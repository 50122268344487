import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { CertificateOfImplementationInvestprojectStagesFormValues } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'
import { IStepMonitoringEnded } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForCertificateOfImplementationInvestprojectStagesForm = (
  objectFromPropertyState: Record<string, any>,
): CertificateOfImplementationInvestprojectStagesFormValues => {
  const preparedStepsMonitoringEnded: IStepMonitoringEnded[] = (() => {
    if (
      !isArray(objectFromPropertyState.stepsMonitoringEnded?.value) ||
      !objectFromPropertyState.stepsMonitoringEnded?.value?.length
    )
      return []

    return objectFromPropertyState.stepsMonitoringEnded?.value.map(
      (stepMonitoringEnded): IStepMonitoringEnded => ({
        id: stepMonitoringEnded.propertyId,
        stepNumber: stepMonitoringEnded.value?.stepMonitoringCheckerId?.value?.id
          ? {
              displayValue:
                stepMonitoringEnded.value?.stepMonitoringCheckerId?.value?.stepMonitoringInvestor
                  ?.planStepId?.stepNumber,
              value: stepMonitoringEnded.value?.stepMonitoringCheckerId?.value?.id,
            }
          : null,
        endedStepTotalCapitalValue: stepMonitoringEnded.value?.endedStepTotalCapitalValue?.value,
        endedStepInvestorCapitalValue:
          stepMonitoringEnded.value?.endedStepInvestorCapitalValue?.value,
        endedStepActivityName: stepMonitoringEnded.value?.endedStepActivityName?.value,
        endedStepActivityValue: stepMonitoringEnded.value?.endedStepActivityValue?.value,
      }),
    )
  })()

  return {
    '1': RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    '2': RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '3': {
      stepsMonitoringEnded: preparedStepsMonitoringEnded,
    },
  }
}

export { generateRHFObjectForCertificateOfImplementationInvestprojectStagesForm }
