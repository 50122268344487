import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm =
  (objectFromPropertyState: Record<string, any>): Record<string, unknown> => {
    return {
      '1': {
        ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
        documentReceivers: {
          propertyId: objectFromPropertyState.documentReceiversDraft?.propertyId,
          type: objectFromPropertyState.documentReceiversDraft?.type,
          lastUpdateDt: objectFromPropertyState.documentReceiversDraft?.lastUpdateDt,
        },
      },
      '2': {
        ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
        investorFullNameInstrumental: {
          propertyId: objectFromPropertyState?.investorFullNameInstrumentalDraft?.propertyId,
          type: objectFromPropertyState?.investorFullNameInstrumentalDraft?.type,
          lastUpdateDt: objectFromPropertyState?.investorFullNameInstrumentalDraft?.lastUpdateDt,
        },
      },
      '3': {
        ruleLinkDenySolicitationProject: {
          propertyId: objectFromPropertyState.ruleLinkDenySolicitationDraft?.propertyId,
          type: objectFromPropertyState.ruleLinkDenySolicitationDraft?.type,
          lastUpdateDt: objectFromPropertyState.ruleLinkDenySolicitationDraft?.lastUpdateDt,
        },
        reasonDenySolicitationProject: {
          propertyId: objectFromPropertyState.reasonDenySolicitationDraft?.propertyId,
          type: objectFromPropertyState.reasonDenySolicitationDraft?.type,
          lastUpdateDt: objectFromPropertyState.reasonDenySolicitationDraft?.lastUpdateDt,
        },
      },
    }
  }

export { generatePropertiesObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm }
