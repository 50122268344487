import { FieldValues } from 'react-hook-form'

import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { defaultRHFValidation } from '@constants/validations'

type InitiatorValidation<T> = FormValuesValidationSection<'initiatorHeaderName', T>

const getInitiatorValidation = <T extends FieldValues>() => {
  return {
    initiatorHeaderName: {
      required: defaultRHFValidation.required,
    },
  } as InitiatorValidation<T>
}

export { getInitiatorValidation }
