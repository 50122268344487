import { memo } from 'react'
import Dropzone from 'react-dropzone'

import mainDropZoneStyles from '@components/FileDropzone/Primary/FileDropzonePrimary.module.scss'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './LetterHeadPartsDropItem.module.scss'

const ItemDropZone = ({
  dropZoneProps,
  bindDropzoneRef,
  title,
  subtitle,
  openFileDialog,
  isHided,
  isPending = false,
}) => (
  <Dropzone {...dropZoneProps} noClick noKeyboard ref={bindDropzoneRef}>
    {({ getRootProps, getInputProps, isDragActive }) => {
      return (
        <div
          {...getRootProps({
            className: cn(styles.dropRoot, {
              [styles.dropzoneHided]: isHided,
            }),
          })}
        >
          <div
            role={'presentation'}
            className={cn(styles.dropzone__container, {
              [styles['dropzone__container--active']]: isDragActive,
            })}
          >
            <div
              role={'presentation'}
              className={mainDropZoneStyles.dropzone__content}
              onClick={(e) => e.preventDefault()}
            >
              <Loader loading={isPending}>
                <input {...getInputProps()} />
                <div style={{ textAlign: 'center', width: '100%' }}>
                  {title && (
                    <Typography variant={'bodyXLMedium'} style={{ textAlign: 'center' }}>
                      {title}
                    </Typography>
                  )}
                  <div>
                    {subtitle && (
                      <Typography
                        as={'span'}
                        variant={'bodyMRegular'}
                        color={'on-surface-secondary'}
                      >
                        {subtitle}
                      </Typography>
                    )}
                    <Button
                      className={mainDropZoneStyles['dropzone__content-downloadTemplate']}
                      size={'xs'}
                      view={'text'}
                      variant={'buttonSMedium'}
                      color={'default'}
                      data-testid="FileDropzoneSecondary-fileUpload-textButton"
                      onClick={openFileDialog}
                    >
                      выберите на устройстве
                    </Button>
                  </div>
                </div>
              </Loader>
            </div>
          </div>
        </div>
      )
    }}
  </Dropzone>
)

export default memo(ItemDropZone)
