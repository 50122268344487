import React, { FC, memo, useCallback } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import { chooseNpaBlockValues } from '@components/RegistryNpa/Choose/const'
import PartNpaItem from '@components/RegistryNpa/Choose/Items/PartINpaItem'
import {
  ChooseNpaFormValues,
  ChooseNpaThreeNpaArrayPathName,
} from '@components/RegistryNpa/Choose/types'
import { ChosenPart, INpaItemNew, TMasterDirectionTypeNpa } from '@services/NPA/NPA.entity'

interface PartNpaListProps {
  formInstance: UseFormReturn<ChooseNpaFormValues>
  direction: TMasterDirectionTypeNpa
  npaId: string
  name: ChooseNpaThreeNpaArrayPathName
  classificationHeader: (part: INpaItemNew) => string
}

const PartNpaList: FC<PartNpaListProps> = ({
  name,
  direction,
  npaId,
  formInstance,
  classificationHeader,
}) => {
  const { fields: parts, update } = useFieldArray({
    control: formInstance.control,
    name: `${name}.parts`,
    keyName: 'keyNameId',
  })

  const handleChooseParts = useCallback(
    (isChosen: boolean, { id, part, ...restPartItem }: INpaItemNew) => {
      const chosenParts = formInstance.getValues(chooseNpaBlockValues.chosenParts)

      const currentPartItem: ChosenPart = {
        ...restPartItem,
        partId: id,
        npaId,
        parentDirection: direction,
      }

      const filteredChosenParts = chosenParts.filter(
        (part) => part.partId !== currentPartItem.partId,
      )

      const preparedChosenParts = isChosen
        ? [...filteredChosenParts, currentPartItem]
        : filteredChosenParts

      formInstance.setValue(chooseNpaBlockValues.chosenParts, preparedChosenParts)
    },
    [direction, npaId],
  )

  const handleUpdateChosenPart = useCallback(
    ({ id, part, ...restPartItem }: INpaItemNew) => {
      const chosenParts = formInstance.getValues(chooseNpaBlockValues.chosenParts)

      const currentPartItem: ChosenPart = {
        ...restPartItem,
        partId: id,
        npaId,
        parentDirection: direction,
      }

      const updatedChosenParts = chosenParts.map((part) =>
        part.partId === currentPartItem.partId ? currentPartItem : part,
      )

      formInstance.setValue(chooseNpaBlockValues.chosenParts, updatedChosenParts)
    },
    [direction, npaId],
  )

  const handleChoosePartItem = useCallback(
    (index: number) => (isChosen: boolean) => {
      const currentPartItem = formInstance.getValues(`${name}.parts.${index}`)

      update(index, {
        ...currentPartItem,
        isChosen,
      })

      handleChooseParts(isChosen, currentPartItem)
    },
    [handleChooseParts, name, update],
  )

  return (
    <div>
      {parts.map((part, index) => {
        return (
          <PartNpaItem
            key={part.id}
            part={part.part}
            partId={part.id}
            isChosen={part.isChosen}
            initialError={part.hasError}
            classificationHeader={classificationHeader(part)}
            formInstance={formInstance}
            name={`${name}.parts.${index}` as const}
            onChangePart={handleUpdateChosenPart}
            onChoosePart={handleChoosePartItem(index)}
          />
        )
      })}
    </div>
  )
}

export default memo(PartNpaList)
