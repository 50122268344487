import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AAChangingVolumeReimbursementLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import AAChangingVolumeReimbursementModalManager from './Manager'
import AAChangingVolumeReimbursementMenu from './Menu'
import AAChangingVolumeReimbursementStatementForm from './Statement'

interface IAAChangingVolumeReimbursement extends FC {
  LayoutWithFormProvider: typeof AAChangingVolumeReimbursementLayoutWithFormProvider
  Menu: typeof AAChangingVolumeReimbursementMenu
  StatementForm: typeof AAChangingVolumeReimbursementStatementForm
  Manager: typeof AAChangingVolumeReimbursementModalManager
}

const useAAChangingVolumeReimbursementControl = () => {
  const popupManager = usePopupManager()

  const openAAChangingVolumeReimbursementModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AAChangingVolumeReimbursementLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAAChangingVolumeReimbursementModal }
}

const AAAChangingVolumeReimbursement: IAAChangingVolumeReimbursement = () => null

AAAChangingVolumeReimbursement.Menu = AAChangingVolumeReimbursementMenu
AAAChangingVolumeReimbursement.LayoutWithFormProvider = AAChangingVolumeReimbursementLayoutWithFormProvider
AAAChangingVolumeReimbursement.StatementForm = AAChangingVolumeReimbursementStatementForm
AAAChangingVolumeReimbursement.Manager = AAChangingVolumeReimbursementModalManager

export { useAAChangingVolumeReimbursementControl }
export default AAAChangingVolumeReimbursement
