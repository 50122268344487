import { generatePropertiesObjectForNoticeOneSideRejectSZPKForm } from '@components/Forms/NoticeOneSideRejectSZPKForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNoticeOneSideRejectSZPKForm } from '@components/Forms/NoticeOneSideRejectSZPKForm/adapters/RHF.adapter'
import { NoticeOneSideRejectSZPKFormValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/types'

const useNoticeOneSideRejectSZPKAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NoticeOneSideRejectSZPKFormValues =>
    generateRHFObjectForNoticeOneSideRejectSZPKForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNoticeOneSideRejectSZPKForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNoticeOneSideRejectSZPKAdapters }
