import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext } from 'react-hook-form'

import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFindFormValueDifferences } from '@components/DocumentFormComponents/hooks/useFindFormValueDifferences'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import {
  FormModalContextProps,
  FormModalManagerProps,
} from '@components/DocumentFormComponents/types'
import { useCertificateOfImplementationInvestprojectStagesAdapters } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/adapters'
import {
  CertificateOfImplementationInvestprojectStagesFormModifierValues,
  CertificateOfImplementationInvestprojectStagesFormValues,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'
import {
  CertificateOfImplementationInvestprojectStagesFieldArrayControlUpdateWatcher,
  CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/watcher'
import { isNull } from '@helpers/checkTypes'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'

const { getDefaultFormModalContextValue } = DocumentFormHelpers

export const CertificateOfImplementationInvestprojectStagesModalContext = createContext<
  FormModalContextProps<CertificateOfImplementationInvestprojectStagesFormValues>
>(getDefaultFormModalContextValue<CertificateOfImplementationInvestprojectStagesFormValues>())

const useCertificateOfImplementationInvestprojectStagesManager = () => {
  return useContext(CertificateOfImplementationInvestprojectStagesModalContext)
}

const CertificateOfImplementationInvestprojectStagesModalManager: FC<FormModalManagerProps> = ({
  projectId,
  formId,
  editMode,
  rolesOfUser,
  initialErrorsFromViolations,
  onSetLastUpdateFormToNow,
  onClose,
  children,
}) => {
  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  const { updateLastRFHBeforeValue, getLastRHFBeforeValue } =
    useFormPreviousValue<CertificateOfImplementationInvestprojectStagesFormValues>()

  const formInstance = useFormContext<CertificateOfImplementationInvestprojectStagesFormValues>()
  const { setValue, reset: resetForm, clearErrors } = formInstance

  const { processOverrideProps, getOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const formModifierInstance =
    useFormModifierContext<CertificateOfImplementationInvestprojectStagesFormModifierValues>()

  const { reset: resetModifierForm } = formModifierInstance

  const { formIsLoading, handleMountForm } = useFormMount(formId)

  const { generatePropertiesObject, generateRHFObject, generateFormModifierValues } =
    useCertificateOfImplementationInvestprojectStagesAdapters()

  const { getBasePropsFromObjectAdapter } = useGenerateValuesFromObjectAdapter({
    generateRHFObject,
    generatePropertiesObject,
  })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
    rolesOfUser,
  })

  const { handleNotifyAllSubscribers } = useNotifyAllSubscribers({
    watcher: CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const { applyDifferences } = useFindFormValueDifferences({
    setValue,
    watcher: CertificateOfImplementationInvestprojectStagesFieldArrayControlUpdateWatcher,
  })

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()

      if (isNull(prevRHFValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const readyOverrideProps = await processOverrideProps(props.overridePropsFromChanges)

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      const formModifierValues = generateFormModifierValues(props.objectForAdapter)

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      unstable_batchedUpdates(() => {
        applyDifferences(prevRHFValue, RHFValueForReset)
        updateLastRFHBeforeValue(RHFValueForReset)

        resetModifierForm(formModifierValues)

        setTimeout(() => {
          CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher.notifyAllSubscribers(
            readyOverrideProps,
          )
        }, 0)

        onSetLastUpdateFormToNow?.()
      })
    },
    [
      getLastRHFBeforeValue,
      lastFieldUpdateTime,
      processOverrideProps,
      getBasePropsFromObjectAdapter,
      generateFormModifierValues,
      propertiesPropsRef,
      applyDifferences,
      updateLastRFHBeforeValue,
      resetModifierForm,
      onSetLastUpdateFormToNow,
    ],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const wrappedHandleApplyFormChanges = useMemo(
    () => applyFormChanges(handleApplyChanges),
    [applyFormChanges, handleApplyChanges],
  )

  const {
    handleAddItemToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleChangeValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    handleChangePublicValue,
    handleChangePublicValueInList,
    debouncedHandleChangeValue,
  } = useFormUpdate({
    formId,
    getPropertiesProps,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: wrappedHandleApplyFormChanges,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const { handleCheckForm, handlePreviewForm } = useFormActions({
    formId,
    watcher: CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    handleApplyChanges: checkFormChanges,
    onClearError: clearErrors,
  })

  const handleSuccessInitializeForm = useCallback(
    async ({ lastChangesDt, propertyInstance }: SuccessInitialCallbackReturn) => {
      lastFieldUpdateTime.current = lastChangesDt

      propertyInstanceRef.current = propertyInstance

      const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(objectForAdapters)

      const formModifierValues = generateFormModifierValues(objectForAdapters)

      //Установка propertiesProps
      propertiesPropsRef.current = cloneDeep(propertiesProps)

      resetForm(RHFValueForReset)
      resetModifierForm(formModifierValues)
      updateLastRFHBeforeValue(RHFValueForReset)
    },
    [
      generateFormModifierValues,
      getBasePropsFromObjectAdapter,
      lastFieldUpdateTime,
      propertiesPropsRef,
      propertyInstanceRef,
      resetForm,
      resetModifierForm,
      updateLastRFHBeforeValue,
    ],
  )

  //Инициализация формы
  useEffect(() => {
    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleNotifyAllSubscribers,
    })
  }, [])

  const preparedValue: FormModalContextProps<CertificateOfImplementationInvestprojectStagesFormValues> =
    useMemo(
      () => ({
        state: {
          formIsLoading,
          blockViewIsValidating,
          initialErrorsFromViolations,
          editMode,
        },
        handlers: {
          checkFormChanges,
          getPropertiesProps,
          getOverrideProps,
          handleChangeValue,
          handleAddCustomValue,
          handleRemoveCustomValue,
          debouncedHandleChangeValue,
          handleAddItemToListWithOutValue,
          handleAddItemToListWithValue,
          handleRemoveItemFromList,
          handleChangePublicValue,
          handleChangePublicValueInList,
          applyFormChanges: wrappedHandleApplyFormChanges,
          handleChangeBlockValidation,
        },
        actions: {
          handleFormClose: onClose,
          handleCheckForm,
          handlePreviewForm,
        },
        preparedProps: {
          subscribableControl,
        },
      }),
      [
        formIsLoading,
        blockViewIsValidating,
        initialErrorsFromViolations,
        editMode,
        checkFormChanges,
        getPropertiesProps,
        getOverrideProps,
        handleChangeValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        debouncedHandleChangeValue,
        handleAddItemToListWithOutValue,
        handleAddItemToListWithValue,
        handleRemoveItemFromList,
        handleChangePublicValue,
        handleChangePublicValueInList,
        wrappedHandleApplyFormChanges,
        handleChangeBlockValidation,
        onClose,
        handleCheckForm,
        handlePreviewForm,
        subscribableControl,
      ],
    )

  return (
    <CertificateOfImplementationInvestprojectStagesModalContext.Provider value={preparedValue}>
      {children}
    </CertificateOfImplementationInvestprojectStagesModalContext.Provider>
  )
}

export { useCertificateOfImplementationInvestprojectStagesManager as useCertificateOfImplementationInvestprojectStagesManager }

export default CertificateOfImplementationInvestprojectStagesModalManager
