import { memo } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import { npaBlockValues } from '@components/DocumentFormComponents/NpaFormAddon/const'
import { BaseNpaFormValues } from '@components/DocumentFormComponents/NpaFormAddon/types'
import ClassificationsView from '@components/DocumentFormComponents/NpaFormAddon/Views/Classifications'
import DirectionView from '@components/DocumentFormComponents/NpaFormAddon/Views/Direction'
import { PartCustomPropsWithId } from '@services/NPA/NPA.entity'

interface ICardViewRefs {
  [key: string]: number
}

export interface DirectionItemProps<T extends BaseNpaFormValues> {
  direction: string
  directionIndex: number
  blockViewIsValidating: boolean
  isDirectionOpen: boolean
  isDirectionNeedEdit: boolean
  formInstance: T extends BaseNpaFormValues ? UseFormReturn<T> : never
  onDirectionChange: (value: boolean) => void
  onBindCardRef: (callback: (prevState: ICardViewRefs | null) => ICardViewRefs) => void
  onScrollToCardView: (partId: string) => void
  onRemoveNpaPart?: (id: string) => Promise<void>
  onUpdateNpaPart?: (partProps: PartCustomPropsWithId) => Promise<void>
}

const DirectionItem = <T extends BaseNpaFormValues>({
  direction,
  directionIndex,
  blockViewIsValidating,
  isDirectionOpen,
  isDirectionNeedEdit,
  formInstance,
  onDirectionChange,
  onBindCardRef,
  onScrollToCardView,
  onUpdateNpaPart,
  onRemoveNpaPart,
}: DirectionItemProps<T>) => {
  const formName = `${npaBlockValues.npaStructure}.${directionIndex}` as const

  const currentClassifications = useWatch({
    name: `${npaBlockValues.npaStructure}.${directionIndex}.classifications`,
    control: formInstance.control,
  })

  const hasErrorInDirection = !!formInstance.getFieldState(formName)?.error && blockViewIsValidating

  const countOfDirectionClassifications = currentClassifications.reduce((prevValue, nextValue) => {
    return prevValue + nextValue.parts.length
  }, 0)

  return (
    <DirectionView
      blockViewIsValidating={blockViewIsValidating}
      isOpen={isDirectionOpen}
      hasError={hasErrorInDirection}
      needEdit={isDirectionNeedEdit}
      direction={direction}
      count={countOfDirectionClassifications}
      onChange={onDirectionChange}
    >
      <ClassificationsView
        blockViewIsValidating={blockViewIsValidating}
        formInstance={formInstance}
        formName={formName}
        onBindCardRef={onBindCardRef}
        onScrollToCardView={onScrollToCardView}
        onUpdateNpaPart={onUpdateNpaPart}
        onRemoveNpaPart={onRemoveNpaPart}
      />
    </DirectionView>
  )
}

export default memo(DirectionItem)
