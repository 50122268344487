import { FC } from 'react'

import MarkdownNodeFactory from '@components/Markdown/NodeFactory'
import MarkdownView from '@components/Markdown/View'
import WithFileWrapper from '@components/Markdown/WithFileWrapper'

interface MarkDownProps extends FC {
  View: typeof MarkdownView
  NodeFactory: typeof MarkdownNodeFactory

  FileWrapper: typeof WithFileWrapper
}

const Markdown: MarkDownProps = () => null

Markdown.View = MarkdownView
Markdown.NodeFactory = MarkdownNodeFactory
Markdown.FileWrapper = WithFileWrapper

export default Markdown
