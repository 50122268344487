import React, { createContext, FC, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'

import { isPermissionForChange } from '@components/Projects/[id]/ActionBanner/Manager'
import { getDfoByType } from '@components/Projects/[id]/helpers'
import { NewDfosType } from '@constants/types'
import { useDfoDocuments } from '@hooks/new/swr/useDfoDocuments'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useBooleanState } from '@hooks/useBooleanState'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'

interface LayoutModeContextProps {
  //Пункт 34
  isChangesMadeMode: boolean
}

const LayoutModeContext = createContext<LayoutModeContextProps>({
  isChangesMadeMode: false,
})

const useLayoutMode = () => {
  return useContext(LayoutModeContext)
}

const LayoutModeManager: FC = ({ children }) => {
  const { projectId } = useParams()

  const { booleanState: isChangesMadeMode, setBooleanState: setChangesMadeMode } = useBooleanState()

  const { projectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
  })

  const SZPKDfoInfo = useMemo(() => {
    if (!projectDfos) return

    return getDfoByType(projectDfos, NewDfosType.SZPK) as IDfoListItem
  }, [projectDfos])

  const { dfoDocuments } = useDfoDocuments({
    key: { dfoId: SZPKDfoInfo?.id, projectId, _key: 'document-sets' },
    config: {
      isPaused: () => !SZPKDfoInfo?.id || !projectId,
    },
  })

  useEffect(() => {
    if (!dfoDocuments) return

    const keyChange = Object.keys(dfoDocuments).find((key) => key.includes('_CHANGES'))

    if (!keyChange) return

    setChangesMadeMode(isPermissionForChange(dfoDocuments[keyChange][0]))
  }, [dfoDocuments, setChangesMadeMode])

  const returnValue = useMemo(
    () => ({
      isChangesMadeMode,
    }),
    [isChangesMadeMode],
  )

  return <LayoutModeContext.Provider value={returnValue}>{children}</LayoutModeContext.Provider>
}

export { useLayoutMode }
export default LayoutModeManager
