import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { useNotificationFillingAppConcludingManager } from '@components/Forms/NotificationFillingAppConcludingForm/Manager'

type NotificationFillingAppConcludingLayoutWrapperProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const NotificationFillingAppConcludingLayoutWrapper: FC<
  NotificationFillingAppConcludingLayoutWrapperProps
> = ({ children, ...rest }) => {
  const {
    state: { lastUpdateDraftTime },
    actions: { handleFormClose },
  } = useNotificationFillingAppConcludingManager()

  const handleClose = () => {
    handleFormClose?.()
  }

  return (
    <>
      <DocumentLayoutContent {...rest} draftIsSaved={!!lastUpdateDraftTime} onClose={handleClose}>
        {children}
      </DocumentLayoutContent>
    </>
  )
}

export default NotificationFillingAppConcludingLayoutWrapper
