import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type {
  SupplementalCostSharingAContractDutyObject,
  SupplementalCostSharingAFormValues,
} from '@components/Forms/SupplementalCostSharingAForm/types'
import { defaultRHFValidation, standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const seventhSectionStepObjectContractValidationMap: FormValuesValidationSection<
  keyof SupplementalCostSharingAContractDutyObject,
  SupplementalCostSharingAFormValues
> = {
  objectName: {
    required: defaultRHFValidation.required,
  },
  contractDutyObjectActivityType: {
    required: defaultRHFValidation.required,
  },
  contractDutyObjectActivityEndDate: {
    required: defaultRHFValidation.required,
  },
  contractDutyObjectCost: {
    required: defaultRHFValidation.required,
    validate: {
      positiveStandardAmount: (value) => {
        if (!isString(value)) return

        return standardAmountValidate(value)
      },
    },
  },
  contractDutyObjectInvestorCost: {
    required: defaultRHFValidation.required,
  },
}

export { seventhSectionStepObjectContractValidationMap }
