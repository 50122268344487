import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'
import { lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const firstSectionValidationMap: FormValuesValidationSection<
  keyof NoticeOfProjectRefuseConcludeAAFormValues['1'],
  NoticeOfProjectRefuseConcludeAAFormValues
> = {
  documentReceivers: {
    validate: (value) => isString(value) && lengthValidate(value, 5000),
  },
}

export { firstSectionValidationMap }
