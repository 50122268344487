import { useMemo } from 'react'

import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import { useNpaTypes } from '@hooks/new/swr/useNpaTypes'
import { useRegions } from '@hooks/new/swr/useRegions'

const useNpaSelectValues = () => {
  const { npaTypes } = useNpaTypes({
    key: {
      _key: 'npaTypes',
    },
  })

  const { regions } = useRegions({
    key: {
      _key: 'regions',
    },
  })

  const { npaDirections } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const preparedTypesForSelect = useMemo(() => {
    if (!npaTypes) return []

    return npaTypes.map(({ type, description }) => ({
      displayValue: description,
      value: type,
    }))
  }, [npaTypes])

  const preparedDirectionsForSelect = useMemo(() => {
    if (!npaDirections) return []

    return npaDirections.map(({ type, description }) => ({
      displayValue: description,
      value: type,
    }))
  }, [npaDirections])

  const preparedRegionsForSelect = useMemo(() => {
    if (!regions) return []

    return Object.entries(regions).map(([regionValue, regionName]) => ({
      displayValue: regionName,
      value: regionValue,
    }))
  }, [regions])

  const preparedRegionsWithCodesForSelect = useMemo(() => {
    if (!regions) return []

    return Object.entries(regions).map(([regionValue, regionName]) => ({
      displayValue: `${regionValue} - ${regionName}`,
      value: regionValue,
    }))
  }, [regions])

  return {
    preparedRegionsForSelect,
    preparedTypesForSelect,
    preparedDirectionsForSelect,
    preparedRegionsWithCodesForSelect,
  }
}

export { useNpaSelectValues }
