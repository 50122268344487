import { objOfDateFormats } from '@constants/dateFormats'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { mapOfDeadlineViewType } from '@services/Deadline/Deadline.const'
import { IDfoDeadline } from '@services/Deadline/Deadline.entity'

class DeadlineService {
  getDifferenceBetweenCurrentAndDeadlineDate(overdueDate: string) {
    return DayjsService.dayjsWithFormatToMSK()
      .startOf('d')
      .diff(DayjsService.dayjs(overdueDate, objOfDateFormats.defaultNativeDateFormat), 'd')
  }

  getDefaultFormattedDate(overdueDate: string) {
    return DayjsService.dayjs(overdueDate, objOfDateFormats.defaultNativeDateFormat).format(
      `до ${objOfDateFormats.onlyMonth}`,
    )
  }

  checkRenderTooltipByViewType(viewType: IDfoDeadline['viewType']) {
    return viewType === mapOfDeadlineViewType.RECOMMENDED
  }
}

export default new DeadlineService()
