import { createContext, FC, useCallback, useContext } from 'react'

import { Urls } from '@constants/urls'
import { useAuth } from '@context/AuthContext/hooks/useAuth'
import { useUserInfo } from '@context/AuthContext/hooks/useUserInfo'
import { StorageFields } from '@context/AuthContext/models'
import {
  getClientId,
  getUserThumbprint,
  resetKapClient,
} from '@context/AuthContext/workers/clientWorkers'
import { getInnByToken } from '@context/AuthContext/workers/innWorkers'
import {
  checkRoleOnExistence,
  findRoleBySelectedRoles,
  getEmailStatus,
  getMainRole,
  getRolesByToken,
  getUserIOandSurname,
  getUserRole,
  isRoleAvailableForRegistration,
} from '@context/AuthContext/workers/rolesWorkers'
import { AuthRequestCert } from '@interfaces/auth'
import { Fetcher, FetcherWithInterceptors, initializeAuthRefresh } from '@packages/api/fetcher'
import storage from 'store2'

import { jwtDecodeFacade } from './helpers/jwtDecodeFacade'
import { AuthContextProps, AuthState, ProcurationData, UserInfoState } from './types'

const initialAuthState = (storage.get(StorageFields.authState) as AuthState) || null
const initialUserInfoState = (storage.get(StorageFields.userInfo) as UserInfoState) || null

export const AuthContext = createContext<AuthContextProps>({
  Fetcher,
  FetcherWithInterceptors,
})

export const AuthProvider: FC = ({ children }) => {
  const { userInfoState, saveCertificateData } = useUserInfo(initialUserInfoState)

  const {
    authState,
    refreshToken,
    setAuthData: setAuthState,
    getNewTokenForRequest,
    ...otherAuthMethods
  } = useAuth(initialAuthState, saveCertificateData)

  // Реинициализируем на каждом ререндере в основном потоке
  jwtDecodeFacade.initialize(authState)

  initializeAuthRefresh(getNewTokenForRequest)

  const saveProcuration = async (body: AuthRequestCert): Promise<ProcurationData> => {
    body.data = body.data.replace(/[\r\n\s]+/g, '')
    console.log(JSON.parse(JSON.stringify(body.data)))
    try {
      const { data } = await Fetcher.post<ProcurationData>({
        url: Urls.PublicProcurations,
        data: { data: body.data },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const logLogoutOfUser = useCallback(async () => {
    try {
      return await FetcherWithInterceptors.post<void>({
        url: Urls.Logout,
        data: {
          refreshToken: authState?.refreshToken,
        },
      })
    } catch (error) {
      throw error
    }
  }, [authState?.refreshToken])

  return (
    <AuthContext.Provider
      value={{
        refreshToken,
        Fetcher,
        FetcherWithInterceptors,
        getClientId,
        getUserThumbprint,
        resetKapClient,
        getInnByToken,
        setAuthState,
        authState,
        saveProcuration,
        saveCertificateData,
        userInfoState,
        getUserRole,
        getUserIOandSurname,
        getRolesByToken,
        getEmailStatus,
        getMainRole,
        findRoleBySelectedRoles,
        checkingRole: checkRoleOnExistence,
        isHasRoleForRegister: isRoleAvailableForRegistration,
        logLogoutOfUser,
        ...otherAuthMethods,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}
