import { optimizedFlat } from '@helpers/array/optimizedFlat'
import { isArray } from '@helpers/checkTypes'
import { IOverridePropsFromClass } from '@services/Properties/Properties.entity'

const generateOverridePropsForAAgreementOnCreationOtherEstateObjects = (
  objectFromPropertyState: Record<string, any>,
): IOverridePropsFromClass[] => {
  const getPreparedObjectRegions = (): IOverridePropsFromClass[] => {
    if (
      !isArray(objectFromPropertyState.steps.value) ||
      !objectFromPropertyState.steps.value?.length
    )
      return []

    return optimizedFlat(
      objectFromPropertyState.steps.value.map((step) => {
        if (!isArray(step?.value?.objects?.value) || !step?.value?.objects?.value?.length) return []

        const objects = step.value.objects?.value?.filter((object) => {
          return (
            object.value.isMainObject?.value &&
            !object.value.isSubObject?.value &&
            !object.value.objectOtherActivityName?.value?.id
          )
        })

        const subObjects = step.value.objects?.value?.filter((object) => {
          return (
            object.value.isSubObject?.value &&
            !object.value.objectOtherActivityName?.value?.id &&
            object.value.parentSubObject?.value
          )
        })

        const objectRegions: IOverridePropsFromClass[] = objects?.map((object) => {
          if (
            !isArray(object.value?.objectRegions?.value) ||
            !object.value?.objectRegions?.value?.length
          )
            return []

          return object.value?.objectRegions?.value?.map(
            (objectRegion): IOverridePropsFromClass => ({
              type: 'PRECONFIGURED',
              setup: 'regions',
              propertyId: objectRegion?.value?.objectRegionCode?.propertyId,
            }),
          )
        })

        const subObjectRegions: IOverridePropsFromClass[] = subObjects?.map((subObject) => {
          if (
            !isArray(subObject.value?.objectRegions?.value) ||
            !subObject.value?.objectRegions?.value?.length
          )
            return []

          return subObject.value?.objectRegions?.value?.map(
            (objectRegion): IOverridePropsFromClass => ({
              type: 'PRECONFIGURED',
              setup: 'regions',
              propertyId: objectRegion?.value?.objectRegionCode?.propertyId,
            }),
          )
        })

        return [...objectRegions, ...subObjectRegions]
      }),
    )
  }

  return getPreparedObjectRegions()
}

export { generateOverridePropsForAAgreementOnCreationOtherEstateObjects }
