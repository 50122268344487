import { FC } from 'react'

import First from './1'
import Second from './2'

interface PetitionRecognizePreviouslyConcludedContractAsBoundFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const PetitionRecognizePreviouslyConcludedContractAsBoundForms: PetitionRecognizePreviouslyConcludedContractAsBoundFormsProps =
  () => null

PetitionRecognizePreviouslyConcludedContractAsBoundForms.First = First
PetitionRecognizePreviouslyConcludedContractAsBoundForms.Second = Second

export default PetitionRecognizePreviouslyConcludedContractAsBoundForms
