import { BeneficiaryInformationFormValues } from '@components/Forms/BeneficiaryInformationForm/types'

const mapOfBeneficiaryInformationMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Информация о бенефициарных владельцах' },
]

const beneficiaryInformationBlockValues = {
  '1': {
    investorInn: '1.investorInn',
    investorFullName: '1.investorFullName',
    investorAddress: '1.investorAddress',
    investorOgrn: '1.investorOgrn',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderPosition: '1.investorHeaderPosition',
  },
  '2': {
    beneficiars: '2.beneficiars',
  },
} as const

const defaultBeneficiaryFormValues: BeneficiaryInformationFormValues = {
  '1': {
    investorInn: '',
    investorFullName: '',
    investorAddress: '',
    investorOgrn: '',
    investorHeaderName: null,
    investorHeaderPosition: '',
  },
  '2': {
    beneficiars: [],
  },
}

export {
  beneficiaryInformationBlockValues,
  defaultBeneficiaryFormValues,
  mapOfBeneficiaryInformationMenu,
}
