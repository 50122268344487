import { isObject } from '@helpers/checkTypes'
import {
  BaseDiffObject,
  DiffObjectInList,
  SimpleDiffObject,
} from '@services/DeepDiff/DeepDiff.entity'

class DeepDiffHelpers {
  static isBaseDiffObject = (value: unknown): value is BaseDiffObject =>
    isObject(value) && 'path' in value && 'kind' in value

  static isSimpleDiffObject = (value: unknown): value is SimpleDiffObject =>
    DeepDiffHelpers.isBaseDiffObject(value) && 'lhs' in value && 'rhs' in value

  static isDiffObjectInList = (value: unknown): value is DiffObjectInList =>
    DeepDiffHelpers.isBaseDiffObject(value) && 'item' in value && 'index' in value
}

export { DeepDiffHelpers }
