import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { PetitionTransferRightFormValues } from '@components/Forms/PetitionTransferRightForm/types'

const generateRHFObjectForPetitionTransferRightForm = (
  objectFromPropertyState: Record<string, any>,
): PetitionTransferRightFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorEgrulAddress:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value
          ?.addressFullEgrulFormat,
      investorFullNameGenitive: objectFromPropertyState.investorFullNameGenitive?.value || '',
    },
    '2': {
      ...RHFBasicAdapters.getInvestorPartnerAdapter(objectFromPropertyState),
      investorPartnerEgrulAddress:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value
          ?.addressFullEgrulFormat,
      investorPartnerFullNameDative:
        objectFromPropertyState.investorPartnerFullNameDative?.value || '',
    },
    '3': {
      rightTransferSolicitationReason:
        objectFromPropertyState.rightTransferSolicitationReason?.value || '',
    },
  } as PetitionTransferRightFormValues
}

export { generateRHFObjectForPetitionTransferRightForm }
