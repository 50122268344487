import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import objectItemStyles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useDeviationText } from '@components/DocumentFormComponents/hooks/useDeviationText'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { DEVIATION_PREFIX } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/const'
import {
  EconomicSphereOkvedsRegionalArrayItemPathName,
  EconomicSphereRegionalOkvedsReportsInfoArrayPathName,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/4/types'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import {
  DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  DataOnFulfillmentOfTermsSZPKFieldCollapseControlUpdateWatcher,
  DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import Typography from '@components/NewDesign/Typography'
import Stack from '@components/ReactBootstrap/Stack'
import DeleteIcon from '@icons/DeleteIcon.svg'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import SzpkReportInfoCard from '../SzpkReportInfoCard'

import { economicSphereOkvedsValidationMap } from './validation'
const { getOkvedTitle } = DocumentFormHelpers

interface EconomicSphereCardProps {
  subObjectId: string
  parentAnchorId: string
  indexOfOkved: number
  expandedRenderMode?: boolean
  formName: EconomicSphereOkvedsRegionalArrayItemPathName
  onDeleteSubObject: VoidFunction
}

const EconomicSphereOkvedCard: FC<EconomicSphereCardProps> = ({
  subObjectId,
  parentAnchorId,
  indexOfOkved,
  expandedRenderMode,
  formName,
  onDeleteSubObject,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const anchorId = `${parentAnchorId}.${indexOfOkved + 1}`

  const currentSzpkReportsInfoFormName = `${formName}.szpkReportsInfo` as const

  useFieldArraySubscribableControl<
    DataOnFulfillmentOfTermsSZPKFormValues,
    EconomicSphereRegionalOkvedsReportsInfoArrayPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: currentSzpkReportsInfoFormName,
    keyName: 'keyNameId',
    watcher: DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  })

  const szpkReportsInfo = useWatch({
    name: currentSzpkReportsInfoFormName,
    control: formInstance?.control,
  })

  const economicSphereOkvedCode = useWatch({
    name: `${formName}.economicSphereOkvedCodeRegionalReport`,
    control: formInstance?.control,
  })?.code

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps, getAmountInputProps, getHierarchyReferenceBookSelectProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
    })

  const { isExpanded: isCardExpanded, onToggleCollapse: toggleCardExpanded } =
    useFormCollapseControl({
      initialExpanded: expandedRenderMode,
      name: anchorId,
      watcher: DataOnFulfillmentOfTermsSZPKFieldCollapseControlUpdateWatcher,
    })

  const totalReportFormName = `${formName}.totalCapitalEconomicSphereOkvedRegionalReport` as const
  const totalReportCheckerFormName =
    `${formName}.totalCapitalEconomicSphereOkvedRegionalReportChecker` as const
  const totalPercentDeviationText = useDeviationText(
    formInstance,
    totalReportFormName,
    totalReportCheckerFormName,
    DEVIATION_PREFIX,
  )

  const investorReportFormName =
    `${formName}.investorCapitalEconomicSphereOkvedRegionalReport` as const
  const investorReportCheckerFormName =
    `${formName}.investorCapitalEconomicSphereOkvedRegionalReportChecker` as const
  const investorPercentDeviationText = useDeviationText(
    formInstance,
    investorReportFormName,
    investorReportCheckerFormName,
    DEVIATION_PREFIX,
  )

  const newWorkPlacesReportFormName =
    `${formName}.newWorkPlacesEconomicSphereOkvedRegionalReport` as const
  const newWorkPlacesReportCheckerFormName =
    `${formName}.newWorkPlacesEconomicSphereOkvedRegionalReportChecker` as const
  const newWorkPlacesPercentDeviationText = useDeviationText(
    formInstance,
    newWorkPlacesReportFormName,
    newWorkPlacesReportCheckerFormName,
    DEVIATION_PREFIX,
  )

  const handleRemoveEconomicSphereOkved = async () => {
    await onDeleteSubObject?.()
  }

  return (
    <SubscribableControl
      {...getSubscribableControlProps({
        path: formName,
      })}
    >
      <FlippedWithCollapse flipId={subObjectId}>
        <div
          id={anchorId}
          className={cn(
            objectItemStyles.objectItem,
            objectItemStyles['objectItem--subObject'],
            objectItemStyles['objectItem--economicSphereOkved'],
          )}
        >
          <div className={objectItemStyles.objectItem__header}>
            <Button
              fixWidth
              view="plain"
              size="s"
              variant="buttonSMedium"
              color="default"
              className={objectItemStyles['objectItem__header-subObjectButton']}
              textClassName={objectItemStyles['objectItem__header-subObjectButton__text']}
              trailingIcon={{
                src: chevronRightIcon,
                className: cn(objectItemStyles['objectItem__header-subObjectButton__icon'], {
                  [objectItemStyles['objectItem__header-subObjectButton__icon--active']]:
                    isCardExpanded,
                }),
                size: 's',
              }}
              onClick={toggleCardExpanded}
            >
              {getOkvedTitle(indexOfOkved + 1, economicSphereOkvedCode)}
            </Button>
            {editMode && (
              <AsyncWrapper promise={handleRemoveEconomicSphereOkved}>
                {({ isLoading, wrappedPromise }) => (
                  <Button
                    disabled={isLoading}
                    variant={'buttonSMedium'}
                    size={'2xs'}
                    color={'negative'}
                    view={'plain'}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    leadingIcon={{
                      src: DeleteIcon,
                    }}
                    onClick={wrappedPromise}
                  >
                    Удалить
                  </Button>
                )}
              </AsyncWrapper>
            )}
          </div>
          <div className={objectItemStyles.objectItem__content}>
            <CollapseWrapper defaultExpanded={isCardExpanded} isExpanded={isCardExpanded}>
              <Stack direction={'vertical'} gap={3}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.economicSphereOkvedCodeRegionalReport`,
                  })}
                >
                  <ControlledHierarchyReferenceBookSelect
                    {...getHierarchyReferenceBookSelectProps({
                      dictionaryName: 'okved',
                      controllerProps: {
                        name: `${formName}.economicSphereOkvedCodeRegionalReport`,
                        rules:
                          economicSphereOkvedsValidationMap.economicSphereOkvedCodeRegionalReport,
                      },
                      inputProps: {
                        label: 'Код ОКВЭД',
                      },
                      onChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.economicSphereOkvedCodeRegionalReport`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
                <Group
                  disableBottomBorder
                  title="Количество проектов"
                  groupClassName={cn(
                    objectItemStyles['objectItem__content-sublist-group'],
                    objectItemStyles['objectItem__content-sublist-group--subObject'],
                  )}
                >
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Количество по отчетам ОРП'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.countEconomicSphereOkvedRegionalReport`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.countEconomicSphereOkvedRegionalReport`,
                          inputProps: {
                            disabled: true,
                            suffix: '',
                            integersOnly: true,
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Количество для переноса в отчет'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.countEconomicSphereOkvedRegionalReportCorrect`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.countEconomicSphereOkvedRegionalReportCorrect`,
                          inputProps: {
                            suffix: '',
                            integersOnly: true,
                          },
                          rules:
                            economicSphereOkvedsValidationMap.countEconomicSphereOkvedRegionalReportCorrect,
                          onBlur: () =>
                            setTimeout(() => {
                              handleChangeValue?.(
                                `${formName}.countEconomicSphereOkvedRegionalReportCorrect`,
                              )
                            }, 0),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.countEconomicSphereOkvedRegionalReportCorrect`,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                </Group>
                <Group
                  disableBottomBorder
                  title="Объем капитальных вложений"
                  groupClassName={cn(
                    objectItemStyles['objectItem__content-sublist-group'],
                    objectItemStyles['objectItem__content-sublist-group--subObject'],
                  )}
                >
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Объем по отчетам ОРП'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: totalReportFormName,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: totalReportFormName,
                          inputProps: {
                            disabled: true,
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Объем по справкам проверяющей организации'}
                    titleHeightFallback={'40px'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: totalReportCheckerFormName,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: totalReportCheckerFormName,
                          inputProps: {
                            disabled: true,
                            leftAddons: (
                              <FormIconWithTooltip tooltipContent={totalPercentDeviationText} />
                            ),
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Объем для переноса в отчет'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.totalCapitalEconomicSphereOkvedRegionalReportCorrect`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.totalCapitalEconomicSphereOkvedRegionalReportCorrect`,
                          rules:
                            economicSphereOkvedsValidationMap.totalCapitalEconomicSphereOkvedRegionalReportCorrect,
                          onBlur: () =>
                            setTimeout(() => {
                              handleChangeValue?.(
                                `${formName}.totalCapitalEconomicSphereOkvedRegionalReportCorrect`,
                              )
                            }, 0),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.totalCapitalEconomicSphereOkvedRegionalReportCorrect`,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                </Group>
                <Group
                  disableBottomBorder
                  title="Объем капиталовложений"
                  groupClassName={cn(
                    objectItemStyles['objectItem__content-sublist-group'],
                    objectItemStyles['objectItem__content-sublist-group--subObject'],
                  )}
                >
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Объем по отчетам ОРП'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: investorReportFormName,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: investorReportFormName,
                          inputProps: {
                            disabled: true,
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Объем по справкам проверяющей организации'}
                    titleHeightFallback={'40px'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: investorReportCheckerFormName,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: investorReportCheckerFormName,
                          inputProps: {
                            disabled: true,
                            leftAddons: (
                              <FormIconWithTooltip tooltipContent={investorPercentDeviationText} />
                            ),
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Объем для переноса в отчет'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.investorCapitalEconomicSphereOkvedRegionalReportCorrect`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.investorCapitalEconomicSphereOkvedRegionalReportCorrect`,
                          rules:
                            economicSphereOkvedsValidationMap.investorCapitalEconomicSphereOkvedRegionalReportCorrect,
                          onBlur: () =>
                            setTimeout(() => {
                              handleChangeValue?.(
                                `${formName}.investorCapitalEconomicSphereOkvedRegionalReportCorrect`,
                              )
                            }, 0),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.investorCapitalEconomicSphereOkvedRegionalReportCorrect`,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                </Group>
                <Group
                  title="Количество рабочих мест"
                  groupClassName={cn(
                    objectItemStyles['objectItem__content-sublist-group'],
                    objectItemStyles['objectItem__content-sublist-group--subObject'],
                  )}
                >
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Количество по отчетам ОРП'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: newWorkPlacesReportFormName,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: newWorkPlacesReportFormName,
                          inputProps: {
                            disabled: true,
                            suffix: '',
                            integersOnly: true,
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Количество по справкам проверяющей организации'}
                    titleHeightFallback={'40px'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: newWorkPlacesReportCheckerFormName,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: newWorkPlacesReportCheckerFormName,
                          inputProps: {
                            disabled: true,
                            suffix: '',
                            integersOnly: true,
                            leftAddons: (
                              <FormIconWithTooltip
                                tooltipContent={newWorkPlacesPercentDeviationText}
                              />
                            ),
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Количество для переноса в отчет'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect`,
                          inputProps: {
                            integersOnly: true,
                            suffix: '',
                          },
                          rules:
                            economicSphereOkvedsValidationMap.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect,
                          onBlur: () =>
                            setTimeout(() => {
                              handleChangeValue?.(
                                `${formName}.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect`,
                              )
                            }, 0),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect`,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                </Group>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.szpkReportsInfo`,
                  })}
                >
                  <Group
                    disableBottomBorder
                    title="Информация по подписанным отчетам ОРП"
                    groupClassName={cn(
                      objectItemStyles['objectItem__content-sublist-group'],
                      objectItemStyles['objectItem__content-sublist-group--subObject'],
                    )}
                  >
                    {!!szpkReportsInfo.length ? (
                      <FlipperList list={szpkReportsInfo}>
                        {szpkReportsInfo.map((reportInfo, index) => {
                          const szpkReportsInfoItemFormName =
                            `${formName}.szpkReportsInfo.${index}` as const

                          return (
                            <SzpkReportInfoCard
                              key={reportInfo.id}
                              reportInd={index}
                              investorFullName={reportInfo.investorFullName}
                              formName={szpkReportsInfoItemFormName}
                            />
                          )
                        })}
                      </FlipperList>
                    ) : (
                      <Typography.Caption
                        className={styles.form__signedSubtitle}
                        variant={'captionSRegular'}
                      >
                        нет подписанных отчетов
                      </Typography.Caption>
                    )}
                  </Group>
                </SubscribableControl>
              </Stack>
            </CollapseWrapper>
          </div>
        </div>
      </FlippedWithCollapse>
    </SubscribableControl>
  )
}

export default EconomicSphereOkvedCard
