import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import Collapse, { CollapseHeightProps } from '@components/Collapse'
import IconButton from '@components/NewDesign/IconButton'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

import styles from './StepTemplate.module.scss'

interface StepTemplateProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onRemoveStep: () => void

  children: ReactNode
  onBeforeRemoveStep?: () => void
  contentClassName?: string
  dataTestId?: string
}

const StepTemplate: FC<StepTemplateProps> = ({
  onBeforeRemoveStep,
  onRemoveStep,
  children,
  contentClassName,
  dataTestId,
  ...divRest
}) => {
  const divRef = useRef<HTMLDivElement | null>(null)

  const [height, setHeight] = useState<CollapseHeightProps>(0)

  useEffect(() => {
    setHeight('auto')
  }, [])

  const handleDelete = (e) => {
    e.preventDefault()

    setHeight(0)
  }

  const currentOnRemoveStep = height === 0 ? onRemoveStep : undefined
  const currentOnBeforeRemoveStep = height === 0 ? onBeforeRemoveStep : undefined

  return (
    <div
      {...divRest}
      style={{
        ...divRest.style,
        position: 'relative',
        marginTop: height !== 0 ? divRest.style?.marginTop : 0,
        transition: 'margin 500ms',
      }}
    >
      <Collapse
        dataTestId="FieldView.StepTemplate-collapse"
        height={height}
        onHeightAnimationStart={currentOnBeforeRemoveStep}
        onHeightAnimationEnd={currentOnRemoveStep}
      >
        <div ref={divRef} className={cn(styles.stepTemplate, contentClassName)}>
          {children}
        </div>
      </Collapse>
      {height === 'auto' && (
        <IconButton
          geometry={'round'}
          view={'tined'}
          color={'negative'}
          dataTestId="FieldView.StepTemplate-delete-iconButton"
          className={styles.stepTemplate__remove}
          size={'s'}
          icon={{ src: DeleteIcon }}
          onClick={handleDelete}
        />
      )}
    </div>
  )
}

export default StepTemplate
