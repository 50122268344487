import React, { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { defaultSystemVariablesFormValue } from '@components/SystemVariables/const'
import SystemVariablesLayout from '@components/SystemVariables/Layout'

const SystemVariables = () => {
  return (
    <FormProviderWrapper defaultValues={defaultSystemVariablesFormValue}>
      <SystemVariablesLayout />
    </FormProviderWrapper>
  )
}

export default memo(SystemVariables)
