import React, { FC } from 'react'

import AsyncWrapper from '@components/AsyncWrapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import { isUndefined } from '@helpers/checkTypes'
import DeleteIcon from '@icons/DeleteIcon.svg'

import styles from './RowWithRemoveButton.module.scss'

interface RowWithRemoveButtonProps {
  id: string
  fieldColSize?: number
  removeButtonColSize?: number
  dataTestId?: string
  onRemove?: VoidFunction
}

const RowWithRemoveButton: FC<RowWithRemoveButtonProps> = ({
  id,
  onRemove,
  fieldColSize = !isUndefined(onRemove) ? 9 : 12,
  removeButtonColSize = 3, 
  children,
  dataTestId
}) => {
  const isShowRemoveButton = !isUndefined(onRemove)

  const handleDelete = async (e) => {
    e.preventDefault()

    await onRemove?.()
  }

  return (
    <FlippedWithCollapse dataTestId={dataTestId} flipId={id}>
      <Row>
        <Col xs={fieldColSize}>{children}</Col>
        {isShowRemoveButton && (
          <AsyncWrapper promise={handleDelete}>
            {({ isLoading, wrappedPromise }) => (
              <Col
                xs={removeButtonColSize}
                className={styles['rowWithRemoveButton__removeButton-wrapper']}
              >
                <Button
                  fixWidth
                  disabled={isLoading}
                  variant={'buttonSMedium'}
                  size={'2xs'}
                  color={'negative'}
                  dataTestId={'deleteButton'}
                  view={'plain'}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  leadingIcon={{
                    noCurrentColorSvgFill: true,
                    src: DeleteIcon,
                  }}
                  onClick={wrappedPromise}
                >
                  Удалить
                </Button>
              </Col>
            )}
          </AsyncWrapper>
        )}
      </Row>
    </FlippedWithCollapse>
  )
}

export default RowWithRemoveButton
