import { FC } from 'react'

import cn from 'classnames'

import { Skeleton, SkeletonProps } from '../Skeleton'

import styles from './SkeletonText.module.scss'

export const SkeletonText: FC<SkeletonProps> = (props) => {
  const { children, className } = props

  return (
    <Skeleton {...props} className={cn(styles.wrapper, className)}>
      {children}
    </Skeleton>
  )
}
