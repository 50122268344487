import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionValidationMap: FormValuesValidationSection<
  keyof InformationOfExecutionConditionsSZPKFormValues['3']['stepsMonitoring'][number],
  InformationOfExecutionConditionsSZPKFormValues
> = {
  planStepTotalCapitalValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  planStepTotalCapitalVatValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepTotalCapitalValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepTotalCapitalVatValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepInvestorCapitalValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepInvestorCapitalVatValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepCompensationValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepCompensationValueVat: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  planStepWorkPlace: {
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  factStepWorkPlace: {
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  stepInfoInvestor: {
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { thirdSectionValidationMap }
