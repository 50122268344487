import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfNotificationOfChangeDocumentsMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Изменение документов' },
]

const defaultNotificationOfChangeDocumentsFormValues: NotificationOfChangeDocumentsFormValues = {
  '1': {
    ...getDefaultInvestorFieldState(),
    investorInn: '',
    investorOgrn: '',
    investorAddress: '',
  },
  '2': {
    ruleLinkStatementChanges: '',
    characteristicOfDocuments: [],
  },
}

const notificationOfChangeDocumentsBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
    investorAddress: '1.investorAddress',
  },
  '2': {
    ruleLinkStatementChanges: '2.ruleLinkStatementChanges',
    characteristicOfDocuments: '2.characteristicOfDocuments',
    changeDocumentText: '2.changeDocumentText',
  },
} as const

export {
  defaultNotificationOfChangeDocumentsFormValues,
  mapOfNotificationOfChangeDocumentsMenu,
  notificationOfChangeDocumentsBlockValues,
}
