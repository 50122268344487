import React from 'react'
import { ArrayPath, FieldValues } from 'react-hook-form'

import Container from '@components/Container'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import MunicipalityForm from '@components/DocumentFormComponents/FormSections/MunicipalitiesSection/MunicipalityForm'
import {
  ArrayWithId,
  SectionWithArray,
} from '@components/DocumentFormComponents/FormSections/types'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import Row from '@components/ReactBootstrap/Row'

const MunicipalitiesSection = <T extends FieldValues>({
  editMode,
  blockViewIsValidating,
  formInstance,
  formName,
  subscribableControl,
  watcher,
  onChangeValue,
  debouncedChangeValue,
  fieldArrayWatcher,
}: SectionWithArray<T>) => {
  const { fields: municipalities } = useFieldArraySubscribableControl<T, ArrayPath<T>, 'keyNameId'>(
    {
      control: formInstance.control,
      name: formName as ArrayPath<T>,
      keyName: 'keyNameId',
      watcher: fieldArrayWatcher,
    },
  )

  return (
    <Container className={'p-0'}>
      {(municipalities as ArrayWithId<T, ArrayPath<T>>).map((municipality, index) => (
        <Row key={municipality.id}>
          <MunicipalityForm
            editMode={editMode}
            blockViewIsValidating={blockViewIsValidating}
            formName={`${formName}.${index}` as const}
            formInstance={formInstance}
            watcher={watcher}
            subscribableControl={subscribableControl}
            debouncedChangeValue={debouncedChangeValue}
            onChangeValue={onChangeValue}
          />
          {index !== municipalities.length - 1 && <Border />}
        </Row>
      ))}
    </Container>
  )
}

export default MunicipalitiesSection
