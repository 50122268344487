import { FC, memo, useRef } from 'react'
import { DropzoneRef } from 'react-dropzone'

import { letterHeadPartsBaseTexts } from '@constants/texts'

import { FileDropzoneSecondaryProps } from '../types'

import ItemDropZone from './ItemDropZone'
import LetterHeadFilledItem from './LetterHeadFilledItem'

const LetterHeadPartsDropItem: FC<FileDropzoneSecondaryProps> = ({
  title,
  fileState,
  fileActions,
  fileItemName,
}) => {
  const dropzoneRef = useRef<DropzoneRef | null>(null)

  const openFileDialog = () => {
    if (!dropzoneRef?.current) return

    dropzoneRef.current.open()
  }

  const bindDropzoneRef = (ref: DropzoneRef | null) => {
    if (!ref) return

    dropzoneRef.current = ref
  }

  return (
    <>
      {fileState.fileId && (
        <LetterHeadFilledItem
          state={fileState}
          title={title.filled}
          actions={{
            resetFile: fileActions.handleFileReset(fileItemName),
            setFileAlignment: fileActions.handleFileSetAlignment(fileItemName),
            reloadFile: openFileDialog,
            downloadFile: fileActions.handleFiledownload,
          }}
        />
      )}
      <ItemDropZone
        isPending={fileState.isLoading}
        isHided={!!fileState.fileId}
        bindDropzoneRef={bindDropzoneRef}
        title={title.empty}
        subtitle={letterHeadPartsBaseTexts.dropZoneText}
        openFileDialog={openFileDialog}
        dropZoneProps={{
          onDrop: fileActions.handleFileDrop(fileItemName),
          accept: {
            'image/jpeg': ['.jpeg', '.png'],
          },
          maxSize: 1024 * 300, //bytes
        }}
      />
    </>
  )
}

export default memo(LetterHeadPartsDropItem)
