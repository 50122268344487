import { useCallback } from 'react'

import { useLastUpdateFormDraft } from '@components/DocumentFormComponents/hooks/useLastUpdateFormDraft'
import DayjsService from '@services/Dayjs/Dayjs.service'

const useTimeUpdateForm = () => {
  const { lastUpdateDraftTime, handleChangeLastUpdateDraft } = useLastUpdateFormDraft()

  const handleSetLastUpdateFormToNow = useCallback(() => {
    handleChangeLastUpdateDraft(DayjsService.dayjsWithFormatToMSK().format('D\u00A0MMMM в HH:mm'))
  }, [handleChangeLastUpdateDraft])

  return {
    lastUpdateDraftTime,
    handleSetLastUpdateFormToNow,
  }
}

export { useTimeUpdateForm }
