import React, { memo } from 'react'

import OrganizationInfoLayout from '@components/OrganizationInfo'
import NotificationsOfRequisitesChanges from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/NotificationsOfRequisitesChanges'
import { RolesTypes } from '@constants/types'
import { checkRoleOnExistence } from '@context/AuthContext/workers/rolesWorkers'
import Layouts from '@layouts'

import styles from './OrganizationNew.module.scss'

const OrganizationNewPage = () => {
  const isOIV = checkRoleOnExistence(RolesTypes.OIV)
  const isMER = checkRoleOnExistence(RolesTypes.MER)
  const isINVESTOR = checkRoleOnExistence(RolesTypes.INVESTOR)
  const isOMSU = checkRoleOnExistence(RolesTypes.OMSU)

  return (
    <Layouts.Main>
      <div className={styles.layout}>
        <div className={styles.wrapper}>
          <OrganizationInfoLayout />
          {(isOIV || isMER || isINVESTOR || isOMSU) && <NotificationsOfRequisitesChanges />}
        </div>
      </div>
    </Layouts.Main>
  )
}

export default memo(OrganizationNewPage)
