import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'

const mapOfInformationOfExecutionAgreementEventsMenu = [
  { id: '1', title: 'Мероприятия этапов соглашения' },
]

const defaultInformationOfExecutionAgreementEventsFormValues: InformationOfExecutionAgreementEventsFormValues =
  {
    '1': {
      monitoringReportDate: '',
      stepsMonitoring: [],
    },
  }

const informationOfExecutionAgreementEventsBlockValues = {
  '1': {
    monitoringReportDate: '1.monitoringReportDate',
    stepsMonitoring: '1.stepsMonitoring',
  },
} as const

export {
  defaultInformationOfExecutionAgreementEventsFormValues,
  informationOfExecutionAgreementEventsBlockValues,
  mapOfInformationOfExecutionAgreementEventsMenu,
}
