import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof AAChangingVolumeReimbursementFormValues['4'],
  AAChangingVolumeReimbursementFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionValidationMap }
