import { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NoticeOfRejectConcludeSZPK from '@components/Forms/NoticeOfRejectConcludeSZPKForm'
import {
  defaultNoticeOfRejectConcludeSZPKFormValues,
  mapOfNoticeOfRejectConcludeSZPKMenu,
} from '@components/Forms/NoticeOfRejectConcludeSZPKForm/const'
import { NoticeOfRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/types'
import { NoticeOfRejectConcludeSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NoticeOfRejectConcludeSZPKLayoutWrapper from './Wrapper'

const NoticeOfRejectConcludeSZPKLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NoticeOfRejectConcludeSZPKFormValues>({
    defaultValues: defaultNoticeOfRejectConcludeSZPKFormValues,
  })

  const handleOnClose = () => {
    NoticeOfRejectConcludeSZPKFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NoticeOfRejectConcludeSZPK.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfNoticeOfRejectConcludeSZPKMenu[0].id}>
            <NoticeOfRejectConcludeSZPKLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об отказе в заключении СЗПК
                </Typography.Headline>
                <NoticeOfRejectConcludeSZPK.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}
              >
                <NoticeOfRejectConcludeSZPK.Menu className={styles.layout__menu} />
              </div>
            </NoticeOfRejectConcludeSZPKLayoutWrapper>
          </FlatMenuManager>
        </NoticeOfRejectConcludeSZPK.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NoticeOfRejectConcludeSZPKLayout)
