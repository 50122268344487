import React, { FC, memo, useCallback } from 'react'
import { useParams } from 'react-router'

import ReferenceBooks from '@components/ReferenceBooks'
import { DictionaryNameFromParams } from '@components/ReferenceBooks/constants'
import ReferenceBooksFlat from '@components/ReferenceBooks/Flat'
import { useReferenceBooksDynamicHeader } from '@components/ReferenceBooks/hooks/useReferenceBooksDynamicHeader'
import styles from '@components/ReferenceBooks/ReferenceBooks.module.scss'
import { useFlatEntrySidebar } from '@components/Sidebars/ReferenceBooks/FlatEntrySidebar'
import { useQueryManager } from '@hooks/useQueryManager'
import { useSWRConfig } from 'swr'

const MAIN_HEADER_HEIGHT = 64

interface ReferenceBooksFlatRootProps {
  titleText: string
}

const ReferenceBooksFlatRoot: FC<ReferenceBooksFlatRootProps> = ({ titleText }) => {
  const { mutate } = useSWRConfig()

  const { dictionaryName } = useParams<DictionaryNameFromParams>()
  const { queryUtils } = useQueryManager()

  const { handleOpenPartSidebar } = useFlatEntrySidebar()

  const { headerHeight, headerRef } = useReferenceBooksDynamicHeader([dictionaryName])

  const searchValue = queryUtils.getQuery('searchString') || ''

  const handleOpenSidebar = useCallback(() => {
    if (!dictionaryName) return

    handleOpenPartSidebar({
      dictionaryName,
      onSuccess: async () => {
        await mutate({
          dictionaryName,
          searchString: searchValue.length ? searchValue : undefined,
          _key: 'referenceBooks',
        })
      },
    })
  }, [dictionaryName, handleOpenPartSidebar, mutate, searchValue])

  const handleRevalidateOnSearchClear = useCallback(async () => {
    await mutate(
      {
        dictionaryName,
        _key: 'referenceBooks',
      },
      undefined,
      {
        revalidate: true,
      },
    )
  }, [dictionaryName, mutate])

  return (
    <ReferenceBooksFlat.Manager>
      <div className={styles.referenceBooks}>
        <header className={styles.referenceBooks__header} ref={headerRef}>
          <ReferenceBooks.Header
            titleText={titleText}
            buttonProps={{
              onClick: handleOpenSidebar,
            }}
            onSearchClear={handleRevalidateOnSearchClear}
          />
        </header>
        <div
          className={styles.referenceBooks__body}
          style={{
            height: `calc(100vh - ${MAIN_HEADER_HEIGHT + headerHeight}px)`,
          }}
        >
          <ReferenceBooksFlat.Grid />
        </div>
      </div>
    </ReferenceBooksFlat.Manager>
  )
}

export default memo(ReferenceBooksFlatRoot)
