import React, { FC, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import { TypeOfUnit } from '@components/NewDesign/Calendar/types'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { registrationReorgDefaultFormValues } from '@components/NewDesignedModals/ReorganizationRegistrationModal/const'
import { getDfoByType } from '@components/Projects/[id]/helpers'
import { objOfDateFormats } from '@constants/dateFormats'
import { NewDfosType } from '@constants/types'
import { defaultRHFValidation } from '@constants/validations'
import { useAPIContext } from '@context/APIContext'
import type { DefaultModalProps } from '@globalTypes/base'
import { isAxiosError } from '@helpers/checkTypes'
import { disableFutureDates, disablePastDates } from '@helpers/date/disableOfDates'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useBooleanState } from '@hooks/useBooleanState'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { IRegistrationBodyProps } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'
import { Dayjs } from 'dayjs'

import styles from './ReorganizationRegistrationModal.module.scss'

type ModalFormValues = Pick<Required<IRegistrationBodyProps>, 'registeredData'>['registeredData']

interface ReorganizationRegistrationProps {
  projectId: string
  actionId: string
  onSuccess: VoidFunction
  onError: (e: AxiosError<unknown>) => void
}

type ReorganizationRegistrationModalProps = PopupProps & ReorganizationRegistrationProps

const useReorganizationRegistrationModal = () => {
  const popupManager = usePopupManager()

  const handleOpenReorganizationRegModal = (
    props: ReorganizationRegistrationProps & DefaultModalProps,
  ) => {
    popupManager.open<ReorganizationRegistrationProps>(ReorganizationRegistrationModal, props)
  }

  return {
    handleOpenReorganizationRegModal,
  }
}

const ReorganizationRegistrationModal: FC<ReorganizationRegistrationModalProps> = ({
  isOpen,
  actionId,
  projectId,
  onClose,
  onSuccess,
  onError,
}) => {
  const {
    projectsApi: { createProjectRegistrate },
  } = useAPIContext()
  const { booleanState: requestIsLoading, setBooleanState: changeLoadingRequestState } =
    useBooleanState()

  const { projectDfos } = useProjectDfos({
    key: { projectId, _key: 'projectDfos' },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ReorganizationRegistrationModal',
          moduleName: 'useDocumentActions',
        },
      }),
    },
  })

  const SZPKDfo = useMemo(() => {
    if (!projectDfos) return

    return getDfoByType(projectDfos, NewDfosType.SZPK)
  }, [projectDfos])

  const { control, getValues, handleSubmit, formState } = useForm<ModalFormValues>({
    defaultValues: registrationReorgDefaultFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const preparedOnClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  const handleRegisterReorganization = useCallback(async () => {
    const { ORG_REORGANIZATION, SZPK_RIGHTS_TRANSFER } = getValues()
    const readyFormBody = {
      ORG_REORGANIZATION: {
        date: DayjsService.dayjs(ORG_REORGANIZATION.date, objOfDateFormats.defaultFormat).format(
          objOfDateFormats.defaultNativeDateFormat,
        ),
        number: ORG_REORGANIZATION.number,
      },
      SZPK_RIGHTS_TRANSFER: {
        date: DayjsService.dayjs(SZPK_RIGHTS_TRANSFER.date, objOfDateFormats.defaultFormat).format(
          objOfDateFormats.defaultNativeDateFormat,
        ),
        number: SZPK_RIGHTS_TRANSFER.number,
      },
    }

    changeLoadingRequestState(true)

    try {
      await createProjectRegistrate({
        actionId,
        projectId,
        registeredData: readyFormBody,
      })

      onSuccess()
    } catch (error) {
      if (!isAxiosError(error)) throw error

      onError(error)
    } finally {
      changeLoadingRequestState(false)
    }
  }, [
    actionId,
    changeLoadingRequestState,
    createProjectRegistrate,
    getValues,
    onError,
    onSuccess,
    projectId,
  ])

  const disabledCalendarDates = useCallback(
    (date: Dayjs, typeOfUnit?: TypeOfUnit) => {
      const dateEnd = typeOfUnit && DayjsService.dayjs(date).endOf(typeOfUnit)

      if (!SZPKDfo?.registeredDate) return disableFutureDates(date)

      return disableFutureDates(date) || disablePastDates(dateEnd || date, SZPKDfo.registeredDate)
    },
    [SZPKDfo],
  )

  const preparedActions = useMemo(() => {
    return [
      {
        dataTestId: 'ReorganizationRegistrationModal-cancel-button',
        children: 'Отмена',
        view: 'gray',
        disabled: requestIsLoading,
        onClick: preparedOnClose,
      },
      {
        dataTestId: 'ReorganizationRegistrationModal-registerReorganization-button',
        children: 'Продолжить',
        disabled: requestIsLoading || !formState.isValid,
        onClick: handleSubmit(handleRegisterReorganization),
        loaderProps: {
          loading: requestIsLoading,
          variant: 'lite',
          placement: 'trailing',
        },
      },
    ] as ButtonProps[]
  }, [
    formState.isValid,
    handleRegisterReorganization,
    handleSubmit,
    preparedOnClose,
    requestIsLoading,
  ])

  return (
    <Modal.Action
      isOpen={isOpen}
      title={'Внесите дополнительные соглашения в реестр'}
      simpleModalContainerClassName={styles.modal}
      dataTestId="ReorganizationRegistrationModal-modal"
      closeButtonDataTestId="ReorganizationRegistrationModal-modal-closeButton"
      actions={preparedActions}
      onClose={preparedOnClose}
    >
      <div className={styles.modal__content}>
        <div className={styles['modal__content-row']}>
          <Typography.Caption variant={'captionSRegular'}>
            Доп. соглашение{' '}
            <Typography as={'span'} fontWeight={'semibold'} variant={'captionSRegular'}>
              в связи с реорганизацией организации, реализующей проект
            </Typography>
          </Typography.Caption>
          <div className={styles['modal__content-inputs']}>
            <ControlledInput
              name={'ORG_REORGANIZATION.number'}
              control={control}
              rules={{
                required: defaultRHFValidation.required,
              }}
              inputProps={{
                disabled: requestIsLoading,
                rootClassName: styles['modal__content-item'],
                view: 'secondary',
                label: 'Регистрационный номер',
                fixWidth: true,
                dataTestId: 'ReorganizationRegistrationModal-ORG_REORGANIZATION.number-input',
              }}
            />
            <ControlledCalendarInput
              name={'ORG_REORGANIZATION.date'}
              control={control}
              rules={{
                required: defaultRHFValidation.required,
              }}
              calendarInputProps={{
                disabled: requestIsLoading,
                wrapperClassName: styles['modal__content-item'],
                size: 'xl',
                view: 'secondary',
                label: 'Дата внесения в реестр',
                dataTestId: 'ReorganizationRegistrationModal-ORG_REORGANIZATION.date-calendarInput',
              }}
              calendarProps={{
                disabled: requestIsLoading,
                disabledDate: disabledCalendarDates,
              }}
              tooltipProps={{
                position: 'bottom-start',
              }}
            />
          </div>
        </div>
        <div className={styles['modal__content-row']}>
          <Typography.Caption variant={'captionSRegular'}>
            Доп. соглашение{' '}
            <Typography as={'span'} fontWeight={'semibold'} variant={'captionSRegular'}>
              о передаче прав и обязанностей по соглашению
            </Typography>
          </Typography.Caption>
          <div className={styles['modal__content-inputs']}>
            <ControlledInput
              name={'SZPK_RIGHTS_TRANSFER.number'}
              control={control}
              rules={{
                required: defaultRHFValidation.required,
              }}
              inputProps={{
                disabled: requestIsLoading,
                rootClassName: styles['modal__content-item'],
                view: 'secondary',
                label: 'Регистрационный номер',
                fixWidth: true,
                dataTestId: 'ReorganizationRegistrationModal-SZPK_RIGHTS_TRANSFER.number-input',
              }}
            />
            <ControlledCalendarInput
              name={'SZPK_RIGHTS_TRANSFER.date'}
              control={control}
              rules={{
                required: defaultRHFValidation.required,
              }}
              calendarInputProps={{
                disabled: requestIsLoading,
                wrapperClassName: styles['modal__content-item'],
                size: 'xl',
                view: 'secondary',
                label: 'Дата внесения в реестр',
                dataTestId:
                  'ReorganizationRegistrationModal-SZPK_RIGHTS_TRANSFER.date-calendarInput',
              }}
              calendarProps={{
                disabled: requestIsLoading,
                disabledDate: disabledCalendarDates,
              }}
              tooltipProps={{
                position: 'bottom-start',
              }}
            />
          </div>
        </div>
      </div>
    </Modal.Action>
  )
}

export { useReorganizationRegistrationModal }
export default ReorganizationRegistrationModal
