import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import type { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import {
  agreementStabilizationNegotiationsBlockValues,
  mapOfAgreementStabilizationNegotiationsMenu,
} from '@components/Forms/АgreementStabilizationNegotiationsForm/const'
import AgreementStabilizationNegotiationsForms from '@components/Forms/АgreementStabilizationNegotiationsForm/Forms'
import { useAgreementStabilizationNegotiationsManager } from '@components/Forms/АgreementStabilizationNegotiationsForm/Manager'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'

const mapOfAgreementStabilizationNegotiationsForms: FormItemProps = {
  '1': {
    node: <AgreementStabilizationNegotiationsForms.One />,
  },
  '2': {
    node: <AgreementStabilizationNegotiationsForms.Two />,
  },
  '3': {
    node: <AgreementStabilizationNegotiationsForms.Third />,
  },
  '4': {
    node: <AgreementStabilizationNegotiationsForms.Four />,
  },
  '5': {
    node: <AgreementStabilizationNegotiationsForms.Five />,
  },
} as const

const AgreementStabilizationNegotiationsForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AgreementStabilizationNegotiationsFormValues>()

  const {
    state: { formIsLoading },
  } = useAgreementStabilizationNegotiationsManager()

  const isFederalSZPK = useWatch({
    name: agreementStabilizationNegotiationsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: agreementStabilizationNegotiationsBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAgreementStabilizationNegotiationsForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={initialMenu ?? mapOfAgreementStabilizationNegotiationsMenu}
      mapOfForms={currentForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(AgreementStabilizationNegotiationsForm)
