import { useCallback } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import CreateProcessModal, { CreateProcessModalProps } from '.'

const useCreateProcessModal = () => {
  const popupManager = usePopupManager()

  const handleOpenCreateProcessModal = useCallback(
    ({
      actionId,
      projectId,
      onCreateProcess,
      onErrorProcess,
      onClose,
    }: CreateProcessModalProps & PopupProps) => {
      popupManager.open(CreateProcessModal, {
        projectId,
        actionId,
        onCreateProcess,
        onErrorProcess,
        onClose: onClose ?? (() => null),
      })
    },
    [popupManager],
  )

  return {
    handleOpenCreateProcessModal,
  }
}

export default useCreateProcessModal
