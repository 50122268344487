import { generatePropertiesObjectForNoticeOfProjectRefuseConcludeAAForm } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNoticeOfProjectRefuseConcludeAAForm } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/adapters/RHF.adapter'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'

const useNoticeOfProjectRefuseConcludeAAAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NoticeOfProjectRefuseConcludeAAFormValues =>
    generateRHFObjectForNoticeOfProjectRefuseConcludeAAForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNoticeOfProjectRefuseConcludeAAForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNoticeOfProjectRefuseConcludeAAAdapters }
