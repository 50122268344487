import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import Chip from '@components/Chip'
import { compact } from '@helpers/array/compact'
import { isArray } from '@helpers/checkTypes'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import cn from 'classnames'

import Input from '../Input'
import { Tooltip } from '../Tooltip'

import styles from './MultipleInput.module.scss'
import { ManualMultipleInputProps, MultipleInputProps, MultipleInputValue } from './types'

const MAX_LENGTH_CHIP_NAME = 10

export const transformValueToMultipleInputValue = (value: string | string[]) => {
  if (!Array.isArray(value)) {
    return [
      {
        displayValue: value,
        value,
      },
    ]
  }

  if (!value || !compact(value).length) return null

  return value.map((item) => ({
    displayValue: item,
    value: item,
  }))
}

const MultipleInput = forwardRef<HTMLInputElement, MultipleInputProps | ManualMultipleInputProps>(
  (
    {
      labelWrapperClassName,
      labelTextClassName,
      className,
      defaultValue,
      staticWidth,
      error,
      parseByString = '',
      chipNameLengthToShowTooltip,
      maxChipsLength = 20,
      keyboardButton = 'Enter',
      inputProps = {
        view: 'secondary',
      },
      chipProps = {
        size: 'xs',
        variant: 'accent',
        priority: 'secondary',
      },
      onFocus,
      onRemoveChip,
      onBlur,
      onChange,
      onAddChip,

      manualValuesControl,
      values,

      onCustomValidate,
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false)
    const [inputValueState, setInputValue] = useState<string>('')
    const [addedValuesState, setAddedValues] = useState<MultipleInputValue[] | undefined>(
      defaultValue,
    )

    const inputValue = inputValueState ?? inputProps.value

    /*
      Если управление добавленными значениями идет извне, то синхронизируем
    */
    const addedValues = manualValuesControl ? values : addedValuesState

    const isActiveDuplicateValue =
      addedValues &&
      inputValue &&
      addedValues?.some((selectedValue) => selectedValue.value === inputValue)

    const canAddValue = addedValues?.length !== maxChipsLength

    const filled = Boolean(inputValue || addedValues?.length)

    const currentError =
      error?.message || (isActiveDuplicateValue && 'такое значение уже добавлено')

    const addValue = (value: MultipleInputValue) => {
      const changedValue = addedValues ? [...addedValues, value] : [value]

      if (manualValuesControl) return onAddChip?.(changedValue, value)

      setAddedValues(changedValue)

      onAddChip?.(changedValue, value)
    }

    const removeAddedValue = useCallback(
      (value: MultipleInputValue) => {
        const changedValue = addedValues?.filter((addedValue) => addedValue.value !== value.value)

        if (manualValuesControl) return onRemoveChip?.(changedValue || [], value)

        setAddedValues(changedValue)

        if (changedValue) {
          onRemoveChip?.(changedValue, value)
        }
      },
      [addedValues, manualValuesControl, onRemoveChip],
    )

    const changedValueHandler = (parsedValue: MultipleInputValue) => {
      addValue(parsedValue)

      setInputValue('')
    }

    const preparedOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      const isTargetButtonPressed = Array.isArray(keyboardButton)
        ? keyboardButton.includes(e.code)
        : e.code === keyboardButton

      const parsedValue = (
        parseByString ? inputValue.replace(parseByString, '') : inputValue
      )?.trim()

      const customValidationCondition = onCustomValidate ? onCustomValidate(parsedValue) : true

      const isValueInvalid =
        addedValues?.includes({
          displayValue: parsedValue,
          value: parsedValue,
        }) || !customValidationCondition

      if (isTargetButtonPressed) {
        if (parsedValue && customValidationCondition && !isValueInvalid) {
          e.preventDefault()

          changedValueHandler({
            displayValue: parsedValue,
            value: parsedValue,
          })
        }
      }
    }

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = e

      const parserStringLength = parseByString.length
      const isParserStringFound =
        parserStringLength > 0 &&
        value.substring(value.length - parserStringLength) === parseByString

      if (isParserStringFound) {
        const parsedValue = value.replace(parseByString, '')?.trim()

        const customValidationCondition = onCustomValidate ? onCustomValidate(parsedValue) : true

        const isValueInvalid =
          !parsedValue ||
          addedValues?.includes({
            displayValue: parsedValue,
            value: parsedValue,
          }) ||
          !customValidationCondition

        if (!isValueInvalid) {
          changedValueHandler({ displayValue: parsedValue, value: parsedValue })
        }

        onChange?.(e, { displayValue: value, value })
      } else {
        setInputValue(value)

        onChange?.(e, { displayValue: value, value })
      }
    }

    const handleInputFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setFocused(true)

        onFocus?.(event)
      },
      [onFocus],
    )

    const onBlurInput = (event: FocusEvent<HTMLInputElement>) => {
      onBlur?.(event)

      const value = event.currentTarget.value
      const parsedValue = (parseByString ? value.replace(parseByString, '') : value)?.trim()

      const customValidationCondition = onCustomValidate ? onCustomValidate?.(value) : true

      const isValueInvalid =
        addedValues?.includes({
          displayValue: parsedValue,
          value: parsedValue,
        }) ||
        !customValidationCondition ||
        !parsedValue

      setFocused(false)

      if (!isValueInvalid) {
        return changedValueHandler({
          displayValue: parsedValue,
          value: parsedValue,
        })
      }

      onChange?.(
        event,
        parsedValue ? { displayValue: parsedValue, value: parsedValue } : addedValues || [],
      )
    }

    useEffect(() => {
      if (!addedValues?.length) setFocused(false)
    }, [addedValues])

    const chip = useCallback(
      (value?: MultipleInputValue, hideClose?: boolean) => {
        if (!value) return null

        return (
          <Chip.Base
            key={value.value}
            dataTestId={`Chip-item-${value.value}`}
            iconDataTestId={`Chip-removeButton-${value.value}`}
            className={styles.addedValue}
            label={value.displayValue}
            disabled={inputProps.disabled}
            contentClassName={cn(styles.addedValue__content, chipProps.contentClassName, {
              [styles.addedValue__static]: staticWidth && !hideClose,
            })}
            onClose={hideClose || inputProps.disabled ? undefined : () => removeAddedValue(value)}
            {...chipProps}
          />
        )
      },
      [chipProps, inputProps.disabled, removeAddedValue, staticWidth],
    )

    const chipWithTooltip = useCallback(
      (value?: MultipleInputValue, hideClose?: boolean) => {
        if (!value) return null

        const renderedChip = chip(value, hideClose)

        if (!renderedChip) return null

        return (
          <Tooltip
            key={value.value}
            content={value.displayValue}
            position={'top'}
            targetClassName={styles.tooltip__target}
          >
            {renderedChip}
          </Tooltip>
        )
      },
      [chip],
    )

    // элементы без первого индекса
    const [, ...addedValuesAtSecond] = staticWidth && addedValues ? addedValues : []

    const tooltipContent = useMemo(
      () => addedValuesAtSecond?.map((value) => chip(value)),
      [addedValuesAtSecond, chip],
    )

    const handleDisablePropagation = (e) => e.stopPropagation()

    const chipCounterTooltipProps = useMemo(
      () => ({
        onClick: handleDisablePropagation,
      }),
      [],
    )

    // чип с тултипом для чипа со счетчиком, вида +1
    const chipCounterWithTooltip = useMemo(
      () =>
        addedValues && (
          <Tooltip
            popoverClassName={styles.tooltip}
            contentProps={chipCounterTooltipProps}
            content={tooltipContent}
            position={'bottom'}
            closeDelay={150}
            offset={[0, 5]}
            zIndex={53}
          >
            {chip(
              { displayValue: `+${addedValues.length - 1}`, value: `+${addedValues.length - 1}` },
              true,
            ) ?? <></>}
          </Tooltip>
        ),
      [addedValues, chip, chipCounterTooltipProps, tooltipContent],
    )

    // чип с тултипом для длинного названия
    const chipContentWithTooltip = useMemo(() => {
      if (addedValues && addedValues?.[0]?.displayValue.length > MAX_LENGTH_CHIP_NAME) {
        return (
          <Tooltip content={addedValues[0].displayValue} position={'top'}>
            {chip(addedValues[0]) ?? <></>}
          </Tooltip>
        )
      }

      return chip(addedValues?.[0])
    }, [addedValues, chip])

    // сгруппированный чип со счетчиком
    const chipContentWithCounter = useMemo(() => {
      if (addedValues && addedValues?.length > 1) {
        return [chipContentWithTooltip, chipCounterWithTooltip]
      }

      return chipContentWithTooltip
    }, [addedValues, chipContentWithTooltip, chipCounterWithTooltip])

    const computedAddedValues = useMemo(() => {
      if (!addedValues || !addedValues.length) return null

      return addedValues?.map((value) =>
        !chipNameLengthToShowTooltip || value.displayValue.length < chipNameLengthToShowTooltip
          ? chip(value)
          : chipWithTooltip(value),
      )
    }, [addedValues, chip, chipNameLengthToShowTooltip, chipWithTooltip])

    const chipContent = useMemo(
      () => (staticWidth ? chipContentWithCounter : computedAddedValues),
      [chipContentWithCounter, computedAddedValues, staticWidth],
    )

    useEffectAfterMount(() => {
      if (!isArray(defaultValue)) return

      setAddedValues(defaultValue)
    }, [defaultValue, defaultValue?.length])

    const inputProperties = {
      value: inputValue,
      disabled: !canAddValue,
      onChange: onChangeInput,
      onFocus: handleInputFocus,
      onBlur: onBlurInput,
      onKeyDown: preparedOnKeyDown,
      caption: !canAddValue ? 'Добавлено максимальное количество значений' : '',
      error: currentError,
      chipContent: chipContent,
      ref,
      ...inputProps,

      labelTextClassName: cn(labelTextClassName, {
        [styles.inputLabelText]: addedValues?.length,
      }),
      labelWrapperClassName: cn(labelWrapperClassName, {
        [styles.inputLabelWrapper]: addedValues?.length,
        [styles.label__transformedY]: focused || filled,
      }),
      controlClassName: cn(
        inputProps.controlClassName,
        styles.inputContainerWeight,
        styles.inputContainer,
        {
          [styles.inputContainerWithValue]: addedValues?.length,
        },
      ),
    }

    return (
      <div className={styles.wrapper}>
        <div className={cn(styles.inputWrapper, className)}>
          <Input
            {...inputProperties}
            wrapperClassName={cn(inputProperties.wrapperClassName, {
              [styles.static]: staticWidth,
            })}
          />
        </div>
      </div>
    )
  },
)

export default MultipleInput
