import React, {
  Children,
  cloneElement,
  ComponentProps,
  FC,
  isValidElement,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import Container from '@components/Layout/Container'
import Menu from '@components/Layout/Header/Menu'
import Logo from '@components/Layout/Logo'
import Wrapper from '@components/Layout/Wrapper'
import NavigationPanel from '@components/NavigationPanel'
import BurgerButton from '@components/NewDesign/BurgerButton'
import MobileMenu from '@components/NewDesign/MobileMenu'
import { isLoginByCrypt } from '@constants/system'
import { useScrollState } from '@hooks/new/scroll/useScrollState'
import { useBooleanState } from '@hooks/useBooleanState'
import useMediaQuery from '@hooks/useMediaQuery'
import { usePaths } from '@hooks/usePaths'
import cn from 'classnames'

import styles from './Header.module.scss'
export interface IHeader {
  children: ReactNode
  logoProps?: Partial<ComponentProps<typeof Logo>>
}

const KNOWLEDGE_DESKTOP_RESOLUTION = '(min-width: 1024px)'

const Header: FC<IHeader> = ({ logoProps, children }) => {
  const { isHome } = usePaths()

  const isDesktopMediaMatches = useMediaQuery(KNOWLEDGE_DESKTOP_RESOLUTION)

  const [sticky, setSticky] = useState(false)
  const { booleanState: mobileMenuIsOpen, setBooleanState: setMobileMenuIsOpen } = useBooleanState()
  const { handleBlockScroll, handleUnblockScroll } = useScrollState()

  const headerRef = useRef<HTMLDivElement>(null)

  const isNeedShowBackToMain = !isHome && isLoginByCrypt

  const handleChangeMobileMenu = () => {
    if (mobileMenuIsOpen) {
      handleUnblockScroll()
    } else {
      handleBlockScroll()
    }

    setMobileMenuIsOpen((prev) => !prev)
  }

  const handleCloseMobileMenu = () => {
    if (!mobileMenuIsOpen) return

    handleUnblockScroll()

    setMobileMenuIsOpen(false)
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (!headerRef.current || !isDesktopMediaMatches) return

      const scrollObj = headerRef.current.getBoundingClientRect()
      setSticky(scrollObj.y <= -100)
    }

    window.addEventListener('load', handleScroll)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('load', handleScroll)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div ref={headerRef}>
      <Wrapper>
        <Container>
          <div className={styles.header}>
            <div
              className={cn(styles.container, {
                [styles.stickyLeft]: sticky,
              })}
            >
              <Logo
                position="center"
                {...logoProps}
                sticky={logoProps?.sticky && sticky}
                withRedirectOnClick={isLoginByCrypt}
                onLogoClick={handleCloseMobileMenu}
              />
              {isNeedShowBackToMain && isDesktopMediaMatches && (
                <div className={styles.back}>
                  <NavigationPanel sticky={sticky} />
                </div>
              )}
            </div>
            <div
              className={cn(styles.container, {
                [styles.stickyRight]: sticky,
              })}
            >
              <Menu
                sticky={sticky}
                className={cn(styles.menu, {
                  [styles.stickyPosition]: sticky,
                })}
              >
                {isLoginByCrypt &&
                  Children.map(children, (child) => {
                    if (!isValidElement(child)) return null

                    return cloneElement(child, {
                      ...child.props,
                      sticky,
                    })
                  })}
              </Menu>
            </div>
            {!isDesktopMediaMatches && (
              <BurgerButton isOpen={mobileMenuIsOpen} onClick={handleChangeMobileMenu} />
            )}
          </div>
        </Container>
      </Wrapper>
      {!isDesktopMediaMatches && (
        <MobileMenu isOpen={mobileMenuIsOpen} onLinkClick={handleChangeMobileMenu} />
      )}
    </div>
  )
}

export default Header
