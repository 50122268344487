import React from 'react'
import { useParams } from 'react-router'

import DfosManager from '@components/Projects/[id]/DfosManager'
import HeaderCollapseManager from '@components/Projects/[id]/HeaderCollapse'
import UiControlManager from '@components/UiControl'
import { useOrganizationInfo } from '@hooks/new/swr/useOrganizationInfo'
import ProjectStructure from '@routes/Projects/[id]/ProjectStructure'
import { withPermissionsProtect } from '@routes/Projects/shared/ProjectPermissionManager'

const ProjectLayout = () => {
  const { projectId } = useParams()

  //Ревалидация пользователя при входе на страницу проекта
  useOrganizationInfo({
    key: { _key: 'organizationInfo' },
    config: {
      revalidateOnMount: true,
    },
  })

  return (
    <DfosManager key={projectId}>
      <UiControlManager>
        <HeaderCollapseManager>
          <ProjectStructure />
        </HeaderCollapseManager>
      </UiControlManager>
    </DfosManager>
  )
}

export default withPermissionsProtect(ProjectLayout)
