import { useCallback } from 'react'

import { generatePropertiesObjectForNpaForm } from '@components/Forms/NpaForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNpaForm } from '@components/Forms/NpaForm/adapters/RHF.adapter'
import { NpaFormValues } from '@components/Forms/NpaForm/types'

const useNpaFormAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): NpaFormValues =>
      generateRHFObjectForNpaForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForNpaForm(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generatePropertiesObject }
}

export { useNpaFormAdapters }
