import { useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import PasswordChangeModal from './PasswordChangeModal'
import type { HandleOpenPasswordChangeModal } from './types'

const usePasswordChangeModal = (onSuccess: () => Promise<void>) => {
  const popupManager = usePopupManager()

  const handleOpenPasswordChangeModal: HandleOpenPasswordChangeModal = useCallback(
    ({ userId }) =>
      (event) => {
        event.stopPropagation()

        popupManager.open(PasswordChangeModal, {
          onClose: () => {},
          userId,
          onSuccess,
        })
      },
    [onSuccess, popupManager],
  )

  return {
    handleOpenPasswordChangeModal,
  }
}

export default usePasswordChangeModal
