import { FC } from 'react'
import { Path, useFormContext, useWatch } from 'react-hook-form'

import DocumentDataView from '@components/DocumentFormComponents/DocumentDataView'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import NoDataFieldFallback from '@components/DocumentFormComponents/NoDataFieldFallback'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { useIndicatorsOfUnfulfilledAgreementReportManager } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/Manager'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'
import { IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/watcher'
import Stack from '@components/ReactBootstrap/Stack'

import styles from './StepDeadlineItemRow.module.scss'

interface StepDeadlineItemRowProps {
  rowTitle: string
  startDateFormName: Path<IndicatorsOfUnfulfilledAgreementReportFormValues>
  endDateFormName: Path<IndicatorsOfUnfulfilledAgreementReportFormValues>
}

const StepDeadlineItemRow: FC<StepDeadlineItemRowProps> = ({
  rowTitle,
  startDateFormName,
  endDateFormName,
}) => {
  const formInstance = useFormContext<IndicatorsOfUnfulfilledAgreementReportFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useIndicatorsOfUnfulfilledAgreementReportManager()

  const { getControllerProps, getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher,
  })

  const startDateValue = useWatch({
    name: startDateFormName,
    control: formInstance.control,
  })

  const endDateValue = useWatch({
    name: endDateFormName,
    control: formInstance.control,
  })

  const isStartDateAndEndDateNotExist = !startDateValue && !endDateValue

  return (
    <RowWithBorder
      alignByTop
      disableTitleTopPadding
      disableBottomDefaultStyles
      title={rowTitle}
      firstColumnSize={6}
      secondColumnSize={6}
    >
      <Stack direction={'horizontal'} gap={0} className={styles.stepDeadlineItemRow__stack}>
        {!isStartDateAndEndDateNotExist ? (
          <>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: startDateFormName,
              })}
            >
              <ControlledDocumentDataView
                hideSuptitle
                disableContainerBorder
                disableContainerPaddingTop
                disableContainerPaddingBottom
                contentPosition="right"
                fallbackValue={<NoDataFieldFallback />}
                controllerProps={getControllerProps({
                  name: startDateFormName,
                })}
              />
            </SubscribableControl>

            <span className={styles['stepDeadlineItemRow__dates-dash']}>—</span>

            <SubscribableControl
              {...getSubscribableControlProps({
                path: endDateFormName,
              })}
            >
              <ControlledDocumentDataView
                hideSuptitle
                disableContainerBorder
                disableContainerPaddingTop
                disableContainerPaddingBottom
                contentPosition="right"
                fallbackValue={<NoDataFieldFallback />}
                controllerProps={getControllerProps({
                  name: endDateFormName,
                })}
                formIconTooltipProps={{
                  tooltipContent: 'по данным СЗПК',
                  iconPosition: 'right',
                }}
              />
            </SubscribableControl>
          </>
        ) : (
          <DocumentDataView
            hideSuptitle
            disableContainerBorder
            disableContainerPaddingTop
            disableContainerPaddingBottom
            contentPosition="right"
            formIconTooltipProps={{
              tooltipContent: 'по данным СЗПК',
              iconPosition: 'right',
            }}
          >
            <NoDataFieldFallback />
          </DocumentDataView>
        )}
      </Stack>
    </RowWithBorder>
  )
}

export default StepDeadlineItemRow
