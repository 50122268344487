import { ComponentType, ReactNode } from 'react'

import { isFunction } from '@helpers/checkTypes'

type ConditionType<Props> = boolean | ((props: Props) => boolean)

interface WithConditionWrappedComponentRenderingProps {
  children: ReactNode
}

const withConditionWrappedComponentRendering =
  <Props,>(
    WrappedComponent: ComponentType<Props>,
    condition: ConditionType<Props>,
  ) =>
  (props: Props & WithConditionWrappedComponentRenderingProps) => {
    const { children } = props

    const currentCondition = isFunction(condition) ? condition(props) : condition

    if (!currentCondition) return <>{children}</>

    return <WrappedComponent {...props}>{children}</WrappedComponent>
  }

export default withConditionWrappedComponentRendering
