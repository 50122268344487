import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { StepsCompensationPathName } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/Forms/5/types'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

import { indicatorsOfUnfulfilledAgreementReportBlockValues } from '../../const'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '../../types'
import { IndicatorsOfUnfulfilledAgreementReportFieldArrayControlUpdateWatcher } from '../../watcher'

import StepCompensationItem from './AdditionalForms/StepCompensationItem'

const Fifth = () => {
  const formInstance = useFormContext<IndicatorsOfUnfulfilledAgreementReportFormValues>()

  useFieldArraySubscribableControl<
    IndicatorsOfUnfulfilledAgreementReportFormValues,
    StepsCompensationPathName
  >({
    name: indicatorsOfUnfulfilledAgreementReportBlockValues['5'].stepsCompensation,
    control: formInstance.control,
    watcher: IndicatorsOfUnfulfilledAgreementReportFieldArrayControlUpdateWatcher,
  })

  const stepsCompensation = useWatch({
    name: indicatorsOfUnfulfilledAgreementReportBlockValues['5'].stepsCompensation,
    control: formInstance.control,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        {stepsCompensation.map((stepCompensation, stepCompensationIndex) => (
          <StepCompensationItem
            stepCompensationDescription={stepCompensation.stepCompensationDescription}
            stepCompensationNumber={stepCompensation.stepCompensationNumber}
            stepCompensationDatesInfo={stepCompensation.stepCompensationDatesInfo}
            key={stepCompensationIndex}
            isLastItem={stepCompensationIndex === stepsCompensation.length - 1}
            formName={
              `${indicatorsOfUnfulfilledAgreementReportBlockValues['5'].stepsCompensation}.${stepCompensationIndex}` as const
            }
          />
        ))}
      </Stack>
    </Container>
  )
}

export default Fifth
