import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForIndicatorsOfUnfulfilledAgreementReportForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const stepsIndicatorsValue = objectFromPropertyState.stepsIndicators?.value || []
  const stepsMonitoringValue = objectFromPropertyState.stepsMonitoring?.value || []
  const isStepsMonitoringArrayOrIndicatorsArrayInvalid =
    !isArray(stepsIndicatorsValue) ||
    !isArray(stepsMonitoringValue) ||
    !stepsMonitoringValue.length ||
    !stepsIndicatorsValue.length

  const findStepMonitoringItemByStepIndicatorsItem = (stepIndicatorsItem) =>
    isArray(stepsMonitoringValue) &&
    stepsMonitoringValue.find(
      (stepMonitoringItem) =>
        stepIndicatorsItem?.value?.planStepId?.permanentId ===
        stepMonitoringItem?.value?.planStepId?.permanentId,
    )

  const getPreparedStepsDeadline = () => {
    if (isStepsMonitoringArrayOrIndicatorsArrayInvalid) return []

    return stepsIndicatorsValue.map((stepIndicatorsItem) => {
      const currentStepMonitoring = findStepMonitoringItemByStepIndicatorsItem(stepIndicatorsItem)

      return {
        factStepStartDate: {
          propertyId: currentStepMonitoring?.value?.factStepStartDate?.propertyId || '',
          type: currentStepMonitoring?.value?.factStepStartDate?.type || '',
          lastUpdateDt: currentStepMonitoring?.value?.factStepStartDate?.lastUpdateDt || '',
        },
        factStepEndDate: {
          propertyId: currentStepMonitoring?.value?.factStepEndDate?.propertyId || '',
          type: currentStepMonitoring?.value?.factStepEndDate?.type || '',
          lastUpdateDt: currentStepMonitoring?.value?.factStepEndDate?.lastUpdateDt || '',
        },
      }
    })
  }

  const getPreparedStepsCompensation = () => {
    if (isStepsMonitoringArrayOrIndicatorsArrayInvalid) return []

    return stepsIndicatorsValue.map((stepIndicatorsItem) => {
      const currentStepMonitoringValue =
        findStepMonitoringItemByStepIndicatorsItem(stepIndicatorsItem)?.value

      const stepCompensationDatesInfo =
        stepIndicatorsItem?.value?.stepIndicatorCompensations?.value?.map(
          (stepIndicatorCompensation) => ({
            stepCompensationEndDate: {
              propertyId:
                stepIndicatorCompensation?.value?.stepIndicatorCompensationEndDate?.propertyId ||
                '',
              type: stepIndicatorCompensation?.value?.stepIndicatorCompensationEndDate?.type || '',
              lastUpdateDt:
                stepIndicatorCompensation?.value?.stepIndicatorCompensationEndDate?.lastUpdateDt ||
                '',
            },
          }),
        )

      return {
        propertyId: stepIndicatorsItem?.propertyId,
        type: stepIndicatorsItem?.type,
        lastUpdateDt: stepIndicatorsItem?.lastUpdateDt,
        value: {
          factStepCompensationValue: {
            propertyId: currentStepMonitoringValue?.factStepCompensationValue?.propertyId,
            type: currentStepMonitoringValue?.factStepCompensationValue?.type,
            lastUpdateDt: currentStepMonitoringValue?.factStepCompensationValue?.lastUpdateDt,
          },
          stepCompensationDatesInfo,
        },
      }
    })
  }

  return {
    '1': {
      investorCapitalMadeOnTimeIndicator: {
        propertyId: objectFromPropertyState.investorCapitalMadeOnTimeIndicator?.propertyId,
        type: objectFromPropertyState.investorCapitalMadeOnTimeIndicator?.type,
        lastUpdateDt: objectFromPropertyState.investorCapitalMadeOnTimeIndicator?.lastUpdateDt,
      },
      investorCapital: {
        propertyId: objectFromPropertyState.investorCapital?.propertyId,
        type: objectFromPropertyState.investorCapital?.type,
        lastUpdateDt: objectFromPropertyState.investorCapital?.lastUpdateDt,
      },
      investorCapitalVAT: {
        propertyId: objectFromPropertyState.investorCapitalVAT?.propertyId,
        type: objectFromPropertyState.investorCapitalVAT?.type,
        lastUpdateDt: objectFromPropertyState.investorCapitalVAT?.lastUpdateDt,
      },
      factInvestorCapitalVatIndicator: {
        propertyId: objectFromPropertyState.factInvestorCapitalVatIndicator?.propertyId,
        type: objectFromPropertyState.factInvestorCapitalVatIndicator?.type,
        lastUpdateDt: objectFromPropertyState.factInvestorCapitalVatIndicator?.lastUpdateDt,
      },
      factInvestorCapitalIndicator: {
        propertyId: objectFromPropertyState.factInvestorCapitalIndicator?.propertyId,
        type: objectFromPropertyState.factInvestorCapitalIndicator?.type,
        lastUpdateDt: objectFromPropertyState.factInvestorCapitalIndicator?.lastUpdateDt,
      },
      madeInvestorCapitalDate: {
        propertyId: objectFromPropertyState.madeInvestorCapitalDate?.propertyId,
        type: objectFromPropertyState.madeInvestorCapitalDate?.type,
        lastUpdateDt: objectFromPropertyState.madeInvestorCapitalDate?.lastUpdateDt,
      },
      monitoringReportDate: {
        propertyId: objectFromPropertyState.monitoringReportDate?.propertyId,
        type: objectFromPropertyState.monitoringReportDate?.type,
        lastUpdateDt: objectFromPropertyState.monitoringReportDate?.lastUpdateDt,
      },
    },
    '2': {
      totalCapital: {
        propertyId: objectFromPropertyState.totalCapital?.propertyId,
        type: objectFromPropertyState.totalCapital?.type,
        lastUpdateDt: objectFromPropertyState.totalCapital?.lastUpdateDt,
      },
      factTotalCapitalIndicator: {
        propertyId: objectFromPropertyState.factTotalCapitalIndicator?.propertyId,
        type: objectFromPropertyState.factTotalCapitalIndicator?.type,
        lastUpdateDt: objectFromPropertyState.factTotalCapitalIndicator?.lastUpdateDt,
      },
    },
    '3': {
      newWorkPlaces: {
        propertyId: objectFromPropertyState.newWorkPlaces?.propertyId,
        type: objectFromPropertyState.newWorkPlaces?.type,
        lastUpdateDt: objectFromPropertyState.newWorkPlaces?.lastUpdateDt,
      },
      factNewWorkPlace: {
        propertyId: objectFromPropertyState.factNewWorkPlace?.propertyId,
        type: objectFromPropertyState.factNewWorkPlace?.type,
        lastUpdateDt: objectFromPropertyState.factNewWorkPlace?.lastUpdateDt,
      },
    },
    '4': {
      stepsDeadline: getPreparedStepsDeadline(),
    },
    '5': {
      stepsCompensation: getPreparedStepsCompensation(),
    },
  }
}

export { generatePropertiesObjectForIndicatorsOfUnfulfilledAgreementReportForm }
