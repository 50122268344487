import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { applicationConclusionAdditionalAgreementFormBlockValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/const'
import { secondSectionValidationMap } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/Forms/2/validation'
import { useApplicationConclusionAdditionalAgreementFormManager } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/Manager'
import { ApplicationConclusionAdditionalAgreementFormFormValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/types'
import { ApplicationConclusionAdditionalAgreementFormFieldsControlUpdateWatcher } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Second = () => {
  const formInstance = useFormContext<ApplicationConclusionAdditionalAgreementFormFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useApplicationConclusionAdditionalAgreementFormManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ApplicationConclusionAdditionalAgreementFormFieldsControlUpdateWatcher,
  })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункт части 6 статьи 11 69-ФЗ на основании которого планируется заключение дополнительного соглашения'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: applicationConclusionAdditionalAgreementFormBlockValues['2']
                    .ruleLinkExtraReason,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: applicationConclusionAdditionalAgreementFormBlockValues['2']
                      .ruleLinkExtraReason,
                    rules: secondSectionValidationMap.ruleLinkExtraReason,
                    inputProps: {},
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          applicationConclusionAdditionalAgreementFormBlockValues['2']
                            .ruleLinkExtraReason,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          applicationConclusionAdditionalAgreementFormBlockValues['2']
                            .ruleLinkExtraReason,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
