import Icon from '@components/Icon'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import ErrorOutline from '@icons/alert/error_outline.svg'

import styles from './StaleActionsModal.module.scss'

export const StaleActionsModal = ({ isOpen, onUpdate, onClose }) => {
  const handleUpdateAndCloseModal = () => {
    onUpdate()
    onClose()
  }

  const updateAction: ButtonProps = {
    children: 'Обновить данные',
    geometry: 'square',
    view: 'filled',
    fixWidth: true,
    onClick: handleUpdateAndCloseModal,
  }

  return (
    <Modal.Action
      hideCloseIcon
      isOpen={isOpen}
      actions={[updateAction]}
      title={<Icon noCurrentColorSvgFill src={ErrorOutline} className={styles.modalIcon} />}
      simpleModalHeaderClassName={styles.modalHeader}
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      actionModalContentClassName={styles.actionModalBody}
      onClose={handleUpdateAndCloseModal}
    >
      <Typography variant={'headlineH3'} align={'center'}>
        Выполнить это действие невозможно
      </Typography>
      <Typography className={styles.bodyText} variant={'bodyMRegular'} align={'center'}>
        Так как используемые данные устарели или&nbsp;возникла техническая ошибка. Пожалуйста,
        обновите&nbsp;данные и повторите попытку
      </Typography>
    </Modal.Action>
  )
}
