import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { IDfoNewList } from '@services/Dfo/Dfo.entity'
import DfoService from '@services/Dfo/Dfo.service'
import useSWRImmutable from 'swr/immutable'

const useProjectDfos = ({ key, config }: SWRHookProps<IDfoNewList[] | undefined>) => {
  const {
    projectsApi: { getProjectDfosById },
  } = useAPIContext()

  const {
    data: projectDfos,
    error,
    mutate,
    isValidating: isLoadingProjectDfos,
  } = useSWRImmutable(key, ({ projectId }) => getProjectDfosById(projectId), config)

  const flattedProjectDfos = useMemo(
    () => DfoService.getAllDfosFromGroupAndSubgrops(projectDfos || []),
    [projectDfos],
  )

  return {
    projectDfos: flattedProjectDfos,
    projectDfosWithGroup: projectDfos,
    error,
    mutate,
    isLoadingProjectDfos,
  }
}

export { useProjectDfos }
