import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'
import { parseDateString } from '@components/NewDesign/DateInput/DateInput'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

const sixthSectionAAgreementAccessionMunicipalValidationMap: FormValuesValidationSection<
  keyof AAgreementAccessionMunicipalFormValues['6'],
  AAgreementAccessionMunicipalFormValues
> = {
  ruleLinkMunicipalNpa: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  extraMunicipalAccessionLinkStabilization: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  extraMunicipalAccessionLinkCompensation: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  dateApproveMO: {
    required: defaultRHFValidation.required,
    validate: {
      positiveCurrentDate: (value) => {
        if (!isString(value)) return

        const currentDate = DayjsService.dayjsWithFormatToMSK()

        return (
          (isDateValidForDayjs(value) &&
            DayjsService.dayjs(value, objOfDateFormats.defaultFormat) <= currentDate) ||
          'дата должна быть меньше или равна текущей дате'
        )
      },
    },
  },
}

export { sixthSectionAAgreementAccessionMunicipalValidationMap }
