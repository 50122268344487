import React from 'react'
import { useFormContext } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { projectResultEcoActivityValidationMap } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/9/validation'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import ControllerTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const MeasuresNegativeImpactForm = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const {
    getFormFieldControlProps,
    getTextareaProps,
    getCalendarInputProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    isNew: false,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={8}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                .ecoActivityName,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityName,
                    )
                  }, 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      value: item.id,
                      displayValue: item.name,
                    }),

                    controllerProps: {
                      name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityName,
                      rules: projectResultEcoActivityValidationMap.ecoActivityName,
                    },
                    selectProps: {
                      withContextSearch: true,
                      inputProps: {
                        label: 'Наименование мероприятий',
                        dataTestId: 'ecoActivityName',
                      },
                      onChangeFormValue: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                              .ecoActivityName,
                          )
                        }, 0),
                    },
                  })}
                />
              )
            }}
          </FormFieldControl>
        </Col>
        <Col xs={4}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                .ecoActivityEndDate,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityEndDate,
                    )
                  }, 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                  .ecoActivityEndDate,
                rules: projectResultEcoActivityValidationMap.ecoActivityEndDate,
                calendarInputProps: {
                  label: 'Дата реализации',
                  dataTestId: 'ecoActivityEndDate',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityEndDate,
                    )
                  }, 0),
                onCalendarChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityEndDate,
                    )
                  }, 0),
                onInputChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityEndDate,
                    )
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                .ecoActivityProgramNameInstrumental,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityProgramNameInstrumental,
                    )
                  }, 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControllerTextarea
              {...getTextareaProps({
                name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                  .ecoActivityProgramNameInstrumental,
                rules: projectResultEcoActivityValidationMap.ecoActivityProgramNameInstrumental,
                textareaProps: {
                  label: 'Наименование и реквизиты программы',
                  caption: 'в творительном падеже',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityProgramNameInstrumental,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .ecoActivityProgramNameInstrumental,
                    )
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
    </Stack>
  )
}

export default MeasuresNegativeImpactForm
