import { useRef } from 'react'

import { KeyedMutator } from 'swr'

export const useBlockingOrgs = <ID = string>(updateList: KeyedMutator<any>) => {
  const blockingInProcess = useRef<Set<ID>>(new Set())

  const addOrg = (id: ID) => {
    blockingInProcess.current.add(id)

    updateList(
      // TODO: swr + immer mb
      (prev) => {
        return !prev
          ? prev
          : {
              ...prev,
              organizations: prev.organizations.map((org) =>
                id === org.id ? { ...org, isBlocking: true } : org,
              ),
            }
      },
      { revalidate: false },
    )
  }

  const removeOrg = (id: ID) => {
    blockingInProcess.current.delete(id)

    updateList(
      (prev) => {
        return !prev
          ? prev
          : {
              ...prev,
              organizations: prev.organizations.map((org) =>
                id === org.id ? { ...org, isBlocking: false } : org,
              ),
            }
      },
      { revalidate: false },
    )
  }

  return {
    addOrg,
    removeOrg,
    blockingInProcess,
  }
}
