import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForParametersApplicationReimbursementExpensesForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      szpkNumber: {
        propertyId: objectFromPropertyState.szpkNumber?.propertyId,
        type: objectFromPropertyState.szpkNumber?.type,
        lastUpdateDt: objectFromPropertyState.szpkNumber?.lastUpdateDt,
      },
      szpkRegistrationDate: {
        propertyId: objectFromPropertyState.szpkRegistrationDate?.propertyId,
        type: objectFromPropertyState.szpkRegistrationDate?.type,
        lastUpdateDt: objectFromPropertyState.szpkRegistrationDate?.lastUpdateDt,
      },
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
    },
    '3': {
      compensationsFacts: {
        propertyId: objectFromPropertyState.compensationFacts?.propertyId,
        type: objectFromPropertyState.compensationFacts?.type,
        lastUpdateDt: objectFromPropertyState.compensationFacts?.lastUpdateDt,
        value: isArray(objectFromPropertyState?.compensationFacts?.value)
          ? objectFromPropertyState?.compensationFacts?.value.map((compensationsFact) => ({
              propertyId: compensationsFact.propertyId,
              type: compensationsFact.propertyId,
              lastUpdateDt: compensationsFact.lastUpdateDt,
              value: {
                compensationFactYear: {
                  propertyId: compensationsFact?.value?.compensationFactYear?.propertyId,
                  type: compensationsFact.value?.compensationFactYear?.type,
                  lastUpdateDt: compensationsFact.value?.compensationFactYear?.lastUpdateDt,
                },
                compensationFactFormValue: {
                  propertyId: compensationsFact.value?.compensationFactFormValue?.propertyId,
                  type: compensationsFact.value?.compensationFactFormValue?.type,
                  lastUpdateDt: compensationsFact.value?.compensationFactFormValue?.lastUpdateDt,
                },
                compensationFactExpenseGroupValue: {
                  propertyId:
                    compensationsFact.value?.compensationFactExpenseGroupValue?.propertyId,
                  type: compensationsFact.value?.compensationFactExpenseGroupValue?.type,
                  lastUpdateDt:
                    compensationsFact.value?.compensationFactExpenseGroupValue?.lastUpdateDt,
                },
                compensationFactObjects: {
                  propertyId: compensationsFact.value?.compensationFactObjects?.propertyId,
                  type: compensationsFact.value?.compensationFactObjects?.type,
                  lastUpdateDt: compensationsFact.value?.compensationFactObjects?.lastUpdateDt,
                  value: isArray(compensationsFact.value?.compensationFactObjects?.value)
                    ? compensationsFact.value?.compensationFactObjects?.value.map(
                        (compensationObject) => ({
                          propertyId: compensationObject.propertyId,
                          type: compensationObject.type,
                          lastUpdateDt: compensationObject.lastUpdateDt,
                          value: {
                            compensationFactObject: {
                              propertyId:
                                compensationObject.value?.compensationFactObjectId?.propertyId,
                              type: compensationObject.value?.compensationFactObjectId?.type,
                              lastUpdateDt:
                                compensationObject.value?.compensationFactObjectId?.lastUpdateDt,
                            },
                            compensationFactObjectOther: {
                              propertyId:
                                compensationObject.value?.compensationFactObjectOther?.propertyId,
                              type: compensationObject.value?.compensationFactObjectOther?.type,
                              lastUpdateDt:
                                compensationObject.value?.compensationFactObjectOther?.lastUpdateDt,
                            },
                          },
                        }),
                      )
                    : [],
                },
                compensationFactSum: {
                  propertyId: compensationsFact.value?.compensationFactSum?.propertyId,
                  type: compensationsFact.value?.compensationFactSum?.type,
                  lastUpdateDt: compensationsFact.value?.compensationFactSum?.lastUpdateDt,
                },
                compensationFactDate: {
                  propertyId: compensationsFact.value?.compensationFactDate?.propertyId,
                  type: compensationsFact.value?.compensationFactDate?.type,
                  lastUpdateDt: compensationsFact.value?.compensationFactDate?.lastUpdateDt,
                },
              },
            }))
          : [],
      },
    },
  }
}

export { generatePropertiesObjectForParametersApplicationReimbursementExpensesForm }
