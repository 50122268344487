import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import FinancialModelLayout from './Layout'
import FinancialModelModalManager from './Manager'
import FinancialModelMenu from './Menu'
import FinancialModelStatementForm from './Statement'

interface IFinancialModel extends FC {
  Layout: typeof FinancialModelLayout
  Menu: typeof FinancialModelMenu
  StatementForm: typeof FinancialModelStatementForm
  Manager: typeof FinancialModelModalManager
}

const useFinancialModelControl = () => {
  const popupManager = usePopupManager()

  const openFinancialModelModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(FinancialModelLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openFinancialModelModal }
}

const FinancialModel: IFinancialModel = () => null

FinancialModel.Menu = FinancialModelMenu
FinancialModel.Layout = FinancialModelLayout
FinancialModel.StatementForm = FinancialModelStatementForm
FinancialModel.Manager = FinancialModelModalManager

export { useFinancialModelControl }
export default FinancialModel
