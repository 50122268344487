import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { defaultRHFValidation, standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const investmentsValidation: FormValuesValidationSection<
  keyof StatementFormValues['participantsAndInvestments'],
  StatementFormValues
> = {
  projectDecisionDate: ({ form }) => ({
    required: defaultRHFValidation.required,
    onChange: () => {
      form.trigger(statementBlockValues.stage.agreementEndDate)
    },
  }),

  investorCapitalVat: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  totalCapital: {
    required: defaultRHFValidation.required,

    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  totalCapitalVat: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { investmentsValidation }
