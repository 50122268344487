import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { financialModelBlockValues } from '@components/Forms/FinancialModelForm/const'
import StageTaxYear from '@components/Forms/FinancialModelForm/Forms/4/Forms/StageTaxYears/StageTaxYear'
import { useFinancialModelManager } from '@components/Forms/FinancialModelForm/Manager'
import styles from '@components/Forms/FinancialModelForm/Statement/FinancialModelForm.module.scss'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import {
  FinancialModelFieldArrayControlUpdateWatcher,
  FinancialModelFieldsControlUpdateWatcher,
} from '@components/Forms/FinancialModelForm/watcher'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import cn from 'classnames'

const { isFormFieldError } = DocumentFormHelpers

const StageTaxYears: FC = () => {
  const formInstance = useFormContext<FinancialModelFormValues>()

  const { fields: stageTaxYears } = useFieldArraySubscribableControl<
    FinancialModelFormValues,
    '4.stageTaxYears',
    'id'
  >({
    control: formInstance?.control,
    name: financialModelBlockValues['4'].stageTaxYears,
    watcher: FinancialModelFieldArrayControlUpdateWatcher,
    keyName: 'id',
  })

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useFinancialModelManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: FinancialModelFieldsControlUpdateWatcher,
  })

  const conditionToRenderExploitationStageTaxYears = !!stageTaxYears.length

  const stageTaxYearsError = formInstance.getFieldState(
    financialModelBlockValues['4'].stageTaxYears,
  )?.error

  return (
    <Container className={'p-0'}>
      {isFormFieldError(stageTaxYearsError) && (
        <FormError rowClassName={'px-0.5'} colClassName={'px-1'}>
          {stageTaxYearsError.message}
        </FormError>
      )}
      {!conditionToRenderExploitationStageTaxYears && (
        <Row
          className={cn(styles.form__item, {
            [styles['form__item--withError']]: isFormFieldError(stageTaxYearsError),
          })}
        >
          <Col xs={12}>
            <Typography.Body variant={'bodyMMedium'}>
              Укажите год начала инвестиционной стадии и дату окончания реализации проекта
              (указывается в Заявлении о заключении СЗПК)
            </Typography.Body>
            <Typography.Body variant={'bodyMRegular'}>
              После этого вы сможете указать объемы налогов по годам
            </Typography.Body>
          </Col>
        </Row>
      )}
      <CollapseWrapper
        defaultExpanded={conditionToRenderExploitationStageTaxYears}
        isExpanded={conditionToRenderExploitationStageTaxYears}
      >
        <Stack direction={'vertical'} gap={2}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: financialModelBlockValues['4'].stageTaxYears,
            })}
          >
            {stageTaxYears.map((item, index) => (
              <StageTaxYear key={item.id} indexOfStage={index} />
            ))}
          </SubscribableControl>
        </Stack>
      </CollapseWrapper>
    </Container>
  )
}

export default StageTaxYears
