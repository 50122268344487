import { useMemo } from 'react'

import { useRegions } from '@hooks/new/swr/useRegions'

export const useRegionsToSelect = () => {
  const { regions } = useRegions({
    key: { _key: 'projectRegions' },
  })

  const regionsToSelect = useMemo(() => {
    if (!regions) return []

    return Object.entries(regions).map(([id, value]) => ({ displayValue: value, value: id }))
  }, [regions])

  return regionsToSelect
}
