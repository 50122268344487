import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { AssignmentMonetaryOrPledgeAFormValues } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/types'

const generateRHFObjectForAssignmentMonetaryOrPledgeAForm = (
  objectFromPropertyState: Record<string, any>,
): AssignmentMonetaryOrPledgeAFormValues => {
  return {
    '1': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      ruleLinkCession: objectFromPropertyState.ruleLinkCession.value || '',
      cessionValue: objectFromPropertyState.cessionValue.value || '',
      cessionFormName: objectFromPropertyState.cessionForm.value?.id
        ? {
            value: objectFromPropertyState.cessionForm.value?.id || '',
            displayValue: objectFromPropertyState.cessionForm.value?.name || '',
          }
        : null,
      cessionReason: objectFromPropertyState.cessionReason.value || '',
      cessionMember: objectFromPropertyState.cessionMember.value || '',
      cessionAvailability: objectFromPropertyState.cessionAvailability.value || '',
      cessionDate: objectFromPropertyState.cessionDate.value || '',
    },
  } as AssignmentMonetaryOrPledgeAFormValues
}

export { generateRHFObjectForAssignmentMonetaryOrPledgeAForm }
