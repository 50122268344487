import React, { DetailedHTMLProps, FC, memo } from 'react'

import Icon from '@components/Icon'
import Typography from '@components/NewDesign/Typography'
import SearchFallbackIcon from '@icons/SearchFallbackIcon.svg'
import cn from 'classnames'

import styles from './NoDataPlaceholder.module.scss'

type NoDataPlaceholder = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const NoDataPlaceholder: FC<NoDataPlaceholder> = ({ ...divRest }) => {
  return (
    <div {...divRest} className={cn(styles.noDataPlaceholder, divRest.className)}>
      <Icon
        noCurrentColorSvgFill
        src={SearchFallbackIcon}
        className={styles.noDataPlaceholder__icon}
        size={'initial'}
      />
      <div className={styles.noDataPlaceholder__content}>
        <Typography.Headline variant={'headlineH2'} fontWeight={'normal'}>
          Ничего не найдено
        </Typography.Headline>
      </div>
    </div>
  )
}

export default memo(NoDataPlaceholder)
