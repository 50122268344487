import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { InclusionAgreementOfRelatedSZPKRegisterFormValues } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/types'

const generateRHFObjectForInclusionAgreementOfRelatedSZPKRegisterForm = (
  objectFromPropertyState: Record<string, any>,
): InclusionAgreementOfRelatedSZPKRegisterFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      projectName: objectFromPropertyState.projectName?.value || '',
    },
  }
}

export { generateRHFObjectForInclusionAgreementOfRelatedSZPKRegisterForm }
