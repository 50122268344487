import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnChangingCharacteristicsOfObjectsBlockValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import ObjectAreasList from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectAreasList'
import ObjectRegionsList from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectRegionsList'
import { eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/validation'
import {
  ConstructionObjectsArrayPathName,
  ConstructionSubObjectsArrayPathName,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/types'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface ObjectFormItemProps {
  isShowObjectItemContent: boolean
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAgreementOnChangingCharacteristicsOfObjectsFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>
  formName: ConstructionObjectsArrayPathName | ConstructionSubObjectsArrayPathName
  isParentObject?: boolean
}

const ObjectFormItem: FC<ObjectFormItemProps> = ({
  isParentObject = false,
  isShowObjectItemContent,
  blockViewIsValidating,
  editMode,
  formInstance,
  formModifierInstance,
  formName,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formModifierInstance.control,
  })

  const isCompensationRenderCondition = useWatch({
    name: `${formName}.objectIsInfrastructure`,
    control: formInstance.control,
  })

  const isOldCreatedRenderCondition = useWatch({
    name: `${formName}.oldIsCapitalObject`,
    control: formInstance.control,
  })

  const isPaper = useWatch({
    name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues.additionalFields.isPaper,
    control: formInstance.control,
  })

  const {
    getSubscribableControlProps,
    getControllerProps,
    getInputProps,
    getTextareaProps,
    getSwitchProps,
    getSingleSelectProps,
    getAmountInputProps,
    getFormFieldControlProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
  })

  return (
    <Container className={'p-0'}>
      <Col xs={12}>
        {!isShowObjectItemContent && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectName`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Наименование'}
                  controllerProps={getControllerProps({
                    name: `${formName}.objectName`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <CollapseWrapper
          defaultExpanded={isShowObjectItemContent}
          isExpanded={isShowObjectItemContent}
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectName`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.objectName`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectName,
                      textareaProps: {
                        label: 'Наименование',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={
                              'Информация о характеристиках объекта вносится в соответствии с РНС, ГПЗУ, проектом планировки территории или иным документом'
                            }
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectName`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <ObjectRegionsList
              blockViewIsValidating={blockViewIsValidating}
              editMode={editMode}
              formInstance={formInstance}
              formModifierInstance={formModifierInstance}
              formName={formName}
            />
            {isParentObject && (
              <ObjectAreasList
                editMode={editMode}
                blockViewIsValidating={blockViewIsValidating}
                formInstance={formInstance}
                formModifierInstance={formModifierInstance}
                formName={formName as ConstructionObjectsArrayPathName}
              />
            )}
            <Row>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.objectConstructionKind`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.objectConstructionKind`),
                        0,
                      ),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.objectConstructionKind`,
                    rules:
                      eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectConstructionKind,
                    textareaProps: {
                      label: 'Вид объекта капитального строительства',
                      caption: 'например: здание, сооружение, строение',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.objectConstructionKind`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.objectConstructionKind`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Row>
            <Row>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.objectPurpose`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.objectPurpose`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.objectPurpose`,
                    rules:
                      eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectPurpose,
                    textareaProps: {
                      label: 'Назначение объекта',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.objectPurpose`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.objectPurpose`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Row>
            <Row>
              <Col xs={6}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectConstructionSquare`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.objectConstructionSquare`),
                          0,
                        ),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectConstructionSquare`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectConstructionSquare,
                      inputProps: {
                        label: 'Площадь застройки, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectConstructionSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectConstructionSquare`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
              <Col xs={6}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectSquare`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.objectSquare`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectSquare`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectSquare,
                      inputProps: {
                        label: 'Площадь, кв.м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectSquare`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectNonLivingAreaSquare`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.objectNonLivingAreaSquare`),
                          0,
                        ),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectNonLivingAreaSquare`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectNonLivingAreaSquare,
                      inputProps: {
                        label: 'Площадь нежилых помещений, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
              <Col xs={6}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectLivingAreaSquare`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.objectLivingAreaSquare`),
                          0,
                        ),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectLivingAreaSquare`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectLivingAreaSquare,
                      inputProps: {
                        label: 'Площадь жилых помещений, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectFloor`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.objectFloor`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.objectFloor`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectFloor,
                      inputProps: {
                        label: 'Количество этажей',
                        caption: 'если применимо',
                        suffix: '',
                        maxLength: 10,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectFloor`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectFloor`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
              <Col xs={6}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectUndergroundFloor`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.objectUndergroundFloor`),
                          0,
                        ),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.objectUndergroundFloor`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectUndergroundFloor,
                      inputProps: {
                        label: 'в том числе количество подземных',
                        caption: 'если применимо',
                        suffix: '',
                        maxLength: 10,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectUndergroundFloor`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectUndergroundFloor`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectHeight`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.objectHeight`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectHeight`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectHeight,
                      inputProps: {
                        label: 'Высота, м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectHeight`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectHeight`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.objectDetails`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.objectDetails`), 0),
                    },
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.objectDetails`,
                      rules:
                        eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectDetails,
                      textareaProps: {
                        label: 'Иные характеристики объекта',
                        caption: 'если применимо',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectDetails`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectDetails`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Stack direction={'vertical'} gap={2}>
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: `${formName}.objectIsInfrastructure`,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(
                            () => handleChangeValue?.(`${formName}.objectIsInfrastructure`),
                            0,
                          ),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledSwitch
                      {...getSwitchProps({
                        name: `${formName}.objectIsInfrastructure`,
                        switchProps: {
                          wrapperClassName: styles.form__switch,
                          label: 'Является объектом инфраструктуры',
                        },
                        onChange: () => handleChangeValue?.(`${formName}.objectIsInfrastructure`),
                      })}
                    />
                  </FormFieldControl>
                  <CollapseWrapper
                    defaultExpanded={isCompensationRenderCondition}
                    isExpanded={isCompensationRenderCondition}
                  >
                    <Stack direction={'vertical'} gap={3}>
                      <Col xs={12}>
                        <FormFieldControl
                          {...getFormFieldControlProps({
                            path: `${formName}.objectInfrastructureType`,
                            formFieldTooltipProps: {
                              onChange: () =>
                                setTimeout(
                                  () => handleChangeValue?.(`${formName}.objectInfrastructureType`),
                                  0,
                                ),
                              onDifference: handleUpdateChanges,
                            },
                          })}
                        >
                          {({ overrideProps }) => {
                            const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                              ? overrideProps.fetcherOptions
                              : undefined

                            return (
                              <ControlledFormSingleSelect
                                {...getSingleSelectProps({
                                  fetcherProps,
                                  optionsAdapter: (item) => ({
                                    displayValue: item.name,
                                    value: item.id,
                                  }),
                                  controllerProps: {
                                    name: `${formName}.objectInfrastructureType`,
                                    rules:
                                      eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectInfrastructureType,
                                  },
                                  selectProps: {
                                    inputProps: {
                                      label: 'Тип инфраструктуры',
                                    },
                                    onChangeFormValue: () =>
                                      setTimeout(() => {
                                        handleChangeValue?.(`${formName}.objectInfrastructureType`)
                                      }),
                                  },
                                })}
                              />
                            )
                          }}
                        </FormFieldControl>
                      </Col>
                    </Stack>
                  </CollapseWrapper>
                </Stack>
              </Col>
            </Row>

            {isPaper && (
              <Row>
                <Col xs={12}>
                  <Stack direction={'vertical'} gap={2}>
                    <FormFieldControl
                      {...getFormFieldControlProps({
                        path: `${formName}.oldIsCapitalObject`,
                        formFieldTooltipProps: {
                          onChange: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.oldIsCapitalObject`),
                              0,
                            ),
                          onDifference: handleUpdateChanges,
                        },
                      })}
                    >
                      <ControlledSwitch
                        {...getSwitchProps({
                          name: `${formName}.oldIsCapitalObject`,
                          switchProps: {
                            wrapperClassName: styles.form__switch,
                            label: 'Является объектом капитального строительства',
                          },
                          onChange: () => handleChangeValue?.(`${formName}.oldIsCapitalObject`),
                        })}
                      />
                    </FormFieldControl>
                    <CollapseWrapper
                      defaultExpanded={!isOldCreatedRenderCondition}
                      isExpanded={!isOldCreatedRenderCondition}
                    >
                      <Stack direction={'vertical'} gap={3}>
                        <Row>
                          <Col xs={12}>
                            <FormFieldControl
                              {...getFormFieldControlProps({
                                path: `${formName}.oldCreatedDocument`,
                                formFieldTooltipProps: {
                                  onChange: () =>
                                    setTimeout(
                                      () => handleChangeValue?.(`${formName}.oldCreatedDocument`),
                                      0,
                                    ),
                                  onDifference: handleUpdateChanges,
                                },
                              })}
                            >
                              <ControlledTextarea
                                {...getTextareaProps({
                                  name: `${formName}.oldCreatedDocument`,
                                  rules:
                                    eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.oldCreatedDocument,

                                  textareaProps: {
                                    label:
                                      'Документ, подтверждающий строительство, реконструкцию, модернизацию',
                                    caption: 'в творительном падеже',
                                  },
                                  onBlur: () =>
                                    setTimeout(() => {
                                      handleChangeValue?.(`${formName}.oldCreatedDocument`)
                                    }, 0),
                                  onChange: () =>
                                    setTimeout(() => {
                                      debouncedHandleChangeValue?.(`${formName}.oldCreatedDocument`)
                                    }, 0),
                                })}
                              />
                            </FormFieldControl>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormFieldControl
                              {...getFormFieldControlProps({
                                path: `${formName}.oldCreatedOrganization`,
                                formFieldTooltipProps: {
                                  onChange: () =>
                                    setTimeout(
                                      () =>
                                        handleChangeValue?.(`${formName}.oldCreatedOrganization`),
                                      0,
                                    ),
                                  onDifference: handleUpdateChanges,
                                },
                              })}
                            >
                              <ControlledTextarea
                                {...getTextareaProps({
                                  name: `${formName}.oldCreatedOrganization`,
                                  rules:
                                    eObjectAAgreementOnChangingCharacteristicsOfObjectsValidationMap.oldCreatedOrganization,

                                  textareaProps: {
                                    label: 'Орган, уполномоченный на выдачу',
                                    caption: 'в творительном падеже',
                                  },
                                  onBlur: () =>
                                    setTimeout(() => {
                                      handleChangeValue?.(`${formName}.oldCreatedOrganization`)
                                    }, 0),
                                  onChange: () =>
                                    setTimeout(() => {
                                      debouncedHandleChangeValue?.(
                                        `${formName}.oldCreatedOrganization`,
                                      )
                                    }, 0),
                                })}
                              />
                            </FormFieldControl>
                          </Col>
                        </Row>
                      </Stack>
                    </CollapseWrapper>
                  </Stack>
                </Col>
              </Row>
            )}
          </Stack>
        </CollapseWrapper>
      </Col>
    </Container>
  )
}

export default ObjectFormItem
