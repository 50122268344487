import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import styles from '@components/DocumentFormComponents/FormStyles/FormController.module.scss'
import mainTemplateStyles from '@components/DocumentFormComponents/FormStyles/MainTemplate.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aaVolumeOfCapitalInvestmentsBlockValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/const'
import OldStepExpenses from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/AdditionalForms/MainTemplate/FormController/OldStepExpenses'
import OldStepInvestorCapitalSources from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/AdditionalForms/MainTemplate/FormController/OldStepInvestorCapitalSources'
import { fifthSectionValidationMap } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/AdditionalForms/MainTemplate/FormController/validation'
import { FifthCapitalStepsArrayPathName } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/types'
import { useAAVolumeOfCapitalInvestmentsManager } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Manager'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

interface FormControllerProps {
  name: FifthCapitalStepsArrayPathName
}

const FormController: FC<FormControllerProps> = ({ name }) => {
  const formInstance = useFormContext<AAVolumeOfCapitalInvestmentsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAVolumeOfCapitalInvestmentsManager()

  const isPaper = useWatch({
    name: aaVolumeOfCapitalInvestmentsBlockValues.additionalFields.isPaper,
    control: formInstance.control,
  })

  const { getSubscribableControlProps, getAmountInputProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <div className={styles.templateController}>
      <Col xs={12}>
        <Row className={styles.templateController__construction}>
          <Row className={mainTemplateStyles.mainTemplate__container}>
            <Row className={'px-2'}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Текущее значение объема капиталовложений по этапу'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.stepInvestorCapitalValueCommited`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.stepInvestorCapitalValueCommited`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Row>
          </Row>
        </Row>
      </Col>
      <Col xs={12}>
        <Row className={styles.templateController__construction}>
          <Row className={mainTemplateStyles.mainTemplate__container}>
            <Row className={'px-2'}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Измененное значение объема капиталовложений по этапу'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.stepInvestorCapitalValue`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.stepInvestorCapitalValue`,
                      rules: fifthSectionValidationMap.stepInvestorCapitalValue,
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${name}.stepInvestorCapitalValue`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.stepInvestorCapitalValue`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Row>
          </Row>
        </Row>
      </Col>
      {isPaper && (
        <Col xs={12}>
          <Row className={styles.templateController__construction}>
            <Row className={mainTemplateStyles.mainTemplate__container}>
              <Row className={'px-2'}>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  firstColumnSize={8}
                  secondColumnSize={4}
                  title={'Текущий срок осуществления капиталовложений'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.stepInvestorCapitalDateCommited`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${name}.stepInvestorCapitalDateCommited`,
                        calendarInputProps: {
                          disabled: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
              </Row>
            </Row>
          </Row>
        </Col>
      )}
      {isPaper && (
        <Col xs={12}>
          <Row className={styles.templateController__construction}>
            <Row className={mainTemplateStyles.mainTemplate__container}>
              <Row className={'px-2'}>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  firstColumnSize={8}
                  secondColumnSize={4}
                  title={'Измененный срок осуществления капиталовложений'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.stepInvestorCapitalDate`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${name}.stepInvestorCapitalDate`,
                        rules: fifthSectionValidationMap.stepInvestorCapitalDate,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${name}.stepInvestorCapitalDate`)
                          }, 0),
                        onInputChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${name}.stepInvestorCapitalDate`)
                          }, 0),
                        onCalendarChange: () =>
                          setTimeout(
                            () => handleChangeValue?.(`${name}.stepInvestorCapitalDate`),
                            0,
                          ),
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
              </Row>
            </Row>
          </Row>
        </Col>
      )}
      {isPaper && (
        <Col xs={12}>
          <Row className={styles.templateController__construction}>
            <Row className={mainTemplateStyles.mainTemplate__container}>
              <Row className={'px-2'}>
                <OldStepInvestorCapitalSources
                  blockViewIsValidating={blockViewIsValidating}
                  editMode={editMode}
                  formInstance={formInstance}
                  formName={`${name}.oldStepInvestorCapitalSources`}
                />
              </Row>
            </Row>
          </Row>
        </Col>
      )}
      {isPaper && (
        <Col xs={12}>
          <Row className={styles.templateController__construction}>
            <Row className={mainTemplateStyles.mainTemplate__container}>
              <Row className={'px-2'}>
                <OldStepExpenses
                  blockViewIsValidating={blockViewIsValidating}
                  editMode={editMode}
                  formInstance={formInstance}
                  formName={`${name}.oldStepExpenses`}
                  parentStepName={name}
                />
              </Row>
            </Row>
          </Row>
        </Col>
      )}
    </div>
  )
}

export default FormController
