import { memo, useCallback, VFC } from 'react'

import LoginItem from '@components/Forms/LoginForm/shared/LoginItem'
import Typography from '@components/NewDesign/Typography/Typography'
import type { Procuration } from '@context/AuthContext/types'
import { formatDate } from '@helpers/format'
import { ComponentInfo } from '@services/LoggerService/LoggerService.entity'
import cn from 'classnames'

import { procurationComponentInfo, signSZPKpermission } from './const'
import styles from './Procurations.module.scss'

interface ProcurationProps {
  procuration: Procuration
  onChooseProcuration: (
    MChDNumber: string,
    organizationINN: string,
    procurationComponentInfo: ComponentInfo,
  ) => void
  className?: string
}

const Procurations: VFC<ProcurationProps> = ({ procuration, onChooseProcuration, className }) => {
  const { number, organizationINN, authority, organizationName, endDate } = procuration

  const hasSignRight = authority?.some((item) => item.includes(signSZPKpermission))

  const handleClick = useCallback(() => {
    onChooseProcuration(number, organizationINN, procurationComponentInfo)
  }, [onChooseProcuration, number, organizationINN])

  return (
    <LoginItem
      className={cn(styles.wrapper, className)}
      dataTestId={`Procurations-procuration-${number}-button`}
      iconButtonDataTestId={`Procurations-procuration-${number}-iconButton`}
      header={
        <div className={styles.head__text}>
          {organizationName && (
            <Typography.Headline variant="headlineH3">{organizationName}</Typography.Headline>
          )}
          <Typography.Body
            variant="bodyMRegular"
            className={cn(hasSignRight ? styles.colorGreen : styles.colorGray)}
          >
            {hasSignRight ? 'С правом подписи' : 'Без права подписи'}
          </Typography.Body>
        </div>
      }
      footer={
        <div className={styles.bottom}>
          <div>
            {organizationINN && (
              <Typography.Body variant="bodyMRegular" className={styles.colorGray}>
                ИНН: {organizationINN}
              </Typography.Body>
            )}
          </div>
          {endDate && (
            <Typography.Body
              variant="bodyMRegular"
              className={styles.colorGray}
            >{`действует до ${formatDate(endDate)}`}</Typography.Body>
          )}
        </div>
      }
      onClick={handleClick}
    />
  )
}

export default memo(Procurations)
