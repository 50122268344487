import React, { FC } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import ErrorModal from '@components/NewDesignedModals/ErrorModal'
import { documentsLinks } from '@constants/documents'

import styles from './WarningRegistrateTerminate.module.scss'

interface WarningRegistrationActions {
  onConfirm: () => void
  onCancel: () => void
}

type WarningRegistrationTerminateModalProps = Required<PopupProps> & WarningRegistrationActions

const useWarningRegistrationTerminateModal = () => {
  const popupManager = usePopupManager()

  const handleOpenWarningRegistrationTerminateModal = (props: WarningRegistrationActions) => {
    popupManager.open(WarningRegistrationTerminateModal, {
      ...props,
      onClose: () => null,
    })
  }

  return {
    handleOpenWarningRegistrationTerminateModal,
  }
}

const WarningRegistrationTerminateModal: FC<WarningRegistrationTerminateModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const warningText = (
    <>
      Регистрация расторжения ранее, чем за 5 рабочих дней до предполагаемой даты расторжения, не
      допускается{' '}
      <a
        className={styles.modal__link}
        href={documentsLinks.DECREE_N1602}
        target={'_blank'}
        rel={'noreferrer noopener'}
      >
        Правилами
      </a>
    </>
  )

  const handleModalClose = () => {
    onClose?.()
    onCancel?.()
  }

  const modalActions = {
    mergeDefault: false,
    actions: [
      {
        dataTestId: 'WarningRegistrationTerminateModal-cancel-button',
        children: 'Отмена',
        view: 'gray',
        onClick: handleModalClose,
      },
      {
        dataTestId: 'WarningRegistrationTerminateModal-registrateTerminate-button',
        className: styles.modal__button,
        children: 'Зарегистрировать',
        onClick: onConfirm,
      },
    ] as ButtonProps[],
  }

  return (
    <ErrorModal
      isOpen={isOpen}
      headerText={'Зарегистрировать расторжение заранее?'}
      customActions={modalActions}
      bodyText={warningText}
      modalActionsProps={{
        title: '',
        disableOnClose: true,
      }}
      onClose={handleModalClose}
    />
  )
}

export { useWarningRegistrationTerminateModal }
export default WarningRegistrationTerminateModal
