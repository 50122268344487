import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectOption,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const objectNamePermissionsValidationMap: FormValuesValidationSection<
  keyof AAgreementOnChangingCharacteristicsOfObjectOption,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues
> = {
  objectOption: {
    required: defaultRHFValidation.required,
  },
}

export { objectNamePermissionsValidationMap }
