import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext, useFormState } from 'react-hook-form'

import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFindFormValueDifferences } from '@components/DocumentFormComponents/hooks/useFindFormValueDifferences'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormRegions } from '@components/DocumentFormComponents/hooks/useFormRegions'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNestedMenuFormErrors } from '@components/DocumentFormComponents/hooks/useNestedMenuFormErrors'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { useNestedMenuManager as useAAgreementOnChangingCharacteristicsOfObjectsMenuManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import {
  FormModalContextProps,
  FormModalManagerProps,
} from '@components/DocumentFormComponents/types'
import { useAAgreementOnChangingCharacteristicsOfObjectsAdapters } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/adapters'
import { getAAgreementOnChangingCharacteristicsOfObjectsMenuByStatus } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Menu/const'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
  AAgreementOnChangingCharacteristicsOfObjectsModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import { isNull } from '@helpers/checkTypes'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'

const { getDefaultFormModalContextValue } = DocumentFormHelpers

export const AAgreementOnChangingCharacteristicsOfObjectsModalContext = createContext<
  FormModalContextProps<AAgreementOnChangingCharacteristicsOfObjectsFormValues>
>(getDefaultFormModalContextValue<AAgreementOnChangingCharacteristicsOfObjectsFormValues>())

const AAgreementOnChangingCharacteristicsOfObjectsModalManager: FC<FormModalManagerProps> = ({
  projectId,
  formId,
  editMode,
  initialErrorsFromViolations,
  onSetLastUpdateFormToNow,
  onClose,
  children,
}) => {
  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  //Инициализация регионов для селектов
  useFormRegions(projectId)

  const {
    updateLastRFHBeforeValue,
    getLastRHFBeforeValue,
    getLastFormModifierBeforeValue,
    updateLastFormModifierBeforeValue,
  } = useFormPreviousValue<
    AAgreementOnChangingCharacteristicsOfObjectsFormValues,
    AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues
  >()

  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()
  const { setValue, getValues, reset: resetForm, clearErrors } = formInstance

  const formModifierInstance =
    useFormModifierContext<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>()

  const {
    reset: resetModifierForm,
    getValues: getValuesModifier,
    setValue: setValueModifier,
  } = formModifierInstance

  const { errors } = useFormState({
    control: formInstance?.control,
  })

  const { processOverrideProps, getOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const { formIsLoading, handleMountForm } = useFormMount(formId)

  const { formErrorsRef } = useNestedMenuFormErrors(errors)

  const {
    handlers: { handleRecalculateFormMenu },
  } = useAAgreementOnChangingCharacteristicsOfObjectsMenuManager()

  const {
    generatePropertiesObject,
    generateRHFObject,
    generateOverrideProps,
    generateMenuListByFormValues,
    generatePrefetchedPropsArray,
    generateFormModifierValues,
  } = useAAgreementOnChangingCharacteristicsOfObjectsAdapters()

  const { getAdvancedPropsFromObjectAdapter, getBasePropsFromObjectAdapter } =
    useGenerateValuesFromObjectAdapter({
      generateRHFObject,
      generatePropertiesObject,
      generateOverrideProps,
      generatePrefetchedPropsArray,
    })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
  })

  const { handleNotifyAllSubscribersWithAdvancedProps } = useNotifyAllSubscribers({
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const { applyDifferences } = useFindFormValueDifferences({
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
    setValue,
  })

  const { applyDifferences: applyModifierDifferences } =
    useFindFormValueDifferences<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>({
      watcher: AAgreementOnChangingCharacteristicsOfObjectsModifierFieldArrayControlUpdateWatcher,
      setValue: setValueModifier,
    })

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()
      const prevModifierValue = getLastFormModifierBeforeValue()

      if (isNull(prevRHFValue) || isNull(prevModifierValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const customProps = await generateOverrideProps(props.objectForAdapter)

      const prefetchedIds = generatePrefetchedPropsArray(props.objectForAdapter)

      const readyOverrideProps = await processOverrideProps(
        [...props.overridePropsFromChanges, ...customProps],
        prefetchedIds,
      )

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      const formModifierValues = generateFormModifierValues(props.objectForAdapter)

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      unstable_batchedUpdates(() => {
        applyDifferences(prevRHFValue, RHFValueForReset)
        updateLastRFHBeforeValue(RHFValueForReset)

        applyModifierDifferences(prevModifierValue, formModifierValues)
        updateLastFormModifierBeforeValue(formModifierValues)

        setTimeout(() => {
          AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher.notifyAllSubscribers(
            readyOverrideProps,
          )
        }, 0)

        onSetLastUpdateFormToNow?.()
      })
    },
    [
      getLastRHFBeforeValue,
      getLastFormModifierBeforeValue,
      lastFieldUpdateTime,
      generateOverrideProps,
      generatePrefetchedPropsArray,
      processOverrideProps,
      getBasePropsFromObjectAdapter,
      generateFormModifierValues,
      propertiesPropsRef,
      applyDifferences,
      updateLastRFHBeforeValue,
      applyModifierDifferences,
      updateLastFormModifierBeforeValue,
      onSetLastUpdateFormToNow,
    ],
  )

  const handleRecalculateMenu = useCallback(() => {
    const formValues = getValues()
    const formModifierValues = getValuesModifier()

    const preparedMenuList = generateMenuListByFormValues(
      formValues,
      cloneDeep(
        getAAgreementOnChangingCharacteristicsOfObjectsMenuByStatus(
          formValues.additionalFields.isFederal,
          !!formValues.additionalFields.initialMunicipalities.length,
        ).initialAAgreementOnCreationOtherEstateObjectsMenuHashMap,
      ),
      formErrorsRef.current,
      formModifierValues,
    )

    handleRecalculateFormMenu?.(preparedMenuList)
  }, [
    getValues,
    getValuesModifier,
    generateMenuListByFormValues,
    formErrorsRef,
    handleRecalculateFormMenu,
  ])

  const handleApplyChangesWithMenu = useCallback(
    async (props: ApplyChangesReturn) => {
      await handleApplyChanges(props)

      handleRecalculateMenu()
    },
    [handleApplyChanges, handleRecalculateMenu],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const wrappedHandleApplyFormChangesWithMenu = useMemo(
    () => applyFormChanges(handleApplyChangesWithMenu),
    [applyFormChanges, handleApplyChangesWithMenu],
  )

  const {
    handleAddItemToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleChangeValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    debouncedHandleChangeValue,
    handleUpElementInList,
    handleDownElementInList,
  } = useFormUpdate({
    formId,
    getPropertiesProps,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: wrappedHandleApplyFormChangesWithMenu,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const wrappedHandleApplyFormChanges = useMemo(
    () => applyFormChanges(handleApplyChanges),
    [applyFormChanges, handleApplyChanges],
  )

  const { handleCheckForm, handlePreviewForm } = useFormActions({
    formId,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    onClearError: clearErrors,
    handleApplyChanges: wrappedHandleApplyFormChanges,
    onErrorCheckForm: handleRecalculateMenu,
  })

  const handleSuccessInitializeForm = useCallback(
    async ({ lastChangesDt, propertyInstance }: SuccessInitialCallbackReturn) => {
      lastFieldUpdateTime.current = lastChangesDt

      propertyInstanceRef.current = propertyInstance

      const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(objectForAdapters)

      const formModifierValues = generateFormModifierValues(objectForAdapters)

      const preparedMenuList = generateMenuListByFormValues(
        RHFValueForReset,
        cloneDeep(
          getAAgreementOnChangingCharacteristicsOfObjectsMenuByStatus(
            RHFValueForReset.additionalFields.isFederal,
            !!RHFValueForReset.additionalFields.initialMunicipalities.length,
          ).initialAAgreementOnCreationOtherEstateObjectsMenuHashMap,
        ),
        errors,
        formModifierValues,
      )

      //Установка propertiesProps
      propertiesPropsRef.current = cloneDeep(propertiesProps)

      resetForm(RHFValueForReset)
      resetModifierForm(formModifierValues)
      updateLastRFHBeforeValue(RHFValueForReset)
      updateLastFormModifierBeforeValue(formModifierValues)

      handleRecalculateFormMenu?.(preparedMenuList)
    },
    [
      errors,
      propertiesPropsRef,
      propertyInstanceRef,
      lastFieldUpdateTime,
      resetForm,
      resetModifierForm,
      generateMenuListByFormValues,
      getBasePropsFromObjectAdapter,
      generateFormModifierValues,
      handleRecalculateFormMenu,
      updateLastRFHBeforeValue,
      updateLastFormModifierBeforeValue,
    ],
  )

  //Инициализация формы
  useEffect(() => {
    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleNotifyAllSubscribersWithAdvancedProps(
        getAdvancedPropsFromObjectAdapter,
      ),
    })
  }, [])

  const preparedValue: FormModalContextProps<AAgreementOnChangingCharacteristicsOfObjectsFormValues> =
    useMemo(
      () => ({
        state: {
          formIsLoading,
          blockViewIsValidating,
          initialErrorsFromViolations,
          editMode,
        },
        handlers: {
          checkFormChanges,
          getPropertiesProps,
          getOverrideProps,
          handleChangeValue,
          handleAddCustomValue,
          handleRemoveCustomValue,
          debouncedHandleChangeValue,
          handleAddItemToListWithOutValue,
          handleAddItemToListWithValue,
          handleUpElementInList,
          handleDownElementInList,
          handleRemoveItemFromList,
          applyFormChanges: wrappedHandleApplyFormChanges,
          handleChangeBlockValidation,
        },
        actions: {
          handleFormClose: onClose,
          handleCheckForm,
          handlePreviewForm,
        },
        preparedProps: {
          subscribableControl,
        },
      }),
      [
        formIsLoading,
        blockViewIsValidating,
        initialErrorsFromViolations,
        editMode,
        checkFormChanges,
        getPropertiesProps,
        getOverrideProps,
        handleChangeValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        debouncedHandleChangeValue,
        handleAddItemToListWithOutValue,
        handleAddItemToListWithValue,
        handleRemoveItemFromList,
        handleUpElementInList,
        handleDownElementInList,
        wrappedHandleApplyFormChanges,
        handleChangeBlockValidation,
        onClose,
        handleCheckForm,
        handlePreviewForm,
        subscribableControl,
      ],
    )

  return (
    <AAgreementOnChangingCharacteristicsOfObjectsModalContext.Provider value={preparedValue}>
      {children}
    </AAgreementOnChangingCharacteristicsOfObjectsModalContext.Provider>
  )
}

const useAAgreementOnChangingCharacteristicsOfObjectsManager = () => {
  return useContext(AAgreementOnChangingCharacteristicsOfObjectsModalContext)
}

export { useAAgreementOnChangingCharacteristicsOfObjectsManager }

export default AAgreementOnChangingCharacteristicsOfObjectsModalManager
