import { FC } from 'react'

import { encodingsConverter } from '@helpers/encodings/converter'

interface EmailFieldProps {
  children: string
}

const EmailField: FC<EmailFieldProps> = ({ children }) => {
  return encodingsConverter.ToUnicode(children)
}

export default EmailField
