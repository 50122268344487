import React, { FC, memo } from 'react'
import { useParams } from 'react-router'

import Typography from '@components/NewDesign/Typography'
import { DictionaryNameFromParams } from '@components/ReferenceBooks/constants'
import { useFlatEntrySidebar } from '@components/Sidebars/ReferenceBooks/FlatEntrySidebar'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { useSWRConfig } from 'swr'

import styles from './ReferenceBooksFlatItem.module.scss'

interface ReferenceBooksFlattedGridItemProps {
  referenceBookItem: ReferenceBookItem
  searchValue: string
}

const ReferenceBooksFlatItem: FC<ReferenceBooksFlattedGridItemProps> = ({
  referenceBookItem,
  searchValue,
}) => {
  const { mutate } = useSWRConfig()

  const { name } = referenceBookItem

  const { dictionaryName } = useParams<DictionaryNameFromParams>()

  const { handleOpenPartSidebar } = useFlatEntrySidebar()

  const handleOpenSidebar = () => {
    if (!dictionaryName) return

    handleOpenPartSidebar({
      dictionaryName,
      onSuccess: async () => {
        await mutate({
          dictionaryName,
          searchString: searchValue.length ? searchValue : undefined,
          _key: 'referenceBooks',
        })
      },
      initialEntry: referenceBookItem,
    })
  }

  return (
    <button className={styles.referenceBookFlatItem} type="button" onClick={handleOpenSidebar}>
      <Typography.Body variant={'bodyMRegular'} className={styles.referenceBookFlatItem__text}>
        {name}
      </Typography.Body>
    </button>
  )
}

export default memo(ReferenceBooksFlatItem)
