import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { getAmountValueFromStrWithoutRound } from '@components/NewDesign/AmountInput/utils'
import {
  defaultRHFValidation,
  lengthValidate,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const sixthSectionValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['6']['stages'][number],
  ProjectSZPKFormValues
> = {
  stepNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  stepDescription: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  stepStartDate: {
    required: defaultRHFValidation.required,
  },
  stepEndDate: {
    required: defaultRHFValidation.required,
  },
  stepInvestorCapitalValue: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  stepCompensationValue: ({ form }) => ({
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
    onBlur: async () => {
      const stages = form.getValues(`${projectSZPKBlockValues['6'].stages}`)

      const projectSupportCompensation = form.getValues(
        projectSZPKBlockValues['5'].projectSupportCompensation,
      )
      const projectRelatedCompensation = form.getValues(
        projectSZPKBlockValues['5'].projectRelatedCompensation,
      )

      const numberProjectSupportCompensation = getAmountValueFromStrWithoutRound(
        projectSupportCompensation,
      )
      const numberProjectRelatedCompensation = getAmountValueFromStrWithoutRound(
        projectRelatedCompensation,
      )

      stages.forEach((_, index) => {
        form.trigger(`${projectSZPKBlockValues['6'].stages}.${index}.stepCompensationValue`)
      })

      if (numberProjectSupportCompensation) {
        form.clearErrors(projectSZPKBlockValues['5'].projectSupportCompensation)
      }

      if (numberProjectRelatedCompensation) {
        form.clearErrors(projectSZPKBlockValues['5'].projectRelatedCompensation)
      }
    },
  }),
}

export { sixthSectionValidationMap }
