import { FC, memo } from 'react'

import KnowledgeBaseMobileItem from '@components/KnowledgeBase/Mobile/Item'
import type { IKnowledgeBaseItem } from '@components/KnowledgeBase/types'

import styles from './MobileMenu.module.scss'

interface KnowledgeBaseMobileMenuProps {
  menuList: IKnowledgeBaseItem[]
}

const KnowledgeBaseMobileMenu: FC<KnowledgeBaseMobileMenuProps> = ({ menuList }) => {
  return (
    <div className={styles.menu}>
      <ul className={styles.list}>
        {menuList.map((item) => (
          <KnowledgeBaseMobileItem key={item.id} baseItem={item} />
        ))}
      </ul>
    </div>
  )
}

export default memo(KnowledgeBaseMobileMenu)
