import { useMemo } from 'react'
import { ControllerProps, FieldValues } from 'react-hook-form'

import { ControlledMultipleSelect, ControlledSingleSelect } from '@components/NewDesign/Select'
import { OptionProps } from '@components/NewDesign/Select/model'
import { useOMSUSelectConfig } from '@components/PreparedSelects/OMSUSelect/useOMSUSelectConfig'

interface OMSUSelectProps<T extends FieldValues> {
  controllerProps: Omit<ControllerProps<T>, 'render'>
  disabled?: boolean
  type?: 'single' | 'multi'
  onAddValue?: () => void
  regionCodes?: string | string[]
  initialSelectOption?: OptionProps[]
}

const ControlledOMSUSelect = <T extends FieldValues>(props: OMSUSelectProps<T>) => {
  const {
    disabled,
    type = 'single',
    initialSelectOption,
    controllerProps,
    onAddValue,
    regionCodes,
  } = props

  const {
    preparedOMSUOptions,
    onContentScroll,
    onChangeSearchValue,
    OMSUData: { isLoadingOMSUOrganizationsList, error },
  } = useOMSUSelectConfig({
    regionCodes,
  })

  const disabledSelectCondition = !!error || disabled

  const selectSingleProps = useMemo(
    () => ({
      withInput: true,
      controllerProps: controllerProps,
      disabled: disabledSelectCondition,
      defaultOptionsSelected: initialSelectOption,
      options: preparedOMSUOptions,
      optionsContainer: {
        onScroll: onContentScroll,
      },
      inputProps: {
        fixWidth: true,
        label: 'Муниципалитет применения акта',
      },
      popoverProps: { zIndex: 51 },
      optionsProps: {
        enableOptionTooltips: {
          valueLength: 35,
          value: true,
        },
        shouldOpenWithoutOptions: true,
        isLoading: isLoadingOMSUOrganizationsList,
        notFoundText:
          'Если вы не нашли нужное муниципальное образование, возможно, оно еще не добавлено.',
      },
      overrideDisplayValue: (value: string) => value.split('-').slice(1).join('-').trim(),
      onChangeSearchValue,
    }),
    [
      controllerProps,
      disabledSelectCondition,
      initialSelectOption,
      isLoadingOMSUOrganizationsList,
      onChangeSearchValue,
      onContentScroll,
      preparedOMSUOptions,
    ],
  )

  const multiSelectProps = useMemo(
    () => ({
      ...selectSingleProps,
      closeOptionsAfterOnChange: true,
      onAddValue,
    }),
    [onAddValue, selectSingleProps],
  )

  if (type === 'single') return <ControlledSingleSelect {...selectSingleProps} />

  return <ControlledMultipleSelect {...multiSelectProps} />
}

export default ControlledOMSUSelect
