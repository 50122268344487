import { useAPIContext } from '@context/APIContext'
import { mapOfStageStatementFilters } from '@routes/NewStatements/const'
import { ProjectTypes } from '@services/Projects/Project.entity'

const needProjectsStages = [
  mapOfStageStatementFilters.ACTIVE,
  mapOfStageStatementFilters.SIGNING,
  mapOfStageStatementFilters.CHECKING,
]

export const useNotificationOfOrganizationChange = () => {
  const {
    projectsApi: { getAllProjects },
  } = useAPIContext()

  const getUpdateNotification = async () => {
    const arrayOfProjectsStagesPromises = () => {
      return needProjectsStages.map((stage) =>
        getAllProjects({
          page: 0,
          size: 15,
          type: ProjectTypes.SZPK,
          stage,
        }),
      )
    }
    const result = await Promise.allSettled(arrayOfProjectsStagesPromises())
    const fulfilledProjects = result.filter(
      (projects) => projects.status === 'fulfilled' && projects.value.length,
    )
    if (!!fulfilledProjects.length) return

    throw new Error()
  }
  return { getUpdateNotification }
}
