import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { IRidRegistration, ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const ridRegistrationsProjectSZPKValidationMap: FormValuesValidationSection<
  keyof IRidRegistration,
  ProjectSZPKFormValues
> = {
  ridName: {
    required: defaultRHFValidation.required,
  },
  ridStateRegistrationNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  ridStateRegistrationOrganization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { ridRegistrationsProjectSZPKValidationMap }
