import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NoticeOneSideRejectSZPKFormValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionValidationMap: FormValuesValidationSection<
  keyof NoticeOneSideRejectSZPKFormValues['3'],
  NoticeOneSideRejectSZPKFormValues
> = {
  ruleLinkOneSideRejectReason: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  oneSideRejectReasonProof: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { thirdSectionValidationMap }
