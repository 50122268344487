import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NoticeOfRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/types'

const generateRHFObjectForNoticeOfRefuseConcludeAAForm = (
  objectFromPropertyState: Record<string, any>,
): NoticeOfRefuseConcludeAAFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: objectFromPropertyState.investorFullNameGenitive?.value || '',
      projectName: objectFromPropertyState.projectName?.value || '',
    },
    '4': {
      ruleLinkExtraReject: objectFromPropertyState.ruleLinkExtraReject?.value || '',
      extraRejectReason: objectFromPropertyState.extraRejectReason?.value || '',
    },
  } as NoticeOfRefuseConcludeAAFormValues
}

export { generateRHFObjectForNoticeOfRefuseConcludeAAForm }
