import React, { DetailedHTMLProps, FC, HTMLAttributes, memo, ReactNode } from 'react'

import { Transition } from '@headlessui/react'
import cn from 'classnames'

import styles from './EntityItemActions.module.scss'

export interface EntityItemActionsProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOpen: boolean
  actions: ReactNode
}

const EntityItemActions: FC<EntityItemActionsProps> = ({ isOpen, actions, ...divRest }) => {
  return (
    <Transition
      {...divRest}
      className={cn(styles.actions, divRest.className)}
      show={isOpen}
      enter={styles.transition__enter}
      enterFrom={styles.transition__enterFrom}
      enterTo={styles.transition__enterTo}
      leave={styles.transition__leave}
      leaveFrom={styles.transition__leaveFrom}
      leaveTo={styles.transition__leaveTo}
    >
      {actions}
    </Transition>
  )
}

export default memo(EntityItemActions)
