import React, { memo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { generalInfoValidation } from '@components/Forms/CreateStatement/Forms/GeneralInfo/validation'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { CreateStatementControlUpdateWatcher } from '@components/Forms/CreateStatement/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const GeneralInfoForm = () => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()

  const {
    getSubscribableControlProps,
    getHierarchyReferenceBookSelectProps,
    getInputProps,
    getTextareaProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CreateStatementControlUpdateWatcher,
  })

  const economicSphere = useWatch({
    name: statementBlockValues.generalInfo.economicSphere,
    control: formInstance.control,
  })

  const isOtherEconomicSphereInputAvailable = economicSphere?.displayValue === 'Иная сфера'

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.generalInfo.economicSphere,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.name,
                        value: item.id,
                      }),
                      controllerProps: {
                        name: statementBlockValues.generalInfo.economicSphere,
                        rules: generalInfoValidation.economicSphere,
                      },
                      selectProps: {
                        inputProps: {
                          label: 'Сфера экономики, в которой реализуется проект',
                          dataTestId: 'economicSphere',
                        },
                        onChangeFormValue: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(statementBlockValues.generalInfo.economicSphere),
                            0,
                          ),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <CollapseWrapper isExpanded={isOtherEconomicSphereInputAvailable}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementBlockValues.generalInfo.otherEconomicSphere,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: statementBlockValues.generalInfo.otherEconomicSphere,
                    rules: generalInfoValidation.otherEconomicSphere,
                    textareaProps: {
                      label: 'Описание иной сферы экономики',
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent="При указании рекомендуется ориентироваться на выбранный ОКВЭД" />
                      ),
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          `${statementBlockValues.generalInfo.otherEconomicSphere}`,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          `${statementBlockValues.generalInfo.otherEconomicSphere}`,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </CollapseWrapper>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.generalInfo.projectOkved,
              })}
            >
              <ControlledHierarchyReferenceBookSelect
                {...getHierarchyReferenceBookSelectProps({
                  dictionaryName: 'okved',
                  controllerProps: {
                    name: statementBlockValues.generalInfo.projectOkved,
                    rules: generalInfoValidation.projectOkved,
                  },
                  inputProps: {
                    label: 'Сфера экономики, согласно коду ОКВЭД',
                    dataTestId: 'projectOkved',
                  },
                  onChange: () =>
                    setTimeout(
                      () => handleChangeValue?.(statementBlockValues.generalInfo.projectOkved),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title={'Основание (статья/статьи) для заключения СЗПК (69-ФЗ)'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementBlockValues.generalInfo.lawLinkReasonSzpk,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: statementBlockValues.generalInfo.lawLinkReasonSzpk,
                rules: generalInfoValidation.lawLinkReasonSzpk,
                inputProps: {
                  dataTestId: `GeneralInfoForm-${statementBlockValues.generalInfo.lawLinkReasonSzpk}-input`,
                  leftAddons: <FormIconWithTooltip tooltipContent="рекомендация: 7" />,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(statementBlockValues.generalInfo.lawLinkReasonSzpk)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(statementBlockValues.generalInfo.lawLinkReasonSzpk)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          title={'Основание (пункт/пункты) для заключения СЗПК (ПП РФ 1602)'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementBlockValues.generalInfo.ruleLinkReasonSzpk,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: statementBlockValues.generalInfo.ruleLinkReasonSzpk,
                rules: generalInfoValidation.ruleLinkReasonSzpk,

                inputProps: {
                  dataTestId: `GeneralInfoForm-${statementBlockValues.generalInfo.ruleLinkReasonSzpk}-input`,
                  leftAddons: <FormIconWithTooltip tooltipContent="рекомендация: 7" />,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(statementBlockValues.generalInfo.ruleLinkReasonSzpk)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      statementBlockValues.generalInfo.ruleLinkReasonSzpk,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          title={'Количество cоздаваемых рабочих мест после окончания проекта'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementBlockValues.generalInfo.newWorkPlaces,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: statementBlockValues.generalInfo.newWorkPlaces,
                rules: generalInfoValidation.newWorkPlaces,
                inputProps: {
                  suffix: '',
                  integersOnly: true,
                  dataTestId: `GeneralInfoForm-${statementBlockValues.generalInfo.newWorkPlaces}-amountInput`,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(statementBlockValues.generalInfo.newWorkPlaces)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(statementBlockValues.generalInfo.newWorkPlaces)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder title={'Количество создаваемых рабочих мест'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementBlockValues.generalInfo.buildWorkPlaces,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: statementBlockValues.generalInfo.buildWorkPlaces,
                rules: generalInfoValidation.buildWorkPlaces,
                inputProps: {
                  suffix: '',
                  integersOnly: true,
                  dataTestId: `GeneralInfoForm-${statementBlockValues.generalInfo.buildWorkPlaces}-amountInput`,
                  leftAddons: <FormIconWithTooltip tooltipContent={'На этапе эксплуатации'} />,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(statementBlockValues.generalInfo.buildWorkPlaces)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(statementBlockValues.generalInfo.buildWorkPlaces)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.generalInfo.projectDescription,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: statementBlockValues.generalInfo.projectDescription,
                  rules: generalInfoValidation.projectDescription,
                  textareaProps: {
                    label: 'Добавьте общую характеристику проекта',
                    dataTestId: 'projectDescription',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(statementBlockValues.generalInfo.projectDescription)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        statementBlockValues.generalInfo.projectDescription,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default memo(GeneralInfoForm)
