import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForStatement = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedInvestorHeaderEmail = (() => {
    if (
      !isArray(objectFromPropertyState.docSignerEmails?.value) ||
      !objectFromPropertyState.docSignerEmails.value.length
    )
      return []

    return objectFromPropertyState.docSignerEmails.value.map((email) => ({
      propertyId: email.propertyId,
      lastUpdateDt: email.lastUpdateDt,
      type: email.type,
    }))
  })()

  const preparedStepOfStages = (() => {
    if (
      !isArray(objectFromPropertyState.steps.value) ||
      !objectFromPropertyState.steps.value.length
    )
      return []

    return objectFromPropertyState.steps.value.map((step) => ({
      propertyId: step.propertyId,
      lastUpdateDt: step.lastUpdateDt,
      value: {
        stepDescription: {
          propertyId: step.value.stepDescription.propertyId,
          lastUpdateDt: step.value.stepDescription.lastUpdateDt,
          type: step.value.stepDescription.type,
        },
        stepStartDate: {
          propertyId: step.value.stepStartDate.propertyId,
          lastUpdateDt: step.value.stepStartDate.lastUpdateDt,
          type: step.value.stepStartDate.type,
        },
        stepEndDate: {
          propertyId: step.value.stepEndDate.propertyId,
          lastUpdateDt: step.value.stepEndDate.lastUpdateDt,
          type: step.value.stepEndDate.type,
        },
      },
    }))
  })()

  const preparedTaxesType = (() => {
    if (
      !isArray(objectFromPropertyState.plannedTax.value) ||
      !objectFromPropertyState.plannedTax.value.length
    )
      return []

    return objectFromPropertyState.plannedTax.value
      .sort((taxA, taxB) => taxA.order - taxB.order)
      .map((tax) => ({
        propertyId: tax.propertyId,
        lastUpdateDt: tax.lastUpdateDt,
        value: {
          taxYear: {
            propertyId: tax.value.taxYear.propertyId,
            lastUpdateDt: tax.value.taxYear.lastUpdateDt,
            type: tax.value.taxYear.type,
          },
          taxYearValue: {
            propertyId: tax.value.taxYearValue.propertyId,
            lastUpdateDt: tax.value.taxYearValue.lastUpdateDt,
            type: tax.value.taxYearValue.type,
          },
        },
      }))
  })()

  const preparedCompensations = (() => {
    if (
      !isArray(objectFromPropertyState.projectCompensations.value) ||
      !objectFromPropertyState.projectCompensations.value.length
    )
      return []

    return objectFromPropertyState.projectCompensations.value
      .sort((compensationA, compensationB) => compensationA.order - compensationB.order)
      .map((compensation) => ({
        propertyId: compensation.propertyId,
        lastUpdateDt: compensation.lastUpdateDt,
        value: {
          projectCompensationType: {
            propertyId: compensation.value.projectCompensationType.propertyId,
            lastUpdateDt: compensation.value.projectCompensationType.lastUpdateDt,
            type: compensation.value.projectCompensationType.type,
          },
          projectCompensationForm: {
            propertyId: compensation.value.projectCompensationForm.propertyId,
            lastUpdateDt: compensation.value.projectCompensationForm.lastUpdateDt,
            type: compensation.value.projectCompensationForm.type,
          },
          projectCompensationValue: {
            propertyId: compensation.value.projectCompensationValue.propertyId,
            lastUpdateDt: compensation.value.projectCompensationValue.lastUpdateDt,
            type: compensation.value.projectCompensationValue.type,
          },
        },
      }))
  })()

  return {
    investor: {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      isProjectCompany: {
        propertyId: objectFromPropertyState.investor?.value.isProjectCompany.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.isProjectCompany.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.isProjectCompany.type,
      },
      isVed: {
        propertyId: objectFromPropertyState.investor?.value.isVed.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.isVed.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.isVed.type,
      },
      isKgn: {
        propertyId: objectFromPropertyState.investor?.value.isKgn.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.isKgn.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.isKgn.type,
      },
      investorHeaderEmail: {
        propertyId: objectFromPropertyState.docSignerEmails?.propertyId,
        lastUpdateDt: objectFromPropertyState.docSignerEmails?.lastUpdateDt,
        value: preparedInvestorHeaderEmail,
      },
      investorHeaderPhone: {
        propertyId: objectFromPropertyState.docSignerPhone.propertyId,
        lastUpdateDt: objectFromPropertyState.docSignerPhone.lastUpdateDt,
        type: objectFromPropertyState.docSignerPhone.type,
      },
      investorHeaderAddPhone: {
        propertyId: objectFromPropertyState.docSignerAddPhone.propertyId,
        lastUpdateDt: objectFromPropertyState.docSignerAddPhone.lastUpdateDt,
        type: objectFromPropertyState.docSignerAddPhone.type,
      },
    },
    generalInfo: {
      economicSphere: {
        propertyId: objectFromPropertyState.economicSphere.propertyId,
        lastUpdateDt: objectFromPropertyState.economicSphere.lastUpdateDt,
        type: objectFromPropertyState.economicSphere.type,
      },
      otherEconomicSphere: {
        propertyId: objectFromPropertyState?.otherEconomicSphere?.propertyId || '',
        lastUpdateDt: objectFromPropertyState?.otherEconomicSphere?.lastUpdateDt || '',
        type: objectFromPropertyState?.otherEconomicSphere?.type || '',
      },
      projectOkved: {
        propertyId: objectFromPropertyState.projectOkved.propertyId,
        lastUpdateDt: objectFromPropertyState.projectOkved.lastUpdateDt,
        type: objectFromPropertyState.projectOkved.type,
      },
      lawLinkReasonSzpk: {
        propertyId: objectFromPropertyState.lawLinkReasonSzpk.propertyId,
        lastUpdateDt: objectFromPropertyState.lawLinkReasonSzpk.lastUpdateDt,
        type: objectFromPropertyState.lawLinkReasonSzpk.type,
      },
      ruleLinkReasonSzpk: {
        propertyId: objectFromPropertyState.ruleLinkReasonSzpk.propertyId,
        lastUpdateDt: objectFromPropertyState.ruleLinkReasonSzpk.lastUpdateDt,
        type: objectFromPropertyState.ruleLinkReasonSzpk.type,
      },
      newWorkPlaces: {
        propertyId: objectFromPropertyState.newWorkPlaces.propertyId,
        lastUpdateDt: objectFromPropertyState.newWorkPlaces.lastUpdateDt,
        type: objectFromPropertyState.newWorkPlaces.type,
      },
      buildWorkPlaces: {
        propertyId: objectFromPropertyState.buildWorkPlaces.propertyId,
        lastUpdateDt: objectFromPropertyState.buildWorkPlaces.lastUpdateDt,
        type: objectFromPropertyState.buildWorkPlaces.type,
      },
      projectDescription: {
        propertyId: objectFromPropertyState.projectDescription.propertyId,
        lastUpdateDt: objectFromPropertyState.projectDescription.lastUpdateDt,
        type: objectFromPropertyState.projectDescription.type,
      },
    },
    stage: {
      projectStartDate: {
        propertyId: objectFromPropertyState.projectStartDate.propertyId,
        lastUpdateDt: objectFromPropertyState.projectStartDate.lastUpdateDt,
        type: objectFromPropertyState.projectStartDate.type,
      },
      projectEndDate: {
        propertyId: objectFromPropertyState.projectEndDate.propertyId,
        lastUpdateDt: objectFromPropertyState.projectEndDate.lastUpdateDt,
        type: objectFromPropertyState.projectEndDate.type,
      },
      agreementEndDate: {
        propertyId: objectFromPropertyState.agreementEndDate.propertyId,
        lastUpdateDt: objectFromPropertyState.agreementEndDate.lastUpdateDt,
        type: objectFromPropertyState.agreementEndDate.type,
      },
      stepOfStages: {
        propertyId: objectFromPropertyState.steps.propertyId,
        lastUpdateDt: objectFromPropertyState.steps.lastUpdateDt,
        type: objectFromPropertyState.steps.type,
        value: preparedStepOfStages,
      },
    },
    participantsAndInvestments: {
      projectDecisionDate: {
        propertyId: objectFromPropertyState?.projectDecisionDate?.propertyId,
        lastUpdateDt: objectFromPropertyState?.projectDecisionDate?.lastUpdateDt,
        type: objectFromPropertyState?.projectDecisionDate?.type,
      },
      endExploitationStage: {
        propertyId: objectFromPropertyState.endExploitationStage.propertyId,
        lastUpdateDt: objectFromPropertyState.endExploitationStage.lastUpdateDt,
        type: objectFromPropertyState.endExploitationStage.type,
      },
      investorCapital: {
        propertyId: objectFromPropertyState.investorCapital.propertyId,
        lastUpdateDt: objectFromPropertyState.investorCapital.lastUpdateDt,
        type: objectFromPropertyState.investorCapital.type,
      },
      investorCapitalVat: {
        propertyId: objectFromPropertyState.investorCapitalVAT.propertyId,
        lastUpdateDt: objectFromPropertyState.investorCapitalVAT.lastUpdateDt,
        type: objectFromPropertyState.investorCapitalVAT.type,
      },
      totalCapitalVat: {
        propertyId: objectFromPropertyState.totalCapitalVAT.propertyId,
        lastUpdateDt: objectFromPropertyState.totalCapitalVAT.lastUpdateDt,
        type: objectFromPropertyState.totalCapitalVAT.type,
      },
      totalCapital: {
        propertyId: objectFromPropertyState.totalCapital.propertyId,
        lastUpdateDt: objectFromPropertyState.totalCapital.lastUpdateDt,
        type: objectFromPropertyState.totalCapital.type,
      },
    },
    plannedTax: {
      taxesType: {
        propertyId: objectFromPropertyState.plannedTax.propertyId,
        lastUpdateDt: objectFromPropertyState.plannedTax.lastUpdateDt,
        type: objectFromPropertyState.plannedTax.type,
        value: preparedTaxesType,
      },
    },
    compensation: {
      projectCompensations: {
        propertyId: objectFromPropertyState.projectCompensations.propertyId,
        lastUpdateDt: objectFromPropertyState.projectCompensations.lastUpdateDt,
        type: objectFromPropertyState.projectCompensations.type,
        value: preparedCompensations,
      },
    },
  }
}

export { generatePropertiesObjectForStatement }
