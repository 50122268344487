import React, { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'

import Typography from '@components/NewDesign/Typography'
import SignedDocumentIcon from '@icons/SignedDocumentIcon.svg'

import styles from './SignItem.module.scss'

interface SignItemProps {
  name: string
  organization: string
  date: string
}

const SignItem: FC<SignItemProps> = ({ date, organization, name }) => {
  return (
    <div className={styles.signItem}>
      <div className={styles.signItem__header}>
        <Typography.Body
          variant={'bodyMSemibold'}
          color={'text-accent-brand'}
          as={'span'}
          className={styles.signItem__name}
        >
          {name}
          {<ReactSVG wrapper={'span'} className={styles.signItem__icon} src={SignedDocumentIcon} />}
        </Typography.Body>
        <Typography.Body
          className={styles.signItem__date}
          variant={'bodyMRegular'}
          color={'accent-brand-faded-secondary'}
          as={'span'}
        >
          {date}
        </Typography.Body>
      </div>
      <Typography.Body variant={'bodyMRegular'} color={'text-accent-brand'}>
        {organization}
      </Typography.Body>
    </div>
  )
}

export default memo(SignItem)
