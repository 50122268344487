import { useAPIContext } from '@context/APIContext'
import { GetReferenceBookChildsByIdReturnType } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import type { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useReferenceBookChildsByDictionaryNameAndId = ({
  key,
  config,
}: SWRHookProps<GetReferenceBookChildsByIdReturnType>) => {
  const {
    referenceBooksApi: { getReferenceBookChildsById },
  } = useAPIContext()

  const {
    data: referenceBooks,
    mutate,
    error: referenceBookError,
    isValidating: isReferenceBookLoading,
  } = useSWRImmutable(key, getReferenceBookChildsById, config)

  return {
    referenceBooks,
    referenceBookError,
    mutate,
    isReferenceBookLoading,
  }
}

export { useReferenceBookChildsByDictionaryNameAndId }
