import React, { DetailedHTMLProps, FC, FormHTMLAttributes, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { useNoticeOfProjectRefuseConcludeAAManager } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/Manager'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'

import { mapOfNoticeOfProjectRefuseConcludeAAMenu } from '../const'
import NoticeOfProjectRefuseConcludeAAForms from '../Forms'

type NoticeOfProjectRefuseConcludeAAFormProps = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>

const mapOfNoticeOfProjectRefuseConcludeAAForms: FormItemProps = {
  '1': {
    node: <NoticeOfProjectRefuseConcludeAAForms.First />,
  },
  '2': {
    node: <NoticeOfProjectRefuseConcludeAAForms.Second />,
  },
  '3': {
    node: <NoticeOfProjectRefuseConcludeAAForms.Third />,
  },
  '4': {
    node: <NoticeOfProjectRefuseConcludeAAForms.Fourth />,
  },
} as const

const NoticeOfProjectRefuseConcludeAAForm: FC<NoticeOfProjectRefuseConcludeAAFormProps> = ({
  ...rest
}) => {
  const formInstance = useFormContext<NoticeOfProjectRefuseConcludeAAFormValues>()

  const {
    state: { formIsLoading },
  } = useNoticeOfProjectRefuseConcludeAAManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNoticeOfProjectRefuseConcludeAAMenu}
      mapOfForms={mapOfNoticeOfProjectRefuseConcludeAAForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NoticeOfProjectRefuseConcludeAAForm)
