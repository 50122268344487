import { useCallback, useEffect, useRef, useState } from 'react'

import { isNullOrUndefined } from '@helpers/checkTypes'
import { MultiClamp } from '@helpers/string/clamp'

interface MultiClampProps {
  ellipsis?: string | HTMLElement
  clamp?: number | 'auto'
  reverse?: boolean
  splitByWords?: boolean
  disableCssClamp?: boolean
  onClampStart?: ({ needClamp }: { needClamp: boolean }) => void
  onClampEnd?: ({ didClamp }: { didClamp: boolean }) => void
}

interface UseTextClampProps<T extends HTMLParagraphElement> {
  initialElement?: T | null
  multipleClampProps?: MultiClampProps
}

interface UseTextClampReturn<T extends HTMLParagraphElement> {
  currentClampInstance: MultiClamp | null
  bindElementRef: (elementRef: T | null) => void
}

const DEFAULT_CLAMP_OPTIONS = {
  ellipsis: '...',
  clamp: 1,
}

const useTextClamp = <T extends HTMLParagraphElement>(
  optionsForClamp?: UseTextClampProps<T>,
): UseTextClampReturn<T> => {
  const [elementRef, setElementRef] = useState<T | null>(optionsForClamp?.initialElement || null)
  const currentClampRef = useRef<MultiClamp | null>(null)

  const bindRef = (elementRef: T | null) => {
    if (!elementRef) return

    setElementRef(elementRef)
  }

  const recreateMultipleClampInstance = useCallback(() => {
    if (!elementRef) return

    currentClampRef.current = new MultiClamp(
      elementRef,
      isNullOrUndefined(optionsForClamp?.multipleClampProps)
        ? DEFAULT_CLAMP_OPTIONS
        : optionsForClamp?.multipleClampProps,
    )
  }, [elementRef, optionsForClamp])

  useEffect(() => {
    currentClampRef.current = null

    recreateMultipleClampInstance()
  }, [recreateMultipleClampInstance])

  return {
    bindElementRef: bindRef,
    currentClampInstance: currentClampRef.current,
  }
}

export type { MultiClampProps }
export { useTextClamp }
