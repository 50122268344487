import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'

import { NoticeOfRejectConcludeSZPKFormValues } from './types'

const { getDefaultInitiatorFieldState, getDefaultInvestorFieldState, getDefaultInfoAboutDocument } =
  FieldStateHelpers

const mapOfNoticeOfRejectConcludeSZPKMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченный орган' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Основания отказа в заключении' },
]

const defaultNoticeOfRejectConcludeSZPKFormValues: NoticeOfRejectConcludeSZPKFormValues = {
  '1': getDefaultInfoAboutDocument(),
  '2': getDefaultInitiatorFieldState(),
  '3': {
    ...getDefaultInvestorFieldState(),
    investorFullNameGenitive: '',
    investorFullNameInstrumental: '',
    investorInn: '',
    investorOgrn: '',
    investorAddress: '',
  },
  '4': {
    projectName: '',
    ruleLinkStatementReject: '',
    ruleLinkStatementRepeat: '',
    statementRejectReason: '',
  },
}

const noticeOfRejectConcludeSZPKBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderNameGenitive: '2.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '2.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '2.initiatorHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameGenitive: '3.investorFullNameGenitive',
    investorFullNameInstrumental: '3.investorFullNameInstrumental',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorAddress: '3.investorAddress',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
  },
  '4': {
    projectName: '4.projectName',
    ruleLinkStatementReject: '4.ruleLinkStatementReject',
    ruleLinkStatementRepeat: '4.ruleLinkStatementRepeat',
    statementRejectReason: '4.statementRejectReason',
  },
} as const

export {
  defaultNoticeOfRejectConcludeSZPKFormValues,
  mapOfNoticeOfRejectConcludeSZPKMenu,
  noticeOfRejectConcludeSZPKBlockValues,
}
