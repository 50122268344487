import { FC, useCallback, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { PopupProps } from 'react-popup-manager'

import FieldInfo from '@components/NewDesign/FieldInfo'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Input from '@components/NewDesign/Input/Input'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import Sidebar from '@components/NewDesign/Sidebar'
import Typography from '@components/NewDesign/Typography/Typography'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import Tag from '@components/TagNew'
import { UserProfiles } from '@constants/types'
import { defaultRHFValidation, Patterns } from '@constants/validations'
import ActionSidebarButtons from '@containers/ActionSidebarButtons'
import { useAPIContext } from '@context/APIContext'
import type { Participant } from '@context/APIContext/hooks/useOperatorApi'
import { encodingsConverter } from '@helpers/encodings/converter'
import { useBooleanState } from '@hooks/useBooleanState'
import { useFormWrapperWithTrim, validateNotWhiteSpaceOnly } from '@hooks/useFormWrapperWithTrim'
import { ORGANIZATION_ROLE_OPTIONS_NEW_DESIGN } from '@routes/Users/ParticipantsTable/constants'
import cn from 'classnames'

import { PROFILE_OPTIONS } from './constants'
import styles from './ManageParticipantSidebar.module.scss'

export interface ManageParticipantSidebarProps {
  onSuccess?: () => Promise<void>
  user?: Participant
}

export type ManageParticipantSidebar = ManageParticipantSidebarProps & Required<PopupProps>

type ParticipantFormValues = Omit<Participant, 'organization'> & {
  organization: {
    id: string
    name: string
    role: string
    inn: string
  }
}

export type FormProps = {
  id: string
  firstName: string
  lastName: string
  email: string
  organization: {
    inn: string
    name: string
    role: string
  }
  position: string
  thumbprint: string
}

const participantTitleMap = {
  add: 'Добавить участника',
  edit: 'Редактирование участника',
} as const

const ManageParticipantSidebar: FC<ManageParticipantSidebar> = ({
  isOpen,
  onSuccess,
  onClose,
  user,
}) => {
  const {
    id: userId,
    firstName,
    lastName,
    email,
    organization,
    isEnabled,
    profile,
    position,
    innFl,
  } = user ?? {}

  const {
    operatorApi: { updateUserById, saveUser },
  } = useAPIContext()

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid },
  } = useFormWrapperWithTrim(
    useForm<ParticipantFormValues>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        id: userId ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        email: email ?? '',
        innFl: innFl ?? '',
        organization: organization ?? {
          inn: '',
          name: '',
          role: '',
          id: '',
        },
        isEnabled: isEnabled ?? true,
        profile: profile ?? UserProfiles.HEADER,
        position: position ?? '',
      },
    }),
  )

  const {
    booleanState: isLoadingSaveUser,
    setBooleanStateToFalse: setLoadingSaveUserToFalse,
    setBooleanStateToTrue: setLoadingSaveUserToTrue,
  } = useBooleanState(false)

  const {
    booleanState: isLoadingBlockUser,
    setBooleanStateToFalse: setLoadingBlockUserToFalse,
    setBooleanStateToTrue: setLoadingBlockUserToTrue,
  } = useBooleanState(false)

  const submitForm: SubmitHandler<ParticipantFormValues> = useCallback(
    async (formState) => {
      const {
        email = '',
        profile = UserProfiles.HEADER,
        id,
        firstName,
        lastName,
        organization,
        position,
        isEnabled,
        innFl = '',
      } = formState

      if (isEnabled) {
        setLoadingSaveUserToTrue()
      } else {
        setLoadingBlockUserToTrue()
      }

      const preparedEmail = email ? encodingsConverter.ToUnicode(email) : ''

      try {
        if (!user) {
          await saveUser({
            inn: organization?.inn,
            email: preparedEmail,
            position,
            lastName,
            firstName,
            innFl,
            profile: profile ?? '',
          })
        } else {
          await updateUserById(id, {
            inn: organization.inn,
            email: preparedEmail,
            position,
            lastName,
            firstName,
            profile: profile ?? '',
            innFl,
            isEnabled,
          })
        }

        await onSuccess?.()

        onClose()
      } finally {
        if (isEnabled) {
          setLoadingSaveUserToFalse()
        } else {
          setLoadingBlockUserToFalse()
        }
      }
    },
    [
      user,
      onClose,
      onSuccess,
      saveUser,
      setLoadingBlockUserToTrue,
      setLoadingSaveUserToFalse,
      setLoadingBlockUserToFalse,
      setLoadingSaveUserToTrue,
      updateUserById,
    ],
  )

  const onSubmit = useCallback(
    async (params?: Record<string, unknown>) => await submitForm({ ...getValues(), ...params }),
    [getValues, submitForm],
  )
  const handleSaveUser = useCallback(
    async () => await handleSubmit(onSubmit)(),
    [handleSubmit, onSubmit],
  )
  const handleBlockUser = useCallback(async () => await onSubmit({ isEnabled: false }), [onSubmit])
  const handleUnBlockUser = useCallback(() => onSubmit({ isEnabled: true }), [onSubmit])
  const handleBackFormState = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  const roleOption = useMemo(
    () => ORGANIZATION_ROLE_OPTIONS_NEW_DESIGN.find((item) => item.value === organization?.role),
    [organization?.role],
  )

  const profileOption = useMemo(
    () => PROFILE_OPTIONS.find((item) => item.value === profile),
    [profile],
  )

  return (
    <Sidebar
      isOpen={isOpen}
      title={user ? participantTitleMap.edit : participantTitleMap.add}
      drawerClassName={styles.documentView__drawer}
      contentClassName={styles['documentView__drawer-content']}
      actions={
        <ActionSidebarButtons
          data={user}
          isLoadingBlockUser={isLoadingBlockUser}
          isLoadingSaveUser={isLoadingSaveUser}
          handleSaveUser={handleSaveUser}
          handleBlockUser={handleBlockUser}
          handleUnBlockUser={handleUnBlockUser}
          handleBackFormState={handleBackFormState}
          isDisabledSaveButton={!isValid}
        />
      }
      onClose={onClose}
    >
      {user && !isEnabled ? (
        <div className={styles.content}>
          <div className={styles.content__head}>
            <Typography.Body
              variant="bodyMMedium"
              className={cn(styles.content__headBlocked, styles.content__headItem)}
            >
              ЗАБЛОКИРОВАН
            </Typography.Body>
            <Typography.Headline variant="headlineH2" className={styles.content__headName}>
              {`${firstName} ${lastName}`}
            </Typography.Headline>
            {email && (
              <Typography.Caption variant="captionMAllcaps" className={styles.content__headCaption}>
                {email}
              </Typography.Caption>
            )}
          </div>
          <div className={styles.section}>
            <FieldInfo label="Роль в организации" className={styles.section__item}>
              {profileOption?.displayValue ?? ''}
            </FieldInfo>
          </div>
          <div className={styles.section}>
            <Typography.Headline variant="headlineH4" className={styles.section__head}>
              Реквизиты организации
            </Typography.Headline>
            <FieldInfo label="Организация" className={styles.section__item}>
              {organization?.name}
            </FieldInfo>
            <FieldInfo label="ИНН" className={styles.section__item}>
              {organization?.inn}
            </FieldInfo>
          </div>
          <div className={styles.section}>
            <Typography.Headline variant="headlineH4" className={styles.section__head}>
              Системные данные
            </Typography.Headline>
            <FieldInfo label="ID пользователя" className={styles.section__item}>
              {userId}
            </FieldInfo>
            <FieldInfo label="Тип организации" className={styles.section__item}>
              {roleOption?.displayValue ?? ''}
            </FieldInfo>
          </div>
        </div>
      ) : (
        <form className={styles.section__form}>
          <div className={styles.content}>
            <div className={styles.section}>
              <Typography.Headline variant="headlineH4" className={styles.section__head}>
                Личные данные
              </Typography.Headline>
              <ControlledInput
                control={control}
                name="lastName"
                rules={{ required: defaultRHFValidation.required }}
                inputProps={{
                  fixWidth: true,
                  type: 'text',
                  label: 'Фамилия',
                  view: 'secondary',
                  rootClassName: styles.section__item,
                }}
              />
              <ControlledInput
                control={control}
                name="firstName"
                inputProps={{
                  fixWidth: true,
                  type: 'text',
                  label: 'Имя',
                  view: 'secondary',
                  rootClassName: styles.section__item,
                }}
                rules={{
                  required: defaultRHFValidation.required,
                  pattern: Patterns.TruthlyString,
                }}
              />
              <ControlledInput
                control={control}
                name="email"
                rules={{ pattern: defaultRHFValidation.email }}
                inputProps={{
                  fixWidth: true,
                  label: 'Email',
                  autoComplete: 'off',
                  view: 'secondary',
                  rootClassName: styles.section__item,
                }}
              />
              <ControlledMaskInput
                control={control}
                name="innFl"
                inputProps={{
                  label: 'ИНН физического лица',
                  mask: '999999999999',
                  view: 'secondary',
                  fixWidth: true,
                  rootClassName: styles.section__item,
                }}
                rules={{
                  required: defaultRHFValidation.required,
                  pattern: {
                    value: Patterns.Numbers,
                    message: 'ИНН физического лица должен состоять из 12 цифр',
                  },
                }}
              />
              <FieldInfo
                label="Право подписи"
                className={styles.section__tags}
                labelClassName={styles.section__tagsLabel}
              >
                <ControlledRadioGroup
                  control={control}
                  name={'profile'}
                  radioGroupProps={{
                    type: 'tag',
                    direction: 'horizontal',
                    className: styles.section__tagsItem,
                  }}
                >
                  {PROFILE_OPTIONS.map((item) => (
                    <Tag.Item className={styles.section__tagsButton} value={item.value}>
                      {item.displayValue}
                    </Tag.Item>
                  ))}
                </ControlledRadioGroup>
              </FieldInfo>
              <ControlledInput
                name="position"
                control={control}
                rules={{ validate: validateNotWhiteSpaceOnly }}
                inputProps={{
                  fixWidth: true,
                  label: 'Должность',
                  type: 'text',
                  view: 'secondary',
                  caption: 'необязательно',
                  rootClassName: styles.section__item,
                }}
              />
            </div>

            <div className={styles.section}>
              <Typography.Headline variant="headlineH4" className={styles.section__head}>
                Реквизиты организации
              </Typography.Headline>
              <ControlledInput
                control={control}
                name="organization.name"
                rules={{ required: defaultRHFValidation.required }}
                inputProps={{
                  fixWidth: true,
                  label: 'Название',
                  view: 'secondary',
                  rootClassName: styles.section__item,
                  readOnly: !!user,
                  disabled: !!user,
                }}
              />
              <ControlledMaskInput
                control={control}
                name="organization.inn"
                inputProps={{
                  label: 'ИНН',
                  mask: '9999999999',
                  view: 'secondary',
                  fixWidth: true,
                  rootClassName: styles.section__item,
                }}
                rules={{
                  required: defaultRHFValidation.required,
                  pattern: { value: Patterns.Numbers, message: 'ИНН должен состоять из 10 цифр' },
                }}
              />
            </div>
            {user && (
              <div className={styles.section}>
                <Typography.Headline variant="headlineH4" className={styles.section__head}>
                  Системные данные
                </Typography.Headline>
                <ControlledInput
                  control={control}
                  name="id"
                  inputProps={{
                    fixWidth: true,
                    maxLength: 10,
                    label: 'ID пользователя',
                    view: 'secondary',
                    rootClassName: styles.section__item,
                    readOnly: true,
                  }}
                />
                <Input
                  readOnly
                  disabled
                  fixWidth
                  view="secondary"
                  label="Тип организации"
                  rootClassName={styles.section__item}
                  value={roleOption?.displayValue ?? 'Не указан'}
                />
              </div>
            )}
          </div>
        </form>
      )}
    </Sidebar>
  )
}

export default ManageParticipantSidebar
