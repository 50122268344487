import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForAgreementStabilizationNegotiationsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      agreementLinkStabilizationPeriod: {
        propertyId: objectFromPropertyState.ruleLinkStabilization?.propertyId,
        type: objectFromPropertyState.ruleLinkStabilization?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStabilization?.lastUpdateDt,
      },
      stabilizationPeriod: {
        propertyId: objectFromPropertyState.stabilizationPeriod?.propertyId,
        type: objectFromPropertyState.stabilizationPeriod?.type,
        lastUpdateDt: objectFromPropertyState.stabilizationPeriod?.lastUpdateDt,
      },
      stabilizationPeriodExtend: {
        propertyId: objectFromPropertyState.stabilizationPeriodExtend?.propertyId,
        type: objectFromPropertyState.stabilizationPeriodExtend?.type,
        lastUpdateDt: objectFromPropertyState.stabilizationPeriodExtend?.lastUpdateDt,
      },
      newStabilizationPeriod: {
        propertyId: objectFromPropertyState.newStabilizationPeriod?.propertyId,
        type: objectFromPropertyState.newStabilizationPeriod?.type,
        lastUpdateDt: objectFromPropertyState.newStabilizationPeriod?.lastUpdateDt,
      },
      ruleLinkChangeStabilization: {
        propertyId: objectFromPropertyState.ruleLinkChangeStabilization?.propertyId,
        type: objectFromPropertyState.ruleLinkChangeStabilization?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkChangeStabilization?.lastUpdateDt,
      },
      reasonChangeStabilization: {
        propertyId: objectFromPropertyState.reasonChangeStabilization?.propertyId,
        type: objectFromPropertyState.reasonChangeStabilization?.type,
        lastUpdateDt: objectFromPropertyState.reasonChangeStabilization?.lastUpdateDt,
      },
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  }
}

export { generatePropertiesObjectForAgreementStabilizationNegotiationsForm }
