import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import styles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import ObjectFormItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem'
import { SeventhConstructionSubObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldCollapseControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import DeleteIcon from '@icons/DeleteIcon.svg'
import arrowSubdirectoryIcon from '@icons/navigation/arrow-subdirectory.svg'
import cn from 'classnames'

interface SubObjectItemProps {
  subObjectId: string
  blockViewIsValidating: boolean
  editMode: boolean
  //Если много объектов, необходимо все сворачивать и анмаунтить
  expandedRenderMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  name: SeventhConstructionSubObjectsArrayPathName
  indexOfObject: number
  onDeleteSubObject: VoidFunction
}

const SubObjectItem: FC<SubObjectItemProps> = ({
  subObjectId,
  blockViewIsValidating,
  editMode,
  expandedRenderMode,
  indexOfObject,
  formInstance,
  name,
  onDeleteSubObject,
}) => {
  const { isExpanded: isShowObjectItemContent, onToggleCollapse: toggleShowObjectItemContent } =
    useFormCollapseControl({
      initialExpanded: expandedRenderMode,
      name,
      watcher: ProjectSZPKFieldCollapseControlUpdateWatcher,
    })

  const handleDeleteSubObject = async () => {
    await onDeleteSubObject()
  }

  return (
    <FlippedWithCollapse flipId={subObjectId}>
      <div className={cn(styles.objectItem, styles['objectItem--subObject'])}>
        <div className={styles.objectItem__header}>
          <ObjectStepWrapperHeaderNode
            buttonProps={{
              onClick: toggleShowObjectItemContent,
              leadingIcon: {
                src: arrowSubdirectoryIcon,
                size: 'xs',
              },
              trailingIcon: {
                className: cn({
                  [styles['objectItem__header-button__icon--active']]: isShowObjectItemContent,
                }),
              },
            }}
          >
            ОБЪЕКТ {indexOfObject + 1}
          </ObjectStepWrapperHeaderNode>
          {editMode && (
            <AsyncWrapper promise={handleDeleteSubObject}>
              {({ isLoading, wrappedPromise }) => (
                <Button
                  disabled={isLoading}
                  variant={'buttonSMedium'}
                  size={'2xs'}
                  color={'negative'}
                  view={'plain'}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  leadingIcon={{
                    src: DeleteIcon,
                  }}
                  onClick={wrappedPromise}
                >
                  Удалить
                </Button>
              )}
            </AsyncWrapper>
          )}
        </div>
        <div className={styles.objectItem__content}>
          <ObjectFormItem
            isShowObjectItemContent={isShowObjectItemContent}
            blockViewIsValidating={blockViewIsValidating}
            editMode={editMode}
            formInstance={formInstance}
            formName={name}
          />
        </div>
      </div>
    </FlippedWithCollapse>
  )
}

export default SubObjectItem
