import React, { DetailedHTMLProps, FC, memo, ReactNode } from 'react'

import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import cn from 'classnames'

import styles from './ControlContainer.module.scss'

interface ControlContainerProps
  extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string
  color?: 'primary' | 'secondary' | 'tertiary' | 'negative'
  contentDirection?: 'column' | 'row'
  titleClassName?: string
  additionalMainContent?: ReactNode
  leadingNodeContent?: ReactNode
  leadingNodeWrapperClassName?: string
  trailingNodeContent?: ReactNode
  trailingNodeWrapperClassName?: string
}

const ControlContainer: FC<ControlContainerProps> = ({
  title,
  color = 'primary',
  contentDirection = 'column',
  additionalMainContent,
  titleClassName,
  leadingNodeContent,
  leadingNodeWrapperClassName,
  trailingNodeContent,
  trailingNodeWrapperClassName,
  ...divProps
}) => {
  return (
    <div
      {...divProps}
      className={cn(
        styles.controlContainer,
        {
          [styles[`controlContainer--${color}`]]: color,
        },
        divProps.className,
      )}
    >
      <div className={styles.controlContainer__content}>
        {leadingNodeContent && (
          <div
            className={cn(
              styles['controlContainer__leadingNode-wrapper'],
              {
                [styles[`controlContainer__leadingNode-wrapper--${color}`]]: color,
              },
              leadingNodeWrapperClassName,
            )}
          >
            {leadingNodeContent}
          </div>
        )}
        <div
          className={cn(styles['controlContainer__content-info'], {
            [styles[`controlContainer__content-info--${contentDirection}`]]: contentDirection,
          })}
        >
          <TypographyWithTooltip.Body
            variant={'bodyLMedium'}
            className={cn(styles['controlContainer__content-title'], titleClassName)}
            textClampProps={{
              clamp: 2,
            }}
            tooltipProps={{
              position: 'top',
              offset: [0, 10],
              targetClassName: styles.tooltip__target,
              popoverClassName: styles.tooltip__popover,
            }}
          >
            {title}
          </TypographyWithTooltip.Body>
          {additionalMainContent}
        </div>
        {trailingNodeContent && (
          <div
            className={cn(
              styles['controlContainer__trailingNode-wrapper'],
              trailingNodeWrapperClassName,
            )}
          >
            {trailingNodeContent}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(ControlContainer)
