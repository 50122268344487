import { ChosenNpaThreeErrors } from '@components/RegistryNpa/Choose/types'
import type { ChosenPart } from '@components/RegistryNpa/ChooseOld/ManagerOld/types'
import {
  TGetModalListNpaResponse,
  TMasterModalNpaItemWithId,
} from '@context/APIContext/hooks/useNpaApi/'
import { compact } from '@helpers/array/compact'
import { filterArrayIntoTwoChunks } from '@helpers/array/filterArrayIntoChunks'
import {
  IMasterNpaItem,
  IMasterNpaItemNormalized,
  IMasterNpaItemNormalizedNew,
  INpaItem,
  INpaItemNormalized,
  INpaItemNormalizedNew,
  INpaThreeLayout,
  INpaThreeLayoutWithChangesNpaRegistry,
  IPartClassification,
  IPartClassificationLayout,
  NpaStatusProps,
  TMasterModalNpaItemNew,
  TMasterModalNpaItemNormalized,
  TMasterModalNpaItemNormalizedNew,
  TMasterModalNpaItemWithChosenParts,
  TNpaDirectionsFromDictWithOrder,
  TNpaDraftPartForBody,
  TPartClassificationLayoutItem,
} from '@services/NPA/NPA.entity'
import NPAHelpers from '@services/NPA/NPA.helpers'

interface DataForBuildThree {
  mapOfClassificationPart: Map<string, IPartClassification> | undefined
  directions: TMasterModalNpaItemNormalized[]
  npas: IMasterNpaItemNormalized[]
  parts: INpaItemNormalized[]
  chosenParts: ChosenPart[]
}

interface DataForBuildThreeNew {
  mapOfClassificationPart: Map<string, IPartClassification> | undefined
  directions: TMasterModalNpaItemNormalizedNew[]
  npas: IMasterNpaItemNormalizedNew[]
  parts: INpaItemNormalizedNew[]
  chosenParts: ChosenPart[]
  threeErrors?: ChosenNpaThreeErrors | null
}

interface DataForBuildLayoutThree {
  initialParts: TNpaDraftPartForBody[]
  mapOfClassificationPart: Map<string, IPartClassification>
  directions: TNpaDirectionsFromDictWithOrder
  helpers: {
    getNpaList: (idsOfNpa: string[]) => Promise<Omit<IMasterNpaItem, 'parts'>[]>
    getPartsByNpaID: (npaId: string, partsId: string[]) => Promise<INpaItem & NpaStatusProps>
  }
}

class NPAService {
  normalizeNpaData(npaData: TGetModalListNpaResponse) {
    const preparedDirections: TMasterModalNpaItemNormalized[] = [],
      preparedNpas: IMasterNpaItemNormalized[] = [],
      preparedParts: INpaItemNormalized[] = []

    npaData.forEach((direction) => {
      preparedDirections.push({
        direction: direction.direction,
        minInvestmentAmount: direction.minInvestmentAmount,
      })

      direction.npas.forEach((npa) => {
        preparedNpas.push({
          id: npa.id,
          level: npa.level,
          type: npa.type,
          region: npa.region,
          number: npa.number,
          date: npa.date,
          name: npa.name,
          redactionDate: npa.redactionDate,
          redaction: npa.redaction,
          redactionNumber: npa.redactionNumber,
          parentDirection: direction.direction,
          isActual: npa.isActual,
          isExcluded: npa.isExcluded,
          complexName: npa.complexName,
        })

        npa.parts.forEach((part) => {
          preparedParts.push({
            id: part.id,
            partId: part.id,
            part: part.part,
            npaParentId: npa.id,
            classificationId: part.classificationId,
          })
        }, [])
      })
    })

    return {
      preparedNpas,
      preparedDirections,
      preparedParts,
    }
  }

  normalizeNpaDataNew(npaData: TMasterModalNpaItemWithId[]) {
    const preparedDirections: TMasterModalNpaItemNormalizedNew[] = [],
      preparedNpas: IMasterNpaItemNormalizedNew[] = [],
      preparedParts: INpaItemNormalizedNew[] = []

    npaData.forEach((direction) => {
      preparedDirections.push({
        id: direction.id,
        isCollapsed: false,
        direction: direction.direction,
        minInvestmentAmount: direction.minInvestmentAmount,
      })

      direction.npas.forEach((npa) => {
        preparedNpas.push({
          isCollapsed: false,
          id: npa.id,
          level: npa.level,
          type: npa.type,
          region: npa.region,
          number: npa.number,
          date: npa.date,
          name: npa.name,
          redactionDate: npa.redactionDate,
          redaction: npa.redaction,
          redactionNumber: npa.redactionNumber,
          parentDirection: direction.direction,
          isActual: npa.isActual,
          isExcluded: npa.isExcluded,
          complexName: npa.complexName,
        })

        npa.parts.forEach((part) => {
          preparedParts.push({
            isChosen: false,
            id: part.id,
            partId: part.id,
            part: part.part,
            npaParentId: npa.id,
            classificationId: part.classificationId,
            parentDirection: direction.direction,
            partCorrection: '',
            justification: '',
          })
        }, [])
      })
    })

    return {
      directions: preparedDirections,
      npas: preparedNpas,
      parts: preparedParts,
    }
  }

  buildThreeToRender(
    npaList: TMasterModalNpaItemWithId[],
    chosenParts: ChosenPart[],
  ): TMasterModalNpaItemNew[] {
    return npaList.map((direction) => ({
      ...direction,
      isCollapsed: false,
      npas: direction.npas.map((npa) => ({
        ...npa,
        isCollapsed: false,
        parts: npa.parts.map((part) => {
          const currentChosenPart = chosenParts.find((chosenPart) => chosenPart.partId === part.id)

          return {
            ...part,
            isChosen: !!currentChosenPart,
            parentDirection: direction.direction,
            justification: currentChosenPart?.justification || '',
            partCorrection: currentChosenPart?.partCorrection || '',
          }
        }),
      })),
    }))
  }

  fromLayoutInterfaceToModalChosen(layoutStructure: INpaThreeLayout[] | null) {
    if (!layoutStructure) return

    return layoutStructure
      ?.reduce<TPartClassificationLayoutItem[]>((prevValue, nextValue) => {
        const preparedValue = nextValue.classifications
          .map((classification) => classification.parts)
          .flat()

        return [...prevValue, ...preparedValue]
      }, [])
      .map((part) => ({
        partId: part.id,
        npaId: part.npaInfo.id,
        classificationId: part.classificationId,
        justification: part.justification,
        partCorrection: part.partCorrection,
      }))
  }

  buildThreeFromChosen({
    npas,
    directions,
    mapOfClassificationPart,
    chosenParts,
    parts,
  }: DataForBuildThree) {
    const parentsId = new Set(chosenParts.map((part) => part.npaId))
    const addedDirections = new Set(
      chosenParts.map((part) => mapOfClassificationPart?.get(part.classificationId)?.direction),
    )

    const npasWithoutParts = npas.filter((npa) => parentsId.has(npa.id))

    const directionWithoutNpas = directions.filter((direction) =>
      addedDirections.has(direction.direction),
    )

    return directionWithoutNpas.map((direction) => ({
      ...direction,
      npas: npasWithoutParts
        .filter((npas) => npas.parentDirection === direction.direction)
        .map((npa) => ({
          ...npa,
          parts: compact(
            chosenParts.map((part) => {
              const elementInAllParts = parts.find(
                (item) =>
                  part.npaId === npa.id &&
                  part.parentDirection === npa.parentDirection &&
                  item.id === part.partId,
              )

              if (!elementInAllParts) return

              return {
                ...elementInAllParts,
                parentDirection: npa.parentDirection,
                justification: part.justification,
                partCorrection: part.partCorrection,
              }
            }),
          ),
        }))
        .filter((npa) => !!npa.parts.length),
    })) as TMasterModalNpaItemWithChosenParts[]
  }

  buildThreeFromChosenNew({
    npas,
    directions,
    mapOfClassificationPart,
    chosenParts,
    parts,
    threeErrors,
  }: DataForBuildThreeNew): TMasterModalNpaItemNew[] {
    const parentsId = new Set(chosenParts.map((part) => part.npaId))
    const addedDirections = new Set(
      chosenParts.map((part) => mapOfClassificationPart?.get(part.classificationId)?.direction),
    )

    const npasWithoutParts = npas.filter((npa) => parentsId.has(npa.id))

    const directionWithoutNpas = directions.filter((direction) =>
      addedDirections.has(direction.direction),
    )

    return directionWithoutNpas.map((direction) => {
      const directionError = threeErrors?.get(direction.direction)

      return {
        ...direction,
        isCollapsed: !!directionError,
        npas: npasWithoutParts
          .filter((npas) => npas.parentDirection === direction.direction)
          .map((npa) => {
            const npaError = directionError?.[npa.id]

            return {
              ...npa,
              isCollapsed: !!npaError,
              parts: compact(
                chosenParts.map((part) => {
                  const elementInAllParts = parts.find(
                    (item) =>
                      part.npaId === npa.id &&
                      part.parentDirection === npa.parentDirection &&
                      item.id === part.partId,
                  )

                  if (!elementInAllParts) return

                  return {
                    ...elementInAllParts,
                    isChosen: true,
                    hasError: !!npaError?.[part.partId],
                    parentDirection: npa.parentDirection,
                    justification: part.justification,
                    partCorrection: part.partCorrection,
                  }
                }),
              ),
            }
          })
          .filter((npa) => !!npa.parts.length),
      }
    }) as TMasterModalNpaItemNew[]
  }

  async buildThreeForNpaLayout({
    initialParts,
    mapOfClassificationPart,
    directions,
    helpers: { getNpaList, getPartsByNpaID },
  }: DataForBuildLayoutThree): Promise<INpaThreeLayoutWithChangesNpaRegistry[]> {
    const initialPartObjectMap = initialParts.reduce((previousValue, currentValue) => {
      return {
        ...previousValue,
        [currentValue.partId]: currentValue,
      }
    }, {})

    const npaIds = initialParts.map((part) => part.npaId)

    const npas = await getNpaList(npaIds)
    const npasInObjectMap = npas.reduce((previousValue, currentValue) => {
      return {
        ...previousValue,
        [currentValue.id]: currentValue,
      }
    }, {})

    const partsPromises: Promise<INpaItem & NpaStatusProps>[] = []

    npas.forEach((npa) => {
      partsPromises.push(
        getPartsByNpaID(
          npa.id,
          compact(
            initialParts.map((part) => (part.npaId === npa.id ? part.partId : undefined)),
          ) as string[],
        ),
      )
    })

    const preparedParts = await Promise.all(partsPromises).then((data) => {
      const parts: TPartClassificationLayoutItem[] = data.flat().map((item) => {
        const extraPartInfo = initialPartObjectMap[item.id]

        return {
          ...item,
          // Это propertyId. Используется в новой реализации НПА
          partId: initialPartObjectMap[item.id]?.id || item.id,
          justification: extraPartInfo.justification,
          partCorrection: extraPartInfo.partCorrection,
          npaInfo: npasInObjectMap[initialPartObjectMap[item.id].npaId],
        }
      })

      const [filteredParts, defaultParts] = filterArrayIntoTwoChunks<TPartClassificationLayoutItem>(
        parts,
        (part) => NPAHelpers.isNotActualPart(part) || NPAHelpers.isExcludedPart(part),
      )

      const sortedFilteredParts = filteredParts.sort(
        (partA, partB) =>
          Number(NPAHelpers.isNotActualPart(partB)) - Number(NPAHelpers.isNotActualPart(partA)) ||
          partA.npaInfo.number.localeCompare(partB.npaInfo.number) ||
          partA.part.localeCompare(partB.part),
      )

      const sortedDefaultParts = defaultParts.sort(
        (partA, partB) =>
          partA.npaInfo.number.localeCompare(partB.npaInfo.number) ||
          partA.part.localeCompare(partB.part),
      )

      return [...sortedFilteredParts, ...sortedDefaultParts]
    })

    const uniqClassificationsIds = Array.from(
      new Set(preparedParts.map((part) => part.classificationId)),
    )

    const uniqDirectionsForClassifications = directions.filter(({ type }) =>
      uniqClassificationsIds.some((classificationId) => {
        const { direction } = mapOfClassificationPart.get(classificationId) || {}

        return direction && type === direction
      }),
    )

    const threeWithSortedClassifications = uniqDirectionsForClassifications.map(
      ({ type, order }) => {
        const filteredClassifications = compact(
          uniqClassificationsIds.map((id) => {
            const classification = mapOfClassificationPart.get(id)

            if (classification?.direction !== type) return

            const { children, ...restClassification } = classification

            return {
              ...restClassification,
              description: (() => {
                if (classification?.level !== '3') {
                  return classification?.description
                }

                const parentClassificationElement = classification
                  ? mapOfClassificationPart.get(classification.parentId)
                  : undefined

                return parentClassificationElement?.header
                  ? `${parentClassificationElement?.header} ${classification?.header ?? ''}`
                  : classification?.description
              })(),
              parts: compact(
                preparedParts.map((part) => (part.classificationId === id ? part : undefined)),
              ),
            }
          }),
        ) as IPartClassificationLayout[]

        return {
          direction: type,
          order,
          classifications: filteredClassifications.sort(NPAHelpers.compareClassifications),
        }
      },
    ) as INpaThreeLayout[]

    return (
      threeWithSortedClassifications.map((item) => ({
        ...item,
        isChangesNpaRegistry: item.classifications.some((classification) =>
          classification.parts.some(
            (part) => NPAHelpers.isNotActualPart(part) || NPAHelpers.isExcludedPart(part),
          ),
        ),
      })) as INpaThreeLayoutWithChangesNpaRegistry[]
    ).sort(NPAHelpers.compareNpaThree)
  }
}

export default new NPAService()
