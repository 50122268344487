import { useMemo } from 'react'

import { IModifiedDocumentSetInfoForRenderToArray } from '@services/Documents/documents.entity'

export const useFormsFromMapOfSetsToRender = (
  mapOfSetsToRender: IModifiedDocumentSetInfoForRenderToArray[] | undefined,
) => {
  const forms = useMemo(() => {
    const allDocumentSets = mapOfSetsToRender?.map(({ documentSets }) => documentSets).flat()
    const allDocumentsTypeInfo = allDocumentSets
      ?.map(({ documentTypeInfo }) => documentTypeInfo)
      .flat()
    const allForms = allDocumentsTypeInfo?.map(({ forms }) => forms || []).flat() || []

    return allForms
  }, [mapOfSetsToRender])

  return forms
}
