import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import type { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import {
  aAChangingVolumeReimbursementBlockValues,
  mapOfAAChangingVolumeReimbursementMenu,
} from '@components/Forms/AAChangingVolumeReimbursementForm/const'
import AAChangingVolumeReimbursementForms from '@components/Forms/AAChangingVolumeReimbursementForm/Forms'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'

const mapOfAAChangingVolumeReimbursementForms: FormItemProps = {
  '1': {
    node: <AAChangingVolumeReimbursementForms.One />,
  },
  '2': {
    node: <AAChangingVolumeReimbursementForms.Two />,
  },
  '3': {
    node: <AAChangingVolumeReimbursementForms.Three />,
  },
  '4': {
    node: <AAChangingVolumeReimbursementForms.Four />,
  },
  '5': {
    node: <AAChangingVolumeReimbursementForms.Five />,
  },
  '6': {
    node: <AAChangingVolumeReimbursementForms.Six />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '7': {
    node: <AAChangingVolumeReimbursementForms.Seven />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const AAChangingVolumeReimbursementForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AAChangingVolumeReimbursementFormValues>()

  const {
    state: { formIsLoading },
  } = useAAChangingVolumeReimbursementManager()

  const isFederalSZPK = useWatch({
    name: aAChangingVolumeReimbursementBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })
  const isMunicipalitiesRender = !!useWatch({
    name: aAChangingVolumeReimbursementBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length
  const isPaper = useWatch({
    name: aAChangingVolumeReimbursementBlockValues.additionalFields.isPaper,
    control: formInstance.control,
  })

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAAChangingVolumeReimbursementForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    if (!isPaper) {
      delete readyForms['7']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender, isPaper])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={initialMenu ?? mapOfAAChangingVolumeReimbursementMenu}
      mapOfForms={currentForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(AAChangingVolumeReimbursementForm)
