import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type {
  IRidExploitation,
  ProjectSZPKFormValues,
} from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const ridExploitationsProjectSZPKValidationMap: FormValuesValidationSection<
  keyof IRidExploitation,
  ProjectSZPKFormValues
> = {
  ridName: {
    required: defaultRHFValidation.required,
  },
  ridCommissioningDate: {
    required: defaultRHFValidation.required,
  },
  ridCommissionNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { ridExploitationsProjectSZPKValidationMap }
