import { useCallback } from 'react'
import { type UseFormReturn, useFieldArray } from 'react-hook-form'

import { AttachmentMultipleItemService } from '@components/Attachments/Attachment/MultipleItem/AttachmentMultipleItem/helpers'
import type {
  HandleAddFileItemProps,
  HandleInsertFileItemProps,
  HandleInsertFolderItemProps,
  HandleRemoveFileItemProps,
  HandleRemoveFolderItemProps,
  HandleUpdateFileItemProps,
  HandleUpdateFolderItemProps,
} from '@components/Attachments/Attachment/MultipleItem/AttachmentMultipleItem/types'
import { attachmentsFormNames } from '@components/Attachments/constants'
import type { AttachmentsFormValue, TMultipleItem } from '@components/Attachments/types'
import type { IAttachmentFolderItem } from '@interfaces/documents'

const { findMultipleItemIndexById } = AttachmentMultipleItemService

const useAttachmentMultipleItemFieldArray = (formInstance: UseFormReturn<AttachmentsFormValue>) => {
  const {
    fields: multipleItems,
    replace,
    append,
    update,
    insert,
    remove,
  } = useFieldArray({
    control: formInstance.control,
    name: attachmentsFormNames.multipleItems,
    keyName: 'keyNameId',
  })

  const handleAddFileItem = useCallback(
    ({ attachmentFileItem, folder }: HandleAddFileItemProps) => {
      if (!folder) {
        return append(attachmentFileItem)
      }

      const currentMultipleItems = formInstance.getValues(attachmentsFormNames.multipleItems)

      const folderIndex = findMultipleItemIndexById(currentMultipleItems, folder.id)

      update(folderIndex, folder)
    },
    [append, formInstance, update],
  )

  const handleUpdateFileItem = useCallback(
    ({ attachmentFileItem, folder }: HandleUpdateFileItemProps) => {
      const currentMultipleItems = formInstance.getValues(attachmentsFormNames.multipleItems)

      if (!folder) {
        const attachmentFileItemIndex = findMultipleItemIndexById(
          currentMultipleItems,
          attachmentFileItem.id,
        )

        return update(attachmentFileItemIndex, attachmentFileItem)
      }

      const folderIndex = findMultipleItemIndexById(currentMultipleItems, folder.id)

      update(folderIndex, folder)
    },
    [formInstance, update],
  )

  const handleRemoveFileItem = useCallback(
    ({ attachmentFileItem, folder }: HandleRemoveFileItemProps) => {
      const currentMultipleItems = formInstance.getValues(attachmentsFormNames.multipleItems)

      if (!folder) {
        const attachmentFileItemIndex = findMultipleItemIndexById(
          currentMultipleItems,
          attachmentFileItem.id,
        )

        return remove(attachmentFileItemIndex)
      }

      const folderIndex = findMultipleItemIndexById(currentMultipleItems, folder.id)

      update(folderIndex, folder)
    },
    [formInstance, remove, update],
  )

  const handleInsertFileItem = useCallback(
    ({ attachmentFileItem, attachmentFileIndex, folder }: HandleInsertFileItemProps) => {
      if (!folder) {
        return insert(attachmentFileIndex, attachmentFileItem)
      }

      const currentMultipleItems = formInstance.getValues(attachmentsFormNames.multipleItems)

      const folderIndex = findMultipleItemIndexById(currentMultipleItems, folder.id)

      const updatedFolder: IAttachmentFolderItem = {
        ...folder,
        documents: [
          ...folder.documents.slice(0, attachmentFileIndex),
          attachmentFileItem,
          ...folder.documents.slice(attachmentFileIndex),
        ],
      }

      update(folderIndex, updatedFolder)
    },
    [formInstance, insert, update],
  )

  const handleReplaceMultipleItems = useCallback(
    (items: TMultipleItem[]) => {
      replace(items)
    },
    [replace],
  )

  const handleUpdateFolderItem = useCallback(
    ({ folder, folderIndex }: HandleUpdateFolderItemProps) => {
      update(folderIndex, folder)
    },
    [update],
  )

  const handleInsertFolderItem = useCallback(
    ({ folder, folderIndex }: HandleInsertFolderItemProps) => {
      insert(folderIndex, folder)
    },
    [insert],
  )

  const handleRemoveFolderItem = useCallback(
    ({ folderIndex }: HandleRemoveFolderItemProps) => {
      remove(folderIndex)
    },
    [remove],
  )

  return {
    multipleItems,
    handleAddFileItem,
    handleUpdateFileItem,
    handleInsertFileItem,
    handleRemoveFileItem,
    handleReplaceMultipleItems,
    handleUpdateFolderItem,
    handleRemoveFolderItem,
    handleInsertFolderItem,
  }
}

export { useAttachmentMultipleItemFieldArray }
