import { OptionProps } from '@components/NewDesign/Select/model'
import { UserProfiles } from '@constants/types'

export const PROFILE_OPTIONS: OptionProps[] = [
  {
    displayValue: 'C правом подписи',
    value: UserProfiles.HEADER,
  },
  {
    displayValue: 'Без права подписи',
    value: UserProfiles.EMPLOYEE,
  },
]
