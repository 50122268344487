import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStage,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { compact } from '@helpers/array/compact'
import { isNotEmptyString, isUndefined } from '@helpers/checkTypes'

const generateMenuListForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA = (
  formValues:
    | AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
    | UnpackNestedValue<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>,
  formModifier:
    | AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues
    | UnpackNestedValue<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>,
): NestedMapOfMenu[] => {
  const getMenuSectionWithError = (
    numberSectionOfMenu: keyof NestedMenuHashMap,
  ): NestedMapOfMenu | undefined => {
    if (isUndefined(initialHashMap[numberSectionOfMenu])) return

    const preparedChildrenSections =
      initialHashMap[numberSectionOfMenu]?.children?.map(
        (item): NestedMapOfMenu => ({
          ...item,
          hasError: !!formErrors?.[item.id],
        }),
      ) || []

    return {
      ...initialHashMap[numberSectionOfMenu],
      hasError:
        !!formErrors?.[numberSectionOfMenu] ||
        preparedChildrenSections.some((item) => item.hasError),
      children: preparedChildrenSections,
    }
  }

  const getMenuSectionWithChanged = (numberSectionOfMenu: keyof NestedMenuHashMap) => {
    if (isUndefined(initialHashMap[numberSectionOfMenu])) return

    const sectionModifier = formModifier?.[numberSectionOfMenu]

    const preparedChildrenSections =
      initialHashMap[numberSectionOfMenu]?.children?.map((item) => ({
        ...item,
        hasError: !!formErrors?.[item.id],
      })) || []

    return {
      ...initialHashMap[numberSectionOfMenu],
      hasError:
        !!formErrors?.[numberSectionOfMenu] ||
        preparedChildrenSections.some((item) => item.hasError),
      isChanged: !!sectionModifier?.isChanged,
      children: preparedChildrenSections,
    }
  }

  const getPreparedStagesMenu = (
    stage: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage,
    indexOfStage: number,
  ): NestedMapOfMenu => {
    const stagePath = `${initialHashMap['7'].id}.stages.${indexOfStage}`
    const stepError = formErrors?.['7']?.stages?.[indexOfStage]
    const stepModifier = formModifier?.['7'].stages?.[indexOfStage]

    const preparedConstructionStageMenu = ((): NestedMapOfMenu[] => {
      if (!stage.isConstruction && !stage.isReconstruction && !stage.isModernization) return []

      const constructionStagePath = `${stagePath}.constructionStage`
      const constructionStageError = stepError?.constructionStage

      const constructionObjectsPath = `${constructionStagePath}.constructionObjects`
      const permissionsObjectsPath = `${constructionStagePath}.permissionsObjects`
      const exploitationObjectsPath = `${constructionStagePath}.exploitation.exploitationObjects`
      const registrationObjectsPath = `${constructionStagePath}.registration.registrationObjects`

      const constructionStageState = stepModifier.constructionStage

      const constructionObjectsState = constructionStageState.constructionObjectsState
      const permissionsObjectsState = constructionStageState.permissionsObjectsState
      const exploitationObjectsState = constructionStageState.exploitation.exploitationObjectsState
      const registrationObjectsState = constructionStageState.registration.registrationObjectsState

      const preparedConstructionObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.constructionStage.constructionObjects ||
          !stage.constructionStage.constructionObjects.length
        )
          return []

        return stage.constructionStage.constructionObjects.map((object, indexOfObject) => {
          const objectModifier = constructionStageState.constructionObjects?.[indexOfObject]

          const objectPath = `${constructionObjectsPath}.${indexOfObject}`

          return {
            path: objectPath,
            id: objectPath,
            title: isNotEmptyString(object.objectName)
              ? object.objectName
              : `Основной объект №${indexOfObject + 1}`,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!constructionStageError?.constructionObjects?.[indexOfObject],
            parentBaseId: constructionObjectsPath,
            children: [],
          }
        })
      })()

      const preparedPermissionObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.constructionStage.permissionsObjects ||
          !stage.constructionStage.permissionsObjects.length
        )
          return []

        return stage.constructionStage.permissionsObjects.map((object, indexOfObject) => {
          const objectModifier = constructionStageState.permissionsObjects?.[indexOfObject]

          const objectPath = `${permissionsObjectsPath}.${indexOfObject}`

          return {
            path: objectPath,
            id: objectPath,
            parentBaseId: permissionsObjectsPath,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!constructionStageError?.permissionsObjects?.[indexOfObject],
            title: isNotEmptyString(object.permissionKind)
              ? object.permissionKind
              : `Разрешение №${indexOfObject + 1}`,
            children: [],
          }
        })
      })()

      const preparedExploitationObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.constructionStage.exploitation.exploitationObjects ||
          !stage.constructionStage.exploitation.exploitationObjects.length
        )
          return []

        return stage.constructionStage?.exploitation?.exploitationObjects.map(
          (object, indexOfObject) => {
            const objectModifier =
              constructionStageState.exploitation.exploitationObjects?.[indexOfObject]

            const objectPath = `${exploitationObjectsPath}.${indexOfObject}`

            return {
              path: objectPath,
              id: objectPath,
              parentBaseId: exploitationObjectsPath,
              isCollapsed: false,
              isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
              hasError:
                !!constructionStageError?.exploitation?.exploitationObjects?.[indexOfObject],
              title: isNotEmptyString(object.objectName)
                ? object.objectName
                : `Эксплуатация №${indexOfObject + 1}`,
              children: [],
            }
          },
        )
      })()

      const preparedRegistrationObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.constructionStage.registration.registrationObjects ||
          !stage.constructionStage.registration.registrationObjects.length
        )
          return []

        return stage.constructionStage.registration.registrationObjects.map(
          (object, indexOfObject) => {
            const objectModifier =
              constructionStageState.registration.registrationObjects?.[indexOfObject]

            const objectPath = `${registrationObjectsPath}.${indexOfObject}`

            return {
              path: objectPath,
              id: objectPath,
              parentBaseId: registrationObjectsPath,
              isCollapsed: false,
              isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
              hasError:
                !!constructionStageError?.registration?.registrationObjects?.[indexOfObject],
              title: isNotEmptyString(object.objectName)
                ? object.objectName
                : `Регистрация №${indexOfObject + 1}`,
              children: [],
            }
          },
        )
      })()

      return [
        {
          path: constructionObjectsPath,
          id: constructionObjectsPath,
          title: 'Объекты недвижимости',
          parentBaseId: stagePath,
          isCollapsed: false,
          isChanged:
            !!constructionObjectsState?.deletedElementFromList ||
            !!constructionObjectsState?.addedElementInList ||
            preparedConstructionObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!constructionStageError?.constructionObjects?.length,
          children: preparedConstructionObjects,
        },
        {
          path: permissionsObjectsPath,
          id: permissionsObjectsPath,
          title: 'Разрешения на объекты',
          parentBaseId: stagePath,
          isCollapsed: false,
          isChanged:
            !!permissionsObjectsState?.deletedElementFromList ||
            !!permissionsObjectsState?.addedElementInList ||
            preparedPermissionObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!constructionStageError?.permissionsObjects?.length,
          children: preparedPermissionObjects,
        },
        {
          path: exploitationObjectsPath,
          id: exploitationObjectsPath,
          title: 'Эксплуатация объектов',
          isCollapsed: false,
          isChanged:
            !!exploitationObjectsState?.deletedElementFromList ||
            !!exploitationObjectsState?.addedElementInList ||
            !!constructionStageState?.exploitation?.isChanged ||
            preparedExploitationObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!constructionStageError?.exploitation,
          parentBaseId: stagePath,
          children: preparedExploitationObjects,
        },
        {
          path: registrationObjectsPath,
          id: registrationObjectsPath,
          title: 'Регистрация объектов',
          isCollapsed: false,
          isChanged:
            !!registrationObjectsState?.deletedElementFromList ||
            !!registrationObjectsState?.addedElementInList ||
            !!constructionStageState?.registration?.isChanged ||
            preparedRegistrationObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!constructionStageError?.registration,
          parentBaseId: stagePath,
          children: preparedRegistrationObjects,
        },
      ]
    })()

    const preparedRidStageMenu = ((): NestedMapOfMenu[] => {
      if (!stage.isCreateRID) return []

      const ridStagePath = `${stagePath}.ridStage`
      const ridError = stepError?.ridStage

      const ridObjectsPath = `${ridStagePath}.ridObjects`
      const ridExploitationObjectsPath = `${ridStagePath}.exploitation.ridExploitationObjects`
      const ridRegistrationObjectsPath = `${ridStagePath}.registration.ridRegistrationsObjects`

      const ridStageState = stepModifier.ridStage

      const ridObjectsState = ridStageState.ridObjectsState
      const ridExploitationObjectsState = ridStageState.exploitation.ridExploitationObjectsState
      const ridRegistrationObjectsState = ridStageState.registration.ridRegistrationsObjectsState

      const preparedRidObjects = ((): NestedMapOfMenu[] => {
        if (!stage.ridStage?.ridObjects || !stage.ridStage?.ridObjects?.length) return []

        return stage.ridStage?.ridObjects?.map((object, indexOfRid) => {
          const objectModifier = ridStageState?.ridObjects?.[indexOfRid]

          const objectPath = `${ridObjectsPath}.${indexOfRid}`

          return {
            id: objectPath,
            path: objectPath,
            title: isNotEmptyString(object.ridName) ? object.ridName : `РИД ${indexOfRid + 1}`,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!ridError?.ridObjects?.[indexOfRid],
            parentBaseId: ridObjectsPath,
            children: [],
          }
        })
      })()

      const preparedRidExploitationObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.ridStage?.exploitation?.ridExploitationObjects ||
          !stage.ridStage?.exploitation?.ridExploitationObjects.length
        )
          return []

        return stage.ridStage?.exploitation?.ridExploitationObjects.map((object, indexOfRid) => {
          const objectModifier = ridStageState?.exploitation?.ridExploitationObjects?.[indexOfRid]

          const objectPath = `${ridExploitationObjectsPath}.${indexOfRid}`

          return {
            id: objectPath,
            path: objectPath,
            title: isNotEmptyString(object.ridName)
              ? object.ridName
              : `Эксплуатация РИД ${indexOfRid + 1}`,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!ridError?.exploitation?.ridExploitationObjects?.[indexOfRid],
            parentBaseId: ridExploitationObjectsPath,
            children: [],
          }
        })
      })()

      const preparedRidRegistrationObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.ridStage?.registration?.ridRegistrationsObjects ||
          !stage.ridStage?.registration?.ridRegistrationsObjects.length
        )
          return []

        return stage.ridStage?.registration?.ridRegistrationsObjects.map((object, indexOfRid) => {
          const objectModifier = ridStageState?.registration?.ridRegistrationsObjects?.[indexOfRid]

          const objectPath = `${ridRegistrationObjectsPath}.${indexOfRid}`

          return {
            id: objectPath,
            path: objectPath,
            title: isNotEmptyString(object.ridName)
              ? object.ridName
              : `Регистрация РИД ${indexOfRid + 1}`,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!ridError?.registration?.ridRegistrationsObjects?.[indexOfRid],
            parentBaseId: ridRegistrationObjectsPath,
            children: [],
          }
        })
      })()

      return [
        {
          id: ridObjectsPath,
          path: ridObjectsPath,
          title: 'Результаты интеллектуальной деятельности (РИД)',
          isCollapsed: false,
          isChanged:
            !!ridObjectsState?.deletedElementFromList ||
            preparedRidObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!ridError?.ridObjects?.length,
          parentBaseId: stagePath,
          children: preparedRidObjects,
        },
        {
          id: ridExploitationObjectsPath,
          path: ridExploitationObjectsPath,
          title: 'Эксплуатация РИД',
          isCollapsed: false,
          isChanged:
            !!ridExploitationObjectsState?.deletedElementFromList ||
            !!ridStageState?.exploitation?.isChanged ||
            preparedRidExploitationObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!ridError?.exploitation,
          parentBaseId: stagePath,
          children: preparedRidExploitationObjects,
        },
        {
          id: ridRegistrationObjectsPath,
          path: ridRegistrationObjectsPath,
          title: 'Регистрация РИД',
          isCollapsed: false,
          isChanged:
            !!ridRegistrationObjectsState?.deletedElementFromList ||
            !!ridStageState?.registration?.isChanged ||
            preparedRidRegistrationObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!ridError?.registration,
          parentBaseId: stagePath,
          children: preparedRidRegistrationObjects,
        },
      ]
    })()

    return {
      path: stagePath,
      id: stagePath,
      title: isNotEmptyString(stage.stepDescription)
        ? stage.stepDescription
        : `Мероприятие ${indexOfStage + 1}`,
      isCollapsed: false,
      isChanged:
        !!stepModifier?.isNew ||
        !!stepModifier?.isChanged ||
        [...preparedConstructionStageMenu, ...preparedRidStageMenu].some(
          ({ isChanged }) => isChanged,
        ),
      parentBaseId: initialHashMap['7'].id,
      hasError: !!stepError,
      children: [...preparedConstructionStageMenu, ...preparedRidStageMenu],
    }
  }

  const getPreparedStepActivityOthersMenu = (
    item: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStage,
    indexOfStep: number,
  ): NestedMapOfMenu[] => {
    if (!item.activityOthers || !item.activityOthers.length) return []

    return item.activityOthers?.map((activity, indexOfActivity): NestedMapOfMenu => {
      const stepAnchorPath = `${initialHashMap['8'].id}.stepActivityOthers.${indexOfStep}`

      const activityAnchorPath = `${stepAnchorPath}.activityOthers.${indexOfActivity}`

      const activityError =
        formErrors?.['8']?.stepActivityOthers?.[indexOfStep]?.activityOthers?.[indexOfActivity]

      const activityModifier =
        formModifier?.['8']?.stepActivityOthers?.[indexOfStep]?.activityOthers?.[indexOfActivity]

      const preparedMeasureObjectsMenu = ((): NestedMapOfMenu[] => {
        if (!activity.measureObjects || !activity.measureObjects.length) return []

        return activity.measureObjects.map((object, indexOfObject) => {
          const objectModifier = activityModifier?.measureObjects?.[indexOfObject]

          const objectPath = `${activityAnchorPath}.measureObjects.${indexOfObject}`

          return {
            path: objectPath,
            id: objectPath,
            parentBaseId: activityAnchorPath,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!activityError?.measureObjects?.length,
            title: isNotEmptyString(object.objectName)
              ? object.objectName
              : `Объект №${indexOfObject + 1}`,
            children: [],
          }
        })
      })()

      return {
        path: activityAnchorPath,
        id: activityAnchorPath,
        parentBaseId: initialHashMap['8'].id,
        title: isNotEmptyString(activity.otherActivityName)
          ? activity.otherActivityName
          : `Мероприятие ${indexOfActivity + 1}`,
        isCollapsed: false,
        isChanged:
          !!activityModifier?.isNew ||
          !!activityModifier?.isChanged ||
          !!preparedMeasureObjectsMenu?.some(({ isChanged }) => isChanged),
        hasError: !!activityError,
        children: preparedMeasureObjectsMenu,
      }
    })
  }

  const preparedStages = formValues['7'].stages?.map(getPreparedStagesMenu)

  const preparedStepActivityOtherMenu = formValues['8']?.stepActivityOthers?.flatMap(
    getPreparedStepActivityOthersMenu,
  )

  const tenthMenuSection = getMenuSectionWithError('10')

  const preparedHashMap: Record<string, NestedMapOfMenu | undefined> = {
    '1': getMenuSectionWithError('1'),
    '2': getMenuSectionWithError('2'),
    '3': getMenuSectionWithError('3'),
    '4': getMenuSectionWithError('4'),
    '5': getMenuSectionWithError('5'),
    '6': getMenuSectionWithChanged('6'),
    '7': {
      ...initialHashMap['7'],
      hasError: preparedStages.some(({ hasError }) => hasError),
      isChanged: preparedStages.some(({ isChanged }) => isChanged),
      children: preparedStages,
    },
    '8': {
      ...initialHashMap['8'],
      hasError: preparedStepActivityOtherMenu.some(({ hasError }) => hasError),
      isChanged: preparedStepActivityOtherMenu.some(({ isChanged }) => isChanged),
      children: preparedStepActivityOtherMenu,
    },
    '9': getMenuSectionWithChanged('9'),
    '10': !!tenthMenuSection
      ? {
          ...tenthMenuSection,
          isChanged: !!formModifier?.['10']?.technologicalRequirements?.some(
            ({ isNew, isChanged }) => isNew || isChanged,
          ),
        }
      : undefined,
    '11': getMenuSectionWithChanged('11'),
    '12': getMenuSectionWithChanged('12'),
  }

  return compact([
    preparedHashMap['1'],
    preparedHashMap['2'],
    preparedHashMap['3'],
    preparedHashMap['4'],
    preparedHashMap['5'],
    preparedHashMap['6'],
    preparedHashMap['7'],
    preparedHashMap['8'],
    preparedHashMap['9'],
    preparedHashMap['10'],
    preparedHashMap['11'],
    preparedHashMap['12'],
  ]) as NestedMapOfMenu[]
}

export { generateMenuListForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA }
