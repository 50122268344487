import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AAConnectionReorganizationImplementingLayout from './Layout'
import AAConnectionReorganizationImplementingModalManager from './Manager'
import AAConnectionReorganizationImplementingMenu from './Menu'
import AAConnectionReorganizationImplementingStatementForm from './Statement'

interface IAAConnectionReorganizationImplementing extends FC {
  Layout: typeof AAConnectionReorganizationImplementingLayout
  Menu: typeof AAConnectionReorganizationImplementingMenu
  StatementForm: typeof AAConnectionReorganizationImplementingStatementForm
  Manager: typeof AAConnectionReorganizationImplementingModalManager
}

const useAAConnectionReorganizationImplementingControl = () => {
  const popupManager = usePopupManager()

  const openAAConnectionReorganizationImplementingModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AAConnectionReorganizationImplementingLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAAConnectionReorganizationImplementingModal }
}

const AAConnectionReorganizationImplementing: IAAConnectionReorganizationImplementing = () => null

AAConnectionReorganizationImplementing.Menu = AAConnectionReorganizationImplementingMenu
AAConnectionReorganizationImplementing.Layout = AAConnectionReorganizationImplementingLayout
AAConnectionReorganizationImplementing.StatementForm =
  AAConnectionReorganizationImplementingStatementForm
AAConnectionReorganizationImplementing.Manager = AAConnectionReorganizationImplementingModalManager

export { useAAConnectionReorganizationImplementingControl }
export default AAConnectionReorganizationImplementing
