import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NoticeOfRefuseConcludeAALayout from './Layout'
import NoticeOfRefuseConcludeAAModalManager from './Manager'
import NoticeOfRefuseConcludeAAMenu from './Menu'
import NoticeOfRefuseConcludeAAStatementForm from './Statement'

interface INoticeOfRefuseConcludeAA extends FC {
  Layout: typeof NoticeOfRefuseConcludeAALayout
  Menu: typeof NoticeOfRefuseConcludeAAMenu
  StatementForm: typeof NoticeOfRefuseConcludeAAStatementForm
  Manager: typeof NoticeOfRefuseConcludeAAModalManager
}

const useNoticeOfRefuseConcludeAAControl = () => {
  const popupManager = usePopupManager()

  const openNoticeOfRefuseConcludeAAModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(NoticeOfRefuseConcludeAALayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openNoticeOfRefuseConcludeAAModal }
}

const NoticeOfRefuseConcludeAA: INoticeOfRefuseConcludeAA = () => null

NoticeOfRefuseConcludeAA.Menu = NoticeOfRefuseConcludeAAMenu
NoticeOfRefuseConcludeAA.Layout = NoticeOfRefuseConcludeAALayout
NoticeOfRefuseConcludeAA.StatementForm = NoticeOfRefuseConcludeAAStatementForm
NoticeOfRefuseConcludeAA.Manager = NoticeOfRefuseConcludeAAModalManager

export { useNoticeOfRefuseConcludeAAControl }
export default NoticeOfRefuseConcludeAA
