import { useCallback } from 'react'

import { generatePropertiesObjectForAAgreementForceCircumstancesForm } from '@components/Forms/AAgreementForceCircumstancesForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAAgreementForceCircumstancesForm } from '@components/Forms/AAgreementForceCircumstancesForm/adapters/RHF.adapter'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'

const useAAgreementForceCircumstancesAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): AAgreementForceCircumstancesFormValues =>
      generateRHFObjectForAAgreementForceCircumstancesForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForAAgreementForceCircumstancesForm(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generatePropertiesObject }
}

export { useAAgreementForceCircumstancesAdapters }
