import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import Icon from '@components/Icon'
import { objOfDateFormats } from '@constants/dateFormats'
import { mergeRefs } from '@helpers/ref/mergeRefs'
import dateRangeIcon from '@icons/action/date_range.svg'
import cn from 'classnames'
import { Dayjs } from 'dayjs'

import Calendar from '../Calendar/Calendar'
import DateInput, { isCompleteDateInput, parseDateString } from '../DateInput/DateInput'
import { DATE_MASK, DateInputProps } from '../DateInput/types'
import { Tooltip } from '../Tooltip'
import { useManualTooltipControl } from '../Tooltip/utils'

import styles from './CalendarInput.module.scss'
import { CalendarInputProps } from './types'

const zIndexDateInput = 51

type InitiatorProps = 'input' | 'calendar'

const CalendarInput = forwardRef<HTMLInputElement, CalendarInputProps>(
  (
    {
      className,
      defaultValue,
      value,
      calendarProps = {},
      onChange,
      onInputChange,
      onCalendarChange,
      onBlur,
      error,
      readOnly,
      leftAddons,
      tooltipProps = {
        position: 'bottom-start',
        offset: [0, 10],
      },
      inputMask = DATE_MASK,
      wrapperClassName,
      onBlurForm,
      dataTestId,
      id,
      ...restProps
    },
    ref,
  ) => {
    const {
      state: { tooltipIsOpen },
      handlers: { handleOpenTooltip, handleCloseTooltip, handleToggleTooltip },
    } = useManualTooltipControl()

    const isHandlingChange = useRef(false)

    const [inputValue, setInputValue] = useState(value || defaultValue)

    const calendarRef = useRef<HTMLDivElement>(null)

    const inputRef = useRef<HTMLInputElement>(null)

    const inputContainerRef = useRef<HTMLInputElement>(null)

    const calendarValue = inputValue
      ? (parseDateString(
          inputValue,
          calendarProps.dateFormat || objOfDateFormats.defaultFormat,
        ) as string & Dayjs)
      : undefined

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (event.target.tagName !== 'INPUT' && inputRef.current) {
          inputRef.current.focus()
        }

        if (!tooltipIsOpen) handleOpenTooltip()
      },
      [tooltipIsOpen, handleOpenTooltip],
    )

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (inputRef.current && calendarRef.current?.contains(event.relatedTarget)) {
          return inputRef.current.focus()
        }

        handleCloseTooltip()

        //Тригерим только blur с формы, чтобы не сломать валидации
        if (isHandlingChange.current) {
          onBlurForm?.()
          return
        }

        if (onBlur) {
          onBlur(event)
        }
      },
      [handleCloseTooltip, onBlur],
    )

    const checkInputValueIsValid = useCallback(
      (newInputValue?: string) => {
        if (!newInputValue || error) return false

        const dateValue = parseDateString(
          newInputValue,
          calendarProps.dateFormat || objOfDateFormats.defaultFormat,
        ).isValid()

        return dateValue && isCompleteDateInput(newInputValue, inputMask)
      },
      [error, inputMask],
    )

    const changeHandler = useCallback(
      (
        event: ChangeEvent<HTMLInputElement> | null,
        newValue: string,
        newDate: Date,
        initiator: InitiatorProps = 'input',
        shouldChange = true,
      ) => {
        if (readOnly) return

        if (initiator === 'input' && event && onInputChange) {
          onInputChange(event, { value: newValue, date: newDate })
        }

        if (initiator === 'calendar' && onCalendarChange) {
          onCalendarChange(newDate.getTime())
        }

        setInputValue(newValue)

        handleCloseTooltip()

        if (shouldChange) {
          onChange?.(event, { date: newDate, value: newValue })
        }
      },
      [onCalendarChange, onChange, onInputChange, handleCloseTooltip, readOnly],
    )

    const handleInputChange = useCallback<Required<DateInputProps>['onChange']>(
      (event, payload) => {
        changeHandler(
          event,
          payload.value,
          payload.date,
          'input',
          !payload.value || checkInputValueIsValid(payload.value),
        )
      },
      [changeHandler, checkInputValueIsValid],
    )

    const handleInputKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
      if (['ArrowDown', 'ArrowUp'].includes(event.key) && calendarRef.current) {
        event.preventDefault()
        calendarRef.current.focus()
      }
    }, [])

    const handleCalendarChange = useCallback(
      (date) => {
        if (date) {
          isHandlingChange.current = true

          changeHandler(
            null,
            parseDateString(
              date,
              calendarProps.dateFormat || objOfDateFormats.defaultFormat,
            ).format(calendarProps.dateFormat || objOfDateFormats.defaultFormat),
            new Date(date),
            'calendar',
          )

          setTimeout(() => {
            isHandlingChange.current = false
          }, 750)
        }
      },
      [changeHandler],
    )

    const handleCalendarWrapperMouseDown = useCallback((event: MouseEvent<HTMLDivElement>) => {
      // Не дает инпуту терять фокус при выборе даты
      event.preventDefault()
    }, [])

    useEffect(() => {
      if (typeof value !== 'undefined') {
        setInputValue(value)
      }
    }, [value])

    const handleClickIcon = (e) => {
      e.stopPropagation()

      handleToggleTooltip()

      inputRef.current?.focus()
    }

    const initialIcon = (
      <Icon
        src={dateRangeIcon}
        size="m"
        className={styles.initialIcon}
        color="text-base-tertiary"
        tabIndex={0}
        onClick={handleClickIcon}
      />
    )

    const tooltipContent = useMemo(
      () => (
        <div
          className={cn(styles.calendar, className)}
          role={'toolbar'}
          onMouseDown={handleCalendarWrapperMouseDown}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Calendar
            {...calendarProps}
            value={checkInputValueIsValid(inputValue) ? calendarValue : undefined}
            onChange={handleCalendarChange}
          />
        </div>
      ),
      [
        className,
        inputValue,
        calendarProps,
        calendarValue,
        checkInputValueIsValid,
        handleCalendarChange,
        handleCalendarWrapperMouseDown,
      ],
    )

    return (
      <div data-testid={dataTestId} id={id} className={cn(styles.calendarInputWrapper, wrapperClassName)}>
        <Tooltip
          {...tooltipProps}
          open={tooltipIsOpen}
          trigger={'click'}
          zIndex={zIndexDateInput}
          content={tooltipContent}
          arrowClassName={styles.calendarTooltip__arrow}
          contentClassName={styles.calendarTooltip__content}
          targetClassName={styles.calendarTooltip__target}
          targetRef={calendarRef}
        >
          <DateInput
            {...restProps}
            readOnly={readOnly}
            value={inputValue}
            error={error}
            leftAddons={leftAddons ?? initialIcon}
            inputMask={inputMask}
            dataTestId={dataTestId}
            format={calendarProps.dateFormat}
            inputContainerRef={inputContainerRef}
            ref={mergeRefs([ref, inputRef])}
            onKeyDown={handleInputKeyDown}
            onClick={handleOpenTooltip}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleInputChange}
          />
        </Tooltip>
      </div>
    )
  },
)

export default CalendarInput
