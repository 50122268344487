import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfParametersOfCostRecoveryApplicationMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'О проекте' },
  { id: '3', title: 'О заявлении на возмещение' },
  { id: '4', title: 'Затраты, которые подлежат возмещению' },
  { id: '5', title: 'Уплаченные налоги' },
]

const defaultParametersOfCostRecoveryApplicationFormValues: ParametersOfCostRecoveryApplicationFormValues =
  {
    '1': {
      ...getDefaultInvestorFieldState(),
      investorAddressFullEgrulFormat: '',
      investorInn: '',
      investorKpp: '',
    },
    '2': {
      szpkNumber: '',
      szpkRegistrationDate: '',
      projectName: '',
    },
    '3': {
      compensationStatementYear: '',
      compensationStatementForm: '',
      compensationStatementBudgetLevels: [],
      isFederal: false,
      isRegional: false,
      isLocal: false,
    },
    '4': {
      compensationStatementExpenseGroupName: null,
      compensationStatementTaxLegalPropertyRefundTotalValue: '',
      compensationStatementTaxLandPropertyRefundTotalValue: '',
      compensationStatementExpenseGroupSystemName: '',
      compensationStatementTypeSystemName: '',
      compensationStatementExpenseSubTypes: [],
      compensationStatementExpenseSubTypesNotExist: [],
    },
    '5': {
      compensationStatementPaidTaxes: [],
    },
    additionalFields: {
      compensationObjectFromSzpkId: '',
      compensationObjectAbsentSzpkId: '',
      creditAgreementId: '',
      loanAgreementId: '',
      bondId: '',
    },
  }

const parametersOfCostRecoveryApplicationBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorInn: '1.investorInn',
    investorKpp: '1.investorKpp',
    investorAddressFullEgrulFormat: '1.investorAddressFullEgrulFormat',
  },
  '2': {
    szpkNumber: '2.szpkNumber',
    szpkRegistrationDate: '2.szpkRegistrationDate',
    projectName: '2.projectName',
  },
  '3': {
    compensationStatementYear: '3.compensationStatementYear',
    compensationStatementForm: '3.compensationStatementForm',
    compensationStatementBudgetLevels: '3.compensationStatementBudgetLevels',
    isFederal: '3.isFederal',
    isRegional: '3.isRegional',
    isLocal: '3.isLocal',
  },
  '4': {
    compensationStatementExpenseGroupName: '4.compensationStatementExpenseGroupName',
    compensationStatementExpenseGroupSystemName: '4.compensationStatementExpenseGroupSystemName',
    compensationStatementTypeSystemName: '4.compensationStatementTypeSystemName',
    compensationStatementTaxLegalPropertyRefundTotalValue:
      '4.compensationStatementTaxLegalPropertyRefundTotalValue',
    compensationStatementTaxLandPropertyRefundTotalValue:
      '4.compensationStatementTaxLandPropertyRefundTotalValue',
    compensationStatementExpenseSubTypes: '4.compensationStatementExpenseSubTypes',
    compensationStatementExpenseSubTypesNotExist: '4.compensationStatementExpenseSubTypesNotExist',
  },
  '5': {
    compensationStatementPaidTaxes: '5.compensationStatementPaidTaxes',
  },
  additionalFields: {
    compensationObjectFromSzpkId: 'additionalFields.compensationObjectFromSzpkId',
    compensationObjectAbsentSzpkId: 'additionalFields.compensationObjectAbsentSzpkId',
    creditAgreementId: 'additionalFields.creditAgreementId',
    loanAgreementId: 'additionalFields.loanAgreementId',
    bondId: 'additionalFields.bondId',
  },
} as const

const parametersOfCostRecoveryApplicationConstValues = {
  SUBSIDY: 'Субсидия',
  TAX_DEDUCTION: 'Налоговый вычет',
  DEBT: 'DEBT',
  FEDERAL: 'FEDERAL',
  REGIONAL: 'REGIONAL',
  LOCAL: 'LOCAL',
  BOND: 'BOND',
  DESTRUCTION: 'DESTRUCTION',
  LOAN_AGREEMENT: 'LOAN_AGREEMENT',
  CREDIT_AGREEMENT: 'CREDIT_AGREEMENT',
  BUILDING_APARTMENTS: 'BUILDING_APARTMENTS',
  BUILD_INFRASTRUCTURE: 'BUILD_INFRASTRUCTURE',
  BUILD_INFRASTRUCTURE_PROVIDING: 'BUILD_INFRASTRUCTURE_PROVIDING',
  COMPENSATION_OBJECT_FROM_SZPK: 'COMPENSATION_OBJECT_FROM_SZPK',
  COMPENSATION_OBJECT_ABSENT_SZPK: 'COMPENSATION_OBJECT_ABSENT_SZPK',
  BUILD_INFRASTRUCTURE_ACCOMPANYING: 'BUILD_INFRASTRUCTURE_ACCOMPANYING',
  DEBT_INFRASTRUCTURE: 'DEBT_INFRASTRUCTURE',
  DEBT_MAIN_OBJECT: 'DEBT_MAIN_OBJECT',
  DEBT_INFRASTRUCTURE_PROVIDING_LOAN_INTEREST: 'DEBT_INFRASTRUCTURE_PROVIDING_LOAN_INTEREST',
  DEBT_INFRASTRUCTURE_ACCOMPANYING_LOAN_INTEREST: 'DEBT_INFRASTRUCTURE_ACCOMPANYING_LOAN_INTEREST',
  DEBT_MAIN_OBJECT_LOAN_INTEREST: 'DEBT_MAIN_OBJECT_LOAN_INTEREST',
  DEBT_INFRASTRUCTURE_PROVIDING_COUPON_INCOME: 'DEBT_INFRASTRUCTURE_PROVIDING_COUPON_INCOME',
  DEBT_INFRASTRUCTURE_ACCOMPANYING_COUPON_INCOME: 'DEBT_INFRASTRUCTURE_ACCOMPANYING_COUPON_INCOME',
  DEBT_MAIN_OBJECT_COUPON_INCOME: 'DEBT_MAIN_OBJECT_COUPON_INCOME',
}

export {
  defaultParametersOfCostRecoveryApplicationFormValues,
  mapOfParametersOfCostRecoveryApplicationMenu,
  parametersOfCostRecoveryApplicationBlockValues,
  parametersOfCostRecoveryApplicationConstValues,
}
