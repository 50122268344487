import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const sixthSectionValidationMap: FormValuesValidationSection<
  keyof SupplementalCostSharingAFormValues['6'],
  SupplementalCostSharingAFormValues
> = {
  contractDutyNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  contractDutyDate: {
    required: defaultRHFValidation.required,
  },
  contractDutyTerms: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  objectsInfrastructureInvestorCost: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { sixthSectionValidationMap }
