import { Urls } from '@constants/urls'
import { ESIAOrganization, ESIAOrganizationProps } from '@context/APIContext/hooks/useESIAApi/types'
import { useAuthContext } from '@context/AuthContext'

const useESIAApi = () => {
  const { Fetcher } = useAuthContext()

  /**
   * Метод для получения ссылки для логина в ЕСИА
   * @returns Promise<string>
   */
  const getESIALoginLink = async () => {
    try {
      window.location.replace(`${Urls.ESIAAuth}/link`)
    } catch (error) {
      throw error
    }
  }

  /**
   * Метод для получения списка организаций пользователя ЕСИА доступных для попытки логина
   * @returns Promise<ESIAOrganization[]>
   * @param params
   */
  const getESIAOrganizations = async (params: ESIAOrganizationProps) => {
    try {
      const { data } = await Fetcher.get<ESIAOrganization[]>({
        url: `${Urls.ESIAAuth}/organizations`,
        config: {
          params,
        },
      })
      return data
    } catch (error) {
      throw error
    }
  }

  /**
   * Метод для получения ссылки для логина в ЕСИА с указанием конкретной организации
   * @returns Promise<string>
   * @param orgOid
   */
  const getESIAOrganizationLink = (orgOid) => {
    try {
      return `${Urls.ESIAAuth}/organizationLink?orgOid=${orgOid}`
    } catch (error) {
      throw error
    }
  }

  return {
    getESIALoginLink,
    getESIAOrganizations,
    getESIAOrganizationLink,
  }
}

export default useESIAApi
