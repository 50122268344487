import { generatePropertiesObjectForStatementByHeadOfMunicipalityForm } from '@components/Forms/StatementByHeadOfMunicipality/adapters/PropertiesObject.adapter'
import { generateRHFObjectForStatementByHeadOfMunicipalityForm } from '@components/Forms/StatementByHeadOfMunicipality/adapters/RHF.adapter'
import { StatementByHeadOfMunicipalityFormValues } from '@components/Forms/StatementByHeadOfMunicipality/types'

const useStatementByHeadOfMunicipalityAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): StatementByHeadOfMunicipalityFormValues =>
    generateRHFObjectForStatementByHeadOfMunicipalityForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForStatementByHeadOfMunicipalityForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useStatementByHeadOfMunicipalityAdapters }
