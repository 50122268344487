import React, { FC } from 'react'

import Container from '@components/Container'
import Paper from '@components/Paper'
import cn from 'classnames'

import styles from './PublicWrapper.module.scss'

export interface PublicWrapperProps {
  variant?: 'primary' | 'secondary' | 'default'
  title?: string
  position?: 'left' | 'right' | 'center'
  titleClassName?: string
  containerSize?: 'm' | 'md' | 'l' | 'xl'
  hideBgColor?: boolean
  wrapperClassName?: string
}

const PublicWrapper: FC<PublicWrapperProps> = ({
  children,
  variant = 'primary',
  containerSize = 'm',
  position,
  title,
  titleClassName,
  wrapperClassName,
}) => {
  const child = {
    primary: (
      <>
        {title && (
          <h1
            className={cn(styles.title, titleClassName, {
              [styles[`title--${position}`]]: position,
            })}
          >
            {title}
          </h1>
        )}
        {children}
      </>
    ),
    secondary: (
      <Paper rounded="lg" size="2xl">
        {title && (
          <h1
            className={cn(styles.title, titleClassName, {
              [styles[`title--${position}`]]: position,
            })}
          >
            {title}
          </h1>
        )}
        {children}
      </Paper>
    ),
    default: (
      <Paper hideBgColor space="m">
        {title && (
          <h1
            className={cn(styles.title, titleClassName, {
              [styles[`title--${position}`]]: position,
            })}
          >
            {title}
          </h1>
        )}
        {children}
      </Paper>
    ),
  }

  return (
    <div
      className={cn(
        styles.wrapper,
        {
          [styles[`wrapper--${variant}`]]: variant,
        },
        wrapperClassName,
      )}
    >
      <Container
        className={cn({
          [styles[`container--${variant}`]]: variant,
          [styles[`container--${containerSize}`]]: containerSize,
        })}
      >
        {child[variant] || child.primary}
      </Container>
    </div>
  )
}

export default PublicWrapper
