import { NpaFormValues } from '@components/Forms/NpaForm/types'

const defaultNpaFormValues: NpaFormValues = {
  headerInfo: {
    investorHeaderName: null,
    investorHeaderPosition: '',
  },
  npa: {
    npaStructure: [],
    chosenParts: null,
  },
  additionalFields: {
    isFederal: true,
  },
}

const npaFormBlockValues = {
  headerInfo: {
    investorHeaderName: 'headerInfo.investorHeaderName',
    investorHeaderPosition: 'headerInfo.investorHeaderPosition',
  },
  npa: {
    npaStructure: 'npa.npaStructure',
    chosenParts: 'npa.chosenParts',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
  },
} as const

export { defaultNpaFormValues, npaFormBlockValues }
