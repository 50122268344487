import React, { FC, ReactNode } from 'react'

import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import cn from 'classnames'

import styles from './ControlContainerSubtitle.module.scss'

interface ControlContainerSubtitleProps {
  subtitleClassName?: string
  additionalContent?: ReactNode
}

const ControlContainerSubtitle: FC<ControlContainerSubtitleProps> = ({
  subtitleClassName,
  additionalContent,
  children,
}) => {
  return (
    <div className={styles['controlContainerSubtitle__subtitle-wrapper']}>
      <TypographyWithTooltip.Body
        as={'span'}
        variant={'bodyMRegular'}
        color={'on-surface-secondary'}
        className={cn(
          styles['controlContainerSubtitle__subtitle'],
          {
            [styles['controlContainerSubtitle__subtitle--withAdditionalContent']]:
              additionalContent,
          },
          subtitleClassName,
        )}
        tooltipProps={{
          position: 'top',
          offset: [0, 10],
          targetClassName: styles.tooltip__target,
          popoverClassName: styles.tooltip__popover,
        }}
      >
        {children}
      </TypographyWithTooltip.Body>
      {additionalContent}
    </div>
  )
}

export default ControlContainerSubtitle
