import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { PetitionRecognizePreviouslyConcludedContractAsBoundFormValues } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForPetitionRecognizePreviouslyConcludedContractAsBoundForm = (
  objectFromPropertyState: Record<string, any>,
): PetitionRecognizePreviouslyConcludedContractAsBoundFormValues => {
  return {
    '1': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      relatedContractNumber:
        objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractNumber.value || '',
      relatedContractDate:
        objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractDate.value || '',
      relatedContractKind:
        objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractKind.value || '',
      projectName: objectFromPropertyState?.projectName?.value || '',
      relatedContractMemberInstrumentals: isArray(
        objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractMembersInstrumental
          ?.value,
      )
        ? objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractMembersInstrumental?.value.map(
            (contract) => ({
              id: contract?.propertyId,
              item: contract?.value,
            }),
          )
        : [],
    },
  } as PetitionRecognizePreviouslyConcludedContractAsBoundFormValues
}

export { generateRHFObjectForPetitionRecognizePreviouslyConcludedContractAsBoundForm }
