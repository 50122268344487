import { FC } from 'react'

import OptionsController from '@components/NewDesign/Select/OptionsNew/Controller'
import OptionDefaultItem from '@components/NewDesign/Select/OptionsNew/DefaultItem'
import OptionsGroup from '@components/NewDesign/Select/OptionsNew/Group'
import OptionGroupItem from '@components/NewDesign/Select/OptionsNew/GroupItem'
import OptionItem from '@components/NewDesign/Select/OptionsNew/Item'
import type { OptionsNewProps } from '@components/NewDesign/Select/OptionsNew/types'

type OptionsFC = FC<OptionsNewProps> & {
  Item: typeof OptionItem
  GroupItem: typeof OptionGroupItem
  DefaultItem: typeof OptionDefaultItem
  Controller: typeof OptionsController
}

const Options: OptionsFC = (props) => {
  return <OptionsGroup {...props} />
}

Options.Item = OptionItem
Options.GroupItem = OptionGroupItem
Options.DefaultItem = OptionDefaultItem
Options.Controller = OptionsController

export default Options
