import React, { memo } from 'react'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { blockValues } from '@components/Forms/CreateStatementOld/const'
import styles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import AddressPart from '@components/Forms/CreateStatementOld/Forms/InvestorGeneralInfo/AddressPart'
import { investorGeneralInfoFieldsValidation } from '@components/Forms/CreateStatementOld/Forms/InvestorGeneralInfo/validation'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import cn from 'classnames'

const { validationAdapter } = DocumentFormHelpers

const InvestorGeneralInfoForm = () => {
  const {
    state: { formInstance, blockViewIsValidating },
  } = useCreateStatementManager()

  if (!formInstance) return null

  return (
    <div className={styles.form__container}>
      <div className={styles.form__item}>
        <ControlledInput
          control={formInstance.control}
          name={blockValues.investorGeneralInfo.fullName}
          inputProps={{
            view: 'secondary',
            size: 'xl',
            label: 'Наименование',
            fixWidth: true,
            maxLength: 1000,
            dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.fullName}-input`,
          }}
          rules={validationAdapter(investorGeneralInfoFieldsValidation.fullName, {
            form: formInstance,
            name: blockValues.investorGeneralInfo.fullName,
            needTrigger: blockViewIsValidating,
          })}
        />
      </div>
      <div className={styles.form__item}>
        <ControlledInput
          control={formInstance.control}
          name={blockValues.investorGeneralInfo.name}
          inputProps={{
            view: 'secondary',
            size: 'xl',
            label: 'Сокращенное наименование',
            fixWidth: true,
            maxLength: 1000,
            dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.name}-input`,
          }}
          rules={validationAdapter(investorGeneralInfoFieldsValidation.name, {
            form: formInstance,
            name: blockValues.investorGeneralInfo.name,
            needTrigger: blockViewIsValidating,
          })}
        />
      </div>
      <div className={styles.form__row}>
        <div className={styles.form__item}>
          <ControlledMaskInput
            name={blockValues.investorGeneralInfo.inn}
            control={formInstance.control}
            inputProps={{
              view: 'secondary',
              size: 'xl',
              mask: '9999999999',
              maskPlaceholder: '',
              label: 'ИНН',
              dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.inn}-maskInput`,
            }}
            rules={validationAdapter(investorGeneralInfoFieldsValidation.inn, {
              form: formInstance,
              name: blockValues.investorGeneralInfo.inn,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
        <div className={styles.form__item}>
          <ControlledMaskInput
            control={formInstance.control}
            name={blockValues.investorGeneralInfo.ogrn}
            inputProps={{
              view: 'secondary',
              size: 'xl',
              mask: '9999999999999',
              maskPlaceholder: '',
              label: 'ОГРН',
              dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.ogrn}-maskInput`,
            }}
            rules={validationAdapter(investorGeneralInfoFieldsValidation.ogrn, {
              form: formInstance,
              name: blockValues.investorGeneralInfo.ogrn,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
        <div className={styles.form__item}>
          <ControlledMaskInput
            control={formInstance.control}
            name={blockValues.investorGeneralInfo.kpp}
            inputProps={{
              view: 'secondary',
              size: 'xl',
              mask: '999999999',
              maskPlaceholder: '',
              label: 'КПП',
              dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.kpp}-maskInput`,
            }}
            rules={validationAdapter(investorGeneralInfoFieldsValidation.kpp, {
              form: formInstance,
              name: blockValues.investorGeneralInfo.kpp,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>

        <div className={styles.form__item}>
          <ControlledMaskInput
            control={formInstance.control}
            name={blockValues.investorGeneralInfo.okpo}
            inputProps={{
              view: 'secondary',
              size: 'xl',
              maskPlaceholder: '',
              mask: '9999999999',
              label: 'ОКПО',
              dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.okpo}-maskInput`,
            }}
            rules={validationAdapter(investorGeneralInfoFieldsValidation.okpo, {
              form: formInstance,
              name: blockValues.investorGeneralInfo.okpo,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
      </div>
      <div className={cn(styles.form__item, styles['form__item--full'])}>
        <ControlledHierarchyReferenceBookSelect
          dictionaryName={'okved'}
          controllerProps={{
            control: formInstance.control,
            name: blockValues.investorGeneralInfo.okved,
            rules: validationAdapter(investorGeneralInfoFieldsValidation.okved, {
              form: formInstance,
              name: blockValues.investorGeneralInfo.okved,
              needTrigger: blockViewIsValidating,
            }),
          }}
          inputProps={{
            label: 'ОКВЭД (основной)',
            dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.okved}-OKVDSelect`,
          }}
        />
      </div>
      <div className={cn(styles.form__item)}>
        <ControlledAmountInput
          control={formInstance.control}
          name={blockValues.investorGeneralInfo.capital}
          inputProps={{
            view: 'secondary',
            size: 'xl',
            label: 'Уставный капитал',
            minorPartView: 'withZeroMinorPart',
            integerLength: 19,
            dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.capital}-amountInput`,
          }}
          rules={validationAdapter(investorGeneralInfoFieldsValidation.capital, {
            form: formInstance,
            name: blockValues.investorGeneralInfo.capital,
            needTrigger: blockViewIsValidating,
          })}
        />
      </div>

      <div className={styles['form__item--checkbox']}>
        <ControlledCheckbox
          control={formInstance.control}
          name={blockValues.investorGeneralInfo.isProjectCompany}
          checkBoxProps={{
            label: 'Проектная компания',
            dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.isProjectCompany}-checkbox`,
          }}
          rules={validationAdapter(investorGeneralInfoFieldsValidation.isProjectCompany, {
            form: formInstance,
            name: blockValues.investorGeneralInfo.isProjectCompany,
            needTrigger: blockViewIsValidating,
          })}
        />
      </div>

      <div className={styles['form__item--checkbox']}>
        <ControlledCheckbox
          control={formInstance.control}
          name={blockValues.investorGeneralInfo.isVed}
          checkBoxProps={{
            label: 'Участник внешнеэкономической деятельности',
            dataTestId: `InvestorGeneralInfoForm-${blockValues.investorGeneralInfo.isVed}-checkbox`,
          }}
          rules={validationAdapter(investorGeneralInfoFieldsValidation.isVed, {
            form: formInstance,
            name: blockValues.investorGeneralInfo.isVed,
            needTrigger: blockViewIsValidating,
          })}
        />
      </div>

      <div className={styles['form__item--checkbox']}>
        <AddressPart />
      </div>
    </div>
  )
}

export default memo(InvestorGeneralInfoForm)
