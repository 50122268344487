import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import AnotherObjectForm from '@components/Forms/ProjectSZPKForm/Forms/8/AdditionalForms/AnotherObjectForm'
import {
  EighthAnotherStagesArrayPathName,
  EighthAnotherStagesPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/8/types'
import { eighthSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/8/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { OptionProps } from '@components/NewDesign/Select/model'
import ControlledSingleSelectNew from '@components/NewDesign/Select/SingleSelectNew/Controlled'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

import { useIsOtherActivityInfrastructureRenderCondition } from './hooks'

const { validationAdapter } = DocumentFormHelpers

interface AnotherStageProps {
  indexOfStep: number
  name: EighthAnotherStagesArrayPathName
  parentName: EighthAnotherStagesPathName
  objectName: string
  anchorId: string
  onSuccessSelectStepNumber: VoidFunction
}

const AnotherStage: FC<AnotherStageProps> = ({
  name,
  indexOfStep,
  parentName,
  objectName,
  anchorId,
  onSuccessSelectStepNumber,
}) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const stepNumber = useWatch({
    name: `${name}.stepNumber`,
    control: formInstance.control,
  })

  const conditionToRenderOtherFields = !!stepNumber

  const anotherStageOptions = useWatch({
    name: 'additionalFields.anotherStageOptions',
    control: formInstance.control,
  }) as OptionProps[]

  const isOtherActivityInfrastructureRenderCondition =
    useIsOtherActivityInfrastructureRenderCondition(formInstance, name)

  const isOtherActivityInfrastructure = useWatch({
    name: `${name}.isOtherActivityInfrastructure`,
    control: formInstance.control,
  })

  const otherActivityInfrastructureTypeRenderCondition =
    !!isOtherActivityInfrastructure && isOtherActivityInfrastructureRenderCondition

  const isOtherActivityInfrastructureBlockCollapsed =
    !otherActivityInfrastructureTypeRenderCondition && !isOtherActivityInfrastructureRenderCondition

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      handleAddCustomValue,
      getPropertiesProps,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const {
    getSubscribableControlProps,
    getInputProps,
    getCalendarInputProps,
    getSwitchProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  if (!conditionToRenderOtherFields)
    return (
      <Container className={'p-0'}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parentName,
              })}
            >
              <ControlledSingleSelectNew
                disabled={!editMode}
                options={anotherStageOptions}
                controllerProps={{
                  name: `${name}.stepNumber`,
                  control: formInstance.control,
                  rules: validationAdapter(eighthSectionValidationMap.stepNumber, {
                    form: formInstance,
                    name: `${name}.stepNumber`,
                    needTrigger: blockViewIsValidating,
                  }),
                }}
                inputProps={{
                  fixWidth: true,
                  view: 'secondary',
                  label: 'Номер этапа',
                }}
                onChangeFormValue={async (option: OptionProps | null) => {
                  const findOfStepActivityOthersProps = getObjectValue(
                    getPropertiesProps?.(),
                    '8.stepActivityOthers.value',
                  )?.find((stepActivity) => stepActivity.propertyId === option?.value)

                  if (!findOfStepActivityOthersProps) return

                  onSuccessSelectStepNumber()

                  await handleAddCustomValue?.({
                    id: findOfStepActivityOthersProps.propertyId,
                    lastUpdateDt: findOfStepActivityOthersProps.lastUpdateDt,
                  })
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Container>
    )

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={8}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.otherActivityName`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${name}.otherActivityName`,
                    rules: eighthSectionValidationMap.otherActivityName,
                    inputProps: {
                      label: 'Наименование мероприятия',
                    },
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.otherActivityName`)),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.otherActivityName`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
            <Col xs={4}>
              <SubscribableControl
                path={`${name}.stepNumber`}
                watcher={ProjectSZPKFieldsControlUpdateWatcher}
                {...subscribableControl}
              >
                <ControlledSingleSelectNew
                  disabled
                  options={anotherStageOptions}
                  controllerProps={{
                    name: `${name}.stepNumber`,
                    control: formInstance.control,
                    rules: validationAdapter(eighthSectionValidationMap.stepNumber, {
                      form: formInstance,
                      name: `${name}.stepNumber`,
                      needTrigger: blockViewIsValidating,
                    }),
                  }}
                  inputProps={{
                    fixWidth: true,
                    view: 'secondary',
                    label: 'Номер этапа',
                  }}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableBottomDefaultStyles
                title={'Срок завершения реализации иного мероприятия'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.otherActivityEndDate`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${name}.otherActivityEndDate`,
                      rules: {
                        ...eighthSectionValidationMap.otherActivityEndDate,
                        validate: {
                          positiveStepEndDate: (value) => {
                            const stepEndDate = formInstance.getValues(
                              `${projectSZPKBlockValues['6'].stages}.${indexOfStep}.stepEndDate`,
                            )

                            return (
                              (isDateValidForDayjs(value) &&
                                dayjs(value, objOfDateFormats.defaultFormat) <=
                                  dayjs(stepEndDate, objOfDateFormats.defaultFormat)) ||
                              'дата завершения иного мероприятия должна быть меньше или равна дате окончания этапа'
                            )
                          },
                        },
                      },

                      calendarInputProps: {
                        label: 'ДД.ММ.ГГГГ',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.otherActivityEndDate`)
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.otherActivityEndDate`)
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.otherActivityEndDate`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
        </Stack>
        <Stack direction={'vertical'} gap={isOtherActivityInfrastructureBlockCollapsed ? 0 : 3}>
          <Row>
            <Col xs={12}>
              <Stack
                direction={'vertical'}
                gap={isOtherActivityInfrastructureBlockCollapsed ? 0 : 3}
              >
                <CollapseWrapper
                  defaultExpanded={isOtherActivityInfrastructureRenderCondition}
                  isExpanded={isOtherActivityInfrastructureRenderCondition}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.isOtherActivityInfrastructure`,
                    })}
                  >
                    <ControlledSwitch
                      {...getSwitchProps({
                        name: `${name}.isOtherActivityInfrastructure`,
                        switchProps: {
                          wrapperClassName: styles.form__switch,
                          label: 'Мероприятие относится к инфраструктуре',
                        },
                        onChange: () =>
                          setTimeout(
                            () => handleChangeValue?.(`${name}.isOtherActivityInfrastructure`),
                            0,
                          ),
                      })}
                    />
                  </SubscribableControl>
                </CollapseWrapper>
                <CollapseWrapper
                  defaultExpanded={otherActivityInfrastructureTypeRenderCondition}
                  isExpanded={otherActivityInfrastructureTypeRenderCondition}
                >
                  <Stack direction={'vertical'} gap={3}>
                    <Col xs={12}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.otherActivityInfrastructureTypeName`,
                        })}
                      >
                        {({ overrideProps }) => {
                          const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                            ? overrideProps.fetcherOptions
                            : undefined

                          return (
                            <ControlledFormSingleSelect
                              {...getSingleSelectProps({
                                fetcherProps,
                                optionsAdapter: (item) => ({
                                  displayValue: item.name || '',
                                  value: item.id,
                                }),

                                selectProps: {
                                  inputProps: {
                                    dataTestId: 'objectInfrastructureType',
                                    label: 'Тип инфраструктуры',
                                  },
                                  onChangeFormValue: () =>
                                    setTimeout(
                                      () =>
                                        handleChangeValue?.(
                                          `${name}.otherActivityInfrastructureTypeName`,
                                        ),
                                      0,
                                    ),
                                },
                                controllerProps: {
                                  name: `${name}.otherActivityInfrastructureTypeName`,
                                  rules:
                                    eighthSectionValidationMap.otherActivityInfrastructureTypeName,
                                },
                              })}
                            />
                          )
                        }}
                      </SubscribableControl>
                    </Col>
                  </Stack>
                </CollapseWrapper>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Group disableBottomBorder groupClassName={'p-2'} title={'Объекты иного мероприятия'}>
              <Col xs={12}>
                <AnotherObjectForm
                  isAddAnotherObjectButtonDisabled={isOtherActivityInfrastructure}
                  objectName={objectName}
                  name={name}
                  parentAnchorId={anchorId}
                />
              </Col>
            </Group>
          </Row>
        </Stack>
      </Stack>
    </Container>
  )
}

export default AnotherStage
