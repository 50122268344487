import { FC, memo } from 'react'

import Button from '@components/NewDesign/Button/Button'
import type { Operator, Participant } from '@context/APIContext/hooks/useOperatorApi'

import styles from './ActionSidebarButtons.module.scss'

interface ActionSidebarButtonsProps {
  isLoadingSaveUser: boolean
  isLoadingBlockUser: boolean
  handleSaveUser: () => Promise<void>
  handleBlockUser: VoidFunction
  handleUnBlockUser: VoidFunction
  handleBackFormState: VoidFunction
  data?: Operator | Participant
  isDisabledSaveButton?: boolean
}

const ActionSidebarButtons: FC<ActionSidebarButtonsProps> = ({
  isLoadingBlockUser,
  isLoadingSaveUser,
  handleSaveUser,
  handleBackFormState,
  handleBlockUser,
  handleUnBlockUser,
  data,
  isDisabledSaveButton,
}) => {
  if (!data) {
    return (
      <div className={styles.sidebar__actions}>
        <Button
          view="gray"
          className={styles.sidebar__actionsItem}
          disabled={isLoadingSaveUser}
          onClick={handleBackFormState}
        >
          Отмена
        </Button>
        <Button
          className={styles.sidebar__actionsItem}
          disabled={isDisabledSaveButton || isLoadingSaveUser}
          loaderProps={{ loading: isLoadingSaveUser, variant: 'lite', placement: 'trailing' }}
          onClick={handleSaveUser}
        >
          Добавить
        </Button>
      </div>
    )
  }

  if (data.isEnabled) {
    return (
      <div className={styles.sidebar__actions}>
        <Button
          view="gray"
          color="negative"
          className={styles.sidebar__actionsItem}
          disabled={isLoadingSaveUser ?? isLoadingBlockUser}
          loaderProps={{ loading: isLoadingBlockUser, variant: 'lite', placement: 'trailing' }}
          onClick={handleBlockUser}
        >
          Заблокировать
        </Button>
        <Button
          className={styles.sidebar__actionsItem}
          disabled={isDisabledSaveButton || isLoadingSaveUser || isLoadingBlockUser}
          loaderProps={{ loading: isLoadingSaveUser, variant: 'lite', placement: 'trailing' }}
          onClick={handleSaveUser}
        >
          Сохранить
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.sidebar__actions}>
      <Button
        view="gray"
        className={styles.sidebar__actionsItem}
        disabled={isLoadingSaveUser}
        loaderProps={{ loading: isLoadingSaveUser, variant: 'lite', placement: 'trailing' }}
        onClick={handleUnBlockUser}
      >
        Разблокировать
      </Button>
    </div>
  )
}

export default memo(ActionSidebarButtons)
