import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm =
  (objectFromPropertyState: Record<string, any>): Record<string, unknown> => {
    return {
      '1': {
        ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
      },
      '2': {
        ...PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
      },
      '3': {
        ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
        investorFullNameInstrumental: {
          propertyId: objectFromPropertyState?.investorFullNameInstrumental?.propertyId,
          type: objectFromPropertyState?.investorFullNameInstrumental?.type,
          lastUpdateDt: objectFromPropertyState?.investorFullNameInstrumental?.lastUpdateDt,
        },
      },
      '4': {
        ruleLinkDenySolicitation: {
          propertyId: objectFromPropertyState.ruleLinkDenySolicitation?.propertyId,
          type: objectFromPropertyState.ruleLinkDenySolicitation?.type,
          lastUpdateDt: objectFromPropertyState.ruleLinkDenySolicitation?.lastUpdateDt,
        },
        reasonDenySolicitation: {
          propertyId: objectFromPropertyState.reasonDenySolicitation?.propertyId,
          type: objectFromPropertyState.reasonDenySolicitation?.type,
          lastUpdateDt: objectFromPropertyState.reasonDenySolicitation?.lastUpdateDt,
        },
      },
    }
  }

export { generatePropertiesObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm }
