import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { defaultRHFValidation } from '@constants/validations'

const mainProjectValidationMap: FormValuesValidationSection<
  keyof InfrFacilitiesCostsExpectedReimbursedFormValues['2']['mainProjectObjects'][number],
  InfrFacilitiesCostsExpectedReimbursedFormValues
> = {
  mainObjectOption: {
    required: defaultRHFValidation.required,
  },
}

export { mainProjectValidationMap }
