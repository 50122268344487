import React, { FC, memo, useCallback, useMemo } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'

import styles from './RemoveModal.module.scss'

export interface RemoveModalComponentProps {
  title: string
  onConfirm: VoidFunction
  description?: string
  cancelButtonText?: string
  confirmButtonText?: string
}

type RemoveModalProps = RemoveModalComponentProps & PopupProps

const useRemoveModal = () => {
  const { open } = usePopupManager()

  const handleOpenRemoveModal = (props: RemoveModalComponentProps) => {
    open(RemoveModal, props)
  }

  return { handleOpenRemoveModal }
}

const RemoveModal: FC<RemoveModalProps> = ({
  cancelButtonText = 'Отмена',
  confirmButtonText = 'Удалить',
  title,
  isOpen,
  onClose,
  onConfirm,
  description,
}) => {
  const preparedOnClose = () => {
    onClose?.()
  }

  const preparedConfirm = useCallback(async () => {
    try {
      await onConfirm()
    } catch {}
  }, [onConfirm])

  const actions = useMemo(
    () =>
      [
        {
          children: cancelButtonText,
          dataTestId: 'RemoveModal-cancel-button',
          view: 'gray',
          onClick: onClose,
        },
        {
          children: confirmButtonText,
          dataTestId: 'RemoveModal-excludeNpa-button',
          color: 'negative',
          onClick: preparedConfirm,
        },
      ] as ButtonProps[],
    [cancelButtonText, confirmButtonText, onClose, preparedConfirm],
  )

  return (
    <Modal.Action
      simpleModalContainerClassName={styles.removeModal}
      actionModalContentClassName={styles.removeModal__content}
      title={title}
      dataTestId="RemoveModal-modal"
      closeButtonDataTestId="RemoveModal-modal-closeButton"
      isOpen={isOpen}
      actions={actions}
      onClose={preparedOnClose}
    >
      <Typography.Body variant={'bodyMMedium'} className={styles.removeModal__description}>
        {description}
      </Typography.Body>
    </Modal.Action>
  )
}

export { useRemoveModal }
export default memo(RemoveModal)
