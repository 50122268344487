import React, { FC } from 'react'

import Typography from '@components/NewDesign/Typography'

interface DataViewProps {
  suptitle: string
}
const DataView: FC<DataViewProps> = ({ suptitle, children }) => {
  return (
    <>
      <Typography.Caption variant={'captionSRegular'} color={'text-base-tertiary'}>
        {suptitle}
      </Typography.Caption>
      <Typography.Body variant={'bodyMMedium'}>{children}</Typography.Body>
    </>
  )
}

export default DataView
