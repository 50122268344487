import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'

interface DataOnFulfillmentOfTermsSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
}

const DataOnFulfillmentOfTermsSZPKForms: DataOnFulfillmentOfTermsSZPKFormsProps = () => null

DataOnFulfillmentOfTermsSZPKForms.First = First
DataOnFulfillmentOfTermsSZPKForms.Second = Second
DataOnFulfillmentOfTermsSZPKForms.Third = Third
DataOnFulfillmentOfTermsSZPKForms.Fourth = Fourth
DataOnFulfillmentOfTermsSZPKForms.Fifth = Fifth

export default DataOnFulfillmentOfTermsSZPKForms
