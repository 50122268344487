import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import FieldView from '@components/DocumentFormComponents/FieldView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  CompensationCreditObjectPathName,
  CompensationDismantlingObjectPathName,
  CompensationExpenseObjectPathName,
  CompensationRelatedObjectPathName,
  CompensationsArrayPathName,
  CompensationSupportingObjectPathName,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/7/types'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  InformationOfExecutionConditionsSZPKFieldCollapseControlUpdateWatcher,
  InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import {
  compensationMonitoringValidationMap,
  creditCompensationsValidationMap,
  dismantlingCompensationsValidationMap,
  expenseCompensationsValidationMap,
  relatedCompensationsValidationMap,
  supportingCompensationsValidationMap,
} from './validation'

interface CompensationItemProps {
  compensationId: string
  formInstance: UseFormReturn<InformationOfExecutionConditionsSZPKFormValues>
  formName: CompensationsArrayPathName
  initialAccordionState?: boolean
}

const CompensationItem: FC<CompensationItemProps> = ({
  compensationId,
  formInstance,
  formName,
  initialAccordionState,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getInputProps, getAmountInputProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
    })

  const { fields: relatedCompensations } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    CompensationRelatedObjectPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${formName}.compensationRelatedInfrastructure`,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  const { fields: supportingCompensations } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    CompensationSupportingObjectPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${formName}.compensationSupportingInfrastructure`,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  const { fields: creditCompensations } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    CompensationCreditObjectPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${formName}.compensationPercentCredit`,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })
  const { fields: dismantlingCompensations } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    CompensationDismantlingObjectPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${formName}.compensationDismantling`,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  const { fields: expenseCompensations } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    CompensationExpenseObjectPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${formName}.compensationExpense`,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  const { anchorId, compensationYear } = useWatch({
    name: formName,
    control: formInstance.control,
  })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name: formName,
    watcher: InformationOfExecutionConditionsSZPKFieldCollapseControlUpdateWatcher,
    initialExpanded: initialAccordionState,
  })

  return (
    <Accordion
      id={compensationId}
      anchorId={anchorId}
      isExpanded={isExpanded}
      title={`Год возмещения затрат - ${compensationYear}`}
      initialAccordionState={initialAccordionState}
      onToggle={onToggleCollapse}
    >
      <FieldView.StepTemplate id={compensationId}>
        <Stack direction={'vertical'} gap={3}>
          <Group
            disableBottomBorder
            title={'Затраты на объекты сопутствующей инфраструктуры'}
            groupClassName={'mt-0'}
          >
            <Stack direction={'vertical'} gap={3}>
              {relatedCompensations.map((field, index) => {
                const name = `${formName}.compensationRelatedInfrastructure.${index}` as const

                return (
                  <Group disableBottomBorder key={field.id} title={`Объект №${index + 1}`}>
                    <Stack direction={'vertical'} gap={3}>
                      <Row>
                        <Col xs={12}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.relatedInfrastructureObjectName`,
                            })}
                          >
                            <ControlledInput
                              {...getInputProps({
                                name: `${name}.relatedInfrastructureObjectName`,
                                inputProps: {
                                  label: 'Объект сопутствующей инфраструктуры',
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </Col>
                      </Row>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        titleHeightFallback={'40px'}
                        title={'Затраты на объект сопутствующей инфраструктуры'}
                        firstColumnSize={6}
                        secondColumnSize={6}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.relatedInfrastructureObjectValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.relatedInfrastructureObjectValue`,
                              rules:
                                relatedCompensationsValidationMap.relatedInfrastructureObjectValue,
                              onBlur: () =>
                                setTimeout(
                                  () =>
                                    handleChangeValue?.(`${name}.relatedInfrastructureObjectValue`),
                                  0,
                                ),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${name}.relatedInfrastructureObjectValue`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Stack>
                  </Group>
                )
              })}
              <Row>
                <Col xs={12}>
                  <Border />
                </Col>
              </Row>
              <FieldView.RowWithBottomBorder
                disableTopPadding
                disableBottomDefaultStyles
                titleHeightFallback={'40px'}
                title={'Сумма затрат по всем объектам сопутствующей инфраструктуры'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.generalCompensationRelatedInfrastructure`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.generalCompensationRelatedInfrastructure`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
          <Group disableBottomBorder title={'Затраты на объекты обеспечивающей инфраструктуры'}>
            <Stack direction={'vertical'} gap={3}>
              {supportingCompensations.map((field, index) => {
                const name = `${formName}.compensationSupportingInfrastructure.${index}` as const
                return (
                  <Group disableBottomBorder key={field.id} title={`Объект №${index + 1}`}>
                    <Stack direction={'vertical'} gap={3}>
                      <Row>
                        <Col xs={12}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.supportingInfrastructureObjectName`,
                            })}
                          >
                            <ControlledInput
                              {...getInputProps({
                                name: `${name}.supportingInfrastructureObjectName`,
                                inputProps: {
                                  label: 'Объект обеспечивающей инфраструктуры',
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </Col>
                      </Row>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        title={'Затраты на объект обеспечивающей инфраструктуры'}
                        titleHeightFallback={'40px'}
                        firstColumnSize={6}
                        secondColumnSize={6}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.supportingInfrastructureObjectValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.supportingInfrastructureObjectValue`,
                              rules:
                                supportingCompensationsValidationMap.supportingInfrastructureObjectValue,
                              onBlur: () =>
                                setTimeout(
                                  () =>
                                    handleChangeValue?.(
                                      `${name}.supportingInfrastructureObjectValue`,
                                    ),
                                  0,
                                ),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${name}.supportingInfrastructureObjectValue`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Stack>
                  </Group>
                )
              })}
              <Row>
                <Col xs={12}>
                  <Border />
                </Col>
              </Row>
              <FieldView.RowWithBottomBorder
                disableTopPadding
                disableBottomDefaultStyles
                titleHeightFallback={'40px'}
                title={'Сумма затрат по всем объектам обеспечивающей инфраструктуры'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.generalCompensationSupportingInfrastructure`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.generalCompensationSupportingInfrastructure`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
          <Group
            disableBottomBorder
            title={
              'Затраты на уплату процентов по кредитам и займам, купонного дохода по облигационным займам'
            }
          >
            <Stack direction={'vertical'} gap={3}>
              {creditCompensations.map((field, index) => {
                const name = `${formName}.compensationPercentCredit.${index}` as const
                return (
                  <Group disableBottomBorder key={field.id} title={`Договор №${index + 1}`}>
                    <Stack direction={'vertical'} gap={3}>
                      <Row>
                        <Col xs={12}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.percentCreditFullName`,
                            })}
                          >
                            <ControlledInput
                              {...getInputProps({
                                name: `${name}.percentCreditFullName`,
                                inputProps: {
                                  label: 'Наименование',
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </Col>
                      </Row>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        title={'Затраты на договор'}
                        firstColumnSize={6}
                        secondColumnSize={6}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.compensationRelatedContractValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.compensationRelatedContractValue`,
                              rules:
                                creditCompensationsValidationMap.compensationRelatedContractValue,
                              onBlur: () =>
                                setTimeout(
                                  () =>
                                    handleChangeValue?.(`${name}.compensationRelatedContractValue`),
                                  0,
                                ),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${name}.compensationRelatedContractValue`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Stack>
                  </Group>
                )
              })}
              <Row>
                <Col xs={12}>
                  <Border />
                </Col>
              </Row>
              <FieldView.RowWithBottomBorder
                disableTopPadding
                disableBottomDefaultStyles
                title={'Сумма затрат по всем договорам'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.generalCompensationsPercentCredit`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.generalCompensationsPercentCredit`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
          <Group
            disableBottomBorder
            title={
              'Затраты на демонтаж объектов жилищного строительства, расположенных на территориях военных городков'
            }
          >
            <Stack direction={'vertical'} gap={3}>
              {dismantlingCompensations.map((field, index) => {
                const name = `${formName}.compensationDismantling.${index}` as const
                return (
                  <Group disableBottomBorder key={field.id} title={`Объект №${index + 1}`}>
                    <Stack direction={'vertical'} gap={3}>
                      <Row>
                        <Col xs={12}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.dismantlingObjectName`,
                            })}
                          >
                            <ControlledInput
                              {...getInputProps({
                                name: `${name}.dismantlingObjectName`,
                                inputProps: {
                                  label: 'Наименование объекта',
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </Col>
                      </Row>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        title={'Затраты на объект'}
                        firstColumnSize={6}
                        secondColumnSize={6}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.dismantlingObjectValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.dismantlingObjectValue`,
                              rules: dismantlingCompensationsValidationMap.dismantlingObjectValue,
                              onBlur: () =>
                                setTimeout(
                                  () => handleChangeValue?.(`${name}.dismantlingObjectValue`),
                                  0,
                                ),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(`${name}.dismantlingObjectValue`)
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Stack>
                  </Group>
                )
              })}
              <Row>
                <Col xs={12}>
                  <Border />
                </Col>
              </Row>
              <FieldView.RowWithBottomBorder
                disableTopPadding
                disableBottomDefaultStyles
                title={'Сумма затрат по всем объектам'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.generalCompensationsDismantling`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.generalCompensationsDismantling`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
          <Group
            disableBottomBorder
            title={
              'Затраты на документально подтвержденные и фактически понесенные расходы, возникшие вследствие нарушения условий стабилизационной оговорки'
            }
          >
            <Stack direction={'vertical'} gap={3}>
              {expenseCompensations.map((field, index) => {
                const name = `${formName}.compensationExpense.${index}` as const
                return (
                  <Group
                    disableBottomBorder
                    key={field.id}
                    title={`Направление расхода №${index + 1}`}
                  >
                    <Stack direction={'vertical'} gap={3}>
                      <Row>
                        <Col xs={12}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.expenseName`,
                            })}
                          >
                            <ControlledInput
                              {...getInputProps({
                                name: `${name}.expenseName`,
                                inputProps: {
                                  label: 'Направление расхода',
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </Col>
                      </Row>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        title={'Затраты по расходу'}
                        firstColumnSize={6}
                        secondColumnSize={6}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.expenseValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.expenseValue`,
                              rules: expenseCompensationsValidationMap.expenseValue,
                              onBlur: () =>
                                setTimeout(() => handleChangeValue?.(`${name}.expenseValue`), 0),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(`${name}.expenseValue`)
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Stack>
                  </Group>
                )
              })}
              <Row>
                <Col xs={12}>
                  <Border />
                </Col>
              </Row>
              <FieldView.RowWithBottomBorder
                disableTopPadding
                disableBottomDefaultStyles
                titleHeightFallback={'40px'}
                title={'Сумма затрат по всем направлениям расходов'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.generalCompensationsExpenses`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.generalCompensationsExpenses`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
          <Group disableBottomBorder title={'Объем возмещенных затрат (расходов)'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                title={'В форме субсидии'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.compensationSubsidyFormat`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.compensationSubsidyFormat`,
                      rules: compensationMonitoringValidationMap.compensationSubsidyFormat,
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.compensationSubsidyFormat`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.compensationSubsidyFormat`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                title={'В форме налогового вычета'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.compensationTaxDeductionFormat`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.compensationTaxDeductionFormat`,
                      rules: compensationMonitoringValidationMap.compensationTaxDeductionFormat,
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.compensationTaxDeductionFormat`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.compensationTaxDeductionFormat`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                title={'В форме возмещения реального ущерба'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.compensationDamageFormat`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.compensationDamageFormat`,
                      rules: compensationMonitoringValidationMap.compensationDamageFormat,
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.compensationDamageFormat`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.compensationDamageFormat`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableTopPadding
                disableBottomDefaultStyles
                title={'Общий объем возмещенных затрат'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.generalCompensation`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.generalCompensation`,
                      inputProps: { disabled: true },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
        </Stack>
      </FieldView.StepTemplate>
    </Accordion>
  )
}

export default CompensationItem
