import { type FC, memo } from 'react'

import Attachments from '@components/Attachments'
import type { IDocumentTypeInfoWithDict } from '@services/Documents/documents.entity'

import styles from './AttachmentsSetItems.module.scss'

interface AttachmentsSetItemsProps {
  permissions: number
  documentSetId: string
  currentDfoId: string
  typeOfSets: string
  documentTypeInfo: IDocumentTypeInfoWithDict[]
  showChangedSetDocuments?: boolean
  isChangesMadeTab?: boolean
  digitizingInProcess?: boolean
}

const AttachmentsSetItems: FC<AttachmentsSetItemsProps> = ({
  permissions,
  documentTypeInfo,
  currentDfoId,
  documentSetId,
  typeOfSets,
  showChangedSetDocuments,
  isChangesMadeTab,
  digitizingInProcess,
}) => {
  return (
    <div className={styles.attachmentContainer}>
      {documentTypeInfo.map((documentProps) => (
        <div key={documentProps.description}>
          <Attachments.ItemController
            digitizingInProcess={digitizingInProcess}
            isChangesMadeTab={isChangesMadeTab}
            showChangedSetDocuments={showChangedSetDocuments}
            permissions={permissions}
            documentSetId={documentSetId}
            currentDfoId={currentDfoId}
            typeOfSets={typeOfSets}
            documentProps={documentProps}
          />
        </div>
      ))}
    </div>
  )
}

export default memo(AttachmentsSetItems)
