import React, { memo, ReactNode } from 'react'

import Button from '@components/NewDesign/Button'
import DoneIcon from '@icons/navigation/done.svg'
import { StatementSortFormValues } from '@routes/NewStatements/types'
import cn from 'classnames'

import styles from './OptionTooltipContent.module.scss'

interface OptionTooltipProps<
  Key extends StatementSortFormValues['filterActions'] | StatementSortFormValues['sortActions'],
  Name extends keyof StatementSortFormValues,
> {
  isActive: boolean
  name: Name
  keyName: Key
  children: ReactNode
  handleSelectOption: (keyName: Key, name: Name) => VoidFunction
  disabled?: boolean
}

const OptionTooltipContent = <
  Key extends StatementSortFormValues['filterActions'] | StatementSortFormValues['sortActions'],
  Name extends keyof StatementSortFormValues,
>({
  isActive,
  name,
  keyName,
  children,
  handleSelectOption,
  disabled,
}: OptionTooltipProps<Key, Name>) => (
  <Button
    fixWidth
    view="plain"
    variant="buttonSMedium"
    size="m"
    disabled={disabled}
    className={cn(styles.tooltip__content, {
      [styles['tooltip__content--normal']]: !isActive,
    })}
    leadingIcon={{
      src: DoneIcon,
      className: cn(styles['tooltip__content-buttonIcon'], {
        [styles['tooltip__content-buttonIcon--active']]: isActive && !disabled,
      }),
    }}
    onClick={handleSelectOption(keyName, name)}
  >
    {children}
  </Button>
)

export default memo(OptionTooltipContent)
