import { useCallback } from 'react'

import { handleCheckScrollOnBottom } from '@helpers/scroll/handleCheckScrollOnBottom'
import { useBooleanState } from '@hooks/useBooleanState'

const useShowMenuLinearGradient = () => {
  const { booleanState: isShowLineGradient, setBooleanState: setShowLineGradient } =
    useBooleanState()

  const handleShowLineGradient = useCallback(
    (element: HTMLElement) => {
      const { isShowScroll, isScrollOnBottom } = handleCheckScrollOnBottom(element)

      setShowLineGradient(isShowScroll && !isScrollOnBottom)
    },
    [setShowLineGradient],
  )

  return { isShowLineGradient, handleShowLineGradient }
}

export { useShowMenuLinearGradient }
