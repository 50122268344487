import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  IAnotherStage,
  IConstructionExploitation,
  IConstructionObject,
  IConstructionPermission,
  IConstructionRegistration,
  IEventOfStage,
  IManufactoringGood,
  IProjectResult,
  IRidExploitation,
  IRidObject,
  IRidRegistration,
  ObjectArea,
  ObjectOption,
  ObjectRegion,
  ProjectSZPKFormValues,
  RidNameRegistration,
  StepAnotherStage,
  TConstructionSubObject,
} from '@components/Forms/ProjectSZPKForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFForProjectSZPK = (
  objectFromPropertyState: Record<string, any>,
): ProjectSZPKFormValues => {
  const getPreparedObjectRegions = (objectRegions: any[]): ObjectRegion[] => {
    if (!isArray(objectRegions) || !objectRegions.length) return []

    return objectRegions.map(
      (objectRegion): ObjectRegion => ({
        id: objectRegion?.value?.objectRegionCode?.propertyId,
        objectRegion: objectRegion?.value?.objectRegionCode?.value || '',
      }),
    )
  }

  const preparedStepActivityOthers = isArray(objectFromPropertyState.steps?.value)
    ? objectFromPropertyState.steps?.value.map((step, indexOfStep): StepAnotherStage => {
        return {
          id: step.propertyId,
          activityOthers: step.value.stepActivityOthers?.value.map(
            (stepActivity, indexOfActivity): Omit<IAnotherStage, 'frontId'> => {
              const activityAnchorId = `3.3.${indexOfStep + 1}.${indexOfActivity + 1}`

              const preparedMeasureObjects = step.value.objects?.value
                .filter((object) => {
                  return object.value.objectOtherActivityName?.value.id === stepActivity.permanentId
                })
                .map((object, indexOfObject) => {
                  const preparedObjectAreas =
                    isArray(object.value?.objectAreas?.value) &&
                    object.value?.objectAreas?.value?.length
                      ? object.value?.objectAreas?.value.map((objectArea): ObjectArea => {
                          return {
                            id: objectArea.propertyId,
                            objectAreaNumber: objectArea.value.objectAreaNumber?.value || '',
                            objectAreaSquare: objectArea.value.objectAreaSquare?.value || '',
                          }
                        })
                      : []

                  return {
                    anchorId: `${activityAnchorId}.${indexOfObject + 1}`,
                    id: object.propertyId,
                    objectName: object.value.objectName?.value,
                    objectRegions: getPreparedObjectRegions(object.value?.objectRegions?.value),
                    objectAreas: preparedObjectAreas,
                    objectConstructionKind: object.value?.objectConstructionKind?.value,
                    objectPurpose: object.value?.objectPurpose?.value,
                    objectConstructionSquare: object.value?.objectConstructionSquare?.value,
                    objectSquare: object.value.objectSquare?.value,
                    objectNonLivingAreaSquare: object.value?.objectNonLivingAreaSquare?.value,
                    objectLivingAreaSquare: object.value?.objectLivingAreaSquare?.value,
                    objectFloor: object.value.objectFloor?.value,
                    objectUndergroundFloor: object.value?.objectUndergroundFloor?.value,
                    objectHeight: object.value?.objectHeight?.value,
                    objectDetails: object.value.objectDetails?.value,
                    objectIsInfrastructure: object.value.isInfrastructure?.value,
                    objectInfrastructureType: !!object.value.infrastructureType?.value?.id
                      ? {
                          value: object.value.infrastructureType?.value?.id || '',
                          displayValue: object.value.infrastructureType?.value?.name || '',
                        }
                      : null,
                  }
                })

              return {
                anchorId: activityAnchorId,
                id: stepActivity.propertyId,
                permanentId: stepActivity.permanentId,
                otherActivityName: stepActivity.value.otherActivityName?.value,
                otherActivityEndDate: stepActivity.value.otherActivityEndDate?.value,
                isOtherActivityInfrastructure:
                  stepActivity.value.isOtherActivityInfrastructure?.value,
                otherActivityInfrastructureTypeName: !!stepActivity.value
                  .otherActivityInfrastructureType?.value?.id
                  ? {
                      value: stepActivity.value.otherActivityInfrastructureType?.value?.id || '',
                      displayValue:
                        stepActivity.value.otherActivityInfrastructureType?.value?.name || '',
                    }
                  : null,
                stepNumber: step.value.stepActivityOthers?.propertyId,
                measureObjects: preparedMeasureObjects,
              }
            },
          ),
        }
      })
    : []

  const preparedProjectResults = isArray(objectFromPropertyState.projectResults?.value)
    ? objectFromPropertyState.projectResults?.value.map((item): IProjectResult => {
        return {
          id: item.propertyId,
          projectResultName: item.value.projectResultName?.value,
          isStabilizationResult: item.value.isStabilizationResult?.value,
          projectResultCodeTnVed: item.value.projectResultCodeTnVed?.value,
          volumeOfProduction: isArray(item.value.projectResultValues?.value)
            ? item.value.projectResultValues?.value
                .sort(
                  (projectResultA, projectResultB) => projectResultA.order - projectResultB.order,
                )
                .map(
                  (projectResult): IManufactoringGood => ({
                    id: projectResult.propertyId,
                    projectResultYear: projectResult.value.projectResultYear?.value,
                    projectResultValue: projectResult.value.projectResultYearValue?.value,
                    projectResultUnit: projectResult.value.projectResultValueUnit?.value,
                  }),
                )
            : [],
        }
      })
    : []

  const preparedEventOfStages: IEventOfStage[] = (() => {
    const eventOfStagesAnchorId = '3.2'

    return objectFromPropertyState.steps?.value.map((step, indexOfStep) => {
      const stepAnchorId = `${eventOfStagesAnchorId}.${indexOfStep + 1}`

      const checkboxes = (() => {
        const chosenCheckboxes = {
          isConstruction: false,
          isReconstruction: false,
          isModernization: false,
          isCreateRID: false,
        }

        step.value.stepActivityNames?.value.forEach((stepActivity) => {
          if (stepActivity.value?.name === 'Строительство (создание)') {
            chosenCheckboxes['isConstruction'] = true
          }

          if (stepActivity.value?.name === 'Реконструкция') {
            chosenCheckboxes['isReconstruction'] = true
          }

          if (stepActivity.value?.name === 'Модернизация') {
            chosenCheckboxes['isModernization'] = true
          }

          if (stepActivity.value?.name === 'Создание РИД') {
            chosenCheckboxes['isCreateRID'] = true
          }
        })

        return chosenCheckboxes
      })()

      const preparedConstructionObjects = (() => {
        const objects = step.value.objects?.value?.filter((object) => {
          return (
            object.value.isMainObject?.value &&
            !object.value.isSubObject?.value &&
            !object.value.objectOtherActivityName?.value?.id
          )
        })

        const subObjects = step.value.objects?.value?.filter((object) => {
          return (
            object.value.isSubObject?.value &&
            !object.value.objectOtherActivityName?.value?.id &&
            object.value.parentSubObject?.value
          )
        })

        const preparedConstructionObjects: IConstructionObject[] = objects?.map(
          (object, indexOfObject) => {
            const objectAnchorId = `${stepAnchorId}.1.${indexOfObject + 1}`

            const preparedSubObjects: TConstructionSubObject[] = subObjects
              ?.filter((subObject) => {
                return object.permanentId === subObject.value.parentSubObject?.value?.id
              })
              .map((subObject, indexOfSubObject) => {
                return {
                  anchorId: `${objectAnchorId}.${indexOfSubObject + 1}`,
                  id: subObject.propertyId,
                  objectName: subObject.value.objectName?.value,
                  objectRegions: getPreparedObjectRegions(subObject.value?.objectRegions?.value),
                  objectConstructionKind: subObject.value?.objectConstructionKind?.value,
                  objectPurpose: subObject.value?.objectPurpose?.value,
                  objectConstructionSquare: subObject.value?.objectConstructionSquare?.value,
                  objectSquare: subObject.value.objectSquare?.value,
                  objectNonLivingAreaSquare: subObject.value?.objectNonLivingAreaSquare?.value,
                  objectLivingAreaSquare: subObject.value?.objectLivingAreaSquare?.value,
                  objectFloor: subObject.value.objectFloor?.value,
                  objectUndergroundFloor: subObject.value?.objectUndergroundFloor?.value,
                  objectHeight: subObject.value?.objectHeight?.value,
                  objectDetails: subObject.value.objectDetails?.value,
                  objectIsInfrastructure: subObject.value.isInfrastructure?.value,
                  objectInfrastructureType: !!subObject.value.infrastructureType?.value?.id
                    ? {
                        value: subObject.value.infrastructureType.value?.id || '',
                        displayValue: subObject.value.infrastructureType.value?.name || '',
                      }
                    : null,
                }
              })

            const preparedObjectAreas =
              isArray(object.value?.objectAreas?.value) && object.value?.objectAreas?.value?.length
                ? object.value?.objectAreas?.value.map((objectArea): ObjectArea => {
                    return {
                      id: objectArea.propertyId,
                      objectAreaNumber: objectArea.value.objectAreaNumber?.value || '',
                      objectAreaSquare: objectArea.value.objectAreaSquare?.value || '',
                    }
                  })
                : []

            return {
              anchorId: objectAnchorId,
              id: object.permanentId,
              objectName: object.value.objectName?.value,
              objectRegions: getPreparedObjectRegions(object.value?.objectRegions?.value),
              objectAreas: preparedObjectAreas,
              objectConstructionKind: object.value?.objectConstructionKind?.value,
              objectPurpose: object.value?.objectPurpose?.value,
              objectConstructionSquare: object.value?.objectConstructionSquare?.value,
              objectSquare: object.value.objectSquare?.value,
              objectNonLivingAreaSquare: object.value?.objectNonLivingAreaSquare?.value,
              objectLivingAreaSquare: object.value?.objectLivingAreaSquare?.value,
              objectFloor: object.value.objectFloor?.value,
              objectUndergroundFloor: object.value?.objectUndergroundFloor?.value,
              objectHeight: object.value?.objectHeight?.value,
              objectDetails: object.value.objectDetails?.value,
              objectIsInfrastructure: object.value.isInfrastructure?.value,
              objectInfrastructureType: !!object.value.infrastructureType?.value?.id
                ? {
                    value: object.value.infrastructureType.value?.id || '',
                    displayValue: object.value.infrastructureType.value?.name || '',
                  }
                : null,
              subObjects: preparedSubObjects,
            }
          },
        )

        return preparedConstructionObjects
      })()

      const preparedPermissions = ((): IConstructionPermission[] => {
        if (!step.value.permissions?.value || !step.value.permissions.value.length) return []

        return step.value.permissions?.value.map((permission, indexOfPermission) => {
          const preparedObjectNamePermissions = ((): ObjectOption[] =>
            permission.value?.permissionObjects?.value?.map(
              (permissionObject): ObjectOption => ({
                id: permissionObject.propertyId,
                objectOption: !!permissionObject?.value?.id
                  ? {
                      value: permissionObject?.value?.id || '',
                      displayValue: permissionObject?.value?.objectName || '',
                    }
                  : null,
              }),
            ))()

          return {
            anchorId: `${stepAnchorId}.2.${indexOfPermission + 1}`,
            id: permission.propertyId,
            permissionKind: !!permission.value.permissionKind?.value?.id
              ? {
                  value: permission.value.permissionKind?.value?.id || '',
                  displayValue: permission.value.permissionKind?.value?.name || '',
                }
              : null,
            permissionOrganization: permission.value.permissionOrganization?.value,
            objectNamePermissions: preparedObjectNamePermissions,
            isReceivedPermission: permission.value.isReceivedPermission?.value,
            permissionDate: permission.value.permissionDate?.value,
            permissionNumber: permission.value.permissionNumber?.value,
            receivedPermissionDate: permission.value.receivedPermissionDate?.value,
          }
        })
      })()

      const preparedExploitationObjects = ((): IConstructionExploitation[] => {
        if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
          return []

        return step.value.commissionObjects?.value.map(
          (exploitation, indexOfExploitation): IConstructionExploitation => ({
            anchorId: `${stepAnchorId}.3.${indexOfExploitation + 1}`,
            id: exploitation.propertyId,
            objectName: !!exploitation.value?.commissionObjectId?.value?.id
              ? {
                  value: exploitation.value?.commissionObjectId?.value?.id || '',
                  displayValue: exploitation.value?.commissionObjectId?.value?.objectName || '',
                }
              : null,
            objectCommissioningDate: exploitation.value?.commissionObjectDate?.value,
            objectPermissionCommissionNumber: !!exploitation.value?.commissionPermissionNumber
              ?.value?.id
              ? {
                  value: exploitation.value?.commissionPermissionNumber?.value?.id || '',
                  displayValue:
                    exploitation.value?.commissionPermissionNumber?.value?.permissionNumber || '',
                }
              : null,
          }),
        )
      })()

      const preparedRegistrationObjects = ((): IConstructionRegistration[] => {
        if (!step.value.registrationObjects?.value || !step.value.registrationObjects?.value.length)
          return []

        return step.value.registrationObjects?.value.map(
          (registration, indexOfRegistration): IConstructionRegistration => ({
            anchorId: `${stepAnchorId}.4.${indexOfRegistration + 1}`,
            id: registration.propertyId,
            objectName: !!registration.value?.registrationObjectId?.value?.id
              ? {
                  value: registration.value?.registrationObjectId?.value?.id || '',
                  displayValue: registration.value?.registrationObjectId?.value?.objectName || '',
                }
              : null,
            objectType: registration.value?.stateRegistrationType?.value,
            estateObjectStateRegistrationDate:
              registration.value?.objectStateRegistrationDate?.value,
            estateObjectStateRegistrationNumber:
              registration.value?.objectStateRegistrationNumber?.value,
            estateObjectStateRegistrationOrganization:
              registration.value?.objectStateRegistrationOrganization?.value,
          }),
        )
      })()

      const preparedRids = ((): IRidObject[] => {
        if (!step.value.rids?.value || !step.value.rids?.value.length) return []

        return step.value.rids?.value.map(
          (rid, indexOfRid): IRidObject => ({
            id: rid.propertyId,
            anchorId: `${stepAnchorId}.5.${indexOfRid + 1}`,
            ridKind: rid.value?.ridKind?.value,
            ridName: rid.value?.ridName?.value,
            ridAddress: rid.value?.ridAddress?.value,
            ridOwner: rid.value?.ridOwner?.value,
            ridDetails: rid.value?.ridDetails?.value,
          }),
        )
      })()

      const preparedExploitationRids = ((): IRidExploitation[] => {
        if (!step.value.commissionRids?.value || !step.value.commissionRids?.value.length) return []

        return step.value.commissionRids?.value.map(
          (exploitation, indexOfExploitation): IRidExploitation => ({
            anchorId: `${stepAnchorId}.6.${indexOfExploitation + 1}`,
            id: exploitation.propertyId,
            ridName: !!exploitation.value?.commissionRidId?.value?.id
              ? {
                  value: exploitation.value?.commissionRidId?.value?.id || '',
                  displayValue: exploitation.value?.commissionRidId?.value?.ridName || '',
                }
              : null,
            ridCommissionNumber: exploitation.value?.ridCommissionNumber?.value,
            ridCommissioningDate: exploitation.value?.ridCommissioningDate?.value,
          }),
        )
      })()

      const preparedRegistrationRids = ((): IRidRegistration[] => {
        if (!step.value.registrationRids?.value || !step.value.registrationRids?.value.length)
          return []

        return step.value.registrationRids?.value.map(
          (registration, indexOfRegistration): IRidRegistration => ({
            anchorId: `${stepAnchorId}.7.${indexOfRegistration + 1}`,
            id: registration.propertyId,
            ridName: !!registration.value?.registrationRidId.value?.id
              ? {
                  value: registration.value?.registrationRidId.value?.id || '',
                  displayValue: registration.value?.registrationRidId.value?.ridName || '',
                }
              : null,
            ridStateRegistrationNumber: registration.value?.ridStateRegistrationNumber?.value,
            ridStateRegistrationOrganization:
              registration.value?.ridStateRegistrationOrganization?.value,
          }),
        )
      })()

      const preparedRidNameRegistrations = ((): RidNameRegistration[] => {
        if (
          !step.value?.ridStateRegistrationIds?.value ||
          !step.value.ridStateRegistrationIds?.value.length
        )
          return []

        return step.value.ridStateRegistrationIds?.value.map(
          (registrationRid): RidNameRegistration => ({
            id: registrationRid.propertyId,
            ridOption: !!registrationRid.value?.id
              ? {
                  value: registrationRid.value?.id || '',
                  displayValue: registrationRid.value?.ridName || '',
                }
              : null,
          }),
        )
      })()

      return {
        id: step.propertyId,
        anchorId: stepAnchorId,
        tabName: step.value.stepDescription?.value,

        //Фейковое свойство для расчётов
        stepActivityNames: step.value.stepActivityNames?.value,

        ...checkboxes,
        constructionStage: {
          constructionObjects: preparedConstructionObjects,
          permissionsObjects: preparedPermissions,

          exploitation: {
            objectCommissioningEndDate: step.value.objectCommissioningEndDate?.value,
            exploitationObjects: preparedExploitationObjects,
          },

          registration: {
            typeObjectStateRegistration: step.value.typeObjectStateRegistration?.value,
            objectStateRegistrationEndDate: step.value.objectStateRegistrationEndDate?.value,
            registrationObjects: preparedRegistrationObjects,
          },
        },
        ridStage: {
          ridObjects: preparedRids,
          exploitation: {
            ridCommissioningEndDate: step.value.ridCommissioningEndDate?.value,
            ridExploitationObjects: preparedExploitationRids,
          },
          registration: {
            ridNames: preparedRidNameRegistrations,
            ridStateRegistrationEndDate: step.value.ridStateRegistrationEndDate?.value,
            ridRegistrationsObjects: preparedRegistrationRids,
          },
        },
      }
    })
  })()

  return {
    '1': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '4': {
      ruleSubjectSzpkInstrumental: objectFromPropertyState.ruleSubjectSzpkInstrumental?.value,
      ruleLinkStabilizationNpa: objectFromPropertyState.ruleLinkStabilizationNpa?.value,
    },
    '5': {
      projectName: objectFromPropertyState.projectName?.value,
      projectRegion: objectFromPropertyState.projectRegionCode?.value,
      economicSphere: !!objectFromPropertyState.economicSphere?.value?.id
        ? {
            value: objectFromPropertyState.economicSphere?.value?.id || '',
            displayValue: objectFromPropertyState.economicSphere?.value?.name || '',
          }
        : null,
      otherEconomicSphere: objectFromPropertyState?.otherEconomicSphere?.value,
      projectPurpose: objectFromPropertyState.projectPurpose?.value,
      projectStartDate: objectFromPropertyState.projectStartDate?.value,
      projectDecisionDepartmentGenitive:
        objectFromPropertyState?.projectDecisionDepartamentGenitive?.value || '',
      totalCapital: objectFromPropertyState.totalCapital?.value,
      investorCapital: objectFromPropertyState.investorCapital?.value,
      madeInvestorCapital: objectFromPropertyState.madeInvestorCapital?.value,
      remainsInvestorCapital: objectFromPropertyState.remainsInvestorCapital?.value,
      madeInvestorCapitalDate: objectFromPropertyState.madeInvestorCapitalDate?.value,
      projectSupportCompensation:
        (isArray(objectFromPropertyState.projectCompensations?.value)
          ? objectFromPropertyState.projectCompensations?.value.find(
              (item) =>
                item.value.projectCompensationType.value?.name === 'Обеспечивающая инфраструктура',
            )?.value.projectCompensationValue?.value
          : '') || '',
      projectRelatedCompensation:
        (isArray(objectFromPropertyState.projectCompensations?.value)
          ? objectFromPropertyState.projectCompensations?.value.find(
              (item) =>
                item.value.projectCompensationType.value?.name === 'Сопутствующая инфраструктура',
            )?.value.projectCompensationValue?.value
          : '') || '',
    },
    '6': {
      stages:
        isArray(objectFromPropertyState.steps?.value) && objectFromPropertyState.steps.value.length
          ? objectFromPropertyState.steps?.value.map((step) => ({
              id: step.propertyId,
              stepNumber: step.value.stepNumber?.value,
              stepDescription: step.value.stepDescription?.value,
              stepStartDate: step.value.stepStartDate?.value,
              stepEndDate: step.value.stepEndDate?.value,
              stepInvestorCapitalValue: step.value.stepInvestorCapitalValue?.value,
              stepCompensationValue: step.value.stepCompensationValue?.value,
              stepCompensationForm: !!step.value.stepCompensationForm?.value?.id
                ? {
                    value: step.value.stepCompensationForm?.value?.id || '',
                    displayValue: step.value.stepCompensationForm?.value?.name || '',
                  }
                : null,
              stepCompensations:
                isArray(step.value.stepCompensations?.value) &&
                step.value.stepCompensations?.value.length
                  ? step.value.stepCompensations?.value.map((stepCompensation) => ({
                      id: stepCompensation.propertyId,
                      stepCompensationType: !!stepCompensation.value.stepCompensationType?.value?.id
                        ? {
                            displayValue:
                              stepCompensation.value?.stepCompensationType?.value?.name || '',
                            value: stepCompensation.value?.stepCompensationType?.value?.id || '',
                          }
                        : null,
                      stepCompensationPeriod:
                        stepCompensation.value?.stepCompensationPeriod?.value?.name || '',
                    }))
                  : [],
            }))
          : [],
    },
    '7': {
      eventsOfStages: preparedEventOfStages,
    },
    '8': {
      stepActivityOthers: preparedStepActivityOthers,
    },
    '9': {
      projectResultEcoActivity: {
        ecoActivityName: !!objectFromPropertyState.projectResultEcoActivity?.value?.ecoActivityName
          ?.value?.id
          ? {
              value:
                objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityName?.value
                  ?.id || '',
              displayValue:
                objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityName?.value
                  ?.name || '',
            }
          : null,
        ecoActivityProgramNameInstrumental:
          objectFromPropertyState.projectResultEcoActivity?.value
            .ecoActyvityProgrammNameInstrumental?.value,
        ecoActivityEndDate:
          objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityEndDate?.value,
      },
      projectResultActivity: objectFromPropertyState.projectResultActivities?.value
        .map((resultActivity) =>
          !!resultActivity.value?.id
            ? {
                value: resultActivity.value?.id,
                displayValue: resultActivity.value?.name || '',
              }
            : null,
        )
        .filter(Boolean),
      otherProjectResultActivity: objectFromPropertyState?.otherProjectResultActivity?.value || '',
      projectResults: preparedProjectResults,
    },
    '10': {
      technologicalRequirements: isArray(objectFromPropertyState.projectNpas?.value)
        ? objectFromPropertyState.projectNpas.value.map((npa) => {
            return {
              id: npa.propertyId,
              projectNpaName: npa.value,
            }
          })
        : [],
    },
    '11': {
      rightDutySubjectForestResource: objectFromPropertyState.rightDutySubjectWaterResource?.value,
      rightDutySubjectWaterResource: objectFromPropertyState.rightDutySubjectForestResource?.value,
      rightDutyRfForestResource: objectFromPropertyState.rightDutyRfWaterResource?.value,
      rightDutyRfWaterResource: objectFromPropertyState.rightDutyRfForestResource?.value,
    },
    '13': {
      extraMunicipalAccessionLinkCompensation:
        objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.value,
      extraMunicipalAccessionLinkStabilization:
        objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.value,
      stabilizationPeriod: objectFromPropertyState.stabilizationPeriod?.value,
    },
    '14': {
      ruleLinkRelatedContract: objectFromPropertyState.ruleLinkRelatedContract?.value,
      subjectRuleLinkRelatedContract: objectFromPropertyState.subjectRuleLinkRelatedContract?.value,
    },
    '15': {
      subjectNpaReasonMonitoring: objectFromPropertyState.subjectNpaReasonMonitoring?.value,
      ruleLinkMonitoring: objectFromPropertyState.ruleLinkMonitoring?.value,
    },
    '16': {
      courtChoice: objectFromPropertyState.courtChoice?.value,
      courtName: objectFromPropertyState.courtName?.value,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      isMunicipalityParticipant: !!objectFromPropertyState.municipalities?.value?.length,
      projectEndDate: objectFromPropertyState.projectEndDate?.value,
      anotherStageOptions: isArray(objectFromPropertyState.steps?.value)
        ? objectFromPropertyState.steps?.value.map((step) => ({
            displayValue: step.value.stepNumber?.value,
            value: step.value.stepActivityOthers?.propertyId,
          }))
        : [],
      allProjectObjectsLength: isArray(objectFromPropertyState.steps?.value)
        ? objectFromPropertyState.steps?.value.reduce((accum, currentValue) => {
            if (isArray(currentValue.value.objects?.value)) {
              return accum + currentValue.value.objects?.value.length
            }

            return accum
          }, 0)
        : 0,
    },
  }
}

export { generateRHFForProjectSZPK }
