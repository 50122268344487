import { useAppDispatch } from '@store'

import { TechnicalWorkActions } from './TechnicalWork.store'

const useTechnicalWorkManager = () => {
  const dispatch = useAppDispatch()

  const initializeState = () => {
    return dispatch(TechnicalWorkActions.initializeState())
  }

  return { initializeState }
}

export { useTechnicalWorkManager }
