import React, { memo } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import {
  BaseNpaFormValues,
  NpaStructureArrayPathName,
} from '@components/DocumentFormComponents/NpaFormAddon/types'
import { PartCustomPropsWithId } from '@services/NPA/NPA.entity'
import { ICardViewRefs } from 'components/Projects/[id]/DocumentLayout/Addons/NpaAddonOld'

import ClassificationView from '../Classification'

interface ClassificationsViewProps<T extends BaseNpaFormValues> {
  blockViewIsValidating: boolean
  formName: NpaStructureArrayPathName
  formInstance: T extends BaseNpaFormValues ? UseFormReturn<T> : never
  onBindCardRef: (callback: (prevState: ICardViewRefs | null) => ICardViewRefs) => void
  onScrollToCardView: (partId: string) => void
  onRemoveNpaPart?: (id: string) => Promise<void>
  onUpdateNpaPart?: (partProps: PartCustomPropsWithId) => Promise<void>
}

const ClassificationsView = <T extends BaseNpaFormValues>({
  blockViewIsValidating,
  formInstance,
  formName,
  onScrollToCardView,
  onBindCardRef,
  onUpdateNpaPart,
  onRemoveNpaPart,
}: ClassificationsViewProps<T>) => {
  const { fields: classifications } = useFieldArray({
    name: `${formName}.classifications`,
    control: formInstance.control,
    keyName: 'classificationId',
  })

  return (
    <>
      {classifications.map((classification, index) => {
        const classificationFormName = `${formName}.classifications.${index}` as const

        return (
          <ClassificationView
            key={classification.classificationId}
            blockViewIsValidating={blockViewIsValidating}
            nameOfFieldArray={classificationFormName}
            classification={classification}
            formInstance={formInstance}
            onBindCardRef={onBindCardRef}
            onScrollToCardView={onScrollToCardView}
            onRemoveNpaPart={onRemoveNpaPart}
            onUpdateNpaPart={onUpdateNpaPart}
          />
        )
      })}
    </>
  )
}

export default memo(ClassificationsView)
