import React from 'react'
import { useFormContext } from 'react-hook-form'

import SubjectsSection from '@components/DocumentFormComponents/FormSections/SubjectsSection'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import {
  AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'

const Second = () => {
  const formInstance = useFormContext<AAgreementOnCreationOtherEstateObjectsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  if (!formInstance) return null

  return (
    <SubjectsSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'2.subjects'}
      formInstance={formInstance}
      watcher={AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher}
      fieldArrayWatcher={AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
