import {
  ChangeMultiplePropertyBodyStructureWithContent,
  ChangePropertyBodySimple,
  ChangePropertyBodyStructureWithContent,
  ChangePropertyBodyStructureWithoutValue,
  ChangePropertyEnum,
  ChangePublicValue,
  ChangePublicValueInList,
  ChangePublicValueType,
  RemovePropertyBodyStructure,
} from '@context/APIContext/hooks/usePropertiesApi'

interface ChangeActionPropertyValueProps {
  property: ChangePropertyBodySimple['property']
  newValue: unknown
}

interface AddItemInListWithoutValueActionProps {
  property: ChangePropertyBodyStructureWithoutValue['property']
}

interface AddItemInListWithValueActionProps {
  property: ChangePropertyBodyStructureWithoutValue['property']
  value: unknown
}

interface RemoveActionPropertyProps {
  property: RemovePropertyBodyStructure['property']
  removePropertyId: string
}

class PropertyActions {
  static changePropertyValue({
    property,
    newValue,
  }: ChangeActionPropertyValueProps): ChangePropertyBodySimple {
    return {
      type: ChangePropertyEnum.CHANGE_PROPERTY_VALUE,
      property,
      newValue,
    }
  }

  static addItemToListWithoutValue({ property }): ChangePropertyBodyStructureWithoutValue {
    return {
      type: ChangePropertyEnum.APPEND_CHILD_ELEMENT,
      property,
    }
  }

  static addItemToListWithContent({ property, content }): ChangePropertyBodyStructureWithContent {
    return {
      type: ChangePropertyEnum.APPEND_CHILD_ELEMENT,
      property,
      content,
    }
  }

  static addMultipleItemsToListWithContent({
    property,
    contents,
  }): ChangeMultiplePropertyBodyStructureWithContent {
    return {
      type: ChangePropertyEnum.APPEND_CHILD_ELEMENT,
      property,
      contents,
    }
  }

  static removeItemFromList({
    property,
    removePropertyId,
  }: RemoveActionPropertyProps): RemovePropertyBodyStructure {
    return {
      type: ChangePropertyEnum.DELETE_CHILD_ELEMENT,
      property,
      childElementId: removePropertyId,
    }
  }

  static orderUpElementInList({ property }): ChangePropertyBodyStructureWithoutValue {
    return {
      type: ChangePropertyEnum.ORDER_UP,
      property,
    }
  }

  static orderDownElementInList({ property }): ChangePropertyBodyStructureWithoutValue {
    return {
      type: ChangePropertyEnum.ORDER_DOWN,
      property,
    }
  }

  static changePublicValue({ property, newIsPublic }): ChangePublicValue {
    return {
      type: ChangePropertyEnum.CHANGE_IS_PUBLIC,
      property,
      newIsPublic,
    }
  }

  static changePublicValueType({ property, memberFieldName, newIsPublic }): ChangePublicValueType {
    return {
      type: ChangePropertyEnum.CHANGE_IS_PUBLIC_ID_TYPE,
      property,
      memberFieldName,
      newIsPublic,
    }
  }

  static changePublicValueInList({ property, newIsPublic }): ChangePublicValueInList {
    return property.map((propertyItem) => ({
      ...propertyItem,
      type: ChangePropertyEnum.CHANGE_IS_PUBLIC,
      newIsPublic,
    }))
  }
}

export type {
  AddItemInListWithoutValueActionProps,
  AddItemInListWithValueActionProps,
  ChangeActionPropertyValueProps,
  RemoveActionPropertyProps,
}
export { PropertyActions }
