import { TStatementParams } from '@routes/NewStatements/types'
import { projectsStateSelector } from '@services/ProjectsState/ProjectsState.selector'
import { useAppSelector } from '@store'

interface TInitialStateOfProjectsStateStore {
  transitionId: string
  transitionPage: number
  transitionFilters: TStatementParams
  transitionFoldStates: Record<string, boolean>
}

const useProjectsStatePage = () => {
  return useAppSelector(projectsStateSelector)
}

export { useProjectsStatePage }
export type { TInitialStateOfProjectsStateStore }
