import { ReactElement } from 'react'

import { dictionariesNames } from '@components/ReferenceBooks/constants'
import AgrementIcon from '@components/Sidebars/MainHeaderMenu/PreparedIcons/AgrementIcon'
import MainHeaderMenuIcon from '@components/Sidebars/MainHeaderMenu/PreparedIcons/MainHeaderMenuIcon'
import { Paths } from '@constants/paths'
import { ValueOf } from '@helpers/ValueOf'
import AuthorizedOrganizationsIcon from '@icons/headerMenu/AuthorizedOrganizationsIcon.svg'
import MyCompanyIcon from '@icons/headerMenu/MyCompanyIcon.svg'
import NPAIcon from '@icons/headerMenu/NPAIcon.svg'
import OrganizationSvgIcon from '@icons/headerMenu/OrganisationIcon.svg'
import SystemVariablesIcon from '@icons/headerMenu/SystemVariablesIcon.svg'
import HistoryIcon from '@icons/HistoryIcon.svg'
import referenceBooksIcon from '@icons/ReferenceBooksIcon.svg'
import ReportsSvgIcon from '@icons/ReportsIcon.svg'
import { buildUrl } from '@packages/api/fetcher/helpers/buildUrl'

interface MainMenuLinkItem {
  title: string
  icon: ReactElement | JSX.Element | string
  link: ValueOf<typeof Paths>
  children: MainMenuLinkItem[]
  disabled?: boolean
}

const mapOfMainMenuByRoles = {
  INVESTOR: {
    mainLinks: [{ title: 'Соглашения', icon: <AgrementIcon />, link: Paths.Projects }],
    secondaryLinks: [
      {
        title: 'Моя организация',
        icon: <MainHeaderMenuIcon src={MyCompanyIcon} />,
        link: Paths.Organization,
      },
    ],
  },
  UO: {
    mainLinks: [
      { title: 'Соглашения', icon: <AgrementIcon />, link: Paths.Projects },
      { title: 'Реестр НПА', icon: <MainHeaderMenuIcon src={NPAIcon} />, link: Paths.RegistryNpa },
    ],
    secondaryLinks: [
      {
        title: 'Моя организация',
        icon: <MainHeaderMenuIcon src={MyCompanyIcon} />,
        link: Paths.Organization,
      },
    ],
  },
  MER: {
    mainLinks: [
      { title: 'Соглашения', icon: <AgrementIcon />, link: Paths.Projects },
      { title: 'Отчёты', icon: <MainHeaderMenuIcon src={ReportsSvgIcon} />, link: Paths.Reports },
      {
        title: 'Организации',
        icon: <MainHeaderMenuIcon src={OrganizationSvgIcon} />,
        link: Paths.Organizations,
      },
      { title: 'Реестр НПА', icon: <MainHeaderMenuIcon src={NPAIcon} />, link: Paths.RegistryNpa },
      {
        title: 'Уполномоченные органы',
        icon: <MainHeaderMenuIcon src={AuthorizedOrganizationsIcon} />,
        link: Paths.AuthorizedOrganizations,
      },
      {
        title: 'Справочники',
        icon: referenceBooksIcon,
        children: [
          {
            title: 'Разрешения на объекты недвижимости',
            link: buildUrl({
              url: Paths.DictionaryPage,
              params: {
                dictionaryName: dictionariesNames.estate_permissions,
              },
            }),
          },
          {
            title: 'Мероприятия по снижению негативного воздействия на окружающую среду',
            link: buildUrl({
              url: Paths.DictionaryPage,
              params: {
                dictionaryName: dictionariesNames.eco_activities,
              },
            }),
          },
          {
            title: 'Объекты инфраструктуры',
            link: buildUrl({
              url: Paths.DictionaryPage,
              params: {
                dictionaryName: dictionariesNames.infrastructure_objects,
              },
            }),
          },
          {
            title: 'ОКВЭД',
            link: buildUrl({
              url: Paths.DictionaryPage,
              params: {
                dictionaryName: dictionariesNames.okved,
              },
            }),
          },
          {
            title: 'ТН ВЭД',
            link: buildUrl({
              url: Paths.DictionaryPage,
              params: {
                dictionaryName: dictionariesNames.tnved,
              },
            }),
          },
        ],
      },
    ],
    secondaryLinks: [
      {
        title: 'Моя организация',
        icon: <MainHeaderMenuIcon src={MyCompanyIcon} />,
        link: Paths.Organization,
      },
    ],
  },
  OIV: {
    mainLinks: [
      { title: 'Соглашения', icon: <AgrementIcon />, link: Paths.Projects },
      { title: 'Отчёты', icon: <MainHeaderMenuIcon src={ReportsSvgIcon} />, link: Paths.Reports },
      { title: 'Реестр НПА', icon: <MainHeaderMenuIcon src={NPAIcon} />, link: Paths.RegistryNpa },
    ],
    secondaryLinks: [
      {
        title: 'Моя организация',
        icon: <MainHeaderMenuIcon src={MyCompanyIcon} />,
        link: Paths.Organization,
      },
    ],
  },
  OMSU: {
    mainLinks: [
      { title: 'Соглашения', icon: <AgrementIcon />, link: Paths.Projects },
      { title: 'Отчёты', icon: <MainHeaderMenuIcon src={ReportsSvgIcon} />, link: Paths.Reports },
      { title: 'Реестр НПА', icon: <MainHeaderMenuIcon src={NPAIcon} />, link: Paths.RegistryNpa },
    ],
    secondaryLinks: [
      {
        title: 'Моя организация',
        icon: <MainHeaderMenuIcon src={MyCompanyIcon} />,
        link: Paths.Organization,
      },
    ],
  },
  ROIV: {
    mainLinks: [
      { title: 'Соглашения', icon: <AgrementIcon />, link: Paths.Projects },
      { title: 'Реестр НПА', icon: <MainHeaderMenuIcon src={NPAIcon} />, link: Paths.RegistryNpa },
    ],
    secondaryLinks: [
      {
        title: 'Моя организация',
        icon: <MainHeaderMenuIcon src={MyCompanyIcon} />,
        link: Paths.Organization,
      },
    ],
  },
  FK: {
    mainLinks: [
      { title: 'Соглашения', icon: <AgrementIcon />, link: Paths.Projects },
      { title: 'Реестр НПА', icon: <MainHeaderMenuIcon src={NPAIcon} />, link: Paths.RegistryNpa },
    ],
    secondaryLinks: [
      {
        title: 'Моя организация',
        icon: <MainHeaderMenuIcon src={MyCompanyIcon} />,
        link: Paths.Organization,
      },
    ],
  },
  OPERATOR: {
    mainLinks: [
      {
        title: 'Пользователи',
        icon: <MainHeaderMenuIcon src={MyCompanyIcon} />,
        link: Paths.Users,
      },
      { title: 'События', icon: <MainHeaderMenuIcon src={HistoryIcon} />, link: Paths.Audits },
      {
        title: 'Системные переменные',
        icon: <MainHeaderMenuIcon src={SystemVariablesIcon} />,
        link: Paths.SystemVariablesPage,
      },
    ],
    secondaryLinks: [],
  },
} as const

export type { MainMenuLinkItem }
export { mapOfMainMenuByRoles }
