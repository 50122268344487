import {
  ADD_MEMBERS_InitialProps,
  CREATE_INVENTORY_InitialProps,
  DefaultInitialProps,
  DELETE_DFO_InitialProps,
  REGISTRATION_InitialProps,
  STATEMENT_CREATE_InitialProps,
} from '@services/DocumentActions/DocumentActions.initialProps'
import { DocumentActionState } from '@services/DocumentActions/DocumentActions.service'
import { AxiosError } from 'axios'

const EDocumentActionFunctionRole = {
  INITIATOR: 0,
  DISTRIBUTOR: 1,
  REGISTRATOR: 2,
  CHECKER: 3,
  FINAL_CHECKER: 4,
  SIGNER: 5,
  FINAL_SIGNER: 6,
  INITIATOR_REVOKED: 7,
  APPLIER: 8,
  VIEWER: 9,
} as const

interface IProcessDocumentActionAction {
  processId: string
  functionRole: typeof EDocumentActionFunctionRole
}

interface IDocumentActionDfo {
  dfoId: string
  processActions: IProcessDocumentActionAction[]
}

type TDocumentActionType = 'approve' | 'cancel' | 'return'

type TUiActionPosition = 'statusPanel' | 'hamburgerButton' | 'documentsBottom' | 'setArea'

interface IDocumentAction {
  actionId: string
  dfos: IDocumentActionDfo[]
  action: TDocumentActionType
  enable: boolean
  uiActions: DocumentActionEnum[]
  displayName: string
  type: string | null
  position: TUiActionPosition
}

interface IDefaultUserAction {
  displayName: string
  type: string
  icon?: string
  callback?: () => void
}

type DocumentActionEnum =
  | RegistrationActionEnum
  | RevokeActionEnum
  | CreateActionEnum
  | 'STATEMENT_CREATE'
  | 'ADD_MEMBERS'
  | 'SIGN'
  | 'CHANGE_STAGE'
  | 'CREATE_INVENTORY'
  | 'FLC'
  | 'DELETE_DFO'
  | 'EDIT_PROCESSING_ORDER'
  | 'COPY_PROJECT'
  | 'SIGN_NOTIFICATION_CUSTOM'
  | WarningActionEnum

type WarningActionEnum = 'RIGHTS_LOSS_WARNING' | 'OPEN_SZPK_PROJECTS'

type RegistrationActionEnum =
  | 'REGISTRATION'
  | 'REGISTRATION_JUDGMENT_TERMINATE_DATE'
  | 'REGISTRATION_ORG_REORGANIZATION'

type RevokeActionEnum =
  | 'REVOKE_SZPK'
  | 'REVOKE_EXTRA'
  | 'REVOKE_MONITORING_REPORT_SZPK'
  | 'REVOKE_TERMINATE'
  | 'REVOKE_CLAIM_SUBSIDY_PERMIT'
  | 'REVOKE_OTHER_DONATIONS'
  | 'REVOKE_COMMON'
  | 'REVOKE_MUNICIPAL_ACCESSION'
  | 'REVOKE_CLAIM_TAX_REFUND'
type CreateActionEnum = 'CREATE_PROCESS' | 'CREATE_SYSTEM_DOCUMENT'

const mapOfDocumentActions: Record<DocumentActionEnum, DocumentActionEnum> = {
  STATEMENT_CREATE: 'STATEMENT_CREATE',
  ADD_MEMBERS: 'ADD_MEMBERS',
  SIGN: 'SIGN',
  CHANGE_STAGE: 'CHANGE_STAGE',
  CREATE_INVENTORY: 'CREATE_INVENTORY',
  FLC: 'FLC',
  DELETE_DFO: 'DELETE_DFO',
  EDIT_PROCESSING_ORDER: 'EDIT_PROCESSING_ORDER',
  COPY_PROJECT: 'COPY_PROJECT',
  REGISTRATION: 'REGISTRATION',
  REGISTRATION_JUDGMENT_TERMINATE_DATE: 'REGISTRATION_JUDGMENT_TERMINATE_DATE',
  REGISTRATION_ORG_REORGANIZATION: 'REGISTRATION_ORG_REORGANIZATION',
  REVOKE_SZPK: 'REVOKE_SZPK',
  REVOKE_EXTRA: 'REVOKE_EXTRA',
  REVOKE_MONITORING_REPORT_SZPK: 'REVOKE_MONITORING_REPORT_SZPK',
  REVOKE_TERMINATE: 'REVOKE_TERMINATE',
  REVOKE_CLAIM_SUBSIDY_PERMIT: 'REVOKE_CLAIM_SUBSIDY_PERMIT',
  REVOKE_OTHER_DONATIONS: 'REVOKE_OTHER_DONATIONS',
  REVOKE_COMMON: 'REVOKE_COMMON',
  REVOKE_MUNICIPAL_ACCESSION: 'REVOKE_MUNICIPAL_ACCESSION',
  CREATE_PROCESS: 'CREATE_PROCESS',
  CREATE_SYSTEM_DOCUMENT: 'CREATE_SYSTEM_DOCUMENT',
  SIGN_NOTIFICATION_CUSTOM: 'SIGN_NOTIFICATION_CUSTOM',
  OPEN_SZPK_PROJECTS: 'OPEN_SZPK_PROJECTS',
  RIGHTS_LOSS_WARNING: 'RIGHTS_LOSS_WARNING',
  REVOKE_CLAIM_TAX_REFUND: 'REVOKE_CLAIM_TAX_REFUND',
} as const

type DefaultReturnedProps = Record<string, unknown> | null

interface IDocumentIdentificateProps {
  id: string
}

interface TDocumentActionConfig {
  onFulfilled: (dataFromAction) => void
  onReject: (errorFromAction) => void
}

type TDocumentPropsByType =
  | {
      type: typeof mapOfDocumentActions.STATEMENT_CREATE
      initialProps: STATEMENT_CREATE_InitialProps
      returnedProps: DefaultReturnedProps
    }
  | {
      type: typeof mapOfDocumentActions.CREATE_INVENTORY
      initialProps: CREATE_INVENTORY_InitialProps
      returnedProps: DefaultReturnedProps
    }
  | {
      type: typeof mapOfDocumentActions.ADD_MEMBERS
      initialProps: ADD_MEMBERS_InitialProps
      returnedProps: DefaultReturnedProps
    }
  | {
      type: typeof mapOfDocumentActions.FLC
      initialProps: DefaultInitialProps
      returnedProps: DefaultReturnedProps
    }
  | {
      type: typeof mapOfDocumentActions.SIGN
      initialProps: DefaultInitialProps
      returnedProps: DefaultReturnedProps
    }
  | {
      type: typeof mapOfDocumentActions.CHANGE_STAGE
      initialProps: DefaultInitialProps
      returnedProps: DefaultReturnedProps
    }
  | {
      type: typeof mapOfDocumentActions.DELETE_DFO
      initialProps: DELETE_DFO_InitialProps
      returnedProps: DefaultReturnedProps
    }
  | {
      type: typeof mapOfDocumentActions.REGISTRATION
      initialProps: REGISTRATION_InitialProps
      returnedProps: DefaultReturnedProps
    }

type TDocumentStackAction = IDocumentIdentificateProps &
  TDocumentPropsByType & {
    config?: TDocumentActionConfig
  }

type TStackErrorMap = 'stackIsOver' | 'stackShiftError'

type TDefaultActionsErrorMap = 'actionWasCanceled'

type TDocumentActionsTypeOfError = TStackErrorMap | TDefaultActionsErrorMap

interface IStackFullProps {
  historyStack: DocumentActionState[]
  memoryStack: DocumentActionState[]
  callStack: TDocumentStackAction['id'][]
  processingAction: DocumentActionState | null
  prevAction: DocumentActionState | null
  nextAction: DocumentActionState | null
}

interface IDocumentActionsError {
  error: AxiosError | TDocumentActionsTypeOfError
  stackInfo: IStackFullProps
}

interface GetUiActionTextByClaimBudgetTypeReturnType {
  revokeClaimSubsidyHeaderText: string
  revokeClaimSubsidyBodyText: string
}

export { mapOfDocumentActions }
export type {
  DocumentActionEnum,
  EDocumentActionFunctionRole,
  GetUiActionTextByClaimBudgetTypeReturnType,
  IDefaultUserAction,
  IDocumentAction,
  IDocumentActionDfo,
  IDocumentActionsError,
  IStackFullProps,
  RevokeActionEnum,
  TDocumentActionsTypeOfError,
  TDocumentActionType,
  TDocumentPropsByType,
  TDocumentStackAction,
}
