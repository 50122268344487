import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledInputSearchTree } from '@components/NewDesign/InputSearchTree/ControlledInputSearchTree'
import { OptionProps } from '@components/NewDesign/Select/model'
import ControlledSingleSelectNew from '@components/NewDesign/Select/SingleSelectNew/Controlled'
import Sidebar from '@components/NewDesign/Sidebar'
import { PAGE_SIZE_FOR_NPA } from '@components/RegistryNpa/const'
import { RolesTypes } from '@constants/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { useAPIContext } from '@context/APIContext'
import { TPutNpaPartBody } from '@context/APIContext/hooks/useNpaApi'
import { useAuthContext } from '@context/AuthContext'
import { isString } from '@helpers/checkTypes'
import { useNpaById } from '@hooks/new/swr/useNpaById'
import { useNpaList } from '@hooks/new/swr/useNpaList'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import { useOrganizationInfo } from '@hooks/new/swr/useOrganizationInfo'
import { useBooleanState } from '@hooks/useBooleanState'
import useDebounce from '@hooks/useDebounce'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { INpaItem } from '@services/NPA/NPA.entity'

import styles from './PartSidebar.module.scss'

const PAGINATION_BOTTOM_TRIGGER = 50

const npaPartActionType = {
  excludeNpaPart: 'excludeNpaPart',
  updateNpaPart: 'updateNpaPart',
} as const

interface PartComponentProps {
  part?: INpaItem
  mainNpaId?: string
  onUpdatePart?: (newPart: INpaItem) => void
  onRemovePart?: () => void
  onReloadGrid?: () => void
}

type PartSidebarProps = PopupProps & PartComponentProps

interface PartFormValues {
  classificationId: string
  part: string

  mainNpaId: string | Pick<OptionProps, 'value' | 'displayValue'>
}

const usePartNpaSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenPartSidebar = (props: PartComponentProps) => {
    popupManager.open(PartNpaSidebar, props)
  }

  return {
    handleOpenPartSidebar,
  }
}

const PartNpaSidebar: FC<PartSidebarProps> = ({
  part,
  mainNpaId,
  isOpen,
  onClose,
  onUpdatePart,
  onRemovePart,
  onReloadGrid,
}) => {
  const {
    npaApi: { createNpaPart, updateNpaPart, excludeNpaPart },
  } = useAPIContext()

  const { checkingRole } = useAuthContext()

  const isMER = !!checkingRole?.(RolesTypes.MER)
  const isOIV = !!checkingRole?.(RolesTypes.OIV)
  const isOMSU = !!checkingRole?.(RolesTypes.OMSU)

  const { partClassificationTreeAsOptions } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  const { npa } = useNpaById({
    key: {
      npaId: mainNpaId,
      _key: 'npa',
    },
    config: {
      isPaused: () => !part,
    },
  })

  const { organizationInfo } = useOrganizationInfo({
    key: { _key: 'organizationInfo' },
    config: {
      isPaused: () => isMER,
    },
  })

  const {
    booleanState: isRequestLoading,
    setBooleanStateToFalse: disableLoading,
    setBooleanStateToTrue: enableLoading,
  } = useBooleanState()

  const initialFocusRef = useRef<HTMLInputElement | null>(null)

  const actualNpaInfo = useMemo(() => {
    return npa?.redactions?.find((redaction) => redaction.isActual)
  }, [npa?.redactions])

  const OIVIsHaveAccessToEditOrExclude = useMemo(() => {
    if (!part) return isOIV

    if (!actualNpaInfo || !organizationInfo || !isOIV) return

    return (
      actualNpaInfo.region === organizationInfo.regionCode && actualNpaInfo.level === 'REGIONAL'
    )
  }, [actualNpaInfo, isOIV, organizationInfo, part])

  const OMSUIsHaveAccessToEditOrExclude = useMemo(() => {
    if (!actualNpaInfo || !isOMSU) return

    return (
      actualNpaInfo.oktmo === organizationInfo?.municipalityOktmo &&
      actualNpaInfo.level === 'MUNICIPAL'
    )
  }, [actualNpaInfo, isOMSU, organizationInfo?.municipalityOktmo])

  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchString = useDebounce(searchValue, 500)

  const preparedSwrKey = useCallback(
    (page) => {
      const preparedLevel = isOMSU ? 'MUNICIPAL' : isOIV ? 'REGIONAL' : undefined

      if ((isOMSU || isOIV) && !organizationInfo) return null

      return {
        page,
        size: PAGE_SIZE_FOR_NPA,
        region: !isMER ? [organizationInfo?.regionCode] : undefined,
        level: preparedLevel,
        oktmo: isOMSU ? [organizationInfo?.municipalityOktmo] : undefined,
        searchString: debouncedSearchString || undefined,
        _key: 'npaList',
      }
    },
    [debouncedSearchString, isMER, isOIV, isOMSU, organizationInfo],
  )

  const { npaList, isNpaListLoading, error, hasMoreData, incPage, mutate } = useNpaList({
    key: preparedSwrKey,
    config: {
      revalidateOnMount: false,
      revalidateFirstPage: false,
    },
  })

  useEffect(() => {
    if (part || ((isOMSU || isOIV) && !organizationInfo)) return
    ;(async () => await mutate())()
  }, [organizationInfo])

  const { control, handleSubmit, getValues } = useForm<PartFormValues>({
    defaultValues: {
      mainNpaId: mainNpaId ?? '',
      part: part?.part ?? '',
      classificationId: part?.classificationId ?? '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const preparedNpaOptions = useMemo(() => {
    if (part) return [{ displayValue: actualNpaInfo?.name || '', value: mainNpaId || '' }]

    if (!npaList) return []

    return npaList.map((npa) => ({
      displayValue: npa.name,
      value: npa.id,
    }))
  }, [actualNpaInfo?.name, mainNpaId, npaList, part])

  const preparedOnCloseHandler = useCallback(() => {
    onClose?.()
  }, [onClose])

  const handleCreateNpaPart = useCallback(async () => {
    const formValues = getValues()

    enableLoading()

    const npaId = isString(formValues.mainNpaId) ? formValues.mainNpaId : formValues.mainNpaId.value

    try {
      await createNpaPart(npaId, {
        part: formValues.part,
        classificationId: formValues.classificationId,
      })

      onReloadGrid?.()

      preparedOnCloseHandler()
    } catch (error) {
      const additionInfo = {
        npaId: formValues.mainNpaId,
        part: formValues.part,
        classificationId: formValues.classificationId,
      }

      LoggerHelpersService.handleMultipleLogError({
        additionInfo,
        componentInfo: {
          componentName: 'PartNpaSidebar',
          componentType: 'createNPAPart',
        },
      })(error)

      throw error
    } finally {
      disableLoading()
    }
  }, [
    getValues,
    enableLoading,
    createNpaPart,
    onReloadGrid,
    preparedOnCloseHandler,
    disableLoading,
  ])

  const handleDecorateSubmit = useCallback(
    (callback, mode: keyof typeof npaPartActionType) => async (body?: TPutNpaPartBody) => {
      if (!part) return

      const formValues = getValues()

      enableLoading()

      try {
        const awaitedValue = await callback(
          {
            npaId: formValues.mainNpaId,
            partId: part.id,
            classificationId: formValues.classificationId,
          },
          body,
        )

        onClose?.()
        onReloadGrid?.()

        return awaitedValue
      } catch (error) {
        const additionInfo = {
          npaId: formValues.mainNpaId,
          partId: part.id,
          classificationId: formValues.classificationId,
          mode,
          body,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'PartNpaSidebar',
            componentType: mode,
          },
        })(error)

        throw error
      } finally {
        disableLoading()
      }
    },
    [disableLoading, enableLoading, getValues, onClose, onReloadGrid, part],
  )

  const handleExcludePart = useCallback(async () => {
    try {
      await handleDecorateSubmit(excludeNpaPart, npaPartActionType.excludeNpaPart)()
      onRemovePart?.()
    } catch (error) {
      throw error
    }
  }, [excludeNpaPart, handleDecorateSubmit, onRemovePart])

  const handleUpdatePart = useCallback(async () => {
    const formValues = getValues()

    try {
      const { id } = await handleDecorateSubmit(
        updateNpaPart,
        npaPartActionType.updateNpaPart,
      )({
        part: formValues.part,
        classificationId: formValues.classificationId,
      })

      onUpdatePart?.({
        id: id || part?.id || '',
        partId: id || part?.id || '',
        part: formValues.part,
        classificationId: formValues.classificationId,
      })
    } catch (error) {
      throw error
    }
  }, [getValues, handleDecorateSubmit, onUpdatePart, part?.id, updateNpaPart])

  const onContentScroll = async (e) => {
    const requestCondition = !error && !isNpaListLoading && hasMoreData

    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
        PAGINATION_BOTTOM_TRIGGER &&
      requestCondition
    ) {
      await incPage((size) => size + 1)
    }
  }

  const currentActions = useMemo(() => {
    if (!isMER && !isOIV && !isOMSU) return

    const loadingProps = {
      loaderProps: {
        loading: isRequestLoading,
        variant: 'lite',
        placement: 'trailing',
      },
    } as const

    const accessToEdit = isMER || OIVIsHaveAccessToEditOrExclude || OMSUIsHaveAccessToEditOrExclude

    return part
      ? accessToEdit
        ? ([
            ...[
              {
                children: 'Исключить',
                view: 'gray',
                color: 'negative',
                onClick: handleExcludePart,
                disabled: isRequestLoading || !npa?.enableActions,
              },
              {
                children: 'Сохранить',
                onClick: handleSubmit(handleUpdatePart),
                disabled: isRequestLoading || !npa?.enableActions,
                loaderProps: loadingProps.loaderProps,
              },
            ],
          ] as ButtonProps[])
        : undefined
      : ([
          {
            children: 'Отмена',
            view: 'gray',
            onClick: onClose,
          },
          {
            children: 'Добавить',
            onClick: handleSubmit(handleCreateNpaPart),
            disabled: isRequestLoading,
            loaderProps: loadingProps.loaderProps,
          },
        ] as ButtonProps[])
  }, [
    OIVIsHaveAccessToEditOrExclude,
    OMSUIsHaveAccessToEditOrExclude,
    handleCreateNpaPart,
    handleExcludePart,
    handleSubmit,
    handleUpdatePart,
    isMER,
    isOIV,
    isOMSU,
    isRequestLoading,
    npa?.enableActions,
    onClose,
    part,
  ])

  const inputsDisabledCondition = useMemo(() => {
    if (isMER || !part) return false

    if (isOMSU) return !OMSUIsHaveAccessToEditOrExclude

    return !OIVIsHaveAccessToEditOrExclude
  }, [OIVIsHaveAccessToEditOrExclude, OMSUIsHaveAccessToEditOrExclude, isMER, isOMSU, part])

  return (
    <Sidebar
      headerClassName={styles['partSidebar__sidebar-header']}
      contentClassName={styles['partSidebar__sidebar-content']}
      initialFocus={initialFocusRef}
      title={part ? 'Просмотр стаб. положения' : 'Создание стаб. положения'}
      isOpen={isOpen}
      actions={currentActions}
      onClose={preparedOnCloseHandler}
    >
      <div className={styles.partSidebar}>
        <div className={styles.partSidebar__control}>
          <ControlledSingleSelectNew
            withInput
            disabled={!!part}
            options={preparedNpaOptions}
            optionsProps={{
              isLoading: isNpaListLoading,
              notFoundText: `Не найдено ни одного НПА.
Возможно, они не добавлены в систему или вы указали некорректные данные при поиске.`,
            }}
            popoverProps={{
              zIndex: 51,
            }}
            optionsContainer={{
              onScroll: onContentScroll,
            }}
            controllerProps={{
              control,
              name: 'mainNpaId',
              rules: {
                required: defaultRHFValidation.required,
              },
            }}
            inputProps={{
              fixWidth: true,
              label: 'НПА',
            }}
            onChangeSearchValue={(value) => {
              setSearchValue(value)
            }}
          />
        </div>
        <div className={styles.partSidebar__control}>
          <ControlledInput
            control={control}
            name={'part'}
            rules={{
              required: defaultRHFValidation.required,
              validate: (value) => isString(value) && lengthValidate(value, 2000),
            }}
            inputProps={{
              ref: initialFocusRef,
              disabled: inputsDisabledCondition,
              fixWidth: true,
              label: 'Положение',
              view: 'secondary',
            }}
          />
        </div>
        <div className={styles.partSidebar__control}>
          <ControlledInputSearchTree
            name="classificationId"
            control={control}
            rules={{
              required: defaultRHFValidation.required,
            }}
            searchProps={{
              zIndexInputSearchTree: 51,
              inputProps: {
                disabled: inputsDisabledCondition,
                fixWidth: true,
                label: 'Класс стабилизируемого положения',
                tabIndex: 51,
                view: 'secondary',
                clear: true,
              },
              tooltipProps: {
                zIndex: 52,
              },
              options: partClassificationTreeAsOptions || [],
            }}
          />
        </div>
      </div>
    </Sidebar>
  )
}

export { usePartNpaSidebar }
export default memo(PartNpaSidebar)
