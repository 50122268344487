import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { isNotEmptyString } from '@helpers/checkTypes'
import { IPropertyFormError } from '@services/Properties/Properties.entity'
import cloneDeep from 'clone-deep'

interface FieldsControlWatcherNotifyValue {
  updateProps:
    | Record<string, unknown>
    | ((prevOverrideProps: Record<string, unknown>) => Record<string, unknown>)
  updateError: IPropertyFormError
  updateCachedProps: () => Record<string, unknown>
}

interface SubscriberValueProps {
  updatePropsCallback: (value: FieldsControlWatcherNotifyValue['updateProps']) => void
  updateErrorCallback: (error: string) => void
  applyCachedPropsCallback: (props: Record<string, unknown>) => void
}

type SubscriberFieldsNotifyProps = {
  id: string
  value: Partial<FieldsControlWatcherNotifyValue>
}

const { fromViolationsMessagesToSingleMessage } = DocumentFormHelpers

class FieldsControlUpdateWatcher {
  private subscribers: Record<string, SubscriberValueProps> = {}
  private subscribersPropsCache: Record<string, Record<string, unknown>> = {}
  private subscribersErrorCache: Record<string, string> = {}

  resetAllState() {
    this.subscribers = {}
    this.subscribersPropsCache = {}
    this.subscribersErrorCache = {}
  }

  subscribe(id: string, callbacks: SubscriberValueProps) {
    this.subscribers[id] = callbacks

    const propsCache = cloneDeep(this.subscribersPropsCache)

    const cachedProps = propsCache[id]
    const cachedError = this.subscribersErrorCache[id]

    if (cachedProps) {
      callbacks.applyCachedPropsCallback(cachedProps)
    }

    if (cachedError) {
      callbacks.updateErrorCallback(cachedError)

      delete this.subscribersErrorCache[id]
    }
  }

  unsubscribe(id: string, unsubscribeProps?: Record<string, unknown>) {
    if (!this.subscribers[id]) return
    delete this.subscribers[id]

    if (unsubscribeProps) {
      const { lastError, ...rest } = unsubscribeProps

      if (Object.keys(rest).length) {
        this.subscribersPropsCache[id] = cloneDeep(rest)
      }

      if (isNotEmptyString(lastError)) {
        this.subscribersErrorCache[id] = lastError
      } else {
        delete this.subscribersErrorCache[id]
      }
    }
  }

  notifySubscriber({ id, value }: SubscriberFieldsNotifyProps) {
    if (value?.updateCachedProps) {
      this.subscribersPropsCache[id] = cloneDeep(value?.updateCachedProps())
    }

    if (value?.updateError) {
      const preparedError = fromViolationsMessagesToSingleMessage(value?.updateError.violations)
      this.subscribersErrorCache[id] = cloneDeep(preparedError)
    }

    if (!this.subscribers[id]) return

    if (value?.updateProps) {
      this.subscribers[id].updatePropsCallback(value?.updateProps)
    }

    if (value?.updateError) {
      const preparedError = fromViolationsMessagesToSingleMessage(value?.updateError?.violations)

      this.subscribers[id].updateErrorCallback(preparedError)
    }
  }

  notifyAllSubscribers(data: SubscriberFieldsNotifyProps[]) {
    data.forEach((subscriberValue) => {
      this.notifySubscriber(subscriberValue)
    })
  }
}

export type { FieldsControlWatcherNotifyValue, SubscriberFieldsNotifyProps }
export { FieldsControlUpdateWatcher }
