import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'

interface CertificateOfImplementationInvestprojectStagesFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
}

const CertificateOfImplementationInvestprojectStagesForms: CertificateOfImplementationInvestprojectStagesFormsProps =
  () => null

CertificateOfImplementationInvestprojectStagesForms.First = First
CertificateOfImplementationInvestprojectStagesForms.Second = Second
CertificateOfImplementationInvestprojectStagesForms.Third = Third

export default CertificateOfImplementationInvestprojectStagesForms
