import { FC } from 'react'

import RowWithBorder from '@components/Forms/CreateStatementOld/FieldView/RowWithBorder'
import StepTemplate from '@components/Forms/CreateStatementOld/FieldView/StepTemplate'

interface FieldViewProps extends FC {
  RowWithBottomBorder: typeof RowWithBorder
  StepTemplate: typeof StepTemplate
}

const FieldView: FieldViewProps = () => null

FieldView.RowWithBottomBorder = RowWithBorder
FieldView.StepTemplate = StepTemplate

export default FieldView
