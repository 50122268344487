import React, { FC, ReactElement, ReactNode } from 'react'
import { PopupProps } from 'react-popup-manager'

import IconButton from '@components/NewDesign/IconButton'
import Modal from '@components/NewDesign/Modal'
import { ModalBaseComponentProps } from '@components/NewDesign/Modal/Base'
import Typography from '@components/NewDesign/Typography'
import ModalCloseIcon from '@icons/ModalCloseIcon.svg'
import cn from 'classnames'

import styles from './SimpleModal.module.scss'

export type SimpleModalComponentProps = ModalBaseComponentProps & {
  title: ReactNode
  hideCloseIcon?: boolean
  simpleModalContainerClassName?: string
  simpleModalBodyClassName?: string
  simpleModalHeaderClassName?: string
  simpleModalTitleClassName?: string
  leadHeaderIcon?: ReactElement
  closeButtonDataTestId?: string
}

type SimpleModalProps = SimpleModalComponentProps & PopupProps

const SimpleModal: FC<SimpleModalProps> = ({
  title,
  hideCloseIcon,
  leadHeaderIcon,
  children,
  simpleModalHeaderClassName,
  simpleModalTitleClassName,
  closeButtonDataTestId,
  ...dialogRest
}) => {
  return (
    <Modal.Base {...dialogRest}>
      <div className={cn(styles.simpleModal, dialogRest.simpleModalContainerClassName)}>
        {Boolean(leadHeaderIcon || !hideCloseIcon || title) && (
          <header className={cn(styles.simpleModal__header, simpleModalHeaderClassName)}>
            {leadHeaderIcon}
            <Typography.Headline
              className={cn(styles['simpleModal__header-title'], simpleModalTitleClassName)}
              variant={'headlineH3'}
              as={'h3'}
            >
              {title}
            </Typography.Headline>
            {!hideCloseIcon && (
              <IconButton
                className={styles.simpleModal__closeButtonIcon}
                geometry={'square'}
                view={'basic'}
                size={'m'}
                dataTestId={closeButtonDataTestId}
                icon={{
                  src: ModalCloseIcon,
                  noCurrentColorSvgFill: true,
                  size: 'initial',
                  className: styles.simpleModal__icon,
                }}
                onClick={dialogRest.onClose}
              />
            )}
          </header>
        )}
        <div className={cn(styles.simpleModal__body, dialogRest.simpleModalBodyClassName)}>
          {children}
        </div>
      </div>
    </Modal.Base>
  )
}

export default SimpleModal
