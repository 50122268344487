import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { supplementalCostSharingAContractDutyMembersValidationMap } from '@components/Forms/SupplementalCostSharingAForm/Forms/5/AdditionalForms/ContractDutyMember/validation'
import { SupplementalCostSharingAContractDutyMemberArrayPathName } from '@components/Forms/SupplementalCostSharingAForm/Forms/5/types'
import { useSupplementalCostSharingAManager } from '@components/Forms/SupplementalCostSharingAForm/Manager'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import { SupplementalCostSharingAFieldsControlUpdateWatcher } from '@components/Forms/SupplementalCostSharingAForm/watcher'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface SupplementalCostSharingAContractDutyMemberProps {
  id: string
  formName: SupplementalCostSharingAContractDutyMemberArrayPathName
  onContractDutyMemberRemove: VoidFunction
}

const SupplementalCostSharingAContractDutyMember: FC<
  SupplementalCostSharingAContractDutyMemberProps
> = ({ id, formName, onContractDutyMemberRemove }) => {
  const formInstance = useFormContext<SupplementalCostSharingAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSupplementalCostSharingAManager()

  const { getSubscribableControlProps, getMaskInputProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: SupplementalCostSharingAFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate
      id={id}
      onRemoveStep={editMode ? onContractDutyMemberRemove : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.contractDutyMemberName`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.contractDutyMemberName`,
                  rules:
                    supplementalCostSharingAContractDutyMembersValidationMap.contractDutyMemberName,
                  textareaProps: {
                    label: 'Наименование',
                    caption: 'в творительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.contractDutyMemberName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.contractDutyMemberName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.contractDutyMemberOgrn`,
              })}
            >
              <ControlledMaskInput
                {...getMaskInputProps({
                  name: `${formName}.contractDutyMemberOgrn`,
                  inputProps: {
                    autoUnmask: true,
                    mask: '9999999999999',
                    label: 'ОГРН',
                  },
                  rules:
                    supplementalCostSharingAContractDutyMembersValidationMap.contractDutyMemberOgrn,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.contractDutyMemberOgrn`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.contractDutyMemberOgrn`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.contractDutyMemberInn`,
              })}
            >
              <ControlledMaskInput
                {...getMaskInputProps({
                  name: `${formName}.contractDutyMemberInn`,
                  inputProps: {
                    autoUnmask: true,
                    mask: '9999999999',
                    label: 'ИНН',
                  },
                  rules:
                    supplementalCostSharingAContractDutyMembersValidationMap.contractDutyMemberInn,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.contractDutyMemberInn`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.contractDutyMemberInn`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.contractDutyMemberAddress`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.contractDutyMemberAddress`,
                  rules:
                    supplementalCostSharingAContractDutyMembersValidationMap.contractDutyMemberAddress,
                  textareaProps: {
                    label: 'Адрес',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.contractDutyMemberAddress`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.contractDutyMemberAddress`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default SupplementalCostSharingAContractDutyMember
