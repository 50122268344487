import React from 'react'
import { useFormContext } from 'react-hook-form'

import MunicipalitiesSection from '@components/DocumentFormComponents/FormSections/MunicipalitiesSection'
import { aAgreementOnChangingCharacteristicsOfObjectsBlockValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import { AAgreementOnChangingCharacteristicsOfObjectsFormValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'

const Third = () => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  if (!formInstance) return null

  return (
    <MunicipalitiesSection
      formName={aAgreementOnChangingCharacteristicsOfObjectsBlockValues['3']['municipalities']}
      formInstance={formInstance}
      blockViewIsValidating={blockViewIsValidating}
      editMode={editMode}
      watcher={AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher}
      fieldArrayWatcher={AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Third
