import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/AdditionalFormItem.module.scss'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import RRegistrationFormItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RRegistrationForm/RRegistrationFormItem'
import { SeventhRidRegistrationObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface RRegistrationItemProps {
  id: string
  anchorId: string
  formName: SeventhRidRegistrationObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  indexOfRegistration: number
  onDeleteRegistration: VoidFunction
}

const RRegistrationItem: FC<RRegistrationItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
  indexOfRegistration,
  onDeleteRegistration,
}) => {
  return (
    <ObjectStepWrapper
      disableBorder
      stepId={id}
      id={anchorId}
      headerNode={
        <Typography.Body variant={'bodyMSemibold'}>
          Регистрация №{indexOfRegistration + 1}
        </Typography.Body>
      }
      onRemoveObjectStep={editMode ? onDeleteRegistration : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <RRegistrationFormItem
            formName={formName}
            blockViewIsValidating={blockViewIsValidating}
            formInstance={formInstance}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Border className={styles.additionalFormItem__border} />
          </Col>
        </Row>
      </Stack>
    </ObjectStepWrapper>
  )
}

export default RRegistrationItem
