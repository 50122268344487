import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import CertificateOnMonitoringResultsExpenseChecker from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/8/ExpenseChecker'
import { CertificateOnMonitoringResultsExpensesCheckerPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/8/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Eighth = () => {
  const formInstance = useFormContext<CertificateOnMonitoringResultsFormValues>()

  const {
    state: { editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { fields: expensesChecker } = useFieldArraySubscribableControl<
    CertificateOnMonitoringResultsFormValues,
    CertificateOnMonitoringResultsExpensesCheckerPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: certificateOnMonitoringResultsBlockValues['8'].expensesChecker,
    keyName: 'keyNameId',
    watcher: CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleAddExpenseChecker = async (e) => {
    e.preventDefault()

    await handleAddItemToListWithOutValue?.(
      certificateOnMonitoringResultsBlockValues['8'].expensesChecker,
    )
  }

  const handleRemoveExpenseChecker = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${certificateOnMonitoringResultsBlockValues['8'].expensesChecker}.${index}`,
      certificateOnMonitoringResultsBlockValues['8'].expensesChecker,
    )
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: certificateOnMonitoringResultsBlockValues['8'].expensesChecker,
          })}
        >
          <FlipperList list={expensesChecker}>
            {expensesChecker.map((expense, index) => {
              const formName =
                `${certificateOnMonitoringResultsBlockValues['8'].expensesChecker}.${index}` as const

              return (
                <CertificateOnMonitoringResultsExpenseChecker
                  key={expense.id}
                  id={expense.id}
                  formInstance={formInstance}
                  title={`Направление расхода №${index + 1}`}
                  name={formName}
                  onRemove={handleRemoveExpenseChecker(index)}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddExpenseChecker}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Eighth
