import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  ICompensationDismantlingObject,
  ICompensationExpenseObject,
  ICompensationMonitoring,
  ICompensationPercentCreditObject,
  ICompensationRelatedObject,
  ICompensationSupportingObject,
  InformationOfExecutionConditionsSZPKFormValues,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const compensationMonitoringValidationMap: FormValuesValidationSection<
  keyof ICompensationMonitoring,
  InformationOfExecutionConditionsSZPKFormValues
> = {
  compensationSubsidyFormat: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  compensationTaxDeductionFormat: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  compensationDamageFormat: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

const relatedCompensationsValidationMap: FormValuesValidationSection<
  keyof ICompensationRelatedObject,
  InformationOfExecutionConditionsSZPKFormValues
> = {
  relatedInfrastructureObjectValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

const supportingCompensationsValidationMap: FormValuesValidationSection<
  keyof ICompensationSupportingObject,
  InformationOfExecutionConditionsSZPKFormValues
> = {
  supportingInfrastructureObjectValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

const creditCompensationsValidationMap: FormValuesValidationSection<
  keyof ICompensationPercentCreditObject,
  InformationOfExecutionConditionsSZPKFormValues
> = {
  compensationRelatedContractValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

const dismantlingCompensationsValidationMap: FormValuesValidationSection<
  keyof ICompensationDismantlingObject,
  InformationOfExecutionConditionsSZPKFormValues
> = {
  dismantlingObjectValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

const expenseCompensationsValidationMap: FormValuesValidationSection<
  keyof ICompensationExpenseObject,
  InformationOfExecutionConditionsSZPKFormValues
> = {
  expenseValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export {
  compensationMonitoringValidationMap,
  creditCompensationsValidationMap,
  dismantlingCompensationsValidationMap,
  expenseCompensationsValidationMap,
  relatedCompensationsValidationMap,
  supportingCompensationsValidationMap,
}
