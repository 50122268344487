import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAChangingVolumeReimbursementFormValues,
  IStep,
} from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import { standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const stepValidationMap: FormValuesValidationSection<
  keyof IStep,
  AAChangingVolumeReimbursementFormValues
> = {
  stepCompensationValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { stepValidationMap }
