import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  petitionRecognizePreviouslyConcludedContractAsBoundBlockValues,
  RelatedContractMemberInstrumentalsPathName,
} from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/const'
import { usePetitionRecognizePreviouslyConcludedContractAsBoundManager } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/Manager'
import { PetitionRecognizePreviouslyConcludedContractAsBoundFormValues } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/types'
import {
  PetitionRecognizePreviouslyConcludedContractAsBoundFieldsControlUpdateWatcher,
  PetitionTransferRightFieldArrayControlUpdateWatcher,
} from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/watcher'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { disableFutureDates } from '@helpers/date/disableOfDates'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

import { twoSectionValidationMap } from './validation'

const currentBlockValues = petitionRecognizePreviouslyConcludedContractAsBoundBlockValues['2']

const { isFormFieldError } = DocumentFormHelpers

const Second = () => {
  const formInstance =
    useFormContext<PetitionRecognizePreviouslyConcludedContractAsBoundFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
    },
    preparedProps: { subscribableControl },
  } = usePetitionRecognizePreviouslyConcludedContractAsBoundManager()

  const {
    getSubscribableControlProps,
    getCalendarInputProps,
    getInputProps,
    getTextareaProps,
    getControllerProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: PetitionRecognizePreviouslyConcludedContractAsBoundFieldsControlUpdateWatcher,
  })

  const { fields: relatedContractMemberInstrumentals } = useFieldArraySubscribableControl<
    PetitionRecognizePreviouslyConcludedContractAsBoundFormValues,
    RelatedContractMemberInstrumentalsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: currentBlockValues.relatedContractMemberInstrumentals,
    keyName: 'keyNameId',
    watcher: PetitionTransferRightFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleAddRelatedContractMemberInstrumental = async () => {
    await handleAddItemToListWithOutValue?.(currentBlockValues.relatedContractMemberInstrumentals)
  }

  const handleRemoveRelatedContractMemberInstrumental = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${currentBlockValues.relatedContractMemberInstrumentals}.${index}`,
      currentBlockValues.relatedContractMemberInstrumentals,
    )
  }

  const relatedContractMemberInstrumentalsError = formInstance.getFieldState(
    currentBlockValues.relatedContractMemberInstrumentals,
  )?.error

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.relatedContractNumber,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: currentBlockValues.relatedContractNumber,
                  rules: twoSectionValidationMap.relatedContractNumber,
                  inputProps: {
                    label: 'Номер договора',
                  },
                  onBlur: () =>
                    setTimeout(
                      () => handleChangeValue?.(currentBlockValues.relatedContractNumber),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(currentBlockValues.relatedContractNumber)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.relatedContractDate,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: currentBlockValues.relatedContractDate,
                  rules: twoSectionValidationMap.relatedContractDate,
                  calendarProps: {
                    disabledDate: (date) => disableFutureDates(date),
                  },
                  calendarInputProps: {
                    label: 'Дата заключения',
                  },
                  onBlur: () =>
                    setTimeout(
                      () => handleChangeValue?.(currentBlockValues.relatedContractDate),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(currentBlockValues.relatedContractDate)
                    }, 0),
                  onCalendarChange: () =>
                    setTimeout(
                      () => handleChangeValue?.(currentBlockValues.relatedContractDate),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.relatedContractKind,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: currentBlockValues.relatedContractKind,
                  rules: twoSectionValidationMap.relatedContractKind,
                  textareaProps: {
                    label: 'Вид договора',
                  },
                  onBlur: () =>
                    setTimeout(
                      () => handleChangeValue?.(currentBlockValues.relatedContractKind),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(currentBlockValues.relatedContractKind)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group title="Стороны договора">
          <Row>
            <Col xs={12}>
              {!editMode && !relatedContractMemberInstrumentals.length && <EmptyDataText />}
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: currentBlockValues.relatedContractMemberInstrumentals,
                })}
              >
                <FlipperList list={relatedContractMemberInstrumentals}>
                  {isFormFieldError(relatedContractMemberInstrumentalsError) &&
                    !relatedContractMemberInstrumentals.length && (
                      <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
                    )}
                  <Stack direction={'vertical'} gap={3}>
                    {relatedContractMemberInstrumentals.map(
                      (relatedContractMemberInstrumental, index) => {
                        const formName =
                          `${currentBlockValues.relatedContractMemberInstrumentals}.${index}.item` as const

                        return (
                          <FieldView.RowWithRemoveButton
                            key={relatedContractMemberInstrumental.id}
                            id={relatedContractMemberInstrumental.id}
                            onRemove={
                              editMode
                                ? handleRemoveRelatedContractMemberInstrumental(index)
                                : undefined
                            }
                          >
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: formName,
                              })}
                            >
                              <ControlledTextarea
                                {...getTextareaProps({
                                  name: formName,
                                  rules: {
                                    required: defaultRHFValidation.required,
                                    validate: (value) =>
                                      isString(value) && lengthValidate(value, 1000),
                                  },
                                  textareaProps: {
                                    label: 'Сторона договора',
                                    caption: 'в творительном падеже',
                                    leftAddons: (
                                      <FormIconWithTooltip
                                        tooltipContent={
                                          'Если ваша организация является стороной договора, то необходимо указать её в этом поле'
                                        }
                                      />
                                    ),
                                  },
                                  onBlur: () =>
                                    setTimeout(() => {
                                      handleChangeValue?.(formName)
                                    }, 0),
                                  onChange: () =>
                                    setTimeout(() => {
                                      debouncedHandleChangeValue?.(formName)
                                    }, 0),
                                })}
                              />
                            </SubscribableControl>
                          </FieldView.RowWithRemoveButton>
                        )
                      },
                    )}
                  </Stack>
                </FlipperList>
              </SubscribableControl>
            </Col>
          </Row>
          {editMode && (
            <Row className={'px-1 py-1'}>
              <Col xs={12}>
                <AsyncWrapper promise={handleAddRelatedContractMemberInstrumental}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <Button
                        disabled={isLoading}
                        leadingIcon={{ src: CircleAddIcon }}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        view={'plain'}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Добавить сторону
                      </Button>
                    )
                  }}
                </AsyncWrapper>
              </Col>
            </Row>
          )}
        </Group>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование инвестиционного проекта'}
                controllerProps={getControllerProps({
                  name: currentBlockValues.projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
