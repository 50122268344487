import { useCallback } from 'react'

import { generatePropertiesObjectForAAChangingVolumeReimbursementForm } from '@components/Forms/AAChangingVolumeReimbursementForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAAChangingVolumeReimbursementForm } from '@components/Forms/AAChangingVolumeReimbursementForm/adapters/RHF.adapter'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'

const useAAChangingVolumeReimbursementAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): AAChangingVolumeReimbursementFormValues =>
      generateRHFObjectForAAChangingVolumeReimbursementForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback((
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForAAChangingVolumeReimbursementForm(objectFromPropertyState), [])

  return { generateRHFObject, generatePropertiesObject }
}

export { useAAChangingVolumeReimbursementAdapters }
