import { useCallback } from 'react'

import { generatePropertiesObjectForSummaryReportOfMonitoringResultsForm } from '@components/Forms/SummaryReportOfMonitoringResultsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForSummaryReportOfMonitoringResultsForm } from '@components/Forms/SummaryReportOfMonitoringResultsForm/adapters/RHF.adapter'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'

const useSummaryReportOfMonitoringResultsAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): SummaryReportOfMonitoringResultsFormValues =>
      generateRHFObjectForSummaryReportOfMonitoringResultsForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForSummaryReportOfMonitoringResultsForm(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generatePropertiesObject }
}

export { useSummaryReportOfMonitoringResultsAdapters }
