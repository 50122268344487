import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const secondSectionCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsFormValues['2'],
  CertificateOnMonitoringResultsFormValues
> = {
  initiatorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { secondSectionCertificateOnMonitoringResultsValidationMap }
