import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { summaryReportOfMonitoringResultsBlockValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/const'
import SummaryReportOfMonitoringResultsRegionReportInfoFederalInvestorCapitalItem from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/FederalInvestorCapitalItem'
import SummaryReportOfMonitoringResultsRegionReportInfoFederalNewWorkPlacesItem from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/FederalNewWorkPlacesItem'
import SummaryReportOfMonitoringResultsRegionReportInfoFederalTotalCapitalItem from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/FederalTotalCapitalItem'
import SummaryReportOfMonitoringResultsRegionReportInfoRegionalCompensationValueItem from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/RegionalCompensationValueItem'
import SummaryReportOfMonitoringResultsRegionReportInfoRegionalInvestorCapitalItem from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/RegionalInvestorCapitalItem'
import SummaryReportOfMonitoringResultsRegionReportInfoRegionalNewWorkPlacesItem from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/RegionalNewWorkPlacesItem'
import SummaryReportOfMonitoringResultsRegionReportInfoRegionalTotalCapitalItem from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/RegionalTotalCapitalItem'
import { SummaryReportOfMonitoringResultsRegionReportInfoPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/types'
import { thirdSectionValidationMap } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/validation'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import SummaryReportOfMonitoringResultsRegionReportInfoCollapse from './RegionReportInfoCollapse'

const Third = () => {
  const formInstance = useFormContext<SummaryReportOfMonitoringResultsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const { fields: regionsReportInfo } = useFieldArraySubscribableControl<
    SummaryReportOfMonitoringResultsFormValues,
    SummaryReportOfMonitoringResultsRegionReportInfoPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
    keyName: 'keyNameId',
    watcher: SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const signedRegionReportInfoSignSubtitle = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3'].signedRegionReportInfoSignSubtitle,
    control: formInstance?.control,
  })

  const federalTotalCapitalFederalReportPlanRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .federalTotalCapitalFederalReportPlanRegionDeviation,
    control: formInstance?.control,
  })

  const federalTotalCapitalFederalReportFactRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .federalTotalCapitalFederalReportFactRegionDeviation,
    control: formInstance?.control,
  })

  const federalInvestorCapitalFederalReportFactRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .federalInvestorCapitalFederalReportFactRegionDeviation,
    control: formInstance?.control,
  })

  const federalInvestorCapitalFederalReportPlanRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .federalInvestorCapitalFederalReportPlanRegionDeviation,
    control: formInstance?.control,
  })

  const federalNewWorkPlacesFederalReportFactRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .federalNewWorkPlacesFederalReportFactRegionDeviation,
    control: formInstance?.control,
  })

  const federalNewWorkPlacesFederalReportPlanRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .federalNewWorkPlacesFederalReportPlanRegionDeviation,
    control: formInstance?.control,
  })

  const federalCompensationValueFederalReportFactRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .federalCompensationValueFederalReportFactRegionDeviation,
    control: formInstance?.control,
  })

  const federalCompensationValueFederalReportPlanRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .federalCompensationValueFederalReportPlanRegionDeviation,
    control: formInstance?.control,
  })

  const regionalCompensationValueFederalReportFactRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .regionalCompensationValueFederalReportFactRegionDeviation,
    control: formInstance?.control,
  })

  const regionalCompensationValueFederalReportPlanRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .regionalCompensationValueFederalReportPlanRegionDeviation,
    control: formInstance?.control,
  })

  const regionalInvestorCapitalFederalReportFactRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .regionalInvestorCapitalFederalReportFactRegionDeviation,
    control: formInstance?.control,
  })

  const regionalInvestorCapitalFederalReportPlanRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .regionalInvestorCapitalFederalReportPlanRegionDeviation,
    control: formInstance?.control,
  })

  const regionalTotalCapitalFederalReportFactRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .regionalTotalCapitalFederalReportFactRegionDeviation,
    control: formInstance?.control,
  })

  const regionalTotalCapitalFederalReportPlanRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .regionalTotalCapitalFederalReportPlanRegionDeviation,
    control: formInstance?.control,
  })

  const regionalNewWorkPlacesFederalReportFactRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .regionalNewWorkPlacesFederalReportFactRegionDeviation,
    control: formInstance?.control,
  })

  const regionalNewWorkPlacesFederalReportPlanRegionDeviation = useWatch({
    name: summaryReportOfMonitoringResultsBlockValues['3']
      .regionalNewWorkPlacesFederalReportPlanRegionDeviation,
    control: formInstance?.control,
  })

  const { getAmountInputProps, getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Typography.Body className={styles.form__signedSubtitle} variant={'bodyMMedium'}>
          {signedRegionReportInfoSignSubtitle}
        </Typography.Body>

        <Group disableBottomBorder title={'Общее количество СЗПК'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .szpkCountFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .szpkCountFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .szpkCountFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .szpkCountFederalReportFact,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group title={'СЗПК, стороной которых является Российская Федерация'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalSzpkCountFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .federalSzpkCountFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalSzpkCountFederalReportPlanCorrect,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .federalSzpkCountFederalReportPlanCorrect,
                      rules: thirdSectionValidationMap.federalSzpkCountFederalReportPlanCorrect,
                      inputProps: {
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={'Данное значение будет перенесено в документ'}
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .federalSzpkCountFederalReportPlanCorrect,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .federalSzpkCountFederalReportPlanCorrect,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalSzpkCountFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .federalSzpkCountFederalReportFact,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalSzpkCountFederalReportFactCorrect,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .federalSzpkCountFederalReportFactCorrect,
                      rules: thirdSectionValidationMap.federalSzpkCountFederalReportFactCorrect,
                      inputProps: {
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={'Данное значение будет перенесено в документ'}
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .federalSzpkCountFederalReportFactCorrect,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .federalSzpkCountFederalReportFactCorrect,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoCollapse
                      key={item.id}
                      indexOfReport={index}
                      title={`${item.regionCode} ${item.regionName}`}
                    >
                      <Stack direction={'vertical'} gap={3}>
                        <Row>
                          <Col xs={6}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.federalSzpkCountPlan`,
                              })}
                            >
                              <ControlledAmountInput
                                {...getAmountInputProps({
                                  name: `${formName}.federalSzpkCountPlan`,
                                  inputProps: {
                                    disabled: true,
                                    integersOnly: true,
                                    label: 'Плановое количество СЗПК',
                                    suffix: '',
                                  },
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                          <Col xs={6}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.federalSzpkCountFact`,
                              })}
                            >
                              <ControlledAmountInput
                                {...getAmountInputProps({
                                  name: `${formName}.federalSzpkCountFact`,
                                  inputProps: {
                                    disabled: true,
                                    integersOnly: true,
                                    label: 'Фактическое количество СЗПК',
                                    suffix: '',
                                  },
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                        </Row>
                      </Stack>
                    </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group title={'СЗПК, стороной которых не является Российская Федерация'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalSzpkCountFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .regionalSzpkCountFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalSzpkCountFederalReportPlanCorrect,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .regionalSzpkCountFederalReportPlanCorrect,
                      rules: thirdSectionValidationMap.regionalSzpkCountFederalReportPlanCorrect,
                      inputProps: {
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={'Данное значение будет перенесено в документ'}
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .regionalSzpkCountFederalReportPlanCorrect,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .regionalSzpkCountFederalReportPlanCorrect,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalSzpkCountFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .regionalSzpkCountFederalReportFact,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalSzpkCountFederalReportFactCorrect,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .regionalSzpkCountFederalReportFactCorrect,
                      rules: thirdSectionValidationMap.regionalSzpkCountFederalReportFactCorrect,
                      inputProps: {
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={'Данное значение будет перенесено в документ'}
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .regionalSzpkCountFederalReportFactCorrect,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .regionalSzpkCountFederalReportFactCorrect,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoCollapse
                      indexOfReport={index}
                      title={`${item.regionCode} ${item.regionName}`}
                    >
                      <Stack direction={'vertical'} gap={3}>
                        <Row>
                          <Col xs={6}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.regionalSzpkCountPlan`,
                              })}
                            >
                              <ControlledAmountInput
                                {...getAmountInputProps({
                                  name: `${formName}.regionalSzpkCountPlan`,
                                  inputProps: {
                                    disabled: true,
                                    integersOnly: true,
                                    label: 'Плановое количество СЗПК',
                                    suffix: '',
                                  },
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                          <Col xs={6}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.regionalSzpkCountFact`,
                              })}
                            >
                              <ControlledAmountInput
                                {...getAmountInputProps({
                                  name: `${formName}.regionalSzpkCountFact`,
                                  inputProps: {
                                    disabled: true,
                                    integersOnly: true,
                                    label: 'Фактическое количество СЗПК',
                                    suffix: '',
                                  },
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                        </Row>
                      </Stack>
                    </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group disableBottomBorder title="Общее количество СЗПК за отчетный период">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .szpkPeriodCountFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .szpkPeriodCountFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .szpkPeriodCountFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .szpkPeriodCountFederalReportFact,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group title={'СЗПК, стороной которых является Российская Федерация'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalSzpkPeriodCountFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .federalSzpkPeriodCountFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalSzpkPeriodCountFederalReportPlanCorrect,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .federalSzpkPeriodCountFederalReportPlanCorrect,
                      rules:
                        thirdSectionValidationMap.federalSzpkPeriodCountFederalReportPlanCorrect,
                      inputProps: {
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={'Данное значение будет перенесено в документ'}
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .federalSzpkPeriodCountFederalReportPlanCorrect,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .federalSzpkPeriodCountFederalReportPlanCorrect,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalSzpkPeriodCountFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .federalSzpkPeriodCountFederalReportFact,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalSzpkPeriodCountFederalReportFactCorrect,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .federalSzpkPeriodCountFederalReportFactCorrect,
                      rules:
                        thirdSectionValidationMap.federalSzpkPeriodCountFederalReportFactCorrect,
                      inputProps: {
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={'Данное значение будет перенесено в документ'}
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .federalSzpkPeriodCountFederalReportFactCorrect,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .federalSzpkPeriodCountFederalReportFactCorrect,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoCollapse
                      title={`${item.regionCode} ${item.regionName}`}
                      indexOfReport={index}
                    >
                      <Stack direction={'vertical'} gap={3}>
                        <Row>
                          <Col xs={6}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.federalSzpkPeriodCountPlan`,
                              })}
                            >
                              <ControlledAmountInput
                                {...getAmountInputProps({
                                  name: `${formName}.federalSzpkPeriodCountPlan`,
                                  inputProps: {
                                    disabled: true,
                                    integersOnly: true,
                                    label: 'Плановое количество СЗПК',
                                    suffix: '',
                                  },
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                          <Col xs={6}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.federalSzpkPeriodCountFact`,
                              })}
                            >
                              <ControlledAmountInput
                                {...getAmountInputProps({
                                  name: `${formName}.federalSzpkPeriodCountFact`,
                                  inputProps: {
                                    disabled: true,
                                    integersOnly: true,
                                    label: 'Фактическое количество СЗПК',
                                    suffix: '',
                                  },
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                        </Row>
                      </Stack>
                    </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group title={'СЗПК, стороной которых не является Российская Федерация'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalSzpkPeriodCountFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .regionalSzpkPeriodCountFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalSzpkPeriodCountFederalReportPlanCorrect,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .regionalSzpkPeriodCountFederalReportPlanCorrect,
                      rules:
                        thirdSectionValidationMap.regionalSzpkPeriodCountFederalReportPlanCorrect,
                      inputProps: {
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={'Данное значение будет перенесено в документ'}
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .regionalSzpkPeriodCountFederalReportPlanCorrect,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .regionalSzpkPeriodCountFederalReportPlanCorrect,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalSzpkPeriodCountFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .regionalSzpkPeriodCountFederalReportFact,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalSzpkPeriodCountFederalReportFactCorrect,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .regionalSzpkPeriodCountFederalReportFactCorrect,
                      rules:
                        thirdSectionValidationMap.regionalSzpkPeriodCountFederalReportFactCorrect,
                      inputProps: {
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={'Данное значение будет перенесено в документ'}
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .regionalSzpkPeriodCountFederalReportFactCorrect,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            summaryReportOfMonitoringResultsBlockValues['3']
                              .regionalSzpkPeriodCountFederalReportFactCorrect,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoCollapse
                      title={`${item.regionCode} ${item.regionName}`}
                      indexOfReport={index}
                    >
                      <Stack direction={'vertical'} gap={3}>
                        <Row>
                          <Col xs={6}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.federalSzpkPeriodCountPlan`,
                              })}
                            >
                              <ControlledAmountInput
                                {...getAmountInputProps({
                                  name: `${formName}.federalSzpkPeriodCountPlan`,
                                  inputProps: {
                                    disabled: true,
                                    integersOnly: true,
                                    label: 'Плановое количество СЗПК',
                                    suffix: '',
                                  },
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                          <Col xs={6}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.federalSzpkPeriodCountFact`,
                              })}
                            >
                              <ControlledAmountInput
                                {...getAmountInputProps({
                                  name: `${formName}.federalSzpkPeriodCountFact`,
                                  inputProps: {
                                    disabled: true,
                                    integersOnly: true,
                                    label: 'Фактическое количество СЗПК',
                                    suffix: '',
                                  },
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                        </Row>
                      </Stack>
                    </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group disableBottomBorder title="Объем осуществленных капитальных вложений">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .totalCapitalFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .totalCapitalFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        label: 'Плановое значение',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .totalCapitalFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .totalCapitalFederalReportFact,
                      inputProps: {
                        disabled: true,
                        label: 'Фактическое значение',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group
          title={
            'Объем осуществленных капитальных вложений по СЗПК, стороной которых является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalTotalCapitalFederalReportPlanChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalTotalCapitalFederalReportPlanChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalTotalCapitalFederalReportPlanRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalTotalCapitalFederalReportPlanRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={federalTotalCapitalFederalReportPlanRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalTotalCapitalFederalReportPlanCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalTotalCapitalFederalReportPlanCorrect,
                    rules: thirdSectionValidationMap.federalTotalCapitalFederalReportPlanCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalTotalCapitalFederalReportPlanCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalTotalCapitalFederalReportPlanCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalTotalCapitalFederalReportFactChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalTotalCapitalFederalReportFactChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalTotalCapitalFederalReportFactRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalTotalCapitalFederalReportFactRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={federalTotalCapitalFederalReportFactRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalTotalCapitalFederalReportFactCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalTotalCapitalFederalReportFactCorrect,
                    rules: thirdSectionValidationMap.federalTotalCapitalFederalReportFactCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalTotalCapitalFederalReportFactCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalTotalCapitalFederalReportFactCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoFederalTotalCapitalItem
                      key={item.id}
                      title={`${item.regionCode} ${item.regionName}`}
                      editMode={editMode}
                      blockViewIsValidating={blockViewIsValidating}
                      formName={formName}
                      formInstance={formInstance}
                      index={index}
                      subscribableControl={subscribableControl}
                    />
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group
          title={
            'Объем осуществленных капитальных вложений по СЗПК, стороной которых не является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalTotalCapitalFederalReportPlanChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalTotalCapitalFederalReportPlanChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalTotalCapitalFederalReportPlanRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalTotalCapitalFederalReportPlanRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={regionalTotalCapitalFederalReportPlanRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalTotalCapitalFederalReportPlanCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalTotalCapitalFederalReportPlanCorrect,
                    rules: thirdSectionValidationMap.regionalTotalCapitalFederalReportPlanCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalTotalCapitalFederalReportPlanCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalTotalCapitalFederalReportPlanCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalTotalCapitalFederalReportFactChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalTotalCapitalFederalReportFactChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalTotalCapitalFederalReportFactRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalTotalCapitalFederalReportFactRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={regionalTotalCapitalFederalReportFactRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalTotalCapitalFederalReportFactCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalTotalCapitalFederalReportFactCorrect,
                    rules: thirdSectionValidationMap.regionalTotalCapitalFederalReportFactCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalTotalCapitalFederalReportFactCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalTotalCapitalFederalReportFactCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoRegionalTotalCapitalItem
                      key={item.id}
                      title={`${item.regionCode} ${item.regionName}`}
                      editMode={editMode}
                      blockViewIsValidating={blockViewIsValidating}
                      index={index}
                      formName={formName}
                      subscribableControl={subscribableControl}
                      formInstance={formInstance}
                    />
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group disableBottomBorder title="Объем осуществленных капиталовложений">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .investorCapitalFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .investorCapitalFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        label: 'Плановое значение',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .investorCapitalFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .investorCapitalFederalReportFact,
                      inputProps: {
                        disabled: true,
                        label: 'Фактическое значение',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group
          title={
            'Объем осуществленных капиталовложений по СЗПК, стороной которых является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalInvestorCapitalFederalReportPlanChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalInvestorCapitalFederalReportPlanChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalInvestorCapitalFederalReportPlanRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalInvestorCapitalFederalReportPlanRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={federalInvestorCapitalFederalReportPlanRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalInvestorCapitalFederalReportPlanCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalInvestorCapitalFederalReportPlanCorrect,
                    rules: thirdSectionValidationMap.federalInvestorCapitalFederalReportPlanCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalInvestorCapitalFederalReportPlanCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalInvestorCapitalFederalReportPlanCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalInvestorCapitalFederalReportFactChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalInvestorCapitalFederalReportFactChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalInvestorCapitalFederalReportFactRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalInvestorCapitalFederalReportFactRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={federalInvestorCapitalFederalReportFactRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalInvestorCapitalFederalReportFactCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalInvestorCapitalFederalReportFactCorrect,
                    rules: thirdSectionValidationMap.federalInvestorCapitalFederalReportFactCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalInvestorCapitalFederalReportFactCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalInvestorCapitalFederalReportFactCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoFederalInvestorCapitalItem
                      key={item.id}
                      title={`${item.regionCode} ${item.regionName}`}
                      editMode={editMode}
                      blockViewIsValidating={blockViewIsValidating}
                      formName={formName}
                      index={index}
                      formInstance={formInstance}
                      subscribableControl={subscribableControl}
                    />
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group
          title={
            'Объем осуществленных капиталовложений по СЗПК, стороной которых не является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalInvestorCapitalFederalReportPlanChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalInvestorCapitalFederalReportPlanChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalInvestorCapitalFederalReportPlanRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalInvestorCapitalFederalReportPlanRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={regionalInvestorCapitalFederalReportPlanRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalInvestorCapitalFederalReportPlanCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalInvestorCapitalFederalReportPlanCorrect,
                    rules:
                      thirdSectionValidationMap.regionalInvestorCapitalFederalReportPlanCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalInvestorCapitalFederalReportPlanCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalInvestorCapitalFederalReportPlanCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalInvestorCapitalFederalReportFactChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalInvestorCapitalFederalReportFactChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalInvestorCapitalFederalReportFactRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalInvestorCapitalFederalReportFactRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={regionalInvestorCapitalFederalReportFactRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalInvestorCapitalFederalReportFactCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalInvestorCapitalFederalReportFactCorrect,
                    rules:
                      thirdSectionValidationMap.regionalInvestorCapitalFederalReportFactCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalInvestorCapitalFederalReportFactCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalInvestorCapitalFederalReportFactCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoCollapse
                      title={`${item.regionCode} ${item.regionName}`}
                      indexOfReport={index}
                    >
                      <Stack direction={'vertical'} gap={3}>
                        <Group title={'Объем капиталовложений'}>
                          <Stack direction={'vertical'} gap={3}>
                            <RowWithBorder
                              disableBottomDefaultStyles
                              firstColumnSize={6}
                              secondColumnSize={6}
                              title={
                                'Объем из справок проверяющей организации. Фактическое значение'
                              }
                            >
                              <SubscribableControl
                                {...getSubscribableControlProps({
                                  path: `${formName}.regionalInvestorCapitalFactChecker`,
                                })}
                              >
                                <ControlledAmountInput
                                  {...getAmountInputProps({
                                    name: `${formName}.regionalInvestorCapitalFactChecker`,
                                    inputProps: {
                                      disabled: true,
                                    },
                                  })}
                                />
                              </SubscribableControl>
                            </RowWithBorder>
                            <RowWithBorder
                              disableBottomDefaultStyles
                              title={'Объем из регионального отчета. Фактическое значение'}
                              firstColumnSize={6}
                              secondColumnSize={6}
                            >
                              <SubscribableControl
                                {...getSubscribableControlProps({
                                  path: `${formName}.regionalInvestorCapitalFact`,
                                })}
                              >
                                <ControlledAmountInput
                                  {...getAmountInputProps({
                                    name: `${formName}.regionalInvestorCapitalFact`,
                                    inputProps: {
                                      disabled: true,
                                    },
                                  })}
                                />
                              </SubscribableControl>
                            </RowWithBorder>
                            <RowWithBorder
                              disableBottomDefaultStyles
                              title={'Объем из справок проверяющей организации. Плановое значение'}
                              firstColumnSize={6}
                              secondColumnSize={6}
                            >
                              <SubscribableControl
                                {...getSubscribableControlProps({
                                  path: `${formName}.regionalInvestorCapitalPlanChecker`,
                                })}
                              >
                                <ControlledAmountInput
                                  {...getAmountInputProps({
                                    name: `${formName}.regionalInvestorCapitalPlanChecker`,
                                    inputProps: {
                                      disabled: true,
                                    },
                                  })}
                                />
                              </SubscribableControl>
                            </RowWithBorder>
                            <RowWithBorder
                              disableBottomDefaultStyles
                              title={'Объем из регионального отчета. Плановое значение'}
                              firstColumnSize={6}
                              secondColumnSize={6}
                            >
                              <SubscribableControl
                                {...getSubscribableControlProps({
                                  path: `${formName}.regionalInvestorCapitalPlan`,
                                })}
                              >
                                <ControlledAmountInput
                                  {...getAmountInputProps({
                                    name: `${formName}.regionalInvestorCapitalPlan`,
                                    inputProps: {
                                      disabled: true,
                                    },
                                  })}
                                />
                              </SubscribableControl>
                            </RowWithBorder>
                          </Stack>
                        </Group>
                      </Stack>
                    </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group disableBottomBorder title="Количество созданных рабочих мест">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .newWorkPlacesFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .newWorkPlacesFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Плановое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .newWorkPlacesFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .newWorkPlacesFederalReportFact,
                      inputProps: {
                        disabled: true,
                        integersOnly: true,
                        label: 'Фактическое значение',
                        suffix: '',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group
          title={
            'Количество созданных рабочих мест по СЗПК, стороной которых является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalNewWorkPlacesFederalReportPlanChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalNewWorkPlacesFederalReportPlanChecker,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalNewWorkPlacesFederalReportPlanRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalNewWorkPlacesFederalReportPlanRegion,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={federalNewWorkPlacesFederalReportPlanRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalNewWorkPlacesFederalReportPlanCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalNewWorkPlacesFederalReportPlanCorrect,
                    rules: thirdSectionValidationMap.federalNewWorkPlacesFederalReportPlanCorrect,
                    inputProps: {
                      integersOnly: true,
                      suffix: '',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalNewWorkPlacesFederalReportPlanCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalNewWorkPlacesFederalReportPlanCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalNewWorkPlacesFederalReportFactChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalNewWorkPlacesFederalReportFactChecker,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalNewWorkPlacesFederalReportFactRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalNewWorkPlacesFederalReportFactRegion,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={federalNewWorkPlacesFederalReportFactRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalNewWorkPlacesFederalReportFactCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalNewWorkPlacesFederalReportFactCorrect,
                    rules: thirdSectionValidationMap.federalNewWorkPlacesFederalReportFactCorrect,
                    inputProps: {
                      integersOnly: true,
                      suffix: '',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalNewWorkPlacesFederalReportFactCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalNewWorkPlacesFederalReportFactCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoFederalNewWorkPlacesItem
                      key={item.id}
                      title={`${item.regionCode} ${item.regionName}`}
                      editMode={editMode}
                      blockViewIsValidating={blockViewIsValidating}
                      index={index}
                      formName={formName}
                      formInstance={formInstance}
                      subscribableControl={subscribableControl}
                    />
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group
          title={
            'Количество созданных рабочих мест по СЗПК, стороной которых не является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalNewWorkPlacesFederalReportPlanChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalNewWorkPlacesFederalReportPlanChecker,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalNewWorkPlacesFederalReportPlanRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalNewWorkPlacesFederalReportPlanRegion,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={regionalNewWorkPlacesFederalReportPlanRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalNewWorkPlacesFederalReportPlanCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalNewWorkPlacesFederalReportPlanCorrect,
                    rules: thirdSectionValidationMap.regionalNewWorkPlacesFederalReportPlanCorrect,
                    inputProps: {
                      integersOnly: true,
                      suffix: '',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalNewWorkPlacesFederalReportPlanCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalNewWorkPlacesFederalReportPlanCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalNewWorkPlacesFederalReportFactChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalNewWorkPlacesFederalReportFactChecker,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalNewWorkPlacesFederalReportFactRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalNewWorkPlacesFederalReportFactRegion,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={regionalNewWorkPlacesFederalReportFactRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalNewWorkPlacesFederalReportFactCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalNewWorkPlacesFederalReportFactCorrect,
                    rules: thirdSectionValidationMap.regionalNewWorkPlacesFederalReportFactCorrect,
                    inputProps: {
                      integersOnly: true,
                      suffix: '',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalNewWorkPlacesFederalReportFactCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalNewWorkPlacesFederalReportFactCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoRegionalNewWorkPlacesItem
                      key={item.id}
                      title={`${item.regionCode} ${item.regionName}`}
                      editMode={editMode}
                      blockViewIsValidating={blockViewIsValidating}
                      index={index}
                      formName={formName}
                      formInstance={formInstance}
                      subscribableControl={subscribableControl}
                    />
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group
          disableBottomBorder
          title="Объем возмещенных затрат организаций, реализующих инвестиционные проекты"
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .compensationValueFederalReportPlan,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .compensationValueFederalReportPlan,
                      inputProps: {
                        disabled: true,
                        label: 'Плановое значение',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['3']
                      .compensationValueFederalReportFact,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: summaryReportOfMonitoringResultsBlockValues['3']
                        .compensationValueFederalReportFact,
                      inputProps: {
                        disabled: true,
                        label: 'Фактическое значение',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group
          title={'Объем возмещенных затрат по СЗПК, стороной которых является Российская Федерация'}
        >
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalCompensationValueFederalReportPlanChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalCompensationValueFederalReportPlanChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalCompensationValueFederalReportPlanRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalCompensationValueFederalReportPlanRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={federalCompensationValueFederalReportPlanRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalCompensationValueFederalReportPlanCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalCompensationValueFederalReportPlanCorrect,
                    rules:
                      thirdSectionValidationMap.federalCompensationValueFederalReportPlanCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalCompensationValueFederalReportPlanCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalCompensationValueFederalReportPlanCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalCompensationValueFederalReportFactChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalCompensationValueFederalReportFactChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalCompensationValueFederalReportFactRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalCompensationValueFederalReportFactRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={federalCompensationValueFederalReportFactRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .federalCompensationValueFederalReportFactCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .federalCompensationValueFederalReportFactCorrect,
                    rules:
                      thirdSectionValidationMap.federalCompensationValueFederalReportFactCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalCompensationValueFederalReportFactCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .federalCompensationValueFederalReportFactCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoRegionalInvestorCapitalItem
                      key={item.id}
                      title={`${item.regionCode} ${item.regionName}`}
                      editMode={editMode}
                      blockViewIsValidating={blockViewIsValidating}
                      index={index}
                      formName={formName}
                      formInstance={formInstance}
                      subscribableControl={subscribableControl}
                    />
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
        <Group
          title={
            'Объем возмещенных затрат по СЗПК, стороной которых не является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalCompensationValueFederalReportPlanChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalCompensationValueFederalReportPlanChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalCompensationValueFederalReportPlanRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalCompensationValueFederalReportPlanRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={regionalCompensationValueFederalReportPlanRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Плановое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalCompensationValueFederalReportPlanCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalCompensationValueFederalReportPlanCorrect,
                    rules:
                      thirdSectionValidationMap.regionalCompensationValueFederalReportPlanCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalCompensationValueFederalReportPlanCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalCompensationValueFederalReportPlanCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalCompensationValueFederalReportFactChecker,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalCompensationValueFederalReportFactChecker,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение по региональным отчетам'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalCompensationValueFederalReportFactRegion,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalCompensationValueFederalReportFactRegion,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={regionalCompensationValueFederalReportFactRegionDeviation}
                        />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Фактическое значение для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['3']
                    .regionalCompensationValueFederalReportFactCorrect,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['3']
                      .regionalCompensationValueFederalReportFactCorrect,
                    rules:
                      thirdSectionValidationMap.regionalCompensationValueFederalReportFactCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalCompensationValueFederalReportFactCorrect,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          summaryReportOfMonitoringResultsBlockValues['3']
                            .regionalCompensationValueFederalReportFactCorrect,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo,
          })}
        >
          <Group disableBottomBorder title={'Информация о подписанных региональных отчетах'}>
            {!!regionsReportInfo.length ? (
              <FlipperList list={regionsReportInfo}>
                {regionsReportInfo.map((item, index) => {
                  const formName =
                    `${summaryReportOfMonitoringResultsBlockValues['3'].regionsReportInfo}.${index}` as const
                  return (
                    <SummaryReportOfMonitoringResultsRegionReportInfoRegionalCompensationValueItem
                      key={item.id}
                      title={`${item.regionCode} ${item.regionName}`}
                      editMode={editMode}
                      blockViewIsValidating={blockViewIsValidating}
                      index={index}
                      formName={formName}
                      formInstance={formInstance}
                      subscribableControl={subscribableControl}
                    />
                  )
                })}
              </FlipperList>
            ) : (
              <Typography.Caption
                className={styles.form__signedSubtitle}
                variant={'captionSRegular'}
              >
                нет подписанных отчетов
              </Typography.Caption>
            )}
          </Group>
        </SubscribableControl>
      </Stack>
    </Container>
  )
}

export default Third
