import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'

const generateRHFObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm =
  (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues => {
    return {
      '1': {
        ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
      },
      '2': {
        ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
      },
      '3': {
        ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
        investorFullNameInstrumental:
          objectFromPropertyState.investorFullNameInstrumental?.value || '',
      },
      '4': {
        reasonDenySolicitation: objectFromPropertyState?.reasonDenySolicitation?.value || '',
        ruleLinkDenySolicitation: objectFromPropertyState?.ruleLinkDenySolicitation?.value || '',
      },
    }
  }

export { generateRHFObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm }
