import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import ApplicationOfConclusionAgreementSZPKRegister from '@components/Forms/ApplicationOfConclusionAgreementSZPKForm'
import { ApplicationOfConclusionAgreementSZPKRegisterFieldsControlUpdateWatcher } from '@components/Forms/ApplicationOfConclusionAgreementSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import {
  defaultApplicationOfConclusionAgreementSZPKRegisterFormValues,
  mapOfApplicationOfConclusionAgreementSZPKRegisterMenu,
} from '../const'
import { ApplicationOfConclusionAgreementSZPKFormValues } from '../types'

import ApplicationOfConclusionAgreementSZPKRegisterLayoutWrapper from './Wrapper'

const ApplicationOfConclusionAgreementSZPKRegisterLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<ApplicationOfConclusionAgreementSZPKFormValues>({
    defaultValues: defaultApplicationOfConclusionAgreementSZPKRegisterFormValues,
  })

  const handleOnClose = () => {
    ApplicationOfConclusionAgreementSZPKRegisterFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <ApplicationOfConclusionAgreementSZPKRegister.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfApplicationOfConclusionAgreementSZPKRegisterMenu[0].id}
          >
            <ApplicationOfConclusionAgreementSZPKRegisterLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Заявление о заключении дополнительного соглашения о включении в СЗПК условий
                  связанного договора
                </Typography.Headline>
                <ApplicationOfConclusionAgreementSZPKRegister.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <ApplicationOfConclusionAgreementSZPKRegister.Menu
                  className={styles.layout__menu}
                />
              </div>
            </ApplicationOfConclusionAgreementSZPKRegisterLayoutWrapper>
          </FlatMenuManager>
        </ApplicationOfConclusionAgreementSZPKRegister.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(ApplicationOfConclusionAgreementSZPKRegisterLayout)
