import { isString } from '@helpers/checkTypes'
import { mapOfInvalidDateTypes } from '@services/Dayjs/Dayjs.entity'
import DayjsHelpersService from '@services/Dayjs/Dayjs.helpers'
import { ConfigType, OptionType } from 'dayjs'

import { CalendarProps } from '../Calendar/Calendar'
import { mapOfCalendarTypes } from '../Calendar/const'

const getErrorMessageByDate = (
  date: ConfigType,
  format: OptionType,
  typeOfPicker: CalendarProps['typeOfPicker'],
) => {
  const invalidDateType = DayjsHelpersService.getInvalidDateType(date, format)

  if (!invalidDateType || (isString(date) && date.trim() === '')) return

  switch (invalidDateType) {
    case mapOfInvalidDateTypes.DATE_FORMAT_INCORRECT:
      if (typeOfPicker === mapOfCalendarTypes.years) return 'дата должна быть в формате гггг'
      return 'дата должна быть в формате дд.мм.гггг'

    case mapOfInvalidDateTypes.DATE_NON_EXISTENT:
      return 'некорректная дата'

    default:
      return 'некорректная дата'
  }
}

export { getErrorMessageByDate }
