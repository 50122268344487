import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { SzpkReportInfoArrayPathName } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/types'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import Typography from '@components/NewDesign/Typography'

import SzpkReportInfoCardItem, {
  CapitalValueGroupCardProps,
  NewWorkplacesGroupCardProps,
} from '../SzpkReportInfoCardItem'

interface SzpkReportListByFormNameProps {
  listFormName: SzpkReportInfoArrayPathName
  capitalValueGroupCardProps?: CapitalValueGroupCardProps
  newWorkPlacesGroupCardProps?: NewWorkplacesGroupCardProps
}

const SzpkReportListByFormName: FC<SzpkReportListByFormNameProps> = ({
  listFormName,
  capitalValueGroupCardProps,
  newWorkPlacesGroupCardProps,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const szpkReportInfoCards = useWatch({
    name: listFormName,
    control: formInstance?.control,
  })

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <SubscribableControl
      {...getSubscribableControlProps({
        path: listFormName,
      })}
    >
      {!!szpkReportInfoCards.length ? (
        <FlipperList list={szpkReportInfoCards}>
          {szpkReportInfoCards.map((reportInfo, index) => {
            const formName = `${listFormName}.${index}` as const

            return (
              <SzpkReportInfoCardItem
                key={index}
                cardIndex={index}
                investorFullName={reportInfo.investorFullName}
                formName={formName}
                capitalValueGroupCardProps={capitalValueGroupCardProps}
                newWorkPlacesGroupCardProps={newWorkPlacesGroupCardProps}
              />
            )
          })}
        </FlipperList>
      ) : (
        <Typography.Caption className={styles.form__signedSubtitle} variant={'captionSRegular'}>
          нет подписанных отчетов
        </Typography.Caption>
      )}
    </SubscribableControl>
  )
}

export default SzpkReportListByFormName
