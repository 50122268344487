import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

import { indicatorsOfUnfulfilledAgreementReportBlockValues } from '../../const'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '../../types'
import { IndicatorsOfUnfulfilledAgreementReportFieldArrayControlUpdateWatcher } from '../../watcher'

import StepDeadlineItem from './AdditionalForms/StepDeadlineItem'
import { StepsDeadlinePathName } from './types'

const Fourth = () => {
  const formInstance = useFormContext<IndicatorsOfUnfulfilledAgreementReportFormValues>()

  useFieldArraySubscribableControl<
    IndicatorsOfUnfulfilledAgreementReportFormValues,
    StepsDeadlinePathName
  >({
    name: indicatorsOfUnfulfilledAgreementReportBlockValues['4'].stepsDeadline,
    control: formInstance.control,
    watcher: IndicatorsOfUnfulfilledAgreementReportFieldArrayControlUpdateWatcher,
  })

  const stepsDeadline = useWatch({
    name: indicatorsOfUnfulfilledAgreementReportBlockValues['4'].stepsDeadline,
    control: formInstance.control,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        {stepsDeadline.map((stepDeadline, stepDeadlineIndex) => (
          <StepDeadlineItem
            stepNumber={stepDeadline.stepNumber}
            stepDescription={stepDeadline.stepDescription}
            key={stepDeadlineIndex}
            isLastItem={stepDeadlineIndex === stepsDeadline.length - 1}
            formName={
              `${indicatorsOfUnfulfilledAgreementReportBlockValues['4'].stepsDeadline}.${stepDeadlineIndex}` as const
            }
          />
        ))}
      </Stack>
    </Container>
  )
}

export default Fourth
