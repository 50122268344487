import React, { useMemo } from 'react'
import { FieldValues, Path, useFormState, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import BeneficiarSecondNationalityCountriesList from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/BeneficiarItem/BeneficiarSecondNationalityCountriesList'
import {
  mapOfCitizenshipCodes,
  mapOfDocumentTypeCodes,
} from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/BeneficiarItem/constants'
import {
  BeneficiarItemProps,
  BeneficiarItemPropsRetyped,
} from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/BeneficiarItem/types'
import { getBeneficialOwnersInfoSectionValidation } from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/validation'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import {
  getFetcherProps,
  isFetcherProps,
} from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CustomTransition from '@components/Transition'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { isEmptyString, isNullOrUndefined, isString } from '@helpers/checkTypes'
import { disableFutureDates } from '@helpers/date/disableOfDates'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

const beneficialOwnersInfoSectionValidation = getBeneficialOwnersInfoSectionValidation()

const { transformRHFPathInProperties } = DocumentFormHelpers

const BeneficiarItem = <T extends FieldValues, P = Path<T>>(_props: BeneficiarItemProps<T, P>) => {
  // это приведение типов нужно для того, чтобы не писать "as Path<T>" везде, где испольузем formName
  const props = _props as unknown as BeneficiarItemPropsRetyped

  const { id, formInstance, formName, manager, watcher, fieldArrayWatcher, onRemoveStep } = props

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      getPropertiesProps,
      getOverrideProps,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = manager

  const {
    getSubscribableControlProps,
    getMaskInputProps,
    getTextareaProps,
    getInputProps,
    getSwitchProps,
    getSingleSelectProps,
    getCalendarInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher,
  })

  const { errors } = useFormState({
    control: formInstance.control,
  })

  const beneficiarNationality = useWatch({
    name: `${formName}.beneficiarNationality`,
    control: formInstance.control,
  })

  const beneficiarDocument = useWatch({
    name: `${formName}.beneficiarDocument`,
    control: formInstance.control,
  })

  const otherNationalityRenderCondition = useWatch({
    name: `${formName}.otherNationality`,
    control: formInstance.control,
  })

  const beneficiarBirthDate = useWatch({
    name: `${formName}.beneficiarBirthDate`,
    control: formInstance.control,
  })

  const beneficiarMigrationEndDate = useWatch({
    name: `${formName}.beneficiarMigrationEndDate`,
    control: formInstance.control,
  })

  const beneficiarMigrationStartDate = useWatch({
    name: `${formName}.beneficiarMigrationStartDate`,
    control: formInstance.control,
  })

  const beneficiarResidenceStartDate = useWatch({
    name: `${formName}.beneficiarResidenceStartDate`,
    control: formInstance.control,
  })

  const beneficiarResidenceEndDate = useWatch({
    name: `${formName}.beneficiarResidenceEndDate`,
    control: formInstance.control,
  })

  const getReferenceBookItemFromOverrideProps = (itemFormName: string, id: string) => {
    const itemPropertyId = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(itemFormName),
    )?.propertyId

    if (!itemPropertyId) return null

    const allControlProps = getOverrideProps?.()[itemPropertyId]

    const overrideProps = getFetcherProps(allControlProps)
    if (!overrideProps) return null

    const selectedItem = overrideProps.find((item) => item?.id === id)

    if (!selectedItem || !selectedItem?.code) return null

    return selectedItem
  }

  const selectedBeneficiarDocumentValue = useMemo((): ReferenceBookItem | null => {
    const selectedBeneficiarDocument = getReferenceBookItemFromOverrideProps(
      `${formName}.beneficiarDocument`,
      beneficiarDocument?.value || '',
    )

    return selectedBeneficiarDocument
  }, [beneficiarDocument])

  const selectedBeneficiarNationalityValue = useMemo((): ReferenceBookItem | null => {
    const selectedBeneficiarNationality = getReferenceBookItemFromOverrideProps(
      `${formName}.beneficiarNationality`,
      beneficiarNationality?.value || '',
    )

    return selectedBeneficiarNationality
  }, [beneficiarNationality])

  const russianCitizenshipRenderCondition =
    selectedBeneficiarNationalityValue?.code === mapOfCitizenshipCodes.russianCitizenship

  const foreignInfoRenderCondition =
    selectedBeneficiarNationalityValue?.code === mapOfCitizenshipCodes.foreignInfo

  const withoutCitizenshipRenderCondition =
    selectedBeneficiarNationalityValue?.code === mapOfCitizenshipCodes.withoutCitizenship

  const russianPassportDocumentTypeCondition =
    selectedBeneficiarDocumentValue?.code === mapOfDocumentTypeCodes.russianPassport

  const handleChangeBeneficiarNationality = () => {
    setTimeout(async () => {
      await handleChangeValue?.(`${formName}.beneficiarNationality`)

      // тригеррим beneficiarInn без проверки на незаполненость, ибо он находится раньше, чем селект beneficiarNationality
      formInstance.trigger(`${formName}.beneficiarInn`)
    }, 0)
  }

  const handleChangeBeneficiarDocument = () => {
    setTimeout(async () => {
      await handleChangeValue?.(`${formName}.beneficiarDocument`)

      const beneficiarDocumentNumber = formInstance.getValues(
        `${formName}.beneficiarDocumentNumber`,
      )

      const beneficiarDocumentCodeOrganization = formInstance.getValues(
        `${formName}.beneficiarDocumentCodeOrganization`,
      )

      const beneficiarDocumentSeries = formInstance.getValues(
        `${formName}.beneficiarDocumentSeries`,
      )

      const currentErrors = getObjectValue(errors, formName)

      if (!isEmptyString(beneficiarDocumentNumber) || !!currentErrors?.beneficiarDocumentNumber) {
        formInstance.trigger(`${formName}.beneficiarDocumentNumber`)
      }

      if (
        !isEmptyString(beneficiarDocumentCodeOrganization) ||
        !!currentErrors?.beneficiarDocumentCodeOrganization
      ) {
        formInstance.trigger(`${formName}.beneficiarDocumentCodeOrganization`)
      }

      if (!isEmptyString(beneficiarDocumentSeries) || !!currentErrors?.beneficiarDocumentSeries) {
        formInstance.trigger(`${formName}.beneficiarDocumentSeries`)
      }
    }, 0)
  }

  return (
    <FieldView.StepTemplate id={id} onRemoveStep={editMode ? onRemoveStep : undefined}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.beneficiarName`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.beneficiarName`,
                  rules: beneficialOwnersInfoSectionValidation.beneficiarName,

                  inputProps: {
                    label: 'Фамилия, Имя, Отчество',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.beneficiarName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.beneficiarName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.beneficiarBirthPlace`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.beneficiarBirthPlace`,
                  rules: beneficialOwnersInfoSectionValidation.beneficiarBirthPlace,

                  textareaProps: {
                    label: 'Место рождения',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.beneficiarBirthPlace`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.beneficiarBirthPlace`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.beneficiarBirthDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${formName}.beneficiarBirthDate`,
                  rules: {
                    ...beneficialOwnersInfoSectionValidation.beneficiarBirthDate,
                    onChange: () => {
                      formInstance.trigger(`${formName}.beneficiarDocumentDate`)
                    },
                  },
                  calendarProps: {
                    disabledDate: (date) => disableFutureDates(date),
                  },
                  calendarInputProps: {
                    label: 'Дата рождения',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.beneficiarBirthDate`), 0),
                  onInputChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.beneficiarBirthDate`)
                    }, 0),
                  onCalendarChange: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.beneficiarBirthDate`), 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.beneficiarInn`,
              })}
            >
              <ControlledMaskInput
                {...getMaskInputProps({
                  name: `${formName}.beneficiarInn`,
                  inputProps: {
                    autoUnmask: true,
                    mask: '999999999999',
                    label: 'ИНН',
                  },
                  rules: {
                    required: russianCitizenshipRenderCondition && defaultRHFValidation.required,
                    validate: (value) => {
                      if (!isString(value)) return

                      if (russianCitizenshipRenderCondition) {
                        return value.length === 12 || 'ИНН должен состоять из 12 цифр'
                      }

                      return lengthValidate(value, 12)
                    },
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.beneficiarInn`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.beneficiarInn`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <RowWithBorder
          disableFlexNoWrap
          disableTitleLeftPadding
          disableBottomDefaultStyles
          firstColumnSize={0}
          secondColumnSize={12}
        >
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.beneficiarNationality`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item.name || '',
                          value: item.id,
                        }),
                        controllerProps: {
                          name: `${formName}.beneficiarNationality`,
                          rules: beneficialOwnersInfoSectionValidation.beneficiarNationality,
                        },
                        selectProps: {
                          withContextSearch: true,
                          inputProps: {
                            label: 'Гражданство',
                          },
                          onChangeFormValue: handleChangeBeneficiarNationality,
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.beneficiarNationalityCountry`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <CustomTransition.Fade show={foreignInfoRenderCondition}>
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.name || '',
                            value: item.id,
                          }),
                          controllerProps: {
                            name: `${formName}.beneficiarNationalityCountry`,
                            rules:
                              beneficialOwnersInfoSectionValidation.beneficiarNationalityCountry,
                          },
                          selectProps: {
                            withContextSearch: true,
                            inputProps: {
                              label: 'Страна гражданства',
                            },
                            onChangeFormValue: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${formName}.beneficiarNationalityCountry`)
                              }, 0),
                          },
                        })}
                      />
                    </CustomTransition.Fade>
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
        </RowWithBorder>
        <CollapseWrapper
          defaultExpanded={russianCitizenshipRenderCondition}
          isExpanded={russianCitizenshipRenderCondition}
        >
          <Row>
            <Col xs={12}>
              <Stack direction={'vertical'} gap={2}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.otherNationality`,
                  })}
                >
                  <ControlledSwitch
                    {...getSwitchProps({
                      name: `${formName}.otherNationality`,
                      switchProps: {
                        wrapperClassName: styles.form__switch,
                        label: 'Есть иное гражданство',
                      },
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.otherNationality`), 0),
                    })}
                  />
                </SubscribableControl>
                <CollapseWrapper
                  defaultExpanded={otherNationalityRenderCondition}
                  isExpanded={otherNationalityRenderCondition}
                >
                  <BeneficiarSecondNationalityCountriesList
                    editMode={editMode}
                    blockViewIsValidating={blockViewIsValidating}
                    formName={formName}
                    manager={manager}
                    formInstance={formInstance}
                    watcher={watcher}
                    fieldArrayWatcher={fieldArrayWatcher}
                  />
                </CollapseWrapper>
              </Stack>
            </Col>
          </Row>
        </CollapseWrapper>
        <Group disableBottomBorder title={'Документ, удостоверяющий личность'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.beneficiarDocument`,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item?.name || '',
                            value: item.id,
                          }),
                          controllerProps: {
                            name: `${formName}.beneficiarDocument`,
                            rules: beneficialOwnersInfoSectionValidation.beneficiarDocument,
                          },
                          selectProps: {
                            withContextSearch: true,
                            inputProps: {
                              label: 'Вид документа',
                            },
                            onChangeFormValue: handleChangeBeneficiarDocument,
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.beneficiarDocumentSeries`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.beneficiarDocumentSeries`,
                      rules: {
                        required:
                          russianPassportDocumentTypeCondition && defaultRHFValidation.required,
                        validate: (value) => {
                          if (!isString(value)) return

                          if (russianPassportDocumentTypeCondition) {
                            return value.length === 4 || 'серия документа должна состоять из 4 цифр'
                          }

                          return lengthValidate(value, 10)
                        },
                      },
                      inputProps: {
                        label: 'Серия',
                        suffix: '',
                        maxLength: russianPassportDocumentTypeCondition ? 4 : 10,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.beneficiarDocumentSeries`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.beneficiarDocumentSeries`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.beneficiarDocumentNumber`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.beneficiarDocumentNumber`,
                      rules: {
                        ...beneficialOwnersInfoSectionValidation.beneficiarDocumentNumber,
                        validate: (value) => {
                          if (!isString(value)) return

                          if (russianPassportDocumentTypeCondition) {
                            return value.length === 6 || 'номер документа должен состоять из 6 цифр'
                          }

                          return lengthValidate(value, 10)
                        },
                      },
                      inputProps: {
                        label: 'Номер',
                        suffix: '',
                        maxLength: russianPassportDocumentTypeCondition ? 6 : 10,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.beneficiarDocumentNumber`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.beneficiarDocumentNumber`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.beneficiarDocumentDate`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${formName}.beneficiarDocumentDate`,
                      rules: {
                        ...beneficialOwnersInfoSectionValidation.beneficiarDocumentDate,
                        validate: {
                          lengthValidate: (value) => isString(value) && lengthValidate(value, 10),
                          positiveCurrentDate: (value) => {
                            const currentDate = DayjsService.dayjsWithFormatToMSK()

                            return (
                              (isDateValidForDayjs(value) &&
                                dayjs(value, objOfDateFormats.defaultFormat) <= currentDate) ||
                              'дата выдачи документа должна быть меньше или равна текущей дате'
                            )
                          },
                          positiveBeneficiarBirthDate: (value) => {
                            return (
                              (isDateValidForDayjs(value) &&
                                dayjs(value, objOfDateFormats.defaultFormat) >
                                  dayjs(beneficiarBirthDate, objOfDateFormats.defaultFormat)) ||
                              'дата выдачи документа должна быть больше, чем дата рождения'
                            )
                          },
                        },
                      },
                      calendarInputProps: { label: 'Дата выдачи' },
                      calendarProps: {
                        disabledDate: (date) => disableFutureDates(date),
                      },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.beneficiarDocumentDate`),
                          0,
                        ),
                      onInputChange: () =>
                        debouncedHandleChangeValue?.(`${formName}.beneficiarDocumentDate`),
                      onCalendarChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.beneficiarDocumentDate`),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <RowWithBorder
              disableFlexNoWrap
              disableTitleLeftPadding
              firstColumnSize={0}
              secondColumnSize={12}
            >
              <Row>
                <Col xs={8}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.beneficiarDocumentOrganization`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${formName}.beneficiarDocumentOrganization`,
                        rules: beneficialOwnersInfoSectionValidation.beneficiarDocumentOrganization,

                        inputProps: {
                          label: 'Кем выдан документ',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.beneficiarDocumentOrganization`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${formName}.beneficiarDocumentOrganization`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={4}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.beneficiarDocumentCodeOrganization`,
                    })}
                  >
                    <ControlledMaskInput
                      key={selectedBeneficiarDocumentValue?.code}
                      {...getMaskInputProps({
                        name: `${formName}.beneficiarDocumentCodeOrganization`,
                        inputProps: {
                          autoUnmask: !russianPassportDocumentTypeCondition,
                          mask: russianPassportDocumentTypeCondition ? '999-999' : '999999',
                          label: 'Код подразделения',
                        },
                        rules: {
                          required:
                            russianPassportDocumentTypeCondition && defaultRHFValidation.required,
                          validate: (value) => {
                            if (!isString(value)) return

                            const valueWithOnlyDigits = value.replace(/[^0-9]/g, '')

                            if (russianPassportDocumentTypeCondition) {
                              return (
                                valueWithOnlyDigits.length === 6 ||
                                'код подразделения должен состоять из 6 цифр'
                              )
                            }

                            return lengthValidate(valueWithOnlyDigits, 6)
                          },
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.beneficiarDocumentCodeOrganization`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${formName}.beneficiarDocumentCodeOrganization`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </RowWithBorder>
          </Stack>
        </Group>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.beneficiarAddress`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.beneficiarAddress`,
                  rules: beneficialOwnersInfoSectionValidation.beneficiarAddress,

                  textareaProps: {
                    label: 'Адрес места жительства (пребывания)',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.beneficiarAddress`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.beneficiarAddress`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <CollapseWrapper
          defaultExpanded={foreignInfoRenderCondition || withoutCitizenshipRenderCondition}
          isExpanded={foreignInfoRenderCondition || withoutCitizenshipRenderCondition}
        >
          <Group disableBottomBorder title={'Данные миграционной карты'}>
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.beneficiarMigrationNumber`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${formName}.beneficiarMigrationNumber`,
                        rules: beneficialOwnersInfoSectionValidation.beneficiarMigrationNumber,

                        inputProps: {
                          maxLength: 20,
                          label: 'Номер карты',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.beneficiarMigrationNumber`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.beneficiarMigrationNumber`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <RowWithBorder
                    disableTitleLeftPadding
                    disableBottomDefaultStyles
                    title={'Дата начала срока пребывания'}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.beneficiarMigrationStartDate`,
                      })}
                    >
                      <ControlledCalendarInput
                        {...getCalendarInputProps({
                          name: `${formName}.beneficiarMigrationStartDate`,
                          rules: {
                            onChange: () => {
                              if (!beneficiarMigrationEndDate) return

                              formInstance?.trigger(`${formName}.beneficiarMigrationEndDate`)
                            },
                          },
                          onBlur: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${formName}.beneficiarMigrationStartDate`)
                            }, 0),
                          onInputChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.beneficiarMigrationStartDate`,
                              )
                            }, 0),
                          onCalendarChange: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${formName}.beneficiarMigrationStartDate`)
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </RowWithBorder>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <RowWithBorder
                    disableTitleLeftPadding
                    disableBottomDefaultStyles
                    title={'Дата окончания срока пребывания'}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.beneficiarMigrationEndDate`,
                      })}
                    >
                      <ControlledCalendarInput
                        {...getCalendarInputProps({
                          name: `${formName}.beneficiarMigrationEndDate`,
                          rules: {
                            validate: {
                              positive: (value) => {
                                if (
                                  isNullOrUndefined(beneficiarMigrationStartDate) ||
                                  !isDateValidForDayjs(beneficiarMigrationStartDate) ||
                                  !isDateValidForDayjs(value)
                                )
                                  return true

                                return (
                                  DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >
                                    DayjsService.dayjs(
                                      beneficiarMigrationStartDate,
                                      objOfDateFormats.defaultFormat,
                                    ) ||
                                  'дата окончания срока пребывания должна быть больше, чем дата начала срока пребывания'
                                )
                              },
                            },
                          },
                          onBlur: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${formName}.beneficiarMigrationStartDate`)
                            }, 0),
                          onInputChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(`${formName}.beneficiarMigrationEndDate`)
                            }, 0),
                          onCalendarChange: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.beneficiarMigrationEndDate`),
                              0,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </RowWithBorder>
                </Col>
              </Row>
            </Stack>
          </Group>
          <Group
            disableBottomBorder
            title={
              'Документ, подтверждающий право иностранного гражданина или лица без гражданства на пребывание в РФ'
            }
          >
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.beneficiarResidenceNumber`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${formName}.beneficiarResidenceNumber`,
                        rules: beneficialOwnersInfoSectionValidation.beneficiarResidenceNumber,

                        inputProps: {
                          label: 'Серия и номер',
                          maxLength: 20,
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.beneficiarResidenceNumber`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.beneficiarResidenceNumber`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <RowWithBorder
                    disableTitleLeftPadding
                    disableBottomDefaultStyles
                    title={'Дата начала действия права пребывания'}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.beneficiarResidenceStartDate`,
                      })}
                    >
                      <ControlledCalendarInput
                        {...getCalendarInputProps({
                          name: `${formName}.beneficiarResidenceStartDate`,
                          rules: {
                            onChange: () => {
                              if (!beneficiarResidenceEndDate) return

                              formInstance?.trigger(`${formName}.beneficiarResidenceEndDate`)
                            },
                          },
                          onBlur: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${formName}.beneficiarResidenceStartDate`)
                            }, 0),
                          onInputChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.beneficiarResidenceStartDate`,
                              )
                            }, 0),
                          onCalendarChange: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.beneficiarResidenceStartDate`),
                              0,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </RowWithBorder>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <RowWithBorder
                    disableTitleLeftPadding
                    disableBottomDefaultStyles
                    title={'Дата окончания действия права пребывания'}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.beneficiarResidenceEndDate`,
                      })}
                    >
                      <ControlledCalendarInput
                        {...getCalendarInputProps({
                          name: `${formName}.beneficiarResidenceEndDate`,
                          rules: {
                            validate: {
                              positive: (value) => {
                                if (
                                  isNullOrUndefined(beneficiarResidenceStartDate) ||
                                  !isDateValidForDayjs(value) ||
                                  !isDateValidForDayjs(beneficiarResidenceStartDate)
                                )
                                  return true

                                return (
                                  DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >
                                    DayjsService.dayjs(
                                      beneficiarResidenceStartDate,
                                      objOfDateFormats.defaultFormat,
                                    ) ||
                                  'дата окончания действия права пребывания должна быть больше, чем дата начала действия права пребывания'
                                )
                              },
                            },
                          },
                          onBlur: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.beneficiarResidenceEndDate`),
                              0,
                            ),
                          onInputChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(`${formName}.beneficiarResidenceEndDate`)
                            }, 0),
                          onCalendarChange: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.beneficiarResidenceEndDate`),
                              0,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </RowWithBorder>
                </Col>
              </Row>
            </Stack>
          </Group>
        </CollapseWrapper>
        <Group disableBottomBorder title={'Основания бенефициарного владения'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableTitleLeftPadding
                  disableBottomDefaultStyles
                  title={
                    'Владение прямо или косвенно более 25 процентами в капитале организации, реализующей инвестиционный проект'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.beneficiarPart`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${formName}.beneficiarPart`,
                        rules: beneficialOwnersInfoSectionValidation.beneficiarPart,
                        inputProps: {},
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.beneficiarPart`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.beneficiarPart`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableTitleLeftPadding
                  disableBottomDefaultStyles
                  title={
                    'Обстоятельства, позволяющие бенефициарному владельцу контролировать действия организации, реализующей инвестиционный проект'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.beneficiarReason`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${formName}.beneficiarReason`,
                        rules: beneficialOwnersInfoSectionValidation.beneficiarReason,

                        inputProps: {},
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.beneficiarReason`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.beneficiarReason`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default BeneficiarItem
