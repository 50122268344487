import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import PermissionsItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm/PermissionsItem'
import {
  SeventhPermissionsObjectsArrayPathName,
  SeventhPermissionsObjectsPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const { isFormFieldError } = DocumentFormHelpers

interface CPermissionsProps {
  indexOfBlock: number
  name: SeventhPermissionsObjectsPathName
  control: Control<ProjectSZPKFormValues>
}

const CPermissionsForm: FC<CPermissionsProps> = ({ control, name, indexOfBlock }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: permissionForms } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhPermissionsObjectsPathName,
    'keyNameId'
  >({
    name,
    control,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const handleAddPermission = async () => {
    await handleAddItemToListWithOutValue?.(name)

    if (!permissionForms.length) {
      formInstance.clearErrors(name)
    }
  }

  const handleRemovePermission = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  if (!formInstance) return null

  const permissionFormsError = formInstance.getFieldState(name)?.error

  return (
    <Container className="p-0">
      <Stack direction={'vertical'} gap={3} className={'mt-3'}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: name,
          })}
        >
          <FlipperList list={permissionForms}>
            {permissionForms.map((permissionForm, index) => {
              const formName = `${name}.${index}` as SeventhPermissionsObjectsArrayPathName

              return (
                <SubscribableControl
                  key={permissionForm.id}
                  {...getSubscribableControlProps({
                    path: formName,
                  })}
                >
                  <PermissionsItem
                    anchorId={permissionForm.anchorId}
                    id={permissionForm.id}
                    blockViewIsValidating={blockViewIsValidating}
                    editMode={editMode}
                    formInstance={formInstance}
                    formName={formName}
                    indexOfBlock={indexOfBlock}
                    indexOfPermission={index}
                    onDeleteObject={handleRemovePermission(index)}
                  />
                </SubscribableControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(permissionFormsError) && !permissionForms.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddPermission}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить разрешение
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default CPermissionsForm
