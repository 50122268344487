import { useCallback } from 'react'
import { ArrayPath, FieldValues, Path } from 'react-hook-form'
import { UnpackNestedValue, UseFormSetValue } from 'react-hook-form/dist/types/form'
import { FieldPathValue } from 'react-hook-form/dist/types/path'

import {
  FieldArrayControlUpdateWatcher,
  SubscriberFieldsNotifyProps,
} from '@components/DocumentFormComponents/fieldArrayWatcher'
import { compact } from '@helpers/array/compact'
import { filterArrayIntoTwoChunks } from '@helpers/array/filterArrayIntoChunks'
import { isNullOrUndefined, isUndefined } from '@helpers/checkTypes'
import { deepDiffKindTypes } from '@services/DeepDiff/DeepDiff.const'
import { DeepDiffHelpers } from '@services/DeepDiff/DeepDiff.helpers'
import { DeepDiffService } from '@services/DeepDiff/DeepDiff.service'

const { getDifferences } = DeepDiffService
const { isBaseDiffObject, isDiffObjectInList, isSimpleDiffObject } = DeepDiffHelpers

interface UseFindDifferencesProps<T extends FieldValues> {
  setValue: UseFormSetValue<T>
  watcher?: FieldArrayControlUpdateWatcher<T>
}

const isAdditionalFieldsDifferences = (value: unknown) => {
  if (!isBaseDiffObject(value)) return false

  const isAdditionalFieldsName = value?.path.includes('additionalFields')

  return isAdditionalFieldsName && value.kind === deepDiffKindTypes.ITEM_IN_ARRAY
}

const getPath = (path: string[]) => path.join('.')

const sortDifferences = (diffA: unknown, diffB: unknown): number => {
  // Если не массив и не одинаковые пути, то позиции не меняются
  if (
    !isDiffObjectInList(diffA) ||
    !isDiffObjectInList(diffB) ||
    getPath(diffA.path) !== getPath(diffB.path)
  )
    return 0

  // Если пути совпадают и добавлены новые элементы, то сортируются по возрастанию
  if (
    diffA.item.kind === deepDiffKindTypes.NEW_ITEM &&
    diffB.item.kind === deepDiffKindTypes.NEW_ITEM
  ) {
    return -1
  }

  // Если пути совпадают и удалены элементы, то сортируются по убыванию
  if (
    diffA.item.kind === deepDiffKindTypes.DELETED_ITEM &&
    diffB.item.kind === deepDiffKindTypes.DELETED_ITEM
  ) {
    return 1
  }

  return 0
}

const useFindFormValueDifferences = <T extends FieldValues>({
  setValue,
  watcher,
}: UseFindDifferencesProps<T>) => {
  const applySimpleDifferences = useCallback(
    (simpleDifferences: unknown[]) => {
      simpleDifferences?.forEach((diff) => {
        if (!isSimpleDiffObject(diff)) return

        setValue(
          getPath(diff.path) as Path<T>,
          diff.rhs as UnpackNestedValue<FieldPathValue<T, Path<T>>>,
        )
      })
    },
    [setValue],
  )

  const applyListDifferences = useCallback(
    (listDifferences: unknown[]) => {
      if (isUndefined(watcher)) return

      const preparedDifferences: SubscriberFieldsNotifyProps<T>[] = compact(
        listDifferences.sort(sortDifferences).map((diff): SubscriberFieldsNotifyProps<T> | null => {
          if (!isDiffObjectInList(diff)) return null

          const path = getPath(diff.path) as ArrayPath<T>

          if (diff.item.kind === deepDiffKindTypes.NEW_ITEM && 'rhs' in diff.item) {
            return {
              path,
              value: {
                appendProps: diff.item.rhs as UnpackNestedValue<FieldPathValue<T, Path<T>>>,
              },
            }
          }

          if (diff.item.kind === deepDiffKindTypes.DELETED_ITEM) {
            return {
              path,
              value: {
                removeProps: diff.index,
              },
            }
          }

          if (diff.item.kind === deepDiffKindTypes.SWAPPED_ITEM) {
            return {
              path,
              value: {
                swapProps: {
                  oldIndex: diff.index,
                  newIndex: diff.item.newIndex || diff.index,
                }
              },
            }
          }

          return null
        }),
      ) as SubscriberFieldsNotifyProps<T>[]

      watcher.notifyAllSubscribers(preparedDifferences)
    },
    [watcher],
  )

  const applyAdditionalFieldDifferences = useCallback(
    (additionalFieldDifferences: unknown[], currentThree: T) => {
      if (isUndefined(additionalFieldDifferences) || !additionalFieldDifferences.length) return

      additionalFieldDifferences.forEach((diff) => {
        if (!isDiffObjectInList(diff)) return

        const currentList = diff.path?.reduce((currentThree, path) => {
          return currentThree?.[path]
        }, currentThree)

        if (!currentList || !currentList.length) return

        setValue(
          getPath(diff.path) as Path<T>,
          currentList as UnpackNestedValue<FieldPathValue<T, Path<T>>>,
        )
      })
    },
    [setValue],
  )

  const applyDifferences = useCallback(
    (prev: T, current: T) => {
      const differences = getDifferences(prev, current)

      if (isNullOrUndefined(differences) || !differences.length) return

      const [simpleDifferences, listDifferences] = filterArrayIntoTwoChunks(
        differences,
        (diff) => isBaseDiffObject(diff) && diff.kind !== deepDiffKindTypes.ITEM_IN_ARRAY,
      )

      const [additionalFieldDifferences, restDifferences] = filterArrayIntoTwoChunks(
        listDifferences,
        isAdditionalFieldsDifferences,
      )

      applySimpleDifferences(simpleDifferences)

      applyListDifferences(restDifferences)

      applyAdditionalFieldDifferences(additionalFieldDifferences, current)
    },
    [applySimpleDifferences, applyListDifferences, applyAdditionalFieldDifferences],
  )

  return {
    applyDifferences,
  }
}

export { useFindFormValueDifferences }
