import { FC, memo, ReactNode } from 'react'

import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography/Typography'
import { isInvestorDomain } from '@constants/system'
import { isString } from '@helpers/checkTypes'
import GoIn from '@icons/GoIn.svg'
import cn from 'classnames'

import styles from './LoginItem.module.scss'

interface ILoginItem {
  header: string | ReactNode
  onClick: () => void
  footer?: string | ReactNode
  dataTestId?: string
  iconButtonDataTestId?: string
  className?: string
}

const iconProps = {
  src: GoIn,
}

const LoginItem: FC<ILoginItem> = ({
  header,
  footer,
  onClick,
  className,
  dataTestId,
  iconButtonDataTestId,
}) => {
  return (
    <button
      type="button"
      data-testid={dataTestId}
      className={cn(styles.wrapper, className, {
        [styles.wrapper_secondary]: isInvestorDomain,
        [styles.wrapper_primary]: !isInvestorDomain,
      })}
      onClick={onClick}
    >
      <div className={styles.head}>
        <div className={styles.head__text}>
          {isString(header) ? (
            <Typography.Headline variant="headlineH3" className={styles.title}>
              {header}
            </Typography.Headline>
          ) : (
            header
          )}
        </div>
        <IconButton
          size="m"
          icon={iconProps}
          dataTestId={iconButtonDataTestId}
          className={styles.head__icon}
          view={isInvestorDomain ? 'tined' : 'gray'}
          geometry="round"
        />
      </div>

      {footer &&
        (isString(footer) ? (
          <Typography.Body variant="bodyMRegular" className={styles.footer}>
            {footer}
          </Typography.Body>
        ) : (
          footer
        ))}
    </button>
  )
}

export default memo(LoginItem)
