import React from 'react'
import { useFormContext } from 'react-hook-form'

import InitiatorSection from '@components/DocumentFormComponents/FormSections/InitiatorSection'

import { useNotificationOfIdentifiedNPAListErrorsManager } from '../../Manager'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '../../types'
import { NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher } from '../../watcher'

const Second = () => {
  const formInstance = useFormContext<NotificationOfIdentifiedNPAListErrorsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedNPAListErrorsManager()

  return (
    <InitiatorSection
      initiatorFullNameSuptitle={'Полное наименование'}
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'2'}
      formInstance={formInstance}
      watcher={NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
