import React, { FC, ReactNode } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import ExploitationFormItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CExploitationForm/ExploitationFormItem'
import { SeventhObjectExploitationObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface ExploitationItemProps {
  id: string
  formName: SeventhObjectExploitationObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  initialExpanded?: boolean
  indexOfBlock: number
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  indexOfExploitation: number
  lastIndexOfExploitation: number
  onDeleteExploitation: VoidFunction
  onMoveTop: () => Promise<void>
  onMoveBottom: () => Promise<void>
  leftAddons?: ReactNode
}

const ExploitationItem: FC<ExploitationItemProps> = ({
  id,
  blockViewIsValidating,
  editMode,
  formInstance,
  formModifierInstance,
  formName,
  indexOfBlock,
  indexOfExploitation,
  lastIndexOfExploitation,
  onDeleteExploitation,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  return (
    <ObjectStepWrapper
      disableBorder
      stepId={id}
      id={formName}
      leftAddons={leftAddons}
      headerNode={
        <Typography.Body variant={'bodyMSemibold'}>
          Ввод в эксплуатацию №{indexOfExploitation + 1}
        </Typography.Body>
      }
      additionalHeaderNode={
        editMode && (
          <FieldArraySwapper
            index={indexOfExploitation}
            lastIndex={lastIndexOfExploitation}
            onMoveTop={onMoveTop}
            onMoveBottom={onMoveBottom}
          />
        )
      }
      onRemoveObjectStep={editMode ? onDeleteExploitation : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <ExploitationFormItem
            formName={formName}
            blockViewIsValidating={blockViewIsValidating}
            formInstance={formInstance}
            formModifierInstance={formModifierInstance}
            indexOfBlock={indexOfBlock}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Border />
          </Col>
        </Row>
      </Stack>
    </ObjectStepWrapper>
  )
}

export default ExploitationItem
