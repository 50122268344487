import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { EighthMeasureObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea, {
  ControllerTextarea,
} from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import StepAnotherObjectAreasList from './StepAnotherObjectAreasList'
import StepAnotherObjectRegionsList from './StepAnotherObjectRegionsList'
import { anotherObjectValidationMap } from './validation'

interface StepAnotherObjectFormProps {
  formName: EighthMeasureObjectsArrayPathName
}

const StepAnotherObjectForm: FC<StepAnotherObjectFormProps> = ({ formName }) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isCompensationRenderCondition = useWatch({
    name: `${formName}.objectIsInfrastructure`,
    control: formInstance.control,
  })

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formModifierInstance.control,
  })

  const {
    getFormFieldControlProps,
    getInputProps,
    getTextareaProps,
    getSwitchProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectName`,
              formFieldTooltipProps: {
                onChange: () => setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControllerTextarea
              {...getTextareaProps({
                name: `${formName}.objectName`,
                rules: anotherObjectValidationMap.objectName,
                textareaProps: {
                  dataTestId: 'objectName',
                  label: 'Наименование',
                },
                onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectName`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
      <StepAnotherObjectRegionsList
        blockViewIsValidating={blockViewIsValidating}
        editMode={editMode}
        formInstance={formInstance}
        formModifierInstance={formModifierInstance}
        formName={formName}
      />
      <StepAnotherObjectAreasList
        editMode={editMode}
        blockViewIsValidating={blockViewIsValidating}
        formInstance={formInstance}
        formModifierInstance={formModifierInstance}
        formName={formName}
      />
      <Row>
        <FormFieldControl
          {...getFormFieldControlProps({
            path: `${formName}.objectConstructionKind`,
            formFieldTooltipProps: {
              onChange: () =>
                setTimeout(() => handleChangeValue?.(`${formName}.objectConstructionKind`), 0),
              onDifference: handleUpdateChanges,
            },
          })}
        >
          <ControlledTextarea
            {...getTextareaProps({
              name: `${formName}.objectConstructionKind`,
              rules: anotherObjectValidationMap.objectConstructionKind,
              textareaProps: {
                dataTestId: 'objectConstructionKind',
                label: 'Вид объекта капитального строительства',
                caption: 'например: здание, сооружение, строение',
              },
              onBlur: () =>
                setTimeout(() => {
                  handleChangeValue?.(`${formName}.objectConstructionKind`)
                }, 0),
              onChange: () =>
                setTimeout(() => {
                  debouncedHandleChangeValue?.(`${formName}.objectConstructionKind`)
                }, 0),
            })}
          />
        </FormFieldControl>
      </Row>
      <Row>
        <FormFieldControl
          {...getFormFieldControlProps({
            path: `${formName}.objectPurpose`,
            formFieldTooltipProps: {
              onChange: () => setTimeout(() => handleChangeValue?.(`${formName}.objectPurpose`), 0),
              onDifference: handleUpdateChanges,
            },
          })}
        >
          <ControlledTextarea
            {...getTextareaProps({
              name: `${formName}.objectPurpose`,
              rules: anotherObjectValidationMap.objectPurpose,
              textareaProps: {
                dataTestId: 'objectPurpose',
                label: 'Назначение объекта',
              },
              onBlur: () =>
                setTimeout(() => {
                  handleChangeValue?.(`${formName}.objectPurpose`)
                }, 0),
              onChange: () =>
                setTimeout(() => {
                  debouncedHandleChangeValue?.(`${formName}.objectPurpose`)
                }, 0),
            })}
          />
        </FormFieldControl>
      </Row>
      <Row>
        <Col xs={6}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectConstructionSquare`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.objectConstructionSquare`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectConstructionSquare`,
                rules: anotherObjectValidationMap.objectConstructionSquare,
                inputProps: {
                  dataTestId: 'objectConstructionSquare',
                  label: 'Площадь застройки, кв. м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectConstructionSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectConstructionSquare`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
        <Col xs={6}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectSquare`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.objectSquare`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectSquare`,
                rules: anotherObjectValidationMap.objectSquare,
                inputProps: {
                  dataTestId: 'objectSquare',
                  label: 'Площадь, кв.м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectSquare`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectNonLivingAreaSquare`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.objectNonLivingAreaSquare`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectNonLivingAreaSquare`,
                rules: anotherObjectValidationMap.objectNonLivingAreaSquare,
                inputProps: {
                  dataTestId: 'objectNonLivingAreaSquare',
                  label: 'Площадь нежилых помещений, кв. м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
        <Col xs={6}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectLivingAreaSquare`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.objectLivingAreaSquare`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectLivingAreaSquare`,
                rules: anotherObjectValidationMap.objectLivingAreaSquare,
                inputProps: {
                  dataTestId: 'objectLivingAreaSquare',
                  label: 'Площадь жилых помещений, кв. м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectFloor`,
              formFieldTooltipProps: {
                onChange: () => setTimeout(() => handleChangeValue?.(`${formName}.objectFloor`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: `${formName}.objectFloor`,
                rules: anotherObjectValidationMap.objectFloor,
                inputProps: {
                  dataTestId: 'objectFloor',
                  label: 'Количество этажей',
                  caption: 'если применимо',
                  suffix: '',
                  maxLength: 10,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectFloor`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectFloor`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
        <Col xs={6}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectUndergroundFloor`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.objectUndergroundFloor`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: `${formName}.objectUndergroundFloor`,
                rules: anotherObjectValidationMap.objectUndergroundFloor,
                inputProps: {
                  dataTestId: 'objectUndergroundFloor',
                  label: 'в том числе количество подземных',
                  caption: 'если применимо',
                  suffix: '',
                  maxLength: 10,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectUndergroundFloor`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectUndergroundFloor`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectHeight`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.objectHeight`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectHeight`,
                rules: anotherObjectValidationMap.objectHeight,
                inputProps: {
                  dataTestId: 'objectHeight',
                  label: 'Высота, м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectHeight`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectHeight`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${formName}.objectDetails`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.objectDetails`), 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: `${formName}.objectDetails`,
                rules: anotherObjectValidationMap.objectDetails,
                textareaProps: {
                  dataTestId: 'objectDetails',
                  label: 'Иные характеристики объекта',
                  caption: 'если применимо',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectDetails`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectDetails`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Stack direction={'vertical'} gap={2}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${formName}.objectIsInfrastructure`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.objectIsInfrastructure`), 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledSwitch
                {...getSwitchProps({
                  name: `${formName}.objectIsInfrastructure`,
                  switchProps: {
                    label: 'Является объектом инфраструктуры',
                    wrapperClassName: styles.form__switch,
                  },
                  onChange: () => handleChangeValue?.(`${formName}.objectIsInfrastructure`),
                })}
              />
            </FormFieldControl>
            <CollapseWrapper
              defaultExpanded={isCompensationRenderCondition}
              isExpanded={isCompensationRenderCondition}
            >
              <Stack direction={'vertical'} gap={3}>
                <Col xs={12}>
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: `${formName}.objectInfrastructureType`,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(
                            () => handleChangeValue?.(`${formName}.objectInfrastructureType`),
                            0,
                          ),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.name || '',
                              value: item.id,
                            }),

                            selectProps: {
                              inputProps: {
                                dataTestId: 'objectInfrastructureType',
                                label: 'Тип инфраструктуры',
                              },
                              onChangeFormValue: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${formName}.objectInfrastructureType`)
                                }),
                            },
                            controllerProps: {
                              name: `${formName}.objectInfrastructureType`,
                              rules: anotherObjectValidationMap.objectInfrastructureType,
                            },
                          })}
                        />
                      )
                    }}
                  </FormFieldControl>
                </Col>
              </Stack>
            </CollapseWrapper>
          </Stack>
        </Col>
      </Row>
    </Stack>
  )
}

export default StepAnotherObjectForm
