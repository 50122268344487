import { FieldValues } from 'react-hook-form'

import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { currentDateValidate, defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

type BeneficialOwnersInfoSectionValidation<T extends FieldValues> = FormValuesValidationSection<any, T>

const getBeneficialOwnersInfoSectionValidation = <T extends FieldValues>() => ({
  beneficiarName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  beneficiarBirthPlace: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 2000),
  },
  beneficiarBirthDate: {
    required: defaultRHFValidation.required,
    validate: {
      lengthValidate: (value) => isString(value) && lengthValidate(value, 10),
      currentValidation: (value) =>
        isString(value) &&
        currentDateValidate(value, 'дата рождения должна быть меньше текущей даты'),
    },
  },
  beneficiarNationality: {
    required: defaultRHFValidation.required,
  },
  beneficiarNationalityCountry: {
    required: defaultRHFValidation.required,
  },

  beneficiarDocument: {
    required: defaultRHFValidation.required,
  },
  beneficiarDocumentNumber: {
    required: defaultRHFValidation.required,
  },
  beneficiarDocumentDate: {
    required: defaultRHFValidation.required,
  },
  beneficiarDocumentOrganization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  beneficiarAddress: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 2000),
  },

  beneficiarMigrationNumber: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 20) : undefined),
  },

  beneficiarResidenceNumber: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 20) : undefined),
  },

  beneficiarPart: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 400) : undefined),
  },
  beneficiarReason: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  } as BeneficialOwnersInfoSectionValidation<T>
})

export { getBeneficialOwnersInfoSectionValidation }
