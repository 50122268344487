import { FC, memo, useCallback } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useExtendedFormModeByUserRoles } from '@components/DocumentFormComponents/hooks/useExtendedFormModeByUserRoles'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import InfrFacilitiesCostsExpectedReimbursed from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm'
import { mapOfInfrFacilitiesCostsExpectedReimbursedMenu } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import InfrFacilitiesCostsExpectedReimbursedLayoutWrapper from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Layout/Wrapper'
import { InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/watcher'
import { Switch } from '@components/NewDesign/Switch'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

const InfrFacilitiesCostsExpectedReimbursedLayout: FC<FormModalLayoutProps> = ({
  isOpen,
  onClose,
  formId,
  projectId,
  initialErrorsFromViolations,
  rolesOfUser,
  editMode,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const {
    currentDebugMode,
    handleChangeDebugMode,
    conditionToActivateDebugMode,
    disabledDebugMode,
  } = useExtendedFormModeByUserRoles(rolesOfUser)

  const handleOnClose = useCallback(() => {
    disabledDebugMode()
    InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher.resetAllState()

    onClose?.()
  }, [onClose, disabledDebugMode])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <InfrFacilitiesCostsExpectedReimbursed.Manager
        editMode={editMode}
        formId={formId}
        projectId={projectId}
        rolesOfUser={rolesOfUser}
        initialErrorsFromViolations={initialErrorsFromViolations}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
        onClose={handleOnClose}
      >
        <FlatMenuManager
          initialCurrentAnchorId={mapOfInfrFacilitiesCostsExpectedReimbursedMenu[0].id}
        >
          <InfrFacilitiesCostsExpectedReimbursedLayoutWrapper
            lastUpdateDraftTime={lastUpdateDraftTime}
          >
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Перечень объектов инфраструктуры, затраты на которые предполагается возместить
              </Typography.Headline>
              <InfrFacilitiesCostsExpectedReimbursed.StatementForm
                className={styles.layout__form}
              />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}>
              <InfrFacilitiesCostsExpectedReimbursed.Menu
                className={cn(styles.layout__menu, styles['layout__menu--nested'])}
                lastUpdateDraftTime={lastUpdateDraftTime}
              >
                {conditionToActivateDebugMode && (
                  <Switch
                    defaultState={currentDebugMode}
                    wrapperClassName={styles.layout__debug}
                    label={'Просмотр мета информации'}
                    onChange={handleChangeDebugMode}
                  />
                )}
              </InfrFacilitiesCostsExpectedReimbursed.Menu>
            </div>
          </InfrFacilitiesCostsExpectedReimbursedLayoutWrapper>
        </FlatMenuManager>
      </InfrFacilitiesCostsExpectedReimbursed.Manager>
    </DocumentFormLayout>
  )
}

export default memo(InfrFacilitiesCostsExpectedReimbursedLayout)
