import { useState } from 'react'

import { getMatchMedia, releaseMatchMedia } from '@hooks/new/breakpoints/utils'
import { useLayoutEffect } from '@hooks/new/collapse/utils'

export const useMatchMedia = (query: string, defaultValue: boolean | (() => boolean) = false) => {
  const [matches, setMatches] = useState(defaultValue)

  useLayoutEffect(() => {
    const mql = getMatchMedia(query)

    const handleMatchChange = () => setMatches(mql.matches)

    handleMatchChange()

    if (mql.addListener) {
      mql.addListener(handleMatchChange)
    } else {
      mql.addEventListener('change', handleMatchChange)
    }

    return () => {
      if (mql.removeListener) {
        mql.removeListener(handleMatchChange)
      } else {
        mql.removeEventListener('change', handleMatchChange)
      }

      releaseMatchMedia(query)
    }
  }, [query])

  return [matches]
}
