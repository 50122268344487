import React, { FC, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { UseFormReturn } from 'react-hook-form'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import { ControlledMultipleSelect, ControlledSingleSelect } from '@components/NewDesign/Select'
import Typography from '@components/NewDesign/Typography'
import FilterTooltip from '@components/PreparedTooltips/Filter'
import { useRegions } from '@hooks/new/swr/useRegions'
import {
  selectIndicatorValueOptions,
  selectStatusOptionsOfInvestorReports,
  selectStatusOptionsOfRegionalReports,
} from '@routes/Reports/Filters/const'
import type { ReportsFormValues } from '@routes/Reports/types'
import FiltersReportsService from '@routes/Reports/utils'
import DayjsService from '@services/Dayjs/Dayjs.service'
import {
  EnumOfStageReports,
  IGetReportsParams,
  mapOfTypeReports,
} from '@services/Monitoring/monitoring.entity'
import cloneDeep from 'clone-deep'

import styles from './Filters.module.scss'

interface ReportFiltersProps {
  filtersIsOpen: boolean

  formInstance: UseFormReturn<ReportsFormValues>
  onApplyFilters: (initialFilters?: IGetReportsParams) => void

  handleCloseTooltip: VoidFunction

  children: ReactElement

  isMER?: boolean
}

const { transformFormFiltersToParams } = FiltersReportsService

const ReportFilters: FC<ReportFiltersProps> = ({
  formInstance,
  filtersIsOpen,
  onApplyFilters,
  handleCloseTooltip,
  children,
  isMER,
}) => {
  const { control, watch } = formInstance

  const initialOpenFilters = useRef<ReportsFormValues | null>(null)

  const isInvestorReport = watch('typeOfReport') === mapOfTypeReports.investor
  const isFederal = watch('filters.isFederal')
  const isRegional = watch('filters.isRegional')

  const thirdRowTooltipRenderCondition = isMER && isInvestorReport

  const { regions } = useRegions({
    key: {
      _key: 'regions',
    },
  })

  const preparedRegionsForSelect = useMemo(() => {
    return regions
      ? Object.entries(regions).map(([value, displayValue]) => ({
          displayValue,
          value,
        }))
      : []
  }, [regions])

  //Имитация маунта TooltipContent
  useEffect(() => {
    if (filtersIsOpen) initialOpenFilters.current = cloneDeep(formInstance.getValues())
  }, [filtersIsOpen])

  const handleResetFilters = () => {
    const currentValues = formInstance.getValues()

    const resetValue = {
      typeOfReport: currentValues.typeOfReport,
      filters: {
        dateOfReport: String(
          DayjsService.dayjsWithFormatToMSK({
            year: new Date().getFullYear() - 1,
            day: 4,
            month: 7,
          }).year(),
        ),
        indicatorValue: '',
        stageOfReport: '' as EnumOfStageReports,
        isFederal: true,
        isRegional: true,
        regions: [],
        searchText: currentValues.filters.searchText,
      },
    }

    formInstance.reset(resetValue)

    handleApplyFilter(transformFormFiltersToParams(resetValue))
  }

  const handleApplyFilter = useCallback(
    (initialFilters?: IGetReportsParams) => {
      onApplyFilters(initialFilters)
      handleCloseTooltip()
    },
    [onApplyFilters, handleCloseTooltip],
  )

  const handleManualCloseTooltip = () => {
    //Делей на закрытие тултипа
    setTimeout(() => {
      if (!initialOpenFilters.current) return

      formInstance.setValue('filters', initialOpenFilters.current.filters)
      initialOpenFilters.current = null
    }, 80)
  }

  const tooltipContent = useMemo(
    () => (
      <>
        <div className={styles.filters__firstRow}>
          <ControlledCalendarInput
            control={control}
            name={'filters.dateOfReport'}
            calendarInputProps={{
              label: 'Отчётный год',
              inputMask: YEAR_MASK,
            }}
            calendarProps={{
              typeOfPicker: 'years',
              disabledDate: (date) => DayjsService.dayjs(date).year() < 2022,
              dateFormat: 'YYYY',
            }}
          />
          <div className={styles.filters__status}>
            <ControlledSingleSelect
              controllerProps={{
                name: 'filters.stageOfReport',
                control: control,
              }}
              inputProps={{
                label: 'Статус',
              }}
              options={
                isInvestorReport
                  ? selectStatusOptionsOfInvestorReports
                  : selectStatusOptionsOfRegionalReports
              }
            />
          </div>
        </div>
        <div className={styles.filters__secondRow}>
          {!isInvestorReport ? (
            <ControlledMultipleSelect
              closeOptionsAfterOnChange
              withContextSearch
              options={preparedRegionsForSelect}
              controllerProps={{
                control,
                name: 'filters.regions',
              }}
              inputProps={{
                label: 'Регион',
                fixWidth: true,
              }}
            />
          ) : (
            <>
              <Typography.Body color={'text-base-tertiary'} variant={'bodyMMedium'}>
                Тип соглашения
              </Typography.Body>
              <div className={styles.filters__checkboxes}>
                <ControlledCheckbox
                  control={formInstance.control}
                  name={'filters.isFederal'}
                  checkBoxProps={{
                    label: 'Федеральный',
                    disabled: !isRegional,
                  }}
                />
                <ControlledCheckbox
                  name={'filters.isRegional'}
                  control={formInstance.control}
                  checkBoxProps={{
                    label: 'Региональный',
                    disabled: !isFederal,
                  }}
                />
              </div>
            </>
          )}
        </div>
        {thirdRowTooltipRenderCondition && (
          <div className={styles.filters__thirdRow}>
            <ControlledSingleSelect
              options={selectIndicatorValueOptions}
              controllerProps={{
                name: 'filters.indicatorValue',
                control: control,
              }}
              inputProps={{
                label: 'Индикатор исполнения объема капиталовложений',
              }}
            />
          </div>
        )}
      </>
    ),
    [
      control,
      formInstance.control,
      isFederal,
      isInvestorReport,
      isRegional,
      thirdRowTooltipRenderCondition,
      preparedRegionsForSelect,
    ],
  )

  return (
    <FilterTooltip
      filterContent={tooltipContent}
      manualControl={{
        tooltipIsOpen: filtersIsOpen,
        handleCloseTooltip,
      }}
      onApplyFilters={handleApplyFilter}
      onResetFilters={handleResetFilters}
      onClose={handleManualCloseTooltip}
    >
      {children}
    </FilterTooltip>
  )
}

export default ReportFilters
