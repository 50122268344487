import React from 'react'
import { useFormContext } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import { secondSectionRegistrationCertificateValidationMap } from '@components/Forms/RegistrationCertificateForm/Forms/2/validation'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import { RegistrationCertificateFormValues } from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const Second = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { getSingleSelectProps, getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <AuthorizedOfficialGroup
          formInstance={formInstance}
          formSection={'2'}
          prefixFormFieldName={'initiator'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: registrationCertificateBlockValues['2'].initiatorHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () =>
                        handleChangeValue?.(
                          registrationCertificateBlockValues['2'].initiatorHeaderName,
                        ),
                    },
                    controllerProps: {
                      name: registrationCertificateBlockValues['2'].initiatorHeaderName,
                      rules: secondSectionRegistrationCertificateValidationMap.initiatorHeaderName,
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
      </Stack>
    </Container>
  )
}

export default Second
