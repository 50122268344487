import React, { memo } from 'react'

import FormModifierProviderWrapper from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import {
  defaultCertificateOfImplementationInvestprojectStagesFormModifierValues,
  defaultCertificateOfImplementationInvestprojectStagesFormValues,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/const'
import CertificateOfImplementationInvestprojectStagesLayout from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Layout/index'

const CertificateOfImplementationInvestprojectStagesLayoutWithFormProvider = (
  props: FormModalLayoutProps,
) => {
  return (
    <FormProviderWrapper
      defaultValues={defaultCertificateOfImplementationInvestprojectStagesFormValues}
    >
      <FormModifierProviderWrapper
        defaultValues={defaultCertificateOfImplementationInvestprojectStagesFormModifierValues}
      >
        <CertificateOfImplementationInvestprojectStagesLayout {...props} />
      </FormModifierProviderWrapper>
    </FormProviderWrapper>
  )
}

export default memo(CertificateOfImplementationInvestprojectStagesLayoutWithFormProvider)
