import { useCallback } from 'react'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generatePropertiesObjectForDataOnFulfillmentOfTermsSZPKForm } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/adapters/PropertiesObject.adapter'
import { generateRHFObjectForDataOnFulfillmentOfTermsSZPKForm } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/adapters/RHF.adapter'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'

import { generateMenuListForDataOnFulfillmentOfTermsSZPKMenuState } from './Menu.adapter'

const useDataOnFulfillmentOfTermsSZPKAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): DataOnFulfillmentOfTermsSZPKFormValues =>
      generateRHFObjectForDataOnFulfillmentOfTermsSZPKForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForDataOnFulfillmentOfTermsSZPKForm(objectFromPropertyState),
    [],
  )

  const generateMenuListByFormValues = useCallback(
    (
      formValues:
        | DataOnFulfillmentOfTermsSZPKFormValues
        | UnpackNestedValue<DataOnFulfillmentOfTermsSZPKFormValues>,
      initialHashMap: NestedMenuHashMap,
      formErrors: FieldErrors<DataOnFulfillmentOfTermsSZPKFormValues>,
    ): NestedMapOfMenu[] =>
      generateMenuListForDataOnFulfillmentOfTermsSZPKMenuState(
        formValues,
        initialHashMap,
        formErrors,
      ),
    [],
  )

  return { generateRHFObject, generatePropertiesObject, generateMenuListByFormValues }
}

export { useDataOnFulfillmentOfTermsSZPKAdapters }
