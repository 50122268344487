import React, { FC, memo } from 'react'

import Collapse from '@components/Collapse'
import Icon from '@components/Icon/Icon'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { objOfDateFormats } from '@constants/dateFormats'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import { useNpaTypes } from '@hooks/new/swr/useNpaTypes'
import { useRegions } from '@hooks/new/swr/useRegions'
import { useBooleanState } from '@hooks/useBooleanState'
import ChevronSmallIcon from '@icons/ChevronSmallIcon.svg'
import LocationIcon from '@icons/LocationIcon.svg'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { INpaPartVersion } from '@services/NPA/NPA.entity'
import cn from 'classnames'

import styles from './Redaction.module.scss'

interface RedactionProps {
  redaction: INpaPartVersion
  applyGreyMode?: boolean
}

const Redaction: FC<RedactionProps> = ({ redaction, applyGreyMode }) => {
  const { mapOfNpaTypes } = useNpaTypes({
    key: {
      _key: 'npaTypes',
    },
  })

  const { regions } = useRegions({
    key: {
      _key: 'regions',
    },
  })

  const { partClassificationInObjectMap } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  const { booleanState: complexNameIsCollapsed, reverseBooleanState: handleToggleComplexName } =
    useBooleanState(true)

  const preparedClassificationHeader =
    partClassificationInObjectMap?.get(redaction.classificationId)?.header || ''

  return (
    <div className={styles.redaction}>
      {redaction.npaInfo.region && regions && (
        <div className={styles.redaction__location}>
          <Icon
            noCurrentColorSvgFill
            src={LocationIcon}
            className={styles['redaction__location-icon']}
            size={'initial'}
          />
          <Typography
            color={'text-base-secondary'}
            variant={'bodyMMedium'}
            className={cn(styles['redaction__location-text'], {
              [styles.greyMode]: applyGreyMode,
            })}
          >
            {regions[redaction.npaInfo.region]}
          </Typography>
        </div>
      )}
      <Typography.Body
        variant={'bodyLMedium'}
        className={cn(styles.redaction__title, {
          [styles.greyMode]: applyGreyMode,
        })}
      >
        {mapOfNpaTypes?.[redaction.npaInfo.type]} {redaction.npaInfo.number} от{' '}
        {DayjsService.dayjs(redaction.npaInfo.date).format(objOfDateFormats.defaultFormat)}
      </Typography.Body>
      <Typography.Body
        variant={'bodyMRegular'}
        color={'text-base-secondary'}
        className={cn(styles.redaction__description, {
          [styles.greyMode]: applyGreyMode,
        })}
      >
        Ред. {redaction.npaInfo.redactionNumber} от{' '}
        {DayjsService.dayjs(redaction.npaInfo.redactionDate).format(objOfDateFormats.defaultFormat)}
      </Typography.Body>

      <div className={styles.redaction__complexName}>
        <Button
          className={styles.redaction__button}
          size={'xs'}
          view={'text'}
          trailingIcon={{
            className: cn(styles.redaction__chevron, {
              [styles['redaction__chevron--toggled']]: !complexNameIsCollapsed,
            }),
            size: 'initial',
            noCurrentColorSvgFill: true,
            src: ChevronSmallIcon,
          }}
          onClick={handleToggleComplexName}
        >
          {complexNameIsCollapsed ? 'Показать полное наименование' : 'Скрыть полное наименование'}
        </Button>

        <Collapse height={complexNameIsCollapsed ? 0 : 'auto'}>
          <Typography.Body
            variant={'bodyMRegular'}
            className={cn(styles['redaction__complexName-content'], {
              [styles.greyMode]: applyGreyMode,
            })}
          >
            {redaction.npaInfo.complexName}
          </Typography.Body>
        </Collapse>
      </div>

      <div className={styles.redaction__item}>
        <Typography.Body
          variant={'bodyLMedium'}
          className={cn({
            [styles.greyMode]: applyGreyMode,
          })}
        >
          {redaction.part}
        </Typography.Body>
        <Typography.Body
          variant={'bodyMRegular'}
          className={cn({
            [styles.greyMode]: applyGreyMode,
          })}
        >
          {preparedClassificationHeader}
        </Typography.Body>
      </div>
    </div>
  )
}

export default memo(Redaction)
