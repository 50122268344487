import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import ReasonGroup from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/10/AdditionalForms/ReasonGroup'
import { ninthSectionValidationMap } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/10/validation'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { reasonObjects } from './const'

const Tenth = () => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getTextareaProps, getCheckboxProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
    })

  const otherReason = useWatch({
    control: formInstance?.control,
    name: informationOfExecutionConditionsSZPKBlockValues['10'].otherReason,
  }) as boolean

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        {reasonObjects.map((reason) => (
          <ReasonGroup
            blockViewIsValidating={blockViewIsValidating}
            editMode={editMode}
            formInstance={formInstance}
            subscribableControl={subscribableControl}
            watcher={InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher}
            debouncedChangeValue={debouncedHandleChangeValue}
            checkboxLabel={reason.checkboxLabel}
            reasonDescriptionRules={reason.reasonDescriptionRules}
            reasonName={reason.reasonName}
            key={reason.id}
            onChangeValue={handleChangeValue}
          />
        ))}
        <Stack direction={'vertical'} gap={3}>
          <Row className={styles.form__checkboxRow}>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['10'].otherReason,
                })}
              >
                <ControlledCheckbox
                  {...getCheckboxProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['10'].otherReason,
                    checkBoxProps: {
                      label: 'Указать иное основание для изменения или расторжения соглашения',
                    },
                    onChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          informationOfExecutionConditionsSZPKBlockValues['10'].otherReason,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <CollapseWrapper isExpanded={otherReason} defaultExpanded={otherReason}>
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: informationOfExecutionConditionsSZPKBlockValues['10'].otherReasonName,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: informationOfExecutionConditionsSZPKBlockValues['10'].otherReasonName,
                        rules: ninthSectionValidationMap.otherReasonName,
                        textareaProps: {
                          label: 'Основание для изменения или расторжения соглашения',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              informationOfExecutionConditionsSZPKBlockValues['10'].otherReasonName,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              informationOfExecutionConditionsSZPKBlockValues['10'].otherReasonName,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: informationOfExecutionConditionsSZPKBlockValues['10']
                        .otherReasonDescription,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: informationOfExecutionConditionsSZPKBlockValues['10']
                          .otherReasonDescription,
                        rules: ninthSectionValidationMap.otherReasonDescription,
                        textareaProps: {
                          label: 'Описание основания',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              informationOfExecutionConditionsSZPKBlockValues['10']
                                .otherReasonDescription,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              informationOfExecutionConditionsSZPKBlockValues['10']
                                .otherReasonDescription,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </Stack>
          </CollapseWrapper>
        </Stack>
        <Row>
          <Col xs={12}>
            <Border />
          </Col>
        </Row>
        <Group
          disableBottomBorder
          title={'Предложения организации, в связи с наличием оснований для изменения соглашения'}
        >
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['10'].changeSuggestions,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['10'].changeSuggestions,
                    textareaProps: {
                      label: 'Предложения организации',
                    },
                    rules: ninthSectionValidationMap.changeSuggestions,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          informationOfExecutionConditionsSZPKBlockValues['10'].changeSuggestions,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          informationOfExecutionConditionsSZPKBlockValues['10'].changeSuggestions,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Group>
      </Stack>
    </Container>
  )
}

export default Tenth
