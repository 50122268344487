import { useCallback } from 'react'

import { generateFormModifierValuesForCertificateOfImplementationInvestprojectStagesForm } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/adapters/Modifier.adapter'
import { generatePropertiesObjectForCertificateOfImplementationInvestprojectStagesForm } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForCertificateOfImplementationInvestprojectStagesForm } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/adapters/RHF.adapter'
import {
  CertificateOfImplementationInvestprojectStagesFormModifierValues,
  CertificateOfImplementationInvestprojectStagesFormValues,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'

const useCertificateOfImplementationInvestprojectStagesAdapters = () => {
  const generateRHFObject = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): CertificateOfImplementationInvestprojectStagesFormValues =>
      generateRHFObjectForCertificateOfImplementationInvestprojectStagesForm(
        objectFromPropertyState,
      ),
    [],
  )

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForCertificateOfImplementationInvestprojectStagesForm(
      objectFromPropertyState,
    )

  const generateFormModifierValues = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): CertificateOfImplementationInvestprojectStagesFormModifierValues =>
      generateFormModifierValuesForCertificateOfImplementationInvestprojectStagesForm(
        objectFromPropertyState,
      ),
    [],
  )

  return { generateRHFObject, generatePropertiesObject, generateFormModifierValues }
}

export { useCertificateOfImplementationInvestprojectStagesAdapters }
