import type { FC } from 'react'

import cn from 'classnames'

import styles from './Skeleton.module.scss'

export interface SkeletonProps {
  /**
   * Флаг, явно задающий состояние, при котором контент закрывается прелоадером
   */
  visible?: boolean
  /**
   * Флаг явного включения анимации скелета
   */
  animate?: boolean
  /**
   * Дополнительный класс
   */
  className?: string

  dataTestId?: string
}

export const Skeleton: FC<SkeletonProps> = ({
  visible,
  animate = true,
  className,
  children,
  dataTestId,
}) => {
  if (visible) {
    return (
      <div
        data-testid={dataTestId}
        className={cn(styles.component, { [styles.animate]: animate }, className)}
      >
        {children}
      </div>
    )
  }

  return <>{children}</>
}
