import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationOfChangeDocuments from '@components/Forms/NotificationOfChangeDocumentsForm'
import { defaultNotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/const'
import { notificationOfChangeDocumentsMenuState } from '@components/Forms/NotificationOfChangeDocumentsForm/Menu/const'
import { NotificationOfChangeDocumentsFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfChangeDocumentsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NotificationOfChangeDocumentsLayoutWrapper from './Wrapper'

const NotificationOfChangeDocumentsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps({
    defaultValues: defaultNotificationOfChangeDocumentsFormValues,
  })

  const handleOnClose = () => {
    NotificationOfChangeDocumentsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationOfChangeDocuments.MenuManager
          initialMenuHash={notificationOfChangeDocumentsMenuState}
          initialSectionId={notificationOfChangeDocumentsMenuState[0].id}
        >
          <NotificationOfChangeDocuments.Manager
            formId={formId}
            projectId={projectId}
            initialErrorsFromViolations={initialErrorsFromViolations}
            editMode={editMode}
            onClose={handleOnClose}
          >
            <NotificationOfChangeDocumentsLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об изменении документов
                </Typography.Headline>
                <NotificationOfChangeDocuments.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}
              >
                <NotificationOfChangeDocuments.Menu className={styles.layout__menu} />
              </div>
            </NotificationOfChangeDocumentsLayoutWrapper>
          </NotificationOfChangeDocuments.Manager>
        </NotificationOfChangeDocuments.MenuManager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NotificationOfChangeDocumentsLayout)
