const organizationUserDetailsFormNames = {
  isAuthorizedPerson: 'isAuthorizedPerson',
  position: 'position',
  positionGenitive: 'positionGenitive',
  fioGenitive: 'fioGenitive',
  procuration: 'procuration',
  reasonProcurationId: 'reasonProcurationId',
  reasonDocument: 'reasonDocument',
  reasonProcurationNumber: 'reasonProcurationNumber',
  reasonProcurationEndDate: 'reasonProcurationEndDate',
} as const

const organizationUserDetailsProcurationFieldValues = {
  reasonProcurationId: 'reasonProcurationId',
  reasonDocument: 'reasonDocument',
} as const

export { organizationUserDetailsFormNames, organizationUserDetailsProcurationFieldValues }
