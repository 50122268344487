import { FC } from 'react'

import AttachmentAddonController from '@components/Attachments/Attachment/Addon'
import { AttachmentAPIProvider } from '@components/Attachments/Attachment/APIProvider'
import AttachmentFileItem from '@components/Attachments/Attachment/FileItem/AttachmentFileItem'
import AttachmentFolderItem from '@components/Attachments/Attachment/FolderItem/AttachmentFolderItem'
import AttachmentItemController from '@components/Attachments/Attachment/ItemController'
import AttachmentMultipleItem from '@components/Attachments/Attachment/MultipleItem/AttachmentMultipleItem'

import AttachmentController from './Controller'

interface AttachmentItemFC extends FC {
  Addon: typeof AttachmentAddonController
  Controller: typeof AttachmentController
  ItemController: typeof AttachmentItemController
  APIProvider: typeof AttachmentAPIProvider
  FileItem: typeof AttachmentFileItem
  FolderItem: typeof AttachmentFolderItem
  MultipleItem: typeof AttachmentMultipleItem
}

const AttachmentItem: AttachmentItemFC = () => null

AttachmentItem.Addon = AttachmentAddonController
AttachmentItem.Controller = AttachmentController
AttachmentItem.ItemController = AttachmentItemController
AttachmentItem.APIProvider = AttachmentAPIProvider
AttachmentItem.FileItem = AttachmentFileItem
AttachmentItem.FolderItem = AttachmentFolderItem
AttachmentItem.MultipleItem = AttachmentMultipleItem

export default AttachmentItem
