import React from 'react'

import CabinetBanner from './CabinetBanner'
import HeaderBanner from './HeaderBanner'
import HomeBanner from './HomeBanner'

type TechnicalWorkProps = React.FC & {
  HeaderBanner: typeof HeaderBanner
  HomeBanner: typeof HomeBanner
  CabinetBanner: typeof CabinetBanner
}

const TechnicalWork: TechnicalWorkProps = () => null

TechnicalWork.HeaderBanner = HeaderBanner
TechnicalWork.HomeBanner = HomeBanner
TechnicalWork.CabinetBanner = CabinetBanner

export default TechnicalWork
