import { VFC } from 'react'
import { useForm } from 'react-hook-form'

import Layouts from '@layouts'
import { formState, TableFormField } from '@routes/Audits/constants'

import styles from './Audits.module.scss'
import Table from './Table'

interface AuditsProps {
  className?: string
}

const Audits: VFC<AuditsProps> = ({ className }) => {
  const formInstance = useForm<TableFormField>({
    defaultValues: formState,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  return (
    <Layouts.Main>
      <div className={styles.page}>
        <Table formInstance={formInstance} />
      </div>
    </Layouts.Main>
  )
}

export default Audits
