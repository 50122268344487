export interface PasswordRowProps {
  text: string
  pattern?: RegExp
}

const defaultPasswordConditionConfig: PasswordRowProps[] = [
  {
    text: 'От 16 до 30 символов',
    pattern: /(^.{16,30})$/,
  },
  { text: 'Большие и маленькие латинские буквы', pattern: /(?=.*[a-z])(?=.*[A-Z])/g },
  { text: 'Цифры', pattern: /(?=.*[0-9])/g },
  {
    text: 'Символы (! @ # $ % ^ & *)',
    pattern: /(?=.*[!@#$%^&*])/g,
  },
]

export { defaultPasswordConditionConfig }
