import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ReactSVG } from 'react-svg'

import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Button from '@components/NewDesign/Button/Button'
import Input from '@components/NewDesign/Input'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography/Typography'
import Table from '@components/Table'
import PageContainer from '@containers/PageContainer'
import { usePreviousTrueValue } from '@hooks/usePreviousTrueValue'
import searchIcon from '@icons/action/search.svg'
import DropDownIcon from '@icons/navigation/arrow_drop_down.svg'
import { INITIAL_TABLE_STATE, useMapHook } from '@routes/Organizations/store'
import cn from 'classnames'

import styles from './Organizations.module.scss'

const filterOrganizationsByActions = Object.freeze({
  all: 'Все',
  blocked: 'Только заблокированные',
})

type TFilterActions = keyof typeof filterOrganizationsByActions

interface OrganizationFormValues {
  filterActions: TFilterActions
}

const Organizations = () => {
  const {
    columns,
    onlyBlocked,
    setOnlyBlockedToTrue,
    setOnlyBlockedToFalse,
    totalPage,
    organizations,
    totalElements,
    handleRowProps,
    handleChangeState,
    debouncedSearchString,
    isValidating,
    bindSearchInput,
    setSearchValue,
  } = useMapHook()

  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
  } = useManualTooltipControl()

  const { watch, setValue } = useForm<OrganizationFormValues>({
    defaultValues: {
      filterActions: 'all',
    },
  })

  const filterAction = watch('filterActions')

  const prev = usePreviousTrueValue(organizations)
  const prevTotalPage = usePreviousTrueValue(totalPage)

  const pageCount = (totalPage ?? (prevTotalPage || 0)) + 1

  const handleClearSearch = useCallback(() => setSearchValue(''), [setSearchValue])

  const handleSelectFilterAction = useCallback(
    (key: TFilterActions) => () => {
      handleCloseTooltip()

      if (!(key in filterOrganizationsByActions)) return

      setValue('filterActions', key)
    },
    [handleCloseTooltip, setValue],
  )

  const dependencyToTable = useMemo(
    () => [debouncedSearchString, onlyBlocked],
    [debouncedSearchString, onlyBlocked],
  )
  const noSearchFallbackRenderToTable = useMemo(() => {
    return (
      debouncedSearchString &&
      !isValidating &&
      !organizations?.length && (
        <SearchPlaceholder className={styles.placeholder}>
          {debouncedSearchString}
        </SearchPlaceholder>
      )
    )
  }, [debouncedSearchString, isValidating, organizations?.length])

  const tooltipContent = useMemo(
    () => (
      <div className={styles.tooltip}>
        {(Object.keys(filterOrganizationsByActions) ?? []).map((key) => (
          <button
            type="button"
            key={key}
            className={styles.tooltip__content}
            onClick={handleSelectFilterAction(key as TFilterActions)}
          >
            <Typography
              variant="bodyMMedium"
              color="text-accent-brand-normal"
              className={filterAction === key ? styles.active : ''}
            >
              {filterOrganizationsByActions[key]}
            </Typography>
          </button>
        ))}
      </div>
    ),
    [filterAction, handleSelectFilterAction],
  )

  useEffect(() => {
    if (filterAction === 'all') setOnlyBlockedToFalse()
    if (filterAction === 'blocked') setOnlyBlockedToTrue()
  }, [filterAction])

  return (
    <PageContainer>
      <div className={styles.heading}>
        <h2 className={styles.title}>Организации</h2>
        <div className={styles.filters__rightGroup}>
          <Tooltip
            useAnchorWidth
            trigger="click"
            open={tooltipIsOpen}
            position="bottom-end"
            content={tooltipContent}
            contentClassName={styles.filters__rightGroup__tooltipContent}
            popoverClassName={styles.filters__rightGroup__tooltipPopover}
            arrowClassName={styles.filters__rightGroup__tooltipArrow}
            offset={[0, 10]}
            onClose={handleCloseTooltip}
          >
            <Button
              className={styles.dropdown}
              view="plain"
              size="l"
              color="default"
              geometry="square"
              trailingIcon={{
                src: DropDownIcon,
                className: cn(styles.button__icon, {
                  [styles['button__icon--active']]: tooltipIsOpen,
                }),
              }}
              onClick={handleOpenTooltip}
            >
              Показывать: {filterOrganizationsByActions[filterAction]?.toLowerCase()}
            </Button>
          </Tooltip>
          <Input
            clear
            rootClassName={styles.search}
            placeholder={'Поиск'}
            size={'s'}
            view="secondary"
            beforeInput={<ReactSVG className={styles.searchIcon} src={searchIcon} />}
            onClear={handleClearSearch}
            {...bindSearchInput}
          />
        </div>
      </div>
      <Table
        manualSortBy
        autoResetPage
        manualPagination
        loading={isValidating}
        paginationClassName={styles.pagination}
        containerClassName={styles.container}
        columns={columns}
        pageCount={pageCount}
        className={styles.table}
        data={(organizations || prev) ?? []}
        totalElements={totalElements}
        initialState={INITIAL_TABLE_STATE}
        getRowProps={handleRowProps}
        noDataFallback={noSearchFallbackRenderToTable}
        resetDeps={dependencyToTable}
        onChangeState={handleChangeState}
      />
    </PageContainer>
  )
}

export default Organizations
