import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router'

import ReferenceBooks from '@components/ReferenceBooks'
import { DictionaryNameFromParams } from '@components/ReferenceBooks/constants'
import HierarchyList from '@components/ReferenceBooks/HierarchyType/List'
import ReferenceBooksHierarchyManager from '@components/ReferenceBooks/HierarchyType/Manager'
import { useReferenceBooksDynamicHeader } from '@components/ReferenceBooks/hooks/useReferenceBooksDynamicHeader'
import styles from '@components/ReferenceBooks/ReferenceBooks.module.scss'
import { useHierarchySectionSidebar } from '@components/Sidebars/ReferenceBooks/HierarchyType/Section'
import { useSWRConfig } from 'swr'

const MAIN_HEADER_HEIGHT = 64

interface ReferenceBooksHierarchyRootProps {
  titleText: string
}

const ReferenceBooksHierarchyRoot: FC<ReferenceBooksHierarchyRootProps> = ({ titleText }) => {
  const { mutate } = useSWRConfig()
  const { handleOpenSectionSidebar } = useHierarchySectionSidebar()

  const { dictionaryName } = useParams<DictionaryNameFromParams>()

  const { headerHeight, headerRef } = useReferenceBooksDynamicHeader([dictionaryName])

  const handleOpenDictionarySidebar = () => {
    if (!dictionaryName) return

    handleOpenSectionSidebar({
      dictionaryName,
      onSuccess: async (referenceBookItem) => {
        await mutate(
          {
            dictionaryName,
            _key: 'referenceBooks',
          },
          undefined,
          {
            revalidate: true,
          },
        )

        handleOpenSectionSidebar({
          initialSection: referenceBookItem,
          onSuccess: () => null,
          dictionaryName,
        })
      },
    })
  }

  const handleRevalidateOnSearchClear = useCallback(async () => {
    await mutate(
      {
        dictionaryName,
        _key: 'referenceBooks',
      },
      undefined,
      {
        revalidate: true,
      },
    )
  }, [dictionaryName, mutate])

  return (
    <ReferenceBooksHierarchyManager>
      <div className={styles.referenceBooks}>
        <header className={styles['referenceBooks__header-hierarchy']} ref={headerRef}>
          <ReferenceBooks.Header
            titleText={titleText}
            buttonProps={{
              children: 'Добавить раздел',
              onClick: handleOpenDictionarySidebar,
            }}
            onSearchClear={handleRevalidateOnSearchClear}
          />
        </header>
        <div
          className={styles.referenceBooks__body}
          style={{
            height: `calc(100vh - ${MAIN_HEADER_HEIGHT + headerHeight}px)`,
          }}
        >
          <HierarchyList />
        </div>
      </div>
    </ReferenceBooksHierarchyManager>
  )
}

export default ReferenceBooksHierarchyRoot
