import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import { useAAgreementIncludeRelatedTermsManager } from '@components/Forms/AAgreementIncludeRelatedTermsForm/Manager'
import { AAgreementIncludeRelatedTermsFormValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/types'

import {
  aAgreementIncludeRelatedTermsBlockValues,
  mapOfAAgreementIncludeRelatedTermsMenu,
} from '../const'
import AAgreementIncludeRelatedTermsForms from '../Forms'

const mapOfAAgreementIncludeRelatedTermsForms: FormItemProps = {
  '1': {
    node: <AAgreementIncludeRelatedTermsForms.First />,
  },
  '2': {
    node: <AAgreementIncludeRelatedTermsForms.Second />,
  },
  '3': {
    node: <AAgreementIncludeRelatedTermsForms.Third />,
  },
  '4': {
    node: <AAgreementIncludeRelatedTermsForms.Fourth />,
  },
} as const

const AAgreementIncludeRelatedTermsForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AAgreementIncludeRelatedTermsFormValues>()

  const {
    state: { formIsLoading },
  } = useAAgreementIncludeRelatedTermsManager()

  if (!formInstance) return null

  const isFederalSZPK = useWatch({
    name: aAgreementIncludeRelatedTermsBlockValues.additionalFields.isFederal,
    control: formInstance.control
  })

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAAgreementIncludeRelatedTermsForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    return readyForms
  }, [isFederalSZPK])

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={initialMenu ?? mapOfAAgreementIncludeRelatedTermsMenu}
      mapOfForms={currentForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(AAgreementIncludeRelatedTermsForm)
