import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const { getPropertyPropsFromPropertyState } = PropertiesPropsBasicAdapters

const generatePropertiesObjectForAAgreementOnCreationOtherEstateObjectsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedConstructionStages = (() => {
    return {
      propertyId: objectFromPropertyState.steps?.propertyId,
      lastUpdateDt: objectFromPropertyState.steps?.lastUpdateDt,
      type: objectFromPropertyState.steps?.type,
      value: objectFromPropertyState.steps?.value.map((step) => {
        const preparedConstructionObjects = (() => {
          const getPreparedObjectRegions = (objectRegions: any[]) => {
            if (!isArray(objectRegions) || !objectRegions.length) return []

            return objectRegions.map((objectRegion) => ({
              propertyId: objectRegion?.propertyId,
              lastUpdateDt: objectRegion?.lastUpdateDt,
              type: objectRegion?.type,
              value: {
                objectRegion: {
                  propertyId: objectRegion?.value.objectRegionCode?.propertyId,
                  lastUpdateDt: objectRegion?.value.objectRegionCode?.lastUpdateDt,
                  type: objectRegion?.value.objectRegionCode?.type,
                },
              },
            }))
          }

          const objects = step.value.objects?.value.filter((object) => {
            return (
              object.value.isMainObject?.value &&
              !object.value.isSubObject?.value &&
              !object.value.objectOtherActivityName?.value?.id
            )
          })

          const subObjects = step.value.objects?.value.filter((object) => {
            return (
              object.value.isSubObject?.value &&
              !object.value.objectOtherActivityName?.value?.id &&
              object.value.parentSubObject?.value
            )
          })

          const preparedConstructionObjects = objects?.map((object) => {
            const preparedSubObjects = subObjects
              .filter((subObject) => {
                return object.permanentId === subObject.value.parentSubObject?.value?.id
              })
              .map((subObject) => {
                return {
                  propertyId: subObject.propertyId,
                  type: subObject.type,
                  lastUpdateDt: subObject.lastUpdateDt,
                  value: {
                    objectName: {
                      propertyId: subObject.value.objectName?.propertyId,
                      type: subObject.value.objectName?.type,
                      lastUpdateDt: subObject.value.objectName?.lastUpdateDt,
                    },
                    objectRegions: {
                      propertyId: subObject.value.objectRegions?.propertyId,
                      type: subObject.value.objectRegions?.type,
                      lastUpdateDt: subObject.value.objectRegions?.lastUpdateDt,
                      value: getPreparedObjectRegions(subObject.value.objectRegions?.value),
                    },
                    objectConstructionKind: {
                      propertyId: subObject.value.objectConstructionKind?.propertyId,
                      type: subObject.value.objectConstructionKind?.type,
                      lastUpdateDt: subObject.value.objectConstructionKind?.lastUpdateDt,
                    },
                    objectPurpose: {
                      propertyId: subObject.value.objectPurpose?.propertyId,
                      type: subObject.value.objectPurpose?.type,
                      lastUpdateDt: subObject.value.objectPurpose?.lastUpdateDt,
                    },
                    objectConstructionSquare: {
                      propertyId: subObject.value.objectConstructionSquare?.propertyId,
                      type: subObject.value.objectConstructionSquare?.type,
                      lastUpdateDt: subObject.value.objectConstructionSquare?.lastUpdateDt,
                    },
                    objectSquare: {
                      propertyId: subObject.value.objectSquare?.propertyId,
                      type: subObject.value.objectSquare?.type,
                      lastUpdateDt: subObject.value.objectSquare?.lastUpdateDt,
                    },
                    objectNonLivingAreaSquare: {
                      propertyId: subObject.value.objectNonLivingAreaSquare?.propertyId,
                      type: subObject.value.objectNonLivingAreaSquare?.type,
                      lastUpdateDt: subObject.value.objectNonLivingAreaSquare?.lastUpdateDt,
                    },
                    objectLivingAreaSquare: {
                      propertyId: subObject.value.objectLivingAreaSquare?.propertyId,
                      type: subObject.value.objectLivingAreaSquare?.type,
                      lastUpdateDt: subObject.value.objectLivingAreaSquare?.lastUpdateDt,
                    },
                    objectFloor: {
                      propertyId: subObject.value.objectFloor?.propertyId,
                      type: subObject.value.objectFloor?.type,
                      lastUpdateDt: subObject.value.objectFloor?.lastUpdateDt,
                    },
                    objectUndergroundFloor: {
                      propertyId: subObject.value.objectUndergroundFloor?.propertyId,
                      type: subObject.value.objectUndergroundFloor?.type,
                      lastUpdateDt: subObject.value.objectUndergroundFloor?.lastUpdateDt,
                    },
                    objectHeight: {
                      propertyId: subObject.value.objectHeight?.propertyId,
                      type: subObject.value.objectHeight?.type,
                      lastUpdateDt: subObject.value.objectHeight?.lastUpdateDt,
                    },
                    objectDetails: {
                      propertyId: subObject.value.objectDetails?.propertyId,
                      type: subObject.value.objectDetails?.type,
                      lastUpdateDt: subObject.value.objectDetails?.lastUpdateDt,
                    },
                    objectIsInfrastructure: {
                      propertyId: subObject.value.isInfrastructure?.propertyId,
                      type: subObject.value.isInfrastructure?.type,
                      lastUpdateDt: subObject.value.isInfrastructure?.lastUpdateDt,
                    },
                    objectInfrastructureType: {
                      propertyId: subObject.value.infrastructureType?.propertyId,
                      type: subObject.value.infrastructureType?.type,
                      lastUpdateDt: subObject.value.infrastructureType?.lastUpdateDt,
                    },
                    oldIsCapitalObject: {
                      propertyId: subObject.value.oldIsCapitalObject?.propertyId,
                      type: subObject.value.oldIsCapitalObject?.type,
                      lastUpdateDt: subObject.value.oldIsCapitalObject?.lastUpdateDt,
                    },
                    oldCreatedDocument: {
                      propertyId: subObject.value.oldCreatedDocument?.propertyId,
                      type: subObject.value.oldCreatedDocument?.type,
                      lastUpdateDt: subObject.value.oldCreatedDocument?.lastUpdateDt,
                    },
                    oldCreatedOrganization: {
                      propertyId: subObject.value.oldCreatedOrganization?.propertyId,
                      type: subObject.value.oldCreatedOrganization?.type,
                      lastUpdateDt: subObject.value.oldCreatedOrganization?.lastUpdateDt,
                    },
                  },
                }
              })

            const preparedObjectAreas =
              isArray(object.value?.objectAreas?.value) && object.value?.objectAreas?.value?.length
                ? object.value?.objectAreas?.value.map((objectArea) => ({
                    propertyId: objectArea?.propertyId,
                    type: objectArea?.type,
                    lastUpdateDt: objectArea?.lastUpdateDt,
                    value: {
                      objectAreaNumber: {
                        propertyId: objectArea.value.objectAreaNumber?.propertyId,
                        type: objectArea.value.objectAreaNumber?.type,
                        lastUpdateDt: objectArea.value.objectAreaNumber?.lastUpdateDt,
                      },
                      objectAreaSquare: {
                        propertyId: objectArea.value.objectAreaSquare?.propertyId,
                        type: objectArea.value.objectAreaSquare?.type,
                        lastUpdateDt: objectArea.value.objectAreaSquare?.lastUpdateDt,
                      },
                    },
                  }))
                : []

            return {
              propertyId: object.propertyId,
              type: object.type,
              lastUpdateDt: object.lastUpdateDt,
              value: {
                objectName: {
                  propertyId: object.value.objectName?.propertyId,
                  type: object.value.objectName?.type,
                  lastUpdateDt: object.value.objectName?.lastUpdateDt,
                },
                objectRegions: {
                  propertyId: object.value.objectRegions?.propertyId,
                  type: object.value.objectRegions?.type,
                  lastUpdateDt: object.value.objectRegions?.lastUpdateDt,
                  value: getPreparedObjectRegions(object.value?.objectRegions?.value),
                },
                objectAreas: {
                  propertyId: object.value.objectAreas?.propertyId,
                  type: object.value.objectAreas?.type,
                  lastUpdateDt: object.value.objectAreas?.lastUpdateDt,
                  value: preparedObjectAreas,
                },
                objectConstructionKind: {
                  propertyId: object.value.objectConstructionKind?.propertyId,
                  type: object.value.objectConstructionKind?.type,
                  lastUpdateDt: object.value.objectConstructionKind?.lastUpdateDt,
                },
                objectPurpose: {
                  propertyId: object.value.objectPurpose?.propertyId,
                  type: object.value.objectPurpose?.type,
                  lastUpdateDt: object.value.objectPurpose?.lastUpdateDt,
                },
                objectConstructionSquare: {
                  propertyId: object.value.objectConstructionSquare?.propertyId,
                  type: object.value.objectConstructionSquare?.type,
                  lastUpdateDt: object.value.objectConstructionSquare?.lastUpdateDt,
                },
                objectSquare: {
                  propertyId: object.value.objectSquare?.propertyId,
                  type: object.value.objectSquare?.type,
                  lastUpdateDt: object.value.objectSquare?.lastUpdateDt,
                },
                objectNonLivingAreaSquare: {
                  propertyId: object.value.objectNonLivingAreaSquare?.propertyId,
                  type: object.value.objectNonLivingAreaSquare?.type,
                  lastUpdateDt: object.value.objectNonLivingAreaSquare?.lastUpdateDt,
                },
                objectLivingAreaSquare: {
                  propertyId: object.value.objectLivingAreaSquare?.propertyId,
                  type: object.value.objectLivingAreaSquare?.type,
                  lastUpdateDt: object.value.objectLivingAreaSquare?.lastUpdateDt,
                },
                objectFloor: {
                  propertyId: object.value.objectFloor?.propertyId,
                  type: object.value.objectFloor?.type,
                  lastUpdateDt: object.value.objectFloor?.lastUpdateDt,
                },
                objectUndergroundFloor: {
                  propertyId: object.value.objectUndergroundFloor?.propertyId,
                  type: object.value.objectUndergroundFloor?.type,
                  lastUpdateDt: object.value.objectUndergroundFloor?.lastUpdateDt,
                },
                objectHeight: {
                  propertyId: object.value.objectHeight?.propertyId,
                  type: object.value.objectHeight?.type,
                  lastUpdateDt: object.value.objectHeight?.lastUpdateDt,
                },
                objectDetails: {
                  propertyId: object.value.objectDetails?.propertyId,
                  type: object.value.objectDetails?.type,
                  lastUpdateDt: object.value.objectDetails?.lastUpdateDt,
                },
                objectIsInfrastructure: {
                  propertyId: object.value.isInfrastructure?.propertyId,
                  type: object.value.isInfrastructure?.type,
                  lastUpdateDt: object.value.isInfrastructure?.lastUpdateDt,
                },
                objectInfrastructureType: {
                  propertyId: object.value.infrastructureType?.propertyId,
                  type: object.value.infrastructureType?.type,
                  lastUpdateDt: object.value.infrastructureType?.lastUpdateDt,
                },
                oldIsCapitalObject: {
                  propertyId: object.value.oldIsCapitalObject?.propertyId,
                  type: object.value.oldIsCapitalObject?.type,
                  lastUpdateDt: object.value.oldIsCapitalObject?.lastUpdateDt,
                },
                oldCreatedDocument: {
                  propertyId: object.value.oldCreatedDocument?.propertyId,
                  type: object.value.oldCreatedDocument?.type,
                  lastUpdateDt: object.value.oldCreatedDocument?.lastUpdateDt,
                },
                oldCreatedOrganization: {
                  propertyId: object.value.oldCreatedOrganization?.propertyId,
                  type: object.value.oldCreatedOrganization?.type,
                  lastUpdateDt: object.value.oldCreatedOrganization?.lastUpdateDt,
                },
                subObjects: {
                  propertyId: step.value.objects?.propertyId,
                  type: step.value.objects?.type,
                  lastUpdateDt: step.value.objects?.lastUpdateDt,
                  value: preparedSubObjects,
                },
              },
            }
          })

          return {
            ...getPropertyPropsFromPropertyState(step.value.objects),
            value: preparedConstructionObjects,
          }
        })()

        const preparedPermissions = (() => {
          const preparedValueForPermissions =
            !step.value.permissions?.value || !step.value.permissions?.value.length
              ? []
              : step.value.permissions?.value.map((permission) => {
                  const preparedObjectNamePermissions = (() => {
                    if (
                      !permission.value.permissionObjects?.value ||
                      !permission.value.permissionObjects?.value.length
                    )
                      return []

                    return permission.value.permissionObjects?.value?.map((permissionObject) => ({
                      propertyId: permissionObject.propertyId,
                      lastUpdateDt: permissionObject.lastUpdateDt,
                      type: permissionObject.type,
                      value: {
                        objectOption: {
                          propertyId: permissionObject.propertyId,
                          lastUpdateDt: permissionObject.lastUpdateDt,
                          type: permissionObject.type,
                        },
                      },
                    }))
                  })()

                  return {
                    propertyId: permission.propertyId,
                    type: permission.type,
                    lastUpdateDt: permission.lastUpdateDt,
                    value: {
                      permissionKind: {
                        propertyId: permission.value.permissionKind?.propertyId,
                        type: permission.value.permissionKind?.type,
                        lastUpdateDt: permission.value.permissionKind?.lastUpdateDt,
                      },
                      permissionOrganization: {
                        propertyId: permission.value.permissionOrganization?.propertyId,
                        type: permission.value.permissionOrganization?.type,
                        lastUpdateDt: permission.value.permissionOrganization?.lastUpdateDt,
                      },
                      objectNamePermissions: {
                        propertyId: permission.value.permissionObjects?.propertyId,
                        type: permission.value.permissionObjects?.type,
                        lastUpdateDt: permission.value.permissionObjects?.lastUpdateDt,
                        value: preparedObjectNamePermissions,
                      },
                      isReceivedPermission: {
                        propertyId: permission.value.isReceivedPermission?.propertyId,
                        type: permission.value.isReceivedPermission?.type,
                        lastUpdateDt: permission.value.isReceivedPermission?.lastUpdateDt,
                      },
                      permissionDate: {
                        propertyId: permission.value.permissionDate?.propertyId,
                        type: permission.value.permissionDate?.type,
                        lastUpdateDt: permission.value.permissionDate?.lastUpdateDt,
                      },
                      permissionNumber: {
                        propertyId: permission.value.permissionNumber?.propertyId,
                        type: permission.value.permissionNumber?.type,
                        lastUpdateDt: permission.value.permissionNumber?.lastUpdateDt,
                      },
                      receivedPermissionDate: {
                        propertyId: permission.value.receivedPermissionDate?.propertyId,
                        type: permission.value.receivedPermissionDate?.type,
                        lastUpdateDt: permission.value.receivedPermissionDate?.lastUpdateDt,
                      },
                    },
                  }
                })

          return {
            ...getPropertyPropsFromPropertyState(step.value.permissions),
            value: preparedValueForPermissions,
          }
        })()

        const preparedExploitationObjects = (() => {
          const exploitationObjects = (() => {
            if (
              !step.value.commissionObjects?.value ||
              !step.value?.commissionObjects?.value.length
            )
              return []

            return step.value.commissionObjects.value.map((exploitation) => ({
              propertyId: exploitation.propertyId,
              lastUpdateDt: exploitation.lastUpdateDt,
              type: exploitation.type,
              value: {
                objectName: {
                  propertyId: exploitation.value.commissionObjectId?.propertyId,
                  lastUpdateDt: exploitation.value.commissionObjectId?.lastUpdateDt,
                  type: exploitation.value.commissionObjectId?.type,
                },
                objectCommissioningDate: {
                  propertyId: exploitation.value.commissionObjectDate?.propertyId,
                  lastUpdateDt: exploitation.value.commissionObjectDate?.lastUpdateDt,
                  type: exploitation.value.commissionObjectDate?.type,
                },
                objectPermissionCommissionNumber: {
                  propertyId: exploitation.value.commissionPermissionNumber?.propertyId,
                  lastUpdateDt: exploitation.value.commissionPermissionNumber?.lastUpdateDt,
                  type: exploitation.value.commissionPermissionNumber?.type,
                },
              },
            }))
          })()

          return {
            ...getPropertyPropsFromPropertyState(step.value.commissionObjects),
            value: exploitationObjects,
          }
        })()

        const preparedRegistrationObjects = (() => {
          const registrationObjects = (() => {
            if (
              !step.value.registrationObjects?.value ||
              !step.value.registrationObjects.value.length
            )
              return []

            return step.value.registrationObjects.value.map((registration) => ({
              propertyId: registration.propertyId,
              lastUpdateDt: registration.lastUpdateDt,
              type: registration.type,
              value: {
                objectName: {
                  propertyId: registration.value.registrationObjectId?.propertyId,
                  lastUpdateDt: registration.value.registrationObjectId?.lastUpdateDt,
                  type: registration.value.registrationObjectId?.type,
                },
                objectType: {
                  propertyId: registration.value.stateRegistrationType?.propertyId,
                  lastUpdateDt: registration.value.stateRegistrationType?.lastUpdateDt,
                  type: registration.value.stateRegistrationType?.type,
                },
                estateObjectStateRegistrationDate: {
                  propertyId: registration.value.objectStateRegistrationDate?.propertyId,
                  lastUpdateDt: registration.value.objectStateRegistrationDate?.lastUpdateDt,
                  type: registration.value.objectStateRegistrationDate?.type,
                },
                estateObjectStateRegistrationNumber: {
                  propertyId: registration.value.objectStateRegistrationNumber?.propertyId,
                  lastUpdateDt: registration.value.objectStateRegistrationNumber?.lastUpdateDt,
                  type: registration.value.objectStateRegistrationNumber?.type,
                },
                estateObjectStateRegistrationOrganization: {
                  propertyId: registration.value.objectStateRegistrationOrganization?.propertyId,
                  lastUpdateDt:
                    registration.value.objectStateRegistrationOrganization?.lastUpdateDt,
                  type: registration.value.objectStateRegistrationOrganization?.type,
                },
              },
            }))
          })()

          return {
            ...getPropertyPropsFromPropertyState(step.value.registrationObjects),
            value: registrationObjects,
          }
        })()

        return {
          value: {
            stepNumber: {
              propertyId: step.value.stepNumber?.propertyId,
              type: step.value.stepNumber?.type,
              lastUpdateDt: step.value.stepNumber?.lastUpdateDt,
            },
            stepDescription: {
              propertyId: step.value.stepDescription?.propertyId,
              type: step.value.stepDescription?.type,
              lastUpdateDt: step.value.stepDescription?.lastUpdateDt,
            },
            stepCompensationValue: {
              propertyId: step.value.stepCompensationValue?.propertyId,
              type: step.value.stepCompensationValue?.type,
              lastUpdateDt: step.value.stepCompensationValue?.lastUpdateDt,
            },
            stepCompensationForm: {
              propertyId: step.value.stepCompensationForm?.propertyId,
              type: step.value.stepCompensationForm?.type,
              lastUpdateDt: step.value.stepCompensationForm?.lastUpdateDt,
            },
            stepEndDate: {
              propertyId: step.value.stepEndDate?.propertyId,
              type: step.value.stepEndDate?.type,
              lastUpdateDt: step.value.stepEndDate?.lastUpdateDt,
            },
            constructionObjects: {
              ...getPropertyPropsFromPropertyState(preparedConstructionObjects),
              value: preparedConstructionObjects.value,
            },
            permissionsObjects: {
              ...getPropertyPropsFromPropertyState(preparedPermissions),
              value: preparedPermissions.value,
            },

            exploitation: {
              propertyId: step.propertyId,
              lastUpdateDt: step.lastUpdateDt,
              type: step.type,
              value: {
                objectCommissioningEndDate: {
                  propertyId: step.value.objectCommissioningEndDate?.propertyId,
                  type: step.value.objectCommissioningEndDate?.type,
                  lastUpdateDt: step.value.objectCommissioningEndDate?.lastUpdateDt,
                },

                exploitationObjects: {
                  ...getPropertyPropsFromPropertyState(preparedExploitationObjects),
                  value: preparedExploitationObjects.value,
                },
              },
            },

            registration: {
              propertyId: step.propertyId,
              lastUpdateDt: step.lastUpdateDt,
              type: step.type,
              value: {
                typeObjectStateRegistration: {
                  propertyId: step.value.typeObjectStateRegistration?.propertyId,
                  type: step.value.typeObjectStateRegistration?.type,
                  lastUpdateDt: step.value.typeObjectStateRegistration?.lastUpdateDt,
                },
                objectStateRegistrationEndDate: {
                  propertyId: step.value.objectStateRegistrationEndDate?.propertyId,
                  type: step.value.objectStateRegistrationEndDate?.type,
                  lastUpdateDt: step.value.objectStateRegistrationEndDate?.lastUpdateDt,
                },

                registrationObjects: {
                  ...getPropertyPropsFromPropertyState(preparedRegistrationObjects),
                  value: preparedRegistrationObjects.value,
                },
              },
            },
          },
        }
      }),
    }
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      constructionStages: preparedConstructionStages,
    },
  }
}

export { generatePropertiesObjectForAAgreementOnCreationOtherEstateObjectsForm }
