import React, { useMemo } from 'react'
import { FieldValues, Path } from 'react-hook-form'

import { getInfoAboutValidation } from '@components/DocumentFormComponents/FormSections/InfoAboutDocumentSection/validation'
import { BaseSectionProps } from '@components/DocumentFormComponents/FormSections/types'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface InfoAboutDocumentSectionProps<T extends FieldValues> extends BaseSectionProps<T> {
  dateAndNumberRenderCondition?: boolean
}

const InfoAboutDocumentSection = <T extends FieldValues>({
  dateAndNumberRenderCondition = true,
  editMode,
  blockViewIsValidating,
  formInstance,
  formName,
  watcher,
  subscribableControl,
  onChangeValue,
  debouncedChangeValue,
}: InfoAboutDocumentSectionProps<T>) => {
  const { getSubscribableControlProps, getCalendarInputProps, getTextareaProps, getInputProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      watcher,
      subscribableControl,
    })

  const infoAboutSectionValidationMap = useMemo(() => getInfoAboutValidation<T>(), [])

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        {dateAndNumberRenderCondition && (
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.documentDateSign` as Path<T>,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${formName}.documentDateSign` as Path<T>,
                    calendarInputProps: {
                      label: 'Дата регистрации',
                      caption: 'не обязательно',
                      dataTestId: 'documentDateSign',
                    },
                    onBlur: () =>
                      setTimeout(
                        () => onChangeValue?.(`${formName}.documentDateSign` as Path<T>),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(
                        () => onChangeValue?.(`${formName}.documentDateSign` as Path<T>),
                        0,
                      ),
                    onInputChange: () =>
                      debouncedChangeValue?.(`${formName}.documentDateSign` as Path<T>),
                  })}
                />
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.documentNumber` as Path<T>,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.documentNumber` as Path<T>,
                    rules: infoAboutSectionValidationMap.documentNumber,
                    inputProps: {
                      label: 'Номер регистрации',
                      caption: 'не обязательно',
                      dataTestId: 'documentNumber',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        onChangeValue?.(`${formName}.documentNumber` as Path<T>)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedChangeValue?.(`${formName}.documentNumber` as Path<T>)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.documentReceivers` as Path<T>,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.documentReceivers` as Path<T>,
                  rules: infoAboutSectionValidationMap.documentReceivers,
                  textareaProps: {
                    label: 'Получатель документа',
                    caption: 'не обязательно',
                    dataTestId: 'documentReceivers',
                    leftAddons: (
                      <FormIconWithTooltip
                        tooltipContent={
                          'Если требуется, укажите наименование и адрес организации получателя документа. Начните указание адреса со слова "Адрес:". Если получателей несколько, укажите сведения для каждого'
                        }
                      />
                    ),
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      onChangeValue?.(`${formName}.documentReceivers` as Path<T>)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedChangeValue?.(`${formName}.documentReceivers` as Path<T>)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default InfoAboutDocumentSection
