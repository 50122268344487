import { IAttributeFromBackend } from '@services/Attribute/Attribute.entity'
import {
  DfoListItemWithDigitizingProcessFromAllProjects,
  IDfoListItemFromAllProjects,
  TShortDfoInfo,
} from '@services/Dfo/Dfo.entity'
import { TGetDfoTypes } from '@services/Dictionaries/dictionaries.entity'
import { IDocumentAction } from '@services/DocumentActions/DocumentActions.entity'

interface IProject {
  id: string
  name: string
  isDeleted: boolean
  isOldStatement: boolean

  isFederal: boolean
  isPaper: boolean
  organizationName: string
}

interface IProjectsCounter {
  projectCount: number
  dfoCount: number
}

interface ProjectWithoutDfosFromAllProjects {
  id: string
  name: string

  isOldStatement: boolean
  investmentSum: number
  isFederal: boolean

  szpkRegisteredDate: string
  szpkRegisteredNumber: string

  organization: {
    id: string
    name: string
  }
}

interface IProjectFromAllProjects extends ProjectWithoutDfosFromAllProjects {
  dfos: IDfoListItemFromAllProjects[]
  projectPage?: number
}

const ProjectTypes = {
  SZPK: 'SZPK',
  MONITORING_REGION: 'MONITORING_REGION',
  MONITORING_FEDERAL: 'MONITORING_FEDERAL',
  ORGANIZATION_INFO_CHANGES: 'ORGANIZATION_INFO_CHANGES',
} as const

type TProjectTypes = keyof typeof ProjectTypes

type TProjectTags = 'SZPK' | 'EXTRA' | 'MONITORING' | 'COST_RECOVERY'

const GroupType = {
  SZPK: 'SZPK',
  EXTRA: 'EXTRA',
  MONITORING: 'MONITORING',
  COST_RECOVERY: 'COST_RECOVERY',

  CLAIM_SUBSIDY_PERMIT: 'CLAIM_SUBSIDY_PERMIT',
  CLAIM_TAX_REFUND: 'CLAIM_TAX_REFUND',

  OTHER_DONATIONS: 'OTHER_DONATIONS',
} as const

type TGetAllProjects = IProjectFromAllProjects[]
type TGetAllProjectsCounter = IProjectsCounter

type TGetProjectAttributes = IAttributeFromBackend[]

type TGetProjectActions = IDocumentAction[]

type TPostProjectBody = {
  name: string
  isOldStatement: boolean
  type: TProjectTypes
  isFederal: boolean
}

type TPostOrganizationIngoChangesProjectBody = {
  name: string
  type: typeof ProjectTypes.ORGANIZATION_INFO_CHANGES
}

type TPostProjectResponse = {
  projectId: string
  dfos: TShortDfoInfo[]
}

interface PrepareProjectsToRenderProps {
  projects: TGetAllProjects
  dfoTypes: TGetDfoTypes
}

interface PreparedProjectToRender extends ProjectWithoutDfosFromAllProjects {
  dfos: DfoListItemWithDigitizingProcessFromAllProjects[]
  projectPage?: number
}

export type {
  IProject,
  IProjectFromAllProjects,
  PreparedProjectToRender,
  PrepareProjectsToRenderProps,
  TGetAllProjects,
  TGetAllProjectsCounter,
  TGetProjectActions,
  TGetProjectAttributes,
  TPostOrganizationIngoChangesProjectBody,
  TPostProjectBody,
  TPostProjectResponse,
  TProjectTags,
  TProjectTypes,
}
export { GroupType, ProjectTypes }
