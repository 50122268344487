import { RefObject } from 'react'

import axios, { AxiosError } from 'axios'

const isNull = (value: unknown): value is null => value === null

const isUndefined = (value: unknown): value is undefined => value === undefined

const isNullOrUndefined = (value: unknown): value is null | undefined =>
  isNull(value) || isUndefined(value)

const isString = (value: unknown): value is string => typeof value === 'string'

const isNumber = (value: unknown): value is number => typeof value === 'number'

const isDateObject = (value: unknown): value is Date => value instanceof Date

const isKey = (value: string) => /^\w*$/.test(value)

const isObjectType = (value: unknown) => typeof value === 'object'

const isObject = <T extends object>(value: unknown): value is T =>
  !isNullOrUndefined(value) && !Array.isArray(value) && isObjectType(value) && !isDateObject(value)

// eslint-disable-next-line @typescript-eslint/ban-types
const isEmptyObject = (value: unknown): value is {} => isObject(value) && !Object.keys(value).length

const isFunction = <T>(value: unknown): value is (props: T) => any => typeof value === 'function'

const isNumberFromString = (n: string) => {
  const number = parseFloat(n)
  return !isNaN(number) && isFinite(number)
}

const isBoolean = (value: unknown): value is boolean => {
  return typeof value === 'boolean'
}

const isArray = (value: unknown): value is [...any] => {
  return Array.isArray(value)
}

const isBlob = (value): value is Blob => {
  if (typeof Blob === 'undefined') {
    return false
  }

  return value instanceof Blob || toString.call(value) === '[object Blob]'
}

const isEmptyString = (value: unknown): value is '' => {
  return isString(value) && !value.length
}

const isNotEmptyString = (value: unknown): value is string => {
  return isString(value) && !!value.length
}

const isJsError = (value: unknown): value is Error => value instanceof Error

const isAxiosError = (value: unknown): value is AxiosError => axios.isAxiosError(value)

const isHexString = (value: unknown): value is string =>
  isString(value) && /^#([0-9A-Fa-f]+)$/.test(value)

const isStringNullOrUndefined = (value: unknown): value is 'null' | 'undefined' =>
  isString(value) && (value === 'null' || value === 'undefined')

const isReactRef = <T>(value: unknown): value is RefObject<T> =>
  value !== null && typeof value === 'object' && 'current' in value

export {
  isArray,
  isAxiosError,
  isBlob,
  isBoolean,
  isDateObject,
  isEmptyObject,
  isEmptyString,
  isFunction,
  isHexString,
  isJsError,
  isKey,
  isNotEmptyString,
  isNull,
  isNullOrUndefined,
  isNumber,
  isNumberFromString,
  isObject,
  isObjectType,
  isReactRef,
  isString,
  isStringNullOrUndefined,
  isUndefined,
}
