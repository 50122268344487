import { useAPIContext } from '@context/APIContext'
import type { ProcurationItem } from '@context/APIContext/hooks/useProcurationsApi/types'
import type { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useProcurations = ({ key, config }: SWRHookProps<ProcurationItem[] | undefined>) => {
  const {
    procurationsApi: { getProcurations },
  } = useAPIContext()

  const {
    data: procurations,
    error,
    mutate,
    isValidating: isLoadingProcurations,
  } = useSWRImmutable(key, getProcurations, config)

  return {
    procurations,
    error,
    mutate,
    isLoadingProcurations,
  }
}

export { useProcurations }
