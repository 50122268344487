import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useInitialCollapseStateList } from '@components/DocumentFormComponents/hooks/useInitialCollapseStateList'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import StageWithSwapper from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/StageWithSwapper'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isUndefined } from '@helpers/checkTypes'

import { SeventhStagesPathName } from './types'

const { isFormFieldError } = DocumentFormHelpers

const Seventh = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  const {
    state: { editMode },
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { fields: stages } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhStagesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
    SeventhStagesPathName
  >({
    control: formModifierInstance?.control,
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
    watcher:
      AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
  })

  const { initialCollapseState } = useInitialCollapseStateList(stages)

  const stagesError = formInstance.getFieldState(
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
  )?.error

  const handleAddStage = async () => {
    await handleAddItemToListWithOutValue?.(
      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
    )

    if (!stages.length) {
      formInstance.clearErrors(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
      )
    }
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
              })}
            >
              <FlipperList list={stages} gap={3} listIdName={'id'}>
                {stages.map((stage, index) => {
                  const currentInitState = !isUndefined(initialCollapseState[stage.id])
                    ? initialCollapseState[stage.id]
                    : true

                  return (
                    <StageWithSwapper
                      key={stage.id}
                      id={stage.id}
                      index={index}
                      lastIndex={stages.length - 1}
                      editMode={editMode}
                      initialAccordionState={currentInitState}
                      onRemoveItemFromList={handleRemoveItemFromList}
                      onUpElementInList={handleUpElementInList}
                      onDownElementInList={handleDownElementInList}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Col>
          {isFormFieldError(stagesError) && !stages.length && (
            <Row className={'px-1'}>
              <FormError rowClassName={'px-4'} colClassName={'px-3'}>
                {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
              </FormError>
            </Row>
          )}
        </Row>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddStage}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить этап
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Seventh
