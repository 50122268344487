import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAVolumeOfCapitalInvestmentsFormValues,
  OldStepInvestorCapitalSource,
} from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const oldStepInvestorCapitalSourcesSectionValidationMap: FormValuesValidationSection<
  keyof OldStepInvestorCapitalSource,
  AAVolumeOfCapitalInvestmentsFormValues
> = {
  oldStepInvestorCapitalSource: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { oldStepInvestorCapitalSourcesSectionValidationMap }
