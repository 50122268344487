import { Dispatch, SetStateAction } from 'react'
import { usePopupManager } from 'react-popup-manager'
import { useNavigate } from 'react-router-dom'

import { useSuccessModal } from '@components/NewDesignedModals/SuccessModal/manager'
import { successModalBodyTexts, successModalHeaderTexts } from '@constants/texts'
import { RolesTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { OrganizationForTransfers } from '@context/APIContext/hooks/useOrganizationApi'
import {
  FOUNDED_FOR_TRANSFERS,
  PAGE,
  PAGE_SIZE,
} from '@routes/Projects/shared/GetRightToSZPK/constants'
import { ExistingOptionsValues } from '@routes/Projects/shared/GetRightToSZPK/steps/SpecifyingSZPKData'
import { unstable_serialize, useSWRConfig } from 'swr'

import { ExistingErrors } from './GetRightsToSZPK'
import { IStepsValues } from './steps'

interface UseRequests {
  savedStepsValues: IStepsValues
  setStepIdx: Dispatch<SetStateAction<number>>
  setLoading: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<ExistingErrors | null>>
  closeHandler: VoidFunction
}

export const useRequests = ({
  savedStepsValues,
  setStepIdx,
  setError,
  setLoading,
  closeHandler,
}: UseRequests) => {
  const {
    organizationsApi: { getOrganizationsList },
    organizationApi: { postTransfer, getTransfers },
  } = useAPIContext()

  const navigate = useNavigate()

  const { cache } = useSWRConfig()

  const popupManager = usePopupManager()
  const { handleOpenSuccessModal } = useSuccessModal()

  const searchSZPKHandler = async () => {
    setLoading(true)

    try {
      const orgs = await getOrganizationsList({
        page: PAGE,
        size: PAGE_SIZE,
        type: RolesTypes.INVESTOR,
        searchFields: 'inn',
        searchString: savedStepsValues.inn,
      })

      if (!orgs.totalElements) return setError('notFound')

      const currentOrganization = orgs.organizations[0]

      cache.set(FOUNDED_FOR_TRANSFERS, currentOrganization)

      const transfersParams: OrganizationForTransfers = {
        organizationId: currentOrganization.id,
        orgReorganizationType: savedStepsValues.formOfReorganization as ExistingOptionsValues,
        page: PAGE,
        size: PAGE_SIZE,
      }

      const transfers = await getTransfers(transfersParams)

      if (!transfers.length) return setError('notFound')

      cache.set(
        unstable_serialize({
          _key: 'transfers',
          ...transfersParams,
        }),
        transfers,
      )

      setStepIdx((prev) => prev + 1)
    } catch (e) {
      setError('notFound')
    } finally {
      setLoading(false)
    }
  }

  const postSelectedTransfer = async () => {
    setLoading(true)

    try {
      const response = await postTransfer(savedStepsValues.transferId)

      const ODOToRedirect = response.headers.location
      if (ODOToRedirect) {
        navigate(ODOToRedirect)

        cache.delete(FOUNDED_FOR_TRANSFERS)
        return
      }

      //TODO: Обрабатывать response по другому

      popupManager.closeAll()
      handleOpenSuccessModal({
        headerText: successModalHeaderTexts.getRightsSZPK,
        text: successModalBodyTexts.getRightsSZPK,
        customActions: {
          mergeDefault: false,
          actions: [
            {
              dataTestId: 'SuccessModal-close-button',
              bindOnClose: true,
              children: 'Понятно',
              fixWidth: true,
              onClick: closeHandler,
            },
          ],
        },
        modalActionsProps: {
          disableOnClose: true,
        },
      })
    } catch {
      closeHandler()
    } finally {
      setLoading(false)
    }
  }

  return { searchSZPKHandler, postSelectedTransfer }
}
