import React, { FC, memo, useCallback } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import CertificateOfImplementationInvestprojectStages from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm'
import { mapOfCertificateOfImplementationInvestprojectStagesMenu } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/const'
import { CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/watcher'
import Typography from '@components/NewDesign/Typography'

import CertificateOfImplementationInvestprojectStagesLayoutWrapper from './Wrapper'

const CertificateOfImplementationInvestprojectStagesLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  rolesOfUser,
  initialErrorsFromViolations,
  editMode,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const handleOnClose = useCallback(() => {
    CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher.resetAllState()

    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <CertificateOfImplementationInvestprojectStages.Manager
        formId={formId}
        projectId={projectId}
        rolesOfUser={rolesOfUser}
        initialErrorsFromViolations={initialErrorsFromViolations}
        editMode={editMode}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
        onClose={handleOnClose}
      >
        <FlatMenuManager
          initialCurrentAnchorId={mapOfCertificateOfImplementationInvestprojectStagesMenu[0].id}
        >
          <CertificateOfImplementationInvestprojectStagesLayoutWrapper
            lastUpdateDraftTime={lastUpdateDraftTime}
          >
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Справка о реализации этапов инвестпроекта
              </Typography.Headline>
              <CertificateOfImplementationInvestprojectStages.StatementForm
                className={styles.layout__form}
              />
            </div>
            <div className={styles['layout__menu-wrapper']}>
              <CertificateOfImplementationInvestprojectStages.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </CertificateOfImplementationInvestprojectStagesLayoutWrapper>
        </FlatMenuManager>
      </CertificateOfImplementationInvestprojectStages.Manager>
    </DocumentFormLayout>
  )
}

export default memo(CertificateOfImplementationInvestprojectStagesLayout)
