import { usePopupManager } from 'react-popup-manager'

import StatusSignModal from '@components/NewDesignedModals/StatusSignModal'

import type { HandleOpenStatusSignModalProps } from './types'

const useStatusSignModal = () => {
  const popupManager = usePopupManager()

  const handleOpenStatusSignModal = ({ onClose, ...rest }: HandleOpenStatusSignModalProps) => {
    popupManager.open(StatusSignModal, {
      ...rest,
      onClose: onClose ?? (() => null),
    })
  }

  return {
    handleOpenStatusSignModal,
  }
}

export default useStatusSignModal
