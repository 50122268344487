import { FC, memo } from 'react'

import { mockKnowledgeBase } from '@components/KnowledgeBase/__mock__'
import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import type { IKnowledgeBaseItem } from '@components/KnowledgeBase/types'
import Button from '@components/NewDesign/Button'
import { isEmptyString } from '@helpers/checkTypes'
import forwardArrowIcon from '@icons/navigation/arrow_forward.svg'
import cn from 'classnames'

import styles from './RootList.module.scss'

interface KnowledgeBaseMobileRootListProps {
  selectedRootItem: IKnowledgeBaseItem | null
  onSelectBaseItem: (baseItem: IKnowledgeBaseItem) => void
}

const KnowledgeBaseMobileRootList: FC<KnowledgeBaseMobileRootListProps> = ({
  selectedRootItem,
  onSelectBaseItem,
}) => {
  const {
    handlers: { handleChooseBaseItem },
  } = useKnowledgeBase()

  const handleSelectBaseItem = (baseItem: IKnowledgeBaseItem) => {
    window.scrollTo(0, 0)

    const childWithMarkdown = baseItem.children.find(({ markdown }) => !isEmptyString(markdown))

    handleChooseBaseItem?.(childWithMarkdown || baseItem)
    onSelectBaseItem(baseItem)
  }

  return (
    <ul className={styles.list}>
      {mockKnowledgeBase.map((item) => {
        const isSelectedItem = selectedRootItem?.id === item.id

        return (
          <li key={item.id} className={styles.item}>
            <Button
              fixWidth
              geometry="square"
              view="text"
              size="xs"
              color="default"
              variant="buttonSMedium"
              className={styles.item__button}
              textClassName={cn(styles['item__button-text'], {
                [styles['item__button-text--active']]: isSelectedItem,
              })}
              trailingIcon={{
                src: forwardArrowIcon,
                className: styles['item__button-icon'],
              }}
              onClick={() => handleSelectBaseItem(item)}
            >
              {item.name}
            </Button>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(KnowledgeBaseMobileRootList)
