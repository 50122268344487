import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  InformationOfExecutionConditionsSZPKFormValues,
  StateSupportObject,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const stateSupportsValidationMap: FormValuesValidationSection<
  keyof StateSupportObject,
  InformationOfExecutionConditionsSZPKFormValues
> = {
  stateSupportName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 200),
  },
  stateSupportReason: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  stateSupportValue: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { stateSupportsValidationMap }
