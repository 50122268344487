import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

import { NotificationOfIdentifiedNPAListErrorsFormValues } from '../../types'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof NotificationOfIdentifiedNPAListErrorsFormValues['4'],
  NotificationOfIdentifiedNPAListErrorsFormValues
> = {
  ruleLinkNpaReturn: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 50),
  }
}

export { fourthSectionValidationMap }
