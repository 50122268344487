import React from 'react'
import { useFormContext } from 'react-hook-form'

import InfoAboutDocumentSection from '@components/DocumentFormComponents/FormSections/InfoAboutDocumentSection'

import { useNotificationOfIdentifiedNPAListErrorsManager } from '../../Manager'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '../../types'
import { NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher } from '../../watcher'

const First = () => {
  const formInstance = useFormContext<NotificationOfIdentifiedNPAListErrorsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedNPAListErrorsManager()

  return (
    <InfoAboutDocumentSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default First
