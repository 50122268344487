import { useCallback, useState } from 'react'

import { jwtDecodeFacade } from '@context/AuthContext/helpers/jwtDecodeFacade'
import { StorageFields } from '@context/AuthContext/models'
import { UserInfoState } from '@context/AuthContext/types'
import { makeFirstLetterInUC } from '@helpers/string/makeFirstLetterInUC'
import storage from 'store2'

export const useUserInfo = (initialState: UserInfoState) => {
  const [userInfoState, setUserInfoState] = useState<UserInfoState | null>(initialState)

  const saveCertificateData = useCallback(() => {
    try {
      const { name, sub, email, resource_access, realm_access } = jwtDecodeFacade.get({
        name: ['id', 'sub', 'name', 'email', 'resource_access', 'realm_access'],
        manyFields: true,
      })

      const upperNameFirstLetters = makeFirstLetterInUC(name, ' ')

      storage.set(StorageFields.userInfo, {
        name: upperNameFirstLetters,
        email,
        resource_access,
        realm_access,
        sub,
      })

      setUserInfoState({
        name: upperNameFirstLetters,
        email,
        resource_access,
        sub,
        realm_access,
      })
    } catch {}
  }, [])

  return { userInfoState, setUserInfoState, saveCertificateData }
}
