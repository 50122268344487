import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import ApplicationConclusionAdditionalAgreementForm from '@components/Forms/ApplicationConclusionAdditionalAgreementForm'
import {
  defaultApplicationConclusionAdditionalAgreementFormValues,
  mapOfApplicationConclusionAdditionalAgreementFormMenu,
} from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/const'
import { ApplicationConclusionAdditionalAgreementFormFieldsControlUpdateWatcher } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/watcher'
import Typography from '@components/NewDesign/Typography'

import ApplicationConclusionAdditionalAgreementFormLayoutWrapper from './Wrapper'

const ApplicationConclusionAdditionalAgreementFormLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps({
    defaultValues: defaultApplicationConclusionAdditionalAgreementFormValues,
  })

  const handleOnClose = () => {
    ApplicationConclusionAdditionalAgreementFormFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <ApplicationConclusionAdditionalAgreementForm.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfApplicationConclusionAdditionalAgreementFormMenu[0].id}
          >
            <ApplicationConclusionAdditionalAgreementFormLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Заявление о заключении дополнительного соглашения
                </Typography.Headline>
                <ApplicationConclusionAdditionalAgreementForm.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <ApplicationConclusionAdditionalAgreementForm.Menu
                  className={styles.layout__menu}
                />
              </div>
            </ApplicationConclusionAdditionalAgreementFormLayoutWrapper>
          </FlatMenuManager>
        </ApplicationConclusionAdditionalAgreementForm.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(ApplicationConclusionAdditionalAgreementFormLayout)
