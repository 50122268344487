import { createContext, FC, useCallback, useContext, useMemo } from 'react'
import { useParams } from 'react-router'

import { useAPIContext } from '@context/APIContext'
import { useBooleanState } from '@hooks/useBooleanState'
import { useQueryManager } from '@hooks/useQueryManager'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import {
  HandleChangeWebFormFileNameProps,
  HandleChangeWebFormFileNameReturnType,
  HandleCreateWebFormDocument,
  HandleDeleteWebFormDocument,
} from './types'

interface WebFormAPIContextProps {
  state: {
    webFormIsLoading: boolean
  }
  handlers: {
    handleCreateWebFormDocument?: (props: HandleCreateWebFormDocument) => Promise<void>
    handleDeleteWebFormDocument?: (props: HandleDeleteWebFormDocument) => Promise<void>
    handleChangeWebFormFileName?: (props: HandleChangeWebFormFileNameProps) => Promise<void>
  }
}

const initialWebFormApiContext: WebFormAPIContextProps = {
  state: {
    webFormIsLoading: false,
  },
  handlers: {},
}

const WebFormAPIContext = createContext<WebFormAPIContextProps>(initialWebFormApiContext)

const WebFormAPIProvider: FC = ({ children }) => {
  const {
    webFormApi: { createFormDocument, deleteFormDocument },
    documentsApi: { changeDocumentAttribute },
  } = useAPIContext()

  const { projectId } = useParams()

  const { queryUtils } = useQueryManager()

  const dfoId = queryUtils.getQuery('dfoId') || ''

  const {
    booleanState: webFormIsLoading,
    setBooleanStateToTrue: enableWebFormLoading,
    setBooleanStateToFalse: disableWebFormLoading,
  } = useBooleanState()

  const defaultWebFormRequestDecorator = useCallback(
    async (fetcher: () => Promise<void>) => {
      enableWebFormLoading()

      try {
        return await fetcher()
      } catch (error) {
        throw error
      } finally {
        disableWebFormLoading()
      }
    },
    [disableWebFormLoading, enableWebFormLoading],
  )

  const handleCreateWebFormDocument = useCallback(
    async ({ documentType, documentSetId, onSuccess }: HandleCreateWebFormDocument) => {
      return await defaultWebFormRequestDecorator(async () => {
        await createFormDocument({
          documentSetId,
          documentType,
        })

        await onSuccess?.()
      })
    },
    [createFormDocument, defaultWebFormRequestDecorator],
  )

  const handleDeleteWebFormDocument = useCallback(
    async ({ formId, onSuccess }: HandleDeleteWebFormDocument) => {
      return await defaultWebFormRequestDecorator(async () => {
        await deleteFormDocument({
          formId,
        })

        await onSuccess?.()
      })
    },
    [defaultWebFormRequestDecorator, deleteFormDocument],
  )

  const handleChangeWebFormFileName = useCallback(
    async ({
      documentSetId,
      fileId,
      name,
    }: HandleChangeWebFormFileNameProps): HandleChangeWebFormFileNameReturnType => {
      if (!projectId || !dfoId || !name) return

      try {
        await changeDocumentAttribute?.({
          documentId: fileId,
          projectId,
          dfoId,
          documentSetId,
          name,
        })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'WebFormAPIProvider',
            componentType: 'handleChangeWebFormFileName',
            moduleName: 'WebFormAPIProvider',
          },
          additionInfo: {
            documentSetId,
            fileId,
            name,
          },
        })(error)

        throw error
      }
    },
    [changeDocumentAttribute, dfoId, projectId],
  )

  const webFormProviderValue: WebFormAPIContextProps = useMemo(
    () => ({
      state: {
        webFormIsLoading,
      },
      handlers: {
        handleCreateWebFormDocument,
        handleDeleteWebFormDocument,
        handleChangeWebFormFileName,
      },
    }),
    [
      handleChangeWebFormFileName,
      handleCreateWebFormDocument,
      handleDeleteWebFormDocument,
      webFormIsLoading,
    ],
  )

  return (
    <WebFormAPIContext.Provider value={webFormProviderValue}>{children}</WebFormAPIContext.Provider>
  )
}

const useWebFormAPIContext = () => {
  return useContext(WebFormAPIContext)
}

export { useWebFormAPIContext, WebFormAPIProvider }
