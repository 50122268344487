import React from 'react'
import { useFormContext } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAConnectionReorganizationImplementingBlockValues } from '@components/Forms/AAConnectionReorganizationImplementingForm/const'
import { fifthSectionValidationMap } from '@components/Forms/AAConnectionReorganizationImplementingForm/Forms/4/validation'
import { useAAConnectionReorganizationImplementingManager } from '@components/Forms/AAConnectionReorganizationImplementingForm/Manager'
import { AAConnectionReorganizationImplementingFormValues } from '@components/Forms/AAConnectionReorganizationImplementingForm/types'
import { AAConnectionReorganizationImplementingFieldsControlUpdateWatcher } from '@components/Forms/AAConnectionReorganizationImplementingForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<AAConnectionReorganizationImplementingFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAConnectionReorganizationImplementingManager()

  const { getSubscribableControlProps, getControllerProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAConnectionReorganizationImplementingFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAConnectionReorganizationImplementingBlockValues['4']
                  .investorPartnerFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                controllerProps={getControllerProps({
                  name: aAConnectionReorganizationImplementingBlockValues['4']
                    .investorPartnerFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAConnectionReorganizationImplementingBlockValues['4'].investorPartnerInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: aAConnectionReorganizationImplementingBlockValues['4'].investorPartnerInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAConnectionReorganizationImplementingBlockValues['4'].investorPartnerOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: aAConnectionReorganizationImplementingBlockValues['4'].investorPartnerOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={42}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAConnectionReorganizationImplementingBlockValues['4']
                  .investorPartnerEgrulAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Адрес'}
                controllerProps={getControllerProps({
                  name: aAConnectionReorganizationImplementingBlockValues['4']
                    .investorPartnerEgrulAddress,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное лицо организации'}
          formInstance={formInstance}
          formSection={'4'}
          prefixFormFieldName={'investorPartner'}
        >
          <SubscribableControl
            watcher={AAConnectionReorganizationImplementingFieldsControlUpdateWatcher}
            path={aAConnectionReorganizationImplementingBlockValues['4'].investorPartnerHeaderName}
            {...subscribableControl}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: aAConnectionReorganizationImplementingBlockValues['4']
                        .investorPartnerHeaderName,
                      rules: fifthSectionValidationMap.investorPartnerHeaderName,
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            aAConnectionReorganizationImplementingBlockValues['4']
                              .investorPartnerHeaderName,
                          )
                        }, 0),
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
      </Stack>
    </Container>
  )
}

export default Fourth
