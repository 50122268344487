import React from 'react'
import { useFormContext } from 'react-hook-form'

import SubjectsSection from '@components/DocumentFormComponents/FormSections/SubjectsSection'
import { aAChangingVolumeReimbursementBlockValues } from '@components/Forms/AAChangingVolumeReimbursementForm/const'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import {
  AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher,
  AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
} from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'

const Two = () => {
  const formInstance = useFormContext<AAChangingVolumeReimbursementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAChangingVolumeReimbursementManager()

  if (!formInstance) return null

  return (
    <SubjectsSection
      formName={aAChangingVolumeReimbursementBlockValues['2']['subjects']}
      formInstance={formInstance}
      blockViewIsValidating={blockViewIsValidating}
      editMode={editMode}
      watcher={AAChangingVolumeReimbursementFieldsControlUpdateWatcher}
      fieldArrayWatcher={AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Two
