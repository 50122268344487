import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { SummaryReportOfMonitoringResultsRegionReportInfoArrayPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/4/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import { SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface SummaryReportOfMonitoringResultsEconomicSphereOkvedItemProps {
  blockViewIsValidating: boolean
  editMode: boolean
  name: SummaryReportOfMonitoringResultsRegionReportInfoArrayPathName
  formInstance: UseFormReturn<SummaryReportOfMonitoringResultsFormValues>
}

const SummaryReportOfMonitoringResultsEconomicSphereOkvedRegionItem: FC<
  SummaryReportOfMonitoringResultsEconomicSphereOkvedItemProps
> = ({ blockViewIsValidating, editMode, name, formInstance }) => {
  const {
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const totalCapitalDeviation = useWatch({
    name: `${name}.totalCapitalDeviation`,
    control: formInstance?.control,
  })

  const investorCapitalDeviation = useWatch({
    name: `${name}.investorCapitalDeviation`,
    control: formInstance?.control,
  })

  const workPlacesCapitalDeviation = useWatch({
    name: `${name}.workPlacesCapitalDeviation`,
    control: formInstance?.control,
  })

  const { getSubscribableControlProps, getAmountInputProps, getControllerProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
    })

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.regionName`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Наименование региона'}
              controllerProps={getControllerProps({
                name: `${name}.regionName`,
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Group disableBottomBorder title={'Объем капитальных вложений'}>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Объем по региональному отчету'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.totalCapital`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.totalCapital`,
                  inputProps: {
                    disabled: true,
                    leftAddons: <FormIconWithTooltip tooltipContent={totalCapitalDeviation} />,
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Объем по справкам проверяющей организации'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.totalCapitalChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.totalCapitalChecker`,
                  inputProps: {
                    disabled: true,
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
        </Stack>
      </Group>
      <Group disableBottomBorder title={'Объем капиталовложений'}>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Объем по региональному отчету'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.investorCapital`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.investorCapital`,
                  inputProps: {
                    disabled: true,
                    leftAddons: <FormIconWithTooltip tooltipContent={investorCapitalDeviation} />,
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Объем по справкам проверяющей организации'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.investorCapitalChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.investorCapitalChecker`,
                  inputProps: {
                    disabled: true,
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
        </Stack>
      </Group>
      <Group disableBottomBorder title={'Количество рабочих мест'}>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Количество по региональному отчету'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.workPlacesCapital`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.workPlacesCapital`,
                  inputProps: {
                    disabled: true,
                    suffix: '',
                    integersOnly: true,
                    leftAddons: <FormIconWithTooltip tooltipContent={workPlacesCapitalDeviation} />,
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Количество по справкам проверяющей организации '}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.workPlacesChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.workPlacesChecker`,
                  inputProps: {
                    disabled: true,
                    suffix: '',
                    integersOnly: true,
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
        </Stack>
      </Group>
    </Stack>
  )
}

export default SummaryReportOfMonitoringResultsEconomicSphereOkvedRegionItem
