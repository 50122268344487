import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'

const authReducerActionMap = {
  SETUP_ERROR: 'SETUP_ERROR',
  SETUP_NATIVE_ERROR: 'SETUP_NATIVE_ERROR',
} as const

const authDefaultFormValues = {
  email: '',
  password: '',
}

const authComponentInfo: ComponentInfo = {
  componentName: 'LoginByAuth',
  moduleName: 'LoginForm',
  componentType: 'operatorAuthByForm',
}

const reducerAuthComponentInfo = {
  componentName: 'authReducer',
  moduleName: 'LoginByAuth',
  componentType: 'reducer',
}

export { authComponentInfo, authDefaultFormValues, authReducerActionMap, reducerAuthComponentInfo }
