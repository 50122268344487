import { useMemo } from 'react'
import { useParams } from 'react-router'

import { getDfoByType } from '@components/Projects/[id]/helpers'
import { useLayoutMode } from '@components/Projects/[id]/LayoutMods'
import {
  accessPermissions,
  DfosStage,
  DocumentsSetsType,
  NewDfosType,
  Roles,
} from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { findOne } from '@helpers/commonHelpers'
import { useDebounceLoading } from '@hooks/new/loading/useDebounceLoading'
import { useDfoDocuments } from '@hooks/new/swr/useDfoDocuments'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useVersions } from '@hooks/new/swr/useVersions'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

const PERMISSIONS = [accessPermissions.READ, accessPermissions.READ_ADD_SIGN]

const useParticipantsOfStatement = () => {
  const { projectId } = useParams()

  const { getUserRole } = useAuthContext()
  const { isChangesMadeMode } = useLayoutMode()

  const { roles } = getUserRole?.()
  const isInvestor = findOne(roles, Roles.Investor)

  const { projectAttributes, isLoadingProjectAttributes } = useProjectAttributes({
    key: {
      projectId,
      _key: 'projectAttributes',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useParticipantsOfStatement',
          componentType: 'hook',
        },
      }),
    },
  })

  const { projectDfos, isLoadingProjectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useParticipantsOfStatement',
          componentType: 'hook',
        },
      }),
    },
  })

  const SZPKDfo = useMemo(() => {
    if (!projectDfos) return

    return getDfoByType(projectDfos, NewDfosType.SZPK)
  }, [projectDfos])
  const INVESTPROJECTDfo = useMemo(() => {
    return getDfoByType(projectDfos, NewDfosType.INVESTPROJECT)
  }, [projectDfos])

  const { dfoDocuments: SZPKDfoSets } = useDfoDocuments({
    key: {
      projectId,
      dfoId: SZPKDfo?.id,
      _key: 'document-sets',
    },
    config: {
      isPaused: () => !projectId || !SZPKDfo?.id,
    },
  })

  const { versions } = useVersions({
    key: { dfoId: INVESTPROJECTDfo?.id, projectId, _key: 'versions' },
    config: {
      isPaused: () => !projectId || !INVESTPROJECTDfo?.id,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useParticipantsOfStatement',
          componentType: 'hook',
        },
      }),
    },
  })

  const { isLoading: isParticipantOfStatementLoading } = useDebounceLoading(
    isLoadingProjectAttributes || isLoadingProjectDfos,
    [isLoadingProjectAttributes, isLoadingProjectDfos],
    300,
  )

  const hasPermissionToSZPKChanges = useMemo(() => {
    if (!SZPKDfoSets) return false

    if (!(DocumentsSetsType.SZPK_CHANGES in SZPKDfoSets)) return false

    return SZPKDfoSets[DocumentsSetsType.SZPK_CHANGES].some((documents) =>
      PERMISSIONS.includes(documents.permissions),
    )
  }, [SZPKDfoSets])

  const hasPermissionToSZPK = useMemo(() => {
    if (!SZPKDfoSets) return false

    if (!(DocumentsSetsType.SZPK in SZPKDfoSets)) return false

    return SZPKDfoSets[DocumentsSetsType.SZPK].some((documents) =>
      PERMISSIONS.includes(documents.permissions),
    )
  }, [SZPKDfoSets])

  const hasPermissions = hasPermissionToSZPK || hasPermissionToSZPKChanges
  const hasTempAttribute = projectAttributes?.find(
    (attr) => attr.name !== 'isFederal' && attr.isTemp,
  )
  const isFirstVersionInvestProject = useMemo(
    () => versions?.find((version) => version.isActual && version.versionNumber === 1),
    [versions],
  )

  const viewParticipantModeCondition = useMemo(() => {
    if (!isInvestor) return true

    if (INVESTPROJECTDfo?.stage === DfosStage.DRAFT && isFirstVersionInvestProject) return false

    return !(isChangesMadeMode && hasTempAttribute && hasPermissionToSZPKChanges)
  }, [
    isInvestor,
    INVESTPROJECTDfo?.stage,
    isFirstVersionInvestProject,
    isChangesMadeMode,
    hasTempAttribute,
    hasPermissionToSZPKChanges,
  ])

  return {
    state: {
      hasPermissions,
      hasTempAttribute,
      viewParticipantModeCondition,
      isFirstVersionInvestProject,
      isParticipantOfStatementLoading,
    },
  }
}

export { useParticipantsOfStatement }
