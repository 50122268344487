import { generatePropertiesObjectForNoticeOfProjectRejectConcludeSZPKForm } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNoticeOfProjectRejectConcludeSZPKForm } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/adapters/RHF.adapter'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'

const useNoticeOfProjectRejectConcludeSZPKAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NoticeOfProjectRejectConcludeSZPKFormValues =>
    generateRHFObjectForNoticeOfProjectRejectConcludeSZPKForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNoticeOfProjectRejectConcludeSZPKForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNoticeOfProjectRejectConcludeSZPKAdapters }
