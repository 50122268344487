import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const secondSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap: FormValuesValidationSection<
  keyof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues['2'],
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues
> = {
  investorFullNameInstrumental: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1050),
  },
}

export { secondSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap }
