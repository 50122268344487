import React, {
  type FC,
  DetailedHTMLProps,
  isValidElement,
  LiHTMLAttributes,
  memo,
  useCallback,
} from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'

import type { IconProps } from '@components/Icon'
import Accordion, { AccordionState } from '@components/NewDesign/Accordion'
import Typography from '@components/NewDesign/Typography'
import { type MainMenuLinkItem } from '@components/Sidebars/MainHeaderMenu/const'
import { isString } from '@helpers/checkTypes'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from './MainHeaderMenuItem.module.scss'

const NESTED_LINK_ITEM_MARGIN_LEFT = 24

interface MainHeaderMenuItemProps
  extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  linkItem: MainMenuLinkItem
  onLinkClick?: (link: string) => VoidFunction
}

const MainHeaderMenuItem: FC<MainHeaderMenuItemProps> = ({ linkItem, onLinkClick, ...props }) => {
  const { pathname } = useLocation()

  const { title, disabled, icon, link } = linkItem

  const isList = !!(linkItem.children && linkItem.children.length)

  const renderLeadingIcon = useCallback(
    (isActiveItem: boolean) => (): IconProps | undefined => {
      if (!isString(icon)) return

      return {
        src: icon,
        className: cn(styles['mainHeaderMenuItem__accordionButton-icon'], {
          [styles['mainHeaderMenuItem__accordionButton-icon--active']]: isActiveItem,
        }),
        size: 'initial',
      }
    },
    [icon],
  )

  const renderTrailingIcon = useCallback(
    (isActiveItem: boolean) =>
      ({ isActive }: AccordionState) => ({
        src: chevronRightIcon,
        className: cn(styles['mainHeaderMenuItem__accordionButton-icon'], {
          [styles['mainHeaderMenuItem__accordionButton-icon--open']]: isActive,
          [styles['mainHeaderMenuItem__accordionButton-icon--active']]: isActiveItem,
        }),
      }),
    [],
  )

  if (isList) {
    const isActiveNestedItem = !!linkItem.children.find((item) => pathname === item.link)

    return (
      <Accordion
        {...props}
        isOpen={isActiveNestedItem}
        text={linkItem.title}
        className={styles.mainHeaderMenuItem__accordion}
        buttonProps={{
          color: 'accent',
          className: styles.mainHeaderMenuItem__accordionButton,
          textClassName: cn(styles['mainHeaderMenuItem__accordionButton-text'], {
            [styles['mainHeaderMenuItem__accordionButton-text--active']]: isActiveNestedItem,
          }),
          renderLeadingIcon: renderLeadingIcon(isActiveNestedItem),
          renderTrailingIcon: renderTrailingIcon(isActiveNestedItem),
        }}
      >
        {linkItem.children.map((nestedLinkItem, index) => (
          <MainHeaderMenuItem
            key={index}
            linkItem={nestedLinkItem}
            style={{
              paddingLeft: NESTED_LINK_ITEM_MARGIN_LEFT,
            }}
            onLinkClick={onLinkClick}
          />
        ))}
      </Accordion>
    )
  }

  return (
    <li
      {...props}
      className={cn(styles['mainHeaderMenuItem__link-wrapper'], {
        [styles['mainHeaderMenuItem__link-wrapper--disabled']]: linkItem.disabled,
      })}
    >
      <NavLink
        to={link}
        data-testid={`MainHeaderMenuItem-${link}-link`}
        className={cn(styles.mainHeaderMenuItem__link, {
          [styles['mainHeaderMenuItem__link--disabled']]: disabled,
        })}
        onClick={onLinkClick?.(link)}
      >
        {({ isActive }) => {
          return (
            <div className={styles['mainHeaderMenuItem__link-content']}>
              {icon &&
                isValidElement(icon) &&
                React.cloneElement(icon, {
                  isActive,
                })}
              {title && (
                <Typography.Body
                  className={styles['mainHeaderMenuItem__link-description']}
                  color={!isActive ? 'text-base-secondary' : 'text-accent-brand'}
                  variant={'bodyMMedium'}
                >
                  {title}
                </Typography.Body>
              )}
            </div>
          )
        }}
      </NavLink>
    </li>
  )
}

export default memo(MainHeaderMenuItem)
