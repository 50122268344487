import React, {
  ChangeEvent,
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  memo,
  MouseEvent,
  ReactElement,
  ReactNode,
  useState,
} from 'react'

import { isNullOrUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './RadioGroup.module.scss'

export type Direction = 'horizontal' | 'vertical'
export type RadioGroupType = 'radio' | 'tag'

export type RadioGroupProps = {
  /**
   * Направление
   */
  direction?: Direction

  /**
   * Тип компонента
   */
  type?: RadioGroupType

  /**
   * Дополнительный класс
   */
  className?: string

  children: ReactNode

  onChange?: (
    event?: ChangeEvent | MouseEvent,
    payload?: {
      value: string
      name?: string
    },
  ) => void

  disabled?: boolean
  disableActiveState?: boolean

  name?: string

  value?: string
}

const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      children,
      className,
      direction = 'horizontal',
      onChange,
      type = 'radio',
      disabled = false,
      name,
      value,
      disableActiveState,
    },
    ref,
  ) => {
    const [stateValue, setStateValue] = useState<string>('')

    const renderRadio = (child: ReactElement) => {
      const { className: childClassName } = child.props

      const checked = (value || stateValue) === child.props.value

      const handleChange = (event: ChangeEvent) => {
        setStateValue(child.props.value)
        if (onChange) {
          onChange(event, { name, value: child.props.value })
        }
      }

      return cloneElement(child, {
        onChange: handleChange,
        disabled,
        ...child.props,
        checked,
        name,
        'data-testid': `RadioGroup-radioItem-${child.props.value || 'ALL'}`,
        className: cn(childClassName, styles.radio),
      })
    }

    const renderTag = (child: ReactElement) => {
      const checked = disableActiveState ? false : (value || stateValue) === child.props.value

      const handleChange = (event: ChangeEvent | MouseEvent) => {
        setStateValue(child.props.value)
        if (onChange) {
          onChange(event, { name, value: child.props.value })
        }
      }

      const clone = cloneElement(child, {
        onClick: handleChange,
        disabled,
        ...child.props,
        checked,
        name,
      })

      return (
        <label className={cn(styles.radio)}>
          {clone}
          <input
            data-testid={`RadioGroup-tagItem-${child.props.value || 'ALL'}`}
            type="radio"
            autoComplete="off"
            disabled={disabled || child.props.disabled}
            name={name}
            checked={checked}
            className={styles.hiddenInput}
            value={child.props.value}
            onChange={handleChange}
          />
        </label>
      )
    }

    const renderChild = (child: ReactElement) => {
      return type === 'radio' ? renderRadio(child) : renderTag(child)
    }

    const renderRecursiveMap = (
      children: ReactNode,
      render: (child: ReactElement) => JSX.Element,
    ) => {
      return Children.map(children, (child) => {
        if (!isValidElement(child)) return null

        if (!isNullOrUndefined(child.props.value)) {
          return render(child)
        }

        return React.cloneElement(child, {
          children: renderRecursiveMap(child.props.children, render),
        })
      })
    }

    return (
      <div
        className={cn(styles.component, styles[type], styles[direction], className)}
        data-testid={`RadioGroup-radioGroup-${name}`}
        ref={ref}
      >
        {children ? (
          <div className={styles.radioList}>{renderRecursiveMap(children, renderChild)}</div>
        ) : null}
      </div>
    )
  },
)

export default memo(RadioGroup)
