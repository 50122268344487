import React, { useCallback, useEffect, useReducer } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import CertificateForm from '@components/Forms/LoginForm/LoginByCrypt/CertificateForm'
import ProcurationForm from '@components/Forms/LoginForm/LoginByCrypt/ProcurationForm'
import LoginLogo from '@components/Forms/LoginForm/shared/LoginLogo'
import LoginWrapper from '@components/Forms/LoginForm/shared/LoginWrapper'
import { mapOfTypeRedirect } from '@components/Forms/RegistrationForm/const'
import { RegistrationLocationState } from '@components/Forms/RegistrationForm/types'
import { useWarnSignSZPKModal } from '@components/NewDesignedModals/WarnSignSZPKModal/manager'
import { Paths } from '@constants/paths'
import { isInvestorDomain } from '@constants/system'
import { Roles, RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { ProcurationData } from '@context/AuthContext/types'
import { checkRoleOnExistence } from '@context/AuthContext/workers/rolesWorkers'
import { useScrollState } from '@hooks/new/scroll/useScrollState'

import { cryptActionCreators, cryptReducer, initialCryptState } from './reducer'

const LoginByCrypt = () => {
  const { checkingRole } = useAuthContext()

  const [state, dispatch] = useReducer(cryptReducer, initialCryptState)

  const location = useLocation()
  const locationState = location.state as RegistrationLocationState | undefined

  const { handleOpenUseWarnSignSZPKModal } = useWarnSignSZPKModal()

  const { handleUnblockScroll } = useScrollState()

  const handleBackAction = useCallback((showProcucation: boolean) => {
    dispatch(cryptActionCreators.setProcuration(showProcucation))
    dispatch(cryptActionCreators.setProcurationList([]))
  }, [])

  const handleProcuration = useCallback((procurations: ProcurationData, signature: string) => {
    dispatch(cryptActionCreators.setProcurationList(procurations))
    dispatch(cryptActionCreators.setSignature(signature))
    dispatch(cryptActionCreators.setProcuration(true))
  }, [])

  const handleError = useCallback((error: unknown | null) => {
    dispatch(cryptActionCreators.setShowLogoError(error))
  }, [])

  const handleSuccessAuthorization = useCallback(() => {
    if (
      !checkRoleOnExistence(RolesTypes.INVESTOR) ||
      !state.isProcuration ||
      !Boolean(state.procurationList.length)
    )
      return

    const isRoleHEADER = !!Roles.Header.find((item) => checkingRole?.(item))

    if (isRoleHEADER) handleOpenUseWarnSignSZPKModal()
  }, [
    checkingRole,
    handleOpenUseWarnSignSZPKModal,
    state.isProcuration,
    state.procurationList.length,
  ])

  const isShowCertificateForm = !state.isProcuration
  const isShowProcurationForm = state.isProcuration

  useEffect(() => {
    if (
      locationState?.from === mapOfTypeRedirect.MCHD &&
      locationState?.state.signature &&
      locationState?.state.procurationList
    ) {
      handleProcuration(locationState.state.procurationList, locationState.state.signature)
    }
  }, [])

  return (
    <LoginWrapper view={isInvestorDomain ? 'secondary' : 'primary'}>
      <Link to={Paths.Home} onClick={handleUnblockScroll}>
        <LoginLogo isShowErrorLogo={state.isShowLogoError} />
      </Link>
      {isShowCertificateForm && (
        <CertificateForm onProcuration={handleProcuration} onError={handleError} />
      )}
      {isShowProcurationForm && (
        <ProcurationForm
          procurationList={state.procurationList}
          signature={state.signature}
          onProcurationChange={handleBackAction}
          onError={handleError}
          onSuccessAuthorization={handleSuccessAuthorization}
        />
      )}
    </LoginWrapper>
  )
}

export default LoginByCrypt
