import { API_URL } from '@constants/urls'
import { setupCache } from 'axios-cache-adapter'

export type HeaderTypes = 'Default' | 'JSON' | 'Form'

// Headers
export const Headers = Object.freeze<Readonly<Record<HeaderTypes, Record<string, string>>>>({
  Default: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  JSON: {
    'Content-Type': 'application/json',
  },
  Form: {
    'Content-Type': 'multipart/form-data',
  },
})

// Cache
const cache = setupCache({
  readHeaders: true,
  exclude: { query: false },
})

// Config
export const axiosConfig = {
  baseURL: API_URL,
  adapter: cache.adapter,
  headers: Headers.Default,
  validateStatus: function (status) {
    return status >= 200 && status <= 302
  },
}
