import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useFormContext } from 'react-hook-form'

import { SystemVariablesFormValues } from '@components/SystemVariables/types'
import { useAPIContext } from '@context/APIContext'
import { TransformedSystemVariableItem } from '@context/APIContext/hooks/useOperatorApi'
import { useSystemVariables } from '@hooks/new/swr/useSystemVariables'

interface SystemVariablesContextProps {
  state: {
    isLoading: boolean
    error?: unknown
  }
  handlers: {
    updateSystemVariable?: (variableItem: TransformedSystemVariableItem) => Promise<void>
  }
}

interface SystemVariablesManagerProps {
  children: ReactNode
}

const SystemVariablesContext = createContext<SystemVariablesContextProps>({
  state: {
    isLoading: false,
    error: null,
  },
  handlers: {},
})

const SystemVariablesManager: FC<SystemVariablesManagerProps> = ({ children }) => {
  const { reset } = useFormContext<SystemVariablesFormValues>()

  const {
    operatorApi: { updateSystemVariable },
  } = useAPIContext()

  const {
    systemVariables,
    isLoadingSystemVariables,
    error,
    mutate: revalidateSystemVariables,
  } = useSystemVariables({
    key: {
      _key: 'systemVariables',
    },
  })

  const handleUpdateSystemVariableItem = useCallback(
    async ({ type, value }: TransformedSystemVariableItem) => {
      if (!value) return

      try {
        await updateSystemVariable({
          [type]: value,
        })

        await revalidateSystemVariables()
      } catch (error) {
        throw error
      }
    },
    [revalidateSystemVariables, updateSystemVariable],
  )

  useEffect(() => {
    if (!systemVariables || !systemVariables.length) return

    reset({
      variables: systemVariables,
    })
  }, [systemVariables, systemVariables?.length])

  const preparedValue: SystemVariablesContextProps = useMemo(
    () => ({
      state: {
        isLoading: isLoadingSystemVariables,
        error,
      },
      handlers: {
        updateSystemVariable: handleUpdateSystemVariableItem,
      },
    }),
    [error, handleUpdateSystemVariableItem, isLoadingSystemVariables],
  )

  return (
    <SystemVariablesContext.Provider value={preparedValue}>
      {children}
    </SystemVariablesContext.Provider>
  )
}

const useSystemVariablesManager = () => {
  return useContext(SystemVariablesContext)
}

export { useSystemVariablesManager }
export default memo(SystemVariablesManager)
