import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfIdentifiedViolationsFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const STATEMENT_DAYS_REVISION_LIMIT = 100

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof NotificationOfIdentifiedViolationsFormValues['4'],
  NotificationOfIdentifiedViolationsFormValues
> = {
  ruleLinkStatementReturn: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  statementTechError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 10000) : undefined),
  },
  statementSetDocumentError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 10000) : undefined),
  },
  statementDocumentError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 10000) : undefined),
  },
  ruleLinkDocumentError: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  statementHeadError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value,  3000) : undefined),
  },
  statementInvestorError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 3000) : undefined),
  },
  statementProjectCompanyError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 3000) : undefined),
  },
  statementProjectError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 3000) : undefined),
  },
  statementNpaError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 3000) : undefined),
  },
  statementOtherError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  statementOtherDetails: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 3000) : undefined),
  },
  statementDaysRevision: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return (
        Number(value) <= STATEMENT_DAYS_REVISION_LIMIT ||
        `значение не должно превышать ${STATEMENT_DAYS_REVISION_LIMIT}`
      )
    },
  },
}

export { fourthSectionValidationMap }
