import React, { FC, memo, useCallback } from 'react'

import OrganizationItem from '@components/Forms/LoginForm/LoginByESIA/OrganizationItem/OrganizationItem'
import { ESIAOrganization } from '@context/APIContext/hooks/useESIAApi'

import styles from './OrganizationsList.module.scss'

interface CertificateListProps {
  organizations: ESIAOrganization[]
  onChooseOrganization: (organization: ESIAOrganization) => void
}

const OrganizationsList: FC<CertificateListProps> = ({ organizations, onChooseOrganization }) => {
  const handleChooseOrganization = useCallback(
    (organization: ESIAOrganization) => () => {
      onChooseOrganization(organization)
    },
    [onChooseOrganization],
  )

  return (
    <div className={styles.wrapperForm}>
      <div className={styles.wrapperForm__container}>
        {!!organizations?.length &&
          organizations.map((organization) => (
            <OrganizationItem
              className={styles.organization}
              organization={organization}
              onChooseOrganization={handleChooseOrganization(organization)}
            />
          ))}
      </div>
    </div>
  )
}

export default memo(OrganizationsList)
