import { generatePropertiesObjectForInclusionAgreementOfRelatedSZPKRegisterForm } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForInclusionAgreementOfRelatedSZPKRegisterForm } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/adapters/RHF.adapter'
import { InclusionAgreementOfRelatedSZPKRegisterFormValues } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/types'

const useInclusionAgreementOfRelatedSZPKRegisterAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): InclusionAgreementOfRelatedSZPKRegisterFormValues =>
    generateRHFObjectForInclusionAgreementOfRelatedSZPKRegisterForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForInclusionAgreementOfRelatedSZPKRegisterForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useInclusionAgreementOfRelatedSZPKRegisterAdapters }
