import React from 'react'
import { useFormContext } from 'react-hook-form'

import RFSection from '@components/DocumentFormComponents/FormSections/RFSection'
import { useAAgreementForceCircumstancesManager } from '@components/Forms/AAgreementForceCircumstancesForm/Manager'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import { AAgreementForceCircumstancesFieldsControlUpdateWatcher } from '@components/Forms/AAgreementForceCircumstancesForm/watcher'

const One = () => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementForceCircumstancesManager()

  if (!formInstance) return null

  return (
    <RFSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={AAgreementForceCircumstancesFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      onChangeValue={handleChangeValue}
    />
  )
}

export default One
