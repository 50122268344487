import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import DocumentDataView from '@components/DocumentFormComponents/DocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnChangingCharacteristicsOfObjectsBlockValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import { fourthSectionAAgreementOnChangingCharacteristicsOfObjectsValidationMap } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/4/validation'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import { AAgreementOnChangingCharacteristicsOfObjectsFormValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const {
    getSubscribableControlProps,

    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
  })

  const investorFullName = useWatch({
    name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues['4'].investorFullName,
    control: formInstance.control
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <DocumentDataView
              suptitle={'Наименование'}
              formIconTooltipProps={{
                tooltipContent:
                  'Наименование и реквизиты можно изменить в разделе "Моя организация"',
              }}
            >
              {investorFullName}
            </DocumentDataView>
          </Col>
        </Row>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное лицо организации'}
          formInstance={formInstance}
          formSection={'4'}
          prefixFormFieldName={'investor'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: aAgreementOnChangingCharacteristicsOfObjectsBlockValues['4'].investorHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues['4']
                        .investorHeaderName,
                      rules:
                        fourthSectionAAgreementOnChangingCharacteristicsOfObjectsValidationMap.investorHeaderName,
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () => {
                        setTimeout(() => {
                          handleChangeValue?.(
                            aAgreementOnChangingCharacteristicsOfObjectsBlockValues['4']
                              .investorHeaderName,
                          )
                        }, 0)
                      },
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
      </Stack>
    </Container>
  )
}

export default Fourth
