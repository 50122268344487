import React, { FC } from 'react'
import { ReactSVG } from 'react-svg'

import Typography from '@components/Typography'

import styles from './Caption.module.scss'
export interface ICaption {
  icon: string
}

const Caption: FC<ICaption> = ({ icon, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <ReactSVG src={icon} />
      </div>
      <Typography variant="p" color="secondary" className={styles.text}>
        {children}
      </Typography>
    </div>
  )
}

export default Caption
