import React, { FC } from 'react'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import styles from '@components/DocumentFormComponents/FormStyles/ConstructionObject.module.scss'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import CExploitationForm from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CExploitationForm'
import CObjectsForm from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm'
import CPermissionsForm from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm'
import CRegistrationForm from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CRegistrationForm'
import { ConstructionStagesArrayPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { AAgreementOnCreationOtherEstateObjectsFormModifierValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import cn from 'classnames'

interface ConstructionStageProps {
  indexOfMainBlock: number
  name: ConstructionStagesArrayPathName
}

const ConstructionObject: FC<ConstructionStageProps> = ({ indexOfMainBlock, name }) => {
  const formModifierInstance =
    useFormModifierContext<AAgreementOnCreationOtherEstateObjectsFormModifierValues>()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  return (
    <div className={styles.constructionObject}>
      <div
        id={`${name}.constructionObjects`}
        className={cn(
          styles.constructionObject__header,
          styles['constructionObject__object-header'],
        )}
      >
        <FormListTooltipControl
          name={`${name}.constructionObjects`}
          control={formModifierInstance.control}
          wrapperClassName={styles.constructionObject__title}
          onDifference={handleUpdateChanges}
        >
          <Typography.Headline variant={'headlineH3'}>Объекты недвижимости</Typography.Headline>
        </FormListTooltipControl>
        <Typography.Body
          color={'on-surface-secondary'}
          className={styles.constructionObject__caption}
          variant={'bodyMRegular'}
        >
          Объект(ы) недвижимости и (или) комплекс объектов движимого и недвижимого имущества,
          связанных между собой
        </Typography.Body>
      </div>
      <Row className={styles.constructionObject__container}>
        <Col xs={12}>
          <CObjectsForm name={`${name}.constructionObjects`} />
        </Col>
      </Row>
      <Border className={styles.constructionObject__border} />
      <Row className={styles.constructionObject__container}>
        <Col id={`${name}.permissionsObjects`} xs={12}>
          <FormListTooltipControl
            wrapperClassName={styles.constructionObject__header}
            control={formModifierInstance.control}
            name={`${name}.permissionsObjects`}
            onDifference={handleUpdateChanges}
          >
            <Typography.Headline variant={'headlineH4'}>Разрешения на объекты</Typography.Headline>
          </FormListTooltipControl>
          <CPermissionsForm indexOfBlock={indexOfMainBlock} name={`${name}.permissionsObjects`} />
        </Col>
      </Row>
      <Border className={styles.constructionObject__border} />
      <Row className={styles.constructionObject__container}>
        <Col id={`${name}.exploitation.exploitationObjects`} xs={12}>
          <FormListTooltipControl
            wrapperClassName={styles.constructionObject__header}
            control={formModifierInstance.control}
            name={`${name}.exploitation.exploitationObjects`}
            onDifference={handleUpdateChanges}
          >
            <Typography.Headline variant={'headlineH4'}>Эксплуатация объектов</Typography.Headline>
          </FormListTooltipControl>
          <CExploitationForm indexOfBlock={indexOfMainBlock} name={`${name}.exploitation`} />
        </Col>
      </Row>
      <Border className={styles.constructionObject__border} />
      <Row className={styles.constructionObject__container}>
        <Col id={`${name}.registration.registrationObjects`} xs={12}>
          <FormListTooltipControl
            wrapperClassName={styles.constructionObject__header}
            control={formModifierInstance.control}
            name={`${name}.registration.registrationObjects`}
            onDifference={handleUpdateChanges}
          >
            <Typography.Headline variant={'headlineH4'}>Регистрация объектов</Typography.Headline>
          </FormListTooltipControl>
          <CRegistrationForm name={`${name}.registration`} indexOfBlock={indexOfMainBlock} />
        </Col>
      </Row>
    </div>
  )
}

export default ConstructionObject
