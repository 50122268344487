import React, { FC, memo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { PopupProps } from 'react-popup-manager'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import CertificateApplicationConclusionOfAARequirements from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm'
import {
  defaultCertificateApplicationConclusionOfAARequirementsFormValues,
  mapOfCertificateApplicationConclusionOfAARequirementsMenu,
} from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/const'
import { CertificateApplicationConclusionOfAARequirementsFieldsControlUpdateWatcher } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import { IFormModalParams } from '@context/APIContext/types'
import cn from 'classnames'

import CertificateApplicationConclusionOfAARequirementsLayoutWrapper from './Wrapper'

export type CertificateApplicationConclusionOfAARequirementsModalProps = IFormModalParams

type CertificateApplicationConclusionOfAARequirementsLayoutProps = PopupProps &
  CertificateApplicationConclusionOfAARequirementsModalProps

const CertificateApplicationConclusionOfAARequirementsLayout: FC<
  CertificateApplicationConclusionOfAARequirementsLayoutProps
> = ({ projectId, formId, isOpen, onClose, initialErrorsFromViolations, editMode }) => {
  const formInstance = useForm({
    defaultValues: defaultCertificateApplicationConclusionOfAARequirementsFormValues,
  })

  const handleOnClose = () => {
    CertificateApplicationConclusionOfAARequirementsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <CertificateApplicationConclusionOfAARequirements.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfCertificateApplicationConclusionOfAARequirementsMenu[0].id}
          >
            <CertificateApplicationConclusionOfAARequirementsLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Справка о соответствии заявления о заключении дополнительного соглашения
                  требованиям
                </Typography.Headline>
                <CertificateApplicationConclusionOfAARequirements.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <CertificateApplicationConclusionOfAARequirements.Menu
                  className={styles.layout__menu}
                />
              </div>
            </CertificateApplicationConclusionOfAARequirementsLayoutWrapper>
          </FlatMenuManager>
        </CertificateApplicationConclusionOfAARequirements.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(CertificateApplicationConclusionOfAARequirementsLayout)
