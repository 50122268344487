import React, { FC, ReactNode, useMemo } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import styles from '@components/DocumentFormComponents/FormStyles/ManufactoringOfGoods.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import ManufactoringOfGoodsItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/9/Forms/ManufactoringOfGoods/ManufactoringOfGoodsItem'
import {
  NinthManufactoringOfGoodsArrayPathName,
  NinthVolumeOfProductionPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/9/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { getAmountValueFromStr } from '@components/NewDesign/AmountInput/utils'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { manufactoringOfGoodsValidationMap } from './validation'

//Добавляем два нуля minority
const LIMIT_INVESTOR_CAPITAL_VALUE = '15 000 000 000,00'

interface ManufactoringOfGoodsFormProps {
  id: string
  title: string
  indexOfGood: number
  lastIndexOfGood: number
  name: NinthManufactoringOfGoodsArrayPathName
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  onRemoveStep: VoidFunction
  onMoveTop: () => Promise<void>
  onMoveBottom: () => Promise<void>
  leftAddons?: ReactNode
}

const ManufactoringOfGoodsForm: FC<ManufactoringOfGoodsFormProps> = ({
  id,
  title,
  indexOfGood,
  lastIndexOfGood,
  formInstance,
  formModifierInstance,
  name,
  onRemoveStep,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const investorCapital = useWatch({
    control: formInstance.control,
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6'].investorCapital,
  })

  const isNew = useWatch({
    control: formModifierInstance.control,
    name: `${name}.isNew`,
  })

  const isPaper = useWatch({
    control: formInstance.control,
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['additionalFields']
      .isPaper,
  })

  const {
    getFormFieldControlProps,
    getHierarchyReferenceBookSelectProps,
    getSwitchProps,
    getTextareaProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { fields: projectResults } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    NinthVolumeOfProductionPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${name}.volumeOfProduction`,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
    NinthVolumeOfProductionPathName
  >({
    control: formModifierInstance.control,
    name: `${name}.volumeOfProduction`,
    watcher:
      AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
  })

  const conditionToIsStabilizationResultRender = useMemo(() => {
    if (isPaper) return true

    if (!investorCapital) return false

    const preparedInvestorCapital = getAmountValueFromStr(investorCapital, 100)
    const preparedLimitInvestorCapital =
      investorCapital && getAmountValueFromStr(LIMIT_INVESTOR_CAPITAL_VALUE, 100)

    if (!preparedInvestorCapital || !preparedLimitInvestorCapital) return false

    return preparedInvestorCapital >= preparedLimitInvestorCapital
  }, [investorCapital])

  const conditionToTnVedRender = !!useWatch({
    name: `${name}.isStabilizationResult`,
    control: formInstance.control,
  })

  const conditionToRenderTaxes = !!projectResults.length

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate
      id={id}
      title={title}
      leftAddons={leftAddons}
      additionalNode={
        editMode && (
          <FieldArraySwapper
            index={indexOfGood}
            lastIndex={lastIndexOfGood}
            onMoveTop={onMoveTop}
            onMoveBottom={onMoveBottom}
          />
        )
      }
      onRemoveStep={editMode ? onRemoveStep : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.projectResultName`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectResultName`)
                    }, 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.projectResultName`,
                  rules: manufactoringOfGoodsValidationMap.projectResultName,
                  textareaProps: {
                    label: 'Наименование и характеристика',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectResultName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.projectResultName`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        {conditionToRenderTaxes && (
          <FormListTooltipControl
            disablePaddingRight
            enablePaddingLeft
            name={`${name}.volumeOfProduction`}
            control={formModifierInstance.control}
            onDifference={handleUpdateChanges}
          >
            {({ iconWithTooltip }) => (
              <Group
                disableBottomBorder
                leftAddons={iconWithTooltip}
                title={
                  'Объем производства товаров, выполнения работ, оказания услуг за указанный год'
                }
              >
                <Stack direction={'vertical'} gap={3}>
                  {projectResults.map((item, index) => {
                    const formName = `${name}.volumeOfProduction.${index}` as const

                    return (
                      <ManufactoringOfGoodsItem
                        key={item.id}
                        name={formName}
                        formInstance={formInstance}
                        formModifierInstance={formModifierInstance}
                      />
                    )
                  })}
                  {conditionToIsStabilizationResultRender && (
                    <Row>
                      <Col xs={12}>
                        <FormFieldControl
                          {...getFormFieldControlProps({
                            path: `${name}.isStabilizationResult`,
                            formFieldTooltipProps: {
                              onChange: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${name}.isStabilizationResult`)
                                }, 0),
                              onDifference: handleUpdateChanges,
                            },
                          })}
                        >
                          <ControlledSwitch
                            {...getSwitchProps({
                              name: `${name}.isStabilizationResult`,
                              switchProps: {
                                wrapperClassName: styles.form__switch,
                                label:
                                  'Применимость стабилизации актов (решений), предусматривающих увеличение ставок вывозных таможенных пошлин в отношении товара',
                              },
                              onChange: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${name}.isStabilizationResult`)
                                }, 0),
                            })}
                          />
                        </FormFieldControl>
                      </Col>
                    </Row>
                  )}
                  {conditionToTnVedRender && (
                    <Row>
                      <Col xs={12}>
                        <FormFieldControl
                          {...getFormFieldControlProps({
                            path: `${name}.projectResultCodeTnVed`,
                            formFieldTooltipProps: {
                              onChange: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${name}.projectResultCodeTnVed`)
                                }, 0),
                              onDifference: handleUpdateChanges,
                            },
                          })}
                        >
                          <ControlledHierarchyReferenceBookSelect
                            {...getHierarchyReferenceBookSelectProps({
                              dictionaryName: 'tnved',
                              customDisplayValueFC: ({ code }) => code || '',
                              controllerProps: {
                                name: `${name}.projectResultCodeTnVed`,
                                rules: manufactoringOfGoodsValidationMap.projectResultCodeTnVed,
                              },
                              inputProps: {
                                label: 'Код товара по ТН ВЭД',
                              },
                              onChange: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${name}.projectResultCodeTnVed`)
                                }, 0),
                            })}
                          />
                        </FormFieldControl>
                      </Col>
                    </Row>
                  )}
                </Stack>
              </Group>
            )}
          </FormListTooltipControl>
        )}
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default ManufactoringOfGoodsForm
