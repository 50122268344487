import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { compact } from '@helpers/array/compact'
import { isUndefined } from '@helpers/checkTypes'

const generateMenuListForCertificateOnMonitoringResultsForm = (
  formValues:
    | CertificateOnMonitoringResultsFormValues
    | UnpackNestedValue<CertificateOnMonitoringResultsFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<CertificateOnMonitoringResultsFormValues>,
): NestedMapOfMenu[] => {
  const getMenuSectionWithError = (numberSectionOfMenu: keyof NestedMenuHashMap) => {
    if (isUndefined(initialHashMap[numberSectionOfMenu])) return

    const preparedChildrenSections = initialHashMap[numberSectionOfMenu].children.map((item) => ({
      ...item,
      hasError: !!formErrors?.[item.id],
    }))

    return {
      ...initialHashMap[numberSectionOfMenu],
      hasError:
        !!formErrors?.[numberSectionOfMenu] ||
        preparedChildrenSections.some((item) => item.hasError),
      children: preparedChildrenSections,
    }
  }

  const preparedStepsMonitoringChecker: NestedMapOfMenu[] =
    formValues?.['5']?.stepsMonitoringChecker.map((step, index) => {
      const stepId = `${initialHashMap['5'].path}.stepsMonitoringChecker.${index}`
      const stepError = formErrors['5']?.stepsMonitoringChecker?.[index]

      return {
        path: stepId,
        id: step.anchorId,
        hasError: !!stepError,
        isCollapsed: false,
        title: `Этап ${index + 1}`,
        parentBaseId: initialHashMap['5'].id,
        children: [],
      }
    }) || []

  const preparedCompensationsMonitoringChecker: NestedMapOfMenu[] =
    formValues?.['9']?.compensationsMonitoringChecker.map((compensationMonitoring, index) => {
      const stateSupportId = `${initialHashMap['9'].path}.compensationsMonitoringChecker.${index}`
      const stateSupportError = formErrors['9']?.compensationsMonitoringChecker?.[index]

      return {
        path: stateSupportId,
        id: compensationMonitoring.anchorId,
        hasError: !!stateSupportError,
        isCollapsed: false,
        title: compensationMonitoring.compensationYearChecker,
        parentBaseId: initialHashMap['9'].id,
        children: [],
      }
    }) || []

  const preparedCompensationsMonitoring: NestedMapOfMenu[] =
    formValues?.['10']?.compensationsMonitoring.map((compensationMonitoring, index) => {
      const stateSupportId = `${initialHashMap['10'].path}.compensationsMonitoring.${index}`
      const stateSupportError = formErrors['10']?.compensationsMonitoring?.[index]

      return {
        path: stateSupportId,
        id: compensationMonitoring.anchorId,
        hasError: !!stateSupportError,
        isCollapsed: false,
        title: compensationMonitoring.compensationYearChecker,
        parentBaseId: initialHashMap['10'].id,
        children: [],
      }
    }) || []

  const preparedHashMap: Record<string, NestedMapOfMenu | undefined> = {
    '1': getMenuSectionWithError('1'),
    '2': getMenuSectionWithError('2'),
    '3': getMenuSectionWithError('3'),
    '4': getMenuSectionWithError('4'),
    '6': getMenuSectionWithError('6'),
    '7': getMenuSectionWithError('7'),
    '8': getMenuSectionWithError('8'),
    '11': getMenuSectionWithError('11'),
    '12': getMenuSectionWithError('12'),
    '5': {
      ...initialHashMap['5'],
      hasError: preparedStepsMonitoringChecker.some(({ hasError }) => hasError),
      children: preparedStepsMonitoringChecker,
    },
    '9': {
      ...initialHashMap['9'],
      hasError: preparedCompensationsMonitoringChecker.some(({ hasError }) => hasError),
      children: preparedCompensationsMonitoringChecker,
    },
    '10': {
      ...initialHashMap['10'],
      hasError: preparedCompensationsMonitoring.some(({ hasError }) => hasError),
      children: preparedCompensationsMonitoring,
    },
  }

  return compact([
    preparedHashMap['1'],
    preparedHashMap['2'],
    preparedHashMap['3'],
    preparedHashMap['4'],
    preparedHashMap['5'],
    preparedHashMap['6'],
    preparedHashMap['7'],
    preparedHashMap['8'],
    preparedHashMap['9'],
    preparedHashMap['10'],
    preparedHashMap['11'],
    preparedHashMap['12'],
  ]) as NestedMapOfMenu[]
}

export { generateMenuListForCertificateOnMonitoringResultsForm }
