import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { CertificateOnMonitoringResultsStateSupportsCheckerArrayPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/11/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { stateSupportCheckerCertificateOnMonitoringResultsValidationMap } from './validation'

interface CertificateOnMonitoringResultsStateSupportCheckerProps {
  id: string
  name: CertificateOnMonitoringResultsStateSupportsCheckerArrayPathName
  title: string
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
  onRemove: VoidFunction
}

const CertificateOnMonitoringResultsStateSupportChecker: FC<
  CertificateOnMonitoringResultsStateSupportCheckerProps
> = ({ id, name, title, formInstance, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { getSubscribableControlProps, getTextareaProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
    })

  return (
    <FieldView.StepTemplate id={id} title={title} onRemoveStep={onRemove}>
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.stateSupportNameChecker`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${name}.stateSupportNameChecker`,
                    rules:
                      stateSupportCheckerCertificateOnMonitoringResultsValidationMap.stateSupportNameChecker,
                    textareaProps: {
                      label: 'Наименование',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.stateSupportNameChecker`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.stateSupportNameChecker`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.stateSupportReasonChecker`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${name}.stateSupportReasonChecker`,
                    rules:
                      stateSupportCheckerCertificateOnMonitoringResultsValidationMap.stateSupportReasonChecker,
                    textareaProps: {
                      label: 'Основание',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.stateSupportReasonChecker`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.stateSupportReasonChecker`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Объем'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stateSupportValue`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.stateSupportValue`,
                  inputProps: {
                    disabled: true,
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Объем'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stateSupportValueChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.stateSupportValueChecker`,
                  rules:
                    stateSupportCheckerCertificateOnMonitoringResultsValidationMap.stateSupportValueChecker,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.stateSupportValueChecker`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stateSupportValueChecker`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default CertificateOnMonitoringResultsStateSupportChecker
