import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { ISolicitationMembersEntity } from '@services/Members/Members.entity'
import useSWRImmutable from 'swr/immutable'

const useSolicitationMembers = ({
  key,
  config,
}: SWRHookProps<ISolicitationMembersEntity[] | undefined>) => {
  const {
    dfosApi: { getSolicitationMembersByDfoId },
  } = useAPIContext()

  const {
    data: solicitationMembers,
    error,
    mutate,
    isValidating: isLoadingProjectMembers,
  } = useSWRImmutable(key, getSolicitationMembersByDfoId, config)

  return {
    solicitationMembers,
    error,
    mutate,
    isLoadingProjectMembers,
  }
}

export { useSolicitationMembers }
