import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'

export const CertificateOnMonitoringResultsFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<CertificateOnMonitoringResultsFormValues>()
export const CertificateOnMonitoringResultsFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
