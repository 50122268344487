import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form'

import { OptionProps } from '@components/NewDesign/Select/model'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import { RegistryNpaChooseComponentProps } from '@components/RegistryNpa/Choose'
import { chooseNpaBlockValues } from '@components/RegistryNpa/Choose/const'
import { ChooseNpaFormValues, ChosenNpaThreeErrors } from '@components/RegistryNpa/Choose/types'
import { defaultFiltersValue } from '@components/RegistryNpa/const'
import { TFiltersModalFormValues } from '@components/RegistryNpa/Header/Filters'
import { useNpaFilters } from '@components/RegistryNpa/hooks/useNpaFilters'
import { IFiltersFormValues } from '@components/RegistryNpa/Manager'
import { useNpaSelectValues } from '@components/Sidebars/NPA/hooks/useNpaSelectValues'
import { errorModalBodyTexts, errorModalButtonTexts, errorModalHeaderTexts } from '@constants/texts'
import {
  TGetModalListNpaResponse,
  TMasterModalNpaItemWithId,
} from '@context/APIContext/hooks/useNpaApi'
import { isAxiosError, isEmptyString, isNotEmptyString } from '@helpers/checkTypes'
import { useNpaModalList } from '@hooks/new/swr/useNpaModalList'
import { useBooleanState } from '@hooks/useBooleanState'
import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { ChosenPart } from '@services/NPA/NPA.entity'
import NPAHelpers from '@services/NPA/NPA.helpers'
import NPAService from '@services/NPA/NPA.service'
import { AxiosError } from 'axios'
import { KeyedMutator } from 'swr'

const { getNpaPropertyPrefixByDocumentType } = NPAHelpers
const { buildThreeToRender, normalizeNpaDataNew } = NPAService

export type ViewModalType = 'chosen' | 'default'

export interface NpaModalContextProps {
  state: {
    filtersInstance: UseFormReturn<IFiltersFormValues> | null
    initialFilters: Partial<IFiltersFormValues['filters']>

    gridSwrInstance: {
      isNpaListLoading: boolean
      mutate: KeyedMutator<TGetModalListNpaResponse>
      npaList?: TGetModalListNpaResponse
      error?: AxiosError<unknown, unknown>
    } | null

    forSelect: {
      typeOptions: OptionProps[]
      directionOptions: OptionProps[]
      regionOptions: OptionProps[]
    }

    debouncedSearchValue: string

    requestLoading: boolean

    viewMode: ViewModalType

    threeErrors: ChosenNpaThreeErrors
  }
  handlers: {
    handleApplyFilters?: (filters: TFiltersModalFormValues) => void
    handleChangeViewMode?: (newMode: ViewModalType) => void
  }
  actions: {
    handleSubmitNpa?: () => Promise<void>
  }
}

const ChooseNpaModalContext = createContext<NpaModalContextProps>({
  state: {
    requestLoading: false,
    gridSwrInstance: null,
    filtersInstance: null,
    initialFilters: defaultFiltersValue.filters,

    forSelect: {
      directionOptions: [],
      typeOptions: [],
      regionOptions: [],
    },

    viewMode: 'default',

    debouncedSearchValue: '',

    threeErrors: null,
  },
  handlers: {},
  actions: {},
})

interface ChooseNpaModalManagerProps extends RegistryNpaChooseComponentProps {
  children: ReactNode
  onClose?: VoidFunction
}

const isNotValidLengthPartFieldValue = (value: string, length = 1000) => value.length > length

const useChooseNpaManager = () => {
  return useContext(ChooseNpaModalContext)
}

const ChooseModalManager: FC<ChooseNpaModalManagerProps> = ({
  documentType,
  chosenParts,
  initialFilters,
  excludedPartIds,
  onAddItemToList,
  onClose,
  children,
}) => {
  const formInstance = useFormContext<ChooseNpaFormValues>()

  const { reset: resetForm, getValues } = formInstance

  const {
    booleanState: requestLoading,
    setBooleanStateToFalse: disableRequestLoading,
    setBooleanStateToTrue: enableRequestLoading,
  } = useBooleanState()

  const [viewMode, setViewMode] = useState<ViewModalType>('default')

  const [threeErrors, setThreeErrors] = useState<ChosenNpaThreeErrors>(new Map())

  const { preparedTypesForSelect, preparedRegionsForSelect, preparedDirectionsForSelect } =
    useNpaSelectValues()

  const preparedInitialFilters = useMemo(() => {
    if (!initialFilters) return defaultFiltersValue.filters

    return {
      ...defaultFiltersValue.filters,
      isMunicipal: initialFilters?.isMunicipal,
      ...initialFilters,
      isFederal: initialFilters?.isFederal,
      isRegional: initialFilters?.isRegional,
      region: initialFilters?.region ?? defaultFiltersValue.filters.region,
      oktmo: initialFilters?.oktmo ?? defaultFiltersValue.filters.oktmo,
    }
  }, [])

  const {
    filtersInstance,
    currentFilters,
    currentSearchString,
    preparedKeyToSwr,
    handleApplyFilters,
    debouncedSearchValue,
  } = useNpaFilters({
    keyMutator: {
      excludedPartIds: excludedPartIds?.length ? excludedPartIds : undefined,
      _key: 'npasModalList',
    },
    initialFilters: preparedInitialFilters,
  })

  const mainDirection = useWatch({
    control: filtersInstance?.control,
    name: 'filters.direction',
  })

  const normalizedData = useWatch({
    control: formInstance.control,
    name: chooseNpaBlockValues.normalizedData,
  })

  const gridSwrInstance = useNpaModalList({
    key: preparedKeyToSwr,
    config: {
      isPaused: () => viewMode === 'chosen',
      onError: LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          searchString: currentSearchString,
          filters: currentFilters,
        },
        componentInfo: {
          componentName: 'ChooseModalManager',
          moduleName: 'RegistryNPAChoose',
          componentType: 'manager',
        },
      }),
    },
  })

  const { handleOpenErrorModal } = useErrorModal()

  const handleNormalizeData = useCallback(
    (npaList: TMasterModalNpaItemWithId[]) => {
      const formValues = getValues()

      const preparedData = normalizeNpaDataNew(npaList)

      resetForm({
        ...formValues,
        normalizedData: preparedData,
      })
    },
    [getValues, resetForm],
  )

  const handleBuildThreeToRender = useCallback(() => {
    const formValues = getValues()

    const preparedThree = buildThreeToRender(gridSwrInstance.npaListWithIds, formValues.chosenParts)

    resetForm({
      ...formValues,
      threeToRender: preparedThree,
    })
  }, [gridSwrInstance.npaListWithIds, getValues, resetForm])

  const handleChangeViewMode = useCallback((viewMode: ViewModalType) => {
    setViewMode(viewMode)
  }, [])

  const handleOpenFillErrorModal = useCallback(() => {
    handleOpenErrorModal({
      headerText: errorModalHeaderTexts.fillError,
      bodyText: errorModalBodyTexts.requiredFieldsFilled,
      customActions: {
        actions: [
          {
            dataTestId: 'ErrorModal-returnToFilling-button',
            fixWidth: true,
            children: errorModalButtonTexts.returnToFilling,
            onClick: () => handleChangeViewMode('chosen'),
            bindOnClose: true,
          },
        ],
        mergeDefault: false,
      },
    })
  }, [handleChangeViewMode, handleOpenErrorModal])

  const getThreeErrors = useCallback((currentChosenParts: ChosenPart[]) => {
    return currentChosenParts.reduce((previousValue, currentValue) => {
      if (
        isEmptyString(currentValue.justification) ||
        isNotValidLengthPartFieldValue(currentValue.justification) ||
        isNotValidLengthPartFieldValue(currentValue.partCorrection)
      ) {
        previousValue.set(currentValue.parentDirection || '', {
          [currentValue.npaId]: {
            [currentValue.partId]: true,
          },
        })
      }

      return previousValue
    }, new Map())
  }, [])

  const handleSubmitNpa = useCallback(async () => {
    const currentChosenParts = getValues(chooseNpaBlockValues.chosenParts)

    const threeErrors = getThreeErrors(currentChosenParts)

    const npaType = getNpaPropertyPrefixByDocumentType(documentType)

    if (threeErrors.size) {
      setThreeErrors(threeErrors)

      return handleOpenFillErrorModal()
    }

    const preparedChosen = currentChosenParts.map((chosenPart) => ({
      [`${npaType}Id`]: {
        id: chosenPart.npaId,
      },
      [`${npaType}PartId`]: {
        id: chosenPart.partId,
      },
      [`${npaType}Justification`]: chosenPart.justification,
      [`${npaType}Correction`]: isNotEmptyString(chosenPart.partCorrection)
        ? chosenPart.partCorrection
        : null,
    }))

    enableRequestLoading()

    try {
      await onAddItemToList(preparedChosen)

      onClose?.()
    } catch (error) {
      const additionInfo = {
        documentType,
        body: {
          npaParts: preparedChosen,
        },
      }
      const componentInfo: ComponentInfo = {
        componentName: 'ChooseModalManager',
        moduleName: 'RegistryNPAChoose',
        componentType: 'updateNPADraft',
      }

      if (!isAxiosError(error)) {
        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo,
        })(error)

        throw error
      }

      handleOpenErrorModal({
        headerText: errorModalHeaderTexts.defaultMessage,
        bodyText: error.response?.data.detail || errorModalBodyTexts.defaultMessage,
      })

      throw error
    } finally {
      disableRequestLoading()
    }
  }, [
    chosenParts,
    disableRequestLoading,
    documentType,
    enableRequestLoading,
    getThreeErrors,
    getValues,
    handleOpenErrorModal,
    handleOpenFillErrorModal,
    onAddItemToList,
    onClose,
  ])

  // Наполняет нормализованные данные. Срабатывает один раз при открытии модалки
  useEffect(() => {
    if (!gridSwrInstance?.npaList) return

    //Нормализация должна сработать только при первом открытии
    if (filtersInstance.getValues().filters.direction === '' && !normalizedData.parts.length) {
      handleNormalizeData(gridSwrInstance.npaListWithIds)
    }
  }, [gridSwrInstance?.npaListWithIds])

  // Создается новое дефолтное дерево при смене вкладок или при изменении фильтров
  useEffect(() => {
    if (viewMode === 'chosen' || !gridSwrInstance?.npaList) return

    handleBuildThreeToRender()
  }, [gridSwrInstance?.npaListWithIds, viewMode, mainDirection])

  const providerValue: NpaModalContextProps = useMemo(
    () => ({
      state: {
        viewMode,
        gridSwrInstance,
        requestLoading,
        filtersInstance,
        initialFilters: preparedInitialFilters,
        threeErrors,
        forSelect: {
          directionOptions: preparedDirectionsForSelect,
          typeOptions: preparedTypesForSelect,
          regionOptions: preparedRegionsForSelect,
        },
        debouncedSearchValue,
      },
      handlers: {
        handleChangeViewMode,
        handleApplyFilters,
      },
      actions: {
        handleSubmitNpa,
      },
    }),
    [
      viewMode,
      gridSwrInstance,
      requestLoading,
      filtersInstance,
      threeErrors,
      preparedDirectionsForSelect,
      preparedInitialFilters,
      preparedTypesForSelect,
      preparedRegionsForSelect,
      debouncedSearchValue,
      handleChangeViewMode,
      handleApplyFilters,
      handleSubmitNpa,
    ],
  )

  return (
    <ChooseNpaModalContext.Provider value={providerValue}>
      {children}
    </ChooseNpaModalContext.Provider>
  )
}

export { useChooseNpaManager }
export default memo(ChooseModalManager)
