import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayout from './Layout'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyModalManager from './Manager'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu from './Menu'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyStatementForm from './Statement'

interface NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyControlFC
  extends FC {
  Layout: typeof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayout
  Menu: typeof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu
  StatementForm: typeof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyStatementForm
  Manager: typeof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyModalManager
}

const useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyControl =
  () => {
    const popupManager = usePopupManager()

    const openNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyModal =
      useCallback(
        (props: FormModalControlProps) => {
          const { formId, onClose, ...rest } = props

          if (!formId) {
            onClose?.()

            return
          }

          popupManager.open<FormModalControlProps>(
            NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayout,
            {
              ...rest,
              formId,
              onClose: onClose || (() => null),
            },
          )
        },
        [popupManager],
      )

    return {
      openNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyModal,
    }
  }

const NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny: NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyControlFC =
  () => null

NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny.Menu =
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny.Layout =
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayout
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny.StatementForm =
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyStatementForm
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny.Manager =
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyModalManager

export { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyControl }
export default NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny
