import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { StatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/types'
import { defaultRHFValidation } from '@constants/validations'

const oneSectionStatementAlreadyInvestmentsValidationMap: FormValuesValidationSection<
  keyof StatementAlreadyInvestmentsFormValues['1'],
  StatementAlreadyInvestmentsFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
  investorHeaderPosition: {
    required: defaultRHFValidation.required,
  },
  investorHeaderNameGenitive: {
    required: defaultRHFValidation.required,
  },
  investorHeaderPositionGenitive: {
    required: defaultRHFValidation.required,
  },
  investorHeaderReasonGenitive: {
    required: defaultRHFValidation.required,
  },
}

export { oneSectionStatementAlreadyInvestmentsValidationMap }
