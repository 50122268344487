import React from 'react'
import { FieldValues } from 'react-hook-form'

import FormFieldPublicControl, {
  FormFieldPublicControlProps,
} from '@components/DocumentFormComponents/FormControls/FormFieldPublicControl'
import FormFieldTooltipControl, {
  FormFieldTooltipControlProps,
} from '@components/DocumentFormComponents/FormControls/FormFieldTooltipControl'
import SubscribableControl, {
  SubscribableControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { isFunction, isUndefined } from '@helpers/checkTypes'
import withConditionWrappedComponentRendering from '@hoc/withConditionWrappedComponentRendering'

interface FormFieldTooltipControlWrapperProps<T extends FieldValues>
  extends FormFieldTooltipControlProps<T> {
  isNew?: boolean
}

interface FormFieldControlProps<
  T extends FieldValues,
  Modifier extends FieldValues | undefined = undefined,
> extends SubscribableControlProps<T> {
  formFieldTooltipProps?: Omit<FormFieldTooltipControlWrapperProps<T>, 'children' | 'name'>
  formFieldPublicProps?: Omit<
    FormFieldPublicControlProps<Modifier extends FieldValues ? Modifier : T>,
    'children' | 'name'
  >
}

const FormFieldTooltipWrapper = withConditionWrappedComponentRendering<
  FormFieldTooltipControlWrapperProps<any>
>(FormFieldTooltipControl, ({ isNew }) => !isUndefined(isNew) && !isNew)

const FormFieldPublicWrapper = withConditionWrappedComponentRendering<
  FormFieldPublicControlProps<any>
>(FormFieldPublicControl, ({ control }) => !isUndefined(control))

const FormFieldControl = <
  T extends FieldValues,
  Modifier extends FieldValues | undefined = undefined,
>({
  path,
  formFieldTooltipProps,
  formFieldPublicProps,
  children,
  propertiesProps,
  ...restSubscribableControlProps
}: FormFieldControlProps<T, Modifier>) => {
  return (
    <SubscribableControl
      {...restSubscribableControlProps}
      propertiesProps={propertiesProps}
      path={path}
    >
      {(props) => (
        <FormFieldTooltipWrapper {...formFieldTooltipProps} name={path}>
          <FormFieldPublicWrapper {...formFieldPublicProps} name={path}>
            {isFunction(children) ? children(props) : children}
          </FormFieldPublicWrapper>
        </FormFieldTooltipWrapper>
      )}
    </SubscribableControl>
  )
}

export type { FormFieldControlProps }
export default FormFieldControl
