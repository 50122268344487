import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { technologicalRequirementsValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/10/AdditionalForms/TechnologicalRequirementsForm/validation'
import { TechnologicalRequirementsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/10/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface TechnologicalRequirementsFormProps {
  id: string
  control: Control<ProjectSZPKFormValues>
  name: TechnologicalRequirementsArrayPathName
  onRemoveObjectStep?: VoidFunction
}

const TechnologicalRequirementsForm: FC<TechnologicalRequirementsFormProps> = ({
  id,
  name,
  control,
  onRemoveObjectStep,
}) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate id={id} dataTestId='projectNpaListItem' onRemoveStep={editMode ? onRemoveObjectStep : undefined}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.projectNpaName`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.projectNpaName`,
                  rules: technologicalRequirementsValidationMap.projectNpaName,
                  textareaProps: {
                    dataTestId: 'projectNpaName',
                    label: 'Наименование и реквизиты акта',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectNpaName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.projectNpaName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default TechnologicalRequirementsForm
