import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import RegistrationForm from '@components/Forms/RegistrationForm'
import { RegistrationLocationState } from '@components/Forms/RegistrationForm/types'
import WithTechnicalWork from '@components/TechnicalWork/Wrapper'
import { Paths } from '@constants/paths'
import AuthLayout from '@layouts/Auth'
import loginStyles from '@routes/LoginPage/LoginPage.module.scss'
import cn from 'classnames'

const RegistrationPage = () => {
  const location = useLocation()
  const locationState = location?.state as RegistrationLocationState | undefined

  const navigate = useNavigate()

  useEffect(() => {
    if (!locationState?.from) navigate(Paths.Login, { replace: true })
  }, [])

  return (
    <WithTechnicalWork>
      <AuthLayout>
        <div className={cn(loginStyles.loginPage)}>
          <RegistrationForm />
        </div>
      </AuthLayout>
    </WithTechnicalWork>
  )
}

export default RegistrationPage
