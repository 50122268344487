import { UseFormReturn, useWatch } from 'react-hook-form'

import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'

// В валидации используется getValues на эти поля, useWatch нужен, чтобы сделать их реактивными
export const useWatchFieldsForValidation = (
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>,
) => {
  useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
    control: formInstance.control,
  })
  useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
      .projectSupportCompensation,
    control: formInstance.control,
  })
  useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
      .projectRelatedCompensation,
    control: formInstance.control,
  })
}
