import { PopupProps, usePopupManager } from 'react-popup-manager'

import CreateMembersModal, { TCreateMembersModal } from '.'

const useCreateMembersModal = () => {
  const popupManager = usePopupManager()

  const handleOpenCreateMembersModal = ({
    isProjectFederal,
    isDocumentChange,
    projectId,
    onAddMembers,
    onClose,
  }: TCreateMembersModal & PopupProps) => {
    popupManager.open(CreateMembersModal, {
      projectId,
      isProjectFederal,
      isDocumentChange,
      onAddMembers,
      onClose: onClose ?? (() => null),
    })
  }

  return {
    handleOpenCreateMembersModal,
  }
}

export { useCreateMembersModal }
