import { GetChangePropertiesData } from '@context/APIContext/hooks/usePropertiesApi'
import { isObject } from '@helpers/checkTypes'

const isCheckFormChangedError = (error: unknown): error is CheckFormChangesCallbackError => {
  return isObject(error) && 'formIsChanged' in error && 'changedProperties' in error
}

interface CheckFormChangesCallbackError {
  formIsChanged: boolean
  changedProperties: GetChangePropertiesData
  lastChangesDt: string
}
export { isCheckFormChangedError }
export type { CheckFormChangesCallbackError }
