import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { IDocumentMetaInfo } from '@services/Documents/documents.entity'
import useSWRImmutable from 'swr/immutable'

const useDocumentMetaInfo = ({ key, config }: SWRHookProps<IDocumentMetaInfo | undefined>) => {
  const {
    documentsApi: { getDocumentMetaInfoById },
  } = useAPIContext()

  const {
    data: documentMetaInfo,
    error,
    mutate,
    isValidating: isLoadingDocumentMetaInfo,
  } = useSWRImmutable(key, getDocumentMetaInfoById, config)

  return {
    documentMetaInfo,
    error,
    mutate,
    isLoadingDocumentMetaInfo,
  }
}

export { useDocumentMetaInfo }
