import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'

const {
  getDefaultInvestorFieldState,
  getDefaultRfFieldState,
  getDefaultSubjectsFieldState,
  getDefaultMunicipalitiesFieldState,
} = FieldStateHelpers

const mapOfAdditionalAgreementOnTerminationFfSZPKMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация, реализующая проект' },
  { id: '5', title: 'Расторжение СЗПК' },
]

const defaultAdditionalAgreementOnTerminationFfSZPKFormValues: AdditionalAgreementOnTerminationFfSZPKFormValues =
  {
    '1': getDefaultRfFieldState(),
    '2': {
      subjects: getDefaultSubjectsFieldState(),
    },
    '3': {
      municipalities: getDefaultMunicipalitiesFieldState(),
    },
    '4': getDefaultInvestorFieldState(),
    '5': {
      ruleLinkAgreementTerminationExtra: '',
    },
    additionalFields: {
      isFederal: true,
      initialMunicipalities: [],
    },
  }

const additionalAgreementOnTerminationFfSZPKValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorFullName: '4.investorFullName',
    investorHeaderName: '4.investorHeaderName',
    investorHeaderNameGenitive: '4.investorHeaderNameGenitive',
    investorHeaderPosition: '4.investorHeaderPosition',
    investorHeaderPositionGenitive: '4.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '4.investorHeaderReasonGenitive',
  },
  '5': {
    ruleLinkAgreementTerminationExtra: '5.ruleLinkAgreementTerminationExtra',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
  },
} as const

export {
  additionalAgreementOnTerminationFfSZPKValues,
  defaultAdditionalAgreementOnTerminationFfSZPKFormValues,
  mapOfAdditionalAgreementOnTerminationFfSZPKMenu,
}
