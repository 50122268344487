import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { supplementalCostSharingABlockValues } from '@components/Forms/SupplementalCostSharingAForm/const'
import SupplementalCostSharingAContractDutyMember from '@components/Forms/SupplementalCostSharingAForm/Forms/5/AdditionalForms/ContractDutyMember'
import { SupplementalCostSharingAContractDutyMemberPathName } from '@components/Forms/SupplementalCostSharingAForm/Forms/5/types'
import { useSupplementalCostSharingAManager } from '@components/Forms/SupplementalCostSharingAForm/Manager'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import {
  SupplementalCostSharingAFieldArrayControlUpdateWatcher,
  SupplementalCostSharingAFieldsControlUpdateWatcher,
} from '@components/Forms/SupplementalCostSharingAForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { isFormFieldError } = DocumentFormHelpers

const Fifth = () => {
  const formInstance = useFormContext<SupplementalCostSharingAFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useSupplementalCostSharingAManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: SupplementalCostSharingAFieldsControlUpdateWatcher,
  })

  const { fields: contractDutyMembers } = useFieldArraySubscribableControl<
    SupplementalCostSharingAFormValues,
    SupplementalCostSharingAContractDutyMemberPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: supplementalCostSharingABlockValues['5'].contractDutyMembers,
    keyName: 'keyNameId',
    watcher: SupplementalCostSharingAFieldArrayControlUpdateWatcher,
  })

  const contractDutyMembersError = formInstance.getFieldState(
    supplementalCostSharingABlockValues['5'].contractDutyMembers,
  )?.error

  if (!formInstance) return null

  const handleAddNewContractDutyMember = async () => {
    await handleAddItemToListWithOutValue?.(
      supplementalCostSharingABlockValues['5'].contractDutyMembers,
    )
  }

  const handleRemoveContractDutyMember = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${supplementalCostSharingABlockValues['5'].contractDutyMembers}.${index}`,
      supplementalCostSharingABlockValues['5'].contractDutyMembers,
    )
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: supplementalCostSharingABlockValues['5'].contractDutyMembers,
          })}
        >
          <FlipperList list={contractDutyMembers}>
            {contractDutyMembers.map((member, index) => {
              const formName =
                `${supplementalCostSharingABlockValues['5'].contractDutyMembers}.${index}` as const

              return (
                <SupplementalCostSharingAContractDutyMember
                  key={member.id}
                  id={member.id}
                  formName={formName}
                  onContractDutyMemberRemove={handleRemoveContractDutyMember(index)}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(contractDutyMembersError) && !contractDutyMembers.length && (
          <Row className={'px-1'}>
            <FormError rowClassName={'px-3'} colClassName={'px-3'}>
              должна быть заполнена хотя бы одна сторона договора
            </FormError>
          </Row>
        )}
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddNewContractDutyMember}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    dataTestId="ExpensesCompensationArrayForm-add-button"
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить сторону договора
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Fifth
