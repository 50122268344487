import { FC } from 'react'

import MainLayout from './Main'

interface LayoutProps extends FC {
  Main: typeof MainLayout
}

const Layout: LayoutProps = () => null

Layout.Main = MainLayout

export default Layout
