import React, { useMemo } from 'react'

import Typography from '@components/NewDesign/Typography'
import { documentsLinks } from '@constants/documents'
import { NewDfosType, RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { useProject } from '@hooks/new/swr/useProject'
import { TAllDfoTypes, TDfoTerminationType } from '@services/Dfo/Dfo.entity'

export const mapOfRejectLogic = {
  regional: {
    INVESTOR: [NewDfosType.SZPK_TERMINATE],
    OIV: [NewDfosType.SZPK_TERMINATE_JUDGMENT, NewDfosType.SZPK_TERMINATE_ONE_SIDE],
  },
  federal: {
    INVESTOR: [NewDfosType.SZPK_TERMINATE],
    OIV: [NewDfosType.SZPK_TERMINATE_ONE_SIDE],
    MER: [NewDfosType.SZPK_TERMINATE_JUDGMENT, NewDfosType.SZPK_TERMINATE_ONE_SIDE],
    OMSU: [NewDfosType.SZPK_TERMINATE, NewDfosType.SZPK_TERMINATE_ONE_SIDE],
  },
} as const

export const mapOfRejectTypes = {
  SZPK_TERMINATE: {
    title: 'По соглашению сторон',
    description: (
      <>
        В случаях, предусмотренных{' '}
        <Typography.Caption as={'span'} variant={'captionSRegular'} color={'text-accent-brand'}>
          {
            <a href={documentsLinks.FZ_N69} target={'_blank'} rel={'noopener noreferrer'}>
              {' '}
              частью 13 статьи 11 69-ФЗ
            </a>
          }
        </Typography.Caption>
      </>
    ),
  },
  SZPK_TERMINATE_JUDGMENT: {
    title: 'По решению суда',
    description: (
      <>
        Когда вынесено решение суда о расторжении соглашения{' '}
        <Typography.Caption as={'span'} variant={'captionSRegular'} color={'text-accent-brand'}>
          {
            <a href={documentsLinks.FZ_N69} target={'_blank'} rel={'noopener noreferrer'}>
              (часть 13,15 статьи 11 и статьи 13 69-ФЗ)
            </a>
          }
        </Typography.Caption>
      </>
    ),
  },
  SZPK_TERMINATE_ONE_SIDE: {
    title: 'В одностороннем порядке',
    description: (
      <>
        В случаях, предусмотренных{' '}
        <Typography.Caption as={'span'} variant={'captionSRegular'} color={'text-accent-brand'}>
          {
            <a href={documentsLinks.FZ_N69} target={'_blank'} rel={'noopener noreferrer'}>
              частью 14 статьи 11 69-ФЗ
            </a>
          }
        </Typography.Caption>
      </>
    ),
  },
} as const

const useProcessRejectTypes = (
  projectId?: string,
  dfoAvailableForCreate?: TAllDfoTypes[],
): TDfoTerminationType[] => {
  const { getUserRole } = useAuthContext()

  const roles = getUserRole?.()

  const { project } = useProject({
    key: { projectId, _key: 'project' },
    config: {
      isPaused: () => !projectId,
    },
  })

  const currentRejectTypes = useMemo(() => {
    if (!project || !roles || !dfoAvailableForCreate) return []

    const currentRole =
      roles.roles.find(
        (role) =>
          role === RolesTypes.INVESTOR ||
          role === RolesTypes.MER ||
          role === RolesTypes.OIV ||
          role === RolesTypes.OMSU,
      ) || []

    const keyToTypeOfSZPK = project.isFederal ? 'federal' : 'regional'

    const rejectTypesFromConfig = mapOfRejectLogic[keyToTypeOfSZPK][currentRole] as
      | TDfoTerminationType[]
      | undefined

    if (!rejectTypesFromConfig) return []

    //Находим пересечение конфига и dfo с возможного создания
    const intersectedRejectTypesWithDfoAvailable = dfoAvailableForCreate.filter((dfo) =>
      rejectTypesFromConfig.includes(dfo as TDfoTerminationType),
    )

    return intersectedRejectTypesWithDfoAvailable as TDfoTerminationType[]
  }, [dfoAvailableForCreate, project, roles])

  return currentRejectTypes
}

export { useProcessRejectTypes }
