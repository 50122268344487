import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  CertificateOnMonitoringResultsCompensationDismantling,
  CertificateOnMonitoringResultsCompensationExpenses,
  CertificateOnMonitoringResultsCompensationMonitoring,
  CertificateOnMonitoringResultsCompensationMonitoringChecker,
  CertificateOnMonitoringResultsCompensationPercentCredit,
  CertificateOnMonitoringResultsCompensationRelatedInfrastructure,
  CertificateOnMonitoringResultsCompensationSupportingInfrastructure,
  CertificateOnMonitoringResultsDismantlingObjectChecker,
  CertificateOnMonitoringResultsExpenseChecker,
  CertificateOnMonitoringResultsFormValues,
  CertificateOnMonitoringResultsPercentCreditChecker,
  CertificateOnMonitoringResultsPercentCreditMembersChecker,
  CertificateOnMonitoringResultsStateSupportChecker,
  CertificateOnMonitoringResultsStepMonitoringChecker,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { objOfDateFormats } from '@constants/dateFormats'
import { sortByStringWithNumber } from '@helpers/array/sortByStringWithNumber'
import { isArray, isEmptyString, isNull, isNullOrUndefined } from '@helpers/checkTypes'
import { convertStringToDateFormat } from '@helpers/date/convertStringToDateFormat'
import DayjsService from '@services/Dayjs/Dayjs.service'

const { getYearOfDateFromPropertyDateValue, formatBackendMoneyToString } = DocumentFormHelpers

const generateRHFObjectForCertificateOnMonitoringResultsForm = (
  objectFromPropertyState: Record<string, any>,
): CertificateOnMonitoringResultsFormValues => {
  const preparedStepsMonitoringChecker: CertificateOnMonitoringResultsStepMonitoringChecker[] =
    (() => {
      if (
        !isArray(objectFromPropertyState.stepsMonitoringChecker?.value) ||
        !objectFromPropertyState.stepsMonitoringChecker?.value?.length
      )
        return []

      return objectFromPropertyState.stepsMonitoringChecker?.value.map(
        (stepMonitoringChecker, index): CertificateOnMonitoringResultsStepMonitoringChecker => {
          const preparedStepCompensationPeriod = isArray(
            stepMonitoringChecker?.value?.stepMonitoringInvestor?.value.planStepId
              ?.stepCompensations,
          )
            ? sortByStringWithNumber(
                Array.from(
                  new Set(
                    stepMonitoringChecker?.value?.stepMonitoringInvestor?.value.planStepId.stepCompensations.map(
                      (stepCompensation) => stepCompensation.stepCompensationPeriod?.name,
                    ),
                  ),
                ),
              ).join(', ')
            : ''

          const factStepCompensationPeriod =
            stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.factStepCompensationPeriod
          const factStepCompensationEndPeriod =
            stepMonitoringChecker.value?.stepMonitoringInvestor?.value
              ?.factStepCompensationEndPeriod

          const preparedFactStepCompensationPeriod =
            isEmptyString(factStepCompensationPeriod) || isNull(factStepCompensationPeriod)
              ? ''
              : DayjsService.dayjs(
                  factStepCompensationPeriod,
                  objOfDateFormats.defaultNativeDateFormat,
                ).format(objOfDateFormats.defaultFormat)
          const preparedFactStepCompensationEndPeriod =
            isEmptyString(factStepCompensationEndPeriod) || isNull(factStepCompensationEndPeriod)
              ? ''
              : DayjsService.dayjs(
                  factStepCompensationEndPeriod,
                  objOfDateFormats.defaultNativeDateFormat,
                ).format(objOfDateFormats.defaultFormat)

          return {
            id: stepMonitoringChecker.propertyId,
            anchorId: `5.${index + 1}`,
            stepNumber:
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.planStepId?.stepNumber,

            planStepTotalCapitalValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.planStepTotalCapitalValue,
            ),
            planStepTotalCapitalVatValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value
                ?.planStepTotalCapitalVatValue,
            ),
            factStepTotalCapitalValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.factStepTotalCapitalValue,
            ),
            factStepTotalCapitalVatValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value
                ?.factStepTotalCapitalVatValue,
            ),
            factStepTotalCapitalValueChecker:
              stepMonitoringChecker.value?.factStepTotalCapitalValueChecker?.value,
            factStepTotalCapitalVatValueChecker:
              stepMonitoringChecker.value?.factStepTotalCapitalVatValueChecker?.value,
            diffStepTotalCapitalChecker:
              stepMonitoringChecker.value?.diffStepTotalCapitalChecker?.value,

            stepInvestorCapitalValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.planStepId
                ?.stepInvestorCapitalValue,
            ),
            stepInvestorCapitalValueVAT: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.planStepId
                ?.stepInvestorCapitalValueVAT,
            ),
            factStepInvestorCapitalValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value
                ?.factStepInvestorCapitalValue,
            ),
            factStepInvestorCapitalVatValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value
                ?.factStepInvestorCapitalVatValue,
            ),
            factStepInvestorCapitalValueChecker:
              stepMonitoringChecker.value?.factStepInvestorCapitalValueChecker?.value,
            factStepInvestorCapitalVatValueChecker:
              stepMonitoringChecker.value?.factStepInvestorCapitalVatValueChecker?.value,
            diffStepInvestorCapitalValueChecker:
              stepMonitoringChecker.value?.diffStepInvestorCapitalValueChecker?.value,

            stepCompensationValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.planStepId
                ?.stepCompensationValue,
            ),
            stepCompensationValueVAT: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.planStepId
                ?.stepCompensationValueVAT,
            ),
            factStepCompensationValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.factStepCompensationValue,
            ),
            factStepCompensationVatValue: formatBackendMoneyToString(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value
                ?.factStepCompensationVatValue,
            ),
            factStepCompensationValueChecker:
              stepMonitoringChecker.value?.factStepCompensationValueChecker?.value,
            factStepCompensationVatValueChecker:
              stepMonitoringChecker.value?.factStepCompensationVatValueChecker?.value,
            diffStepCompensationValueChecker:
              stepMonitoringChecker.value?.diffStepCompensationValueChecker?.value,

            stepCompensationPeriod: preparedStepCompensationPeriod,
            factStepCompensationPeriod: getYearOfDateFromPropertyDateValue(
              preparedFactStepCompensationPeriod,
            ),
            factStepCompensationEndPeriod: getYearOfDateFromPropertyDateValue(
              preparedFactStepCompensationEndPeriod,
            ),
            factStepCompensationPeriodChecker: getYearOfDateFromPropertyDateValue(
              stepMonitoringChecker.value?.factStepCompensationPeriodChecker?.value,
            ),
            factStepCompensationPeriodEndChecker: getYearOfDateFromPropertyDateValue(
              stepMonitoringChecker?.value?.factStepCompensationPeriodEndChecker?.value,
            ),

            planStepWorkPlace:
              stepMonitoringChecker?.value?.stepMonitoringInvestor?.value?.planStepWorkPlace,
            factStepWorkPlace:
              stepMonitoringChecker?.value?.stepMonitoringInvestor?.value?.factStepWorkPlace,
            factStepWorkPlaceChecker: stepMonitoringChecker?.value?.factStepWorkPlaceChecker?.value,
            diffStepWorkPlaceChecker: stepMonitoringChecker?.value?.diffStepWorkPlaceChecker?.value,

            stepStartDate: convertStringToDateFormat(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.planStepId?.stepStartDate,
            ),
            stepEndDate: convertStringToDateFormat(
              stepMonitoringChecker.value?.stepMonitoringInvestor?.value?.planStepId?.stepEndDate,
            ),
            factStepStartDate: convertStringToDateFormat(
              stepMonitoringChecker?.value?.stepMonitoringInvestor?.value?.factStepStartDate,
            ),
            factStepEndDate: convertStringToDateFormat(
              stepMonitoringChecker?.value?.stepMonitoringInvestor?.value?.factStepEndDate,
            ),

            factStepStartDateChecker: stepMonitoringChecker?.value?.factStepStartDateChecker?.value,
            factStepEndDateChecker: stepMonitoringChecker?.value?.factStepEndDateChecker?.value,
            diffStepDateChecker: stepMonitoringChecker?.value?.diffStepDateChecker?.value,

            stepInfo: stepMonitoringChecker?.value?.stepMonitoringInvestor?.value?.stepInfoInvestor,
            stepInfoChecker:
              stepMonitoringChecker?.value?.stepMonitoringInvestor?.value?.stepInfoChecker,
          }
        },
      )
    })()

  const preparedPercentCreditsChecker: CertificateOnMonitoringResultsPercentCreditChecker[] =
    (() => {
      if (
        !isArray(objectFromPropertyState.percentCreditsChecker?.value) ||
        !objectFromPropertyState.percentCreditsChecker?.value?.length
      )
        return []

      return objectFromPropertyState.percentCreditsChecker?.value.map(
        (percentCredit): CertificateOnMonitoringResultsPercentCreditChecker => {
          const preparedPercentCreditMembersChecker: CertificateOnMonitoringResultsPercentCreditMembersChecker[] =
            (() => {
              if (
                !isArray(percentCredit.value?.percentCreditMembersChecker?.value) ||
                !percentCredit.value?.percentCreditMembersChecker?.value?.length
              )
                return []

              return percentCredit.value?.percentCreditMembersChecker?.value.map(
                (
                  percentCreditMember,
                ): CertificateOnMonitoringResultsPercentCreditMembersChecker => ({
                  id: percentCreditMember.propertyId,
                  percentCreditMemberChecker: percentCreditMember?.value || '',
                }),
              )
            })()

          return {
            id: percentCredit.propertyId,
            percentCreditNameChecker: percentCredit.value?.percentCreditNameChecker?.value,
            percentCreditNumberChecker: percentCredit.value?.percentCreditNumberChecker?.value,
            percentCreditDateChecker: percentCredit.value?.percentCreditDateChecker?.value,
            percentCreditMembersChecker: preparedPercentCreditMembersChecker,
          }
        },
      )
    })()

  const preparedDismantlingObjectsChecker: CertificateOnMonitoringResultsDismantlingObjectChecker[] =
    (() => {
      if (
        !isArray(objectFromPropertyState.dismantlingObjectsChecker?.value) ||
        !objectFromPropertyState.dismantlingObjectsChecker?.value?.length
      )
        return []

      return objectFromPropertyState.dismantlingObjectsChecker?.value.map(
        (dismantlingObject): CertificateOnMonitoringResultsDismantlingObjectChecker => ({
          id: dismantlingObject.propertyId,
          dismantlingObjectNameChecker:
            dismantlingObject.value?.dismantlingObjectNameChecker?.value,
        }),
      )
    })()

  const preparedExpensesChecker: CertificateOnMonitoringResultsExpenseChecker[] = (() => {
    if (
      !isArray(objectFromPropertyState.expensesChecker?.value) ||
      !objectFromPropertyState.expensesChecker?.value?.length
    )
      return []

    return objectFromPropertyState.expensesChecker?.value.map(
      (expense): CertificateOnMonitoringResultsExpenseChecker => ({
        id: expense.propertyId,
        expenseNameChecker: expense.value?.expenseNameChecker?.value,
      }),
    )
  })()

  const preparedStateSupportsChecker: CertificateOnMonitoringResultsStateSupportChecker[] = (() => {
    if (
      !isArray(objectFromPropertyState.stateSupportsChecker?.value) ||
      !objectFromPropertyState.stateSupportsChecker?.value?.length
    )
      return []

    return objectFromPropertyState.stateSupportsChecker?.value.map(
      (stateSupport): CertificateOnMonitoringResultsStateSupportChecker => ({
        id: stateSupport.propertyId,
        stateSupportNameChecker: stateSupport.value.stateSupportNameChecker?.value,
        stateSupportReasonChecker: stateSupport.value.stateSupportReasonChecker?.value,
        stateSupportValueChecker: stateSupport.value.stateSupportValueChecker?.value,
        stateSupportValue: !!stateSupport.value?.stateSupportInvestor?.value?.id
          ? formatBackendMoneyToString(
              stateSupport.value?.stateSupportInvestor?.value?.stateSupportValue,
            )
          : '',
      }),
    )
  })()

  const preparedCompensationsMonitoringChecker: CertificateOnMonitoringResultsCompensationMonitoringChecker[] =
    (() => {
      if (
        !isArray(objectFromPropertyState.compensationsMonitoringChecker?.value) ||
        !objectFromPropertyState.compensationsMonitoringChecker?.value?.length
      )
        return []

      return objectFromPropertyState.compensationsMonitoringChecker?.value.map(
        (compensation, index): CertificateOnMonitoringResultsCompensationMonitoringChecker => {
          const preparedCompensationsRelatedInfrastructureChecker: CertificateOnMonitoringResultsCompensationRelatedInfrastructure[] =
            (() => {
              if (
                !isArray(compensation?.value?.compensationsRelatedInfrastructureChecker?.value) ||
                !compensation?.value?.compensationsRelatedInfrastructureChecker?.value?.length
              )
                return []

              return compensation.value?.compensationsRelatedInfrastructureChecker?.value.map(
                (
                  compensationRelated,
                ): CertificateOnMonitoringResultsCompensationRelatedInfrastructure => ({
                  id: compensationRelated.propertyId,
                  objectName: !!compensationRelated.value.compensationRelatedInfrastructureInvestor
                    ?.value?.relatedInfrastructureObject?.id
                    ? {
                        value:
                          compensationRelated.value.compensationRelatedInfrastructureInvestor?.value
                            ?.relatedInfrastructureObject?.id || '',
                        displayValue:
                          compensationRelated.value.compensationRelatedInfrastructureInvestor?.value
                            ?.relatedInfrastructureObject?.objectName || '',
                      }
                    : null,
                  relatedInfrastructureObjectValue: formatBackendMoneyToString(
                    compensationRelated?.value?.compensationRelatedInfrastructureInvestor?.value
                      .relatedInfrastructureObjectValue,
                  ),
                  relatedInfrastructureObjectValueChecker:
                    compensationRelated?.value?.relatedInfrastructureObjectValueChecker?.value,
                }),
              )
            })()

          const preparedCompensationsSupportingInfrastructureChecker: CertificateOnMonitoringResultsCompensationSupportingInfrastructure[] =
            (() => {
              if (
                !isArray(
                  compensation?.value?.compensationsSupportingInfrastructureChecker?.value,
                ) ||
                !compensation?.value?.compensationsSupportingInfrastructureChecker?.value?.length
              )
                return []

              return compensation.value?.compensationsSupportingInfrastructureChecker?.value.map(
                (
                  compensationSupporting,
                ): CertificateOnMonitoringResultsCompensationSupportingInfrastructure => ({
                  id: compensationSupporting.propertyId,
                  objectName: !!compensationSupporting.value
                    .compensationSupportingInfrastructureInvestor?.value
                    ?.supportingInfrastructureObject?.id
                    ? {
                        value:
                          compensationSupporting.value.compensationSupportingInfrastructureInvestor
                            ?.value?.supportingInfrastructureObject?.id || '',
                        displayValue:
                          compensationSupporting.value.compensationSupportingInfrastructureInvestor
                            ?.value?.supportingInfrastructureObject?.objectName || '',
                      }
                    : null,
                  supportingInfrastructureObjectValue: formatBackendMoneyToString(
                    compensationSupporting?.value?.compensationSupportingInfrastructureInvestor
                      ?.value.supportingInfrastructureObjectValue,
                  ),
                  supportingInfrastructureObjectValueChecker:
                    compensationSupporting?.value?.supportingInfrastructureObjectValueChecker
                      ?.value,
                }),
              )
            })()

          const preparedCompensationsPercentCreditChecker: CertificateOnMonitoringResultsCompensationPercentCredit[] =
            (() => {
              if (
                !isArray(compensation?.value?.compensationsPercentCreditChecker?.value) ||
                !compensation?.value?.compensationsPercentCreditChecker?.value?.length
              )
                return []

              return compensation?.value?.compensationsPercentCreditChecker?.value.map(
                (
                  compensationPercentCredit,
                ): CertificateOnMonitoringResultsCompensationPercentCredit => {
                  const compensationPercentCreditInvestorValue =
                    compensationPercentCredit?.value?.compensationPercentCreditInvestor?.value

                  const percentCreditName =
                    compensationPercentCreditInvestorValue?.compensationPercentCreditId
                      ?.percentCreditName || ''
                  const percentCreditNumber =
                    compensationPercentCreditInvestorValue?.compensationPercentCreditId
                      ?.percentCreditNumber || ''

                  const firstConditionPercentCreditName = !isEmptyString(percentCreditNumber)
                    ? `${percentCreditName} ${percentCreditNumber}`
                    : percentCreditName

                  const percentCreditNameChecker =
                    compensationPercentCredit?.value?.compensationPercentCreditIdChecker?.value
                      ?.percentCreditNameChecker || ''
                  const percentCreditNumberChecker =
                    compensationPercentCredit?.value?.compensationPercentCreditIdChecker?.value
                      ?.percentCreditNumberChecker || ''

                  const secondConditionPercentCreditName = !isEmptyString(
                    percentCreditNumberChecker,
                  )
                    ? `${percentCreditNameChecker} ${percentCreditNumberChecker}`
                    : percentCreditNameChecker

                  const preparedPercentCreditName = !isNullOrUndefined(
                    compensationPercentCreditInvestorValue?.id,
                  )
                    ? firstConditionPercentCreditName
                    : secondConditionPercentCreditName

                  const preparedCompensationRelatedContractValue = !isNullOrUndefined(
                    compensationPercentCredit?.value?.compensationPercentCreditInvestor?.value?.id,
                  )
                    ? formatBackendMoneyToString(
                        compensationPercentCredit.value?.compensationPercentCreditInvestor?.value
                          ?.compensationRelatedContractValue,
                      )
                    : ''

                  return {
                    id: compensationPercentCredit.propertyId,
                    percentCreditName: preparedPercentCreditName,
                    compensationRelatedContractValue: preparedCompensationRelatedContractValue,
                    compensationRelatedContractValueChecker:
                      compensationPercentCredit?.value?.compensationRelatedContractValueChecker
                        ?.value,
                  }
                },
              )
            })()

          const preparedCompensationsDismantlingChecker: CertificateOnMonitoringResultsCompensationDismantling[] =
            (() => {
              if (
                !isArray(compensation?.value?.compensationsDismantlingChecker?.value) ||
                !compensation?.value?.compensationsDismantlingChecker?.value?.length
              )
                return []

              return compensation?.value?.compensationsDismantlingChecker?.value.map(
                (
                  compensationDismantling,
                ): CertificateOnMonitoringResultsCompensationDismantling => {
                  const preparedDismantlingObjectName = !isNullOrUndefined(
                    compensationDismantling?.value?.compensationDismantlingInvestor?.value?.id,
                  )
                    ? compensationDismantling?.value?.compensationDismantlingInvestor?.value
                        ?.dismantlingObjectId?.dismantlingObjectName
                    : compensationDismantling?.value?.dismantlingObjectIdChecker?.value
                        ?.dismantlingObjectNameChecker

                  const preparedCompensationRelatedContractValue = !isNullOrUndefined(
                    compensationDismantling?.value?.compensationDismantlingInvestor?.value?.id,
                  )
                    ? formatBackendMoneyToString(
                        compensationDismantling?.value?.compensationDismantlingInvestor?.value
                          ?.dismantlingObjectValue,
                      )
                    : ''

                  return {
                    id: compensationDismantling.propertyId,
                    dismantlingObjectName: preparedDismantlingObjectName,
                    compensationRelatedContractValue: preparedCompensationRelatedContractValue,
                    dismantlingObjectValueChecker:
                      compensationDismantling?.value?.dismantlingObjectValueChecker?.value,
                  }
                },
              )
            })()

          const preparedCompensationsExpensesChecker: CertificateOnMonitoringResultsCompensationExpenses[] =
            (() => {
              if (
                !isArray(compensation?.value?.compensationsExpensesChecker?.value) ||
                !compensation?.value?.compensationsExpensesChecker?.value?.length
              )
                return []

              return compensation?.value?.compensationsExpensesChecker?.value.map(
                (compensationExpense): CertificateOnMonitoringResultsCompensationExpenses => {
                  const preparedExpenseName = !isNullOrUndefined(
                    compensationExpense?.value?.compensationExpensesInvestor?.value?.id,
                  )
                    ? compensationExpense?.value?.compensationExpensesInvestor?.value?.expenseId
                        ?.expenseName
                    : compensationExpense?.value?.expenseIdChecker?.value?.expenseNameChecker

                  const preparedExpenseValue = !isNullOrUndefined(
                    compensationExpense?.value?.compensationExpensesInvestor?.value?.id,
                  )
                    ? formatBackendMoneyToString(
                        compensationExpense?.value?.compensationExpensesInvestor?.value
                          ?.expenseValue,
                      )
                    : ''

                  return {
                    id: compensationExpense.propertyId,
                    expenseName: preparedExpenseName,
                    expenseValue: preparedExpenseValue,
                    expenseValueChecker: compensationExpense?.value?.expenseValueChecker?.value,
                  }
                },
              )
            })()

          return {
            id: compensation.propertyId,
            anchorId: `9.${index + 1}`,
            compensationYearChecker: getYearOfDateFromPropertyDateValue(
              compensation.value.compensationYearChecker?.value,
            ),

            compensationsRelatedInfrastructureChecker:
              preparedCompensationsRelatedInfrastructureChecker,
            generalCompensationRelatedInfrastructure: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value
                .generalCompensationRelatedInfrastructure,
            ),
            generalCompensationRelatedInfrastructureChecker:
              compensation.value?.generalCompensationRelatedInfrastructureChecker?.value,

            compensationsSupportingInfrastructureChecker:
              preparedCompensationsSupportingInfrastructureChecker,
            generalCompensationSupportingInfrastructure: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value
                .generalCompensationSupportingInfrastructure,
            ),
            generalCompensationSupportingInfrastructureChecker:
              compensation.value?.generalCompensationSupportingInfrastructureChecker?.value,

            compensationsPercentCreditChecker: preparedCompensationsPercentCreditChecker,
            generalCompensationsPercentCredit: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value
                .generalCompensationsPercentCredit,
            ),
            generalCompensationsPercentCreditChecker:
              compensation.value?.generalCompensationsPercentCreditChecker?.value,

            compensationsDismantlingChecker: preparedCompensationsDismantlingChecker,
            generalCompensationsDismantling: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value
                .generalCompensationsDismantling,
            ),
            generalCompensationsDismantlingChecker:
              compensation.value?.generalCompensationsDismantlingChecker?.value,

            compensationsExpensesChecker: preparedCompensationsExpensesChecker,
            generalCompensationsExpenses: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value
                .generalCompensationsExpenses,
            ),
            generalCompensationsExpensesChecker:
              compensation.value?.generalCompensationsExpensesChecker?.value,

            compensationSubsidyFormat: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value?.compensationSubsidyFormat,
            ),
            compensationTaxDeductionFormat: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value
                ?.compensationTaxDeductionFormat,
            ),
            compensationDamageFormat: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value?.compensationDamageFormat,
            ),
            generalCompensation: formatBackendMoneyToString(
              compensation.value?.compensationMonitoringInvestor?.value?.generalCompensation,
            ),
            compensationSubsidyFormatChecker:
              compensation.value?.compensationSubsidyFormatChecker?.value,
            compensationTaxDeductionFormatChecker:
              compensation.value?.compensationTaxDeductionFormatChecker?.value,
            compensationDamageFormatChecker:
              compensation.value?.compensationDamageFormatChecker?.value,
            generalCompensationChecker: compensation.value?.generalCompensationChecker?.value,
          }
        },
      )
    })()

  const preparedCompensationsMonitoring: CertificateOnMonitoringResultsCompensationMonitoring[] =
    (() => {
      if (
        !isArray(objectFromPropertyState.compensationsMonitoringChecker?.value) ||
        !objectFromPropertyState.compensationsMonitoringChecker?.value?.length
      )
        return []

      return objectFromPropertyState.compensationsMonitoringChecker?.value.map(
        (compensation, index): CertificateOnMonitoringResultsCompensationMonitoring => ({
          id: compensation.propertyId,
          anchorId: `10.${index + 1}`,
          compensationYearChecker: getYearOfDateFromPropertyDateValue(
            compensation.value.compensationYearChecker?.value,
          ),
          incomeTaxFederal: formatBackendMoneyToString(
            compensation.value?.compensationMonitoringInvestor?.value?.incomeTaxFederal,
          ),
          incomeTaxRegional: formatBackendMoneyToString(
            compensation.value?.compensationMonitoringInvestor?.value?.incomeTaxRegional,
          ),
          propertyTax: formatBackendMoneyToString(
            compensation.value?.compensationMonitoringInvestor?.value?.propertyTax,
          ),
          landTax: formatBackendMoneyToString(
            compensation.value?.compensationMonitoringInvestor?.value?.landTax,
          ),
          vatTax: formatBackendMoneyToString(
            compensation.value?.compensationMonitoringInvestor?.value?.vatTax,
          ),
          customDuty: formatBackendMoneyToString(
            compensation.value?.compensationMonitoringInvestor?.value?.customDuty,
          ),
          generalTaxValue: formatBackendMoneyToString(
            compensation.value?.compensationMonitoringInvestor?.value?.generalTaxValue,
          ),
          incomeTaxFederalChecker: compensation.value?.incomeTaxFederalChecker?.value,
          incomeTaxRegionalChecker: compensation.value?.incomeTaxRegionalChecker?.value,
          propertyTaxChecker: compensation.value?.propertyTaxChecker?.value,
          landTaxChecker: compensation.value?.landTaxChecker?.value,
          vatTaxChecker: compensation.value?.vatTaxChecker?.value,
          customDutyChecker: compensation.value?.customDutyChecker?.value,
          generalTaxValueChecker: compensation.value?.generalTaxValueChecker?.value,
        }),
      )
    })()

  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
      initiatorFullNameInstrumental:
        objectFromPropertyState.initiator?.value?.memberFullNameInstrumental?.value,
      initiatorAddressFullEgrulFormat:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value
          ?.addressFullEgrulFormat,
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: objectFromPropertyState.investorFullNameGenitive?.value || '',
    },
    '4': {
      projectName: objectFromPropertyState?.projectName?.value,
      projectDecisionDepartmentGenitive:
        objectFromPropertyState?.projectDecisionDepartamentGenitive?.value,
      economicSphere: objectFromPropertyState?.economicSphere?.value?.name,
      szpkRegistrationDate: objectFromPropertyState?.szpkRegistrationDate?.value,
      startPreinvestStage: objectFromPropertyState?.startPreinvestStage?.value,
      endPreinvestStage: objectFromPropertyState?.endPreinvestStage?.value,
      startInvestStage: objectFromPropertyState?.startInvestStage?.value,
      endInvestStage: objectFromPropertyState?.endInvestStage?.value,
      startExploitationStage: objectFromPropertyState?.startExploitationStage?.value,
      endExploitationStage: objectFromPropertyState?.endExploitationStage?.value,
      isVatMoney: objectFromPropertyState?.isVatMoney?.value,
      totalCapital: objectFromPropertyState?.totalCapital?.value,
      totalCapitalVAT: objectFromPropertyState?.totalCapitalVAT?.value,
      investorCapital: objectFromPropertyState?.investorCapital?.value,
      investorCapitalVAT: objectFromPropertyState?.investorCapitalVAT?.value,
      newWorkPlaces: objectFromPropertyState?.newWorkPlaces?.value,
      projectResultEffect: objectFromPropertyState?.projectResultEffect?.value,
      projectResultEffectChecker: objectFromPropertyState?.projectResultEffectChecker?.value,
    },
    '5': {
      stepsMonitoringChecker: preparedStepsMonitoringChecker,
    },
    '6': {
      percentCreditsChecker: preparedPercentCreditsChecker,
    },
    '7': {
      dismantlingObjectsChecker: preparedDismantlingObjectsChecker,
    },
    '8': {
      expensesChecker: preparedExpensesChecker,
    },
    '9': {
      compensationsMonitoringChecker: preparedCompensationsMonitoringChecker,
    },
    '10': {
      compensationsMonitoring: preparedCompensationsMonitoring,
    },
    '11': {
      stateSupportsChecker: preparedStateSupportsChecker,
      generalStateSupportValue: objectFromPropertyState?.generalStateSupportValue?.value,
      generalStateSupportValueChecker:
        objectFromPropertyState?.generalStateSupportValueChecker?.value,
    },
    '12': {
      isChanges: objectFromPropertyState?.isChanges?.value,
      isTerminate: objectFromPropertyState?.isTerminate?.value,
      relatedContractReason: objectFromPropertyState?.relatedContractReason?.value,
      relatedContractReasonChecker: objectFromPropertyState?.relatedContractReasonChecker?.value,
      relatedContractReasonDescription:
        objectFromPropertyState?.relatedContractReasonDescription?.value,
      relatedContractReasonDescriptionChecker:
        objectFromPropertyState?.relatedContractReasonDescriptionChecker?.value,
      extendStabilizationReason: objectFromPropertyState?.extendStabilizationReason?.value,
      extendStabilizationReasonChecker:
        objectFromPropertyState?.extendStabilizationReasonChecker?.value,
      extendStabilizationReasonDescription:
        objectFromPropertyState?.extendStabilizationReasonDescription?.value,
      extendStabilizationReasonDescriptionChecker:
        objectFromPropertyState?.extendStabilizationReasonDescriptionChecker?.value,
      municipalAccessionReason: objectFromPropertyState?.municipalAccessionReason?.value,
      municipalAccessionReasonChecker:
        objectFromPropertyState?.municipalAccessionReasonChecker?.value,
      municipalAccessionReasonDescription:
        objectFromPropertyState?.municipalAccessionReasonDescription?.value,
      municipalAccessionReasonDescriptionChecker:
        objectFromPropertyState?.municipalAccessionReasonDescriptionChecker?.value,
      rightTransferReason: objectFromPropertyState?.rightTransferReason?.value,
      rightTransferReasonChecker: objectFromPropertyState?.rightTransferReasonChecker?.value,
      rightTransferReasonDescription:
        objectFromPropertyState?.rightTransferReasonDescription?.value,
      rightTransferReasonDescriptionChecker:
        objectFromPropertyState?.rightTransferReasonDescriptionChecker?.value,
      infoChangesReason: objectFromPropertyState?.infoChangesReason?.value,
      infoChangesReasonChecker: objectFromPropertyState?.infoChangesReasonChecker?.value,
      infoChangesReasonDescription: objectFromPropertyState?.infoChangesReasonDescription?.value,
      infoChangesReasonDescriptionChecker:
        objectFromPropertyState?.infoChangesReasonDescriptionChecker?.value,
      forceMajeureReason: objectFromPropertyState?.forceMajeureReason?.value,
      forceMajeureReasonChecker: objectFromPropertyState?.forceMajeureReasonChecker?.value,
      forceMajeureReasonDescription: objectFromPropertyState?.forceMajeureReasonDescription?.value,
      forceMajeureReasonDescriptionChecker:
        objectFromPropertyState?.forceMajeureReasonDescriptionChecker?.value,
      agreementDutyReason: objectFromPropertyState?.agreementDutyReason?.value,
      agreementDutyReasonChecker: objectFromPropertyState?.agreementDutyReasonChecker?.value,
      agreementDutyReasonDescription:
        objectFromPropertyState?.agreementDutyReasonDescription?.value,
      agreementDutyReasonDescriptionChecker:
        objectFromPropertyState?.agreementDutyReasonDescriptionChecker?.value,
      concessionFailReason: objectFromPropertyState?.concessionFailReason?.value,
      concessionFailReasonChecker: objectFromPropertyState?.concessionFailReasonChecker?.value,
      concessionFailReasonDescription:
        objectFromPropertyState?.concessionFailReasonDescription?.value,
      concessionFailReasonDescriptionChecker:
        objectFromPropertyState?.concessionFailReasonDescriptionChecker?.value,
      constructionChangeReason: objectFromPropertyState?.constructionChangeReason?.value,
      constructionChangeReasonChecker:
        objectFromPropertyState?.constructionChangeReasonChecker?.value,
      constructionChangeReasonDescription:
        objectFromPropertyState?.constructionChangeReasonDescription?.value,
      constructionChangeReasonDescriptionChecker:
        objectFromPropertyState?.constructionChangeReasonDescriptionChecker?.value,
      supportingInfrastructureReason:
        objectFromPropertyState?.supportingInfrastructureReason?.value,
      supportingInfrastructureReasonChecker:
        objectFromPropertyState?.supportingInfrastructureReasonChecker?.value,
      supportingInfrastructureReasonDescription:
        objectFromPropertyState?.supportingInfrastructureReasonDescription?.value,
      supportingInfrastructureReasonDescriptionChecker:
        objectFromPropertyState?.supportingInfrastructureReasonDescriptionChecker?.value,
      investmentChangeReason: objectFromPropertyState?.investmentChangeReason?.value,
      investmentChangeReasonChecker: objectFromPropertyState?.investmentChangeReasonChecker?.value,
      investmentChangeReasonDescription:
        objectFromPropertyState?.investmentChangeReasonDescription?.value,
      investmentChangeReasonDescriptionChecker:
        objectFromPropertyState?.investmentChangeReasonDescriptionChecker?.value,
      compensationChangeReason: objectFromPropertyState?.compensationChangeReason?.value,
      compensationChangeReasonChecker:
        objectFromPropertyState?.compensationChangeReasonChecker?.value,
      compensationChangeReasonDescription:
        objectFromPropertyState?.compensationChangeReasonDescription?.value,
      compensationChangeReasonDescriptionChecker:
        objectFromPropertyState?.compensationChangeReasonDescriptionChecker?.value,
      changeCompensationFormatReason:
        objectFromPropertyState?.changeCompensationFormatReason?.value,
      changeCompensationFormatReasonChecker:
        objectFromPropertyState?.changeCompensationFormatReasonChecker?.value,
      changeCompensationFormatReasonDescription:
        objectFromPropertyState?.changeCompensationFormatReasonDescription?.value,
      changeCompensationFormatReasonDescriptionChecker:
        objectFromPropertyState?.changeCompensationFormatReasonDescriptionChecker?.value,
      lawChangeReason: objectFromPropertyState?.lawChangeReason?.value,
      lawChangeReasonChecker: objectFromPropertyState?.lawChangeReasonChecker?.value,
      lawChangeReasonDescription: objectFromPropertyState?.lawChangeReasonDescription?.value,
      lawChangeReasonDescriptionChecker:
        objectFromPropertyState?.lawChangeReasonDescriptionChecker?.value,
      noMadeInvestorCapitalReason: objectFromPropertyState?.noMadeInvestorCapitalReason?.value,
      noMadeInvestorCapitalReasonChecker:
        objectFromPropertyState?.noMadeInvestorCapitalReasonChecker?.value,
      noMadeInvestorCapitalReasonDescription:
        objectFromPropertyState?.noMadeInvestorCapitalReasonDescription?.value,
      noMadeInvestorCapitalReasonDescriptionChecker:
        objectFromPropertyState?.noMadeInvestorCapitalReasonDescriptionChecker?.value,
      legalFactsReason: objectFromPropertyState?.legalFactsReason?.value,
      legalFactsReasonChecker: objectFromPropertyState?.legalFactsReasonChecker?.value,
      legalFactsReasonDescription: objectFromPropertyState?.legalFactsReasonDescription?.value,
      legalFactsReasonDescriptionChecker:
        objectFromPropertyState?.legalFactsReasonDescriptionChecker?.value,
      lawViolationReason: objectFromPropertyState?.lawViolationReason?.value,
      lawViolationReasonChecker: objectFromPropertyState?.lawViolationReasonChecker?.value,
      lawViolationReasonDescription: objectFromPropertyState?.lawViolationReasonDescription?.value,
      lawViolationReasonDescriptionChecker:
        objectFromPropertyState?.lawViolationReasonDescriptionChecker?.value,
      suspensionOrganizationActivityReason:
        objectFromPropertyState?.suspensionOrganizationActivityReason?.value,
      suspensionOrganizationActivityReasonChecker:
        objectFromPropertyState?.suspensionOrganizationActivityReasonChecker?.value,
      suspensionOrganizationActivityReasonDescription:
        objectFromPropertyState?.suspensionOrganizationActivityReasonDescription?.value,
      suspensionOrganizationActivityReasonDescriptionChecker:
        objectFromPropertyState?.suspensionOrganizationActivityReasonDescriptionChecker?.value,
      liquidationOrganisationReason: objectFromPropertyState?.liquidationOrganisationReason?.value,
      liquidationOrganisationReasonChecker:
        objectFromPropertyState?.liquidationOrganisationReasonChecker?.value,
      liquidationOrganisationReasonDescription:
        objectFromPropertyState?.liquidationOrganisationReasonDescription?.value,
      liquidationOrganisationReasonDescriptionChecker:
        objectFromPropertyState?.liquidationOrganisationReasonDescriptionChecker?.value,
      otherReason: objectFromPropertyState?.otherReason?.value,
      otherReasonChecker: objectFromPropertyState?.otherReasonChecker?.value,
      otherReasonName: objectFromPropertyState?.otherReasonName?.value,
      otherReasonDescription: objectFromPropertyState?.otherReasonDescription?.value,
      otherReasonNameChecker: objectFromPropertyState?.otherReasonNameChecker?.value,
      otherReasonDescriptionChecker: objectFromPropertyState?.otherReasonDescriptionChecker?.value,
      changeSuggestions: objectFromPropertyState?.changeSuggestions?.value,
      changeRecommendations: objectFromPropertyState?.changeRecommendations?.value,
    },
  }
}

export { generateRHFObjectForCertificateOnMonitoringResultsForm }
