import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  RegistrationCertificateExtraRegisteredDocumentValue,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const extraRegisteredDocumentValueItemRegistrationCertificateValidationMap: FormValuesValidationSection<
  keyof RegistrationCertificateExtraRegisteredDocumentValue,
  RegistrationCertificateFormValues
> = {
  extraDescription: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { extraRegisteredDocumentValueItemRegistrationCertificateValidationMap }
