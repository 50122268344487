import { generatePropertiesObjectForStatementConsiderationInvestmentAuthorizedForm } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForStatementConsiderationInvestmentAuthorizedForm } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/adapters/RHF.adapter'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'

const useStatementConsiderationInvestmentAuthorizedAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): StatementConsiderationInvestmentAuthorizedFormValues =>
    generateRHFObjectForStatementConsiderationInvestmentAuthorizedForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForStatementConsiderationInvestmentAuthorizedForm(
      objectFromPropertyState,
    )

  return { generateRHFObject, generatePropertiesObject }
}

export { useStatementConsiderationInvestmentAuthorizedAdapters }
