import React, { FC, ReactNode } from 'react'

import FormTooltip, { FormTooltipProps } from '@components/DocumentFormComponents/FormTooltip'
import Icon, { type IconProps } from '@components/Icon'
import editIcon from '@icons/EditIcon.svg'
import cn from 'classnames'

import styles from './FormActionIconWithTooltip.module.scss'

interface FormActionIconWithTooltipProps extends Partial<IconProps> {
  tooltipContent: ReactNode
  tooltipProps?: Omit<FormTooltipProps, 'content' | 'children'>
}

const FormActionIconWithTooltip: FC<FormActionIconWithTooltipProps> = ({
  src = editIcon,
  size = 'm',
  className,
  tooltipContent,
  tooltipProps,
  ...restProps
}) => {
  const {
    position = 'bottom',
    fallbackPlacements = ['bottom', 'top', 'right', 'left'],
    offset = [60, 10],
    width = 'l',
    ...restTooltipProps
  } = tooltipProps || {}

  return (
    <FormTooltip
      {...restTooltipProps}
      position={position}
      fallbackPlacements={fallbackPlacements}
      offset={offset}
      width={width}
      content={tooltipContent}
    >
      <Icon
        {...restProps}
        src={src}
        className={cn(styles.formActionIconWithTooltip__icon, className)}
        size={size}
      />
    </FormTooltip>
  )
}

export default FormActionIconWithTooltip
