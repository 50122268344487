import React, { FC, memo, useMemo } from 'react'
import { useParams } from 'react-router'

import UiActionButton from '@components/Projects/[id]/ActionBanner/Button'
import { ICurrentDfoActionDocumentsButton } from '@components/Projects/[id]/ActionBanner/Manager'
import { ChangesMadeContextProvider } from '@components/Projects/[id]/DocumentLayout/ChangesMade'
import { useFormsFromMapOfSetsToRender } from '@components/Projects/[id]/DocumentLayout/View/hooks'
import RelatedDfoLayout from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout'
import MunicipalAccession from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout/MunicipalAccession'
import ReorganizationSplitOff from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout/ReorganizationSplitOff'
import ReorganizationTypeAddition from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout/ReorganizationType'
import SolicitationMembers from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout/SolicitationMembers'
import SubsidyMembers from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout/SubsidyMembers'
import TransferMembers from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout/TransferMembers'
import StandardDfoLayout from '@components/Projects/[id]/DocumentLayout/View/Layouts/StandardDfoLayout'
import { useDfoDocumentSets } from '@components/Projects/[id]/DocumentLayout/View/store'
import { DfosStage, NewDfosType } from '@constants/types'
import { isNumber } from '@helpers/checkTypes'
import useRevalidateUntilFormsLoaded from '@hooks/new/documents/useRevalidateUntilFormsLoaded'
import { useDebounceLoading } from '@hooks/new/loading/useDebounceLoading'
import { useProject } from '@hooks/new/swr/useProject'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useProjectActions } from '@hooks/new/swr/useUiActions'
import { useVersions } from '@hooks/new/swr/useVersions'
import { useInitialRender } from '@hooks/useInitialRender'
import DeleteIcon from '@icons/DeleteIcon.svg'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import DfoService from '@services/Dfo/Dfo.service'
import { useDocumentActions } from '@services/DocumentActions/DocumentAction.hook'
import { mapOfDocumentActions } from '@services/DocumentActions/DocumentActions.entity'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { GroupType } from '@services/Projects/Project.entity'

import styles from './DocumentsView.module.scss'

interface DocumentsViewProps {
  currentDfoInfo: IDfoListItem | null
}

const { fromActionToActionsStack } = DocumentActionsService

const DocumentsView: FC<DocumentsViewProps> = ({ currentDfoInfo }) => {
  const { projectId } = useParams()
  const dfoId = currentDfoInfo?.id || ''

  const { createActionsStack } = useDocumentActions()

  const initialSetsLoading = useInitialRender()

  const { projectActions } = useProjectActions({
    key: {
      projectId,
      _key: 'projectActions',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DocumentsView',
          moduleName: 'DocumentsLayout',
        },
      }),
    },
  })

  const { preparedSets, mapOfSetsToRender, documentsError, setsIsLoading, updateDocumentSets } =
    useDfoDocumentSets({
      dfo: currentDfoInfo,
      dfoConfig: {
        revalidateOnMount: true,
        onError: LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'DocumentsView',
            moduleName: 'DocumentsLayout',
          },
        }),
      },
    })

  const { project } = useProject({
    key: { projectId, _key: 'project' },
    config: {
      isPaused: () => !projectId,
    },
  })
  const { projectDfos, isLoadingProjectDfos } = useProjectDfos({
    key: { projectId, _key: 'projectDfos' },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DocumentsView',
          moduleName: 'DocumentsLayout',
        },
      }),
    },
  })

  const { isLoadingVersions } = useVersions({
    key: { dfoId, projectId, _key: 'versions' },
    config: {
      isPaused: () => !dfoId || !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DocumentsView',
          moduleName: 'DocumentsLayout',
        },
      }),
    },
  })

  const isLoadingAllDfoData =
    isLoadingProjectDfos || (initialSetsLoading && setsIsLoading) || isLoadingVersions

  const { isLoading: isLoadingDfoLayout } = useDebounceLoading(
    isLoadingAllDfoData,
    [isLoadingProjectDfos, setsIsLoading, isLoadingVersions],
    300,
  )

  const documentsBottomPanelActions = useMemo(() => {
    if (!projectActions) return []
    const currentDfoActions = DfoService.getCurrentDfoActions(dfoId, projectActions)

    if (!currentDfoActions.length) return []

    return currentDfoActions.filter(
      (item) => item.position === 'documentsBottom',
    ) as ICurrentDfoActionDocumentsButton[]
  }, [dfoId, projectActions])

  const renderActions = useMemo(
    () => (
      <div className={styles['documentsView__related-actions']}>
        {documentsBottomPanelActions.map((action, index) => {
          const isDeleteAction = action.uiActions.find(
            (type) => type === mapOfDocumentActions.DELETE_DFO,
          )

          //Если появятся новые action  у документов, сделать здесь маппинг.
          if (!isDeleteAction) return null

          const actionsForStack = fromActionToActionsStack(action)

          const handleUiActionClick = async () => {
            try {
              await createActionsStack(actionsForStack).runStack()
            } catch {}
          }

          return (
            <UiActionButton
              key={index}
              uiAction={action}
              buttonProps={{
                geometry: 'square',
                size: 'l',
                view: 'tined',
                dataTestId: `DocumentsView-action-${action.actionId}-button`,
                leadingIcon: {
                  src: DeleteIcon,
                },
                onClick: handleUiActionClick,
              }}
            />
          )
        })}
      </div>
    ),
    [createActionsStack, documentsBottomPanelActions],
  )

  const forms = useFormsFromMapOfSetsToRender(mapOfSetsToRender)

  useRevalidateUntilFormsLoaded({
    isRevalidatePaused: setsIsLoading,
    revalidateCallback: updateDocumentSets,
    forms,
  })

  return (
    <ChangesMadeContextProvider key={dfoId} currentDfoInfo={currentDfoInfo}>
      <div className={styles.documentsView}>
        {(() => {
          if (
            currentDfoInfo?.type === NewDfosType.INVESTPROJECT ||
            currentDfoInfo?.type === NewDfosType.SZPK ||
            currentDfoInfo?.type === NewDfosType.NPA_LIST
          ) {
            return (
              <StandardDfoLayout
                dfo={currentDfoInfo}
                preparedSetsOfDocuments={preparedSets}
                mapOfSetsToRender={mapOfSetsToRender}
                documentsError={documentsError}
                isLoading={isLoadingDfoLayout}
              />
            )
          }

          if (currentDfoInfo?.type === NewDfosType.OTHER_DONATIONS) {
            return (
              <StandardDfoLayout
                dfo={currentDfoInfo}
                preparedSetsOfDocuments={preparedSets}
                mapOfSetsToRender={mapOfSetsToRender}
                documentsError={documentsError}
                isLoading={isLoadingDfoLayout}
              >
                {renderActions}
              </StandardDfoLayout>
            )
          }

          if (currentDfoInfo?.type === NewDfosType.RELATED_CONTRACT_LIST) {
            const solicitationRenderCondition =
              isNumber(currentDfoInfo?.systemVersion) && currentDfoInfo?.systemVersion < 3

            return (
              <RelatedDfoLayout
                disableLayoutHeader
                dfo={currentDfoInfo}
                preparedSetsOfDocuments={preparedSets}
                mapOfSetsToRender={mapOfSetsToRender}
                isLoading={isLoadingDfoLayout}
                topAddition={
                  solicitationRenderCondition ? (
                    <SolicitationMembers dfo={currentDfoInfo} />
                  ) : undefined
                }
                standardDfoLayoutProps={{
                  disableDownloadDocuments: true,
                }}
              >
                {renderActions}
              </RelatedDfoLayout>
            )
          }

          if (
            currentDfoInfo?.type === NewDfosType.MONITORING_REPORT_REGIONAL ||
            currentDfoInfo?.type === NewDfosType.MONITORING_REPORT_FEDERAL ||
            currentDfoInfo?.type === NewDfosType.MONITORING_REPORT_SZPK
          ) {
            return (
              <RelatedDfoLayout
                disableLayoutHeader
                dfo={currentDfoInfo}
                mapOfSetsToRender={mapOfSetsToRender}
                preparedSetsOfDocuments={preparedSets}
                isLoading={isLoadingDfoLayout}
                standardDfoLayoutProps={{
                  actionsClassName: styles.documentsView__monitioring,
                  disableDownloadDocuments: true,
                }}
              >
                {renderActions}
              </RelatedDfoLayout>
            )
          }

          if (
            currentDfoInfo?.type.startsWith(GroupType.CLAIM_SUBSIDY_PERMIT) ||
            currentDfoInfo?.type.startsWith(GroupType.CLAIM_TAX_REFUND)
          ) {
            const isSubsidyMemberRenderCondition =
              currentDfoInfo?.claimSubsidyBudgetType === 'REGIONAL' ||
              currentDfoInfo?.claimSubsidyBudgetType === 'LOCAL'

            return (
              <RelatedDfoLayout
                disableLayoutHeader
                dfo={currentDfoInfo}
                mapOfSetsToRender={mapOfSetsToRender}
                preparedSetsOfDocuments={preparedSets}
                isLoading={isLoadingDfoLayout}
                standardDfoLayoutProps={{
                  disableDownloadDocuments: true,
                }}
                topAddition={
                  isSubsidyMemberRenderCondition &&
                  project && (
                    <SubsidyMembers
                      projectId={project.id}
                      dfoId={dfoId}
                      subsidyBudgetType={currentDfoInfo?.claimSubsidyBudgetType}
                    />
                  )
                }
              >
                {renderActions}
              </RelatedDfoLayout>
            )
          }

          if (currentDfoInfo?.type === NewDfosType.SZPK_RIGHTS_TRANSFER) {
            return (
              <RelatedDfoLayout
                disableLayoutHeader
                dfo={currentDfoInfo}
                mapOfSetsToRender={mapOfSetsToRender}
                preparedSetsOfDocuments={preparedSets}
                isLoading={isLoadingDfoLayout}
                standardDfoLayoutProps={{
                  disableDownloadDocuments: true,
                }}
                topAddition={
                  projectDfos &&
                  project && (
                    <TransferMembers
                      projectId={project?.id}
                      transferDfo={currentDfoInfo}
                      additionalWriteCondition={currentDfoInfo?.stage === DfosStage.DRAFT}
                    />
                  )
                }
              >
                {renderActions}
              </RelatedDfoLayout>
            )
          }

          if (currentDfoInfo?.type === NewDfosType.ORG_REORGANIZATION) {
            if (!project) return null

            return (
              <RelatedDfoLayout
                disableLayoutHeader
                dfo={currentDfoInfo}
                mapOfSetsToRender={mapOfSetsToRender}
                preparedSetsOfDocuments={preparedSets}
                isLoading={isLoadingDfoLayout}
                topAddition={<ReorganizationTypeAddition projectId={project?.id} dfoId={dfoId} />}
                standardDfoLayoutProps={{
                  disableDownloadDocuments: true,
                }}
              >
                {renderActions}
              </RelatedDfoLayout>
            )
          }

          if (currentDfoInfo?.type === NewDfosType.ORG_REORGANIZATION_SPLIT_OFF) {
            if (!project) return null

            return (
              <RelatedDfoLayout
                disableLayoutHeader
                dfo={currentDfoInfo}
                mapOfSetsToRender={mapOfSetsToRender}
                preparedSetsOfDocuments={preparedSets}
                isLoading={isLoadingDfoLayout}
                topAddition={
                  project &&
                  currentDfoInfo && (
                    <ReorganizationSplitOff projectId={project?.id} dfo={currentDfoInfo} />
                  )
                }
                standardDfoLayoutProps={{
                  disableDownloadDocuments: true,
                }}
              >
                {renderActions}
              </RelatedDfoLayout>
            )
          }

          if (
            currentDfoInfo?.type === NewDfosType.MUNICIPAL_ACCESSION ||
            currentDfoInfo?.type === NewDfosType.MUNICIPAL_ACCESSION_UPDATE
          ) {
            if (!project) return null

            return (
              <RelatedDfoLayout
                disableLayoutHeader
                dfo={currentDfoInfo}
                isLoading={isLoadingDfoLayout}
                mapOfSetsToRender={mapOfSetsToRender}
                topAddition={
                  project &&
                  currentDfoInfo && (
                    <MunicipalAccession projectId={project.id} dfo={currentDfoInfo} />
                  )
                }
                standardDfoLayoutProps={{
                  disableDownloadDocuments: true,
                }}
              >
                {renderActions}
              </RelatedDfoLayout>
            )
          }

          return (
            <RelatedDfoLayout
              disableLayoutHeader
              dfo={currentDfoInfo}
              mapOfSetsToRender={mapOfSetsToRender}
              preparedSetsOfDocuments={preparedSets}
              isLoading={isLoadingDfoLayout}
              standardDfoLayoutProps={{
                disableDownloadDocuments: true,
              }}
            >
              {renderActions}
            </RelatedDfoLayout>
          )
        })()}
      </div>
    </ChangesMadeContextProvider>
  )
}

export default memo(DocumentsView)
