import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValuesForm =
  (objectFromPropertyState: Record<string, any>): Record<string, unknown> => {
    return {
      '1': {
        ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
      },
      '2': {
        ...PropertiesPropsBasicAdapters.getUOAdapter(objectFromPropertyState),
      },
      '3': {
        ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
        investorFullNameInstrumental: {
          propertyId: objectFromPropertyState?.investorFullNameInstrumental?.propertyId,
          type: objectFromPropertyState?.investorFullNameInstrumental?.type,
          lastUpdateDt: objectFromPropertyState?.investorFullNameInstrumental?.lastUpdateDt,
        },
      },
      '4': {
        ruleLinkSolicitationRelatedContractChecking: {
          propertyId:
            objectFromPropertyState.ruleLinkSolicitationRelatedContractChecking?.propertyId,
          type: objectFromPropertyState.ruleLinkSolicitationRelatedContractChecking?.type,
          lastUpdateDt:
            objectFromPropertyState.ruleLinkSolicitationRelatedContractChecking?.lastUpdateDt,
        },
        ruleLinkSolicitationRelatedContractReturn: {
          propertyId: objectFromPropertyState.ruleLinkSolicitationRelatedContractReturn?.propertyId,
          type: objectFromPropertyState.ruleLinkSolicitationRelatedContractReturn?.type,
          lastUpdateDt:
            objectFromPropertyState.ruleLinkSolicitationRelatedContractReturn?.lastUpdateDt,
        },
        solicitationRelatedContractTechError: {
          propertyId: objectFromPropertyState.solicitationRelatedContractTechError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractTechError?.type,
          lastUpdateDt: objectFromPropertyState.solicitationRelatedContractTechError?.lastUpdateDt,
        },
        solicitationRelatedContractDocumentError: {
          propertyId: objectFromPropertyState.solicitationRelatedContractDocumentError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractDocumentError?.type,
          lastUpdateDt:
            objectFromPropertyState.solicitationRelatedContractDocumentError?.lastUpdateDt,
        },
        solicitationRelatedContractRequirementError: {
          propertyId:
            objectFromPropertyState.solicitationRelatedContractRequirementError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractRequirementError?.type,
          lastUpdateDt:
            objectFromPropertyState.solicitationRelatedContractRequirementError?.lastUpdateDt,
        },
        solicitationRelatedContractRequirementLinkError: {
          propertyId:
            objectFromPropertyState.solicitationRelatedContractRequirementLinkError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractRequirementLinkError?.type,
          lastUpdateDt:
            objectFromPropertyState.solicitationRelatedContractRequirementLinkError?.lastUpdateDt,
        },
        solicitationRelatedContractContractKindError: {
          propertyId:
            objectFromPropertyState.solicitationRelatedContractContractKindError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractContractKindError?.type,
          lastUpdateDt:
            objectFromPropertyState.solicitationRelatedContractContractKindError?.lastUpdateDt,
        },
        solicitationRelatedContractRuleError: {
          propertyId: objectFromPropertyState.solicitationRelatedContractRuleError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractRuleError?.type,
          lastUpdateDt: objectFromPropertyState.solicitationRelatedContractRuleError?.lastUpdateDt,
        },
        solicitationRelatedContractRuleLinkError: {
          propertyId: objectFromPropertyState.solicitationRelatedContractRuleLinkError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractRuleLinkError?.type,
          lastUpdateDt:
            objectFromPropertyState.solicitationRelatedContractRuleLinkError?.lastUpdateDt,
        },
        solicitationRelatedContractRelatedContractError: {
          propertyId:
            objectFromPropertyState.solicitationRelatedContractRelatedContractError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractRelatedContractError?.type,
          lastUpdateDt:
            objectFromPropertyState.solicitationRelatedContractRelatedContractError?.lastUpdateDt,
        },
        solicitationRelatedContractCreditContractError: {
          propertyId:
            objectFromPropertyState.solicitationRelatedContractCreditContractError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractCreditContractError?.type,
          lastUpdateDt:
            objectFromPropertyState.solicitationRelatedContractCreditContractError?.lastUpdateDt,
        },
        solicitationRelatedContractOtherError: {
          propertyId: objectFromPropertyState.solicitationRelatedContractOtherError?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractOtherError?.type,
          lastUpdateDt: objectFromPropertyState.solicitationRelatedContractOtherError?.lastUpdateDt,
        },
        solicitationRelatedContractOtherDetails: {
          propertyId: objectFromPropertyState.solicitationRelatedContractOtherDetails?.propertyId,
          type: objectFromPropertyState.solicitationRelatedContractOtherDetails?.type,
          lastUpdateDt:
            objectFromPropertyState.solicitationRelatedContractOtherDetails?.lastUpdateDt,
        },
        solicitationDaysRevision: {
          propertyId: objectFromPropertyState.solicitationDaysRevision?.propertyId,
          type: objectFromPropertyState.solicitationDaysRevision?.type,
          lastUpdateDt: objectFromPropertyState.solicitationDaysRevision?.lastUpdateDt,
        },
      },
    }
  }

export { generatePropertiesObjectForNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValuesForm }
