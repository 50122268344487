import { IconProps } from '@components/Icon'

import { TagProps } from './Tag'

export const getIconSizeByTagSize = (size: TagProps['size']): IconProps['size'] => {
  switch (size) {
    case 'l':
      return 's'

    case 'm':
      return 'xs'

    default:
      return 's'
  }
}
