import React, { FC } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { FormModalLayoutWrapperProps } from '@components/DocumentFormComponents/types'
import { usePetitionRecognizePreviouslyConcludedContractAsBoundManager } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/Manager'

const PetitionRecognizePreviouslyConcludedContractAsBoundLayoutWrapper: FC<
  FormModalLayoutWrapperProps
> = ({ children, ...rest }) => {
  const {
    state: { lastUpdateDraftTime },
    actions: { handleFormClose },
  } = usePetitionRecognizePreviouslyConcludedContractAsBoundManager()

  const handleClose = () => {
    handleFormClose?.()
  }

  return (
    <>
      <DocumentLayoutContent {...rest} draftIsSaved={!!lastUpdateDraftTime} onClose={handleClose}>
        {children}
      </DocumentLayoutContent>
    </>
  )
}

export default PetitionRecognizePreviouslyConcludedContractAsBoundLayoutWrapper
