import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NoticeOfRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof NoticeOfRefuseConcludeAAFormValues['2'],
  NoticeOfRefuseConcludeAAFormValues
> = {
  initiatorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { secondSectionValidationMap }
