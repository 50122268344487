import { useCallback } from 'react'

import { useAPIContext } from '@context/APIContext'
import { useBooleanState } from '@hooks/useBooleanState'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { IProjectFromAllProjects, ProjectTypes } from '@services/Projects/Project.entity'

export const useNotificationsAddingActions = (
  activeProjectIfExist: IProjectFromAllProjects | undefined,
  revalidateAfterActions: () => Promise<void>,
) => {
  const {
    projectsApi: { createProject, deleteProjectById },
  } = useAPIContext()

  const {
    booleanState: isProjectAddingInProgress,
    setBooleanStateToTrue: startProjectAdding,
    setBooleanStateToFalse: endProjectAdding,
  } = useBooleanState()

  const {
    booleanState: isProjectDeletingInProgress,
    setBooleanStateToTrue: startProjectDeleting,
    setBooleanStateToFalse: endProjectDeleting,
  } = useBooleanState()

  const handleCreateProject = useCallback(async () => {
    try {
      startProjectAdding()

      await createProject({
        type: ProjectTypes.ORGANIZATION_INFO_CHANGES,
        name: 'Уведомление о смене реквизитов',
      })

      await revalidateAfterActions()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useProjectAdding',
          componentType: 'createProjectHandler',
          moduleName: 'NotificationsOfRequisitesChanges',
        },
      })(error)
    } finally {
      endProjectAdding()
    }
  }, [createProject, endProjectAdding, revalidateAfterActions, startProjectAdding])

  const handleDeleteActiveProject = useCallback(async () => {
    if (!activeProjectIfExist) return
    startProjectDeleting()

    try {
      await deleteProjectById(activeProjectIfExist.id)
      await revalidateAfterActions()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useProjectAdding',
          componentType: 'deleteActiveProjectHandler',
          moduleName: 'NotificationsOfRequisitesChanges',
        },
      })(error)
    } finally {
      endProjectDeleting()
    }
  }, [
    activeProjectIfExist,
    deleteProjectById,
    endProjectDeleting,
    revalidateAfterActions,
    startProjectDeleting,
  ])

  return {
    isProjectAddingInProgress,
    isProjectDeletingInProgress,
    isNotificationCanBeAdded: !activeProjectIfExist,
    onCreateProject: handleCreateProject,
    onDeleteActiveProject: handleDeleteActiveProject,
  }
}
