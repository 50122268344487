import React, { DetailedHTMLProps, FC, memo, MouseEvent, useState } from 'react'

import Icon from '@components/Icon'
import styles from '@components/TechnicalWork/CabinetBanner/CabinetBanner.module.scss'
import closeIcon from '@icons/navigation/close.svg'
import technicalIcon from '@icons/TechnicalWork.svg'
import { IInitialTechnicalWork } from '@services/TechnicalWork/TechnicalWork.entity'
import { TechnicalWorkService } from '@services/TechnicalWork/TechnicalWork.service'
import cn from 'classnames'

type CabinetBannerProps = IInitialTechnicalWork &
  DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const CabinetBanner: FC<CabinetBannerProps> = ({
  startDate,
  endDate,
  timeToStart,
  timeToEnd,
  isRunning,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(!isRunning)

  const handleChangeOpen = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    setIsOpen((prev) => !prev)
  }

  const technicalWorkService = new TechnicalWorkService({
    startDate,
    endDate,
    isRunning,
    timeToStart,
    timeToEnd,
  })

  const timeAbout = technicalWorkService.getFormattedOffsetOfStartTime('через %formatted')

  const currentAllTimeText = technicalWorkService.getFormattedAllTime('D MMMM, HH:mm')

  const lastNotificationCondition = timeToStart.minutes && timeToStart.minutes <= 30

  const currentTitleText = lastNotificationCondition
    ? `Технические работы ${timeAbout}`
    : 'Технические работы'

  const currentSubtitleText = lastNotificationCondition
    ? 'Система будет недоступна во время их проведения'
    : timeAbout

  if ((!startDate && !endDate) || !isOpen) return null

  return (
    <div {...rest} className={cn(styles.cabinetBanner, rest.className)}>
      <div className={styles.technicalIcon_wrapper}>
        <Icon src={technicalIcon} size="initial" className={styles.technicalIcon} wrapper="span" />
      </div>
      <div className={styles.cabinetBanner__content}>
        <span className={styles['cabinetBanner__content-title']}>{currentTitleText}</span>
        <span className={styles['cabinetBanner__content-timeAbout']}>{currentSubtitleText}</span>
        <span className={styles['cabinetBanner__content-allTime']}>{currentAllTimeText}</span>
      </div>
      {!lastNotificationCondition && (
        <div className={styles.closeIcon__wrapper} role="presentation" onClick={handleChangeOpen}>
          <Icon className={styles.closeIcon} src={closeIcon} size="initial" wrapper="span" />
        </div>
      )}
    </div>
  )
}

export default memo(CabinetBanner)
