import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ObjectItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectItem'
import { SeventhConstructionObjectsPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const { isFormFieldError } = DocumentFormHelpers

interface CObjectsFormProps {
  name: SeventhConstructionObjectsPathName
  control: Control<ProjectSZPKFormValues>
}

const CObjectsForm: FC<CObjectsFormProps> = ({ name, control }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: objectForms } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhConstructionObjectsPathName,
    'keyNameId'
  >({
    name,
    control,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const allProjectObjectsLength = useWatch({
    name: 'additionalFields.allProjectObjectsLength',
  })

  if (!formInstance) return null

  const objectFormsError = formInstance.getFieldState(name)?.error

  const expandedObjectsRenderMode = allProjectObjectsLength < 70

  const handleAddObject = async () => {
    await handleAddItemToListWithOutValue?.(name)

    if (!objectForms.length) {
      formInstance.clearErrors(name)
    }
  }

  const handleRemoveObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  return (
    <Container className="p-0">
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: name,
          })}
        >
          <FlipperList list={objectForms}>
            {objectForms.map((form, index) => {
              const formName = `${name}.${index}` as const

              return (
                <SubscribableControl
                  key={form.id}
                  {...getSubscribableControlProps({
                    path: formName,
                  })}
                >
                  <ObjectItem
                    parentId={form.anchorId}
                    objectId={form.id}
                    blockViewIsValidating={blockViewIsValidating}
                    expandedRenderMode={expandedObjectsRenderMode}
                    editMode={editMode}
                    formInstance={formInstance}
                    name={formName}
                    indexOfObject={index}
                    onDeleteObject={handleRemoveObject(index)}
                  />
                </SubscribableControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(objectFormsError) && !objectForms.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddObject}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      {`Добавить основной объект №${objectForms.length + 1}`}
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default CObjectsForm
