import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Path } from 'react-hook-form'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import Error500Fallback from '@components/DataFallback/500Error'
import { IconProps } from '@components/Icon'
import Icon from '@components/Icon/Icon'
import Loader from '@components/Loader/Loader'
import Button from '@components/NewDesign/Button'
import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import OrganizationLetterHeadParts from '@components/OrganizationInfo/LetterHeadParts'
import { validationOrganizationByRoles } from '@components/OrganizationInfo/MainInfo/constants'
import OrganizationMainInfoGrid from '@components/OrganizationInfo/MainInfo/Grid'
import { getMissingFieldsByValidation } from '@components/OrganizationInfo/MainInfo/helpers'
import { isLoginByLP } from '@constants/system'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { isNull, isNullOrUndefined } from '@helpers/checkTypes'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { useOrganizationERGUL } from '@hooks/new/swr/useOrganizationERGUL'
import { useOrganizationVerify } from '@hooks/new/swr/useOrganizationVerify'
import { useBooleanState } from '@hooks/useBooleanState'
import warningAmberIcon from '@icons/alert/warning_amber.svg'
import ReloadIcon from '@icons/attachment/actions/ReplaceAttachmentIcon.svg'
import editIcon from '@icons/EditIcon.svg'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { mapOfOrganizationEGRULStatus } from '@services/Organization/organization.const'
import { IOrganizationInfo } from '@services/Organization/organization.entity'
import cn from 'classnames'

import { useNotificationOfOrganizationChange } from './hooks/useNotificationOfOrganizationChange'
import styles from './MainInfo.module.scss'

const POLLING_EGRUL_INTERVAL = 30000

const CurrentIconProps: IconProps = {
  src: ReloadIcon,
  size: 'xs',
  className: styles['contextMenu__button-icon'],
}

const OrganizationMainInfo = () => {
  const {
    organizationApi: { changeOrganizationInfo },
  } = useAPIContext()
  const { getMainRole } = useAuthContext()

  const {
    booleanState: organizationEditMode,
    setBooleanStateToFalse: handleDeactivateEditMode,
    setBooleanStateToTrue: handleActiveEditMode,
  } = useBooleanState()

  const [requiredFields, setRequiredFields] = useState<Record<Path<IOrganizationInfo>, boolean>>()

  const lastPollingOrganizationInfoRef = useRef<NodeJS.Timeout | null>(null)

  const {
    organizationSwr: {
      organizationInfo,
      error: organizationError,
      mutate: revalidateOrganizationInfo,
      isLoadingOrganization,
    },
  } = useOrganizationERGUL({
    key: {
      _key: 'organizationInfo',
    },
    config: {
      revalidateOnMount: true,
    },
  })

  const {
    organizationSwr: {
      organizationInfo: organizationInfoEGRUL,
      mutate: revalidateOrganizationInfoEGRUL,
    },
    lastEGRULUpdateInfo,
    updateEGRUL,
    EGRULRequestError,
    EGRULRequestIsLoading,
  } = useOrganizationERGUL({
    key: { _key: 'organizationInfoEGRUL' },
    config: {
      revalidateOnMount: true,
    },
  })

  const { mutate: revalidateOrganizationVerify } = useOrganizationVerify({
    key: {
      _key: 'organizationVerify',
    },
    config: {
      isPaused: () => isLoginByLP,
      revalidateOnMount: true,
    },
  })

  const { getUpdateNotification } = useNotificationOfOrganizationChange()

  const handleOrganizationVerify = async () => {
    await revalidateOrganizationVerify()
    await getUpdateNotification()
    toast(
      <Alert transparent variant="warning">
        После обновления данных из ЕГРЮЛ необходимости подать уведомление о смене реквизитов
      </Alert>,
    )
  }

  useEffect(() => {
    const mainRole = getMainRole?.()

    if (!mainRole || !organizationInfo) return

    setRequiredFields(validationOrganizationByRoles[mainRole])

    const roleMissingFields = getMissingFieldsByValidation(mainRole, organizationInfo)
    if (!roleMissingFields || !roleMissingFields.length) return

    const isActivateEditMode = !!roleMissingFields

    if (!isActivateEditMode) return

    handleActiveEditMode()
  }, [getMainRole, handleActiveEditMode, organizationInfo])

  useEffect(() => {
    if (!lastEGRULUpdateInfo) return

    if (lastPollingOrganizationInfoRef.current) {
      clearInterval(lastPollingOrganizationInfoRef.current)
      lastPollingOrganizationInfoRef.current = null
      if (lastEGRULUpdateInfo?.status === mapOfOrganizationEGRULStatus.SUCCEEDED) {
        handleOrganizationVerify()
      }
    }

    if (lastEGRULUpdateInfo?.status !== mapOfOrganizationEGRULStatus.IN_PROGRESS) return

    lastPollingOrganizationInfoRef.current = setInterval(() => {
      (async () => {
        await revalidateOrganizationInfoEGRUL()
      })()
    }, POLLING_EGRUL_INTERVAL)
  }, [lastEGRULUpdateInfo, organizationEditMode, revalidateOrganizationInfoEGRUL])

  useEffect(() => {
    const intervalId = lastPollingOrganizationInfoRef.current

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [])

  const isEGRULSuccesed =
    !isNullOrUndefined(lastEGRULUpdateInfo) &&
    lastEGRULUpdateInfo?.status === mapOfOrganizationEGRULStatus.SUCCEEDED

  const isEGRULProcessing =
    !isNullOrUndefined(lastEGRULUpdateInfo) &&
    lastEGRULUpdateInfo?.status === mapOfOrganizationEGRULStatus.IN_PROGRESS

  const isEGRULTimeout =
    !isNullOrUndefined(lastEGRULUpdateInfo) &&
    lastEGRULUpdateInfo?.status === mapOfOrganizationEGRULStatus.TIMEOUT

  const isEGRULFailed =
    !isNullOrUndefined(lastEGRULUpdateInfo) &&
    lastEGRULUpdateInfo?.status === mapOfOrganizationEGRULStatus.FAILED

  const lastUpdateEGRULDayjsInstance =
    organizationInfoEGRUL &&
    organizationInfoEGRUL.lastSuccessfulUpdateDatetime &&
    DayjsService.dayjs(organizationInfoEGRUL.lastSuccessfulUpdateDatetime)

  const lastUpdateEGRULDateTime =
    lastUpdateEGRULDayjsInstance &&
    lastUpdateEGRULDayjsInstance.format(
      lastUpdateEGRULDayjsInstance.year() === DayjsService.dayjsWithFormatToMSK().year()
        ? 'DD MMMM в HH:mm'
        : 'DD MMMM YYYY в HH:mm',
    )

  const getEGRULTooltipContent = useCallback(() => {
    if (isLoadingOrganization || EGRULRequestIsLoading) {
      return <Loader loading variant={'lite'} className={styles.contextMenu__loader} />
    }

    if (isEGRULProcessing) {
      return (
        <div className={styles.contextMenu__block}>
          <Loader
            loading
            variant={'lite'}
            className={cn(styles.contextMenu__loader, styles.contextMenu__icon)}
          />
          <div className={styles.contextMenu__text}>
            <Typography.Body variant="bodyMMedium">Обновляем данные организации</Typography.Body>
            {lastUpdateEGRULDateTime && (
              <span className={styles.contextMenu__textGray}>
                Последнее обновление {lastUpdateEGRULDateTime}
              </span>
            )}
          </div>
        </div>
      )
    }

    if (
      isEGRULSuccesed ||
      (organizationInfoEGRUL &&
        lastEGRULUpdateInfo &&
        isNull(lastEGRULUpdateInfo.status) &&
        organizationInfoEGRUL?.lastSuccessfulUpdateDatetime)
    ) {
      return (
        <div className={styles.contextMenu__block}>
          <div className={styles.contextMenu__text}>
            <span className={styles.contextMenu__textGray}>Данные организации обновлены:</span>
            <span className={styles.contextMenu__textBlack}>{lastUpdateEGRULDateTime}</span>
          </div>
        </div>
      )
    }

    if (isEGRULFailed || isEGRULTimeout || !!EGRULRequestError) {
      return (
        <div className={styles.contextMenu__block}>
          <Icon
            src={warningAmberIcon}
            size="m"
            className={cn(styles.contextMenu__icon, styles['contextMenu__icon--warning'])}
          />
          <div className={styles.contextMenu__text}>
            <Typography.Body variant="bodyMMedium">
              Ошибка обновления. Повторите попытку
            </Typography.Body>
            {lastUpdateEGRULDateTime && (
              <span className={styles.contextMenu__textGray}>
                Последнее обновление {lastUpdateEGRULDateTime}
              </span>
            )}
          </div>
        </div>
      )
    }

    return (
      <Typography.Body className={styles.contextMenu__textBlack} variant="bodyMRegular">
        Данные организации еще не обновлялись
      </Typography.Body>
    )
  }, [
    isLoadingOrganization,
    EGRULRequestIsLoading,
    isEGRULProcessing,
    isEGRULSuccesed,
    organizationInfoEGRUL,
    lastEGRULUpdateInfo,
    isEGRULFailed,
    EGRULRequestError,
    isEGRULTimeout,
    lastUpdateEGRULDateTime,
  ])

  const handleClickUpdateEGRUL = async () => {
    try {
      await updateEGRUL()

      await revalidateOrganizationInfoEGRUL()
    } catch (e) {
      throw e
    }
  }

  const handleOrganizationChange = async (newOrganization: IOrganizationInfo) => {
    try {
      await changeOrganizationInfo({
        fax: newOrganization.fax,
        email: newOrganization.email,
        okpo: newOrganization.okpo,
        oktmo: newOrganization.oktmo,
        phone: newOrganization.phone,
        paymentDetails: newOrganization.paymentDetails,
        phoneExtension: newOrganization.phoneExtension,
      })

      handleDeactivateEditMode()

      try {
        await revalidateOrganizationInfo()
      } catch (e) {}

      try {
        await revalidateOrganizationVerify()
      } catch (e) {}
    } catch (e) {
      throw e
    }
  }

  const handleCancelOrganizationChange = async () => {
    handleDeactivateEditMode()

    try {
      await revalidateOrganizationInfo()
    } catch (error) {}
  }

  return (
    <div
      className={cn(styles.organizationMainInfo, {
        [styles['organizationMainInfo-error']]: !!organizationError,
      })}
    >
      <Error500Fallback
        error={isIntervalServerError(organizationError?.response?.status)}
        title={'Не удалось загрузить информацию об организации'}
      >
        {!isLoadingOrganization && (
          <div className={styles.organizationMainInfo__header}>
            <Typography.Headline as={'h2'} variant={'headlineH2'}>
              {organizationInfo?.name}{' '}
            </Typography.Headline>

            <div className={styles.organizationMainInfo__actions}>
              {!organizationEditMode && (
                <Button
                  geometry={'square'}
                  size={'s'}
                  view={'plain'}
                  variant={'buttonSMedium'}
                  className={styles['organizationMainInfo__actions-button']}
                  leadingIcon={{
                    src: editIcon,
                    size: 'xs',
                  }}
                  onClick={handleActiveEditMode}
                >
                  Редактировать
                </Button>
              )}
              <Tooltip
                trigger={'hover'}
                content={getEGRULTooltipContent()}
                position={'right'}
                offset={[0, 10]}
                popoverClassName={styles.contextMenu__popover}
                contentClassName={styles.contextMenu__content}
                targetClassName={styles.contextMenu__target}
                arrowClassName={styles.contextMenu__arrow}
              >
                <Button
                  geometry={'square'}
                  size={'s'}
                  view={'plain'}
                  variant={'buttonSMedium'}
                  leadingIcon={CurrentIconProps}
                  className={styles.contextMenu__button}
                  onClick={handleClickUpdateEGRUL}
                >
                  Обновить данные из ЕГРЮЛ
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
        <div className={styles.organizationMainInfo__body}>
          <Loader loading={isLoadingOrganization}>
            {organizationInfo && (
              <OrganizationMainInfoGrid
                editMode={organizationEditMode}
                requiredFields={requiredFields}
                organization={organizationInfo}
                onOrganizationChange={handleOrganizationChange}
                onOrganizationChangeCancel={handleCancelOrganizationChange}
              />
            )}
          </Loader>
        </div>
      </Error500Fallback>
      {organizationInfo && <OrganizationLetterHeadParts organizationId={organizationInfo.id} />}
    </div>
  )
}

export default memo(OrganizationMainInfo)
