import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { ProjectSZPKFormValues, RidNameRegistration } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const ridNamePermissionsValidationMap: FormValuesValidationSection<
  keyof RidNameRegistration,
  ProjectSZPKFormValues
> = {
  ridOption: {
    required: defaultRHFValidation.required,
  },
}

export { ridNamePermissionsValidationMap }
