import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'

import { mapOfCertificateOnMonitoringResultsMenu } from '../const'
import CertificateOnMonitoringResultsForms from '../Forms'

const mapOfCertificateOnMonitoringResultsForms: FormItemProps = {
  '1': {
    node: <CertificateOnMonitoringResultsForms.First />,
  },
  '2': {
    node: <CertificateOnMonitoringResultsForms.Second />,
  },
  '3': {
    node: <CertificateOnMonitoringResultsForms.Third />,
  },
  '4': {
    node: <CertificateOnMonitoringResultsForms.Fourth />,
  },
  '5': {
    node: <CertificateOnMonitoringResultsForms.Fifth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '6': {
    node: <CertificateOnMonitoringResultsForms.Sixth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '7': {
    node: <CertificateOnMonitoringResultsForms.Seventh />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '8': {
    node: <CertificateOnMonitoringResultsForms.Eighth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '9': {
    node: <CertificateOnMonitoringResultsForms.Ninth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '10': {
    node: <CertificateOnMonitoringResultsForms.Tenth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '11': {
    node: <CertificateOnMonitoringResultsForms.Eleventh />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '12': {
    node: <CertificateOnMonitoringResultsForms.Twelfth />,
  },
} as const

const CertificateOnMonitoringResultsForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<CertificateOnMonitoringResultsFormValues>()

  const {
    state: { formIsLoading },
  } = useCertificateOnMonitoringResultsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfCertificateOnMonitoringResultsMenu}
      mapOfForms={mapOfCertificateOnMonitoringResultsForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(CertificateOnMonitoringResultsForm)
