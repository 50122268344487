import { FC } from 'react'

import ProjectsContainer, {
  ProjectsContainerProps,
} from '@components/Projects/Containers/ProjectsContainer'
import cn from 'classnames'

import styles from '../Container.module.scss'

const ProjectContainer: FC<ProjectsContainerProps> = ({ children, className, ...rest }) => {
  return (
    <ProjectsContainer {...rest} className={cn(styles.projectContainer, className)}>
      {children}
    </ProjectsContainer>
  )
}

export default ProjectContainer
