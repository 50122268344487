import React, { FC } from 'react'

import Loader, { LoaderProps } from '@components/Loader/Loader'
import cn from 'classnames'

import styles from './ControlContainerStatusLoader.module.scss'

type ControlContainerStatusLoader = Omit<LoaderProps, 'variant'>

const ControlContainerStatusLoader: FC<ControlContainerStatusLoader> = ({
  loading = true,
  className,
  ...restLoaderProps
}) => {
  return (
    <Loader
      {...restLoaderProps}
      className={cn(styles.controlContainerStatusLoader__loader, className)}
      loading={loading}
      variant="lite"
    />
  )
}

export default ControlContainerStatusLoader
