import { Dispatch, SetStateAction, useState } from 'react'
import { useLocation } from 'react-router'

import { NotificationId } from '@components/NewNotifications/model'
import {
  currentNotificationsList,
  newNotificationsArrivedHandler,
} from '@components/NewNotifications/notificationsManager'
import { defaultPageSize, PageState } from '@components/NewNotifications/useNotifications'
import { isLoginByLP } from '@constants/system'
import { INotification } from '@context/APIContext/hooks/useNotificationsApi'
import { useNotifications as useNotificationsData } from '@hooks/swr/useNotifications'
import { mutate, useSWRConfig } from 'swr'

export const firstPageKey = 'notifications/page0'
const buildLastPageOfNotificationsData = (page) => `notifications/page${page}`

const twoMinutesInMilliseconds = 1000 * 60 * 2

export interface PageStateProps extends PageState {
  setPage: Dispatch<SetStateAction<PageState>>
  isPanelOpen: boolean
  onLoadLastPage: (data: INotification[]) => any
}

export const useNotificationsManager = ({
  setPage,
  page,
  onLoadLastPage,
  isPanelOpen,
  lastPage,
}: PageStateProps) => {
  const { cache } = useSWRConfig()

  const [notificationsState, setNotificationsState] = useState<INotification[]>(
    cache.get(firstPageKey) || [],
  )

  const pageView = page + 1

  const location = useLocation()

  const syncStateWithList = () => {
    setNotificationsState(currentNotificationsList.toArray())
  }

  const lastPageLoadedSetter = (receivedPageSize: number) => {
    const newLastPage = receivedPageSize ? page : Math.max(page - 1, 0)

    if (isPanelOpen) onLoadLastPage?.(notificationsState)

    return setPage((prevState) => ({ ...prevState, page: newLastPage, lastPage: newLastPage }))
  }

  const isItLastPage = (page) => page.length < defaultPageSize

  const notificationsToView = notificationsState.slice(0, pageView * defaultPageSize)

  const canGoToNextPage =
    notificationsToView.length <= notificationsState.length && page !== lastPage

  /**
   * Количество страниц * размер страницы
   */
  const notEnoughData = notificationsState.length < pageView * defaultPageSize

  const shouldRevalidateLastPage = canGoToNextPage && notEnoughData
  //
  // const keyForNotificationsData = useMemo(() => {
  //   // !page - Для первой страницы отдельный обработчик
  //   if (!page || !canGoToNextPage) return null
  //
  //   return !shouldRevalidateLastPage && buildNotificationsDataKey(page, location.pathname)
  // }, [page, canGoToNextPage, shouldRevalidateLastPage, location.pathname])
  //

  /* Если панель открыта, то перезапросы не делаем. Лайв добваление уведомлений займет ощутимое кол-во доп времени, даже учи. В постановке такое требование не указано */
  const { isNotificationsLoading: isFirstPageOfNotificationsLoading, error: firstPageError } =
    useNotificationsData({
      key: `notifications/${location.pathname}/page0`,
      page: 0,
      config: {
        /* FirstPage/1 - если уведомлений ранее не было(ответ 200 с пустым массивом) - отправить запрос повторно */
        /* если ответ ранее был 200 и с не пустым массивом - использовать тот же ответ */
        isPaused: () => isLoginByLP || (isPanelOpen && cache.get(firstPageKey)?.length),
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: false,
        refreshInterval: twoMinutesInMilliseconds,
        onSuccess: (data: INotification[]) => {
          // if (notificationsState.length) return
          /* Если последовательность не изменилась */
          // if (data[data.length - 1] === notificationsState[notificationsState.length - 1]) return
          /* После удаления уведомления с первой страницы */
          // else if (notificationsState.length < (pageView) * defaultPageSize) currentNotificationsList.recreateList(data)
          // else {
          /* Если пришло свежее уведомление */
          // freshNotificationsArrivedHandler(data)
          /* Сформировалась новая страница. Теперь мы не знаем о номере последней */
          // setPage((prevState) => ({ ...prevState, lastPage: null }))
          // }
          //  FirstPage/2 - если ответ такой же - показать попап с сообщением "Новых уведомлений нет" и не открывать панель с уведомлениями
          if (!data.length) cache.delete(firstPageKey)

          /* Устанавливаем последнуюю страницу */
          if (isItLastPage(data)) lastPageLoadedSetter(data.length)

          // if (!notificationsState) {
          currentNotificationsList.recreateList(data)
          return syncStateWithList()
          // }

          // /* Если первая страница не изменилась */
          // if (!data.length) return
          //
          // if (data.length === defaultPageSize) newNotificationsArrivedHandler(data)
          // /* Если пришло свежее уведомление */
          // else freshNotificationsArrivedHandler(data)
          //
          // /* Актуализируем состояние уведомлений с списком */
          // syncStateWithList()
        },
      },
    })

  // const { isNotificationsLoading } = useNotificationsData({
  //   key: null,
  //   page,
  //   config: {
  //     revalidateIfStale: false,
  //     onSuccess: (data: INotification[]) => {
  //       /**
  //        * Если ответ пустой, значит страниц больше нет.
  //        * Так же удаляем результат из кэша, на случай получения нового уведомления в рантайме и формирования из-за этого новой страницы (Пока смысла не имеет)
  //        */
  //       if (!data.length) {
  //         // cache.delete(keyForNotificationsData)
  //         /* Предыдущая страница становится последней и текущей */
  //         return lastPageLoadedSetter(page - 1, page - 1)
  //       }
  //
  //
  //
  //       /* Если полученная страница меньше размера запрошенной, то страниц больше нет, мы на последней */
  //       if (isItLastPage(data)) lastPageLoadedSetter(page, page)
  //
  //       /* Если получили новые уведомления */
  //       newNotificationsArrivedHandler(data)
  //       syncStateWithList()
  //     }
  //   }
  // })

  const { isNotificationsLoading: isLastPageDataLoading } = useNotificationsData({
    /* Если последнюю страницу будет догружать обычный обработчик, то не дублируем запрос */
    key: buildLastPageOfNotificationsData(page),
    page,
    config: {
      isPaused: () => isLoginByLP || !shouldRevalidateLastPage,
      revalidateIfStale: false,
      onSuccess: (data: INotification[]) => {
        /* Используется после прочтения уведомления. Догружает страницу, что бы компенсировать смещение */
        if (page === 0) currentNotificationsList.recreateList(data)
        else newNotificationsArrivedHandler(data)

        if (isItLastPage(data)) lastPageLoadedSetter(data.length)

        /* Актуализируем состояние уведомлений с списком */
        syncStateWithList()
      },
    },
  })
  // /* После удаления элемента догружаем последнюю страницу, что бы компенсировать смещение */
  const removeNotification = (id: NotificationId) => {
    cache.delete(buildLastPageOfNotificationsData(page))
    currentNotificationsList.removeValue(id)
    syncStateWithList()

    mutate(buildLastPageOfNotificationsData(page), undefined, { revalidate: true })
  }

  return {
    allNotifications: notificationsState,
    notifications: notificationsToView,
    removeNotification,
    isLastPageDataLoading,
    firstPageError,
    // error,
    // mutate,
    isNotificationsLoading: false,
    isFirstPageOfNotificationsLoading,
    canGoToNextPage,
  }
}
