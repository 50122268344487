import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'
import { lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const firstSectionValidationMap: FormValuesValidationSection<
  keyof NoticeOfProjectRejectConcludeSZPKFormValues['1'],
  NoticeOfProjectRejectConcludeSZPKFormValues
> = {
  documentReceiversDraft: {
    validate: (value) => isString(value) && lengthValidate(value, 5000),
  },
}

export { firstSectionValidationMap }
