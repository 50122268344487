import React, { FC, memo, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { PopupProps } from 'react-popup-manager'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import AAgreementOnNonConclusionOrNonPerformanceOfConcessionA from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { getAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatus } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Menu/const'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Typography from '@components/NewDesign/Typography'
import { IFormModalParams } from '@context/APIContext/types'
import cn from 'classnames'

import AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutWrapper from './Wrapper'

export type AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalProps = IFormModalParams

type AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutProps = PopupProps &
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalProps

const AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayout: FC<
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutProps
> = ({ projectId, formId, isOpen, onClose, initialErrorsFromViolations, editMode }) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const isFederalSZPK = useWatch({
    control: formInstance?.control,
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues.additionalFields
      .isFederal,
  })

  const isMunicipalitiesRender = !!useWatch({
    control: formInstance?.control,
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues.additionalFields
      .isMunicipalityParticipant,
  })

  const handleOnClose = useCallback(() => {
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  const initialMenuState = useMemo(() => {
    return getAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatus(
      isFederalSZPK,
      isMunicipalitiesRender,
    )
  }, [isFederalSZPK, isMunicipalitiesRender])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.MenuManager
        initialMenuHash={
          initialMenuState.initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuState
        }
        initialSectionId={
          initialMenuState.initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuState[0]
            .id
        }
      >
        <AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
          onClose={handleOnClose}
        >
          <AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutWrapper
            lastUpdateDraftTime={lastUpdateDraftTime}
          >
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Дополнительное соглашение о незаключении или неисполнении концессионного соглашения
              </Typography.Headline>
              <AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.StatementForm
                initialMenu={initialMenuState.mapOfMenu}
                className={styles.layout__form}
              />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}>
              <AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutWrapper>
        </AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.Manager>
      </AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.MenuManager>
    </DocumentFormLayout>
  )
}

export default memo(AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayout)
