import React, { useCallback, useEffect, useRef } from 'react'
import { Path, useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import { ControlledSingleSelect } from '@components/NewDesign/Select'
import { ControlledMultipleSelect } from '@components/NewDesign/Select/MultipleSelect/ControlledMultipleSelect'
import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import { useLayoutMode } from '@components/Projects/[id]/LayoutMods'
import DataView from '@components/Projects/[id]/ParticipantsOfStatement/DataView'
import { useOrganizationMembers } from '@components/Projects/[id]/ParticipantsOfStatement/hooks'
import { useParticipantsManager } from '@components/Projects/[id]/ParticipantsOfStatement/Manager'
import { SkeletonSelect } from '@components/Skeleton/Select/SkeletonSelect'
import { SkeletonText } from '@components/Skeleton/Text/SkeletonText'
import MunicipalityOptionsTailNode from '@containers/MunicipalityOptionsTailNode'
import { useAPIContext } from '@context/APIContext'
import { isEmptyString, isString } from '@helpers/checkTypes'
import { useOrganizationInfo } from '@hooks/new/swr/useOrganizationInfo'
import { useInView } from '@hooks/useInView'
import { useMunicipalitySelectConfig } from '@hooks/useMunicipalitySelectConfig'
import { IPostAttributeBody } from '@services/Attribute/Attribute.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import { municipalityCaptionTexts, municipalityLabelTexts } from './constants'
import styles from './ParticipantsOfStatementEdit.module.scss'

interface ParticipantsEditFormValues {
  municipality: string[]

  subjects: string[]

  subjectsExtra: string[]
}

const EditParticipantsOfStatementMode = () => {
  const {
    projectsApi: { changeProjectAttribute },
  } = useAPIContext()

  const {
    editParticipantsState: {
      subjects,
      subjectsExtra,
      municipalityMembers,
      isFederal,
      isLoadingMunicipalityMembers,
    },
    viewParticipantsState: { OIVMembers, OIVExtraViewMembers },
  } = useParticipantsManager()

  const { isChangesMadeMode } = useLayoutMode()

  const { inView, observe: selectRef } = useInView()

  const { preparedOrganizationsForSelect, isLoadingAllOrganizations, onSelectContainerScroll } =
    useOrganizationMembers()

  const { organizationInfo, isLoadingOrganization } = useOrganizationInfo({
    key: { _key: 'organizationInfo' },
  })

  const { projectId } = useParams()

  const { control, watch, reset, setValue } = useForm<ParticipantsEditFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      municipality: municipalityMembers.ids,
      subjects: subjects.length ? subjects : [],
      subjectsExtra: subjectsExtra.length ? subjectsExtra : [],
    },
  })

  const formValues = watch()

  const previousFormValues = useRef<ParticipantsEditFormValues>(formValues)

  const isShowMunicipality = !isFederal || !isChangesMadeMode || !!municipalityMembers.ids?.length

  const municipalityLabelText = isFederal ? '' : municipalityLabelTexts.regionalText

  const federalMunicipalityCaptionText = isChangesMadeMode
    ? ''
    : municipalityCaptionTexts.federalText

  const municipalityCaptionText = isFederal
    ? federalMunicipalityCaptionText
    : municipalityCaptionTexts.regionalText

  const {
    onChangeSearchValue,
    onContentScroll,
    preparedMunicipalityOption,
    municipalityData: { isMunicipalityLoading, error },
  } = useMunicipalitySelectConfig({
    isProjectFederal: isFederal,
  })

  //Обновления стейта после обновления переменных
  useEffect(() => {
    reset({
      municipality: municipalityMembers.ids?.length ? municipalityMembers.ids : [],
      subjects: subjects.length ? subjects : [],
      subjectsExtra: subjectsExtra.length ? subjectsExtra : [],
    })
  }, [municipalityMembers.ids, reset, subjects, subjectsExtra])

  const onFieldBlur = useCallback(
    (name: IPostAttributeBody['name']) => async () => {
      const formName = name as Path<ParticipantsEditFormValues>

      const value = watch(formName)

      try {
        await changeProjectAttribute(projectId || '', {
          name,
          value: isString(value) ? value : JSON.stringify(value),
        })

        previousFormValues.current = { ...previousFormValues.current, [formName]: value }
      } catch (error) {
        const additionInfo = {
          projectAttributeName: name,
          projectAttributeValue: value,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'EditParticipantsOfStatementMode',
            moduleName: 'ParticipantsOfStatement',
            componentType: 'chooseMembers',
          },
        })(error)

        setValue(formName, previousFormValues.current[formName])

        throw error
      }
    },
    [changeProjectAttribute, projectId, setValue, watch],
  )

  const onFieldRemoveChip = useCallback(
    (name: IPostAttributeBody['name']) => async (payload: string[], _removedValue: string) => {
      const formName = name as Path<ParticipantsEditFormValues>

      try {
        await changeProjectAttribute(projectId || '', {
          name,
          value: JSON.stringify(payload),
        })

        previousFormValues.current = { ...previousFormValues.current, [formName]: payload }
      } catch (error) {
        const additionInfo = {
          projectAttributeName: name,
          projectAttributeValue: payload,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'EditParticipantsOfStatementMode',
            moduleName: 'ParticipantsOfStatement',
            componentType: 'chooseMembers',
          },
        })(error)

        setValue(formName, previousFormValues.current[formName])

        throw error
      }
    },
    [changeProjectAttribute, projectId, setValue, watch],
  )

  const handleChangeSingleFormValue = useCallback(
    (name: IPostAttributeBody['name']) => async (value: string) => {
      const formName = name as Path<ParticipantsEditFormValues>

      const preparedValue: string[] = isEmptyString(value) ? [] : [value]

      try {
        await changeProjectAttribute(projectId || '', {
          name,
          value: JSON.stringify(preparedValue),
        })

        previousFormValues.current = { ...previousFormValues.current, [formName]: preparedValue }
      } catch (error) {
        const additionInfo = {
          projectAttributeName: name,
          projectAttributeValue: value,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'EditParticipantsOfStatementMode',
            moduleName: 'ParticipantsOfStatement',
            componentType: 'handleChangeSingleFormValue',
          },
        })(error)

        setValue(formName, previousFormValues.current[formName])

        throw error
      }
    },
    [changeProjectAttribute, projectId, setValue],
  )

  return (
    <div className={styles.participantsOfStatementEdit}>
      {!isLoadingOrganization && (
        <Typography.Body variant={'bodyLMedium'}>Укажите участников соглашения</Typography.Body>
      )}
      <div
        className={cn(styles.participantsOfStatementEdit__content, {
          [styles['participantsOfStatementEdit__content--baseline']]:
            !isFederal && !isChangesMadeMode,
        })}
      >
        <SkeletonText animate visible={isLoadingOrganization}>
          {organizationInfo?.name && (
            <div
              className={cn(
                styles['participantsOfStatementEdit__content-leftItem'],
                styles['participantsOfStatementEdit__content-leftItem--text'],
              )}
            >
              <DataView suptitle={'Инвестор'}>{organizationInfo.name}</DataView>
            </div>
          )}
        </SkeletonText>
        <SkeletonSelect animate visible={isLoadingOrganization}>
          <div className={styles['participantsOfStatementEdit__content-rightItem']}>
            {isFederal && (
              <DataView suptitle={'Федеральный орган исполнительной власти'}>
                Министерство экономического развития Российской Федерации
              </DataView>
            )}
            {!isFederal && !isChangesMadeMode && (
              <ControlledMultipleSelect
                withContextSearch
                options={preparedOrganizationsForSelect}
                controllerProps={{
                  control,
                  name: 'subjectsExtra',
                }}
                popoverProps={{
                  className: cn({ [styles.popover__collapsed]: !inView }),
                }}
                inputProps={{
                  fixWidth: true,
                  label: 'Иные субъекты РФ, на территории которых реализуется проект',
                  caption:
                    'указываются, если сторонами соглашения являются более одного субъекта РФ',
                  view: 'secondary',
                  controlClassName: styles.select,
                  dataTestId: 'EditParticipantsOfStatementMode-subjectsExtra-multiple-select',
                  captionClassName: styles['select-caption'],
                }}
                chipProps={{
                  dataTestId: 'EditParticipantsOfStatementMode-subjectsExtra-chip',
                  iconDataTestId: 'EditParticipantsOfStatementMode-subjectsExtra-delete-chip',
                }}
                optionsContainer={{
                  onScroll: onSelectContainerScroll,
                }}
                onBlurForm={onFieldBlur('subjectsExtra')}
                onRemoveChip={onFieldRemoveChip('subjectsExtra')}
              />
            )}
          </div>
        </SkeletonSelect>

        <SkeletonSelect
          animate
          dataTestId="EditParticipantsOfStatementMode-subjects-skeletonSelect"
          visible={isLoadingOrganization || isLoadingAllOrganizations}
        >
          <div
            className={cn(
              styles['participantsOfStatementEdit__content-select'],
              styles['participantsOfStatementEdit__content-leftItem'],
              {
                [styles['participantsOfStatementEdit__content-leftItem--text']]:
                  !isFederal && isChangesMadeMode,
              },
            )}
          >
            {!isFederal &&
              isChangesMadeMode &&
              OIVMembers.map((oivMember) => (
                <div key={oivMember.organizationId} className={styles['dataView-wrapper']}>
                  {oivMember.fullName ? (
                    <Tooltip
                      position="top-start"
                      offset={[0, 2]}
                      content={oivMember.fullName}
                      targetClassName={styles.tooltip__target}
                      popoverClassName={styles.tooltip__popover}
                      arrowClassName={styles.tooltip__arrow}
                    >
                      <DataView suptitle="Уполномоченный орган субъекта РФ (координатор)">
                        {oivMember.name}
                      </DataView>
                    </Tooltip>
                  ) : (
                    <DataView suptitle="Уполномоченный орган субъекта РФ (координатор)">
                      {oivMember.name}
                    </DataView>
                  )}
                </div>
              ))}
            {!isFederal &&
              isChangesMadeMode &&
              OIVExtraViewMembers.map((oivExtra) => (
                <div key={oivExtra.organizationId} className={styles['dataView-wrapper']}>
                  {oivExtra.fullName ? (
                    <Tooltip
                      position="top-start"
                      offset={[0, 2]}
                      content={oivExtra.fullName}
                      targetClassName={styles.tooltip__target}
                      popoverClassName={styles.tooltip__popover}
                      arrowClassName={styles.tooltip__arrow}
                    >
                      <DataView suptitle="Уполномоченный орган субъекта РФ">
                        {oivExtra.name}
                      </DataView>
                    </Tooltip>
                  ) : (
                    <DataView suptitle="Уполномоченный орган субъекта РФ">{oivExtra.name}</DataView>
                  )}
                </div>
              ))}
            {isFederal && (
              <ControlledMultipleSelect
                withContextSearch
                options={preparedOrganizationsForSelect}
                controllerProps={{
                  control,
                  name: 'subjects',
                }}
                popoverProps={{
                  className: cn({ [styles.popover__collapsed]: !inView }),
                }}
                inputProps={{
                  fixWidth: true,
                  label: 'Субъект(-ы) РФ, на территории которого(-ых) реализуется проект',
                  view: 'secondary',
                  controlClassName: styles.select,
                  captionClassName: styles['select-caption'],
                  dataTestId: 'EditParticipantsOfStatementMode-subjects-multiple-select',
                  formControlDataTestId: 'EditParticipantsOfStatementMode-subjectsFormControl',
                }}
                optionsProps={{
                  dataTestId: 'EditParticipantsOfStatementMode-subjects-optionsContainer',
                }}
                chipProps={{
                  dataTestId: 'EditParticipantsOfStatementMode-subjects-chip',
                  iconDataTestId: 'EditParticipantsOfStatementMode-subjects-delete-chip',
                }}
                optionsContainer={{
                  onScroll: onSelectContainerScroll,
                }}
                onBlurForm={onFieldBlur('subjects')}
                onRemoveChip={onFieldRemoveChip('subjects')}
              />
            )}
            {!isFederal && !isChangesMadeMode && (
              <ControlledSingleSelect
                withContextSearch
                options={preparedOrganizationsForSelect}
                controllerProps={{
                  control,
                  name: 'subjects',
                }}
                popoverProps={{
                  className: cn({ [styles.popover__collapsed]: !inView }),
                }}
                inputProps={{
                  fixWidth: true,
                  label: 'Субъект РФ, на территории которого реализуется проект',
                  caption:
                    'в случае, если субъектов РФ-сторон соглашения несколько, выбранный регион будет координировать рассмотрение документов',
                  view: 'secondary',
                  controlClassName: styles.select,
                  dataTestId: 'EditParticipantsOfStatementMode-subjects-single-select',
                  captionClassName: styles['select-caption'],
                }}
                optionsContainer={{
                  onScroll: onSelectContainerScroll,
                }}
                onChangeFormValue={handleChangeSingleFormValue('subjects')}
              />
            )}
          </div>
        </SkeletonSelect>
        <SkeletonSelect
          animate
          dataTestId="EditParticipantsOfStatementMode-municipality-skeletonSelect"
          visible={isLoadingOrganization || isLoadingMunicipalityMembers}
        >
          <div
            className={cn(
              styles['participantsOfStatementEdit__content-rightItem'],
              styles['participantsOfStatementEdit__content-select'],
            )}
          >
            {isShowMunicipality && (
              <ControlledMultipleSelect
                withInput
                disabled={isFederal || !!error}
                defaultOptionsSelected={municipalityMembers.defaultOptionsSelected}
                options={preparedMunicipalityOption}
                optionsContainer={{
                  onScroll: onContentScroll,
                }}
                controllerProps={{
                  control: control,
                  name: 'municipality',
                }}
                popoverProps={{
                  className: cn({ [styles.popover__collapsed]: !inView }),
                }}
                inputProps={{
                  fixWidth: true,
                  controlClassName: styles.select,
                  label: `Муниципальное образование ${municipalityLabelText}`,
                  caption: municipalityCaptionText,
                  dataTestId: 'EditParticipantsOfStatementMode-municipality-multipleSelect',
                  formControlDataTestId: 'EditParticipantsOfStatementMode-municipalityFormControl',
                  captionClassName: styles['select-caption'],
                }}
                optionsProps={{
                  isLoading: isMunicipalityLoading,
                  dataTestId: 'EditParticipantsOfStatementMode-municipality-optionsContainer',
                  tailNode: (
                    <MunicipalityOptionsTailNode
                      municipalityOptionsLength={preparedMunicipalityOption.length}
                    />
                  ),
                }}
                onChangeSearchValue={onChangeSearchValue}
                onBlurForm={onFieldBlur('municipality')}
                onRemoveChip={onFieldRemoveChip('municipality')}
              />
            )}
          </div>
        </SkeletonSelect>
        <div ref={selectRef} className={styles.collapsed_tracker} />
      </div>
    </div>
  )
}

export default EditParticipantsOfStatementMode
