import { ComponentType } from 'react'

import { useAuthContext } from '@context/AuthContext'

function withRolesProtect<T = any>(WrappedComponent: ComponentType<T>, roles: string[]) {
  return (props: T) => {
    const { getUserRole, checkingRole } = useAuthContext()

    const currentRole = getUserRole?.()

    if (!currentRole || !roles.some((role) => checkingRole?.(role))) return null

    return <WrappedComponent {...props} />
  }
}

export default withRolesProtect
