import { UseFormReturn, useWatch } from 'react-hook-form'

import { EighthAnotherStagesArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/8/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'

export const useIsOtherActivityInfrastructureRenderCondition = (
  formInstance: UseFormReturn<ProjectSZPKFormValues, any>,
  name: EighthAnotherStagesArrayPathName,
) => {
  const measureObjects = useWatch({
    name: `${name}.measureObjects`,
    control: formInstance.control,
  })

  const isOtherActivityInfrastructureRenderCondition = !measureObjects.length
  return isOtherActivityInfrastructureRenderCondition
}
