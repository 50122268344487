import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'

import { NotificationOfIdentifiedNPAListErrorsFormValues } from './types'

export const NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()

export const NotificationOfIdentifiedNPAListErrorsFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<NotificationOfIdentifiedNPAListErrorsFormValues>()

export const NotificationOfIdentifiedNPAListErrorsFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
