import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import CertificateOfComplianceContractForRecognitionConcludedALayout from './Layout'
import CertificateOfComplianceContractForRecognitionConcludedAModalManager from './Manager'
import CertificateOfComplianceContractForRecognitionConcludedAMenu from './Menu'
import CertificateOfComplianceContractForRecognitionConcludedAStatementForm from './Statement'

interface CertificateOfComplianceContractForRecognitionConcludedAFC extends FC {
  Layout: typeof CertificateOfComplianceContractForRecognitionConcludedALayout
  Menu: typeof CertificateOfComplianceContractForRecognitionConcludedAMenu
  StatementForm: typeof CertificateOfComplianceContractForRecognitionConcludedAStatementForm
  Manager: typeof CertificateOfComplianceContractForRecognitionConcludedAModalManager
}

const useCertificateOfComplianceContractForRecognitionConcludedAControl = () => {
  const popupManager = usePopupManager()

  const openCertificateOfComplianceContractForRecognitionConcludedAModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        CertificateOfComplianceContractForRecognitionConcludedALayout,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openCertificateOfComplianceContractForRecognitionConcludedAModal }
}

const CertificateOfComplianceContractForRecognitionConcludedA: CertificateOfComplianceContractForRecognitionConcludedAFC =
  () => null

CertificateOfComplianceContractForRecognitionConcludedA.Menu =
  CertificateOfComplianceContractForRecognitionConcludedAMenu
CertificateOfComplianceContractForRecognitionConcludedA.Layout =
  CertificateOfComplianceContractForRecognitionConcludedALayout
CertificateOfComplianceContractForRecognitionConcludedA.StatementForm =
  CertificateOfComplianceContractForRecognitionConcludedAStatementForm
CertificateOfComplianceContractForRecognitionConcludedA.Manager =
  CertificateOfComplianceContractForRecognitionConcludedAModalManager

export { useCertificateOfComplianceContractForRecognitionConcludedAControl }
export default CertificateOfComplianceContractForRecognitionConcludedA
