import { ErrorDescriptionTexts, ErrorHeadersTexts } from '@components/Forms/LoginForm/constants'
import {
  handleBadRequestAuthError,
  handleForbiddenAuthError,
  handleInternalServerError,
  handleUnauthorizedError,
} from '@components/Forms/LoginForm/helpers'
import { defaultMessageSupportWithLink } from '@constants/texts'
import { isNull } from '@helpers/checkTypes'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import { certificateReducerActionMap, certificateReducerComponentInfo } from './constants'
import type {
  CertificateActionCreators,
  CertificateReducerAction,
  CertificateReducerState,
} from './types'

const initialCertificateState: CertificateReducerState = {
  isLoading: false,
  isHideButtons: false,
  caption: '',
  certificates: [],
  error: null,
}

const certificateReducer = (
  state: CertificateReducerState,
  action: CertificateReducerAction,
): CertificateReducerState => {
  const { type } = action

  switch (type) {
    case certificateReducerActionMap.SETUP_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case certificateReducerActionMap.SETUP_CAPTION:
      return {
        ...state,
        caption: action.payload,
      }

    case certificateReducerActionMap.SETUP_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      }

    case certificateReducerActionMap.SETUP_CRYPTO_PLUGIN_ERROR:
      return {
        ...state,
        isHideButtons: true,
        error: {
          header: ErrorHeadersTexts.cryptoPluginNotInstalled,
          body: ErrorDescriptionTexts.cryptoPluginNotInstalled,
          swipeTab: 1,
        },
      }

    case certificateReducerActionMap.SETUP_CERTIFICATE_MISSING_ERROR:
      return {
        ...state,
        isHideButtons: true,
        error: {
          header: ErrorHeadersTexts.certificateMissing,
          body: ErrorDescriptionTexts.certificateMissing,
        },
      }

    case certificateReducerActionMap.SETUP_NATIVE_ERROR:
      const nativeError = action.payload

      return {
        ...state,
        error: {
          header: ErrorHeadersTexts.errorMessage,
          body: nativeError.message || defaultMessageSupportWithLink,
        },
      }

    case certificateReducerActionMap.SETUP_ERROR:
      if (isNull(action.payload)) {
        return {
          ...state,
          error: action.payload,
        }
      }

      const error = action.payload
      const { error_description: errorDescription, detail: errorDetail } =
        error?.response?.data ?? {}

      LoggerHelpersService.handleInternalLogError({
        componentInfo: certificateReducerComponentInfo,
      })(error)

      const internalServerError = handleInternalServerError(error)

      if (internalServerError) {
        return {
          ...state,
          error: internalServerError,
        }
      }

      const unauthorizedError = handleUnauthorizedError(error)

      if (unauthorizedError) {
        return {
          ...state,
          error: unauthorizedError,
        }
      }

      const forbiddenError = handleForbiddenAuthError(error)

      if (forbiddenError) {
        return {
          ...state,
          error: forbiddenError,
        }
      }

      const badRequestError = handleBadRequestAuthError(error)

      if (badRequestError) {
        return {
          ...state,
          error: badRequestError,
        }
      }

      return {
        ...state,
        error: {
          header: ErrorHeadersTexts.accessIdDenied,
          body: errorDescription || errorDetail || defaultMessageSupportWithLink,
        },
      }

    default:
      return state
  }
}

const certificateActionCreators: CertificateActionCreators = {
  setCryptoPluginError: () => ({ type: certificateReducerActionMap.SETUP_CRYPTO_PLUGIN_ERROR }),
  setCertificateMissingError: () => ({
    type: certificateReducerActionMap.SETUP_CERTIFICATE_MISSING_ERROR,
  }),
  setLoading: (payload) => ({ type: certificateReducerActionMap.SETUP_LOADING, payload }),
  setCaption: (payload) => ({ type: certificateReducerActionMap.SETUP_CAPTION, payload }),
  setCertificates: (payload) => ({ type: certificateReducerActionMap.SETUP_CERTIFICATES, payload }),
  setError: (payload) => ({ type: certificateReducerActionMap.SETUP_ERROR, payload }),
  setNativeError: (payload) => ({ type: certificateReducerActionMap.SETUP_NATIVE_ERROR, payload }),
}

export { certificateActionCreators, certificateReducer, initialCertificateState }
