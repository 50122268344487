import React, {
  createContext,
  Dispatch,
  FC,
  memo,
  ReactElement,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

import { useHeaderCollapse } from '@layouts/Header/Portal/store'

interface HeaderCollapseContextProps {
  isCollapsed: boolean
  bindHeadlineRef?: (ref: HTMLDivElement | null) => void
  handleCollapse?: Dispatch<SetStateAction<boolean>>
}

interface HeaderCollapseProps {
  children: ReactElement
}

const HeaderCollapseContext = createContext<HeaderCollapseContextProps>({
  isCollapsed: false,
})

const useHeaderCollapseManager = () => useContext(HeaderCollapseContext)

const HeaderCollapseManager: FC<HeaderCollapseProps> = ({ children }) => {
  const [headlineRef, setHeadlineRef] = useState<HTMLDivElement | null>(null)

  const { collapsedPageMode, handleCollapsedMode } = useHeaderCollapse(headlineRef, {
    threshold: 0.5,
  })

  const bindHeadlineRef = (ref: HTMLDivElement | null) => {
    if (ref) setHeadlineRef(ref)
  }

  const contextValue = useMemo(
    () => ({
      isCollapsed: collapsedPageMode,
      handleCollapse: handleCollapsedMode,
      bindHeadlineRef,
    }),
    [collapsedPageMode, handleCollapsedMode],
  )

  return (
    <HeaderCollapseContext.Provider value={contextValue}>{children}</HeaderCollapseContext.Provider>
  )
}
export { useHeaderCollapseManager }

export default memo(HeaderCollapseManager)
