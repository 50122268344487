import React, { memo } from 'react'

import DocumentsSet from '@components/DocumentsSet'
import { useCreateSubsidyModal } from '@components/NewDesignedModals/CreateSubsidyModal'
import { RolesTypes } from '@constants/types'
import withRolesRender from '@hoc/withRolesRender'

import styles from './SubsidyAddon.module.scss'

const SubsidyAddon = ({ projectId }) => {
  const { handleOpenCreateSubsidy } = useCreateSubsidyModal(projectId)

  return (
    <DocumentsSet.ItemCreate
      className={styles.button}
      title={'Новое заявление'}
      dataTestId="DocumentSetItemCreate-addSubsidyDfo-button"
      positionOfIcon={'left'}
      onClick={handleOpenCreateSubsidy}
    />
  )
}

export default withRolesRender(memo(SubsidyAddon), [RolesTypes.INVESTOR])
