import React, { FC } from 'react'

import {
  GroupOfOptions,
  OptionProps,
  OptionsVariant,
  OptionsView,
} from '@components/NewDesign/Select/model'
import Options from '@components/NewDesign/Select/OptionsNew'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './OptionGroupItem.module.scss'

const typographyVariantsMap = {
  primary: 'captionAllcaps',
  secondary: 'captionSRegular',
} as const

const typographyColorsMap = {
  primary: 'text-base-tertiary',
  secondary: 'text-base-secondary',
} as const

interface OptionsGroupItemProps {
  option: GroupOfOptions
  onClick: (value: OptionProps) => void
  variant?: OptionsVariant
  view?: OptionsView
  selected: OptionProps | OptionProps[] | null
  optionClassName?: string
}

const OptionGroupItem: FC<OptionsGroupItemProps> = ({
  variant = 'primary',
  option,
  onClick,
  optionClassName,
  selected,
}) => {
  return (
    <div
      className={cn(styles.optionGroupItem, {
        [styles[`optionGroupItem--${variant}`]]: variant,
      })}
    >
      <Typography.Caption
        variant={typographyVariantsMap[variant]}
        className={styles.optionGroupItem__title}
        color={typographyColorsMap[variant]}
      >
        {option.title}
      </Typography.Caption>
      {option.options.map((optionInner, index) => {
        return (
          <Options.Item
            {...optionInner}
            key={index}
            selected={selected}
            optionClassName={optionClassName}
            variant={variant}
            onClick={onClick}
          >
            {optionInner.displayValue}
          </Options.Item>
        )
      })}
    </div>
  )
}

export default OptionGroupItem
