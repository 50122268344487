import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'

import { mapOfFinancialModelMenu } from '../const'
import FinancialModelForms from '../Forms'
import { useFinancialModelManager } from '../Manager'

import styles from './FinancialModelForm.module.scss'

const mapOfFinancialModelForms: FormItemProps = {
  '1': {
    node: <FinancialModelForms.One />,
    overrideBlockProps: {
      className: styles.overrideBlock,
    },
  },
  '2': {
    node: <FinancialModelForms.Two />,
    overrideBlockProps: {
      className: styles.overrideBlock,
    },
  },
  '3': {
    node: <FinancialModelForms.Three />,
    overrideBlockProps: {
      className: styles.overrideBlock,
    },
  },
  '4': {
    node: <FinancialModelForms.Four />,
    overrideBlockProps: {
      className: styles.overrideBlock,
    },
  },
} as const

const FinancialModelForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<FinancialModelFormValues>()

  const {
    state: { formIsLoading },
  } = useFinancialModelManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfFinancialModelMenu}
      mapOfForms={mapOfFinancialModelForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(FinancialModelForm)
