import React, { FC } from 'react'

import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import cn from 'classnames'

import styles from './FormError.module.scss'

interface FormErrorProps {
  disablePaddingX?: boolean
  className?: string
  rowClassName?: string
  colClassName?: string
}

const FormError: FC<FormErrorProps> = ({
  disablePaddingX = false,
  className,
  rowClassName,
  colClassName,
  children,
}) => {
  return (
    <Row className={rowClassName}>
      <Col xs={12} className={colClassName}>
        <Typography.Caption
          variant="captionSRegular"
          className={cn(
            styles.formError,
            {
              [styles['formError--disablePaddingX']]: disablePaddingX,
            },
            className,
          )}
        >
          {children}
        </Typography.Caption>
      </Col>
    </Row>
  )
}

export default FormError
