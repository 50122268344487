import React from 'react'
import { Path, useFormContext } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import NpaFormAddon from '@components/DocumentFormComponents/NpaFormAddon'
import { npaSolicitationInclusionFormBlockValues } from '@components/Forms/NpaSolicitationInclusionForm/const'
import { useNpaSolicitationInclusionFormManager } from '@components/Forms/NpaSolicitationInclusionForm/Manager'
import { NpaSolicitationInclusionFormValues } from '@components/Forms/NpaSolicitationInclusionForm/types'
import { useRegistryNpaChooseModal } from '@components/RegistryNpa/Choose'
import { isArray, isNotEmptyString, isUndefined } from '@helpers/checkTypes'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { PartCustomPropsWithId } from '@services/NPA/NPA.entity'
import NPAHelpers from '@services/NPA/NPA.helpers'
import NPAService from '@services/NPA/NPA.service'

const { transformRHFPathInProperties } = DocumentFormHelpers
const { fromLayoutInterfaceToModalChosen } = NPAService
const { getNpaPropertyPrefixByDocumentType } = NPAHelpers

const NPA = () => {
  const formInstance = useFormContext<NpaSolicitationInclusionFormValues>()

  const {
    state: { documentType, blockViewIsValidating, regionsToNpaModal },
    handlers: {
      handleChangeCustomValue,
      handleAddMultipleItemsToListWithValue,
      handleRemoveCustomValue,
      getPropertiesProps,
      handleRemoveNpaForm,
    },
  } = useNpaSolicitationInclusionFormManager()

  const { handleOpenRegistryNpaChooseModal } = useRegistryNpaChooseModal()

  const getPropertyPropsByPathName = (
    pathName: Path<NpaSolicitationInclusionFormValues>,
  ): Record<string, any> | undefined => {
    const propertyPropsPath = transformRHFPathInProperties(pathName)

    return getObjectValue(getPropertiesProps?.(), propertyPropsPath)
  }

  const handleAddItemInList = async (chosenParts: unknown[]) => {
    await handleAddMultipleItemsToListWithValue?.(
      npaSolicitationInclusionFormBlockValues.npa.chosenParts,
      chosenParts,
    )
  }

  const handleOpenNpaModal = () => {
    const npaStructure = formInstance.getValues(
      npaSolicitationInclusionFormBlockValues.npa.npaStructure,
    )

    const isFederal = formInstance.getValues(
      npaSolicitationInclusionFormBlockValues.additionalFields.isFederal,
    )

    const chosenPartsForModal = fromLayoutInterfaceToModalChosen(npaStructure)

    const excludedPartIds = chosenPartsForModal?.map((part) => part.partId) || []

    handleOpenRegistryNpaChooseModal({
      documentType,
      chosenParts: chosenPartsForModal,
      excludedPartIds,
      onAddItemToList: handleAddItemInList,
      initialFilters: {
        isFederal,
        isRegional: true,
        isMunicipal: true,
        region:
          !isUndefined(regionsToNpaModal) && regionsToNpaModal?.length
            ? regionsToNpaModal
            : undefined,
      },
    })
  }

  const handleUpdateNpaPart = async ({
    id,
    justification,
    partCorrection,
    versionId,
  }: PartCustomPropsWithId) => {
    const propertiesPropsOfChosenPartsValue = getPropertyPropsByPathName(
      npaSolicitationInclusionFormBlockValues.npa.chosenParts,
    )?.value?.npaParts

    if (
      !propertiesPropsOfChosenPartsValue ||
      !isArray(propertiesPropsOfChosenPartsValue) ||
      !propertiesPropsOfChosenPartsValue.length
    )
      return

    const currentNpaPartPropertyProps = propertiesPropsOfChosenPartsValue.find(
      (property) => property?.propertyId === id,
    )

    if (!currentNpaPartPropertyProps) return

    const npaType = getNpaPropertyPrefixByDocumentType(documentType)

    await handleChangeCustomValue?.({
      property: {
        id: currentNpaPartPropertyProps.propertyId,
        lastUpdateDt: currentNpaPartPropertyProps.lastUpdateDt,
      },
      type: currentNpaPartPropertyProps.type,
      newValue: {
        [`${npaType}PartId`]: {
          id: versionId,
        },
        [`${npaType}Justification`]: justification,
        [`${npaType}Correction`]: isNotEmptyString(partCorrection) ? partCorrection : null,
      },
    })
  }

  const handleRemoveNpaPart = async (propertyPartId: string) => {
    const propertyPropsOfChosenParts = getPropertyPropsByPathName(
      npaSolicitationInclusionFormBlockValues.npa.chosenParts,
    )

    const propertiesPropsOfChosenPartsValue = propertyPropsOfChosenParts?.value?.npaParts

    if (
      !propertiesPropsOfChosenPartsValue ||
      !isArray(propertiesPropsOfChosenPartsValue) ||
      !propertiesPropsOfChosenPartsValue.length
    )
      return

    const propertyParentToRemove = {
      id: propertyPropsOfChosenParts.propertyId,
      lastUpdateDt: propertyPropsOfChosenParts.lastUpdateDt,
    }

    await handleRemoveCustomValue?.(propertyParentToRemove, propertyPartId)
  }

  if (!formInstance) return null

  return (
    <NpaFormAddon
      blockViewIsValidating={blockViewIsValidating}
      formInstance={formInstance}
      onOpenRegistryNpaChooseModal={handleOpenNpaModal}
      onUpdateNpaPart={handleUpdateNpaPart}
      onRemoveNpaPart={handleRemoveNpaPart}
      onRemove={handleRemoveNpaForm}
    />
  )
}

export default NPA
