import { CollapseHeightProps } from '@components/Collapse/index'

const hideContent = (element: HTMLDivElement | null, height: CollapseHeightProps) => {
  if (height === 0 && element?.style) {
    element.style.display = 'none'
  }
}

const showContent = (element: HTMLDivElement | null, height: CollapseHeightProps) => {
  if (height === 0 && element?.style) {
    element.style.display = ''
  }
}

export { hideContent, showContent }
