import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useNotificationOfIdentifiedNPAListErrorsManager } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/Manager'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/types'

import NotificationOfIdentifiedNPAListErrorsForms from '../Forms'
import { mapOfNotificationOfIdentifiedNPAListErrorsMenu } from '../Menu/const'

const mapOfNotificationOfIdentifiedNPAListErrorsForms: FormItemProps = {
  '1': {
    node: <NotificationOfIdentifiedNPAListErrorsForms.First />,
  },
  '2': {
    node: <NotificationOfIdentifiedNPAListErrorsForms.Second />,
  },
  '3': {
    node: <NotificationOfIdentifiedNPAListErrorsForms.Third />,
  },
  '4': {
    node: <NotificationOfIdentifiedNPAListErrorsForms.Fourth />,
  },
} as const

const NotificationOfIdentifiedNPAListErrorsForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<NotificationOfIdentifiedNPAListErrorsFormValues>()

  const {
    state: { formIsLoading },
  } = useNotificationOfIdentifiedNPAListErrorsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNotificationOfIdentifiedNPAListErrorsMenu}
      mapOfForms={mapOfNotificationOfIdentifiedNPAListErrorsForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NotificationOfIdentifiedNPAListErrorsForm)
