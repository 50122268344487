import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { fifteenthSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/15/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fifteenth = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const isFederalSZPK = useWatch({
    name: 'additionalFields.isFederal',
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункт Правил, на основании которого уполномоченный орган осуществляет мониторинг исполнения условий СЗПК'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: projectSZPKBlockValues['15'].ruleLinkMonitoring,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: projectSZPKBlockValues['15'].ruleLinkMonitoring,
                    rules: fifteenthSectionValidationMap.ruleLinkMonitoring,
                    inputProps: {
                      caption: 'рекомендация: 6',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(projectSZPKBlockValues['15'].ruleLinkMonitoring)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          projectSZPKBlockValues['15'].ruleLinkMonitoring,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        {!isFederalSZPK && (
          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableBottomDefaultStyles
                title={
                  'Указываются акты органов государственной власти субъектов РФ, принятые в соответствии с пунктом 2 части 7 статьи 4 ФЗ'
                }
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['15'].subjectNpaReasonMonitoring,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: projectSZPKBlockValues['15'].subjectNpaReasonMonitoring,
                      rules: fifteenthSectionValidationMap.subjectNpaReasonMonitoring,
                      inputProps: {
                        caption: 'заполняется только для региональных СЗПК',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            projectSZPKBlockValues['15'].subjectNpaReasonMonitoring,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            projectSZPKBlockValues['15'].subjectNpaReasonMonitoring,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Fifteenth
