import { generatePropertiesObjectForAssignmentMonetaryOrPledgeAForm } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAssignmentMonetaryOrPledgeAForm } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/adapters/RHF.adapter'
import { AssignmentMonetaryOrPledgeAFormValues } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/types'

const useAssignmentMonetaryOrPledgeAAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): AssignmentMonetaryOrPledgeAFormValues =>
    generateRHFObjectForAssignmentMonetaryOrPledgeAForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForAssignmentMonetaryOrPledgeAForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useAssignmentMonetaryOrPledgeAAdapters }
