import React, { memo, useCallback, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Loader from '@components/Loader'
import { getFullClassification } from '@components/NewDesign/InputSearchTree/InputSearchTree'
import Typography from '@components/NewDesign/Typography'
import { chooseNpaBlockValues } from '@components/RegistryNpa/Choose/const'
import NpaModalChosenThree from '@components/RegistryNpa/Choose/Grid/ChosenThree'
import NpaModalDefaultThree from '@components/RegistryNpa/Choose/Grid/DefaultThree'
import { useChooseNpaManager } from '@components/RegistryNpa/Choose/Manager'
import { ChooseNpaFormValues } from '@components/RegistryNpa/Choose/types'
import styles from '@components/RegistryNpa/Grid/Grid.module.scss'
import env from '@config'
import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import { INpaItem } from '@services/NPA/NPA.entity'
import NPAService from '@services/NPA/NPA.service'

const { buildThreeFromChosenNew } = NPAService

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

const NpaModalGrid = () => {
  const formInstance = useFormContext<ChooseNpaFormValues>()

  const {
    state: { filtersInstance, threeErrors, gridSwrInstance, debouncedSearchValue, viewMode },
  } = useChooseNpaManager()

  const mainDirection = useWatch({
    control: filtersInstance?.control,
    name: 'filters.direction',
  })

  const threeToRender = useWatch({
    control: formInstance.control,
    name: chooseNpaBlockValues.threeToRender,
  })

  const { mapOfNpaDirections } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const { partClassificationInObjectMap, partClassifications } = useNpaPartClassification({
    key: {
      _key: 'partClassification',
    },
  })

  const [searchPlaceholderCondition, setSearchPlaceholderCondition] = useState<boolean>(false)

  const handleBuildChosenThree = useCallback(() => {
    const { normalizedData, chosenParts, ...restFormValues } = formInstance.getValues()

    const data = buildThreeFromChosenNew({
      chosenParts,
      npas: normalizedData.npas,
      parts: normalizedData.parts,
      directions: normalizedData.directions,
      mapOfClassificationPart: partClassificationInObjectMap,
      threeErrors,
    })

    formInstance.reset({
      ...restFormValues,
      normalizedData,
      chosenParts,
      chosenThree: data,
    })
  }, [formInstance, partClassificationInObjectMap, threeErrors])

  const classificationHeader = useCallback(
    (part: INpaItem) =>
      getFullClassification({
        partClassifications: partClassifications ?? [],
        value: part.classificationId,
      }),
    [partClassifications],
  )

  useEffectAfterMount(() => {
    setSearchPlaceholderCondition(false)

    if (debouncedSearchValue.length && !threeToRender?.length) setSearchPlaceholderCondition(true)
  }, [debouncedSearchValue, threeToRender])

  // Создается новое дерево с выбранными НПА при смене вкладки на "Выбрано".
  useEffectAfterMount(() => {
    if (viewMode === 'default') return

    handleBuildChosenThree()
  }, [viewMode])

  const noDataCondition = useMemo(() => {
    return (
      !gridSwrInstance?.error &&
      !gridSwrInstance?.isNpaListLoading &&
      !threeToRender?.length &&
      !debouncedSearchValue.length
    )
  }, [
    debouncedSearchValue.length,
    gridSwrInstance?.error,
    gridSwrInstance?.isNpaListLoading,
    threeToRender?.length,
  ])

  return (
    <div className={styles.grid__choose}>
      <Loader loading={gridSwrInstance?.isNpaListLoading} dataTestId="NpaModalGrid-gridLoader">
        {noDataCondition && <NoDataPlaceholder className={styles.placeholder} />}
        {searchPlaceholderCondition && !gridSwrInstance?.isNpaListLoading && (
          <SearchPlaceholder
            className={styles.placeholder}
            contentClassName={styles.placeholder__content}
            bottomRender={
              <>
                Проверьте фильтры и то, что все слова написаны без ошибок. <br />
                Возможно, положение акта, которое вы ищете, ещё не добавлено. <br />
                Отправьте информацию о стабилизируемом положении на почту{' '}
                <Typography.Body as={'span'} variant={'bodyLMedium'} color={'text-accent-brand'}>
                  <a href={`mailto:${ECONOMY_SUPPORT_EMAIL}`}>{ECONOMY_SUPPORT_EMAIL}</a>{' '}
                </Typography.Body>
              </>
            }
          >
            {debouncedSearchValue}
          </SearchPlaceholder>
        )}
        {(() => {
          switch (viewMode) {
            case 'chosen':
              return (
                <NpaModalChosenThree
                  mapOfNpaDirections={mapOfNpaDirections}
                  classificationHeader={classificationHeader}
                  formInstance={formInstance}
                />
              )

            default:
              return (
                <NpaModalDefaultThree
                  mapOfNpaDirections={mapOfNpaDirections}
                  classificationHeader={classificationHeader}
                  formInstance={formInstance}
                  mainDirection={mainDirection}
                />
              )
          }
        })()}
      </Loader>
    </div>
  )
}

export default memo(NpaModalGrid)
