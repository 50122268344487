import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NoticeOneSideRejectSZPKFormValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/types'

const generateRHFObjectForNoticeOneSideRejectSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): NoticeOneSideRejectSZPKFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      ruleLinkOneSideRejectReason: objectFromPropertyState.ruleLinkOneSideRejectReason?.value || '',
      oneSideRejectReasonProof: objectFromPropertyState.oneSideRejectReasonProof?.value || '',
    },
  } as NoticeOneSideRejectSZPKFormValues
}

export { generateRHFObjectForNoticeOneSideRejectSZPKForm }
