const PAGE_SIZE_OIV = 15
const PAGE_SIZE_OMSU = 15

const defaultFiltersValue = Object.freeze({
  size: PAGE_SIZE_OMSU,
  region: '',
  searchString: '',
})

export { defaultFiltersValue, PAGE_SIZE_OIV, PAGE_SIZE_OMSU }
