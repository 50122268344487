import { DefaultValues, FieldValues, useForm, UseFormProps } from 'react-hook-form'

interface UseFormWithDefaultProps<T extends FieldValues> extends UseFormProps<T> {
  defaultValues: DefaultValues<T>
}

const useFormWithDefaultProps = <T extends FieldValues>({
  mode = 'onBlur',
  reValidateMode = 'onChange',
  ...restProps
}: UseFormWithDefaultProps<T>) => {
  return useForm<T>({
    mode,
    reValidateMode,
    ...restProps,
  })
}

export { useFormWithDefaultProps }
