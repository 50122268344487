import { FC, memo } from 'react'
import { PopupProps } from 'react-popup-manager'
import { NavLink } from 'react-router-dom'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { documentsLinks } from '@constants/documents'
import WarnIcon from '@icons/alert/warning_amber.svg'

import styles from './WarnSignSZPKModal.module.scss'

const IconProp = {
  src: WarnIcon,
  className: styles.icon,
}

export type TWarnModalProps = Required<PopupProps>

const WarnSignSZPKModal: FC<TWarnModalProps> = ({ isOpen, onClose }) => {
  const doneActions = [
    {
      children: 'Продолжить',
      dataTestId: 'WarnSignSZPKModal-signSZPK-button',
      fixWidth: true,
      onClick: onClose,
      className: styles.warn,
    },
  ] as ButtonProps[]

  return (
    <Modal.Status
      actions={doneActions}
      isOpen={isOpen}
      title={'Вы не можете подписывать СЗПК'}
      dataTestId="WarnSignSZPKModal-modal"
      containerClassName={styles.modalWrapper}
      icon={IconProp}
      onClose={onClose}
    >
      <div className={styles.body}>
        <Typography.Body variant="bodyMRegular" className={styles.bodyItem}>
          <NavLink to={documentsLinks.DECREE_N1602} target="_blank" className={styles.bodyLink}>
            Согласно п.4 Правил заключения СЗПК,
          </NavLink>
          чтобы подписать соглашение о защите и поощрении капиталовложений, используйте УКЭП лица,
          имеющего право действовать от имени заявителя без доверенности
        </Typography.Body>
        <Typography.Body variant="bodyMRegular" className={styles.bodyItem}>
          Все остальные документы вы можете подписывать
        </Typography.Body>
      </div>
    </Modal.Status>
  )
}

export default memo(WarnSignSZPKModal)
