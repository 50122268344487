import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  AAgreementForceCircumstancesFormValues,
  IAAgreementForceCircumstancesStage,
} from '@components/Forms/AAgreementForceCircumstancesForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForAAgreementForceCircumstancesForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementForceCircumstancesFormValues => {
  const preparedStages = (() => {
    if (
      !isArray(objectFromPropertyState.steps?.value) ||
      !objectFromPropertyState.steps.value.length
    )
      return []

    return objectFromPropertyState.steps.value.map(
      (stage): IAAgreementForceCircumstancesStage => ({
        id: stage.projectId,
        stepNumber: stage.value?.stepNumber?.value || '',
        stepDescription: stage.value?.stepDescription?.value || '',
        stepEndDateCommited: stage.value?.stepEndDate?.commitedValue || '',
        stepEndDate: stage.value?.stepEndDate?.value || '',
      }),
    )
  })()

  return {
    '1': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '5': {
      stages: preparedStages,
    },
    '6': {
      projectEndDateCommited: objectFromPropertyState?.projectEndDate?.commitedValue || '',
      projectEndDate: objectFromPropertyState?.projectEndDate?.value || '',
      agreementLinkProjectPeriod: objectFromPropertyState?.agreementLinkProjectPeriod?.value || '',
    },
    '7': {
      forceMajeureName: objectFromPropertyState?.forceMajeureName?.value || '',
      forceMajeureProof: objectFromPropertyState?.forceMajeureProof?.value || '',
    },
    additionalFields: {
      isFederal: objectFromPropertyState?.isFederal?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  }
}

export { generateRHFObjectForAAgreementForceCircumstancesForm }
