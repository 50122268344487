import {
  FormFieldStates,
  FormListStates,
  FormObjectStates,
  FormObjectStatesWithChanges,
} from '@components/DocumentFormComponents/types'
import { isEmptyObject, isNull, isObject, isUndefined } from '@helpers/checkTypes'
import { ValueOf } from '@helpers/ValueOf'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

type GetModifierStatesFromPropertyStateReturnType<T extends ValueOf<typeof PropertyTypeEnum>> =
  T extends typeof PropertyTypeEnum.LIST
    ? FormListStates
    : T extends typeof PropertyTypeEnum.MAP
    ? FormObjectStates
    : FormFieldStates

class FormModifierAdapters {
  static getModifierStatesFormPropertyState<
    T extends ValueOf<typeof PropertyTypeEnum> = typeof PropertyTypeEnum.STRING,
  >(propertyState: Record<string, any>): GetModifierStatesFromPropertyStateReturnType<T> {
    if (propertyState?.type === PropertyTypeEnum.LIST) {
      return {
        id: propertyState?.propertyId,
        deletedElementFromList: !!propertyState?.deletedElementFromList,
        addedElementInList: !!propertyState?.value?.some((item) => !!item?.new),
      } as GetModifierStatesFromPropertyStateReturnType<T>
    }

    if (propertyState?.type === PropertyTypeEnum.MAP) {
      return {
        id: propertyState?.propertyId,
        isNew: !!propertyState?.new,
      } as GetModifierStatesFromPropertyStateReturnType<T>
    }

    return {
      id: propertyState?.propertyId,
      isPublic: !!propertyState?.isPublic,
    } as GetModifierStatesFromPropertyStateReturnType<T>
  }

  static getInvestorModifierAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInvestorHeaderInfo = (() => {
      const defaultInvestorHeaderValue = {
        investorHeaderName: {
          id: '1',
          isPublic: false,
        },
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultInvestorHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.investor?.permanentId
        )
      })

      if (!headerInfo) return defaultInvestorHeaderValue

      return {
        investorHeaderName: FormModifierAdapters.getModifierStatesFormPropertyState(
          headerInfo.value.signer,
        ),
      }
    })()

    return {
      investorFullName: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberFullName,
      },
      investorName: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberName,
      },
      investorFullNameGenitive: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.investor?.value.investorFullNameGenitive,
      ),
      investorFullNameInstrumental: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.investor?.value?.investorFullNameInstrumental,
      ),
      investorInn: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic?.memberInn,
      },
      investorOgrn: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOgrn,
      },
      investorKpp: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic?.memberKpp,
      },
      investorOktmo: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOktmo,
      },
      investorOkpo: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOkpo,
      },
      investorCapital: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberCapital,
      },
      investorOkved: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOkved,
      },
      investorAddressFullEgrulFormat: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.addressFullEgrulFormat,
      },
      investorEmail: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberEmail,
      },
      investorPhone: {
        id: objectFromPropertyState.investor?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.isPublic
            ?.memberPhone,
      },
      ...preparedInvestorHeaderInfo,
    }
  }

  static getRFModifierAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedRfHeaderInfo = (() => {
      const defaultRfHeaderValue = {
        rfHeaderName: {
          id: '1',
          isPublic: false,
        },
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultRfHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id === objectFromPropertyState.rf?.permanentId
        )
      })

      if (!headerInfo) return defaultRfHeaderValue

      return {
        rfHeaderName: FormModifierAdapters.getModifierStatesFormPropertyState(
          headerInfo.value.signer,
        ),
      }
    })()

    return {
      rfFullName: {
        id: objectFromPropertyState.rf?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.isPublic?.memberFullName,
      },
      rfName: {
        id: objectFromPropertyState.rf?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.isPublic?.memberName,
      },
      rfInn: {
        id: objectFromPropertyState.rf?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.isPublic?.memberInn,
      },
      rfKpp: {
        id: objectFromPropertyState.rf?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.isPublic?.memberKpp,
      },
      rfOgrn: {
        id: objectFromPropertyState.rf?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.isPublic?.memberOgrn,
      },
      rfOktmo: {
        id: objectFromPropertyState.rf?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.isPublic?.memberOktmo,
      },
      rfPaymentDetails: {
        id: objectFromPropertyState.rf?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.isPublic
            ?.memberPaymentDetails,
      },
      ...preparedRfHeaderInfo,
    }
  }

  static getMunicipalitiesModifierAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedMunicipalities = (() => {
      const defaultMunicipalityValue = [
        {
          municipalityFullName: {
            id: '1',
            isPublic: false,
          },
        },
      ]

      if (
        !objectFromPropertyState.municipalities ||
        !objectFromPropertyState.municipalities?.value.length
      )
        return defaultMunicipalityValue

      const preparedForDocSigners = objectFromPropertyState.municipalities?.value.map(
        (municipality) => ({
          id: municipality.permanentId,
          municipalityFullName: {
            id: municipality.value?.memberOrganizationId?.propertyId,
            isPublic: municipality.value?.memberOrganizationId?.value?.isPublic?.memberFullName,
          },
        }),
      )

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return preparedForDocSigners

      const preparedMunicipalities = preparedForDocSigners.map((municipalityWithoutHeaderInfo) => {
        const headerInfo = docSigners.find((docSigner) => {
          return docSigner.value?.signerOrganization?.value.id === municipalityWithoutHeaderInfo.id
        })

        if (!headerInfo) return municipalityWithoutHeaderInfo

        return {
          ...municipalityWithoutHeaderInfo,
          //Для селекта используется id в качестве значения
          municipalityHeaderName: FormModifierAdapters.getModifierStatesFormPropertyState(
            headerInfo.value.signer,
          ),
        }
      })

      return preparedMunicipalities
    })()

    return preparedMunicipalities
  }

  static getNewMunicipalityModifierAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedNewMunicipalityHeaderInfo = (() => {
      const defaultNewMunicipalityHeaderValue = {
        municipalityHeaderName: {
          id: '1',
          isPublic: false,
        },
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultNewMunicipalityHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.newMunicipality?.permanentId
        )
      })

      if (!headerInfo) return defaultNewMunicipalityHeaderValue

      return {
        municipalityHeaderName: FormModifierAdapters.getModifierStatesFormPropertyState(
          headerInfo.value.signer,
        ),
      }
    })()

    return {
      municipalityFullName: {
        id: objectFromPropertyState.newMunicipality?.value?.memberOrganizationId?.propertyId,
        isPublic:
          objectFromPropertyState.newMunicipality?.value?.memberOrganizationId?.value?.isPublic
            ?.memberFullName,
      },
      municipalityFullNameGenitive: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.newMunicipality?.value?.memberFullNameGenitive,
      ),
      municipalityFullNameInstrumental: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.newMunicipality?.value?.memberFullNameInstrumental,
      ),
      ...preparedNewMunicipalityHeaderInfo,
    }
  }

  static getSubjectsModifierAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedSubjects = (() => {
      const defaultSubjectValue = [
        {
          subjectFullName: '',
          subjectRegion: '',
          subjectAddress: '',
          subjectInn: '',
          subjectKpp: '',
          subjectOgrn: '',
          subjectOktmo: '',
          subjectOtherDetails: '',
          subjectPaymentDetails: '',
          subjectHeaderName: null,
          subjectHeaderNameGenitive: '',
          subjectHeaderPosition: '',
          subjectHeaderPositionGenitive: '',
          subjectHeaderReasonGenitive: '',
        },
      ]

      if (!objectFromPropertyState.subjects || !objectFromPropertyState.subjects?.value.length)
        return defaultSubjectValue

      const preparedForDocSigners = objectFromPropertyState.subjects?.value.map((subject) => ({
        id: subject.permanentId,
        subjectFullName: {
          id: subject.value?.memberOrganizationId?.propertyId,
          isPublic: subject.value?.memberOrganizationId?.value?.isPublic?.memberFullName,
        },
        subjectRegion: {
          id: subject.value?.memberOrganizationId?.propertyId,
          isPublic: subject.value?.memberOrganizationId?.value?.isPublic?.region,
        },
        subjectInn: {
          id: subject.value?.memberOrganizationId?.propertyId,
          isPublic: subject.value?.memberOrganizationId?.value?.isPublic?.memberInn,
        },
        subjectKpp: {
          id: subject.value?.memberOrganizationId?.propertyId,
          isPublic: subject.value?.memberOrganizationId?.value?.isPublic?.memberKpp,
        },
        subjectOgrn: {
          id: subject.value?.memberOrganizationId?.propertyId,
          isPublic: subject.value?.memberOrganizationId?.value?.isPublic?.memberOgrn,
        },
        subjectOktmo: {
          id: subject.value?.memberOrganizationId?.propertyId,
          isPublic: subject.value?.memberOrganizationId?.value?.isPublic?.memberOktmo,
        },
        subjectPaymentDetails: {
          id: subject.value?.memberOrganizationId?.propertyId,
          isPublic: subject.value?.memberOrganizationId?.value?.isPublic?.memberPaymentDetails,
        },
        subjectHeaderName: {
          id: '1',
          isPublic: false,
        },
      }))

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return preparedForDocSigners

      const preparedSubjects = preparedForDocSigners.map((subjectWithoutHeaderInfo) => {
        const headerInfo = docSigners.find((docSigner) => {
          return docSigner.value?.signerOrganization?.value.id === subjectWithoutHeaderInfo.id
        })

        if (!headerInfo) return subjectWithoutHeaderInfo

        return {
          ...subjectWithoutHeaderInfo,
          //Для селекта используется id в качестве значения
          subjectHeaderName: FormModifierAdapters.getModifierStatesFormPropertyState(
            headerInfo.value.signer,
          ),
        }
      })

      return preparedSubjects
    })()

    return preparedSubjects
  }

  static getUOModifierAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedUoHeaderInfo = (() => {
      const defaultUoHeaderValue = {
        uoHeaderName: {
          id: '1',
          isPublic: false,
        },
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultUoHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id === objectFromPropertyState.uo?.permanentId
        )
      })

      if (!headerInfo) return defaultUoHeaderValue

      return {
        uoHeaderName: FormModifierAdapters.getModifierStatesFormPropertyState(
          headerInfo.value.signer,
        ),
      }
    })()

    return {
      uoFullName: {
        id: objectFromPropertyState.uo?.permanentId,
        isPublic:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.isPublic?.memberFullName,
      },
      uoName: {
        id: objectFromPropertyState.uo?.permanentId,
        isPublic:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.isPublic?.memberName,
      },
      uoInn: {
        id: objectFromPropertyState.uo?.permanentId,
        isPublic:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.isPublic?.memberInn,
      },
      uoKpp: {
        id: objectFromPropertyState.uo?.permanentId,
        isPublic:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.isPublic?.memberKpp,
      },
      uoOgrn: {
        id: objectFromPropertyState.uo?.permanentId,
        isPublic:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.isPublic?.memberOgrn,
      },
      uoOktmo: {
        id: objectFromPropertyState.uo?.permanentId,
        isPublic:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.isPublic?.memberOktmo,
      },
      uoPaymentDetails: {
        id: objectFromPropertyState.uo?.permanentId,
        isPublic:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.isPublic
            ?.memberPaymentDetails,
      },
      ...preparedUoHeaderInfo,
    }
  }

  static getInvestorPartnerModifierAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInvestorPartnerHeaderInfo = (() => {
      const defaultInvestorPartnerHeaderValue = {
        investorPartnerHeaderName: {
          id: '1',
          isPublic: false,
        },
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultInvestorPartnerHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.investorPartner?.permanentId
        )
      })

      if (!headerInfo) return defaultInvestorPartnerHeaderValue

      return {
        investorPartnerHeaderName: FormModifierAdapters.getModifierStatesFormPropertyState(
          headerInfo.value.signer,
        ),
      }
    })()

    return {
      investorPartnerFullName: {
        id: objectFromPropertyState.investorPartner?.permanentId,
        isPublic:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.isPublic
            ?.memberFullName,
      },
      investorPartnerName: {
        id: objectFromPropertyState.investorPartner?.permanentId,
        isPublic:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.isPublic
            ?.memberName,
      },
      investorPartnerInn: {
        id: objectFromPropertyState.investorPartner?.permanentId,
        isPublic:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.isPublic
            ?.memberInn,
      },
      investorPartnerKpp: {
        id: objectFromPropertyState.investorPartner?.permanentId,
        isPublic:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.isPublic
            ?.memberKpp,
      },
      investorPartnerOgrn: {
        id: objectFromPropertyState.investorPartner?.permanentId,
        isPublic:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOgrn,
      },
      investorPartnerOktmo: {
        id: objectFromPropertyState.investorPartner?.permanentId,
        isPublic:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOktmo,
      },
      investorPartnerPaymentDetails: {
        id: objectFromPropertyState.investorPartner?.permanentId,
        isPublic:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.isPublic
            ?.memberPaymentDetails,
      },
      ...preparedInvestorPartnerHeaderInfo,
    }
  }

  static getInitiatorModifierAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInitiatorHeaderInfo = (() => {
      const defaultInitiatorHeaderValue = {
        initiatorHeaderName: {
          id: '1',
          isPublic: false,
        },
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultInitiatorHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.initiator?.permanentId
        )
      })

      if (!headerInfo) return defaultInitiatorHeaderValue

      return {
        initiatorHeaderName: FormModifierAdapters.getModifierStatesFormPropertyState(
          headerInfo.value.signer,
        ),
      }
    })()

    return {
      initiatorFullName: {
        id: objectFromPropertyState.initiator?.permanentId,
        isPublic:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.isPublic
            ?.memberFullName,
      },
      initiatorInn: {
        id: objectFromPropertyState.initiator?.permanentId,
        isPublic:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.isPublic
            ?.memberInn,
      },
      initiatorOgrn: {
        id: objectFromPropertyState.initiator?.permanentId,
        isPublic:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOgrn,
      },
      initiatorKpp: {
        id: objectFromPropertyState.initiator?.permanentId,
        isPublic:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.isPublic
            ?.memberKpp,
      },
      initiatorOktmo: {
        id: objectFromPropertyState.initiator?.permanentId,
        isPublic:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOktmo,
      },
      initiatorAddressEgrul: {
        id: objectFromPropertyState.initiator?.permanentId,
        isPublic:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.isPublic
            ?.addressFullEgrulFormat,
      },
      initiatorPaymentDetails: {
        id: objectFromPropertyState.initiator?.permanentId,
        isPublic:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.isPublic
            ?.memberPaymentDetails,
      },
      initiatorOtherDetails: {
        id: objectFromPropertyState.initiator?.permanentId,
        isPublic:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.isPublic
            ?.memberOtherDetails,
      },
      ...preparedInitiatorHeaderInfo,
    }
  }

  static getInfoAboutDocumentModifierAdapter(objectFromPropertyState: Record<string, any>) {
    return {
      documentDateSign: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState?.documentDateSign,
      ),
      documentNumber: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState?.documentNumber,
      ),
      documentReceivers: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState?.documentReceivers,
      ),
    }
  }

  static comparePropertyValueWithCommitedValue(objectFromPropertyState?: Record<string, any>) {
    const { value, commitedValue } = objectFromPropertyState || {}

    if (isUndefined(value) || isUndefined(commitedValue)) return false

    if (
      (isNull(value) && isNull(commitedValue)) ||
      (isEmptyObject(value) && isNull(commitedValue)) ||
      (isNull(value) && isEmptyObject(commitedValue))
    )
      return false

    if (!isObject(value) || !isObject(commitedValue)) return value !== commitedValue

    if ('id' in value && 'id' in commitedValue) return value?.['id'] !== commitedValue?.['id']

    if ('name' in value && 'name' in commitedValue)
      return value?.['name'] !== commitedValue?.['name']

    return false
  }

  static mergeChanges(
    formObjectStates: FormObjectStatesWithChanges[],
    formListStates?: FormListStates,
  ) {
    return (
      formListStates?.deletedElementFromList ||
      formListStates?.addedElementInList ||
      formObjectStates.some(({ isChanged }) => isChanged)
    )
  }
}

export { FormModifierAdapters }
