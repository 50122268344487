import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAVolumeOfCapitalInvestmentsFormValues,
  OldStepExpense,
} from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { lengthValidate, standardAmountValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const oldStepExpensesSectionValidationMap: FormValuesValidationSection<
  keyof OldStepExpense,
  AAVolumeOfCapitalInvestmentsFormValues
> = {
  oldStepExpenseName: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  oldStepExpenseValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { oldStepExpensesSectionValidationMap }
