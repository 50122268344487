import React, { useEffect, useRef } from 'react'

import { KeyboardFocusable } from '@components/KeyboardFocusable'
import cn from 'classnames'

import { useTabs } from '../../hooks/useTabs'
import { PlatformProps, Styles, TabListProps } from '../../types'
import { ScrollableContainer } from '../scrollableContainer'
import { Title } from '../title'

export const PrimaryTabList = ({
  size,
  styles = {},
  className,
  containerClassName,
  titles = [],
  selectedId = titles.length ? titles[0].id : undefined,
  scrollable = true,
  fullWidthScroll,
  onChange,
  dataTestId,
  platform,
  textStyle,
}: TabListProps & Styles & PlatformProps) => {
  const lineRef = useRef<HTMLDivElement>(null)

  const { selectedTab, focusedTab, getTabListItemProps } = useTabs({
    titles,
    selectedId,
    onChange,
  })

  useEffect(() => {
    if (selectedTab) {
      const updateLineWidth = () => {
        if (lineRef.current) {
          lineRef.current.style.width = `${selectedTab.offsetWidth}px`
          lineRef.current.style.transform = `translateX(${selectedTab.offsetLeft}px)`
        }
      }

      const observer = new window.ResizeObserver(updateLineWidth)

      observer.observe(selectedTab)

      return () => observer.disconnect()
    }

    return function () {}
  }, [selectedTab])

  const renderContent = () => (
    <div
      role="tablist"
      data-test-id={dataTestId}
      className={cn(styles.component, className, !textStyle && size && styles[size], {
        [styles.fullWidthScroll]: fullWidthScroll,
      })}
    >
      {titles.map(({ dataTestId: _, toggleRef: __, ...restTitleProps }, index) => (
        <KeyboardFocusable key={restTitleProps.id}>
          {(ref, focused) => (
            <Title
              {...getTabListItemProps(index, ref)}
              {...restTitleProps}
              focused={focused}
              styles={styles}
            />
          )}
        </KeyboardFocusable>
      ))}
      <div className={styles.line} ref={lineRef} />
    </div>
  )

  const wrapperClassName = cn(textStyle && styles[textStyle], styles[platform])

  return scrollable ? (
    <ScrollableContainer
      containerWrapperClassName={wrapperClassName}
      activeChild={focusedTab || selectedTab}
      containerClassName={containerClassName}
      scrollControlsClassName={cn(textStyle && styles.scrollControls)}
      fullWidthScroll={fullWidthScroll}
      view="primary"
      size={textStyle ? undefined : size}
      platform={platform}
    >
      {renderContent()}
    </ScrollableContainer>
  ) : (
    <div className={cn(styles.container, wrapperClassName, containerClassName)}>
      {renderContent()}
    </div>
  )
}
