import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NoticeIntentionTerminateSZPKFormValues } from '@components/Forms/NoticeIntentionTerminateSZPKForm/types'

const { getDefaultInfoAboutDocument } = FieldStateHelpers

const mapOfNoticeIntentionTerminateSZPKMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Организация, реализующая проект' },
  { id: '3', title: 'Уведомление о расторжении СЗПК' },
]

const defaultNoticeIntentionTerminateSZPKFormValues: NoticeIntentionTerminateSZPKFormValues = {
  '1': getDefaultInfoAboutDocument(),
  '2': {
    initiatorFullName: '',
  },
  '3': {
    ruleLinkAgreementTermination: '',
  },
}

const noticeIntentionTerminateSZPKBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
  },
  '3': {
    ruleLinkAgreementTermination: '3.ruleLinkAgreementTermination',
  },
} as const

export {
  defaultNoticeIntentionTerminateSZPKFormValues,
  mapOfNoticeIntentionTerminateSZPKMenu,
  noticeIntentionTerminateSZPKBlockValues,
}
