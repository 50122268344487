import {
  CreateLoadingFolderFileItemProps,
  FileItemReducerState,
} from '@components/Attachments/Attachment/FileItem/types'
import { FolderFileItem } from '@components/Attachments/Attachment/FolderItem/types'
import { nanoid } from 'nanoid'

class FolderItemService {
  static createFolderFileItem(initialFileState: FileItemReducerState): FolderFileItem {
    return {
      id: nanoid(),
      fileState: initialFileState,
    }
  }

  static createLoadingFolderFileItem({
    file,
    rejectedFile,
  }: CreateLoadingFolderFileItemProps): FolderFileItem {
    return {
      id: nanoid(),
      fileState: {
        file,
        isDeletedFile: false,
        rejectedFile,
        fileSize: file?.size || rejectedFile?.file?.size || 0,
        isLoading: true,
        errorMessage: null,
      },
    }
  }

  static fromFilesItemToFolderItem(files: FileItemReducerState[]): FolderFileItem[] {
    if (!files.length) return []
    return files.map((fileState) => FolderItemService.createFolderFileItem(fileState))
  }

  static getFileIndexById(fileId: string, files: FolderFileItem[]) {
    return files.findIndex((file) => file.id === fileId)
  }
}

export { FolderItemService }
