import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { StateSupportsArrayPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/9/types'
import { stateSupportsValidationMap } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/9/validation'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface StateSupportItemProps {
  supportItemId: string
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<InformationOfExecutionConditionsSZPKFormValues>
  indexOfSupport: number
  formName: StateSupportsArrayPathName
  onDeleteSupport: VoidFunction
}

const StateSupportItem: FC<StateSupportItemProps> = ({
  supportItemId,
  blockViewIsValidating,
  editMode,
  formInstance,
  indexOfSupport,
  formName,
  onDeleteSupport,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getTextareaProps, getAmountInputProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
    })

  return (
    <FieldView.StepTemplate
      id={supportItemId}
      anchorId={supportItemId}
      title={`Мера поддержки №${indexOfSupport + 1}`}
      onRemoveStep={editMode ? onDeleteSupport : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.stateSupportName`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.stateSupportName`,
                  rules: stateSupportsValidationMap.stateSupportName,
                  textareaProps: {
                    label: 'Наименование',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.stateSupportName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.stateSupportName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.stateSupportReason`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.stateSupportReason`,
                  rules: stateSupportsValidationMap.stateSupportReason,
                  textareaProps: {
                    label: 'Основание',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.stateSupportReason`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.stateSupportReason`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title={'Объем'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.stateSupportValue`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.stateSupportValue`,
                rules: stateSupportsValidationMap.stateSupportValue,
                onBlur: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.stateSupportValue`), 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.stateSupportValue`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default StateSupportItem
