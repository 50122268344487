import { forwardRef } from 'react'

import Icon, { IconProps } from '@components/Icon'
import cn from 'classnames'

import Button from '../Button'

import defaultColor from './default.module.scss'
import styles from './IconButton.module.scss'
import negativeColor from './negative.module.scss'
import orangeColor from './orange.module.scss'
import { IconButtonProps } from './types'

const colorStyles = {
  default: defaultColor,
  negative: negativeColor,
  orange:orangeColor
} as const

const getIconSizeByButtonSize = (size: IconButtonProps['size']) => {
  switch (size) {
    case 'xl':
    case 'l':
    case 'm':
      return 'm'

    case 's':
      return 's'

    case 'xs':
      return 'xs'

    case 'xxs':
    case 'xxxs':
      return 's'

    default:
      return 'm'
  }
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      color = 'default',
      geometry = 'square',
      view = 'plain',
      size = 'l',
      type = 'button',
      accent,
      className,
      icon,
      rounded,
      widthSize,
      dataTestId,
      ...props
    },
    ref,
  ) => {
    const iconComponent = (props: IconProps) => {
      return <Icon size={getIconSizeByButtonSize(size)} {...props} src={props.src} />
    }

    return (
      <Button
        {...props}
        type={type}
        icon={iconComponent(icon)}
        view="plain"
        color="accent"
        ref={ref}
        dataTestId={dataTestId}
        className={cn(
          styles.iconButton,
          className,
          colorStyles[color][`${view}${accent ? '--accent' : ''}`],
          styles[size],
          styles[geometry],
          {
            [styles['round']]: rounded,
            [styles[`w-${widthSize}`]]: widthSize,
          },
        )}
      />
    )
  },
)

export default IconButton
