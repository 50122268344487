import React from 'react'

import styles from '@components/Forms/LoginForm/LoginByCrypt/ProcurationForm/ProcurationForm.module.scss'
import env from '@config'
import LogoCommissioner from '@icons/layout/LogoCommissioner.png'
import LogoCommissionerWarn from '@icons/layout/LogoCommissionerWarn.png'
import LogoInvestor from '@icons/layout/LogoInvestor.png'
import LogoInvestorWarn from '@icons/layout/LogoInvestorWarn.png'

const ErrorTypes = {
  organization_not_registered: 'organization_not_registered',
  procuration_not_valid: 'procuration_not_valid',
  user_not_registered: 'user_not_registered',
  domain_not_allowed: 'domain_not_allowed',
  invalid_data: 'invalid_data',
  access_denied: 'access_denied',
  temporarily_unavailable: 'temporarily_unavailable',
} as const

const ErrorHeadersTexts = {
  cryptoPluginNotInstalled: 'Не установлен криптоплагин',
  invalidCertificateMessage: 'Сертификат электронной подписи недействителен',
  errorRegistration: 'Ошибка при регистрации',
  organizationNotRegistered: 'Регистрация организации невозможна',
  temporarilyUnavailableMessage: 'Действие временно недоступно',
  numberNotFound: 'Действующей машиночитаемой доверенности не найдено',
  errorMessage: 'Произошла ошибка',
  invalidRequestMessage: 'Запрос невалиден',
  accessIdDenied: 'Доступ запрещен',
  systemAccessDenied: 'Доступ в систему запрещен',
  certificateMissing: 'Электронные подписи не обнаружены',
} as const

const ErrorDescriptionTexts = {
  cryptoPluginNotInstalled: `Пожалуйста, установите плагин \nи повторите попытку`,
  cryptoPluginErrorMessage: 'Произошла ошибка криптоплагина',
  notFound: `В данный момент работа физических лиц без доверенности от юридического лица не предусмотрена.
          Попробуйте проверить срок действия МЧД, выпущенной Вашей организацией, и значения ИНН в УКЭП и ИНН представителя в МЧД`,
  certificateMissing: 'Пожалуйста, проверьте наличие носителя сертификата и повторите попытку',
  userESIANotGivePermission:
    'Пользователь ЕСИА не дал разрешения на использование данных в ГИС "Капиталовложения"',
  temporarilyESIAUnavailable: 'ЕСИА временно недоступна. Повторите попытку позже',
  operatorUnauthorizedMessage: 'Неверный логин или пароль',
} as const

const loginFormTitle = {
  investor: 'Вход',
  goverment: 'Вход',
  operator: 'Вход для операторов',
}

const loginFormIcon = {
  investor: {
    default: LogoInvestor,
    error: LogoInvestorWarn,
  },
  goverment: {
    default: LogoCommissioner,
    error: LogoCommissionerWarn,
  },
}

const ESIA_IS_RENDER = env.REACT_APP_ESIA_FLAG === 'true'

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

const notRegisteredOrganizationErrorBodyText = (
  <>
    Потому что организация не входит в список уполномоченных органов. Чтобы вас добавили в список
    уполномоченных органов, обратитесь в Минэкономразвития России по адресу{' '}
    <a className={styles.merLink} href={`mailto:${ECONOMY_SUPPORT_EMAIL}`}>
      giskap@economy.gov.ru
    </a>
  </>
)

export {
  ErrorDescriptionTexts,
  ErrorHeadersTexts,
  ErrorTypes,
  ESIA_IS_RENDER,
  loginFormIcon,
  loginFormTitle,
  notRegisteredOrganizationErrorBodyText,
}
