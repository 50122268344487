import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { bigIntIsValidate, defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

type ManufactoringOfGoodsKey = keyof ProjectSZPKFormValues['9']['projectResults'][number]

type VolumeOfProductionKey =
  keyof ProjectSZPKFormValues['9']['projectResults'][number]['volumeOfProduction'][number]

const manufactoringOfGoodsValidationMap: FormValuesValidationSection<
  ManufactoringOfGoodsKey,
  ProjectSZPKFormValues
> = {
  projectResultName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 2000),
  },
  projectResultCodeTnVed: {
    required: defaultRHFValidation.required,
  },
}

const volumeOfProductionValidationMap: FormValuesValidationSection<
  VolumeOfProductionKey,
  ProjectSZPKFormValues
> = {
  projectResultValue: {
    validate: bigIntIsValidate,
  },
  projectResultUnit: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 50) : undefined),
  },
}

export { manufactoringOfGoodsValidationMap, volumeOfProductionValidationMap }
