import { generatePropertiesObjectForAATransferRightsAndObligationsForm } from '@components/Forms/AATransferRightsAndObligations/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAATransferRightsAndObligationsForm } from '@components/Forms/AATransferRightsAndObligations/adapters/RHF.adapter'
import { AATransferRightsAndObligationsFormValues } from '@components/Forms/AATransferRightsAndObligations/types'

const useAATransferRightsAndObligationsAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): AATransferRightsAndObligationsFormValues =>
    generateRHFObjectForAATransferRightsAndObligationsForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForAATransferRightsAndObligationsForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useAATransferRightsAndObligationsAdapters }
