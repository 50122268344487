import { RolesTypes } from '@constants/types'

const mapOfStage = {
  EMPTY: {
    label: 'Отчёт не предоставлен',
    variant: 'error',
    priority: 'secondary',
  },
  TERMINATED: {
    label: 'Расторгнуто',
    variant: 'accent',
    priority: 'secondary',
  },
  CHECKING: {
    label: 'На рассмотрении',
    variant: 'accent',
    priority: 'secondary',
  },
  RETURNED: {
    label: 'На доработке',
    variant: 'warning',
    priority: 'secondary',
  },
  ACCEPTED: {
    label: 'Принято',
    variant: 'success',
    priority: 'secondary',
  },
} as const

const mapOfViewReportByRole: Partial<Record<keyof typeof RolesTypes, string>> = {
  MER: 'consolidated',
  OIV: 'region',
} as const

const reportReducerActionMap = {
  SETUP_LOADING: 'SETUP_LOADING',
  SETUP_REPORTS: 'SETUP_REPORTS',
  SETUP_ERROR: 'SETUP_ERROR',
  SETUP_SEARCH_PLACEHOLDER: 'SETUP_SEARCH_PLACEHOLDER',
  SETUP_NO_DATA_PLACEHOLDER: 'SETUP_NO_DATA_PLACEHOLDER',
  SETUP_FILTERS_COUNT: 'SETUP_FILTERS_COUNT',
  REMOVE_PLACEHOLDERS: 'REMOVE_PLACEHOLDERS',
  RESET_ALL_DATA: 'RESET_ALL_DATA',
} as const

export { mapOfStage, mapOfViewReportByRole, reportReducerActionMap }
