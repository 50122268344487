import React, { FC, memo, ReactNode } from 'react'

import Chip from '@components/Chip'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import { isNullOrUndefined } from '@helpers/checkTypes'
import { formatCurrency } from '@helpers/format'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useBooleanState } from '@hooks/useBooleanState'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import type { TMasterDirectionTypeNpa } from '@services/NPA/NPA.entity'
import cn from 'classnames'

import styles from './DirectionItemOld.module.scss'

interface DirectionItemOldProps {
  type: string
  direction: TMasterDirectionTypeNpa
  investmentSum: number | null
  children: ReactNode
  initialCollapseState?: boolean
  disabled?: boolean
}

const DirectionItemOld: FC<DirectionItemOldProps> = ({
  initialCollapseState,
  disabled,
  type,
  direction,
  investmentSum,
  children,
}) => {
  const {
    booleanState: masterItemIsOpen,
    setBooleanState: setMasterItemState,
    reverseBooleanState: toggleMasterItem,
  } = useBooleanState(initialCollapseState)

  const {
    booleanState: isMountChildren,
    setBooleanStateToTrue: mountChildren,
    setBooleanStateToFalse: unmountChildren,
  } = useBooleanState(initialCollapseState)

  const { getCollapseProps } = useCollapse({
    isExpanded: masterItemIsOpen,
    onExpandStart: () => {
      mountChildren()
    },
    onCollapseEnd: () => {
      unmountChildren()
    },
  })

  const handleToggleMasterItem = (e) => {
    e.preventDefault()
    e.stopPropagation()

    toggleMasterItem()
  }

  useUpdateEffect(() => {
    if (isNullOrUndefined(initialCollapseState)) return

    setMasterItemState(initialCollapseState)
  }, [initialCollapseState])

  if (!!disabled) return <>{children}</>

  return (
    <>
      <button
        type="button"
        className={styles.directionItem}
        data-testid={`DirectionItem-${direction}-itemButton`}
        onClick={handleToggleMasterItem}
      >
        <div className={styles.directionItem__leftPart}>
          <IconButton
            className={styles.directionItem__control}
            size={'s'}
            view={'basic'}
            dataTestId={`DirectionItem-${direction}-toggleCollapse-iconButton`}
            icon={{
              className: cn(styles['directionItem__control-item'], {
                [styles['directionItem__control--toggled']]: masterItemIsOpen,
              }),
              src: chevronRightIcon,
            }}
            onClick={handleToggleMasterItem}
          />
        </div>
        <div className={styles.directionItem__rightPart}>
          <Typography.Body variant={'bodyLMedium'}>{type}</Typography.Body>
          {investmentSum && (
            <Chip.Base
              disableClickableEffect
              variant={'accent'}
              size={'xs'}
              priority={'secondary'}
              label={`от ${formatCurrency(investmentSum)}. ₽`}
            />
          )}
        </div>
      </button>
      <div {...getCollapseProps()}>
        {isMountChildren && <div className={styles.transition__content}>{children}</div>}
      </div>
    </>
  )
}

export default memo(DirectionItemOld)
