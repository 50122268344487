import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo } from 'react'
import { Props as ReactSVGProps, ReactSVG } from 'react-svg'

import ChipCloseIcon from '@icons/ChipCloseIcon.svg'
import cn from 'classnames'

import styles from './Chip.module.scss'

type NativeProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export interface ChipBaseProps extends NativeProps {
  label: string
  priority?: 'primary' | 'secondary'
  variant?: 'success' | 'accent' | 'brand' | 'outline' | 'error' | 'warning'
  size?: 'xs' | 's' | 'm'
  leftAddon?: Omit<ReactSVGProps, 'ref'>
  leftAddonClassName?: string
  contentClassName?: string
  onClose?: () => void
  stopPropagation?: boolean

  //Отключает курсор и ховер эффект по умолчанию
  disableClickableEffect?: boolean

  dataTestId?: string
  iconDataTestId?: string
}

const ChipBase: FC<ChipBaseProps> = ({
  label,
  leftAddon,
  priority = 'primary',
  variant = 'outline',
  size = 'm',
  type = 'button',
  onClose,
  leftAddonClassName,
  contentClassName,
  disableClickableEffect,
  stopPropagation,
  dataTestId,
  iconDataTestId,
  ...rest
}) => {
  const closeHandler = (e) => {
    if (stopPropagation) e.stopPropagation()
    onClose?.()
  }

  return (
    <button
      {...rest}
      data-testid='currentProjectState'
      type={type}
      tabIndex={disableClickableEffect ? -1 : rest.tabIndex}
      className={cn(
        styles.container,
        styles[priority],
        styles[size],
        styles[variant],
        rest.className,
        { [styles.disableClickableEffect]: disableClickableEffect },
      )}
    >
      {leftAddon && (
        <ReactSVG
          {...leftAddon}
          src={leftAddon.src as string}
          className={cn(styles.leftAddon, styles[variant], leftAddonClassName)}
        />
      )}
      <div className={cn(styles.content, contentClassName)}>{label}</div>
      {onClose && (
        <ReactSVG
          data-testid={iconDataTestId}
          className={cn(styles.closeIcon, styles[variant])}
          src={ChipCloseIcon}
          wrapper="span"
          onClickCapture={closeHandler}
          onClick={closeHandler}
        />
      )}
    </button>
  )
}

export default memo(ChipBase)
