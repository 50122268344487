import React, { memo, useCallback } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/NpaFormAddon/NpaThree/NpaThree.module.scss'
import {
  BaseNpaFormValues,
  NpaClassificationsArrayPathName,
} from '@components/DocumentFormComponents/NpaFormAddon/types'
import TextWithTooltip from '@components/NewDesign/TextWithTooltip'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import LoggerServiceHelpers from '@services/LoggerService/LoggerService.helpers'
import {
  IPartClassificationLayout,
  PartCustomProps,
  PartCustomPropsWithId,
} from '@services/NPA/NPA.entity'
import { ICardViewRefs } from 'components/Projects/[id]/DocumentLayout/Addons/NpaAddonOld'

import PartCardView from '../PartCard'

const DESCRIPTION_LIMIT = 43

interface ClassificationProps<T extends BaseNpaFormValues> {
  blockViewIsValidating: boolean
  nameOfFieldArray: NpaClassificationsArrayPathName
  classification: T extends BaseNpaFormValues ? IPartClassificationLayout : never
  formInstance: T extends BaseNpaFormValues ? UseFormReturn<T> : never
  onBindCardRef: (callback: (prevState: ICardViewRefs | null) => ICardViewRefs) => void
  onScrollToCardView: (partId: string) => void
  onRemoveNpaPart?: (id: string) => Promise<void>
  onUpdateNpaPart?: (partProps: PartCustomPropsWithId) => Promise<void>
}

const ClassificationView = <T extends BaseNpaFormValues>({
  blockViewIsValidating,
  formInstance,
  nameOfFieldArray,
  onBindCardRef,
  onScrollToCardView,
  classification,
  onRemoveNpaPart,
  onUpdateNpaPart,
}: ClassificationProps<T>) => {
  const { fields } = useFieldArray({
    control: formInstance.control,
    name: `${nameOfFieldArray}.parts`,
    keyName: 'fieldId',
  })

  const handleUpdatePart = useCallback(
    (partId: string) => async (partProps: PartCustomProps) => {
      try {
        await onUpdateNpaPart?.({
          id: partId,
          ...partProps,
        })

        onScrollToCardView(partId)
      } catch (error) {
        const additionInfo = {
          partId,
          partData: partProps,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'ClassificationView',
            moduleName: 'ClassificationsView',
            componentType: 'updateNPAPart',
          },
        })(error)

        throw error
      }
    },
    [onUpdateNpaPart, onScrollToCardView],
  )

  const handleRemovePart = useCallback(
    (partId: string) => async () => {
      try {
        await onRemoveNpaPart?.(partId)
      } catch (error) {
        const additionInfo = {
          partId,
        }

        LoggerServiceHelpers.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'ClassificationView',
            moduleName: 'ClassificationsView',
            componentType: 'removeNPAPart',
          },
        })(error)

        throw error
      }
    },
    [onRemoveNpaPart],
  )

  const bindRef = useCallback(
    (partId: string) => (ref: HTMLDivElement | null) => {
      if (ref) {
        return onBindCardRef?.((prevState) => {
          if (!prevState) {
            return { [partId]: ref.offsetTop }
          }

          return {
            ...prevState,
            [partId]: ref.offsetTop,
          }
        })
      }
    },
    [onBindCardRef],
  )

  return (
    <>
      <TextWithTooltip
        variant="bodyXLMedium"
        value={classification.description}
        className={styles['npaThree__description']}
        limit={DESCRIPTION_LIMIT}
        tooltipProps={{
          contentClassName: styles.tooltip__content,
          targetClassName: styles.tooltip__target,
          popoverClassName: styles.tooltip__popover,
          arrowClassName: styles.tooltip__arrow,
          position: 'top',
        }}
      />
      <div className={styles.npaThree__parts}>
        {fields.map((part, index) => {
          const partError = formInstance.getFieldState(`${nameOfFieldArray}.parts.${index}`)?.error

          return (
            <PartCardView
              key={part.id}
              part={part}
              partError={blockViewIsValidating ? partError : undefined}
              partCardRef={bindRef(part.id)}
              onUpdatePart={handleUpdatePart(part.partId)}
              onRemovePart={handleRemovePart(part.partId)}
            />
          )
        })}
      </div>
    </>
  )
}

export default memo(ClassificationView)
