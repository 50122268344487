import { KeyboardEvent } from 'react'
import { Control } from 'react-hook-form'

const MAX_LENGTH = 24

export const onlyNumberKey = <T extends HTMLElement>(
  e: KeyboardEvent<T>,
  additionalCharacters?: string[],
) => {
  // Only ASCII character in that range allowed
  const notNumberOrSpecialSymbol = new RegExp(
    `[0-9 | ${additionalCharacters?.length ? additionalCharacters.join('') : ''}]`,
  )

  // @ts-ignore
  if (!notNumberOrSpecialSymbol.test(e.key) || e.target?.value.length > MAX_LENGTH) {
    e.preventDefault()

    return false
  }

  return true
}

export const getErrorFromControl = <T>(control: Control<T, any>, keys: string[]) => {
  let currentObj = ''

  for (let i = 0; i < keys.length; i++) {
    currentObj = currentObj?.[keys[i]] ?? control._formState.errors?.[keys[i]]
  }

  return !!currentObj
}
