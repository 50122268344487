import React, { memo, useCallback, useEffect, useMemo, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import { ErrorTypes } from '@components/Forms/LoginForm/constants'
import useGovermentDomainModal from '@components/Forms/LoginForm/hooks/useGovermentDomainModal'
import useLoginErrorHandler from '@components/Forms/LoginForm/hooks/useLoginErrorHandler'
import {
  initialOrganizationsState,
  organizationsActionCreators,
  organizationsReducer,
} from '@components/Forms/LoginForm/LoginByESIA/reducer'
import {
  ESIAErrorProps,
  HandleNativeReducerError,
} from '@components/Forms/LoginForm/LoginByESIA/types'
import LoginError from '@components/Forms/LoginForm/shared/LoginError'
import LoginLogo from '@components/Forms/LoginForm/shared/LoginLogo'
import LoginWrapper from '@components/Forms/LoginForm/shared/LoginWrapper'
import { mapOfTypeRedirect } from '@components/Forms/RegistrationForm/const'
import {
  DefaultRegistrationLocationState,
  RegistrationLocationState,
} from '@components/Forms/RegistrationForm/types'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography/Typography'
import { Paths } from '@constants/paths'
import { isInvestorDomain } from '@constants/system'
import { useAPIContext } from '@context/APIContext'
import type { ESIAOrganization } from '@context/APIContext/hooks/useESIAApi'
import { useAuthContext } from '@context/AuthContext'
import { isAxiosError, isJsError } from '@helpers/checkTypes'
import { useQueryManager } from '@hooks/useQueryManager'
import arrowBackIcon from '@icons/navigation/arrow_back.svg'
import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import type { AxiosError } from 'axios'

import {
  descriptionTexts,
  ESIA_ERROR_DESCRIPTION_TYPE,
  ESIAComponentInfo,
  ESIAOrganizationComponentInfo,
  headerTexts,
  mapOfESIARedirect,
} from './constants'
import styles from './LoginByESIA.module.scss'
import OrganizationsList from './OrganizationsList'

const LoginByESIA = () => {
  const navigate = useNavigate()

  const { signInByESIA } = useAuthContext()
  const {
    ESIAApi: { getESIAOrganizations, getESIAOrganizationLink },
  } = useAPIContext()

  const { queryUtils } = useQueryManager()

  const [state, dispatch] = useReducer(organizationsReducer, initialOrganizationsState)

  const { handleOpenRedirectGovermentDomainModal } = useGovermentDomainModal()

  const handleDefaultReducerError = () => {
    dispatch(organizationsActionCreators.setDefaultError())
  }

  const handleESIAErrorReducerError = (props: ESIAErrorProps) => {
    dispatch(organizationsActionCreators.setESIAError(props))
  }

  const handleNativeReducerError = useCallback(
    ({ componentInfo = ESIAComponentInfo, error }: HandleNativeReducerError) => {
      LoggerHelpersService.handleInternalLogError({
        componentInfo,
      })(error)

      if (!isJsError(error)) throw error

      dispatch(organizationsActionCreators.setNativeError(error))
    },
    [],
  )

  const handleReducerError = useCallback((error: AxiosError) => {
    dispatch(organizationsActionCreators.setError(error))
  }, [])

  const onGovermentDomainModalOpen = useCallback(
    (domain: string) => {
      handleOpenRedirectGovermentDomainModal(domain)
    },
    [handleOpenRedirectGovermentDomainModal],
  )

  // TODO: в 2.0.8 поменять условие на errorCode 02.02
  const notAllowedDomainHandler = useCallback(
    (error: AxiosError) => {
      const { error_description: domain } = error.response?.data || {}

      if (!domain) return dispatch(organizationsActionCreators.setError(error))

      onGovermentDomainModalOpen(domain)
    },
    [onGovermentDomainModalOpen],
  )

  const paramsForOrganizationRequest = useMemo(() => {
    return {
      state: queryUtils.getQuery('state'),
      code: queryUtils.getQuery('code'),
      error: queryUtils.getQuery('error_description'),
      error_type: queryUtils.getQuery('error'),
    }
  }, [queryUtils])

  const paramsForLoginRequest = useMemo(() => {
    return {
      ...paramsForOrganizationRequest,
      orgOid: queryUtils.getQuery('orgOid'),
    }
  }, [paramsForOrganizationRequest, queryUtils])

  const { handleErrorByType } = useLoginErrorHandler({
    notAllowedDomainHandler,
  })

  const handleBackButton = useCallback(() => {
    navigate(Paths.Login, { replace: true })
  }, [navigate])

  const handleRegistrationRedirect = useCallback(
    async ({ authenticationKey }: DefaultRegistrationLocationState) => {
      if (!authenticationKey) return handleBackButton()

      const { state, code, orgOid } = paramsForLoginRequest

      if (!state || !code || !orgOid) return handleDefaultReducerError()

      const stateToRegistration: RegistrationLocationState = {
        from: mapOfTypeRedirect.ESIA,
        authenticationKey,
      }

      navigate(Paths.Registration, {
        state: stateToRegistration,
      })
    },
    [handleBackButton, navigate, paramsForLoginRequest],
  )

  const defaultOrganizationRequestDecorator = useCallback(
    (fetcher) => {
      return async (componentInfo: ComponentInfo) => {
        dispatch(organizationsActionCreators.setLoading(true))

        try {
          return await fetcher()
        } catch (error) {
          if (!isAxiosError(error)) {
            handleNativeReducerError({ error, componentInfo })

            throw error
          }

          const { response } = error
          const authenticationKey = response?.data?.authenticationKey as string | undefined

          handleErrorByType({
            error,
            onRegistrationRedirect: () =>
              authenticationKey ? handleRegistrationRedirect({ authenticationKey }) : undefined,
            onError: handleReducerError,
          })
        } finally {
          dispatch(organizationsActionCreators.setLoading(false))
        }
      }
    },
    [handleErrorByType, handleNativeReducerError, handleReducerError, handleRegistrationRedirect],
  )

  //Осуществляется переход в ESIA для авторизации пользователя
  const handleSelectOrganization = useCallback(
    (organization: ESIAOrganization) => {
      dispatch(organizationsActionCreators.setRedirectLoading(true))

      try {
        const readyLink = getESIAOrganizationLink(organization.esiaOrgOid)
        window.location.replace(readyLink)
      } catch (error) {
        LoggerHelpersService.handleInternalLogError({
          componentInfo: ESIAComponentInfo,
        })(error)

        throw error
      } finally {
        dispatch(organizationsActionCreators.setRedirectLoading(true))
      }
    },
    [getESIAOrganizationLink],
  )

  //Если одна организация, то сразу получение ссылки с orgOid, иначе отрисовка и выбор
  const handleSuccessOrganizations = useCallback(
    (organizations: ESIAOrganization[]) => {
      if (organizations.length === 1) return handleSelectOrganization(organizations[0])

      dispatch(organizationsActionCreators.setOrganizations(organizations))
    },
    [handleSelectOrganization],
  )

  const checkErrorFromRedirect = useCallback(() => {
    if (!paramsForOrganizationRequest.error) return

    dispatch(organizationsActionCreators.setError(paramsForOrganizationRequest.error))
  }, [paramsForOrganizationRequest.error])

  const handleReorganizationRedirect = useCallback(async () => {
    checkErrorFromRedirect()

    if (
      paramsForLoginRequest.error_type === ErrorTypes.access_denied &&
      paramsForLoginRequest.error?.includes(ESIA_ERROR_DESCRIPTION_TYPE)
    )
      return handleESIAErrorReducerError({
        error_description: paramsForLoginRequest.error,
        error_type: paramsForLoginRequest.error_type,
      })

    if (!paramsForOrganizationRequest.state || !paramsForOrganizationRequest.code)
      return handleDefaultReducerError()

    const { state, code } = paramsForOrganizationRequest

    return await defaultOrganizationRequestDecorator(async () => {
      const organizations = await getESIAOrganizations({ state, code })

      return handleSuccessOrganizations(organizations)
    })(ESIAOrganizationComponentInfo)
  }, [
    checkErrorFromRedirect,
    defaultOrganizationRequestDecorator,
    getESIAOrganizations,
    handleSuccessOrganizations,
    paramsForLoginRequest.error,
    paramsForLoginRequest.error_type,
    paramsForOrganizationRequest,
  ])

  const handleLoginRedirect = useCallback(async () => {
    checkErrorFromRedirect()

    if (
      paramsForLoginRequest.error_type === ErrorTypes.access_denied &&
      paramsForLoginRequest.error?.includes(ESIA_ERROR_DESCRIPTION_TYPE)
    )
      return handleESIAErrorReducerError({
        error_description: paramsForLoginRequest.error,
        error_type: paramsForLoginRequest.error_type,
      })

    if (
      !paramsForLoginRequest.state ||
      !paramsForLoginRequest.code ||
      !paramsForLoginRequest.orgOid
    )
      return handleDefaultReducerError()

    const { state, code, orgOid } = paramsForLoginRequest

    return await defaultOrganizationRequestDecorator(() => {
      return signInByESIA?.({
        code,
        state,
        orgOid,
      })
    })(ESIAComponentInfo)
  }, [
    checkErrorFromRedirect,
    defaultOrganizationRequestDecorator,
    paramsForLoginRequest,
    signInByESIA,
  ])

  const typeOfRedirect = queryUtils.getQuery('redirect_from') as keyof typeof mapOfESIARedirect

  useEffect(() => {
    (async () => {
      switch (typeOfRedirect) {
        case mapOfESIARedirect.ESIA_LOGIN:
          await handleLoginRedirect()
          break

        case mapOfESIARedirect.ESIA_ORGANIZATIONS:
          await handleReorganizationRedirect()
          break

        default:
          break
      }
    })()
  }, [typeOfRedirect])

  const isShowLoginError = Boolean(state.error?.header) || state.isLoading

  const redirectFromLogin = typeOfRedirect === mapOfESIARedirect.ESIA_LOGIN
  const isShowOrganizationList = state.organizations.length > 1 && !redirectFromLogin

  const isShowErrorLogo = Boolean(state.error) && !state.isLoading

  const currentModalHeader =
    (!state.isLoading && state.error && state.error.header) ||
    (isShowOrganizationList && headerTexts.chooseOrganization) ||
    (state.isRedirectLoading && headerTexts.processRedirect) ||
    ''
  const currentModalDescription =
    (!state.isLoading && state.error && state.error.body) ||
    (!redirectFromLogin && isShowOrganizationList && descriptionTexts.multipleOrganizations) ||
    (state.isRedirectLoading && descriptionTexts.plsWait) ||
    ''

  return (
    <LoginWrapper view={isInvestorDomain ? 'secondary' : 'primary'}>
      <LoginLogo isShowErrorLogo={isShowErrorLogo} />
      {!isShowLoginError && (
        <div className={styles.container}>
          <div className={styles.title}>
            <IconButton
              className={styles.title__icon}
              dataTestId="LoginByESIA-back-button"
              icon={{
                src: arrowBackIcon,
              }}
              onClick={handleBackButton}
            />
            <Typography.Headline variant="headlineH2" className={styles.title__text}>
              {currentModalHeader}
            </Typography.Headline>
          </div>
          <Typography.Body variant="bodyMRegular" className={styles.text}>
            {currentModalDescription}
          </Typography.Body>
        </div>
      )}
      {isShowLoginError && (
        <LoginError
          isLoading={state.isLoading}
          caption=""
          customLoadingMessage="Авторизация"
          error={state.error}
          goToFormHandler={handleBackButton}
        />
      )}
      {!isShowLoginError && isShowOrganizationList && (
        <div className={styles.form}>
          <OrganizationsList
            organizations={state.organizations}
            onChooseOrganization={handleSelectOrganization}
          />
        </div>
      )}
    </LoginWrapper>
  )
}

export default memo(LoginByESIA)
