import { useCallback, useState } from 'react'

import { Paths } from '@constants/paths'
import { Urls } from '@constants/urls'
import { ESIASignInProps } from '@context/APIContext/hooks/useESIAApi'
import { jwtDecodeFacade } from '@context/AuthContext/helpers/jwtDecodeFacade'
import { SessionStorageFields, StorageFields } from '@context/AuthContext/models'
import { AuthRequest, AuthState, SignInByCertificateProps } from '@context/AuthContext/types'
import { AuthData } from '@interfaces/auth'
import { Fetcher } from '@packages/api/fetcher'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import axios from 'axios'
import { nanoid } from 'nanoid'
import storage from 'store2'

const fieldsToRemoveOnReset = [
  StorageFields.clientId,
  StorageFields.authState,
  StorageFields.userInfo,
  StorageFields.userUuid,
  StorageFields.thumbprint,
]

export const signInByCertificateTypes = {
  afterRegistration: 'afterRegistration',
  default: 'default',
} as const

export const useAuth = (initialState: AuthState, onSaveAuthData: (props?: unknown) => void) => {
  const [authState, setAuthState] = useState<AuthState | null>(initialState)

  const resetAuthState = useCallback(() => {
    //Запоминается последний пользователь, который зашёл
    sessionStorage.setItem(
      SessionStorageFields.lastAuthedUser,
      storage.get(StorageFields.userInfo)?.sub,
    )

    fieldsToRemoveOnReset.forEach((fieldName) => storage.remove(fieldName))

    setAuthState(null)
  }, [])

  const setAuthData = useCallback(
    (state: AuthState) => {
      const userUuid = nanoid()

      storage.set(StorageFields.authState, state)
      storage.set(StorageFields.userUuid, userUuid)
      setAuthState((prevState) => ({
        ...prevState,
        ...state,
      }))
      onSaveAuthData?.()
    },
    [onSaveAuthData],
  )

  const signIn = useCallback(
    async (body: AuthRequest) => {
      try {
        const { data } = await Fetcher.post<AuthData, unknown, { email: string; password: string }>(
          {
            url: Urls.OperatorToken,
            data: {
              email: body.email || '',
              password: body.password || '',
            },
            header: 'JSON',
          },
        )

        if (data.access_token) {
          try {
            const azp = jwtDecodeFacade.get({ name: 'azp' })
            storage.set(StorageFields.clientId, azp)
          } catch {
            storage.set(StorageFields.clientId, '')
          }
        }

        setAuthData({
          token: data.access_token,
          refreshToken: data.refresh_token,
        })

        return data
      } catch (error) {
        throw error
      }
    },
    [setAuthData],
  )

  const refreshToken = useCallback(
    async (body: AuthRequest) => {
      try {
        const { data } = await Fetcher.post<AuthData, unknown, { refreshToken: string }>({
          url: Urls.RefreshToken,
          data: { refreshToken: body?.refresh_token || '' },
          header: 'JSON',
        })

        if (data.access_token) {
          try {
            const azp = jwtDecodeFacade.get({ name: 'azp' })
            storage.set(StorageFields.clientId, azp)
          } catch {
            storage.set(StorageFields.clientId, '')
          }
        }

        setAuthData({
          token: data.access_token,
          refreshToken: data.refresh_token,
        })

        return data
      } catch (error) {
        throw error
      }
    },
    [setAuthData],
  )

  const signInByESIA = useCallback(
    async (params: ESIASignInProps) => {
      try {
        const { data } = await axios.post<AuthData>(`${Urls.ESIAAuth}/token?`, null, {
          params,
        })

        setAuthData({
          token: data.access_token,
          refreshToken: data.refresh_token,
        })

        return data
      } catch (error) {
        throw error
      }
    },
    [setAuthData],
  )

  const signInByCertificate = useCallback(
    async (props: SignInByCertificateProps, isFL?: boolean) => {
      const { body } = props
      body.data = body.data.replace(/[\r\n\s]+/g, '')

      const preparedBodyRequest = isFL
        ? {
            data: body.data,
            issuerInn: body.issuerINN || '',
            number: body.number?.toString() || '',
          }
        : { data: body.data }

      try {
        const { data: tokesFromBaseSign } = await Fetcher.post<AuthData>({
          url: Urls.Token,
          data: preparedBodyRequest,
        })

        setAuthData({
          token: tokesFromBaseSign.access_token,
          refreshToken: tokesFromBaseSign.refresh_token,
        })
        return tokesFromBaseSign
      } catch (error) {
        throw error
      }
    },
    [setAuthData],
  )

  const getDataToSign = useCallback(async () => {
    try {
      const { data } = await Fetcher.get({ url: Urls.SignData, header: 'Default' })

      return data
    } catch (error) {
      throw error
    }
  }, [])

  const getNewTokenForRequest = useCallback(
    async (failedRequest: any) => {
      try {
        const body: AuthRequest = {
          refresh_token: storage.get('auth_state')?.refreshToken,
        }

        const { access_token } = await refreshToken(body)
        failedRequest.response.config.headers['Authorization'] = `Bearer ${access_token}`

        return Promise.resolve()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useAuth',
            componentType: 'getNewTokenForRequest',
          },
        })(error)

        window.location.href = Paths.Login
        resetAuthState()
      }
    },
    [resetAuthState, signIn],
  )

  return {
    authState,
    setAuthData,
    getDataToSign,
    signIn,
    signInByCertificate,
    signInByESIA,
    resetAuthState,
    getNewTokenForRequest,
    refreshToken,
  }
}
