import { fromStringBooleanToNative } from '@helpers/boolean/fromStringBooleanToNative'
import { isNullOrUndefined, isString } from '@helpers/checkTypes'
import { IAttributeFromBackend } from '@services/Attribute/Attribute.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

class AttributeService {
  fromBackendAttributeToJsTypes(attribute: IAttributeFromBackend) {
    if (isNullOrUndefined(attribute.value)) return attribute.value

    switch (attribute.type) {
      case 'Boolean':
        return fromStringBooleanToNative(attribute.value)

      case 'Double':
        return parseFloat(attribute.value)

      case 'List':
        return isString(attribute.value) ? JSON.parse(attribute.value) : attribute.value

      case 'LocalDate':
        return DayjsService.dayjs(attribute.value)

      default:
      case 'String':
        return attribute.value
    }
  }
}

export default new AttributeService()
