import React from 'react'
import { useFormContext } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeOfRefuseConcludeAABlockValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/const'
import { secondSectionValidationMap } from '@components/Forms/NoticeOfRefuseConcludeAAForm/Forms/2/validation'
import { useNoticeOfRefuseConcludeAAManager } from '@components/Forms/NoticeOfRefuseConcludeAAForm/Manager'
import { NoticeOfRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/types'
import { NoticeOfRefuseConcludeAAFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfRefuseConcludeAAForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const currentBlockValues = noticeOfRefuseConcludeAABlockValues['2']

const Second = () => {
  const formInstance = useFormContext<NoticeOfRefuseConcludeAAFormValues>()
  const {
    handlers: { handleChangeValue },
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useNoticeOfRefuseConcludeAAManager()

  const { getSubscribableControlProps, getControllerProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: NoticeOfRefuseConcludeAAFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.initiatorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                controllerProps={{
                  ...getControllerProps({
                    name: currentBlockValues.initiatorFullName,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное должностное лицо'}
          formInstance={formInstance}
          formSection={'2'}
          prefixFormFieldName={'initiator'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: currentBlockValues.initiatorHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: currentBlockValues.initiatorHeaderName,
                      rules: secondSectionValidationMap.initiatorHeaderName,
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () =>
                        setTimeout(() => {
                          handleChangeValue?.(currentBlockValues.initiatorHeaderName)
                        }, 0),
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
      </Stack>
    </Container>
  )
}

export default Second
