import React, { FC, memo } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Typography from '@components/NewDesign/Typography'
import { defaultRHFValidation } from '@constants/validations'
import { PartCustomProps } from '@services/NPA/NPA.entity'

import styles from './EditPartOld.module.scss'

interface EditPartCardOldProps {
  initialPartInfo: PartCustomProps
  onUpdate: ({ partCorrection, justification }: PartCustomProps) => void
  onCancel: () => void
}

const EditPartCardOld: FC<EditPartCardOldProps> = ({ initialPartInfo, onUpdate, onCancel }) => {
  const {
    getValues,
    control,
    formState: { isValid },
  } = useForm<PartCustomProps>({
    reValidateMode: 'onChange',
    mode: 'onBlur',
    defaultValues: {
      justification: initialPartInfo.justification,
      partCorrection: initialPartInfo.partCorrection,
    },
  })

  const handleUpdate = () => {
    const formValues = getValues()

    onUpdate({
      partCorrection: formValues.partCorrection,
      justification: formValues.justification,
    })
  }

  return (
    <div className={styles.editPart}>
      <div className={styles.editPart__inputContainer}>
        <div className={styles.editPart__inputRow}>
          <Typography.Body className={styles.editPart__part} variant={'bodyMRegular'}>
            Обоснование
          </Typography.Body>
          <ControlledInput
            name={'justification'}
            control={control}
            rules={{
              required: defaultRHFValidation.required,
            }}
            inputProps={{
              controlClassName: styles.editPart__control,
              rootClassName: styles.editPart__input,
              fixWidth: true,
              clear: true,
              size: 's',
              placeholder: 'Укажите обоснование',
            }}
          />
        </div>
        <div className={styles.editPart__inputRow}>
          <Typography.Body className={styles.editPart__part} variant={'bodyMRegular'}>
            Статьи и пункты
          </Typography.Body>
          <ControlledInput
            name={'partCorrection'}
            control={control}
            inputProps={{
              controlClassName: styles.editPart__control,
              rootClassName: styles.editPart__input,
              fixWidth: true,
              clear: true,
              size: 's',
              placeholder: 'Укажите статьи и пункты, которые хотите стабилизировать',
            }}
          />
        </div>
      </div>
      <div className={styles.editPart__actions}>
        <Button size={'m'} view={'gray'} onClick={onCancel}>
          Отмена
        </Button>
        <Button size={'m'} disabled={!isValid} onClick={handleUpdate}>
          Сохранить
        </Button>
      </div>
    </div>
  )
}

export default memo(EditPartCardOld)
