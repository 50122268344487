import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
  IConstructionStage,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const eRegistrationAAgreementOnChangingCharacteristicsOfObjectsValidationMap: FormValuesValidationSection<
  keyof IConstructionStage['registration'],
  AAgreementOnChangingCharacteristicsOfObjectsFormValues
> = {
  typeObjectStateRegistration: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  objectStateRegistrationEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { eRegistrationAAgreementOnChangingCharacteristicsOfObjectsValidationMap }
