import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import ProjectStage from '@components/Forms/ProjectSZPKForm/Forms/6/AdditionalForms/ProjectStage'
import { StagesPathName } from '@components/Forms/ProjectSZPKForm/Forms/6/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { isFormFieldError } = DocumentFormHelpers

const Sixth = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: stages } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    StagesPathName
  >({
    control: formInstance?.control,
    name: projectSZPKBlockValues['6'].stages,
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const stagesError = formInstance.getFieldState(projectSZPKBlockValues['6'].stages)?.error

  const handleAddStage = async () => {
    await handleAddItemToListWithOutValue?.(projectSZPKBlockValues['6'].stages)

    if (!stages.length) {
      formInstance.clearErrors(projectSZPKBlockValues['6'].stages)
    }
  }

  const handleRemoveStage = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${projectSZPKBlockValues['6'].stages}.${index}`,
      projectSZPKBlockValues['6'].stages,
    )
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            {!editMode && !stages.length && <EmptyDataText />}
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: projectSZPKBlockValues['6'].stages,
                })}
              >
                <FlipperList list={stages}>
                  {stages.map((stage, index) => (
                    <ProjectStage
                      key={stage.id}
                      id={stage.id}
                      indexOfStep={index}
                      name={`${projectSZPKBlockValues['6'].stages}.${index}` as const}
                      onRemoveStage={handleRemoveStage(index)}
                    />
                  ))}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </Col>
          {isFormFieldError(stagesError) && !stages.length && (
            <Row className={'px-1'}>
              <FormError rowClassName={'px-4'} colClassName={'px-3'}>
                {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
              </FormError>
            </Row>
          )}
        </Row>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddStage}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить этап
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Sixth
