import { createContext, FC, useContext } from 'react'

import useDictionariesApi from '@context/APIContext/hooks/useDictionariesApi/useDictionariesApi'
import useESIAApi from '@context/APIContext/hooks/useESIAApi'
import useMonitoringApi from '@context/APIContext/hooks/useMonitoringApi'
import useNotificationsApi from '@context/APIContext/hooks/useNotificationsApi'
import useNpaApi from '@context/APIContext/hooks/useNpaApi/useNpaApi'
import { useProcurationsApi } from '@context/APIContext/hooks/useProcurationsApi'
import useProjectsApi from '@context/APIContext/hooks/useProjectsApi'
import usePropertiesApi from '@context/APIContext/hooks/usePropertiesApi'
import { useReferenceBooksApi } from '@context/APIContext/hooks/useReferenceBooksApi'
import { useWebFormApi } from '@context/APIContext/hooks/useWebFormApi'

import { useAuthorizedOrganizationsApi } from './hooks/useAuthorizedOrganizationsApi'
import useDFOSApi from './hooks/useDFOSApi'
import useDocumentsApi from './hooks/useDocumentsApi'
import useDraftApi from './hooks/useDraftApi'
import useInnsApi from './hooks/useInnsApi'
import { useHeaderLetterPartsApi } from './hooks/useLetterHeadPartsApi/useHeaderLetterPartsApi'
import useOperatorApi from './hooks/useOperatorApi'
import useOrganizationApi from './hooks/useOrganizationApi'
import useOrganizationsApi from './hooks/useOrganizationsApi'
import useProcessesApi from './hooks/useProcessesApi'

interface APIContextProps {
  innsApi: ReturnType<typeof useInnsApi>
  dfosApi: ReturnType<typeof useDFOSApi>
  operatorApi: ReturnType<typeof useOperatorApi>
  documentsApi: ReturnType<typeof useDocumentsApi>
  organizationApi: ReturnType<typeof useOrganizationApi>
  organizationsApi: ReturnType<typeof useOrganizationsApi>
  projectsApi: ReturnType<typeof useProjectsApi>
  notificationsApi: ReturnType<typeof useNotificationsApi>

  monitoringApi: ReturnType<typeof useMonitoringApi>

  npaApi: ReturnType<typeof useNpaApi>

  draftApi: ReturnType<typeof useDraftApi>

  processesApi: ReturnType<typeof useProcessesApi>

  authorizedOrganizationsApi: ReturnType<typeof useAuthorizedOrganizationsApi>
  dictionariesApi: ReturnType<typeof useDictionariesApi>
  ESIAApi: ReturnType<typeof useESIAApi>

  referenceBooksApi: ReturnType<typeof useReferenceBooksApi>

  procurationsApi: ReturnType<typeof useProcurationsApi>

  webFormApi: ReturnType<typeof useWebFormApi>

  propertiesApi: ReturnType<typeof usePropertiesApi>
  headerLetterPartsApi: ReturnType<typeof useHeaderLetterPartsApi>
}

// @ts-ignore
export const APIContext = createContext<APIContextProps>({})

export const APIProvider: FC = ({ children }) => {
  const innsApi = useInnsApi()
  const dfosApi = useDFOSApi()
  const operatorApi = useOperatorApi()
  const documentsApi = useDocumentsApi()
  const organizationApi = useOrganizationApi()
  const organizationsApi = useOrganizationsApi()
  const notificationsApi = useNotificationsApi()

  const projectsApi = useProjectsApi()

  const monitoringApi = useMonitoringApi()

  const npaApi = useNpaApi()

  const draftApi = useDraftApi()

  const processesApi = useProcessesApi()

  const authorizedOrganizationsApi = useAuthorizedOrganizationsApi()

  const dictionariesApi = useDictionariesApi()
  const ESIAApi = useESIAApi()

  const referenceBooksApi = useReferenceBooksApi()

  const procurationsApi = useProcurationsApi()

  const webFormApi = useWebFormApi()

  const propertiesApi = usePropertiesApi()

  const headerLetterPartsApi = useHeaderLetterPartsApi()

  return (
    <APIContext.Provider
      value={{
        innsApi,
        dfosApi,
        operatorApi,
        documentsApi,
        organizationApi,
        organizationsApi,
        notificationsApi,
        projectsApi,
        monitoringApi,
        npaApi,
        draftApi,
        processesApi,
        authorizedOrganizationsApi,
        dictionariesApi,
        ESIAApi,
        referenceBooksApi,
        procurationsApi,
        webFormApi,
        propertiesApi,
        headerLetterPartsApi,
      }}
    >
      {children}
    </APIContext.Provider>
  )
}

export const useAPIContext = () => {
  return useContext(APIContext)
}
