import { ErrorDescriptionTexts, ErrorHeadersTexts } from '@components/Forms/LoginForm/constants'
import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'

const mapOfESIARedirect = {
  ESIA_ORGANIZATIONS: 'ESIA_ORGANIZATIONS',
  ESIA_LOGIN: 'ESIA_LOGIN',
} as const

const organizationReducerActionMap = {
  SETUP_ORGANIZATIONS: 'SETUP_ORGANIZATIONS',
  SETUP_LOADING: 'SETUP_LOADING',
  SETUP_REDIRECT_LOADING: 'SETUP_REDIRECT_LOADING',
  SETUP_ERROR: 'SETUP_ERROR',
  SETUP_ESIA_ERROR: 'SETUP_ESIA_ERROR',
  SETUP_REGISTRATION_ERROR: 'SETUP_REGISTRATION_ERROR',
  SETUP_ARGUMENT_ERROR: 'SETUP_ARGUMENT_ERROR',
  SETUP_NATIVE_ERROR: 'SETUP_NATIVE_ERROR',
} as const

// Пользователь ЕСИА не дал разрешения на использование данных в ГИС "Капиталовложения"
const ESIA_ERROR_DESCRIPTION_TYPE = 'ESIA-007004'

const ESIAErrorsTexts = {
  access_denied: {
    header: ErrorHeadersTexts.accessIdDenied,
    body: ErrorDescriptionTexts.userESIANotGivePermission,
  },
  temporarily_unavailable: {
    header: ErrorHeadersTexts.accessIdDenied,
    body: ErrorDescriptionTexts.temporarilyESIAUnavailable,
  },
} as const

const headerTexts = {
  chooseOrganization: 'Выберите организацию',
  processRedirect: 'Выполняется редирект',
} as const

const descriptionTexts = {
  multipleOrganizations:
    'С вашей учетной записью ЕСИА связаны аккаунты нескольких организаций. Выберите, под каким аккаунтом войти в систему',
  plsWait: 'Пожалуйста, подождите',
} as const

const ESIARedirectTypes: string[] = [
  mapOfESIARedirect.ESIA_ORGANIZATIONS,
  mapOfESIARedirect.ESIA_LOGIN,
]

const ESIAComponentInfo: ComponentInfo = {
  componentName: 'LoginByESIA',
  moduleName: 'LoginForm',
  componentType: 'authByESIA',
}

const ESIAOrganizationComponentInfo: ComponentInfo = {
  componentName: 'LoginByESIA',
  moduleName: 'LoginForm',
  componentType: 'getESIAOrganizations',
}

const ESIAReducerComponentInfo: ComponentInfo = {
  componentName: 'organizationsReducer',
  moduleName: 'LoginByESIA',
  componentType: 'reducer',
}

export {
  descriptionTexts,
  ESIA_ERROR_DESCRIPTION_TYPE,
  ESIAComponentInfo,
  ESIAErrorsTexts,
  ESIAOrganizationComponentInfo,
  ESIARedirectTypes,
  ESIAReducerComponentInfo,
  headerTexts,
  mapOfESIARedirect,
  organizationReducerActionMap,
}
