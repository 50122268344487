export const HeaderTextByRevokeAction = {
  REVOKE_SZPK: 'Вы действительно хотите отозвать заявление?',
  REVOKE_EXTRA: 'Вы действительно хотите отозвать заявление?',
  REVOKE_MONITORING_REPORT_SZPK: 'Вы действительно хотите отозвать отчет об исполнении?',
  REVOKE_TERMINATE: 'Вы действительно хотите отозвать документы о расторжении?',
  REVOKE_OTHER_DONATIONS: 'Вы действительно хотите отозвать сведения об иных мерах поддержки?',
  REVOKE_COMMON: 'Вы действительно хотите отозвать поданные документы?',
  REVOKE_MUNICIPAL_ACCESSION: 'Вы действительно хотите отозвать заявление на доп. соглашение?',
  REVOKE_CLAIM_TAX_REFUND:
    'Вы действительно хотите отозвать заявление на предоставление меры поддержки?',
}

export const HeaderTextByRevokeClaimSubsidyPermitAction = {
  FEDERAL_REVOKE_CLAIM_SUBSIDY_PERMIT:
    'Вы действительно хотите отозвать заявление на предоставление субсидии?',
  REGIONAL_REVOKE_CLAIM_SUBSIDY_PERMIT:
    'Вы действительно хотите отозвать заявление на предоставление субсидии?',
}

export const BodyTextByRevokeAction = {
  REVOKE_SZPK:
    'После отзыва рассмотрение заявления будет прекращено. На основе отозванного заявления Вы сможете создать новый проект и отправить его на согласование.',
  REVOKE_EXTRA: 'После отзыва рассмотрение дополнительного соглашения будет прекращено.',
  REVOKE_MONITORING_REPORT_SZPK:
    'После отзыва рассмотрение отчета будет прекращено. На основе поданных данных Вы сможете создать новый отчет и отправить его на рассмотрение до 1 февраля включительно.',
  REVOKE_TERMINATE: 'После отзыва процесс расторжения соглашения будет прекращен.',
  REVOKE_OTHER_DONATIONS: 'Документ перестанет быть виден другим участникам соглашения.',
  REVOKE_COMMON: 'После отзыва рассмотрение поданных документов будет прекращено.',
  REVOKE_MUNICIPAL_ACCESSION:
    'После отзыва рассмотрение дополнительного соглашения будет прекращено, а муниципальное образование исключено из участников соглашения.\nЕсли заявление о заключении СЗПК уже было подано, то в него необходимо внести изменения (так как в нём указано МО, которое исключено), для этого перейдите в «Заявление и соглашение» и выполните действие «Внести изменения».',
  REVOKE_CLAIM_TAX_REFUND: 'Подать повторно заявление возможно только до 1 апреля включительно.',
}

export const BodyTextByRevokeClaimSubsidyPermitAction = {
  FEDERAL_REVOKE_CLAIM_SUBSIDY_PERMIT:
    'Подать повторно заявление возможно только до 1 апреля включительно.',
  REGIONAL_REVOKE_CLAIM_SUBSIDY_PERMIT: 'После отзыва рассмотрение заявления будет прекращено.',
}

export const ButtonTextByRevokeAction = {
  REVOKE_SZPK: 'Отозвать заявление',
  REVOKE_EXTRA: 'Отозвать',
  REVOKE_MONITORING_REPORT_SZPK: 'Отозвать отчет',
  REVOKE_TERMINATE: 'Отозвать',
  REVOKE_CLAIM_SUBSIDY_PERMIT: 'Отозвать',
  REVOKE_OTHER_DONATIONS: 'Отозвать',
  REVOKE_COMMON: 'Отозвать',
  REVOKE_MUNICIPAL_ACCESSION: 'Отозвать',
} as const
