export const Paths = {
  Home: '/',
  Acts: '/acts',
  Login: '/login',
  Registration: '/registration',
  Users: '/users',
  Audits: '/audits',
  Support: '/support',
  //Обратная совместимость с ссылками, т.к раньше проекты были по этому урлу
  Statements: '/statements',
  Projects: '/projects',
  ProjectPage: '/projects/:projectId',
  Settings: '/settings',
  Agreements: '/agreements',
  SchemeSZPK: '/scheme-szpk',
  Organizations: '/organizations',
  AuthorizedOrganizations: '/authorizedOrganizations',
  TechnicalWork: '/technical-work',
  AuthorizedList: '/authorized-list',
  Rating: '/rating',
  Politics: '/politics',
  KnowledgeBase: '/knowledgeBase',
  Organization: '/organization',
  EmailApproving: '/email-approving',
  Reports: '/reports',
  RegistryNpa: '/registry-npa',
  ReferenceBooks: '/referenceBooks',
  DictionaryPage: '/referenceBooks/:dictionaryName',
  SystemVariablesPage: '/systemVariables',
} as const

export const AllowedPathsNew = Object.freeze({
  Operator: [Paths.Users, Paths.Audits, Paths.SystemVariablesPage],
  Investor: [Paths.Projects, Paths.Settings, Paths.Organization, Paths.EmailApproving],
  UO: [
    Paths.Projects,
    Paths.Statements,
    Paths.Organization,
    Paths.RegistryNpa,
    Paths.EmailApproving,
  ],
  OIV: [
    Paths.Projects,
    Paths.Statements,
    Paths.Organization,
    Paths.Reports,
    Paths.RegistryNpa,
    Paths.EmailApproving,
  ],
  OMSU: [
    Paths.Projects,
    Paths.Statements,
    Paths.Organization,
    Paths.Reports,
    Paths.RegistryNpa,
    Paths.EmailApproving,
  ],
  MER: [
    Paths.Projects,
    Paths.Statements,
    Paths.Organization,
    Paths.Reports,
    Paths.RegistryNpa,
    Paths.EmailApproving,
    Paths.AuthorizedOrganizations,
    Paths.ReferenceBooks,
  ],
  FK: [
    Paths.Projects,
    Paths.Statements,
    Paths.Organization,
    Paths.RegistryNpa,
    Paths.EmailApproving,
  ],
})
