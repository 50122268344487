import React from 'react'
import { useFormContext } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { agreementStabilizationNegotiationsBlockValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/const'
import AgreementStabilizationNegotiationsMunicipalityForm from '@components/Forms/АgreementStabilizationNegotiationsForm/Forms/3/MunicipalityForm'
import { AgreementStabilizationNegotiationsMunicipalityPathName } from '@components/Forms/АgreementStabilizationNegotiationsForm/Forms/3/types'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import { AgreementStabilizationNegotiationsFieldArrayControlUpdateWatcher } from '@components/Forms/АgreementStabilizationNegotiationsForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'

const Third = () => {
  const formInstance = useFormContext<AgreementStabilizationNegotiationsFormValues>()

  const { fields: municipalities } = useFieldArraySubscribableControl<
    AgreementStabilizationNegotiationsFormValues,
    AgreementStabilizationNegotiationsMunicipalityPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: agreementStabilizationNegotiationsBlockValues['3'].municipalities,
    keyName: 'keyNameId',
    watcher: AgreementStabilizationNegotiationsFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      {municipalities.map((municipality, index) => (
        <Row key={municipality.id}>
          <AgreementStabilizationNegotiationsMunicipalityForm
            formInstance={formInstance}
            formName={
              `${agreementStabilizationNegotiationsBlockValues['3'].municipalities}.${index}` as const
            }
          />
          {index !== municipalities.length - 1 && <Border />}
        </Row>
      ))}
    </Container>
  )
}

export default Third
