import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu, NestedMapOfMenu } from '@components/DocumentFormComponents/types'

const informationOfExecutionConditionsSZPKMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Организация, реализующая проект',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'О проекте',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '3': {
    id: '3',
    path: '3',
    title: 'Информация о реализации этапов',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '4': {
    id: '4',
    path: '4',
    title:
      'Кредиты и займы, купонный доход, облигационные займы, затраты по которым понесены при реализации инвестиционного проекта',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '5': {
    id: '5',
    path: '5',
    title: 'Объекты жилищного строительства, расположенные на территориях военных городков',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '6': {
    id: '6',
    path: '6',
    title:
      'Документально подтвержденные и фактические понесенные расходы, возникшие вследствие нарушения условий стабилизационной оговорки',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '7': {
    id: '7',
    path: '7',
    title: 'Информация о возмещении затрат',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '8': {
    id: '8',
    path: '8',
    title: 'Объем уплаченных налогов и таможенных платежей',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '9': {
    id: '9',
    path: '9',
    title: 'Информация об осуществленных мерах государственной поддержки',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '10': {
    id: '10',
    path: '10',
    title: 'Информация о наличии оснований для изменения или расторжения СЗПК',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
}

const getInformationOfExecutionConditionsSZPKMenuByStatus = () => {
  const informationOfExecutionConditionsSZPKMenuState = [
    informationOfExecutionConditionsSZPKMenuHashMap['1'],
    informationOfExecutionConditionsSZPKMenuHashMap['2'],
    informationOfExecutionConditionsSZPKMenuHashMap['3'],
    informationOfExecutionConditionsSZPKMenuHashMap['4'],
    informationOfExecutionConditionsSZPKMenuHashMap['5'],
    informationOfExecutionConditionsSZPKMenuHashMap['6'],
    informationOfExecutionConditionsSZPKMenuHashMap['7'],
    informationOfExecutionConditionsSZPKMenuHashMap['8'],
    informationOfExecutionConditionsSZPKMenuHashMap['9'],
  ]
  const initialAAgreementOnCreationOtherEstateObjectsMenuState =
    informationOfExecutionConditionsSZPKMenuState.reduce<NestedMapOfMenu[]>(
      (previousValue, currentValue) => [...previousValue, currentValue],
      [],
    )

  const mapOfMenu = informationOfExecutionConditionsSZPKMenuState.map(
    (menu): MapOfMenu => ({
      id: menu.id,
      title: menu.title,
    }),
  )

  return {
    informationOfExecutionConditionsSZPKMenuHashMap,
    initialAAgreementOnCreationOtherEstateObjectsMenuState,
    mapOfMenu,
  }
}

export { getInformationOfExecutionConditionsSZPKMenuByStatus }
