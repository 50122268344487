import { ChipBaseProps } from '@components/Chip/Base'
import { objOfDateFormats } from '@constants/dateFormats'
import { NewDfosType } from '@constants/types'
import { getProjectStatus } from '@hooks/new/statuses/helpers'
import { useProjects } from '@hooks/new/swr/useProjects'
import { keyForUseProjects } from '@routes/Projects/helpers'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { IProjectFromAllProjects } from '@services/Projects/Project.entity'

export const ProjectStatuses = {
  REGISTERED: 'Заключено',
  TERMINATING: 'На расторжении',
  TERMINATED: 'Расторгнуто',
  REJECTED: 'Отказано',
  STOPPED: 'Отказано',
  INITIATOR_REVOKED: 'Отозвано',

  _FALLBACK: 'В работе',
} as const

export type SZPKStagesWithAvailableStatusText = keyof typeof ProjectStatuses

export interface ProjectStatus {
  status: typeof ProjectStatuses[SZPKStagesWithAvailableStatusText]
  additionalText?: string
  chipProps: Pick<ChipBaseProps, 'variant' | 'priority'>
}

export type StatusById = Record<IProjectFromAllProjects['id'], ProjectStatus>

export const useProjectStatusesOnTheProjectsPage = () => {
  const { projects } = useProjects({
    key: keyForUseProjects,
  })

  if (!projects) return {}

  // Возвращаем объект, в котором ключом является id проекта, а значение - его статус
  return projects.reduce<StatusById>(
    (statuses, { szpkRegisteredDate, szpkRegisteredNumber, dfos, id }) => {
      const SZPKDfo = dfos.find((dfo) => dfo.type === NewDfosType.SZPK) // Судя по постановке, такое дфо есть 100%, поэтому использую as string

      const formattedDate = szpkRegisteredDate
        ? DayjsService.dayjs(szpkRegisteredDate).format(objOfDateFormats.formatWithFullMonth)
        : ''

      statuses[id] = getProjectStatus({
        szpkRegisteredNumber,
        szpkRegisteredDate: formattedDate,
        stage: SZPKDfo?.stage as SZPKStagesWithAvailableStatusText,
      })

      return statuses
    },
    {},
  )
}
