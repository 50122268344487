import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  IConstructionExploitation,
  ProjectSZPKFormValues,
} from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const eExploitationsProjectSZPKValidationMap: FormValuesValidationSection<
  keyof IConstructionExploitation,
  ProjectSZPKFormValues
> = {
  objectName: {
    required: defaultRHFValidation.required,
  },
  objectCommissioningDate: {
    required: defaultRHFValidation.required,
  },
  objectPermissionCommissionNumber: {
    required: defaultRHFValidation.required,
  },
}

export { eExploitationsProjectSZPKValidationMap }
