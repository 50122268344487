import React, { forwardRef, ReactNode } from 'react'
import { PopupProps } from 'react-popup-manager'

import Drawer, { DrawerComponentProps } from '@components/Drawer'
import { ButtonProps } from '@components/NewDesign/Button/types'
import IconButton from '@components/NewDesign/IconButton'
import BaseModalActions from '@components/NewDesign/Modal/Base/Actions'
import Typography from '@components/NewDesign/Typography'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import { isNotEmptyString, isNullOrUndefined } from '@helpers/checkTypes'
import ModalCloseIcon from '@icons/ModalCloseIcon.svg'
import cn from 'classnames'

import { Tooltip } from '../Tooltip'
import { useManualTooltipControl } from '../Tooltip/utils'

import styles from './Sidebar.module.scss'

export type SidebarComponentProps = DrawerComponentProps & {
  title?: string
  subtitle?: string
  hideCloseIcon?: string
  headerAddons?: ReactNode
  contentClassName?: string
  drawerClassName?: string
  headerClassName?: string
  bottomSidebarClassName?: string
  actions?: ButtonProps[] | ReactNode
}

type SidebarProps = PopupProps &
  SidebarComponentProps & {
    closeButtonDataTestId?: string
  }

const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(
  (
    {
      isOpen,
      title,
      subtitle,
      hideCloseIcon,
      headerAddons,
      children,
      contentClassName,
      drawerClassName,
      headerClassName,
      bottomSidebarClassName,
      actions,
      closeButtonDataTestId,
      onClose,
      ...drawerProps
    },
    ref,
  ) => {
    const {
      state: { tooltipIsOpen },
      handlers: { handleOpenTooltip, handleCloseTooltip },
    } = useManualTooltipControl()

    const handleMouseEnter = () => {
      handleOpenTooltip()
    }

    const handleMouseLeave = () => {
      handleCloseTooltip()
    }

    const isOverflowTitle = (title?.length ?? 0) > 250
    const currentTooltipPosition =
      isNullOrUndefined(drawerProps.position) || drawerProps.position === 'right' ? 'left' : 'right'

    const handleClose = () => {
      onClose?.()
    }

    return (
      <Drawer
        ref={ref}
        isOpen={isOpen}
        drawerClassName={cn(styles.sidebar, drawerClassName)}
        onClose={onClose}
        {...drawerProps}
      >
        <header className={cn(styles.sidebar__header, headerClassName)}>
          <div
            className={styles.sidebar__wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Tooltip
              availableHeight
              preventOverflow={false}
              open={isOverflowTitle ? tooltipIsOpen : false}
              position={currentTooltipPosition}
              view="tooltip"
              content={title}
              zIndex={51}
              popoverClassName={styles.tooltip__popper}
            >
              <Typography.Headline
                variant={'headlineH3'}
                className={cn({
                  [styles.title]: isOverflowTitle,
                })}
              >
                {title}
              </Typography.Headline>
            </Tooltip>
            {isNotEmptyString(subtitle) && (
              <TypographyWithTooltip.Body
                variant={'bodyMRegular'}
                className={styles.subtitle}
                color="text-base-secondary"
                tooltipProps={{
                  availableHeight: true,
                  preventOverflow: false,
                  zIndex: 51,
                  position: currentTooltipPosition,
                  popperClassName: styles.tooltip__popper,
                }}
              >
                {subtitle}
              </TypographyWithTooltip.Body>
            )}
          </div>
          <div className={styles.sidebar__actions}>
            {headerAddons}
            {!hideCloseIcon && (
              <IconButton
                dataTestId={closeButtonDataTestId}
                className={styles.sidebar__closeIconButton}
                geometry={'square'}
                view={'basic'}
                size={'m'}
                icon={{
                  src: ModalCloseIcon,
                  noCurrentColorSvgFill: true,
                  size: 'initial',
                  className: styles.sidebar__closeIcon,
                }}
                onClick={onClose}
              />
            )}
          </div>
        </header>
        <div className={cn(styles.sidebar__content, contentClassName)}>{children}</div>
        {actions && (
          <div className={cn(styles.sidebar__bottom, bottomSidebarClassName)}>
            {Array.isArray(actions) ? (
              <BaseModalActions actions={actions} onClose={handleClose} />
            ) : (
              actions
            )}
          </div>
        )}
      </Drawer>
    )
  },
)

export default Sidebar
