import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfChangeDocumentsBlockValues } from '@components/Forms/NotificationOfChangeDocumentsForm/const'
import CharacteristicOfDocument from '@components/Forms/NotificationOfChangeDocumentsForm/Forms/2/AdditionalForms/CharacteristicOfDocument'
import { secondSectionValidationMap } from '@components/Forms/NotificationOfChangeDocumentsForm/Forms/2/validation'
import { useNotificationOfChangeDocumentsManager } from '@components/Forms/NotificationOfChangeDocumentsForm/Manager'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'
import { NotificationOfChangeDocumentsFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfChangeDocumentsForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Second = () => {
  const formInstance = useFormContext<NotificationOfChangeDocumentsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfChangeDocumentsManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NotificationOfChangeDocumentsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={4}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              title={
                'Пункт Правил, на основании которого осуществляется изменение проекта СЗПК и прилагаемых документов'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfChangeDocumentsBlockValues[2].ruleLinkStatementChanges,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationOfChangeDocumentsBlockValues[2].ruleLinkStatementChanges,
                    rules: secondSectionValidationMap.ruleLinkStatementChanges,
                    inputProps: {
                      caption: 'рекомендация:  34',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfChangeDocumentsBlockValues[2].ruleLinkStatementChanges,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfChangeDocumentsBlockValues[2].ruleLinkStatementChanges,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
      <CharacteristicOfDocument
        formName={notificationOfChangeDocumentsBlockValues[2].characteristicOfDocuments}
        formInstance={formInstance}
      />
    </Container>
  )
}

export default Second
