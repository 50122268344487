import React, { useCallback } from 'react'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import {
  FieldsControlUpdateWatcher,
  SubscriberFieldsNotifyProps,
} from '@components/DocumentFormComponents/watcher'
import usePropertiesApi, { GetFormErrorsData } from '@context/APIContext/hooks/usePropertiesApi'
import { isArray, isAxiosError } from '@helpers/checkTypes'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'

export const isCheckFormViolationsError = (error: unknown): error is ICheckFormViolationsError => {
  return isArray(error) && error.every((error) => 'violations' in error && 'id' in error)
}

type ICheckFormViolationsError = GetFormErrorsData

export interface UseFormSubmitInManager {
  formId: string
  watcher: FieldsControlUpdateWatcher
  onChangeBlockValidation: (newState: boolean) => void
}

/**
 * Используется в Manager компоненте
 */
const useFormSubmitInManager = ({
  formId,
  watcher,
  onChangeBlockValidation,
}: UseFormSubmitInManager) => {
  const { getFormErrors } = usePropertiesApi()

  const handleCheckForm = useCallback(async () => {
    if (!formId) return

    try {
      await getFormErrors({ formId })
    } catch (e) {
      if (!isAxiosError(e)) throw e

      if (e.response && isCheckFormViolationsError(e.response.data) && e.response.data.length) {
        const parentErrorsToApply: SubscriberFieldsNotifyProps[] = []
        const childrenErrorsToApply: SubscriberFieldsNotifyProps[] = []

        e.response.data.forEach((error) => {
          const valueToApply = {
            updateError: error,
          }

          if (error.parentId && !error.id) {
            parentErrorsToApply.push({
              id: error.parentId,
              value: valueToApply,
            })
          }

          if (error.id) {
            childrenErrorsToApply.push({
              id: error.id,
              value: valueToApply,
            })
          }
        })

        watcher.notifyAllSubscribers([...parentErrorsToApply, ...childrenErrorsToApply])

        throw e
      }

      handleErrorWithPopup(e, true)
      throw e
    } finally {
      onChangeBlockValidation(true)
    }
  }, [formId, watcher, onChangeBlockValidation, getFormErrors])

  return {
    handleCheckForm,
  }
}

/**
 * Используется в Statement компоненте
 */
const useFormSubmitInStatement = () => {
  const handleRejectModalVerify = () => {
    return toast(
      <Alert transparent variant="warning">
        Проверка может быть выполнена только после применения изменений
      </Alert>,
    )
  }

  const handleSubmitForm = (onSubmit: VoidFunction) => async (e) => {
    e.preventDefault()
    e.stopPropagation()

    onSubmit()
  }

  return {
    handleRejectModalVerify,
    handleSubmitForm,
  }
}

export { useFormSubmitInManager, useFormSubmitInStatement }
