import { FC, MutableRefObject, ReactNode } from 'react'
import { PopupProps } from 'react-popup-manager'

import IconButton from '@components/IconButton'
import TextWithCopy from '@components/TextWithCopy'
import { Dialog } from '@headlessui/react'
import useScrollable from '@hooks/useScrollable'
import closeIcon from '@icons/navigation/close.svg'
import cn from 'classnames'

import styles from './Modal.module.scss'

interface ModalProps extends PopupProps {
  size?: 'm' | 'l'
  title?: ReactNode
  className?: string
  description?: string
  linkWithCopyAfterDescription?: string
  descriptionClassname?: string
  windowClassName?: string
  position?: 'top' | 'center' | 'scrollable' | 'right'
  isCloseModalIcon?: boolean
  disabled?: boolean
  initialFocus?: MutableRefObject<HTMLElement | null>
}

const Modal: FC<ModalProps> = ({
  size = 'm',
  title,
  isOpen,
  children,
  disabled = false,
  position = 'top',
  className,
  description,
  windowClassName,
  descriptionClassname,
  isCloseModalIcon = true,
  linkWithCopyAfterDescription,
  onClose,
  initialFocus,
}) => {
  const { isScrollable } = useScrollable()

  const handleClose = () => {
    if (disabled) return
    onClose?.()
  }

  return (
    <Dialog
      className="relative z-50"
      open={isOpen}
      initialFocus={initialFocus}
      onClose={handleClose}
    >
      <div className="fixed inset-0 bg-special-modal-overlay" aria-hidden="true" />
      <div
        className={cn(
          styles.modal,
          {
            [styles['modal--scrollable']]: isScrollable,
            [styles[`modal--${position}`]]: position,
          },
          className,
        )}
      >
        <Dialog.Panel
          className={cn(styles.window, windowClassName, {
            [styles[`window--${size}`]]: size,
          })}
        >
          {(title || isCloseModalIcon) && (
            <div
              className={cn(styles.header, {
                'justify-end': !title,
                'justify-between': title,
                space: description,
              })}
            >
              {typeof title === 'string' && (
                <Dialog.Title as="h3" className={styles.title}>
                  {title}
                </Dialog.Title>
              )}

              {typeof title !== 'string' && title}

              {isCloseModalIcon && (
                <IconButton
                  size="m"
                  icon={closeIcon}
                  priority="secondary"
                  className={styles.close}
                  onClick={onClose}
                />
              )}
            </div>
          )}
          {description && (
            <Dialog.Description
              as="span"
              dangerouslySetInnerHTML={{ __html: description }}
              className={cn(styles.description, descriptionClassname)}
            />
          )}
          {linkWithCopyAfterDescription && (
            <span>
              <TextWithCopy value={linkWithCopyAfterDescription}>
                <span className={styles.copiedLink}>{linkWithCopyAfterDescription}</span>
              </TextWithCopy>
            </span>
          )}
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Modal
