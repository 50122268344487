import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'

interface ParametersOfCostRecoveryApplicationFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
}

const ParametersOfCostRecoveryApplicationForms: ParametersOfCostRecoveryApplicationFormsProps =
  () => null

ParametersOfCostRecoveryApplicationForms.First = First
ParametersOfCostRecoveryApplicationForms.Second = Second
ParametersOfCostRecoveryApplicationForms.Third = Third
ParametersOfCostRecoveryApplicationForms.Fourth = Fourth
ParametersOfCostRecoveryApplicationForms.Fifth = Fifth


export default ParametersOfCostRecoveryApplicationForms
