import React, { FC, ReactNode, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import { isUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './Label.module.scss'

const TRANSITION_TIMEOUT = 200

const transitionLabelAddonsClassNames = {
  enter: styles['transition__fade-enter'],
  exit: styles['transition__fade-exit'],
  enterActive: styles['transition__fade-enter--active'],
  exitActive: styles['transition__fade-exit--active'],
}

interface FormControlLabelProps {
  className?: string
  textClassName?: string
  wrapperClassName?: string
  leftAddonClassName?: string
  rightAddonClassName?: string
  leftAddons?: ReactNode
  rightAddons?: ReactNode
}

const FormControlLabel: FC<FormControlLabelProps> = ({
  children,
  leftAddons,
  rightAddons,
  className,
  textClassName,
  wrapperClassName,
  leftAddonClassName,
  rightAddonClassName,
}) => {
  const labelLeftAddonNodeRef = useRef<HTMLDivElement | null>(null)
  const labelRightAddonNodeRef = useRef<HTMLDivElement | null>(null)

  const labelLeftAddonsRenderCondition = !isUndefined(leftAddons)
  const labelRightAddonsRenderCondition = !isUndefined(rightAddons)

  return (
    <div className={cn(styles['label-wrapper'], wrapperClassName)}>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={labelLeftAddonsRenderCondition}
        timeout={TRANSITION_TIMEOUT}
        nodeRef={labelRightAddonNodeRef}
        classNames={transitionLabelAddonsClassNames}
      >
        <div ref={labelLeftAddonNodeRef} className={cn(styles['label-addon'], leftAddonClassName)}>
          {leftAddons}
        </div>
      </CSSTransition>
      <div
        className={cn(
          styles.label,
          {
            [styles['label--withLeftAddons']]: labelLeftAddonsRenderCondition,
            [styles['label--withRightAddons']]: labelRightAddonsRenderCondition,
          },
          className,
        )}
      >
        <TypographyWithTooltip.Caption
          variant={'captionMMedium'}
          as={'span'}
          className={cn(styles['label-inner'], textClassName)}
          tooltipProps={{
            zIndex: 51,
            position: 'top',
            fallbackPlacements: ['top', 'right', 'left', 'bottom'],
            targetClassName: styles.tooltip__target,
            popoverClassName: styles.tooltip__popover,
          }}
        >
          {children}
        </TypographyWithTooltip.Caption>
      </div>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={labelRightAddonsRenderCondition}
        timeout={TRANSITION_TIMEOUT}
        nodeRef={labelRightAddonNodeRef}
        classNames={transitionLabelAddonsClassNames}
      >
        <div
          ref={labelRightAddonNodeRef}
          className={cn(styles['label-addon'], rightAddonClassName)}
        >
          {rightAddons}
        </div>
      </CSSTransition>
    </div>
  )
}

export default FormControlLabel
