import { generatePropertiesObjectForNotificationOfIdentifiedViolationsForm } from '@components/Forms/NotificationOfIdentifiedViolationsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNotificationOfIdentifiedViolationsForm } from '@components/Forms/NotificationOfIdentifiedViolationsForm/adapters/RHF.adapter'
import { NotificationOfIdentifiedViolationsFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/types'

const useNotificationOfIdentifiedViolationsAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfIdentifiedViolationsFormValues =>
    generateRHFObjectForNotificationOfIdentifiedViolationsForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNotificationOfIdentifiedViolationsForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNotificationOfIdentifiedViolationsAdapters }
