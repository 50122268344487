import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import {
  defaultRHFValidation,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const requiredSpecificIntValidate = {
  required: defaultRHFValidation.required,
  validate: (value) => {
    if (!isString(value)) return

    return specificIntValidate(value)
  },
}

const requiredStandartAmountValidate = {
  required: defaultRHFValidation.required,
  validate: (value) => {
    if (!isString(value)) return

    return standardAmountValidate(value)
  },
}

export const thirdSectionValidationMap: FormValuesValidationSection<
  keyof (DataOnFulfillmentOfTermsSZPKFormValues['3'] &
    DataOnFulfillmentOfTermsSZPKFormValues['4'] &
    DataOnFulfillmentOfTermsSZPKFormValues['5'] &
    DataOnFulfillmentOfTermsSZPKFormValues['6'] &
    DataOnFulfillmentOfTermsSZPKFormValues['7'] &
    DataOnFulfillmentOfTermsSZPKFormValues['8']),
  DataOnFulfillmentOfTermsSZPKFormValues
> = {
  federalSzpkCountRegionalReportPlanCorrect: requiredSpecificIntValidate,
  federalSzpkCountRegionalReportFactCorrect: requiredSpecificIntValidate,
  regionalSzpkCountRegionalReportPlanCorrect: requiredSpecificIntValidate,
  regionalSzpkCountRegionalReportFactCorrect: requiredSpecificIntValidate,
  federalSzpkPeriodCountRegionalReportPlanCorrect: requiredSpecificIntValidate,
  federalSzpkPeriodCountRegionalReportFactCorrect: requiredSpecificIntValidate,
  regionalSzpkPeriodCountRegionalReportPlanCorrect: requiredSpecificIntValidate,
  regionalSzpkPeriodCountRegionalReportFactCorrect: requiredSpecificIntValidate,
  federalNewWorkPlacesRegionalReportPlanCorrect: requiredSpecificIntValidate,
  federalNewWorkPlacesRegionalReportFactCorrect: requiredSpecificIntValidate,
  regionalNewWorkPlacesRegionalReportPlanCorrect: requiredSpecificIntValidate,
  regionalNewWorkPlacesRegionalReportFactCorrect: requiredSpecificIntValidate,

  federalTotalCapitalRegionalReportPlanCorrect: requiredStandartAmountValidate,
  federalTotalCapitalRegionalReportFactCorrect: requiredStandartAmountValidate,
  regionalTotalCapitalRegionalReportPlanCorrect: requiredStandartAmountValidate,
  regionalTotalCapitalRegionalReportFactCorrect: requiredStandartAmountValidate,
  federalInvestorCapitalRegionalReportPlanCorrect: requiredStandartAmountValidate,
  federalInvestorCapitalRegionalReportFactCorrect: requiredStandartAmountValidate,
  regionalCompensationValueRegionalReportPlanCorrect: requiredStandartAmountValidate,
  regionalCompensationValueRegionalReportFactCorrect: requiredStandartAmountValidate,
  regionalInvestorCapitalRegionalReportPlanCorrect: requiredStandartAmountValidate,
  regionalInvestorCapitalRegionalReportFactCorrect: requiredStandartAmountValidate,
  federalCompensationValueRegionalReportPlanCorrect: requiredStandartAmountValidate,
  federalCompensationValueRegionalReportFactCorrect: requiredStandartAmountValidate,
}
