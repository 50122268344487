import { FC } from 'react'
import { Path, useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group, { GroupProps } from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Stack from '@components/ReactBootstrap/Stack'

interface SzpkRegionalReportGroupProps {
  groupTitle: string
  groupProps?: Omit<GroupProps, 'children'>

  countFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  totalCapitalFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  investorCapitalFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  reasonCountFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
}

const SzpkRegionalReportGroup: FC<SzpkRegionalReportGroupProps> = ({
  groupTitle,
  groupProps,
  countFormName,
  totalCapitalFormName,
  investorCapitalFormName,
  reasonCountFormName,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state,
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { blockViewIsValidating, editMode } = state

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  return (
    <Group title={groupTitle} {...groupProps}>
      <Stack direction={'vertical'} gap={3}>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Количество соглашений'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: countFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: countFormName,
                inputProps: {
                  disabled: true,
                  suffix: '',
                  integersOnly: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Объем капитальных вложений'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: totalCapitalFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: totalCapitalFormName,
                inputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Объем капиталовложений'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: investorCapitalFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: investorCapitalFormName,
                inputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Основание для изменения или расторжения соглашения'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: reasonCountFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: reasonCountFormName,
                inputProps: {
                  disabled: true,
                  suffix: '',
                  integersOnly: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
      </Stack>
    </Group>
  )
}

export default SzpkRegionalReportGroup
