import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { defaultStatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/const'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'
import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForStatementConsiderationInvestmentAuthorizedForm = (
  objectFromPropertyState: Record<string, any>,
): StatementConsiderationInvestmentAuthorizedFormValues => {
  const preparedInvestorHeaderEmail = ((): MultipleInputValue[] | null => {
    if (
      !isArray(objectFromPropertyState.docSignerEmails?.value) ||
      !objectFromPropertyState.docSignerEmails.value.length
    )
      return null

    return objectFromPropertyState.docSignerEmails.value.map((email) => ({
      value: email.propertyId,
      displayValue: email.value,
    }))
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      isProjectCompany:
        objectFromPropertyState.investor?.value.isProjectCompany?.value ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].isProjectCompany,
      isVed:
        objectFromPropertyState.investor?.value.isVed?.value ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].isVed,
      region:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.region ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].region,
      city:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.city ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].city,
      district:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.district ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].district,
      area:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.area ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].area,
      planningStructureElement:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value
          .planningStructureElement ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].planningStructureElement,
      roadNetworkElement:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.roadNetworkElement ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].roadNetworkElement,
      house:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.house ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].roadNetworkElement,
      room:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.room ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].room,
      roomPart:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.roomPart ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].roomPart,
      investorHeaderEmail: preparedInvestorHeaderEmail,
      investorHeaderPhone:
        objectFromPropertyState.docSignerPhone?.value ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].investorHeaderPhone,
      investorHeaderAddPhone:
        objectFromPropertyState.docSignerAddPhone?.value ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['1'].investorHeaderAddPhone,
    },
    '2': {
      projectName:
        objectFromPropertyState?.projectName?.value ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['2'].projectName,
      lawLinkInvestProject:
        objectFromPropertyState?.lawLinkInvestProject?.value ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['2'].lawLinkInvestProject,
      ruleLinkInvestProject:
        objectFromPropertyState?.ruleLinkInvestProject?.value ||
        defaultStatementConsiderationInvestmentAuthorizedFormValues['2'].ruleLinkInvestProject,
    },
  }
}

export { generateRHFObjectForStatementConsiderationInvestmentAuthorizedForm }
