import { FC, memo, useCallback, useMemo } from 'react'
import { usePopupManager } from 'react-popup-manager'

import Icon from '@components/Icon'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import useStatusSignModal from '@components/NewDesignedModals/StatusSignModal/manager'
import {
  loaderModalBodyTexts,
  signModalBodyTexts,
  signModalButtonTexts,
  signModalHeaderTexts,
  successModalBodyTexts,
  successModalHeaderTexts,
} from '@constants/texts'
import signatureIcon from '@icons/Signature.svg'
import cn from 'classnames'

import styles from './SignModal.module.scss'
import type { TSignModalProps } from './types'

export const arrayWordEndDocument: [string, string, string] = [
  ' документа',
  ' документов',
  ' документов',
]

const SignModal: FC<TSignModalProps> = ({
  headerTitle = signModalHeaderTexts.defaultMessage,
  bodyText = signModalBodyTexts.defaultMessage,

  loaderHeaderTitle = '',
  loaderBodyText = loaderModalBodyTexts.defaultMessage,

  successHeaderTitle = successModalHeaderTexts.defaultMessage,
  successBodyText = successModalBodyTexts.defaultMessage,

  isOpen,
  actionType,
  actionId,

  onSuccessSignFiles,
  onClose,

  projectId,
  dfoId,

  signBodyClassname,

  onErrorSignFiles,
}) => {
  const popupManager = usePopupManager()
  const { handleOpenStatusSignModal } = useStatusSignModal()

  const handleSignDocuments = useCallback(() => {
    popupManager.closeAll()

    handleOpenStatusSignModal({
      actionType,
      actionId,

      loaderHeaderTitle,
      loaderBodyText,

      successHeaderTitle,
      successBodyText,

      onSuccessSignFiles,
      onClose,

      projectId,
      dfoId,
      onErrorSignFiles,
    })
  }, [
    actionType,
    actionId,
    dfoId,
    handleOpenStatusSignModal,
    loaderBodyText,
    loaderHeaderTitle,
    onClose,
    onErrorSignFiles,
    onSuccessSignFiles,
    popupManager,
    projectId,
    successBodyText,
    successHeaderTitle,
  ])

  const actions = useMemo(() => {
    return [
      {
        dataTestId: 'SignModal-cancel-button',
        view: 'gray',
        children: signModalButtonTexts.cancelMessage,
        className: cn(styles.SignModal__buttonDefault),
        onClick: onClose,
      },
      {
        dataTestId: 'SignModal-signDocuments-button',
        children: signModalButtonTexts.defaultMessage,
        className: cn(styles.SignModal__buttonDefault, styles['SignModal__buttonDefault--sign']),
        onClick: handleSignDocuments,
      },
    ] as ButtonProps[]
  }, [handleSignDocuments, onClose])

  return (
    <Modal.Action
      hideCloseIcon
      isOpen={isOpen}
      title={''}
      actions={actions}
      dataTestId="SignModal-sign-modal"
      closeButtonDataTestId="SignModal-sign-closeButton"
      simpleModalContainerClassName={styles.SignModal__modalWrapper}
      simpleModalBodyClassName={styles.SignModal__modalBody}
      onClose={onClose}
    >
      <div className={styles.SignModal__icon}>
        <Icon src={signatureIcon} size={'4xl'} />
      </div>
      <Typography.Headline variant="headlineH3" className={styles.SignModal__head}>
        {headerTitle}
      </Typography.Headline>
      <Typography.Body
        variant="bodyMRegular"
        className={cn(styles.SignModal__body, signBodyClassname)}
      >
        {bodyText}
      </Typography.Body>
    </Modal.Action>
  )
}

export default memo(SignModal)
