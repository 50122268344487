import { useEffect, useState } from 'react'

import { FormSingleSelectProps } from '@components/DocumentFormComponents/FormSelect/Single'
import { GroupOfOptions, isOptions, OptionProps } from '@components/NewDesign/Select/model'
import { SingleSelectNewProps } from '@components/NewDesign/Select/SingleSelectNew'
import { compact } from '@helpers/array/compact'
import { isArray, isUndefined } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import cloneDeep from 'clone-deep'

type UserFetchSelectProps = Omit<FormSingleSelectProps, 'selectProps'>

const useFetchSelect = ({
  fetcherProps,
  optionsAdapter,
  transformOptionsData,
}: UserFetchSelectProps) => {
  const [options, setOptions] = useState<SingleSelectNewProps['options']>([])
  const { booleanState: optionsIsLoading, setBooleanState: setOptionsIsLoading } = useBooleanState()

  useEffect(() => {
    if (fetcherProps?.settings?.prefetch && fetcherProps?.lastDataFromFetcher) {
      setOptionsByData(fetcherProps?.lastDataFromFetcher)
    }
  }, [fetcherProps?.lastDataFromFetcher])

  const setOptionsByData = (data: unknown) => {
    let transformedData = cloneDeep(data)

    if (transformOptionsData) {
      transformedData = transformOptionsData(transformedData)
    }

    if (optionsAdapter && isArray(transformedData)) {
      setOptions(compact(transformedData.map(optionsAdapter)) as (GroupOfOptions | OptionProps)[])
      return
    }

    setOptions(isOptions(data) ? data : [])
  }

  const handleGetOptions = async () => {
    if (!fetcherProps?.fetcher) return

    try {
      const data = await fetcherProps.fetcher()

      setOptionsByData(data)
    } catch (e) {
      throw e
    }
  }

  const handleFocusSelect = async () => {
    setOptions([])
    setOptionsIsLoading(true)

    try {
      if (fetcherProps?.systemFlags?.dataIsOld) {
        await handleGetOptions()
        return
      }

      if (optionsAdapter && isArray(fetcherProps?.lastDataFromFetcher)) {
        setOptions(
          (fetcherProps?.lastDataFromFetcher.map(optionsAdapter) as (
            | GroupOfOptions
            | OptionProps
          )[]) || [],
        )
        return
      }

      setOptions(
        isOptions(fetcherProps?.lastDataFromFetcher) && !isUndefined(fetcherProps)
          ? fetcherProps.lastDataFromFetcher
          : [],
      )
    } catch (e) {
    } finally {
      setOptionsIsLoading(false)
    }
  }

  return {
    options,
    optionsIsLoading,
    handleFocusSelect,
  }
}

export { useFetchSelect }
