import React, { FC, memo } from 'react'

import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import NpaSolicitationInclusionForm from '@components/Forms/NpaSolicitationInclusionForm'

const NpaSolicitationInclusionFormLayout: FC<NpaFormLayoutProps> = (props) => {
  return (
    <NpaSolicitationInclusionForm.Manager {...props}>
      <div>
        <NpaSolicitationInclusionForm.Actions />
        <NpaSolicitationInclusionForm.Statement />
      </div>
    </NpaSolicitationInclusionForm.Manager>
  )
}

export default memo(NpaSolicitationInclusionFormLayout)
