import { NotificationId } from '@components/NewNotifications/model'
import { INotification } from '@context/APIContext/hooks/useNotificationsApi'
import { isArray } from '@helpers/checkTypes'

class ListNode<T = INotification> {
  value: T
  next: null | ListNode<T>

  constructor(value: T) {
    this.value = value
    this.next = null
  }
}

export class NotificationsLinkedList {
  head: ListNode | null = null
  tail: ListNode | null = null

  constructor(initValue?: INotification | INotification[]) {
    if (isArray(initValue)) this.addValues(initValue)
    else this.head = this.tail = initValue ? new ListNode(initValue) : null
  }

  recreateList(data: INotification[]) {
    this.head = this.tail = null
    this.addValues(data)
  }

  addValue(value: INotification) {
    const newNode = new ListNode(value)
    if (!this.head) return this.head = this.tail = newNode

    if (this.tail) this.tail = this.tail.next = newNode
    else this.tail = newNode
  }

  addValues(values: INotification[]) {
    values.forEach((value) => this.addValue(value))
  }

  private isItSearchedNotification(id: NotificationId, notification?: INotification | null) {
    return notification?.lastNotification.notificationId === id
  }

  findNode(id: NotificationId, startFrom: ListNode | null = this.head): ListNode | null {
    if (this.isItSearchedNotification(id, startFrom?.value)) return startFrom
    return startFrom?.next ?  this.findNode(id, startFrom?.next) : null
  }

  removeValue(id: NotificationId, startFrom: ListNode | null = this.head): boolean {
    /**
     * Обработка первого элемента
     */
    if (this.isItSearchedNotification(id, startFrom?.value)) {
      if (startFrom?.next) {
        this.head = startFrom.next
      } else {
        this.head = this.tail = null
      }
      return true
    }

    if (!startFrom?.next) return false

    const nextNode = startFrom.next

    if (this.isItSearchedNotification(id, nextNode.value)) {
      startFrom.next = nextNode.next
      if (!nextNode.next) this.tail = startFrom

      return true
    } else return this.removeValue(id, nextNode)
  }

  // Склеиваем новые уведомления с уже имеющимися
  concat(receivedList: NotificationsLinkedList) {
    if (!receivedList.tail?.value) return

    const startOfActualList = this.findNode(receivedList.tail.value.lastNotification.notificationId)

    if (!startOfActualList) return

    receivedList.tail.next = startOfActualList.next

    this.head = receivedList.head
  }

  toArray(startFrom: ListNode | null = this.head): INotification[] {
    if (!startFrom) return []
    return [startFrom.value, ...this.toArray(startFrom.next)]
  }
}
