import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { PetitionTransferRightFormValues } from '@components/Forms/PetitionTransferRightForm/types'

const { getDefaultInvestorFieldState, getDefaultInvestorPartnerFieldStates } = FieldStateHelpers

const mapOfPetitionTransferRightMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Организация, приобретающая права' },
  { id: '3', title: 'Правовые основания передачи прав и обязанностей' },
]

const defaultPetitionTransferRightFormValues: PetitionTransferRightFormValues = {
  '1': {
    ...getDefaultInvestorFieldState(),
    investorFullNameGenitive: '',
    investorInn: '',
    investorOgrn: '',
    investorEgrulAddress: '',
  },
  '2': {
    ...getDefaultInvestorPartnerFieldStates(),
    investorPartnerFullNameDative: '',
    investorPartnerInn: '',
    investorPartnerOgrn: '',
    investorPartnerEgrulAddress: '',
  },
  '3': {
    rightTransferSolicitationReason: '',
  },
}

const petitionTransferRightBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorFullNameGenitive: '1.investorFullNameGenitive',
    investorEgrulAddress: '1.investorEgrulAddress',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
  },
  '2': {
    investorPartnerFullName: '2.investorPartnerFullName',
    investorPartnerInn: '2.investorPartnerInn',
    investorPartnerOgrn: '2.investorPartnerOgrn',
    investorPartnerEgrulAddress: '2.investorPartnerEgrulAddress',
    investorPartnerFullNameDative: '2.investorPartnerFullNameDative',
    investorPartnerHeaderName: '2.investorPartnerHeaderName',
    investorPartnerHeaderNameGenitive: '2.investorPartnerHeaderNameGenitive',
    investorPartnerHeaderPosition: '2.investorPartnerHeaderPosition',
    investorPartnerHeaderPositionGenitive: '2.investorPartnerHeaderPositionGenitive',
    investorPartnerHeaderReasonGenitive: '2.investorPartnerHeaderReasonGenitive',
  },
  '3': {
    rightTransferSolicitationReason: '3.rightTransferSolicitationReason',
  },
} as const

export {
  defaultPetitionTransferRightFormValues,
  mapOfPetitionTransferRightMenu,
  petitionTransferRightBlockValues,
}
