import { useCallback } from 'react'

import { defaultReferenceBooksFiltersValue } from '@components/ReferenceBooks/constants'
import type { ReferenceBooksFiltersFormValues } from '@components/ReferenceBooks/types'
import { useQueryManager } from '@hooks/useQueryManager'

const useGetPersistedFilterValues = () => {
  const { searchParams, queryUtils } = useQueryManager()

  const getInitialFormStateFilters = useCallback((): ReferenceBooksFiltersFormValues => {
    const transitionWasFromLink = Array.from(searchParams).length

    const getFieldByDataLayer = (fieldName: keyof ReferenceBooksFiltersFormValues) => {
      if (transitionWasFromLink) {
        return queryUtils.getQuery(fieldName)
      }

      return null
    }

    const persistedObject: Required<ReferenceBooksFiltersFormValues> = {
      searchString:
        (getFieldByDataLayer('searchString') as string) ||
        defaultReferenceBooksFiltersValue.searchString,
    }

    return {
      searchString: persistedObject.searchString,
    }
  }, [])

  return {
    getInitialFormStateFilters,
  }
}

export { useGetPersistedFilterValues }
