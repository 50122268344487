import React, { FC, memo, useMemo } from 'react'

import { Tooltip, TooltipProps } from '@components/NewDesign/Tooltip'
import { ManualTooltipControlReturn } from '@components/NewDesign/Tooltip/utils'

import styles from './SelectTooltip.module.scss'

interface ManualControlFilterProps {
  tooltipIsOpen: ManualTooltipControlReturn['state']['tooltipIsOpen']
  handleCloseTooltip: ManualTooltipControlReturn['handlers']['handleCloseTooltip']
}

interface SelectTooltipProps {
  filterContent: TooltipProps['content']
  sortContent: TooltipProps['content']
  manualControl: ManualControlFilterProps
  children: TooltipProps['children']
}

const SelectTooltip: FC<SelectTooltipProps> = ({
  manualControl,
  filterContent,
  sortContent,
  children,
}) => {
  const { tooltipIsOpen, handleCloseTooltip } = manualControl

  const selectTooltipContent = useMemo(
    () => (
      <div className={styles.tooltip}>
        {filterContent}
        <div className={styles.tooltip__separator} />
        {sortContent}
      </div>
    ),
    [filterContent, sortContent],
  )

  return (
    <Tooltip
      trigger="click"
      open={tooltipIsOpen}
      content={selectTooltipContent}
      position="bottom-end"
      offset={[0, 4]}
      contentClassName={styles.select__tooltipContent}
      popoverClassName={styles.select__tooltipPopover}
      arrowClassName={styles.select__tooltipArrow}
      onClose={handleCloseTooltip}
    >
      {children}
    </Tooltip>
  )
}

export default memo(SelectTooltip)
