import React, { createContext, FC, memo, ReactNode, useContext, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { OptionProps } from '@components/NewDesign/Select/model'
import { TFiltersModalFormValues } from '@components/RegistryNpa/Header/Filters'
import { useNpaFiltersOld } from '@components/RegistryNpa/hooks/useNpaFiltersOld'
import { useNpaSelectValues } from '@components/Sidebars/NPA/hooks/useNpaSelectValues'
import { IFiltersOfGetNpa, TGetListNpaResponse } from '@context/APIContext/hooks/useNpaApi'
import { useNpaList } from '@hooks/new/swr/useNpaList'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'
import { KeyedMutator } from 'swr'

export interface IFiltersFormValues {
  filters: Omit<IFiltersOfGetNpa, 'level' | 'searchString'> & {
    isFederal?: boolean
    isRegional?: boolean
    isMunicipal?: boolean
  }

  searchString?: string
}

interface NpaContextProps {
  state: {
    filtersInstance: UseFormReturn<IFiltersFormValues> | null

    gridSwrInstance: {
      isNpaListLoading: boolean
      mutate: KeyedMutator<TGetListNpaResponse[]>
      incPage: (
        size: number | ((_size: number) => number),
      ) => Promise<TGetListNpaResponse[] | undefined>
      npaList?: TGetListNpaResponse
      error?: AxiosError<unknown, unknown>
      hasMoreData?: boolean
      onLoadMore: VoidFunction
    } | null

    forSelect: {
      typeOptions: OptionProps[]
      directionOptions: OptionProps[]
      regionOptions: OptionProps[]
      regionWithCodeOptions: OptionProps[]
    }

    debouncedSearchValue: string
  }
  handlers: {
    handleApplyFilters?: (filters: TFiltersModalFormValues) => void
  }
}

interface NpaManagerProps {
  children: ReactNode
}

const NpaContext = createContext<NpaContextProps>({
  state: {
    gridSwrInstance: null,
    filtersInstance: null,

    forSelect: {
      directionOptions: [],
      typeOptions: [],
      regionOptions: [],
      regionWithCodeOptions: [],
    },

    debouncedSearchValue: '',
  },
  handlers: {},
})

const keyMutatorForNpaInfinite = {
  keyMutator: { _key: 'npaList' },
}

const useNpaManager = () => {
  return useContext(NpaContext)
}

const NpaManager: FC<NpaManagerProps> = ({ children }) => {
  const {
    preparedTypesForSelect,
    preparedRegionsForSelect,
    preparedRegionsWithCodesForSelect,
    preparedDirectionsForSelect,
  } = useNpaSelectValues()

  const {
    filtersInstance,
    preparedKeyToSwr,
    currentFilters,
    currentSearchString,
    handleApplyFilters,
    debouncedSearchValue,
  } = useNpaFiltersOld(keyMutatorForNpaInfinite)

  const gridSwrInstance = useNpaList({
    key: preparedKeyToSwr,
    config: {
      revalidateAll: true,
      onError: LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          searchString: currentSearchString,
          filters: currentFilters,
        },
        componentInfo: {
          componentName: 'NpaManager',
          moduleName: 'RegistryNpa',
          componentType: 'manager',
        },
      }),
    },
  })

  const providerValue: NpaContextProps = useMemo(
    () => ({
      state: {
        gridSwrInstance,
        filtersInstance,
        preparedKeyToSwr,
        forSelect: {
          directionOptions: preparedDirectionsForSelect,
          typeOptions: preparedTypesForSelect,
          regionOptions: preparedRegionsForSelect,
          regionWithCodeOptions: preparedRegionsWithCodesForSelect,
        },
        debouncedSearchValue,
      },
      handlers: {
        handleApplyFilters,
      },
    }),
    [
      debouncedSearchValue,
      filtersInstance,
      gridSwrInstance,
      handleApplyFilters,
      preparedDirectionsForSelect,
      preparedKeyToSwr,
      preparedRegionsForSelect,
      preparedRegionsWithCodesForSelect,
      preparedTypesForSelect,
    ],
  )

  return <NpaContext.Provider value={providerValue}>{children}</NpaContext.Provider>
}

export { useNpaManager }

export default memo(NpaManager)
