import { type FC } from 'react'

import { type TooltipProps, Tooltip } from '@components/NewDesign/Tooltip'
import cn from 'classnames'

import styles from './FormTooltip.module.scss'

interface FormTooltipProps extends Omit<TooltipProps, 'position'> {
  position?: 'top' | 'right' | 'left' | 'bottom'
  width?: 's' | 'm' | 'l'
}

const FormTooltip: FC<FormTooltipProps> = ({
  position = 'top',
  fallbackPlacements = ['top', 'bottom', 'right', 'left'],
  width = 'm',
  offset = [0, 10],
  children,
  content,
  contentClassName,
  arrowClassName,
  popoverClassName,
  popperClassName,
  targetClassName,
  ...restProps
}) => {
  return (
    <Tooltip
      {...restProps}
      offset={offset}
      fallbackPlacements={fallbackPlacements}
      position={position}
      contentClassName={cn(styles.tooltip__content, contentClassName)}
      targetClassName={cn(styles.tooltip__target, targetClassName)}
      arrowClassName={cn(styles.tooltip__arrow, arrowClassName)}
      popperClassName={cn(styles.tooltip__popper, popperClassName)}
      content={content}
      popoverClassName={cn(styles.tooltip__popover, popoverClassName, {
        [styles[`tooltip__popover--${width}`]]: width,
      })}
    >
      {children}
    </Tooltip>
  )
}

export type { FormTooltipProps }
export default FormTooltip
