import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { defaultRHFValidation, standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const projectCompensationFieldsValidation: FormValuesValidationSection<
  keyof StatementFormValues['compensation']['projectCompensations'][number],
  StatementFormValues
> = {
  projectCompensationType: {
    required: defaultRHFValidation.required,
  },
  projectCompensationValue: {
    required: defaultRHFValidation.required,
    pattern: defaultRHFValidation.capital,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { projectCompensationFieldsValidation }
