import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForPetitionTransferRightForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: {
        propertyId: objectFromPropertyState?.investorFullNameGenitive?.propertyId,
        type: objectFromPropertyState?.investorFullNameGenitive?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameGenitive?.lastUpdateDt,
      },
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getInvestorPartnerAdapter(objectFromPropertyState),
      investorPartnerFullNameDative: {
        propertyId: objectFromPropertyState?.investorPartnerFullNameDative?.propertyId,
        type: objectFromPropertyState?.investorPartnerFullNameDative?.type,
        lastUpdateDt: objectFromPropertyState?.investorPartnerFullNameDative?.lastUpdateDt,
      },
    },
    '3': {
      rightTransferSolicitationReason: {
        propertyId: objectFromPropertyState.rightTransferSolicitationReason.propertyId,
        type: objectFromPropertyState.rightTransferSolicitationReason.type,
        lastUpdateDt: objectFromPropertyState.rightTransferSolicitationReason.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForPetitionTransferRightForm }
