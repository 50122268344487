import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { parametersOfCostRecoveryApplicationBlockValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const First = () => {
  const formInstance = useFormContext<ParametersOfCostRecoveryApplicationFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getControllerProps, getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['1'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Полное наименование'}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                  tooltipProps: {
                    width: 'm',
                  },
                }}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['1'].investorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['1'].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['1'].investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['1'].investorKpp,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'КПП'}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['1'].investorKpp,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['1']
                  .investorAddressFullEgrulFormat,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Адрес'}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['1']
                    .investorAddressFullEgrulFormat,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default First
