import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import useInput from '@hooks/useInput'
import { IPartClassification } from '@services/NPA/NPA.entity'

import Input from '../Input/Input'
import { Tooltip } from '../Tooltip'
import { useManualTooltipControl } from '../Tooltip/utils'
import Typography from '../Typography'

import styles from './InputSearchTree.module.scss'
import TreeItem from './Item'
import { InputSearchTreeProps, ISearchElementTree } from './types'

type IFullClassification = { partClassifications: IPartClassification[]; value: string }

export const getFullClassification = ({
  partClassifications,
  value,
}: IFullClassification): string => {
  const fullCurrentElement = partClassifications?.find((item) => item.id === value)

  const parentCurrentElement = partClassifications?.find(
    (item) => item.id === fullCurrentElement?.parentId && item.level !== '1',
  )

  return parentCurrentElement?.header
    ? `${parentCurrentElement?.header} ${fullCurrentElement?.header ?? ''}`
    : fullCurrentElement?.header ?? ''
}

const InputSearchTree = forwardRef<HTMLInputElement, InputSearchTreeProps>((props, ref) => {
  const {
    defaultSearchValue,
    options,

    tooltipProps,
    zIndexInputSearchTree,
    inputProps,
    emptyMessage = 'Нет совпадений',
    error,

    onChange,
    onInputChange,
    onBlur,
    onClear,
  } = props

  const { partClassifications } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  const { value: searchValue, setValue: setSearchValue } = useInput('')

  const [tree, setTree] = useState<ISearchElementTree[]>([])

  const [uuid, setUuid] = useState('')

  useEffect(() => {
    setSearchValue('')

    // запоминаем classificationId
    setUuid(defaultSearchValue ?? '')

    if (partClassifications && defaultSearchValue) {
      const descriptionToSearch = getFullClassification({
        partClassifications,
        value: defaultSearchValue,
      })

      setSearchValue(descriptionToSearch)
    }
  }, [defaultSearchValue])

  useEffect(() => {
    if (!tree?.length && options?.length) setTree(options)
  }, [options])

  useEffect(() => {
    const searchTree = treeFromSearch(uuid || searchValue)

    setTree(searchTree)
  }, [searchValue])

  const treeFromSearch = (searchText: string) => {
    if (searchText.length <= 2 || !searchText) return options

    const generateChildren = (treeValues: ISearchElementTree[]) => {
      return treeValues.reduce((acc, curr) => {
        const hasSearchElement =
          curr.value.toLowerCase().includes(searchText.toLowerCase()) ||
          curr.displayValue.toLowerCase().includes(searchText.toLowerCase())

        if (hasSearchElement && !curr.children) {
          acc.push(curr)
        }

        if (hasSearchElement && curr.children) {
          acc.push({ ...curr, children: curr.children })
        }

        if (!hasSearchElement && curr.children) {
          const findCorrectChildren = generateChildren(curr.children)

          if (findCorrectChildren.length) {
            acc.push({ ...curr, children: findCorrectChildren })
          }
        }

        return acc
      }, [] as ISearchElementTree[])
    }

    return generateChildren(options)
  }

  const {
    state: { tooltipIsOpen, targetTooltipRef },
    handlers: { handleOpenTooltip, handleCloseTooltip },
  } = useManualTooltipControl()

  const initialFocusRef = useRef<HTMLDivElement>(null)

  const currentValueOfSelect = useRef<ISearchElementTree | null>(null)

  const handleClear = () => {
    setSearchValue('')
    setTree([])
    setUuid('')
    currentValueOfSelect.current = null

    onClear?.()
  }

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (event.target.tagName !== 'INPUT' && initialFocusRef.current) {
        initialFocusRef.current.focus()
      }

      handleOpenTooltip()
    },
    [handleOpenTooltip],
  )

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (initialFocusRef.current || targetTooltipRef.current) {
        initialFocusRef.current?.focus()
      }

      handleCloseTooltip()

      onBlur?.(event)
    },
    [handleCloseTooltip],
  )

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)

    setUuid('')

    onInputChange?.(e)
  }

  const bindSelectValue = useCallback(
    (code: ISearchElementTree) => {
      if (!code.chapter) return

      if (currentValueOfSelect.current?.value === code.value) {
        handleCloseTooltip()

        return
      }

      const displayValue = getFullClassification({
        partClassifications: partClassifications ?? [],
        value: code.value,
      })

      const changedCodeTree = { ...code, displayValue }

      currentValueOfSelect.current = changedCodeTree

      setSearchValue(displayValue)

      setUuid(code.value)

      handleCloseTooltip()

      onChange?.(changedCodeTree)
    },
    [currentValueOfSelect.current],
  )

  const handleTreeWrapperMouseDown = useCallback((event: MouseEvent<HTMLDivElement>) => {
    // Не дает инпуту терять фокус при выборе значения
    event.preventDefault()
  }, [])

  const tooltipContent = useMemo(
    () => (
      <div
        role="none"
        onMouseDown={handleTreeWrapperMouseDown}
        onClick={handleTreeWrapperMouseDown}
      >
        {tree.length ? (
          tree.map((props) => (
            <TreeItem
              {...props}
              stepOfPadding={0}
              searchValue={uuid || searchValue}
              key={props.value}
              onTreeChange={bindSelectValue}
            />
          ))
        ) : (
          <Typography.Body
            variant={'bodyMRegular'}
            color={'text-base-tertiary'}
            className={styles.emptyResult}
          >
            {emptyMessage}
          </Typography.Body>
        )}
      </div>
    ),
    [tree, uuid, emptyMessage, searchValue, handleTreeWrapperMouseDown, bindSelectValue],
  )

  return (
    <div className={styles.wrapper}>
      <Tooltip
        {...tooltipProps}
        availableHeight
        trigger={'click'}
        open={tooltipIsOpen}
        zIndex={zIndexInputSearchTree}
        content={tooltipContent}
        position={'bottom-start'}
        offset={[0, 10]}
        arrowClassName={styles.searchTooltip__arrow}
        contentClassName={styles.searchTooltip__content}
        targetClassName={styles.searchTooltip__target}
        targetRef={targetTooltipRef}
      >
        <Input
          {...inputProps}
          fixWidth
          value={searchValue}
          error={error}
          ref={ref}
          onChange={onSearchInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClear={handleClear}
        />
      </Tooltip>
    </div>
  )
})

export default InputSearchTree
