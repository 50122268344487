import React, { memo, useCallback, useRef } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import { DEFAULT_CONTROL_TITLE } from '@components/DocumentFormComponents/NpaFormAddon/const'
import { BaseNpaFormValues } from '@components/DocumentFormComponents/NpaFormAddon/types'
import Button from '@components/NewDesign/Button'
import {
  ICardViewRefs,
  IDirectionStateViewRef,
} from '@components/Projects/[id]/DocumentLayout/Addons/NpaAddonOld'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'
import { isUndefined } from '@helpers/checkTypes'
import { PartCustomPropsWithId } from '@services/NPA/NPA.entity'

import styles from './NpaLayout.module.scss'
import NpaThree from './NpaThree'

interface NpaFormAddonProps<T extends BaseNpaFormValues> {
  blockViewIsValidating: boolean
  formInstance: T extends BaseNpaFormValues ? UseFormReturn<T> : never
  onOpenRegistryNpaChooseModal: VoidFunction
  controlTitle?: string
  onRemoveNpaPart?: (id: string) => Promise<void>
  onUpdateNpaPart?: (partProps: PartCustomPropsWithId) => Promise<void>
  onRemove?: () => Promise<void>
}

const NpaFormAddon = <T extends BaseNpaFormValues>({
  controlTitle = DEFAULT_CONTROL_TITLE,
  blockViewIsValidating,
  formInstance,
  onOpenRegistryNpaChooseModal,
  onUpdateNpaPart,
  onRemoveNpaPart,
  onRemove,
}: NpaFormAddonProps<T>) => {
  const cardViewRefs = useRef<ICardViewRefs | null>(null)
  const stateOfDirections = useRef<IDirectionStateViewRef | null>(null)

  const handleBindCardRef = useCallback(
    (callback: (prevState: ICardViewRefs | null) => ICardViewRefs) => {
      cardViewRefs.current = callback(cardViewRefs.current)
    },
    [],
  )

  const handleScrollToCardView = useCallback((partId: string) => {
    setTimeout(() => {
      document.querySelector('#headerLayout')?.scrollTo({
        top: cardViewRefs.current?.[partId] || 0,
        behavior: 'smooth',
      })
    }, 0)
  }, [])

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <NpaThree
          blockViewIsValidating={blockViewIsValidating}
          stateOfDirections={stateOfDirections}
          formInstance={formInstance}
          onBindCardRef={handleBindCardRef}
          onScrollToCardView={handleScrollToCardView}
          onRemoveNpaPart={onRemoveNpaPart}
          onUpdateNpaPart={onUpdateNpaPart}
        />
        <ControlContainer
          className={styles.npaLayout__control}
          titleClassName={styles['npaLayout__control-title']}
          leadingNodeContent={<ControlContainerStatusIcon />}
          title={controlTitle}
          trailingNodeContent={
            <Button
              fixWidth
              view="tined"
              dataTestId="NpaFormAddon-openNpaModal-button"
              onClick={onOpenRegistryNpaChooseModal}
            >
              Выбрать НПА
            </Button>
          }
          additionalMainContent={
            !isUndefined(onRemove) && (
              <ControlContainerSubtitle
                additionalContent={
                  <AsyncWrapper promise={onRemove}>
                    {({ isLoading, wrappedPromise }) => (
                      <Button
                        size="xs"
                        view="text"
                        disabled={isLoading}
                        color="negative"
                        variant="buttonSMedium"
                        dataTestId="deleteFormButton"
                        onClick={wrappedPromise}
                      >
                        не подавать документ
                      </Button>
                    )}
                  </AsyncWrapper>
                }
              >
                Чтобы отказаться от подачи, нажмите
              </ControlContainerSubtitle>
            )
          }
        />
      </Stack>
    </Container>
  )
}

export default memo(NpaFormAddon) as typeof NpaFormAddon
