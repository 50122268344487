import { generatePropertiesObjectForApplicationInclusionOfAARegisterSZPKForm } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/adapters/PropertiesObject.adapter'
import { generateRHFObjectForApplicationInclusionOfAARegisterSZPKForm } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/adapters/RHF.adapter'
import { ApplicationInclusionOfAARegisterSZPKFormValues } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/types'

const useApplicationInclusionOfAARegisterSZPKAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): ApplicationInclusionOfAARegisterSZPKFormValues =>
    generateRHFObjectForApplicationInclusionOfAARegisterSZPKForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForApplicationInclusionOfAARegisterSZPKForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useApplicationInclusionOfAARegisterSZPKAdapters }
