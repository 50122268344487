import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'

interface NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormsProps
  extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
}

const NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms: NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormsProps =
  () => null

NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms.First =
  First
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms.Second =
  Second
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms.Third =
  Third

export default NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms
