import React, { FC, memo } from 'react'

import NpaDocumentForm from '@components/DocumentFormComponents/Form/NpaForm'
import { NpaFormItemProps } from '@components/DocumentFormComponents/Form/NpaForm/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import NpaSolicitationInclusionForms from '@components/Forms/NpaSolicitationInclusionForm/Forms'
import { useNpaSolicitationInclusionFormManager } from '@components/Forms/NpaSolicitationInclusionForm/Manager'

const mapOfNpaSolicitationInclusionForms: NpaFormItemProps[] = [
  {
    id: 'headerInfo',
    node: <NpaSolicitationInclusionForms.HeaderInfo />,
  },
  {
    id: 'npa',
    node: <NpaSolicitationInclusionForms.NPA />,
  },
]

const NpaSolicitationInclusionFormStatement: FC<FormModalStatementProps> = (props) => {
  const {
    state: { formIsLoading },
  } = useNpaSolicitationInclusionFormManager()

  return (
    <NpaDocumentForm
      {...props}
      formIsLoading={formIsLoading}
      mapOfForms={mapOfNpaSolicitationInclusionForms}
    />
  )
}

export default memo(NpaSolicitationInclusionFormStatement)
