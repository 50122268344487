import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/const'
import { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyManager } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/Manager'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap } from './validation'

const Third = () => {
  const formInstance =
    useFormContext<NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyManager()

  const { getSubscribableControlProps, getInputProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher:
      NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункт Правил, на основании которого направляется уведомление о невозможности удовлетворения ходатайства о связанных договорах'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                    '3'
                  ].ruleLinkDenySolicitationProject,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                      '3'
                    ].ruleLinkDenySolicitationProject,
                    rules:
                      thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap.ruleLinkDenySolicitationProject,
                    inputProps: {
                      caption: 'рекомендация: 43',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                            '3'
                          ].ruleLinkDenySolicitationProject,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                            '3'
                          ].ruleLinkDenySolicitationProject,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={
                'Основания, в связи с которыми не может быть удовлетворено ходатайство о связанности\n' +
                'и заключено дополнительное соглашение о связанных договорах'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                    '3'
                  ].reasonDenySolicitationProject,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                      '3'
                    ].reasonDenySolicitationProject,
                    rules:
                      thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap.reasonDenySolicitationProject,
                    textareaProps: {
                      maxRows: Infinity,
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                            '3'
                          ].reasonDenySolicitationProject,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                            '3'
                          ].reasonDenySolicitationProject,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
