import { FileItemReducerState } from '@components/Attachments/Attachment/FileItem/types'
import { FolderItemService } from '@components/Attachments/Attachment/FolderItem/helpers'
import {
  FolderFileItem,
  FolderItemReducerState,
} from '@components/Attachments/Attachment/FolderItem/types'
import { isFunction } from '@helpers/checkTypes'
import { nanoid } from 'nanoid'

import type { MultipleFileItem, MultipleFolderItem, MultipleItemDefaultPayloadProps } from './types'

interface ReplaceFolderHelperProps {
  folderId: string
  newFolderState:
    | Partial<MultipleFolderItem>
    | ((prevFolderState: MultipleFolderItem) => Partial<MultipleFolderItem>)
  itemsReducerState: (MultipleFileItem | MultipleFolderItem)[]
}

interface ReplaceFileHelperProps {
  fileId: string
  fileState: Partial<FileItemReducerState>
  itemsReducerState: (MultipleFileItem | MultipleFolderItem)[]
}

interface ReplaceFileInFolderHelperProps extends Required<MultipleItemDefaultPayloadProps> {
  fileFolderItem: Partial<FolderFileItem['fileState']>
  itemsReducerState: (MultipleFileItem | MultipleFolderItem)[]
}

const { getFileIndexById } = FolderItemService

class MultipleItemService {
  static createFolder(initialFiles: FolderFileItem[] = []): MultipleFolderItem {
    return {
      id: nanoid(),
      files: initialFiles,
      isFold: false,
      folderName: 'Новая папка',
    }
  }

  static fromFolderAndFileItemsToMultipleItems(
    items: (FileItemReducerState | FolderItemReducerState)[],
  ): (MultipleFolderItem | MultipleFileItem)[] {
    return items.map((item) => {
      return {
        id: nanoid(),
        ...item,
      }
    })
  }

  static getMultipleItemIndexById(
    itemId: string,
    items: (MultipleFileItem | MultipleFolderItem)[],
  ) {
    return items.findIndex((item) => item.id === itemId)
  }

  static replaceFileInFolderAndReturnAllState({
    fileId,
    folderId,
    fileFolderItem,
    itemsReducerState,
  }: ReplaceFileInFolderHelperProps): (MultipleFileItem | MultipleFolderItem)[] {
    const folderIndex = MultipleItemService.getMultipleItemIndexById(folderId, itemsReducerState)
    if (folderIndex === -1) return itemsReducerState

    const folderByIndex = itemsReducerState[folderIndex] as MultipleFolderItem
    if (!folderByIndex.files) return itemsReducerState

    const fileIndex = getFileIndexById(fileId, folderByIndex.files)
    const fileByIndexInFolder = folderByIndex.files[fileIndex]

    const fileToReplace: FolderFileItem = {
      ...fileByIndexInFolder,
      fileState: {
        ...fileByIndexInFolder.fileState,
        ...fileFolderItem,
      },
    }

    const folderToReplace: MultipleFolderItem = {
      ...folderByIndex,
      files: [
        ...folderByIndex.files.slice(0, fileIndex),
        fileToReplace,
        ...folderByIndex.files.slice(fileIndex + 1, folderByIndex.files.length),
      ],
    }

    return [
      ...itemsReducerState.slice(0, folderIndex),
      folderToReplace,
      ...itemsReducerState.slice(folderIndex + 1, itemsReducerState.length),
    ]
  }

  static replaceFileWithoutFolderAndReturnState({
    fileState,
    fileId,
    itemsReducerState,
  }: ReplaceFileHelperProps): (MultipleFileItem | MultipleFolderItem)[] {
    const fileIndex = MultipleItemService.getMultipleItemIndexById(fileId, itemsReducerState)

    if (fileIndex === -1) return itemsReducerState

    const fileByIndex = itemsReducerState[fileIndex] as MultipleFileItem
    const fileToReplace: MultipleFileItem = {
      ...fileByIndex,
      ...fileState,
    }

    return [
      ...itemsReducerState.slice(0, fileIndex),
      fileToReplace,
      ...itemsReducerState.slice(fileIndex + 1, itemsReducerState.length),
    ]
  }

  static replaceFolderStateAndReturnState({
    folderId,
    newFolderState,
    itemsReducerState,
  }: ReplaceFolderHelperProps): (MultipleFileItem | MultipleFolderItem)[] {
    const folderIndex = MultipleItemService.getMultipleItemIndexById(folderId, itemsReducerState)

    if (folderIndex === -1) return itemsReducerState

    const folderByIndex = itemsReducerState[folderIndex] as MultipleFolderItem

    const folderToReplace: MultipleFolderItem = {
      ...folderByIndex,
      ...(isFunction(newFolderState) ? newFolderState(folderByIndex) : newFolderState),
    }

    return [
      ...itemsReducerState.slice(0, folderIndex),
      folderToReplace,
      ...itemsReducerState.slice(folderIndex + 1, itemsReducerState.length),
    ]
  }

  static isMultipleFileItem(item: MultipleFileItem | MultipleFolderItem): item is MultipleFileItem {
    return 'file' in item
  }
}

export { MultipleItemService }
