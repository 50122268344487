import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { usePopupManager } from 'react-popup-manager'

import Form from '@components/Form'
import type { ButtonProps } from '@components/NewDesign/Button/types'
import { TypeOfUnit } from '@components/NewDesign/Calendar/types'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { parseDateString } from '@components/NewDesign/DateInput/DateInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import useStatusSignModal from '@components/NewDesignedModals/StatusSignModal/manager'
import {
  defaultMessageSupport,
  errorModalHeaderTexts,
  loaderModalBodyTexts,
  registrationModalBodyTexts,
  registrationModalHeaderTexts,
  signModalButtonTexts,
  successModalBodyTexts,
  successModalHeaderTexts,
} from '@constants/texts'
import { NewDfosType } from '@constants/types'
import { defaultRHFValidation } from '@constants/validations'
import useProjectsApi from '@context/APIContext/hooks/useProjectsApi'
import { isAxiosError, isJsError } from '@helpers/checkTypes'
import { disableFutureDates, disablePastDates } from '@helpers/date/disableOfDates'
import { isErrorsWithBackendMessage } from '@helpers/errorHelpers'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useBooleanState } from '@hooks/useBooleanState'
import DayjsService from '@services/Dayjs/Dayjs.service'
import LoggerServiceHelpers from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'
import { Dayjs } from 'dayjs'

import { registrationSignModalDefaultValues, registrationSignModalFormNames } from './constants'
import styles from './RegistrationSignModal.module.scss'
import type {
  HandleError,
  RegistrationSignModalFormValues,
  RegistrationSignModalProps,
} from './types'

const RegistrationSignModal: FC<RegistrationSignModalProps> = ({
  headerTitle = registrationModalHeaderTexts.agreementIntoRegister,
  bodyText = registrationModalBodyTexts.agreementMessage,

  loaderHeaderTitle = '',
  loaderBodyText = loaderModalBodyTexts.defaultMessage,

  successHeaderTitle = successModalHeaderTexts.defaultMessage,
  successBodyText = successModalBodyTexts.defaultMessage,

  isOpen,
  actionType,
  actionId,
  onSuccessSignFiles,
  onClose,

  projectId,
  dfoId,
  signBodyClassname,

  // Пропсы для расторжения
  disableNumberOfRegistration,
  dateRegistrationPlaceholder,

  onErrorSignFiles,
}) => {
  const { createProjectRegistrate } = useProjectsApi()

  const popupManager = usePopupManager()
  const { handleOpenStatusSignModal } = useStatusSignModal()
  const { handleOpenErrorModal } = useErrorModal()

  const { projectDfos } = useProjectDfos({
    key: {
      projectId: projectId || '',
      _key: 'projectDfos',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerServiceHelpers.handleMultipleLogError({
        componentInfo: {
          componentName: 'RegistrationSignModal',
          componentType: 'modal',
        },
      }),
    },
  })

  const {
    formState: { isValid, isSubmitting },
    control,
    handleSubmit,
    setError,
  } = useForm<RegistrationSignModalFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: registrationSignModalDefaultValues,
  })

  const {
    booleanState: isLoading,
    setBooleanStateToTrue: enableLoading,
    setBooleanStateToFalse: disableLoading,
  } = useBooleanState()

  const SZPKDfo = projectDfos?.find((dfo) => dfo.type === NewDfosType.SZPK)

  const { registeredDate } = SZPKDfo ?? {}

  useEffect(() => {
    return () => {
      disableLoading()
    }
  }, [])

  const disabledDate = useCallback(
    (date: Dayjs, typeOfUnit?: TypeOfUnit) => {
      const dateEnd = typeOfUnit && DayjsService.dayjs(date).endOf(typeOfUnit)

      return disableFutureDates(date) || disablePastDates(dateEnd || date, registeredDate)
    },
    [registeredDate],
  )

  const handleError: HandleError = useCallback(
    (name, message) => {
      setError(name, {
        type: 'validate',
        message,
      })
    },
    [setError],
  )

  const handleSubmitForm: SubmitHandler<RegistrationSignModalFormValues> = useCallback(
    async ({ registeredDate, registeredNumber }) => {
      if (!projectId) return

      enableLoading()

      try {
        await createProjectRegistrate({
          actionId,
          projectId,
          registeredDate: registeredDate
            ? DayjsService.dayjs(parseDateString(registeredDate)).format('YYYY-MM-DD')
            : undefined,
          registeredNumber: !disableNumberOfRegistration ? registeredNumber : undefined,
        })

        popupManager.closeAll()

        handleOpenStatusSignModal({
          actionType,
          actionId,

          loaderHeaderTitle,
          loaderBodyText,

          successHeaderTitle,
          successBodyText,

          onSuccessSignFiles,
          onClose,

          projectId,
          dfoId,
          onErrorSignFiles,
        })
      } catch (error) {
        LoggerServiceHelpers.handleMultipleLogError({
          componentInfo: {
            componentName: 'RegistrationSignModal',
            componentType: 'handleSubmitForm',
          },
          additionInfo: {
            registrationDate: registeredDate,
            registrationNumber: registeredNumber,
          },
        })(error)
        if (!isAxiosError(error)) {
          handleOpenErrorModal({
            headerText: errorModalHeaderTexts.defaultMessage,
            bodyText: isJsError(error) ? error.message : defaultMessageSupport,
          })

          throw error
        }

        if (isErrorsWithBackendMessage(error.response?.status ?? 0)) {
          error?.response?.data?.violations?.forEach(({ field, message }) => {
            if (field) handleError?.(field, message)
          })
        }

        onErrorSignFiles?.(error)

        throw error
      } finally {
        disableLoading()
      }
    },
    [
      actionType,
      actionId,
      createProjectRegistrate,
      dfoId,
      disableLoading,
      disableNumberOfRegistration,
      enableLoading,
      handleError,
      handleOpenErrorModal,
      handleOpenStatusSignModal,
      loaderBodyText,
      loaderHeaderTitle,
      onClose,
      onErrorSignFiles,
      onSuccessSignFiles,
      popupManager,
      projectId,
      successBodyText,
      successHeaderTitle,
    ],
  )

  const actions = useMemo(() => {
    return [
      {
        view: 'gray',
        dataTestId: 'RegistrationSignModal-cancel-button',
        children: signModalButtonTexts.cancelMessage,
        className: cn(styles.RegistrationSignModal__buttonDefault),
        onClick: onClose,
      },
      {
        disabled: isLoading || !isValid || isSubmitting,
        loaderProps: {
          loading: isLoading,
          variant: 'lite',
          placement: 'trailing',
        },
        dataTestId: 'RegistrationSignModal-registration-button',
        children: signModalButtonTexts.defaultMessage,
        className: cn(
          styles.RegistrationSignModal__buttonDefault,
          styles['RegistrationSignModal__buttonDefault--sign'],
        ),
        onClick: handleSubmit(handleSubmitForm),
      },
    ] as ButtonProps[]
  }, [handleSubmit, handleSubmitForm, isLoading, isSubmitting, isValid, onClose])

  return (
    <Modal.Action
      isOpen={isOpen}
      title={headerTitle}
      actions={actions}
      dataTestId="RegistrationSignModal-registration-modal"
      closeButtonDataTestId="RegistrationSignModal-registration-closeButton"
      simpleModalContainerClassName={styles.RegistrationSignModal__modalWrapper}
      simpleModalBodyClassName={styles.RegistrationSignModal__modalBody}
      onClose={onClose}
    >
      <Typography.Body
        variant="bodyMRegular"
        className={cn(styles.RegistrationSignModal__body, signBodyClassname)}
      >
        {bodyText}
      </Typography.Body>
      <Form
        className={styles.RegistrationSignModal__form}
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className={styles['RegistrationSignModal__form-inputWrapper']}>
          {!disableNumberOfRegistration && (
            <ControlledInput
              control={control}
              name={registrationSignModalFormNames.registeredNumber}
              rules={{
                required: defaultRHFValidation.required,
              }}
              inputProps={{
                view: 'secondary',
                placeholder: 'Регистрационный номер',
              }}
            />
          )}

          <ControlledCalendarInput
            control={control}
            name={registrationSignModalFormNames.registeredDate}
            calendarProps={{ align: 'right', disabledDate }}
            rules={{
              required: defaultRHFValidation.required,
            }}
            calendarInputProps={{
              label: dateRegistrationPlaceholder ?? 'ДД.ММ.ГГГГ',
              size: 'xl',
              view: 'secondary',
              fixWidth: disableNumberOfRegistration,
            }}
            tooltipProps={{
              position: 'bottom-start',
            }}
          />
        </div>
      </Form>
    </Modal.Action>
  )
}

export default memo(RegistrationSignModal)
