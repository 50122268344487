import type { FileRejection } from 'react-dropzone'

import { RejectedFile } from '@components/Attachments/Attachment/FileItem/types'
import { rejectedFileErrorCodesMap } from '@constants/documents'

class FileItemService {
  static convertRejectedFile(rejectedFile: FileRejection): RejectedFile {
    const { file, errors } = rejectedFile

    const rejectedFileWithCurrentDate = new File([file], file.name)

    const foundError = errors.find((error) =>
      Object.keys(rejectedFileErrorCodesMap).includes(error.code),
    ) as RejectedFile | undefined

    return {
      file: rejectedFileWithCurrentDate,
      message: foundError?.message || '',
      buttonMessage: foundError?.buttonMessage || '',
      code: foundError?.code || '',
    }
  }

  static convertMultipleRejectedFile(file: File): RejectedFile {
    const rejectedFileWithCurrentDate = new File([file], file.name)

    return {
      file: rejectedFileWithCurrentDate,
      message: 'Загрузить несколько файлов невозможно',
      buttonMessage: 'по одному',
      code: rejectedFileErrorCodesMap.MULTIPLE_FILE,
    }
  }
}

export { FileItemService }
