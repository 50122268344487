import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { defaultFiltersValue, PAGE_SIZE_FOR_NPA } from '@components/RegistryNpa/const'
import { TFiltersModalFormValues } from '@components/RegistryNpa/Header/Filters'
import { IFiltersFormValues } from '@components/RegistryNpa/Manager'
import { isArray, isEmptyString, isNullOrUndefined, isObject } from '@helpers/checkTypes'
import useDebounce from '@hooks/useDebounce'
import { Key } from 'swr'

interface NpaFiltersProps {
  keyMutator: Key
  initialFilters?: Partial<IFiltersFormValues['filters']>
}

const useNpaFiltersOld = ({ keyMutator, initialFilters }: NpaFiltersProps) => {
  const filtersInstance = useForm<IFiltersFormValues>({
    defaultValues: {
      filters: {
        ...defaultFiltersValue.filters,
        isMunicipal: initialFilters?.isMunicipal ?? defaultFiltersValue.filters.isMunicipal,
        ...initialFilters,
        isFederal: initialFilters?.isFederal ?? defaultFiltersValue.filters.isFederal,
        isRegional: initialFilters?.isRegional ?? defaultFiltersValue.filters.isRegional,
        region: initialFilters?.region ?? defaultFiltersValue.filters.region,
        oktmo: initialFilters?.oktmo ?? defaultFiltersValue.filters.oktmo,
      },
      searchString: defaultFiltersValue.searchString,
    },
  })

  const currentFilters = filtersInstance.watch('filters')
  const currentSearchString = filtersInstance.watch('searchString')?.trim()

  const debouncedSearchValue = useDebounce(currentSearchString, 300)

  const preparedKeyToSwr = useCallback(
    (page) => {
      const { isFederal, isRegional, isMunicipal } = currentFilters

      const currentLvl = (() => {
        if (isFederal && isRegional && isMunicipal) return

        if (!isFederal && isRegional && isMunicipal) return ['REGIONAL', 'MUNICIPAL']
        if (isFederal && isMunicipal && !isRegional) return ['FEDERAL', 'MUNICIPAL']
        if (isFederal && !isMunicipal && isRegional) return ['FEDERAL', 'REGIONAL']

        if (isFederal && !isRegional && !isMunicipal) return 'FEDERAL'
        if (!isFederal && isRegional && !isMunicipal) return 'REGIONAL'
        if (!isFederal && !isRegional && isMunicipal) return 'MUNICIPAL'
      })()
      const defaultRegion = currentFilters.region

      const preparedRegion = isFederal
        ? isArray(currentFilters.region)
          ? [currentFilters.region, '']
          : defaultRegion
        : defaultRegion

      const preparedMunicipality =
        isMunicipal &&
        !isNullOrUndefined(currentFilters.oktmo) &&
        !isEmptyString(currentFilters.oktmo)
          ? isArray(currentFilters.oktmo)
            ? currentFilters.oktmo.map((oktmoString) => JSON.parse(oktmoString).oktmo)
            : JSON.parse(currentFilters.oktmo).oktmo
          : ''

      return {
        page,
        size: currentFilters.size,
        direction: currentFilters.direction || undefined,
        level: currentLvl || undefined,
        type: currentFilters.type || undefined,
        region: preparedRegion || undefined,
        oktmo: preparedMunicipality || undefined,
        part: currentFilters.part || undefined,
        searchString: debouncedSearchValue.length ? debouncedSearchValue : undefined,
        ...(isObject(keyMutator) ? keyMutator : {}),
      }
    },
    [currentFilters, debouncedSearchValue, keyMutator],
  )

  const handleApplyFilters = useCallback(
    (filters: TFiltersModalFormValues) => {
      filtersInstance.setValue('filters', { ...filters, size: PAGE_SIZE_FOR_NPA })
    },
    [filtersInstance],
  )

  return {
    filtersInstance,
    preparedKeyToSwr,
    handleApplyFilters,
    debouncedSearchValue,
    currentFilters,
    currentSearchString,
  }
}

export { useNpaFiltersOld }
