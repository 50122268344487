import { useCallback } from 'react'

import { rejectedFileErrorCodesMap } from '@constants/documents'
import { formatBytes, splitFileName } from '@helpers/fileHelpers'
import { CustomFileError } from '@interfaces/documents'

const MAX_SIZE_IN_BYTES = 200 * 1024 * 1024

interface UseDropzoneValidationProps {
  maxFileSize?: number
  minFileSize?: number
  acceptExtensions: string[]
}

const useDropzoneValidation = (props?: UseDropzoneValidationProps) => {
  const { minFileSize = 1, maxFileSize = MAX_SIZE_IN_BYTES, acceptExtensions } = props || {}

  const dropzoneValidator = useCallback(
    (documentName?: string) =>
      <T extends File>(file: T): CustomFileError | CustomFileError[] | null => {
        const [_, fileExtension] = splitFileName(file.name)

        const isFoundExtension = !!(
          acceptExtensions &&
          acceptExtensions.length &&
          acceptExtensions.find((extension) => extension === `.${fileExtension}`)
        )

        if (!isFoundExtension) {
          const documentNameText = documentName ? ` «${documentName}»` : ''

          return {
            code: rejectedFileErrorCodesMap.FILE_INVALID_TYPE,
            message: `Недопустимое расширение файла${documentNameText}`,
            buttonMessage: 'с допустимым расширением',
          }
        }

        if (isNaN(file.size) || file.size < minFileSize) {
          return {
            code: rejectedFileErrorCodesMap.MIN_FILE_SIZE,
            message: 'Файл пустой',
            buttonMessage: 'с данными',
          }
        }

        if (file.size > maxFileSize) {
          return {
            code: rejectedFileErrorCodesMap.MAX_FILE_SIZE,
            message: 'Размер файла превышает допустимый',
            buttonMessage: `с размером максимум ${formatBytes(maxFileSize).toUpperCase()}`,
          }
        }

        return null
      },
    [acceptExtensions, maxFileSize, minFileSize],
  )

  return {
    dropzoneValidator,
  }
}

export { useDropzoneValidation }
