import { FC, memo, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'

import Icon from '@components/Icon/Icon'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { signModalButtonTexts } from '@constants/texts'
import doneAllIcon from '@icons/action/done_all.svg'

import styles from './SuccessModal.module.scss'

interface SuccessSignModalProps extends Required<PopupProps> {
  headerText: string
  bodyText: string
}

const SuccessSignModal: FC<SuccessSignModalProps> = ({ isOpen, headerText, bodyText, onClose }) => {
  const actions = useMemo(() => {
    return [
      {
        children: signModalButtonTexts.doneMessage,
        dataTestId: 'SuccessSignModal-done-button',
        fixWidth: true,
        onClick: onClose,
        className: styles.success,
      },
    ] as ButtonProps[]
  }, [onClose])

  return (
    <Modal.Action
      hideCloseIcon
      isOpen={isOpen}
      title={''}
      dataTestId="SuccessSignModal-successSign-modal"
      closeButtonDataTestId="SuccessSignModal-successSign-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      actions={actions}
      onClose={onClose}
    >
      <div className={styles.icon}>
        <Icon src={doneAllIcon} size={'4xl'} />
      </div>
      <Typography.Headline variant="headlineH3" className={styles.head}>
        {headerText}
      </Typography.Headline>
      <Typography.Body variant="bodyMRegular" align="center">
        {bodyText}
      </Typography.Body>
    </Modal.Action>
  )
}

export default memo(SuccessSignModal)
