import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface NotificationOfIdentifiedViolationsFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const NotificationOfIdentifiedViolationsForms: NotificationOfIdentifiedViolationsFormsProps = () =>
  null

NotificationOfIdentifiedViolationsForms.First = First
NotificationOfIdentifiedViolationsForms.Second = Second
NotificationOfIdentifiedViolationsForms.Third = Third
NotificationOfIdentifiedViolationsForms.Fourth = Fourth

export default NotificationOfIdentifiedViolationsForms
