import { FC } from 'react'

import One from './1'
import Two from './2'
import Three from './3'
import Fourth from './4'
import Fifth from './5'
import Sixth from './6'

interface AAgreementAccessionMunicipalFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Three: typeof Three
  Fourth: typeof Fourth
  Fifth: typeof Fifth
  Sixth: typeof Sixth
}

const AAgreementAccessionMunicipalForms: AAgreementAccessionMunicipalFormsProps = () => null

AAgreementAccessionMunicipalForms.One = One
AAgreementAccessionMunicipalForms.Two = Two
AAgreementAccessionMunicipalForms.Three = Three
AAgreementAccessionMunicipalForms.Fourth = Fourth
AAgreementAccessionMunicipalForms.Fifth = Fifth
AAgreementAccessionMunicipalForms.Sixth = Sixth

export default AAgreementAccessionMunicipalForms
