import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForInformationOfExecutionAgreementEventsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedStepsMonitoring = (() => {
    if (
      !isArray(objectFromPropertyState.stepsMonitoring?.value) ||
      !objectFromPropertyState.stepsMonitoring?.value?.length
    )
      return []

    return objectFromPropertyState.stepsMonitoring?.value.map((stepMonitoring) => {
      const preparedFactStepPermissions = (() => {
        if (
          !isArray(stepMonitoring.value?.factStepPermissions?.value) ||
          !stepMonitoring.value?.factStepPermissions?.value?.length
        )
          return []

        return stepMonitoring.value?.factStepPermissions?.value.map((factStepPermission) => ({
          propertyId: factStepPermission.propertyId,
          type: factStepPermission.type,
          lastUpdateDt: factStepPermission.lastUpdateDt,
          value: {
            permissionKindName: {
              propertyId: factStepPermission.value?.planPermissionId?.value?.propertyId,
              type: factStepPermission.value?.planPermissionId?.value?.type,
              lastUpdateDt: factStepPermission.value?.planPermissionId?.value?.lastUpdateDt,
            },
            permissionOrganization: {
              propertyId: factStepPermission.value?.planPermissionId?.value?.propertyId,
              type: factStepPermission.value?.planPermissionId?.value?.type,
              lastUpdateDt: factStepPermission.value?.planPermissionId?.value?.lastUpdateDt,
            },
            permissionDate: {
              propertyId: factStepPermission.value?.planPermissionId?.value?.propertyId,
              type: factStepPermission.value?.planPermissionId?.value?.type,
              lastUpdateDt: factStepPermission.value?.planPermissionId?.value?.lastUpdateDt,
            },
            factPermissionDate: {
              propertyId: factStepPermission.value?.factPermissionDate?.propertyId,
              type: factStepPermission.value?.factPermissionDate?.type,
              lastUpdateDt: factStepPermission.value?.factPermissionDate?.lastUpdateDt,
            },
          },
        }))
      })()

      const preparedFactStepActivityOthers = (() => {
        if (
          !isArray(stepMonitoring.value?.factStepActivityOthers?.value) ||
          !stepMonitoring.value?.factStepActivityOthers?.value?.length
        )
          return []

        return stepMonitoring.value?.factStepActivityOthers?.value.map((factStepActivityOther) => ({
          propertyId: factStepActivityOther.propertyId,
          type: factStepActivityOther.type,
          lastUpdateDt: factStepActivityOther.lastUpdateDt,
          value: {
            otherActivityName: {
              propertyId: factStepActivityOther.value?.planStepActivityOtherId?.value?.propertyId,
              type: factStepActivityOther.value?.planStepActivityOtherId?.value?.type,
              lastUpdateDt:
                factStepActivityOther.value?.planStepActivityOtherId?.value?.lastUpdateDt,
            },
            otherActivityEndDate: {
              propertyId: factStepActivityOther.value?.planStepActivityOtherId?.value?.propertyId,
              type: factStepActivityOther.value?.planStepActivityOtherId?.value?.type,
              lastUpdateDt:
                factStepActivityOther.value?.planStepActivityOtherId?.value?.lastUpdateDt,
            },
            factOtherActivityEndDate: {
              propertyId: factStepActivityOther.value?.factOtherActivityEndDate?.propertyId,
              type: factStepActivityOther.value?.factOtherActivityEndDate?.type,
              lastUpdateDt: factStepActivityOther.value?.factOtherActivityEndDate?.lastUpdateDt,
            },
          },
        }))
      })()

      return {
        propertyId: stepMonitoring.propertyId,
        type: stepMonitoring.type,
        lastUpdateDt: stepMonitoring.lastUpdateDt,
        value: {
          stepTitle: {
            propertyId: stepMonitoring.value?.planStepId?.value?.propertyId,
            type: stepMonitoring.value.planStepId?.value?.type,
            lastUpdateDt: stepMonitoring.value.planStepId?.value?.lastUpdateDt,
          },
          notContainsCompletedEvents: {
            propertyId: stepMonitoring.value?.notContainsCompletedEvents?.propertyId,
            type: stepMonitoring.value.notContainsCompletedEvents?.type,
            lastUpdateDt: stepMonitoring.value.notContainsCompletedEvents?.lastUpdateDt,
          },
          notContainsCompletedEventsComment: {
            propertyId: stepMonitoring.value?.notContainsCompletedEventsComment?.propertyId,
            type: stepMonitoring.value.notContainsCompletedEventsComment?.type,
            lastUpdateDt: stepMonitoring.value.notContainsCompletedEventsComment?.lastUpdateDt,
          },
          objectCommissioningEndDate: {
            propertyId: stepMonitoring.value?.planStepId?.value?.propertyId,
            type: stepMonitoring.value.planStepId?.value?.type,
            lastUpdateDt: stepMonitoring.value.planStepId?.value?.lastUpdateDt,
          },
          factObjectCommissioningEndDate: {
            propertyId: stepMonitoring.value?.factObjectCommissioningEndDate?.propertyId,
            type: stepMonitoring.value.factObjectCommissioningEndDate?.type,
            lastUpdateDt: stepMonitoring.value.factObjectCommissioningEndDate?.lastUpdateDt,
          },
          ridCommissioningEndDate: {
            propertyId: stepMonitoring.value?.planStepId?.value?.propertyId,
            type: stepMonitoring.value.planStepId?.value?.type,
            lastUpdateDt: stepMonitoring.value.planStepId?.value?.lastUpdateDt,
          },
          factRidCommissioningEndDate: {
            propertyId: stepMonitoring.value?.factRidCommissioningEndDate?.propertyId,
            type: stepMonitoring.value.factRidCommissioningEndDate?.type,
            lastUpdateDt: stepMonitoring.value.factRidCommissioningEndDate?.lastUpdateDt,
          },
          typeObjectStateRegistration: {
            propertyId: stepMonitoring.value?.planStepId?.value?.propertyId,
            type: stepMonitoring.value.planStepId?.value?.type,
            lastUpdateDt: stepMonitoring.value.planStepId?.value?.lastUpdateDt,
          },
          objectStateRegistrationEndDate: {
            propertyId: stepMonitoring.value?.planStepId?.value?.propertyId,
            type: stepMonitoring.value.planStepId?.value?.type,
            lastUpdateDt: stepMonitoring.value.planStepId?.value?.lastUpdateDt,
          },
          factObjectStateRegistrationDate: {
            propertyId: stepMonitoring.value?.factObjectStateRegistrationDate?.propertyId,
            type: stepMonitoring.value.factObjectStateRegistrationDate?.type,
            lastUpdateDt: stepMonitoring.value.factObjectStateRegistrationDate?.lastUpdateDt,
          },
          ridStateRegistrationEndDate: {
            propertyId: stepMonitoring.value?.planStepId?.value?.propertyId,
            type: stepMonitoring.value.planStepId?.value?.type,
            lastUpdateDt: stepMonitoring.value.planStepId?.value?.lastUpdateDt,
          },
          factRidStateRegistrationDate: {
            propertyId: stepMonitoring.value?.factRidStateRegistrationDate?.propertyId,
            type: stepMonitoring.value.factRidStateRegistrationDate?.type,
            lastUpdateDt: stepMonitoring.value.factRidStateRegistrationDate?.lastUpdateDt,
          },
          factStepPermissions: {
            propertyId: stepMonitoring.value?.factStepPermissions?.propertyId,
            type: stepMonitoring.value.factStepPermissions?.type,
            lastUpdateDt: stepMonitoring.value.factStepPermissions?.lastUpdateDt,
            value: preparedFactStepPermissions,
          },
          factStepActivityOthers: {
            propertyId: stepMonitoring.value?.factStepActivityOthers?.propertyId,
            type: stepMonitoring.value.factStepActivityOthers?.type,
            lastUpdateDt: stepMonitoring.value.factStepActivityOthers?.lastUpdateDt,
            value: preparedFactStepActivityOthers,
          },
        },
      }
    })
  })()

  return {
    '1': {
      monitoringReportDate: {
        propertyId: objectFromPropertyState.monitoringReportDate.propertyId,
        type: objectFromPropertyState.monitoringReportDate.propertyId,
        lastUpdateDt: objectFromPropertyState.monitoringReportDate.propertyId,
      },
      stepsMonitoring: {
        propertyId: objectFromPropertyState.stepsMonitoring.propertyId,
        type: objectFromPropertyState.stepsMonitoring.propertyId,
        lastUpdateDt: objectFromPropertyState.stepsMonitoring.propertyId,
        value: preparedStepsMonitoring,
      },
    },
  }
}

export { generatePropertiesObjectForInformationOfExecutionAgreementEventsForm }
