import { FC } from 'react'
import ReactDOM from 'react-dom'
import { Toaster, ToasterProps } from 'react-hot-toast'

import styles from './Toaster.module.scss'

const CustomToaster: FC<ToasterProps> = ({ ...rest }) => {
  return ReactDOM.createPortal(
    <Toaster position="bottom-center" containerClassName={styles.Toaster} {...rest} />,
    document.querySelector('#react-hot-toast') || document.body,
  )
}

export default CustomToaster
