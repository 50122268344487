import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'

const certificateReducerActionMap = {
  SETUP_LOADING: 'SETUP_LOADING',
  SETUP_CERTIFICATE_MISSING_ERROR: 'SETUP_CERTIFICATE_MISSING_ERROR',
  SETUP_DATA_UID: 'SETUP_DATA_UID',
  SETUP_CAPTION: 'SETUP_CAPTION',
  SETUP_CERTIFICATES: 'SETUP_CERTIFICATES',
  SETUP_SELECTED_CERTIFICATE: 'SETUP_SELECTED_CERTIFICATE',
  SETUP_ERROR: 'SETUP_ERROR',
  SETUP_REGISTRATION_ERROR: 'SETUP_REGISTRATION_ERROR',
  SETUP_CRYPTO_PLUGIN_ERROR: 'SETUP_CRYPTO_PLUGIN_ERROR',
  SETUP_SIGN_ERROR: 'SETUP_SIGN_ERROR',
  SETUP_SIGNATURE_ERROR: 'SETUP_SIGNATURE_ERROR',
  SETUP_REDIRECT_ERROR: 'SETUP_REDIRECT_ERROR',
  SETUP_NATIVE_ERROR: 'SETUP_NATIVE_ERROR',
} as const

const OIDINNLE = '1.2.643.100.4'
const OIDINN = '1.2.643.3.131.1.1'

const certificateComponentInfo: ComponentInfo = {
  componentName: 'CertificateForm',
  moduleName: 'LoginByCrypt',
  componentType: 'authByCertificate',
}

const certificateReducerComponentInfo: ComponentInfo = {
  componentName: 'certificateReducer',
  moduleName: 'CertificateForm',
  componentType: 'reducer',
}

const certificateConfidentialModalComponentInfo: ComponentInfo = {
  componentName: 'ConfidentialModal',
  moduleName: 'CertificateForm',
  componentType: 'registrationOrganization',
}

export {
  certificateComponentInfo,
  certificateConfidentialModalComponentInfo,
  certificateReducerActionMap,
  certificateReducerComponentInfo,
  OIDINN,
  OIDINNLE,
}
