import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { fifthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from './validation'

const Fifth = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { getSubscribableControlProps, getSingleSelectProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
    })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['5']
                  .concessionFail,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => {
                        return {
                          displayValue: item.name || '',
                          value: item.id,
                        }
                      },
                      selectProps: {
                        inputProps: {
                          label: 'Сторонами установлен факт',
                        },
                        onChangeFormValue: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['5']
                                .concessionFail,
                            )
                          }, 0),
                      },
                      controllerProps: {
                        name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['5']
                          .concessionFail,
                        rules:
                          fifthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.concessionFail,
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['5']
                  .concessionFailDocument,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['5']
                    .concessionFailDocument,
                  rules:
                    fifthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.concessionFailDocument,
                  textareaProps: {
                    label: 'Факт подтверждается следующими документами',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['5']
                          .concessionFailDocument,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['5']
                          .concessionFailDocument,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Fifth
