import React, { FC } from 'react'

import Group from '@components/DocumentFormComponents/Group'
import stepGroupStyles from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/Forms/StepGroup.module.scss'
import { StepDeadline } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'
import Stack from '@components/ReactBootstrap/Stack'

import { StepDeadlinePathName } from '../../types'

import StepDeadlineItemRow from './StepDeadlineItemRow'

interface StepDeadlineItemProps {
  stepDescription: StepDeadline['stepDescription']
  stepNumber: StepDeadline['stepNumber']
  formName: StepDeadlinePathName
  isLastItem: boolean
}

const StepDeadlineItem: FC<StepDeadlineItemProps> = ({
  stepDescription,
  stepNumber,
  formName,
  isLastItem,
}) => {
  return (
    <Stack direction={'vertical'} gap={3}>
      <Group title={`ЭТАП №${stepNumber}`} disableBottomBorder={isLastItem}>
        <Group
          disableBottomBorder
          groupClassName={stepGroupStyles.stepGroup}
          titleClassName={stepGroupStyles['stepGroup-title']}
          title={stepDescription}
        >
          <Stack direction={'vertical'} gap={2}>
            <StepDeadlineItemRow
              rowTitle={'Сроки этапа (план)'}
              startDateFormName={`${formName}.stepStartDate`}
              endDateFormName={`${formName}.stepEndDate`}
            />

            <StepDeadlineItemRow
              rowTitle={'Сроки этапа (факт)'}
              startDateFormName={`${formName}.factStepStartDate`}
              endDateFormName={`${formName}.factStepEndDate`}
            />
          </Stack>
        </Group>
      </Group>
    </Stack>
  )
}

export default StepDeadlineItem
