import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NoticeOfRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/types'

const generateRHFObjectForNoticeOfRejectConcludeSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): NoticeOfRejectConcludeSZPKFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: objectFromPropertyState.investorFullNameGenitive?.value || '',
      investorFullNameInstrumental:
        objectFromPropertyState.investorFullNameInstrumental?.value || '',
    },
    '4': {
      projectName: objectFromPropertyState.projectName?.value || '',
      ruleLinkStatementReject: objectFromPropertyState.ruleLinkStatementReject?.value || '',
      statementRejectReason: objectFromPropertyState.statementRejectReason?.value || '',
      ruleLinkStatementRepeat: objectFromPropertyState.ruleLinkStatementRepeat?.value || '',
    },
  }
}

export { generateRHFObjectForNoticeOfRejectConcludeSZPKForm }
