import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOfImplementationInvestprojectStagesBlockValues } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/const'
import CertificateOfImplementationInvestprojectStagesStepsMonitoringEnded from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Forms/3/StepsMonitoringEnded'
import { useCertificateOfImplementationInvestprojectStagesManager } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Manager'
import {
  CertificateOfImplementationInvestprojectStagesFormModifierValues,
  CertificateOfImplementationInvestprojectStagesFormValues,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'
import {
  CertificateOfImplementationInvestprojectStagesFieldArrayControlUpdateWatcher,
  CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance = useFormContext<CertificateOfImplementationInvestprojectStagesFormValues>()

  const formModifierInstance =
    useFormModifierContext<CertificateOfImplementationInvestprojectStagesFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleRemoveItemFromList, handleAddItemToListWithOutValue },
    preparedProps: { subscribableControl },
  } = useCertificateOfImplementationInvestprojectStagesManager()

  const { fields: stepsMonitoringEnded } = useFieldArraySubscribableControl<
    CertificateOfImplementationInvestprojectStagesFormValues,
    typeof certificateOfImplementationInvestprojectStagesBlockValues['3']['stepsMonitoringEnded']
  >({
    control: formInstance.control,
    name: certificateOfImplementationInvestprojectStagesBlockValues['3'].stepsMonitoringEnded,
    watcher: CertificateOfImplementationInvestprojectStagesFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher,
  })

  const handleAddStepMonitoringEnded = async () => {
    await handleAddItemToListWithOutValue?.(
      certificateOfImplementationInvestprojectStagesBlockValues['3'].stepsMonitoringEnded,
    )
  }

  const handleRemoveStepMonitoringEnded = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${certificateOfImplementationInvestprojectStagesBlockValues['3'].stepsMonitoringEnded}.${index}`,
      certificateOfImplementationInvestprojectStagesBlockValues['3'].stepsMonitoringEnded,
    )
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: certificateOfImplementationInvestprojectStagesBlockValues['3']
              .stepsMonitoringEnded,
          })}
        >
          <FlipperList list={stepsMonitoringEnded}>
            {stepsMonitoringEnded.map((stepMonitoringEnded, index) => {
              const formName =
                `${certificateOfImplementationInvestprojectStagesBlockValues['3'].stepsMonitoringEnded}.${index}` as const

              return (
                <SubscribableControl
                  key={stepMonitoringEnded.id}
                  {...getSubscribableControlProps({
                    path: formName,
                  })}
                >
                  <CertificateOfImplementationInvestprojectStagesStepsMonitoringEnded
                    id={stepMonitoringEnded.id}
                    indexOfStep={index}
                    formName={formName}
                    formInstance={formInstance}
                    formModifierInstance={formModifierInstance}
                    onRemove={handleRemoveStepMonitoringEnded(index)}
                  />
                </SubscribableControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddStepMonitoringEnded}>
              {({ isLoading, wrappedPromise }) => (
                <FieldView.ActionButton
                  disabled={isLoading}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  onClick={wrappedPromise}
                >
                  Добавить
                </FieldView.ActionButton>
              )}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Third
