import React, { FC, memo } from 'react'

import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import WebFormDocumentItem from '@components/Attachments/WebForm/WithDocumentController/DocumentItem'
import WebFormDocumentStatus from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DocumentStatus'
import WebFormDocumentDownloadButton from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DownloadButton'
import type { WebFormStateWithDocumentControllerStateProps } from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/types'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import Button from '@components/NewDesign/Button'
import env from '@config'

const WebFormNonDigitizableStateWithDocumentController: FC<
  WebFormStateWithDocumentControllerStateProps
> = ({
  documentInfoFromDict,
  downloadProps,
  document,
  statusType,
  permissions,
  onControlClick,
}) => {
  const { description: title, isVisible } = documentInfoFromDict

  const { isWebFormReadDownloadingForbidden } = useWebFormPermissions(permissions)

  if (isWebFormReadDownloadingForbidden) {
    return (
      <WebFormDocumentItem
        title={title}
        leadingNodeContent={statusType && <WebFormDocumentStatus type={statusType} />}
        document={document}
        onClick={onControlClick}
      />
    )
  }

  if (isVisible && !isWebFormReadDownloadingForbidden) {
    return (
      <WebFormDocumentItem
        document={document}
        title={title}
        leadingNodeContent={statusType && <WebFormDocumentStatus type={statusType} />}
        trailingNodeContent={<WebFormDocumentDownloadButton {...downloadProps} />}
        onClick={onControlClick}
      />
    )
  }

  if (!isVisible) return null

  return (
    <ControlContainer
      color="negative"
      title={title}
      leadingNodeContent={<ControlContainerStatusIcon iconType="error" />}
      additionalMainContent={
        <ControlContainerSubtitle
          additionalContent={
            <Button
              href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}
              size="xs"
              view="text"
              variant="buttonSMedium"
              color="negative"
            >
              Сообщить в поддержку
            </Button>
          }
        >
          Произошла ошибка получения формы документа.
        </ControlContainerSubtitle>
      }
    />
  )
}

export default memo(WebFormNonDigitizableStateWithDocumentController)
