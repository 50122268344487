import React, { FC, memo, useCallback } from 'react'
import { ReactSVG } from 'react-svg'

import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import TextWithTooltip from '@components/NewDesign/TextWithTooltip'
import Typography from '@components/NewDesign/Typography'
import { useNpaPartChangesOldSidebar } from '@components/Sidebars/NPA/PartChanges/PartChangesSidebarOld'
import { objOfDateFormats } from '@constants/dateFormats'
import { Transition } from '@headlessui/react'
import { useHover } from '@hooks/new/events/useHover'
import { useBooleanState } from '@hooks/useBooleanState'
import EditAttachmentIcon from '@icons/attachment/actions/EditAttachmentIcon.svg'
import RemoveAttachmentIcon from '@icons/attachment/actions/RemoveAttachmentIcon.svg'
import PartNpaIcon from '@icons/PartNpaIcon.svg'
import {
  ChosenPart,
  PartCustomProps,
  TPartClassificationLayoutItem,
} from '@services/NPA/NPA.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import EditPartCardOld from './EditOld'
import styles from './PartCardOld.module.scss'

const NPA_INFO_NAME_LIMIT = 78

interface PartCardOldProps {
  part: TPartClassificationLayoutItem
  partCardRef: (ref: HTMLDivElement | null) => void
  onUpdatePart: ({ partCorrection, justification }: PartCustomProps) => void
  onReplaceChosenPart: (newChosen: Omit<ChosenPart, 'classificationId'>) => void
  onRemovePart: () => void
}

const PartCardViewOld: FC<PartCardOldProps> = ({
  part,
  partCardRef,
  onUpdatePart,
  onReplaceChosenPart,
  onRemovePart,
}) => {
  const {
    booleanState: isEditMode,
    setBooleanStateToFalse: disableEditMode,
    setBooleanStateToTrue: enableEditMode,
  } = useBooleanState()

  const [ref, isHovered] = useHover()

  const {
    part: partName,
    npaInfo,
    partCorrection,
    justification,
    isActual: isPartActual,
    isExcluded: isPartExcluded,
  } = part

  const preparedNumberAndDate = `${npaInfo.number} от ${dayjs(
    npaInfo.date,
    objOfDateFormats.defaultNativeDateFormat,
  ).format(objOfDateFormats.defaultFormat)}`

  const onUpdateCard = useCallback(
    (props: PartCustomProps) => {
      onUpdatePart(props)
      disableEditMode()
    },
    [disableEditMode, onUpdatePart],
  )

  const { handleOpenNpaPartChangesSidebar } = useNpaPartChangesOldSidebar()

  const isNotActual = !isPartActual || !npaInfo.isActual
  const isExcluded = isPartExcluded || npaInfo.isExcluded

  return (
    <div ref={partCardRef} className={styles.partCard}>
      {isExcluded && (
        <div className={cn(styles.partCard__notification, styles['partCard__notification-delete'])}>
          <Typography.Body variant={'bodyMMedium'}>
            Положение исключено из реестра и не может быть стабилизировано. Удалите его, чтобы
            продолжить работу
          </Typography.Body>
          <Button view={'plain'} size={'s'} color={'negative'} onClick={onRemovePart}>
            Удалить
          </Button>
        </div>
      )}
      {!isExcluded && isNotActual && (
        <div
          className={cn(styles.partCard__notification, styles['partCard__notification-notActual'])}
        >
          <Typography.Body variant={'bodyMMedium'}>
            Положение изменено. Если изменения вам не подходят, удалите положение
          </Typography.Body>
          <Button
            view={'plain'}
            size={'s'}
            onClick={() =>
              handleOpenNpaPartChangesSidebar({
                initialJustification: justification,
                initialPartCorrection: partCorrection,
                partId: part.id,
                npaId: part.npaInfo.id,
                onReplaceChosenPart,
                onRemovePart,
              })
            }
          >
            Посмотреть изменения
          </Button>
        </div>
      )}
      <div
        ref={ref}
        className={cn(styles.partCard__content, {
          [styles['partCard__content--whiteBackground']]: isEditMode || isHovered,
          [styles['partCard__content--disabledTopBorder']]: isExcluded || isNotActual,
        })}
      >
        <div className={styles.partCard__header}>
          <TextWithTooltip
            variant="bodyLMedium"
            value={npaInfo.name}
            limit={NPA_INFO_NAME_LIMIT}
            className={styles['partCard__header-title']}
            tooltipProps={{
              contentClassName: styles.tooltip__content,
              targetClassName: styles.tooltip__target,
              popoverClassName: styles.tooltip__popover,
              arrowClassName: styles.tooltip__arrow,
              position: 'top',
            }}
          />
          <Typography.Caption
            className={styles['partCard__header-date']}
            variant={'captionSRegular'}
            color={'text-base-tertiary'}
          >
            {preparedNumberAndDate}
          </Typography.Caption>
        </div>
        <Typography.Body className={styles.partCard__partName} variant={'bodyMMedium'}>
          {partName}
        </Typography.Body>
        {!isEditMode ? (
          <>
            {(justification || partCorrection) && (
              <div className={styles.partCard__footer}>
                <ReactSVG src={PartNpaIcon} />
                <div className={styles.partCard__partContent}>
                  {justification && (
                    <Typography.Body variant={'bodyMRegular'} color={'text-base-secondary'}>
                      {justification}
                    </Typography.Body>
                  )}
                  {partCorrection && (
                    <Typography.Body variant={'bodyMRegular'} color={'text-base-secondary'}>
                      {partCorrection}
                    </Typography.Body>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <EditPartCardOld
            initialPartInfo={{
              justification,
              partCorrection,
            }}
            onCancel={disableEditMode}
            onUpdate={onUpdateCard}
          />
        )}

        {!isExcluded && !isNotActual && (
          <div className={styles.partCard__actions}>
            <Transition
              show={!isEditMode && isHovered}
              enter={styles.transition__enter}
              enterFrom={styles.transition__enterFrom}
              enterTo={styles.transition__enterTo}
              leave={styles.transition__leave}
              leaveFrom={styles.transition__leaveFrom}
              leaveTo={styles.transition__leaveTo}
            >
              <IconButton
                className={styles.partCard__icons}
                geometry={'round'}
                size={'s'}
                view={'plain'}
                icon={{
                  src: EditAttachmentIcon,
                }}
                onClick={enableEditMode}
              />

              <IconButton
                className={styles.partCard__icons}
                geometry={'round'}
                size={'s'}
                view={'plain'}
                icon={{
                  noCurrentColorSvgFill: true,
                  src: RemoveAttachmentIcon,
                }}
                onClick={onRemovePart}
              />
            </Transition>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(PartCardViewOld)
