import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import ReasonGroup from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/12/ReasonGroup'
import { twelfthSectionCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/12/validation'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { certificateOnMonitoringResultsReasonGroupList } from './const'

const Twelfth = () => {
  const formInstance = useFormContext<CertificateOnMonitoringResultsFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { getSubscribableControlProps, getCheckboxProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
    })

  const otherReason = useWatch({
    control: formInstance?.control,
    name: certificateOnMonitoringResultsBlockValues['12'].otherReason,
  })

  const otherReasonChecker = useWatch({
    control: formInstance?.control,
    name: certificateOnMonitoringResultsBlockValues['12'].otherReasonChecker,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <AsyncWrapper promise={handleChangeValue}>
          {({ isLoading: isReasonsChecking, wrappedPromise: handleCheckReasons }) => (
            <Row className={styles.form__checkboxRow}>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: certificateOnMonitoringResultsBlockValues['12'].isTerminate,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: certificateOnMonitoringResultsBlockValues['12'].isTerminate,
                      checkBoxProps: {
                        disabled: isReasonsChecking || !editMode,
                        label: 'Есть основания для расторжения',
                      },
                      onChange: () =>
                        setTimeout(
                          () =>
                            handleCheckReasons(
                              certificateOnMonitoringResultsBlockValues['12'].isTerminate,
                            ),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: certificateOnMonitoringResultsBlockValues['12'].isChanges,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: certificateOnMonitoringResultsBlockValues['12'].isChanges,
                      checkBoxProps: {
                        disabled: isReasonsChecking || !editMode,
                        label: 'Есть основания для изменения',
                      },
                      onChange: () =>
                        setTimeout(
                          () =>
                            handleCheckReasons(
                              certificateOnMonitoringResultsBlockValues['12'].isChanges,
                            ),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          )}
        </AsyncWrapper>
        {certificateOnMonitoringResultsReasonGroupList.map(({ name, label }) => (
          <ReasonGroup
            key={name}
            editMode={editMode}
            blockViewIsValidating={blockViewIsValidating}
            reasonName={name}
            checkboxLabel={label}
            formInstance={formInstance}
            subscribableControl={subscribableControl}
            watcher={CertificateOnMonitoringResultsFieldsControlUpdateWatcher}
            debouncedChangeValue={debouncedHandleChangeValue}
            onChangeValue={handleChangeValue}
          />
        ))}
        <Row className={styles.form__checkboxRow}>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['12'].otherReasonChecker,
              })}
            >
              <AsyncWrapper
                promise={async () =>
                  handleChangeValue?.(
                    certificateOnMonitoringResultsBlockValues['12'].otherReasonChecker,
                  )
                }
              >
                {({ isLoading, wrappedPromise }) => (
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: certificateOnMonitoringResultsBlockValues['12'].otherReasonChecker,
                      checkBoxProps: {
                        disabled: isLoading || !editMode,
                        label:
                          'Указать иное основание для изменения\n' + 'или расторжения соглашения',
                      },
                      onChange: () => setTimeout(() => wrappedPromise(), 0),
                    })}
                  />
                )}
              </AsyncWrapper>
            </SubscribableControl>
          </Col>
        </Row>
        <CollapseWrapper isExpanded={otherReason} defaultExpanded={otherReason}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: certificateOnMonitoringResultsBlockValues['12'].otherReasonName,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: certificateOnMonitoringResultsBlockValues['12'].otherReasonName,
                      textareaProps: {
                        disabled: true,
                        label: 'Основание для изменения или\n' + 'расторжения соглашения ',
                        caption: 'значение, введенное инвестором',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: certificateOnMonitoringResultsBlockValues['12'].otherReasonDescription,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: certificateOnMonitoringResultsBlockValues['12'].otherReasonDescription,
                      textareaProps: {
                        disabled: true,
                        label: 'Описание основания',
                        caption: 'значение, введенное инвестором',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </CollapseWrapper>
        <CollapseWrapper isExpanded={otherReasonChecker} defaultExpanded={otherReasonChecker}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: certificateOnMonitoringResultsBlockValues['12'].otherReasonNameChecker,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: certificateOnMonitoringResultsBlockValues['12'].otherReasonNameChecker,
                      rules:
                        twelfthSectionCertificateOnMonitoringResultsValidationMap.otherReasonNameChecker,
                      textareaProps: {
                        label: 'Основание для изменения или\n' + 'расторжения соглашения',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            certificateOnMonitoringResultsBlockValues['12'].otherReasonNameChecker,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            certificateOnMonitoringResultsBlockValues['12'].otherReasonNameChecker,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: certificateOnMonitoringResultsBlockValues['12']
                      .otherReasonDescriptionChecker,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: certificateOnMonitoringResultsBlockValues['12']
                        .otherReasonDescriptionChecker,
                      rules:
                        twelfthSectionCertificateOnMonitoringResultsValidationMap.otherReasonDescriptionChecker,
                      textareaProps: {
                        label: 'Описание основания',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            certificateOnMonitoringResultsBlockValues['12']
                              .otherReasonDescriptionChecker,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            certificateOnMonitoringResultsBlockValues['12']
                              .otherReasonDescriptionChecker,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </CollapseWrapper>
        <Border />
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['12'].changeSuggestions,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: certificateOnMonitoringResultsBlockValues['12'].changeSuggestions,
                  textareaProps: {
                    disabled: true,
                    label: 'Предложения организации по изменению или расторжению соглашения',
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['12'].changeRecommendations,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: certificateOnMonitoringResultsBlockValues['12'].changeRecommendations,
                  rules:
                    twelfthSectionCertificateOnMonitoringResultsValidationMap.changeRecommendations,
                  textareaProps: {
                    label: 'Рекомендации по изменению или расторжению соглашения',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        certificateOnMonitoringResultsBlockValues['12'].changeRecommendations,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        certificateOnMonitoringResultsBlockValues['12'].changeRecommendations,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Twelfth
