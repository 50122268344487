import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import FieldView from '@components/DocumentFormComponents/FieldView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import { secondSectionValidationMap } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/2/validation'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Second = () => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const isPaper = useWatch({
    name: informationOfExecutionConditionsSZPKBlockValues.additionalFields.isPaper,
    control: formInstance?.control,
  })

  const isVatMoney = useWatch({
    name: informationOfExecutionConditionsSZPKBlockValues['2'].isVatMoney,
    control: formInstance?.control,
  })

  const {
    getAmountInputProps,
    getHierarchyReferenceBookSelectProps,
    getTextareaProps,
    getSubscribableControlProps,
    getInputProps,
    getCalendarInputProps,
    getCheckboxProps,
  } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: informationOfExecutionConditionsSZPKBlockValues['2'].projectName,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: informationOfExecutionConditionsSZPKBlockValues['2'].projectName,
                  inputProps: {
                    label: 'Наименование проекта',
                    disabled: true,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <RowWithBorder
          disableFlexNoWrap
          disableTitleLeftPadding
          firstColumnSize={0}
          secondColumnSize={12}
        >
          <Col xs={12}>
            <Group
              disableBottomBorder
              title={
                'Наименование органа, уполномоченного на принятие решения об осуществлении инвестиционного проекта'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['2']
                    .projectDecisionDepartament,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['2']
                      .projectDecisionDepartament,
                      rules: secondSectionValidationMap.projectDecisionDepartament,
                    textareaProps: {
                      label: 'Наименование',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          informationOfExecutionConditionsSZPKBlockValues['2']
                            .projectDecisionDepartament,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          informationOfExecutionConditionsSZPKBlockValues['2']
                            .projectDecisionDepartament,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Group>
          </Col>
        </RowWithBorder>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: informationOfExecutionConditionsSZPKBlockValues['2'].economicSphere,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: informationOfExecutionConditionsSZPKBlockValues['2'].economicSphere,
                  inputProps: {
                    label: 'Сфера экономики',
                    disabled: true,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: informationOfExecutionConditionsSZPKBlockValues['2'].projectOkved,
              })}
            >
              <ControlledHierarchyReferenceBookSelect
                {...getHierarchyReferenceBookSelectProps({
                  dictionaryName: 'okved',
                  controllerProps: {
                    name: informationOfExecutionConditionsSZPKBlockValues['2'].projectOkved,
                    rules: secondSectionValidationMap.projectOkved,
                  },
                  inputProps: {
                    label: 'Код ОКВЭД',
                  },
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        informationOfExecutionConditionsSZPKBlockValues['2'].projectOkved,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder disableBottomDefaultStyles title={'Дата заключения соглашения'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['2'].szpkRegistrationDate,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['2'].szpkRegistrationDate,
                    calendarInputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Предынвестиционная стадия'} groupClassName={'mt-0'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: informationOfExecutionConditionsSZPKBlockValues['2'].startPreinvestStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: informationOfExecutionConditionsSZPKBlockValues['2']
                        .startPreinvestStage,
                      calendarInputProps: {
                        label: 'Дата начала',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startPreinvestStage}`,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startPreinvestStage}`,
                          )
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startPreinvestStage}`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: informationOfExecutionConditionsSZPKBlockValues['2'].endPreinvestStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: informationOfExecutionConditionsSZPKBlockValues['2'].endPreinvestStage,
                      rules: secondSectionValidationMap.endPreinvestStage,
                      calendarInputProps: {
                        label: 'Дата окончания',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endPreinvestStage}`,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endPreinvestStage}`,
                          )
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endPreinvestStage}`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Инвестиционная стадия'} groupClassName={'mt-0'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: informationOfExecutionConditionsSZPKBlockValues['2'].startInvestStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: informationOfExecutionConditionsSZPKBlockValues['2'].startInvestStage,
                      calendarInputProps: {
                        label: 'Дата начала',
                      },
                      rules: secondSectionValidationMap.startInvestStage,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startInvestStage}`,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startInvestStage}`,
                          )
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startInvestStage}`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: informationOfExecutionConditionsSZPKBlockValues['2'].endInvestStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: informationOfExecutionConditionsSZPKBlockValues['2'].endInvestStage,
                      calendarInputProps: {
                        label: 'Дата окончания',
                      },
                      rules: secondSectionValidationMap.endInvestStage,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endInvestStage}`,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endInvestStage}`,
                          )
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endInvestStage}`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Эксплуатационная стадия'} groupClassName={'mt-0'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: informationOfExecutionConditionsSZPKBlockValues['2']
                      .startExploitationStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: informationOfExecutionConditionsSZPKBlockValues['2']
                        .startExploitationStage,
                      calendarInputProps: {
                        label: 'Дата начала',
                      },
                      rules: secondSectionValidationMap.startExploitationStage,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startExploitationStage}`,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startExploitationStage}`,
                          )
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].startExploitationStage}`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: informationOfExecutionConditionsSZPKBlockValues['2'].endExploitationStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: informationOfExecutionConditionsSZPKBlockValues['2']
                        .endExploitationStage,
                      calendarInputProps: {
                        label: 'Дата окончания',
                      },
                      rules: secondSectionValidationMap.endExploitationStage,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endExploitationStage}`,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endExploitationStage}`,
                          )
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${informationOfExecutionConditionsSZPKBlockValues['2'].endExploitationStage}`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        {isPaper && (
          <Row>
            <Col xs={12}>
              <AsyncWrapper
                promise={async () =>
                  handleChangeValue?.(
                    informationOfExecutionConditionsSZPKBlockValues['2'].isVatMoney,
                  )
                }
              >
                {({ isLoading, wrappedPromise }) => (
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: informationOfExecutionConditionsSZPKBlockValues['2'].isVatMoney,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: informationOfExecutionConditionsSZPKBlockValues['2'].isVatMoney,
                        checkBoxProps: {
                          disabled: isLoading || !editMode,
                          label: 'Денежные значения будут указаны с НДС',
                        },
                        onChange: () => setTimeout(() => wrappedPromise(), 0),
                      })}
                    />
                  </SubscribableControl>
                )}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
        <FieldView.ContainerWithSwitchTransition condition={isVatMoney}>
          {(condition) => {
            if (!condition) {
              return (
                <Stack gap={3} direction={'vertical'}>
                  <Row>
                    <Col xs={12}>
                      <RowWithBorder
                        disableBottomDefaultStyles
                        title={'Общий объем капитальных вложений (без НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: informationOfExecutionConditionsSZPKBlockValues['2'].totalCapital,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: informationOfExecutionConditionsSZPKBlockValues['2']
                                .totalCapital,
                              inputProps: {
                                disabled: true,
                              },
                            })}
                          />
                        </SubscribableControl>
                      </RowWithBorder>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <RowWithBorder
                        disableBottomDefaultStyles
                        title={'Общий объем капиталовложений\n' + 'по проекту (без НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: informationOfExecutionConditionsSZPKBlockValues['2']
                              .investorCapital,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: informationOfExecutionConditionsSZPKBlockValues['2']
                                .investorCapital,
                              inputProps: {
                                disabled: true,
                              },
                            })}
                          />
                        </SubscribableControl>
                      </RowWithBorder>
                    </Col>
                  </Row>
                </Stack>
              )
            }

            return (
              <Stack gap={3} direction={'vertical'}>
                <Row>
                  <Col xs={12}>
                    <RowWithBorder
                      disableBottomDefaultStyles
                      title={'Общий объем капитальных вложений (с НДС)'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: informationOfExecutionConditionsSZPKBlockValues['2']
                            .totalCapitalVAT,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: informationOfExecutionConditionsSZPKBlockValues['2']
                              .totalCapitalVAT,
                            inputProps: {
                              disabled: true,
                            },
                          })}
                        />
                      </SubscribableControl>
                    </RowWithBorder>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <RowWithBorder
                      disableBottomDefaultStyles
                      title={'Общий объем капиталовложений\n' + 'по проекту (с НДС)'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: informationOfExecutionConditionsSZPKBlockValues['2']
                            .investorCapitalVAT,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: informationOfExecutionConditionsSZPKBlockValues['2']
                              .investorCapitalVAT,
                            inputProps: {
                              disabled: true,
                            },
                          })}
                        />
                      </SubscribableControl>
                    </RowWithBorder>
                  </Col>
                </Row>
              </Stack>
            )
          }}
        </FieldView.ContainerWithSwitchTransition>
        <Row>
          <Col xs={12}>
            <RowWithBorder disableBottomDefaultStyles title={'Количество рабочих мест'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['2'].newWorkPlaces,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['2'].newWorkPlaces,
                    inputProps: {
                      suffix: '',
                      integersOnly: true,
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: informationOfExecutionConditionsSZPKBlockValues['2'].projectResultEffect,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: informationOfExecutionConditionsSZPKBlockValues['2'].projectResultEffect,
                  rules: secondSectionValidationMap.projectResultEffect,
                  textareaProps: {
                    label: 'Эффекты реализации инвестиционного проекта',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        `${informationOfExecutionConditionsSZPKBlockValues['2'].projectResultEffect}`,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        `${informationOfExecutionConditionsSZPKBlockValues['2'].projectResultEffect}`,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
