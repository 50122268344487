import React from 'react'
import { useFormContext } from 'react-hook-form'

import Group from '@components/DocumentFormComponents/Group'
import StageTaxYears from '@components/Forms/FinancialModelForm/Forms/4/Forms/StageTaxYears'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<FinancialModelFormValues>()

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Group disableBottomBorder groupClassName={'mt-0'} title={'Налоговые выплаты по годам'}>
          <Stack direction={'vertical'} gap={3}>
            <StageTaxYears />
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default Fourth
