import React, { FC, memo, useCallback, useMemo } from 'react'
import { FieldError } from 'react-hook-form'
import { ReactSVG } from 'react-svg'

import AsyncWrapper from '@components/AsyncWrapper'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import TextWithTooltip from '@components/NewDesign/TextWithTooltip'
import Typography from '@components/NewDesign/Typography'
import { useNpaPartChangesSidebar } from '@components/Sidebars/NPA/PartChanges'
import { objOfDateFormats } from '@constants/dateFormats'
import { Transition } from '@headlessui/react'
import { isUndefined } from '@helpers/checkTypes'
import { useHover } from '@hooks/new/events/useHover'
import { useBooleanState } from '@hooks/useBooleanState'
import EditAttachmentIcon from '@icons/attachment/actions/EditAttachmentIcon.svg'
import RemoveAttachmentIcon from '@icons/attachment/actions/RemoveAttachmentIcon.svg'
import PartNpaIcon from '@icons/PartNpaIcon.svg'
import { PartCustomProps, TPartClassificationLayoutItem } from '@services/NPA/NPA.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import EditPartCard from './Edit'
import styles from './PartCard.module.scss'

const NPA_INFO_NAME_LIMIT = 78

interface PartCardProps {
  part: TPartClassificationLayoutItem
  partCardRef: (ref: HTMLDivElement | null) => void
  onUpdatePart: ({ partCorrection, justification, versionId }: PartCustomProps) => Promise<void>
  onRemovePart: () => Promise<void>
  partError?: FieldError
}

const PartCardView: FC<PartCardProps> = ({
  part,
  partCardRef,
  onUpdatePart,
  onRemovePart,
  partError,
}) => {
  const {
    booleanState: isEditMode,
    setBooleanStateToFalse: disableEditMode,
    setBooleanStateToTrue: enableEditMode,
  } = useBooleanState()

  const [ref, isHovered] = useHover()

  const {
    part: partName,
    npaInfo,
    partCorrection,
    justification,
    isActual: isPartActual,
    isExcluded: isPartExcluded,
  } = part

  const preparedNumberAndDate = `${npaInfo.number} от ${dayjs(
    npaInfo.date,
    objOfDateFormats.defaultNativeDateFormat,
  ).format(objOfDateFormats.defaultFormat)}`

  const onUpdateCard = useCallback(
    async (props: PartCustomProps) => {
      await onUpdatePart({
        ...props,
        versionId: part.id,
      })
      disableEditMode()
    },
    [disableEditMode, onUpdatePart, part.id],
  )

  const { handleOpenNpaPartChangesSidebar } = useNpaPartChangesSidebar()

  const isNotActual = !isPartActual || !npaInfo.isActual
  const isExcluded = isPartExcluded || npaInfo.isExcluded

  const handleOpenSidebar = () => {
    handleOpenNpaPartChangesSidebar({
      initialJustification: justification,
      initialPartCorrection: partCorrection,
      partId: part.id,
      npaId: part.npaInfo.id,
      onRemovePart,
      onReplaceChosenPart: onUpdatePart,
    })
  }

  const partNotificationElement = useMemo(() => {
    if (isExcluded) {
      return (
        <div className={cn(styles.partCard__notification, styles['partCard__notification-delete'])}>
          <Typography.Body variant={'bodyMMedium'}>
            Положение исключено из реестра и не может быть стабилизировано. Удалите его, чтобы
            продолжить работу
          </Typography.Body>
          <AsyncWrapper promise={onRemovePart}>
            {({ isLoading, wrappedPromise }) => (
              <Button
                view={'plain'}
                size={'s'}
                color={'negative'}
                disabled={isLoading}
                loaderProps={{
                  loading: isLoading,
                  placement: 'trailing',
                  variant: 'lite',
                }}
                onClick={wrappedPromise}
              >
                Удалить
              </Button>
            )}
          </AsyncWrapper>
        </div>
      )
    }

    if (!isExcluded && isNotActual) {
      return (
        <div
          className={cn(styles.partCard__notification, styles['partCard__notification-notActual'])}
        >
          <Typography.Body variant={'bodyMMedium'}>
            Положение изменено. Если изменения вам не подходят, удалите положение
          </Typography.Body>
          <Button view={'plain'} size={'s'} onClick={handleOpenSidebar}>
            Посмотреть изменения
          </Button>
        </div>
      )
    }

    if (!isUndefined(partError)) {
      return (
        <div className={cn(styles.partCard__notification, styles['partCard__notification-error'])}>
          <Typography.Body variant={'bodyMMedium'}>{partError.message}</Typography.Body>
        </div>
      )
    }

    return null
  }, [handleOpenSidebar, isExcluded, isNotActual, onRemovePart, partError])

  return (
    <div ref={partCardRef} className={styles.partCard}>
      {partNotificationElement}
      <div
        ref={ref}
        className={cn(styles.partCard__content, {
          [styles['partCard__content--whiteBackground']]: isEditMode || isHovered,
          [styles['partCard__content--disabledTopBorder']]: isExcluded || isNotActual,
        })}
      >
        <div className={styles.partCard__header}>
          <TextWithTooltip
            variant="bodyLMedium"
            value={npaInfo.name}
            limit={NPA_INFO_NAME_LIMIT}
            className={styles['partCard__header-title']}
            tooltipProps={{
              contentClassName: styles.tooltip__content,
              targetClassName: styles.tooltip__target,
              popoverClassName: styles.tooltip__popover,
              arrowClassName: styles.tooltip__arrow,
              position: 'top',
            }}
          />
          <Typography.Caption
            className={styles['partCard__header-date']}
            variant={'captionSRegular'}
            color={'text-base-tertiary'}
          >
            {preparedNumberAndDate}
          </Typography.Caption>
        </div>
        <Typography.Body className={styles.partCard__partName} variant={'bodyMMedium'}>
          {partName}
        </Typography.Body>
        {!isEditMode ? (
          <>
            {(justification || partCorrection) && (
              <div className={styles.partCard__footer}>
                <ReactSVG src={PartNpaIcon} />
                <div className={styles.partCard__partContent}>
                  {justification && (
                    <Typography.Body variant={'bodyMRegular'} color={'text-base-secondary'}>
                      {justification}
                    </Typography.Body>
                  )}
                  {partCorrection && (
                    <Typography.Body variant={'bodyMRegular'} color={'text-base-secondary'}>
                      {partCorrection}
                    </Typography.Body>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <EditPartCard
            initialPartInfo={{
              justification,
              partCorrection,
            }}
            onCancel={disableEditMode}
            onUpdate={onUpdateCard}
          />
        )}

        {!isExcluded && !isNotActual && (
          <div className={styles.partCard__actions}>
            <Transition
              show={!isEditMode && isHovered}
              enter={styles.transition__enter}
              enterFrom={styles.transition__enterFrom}
              enterTo={styles.transition__enterTo}
              leave={styles.transition__leave}
              leaveFrom={styles.transition__leaveFrom}
              leaveTo={styles.transition__leaveTo}
            >
              <AsyncWrapper promise={onRemovePart}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <div className={styles['partCard__icons-wrapper']}>
                      <IconButton
                        className={styles.partCard__icons}
                        geometry={'round'}
                        disabled={isLoading}
                        size={'s'}
                        view={'plain'}
                        icon={{
                          src: EditAttachmentIcon,
                        }}
                        onClick={enableEditMode}
                      />

                      {!isLoading ? (
                        <IconButton
                          className={styles.partCard__icons}
                          geometry={'round'}
                          size={'s'}
                          view={'plain'}
                          icon={{
                            noCurrentColorSvgFill: true,
                            src: RemoveAttachmentIcon,
                          }}
                          onClick={wrappedPromise}
                        />
                      ) : (
                        <div
                          className={cn(styles.partCard__icons, styles['partCard__loader-wrapper'])}
                        >
                          <Loader loading variant={'lite'} className={styles.partCard__loader} />
                        </div>
                      )}
                    </div>
                  )
                }}
              </AsyncWrapper>
            </Transition>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(PartCardView)
