import React, { FC, memo } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/NpaLayout.module.scss'
import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import NpaMunicipalForm from '@components/Forms/NpaMunicipalForm'
import Typography from '@components/NewDesign/Typography'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const NpaMunicipalLayout: FC<NpaFormLayoutProps> = (props) => {
  return (
    <NpaMunicipalForm.Manager {...props}>
      <div className={styles['npaLayout--municipal']}>
        <Stack direction={'vertical'} gap={2}>
          <Row className={'px-4'}>
            <Typography.Body variant={'bodyLMedium'} color={'text-base-secondary'}>
              Список муниципальных НПА
            </Typography.Body>
          </Row>
          <NpaMunicipalForm.Actions />
        </Stack>
        <NpaMunicipalForm.Statement />
      </div>
    </NpaMunicipalForm.Manager>
  )
}

export default memo(NpaMunicipalLayout)
