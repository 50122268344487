import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationOfIdentifiedViolations from '@components/Forms/NotificationOfIdentifiedViolationsForm'
import {
  defaultNotificationOfIdentifiedViolationsFormValues,
  mapOfNotificationOfIdentifiedViolationsMenu,
} from '@components/Forms/NotificationOfIdentifiedViolationsForm/const'
import { NotificationOfIdentifiedViolationsFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/types'
import { NotificationOfIdentifiedViolationsFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfIdentifiedViolationsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NotificationOfIdentifiedViolationsLayoutWrapper from './Wrapper'

const NotificationOfIdentifiedViolationsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NotificationOfIdentifiedViolationsFormValues>({
    defaultValues: defaultNotificationOfIdentifiedViolationsFormValues,
  })

  const handleOnClose = () => {
    NotificationOfIdentifiedViolationsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationOfIdentifiedViolations.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfNotificationOfIdentifiedViolationsMenu[0].id}
          >
            <NotificationOfIdentifiedViolationsLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление о выявленных нарушениях
                </Typography.Headline>
                <NotificationOfIdentifiedViolations.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}
              >
                <NotificationOfIdentifiedViolations.Menu className={styles.layout__menu} />
              </div>
            </NotificationOfIdentifiedViolationsLayoutWrapper>
          </FlatMenuManager>
        </NotificationOfIdentifiedViolations.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NotificationOfIdentifiedViolationsLayout)
