import { FC, memo, useMemo } from 'react'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import Icon from '@components/Icon'
import Loader from '@components/Loader'
import { useNavigationToChange } from '@components/NewDesign/ChangesNavigation/useNavigationToChange'
import IconButton from '@components/NewDesign/IconButton'
import Stack from '@components/ReactBootstrap/Stack'
import { isNull } from '@helpers/checkTypes'
import editIcon from '@icons/EditIcon.svg'
import chevronLeft from '@icons/navigation/chevron_left.svg'
import chevronRight from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'
import plural from 'plural-ru'

import styles from './ChangesNavigation.module.scss'

interface ChangesNavigationProps {
  listOfChanges: string[]
  onNavigate?: (path: string) => Promise<void>
  wrapperClassName?: string
  titleClassName?: string
  buttonsClassName?: string
}

const ChangesNavigation: FC<ChangesNavigationProps> = ({
  listOfChanges,
  onNavigate,
  wrapperClassName,
  titleClassName,
  buttonsClassName,
}) => {
  const {
    currentStepOfChanges,
    isNavigateToPreviousChangeLoading,
    isNavigateToNextChangeLoading,
    handleNextChange,
    handlePreviousChange,
  } = useNavigationToChange({
    listOfChanges,
    onNavigate,
  })

  const preparedChangesNavigationTitle = useMemo(() => {
    if (isNull(currentStepOfChanges) || !listOfChanges?.length) {
      return `${listOfChanges.length} ${plural(
        listOfChanges.length || 0,
        'изменение',
        'изменения',
        'изменений',
      )}`
    }

    return `${currentStepOfChanges + 1} из ${listOfChanges.length || 0} ${plural(
      listOfChanges.length || 0,
      'изменение',
      'изменения',
      'изменений',
    )}`
  }, [currentStepOfChanges, listOfChanges.length])

  const isShowChangeButtons = !!listOfChanges.length

  return (
    <div className={cn(styles.changesNavigation, wrapperClassName)}>
      <div className={cn(styles.changesNavigation__title, titleClassName)}>
        <Icon src={editIcon} className={styles['changesNavigation__title-icon']} size={'s'} />
        {preparedChangesNavigationTitle}
      </div>

      <CollapseWrapper isExpanded={isShowChangeButtons}>
        <Stack
          gap={1}
          direction={'horizontal'}
          className={cn(styles.changesNavigation__buttons, buttonsClassName)}
        >
          <Loader
            loading={isNavigateToPreviousChangeLoading}
            variant="lite"
            wrapperClassName={styles['changesNavigation__buttons-loader']}
          >
            <IconButton
              rounded
              disabled={isNavigateToNextChangeLoading}
              className={styles['changesNavigation__buttons-icon']}
              size={'s'}
              geometry={'round'}
              color={'orange'}
              icon={{
                src: chevronLeft,
              }}
              onClick={handlePreviousChange}
            />
          </Loader>
          <Loader
            loading={isNavigateToNextChangeLoading}
            variant="lite"
            wrapperClassName={styles['changesNavigation__buttons-loader']}
          >
            <IconButton
              rounded
              disabled={isNavigateToPreviousChangeLoading}
              className={styles['changesNavigation__buttons-icon']}
              size={'s'}
              geometry={'round'}
              color={'orange'}
              icon={{
                src: chevronRight,
              }}
              onClick={handleNextChange}
            />
          </Loader>
        </Stack>
      </CollapseWrapper>
    </div>
  )
}

export type { ChangesNavigationProps }

export default memo(ChangesNavigation)
