import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  AAVolumeOfCapitalInvestmentsFormValues,
  ICapitalStep,
  OldStepExpense,
  OldStepInvestorCapitalSource,
} from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForAAVolumeOfCapitalInvestmentsForm = (
  objectFromPropertyState: Record<string, any>,
): AAVolumeOfCapitalInvestmentsFormValues => {
  const preparedCapitalSteps = (() => {
    if (
      !isArray(objectFromPropertyState.steps?.value) ||
      !objectFromPropertyState.steps?.value.length
    )
      return []

    return objectFromPropertyState.steps?.value.map((step, index): ICapitalStep => {
      const oldStepExpenses = objectFromPropertyState?.oldStepExpenses?.value?.filter(
        (oldStepExpense) => oldStepExpense?.value?.oldStepIdExpense?.value?.id === step.permanentId,
      )

      const preparedOldStepExpenses = (() => {
        if (!isArray(oldStepExpenses) || !oldStepExpenses.length) return []

        return oldStepExpenses.map(
          (oldStepExpenses): OldStepExpense => ({
            id: oldStepExpenses.propertyId,
            oldStepExpenseName: oldStepExpenses?.value?.oldStepExpenseName?.value || '',
            oldStepExpenseNameCommited: oldStepExpenses?.value?.oldStepExpenseName?.commitedValue,
            oldStepExpenseValue: oldStepExpenses?.value?.oldStepExpenseValue?.value || '',
            oldStepExpenseValueCommited: oldStepExpenses?.value?.oldStepExpenseValue?.commitedValue,
          }),
        )
      })()

      const preparedOldStepInvestorCapitalSources = (() => {
        if (
          !isArray(step?.value.oldStepInvestorCapitalSources?.value) ||
          !step?.value.oldStepInvestorCapitalSources?.value.length
        )
          return []

        return step?.value.oldStepInvestorCapitalSources?.value.map(
          (oldStepInvestorCapitalSource): OldStepInvestorCapitalSource => ({
            id: oldStepInvestorCapitalSource.propertyId,
            oldStepInvestorCapitalSourceCommitted: oldStepInvestorCapitalSource?.commitedValue,
            oldStepInvestorCapitalSource: oldStepInvestorCapitalSource?.value || '',
          }),
        )
      })()

      return {
        id: step.propertyId,
        anchorId: `5.${index + 1}`,
        stepNumber: step?.value.stepNumber?.value,
        stepDescription: step?.value.stepDescription?.value,
        stepInvestorCapitalValue: !!objectFromPropertyState?.isPaper?.value
          ? step?.value.stepInvestorCapitalValueVAT?.value
          : step?.value.stepInvestorCapitalValue?.value,
        stepInvestorCapitalValueCommited: !!objectFromPropertyState?.isPaper?.value
          ? step?.value.stepInvestorCapitalValueVAT?.commitedValue
          : step?.value.stepInvestorCapitalValue?.commitedValue,
        stepInvestorCapitalDate: step?.value.stepInvestorCapitalDate?.value,
        stepInvestorCapitalDateCommited: step?.value.stepInvestorCapitalDate?.commitedValue,
        oldStepInvestorCapitalSources: preparedOldStepInvestorCapitalSources,
        oldStepExpenses: preparedOldStepExpenses,
      }
    })
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      capitalSteps: preparedCapitalSteps,
    },
    '6': {
      investorCapital: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.investorCapitalVAT?.value
        : objectFromPropertyState.investorCapital?.value,
      investorCapitalCommited: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.investorCapitalVAT?.commitedValue
        : objectFromPropertyState.investorCapital?.commitedValue,
      totalCapital: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.totalCapitalVAT?.value
        : objectFromPropertyState.totalCapital?.value,
      totalCapitalCommited: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.totalCapitalVAT?.commitedValue
        : objectFromPropertyState.totalCapital?.commitedValue,
      remainsInvestorCapital: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.remainsInvestorCapitalVAT?.value
        : objectFromPropertyState.remainsInvestorCapital?.value,
      remainsInvestorCapitalCommited: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.remainsInvestorCapitalVAT?.commitedValue
        : objectFromPropertyState.remainsInvestorCapital?.commitedValue,
      madeInvestorCapitalDate: objectFromPropertyState.madeInvestorCapitalDate?.value,
      madeInvestorCapitalDateCommited:
        objectFromPropertyState.madeInvestorCapitalDate?.commitedValue,
    },
    additionalFields: {
      isFederal: objectFromPropertyState?.isFederal?.value,
      isPaper: objectFromPropertyState?.isPaper?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  }
}

export { generateRHFObjectForAAVolumeOfCapitalInvestmentsForm }
