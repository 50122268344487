import React, { FC, memo } from 'react'

import Input from '@components/NewDesign/Input'
import { InputProps } from '@components/NewDesign/Input/types'
import MetaItem, { MetaItemProps } from '@components/Sidebars/DocumentView/MetaItem'
import useInput from '@hooks/useInput'

interface MetaInputItemProps {
  metaItemProps: Omit<MetaItemProps, 'metaInfo'>
  inputProps: InputProps
}

const MetaInputItem: FC<MetaInputItemProps> = ({ metaItemProps, inputProps }) => {
  const { value, setValue } = useInput(inputProps.value)
  return (
    <MetaItem
      {...metaItemProps}
      metaInfo={
        <Input
          {...inputProps}
          value={value}
          size={'s'}
          onChange={(e) => setValue(e.currentTarget.value)}
          onBlur={inputProps.onBlur}
        />
      }
    />
  )
}

export default memo(MetaInputItem)
