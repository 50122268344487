import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import { secondSectionCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/2/validation'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Second = () => {
  const formInstance = useFormContext<CertificateOnMonitoringResultsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getTextareaProps,
    getInputProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['2'].initiatorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
                controllerProps={getControllerProps({
                  name: certificateOnMonitoringResultsBlockValues['2'].initiatorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['2'].initiatorFullNameInstrumental,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: certificateOnMonitoringResultsBlockValues['2'].initiatorFullNameInstrumental,
                  textareaProps: {
                    label: 'Наименование в творительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          certificateOnMonitoringResultsBlockValues['2'].initiatorFullNameInstrumental,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        certificateOnMonitoringResultsBlockValues['2'].initiatorFullNameInstrumental,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['2'].initiatorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: certificateOnMonitoringResultsBlockValues['2'].initiatorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['2'].initiatorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: certificateOnMonitoringResultsBlockValues['2'].initiatorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['2']
                  .initiatorAddressFullEgrulFormat,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Адрес'}
                controllerProps={getControllerProps({
                  name: certificateOnMonitoringResultsBlockValues['2']
                    .initiatorAddressFullEgrulFormat,
                })}
                formIconTooltipProps={{
                  tooltipContent: 'Адрес можно изменить в разделе "Моя организация"',
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Уполномоченное должностное лицо'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: certificateOnMonitoringResultsBlockValues['2'].initiatorHeaderName,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.headerName || '',
                            value: item.id,
                          }),
                          controllerProps: {
                            name: certificateOnMonitoringResultsBlockValues['2']
                              .initiatorHeaderName,
                            rules:
                              secondSectionCertificateOnMonitoringResultsValidationMap.initiatorHeaderName,
                          },
                          selectProps: {
                            optionsProps: {
                            },
                            inputProps: {
                              label: 'Фамилия, Имя, Отчество',
                            },
                            onChangeFormValue: () =>
                              setTimeout(() => {
                                handleChangeValue?.(
                                  certificateOnMonitoringResultsBlockValues['2']
                                    .initiatorHeaderName,
                                )
                              }, 0),
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: certificateOnMonitoringResultsBlockValues['2'].initiatorHeaderPosition,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: certificateOnMonitoringResultsBlockValues['2'].initiatorHeaderPosition,
                      inputProps: {
                        disabled: true,
                        label: 'Должность',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            certificateOnMonitoringResultsBlockValues['2'].initiatorHeaderPosition,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            certificateOnMonitoringResultsBlockValues['2'].initiatorHeaderPosition,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default Second
