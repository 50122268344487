import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { StatementByHeadOfMunicipalityFormValues } from '@components/Forms/StatementByHeadOfMunicipality/types'

const {
  getDefaultNewMunicipalityFieldState,
  getDefaultInvestorFieldState,
  getDefaultInfoAboutDocument,
} = FieldStateHelpers

const mapOfStatementByHeadOfMunicipalityMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Муниципальное образование' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Информация о проекте' },
]

const defaultStatementByHeadOfMunicipalityFormValues: StatementByHeadOfMunicipalityFormValues = {
  '1': getDefaultInfoAboutDocument(),
  '2': getDefaultNewMunicipalityFieldState(),
  '3': {
    ...getDefaultInvestorFieldState(),
    investorInn: '',
    investorOgrn: '',
    investorAddress: '',
  },
  '4': {
    projectName: '',
  },
}

const statementByHeadOfMunicipalityBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    municipalityFullName: '2.municipalityFullName',
    municipalityFullNameGenitive: '2.municipalityFullNameGenitive',
    municipalityHeaderName: '2.municipalityHeaderName',
    municipalityHeaderNameGenitive: '2.municipalityHeaderNameGenitive',
    municipalityHeaderPosition: '2.municipalityHeaderPosition',
    municipalityHeaderPositionGenitive: '2.municipalityHeaderPositionGenitive',
    municipalityHeaderReasonGenitive: '2.municipalityHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorAddress: '3.investorAddress',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
  },
  '4': {
    projectName: '4.projectName',
  },
} as const

export {
  defaultStatementByHeadOfMunicipalityFormValues,
  mapOfStatementByHeadOfMunicipalityMenu,
  statementByHeadOfMunicipalityBlockValues,
}
