import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForParametersOfCostRecoveryApplicationForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const checkboxes = (() => {
    const updateValueForCheckbox = {
      propertyId: objectFromPropertyState.compensationStatementBudgetLevels?.propertyId,
      type: objectFromPropertyState.compensationStatementBudgetLevels?.type,
      lastUpdateDt: objectFromPropertyState.compensationStatementBudgetLevels?.lastUpdateDt,
    }

    const chosenCheckboxes = {
      isFederal: updateValueForCheckbox,
      isRegional: updateValueForCheckbox,
      isLocal: updateValueForCheckbox,
    }

    return chosenCheckboxes
  })()

  const preparedCompensationStatementPaidTaxes = (() => {
    if (
      !isArray(objectFromPropertyState.compensationStatementPaidTaxes?.value) ||
      !objectFromPropertyState.compensationStatementPaidTaxes?.value?.length
    )
      return []

    return objectFromPropertyState.compensationStatementPaidTaxes?.value.map(
      (compensationStatementPaidTax) => {
        const preparedCompensationStatementPaidTaxValues = (() => {
          if (
            !isArray(
              compensationStatementPaidTax.value?.compensationStatementPaidTaxValues?.value,
            ) ||
            !compensationStatementPaidTax.value?.compensationStatementPaidTaxValues?.value?.length
          )
            return []

          return compensationStatementPaidTax.value?.compensationStatementPaidTaxValues?.value.map(
            (paidTaxValue) => ({
              propertyId: paidTaxValue.propertyId,
              lastUpdateDt: paidTaxValue.lastUpdateDt,
              type: paidTaxValue.type,
              value: {
                paidTaxYear: {
                  propertyId: paidTaxValue.value?.paidTaxYear?.propertyId,
                  type: paidTaxValue.value?.paidTaxYear?.type,
                  lastUpdateDt: paidTaxValue.value?.paidTaxYear?.lastUpdateDt,
                },
                paidTaxYearValue: {
                  propertyId: paidTaxValue.value?.paidTaxYearValue?.propertyId,
                  type: paidTaxValue.value?.paidTaxYearValue?.type,
                  lastUpdateDt: paidTaxValue.value?.paidTaxYearValue?.lastUpdateDt,
                },
              },
            }),
          )
        })()

        return {
          propertyId: compensationStatementPaidTax.propertyId,
          lastUpdateDt: compensationStatementPaidTax.lastUpdateDt,
          type: compensationStatementPaidTax.type,
          value: {
            compensationStatementPaidTaxTaxKindName: {
              propertyId:
                compensationStatementPaidTax.value?.compensationStatementPaidTaxTaxKind?.propertyId,
              type: compensationStatementPaidTax.value?.compensationStatementPaidTaxTaxKind?.type,
              lastUpdateDt:
                compensationStatementPaidTax.value?.compensationStatementPaidTaxTaxKind
                  ?.lastUpdateDt,
            },
            compensationStatementPaidTaxValues: {
              propertyId:
                compensationStatementPaidTax.value?.compensationStatementPaidTaxValues?.propertyId,
              type: compensationStatementPaidTax.value?.compensationStatementPaidTaxValues?.type,
              lastUpdateDt:
                compensationStatementPaidTax.value?.compensationStatementPaidTaxValues
                  ?.lastUpdateDt,
              value: preparedCompensationStatementPaidTaxValues,
            },
          },
        }
      },
    )
  })()

  const preparedExpenseSubTypes = (() => {
    if (
      !isArray(objectFromPropertyState.compensationStatementExpenseSubTypes?.value) ||
      !objectFromPropertyState.compensationStatementExpenseSubTypes?.value?.length
    )
      return []

    return objectFromPropertyState.compensationStatementExpenseSubTypes?.value.map(
      (compensationStatementExpenseSubType) => {
        const preparedCompensationObjects = (() => {
          if (
            !isArray(compensationStatementExpenseSubType.value?.compensationObjects?.value) ||
            !compensationStatementExpenseSubType.value?.compensationObjects?.value?.length
          )
            return []

          return compensationStatementExpenseSubType.value?.compensationObjects?.value.map(
            (compensationObject) => {
              const preparedCompensationObjectExpenses = (() => {
                if (
                  !isArray(compensationObject.value?.compensationObjectExpenses?.value) ||
                  !compensationObject.value?.compensationObjectExpenses?.value?.length
                )
                  return []

                return compensationObject.value?.compensationObjectExpenses?.value.map(
                  (compensationObjectExpense) => ({
                    propertyId: compensationObjectExpense.propertyId,
                    type: compensationObjectExpense.type,
                    lastUpdateDt: compensationObjectExpense.lastUpdateDt,
                    value: {
                      compensationObjectExpenseValue: {
                        propertyId:
                          compensationObjectExpense.value?.compensationObjectExpenseValue
                            ?.propertyId,
                        type: compensationObjectExpense.value?.compensationObjectExpenseValue?.type,
                        lastUpdateDt:
                          compensationObjectExpense.value?.compensationObjectExpenseValue
                            ?.lastUpdateDt,
                      },
                      compensationObjectExpenseYear: {
                        propertyId:
                          compensationObjectExpense.value?.compensationObjectExpenseYear
                            ?.propertyId,
                        type: compensationObjectExpense.value?.compensationObjectExpenseYear?.type,
                        lastUpdateDt:
                          compensationObjectExpense.value?.compensationObjectExpenseYear
                            ?.lastUpdateDt,
                      },
                    },
                  }),
                )
              })()

              const preparedCompensationObjectPaidTaxLandValues = (() => {
                if (
                  !isArray(compensationObject.value?.compensationObjectPaidTaxLandValues?.value) ||
                  !compensationObject.value?.compensationObjectPaidTaxLandValues?.value?.length
                )
                  return []

                return compensationObject.value?.compensationObjectPaidTaxLandValues?.value.map(
                  (paidTaxValue) => ({
                    propertyId: paidTaxValue.propertyId,
                    type: paidTaxValue.type,
                    lastUpdateDt: paidTaxValue.lastUpdateDt,
                    value: {
                      paidTaxYear: {
                        propertyId: paidTaxValue.value?.paidTaxYear?.propertyId,
                        type: paidTaxValue.value?.paidTaxYear?.type,
                        lastUpdateDt: paidTaxValue.value?.paidTaxYear?.lastUpdateDt,
                      },
                      paidTaxYearValue: {
                        propertyId: paidTaxValue.value?.paidTaxYearValue?.propertyId,
                        type: paidTaxValue.value?.paidTaxYearValue?.type,
                        lastUpdateDt: paidTaxValue.value?.paidTaxYearValue?.lastUpdateDt,
                      },
                    },
                  }),
                )
              })()

              const preparedCompensationObjectPaidTaxLegalPropertyValues = (() => {
                if (
                  !isArray(
                    compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues?.value,
                  ) ||
                  !compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues?.value
                    ?.length
                )
                  return []

                return compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues?.value.map(
                  (paidTaxValue) => ({
                    propertyId: paidTaxValue.propertyId,
                    type: paidTaxValue.type,
                    lastUpdateDt: paidTaxValue.lastUpdateDt,
                    value: {
                      paidTaxYear: {
                        propertyId: paidTaxValue.value?.paidTaxYear?.propertyId,
                        type: paidTaxValue.value?.paidTaxYear?.type,
                        lastUpdateDt: paidTaxValue.value?.paidTaxYear?.lastUpdateDt,
                      },
                      paidTaxYearValue: {
                        propertyId: paidTaxValue.value?.paidTaxYearValue?.propertyId,
                        type: paidTaxValue.value?.paidTaxYearValue?.type,
                        lastUpdateDt: paidTaxValue.value?.paidTaxYearValue?.lastUpdateDt,
                      },
                    },
                  }),
                )
              })()

              return {
                propertyId: compensationObject.propertyId,
                type: compensationObject.type,
                lastUpdateDt: compensationObject.lastUpdateDt,
                value: {
                  compensationObjectAgreementDate: {
                    propertyId:
                      compensationObject.value?.compensationObjectAgreementDate?.propertyId,
                    type: compensationObject.value?.compensationObjectAgreementDate?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectAgreementDate?.lastUpdateDt,
                  },
                  compensationObjectAgreementNumber: {
                    propertyId:
                      compensationObject.value?.compensationObjectAgreementNumber?.propertyId,
                    type: compensationObject.value?.compensationObjectAgreementNumber?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectAgreementNumber?.lastUpdateDt,
                  },
                  compensationObjectBondNumber: {
                    propertyId: compensationObject.value?.compensationObjectBondNumber?.propertyId,
                    type: compensationObject.value?.compensationObjectBondNumber?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectBondNumber?.lastUpdateDt,
                  },
                  compensationObjectCreditorName: {
                    propertyId:
                      compensationObject.value?.compensationObjectCreditorName?.propertyId,
                    type: compensationObject.value?.compensationObjectCreditorName?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectCreditorName?.lastUpdateDt,
                  },
                  compensationObjectExpensesTotalValue: {
                    propertyId:
                      compensationObject.value?.compensationObjectExpensesTotalValue?.propertyId,
                    type: compensationObject.value?.compensationObjectExpensesTotalValue?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectExpensesTotalValue?.lastUpdateDt,
                  },
                  compensationObjectPaidTaxLandTotalValue: {
                    propertyId:
                      compensationObject.value?.compensationObjectPaidTaxLandTotalValue?.propertyId,
                    type: compensationObject.value?.compensationObjectPaidTaxLandTotalValue?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectPaidTaxLandTotalValue
                        ?.lastUpdateDt,
                  },
                  compensationObjectPaidTaxLegalPropertyTotalValue: {
                    propertyId:
                      compensationObject.value?.compensationObjectPaidTaxLegalPropertyTotalValue
                        ?.propertyId,
                    type: compensationObject.value?.compensationObjectPaidTaxLegalPropertyTotalValue
                      ?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectPaidTaxLegalPropertyTotalValue
                        ?.lastUpdateDt,
                  },

                  compensationObjectTaxLandAreaNumber: {
                    propertyId:
                      compensationObject.value?.compensationObjectTaxLandAreaNumber?.propertyId,
                    type: compensationObject.value?.compensationObjectTaxLandAreaNumber?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectTaxLandAreaNumber?.lastUpdateDt,
                  },
                  compensationObjectTaxLandRefundPeriod: {
                    propertyId:
                      compensationObject.value?.compensationObjectTaxLandRefundPeriod?.propertyId,
                    type: compensationObject.value?.compensationObjectTaxLandRefundPeriod?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectTaxLandRefundPeriod?.lastUpdateDt,
                  },
                  compensationObjectTaxLegalPropertyCadastralNumber: {
                    propertyId:
                      compensationObject.value?.compensationObjectTaxLegalPropertyCadastralNumber
                        ?.propertyId,
                    type: compensationObject.value
                      ?.compensationObjectTaxLegalPropertyCadastralNumber?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectTaxLegalPropertyCadastralNumber
                        ?.lastUpdateDt,
                  },

                  compensationObjectTaxLegalPropertyOtherIdNumber: {
                    propertyId:
                      compensationObject.value?.compensationObjectTaxLegalPropertyOtherIdNumber
                        ?.propertyId,
                    type: compensationObject.value?.compensationObjectTaxLegalPropertyOtherIdNumber
                      ?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectTaxLegalPropertyOtherIdNumber
                        ?.lastUpdateDt,
                  },
                  compensationObjectTaxLegalPropertyRefundPeriod: {
                    propertyId:
                      compensationObject.value?.compensationObjectTaxLegalPropertyRefundPeriod
                        ?.propertyId,
                    type: compensationObject.value?.compensationObjectTaxLegalPropertyRefundPeriod
                      ?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectTaxLegalPropertyRefundPeriod
                        ?.lastUpdateDt,
                  },
                  compensationObjectTypeName: {
                    propertyId: compensationObject.value?.compensationObjectType?.propertyId,
                    type: compensationObject.value?.compensationObjectType?.type,
                    lastUpdateDt: compensationObject.value?.compensationObjectType?.lastUpdateDt,
                  },
                  compensationObjectTypeSystemName: {
                    propertyId: compensationObject.value?.paidTaxYear?.propertyId,
                    type: compensationObject.value?.paidTaxYear?.type,
                    lastUpdateDt: compensationObject.value?.paidTaxYear?.lastUpdateDt,
                  },
                  finalBalanceHolder: {
                    propertyId: compensationObject.value?.finalBalanceHolder?.propertyId,
                    type: compensationObject.value?.finalBalanceHolder?.type,
                    lastUpdateDt: compensationObject.value?.finalBalanceHolder?.lastUpdateDt,
                  },
                  infrastructureKindCode: {
                    propertyId: compensationObject.value?.infrastructureKind?.propertyId,
                    type: compensationObject.value?.infrastructureKind?.type,
                    lastUpdateDt: compensationObject.value?.infrastructureKind?.lastUpdateDt,
                  },
                  isTaxLandPropertyObject: {
                    propertyId: compensationObject.value?.isTaxLandPropertyObject?.propertyId,
                    type: compensationObject.value?.isTaxLandPropertyObject?.type,
                    lastUpdateDt: compensationObject.value?.isTaxLandPropertyObject?.lastUpdateDt,
                  },
                  isTaxLegalPropertyObject: {
                    propertyId: compensationObject.value?.isTaxLegalPropertyObject?.propertyId,
                    type: compensationObject.value?.isTaxLegalPropertyObject?.type,
                    lastUpdateDt: compensationObject.value?.isTaxLegalPropertyObject?.lastUpdateDt,
                  },

                  objectName: {
                    propertyId: compensationObject.value?.objectName?.propertyId,
                    type: compensationObject.value?.objectName?.type,
                    lastUpdateDt: compensationObject.value?.objectName?.lastUpdateDt,
                  },
                  projectDecisionDate: {
                    propertyId: objectFromPropertyState?.projectDecisionDate?.propertyId,
                    type: objectFromPropertyState?.projectDecisionDate?.type,
                    lastUpdateDt: objectFromPropertyState?.projectDecisionDate?.lastUpdateDt,
                  },
                  projectObjectValueName: {
                    propertyId: compensationObject.value?.projectObjectValue?.propertyId,
                    type: compensationObject.value?.projectObjectValue?.type,
                    lastUpdateDt: compensationObject.value?.projectObjectValue?.lastUpdateDt,
                  },
                  compensationObjectExpenses: {
                    propertyId: compensationObject.value?.compensationObjectExpenses?.propertyId,
                    type: compensationObject.value?.compensationObjectExpenses?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectExpenses?.lastUpdateDt,
                    value: preparedCompensationObjectExpenses,
                  },
                  compensationObjectPaidTaxLandValues: {
                    propertyId:
                      compensationObject.value?.compensationObjectPaidTaxLandValues?.propertyId,
                    type: compensationObject.value?.compensationObjectPaidTaxLandValues?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectPaidTaxLandValues?.lastUpdateDt,
                    value: preparedCompensationObjectPaidTaxLandValues,
                  },
                  compensationObjectPaidTaxLegalPropertyValues: {
                    propertyId:
                      compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues
                        ?.propertyId,
                    type: compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues
                      ?.type,
                    lastUpdateDt:
                      compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues
                        ?.lastUpdateDt,
                    value: preparedCompensationObjectPaidTaxLegalPropertyValues,
                  },
                },
              }
            },
          )
        })()

        return {
          propertyId: compensationStatementExpenseSubType.propertyId,
          type: compensationStatementExpenseSubType.type,
          lastUpdateDt: compensationStatementExpenseSubType.lastUpdateDt,
          value: {
            compensationStatementExpenseSubTypeTaxRefundPeriod: {
              propertyId:
                compensationStatementExpenseSubType.value
                  ?.compensationStatementExpenseSubTypeTaxRefundPeriod?.propertyId,
              type: compensationStatementExpenseSubType.value
                ?.compensationStatementExpenseSubTypeTaxRefundPeriod?.type,
              lastUpdateDt:
                compensationStatementExpenseSubType.value
                  ?.compensationStatementExpenseSubTypeTaxRefundPeriod?.lastUpdateDt,
            },
            compensationStatementExpenseSubTypeValueFullName: {
              propertyId:
                compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
                  ?.propertyId,
              type: compensationStatementExpenseSubType.value
                ?.compensationStatementExpenseSubTypeValue?.type,
              lastUpdateDt:
                compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
                  ?.lastUpdateDt,
            },

            compensationStatementExpenseSubTypeValueSystemName: {
              propertyId:
                compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
                  ?.propertyId,
              type: compensationStatementExpenseSubType.value
                ?.compensationStatementExpenseSubTypeValue?.type,
              lastUpdateDt:
                compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
                  ?.lastUpdateDt,
            },
            compensationStatementExpenseSubTypeName: {
              propertyId:
                compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
                  ?.propertyId,
              type: compensationStatementExpenseSubType.value
                ?.compensationStatementExpenseSubTypeValue?.type,
              lastUpdateDt:
                compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
                  ?.lastUpdateDt,
            },
            compensationObjects: {
              propertyId:
                compensationStatementExpenseSubType.value?.compensationObjects?.propertyId,
              type: compensationStatementExpenseSubType.value?.compensationObjects?.type,
              lastUpdateDt:
                compensationStatementExpenseSubType.value?.compensationObjects?.lastUpdateDt,
              value: preparedCompensationObjects,
            },
          },
        }
      },
    )
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
      szpkNumber: {
        propertyId: objectFromPropertyState.szpkNumber?.propertyId,
        type: objectFromPropertyState.szpkNumber?.type,
        lastUpdateDt: objectFromPropertyState.szpkNumber?.lastUpdateDt,
      },
      szpkRegistrationDate: {
        propertyId: objectFromPropertyState.szpkRegistrationDate?.propertyId,
        type: objectFromPropertyState.szpkRegistrationDate?.type,
        lastUpdateDt: objectFromPropertyState.szpkRegistrationDate?.lastUpdateDt,
      },
    },
    '3': {
      ...checkboxes,
      compensationStatementForm: {
        propertyId: objectFromPropertyState.compensationStatementForm?.propertyId,
        type: objectFromPropertyState.compensationStatementForm?.type,
        lastUpdateDt: objectFromPropertyState.compensationStatementForm?.lastUpdateDt,
      },
      compensationStatementYear: {
        propertyId: objectFromPropertyState.compensationStatementYear?.propertyId,
        type: objectFromPropertyState.compensationStatementYear?.type,
        lastUpdateDt: objectFromPropertyState.compensationStatementYear?.lastUpdateDt,
      },
      //Фейковое свойство
      compensationStatementBudgetLevels: {
        propertyId: objectFromPropertyState.compensationStatementBudgetLevels?.propertyId,
        type: objectFromPropertyState.compensationStatementBudgetLevels?.type,
        lastUpdateDt: objectFromPropertyState.compensationStatementBudgetLevels?.lastUpdateDt,
        value: objectFromPropertyState.compensationStatementBudgetLevels?.value.map((name) => ({
          propertyId: name.propertyId,
          type: name?.type,
          lastUpdateDt: name?.lastUpdateDt,
        })),
      },
    },
    '4': {
      compensationStatementExpenseGroupName: {
        propertyId: objectFromPropertyState.compensationStatementExpenseGroup?.propertyId,
        type: objectFromPropertyState.compensationStatementExpenseGroup?.type,
        lastUpdateDt: objectFromPropertyState.compensationStatementExpenseGroup?.lastUpdateDt,
      },
      compensationStatementExpenseGroupSystemName: {
        propertyId: objectFromPropertyState.compensationStatementExpenseGroup?.propertyId,
        type: objectFromPropertyState.compensationStatementExpenseGroup?.type,
        lastUpdateDt: objectFromPropertyState.compensationStatementExpenseGroup?.lastUpdateDt,
      },
      compensationStatementTaxLandPropertyRefundTotalValue: {
        propertyId:
          objectFromPropertyState.compensationStatementTaxLandPropertyRefundTotalValue?.propertyId,
        type: objectFromPropertyState.compensationStatementTaxLandPropertyRefundTotalValue?.type,
        lastUpdateDt:
          objectFromPropertyState.compensationStatementTaxLandPropertyRefundTotalValue
            ?.lastUpdateDt,
      },
      compensationStatementTaxLegalPropertyRefundTotalValue: {
        propertyId:
          objectFromPropertyState.compensationStatementTaxLegalPropertyRefundTotalValue?.propertyId,
        type: objectFromPropertyState.compensationStatementTaxLegalPropertyRefundTotalValue?.type,
        lastUpdateDt:
          objectFromPropertyState.compensationStatementTaxLegalPropertyRefundTotalValue
            ?.lastUpdateDt,
      },
      compensationStatementTypeSystemName: {
        propertyId: objectFromPropertyState.compensationStatementType?.propertyId,
        type: objectFromPropertyState.compensationStatementType?.type,
        lastUpdateDt: objectFromPropertyState.compensationStatementType?.lastUpdateDt,
      },
      compensationStatementExpenseSubTypes: {
        propertyId: objectFromPropertyState.compensationStatementExpenseSubTypes?.propertyId,
        type: objectFromPropertyState.compensationStatementExpenseSubTypes?.type,
        lastUpdateDt: objectFromPropertyState.compensationStatementExpenseSubTypes?.lastUpdateDt,
        value: preparedExpenseSubTypes,
      },
      //Фейковое свойство
      compensationStatementExpenseSubTypesNotExist: {
        propertyId:
          objectFromPropertyState.compensationStatementExpenseSubTypes?.propertyId,
        type: objectFromPropertyState.compensationStatementExpenseSubTypes?.type,
        lastUpdateDt:
          objectFromPropertyState.compensationStatementExpenseSubTypes?.lastUpdateDt,
        value: objectFromPropertyState.compensationStatementExpenseSubTypes?.value.map(
          (name) => ({
            propertyId: name.propertyId,
            type: name?.type,
            lastUpdateDt: name?.lastUpdateDt,
          }),
        ),
      },
    },
    '5': {
      compensationStatementPaidTaxes: {
        propertyId: objectFromPropertyState.compensationStatementPaidTaxes?.propertyId,
        type: objectFromPropertyState.compensationStatementPaidTaxes?.type,
        lastUpdateDt: objectFromPropertyState.compensationStatementPaidTaxes?.lastUpdateDt,
        value: preparedCompensationStatementPaidTaxes,
      },
    },
  }
}

export { generatePropertiesObjectForParametersOfCostRecoveryApplicationForm }
