import { UseFormReturn, useWatch } from 'react-hook-form'

import { objOfDateFormats } from '@constants/dateFormats'
import DayjsService from '@services/Dayjs/Dayjs.service'

import { informationOfExecutionAgreementEventsBlockValues } from '../const'
import { InformationOfExecutionAgreementEventsFormValues } from '../types'

export const useMonitoringReportDateSubtitle = (
  formInstance: UseFormReturn<InformationOfExecutionAgreementEventsFormValues, any>,
) => {
  const monitoringReportDate = useWatch({
    name: informationOfExecutionAgreementEventsBlockValues['1'].monitoringReportDate,
    control: formInstance.control,
  })

  const monitoringReportDateFullMonth = monitoringReportDate
    ? DayjsService.dayjs(monitoringReportDate, objOfDateFormats.defaultFormat).format(
        objOfDateFormats.formatWithFullMonth,
      )
    : ''

  const monitoringReportDateSubtitle = monitoringReportDateFullMonth
    ? `на ${monitoringReportDateFullMonth}`
    : ''

  return monitoringReportDateSubtitle
}
