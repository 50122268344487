import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionAgreementStabilizationNegotiationsValidationMap: FormValuesValidationSection<
  keyof AgreementStabilizationNegotiationsFormValues['3']['municipalities'][number],
  AgreementStabilizationNegotiationsFormValues
> = {
  municipalityFullNameGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },

  municipalityHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { thirdSectionAgreementStabilizationNegotiationsValidationMap }
