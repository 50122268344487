import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const sixthSectionAAgreementForceCircumstancesValidationMap: FormValuesValidationSection<
  keyof AAgreementForceCircumstancesFormValues['6'],
  AAgreementForceCircumstancesFormValues
> = {
  projectEndDate: {
    required: defaultRHFValidation.required,
  },
  agreementLinkProjectPeriod: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { sixthSectionAAgreementForceCircumstancesValidationMap }
