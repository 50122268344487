import { ChangeEvent, FocusEvent, MouseEvent } from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import Textarea from './Textarea'
import { TextareaProps } from './types'

type TControlledTextareaProps<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> &
  Pick<TextareaProps, 'onClear' | 'onBlur' | 'onChange'| 'id'> & {
    textareaProps: Omit<
      TextareaProps,
      | 'onBlur'
      | 'onChange'
      | 'onFocus'
      | 'onPaste'
      | 'onClear'
      | 'onKeyDown'
      | 'value'
      | 'ref'
      | 'error'
    >
  }

export const ControllerTextarea = <T extends FieldValues>(props: TControlledTextareaProps<T>) => {
  const { onClear, onChange, onBlur, textareaProps, id, ...controllerProps } = props

  const {
    field: { onChange: onFormChange, onBlur: onFormBlur, value, ref },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const mergedOnBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    onBlur?.(e)

    onFormBlur()
  }

  const mergedOnClear = (e: MouseEvent<HTMLButtonElement>) => {
    onClear?.(e)

    onFormChange('')
  }

  const mergedOnChange = (e: ChangeEvent<HTMLTextAreaElement>, payload) => {
    onChange?.(e, payload)
    onFormChange(e)
  }

  return (
    <Textarea
      {...textareaProps}
      value={value}
      id={id}
      ref={ref}
      error={error}
      onBlur={mergedOnBlur}
      onChange={mergedOnChange}
      onClear={mergedOnClear}
    />
  )
}

ControllerTextarea.displayName = 'ControllerTextarea'

export type { TControlledTextareaProps }
export default ControllerTextarea
