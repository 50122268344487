import { FC, memo, MutableRefObject, useEffect, useRef } from 'react'
import Dropzone, { DropzoneProps, DropzoneRef } from 'react-dropzone'
import { ReactSVG } from 'react-svg'

import Button from '@components/NewDesign/Button'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import { listOfFileTypes } from '@constants/types'
import AttachmentIcon from '@icons/AttachmentIcon.svg'
import cn from 'classnames'

import { useDragAndDropHook } from '../useDragAndDropHook'

import styles from './FileDropzoneErrorPrimary.module.scss'

export interface FileDropzoneErrorPrimaryProps {
  extensionTooltipTargetRef?: MutableRefObject<HTMLElement | null>
  rejectedFile?: File | null
  disableUploadButton?: boolean
  disableTooltipButton?: boolean
  className?: string
  dropZoneProps?: DropzoneProps
  titleReject?: string
  buttonReject?: string
  subTitleReject?: string
  dropzoneControlRef?: MutableRefObject<DropzoneRef | null>
}

const FileDropzoneErrorPrimary: FC<FileDropzoneErrorPrimaryProps> = ({
  rejectedFile = null,
  titleReject = 'Недопустимое расширение файла',
  buttonReject = 'с допустимым расширением',
  subTitleReject = 'Перетащите файл',
  extensionTooltipTargetRef,
  disableUploadButton,
  disableTooltipButton,
  dropZoneProps,
  className,
  dropzoneControlRef,
}) => {
  const dropzoneRef = useRef<DropzoneRef | null>(null)
  const { handleClose, handleRejectedFiles } = useDragAndDropHook(rejectedFile)

  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
    bindHandlersRef: { bindRefOfClickableOutsideZone },
  } = useManualTooltipControl()

  useEffect(() => {
    if (!extensionTooltipTargetRef?.current) return

    bindRefOfClickableOutsideZone(extensionTooltipTargetRef.current)
  }, [bindRefOfClickableOutsideZone, extensionTooltipTargetRef])

  const openFileDialog = () => {
    if (!dropzoneRef?.current) return
    dropzoneRef.current?.open()

    handleClose()
  }

  const tooltipContent = (
    <div className={styles.tooltip__container}>
      <Typography.Headline
        variant="headlineH3"
        color="text-accent-brand-normal"
        className={styles.title}
      >
        Допустимые расширения файлов
      </Typography.Headline>

      <div className={styles['tooltip__container-content']}>
        {listOfFileTypes.map(({ title, types }) => (
          <div className={styles['tooltip__container-wrapper']}>
            <Typography.Body variant={'bodyMRegular'} color={'text-base-secondary'}>
              {title}
            </Typography.Body>
            <Typography.Body variant={'bodyMMedium'} color={'text-base-primary'}>
              {types.join(', ')}
            </Typography.Body>
          </div>
        ))}
      </div>
    </div>
  )

  const handleExtensionClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    handleOpenTooltip()
  }

  const errorTextWithTooltipButton = (
    <>
      <Tooltip
        trigger={'click'}
        open={tooltipIsOpen}
        popoverClassName={styles.tooltip__popover}
        position={'top'}
        content={tooltipContent}
        offset={[0, 10]}
        fallbackPlacements={['left', 'right', 'top-start', 'bottom-start']}
        zIndex={100}
        onClose={handleCloseTooltip}
      >
        <Button
          disabled={disableTooltipButton}
          color={'negative'}
          size={'xs'}
          view={'text'}
          variant={'buttonSMedium'}
          className={styles.tooltipButton}
          dataTestId="FileDropzoneError-fileExtension-button"
          onClick={handleExtensionClick}
        >
          {buttonReject}
        </Button>
      </Tooltip>
      <Typography.Body as={'span'} variant={'bodyMRegular'} color={'on-surface-secondary'}>
        в эту область
      </Typography.Body>
    </>
  )

  const bindDropzoneRef = (ref: DropzoneRef | null) => {
    if (!ref) return

    dropzoneRef.current = ref
    if (dropzoneControlRef) dropzoneControlRef.current = ref
  }

  return (
    <Dropzone
      {...dropZoneProps}
      noClick
      noKeyboard
      ref={bindDropzoneRef}
      onDropRejected={handleRejectedFiles}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          role={'presentation'}
          {...getRootProps({
            className: cn(styles.dropzone__container, className),
          })}
        >
          <div
            role={'presentation'}
            className={styles.dropzone__content}
            onClick={(e) => e.preventDefault()}
          >
            <input {...getInputProps()} />
            <div className={styles['dropzone__content-icon']}>
              <ReactSVG src={AttachmentIcon} />
            </div>
            <div className={styles['dropzone__content-info']}>
              {titleReject && (
                <Typography.Body variant={'bodyXLMedium'}>{titleReject}</Typography.Body>
              )}
              <div className={styles['dropzone__content-subtitle']}>
                <Typography.Body
                  as={'span'}
                  variant={'bodyMRegular'}
                  color={'on-surface-secondary'}
                >
                  {subTitleReject}
                </Typography.Body>
                {errorTextWithTooltipButton}
              </div>
            </div>
            {!disableUploadButton && (
              <Button
                className={styles['dropzone__content-download']}
                view={'tined'}
                color={'negative'}
                dataTestId="FileDropzoneError-fileUpload-button"
                onClick={openFileDialog}
              >
                Загрузить документ
              </Button>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  )
}

export default memo(FileDropzoneErrorPrimary)
