import { FC } from 'react'

import One from './1'
import Two from './2'
import Three from './3'
import Four from './4'
import Five from './5'
import Six from './6'
import Seven from './7'

interface AAgreementForceCircumstancesFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Three: typeof Three
  Four: typeof Four
  Five: typeof Five
  Six: typeof Six
  Seven: typeof Seven
}

const AAgreementForceCircumstancesForms: AAgreementForceCircumstancesFormsProps = () => null

AAgreementForceCircumstancesForms.One = One
AAgreementForceCircumstancesForms.Two = Two
AAgreementForceCircumstancesForms.Three = Three
AAgreementForceCircumstancesForms.Four = Four
AAgreementForceCircumstancesForms.Five = Five
AAgreementForceCircumstancesForms.Six = Six
AAgreementForceCircumstancesForms.Seven = Seven

export default AAgreementForceCircumstancesForms
