import React from 'react'
import { useFormContext } from 'react-hook-form'

import InfoAboutDocumentSection from '@components/DocumentFormComponents/FormSections/InfoAboutDocumentSection'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'

const First = () => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  if (!formInstance) return null

  return (
    <InfoAboutDocumentSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default First
