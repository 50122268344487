import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { sixthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from './validation'

const Sixth = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { getFormFieldControlProps, getTextareaProps, getAmountInputProps } =
    useFormComponentPresets({
      isNew: false,
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
    })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                  .projectPurpose,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                          .projectPurpose,
                      )
                    }, 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                    .projectPurpose,
                  rules:
                    sixthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.projectPurpose,
                  textareaProps: {
                    label: 'Цель реализации инвестиционного проекта',
                    leftAddons: (
                      <FormIconWithTooltip tooltipContent="Например, извлечение прибыли, достижение иного полезного эффекта, в том числе предотвращение или минимизация негативного влияния на окружающую среду" />
                    ),
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                          .projectPurpose,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                          .projectPurpose,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Общий объем капитальных вложений (без НДС)'}
        >
          <FormFieldControl
            {...getFormFieldControlProps({
              path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                .totalCapital,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                        .totalCapital,
                    )
                  }, 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                  .totalCapital,
                rules:
                  sixthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.totalCapital,
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                        .totalCapital,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                        .totalCapital,
                    )
                  }, 0),
              })}
            />
          </FormFieldControl>
        </RowWithBorder>
        <RowWithBorder title={'Общий объем капиталовложений'}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                .investorCapital,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                  .investorCapital,
                inputProps: {
                  disabled: true,
                  leftAddons: (
                    <FormIconWithTooltip tooltipContent="Объем капиталовложений заполняется автоматически на основе сведений об объеме капиталовложений по этапам" />
                  ),
                },
              })}
            />
          </FormFieldControl>
        </RowWithBorder>
        <RowWithBorder title={'Оставшийся объем капиталовложений'}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                .remainsInvestorCapital,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                  .remainsInvestorCapital,
                inputProps: {
                  disabled: true,
                  leftAddons: (
                    <FormIconWithTooltip tooltipContent="Оставшийся объем капиталовложений будет посчитан автоматически на основе сведений об объеме капиталовложений" />
                  ),
                },
              })}
            />
          </FormFieldControl>
        </RowWithBorder>
        <Group disableBottomBorder title={'Планируемый объем возмещения затрат'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title={'на обеспечивающую инфраструктуру'}
                >
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                        .projectSupportCompensation,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                                .projectSupportCompensation,
                            )
                          }, 0),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                          .projectSupportCompensation,
                        rules:
                          sixthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.projectSupportCompensation,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                                .projectSupportCompensation,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                                .projectSupportCompensation,
                            )
                          }, 0),
                      })}
                    />
                  </FormFieldControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder disableBottomDefaultStyles title={'на сопутствующую инфраструктуру'}>
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                        .projectRelatedCompensation,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                                .projectRelatedCompensation,
                            )
                          }, 0),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                          .projectRelatedCompensation,
                        rules:
                          sixthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.projectRelatedCompensation,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                                .projectRelatedCompensation,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
                                .projectRelatedCompensation,
                            )
                          }, 0),
                      })}
                    />
                  </FormFieldControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default Sixth
