import ApplicationsTerminate from '@icons/action/applications_terminate.svg'
import ErrorFileIcon from '@icons/action/applications_terminate_error_file.svg'
import Done from '@icons/action/done.svg'
import FindInPage from '@icons/action/find_in_page.svg'
import ErrorOutline from '@icons/alert/error_outline.svg'
import WarningAmber from '@icons/alert/warning_amber.svg'
import ForwardToInbox from '@icons/communication/forward_to_inbox.svg'
import Add from '@icons/content/add.svg'
import Create from '@icons/content/create.svg'
import AttachEmail from '@icons/file/attach_email.svg'
import Edit from '@icons/image/edit.svg'
import Close from '@icons/navigation/close.svg'
import Logo from '@icons/project.svg'

export const iconByName = {
  attach_email: AttachEmail,
  add: Add,
  find_in_page: FindInPage,
  warning_amber: WarningAmber,
  edit: Edit,
  applications_terminate: ApplicationsTerminate,
  close: Close,
  forward_to_inbox: ForwardToInbox,
  create: Create,
  error_outline: ErrorOutline,
  done: Done,
  logo: Logo,
  error_file: ErrorFileIcon,
  warning: WarningAmber,
} as const

export type ConfigIcons = keyof typeof iconByName

export const colorVariableNameBySimpleName = {
  blue: 'text-accent-brand',
  orange: 'text-accent-orange-secondary',
  red: 'text-accent-error-secondary',
  green: 'text-accent-success',
  grey: 'text-base-secondary',
  'grey-red': 'text-accent-error-secondary',
}

export type ConfigColors = keyof typeof colorVariableNameBySimpleName
