import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
  IConstructionStage,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fifthSectionValidationMap: FormValuesValidationSection<
  keyof IConstructionStage,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues
> = {
  stepEndDate: {
    required: defaultRHFValidation.required,
  },
  stepDescription: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  stepCompensationValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  stepCompensationForm: {
    required: defaultRHFValidation.required,
  },
}

export { fifthSectionValidationMap }
