import { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import StepRegisteredDateValueItem from '@components/Forms/RegistrationCertificateForm/Forms/5/StepRegisteredDateValuesList/StepRegisteredDateValueItem'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'

interface SubscribableStepRegisteredDateValueItemProps {
  id: string
  indexOfStep: number
  initialAccordionState?: boolean
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  onAccordionToggle?: (value: boolean) => void
}

const SubscribableStepRegisteredDateValueItem: FC<SubscribableStepRegisteredDateValueItemProps> = ({
  id,
  indexOfStep,
  initialAccordionState,
  formInstance,
  formModifierInstance,
  onAccordionToggle,
}) => {
  const formName =
    `${registrationCertificateBlockValues['5'].stepRegisteredDateValues}.${indexOfStep}` as const

  const tabName = useWatch({
    name: `${formName}.tabName`,
    control: formInstance.control,
  })

  const {
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
  })

  return (
    <SubscribableControl
      {...getSubscribableControlProps({
        path: formName,
      })}
    >
      <Accordion
        id={id}
        title={tabName}
        initialAccordionState={initialAccordionState}
        onToggle={onAccordionToggle}
      >
        <StepRegisteredDateValueItem
          formName={formName}
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
        />
      </Accordion>
    </SubscribableControl>
  )
}

export default SubscribableStepRegisteredDateValueItem
