import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { beneficiaryInformationBlockValues } from '@components/Forms/BeneficiaryInformationForm/const'
import { oneSectionValidationMap } from '@components/Forms/BeneficiaryInformationForm/Forms/1/validation'
import { useBeneficiaryInformationManager } from '@components/Forms/BeneficiaryInformationForm/Manager'
import { BeneficiaryInformationFormValues } from '@components/Forms/BeneficiaryInformationForm/types'
import { BeneficiaryInformationFieldsControlUpdateWatcher } from '@components/Forms/BeneficiaryInformationForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'


const First = () => {
  const formInstance = useFormContext<BeneficiaryInformationFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useBeneficiaryInformationManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getInputProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: BeneficiaryInformationFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: beneficiaryInformationBlockValues['1'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
                controllerProps={getControllerProps({
                  name: beneficiaryInformationBlockValues['1'].investorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: beneficiaryInformationBlockValues['1'].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: beneficiaryInformationBlockValues['1'].investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: beneficiaryInformationBlockValues['1'].investorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: beneficiaryInformationBlockValues['1'].investorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: beneficiaryInformationBlockValues['1'].investorAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Адрес'}
                controllerProps={getControllerProps({
                  name: beneficiaryInformationBlockValues['1'].investorAddress,
                })}
                formIconTooltipProps={{
                  tooltipContent: 'Адрес можно изменить в разделе "Моя организация"',
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Уполномоченное лицо организации'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: beneficiaryInformationBlockValues['1'].investorHeaderName,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.headerName || '',
                            value: item.id,
                          }),
                          controllerProps: {
                            name: beneficiaryInformationBlockValues['1'].investorHeaderName,
                            rules: oneSectionValidationMap.investorHeaderName,
                          },
                          selectProps: {
                            optionsProps: {
                            },
                            inputProps: {
                              dataTestId: 'investorFio',
                              label: 'Фамилия, Имя, Отчество',
                            },
                            onChangeFormValue: () =>
                              setTimeout(() => {
                                handleChangeValue?.(
                                  beneficiaryInformationBlockValues['1'].investorHeaderName,
                                )
                              }, 0),
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: beneficiaryInformationBlockValues['1'].investorHeaderPosition,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: beneficiaryInformationBlockValues['1'].investorHeaderPosition,
                      inputProps: {
                        dataTestId: 'investorPosition',
                        disabled: true,
                        label: 'Должность',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            beneficiaryInformationBlockValues['1'].investorHeaderPosition,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            beneficiaryInformationBlockValues['1'].investorHeaderPosition,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default First
