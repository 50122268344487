import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import SupplementalCostSharingALayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import SupplementalCostSharingAModalManager from './Manager'
import SupplementalCostSharingAMenu from './Menu'
import SupplementalCostSharingAStatementForm from './Statement'

interface ISupplementalCostSharingA extends FC {
  LayoutWithFormProvider: typeof SupplementalCostSharingALayoutWithFormProvider
  Menu: typeof SupplementalCostSharingAMenu
  StatementForm: typeof SupplementalCostSharingAStatementForm
  Manager: typeof SupplementalCostSharingAModalManager
}

const useSupplementalCostSharingAControl = () => {
  const popupManager = usePopupManager()

  const openSupplementalCostSharingAModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(SupplementalCostSharingALayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openSupplementalCostSharingAModal }
}

const SupplementalCostSharingA: ISupplementalCostSharingA = () => null

SupplementalCostSharingA.Menu = SupplementalCostSharingAMenu
SupplementalCostSharingA.LayoutWithFormProvider = SupplementalCostSharingALayoutWithFormProvider
SupplementalCostSharingA.StatementForm = SupplementalCostSharingAStatementForm
SupplementalCostSharingA.Manager = SupplementalCostSharingAModalManager

export { useSupplementalCostSharingAControl }
export default SupplementalCostSharingA
