import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NotificationFillingAppConcludingLayout from './Layout'
import NotificationFillingAppConcludingModalManager from './Manager'
import NotificationFillingAppConcludingMenu from './Menu'
import NotificationFillingAppConcludingStatementForm from './Statement'

interface ICreateNotificationFillingAppConcluding extends FC {
  Layout: typeof NotificationFillingAppConcludingLayout
  Menu: typeof NotificationFillingAppConcludingMenu
  StatementForm: typeof NotificationFillingAppConcludingStatementForm
  Manager: typeof NotificationFillingAppConcludingModalManager
}

const useNotificationFillingAppConcludingControl = () => {
  const popupManager = usePopupManager()

  const openNotificationFillingAppConcludingModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(NotificationFillingAppConcludingLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openNotificationFillingAppConcludingModal }
}

const NotificationFillingAppConcluding: ICreateNotificationFillingAppConcluding = () => null

NotificationFillingAppConcluding.Menu = NotificationFillingAppConcludingMenu
NotificationFillingAppConcluding.Layout = NotificationFillingAppConcludingLayout
NotificationFillingAppConcluding.StatementForm = NotificationFillingAppConcludingStatementForm
NotificationFillingAppConcluding.Manager = NotificationFillingAppConcludingModalManager

export { useNotificationFillingAppConcludingControl }
export default NotificationFillingAppConcluding
