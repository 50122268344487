const documentsLinks = {
  GIS_KAP_RP: '/documents/ГИС_Капиталовложения_Руководство_пользователя.pdf',
  DECREE_N1599: '/documents/Постановление_N1599_от_3.10.2020.pdf',
  DECREE_N1602: '/documents/Постановление_N1602_от_13.09.2022.pdf',
  DECREE_GIS_KAP: '/documents/Постановление_о_ГИС_Капиталовложения.pdf',
  RULES_SZPK: '/documents/Правила_заключения_СЗПК.pdf',
  ORDER_N626: '/documents/Приказ_N_626_от_16.11.2022.pdf',
  GOVERNMENT_ORDER_N2724: '/documents/Распоряжение_Правительства_РФ_N2724-р_ от_21.09.2022.pdf',
  FZ_N69: '/documents/ФЗ_N69_от_01.04.2020.pdf',
} as const

const documentActionsMap = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
} as const

const rejectedFileErrorCodesMap = {
  MIN_FILE_SIZE: 'MIN_FILE_SIZE',
  MAX_FILE_SIZE: 'MAX_FILE_SIZE',
  FILE_INVALID_TYPE: 'FILE_INVALID_TYPE',
  MULTIPLE_FILE: 'MULTIPLE_FILE',
} as const

export { documentActionsMap, documentsLinks,rejectedFileErrorCodesMap }
