import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentDataView from '@components/DocumentFormComponents/DocumentDataView'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/const'
import { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyManager } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/Manager'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { secondSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap } from './validation'

const Second = () => {
  const formInstance =
    useFormContext<NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyManager()

  const { getSubscribableControlProps, getControllerProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher:
        NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFieldsControlUpdateWatcher,
    })

  const investorFullName = useWatch({
    name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
      '2'
    ].investorFullName,
    control: formInstance.control,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <DocumentDataView suptitle={'Полное наименование'}>{investorFullName}</DocumentDataView>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                  '2'
                ].investorFullNameInstrumental,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                    '2'
                  ].investorFullNameInstrumental,
                  rules:
                    secondSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap.investorFullNameInstrumental,
                  textareaProps: {
                    maxRows: Infinity,
                    label: 'Полное наименование',
                    caption: 'в творительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                          '2'
                        ].investorFullNameInstrumental,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                          '2'
                        ].investorFullNameInstrumental,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                  '2'
                ].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                    '2'
                  ].investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                  '2'
                ].investorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                    '2'
                  ].investorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                  '2'
                ].investorAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Юридический адрес'}
                controllerProps={getControllerProps({
                  name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues[
                    '2'
                  ].investorAddress,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
