import React from 'react'
import { useNavigate } from 'react-router-dom'

import { NotificationWithButton } from '@components/NewNotifications/NotificationWithButton'
import { Paths } from '@constants/paths'
import { notificationLevelType } from '@constants/types'

const OrganizationMessageVerifyNegative = () => {
  const navigate = useNavigate()

  const handleNotificationClick = () => {
    navigate(Paths.Organization)
  }

  return (
    <NotificationWithButton
      level={notificationLevelType.WARNING}
      title={'Укажите данные организации'}
      description={
        'Для работы с документами необходимо указать реквизиты организации и информацию о лицах, имеющих право подписывать документы'
      }
      onClick={handleNotificationClick}
    />
  )
}

export default OrganizationMessageVerifyNegative
