import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Twelfth = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { getFormFieldControlProps, getCheckboxProps } = useFormComponentPresets({
    isNew: false,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Group
        disableBottomBorder
        groupClassName={'mt-0'}
        title={
          'В течении срока действия стабилизационной оговорки к инвестиционному проекту не будут применяться следующие акты:'
        }
      >
        <Group title={'Субъект РФ'}>
          <Stack direction={'vertical'} gap={3}>
            <Row className={styles.form__checkboxRow}>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['12']
                      .rightDutySubjectWaterResource,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['12']
                        .rightDutySubjectWaterResource,
                      checkBoxProps: {
                        label:
                          'Акты, предполагающие увеличение ставок платы и (или) расширение платежной базы, используемых при определении платы за пользование водными объектами, в течение 3 лет со дня вступления таких актов в силу',
                      },
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row className={styles.form__checkboxRow}>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['12']
                      .rightDutySubjectForestResource,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['12']
                        .rightDutySubjectForestResource,
                      checkBoxProps: {
                        label:
                          'Акты, предполагающие увеличение ставок платы за единицу объема лесных ресурсов и изменения размера арендной платы, устанавливаемых при использовании лесного участка с изъятием лесных ресурсов, в течение 3 лет со дня вступления таких актов в силу',
                      },
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Российская Федерация'}>
          <Stack direction={'vertical'} gap={3}>
            <Row className={styles.form__checkboxRow}>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['12']
                      .rightDutyRfWaterResource,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['12']
                        .rightDutyRfWaterResource,
                      checkBoxProps: {
                        label:
                          'Акты, предполагающие увеличение ставок платы и (или) расширение платежной базы, используемых при определении размера платы за негативное воздействие на окружающую среду, платы за пользование водными объектами и экологического сбора, в течение 3 лет со дня вступления таких актов в силу',
                      },
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row className={styles.form__checkboxRow}>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['12']
                      .rightDutyRfForestResource,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['12']
                        .rightDutyRfForestResource,
                      checkBoxProps: {
                        label:
                          'Акты, предполагающие увеличение ставок платы за единицу объема лесных ресурсов, изменения порядка определения и общего размера арендной платы, устанавливаемых при использовании лесного участка с изъятием лесных ресурсов, в течение 3 лет со дня вступления соответствующих актов в силу',
                      },
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Group>
    </Container>
  )
}

export default Twelfth
