import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormsProps
  extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms: NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormsProps =
  () => null

NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms.First = First
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms.Second = Second
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms.Third = Third
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms.Fourth = Fourth

export default NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms
