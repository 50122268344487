import { FC } from 'react'

import WebFormAddonController from '@components/Attachments/WebForm/Addon'

import { WebFormAPIProvider } from './APIProvider'
import WebFormController from './Controller'
import WebFormItemController from './ItemController'
import WebFormWithDocumentController from './WithDocumentController'
import WebFormWithoutDocumentController from './WIthoutDocumentController'

interface WebFormFC extends FC {
  Addon: typeof WebFormAddonController
  Controller: typeof WebFormController
  ItemController: typeof WebFormItemController
  APIProvider: typeof WebFormAPIProvider
  WithDocumentController: typeof WebFormWithDocumentController
  WithoutDocumentController: typeof WebFormWithoutDocumentController
}

const WebForm: WebFormFC = () => null

WebForm.Addon = WebFormAddonController
WebForm.Controller = WebFormController
WebForm.ItemController = WebFormItemController
WebForm.APIProvider = WebFormAPIProvider
WebForm.WithDocumentController = WebFormWithDocumentController
WebForm.WithoutDocumentController = WebFormWithoutDocumentController

export default WebForm
