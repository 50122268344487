import { useCallback } from 'react'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForCertificateOnMonitoringResultsForm } from '@components/Forms/CertificateOnMonitoringResultsForm/adapters/Menu.adapter'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'

import { generatePropertiesObjectForCertificateOnMonitoringResultsForm } from './PropertiesObject.adapter'
import { generateRHFObjectForCertificateOnMonitoringResultsForm } from './RHF.adapter'

const useCertificateOnMonitoringResultsAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): CertificateOnMonitoringResultsFormValues =>
      generateRHFObjectForCertificateOnMonitoringResultsForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForCertificateOnMonitoringResultsForm(objectFromPropertyState),
    [],
  )

  const generateMenuListByFormValues = useCallback(
    (
      formValues:
        | CertificateOnMonitoringResultsFormValues
        | UnpackNestedValue<CertificateOnMonitoringResultsFormValues>,
      initialHashMap: NestedMenuHashMap,
      formErrors: FieldErrors<CertificateOnMonitoringResultsFormValues>,
    ): NestedMapOfMenu[] =>
      generateMenuListForCertificateOnMonitoringResultsForm(formValues, initialHashMap, formErrors),
    [],
  )

  return { generateRHFObject, generatePropertiesObject, generateMenuListByFormValues }
}

export { useCertificateOnMonitoringResultsAdapters }
