import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NoticeOneSideRejectSZPK from '@components/Forms/NoticeOneSideRejectSZPKForm'
import {
  defaultNoticeOneSideRejectSZPKFormValues,
  mapOfNoticeOneSideRejectSZPKMenu,
} from '@components/Forms/NoticeOneSideRejectSZPKForm/const'
import { NoticeOneSideRejectSZPKFormValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/types'
import { NoticeOneSideRejectSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOneSideRejectSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'

import NoticeOneSideRejectSZPKLayoutWrapper from './Wrapper'

const NoticeOneSideRejectSZPKLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NoticeOneSideRejectSZPKFormValues>({
    defaultValues: defaultNoticeOneSideRejectSZPKFormValues,
  })

  const handleOnClose = () => {
    NoticeOneSideRejectSZPKFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NoticeOneSideRejectSZPK.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfNoticeOneSideRejectSZPKMenu[0].id}>
            <NoticeOneSideRejectSZPKLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об одностороннем отказе от СЗПК
                </Typography.Headline>
                <NoticeOneSideRejectSZPK.StatementForm className={styles.layout__form} />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <NoticeOneSideRejectSZPK.Menu className={styles.layout__menu} />
              </div>
            </NoticeOneSideRejectSZPKLayoutWrapper>
          </FlatMenuManager>
        </NoticeOneSideRejectSZPK.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NoticeOneSideRejectSZPKLayout)
