import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NotificationOfIdentifiedViolationsFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/types'

const generateRHFObjectForNotificationOfIdentifiedViolationsForm = (
  objectFromPropertyState: Record<string, any>,
): NotificationOfIdentifiedViolationsFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getUOAdapter(objectFromPropertyState),
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: objectFromPropertyState.investorFullNameGenitive?.value || '',
    },
    '4': {
      projectName: objectFromPropertyState.projectName?.value || '',
      ruleLinkStatementReturn: objectFromPropertyState.ruleLinkStatementReturn?.value || '',
      statementTechError: objectFromPropertyState.statementTechError?.value || '',
      statementSetDocumentError: objectFromPropertyState.statementSetDocumentError?.value || '',
      statementDocumentError: objectFromPropertyState.statementDocumentError?.value || '',
      ruleLinkDocumentError: objectFromPropertyState.ruleLinkDocumentError?.value || '',
      statementHeadError: objectFromPropertyState.statementHeadError?.value || '',
      statementInvestorError: objectFromPropertyState.statementInvestorError?.value || '',
      statementProjectCompanyError:
        objectFromPropertyState.statementprojectCompanyError?.value || '',
      statementProjectError: objectFromPropertyState.statementProjectError?.value || '',
      statementNpaError: objectFromPropertyState.statementNpaError?.value || '',
      statementOtherError: objectFromPropertyState.statementOtherError?.value || '',
      statementOtherDetails: objectFromPropertyState.statementOtherDetails?.value || '',
      statementDaysRevision: objectFromPropertyState.statementDaysRevision?.value || '',
    },
  }
}

export { generateRHFObjectForNotificationOfIdentifiedViolationsForm }
