import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForNpaMunicipalForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedChosenParts = (() => {
    if (
      !isArray(objectFromPropertyState.municipalNpas?.value) ||
      !objectFromPropertyState.municipalNpas?.value.length
    )
      return null

    const npaParts = objectFromPropertyState.municipalNpas.value.map((npa) => ({
      propertyId: npa?.propertyId,
      type: npa?.type,
      lastUpdateDt: npa?.lastUpdateDt,
      value: {
        justification: {
          propertyId: npa?.value?.municipalNpaJustification?.propertyId,
          type: npa?.value?.municipalNpaJustification?.type,
          lastUpdateDt: npa?.value?.municipalNpaJustification?.lastUpdateDt,
        },
        partCorrection: {
          propertyId: npa?.value?.municipalNpaCorrection?.propertyId,
          type: npa?.value?.municipalNpaCorrection?.type,
          lastUpdateDt: npa?.value?.municipalNpaCorrection?.lastUpdateDt,
        },
        npaId: {
          propertyId: npa?.value?.municipalNpaId?.propertyId,
          type: npa?.value?.municipalNpaId?.type,
          lastUpdateDt: npa?.value?.municipalNpaId?.lastUpdateDt,
        },
        partId: {
          propertyId: npa?.value?.municipalNpaPartId?.propertyId,
          type: npa?.value?.municipalNpaPartId?.type,
          lastUpdateDt: npa?.value?.municipalNpaPartId?.lastUpdateDt,
        },
      },
    }))

    return {
      npaParts,
    }
  })()

  return {
    headerInfo: {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      ...PropertiesPropsBasicAdapters.getNewMunicipalityAdapter(objectFromPropertyState),
    },
    npa: {
      chosenParts: {
        propertyId: objectFromPropertyState.municipalNpas?.propertyId,
        lastUpdateDt: objectFromPropertyState.municipalNpas?.lastUpdateDt,
        type: objectFromPropertyState.municipalNpas?.type,
        value: preparedChosenParts,
      },
    },
    additionalFields: {
      municipalities: {
        propertyId: objectFromPropertyState.municipalities?.propertyId,
        lastUpdateDt: objectFromPropertyState.municipalities?.lastUpdateDt,
        type: objectFromPropertyState.municipalities?.type,
      },
    },
  }
}

export { generatePropertiesObjectForNpaMunicipalForm }
