import React, { FC } from 'react'

import cn from 'classnames'

import styles from './Paper.module.scss'

export type TSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'

export interface IPaper {
  className?: string
  rounded?: TSize
  size?: TSize
  space?: 'm' | 'l'
  hideBgColor?: boolean
}

const Paper: FC<IPaper> = ({
  children,
  className,
  size = 'md',
  rounded = 'lg',
  space,
  hideBgColor,
}) => {
  return (
    <div
      className={cn(
        styles.wrapper,
        {
          [styles[`size--${size}`]]: size,
          [styles[`rounded--${rounded}`]]: rounded,
          [styles[`wrapper--hideBgColor`]]: hideBgColor,
          [styles[`wrapper--${space}`]]: space,
        },
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Paper
