import React, {
  Children,
  cloneElement,
  ComponentType,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react'
import { FieldValues, Path, useWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import AsyncWrapper from '@components/AsyncWrapper'
import FormIconWithTooltip, {
  FormIconWithTooltipProps,
} from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isArray, isFunction, isUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './ContainerWithPublic.module.scss'

const uiComponentNames = [
  'ControllerTextarea',
  'ControlledInput',
  'ControlledMultipleInput',
  'ControlledCalendarInput',
  'ControlledMaskInput',
  'ControlledAmountInput',
  'ControlledSwitch',
  'ControlledCheckbox',
  'ControlledFormSingleSelect',
  'ControlledFormMultipleSelect',
  'ControlledHierarchyReferenceBookSelect',
  'ControlledDocumentDataView',
]

interface ContainerWithPublicProps<T extends FieldValues> {
  control: Control<T>
  publicFormName: Path<T>
  children: ReactNode
  editMode?: boolean
  containerClassName?: string
  controlledFormNames?: string[]
  formIconWithTooltipProps?: FormIconWithTooltipProps
  onPublic?: (formNames: string[], value: boolean) => void
}

const ContainerWithPublic = <T extends FieldValues>({
  editMode = false,
  publicFormName,
  children,
  formIconWithTooltipProps,
  control,
  containerClassName,
  controlledFormNames,
  onPublic,
}: ContainerWithPublicProps<T>) => {
  const isPublic = useWatch({
    name: publicFormName as Path<T>,
    control,
  })

  const switchFormIconRenderCondition = !isUndefined(formIconWithTooltipProps)

  const findFormNameRecursively = (children: ReactNode, formNames: string[]) => {
    Children.forEach(children, (child) => {
      if (!isValidElement(child)) return

      if (
        isFunction(child.type) &&
        'displayName' in child.type &&
        uiComponentNames.includes(child.type?.['displayName'])
      ) {
        if ('controllerProps' in child.props) {
          formNames.push(child.props.controllerProps.name)
        } else {
          formNames.push(child.props.name)
        }
      }

      findFormNameRecursively(child.props.children, formNames)
    })
  }

  const handlePublicOnClick = async () => {
    if (isArray(controlledFormNames) && !!controlledFormNames.length)
      return onPublic?.(controlledFormNames, !isPublic)

    const formNames: string[] = []

    findFormNameRecursively(children, formNames)

    await onPublic?.(formNames, !isPublic)
  }

  const cloneSelectComponent = (children: ReactElement) => {
    return cloneElement(children, {
      ...children.props,
      selectProps: {
        ...children.props.selectProps,
        inputProps: {
          ...children.props.selectProps.inputProps,
          labelTextClassName: cn(children.props?.selectProps?.inputProps?.labelTextClassName, {
            [styles['containerWithPublic__label--isPublic']]: isPublic,
          }),
        },
      },
    })
  }

  const cloneDataViewComponent = (children: ReactElement) => {
    return cloneElement(children, {
      ...children.props,
      suptitleTypographyProps: {
        ...children.props.suptitleTypographyProps,
        className: cn(children.props?.suptitleTypographyProps?.className, {
          [styles['containerWithPublic__label--isPublic']]: isPublic,
        }),
      },
    })
  }

  const cloneComponentByPropsName = (children: ReactElement, propsName: string) => {
    return cloneElement(children, {
      ...children.props,
      [propsName]: {
        ...children.props[propsName],
        labelTextClassName: cn(children.props[propsName]?.labelTextClassName, {
          [styles['containerWithPublic__label--isPublic']]: isPublic,
        }),
      },
    })
  }

  const cloneChild = (child: ReactElement) => {
    if ((child.type as ComponentType)?.displayName === 'ControlledDocumentDataView') {
      return cloneDataViewComponent(child)
    }

    if ('selectProps' in child.props) {
      return cloneSelectComponent(child)
    }

    if ('textareaProps' in child.props) {
      return cloneComponentByPropsName(child, 'textareaProps')
    }

    if ('calendarInputProps' in child.props) {
      return cloneComponentByPropsName(child, 'calendarInputProps')
    }

    if ('switchProps' in child.props) {
      return cloneComponentByPropsName(child, 'switchProps')
    }

    if ('checkBoxProps' in child.props) {
      return cloneComponentByPropsName(child, 'checkBoxProps')
    }

    if ('inputProps' in child.props) {
      return cloneComponentByPropsName(child, 'inputProps')
    }

    return child
  }

  const renderRecursiveMap = (children: ReactNode) => {
    return React.Children.map(children, (child) => {
      if (!isValidElement(child)) return child

      if (
        isFunction(child.type) &&
        'displayName' in child.type &&
        uiComponentNames.includes(child.type?.['displayName'])
      ) {
        return cloneChild(child)
      }

      return cloneElement(child, {
        children: renderRecursiveMap(child.props.children || null),
      })
    })
  }

  return (
    <Container
      className={cn(styles.containerWithPublic, containerClassName, {
        [styles['containerWithPublic--isPublic']]: !!isPublic,
      })}
    >
      <Stack direction={'vertical'} gap={3}>
        {!!editMode && (
          <Row>
            <Stack direction={'vertical'} gap={1} className={styles.containerWithPublic__stack}>
              {switchFormIconRenderCondition && (
                <FormIconWithTooltip {...formIconWithTooltipProps} />
              )}
              <AsyncWrapper promise={handlePublicOnClick}>
                {({ isLoading, wrappedPromise }) => (
                  <ControlledSwitch
                    name={publicFormName}
                    control={control}
                    switchProps={{
                      disabled: isLoading,
                      label: {
                        position: 'right',
                        text: 'Публиковать',
                      },
                      wrapperClassName: styles.containerWithPublic__switch,
                      labelClassName: styles['containerWithPublic__switch-label'],
                    }}
                    onChange={wrappedPromise}
                  />
                )}
              </AsyncWrapper>
            </Stack>
          </Row>
        )}
        {renderRecursiveMap(children)}
      </Stack>
    </Container>
  )
}

export default ContainerWithPublic
