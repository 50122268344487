import React from 'react'

import PublicWrapper from '@components/PublicWrapper'
import Step from '@components/Step'

import styles from './StatementScheme.module.scss'

const StatementScheme = () => {
  return (
    <div>
      <PublicWrapper
        variant="secondary"
        title="Процесс заключения  соглашения о защите и поощрении капиталовложений (СЗПК)"
      >
        <div className={styles.label}>
          Процесс описан для заявителей, чьи документы соответствуют всем требованиям
          законодательства
        </div>
        <Step
          step="one"
          title="Создание заявления на заключение СЗПК организацией, реализующей инвестиционный проект"
          label="Заполнение формы заявления и загрузка файлов сопроводительных документов, включая проект СЗПК"
        />
        <Step
          step="two"
          title="Подписание  организацией, реализующей инвестиционный проект,  заявления на СЗПК электронной подписью и отправка подписанного пакета документов на проверку в Уполномоченную организацию"
        />
        <Step
          step="three"
          title="Проверка Уполномоченной организацией пакета документов на соответствие требованиями 69-ФЗ и нормативной документации"
        />
        <Step
          step="four"
          title="Подписание органами исполнительной власти РФ проекта СЗПК"
          label="Региональные органы исполнительной власти – будущие стороны в соглашении, при отсутствии замечаний подписывают проект СЗПК , предоставленный организацией, реализующей инвестиционный проект"
        />
        <Step
          step="five"
          title="Подписание Минэкономразвития РФ СЗПК, формирование справки для подписанного СЗПК и передача в Федеральное казначейство для внесения в реестр"
        />
        <Step
          step="six"
          title="Проверка Федеральным казначейством пакета документов заявления и регистрация СЗПК для вступления в законную силу"
        />
        <Step step="final" title="СЗПК заключен" className="pt-4" />
      </PublicWrapper>
    </div>
  )
}

export default StatementScheme
