import React, { FC } from 'react'

import Icon from '@components/Icon'
import Typography from '@components/NewDesign/Typography'
import InfoTwoToneIcon from '@icons/InfoTwoToneIcon.svg'
import cn from 'classnames'

import styles from './Banner.module.scss'

type AutoGeneratedFormBannerProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const AutoGeneratedFormBanner: FC<AutoGeneratedFormBannerProps> = ({ ...divRest }) => {
  return (
    <div {...divRest} className={cn(styles.banner, divRest.className)}>
      <div className={styles.banner__content}>
        <Icon
          noCurrentColorSvgFill
          size={'initial'}
          className={styles['banner__content-icon']}
          src={InfoTwoToneIcon}
        />
        <Typography.Body
          className={styles['banner__content-text']}
          color={'text-accent-brand'}
          variant={'bodyMRegular'}
        >
          Форма была автоматически заполнена на основе информации об участниках соглашения. Укажите
          данные уполномоченных лиц, которые будут подписывать документ.
        </Typography.Body>
      </div>
    </div>
  )
}

export default AutoGeneratedFormBanner
