import React, { DetailedHTMLProps, FC, ReactNode } from 'react'

import Icon from '@components/Icon'
import Typography from '@components/NewDesign/Typography'
import SearchFallbackIcon from '@icons/SearchFallbackIcon.svg'
import cn from 'classnames'

import styles from './SearchPlaceholder.module.scss'

interface SearchPlaceholder
  extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  contentClassName?: string
  bottomRender?: ReactNode
}

const SearchPlaceholder: FC<SearchPlaceholder> = ({
  bottomRender,
  contentClassName,
  children,
  ...divRest
}) => {
  const currentBottomRender = bottomRender ?? (
    <>
      Проверьте фильтры и то, что все слова написаны без ошибок. <br /> Если и так все корректно,
      значит в нашей базе еще нет результатов, <br /> соответствующих вашему запросу.
    </>
  )

  return (
    <div {...divRest} className={cn(styles.searchPlaceholder, divRest.className)}>
      <Icon
        noCurrentColorSvgFill
        src={SearchFallbackIcon}
        size={'initial'}
        className={styles.searchPlaceholder__icon}
      />
      <div className={cn(styles.searchPlaceholder__content, contentClassName)}>
        <Typography.Headline variant={'headlineH2'} fontWeight={'normal'}>
          По запросу{' '}
          <Typography.Headline
            as={'span'}
            variant={'headlineH2'}
          >{`«${children}»`}</Typography.Headline>{' '}
          ничего не найдено
        </Typography.Headline>
        <Typography.Body
          className={styles['searchPlaceholder__content-subtitle']}
          variant={'bodyLMedium'}
          color={'text-base-tertiary'}
        >
          {currentBottomRender}
        </Typography.Body>
      </div>
    </div>
  )
}

export default SearchPlaceholder
