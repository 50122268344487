import React, { memo, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import Container from '@components/Layout/Container'
import Typography from '@components/NewDesign/Typography'
import env from '@config'
import { documentsLinks } from '@constants/documents'
import { Paths } from '@constants/paths'
import useMediaQuery from '@hooks/useMediaQuery'
import useMobile from '@hooks/useMobile'
import FooterGerb from '@images/FooterGerb.svg'
import FooterLogo from '@images/FooterLogo.svg'
import cn from 'classnames'

import styles from './Footer.module.scss'

const SUPPORT_EMAIL = env.REACT_APP_SUPPORT_EMAIL

export interface IFooter {
  handleGoToCommissionerLK?: (
    header: string,
    description?: string,
  ) => (e: MouseEvent<HTMLParagraphElement>) => void
}

const DESKTOP_RESOLUTION = '(min-width: 769px)'

const Footer = ({ handleGoToCommissionerLK }: IFooter) => {
  const isDesktopMediaMatches = useMediaQuery(DESKTOP_RESOLUTION)
  const { isMobile, isTablet } = useMobile()

  const isDesktop = !isTablet && !isMobile

  const govermentButtonsRender = isDesktopMediaMatches && isDesktop

  return (
    <Container>
      <div className={styles.footerContainer}>
        <div className={styles.footerLogo}>
          <ReactSVG src={FooterLogo} />
          <ReactSVG src={FooterGerb} />
        </div>
        <div className={styles.support}>
          <div className={styles.politics}>
            <Link to={Paths.Politics} className={cn(styles.text, styles.linkColor)}>
              Политика обработки персональных данных
            </Link>
            <a
              className={cn(styles.text, styles.linkColor)}
              href={documentsLinks.GIS_KAP_RP}
              target="_blank"
            >
              Руководство пользователя
            </a>
            {govermentButtonsRender && (
              <>
                <Typography.Button
                  variant="buttonSMedium"
                  fontWeight="normal"
                  align="left"
                  className={cn(styles.text, styles.cursor, styles.linkColor)}
                  onClick={handleGoToCommissionerLK?.(
                    'Личный кабинет уполномоченной организации',
                    'доступен по адресу',
                  )}
                >
                  Вход для представителей уполномоченной организации
                </Typography.Button>

                <Typography.Button
                  variant="buttonSMedium"
                  fontWeight="normal"
                  align="left"
                  className={cn(styles.text, styles.cursor, styles.linkColor)}
                  onClick={handleGoToCommissionerLK?.(
                    'Личные кабинеты органов власти, местного самоуправления и уполномоченной организации',
                    'доступны по адресу',
                  )}
                >
                  Вход для представителей ОМСУ
                </Typography.Button>
              </>
            )}
          </div>
          {SUPPORT_EMAIL && (
            <div className={styles.supportEmail}>
              <Typography.Body
                variant="bodySMedium"
                fontWeight="normal"
                align="right"
                className={styles.text}
              >
                Техническая поддержка
              </Typography.Body>
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                className={cn(styles.supportLink, styles.linkColor)}
              >
                {SUPPORT_EMAIL}
              </a>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default memo(Footer)
