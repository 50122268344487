import { useCallback, useMemo, useState } from 'react'

import { OptionProps } from '@components/NewDesign/Select/model'
import { RolesTypes } from '@constants/types'
import { useMunicipality } from '@hooks/new/swr/useMunicipality'
import useDebounce from '@hooks/useDebounce'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'

interface MunicipalitySelectConfigProps {
  isProjectFederal?: boolean
  pageSize?: number
  paginationBottonTrigger?: number
}

const PAGE_SIZE_FOR_MUNICIPALITY = 30

const PAGINATION_BOTTOM_TRIGGER = 150

const useMunicipalitySelectConfig = (props?: MunicipalitySelectConfigProps) => {
  const {
    isProjectFederal,
    pageSize = PAGE_SIZE_FOR_MUNICIPALITY,
    paginationBottonTrigger = PAGINATION_BOTTOM_TRIGGER,
  }: MunicipalitySelectConfigProps = props ?? {}

  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchString = useDebounce(searchValue, 500)

  const getKey = useCallback(
    (page) => ({
      _key: 'projectMunicipality',
      page,
      size: pageSize,
      type: RolesTypes.OMSU,
      searchFields: 'omsu',
      searchString: debouncedSearchString,
    }),
    [debouncedSearchString, pageSize],
  )

  const { municipalityList, isMunicipalityLoading, hasMoreData, incPage, error } = useMunicipality({
    key: getKey,
    config: {
      isPaused: () => !!isProjectFederal,
    },
  })

  const incrementPage = useDebouncedCallback(incPage, 250)

  const preparedMunicipalityOption: OptionProps[] = useMemo(() => {
    if (!municipalityList) return []

    return municipalityList.map((municipality) => ({
      label: municipality?.regionName || '',
      displayValue: municipality?.municipalityName || '',
      value: municipality.id,
    }))
  }, [municipalityList])

  const onContentScroll = useCallback(
    async (e) => {
      const requestCondition = !error && !isMunicipalityLoading && hasMoreData

      if (
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
          paginationBottonTrigger &&
        requestCondition
      ) {
        try {
          await incrementPage((size) => size + 1)
        } catch {}
      }
    },
    [incrementPage, paginationBottonTrigger, error, hasMoreData, isMunicipalityLoading],
  )

  return {
    onChangeSearchValue: setSearchValue,
    onContentScroll,
    preparedMunicipalityOption,
    municipalityData: { municipalityList, isMunicipalityLoading, hasMoreData, incPage, error },
  }
}

export { useMunicipalitySelectConfig }
