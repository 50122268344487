import { useCallback, useState } from 'react'
import { FieldValues } from 'react-hook-form'

import HierarchyReferenceBookManager from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Manager'
import {
  HierarchyReferenceBookSelectProps,
  HierarchyTypeSelectOption,
} from '@components/NewDesign/Select/HierarchyReferenceBookSelect/types'

import BaseHierarchyReferenceBookSelect from './Select'

const HierarchyReferenceBookSelect = <T extends FieldValues>({
  id,
  name,
  dictionaryName,
  onChange,
  defaultSelected,
  inputProps,
  ...props
}: HierarchyReferenceBookSelectProps<T>) => {
  const [initialId, setInitialId] = useState<string | null>(defaultSelected?.id ?? null)

  //При выделении устанавливаем начальный id
  const handleChange = useCallback(
    (value: HierarchyTypeSelectOption | null) => {
      onChange?.(value)

      if (value) {
        return setInitialId(value.id)
      }

      setInitialId(null)
    },
    [onChange, setInitialId],
  )

  return (
    <HierarchyReferenceBookManager dictionaryName={dictionaryName} initialId={initialId}>
      <BaseHierarchyReferenceBookSelect
        id={id}
        name={name}
        inputProps={inputProps}
        defaultSelected={defaultSelected}
        onChange={handleChange}
        {...props}
      />
    </HierarchyReferenceBookManager>
  )
}

export default HierarchyReferenceBookSelect
