import React from 'react'
import { useFormContext } from 'react-hook-form'

import FirstVariant from '@components/Forms/АgreementStabilizationNegotiationsForm/Forms/5/AdditionalForms/FirstVariant'
import { useAgreementStabilizationNegotiationsManager } from '@components/Forms/АgreementStabilizationNegotiationsForm/Manager'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fifth = () => {
  const formInstance = useFormContext<AgreementStabilizationNegotiationsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
  } = useAgreementStabilizationNegotiationsManager()

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FirstVariant
              formInstance={formInstance}
              editMode={editMode}
              blockViewIsValidating={blockViewIsValidating}
            />
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Fifth
