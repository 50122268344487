import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationOfIdentifiedNPAListErrorsForm from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm'
import { defaultNotificationOfIdentifiedNPAListErrorsFormValues } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/const'
import { notificationOfIdentifiedNPAListErrorsMenuState } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/Menu/const'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/types'
import { NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NotificationOfIdentifiedNPAListErrorsLayoutWrapper from './Wrapper'

const NotificationOfIdentifiedNPAListErrorsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  rolesOfUser,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NotificationOfIdentifiedNPAListErrorsFormValues>({
    defaultValues: defaultNotificationOfIdentifiedNPAListErrorsFormValues,
  })

  const handleOnClose = () => {
    NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationOfIdentifiedNPAListErrorsForm.MenuManager
          initialMenuHash={notificationOfIdentifiedNPAListErrorsMenuState}
          initialSectionId={notificationOfIdentifiedNPAListErrorsMenuState[0].id}
        >
          <NotificationOfIdentifiedNPAListErrorsForm.Manager
            formId={formId}
            projectId={projectId}
            rolesOfUser={rolesOfUser}
            initialErrorsFromViolations={initialErrorsFromViolations}
            editMode={editMode}
            onClose={handleOnClose}
          >
            <NotificationOfIdentifiedNPAListErrorsLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление о выявленных несоответствиях в списке НПА
                </Typography.Headline>
                <NotificationOfIdentifiedNPAListErrorsForm.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <NotificationOfIdentifiedNPAListErrorsForm.Menu className={styles.layout__menu} />
              </div>
            </NotificationOfIdentifiedNPAListErrorsLayoutWrapper>
          </NotificationOfIdentifiedNPAListErrorsForm.Manager>
        </NotificationOfIdentifiedNPAListErrorsForm.MenuManager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NotificationOfIdentifiedNPAListErrorsLayout)
