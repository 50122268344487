import React from 'react'
import { useFormContext } from 'react-hook-form'

import InfoAboutDocumentSection from '@components/DocumentFormComponents/FormSections/InfoAboutDocumentSection'
import { useCertificateOfImplementationInvestprojectStagesManager } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Manager'
import { CertificateOfImplementationInvestprojectStagesFormValues } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'
import { CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/watcher'

const First = () => {
  const formInstance = useFormContext<CertificateOfImplementationInvestprojectStagesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOfImplementationInvestprojectStagesManager()

  if (!formInstance) return null

  return (
    <InfoAboutDocumentSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}
export default First
