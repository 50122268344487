import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'

const {
  getDefaultRfFieldState,
  getDefaultSubjectsFieldState,
  getDefaultMunicipalitiesFieldState,
  getDefaultInvestorFieldState,
} = FieldStateHelpers

const mapOfAAChangingVolumeReimbursementMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация, реализующая проект' },
  { id: '5', title: 'Участие МСП в проекте' },
  { id: '6', title: 'Изменение объема планируемых к возмещению затрат' },
  { id: '7', title: 'Изменяемые пункты соглашения' },
]

const defaultAAChangingFormValues: AAChangingVolumeReimbursementFormValues = {
  '1': getDefaultRfFieldState(),
  '2': {
    subjects: getDefaultSubjectsFieldState(),
  },
  '3': {
    municipalities: getDefaultMunicipalitiesFieldState(),
  },
  '4': getDefaultInvestorFieldState(),
  '5': {
    isSMBusinessesInvolved: false,
  },
  '6': {
    steps: [],
  },
  '7': {
    oldAgreementLinkChanges: [],
  },
  additionalFields: {
    isFederal: true,
    isPaper: true,
    initialMunicipalities: [],
  },
}

const aAChangingVolumeReimbursementBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorFullName: '4.investorFullName',
    investorHeaderName: '4.investorHeaderName',
    investorHeaderNameGenitive: '4.investorHeaderNameGenitive',
    investorHeaderPosition: '4.investorHeaderPosition',
    investorHeaderPositionGenitive: '4.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '4.investorHeaderReasonGenitive',
  },
  '5': {
    isSMBusinessesInvolved: '5.isSMBusinessesInvolved',
  },
  '6': {
    steps: '6.steps',
  },
  '7': {
    oldAgreementLinkChanges: '7.oldAgreementLinkChanges',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    isPaper: 'additionalFields.isPaper',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
  },
} as const

export {
  aAChangingVolumeReimbursementBlockValues,
  defaultAAChangingFormValues,
  mapOfAAChangingVolumeReimbursementMenu,
}
