import env from '@config'
import { IS_DEVELOPMENT } from '@constants/system'

export const BASE_API = '/api/v1/kap'

export const PUBLIC_API = '/api/public/kap'

export const INTERNAL_API = '/api/internal/kap'
export const PUBLIC_AUTHORIZED_USERS = '/api/public/kap/inns/mers-oivs'

const BaseUrls = Object.freeze({
  Kap: BASE_API,
  Dfo: `${BASE_API}/dfos`,
  ProjectsNew: `${BASE_API}/projects`,
  ProjectNew: `${BASE_API}/project`,
  NPA: `${BASE_API}/npas`,
  FLC: `${BASE_API}/flc`,
  InternalDocumentsNew: `${INTERNAL_API}/documents`,
  DocumentsSets: `${BASE_API}`,
  MersOIVS: `${BASE_API}/inns/mers-oivs`,
  Audit: `${BASE_API}/audit`,
  Users: `${BASE_API}/users`,
  Variables: `${BASE_API}/variables`,
  Logging: `${BASE_API}/logging`,
  Logout: `${BASE_API}/auth/logout`,
  Projects: `${BASE_API}/odo/szpks`,
  Operators: `${BASE_API}/operators`,
  Documents: `${BASE_API}/documents`,
  Archiving: `${BASE_API}/archiving`,
  Dictionaries: `${BASE_API}/dictionaries`,
  Organization: `${BASE_API}/organization`,
  DocumentsKap: `${BASE_API}/s3/documents`,
  Procurations: `${BASE_API}/procurations`,
  PublicProcurations: `${PUBLIC_API}/procurations`,
  Organizations: `${BASE_API}/organizations`,
  Notifications: `${BASE_API}/notifications`,
  NotificationsCount: `${BASE_API}/notifications/count`,
  SignData: `${BASE_API}/auth/signature/data`,
  UKEPToken: `${BASE_API}/auth/signature/token`,
  OperatorToken: `${BASE_API}/auth/operator/token`,
  InternalDocuments: `${INTERNAL_API}/s3/documents`,
  Token: `${BASE_API}/auth/signature/token`,
  RefreshToken: `${BASE_API}/auth/refresh/token`,
  RegistrationOrganization: `${BASE_API}/organization/registration`,
  Deadlines: `${BASE_API}/deadlines`,
  AskEmailApproving: `${BASE_API}/email-approving/ask`,
  Monitoring: `${BASE_API}/monitoring`,
  Forms: `${BASE_API}/forms`,
})

export const NewUrls = Object.freeze({
  Project: `${BASE_API}/project`,
  Projects: `${BASE_API}/projects`,
  S3Projects: `${BASE_API}/s3/projects`,

  FLC: `${BASE_API}/flc`,
  S3Dfos: `${BASE_API}/s3/dfos`,
  AuthorizedOrganizations: `${BASE_API}/authorized-organizations`,
})

export const LetterHeadPartsUrls = Object.freeze({
  UpLoadPart: `${BASE_API}/flc/organizations/letterhead-parts`,
  Parts: `${BaseUrls.Organizations}/letterhead-parts`,
  Preview: `${BaseUrls.Documents}/organizations/letterhead-parts/preview`,
  DownloadFile: `${BaseUrls.Documents}/organizations/letterhead-parts/:fileId`,
  OrganizationParts: `${BaseUrls.Organizations}/:organizationId/letterhead-parts`,
})

export const Urls = Object.freeze({
  ...BaseUrls,
  Transfers: `${BaseUrls.Kap}/transfers`,
  TransfersById: `${BaseUrls.Kap}/transfers/:transferId`,
  DeleteProject: `${NewUrls.Projects}/:projectId`,
  NotificationsSummary: `${BaseUrls.Notifications}/summary`,
  LoadDocument: `${BaseUrls.Documents}/process/loadFile`,
  DfoList: `${BaseUrls.Dfo}/list`,
  Npas: `${BaseUrls.FLC}/npas`,
  OrganizationProfiles: `${BaseUrls.Organization}/users/profiles`,
  DfoProcessTypes: `${BaseUrls.Dfo}/dictionaries/process-types`,
  DfoDocumentsTypes: `${BaseUrls.Dfo}/dictionaries/process-documents-types`,
  DfoSubTypes: `${BaseUrls.Dfo}/dictionaries/dfo-subtypes`,
  DfoValidStages: `${BaseUrls.Dfo}/dictionaries/valid-stages`,
  DfoOKVDCodes: `${BaseUrls.Dfo}/dictionaries/okveds`,
  ApproveEmail: `${BaseUrls.Kap}/email-approving/approve`,
  Reports: `${BaseUrls.Monitoring}/reports`,
  UpdateAuthorizedOrganizations: `${NewUrls.AuthorizedOrganizations}/:inn/modify`,
  OldUpdateAuthorizedOrganizations: `${NewUrls.AuthorizedOrganizations}/:inn`,

  ESIAAuth: `${BaseUrls.Kap}/auth/esia`,
  CheckRegistrationStatus: `${BaseUrls.Organization}/registration/status`,

  DocumentSetsForms: `${BaseUrls.ProjectsNew}/dfos/document-sets/forms`,
  DynamicByProjectIdDocumentSetsForms: `${BaseUrls.ProjectsNew}/:projectId/dfos/document-sets/forms`,

  DfosDigitizing: `${BaseUrls.ProjectsNew}/dfos/digitizing`,

  OrganizationVerify: `${BaseUrls.Organization}/verify`,
})

export const DependentUrls = Object.freeze({
  Regions: `${Urls.ProjectsNew}/:projectId/dfos/dictionaries/regions`,

  DfoSet: `${BaseUrls.Kap}/projects/:projectId/dfos/:dfoId/document-sets`,
  DfosById: `${Urls.Dfo}/:dfosId`,
  ProjectsById: `${Urls.ProjectsNew}/:projectId`,
  DocumentById: `${Urls.Documents}/:id`,
  NpasDocumentById: `${Urls.Documents}/npas/:documentId`,
  HashDocument: `${Urls.Documents}/:documentId/hash`,
  Notifications: `${Urls.Notifications}/:dfoId`,
  ReadNotification: `${Urls.Notifications}/:notificationId`,
  DocumentsToSign: `${Urls.Dfo}/:dfoId/documents/for-sign`,
  CreateFolder: `${Urls.Dfo}/:dfoId/folders`,
  BlockOrganization: `${Urls.Organizations}/:id/block`,
  UnblockOrganization: `${Urls.Organizations}/:id/unblock`,
  ChangeDfoSigningTypeByDfoId: `${Urls.Dfo}/:dfoId/signing-type`,
  ChangeDfoStatus: `${Urls.Dfo}/:dfosId/processes/:processId/status`,
  DfoProcesses: `${Urls.Dfo}/:dfoId/processes`,
  DfoLinkedList: `${Urls.Dfo}/:dfoId/linked`,
  NpaListFile: `${Urls.Dfo}/:dfoId/inventory`,
  NpaList: `${Urls.Dfo}/:dfoId/npas/list`,
  DfoMembers: `${Urls.Dfo}/:dfoId/processes/:processId/members`,
  DfoMemberById: `${Urls.Dfo}/:dfoId/processes/:processId/members/:memberId`,
  RegistrateDfo: `${Urls.Dfo}/:dfoId/registrate`,
  VersionsByDfoId: `${Urls.Dfo}/:dfoId/versions`,
  AttributeByName: `${Urls.Dfo}/:dfoId/attributes/:name`,
  OrganizationUserById: `${Urls.Organization}/users/:id`,
  UserById: `${Urls.Users}/:id`,
  OperatorById: `${Urls.Operators}/:id`,
  ChangeOperatorPassword: `${Urls.Operators}/:id/password`,

  DfoDocuments: `${Urls.Dfo}/:dfoId/processes/:processId/documents`,
  DfoDocumentsById: `${Urls.Dfo}/:dfoId/processes/:processId/documents/:id`,
  DfoOKVDCodesByCode: `${Urls.ProjectsNew}/dfos/dictionaries/okved/:code`,
  DfoOKVDCodesByProjectId: `${Urls.ProjectsNew}/:projectId/dfos/dictionaries/okved`,
  DfoDocumentsTypesByProjectId: `${Urls.ProjectsNew}/:projectId/dfos/dictionaries/process-documents-types`,
  DfoCreateFolder: `${Urls.Dfo}/:dfoId/processes/:processId/folders`,
  DfoUpdateFolder: `${Urls.Dfo}/:dfoId/processes/:processId/folders/:folderId`,
  DateDocuments: `${Urls.Dfo}/:dfoId/processes/:processId/documents/:documentId/fields`,
  DfoNpas: `${Urls.Dfo}/:dfoId/processes/:processId/npas`,
  DfoNpasById: `${Urls.Dfo}/:dfoId/processes/:processId/npas/:id`,
  DfoStageFlc: `${Urls.Dfo}/:dfoId/:action/flc`,
  DfoStage: `${Urls.Dfo}/:dfoId/:action`,
  documentTypeByType: `${Urls.Dfo}/dictionaries/documents-templates/:documentType`,

  downloadTemplateByType: `${Urls.Dfo}/dictionaries/document-templates/:documentType`,

  EventsByProjectId: `${Urls.ProjectsNew}/:projectId/events`,

  // Получения списка справочников по dictionaryName
  ReferenceBooks: `${Urls.Dictionaries}/:dictionaryName/elements`,
  ReferenceBooksById: `${Urls.Dictionaries}/:dictionaryName/elements/:id`,
  ReferenceBookByCode: `${Urls.Dictionaries}/:dictionaryName/elements/single-value`,

  ReferenceBooksChildsById: `${Urls.Dictionaries}/:dictionaryName/elements/:id/children`,

  //Получить хэш документа
  GetHashDocumentFromS3: `${NewUrls.S3Projects}/:projectId/document-sets/:documentSetId/documents/:documentId/versions/:versionId/hash`,

  SaveDetachedSignature: `${NewUrls.FLC}/dfos/:dfoId/document-sets/:documentSetId/signature`,
  CreateInventory: `${NewUrls.Projects}/:projectId/actions/:actionId/inventorize`,
  CheckFlc: `${NewUrls.Projects}/:projectId/actions/:actionId/flc`,

  DownloadProjectArchive: `${NewUrls.Projects}/:projectId/archive`,

  DownloadDfoArchive: `${NewUrls.Projects}/:projectId/dfos/:dfoId/archive`,

  //Создание документа(post)
  SaveFLCDocument: `${NewUrls.FLC}/projects/:projectId/document-sets/:documentSetId/documents`,
  SaveDocument: `${NewUrls.Projects}/:projectId/dfos/:dfoId/document-sets/:documentSetId/documents`,

  //Создание подписи
  SaveFLCSignature: `${NewUrls.FLC}/projects/:projectId/document-sets/:documentSetId/signature`,
  SaveDocumentSignature: `${NewUrls.Projects}/:projectId/dfos/:dfoId/document-sets/:documentSetId/documents/:documentId/signature`,
  SaveForeignSignature: `${NewUrls.Projects}/:projectId/dfos/:dfoId/document-sets/:documentSetId/documents/:documentId/foreign-signature`,

  //Get info, put document, delete document. Изменяется лишь тип запроса
  ActionsWithDocument: `${NewUrls.Projects}/:projectId/dfos/:dfoId/document-sets/:documentSetId/documents/:documentId`,

  SaveFolder: `${NewUrls.Projects}/:projectId/dfos/:dfoId/document-sets/:documentSetId/folders`,
  ActionsWithFolders: `${NewUrls.Projects}/:projectId/dfos/:dfoId/document-sets/:documentSetId/folders/:folderId`,

  DownloadFile: `${NewUrls.S3Projects}/:projectId/document-sets/:documentSetId/documents/:documentId/versions/:versionId`,

  GetUnsignedDocuments: `${NewUrls.Projects}/:projectId/actions/:actionId/unsigned-documents`,
  ChangeProjectAction: `${NewUrls.Projects}/:projectId/actions/:actionId`,
  GetProjectActions: `${NewUrls.Projects}/:projectId/actions`,
  ProjectAttribute: `${NewUrls.Projects}/:projectId/attributes`,
  ProjectRegistrate: `${NewUrls.Projects}/:projectId/actions/:actionId/register`,

  //Формирование черновика
  GeneratedStatement: `${NewUrls.Projects}/:projectId/dfos/:dfoId/document-sets/:documentSetId/statement/pdf`,

  //Создание необязательного документа, заполняемого через веб-форму в набор документов
  CreateFormDocument: `${NewUrls.Projects}/dfos/document-sets/:documentSetId/forms`,

  //Удаление формы необязательного документа из набора документов
  DeleteFormDocument: `${NewUrls.Projects}/dfos/document-sets/forms/:formId`,

  ProjectDfos: `${NewUrls.Projects}/:projectId/dfos`,

  //Обновление с указанием порядка обработки процесса ОДО участниками
  ProcessingOrder: `${NewUrls.Projects}/:projectId/dfos/:dfoId/processing-order`,

  //Удаление dfo из проекта
  ActionsWithDfo: `${NewUrls.Projects}/:projectId/dfos/:dfoId`,

  //Внесение изменений в поданные документы
  CreateProcess: `${NewUrls.Projects}/:projectId/actions/:actionId/processes`,

  //Создание системного файла
  CreateSystemDocument: `${NewUrls.Projects}/:projectId/actions/:actionId/system-documents`,

  //Получение согласующего органа
  SolicitationMembers: `${NewUrls.Projects}/:projectId/dfos/:dfoId/solicitation-members`,

  // Указание почты
  EmailIndication: `${Urls.Kap}/organization/users/:id`,

  // Изменение данных пользователя организации
  ChangeOrganizationUserDetails: `${BaseUrls.Organization}/users/:id/additional-data`,

  //Изменение данных организации
  ChangeOrganizationInfo: `${BaseUrls.Organization}/additional-data`,

  //Получение списка версий
  GetVersions: `${NewUrls.Projects}/:projectId/dfos/:dfoId/versions`,

  //Получение регионов у заявления
  GetStatementRegions: `${BaseUrls.Dfo}/:dfoId/statement/regions`,

  //Создание копии проекта
  CopyProject: `${NewUrls.Projects}/:projectId/copy`,

  //Получение участников
  Members: `${NewUrls.Projects}/:projectId/members`,

  //Действия с  NPA по id
  NPAById: `${Urls.NPA}/:npaId`,

  NPAPartsById: `${Urls.NPA}/:npaId/parts/get`,
  NPAPartById: `${Urls.NPA}/:npaId/parts/:partId`,

  //Получение документа NPA по id
  NPADocumentById: `${Urls.Documents}/npas/:documentId`,

  //Работа с черновиками
  Drafts: `${Urls.ProjectsNew}/:projectId/dfos/:dfoId/document-sets/:documentSetId/drafts/:documentType`,

  //Конвертирует черновик в документ
  ConvertDraft: `${NewUrls.Projects}/:projectId/actions/:actionId/drafts/convert`,

  //Получение actions для форм в списке уведомлений
  OrganizationActions: `${NewUrls.Projects}/organization-info-changes/actions`,

  //Получение информации об организации по organizationId
  OrganizationById: `${Urls.Organizations}/:organizationId`,

  //Получение аттрибутов dfo
  DfoAttributes: `${NewUrls.Projects}/:projectId/dfos/:dfoId/attributes`,

  //Показатели dfo
  DfoProperties: `${NewUrls.Projects}/dfos/:dfoId/properties`,

  //Изменение ИНН авторизованной организации (ОМСУ)
  UpdateINNAuthorizedOrganizations: `${NewUrls.AuthorizedOrganizations}/:inn/replace`,

  //Изменение полей без изменения ИНН авторизованной организации (ОМСУ)
  UpdateAuthorizedOrganizations: `${NewUrls.AuthorizedOrganizations}/:inn`,

  //Показатели формы
  FormProperties: `${Urls.DocumentSetsForms}/:formId/properties`,
  //Показатели формы внутри документ сета(обновление)
  ChangesFormProperties: `${Urls.DocumentSetsForms}/:formId/changes`,
  //Получить хэш документа формы
  GetHashFormDocument: `${Urls.DocumentSetsForms}/:formId/hash`,

  //Получение данных LOOKUP
  Lookup: `${Urls.DynamicByProjectIdDocumentSetsForms}/:formId/properties/:propertyId/lookup`,

  //Получение pdf-документа
  FormPreview: `${Urls.DocumentSetsForms}/:formId/preview`,

  //Получение pdf-документа c закомиченными данными
  FormCommittedFile: `${Urls.DocumentSetsForms}/:formId/committedPdf`,

  //FLC на форму
  FormFLC: `${Urls.DocumentSetsForms}/:formId/properties/flc`,

  //Доступные регионы относительно проекта
  RegionsByProjectId: `${Urls.ProjectsNew}/:projectId/projectRegions`,

  //Cнятие блокировки подписания
  SigningBlock: `${Urls.ProjectsNew}/:projectId/blockings`,
})

export const API_URL =
  location.origin.includes('localhost') || IS_DEVELOPMENT ? env.REACT_APP_API_URL : location.origin
