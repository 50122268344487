import React, { FC, memo } from 'react'

import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import WebFormDocumentItem from '@components/Attachments/WebForm/WithDocumentController/DocumentItem'
import WebFormDocumentStatus from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DocumentStatus'
import WebFormDocumentDownloadButton from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DownloadButton'
import WebFormDocumentFormButton from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/FormButton'
import type { WebFormStateWithDocumentControllerStateProps } from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/types'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import Button from '@components/NewDesign/Button'
import env from '@config'
import { defaultTextEditableWebFormTypes } from '@constants/types'
import { isUndefined } from '@helpers/checkTypes'

const WebFormEditableStateWithDocumentController: FC<
  WebFormStateWithDocumentControllerStateProps
> = ({
  disabled,
  document,
  permissions,
  downloadProps,
  statusType,
  onOpenForm,
  documentInfoFromDict,
  digitizingInProcess,
  onControlClick,
}) => {
  const { isVisible, description: title, documentType } = documentInfoFromDict

  const { isWebFormReadDigitizing, isWebFormReadDownloadingForbidden } =
    useWebFormPermissions(permissions)

  const openFormButtonRenderCondition = !isUndefined(onOpenForm)

  if (!!digitizingInProcess && !isWebFormReadDigitizing) {
    return (
      <WebFormDocumentItem
        document={document}
        title={title}
        leadingNodeContent={statusType && <WebFormDocumentStatus type={statusType} />}
        trailingNodeContent={<WebFormDocumentDownloadButton {...downloadProps} />}
        onClick={onControlClick}
      />
    )
  }

  if (!!digitizingInProcess && isWebFormReadDigitizing && isVisible) {
    return (
      <WebFormDocumentItem
        document={document}
        title={title}
        leadingNodeContent={statusType && <WebFormDocumentStatus type={statusType} />}
        trailingNodeContent={
          <>
            {openFormButtonRenderCondition && (
              <WebFormDocumentFormButton
                dataTestId="fillFormButton"
                disabled={disabled}
                onClick={onOpenForm(true)}
              >
                {defaultTextEditableWebFormTypes[documentType] || 'Заполнить форму'}
              </WebFormDocumentFormButton>
            )}
            <WebFormDocumentDownloadButton {...downloadProps} />
          </>
        }
        onClick={onControlClick}
      />
    )
  }

  if (!!digitizingInProcess && isWebFormReadDigitizing && !isVisible) {
    return (
      <ControlContainer
        title={title}
        leadingNodeContent={<ControlContainerStatusIcon iconType="form" />}
        trailingNodeContent={
          openFormButtonRenderCondition && (
            <Button
              fixWidth
              disabled={disabled}
              dataTestId="fillFormButton"
              view="tined"
              onClick={onOpenForm(true)}
            >
              {defaultTextEditableWebFormTypes[documentType] || 'Заполнить форму'}
            </Button>
          )
        }
      />
    )
  }

  if (!digitizingInProcess && isWebFormReadDownloadingForbidden) {
    return (
      <WebFormDocumentItem
        document={document}
        title={title}
        leadingNodeContent={statusType && <WebFormDocumentStatus type={statusType} />}
        onClick={onControlClick}
      />
    )
  }

  return (
    <ControlContainer
      color="negative"
      title={title}
      leadingNodeContent={<ControlContainerStatusIcon iconType="error" />}
      additionalMainContent={
        <ControlContainerSubtitle
          additionalContent={
            <Button
              href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}
              size="xs"
              view="text"
              variant="buttonSMedium"
              color="negative"
            >
              Сообщить в поддержку
            </Button>
          }
        >
          Произошла ошибка получения формы документа.
        </ControlContainerSubtitle>
      }
    />
  )
}

export default memo(WebFormEditableStateWithDocumentController)
