import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import Loader from '@components/Loader'
import { ControlledSingleSelect } from '@components/NewDesign/Select'
import { OptionProps } from '@components/NewDesign/Select/model'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea, {
  ControllerTextarea,
} from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import { ORGANIZATION_ROLES } from '@components/OrganizationInfo/constants'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import RadioButton from '@components/Radio/Item'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import {
  organizationUserDetailsFormNames,
  organizationUserDetailsProcurationFieldValues,
} from '@components/Sidebars/OrganizationUserDetails/constants'
import { isEmptyField } from '@components/Sidebars/OrganizationUserDetails/Form/WithSign/utils'
import { OrganizationUserDetailsFormValues } from '@components/Sidebars/OrganizationUserDetails/types'
import { defaultRHFValidation, lengthValidate, trimmedValueValidate } from '@constants/validations'
import { procurationsAuthoritiesMap } from '@context/APIContext/hooks/useProcurationsApi/constants'
import { getUserRole } from '@context/AuthContext/workers/rolesWorkers'
import { isString } from '@helpers/checkTypes'
import { findOne } from '@helpers/commonHelpers'
import { useProcurations } from '@hooks/new/swr/useProcurations'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import cn from 'classnames'
import dayjs from 'dayjs'

import styles from './OrganizationUserDetailsWithSignForm.module.scss'

const filteredProcurationsBySIGNKey = {
  _key: 'procurations',
  authorities: procurationsAuthoritiesMap.SIGN,
}

interface OrganizationUserDetailsWithSignFormProps {
  editMode: boolean
  userId: string
  formInstance: UseFormReturn<OrganizationUserDetailsFormValues>
}

const OrganizationUserDetailsWithSignForm: FC<OrganizationUserDetailsWithSignFormProps> = ({
  editMode,
  userId,
  formInstance,
}) => {
  const [defaultSelectedProcuration, setDefaultSelectedProcuration] = useState<OptionProps | null>(
    null,
  )

  const reasonProcurationIdNodeRef = useRef<HTMLDivElement>(null)
  const reasonDocumentNodeRef = useRef<HTMLDivElement>(null)

  const allFormValues = useWatch({
    control: formInstance.control,
  })
  const procurationValue = useWatch({
    control: formInstance.control,
    name: organizationUserDetailsFormNames.procuration,
  })
  const reasonProcurationId = useWatch({
    control: formInstance.control,
    name: organizationUserDetailsFormNames.reasonProcurationId,
  })

  const { roles } = getUserRole?.()
  const isAuthorizedRole = findOne(roles, ORGANIZATION_ROLES)

  const isShowReasonProcurationId =
    procurationValue === organizationUserDetailsProcurationFieldValues.reasonProcurationId
  const isShowReasonDocument =
    procurationValue === organizationUserDetailsProcurationFieldValues.reasonDocument

  const { procurations, isLoadingProcurations } = useProcurations({
    key: {
      ...filteredProcurationsBySIGNKey,
      userId,
      procuration: procurationValue,
    },
    config: {
      isPaused: () => !isShowReasonProcurationId,
    },
  })

  const currentNodeRef = isShowReasonProcurationId
    ? reasonProcurationIdNodeRef
    : reasonDocumentNodeRef

  const preparedProcurationsOptions: OptionProps[] = useMemo(() => {
    if (!procurations || !procurations.length) return []

    return procurations
      .filter(({ validity, id }) => validity || id === defaultSelectedProcuration?.value)
      .map(({ number, endDate, id }) => ({
        value: id,
        displayValue: number,
        label: `действует до ${endDate}`,
      }))
  }, [defaultSelectedProcuration, procurations])

  useEffect(() => {
    const reasonProcurationNumber = formInstance.watch(
      organizationUserDetailsFormNames.reasonProcurationNumber,
    )
    const reasonProcurationEndDate = formInstance.watch(
      organizationUserDetailsFormNames.reasonProcurationEndDate,
    )

    if (!reasonProcurationId || !reasonProcurationNumber || !reasonProcurationEndDate) return

    setDefaultSelectedProcuration({
      value: reasonProcurationId,
      displayValue: reasonProcurationNumber,
      label: `действует до ${reasonProcurationEndDate}`,
    })
  }, [])

  // триггерит валидацию reasonProcurationId, если есть дефолтное значение
  useEffect(() => {
    if (isLoadingProcurations || !defaultSelectedProcuration) return

    formInstance.trigger(organizationUserDetailsFormNames.reasonProcurationId)
  }, [isLoadingProcurations, defaultSelectedProcuration])

  const conditionToAnotherFieldIsEmpty = useMemo(() => {
    const isPositionEmpty = isEmptyField(allFormValues.position)
    const isPositionGenitiveEmpty = isEmptyField(allFormValues.positionGenitive)
    const isFioGenitiveEmpty = isEmptyField(allFormValues.fioGenitive)
    const isReasonProcurationEmpty =
      isEmptyField(allFormValues.reasonProcurationId) && isEmptyField(allFormValues.reasonDocument)

    return (
      isPositionEmpty || isPositionGenitiveEmpty || isFioGenitiveEmpty || isReasonProcurationEmpty
    )
  }, [allFormValues])

  const conditionToRenderWarningUserText =
    editMode && (conditionToAnotherFieldIsEmpty || !formInstance.formState.isValid)

  const handleChangeProcurationIdValue = (value: string) => {
    const currentProcuration = (procurations ?? []).find((procuration) => procuration.id === value)

    formInstance.setValue(
      organizationUserDetailsFormNames.reasonProcurationNumber,
      currentProcuration?.number ?? '',
    )
    formInstance.setValue(
      organizationUserDetailsFormNames.reasonProcurationEndDate,
      currentProcuration?.endDate ?? '',
    )

    setTimeout(() => {
      formInstance.trigger(organizationUserDetailsFormNames.reasonProcurationId)
    })
  }

  return (
    <Stack direction="vertical" gap={3}>
      {isAuthorizedRole && (
        <Row>
          <Col xs={12}>
            <ControlledSwitch
              name={organizationUserDetailsFormNames.isAuthorizedPerson}
              control={formInstance.control}
              switchProps={{
                disabled: !editMode,
                label:
                  'Может быть указан в качестве уполномоченного лица в документах, подписываемых от имени организации',
              }}
            ></ControlledSwitch>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          <Typography.Body
            variant="bodyMMedium"
            color={`${
              conditionToRenderWarningUserText ? 'text-accent-orange-secondary' : undefined
            }`}
          >
            {conditionToRenderWarningUserText
              ? 'Укажите данные пользователя. Они необходимы для формирования документов'
              : 'Данные пользователя для формирования документов'}
          </Typography.Body>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="p-0">
          <ControlledTextarea
            name={organizationUserDetailsFormNames.position}
            control={formInstance.control}
            rules={{
              required: defaultRHFValidation.required,
              validate: {
                positiveTrimmedValue: trimmedValueValidate,
                positiveLength: (value) => isString(value) && lengthValidate(value, 500),
              },
            }}
            textareaProps={{
              disabled: !editMode,
              fixWidth: true,
              label: 'Должность',
              size: 'xl',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="p-0">
          <ControlledTextarea
            name={organizationUserDetailsFormNames.positionGenitive}
            control={formInstance.control}
            rules={{
              required: defaultRHFValidation.required,
              validate: {
                positiveTrimmedValue: trimmedValueValidate,
                positiveLength: (value) => isString(value) && lengthValidate(value, 500),
              },
            }}
            textareaProps={{
              disabled: !editMode,
              fixWidth: true,
              label: 'Должность в родительном падеже',
              size: 'xl',
              caption: 'например, Генерального директора',
              captionClassName: styles['organizationUserDetailsWithSignForm__field-caption'],
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="p-0">
          <ControllerTextarea
            name={organizationUserDetailsFormNames.fioGenitive}
            control={formInstance.control}
            rules={{
              required: defaultRHFValidation.required,
              validate: {
                positiveTrimmedValue: trimmedValueValidate,
                positiveLength: (value) => isString(value) && lengthValidate(value, 500),
              },
            }}
            textareaProps={{
              disabled: !editMode,
              fixWidth: true,
              label: 'ФИО в родительном падеже',
              size: 'xl',
              caption: 'например, Иванова Ивана Ивановича',
              captionClassName: styles['organizationUserDetailsWithSignForm__field-caption'],
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={'pt-3'}>
          <Stack direction="vertical" gap={3}>
            <Row>
              <Col xs={12} className="pl-3">
                <Typography.Body variant="bodyLMedium">Основание действий</Typography.Body>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Stack direction="vertical" gap={2}>
                  <Row>
                    <ControlledRadioGroup
                      name={organizationUserDetailsFormNames.procuration}
                      control={formInstance.control}
                      radioGroupProps={{
                        disabled: !editMode,
                        direction: 'horizontal',
                      }}
                    >
                      <Col xs={12}>
                        <Row>
                          <Col xs={5}>
                            <RadioButton
                              align="center"
                              variant="buttonMMedium"
                              label="Доверенность"
                              value={
                                organizationUserDetailsProcurationFieldValues.reasonProcurationId
                              }
                              className={
                                styles['organizationUserDetailsWithSignForm__radio-button']
                              }
                            />
                          </Col>
                          <Col xs={5}>
                            <RadioButton
                              align="center"
                              variant="buttonMMedium"
                              value={organizationUserDetailsProcurationFieldValues.reasonDocument}
                              label="Другое"
                              className={
                                styles['organizationUserDetailsWithSignForm__radio-button']
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </ControlledRadioGroup>
                  </Row>
                  <Row>
                    <Col xs={12} className="p-0">
                      <SwitchTransition>
                        <CSSTransition
                          mountOnEnter
                          unmountOnExit
                          key={procurationValue}
                          nodeRef={currentNodeRef}
                          classNames={{
                            enter: styles['transition__fade-enter'],
                            exit: styles['transition__fade-exit'],
                            enterActive: styles['transition__fade-enter--active'],
                            exitActive: styles['transition__fade-exit--active'],
                          }}
                          addEndListener={(done) =>
                            currentNodeRef.current?.addEventListener('transitionend', done, false)
                          }
                        >
                          <div ref={currentNodeRef}>
                            {isShowReasonProcurationId && (
                              <Loader
                                loading={isLoadingProcurations}
                                variant="lite"
                                wrapperClassName={
                                  styles['organizationUserDetailsWithSignForm__loader-wrapper']
                                }
                              >
                                <Row>
                                  <Col xs={12}>
                                    {!preparedProcurationsOptions.length ? (
                                      <Typography.Body
                                        variant="bodySMedium"
                                        color="text-base-secondary"
                                        className={
                                          styles[
                                            'organizationUserDetailsWithSignForm__options-notFound'
                                          ]
                                        }
                                      >
                                        <span
                                          className={
                                            styles[
                                              'organizationUserDetailsWithSignForm__options-notFoundSpan'
                                            ]
                                          }
                                        >
                                          У пользователя не найдено ни одной действующей
                                          доверенности.
                                        </span>
                                        {
                                          ' Убедитесь, что машиночитаемая доверенность создана и выдана. В ином случае укажите другое основание для юридически значимых действий'
                                        }
                                      </Typography.Body>
                                    ) : (
                                      <ControlledSingleSelect
                                        disabled={!editMode}
                                        options={preparedProcurationsOptions}
                                        defaultOptionsSelected={
                                          !defaultSelectedProcuration
                                            ? undefined
                                            : [defaultSelectedProcuration]
                                        }
                                        controllerProps={{
                                          name: organizationUserDetailsFormNames.reasonProcurationId,
                                          control: formInstance.control,
                                          rules: {
                                            required: defaultRHFValidation.required,
                                            validate: {
                                              positiveCurrentDate: () => {
                                                const currentDate =
                                                  DayjsService.dayjsWithFormatToMSK()
                                                const reasonProcurationEndDate = formInstance.watch(
                                                  organizationUserDetailsFormNames.reasonProcurationEndDate,
                                                )

                                                if (
                                                  isDateValidForDayjs(reasonProcurationEndDate) &&
                                                  dayjs(reasonProcurationEndDate) < currentDate
                                                ) {
                                                  return `Срок действия истек ${reasonProcurationEndDate}`
                                                }
                                              },
                                            },
                                          },
                                        }}
                                        inputProps={{
                                          clear: true,
                                          fixWidth: true,
                                          view: 'secondary',
                                          label: 'МЧД',
                                          addonsClassName:
                                            styles[
                                              'organizationUserDetailsWithSignForm__field-addons'
                                            ],
                                        }}
                                        popoverProps={{
                                          zIndex: 51,
                                        }}
                                        optionsProps={{
                                          view: 'reversed',
                                          DisplayValueFC: ({ displayValue, value }) => (
                                            <Typography.Body
                                              variant={'bodyMMedium'}
                                              className={cn(
                                                styles[
                                                  'organizationUserDetailsWithSignForm__options-item'
                                                ],
                                                {
                                                  [styles[
                                                    'organizationUserDetailsWithSignForm__options-item--selected'
                                                  ]]: reasonProcurationId === value,
                                                },
                                              )}
                                            >
                                              {displayValue}
                                            </Typography.Body>
                                          ),
                                          tailNode: (
                                            <Typography.Caption
                                              variant="captionMMedium"
                                              color="text-base-secondary"
                                              className={
                                                styles[
                                                  'organizationUserDetailsWithSignForm__options-tailNode'
                                                ]
                                              }
                                            >
                                              {
                                                'Не нашли нужную МЧД? Возможно, пользователь не применял МЧД при регистрации в системе или срок её действия истёк'
                                              }
                                            </Typography.Caption>
                                          ),
                                        }}
                                        onChangeFormValue={handleChangeProcurationIdValue}
                                      />
                                    )}
                                  </Col>
                                </Row>
                              </Loader>
                            )}
                            {isShowReasonDocument && (
                              <Row>
                                <Col xs={12}>
                                  <ControlledTextarea
                                    name={organizationUserDetailsFormNames.reasonDocument}
                                    control={formInstance.control}
                                    rules={{
                                      required: defaultRHFValidation.required,
                                      validate: {
                                        positiveTrimmedValue: trimmedValueValidate,
                                        positiveLength: (value) =>
                                          isString(value) && lengthValidate(value, 1500),
                                      },
                                    }}
                                    textareaProps={{
                                      disabled: !editMode,
                                      fixWidth: true,
                                      label: 'Действует на основании',
                                      size: 'xl',
                                      caption: 'например, Устава (в родительном падеже)',
                                      captionClassName:
                                        styles[
                                          'organizationUserDetailsWithSignForm__field-caption'
                                        ],
                                    }}
                                  />
                                </Col>
                              </Row>
                            )}
                          </div>
                        </CSSTransition>
                      </SwitchTransition>
                    </Col>
                  </Row>
                </Stack>
              </Col>
            </Row>
          </Stack>
        </Col>
      </Row>
    </Stack>
  )
}

export default OrganizationUserDetailsWithSignForm
