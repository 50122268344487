import { getIdByNameFromUrl } from '@helpers/url/getIdByNameFromUrl'

const PROJECTS_PATHNAME = '/projects/'

class ProjectHelpersService {
  static getProjectIdFromUrl() {
    return getIdByNameFromUrl(PROJECTS_PATHNAME) || null
  }
}

export default ProjectHelpersService
