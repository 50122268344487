import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { summaryReportOfMonitoringResultsBlockValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/const'
import { secondSectionValidationMap } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/2/validation'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import { SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Second = () => {
  const formInstance = useFormContext<SummaryReportOfMonitoringResultsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const { getSubscribableControlProps, getControllerProps, getSingleSelectProps, getInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: summaryReportOfMonitoringResultsBlockValues['2'].initiatorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                controllerProps={getControllerProps({
                  name: summaryReportOfMonitoringResultsBlockValues['2'].initiatorFullName,
                })}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Уполномоченное должностное лицо'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: summaryReportOfMonitoringResultsBlockValues['2'].initiatorHeaderName,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.headerName || '',
                            value: item.id,
                          }),
                          selectProps: {
                            inputProps: {
                              label: 'Фамилия, Имя, Отчество',
                            },
                            onChangeFormValue: () => {
                              setTimeout(() => {
                                handleChangeValue?.(
                                  summaryReportOfMonitoringResultsBlockValues['2']
                                    .initiatorHeaderName,
                                )
                              }, 0)
                            },
                          },
                          controllerProps: {
                            name: summaryReportOfMonitoringResultsBlockValues['2']
                              .initiatorHeaderName,
                            rules: secondSectionValidationMap.initiatorHeaderName,
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <ControlledInput
                  {...getInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['2'].initiatorHeaderPosition,
                    inputProps: {
                      disabled: true,
                      label: 'Должность',
                    },
                  })}
                />
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default Second
