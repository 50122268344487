import { useMemo } from 'react'

import { ISearchElementTree } from '@components/NewDesign/InputSearchTree/types'
import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { IPartClassification } from '@services/NPA/NPA.entity'
import useSWRImmutable from 'swr/immutable'

const useNpaPartClassification = ({
  key,
  config,
}: SWRHookProps<IPartClassification[] | undefined>) => {
  const {
    npaApi: { getNpaPartClassifications },
  } = useAPIContext()

  const {
    data: partClassificationsInRow,
    error,
    mutate,
    isValidating: isLoadingPartClassifications,
  } = useSWRImmutable(key, getNpaPartClassifications, config)

  const partClassificationsInThree = useMemo(() => {
    if (!partClassificationsInRow) return

    const parentCodes = partClassificationsInRow.filter((item) => !item.parentId)

    const generateChildren = (codes: IPartClassification[]) => {
      codes.forEach((item) => {
        const childrenOfItem = partClassificationsInRow.filter(
          (childItem) => childItem.parentId === item.id,
        )

        item.children = childrenOfItem.length ? childrenOfItem : null

        const recursionCondition = childrenOfItem.some((item) => item.level !== '3')

        if (recursionCondition) {
          generateChildren(childrenOfItem)
        }
      })
    }

    generateChildren(parentCodes)

    return parentCodes
  }, [partClassificationsInRow])

  const partClassificationInObjectMap: Map<string, IPartClassification> | undefined =
    useMemo(() => {
      return partClassificationsInRow?.reduce((prevValue, nextValue) => {
        prevValue.set(nextValue.id, nextValue)

        return prevValue
      }, new Map())
    }, [partClassificationsInRow])

  const getTree = (tree: IPartClassification[]): ISearchElementTree[] => {
    return tree.reduce((acc, curr) => {
      if (curr.children) {
        const childrens = getTree(curr.children)

        acc.push({
          displayValue: curr.header,
          value: curr.id,
          children: childrens,
          level: curr.level,
          chapter: curr.chapter,
        })
      } else {
        acc.push({
          displayValue: curr.header,
          value: curr.id,
          level: curr.level,
          chapter: curr.chapter,
        })
      }

      return acc
    }, [] as ISearchElementTree[])
  }

  const partClassificationTreeAsOptions = useMemo(() => {
    if (partClassificationsInThree) return getTree(partClassificationsInThree)
  }, [partClassificationsInThree])

  return {
    partClassifications: partClassificationsInRow,
    partClassificationsInThree,
    partClassificationInObjectMap,
    partClassificationTreeAsOptions,
    error,
    mutate,
    isLoadingPartClassifications,
  }
}

export { useNpaPartClassification }
