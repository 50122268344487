import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface StatementByHeadOfMunicipalityFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const StatementByHeadOfMunicipalityForms: StatementByHeadOfMunicipalityFormsProps = () => null

StatementByHeadOfMunicipalityForms.First = First
StatementByHeadOfMunicipalityForms.Second = Second
StatementByHeadOfMunicipalityForms.Third = Third
StatementByHeadOfMunicipalityForms.Fourth = Fourth

export default StatementByHeadOfMunicipalityForms
