import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { agreementStabilizationNegotiationsBlockValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/const'
import { fifthSectionAgreementStabilizationNegotiationsValidationMap } from '@components/Forms/АgreementStabilizationNegotiationsForm/Forms/5/validation'
import { useAgreementStabilizationNegotiationsManager } from '@components/Forms/АgreementStabilizationNegotiationsForm/Manager'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import { AgreementStabilizationNegotiationsFieldsControlUpdateWatcher } from '@components/Forms/АgreementStabilizationNegotiationsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface FirstVariantProps {
  formInstance: UseFormReturn<AgreementStabilizationNegotiationsFormValues>
  editMode: boolean
  blockViewIsValidating: boolean
}

const FirstVariant: FC<FirstVariantProps> = ({ formInstance, editMode, blockViewIsValidating }) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAgreementStabilizationNegotiationsManager()

  const { getSubscribableControlProps, getInputProps, getTextareaProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AgreementStabilizationNegotiationsFieldsControlUpdateWatcher,
    })

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          <RowWithBorder
            disableBottomDefaultStyles
            title={
              'Пункт соглашения, которым установлен срок, планируемый к увеличению в рамках дополнительного соглашения'
            }
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: agreementStabilizationNegotiationsBlockValues['5']
                  .agreementLinkStabilizationPeriod,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: agreementStabilizationNegotiationsBlockValues['5']
                    .agreementLinkStabilizationPeriod,
                  rules:
                    fifthSectionAgreementStabilizationNegotiationsValidationMap.agreementLinkStabilizationPeriod,
                  inputProps: {
                    caption: 'рекомендация: 8.1',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        agreementStabilizationNegotiationsBlockValues['5']
                          .agreementLinkStabilizationPeriod,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        agreementStabilizationNegotiationsBlockValues['5']
                          .agreementLinkStabilizationPeriod,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </RowWithBorder>
        </Col>
      </Row>
      <ContainerWithBorder>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={6}>
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: agreementStabilizationNegotiationsBlockValues['5'].stabilizationPeriod,
                  inputProps: {
                    label: 'Текущий срок стаб. оговорки (лет)',
                    hasNegativeValue: false,
                    disabled: true,
                    integersOnly: true,
                    suffix: '',
                  },
                })}
              />
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: agreementStabilizationNegotiationsBlockValues['5']
                    .stabilizationPeriodExtend,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: agreementStabilizationNegotiationsBlockValues['5']
                      .stabilizationPeriodExtend,
                    rules:
                      fifthSectionAgreementStabilizationNegotiationsValidationMap.stabilizationPeriodExtend,
                    inputProps: {
                      label: 'Срок продления стаб. оговорки (лет)',
                      hasNegativeValue: false,
                      integersOnly: true,
                      offAmountSplitting: true,
                      suffix: '',
                    },
                    onBlur: () =>
                      setTimeout(async () => {
                        await handleChangeValue?.(
                          agreementStabilizationNegotiationsBlockValues['5']
                            .stabilizationPeriodExtend,
                        )

                        formInstance.trigger(
                          agreementStabilizationNegotiationsBlockValues['5'].newStabilizationPeriod,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(async () => {
                        await debouncedHandleChangeValue?.(
                          agreementStabilizationNegotiationsBlockValues['5']
                            .stabilizationPeriodExtend,
                        )

                        formInstance.trigger(
                          agreementStabilizationNegotiationsBlockValues['5'].newStabilizationPeriod,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableBottomDefaultStyles
                firstColumnSize={8}
                secondColumnSize={4}
                title={'Новый срок стабилизационной оговорки (лет)'}
              >
                <SubscribableControl
                  path={agreementStabilizationNegotiationsBlockValues['5'].newStabilizationPeriod}
                  watcher={AgreementStabilizationNegotiationsFieldsControlUpdateWatcher}
                  {...subscribableControl}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: agreementStabilizationNegotiationsBlockValues['5']
                        .newStabilizationPeriod,
                      rules:
                        fifthSectionAgreementStabilizationNegotiationsValidationMap.newStabilizationPeriod,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
        </Stack>
      </ContainerWithBorder>
      <Row>
        <Col xs={12}>
          <RowWithBorder
            disableBottomDefaultStyles
            title={
              'Пункт ч. 11 ст. 10 Федерального Закона, предусматривающий условия для увеличения стаб. оговорки'
            }
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: agreementStabilizationNegotiationsBlockValues['5']
                  .ruleLinkChangeStabilization,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: agreementStabilizationNegotiationsBlockValues['5']
                    .ruleLinkChangeStabilization,
                  rules:
                    fifthSectionAgreementStabilizationNegotiationsValidationMap.ruleLinkChangeStabilization,
                  inputProps: {},
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        agreementStabilizationNegotiationsBlockValues['5']
                          .ruleLinkChangeStabilization,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        agreementStabilizationNegotiationsBlockValues['5']
                          .ruleLinkChangeStabilization,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </RowWithBorder>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: agreementStabilizationNegotiationsBlockValues['5'].reasonChangeStabilization,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: agreementStabilizationNegotiationsBlockValues['5'].reasonChangeStabilization,
                rules:
                  fifthSectionAgreementStabilizationNegotiationsValidationMap.reasonChangeStabilization,
                textareaProps: {
                  label: 'Выполненное условие и подтверждение его выполнения',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      agreementStabilizationNegotiationsBlockValues['5'].reasonChangeStabilization,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      agreementStabilizationNegotiationsBlockValues['5'].reasonChangeStabilization,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
    </Stack>
  )
}

export default FirstVariant
