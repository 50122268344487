import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationDrawalAppConclusionSZPKFormValues } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof NotificationDrawalAppConclusionSZPKFormValues['2'],
  NotificationDrawalAppConclusionSZPKFormValues
> = {
  ruleLinkStatementRevoke: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { secondSectionValidationMap }
