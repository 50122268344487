import React, { FC, memo, useCallback } from 'react'
import { useParams } from 'react-router'

import WebForm from '@components/Attachments/WebForm'
import { useWebFormControl } from '@components/Attachments/WebForm/hooks/useWebFormControl'
import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import { ExcludedDocumentsSetsTypes, ExcludedWebFormTypes } from '@constants/types'
import { getUserRole } from '@context/AuthContext/workers/rolesWorkers'
import { useBooleanState } from '@hooks/useBooleanState'
import type { IDocumentTypeInfoWithDict } from '@services/Documents/documents.entity'

interface WebFormItemControllerProps {
  documentProps: IDocumentTypeInfoWithDict
  permissions: number
  projectId?: string
  currentDfoId: string
  documentSetId: string
  documentSetType: string
  digitizingInProcess?: boolean
}

const WebFormItemController: FC<WebFormItemControllerProps> = ({
  permissions,
  documentProps,
  projectId: projectIdFromProps,
  currentDfoId,
  documentSetId,
  documentSetType,
  digitizingInProcess,
}) => {
  const { documentInfoFromDict, forms, documents } = documentProps
  const { documentType } = documentInfoFromDict

  const { projectId: projectIdFromParams = '' } = useParams()

  const currentProjectId = projectIdFromParams || projectIdFromProps || ''

  const formInfo = forms?.[0]

  const { currentOpenWebFormHandler } = useWebFormControl({
    documentType,
  })

  const { roles } = getUserRole()

  const { isWebFormReadDigitizing } = useWebFormPermissions(permissions)

  const {
    booleanState: disabledWebFormControl,
    setBooleanStateToTrue: disableWebFormControl,
    setBooleanStateToFalse: enableWebFormControl,
  } = useBooleanState()

  const hasDocuments = !!documents && !!documents.length
  const hasDocumentsWithoutForms = !!documents && !!documents.length && !forms?.length
  const hasDocumentsWithoutDigitizingPermission =
    !!documents && !!documents.length && !!forms?.length && !isWebFormReadDigitizing

  const handleCloseWebForm = useCallback(() => {
    enableWebFormControl()
  }, [enableWebFormControl])

  const handleOpenWebForm = useCallback(
    (editMode?: boolean) =>
      (
        event?:
          | React.MouseEvent<HTMLButtonElement, MouseEvent>
          | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      ) => {
        event?.stopPropagation()

        if (!formInfo) return

        disableWebFormControl()

        currentOpenWebFormHandler({
          formId: formInfo.id,
          projectId: currentProjectId,
          editMode,
          documentsTypeOfSet: documentSetType,
          rolesOfUser: roles,
          onClose: handleCloseWebForm,
        })
      },
    [
      currentOpenWebFormHandler,
      disableWebFormControl,
      documentSetType,
      formInfo,
      handleCloseWebForm,
      currentProjectId,
      roles,
    ],
  )

  if (!!ExcludedDocumentsSetsTypes?.[documentSetType] && !!ExcludedWebFormTypes?.[documentType])
    return null

  if (hasDocuments || hasDocumentsWithoutForms || hasDocumentsWithoutDigitizingPermission) {
    return (
      <WebForm.WithDocumentController
        disabled={disabledWebFormControl}
        documentSetId={documentSetId}
        projectId={currentProjectId}
        currentDfoId={currentDfoId}
        documentInfoFromDict={documentInfoFromDict}
        documents={documents}
        permissions={permissions}
        formInfo={formInfo}
        digitizingInProcess={digitizingInProcess}
        onOpenForm={!!currentOpenWebFormHandler ? handleOpenWebForm : undefined}
      />
    )
  }

  if (!!digitizingInProcess) return null

  return (
    <WebForm.WithoutDocumentController
      disabled={disabledWebFormControl}
      documentSetId={documentSetId}
      projectId={currentProjectId}
      currentDfoId={currentDfoId}
      permissions={permissions}
      documentInfoFromDict={documentInfoFromDict}
      formInfo={formInfo}
      onOpenForm={!!currentOpenWebFormHandler ? handleOpenWebForm : undefined}
    />
  )
}

export default memo(WebFormItemController)
