import { DependentUrls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'

import type {
  CreateFormDocumentProps,
  DeleteFormDocumentProps,
  GetFormDocumentHashProps,
  GetFormDocumentHashReturnType,
} from './types'

const useWebFormApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const createFormDocument = async ({
    documentSetId,
    documentType,
  }: CreateFormDocumentProps): Promise<void> => {
    try {
      await FetcherWithInterceptors.post({
        url: {
          url: DependentUrls.CreateFormDocument,
          params: {
            documentSetId,
          },
        },
        data: {
          documentType,
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const deleteFormDocument = async ({ formId }: DeleteFormDocumentProps): Promise<void> => {
    try {
      await FetcherWithInterceptors.delete({
        url: {
          url: DependentUrls.DeleteFormDocument,
          params: {
            formId,
          },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getFormDocumentHashToSign = async ({ formId, actionId }: GetFormDocumentHashProps) => {
    try {
      return FetcherWithInterceptors.get<GetFormDocumentHashReturnType>({
        url: {
          url: DependentUrls.GetHashFormDocument,
          params: {
            formId,
          },
        },
        config: {
          params: {
            actionId,
          },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getFormCommitedFile = async (formId: string, documentType: string) => {
    try {
      const { data, headers } = await FetcherWithInterceptors.get({
        url: {
          url: DependentUrls.FormCommittedFile,
          params: {
            formId,
          },
        },
        config: {
          responseType: 'blob',
        },
        data: {
          documentType,
        },
      })

      return { data, headers }
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    getFormCommitedFile,
    createFormDocument,
    deleteFormDocument,
    getFormDocumentHashToSign,
  }
}

export { useWebFormApi }
