import React from 'react'

import Act from '@components/Act'
import PublicWrapper from '@components/PublicWrapper'

import styles from './Acts.module.scss'
import { listActs } from './mapping'

const ActsPage = () => {
  return (
    <div>
      <PublicWrapper
        wrapperClassName={styles.acts__wrapper}
        position="center"
        variant="default"
        title="Нормативные документы"
      >
        <div className={styles.acts}>
          {listActs.map(({ id, ...actProps }) => (
            <Act key={id} id={id} {...actProps} />
          ))}
        </div>
      </PublicWrapper>
    </div>
  )
}

export default ActsPage
