import React, { FC, memo } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/NpaLayout.module.scss'
import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import NpaForm from '@components/Forms/NpaForm'
import Typography from '@components/NewDesign/Typography'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const NpaFormLayout: FC<NpaFormLayoutProps> = (props) => {
  return (
    <NpaForm.Manager {...props}>
      <div className={styles.npaLayout}>
        <Stack direction={'vertical'} gap={2}>
          <Row className={'px-4'}>
            <Typography.Body variant={'bodyLMedium'} color={'text-base-secondary'}>
              Список НПА
            </Typography.Body>
          </Row>
          <NpaForm.Actions />
          <NpaForm.Statement />
        </Stack>
      </div>
    </NpaForm.Manager>
  )
}

export default memo(NpaFormLayout)
