import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import {
  ICompensationMonitoring,
  ICompensationMonitoringChecker,
  InformationOfExecutionConditionsSZPKFormValues,
  IStepsMonitoring,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { compact } from '@helpers/array/compact'
import { isNotEmptyString, isUndefined } from '@helpers/checkTypes'

const generateMenuListForInformationOfExecutionConditionsSZPKForm = (
  formValues:
    | InformationOfExecutionConditionsSZPKFormValues
    | UnpackNestedValue<InformationOfExecutionConditionsSZPKFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<InformationOfExecutionConditionsSZPKFormValues>,
): NestedMapOfMenu[] => {
  const getMenuSectionWithError = (numberSectionOfMenu: keyof NestedMenuHashMap) => {
    if (isUndefined(initialHashMap[numberSectionOfMenu])) return

    const preparedChildrenSections = initialHashMap[numberSectionOfMenu].children.map((item) => ({
      ...item,
      hasError: !!formErrors?.[item.id],
    }))

    return {
      ...initialHashMap[numberSectionOfMenu],
      hasError:
        !!formErrors?.[numberSectionOfMenu] ||
        preparedChildrenSections.some((item) => item.hasError),
      children: preparedChildrenSections,
    }
  }

  const getPreparedStepsMonitoringAnchorMenu = (
    step: IStepsMonitoring,
    indexOfStep: number,
  ): NestedMapOfMenu => {
    const stepError = formErrors?.['3']?.stepsMonitoring?.[indexOfStep]
    const stepId = `${initialHashMap['3'].path}.stepsMonitoring.${indexOfStep}`

    return {
      path: stepId,
      id: step.anchorId,
      title: isNotEmptyString(step.stepNumber)
        ? `Этап ${step.stepNumber}`
        : `Этап ${indexOfStep + 1}`,
      isCollapsed: false,
      hasError: !!stepError,
      parentBaseId: initialHashMap['3'].id,
      children: [],
    }
  }

  const preparedChildrenOfStepsMonitoring = formValues['3'].stepsMonitoring.map(
    getPreparedStepsMonitoringAnchorMenu,
  )

  const getPreparedCompensationsMonitoringAnchorMenu = (
    compensation: ICompensationMonitoring,
    indexOfStep: number,
  ): NestedMapOfMenu => {
    const stepError = formErrors?.['7']?.compensationsMonitoring?.[indexOfStep]
    const stepId = `${initialHashMap['7'].path}.compensationsMonitoring.${indexOfStep}`

    return {
      path: stepId,
      id: compensation.anchorId,
      title: isNotEmptyString(compensation.compensationYear) ? compensation.compensationYear : '',
      isCollapsed: false,
      hasError: !!stepError,
      parentBaseId: initialHashMap['7'].id,
      children: [],
    }
  }

  const getPreparedCompensationsMonitoringCheckerAnchorMenu = (
    compensation: ICompensationMonitoringChecker,
    indexOfStep: number,
  ): NestedMapOfMenu => {
    const stepError = formErrors?.['8']?.compensationsMonitoringChecker?.[indexOfStep]
    const stepId = `${initialHashMap['8'].path}.compensationsMonitoringChecker.${indexOfStep}`

    return {
      path: stepId,
      id: compensation.anchorId,
      title: isNotEmptyString(compensation.compensationYear) ? compensation.compensationYear : '',
      isCollapsed: false,
      hasError: !!stepError,
      parentBaseId: initialHashMap['8'].id,
      children: [],
    }
  }

  const preparedChildrenOfCompensationsMonitoring = formValues['7'].compensationsMonitoring.map(
    getPreparedCompensationsMonitoringAnchorMenu,
  )

  const preparedChildrenOfCompensationsMonitoringChecker = formValues[
    '8'
  ].compensationsMonitoringChecker.map(getPreparedCompensationsMonitoringCheckerAnchorMenu)

  const preparedHashMap: Record<string, NestedMapOfMenu | undefined> = {
    '1': getMenuSectionWithError('1'),
    '2': getMenuSectionWithError('2'),
    '3': {
      ...initialHashMap['3'],
      hasError: preparedChildrenOfStepsMonitoring.some(({ hasError }) => hasError),
      children: preparedChildrenOfStepsMonitoring,
    },
    '4': getMenuSectionWithError('4'),
    '5': getMenuSectionWithError('5'),
    '6': getMenuSectionWithError('6'),
    '7': {
      ...initialHashMap['7'],
      hasError: preparedChildrenOfCompensationsMonitoring.some(({ hasError }) => hasError),
      children: preparedChildrenOfCompensationsMonitoring,
    },
    '8': {
      ...initialHashMap['8'],
      hasError: preparedChildrenOfCompensationsMonitoringChecker.some(({ hasError }) => hasError),
      children: preparedChildrenOfCompensationsMonitoringChecker,
    },
    '9': getMenuSectionWithError('9'),
    '10': getMenuSectionWithError('10'),
  }

  return compact([
    preparedHashMap['1'],
    preparedHashMap['2'],
    preparedHashMap['3'],
    preparedHashMap['4'],
    preparedHashMap['5'],
    preparedHashMap['6'],
    preparedHashMap['7'],
    preparedHashMap['8'],
    preparedHashMap['9'],
    preparedHashMap['10'],
  ]) as NestedMapOfMenu[]
}

export { generateMenuListForInformationOfExecutionConditionsSZPKForm }
