import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { AAgreementForceCircumstancesStagesArrayPathName } from '@components/Forms/AAgreementForceCircumstancesForm/Forms/5/types'
import { fifthSectionAAgreementForceCircumstancesValidationMap } from '@components/Forms/AAgreementForceCircumstancesForm/Forms/5/validation'
import { useAAgreementForceCircumstancesManager } from '@components/Forms/AAgreementForceCircumstancesForm/Manager'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import { AAgreementForceCircumstancesFieldsControlUpdateWatcher } from '@components/Forms/AAgreementForceCircumstancesForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface AAgreementForceCircumstancesStageProps {
  formName: AAgreementForceCircumstancesStagesArrayPathName
}

const AAgreementForceCircumstancesStage: FC<AAgreementForceCircumstancesStageProps> = ({
  formName,
}) => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementForceCircumstancesManager()

  const {
    getSubscribableControlProps,
    getTextareaProps,
    getCalendarInputProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementForceCircumstancesFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container>
      <Stack direction={'vertical'} gap={3}>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Номер этапа'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.stepNumber`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.stepNumber`,
                inputProps: {
                  disabled: true,
                  integersOnly: true,
                  hideSuffix: true,
                  hasNegativeValue: false,
                },
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.stepDescription`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.stepDescription`,
                  textareaProps: {
                    disabled: true,
                    maxRows: Infinity,
                    label: 'Описание этапа',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title="Текущая дата реализации этапа"
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <ControlledCalendarInput
            {...getCalendarInputProps({
              name: `${formName}.stepEndDateCommited`,
              calendarInputProps: {
                disabled: true,
              },
            })}
          />
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title="Предельная дата реализации этапа"
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.stepEndDate`,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: `${formName}.stepEndDate`,
                rules: fifthSectionAAgreementForceCircumstancesValidationMap.stepEndDate,
                onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.stepEndDate`), 0),
                onCalendarChange: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.stepEndDate`), 0),
                onInputChange: () =>
                  setTimeout(() => debouncedHandleChangeValue?.(`${formName}.stepEndDate`), 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </Container>
  )
}

export default AAgreementForceCircumstancesStage
