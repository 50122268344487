import React, { DetailedHTMLProps, FC, HTMLAttributes, useCallback } from 'react'

import Formation from '@components/Forms/CreateStatementOld/Formation'
import styles from '@components/Forms/CreateStatementOld/Layout/Layout.module.scss'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import CustomTransition from '@components/Transition'
import { useHover } from '@hooks/useHover'
import backArrowIcon from '@icons/BackArrow.svg'

type LayoutWrapperProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const LayoutWrapper: FC<LayoutWrapperProps> = ({ children, ...rest }) => {
  const {
    state: { dfoId, modalState, lastUpdateDraftTime },
    handlers: { handleChangeModalState, withCheckChangesBeforeApply },
    actions: { handleStatementClose },
  } = useCreateStatementManager()

  const [hoverRef, isHovered] = useHover<HTMLButtonElement>()

  const handleClose = useCallback(() => {
    if (modalState.isLoading) {
      // Обновить сет документов, когда контрол нажатия будет иметь состояние
    }

    handleChangeModalState?.({
      title: '',
      formationShow: false,
      isLoading: true,
    })

    handleStatementClose?.({ saveDraft: true })
  }, [handleChangeModalState, handleStatementClose, modalState.isLoading])

  return (
    <div {...rest}>
      <div className={styles.layout__close}>
        <IconButton
          accent
          ref={hoverRef}
          view={'basic'}
          geometry={'round'}
          size={'xl'}
          type={'button'}
          dataTestId="LayoutWrapper-close-icon-button"
          icon={{
            src: backArrowIcon,
            className: styles['layout__close-icon'],
          }}
          onClick={() => {
            withCheckChangesBeforeApply?.({
              submitCallback: handleClose,
              rejectCallback: () => handleStatementClose?.({ saveDraft: false }) || (() => {}),
            })
          }}
        />
        <CustomTransition.Fade show={isHovered && !!lastUpdateDraftTime}>
          <Typography.Caption
            className={styles['layout__close-text']}
            color={'on-surface-secondary'}
            variant={'captionSRegular'}
          >
            Изменения сохранены
          </Typography.Caption>
        </CustomTransition.Fade>
      </div>
      {children}
      {dfoId && modalState.formationShow && (
        <Formation
          title={modalState.title}
          isLoading={modalState.isLoading}
          handleClose={handleClose}
          dfoId={dfoId}
        />
      )}
    </div>
  )
}

export default LayoutWrapper
