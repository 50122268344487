import { FC } from 'react'
import { Components } from 'react-markdown'
import { Link } from 'react-router-dom'

import { isString } from '@helpers/checkTypes'
import WithImageLoading from '@helpers/img/WithImageLoading'
import { isValidUrl } from '@helpers/url/isValidURL'
import cn from 'classnames'

import styles from './NodeFactory.module.scss'

interface MarkdownNodeFactory {
  tagName: keyof Components
  anotherElementProps: any
}

const MarkdownNodeFactory: FC<MarkdownNodeFactory> = ({
  tagName,
  children,
  anotherElementProps,
}) => {
  return (
    <>
      {(() => {
        switch (tagName) {
          case 'h1': {
            return (
              <h1 {...anotherElementProps} className={styles.h1}>
                {children}
              </h1>
            )
          }
          case 'h2': {
            return (
              <h2 {...anotherElementProps} className={styles.h2}>
                {children}
              </h2>
            )
          }
          case 'h3': {
            return (
              <h3 {...anotherElementProps} className={styles.h3}>
                {children}
              </h3>
            )
          }
          case 'h4': {
            return (
              <h4 {...anotherElementProps} className={styles.h4}>
                {children}
              </h4>
            )
          }
          case 'h5': {
            return (
              <h5 {...anotherElementProps} className={styles.h5}>
                {children}
              </h5>
            )
          }
          case 'h6': {
            return (
              <h6 {...anotherElementProps} className={styles.h6}>
                {children}
              </h6>
            )
          }

          case 'a': {
            const { href } = anotherElementProps
            const preparedlinkProps = {
              ...anotherElementProps,
              className: styles.link,
              target: '_blank',
              rel: 'noopener noreferrer',
            }

            if (!isString(href)) return null

            if (isValidUrl(href)) {
              return <a {...preparedlinkProps}>{children}</a>
            }

            if (href.startsWith('~')) {
              const preparedHref = href.replace('~', window.location.origin)

              return (
                <a {...preparedlinkProps} href={preparedHref}>
                  {children}
                </a>
              )
            }

            return <Link to={`/knowledgeBase?pageViewId=${href}`}>{children}</Link>
          }

          case 'blockquote': {
            return (
              <blockquote {...anotherElementProps} className={styles.blockquote}>
                {children}
              </blockquote>
            )
          }

          case 'br': {
            return (
              <br {...anotherElementProps} className={styles.br}>
                {children}
              </br>
            )
          }

          case 'hr': {
            return (
              <hr {...anotherElementProps} className={styles.hr}>
                {children}
              </hr>
            )
          }
          case 'em': {
            return (
              <em {...anotherElementProps} className={styles.em}>
                {children}
              </em>
            )
          }

          case 'code': {
            return (
              <code {...anotherElementProps} className={styles.code}>
                {children}
              </code>
            )
          }

          case 'img': {
            return (
              <WithImageLoading>
                <img
                  {...anotherElementProps}
                  alt={anotherElementProps.alt}
                  className={styles.image}
                />
              </WithImageLoading>
            )
          }

          case 'ul': {
            let haveInputChild
            const currentNode = anotherElementProps.node as Element

            for (const element of currentNode.children) {
              if (element.children) {
                for (const childElement of element.children) {
                  if (childElement.tagName === 'input') {
                    haveInputChild = true
                  }
                }
              }
            }

            return (
              <ul
                {...anotherElementProps}
                className={cn(styles.ul, { [styles['ul--disableList']]: haveInputChild })}
              >
                {children}
              </ul>
            )
          }
          case 'ol': {
            return (
              <ol {...anotherElementProps} className={styles.ol}>
                {children}
              </ol>
            )
          }
          case 'li': {
            return (
              <li {...anotherElementProps} className={styles.li}>
                <span>{children}</span>
              </li>
            )
          }

          case 'p': {
            return (
              <p {...anotherElementProps} className={styles.p}>
                {children}
              </p>
            )
          }
          case 'pre': {
            return (
              <pre {...anotherElementProps} className={styles.pre}>
                {children}
              </pre>
            )
          }
          case 'strong': {
            return (
              <strong {...anotherElementProps} className={styles.strong}>
                {children}
              </strong>
            )
          }

          case 'del': {
            return (
              <del {...anotherElementProps} className={styles.del}>
                {children}
              </del>
            )
          }

          case 'input': {
            return <input {...anotherElementProps} className={styles.input} disabled={false} />
          }

          case 'table': {
            return (
              <table {...anotherElementProps} className={styles.table}>
                {children}
              </table>
            )
          }
          case 'tbody': {
            return <tbody {...anotherElementProps}>{children}</tbody>
          }
          case 'thead': {
            return <thead {...anotherElementProps}>{children}</thead>
          }
          case 'td': {
            return <td {...anotherElementProps}>{children}</td>
          }
          case 'th': {
            return <th {...anotherElementProps}>{children}</th>
          }
          case 'tr': {
            return <tr {...anotherElementProps}>{children}</tr>
          }

          default:
            return null
        }
      })()}
    </>
  )
}

export default MarkdownNodeFactory
