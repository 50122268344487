import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AgreementStabilizationNegotiationsLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import AgreementStabilizationNegotiationsModalManager from './Manager'
import AgreementStabilizationNegotiationsMenu from './Menu'
import AgreementStabilizationNegotiationsStatementForm from './Statement'

interface ICreateStatement extends FC {
  LayoutWithFormProvider: typeof AgreementStabilizationNegotiationsLayoutWithFormProvider
  Menu: typeof AgreementStabilizationNegotiationsMenu
  StatementForm: typeof AgreementStabilizationNegotiationsStatementForm
  Manager: typeof AgreementStabilizationNegotiationsModalManager
}

const useAgreementStabilizationNegotiationsControl = () => {
  const popupManager = usePopupManager()

  const openAgreementStabilizationNegotiationsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        AgreementStabilizationNegotiationsLayoutWithFormProvider,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openAgreementStabilizationNegotiationsModal }
}

const AgreementStabilizationNegotiations: ICreateStatement = () => null

AgreementStabilizationNegotiations.Menu = AgreementStabilizationNegotiationsMenu
AgreementStabilizationNegotiations.LayoutWithFormProvider =
  AgreementStabilizationNegotiationsLayoutWithFormProvider 
AgreementStabilizationNegotiations.StatementForm = AgreementStabilizationNegotiationsStatementForm
AgreementStabilizationNegotiations.Manager = AgreementStabilizationNegotiationsModalManager

export { useAgreementStabilizationNegotiationsControl }
export default AgreementStabilizationNegotiations
