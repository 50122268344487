import type { FolderFileItem } from '@components/Attachments/Attachment/FolderItem/types'
import type { CombinedMultipleItem } from '@components/Attachments/Attachment/MultipleItem/types'
import type { TMultipleItem } from '@components/Attachments/types'
import type { IAttachmentFileItem, IAttachmentFolderItem } from '@interfaces/documents'

import type { GetAttachmentFileItemReturnType } from './types'

class AttachmentMultipleItemService {
  static isAttachmentMultipleItem(item: TMultipleItem): item is IAttachmentFileItem {
    return 'file' in item
  }

  static findMultipleItemIndexById(items: TMultipleItem[], id?: string) {
    return items.findIndex((item) => item.id === id)
  }

  static findFileItemIndexInDocumentsById(documents: IAttachmentFileItem[], id?: string) {
    return documents.findIndex((document) => document.id === id)
  }

  static findFolderItemById(items: TMultipleItem[], id?: string) {
    if (!id) return

    return items.find(
      (item) => !AttachmentMultipleItemService.isAttachmentMultipleItem(item) && item.id === id,
    ) as IAttachmentFolderItem | undefined
  }

  static getFolderItemWithNewFile(
    attachmentFileItem: IAttachmentFileItem,
    folder?: IAttachmentFolderItem,
  ): IAttachmentFolderItem | undefined {
    if (!folder) return

    return {
      ...folder,
      documents: [...folder.documents, attachmentFileItem],
    }
  }

  static getFolderItemWithUpdatedFile(
    attachmentFileItem: IAttachmentFileItem,
    folder?: IAttachmentFolderItem,
  ): IAttachmentFolderItem | undefined {
    if (!folder) return

    return {
      ...folder,
      documents: folder.documents.map((document) =>
        document.id !== attachmentFileItem.id ? document : attachmentFileItem,
      ),
    }
  }

  static getFolderItemWithoutRemovedFile(
    id: string,
    folder?: IAttachmentFolderItem,
  ): IAttachmentFolderItem | undefined {
    if (!folder) return

    return {
      ...folder,
      documents: folder.documents.filter((document) => document.id !== id),
    }
  }

  static getAttachmentFileItem(
    items: TMultipleItem[],
    item: CombinedMultipleItem,
    folderFileItem?: FolderFileItem,
  ): GetAttachmentFileItemReturnType {
    const folder = folderFileItem
      ? AttachmentMultipleItemService.findFolderItemById(items, item.id)
      : undefined
    const attachmentFileIndex = folder
      ? AttachmentMultipleItemService.findFileItemIndexInDocumentsById(
          folder.documents,
          folderFileItem?.id,
        )
      : AttachmentMultipleItemService.findMultipleItemIndexById(items, item.id)

    const attachmentFileItem = (
      folder ? folder?.documents[attachmentFileIndex] : items[attachmentFileIndex]
    ) as IAttachmentFileItem | undefined

    return {
      attachmentFileItem,
      attachmentFileIndex,
      folder,
    }
  }
}

export { AttachmentMultipleItemService }
