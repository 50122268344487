import React, { memo, useEffect } from 'react'
import { Outlet } from 'react-router'

import withAuthProtect from '@hoc/withAuthProtect'
import withRolesProtect from '@hoc/withRolesProtect'
import { useQueryManager } from '@hooks/useQueryManager'
import compose from 'lodash/fp/compose'

const InitializeAuthenticatedGlobalState = () => {
  const { queryUtils } = useQueryManager()

  const removeQueryAfterReloadPage = () => {
    const debugMode = queryUtils.getQuery('debugMode')

    if (debugMode) {
      queryUtils.removeQuery({
        query: 'debugMode',
      })
    }
  }

  useEffect(() => {
    removeQueryAfterReloadPage()
  }, [])

  return <Outlet />
}

export default memo(compose(withAuthProtect, withRolesProtect)(InitializeAuthenticatedGlobalState))
