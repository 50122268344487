import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import AAVolumeOfCapitalInvestmentsModalMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AAVolumeOfCapitalInvestmentsLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import AAVolumeOfCapitalInvestmentsModalManager from './Manager'
import AAVolumeOfCapitalInvestmentsMenu from './Menu'
import AAVolumeOfCapitalInvestmentsStatementForm from './Statement'

interface IAAVolumeOfCapitalInvestments extends FC {
  LayoutWithFormProvider: typeof AAVolumeOfCapitalInvestmentsLayoutWithFormProvider
  Menu: typeof AAVolumeOfCapitalInvestmentsMenu
  StatementForm: typeof AAVolumeOfCapitalInvestmentsStatementForm
  Manager: typeof AAVolumeOfCapitalInvestmentsModalManager
  MenuManager: typeof AAVolumeOfCapitalInvestmentsModalMenuManager
}

const useAAVolumeOfCapitalInvestmentsControl = () => {
  const popupManager = usePopupManager()

  const openAAVolumeOfCapitalInvestmentsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AAVolumeOfCapitalInvestmentsLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAAVolumeOfCapitalInvestmentsModal }
}

const AAVolumeOfCapitalInvestments: IAAVolumeOfCapitalInvestments = () => null

AAVolumeOfCapitalInvestments.Menu = AAVolumeOfCapitalInvestmentsMenu
AAVolumeOfCapitalInvestments.LayoutWithFormProvider = AAVolumeOfCapitalInvestmentsLayoutWithFormProvider
AAVolumeOfCapitalInvestments.StatementForm = AAVolumeOfCapitalInvestmentsStatementForm
AAVolumeOfCapitalInvestments.Manager = AAVolumeOfCapitalInvestmentsModalManager
AAVolumeOfCapitalInvestments.MenuManager = AAVolumeOfCapitalInvestmentsModalMenuManager

export { useAAVolumeOfCapitalInvestmentsControl }
export default AAVolumeOfCapitalInvestments
