import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { TGetProjectActions } from '@services/Projects/Project.entity'
import useSWRImmutable from 'swr/immutable'

const useProjectActions = ({ key, config }: SWRHookProps<TGetProjectActions | undefined>) => {
  const {
    projectsApi: { getProjectActions },
  } = useAPIContext()

  const {
    data: projectActions,
    error,
    mutate,
    isValidating: isLoadingProjectActions,
  } = useSWRImmutable(key, ({ projectId }) => getProjectActions(projectId), config)

  return {
    projectActions,
    error,
    mutate,
    isLoadingProjectActions,
  }
}

export { useProjectActions }
