import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const { getPropertyPropsFromPropertyState } = PropertiesPropsBasicAdapters

const generatePropertiesObjectForStatementByHeadOfMunicipalityForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getNewMunicipalityAdapter(objectFromPropertyState),
      municipalityFullNameGenitive: getPropertyPropsFromPropertyState(
        objectFromPropertyState?.newMunicipalityFullNameGenitive,
      ),
    },
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '4': {
      projectName: {
        propertyId: objectFromPropertyState.projectName.projectId,
        type: objectFromPropertyState.projectName.type,
        lastUpdateDt: objectFromPropertyState.projectName.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForStatementByHeadOfMunicipalityForm }
