import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AAgreementIncludeRelatedTermsFormValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/types'

const { getDefaultInvestorFieldState, getDefaultRfFieldState, getDefaultSubjectsFieldState } =
  FieldStateHelpers

const mapOfAAgreementIncludeRelatedTermsMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Реквизиты связанного договора' },
]

const defaultAAgreementIncludeRelatedTermsFormValues: AAgreementIncludeRelatedTermsFormValues = {
  '1': getDefaultRfFieldState(),
  '2': {
    subjects: getDefaultSubjectsFieldState(),
  },
  '3': getDefaultInvestorFieldState(),
  '4': {
    relatedContractNumber: '',
    relatedContractDate: '',
    relatedContractTerms: '',
    relatedContractPublicLawMember: null,
    relatedContractPublicLawMemberInstrumental: '',
    agreementLinkDamageCompensations: '',
    isSubsidyInvestmentViolation: false,
    isSubsidyCreditViolation: false,
    isLongTermParametersChanging: false,
  },
  additionalFields: {
    isFederal: true,
  },
}

const aAgreementIncludeRelatedTermsBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
  },
  '4': {
    relatedContractNumber: '4.relatedContractNumber',
    relatedContractDate: '4.relatedContractDate',
    relatedContractTerms: '4.relatedContractTerms',
    relatedContractPublicLawMember: '4.relatedContractPublicLawMember',
    relatedContractPublicLawMemberInstrumental: '4.relatedContractPublicLawMemberInstrumental',
    agreementLinkDamageCompensations: '4.agreementLinkDamageCompensations',
    isSubsidyInvestmentViolation: '4.isSubsidyInvestmentViolation',
    isSubsidyCreditViolation: '4.isSubsidyCreditViolation',
    isLongTermParametersChanging: '4.isLongTermParametersChanging',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
  },
} as const

export {
  aAgreementIncludeRelatedTermsBlockValues,
  defaultAAgreementIncludeRelatedTermsFormValues,
  mapOfAAgreementIncludeRelatedTermsMenu,
}
