import { FC } from 'react'

import One from './1'
import Two from './2'
import Three from './3'
import Four from './4'

interface InfrFacilitiesCostsExpectedReimbursedFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Three: typeof Three
  Four: typeof Four
}

const InfrFacilitiesCostsExpectedReimbursedForms: InfrFacilitiesCostsExpectedReimbursedFormsProps =
  () => null

InfrFacilitiesCostsExpectedReimbursedForms.One = One
InfrFacilitiesCostsExpectedReimbursedForms.Two = Two
InfrFacilitiesCostsExpectedReimbursedForms.Three = Three
InfrFacilitiesCostsExpectedReimbursedForms.Four = Four

export default InfrFacilitiesCostsExpectedReimbursedForms
