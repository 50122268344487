import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'
import { compact } from '@helpers/array/compact'
import { isArray, isNumber } from '@helpers/checkTypes'
import { convertStringToDateFormat } from '@helpers/date/convertStringToDateFormat'

import { defaultIndicatorsOfUnfulfilledAgreementReportFormValues } from '../const'

const { formatBackendMoneyToString } = DocumentFormHelpers

const generateRHFObjectForIndicatorsOfUnfulfilledAgreementReportForm = (
  objectFromPropertyState: Record<string, any>,
): IndicatorsOfUnfulfilledAgreementReportFormValues => {
  const stepsIndicatorsValue = objectFromPropertyState.stepsIndicators?.value || []
  const stepsMonitoringValue = objectFromPropertyState.stepsMonitoring?.value || []
  const isStepsMonitoringArrayOrIndicatorsArrayInvalid =
    !isArray(stepsIndicatorsValue) ||
    !isArray(stepsMonitoringValue) ||
    !stepsMonitoringValue.length ||
    !stepsIndicatorsValue.length

  const findStepIndicatorsItemByStepMonitoringItem = (stepMonitoringItem) =>
    isArray(stepsIndicatorsValue) &&
    stepsIndicatorsValue.find(
      (stepIndicatorsItem) =>
        stepIndicatorsItem?.value?.planStepId?.permanentId ===
        stepMonitoringItem?.value?.planStepId?.permanentId,
    )

  const getPreparedStepsDeadline = () => {
    if (isStepsMonitoringArrayOrIndicatorsArrayInvalid)
      return defaultIndicatorsOfUnfulfilledAgreementReportFormValues['4'].stepsDeadline

    return compact(
      stepsMonitoringValue.map((stepMonitoringItem) => {
        const currentStepIndicatorsItem =
          findStepIndicatorsItemByStepMonitoringItem(stepMonitoringItem)

        if (!currentStepIndicatorsItem) return null

        return {
          stepNumber: currentStepIndicatorsItem?.value?.planStepId?.value?.stepNumber || '',
          stepDescription:
            currentStepIndicatorsItem?.value?.planStepId?.value?.stepDescription || '',
          stepStartDate: convertStringToDateFormat(
            currentStepIndicatorsItem?.value?.planStepId?.value?.stepStartDate,
          ),
          stepEndDate: convertStringToDateFormat(
            currentStepIndicatorsItem?.value?.planStepId?.value?.stepEndDate,
          ),
          factStepStartDate: stepMonitoringItem?.value?.factStepStartDate?.value || '',
          factStepEndDate: stepMonitoringItem?.value?.factStepEndDate?.value || '',
        }
      }),
    )
  }

  const getPreparedStepsCompensation = () => {
    if (isStepsMonitoringArrayOrIndicatorsArrayInvalid)
      return defaultIndicatorsOfUnfulfilledAgreementReportFormValues['5'].stepsCompensation

    return compact(
      stepsMonitoringValue.map((stepMonitoringItem) => {
        const currentStepIndicatorsItem =
          findStepIndicatorsItemByStepMonitoringItem(stepMonitoringItem)

        if (!currentStepIndicatorsItem) return null

        const stepIndicatorsValue = currentStepIndicatorsItem?.value
        const stepMonitoringValue = stepMonitoringItem?.value

        const stepIndicatorItemPlanStepIdValue = stepIndicatorsValue?.planStepId?.value

        const stepCompensationDatesInfo =
          stepIndicatorsValue?.stepIndicatorCompensations?.value?.map(
            (stepIndicatorCompensation) => ({
              stepCompensationName:
                stepIndicatorCompensation?.value?.stepIndicatorCompensationType?.value?.name || '',
              stepCompensationEndDate: stepIndicatorCompensation?.value
                ?.stepIndicatorCompensationEndDate?.value
                ? `до ${stepIndicatorCompensation.value.stepIndicatorCompensationEndDate.value}`
                : '',
            }),
          )

        return {
          stepCompensationNumber: stepIndicatorItemPlanStepIdValue?.stepNumber || '',
          stepCompensationDescription: stepIndicatorItemPlanStepIdValue?.stepDescription || '',
          stepCompensationValue: isNumber(stepIndicatorItemPlanStepIdValue?.stepCompensationValue)
            ? `${formatBackendMoneyToString(
                stepIndicatorItemPlanStepIdValue.stepCompensationValue,
              )} ₽`
            : '',
          factStepCompensationValue: stepMonitoringValue?.factStepCompensationValue?.value
            ? `${stepMonitoringValue.factStepCompensationValue.value} ₽`
            : '',
          stepCompensationDatesInfo,
        }
      }),
    )
  }

  return {
    '1': {
      investorCapitalMadeOnTimeIndicator:
        objectFromPropertyState.investorCapitalMadeOnTimeIndicator?.value ||
        defaultIndicatorsOfUnfulfilledAgreementReportFormValues['1']
          .investorCapitalMadeOnTimeIndicator,
      investorCapital: objectFromPropertyState.investorCapital?.value
        ? `${objectFromPropertyState.investorCapital.value} ₽`
        : defaultIndicatorsOfUnfulfilledAgreementReportFormValues['1'].investorCapital,
      investorCapitalVAT: objectFromPropertyState.investorCapitalVAT?.value
        ? `${objectFromPropertyState.investorCapitalVAT.value} ₽`
        : defaultIndicatorsOfUnfulfilledAgreementReportFormValues['1'].investorCapitalVAT,
      factInvestorCapitalVatIndicator: objectFromPropertyState.factInvestorCapitalVatIndicator
        ?.value
        ? `${objectFromPropertyState.factInvestorCapitalVatIndicator.value} ₽`
        : defaultIndicatorsOfUnfulfilledAgreementReportFormValues['1']
            .factInvestorCapitalVatIndicator,
      factInvestorCapitalIndicator: objectFromPropertyState.factInvestorCapitalIndicator?.value
        ? `${objectFromPropertyState.factInvestorCapitalIndicator.value} ₽`
        : defaultIndicatorsOfUnfulfilledAgreementReportFormValues['1'].factInvestorCapitalIndicator,
      madeInvestorCapitalDate:
        objectFromPropertyState.madeInvestorCapitalDate?.value ||
        defaultIndicatorsOfUnfulfilledAgreementReportFormValues['1'].madeInvestorCapitalDate,
      monitoringReportDate:
        objectFromPropertyState.monitoringReportDate?.value ||
        defaultIndicatorsOfUnfulfilledAgreementReportFormValues['1'].monitoringReportDate,
    },
    '2': {
      totalCapital: objectFromPropertyState.totalCapital?.value
        ? `${objectFromPropertyState.totalCapital.value} ₽`
        : defaultIndicatorsOfUnfulfilledAgreementReportFormValues['2'].totalCapital,
      factTotalCapitalIndicator: objectFromPropertyState.factTotalCapitalIndicator?.value
        ? `${objectFromPropertyState.factTotalCapitalIndicator.value} ₽`
        : defaultIndicatorsOfUnfulfilledAgreementReportFormValues['2'].factTotalCapitalIndicator,
    },
    '3': {
      newWorkPlaces:
        objectFromPropertyState.newWorkPlaces?.value ||
        defaultIndicatorsOfUnfulfilledAgreementReportFormValues['3'].newWorkPlaces,
      factNewWorkPlace:
        objectFromPropertyState.factNewWorkPlace?.value ||
        defaultIndicatorsOfUnfulfilledAgreementReportFormValues['3'].factNewWorkPlace,
    },
    '4': {
      stepsDeadline: getPreparedStepsDeadline(),
    },
    '5': {
      stepsCompensation: getPreparedStepsCompensation(),
    },
  } as IndicatorsOfUnfulfilledAgreementReportFormValues
}

export { generateRHFObjectForIndicatorsOfUnfulfilledAgreementReportForm }
