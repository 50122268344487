import { forwardRef, memo } from 'react'

import Button from '@components/NewDesign/Button'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import { useItemAuthorizedOrganizationsOMSUSidebar } from '@components/Sidebars/AuthorizedOrganizations/OMSU'
import { IAuthorizedOrganizationItem } from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { Transition } from '@headlessui/react'
import { useHover } from '@hooks/new/events/useHover'
import IconOpen from '@icons/action/open_in_new.svg'
import cn from 'classnames'

import styles from './OMSUItem.module.scss'

interface AuthorizedOrganizationItemProps {
  authorizedOrganization: IAuthorizedOrganizationItem
  onReloadGrid?: VoidFunction
  className?: string
}

const MAX_LENGTH_ORGANIZATION_NAME = 105
const MAX_LENGTH_MUNICIPALITY_NAME = 80

const OMSUItem = forwardRef<HTMLDivElement, AuthorizedOrganizationItemProps>(
  ({ authorizedOrganization, className, onReloadGrid }) => {
    const { inn, organizationName, link, municipalityName, oktmo } = authorizedOrganization

    const organizationTooltip = useManualTooltipControl()
    const municipalityTooltip = useManualTooltipControl()

    const { handleOpenOMSUItemAuthorizedOrganizationsSidebar } =
      useItemAuthorizedOrganizationsOMSUSidebar()

    const [hoverRef, isHovered] = useHover<HTMLDivElement>()

    const handleOpenChangeAuthorizedOrganizationsSidebar = () => {
      handleOpenOMSUItemAuthorizedOrganizationsSidebar({
        organization: authorizedOrganization,
        onReloadGrid,
      })
    }

    return (
      <div className={cn(styles.container, className)} ref={hoverRef}>
        <button type="button" onClick={handleOpenChangeAuthorizedOrganizationsSidebar}>
          <div className={styles.inn}>
            <div className={styles.wrapper__tooltip}>
              <Tooltip
                availableHeight
                content={organizationName}
                popoverClassName={styles.tooltip__content}
                open={
                  organizationName?.length > MAX_LENGTH_ORGANIZATION_NAME &&
                  organizationTooltip.state.tooltipIsOpen
                }
                onOpen={organizationTooltip.handlers.handleOpenTooltip}
                onClose={organizationTooltip.handlers.handleCloseTooltip}
              >
                <Typography.Body
                  color={'text-base-primary'}
                  variant="bodyMRegular"
                  className={styles.name}
                >
                  {organizationName}
                </Typography.Body>
              </Tooltip>
            </div>
            <div>
              <Typography.Body color={'text-base-secondary'} variant="bodyMRegular">
                ИНН: {inn}
              </Typography.Body>
            </div>
          </div>
          <div className={styles.oktmo}>
            <div className={styles.wrapper__tooltip}>
              <Tooltip
                availableHeight
                content={municipalityName}
                popoverClassName={styles.tooltip__content}
                open={
                  (municipalityName?.length ?? '') > MAX_LENGTH_MUNICIPALITY_NAME &&
                  municipalityTooltip.state.tooltipIsOpen
                }
                onOpen={municipalityTooltip.handlers.handleOpenTooltip}
                onClose={municipalityTooltip.handlers.handleCloseTooltip}
              >
                <Typography.Body
                  color={'text-base-primary'}
                  variant="bodyMRegular"
                  className={styles.name}
                >
                  {municipalityName}
                </Typography.Body>
              </Tooltip>
            </div>
            <div>
              <Typography.Body color={'text-base-secondary'} variant="bodyMRegular">
                ОКТМО: {oktmo}
              </Typography.Body>
            </div>
          </div>
        </button>
        {link && (
          <div className={styles.link}>
            <Transition
              show={isHovered}
              enter={styles.transition__enter}
              enterFrom={styles.transition__enterFrom}
              enterTo={styles.transition__enterTo}
              leave={styles.transition__leave}
              leaveFrom={styles.transition__leaveFrom}
              leaveTo={styles.transition__leaveTo}
            >
              <div className={styles.link__wrapper}>
                <Button
                  geometry={'square'}
                  size={'s'}
                  view={'outline'}
                  trailingIcon={{ src: IconOpen, className: styles.buttonLink__icon }}
                  variant={'buttonSMedium'}
                  href={link}
                  target={'_blank'}
                >
                  Перейти на сайт
                </Button>
              </div>
            </Transition>
          </div>
        )}
      </div>
    )
  },
)
export default memo(OMSUItem)
