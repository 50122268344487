import { useLocation } from 'react-router'

import { Paths } from '@constants/paths'

export const usePaths = () => {
  const { pathname } = useLocation()

  return {
    isHome: pathname === Paths.Home,
    isLogin: pathname === Paths.Login,
    isUsers: pathname === Paths.Users,
    isAudits: pathname === Paths.Audits,
    isSupport: pathname === Paths.Support,
    isProjects: pathname === Paths.Projects,
    isSettings: pathname === Paths.Settings,
    isAgreements: pathname === Paths.Agreements,
    isRegistryNPA: pathname === Paths.RegistryNpa,
    isSchemeSZPK: pathname === Paths.SchemeSZPK,
    isOrganizations: pathname === Paths.Organizations,
    isTechnicalWork: pathname === Paths.TechnicalWork,
    isRating: pathname === Paths.Rating,
    isAuthorizedList: pathname === Paths.AuthorizedList,
    isActs: pathname === Paths.Acts,
    isPolitics: pathname === Paths.Politics,
  }
}
