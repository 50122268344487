import { FC } from 'react'

import One from './1'
import Two from './2'
import Three from './3'
import Four from './4'
import Five from './5'
import Six from './6'
import Seven from './7'

interface AAChangingVolumeReimbursementFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Three: typeof Three
  Four: typeof Four
  Five: typeof Five
  Six: typeof Six
  Seven: typeof Seven
}

const AAChangingVolumeReimbursementForms: AAChangingVolumeReimbursementFormsProps = () => null

AAChangingVolumeReimbursementForms.One = One
AAChangingVolumeReimbursementForms.Two = Two
AAChangingVolumeReimbursementForms.Three = Three
AAChangingVolumeReimbursementForms.Four = Four
AAChangingVolumeReimbursementForms.Five = Five
AAChangingVolumeReimbursementForms.Six = Six
AAChangingVolumeReimbursementForms.Seven = Seven

export default AAChangingVolumeReimbursementForms
