import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { TGetProjectMembers } from '@services/Members/Members.entity'
import useSWRImmutable from 'swr/immutable'

const useProjectMembers = ({ key, config }: SWRHookProps<TGetProjectMembers | undefined>) => {
  const {
    projectsApi: { getProjectMembersById },
  } = useAPIContext()

  const {
    data: projectMembers,
    error,
    mutate,
    isValidating: isLoadingProjectMembers,
  } = useSWRImmutable(key, ({ projectId }) => getProjectMembersById(projectId), config)

  return {
    projectMembers,
    error,
    mutate,
    isLoadingProjectMembers,
  }
}

export { useProjectMembers }
