import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { RegistrationCertificateFormValues } from '@components/Forms/RegistrationCertificateForm/types'
import { defaultRHFValidation } from '@constants/validations'

const secondSectionRegistrationCertificateValidationMap: FormValuesValidationSection<
  keyof RegistrationCertificateFormValues['2'],
  RegistrationCertificateFormValues
> = {
  initiatorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { secondSectionRegistrationCertificateValidationMap }
