import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionAgreementStabilizationNegotiationsValidationMap: FormValuesValidationSection<
  keyof AgreementStabilizationNegotiationsFormValues['4'],
  AgreementStabilizationNegotiationsFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionAgreementStabilizationNegotiationsValidationMap }
