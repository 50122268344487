import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import StateSupportItem from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/9/AdditionalForms/StateSupportItem'
import { StateSupportsPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/9/types'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { isFormFieldError } = DocumentFormHelpers

const Ninth = () => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleRemoveItemFromList, handleAddItemToListWithOutValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  const { fields: supports } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    StateSupportsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: informationOfExecutionConditionsSZPKBlockValues['9'].stateSupports,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const supportsError = formInstance.getFieldState(
    informationOfExecutionConditionsSZPKBlockValues['9'].stateSupports,
  )?.error

  const handleAddExpense = async () => {
    await handleAddItemToListWithOutValue?.(
      informationOfExecutionConditionsSZPKBlockValues['9'].stateSupports,
    )

    if (!supports.length) {
      formInstance.clearErrors(informationOfExecutionConditionsSZPKBlockValues['9'].stateSupports)
    }
  }
  const handleRemoveExpense = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${informationOfExecutionConditionsSZPKBlockValues['9'].stateSupports}.${index}`,
      informationOfExecutionConditionsSZPKBlockValues['9'].stateSupports,
    )
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['9'].stateSupports,
                })}
              >
                <FlipperList list={supports}>
                  {supports.map((support, index) => {
                    const formName =
                      `${informationOfExecutionConditionsSZPKBlockValues['9'].stateSupports}.${index}` as const
                    return (
                      <StateSupportItem
                        supportItemId={support.id}
                        key={support.id}
                        blockViewIsValidating={blockViewIsValidating}
                        editMode={editMode}
                        formInstance={formInstance}
                        indexOfSupport={index}
                        formName={formName}
                        onDeleteSupport={handleRemoveExpense(index)}
                      />
                    )
                  })}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </Col>
          {isFormFieldError(supportsError) && !supports.length && (
            <Row className={'px-1'}>
              <FormError rowClassName={'px-4'} colClassName={'px-3'}>
                {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
              </FormError>
            </Row>
          )}
        </Row>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddExpense}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    dataTestId="StepProjectArrayForm-add-button"
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить меру поддержки
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <Border />
          </Col>
        </Row>
        <Row className={'px-3'}>
          <Col xs={12}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              title={'Совокупный объем предоставленных мер гос. поддержки'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['9']
                    .generalStateSupportValue,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['9']
                      .generalStateSupportValue,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Ninth
