import { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import { getAllDfoDocumentsInArray } from '@helpers/dfoDocuments/getAllDfoDocumentsInArray'
import { deepEqual } from '@helpers/equals'
import { IModifiedDocumentSetInfoForRender } from '@services/Documents/documents.entity'
import cloneDeep from 'clone-deep'

import type {
  FileContextProps,
  FileProviderProps,
  HandleAddFileToSetProps,
  HandleAddFolderToSetProps,
  HandleRemoveFileFromSetProps,
  HandleRemoveFolderFromSetProps,
  HandleUpdateFileFromSetProps,
} from './types'

const FileContext = createContext<FileContextProps>({} as FileContextProps)

const FileProvider: FC<FileProviderProps> = ({ children, initialDocuments }) => {
  const documents = useRef<IModifiedDocumentSetInfoForRender>()

  useEffect(() => {
    const currentDocuments = initialDocuments

    if (currentDocuments && !deepEqual(documents.current, currentDocuments)) {
      documents.current = cloneDeep(currentDocuments)
    }
  }, [initialDocuments])

  const checkAllFileNamesToRepeats = useCallback(
    (fileNameWithExtension: string, fileId?: string) => {
      if (!documents.current) return

      const allDfoDocuments = getAllDfoDocumentsInArray(documents.current.documentTypeInfo)

      const duplicateNameCondition = allDfoDocuments.length
        ? allDfoDocuments.some(
            (item) =>
              item?.id !== fileId &&
              `${item?.name ?? ''}.${item?.extension ?? ''}` === fileNameWithExtension,
          )
        : false

      if (duplicateNameCondition) {
        toast(
          <Alert transparent variant="warning">
            Файл с таким именем уже существует
          </Alert>,
        )
      }
    },
    [],
  )

  const handleAddFolderToSet = useCallback(({ folder, type }: HandleAddFolderToSetProps) => {
    if (!documents.current) return

    const folderDocumentsIds = (folder.documents || []).map((document) => document.id)

    const updatedDocumentTypeInfo = documents.current?.documentTypeInfo.map((documentInfo) => {
      if (documentInfo.description !== type) return documentInfo

      return {
        ...documentInfo,
        documents: documentInfo.documents?.filter(
          (document) => !folderDocumentsIds.includes(document.id),
        ),
        folders: [...(documentInfo.folders || []), folder],
      }
    })

    documents.current = {
      ...documents.current,
      documentTypeInfo: updatedDocumentTypeInfo,
    }
  }, [])

  const handleRemoveFolderFromSet = useCallback(
    ({ folderId, type }: HandleRemoveFolderFromSetProps) => {
      if (!documents.current) return

      const updatedDocumentTypeInfo = documents.current?.documentTypeInfo.map((documentInfo) => {
        if (documentInfo.description !== type) return documentInfo

        return {
          ...documentInfo,
          folders: documentInfo.folders.filter((folder) => folder.id !== folderId),
        }
      })

      documents.current = {
        ...documents.current,
        documentTypeInfo: updatedDocumentTypeInfo,
      }
    },
    [],
  )

  const handleAddFileToSet = useCallback(
    ({ document, type, folderId }: HandleAddFileToSetProps) => {
      if (!documents.current) return

      const { name, extension, id } = document
      const fileNameWithExtension = `${name}.${extension}`

      checkAllFileNamesToRepeats(fileNameWithExtension, id)

      const updatedDocumentTypeInfo = documents.current?.documentTypeInfo.map((documentInfo) => {
        if (documentInfo.description !== type) return documentInfo

        if (!folderId) {
          return {
            ...documentInfo,
            documents: [...(documentInfo.documents || []), document],
          }
        }

        const updatedFolders = (documentInfo.folders || []).map((folder) =>
          folder.id !== folderId
            ? folder
            : {
                ...folder,
                documents: [...(folder.documents || []), document],
              },
        )

        return {
          ...documentInfo,
          folders: updatedFolders,
        }
      })

      documents.current = {
        ...documents.current,
        documentTypeInfo: updatedDocumentTypeInfo,
      }
    },
    [checkAllFileNamesToRepeats],
  )

  const handleUpdateFileFromSet = useCallback(
    ({ document, type, folderId }: HandleUpdateFileFromSetProps) => {
      if (!documents.current) return

      const { name, extension, id } = document

      const fileNameWithExtension = `${name}.${extension}`

      checkAllFileNamesToRepeats(fileNameWithExtension, id)

      const updatedDocumentTypeInfo = documents.current?.documentTypeInfo.map((documentInfo) => {
        if (documentInfo.description !== type) return documentInfo

        if (!folderId) {
          return {
            ...documentInfo,
            documents: documentInfo.documents?.map((documentItem) =>
              documentItem.id !== document.id ? documentItem : document,
            ),
          }
        }

        const updatedFolderWithUpdatedFile = documentInfo.folders.map((folder) =>
          folder.id !== folderId
            ? folder
            : {
                ...folder,
                documents: folder.documents?.map((documentItem) =>
                  documentItem.id !== document.id ? documentItem : document,
                ),
              },
        )

        return {
          ...documentInfo,
          folders: updatedFolderWithUpdatedFile,
        }
      })

      documents.current = {
        ...documents.current,
        documentTypeInfo: updatedDocumentTypeInfo,
      }
    },
    [checkAllFileNamesToRepeats],
  )

  const handleRemoveFileFromSet = useCallback(
    ({ documentId, type, folderId }: HandleRemoveFileFromSetProps) => {
      if (!documents.current) return

      const updatedDocumentTypeInfo = documents.current?.documentTypeInfo.map((documentInfo) => {
        if (documentInfo.description !== type) return documentInfo

        if (!folderId) {
          return {
            ...documentInfo,
            documents: documentInfo.documents?.filter((document) => document.id !== documentId),
          }
        }

        const updatedFoldersWithoutRemovedFile = documentInfo.folders.map((folder) =>
          folder.id !== folderId
            ? folder
            : {
                ...folder,
                documents: (folder.documents || []).filter(
                  (document) => document.id !== documentId,
                ),
              },
        )

        return {
          ...documentInfo,
          folders: updatedFoldersWithoutRemovedFile,
        }
      })

      documents.current = {
        ...documents.current,
        documentTypeInfo: updatedDocumentTypeInfo,
      }
    },
    [],
  )

  const fileContextValue: FileContextProps = useMemo(
    () => ({
      checkAllFileNamesToRepeats,
      handleAddFolderToSet,
      handleRemoveFolderFromSet,
      handleAddFileToSet,
      handleUpdateFileFromSet,
      handleRemoveFileFromSet,
    }),
    [
      checkAllFileNamesToRepeats,
      handleAddFileToSet,
      handleAddFolderToSet,
      handleRemoveFileFromSet,
      handleRemoveFolderFromSet,
      handleUpdateFileFromSet,
    ],
  )

  return <FileContext.Provider value={fileContextValue}>{children}</FileContext.Provider>
}

const useFileContext = () => {
  return useContext(FileContext)
}

export { FileProvider, useFileContext }
