import React from 'react'
import { useFormContext } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { petitionTransferRightBlockValues } from '@components/Forms/PetitionTransferRightForm/const'
import { thirdSectionValidationMap } from '@components/Forms/PetitionTransferRightForm/Forms/3/validation'
import { usePetitionTransferRightManager } from '@components/Forms/PetitionTransferRightForm/Manager'
import { PetitionTransferRightFormValues } from '@components/Forms/PetitionTransferRightForm/types'
import { PetitionTransferRightFieldsControlUpdateWatcher } from '@components/Forms/PetitionTransferRightForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { validationAdapter } = DocumentFormHelpers

const Third = () => {
  const formInstance = useFormContext<PetitionTransferRightFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = usePetitionTransferRightManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: PetitionTransferRightFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: petitionTransferRightBlockValues[3].rightTransferSolicitationReason,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: petitionTransferRightBlockValues[3].rightTransferSolicitationReason,
                  rules: thirdSectionValidationMap.rightTransferSolicitationReason,
                  textareaProps: {
                    label: 'Описание оснований передачи прав и обязанностей',
                    caption: 'в творительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          petitionTransferRightBlockValues[3].rightTransferSolicitationReason,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        petitionTransferRightBlockValues[3].rightTransferSolicitationReason,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
