import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { PopupProvider } from 'react-popup-manager'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import CustomToaster from '@components/Toaster'
import { APIProvider } from '@context/APIContext'
import { AuthProvider } from '@context/AuthContext'
import store from '@store'
import { SWRConfig } from 'swr'

import './styles/index.scss'
import './styles/bootstrap-grid.min.css'
import './styles/bootstrap-utilities.min.css'

import App from './App'

const defaultSwrConfig = { shouldRetryOnError: false, errorRetryCount: 5, errorRetryInterval: 300 }

const rootThree = (
  <StrictMode>
    <BrowserRouter>
      <SWRConfig value={defaultSwrConfig}>
        <AuthProvider>
          <APIProvider>
            <Provider store={store}>
              <PopupProvider>
                <App />
                <CustomToaster position="bottom-center" />
              </PopupProvider>
            </Provider>
          </APIProvider>
        </AuthProvider>
      </SWRConfig>
    </BrowserRouter>
  </StrictMode>
)

ReactDOM.render(rootThree, document.getElementById('root'))
