import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { CertificateOfComplianceContractForRecognitionConcludedAFormValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/types'

const generateRHFObjectForCertificateOfComplianceContractForRecognitionConcludedForm = (
  objectFromPropertyState: Record<string, any>,
): CertificateOfComplianceContractForRecognitionConcludedAFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getUOAdapter(objectFromPropertyState),
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameInstrumental:
        objectFromPropertyState.investorFullNameInstrumental?.value || '',
    },
  }
}

export { generateRHFObjectForCertificateOfComplianceContractForRecognitionConcludedForm }
