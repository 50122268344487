import { usePopupManager } from 'react-popup-manager'

import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import { useAPIContext } from '@context/APIContext'
import { useDfoAvailableForCreate } from '@hooks/new/swr/useDfoAvailableForCreate'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useProjectActions } from '@hooks/new/swr/useUiActions'
import { useQueryManager } from '@hooks/useQueryManager'
import { CreateProjectDfoParameters, TAllDfoTypes } from '@services/Dfo/Dfo.entity'

const useCreateDfo = (projectId: string) => {
  const {
    projectsApi: { createProjectDfo },
  } = useAPIContext()

  const { queryUtils } = useQueryManager()

  const { handleOpenLoadingModal } = useLoadingModal()
  const popupManager = usePopupManager()

  const { mutate: revalidateProjectDfos } = useProjectDfos({
    key: { projectId, _key: 'projectDfos' },
    config: {
      isPaused: () => !projectId,
    },
  })
  const { mutate: revalidateProjectActions } = useProjectActions({
    key: {
      projectId,
      _key: 'projectActions',
    },
    config: {
      isPaused: () => !projectId,
    },
  })

  const { mutate: revalidateProjectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      isPaused: () => !projectId,
    },
  })

  const { mutate: revalidateDfoAvailableForCreate } = useDfoAvailableForCreate({
    key: {
      projectId,
      _key: 'availableDfoForCreate',
    },
    config: {
      isPaused: () => !projectId,
    },
  })

  const handleCreateDfo =
    (type: TAllDfoTypes, parameters?: CreateProjectDfoParameters[]) => async () => {
      handleOpenLoadingModal()

      await revalidateProjectAttributes()

      try {
        const dfoResponse = await createProjectDfo({
          projectId,
          type,
          parameters,
        })

        try {
          await revalidateProjectDfos()
          await revalidateProjectActions()
          await revalidateDfoAvailableForCreate()
        } catch (e) {
          throw e
        }

        queryUtils.addQuery({
          query: {
            dfoId: dfoResponse?.data.id || '',
          },
        })
      } catch (error) {
        throw error
      } finally {
        popupManager.closeAll()
      }
    }

  return { handleCreateDfo }
}

export { useCreateDfo }
