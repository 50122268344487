const NPAModalActionKindOld = {
  TOGGLE_REQUEST_LOADING: 'TOGGLE_REQUEST_LOADING',
  SETUP_READY_THREE: 'SETUP_READY_THREE',
  SETUP_CHOSEN_THREE: 'SETUP_CHOSEN_THREE',
  NORMALIZE_DATA: 'NORMALIZE_DATA',
  CHANGE_CHOSEN: 'CHANGE_CHOSEN',
  CHANGE_CHOSEN_DATA: 'CHANGE_CHOSEN_DATA',
  CHANGE_CHOSEN_ERRORS: 'CHANGE_CHOSEN_ERRORS',
} as const

export { NPAModalActionKindOld }
