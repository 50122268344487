import React, { type FC, type MouseEvent, useRef } from 'react'
import Dropzone, { type DropzoneProps, type DropzoneRef } from 'react-dropzone'

import type { IconProps } from '@components/Icon'
import IconButton from '@components/NewDesign/IconButton'
import type { IconButtonProps } from '@components/NewDesign/IconButton/types'
import DownloadAttachmentIcon from '@icons/attachment/actions/DownloadAttachmentIcon.svg'
import EditAttachmentIcon from '@icons/attachment/actions/EditAttachmentIcon.svg'
import ReloadAttachmentIcon from '@icons/attachment/actions/ReloadAttachmentIcon.svg'
import RemoveAttachmentIcon from '@icons/attachment/actions/RemoveAttachmentIcon.svg'
import ReplaceAttachmentIcon from '@icons/attachment/actions/ReplaceAttachmentIcon.svg'
import SignAttachmentIcon from '@icons/attachment/actions/SignAttachmentIcon.svg'

import { SignMimeTypes } from './constants'

interface FileItemReducerActionMap extends Omit<IconButtonProps, 'disabled' | 'onClick' | 'icon'> {
  onClick: (e) => void
  icon: Omit<IconProps, 'src'>
  disabled?: boolean
}

interface FileItemReducerSignAction extends Omit<IconButtonProps, 'onClick' | 'icon'> {
  icon: Omit<IconProps, 'src'>
  onDropSign: DropzoneProps['onDrop']
}

interface FileItemActionsProps extends FC {
  Reload: FC<FileItemReducerActionMap>
  Edit: FC<FileItemReducerActionMap>
  Download: FC<FileItemReducerActionMap>
  Replace: FC<FileItemReducerActionMap>
  Remove: FC<FileItemReducerActionMap>
  Sign: FC<FileItemReducerSignAction>
}

const FileItemActions: FileItemActionsProps = () => null

FileItemActions.Reload = ({ disabled, onClick, icon, ...props }) => (
  <IconButton
    {...props}
    disabled={disabled}
    geometry={'round'}
    size={'s'}
    view={'plain'}
    icon={{
      src: ReloadAttachmentIcon,
      ...icon,
    }}
    onClick={onClick}
  />
)

FileItemActions.Edit = ({ disabled, onClick, icon, ...props }) => (
  <IconButton
    {...props}
    disabled={disabled}
    geometry={'round'}
    size={'s'}
    view={'plain'}
    icon={{
      src: EditAttachmentIcon,
      ...icon,
    }}
    onClick={onClick}
  />
)

FileItemActions.Remove = ({ disabled, onClick, icon, ...props }) => (
  <IconButton
    {...props}
    disabled={disabled}
    geometry={'round'}
    size={'s'}
    view={'plain'}
    icon={{
      noCurrentColorSvgFill: !disabled,
      src: RemoveAttachmentIcon,
      ...icon,
    }}
    onClick={onClick}
  />
)

FileItemActions.Download = ({ disabled, onClick, icon, ...props }) => (
  <IconButton
    {...props}
    disabled={disabled}
    geometry={'round'}
    size={'s'}
    view={'plain'}
    icon={{
      src: DownloadAttachmentIcon,
      ...icon,
    }}
    onClick={onClick}
  />
)

FileItemActions.Replace = ({ disabled, onClick, icon, ...props }) => (
  <IconButton
    {...props}
    disabled={disabled}
    geometry={'round'}
    size={'s'}
    view={'plain'}
    icon={{
      src: ReplaceAttachmentIcon,
      ...icon,
    }}
    onClick={onClick}
  />
)

FileItemActions.Sign = ({
  geometry = 'round',
  size = 's',
  view = 'plain',
  onDropSign,
  icon,
  ...props
}) => {
  const dropzoneRef = useRef<DropzoneRef | null>(null)

  const handleOpenDropzone = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    dropzoneRef.current?.open()
  }

  return (
    <Dropzone
      noDrag
      noClick
      noKeyboard
      noDragEventsBubbling
      accept={SignMimeTypes}
      ref={dropzoneRef}
      onDrop={onDropSign}
    >
      {({ getRootProps }) => (
        <IconButton
          {...getRootProps()}
          {...props}
          geometry={geometry}
          size={size}
          view={view}
          icon={{
            src: SignAttachmentIcon,
            ...icon,
          }}
          onClick={handleOpenDropzone}
        />
      )}
    </Dropzone>
  )
}

export default FileItemActions
