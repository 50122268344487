import React from 'react'
import { Path, useFormContext, useWatch } from 'react-hook-form'

import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { financialModelBlockValues } from '@components/Forms/FinancialModelForm/const'
import InvestYears from '@components/Forms/FinancialModelForm/Forms/2/InvestYears'
import { useFinancialModelManager } from '@components/Forms/FinancialModelForm/Manager'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { FinancialModelFieldsControlUpdateWatcher } from '@components/Forms/FinancialModelForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { billionIntValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { getObjectValue } from '@helpers/object/getObjectValue'

const { transformRHFPathInProperties, getRublesFromBillion } = DocumentFormHelpers

const Two = () => {
  const formInstance = useFormContext<FinancialModelFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeCustomValue, getLastRHFBeforeValue, getPropertiesProps },
    preparedProps: { subscribableControl },
  } = useFinancialModelManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: FinancialModelFieldsControlUpdateWatcher,
  })

  const investmentBudgetFinance = useWatch({
    name: financialModelBlockValues['2'].investmentBudgetFinance,
    control: formInstance.control,
  })
  
  const investmentRaiseFinance = useWatch({
    name: financialModelBlockValues['2'].investmentRaiseFinance,
    control: formInstance.control,
  })

  const handleUpdateBillionValue = async (pathName: Path<FinancialModelFormValues>) => {
    const beforeUpdateValue = getObjectValue(getLastRHFBeforeValue?.(), pathName) as
      | string
      | undefined
    const propertyPropsPath = transformRHFPathInProperties(pathName)

    const propertyProps = getObjectValue(getPropertiesProps?.(), propertyPropsPath)

    const value = formInstance.getValues(pathName) as string

    if (!propertyProps || beforeUpdateValue === value) return

    await handleChangeCustomValue?.(
      {
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
        type: propertyProps.type,
        newValue: Math.round(getRublesFromBillion(value) * 100),
      },
      'investYearsBatchId',
    )
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <InvestYears
          formInstance={formInstance}
          subscribableControl={subscribableControl}
          onUpdateBillionValue={handleUpdateBillionValue}
        />
        <Group disableBottomBorder groupClassName={'mt-0'} title="Бюджетное финансирование">
          <ContainerWithBorder>
            <Stack direction={'vertical'} gap={2}>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                firstColumnSize={7}
                secondColumnSize={5}
                title="Из федерального бюджета"
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: financialModelBlockValues['2'].investmentFederalBudget,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: financialModelBlockValues['2'].investmentFederalBudget,
                      rules: {
                        validate: (value) => {
                          if (!isString(value)) return

                          return billionIntValidate(value)
                        },
                      },
                      inputProps: {
                        label: 'млрд',
                        dataTestId: 'investmentFederalBudget',
                      },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleUpdateBillionValue(
                              financialModelBlockValues['2'].investmentFederalBudget,
                            ),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                firstColumnSize={7}
                secondColumnSize={5}
                title="Из регионального бюджета"
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: financialModelBlockValues['2'].investmentRegionalBudget,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: financialModelBlockValues['2'].investmentRegionalBudget,
                      rules: {
                        validate: (value) => {
                          if (!isString(value)) return

                          return billionIntValidate(value)
                        },
                      },
                      inputProps: {
                        label: 'млрд',
                        dataTestId: 'investmentRegionalBudget',
                      },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleUpdateBillionValue?.(
                              financialModelBlockValues['2'].investmentRegionalBudget,
                            ),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                firstColumnSize={7}
                secondColumnSize={5}
                title="Из муниципальных бюджетов"
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: financialModelBlockValues['2'].investmentMunicipalBudget,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: financialModelBlockValues['2'].investmentMunicipalBudget,
                      rules: {
                        validate: (value) => {
                          if (!isString(value)) return

                          return billionIntValidate(value)
                        },
                      },
                      inputProps: {
                        label: 'млрд',
                        dataTestId: 'investmentMunicipalBudget',
                      },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleUpdateBillionValue?.(
                              financialModelBlockValues['2'].investmentMunicipalBudget,
                            ),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                firstColumnSize={7}
                secondColumnSize={5}
                title="Итого"
              >
                <Typography.Body variant="bodyMMedium" className={'py-3 pl-3'}>
                  {investmentBudgetFinance || 0} млрд
                </Typography.Body>
              </RowWithBorder>
            </Stack>
          </ContainerWithBorder>
        </Group>
        <ContainerWithBorder>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: financialModelBlockValues['2'].investmentInvestorFinance,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: financialModelBlockValues['2'].investmentInvestorFinance,
                  rules: {
                    validate: (value) => {
                      if (!isString(value)) return

                      return billionIntValidate(value)
                    },
                  },
                  inputProps: {
                    label: 'Собственные средства, млрд',
                    dataTestId: 'investmentInvestorFinance',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleUpdateBillionValue?.(
                          financialModelBlockValues['2'].investmentInvestorFinance,
                        ),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </ContainerWithBorder>
        <RowWithBorder
          disableTitleLeftPadding
          disableTopPadding
          disableBottomDefaultStyles
          firstColumnSize={7}
          secondColumnSize={5}
          title="Привлеченные средства"
        >
          <Typography.Body variant="bodyMMedium" className={'py-3 pl-3'}>
            {investmentRaiseFinance || 0} млрд
          </Typography.Body>
        </RowWithBorder>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: financialModelBlockValues['2'].investmentBankCredits,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: financialModelBlockValues['2'].investmentBankCredits,
                  rules: {
                    validate: (value) => {
                      if (!isString(value)) return

                      return billionIntValidate(value)
                    },
                  },
                  inputProps: {
                    label: 'Банковские кредиты, млрд',
                    dataTestId: 'investmentBankCredits',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleUpdateBillionValue?.(
                          financialModelBlockValues['2'].investmentBankCredits,
                        ),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: financialModelBlockValues['2'].investmentOtherRaiseFinance,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: financialModelBlockValues['2'].investmentOtherRaiseFinance,
                  rules: {
                    validate: (value) => {
                      if (!isString(value)) return

                      return billionIntValidate(value)
                    },
                  },
                  inputProps: {
                    label: 'Иные привлеченные средства, млрд',
                    dataTestId: 'investmentOtherRaiseFinance',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleUpdateBillionValue?.(
                          financialModelBlockValues['2'].investmentOtherRaiseFinance,
                        ),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Two
