import React, { FC } from 'react'
import { ReactSVG } from 'react-svg'

import Typography from '@components/Typography'
import { icons } from '@routes/Home/StepCard/icons'
import cn from 'classnames'

import styles from './StepCard.module.scss'

export type IStepCard = FC<{
  type?: '100' | '200' | '300' | '400' | '500' | '600'
  step?: string
}> & {
  Title: FC<{ className?: string }>
  Body: FC
  Bottom: FC
}

const StepCard: IStepCard = ({ children, type, step }) => {
  return (
    <div
      className={cn(styles.card, {
        [styles[`contrast-${type}`]]: type,
      })}
    >
      <div className={styles.step}>
        <span>{step}</span>
        <span>
          <ReactSVG src={icons[type ?? '100']} />
        </span>
      </div>
      {children}
    </div>
  )
}

StepCard.Title = ({ children }) => {
  return (
    <Typography variant="h5" className={styles.cardTitle}>
      {children}
    </Typography>
  )
}
StepCard.Body = ({ children }) => {
  return <Typography variant="p">{children}</Typography>
}

StepCard.Bottom = ({ children }) => {
  return (
    <Typography variant="p" color="secondary" className={styles.bottom}>
      {children}
    </Typography>
  )
}

export default StepCard
