import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNoticeOfRejectConcludeSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: {
        propertyId: objectFromPropertyState?.investorFullNameGenitive?.propertyId,
        type: objectFromPropertyState?.investorFullNameGenitive?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameGenitive?.lastUpdateDt,
      },
      investorFullNameInstrumental: {
        propertyId: objectFromPropertyState?.investorFullNameInstrumental?.propertyId,
        type: objectFromPropertyState?.investorFullNameInstrumental?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameInstrumental?.lastUpdateDt,
      },
    },
    '4': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
      ruleLinkStatementReject: {
        propertyId: objectFromPropertyState.ruleLinkStatementReject?.propertyId,
        type: objectFromPropertyState.ruleLinkStatementReject?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStatementReject?.lastUpdateDt,
      },
      ruleLinkStatementRepeat: {
        propertyId: objectFromPropertyState.ruleLinkStatementRepeat?.propertyId,
        type: objectFromPropertyState.ruleLinkStatementRepeat?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStatementRepeat?.lastUpdateDt,
      },
      statementRejectReason: {
        propertyId: objectFromPropertyState.statementRejectReason?.propertyId,
        type: objectFromPropertyState.statementRejectReason?.type,
        lastUpdateDt: objectFromPropertyState.statementRejectReason?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNoticeOfRejectConcludeSZPKForm }
