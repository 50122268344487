import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpense,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { lengthValidate, standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const stepOldExpenseValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpense,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  oldStepExpenseName: {
    validate: (value) => (isString(value) ? lengthValidate(value, 1000) : undefined),
  },
  oldStepExpenseValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { stepOldExpenseValidationMap }
