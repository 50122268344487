import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { ridExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RExploitationForm/RExploitationFormItem/validation'
import { SeventhRidExploitationObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

interface RExploitationFormItemProps {
  formName: SeventhRidExploitationObjectsArrayPathName
  blockViewIsValidating: boolean
  indexOfBlock: number
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
}

const RExploitationFormItem: FC<RExploitationFormItemProps> = ({
  blockViewIsValidating,
  formInstance,
  formModifierInstance,
  formName,
  indexOfBlock,
}) => {
  const {
    state: { editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formModifierInstance.control,
  })

  const {
    getFormFieldControlProps,
    getSubscribableControlProps,
    getInputProps,
    getSingleSelectProps,
    getCalendarInputProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const stepEndDate = useWatch({
    name: `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${indexOfBlock}.stepEndDate`,
    control: formInstance.control,
  })

  const positiveStepEndDate = (text: string) => (value: unknown) => {
    return (
      (isDateValidForDayjs(value) &&
        dayjs(value, objOfDateFormats.defaultFormat) <
          dayjs(stepEndDate, objOfDateFormats.defaultFormat)) ||
      text
    )
  }

  return (
    <div>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.ridName`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          value: item.id,
                          displayValue: item.ridName,
                        }),
                        controllerProps: {
                          name: `${formName}.ridName`,
                          rules:
                            ridExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridName,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'РИД',
                          },
                          onChangeFormValue: () =>
                            setTimeout(() => handleChangeValue?.(`${formName}.ridName`), 0),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.ridCommissionNumber`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.ridCommissionNumber`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.ridCommissionNumber`,
                    rules:
                      ridExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridCommissionNumber,
                    inputProps: {
                      label: 'Номер',
                    },
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.ridCommissionNumber`), 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.ridCommissionNumber`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Col>
            <Col xs={4}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.ridCommissioningDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.ridCommissioningDate`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${formName}.ridCommissioningDate`,
                    rules: {
                      ...ridExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridCommissioningDate,
                      validate: {
                        positiveStepEndDate: positiveStepEndDate(
                          'дата ввода РИД в эксплуатацию должна быть меньше даты окончания этапа',
                        ),
                      },
                    },
                    calendarInputProps: {
                      label: 'Дата ввода',
                    },

                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.ridCommissioningDate`), 0),
                    onInputChange: () =>
                      setTimeout(
                        () => debouncedHandleChangeValue?.(`${formName}.ridCommissioningDate`),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.ridCommissioningDate`), 0),
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
        </Stack>
      </Col>
    </div>
  )
}

export default RExploitationFormItem
