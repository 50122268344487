type Listener = (value: boolean) => void

class WatcherManager {
  isStale = false

  private listeners: Listener[] = []

  subscribe = (listener: Listener) => {
    this.listeners.push(listener)
  }

  unsubscribe = (receivedListener: Listener) => {
    this.listeners = this.listeners.filter((listener) => listener !== receivedListener)
  }

  setStaleStatus = (status: boolean) => {
    this.isStale = status
    this.listeners.forEach((listener) => listener(status))
  }
}

export { WatcherManager }
export const NpaAddonThreeManager = new WatcherManager()
