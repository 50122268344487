import { BeneficiaryOfSuccessorOrganizationInformationFormValues } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/types'

const mapOfBeneficiaryOfSuccessorOrganizationInformationMenu = [
  { id: '1', title: 'Организация правопреемник' },
  { id: '2', title: 'Информация о бенефициарных владельцах' },
]

const defaultBeneficiaryOfSuccessorOrganizationInformationFormValues: BeneficiaryOfSuccessorOrganizationInformationFormValues =
  {
    '1': {
      investorPartnerFullName: '',
      investorPartnerInn: '',
      investorPartnerOgrn: '',
      investorPartnerAddress: '',
      investorPartnerHeaderName: null,
      investorPartnerHeaderNameGenitive: '',
      investorPartnerHeaderPosition: '',
      investorPartnerHeaderPositionGenitive: '',
      investorPartnerHeaderReasonGenitive: '',
      investorPartnerAddressFullEgrulFormat: '',
    },
    '2': {
      beneficiars: [],
    },
  }

const beneficiaryOfSuccessorOrganizationInformationBlockValues = {
  '1': {
    investorPartnerFullName: '1.investorPartnerFullName',
    investorPartnerInn: '1.investorPartnerInn',
    investorPartnerOgrn: '1.investorPartnerOgrn',
    investorPartnerAddress: '1.investorPartnerAddress',
    investorPartnerHeaderName: '1.investorPartnerHeaderName',
    investorPartnerHeaderNameGenitive: '1.investorPartnerHeaderNameGenitive',
    investorPartnerHeaderPosition: '1.investorPartnerHeaderPosition',
    investorPartnerHeaderPositionGenitive: '1.investorPartnerHeaderPositionGenitive',
    investorPartnerHeaderReasonGenitive: '1.investorPartnerHeaderReasonGenitive',
    investorPartnerAddressFullEgrulFormat: '1.investorPartnerAddressFullEgrulFormat',
  },
  '2': {
    beneficiars: '2.beneficiars',
  },
} as const

export {
  beneficiaryOfSuccessorOrganizationInformationBlockValues,
  defaultBeneficiaryOfSuccessorOrganizationInformationFormValues,
  mapOfBeneficiaryOfSuccessorOrganizationInformationMenu,
}
