import { FC, ReactNode } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import MainTemplate from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldCollapseControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'

interface StageProps {
  id: string
  indexOfStage: number
  initialAccordionState?: boolean
  initialAccordionMountState?: boolean
  additionalAccordionNode?: ReactNode
  onRemoveStage?: VoidFunction
}

const Stage: FC<StageProps> = ({
  id,
  indexOfStage,
  initialAccordionState,
  initialAccordionMountState,
  additionalAccordionNode,
  onRemoveStage,
}) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  const formName =
    `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${indexOfStage}` as const

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name: formName,
    watcher:
      AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldCollapseControlUpdateWatcher,
    initialExpanded: initialAccordionMountState,
  })

  const {
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { isConstruction, isCreateRID, isModernization, isReconstruction, stepDescription } =
    useWatch({
      name: formName,
      control: formInstance.control,
    })

  return (
    <SubscribableControl
      {...getSubscribableControlProps({
        path: formName,
      })}
    >
      <Accordion
        id={id}
        anchorId={formName}
        isExpanded={isExpanded}
        title={stepDescription}
        initialAccordionState={initialAccordionState}
        initialAccordionMountState={initialAccordionMountState}
        additionalNode={additionalAccordionNode}
        onToggle={onToggleCollapse}
        onRemove={onRemoveStage}
      >
        <MainTemplate
          indexOfMainBlock={indexOfStage}
          isRidRender={isCreateRID}
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
          stageId={id}
          name={formName}
          isConstructionRender={isConstruction || isModernization || isReconstruction}
        />
      </Accordion>
    </SubscribableControl>
  )
}

export default Stage
