import { compact } from '@helpers/array/compact'

const generatePrefetchedPropsForBeneficiary = (
  objectFromPropertyState: Record<string, any>,
): string[] => {
  return compact([
    ...objectFromPropertyState.beneficiarsPartner?.value.map(
      (beneficiar) => beneficiar.value.beneficiarPartnerNationality?.propertyId,
    ),
    ...objectFromPropertyState.beneficiarsPartner?.value.map(
      (beneficiar) => beneficiar.value.beneficiarPartnerDocument?.propertyId,
    ),
  ])
}

export { generatePrefetchedPropsForBeneficiary }
