import { FormModifierAdapters } from '@components/DocumentFormComponents/adapters/Modifier.adapter'
import { FormObjectStatesWithChanges } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectsConstructionObjectModifier,
  AAgreementOnCreationOtherEstateObjectsFormModifierValues,
  AAgreementOnCreationOtherEstateObjectsStageModifier,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { isArray } from '@helpers/checkTypes'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

const { getModifierStatesFormPropertyState, comparePropertyValueWithCommitedValue } =
  FormModifierAdapters

const generateFormModifierValuesForAAgreementOnCreationOtherEstateObjectsForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnCreationOtherEstateObjectsFormModifierValues => {
  const preparedConstructionStages = ((): AAgreementOnCreationOtherEstateObjectsStageModifier[] => {
    if (
      !isArray(objectFromPropertyState.steps?.value) ||
      !objectFromPropertyState.steps?.value?.length
    )
      return []

    return objectFromPropertyState.steps?.value.map(
      (step): AAgreementOnCreationOtherEstateObjectsStageModifier => {
        const stepState = getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(step)

        const preparedConstructionObjects: AAgreementOnCreationOtherEstateObjectsConstructionObjectModifier[] =
          (() => {
            const objects = step.value.objects?.value?.filter((object) => {
              return (
                object.value.isMainObject?.value &&
                !object.value.isSubObject?.value &&
                !object.value.objectOtherActivityName?.value?.id
              )
            })

            const subObjects = step.value.objects?.value?.filter((object) => {
              return (
                object.value.isSubObject?.value &&
                !object.value.objectOtherActivityName?.value?.id &&
                object.value.parentSubObject?.value
              )
            })

            return objects?.map(
              (object): AAgreementOnCreationOtherEstateObjectsConstructionObjectModifier => {
                const objectState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(object)

                const preparedSubObjects: AAgreementOnCreationOtherEstateObjectsConstructionObjectModifier[] =
                  subObjects
                    ?.filter((subObject) => {
                      return object.permanentId === subObject.value.parentSubObject?.value?.id
                    })
                    .map((subObject) =>
                      getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(subObject),
                    )

                return {
                  ...objectState,
                  subObjects: preparedSubObjects,
                }
              },
            )
          })()

        const preparedPermissions: FormObjectStatesWithChanges[] = (() => {
          if (!isArray(step.value.permissions?.value) || !step.value.permissions.value.length)
            return []

          return step.value.permissions?.value.map((permission) =>
            getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(permission),
          )
        })()

        const preparedExploitationObjects: FormObjectStatesWithChanges[] = (() => {
          if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
            return []

          return step.value.commissionObjects?.value.map((exploitation) =>
            getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(exploitation),
          )
        })()

        const preparedRegistrationObjects: FormObjectStatesWithChanges[] = (() => {
          if (
            !step.value.registrationObjects?.value ||
            !step.value.registrationObjects?.value.length
          )
            return []

          return step.value.registrationObjects?.value.map((registration) =>
            getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(registration),
          )
        })()

        const isChangedExploitationFields = !stepState?.isNew
          ? comparePropertyValueWithCommitedValue(step.value.objectCommissioningEndDate)
          : false

        const isChangedRegistrationFields = !stepState?.isNew
          ? [
              step.value.typeObjectStateRegistration,
              step.value.objectStateRegistrationEndDate,
            ].some(comparePropertyValueWithCommitedValue)
          : false

        const isChangedStepFields = !stepState?.isNew
          ? [step.value.stepCompensationValue, step.value.stepCompensationForm].some(
              comparePropertyValueWithCommitedValue,
            )
          : false

        return {
          ...stepState,
          isChanged: isChangedStepFields,
          constructionObjects: preparedConstructionObjects,
          constructionObjectsState: getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.objects),
          permissionsObjects: preparedPermissions,
          permissionsObjectsState: getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(
            step.value.permissions,
          ),

          exploitation: {
            isChanged: isChangedExploitationFields,
            exploitationObjects: preparedExploitationObjects,
            exploitationObjectsState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.commissionObjects),
          },

          registration: {
            isChanged: isChangedRegistrationFields,
            registrationObjects: preparedRegistrationObjects,
            registrationObjectsState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.registrationObjects),
          },
        }
      },
    )
  })()

  return {
    '5': {
      constructionStages: preparedConstructionStages,
    },
  }
}

export { generateFormModifierValuesForAAgreementOnCreationOtherEstateObjectsForm }
