import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NoticeOneSideRejectSZPKLayout from './Layout'
import NoticeOneSideRejectSZPKModalManager from './Manager'
import NoticeOneSideRejectSZPKMenu from './Menu'
import NoticeOneSideRejectSZPKStatementForm from './Statement'

interface INoticeOneSideRejectSZPK extends FC {
  Layout: typeof NoticeOneSideRejectSZPKLayout
  Menu: typeof NoticeOneSideRejectSZPKMenu
  StatementForm: typeof NoticeOneSideRejectSZPKStatementForm
  Manager: typeof NoticeOneSideRejectSZPKModalManager
}

const useNoticeOneSideRejectSZPKControl = () => {
  const popupManager = usePopupManager()

  const openNoticeOneSideRejectSZPKModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(NoticeOneSideRejectSZPKLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openNoticeOneSideRejectSZPKModal }
}

const NoticeOneSideRejectSZPK: INoticeOneSideRejectSZPK = () => null

NoticeOneSideRejectSZPK.Menu = NoticeOneSideRejectSZPKMenu
NoticeOneSideRejectSZPK.Layout = NoticeOneSideRejectSZPKLayout
NoticeOneSideRejectSZPK.StatementForm = NoticeOneSideRejectSZPKStatementForm
NoticeOneSideRejectSZPK.Manager = NoticeOneSideRejectSZPKModalManager

export { useNoticeOneSideRejectSZPKControl }
export default NoticeOneSideRejectSZPK
