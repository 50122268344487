import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface NoticeOfProjectRefuseConcludeAAFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const NoticeOfProjectRefuseConcludeAAForms: NoticeOfProjectRefuseConcludeAAFormsProps = () => null

NoticeOfProjectRefuseConcludeAAForms.First = First
NoticeOfProjectRefuseConcludeAAForms.Second = Second
NoticeOfProjectRefuseConcludeAAForms.Third = Third
NoticeOfProjectRefuseConcludeAAForms.Fourth = Fourth


export default NoticeOfProjectRefuseConcludeAAForms
