import React, { FC, useCallback, useMemo } from 'react'

import DocumentsSet from '@components/DocumentsSet'
import {
  isGroupingChangesToDfoIsAvailable,
  isSubsidyStatementWillBeCreate,
} from '@components/DocumentsSet/helpers'
import { useCreateAgreementControlModal } from '@components/NewDesignedModals/CreateAgreementModal'
import {
  DfoCategoryTypes,
  divideAgreementTypesIntoCategories,
  hasAnyCategoryValue,
  IAgreementIntoCategories,
} from '@components/NewDesignedModals/CreateAgreementModal/helpers'
import { useCreateSubsidyModal } from '@components/NewDesignedModals/CreateSubsidyModal'
import { dfoAvailableForCreateTitlesMap } from '@components/Projects/[id]/DocumentLayout/Navigation/Actions/AddChangesToProject/constants'
import { DfosStage, DfosType, NewDfosType, RolesTypes } from '@constants/types'
import withRolesRender from '@hoc/withRolesRender'
import { useCreateDfo } from '@hooks/new/dfo/useCreateDfo'
import { useDfoTypes } from '@hooks/new/swr/useDfoTypes'
import { IDfoListItem, TAllDfoTypes } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import styles from './AddChangesToProject.module.scss'

//В панели навигации документов, в ЛК инвестора, логика добавления дфо

interface AddChangesToSZPKProps {
  projectId: string

  dfoAvailableForCreate: string[]
  projectDfos: IDfoListItem[]
  createdDfoCategory?: string[]
}

const AddChangesToProject: FC<AddChangesToSZPKProps> = ({
  projectId,
  dfoAvailableForCreate,
  projectDfos,
  createdDfoCategory,
}) => {
  const { handleCreateDfo } = useCreateDfo(projectId)

  const { handleOpenAgreementModal } = useCreateAgreementControlModal()
  const { handleOpenCreateSubsidy } = useCreateSubsidyModal(projectId)

  const { dfoTypes } = useDfoTypes({ key: { _key: 'dfoTypes' } })

  const readySplitCategories = useMemo(() => {
    if (!dfoTypes) return {}

    return divideAgreementTypesIntoCategories(dfoAvailableForCreate as TAllDfoTypes[], dfoTypes)
  }, [dfoTypes, dfoAvailableForCreate])

  const notGroupAddChangesDfo = useMemo(
    () => dfoAvailableForCreate?.length && isGroupingChangesToDfoIsAvailable(dfoAvailableForCreate),
    [dfoAvailableForCreate],
  )

  const isSZPKRegistered = projectDfos.find(
    (dfo) =>
      dfo.type === DfosType.SZPK &&
      (dfo.stage === DfosStage.REGISTERED || dfo.stage === DfosStage.TERMINATING),
  )

  const agreementAddRenderCondition = useMemo(() => {
    return hasAnyCategoryValue(readySplitCategories) && isSZPKRegistered
  }, [isSZPKRegistered, readySplitCategories])

  const subsidyAddRenderCondition = useMemo(
    () =>
      dfoAvailableForCreate?.length &&
      isSubsidyStatementWillBeCreate(dfoAvailableForCreate) &&
      !createdDfoCategory?.includes(DfoCategoryTypes.CLAIM_SUBSIDY_PERMIT) &&
      !createdDfoCategory?.includes(DfoCategoryTypes.CLAIM_TAX_REFUND),
    [createdDfoCategory, dfoAvailableForCreate],
  )

  const handleClickAgreementButton =
    (projectId: string, readyCategories: IAgreementIntoCategories) => () => {
      handleOpenAgreementModal(projectId, readyCategories)
    }

  const handleAddChangesToProject = useCallback(
    (type: TAllDfoTypes) => async () => {
      try {
        await handleCreateDfo(type)()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          additionInfo: {
            type,
          },
          componentInfo: {
            componentName: 'AddChangesToProject',
            componentType: 'handleAddChangesToProject',
            moduleName: 'DocumentsNavigation',
          },
        })(error)
      }
    },
    [handleCreateDfo],
  )

  if (!dfoAvailableForCreate.length) return null

  return (
    <>
      {notGroupAddChangesDfo &&
        dfoAvailableForCreate?.map((dfoType, index) => {
          //Убираем кнопку добавления ходатайства из зарегистрированных
          if (dfoType === NewDfosType.RELATED_CONTRACT_LIST && isSZPKRegistered) return null

          const titleOfItemCreate: string = dfoAvailableForCreateTitlesMap[dfoType]
          const subtitleOfItemCreate = dfoTypes ? dfoTypes[dfoType]?.dfoSubtitle : ''

          // Возможно, в зависимости от типов будут разные хендлеры, на каждый тип создавать разную абстракцию. Ниже - визуальная часть для этих кнопок
          return (
            <DocumentsSet.ItemCreate
              key={index}
              title={titleOfItemCreate}
              subtitle={subtitleOfItemCreate}
              className={styles.navigation__create}
              dataTestId={`DocumentSetItemCreate-button-${dfoType}`}
              onClick={handleAddChangesToProject(dfoType as TAllDfoTypes)}
            />
          )
        })}

      {agreementAddRenderCondition && (
        <DocumentsSet.ItemCreate
          className={cn(styles.button, styles.button__addChanges)}
          positionOfIcon={'left'}
          title={'Добавить изменения к СЗПК'}
          dataTestId="DocumentSetItemCreate-button-addSZPKChanges"
          onClick={handleClickAgreementButton(projectId, readySplitCategories)}
        />
      )}

      {subsidyAddRenderCondition && (
        <DocumentsSet.ItemCreate
          className={cn(styles.button, styles.button__addChanges)}
          positionOfIcon={'left'}
          title={'Заявление на возмещение затрат'}
          dataTestId="DocumentSetItemCreate-button-addSubsidy"
          onClick={handleOpenCreateSubsidy}
        />
      )}
    </>
  )
}

export default withRolesRender(AddChangesToProject, [RolesTypes.INVESTOR])
