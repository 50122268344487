import React, { FC, memo, useContext, useEffect } from 'react'

import { MONTH_NAMES_WITH_ENDING } from '@components/NewDesign/Calendar/const'
import IconButton from '@components/NewDesign/IconButton'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import { LoadingAttachment } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/Attachment/LoadingAttachment'
import {
  INotificationItem,
  NotificationAttachment,
} from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/Attachment/NotificationAttachment'
import { NotificationsOfRequisitesChangesContext } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/NotificationsOfRequisitesChanges'
import { DocumentsSetsType } from '@constants/types'
import DownloadDocumentTooltipContent from '@containers/DownloadDocumentTooltipContent'
import { useAPIContext } from '@context/APIContext'
import { isNullOrUndefined } from '@helpers/checkTypes'
import { convertBytes } from '@helpers/convertBytesInString'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import { useDfoDocuments } from '@hooks/new/swr/useDfoDocuments'
import { useProjectActions } from '@hooks/new/swr/useUiActions'
import { useBooleanState } from '@hooks/useBooleanState'
import DownloadAttachmentIcon from '@icons/attachment/actions/DownloadAttachmentIcon.svg'
import RemoveAttachmentIcon from '@icons/attachment/actions/RemoveAttachmentIcon.svg'
import { DownloadFileParams } from '@interfaces/params'
import { useDocumentActions } from '@services/DocumentActions/DocumentAction.hook'
import { mapOfDocumentActions } from '@services/DocumentActions/DocumentActions.entity'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './AddedNotificationAttachment.module.scss'

const formatDate = (date) =>
  `${date.getDate()} ${MONTH_NAMES_WITH_ENDING[date.getMonth()]} ${date.getFullYear()}`

const { fromActionToActionsStack } = DocumentActionsService

const AddedNotificationAttachment: FC<Omit<INotificationItem, 'actions' | 'date' | 'fileInfo'>> = (
  props,
) => {
  const {
    projectsApi: { deleteProjectById },
    documentsApi: { downloadDocumentById },
  } = useAPIContext()

  const {
    booleanState: isLoading,
    setBooleanStateToTrue: startLoading,
    setBooleanStateToFalse: endLoading,
  } = useBooleanState()

  const {
    state: { tooltipIsOpen, targetTooltipRef },
    handlers: { handleOpenTooltip, handleCloseTooltip },
    bindHandlersRef: { bindRefOfClickableOutsideZone },
  } = useManualTooltipControl()

  useEffect(() => {
    bindRefOfClickableOutsideZone(window.document.body)
  }, [bindRefOfClickableOutsideZone])

  const { revalidateProjects } = useContext(NotificationsOfRequisitesChangesContext)

  const { dfoDocuments, mutate: revalidateDfoDocuments } = useDfoDocuments({
    key: {
      projectId: props.projectId,
      dfoId: props.dfoId,
      _key: 'dfoDocuments',
    },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AddedNotificationAttachment',
          moduleName: 'NotificationItem',
        },
      }),
    },
  })

  const { createActionsStack } = useDocumentActions(props.projectId)

  const {
    projectActions,
    mutate: revalidateActions,
    isLoadingProjectActions,
  } = useProjectActions({
    key: {
      projectId: props.projectId,
      _key: 'projectActions',
    },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AddedNotificationAttachment',
          moduleName: 'NotificationItem',
        },
      }),
    },
  })

  const currentAction = projectActions?.[0]

  const deleteAddedNotificationAttachmentHandler = async () => {
    startLoading()

    try {
      await deleteProjectById(props.projectId)
      await revalidateProjects()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AddedNotificationAttachment',
          componentType: 'deleteAddedNotificationAttachmentHandler',
          moduleName: 'NotificationItem',
        },
      })(error)
    } finally {
      endLoading()
    }
  }

  useEffect(() => {
    return () => {
      endLoading()
    }
  }, [])

  const documentSet = dfoDocuments?.[DocumentsSetsType.ORGANIZATION_INFO_CHANGES][0]
  const documentInfo = documentSet?.documentTypeInfo?.[0]?.documents?.[0]

  const hasPermissions = documentSet?.permissions && [3, 7].includes(documentSet.permissions)

  const size = documentInfo?.size
  const date =
    documentInfo && documentInfo.updateDatetime && formatDate(new Date(documentInfo.updateDatetime))

  const downloadHandler = async ({ withSigned }: DownloadFileParams) => {
    if (!documentInfo || !documentSet) return

    const { versionId, name, id, extension } = documentInfo

    return await downloadDocumentById(
      {
        withSigned,
        projectId: props.projectId,
        name: `${name}.${extension}`,
        documentId: id,
        documentSetId: documentSet.documentSetId,
        versionId,
      },
      'save',
    )
  }

  const handleDownloadWithToast = (fileParams: DownloadFileParams) => async () => {
    handleCloseTooltip()
    await withDownloadToastPromise(downloadHandler(fileParams))
  }

  const uiActionsHandler = async () => {
    if (!currentAction) return

    const preparedAction = {
      ...currentAction,
      uiActions: currentAction.uiActions.map((action) =>
        action === mapOfDocumentActions.SIGN
          ? mapOfDocumentActions.SIGN_NOTIFICATION_CUSTOM
          : action,
      ),
    }

    const onStackSuccess = async () => {
      await Promise.all([revalidateActions(), revalidateProjects(), revalidateDfoDocuments()])
    }

    try {
      const actionsForStack = fromActionToActionsStack(preparedAction)

      await createActionsStack(actionsForStack).runStack()
      await onStackSuccess()
    } catch {}
  }

  const uiActionProp =
    (currentAction &&
      !isLoadingProjectActions && {
        displayName: currentAction.displayName,
        handler: uiActionsHandler,
        disabled: !currentAction.enable,
      }) ||
    undefined

  const handleDownloadAttachment = () => {
    if (!isNullOrUndefined(documentInfo) && !documentInfo.isEditable) return handleOpenTooltip

    return handleDownloadWithToast({ withSigned: false })
  }

  const isLoadingRender = isLoading || !projectActions || props.loading

  if (isLoadingRender) return <LoadingAttachment loading />

  return (
    <NotificationAttachment
      {...props}
      isActiveActions={tooltipIsOpen}
      date={date}
      uiAction={uiActionProp}
      fileInfo={
        documentInfo
          ? `${documentInfo?.extension.toUpperCase()} · ${size ? convertBytes(size) : ''}`
          : ''
      }
      actions={(actionsContainerClassName) => (
        <>
          <div className={actionsContainerClassName}>
            <Tooltip
              popoverClassName={styles.tooltip__popover}
              contentClassName={styles.tooltip__content}
              arrowClassName={styles.tooltip__arrow}
              targetRef={targetTooltipRef}
              open={tooltipIsOpen}
              position={'bottom-start'}
              content={
                <DownloadDocumentTooltipContent
                  handleDownloadFile={handleDownloadWithToast({ withSigned: false })}
                  handleDownloadFileWithStamps={handleDownloadWithToast({ withSigned: true })}
                />
              }
            >
              <IconButton
                geometry={'round'}
                size={'s'}
                view={'plain'}
                icon={{
                  src: DownloadAttachmentIcon,
                }}
                onClick={handleDownloadAttachment()}
              />
            </Tooltip>

            {hasPermissions && (
              <IconButton
                geometry={'round'}
                size={'s'}
                view={'plain'}
                icon={{
                  noCurrentColorSvgFill: true,
                  src: RemoveAttachmentIcon,
                }}
                onClick={deleteAddedNotificationAttachmentHandler}
              />
            )}
          </div>
        </>
      )}
    />
  )
}

export default memo(AddedNotificationAttachment)
