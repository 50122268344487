import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForAAConnectionReorganizationImplementingForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': PropertiesPropsBasicAdapters.getInvestorPartnerAdapter(objectFromPropertyState),
  }
}

export { generatePropertiesObjectForAAConnectionReorganizationImplementingForm }
