import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fifthSectionValidationMap: FormValuesValidationSection<
  keyof AdditionalAgreementOnTerminationFfSZPKFormValues['5'],
  AdditionalAgreementOnTerminationFfSZPKFormValues
> = {
  ruleLinkAgreementTerminationExtra: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 200),
  },
}

export { fifthSectionValidationMap }
