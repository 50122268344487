import React, { FC, memo, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import Loader from '@components/Loader'
import IconButton from '@components/NewDesign/IconButton'
import Sidebar, { SidebarComponentProps } from '@components/NewDesign/Sidebar'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import SidebarEventItem from '@components/Sidebars/Events/Item'
import { useEventsSidebar } from '@components/Sidebars/Events/store'
import SortSmallIcon from '@icons/SortSmallIcon.svg'

import styles from './Events.module.scss'

//Sidebar событий по проекту

export type EventsSidebarProps = Omit<SidebarComponentProps, 'dataTestId'> &
  PopupProps & {
    projectId: string
  }

const EventsSidebar: FC<EventsSidebarProps> = ({ isOpen, onClose, projectId }) => {
  const { eventsIsEmpty, events, eventIsLoading, filtersFormInstance } = useEventsSidebar(projectId)

  const allFilters = filtersFormInstance.watch()

  const { state, handlers, bindHandlersRef } = useManualTooltipControl()

  const headerAddons = useMemo(() => {
    const tooltipContent = (
      <div className={styles.eventsFilters}>
        {Object.entries(allFilters).map(([dfoType, dfoInfo]) => {
          return (
            <ControlledCheckbox
              key={dfoType}
              control={filtersFormInstance.control}
              name={`${dfoType}.value`}
              checkBoxProps={{
                label: dfoInfo.name,
                contentClassName: styles.eventsFilters__checkboxContent,
                labelTypographyProps: {
                  color: 'text-base-secondary',
                },
                dataTestId: `EventsSidebar-${dfoType}-checkbox`,
              }}
            />
          )
        })}
      </div>
    )

    if (!Object.keys(allFilters).length) return null

    return (
      <Tooltip
        targetRef={state.targetTooltipRef}
        open={state.tooltipIsOpen}
        content={tooltipContent}
        contentClassName={styles.eventsFilters__content}
        targetClassName={styles.eventsFilters__target}
        trigger={'click'}
        position={'bottom-end'}
        fallbackPlacements={['left']}
        arrowClassName={styles.eventsFilters__arrow}
        zIndex={51}
      >
        <IconButton
          geometry={'square'}
          view={'basic'}
          size={'s'}
          dataTestId="EventsSidebar-filterTooltip-button"
          icon={{ src: SortSmallIcon }}
          onClick={handlers.handleToggleTooltip}
        />
      </Tooltip>
    )
  }, [
    allFilters,
    eventsIsEmpty,
    filtersFormInstance.control,
    handlers,
    state.targetTooltipRef,
    state.tooltipIsOpen,
  ])

  return (
    <Sidebar
      headerClassName={styles.eventsFilters__header}
      drawerClassName={styles.eventsFilters__sidebar}
      contentClassName={styles.eventsFilters__sidebarContent}
      title={'История событий'}
      headerAddons={headerAddons}
      isOpen={isOpen}
      dataTestId="EventsSidebar-sidebar"
      closeButtonDataTestId="EventsSidebar-modal-closeButton"
      ref={bindHandlersRef.bindRefOfClickableOutsideZone}
      onClose={onClose}
    >
      <Loader loading={eventIsLoading}>
        {!eventsIsEmpty ? (
          events?.map((event) => <SidebarEventItem key={event.id} {...event} />)
        ) : (
          <Typography.Body
            className={styles.eventsFilters__empty}
            color={'text-base-secondary'}
            variant={'bodySMedium'}
          >
            Событий по проекту не найдено
          </Typography.Body>
        )}
      </Loader>
    </Sidebar>
  )
}

export default memo(EventsSidebar)
