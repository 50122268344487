import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { type PopupProps, usePopupManager } from 'react-popup-manager'

import Alert from '@components/Alert'
import type { ButtonProps } from '@components/NewDesign/Button/types'
import IconButton from '@components/NewDesign/IconButton'
import Modal from '@components/NewDesign/Modal'
import ChooseSolicitationStep from '@components/NewDesignedModals/AddSolicitationMembersModal/Steps/ChooseSoliciation/ChooseSoliciation'
import SubsidyStep from '@components/NewDesignedModals/CreateSubsidyModal/SubsidyStep'
import { mapOfDfoTypeGroupByStatementType } from '@components/NewDesignedModals/CreateSubsidyModal/SubsidyStep/const'
import { getOrganizationTypeMembers } from '@components/Projects/[id]/helpers'
import { NewDfosType, RolesTypes } from '@constants/types'
import { MainToastManager } from '@helpers/ToastManager'
import { useCreateDfo } from '@hooks/new/dfo/useCreateDfo'
import { useDfoTypes } from '@hooks/new/swr/useDfoTypes'
import { useProject } from '@hooks/new/swr/useProject'
import { useProjectMembers } from '@hooks/new/swr/useProjectMembers'
import backArrowIcon from '@icons/BackArrow.svg'
import { type TAllDfoTypes, CreateDfoAttributesNames } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './CreateSubsidy.module.scss'

export type CreateSubsidyModalProps = Required<PopupProps> & {
  projectId: string
}

export type StatementFormFieldProps = 'taxRefund' | 'subsidy'

export interface ModalFormValues {
  typeOfStatement: StatementFormFieldProps
  typeOfDfo: string
  typeOfBudget: 'FEDERAL' | 'REGIONAL' | 'LOCAL'

  solicitationMemberId: string
}

type StateStepProps = 'subsidyStep' | 'solicitationMember'

const useCreateSubsidyModal = (projectId: string) => {
  const popupManager = usePopupManager()

  const handleOpenCreateSubsidy = () => {
    popupManager.open(CreateSubsidyModal, { projectId, onClose: () => null })
  }

  return { handleOpenCreateSubsidy }
}

const CreateSubsidyModal: FC<CreateSubsidyModalProps> = ({ projectId, isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState<StateStepProps>('subsidyStep')

  const { handleCreateDfo } = useCreateDfo(projectId)

  const { project } = useProject({
    key: {
      projectId,
      _key: 'project',
    },
    config: {
      isPaused: () => !projectId,
    },
  })

  const { projectMembers } = useProjectMembers({
    key: {
      projectId,
      _key: 'projectMembers',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'CreateSubsidyModal',
          componentType: 'modal',
        },
      }),
    },
  })

  const { dfoTypes } = useDfoTypes({ key: { _key: 'dfoTypes' } })

  const modalFormState = useForm<ModalFormValues>({
    defaultValues: {
      typeOfStatement: 'subsidy',
      typeOfDfo: NewDfosType.CLAIM_SUBSIDY_PERMIT_BUILDING_APARTMENTS,
      typeOfBudget: !project?.isFederal ? 'REGIONAL' : 'FEDERAL',
      solicitationMemberId: '',
    },
  })

  const solicitationMemberId = modalFormState.watch('solicitationMemberId')
  const typeOfChosenDfo = modalFormState.watch('typeOfDfo')
  const typeOfStatement = modalFormState.watch('typeOfStatement')
  const typeOfBudget = modalFormState.watch('typeOfBudget')

  const isSubsidyStep = currentStep === 'subsidyStep'

  const onlyMerMember = useMemo(() => {
    if (!projectMembers) return

    return getOrganizationTypeMembers(RolesTypes.MER, projectMembers)
  }, [projectMembers])

  const onlyOIVsMembers = useMemo(() => {
    if (!projectMembers) return

    return getOrganizationTypeMembers(RolesTypes.OIV, projectMembers)
  }, [projectMembers])

  const onlyOMSUMembers = useMemo(() => {
    if (!projectMembers) return

    return getOrganizationTypeMembers(RolesTypes.OMSU, projectMembers)
  }, [projectMembers])

  const currentPossibleSolicitationMembers =
    typeOfBudget === 'REGIONAL' ? onlyOIVsMembers : onlyOMSUMembers

  const preparedDfoTypesForSubcidyStep = useMemo(() => {
    if (!dfoTypes) return

    return Object.entries(dfoTypes)
      .filter(([dfoType]) => dfoType.startsWith(mapOfDfoTypeGroupByStatementType[typeOfStatement]))
      .sort()
      .map(([dfoType, dfoObj]) => {
        return {
          name: dfoObj.dfoTitle,
          value: dfoType,
        }
      })
  }, [dfoTypes, typeOfStatement])

  const handleChangeStep = (value: StateStepProps) => {
    setCurrentStep(value)
  }

  const handleApplyChanges = useCallback(async () => {
    const allFormValues = modalFormState.getValues()

    const subsidyDfoParameters = [
      {
        name: 'consider' as CreateDfoAttributesNames,
        value: allFormValues.solicitationMemberId,
      },
      ...(allFormValues.typeOfStatement === 'subsidy'
        ? [
            {
              name: 'subsidyBudgetType' as CreateDfoAttributesNames,
              value: allFormValues.typeOfBudget,
            },
          ]
        : []),
    ]

    try {
      await handleCreateDfo(allFormValues.typeOfDfo as TAllDfoTypes, subsidyDfoParameters)()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          type: allFormValues.typeOfDfo,
          dfoParameters: subsidyDfoParameters,
        },
        componentInfo: {
          componentName: 'CreateSubsidyModal',
          componentType: 'handleApplyChanges',
        },
      })(error)
    }
  }, [handleCreateDfo, modalFormState])

  const currentContinueHandler = useCallback(() => {
    const formValues = modalFormState.getValues()

    const currentStatementType = formValues.typeOfStatement
    //Если налоговый вычет, то сразу переходим к созданию ОДО
    if (currentStatementType === 'taxRefund' && onlyMerMember) {
      modalFormState.setValue('solicitationMemberId', onlyMerMember[0].organizationId)

      return handleApplyChanges()
    }

    const currentBudget = formValues.typeOfBudget

    const isRegionalBudget = currentBudget === 'REGIONAL'
    const isLocalBudget = currentBudget === 'LOCAL'

    const currentReturnCondition = (() => {
      if (isRegionalBudget) return !onlyOIVsMembers || !onlyOIVsMembers.length

      if (isLocalBudget) return !onlyOMSUMembers || !onlyOMSUMembers.length

      return !onlyMerMember
    })()

    if (currentReturnCondition) {
      MainToastManager.toast(
        <Alert transparent variant="error">
          Не найден согласующий орган
        </Alert>,
      )
    }

    //Если участников больше 1, то необходим выбор
    if (isRegionalBudget && onlyOIVsMembers && onlyOIVsMembers.length > 1) {
      modalFormState.setValue('solicitationMemberId', onlyOIVsMembers[0].organizationId)

      handleChangeStep('solicitationMember')

      return
    }

    //Если участников больше 1, то необходим выбор
    if (isLocalBudget && onlyOMSUMembers && onlyOMSUMembers.length > 1) {
      modalFormState.setValue('solicitationMemberId', onlyOMSUMembers[0].organizationId)

      handleChangeStep('solicitationMember')

      return
    }

    //Иначе сразу выбираем участника и устанавливаем его id, без смены шага
    const currentApplyOnlyParticipantValue = (() => {
      if (isRegionalBudget) return onlyOIVsMembers?.[0].organizationId || ''

      if (isLocalBudget) return onlyOMSUMembers?.[0].organizationId || ''

      return onlyMerMember?.[0]?.organizationId || ''
    })()

    modalFormState.setValue('solicitationMemberId', currentApplyOnlyParticipantValue)

    return handleApplyChanges()
  }, [handleApplyChanges, modalFormState, onlyMerMember, onlyOIVsMembers, onlyOMSUMembers])

  useEffect(() => {
    if (!preparedDfoTypesForSubcidyStep) return

    modalFormState.setValue('typeOfDfo', preparedDfoTypesForSubcidyStep[0]?.value)
    modalFormState.setValue('typeOfBudget', 'FEDERAL')
  }, [modalFormState, preparedDfoTypesForSubcidyStep])

  const currentActions = useMemo(() => {
    return isSubsidyStep
      ? ([
          {
            view: 'gray',
            children: 'Отмена',
            dataTestId: 'CreateSubsidyModal-cancel-button',
            onClick: onClose,
          },
          {
            children: 'Продолжить',
            disabled: !dfoTypes || !typeOfChosenDfo,
            dataTestId: 'CreateSubsidyModal-continue-button',
            onClick: currentContinueHandler,
          },
        ] as ButtonProps[])
      : ([
          {
            view: 'gray',
            children: 'Отмена',
            dataTestId: 'CreateSubsidyModal-cancel-button',
            onClick: onClose,
          },
          {
            children: 'Продолжить',
            disabled: !solicitationMemberId,
            dataTestId: 'CreateSubsidyModal-apply-button',
            onClick: handleApplyChanges,
          },
        ] as ButtonProps[])
  }, [
    currentContinueHandler,
    dfoTypes,
    handleApplyChanges,
    isSubsidyStep,
    onClose,
    solicitationMemberId,
    typeOfChosenDfo,
  ])

  const currentModalProps = useMemo(() => {
    return {
      title: isSubsidyStep ? (
        'Добавить заявление на возмещение затрат'
      ) : (
        <div className={styles.createSubsidyModal__header}>
          <IconButton
            className={styles.createSubsidyModal__backIcon}
            view={'basic'}
            color={'default'}
            geometry={'square'}
            size={'s'}
            dataTestId="CreateSubsidyModal-back-button"
            icon={{
              src: backArrowIcon,
              className: styles['createSubsidyModal__backIcon-icon'],
            }}
            onClick={() => handleChangeStep('subsidyStep')}
          />
          Кто будет согласовывать заявление?
        </div>
      ),

      actions: currentActions as ButtonProps[],
    }
  }, [currentActions, isSubsidyStep])

  return (
    <Modal.Action
      isOpen={isOpen}
      dataTestId="CreateSubsidyModal-modal"
      closeButtonDataTestId="CreateSubsidyModal-modal-closeButton"
      onClose={onClose}
      {...currentModalProps}
    >
      {isSubsidyStep ? (
        <SubsidyStep
          isFederal={project?.isFederal}
          typeOfStatement={typeOfStatement}
          dfoToCreate={preparedDfoTypesForSubcidyStep}
          formInstance={modalFormState}
          localBudgetRenderCondition={!!(onlyOMSUMembers && onlyOMSUMembers.length)}
        />
      ) : (
        <ChooseSolicitationStep
          nameOfSolicitationControl={'solicitationMemberId'}
          formInstance={modalFormState as any}
          possibleSolicitationMembers={currentPossibleSolicitationMembers}
        />
      )}
    </Modal.Action>
  )
}

export { useCreateSubsidyModal }

export default memo(CreateSubsidyModal)
