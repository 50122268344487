import React, { FC, ReactNode } from 'react'

import HeaderLayout, { HeaderLayoutProps } from '../Header'

import styles from './Main.module.scss'

interface MainLayoutProps extends HeaderLayoutProps {
  mapOfMenu?: ReactNode[]
}

const MainLayout: FC<MainLayoutProps> = ({
  headerContentContainer,
  headerContentRef,
  children,
}) => {
  return (
    <HeaderLayout
      headerContentRef={headerContentRef}
      headerContentContainer={headerContentContainer}
    >
      <section className={styles.mainLayout}>{children}</section>
    </HeaderLayout>
  )
}

export default MainLayout
