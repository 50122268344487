import { generatePropertiesObjectForFinancialModel } from '@components/Forms/FinancialModelForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForFinancialModel } from '@components/Forms/FinancialModelForm/adapters/RHF.adapter'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'

const useFinancialModelAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): FinancialModelFormValues => generateRHFObjectForFinancialModel(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> => generatePropertiesObjectForFinancialModel(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useFinancialModelAdapters }
