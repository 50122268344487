import React from 'react'
import { useFormContext } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { aAgreementOnCreationOtherEstateObjectsBlockValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import AAgreementOnCreationOtherEstateObjectsMunicipalityForm from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/3/MunicipalityForm'
import { AAgreementOnCreationOtherEstateObjectsMunicipalityPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/3/types'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'

const Third = () => {
  const formInstance = useFormContext<AAgreementOnCreationOtherEstateObjectsFormValues>()

  const { fields: municipalities } = useFieldArraySubscribableControl<
    AAgreementOnCreationOtherEstateObjectsFormValues,
    AAgreementOnCreationOtherEstateObjectsMunicipalityPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: aAgreementOnCreationOtherEstateObjectsBlockValues['3'].municipalities,
    keyName: 'keyNameId',
    watcher: AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      {municipalities.map((municipality, index) => (
        <Row key={municipality.id}>
          <AAgreementOnCreationOtherEstateObjectsMunicipalityForm
            formInstance={formInstance}
            formName={
              `${aAgreementOnCreationOtherEstateObjectsBlockValues['3'].municipalities}.${index}` as const
            }
          />
          {index !== municipalities.length - 1 && <Border />}
        </Row>
      ))}
    </Container>
  )
}

export default Third
