import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'

const mapOfIndicatorsOfUnfulfilledAgreementReportMenu = [
  {
    id: '1',
    title: 'Индикатор исполнения объема капиталовложений',
  },
  {
    id: '2',
    title: 'Индикатор исполнения объема капитальных вложений',
  },
  {
    id: '3',
    title: 'Индикатор создания рабочих мест',
  },
  {
    id: '4',
    title: 'Индикатор завершения этапов проекта',
  },
  {
    id: '5',
    title: 'Индикатор объема планируемых к возмещению затрат',
  },
]

const defaultIndicatorsOfUnfulfilledAgreementReportFormValues: IndicatorsOfUnfulfilledAgreementReportFormValues =
  {
    '1': {
      investorCapitalMadeOnTimeIndicator: null,
      investorCapital: '',
      investorCapitalVAT: '',
      factInvestorCapitalVatIndicator: '',
      factInvestorCapitalIndicator: '',
      madeInvestorCapitalDate: '',
      monitoringReportDate: '',
    },
    '2': {
      totalCapital: '',
      factTotalCapitalIndicator: '',
    },
    '3': {
      newWorkPlaces: null,
      factNewWorkPlace: null,
    },
    '4': {
      stepsDeadline: [],
    },
    '5': {
      stepsCompensation: [],
    },
  }

const indicatorsOfUnfulfilledAgreementReportBlockValues = {
  '1': {
    investorCapitalMadeOnTimeIndicator: '1.investorCapitalMadeOnTimeIndicator',
    investorCapital: '1.investorCapital',
    investorCapitalVAT: '1.investorCapitalVAT',
    factInvestorCapitalVatIndicator: '1.factInvestorCapitalVatIndicator',
    factInvestorCapitalIndicator: '1.factInvestorCapitalIndicator',
    madeInvestorCapitalDate: '1.madeInvestorCapitalDate',
    monitoringReportDate: '1.monitoringReportDate',
  },
  '2': {
    totalCapital: '2.totalCapital',
    factTotalCapitalIndicator: '2.factTotalCapitalIndicator',
  },
  '3': {
    newWorkPlaces: '3.newWorkPlaces',
    factNewWorkPlace: '3.factNewWorkPlace',
  },
  '4': {
    stepsDeadline: '4.stepsDeadline',
  },
  '5': {
    stepsCompensation: '5.stepsCompensation',
  },
} as const

export {
  defaultIndicatorsOfUnfulfilledAgreementReportFormValues,
  indicatorsOfUnfulfilledAgreementReportBlockValues,
  mapOfIndicatorsOfUnfulfilledAgreementReportMenu,
}
