import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NoticeOfRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof NoticeOfRejectConcludeSZPKFormValues['4'],
  NoticeOfRejectConcludeSZPKFormValues
> = {
  ruleLinkStatementRepeat: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  statementRejectReason: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 5000),
  },
  ruleLinkStatementReject: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { fourthSectionValidationMap }
