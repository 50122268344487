import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'

interface IndicatorsOfUnfulfilledAgreementReportFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fourth
}

const FormTemplateForms: IndicatorsOfUnfulfilledAgreementReportFormsProps = () => null

FormTemplateForms.First = First
FormTemplateForms.Second = Second
FormTemplateForms.Third = Third
FormTemplateForms.Fourth = Fourth
FormTemplateForms.Fifth = Fifth

export default FormTemplateForms
