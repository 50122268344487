import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues['4'],
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
