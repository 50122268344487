import React, { FC, memo, ReactNode } from 'react'

import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Typography from '@components/NewDesign/Typography'
import { isString } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './StatusView.module.scss'

export interface StatusViewProps {
  isLoading: boolean
  title?: ReactNode | string
  description?: ReactNode | string
  actions?: ButtonProps[]
}

const StatusView: FC<StatusViewProps> = ({ isLoading, description, title, actions }) => {
  const currentTitle =
    title && isString(title) ? (
      <Typography.Headline
        className={styles.statusView__title}
        align={'center'}
        variant={'headlineH3'}
        as={'h3'}
      >
        {title}
      </Typography.Headline>
    ) : (
      title
    )

  const currentDescription =
    description && isString(description) ? (
      <Typography.Body
        align={'center'}
        className={styles.statusView__description}
        variant={'bodyMRegular'}
      >
        {description}
      </Typography.Body>
    ) : (
      <div className={cn(styles.statusView__description, styles['statusView__description--node'])}>
        {description}
      </div>
    )

  return (
    <div className={styles.statusView}>
      {isLoading && <Loader loading variant={'lite'} className={styles.statusView__loader} />}
      <div
        className={cn(styles.statusView__content, {
          [styles['statusView__content--loading']]: isLoading,
        })}
      >
        {currentTitle}
        {currentDescription}
      </div>
      {!!actions?.length && (
        <div className={styles.statusView__actions}>
          {actions.map((action, index) => {
            return <Button key={index} {...action} />
          })}
        </div>
      )}
    </div>
  )
}

export default memo(StatusView)
