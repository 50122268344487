import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { npaFormBlockValues } from '@components/Forms/NpaForm/const'
import { useNpaFormManager } from '@components/Forms/NpaForm/Manager'
import { NpaFormValues } from '@components/Forms/NpaForm/types'
import { NpaFieldsControlUpdateWatcher } from '@components/Forms/NpaForm/watcher'
import Button from '@components/NewDesign/Button'
import useAuthorizedPersonModal from '@components/NewDesignedModals/AddAuthorizedPersonModal/manager'
import Container from '@components/ReactBootstrap/Container'
import PersonAddIcon from '@icons/PersonAddIcon.svg'

const HeaderInfo = () => {
  const formInstance = useFormContext<NpaFormValues>()

  const {
    state: { blockViewIsValidating },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useNpaFormManager()

  const { handleOpenAddAuthorizedPersonModal } = useAuthorizedPersonModal()

  const { getSubscribableControlProps } = useFormComponentPresets({
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NpaFieldsControlUpdateWatcher,
  })

  const headerInfoErrorRenderCondition = !!formInstance.getFieldState('headerInfo')?.error

  const currentControlContainerColor = !headerInfoErrorRenderCondition ? 'primary' : 'negative'
  const currentControlIcon = !headerInfoErrorRenderCondition ? PersonAddIcon : undefined
  const currentIconType = !headerInfoErrorRenderCondition ? '' : 'error'

  const handleOpenModal = () => {
    const isFederalSZPK = formInstance.getValues(npaFormBlockValues.additionalFields.isFederal)

    handleOpenAddAuthorizedPersonModal({
      blockViewIsValidating,
      isFederalSZPK,
      formInstance,
      subscribableControl,
      name: 'headerInfo',
      watcher: NpaFieldsControlUpdateWatcher,
      onChangeValue: handleChangeValue,
    })
  }

  return (
    <Container className={'p-0'}>
      <SubscribableControl
        {...getSubscribableControlProps({
          path: npaFormBlockValues.headerInfo.investorHeaderName,
        })}
      >
        <ControlContainer
          color={currentControlContainerColor}
          title={'Укажите уполномоченное лицо организации'}
          additionalMainContent={
            headerInfoErrorRenderCondition && (
              <ControlContainerSubtitle>не заполнены обязательные поля</ControlContainerSubtitle>
            )
          }
          leadingNodeContent={
            <ControlContainerStatusIcon
              controlIcon={currentControlIcon}
              iconType={currentIconType}
            />
          }
          trailingNodeContent={
            <Button
              fixWidth
              view="tined"
              color={headerInfoErrorRenderCondition ? 'negative' : 'default'}
              onClick={handleOpenModal}
            >
              Выбрать
            </Button>
          }
        />
      </SubscribableControl>
    </Container>
  )
}

export default HeaderInfo
