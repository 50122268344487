import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import InclusionAgreementOfRelatedSZPKRegisterLayout from './Layout'
import InclusionAgreementOfRelatedSZPKRegisterModalManager from './Manager'
import InclusionAgreementOfRelatedSZPKRegisterMenu from './Menu'
import InclusionAgreementOfRelatedSZPKRegisterStatementForm from './Statement'

interface ICreateInclusionAgreementOfRelatedSZPKRegister extends FC {
  Layout: typeof InclusionAgreementOfRelatedSZPKRegisterLayout
  Menu: typeof InclusionAgreementOfRelatedSZPKRegisterMenu
  StatementForm: typeof InclusionAgreementOfRelatedSZPKRegisterStatementForm
  Manager: typeof InclusionAgreementOfRelatedSZPKRegisterModalManager
}

const useInclusionAgreementOfRelatedSZPKRegisterControl = () => {
  const popupManager = usePopupManager()

  const openInclusionAgreementOfRelatedSZPKRegisterModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(InclusionAgreementOfRelatedSZPKRegisterLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openInclusionAgreementOfRelatedSZPKRegisterModal }
}

const InclusionAgreementOfRelatedSZPKRegister: ICreateInclusionAgreementOfRelatedSZPKRegister =
  () => null

InclusionAgreementOfRelatedSZPKRegister.Menu = InclusionAgreementOfRelatedSZPKRegisterMenu
InclusionAgreementOfRelatedSZPKRegister.Layout = InclusionAgreementOfRelatedSZPKRegisterLayout
InclusionAgreementOfRelatedSZPKRegister.StatementForm =
  InclusionAgreementOfRelatedSZPKRegisterStatementForm
InclusionAgreementOfRelatedSZPKRegister.Manager =
  InclusionAgreementOfRelatedSZPKRegisterModalManager

export { useInclusionAgreementOfRelatedSZPKRegisterControl }
export default InclusionAgreementOfRelatedSZPKRegister
