import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalManager from './Manager'
import AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenu from './Menu'
import AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStatementForm from './Statement'

interface ICreateAAgreementOnNonConclusionOrNonPerformanceOfConcessionA extends FC {
  LayoutWithFormProvider: typeof AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutWithFormProvider
  Menu: typeof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenu
  StatementForm: typeof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStatementForm
  Manager: typeof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalManager
  MenuManager: typeof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuManager
}

const useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAControl = () => {
  const popupManager = usePopupManager()

  const openAAgreementOnNonConclusionOrNonPerformanceOfConcessionAModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutWithFormProvider,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openAAgreementOnNonConclusionOrNonPerformanceOfConcessionAModal }
}

const AAgreementOnNonConclusionOrNonPerformanceOfConcessionA: ICreateAAgreementOnNonConclusionOrNonPerformanceOfConcessionA =
  () => null

AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.Menu =
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenu
AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.LayoutWithFormProvider =
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayoutWithFormProvider
AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.StatementForm =
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStatementForm
AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.Manager =
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalManager
AAgreementOnNonConclusionOrNonPerformanceOfConcessionA.MenuManager =
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuManager

export { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAControl }
export default AAgreementOnNonConclusionOrNonPerformanceOfConcessionA
