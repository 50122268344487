import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'

export const InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const InformationOfExecutionAgreementEventsFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<InformationOfExecutionAgreementEventsFormValues>()
export const InformationOfExecutionAgreementEventsFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
