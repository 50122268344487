import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { summaryReportOfMonitoringResultsBlockValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/const'
import { SummaryReportOfMonitoringResultsEconomicSpheresPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/4/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import SummaryReportOfMonitoringResultsEconomicSphereCollapse from './EconomicSphereCollapse'

const Fourth = () => {
  const formInstance = useFormContext<SummaryReportOfMonitoringResultsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleRemoveItemFromList, handleAddItemToListWithOutValue },
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
  })

  const { fields: economicSphereNames } = useFieldArraySubscribableControl<
    SummaryReportOfMonitoringResultsFormValues,
    SummaryReportOfMonitoringResultsEconomicSpheresPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: summaryReportOfMonitoringResultsBlockValues['4'].economicSpheresFederalReport,
    keyName: 'keyNameId',
    watcher: SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const handleAddSphere = async () => {
    await handleAddItemToListWithOutValue?.(
      summaryReportOfMonitoringResultsBlockValues['4'].economicSpheresFederalReport,
    )

    if (!economicSphereNames.length) {
      formInstance.clearErrors(
        summaryReportOfMonitoringResultsBlockValues['4'].economicSpheresFederalReport,
      )
    }
  }
  const handleRemoveSphere = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${summaryReportOfMonitoringResultsBlockValues['4'].economicSpheresFederalReport}.${index}`,
      summaryReportOfMonitoringResultsBlockValues['4'].economicSpheresFederalReport,
    )
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['4'].economicSpheresFederalReport,
          })}
        >
          <FlipperList list={economicSphereNames}>
            {economicSphereNames.map((sphere, index) => {
              const formName =
                `${summaryReportOfMonitoringResultsBlockValues['4'].economicSpheresFederalReport}.${index}` as const

              return (
                <SummaryReportOfMonitoringResultsEconomicSphereCollapse
                  expandedRenderMode={false}
                  parentId={sphere.id}
                  objectId={sphere.id}
                  key={sphere.id}
                  indexOfObject={index}
                  blockViewIsValidating={blockViewIsValidating}
                  editMode={editMode}
                  formInstance={formInstance}
                  name={formName}
                  onDeleteObject={handleRemoveSphere(index)}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddSphere}>
              {({ isLoading, wrappedPromise }) => (
                <FieldView.ActionButton
                  disabled={isLoading}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  onClick={wrappedPromise}
                >
                  Добавить сферу реализации
                </FieldView.ActionButton>
              )}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Fourth
