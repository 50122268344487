import { FC } from 'react'

import One from './1'
import Two from './2'
import Three from './3'
import Four from './4'
import Five from './5'
import Six from './6'
import Seven from './7'
import Eight from './8'
import Nine from './9'
import Ten from './10'
import Eleven from './11'
import Thirteen from './13'
import Fourteen from './14'
import Fifteen from './15'
import Sixteen from './16'

interface ProjectSZPKFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Three: typeof Three
  Four: typeof Four
  Five: typeof Five
  Six: typeof Six
  Seven: typeof Seven
  Eight: typeof Eight
  Nine: typeof Nine
  Ten: typeof Ten
  Eleven: typeof Eleven
  Thirteen: typeof Thirteen
  Fourteen: typeof Fourteen
  Fifteen: typeof Fifteen
  Sixteen: typeof Sixteen
}

const ProjectSZPKForms: ProjectSZPKFormsProps = () => null

ProjectSZPKForms.One = One
ProjectSZPKForms.Two = Two
ProjectSZPKForms.Three = Three
ProjectSZPKForms.Four = Four
ProjectSZPKForms.Five = Five
ProjectSZPKForms.Six = Six
ProjectSZPKForms.Seven = Seven
ProjectSZPKForms.Eight = Eight
ProjectSZPKForms.Nine = Nine
ProjectSZPKForms.Ten = Ten
ProjectSZPKForms.Eleven = Eleven
ProjectSZPKForms.Thirteen = Thirteen
ProjectSZPKForms.Fourteen = Fourteen
ProjectSZPKForms.Fifteen = Fifteen
ProjectSZPKForms.Sixteen = Sixteen

export default ProjectSZPKForms
