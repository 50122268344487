import { FocusEvent } from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import InputSearchTree from './InputSearchTree'
import { InputSearchTreeProps, ISearchElementTree } from './types'

type ControlledInputSearchTree<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> &
  Pick<InputSearchTreeProps, 'onBlur' | 'onChange'> & {
    searchProps: Omit<InputSearchTreeProps, 'error' | 'ref'>
  }

export const ControlledInputSearchTree = <T extends FieldValues>(
  props: ControlledInputSearchTree<T>,
) => {
  const { onBlur, onChange, searchProps, ...controllerProps } = props

  const {
    field: { onChange: onFormChange, onBlur: onFormBlur, value, ref },
    fieldState: { error },
  } = useController(controllerProps)

  const mergedOnChange = (tree: ISearchElementTree) => {
    onFormChange(tree.value)

    onChange?.(tree)
  }

  const mergedOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event)
    }

    onFormBlur()
  }

  const mergedOnClear = () => {
    onFormChange('')

    onChange?.({
      displayValue: '',
      value: '',
    })
  }

  return (
    <InputSearchTree
      {...searchProps}
      error={error}
      defaultSearchValue={value}
      ref={ref}
      onBlur={mergedOnBlur}
      onChange={mergedOnChange}
      onClear={mergedOnClear}
    />
  )
}
