import React, { FC } from 'react'

import { WebFormAddonBaseProps } from '@components/Attachments/WebForm/Addon/types'
import styles from '@components/Attachments/WebForm/Addon/WebFormAddon.module.scss'
import { useWebFormAPIContext } from '@components/Attachments/WebForm/APIProvider'
import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import NpaForm from '@components/Forms/NpaForm'
import NpaMunicipalForm from '@components/Forms/NpaMunicipalForm'
import NpaSolicitationInclusionForm from '@components/Forms/NpaSolicitationInclusionForm'
import Loader from '@components/Loader'
import { LoaderProps } from '@components/Loader/Loader'
import { WebFormTypes } from '@constants/types'
import { documentsWebFormStatesMap } from '@services/Documents/documents.const'
import { NpaFormTypes } from '@services/NPA/NPA.entity'
import { useSWRConfig } from 'swr'

interface NpaAddonProps extends NpaFormLayoutProps {
  isLoading: boolean
}

const renderInvestorAddonMap = ({ isLoading, ...props }: NpaAddonProps) => {
  const baseLoadingProps: LoaderProps = {
    loading: isLoading,
    className: styles.webFormAddon__loader,
    variant: 'l',
  }

  return {
    [WebFormTypes.NPA_LIST]: (
      <Loader {...baseLoadingProps}>
        <NpaForm {...props} />
      </Loader>
    ),
    [WebFormTypes.SOLICITATION_INCLUSION_NPA]: (
      <Loader {...baseLoadingProps}>
        <NpaSolicitationInclusionForm {...props} />
      </Loader>
    ),
    [WebFormTypes.NPA_LIST_MUNICIPAL]: (
      <Loader {...baseLoadingProps}>
        <NpaMunicipalForm {...props} />
      </Loader>
    ),
  }
}

const WebFormAddonInvestorController: FC<WebFormAddonBaseProps> = ({
  permissions,
  projectId,
  currentDfoId,
  documentType,
  documentProps,
  children,
}) => {
  const { forms, documentInfoFromDict } = documentProps || {}
  const { isRequired } = documentInfoFromDict

  const { mutate } = useSWRConfig()

  const formInfo = forms?.[0]

  const { isWebFormReadEditPermission, isWebFormReadAddSignPermission } =
    useWebFormPermissions(permissions)

  const isLoading = formInfo?.state === documentsWebFormStatesMap.NEW

  const isShowAddon = isWebFormReadAddSignPermission || isWebFormReadEditPermission

  const {
    handlers: { handleDeleteWebFormDocument },
  } = useWebFormAPIContext()

  const handleUpdateDocumentSets = async () => {
    await mutate({
      _key: 'document-sets',
      projectId,
      dfoId: currentDfoId,
    })
  }

  const handleDeleteCreatedAddonOnClick = async () => {
    if (!formInfo || !formInfo?.id) return

    await handleDeleteWebFormDocument?.({
      formId: formInfo.id,
      onSuccess: handleUpdateDocumentSets,
    })
  }

  if (!formInfo || !formInfo.id || !isShowAddon) return <>{children}</>

  return (
    <>
      {renderInvestorAddonMap({
        isLoading,
        documentType: documentType as NpaFormTypes,
        formId: formInfo.id,
        projectId,
        onRemoveNpaForm: !isRequired ? handleDeleteCreatedAddonOnClick : undefined,
      })[documentType] || children}
    </>
  )
}

export default WebFormAddonInvestorController
