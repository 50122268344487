import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementForceCircumstancesBlockValues } from '@components/Forms/AAgreementForceCircumstancesForm/const'
import { useAAgreementForceCircumstancesManager } from '@components/Forms/AAgreementForceCircumstancesForm/Manager'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import { AAgreementForceCircumstancesFieldsControlUpdateWatcher } from '@components/Forms/AAgreementForceCircumstancesForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { sixthSectionAAgreementForceCircumstancesValidationMap } from './validation'

const Sixth = () => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementForceCircumstancesManager()

  const { getSubscribableControlProps, getInputProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAgreementForceCircumstancesFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title="Текущая дата реализации инвестиционного проекта"
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aAgreementForceCircumstancesBlockValues['6'].projectEndDateCommited,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: aAgreementForceCircumstancesBlockValues['6'].projectEndDateCommited,
                    calendarInputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title="Предельная дата реализации инвестиционного проекта"
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aAgreementForceCircumstancesBlockValues['6'].projectEndDate,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: aAgreementForceCircumstancesBlockValues['6'].projectEndDate,
                    rules: sixthSectionAAgreementForceCircumstancesValidationMap.projectEndDate,

                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['6'].projectEndDate,
                          ),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['6'].projectEndDate,
                          ),
                        0,
                      ),
                    onInputChange: () =>
                      setTimeout(
                        () =>
                          debouncedHandleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['6'].projectEndDate,
                          ),
                        0,
                      ),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title="Пункт соглашения, устанавливающий срок реализации инвест. проекта"
              titleClassName={'pb-4'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aAgreementForceCircumstancesBlockValues['6'].agreementLinkProjectPeriod,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: aAgreementForceCircumstancesBlockValues['6'].agreementLinkProjectPeriod,
                    rules:
                      sixthSectionAAgreementForceCircumstancesValidationMap.agreementLinkProjectPeriod,
                    inputProps: {},
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['6'].agreementLinkProjectPeriod,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(
                        () =>
                          debouncedHandleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['6'].agreementLinkProjectPeriod,
                          ),
                        0,
                      ),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Sixth
