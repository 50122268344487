import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AAgreementAccessionMunicipalLayout from './Layout'
import AAgreementAccessionMunicipalModalManager from './Manager'
import AAgreementAccessionMunicipalMenu from './Menu'
import AAgreementAccessionMunicipalStatementForm from './Statement'

interface IAAgreementAccessionMunicipal extends FC {
  Layout: typeof AAgreementAccessionMunicipalLayout
  Menu: typeof AAgreementAccessionMunicipalMenu
  StatementForm: typeof AAgreementAccessionMunicipalStatementForm
  Manager: typeof AAgreementAccessionMunicipalModalManager
}

const useAAgreementAccessionMunicipalControl = () => {
  const popupManager = usePopupManager()

  const openAAgreementAccessionMunicipalModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AAgreementAccessionMunicipalLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAAgreementAccessionMunicipalModal }
}

const AAgreementAccessionMunicipal: IAAgreementAccessionMunicipal = () => null

AAgreementAccessionMunicipal.Menu = AAgreementAccessionMunicipalMenu
AAgreementAccessionMunicipal.Layout = AAgreementAccessionMunicipalLayout
AAgreementAccessionMunicipal.StatementForm = AAgreementAccessionMunicipalStatementForm
AAgreementAccessionMunicipal.Manager = AAgreementAccessionMunicipalModalManager

export { useAAgreementAccessionMunicipalControl }
export default AAgreementAccessionMunicipal
