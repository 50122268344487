import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNoticeOfProjectRejectConcludeSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      documentReceiversDraft: {
        propertyId: objectFromPropertyState.documentReceiversDraft?.propertyId,
        type: objectFromPropertyState.documentReceiversDraft?.type,
        lastUpdateDt: objectFromPropertyState.documentReceiversDraft?.lastUpdateDt,
      },
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: {
        propertyId: objectFromPropertyState?.investorFullNameGenitiveDraft?.propertyId,
        type: objectFromPropertyState?.investorFullNameGenitiveDraft?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameGenitiveDraft?.lastUpdateDt,
      },
      investorFullNameInstrumental: {
        propertyId: objectFromPropertyState?.investorFullNameInstrumentalDraft?.propertyId,
        type: objectFromPropertyState?.investorFullNameInstrumentalDraft?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameInstrumentalDraft?.lastUpdateDt,
      },
    },
    '3': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
      ruleLinkStatementRejectDraft: {
        propertyId: objectFromPropertyState.ruleLinkStatementRejectDraft?.propertyId,
        type: objectFromPropertyState.ruleLinkStatementRejectDraft?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStatementRejectDraft?.lastUpdateDt,
      },
      ruleLinkStatementRepeatDraft: {
        propertyId: objectFromPropertyState.ruleLinkStatementRepeatDraft?.propertyId,
        type: objectFromPropertyState.ruleLinkStatementRepeatDraft?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStatementRepeatDraft?.lastUpdateDt,
      },
      statementRejectReasonDraft: {
        propertyId: objectFromPropertyState.statementRejectReasonDraft?.propertyId,
        type: objectFromPropertyState.statementRejectReasonDraft?.type,
        lastUpdateDt: objectFromPropertyState.statementRejectReasonDraft?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNoticeOfProjectRejectConcludeSZPKForm }
