import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import objectItemStyles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFields from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/Forms/GeneralFields'
import { compensationStatementExpenseSubTypeValidationMap } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/validation'
import { ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { useBooleanState } from '@hooks/useBooleanState'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

interface ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectCreditProps {
  id: string
  title: string
  name: ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
  onRemove?: VoidFunction
}

const ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectCredit: FC<
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectCreditProps
> = ({ id, title, name, formInstance, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getSubscribableControlProps, getInputProps, getTextareaProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
    })

  const {
    booleanState: isShowObjectItemContent,
    reverseBooleanState: toggleShowObjectItemContent,
  } = useBooleanState(true)

  const handleRemoveObject = async () => {
    await onRemove?.()
  }

  return (
    <FlippedWithCollapse flipId={id}>
      <div className={objectItemStyles.objectItem__header}>
        <ObjectStepWrapperHeaderNode
          buttonProps={{
            onClick: toggleShowObjectItemContent,
            trailingIcon: {
              className: cn({
                [objectItemStyles['objectItem__header-button__icon--active']]:
                  isShowObjectItemContent,
              }),
            },
          }}
        >
          {title}
        </ObjectStepWrapperHeaderNode>

        {editMode && (
          <AsyncWrapper promise={handleRemoveObject}>
            {({ isLoading, wrappedPromise }) => (
              <Button
                disabled={isLoading}
                variant={'buttonSMedium'}
                size={'2xs'}
                color={'negative'}
                view={'plain'}
                loaderProps={{
                  loading: isLoading,
                  placement: 'trailing',
                  variant: 'lite',
                }}
                leadingIcon={{
                  noCurrentColorSvgFill: true,
                  src: DeleteIcon,
                }}
                onClick={wrappedPromise}
              >
                Удалить
              </Button>
            )}
          </AsyncWrapper>
        )}
      </div>
      <div className={objectItemStyles.objectItem__content}>
        <CollapseWrapper isExpanded={isShowObjectItemContent}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationObjectCreditorName`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${name}.compensationObjectCreditorName`,
                      rules:
                        compensationStatementExpenseSubTypeValidationMap.compensationObjectCreditorName,
                      textareaProps: {
                        label: 'Наименование кредитора организации/ ФИО кредитора физического лица',
                      },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${name}.compensationObjectCreditorName`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.compensationObjectCreditorName`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={8}
              secondColumnSize={4}
              title={'Номер договора'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.compensationObjectAgreementNumber`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${name}.compensationObjectAgreementNumber`,
                    rules:
                      compensationStatementExpenseSubTypeValidationMap.compensationObjectAgreementNumber,
                    inputProps: {},
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.compensationObjectAgreementNumber`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.compensationObjectAgreementNumber`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={8}
              secondColumnSize={4}
              title={'Дата договора'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.compensationObjectAgreementDate`,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.compensationObjectAgreementDate`,
                    rules:
                      compensationStatementExpenseSubTypeValidationMap.compensationObjectAgreementDate,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.compensationObjectAgreementDate`)
                      }, 0),
                    onCalendarChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.compensationObjectAgreementDate`)
                      }, 0),
                    onInputChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.compensationObjectAgreementDate`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFields
              formInstance={formInstance}
              name={name}
            />
          </Stack>
        </CollapseWrapper>
      </div>
    </FlippedWithCollapse>
  )
}

export default ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectCredit
