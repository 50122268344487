import { useCallback, useMemo } from 'react'

import type {
  OperatorsData,
  OperatorsListProps,
  ParticipantsData,
  UsersListProps,
} from '@context/APIContext/hooks/useOperatorApi'
import type { SWRIniniteHookProps } from '@interfaces'
import useSWRInfinite from 'swr/infinite'

interface UseUsersProps<
  Data extends ParticipantsData | OperatorsData,
  Fetcher extends (
    props: Data extends ParticipantsData ? UsersListProps : OperatorsListProps,
  ) => Promise<Data> = (props: UsersListProps | OperatorsListProps) => Promise<Data>,
> extends SWRIniniteHookProps<Data> {
  pageSize?: number
  fetcher: Awaited<ReturnType<Fetcher>> extends ParticipantsData | OperatorsData ? Fetcher : never
}

const useUsers = <Data extends ParticipantsData | OperatorsData>({
  key,
  fetcher,
  config,
  pageSize,
}: UseUsersProps<Data>) => {
  const { data, isValidating, error, size, mutate, setSize } = useSWRInfinite(key, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    ...config,
  })

  const hasMoreData = useMemo(() => {
    return Boolean(data?.length) && data?.[data.length - 1].length === (pageSize ?? 11)
  }, [pageSize, data])

  const goToNextPage = useCallback(() => {
    hasMoreData && setSize((prev) => prev + 1)
  }, [hasMoreData, setSize])

  const preparedData = useMemo(() => {
    return data?.flat()
  }, [data])

  return {
    data: preparedData,
    size,

    error,
    isValidating,

    hasMoreData,

    mutate,
    setSize,
    goToNextPage,
  }
}

export default useUsers
