import React, { forwardRef, memo, useCallback, useMemo, useRef, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Loader from '@components/Loader'
import ReferenceBooksFlat from '@components/ReferenceBooks/Flat'
import VirtuosoList from '@components/ReferenceBooks/Flat/Grid/VirtuosoList'
import { useReferenceBooksFlatManager } from '@components/ReferenceBooks/Flat/Manager'
import { removePx } from '@helpers/manipulateWithPx'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useBooleanState } from '@hooks/useBooleanState'
import cn from 'classnames'

import styles from './ReferenceBooksGrid.module.scss'

const DEFAULT_REFERENCE_FLAT_ITEM_HEIGHT = 56

const ReferenceBooksFlatGrid = () => {
  const {
    state: { gridSwrInstance, searchValue },
  } = useReferenceBooksFlatManager()

  const { booleanState: isShowScroll, setBooleanState: setShowScroll } = useBooleanState()

  const { referenceBooks, referenceBooksError, isReferenceBooksLoading } = gridSwrInstance ?? {}

  const [searchPlaceholderCondition, setSearchPlaceholderCondition] = useState<boolean>(false)

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const noDataCondition = useMemo(() => {
    return (
      !referenceBooksError &&
      !isReferenceBooksLoading &&
      !referenceBooks?.length &&
      !searchValue.length
    )
  }, [searchValue.length, referenceBooksError, isReferenceBooksLoading, referenceBooks?.length])

  const handleTotalListHeightChanged = useCallback(
    (listHeight: number) => {
      if (!wrapperRef.current) return

      const { height } = window.getComputedStyle(wrapperRef.current)

      const wrapperHeight = removePx(height)

      setShowScroll(listHeight > wrapperHeight)
    },
    [setShowScroll],
  )

  useUpdateEffect(() => {
    setSearchPlaceholderCondition(false)

    if (searchValue.length && !referenceBooks?.length) setSearchPlaceholderCondition(true)
  }, [searchValue, referenceBooks?.length])

  if (noDataCondition) {
    return <NoDataPlaceholder className={styles.referenceBooksGrid__placeholder} />
  }

  if (searchPlaceholderCondition && !isReferenceBooksLoading) {
    return (
      <SearchPlaceholder className={styles.referenceBooksGrid__placeholder}>
        {searchValue}
      </SearchPlaceholder>
    )
  }

  return (
    <div className={styles['referenceBooksGrid__list-wrapper']} ref={wrapperRef}>
      {referenceBooks && (
        <Virtuoso
          data={referenceBooks}
          totalCount={referenceBooks.length}
          computeItemKey={(_index, referenceBook) => referenceBook.id}
          defaultItemHeight={DEFAULT_REFERENCE_FLAT_ITEM_HEIGHT}
          totalListHeightChanged={handleTotalListHeightChanged}
          components={{
            List: forwardRef(({ children, ...props }, ref) => (
              <VirtuosoList
                {...props}
                ref={ref}
                className={cn(styles.referenceBooksGrid__list, {
                  [styles['referenceBooksGrid__list--show-scroll']]: isShowScroll,
                })}
              >
                {children}
              </VirtuosoList>
            )),
          }}
          itemContent={(_index, referenceBook) => (
            <ReferenceBooksFlat.Item
              key={referenceBook.id}
              referenceBookItem={referenceBook}
              searchValue={searchValue}
            />
          )}
        />
      )}
      <Loader loading={isReferenceBooksLoading} />
    </div>
  )
}

export default memo(ReferenceBooksFlatGrid)
