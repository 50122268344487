import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { StatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/types'
import { standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const secondSectionStatementAlreadyInvestmentsValidationMap: FormValuesValidationSection<
  keyof StatementAlreadyInvestmentsFormValues['2'],
  StatementAlreadyInvestmentsFormValues
> = {
  madeInvestorCapital: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { secondSectionStatementAlreadyInvestmentsValidationMap }
