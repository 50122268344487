import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOnMonitoringResultsExpenseChecker,
  CertificateOnMonitoringResultsFormValues,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const expenseCheckerCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsExpenseChecker,
  CertificateOnMonitoringResultsFormValues
> = {
  expenseNameChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
}

export { expenseCheckerCertificateOnMonitoringResultsValidationMap }
