import { FC } from 'react'

import ModalAction, { ModalActionWithoutActionProps } from '@components/NewDesign/Modal/Action'
import styles from '@components/Sidebars/AuthorizedOrganizations/OMSU/Item.module.scss'

interface SubmitInnChangeModal extends Omit<ModalActionWithoutActionProps, 'title'> {
  onSubmit: VoidFunction
  onClose: VoidFunction
}

export const SubmitInnChangeModal: FC<SubmitInnChangeModal> = ({
  onSubmit,
  onClose,
  isOpen,
  bodyText,
  title,
}) => {
  return (
    <ModalAction
      isOpen={isOpen}
      title={title || 'Изменить ИНН?'}
      simpleModalContainerClassName={styles.SubmitModal}
      actions={[
        {
          children: 'Отмена',
          onClick: onClose,
          view: 'gray',
          color: 'default',
        },
        {
          children: 'Изменить ИНН',
          onClick: onSubmit,
          color: 'negative',
        },
      ]}
      onClose={onClose}
    >
      {bodyText ||
        'Организация потеряет доступ в систему и больше не сможет участвовать в рассмотрении соглашений. Все полномочия получит новый орган исполнительной власти'}
    </ModalAction>
  )
}
