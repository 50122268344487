import { useDfoTypes } from '@hooks/new/swr/useDfoTypes'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

const useNavigationLoading = (projectId: string) => {
  const { isLoadingProjectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useNavigationLoading',
          moduleName: 'DocumentsNavigation',
        },
      }),
    },
  })

  const { isLoadingDfoTypes } = useDfoTypes({ key: { _key: 'dfoTypes' } })

  return isLoadingProjectDfos || isLoadingDfoTypes
}

export { useNavigationLoading }
