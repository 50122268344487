import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'

const { getDefaultRfFieldState, getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfSupplementalCostSharingAMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация, реализующая проект' },
  { id: '5', title: 'Сторона договора' },
  { id: '6', title: 'Условия договора' },
  { id: '7', title: 'Объекты, в отношении которых заключен договор' },
]

const defaultSupplementalCostSharingAFormValues: SupplementalCostSharingAFormValues = {
  '1': getDefaultRfFieldState(),
  '2': {
    subjects: [
      {
        id: '1',
        subjectFullName: '',
        subjectNameGenitive: '',
        subjectHeaderName: null,
        subjectHeaderNameGenitive: '',
        subjectHeaderPosition: '',
        subjectHeaderPositionGenitive: '',
        subjectHeaderReasonGenitive: '',
      },
    ],
  },
  '3': {
    municipalities: [
      {
        id: '1',
        municipalityFullName: '',
        municipalityFullNameGenitive: '',
        municipalityHeaderName: null,
        municipalityHeaderNameGenitive: '',
        municipalityHeaderPosition: '',
        municipalityHeaderPositionGenitive: '',
        municipalityHeaderReasonGenitive: '',
      },
    ],
  },
  '4': getDefaultInvestorFieldState(),
  '5': {
    contractDutyMembers: [],
  },
  '6': {
    contractDutyNumber: '',
    contractDutyDate: '',
    contractDutyTerms: '',
    objectsInfrastructureInvestorCost: '',
  },
  '7': {
    contractDutyObjects: [],
  },
  additionalFields: {
    isFederal: true,
    initialMunicipalities: [],
  },
}

const supplementalCostSharingABlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorFullName: '4.investorFullName',
    investorHeaderName: '4.investorHeaderName',
    investorHeaderNameGenitive: '4.investorHeaderNameGenitive',
    investorHeaderPosition: '4.investorHeaderPosition',
    investorHeaderPositionGenitive: '4.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '4.investorHeaderReasonGenitive',
  },
  '5': {
    contractDutyMembers: '5.contractDutyMembers',
  },
  '6': {
    contractDutyNumber: '6.contractDutyNumber',
    contractDutyDate: '6.contractDutyDate',
    contractDutyTerms: '6.contractDutyTerms',
    objectsInfrastructureInvestorCost: '6.objectsInfrastructureInvestorCost',
  },
  '7': {
    contractDutyObjects: '7.contractDutyObjects',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
  },
} as const

export {
  defaultSupplementalCostSharingAFormValues,
  mapOfSupplementalCostSharingAMenu,
  supplementalCostSharingABlockValues,
}
