import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAConnectionReorganizationImplementingFormValues } from '@components/Forms/AAConnectionReorganizationImplementingForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fifthSectionValidationMap: FormValuesValidationSection<
  keyof AAConnectionReorganizationImplementingFormValues['4'],
  AAConnectionReorganizationImplementingFormValues
> = {
  investorPartnerHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fifthSectionValidationMap }
