import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import CertificateOnMonitoringResultsPercentCreditMembersCheckerList from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/6/PercentCreditChecker/PercentCreditMembersCheckerList'
import { percentCreditCheckerCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/6/PercentCreditChecker/validation'
import { CertificateOnMonitoringResultsPercentCreditsCheckerArrayPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/6/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CertificateOnMonitoringResultsPercentCreditCheckerProps {
  id: string
  name: CertificateOnMonitoringResultsPercentCreditsCheckerArrayPathName
  title: string
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
  onRemove: VoidFunction
}

const CertificateOnMonitoringResultsPercentCreditChecker: FC<
  CertificateOnMonitoringResultsPercentCreditCheckerProps
> = ({ id, name, title, formInstance, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { getSubscribableControlProps, getInputProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
    })

  return (
    <FieldView.StepTemplate id={id} title={title} onRemoveStep={onRemove}>
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.percentCreditNameChecker`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${name}.percentCreditNameChecker`,
                    rules:
                      percentCreditCheckerCertificateOnMonitoringResultsValidationMap.percentCreditNameChecker,
                    inputProps: {
                      label: 'Наименование',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.percentCreditNameChecker`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.percentCreditNameChecker`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.percentCreditDateChecker`,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.percentCreditDateChecker`,
                    calendarInputProps: {
                      label: 'Дата договора',
                    },
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.percentCreditDateChecker`), 0),
                    onInputChange: () =>
                      setTimeout(
                        () => debouncedHandleChangeValue?.(`${name}.percentCreditDateChecker`),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.percentCreditDateChecker`), 0),
                  })}
                />
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.percentCreditNameChecker`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${name}.percentCreditNumberChecker`,
                    inputProps: {
                      label: 'Номер договора',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.percentCreditNumberChecker`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.percentCreditNumberChecker`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <CertificateOnMonitoringResultsPercentCreditMembersCheckerList
            editMode={editMode}
            blockViewIsValidating={blockViewIsValidating}
            name={`${name}.percentCreditMembersChecker` as const}
            formInstance={formInstance}
          />
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default CertificateOnMonitoringResultsPercentCreditChecker
