import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import objectItemStyles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFields from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/Forms/GeneralFields'
import { compensationStatementExpenseSubTypeValidationMap } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/validation'
import { ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import Stack from '@components/ReactBootstrap/Stack'
import { useBooleanState } from '@hooks/useBooleanState'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

interface ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectBondProps {
  id: string
  title: string
  name: ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
  onRemove?: VoidFunction
}

const ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectBond: FC<
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectBondProps
> = ({ id, title, name, formInstance, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
  })

  const {
    booleanState: isShowObjectItemContent,
    reverseBooleanState: toggleShowObjectItemContent,
  } = useBooleanState(true)

  const handleRemoveObject = async () => {
    await onRemove?.()
  }

  return (
    <FlippedWithCollapse flipId={id}>
      <Stack direction={'vertical'}>
        <div className={objectItemStyles.objectItem__header}>
          <ObjectStepWrapperHeaderNode
            buttonProps={{
              onClick: toggleShowObjectItemContent,
              trailingIcon: {
                className: cn({
                  [objectItemStyles['objectItem__header-button__icon--active']]:
                    isShowObjectItemContent,
                }),
              },
            }}
          >
            {title}
          </ObjectStepWrapperHeaderNode>

          {editMode && (
            <AsyncWrapper promise={handleRemoveObject}>
              {({ isLoading, wrappedPromise }) => (
                <Button
                  disabled={isLoading}
                  variant={'buttonSMedium'}
                  size={'2xs'}
                  color={'negative'}
                  view={'plain'}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  leadingIcon={{
                    noCurrentColorSvgFill: true,
                    src: DeleteIcon,
                  }}
                  onClick={wrappedPromise}
                >
                  Удалить
                </Button>
              )}
            </AsyncWrapper>
          )}
        </div>
        <div className={objectItemStyles.objectItem__content}>
          <CollapseWrapper isExpanded={isShowObjectItemContent}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                firstColumnSize={8}
                secondColumnSize={4}
                title={'Государственный регистрационный номер выпуска облигаций'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationObjectBondNumber`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.compensationObjectBondNumber`,
                      rules:
                        compensationStatementExpenseSubTypeValidationMap.compensationObjectBondNumber,
                      inputProps: {
                        integersOnly: true,
                        hideSuffix: true,
                        hasNegativeValue: false,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.compensationObjectBondNumber`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.compensationObjectBondNumber`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>

              <ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFields
                formInstance={formInstance}
                name={name}
              />
            </Stack>
          </CollapseWrapper>
        </div>
      </Stack>
    </FlippedWithCollapse>
  )
}

export default ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectBond
