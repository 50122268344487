import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { compact } from '@helpers/array/compact'
import { isArray, isFunction, isNullOrUndefined, isObject } from '@helpers/checkTypes'
import { BasePropertyState } from '@services/Properties/OOP/BaseClass'
import { PropertyStateCustom } from '@services/Properties/OOP/PropertyCustom'
import { PropertyStateSimple } from '@services/Properties/OOP/PropertySimple'
import {
  PropertyTypeEnum,
  TPropertyValueCustom,
  TPropertyValueSimple,
  TPropertyValueStructure,
} from '@services/Properties/Properties.entity'
import {
  PropertiesWatcher,
  SubscriberPropertiesWatcherUpdateValueProps,
} from '@services/Properties/Properties.watcher'

interface PropertyStateStructureValue {
  '@type': string
  '@listElementType': keyof typeof PropertyTypeEnum
  '@isPublic': boolean
  '@value': (PropertyStateStructure | PropertyStateSimple | PropertyStateCustom)[]
}

interface PropertyStateStructureListModifiers {
  '@type': typeof PropertyTypeEnum['LIST']
  '@deletedElementFromList': boolean
}

interface PropertyStateStructureMapModifiers {
  '@type': typeof PropertyTypeEnum['MAP']
  '@new': boolean
}

const { getDictionaryNameByCatalogType } = DocumentFormHelpers

class PropertyStateStructure extends BasePropertyState {
  private _propertyValueInfo: PropertyStateStructureValue | null = null
  private _propertyModifiersInfo:
    | PropertyStateStructureListModifiers
    | PropertyStateStructureMapModifiers
    | null = null

  constructor(
    propertyKey: string,
    propertyValue: TPropertyValueStructure,
    watcher: PropertiesWatcher,
  ) {
    if (!isObject(propertyValue)) return

    super(propertyKey, {
      '@id': propertyValue['@id'],
      '@isPublic': propertyValue['@isPublic'],
      '@lastUpdateDt': propertyValue['@lastUpdateDt'],
      '@order': propertyValue['@order'],
      '@permanentId': propertyValue['@permanentId'],
    })

    this.generateStructure(propertyValue, watcher)

    if (this.getPropertyId) {
      watcher.subscribe(this.getPropertyId, {
        updateValueCallback: (value: SubscriberPropertiesWatcherUpdateValueProps['updateValue']) =>
          this.updateValueCallback(value, watcher),
      })
    }
  }

  get getType() {
    return this._propertyValueInfo?.['@type']
  }

  get getIsPublic() {
    return this._propertyValueInfo?.['@isPublic']
  }

  get getDeletedElementFromList() {
    return this._propertyModifiersInfo?.['@deletedElementFromList']
  }

  get getNew() {
    return this._propertyModifiersInfo?.['@new']
  }

  private readonly updateValueCallback = (
    newValue: SubscriberPropertiesWatcherUpdateValueProps['updateValue'],
    watcher: PropertiesWatcher,
  ) => {
    const objectToUpdate = isFunction(newValue) ? newValue(this._propertyValueInfo) : newValue

    this.setLastUpdateDt(objectToUpdate['@lastUpdateDt'])
    this.setOrder(objectToUpdate['@order'] ?? this.getOrder)

    if (
      !isNullOrUndefined(objectToUpdate['@value']) &&
      typeof objectToUpdate['@value'] !== typeof this._propertyValueInfo?.['@value']
    )
      return

    // Временное решение перед показом, дабы не задеть иную логику.
    // Настоящее решение предложено в мр https://gitlab.renue.ru/kap/kap-frontend/-/merge_requests/2406
    if (objectToUpdate['@type'] === PropertyTypeEnum.LIST) {
      if (objectToUpdate['@value'][0] instanceof BasePropertyState) {
        this._propertyValueInfo = {
          '@type': objectToUpdate['@type'],
          '@value': objectToUpdate['@value'],
          '@isPublic': objectToUpdate['@isPublic'],
          '@listElementType': objectToUpdate['@listElementType'],
        }

        this._propertyModifiersInfo = {
          '@type': objectToUpdate['@type'],
          '@deletedElementFromList': !!objectToUpdate['@deletedElementFromList'],
        }

        return
      }

      this.generateStructure(objectToUpdate, watcher)
    }

    if (objectToUpdate['@type'] === PropertyTypeEnum.MAP) {
      this.generateStructure(objectToUpdate, watcher)
    }
  }

  private compareProperty<
    T extends PropertyStateStructure | PropertyStateSimple | PropertyStateCustom,
  >(propertyLeft: T, propertyRight: T): number {
    if (!isNullOrUndefined(propertyLeft.getOrder) && !isNullOrUndefined(propertyRight.getOrder)) {
      return propertyLeft.getOrder - propertyRight.getOrder
    }

    return 0
  }

  private generateStructure(propertyValue: TPropertyValueStructure, watcher: PropertiesWatcher) {
    if (propertyValue['@type'] === PropertyTypeEnum.LIST && isArray(propertyValue['@value'])) {
      const newValues = propertyValue['@value']?.map((property, index) => {
        const propertyEntries = Object.entries(property).map(([propertyKey, propertyValue]) => {
          const nextPropertyKey = `${this.getPropertyKey}.${String(index)}.${propertyKey}`

          if (
            (propertyValue['@type'] === PropertyTypeEnum.LIST &&
              isArray(propertyValue['@value'])) ||
            (propertyValue['@type'] === PropertyTypeEnum.MAP && isObject(propertyValue['@value']))
          ) {
            return new PropertyStateStructure(
              nextPropertyKey,
              propertyValue as TPropertyValueStructure,
              watcher,
            )
          }

          if (
            propertyValue['@type'] === PropertyTypeEnum.MONEY ||
            propertyValue['@type'] === PropertyTypeEnum.ORGANIZATION ||
            propertyValue['@type'] === PropertyTypeEnum.NPA ||
            propertyValue['@type'] === PropertyTypeEnum.NPA_PART ||
            propertyValue['@type'].startsWith(PropertyTypeEnum.CATALOG) ||
            propertyValue['@type'].startsWith(PropertyTypeEnum.LOOKUP) ||
            propertyValue['@type'].startsWith(PropertyTypeEnum.FIXED_LOOKUP)
          ) {
            return new PropertyStateCustom(
              nextPropertyKey,
              propertyValue as TPropertyValueCustom,
              watcher,
            )
          }

          return new PropertyStateSimple(
            nextPropertyKey,
            propertyValue as TPropertyValueSimple,
            watcher,
          )
        })

        return propertyEntries
      }, [])

      this._propertyValueInfo = {
        '@type': propertyValue['@type'],
        '@isPublic': propertyValue['@isPublic'],
        '@listElementType': propertyValue['@listElementType'],
        '@value': newValues?.flat() || [],
      }

      this._propertyModifiersInfo = {
        '@type': propertyValue['@type'],
        '@deletedElementFromList': !!propertyValue['@deletedElementFromList'],
      }
    }

    if (propertyValue['@type'] === PropertyTypeEnum.MAP && isObject(propertyValue['@value'])) {
      const newValues = Object.entries(propertyValue['@value'] || {}).map(
        ([projectKey, propertyValue]) => {
          if (
            (propertyValue['@type'] === PropertyTypeEnum.LIST &&
              isArray(propertyValue['@value'])) ||
            (propertyValue['@type'] === PropertyTypeEnum.MAP && isObject(propertyValue['@value']))
          ) {
            return new PropertyStateStructure(
              projectKey,
              propertyValue as TPropertyValueStructure,
              watcher,
            )
          }

          if (
            propertyValue['@type'] === PropertyTypeEnum.MONEY ||
            propertyValue['@type'] === PropertyTypeEnum.ORGANIZATION ||
            propertyValue['@type'] === PropertyTypeEnum.NPA ||
            propertyValue['@type'] === PropertyTypeEnum.NPA_PART ||
            propertyValue['@type'].startsWith(PropertyTypeEnum.CATALOG) ||
            propertyValue['@type'].startsWith(PropertyTypeEnum.LOOKUP) ||
            propertyValue['@type'].startsWith(PropertyTypeEnum.FIXED_LOOKUP)
          ) {
            return new PropertyStateCustom(
              projectKey,
              propertyValue as TPropertyValueCustom,
              watcher,
            )
          }

          return new PropertyStateSimple(projectKey, propertyValue as TPropertyValueSimple, watcher)
        },
        [],
      )

      this._propertyValueInfo = {
        '@type': propertyValue['@type'],
        '@isPublic': propertyValue['@isPublic'],
        '@listElementType': propertyValue['@listElementType'],
        '@value': newValues || [],
      }

      this._propertyModifiersInfo = {
        '@type': propertyValue['@type'],
        '@new': propertyValue['@new'] ?? false,
      }
    }
  }

  generatePropertiesProps() {
    if (!this._propertyValueInfo) return null

    if (this._propertyValueInfo['@type'] === PropertyTypeEnum.LIST) {
      return {
        propertyId: this.getPropertyId,
        type: this.getType,
        lastUpdateDt: this.getLastUpdateDate,
        deletedElementFromList: this.getDeletedElementFromList,
        value: isArray(this._propertyValueInfo['@value'])
          ? this._propertyValueInfo['@value'].map((property) => property.generatePropertiesProps())
          : [],
      }
    }

    if (this._propertyValueInfo['@type'] === PropertyTypeEnum.MAP) {
      return {
        type: this.getType,
        propertyId: this.getPropertyId,
        lastUpdateDt: this.getLastUpdateDate,
        order: this.getOrder,
        new: this.getNew,
        value: isArray(this._propertyValueInfo['@value'])
          ? this._propertyValueInfo['@value'].reduce(
              (prevValue: Record<string, unknown>, propertyValue) => {
                prevValue[propertyValue.getPropertyKey] = propertyValue.generatePropertiesProps()

                return prevValue
              },
              {},
            )
          : {},
      }
    }
  }

  generateObjectForAdapterFromPropertiesState() {
    if (!this._propertyValueInfo) return null

    if (this._propertyValueInfo['@type'] === PropertyTypeEnum.LIST) {
      return {
        ...this.generatePropertiesProps(),
        permanentId: this.getPermanentId,
        isPublic: this.getIsPublic,
        value: isArray(this._propertyValueInfo['@value'])
          ? this._propertyValueInfo['@value']
              .sort(this.compareProperty)
              .map((property) => property.generateObjectForAdapterFromPropertiesState())
          : [],
      }
    }

    if (this._propertyValueInfo['@type'] === PropertyTypeEnum.MAP) {
      return {
        ...this.generatePropertiesProps(),
        permanentId: this.getPermanentId,
        isPublic: this.getIsPublic,
        value: isArray(this._propertyValueInfo['@value'])
          ? this._propertyValueInfo['@value'].reduce(
              (prevValue: Record<string, unknown>, propertyValue) => {
                prevValue[propertyValue.getPropertyKey] =
                  propertyValue.generateObjectForAdapterFromPropertiesState()

                return prevValue
              },
              {},
            )
          : {},
      }
    }
  }

  getOverrideProps() {
    if (!this._propertyValueInfo) return null

    if (this._propertyValueInfo['@type'] === PropertyTypeEnum.LIST) {
      const propsOfChildren = this._propertyValueInfo['@value'].map((property) =>
        property.getOverrideProps(),
      )

      const parentProps = this._propertyValueInfo['@listElementType']?.startsWith(
        `${PropertyTypeEnum.CATALOG}_`,
      ) && {
        type: PropertyTypeEnum.CATALOG,
        initialType: this.getType,
        propertyId: this.getPropertyId,
        dictionaryName: getDictionaryNameByCatalogType(this._propertyValueInfo['@listElementType']),
      }

      return compact([...propsOfChildren, parentProps])
    }

    if (this._propertyValueInfo['@type'] === PropertyTypeEnum.MAP) {
      return this._propertyValueInfo['@value'].reduce(
        (prevValue: Array<unknown>, propertyValue) => {
          return [...prevValue, propertyValue.getOverrideProps()]
        },
        [],
      )
    }
  }
}

export type { PropertyStateStructureValue }
export { PropertyStateStructure }
