import { useState } from 'react'

const useDropRejectedFile = <T>() => {
  const [rejectedFile, setRejectedFile] = useState<T | null>(null)

  const handleClose = () => {
    setRejectedFile(null)
  }

  const handleRejectedFiles = (rejectedFile: T) => {
    setRejectedFile(rejectedFile)
  }

  return {
    rejectedFile,
    handleClose,
    handleRejectedFiles,
  }
}

export { useDropRejectedFile }
