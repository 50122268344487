import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import styles from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationMonitoringChecker.module.scss'
import { compensationSupportingInfrastructureCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationsSupportingInfrastructureCheckerGroup/validation'
import {
  CertificateOnMonitoringResultsCompensationsMonitoringCheckerArrayPathName,
  CertificateOnMonitoringResultsCompensationsSupportingInfrastructureCheckerPathName,
} from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CertificateOnMonitoringResultsCompensationsSupportingInfrastructureCheckerGroupProps {
  name: CertificateOnMonitoringResultsCompensationsMonitoringCheckerArrayPathName
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
}

const CertificateOnMonitoringResultsCompensationsSupportingInfrastructureCheckerGroup: FC<
  CertificateOnMonitoringResultsCompensationsSupportingInfrastructureCheckerGroupProps
> = ({ name, formInstance }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { fields: compensationsSupportingInfrastructureChecker } = useFieldArraySubscribableControl<
    CertificateOnMonitoringResultsFormValues,
    CertificateOnMonitoringResultsCompensationsSupportingInfrastructureCheckerPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.compensationsSupportingInfrastructureChecker`,
    keyName: 'keyNameId',
    watcher: CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps, getAmountInputProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
    })

  return (
    <Group disableBottomBorder title={'Затраты на объекты обеспечивающей инфраструктуры'}>
      <Stack direction={'vertical'} gap={3}>
        <ContainerWithBorder>
          {!compensationsSupportingInfrastructureChecker.length && (
            <EmptyDataText
              wrapperClassName={styles.compensationMonitoringChecker__emptyDataText}
              text={'Нет данных для заполнения'}
            />
          )}
          <Stack direction={'vertical'} gap={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.compensationsSupportingInfrastructureChecker`,
              })}
            >
              <FlipperList list={compensationsSupportingInfrastructureChecker}>
                {compensationsSupportingInfrastructureChecker.map((compensationSupporting, index) => {
                  const formName = `${name}.compensationsSupportingInfrastructureChecker.${index}` as const

                  return (
                    <Group
                      disableBottomBorder
                      key={compensationSupporting.id}
                      title={`Объект №${index + 1}`}
                    >
                      <Row>
                        <Col xs={12}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${formName}.objectName`,
                            })}
                          >
                            {({ overrideProps }) => {
                              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                                ? overrideProps.fetcherOptions
                                : undefined

                              return (
                                <ControlledFormSingleSelect
                                  {...getSingleSelectProps({
                                    fetcherProps,
                                    optionsAdapter: (item) => ({
                                      displayValue: item.name || '',
                                      value: item.id,
                                    }),
                                    controllerProps: {
                                      name: `${formName}.objectName`,
                                    },
                                    selectProps: {
                                      disabled: true,
                                      inputProps: {
                                        label: 'Объект обеспечивающей инфраструктуры',
                                      },
                                    },
                                  })}
                                />
                              )
                            }}
                          </SubscribableControl>
                        </Col>
                      </Row>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Затраты на объект обеспечивающей инфраструктуры'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${formName}.supportingInfrastructureObjectValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${formName}.supportingInfrastructureObjectValue`,
                              inputProps: {
                                disabled: true,
                                caption: 'значение, введенное инвестором',
                              },
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Затраты на объект обеспечивающей инфраструктуры'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${formName}.supportingInfrastructureObjectValueChecker`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${formName}.supportingInfrastructureObjectValueChecker`,
                              rules:
                                compensationSupportingInfrastructureCertificateOnMonitoringResultsValidationMap.supportingInfrastructureObjectValueChecker,
                              onBlur: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(
                                    `${formName}.supportingInfrastructureObjectValueChecker`,
                                  )
                                }, 0),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${formName}.supportingInfrastructureObjectValueChecker`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Group>
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Stack>
        </ContainerWithBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Сумма затрат по всем объектам обеспечивающей инфраструктуры'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.generalCompensationSupportingInfrastructure`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${name}.generalCompensationSupportingInfrastructure`,
                inputProps: {
                  disabled: true,
                  caption: 'рассчитывается на основе данных, введенных инвестором',
                },
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Сумма затрат по всем объектам обеспечивающей инфраструктуры'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.generalCompensationSupportingInfrastructureChecker`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${name}.generalCompensationSupportingInfrastructureChecker`,
                inputProps: {
                  disabled: true,
                  caption: 'рассчитывается на основе данных, введенных уполномоченной организацией',
                },
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </Group>
  )
}

export default CertificateOnMonitoringResultsCompensationsSupportingInfrastructureCheckerGroup
