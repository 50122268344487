import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu } from '@components/DocumentFormComponents/types'
import cloneDeep from 'clone-deep'

const certificateOnMonitoringResultsFormMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Сведения о документе',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'Уполномоченная организация',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '3': {
    id: '3',
    path: '3',
    title: 'Организация, реализующая проект',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '4': {
    id: '4',
    path: '4',
    title: 'О проекте',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '5': {
    id: '5',
    path: '5',
    title: 'Информация о реализации этапов',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '6': {
    id: '6',
    path: '6',
    title:
      'Кредиты и займы, купонный доход, облигационные займы, затраты по которым понесены при реализации инвестиционного проекта',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '7': {
    id: '7',
    path: '7',
    title: 'Объекты жилищного строительства, расположенные на территориях военных городков',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '8': {
    id: '8',
    path: '8',
    title:
      'Документально подтвержденные и фактические понесенные расходы, возникшие вследствие нарушения условий стабилизационной оговорки',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '9': {
    id: '9',
    path: '9',
    title: 'Информация о возмещении затрат',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '10': {
    id: '10',
    path: '10',
    title: 'Объем уплаченных налогов и таможенных платежей',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '11': {
    id: '11',
    path: '11',
    title: 'Информация об осуществленных мерах государственной поддержки',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '12': {
    id: '12',
    path: '12',
    title: 'Информация о наличии оснований для изменения или расторжения СЗПК',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
}

const getCertificateOnMonitoringResultsFormMenuHashMapMenuByStatus = () => {
  const initialCertificateOnMonitoringResultsMenuHashMap = cloneDeep(
    certificateOnMonitoringResultsFormMenuHashMap,
  )

  const certificateOnMonitoringResultsMenuState = [
    certificateOnMonitoringResultsFormMenuHashMap['1'],
    certificateOnMonitoringResultsFormMenuHashMap['2'],
    certificateOnMonitoringResultsFormMenuHashMap['3'],
    certificateOnMonitoringResultsFormMenuHashMap['4'],
    certificateOnMonitoringResultsFormMenuHashMap['5'],
    certificateOnMonitoringResultsFormMenuHashMap['6'],
    certificateOnMonitoringResultsFormMenuHashMap['7'],
    certificateOnMonitoringResultsFormMenuHashMap['8'],
    certificateOnMonitoringResultsFormMenuHashMap['9'],
    certificateOnMonitoringResultsFormMenuHashMap['10'],
    certificateOnMonitoringResultsFormMenuHashMap['11'],
    certificateOnMonitoringResultsFormMenuHashMap['12'],
  ]

  const mapOfMenu = certificateOnMonitoringResultsMenuState.map(
    (menu): MapOfMenu => ({
      id: menu.id,
      title: menu.title,
    }),
  )

  return {
    initialCertificateOnMonitoringResultsMenuHashMap,
    initialCertificateOnMonitoringResultsMenuState: certificateOnMonitoringResultsMenuState,
    mapOfMenu,
  }
}

export { getCertificateOnMonitoringResultsFormMenuHashMapMenuByStatus }
