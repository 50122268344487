import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { PetitionRecognizePreviouslyConcludedContractAsBoundFormValues } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfPetitionRecognizePreviouslyConcludedContractAsBoundMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Реквизиты связанного договора' },
]

const defaultPetitionRecognizePreviouslyConcludedContractAsBoundFormValues: PetitionRecognizePreviouslyConcludedContractAsBoundFormValues =
  {
    '1': {
      ...getDefaultInvestorFieldState(),
      investorAddress: '',
      investorInn: '',
      investorOgrn: '',
    },
    '2': {
      relatedContractNumber: '',
      relatedContractDate: '',
      relatedContractKind: '',
      relatedContractMemberInstrumentals: [],
      projectName: '',
    },
  }

const petitionRecognizePreviouslyConcludedContractAsBoundBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorFullNameInstrumental: '1.investorFullNameInstrumental',
    investorNameInstrumental: '1.investorNameInstrumental',
    investorAddress: '1.investorAddress',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
  },
  '2': {
    relatedContractNumber: '2.relatedContractNumber',
    relatedContractDate: '2.relatedContractDate',
    relatedContractKind: '2.relatedContractKind',
    relatedContractMemberInstrumentals: '2.relatedContractMemberInstrumentals',
    projectName: '2.projectName',
  },
} as const

type RelatedContractMemberInstrumentalsPathName =
  typeof petitionRecognizePreviouslyConcludedContractAsBoundBlockValues['2']['relatedContractMemberInstrumentals']

export type { RelatedContractMemberInstrumentalsPathName }
export {
  defaultPetitionRecognizePreviouslyConcludedContractAsBoundFormValues,
  mapOfPetitionRecognizePreviouslyConcludedContractAsBoundMenu,
  petitionRecognizePreviouslyConcludedContractAsBoundBlockValues,
}
