import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { assignmentMonetaryOrPledgeABlockValues } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/const'
import { useAssignmentMonetaryOrPledgeAManager } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/Manager'
import { AssignmentMonetaryOrPledgeAFormValues } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/types'
import { AssignmentMonetaryOrPledgeAFieldsControlUpdateWatcher } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { secondSectionValidationMap } from './validation'

const Second = () => {
  const formInstance = useFormContext<AssignmentMonetaryOrPledgeAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAssignmentMonetaryOrPledgeAManager()

  const {
    getInputProps,
    getTextareaProps,
    getAmountInputProps,
    getSingleSelectProps,
    getSubscribableControlProps,
    getCalendarInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AssignmentMonetaryOrPledgeAFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <RowWithBorder
          title={
            'Пункт правил, на основании которого осуществляется уступка (передача в залог) денежных требований '
          }
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: assignmentMonetaryOrPledgeABlockValues['2'].ruleLinkCession,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: assignmentMonetaryOrPledgeABlockValues['2'].ruleLinkCession,
                rules: secondSectionValidationMap.ruleLinkCession,
                inputProps: {},
                onBlur: () =>
                  setTimeout(
                    () =>
                      handleChangeValue?.(
                        assignmentMonetaryOrPledgeABlockValues['2'].ruleLinkCession,
                      ),
                    0,
                  ),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      assignmentMonetaryOrPledgeABlockValues['2'].ruleLinkCession,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: assignmentMonetaryOrPledgeABlockValues['2'].cessionFormName,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.name || '',
                        value: item.id,
                      }),
                      selectProps: {
                        inputProps: {
                          label: 'Форма передачи',
                        },
                        onChangeFormValue: () => {
                          setTimeout(() => {
                            handleChangeValue?.(
                              assignmentMonetaryOrPledgeABlockValues['2'].cessionFormName,
                            )
                          }, 0)
                        },
                      },
                      controllerProps: {
                        name: assignmentMonetaryOrPledgeABlockValues['2'].cessionFormName,
                        rules: secondSectionValidationMap.cessionFormName,
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: assignmentMonetaryOrPledgeABlockValues['2'].cessionValue,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: assignmentMonetaryOrPledgeABlockValues['2'].cessionValue,
                  inputProps: {
                    label: 'Размер денежных требований',
                    minorPartView: 'withZeroMinorPart',
                    hasNegativeValue: true,
                    hideCurrency: true,
                  },
                  rules: secondSectionValidationMap.cessionValue,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(assignmentMonetaryOrPledgeABlockValues['2'].cessionValue)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        assignmentMonetaryOrPledgeABlockValues['2'].cessionValue,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: assignmentMonetaryOrPledgeABlockValues['2'].cessionReason,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: assignmentMonetaryOrPledgeABlockValues['2'].cessionReason,
                  rules: secondSectionValidationMap.cessionReason,
                  textareaProps: {
                    label: 'Основание уступки или залога',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(assignmentMonetaryOrPledgeABlockValues['2'].cessionReason)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        assignmentMonetaryOrPledgeABlockValues['2'].cessionReason,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: assignmentMonetaryOrPledgeABlockValues['2'].cessionMember,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: assignmentMonetaryOrPledgeABlockValues['2'].cessionMember,
                  rules: secondSectionValidationMap.cessionMember,
                  textareaProps: {
                    label: 'Наименование плательщика',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(assignmentMonetaryOrPledgeABlockValues['2'].cessionMember)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        assignmentMonetaryOrPledgeABlockValues['2'].cessionMember,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: assignmentMonetaryOrPledgeABlockValues['2'].cessionAvailability,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: assignmentMonetaryOrPledgeABlockValues['2'].cessionAvailability,
                  rules: secondSectionValidationMap.cessionAvailability,
                  textareaProps: {
                    label:
                      'Решение органа власти, подтверждающее наличие денежных требований (в творительном падеже)',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        assignmentMonetaryOrPledgeABlockValues['2'].cessionAvailability,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        assignmentMonetaryOrPledgeABlockValues['2'].cessionAvailability,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={
                'Дата внесения соответствующей информации в реестр соглашений о защите и поощрении капиталовложений'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: assignmentMonetaryOrPledgeABlockValues['2'].cessionDate,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: assignmentMonetaryOrPledgeABlockValues['2'].cessionDate,
                    calendarInputProps: {},
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            assignmentMonetaryOrPledgeABlockValues['2'].cessionDate,
                          ),
                        0,
                      ),
                    onInputChange: () =>
                      setTimeout(
                        () =>
                          debouncedHandleChangeValue?.(
                            assignmentMonetaryOrPledgeABlockValues['2'].cessionDate,
                          ),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            assignmentMonetaryOrPledgeABlockValues['2'].cessionDate,
                          ),
                        0,
                      ),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
