import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { parametersOfCostRecoveryApplicationConstValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ICompensationObjectExpense,
  ICompensationObjectPaidTaxLegalPropertyValue,
  ICompensationObjects,
  ICompensationStatementExpenseSubType,
  ICompensationStatementPaidTax,
  ICompensationStatementPaidTaxValue,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { GetReferenceBooksReturnType } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { isArray } from '@helpers/checkTypes'

const { getYearOfDateFromPropertyDateValue } = DocumentFormHelpers

const generateRHFObjectForParametersOfCostRecoveryApplicationForm = (
  objectFromPropertyState: Record<string, any>,
  catalogObjectTypes: GetReferenceBooksReturnType,
): ParametersOfCostRecoveryApplicationFormValues => {
  const checkboxes = (() => {
    const selectedCheckboxes = {
      isFederal: false,
      isRegional: false,
      isLocal: false,
    }

    objectFromPropertyState?.compensationStatementBudgetLevels?.value.forEach((budgetLevel) => {
      if (
        budgetLevel.value?.systemName === parametersOfCostRecoveryApplicationConstValues.FEDERAL
      ) {
        selectedCheckboxes.isFederal = true
      }

      if (
        budgetLevel.value?.systemName === parametersOfCostRecoveryApplicationConstValues.REGIONAL
      ) {
        selectedCheckboxes.isRegional = true
      }

      if (budgetLevel.value?.systemName === parametersOfCostRecoveryApplicationConstValues.LOCAL) {
        selectedCheckboxes.isLocal = true
      }
    })

    return selectedCheckboxes
  })()

  const preparedCompensationStatementPaidTaxes: ICompensationStatementPaidTax[] = (() => {
    if (
      !isArray(objectFromPropertyState.compensationStatementPaidTaxes?.value) ||
      !objectFromPropertyState.compensationStatementPaidTaxes?.value?.length
    )
      return []

    return objectFromPropertyState.compensationStatementPaidTaxes?.value.map(
      (compensationStatementPaidTax): ICompensationStatementPaidTax => {
        const preparedCompensationStatementPaidTaxValues: ICompensationStatementPaidTaxValue[] =
          (() => {
            if (
              !isArray(
                compensationStatementPaidTax.value?.compensationStatementPaidTaxValues?.value,
              ) ||
              !compensationStatementPaidTax.value?.compensationStatementPaidTaxValues?.value?.length
            )
              return []

            return compensationStatementPaidTax.value?.compensationStatementPaidTaxValues?.value.map(
              (paidTaxValue): ICompensationStatementPaidTaxValue => ({
                id: paidTaxValue.propertyId,
                paidTaxYear: getYearOfDateFromPropertyDateValue(
                  paidTaxValue.value?.paidTaxYear?.value,
                ),

                paidTaxYearValue: paidTaxValue.value?.paidTaxYearValue?.value,
              }),
            )
          })()

        return {
          id: compensationStatementPaidTax.propertyId,
          compensationStatementPaidTaxTaxKindName:
            compensationStatementPaidTax.value?.compensationStatementPaidTaxTaxKind?.value?.name,
          compensationStatementPaidTaxValues: preparedCompensationStatementPaidTaxValues,
        }
      },
    )
  })()

  const preparedExpenseSubTypes: ICompensationStatementExpenseSubType[] = (() => {
    if (
      !isArray(objectFromPropertyState.compensationStatementExpenseSubTypes?.value) ||
      !objectFromPropertyState.compensationStatementExpenseSubTypes?.value?.length
    )
      return []

    return objectFromPropertyState.compensationStatementExpenseSubTypes?.value.map(
      (compensationStatementExpenseSubType): ICompensationStatementExpenseSubType => {
        const preparedCompensationObjects: ICompensationObjects[] = (() => {
          if (
            !isArray(compensationStatementExpenseSubType.value?.compensationObjects?.value) ||
            !compensationStatementExpenseSubType.value?.compensationObjects?.value?.length
          )
            return []

          return compensationStatementExpenseSubType.value?.compensationObjects?.value.map(
            (compensationObject): ICompensationObjects => {
              const preparedCompensationObjectExpenses: ICompensationObjectExpense[] = (() => {
                if (
                  !isArray(compensationObject.value?.compensationObjectExpenses?.value) ||
                  !compensationObject.value?.compensationObjectExpenses?.value?.length
                )
                  return []

                return compensationObject.value?.compensationObjectExpenses?.value.map(
                  (compensationObjectExpense): ICompensationObjectExpense => ({
                    id: compensationObjectExpense.propertyId,
                    compensationObjectExpenseValue:
                      compensationObjectExpense.value?.compensationObjectExpenseValue?.value,
                    compensationObjectExpenseYear: getYearOfDateFromPropertyDateValue(
                      compensationObjectExpense.value?.compensationObjectExpenseYear?.value,
                    ),
                  }),
                )
              })()

              const preparedCompensationObjectPaidTaxLandValues: ICompensationObjectPaidTaxLegalPropertyValue[] =
                (() => {
                  if (
                    !isArray(
                      compensationObject.value?.compensationObjectPaidTaxLandValues?.value,
                    ) ||
                    !compensationObject.value?.compensationObjectPaidTaxLandValues?.value?.length
                  )
                    return []

                  return compensationObject.value?.compensationObjectPaidTaxLandValues?.value.map(
                    (paidTaxValue): ICompensationObjectPaidTaxLegalPropertyValue => ({
                      id: paidTaxValue.propertyId,
                      paidTaxYear: getYearOfDateFromPropertyDateValue(
                        paidTaxValue.value?.paidTaxYear?.value,
                      ),
                      paidTaxYearValue: paidTaxValue.value?.paidTaxYearValue?.value,
                    }),
                  )
                })()

              const preparedCompensationObjectPaidTaxLegalPropertyValues: ICompensationObjectPaidTaxLegalPropertyValue[] =
                (() => {
                  if (
                    !isArray(
                      compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues?.value,
                    ) ||
                    !compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues?.value
                      ?.length
                  )
                    return []

                  return compensationObject.value?.compensationObjectPaidTaxLegalPropertyValues?.value.map(
                    (paidTaxValue): ICompensationObjectPaidTaxLegalPropertyValue => ({
                      id: paidTaxValue.propertyId,
                      paidTaxYear: getYearOfDateFromPropertyDateValue(
                        paidTaxValue.value?.paidTaxYear?.value,
                      ),
                      paidTaxYearValue: paidTaxValue.value?.paidTaxYearValue?.value,
                    }),
                  )
                })()

              return {
                id: compensationObject.propertyId,
                compensationObjectAgreementDate:
                  compensationObject.value?.compensationObjectAgreementDate?.value,
                compensationObjectAgreementNumber:
                  compensationObject.value?.compensationObjectAgreementNumber?.value,
                compensationObjectBondNumber:
                  compensationObject.value?.compensationObjectBondNumber?.value,
                compensationObjectCreditorName:
                  compensationObject.value?.compensationObjectCreditorName?.value,
                compensationObjectExpensesTotalValue:
                  compensationObject.value?.compensationObjectExpensesTotalValue?.value,
                compensationObjectPaidTaxLandTotalValue:
                  compensationObject.value?.compensationObjectPaidTaxLandTotalValue?.value,
                compensationObjectPaidTaxLegalPropertyTotalValue:
                  compensationObject.value?.compensationObjectPaidTaxLegalPropertyTotalValue?.value,
                compensationObjectTaxLandAreaNumber:
                  compensationObject.value?.compensationObjectTaxLandAreaNumber?.value,
                compensationObjectTaxLandRefundPeriod:
                  compensationObject.value?.compensationObjectTaxLandRefundPeriod?.value,
                compensationObjectTaxLegalPropertyCadastralNumber:
                  compensationObject.value?.compensationObjectTaxLegalPropertyCadastralNumber
                    ?.value,
                compensationObjectTaxLegalPropertyOtherIdNumber:
                  compensationObject.value?.compensationObjectTaxLegalPropertyOtherIdNumber?.value,
                compensationObjectTaxLegalPropertyRefundPeriod:
                  compensationObject.value?.compensationObjectTaxLegalPropertyRefundPeriod?.value,
                compensationObjectTypeName:
                  compensationObject.value?.compensationObjectType?.value?.name,
                compensationObjectTypeSystemName:
                  compensationObject.value?.compensationObjectType?.value?.systemName,
                finalBalanceHolder: compensationObject.value?.finalBalanceHolder?.value,
                infrastructureKindCode: compensationObject.value?.infrastructureKind?.value || null,
                isTaxLandPropertyObject: compensationObject.value?.isTaxLandPropertyObject?.value,
                isTaxLegalPropertyObject: compensationObject.value?.isTaxLegalPropertyObject?.value,
                objectName: compensationObject.value?.objectName?.value,
                projectDecisionDate: objectFromPropertyState.projectDecisionDate?.value,
                projectObjectValueName: compensationObject.value?.projectObjectValue?.value?.id
                  ? {
                      value: compensationObject.value?.projectObjectValue?.value?.id || '',
                      displayValue:
                        compensationObject.value?.projectObjectValue?.value?.objectName ||
                        compensationObject.value?.projectObjectValue?.value?.otherActivityName ||
                        '',
                    }
                  : null,
                compensationObjectExpenses: preparedCompensationObjectExpenses,
                compensationObjectPaidTaxLandValues: preparedCompensationObjectPaidTaxLandValues,
                compensationObjectPaidTaxLegalPropertyValues:
                  preparedCompensationObjectPaidTaxLegalPropertyValues,
              }
            },
          )
        })()

        return {
          id: compensationStatementExpenseSubType.propertyId,
          compensationStatementExpenseSubTypeTaxRefundPeriod:
            compensationStatementExpenseSubType.value
              ?.compensationStatementExpenseSubTypeTaxRefundPeriod?.value,
          compensationStatementExpenseSubTypeValueFullName:
            compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
              ?.value?.fullName,
          compensationStatementExpenseSubTypeValueSystemName:
            compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
              ?.value?.systemName,
          compensationStatementExpenseSubTypeName:
            compensationStatementExpenseSubType.value?.compensationStatementExpenseSubTypeValue
              ?.value?.name,
          compensationObjects: preparedCompensationObjects,
        }
      },
    )
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorAddressFullEgrulFormat:
        objectFromPropertyState?.investor?.value?.memberOrganizationId?.value
          ?.addressFullEgrulFormat,
    },
    '2': {
      projectName: objectFromPropertyState?.projectName?.value,
      szpkNumber: objectFromPropertyState?.szpkNumber?.value
        ? `№ ${objectFromPropertyState?.szpkNumber?.value}`
        : '',
      szpkRegistrationDate: objectFromPropertyState?.szpkRegistrationDate?.value
        ? `от ${objectFromPropertyState?.szpkRegistrationDate?.value}`
        : '',
    },
    '3': {
      ...checkboxes,
      compensationStatementForm: objectFromPropertyState?.compensationStatementForm?.value?.name,
      compensationStatementYear: objectFromPropertyState?.compensationStatementYear?.value
        ? getYearOfDateFromPropertyDateValue(
            objectFromPropertyState?.compensationStatementYear?.value,
          )
        : '',
      compensationStatementBudgetLevels:
        objectFromPropertyState?.compensationStatementBudgetLevels?.value,
    },
    '4': {
      compensationStatementExpenseGroupName: objectFromPropertyState
        ?.compensationStatementExpenseGroup?.value?.id
        ? {
            value: objectFromPropertyState.compensationStatementExpenseGroup?.value?.id || '',
            displayValue:
              objectFromPropertyState.compensationStatementExpenseGroup?.value?.name || '',
          }
        : null,
      compensationStatementExpenseGroupSystemName:
        objectFromPropertyState?.compensationStatementExpenseGroup?.value?.systemName,
      compensationStatementExpenseSubTypes: preparedExpenseSubTypes,
      //Фейковое поле
      compensationStatementExpenseSubTypesNotExist:
        objectFromPropertyState.compensationStatementExpenseSubTypes?.value,
      compensationStatementTaxLandPropertyRefundTotalValue:
        objectFromPropertyState?.compensationStatementTaxLandPropertyRefundTotalValue?.value,
      compensationStatementTaxLegalPropertyRefundTotalValue:
        objectFromPropertyState?.compensationStatementTaxLegalPropertyRefundTotalValue?.value,
      compensationStatementTypeSystemName:
        objectFromPropertyState?.compensationStatementType?.value?.systemName,
    },

    '5': {
      compensationStatementPaidTaxes: preparedCompensationStatementPaidTaxes,
    },
    additionalFields: {
      compensationObjectFromSzpkId: catalogObjectTypes?.find(
        (type) =>
          type.systemName ===
          parametersOfCostRecoveryApplicationConstValues.COMPENSATION_OBJECT_FROM_SZPK,
      )?.id,
      compensationObjectAbsentSzpkId: catalogObjectTypes?.find(
        (type) =>
          type.systemName ===
          parametersOfCostRecoveryApplicationConstValues.COMPENSATION_OBJECT_ABSENT_SZPK,
      )?.id,
      creditAgreementId: catalogObjectTypes?.find(
        (type) =>
          type.systemName === parametersOfCostRecoveryApplicationConstValues.CREDIT_AGREEMENT,
      )?.id,
      loanAgreementId: catalogObjectTypes?.find(
        (type) => type.systemName === parametersOfCostRecoveryApplicationConstValues.LOAN_AGREEMENT,
      )?.id,
      bondId: catalogObjectTypes?.find(
        (type) => type.systemName === parametersOfCostRecoveryApplicationConstValues.BOND,
      )?.id,
    },
  }
}

export { generateRHFObjectForParametersOfCostRecoveryApplicationForm }
