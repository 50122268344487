import { DfoAttributeTypes, DfoPropertiesTypes } from '@constants/types'
import { isString } from '@helpers/checkTypes'
import { ValueOf } from '@helpers/ValueOf'
import { useDfoAttributes } from '@hooks/new/swr/useDfoAttributes'
import { useDfoProperties } from '@hooks/new/swr/useDfoProperties'
import { DEFAULT_SYSTEM_VERSION } from '@services/Dfo/Dfo.const'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import DfoHelpersService from '@services/Dfo/Dfo.helpers'
import { HandleLogErrorParams } from '@services/LoggerService/LoggerService.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

type GetCurrentOrganizationIdProps = {
  projectId: string
  dfo: IDfoListItem
  dfoAttributeType: ValueOf<typeof DfoAttributeTypes>
  dfoPropertyType: ValueOf<typeof DfoPropertiesTypes>
  handleLogErrorParams?: HandleLogErrorParams
}

export const useCurrentOrganizationId = ({
  projectId,
  dfo,
  dfoAttributeType,
  dfoPropertyType,
  handleLogErrorParams,
}: GetCurrentOrganizationIdProps) => {
  const systemVersion = dfo.systemVersion ?? DEFAULT_SYSTEM_VERSION
  
  const dfoId = dfo.id
  const isTakeOrganizationIdFromProperties = systemVersion >= 3

  const organizationDfoPropertiesBody = {
    path: dfoPropertyType,
  }
  const useDfoPropertiesKey = isTakeOrganizationIdFromProperties
    ? {
        dfoId,
        body: organizationDfoPropertiesBody,
        _key: 'dfoProperties',
      }
    : null

  const {
    dfoProperties,
    mutate: revalidateDfoProperties,
    isLoadingDfoProperties,
    error: errorDfoProperties,
  } = useDfoProperties({
    key: useDfoPropertiesKey,
    config: {
      isPaused: () => !dfoId,
      onError:
        handleLogErrorParams && LoggerHelpersService.handleMultipleLogError(handleLogErrorParams),
    },
  })

  const useDfoAttributesKey = !isTakeOrganizationIdFromProperties
    ? {
        projectId,
        dfoId,
        _key: 'dfoAttributes',
      }
    : null
  const {
    mapOfAttributes,
    mutate: revalidateDfoAttributes,
    isLoadingDfoAttributes,
    error: errorDfoAttributes,
  } = useDfoAttributes({
    key: useDfoAttributesKey,
    config: {
      isPaused: () => !projectId || !dfoId,
      onError:
        handleLogErrorParams && LoggerHelpersService.handleMultipleLogError(handleLogErrorParams),
    },
  })

  const organizationIdFromMapOfAttributes = mapOfAttributes?.[dfoAttributeType]
  const organizationIdFromAttributes = isString(organizationIdFromMapOfAttributes)
    ? organizationIdFromMapOfAttributes
    : undefined

  const organizationIdFromProperties =
    dfoProperties && DfoHelpersService.isDfoPropertyValueOrganisation(dfoProperties)
      ? dfoProperties?.['@value']?.id
      : undefined

  if (isTakeOrganizationIdFromProperties)
    return {
      currentOrganizationId: organizationIdFromProperties,
      isLoadingCurrentOrganizationId: isLoadingDfoProperties,
      revalidateCurrentOrganizationId: revalidateDfoProperties,
      errorCurrentOrganizationId: errorDfoProperties,
    }

  return {
    currentOrganizationId: organizationIdFromAttributes,
    isLoadingCurrentOrganizationId: isLoadingDfoAttributes,
    revalidateCurrentOrganizationId: revalidateDfoAttributes,
    errorCurrentOrganizationId: errorDfoAttributes,
  }
}
