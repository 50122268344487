import React, { FC, LegacyRef } from 'react'
import { Link } from 'react-router-dom'

import ChipBase from '@components/Chip/Base'
import Icon from '@components/Icon'
import IconButton from '@components/NewDesign/IconButton'
import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import StatementDfoStatus from '@components/NewStatements/Item/Status'
import Regional from '@components/PreparedTooltips/Regional'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { Paths } from '@constants/paths'
import { NewDfosType } from '@constants/types'
import { DfoGroupTypes } from '@context/APIContext/types'
import { isBoolean } from '@helpers/checkTypes'
import { toggle } from '@helpers/commonHelpers'
import { formatCurrency } from '@helpers/format'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useAvailableConfigsByRole } from '@hooks/new/configs/useAvailableDfoConfigsByRole'
import { getProjectStatus } from '@hooks/new/statuses/helpers'
import { useBooleanState } from '@hooks/useBooleanState'
import deadlineTimeIcon from '@icons/deadline_time.svg'
import dfoDigitizingIcon from '@icons/digitizing.svg'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import { buildUrl } from '@packages/api/fetcher/helpers/buildUrl'
import DayjsService from '@services/Dayjs/Dayjs.service'
import DeadlineService from '@services/Deadline/Deadline.service'
import { PreparedProjectToRender, TProjectTags } from '@services/Projects/Project.entity'
import cn from 'classnames'

import styles from './StatementItem.module.scss'

const DFO_DIGITIZING_ICON_COLOR = '#FCFCFC' // on-accent-on-surface-primary

type StatementItemProps = PreparedProjectToRender & {
  isCostRecoveryTagActive: boolean
  initialToggleState?: boolean
  filterTagType?: TProjectTags

  subsideBudgetType?: boolean
  claimCreateDate?: string
  containerRef?: LegacyRef<HTMLDivElement>
  onFold?: (prevValue: boolean) => void
  onLinkClick?: VoidFunction
}

const { getDifferenceBetweenCurrentAndDeadlineDate } = DeadlineService

const StatementItem: FC<StatementItemProps> = ({
  dfos,
  initialToggleState = false,
  investmentSum,
  name,
  organization,
  id,
  isFederal,
  filterTagType,
  isCostRecoveryTagActive,
  szpkRegisteredDate,
  szpkRegisteredNumber,
  isOldStatement,
  projectPage,

  subsideBudgetType,
  claimCreateDate,

  containerRef,

  onLinkClick,
  onFold,
}) => {
  const { getConfigForDfo } = useAvailableConfigsByRole()

  const { booleanState: isToggled, setBooleanState: setIsToggled } =
    useBooleanState(initialToggleState)

  const {
    booleanState: isMountChildren,
    setBooleanStateToTrue: mountChildren,
    setBooleanStateToFalse: unmountChildren,
  } = useBooleanState(initialToggleState)

  const { getCollapseProps } = useCollapse({
    isExpanded: isToggled,
    duration: 500,
    onExpandStart: () => {
      mountChildren()
    },
    onCollapseEnd: () => {
      unmountChildren()
    },
  })

  const handleFoldUnfold = () => {
    onFold?.(toggle(isToggled))
    setIsToggled(toggle)
  }

  const SZPK = dfos.find((item) => item.type === NewDfosType.SZPK)

  const status = getProjectStatus({
    stage: SZPK?.stage,
    szpkRegisteredNumber,
    szpkRegisteredDate,
  })

  const isOverdueChipRender = dfos.some((dfo) => {
    return (
      dfo.deadline?.overdueDate &&
      getDifferenceBetweenCurrentAndDeadlineDate(dfo.deadline.overdueDate) > 0
    )
  }, [])

  const isWarningDeadlineChipRender = dfos.some((dfo) => {
    if (!dfo.deadline?.overdueDate) return false

    const differenceBetweenDates = getDifferenceBetweenCurrentAndDeadlineDate(
      dfo.deadline.overdueDate,
    )

    return differenceBetweenDates > -7 && differenceBetweenDates <= 0
  }, [])

  const { digitizingInProcess } =
    dfos.find(({ digitizingInProcess }) => isBoolean(digitizingInProcess)) || {}

  const regionalRenderCondition = !(isFederal ?? true) && !isCostRecoveryTagActive

  return (
    <div data-id={id} data-page={projectPage} ref={containerRef} className={styles.container}>
      <button type="button" className={styles.wrapper} onClick={handleFoldUnfold}>
        <IconButton
          size={'s'}
          view={'basic'}
          icon={{
            className: cn(styles.toggler, isToggled && styles.toggler__toggled),
            src: chevronRightIcon,
          }}
        />
        <div className={styles.blockName}>
          {regionalRenderCondition && <Regional position="bottom-start" offset={[-12, 16]} />}
          <TypographyWithTooltip.Body
            variant="bodyMMedium"
            className={cn(styles.name, {
              [styles.isRegional]: regionalRenderCondition,
            })}
            tooltipProps={{
              position: 'top-start',
              targetClassName: styles.tooltip__target,
              contentClassName: styles.tooltip__content,
            }}
          >
            {`${isOldStatement ? '[подано до 01.04.2021]' : ''} ${name}`}
          </TypographyWithTooltip.Body>
        </div>
        <Typography.Body align={'center'} variant="bodyMMedium" className={styles.organizationName}>
          {organization?.name}
        </Typography.Body>
        <Stack direction="horizontal" gap={2} className={styles.deadline}>
          {(filterTagType ?? DfoGroupTypes.SZPK) === DfoGroupTypes.SZPK && (
            <ChipBase
              disableClickableEffect
              label={status.status}
              className={styles['deadline__chip-dfoType']}
              contentClassName={styles['deadline__chip-dfoType-text']}
              {...status.chipProps}
              size="xs"
            />
          )}
          {isOverdueChipRender && (
            <ChipBase
              disableClickableEffect
              className={styles.deadline__chip}
              contentClassName={styles['deadline__chip-text']}
              priority={'secondary'}
              variant={'error'}
              label={'Просрочено'}
              size="xs"
            />
          )}
          {!isOverdueChipRender && isWarningDeadlineChipRender && (
            <Tooltip
              position={'top'}
              offset={[0, 10]}
              content={
                <Typography.Caption color={'text-base-secondary'} variant={'captionMMedium'}>
                  Приближается срок рассмотрения
                </Typography.Caption>
              }
            >
              <div className={styles['deadline__chip-warning']}>
                <Icon
                  noCurrentColorSvgFill
                  className={styles['deadline__chip-warning-icon']}
                  src={deadlineTimeIcon}
                  size={'xs'}
                />
              </div>
            </Tooltip>
          )}
          {isBoolean(digitizingInProcess) && (
            <Tooltip
              position={'top'}
              offset={[0, 10]}
              content={
                <Typography.Caption color={'text-base-secondary'} variant={'captionMMedium'}>
                  {digitizingInProcess
                    ? 'Есть незаполненные формы'
                    : 'После регистрации необходимо заполнить формы'}
                </Typography.Caption>
              }
            >
              <div
                className={cn(styles['deadline__digitizing'], {
                  [styles['deadline__digitizing--inProcess']]: digitizingInProcess,
                  [styles['deadline__digitizing--notProcess']]: !digitizingInProcess,
                })}
              >
                <Icon
                  src={dfoDigitizingIcon}
                  className={styles['deadline__digitizing-icon']}
                  size="initial"
                  color={DFO_DIGITIZING_ICON_COLOR}
                />
              </div>
            </Tooltip>
          )}
        </Stack>
        <Typography.Body variant="bodyMMedium" className={styles.investmentSum}>
          {investmentSum && !isCostRecoveryTagActive && formatCurrency(investmentSum)}
        </Typography.Body>
      </button>
      <div {...getCollapseProps()}>
        {isMountChildren && (
          <div>
            <div className={styles.items}>
              {dfos?.length &&
                dfos.map((dfo, index) => {
                  const { stage, type, version, id: dfoId, typeName, actionAllowed } = dfo

                  const params = getConfigForDfo({
                    dfo: { stage, actionAllowed, type, version },
                    isFederal,
                  })

                  const preparedLinkHref = buildUrl({
                    url: Paths.ProjectPage,
                    params: {
                      projectId: id,
                    },
                    queries: {
                      dfoId: dfoId || '',
                    },
                  })

                  return (
                    <Link
                      key={index}
                      className={styles.item}
                      to={preparedLinkHref}
                      onClick={onLinkClick}
                    >
                      {!(subsideBudgetType ?? true) && isCostRecoveryTagActive && (
                        <Regional offset={[20, 10]} text={'Возмещение из регионального бюджета'} />
                      )}
                      <TypographyWithTooltip
                        variant="bodyMMedium"
                        className={styles.name}
                        tooltipProps={{
                          position: 'top-start',
                          targetClassName: styles.tooltip__target,
                          contentClassName: styles.tooltip__content,
                        }}
                      >
                        {typeName}
                      </TypographyWithTooltip>
                      <Typography.Body
                        align={'center'}
                        variant="bodyMMedium"
                        className={styles.version}
                      >
                        {version && version > 1 && version + ' версия'}
                      </Typography.Body>
                      {claimCreateDate && (
                        <Typography.Body variant="bodyMMedium" className={styles.version}>
                          {DayjsService.dayjs(claimCreateDate).format(
                            objOfDateFormats.formatWithFullMonth,
                          )}
                        </Typography.Body>
                      )}
                      <StatementDfoStatus dfo={dfo} params={params} />
                    </Link>
                  )
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default StatementItem
