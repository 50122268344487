import React, { FC, memo, ReactNode } from 'react'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isEmptyString, isNotEmptyString } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './Group.module.scss'

export interface GroupProps {
  children: ReactNode
  leftAddons?: ReactNode
  disableBottomBorder?: boolean
  disableTitlePaddingLeft?: boolean
  disableTitlePaddingRight?: boolean
  groupClassName?: string
  titleClassName?: string
  title?: string
  id?: string
}

const Group: FC<GroupProps> = ({
  disableTitlePaddingLeft = false,
  disableTitlePaddingRight = false,
  title,
  id,
  leftAddons,
  disableBottomBorder,
  groupClassName,
  titleClassName,
  children,
}) => {
  return (
    <Container
      id={id}
      className={cn(
        styles.group,
        'p-0',
        {
          [styles['group--disableMarginTop']]: !title || isEmptyString(title),
        },
        groupClassName,
      )}
    >
      <Row>
        <Col xs={12}>
          {isNotEmptyString(title) && (
            <Stack className={styles.group__wrapper} direction={'horizontal'} gap={0}>
              {leftAddons}
              <Typography.Body
                variant={'bodyMMedium'}
                className={cn(
                  styles.group__title,
                  {
                    [styles['group__title--disablePaddingLeft']]: disableTitlePaddingLeft,
                    [styles['group__title--disablePaddingRight']]: disableTitlePaddingRight,
                  },
                  titleClassName,
                )}
              >
                {title}
              </Typography.Body>
            </Stack>
          )}
          {children}
          {!disableBottomBorder && <Border className={styles.group__border} />}
        </Col>
      </Row>
    </Container>
  )
}

export default memo(Group)
