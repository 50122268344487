import { TDocumentStackAction } from '@services/DocumentActions/DocumentActions.entity'

const defaultActionConfig: TDocumentStackAction['config'] = {
  onFulfilled: () => null,
  onReject: () => null,
}

const actionsInitialProps = {
  STATEMENT_CREATE: ['SZPKDocumentsSetId', 'projectDfos'],
  CREATE_INVENTORY: ['isDocumentChange'],
  ADD_MEMBERS: ['isProjectFederal', 'isDocumentChange'],
  DELETE_DFO: ['nameDfoForDeleteProps', 'redirectDfoIdForDeleteProps'],
  REGISTRATION: ['projectDfos'],
}

const stackErrorMap = {
  stackIsOver: 'stackIsOver',
  actionWasCanceled: 'actionWasCanceled',
  stackShiftError: 'stackShiftError',
  refLostContext: 'refLostContext',
  errorWasProcessed: 'errorWasProcessed',
} as const

export { actionsInitialProps, defaultActionConfig, stackErrorMap }
