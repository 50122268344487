import React, { FC, memo } from 'react'

import LoginItem from '@components/Forms/LoginForm/shared/LoginItem'
import Typography from '@components/NewDesign/Typography/Typography'
import { ESIAOrganization } from '@context/APIContext/hooks/useESIAApi'

import styles from './OrganizationItem.module.scss'

interface CertificateItemProps {
  organization: ESIAOrganization
  onChooseOrganization: () => void
  className?: string
}

const OrganizationItem: FC<CertificateItemProps> = ({ organization, onChooseOrganization }) => {
  return (
    <LoginItem
      className={styles.wrapper}
      dataTestId={`OrganizationItem-organization-${organization.esiaOrgOid}-button`}
      iconButtonDataTestId={`OrganizationItem-organization-${organization.esiaOrgOid}-iconButton`}
      header={
        <div className={styles.head__text}>
          {organization?.name && (
            <Typography.Headline variant="headlineH3" className={styles.title}>
              {organization?.name}
            </Typography.Headline>
          )}
          {organization?.ogrn && (
            <Typography.Body variant="bodyMRegular" className={styles.inn}>
              {organization?.ogrn}
            </Typography.Body>
          )}
        </div>
      }
      onClick={onChooseOrganization}
    />
  )
}

export default memo(OrganizationItem)
