import {
  IProjectFromAllProjects,
  PrepareProjectsToRenderProps,
} from '@services/Projects/Project.entity'

class ProjectService {
  static prepareProjectsToRender({
    projects,
    dfoTypes,
  }: PrepareProjectsToRenderProps): IProjectFromAllProjects[] {
    return projects?.map((project) => {
      const preparedDfo = project.dfos.map((dfo) => {
        return {
          ...dfo,
          name: dfoTypes[dfo.type]?.dfoTitle || dfo.type || '',
        }
      })

      return {
        ...project,
        dfos: preparedDfo,
      }
    })
  }
}

export { ProjectService }
