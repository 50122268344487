import { FC, useContext } from 'react'

import { NotificationsOfRequisitesChangesContext } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/NotificationsOfRequisitesChanges'
import useLoadMore from '@hooks/useLoadMore'
import { IProjectFromAllProjects } from '@services/Projects/Project.entity'

import NotificationItem from './NotificationItem'
import styles from './NotificationsOfRequisitesChanges.module.scss'

export interface INotificationsList {
  projects: IProjectFromAllProjects[] | undefined
  onLoadMore: VoidFunction
}

export const NotificationsList: FC<INotificationsList> = ({ projects, onLoadMore }) => {
  const { isDeletingInProcess } = useContext(NotificationsOfRequisitesChangesContext)
  const { getObserveRef } = useLoadMore({ onLoadMore })

  return (
    <div className={styles.List}>
      {projects?.map(({ dfos, id }, idx) => (
        <NotificationItem
          key={id}
          attachmentLoading={!idx ? isDeletingInProcess : undefined}
          ref={getObserveRef(idx, projects?.length - 1)}
          dfoId={dfos[0].id}
          projectId={id}
        />
      ))}
    </div>
  )
}
