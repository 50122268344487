import React, { FC, memo, useCallback, useEffect, useMemo } from 'react'

import Avatar from '@components/NewDesign/Avatar'
import Button from '@components/NewDesign/Button'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import { useChangeUserEmailModal } from '@components/NewDesignedModals/ChangeUserEmailModal'
import { useSuccessModal } from '@components/NewDesignedModals/SuccessModal/manager'
import UserItemDetails from '@components/OrganizationInfo/Users/Item/UserItemDetails'
import { useOrganizationUserDetailsSidebar } from '@components/Sidebars/OrganizationUserDetails'
import { successModalBodyTexts, successModalHeaderTexts } from '@constants/texts'
import { UserProfiles } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { Transition } from '@headlessui/react'
import { getAllPartsOfName } from '@helpers/name/getAllPartsOfName'
import { useHover } from '@hooks/new/events/useHover'
import EditIcon from '@icons/EditIcon.svg'
import { IOrganizationUser } from '@services/Organization/organization.entity'
import cn from 'classnames'

import styles from './UserItem.module.scss'

type OrganizationUserItemProps = {
  user: IOrganizationUser
  onSave: VoidFunction
}

const Component: FC<OrganizationUserItemProps> = ({ user, onSave }) => {
  const { firstName, lastName, email, isCurrentUser, emailVerified, profile, isEnabled, id } = user

  const { handleOpenSuccessModal } = useSuccessModal()

  const {
    organizationApi: { askEmailApproving },
  } = useAPIContext()

  const {
    state: { tooltipIsOpen, targetTooltipRef },
    handlers: { handleToggleTooltip },
    bindHandlersRef: { bindRefOfClickableOutsideZone },
  } = useManualTooltipControl()

  const { handleOpenOrganizationUserDetailsSidebar } = useOrganizationUserDetailsSidebar()

  useEffect(() => {
    bindRefOfClickableOutsideZone(document.body)
  }, [bindRefOfClickableOutsideZone])

  const [itemRef, isHovered] = useHover<HTMLButtonElement>()

  const preparedName = `${firstName} ${lastName} ${isCurrentUser ? '(Вы)' : ''}`

  const partNameObject = getAllPartsOfName(`${firstName} ${lastName}`)

  const isHavePermissionToSign = profile === UserProfiles.HEADER

  const handleTryApproveEmail = useCallback(
    async (onSuccess: () => void) => {
      try {
        await askEmailApproving()
        onSuccess()
      } catch {}
    },
    [askEmailApproving],
  )

  const handleEmailApprove = useCallback(async () => {
    try {
      await handleTryApproveEmail(() =>
        handleOpenSuccessModal({
          text: `${successModalBodyTexts.checkEmail} ${email}`,
          headerText: successModalHeaderTexts.checkEmail,
        }),
      )
    } catch {}
  }, [email, handleOpenSuccessModal, handleTryApproveEmail])

  const tooltipContent = useMemo(() => {
    return (
      <>
        <Typography.Body
          variant={'bodyMRegular'}
          className={styles['userItem-email-confirm__popover-text']}
        >
          Письмо со ссылкой для подтверждения отправлено Вам на почту{' '}
        </Typography.Body>

        <Button
          fixWidth
          className={styles['userItem-email-confirm__popover-content']}
          size={'s'}
          variant={'buttonSMedium'}
          onClick={handleEmailApprove}
        >
          Отправить ещё раз
        </Button>
      </>
    )
  }, [handleEmailApprove])

  const { handleOpenChangeUserEmail } = useChangeUserEmailModal()

  const handleChangeUserEmail =
    (idOfCurrentUser: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()

      handleOpenChangeUserEmail({
        userInfo: {
          firstName,
          lastName,
          profile,
          id: idOfCurrentUser,
        },
        initialEmail: email || '',
      })
    }

  const handleOpenSidebar = () => {
    handleOpenOrganizationUserDetailsSidebar({
      user,
      onSave,
    })
  }

  const handleToggleEmailTooltip = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.stopPropagation()

    handleToggleTooltip()
  }

  return (
    <button
      type="button"
      ref={itemRef}
      className={styles.userItemWrapper}
      onClick={handleOpenSidebar}
    >
      <div className={styles['userItem__name-wrapper']}>
        <Avatar.Small
          variant={'bodyMSemibold'}
          size={'s'}
          className={cn({
            [styles['userItem__avatar--banned']]: !isEnabled,
          })}
          textClassName={cn({
            [styles['userItem__avatar-text--banned']]: !isEnabled,
          })}
        >
          {`${partNameObject?.name?.[0]}${
            partNameObject?.surname?.[0] || partNameObject?.patronymic?.[0]
          }`}
        </Avatar.Small>
        <TypographyWithTooltip.Body
          variant={'bodyMMedium'}
          className={cn(styles.userItem, styles.userItem__name, {
            [styles['userItem__name--banned']]: !isEnabled,
          })}
          tooltipProps={{
            position: 'top-start',
            offset: [0, 4],
            popoverClassName: styles['userItem-tooltip__popover'],
            contentClassName: styles['userItem-tooltip__content'],
            targetClassName: styles['userItem-tooltip__target'],
          }}
        >
          {preparedName}
        </TypographyWithTooltip.Body>
      </div>
      <div className={cn(styles['userItem-container'])}>
        <Typography.Body
          variant={'bodyMMedium'}
          className={cn(styles.userItem, styles.userItem__sign, {
            [styles['userItem__sign--withoutPermission']]: !isHavePermissionToSign || !isEnabled,
          })}
        >
          {isHavePermissionToSign ? 'с правом подписи' : 'без права подписи'}
        </Typography.Body>

        <div className={styles['userItem-email-wrapper']}>
          {isCurrentUser && isEnabled && !emailVerified && email?.length && (
            <button type="button" className={styles['userItem-email-confirm']}>
              <Tooltip
                targetRef={targetTooltipRef}
                open={tooltipIsOpen}
                targetClassName={styles['userItem-email-confirm__popover-body']}
                popoverClassName={styles['userItem-email-confirm__popover']}
                content={tooltipContent}
                trigger={'click'}
                position={'bottom-start'}
                offset={[-50, 10]}
                contentProps={{
                  onClick: (event) => event.stopPropagation(),
                }}
              >
                <Typography.Body
                  variant={'bodyMMedium'}
                  color={'text-accent-orange-secondary'}
                  onClick={handleToggleEmailTooltip}
                >
                  подтвердите почту
                </Typography.Body>
              </Tooltip>
            </button>
          )}

          <TypographyWithTooltip.Body
            variant={'bodyMMedium'}
            className={cn(styles.userItem, styles['userItem-email'], {
              [styles['userItem-email--banned']]: !isEnabled,
            })}
            tooltipProps={{
              position: 'top',
              offset: [0, 4],
              popoverClassName: styles['userItem-tooltip__popover'],
              contentClassName: styles['userItem-tooltip__content'],
              targetClassName: cn(styles['userItem-tooltip__target'], {
                [styles['userItem-tooltip__target--banned']]: !isEnabled,
              }),
            }}
          >
            {email}
          </TypographyWithTooltip.Body>
        </div>

        <UserItemDetails user={user} />

        {isEnabled && isCurrentUser && (
          <div className={styles['userItem__action-wrapper']}>
            <Transition
              show={isHovered}
              enter={styles.transition__enter}
              enterFrom={styles.transition__enterFrom}
              enterTo={styles.transition__enterTo}
              leave={styles.transition__leave}
              leaveFrom={styles.transition__leaveFrom}
              leaveTo={styles.transition__leaveTo}
            >
              <div
                role={'presentation'}
                className={cn(styles.userItem__action, {
                  [styles['userItem__action--open']]: isHovered,
                })}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <div className={styles['userItem__action-gradient']} />
                <Button
                  size={'s'}
                  view={'plain'}
                  geometry={'square'}
                  variant={'buttonSMedium'}
                  className={styles['userItem__action-button']}
                  leadingIcon={{
                    src: EditIcon,
                  }}
                  onClick={handleChangeUserEmail(id)}
                >
                  Изменить почту
                </Button>
              </div>
            </Transition>
          </div>
        )}
      </div>
    </button>
  )
}

export const OrganizationUserItem = memo(Component)
