import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import { StepsArrayPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/3/types'
import { thirdSectionValidationMap } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/3/validation'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

interface MonitoringStepProps {
  id: string
  formName: StepsArrayPathName
  anchorId: string
}

const MonitoringStep: FC<MonitoringStepProps> = ({ id, formName, anchorId }) => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const {
    getSubscribableControlProps,
    getInputProps,
    getTextareaProps,
    getCalendarInputProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  const factStepStartDate = useWatch({
    name: `${formName}.factStepStartDate`,
    control: formInstance.control,
  })

  const factStepCompensationPeriod = useWatch({
    name: `${formName}.factStepCompensationPeriod`,
    control: formInstance.control,
  })

  const isVatMoney = useWatch({
    name: informationOfExecutionConditionsSZPKBlockValues['2'].isVatMoney,
    control: formInstance?.control,
  })

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate id={id} anchorId={anchorId}>
      <Stack direction={'vertical'} gap={3}>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title={'Номер этапа'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.stepNumber`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.stepNumber`,
                inputProps: {
                  disabled: true,
                  integersOnly: true,
                  hideSuffix: true,
                  hideCurrency: true,
                  hasNegativeValue: false,
                },
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <Group disableBottomBorder title={'Объем капитальных вложений'}>
          <FieldView.ContainerWithSwitchTransition condition={isVatMoney}>
            {(condition) => {
              if (!condition) {
                return (
                  <Stack direction={'vertical'} gap={3}>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      title={'Плановое значение (без НДС)'}
                      firstColumnSize={6}
                      secondColumnSize={6}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.planStepTotalCapitalValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${formName}.planStepTotalCapitalValue`,
                            rules: thirdSectionValidationMap.planStepTotalCapitalValue,
                            onBlur: () =>
                              setTimeout(
                                () => handleChangeValue?.(`${formName}.planStepTotalCapitalValue`),
                                0,
                              ),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${formName}.planStepTotalCapitalValue`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      title={'Фактическое значение (без НДС)'}
                      firstColumnSize={6}
                      secondColumnSize={6}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.factStepTotalCapitalValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${formName}.factStepTotalCapitalValue`,
                            rules: thirdSectionValidationMap.factStepTotalCapitalValue,
                            onBlur: () =>
                              setTimeout(
                                () => handleChangeValue?.(`${formName}.factStepTotalCapitalValue`),
                                0,
                              ),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${formName}.factStepTotalCapitalValue`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                  </Stack>
                )
              }

              return (
                <Stack direction={'vertical'} gap={3}>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Плановое значение (с НДС)'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.planStepTotalCapitalVatValue`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.planStepTotalCapitalVatValue`,
                          rules: thirdSectionValidationMap.planStepTotalCapitalVatValue,
                          onBlur: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.planStepTotalCapitalVatValue`),
                              0,
                            ),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.planStepTotalCapitalVatValue`,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Фактическое значение (с НДС)'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.factStepTotalCapitalVatValue`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.factStepTotalCapitalVatValue`,
                          rules: thirdSectionValidationMap.factStepTotalCapitalVatValue,
                          onBlur: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.factStepTotalCapitalVatValue`),
                              0,
                            ),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.factStepTotalCapitalVatValue`,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                </Stack>
              )
            }}
          </FieldView.ContainerWithSwitchTransition>
        </Group>
        <Group disableBottomBorder title={'Объем капиталовложений'}>
          <FieldView.ContainerWithSwitchTransition condition={isVatMoney}>
            {(condition) => {
              if (!condition) {
                return (
                  <Stack direction={'vertical'} gap={3}>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      title={'Плановое значение (без НДС)'}
                      firstColumnSize={6}
                      secondColumnSize={6}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.stepInvestorCapitalValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${formName}.stepInvestorCapitalValue`,
                            inputProps: {
                              disabled: true,
                            },
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      title={'Фактическое значение (без НДС)'}
                      firstColumnSize={6}
                      secondColumnSize={6}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.factStepInvestorCapitalValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${formName}.factStepInvestorCapitalValue`,
                            rules: thirdSectionValidationMap.factStepInvestorCapitalValue,
                            onBlur: () =>
                              setTimeout(
                                () =>
                                  handleChangeValue?.(`${formName}.factStepInvestorCapitalValue`),
                                0,
                              ),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${formName}.factStepInvestorCapitalValue`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                  </Stack>
                )
              }

              return (
                <Stack direction={'vertical'} gap={3}>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Плановое значение (с НДС)'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.stepInvestorCapitalValueVat`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.stepInvestorCapitalValueVat`,
                          inputProps: {
                            disabled: true,
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Фактическое значение (с НДС)'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.factStepInvestorCapitalVatValue`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.factStepInvestorCapitalVatValue`,
                          rules: thirdSectionValidationMap.factStepInvestorCapitalVatValue,
                          onBlur: () =>
                            setTimeout(
                              () =>
                                handleChangeValue?.(`${formName}.factStepInvestorCapitalVatValue`),
                              0,
                            ),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.factStepInvestorCapitalVatValue`,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                </Stack>
              )
            }}
          </FieldView.ContainerWithSwitchTransition>
        </Group>
        <Group disableBottomBorder title={'Объем планируемых к возмещению затрат'}>
          <FieldView.ContainerWithSwitchTransition condition={isVatMoney}>
            {(condition) => {
              if (!condition) {
                return (
                  <Stack direction={'vertical'} gap={3}>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      title={'Плановое значение (без НДС)'}
                      firstColumnSize={6}
                      secondColumnSize={6}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.stepCompensationValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${formName}.stepCompensationValue`,
                            inputProps: {
                              disabled: true,
                            },
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      title={'Фактическое значение (без НДС)'}
                      firstColumnSize={6}
                      secondColumnSize={6}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.factStepCompensationValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${formName}.factStepCompensationValue`,
                            rules: thirdSectionValidationMap.factStepCompensationValue,

                            onBlur: () =>
                              setTimeout(
                                () => handleChangeValue?.(`${formName}.factStepCompensationValue`),
                                0,
                              ),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${formName}.factStepCompensationValue`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                  </Stack>
                )
              }

              return (
                <Stack direction={'vertical'} gap={3}>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Плановое значение (с НДС)'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.factStepCompensationVatValue`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.factStepCompensationVatValue`,
                          inputProps: {
                            disabled: true,
                          },
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    title={'Фактическое значение (с НДС)'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.factStepCompensationValueVat`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${formName}.factStepCompensationValueVat`,
                          rules: thirdSectionValidationMap.factStepCompensationValue,

                          onBlur: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.factStepCompensationValueVat`),
                              0,
                            ),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                `${formName}.factStepCompensationValueVat`,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                </Stack>
              )
            }}
          </FieldView.ContainerWithSwitchTransition>
        </Group>
        <Group disableBottomBorder title={'Планируемый срок возмещения затрат'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.stepCompensationPeriod`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.stepCompensationPeriod`,
                      inputProps: {
                        disabled: true,
                        label: 'Плановое значение',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder disableBottomDefaultStyles title={'Фактическое начало возмещения'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.factStepCompensationPeriod`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${formName}.factStepCompensationPeriod`,
                        calendarProps: {
                          typeOfPicker: 'years',
                          dateFormat: 'YYYY',
                        },
                        calendarInputProps: {
                          inputMask: YEAR_MASK,
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepCompensationPeriod`)
                          }, 0),
                        onCalendarChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepCompensationPeriod`)
                          }, 0),
                        onInputChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.factStepCompensationPeriod`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title={'Фактическое окончание возмещения'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.factStepCompensationEndPeriod`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${formName}.factStepCompensationEndPeriod`,
                        rules: {
                          validate: {
                            positiveStartPreinvestStage: (value) =>
                              !factStepCompensationPeriod ||
                              (isDateValidForDayjs(value) &&
                                DayjsService.dayjs(value, objOfDateFormats.yearFormat.yearOnly) >=
                                  DayjsService.dayjs(
                                    factStepCompensationPeriod,
                                    objOfDateFormats.yearFormat.yearOnly,
                                  )) ||
                              'Дата фактического окончания возмещения должна быть больше или равна дате фактического начала возмещения',
                          },
                        },
                        calendarProps: {
                          typeOfPicker: 'years',
                          dateFormat: 'YYYY',
                        },
                        calendarInputProps: {
                          inputMask: YEAR_MASK,
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepCompensationEndPeriod`)
                          }, 0),
                        onCalendarChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepCompensationEndPeriod`)
                          }, 0),
                        onInputChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${formName}.factStepCompensationEndPeriod`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Количество рабочих мест (единиц)'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <RowWithBorder disableBottomDefaultStyles title={'Плановое значение'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.planStepWorkPlace`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${formName}.planStepWorkPlace`,
                        rules: thirdSectionValidationMap.planStepWorkPlace,
                        inputProps: {
                          suffix: '',
                          integersOnly: true,
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.planStepWorkPlace`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.planStepWorkPlace`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder disableBottomDefaultStyles title={'Фактическое значение'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.factStepWorkPlace`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${formName}.factStepWorkPlace`,
                        rules: thirdSectionValidationMap.factStepWorkPlace,
                        inputProps: {
                          suffix: '',
                          integersOnly: true,
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepWorkPlace`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.factStepWorkPlace`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Срок реализации этапа инвестиционного проекта'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <RowWithBorder disableBottomDefaultStyles title={'Плановая дата начала этапа'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.stepStartDate`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${formName}.stepStartDate`,
                        calendarInputProps: {
                          disabled: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder disableBottomDefaultStyles title={'Плановая дата окончания этапа'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.stepEndDate`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${formName}.stepEndDate`,
                        calendarInputProps: {
                          disabled: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder disableBottomDefaultStyles title={'Фактическая дата начала этапа'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.factStepStartDate`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${formName}.factStepStartDate`,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepStartDate`)
                          }, 0),
                        onCalendarChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepStartDate`)
                          }, 0),
                        onInputChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.factStepStartDate`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder title={'Фактическая дата окончания этапа'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.factStepEndDate`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${formName}.factStepEndDate`,
                        rules: {
                          validate: {
                            positiveFactStepStartDate: (value) => {
                              return (
                                !factStepStartDate ||
                                (isDateValidForDayjs(value) &&
                                  dayjs(value, objOfDateFormats.defaultFormat) >
                                    dayjs(factStepStartDate, objOfDateFormats.defaultFormat)) ||
                                'Дата окончания этапа должна быть больше даты начала этапа'
                              )
                            },
                          },
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepEndDate`)
                          }, 0),
                        onCalendarChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${formName}.factStepEndDate`)
                          }, 0),
                        onInputChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.factStepEndDate`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.stepInfoInvestor`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.stepInfoInvestor`,
                      rules: thirdSectionValidationMap.stepInfoInvestor,
                      textareaProps: {
                        label: 'Дополнительная информация о результатах мониторинга этапа',
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.stepInfoInvestor`)),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.stepInfoInvestor`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default MonitoringStep
