import { objOfDateFormats } from '@constants/dateFormats'
import { Patterns } from '@constants/validations'
import type { AuditListProps } from '@context/APIContext/hooks/useOperatorApi'
import { isEmptyString, isNullOrUndefined } from '@helpers/checkTypes'
import { dateWithTime } from '@helpers/format'
import { TableFormField } from '@routes/Audits/constants'
import DayjsService from '@services/Dayjs/Dayjs.service'

const defaultStartTime = '00:00'
const defaultEndTime = '23:59'
const defaultTimeFormats = ['0', '0']

const initialResponse: AuditListProps = {
  from: '',
  to: '',
} as const

export const calculateCountOfActiveFilters = (filters: TableFormField) => {
  const resolveCalc = {
    userId: filters.userId,
    actionType: filters.actionType,
    organizationRole: filters.organizationRole,
    searchString: false,
    date: !!(filters.dateFrom.date || filters.dateTo.date),
  }

  return Object.values(resolveCalc).reduce((acc: number, item) => {
    return item ? acc + 1 : acc
  }, 0)
}

const isValidTime = (str: string) => !(str === '' || str.includes('_'))

export const getDefaultDateValues = (date: string, formState: TableFormField) => {
  if (!isValidTime(date)) return null

  return {
    dateFrom: {
      date: formState.dateFrom.date || date,
      time: formState.dateFrom.time || defaultStartTime,
    },
    dateTo: {
      date: formState.dateTo.date || date,
      time: formState.dateTo.time || defaultEndTime,
    },
  }
}

export const dataFormToDataParameters = ({
  dateFrom,
  dateTo,
  ...formFields
}: TableFormField): AuditListProps => {
  const dateFromField = { ...dateFrom, key: 'from' }
  const dateToField = { ...dateTo, key: 'to' }

  const dateFields = [dateFromField, dateToField].reduce((acc, { date, time, key }) => {
    if (!date.match(Patterns.DayMonthYear)) return acc

    const [hours, minutes] = isValidTime(time) ? time.split(':') : defaultTimeFormats

    acc[key] = dateWithTime(
      DayjsService.dayjs(date, objOfDateFormats.defaultFormat),
      Number(hours),
      Number(minutes),
    )

    return acc
  }, initialResponse)

  return Object.entries(formFields).reduce(
    (acc, [key, value]) =>
      isEmptyString(value) || isNullOrUndefined(value)
        ? acc
        : {
            ...acc,
            [key]: value,
          },
    dateFields,
  )
}
