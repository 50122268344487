import { SystemVariablesFormValues } from '@components/SystemVariables/types'

const defaultSystemVariablesFormValue: SystemVariablesFormValues = {
  variables: [],
}

const systemVariablesTextMap = {
  MONITORING_SZPK_REPORT_APPROVE_ENDS_AT: 'Контрольный срок подачи отчета ОРП',
}

export { defaultSystemVariablesFormValue, systemVariablesTextMap }
