import { BeneficiarStep } from "@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/types"

const defaultBeneficiarItemValue: BeneficiarStep = {
  id: '',
  beneficiarName: '',
  beneficiarBirthPlace: '',
  beneficiarBirthDate: '',
  beneficiarNationality: null,
  otherNationality: false,
  beneficiarNationalityCountry: null,
  beneficiarSecondNationalityCountries: [],
  beneficiarInn: '',
  beneficiarDocument: null,
  beneficiarDocumentSeries: '',
  beneficiarDocumentNumber: '',
  beneficiarDocumentDate: '',
  beneficiarDocumentOrganization: '',
  beneficiarDocumentCodeOrganization: '',
  beneficiarAddress: '',
  beneficiarMigrationNumber: '',
  beneficiarMigrationStartDate: '',
  beneficiarMigrationEndDate: '',
  beneficiarResidenceNumber: '',
  beneficiarResidenceStartDate: '',
  beneficiarResidenceEndDate: '',
  beneficiarPart: '',
  beneficiarReason: '',
}

export { defaultBeneficiarItemValue }
