import React from 'react'

import { PrimaryTabListResponsive } from '@components/NewDesign/Tabs'

import { TabsProps } from '../../types'

import { Tabs } from './Component'

const views = {
  primary: PrimaryTabListResponsive,
  //Добавление новых view
}

export type TabsResponsiveProps = Omit<TabsProps, 'TabList' | 'collapsedTabsIds'>

export const TabsResponsive = ({
  view = 'primary',
  scrollable = false,
  ...restProps
}: TabsResponsiveProps) => <Tabs TabList={views[view]} scrollable={scrollable} {...restProps} />
