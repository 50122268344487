import { generatePropertiesAAgreementAccessionMunicipalForm } from '@components/Forms/AAgreementAccessionMunicipalForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAAgreementAccessionMunicipalForm } from '@components/Forms/AAgreementAccessionMunicipalForm/adapters/RHF.adapter'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'

const useAAgreementAccessionMunicipalAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): AAgreementAccessionMunicipalFormValues =>
    generateRHFObjectForAAgreementAccessionMunicipalForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesAAgreementAccessionMunicipalForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useAAgreementAccessionMunicipalAdapters }
