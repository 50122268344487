import { useCallback } from 'react'

import { useFormControl } from '@components/DocumentFormComponents/hooks/useFormControl'
import {
  SuccessInitialCallbackReturn,
  useInitializeForm,
} from '@components/DocumentFormComponents/hooks/useInitializeForm'

type SetFormLoadingHandler = (state: boolean) => void

interface HandleMountFormProps {
  onInitializeForm: (props: SuccessInitialCallbackReturn) => Promise<void>
  onAfterInitializeForm: (setFormLoading: SetFormLoadingHandler) => Promise<void>
  onErrorInitializeForm?: VoidFunction
}

const useFormMount = (formId: string) => {
  const { formState, intervalState } = useFormControl()

  const { initializeForm } = useInitializeForm({
    formId,
  })

  const handleMountForm = useCallback(
    async ({ onInitializeForm, onAfterInitializeForm, onErrorInitializeForm }: HandleMountFormProps) => {
      formState.setFormLoading(true)

      try {
        await initializeForm(onInitializeForm)

        await onAfterInitializeForm(formState.setFormLoading)
      } catch (error) {
        formState.setFormLoading(false)

        onErrorInitializeForm?.()

        throw error
      }
    },
    [formState, initializeForm],
  )

  return {
    formIsLoading: formState.isFormLoading,
    intervalState,
    handleMountForm,
  }
}

export type { SetFormLoadingHandler }
export { useFormMount }
