import React, { DetailedHTMLProps, FC, ReactNode } from 'react'

import Icon, { IconProps } from '@components/Icon'
import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Typography from '@components/NewDesign/Typography'
import { ITypographyBody, ITypographyHeadline } from '@components/NewDesign/Typography/types'
import env from '@config'
import Error500Icon from '@icons/500ErrorIcon.svg'
import refreshIcon from '@icons/navigation/refresh.svg'
import cn from 'classnames'

import styles from './Error500Fallback.module.scss'

type Error500PlaceholderProps = DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  title: string
  error: boolean
  children?: ReactNode
  titleProps?: Partial<ITypographyHeadline>
  descriptionProps?: Partial<ITypographyBody>
  buttonProps?: Pick<ButtonProps, 'size'>
  iconProps?: Partial<IconProps>
}

const SUPPORT_EMAIL = env.REACT_APP_SUPPORT_EMAIL

const Error500Fallback: FC<Error500PlaceholderProps> = ({
  title,
  error,
  titleProps,
  descriptionProps,
  buttonProps,
  iconProps,
  children,
  ...divRest
}) => {
  const { className, ...restIconProps } = iconProps || {}

  const handleReloadPage = () => {
    window.location.reload()
  }

  if (!error) return <>{children}</>

  return (
    <div {...divRest} className={cn(styles.error500Placeholder, divRest.className)}>
      <Icon
        noCurrentColorSvgFill
        src={Error500Icon}
        size={'initial'}
        className={cn(styles.error500Placeholder__errorIcon, className)}
        {...restIconProps}
      />
      <div className={styles.error500Placeholder__contentBlock}>
        <div className={styles.error500Placeholder__content}>
          <Typography.Headline as={'span'} variant={'headlineH2'} {...titleProps}>
            {title}
          </Typography.Headline>
          <Typography.Body
            className={styles['error500Placeholder__content-subtitle']}
            variant={'bodyLMedium'}
            color={'text-base-tertiary'}
            {...descriptionProps}
          >
            Произошла внутренняя ошибка сервера <br />
            Попробуйте обновить страницу. Если это не помогло - подождите,
            <br />
            мы скоро её исправим
          </Typography.Body>
        </div>
        <div className={styles.error500Placeholder__actions}>
          <Button
            view={'gray'}
            className={styles.button}
            dataTestId="Error500Fallback-reloadPage-button"
            leadingIcon={{
              wrapper: 'span',
              className: styles.error500Placeholder__icon,
              src: refreshIcon,
            }}
            onClick={handleReloadPage}
            {...buttonProps}
          >
            <Typography.Body variant={'bodyLMedium'} color={'text-accent-brand'}>
              Обновить страницу
            </Typography.Body>
          </Button>

          <Button
            view={'plain'}
            href={`mailto:${SUPPORT_EMAIL}`}
            dataTestId="Error500Fallback-support-button"
            className={styles.button}
            {...buttonProps}
          >
            <Typography.Body as={'span'} variant={'bodyLMedium'} color={'text-accent-brand'}>
              Сообщить в поддержку
            </Typography.Body>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Error500Fallback
