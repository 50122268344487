import { memo, useCallback } from 'react'

import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { useLetterHeadPartsControl } from '@components/Sidebars/LetterHeadParts'
import { letterHeadPartsBaseTexts } from '@constants/texts'
import { RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { useOrganizationBlankFiles } from '@hooks/new/swr/useOrganizationBlankFiles'
import { useOrganizationBlankPreview } from '@hooks/new/swr/useOrganizationBlankPreview'
import cn from 'classnames'

import styles from './LetterHeadParts.module.scss'
import { OrganizationLetterHeadPartsProps } from './types'

const OrganizationLetterHeadParts = ({ organizationId }: OrganizationLetterHeadPartsProps) => {
  const { getBlankPreview, isPreviewPending } = useOrganizationBlankPreview()

  const { files, isLoading: isBlankPending } = useOrganizationBlankFiles(organizationId)

  const { openLetterHeadPartsControl } = useLetterHeadPartsControl()

  const handleOpenLetterHeadPartsSidebar = () => openLetterHeadPartsControl({ organizationId })

  const handlePreviewBlank = useCallback(() => {
    if (!files) return

    getBlankPreview(files)
  }, [files, getBlankPreview])

  const { checkingRole } = useAuthContext()

  const isMER = !!checkingRole?.(RolesTypes.MER)
  const isOIV = !!checkingRole?.(RolesTypes.OIV)
  const isROIV = !!checkingRole?.(RolesTypes.ROIV)
  const isOMSU = !!checkingRole?.(RolesTypes.OMSU)
  const isUO = !!checkingRole?.(RolesTypes.UO)
  const isAvailable = isMER || isOIV || isROIV || isOMSU || isUO

  if (!isAvailable) return <></>

  const isNotEmptyBlank = !!files && files.length > 0

  return (
    <div
      className={cn(styles.organizationLetterHeadParts, {
        [styles.emptyBlankBackground]: !isNotEmptyBlank,
      })}
    >
      <Loader loading={isBlankPending && !isNotEmptyBlank}>
        <div className={styles.leftSide}>
          {isNotEmptyBlank ? (
            <Typography variant={'headlineH3'}>
              {letterHeadPartsBaseTexts.main.header.notEmpty}
            </Typography>
          ) : (
            <Typography variant={'headlineH3'}>
              {letterHeadPartsBaseTexts.main.header.empty}
            </Typography>
          )}

          <Typography variant="bodyMRegular" className={styles.organizationLetterHeadPartsText}>
            {isNotEmptyBlank
              ? letterHeadPartsBaseTexts.main.text.notEmpty
              : letterHeadPartsBaseTexts.main.text.empty}
          </Typography>
          {!isNotEmptyBlank && (
            <Button
              className={styles.organizationLetterHeadButton}
              onClick={handleOpenLetterHeadPartsSidebar}
            >
              {letterHeadPartsBaseTexts.main.buttons.edit}
            </Button>
          )}
        </div>
        {isNotEmptyBlank && (
          <div className={styles.rightSide}>
            <Button
              loaderProps={{ loading: isPreviewPending, placement: 'trailing', variant: 'lite' }}
              disabled={isPreviewPending}
              onClick={handlePreviewBlank}
            >
              {letterHeadPartsBaseTexts.main.buttons.open}
            </Button>
            <Button view="tined" onClick={handleOpenLetterHeadPartsSidebar}>
              {letterHeadPartsBaseTexts.main.buttons.change}
            </Button>
          </div>
        )}
      </Loader>
    </div>
  )
}

export default memo(OrganizationLetterHeadParts)
