import { useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import ConfidentialModal from '@components/Modals/ConfidentialModal/index'

export interface RegistrationSubmitProps {
  isUserContinue?: boolean
  signature?: string
  thumbprint?: string
}

export interface HandleOpenConfidentialModalProps {
  onSubmit: VoidFunction
  onClose: VoidFunction
}

const useConfidentialModal = () => {
  const popupManager = usePopupManager()

  const handleOpenConfidentialModal = useCallback(
    ({ onSubmit, onClose }: HandleOpenConfidentialModalProps) => {
      popupManager.open(ConfidentialModal, {
        onClose,
        onSubmit,
      })
    },
    [popupManager],
  )

  return {
    handleOpenConfidentialModal,
  }
}

export default useConfidentialModal
