import { useBooleanState } from '@hooks/useBooleanState'

export const useOpenState = (defaultState?: boolean) => {
  const {
    booleanState: isEntityOpen,
    setBooleanStateToFalse: closeEntity,
    setBooleanStateToTrue: openEntity,
    reverseBooleanState: reverseIsEntityOpen,
  } = useBooleanState(defaultState)

  return {
    isEntityOpen,
    closeEntity,
    openEntity,
    reverseIsEntityOpen,
  }
}
