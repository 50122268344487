import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fifthSectionValidationMap: FormValuesValidationSection<
  keyof InformationOfExecutionConditionsSZPKFormValues['5']['dismantlingObjects'][number],
  InformationOfExecutionConditionsSZPKFormValues
> = {
  dismantlingObjectName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { fifthSectionValidationMap }
