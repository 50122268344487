import React, { FC, memo } from 'react'

import { WebFormStateControllerProps } from '@components/Attachments/WebForm/WIthoutDocumentController/types'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import Button from '@components/NewDesign/Button'
import env from '@config'

const WebFormMovingStateController: FC<WebFormStateControllerProps> = ({
  documentInfoFromDict,
}) => {
  const { description: title } = documentInfoFromDict

  return (
    <ControlContainer
      color="negative"
      title={title}
      leadingNodeContent={<ControlContainerStatusIcon iconType="error" />}
      additionalMainContent={
        <ControlContainerSubtitle
          additionalContent={
            <Button
              href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}
              size="xs"
              view="text"
              variant="buttonSMedium"
              color="negative"
            >
              Сообщить в поддержку
            </Button>
          }
        >
          Произошла ошибка получения формы документа.
        </ControlContainerSubtitle>
      }
    />
  )
}

export default memo(WebFormMovingStateController)
