import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultProjectSZPKFormValue } from '@components/Forms/ProjectSZPKForm/const'
import ProjectSZPKLayout from '@components/Forms/ProjectSZPKForm/Layout'

const ProjectSZPKLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultProjectSZPKFormValue}>
      <ProjectSZPKLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(ProjectSZPKLayoutWithFormProvider)
