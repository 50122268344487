import React, { DetailedHTMLProps, FC, FormHTMLAttributes, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { useNoticeOfRefuseConcludeAAManager } from '@components/Forms/NoticeOfRefuseConcludeAAForm/Manager'
import { NoticeOfRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/types'

import { mapOfNoticeOfRefuseConcludeAAMenu } from '../const'
import NoticeOfRefuseConcludeAAForms from '../Forms'

type NoticeOfRefuseConcludeAAFormProps = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>

const mapOfNoticeOfRefuseConcludeAAForms: FormItemProps = {
  '1': {
    node: <NoticeOfRefuseConcludeAAForms.First />,
  },
  '2': {
    node: <NoticeOfRefuseConcludeAAForms.Second />,
  },
  '3': {
    node: <NoticeOfRefuseConcludeAAForms.Third />,
  },
  '4': {
    node: <NoticeOfRefuseConcludeAAForms.Fourth />,
  },
} as const

const NoticeOfRefuseConcludeAAForm: FC<NoticeOfRefuseConcludeAAFormProps> = ({ ...rest }) => {
  const formInstance = useFormContext<NoticeOfRefuseConcludeAAFormValues>()

  const {
    state: { formIsLoading },
  } = useNoticeOfRefuseConcludeAAManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNoticeOfRefuseConcludeAAMenu}
      mapOfForms={mapOfNoticeOfRefuseConcludeAAForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NoticeOfRefuseConcludeAAForm)
