import { FC } from 'react'

import First from './1'
import Second from './2'

interface NotificationOfChangeDocumentsFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const NotificationOfChangeDocumentsForms: NotificationOfChangeDocumentsFormsProps = () => null

NotificationOfChangeDocumentsForms.First = First
NotificationOfChangeDocumentsForms.Second = Second

export default NotificationOfChangeDocumentsForms
