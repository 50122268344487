import {
  GenerateAddressInOneStringProps,
  GenerateOtherDetailsInOneStringProps,
} from '@components/OrganizationInfo/types'
import { compact } from '@helpers/array/compact'
import { IOrganizationUser } from '@services/Organization/organization.entity'

class OrganizationInfoHelpersService {
  static mainUserToFirstPositionCompare(user?: IOrganizationUser) {
    if (user?.isCurrentUser) return -1

    return 0
  }

  static generateAddressInOneString({
    index,
    region,
    city,
    district,
    planningStructureElement,
    roadNetworkElement,
    room,
    roomPart,
    subject,
    house,
    area,
  }: GenerateAddressInOneStringProps): string {
    const addressValue = compact([
      index,
      region,
      subject,
      district,
      area,
      city,
      planningStructureElement,
      roadNetworkElement,
      house,
      room,
      roomPart,
    ])

    if (!addressValue.length) return '-'

    return addressValue.join(', ')
  }

  static generateOtherDetails({
    memberAddressEgrul,
    memberFax,
    memberPhone,
    memberPhoneExtension,
    memberEmail,
  }: GenerateOtherDetailsInOneStringProps): string {
    const details: string[] = []

    // Адрес
    if (memberAddressEgrul) {
      details.push(`Адрес: ${memberAddressEgrul}`)
    }

    // Адрес электронной почты
    if (memberEmail) {
      details.push(`Адрес электронной почты: ${memberEmail}`)
    }

    // Телефон с условием для добавочного номера
    if (memberPhone) {
      if (memberPhoneExtension) {
        details.push(`Телефон: ${memberPhone} (${memberPhoneExtension})`)
      } else {
        details.push(`Телефон: ${memberPhone}`)
      }
    }

    // Факс (показывается только если заполнен)
    if (memberFax) {
      details.push(`Факс: ${memberFax}`)
    }

    // Возврат строки с разделением на абзацы
    return details.length > 0 ? details.join('\n') : ''
  }
}

export { OrganizationInfoHelpersService }
