import { ChipBaseProps } from '@components/Chip/Base'
import { defaultStageStatuses } from '@constants/types'
import {
  ProjectStatuses,
  StatusById,
  SZPKStagesWithAvailableStatusText,
} from '@hooks/new/statuses/useProjectStatusOnTheProjectPage'
import { IProjectFromAllProjects } from '@services/Projects/Project.entity'

type GetProjectStatusProps = Pick<
  IProjectFromAllProjects,
  'szpkRegisteredDate' | 'szpkRegisteredNumber'
> & { stage: SZPKStagesWithAvailableStatusText | string }

type GetProjectStatus = (props: Partial<GetProjectStatusProps>) => StatusById[number]

const secondaryAccentChipProps: typeof chipPropsByVariant['_FALLBACK'] = {
  priority: 'secondary',
  variant: 'accent',
}

const chipPropsByVariant: Record<
  SZPKStagesWithAvailableStatusText,
  Pick<ChipBaseProps, 'variant' | 'priority'>
> = {
  REGISTERED: { priority: 'secondary', variant: 'success' },
  TERMINATING: secondaryAccentChipProps,
  TERMINATED: secondaryAccentChipProps,
  REJECTED: secondaryAccentChipProps,
  STOPPED: secondaryAccentChipProps,
  INITIATOR_REVOKED: secondaryAccentChipProps,

  _FALLBACK: { priority: 'primary', variant: 'brand' },
}

export const getProjectStatus: GetProjectStatus = ({
  szpkRegisteredDate,
  szpkRegisteredNumber,
  stage,
}) => {
  switch (stage) {
    case defaultStageStatuses.REGISTERED:
      return {
        status: ProjectStatuses.REGISTERED,
        additionalText: `№ ${szpkRegisteredNumber} от ${szpkRegisteredDate}`,
        chipProps: chipPropsByVariant[defaultStageStatuses.REGISTERED],
      }

    case defaultStageStatuses.TERMINATING:
      return {
        status: ProjectStatuses.TERMINATING,
        chipProps: chipPropsByVariant.TERMINATING,
      }

    case defaultStageStatuses.TERMINATED:
      return {
        status: ProjectStatuses.TERMINATED,
        chipProps: chipPropsByVariant.TERMINATED,
      }

    case 'INITIATOR_REVOKED':
      return {
        status: ProjectStatuses.INITIATOR_REVOKED,
        chipProps: chipPropsByVariant.INITIATOR_REVOKED,
      }

    case 'REJECTED':
      return {
        status: ProjectStatuses.REJECTED,
        chipProps: chipPropsByVariant.REJECTED,
      }

    case 'STOPPED':
      return {
        status: ProjectStatuses.STOPPED,
        chipProps: chipPropsByVariant.STOPPED,
      }

    default:
      return {
        status: ProjectStatuses._FALLBACK,
        chipProps: chipPropsByVariant._FALLBACK,
      }
  }
}
