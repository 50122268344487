import { UseFormReturn, useWatch } from 'react-hook-form'

import { objOfDateFormats } from '@constants/dateFormats'
import DayjsService from '@services/Dayjs/Dayjs.service'

import { indicatorsOfUnfulfilledAgreementReportBlockValues } from '../const'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '../types'

export const useMonitoringReportDateSubtitle = (
  formInstance: UseFormReturn<IndicatorsOfUnfulfilledAgreementReportFormValues, any>,
) => {
  const monitoringReportDate = useWatch({
    name: indicatorsOfUnfulfilledAgreementReportBlockValues['1'].monitoringReportDate,
    control: formInstance.control
  })

  const monitoringReportDateFullMonth = monitoringReportDate
    ? DayjsService.dayjs(monitoringReportDate, objOfDateFormats.defaultFormat).format(
        objOfDateFormats.formatWithFullMonth,
      )
    : ''

  const monitoringReportDateSubtitle = monitoringReportDateFullMonth
    ? `на ${monitoringReportDateFullMonth}`
    : ''

  return monitoringReportDateSubtitle
}
