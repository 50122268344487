import { useMemo } from 'react'

const useScrollState = () => {
  const htmlSelector = useMemo(() => document.querySelector('html'), [])

  const handleBlockScroll = () => {
    if (!htmlSelector) return

    htmlSelector.style.overflow = 'hidden'
  }

  const handleUnblockScroll = () => {
    if (!htmlSelector) return

    htmlSelector.style.overflow = 'visible'
  }

  return {
    handleBlockScroll,
    handleUnblockScroll,
  }
}

export { useScrollState }
