import { ComponentType } from 'react'
import { Navigate } from 'react-router'

import { Paths } from '@constants/paths'
import { isLoginByLP } from '@constants/system'

function withBuildTypeProtect<T = any>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    if (isLoginByLP) {
      return <Navigate replace to={Paths.Login} />
    }

    return <WrappedComponent {...props} />
  }
}

export default withBuildTypeProtect
