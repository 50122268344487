import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof SupplementalCostSharingAFormValues['4'],
  SupplementalCostSharingAFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionValidationMap }
