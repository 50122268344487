import { FocusEvent, MouseEvent } from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import MaskedInput from './MaskedInput'
import { MaskedInputProps } from './types'

export type TControlledMaskInputProps<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> &
  Pick<
    MaskedInputProps,
    'onBlur' | 'onChange' | 'onFocus' | 'onPaste' | 'onClear' | 'onKeyDown'
  > & {
    inputProps: Omit<
      MaskedInputProps,
      | 'onBlur'
      | 'onChange'
      | 'onFocus'
      | 'onPaste'
      | 'onClear'
      | 'onKeyDown'
      | 'value'
      | 'ref'
      | 'error'
    >
  }

export const ControlledMaskInput = <T extends FieldValues>(props: TControlledMaskInputProps<T>) => {
  const { onBlur, onChange, onFocus, onPaste, onClear, onKeyDown, inputProps, ...controllerProps } =
    props

  const {
    field: { onChange: onFormChange, onBlur: onFormBlur, value, ref },
    fieldState: { error },
  } = useController(controllerProps)

  const mergedOnChange = (e) => {
    onChange?.(e)
    onFormChange(e)
  }

  const mergedOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e)

    onFormBlur()
  }

  const mergedOnClear = (e: MouseEvent<HTMLButtonElement>) => {
    onClear?.(e)

    onFormChange('')
  }

  return (
    <MaskedInput
      value={value}
      ref={ref}
      error={error}
      onBlur={mergedOnBlur}
      onChange={mergedOnChange}
      onFocus={onFocus}
      onPaste={onPaste}
      onClear={mergedOnClear}
      onKeyDown={onKeyDown}
      {...inputProps}
    />
  )
}

ControlledMaskInput.displayName = 'ControlledMaskInput'
