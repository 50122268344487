import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnCreationOtherEstateObjectsBlockValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import MainTemplate from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import {
  AAgreementOnCreationOtherEstateObjectsFieldCollapseControlUpdateWatcher,
  AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'

interface AAgreementOnCreationOtherEstateObjectsStageProps {
  id: string
  indexOfStage: number
  initialAccordionState?: boolean
}

const AAgreementOnCreationOtherEstateObjectsStage: FC<
  AAgreementOnCreationOtherEstateObjectsStageProps
> = ({ id, indexOfStage, initialAccordionState }) => {
  const formInstance = useFormContext<AAgreementOnCreationOtherEstateObjectsFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const formName =
    `${aAgreementOnCreationOtherEstateObjectsBlockValues['5'].constructionStages}.${indexOfStage}` as const

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name: formName,
    watcher: AAgreementOnCreationOtherEstateObjectsFieldCollapseControlUpdateWatcher,
    initialExpanded: initialAccordionState,
  })

  const { stepDescription } = useWatch({
    name: formName,
    control: formInstance.control,
  })

  return (
    <SubscribableControl
      {...getSubscribableControlProps({
        path: formName,
      })}
    >
      <Accordion
        id={id}
        anchorId={formName}
        title={stepDescription}
        initialAccordionState={initialAccordionState}
        isExpanded={isExpanded}
        onToggle={onToggleCollapse}
      >
        <MainTemplate indexOfMainBlock={indexOfStage} name={formName} />
      </Accordion>
    </SubscribableControl>
  )
}

export default AAgreementOnCreationOtherEstateObjectsStage
