import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import ParametersApplicationReimbursementExpenses from '@components/Forms/ParametersApplicationReimbursementExpensesForm'
import { defaultParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/const'
import { parametersApplicationReimbursementExpensesMenuState } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Menu/const'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import { ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import ParametersApplicationReimbursementExpensesLayoutWrapper from './Wrapper'

const ParametersApplicationReimbursementExpensesLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  rolesOfUser,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance =
    useFormWithDefaultProps<ParametersApplicationReimbursementExpensesFormValues>({
      defaultValues: defaultParametersApplicationReimbursementExpensesFormValues,
    })

  const handleOnClose = () => {
    ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <ParametersApplicationReimbursementExpenses.MenuManager
          initialMenuHash={parametersApplicationReimbursementExpensesMenuState}
          initialSectionId={parametersApplicationReimbursementExpensesMenuState[0].id}
        >
          <ParametersApplicationReimbursementExpenses.Manager
            formId={formId}
            projectId={projectId}
            rolesOfUser={rolesOfUser}
            initialErrorsFromViolations={initialErrorsFromViolations}
            editMode={editMode}
            onClose={handleOnClose}
          >
            <ParametersApplicationReimbursementExpensesLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Данные о фактически предоставленном возмещении затрат
                </Typography.Headline>
                <ParametersApplicationReimbursementExpenses.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper'])}>
                <ParametersApplicationReimbursementExpenses.Menu className={styles.layout__menu} />
              </div>
            </ParametersApplicationReimbursementExpensesLayoutWrapper>
          </ParametersApplicationReimbursementExpenses.Manager>
        </ParametersApplicationReimbursementExpenses.MenuManager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(ParametersApplicationReimbursementExpensesLayout)
