import { IDocumentTypeInfoWithDict } from '@services/Documents/documents.entity'

const getAllDfoDocumentsInArray = (infos: IDocumentTypeInfoWithDict[]) => {
  const arrayOfDocuments = infos.map((currentItem) => {
    if (!currentItem.folders?.length && !currentItem.documents?.length) {
      return []
    }

    const readyDocuments = currentItem.documents?.length ? currentItem.documents : []

    const readyDocumentsFromFolders = currentItem.folders?.length
      ? currentItem.folders.map((folder) => folder.documents).flat(1)
      : []

    return [...readyDocuments, ...readyDocumentsFromFolders]
  })

  return arrayOfDocuments.flat(1)
}

export { getAllDfoDocumentsInArray }
