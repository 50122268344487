import React, { FC, memo } from 'react'

import { Skeleton } from '@components/Skeleton/Skeleton'
import { SkeletonText } from '@components/Skeleton/Text/SkeletonText'

import styles from './ProjectsPageLoader.module.scss'

const COUNT_OF_FAKE_PROJECTS = 3

interface ProjectsPageLoaderProps {
  countOfEntity?: number
}

//INVESTOR PAGE
const ProjectsPageLoader: FC<ProjectsPageLoaderProps> = ({ countOfEntity }) => {
  return (
    <div className={styles.skeleton}>
      {Array(countOfEntity ?? COUNT_OF_FAKE_PROJECTS)
        .fill(0)
        .map((_, index) => (
          <div key={index} className={styles.skeleton__item}>
            <Skeleton animate visible className={styles.skeleton__chip} />
            <SkeletonText animate visible className={styles.skeleton__title} />
            <Skeleton animate visible className={styles.skeleton__projects} />
            <Skeleton animate visible className={styles.skeleton__collapse} />
          </div>
        ))}
    </div>
  )
}

export default memo(ProjectsPageLoader)
