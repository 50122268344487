import React, { FC } from 'react'

import {
  GroupOfOptions,
  isOptionsGroup,
  OptionProps,
  OptionsVariant,
  OptionsView,
} from '@components/NewDesign/Select/model'
import Options from '@components/NewDesign/Select/OptionsNew'
import styles from '@components/NewDesign/Select/OptionsNew/Group/OptionsGroup.module.scss'

interface OptionsControllerProps {
  option: GroupOfOptions | OptionProps
  onClick: (value: OptionProps) => void
  variant?: OptionsVariant
  view?: OptionsView
  selected: OptionProps | OptionProps[] | null
  optionClassName?: string
  DisplayValueFC?: FC<OptionProps>
}

const OptionsController: FC<OptionsControllerProps> = ({
  variant = 'primary',
  view = 'normal',
  selected,
  option,
  onClick,
  optionClassName,
  DisplayValueFC,
}) => {
  if (isOptionsGroup(option)) {
    return (
      <div className={styles.optionsGroup__item}>
        <Options.GroupItem
          option={option}
          selected={selected}
          variant={variant}
          view={view}
          optionClassName={optionClassName}
          onClick={onClick}
        />
      </div>
    )
  }

  return (
    <div className={styles.optionsGroup__item}>
      <Options.DefaultItem
        option={option}
        optionClassName={optionClassName}
        selected={selected}
        view={view}
        variant={variant}
        DisplayValueFC={DisplayValueFC}
        onClick={onClick}
      />
    </div>
  )
}

export default OptionsController
