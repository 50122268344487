import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import CertificateOfImplementationInvestprojectStagesLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import CertificateOfImplementationInvestprojectStagesModalManager from './Manager'
import CertificateOfImplementationInvestprojectStagesMenu from './Menu'
import CertificateOfImplementationInvestprojectStagesStatementForm from './Statement'

interface ICreateCertificateOfImplementationInvestprojectStages extends FC {
  LayoutWithFormProvider: typeof CertificateOfImplementationInvestprojectStagesLayoutWithFormProvider
  Menu: typeof CertificateOfImplementationInvestprojectStagesMenu
  StatementForm: typeof CertificateOfImplementationInvestprojectStagesStatementForm
  Manager: typeof CertificateOfImplementationInvestprojectStagesModalManager
}

const useCertificateOfImplementationInvestprojectStagesControl = () => {
  const popupManager = usePopupManager()

  const openCertificateOfImplementationInvestprojectStagesModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        CertificateOfImplementationInvestprojectStagesLayoutWithFormProvider,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openCertificateOfImplementationInvestprojectStagesModal }
}

const CertificateOfImplementationInvestprojectStages: ICreateCertificateOfImplementationInvestprojectStages =
  () => null

CertificateOfImplementationInvestprojectStages.Menu =
  CertificateOfImplementationInvestprojectStagesMenu
CertificateOfImplementationInvestprojectStages.LayoutWithFormProvider =
  CertificateOfImplementationInvestprojectStagesLayoutWithFormProvider
CertificateOfImplementationInvestprojectStages.StatementForm =
  CertificateOfImplementationInvestprojectStagesStatementForm
CertificateOfImplementationInvestprojectStages.Manager =
  CertificateOfImplementationInvestprojectStagesModalManager

export { useCertificateOfImplementationInvestprojectStagesControl as useCertificateOfImplementationInvestprojectStagesControl }
export default CertificateOfImplementationInvestprojectStages
