import { FC, ReactNode } from 'react'

import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './RowWithBorder.module.scss'

interface RowWithBorder {
  title: ReactNode
  children: ReactNode
  contentClassName?: string
  disableBottomDefaultStyles?: boolean
  disableLeftPadding?: boolean
}

const RowWithBorder: FC<RowWithBorder> = ({
  title,
  children,
  contentClassName,
  disableBottomDefaultStyles,
  disableLeftPadding,
}) => {
  return (
    <div
      className={cn(styles.rowWithBorder, {
        [styles['rowWithBorder--disableBottom']]: disableBottomDefaultStyles,
        [styles['rowWithBorder--disableLeftPadding']]: disableLeftPadding,
      })}
    >
      <Typography.Body className={styles.rowWithBorder__title} variant={'bodyMRegular'}>
        {title}
      </Typography.Body>
      <div className={cn(styles.rowWithBorder__content, contentClassName)}>{children}</div>
    </div>
  )
}

export default RowWithBorder
