import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import objectItemStyles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  EconomicSphereOkvedsRegionalArrayPathName,
  EconomicSpheresRegionalReportArrayPathName,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/4/types'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import {
  DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  DataOnFulfillmentOfTermsSZPKFieldCollapseControlUpdateWatcher,
  DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import Button from '@components/NewDesign/Button'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import useIsFirstRender from '@hooks/new/effects/useIsFirstRender'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import DeleteIcon from '@icons/DeleteIcon.svg'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import EconomicSphereOkvedCard from '../EconomicSphereOkvedsCard'

import { economicSphereValidationMap } from './validation'

const { getEconomicSphereTitle } = DocumentFormHelpers

interface EconomicSphereCardProps {
  parentAnchorId: string
  objectId: string
  indexOfSphere: number
  formName: EconomicSpheresRegionalReportArrayPathName
  onDeleteObject: () => Promise<void>
}

const EconomicSphereCard: FC<EconomicSphereCardProps> = ({
  parentAnchorId,
  objectId,
  indexOfSphere,
  formName,
  onDeleteObject,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const economicSphereOkvedsRegionalReportFormName =
    `${formName}.economicSphereOkvedsRegionalReport` as const

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithValue,
      handleRemoveItemFromList,
    },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps, getTextareaProps, getControllerProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
    })

  const { fields: economicSphereOkveds } = useFieldArraySubscribableControl<
    DataOnFulfillmentOfTermsSZPKFormValues,
    EconomicSphereOkvedsRegionalArrayPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: economicSphereOkvedsRegionalReportFormName,
    keyName: 'keyNameId',
    watcher: DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  })

  const isEconomicSphereOkvedsEmptyList = !economicSphereOkveds?.length

  const okvedExpandedRenderMode = !useIsFirstRender()

  const handleDeleteObject = async () => {
    await onDeleteObject()
  }

  const handleAddSubObject = async () => {
    await handleAddItemToListWithValue?.(`${formName}.economicSphereOkvedsRegionalReport`, {
      isSubObject: true,
      parentSubObject: objectId,
    })
  }

  const getRemoveSubObjectHandler = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${formName}.economicSphereOkvedsRegionalReport.${index}`,
      `${formName}.economicSphereOkvedsRegionalReport`,
    )
  }

  const anchorId = `${parentAnchorId}.${indexOfSphere + 1}`

  const { isExpanded: isCardExpanded, onToggleCollapse: toggleCardExpanded } =
    useFormCollapseControl({
      initialExpanded: true,
      name: anchorId,
      watcher: DataOnFulfillmentOfTermsSZPKFieldCollapseControlUpdateWatcher,
    })

  return (
    <FlippedWithCollapse flipId={objectId}>
      <Row id={anchorId}>
        <Col xs={12}>
          <div
            className={cn(
              objectItemStyles.objectItem,
              objectItemStyles['objectItem--economicSphere'],
            )}
          >
            <div className={objectItemStyles.objectItem__header}>
              <div className={objectItemStyles['objectItem__header-collapse']}>
                <Button
                  fixWidth
                  view="plain"
                  size="s"
                  variant="buttonSMedium"
                  color="default"
                  className={cn(
                    objectItemStyles['objectItem__header-button'],
                    objectItemStyles['objectItem__header-button--economicSphere'],
                  )}
                  trailingIcon={{
                    src: chevronRightIcon,
                    className: cn(objectItemStyles['objectItem__header-button__icon'], {
                      [objectItemStyles['objectItem__header-button__icon--active']]: isCardExpanded,
                    }),
                  }}
                  onClick={toggleCardExpanded}
                >
                  {getEconomicSphereTitle(indexOfSphere + 1)}
                </Button>
              </div>

              {editMode && (
                <AsyncWrapper promise={handleDeleteObject}>
                  {({ isLoading, wrappedPromise }) => (
                    <Button
                      disabled={isLoading}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      dataTestId={'deleteButton'}
                      color={'negative'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      leadingIcon={{
                        noCurrentColorSvgFill: true,
                        src: DeleteIcon,
                      }}
                      onClick={wrappedPromise}
                    >
                      Удалить
                    </Button>
                  )}
                </AsyncWrapper>
              )}
            </div>
            <div className={objectItemStyles.objectItem__content}>
              {!isCardExpanded && (
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.economicSphereNameRegionalReport`,
                      })}
                    >
                      <ControlledDocumentDataView
                        suptitle={'Наименование сферы реализации'}
                        controllerProps={getControllerProps({
                          name: `${formName}.economicSphereNameRegionalReport`,
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
              <CollapseWrapper defaultExpanded={isCardExpanded} isExpanded={isCardExpanded}>
                <Stack direction={'vertical'} gap={3}>
                  <Stack direction={'vertical'} gap={3}>
                    <Row>
                      <Col xs={12}>
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${formName}.economicSphereNameRegionalReport`,
                          })}
                        >
                          <ControlledTextarea
                            {...getTextareaProps({
                              name: `${formName}.economicSphereNameRegionalReport`,
                              rules: economicSphereValidationMap.economicSphereNameRegionalReport,
                              textareaProps: {
                                label: 'Наименование сферы реализации',
                              },
                              onBlur: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(
                                    `${formName}.economicSphereNameRegionalReport`,
                                  )
                                }, 0),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${formName}.economicSphereNameRegionalReport`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </Col>
                    </Row>
                  </Stack>
                  <Stack direction={'vertical'} gap={0}>
                    <CollapseWrapper
                      defaultExpanded={!isEconomicSphereOkvedsEmptyList}
                      isExpanded={!isEconomicSphereOkvedsEmptyList}
                    >
                      <Typography.Headline
                        variant={'headlineH3'}
                        className={objectItemStyles['objectItem__content-title']}
                      >
                        ОКВЭД
                      </Typography.Headline>
                    </CollapseWrapper>
                    <Stack direction={'vertical'} gap={3}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.economicSphereOkvedsRegionalReport`,
                        })}
                      >
                        <FlipperList list={economicSphereOkveds}>
                          {economicSphereOkveds?.map((okved, index) => {
                            const economicSphereOkvedFormName =
                              `${formName}.economicSphereOkvedsRegionalReport.${index}` as const

                            return (
                              <EconomicSphereOkvedCard
                                key={okved.id}
                                subObjectId={okved.id}
                                parentAnchorId={anchorId}
                                indexOfOkved={index}
                                formName={economicSphereOkvedFormName}
                                expandedRenderMode={okvedExpandedRenderMode}
                                onDeleteSubObject={getRemoveSubObjectHandler(index)}
                              />
                            )
                          })}
                        </FlipperList>
                      </SubscribableControl>
                      {editMode && (
                        <Row>
                          <Col xs={12}>
                            <AsyncWrapper promise={handleAddSubObject}>
                              {({ isLoading, wrappedPromise }) => {
                                return (
                                  <Button
                                    disabled={isLoading}
                                    leadingIcon={{ src: CircleAddIcon }}
                                    variant={'buttonSMedium'}
                                    size={'s'}
                                    view={'plain'}
                                    dataTestId={'addSubObject'}
                                    className={cn(
                                      objectItemStyles['objectItem__action-button'],
                                      objectItemStyles[
                                        'objectItem__action-button--economicSphereOkved'
                                      ],
                                    )}
                                    loaderProps={{
                                      loading: isLoading,
                                      placement: 'trailing',
                                      variant: 'lite',
                                    }}
                                    onClick={wrappedPromise}
                                  >
                                    Добавить ОКВЭД
                                  </Button>
                                )
                              }}
                            </AsyncWrapper>
                          </Col>
                        </Row>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </CollapseWrapper>
            </div>
          </div>
        </Col>
      </Row>
    </FlippedWithCollapse>
  )
}

export default EconomicSphereCard
