import React, { FC, ReactNode } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import { BaseModalActionsProps } from '@components/NewDesign/Modal/Base/Actions'
import type { DefaultModalProps } from '@globalTypes/base'
import WarnIcon from '@icons/alert/warning_amber.svg'

import styles from './WarningModal.module.scss'

interface IWarningModal extends Partial<Pick<BaseModalActionsProps, 'actions' | 'title'>> {
  onConfirm: (value: unknown) => void
  children: ReactNode
}

type WarningModalProps = Required<PopupProps> & IWarningModal

const IconProp = {
  src: WarnIcon,
  className: styles.icon,
}

const useWarningModal = () => {
  const popupManager = usePopupManager()

  const handleOpenWarningModal = (props: IWarningModal & DefaultModalProps) => {
    popupManager.open<WarningModalProps>(WarningModal, {
      title: props.title,
      actions: props.actions,
      children: props.children,
      onClose: props.onClose ?? (() => null),
      onConfirm: props.onConfirm,
    })
  }

  return {
    handleOpenWarningModal,
  }
}

const WarningModal: FC<WarningModalProps> = ({
  title,
  children,
  actions,
  isOpen,
  onConfirm,
  onClose,
}) => {
  const doneActions = [
    {
      children: 'Отмена',
      dataTestId: 'WarningModal-cancel-button',
      view: 'gray',
      fixWidth: true,
      onClick: onClose,
    },
    {
      children: 'Продолжить',
      dataTestId: 'WarningModal-confirm-button',
      fixWidth: true,
      onClick: onConfirm,
    },
  ] as ButtonProps[]

  return (
    <Modal.Status
      actions={actions ?? doneActions}
      isOpen={isOpen}
      title={title ?? 'Подтвердите действие'}
      dataTestId="WarningModal-modal"
      containerClassName={styles.modalWrapper}
      actionsClassName={styles.modalActions}
      contentClassName={styles.modalContent}
      icon={IconProp}
      onClose={onClose}
    >
      {children}
    </Modal.Status>
  )
}

export { useWarningModal }
export default WarningModal
