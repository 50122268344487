import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import PetitionTransferRightLayout from './Layout'
import PetitionTransferRightModalManager from './Manager'
import PetitionTransferRightMenu from './Menu'
import PetitionTransferRightStatementForm from './Statement'

interface IPetitionTransferRight extends FC {
  Layout: typeof PetitionTransferRightLayout
  Menu: typeof PetitionTransferRightMenu
  StatementForm: typeof PetitionTransferRightStatementForm
  Manager: typeof PetitionTransferRightModalManager
}

const usePetitionTransferRightControl = () => {
  const popupManager = usePopupManager()

  const openPetitionTransferRightModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(PetitionTransferRightLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openPetitionTransferRightModal }
}

const PetitionTransferRight: IPetitionTransferRight = () => null

PetitionTransferRight.Menu = PetitionTransferRightMenu
PetitionTransferRight.Layout = PetitionTransferRightLayout
PetitionTransferRight.StatementForm = PetitionTransferRightStatementForm
PetitionTransferRight.Manager = PetitionTransferRightModalManager

export { usePetitionTransferRightControl }
export default PetitionTransferRight
