import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementIncludeRelatedTermsFormValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const thirdSectionValidationMap: FormValuesValidationSection<
  keyof AAgreementIncludeRelatedTermsFormValues['3'],
  AAgreementIncludeRelatedTermsFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { thirdSectionValidationMap }
