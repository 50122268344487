import { Urls } from '@constants/urls'
import { StorageFields } from '@context/AuthContext/models'
import { removeNullOrUndefinedValuesFromObject } from '@helpers/object/removeNullOrUndefinedValuesFromObject'
import { FetcherWithInterceptors } from '@packages/api/fetcher'
import DayjsService from '@services/Dayjs/Dayjs.service'
import DfoHelpersService from '@services/Dfo/Dfo.helpers'
import ErrorResolver from '@services/ErrorResolver/ErrorResolver.service'
import type { LogErrorParams, SendToApiParams } from '@services/LoggerService/LoggerService.entity'
import ProjectHelpersService from '@services/Projects/Project.helpers'
import storage from 'store2'

class LoggerService {
  static multipleLog(logError: LogErrorParams) {
    this.internalLog(logError)
    this.externalLog(logError)
  }

  static internalLog({ componentId, ...logError }: LogErrorParams) {
    const { additionInfo, ...parsedError } = new ErrorResolver(logError)

    const { additionInformation, mainInformation } = this.getMainLogProps()

    const loggerInfo = removeNullOrUndefinedValuesFromObject({
      ...mainInformation,
      componentId,
      ...parsedError,
      additionInformation: {
        ...additionInformation,
        additionInfo,
      },
    })

    console.error(JSON.stringify(loggerInfo))
  }

  static externalLog({ componentId, ...logError }: LogErrorParams) {
    const { additionInfo, ...parsedError } = new ErrorResolver(logError)

    const { additionInformation, mainInformation } = this.getMainLogProps()

    const loggerInfo = removeNullOrUndefinedValuesFromObject({
      ...mainInformation,
      componentId,
      ...parsedError,
      additionInformation: {
        ...additionInformation,
        additionInfo,
      },
    })

    const timestamp = String(DayjsService.dayjs().unix())

    this.sendToApi({
      timestamp,
      message: JSON.stringify(loggerInfo),
    })
  }

  private static getMainLogProps() {
    const uuid: string = storage.get(StorageFields.userUuid)
    const { sub: organizationId } = storage.get(StorageFields.userInfo) || {}

    const projectId = ProjectHelpersService.getProjectIdFromUrl()
    const dfoId = DfoHelpersService.getDfoIdFromUrl()

    const mainInformation = {
      uuid,
      organizationId,
    }

    const additionInformation = {
      projectId,
      dfoId,
    }

    return {
      mainInformation,
      additionInformation,
    }
  }

  private static sendToApi({ timestamp, message }: SendToApiParams) {
    try {
      FetcherWithInterceptors.post({
        url: Urls.Logging,
        data: {
          timestamp,
          message,
        },
      })
    } catch (error) {
      throw error
    }
  }
}

export default LoggerService
