import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import AsyncWrapper from '@components/AsyncWrapper'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import ExploitationItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CExploitationForm/ExploitationItem'
import { eExploitationProjectSZPKValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CExploitationForm/validation'
import {
  SeventhObjectExploitationObjectsPathName,
  SeventhObjectExploitationPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

interface CExploitationFormProps {
  indexOfBlock: number
  name: SeventhObjectExploitationPathName
  control: Control<ProjectSZPKFormValues>
}

const CExploitationForm: FC<CExploitationFormProps> = ({ indexOfBlock, name, control }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
    },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getCalendarInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: exploitationForms } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhObjectExploitationObjectsPathName,
    'keyNameId'
  >({
    name: `${name}.exploitationObjects`,
    control,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleAddExplotation = async () => {
    await handleAddItemToListWithOutValue?.(`${name}.exploitationObjects`)
  }

  const handleRemoveExplotation = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.exploitationObjects.${index}`,
      `${name}.exploitationObjects`,
    )
  }

  return (
    <Container className="pb-0 pt-2 px-0">
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              titleHeightFallback={'40px'}
              title={'Ввод в эксплуатацию объектов недвижимости планируется осуществить до'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.objectCommissioningEndDate`,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.objectCommissioningEndDate`,
                    calendarInputProps: {
                      dataTestId: 'objectCommissioningEndDate',
                    },
                    rules: {
                      ...eExploitationProjectSZPKValidationMap.objectCommissioningEndDate,
                      validate: {
                        positiveObjectStateRegistrationEndDate: (value) => {
                          const objectStateRegistrationEndDate = formInstance.getValues(
                            `${projectSZPKBlockValues['7'].eventsOfStages}.${indexOfBlock}.constructionStage.registration.objectStateRegistrationEndDate`,
                          ) as string

                          if (
                            isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) >
                              dayjs(objectStateRegistrationEndDate, objOfDateFormats.defaultFormat)
                          )
                            return 'дата ввода всех объектов в эксплуатацию должна быть меньше или равна дате регистрации всех объектов'
                        },
                      },
                    },
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${name}.objectCommissioningEndDate`),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${name}.objectCommissioningEndDate`),
                        0,
                      ),
                    onInputChange: () =>
                      debouncedHandleChangeValue?.(`${name}.objectCommissioningEndDate`),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Stack direction="vertical" gap={3} className={'mt-3'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.exploitationObjects`,
            })}
          >
            <FlipperList list={exploitationForms} stackClassName={'mt-3'}>
              {exploitationForms.map((exploitation, index) => {
                return (
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.exploitationObjects.${index}`,
                    })}
                  >
                    <ExploitationItem
                      id={exploitation.id}
                      anchorId={exploitation.anchorId}
                      key={exploitation.id}
                      formName={`${name}.exploitationObjects.${index}`}
                      indexOfBlock={indexOfBlock}
                      blockViewIsValidating={blockViewIsValidating}
                      editMode={editMode}
                      formInstance={formInstance}
                      indexOfExploitation={index}
                      onDeleteExploitation={handleRemoveExplotation(index)}
                    />
                  </SubscribableControl>
                )
              })}
            </FlipperList>
          </SubscribableControl>
          {editMode && (
            <Row className={'px-1'}>
              <Col xs={9}>
                <AsyncWrapper promise={handleAddExplotation}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <Button
                        disabled={isLoading}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        view={'plain'}
                        leadingIcon={{
                          src: CircleAddIcon,
                        }}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Указать объект, введенный в эксплуатацию
                      </Button>
                    )
                  }}
                </AsyncWrapper>
              </Col>
            </Row>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

export default CExploitationForm
