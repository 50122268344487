import { FC, memo, ReactNode, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'

import Icon from '@components/Icon'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import { ModalActionWithoutActionProps } from '@components/NewDesign/Modal/Action'
import Typography from '@components/NewDesign/Typography'
import errorIcon from '@icons/alert/error_outline.svg'

import styles from './ErrorModal.module.scss'

export type CustomActionsProps = ButtonProps & {
  bindOnClose?: boolean
}

export interface ErrorComponentProps {
  headerText: string
  bodyText: string | ReactNode
  customActions?: {
    actions: CustomActionsProps[]
    mergeDefault: boolean
  }
  modalActionsProps?: Partial<ModalActionWithoutActionProps>
}

export type ErrorModalProps = Required<PopupProps> & ErrorComponentProps
const ErrorModal: FC<ErrorModalProps> = ({
  headerText,
  bodyText,
  isOpen,
  onClose,
  customActions,
  modalActionsProps,
}) => {
  const doneActions = useMemo(() => {
    const defaultActions = [
      {
        children: 'Закрыть',
        dataTestId: 'ErrorModal-close-button',
        fixWidth: true,
        onClick: onClose,
        className: styles.error,
      },
    ] as ButtonProps[]

    if (!customActions) return defaultActions as ButtonProps[]

    const preparedActions = customActions.actions.map((action) => ({
      ...action,
      onClick: action.bindOnClose
        ? (e) => {
            action.onClick?.(e)
            onClose?.()
          }
        : action.onClick,
    }))

    if (customActions.mergeDefault) {
      return [...defaultActions, ...preparedActions] as ButtonProps[]
    }

    return preparedActions as ButtonProps[]
  }, [customActions, onClose])

  return (
    <Modal.Action
      {...modalActionsProps}
      hideCloseIcon
      actions={doneActions}
      isOpen={isOpen}
      title={''}
      dataTestId="ErrorModal-modal"
      closeButtonDataTestId="ErrorModal-modal-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      onClose={onClose}
    >
      <div className={styles.icon}>
        <Icon src={errorIcon} size={'4xl'} />
      </div>
      {headerText && (
        <Typography.Headline variant="headlineH3" className={styles.head}>
          {headerText}
        </Typography.Headline>
      )}
      <Typography.Body variant="bodyMRegular" className={styles.body}>
        {bodyText}
      </Typography.Body>
    </Modal.Action>
  )
}

export default memo(ErrorModal)
