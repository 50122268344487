import { FC, FormHTMLAttributes } from 'react'

import cn from 'classnames'

import styles from './Form.module.scss'

export interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  dataTestId?: string
  className?: string
}

const Form: FC<FormProps> = ({ className, dataTestId, ...props }) => {
  return <form className={cn(styles.wrapper, className)} data-testid={dataTestId} {...props} />
}

export default Form
