import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/const'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/Forms'
import { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyManager } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/Manager'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'

const mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms: FormItemProps =
  {
    '1': {
      node: (
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms.First />
      ),
    },
    '2': {
      node: (
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms.Second />
      ),
    },
    '3': {
      node: (
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms.Third />
      ),
    },
  } as const

const NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAStatementForm: FC<
  FormModalStatementProps
> = ({ ...rest }) => {
  const formInstance =
    useFormContext<NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues>()

  const {
    state: { formIsLoading },
  } =
    useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      formIsLoading={formIsLoading}
      mapOfForms={
        mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForms
      }
      mapOfMenu={
        mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu
      }
    />
  )
}

export default memo(NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAStatementForm)
