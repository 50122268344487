import React, { FC, MouseEvent } from 'react'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import IconButton from '@components/NewDesign/IconButton'
import { TSize, TView } from '@components/NewDesign/IconButton/types'
import { copyToClipboard } from '@helpers/copy'
import CopyIcon from '@icons/content/copy.svg'
import cn from 'classnames'

import styles from './TextWithCopy.module.scss'

interface TextWithCopyProps {
  value: string
  size?: TSize
  view?: TView
  className?: string
  iconWrapperClassName?: string
  SuccessAlert?: JSX.Element
  ErrorAlert?: JSX.Element
}

const TextWithCopy: FC<TextWithCopyProps> = ({
  size = 's',
  view = 'basic',
  value,
  className,
  iconWrapperClassName,
  children,
  SuccessAlert,
  ErrorAlert,
}) => {
  const handleCopyClick = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      e.stopPropagation()

      await copyToClipboard(value)

      toast(
        SuccessAlert ?? (
          <Alert transparent variant="success">
            Успешно скопировано
          </Alert>
        ),
      )
    } catch {
      toast(
        ErrorAlert ?? (
          <Alert transparent variant="error">
            Произошла ошибка при копировании
          </Alert>
        ),
      )
    }
  }

  return (
    <div className={cn(styles.wrapper, className)}>
      <div>{children}</div>
      <div className={cn(styles.icon_Wrapper, iconWrapperClassName)}>
        <IconButton
          size={size}
          view={view}
          className={styles.icon__button}
          icon={{
            src: CopyIcon,
          }}
          onClick={handleCopyClick}
        />
      </div>
    </div>
  )
}

export default TextWithCopy
