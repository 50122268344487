import React from 'react'

import { TabListProps } from '../../types'

import { PrimaryTabList } from './Component'
import mobileStyles from './primaryTabList.mobile.module.scss'
import commonStyles from './primaryTabList.module.scss'

const styles = {
  ...commonStyles,
  ...mobileStyles,
}

export type PrimaryTabListMobileProps = Omit<TabListProps, 'size' | 'collapsedTabsIds'>

export const PrimaryTabListMobile = (props: PrimaryTabListMobileProps) => (
  <PrimaryTabList {...props} styles={styles} platform="mobile" />
)
