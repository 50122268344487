import { FC, memo, useEffect } from 'react'
import { PopupProps } from 'react-popup-manager'

import Loader from '@components/Loader'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'

import styles from './LoadingModal.module.scss'

type TLoadingModal = PopupProps & {
  text: string
  bindCloseRef?: (ref: VoidFunction) => void
}

const LoadingModal: FC<TLoadingModal> = ({ text, isOpen, bindCloseRef, onClose }) => {
  useEffect(() => {
    if (!bindCloseRef || !onClose) return

    bindCloseRef(onClose)
  }, [bindCloseRef, onClose])

  return (
    <Modal.Simple
      disableOnClose
      hideCloseIcon
      isOpen={isOpen}
      title=""
      dataTestId="LoadingModal-modal"
      closeButtonDataTestId="LoadingModal-modal-closeButton"
      simpleModalContainerClassName={styles.simpleModalContainer}
      simpleModalBodyClassName={styles.simpleModalBody}
      onClose={onClose}
    >
      <Loader
        loading
        variant="lite"
        className={styles.loader}
        dataTestId="LoadingModal-modalLoader"
      />
      <Typography.Headline variant="headlineH3" className={styles.loadingText}>
        {text}
      </Typography.Headline>
    </Modal.Simple>
  )
}

export default memo(LoadingModal)
