import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import { stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/5/StepMonitoringChecker/validation'
import { CertificateOnMonitoringResultsStepsMonitoringCheckerArrayPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/5/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

interface CertificateOnMonitoringResultsStepMonitoringCheckerProps {
  id: string
  anchorId: string
  name: CertificateOnMonitoringResultsStepsMonitoringCheckerArrayPathName
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
}

const CertificateOnMonitoringResultsStepMonitoringChecker: FC<
  CertificateOnMonitoringResultsStepMonitoringCheckerProps
> = ({ id, anchorId, name, formInstance }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const isVatMoney = useWatch({
    name: certificateOnMonitoringResultsBlockValues['4'].isVatMoney,
    control: formInstance?.control,
  })

  const {
    getSubscribableControlProps,
    getInputProps,
    getCalendarInputProps,
    getTextareaProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  const factStepStartDateChecker = useWatch({
    control: formInstance.control,
    name: `${name}.factStepStartDateChecker`,
  })

  return (
    <FieldView.StepTemplate id={id} anchorId={anchorId}>
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Номер этапа'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepNumber`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.stepNumber`,
                  inputProps: {
                    disabled: true,
                    integersOnly: true,
                    hideSuffix: true,
                    hasNegativeValue: false,
                    minorPartView: 'default',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <Group disableBottomBorder title={'Объем капитальных вложений'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.ContainerWithSwitchTransition condition={isVatMoney}>
                {(condition) => {
                  if (!condition) {
                    return (
                      <Stack gap={3} direction={'vertical'}>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Плановое значение (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.planStepTotalCapitalValue`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.planStepTotalCapitalValue`,
                                inputProps: {
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Фактическое значение, введенное инвестором (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.factStepTotalCapitalValue`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.factStepTotalCapitalValue`,
                                inputProps: {
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Фактическое значение (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.factStepTotalCapitalValueChecker`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.factStepTotalCapitalValueChecker`,
                                rules:
                                  stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.factStepTotalCapitalValueChecker,
                                onBlur: () =>
                                  setTimeout(() => {
                                    handleChangeValue?.(`${name}.factStepTotalCapitalValueChecker`)
                                  }, 0),
                                onChange: () =>
                                  setTimeout(() => {
                                    debouncedHandleChangeValue?.(
                                      `${name}.factStepTotalCapitalValueChecker`,
                                    )
                                  }, 0),
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                      </Stack>
                    )
                  }

                  return (
                    <Stack gap={3} direction={'vertical'}>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Плановое значение (с НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.planStepTotalCapitalVatValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.planStepTotalCapitalVatValue`,
                              inputProps: {
                                disabled: true,
                              },
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Фактическое значение, введенное инвестором (с НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.factStepTotalCapitalVatValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.factStepTotalCapitalVatValue`,
                              inputProps: {
                                disabled: true,
                              },
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Фактическое значение (с НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.factStepTotalCapitalVatValueChecker`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.factStepTotalCapitalVatValueChecker`,
                              rules:
                                stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.factStepTotalCapitalVatValueChecker,
                              onBlur: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${name}.factStepTotalCapitalVatValueChecker`)
                                }, 0),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${name}.factStepTotalCapitalVatValueChecker`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Stack>
                  )
                }}
              </FieldView.ContainerWithSwitchTransition>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Отклонение'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.diffStepTotalCapitalChecker`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${name}.diffStepTotalCapitalChecker`,
                      rules:
                        stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.diffStepTotalCapitalChecker,
                      inputProps: {},
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.diffStepTotalCapitalChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.diffStepTotalCapitalChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>

          <Group disableBottomBorder title={'Объем капиталовложений'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.ContainerWithSwitchTransition condition={isVatMoney}>
                {(condition) => {
                  if (!condition) {
                    return (
                      <Stack gap={3} direction={'vertical'}>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Плановое значение (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.stepInvestorCapitalValue`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.stepInvestorCapitalValue`,
                                inputProps: {
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Фактическое значение, введенное инвестором (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.factStepInvestorCapitalValue`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.factStepInvestorCapitalValue`,
                                inputProps: {
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Фактическое значение (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.factStepInvestorCapitalValueChecker`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.factStepInvestorCapitalValueChecker`,
                                rules:
                                  stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.factStepInvestorCapitalValueChecker,
                                onBlur: () =>
                                  setTimeout(() => {
                                    handleChangeValue?.(
                                      `${name}.factStepInvestorCapitalValueChecker`,
                                    )
                                  }, 0),
                                onChange: () =>
                                  setTimeout(() => {
                                    debouncedHandleChangeValue?.(
                                      `${name}.factStepInvestorCapitalValueChecker`,
                                    )
                                  }, 0),
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                      </Stack>
                    )
                  }

                  return (
                    <Stack gap={3} direction={'vertical'}>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Плановое значение (с НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.stepInvestorCapitalValueVAT`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.stepInvestorCapitalValueVAT`,
                              inputProps: {
                                disabled: true,
                              },
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Фактическое значение, введенное инвестором (с НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.factStepInvestorCapitalVatValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.factStepInvestorCapitalVatValue`,
                              inputProps: {
                                disabled: true,
                              },
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Фактическое значение (с НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.factStepInvestorCapitalVatValueChecker`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.factStepInvestorCapitalVatValueChecker`,
                              rules:
                                stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.factStepInvestorCapitalVatValueChecker,
                              onBlur: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(
                                    `${name}.factStepInvestorCapitalVatValueChecker`,
                                  )
                                }, 0),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${name}.factStepInvestorCapitalVatValueChecker`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Stack>
                  )
                }}
              </FieldView.ContainerWithSwitchTransition>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Отклонение'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.diffStepInvestorCapitalValueChecker`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${name}.diffStepInvestorCapitalValueChecker`,
                      rules:
                        stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.diffStepInvestorCapitalValueChecker,
                      inputProps: {},
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.diffStepInvestorCapitalValueChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${name}.diffStepInvestorCapitalValueChecker`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>

          <Group disableBottomBorder title={'Объем планируемых к возмещению затрат'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.ContainerWithSwitchTransition condition={isVatMoney}>
                {(condition) => {
                  if (!condition) {
                    return (
                      <Stack gap={3} direction={'vertical'}>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Плановое значение (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.stepCompensationValue`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.stepCompensationValue`,
                                inputProps: {
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Фактическое значение, введенное инвестором (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.factStepCompensationValue`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.factStepCompensationValue`,
                                inputProps: {
                                  disabled: true,
                                },
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                        <FieldView.RowWithBottomBorder
                          disableBottomDefaultStyles
                          firstColumnSize={6}
                          secondColumnSize={6}
                          title={'Фактическое значение (без НДС)'}
                        >
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${name}.factStepCompensationValueChecker`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${name}.factStepCompensationValueChecker`,
                                rules:
                                  stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.factStepCompensationValueChecker,
                                onBlur: () =>
                                  setTimeout(() => {
                                    handleChangeValue?.(`${name}.factStepCompensationValueChecker`)
                                  }, 0),
                                onChange: () =>
                                  setTimeout(() => {
                                    debouncedHandleChangeValue?.(
                                      `${name}.factStepCompensationValueChecker`,
                                    )
                                  }, 0),
                              })}
                            />
                          </SubscribableControl>
                        </FieldView.RowWithBottomBorder>
                      </Stack>
                    )
                  }

                  return (
                    <Stack gap={3} direction={'vertical'}>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Плановое значение (c НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.stepCompensationValueVAT`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.stepCompensationValueVAT`,
                              inputProps: {
                                disabled: true,
                              },
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Фактическое значение, введенное инвестором (c НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.factStepCompensationVatValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.factStepCompensationVatValue`,
                              inputProps: {
                                disabled: true,
                              },
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Фактическое значение (c НДС)'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${name}.factStepCompensationVatValueChecker`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${name}.factStepCompensationVatValueChecker`,
                              rules:
                                stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.factStepCompensationVatValueChecker,
                              onBlur: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${name}.factStepCompensationVatValueChecker`)
                                }, 0),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${name}.factStepCompensationVatValueChecker`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Stack>
                  )
                }}
              </FieldView.ContainerWithSwitchTransition>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Отклонение'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.diffStepCompensationValueChecker`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${name}.diffStepCompensationValueChecker`,
                      inputProps: {},
                      rules:
                        stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.diffStepCompensationValueChecker,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.diffStepCompensationValueChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.diffStepCompensationValueChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>

          <Group disableBottomBorder title={'Планируемый срок возмещения затрат'}>
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.stepCompensationPeriod`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${name}.stepCompensationPeriod`,
                        inputProps: {
                          disabled: true,
                          label: 'Плановое значение',
                        },
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <ContainerWithBorder>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.factStepCompensationPeriod`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${name}.factStepCompensationPeriod`,
                        calendarInputProps: {
                          disabled: true,
                          inputMask: YEAR_MASK,
                          label: 'Фактическое начало возмещения',
                          caption: 'значение, введенное инвестором',
                        },
                        calendarProps: {
                          typeOfPicker: 'years',
                          dateFormat: 'YYYY',
                        },
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.factStepCompensationEndPeriod`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${name}.factStepCompensationEndPeriod`,
                        calendarInputProps: {
                          disabled: true,
                          inputMask: YEAR_MASK,
                          label: 'Фактическое окончание возмещения',
                          caption: 'значение, введенное инвестором',
                        },
                        calendarProps: {
                          typeOfPicker: 'years',
                          dateFormat: 'YYYY',
                        },
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </ContainerWithBorder>
              <Row>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.factStepCompensationPeriodChecker`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${name}.factStepCompensationPeriodChecker`,
                        calendarInputProps: {
                          inputMask: YEAR_MASK,
                          label: 'Фактическое начало возмещения',
                        },
                        calendarProps: {
                          typeOfPicker: 'years',
                          dateFormat: 'YYYY',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${name}.factStepCompensationPeriodChecker`)
                          }, 0),
                        onCalendarChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${name}.factStepCompensationPeriodChecker`)
                          }, 0),
                        onInputChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${name}.factStepCompensationPeriodChecker`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.factStepCompensationPeriodEndChecker`,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: `${name}.factStepCompensationPeriodEndChecker`,
                        calendarInputProps: {
                          inputMask: YEAR_MASK,
                          label: 'Фактическое окончание возмещения',
                        },
                        calendarProps: {
                          typeOfPicker: 'years',
                          dateFormat: 'YYYY',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${name}.factStepCompensationPeriodEndChecker`)
                          }, 0),
                        onCalendarChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${name}.factStepCompensationPeriodEndChecker`)
                          }, 0),
                        onInputChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${name}.factStepCompensationPeriodEndChecker`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </Stack>
          </Group>

          <Group disableBottomBorder title={'Количество рабочих мест (единиц)'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Плановое значение'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.planStepWorkPlace`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.planStepWorkPlace`,
                      inputProps: {
                        disabled: true,
                        suffix: '',
                        integersOnly: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Фактическое значение, введенное инвестором'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.factStepWorkPlace`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.factStepWorkPlace`,
                      inputProps: {
                        disabled: true,
                        suffix: '',
                        integersOnly: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Фактическое значение'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.factStepWorkPlaceChecker`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.factStepWorkPlaceChecker`,
                      rules:
                        stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.factStepWorkPlaceChecker,
                      inputProps: {
                        suffix: '',
                        integersOnly: true,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.factStepWorkPlaceChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.factStepWorkPlaceChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Отклонение'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.diffStepWorkPlaceChecker`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${name}.diffStepWorkPlaceChecker`,
                      inputProps: {},
                      rules:
                        stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.diffStepWorkPlaceChecker,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.diffStepWorkPlaceChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.diffStepWorkPlaceChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>

          <Group disableBottomBorder title={'Срок реализации этапа инвестиционного проекта'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Плановая дата начала этапа'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.stepStartDate`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${name}.stepStartDate`,
                      calendarInputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Плановая дата окончания этапа'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.stepEndDate`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${name}.stepEndDate`,
                      calendarInputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Фактическая дата начала этапа'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.factStepStartDate`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${name}.factStepStartDate`,
                      calendarInputProps: {
                        disabled: true,
                        caption: 'значение, введенное инвестором',
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Фактическая дата окончания этапа'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.factStepEndDate`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${name}.factStepEndDate`,
                      calendarInputProps: {
                        disabled: true,
                        caption: 'значение, введенное инвестором',
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Фактическая дата начала этапа'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.factStepStartDateChecker`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${name}.factStepStartDateChecker`,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.factStepStartDateChecker`)
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.factStepStartDateChecker`)
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.factStepStartDateChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Фактическая дата окончания этапа'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.factStepEndDateChecker`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${name}.factStepEndDateChecker`,
                      rules: {
                        validate: {
                          positiveFactStepStartDateChecker: (value) => {
                            return (
                              !factStepStartDateChecker ||
                              (isDateValidForDayjs(value) &&
                                dayjs(value, objOfDateFormats.defaultFormat) >=
                                  dayjs(
                                    factStepStartDateChecker,
                                    objOfDateFormats.defaultFormat,
                                  )) ||
                              'дата окончания этапа должна быть больше даты начала этапа'
                            )
                          },
                        },
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.factStepEndDateChecker`)
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.factStepEndDateChecker`)
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.factStepEndDateChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Отклонение'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.diffStepDateChecker`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${name}.diffStepDateChecker`,
                      rules:
                        stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.diffStepDateChecker,
                      inputProps: {},
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.diffStepDateChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.diffStepDateChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.stepInfo`,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: `${name}.stepInfo`,
                        rules:
                          stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.stepInfo,
                        textareaProps: {
                          disabled: true,
                          label: 'Дополнительная информация о результатах мониторинга этапа',
                          caption: 'значение, введенное инвестором',
                        },
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.stepInfoChecker`,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: `${name}.stepInfoChecker`,
                        rules:
                          stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap.stepInfoChecker,
                        textareaProps: {
                          label: 'Дополнительная информация о результатах мониторинга этапа',
                        },
                        onBlur: () =>
                          setTimeout(() => handleChangeValue?.(`${name}.stepInfoChecker`), 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${name}.stepInfoChecker`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </Stack>
          </Group>
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default CertificateOnMonitoringResultsStepMonitoringChecker
