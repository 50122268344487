import React, { memo, useMemo } from 'react'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { blockValues, mapOfActivityKind } from '@components/Forms/CreateStatementOld/const'
import FieldView from '@components/Forms/CreateStatementOld/FieldView'
import styles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import { generalInfoValidation } from '@components/Forms/CreateStatementOld/Forms/GeneralInfo/validation'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import { ControlledSingleSelect } from '@components/NewDesign/Select/SingleSelect/ControlledSingleSelect'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import cn from 'classnames'

const { validationAdapter } = DocumentFormHelpers

const GeneralInfoForm = () => {
  const {
    state: { formInstance, blockViewIsValidating },
  } = useCreateStatementManager()

  const preparedActivityKind = useMemo(() => {
    return Object.entries(mapOfActivityKind).map(([value, displayValue]) => ({
      displayValue,
      value,
    }))
  }, [])

  if (!formInstance) return null

  return (
    <div className={styles.form__container}>
      <div className={styles.form__item}>
        <ControlledSingleSelect
          options={preparedActivityKind}
          controllerProps={{
            name: blockValues.generalInfo.activityKind,
            control: formInstance.control,
            rules: validationAdapter(generalInfoValidation.activityKind, {
              form: formInstance,
              name: blockValues.generalInfo.activityKind,
              needTrigger: blockViewIsValidating,
            }),
          }}
          inputProps={{
            fixWidth: true,
            view: 'secondary',
            label: 'Сфера экономики, в которой реализуется проект',
            dataTestId: 'economicSphere',
          }}
        />
      </div>

      <div className={cn(styles.form__item, styles['form__item--full'])}>
        <ControlledHierarchyReferenceBookSelect
          dictionaryName={'okved'}
          controllerProps={{
            control: formInstance.control,
            name: blockValues.generalInfo.activityKindOkved,
            rules: validationAdapter(generalInfoValidation.activityKindOkved, {
              form: formInstance,
              name: blockValues.generalInfo.activityKindOkved,
              needTrigger: blockViewIsValidating,
            }),
          }}
          inputProps={{
            label: 'Сфера экономики, согласно коду ОКВЭД',
            dataTestId: 'projectOkved',
          }}
        />
      </div>

      <div className={cn(styles.form__item, styles['form__item--full'])}>
        <FieldView.RowWithBottomBorder
          title={
            'Пункт или пункты правил заключения СЗПК, на основании которых будет заключаться соглашение'
          }
        >
          <ControlledInput
            control={formInstance.control}
            name={blockValues.generalInfo.ruleParagraph}
            inputProps={{
              size: 'xl',
              view: 'secondary',
              label: 'Пункт или пункты',
              maxLength: 200,
              dataTestId: `GeneralInfoForm-${blockValues.generalInfo.ruleParagraph}-input`,
            }}
            rules={validationAdapter(generalInfoValidation.ruleParagraph, {
              form: formInstance,
              name: blockValues.generalInfo.ruleParagraph,
              needTrigger: blockViewIsValidating,
            })}
          />
        </FieldView.RowWithBottomBorder>
      </div>

      <div className={styles.form__item}>
        <FieldView.RowWithBottomBorder
          title={'Количество cоздаваемых рабочих мест после окончания проекта'}
        >
          <ControlledAmountInput
            control={formInstance.control}
            name={blockValues.generalInfo.newWorkplaces}
            inputProps={{
              size: 'xl',
              view: 'secondary',
              label: 'Количество',
              suffix: '',
              integersOnly: true,
              integerLength: 19,
              dataTestId: `GeneralInfoForm-${blockValues.generalInfo.newWorkplaces}-amountInput`,
            }}
            rules={validationAdapter(generalInfoValidation.newWorkplaces, {
              form: formInstance,
              name: blockValues.generalInfo.newWorkplaces,
              needTrigger: blockViewIsValidating,
            })}
          />
        </FieldView.RowWithBottomBorder>
      </div>
      <div className={styles.form__item}>
        <FieldView.RowWithBottomBorder title={'Количество рабочих мест  на этапе строительства'}>
          <ControlledAmountInput
            control={formInstance.control}
            name={blockValues.generalInfo.newBuildingWorkplaces}
            inputProps={{
              size: 'xl',
              view: 'secondary',
              label: 'Количество',
              suffix: '',
              integersOnly: true,
              integerLength: 19,
              dataTestId: `GeneralInfoForm-${blockValues.generalInfo.newBuildingWorkplaces}-amountInput`,
            }}
            rules={validationAdapter(generalInfoValidation.newBuildingWorkplaces, {
              form: formInstance,
              name: blockValues.generalInfo.newBuildingWorkplaces,
              needTrigger: blockViewIsValidating,
            })}
          />
        </FieldView.RowWithBottomBorder>
      </div>
      <div className={styles.form__item}>
        <ControlledTextarea
          control={formInstance.control}
          name={blockValues.generalInfo.characteristic}
          textareaProps={{
            label: 'Добавьте описание проекта',
            fixWidth: true,
            maxLength: 500,
            dataTestId: `GeneralInfoForm-${blockValues.generalInfo.characteristic}-textarea`,
          }}
          rules={validationAdapter(generalInfoValidation.characteristic, {
            form: formInstance,
            name: blockValues.generalInfo.characteristic,
            needTrigger: blockViewIsValidating,
          })}
        />
      </div>
    </div>
  )
}

export default memo(GeneralInfoForm)
