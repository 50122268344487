import React, { FC, memo } from 'react'
import { NavLink } from 'react-router-dom'

import Typography from '@components/NewDesign/Typography'
import { Paths } from '@constants/paths'
import cn from 'classnames'

import styles from './MobileMenu.module.scss'

interface MobileMenuProps {
  isOpen: boolean
  onLinkClick: () => void
}

const MOBILE_MENU_LINKS = [
  { to: '/', label: 'Главная' },
  {
    to: Paths.AuthorizedList,
    label: 'Уполномоченные ОИВ',
  },
  {
    to: Paths.Acts,
    label: 'Документы',
  },
  { to: Paths.KnowledgeBase, label: 'Cправка' },
]

const MobileMenu: FC<MobileMenuProps> = ({ isOpen, onLinkClick }) => {
  return (
    <div
      className={cn(styles.mobileMenu__dropdown, {
        [styles['mobileMenu__dropdown--opened']]: isOpen,
      })}
    >
      <div className={styles.mobileMenu__content}>
        {MOBILE_MENU_LINKS.map((link, key) => {
          return (
            <NavLink key={key} to={link.to} onClick={onLinkClick}>
              {({ isActive }) => (
                <Typography.Body
                  variant={'bodyMSemibold'}
                  className={cn(styles.mobileMenu__link, {
                    [styles['mobileMenu__link--active']]: isActive,
                  })}
                >
                  {link.label}
                </Typography.Body>
              )}
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}

export default memo(MobileMenu)
