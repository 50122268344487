import { generatePropertiesObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/adapters/RHF.adapter'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'

const useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyAdapters =
  () => {
    const generateRHFObject = (
      objectFromPropertyState: Record<string, any>,
    ): NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues =>
      generateRHFObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm(
        objectFromPropertyState,
      )

    const generatePropertiesObject = (
      objectFromPropertyState: Record<string, any>,
    ): Record<string, unknown> =>
      generatePropertiesObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm(
        objectFromPropertyState,
      )

    return { generateRHFObject, generatePropertiesObject }
  }

export { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyAdapters }
