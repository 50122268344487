// Утилита, позволяющая получить значение объекта, по переданному пути через .

import { compact } from '@helpers/array/compact'
import { isNullOrUndefined } from '@helpers/checkTypes'

const getObjectValue = <T = any>(obj: T, path: string): any => {
  return compact(path.split(/[,[\].]+?/)).reduce(
    (result, key) => (isNullOrUndefined(result) ? result : result[key]),
    obj,
  )
}

export { getObjectValue }
