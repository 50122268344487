import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNotificationOfChangesInDetailsOfPartiesForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '2': {
      ruleLinkInfoChanges: {
        propertyId: objectFromPropertyState.ruleLinkInfoChanges?.propertyId,
        type: objectFromPropertyState.ruleLinkInfoChanges?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkInfoChanges?.lastUpdateDt,
      },
      ...PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
  }
}

export { generatePropertiesObjectForNotificationOfChangesInDetailsOfPartiesForm }
