import env from '@config'

export const messageToYandexBrowser =
  'Для обеспечения безопасности и установки соединения используйте Яндекс.Браузер.'

export const messageToOtherBrowser = (
  text = 'Личные кабинеты органов власти, местного самоуправления и уполномоченной организации доступны по адресу',
) => {
  return `${messageToYandexBrowser}</br>${text}`
}
export const certificateIsNotValidText =
  'Сертификат принадлежит органу власти, который не является уполномоченным. Регистрация невозможна'

export const certificateIsNotValidTextByESIA =
  'Орган власти не входит в список уполномоченных. Регистрация невозможна'
export const defaultMessage = 'Произошла ошибка. Повторите попытку позже'
export const defaultMessageSupport = `Произошла ошибка. Повторите попытку позже или обратитесь по адресу ${
  env.REACT_APP_SUPPORT_EMAIL || ''
}`
export const defaultMessageSupportWithLink = (
  <>
    {'Повторите попытку позже или обратитесь по адресу '}
    <a href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}>{env.REACT_APP_SUPPORT_EMAIL || ''}</a>
  </>
)

export const errorsMessages = {
  413: 'Размер файла превышает установленное ограничение в 200MB',
}

export const getLKHeaderOrganization = (
  additionalText = 'Личные кабинеты органов власти, местного самоуправления и уполномоченной организации',
) => {
  return additionalText
}

export const successModalHeaderTexts = {
  checkEmail: 'Проверьте свою почту',
  agreementIntoRegister: 'Соглашение внесено в реестр',
  documentsSigned: 'Документы подписаны',
  terminationInRegister: 'Расторжение внесено в реестр',
  defaultMessage: 'Документы подписаны и отправлены на проверку',
  getRightsSZPK: 'Началось получение прав на СЗПК',
} as const

export const successModalBodyTexts = {
  checkEmail: `Письмо со ссылкой для подтверждения отправили на`,
  agreementInfo: 'Информация о соглашении зарегистрирована в реестре и направлена инвестору',
  terminationInfo: 'Информация о расторжении зарегистрирована в реестре и направлена инвестору',
  getRightsSZPK:
    'Вы получите уведомление, когда документы по передаче прав поступят к вам на подписание',
  defaultMessage: 'Мы уведомим вас, когда начнется их проверка',
} as const

export const successModalButtonTexts = {
  defaultMessage: 'Отлично',
} as const

export const errorModalHeaderTexts = {
  statementIncorrect: 'Заявление заполнено некорректно',
  szpkIncorrect: 'Заявление на заключение СЗПК заполнено некорректно',
  npaListIncorrect: 'Список стабилизируемых актов заполнен некорректно',
  fillError: 'Ошибка заполнения',
  defaultMessage: 'Произошла ошибка',
} as const

export const errorModalBodyTexts = {
  statementFixNecessary: 'Для продолжения необходимо исправить заявление',
  innIncorrect: `Проверьте, что номер ИНН указан верно
Если все корректно, значит организация не зарегистрирована в системе и пока не может быть участником соглашения `,
  fixErrorsMessage: 'Исправьте ошибки и попробуйте ещё раз',
  requiredFieldsFilled: 'Убедитесь, что заполнены все обязательные поля и повторите попытку',
  defaultMessage: 'Произошла ошибка',
} as const

export const errorModalButtonTexts = {
  returnToStatement: 'Вернуться к заявлению',
  returnToNpaList: 'Вернуться к списку актов',
  returnToFilling: 'Вернуться к заполнению',
  continueMessage: 'Продолжить',
  fixMessage: 'Исправить',
  backMessage: 'Назад',
  defaultMessage: 'Понятно',
} as const

export const signModalHeaderTexts = {
  defaultMessage: 'Подпишите документы',
} as const

export const signModalBodyTexts = {
  szpkNotification:
    'Уведомление о смене реквизитов будет добавлено в\u00A0действующие СЗПК и\u00A0доступно участникам соглашения',
  defaultMessage:
    'После подписания они будут отправлены следующему участнику. Отменить подписание будет невозможно',
} as const

export const defaultModalButtonTexts = {
  cancelMessage: 'Отмена',
  successMessage: 'Сохранить',
} as const

export const letterHeadPartsBaseTexts = {
  sideBarTitle: 'Бланк организации',
  itemsTitlePrefix: 'Изображение для',
  firstPostfix: 'титульного листа',
  generalPostfix: 'страниц документа',
  main: {
    header: {
      empty: 'Загрузите бланк организации',
      notEmpty: 'Бланк организации',
    },
    text: {
      empty: 'Загрузите изображения для размещения на документах, формируемых от имени организации',
      notEmpty:
        'Документы, формируемые от имени организации, будут оформлены с применением бланка организации',
    },
    buttons: {
      open: 'Открыть бланк',
      edit: 'Редактировать',
      change: 'Изменить',
      preview: 'Предпросмотр бланка',
    },
  },
  dropZoneText: 'Перетащите файл в эту область или ',
  previewFileName: 'Пример бланка организации',
  errorUploadFileMessage: 'Выберите файл формата PNG или JPEG, размером до 300Кб',
  messageSupportWithLink: (
    <>
      {'Если у вас не получается заполнить бланк организации, обратитесь по адресу '}
      <a href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}>
        {env.REACT_APP_SUPPORT_EMAIL || ''}
      </a>
    </>
  ),
} as const

const _letter_footer = 'нижнего колонтитула'
export const letterHeadPartItemsTexts = {
  first_header: {
    empty: `${letterHeadPartsBaseTexts.itemsTitlePrefix} ${letterHeadPartsBaseTexts.firstPostfix}`,
    filled: 'Титульный лист',
  },
  first_footer: {
    empty: `${letterHeadPartsBaseTexts.itemsTitlePrefix} ${_letter_footer} ${letterHeadPartsBaseTexts.firstPostfix}`,
    filled: `Нижний колонтитул ${letterHeadPartsBaseTexts.firstPostfix}`,
  },
  general_header: {
    empty: `${letterHeadPartsBaseTexts.itemsTitlePrefix} верхнего колонтитула ${letterHeadPartsBaseTexts.generalPostfix}`,
    filled: `Верхний колонтитул ${letterHeadPartsBaseTexts.generalPostfix}`,
  },
  general_footer: {
    empty: `${letterHeadPartsBaseTexts.itemsTitlePrefix} ${_letter_footer} ${letterHeadPartsBaseTexts.generalPostfix}`,
    filled: `Нижний колонтитул ${letterHeadPartsBaseTexts.generalPostfix}`,
  },
} as const

export const signModalButtonTexts = {
  doneMessage: 'Отлично',
  failedMessage: 'Подписать еще раз',
  cancelMessage: 'Отмена',
  defaultMessage: 'Подписать и отправить',
} as const

export const loaderModalHeaderTexts = {
  terminationIntoRegister: 'Внесение расторжения в реестр',
  agreementIntoRegister: 'Внесение соглашения в реестр',
  defaultMessage: 'Выполняется действие',
} as const

export const loaderModalBodyTexts = {
  progressMessage: 'Это может занять некоторое время',
  defaultMessage: 'Идет подписание документов',
} as const

export const registrationModalHeaderTexts = {
  agreementIntoRegister: 'Внесение соглашения в реестр',
} as const

export const registrationModalBodyTexts = {
  agreementMessage: 'Пожалуйста, укажите регистрационный номер соглашения и дату внесения в реестр',
} as const
