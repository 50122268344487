import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import GroupWithPublic from '@components/DocumentFormComponents/GroupWithPublic'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { StepRegisteredDateValuesArrayPathName } from '@components/Forms/RegistrationCertificateForm/Forms/5/types'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface StepRegisteredDateValueItemProps {
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  formName: StepRegisteredDateValuesArrayPathName
}

const StepRegisteredDateValueItem: FC<StepRegisteredDateValueItemProps> = ({
  formName,
  formInstance,
  formModifierInstance,
}) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue, handleChangePublicValueInList },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { getFormFieldControlProps, getControllerProps, getAmountInputProps } =
    useFormComponentPresets({
      isNew: true,
      editMode,
      blockViewIsValidating,
      formInstance,
      watcher: RegistrationCertificateFieldsControlUpdateWatcher,
      subscribableControl,
    })

  return (
    <Container>
      <Stack direction={'vertical'} gap={3}>
        <GroupWithPublic
          disableBottomBorder
          name={`${formName}.stepDateGroup`}
          control={formModifierInstance.control}
          controlledFormNames={[`${formName}.stepStartDate`, `${formName}.stepEndDate`]}
          onPublic={handleChangePublicValueInList}
        >
          <Row>
            <Col xs={6}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.stepStartDate`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle="Дата начала этапа"
                  controllerProps={getControllerProps({
                    name: `${formName}.stepStartDate`,
                  })}
                />
              </FormFieldControl>
            </Col>
            <Col xs={6}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.stepEndDate`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle="Дата окончания этапа"
                  controllerProps={getControllerProps({
                    name: `${formName}.stepEndDate`,
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
        </GroupWithPublic>
        <GroupWithPublic
          disableBottomBorder
          name={`${formName}.capitalGroup`}
          control={formModifierInstance.control}
          controlledFormNames={[
            `${formName}.stepInvestorCapitalValue`,
            `${formName}.factStepInvestorCapital`,
            `${formName}.stepRemainsCapital`,
          ]}
          onPublic={handleChangePublicValueInList}
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.stepInvestorCapitalValue`,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Объем капиталовложений по этапу"
                    controllerProps={getControllerProps({
                      name: `${formName}.stepInvestorCapitalValue`,
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.factStepInvestorCapital`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.factStepInvestorCapital`,
                      inputProps: {
                        label: 'Объем осуществленных капиталовложений',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.factStepInvestorCapital`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.factStepInvestorCapital`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
              <Col xs={6}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.stepRemainsCapital`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.stepRemainsCapital`,
                      inputProps: {
                        label: 'Объем планируемых капиталовложений',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.stepRemainsCapital`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.stepRemainsCapital`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
          </Stack>
        </GroupWithPublic>
      </Stack>
    </Container>
  )
}

export default StepRegisteredDateValueItem
