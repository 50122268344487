import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  IEconomicSphereOkvedFederalReport,
  SummaryReportOfMonitoringResultsFormValues,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  defaultRHFValidation,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const summaryReportOfMonitoringResultsEconomicSphereOkvedsValidationMap: FormValuesValidationSection<
  keyof IEconomicSphereOkvedFederalReport,
  SummaryReportOfMonitoringResultsFormValues
> = {
  economicSphereOkvedCodeFederalReport: {
    required: defaultRHFValidation.required,
  },
  countEconomicSphereOkvedFederalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  totalCapitalEconomicSphereOkvedFederalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  investorCapitalEconomicSphereOkvedFederalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  newWorkPlacesEconomicSphereOkvedFederalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
}

export { summaryReportOfMonitoringResultsEconomicSphereOkvedsValidationMap }
