import React, { FC, memo, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ReactSVG } from 'react-svg'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import {
  ChooseNpaFormValues,
  ChooseNpaThreeNpaPartArrayPathName,
} from '@components/RegistryNpa/Choose/types'
import PartItem from '@components/RegistryNpa/MasterItem/Parts/Item'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'
import { useHover } from '@hooks/new/events/useHover'
import AddButtonIcon from '@icons/AddButtonIcon.svg'
import ChosenIcon from '@icons/ChoosenIcon.svg'
import { INpaItemNew } from '@services/NPA/NPA.entity'
import cn from 'classnames'

import styles from './PartNpaItem.module.scss'

interface PartNpaItemProps {
  isChosen: boolean
  partId: string
  part: string
  classificationHeader: string
  name: ChooseNpaThreeNpaPartArrayPathName
  formInstance: UseFormReturn<ChooseNpaFormValues>
  onChoosePart: (isChosen: boolean) => void
  onChangePart: (partItem: INpaItemNew) => void
  initialError?: boolean
}

const PartNpaItem: FC<PartNpaItemProps> = ({
  isChosen,
  partId,
  part,
  name,
  formInstance,
  classificationHeader,
  onChoosePart,
  onChangePart,
  initialError,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>()

  const handleChangePart = () => {
    const partItem = formInstance.getValues(name)

    onChangePart(partItem)
  }

  const handleTogglePart = () => {
    onChoosePart(!isChosen)
  }

  useEffect(() => {
    if (!isChosen) return

    setTimeout(() => formInstance.setFocus(`${name}.justification`), 0)
  }, [isChosen])

  useEffect(() => {
    if (!initialError) return

    formInstance.trigger(name)
  }, [])

  return (
    <button
      type="button"
      ref={hoverRef}
      data-testid={`PartNpaItem-part-${partId}-itemButton`}
      className={cn(styles.partNpaItem, {
        [styles['partNpaItem--hovered']]: isHovered || isChosen,
      })}
    >
      <PartItem
        contentClassName={styles.partNpaItem__content}
        part={part}
        classificationHeader={classificationHeader}
      >
        <ReactSVG
          data-testid={`PartNpaItem-part-${partId}-togglePartItem`}
          src={isChosen ? ChosenIcon : AddButtonIcon}
          onClick={handleTogglePart}
        />
      </PartItem>

      <CollapseWrapper
        unmountOnCollapse
        className={styles.partNpaItem__collapse}
        isExpanded={isChosen}
        defaultExpanded={isChosen}
      >
        <div className={styles.partNpaItem__collapse}>
          <div className={styles.partNpaItem__inputs}>
            <ControlledInput
              name={`${name}.justification`}
              control={formInstance.control}
              rules={
                isChosen
                  ? {
                      required: defaultRHFValidation.required,
                      validate: (value) => isString(value) && lengthValidate(value, 1000),
                    }
                  : undefined
              }
              inputProps={{
                rootClassName: styles['partNpaItem__inputs-justification'],
                size: 's',
                placeholder: 'Обоснование (обязательно)',
                dataTestId: `PartNpaItem-part-${partId}-justification-input`,
              }}
              onBlur={handleChangePart}
            />
            <ControlledInput
              name={`${name}.partCorrection`}
              control={formInstance.control}
              rules={
                isChosen
                  ? {
                      validate: (value) =>
                        isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined,
                    }
                  : undefined
              }
              inputProps={{
                rootClassName: styles['partNpaItem__inputs-partCorrection'],
                size: 's',
                placeholder:
                  'Положения (пункты и статьи), которые хотите стабилизировать (например, ч. 1 ст. 5)',
                dataTestId: `PartNpaItem-part-${partId}-partCorrection-input`,
              }}
              onBlur={handleChangePart}
            />
          </div>
        </div>
      </CollapseWrapper>
    </button>
  )
}

export default memo(PartNpaItem)
