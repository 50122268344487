const hierarchyReferenceBookSelectManagerReducerActionMap = {
  SETUP_THREE_LOADING: 'SETUP_THREE_LOADING',
  SETUP_MODAL_OPEN: 'SETUP_MODAL_OPEN',
  SETUP_SELECTED_ITEM_ID: 'SETUP_SELECTED_ITEM_ID',
  SETUP_THREE: 'SETUP_THREE',
  SETUP_SEARCH_THREE: 'SETUP_SEARCH_THREE',
  CLEAR_ALL_STATES: 'CLEAR_ALL_STATES',
  CLEAR_SELECTED_ITEM: 'CLEAR_SELECTED_ITEM',
} as const

export { hierarchyReferenceBookSelectManagerReducerActionMap }
