import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const oneSectionAgreementStabilizationNegotiationsValidationMap: FormValuesValidationSection<
  keyof AgreementStabilizationNegotiationsFormValues['1'],
  AgreementStabilizationNegotiationsFormValues
> = {
  rfHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { oneSectionAgreementStabilizationNegotiationsValidationMap }
