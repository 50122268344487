import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { parametersApplicationReimbursementExpensesBlockValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/const'
import { compensationFactValidationMap } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Forms/3/AdditionalForms/CompensationFact/validation'
import CompensationFactObjects from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Forms/3/AdditionalForms/CompensationFactObjects'
import { CompensationArrayFactPathName } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Forms/3/types'
import { useParametersApplicationReimbursementExpensesManager } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Manager'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import { ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { disableFutureDates } from '@helpers/date/disableOfDates'

import styles from './CompensationFact.module.scss'

interface CompensationFactProps {
  id: string
  name: CompensationArrayFactPathName
}

const COMPENSATION_FACT_FORM_TO_IGNORE = ['Налоговый вычет', 'Субсидия и (или) налоговый вычет']

const CompensationFact: FC<CompensationFactProps> = ({ id, name }) => {
  const formInstance = useFormContext<ParametersApplicationReimbursementExpensesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useParametersApplicationReimbursementExpensesManager()

  const isFederal = useWatch({
    name: parametersApplicationReimbursementExpensesBlockValues.additionalFields.isFederal,
    control: formInstance?.control,
  })

  const {
    getSubscribableControlProps,
    getCalendarInputProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate id={id} contentClassName={styles.compensationFact__stepTemplate}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={4}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.compensationFactYear`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.compensationFactYear`,
                  rules: compensationFactValidationMap.compensationFactYear,
                  calendarProps: {
                    typeOfPicker: 'years',
                    dateFormat: 'YYYY',
                    disabledDate: (date) => disableFutureDates(date),
                  },
                  calendarInputProps: {
                    label: 'Отчетный год',
                    inputMask: YEAR_MASK,
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.compensationFactYear`), 0),
                  onInputChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.compensationFactYear`)
                    }, 0),
                  onCalendarChange: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.compensationFactYear`), 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={8}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.compensationFactFormValue`,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => {
                        // Решение аналитика
                        if (item.name === 'Субсидия и (или) налоговый вычет') return null

                        if (
                          !isFederal &&
                          COMPENSATION_FACT_FORM_TO_IGNORE.some((value) => value == item.name)
                        )
                          return null

                        return {
                          value: item.id,
                          displayValue: item.name,
                        }
                      },
                      controllerProps: {
                        name: `${name}.compensationFactFormValue`,
                        rules: compensationFactValidationMap.compensationFactFormValue,
                      },
                      selectProps: {
                        inputProps: {
                          label: 'Форма возмещения',
                        },
                        onChangeFormValue: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${name}.compensationFactFormValue`)
                          }, 0),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.compensationFactExpenseGroupValue`,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        value: item.id,
                        displayValue: item.name,
                      }),
                      controllerProps: {
                        name: `${name}.compensationFactExpenseGroupValue`,
                        rules: compensationFactValidationMap.compensationFactExpenseGroupValue,
                      },
                      selectProps: {
                        inputProps: {
                          label: 'Вид затрат в соответствии с 69-ФЗ',
                        },
                        onChangeFormValue: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${name}.compensationFactExpenseGroupValue`)
                          }, 0),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.compensationFactSum`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.compensationFactSum`,
                  rules: compensationFactValidationMap.compensationFactSum,
                  inputProps: {
                    label: 'Сумма',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.compensationFactSum`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.compensationFactSum`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={8}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.compensationFactDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.compensationFactDate`,
                  rules: compensationFactValidationMap.compensationFactDate,
                  calendarInputProps: {
                    label: 'Дата предоставления меры поддержки',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.compensationFactDate`), 0),
                  onInputChange: () =>
                    setTimeout(
                      () => debouncedHandleChangeValue?.(`${name}.compensationFactDate`),
                      0,
                    ),
                  onCalendarChange: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.compensationFactDate`), 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <CompensationFactObjects
              formInstance={formInstance}
              name={`${name}.compensationFactObjects`}
            />
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default CompensationFact
