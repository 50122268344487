import React, { type FC, type FocusEvent, useCallback, useRef } from 'react'
import type { FileRejection } from 'react-dropzone'
import { UseFormReturn } from 'react-hook-form'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import { useAttachmentAPIContext } from '@components/Attachments/Attachment/APIProvider'
import FileItem from '@components/Attachments/Attachment/FileItem'
import { useAttachmentFileItemFieldArray } from '@components/Attachments/Attachment/FileItem/AttachmentFileItem/hooks/useAttachmentFileItemFieldArray'
import { FileItemService } from '@components/Attachments/Attachment/FileItem/helpers'
import LeadingAdditionInTrailingNode from '@components/Attachments/Attachment/FileItem/TrailingNode/Addition/LeadingNodeTrailing'
import TrailingAdditionInTrailingNode from '@components/Attachments/Attachment/FileItem/TrailingNode/Addition/TrailingNodeAddition'
import styles from '@components/Attachments/Attachment/FileItem/TrailingNode/FileItemTrailingNode.module.scss'
import {
  FileItemDropzoneProps,
  RejectedFile,
} from '@components/Attachments/Attachment/FileItem/types'
import { useAttachmentPermissions } from '@components/Attachments/Attachment/hooks/useAttachmentPermissions'
import { useDropzoneValidation } from '@components/Attachments/Attachment/hooks/useDropzoneValidation'
import { attachmentsFormNames, statusTypesMap } from '@components/Attachments/constants'
import { useFileContext } from '@components/Attachments/FileProvider'
import type { AttachmentsFormValue } from '@components/Attachments/types'
import { useCreateStatementOldControl } from '@components/Forms/CreateStatementOld'
import Typography from '@components/NewDesign/Typography'
import { useDeleteFolderModal } from '@components/NewDesignedModals/DeleteFolderModal/manager'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import { useVersionController } from '@components/Projects/[id]/VersionConroller'
import {
  ChangeDocumentAttributeProps,
  useDocumentViewSidebarControl,
} from '@components/Sidebars/DocumentView'
import { documentActionsMap } from '@constants/documents'
import { DocumentsSetsType, DocumentsType, MimeTypes, RolesTypes } from '@constants/types'
import DownloadDocumentTooltipContent from '@containers/DownloadDocumentTooltipContent'
import { checkRoleOnExistence } from '@context/AuthContext/workers/rolesWorkers'
import { isBoolean, isNull, isNullOrUndefined } from '@helpers/checkTypes'
import { createFileItem, splitFileName } from '@helpers/fileHelpers'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import useDownloadFile from '@hooks/new/documents/useDownloadFile'
import { useVersions } from '@hooks/new/swr/useVersions'
import { AttachmentErrorProps, IAttachmentFileItem } from '@interfaces/documents'
import type { IDocumentDictInfo } from '@services/Dictionaries/dictionaries.entity'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import { AttachmentFileItemService } from './helpers'
import type { HandleDownloadFileProps } from './types'

const MAX_FILE_SIZE_IN_BYTES = 200 * 1024 * 1024

const { convertRejectedFile, convertMultipleRejectedFile } = FileItemService
const { getFileIndexById, isSignedDocument, parseFileItemError, parseFileItemSignError } =
  AttachmentFileItemService

const { isDocumentReadSignMainPermission } = DocumentsHelpersService

interface AttachmentFileItemProps {
  documentInfoFromDict: Omit<IDocumentDictInfo, 'isFolderAvailable' | 'isMultiple'>
  formInstance: UseFormReturn<AttachmentsFormValue>
  documentSetId: string
  permissions: number
  parentSetType: string
  projectId: string
  dfoId: string
  isChangesMadeTab?: boolean
}

const AttachmentFileItem: FC<AttachmentFileItemProps> = ({
  documentInfoFromDict,
  formInstance,
  documentSetId,
  permissions,
  parentSetType,
  projectId,
  dfoId,
  isChangesMadeTab,
}) => {
  const { isFederal, isMain, templateExists, description, documentType } = documentInfoFromDict
  const { handleAddFileToSet, handleUpdateFileFromSet, handleRemoveFileFromSet } = useFileContext()

  const {
    attachmentFileApi: {
      handleAddFile,
      handleRemoveFile,
      handleChangeFileName,
      handleChangeFile,
      handleForeignSign,
      handleDownloadTemplate,
    },
  } = useAttachmentAPIContext()

  const { download } = useDownloadFile()

  const { openStatementOldModal } = useCreateStatementOldControl()
  const { openDocumentViewSidebar } = useDocumentViewSidebarControl()
  const { handleOpenDeleteFolderModal } = useDeleteFolderModal()
  const { handleOpenErrorModal } = useErrorModal()

  const { dropzoneValidator } = useDropzoneValidation({
    maxFileSize: MAX_FILE_SIZE_IN_BYTES,
    acceptExtensions: MimeTypes['text/csv'],
  })

  const replacedAttachmentFileRef = useRef<IAttachmentFileItem | null>(null)

  const { attachments, handleAddFileItem, handleUpdateFileItem, handleRemoveFileItem } =
    useAttachmentFileItemFieldArray(formInstance)

  const { versions } = useVersions({
    key: { dfoId, projectId, _key: 'versions' },
    config: {
      isPaused: () => !dfoId || !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AttachmentFileItem',
          moduleName: 'AttachmentsController',
        },
      }),
    },
  })

  const versionState = useVersionController()

  const {
    isReadOnlyAttachmentPermission,
    isReadAttachmentPermission,
    isReadApproveAttachmentPermission,
    isWriteAttachmentPermission,
    isDownloadForbiddenAttachmentPermission,
    isReadDigitizingPermission,
  } = useAttachmentPermissions({
    permissions,
  })

  const isReadSignMainPermission = isDocumentReadSignMainPermission(permissions)

  const isSignAttachmentPermission = isReadSignMainPermission && isMain

  const baseDropzoneProps: FileItemDropzoneProps = {
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    accept: MimeTypes,
    validator: dropzoneValidator(description),
    multiple: false,
  }

  const isOIV = checkRoleOnExistence(RolesTypes.OIV)

  const isShowSignButton = isOIV && !isFederal && isSignAttachmentPermission

  const hasDocumentTemplateType = isWriteAttachmentPermission && templateExists

  const isStaticDocument =
    isReadAttachmentPermission || isReadApproveAttachmentPermission || isReadDigitizingPermission

  const isStatementDocumentType = documentType === DocumentsType.STATEMENT

  const conditionToSignedMarkRender =
    isReadOnlyAttachmentPermission && parentSetType === DocumentsSetsType.DEPARTMENTS_RESPONSES

  const handleToastAlert = useCallback((text = 'Не удалось получить информацию о документе') => {
    toast(
      <Alert transparent variant="error">
        {text}
      </Alert>,
    )
  }, [])

  const handleClearError = useCallback(
    (attachment: IAttachmentFileItem, index: number) => {
      handleUpdateFileItem({
        attachmentFileIndex: index,
        attachmentFileItem: {
          ...attachment,
          error: null,
        },
      })
    },
    [handleUpdateFileItem],
  )

  const handleAddNewFile = useCallback(
    async (file: File) => {
      const fileWithCurrentDate = new File([file], file.name)

      const createdAttachmentItem = createFileItem({
        file: fileWithCurrentDate,
        isLoading: true,
      })

      let updatedAttachmentItem = createdAttachmentItem

      handleAddFileItem({
        attachmentFileItem: updatedAttachmentItem,
      })

      const currentAttachments = formInstance.getValues(attachmentsFormNames.fileItems)

      const attachmentFileIndex = getFileIndexById(currentAttachments, updatedAttachmentItem.id)

      try {
        const document = await handleAddFile?.({
          file: fileWithCurrentDate,
          type: documentType,
          documentSetId,
        })

        if (!document) return

        const { type, folder, ...documentInfo } = document

        updatedAttachmentItem = {
          ...createdAttachmentItem,
          file: fileWithCurrentDate,
          documentInfo,
        }

        handleUpdateFileItem({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachmentItem,
        })

        handleAddFileToSet?.({
          type,
          document: documentInfo,
        })
      } catch (error) {
        const preparedError: AttachmentErrorProps = {
          actionType: documentActionsMap.ADD,
          message: parseFileItemError(error),
        }

        updatedAttachmentItem = {
          ...createdAttachmentItem,
          error: preparedError,
        }

        handleUpdateFileItem({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachmentItem,
        })
      } finally {
        handleUpdateFileItem({
          attachmentFileIndex,
          attachmentFileItem: {
            ...updatedAttachmentItem,
            isLoading: false,
          },
        })
      }
    },
    [
      documentSetId,
      documentType,
      formInstance,
      handleAddFile,
      handleAddFileItem,
      handleAddFileToSet,
      handleUpdateFileItem,
    ],
  )

  const handleUpdateFile = useCallback(
    async (attachment: IAttachmentFileItem, updatedFile: File) => {
      const { documentInfo } = attachment

      if (!documentInfo) return

      const attachmentFileIndex = getFileIndexById(attachments, attachment.id)

      let updatedAttachment: IAttachmentFileItem = {
        ...attachment,
        isLoading: true,
        error: null,
      }

      handleUpdateFileItem({
        attachmentFileIndex,
        attachmentFileItem: updatedAttachment,
      })

      const updatedFileWithCurrentDate = new File([updatedFile], updatedFile.name)

      const [fileName, fileExtension] = splitFileName(updatedFileWithCurrentDate.name)

      updatedAttachment = {
        ...updatedAttachment,
        file: updatedFileWithCurrentDate,
        documentInfo: {
          ...documentInfo,
          name: fileName,
          extension: fileExtension,
          size: updatedFile.size,
        },
      }

      handleUpdateFileItem({
        attachmentFileIndex,
        attachmentFileItem: updatedAttachment,
      })
      try {
        const updatedDocumentInfo = await handleChangeFile?.({
          file: updatedFileWithCurrentDate,
          fileId: documentInfo.id,
          documentSetId,
        })

        if (!updatedDocumentInfo) return

        updatedAttachment = {
          ...updatedAttachment,
          documentInfo: updatedDocumentInfo,
        }

        handleUpdateFileItem({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachment,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
        })

        replacedAttachmentFileRef.current = null
      } catch (error) {
        const preparedError: AttachmentErrorProps = {
          actionType: documentActionsMap.UPDATE,
          message: parseFileItemError(error),
        }

        updatedAttachment = {
          ...attachment,
          error: preparedError,
        }

        replacedAttachmentFileRef.current = {
          ...updatedAttachment,
          file: updatedFileWithCurrentDate,
        }

        handleUpdateFileItem({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachment,
        })
      } finally {
        updatedAttachment = {
          ...updatedAttachment,
          isLoading: false,
        }

        handleUpdateFileItem({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachment,
        })
      }
    },
    [
      attachments,
      documentSetId,
      documentType,
      handleChangeFile,
      handleUpdateFileFromSet,
      handleUpdateFileItem,
    ],
  )

  const handleRejectedFile = useCallback(
    (rejectedFiles: FileRejection[], files?: File[]) => {
      let preparedRejectedFile: RejectedFile | null = null

      if (
        rejectedFiles.length > 1 ||
        !!files?.length ||
        (rejectedFiles.length && !!files?.length)
      ) {
        preparedRejectedFile = convertMultipleRejectedFile(files?.[0] || rejectedFiles[0].file)
      } else {
        preparedRejectedFile = convertRejectedFile(rejectedFiles[0])
      }

      if (!replacedAttachmentFileRef.current) {
        const rejectedAttachmentItem = createFileItem({
          rejectedFile: preparedRejectedFile,
        })

        return handleAddFileItem({ attachmentFileItem: rejectedAttachmentItem })
      }

      const { file, documentInfo, ...restAttachmentFileItem } = replacedAttachmentFileRef.current

      if (!documentInfo) return

      const attachmentFileIndex = getFileIndexById(
        attachments,
        replacedAttachmentFileRef.current.id,
      )

      const attachmentFileItem: IAttachmentFileItem = {
        ...restAttachmentFileItem,
        file: null,
        rejectedFile: preparedRejectedFile,
      }

      handleUpdateFileItem({ attachmentFileItem, attachmentFileIndex })
    },
    [attachments, handleAddFileItem, handleUpdateFileItem],
  )

  const handleDropAction = useCallback(
    async (fileToUpload: File) => {
      if (!replacedAttachmentFileRef.current) {
        return handleAddNewFile(fileToUpload)
      }

      return handleUpdateFile(replacedAttachmentFileRef.current, fileToUpload)
    },
    [handleAddNewFile, handleUpdateFile],
  )

  // Используется в FileDropzoneError, когда уже есть rejectedFile
  const handleRejectDropFile = useCallback(
    (attachment: IAttachmentFileItem) =>
      async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length || acceptedFiles.length > 1)
          return handleRejectedFile(fileRejections, acceptedFiles)

        const fileToUpload = acceptedFiles[0]

        const attachmentFileIndex = getFileIndexById(attachments, attachment.id)

        handleRemoveFileItem({ attachmentFileIndex })

        await handleDropAction(fileToUpload)
      },
    [attachments, handleDropAction, handleRejectedFile, handleRemoveFileItem],
  )

  const handleDropFile = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length || acceptedFiles.length > 1)
        return handleRejectedFile(fileRejections, acceptedFiles)

      const fileToUploadWithCurrentDate = new File([acceptedFiles[0]], acceptedFiles[0].name)

      await handleDropAction(fileToUploadWithCurrentDate)
    },
    [handleDropAction, handleRejectedFile],
  )

  const handleReplaceFile = useCallback(
    (attachment: IAttachmentFileItem) => () => {
      replacedAttachmentFileRef.current = attachment
    },
    [],
  )

  const handleDeleteFile = useCallback(
    (attachment: IAttachmentFileItem) => async () => {
      const { id } = attachment.documentInfo || {}

      const attachmentFileIndex = getFileIndexById(attachments, attachment.id)

      handleRemoveFileItem({
        attachmentFileIndex,
      })

      try {
        await handleRemoveFile?.({
          documentSetId,
          fileId: id || '',
        })

        handleRemoveFileFromSet?.({
          documentId: id || '',
          type: documentType,
        })
      } catch (error) {
        const preparedError: AttachmentErrorProps = {
          actionType: documentActionsMap.DELETE,
          message: parseFileItemError(error),
        }

        handleAddFileItem({
          attachmentFileItem: {
            ...attachment,
            error: preparedError,
          },
        })
      }
    },
    [
      attachments,
      documentSetId,
      documentType,
      handleAddFileItem,
      handleRemoveFile,
      handleRemoveFileFromSet,
      handleRemoveFileItem,
    ],
  )

  const handleDeleteFileWithModal = useCallback(
    (attachment: IAttachmentFileItem) => async () => {
      const { name, extension } = attachment.documentInfo || {}

      const preparedFileNameText = name && extension ? ` "${name}.${extension}"` : ''

      handleOpenDeleteFolderModal({
        header: 'Подтверждение удаления документа',
        description: `Вы действительно хотите удалить документ${preparedFileNameText}?`,
        onDelete: handleDeleteFile(attachment),
      })
    },
    [handleDeleteFile, handleOpenDeleteFolderModal],
  )

  const handleDownloadFile = useCallback(
    ({ withSigned = false, attachment }: HandleDownloadFileProps) =>
      (event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation()

        const { documentInfo } = attachment

        if (!documentInfo) return handleToastAlert()

        const { name, extension, id, versionId } = documentInfo

        const fileNameWithExtension = `${name}.${extension}`

        return withDownloadToastPromise(
          download({
            documentSetId,
            projectId,
            fileId: id,
            fileNameWithExtension,
            versionId,
            withSigned,
            type: 'save',
          }),
        )
      },
    [documentSetId, download, handleToastAlert, projectId],
  )

  const handleDownloadDocumentTemplate = useCallback(() => {
    return withDownloadToastPromise(handleDownloadTemplate({ documentType }))
  }, [documentType, handleDownloadTemplate])

  const handleChangeFileNameOnBlur = useCallback(
    (attachment: IAttachmentFileItem) => async (event: FocusEvent<HTMLInputElement>) => {
      const { documentInfo, file, ...restAttachment } = attachment || {}

      if (!documentSetId || !documentInfo || !file) return

      const attachmentFileIndex = getFileIndexById(attachments, attachment.id)

      const { value } = event.target

      const [_fileName, fileExtension] = splitFileName(file.name)

      const updatedFile = new File([file], `${value}.${fileExtension}`)
      const updatedDocumentInfo: IAttachmentFileItem['documentInfo'] = {
        ...documentInfo,
        name: value,
      }

      const updatedAttachment: IAttachmentFileItem = {
        ...restAttachment,
        file: updatedFile,
        documentInfo: updatedDocumentInfo,
      }

      handleUpdateFileItem({
        attachmentFileIndex,
        attachmentFileItem: updatedAttachment,
      })

      try {
        await handleChangeFileName?.({
          fileId: documentInfo.id,
          documentSetId,
          name: value,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
        })
      } catch (error) {
        handleUpdateFileItem({
          attachmentFileIndex,
          attachmentFileItem: attachment,
        })
      }
    },
    [
      attachments,
      documentSetId,
      documentType,
      handleChangeFileName,
      handleUpdateFileFromSet,
      handleUpdateFileItem,
    ],
  )

  const handleOpenStatementModal = useCallback(() => {
    openStatementOldModal({
      projectId,
      dfoId,
      documentSetId,
    })
  }, [dfoId, documentSetId, openStatementOldModal, projectId])

  const handleChangeAttachmentAttribute = useCallback(
    (attachment: IAttachmentFileItem) =>
      ({ attributeName, value }: ChangeDocumentAttributeProps) => {
        if (!attachment.documentInfo) return handleToastAlert()

        const attachmentFileIndex = getFileIndexById(attachments, attachment.id)

        const updatedDocumentInfo: IAttachmentFileItem['documentInfo'] = {
          ...attachment.documentInfo,
          [attributeName]: value,
        }

        handleUpdateFileItem({
          attachmentFileItem: {
            ...attachment,
            documentInfo: updatedDocumentInfo,
          },
          attachmentFileIndex,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
        })
      },
    [attachments, documentType, handleToastAlert, handleUpdateFileFromSet, handleUpdateFileItem],
  )

  const handleOpenDocumentViewSidebar = useCallback(
    (attachment: IAttachmentFileItem) => () => {
      const { documentInfo } = attachment || {}

      if (!documentInfo) return handleToastAlert()

      openDocumentViewSidebar({
        documentId: documentInfo.id,
        projectId,
        dfoId,
        documentSetId,
        isStatic: isStaticDocument || !!documentInfo.isApproved,
        isApproved: documentInfo.isApproved,
        accessToApprove: isReadApproveAttachmentPermission,
        typeOfDocument: description || '',
        edit: {
          onBlur: handleChangeFileNameOnBlur(attachment),
          value: documentInfo.name,
        },
        onChangeDocumentAttribute: handleChangeAttachmentAttribute(attachment),
      })
    },
    [
      description,
      dfoId,
      documentSetId,
      handleChangeAttachmentAttribute,
      handleChangeFileNameOnBlur,
      handleToastAlert,
      isReadApproveAttachmentPermission,
      isStaticDocument,
      openDocumentViewSidebar,
      projectId,
    ],
  )

  const handleDropFileSign = useCallback(
    (attachment: IAttachmentFileItem) =>
      async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (!acceptedFiles.length || fileRejections.length) return

        const { documentInfo } = attachment || {}

        if (!documentInfo) return handleToastAlert()

        const sigFile = acceptedFiles[0]

        try {
          await handleForeignSign({
            sigFile,
            documentId: documentInfo.id,
            documentSetId,
          })
        } catch (error) {
          const { headerText, bodyText } = parseFileItemSignError(error)

          handleOpenErrorModal({
            headerText,
            bodyText,
          })

          throw error
        }
      },
    [documentSetId, handleForeignSign, handleOpenErrorModal, handleToastAlert],
  )

  const handleReloadFile = useCallback(
    (attachment: IAttachmentFileItem) => () => {
      if (!attachment.file) return

      const attachmentFileIndex = getFileIndexById(attachments, attachment.id)

      const { actionType } = attachment.error || {}

      if (actionType === documentActionsMap.ADD) {
        handleRemoveFileItem({
          attachmentFileIndex,
        })

        return handleAddNewFile(attachment.file)
      }

      if (actionType === documentActionsMap.DELETE) {
        return handleDeleteFile(attachment)()
      }

      if (actionType === documentActionsMap.UPDATE) {
        if (!replacedAttachmentFileRef.current || !replacedAttachmentFileRef.current?.file) return

        return handleUpdateFile(attachment, replacedAttachmentFileRef.current.file)
      }
    },
    [attachments, handleAddNewFile, handleDeleteFile, handleRemoveFileItem, handleUpdateFile],
  )

  const handleRemoveNotLoadedFile = useCallback(
    (attachment: IAttachmentFileItem) => () => {
      const { actionType } = attachment.error || {}

      const attachmentFileIndex = getFileIndexById(attachments, attachment.id)

      if (actionType === documentActionsMap.ADD) {
        return handleRemoveFileItem({
          attachmentFileIndex,
        })
      }

      if (actionType === documentActionsMap.UPDATE) {
        replacedAttachmentFileRef.current = null
      }

      handleClearError(attachment, attachmentFileIndex)
    },
    [attachments, handleClearError, handleRemoveFileItem],
  )

  if (!attachments.length) {
    return (
      <FileItem
        contentNode={description}
        hasDocumentTemplateType={hasDocumentTemplateType}
        readOnly={isStaticDocument}
        dropzoneProps={baseDropzoneProps}
        onLoadFile={handleAddNewFile}
        onDropError={handleRejectedFile}
        onDownloadTemplate={handleDownloadDocumentTemplate}
      />
    )
  }

  return (
    <>
      {attachments.map((attachment) => {
        const { documentInfo, file, rejectedFile, error, isLoading, id } = attachment
        const { isEditable, isApproved, number, size, version, versionChangeState } =
          documentInfo || {}

        const isDepartmentResponseSigned =
          conditionToSignedMarkRender && isBoolean(isEditable) && !isEditable

        const hasCorrectVersionsLength = (versions?.length ?? 0) > 1

        const filteredVersionChangeState =
          isChangesMadeTab || hasCorrectVersionsLength ? versionChangeState : undefined

        const isDeletedVersionDocument = filteredVersionChangeState === 'DELETED'
        const isFixedVersionDocument = filteredVersionChangeState === 'FIXED'

        const showVersionChangeState =
          (isChangesMadeTab || versionState.versionNumber > 1) &&
          !!filteredVersionChangeState &&
          !isFixedVersionDocument &&
          !isDeletedVersionDocument

        return (
          <FileItem
            key={id}
            readOnly={isStaticDocument || !!documentInfo?.isApproved}
            contentNode={number ? `${description} ${number}` : description}
            hasDocumentTemplateType={hasDocumentTemplateType}
            isDownloadForbidden={
              isDeletedVersionDocument || isDownloadForbiddenAttachmentPermission
            }
            leadingAdditionProps={{
              statusProps: !isNullOrUndefined(isApproved)
                ? {
                    statusType: isApproved ? statusTypesMap.APPROVED : statusTypesMap.REJECTED,
                  }
                : undefined,
            }}
            trailingAdditionProps={{
              sizeClassName: cn({
                [styles['trailingNode__item-size--small']]:
                  isDepartmentResponseSigned || showVersionChangeState,
              }),
              trailingNode: <TrailingAdditionInTrailingNode version={version} />,
              additionalTrailingNode: isDeletedVersionDocument && (
                <Typography.Body variant="bodySMedium" color={'text-base-tertiary'}>
                  Документ был удален инвестором
                </Typography.Body>
              ),
              leadingNode: !isDeletedVersionDocument && (
                <LeadingAdditionInTrailingNode
                  readOnly={isStaticDocument && isNull(documentInfo?.isApproved)}
                  isDepartmentResponseSigned={isDepartmentResponseSigned}
                  versionChangeState={versionChangeState}
                  versionNumber={versionState.versionNumber}
                />
              ),
            }}
            fileState={{
              file: file,
              isDeletedFile: isDeletedVersionDocument,
              rejectedFile,
              fileSize: file?.size || rejectedFile?.file?.size || size || 0,
              isLoading,
              errorMessage: error?.message || null,
            }}
            downloadProps={{
              signCondition: isSignedDocument(attachment.documentInfo),
              onFileDownload: handleDownloadFile({ attachment }),
              downloadTooltipContent: (
                <DownloadDocumentTooltipContent
                  handleDownloadFile={handleDownloadFile({ attachment })}
                  handleDownloadFileWithStamps={handleDownloadFile({
                    attachment,
                    withSigned: true,
                  })}
                />
              ),
            }}
            signFileProps={{
              signFileTooltipContent: 'Добавить открепленную подпись',
              onFileDropSign: isShowSignButton ? handleDropFileSign(attachment) : undefined,
            }}
            entityProps={{
              dataTestId: `${documentType}.AttachmentFileItem-attachment`,
              onClick:
                !!error || isDeletedVersionDocument
                  ? undefined
                  : handleOpenDocumentViewSidebar(attachment),
            }}
            dropzoneProps={{
              ...baseDropzoneProps,
              onDrop: handleDropFile,
              onRejectDrop: handleRejectDropFile(attachment),
            }}
            onFileReload={handleReloadFile(attachment)}
            onFileReplace={!isStatementDocumentType ? handleReplaceFile(attachment) : undefined}
            onFileNotLoadedRemove={handleRemoveNotLoadedFile(attachment)}
            onFileEdit={isStatementDocumentType ? handleOpenStatementModal : undefined}
            onFileRemove={
              !isStatementDocumentType ? handleDeleteFileWithModal(attachment) : undefined
            }
          />
        )
      })}
    </>
  )
}

export default AttachmentFileItem
