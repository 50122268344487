import { generatePropertiesObjectForNotificationOfChangesInDetailsOfPartiesForm } from '@components/Forms/NotificationOfChangesInDetailsOfParties/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNotificationOfChangesInDetailsOfPartiesForm } from '@components/Forms/NotificationOfChangesInDetailsOfParties/adapters/RHF.adapter'

import { NotificationOfChangesInDetailsOfPartiesFormValues } from '../types'

const useNotificationOfChangesInDetailsOfPartiesAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfChangesInDetailsOfPartiesFormValues =>
    generateRHFObjectForNotificationOfChangesInDetailsOfPartiesForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNotificationOfChangesInDetailsOfPartiesForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNotificationOfChangesInDetailsOfPartiesAdapters }
