import React from 'react'
import { useFormContext } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { summaryReportOfMonitoringResultsBlockValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/const'
import SummaryReportOfMonitoringResultsSzpkChangeTerminateStep from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/6/SzpkChangeTerminateStep'
import { SummaryReportOfMonitoringResultsSzpkChangeTerminatePathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/6/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const Sixth = () => {
  const formInstance = useFormContext<SummaryReportOfMonitoringResultsFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const {
    getSubscribableControlProps,
    getAmountInputProps,
    getInputProps,
  } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
  })

  const { fields: szpkChangeTerminate } = useFieldArraySubscribableControl<
    SummaryReportOfMonitoringResultsFormValues,
    SummaryReportOfMonitoringResultsSzpkChangeTerminatePathName,
    'keyNameId'
  >({
    name: summaryReportOfMonitoringResultsBlockValues['6'].szpksChangeTerminateFederalReport,
    control: formInstance?.control,
    keyName: 'keyNameId',
    watcher: SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className="p-0">
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['6']
              .szpksChangeTerminateFederalReport,
          })}
        >
          <FlipperList list={szpkChangeTerminate}>
            {szpkChangeTerminate.map((szpkTerminate, index) => {
              const formName =
                `${summaryReportOfMonitoringResultsBlockValues['6'].szpksChangeTerminateFederalReport}.${index}` as const

              return (
                <SummaryReportOfMonitoringResultsSzpkChangeTerminateStep
                  key={szpkTerminate.id}
                  id={szpkTerminate.id}
                  formName={formName}
                  formInstance={formInstance}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        <Group
          disableBottomBorder
          title={
            'Соглашения, по которым выявлены нарушения, стороной которых является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Количество соглашений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalChangeTerminationSzpkCountFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalChangeTerminationSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                      suffix: '',
                      integersOnly: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капитальных вложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalChangeTerminationSzpkTotalCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalChangeTerminationSzpkTotalCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капиталовложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalChangeTerminationSzpkInvestorCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalChangeTerminationSzpkInvestorCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>

            {/* исправить */}
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Основание для изменения или расторжения соглашения'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalChangeTerminationSzpkCountFederalReport,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalChangeTerminationSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
        <Group
          disableBottomBorder
          title={'Соглашения, по которым выявлены основания для их изменения'}
        >
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Количество соглашений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalChangeSzpkCountFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalChangeSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                      suffix: '',
                      integersOnly: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капитальных вложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalChangeSzpkTotalCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalChangeSzpkTotalCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капиталовложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalChangeSzpkInvestorCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalChangeSzpkInvestorCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>

            {/* исправить */}
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Основание для изменения или расторжения соглашения'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalChangeSzpkCountFederalReport,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalChangeSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
        <Group
          disableBottomBorder
          title={'Соглашения, по которым выявлены основания для их расторжения'}
        >
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Количество соглашений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalTerminationSzpkCountFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalTerminationSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                      suffix: '',
                      integersOnly: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капитальных вложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalTerminationSzpkTotalCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalTerminationSzpkTotalCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капиталовложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalTerminationSzpkInvestorCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalTerminationSzpkInvestorCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>

            {/* исправить */}
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Основание для изменения или расторжения соглашения'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .federalTerminationSzpkCountFederalReport,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .federalTerminationSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
        <Group
          disableBottomBorder
          title={
            'Соглашения, по которым выявлены нарушения, стороной которых не является Российская Федерация'
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Количество соглашений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalChangeTerminationSzpkCountFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalChangeTerminationSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                      suffix: '',
                      integersOnly: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капитальных вложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalChangeTerminationSzpkTotalCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalChangeTerminationSzpkTotalCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капиталовложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalChangeTerminationSzpkInvestorCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalChangeTerminationSzpkInvestorCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>

            {/* исправить */}
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Основание для изменения или расторжения соглашения'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalChangeTerminationSzpkCountFederalReport,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalChangeTerminationSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
        <Group
          disableBottomBorder
          title={'Соглашения, по которым выявлены основания для их изменения'}
        >
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Количество соглашений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalChangeSzpkCountFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalChangeSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                      suffix: '',
                      integersOnly: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капитальных вложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalChangeSzpkTotalCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalChangeSzpkTotalCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капиталовложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalChangeSzpkInvestorCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalChangeSzpkInvestorCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>

            {/* исправить */}
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Основание для изменения или расторжения соглашения'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalChangeSzpkCountFederalReport,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalChangeSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
        <Group
          disableBottomBorder
          title={'Соглашения, по которым выявлены основания для их расторжения'}
        >
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Количество соглашений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalTerminationSzpkCountFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalTerminationSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                      suffix: '',
                      integersOnly: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капитальных вложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalTerminationSzpkTotalCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalTerminationSzpkTotalCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем капиталовложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalTerminationSzpkInvestorCapitalFederalReport,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalTerminationSzpkInvestorCapitalFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>

            {/* исправить */}
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Основание для изменения или расторжения соглашения'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: summaryReportOfMonitoringResultsBlockValues['6']
                    .regionalTerminationSzpkCountFederalReport,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: summaryReportOfMonitoringResultsBlockValues['6']
                      .regionalTerminationSzpkCountFederalReport,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default Sixth
