import React, { FC, memo } from 'react'

import NpaDocumentForm from '@components/DocumentFormComponents/Form/NpaForm'
import { NpaFormItemProps } from '@components/DocumentFormComponents/Form/NpaForm/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import NpaMunicipalForms from '@components/Forms/NpaMunicipalForm/Forms'
import { useNpaMunicipalManager } from '@components/Forms/NpaMunicipalForm/Manager'

const mapOfNpaMunicipalForms: NpaFormItemProps[] = [
  {
    id: 'headerInfo',
    node: <NpaMunicipalForms.HeaderInfo />,
  },
  {
    id: 'npa',
    node: <NpaMunicipalForms.NPA />,
  },
]

const NpaMunicipalStatement: FC<FormModalStatementProps> = (props) => {
  const {
    state: { formIsLoading },
  } = useNpaMunicipalManager()

  return (
    <NpaDocumentForm {...props} formIsLoading={formIsLoading} mapOfForms={mapOfNpaMunicipalForms} />
  )
}

export default memo(NpaMunicipalStatement)
