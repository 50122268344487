import { ReactNode, useEffect, useState } from 'react'

interface AsyncWrapperChildrenProps<T extends unknown[]> {
  isLoading: boolean
  error: unknown
  wrappedPromise: (...params: T) => Promise<unknown>
}

interface AsyncWrapperProps<T extends unknown[]> {
  children: ({ isLoading, error, wrappedPromise }: AsyncWrapperChildrenProps<T>) => ReactNode
  promise?: (...params: T) => Promise<unknown>
}

const AsyncWrapper = <T extends unknown[]>({ promise, children }: AsyncWrapperProps<T>) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<unknown | null>(null)

  const handleResolvePromise = async (...params: T) => {
    setIsLoading(true)

    try {
      await promise?.(...params)
    } catch (e) {
      setError(e)
      throw e
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    return () => {
      setIsLoading(false)
      setError(null)
    }
  }, [])

  return (
    <>
      {children({
        wrappedPromise: handleResolvePromise,
        isLoading,
        error: error,
      })}
    </>
  )
}

export default AsyncWrapper
