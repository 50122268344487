import { FC, memo, useEffect } from 'react'
import { PopupProps } from 'react-popup-manager'

import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import CreateStatementOld from '@components/Forms/CreateStatementOld'
import { mapOFMenu } from '@components/Forms/CreateStatementOld/const'
import LayoutWrapper from '@components/Forms/CreateStatementOld/Layout/Wrapper'
import { IViolation } from '@components/Forms/CreateStatementOld/types'
import Typography from '@components/NewDesign/Typography'
import { IDefaultStatementOldUrlParams } from '@context/APIContext/types'
import { Transition } from '@headlessui/react'
import cn from 'classnames'

import styles from './Layout.module.scss'

export type CreateStatementOldModalProps = IDefaultStatementOldUrlParams & {
  initialErrorsFromViolations?: IViolation[]
}

type CreateStatementLayoutProps = PopupProps & CreateStatementOldModalProps

const CreateStatementLayout: FC<CreateStatementLayoutProps> = ({
  isOpen,
  onClose,
  dfoId,
  projectId,
  documentSetId,
  initialErrorsFromViolations,
}) => {
  //При открытии заявления закрываем возможность скроллиться body.
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return (
    <Transition
      show={isOpen}
      enter={styles['layout__animate--enter']}
      enterFrom={styles['layout__animate--enterFrom']}
      enterTo={styles['layout__animate--enterTo']}
      leave={styles['layout__animate--leave']}
      leaveFrom={styles['layout__animate--leaveFrom']}
      leaveTo={styles['layout__animate--leaveTo']}
      className={cn(styles.layout, {
        [styles.layout__opened]: isOpen,
      })}
    >
      <CreateStatementOld.Manager
        documentSetId={documentSetId}
        projectId={projectId}
        dfoId={dfoId}
        initialErrorsFromViolations={initialErrorsFromViolations}
        onClose={onClose}
      >
        <FlatMenuManager initialCurrentAnchorId={mapOFMenu[0].id}>
          <LayoutWrapper className={styles.layout__wrapper}>
            <div className={styles.layout__container}>
              <div className={styles.layout__content}>
                <div>
                  <Typography.Headline variant={'headlineH1'} align={'center'} as={'h1'}>
                    Заявление на заключение СЗПК
                  </Typography.Headline>
                  <CreateStatementOld.StatementForm className={styles.layout__form} />
                </div>
                <div className={styles['layout__menu-wrapper']}>
                  <CreateStatementOld.Menu className={styles.layout__menu} />
                </div>
              </div>
            </div>
          </LayoutWrapper>
        </FlatMenuManager>
      </CreateStatementOld.Manager>
    </Transition>
  )
}

export default memo(CreateStatementLayout)
