import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { InclusionAgreementOfRelatedSZPKRegisterFormValues } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfInclusionAgreementOfRelatedSZPKRegisterMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Информация о проекте' },
]

const defaultInclusionAgreementOfRelatedSZPKRegisterFormValues: InclusionAgreementOfRelatedSZPKRegisterFormValues =
  {
    '1': {
      ...getDefaultInvestorFieldState(),
      investorInn: '',
      investorOgrn: '',
      investorAddress: '',
    },
    '2': {
      projectName: '',
    },
  }

const inclusionAgreementOfRelatedSZPKRegisterBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    investorFullName: '2.investorFullName',
    investorFullNameGenitive: '2.investorFullNameGenitive',
    investorHeaderName: '2.investorHeaderName',
    investorHeaderNameGenitive: '2.investorHeaderNameGenitive',
    investorHeaderPosition: '2.investorHeaderPosition',
    investorHeaderPositionGenitive: '2.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '2.investorHeaderReasonGenitive',
    investorInn: '2.investorInn',
    investorOgrn: '2.investorOgrn',
    investorAddress: '2.investorAddress',
    projectName: '2.projectName',
  },
  '3': {
    extraRejectReason: '3.extraRejectReason',
    ruleLinkExtraReject: '3.ruleLinkExtraReject',
  },
} as const

export {
  defaultInclusionAgreementOfRelatedSZPKRegisterFormValues,
  inclusionAgreementOfRelatedSZPKRegisterBlockValues,
  mapOfInclusionAgreementOfRelatedSZPKRegisterMenu,
}
