import React, { FC, memo } from 'react'
import Dropzone, { DropzoneProps } from 'react-dropzone'

import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './FolderDropzone.module.scss'

interface FolderDropzoneProps extends DropzoneProps {
  dataTestId?: string
  inputDataTestId?: string
}

const FolderDropzone: FC<FolderDropzoneProps> = ({
  dataTestId,
  inputDataTestId,
  ...dropzoneRest
}) => {
  return (
    <Dropzone {...dropzoneRest}>
      {({ getInputProps, getRootProps, isDragActive }) => {
        return (
          <div
            {...getRootProps({
              'data-testid': dataTestId,
              className: cn(styles.folderDropzone, {
                [styles['folderDropzone--active']]: isDragActive,
              }),
            })}
          >
            <div className={styles.folderDropzone__info}>
              <Typography.Body variant={'bodyXLMedium'}>Добавить документ в папку</Typography.Body>
              <Typography.Body
                className={styles['folderDropzone__info-subtitle']}
                variant={'bodyMRegular'}
              >
                Нажмите или перетащите сюда файл
              </Typography.Body>
            </div>
            <input {...getInputProps()} data-testid={inputDataTestId} />
          </div>
        )
      }}
    </Dropzone>
  )
}

export default memo(FolderDropzone)
