import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNoticeOfProjectRefuseConcludeAAForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
      documentReceivers: {
        propertyId: objectFromPropertyState.documentReceiversDraft?.propertyId,
        type: objectFromPropertyState.documentReceiversDraft?.type,
        lastUpdateDt: objectFromPropertyState.documentReceiversDraft?.lastUpdateDt,
      },
    },
    '2':PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: {
        propertyId: objectFromPropertyState?.investorFullNameGenitiveDraft?.propertyId,
        type: objectFromPropertyState?.investorFullNameGenitiveDraft?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameGenitiveDraft?.lastUpdateDt,
      },
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
    },
    '4': {
      ruleLinkExtraReject: {
        propertyId: objectFromPropertyState.ruleLinkExtraRejectDraft?.propertyId,
        type: objectFromPropertyState.ruleLinkExtraRejectDraft?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkExtraRejectDraft?.lastUpdateDt,
      },
      extraRejectReason: {
        propertyId: objectFromPropertyState.extraRejectReasonDraft?.propertyId,
        type: objectFromPropertyState.extraRejectReasonDraft?.type,
        lastUpdateDt: objectFromPropertyState.extraRejectReasonDraft?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNoticeOfProjectRefuseConcludeAAForm }
