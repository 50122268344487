import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import InclusionAgreementOfRelatedSZPKRegister from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm'
import {
  defaultInclusionAgreementOfRelatedSZPKRegisterFormValues,
  mapOfInclusionAgreementOfRelatedSZPKRegisterMenu,
} from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/const'
import { InclusionAgreementOfRelatedSZPKRegisterFormValues } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/types'
import { InclusionAgreementOfRelatedSZPKRegisterFieldsControlUpdateWatcher } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import InclusionAgreementOfRelatedSZPKRegisterLayoutWrapper from './Wrapper'

const InclusionAgreementOfRelatedSZPKRegisterLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<InclusionAgreementOfRelatedSZPKRegisterFormValues>({
    defaultValues: defaultInclusionAgreementOfRelatedSZPKRegisterFormValues,
  })

  const handleOnClose = () => {
    InclusionAgreementOfRelatedSZPKRegisterFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <InclusionAgreementOfRelatedSZPKRegister.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfInclusionAgreementOfRelatedSZPKRegisterMenu[0].id}
          >
            <InclusionAgreementOfRelatedSZPKRegisterLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Заявление о включении дополнительного соглашения о включении в СЗПК условий
                  связанного договора в реестр СЗПК
                </Typography.Headline>
                <InclusionAgreementOfRelatedSZPKRegister.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--sm'])}
              >
                <InclusionAgreementOfRelatedSZPKRegister.Menu className={styles.layout__menu} />
              </div>
            </InclusionAgreementOfRelatedSZPKRegisterLayoutWrapper>
          </FlatMenuManager>
        </InclusionAgreementOfRelatedSZPKRegister.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(InclusionAgreementOfRelatedSZPKRegisterLayout)
