import { Control } from 'react-hook-form'

import { mapOfCompensationsTypeByReimbursementPeriod } from '@components/DocumentFormComponents/const'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { StatementOldFormValues } from '@components/Forms/CreateStatementOld/types'
import { getAmountValueFromStrWithoutRound } from '@components/NewDesign/AmountInput/utils'
import { objOfDateFormats } from '@constants/dateFormats'
import { encodingsConverter } from '@helpers/encodings/converter'
import DayjsService from '@services/Dayjs/Dayjs.service'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

const { transformValueFromRHF, validateValueBeforeSend } = DocumentFormHelpers

class StatementDataConverter {
  private _outputData: StatementOldFormValues
  private readonly _control: Control<StatementOldFormValues>

  constructor(control: Control<StatementOldFormValues>, RHFObjectOfValues: StatementOldFormValues) {
    this._outputData = RHFObjectOfValues
    this._control = control
  }

  private _prepareDate() {
    const startDate = this._outputData.generalInfo.startDate
      ? DayjsService.dayjs(
          this._outputData.generalInfo.startDate,
          objOfDateFormats.defaultFormat,
        ).format(objOfDateFormats.defaultNativeDateFormat)
      : null
    const projectEndDate = this._outputData.generalInfo.projectEndDate
      ? DayjsService.dayjs(
          this._outputData.generalInfo.projectEndDate,
          objOfDateFormats.defaultFormat,
        ).format(objOfDateFormats.defaultNativeDateFormat)
      : null

    const decisionDate = this._outputData.participantsAndInvestments.decisionDate
      ? DayjsService.dayjs(
          this._outputData.participantsAndInvestments.decisionDate,
          objOfDateFormats.defaultFormat,
        ).format(objOfDateFormats.defaultNativeDateFormat)
      : null

    const endDateProject = this._outputData.participantsAndInvestments.endDate
      ? DayjsService.dayjs(
          this._outputData.participantsAndInvestments.endDate,
          objOfDateFormats.defaultFormat,
        ).format(objOfDateFormats.defaultNativeDateFormat)
      : null

    const szpkEndDate = this._outputData.generalInfo.szpkEndDate
      ? DayjsService.dayjs(
          this._outputData.generalInfo.szpkEndDate,
          objOfDateFormats.defaultFormat,
        ).format(objOfDateFormats.defaultNativeDateFormat)
      : null

    return {
      generalInfoDates: {
        startDate: transformValueFromRHF(startDate),
        projectEndDate: transformValueFromRHF(projectEndDate),
        szpkEndDate: transformValueFromRHF(szpkEndDate),
      },
      participantsAndInvestmentsDates: {
        decisionDate: transformValueFromRHF(decisionDate),
        endDateProject: transformValueFromRHF(endDateProject),
      },
    }
  }

  private _prepareStages() {
    return this._outputData.stages.map((item, index) => {
      return {
        name: validateValueBeforeSend(
          this._control,
          transformValueFromRHF(item.name),
          `stepProjectArray.${index}.name`,
        ),
        startDate: validateValueBeforeSend(
          this._control,
          item.startDate != '' && item.startDate != null
            ? DayjsService.dayjs(item.startDate, objOfDateFormats.defaultFormat).format(
                objOfDateFormats.defaultNativeDateFormat,
              )
            : null,
          `stepProjectArray.${index}.startDate`,
        ),
        endDate: validateValueBeforeSend(
          this._control,
          item.endDate != '' && item.endDate != null
            ? DayjsService.dayjs(item.endDate, objOfDateFormats.defaultFormat).format(
                objOfDateFormats.defaultNativeDateFormat,
              )
            : null,
          `stepProjectArray.${index}.endDate`,
        ),
      }
    })
  }

  private _prepareCompensations() {
    return this._outputData.compensations.map((item, index: number) => {
      const preparedValue = getAmountValueFromStrWithoutRound(item.value || '')

      const readyCompensationType = item.type ? item.type : transformValueFromRHF(item.type)

      const readyCompensationFormat = item.format ? item.format : transformValueFromRHF(item.format)

      const readyReimbursementPeriodFormat = item.type
        ? mapOfCompensationsTypeByReimbursementPeriod[item.type]
        : null

      return {
        type: validateValueBeforeSend(
          this._control,
          readyCompensationType,
          `expensesCompensationArray.${index}.type`,
        ),
        format: validateValueBeforeSend(
          this._control,
          readyCompensationFormat,
          `expensesCompensationArray.${index}.format`,
        ),
        value: validateValueBeforeSend(
          this._control,
          preparedValue,
          `expensesCompensationArray.${index}.value`,
        ),
        reimbursementPeriod: readyReimbursementPeriodFormat,
      }
    })
  }

  private _preparePlannedTaxes() {
    return this._outputData.participantsAndInvestments.taxes.map((item) => {
      return {
        taxYear: DayjsService.dayjs(item.taxYear).year().toString(),
        plannedTax: getAmountValueFromStrWithoutRound(item.plannedTax || '', true),
      }
    })
  }

  private _prepareInvestorGeneralInfo() {
    return {
      name: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.name),
        'investorGeneralInfo.name',
      ),
      fullName: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.fullName),
        'investorGeneralInfo.fullName',
      ),
      inn: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.inn),
        'investorGeneralInfo.inn',
      ),
      ogrn: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.ogrn),
        'investorGeneralInfo.ogrn',
      ),
      kpp: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.kpp),
        'investorGeneralInfo.kpp',
      ),
      head: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.head),
        'investorGeneralInfo.head',
      ),
      headGenitive: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.headGenitive),
        'investorGeneralInfo.headGenitive',
      ),
      position: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.position),
        'investorGeneralInfo.position',
      ),
      positionGenitive: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.positionGenitive),
        'investorGeneralInfo.position',
      ),
      reason: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.reason),
        'investorGeneralInfo.reason',
      ),
      reasonGenitive: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.reasonGenitive),
        'investorGeneralInfo.reasonGenitive',
      ),
      okpo: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.okpo),
        'investorGeneralInfo.okpo',
      ),
      okved: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorGeneralInfo.okved?.code),
        'investorGeneralInfo.okved',
      ),
      capital: validateValueBeforeSend(
        this._control,
        !!this._outputData.investorGeneralInfo.capital
          ? getAmountValueFromStrWithoutRound(this._outputData.investorGeneralInfo.capital)
          : null,
        'investorGeneralInfo.capital',
      ),
      isProjectCompany:
        this._outputData.investorGeneralInfo.isProjectCompany !== null
          ? this._outputData.investorGeneralInfo.isProjectCompany
          : false,
      isVed:
        this._outputData.investorGeneralInfo.isVed !== null
          ? this._outputData.investorGeneralInfo.isVed
          : false,
    }
  }

  private _prepareInvestorAddress() {
    return {
      subject: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.subject ?? ''),
        'investorAddress.subject',
      ),
      district: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.district),
        'investorAddress.district',
      ),
      area: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.area),
        'investorAddress.area',
      ),
      city: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.city),
        'investorAddress.city',
      ),
      planningStructureElement: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.planningStructureElement),
        'investorAddress.planningStructureElement',
      ),
      roadNetworkElement: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.roadNetworkElement),
        'investorAddress.roadNetworkElement',
      ),
      house: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.house),
        'investorAddress.house',
      ),
      room: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.room),
        'investorAddress.room',
      ),
      roomPart: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.investorAddress.roomPart),
        'investorAddress.roomPart',
      ),
    }
  }

  private _prepareGeneralInfo() {
    const { generalInfoDates } = this._prepareDate()

    const readyActivityKind = this._outputData.generalInfo.activityKind
      ? this._outputData.generalInfo.activityKind
      : transformValueFromRHF(this._outputData.generalInfo.activityKind)

    return {
      activityKind: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(readyActivityKind),
        'generalInfo.activityKind',
      ),

      ruleParagraph: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.generalInfo.ruleParagraph),
        'generalInfo.ruleParagraph',
      ),
      headPhone: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.generalInfo.headPhone),
        'generalInfo.headPhone',
      ),
      headAdditionalPhone: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.generalInfo.headAdditionalPhone),
        'generalInfo.headAdditionalPhone',
      ),
      headEmail: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(
          this._outputData.generalInfo.headEmail?.map((option) =>
            encodingsConverter.ToUnicode(option.value),
          ),
        ),
        'generalInfo.headEmail',
      ),
      characteristic: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.generalInfo.characteristic),
        'generalInfo.characteristic',
      ),

      activityKindOkved: validateValueBeforeSend(
        this._control,
        transformValueFromRHF(this._outputData.generalInfo.activityKindOkved?.code),
        'generalInfo.activityKind',
      ),
      startDate: validateValueBeforeSend(
        this._control,
        generalInfoDates.startDate,
        'generalInfo.startDate',
      ),
      projectEndDate: validateValueBeforeSend(
        this._control,
        generalInfoDates.projectEndDate,
        'generalInfo.projectEndDate',
      ),
      szpkEndDate: validateValueBeforeSend(
        this._control,
        generalInfoDates.szpkEndDate,
        'generalInfo.szpkEndDate',
      ),
      newWorkplaces: !!this._outputData.generalInfo.newWorkplaces
        ? validateValueBeforeSend(
            this._control,
            Number(this._outputData.generalInfo.newWorkplaces.replace(/\s/g, '')),
            'generalInfo.newWorkplaces',
          )
        : null,
      newBuildingWorkplaces: !!this._outputData.generalInfo.newBuildingWorkplaces
        ? validateValueBeforeSend(
            this._control,
            Number(this._outputData.generalInfo.newBuildingWorkplaces.replace(/\s/g, '')),
            'generalInfo.newBuildingWorkplaces',
          )
        : null,
    }
  }

  private _prepareParticipantsAndInvestments() {
    const { participantsAndInvestmentsDates } = this._prepareDate()
    const plannedTaxes = this._preparePlannedTaxes()

    return {
      decisionDate: validateValueBeforeSend(
        this._control,
        this._outputData.participantsAndInvestments.decisionDate != ''
          ? participantsAndInvestmentsDates.decisionDate
          : null,
        'participantsAndInvestments.decisionDate',
      ),
      endDate: validateValueBeforeSend(
        this._control,
        this._outputData.participantsAndInvestments.endDate != ''
          ? participantsAndInvestmentsDates.endDateProject
          : null,
        'participantsAndInvestments.endDate',
      ),
      investorCapital: !!this._outputData.participantsAndInvestments.investorCapital
        ? validateValueBeforeSend(
            this._control,
            getAmountValueFromStrWithoutRound(
              this._outputData.participantsAndInvestments.investorCapital,
            ),
            'participantsAndInvestments.investorCapital',
          )
        : null,
      investorCapitalVAT: !!this._outputData.participantsAndInvestments.investorCapitalVAT
        ? validateValueBeforeSend(
            this._control,
            getAmountValueFromStrWithoutRound(
              this._outputData.participantsAndInvestments.investorCapitalVAT,
            ),
            'participantsAndInvestments.investorCapitalVAT',
          )
        : null,
      totalCapital: !!this._outputData.participantsAndInvestments.totalCapital
        ? validateValueBeforeSend(
            this._control,
            getAmountValueFromStrWithoutRound(
              this._outputData.participantsAndInvestments.totalCapital,
            ),
            'participantsAndInvestments.totalCapital',
          )
        : null,
      totalCapitalVAT: !!this._outputData.participantsAndInvestments.totalCapitalVAT
        ? validateValueBeforeSend(
            this._control,
            getAmountValueFromStrWithoutRound(
              this._outputData.participantsAndInvestments.totalCapitalVAT,
            ),
            'participantsAndInvestments.totalCapitalVAT',
          )
        : null,
      taxes: plannedTaxes,
    }
  }

  fromRHFToApi() {
    try {
      const stages = this._prepareStages()
      const compensations = this._prepareCompensations()
      const investorGeneralInfo = this._prepareInvestorGeneralInfo()
      const investorAddress = this._prepareInvestorAddress()
      const generalInfo = this._prepareGeneralInfo()
      const participantsAndInvestments = this._prepareParticipantsAndInvestments()

      return {
        investorGeneralInfo,
        stages,
        compensations,
        investorAddress,
        generalInfo,
        participantsAndInvestments,
      }
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'StatementDataConverter',
          componentType: 'fromRHFToApi',
          moduleName: 'CreateStatementModalManager',
        },
      })(error)

      throw error
    }
  }
}

export default StatementDataConverter
