import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import FormController from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/AdditionalForms/MainTemplate/FormController'
import { FifthCapitalStepsArrayPathName } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/types'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { AAVolumeOfCapitalInvestmentsFieldCollapseControlUpdateWatcher } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'

import styles from './MainTemplate.module.scss'

interface MainTemplateProps {
  id: string
  name: FifthCapitalStepsArrayPathName
  initialAccordionState?: boolean
}

const MainTemplate: FC<MainTemplateProps> = ({ name, id, initialAccordionState }) => {
  const formInstance = useFormContext<AAVolumeOfCapitalInvestmentsFormValues>()

  const { anchorId, stepDescription } = useWatch({
    control: formInstance.control,
    name,
  })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    initialExpanded: initialAccordionState,
    name,
    watcher: AAVolumeOfCapitalInvestmentsFieldCollapseControlUpdateWatcher,
  })

  return (
    <Accordion
      unmountOnCollapse={false}
      id={id}
      anchorId={anchorId}
      title={stepDescription}
      isExpanded={isExpanded}
      initialAccordionState={initialAccordionState}
      onToggle={onToggleCollapse}
    >
      <Container className={styles.mainTemplate}>
        <Row>
          <Col xs={12}>
            <FormController name={name} />
          </Col>
        </Row>
      </Container>
    </Accordion>
  )
}

export default MainTemplate
