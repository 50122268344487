const attachmentsFormNames = {
  fileItems: 'fileItems',
  folderItems: 'folderItems',
  multipleItems: 'multipleItems',
} as const

const downloadSignedExtension = ['pdf', 'docx', 'doc', 'txt', 'odt', 'csv', 'rtf']

const statusTypesMap = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const

const statusTextsMap = {
  APPROVED: 'Документ принят согласующим лицом',
  REJECTED: 'Документ не принят согласующим лицом',
}

export { statusTextsMap, statusTypesMap }

export { attachmentsFormNames, downloadSignedExtension }
