import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ObjectAreasList from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectAreasList'
import ObjectRegionsList from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectRegionsList'
import { eObjectProjectSZPKValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/validation'
import {
  SeventhConstructionObjectsArrayPathName,
  SeventhConstructionSubObjectsArrayPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface ObjectFormItemProps {
  isShowObjectItemContent: boolean
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: SeventhConstructionObjectsArrayPathName | SeventhConstructionSubObjectsArrayPathName
  isParentObject?: boolean
}

const ObjectFormItem: FC<ObjectFormItemProps> = ({
  isParentObject = false,
  isShowObjectItemContent,
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getSwitchProps,
    getInputProps,
    getTextareaProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const isCompensationRenderCondition = useWatch({
    name: `${formName}.objectIsInfrastructure`,
  })

  return (
    <Container className={'p-0'}>
      <Col xs={12}>
        {!isShowObjectItemContent && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectName`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Наименование'}
                  controllerProps={getControllerProps({
                    name: `${formName}.objectName`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <CollapseWrapper
          defaultExpanded={isShowObjectItemContent}
          isExpanded={isShowObjectItemContent}
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectName`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.objectName`,
                      rules: eObjectProjectSZPKValidationMap.objectName,
                      textareaProps: {
                        dataTestId: 'objectName',
                        label: 'Наименование',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={
                              'Информация о характеристиках объекта вносится в соответствии с РНС, ГПЗУ, проектом планировки территории или иным документом'
                            }
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectName`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <ObjectRegionsList
              blockViewIsValidating={blockViewIsValidating}
              editMode={editMode}
              formInstance={formInstance}
              formName={formName}
            />
            {isParentObject && (
              <ObjectAreasList
                editMode={editMode}
                blockViewIsValidating={blockViewIsValidating}
                formInstance={formInstance}
                formName={formName as SeventhConstructionObjectsArrayPathName}
              />
            )}
            <Row>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectConstructionKind`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.objectConstructionKind`,
                    rules: eObjectProjectSZPKValidationMap.objectConstructionKind,

                    textareaProps: {
                      dataTestId: 'objectConstructionKind',
                      label: 'Вид объекта капитального строительства',
                      caption: 'например: здание, сооружение, строение',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.objectConstructionKind`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.objectConstructionKind`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Row>
            <Row>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectPurpose`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.objectPurpose`,
                    rules: eObjectProjectSZPKValidationMap.objectPurpose,
                    textareaProps: {
                      dataTestId: 'objectPurpose',
                      label: 'Назначение объекта',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.objectPurpose`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.objectPurpose`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectConstructionSquare`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectConstructionSquare`,
                      rules: eObjectProjectSZPKValidationMap.objectConstructionSquare,
                      inputProps: {
                        dataTestId: 'objectConstructionSquare',
                        label: 'Площадь застройки, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectConstructionSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectConstructionSquare`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectSquare`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectSquare`,
                      rules: eObjectProjectSZPKValidationMap.objectSquare,

                      inputProps: {
                        dataTestId: 'objectSquare',
                        label: 'Площадь, кв.м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectSquare`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectNonLivingAreaSquare`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectNonLivingAreaSquare`,
                      rules: eObjectProjectSZPKValidationMap.objectNonLivingAreaSquare,

                      inputProps: {
                        dataTestId: 'objectNonLivingAreaSquare',
                        label: 'Площадь нежилых помещений, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectLivingAreaSquare`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectLivingAreaSquare`,
                      rules: eObjectProjectSZPKValidationMap.objectLivingAreaSquare,

                      inputProps: {
                        dataTestId: 'objectLivingAreaSquare',
                        label: 'Площадь жилых помещений, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectFloor`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.objectFloor`,
                      rules: eObjectProjectSZPKValidationMap.objectFloor,
                      inputProps: {
                        dataTestId: 'objectFloor',
                        label: 'Количество этажей',
                        caption: 'если применимо',
                        suffix: '',
                        maxLength: 10,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectFloor`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectFloor`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectUndergroundFloor`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.objectUndergroundFloor`,
                      rules: eObjectProjectSZPKValidationMap.objectUndergroundFloor,
                      inputProps: {
                        dataTestId: 'objectUndergroundFloor',
                        label: 'в том числе количество подземных',
                        caption: 'если применимо',
                        suffix: '',
                        maxLength: 10,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectUndergroundFloor`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectUndergroundFloor`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectHeight`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectHeight`,
                      rules: eObjectProjectSZPKValidationMap.objectHeight,

                      inputProps: {
                        dataTestId: 'objectHeight',
                        label: 'Высота, м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectHeight`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectHeight`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectDetails`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.objectDetails`,
                      rules: eObjectProjectSZPKValidationMap.objectDetails,

                      textareaProps: {
                        dataTestId: 'objectDetails',
                        label: 'Иные характеристики',
                        caption: 'если применимо',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectDetails`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectDetails`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Stack direction={'vertical'} gap={2}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.objectIsInfrastructure`,
                    })}
                  >
                    <ControlledSwitch
                      {...getSwitchProps({
                        name: `${formName}.objectIsInfrastructure`,
                        switchProps: {
                          wrapperClassName: styles.form__switch,
                          label: 'Является объектом инфраструктуры',
                        },
                        onChange: () => handleChangeValue?.(`${formName}.objectIsInfrastructure`),
                      })}
                    />
                  </SubscribableControl>
                  <CollapseWrapper
                    defaultExpanded={isCompensationRenderCondition}
                    isExpanded={isCompensationRenderCondition}
                  >
                    <Stack direction={'vertical'} gap={3}>
                      <Col xs={12}>
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${formName}.objectInfrastructureType`,
                          })}
                        >
                          {({ overrideProps }) => {
                            const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                              ? overrideProps.fetcherOptions
                              : undefined

                            return (
                              <ControlledFormSingleSelect
                                {...getSingleSelectProps({
                                  fetcherProps,
                                  optionsAdapter: (item) => ({
                                    displayValue: item.name,
                                    value: item.id,
                                  }),
                                  controllerProps: {
                                    name: `${formName}.objectInfrastructureType`,
                                    rules: eObjectProjectSZPKValidationMap.objectInfrastructureType,
                                  },
                                  selectProps: {
                                    inputProps: {
                                      dataTestId: 'objectInfrastructureType',
                                      label: 'Тип инфраструктуры',
                                    },
                                    onChangeFormValue: () =>
                                      setTimeout(() => {
                                        handleChangeValue?.(`${formName}.objectInfrastructureType`)
                                      }),
                                  },
                                })}
                              />
                            )
                          }}
                        </SubscribableControl>
                      </Col>
                    </Stack>
                  </CollapseWrapper>
                </Stack>
              </Col>
            </Row>
          </Stack>
        </CollapseWrapper>
      </Col>
    </Container>
  )
}

export default ObjectFormItem
