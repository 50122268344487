import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import styles from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationMonitoringChecker.module.scss'
import { compensationExpensesCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationsExpensesCheckerGroup/validation'
import {
  CertificateOnMonitoringResultsCompensationsExpensesCheckerPathName,
  CertificateOnMonitoringResultsCompensationsMonitoringCheckerArrayPathName,
} from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CertificateOnMonitoringResultsCompensationsExpensesCheckerGroupProps {
  name: CertificateOnMonitoringResultsCompensationsMonitoringCheckerArrayPathName
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
}

const CertificateOnMonitoringResultsCompensationsExpensesCheckerGroup: FC<
  CertificateOnMonitoringResultsCompensationsExpensesCheckerGroupProps
> = ({ name, formInstance }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { fields: compensationsExpensesChecker } = useFieldArraySubscribableControl<
    CertificateOnMonitoringResultsFormValues,
    CertificateOnMonitoringResultsCompensationsExpensesCheckerPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.compensationsExpensesChecker`,
    keyName: 'keyNameId',
    watcher: CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps, getAmountInputProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
    })

  return (
    <Group
      disableBottomBorder
      title={
        'Документально подтвержденные и фактически понесенные расходы, возникшие вследствие нарушения условий стабилизационной оговорки'
      }
    >
      <Stack direction={'vertical'} gap={3}>
        <ContainerWithBorder>
          {!compensationsExpensesChecker.length && (
            <EmptyDataText
              wrapperClassName={styles.compensationMonitoringChecker__emptyDataText}
              text={'Нет данных для заполнения'}
            />
          )}
          <Stack direction={'vertical'} gap={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.compensationsExpensesChecker`,
              })}
            >
              <FlipperList list={compensationsExpensesChecker}>
                {compensationsExpensesChecker.map((compensationExpense, index) => {
                  const formName = `${name}.compensationsExpensesChecker.${index}` as const

                  return (
                    <Group
                      disableBottomBorder
                      key={compensationExpense.id}
                      title={`Направление расхода №${index + 1}`}
                    >
                      <Row>
                        <Col xs={12}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${formName}.expenseName`,
                            })}
                          >
                            <ControlledTextarea
                              {...getTextareaProps({
                                name: `${formName}.expenseName`,
                                rules:
                                  compensationExpensesCertificateOnMonitoringResultsValidationMap.expenseName,
                                textareaProps: {
                                  disabled: true,
                                  label: 'Направление расхода',
                                },
                              })}
                            />
                          </SubscribableControl>
                        </Col>
                      </Row>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Затраты по расходу'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${formName}.expenseValue`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${formName}.expenseValue`,
                              inputProps: {
                                disabled: true,
                                caption: 'значение, введенное инвестором',
                              },
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                      <FieldView.RowWithBottomBorder
                        disableBottomDefaultStyles
                        firstColumnSize={6}
                        secondColumnSize={6}
                        title={'Затраты по расходу'}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${formName}.expenseValueChecker`,
                          })}
                        >
                          <ControlledAmountInput
                            {...getAmountInputProps({
                              name: `${formName}.expenseValueChecker`,
                              rules:
                                compensationExpensesCertificateOnMonitoringResultsValidationMap.expenseValueChecker,
                              onBlur: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${formName}.expenseValueChecker`)
                                }, 0),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(`${formName}.expenseValueChecker`)
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </FieldView.RowWithBottomBorder>
                    </Group>
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Stack>
        </ContainerWithBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Сумма затрат по всем направлениям расходов'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.generalCompensationsExpenses`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${name}.generalCompensationsExpenses`,
                inputProps: {
                  disabled: true,
                  caption: 'рассчитывается на основе данных, введенных инвестором',
                },
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Сумма затрат по всем направлениям расходов'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.generalCompensationsExpensesChecker`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${name}.generalCompensationsExpensesChecker`,
                inputProps: {
                  disabled: true,
                  caption: 'рассчитывается на основе данных, введенных уполномоченной организацией',
                },
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </Group>
  )
}

export default CertificateOnMonitoringResultsCompensationsExpensesCheckerGroup
