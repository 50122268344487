export const fileAlignmnet = {
  left: 'left',
  center: 'center',
  right: 'right',
} as const

export const fileItemReducerActionMap = {
  SETUP_LOADING: 'SETUP_LOADING',
  SETUP_FILE: 'SETUP_FILE',
  RESET_FILE: 'RESET_FILE',
  SETUP_ALIGNMENT: 'SETUP_ALIGNMENT',
  SETUP_ERROR: 'SETUP_ERROR',
} as const
