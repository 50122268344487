import { useCallback, useMemo, useState } from 'react'

import { defaultIntersectionObserverOptions } from '@hooks/new/observers/constants'

import { useIntersectionObserver } from './useIntersectionObserver'

export interface UseIntersectionProps extends IntersectionObserverInit {
  freezeOnceVisible?: boolean
}

const useIntersectionObserverEntry = (
  elementRef: HTMLElement | null,
  observerOptionsFromProps: UseIntersectionProps,
): IntersectionObserverEntry | undefined => {
  const {
    threshold = defaultIntersectionObserverOptions.threshold,
    root = defaultIntersectionObserverOptions.root,
    rootMargin = defaultIntersectionObserverOptions.rootMargin,
    freezeOnceVisible = false,
  } = observerOptionsFromProps || {}

  const observerOptions = useMemo(() => {
    return {
      threshold,
      root,
      rootMargin,
    }
  }, [threshold, root, rootMargin])

  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const frozen = entry?.isIntersecting && freezeOnceVisible

  const updateEntry = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      setEntry(entry)
    },
    [setEntry],
  )

  useIntersectionObserver({
    elementsToObserve: elementRef,
    intersectionListener: updateEntry,
    observerOptions,
    conditionToSkip: frozen,
  })

  return entry
}

export { useIntersectionObserverEntry }
