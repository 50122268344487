import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import FormError from '@components/DocumentFormComponents/FormError'
import styles from '@components/DocumentFormComponents/FormStyles/NpaHeadInfo.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { npaMunicipalFormBlockValues } from '@components/Forms/NpaMunicipalForm/const'
import { useNpaMunicipalManager } from '@components/Forms/NpaMunicipalForm/Manager'
import { NpaMunicipalFormValues } from '@components/Forms/NpaMunicipalForm/types'
import { NpaMunicipalFieldsControlUpdateWatcher } from '@components/Forms/NpaMunicipalForm/watcher'
import Button from '@components/NewDesign/Button'
import useAuthorizedPersonModal from '@components/NewDesignedModals/AddAuthorizedPersonModal/manager'
import Container from '@components/ReactBootstrap/Container'
import { isUndefined } from '@helpers/checkTypes'
import PersonAddIcon from '@icons/PersonAddIcon.svg'

const HeaderInfo = () => {
  const formInstance = useFormContext<NpaMunicipalFormValues>()

  const {
    state: { blockViewIsValidating },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useNpaMunicipalManager()

  const { handleOpenAddAuthorizedPersonModal } = useAuthorizedPersonModal()

  const { getSubscribableControlProps } = useFormComponentPresets({
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NpaMunicipalFieldsControlUpdateWatcher,
  })

  const headerInfoErrorRenderCondition = !!formInstance.getFieldState('headerInfo')?.error
  const municipalitiesError = formInstance.getFieldState(
    npaMunicipalFormBlockValues.additionalFields.municipalities,
  )?.error

  const currentControlContainerColor = !headerInfoErrorRenderCondition ? 'primary' : 'negative'
  const currentControlIcon = !headerInfoErrorRenderCondition ? PersonAddIcon : undefined
  const currentIconType = !headerInfoErrorRenderCondition ? '' : 'error'

  const handleOpenModal = () => {
    const isFederalSZPK = formInstance.getValues(
      npaMunicipalFormBlockValues.additionalFields.isFederal,
    )

    handleOpenAddAuthorizedPersonModal({
      blockViewIsValidating,
      isFederalSZPK,
      formInstance,
      subscribableControl,
      name: 'headerInfo',
      watcher: NpaMunicipalFieldsControlUpdateWatcher,
      onChangeValue: handleChangeValue,
    })
  }

  return (
    <Container className={'p-0'}>
      <SubscribableControl
        {...getSubscribableControlProps({
          path: npaMunicipalFormBlockValues.additionalFields.municipalities,
        })}
      >
        {!isUndefined(municipalitiesError) && (
          <FormError rowClassName={styles.npaHeadInfo__error}>
            {municipalitiesError.message}
          </FormError>
        )}
      </SubscribableControl>
      <SubscribableControl
        {...getSubscribableControlProps({
          path: npaMunicipalFormBlockValues.headerInfo.investorHeaderName,
        })}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: npaMunicipalFormBlockValues.headerInfo.municipalityHeaderName,
          })}
        >
          <ControlContainer
            color={currentControlContainerColor}
            title={'Укажите уполномоченное лицо организации'}
            additionalMainContent={
              headerInfoErrorRenderCondition && (
                <ControlContainerSubtitle>не заполнены обязательные поля</ControlContainerSubtitle>
              )
            }
            leadingNodeContent={
              <ControlContainerStatusIcon
                controlIcon={currentControlIcon}
                iconType={currentIconType}
              />
            }
            trailingNodeContent={
              <Button
                fixWidth
                view="tined"
                color={headerInfoErrorRenderCondition ? 'negative' : 'default'}
                onClick={handleOpenModal}
              >
                Выбрать
              </Button>
            }
          />
        </SubscribableControl>
      </SubscribableControl>
    </Container>
  )
}

export default HeaderInfo
