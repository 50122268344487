import React, { type FocusEvent, FC, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { AttachmentFileItemService } from '@components/Attachments/Attachment/FileItem/AttachmentFileItem/helpers'
import { statusTypesMap } from '@components/Attachments/constants'
import { useFileContext } from '@components/Attachments/FileProvider'
import { useWebFormAPIContext } from '@components/Attachments/WebForm/APIProvider'
import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import { DownloadProps } from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DownloadButton'
import { HandleDownloadWebFormFile } from '@components/Attachments/WebForm/WithDocumentController/types'
import WebFormCompletedStateWithDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/CompletedStateWithDocumentController'
import { additionalDfoTypesToShowActualProjectSZPKMap } from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/const'
import WebFormConvertedStateWithDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/ConvertedStateWithDocumentController'
import WebFormConvertingStateWithDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/ConvertingStateWithDocumentController'
import WebFormEditableStateWithDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/EditableStateWithDocumentController'
import WebFormMovingStateWithDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/MovingStateWithDocumentController'
import WebFormNewStateWithDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/NewStateWithDocumentController'
import WebFormNonDigitizableStateWithDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/NonDigitizableStateWithDocumentController'
import WebFormNotFoundStateWithDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/NotFoundStateWithDocumentController'
import { WebFormStateWithDocumentControllerStateProps } from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/types'
import {
  ChangeDocumentAttributeProps,
  useDocumentViewSidebarControl,
} from '@components/Sidebars/DocumentView'
import { WebFormTypes } from '@constants/types'
import DownloadDocumentTooltipContent from '@containers/DownloadDocumentTooltipContent/DownloadDocumentTooltipContent'
import { isNullOrUndefined } from '@helpers/checkTypes'
import { parseDocument, splitFileName } from '@helpers/fileHelpers'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import useDownloadFile from '@hooks/new/documents/useDownloadFile'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { IAttachmentFileItem, IDocumentItem } from '@interfaces/documents'
import { IDocumentDictInfo } from '@services/Dictionaries/dictionaries.entity'
import { documentsWebFormStatesMap } from '@services/Documents/documents.const'
import { WebFormInfo } from '@services/Documents/documents.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

const { isSignedDocument } = AttachmentFileItemService

interface WebFormDocumentControllerProps {
  disabled: boolean
  projectId: string
  dfoId: string
  documentSetId: string
  permissions: number
  document: IDocumentItem
  documentInfoFromDict: IDocumentDictInfo
  formInfo?: WebFormInfo
  onOpenForm?: (editMode?: boolean) => VoidFunction
  digitizingInProcess?: boolean
}

const renderStateWithDocumentControllersMap = (
  props: WebFormStateWithDocumentControllerStateProps,
) => ({
  [documentsWebFormStatesMap.NEW]: <WebFormNewStateWithDocumentController {...props} />,
  [documentsWebFormStatesMap.MOVING]: <WebFormMovingStateWithDocumentController {...props} />,
  [documentsWebFormStatesMap.CONVERTING]: (
    <WebFormConvertingStateWithDocumentController {...props} />
  ),
  [documentsWebFormStatesMap.EDITABLE]: <WebFormEditableStateWithDocumentController {...props} />,
  [documentsWebFormStatesMap.COMPLETED]: <WebFormCompletedStateWithDocumentController {...props} />,
  [documentsWebFormStatesMap.CONVERTED]: <WebFormConvertedStateWithDocumentController {...props} />,
  [documentsWebFormStatesMap.NON_DIGITIZABLE]: (
    <WebFormNonDigitizableStateWithDocumentController {...props} />
  ),
  // Если formInfo или formInfo.state пустой, то попадает в этот компонент
  [documentsWebFormStatesMap.NOT_FOUND]: <WebFormNotFoundStateWithDocumentController {...props} />,
})

const WebFormDocumentController: FC<WebFormDocumentControllerProps> = ({
  projectId,
  dfoId,
  documentSetId,
  disabled,
  permissions,
  document,
  formInfo,
  documentInfoFromDict,
  onOpenForm,
  digitizingInProcess,
}) => {
  const { id: fileId, name: fileName, extension: fileExtension, versionId } = document
  const { state = documentsWebFormStatesMap.NOT_FOUND } = formInfo || {}
  const { description, documentType } = documentInfoFromDict

  const {
    handlers: { handleChangeWebFormFileName },
  } = useWebFormAPIContext()

  const { handleUpdateFileFromSet } = useFileContext()

  const { download, downloadFormCommitedFile } = useDownloadFile()

  const { openDocumentViewSidebar } = useDocumentViewSidebarControl()

  const { isWebFormReadPermission, isWebFormReadApprovePermission, isWebFormReadDigitizing } =
    useWebFormPermissions(permissions)

  const isProjectSZPK = documentInfoFromDict?.documentType === WebFormTypes.PROJECT

  const { projectDfos } = useProjectDfos({
    key: { projectId, _key: 'projectDfos' },
    config: {
      isPaused: () => !isProjectSZPK || !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'WebFormDocumentController',
          moduleName: 'WebFormWithDocumentController',
        },
      }),
    },
  })

  const isShowActualSZPK = useMemo(() => {
    if (!projectDfos || !projectDfos.length) return false

    const hasAdditionalDfo = projectDfos.some(
      (dfo) => !!additionalDfoTypesToShowActualProjectSZPKMap?.[dfo.type],
    )

    return isProjectSZPK && hasAdditionalDfo
  }, [isProjectSZPK, projectDfos])

  const [attachmentFileItem, setAttachmentFileItem] = useState<IAttachmentFileItem | null>(null)

  const { isApproved } = attachmentFileItem?.documentInfo || {}

  const isStatic =
    isWebFormReadPermission || isWebFormReadApprovePermission || isWebFormReadDigitizing

  const handleChangeDocumentAttribute = useCallback(
    (attachment: IAttachmentFileItem) =>
      ({ attributeName, value }: ChangeDocumentAttributeProps) => {
        if (!attachment || !attachment.documentInfo) return

        const { documentInfo } = attachment

        const updatedDocument: IDocumentItem = {
          ...documentInfo,
          [attributeName]: value,
        }

        setAttachmentFileItem({
          ...attachment,
          documentInfo: updatedDocument,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocument,
          type: documentType,
        })
      },
    [documentType, handleUpdateFileFromSet],
  )

  const handleChangeFileNameOnBlur = useCallback(
    (attachment: IAttachmentFileItem) => async (event: FocusEvent<HTMLInputElement>) => {
      if (!documentSetId || !attachment || !attachment.documentInfo || !attachment.file) return

      const { file, documentInfo } = attachment

      const { value } = event.target

      const [_fileName, fileExtension] = splitFileName(file.name)

      const updatedFile = new File([file], `${value}.${fileExtension}`)

      const updatedDocumentInfo: IDocumentItem = {
        ...documentInfo,
        name: value,
      }

      const updatedAttachment: IAttachmentFileItem = {
        ...attachment,
        file: updatedFile,
        documentInfo: updatedDocumentInfo,
      }

      setAttachmentFileItem(updatedAttachment)

      try {
        await handleChangeWebFormFileName?.({
          fileId,
          documentSetId,
          name: value,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
        })
      } catch (error) {
        setAttachmentFileItem(attachment)
      }
    },
    [documentSetId, documentType, fileId, handleChangeWebFormFileName, handleUpdateFileFromSet],
  )

  const handleOpenDocumentViewSidebar = useCallback(() => {
    if (!fileId || !attachmentFileItem || !attachmentFileItem.file) return

    openDocumentViewSidebar({
      documentId: fileId,
      projectId,
      dfoId,
      documentSetId,
      isStatic: isStatic || !!isApproved,
      isApproved,
      accessToApprove: isWebFormReadApprovePermission,
      typeOfDocument: description || '',
      edit: {
        onBlur: handleChangeFileNameOnBlur(attachmentFileItem),
        value: fileName,
      },
      onChangeDocumentAttribute: handleChangeDocumentAttribute(attachmentFileItem),
    })
  }, [
    attachmentFileItem,
    description,
    dfoId,
    documentSetId,
    fileId,
    fileName,
    handleChangeDocumentAttribute,
    handleChangeFileNameOnBlur,
    isApproved,
    isStatic,
    isWebFormReadApprovePermission,
    openDocumentViewSidebar,
    projectId,
  ])

  const handleDownloadFile = useCallback(
    ({
        withSigned = false,
        fileId,
        versionId,
        fileName,
        fileExtension,
      }: HandleDownloadWebFormFile) =>
      (event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation()

        const fileNameWithExtension = `${fileName}.${fileExtension}`

        return withDownloadToastPromise(
          download({
            projectId,
            documentSetId,
            fileId,
            versionId,
            fileNameWithExtension,
            withSigned,
            type: 'save',
          }),
        )
      },
    [documentSetId, download, projectId],
  )

  const handleDownloadCommitedFile = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      if (!formInfo) return

      event?.stopPropagation()

      return withDownloadToastPromise(
        downloadFormCommitedFile(formInfo.id, documentInfoFromDict.documentType),
      )
    },
    [documentInfoFromDict.documentType, formInfo, downloadFormCommitedFile],
  )

  const preparedDownloadProps: DownloadProps = useMemo(() => {
    return {
      signCondition: isSignedDocument(document),
      onDownload: handleDownloadFile({
        fileId,
        fileName,
        fileExtension,
        versionId,
      }),
      downloadTooltipContent: (
        <DownloadDocumentTooltipContent
          additionalButtonsRender={
            isShowActualSZPK
              ? [
                  {
                    size: 's',
                    view: 'plain',
                    geometry: 'square',
                    onClick: handleDownloadCommitedFile,
                    children: 'Актуальный СЗПК',
                  },
                ]
              : undefined
          }
          handleDownloadFile={handleDownloadFile({
            fileId,
            fileName,
            fileExtension,
            versionId,
          })}
          handleDownloadFileWithStamps={handleDownloadFile({
            withSigned: true,
            fileId,
            fileName,
            fileExtension,
            versionId,
          })}
        />
      ),
    }
  }, [
    isShowActualSZPK,
    document,
    fileExtension,
    fileId,
    fileName,
    handleDownloadCommitedFile,
    handleDownloadFile,
    versionId,
  ])

  useEffect(() => {
    if (!document) return

    setAttachmentFileItem(parseDocument(document))
  }, [])

  if (!attachmentFileItem?.documentInfo) return null

  return (
    renderStateWithDocumentControllersMap({
      downloadProps: preparedDownloadProps,
      documentInfoFromDict,
      disabled,
      document: attachmentFileItem.documentInfo,
      permissions,
      onControlClick: handleOpenDocumentViewSidebar,
      onOpenForm,
      digitizingInProcess,
      ...(!isNullOrUndefined(attachmentFileItem?.documentInfo?.isApproved) && {
        statusType: isApproved ? statusTypesMap.APPROVED : statusTypesMap.REJECTED,
      }),
    })[state] || null
  )
}

export default memo(WebFormDocumentController)
