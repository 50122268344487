import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'

const generateRHFObjectForNoticeOfProjectRefuseConcludeAAForm = (
  objectFromPropertyState: Record<string, any>,
): NoticeOfProjectRefuseConcludeAAFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
      documentReceivers: objectFromPropertyState.documentReceiversDraft?.value,
    },
    '2': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: objectFromPropertyState.investorFullNameGenitiveDraft?.value || '',
      projectName: objectFromPropertyState.projectName?.value || '',
    },
    '4': {
      ruleLinkExtraReject: objectFromPropertyState.ruleLinkExtraRejectDraft?.value || '',
      extraRejectReason: objectFromPropertyState.extraRejectReasonDraft?.value || '',
    },
  } as NoticeOfProjectRefuseConcludeAAFormValues
}

export { generateRHFObjectForNoticeOfProjectRefuseConcludeAAForm }
