import React, { FC, memo, useMemo } from 'react'

import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import { DefaultActionProps } from '@components/NewDesign/Modal/Base/Actions'
import { Tooltip } from '@components/NewDesign/Tooltip'
import ThreeDot from '@icons/ThreeDot.svg'

import styles from './ContextMenu.module.scss'

interface FolderItemContextMenuProps {
  actions: DefaultActionProps[]
  dataTestId?: string
}

const FolderItemContextMenu: FC<FolderItemContextMenuProps> = ({ actions, dataTestId }) => {
  const tooltipContent = useMemo(
    () => (
      <div className={styles.contextMenu__actions}>
        {actions.map((actionProps, index) => {
          const { bindOnClose, ...buttonRest } = actionProps
          return (
            <Button key={index} {...buttonRest}>
              {buttonRest.children}
            </Button>
          )
        })}
      </div>
    ),
    [actions],
  )

  return (
    <div>
      <Tooltip
        contentClassName={styles.contextMenu__content}
        targetClassName={styles.contextMenu__target}
        trigger={'click'}
        position={'bottom-start'}
        content={tooltipContent}
        arrowClassName={styles.contextMenu__arrow}
      >
        <IconButton
          icon={{ src: ThreeDot, className: styles.contextMenu__icon }}
          dataTestId={dataTestId}
          geometry={'square'}
          view={'basic'}
          size={'s'}
        />
      </Tooltip>
    </div>
  )
}

export default memo(FolderItemContextMenu)
