import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeOfRejectConcludeSZPKBlockValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/const'
import { fourthSectionValidationMap } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/Forms/4/validation'
import { useNoticeOfRejectConcludeSZPKManager } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/Manager'
import { NoticeOfRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/types'
import { NoticeOfRejectConcludeSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<NoticeOfRejectConcludeSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOfRejectConcludeSZPKManager()

  const { getSubscribableControlProps, getControllerProps, getInputProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: NoticeOfRejectConcludeSZPKFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: noticeOfRejectConcludeSZPKBlockValues['4'].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование проекта'}
                controllerProps={getControllerProps({
                  name: noticeOfRejectConcludeSZPKBlockValues['4'].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title="Пункт Правил, на основании которого направляется уведомление об отказе в заключении СЗПК"
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: noticeOfRejectConcludeSZPKBlockValues['4'].ruleLinkStatementReject,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: noticeOfRejectConcludeSZPKBlockValues['4'].ruleLinkStatementReject,
                rules: fourthSectionValidationMap.ruleLinkStatementReject,
                inputProps: {
                  caption: 'рекомендация: 27',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      noticeOfRejectConcludeSZPKBlockValues['4'].ruleLinkStatementReject,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      noticeOfRejectConcludeSZPKBlockValues['4'].ruleLinkStatementReject,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title="Основания, в связи с которыми не может быть заключено СЗПК"
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: noticeOfRejectConcludeSZPKBlockValues['4'].statementRejectReason,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: noticeOfRejectConcludeSZPKBlockValues['4'].statementRejectReason,
                rules: fourthSectionValidationMap.statementRejectReason,
                textareaProps: {},
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      noticeOfRejectConcludeSZPKBlockValues['4'].statementRejectReason,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      noticeOfRejectConcludeSZPKBlockValues['4'].statementRejectReason,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title="Пункт правил, в соответствии с которым можно повторно подать заявление на СЗПК"
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: noticeOfRejectConcludeSZPKBlockValues['4'].ruleLinkStatementRepeat,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: noticeOfRejectConcludeSZPKBlockValues['4'].ruleLinkStatementRepeat,
                rules: fourthSectionValidationMap.ruleLinkStatementRepeat,
                inputProps: {
                  caption: 'рекомендация: 36',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      noticeOfRejectConcludeSZPKBlockValues['4'].ruleLinkStatementRepeat,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      noticeOfRejectConcludeSZPKBlockValues['4'].ruleLinkStatementRepeat,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </Container>
  )
}

export default Fourth
