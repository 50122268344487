import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import {
  AAgreementOnCreationOtherEstateObjectsFormModifierValues,
  AAgreementOnCreationOtherEstateObjectsFormValues,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'

export const AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<AAgreementOnCreationOtherEstateObjectsFormValues>()
export const AAgreementOnCreationOtherEstateObjectsModifierFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<AAgreementOnCreationOtherEstateObjectsFormModifierValues>()
export const AAgreementOnCreationOtherEstateObjectsFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
