import React, { FC, useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import { getFullClassification } from '@components/NewDesign/InputSearchTree/InputSearchTree'
import { MasterItemFormValues } from '@components/RegistryNpa/MasterItem'
import AddPartItem, { AddPartItemFormValues } from '@components/RegistryNpa/MasterItem/Parts/Add'
import PartItem from '@components/RegistryNpa/MasterItem/Parts/Item'
import { usePartNpaSidebar } from '@components/Sidebars/NPA/Part'
import { RolesTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { IMasterNpaItem, INpaItem } from '@services/NPA/NPA.entity'
import { unstable_serialize, useSWRConfig } from 'swr'

import styles from './Parts.module.scss'

interface PartsOfMasterItemProps {
  control: Control<MasterItemFormValues>
  mainNpa: IMasterNpaItem
}

const PartsOfMasterItem: FC<PartsOfMasterItemProps> = ({ control, mainNpa }) => {
  const {
    npaApi: { createNpaPart },
  } = useAPIContext()

  const {
    fields: parts,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    name: 'npa.parts',
    keyName: 'fieldId',
  })

  const { cache } = useSWRConfig()

  const { checkingRole } = useAuthContext()
  const { partClassifications } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  const { handleOpenPartSidebar } = usePartNpaSidebar()

  const mainNpaId = mainNpa.id

  const handleAddPart = useCallback(
    async (partProps: AddPartItemFormValues) => {
      try {
        const { id } = await createNpaPart(mainNpaId, partProps)

        append({ ...partProps, id })

        cache.delete(
          unstable_serialize({
            npaId: mainNpaId,
            _key: 'npa',
          }),
        )
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          additionInfo: {
            npaId: mainNpaId,
            ...partProps,
          },
          componentInfo: {
            componentName: 'PartsOfMasterItem',
            moduleName: 'MasterItem',
            componentType: 'addNPAPart',
          },
        })(error)

        throw error
      }
    },
    [append, cache, createNpaPart, mainNpaId],
  )

  const handleUpdateNpaPart = (index: number) => (newPart: INpaItem) => {
    update(index, newPart)
  }

  const handleRemoveNpaPart = (index: number) => () => {
    remove(index)
  }

  return (
    <>
      {parts?.map((part, index) => {
        const classificationHeader = getFullClassification({
          partClassifications: partClassifications ?? [],
          value: part.classificationId,
        })

        return (
          <button
            key={part.id}
            type="button"
            className={styles.parts__item}
            onClick={() =>
              handleOpenPartSidebar({
                part,
                mainNpaId,
                onUpdatePart: handleUpdateNpaPart(index),
                onRemovePart: handleRemoveNpaPart(index),
              })
            }
          >
            <PartItem part={part.part} classificationHeader={classificationHeader} />
          </button>
        )
      })}
      {checkingRole?.(RolesTypes.MER) && <AddPartItem onConfirm={handleAddPart} />}
    </>
  )
}

export default PartsOfMasterItem
