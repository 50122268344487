import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { isArray, isObject } from '@helpers/checkTypes'

const { isFormFieldError } = DocumentFormHelpers

class NestedMenuHelpers {
  static getMenuListError(value: unknown) {
    if (isArray(value)) return !!value.length && value.some(isFormFieldError)

    return isFormFieldError(value)
  }

  static isNestedMenu(value: unknown): value is NestedMapOfMenu {
    return (
      isObject(value) &&
      'path' in value &&
      'id' in value &&
      'parentBaseId' in value &&
      'children' in value
    )
  }

  static fillArrayWithSeparatedPaths(path: string, pathsInArray: string[] = []): string[] {
    const lastDotIndex = path.lastIndexOf('.')

    if (lastDotIndex === -1) return [path, ...pathsInArray]

    const newPath = path.slice(0, lastDotIndex)

    return NestedMenuHelpers.fillArrayWithSeparatedPaths(newPath, [path, ...pathsInArray])
  }
}

export { NestedMenuHelpers }
