import { generatePropertiesObjectForCertificateApplicationConclusionOfAARequirementsForm } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForCertificateApplicationConclusionOfAARequirementsForm } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/adapters/RHF.adapter'
import { CertificateApplicationConclusionOfAARequirementsFormValues } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/types'

const useCertificateApplicationConclusionOfAARequirementsAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): CertificateApplicationConclusionOfAARequirementsFormValues =>
    generateRHFObjectForCertificateApplicationConclusionOfAARequirementsForm(
      objectFromPropertyState,
    )

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForCertificateApplicationConclusionOfAARequirementsForm(
      objectFromPropertyState,
    )

  return { generateRHFObject, generatePropertiesObject }
}

export { useCertificateApplicationConclusionOfAARequirementsAdapters }
