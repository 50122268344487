import { RefObject, useEffect, useRef, useState } from 'react'
import { forwardRef } from 'react'

import IconButton from '@components/IconButton'
import { objOfDateFormats } from '@constants/dateFormats'
import { decrement, decrementToTen, increment, incrementToTen } from '@helpers/commonHelpers'
import { mergeRefs } from '@helpers/ref/mergeRefs'
import useOnClickOutside from '@hooks/useOutsideClick'
import keyboardArrowLeftIcon from '@icons/hardware/keyboard_arrow_left.svg'
import keyboardArrowRightIcon from '@icons/hardware/keyboard_arrow_right.svg'
import DayjsService from '@services/Dayjs/Dayjs.service'
import cn from 'classnames'
import { Dayjs } from 'dayjs'

import styles from './Calendar.module.scss'
import { DAYS, mapOfCalendarTypes, MONTH_NAMES, MONTH_SHORT_NAMES } from './const'
import { generateMonthDays } from './helpers'
import { CalendarType, TypeOfUnit } from './types'

export type CalendarProps = {
  fixWidth?: boolean
  readOnly?: boolean
  initialShow?: boolean
  align?: 'left' | 'right'
  dateFormat?: string
  className?: string
  disabled?: boolean
  containerRef?: RefObject<HTMLElement>
  value?: Dayjs
  typeOfPicker?: CalendarType

  onChange?: (date: Dayjs) => void
  handleSelectDay?: (value: Dayjs | number) => void
  disabledDate?: (value: Dayjs, typeOfUnit?: TypeOfUnit, periodOfDate?: 'start' | 'end') => boolean
  disabledDatePeriod?: 'start' | 'end'
}

const MINIMAL_YEAR = 1900

const Calendar = forwardRef<HTMLElement, CalendarProps>(
  (
    {
      align,
      dateFormat,
      disabled,
      value: selectedDate,
      typeOfPicker,
      onChange,
      handleSelectDay,
      disabledDate,
      disabledDatePeriod = 'start',
    },
    ref,
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const datapickerRef = useRef<HTMLDivElement>(null)

    const initialDate = selectedDate || DayjsService.dayjs()

    const [prevMonthDays, setPrevMonthDays] = useState<number[]>([])
    const [monthDays, setMonthDays] = useState<number[]>([])
    const [nextMonthDays, setNextMonthDays] = useState<number[]>([])
    const [month, setMonth] = useState(initialDate.get('month'))
    const [year, setYear] = useState(initialDate.get('year'))
    const [calendarType, setCalendarType] = useState<CalendarType>(typeOfPicker || mapOfCalendarTypes.days)

    const [disabledLeftButton, setDisabledLeftButton] = useState(false)
    const [disabledRightButton, setDisabledRightButton] = useState(false)

    useOnClickOutside(wrapperRef, () => {
      if (!typeOfPicker) {
        setCalendarType(mapOfCalendarTypes.days)
      }

      setMonth(initialDate.get('month'))
      setYear(initialDate.get('year'))
    })

    useEffect(() => {
      setMonth(initialDate.get('month'))
      setYear(initialDate.get('year'))
    }, [selectedDate])

    useEffect(() => {
      const date = DayjsService.dayjs({ year, month })
      const prevDate = date.subtract(1, 'month')
      const nextDate = date.add(1, 'month')
      const dateOffset = DayjsService.dayjs(date).weekday()
      const nextDateOffset = DayjsService.dayjs(nextDate).weekday()

      const _monthDays = generateMonthDays(date)
      const _prevMonthDays = dateOffset ? generateMonthDays(prevDate) : []
      const _nextMonthDays = nextDateOffset ? generateMonthDays(nextDate) : []

      setMonthDays(_monthDays)
      setPrevMonthDays(_prevMonthDays.slice(-dateOffset))
      setNextMonthDays(_nextMonthDays.slice(0, 7 - nextDateOffset))

      switch (calendarType) {
        case mapOfCalendarTypes.days: {
          const _dateForRightButton = DayjsService.dayjs({ year, month: month + 1, date: 1 })
          const _dateForLeftButton = DayjsService.dayjs({ year, month })

          setDisabledRightButton(!!disabledDate?.(_dateForRightButton))
          setDisabledLeftButton(!!disabledDate?.(_dateForLeftButton))

          break
        }
        case mapOfCalendarTypes.months: {
          const _dateForRightButton = DayjsService.dayjs({ year: year + 1, month: 1 })
          const _dateForLeftButton = DayjsService.dayjs({ year, month: 1 })

          setDisabledRightButton(!!disabledDate?.(_dateForRightButton))
          setDisabledLeftButton(!!disabledDate?.(_dateForLeftButton))

          break
        }
        case mapOfCalendarTypes.years: {
          const _dateForRightButton = DayjsService.dayjs({ year: year + 10 })
          const _dateForLeftButton = DayjsService.dayjs({ year: year - 10 })

          setDisabledRightButton(!!disabledDate?.(_dateForRightButton))
          setDisabledLeftButton(!!disabledDate?.(_dateForLeftButton))

          break
        }
      }
    }, [year, month, calendarType])

    const isSelectedDate = (date: Dayjs, type = 'day') => {
      const _selectedDate = selectedDate || initialDate
      let formattedSelectedDate = ''
      let formattedDate = ''

      switch (type) {
        case 'day':
          formattedSelectedDate = _selectedDate.format(dateFormat)
          formattedDate = date.format(dateFormat)
          break
        case 'month':
          formattedSelectedDate = _selectedDate.format('MM.YYYY')
          formattedDate = date.format('MM.YYYY')
          break
        case 'year':
          formattedSelectedDate = _selectedDate.format('YYYY')
          formattedDate = date.format('YYYY')
          break
      }

      return formattedSelectedDate === formattedDate
    }

    const isCurrentDate = (date: Dayjs) => {
      const formattedCurrentDate = DayjsService.dayjs().format(dateFormat)
      const formattedDate = date.format(dateFormat)

      return formattedCurrentDate === formattedDate
    }

    const handleChangeDate = (date: string | number) => (e) => {
      e.stopPropagation()
      e.preventDefault()

      const _selectedDate = DayjsService.dayjs({ year, month, date })

      onChange?.(_selectedDate)

      handleSelectDay?.(DayjsService.dayjs({ year, month, date }))
    }

    const handleChangeMonth = (monthIndex: number) => () => {
      setMonth(monthIndex)
      setCalendarType(mapOfCalendarTypes.days)
    }

    const handleChangeYear = (year: number) => () => {
      if (typeOfPicker === mapOfCalendarTypes.years) {
        const _selectedDate = DayjsService.dayjs({ year }).endOf('year')
        setYear(year)

        return onChange?.(_selectedDate)
      }

      setCalendarType(mapOfCalendarTypes.months)
      setYear(year)
      setMonth(initialDate.get('month'))
    }

    const handlePrevMonth = () => {
      if (calendarType === mapOfCalendarTypes.months) {
        setYear(decrement)
        return
      }

      if (calendarType === mapOfCalendarTypes.years) {
        setYear(decrementToTen)
        return
      }

      if (!month) {
        setMonth(11)
        setYear(decrement)
      } else {
        setMonth(decrement)
      }
    }

    const handleNextMonth = () => {
      if (calendarType === mapOfCalendarTypes.months) {
        setYear(increment)
        return
      }

      if (calendarType === mapOfCalendarTypes.years) {
        setYear(incrementToTen)
        return
      }

      if (month === 11) {
        setMonth(0)
        setYear(increment)
      } else {
        setMonth(increment)
      }
    }

    const handleMonthSelect = () => {
      if (calendarType === mapOfCalendarTypes.years) return

      calendarType === mapOfCalendarTypes.months ?
        setCalendarType(mapOfCalendarTypes.years) : setCalendarType(mapOfCalendarTypes.months)
    }

    const renderBlankDay = (date: number, index: number) => {
      return (
        <div key={index} style={{ width: '14.28%' }} className="px-px mb-0.5">
          <button disabled type="button" className={styles.blankDay}>
            {date}
          </button>
        </div>
      )
    }

    const monthContent = (
      <div className={styles.monthContent}>
        <div className={styles.weekdays}>
          {DAYS.map((weekday, index) => (
            <div key={index} style={{ width: '14.26%' }} className="px-0.5">
              <div className={styles.weekday}>{weekday}</div>
            </div>
          ))}
        </div>

        <div className={styles.days}>
          {prevMonthDays.map(renderBlankDay)}
          {monthDays.map((date, index) => {
            const _date = DayjsService.dayjs({ year, month, date })
            const _isSelectedDate = isSelectedDate(_date)
            const _isCurrentDate = isCurrentDate(_date)

            return (
              <div key={index} style={{ width: '14.28%' }} className="px-px mb-0.5">
                <button
                  type="button"
                  disabled={disabled || disabledDate?.(_date)}
                  className={cn(styles.day, {
                    [styles['day--selected']]: _isSelectedDate,
                    [styles['day--current']]: _isCurrentDate,
                  })}
                  onMouseDown={handleChangeDate(date)}
                >
                  {date}
                </button>
              </div>
            )
          })}
          {nextMonthDays.map(renderBlankDay)}
        </div>
      </div>
    )

    // если первый день/месяц/двадцатилетие следующего дня/месяца/двадцатилетия не прошел проверку, то disabled
    const yearContent = (
      <div className={styles.yearContent}>
        <div className={styles.months}>
          {MONTH_SHORT_NAMES.map((date, index) => {
            const argOfPeriod = 'month'
            const dayJsInstance = DayjsService.dayjs({ year, month: index })

            const _date =
              disabledDatePeriod === 'start'
                ? dayJsInstance.startOf(argOfPeriod)
                : dayJsInstance.endOf(argOfPeriod)
            const _isSelectedDate = isSelectedDate(_date, argOfPeriod)
            const _isCurrentDate = isCurrentDate(_date)

            return (
              <div key={date + index}>
                <button
                  type="button"
                  disabled={disabled || disabledDate?.(_date, argOfPeriod)}
                  className={cn(styles.month, {
                    [styles['month--selected']]: _isSelectedDate,
                    [styles['month--current']]: _isCurrentDate,
                  })}
                  onClick={handleChangeMonth(index)}
                >
                  {date}
                </button>
              </div>
            )
          })}
        </div>
      </div>
    )

    const twentiethYearContent = (
      <div className={styles.twentiethYearContent}>
        <div className={styles.twentiethYear}>
          {[...Array(year + 10).keys()].slice(year - 10).map((date, index) => {
            const argOfPeriod = 'year'
            const dayJsInstance = DayjsService.dayjs({ year: date })

            const _date =
              disabledDatePeriod === 'start'
                ? dayJsInstance.startOf(argOfPeriod)
                : dayJsInstance.endOf(argOfPeriod)

            const _isSelectedDate = isSelectedDate(_date, argOfPeriod)
            const _isCurrentDate = isCurrentDate(_date)

            return (
              <div key={date + index}>
                <button
                  type="button"
                  disabled={disabled || disabledDate?.(_date, argOfPeriod) || date < MINIMAL_YEAR}
                  className={cn(styles.year, {
                    [styles['year--selected']]: _isSelectedDate,
                    [styles['year--current']]: _isCurrentDate,
                  })}
                  onClick={handleChangeYear(date)}
                >
                  {date}
                </button>
              </div>
            )
          })}
        </div>
      </div>
    )

    const renderCalendarType = () => {
      switch (calendarType) {
        case mapOfCalendarTypes.days:
          return monthContent
        case mapOfCalendarTypes.months:
          return yearContent
        case mapOfCalendarTypes.years:
          return twentiethYearContent
      }
    }

    const renderCalendarHeader = () => {
      switch (calendarType) {
        case mapOfCalendarTypes.days:
          return `${MONTH_NAMES[month]}, ${year}`
        case mapOfCalendarTypes.months:
          return year
        case mapOfCalendarTypes.years:
          return `${year - 10} - ${year + 9}`
      }
    }

    const isMinimalTenYear = year - 10 <= MINIMAL_YEAR
    const isMinimalYear = year <= MINIMAL_YEAR
    const isMinimalYearMonth = year <= MINIMAL_YEAR && month <= 0

    return (
      <div
        className={cn(styles.datepicker, styles[`datepicker--${align}`])}
        ref={mergeRefs([ref, datapickerRef])}
      >
        <div className={styles.header}>
          <button
            type="button"
            disabled={disabled}
            className={calendarType === mapOfCalendarTypes.years ? 'cursor-default' : ''}
            onClick={handleMonthSelect}
          >
            <h4 className={styles.date}>{renderCalendarHeader()}</h4>
          </button>

          <div className={styles.arrows}>
            <IconButton
              size="s"
              type="button"
              priority="secondary"
              icon={keyboardArrowLeftIcon}
              disabled={
                disabled ||
                disabledLeftButton ||
                (calendarType === mapOfCalendarTypes.years && isMinimalTenYear) ||
                (calendarType === mapOfCalendarTypes.months && isMinimalYear) ||
                (calendarType === mapOfCalendarTypes.days && isMinimalYearMonth)
              }
              onClick={handlePrevMonth}
            />
            <IconButton
              size="s"
              type="button"
              disabled={disabled || disabledRightButton}
              priority="secondary"
              icon={keyboardArrowRightIcon}
              onClick={handleNextMonth}
            />
          </div>
        </div>
        {renderCalendarType()}
      </div>
    )
  },
)

Calendar.defaultProps = {
  align: 'left',
  dateFormat: objOfDateFormats.defaultFormat,
  initialShow: false,
}

export default Calendar
