import { FC } from 'react'

import First from './1'

interface InformationOfExecutionAgreementEventsFormsProps extends FC {
  First: typeof First
}

const InformationOfExecutionAgreementEventsForms: InformationOfExecutionAgreementEventsFormsProps = () => null

InformationOfExecutionAgreementEventsForms.First = First

export default InformationOfExecutionAgreementEventsForms
