const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

export const convertBytes = (bytes: number) => {
  if (bytes == 0) return null

  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))))

  if (i == 0) return bytes + ' ' + sizes[i]

  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
}
