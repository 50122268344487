import { DetailedHTMLProps, FC, LiHTMLAttributes, memo, useCallback, useRef } from 'react'

import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import type { IKnowledgeBaseItem } from '@components/KnowledgeBase/types'
import Accordion, { AccordionState } from '@components/NewDesign/Accordion'
import Button from '@components/NewDesign/Button'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from './MobileItem.module.scss'

interface KnowledgeBaseMobileItemProps
  extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  baseItem: IKnowledgeBaseItem
  initialPadding?: number
  stepOfPadding?: number
}

const KnowledgeBaseMobileItem: FC<KnowledgeBaseMobileItemProps> = ({
  baseItem,
  initialPadding = 0,
  stepOfPadding = 12,
  ...rest
}) => {
  const {
    state: { currentBaseItem },
    handlers: { handleChooseBaseItem },
  } = useKnowledgeBase()

  const isOpenAccordion = useRef(currentBaseItem?.parentBaseId === baseItem.id)

  const isList = !!baseItem.children.length

  const nextInitialPaddingLeft = stepOfPadding + initialPadding

  const isSelectedItem = currentBaseItem?.id === baseItem.id

  const renderTrailingIcon = useCallback(
    ({ isActive }: AccordionState) => ({
      src: chevronRightIcon,
      className: cn(styles['item__button-icon'], {
        [styles['item__button-icon--active']]: isActive,
      }),
    }),
    [],
  )

  const handleSelectBaseItem = useCallback(() => {
    // чтобы убрать лишние ререндеры
    if (isSelectedItem) return

    handleChooseBaseItem?.(baseItem)
  }, [baseItem, handleChooseBaseItem, isSelectedItem])

  if (!isList) {
    return (
      <li className={styles.item} {...rest}>
        <Button
          fixWidth
          geometry="square"
          view="text"
          size="xs"
          color="default"
          variant="buttonSMedium"
          className={styles.item__button}
          textClassName={cn(styles['item__button-text'], {
            [styles['item__button-text--active']]: isSelectedItem,
          })}
          onClick={() => handleChooseBaseItem?.(baseItem)}
        >
          {baseItem.name}
        </Button>
      </li>
    )
  }

  return (
    <Accordion
      isOpen={isOpenAccordion.current}
      text={baseItem.name}
      buttonProps={{
        renderTrailingIcon,
        className: styles.item__button,
        textClassName: cn(styles['item__button-text'], {
          [styles['item__button-text--active']]: isSelectedItem,
        }),
        onClick: !isSelectedItem ? handleSelectBaseItem : undefined,
      }}
      {...rest}
    >
      {baseItem.children.map((item) => (
        <KnowledgeBaseMobileItem
          key={item.id}
          baseItem={item}
          initialPadding={nextInitialPaddingLeft}
          style={{ paddingLeft: nextInitialPaddingLeft }}
        />
      ))}
    </Accordion>
  )
}

export default memo(KnowledgeBaseMobileItem)
