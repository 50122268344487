import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationFillingAppConcludingFormValues } from '@components/Forms/NotificationFillingAppConcludingForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionValidationMap: FormValuesValidationSection<
  keyof NotificationFillingAppConcludingFormValues['3'],
  NotificationFillingAppConcludingFormValues
> = {
  investorFullNameGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1050),
  },
}

export { thirdSectionValidationMap }
