import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import {
  getFetcherProps,
  isFetcherProps,
} from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { projectCompensationFieldsValidation } from '@components/Forms/CreateStatement/Forms/Compensations/Compensation/validation'
import { ProjectCompensationsArrayPathName } from '@components/Forms/CreateStatement/Forms/Compensations/types'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { CreateStatementControlUpdateWatcher } from '@components/Forms/CreateStatement/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { HierarchyTypeSelectOption } from '@components/NewDesign/Select/HierarchyReferenceBookSelect/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { documentsLinks } from '@constants/documents'
import { defaultRHFValidation } from '@constants/validations'
import { getObjectValue } from '@helpers/object/getObjectValue'

import styles from './Compensation.module.scss'

const { transformRHFPathInProperties } = DocumentFormHelpers

interface IStepCompensation {
  id: string
  name: ProjectCompensationsArrayPathName
  index: number
  onRemoveCompensation: VoidFunction
}

const PROJECT_COMPENSATION_FORM_TO_IGNORE = ['Налоговый вычет', 'Субсидия и (или) налоговый вычет']

const INFRASTRUCTURES = ['Обеспечивающая инфраструктура', 'Сопутствующая инфраструктура']

const StepCompensation: FC<IStepCompensation> = ({ id, index, name, onRemoveCompensation }) => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      getPropertiesProps,
      getOverrideProps,
    },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()

  const { getSubscribableControlProps, getSingleSelectProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: CreateStatementControlUpdateWatcher,
    })

  const isFederal = useWatch({
    name: statementBlockValues.additionalFields.isFederal,
    control: formInstance?.control,
  })

  const currentValueOfType = useWatch({
    name: `${name}.projectCompensationType`,
    control: formInstance?.control,
  })
  const projectCompensationPeriod = useWatch({
    name: `${name}.projectCompensationPeriod`,
    control: formInstance?.control,
  })

  const selectedProjectCompensationType: HierarchyTypeSelectOption | null = useMemo(() => {
    if (!editMode) return null

    const propertyProjectCompensationType = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(`${name}.projectCompensationType`),
    )

    if (!propertyProjectCompensationType || !propertyProjectCompensationType?.propertyId)
      return null

    const allControlProps = getOverrideProps?.()[propertyProjectCompensationType.propertyId]

    const overrideProps = getFetcherProps(allControlProps)
    if (!overrideProps) return null

    const foundSelectedOption = overrideProps.find(
      (option) => option.id === currentValueOfType?.value,
    )

    return foundSelectedOption || null
  }, [currentValueOfType?.value, editMode, getOverrideProps, getPropertiesProps, name])

  const hasInfrastructure = INFRASTRUCTURES.includes(selectedProjectCompensationType?.name || '')

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate
      dataTestId={'compensationsListItem'}
      id={id}
      onRemoveStep={index > 1 && editMode ? onRemoveCompensation : undefined}
    >
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.projectCompensationType`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item?.name || '',
                          value: item.id,
                        }),
                        controllerProps: {
                          name: `${name}.projectCompensationType`,
                          rules: projectCompensationFieldsValidation.projectCompensationType,
                        },
                        selectProps: {
                          disabled: !editMode || index < 2,
                          inputProps: {
                            label: 'Вид затрат',
                            dataTestId: `StepCompensation-${name}.projectCompensationType-singleSelect`,
                          },
                          optionsProps: {
                            variant: 'secondary',
                            scrollableOptionsContainerClassName: styles.compensation__options,
                            optionClassName: styles.compensation__option,
                          },
                          onChangeFormValue: () => {
                            setTimeout(async () => {
                              await handleChangeValue?.(`${name}.projectCompensationType`)

                              await formInstance.trigger(`${name}.projectCompensationForm`)
                            }, 0)
                          },
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>

              {currentValueOfType && projectCompensationPeriod && (
                <Typography.Caption
                  className={styles.compensation__caption}
                  color={'text-base-tertiary'}
                  variant={'captionSRegular'}
                >
                  {`Срок возмещения затрат составит до ${projectCompensationPeriod}`}
                  <Typography.Caption
                    as={'span'}
                    variant={'captionSRegular'}
                    color={'text-accent-brand'}
                  >
                    <a href={documentsLinks.FZ_N69} target="_blank" rel="noopener noreferrer">
                      &nbsp;(часть 6 статьи 15 69-ФЗ)
                    </a>
                  </Typography.Caption>
                </Typography.Caption>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.projectCompensationForm`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => {
                          if (
                            !isFederal &&
                            PROJECT_COMPENSATION_FORM_TO_IGNORE.some((value) => value == item.name)
                          )
                            return null

                          return {
                            displayValue: item?.name || '',
                            value: item.id,
                          }
                        },
                        controllerProps: {
                          name: `${name}.projectCompensationForm`,
                          rules: {
                            required: !hasInfrastructure && defaultRHFValidation.required,
                          },
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Форма возмещения затрат',
                            dataTestId: `StepCompensation-${name}.projectCompensationForm-singleSelect`,
                          },
                          onChangeFormValue: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${name}.projectCompensationForm`)
                            }, 0),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.projectCompensationValue`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${name}.projectCompensationValue`,
                    rules: projectCompensationFieldsValidation.projectCompensationValue,
                    inputProps: {
                      label: 'Объем затрат, без учета НДС',
                      dataTestId: `StepCompensation-${name}.projectCompensationValue-amountInput`,
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.projectCompensationValue`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.projectCompensationValue`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default memo(StepCompensation)
