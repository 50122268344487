import React from 'react'
import { useFormContext } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import SubjectForm from '@components/Forms/ProjectSZPKForm/Forms/2/AdditionalForms/SubjectForm'
import { SecondSubjectsPathName } from '@components/Forms/ProjectSZPKForm/Forms/2/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldArrayControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'

const Second = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const { fields: subjects } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SecondSubjectsPathName
  >({
    control: formInstance.control,
    name: projectSZPKBlockValues['2'].subjects,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      {subjects.map((subject, index) => (
        <Row key={subject.id}>
          <SubjectForm formName={`${projectSZPKBlockValues['2'].subjects}.${index}` as const} />
          {index !== subjects.length - 1 && <Border />}
        </Row>
      ))}
    </Container>
  )
}

export default Second
