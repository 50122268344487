import { PasswordRowProps } from '@containers/FieldCondition/const'

const codePatterns: PasswordRowProps[] = [
  {
    text: 'От 1 до 50 символов',
  },
  { text: 'Большие и маленькие латинские буквы' },
  { text: 'Цифры' },
  {
    text: 'Символ точки "."',
  },
]

export { codePatterns }
