import { getQueryByNameFromUrl } from '@helpers/url/getQueryByNameFromUrl'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

import { DfoPropertyValue, DfoPropertyValueOrganisation } from './Dfo.entity'

class DfoHelpersService {
  static getDfoIdFromUrl() {
    return getQueryByNameFromUrl('dfoId')
  }

  static isDfoPropertyValueOrganisation(dfoPropertyValue: DfoPropertyValue): dfoPropertyValue is DfoPropertyValueOrganisation {
    return dfoPropertyValue["@type"] === PropertyTypeEnum['ORGANIZATION']
  }
}

export default DfoHelpersService
