import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import GroupWithPublic from '@components/DocumentFormComponents/GroupWithPublic'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import RegistrationCertificatePlannedTax from '@components/Forms/RegistrationCertificateForm/Forms/5/PlannedTax'
import RegistrationCertificateStepRegisteredDateValuesList from '@components/Forms/RegistrationCertificateForm/Forms/5/StepRegisteredDateValuesList'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fifth = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const formModifierInstance = useFormModifierContext<RegistrationCertificateFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
    handlers: { handleChangePublicValue, handleChangePublicValueInList },
  } = useRegistrationCertificateManager()

  const { getControllerProps, getFormFieldControlProps, getSubscribableControlProps } =
    useFormComponentPresets({
      isNew: true,
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: RegistrationCertificateFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['5'].projectName,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['5'].projectName,
                      value,
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Наименование проекта"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['5'].projectName,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <GroupWithPublic
          isTitleAbsolute
          disableBottomBorder
          name={registrationCertificateBlockValues['5'].szpkInfoGroup}
          control={formModifierInstance.control}
          onPublic={handleChangePublicValueInList}
        >
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: registrationCertificateBlockValues['5'].szpkNumber,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Регистрационный номер'}
                  controllerProps={getControllerProps({
                    name: registrationCertificateBlockValues['5'].szpkNumber,
                  })}
                />
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: registrationCertificateBlockValues['5'].szpkRegistrationDate,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Дата регистрации'}
                  controllerProps={getControllerProps({
                    name: registrationCertificateBlockValues['5'].szpkRegistrationDate,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </GroupWithPublic>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['5'].szpkSignDate,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['5'].szpkSignDate,
                      value,
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Дата подписания СЗПК последней из сторон"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['5'].szpkSignDate,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['5'].investorCapital,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['5'].investorCapital,
                      value,
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Объем капиталовложений по проекту"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['5'].investorCapital,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['5'].factMadeInvestorCapital,
                formFieldTooltipProps: {
                  isNew: true,
                },
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['5'].factMadeInvestorCapital,
                      value,
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Объем осуществленных капиталовложений"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['5'].factMadeInvestorCapital,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['5'].factRemainsInvestorCapital,
                formFieldTooltipProps: {
                  isNew: true,
                },
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['5'].factRemainsInvestorCapital,
                      value,
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Объем планируемых капиталовложений"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['5'].factRemainsInvestorCapital,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['5'].stabilizationPeriod,
                formFieldTooltipProps: {
                  isNew: true,
                },
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['5'].stabilizationPeriod,
                      value,
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Срок применения стабилизационной оговорки (лет)"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['5'].stabilizationPeriod,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <RegistrationCertificatePlannedTax
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
          handleChangePublicValue={handleChangePublicValue}
        />
        <RegistrationCertificateStepRegisteredDateValuesList
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
          subscribableControl={subscribableControl}
        />
      </Stack>
    </Container>
  )
}

export default Fifth
