import React, {
  FC,
  Fragment,
  HTMLAttributes,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Popover, PopoverProps, Position } from '@components/NewDesign/Popover'
import { mergeRefs } from '@helpers/ref/mergeRefs'
import cn from 'classnames'

import styles from './Tooltip.module.scss'

type Trigger = 'click' | 'hover'

type RefElement = HTMLDivElement | null

export type TooltipProps = {
  /**
   * Контент тултипа
   */
  content: ReactNode

  /**
   * Позиционирование тултипа
   */
  position?: Position

  /**
   * Задержка перед открытием тултипа
   */
  openDelay?: number

  /**
   * Задержка перед закрытием тултипа
   */
  closeDelay?: number

  /**
   * Обработчик открытия тултипа
   */
  onOpen?: () => void

  /**
   * Обработчик закрытия тултипа
   */
  onClose?: () => void

  /**
   * Событие, по которому происходит открытие тултипа
   */
  trigger?: Trigger

  /**
   * Если `true`, то тултип будет открыт
   */
  open?: boolean

  /**
   * Дочерние элементы тултипа.
   * При срабатывании событий на них, тултип будет открываться
   */
  children: ReactElement

  /**
   * Смещение тултипа
   */
  offset?: [number, number]

  /**
   * Функция, возвращающая контейнер, в который будет рендериться тултип
   */
  getPortalContainer?: () => HTMLElement

  /**
   * Дополнительный класс для стрелочки
   */
  arrowClassName?: string

  /**
   * Дополнительный класс для контента
   */
  contentClassName?: string

  /**
   * Дополнительный класс для поповера
   */
  popoverClassName?: string

  /**
   * Дополнительный класс для поппера
   */
  popperClassName?: string

  /**
   * Дополнительный класс для обертки над дочерними элементами
   */
  targetClassName?: string

  /**
   * Вид тултипа
   */
  view?: 'tooltip' | 'hint'

  /**
   * Хранит функцию, с помощью которой можно обновить положение компонента
   */
  updatePopover?: PopoverProps['update']

  /**
   * z-index компонента
   */
  zIndex?: number

  /**
   * Реф для обертки над дочерними элементами
   */
  targetRef?: MutableRefObject<HTMLElement | null>

  /**
   * Если тултип не помещается в переданной позиции (position), он попробует открыться в другой позиции,
   * по очереди для каждой позиции из этого списка.
   * Если не передавать, то тултип открывается в противоположном направлении от переданного position.
   */
  fallbackPlacements?: PopoverProps['fallbackPlacements']

  /**
   * Запрещает тултипу менять свою позицию, если он не влезает в видимую область.
   */
  preventOverflow?: PopoverProps['preventOverflow']

  /**
   *  Позволяет тултипу подствраивать свою высоту под границы экрана, если из-за величины контента он выходит за рамки видимой области экрана
   */
  availableHeight?: PopoverProps['availableHeight']

  /**
   *  Элемент, относительно которого будет позиционировать тултип.
   */
  anchor?: PopoverProps['anchorElement']

  useAnchorWidth?: PopoverProps['useAnchorWidth']

  contentProps?: HTMLAttributes<HTMLDivElement>
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  trigger = 'hover',
  closeDelay = 50,
  openDelay = 50,
  open: forcedOpen,
  offset = [0, 16],
  position,
  contentClassName,
  arrowClassName,
  popoverClassName,
  popperClassName,
  updatePopover,
  targetClassName,
  zIndex,
  onClose,
  onOpen,
  getPortalContainer,
  view = 'tooltip',
  targetRef = null,
  fallbackPlacements,
  preventOverflow = true,
  availableHeight = false,
  anchor = null,
  useAnchorWidth,
  contentProps,
}) => {
  const [visible, setVisible] = useState(!!forcedOpen)
  const [target, setTarget] = useState<HTMLElement | null>(null)

  const contentRef = useRef<RefElement>(null)
  const timer = useRef(0)

  const show = forcedOpen === undefined ? visible : forcedOpen

  const open = () => {
    if (!show) {
      setVisible(true)

      if (onOpen) {
        onOpen()
      }
    }
  }

  const close = useCallback(() => {
    if (show) {
      setVisible(false)

      if (onClose) {
        onClose()
      }
    }
  }, [onClose, show])

  const toggle = () => {
    if (show) {
      close()
    } else {
      open()
    }
  }

  const clickedOutside = useCallback(
    (node: Element): boolean => {
      if (target && target.contains(node)) {
        return false
      }

      return !(contentRef.current && contentRef.current.contains(node))
    },
    [target],
  )

  useEffect(() => {
    const handleBodyClick = (event: MouseEvent) => {
      const eventTarget = event.target as Element

      if (clickedOutside(eventTarget)) {
        close()
      }
    }

    document.body.addEventListener('click', handleBodyClick)

    return () => {
      document.body.removeEventListener('click', handleBodyClick)

      clearTimeout(timer.current)
    }
  }, [clickedOutside, close])

  const handleTargetClick = () => {
    toggle()
  }

  const handleMouseOver = () => {
    clearTimeout(timer.current)

    timer.current = window.setTimeout(() => {
      open()
    }, openDelay)
  }

  const handleMouseOut = () => {
    clearTimeout(timer.current)

    timer.current = window.setTimeout(() => {
      close()
    }, closeDelay)
  }

  const handleTouchStart = (event: React.TouchEvent<HTMLElement>) => {
    const eventTarget = event.target as Element

    clearTimeout(timer.current)

    if (clickedOutside(eventTarget)) {
      timer.current = window.setTimeout(() => {
        close()
      }, closeDelay)
    } else {
      open()
    }
  }

  const onClickProps = { onClick: handleTargetClick }

  const onHoverProps = {
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    onTouchStart: handleTouchStart,
  }

  const getTargetProps = (): HTMLAttributes<HTMLElement> => {
    const props = {
      className: cn(styles.target, targetClassName),
    }

    switch (trigger) {
      case 'click':
        return {
          ...props,
          ...onClickProps,
        }
      case 'hover':
        return {
          ...props,
          ...onHoverProps,
        }
      default:
        return {}
    }
  }

  const getContentProps = (): HTMLAttributes<HTMLElement> => {
    const props = {
      ...contentProps,
      ref: contentRef,
      className: cn(styles.component, contentClassName),
    }

    switch (trigger) {
      case 'hover':
        return {
          ...props,
          ...onHoverProps,
        }
      default:
        return props
    }
  }

  return (
    <Fragment>
      <div ref={mergeRefs([targetRef, setTarget])} {...getTargetProps()}>
        {children.props.disabled && <div className={styles.overlap} />}
        {children}
      </div>

      <Popover
        withArrow
        anchorElement={anchor || target}
        open={show}
        getPortalContainer={getPortalContainer}
        arrowClassName={cn(styles.arrowClassName, arrowClassName)}
        popperClassName={cn(styles.popper, styles[view], popperClassName)}
        className={popoverClassName}
        offset={offset}
        position={position}
        update={updatePopover}
        zIndex={zIndex}
        fallbackPlacements={fallbackPlacements}
        preventOverflow={preventOverflow}
        availableHeight={availableHeight}
        useAnchorWidth={useAnchorWidth}
      >
        <div {...getContentProps()}>{content}</div>
      </Popover>
    </Fragment>
  )
}
