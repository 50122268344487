import React, { FC, memo } from 'react'

import type { ICertificate } from '@components/Forms/LoginForm/types'

import UserCertificate from '../UserCertificate'

import styles from './DigitalSignatures.module.scss'

interface DigitalSignaturesProps {
  certificates: ICertificate[]
  onSelectCertificate: (certificate: ICertificate) => void
}

const DigitalSignatures: FC<DigitalSignaturesProps> = ({ certificates, onSelectCertificate }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.certificates}>
          {!!certificates?.length &&
            certificates.map((certificate: ICertificate) => (
              <UserCertificate
                key={certificate.id}
                className={styles.certificate}
                certificate={certificate}
                onSelect={onSelectCertificate}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default memo(DigitalSignatures)
