import React, { cloneElement, isValidElement, ReactNode, useEffect, useMemo } from 'react'
import { FieldValues, Path, useWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types'

import FormActionIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormActionIconWithTooltip'
import { isFunction, isUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './FormObjectTooltipControl.module.scss'

const formObjectTooltipControlTexts = {
  new: 'Новый элемент',
  changed: 'Один или несколько параметров были изменены',
}

interface ChildrenProps {
  leftAddons?: ReactNode
}

interface FormObjectTooltipControlProps<T extends FieldValues> {
  name: Path<T>
  control: Control<T>
  size?: 'm' | 's'
  children: ((props: ChildrenProps) => ReactNode) | ReactNode
  onDifference?: (path: Path<T> | string, isChanged: boolean) => void
}

const FormObjectTooltipControl = <T extends FieldValues>({
  size = 'm',
  control,
  name,
  children,
  onDifference,
}: FormObjectTooltipControlProps<T>) => {
  const isNew = useWatch({
    name: `${name}.isNew` as Path<T>,
    control,
  })

  const isChanged = useWatch({
    name: `${name}.isChanged` as Path<T>,
    control,
  })

  const tooltipContent: string = useMemo(() => {
    if (!!isChanged) return formObjectTooltipControlTexts.changed

    if (!!isNew) return formObjectTooltipControlTexts.new

    return ''
  }, [isChanged, isNew])

  useEffect(() => {
    if (isUndefined(onDifference)) return

    onDifference(name, isNew)

    return () => {
      onDifference(name, false)
    }
  }, [isNew, name])

  const leftAddonsElement = (
    <FormActionIconWithTooltip
      tooltipContent={tooltipContent}
      size={size}
      className={cn({
        [styles[`formObjectTooltipControl__icon--${size}`]]: size,
      })}
      tooltipProps={{
        offset: [40, 10],
      }}
    />
  )

  const renderChildren = () => {
    if (!isValidElement(children)) return children

    return cloneElement(children, {
      ...children.props,
      ...((isNew || isChanged) && {
        leftAddons: leftAddonsElement,
      }),
    })
  }

  if (isFunction(children))
    return children({
      leftAddons: isNew || isChanged ? leftAddonsElement : undefined,
    })

  return <>{renderChildren()}</>
}

export default FormObjectTooltipControl
