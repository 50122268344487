import { useCallback } from 'react'

import { generateFormModifierValuesForRegistrationCertificateForm } from '@components/Forms/RegistrationCertificateForm/adapters/Modifier.adapter'
import { generatePropertiesObjectForRegistrationCertificateForm } from '@components/Forms/RegistrationCertificateForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForRegistrationCertificateForm } from '@components/Forms/RegistrationCertificateForm/adapters/RHF.adapter'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'

const useRegistrationCertificateAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): RegistrationCertificateFormValues =>
      generateRHFObjectForRegistrationCertificateForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForRegistrationCertificateForm(objectFromPropertyState),
    [],
  )

  const generateFormModifierValues = useCallback(
    (objectFromPropertyState: Record<string, any>): RegistrationCertificateFormModifierValues =>
      generateFormModifierValuesForRegistrationCertificateForm(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generateFormModifierValues, generatePropertiesObject }
}

export { useRegistrationCertificateAdapters }
