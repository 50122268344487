import { type FC } from 'react'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import cn from 'classnames'

import styles from './ContainerWithBorder.module.scss'

interface ContainerWithBorderProps {
  disablePaddingTop?: boolean
  disablePaddingBottom?: boolean
}

const ContainerWithBorder: FC<ContainerWithBorderProps> = ({
  disablePaddingTop,
  disablePaddingBottom,
  children,
}) => {
  return (
    <Container className={'p-0'}>
      <Row
        className={cn(styles.containerWithBorder, {
          [styles['containerWithBorder--disablePaddingTop']]: disablePaddingTop,
          [styles['containerWithBorder--disablePaddingBottom']]: disablePaddingBottom,
        })}
      >
        {children}
      </Row>
      <Border />
    </Container>
  )
}

export default ContainerWithBorder
