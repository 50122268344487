import { FC } from 'react'

import First from './1'
import Second from './2'

interface AssignmentMonetaryOrPledgeAFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const AssignmentMonetaryOrPledgeAForms: AssignmentMonetaryOrPledgeAFormsProps = () => null

AssignmentMonetaryOrPledgeAForms.First = First
AssignmentMonetaryOrPledgeAForms.Second = Second

export default AssignmentMonetaryOrPledgeAForms
