import { useAPIContext } from '@context/APIContext'
import { AuditDictionaries } from '@context/APIContext/hooks/useOperatorApi'
import { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useAuditsDictionaries = ({ key, config }: SWRHookProps<AuditDictionaries>) => {
  const {
    operatorApi: { getAuditDictionaries },
  } = useAPIContext()

  const {
    data: auditsDictionaries,
    isValidating,
    error,
    mutate,
  } = useSWRImmutable(key, getAuditDictionaries, config)

  return {
    auditsDictionaries,
    isValidating,
    error,
    mutate,
  }
}

export default useAuditsDictionaries
