import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { expenseCheckerCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/8/ExpenseChecker/validation'
import { CertificateOnMonitoringResultsExpensesCheckerArrayPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/8/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CertificateOnMonitoringResultsExpenseCheckerProps {
  id: string
  name: CertificateOnMonitoringResultsExpensesCheckerArrayPathName
  title: string
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
  onRemove: VoidFunction
}

const CertificateOnMonitoringResultsExpenseChecker: FC<
  CertificateOnMonitoringResultsExpenseCheckerProps
> = ({ id, name, title, formInstance, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  return (
    <FieldView.StepTemplate id={id} title={title} onRemoveStep={onRemove}>
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.expenseNameChecker`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${name}.expenseNameChecker`,
                    rules:
                      expenseCheckerCertificateOnMonitoringResultsValidationMap.expenseNameChecker,
                    textareaProps: {
                      label: 'Направление расхода',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.expenseNameChecker`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.expenseNameChecker`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default CertificateOnMonitoringResultsExpenseChecker
