import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAChangingVolumeReimbursementBlockValues } from '@components/Forms/AAChangingVolumeReimbursementForm/const'
import OldAgreementLinkChangeItem from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/7/OldAgreementLinkChangeItem'
import { OldAgreementLinkChangesPathName } from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/7/types'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import {
  AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher,
  AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
} from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Seventh = () => {
  const formInstance = useFormContext<AAChangingVolumeReimbursementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useAAChangingVolumeReimbursementManager()

  const { fields: oldAgreementLinkChanges } = useFieldArraySubscribableControl<
    AAChangingVolumeReimbursementFormValues,
    OldAgreementLinkChangesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aAChangingVolumeReimbursementBlockValues['7'].oldAgreementLinkChanges,
    keyName: 'keyNameId',
    watcher: AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher,
  })

  const handleAddOldAgreementLinkChangeItem = useCallback(async () => {
    await handleAddItemToListWithOutValue?.(
      aAChangingVolumeReimbursementBlockValues['7'].oldAgreementLinkChanges,
    )
  }, [handleAddItemToListWithOutValue])

  const handleRemoveOldAgreementLinkChangeItem = useCallback(
    (index: number) => async () => {
      await handleRemoveItemFromList?.(
        `${aAChangingVolumeReimbursementBlockValues['7'].oldAgreementLinkChanges}.${index}`,
        aAChangingVolumeReimbursementBlockValues['7'].oldAgreementLinkChanges,
      )
    },
    [handleRemoveItemFromList],
  )
  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: aAChangingVolumeReimbursementBlockValues['7'].oldAgreementLinkChanges,
          })}
        >
          <FlipperList list={oldAgreementLinkChanges}>
            {oldAgreementLinkChanges.map((agreementLink, index) => {
              const formName =
                `${aAChangingVolumeReimbursementBlockValues['7'].oldAgreementLinkChanges}.${index}` as const

              return (
                <OldAgreementLinkChangeItem
                  key={agreementLink.id}
                  id={agreementLink.id}
                  formName={formName}
                  onRemoveOldAgreementLink={handleRemoveOldAgreementLinkChangeItem(index)}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddOldAgreementLinkChangeItem}>
              {({ wrappedPromise, isLoading }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Seventh
