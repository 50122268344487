import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import styles from '@components/DocumentFormComponents/FieldView/RowWithRemoveButton/RowWithRemoveButton.module.scss'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { compensationStatementPaidTaxValuesValidationMap } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/5/CompensationStatementPaidTax/CompensationStatementPaidTaxValuesList/validation'
import { ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/5/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import DeleteIcon from '@icons/DeleteIcon.svg'

interface ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesListProps {
  editMode: boolean
  blockViewIsValidating: boolean
  name: ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
}

const ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesList: FC<
  ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesListProps
> = ({ editMode, blockViewIsValidating, name, formInstance }) => {
  const {
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleChangeValue,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getSubscribableControlProps, getAmountInputProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
    })

  const { fields: paidTaxValues } = useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name,
    keyName: 'keyNameId',
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const handleAddPaidTaxValue = async () => {
    await handleAddItemToListWithOutValue?.(name)
  }

  const handleRemovePaidTaxValue = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  return (
    <Group disableBottomBorder title="Налоговые выплаты по годам">
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: name,
          })}
        >
          <FlipperList list={paidTaxValues}>
            {paidTaxValues.map((taxValue, index) => {
              const taxValueName = `${name}.${index}` as const

              return (
                <FlippedWithCollapse flipId={taxValue.id} key={taxValue.id}>
                  <Row>
                    <Col xs={3}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${taxValueName}.paidTaxYear`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${taxValueName}.paidTaxYear`,
                            calendarProps: {
                              typeOfPicker: 'years',
                              dateFormat: 'YYYY',
                            },
                            calendarInputProps: {
                              label: 'Год',
                              inputMask: YEAR_MASK,
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${taxValueName}.paidTaxYear`)
                              }, 0),
                            onCalendarChange: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${taxValueName}.paidTaxYear`)
                              }, 0),
                            onInputChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(`${taxValueName}.paidTaxYear`)
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${taxValueName}.paidTaxYearValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${taxValueName}.paidTaxYearValue`,
                            rules: compensationStatementPaidTaxValuesValidationMap.paidTaxYearValue,
                            inputProps: {
                              label: 'Объем за год',
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${taxValueName}.paidTaxYearValue`)
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(`${taxValueName}.paidTaxYearValue`)
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    {editMode && (
                      <AsyncWrapper promise={handleRemovePaidTaxValue(index)}>
                        {({ isLoading, wrappedPromise }) => (
                          <Col
                            xs={3}
                            className={styles['rowWithRemoveButton__removeButton-wrapper']}
                          >
                            <Button
                              fixWidth
                              disabled={isLoading}
                              variant={'buttonSMedium'}
                              size={'2xs'}
                              color={'negative'}
                              dataTestId={'deleteButton'}
                              view={'plain'}
                              loaderProps={{
                                loading: isLoading,
                                placement: 'trailing',
                                variant: 'lite',
                              }}
                              leadingIcon={{
                                noCurrentColorSvgFill: true,
                                src: DeleteIcon,
                              }}
                              onClick={wrappedPromise}
                            >
                              Удалить
                            </Button>
                          </Col>
                        )}
                      </AsyncWrapper>
                    )}
                  </Row>
                </FlippedWithCollapse>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddPaidTaxValue}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить налоги за год
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesList
