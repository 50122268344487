import { subsidyMemberReducerActionMap } from './constants'
import type {
  SubsidyMemberReducerAction,
  SubsidyMemberReducerActionCreators,
  SubsidyMemberReducerState,
} from './types'

const initialSubsidyMembersState: SubsidyMemberReducerState = {
  organization: null,
  isLoading: false,
  error: null,
}

const subsidyMembersReducer = (
  state: SubsidyMemberReducerState,
  action: SubsidyMemberReducerAction,
): SubsidyMemberReducerState => {
  const { type } = action

  switch (type) {
    case subsidyMemberReducerActionMap.SETUP_ORGANIZATION:
      return {
        ...state,
        isLoading: false,
        organization: action.payload,
      }

    case subsidyMemberReducerActionMap.SETUP_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case subsidyMemberReducerActionMap.SETUP_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

const subsidyMembersActionCreators: SubsidyMemberReducerActionCreators = {
  setError: (payload) => ({ type: subsidyMemberReducerActionMap.SETUP_ERROR, payload }),
  setLoading: (payload) => ({ type: subsidyMemberReducerActionMap.SETUP_LOADING, payload }),
  setOrganization: (payload) => ({
    type: subsidyMemberReducerActionMap.SETUP_ORGANIZATION,
    payload,
  }),
}

export { initialSubsidyMembersState, subsidyMembersActionCreators, subsidyMembersReducer }
