import { useCallback } from 'react'
import { FieldValues, Path, useFormContext } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  BatchTimeOrTimerHandler,
  useBatchFormActions,
} from '@components/DocumentFormComponents/hooks/useBatchFormActions'
import { useFormRequestManager } from '@components/DocumentFormComponents/hooks/useFormRequestManager'
import { AdditionalValueParams } from '@components/DocumentFormComponents/types'
import { objOfDateFormats } from '@constants/dateFormats'
import usePropertiesApi, {
  ChangePropertyBody,
  ChangePropertyBodyRequired,
} from '@context/APIContext/hooks/usePropertiesApi'
import { isActionRemovePropertyBodyStructure } from '@context/APIContext/hooks/usePropertiesApi/helpers'
import { filterArrayIntoTwoChunks } from '@helpers/array/filterArrayIntoChunks'
import { isArray, isNotEmptyString, isNull, isUndefined } from '@helpers/checkTypes'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { ChangeActionPropertyValueProps, PropertyActions } from '@services/Properties/actions'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'
import { nanoid } from 'nanoid'

const {
  changePropertyValue,
  changePublicValueType,
  addItemToListWithoutValue,
  addItemToListWithContent,
  addMultipleItemsToListWithContent,
  removeItemFromList,
  orderUpElementInList,
  orderDownElementInList,
  changePublicValue,
  changePublicValueInList,
} = PropertyActions

const { transformRHFPathInProperties, transformValueByTypeForSendToApi } = DocumentFormHelpers

interface UseFormUpdateProps<T extends FieldValues> {
  formId: string
  getPropertiesProps: () => Record<string, any> | null
  getRHFValueBeforeUpdate: () => T | null
  applyFormChanges?: () => Promise<void>
}

interface CustomValueChangeProps extends ChangeActionPropertyValueProps {
  type: keyof typeof PropertyTypeEnum
}

const useFormUpdate = <T extends FieldValues>({
  formId,
  getPropertiesProps,
  getRHFValueBeforeUpdate,
  applyFormChanges,
}: UseFormUpdateProps<T>) => {
  const { handleAddQueryRequest } = useFormRequestManager()
  const { checkActionToBatch } = useBatchFormActions({
    onBatchCreate: handleAddQueryRequest,
    onApplyFormChanges: applyFormChanges,
  })

  const { changeProperties } = usePropertiesApi()

  const sendValueToApi = useCallback(
    async (action: ChangePropertyBody | ChangePropertyBody[]) => {
      if (!formId) return

      const currentAction = isArray(action) ? action.flat() : [action]

      try {
        await changeProperties(formId, currentAction)
      } catch (e) {
        throw e
      }
    },
    [formId, changeProperties],
  )

  const formInstance = useFormContext<T>()
  const { getValues } = formInstance

  const processAction = useCallback(
    async (
      action: ChangePropertyBody,
      batchBlockId?: string | null,
      batchTimeOrTimerHandler?: BatchTimeOrTimerHandler,
    ) => {
      const generateIdToPromise =
        (!isArray(action) &&
          (isActionRemovePropertyBodyStructure(action)
            ? action.childElementId
            : action.property.id)) ||
        nanoid()

      const promiseToApply = {
        id: generateIdToPromise,
        promise: () => sendValueToApi(action),
      }

      //Action, который прервал сбор батча, или который не относится к нему в общем
      const notAppliedAction = checkActionToBatch(
        promiseToApply,
        batchBlockId,
        batchTimeOrTimerHandler,
      )

      //Добавляем его в очередь самостоятельно
      if (notAppliedAction) {
        await handleAddQueryRequest({
          id: nanoid(),
          promise: async () => {
            try {
              await notAppliedAction.promise()
            } catch {
            } finally {
              await applyFormChanges?.()
            }
          },
        })
      }
    },
    [applyFormChanges, checkActionToBatch, handleAddQueryRequest, sendValueToApi],
  )

  const handleChangeCustomValue = useCallback(
    async (
      propertyProps: CustomValueChangeProps,
      batchBlockId?: string,
      batchTimeOrTimerHandler?: BatchTimeOrTimerHandler,
      additionalValueParams?: AdditionalValueParams,
    ) => {
      const transformedValueToSend = transformValueByTypeForSendToApi(
        propertyProps.type,
        propertyProps.newValue,
        additionalValueParams,
      )

      const generatedAction = changePropertyValue({
        property: propertyProps.property,
        newValue: transformedValueToSend,
      })

      await processAction(generatedAction, batchBlockId, batchTimeOrTimerHandler)
    },
    [processAction],
  )

  const handleChangeValue = useCallback(
    async (
      pathName: Path<T>,
      batchBlockId?: string | null,
      batchTimeOrTimerHandler?: BatchTimeOrTimerHandler,
      additionalValueParams?: AdditionalValueParams,
    ) => {
      const beforeUpdateValue = getObjectValue(getRHFValueBeforeUpdate(), pathName)
      const updateValue = getObjectValue(getValues(), pathName)

      const propertyPropsPath = transformRHFPathInProperties(pathName)
      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)

      const conditionToNotValidDate =
        propertyProps?.type === PropertyTypeEnum.DATE &&
        isNotEmptyString(updateValue) &&
        !DayjsService.isDateValid(updateValue, objOfDateFormats.defaultFormat) &&
        !DayjsService.isDateValid(updateValue, objOfDateFormats.yearFormat.yearOnly)

      if (beforeUpdateValue === updateValue || isUndefined(updateValue) || conditionToNotValidDate)
        return

      if (!propertyProps || !Object.keys(propertyProps).length) return

      const transformedValueToSend = transformValueByTypeForSendToApi(
        propertyProps.type,
        updateValue,
        additionalValueParams,
      )

      const generatedAction = changePropertyValue({
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
        newValue: transformedValueToSend,
      })

      await processAction(generatedAction, batchBlockId, batchTimeOrTimerHandler)
    },
    [getPropertiesProps, getRHFValueBeforeUpdate, processAction, getValues],
  )

  const debouncedHandleChangeValue = useDebouncedCallback(handleChangeValue, 5000)

  const handleAddCustomValue = useCallback(
    async (
      propertyProps: ChangePropertyBodyRequired['property'],
      content?: Record<string, unknown> | unknown,
    ) => {
      const generatedAction = !content
        ? addItemToListWithoutValue({
            property: propertyProps,
          })
        : addItemToListWithContent({
            property: propertyProps,
            content,
          })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction],
  )

  const handleRemoveCustomValue = useCallback(
    async (propertyProps: ChangePropertyBodyRequired['property'], removePropertyId: string) => {
      const generatedAction = removeItemFromList({
        property: propertyProps,
        removePropertyId,
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction],
  )

  const handleAddItemToListWithOutValue = useCallback(
    async (pathName: string) => {
      const propertyPropsPath = transformRHFPathInProperties(pathName)

      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)
      if (!propertyProps) return

      const generatedAction = addItemToListWithoutValue({
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction, getPropertiesProps],
  )

  const handleAddItemToListWithValue = useCallback(
    async (pathName: Path<T>, content: Record<string, unknown> | unknown) => {
      const propertyPropsPath = transformRHFPathInProperties(pathName)

      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)
      if (!propertyProps) return

      const generatedAction = addItemToListWithContent({
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
        content,
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction, getPropertiesProps],
  )

  const handleAddMultipleItemsToListWithValue = useCallback(
    async (
      pathName: Path<T>,
      contents: (Record<string, unknown> | unknown)[],
      batchBlockId?: string,
      batchTimeOrTimerHandler?: BatchTimeOrTimerHandler,
    ) => {
      const propertyPropsPath = transformRHFPathInProperties(pathName)

      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)
      if (!propertyProps || !contents.length) return

      const generatedAction = addMultipleItemsToListWithContent({
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
        contents,
      })

      try {
        await processAction(generatedAction, batchBlockId, batchTimeOrTimerHandler)
      } catch (e) {
        throw e
      }
    },
    [getPropertiesProps, processAction],
  )

  const handleRemoveItemFromList = useCallback(
    async (pathName: Path<T>, parentPathName: Path<T>) => {
      const propertyPropsPath = transformRHFPathInProperties(pathName)
      const parentPropertyPropsPath = transformRHFPathInProperties(parentPathName)

      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)
      const parentPropertyProps = getObjectValue(getPropertiesProps(), parentPropertyPropsPath)
      if (!propertyProps || !parentPropertyProps) return

      const generatedAction = removeItemFromList({
        property: {
          id: parentPropertyProps.propertyId,
          lastUpdateDt: parentPropertyProps.lastUpdateDt,
        },
        removePropertyId: propertyProps.propertyId,
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction, getPropertiesProps],
  )

  const handleUpElementInList = useCallback(
    async (pathName: string) => {
      const propertyPropsPath = transformRHFPathInProperties(pathName)

      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)
      if (!propertyProps) return

      const generatedAction = orderDownElementInList({
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction, getPropertiesProps],
  )

  const handleDownElementInList = useCallback(
    async (pathName: string) => {
      const propertyPropsPath = transformRHFPathInProperties(pathName)

      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)
      if (!propertyProps) return

      const generatedAction = orderUpElementInList({
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction, getPropertiesProps],
  )

  const handleChangePublicValue = useCallback(
    async (pathName: string, newIsPublic: boolean) => {
      const propertyPropsPath = transformRHFPathInProperties(pathName)

      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)
      if (!propertyProps) return

      const generatedAction = changePublicValue({
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
        newIsPublic,
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction, getPropertiesProps],
  )

  // Используется для полей внутри лукапа
  const handleChangePublicValueType = useCallback(
    async (pathName: string, newIsPublic: boolean, memberFieldName: string) => {
      const propertyPropsPath = transformRHFPathInProperties(pathName)

      const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)
      if (!propertyProps) return

      const generatedAction = changePublicValueType({
        property: {
          id: propertyProps.propertyId,
          lastUpdateDt: propertyProps.lastUpdateDt,
        },
        memberFieldName,
        newIsPublic,
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [getPropertiesProps, processAction],
  )

  const handleChangePublicValueInList = useCallback(
    async (pathNames: string[], newIsPublic: boolean) => {
      if (!pathNames.length) return

      const propertiesProps = pathNames.map((pathName) => {
        const propertyPropsPath = transformRHFPathInProperties(pathName)
        const propertyProps = getObjectValue(getPropertiesProps(), propertyPropsPath)

        if (!propertyProps) return null

        return {
          property: {
            id: propertyProps.propertyId,
            lastUpdateDt: propertyProps.lastUpdateDt,
          },
        }
      })

      const [correctPropertiesProps, emptyPropertiesProps] = filterArrayIntoTwoChunks(
        propertiesProps,
        (property) => !isNull(property),
      )

      if (!!emptyPropertiesProps.length) return

      const generatedAction = changePublicValueInList({
        property: correctPropertiesProps,
        newIsPublic,
      })

      try {
        await processAction(generatedAction)
      } catch (e) {
        throw e
      }
    },
    [processAction, getPropertiesProps],
  )

  return {
    handleChangeValue,
    handleChangeCustomValue,
    handleAddCustomValue,
    handleAddItemToListWithOutValue,
    handleAddItemToListWithValue,
    handleAddMultipleItemsToListWithValue,
    handleRemoveItemFromList,
    handleRemoveCustomValue,
    handleDownElementInList,
    handleUpElementInList,
    sendValueToApi,
    handleChangePublicValue,
    handleChangePublicValueType,
    handleChangePublicValueInList,
    debouncedHandleChangeValue,
  }
}

export type { CustomValueChangeProps }
export { useFormUpdate }
