import { memo } from 'react'

import IconButton from '@components/NewDesign/IconButton'

import { PanelIconButtonProps } from './types'

const PanelIconButton = ({ icon, onClick, className }: PanelIconButtonProps) => (
  <IconButton
    accent={false}
    geometry={'round'}
    view={'basic'}
    size={'m'}
    className={className}
    icon={{
      src: icon,
      noCurrentColorSvgFill: true,
      size: 'initial',
    }}
    onClick={onClick}
  />
)

export default memo(PanelIconButton)
