import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import styles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { summaryReportOfMonitoringResultsEconomicSpheresValidationMap } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/4/EconomicSphereCollapse/validation'
import { SummaryReportOfMonitoringResultsEconomicSphereOkvedsPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/4/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import Button from '@components/NewDesign/Button'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { useBooleanState } from '@hooks/useBooleanState'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

import SummaryReportOfMonitoringResultsEconomicSphereOkvedItem from '../EconomicSphereOkvedsList'
import { SummaryReportOfMonitoringResultsEconomicSpheresArrayPathName } from '../types'

const { getEconomicSphereTitle } = DocumentFormHelpers

interface SummaryReportOfMonitoringResultsEconomicSphereCollapseProps {
  blockViewIsValidating: boolean
  expandedRenderMode: boolean
  editMode: boolean
  parentId: string
  objectId: string
  indexOfObject: number
  name: SummaryReportOfMonitoringResultsEconomicSpheresArrayPathName
  formInstance: UseFormReturn<SummaryReportOfMonitoringResultsFormValues>
  onDeleteObject: VoidFunction
}

const SummaryReportOfMonitoringResultsEconomicSphereCollapse: FC<
  SummaryReportOfMonitoringResultsEconomicSphereCollapseProps
> = ({
  blockViewIsValidating,
  expandedRenderMode,
  editMode,
  parentId,
  objectId,
  indexOfObject,
  name,
  formInstance,
  onDeleteObject,
}) => {
  const {
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithValue,
      handleRemoveItemFromList,
    },
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const { getSubscribableControlProps, getTextareaProps, getControllerProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
    })

  const { fields: economicSphereOkveds } = useFieldArraySubscribableControl<
    SummaryReportOfMonitoringResultsFormValues,
    SummaryReportOfMonitoringResultsEconomicSphereOkvedsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${name}.economicSphereOkvedsFederalReport`,
    keyName: 'keyNameId',
    watcher: SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const {
    booleanState: isShowObjectItemContent,
    reverseBooleanState: toggleShowObjectItemContent,
  } = useBooleanState(expandedRenderMode)

  const handleDeleteObject = async () => {
    await onDeleteObject()
  }

  const handleAddSubObject = async () => {
    await handleAddItemToListWithValue?.(`${name}.economicSphereOkvedsFederalReport`, {
      isSubObject: true,
      parentSubObject: objectId,
    })
  }

  const handleDeleteSubObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.economicSphereOkvedsFederalReport.${index}`,
      `${name}.economicSphereOkvedsFederalReport`,
    )
  }

  return (
    <FlippedWithCollapse flipId={objectId}>
      <Row id={parentId}>
        <Col xs={12}>
          <div className={cn(styles.objectItem, styles['objectItem--economicSphere'])}>
            <div className={styles.objectItem__header}>
              <ObjectStepWrapperHeaderNode
                buttonProps={{
                  onClick: toggleShowObjectItemContent,
                  trailingIcon: {
                    className: cn({
                      [styles['objectItem__header-button__icon--active']]: isShowObjectItemContent,
                    }),
                  },
                }}
              >
                {getEconomicSphereTitle(indexOfObject + 1)}
              </ObjectStepWrapperHeaderNode>

              {editMode && (
                <AsyncWrapper promise={handleDeleteObject}>
                  {({ isLoading, wrappedPromise }) => (
                    <Button
                      disabled={isLoading}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      dataTestId={'deleteButton'}
                      color={'negative'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      leadingIcon={{
                        noCurrentColorSvgFill: true,
                        src: DeleteIcon,
                      }}
                      onClick={wrappedPromise}
                    >
                      Удалить
                    </Button>
                  )}
                </AsyncWrapper>
              )}
            </div>
            <div className={styles.objectItem__content}>
              {!isShowObjectItemContent && (
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.economicSphereNameFederalReport`,
                      })}
                    >
                      <ControlledDocumentDataView
                        suptitle={'Наименование сферы реализации'}
                        controllerProps={getControllerProps({
                          name: `${name}.economicSphereNameFederalReport`,
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
              <CollapseWrapper
                defaultExpanded={isShowObjectItemContent}
                isExpanded={isShowObjectItemContent}
              >
                <Stack direction={'vertical'} gap={3}>
                  <Row>
                    <Col xs={12}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.economicSphereNameFederalReport`,
                        })}
                      >
                        <ControlledTextarea
                          {...getTextareaProps({
                            name: `${name}.economicSphereNameFederalReport`,
                            rules:
                              summaryReportOfMonitoringResultsEconomicSpheresValidationMap.economicSphereNameFederalReport,
                            textareaProps: {
                              label: 'Наименование сферы реализации',
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.economicSphereNameFederalReport`)
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${name}.economicSphereNameFederalReport`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                </Stack>
                <Stack direction={'vertical'} gap={3}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.economicSphereOkvedsFederalReport`,
                    })}
                  >
                    <FlipperList list={economicSphereOkveds}>
                      {economicSphereOkveds.map((okved, index) => {
                        const formName =
                          `${name}.economicSphereOkvedsFederalReport.${index}` as const

                        return (
                          <SummaryReportOfMonitoringResultsEconomicSphereOkvedItem
                            expandedRenderMode={false}
                            subObjectId={okved.id}
                            key={okved.id}
                            indexOfObject={index}
                            name={formName}
                            blockViewIsValidating={blockViewIsValidating}
                            editMode={editMode}
                            formInstance={formInstance}
                            onDeleteSubObject={handleDeleteSubObject(index)}
                          />
                        )
                      })}
                    </FlipperList>
                  </SubscribableControl>
                  {editMode && (
                    <Row className={'px-1'}>
                      <Col xs={12}>
                        <AsyncWrapper promise={handleAddSubObject}>
                          {({ isLoading, wrappedPromise }) => {
                            return (
                              <Button
                                disabled={isLoading}
                                leadingIcon={{ src: CircleAddIcon }}
                                variant={'buttonSMedium'}
                                size={'2xs'}
                                view={'plain'}
                                dataTestId={'addSubObject'}
                                className={styles['objectItem__action-button']}
                                loaderProps={{
                                  loading: isLoading,
                                  placement: 'trailing',
                                  variant: 'lite',
                                }}
                                onClick={wrappedPromise}
                              >
                                Добавить ОКВЭД
                              </Button>
                            )
                          }}
                        </AsyncWrapper>
                      </Col>
                    </Row>
                  )}
                </Stack>
              </CollapseWrapper>
            </div>
          </div>
        </Col>
      </Row>
    </FlippedWithCollapse>
  )
}

export default SummaryReportOfMonitoringResultsEconomicSphereCollapse
