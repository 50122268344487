import { useCallback } from 'react'

import { DependentUrls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'

import {
  CreateReferenceBookProps,
  DeleteReferenceBookProps,
  GetReferenceBookByCodeProps,
  GetReferenceBookByCodeReturnType,
  GetReferenceBookByIdProps,
  GetReferenceBookChildsByIdReturnType,
  GetReferenceBooksProps,
  GetReferenceBooksReturnType,
  UpdateReferenceBookProps,
} from './types'

const useReferenceBooksApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const getReferenceBooks = useCallback(
    async ({
      dictionaryName,
      searchString,
      id,
      signal,
    }: GetReferenceBooksProps): Promise<GetReferenceBooksReturnType> => {
      try {
        const { data } = await FetcherWithInterceptors.get<GetReferenceBooksReturnType>({
          url: {
            url: DependentUrls.ReferenceBooks,
            params: {
              dictionaryName,
            },
          },
          config: {
            signal,
            params: {
              searchString,
              id,
            },
          },
        })

        return data
      } catch (error) {
        handleErrorWithPopup(error, true)

        throw error
      }
    },
    [],
  )

  const getReferenceBookByCode = useCallback(
    async ({
      dictionaryName,
      code,
      fetcherOptions,
    }: GetReferenceBookByCodeProps): Promise<GetReferenceBookByCodeReturnType> => {
      try {
        const { data } = await FetcherWithInterceptors.get<GetReferenceBookByCodeReturnType>({
          url: {
            url: DependentUrls.ReferenceBookByCode,
            params: {
              dictionaryName,
            },
          },
          config: {
            params: {
              code,
            },
          },
        })

        return data
      } catch (error) {
        if (fetcherOptions?.disableErrorHandling) throw error

        handleErrorWithPopup(error, true)

        throw error
      }
    }, []
  )

  const getReferenceBookChildsById = useCallback(async ({
    dictionaryName,
    id,
  }: GetReferenceBookByIdProps): Promise<GetReferenceBookChildsByIdReturnType> => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetReferenceBookChildsByIdReturnType>({
        url: {
          url: DependentUrls.ReferenceBooksChildsById,
          params: {
            dictionaryName,
            id,
          },
        },
      })
      return data
    } catch (e) {
      throw e
    }
  }, [])

  const createReferenceBook = useCallback(async ({
    dictionaryName,
    newReferenceBookBody,
  }: CreateReferenceBookProps): Promise<string> => {
    try {
      const response = await FetcherWithInterceptors.post<string>({
        url: {
          url: DependentUrls.ReferenceBooks,
          params: {
            dictionaryName,
          },
        },
        data: newReferenceBookBody,
      })
      return response.data
    } catch (error) {
      throw error
    }
  }, [])

  const updateReferenceBook = useCallback(async ({
    dictionaryName,
    updatedReferenceBookBody,
  }: UpdateReferenceBookProps): Promise<string> => {
    const { id, ...restUpdatedReferenceBookBody } = updatedReferenceBookBody

    try {
      const response = await FetcherWithInterceptors.put<string>({
        url: {
          url: DependentUrls.ReferenceBooksById,
          params: {
            dictionaryName,
            id,
          },
        },
        data: restUpdatedReferenceBookBody,
      })

      return response.data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }, [])

  const deleteReferenceBook = useCallback(async ({
    dictionaryName,
    id,
  }: DeleteReferenceBookProps): Promise<void> => {
    try {
      await FetcherWithInterceptors.delete({
        url: {
          url: DependentUrls.ReferenceBooksById,
          params: {
            dictionaryName,
            id,
          },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }, [])

  return {
    getReferenceBooks,
    getReferenceBookChildsById,
    createReferenceBook,
    updateReferenceBook,
    deleteReferenceBook,
    getReferenceBookByCode,
  }
}

export { useReferenceBooksApi }
