import { FC } from 'react'

import ActionButton from '@components/DocumentFormComponents/FieldView/ActionButton'
import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import ContainerWithPublic from '@components/DocumentFormComponents/FieldView/ContainerWithPublic'
import ContainerWithSwitchTransition from '@components/DocumentFormComponents/FieldView/ContainerWithSwitchTransition'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import RowWithRemoveButton from '@components/DocumentFormComponents/FieldView/RowWithRemoveButton'
import StepTemplate from '@components/DocumentFormComponents/FieldView/StepTemplate'

interface FieldViewProps extends FC {
  RowWithBottomBorder: typeof RowWithBorder
  RowWithRemoveButton: typeof RowWithRemoveButton
  ContainerWithBorder: typeof ContainerWithBorder
  ContainerWithPublic: typeof ContainerWithPublic
  ContainerWithSwitchTransition: typeof ContainerWithSwitchTransition
  StepTemplate: typeof StepTemplate
  ActionButton: typeof ActionButton
}

const FieldView: FieldViewProps = () => null

FieldView.RowWithBottomBorder = RowWithBorder
FieldView.StepTemplate = StepTemplate
FieldView.ActionButton = ActionButton
FieldView.RowWithRemoveButton = RowWithRemoveButton
FieldView.ContainerWithBorder = ContainerWithBorder
FieldView.ContainerWithPublic = ContainerWithPublic
FieldView.ContainerWithSwitchTransition = ContainerWithSwitchTransition

export default FieldView
