import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectsFormValues,
  IConstructionPermission,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const ePermissionAAgreementOnCreationOtherEstateValidationMap: FormValuesValidationSection<
  keyof IConstructionPermission,
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  permissionKind: {
    required: defaultRHFValidation.required,
  },
  permissionOrganization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  permissionNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  receivedPermissionDate: {
    required: defaultRHFValidation.required,
  },
}

export { ePermissionAAgreementOnCreationOtherEstateValidationMap }
