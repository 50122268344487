import { forwardRef, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import { mergeRefs } from '@helpers/ref/mergeRefs'
import { useLayoutEffect } from '@hooks/new/collapse/utils'
import { useFocus } from '@hooks/useFocus'
import cn from 'classnames'

import FormControl from '../FormControl'
import { defineTypeError } from '../Input/Input'

import styles from './Textarea.module.scss'
import { TextareaProps } from './types'

export const getDefaultCounterText = (textLength: number, maxLength = 0): string =>
  `${textLength} / ${maxLength}`

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      autosize = true,
      fixWidth = false,
      clear,
      disabled,
      autoComplete = 'on',
      hideMaxLengthCounter,
      rows = autosize ? 1 : 3,
      caption,
      className,
      defaultValue,
      error,
      fieldClassName,
      rootClassName,
      captionClassName,
      labelClassName,
      labelTextClassName,
      labelWrapperClassName,
      label,
      leftAddons,
      labelLeftAddons,
      labelRightAddons,
      maxHeight,
      maxLength,
      maxRows,
      minRows,
      resize = 'vertical',
      size = 'xl',
      rightAddons,
      textareaClassName,
      value,

      inputContainerRef,
      textAreaInactive,

      onFocus,
      onBlur,
      onClear,
      onChange,
      onHeightChange,
      dataTestId,
      id,
      ...restProps
    },
    ref,
  ) => {
    const uncontrolled = value === undefined

    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const containerRef = useRef<HTMLDivElement>(null)

    const [focused, setFocused] = useState(false)
    const [stateValue, setStateValue] = useState(defaultValue || '')

    const [focusVisible] = useFocus(textareaRef, 'keyboard')

    //Данный код исключительно для textAreaAutosize, которая монтирует свой компонент без знаний высоты.
    // Происходит из-за портала хедлесса или модалки его же.
    const [isRendered, setIsRenderer] = useState(false)
    useLayoutEffect(() => {
      setTimeout(() => {
        setIsRenderer(true)
      }, 0)
    }, [])

    const filled = Boolean(uncontrolled ? stateValue : value)

    const captionText = error ? defineTypeError(error) : caption

    // отображаем крестик только для заполненного и активного инпута
    const clearButtonVisible = clear && filled && focused && !disabled

    // Хак, так как react-textarea-autosize перестал поддерживать maxHeight
    useEffect(() => {
      if (autosize && maxHeight && textareaRef.current && textareaRef.current.style) {
        textareaRef.current.style.maxHeight = `${maxHeight}px`
      }
    }, [textareaRef, autosize, maxHeight])

    const handleTextareaFocus = useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setFocused(true)

        onFocus?.(event)
      },
      [onFocus],
    )

    const handleTextareaBlur = useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        if (textareaRef.current && containerRef.current?.contains(event.relatedTarget)) {
          return textareaRef.current.focus()
        }

        setFocused(false)

        onBlur?.(event)
      },
      [onBlur],
    )

    const handleTextareaChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event, { value: event.target.value })

        if (uncontrolled) {
          setStateValue(event.target.value)
        }
      },
      [onChange, uncontrolled],
    )

    const handleClear = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (!clearButtonVisible) return

        if (uncontrolled) {
          setStateValue('')
        }

        onClear?.(event)

        if (!focused) return textareaRef.current?.focus()
      },
      [clearButtonVisible, onClear, focused, uncontrolled],
    )

    const handleClickToFocusInput = (e) => {
      e.stopPropagation()

      if (!focused) return textareaRef.current?.focus()
    }

    const getValueLength = (): number => {
      if (uncontrolled) {
        return stateValue.length
      }

      return (value as string).length
    }

    const textareaProps = {
      ...restProps,
      disabled,
      className: cn(
        styles.textarea,
        styles[size],
        {
          [styles.hasLabel]: label,
          [styles.filled]: filled,
          [styles.resizeVertical]: resize === 'vertical',
          [styles.autosize]: autosize,
        },
        textareaClassName,
      ),
      autoComplete,
      value: uncontrolled ? stateValue : value,
      rows,
      maxLength,
      ref: mergeRefs([ref, textareaRef]),
      onFocus: handleTextareaFocus,
      onChange: handleTextareaChange,
      onBlur: handleTextareaBlur,
    }

    if (!isRendered) return null

    return (
      <FormControl
        disabled={disabled}
        clear={clearButtonVisible}
        labelTextClassName={labelTextClassName}
        labelWrapperClassName={labelWrapperClassName}
        labelRightAddons={labelRightAddons}
        labelLeftAddons={labelLeftAddons}
        filled={filled}
        fixWidth={fixWidth}
        focused={focused}
        view="secondary"
        className={className}
        rootClassName={rootClassName}
        captionClassName={captionClassName}
        labelClassName={labelClassName}
        label={label}
        leftAddons={leftAddons}
        rightAddons={rightAddons}
        id={id}
        dataTestId={dataTestId}
        size={size}
        error={error}
        caption={captionText}
        ref={containerRef}
        inputContainerRef={inputContainerRef}
        fieldClassName={cn(fieldClassName, {
          [styles.focusVisible]: focusVisible,
        })}
        textareaCounter={
          maxLength && hideMaxLengthCounter
            ? getDefaultCounterText(getValueLength(), maxLength)
            : ''
        }
        onClear={handleClear}
      >
        <div
          role={'presentation'}
          tabIndex={0}
          id={id}
          className={cn(styles.textareaWrapper, {
            [styles[`${size}`]]: size,
          })}
          onClick={handleClickToFocusInput}
        >
          {autosize ? (
            <TextareaAutosize
              {...textareaProps}
              data-testid={dataTestId}
              disabled={textAreaInactive || textareaProps.disabled}
              maxRows={maxRows}
              minRows={minRows}
              onHeightChange={onHeightChange}
            />
          ) : (
            <textarea
              {...textareaProps}
              data-testid={dataTestId}
              disabled={textAreaInactive || textareaProps.disabled}
              style={{ maxHeight }}
            />
          )}
        </div>
      </FormControl>
    )
  },
)

export default Textarea
