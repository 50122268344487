import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { fifthSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/5/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isArray, isObject } from '@helpers/checkTypes'
import { IRegionsSetupPropsObject } from '@services/Properties/Properties.entity'

const Fifth = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { debouncedHandleChangeValue, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getInputProps,
    getTextareaProps,
    getCalendarInputProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const economicSphere = useWatch({
    name: projectSZPKBlockValues['5'].economicSphere,
  })

  if (!formInstance) return null

  const isOtherEconomicSphereInputAvailable = economicSphere?.displayValue === 'Иная сфера'

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['5'].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Наименование инвестиционного проекта"
                controllerProps={getControllerProps({
                  name: projectSZPKBlockValues['5'].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['5'].projectRegion,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => {
                        if (!isArray(item)) return null

                        const [itemKey, itemValue] = item

                        return {
                          displayValue: itemValue || '',
                          value: itemKey,
                        }
                      },
                      transformOptionsData: (data) =>
                        isObject<IRegionsSetupPropsObject>(data) && isObject(data.filteredRegions)
                          ? Object.entries(data.filteredRegions)
                          : data,
                      controllerProps: {
                        name: projectSZPKBlockValues['5'].projectRegion,
                      },
                      selectProps: {
                        withContextSearch: true,
                        inputProps: {
                          label: 'Территория реализации инвестиционного проекта',
                          leftAddons: (
                            <FormIconWithTooltip tooltipContent="Поле заполняется, если наименование проекта не содержит наименование субъекта РФ" />
                          ),
                        },
                        onChangeFormValue: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${projectSZPKBlockValues['5'].projectRegion}`)
                          }, 0),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['5'].economicSphere,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (catalog) => ({
                        displayValue: (catalog.code || '') + catalog.name,
                        value: catalog.id,
                      }),
                      controllerProps: {
                        name: projectSZPKBlockValues['5'].economicSphere,
                        rules: fifthSectionValidationMap.economicSphere,
                      },
                      selectProps: {
                        withContextSearch: true,
                        
                        inputProps: {
                          label: 'Сфера экономики, в которой реализуется проект',
                          dataTestId: 'economicSphere',
                        },
                        onChangeFormValue: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${projectSZPKBlockValues['5'].economicSphere}`)
                          }, 0),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <CollapseWrapper isExpanded={isOtherEconomicSphereInputAvailable}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: projectSZPKBlockValues['5'].otherEconomicSphere,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: projectSZPKBlockValues['5'].otherEconomicSphere,
                    rules: fifthSectionValidationMap.otherEconomicSphere,
                    textareaProps: {
                      label: 'Описание иной сферы экономики',
                      dataTestId: 'otherEconomicSphere',
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent="При указании рекомендуется ориентироваться на выбранный ОКВЭД" />
                      ),
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${projectSZPKBlockValues['5'].otherEconomicSphere}`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          `${projectSZPKBlockValues['5'].otherEconomicSphere}`,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </CollapseWrapper>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['5'].projectPurpose,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: projectSZPKBlockValues['5'].projectPurpose,
                  rules: fifthSectionValidationMap.projectPurpose,
                  inputProps: {
                    label: 'Цель реализации инвестиционного проекта',
                    dataTestId: 'projectPurpose',
                    leftAddons: (
                      <FormIconWithTooltip tooltipContent="Например, извлечение прибыли, достижение иного полезного эффекта, в том числе предотвращение или минимизация негативного влияния на окружающую среду" />
                    ),
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${projectSZPKBlockValues['5'].projectPurpose}`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${projectSZPKBlockValues['5'].projectPurpose}`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <RowWithBorder disableBottomDefaultStyles title={'Дата начала проекта'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: projectSZPKBlockValues['5'].projectStartDate,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: projectSZPKBlockValues['5'].projectStartDate,
                calendarInputProps: {
                  dataTestId: 'projectStartDate',
                },
                rules: fifthSectionValidationMap.projectStartDate,
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${projectSZPKBlockValues['5'].projectStartDate}`)
                  }, 0),
                onCalendarChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${projectSZPKBlockValues['5'].projectStartDate}`)
                  }, 0),
                onInputChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${projectSZPKBlockValues['5'].projectStartDate}`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <Group disableBottomBorder title={'Реализация инвестиционного проекта осуществляется'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['5'].projectDecisionDepartmentGenitive,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: projectSZPKBlockValues['5'].projectDecisionDepartmentGenitive,
                      rules: fifthSectionValidationMap.projectDecisionDepartmentGenitive,
                      textareaProps: {
                        label: 'На основании',
                        dataTestId: 'projectDecisionDepartmentGenitive',
                        caption: 'в родительном падеже',
                        leftAddons: (
                          <FormIconWithTooltip tooltipContent="Указывается наименование и реквизиты решения уполномоченного органа, определенного в соответствии с учредительными документами (уставом) ОРП" />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            `${projectSZPKBlockValues['5'].projectDecisionDepartmentGenitive}`,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${projectSZPKBlockValues['5'].projectDecisionDepartmentGenitive}`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>

        <Group disableBottomBorder title={'Капиталовложения'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title={'Общий объем капитальных вложений (без НДС)'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['5'].totalCapital,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: projectSZPKBlockValues['5'].totalCapital,
                        rules: fifthSectionValidationMap.totalCapital,
                        inputProps: {
                          dataTestId: 'totalCapital',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${projectSZPKBlockValues['5'].totalCapital}`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${projectSZPKBlockValues['5'].totalCapital}`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder title={'Общий объем капиталовложений (без НДС)'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['5'].investorCapital,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: projectSZPKBlockValues['5'].investorCapital,
                        inputProps: {
                          dataTestId: 'totalCapital',
                          disabled: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title={
                    'Объем капиталовложений, осуществленных на момент заключения соглашения (без НДС)'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['5'].madeInvestorCapital,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: projectSZPKBlockValues['5'].madeInvestorCapital,
                        inputProps: {
                          dataTestId: 'madeInvestorCapital',
                        },
                        rules: fifthSectionValidationMap.madeInvestorCapital,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${projectSZPKBlockValues['5'].madeInvestorCapital}`,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${projectSZPKBlockValues['5'].madeInvestorCapital}`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder title={'Оставшийся объем капиталовложений (без НДС)'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['5'].remainsInvestorCapital,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: projectSZPKBlockValues['5'].remainsInvestorCapital,
                        inputProps: {
                          dataTestId: 'remainsInvestorCapital',
                          disabled: true,
                          leftAddons: (
                            <FormIconWithTooltip
                              tooltipContent={
                                'Оставшийся объем капиталовложений будет посчитан автоматически на основе сведений об объеме капиталовложений, указанных в форме "Проект СЗПК"'
                              }
                            />
                          ),
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${projectSZPKBlockValues['5'].remainsInvestorCapital}`,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${projectSZPKBlockValues['5'].remainsInvestorCapital}`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title={'Срок осуществления капиталовложений в полном объеме'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['5'].madeInvestorCapitalDate,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: projectSZPKBlockValues['5'].madeInvestorCapitalDate,
                        calendarInputProps: {
                          dataTestId: 'madeInvestorCapitalDate',
                        },
                        rules: fifthSectionValidationMap.madeInvestorCapitalDate,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${projectSZPKBlockValues['5'].madeInvestorCapitalDate}`,
                            )
                          }, 0),
                        onCalendarChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${projectSZPKBlockValues['5'].madeInvestorCapitalDate}`,
                            )
                          }, 0),
                        onInputChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${projectSZPKBlockValues['5'].madeInvestorCapitalDate}`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Планируемый объем возмещения затрат'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title={'на обеспечивающую инфраструктуру'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['5'].projectSupportCompensation,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: projectSZPKBlockValues['5'].projectSupportCompensation,
                        inputProps: {
                          dataTestId: 'projectSupportCompensation',
                        },
                        rules: fifthSectionValidationMap.projectSupportCompensation,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${projectSZPKBlockValues['5'].projectSupportCompensation}`,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${projectSZPKBlockValues['5'].projectSupportCompensation}`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder disableBottomDefaultStyles title={'на сопутствующую инфраструктуру'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['5'].projectRelatedCompensation,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: projectSZPKBlockValues['5'].projectRelatedCompensation,
                        inputProps: {
                          dataTestId: 'projectRelatedCompensation',
                        },
                        rules: fifthSectionValidationMap.projectRelatedCompensation,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${projectSZPKBlockValues['5'].projectRelatedCompensation}`,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${projectSZPKBlockValues['5'].projectRelatedCompensation}`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default Fifth
