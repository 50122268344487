import { useAPIContext } from '@context/APIContext'
import { downloadFile } from '@helpers/fileHelpers'

interface DownloadPropsNew {
  fileId: string
  versionId: string
  fileNameWithExtension: string

  projectId: string

  documentSetId: string

  type: 'save' | 'open'

  withSigned: boolean
}

const useDownloadFile = () => {
  const {
    webFormApi: { getFormCommitedFile },
    documentsApi: { downloadDocumentById },
  } = useAPIContext()

  const download = ({
    fileId,
    versionId,
    fileNameWithExtension,
    type = 'save',
    documentSetId,
    projectId,
    withSigned,
  }: DownloadPropsNew) => {
    return downloadDocumentById(
      {
        projectId,
        name: fileNameWithExtension,
        documentId: fileId,
        versionId,
        documentSetId,
        withSigned,
      },
      type,
    )
  }

  const downloadFormCommitedFile = async (formId: string, documentType: string) => {
    try {
      const { data: blob, headers } = await getFormCommitedFile(formId, documentType)

      downloadFile(blob, headers)
    } catch (e) {
      throw e
    }
  }

  return { download, downloadFormCommitedFile }
}

export default useDownloadFile
