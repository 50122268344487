import React, { DetailedHTMLProps, FC, HTMLAttributes, memo, useMemo } from 'react'

import DataView from '@components/Projects/[id]/ParticipantsOfStatement/DataView'
import { isString } from '@helpers/checkTypes'
import { useDfoAttributes } from '@hooks/new/swr/useDfoAttributes'
import { useReorganizationForm } from '@hooks/new/swr/useReorganizationForm'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import styles from './ReorganizationType.module.scss'

interface ReorganizationTypeAdditionProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  projectId: string
  dfoId: string
}

const ReorganizationTypeAddition: FC<ReorganizationTypeAdditionProps> = ({
  projectId,
  dfoId,
  ...divRest
}) => {
  const { dfoAttributes, mapOfAttributes } = useDfoAttributes({
    key: {
      projectId,
      dfoId,
      _key: 'dfoAttributes',
    },
    config: {
      isPaused: () => !projectId || !dfoId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ReorganizationTypeAddition',
          moduleName: 'DocumentsView',
        },
      }),
    },
  })

  const { mapOfReorganizationForm } = useReorganizationForm({
    key: 'reorganizationForm',
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ReorganizationTypeAddition',
          moduleName: 'DocumentsView',
        },
      }),
    },
  })

  const orgReorganizationType = useMemo(() => {
    if (!dfoAttributes || !mapOfAttributes) return

    return mapOfAttributes['orgReorganizationType']
  }, [dfoAttributes, mapOfAttributes])

  return (
    <div {...divRest} className={cn(styles.reorganizationType, divRest.className)}>
      {mapOfReorganizationForm && isString(orgReorganizationType) && (
        <DataView suptitle={'Форма реорганизации'}>
          {mapOfReorganizationForm[orgReorganizationType] ?? 'Не найдена'}
        </DataView>
      )}
    </div>
  )
}

export default memo(ReorganizationTypeAddition)
