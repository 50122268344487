import { useCallback } from 'react'

import { getDfoByType } from '@components/Projects/[id]/helpers'
import { DfosType } from '@constants/types'
import { SWRHookProps } from '@interfaces'
import { TAllDfoTypes } from '@services/Dfo/Dfo.entity'
import DfoService from '@services/Dfo/Dfo.service'
import { useSWRConfig } from 'swr'

const useUpdateDataAfterDocumentActions = (projectId: string, dfoId: string) => {
  const { mutate } = useSWRConfig()

  const updateSwrHook = useCallback(
    async (key: SWRHookProps['key']) => {
      try {
        return await mutate(key)
      } catch (e) {
        throw e
      }
    },
    [mutate],
  )

  const updateProjectDfos = useCallback(
    () =>
      updateSwrHook({
        projectId,
        _key: 'projectDfos',
      }),
    [projectId, updateSwrHook],
  )

  const updateProject = useCallback(
    () =>
      updateSwrHook({
        projectId,
        _key: 'project',
      }),
    [projectId, updateSwrHook],
  )

  const updateProjectMembers = useCallback(
    () =>
      updateSwrHook({
        projectId,
        _key: 'projectMembers',
      }),
    [projectId, updateSwrHook],
  )

  const updateProjectAttributes = useCallback(
    () => updateSwrHook({ projectId, _key: 'projectAttributes' }),
    [projectId, updateSwrHook],
  )

  const updateProjectActions = useCallback(
    () =>
      updateSwrHook({
        projectId,
        _key: 'projectActions',
      }),
    [projectId, updateSwrHook],
  )

  const updateProjectVersions = useCallback(
    () => updateSwrHook({ dfoId, projectId, _key: 'versions' }),
    [dfoId, projectId, updateSwrHook],
  )

  const updateCurrentDfoDocuments = useCallback(
    () => updateSwrHook({ dfoId, projectId, _key: 'document-sets' }),
    [dfoId, projectId, updateSwrHook],
  )

  const updateAvailableForCreateDfo = useCallback(
    () =>
      updateSwrHook({
        projectId,
        _key: 'availableDfoForCreate',
      }),
    [projectId, updateSwrHook],
  )

  const updateDfoAttributes = useCallback(
    () =>
      updateSwrHook({
        projectId,
        dfoId,
        _key: 'dfoAttributes',
      }),
    [dfoId, projectId, updateSwrHook],
  )

  //Не менять порядок ревалидаций.!!!
  const updateAllProjectData = async () => {
    try {
      await updateCurrentDfoDocuments()
      await updateDfoAttributes()

      const versions = await updateProjectVersions()
      const dfos = await updateProjectDfos()

      const SZPKDfo = getDfoByType(
        DfoService.getAllDfosFromGroupAndSubgrops(dfos),
        DfosType.SZPK as TAllDfoTypes,
      )

      await updateAvailableForCreateDfo()
      await updateProject()

      await Promise.allSettled([
        updateProjectActions(),
        updateProjectMembers(),
        updateProjectAttributes(),
      ])

      if (SZPKDfo?.id !== dfoId) {
        await updateSwrHook({ dfoId: SZPKDfo?.id, projectId, _key: 'document-sets' })
      }

      return versions
    } catch (e) {
      throw e
    }
  }

  return {
    updateAvailableForCreateDfo,
    updateCurrentDfoDocuments,
    updateProjectActions,
    updateProjectVersions,
    updateProjectMembers,
    updateProjectAttributes,
    updateAllProjectData,
    updateProject,
    updateProjectDfos,
  }
}

export { useUpdateDataAfterDocumentActions }
