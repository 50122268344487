import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import SingleSelectNew, {
  type SingleSelectNewProps,
} from '@components/NewDesign/Select/SingleSelectNew'

interface ControlledSingleSelectNewProps<T extends FieldValues> extends SingleSelectNewProps {
  controllerProps: Omit<ControllerProps<T>, 'render'>
}

const ControlledSingleSelectNew = <T extends FieldValues>(
  props: ControlledSingleSelectNewProps<T>,
) => {
  const { controllerProps, ...restProps } = props

  const {
    field: { onChange: onChangeForm, onBlur: onBlurForm, value: formValue },
    fieldState: { error },
  } = useController(controllerProps)

  return (
    <SingleSelectNew
      {...restProps}
      error={error}
      defaultSelected={restProps.defaultSelected || formValue}
      onChangeForm={onChangeForm}
      onBlurForm={onBlurForm}
    />
  )
}

export type { ControlledSingleSelectNewProps }
export default ControlledSingleSelectNew
