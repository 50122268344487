import { useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { StaleActionsModal } from '.'

export const useStaleActionsModal = () => {
  const popupManager = usePopupManager()

  const handleOpenStaleActionsModal = useCallback(
    (onUpdate) => {
      popupManager.closeAll()

      popupManager.open(StaleActionsModal, {
        onUpdate,
        onClose: () => null,
      })
    },
    [popupManager],
  )

  return {
    handleOpenStaleActionsModal,
  }
}
