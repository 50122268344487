import { dataOnFulfillmentOfTermsSZPKBlockValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/const'
import { SzpkReportInfoArrayPathName } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/types'

export const useCurrentSzpkReportsListFormName = (
  isSzpkReportsFederal: boolean | undefined,
  isSzpkReportsMustBeWithinMonitoringDates: boolean | undefined,
) => {
  const {
    federalSzpkReportsInfo,
    federalSzpkReportsInfoWithinMonitoringDates,
    notFederalSzpkReportsInfo,
    notFederalSzpkReportsInfoWithinMonitoringDates,
  } = dataOnFulfillmentOfTermsSZPKBlockValues.additionalForms

  let listFormName: SzpkReportInfoArrayPathName = federalSzpkReportsInfo

  if (isSzpkReportsFederal && isSzpkReportsMustBeWithinMonitoringDates)
    listFormName = federalSzpkReportsInfoWithinMonitoringDates

  if (!isSzpkReportsFederal && !isSzpkReportsMustBeWithinMonitoringDates)
    listFormName = notFederalSzpkReportsInfo

  if (!isSzpkReportsFederal && isSzpkReportsMustBeWithinMonitoringDates)
    listFormName = notFederalSzpkReportsInfoWithinMonitoringDates

  return listFormName
}
