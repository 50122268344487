import { Urls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'

import type { INotification, INotificationFromStack, NotificationsCount } from './types'

const SIZE = 3

const useNotificationsApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const getNotifications = async (page: number) => {
    try {
      const { data } = await FetcherWithInterceptors.get<INotification[]>({
        url: Urls.NotificationsSummary,
        config: {
          params: {
            page,
            size: SIZE,
          },
        },
      })
      return data
    } catch (error) {
      throw error
    }
  }

  const getNotificationsById = async (dfoId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<INotificationFromStack>({
        url: `${Urls.Notifications}?dfoId=${dfoId}`,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error)

      throw error
    }
  }

  const getNotificationsCount = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<NotificationsCount>({
        url: Urls.NotificationsCount,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error)

      throw error
    }
  }

  return {
    getNotifications,
    getNotificationsById,
    getNotificationsCount,
  }
}

export default useNotificationsApi
