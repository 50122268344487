import { useCallback } from 'react'

import { generatePropertiesObjectForInfrFacilitiesCosts } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/adapters/PropertiesProps.adapter'
import { generateRHFObjectForInfrFacilitiesCosts } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/adapters/RHF.adapter'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'

const useInfrFacilitiesCostsExpectedReimbursedAdapters = () => {
  const generateRHFObject = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): InfrFacilitiesCostsExpectedReimbursedFormValues =>
      generateRHFObjectForInfrFacilitiesCosts(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForInfrFacilitiesCosts(objectFromPropertyState),
    [],
  )

  return {
    generateRHFObject,
    generatePropertiesObject,
  }
}

export { useInfrFacilitiesCostsExpectedReimbursedAdapters }
