import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { DocumentSetDictTypesWithSystemVersion } from '@services/Dictionaries/dictionaries.entity'
import useSWRImmutable from 'swr/immutable'

const useDocumentsSetDictTypes = ({
  key,
  config,
}: SWRHookProps<DocumentSetDictTypesWithSystemVersion | undefined>) => {
  const {
    dfosApi: { getDocumentsSetsTypes },
  } = useAPIContext()

  const {
    data: documentsSetsDict,
    error,
    mutate,
    isValidating: isLoadingDocumentsSetsDict,
  } = useSWRImmutable(key, getDocumentsSetsTypes, config)

  return {
    documentsSetsDict,
    error,
    mutate,
    isLoadingDocumentsSetsDict,
  }
}

export { useDocumentsSetDictTypes }
