import { ButtonHTMLAttributes, VFC } from 'react'

import Icon, { IconProps } from '@components/Icon'
import cn from 'classnames'

import styles from './IconButton.module.scss'

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Pick<IconProps, 'size'> {
  priority?: 'primary' | 'secondary' | 'white'
  icon: string
  noCurrentColorSvgFill?: boolean
}

const IconButton: VFC<IconButtonProps> = ({
  size = 'm',
  icon,
  className,
  priority = 'primary',
  noCurrentColorSvgFill,
  ...props
}) => {
  return (
    <button
      type="button"
      className={cn(
        styles.button,
        styles[`button--${size}`],
        styles[`button--${priority}`],
        className,
      )}
      {...props}
    >
      <Icon
        size={size}
        src={icon}
        className="pointer-events-none"
        noCurrentColorSvgFill={noCurrentColorSvgFill}
      />
    </button>
  )
}

export default IconButton
