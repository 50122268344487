import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'

interface ParametersApplicationReimbursementExpensesFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
}

const ParametersApplicationReimbursementExpensesForms: ParametersApplicationReimbursementExpensesFormsProps =
  () => null

ParametersApplicationReimbursementExpensesForms.First = First
ParametersApplicationReimbursementExpensesForms.Second = Second
ParametersApplicationReimbursementExpensesForms.Third = Third

export default ParametersApplicationReimbursementExpensesForms
