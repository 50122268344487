import { FC } from 'react'

import One from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'

interface AAgreementOnCreationOtherEstateObjectsFormsProps extends FC {
  One: typeof One
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
}

const AAgreementOnCreationOtherEstateObjectsForms: AAgreementOnCreationOtherEstateObjectsFormsProps =
  () => null

AAgreementOnCreationOtherEstateObjectsForms.One = One
AAgreementOnCreationOtherEstateObjectsForms.Second = Second
AAgreementOnCreationOtherEstateObjectsForms.Third = Third
AAgreementOnCreationOtherEstateObjectsForms.Fourth = Fourth
AAgreementOnCreationOtherEstateObjectsForms.Fifth = Fifth

export default AAgreementOnCreationOtherEstateObjectsForms
