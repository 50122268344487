import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import Checkbox, { CheckboxProps } from '@components/Checkbox/Base/Checkbox'
import WithCaption from '@components/WithCaption'

export type ControlledCheckboxProps<T> = Omit<ControllerProps<T>, 'render'> &
  Pick<CheckboxProps, 'onBlur' | 'onFocus' | 'onChange'> & {
    checkBoxProps?: Omit<CheckboxProps, 'onChange'>
  }

const ControlledCheckbox = <T extends FieldValues>(props: ControlledCheckboxProps<T>) => {
  const { onFocus, onChange: onChangeForm, onBlur, checkBoxProps, ...controllerProps } = props

  const {
    field: { onChange, onBlur: onFormBlur, value, ref },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const mergedOnBlur = (e) => {
    onBlur?.(e)

    onFormBlur()
  }

  const mergedOnChange = (...params) => {
    onChange(...params)

    onChangeForm?.(...params)
  }

  return (
    <WithCaption caption={error?.message}>
      <Checkbox
        {...checkBoxProps}
        checked={value}
        ref={ref}
        onBlur={mergedOnBlur}
        onChange={mergedOnChange}
        onFocus={onFocus}
      />
    </WithCaption>
  )
}

ControlledCheckbox.displayName = 'ControlledCheckbox'

export default ControlledCheckbox
