import { ICurrentDfoActionProjectPanel } from '@components/Projects/[id]/ActionBanner/Manager'
import {
  BodyTextByRevokeClaimSubsidyPermitAction,
  HeaderTextByRevokeClaimSubsidyPermitAction,
} from '@interfaces/statement'
import { ClaimSubsidyBudgetType } from '@services/Dfo/Dfo.entity'
import {
  GetUiActionTextByClaimBudgetTypeReturnType,
  IDocumentAction,
  TDocumentStackAction,
} from '@services/DocumentActions/DocumentActions.entity'
import { TGetProjectActions } from '@services/Projects/Project.entity'

class DocumentActionsService {
  comparePanelFunctions(
    prevValue: ICurrentDfoActionProjectPanel,
    nextValue: ICurrentDfoActionProjectPanel,
  ) {
    if (prevValue.action === 'return') {
      if (nextValue.action === 'return') {
        return 0
      }
      return -1
    }

    if (prevValue.action === 'cancel' && nextValue.action === 'approve') {
      return -1
    }

    if (nextValue.action === 'cancel' && prevValue.action === 'approve') {
      return 1
    }

    return 0
  }

  isDfoTypeExistInActionsById(dfoId: string, actions: TGetProjectActions) {
    let dfoIsExist = false

    for (let i = 0; i < actions.length; i++) {
      if (dfoIsExist) break

      const searchDfo = actions[i].dfos.find((dfo) => {
        return dfo.dfoId === dfoId
      })

      if (searchDfo) {
        dfoIsExist = true
      }
    }

    return dfoIsExist
  }

  fromActionToActionsStack(actionFromBackend: IDocumentAction): TDocumentStackAction[] {
    return actionFromBackend.uiActions.map((actionType, index) => ({
      id: String(index),
      type: actionType,
      initialProps: {
        actionId: actionFromBackend.actionId,
        actionType: actionFromBackend.action,
        dfos: actionFromBackend.dfos,
      },
      returnedProps: null,
    }))
  }

  getRevokeClaimSubsidyPermitText(
    isProjectFederal: boolean,
    claimBudgetType?: ClaimSubsidyBudgetType,
  ): GetUiActionTextByClaimBudgetTypeReturnType {
    if (isProjectFederal && claimBudgetType === 'FEDERAL') {
      return {
        revokeClaimSubsidyHeaderText:
          HeaderTextByRevokeClaimSubsidyPermitAction.FEDERAL_REVOKE_CLAIM_SUBSIDY_PERMIT,
        revokeClaimSubsidyBodyText:
          BodyTextByRevokeClaimSubsidyPermitAction.FEDERAL_REVOKE_CLAIM_SUBSIDY_PERMIT,
      }
    }

    return {
      revokeClaimSubsidyHeaderText:
        HeaderTextByRevokeClaimSubsidyPermitAction.FEDERAL_REVOKE_CLAIM_SUBSIDY_PERMIT,
      revokeClaimSubsidyBodyText:
        BodyTextByRevokeClaimSubsidyPermitAction.REGIONAL_REVOKE_CLAIM_SUBSIDY_PERMIT,
    }
  }
}

export default new DocumentActionsService()
