import { ErrorHeadersTexts, ErrorTypes } from '@components/Forms/LoginForm/constants'
import type { ErrorFromRequest, ErrorProps } from '@components/Forms/LoginForm/types'
import { fromEmailStringToEmailJSX } from '@components/Forms/RegistrationForm/helpers'
import { mapOfEmails } from '@constants/email'
import { mapOfCustomErrorCodes } from '@constants/errorCodes'
import { HttpStatusCode } from '@constants/statusCodes'
import { defaultMessageSupport, defaultMessageSupportWithLink } from '@constants/texts'
import type { AxiosError } from 'axios'

const handleInternalServerError = (error: AxiosError<ErrorFromRequest>): ErrorProps | void => {
  const errorStatus = error?.response?.status

  if (errorStatus && errorStatus !== HttpStatusCode.INTERNAL_SERVER_ERROR) return

  return {
    header: ErrorHeadersTexts.temporarilyUnavailableMessage,
    body: defaultMessageSupportWithLink,
  }
}

const handleForbiddenAuthError = (error: AxiosError<ErrorFromRequest>): ErrorProps | void => {
  const errorStatus = error.response?.status
  const { error_description, detail, errorCode } = error.response?.data ?? {}

  const defaultForbiddenError = {
    header: ErrorHeadersTexts.accessIdDenied,
    body: error_description || detail || defaultMessageSupportWithLink,
  }

  if (errorStatus !== HttpStatusCode.FORBIDDEN) return

  if (errorCode === mapOfCustomErrorCodes.USER_DETERMINING_AUTHORIZED_PERSONS) {
    return {
      ...defaultForbiddenError,
      body: fromEmailStringToEmailJSX(
        error_description || detail || defaultMessageSupport,
        mapOfEmails,
      ),
      supportButton: true,
    }
  }

  return defaultForbiddenError
}

const handleUnauthorizedError = (error: AxiosError<ErrorFromRequest>): ErrorProps | void => {
  const errorStatus = error?.response?.status
  const { detail, error_description, error: error_type } = error?.response?.data ?? {}

  if (errorStatus !== HttpStatusCode.UNAUTHORIZED) return

  if (error_type === ErrorTypes.invalid_data) {
    return {
      header: ErrorHeadersTexts.invalidCertificateMessage,
      body: error_description || detail || defaultMessageSupportWithLink,
    }
  }

  if (error_type === ErrorTypes.procuration_not_valid) {
    return {
      header: ErrorHeadersTexts.numberNotFound,
      body: error_description || detail || defaultMessageSupportWithLink,
    }
  }

  return {
    header: ErrorHeadersTexts.accessIdDenied,
    body: error_description || detail || defaultMessageSupportWithLink,
  }
}

const handleBadRequestAuthError = (error: AxiosError<ErrorFromRequest>): ErrorProps | void => {
  const errorStatus = error.response?.status
  const { error_description, detail } = error.response?.data || {}

  if (errorStatus !== HttpStatusCode.BAD_REQUEST) return

  return {
    header: ErrorHeadersTexts.errorMessage,
    body: error_description || detail || defaultMessageSupportWithLink,
  }
}

export {
  handleBadRequestAuthError,
  handleForbiddenAuthError,
  handleInternalServerError,
  handleUnauthorizedError,
}
