import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof InformationOfExecutionConditionsSZPKFormValues['4']['percentCredits'][number],
  InformationOfExecutionConditionsSZPKFormValues
> = {
  percentCreditName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 200),
  },
  percentCreditDate: {
    required: defaultRHFValidation.required,
  },
  percentCreditNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 200),
  },
}

export { fourthSectionValidationMap }
