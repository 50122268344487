import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNotificationDrawalAppConclusionSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      ruleLinkStatementRevoke: {
        propertyId: objectFromPropertyState.ruleLinkStatementRevoke.propertyId,
        type: objectFromPropertyState.ruleLinkStatementRevoke.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStatementRevoke.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNotificationDrawalAppConclusionSZPKForm }
