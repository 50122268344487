import { memo } from 'react'

import RegistryNPAGrid from '@components/RegistryNpa/Grid'
import RegistryNpaHeader from '@components/RegistryNpa/Header'
import NpaManager from '@components/RegistryNpa/Manager'
import { useOrganizationInfo } from '@hooks/new/swr/useOrganizationInfo'

import styles from './RegistryNpa.module.scss'

const RegistryNpa = () => {
  //Ревалидация пользователя, т.к доступно его редактирование
  useOrganizationInfo({
    key: { _key: 'organizationInfo' },
    config: {
      revalidateOnMount: true,
    },
  })

  return (
    <NpaManager>
      <div className={styles.registryNpa}>
        <header className={styles.registryNpa__header}>
          <RegistryNpaHeader />
        </header>
        <div className={styles.registryNpa__body}>
          <RegistryNPAGrid />
        </div>
      </div>
    </NpaManager>
  )
}

export default memo(RegistryNpa)
