import React from 'react'
import { useFormContext } from 'react-hook-form'

import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import MonitoringStep from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/3/AdditionalForms/MonitoringStep'
import { StepsPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/3/types'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  const { fields: steps } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    StepsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: informationOfExecutionConditionsSZPKBlockValues['3'].stepsMonitoring,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            {!steps.length && (
              <EmptyDataText text={'В проекте нет начатых или завершенных этапов'} />
            )}
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['3'].stepsMonitoring,
                })}
              >
                <FlipperList list={steps} className={'p-0'}>
                  {steps.map((step, index) => (
                    <MonitoringStep
                      key={step.id}
                      id={step.id}
                      anchorId={step.anchorId}
                      formName={
                        `${informationOfExecutionConditionsSZPKBlockValues['3'].stepsMonitoring}.${index}` as const
                      }
                    />
                  ))}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
