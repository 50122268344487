import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { IProject } from '@services/Projects/Project.entity'
import useSWRImmutable from 'swr/immutable'

const useProject = ({ key, config }: SWRHookProps<IProject | undefined>) => {
  const {
    projectsApi: { getProjectById },
  } = useAPIContext()

  const {
    data: project,
    error,
    mutate,
    isValidating: isLoadingProject,
  } = useSWRImmutable(key, ({ projectId }) => getProjectById(projectId), config)

  return {
    project,
    error,
    mutate,
    isLoadingProject,
  }
}

export { useProject }
