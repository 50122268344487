import { useAPIContext } from '@context/APIContext'
import { EventItem } from '@context/APIContext/hooks/useProjectsApi'
import { SWRHookProps } from '@interfaces'
import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

const useProjectEvents = ({ key, config }: SWRHookProps) => {
  const {
    projectsApi: { getEventsByProjectId },
  } = useAPIContext()

  const {
    data: events,
    error: eventsError,
    mutate: revalidateEvents,
    isValidating: eventIsLoading,
  } = useSWRImmutable<EventItem[], AxiosError>(key, getEventsByProjectId, config)

  return {
    events,
    eventsError,
    revalidateEvents,
    eventIsLoading,
  }
}

export default useProjectEvents
