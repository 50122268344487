import { FC, memo, useCallback, useEffect } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Icon from '@components/Icon'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import Sidebar from '@components/NewDesign/Sidebar'
import { letterHeadPartItemsTexts, letterHeadPartsBaseTexts } from '@constants/texts'
import { useOrganizationBlankFiles } from '@hooks/new/swr/useOrganizationBlankFiles'
import { useOrganizationBlankPreview } from '@hooks/new/swr/useOrganizationBlankPreview'
import { useOrganizationBlankSave } from '@hooks/new/swr/useOrganizationBlankSave'
import PreviewIcon from '@icons/action/preview.svg'

import { useDrawerControls, useFileDropLoader } from './hooks'
import styles from './LetterHeadParts.module.scss'
import LetterHeadPartsDropItem from './LetterHeadPartsDropItem'

interface LetterHeadPartsProps extends PopupProps {
  organizationId: string
}

const useLetterHeadPartsControl = () => {
  const popupManager = usePopupManager()
  const openLetterHeadPartsControl = (openProps: LetterHeadPartsProps) => {
    popupManager.open<LetterHeadPartsProps>(LetterHeadPartsSidebar, openProps)
  }
  return { openLetterHeadPartsControl }
}

const LetterHeadPartsSidebar: FC<LetterHeadPartsProps> = ({ isOpen, onClose, organizationId }) => {
  const {
    files,
    isLoading: isBlankPending,
    refetchFiles,
  } = useOrganizationBlankFiles(organizationId)

  useEffect(() => {
    if (files && files.length > 0) refetchFiles()
  }, [])

  const handleCloseSidebar = useCallback(() => {
    refetchFiles?.()
    onClose?.()
  }, [onClose, refetchFiles])

  const {
    first_footer,
    first_header,
    general_footer,
    general_header,
    fileActions,
    isPreviewEnabled,
  } = useFileDropLoader(files)

  const { isSaveBlankPending, saveBlank } = useOrganizationBlankSave(handleCloseSidebar)

  const { getBlankPreview, isPreviewPending } = useOrganizationBlankPreview()

  const handlePreviewBlank = useCallback(() => {
    getBlankPreview(
      [first_footer, first_header, general_footer, general_header].filter((f) => f.fileId),
    )
  }, [first_footer, first_header, general_footer, general_header, getBlankPreview])

  const handleSaveFiles = useCallback(() => {
    saveBlank([first_footer, first_header, general_footer, general_header].filter((f) => f.fileId))
  }, [first_footer, first_header, general_footer, general_header, saveBlank])

  const actions = useDrawerControls({
    onSave: handleSaveFiles,
    onClose: () => onClose?.(),
    isPending: isSaveBlankPending || isBlankPending,
  })

  return (
    <Sidebar
      isOpen={isOpen}
      title={letterHeadPartsBaseTexts.sideBarTitle}
      actions={actions}
      contentClassName={styles.root}
      onClose={onClose}
    >
      <Loader loading={isBlankPending}>
        <div className={styles.filesPart}>
          <LetterHeadPartsDropItem
            title={letterHeadPartItemsTexts.first_header}
            fileState={first_header}
            fileActions={fileActions}
            fileItemName={'first_header'}
          />
          <LetterHeadPartsDropItem
            title={letterHeadPartItemsTexts.first_footer}
            fileState={first_footer}
            fileActions={fileActions}
            fileItemName={'first_footer'}
          />
        </div>
        <div className={styles.filesPart}>
          <LetterHeadPartsDropItem
            title={letterHeadPartItemsTexts.general_header}
            fileState={general_header}
            fileActions={fileActions}
            fileItemName={'general_header'}
          />
          <LetterHeadPartsDropItem
            title={letterHeadPartItemsTexts.general_footer}
            fileState={general_footer}
            fileActions={fileActions}
            fileItemName={'general_footer'}
          />
        </div>
        <Button
          view="tined"
          className={styles.previewButton}
          textClassName={styles.previewButton__text}
          disabled={!isPreviewEnabled || isPreviewPending}
          onClick={handlePreviewBlank}
        >
          <Loader loading={isPreviewPending} variant="lite">
            <Icon src={PreviewIcon} size="m" className={styles.previewButton__icon} />
            {letterHeadPartsBaseTexts.main.buttons.preview}
          </Loader>
        </Button>
      </Loader>
    </Sidebar>
  )
}

export type { LetterHeadPartsProps }
export { useLetterHeadPartsControl }
export default memo(LetterHeadPartsSidebar)
