import React, { DetailedHTMLProps, FC, HTMLAttributes, LegacyRef, memo, ReactNode } from 'react'
import Dropzone, { DropzoneProps, DropzoneRef } from 'react-dropzone'

import EntityItemActions, { EntityItemActionsProps } from '@components/EntityItem/Actions'
import { EntityItemVariant } from '@components/EntityItem/types'
import cn from 'classnames'

import styles from './EntityItem.module.scss'

interface IDropzone {
  dropZoneProps?: DropzoneProps
}

export interface EntityItemProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  mainContentNode: ReactNode
  entityItemRef?: LegacyRef<HTMLDivElement>
  actionsProps?: EntityItemActionsProps
  variant?: EntityItemVariant
  trailingAddition?: ReactNode
  leadingAddition?: ReactNode
  leadingClassName?: string
  mainContentClassName?: string
  trailingClassName?: string
  dataTestId?: string
  dropZone?: IDropzone
  dropZoneRef?: React.Ref<DropzoneRef>
}

const EntityItem: FC<EntityItemProps> = ({
  variant = 'default',
  actionsProps,
  entityItemRef,
  trailingAddition,
  mainContentNode,
  leadingAddition,
  leadingClassName,
  mainContentClassName,
  trailingClassName,
  dataTestId,
  dropZone,
  dropZoneRef,
  ...divRest
}) => {
  const { dropZoneProps } = dropZone || {}

  const isLoading = variant === 'loading'
  const isError = variant === 'error'
  const isRemoved = variant === 'removed'
  const isRemovedNow = variant === 'removedNow'

  return (
    <Dropzone {...dropZoneProps} noDrag noKeyboard noClick ref={dropZoneRef}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          {...divRest}
          ref={entityItemRef}
          data-testid={dataTestId}
          className={cn(
            styles.entityItem,
            {
              [styles.entityItem__loading]: isLoading,
              [styles.entityItem__error]: isError,
              [styles.entityItem__removed]: isRemoved,
              [styles.entityItem__removedNow]: isRemovedNow,
              [styles.entityItem__withLeading]: !!leadingAddition,
              [styles.entityItem__withTrailing]: !!trailingAddition,
            },
            divRest.className,
          )}
        >
          <input {...getInputProps()} />
          {leadingAddition && (
            <div className={cn(styles.entityItem__withLeading, leadingClassName)}>
              {leadingAddition}
            </div>
          )}
          {
            <div className={cn(styles.entityItem__content, mainContentClassName)}>
              {mainContentNode}
            </div>
          }
          {
            <div className={cn(styles.entityItem__withTrailing, trailingClassName)}>
              {trailingAddition}
            </div>
          }
          {actionsProps && <EntityItemActions {...actionsProps} />}
        </div>
      )}
    </Dropzone>
  )
}

export type { IDropzone }
export default memo(EntityItem)
