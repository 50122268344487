import React, {
  createContext,
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from 'react'

import { MINIMUM_VALUE_LENGTH_TO_SEARCH } from '@components/NewDesign/Select/HierarchyReferenceBookSelect/constants'
import {
  hierarchyReferenceBookSelectCreators,
  hierarchyReferenceBookSelectManagerReducer,
  initialHierarchyReferenceBookSelectManagerReducerState,
} from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Manager/reducer'
import { HierarchyReferenceBookNames } from '@components/ReferenceBooks/types'
import { useAPIContext } from '@context/APIContext'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { useReferenceBooksByDictionaryName } from '@hooks/new/swr/useReferenceBooksByDictionaryName'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import useInput from '@hooks/useInput'

import type { InitialThreeManager } from './types'

interface HierarchyReferenceBookContextProps {
  state: {
    searchPattern: string
    selectModalIsOpen: boolean
    searchThree: ReferenceBookItem[] | null
    three: InitialThreeManager | null
    threeIsLoading: boolean
    selectedItemId: string | null
    selectedItemRef: HTMLDivElement | null
    dictionaryName: HierarchyReferenceBookNames | null
  }
  refs: {
    contentModalRef?: MutableRefObject<HTMLElement | null>
  }
  handlers: {
    onChangeSearchPattern: ((searchPattern: string) => void) | null
    onSelectItem: ((item: ReferenceBookItem) => void) | null
    onSelectRef: ((ref: HTMLDivElement | null) => void) | null
    onClearSelected: VoidFunction | null
    onCloseModal: VoidFunction | null
    handleSetupModal: ((value: boolean) => void) | null
    handleSetupSearchThree: ((value: ReferenceBookItem[] | null) => void) | null
    handleSetupThree: ((value: InitialThreeManager) => void) | null
    handleSetupThreeLoading: ((value: boolean) => void) | null
    onModalMount: VoidFunction | null
  }
}

interface HierarchyReferenceBookManagerProps {
  dictionaryName: HierarchyReferenceBookNames
  initialId: string | null
  children?: ReactNode
}

export const HierarchyReferenceBookContext = createContext<HierarchyReferenceBookContextProps>({
  state: {
    searchPattern: '',
    selectModalIsOpen: false,
    searchThree: null,
    three: null,
    threeIsLoading: true,
    selectedItemId: null,
    selectedItemRef: null,
    dictionaryName: null,
  },
  refs: {},
  handlers: {
    onChangeSearchPattern: null,
    onSelectItem: null,
    onSelectRef: null,
    onClearSelected: null,
    onCloseModal: null,
    handleSetupModal: null,
    handleSetupSearchThree: null,
    handleSetupThree: null,
    handleSetupThreeLoading: null,
    onModalMount: null,
  },
})

const HierarchyReferenceBookManager: FC<HierarchyReferenceBookManagerProps> = ({
  dictionaryName,
  initialId,
  children,
}) => {
  const {
    referenceBooksApi: { getReferenceBooks },
  } = useAPIContext()

  const [state, dispatch] = useReducer(
    hierarchyReferenceBookSelectManagerReducer,
    initialHierarchyReferenceBookSelectManagerReducerState,
  )

  const selectedItemRef = useRef<HTMLDivElement | null>(null)

  const currentSearchPattern = useInput('')

  const contentModalRef = useRef<HTMLElement | null>(null)

  const abortControllerRef = useRef<AbortController | null>(null)

  //Сброс скролла от выделенного рефа, при ввёденом значении в поиск
  const resetContentModalScroll = useDebouncedCallback(() => {
    if (!contentModalRef.current) return

    contentModalRef.current.scrollTop = 0
  }, 600)

  //Генерация стартовых кодов при рендере селекта
  const { referenceBooks } = useReferenceBooksByDictionaryName({
    key: {
      dictionaryName,
      _key: 'referenceBooks',
    },
  })

  // Интерфейс изменения значения поиска
  const onChangeSearchPattern = useCallback(
    (newSearchValue: string) => {
      currentSearchPattern.setValue(newSearchValue)
    },
    [currentSearchPattern],
  )

  const onSelectRef = (ref: HTMLDivElement | null) => {
    selectedItemRef.current = ref
  }

  const handleSetupSelectedItemId = (value: string | null) => {
    dispatch(hierarchyReferenceBookSelectCreators.setSelectedItemId(value))

    if (!value || !selectedItemRef.current) return

    // костыль для корректного перехода скролла к выделенному элементу
    setTimeout(() => {
      selectedItemRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    })
  }

  const onClearSelected = () => {
    selectedItemRef.current = null
    dispatch(hierarchyReferenceBookSelectCreators.clearSelectedItem())
  }

  const onCloseModal = () => {
    dispatch(hierarchyReferenceBookSelectCreators.clearAllStates())
    selectedItemRef.current = null
    onChangeSearchPattern('')
  }

  const handleSetupModal = (value: boolean) => {
    dispatch(hierarchyReferenceBookSelectCreators.setSelectModalOpen(value))
  }

  const handleSetupSearchThree = (value: ReferenceBookItem[] | null) => {
    dispatch(hierarchyReferenceBookSelectCreators.setSearchThree(value))
  }

  const handleSetupThree = (value: InitialThreeManager) => {
    dispatch(hierarchyReferenceBookSelectCreators.setThree(value))
  }

  const handleSetupThreeLoading = (value: boolean) => {
    dispatch(hierarchyReferenceBookSelectCreators.setThreeLoading(value))
  }

  const onModalMount = async () => {
    if (referenceBooks && !initialId) {
      handleSetupThreeLoading(false)
    }

    if (!initialId) return

    //Проверяем, если есть стартовый код в селекте, генерируем карту кодов, которые должны открыться при маунте, выбираем элемент
    await generateThreeByInitialIdOnModalOpen(initialId)
  }

  //Генерации карты кодов выделенного элемента
  const generateThree = useCallback(
    async (id: string | null) => {
      if (!id) return

      try {
        const referenceBooks = await getReferenceBooks({ dictionaryName, id })

        if (!referenceBooks || !referenceBooks.length) return Promise.reject()

        const generateThreeRecursive = (
          referenceBookItem: ReferenceBookItem,
          map: InitialThreeManager = new Map(),
        ) => {
          if (!referenceBookItem.children || !referenceBookItem.children.length) {
            handleSetupThree(map)

            return
          }

          const newMap = new Map(map.set(referenceBookItem.id, true))

          referenceBookItem.children.forEach((referenceBookChild) =>
            generateThreeRecursive(referenceBookChild, newMap),
          )
        }

        referenceBooks.forEach((referenceBookItem) => generateThreeRecursive(referenceBookItem))

        return Promise.resolve()
      } catch {
        handleSetupThree(new Map())
        return Promise.reject()
      }
    },
    [dictionaryName, getReferenceBooks],
  )

  const onSelectItem = useCallback(
    async (newReferenceBookItem: ReferenceBookItem) => {
      if (newReferenceBookItem.id === state.selectedItemId) {
        return handleSetupSelectedItemId(null)
      }

      handleSetupSelectedItemId(newReferenceBookItem.id)

      // запускается генерация дерева, чтобы элементы были раскрыты от родителя до выбранного ребенка
      generateThree(newReferenceBookItem.id)
    },
    [generateThree, state.selectedItemId],
  )

  const generateThreeByInitialIdOnModalOpen = async (initialId: string) => {
    try {
      await generateThree(initialId)

      handleSetupSelectedItemId(initialId)
    } catch (error) {
      handleSetupSelectedItemId(null)
    } finally {
      handleSetupThreeLoading(false)
    }
  }

  const generateSearchThree = useCallback(
    async (searchString: string) => {
      try {
        const readyThree = await getReferenceBooks({
          dictionaryName,
          searchString: searchString.trim(),
          signal: abortControllerRef.current?.signal,
        })

        const putSearchMarkOnThree = (three: ReferenceBookItem[]): ReferenceBookItem[] => {
          const addKeyToChildren = (node: ReferenceBookItem): ReferenceBookItem => {
            // Если у узла есть дочерние элементы, добавляем ключ и обновляем дочерние элементы
            if (node.children && node.children.length > 0) {
              // Добавляем ключ fromSearchThree и рекурсивно обновляем дочерние элементы
              return {
                ...node,
                fromSearchThree: true,
                children: node.children.map((child) => addKeyToChildren(child)),
              }
            }
            // Возвращаем узел без изменений, если у него нет дочерних элементов
            return node
          }

          return three.map((node) => addKeyToChildren(node))
        }

        if (readyThree?.length) {
          return handleSetupSearchThree(putSearchMarkOnThree(readyThree))
        }
        //При нулевом дереве ничего не показываем, когда дерево = null, работает fetcher
        handleSetupSearchThree(null)
      } catch {
        handleSetupSearchThree(null)
      } finally {
        handleSetupThreeLoading(false)
      }
    },
    [dictionaryName, getReferenceBooks],
  )

  //Дебаунс генерация дерева
  const debouncedGenerateSearchThree = useDebouncedCallback(generateSearchThree, 600)

  useEffectAfterMount(() => {
    resetContentModalScroll()

    const abortController = new AbortController()

    abortControllerRef.current = abortController

    if (!state.threeIsLoading) {
      handleSetupThreeLoading(true)
    }

    if (currentSearchPattern.value.length >= MINIMUM_VALUE_LENGTH_TO_SEARCH) {
      //Генерация дерева из поиска после 600ms
      debouncedGenerateSearchThree(currentSearchPattern.value)
      return
    }

    // Отменяется запрос, если символов поиска меньше, чем MINIMUM_VALUE_LENGTH_TO_SEARCH
    abortController.abort()

    handleSetupThreeLoading(false)
    handleSetupSearchThree(null)

    if (!state.selectedItemId || !selectedItemRef.current) return

    selectedItemRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }, [currentSearchPattern.value])

  const providerValue: HierarchyReferenceBookContextProps = useMemo(
    () => ({
      state: {
        searchPattern: currentSearchPattern.value,
        selectModalIsOpen: state.selectModalIsOpen,
        searchThree: state.searchThree,
        three: state.three,
        threeIsLoading: state.threeIsLoading,
        selectedItemId: state.selectedItemId,
        selectedItemRef: selectedItemRef.current,
        dictionaryName,
      },
      refs: {
        contentModalRef,
      },
      handlers: {
        onChangeSearchPattern,
        onSelectItem,
        onSelectRef,
        onClearSelected,
        onCloseModal,
        handleSetupModal,
        handleSetupSearchThree,
        handleSetupThree,
        handleSetupThreeLoading,
        onModalMount,
      },
    }),
    [
      currentSearchPattern.value,
      state.selectModalIsOpen,
      state.searchThree,
      state.three,
      state.threeIsLoading,
      state.selectedItemId,
      dictionaryName,
      onChangeSearchPattern,
      onSelectItem,
      onCloseModal,
    ],
  )

  return (
    <HierarchyReferenceBookContext.Provider value={providerValue}>
      {children}
    </HierarchyReferenceBookContext.Provider>
  )
}

const useHierarchyReferenceBookManager = () => {
  return useContext(HierarchyReferenceBookContext)
}

export type { HierarchyReferenceBookContextProps }
export { useHierarchyReferenceBookManager }
export default HierarchyReferenceBookManager
