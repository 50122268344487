import { useMemo } from 'react'
import { FieldPathValue, FieldValues, UnpackNestedValue, useForm } from 'react-hook-form'

import Icon from '@components/Icon/Icon'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { useHierarchyReferenceBookManager } from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Manager'
import HierarchyReferenceBookModal from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Modal'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { useBooleanState } from '@hooks/useBooleanState'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import arrowBackIcon from '@icons/hardware/keyboard_arrow_down.svg'
import cn from 'classnames'

import styles from './Select.module.scss'
import type { BaseHierarchyReferenceBookSelectProps } from './types'

const BaseHierarchyReferenceBookSelect = <T extends FieldValues>({
  id,
  name,
  error,
  defaultSelected,
  inputProps,
  onChange,
  onBlur,
  customDisplayValueFC,
}: BaseHierarchyReferenceBookSelectProps<T>) => {
  const { disabled, enableConditionToShowTooltip, ...restInputProps } = inputProps || {}

  const { handlers, state } = useHierarchyReferenceBookManager()

  const {
    booleanState: isDirty,
    setBooleanState: setDirty,
    setBooleanStateToFalse: disableDirty,
  } = useBooleanState()

  // Синхронизация ошибок из оригинального control
  useEffectAfterMount(() => {
    if (!error) return controlForDisplaySelect.clearErrors()

    controlForDisplaySelect.setError(`${name}OnChange`, error)
  }, [error])

  // Значение при маунте на селект
  const preparedDefaultValueForSelect = useMemo(() => {
    if (!defaultSelected) return ''

    if (customDisplayValueFC) return customDisplayValueFC(defaultSelected)

    const { code, name } = defaultSelected

    return code ? `${code} · ${name}` : name
  }, [customDisplayValueFC, defaultSelected])

  const controlForDisplaySelect = useForm({
    defaultValues: {
      [`${name}OnChange`]: preparedDefaultValueForSelect,
    },
  })

  // Меняем поле из формы выше и устанавливаем локальный селект (форма)
  const handleModalOnChange = ({ id, code, name: referenceBookName }: ReferenceBookItem) => {
    const displayValue = !customDisplayValueFC
      ? `${code} · ${referenceBookName}`
      : customDisplayValueFC({
          code,
          id,
          name: referenceBookName,
        })

    controlForDisplaySelect.setValue(
      `${name}OnChange`,
      displayValue as UnpackNestedValue<FieldPathValue<FieldValues, `${string}OnChange`>>,
    )

    onChange?.({
      id,
      code,
      name: referenceBookName,
    })
  }

  const handleSelectOption = ({ id }: ReferenceBookItem) => {
    setDirty(defaultSelected?.id !== id)
  }

  // При нажатии на крестик у селекта очищаем связанные элементы
  const handleClearSelect = () => {
    handlers.onClearSelected?.()

    onChange?.(null)
  }

  // Открываем модалку при нажатии на селект
  const handleClickSelectButton = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!state.selectModalIsOpen && !disabled) {
      handlers.handleSetupModal?.(true)
    }
  }

  // Очищаем состояния при выходе из модалки
  const handleClose = () => {
    handlers.onCloseModal?.()

    disableDirty()

    onBlur?.()
  }

  // Если включено условие и открыты опции, то тултип закрывается
  const enableConditionInputTooltip = enableConditionToShowTooltip
    ? !state.selectModalIsOpen
    : enableConditionToShowTooltip

  return (
    <>
      <button
        id={id}
        type="button"
        tabIndex={-1}
        className={cn(styles.select__wrapper, {
          [styles['select__wrapper--disabled']]: !!disabled,
        })}
        onMouseDown={handleClickSelectButton}
      >
        <ControlledInput
          name={`${name}OnChange`}
          control={controlForDisplaySelect.control}
          inputProps={{
            fixWidth: true,
            view: 'secondary',
            ...restInputProps,
            disabled,
            inputActive: false,
            rightAddons: !disabled ? (
              <Icon src={arrowBackIcon} className={styles.select__arrow} />
            ) : undefined,
            inputCursor: disabled ? 'default' : 'pointer',
            clear: true,
            enableConditionToShowTooltip: enableConditionInputTooltip,
          }}
          onClear={handleClearSelect}
        />
      </button>

      {state.selectModalIsOpen && (
        <HierarchyReferenceBookModal
          isOpen={state.selectModalIsOpen}
          disabled={!isDirty}
          onClose={handleClose}
          onSelect={handleSelectOption}
          onChange={handleModalOnChange}
        />
      )}
    </>
  )
}

export default BaseHierarchyReferenceBookSelect
