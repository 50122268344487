import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNoticeOneSideRejectSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      ruleLinkOneSideRejectReason: {
        propertyId: objectFromPropertyState.ruleLinkOneSideRejectReason?.propertyId,
        type: objectFromPropertyState.ruleLinkOneSideRejectReason?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkOneSideRejectReason?.lastUpdateDt,
      },
      oneSideRejectReasonProof: {
        propertyId: objectFromPropertyState.oneSideRejectReasonProof?.propertyId,
        type: objectFromPropertyState.oneSideRejectReasonProof?.type,
        lastUpdateDt: objectFromPropertyState.oneSideRejectReasonProof?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNoticeOneSideRejectSZPKForm }
