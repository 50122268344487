import React, { FC, memo, useCallback } from 'react'

import Loader from '@components/Loader'
import { MINIMUM_VALUE_LENGTH_TO_SEARCH } from '@components/NewDesign/Select/HierarchyReferenceBookSelect/constants'
import { useHierarchyReferenceBookManager } from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Manager'
import HierarchyReferenceBookItem from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Modal/Item'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { useReferenceBooksByDictionaryName } from '@hooks/new/swr/useReferenceBooksByDictionaryName'

import styles from './List.module.scss'

interface HierarchyReferenceBookListProps {
  onReferenceBookItemChange: (referenceBookItem: ReferenceBookItem) => void
}

const HierarchyReferenceBookList: FC<HierarchyReferenceBookListProps> = ({
  onReferenceBookItemChange,
}) => {
  const { state, handlers } = useHierarchyReferenceBookManager()

  const { referenceBooks, isReferenceBooksLoading } = useReferenceBooksByDictionaryName({
    key: state.dictionaryName
      ? {
          dictionaryName: state.dictionaryName,
          _key: 'referenceBooks',
        }
      : null,
  })

  //Выбор типа рендера, потому что при поиске строится дерево без запросов
  const currentThreeOfRender =
    state.searchPattern.length < MINIMUM_VALUE_LENGTH_TO_SEARCH ? referenceBooks : state.searchThree

  // Выбираем элемент, прокидываем onChange дальше
  const onCodeChangeList = useCallback(
    (referenceBookItem: ReferenceBookItem) => {
      if (handlers.onSelectItem) {
        handlers.onSelectItem(referenceBookItem)
      }

      onReferenceBookItemChange(referenceBookItem)
    },
    [handlers.onSelectItem, onReferenceBookItemChange],
  )

  if (state.searchPattern && state.searchPattern.length < MINIMUM_VALUE_LENGTH_TO_SEARCH)
    return <div className={styles.searchPlaceholder}>Введите поисковый запрос от двух символов</div>

  return (
    <Loader loading={state.threeIsLoading || isReferenceBooksLoading}>
      {currentThreeOfRender?.map((referenceBookItem) => (
        <HierarchyReferenceBookItem
          key={referenceBookItem.id}
          referenceBookItem={referenceBookItem}
          onReferenceBookItemChange={onCodeChangeList}
        />
      ))}
    </Loader>
  )
}

export default memo(HierarchyReferenceBookList)
