import React, { FC, memo } from 'react'

import EditParticipantsOfStatementMode from '@components/Projects/[id]/ParticipantsOfStatement/EditMode'
import ParticipantsOfStatementLoader from '@components/Projects/[id]/ParticipantsOfStatement/Loader'
import { useParticipantsOfStatement } from '@components/Projects/[id]/ParticipantsOfStatement/store'
import ViewParticipantsOfStatementMode from '@components/Projects/[id]/ParticipantsOfStatement/ViewMode'

const ParticipantsOfStatement: FC = () => {
  const {
    state: { viewParticipantModeCondition, isParticipantOfStatementLoading },
  } = useParticipantsOfStatement()

  if (isParticipantOfStatementLoading) return <ParticipantsOfStatementLoader />

  if (viewParticipantModeCondition) return <ViewParticipantsOfStatementMode />

  return <EditParticipantsOfStatementMode />
}

export default memo(ParticipantsOfStatement)
