import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import {
  aAgreementOnChangingCharacteristicsOfObjectsBlockValues,
  mapOfAAgreementOnChangingCharacteristicsOfObjectsMenu,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import AAgreementOnChangingCharacteristicsOfObjectsForms from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import type { AAgreementOnChangingCharacteristicsOfObjectsFormValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'

const mapOfAAgreementOnChangingCharacteristicsOfObjectsForms: FormItemProps = {
  '1': {
    node: <AAgreementOnChangingCharacteristicsOfObjectsForms.One />,
  },
  '2': {
    node: <AAgreementOnChangingCharacteristicsOfObjectsForms.Second />,
  },
  '3': {
    node: <AAgreementOnChangingCharacteristicsOfObjectsForms.Third />,
  },
  '4': {
    node: <AAgreementOnChangingCharacteristicsOfObjectsForms.Fourth />,
  },
  '5': {
    node: <AAgreementOnChangingCharacteristicsOfObjectsForms.Fifth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const AAgreementOnCreationOtherEstateObjectsForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const {
    state: { formIsLoading },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const isFederalSZPK = useWatch({
    name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })
  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  }).length

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAAgreementOnChangingCharacteristicsOfObjectsForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      formIsLoading={formIsLoading}
      mapOfForms={currentForms}
      mapOfMenu={initialMenu ?? mapOfAAgreementOnChangingCharacteristicsOfObjectsMenu}
    />
  )
}

export default memo(AAgreementOnCreationOtherEstateObjectsForm)
