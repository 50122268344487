import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'

const generateMenuListForParametersApplicationReimbursementExpensesMenuState = (
  formValues:
    | ParametersApplicationReimbursementExpensesFormValues
    | UnpackNestedValue<ParametersApplicationReimbursementExpensesFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<ParametersApplicationReimbursementExpensesFormValues>,
): NestedMapOfMenu[] => {
  const getPreparedCompensationFacts = (item: NestedMapOfMenu): NestedMapOfMenu => {
    const compensationFactsError = formErrors?.['3']?.compensationsFacts

    if (!formValues['3'].compensationsFacts || !formValues['3'].compensationsFacts.length)
      return {
        ...item,
        hasError: !!compensationFactsError,
      }

    return {
      ...item,
      hasError: !!compensationFactsError,
      children: formValues['3'].compensationsFacts.map(
        (characteristic, indexOfCharacteristic): NestedMapOfMenu => {
          const characteristicsError =
            formErrors?.['3']?.compensationsFacts?.[indexOfCharacteristic]

          return {
            id: `3.${indexOfCharacteristic}`,
            path: `3.compensationsFacts.${indexOfCharacteristic}`,
            title: `Сведения о поддержке ОРП №${indexOfCharacteristic + 1} ${
              characteristic.compensationFactDate ? `от ${characteristic.compensationFactDate}` : ''
            }`,
            isCollapsed: false,
            hasError: !!characteristicsError,
            parentBaseId: '3',
            children: [],
          }
        },
      ),
    }
  }

  const preparedHashMap = {
    '1': {
      ...initialHashMap['1'],
      hasError: !!formErrors?.[initialHashMap['1'].id],
    },
    '2': {
      ...initialHashMap['2'],
      hasError: !!formErrors?.[initialHashMap['2'].id],
    },
    '3': getPreparedCompensationFacts(initialHashMap['3']),
  }

  return Object.values(preparedHashMap)
}

export { generateMenuListForParametersApplicationReimbursementExpensesMenuState }
