import React, { FC, memo } from 'react'

import DocumentForm from '@components/DocumentFormComponents/Form'
import type { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOFMenu } from '@components/Forms/CreateStatement/const'
import CreateStatementForms from '@components/Forms/CreateStatement/Forms'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'

const mapOfStatementForms: FormItemProps = {
  investor: {
    node: <CreateStatementForms.Investor />,
  },
  generalInfo: {
    node: <CreateStatementForms.GeneralInfo />,
  },
  stage: {
    node: <CreateStatementForms.StageForm />,
  },
  compensation: {
    node: <CreateStatementForms.ExpensesCompensationArray />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  participantsAndInvestments: {
    node: <CreateStatementForms.Investments />,
  },
  plannedTax: {
    node: <CreateStatementForms.PlannedTaxForm />,
  },
} as const

const CreateStatementForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const {
    state: { formIsLoading },
  } = useCreateStatementManager()

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOFMenu}
      mapOfForms={mapOfStatementForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(CreateStatementForm)
