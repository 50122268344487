import { FC } from 'react'

import AuthorizedOrganizations from '@components/AuthorizedOrganizations'
import Layouts from '@layouts'

const AuthorizedOrganizationsPage: FC = () => {
  return (
    <Layouts.Main>
      <AuthorizedOrganizations />
    </Layouts.Main>
  )
}

export default AuthorizedOrganizationsPage
