import { FC } from 'react'

import ExpensesCompensationArrayForm from '@components/Forms/CreateStatementOld/Forms/Compensations'
import GeneralInfoForm from '@components/Forms/CreateStatementOld/Forms/GeneralInfo'
import InvestmentsForm from '@components/Forms/CreateStatementOld/Forms/Investments'
import InvestorAddressForm from '@components/Forms/CreateStatementOld/Forms/InvestorAddress'
import InvestorGeneralInfoForm from '@components/Forms/CreateStatementOld/Forms/InvestorGeneralInfo'
import PlannedTaxes from '@components/Forms/CreateStatementOld/Forms/PlannedTaxes'
import StagesBlockForm from '@components/Forms/CreateStatementOld/Forms/Stages'

interface CreateStatementFormsProps extends FC {
  InvestorGeneralInfo: typeof InvestorGeneralInfoForm
  InvestorAddress: typeof InvestorAddressForm
  GeneralInfo: typeof GeneralInfoForm
  Investments: typeof InvestmentsForm
  ExpensesCompensationArray: typeof ExpensesCompensationArrayForm
  StagesForm: typeof StagesBlockForm
  PlannedTaxesForm: typeof PlannedTaxes
}

const CreateStatementForms: CreateStatementFormsProps = () => null

CreateStatementForms.InvestorGeneralInfo = InvestorGeneralInfoForm
CreateStatementForms.InvestorAddress = InvestorAddressForm
CreateStatementForms.GeneralInfo = GeneralInfoForm
CreateStatementForms.Investments = InvestmentsForm
CreateStatementForms.ExpensesCompensationArray = ExpensesCompensationArrayForm
CreateStatementForms.StagesForm = StagesBlockForm
CreateStatementForms.PlannedTaxesForm = PlannedTaxes

export default CreateStatementForms
