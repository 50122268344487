import React, { FC, useMemo } from 'react'
import { Path, UseFormReturn, useWatch } from 'react-hook-form'

import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { financialModelBlockValues } from '@components/Forms/FinancialModelForm/const'
import { useFinancialModelManager } from '@components/Forms/FinancialModelForm/Manager'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import {
  FinancialModelFieldArrayControlUpdateWatcher,
  FinancialModelFieldsControlUpdateWatcher,
} from '@components/Forms/FinancialModelForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { billionIntValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useBooleanState } from '@hooks/useBooleanState'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

import styles from './InvestYears.module.scss'

interface InvestYearsProps {
  formInstance: UseFormReturn<FinancialModelFormValues>
  subscribableControl: SubscribableFormControlProps<FinancialModelFormValues>
  onUpdateBillionValue: (pathName: Path<FinancialModelFormValues>) => Promise<void>
}

const InvestYears: FC<InvestYearsProps> = ({
  formInstance,
  subscribableControl,
  onUpdateBillionValue,
}) => {
  const { fields: investYears } = useFieldArraySubscribableControl<
    FinancialModelFormValues,
    '2.investmentYears',
    'id'
  >({
    control: formInstance?.control,
    name: financialModelBlockValues['2'].investmentYears,
    watcher: FinancialModelFieldArrayControlUpdateWatcher,
    keyName: 'id',
  })

  const {
    state: { editMode, blockViewIsValidating },
  } = useFinancialModelManager()
  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: FinancialModelFieldsControlUpdateWatcher,
  })

  const startInvestStage = useWatch({
    name: financialModelBlockValues['1'].startInvestStage,
    control: formInstance.control
  })

  const endInvestStage = useWatch({
    name: financialModelBlockValues['1'].endInvestStage,
    control: formInstance.control
  })

  const conditionToRenderInvestYears = !!(startInvestStage && endInvestStage && investYears.length)

  const {
    booleanState: isMountChildren,
    setBooleanStateToTrue: mountChildren,
    setBooleanStateToFalse: unmountChildren,
  } = useBooleanState(conditionToRenderInvestYears)

  const { getCollapseProps } = useCollapse({
    isExpanded: conditionToRenderInvestYears,
    onExpandStart: () => {
      mountChildren()
    },
    onCollapseEnd: () => {
      unmountChildren()
    },
  })

  const debouncedHandleUpdateBillionValue = useDebouncedCallback(onUpdateBillionValue, 5000)

  const investYearsRender = useMemo(() => {
    if (!isMountChildren) return null

    return investYears.map((item, index) => {
      const formName = `${financialModelBlockValues['2'].investmentYears}.${index}` as const

      const investYear = formInstance.watch(`${formName}.investmentYear`)

      const preparedInvestYear = isDateValidForDayjs(investYear)
        ? DayjsService.dayjs(investYear, objOfDateFormats.defaultFormat).format(
            objOfDateFormats.yearFormat.yearOnly,
          )
        : investYear

      return (
        <RowWithBorder
          disableTitleLeftPadding
          disableBottomDefaultStyles
          firstColumnSize={7}
          secondColumnSize={5}
          key={item.id}
          title={preparedInvestYear}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.investmentValue`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.investmentValue`,
                rules: {
                  validate: (value) => {
                    if (!isString(value)) return

                    return billionIntValidate(value)
                  },
                },
                inputProps: {
                  label: 'Общий объем за год, млрд',
                },
                onBlur: () =>
                  setTimeout(() => onUpdateBillionValue?.(`${formName}.investmentValue`), 0),
                onChange: () =>
                  setTimeout(
                    () => debouncedHandleUpdateBillionValue?.(`${formName}.investmentValue`),
                    0,
                  ),
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
      )
    })
  }, [formInstance, investYears, isMountChildren, subscribableControl])

  return (
    <ContainerWithBorder>
      {!conditionToRenderInvestYears && (
        <Row className={styles['investYears__text-wrapper']}>
          <Col xs={12}>
            <Typography variant={'bodyMMedium'}>
              Укажите год начала и окончания инвестиционной стадии.
            </Typography>
            <Typography variant={'bodyMRegular'}>
              После этого вы сможете указать объемы инвестиций по годам
            </Typography>
          </Col>
        </Row>
      )}
      <div {...getCollapseProps()}>
        <Stack direction={'vertical'} gap={3}>
          {investYearsRender}
        </Stack>
      </div>
    </ContainerWithBorder>
  )
}

export default InvestYears
