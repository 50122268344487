import env from '@config'

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL
const SUPPORT_EMAIL = env.REACT_APP_SUPPORT_EMAIL

const mapOfEmails = {
  REACT_APP_ECONOMY_SUPPORT_EMAIL: ECONOMY_SUPPORT_EMAIL,
  REACT_APP_SUPPORT_EMAIL: SUPPORT_EMAIL,
}

export { mapOfEmails }
