import { ApplicationOfConclusionAgreementSZPKFormValues } from '../types'

import { generatePropertiesObjectForApplicationOfConclusionAgreementSZPKRegisterForm } from './PropertiesObject.adapter'
import { generateRHFObjectForApplicationOfConclusionAgreementSZPKRegisterForm } from './RHF.adapter'

const useApplicationOfConclusionAgreementSZPKRegisterAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): ApplicationOfConclusionAgreementSZPKFormValues =>
    generateRHFObjectForApplicationOfConclusionAgreementSZPKRegisterForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForApplicationOfConclusionAgreementSZPKRegisterForm(
      objectFromPropertyState,
    )

  return { generateRHFObject, generatePropertiesObject }
}

export { useApplicationOfConclusionAgreementSZPKRegisterAdapters }
