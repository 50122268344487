import { useShowMenuLinearGradient } from './useShowMenuLinearGradient'

export const useShowMenuLinearGradientManager = () => {
  const { isShowLineGradient, handleShowLineGradient } = useShowMenuLinearGradient()

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    handleShowLineGradient(event.currentTarget)
  }

  const handleResizeObserverCallback: ResizeObserverCallback = ([entry]) =>
    handleShowLineGradient(entry.target as HTMLDivElement)

  return { isShowLineGradient, handleOnScroll, handleResizeObserverCallback }
}
