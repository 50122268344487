import { type FC, type ReactNode, memo } from 'react'

import NpaAddonOld from '@components/Projects/[id]/DocumentLayout/Addons/NpaAddonOld'
import AttachmentDraftContainer from '@components/Projects/[id]/DocumentLayout/Addons/OnlyDraft'
import { DocumentsType } from '@constants/types'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'

const { isDocumentReadEditPermission, isDocumentReadAddSignPermission } = DocumentsHelpersService

interface AttachmentAddonInvestorControllerProps {
  isDraftExists: boolean
  documentType: string
  permissions: number
  projectId: string
  dfoId: string
  documentSetId: string
  children: ReactNode
}

const AttachmentAddonInvestorController: FC<AttachmentAddonInvestorControllerProps> = ({
  isDraftExists,
  permissions,
  documentType,
  children,
  ...addonBaseProps
}) => {
  const isStatementDocumentType = documentType === DocumentsType.STATEMENT
  const isNpaDocumentType = documentType === DocumentsType.NPA_LIST

  const isWritePermission =
    isDocumentReadEditPermission(permissions) || isDocumentReadAddSignPermission(permissions)

  const isShowStatement = isStatementDocumentType && isWritePermission && !isDraftExists
  const isShowNpa = isNpaDocumentType && isWritePermission

  if (isShowStatement) {
    return <AttachmentDraftContainer {...addonBaseProps} />
  }

  if (isShowNpa) {
    return <NpaAddonOld {...addonBaseProps} />
  }

  return <>{children}</>
}

export default memo(AttachmentAddonInvestorController)
