import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import { firstSectionValidationMap } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/1/validation'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const First = () => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getControllerProps, getSingleSelectProps, getSubscribableControlProps, getInputProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null
  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: informationOfExecutionConditionsSZPKBlockValues['1'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Полное наименование'}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                  tooltipProps: {
                    width: 'm',
                  },
                }}
                controllerProps={{
                  ...getControllerProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['1'].investorFullName,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: informationOfExecutionConditionsSZPKBlockValues['1'].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={{
                  ...getControllerProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['1'].investorInn,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: informationOfExecutionConditionsSZPKBlockValues['1'].investorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={{
                  ...getControllerProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['1'].investorOgrn,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: informationOfExecutionConditionsSZPKBlockValues['1'].investorAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Адрес'}
                controllerProps={{
                  ...getControllerProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['1'].investorAddress,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group
          disableBottomBorder
          title={'Уполномоченное лицо организации'}
          groupClassName={'mt-0'}
        >
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['1'].investorHeaderName,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item.headerName || '',
                          value: item.id,
                        }),
                        controllerProps: {
                          name: informationOfExecutionConditionsSZPKBlockValues['1']
                            .investorHeaderName,
                          rules: firstSectionValidationMap.investorHeaderName,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Фамилия, Имя, Отчество',
                          },
                          onChangeFormValue: () =>
                            setTimeout(() => {
                              handleChangeValue?.(
                                informationOfExecutionConditionsSZPKBlockValues['1']
                                  .investorHeaderName,
                              )
                            }, 0),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['1'].investorHeaderPosition,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: informationOfExecutionConditionsSZPKBlockValues['1']
                      .investorHeaderPosition,
                    inputProps: {
                      disabled: true,
                      label: 'Должность',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          informationOfExecutionConditionsSZPKBlockValues['1']
                            .investorHeaderPosition,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          informationOfExecutionConditionsSZPKBlockValues['1']
                            .investorHeaderPosition,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Group>
      </Stack>
    </Container>
  )
}

export default First
