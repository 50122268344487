import store from 'store2'

class ReleaseMigrationLSService {
  private readonly _storeName = 'lastVersionAppliedMigration'

  getLastMigrationVersion() {
    return store.get(this._storeName)
  }

  setLastMigrationVersion(lastMigrationVersion: string) {
    store.set(this._storeName, lastMigrationVersion, true)
  }
}

export default new ReleaseMigrationLSService()
