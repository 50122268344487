import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import Error500Fallback from '@components/DataFallback/500Error'
import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Icon from '@components/Icon'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { Tab, Tabs } from '@components/NewDesign/Tabs'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import Statements from '@components/NewStatements'
import SelectTooltip from '@components/PreparedTooltips/Select'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import { SortKeyType } from '@components/SortBy/SortBy'
import TagItem from '@components/TagNew/Item'
import { filterProjectsByActions, RolesTypes, sortProjectsByActions } from '@constants/types'
import PageContainer from '@containers/PageContainer'
import { useAPIContext } from '@context/APIContext'
import { ProjectListProps } from '@context/APIContext/hooks/useProjectsApi'
import { DfoGroupTypes } from '@context/APIContext/types'
import { useAuthContext } from '@context/AuthContext'
import { fromStringBooleanToNative } from '@helpers/boolean/fromStringBooleanToNative'
import { isAxiosError, isJsError, isString } from '@helpers/checkTypes'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { fromStringNullOfUndefinedToNative } from '@helpers/string/fromStringNullOfUndefinedToNative'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useDfosDigitizing } from '@hooks/new/swr/useDfosDigitizing'
import { useProjects } from '@hooks/new/swr/useProjects'
import useDebounce from '@hooks/useDebounce'
import useLoadMore from '@hooks/useLoadMore'
import { useQueryManager } from '@hooks/useQueryManager'
import searchIcon from '@icons/action/search.svg'
import DropDownIconNew from '@icons/navigation/arrow-drop-down-new.svg'
import { formNames } from '@routes/Audits/constants'
import {
  mapOfDefaultSortActionsByFilterActions,
  mapOfSortTooltipNames,
  mapOfStatementFilterActions,
  mapOfStatementSortActions,
  statementBasketsDefaultOptions,
  statementDefaultFormValues,
  statementFiltersDefaultValues,
  statementSortDefaultValues,
  statementTableFormNames,
} from '@routes/NewStatements/const'
import StatementFiltersControl from '@routes/NewStatements/FilterTooltip'
import OptionTooltipContent from '@routes/NewStatements/OptionTooltipContent'
import type {
  BasketFilterValue,
  FilterActionAllowed,
  FilterStage,
  FilterStatementLvl,
  SortFilterActions,
  StatementFilterFormValues,
  StatementSortFormValues,
  TFormStatement,
  TStatementParams,
} from '@routes/NewStatements/types'
import {
  PreparedProjectToRender,
  TGetAllProjectsCounter,
  TProjectTags,
} from '@services/Projects/Project.entity'
import { useProjectsStatePage } from '@services/ProjectsState/ProjectsState.entity'
import { useProjectsPageStateManager } from '@services/ProjectsState/ProjectsState.manager'
import { projectsStateInitial } from '@services/ProjectsState/ProjectsState.store'
import { AxiosError } from 'axios'
import cn from 'classnames'
import cloneDeep from 'clone-deep'

import Layouts from '../../layouts'

import {
  BasketFiltersService,
  BasketsRolesMap,
  StatementFiltersService,
  StatementPersistService,
} from './helpers'
import styles from './StatementsPage.module.scss'

const DEFAULT_PROJECTS_SIZE = 30
const DROPDOWN_TEXT_LINES_LIMIT = 2

const {
  getFiltersToSendAPI,
  getCountOfActiveFilters,
  prepareQueryForApply,
  convertFiltersToPersist,
  getConditionToSessionPersistFilters,
} = StatementFiltersService
const statementPersistService = new StatementPersistService()

const StatementsPage = () => {
  const {
    projectsApi: { getAllProjects, getAllProjectsCount },
  } = useAPIContext()

  const { checkingRole, getMainRole, userInfoState } = useAuthContext()
  const { searchParams, queryUtils } = useQueryManager()

  const [basketFiltersService] = useState(
    () => new BasketFiltersService(getMainRole?.() as BasketsRolesMap),
  )
  const [allBasketsCounter, setAllBasketCounter] = useState<Record<
    string,
    TGetAllProjectsCounter | AxiosError<unknown>
  > | null>(null)
  const [isLoadingProjectsCounters, setIsLoadingProjectsCounters] = useState<boolean>(false)

  const sessionPersistedFiltersState = useRef<StatementFilterFormValues | null>(null)

  const projectsTransitionState = useProjectsStatePage()
  const { setTransitionProjectsPageState, toggleTransitionFoldState } =
    useProjectsPageStateManager()

  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
  } = useManualTooltipControl()

  const transitionFromProjectsPage = !!projectsTransitionState.transitionId

  const getInitialFormStateFilters = useCallback((): Partial<TFormStatement> => {
    //В этом случае игнорируем LC, просто его перезаписываем
    const transitionWasFromLink = Array.from(searchParams).length
    const persistedFiltersInLc = statementPersistService.getFiltersFromLC(userInfoState?.sub || '')

    const transitionFromLC =
      !transitionWasFromLink && persistedFiltersInLc && Object.keys(persistedFiltersInLc).length

    const getFieldByDataLayer = (fieldName: keyof TStatementParams) => {
      let valueToReturn = statementFiltersDefaultValues[fieldName] ?? undefined

      if (transitionWasFromLink) {
        valueToReturn = queryUtils.getQuery(fieldName)
      }

      if (transitionFromProjectsPage) {
        valueToReturn = projectsTransitionState.transitionFilters[fieldName]
      }

      if (transitionFromLC) {
        valueToReturn = persistedFiltersInLc[fieldName]
      }

      return fromStringNullOfUndefinedToNative(valueToReturn)
    }

    const persistObject: Required<TStatementParams> = {
      filterActions:
        (getFieldByDataLayer('filterActions') as SortFilterActions) ||
        statementSortDefaultValues.filterActions,
      sortActions:
        (getFieldByDataLayer('sortActions') as SortKeyType) ||
        statementSortDefaultValues.sortActions,
      tagRadioGroup:
        (getFieldByDataLayer('tagRadioGroup') as TProjectTags | 'all') ||
        statementDefaultFormValues.tagRadioGroup,
      searchString:
        (getFieldByDataLayer('searchString') as string) || statementDefaultFormValues.searchString,
      actionAllowed: getFieldByDataLayer('actionAllowed') as FilterActionAllowed,
      statementLvl: getFieldByDataLayer('statementLvl') as FilterStatementLvl,
      dfoStageExclude: isString(getFieldByDataLayer('dfoStageExclude'))
        ? JSON.parse(getFieldByDataLayer('dfoStageExclude') as string)
        : getFieldByDataLayer('dfoStageExclude'),
      dfoStageInclude: isString(getFieldByDataLayer('dfoStageInclude'))
        ? JSON.parse(getFieldByDataLayer('dfoStageInclude') as string)
        : getFieldByDataLayer('dfoStageInclude'),
      stage: getFieldByDataLayer('stage') as FilterStage,
      isRegistered: isString(getFieldByDataLayer('isRegistered'))
        ? fromStringBooleanToNative(getFieldByDataLayer('isRegistered') as string)
        : (getFieldByDataLayer('isRegistered') as boolean),
      basketTagGroup:
        (getFieldByDataLayer('basketTagGroup') as BasketFilterValue) ||
        statementDefaultFormValues.basketTagGroup,
    }

    const filtersState = {
      statementLvl: persistObject.statementLvl,
      dfoStageExclude: persistObject.dfoStageExclude,
      dfoStageInclude: persistObject.dfoStageInclude,
      isRegistered: persistObject.isRegistered,
      stage: persistObject.stage,
      actionAllowed: persistObject.actionAllowed,
    }

    return {
      sortState: {
        sortActions: persistObject.sortActions,
        filterActions: persistObject.filterActions,
      },
      filtersState: filtersState,
      searchString: persistObject.searchString,
      tagRadioGroup: persistObject.tagRadioGroup,
      basketTagGroup: persistObject.basketTagGroup,
    }
  }, [])

  const [searchPlaceholderCondition, setSearchPlaceholderCondition] = useState<boolean>(false)
  const { control, watch, getValues, setValue, reset } = useForm<TFormStatement>({
    defaultValues: statementDefaultFormValues,
  })

  const allFormValues = watch()
  const debouncedSearchString = useDebounce(allFormValues.searchString, 300)

  const keyFormUseProjects = useCallback(
    (page, previousPageData) => {
      if (previousPageData && !previousPageData.length) return null

      const mainFilters = getFiltersToSendAPI(allFormValues)
      const currentSearchString =
        debouncedSearchString.trim().length >= 3 ? debouncedSearchString : undefined

      const filtersToApply = {
        ...mainFilters,
        searchString: currentSearchString,
      }

      return {
        ...filtersToApply,
        page,
        size: DEFAULT_PROJECTS_SIZE,
        _key: 'projects',
      }
    },
    [allFormValues, debouncedSearchString],
  )

  const isBasketsRender = useMemo(() => {
    const mainRoleOfUser = getMainRole?.()
    return !!(
      (allFormValues.tagRadioGroup === DfoGroupTypes.SZPK ||
        allFormValues.tagRadioGroup === DfoGroupTypes.EXTRA) &&
      mainRoleOfUser &&
      mainRoleOfUser !== RolesTypes.OMSU &&
      mainRoleOfUser !== RolesTypes.FK
    )
  }, [allFormValues.tagRadioGroup, getMainRole])

  //Проверяются наличие проектов у пользователя (орган власти)
  const {
    projects,
    initialHookProjects,
    mutate,
    isLoadingProjects,
    error: projectsError,
    goToNextPage,
  } = useProjects({
    key: keyFormUseProjects,
    config: { revalidateFirstPage: false, revalidateAll: false },
    pageSize: DEFAULT_PROJECTS_SIZE,
  })

  const isRoleMER = useMemo(() => checkingRole?.(RolesTypes.MER), [checkingRole])

  const { dfosDigitizing } = useDfosDigitizing({
    key: isRoleMER
      ? {
          _key: 'dfosDigitizing',
        }
      : null,
    config: {
      revalidateOnMount: true,
    },
  })

  const currentBasketsOptions = useMemo(() => {
    return (
      basketFiltersService?.getBasketOptionsByTagGroup(
        allFormValues.tagRadioGroup as TProjectTags,
      ) || statementBasketsDefaultOptions
    )
  }, [allFormValues.tagRadioGroup, basketFiltersService])

  const getCurrentIdsOfBasketOptions = useCallback(() => {
    return currentBasketsOptions.map((basketOption) => basketOption.id)
  }, [currentBasketsOptions])

  const getAllBasketCounters = async () => {
    const idsOfBasketOptions = getCurrentIdsOfBasketOptions()

    const allFormValues = getValues()

    const generatedArrayOfCounterPromises = (() => {
      return idsOfBasketOptions.map((idOfBasket) => {
        const filtersByBasketType = basketFiltersService.getFiltersByBasketType(
          allFormValues.tagRadioGroup as TProjectTags,
          idOfBasket as BasketFilterValue,
        ) as StatementFilterFormValues

        const currentStatementValues = {
          ...statementDefaultFormValues,
          tagRadioGroup: allFormValues.tagRadioGroup,
          sortState: allFormValues.sortState,
        }

        const currentSearchString =
          allFormValues.searchString.trim().length >= 3 ? allFormValues.searchString : undefined

        const mainFilters = getFiltersToSendAPI({
          ...currentStatementValues,
          filtersState: filtersByBasketType,
        })

        return getAllProjectsCount({
          ...mainFilters,
          searchString: currentSearchString,
          page: 0,
          size: DEFAULT_PROJECTS_SIZE,
        } as ProjectListProps)
      })
    })()

    try {
      const result = await Promise.allSettled(generatedArrayOfCounterPromises)

      const readyCounters = result.reduce((previousValue, currentPromise, index) => {
        if (currentPromise.status === 'fulfilled') {
          return {
            ...previousValue,
            [idsOfBasketOptions[index]]: currentPromise.value,
          }
        }

        if (currentPromise.status === 'rejected') {
          return {
            ...previousValue,
            [idsOfBasketOptions[index]]: currentPromise.reason,
          }
        }

        return previousValue
      }, {} as Record<string, TGetAllProjectsCounter>)

      return readyCounters
    } catch (e) {
      console.error(e)
    }
  }

  const calculateBasketCountersToRender = () => {
    const allFormValues = getValues()

    if (
      allFormValues.tagRadioGroup !== DfoGroupTypes.SZPK &&
      allFormValues.tagRadioGroup !== DfoGroupTypes.EXTRA
    )
      return
    ;(async () => {
      setAllBasketCounter(null)
      setIsLoadingProjectsCounters(true)

      try {
        const result = await getAllBasketCounters()

        setAllBasketCounter(result ?? null)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoadingProjectsCounters(false)
      }
    })()
  }

  const { getObserveRef } = useLoadMore({ onLoadMore: goToNextPage })

  const isRoleOMSU = useMemo(() => checkingRole?.(RolesTypes.OMSU), [checkingRole])
  const isFiltersRender = useMemo(() => {
    if (getMainRole?.() === RolesTypes.FK) return true

    return (
      allFormValues.tagRadioGroup === 'all' ||
      allFormValues.tagRadioGroup === DfoGroupTypes.MONITORING ||
      allFormValues.tagRadioGroup === DfoGroupTypes.COST_RECOVERY
    )
  }, [allFormValues.tagRadioGroup, getMainRole])

  const isDefaultFilterActions =
    allFormValues.sortState.filterActions === mapOfStatementFilterActions.initial

  //Стадия инициализации
  useEffect(() => {
    const initialFilters = getInitialFormStateFilters()

    //Инициалиализация состояния, если мы находимся не в корзинах, а там, где работает общий фильтр.
    if (
      initialFilters.filtersState &&
      initialFilters.tagRadioGroup &&
      initialFilters.basketTagGroup &&
      getConditionToSessionPersistFilters(
        initialFilters.tagRadioGroup,
        initialFilters.basketTagGroup,
      )
    ) {
      sessionPersistedFiltersState.current = cloneDeep(initialFilters.filtersState)
    }

    reset(initialFilters)

    setTimeout(() => {
      calculateBasketCountersToRender()
    }, 0)
  }, [])

  //После применение defaultValues необходимо либо перезаписывать LC, либо снова выстраивать кверики
  useEffect(() => {
    //После перехода очищаем transitionState
    if (transitionFromProjectsPage) {
      (async () => {
        await mutate(async (data) => {
          if (data) {
            const newData = [...data]

            const keyToUpdate = keyFormUseProjects(
              projectsTransitionState.transitionPage,
              initialHookProjects?.[projectsTransitionState.transitionPage - 1],
            )

            if (!keyToUpdate) return newData

            try {
              const newPageData = await getAllProjects(keyToUpdate as ProjectListProps)

              //Обновление страницы после перехода
              newData[projectsTransitionState.transitionPage] = newPageData
            } catch {}

            return newData
          }
        }, false)

        setTimeout(() => {
          const elementToScroll = document.querySelector(
            `[data-id="${projectsTransitionState.transitionId}"]`,
          )

          if (elementToScroll) {
            elementToScroll.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            })
          } else {
            const firstElementPageToScroll = document.querySelector(
              `[data-page="${projectsTransitionState.transitionPage}"]`,
            )

            firstElementPageToScroll?.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            })
          }

          //Обнуление состояния кроме открытых папок
          setTransitionProjectsPageState({
            transitionId: projectsStateInitial.transitionId,
            transitionFilters: projectsStateInitial.transitionFilters,
          })
        }, 0)
      })()
    }

    handleChangeAnyFilterOfStatement()
  }, [])

  useUpdateEffect(() => {
    setSearchPlaceholderCondition(false)

    if (debouncedSearchString.length && !projects?.length) setSearchPlaceholderCondition(true)
  }, [projects, allFormValues.searchString])

  useUpdateEffect(() => {
    if (debouncedSearchString.length >= 3) {
      queryUtils.addQuery({
        query: {
          searchString: debouncedSearchString,
        },
      })

      calculateBasketCountersToRender()

      return
    }

    calculateBasketCountersToRender()

    queryUtils.removeQuery({ query: 'searchString' })
  }, [debouncedSearchString])

  const handleApplySearchParamsFromFilters = useCallback(
    (readyParams: TStatementParams) => {
      queryUtils.clearAllQuery()

      const queryToApply = prepareQueryForApply(readyParams)

      if (!Object.keys(queryToApply).length) return

      queryUtils.addQuery({
        query: queryToApply,
      })
    },
    [queryUtils],
  )

  const handleChangeAnyFilterOfStatement = useCallback(() => {
    const formFilters = getValues()

    const readyFiltersToPersistQuery = convertFiltersToPersist(formFilters)
    handleApplySearchParamsFromFilters(readyFiltersToPersistQuery)

    const readyFiltersToPersistLC = prepareQueryForApply({
      ...readyFiltersToPersistQuery,
      searchString: undefined,
    })

    if (!Object.keys(readyFiltersToPersistLC).length)
      return statementPersistService.resetFiltersFromLC(userInfoState?.sub || '')

    statementPersistService.writeFiltersToLC(userInfoState?.sub || '', readyFiltersToPersistLC)
  }, [getValues, handleApplySearchParamsFromFilters])

  const handleChangeFiltersAfterRender = useCallback(() => {
    setTimeout(handleChangeAnyFilterOfStatement, 0)
    setTimeout(calculateBasketCountersToRender, 0)
  }, [handleChangeAnyFilterOfStatement])

  const handleSelectAction = useCallback(
    (
        key: StatementSortFormValues['filterActions'] | StatementSortFormValues['sortActions'],
        name: keyof StatementSortFormValues,
      ) =>
      () => {
        if (
          name === mapOfSortTooltipNames.filterActions &&
          mapOfDefaultSortActionsByFilterActions[key]
        ) {
          const defaultSortValue = mapOfDefaultSortActionsByFilterActions[key]
          setValue(`${statementTableFormNames.sortState}.sortActions`, defaultSortValue)
        }

        setValue(`${statementTableFormNames.sortState}.${name}`, key)
        handleChangeFiltersAfterRender()
      },
    [handleChangeFiltersAfterRender, setValue],
  )

  const handleResetStatementFilters = useCallback(() => {
    setValue(statementTableFormNames.filtersState, statementFiltersDefaultValues)
    sessionPersistedFiltersState.current = null

    handleChangeFiltersAfterRender()
  }, [handleChangeFiltersAfterRender, setValue])

  const handleApplyStatementFilters = useCallback(
    (filtersState) => {
      setValue(statementTableFormNames.filtersState, filtersState)

      handleChangeFiltersAfterRender()
    },
    [handleChangeFiltersAfterRender, setValue],
  )

  const handleApplyStatementFiltersFromFilterComponent = useCallback(
    (filtersState) => {
      setValue(statementTableFormNames.filtersState, filtersState)
      sessionPersistedFiltersState.current = cloneDeep(filtersState)

      handleChangeFiltersAfterRender()
    },
    [handleChangeFiltersAfterRender, setValue],
  )

  const handleRestoreSessionPersistedFilters = (
    tagGroup: TFormStatement['tagRadioGroup'],
    basketGroup: TFormStatement['basketTagGroup'],
  ) => {
    const currentSessionPersisted = (() => sessionPersistedFiltersState.current)()

    if (currentSessionPersisted && getConditionToSessionPersistFilters(tagGroup, basketGroup)) {
      return currentSessionPersisted
    }

    return
  }

  const handleChangeTagRadioGroup = useCallback(() => {
    setIsLoadingProjectsCounters(true)

    //Сбрасываем корзины, при переходе по высшим вкладкам.
    setValue(statementTableFormNames.basketTagGroup, statementDefaultFormValues.basketTagGroup)

    const { tagRadioGroup, basketTagGroup } = getValues()

    const sessionFiltersToApply = handleRestoreSessionPersistedFilters(
      tagRadioGroup,
      basketTagGroup,
    )

    if (sessionFiltersToApply) {
      handleApplyStatementFilters(sessionFiltersToApply)
      return
    }

    const filtersByBasketType = basketFiltersService.getFiltersByBasketType(
      tagRadioGroup as TProjectTags,
      basketTagGroup,
    )

    if (!Object.keys(filtersByBasketType).length) {
      handleApplyStatementFilters(statementFiltersDefaultValues)
      return
    }

    handleApplyStatementFilters(filtersByBasketType)
  }, [])

  const handleChangeBasket = (event, { selectedId: selectedBasket }) => {
    setIsLoadingProjectsCounters(true)

    setValue(statementTableFormNames.basketTagGroup, selectedBasket)

    const sessionFiltersToApply = handleRestoreSessionPersistedFilters(
      allFormValues.tagRadioGroup,
      selectedBasket,
    )

    if (sessionFiltersToApply) {
      handleApplyStatementFilters(sessionFiltersToApply)
      return
    }

    const filtersByBasketType = basketFiltersService.getFiltersByBasketType(
      allFormValues.tagRadioGroup as TProjectTags,
      selectedBasket,
    )

    if (!Object.keys(filtersByBasketType).length) return

    handleApplyStatementFilters(filtersByBasketType)
  }

  const handleProjectClick = useCallback(
    (id: string, page?: number) => () => {
      setTransitionProjectsPageState({
        transitionId: id,
        transitionPage: page ?? projectsStateInitial.transitionPage,
        transitionFilters: prepareQueryForApply(convertFiltersToPersist(getValues())),
      })
    },
    [getValues, setTransitionProjectsPageState],
  )

  const handleFoldProject = useCallback(
    (id: string) => () => {
      toggleTransitionFoldState(id)
    },
    [],
  )

  const filterSelectContent = useMemo(
    () => (
      <>
        {Object.entries(filterProjectsByActions).map(([key, value]) => (
          <OptionTooltipContent
            key={key}
            isActive={allFormValues.sortState.filterActions === key}
            name={mapOfSortTooltipNames.filterActions}
            keyName={key as StatementSortFormValues['filterActions']}
            handleSelectOption={handleSelectAction}
          >
            {value}
          </OptionTooltipContent>
        ))}
      </>
    ),
    [allFormValues.sortState.filterActions, handleSelectAction],
  )

  const sortSelectContent = useMemo(
    () => (
      <>
        {Object.entries(sortProjectsByActions).map(([key, value]) => (
          <OptionTooltipContent
            key={key}
            isActive={allFormValues.sortState.sortActions === key}
            name={mapOfSortTooltipNames.sortActions}
            keyName={key as StatementSortFormValues['sortActions']}
            handleSelectOption={handleSelectAction}
            disabled={isDefaultFilterActions}
          >
            {value}
          </OptionTooltipContent>
        ))}
      </>
    ),
    [allFormValues.sortState.sortActions, handleSelectAction, isDefaultFilterActions],
  )

  const isRegisteredStatementsRender =
    allFormValues.tagRadioGroup === 'all' || allFormValues.tagRadioGroup === DfoGroupTypes.SZPK

  const countOfActiveFilters = useMemo(() => {
    const preparedObjectToCount = isRegisteredStatementsRender
      ? allFormValues.filtersState
      : { ...allFormValues.filtersState, isRegistered: undefined }

    return getCountOfActiveFilters(preparedObjectToCount)
  }, [allFormValues.filtersState, isRegisteredStatementsRender])
  const isCostRecoveryTagActive = useMemo(
    () => allFormValues.tagRadioGroup === DfoGroupTypes.COST_RECOVERY,
    [allFormValues.tagRadioGroup],
  )

  const noDataCondition = useMemo(() => {
    return (
      !projectsError && !isLoadingProjects && !projects?.length && !debouncedSearchString.length
    )
  }, [debouncedSearchString.length, isLoadingProjects, projects?.length, projectsError])

  const dropdownText = useMemo(
    () =>
      filterProjectsByActions[allFormValues.sortState.filterActions]
        ?.split(' ', DROPDOWN_TEXT_LINES_LIMIT)
        .join(' ')
        .toLowerCase(),
    [allFormValues.sortState.filterActions],
  )

  const dropdownIcon = isDefaultFilterActions
    ? undefined
    : {
        src: DropDownIconNew,
        className: cn(styles.button__icon, {
          [styles['button__icon--active']]:
            allFormValues.sortState.sortActions === mapOfStatementSortActions.asc,
        }),
      }

  const preparedProjects: PreparedProjectToRender[] = useMemo(() => {
    if (!projects || !projects.length) return []

    return projects.map((project) => {
      const foundDfosDigitizing = (dfosDigitizing || [])?.find(
        ({ projectId }) => projectId === project.id,
      )?.dfos

      const preparedDfo = project.dfos.map((dfo) => {
        const digitizingInProcess = foundDfosDigitizing?.find(
          ({ dfoId }) => dfoId === dfo.id,
        )?.digitizingInProcess

        return {
          ...dfo,
          digitizingInProcess,
        }
      })

      return {
        ...project,
        dfos: preparedDfo,
      }
    })
  }, [projects, dfosDigitizing])

  const projectsRender = useMemo(() => {
    return (preparedProjects ?? []).map((projectProps, index, list) => {
      const initialToggleState =
        transitionFromProjectsPage &&
        (projectProps.id === projectsTransitionState.transitionId ||
          projectsTransitionState.transitionFoldStates[projectProps.id])

      const filterTagType =
        (allFormValues.tagRadioGroup !== 'all' && allFormValues.tagRadioGroup) || undefined

      return (
        <Statements.Item
          key={projectProps.id}
          isCostRecoveryTagActive={isCostRecoveryTagActive}
          filterTagType={filterTagType}
          {...projectProps}
          containerRef={getObserveRef(index, list.length - 1)}
          initialToggleState={initialToggleState}
          onFold={handleFoldProject(projectProps.id)}
          onLinkClick={handleProjectClick(projectProps.id, projectProps.projectPage)}
        />
      )
    })
  }, [
    allFormValues.tagRadioGroup,
    getObserveRef,
    handleFoldProject,
    handleProjectClick,
    isCostRecoveryTagActive,
    preparedProjects,
    projectsTransitionState.transitionFoldStates,
    projectsTransitionState.transitionId,
    transitionFromProjectsPage,
  ])

  return (
    <Layouts.Main>
      <PageContainer>
        <Error500Fallback
          className={styles.fallback}
          title={'Не удалось загрузить соглашения'}
          error={isIntervalServerError(projectsError?.response?.status)}
        >
          <div className={styles.filters}>
            <ControlledRadioGroup
              radioGroupProps={{ type: 'tag', direction: 'horizontal' }}
              name={statementTableFormNames.tagRadioGroup}
              control={control}
              onChange={handleChangeTagRadioGroup}
            >
              <TagItem value="all" className={styles.tag}>
                Все
              </TagItem>
              <TagItem value={DfoGroupTypes.SZPK} className={styles.tag}>
                Соглашения
              </TagItem>
              <TagItem value={DfoGroupTypes.EXTRA} className={styles.tag}>
                Изменения к СЗПК
              </TagItem>
              <TagItem value={DfoGroupTypes.MONITORING} className={styles.tag}>
                Отчеты
              </TagItem>
              {!isRoleOMSU && (
                <TagItem value={DfoGroupTypes.COST_RECOVERY} className={styles.tag}>
                  Возмещение затрат
                </TagItem>
              )}
            </ControlledRadioGroup>

            <div className={styles.filters__rightGroup}>
              {!isRoleOMSU && (
                <>
                  <SelectTooltip
                    filterContent={filterSelectContent}
                    sortContent={sortSelectContent}
                    manualControl={{
                      tooltipIsOpen,
                      handleCloseTooltip,
                    }}
                  >
                    <Button
                      view="plain"
                      size="l"
                      color="default"
                      geometry="square"
                      className={styles.dropdown}
                      textClassName={styles.dropdownText}
                      trailingIcon={dropdownIcon}
                      onClick={handleOpenTooltip}
                    >
                      Сортировать: {dropdownText}
                    </Button>
                  </SelectTooltip>
                  {isFiltersRender && (
                    <StatementFiltersControl
                      getInitialFilters={() => getValues().filtersState}
                      countOfActiveFilters={countOfActiveFilters}
                      isRegisteredStatementsRender={isRegisteredStatementsRender}
                      onApplyFilters={handleApplyStatementFiltersFromFilterComponent}
                      onResetFilters={handleResetStatementFilters}
                    />
                  )}
                </>
              )}
              <ControlledInput
                name={formNames.searchString}
                control={control}
                inputProps={{
                  controlClassName: styles.input__control,
                  rootClassName: styles.input__root,
                  size: 'm',
                  placeholder: 'Поиск',
                  clear: true,
                  view: 'secondary',
                  leftAddons: <Icon src={searchIcon} size="s" className={styles.searchIcon} />,
                }}
              />
            </div>
          </div>

          {isBasketsRender && (
            <Tabs
              scrollable
              className={styles.baskets}
              breakpoint={0}
              selectedId={allFormValues.basketTagGroup}
              onChange={handleChangeBasket}
            >
              {currentBasketsOptions.map((basketTagGroup) => {
                const isActiveCounter = allFormValues.basketTagGroup === basketTagGroup.id

                const currentCounterData = allBasketsCounter?.[basketTagGroup.id]
                const isErrorCounter =
                  isAxiosError(currentCounterData) || isJsError(currentCounterData)

                const basketRender = (
                  <div
                    className={cn(styles.baskets__counter, {
                      [styles['baskets__counter--active']]: isActiveCounter,
                    })}
                  >
                    <Typography.Body
                      variant={'bodyMMedium'}
                      color={
                        isActiveCounter ? 'on-accent-on-surface-primary' : 'text-base-secondary'
                      }
                    >
                      {(() => {
                        if (isErrorCounter) return '?'

                        return allFormValues.tagRadioGroup === 'SZPK'
                          ? currentCounterData?.projectCount
                          : currentCounterData?.dfoCount
                      })()}
                    </Typography.Body>
                  </div>
                )

                return (
                  <Tab
                    key={basketTagGroup.id}
                    id={basketTagGroup.id}
                    title={basketTagGroup.title}
                    rightAddonClassname={styles.baskets__addon}
                    rightAddons={
                      <Loader
                        className={styles['baskets__counter-loader']}
                        variant={'lite'}
                        loading={isLoadingProjectsCounters || isLoadingProjects}
                      >
                        {isErrorCounter ? (
                          <Tooltip
                            trigger={'hover'}
                            popoverClassName={styles.baskets__tooltip}
                            position={'top'}
                            content={'Не удалось рассчитать количество'}
                          >
                            {basketRender}
                          </Tooltip>
                        ) : (
                          basketRender
                        )}
                      </Loader>
                    }
                  />
                )
              })}
            </Tabs>
          )}

          <div className={styles.list}>
            {noDataCondition && !isLoadingProjects && (
              <NoDataPlaceholder className={styles.placeholder} />
            )}
            {searchPlaceholderCondition && !isLoadingProjects && (
              <SearchPlaceholder className={styles.placeholder}>
                {debouncedSearchString}
              </SearchPlaceholder>
            )}

            {projectsRender}
            <Loader loading={isLoadingProjects} className={styles.list__loadingContainer} />
          </div>
        </Error500Fallback>
      </PageContainer>
    </Layouts.Main>
  )
}

export default memo(StatementsPage)
