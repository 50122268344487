import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'

const { getDefaultInitiatorFieldState, getDefaultInfoAboutDocument } = FieldStateHelpers

const mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченный орган' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Основания невозможности удовлетворения ходатайства' },
]

const defaultNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues: NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': getDefaultInitiatorFieldState(),
    '3': {
      investorFullName: '',
      investorFullNameInstrumental: '',
      investorInn: '',
      investorOgrn: '',
      investorAddress: '',
    },
    '4': {
      ruleLinkDenySolicitation: '',
      reasonDenySolicitation: '',
    },
  }

const notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
    initiatorHeaderNameGenitive: '2.initiatorHeaderNameGenitive',
    initiatorHeaderPositionGenitive: '2.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '2.initiatorHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameInstrumental: '3.investorFullNameInstrumental',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorAddress: '3.investorAddress',
  },
  '4': {
    ruleLinkDenySolicitation: '4.ruleLinkDenySolicitation',
    reasonDenySolicitation: '4.reasonDenySolicitation',
  },
} as const

export {
  defaultNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues,
  mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu,
  notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues,
}
