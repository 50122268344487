import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'

interface AATransferRightsAndObligationsFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
}

const AATransferRightsAndObligationsForms: AATransferRightsAndObligationsFormsProps = () => null

AATransferRightsAndObligationsForms.First = First
AATransferRightsAndObligationsForms.Second = Second
AATransferRightsAndObligationsForms.Third = Third
AATransferRightsAndObligationsForms.Fourth = Fourth
AATransferRightsAndObligationsForms.Fifth = Fifth

export default AATransferRightsAndObligationsForms
