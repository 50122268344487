import { StatementOldFormValues, TStatementRules } from '@components/Forms/CreateStatementOld/types'
import { amountValidate, defaultRHFValidation } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const investmentsValidation: Record<
  keyof StatementOldFormValues['participantsAndInvestments'],
  TStatementRules
> = {
  decisionDate: {
    required: defaultRHFValidation.required,
  },
  endDate: () => ({
    required: defaultRHFValidation.required,
  }),

  investorCapital: {
    required: defaultRHFValidation.required,
    pattern: defaultRHFValidation.capital,
    validate: (value) => {
      if (!isString(value)) return

      return amountValidate(value)
    },
  },

  investorCapitalVAT: {
    validate: (value) => {
      if (!isString(value)) return

      return amountValidate(value)
    },
  },
  totalCapital: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return amountValidate(value)
    },
  },
  totalCapitalVAT: {
    validate: (value) => {
      if (!isString(value)) return

      return amountValidate(value)
    },
  },
  taxes: {
    required: defaultRHFValidation.required,
  },
}

export { investmentsValidation }
