import { Path } from 'react-hook-form'

import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { IStatementCompensation, IStatementStepOfStage } from '@context/APIContext/types'

const { getDefaultInvestorFullFieldState } = FieldStateHelpers

const mapOFMenu = [
  { id: 'investor', title: 'Организация, реализующая проект' },
  { id: 'generalInfo', title: 'О проекте' },
  { id: 'stage', title: 'Этапы реализации проекта' },
  { id: 'participantsAndInvestments', title: 'Инвестиции' },
  { id: 'plannedTax', title: 'Прогнозируемый объем налогов' },
  { id: 'compensation', title: 'Объем затрат, планируемых к возмещению' },
]

//! При добавлении значений, указать его ниже в blockValues
const defaultCreateStatementFormValues: StatementFormValues = {
  investor: {
    ...getDefaultInvestorFullFieldState(),
    investorCapital: null,
    isProjectCompany: false,
    isVed: false,
    isKgn: false,
    region: '',
    city: '',
    district: '',
    area: '',
    planningStructureElement: '',
    roadNetworkElement: '',
    house: '',
    room: '',
    roomPart: '',
  },

  generalInfo: {
    economicSphere: null,
    otherEconomicSphere: '',
    projectOkved: null,
    lawLinkReasonSzpk: '',
    ruleLinkReasonSzpk: '',
    newWorkPlaces: '',
    buildWorkPlaces: '',
    projectDescription: '',
  },

  stage: {
    projectStartDate: '',
    projectEndDate: '',
    agreementEndDate: '',
    stepOfStages: [],
  },

  participantsAndInvestments: {
    projectDecisionDate: '',
    endExploitationStage: '',
    investorCapital: '',
    investorCapitalVat: '',
    totalCapitalVat: '',
    totalCapital: '',
  },

  plannedTax: {
    taxesType: [],
  },

  compensation: {
    projectCompensations: [],
  },

  additionalFields: {
    isFederal: true,
  },
}

//Расположение полей по блокам, необходимо для вычисления ошибочных блоков
const statementBlockValues = {
  compensation: {
    projectCompensations: 'compensation.projectCompensations',
  },
  generalInfo: {
    projectOkved: 'generalInfo.projectOkved',
    economicSphere: 'generalInfo.economicSphere',
    otherEconomicSphere: 'generalInfo.otherEconomicSphere',
    lawLinkReasonSzpk: 'generalInfo.lawLinkReasonSzpk',
    ruleLinkReasonSzpk: 'generalInfo.ruleLinkReasonSzpk',
    newWorkPlaces: 'generalInfo.newWorkPlaces',
    buildWorkPlaces: 'generalInfo.buildWorkPlaces',
    projectDescription: 'generalInfo.projectDescription',
  },
  plannedTax: {
    taxesType: 'plannedTax.taxesType',
  },
  investor: {
    investorFullName: 'investor.investorFullName',
    investorName: 'investor.investorName',
    investorInn: 'investor.investorInn',
    investorOgrn: 'investor.investorOgrn',
    investorKpp: 'investor.investorKpp',
    investorOkpo: 'investor.investorOkpo',
    investorOkved: 'investor.investorOkved',
    investorCapital: 'investor.investorCapital',
    isProjectCompany: 'investor.isProjectCompany',
    isVed: 'investor.isVed',
    isKgn: 'investor.isKgn',
    region: 'investor.region',
    city: 'investor.city',
    district: 'investor.district',
    area: 'investor.area',
    planningStructureElement: 'investor.planningStructureElement',
    roadNetworkElement: 'investor.roadNetworkElement',
    house: 'investor.house',
    room: 'investor.room',
    roomPart: 'investor.roomPart',
    investorHeaderName: 'investor.investorHeaderName',
    investorHeaderNameGenitive: 'investor.investorHeaderNameGenitive',
    investorHeaderPosition: 'investor.investorHeaderPosition',
    investorHeaderPositionGenitive: 'investor.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: 'investor.investorHeaderReasonGenitive',
    investorHeaderEmail: 'investor.investorHeaderEmail',
    investorHeaderPhone: 'investor.investorHeaderPhone',
    investorHeaderAddPhone: 'investor.investorHeaderAddPhone',
  },
  stage: {
    projectStartDate: 'stage.projectStartDate',
    projectEndDate: 'stage.projectEndDate',
    agreementEndDate: 'stage.agreementEndDate',
    stepOfStages: 'stage.stepOfStages',
  },
  participantsAndInvestments: {
    projectDecisionDate: 'participantsAndInvestments.projectDecisionDate',
    endExploitationStage: 'participantsAndInvestments.endExploitationStage',
    investorCapital: 'participantsAndInvestments.investorCapital',
    totalCapital: 'participantsAndInvestments.totalCapital',
    investorCapitalVat: 'participantsAndInvestments.investorCapitalVat',
    totalCapitalVat: 'participantsAndInvestments.totalCapitalVat',
  },

  additionalFields: {
    isFederal: 'additionalFields.isFederal',
  },
} as const

const defaultStepOfStage: IStatementStepOfStage = {
  id: '',
  stepDescription: '',
  stepEndDate: '',
  stepStartDate: '',
}

const defaultTaxType = {
  taxYear: '',
  taxYearValue: '',
}

const defaultProjectCompensation: IStatementCompensation = {
  id: '',
  projectCompensationForm: '',
  projectCompensationType: '',
  projectCompensationValue: '',
}

const rewriteCreateStatementFieldsAfterApplyDiffs: Path<StatementFormValues>[] = [
  statementBlockValues.investor.investorHeaderEmail,
]

export {
  defaultCreateStatementFormValues,
  defaultProjectCompensation,
  defaultStepOfStage,
  defaultTaxType,
  mapOFMenu,
  rewriteCreateStatementFieldsAfterApplyDiffs,
  statementBlockValues,
}
