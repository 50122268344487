import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FieldView from '@components/DocumentFormComponents/FieldView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import { fourthSectionCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/4/validation'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<CertificateOnMonitoringResultsFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const isVatMoney = useWatch({
    name: certificateOnMonitoringResultsBlockValues['4'].isVatMoney,
    control: formInstance?.control,
  })

  const {
    getSubscribableControlProps,
    getControllerProps,
    getAmountInputProps,
    getCalendarInputProps,
    getTextareaProps,
    getInputProps,
    getCheckboxProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['4'].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование проекта'}
                controllerProps={getControllerProps({
                  name: certificateOnMonitoringResultsBlockValues['4'].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group
          disableBottomBorder
          title={
            'Наименование органа, уполномоченного на принятие\n' +
            'решения об осуществлении\n' +
            'инвестиционного проекта'
          }
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4']
                .projectDecisionDepartmentGenitive,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: certificateOnMonitoringResultsBlockValues['4']
                  .projectDecisionDepartmentGenitive,
                textareaProps: {
                  disabled: true,
                  label: 'Наименование',
                },
              })}
            />
          </SubscribableControl>
        </Group>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['4'].economicSphere,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: certificateOnMonitoringResultsBlockValues['4'].economicSphere,
                  inputProps: {
                    disabled: true,
                    label: 'Сфера экономики',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дата заключения соглашения'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4'].szpkRegistrationDate,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: certificateOnMonitoringResultsBlockValues['4'].szpkRegistrationDate,
                calendarInputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дата начала предынвестиционной стадии'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4'].startPreinvestStage,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: certificateOnMonitoringResultsBlockValues['4'].startPreinvestStage,
                calendarInputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дата окончания предынвестиционной стадии'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4'].endPreinvestStage,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: certificateOnMonitoringResultsBlockValues['4'].endPreinvestStage,
                calendarInputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дата начала инвестиционной стадии'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4'].startInvestStage,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: certificateOnMonitoringResultsBlockValues['4'].startInvestStage,
                calendarInputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дата окончания инвестиционной стадии'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4'].endInvestStage,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: certificateOnMonitoringResultsBlockValues['4'].endInvestStage,
                calendarInputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дата начала эксплуатационной стадии'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4'].startExploitationStage,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: certificateOnMonitoringResultsBlockValues['4'].startExploitationStage,
                calendarInputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дата окончания эксплуатационной стадии'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4'].endExploitationStage,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: certificateOnMonitoringResultsBlockValues['4'].endExploitationStage,
                calendarInputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <FieldView.ContainerWithSwitchTransition condition={isVatMoney}>
          {(condition) => {
            if (!condition) {
              return (
                <Stack gap={3} direction={'vertical'}>
                  <RowWithBorder
                    disableBottomDefaultStyles
                    title={'Общий объем капитальных вложений (без НДС)'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: certificateOnMonitoringResultsBlockValues['4'].totalCapital,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: certificateOnMonitoringResultsBlockValues['4'].totalCapital,
                          inputProps: {
                            disabled: true,
                          },
                        })}
                      />
                    </SubscribableControl>
                  </RowWithBorder>
                  <RowWithBorder
                    disableBottomDefaultStyles
                    title={'Общий объем капиталовложений по проекту (без НДС)'}
                    firstColumnSize={6}
                    secondColumnSize={6}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: certificateOnMonitoringResultsBlockValues['4'].investorCapital,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: certificateOnMonitoringResultsBlockValues['4'].investorCapital,
                          inputProps: {
                            disabled: true,
                          },
                        })}
                      />
                    </SubscribableControl>
                  </RowWithBorder>
                </Stack>
              )
            }

            return (
              <Stack gap={3} direction={'vertical'}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title={'Общий объем капитальных вложений (с НДС)'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: certificateOnMonitoringResultsBlockValues['4'].totalCapitalVAT,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: certificateOnMonitoringResultsBlockValues['4'].totalCapitalVAT,
                        inputProps: {
                          disabled: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title={'Общий объем капиталовложений по проекту (с НДС)'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: certificateOnMonitoringResultsBlockValues['4'].investorCapitalVAT,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: certificateOnMonitoringResultsBlockValues['4'].investorCapitalVAT,
                        inputProps: {
                          disabled: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Stack>
            )
          }}
        </FieldView.ContainerWithSwitchTransition>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Количество рабочих мест'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: certificateOnMonitoringResultsBlockValues['4'].newWorkPlaces,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: certificateOnMonitoringResultsBlockValues['4'].newWorkPlaces,
                inputProps: {
                  disabled: true,
                  suffix: '',
                  integersOnly: true,
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['4'].projectResultEffect,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: certificateOnMonitoringResultsBlockValues['4'].projectResultEffect,
                  textareaProps: {
                    disabled: true,
                    label: 'Эффекты реализации\n' + 'инвестиционного проекта ',
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['4'].projectResultEffectChecker,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: certificateOnMonitoringResultsBlockValues['4'].projectResultEffectChecker,
                  rules:
                    fourthSectionCertificateOnMonitoringResultsValidationMap.projectResultEffectChecker,
                  textareaProps: {
                    label: 'Эффекты реализации\n' + 'инвестиционного проекта ',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        certificateOnMonitoringResultsBlockValues['4'].projectResultEffectChecker,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        certificateOnMonitoringResultsBlockValues['4'].projectResultEffectChecker,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Fourth
