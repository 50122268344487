import React, { FC, memo } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledInputSearchTree } from '@components/NewDesign/InputSearchTree/ControlledInputSearchTree'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import { useBooleanState } from '@hooks/useBooleanState'
import AddButtonIcon from '@icons/AddButtonIcon.svg'
import cn from 'classnames'

import styles from './AddItem.module.scss'

interface AddPartItemProps {
  onConfirm: (props: AddPartItemFormValues) => Promise<void>
}

export interface AddPartItemFormValues {
  part: string
  classificationId: string
}

const AddPartItem: FC<AddPartItemProps> = ({ onConfirm }) => {
  const { booleanState: editModeIsOpen, setBooleanState: setEditModeIsOpen } = useBooleanState()
  const { booleanState: partIsCreating, setBooleanState: setPartIsCreating } = useBooleanState()

  const { getCollapseProps } = useCollapse({
    isExpanded: editModeIsOpen,
  })

  const { partClassificationTreeAsOptions } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<AddPartItemFormValues>({
    defaultValues: {
      part: '',
      classificationId: '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  })

  const handleClose = () => {
    reset({
      classificationId: '',
      part: '',
    })

    setEditModeIsOpen(false)
  }

  const handleOpen = () => {
    reset({
      classificationId: '',
      part: '',
    })

    setEditModeIsOpen(true)
  }

  const handleConfirmForm = async () => {
    const formValues = getValues()

    setPartIsCreating(true)
    try {
      await onConfirm({
        part: formValues.part,
        classificationId: formValues.classificationId || '',
      })

      reset()

      handleClose()
    } catch (e) {
      throw e
    } finally {
      setPartIsCreating(false)
    }
  }

  return (
    <>
      <div {...getCollapseProps()}>
        <div>
          <div className={styles.addItem}>
            <div className={styles.addItem__inputs}>
              <ControlledInput
                name={'part'}
                control={control}
                rules={{
                  required: defaultRHFValidation.required,
                  validate: (value) => isString(value) && lengthValidate(value, 2000),
                }}
                inputProps={{
                  size: 's',
                  placeholder: 'Стабилизируемое положение',
                  rootClassName: styles.addItem__partInput,
                }}
              />
              <ControlledInputSearchTree
                name="classificationId"
                control={control}
                rules={{
                  required: defaultRHFValidation.required,
                }}
                searchProps={{
                  inputProps: {
                    size: 's',
                    placeholder: 'Класс стабилизируемого положения',
                    controlClassName: styles.addItem__classItem,
                    clear: true,
                  },
                  options: partClassificationTreeAsOptions || [],
                }}
              />
            </div>
            <div className={styles.addItem__buttons}>
              <Button disabled={partIsCreating} view={'gray'} size={'s'} onClick={handleClose}>
                Отменить
              </Button>
              <Button
                disabled={partIsCreating || !isValid}
                size={'s'}
                loaderProps={{
                  loading: partIsCreating,
                  placement: 'trailing',
                  variant: 'lite',
                }}
                onClick={handleSubmit(handleConfirmForm)}
              >
                Сохранить
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.addItem__container}>
        <Button
          disabled={editModeIsOpen}
          variant={'buttonSMedium'}
          size={'s'}
          view={'plain'}
          leadingIcon={{
            className: cn(styles.addItem__addButton, {
              [styles['addItem__addButton--disabled']]: editModeIsOpen,
            }),
            src: AddButtonIcon,
          }}
          onClick={handleOpen}
        >
          Добавить положение
        </Button>
      </div>
    </>
  )
}

export default memo(AddPartItem)
