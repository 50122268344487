import { generatePropertiesObjectForAdditionalAgreementOnTerminationFfSZPKForm } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAdditionalAgreementOnTerminationFfSZPKForm } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/adapters/RHF.adapter'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'

const useAdditionalAgreementOnTerminationFfSZPKAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): AdditionalAgreementOnTerminationFfSZPKFormValues =>
    generateRHFObjectForAdditionalAgreementOnTerminationFfSZPKForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForAdditionalAgreementOnTerminationFfSZPKForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useAdditionalAgreementOnTerminationFfSZPKAdapters }
