import { useState } from 'react'

import { DependentUrls, Urls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { isAxiosError } from '@helpers/checkTypes'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'

import {
  AuditDictionaries,
  AuditListProps,
  GetUserProps,
  OperatorProps,
  OperatorsData,
  OperatorsListProps,
  ParticipantsData,
  PutUserProps,
  SavedUser,
  SystemVariablesData,
  SystemVariablesItem,
  TableAudits,
  TableOperators,
  UsersListProps,
} from './types'

const useOperatorApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()
  const [violations, setViolations] = useState()

  const getUsersList = async ({ pageIndex, pageSize, ...params }: UsersListProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<ParticipantsData>({
        url: Urls.Users,
        config: {
          params: {
            page: pageIndex,
            size: pageSize,
            ...params,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getAuditList = async ({ pageIndex, pageSize, ...params }: AuditListProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TableAudits>({
        url: `${Urls.Audit}/list`,
        config: {
          params: {
            page: pageIndex,
            size: pageSize,
            ...params,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getAuditDictionaries = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<AuditDictionaries>({
        url: `${Urls.Audit}/dictionaries/action-type`,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getOperatorsList = async ({
    pageIndex,
    pageSize,
    ...params
  }: OperatorsListProps): Promise<OperatorsData> => {
    try {
      const { data } = await FetcherWithInterceptors.get<TableOperators>({
        url: Urls.Operators,
        config: {
          params: {
            page: pageIndex,
            size: pageSize,
            ...params,
          },
        },
      })
      return data.content
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const saveUser = async (params: GetUserProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post<SavedUser>({
        url: Urls.Users,
        data: params,
      })
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        setViolations(error?.response?.data.violations)
      }

      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const updateUserById = async (id: string, params: PutUserProps) => {
    try {
      const { data } = await FetcherWithInterceptors.put<SavedUser>({
        url: {
          url: DependentUrls.UserById,
          params: {
            id,
          },
        },
        data: params,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const updateOperatorById = async (id: string, params: OperatorProps) => {
    try {
      const { data } = await FetcherWithInterceptors.put<SavedUser>({
        url: {
          url: DependentUrls.OperatorById,
          params: {
            id,
          },
        },
        data: params,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const changeOperatorPasswordById = async (id: string, password: string) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.ChangeOperatorPassword,
          params: {
            id,
          },
        },
        data: {
          password,
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const saveOperator = async (params: OperatorProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post<SavedUser>({
        url: Urls.Operators,
        data: params,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getSystemVariables = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<SystemVariablesData>({
        url: Urls.Variables,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const updateSystemVariable = async (variable: SystemVariablesItem) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: Urls.Variables,
        data: variable,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    saveUser,
    saveOperator,
    getUsersList,
    getAuditList,
    updateUserById,
    getOperatorsList,
    updateOperatorById,
    changeOperatorPasswordById,
    getAuditDictionaries,
    getSystemVariables,
    updateSystemVariable,
    violations,
  }
}

export default useOperatorApi
