import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfNotificationOfIdentifiedViolationsMenu } from '@components/Forms/NotificationOfIdentifiedViolationsForm/const'
import NotificationOfIdentifiedViolationsForms from '@components/Forms/NotificationOfIdentifiedViolationsForm/Forms'
import { useNotificationOfIdentifiedViolationsManager } from '@components/Forms/NotificationOfIdentifiedViolationsForm/Manager'
import { NotificationOfIdentifiedViolationsFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/types'

const mapOfNotificationOfIdentifiedViolationsForms: FormItemProps = {
  '1': {
    node: <NotificationOfIdentifiedViolationsForms.First />,
  },
  '2': {
    node: <NotificationOfIdentifiedViolationsForms.Second />,
  },
  '3': {
    node: <NotificationOfIdentifiedViolationsForms.Third />,
  },
  '4': {
    node: <NotificationOfIdentifiedViolationsForms.Fourth />,
  },
} as const

const NotificationOfIdentifiedViolationsForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<NotificationOfIdentifiedViolationsFormValues>()

  const {
    state: { formIsLoading },
  } = useNotificationOfIdentifiedViolationsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNotificationOfIdentifiedViolationsMenu}
      mapOfForms={mapOfNotificationOfIdentifiedViolationsForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NotificationOfIdentifiedViolationsForm)
