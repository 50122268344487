import React, { FC, memo, useCallback } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import SummaryReportOfMonitoringResults from '@components/Forms/SummaryReportOfMonitoringResultsForm'
import { mapOfSummaryReportOfMonitoringResultsMenu } from '@components/Forms/SummaryReportOfMonitoringResultsForm/const'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import SummaryReportOfMonitoringResultsLayoutWrapper from './Wrapper'

const SummaryReportOfMonitoringResultsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const handleOnClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <SummaryReportOfMonitoringResults.Manager
        formId={formId}
        projectId={projectId}
        initialErrorsFromViolations={initialErrorsFromViolations}
        editMode={editMode}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
        onClose={handleOnClose}
      >
        <FlatMenuManager initialCurrentAnchorId={mapOfSummaryReportOfMonitoringResultsMenu[0].id}>
          <SummaryReportOfMonitoringResultsLayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Сводный отчет о результатах мониторинга
              </Typography.Headline>
              <SummaryReportOfMonitoringResults.StatementForm className={styles.layout__form} />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}>
              <SummaryReportOfMonitoringResults.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </SummaryReportOfMonitoringResultsLayoutWrapper>
        </FlatMenuManager>
      </SummaryReportOfMonitoringResults.Manager>
    </DocumentFormLayout>
  )
}

export default memo(SummaryReportOfMonitoringResultsLayout)
