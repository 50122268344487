import React, { FC, memo, useEffect } from 'react'

import {
  HIGHLIGHTED_BACKGROUND_COLOR,
  HIGHLIGHTED_TEXT_COLOR,
} from '@components/DocumentFormComponents/NestedMenu/constants'
import NestedMenuItem from '@components/DocumentFormComponents/NestedMenu/Item'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import Highlighted from '@components/Highlighted/Highlighted'
import Icon from '@components/Icon'
import Accordion, { AccordionProps } from '@components/NewDesign/Accordion'
import { useBooleanState } from '@hooks/useBooleanState'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import editIcon from '@icons/EditIcon.svg'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from './NestedMenuList.module.scss'

interface NestedMenuListProps {
  blockViewIsValidating: boolean
  currentSearchPattern?: string
  menuItem: NestedMapOfMenu
  menuItemClassName?: string
  activeMenuId?: string
  listProps?: Omit<Partial<AccordionProps>, 'controlledState' | 'children'>
  onMenuItemClick?: (menuItem: NestedMapOfMenu) => Promise<void>
  getInitialExpandedCondition?: (menuItemId: string) => boolean
}

const NestedMenuList: FC<NestedMenuListProps> = ({
  blockViewIsValidating,
  menuItem,
  currentSearchPattern,
  activeMenuId,
  menuItemClassName,
  listProps,
  onMenuItemClick,
  getInitialExpandedCondition,
}) => {
  const {
    unmountOnCollapse = true,
    className: accordionClassName,
    listClassName: accordionListClassName,
    borderClassName: accordionBorderClassName,
    buttonProps,
    ...restListProps
  } = listProps || {}

  const {
    className: buttonClassName,
    textClassName: buttonTextClassName,
    ...restButtonProps
  } = buttonProps || {}

  const {
    booleanState: isOpenAccordion,
    setBooleanState: setOpenAccordion,
    reverseBooleanState: toggleAccordion,
  } = useBooleanState()

  const isRenderFromSearchThree = !!currentSearchPattern?.length
  const initialExpandedCondition = !!getInitialExpandedCondition?.(menuItem.id)

  const blockHaveError = menuItem.hasError
  const blockNotHaveError = !blockHaveError

  useEffect(() => {
    if (
      isRenderFromSearchThree ||
      initialExpandedCondition ||
      (blockHaveError && blockViewIsValidating)
    ) {
      return setOpenAccordion(true)
    }
  }, [isRenderFromSearchThree, initialExpandedCondition, blockHaveError, blockViewIsValidating])

  useEffectAfterMount(() => {
    if (currentSearchPattern?.length) return

    if (initialExpandedCondition || (blockHaveError && blockViewIsValidating)) {
      return setOpenAccordion(true)
    }

    return setOpenAccordion(false)
  }, [currentSearchPattern])

  return (
    <Accordion
      {...restListProps}
      unmountOnCollapse={unmountOnCollapse}
      listClassName={cn(styles.accordion__list, accordionListClassName)}
      controlledState={isOpenAccordion}
      borderClassName={cn(styles.accordion__border, accordionBorderClassName)}
      leftAddon={
        !!menuItem?.isChanged && (
          <Icon src={editIcon} size={'xs'} className={styles.accordion__leftAddon} />
        )
      }
      text={
        <Highlighted
          highlight={currentSearchPattern || ''}
          color={HIGHLIGHTED_TEXT_COLOR}
          backgroundColor={HIGHLIGHTED_BACKGROUND_COLOR}
        >
          {menuItem.title}
        </Highlighted>
      }
      className={cn(styles.accordion, accordionClassName, {
        [styles['accordion--open']]: isOpenAccordion,
      })}
      buttonProps={{
        ...restButtonProps,
        className: cn(
          styles.accordion__button,
          {
            [styles['accordion__button--withChanged']]: !!menuItem?.isChanged,
          },
          buttonClassName,
        ),
        textClassName: cn(styles['accordion__buttonText'], buttonTextClassName, {
          [styles['accordion__buttonText--error']]: blockHaveError && blockViewIsValidating,
          [styles['accordion__buttonText--success']]: blockNotHaveError && blockViewIsValidating,
        }),
        renderTrailingIcon: {
          src: chevronRightIcon,
          className: cn(styles['accordion__buttonIcon'], {
            [styles['accordion__buttonIcon--open']]: isOpenAccordion,
          }),
        },
        onClick: () => toggleAccordion(),
      }}
    >
      {menuItem.children.map((nestedItem) => (
        <NestedMenuItem
          key={nestedItem.id}
          item={nestedItem}
          blockViewIsValidating={blockViewIsValidating}
          activeMenuId={activeMenuId}
          className={menuItemClassName}
          currentSearchPattern={currentSearchPattern}
          dataTestId={nestedItem.id}
          isOpen={getInitialExpandedCondition?.(nestedItem.id)}
          onClick={onMenuItemClick}
        />
      ))}
    </Accordion>
  )
}

export type { NestedMenuListProps }

export default memo(NestedMenuList)
