import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForAAChangingVolumeReimbursementForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '5': {
      isSMBusinessesInvolved: {
        propertyId: objectFromPropertyState?.isSMBusinessesInvolved?.propertyId,
        type: objectFromPropertyState?.isSMBusinessesInvolved?.type,
        lastUpdateDt: objectFromPropertyState?.isSMBusinessesInvolved?.lastUpdateDt,
      },
    },
    '6': {
      steps: {
        propertyId: objectFromPropertyState.steps?.propertyId,
        lastUpdateDt: objectFromPropertyState.steps?.lastUpdateDt,
        type: objectFromPropertyState.steps?.type,
        value:
          isArray(objectFromPropertyState.steps?.value) &&
          objectFromPropertyState.steps.value.length
            ? objectFromPropertyState.steps?.value.map((step) => ({
                propertyId: step.propertyId,
                lastUpdateDt: step.lastUpdateDt,
                value: {
                  stepNumber: {
                    propertyId: step.value.stepNumber?.propertyId,
                    type: step.value.stepNumber?.type,
                    lastUpdateDt: step.value.stepNumber?.lastUpdateDt,
                  },
                  stepDescription: {
                    propertyId: step.value.stepDescription?.propertyId,
                    type: step.value.stepDescription?.type,
                    lastUpdateDt: step.value.stepDescription?.lastUpdateDt,
                  },
                  stepCompensationValue: {
                    propertyId: step.value.stepCompensationValue?.propertyId,
                    type: step.value.stepCompensationValue?.type,
                    lastUpdateDt: step.value.stepCompensationValue?.lastUpdateDt,
                  },
                  stepCompensationFormat: {
                    propertyId: step.value.stepCompensationForm?.propertyId,
                    type: step.value.stepCompensationForm?.type,
                    lastUpdateDt: step.value.stepCompensationForm?.lastUpdateDt,
                  },
                  stepCompensations: {
                    propertyId: step.value.stepCompensations?.propertyId,
                    type: step.value.stepCompensations?.type,
                    lastUpdateDt: step.value.stepCompensations?.lastUpdateDt,
                    value:
                      isArray(step.value.stepCompensations?.value) &&
                      step.value.stepCompensations?.value.length
                        ? step.value.stepCompensations?.value
                            .sort(
                              (compensationA, compensationB) =>
                                compensationA.order - compensationB.order,
                            )
                            .map((stepCompensation) => ({
                              propertyId: stepCompensation?.propertyId,
                              type: stepCompensation?.type,
                              lastUpdateDt: stepCompensation?.lastUpdateDt,
                              value: {
                                stepCompensationType: {
                                  propertyId:
                                    stepCompensation?.value.stepCompensationType?.propertyId,
                                  type: stepCompensation?.value.stepCompensationType?.type,
                                  lastUpdateDt:
                                    stepCompensation?.value.stepCompensationType?.lastUpdateDt,
                                },
                              },
                            }))
                        : [],
                  },
                },
              }))
            : [],
      },
    },
    '7': {
      oldAgreementLinkChanges: {
        propertyId: objectFromPropertyState.oldAgreementLinkChanges?.propertyId,
        lastUpdateDt: objectFromPropertyState.oldAgreementLinkChanges?.lastUpdateDt,
        type: objectFromPropertyState.oldAgreementLinkChanges?.type,
        value:
          isArray(objectFromPropertyState.oldAgreementLinkChanges?.value) &&
          objectFromPropertyState.oldAgreementLinkChanges.value.length
            ? objectFromPropertyState.oldAgreementLinkChanges?.value.map((linkChange) => ({
                propertyId: linkChange.propertyId,
                lastUpdateDt: linkChange.lastUpdateDt,
                value: {
                  oldAgreementLink: {
                    propertyId: linkChange.value.oldAgreementLink?.propertyId,
                    type: linkChange.value.oldAgreementLink?.type,
                    lastUpdateDt: linkChange.value.oldAgreementLink?.lastUpdateDt,
                  },
                  oldAgreementLinkNewRedaction: {
                    propertyId: linkChange.value.oldAgreementLinkNewRedaction?.propertyId,
                    type: linkChange.value.oldAgreementLinkNewRedaction?.type,
                    lastUpdateDt: linkChange.value.oldAgreementLinkNewRedaction?.lastUpdateDt,
                  },
                },
              }))
            : [],
      },
    },
  }
}

export { generatePropertiesObjectForAAChangingVolumeReimbursementForm }
