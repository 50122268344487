import { useMemo } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'

import { SubscribableFormControlProps } from '@components/DocumentFormComponents/SubscribableControl'

interface UseFormSubscribableControlProps<T extends FieldValues> {
  getPropertiesProps: () => Record<string, any> | null
  formInstance: UseFormReturn<T>
  rolesOfUser?: string[]
}

const useFormSubscribableControl = <T extends FieldValues>({
  formInstance,
  getPropertiesProps,
  rolesOfUser,
}: UseFormSubscribableControlProps<T>) => {
  const { getFieldState, setError, getValues } = formInstance

  const subscribableControl: SubscribableFormControlProps<T> = useMemo(
    () => ({
      getFieldState,
      setError,
      getValues,
      propertiesProps: getPropertiesProps,
      userRoles: rolesOfUser,
    }),
    [getFieldState, setError, getValues, getPropertiesProps, rolesOfUser],
  )

  return {
    subscribableControl,
  }
}

export { useFormSubscribableControl }
