import React, { memo } from 'react'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { blockValues } from '@components/Forms/CreateStatementOld/const'
import FieldView from '@components/Forms/CreateStatementOld/FieldView'
import styles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import { investmentsValidation } from '@components/Forms/CreateStatementOld/Forms/Investments/validation'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Typography from '@components/NewDesign/Typography'

const { validationAdapter } = DocumentFormHelpers

const ParticipantsAndInvestmentsForm = () => {
  const {
    state: { formInstance, blockViewIsValidating },
  } = useCreateStatementManager()

  if (!formInstance) return null

  return (
    <div className={styles.form__container}>
      <div className={styles.form__item}>
        <div className={styles.item_input}>
          <FieldView.RowWithBottomBorder
            disableLeftPadding
            contentClassName={styles.form_CalendarGap}
            title={
              <>
                <Typography.Body variant={'bodyMSemibold'} as={'span'}>
                  Дата принятия решения об осуществлении проекта
                </Typography.Body>
                {
                  ', в\u00A0том числе об определении объема капитальных вложений или решения об утверждении бюджета на\u00A0капитальные вложения '
                }
              </>
            }
          >
            <ControlledCalendarInput
              control={formInstance.control}
              name={blockValues.participantsAndInvestments.decisionDate}
              calendarInputProps={{
                label: 'ДД.ММ.ГГГГ',
                size: 'xl',
                view: 'secondary',
                dataTestId: `ParticipantsAndInvestmentsForm-${blockValues.participantsAndInvestments.decisionDate}-calendarInput`,
              }}
              rules={validationAdapter(investmentsValidation.decisionDate, {
                form: formInstance,
                name: blockValues.participantsAndInvestments.decisionDate,
                needTrigger: blockViewIsValidating,
              })}
            />
          </FieldView.RowWithBottomBorder>
        </div>
      </div>
      <div className={styles.form__item}>
        <FieldView.RowWithBottomBorder
          disableLeftPadding
          contentClassName={styles.form_CalendarGap}
          title={
            <>
              {'Планируемая'}{' '}
              <Typography.Body variant={'bodyMSemibold'} as={'span'}>
                дата окончания реализации проекта,
              </Typography.Body>
              {' завершения стадии эксплуатации'}
            </>
          }
        >
          <ControlledCalendarInput
            control={formInstance.control}
            name={blockValues.participantsAndInvestments.endDate}
            calendarInputProps={{
              label: 'ДД.ММ.ГГГГ',
              size: 'xl',
              view: 'secondary',
              dataTestId: `ParticipantsAndInvestmentsForm-${blockValues.participantsAndInvestments.endDate}-calendarInput`,
            }}
            rules={validationAdapter(investmentsValidation.endDate, {
              form: formInstance,
              name: blockValues.participantsAndInvestments.endDate,
              needTrigger: blockViewIsValidating,
            })}
          />
        </FieldView.RowWithBottomBorder>
      </div>
      <div className={styles.form__multiple}>
        <Typography.Body variant={'bodyMRegular'}>
          Общий объем капиталовложений, включая осуществленные капиталовложения
        </Typography.Body>
        <div className={styles['form__multiple-row']}>
          <Typography.Body variant={'bodyMRegular'}>с учетом НДС</Typography.Body>
          <ControlledAmountInput
            control={formInstance.control}
            name={blockValues.participantsAndInvestments.investorCapitalVAT}
            inputProps={{
              size: 'xl',
              view: 'secondary',
              minorPartView: 'withZeroMinorPart',
              integerLength: 19,
              dataTestId: `ParticipantsAndInvestmentsForm-${blockValues.participantsAndInvestments.investorCapitalVAT}-amountInput`,
            }}
            rules={validationAdapter(investmentsValidation.investorCapitalVAT, {
              form: formInstance,
              name: blockValues.participantsAndInvestments.investorCapitalVAT,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
        <div className={styles['form__multiple-row']}>
          <Typography.Body variant={'bodyMRegular'}>без учета НДС</Typography.Body>
          <ControlledAmountInput
            control={formInstance.control}
            name={blockValues.participantsAndInvestments.investorCapital}
            inputProps={{
              size: 'xl',
              view: 'secondary',
              minorPartView: 'withZeroMinorPart',
              integerLength: 19,
              dataTestId: `ParticipantsAndInvestmentsForm-${blockValues.participantsAndInvestments.investorCapital}-amountInput`,
            }}
            rules={validationAdapter(investmentsValidation.investorCapital, {
              form: formInstance,
              name: blockValues.participantsAndInvestments.investorCapital,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
      </div>
      <div className={styles.form__multiple}>
        <Typography.Body variant={'bodyMRegular'}>
          {'Общий объем капитальных вложений (инвестиций)'}
        </Typography.Body>
        <div className={styles['form__multiple-row']}>
          <Typography.Body variant={'bodyMRegular'}>с учетом НДС</Typography.Body>
          <ControlledAmountInput
            control={formInstance.control}
            name={blockValues.participantsAndInvestments.totalCapitalVAT}
            inputProps={{
              size: 'xl',
              view: 'secondary',
              minorPartView: 'withZeroMinorPart',
              integerLength: 19,
              dataTestId: `ParticipantsAndInvestmentsForm-${blockValues.participantsAndInvestments.totalCapitalVAT}-amountInput`,
            }}
            rules={validationAdapter(investmentsValidation.totalCapitalVAT, {
              form: formInstance,
              name: blockValues.participantsAndInvestments.totalCapitalVAT,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
        <div className={styles['form__multiple-row']}>
          <Typography.Body variant={'bodyMRegular'}>без учета НДС</Typography.Body>
          <ControlledAmountInput
            control={formInstance.control}
            name={blockValues.participantsAndInvestments.totalCapital}
            inputProps={{
              size: 'xl',
              view: 'secondary',
              minorPartView: 'withZeroMinorPart',
              integerLength: 19,
              dataTestId: `ParticipantsAndInvestmentsForm-${blockValues.participantsAndInvestments.totalCapital}-amountInput`,
            }}
            rules={validationAdapter(investmentsValidation.totalCapital, {
              form: formInstance,
              name: blockValues.participantsAndInvestments.totalCapital,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(ParticipantsAndInvestmentsForm)
