import { FC, memo, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import AAgreementForceCircumstances from '@components/Forms/AAgreementForceCircumstancesForm'
import {
  aAgreementForceCircumstancesBlockValues,
  mapOfAAgreementForceCircumstancesMenu,
} from '@components/Forms/AAgreementForceCircumstancesForm/const'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import { AAgreementForceCircumstancesFieldsControlUpdateWatcher } from '@components/Forms/AAgreementForceCircumstancesForm/watcher'
import Typography from '@components/NewDesign/Typography'

import AAgreementForceCircumstancesLayoutWrapper from './Wrapper'

const AAgreementForceCircumstancesLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const isFederalSZPK = useWatch({
    name: aAgreementForceCircumstancesBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementForceCircumstancesBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  }).length

  const currentMenu = useMemo(() => {
    let readyMenu = [...mapOfAAgreementForceCircumstancesMenu]

    if (!isFederalSZPK) {
      readyMenu = readyMenu.slice(1)
    }

    if (!isMunicipalitiesRender && isFederalSZPK) {
      readyMenu.splice(2, 1)
    }

    if (!isMunicipalitiesRender && !isFederalSZPK) {
      readyMenu.splice(1, 1)
    }

    return readyMenu
  }, [isFederalSZPK, isMunicipalitiesRender])

  const handleOnClose = useCallback(() => {
    AAgreementForceCircumstancesFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <AAgreementForceCircumstances.Manager
        formId={formId}
        projectId={projectId}
        initialErrorsFromViolations={initialErrorsFromViolations}
        editMode={editMode}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
        onClose={handleOnClose}
      >
        <FlatMenuManager key={String(isFederalSZPK)} initialCurrentAnchorId={currentMenu[0].id}>
          <AAgreementForceCircumstancesLayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Дополнительное соглашение об обстоятельствах непреодолимой силы
              </Typography.Headline>
              <AAgreementForceCircumstances.StatementForm
                className={styles.layout__form}
                initialMenu={currentMenu}
              />
            </div>
            <div className={styles['layout__menu-wrapper']}>
              <AAgreementForceCircumstances.Menu
                className={styles.layout__menu}
                initialMenu={currentMenu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </AAgreementForceCircumstancesLayoutWrapper>
        </FlatMenuManager>
      </AAgreementForceCircumstances.Manager>
    </DocumentFormLayout>
  )
}

export default memo(AAgreementForceCircumstancesLayout)
