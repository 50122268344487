import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/types'

const { getDefaultInitiatorFieldState, getDefaultInfoAboutDocument, getDefaultInvestorFieldState } =
  FieldStateHelpers

const defaultNotificationOfIdentifiedNPAListErrorsFormValues: NotificationOfIdentifiedNPAListErrorsFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': getDefaultInitiatorFieldState(),
    '3': {
      ...getDefaultInvestorFieldState(),
      investorFullNameInstrumental: '',
    },
    '4': {
      ruleLinkNpaReturn: '',
      npaErrors: [],
    },
  }

const notificationOfIdentifiedNPAListErrorsFormBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorFullNameInstrumental: '1.investorFullNameInstrumental',
    investorNameInstrumental: '1.investorNameInstrumental',
    investorAddress: '1.investorAddress',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
  },
  '2': {
    uoFullName: '2.uoFullName',
    uoHeaderName: '2.uoHeaderName',
    uoHeaderPosition: '2.uoHeaderPosition',
    uoHeaderNameGenitive: '2.uoHeaderNameGenitive',
    uoHeaderPositionGenitive: '2.uoHeaderPositionGenitive',
    uoHeaderReasonGenitive: '2.uoHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorFullNameInstrumental: '3.investorFullNameInstrumental',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
  },
  '4': {
    ruleLinkNpaReturn: '4.ruleLinkNpaReturn',
    npaErrors: '4.npaErrors',
  },
} as const

export {
  defaultNotificationOfIdentifiedNPAListErrorsFormValues,
  notificationOfIdentifiedNPAListErrorsFormBlockValues,
}
