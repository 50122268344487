import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationFillingAppConcludingFormValues } from '@components/Forms/NotificationFillingAppConcludingForm/types'
import { defaultRHFValidation } from '@constants/validations'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof NotificationFillingAppConcludingFormValues['2'],
  NotificationFillingAppConcludingFormValues
> = {
  uoFullName: {
    required: defaultRHFValidation.required,
  },
}

export { secondSectionValidationMap }
