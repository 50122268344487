import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { RolesTypes } from '@constants/types'
import { IFiltersOfAuthorizedOrganizations } from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { isObject } from '@helpers/checkTypes'
import useDebounce from '@hooks/useDebounce'
import { Key } from 'swr'

import { defaultFiltersValue, PAGE_SIZE_OIV, PAGE_SIZE_OMSU } from '../consts'

export interface IFiltersFormValues
  extends Omit<IFiltersOfAuthorizedOrganizations, 'searchString'> {
  searchString?: string
}

interface AuthorizedOrganizationsFiltersProps {
  keyMutator: Key
  initialFilters?: Omit<IFiltersOfAuthorizedOrganizations, 'searchString'>
}

const useAuthorizedOrganizationsFilters = ({
  keyMutator,
  initialFilters,
}: AuthorizedOrganizationsFiltersProps) => {
  const filtersInstance = useForm<IFiltersFormValues>({
    defaultValues: {
      ...defaultFiltersValue,
      ...initialFilters,
      regionNumbers: initialFilters?.regionNumbers ?? defaultFiltersValue.region,
      type: RolesTypes.OMSU,
      searchString: defaultFiltersValue.searchString,
    },
  })

  const currentRegionNumber = filtersInstance.watch('regionNumbers')
  const currentType = filtersInstance.watch('type')
  const currentSize = filtersInstance.watch('size')
  const currentSearchString = filtersInstance.watch('searchString')?.trim()

  const debouncedSearchValue = useDebounce(currentSearchString, 300)

  const preparedKeyToSwr = useCallback(
    (page) => {
      return {
        page,
        size: currentType === RolesTypes.OMSU ? PAGE_SIZE_OMSU : PAGE_SIZE_OIV,
        regionNumbers: currentRegionNumber,
        type: currentType,
        searchString: debouncedSearchValue.length ? debouncedSearchValue : undefined,
        ...(isObject(keyMutator) ? keyMutator : {}),
      }
    },
    [currentRegionNumber, currentType, debouncedSearchValue, keyMutator],
  )

  return {
    filtersInstance,
    preparedKeyToSwr,
    debouncedSearchValue,
    currentRegionNumber,
    currentType,
    currentSize,
    currentSearchString,
  }
}

export { useAuthorizedOrganizationsFilters }
