import { MutableRefObject, useCallback, useRef, useState } from 'react'

export interface ManualTooltipControlReturn {
  state: {
    tooltipIsOpen: boolean
    targetTooltipRef: MutableRefObject<HTMLElement | null>
    refOfClickableOutsideZone: MutableRefObject<HTMLElement | null>
  }
  handlers: {
    handleSetupTooltip: (value: boolean) => void
    handleCloseTooltip: VoidFunction
    handleOpenTooltip: VoidFunction
    withCloseTooltip: (callback: unknown) => VoidFunction
    handleToggleTooltip: VoidFunction
  }
  bindHandlersRef: { bindRefOfClickableOutsideZone: (ref: HTMLElement | null) => void }
}

const useManualTooltipControl = <
  T extends HTMLElement = HTMLElement,
>(): ManualTooltipControlReturn => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

  const refOfClickableOutsideZone = useRef<HTMLElement | null>(null)
  const targetTooltipRef = useRef<T | null>(null)

  const handleSetupTooltip = (value: boolean) => {
    setTooltipIsOpen(value)
  }

  const handleOpenTooltip = () => {
    setTooltipIsOpen(true)
  }

  const handleCloseTooltip = () => {
    setTooltipIsOpen(false)
  }

  const handleToggleTooltip = () => {
    setTooltipIsOpen((state) => !state)
  }

  const withCloseTooltip = (callback) => () => {
    callback()
    handleCloseTooltip()
  }

  const bindRefOfClickableOutsideZone = useCallback((ref: HTMLElement | null) => {
    if (!ref) return

    const outSideWatcher = (e) => {
      const { target } = e

      if (targetTooltipRef.current && targetTooltipRef.current.contains(target)) return

      setTooltipIsOpen(false)
    }

    refOfClickableOutsideZone.current = ref

    ref.addEventListener('click', outSideWatcher)
  }, [])

  return {
    state: {
      tooltipIsOpen,
      targetTooltipRef,
      refOfClickableOutsideZone,
    },
    handlers: {
      handleSetupTooltip,
      handleCloseTooltip,
      handleOpenTooltip,
      withCloseTooltip,
      handleToggleTooltip,
    },
    bindHandlersRef: { bindRefOfClickableOutsideZone },
  }
}

export { useManualTooltipControl }
