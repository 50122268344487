import { PropertyStateStructureValue } from '@services/Properties/OOP/PropertyStructure'
import { TPropertyValue } from '@services/Properties/Properties.entity'

interface SubscriberPropertiesWatcherUpdateValueProps {
  updateValue:
    | TPropertyValue
    | PropertyStateStructureValue
    | ((prevValue: PropertyStateStructureValue) => PropertyStateStructureValue)
    | ((prevValue: TPropertyValue) => TPropertyValue)
}

interface SubscriberValueProps {
  updateValueCallback: (value: SubscriberPropertiesWatcherUpdateValueProps['updateValue']) => void
}

interface SubscriberPropertiesWatcherNotifyProps {
  id: string
  value: SubscriberPropertiesWatcherUpdateValueProps
}

class PropertiesWatcher {
  private readonly subscribers: Record<string, SubscriberValueProps> = {}

  subscribe(id: string, callback: SubscriberValueProps) {
    this.subscribers[id] = callback
  }

  unsubscribe(id: string) {
    if (!this.subscribers[id]) return
    delete this.subscribers[id]
  }

  notifySubscriber({ id, value }: SubscriberPropertiesWatcherNotifyProps) {
    if (!this.subscribers[id]) return

    this.subscribers[id].updateValueCallback(value.updateValue)
  }

  notifyAllSubscribers(data: SubscriberPropertiesWatcherNotifyProps[]) {
    data.forEach((subscriberValue) => {
      this.notifySubscriber(subscriberValue)
    })
  }
}

export type { SubscriberPropertiesWatcherNotifyProps, SubscriberPropertiesWatcherUpdateValueProps }
export { PropertiesWatcher }
