import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { BeneficiaryOfSuccessorOrganizationInformationFormValues } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/types'
import { defaultRHFValidation } from '@constants/validations'

const oneSectionValidationMap: FormValuesValidationSection<
  keyof BeneficiaryOfSuccessorOrganizationInformationFormValues['1'],
  BeneficiaryOfSuccessorOrganizationInformationFormValues
> = {
  investorPartnerHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { oneSectionValidationMap }
