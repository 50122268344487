export const handleCheckScrollOnBottom = (element: HTMLElement) => {
  const { scrollHeight, clientHeight, scrollTop } = element

  const isShowScroll = scrollHeight > clientHeight
  const isScrollOnBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 2

  return {
    isShowScroll,
    isScrollOnBottom,
  }
}
