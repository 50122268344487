import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOfImplementationInvestprojectStagesFormValues,
  IStepMonitoringEnded,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'
import {} from '@helpers/checkTypes'

const stepsMonitoringEndedValidationMap: FormValuesValidationSection<
  keyof IStepMonitoringEnded,
  CertificateOfImplementationInvestprojectStagesFormValues
> = {
  stepNumber: {
    required: defaultRHFValidation.required,
  },
  endedStepTotalCapitalValue: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  endedStepInvestorCapitalValue: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  endedStepActivityName: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 800) : undefined),
  },
  endedStepActivityValue: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
}

export { stepsMonitoringEndedValidationMap }
