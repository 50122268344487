import { type FC, memo, useEffect, useMemo } from 'react'

import Typography from '@components/NewDesign/Typography'
import { arrayWordEndDocument } from '@components/NewDesignedModals/SignModal'
import useSignDocuments from '@components/NewDesignedModals/StatusSignModal/hooks/useSignDocuments'
import { errorModalHeaderTexts, loaderModalBodyTexts } from '@constants/texts'
import { isEmptyString } from '@helpers/checkTypes'
import plural from 'plural-ru'

import ErrorSignModal from './ErrorSignModal'
import LoaderSignModal from './LoaderSignModal'
import styles from './StatusSignModal.module.scss'
import SuccessSignModal from './SuccessSignModal'
import type { StatusSignModalProps } from './types'

const StatusSignModal: FC<StatusSignModalProps> = ({
  isOpen,
  actionType,
  actionId,

  loaderHeaderTitle,
  loaderBodyText,

  successHeaderTitle,
  successBodyText,

  onSuccessSignFiles,
  onClose,

  projectId,
  dfoId,
  onErrorSignFiles,
}) => {
  const {
    isLoadingSignDocuments,
    isDoneSignDocuments,
    signDocumentsInProgress,
    handleSignDocuments,
    failedSignDocumentsCounter,
    allDocumentsToSignCounter,
    signDocumentsErrorMessage,
    signedDocumentsCounter,
    handleRetrySign,
  } = useSignDocuments({
    actionType,
    actionId,
    onSuccessSignFiles,
    onErrorSignFiles,
    projectId,
    dfoId,
  })

  useEffect(() => {
    handleSignDocuments()
  }, [])

  const isShowErrorModal =
    isDoneSignDocuments &&
    (!!failedSignDocumentsCounter || !isEmptyString(signDocumentsErrorMessage))

  const preparedLoaderBodyText = useMemo(() => {
    if (!signDocumentsInProgress) return loaderBodyText

    return (
      <>
        <div className={styles.loader__label}>
          {`Идет подписание ${signedDocumentsCounter + 1} из ${allDocumentsToSignCounter} ${plural(
            allDocumentsToSignCounter,
            ...arrayWordEndDocument,
          )}`}
        </div>
        <Typography align={'center'} variant={'bodyMRegular'}>
          {loaderModalBodyTexts.progressMessage}
        </Typography>
      </>
    )
  }, [allDocumentsToSignCounter, loaderBodyText, signDocumentsInProgress, signedDocumentsCounter])

  const errorHeaderText = !!failedSignDocumentsCounter
    ? `Подписано ${
        allDocumentsToSignCounter - failedSignDocumentsCounter
      } из ${allDocumentsToSignCounter} ${plural(
        allDocumentsToSignCounter,
        ...arrayWordEndDocument,
      )}`
    : errorModalHeaderTexts.defaultMessage

  const errorBodyText =
    !failedSignDocumentsCounter || !isEmptyString(signDocumentsErrorMessage)
      ? signDocumentsErrorMessage
      : undefined

  if (isLoadingSignDocuments) {
    return (
      <LoaderSignModal
        isOpen={isOpen}
        title={loaderHeaderTitle}
        bodyText={preparedLoaderBodyText}
        onClose={onClose}
      />
    )
  }

  if (isShowErrorModal) {
    return (
      <ErrorSignModal
        isOpen={isOpen}
        title={errorHeaderText}
        bodyText={errorBodyText}
        onClick={handleRetrySign}
        onClose={onClose}
      />
    )
  }

  return (
    <SuccessSignModal
      isOpen={isOpen}
      headerText={successHeaderTitle}
      bodyText={successBodyText}
      onClose={onClose}
    />
  )
}

export default memo(StatusSignModal)
