import React, { memo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { investmentsValidation } from '@components/Forms/CreateStatement/Forms/Investments/validation'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { CreateStatementControlUpdateWatcher } from '@components/Forms/CreateStatement/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import dayjs from 'dayjs'

import styles from './Investments.module.scss'

const ParticipantsAndInvestmentsForm = () => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()

  const { getSubscribableControlProps, getCalendarInputProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: CreateStatementControlUpdateWatcher,
    })

  const projectStartDate = useWatch({
    name: statementBlockValues.stage.projectStartDate,
    control: formInstance.control,
  })

  const projectEndDate = useWatch({
    name: statementBlockValues.stage.projectEndDate,
    control: formInstance.control,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title={'Дата решения об утверждении бюджета на капитальные вложения'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementBlockValues.participantsAndInvestments.projectDecisionDate,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: statementBlockValues.participantsAndInvestments.projectDecisionDate,
                rules: investmentsValidation.projectDecisionDate,
                calendarInputProps: {
                  label: 'ДД.ММ.ГГГГ',
                  dataTestId: `ParticipantsAndInvestmentsForm-${statementBlockValues.participantsAndInvestments.projectDecisionDate}-calendarInput`,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      statementBlockValues.participantsAndInvestments.projectDecisionDate,
                    )
                  }, 0),
                onInputChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      statementBlockValues.participantsAndInvestments.projectDecisionDate,
                    )
                  }, 0),
                onCalendarChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      statementBlockValues.participantsAndInvestments.projectDecisionDate,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          title={'Планируемая дата окончания реализации проекта (завершения стадии эксплуатации)'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementBlockValues.participantsAndInvestments.endExploitationStage,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: statementBlockValues.participantsAndInvestments.endExploitationStage,
                rules: {
                  required: defaultRHFValidation.required,
                  validate: {
                    positiveProjectStartDate: (value) =>
                      (isString(value) &&
                        dayjs(value, objOfDateFormats.defaultFormat) >=
                          dayjs(projectStartDate, objOfDateFormats.defaultFormat)) ||
                      'дата завершения стадии эксплуатации должна быть больше или равна дате начала проекта',
                    positiveProjectEnd: (value) =>
                      (isString(value) &&
                        dayjs(value, objOfDateFormats.defaultFormat) <=
                          dayjs(projectEndDate, objOfDateFormats.defaultFormat)) ||
                      'дата завершения стадии эксплуатации должна быть меньше или равна дате окончания проекта',
                  },
                },
                calendarInputProps: {
                  label: 'ДД.ММ.ГГГГ',
                  dataTestId: `ParticipantsAndInvestmentsForm-${statementBlockValues.participantsAndInvestments.endExploitationStage}-calendarInput`,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      statementBlockValues.participantsAndInvestments.endExploitationStage,
                    )
                  }, 0),
                onInputChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      statementBlockValues.participantsAndInvestments.endExploitationStage,
                    )
                  }, 0),
                onCalendarChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      statementBlockValues.participantsAndInvestments.endExploitationStage,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <Group
          disableBottomBorder
          title={'Общий объем капиталовложений, включая осуществленные капиталовложения'}
          leftAddons={
            <FormIconWithTooltip
              tooltipContent='Объем капиталовложений (без НДС) заполняется автоматически на основе сведений об объеме капиталовложений по этапам, указанных в форме "Проект СЗПК"'
              iconProps={{
                className: styles.tooltip,
              }}
            />
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder disableBottomDefaultStyles title={'с учетом НДС'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementBlockValues.participantsAndInvestments.investorCapitalVat,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: statementBlockValues.participantsAndInvestments.investorCapitalVat,
                    rules: investmentsValidation.investorCapitalVat,
                    inputProps: {
                      dataTestId: `ParticipantsAndInvestmentsForm-${statementBlockValues.participantsAndInvestments.investorCapitalVat}-amountInput`,
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          statementBlockValues.participantsAndInvestments.investorCapitalVat,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          statementBlockValues.participantsAndInvestments.investorCapitalVat,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder disableBottomDefaultStyles title={'без учета НДС'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementBlockValues.participantsAndInvestments.investorCapital,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: statementBlockValues.participantsAndInvestments.investorCapital,
                    inputProps: {
                      disabled: true,
                      dataTestId: `ParticipantsAndInvestmentsForm-${statementBlockValues.participantsAndInvestments.investorCapital}-amountInput`,
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          statementBlockValues.participantsAndInvestments.investorCapital,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          statementBlockValues.participantsAndInvestments.investorCapital,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Общий объем капитальных вложений (инвестиций)'}>
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder disableBottomDefaultStyles title={'с учетом НДС'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementBlockValues.participantsAndInvestments.totalCapitalVat,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: statementBlockValues.participantsAndInvestments.totalCapitalVat,
                    rules: investmentsValidation.totalCapitalVat,
                    inputProps: {
                      dataTestId: `ParticipantsAndInvestmentsForm-${statementBlockValues.participantsAndInvestments.totalCapitalVat}-amountInput`,
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          statementBlockValues.participantsAndInvestments.totalCapitalVat,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          statementBlockValues.participantsAndInvestments.totalCapitalVat,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder disableBottomDefaultStyles title={'без учета НДС'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementBlockValues.participantsAndInvestments.totalCapital,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: statementBlockValues.participantsAndInvestments.totalCapital,
                    rules: investmentsValidation.totalCapital,
                    inputProps: {
                      dataTestId: `ParticipantsAndInvestmentsForm-${statementBlockValues.participantsAndInvestments.totalCapital}-amountInput`,
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          statementBlockValues.participantsAndInvestments.totalCapital,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          statementBlockValues.participantsAndInvestments.totalCapital,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default memo(ParticipantsAndInvestmentsForm)
