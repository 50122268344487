import { getNotificationItemDfoDocumentsKey } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/NotificationItem'
import { useOrganizationActions } from '@hooks/new/swr/useOrganizationActions'
import { useDocumentActions } from '@services/DocumentActions/DocumentAction.hook'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { useSWRConfig } from 'swr'

const { fromActionToActionsStack } = DocumentActionsService

export const useNotificationsUiAction = (
  keyForRevalidate: string,
  revalidateAfterActions: () => Promise<void>,
) => {
  const { mutate: revalidate } = useSWRConfig()

  const { actions } = useOrganizationActions({
    key: keyForRevalidate,
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'NotificationsOfRequisitesChanges',
          moduleName: 'OrganizationInfo',
        },
      }),
    },
  })

  //массив actions содержит лишь один экшен - это экшен актуальной формы в процессе заполнения
  const currentAction = actions?.[0]
  const actionsForStack = currentAction ? fromActionToActionsStack(currentAction) : null

  const projectId = currentAction?.projectId
  const dfoId = currentAction?.dfos[0].dfoId

  const { createActionsStack } = useDocumentActions(projectId)

  const handleUiActionClick = async () => {
    try {
      if (actionsForStack) await createActionsStack(actionsForStack).runStack()
    } catch {
    } finally {
      await revalidateAfterActions()

      const notificationItemDfoDocumentsKey = getNotificationItemDfoDocumentsKey(
        projectId || '',
        dfoId || '',
      )
      await revalidate(notificationItemDfoDocumentsKey)
    }
  }

  return {
    uiAction: currentAction,
    onUiActionClick: handleUiActionClick,
  }
}
