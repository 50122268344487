import { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnChangingCharacteristicsOfObjectsBlockValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import MainTemplate from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import { AAgreementOnChangingCharacteristicsOfObjectsFormValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFieldCollapseControlUpdateWatcher,
  AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'

interface ConstructionStageProps {
  stepDescription: string
  id: string
  indexOfStage: number
  initialAccordionState: boolean | undefined
}

const ConstructionStage: FC<ConstructionStageProps> = ({
  stepDescription,
  id,
  indexOfStage,
  initialAccordionState,
}) => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
  })

  const formName =
    `${aAgreementOnChangingCharacteristicsOfObjectsBlockValues['5'].constructionStages}.${indexOfStage}` as const

  const { isConstruction, isModernization, isReconstruction } = useWatch({
    name: formName,
    control: formInstance.control,
  })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name: formName,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldCollapseControlUpdateWatcher,
    initialExpanded: initialAccordionState,
  })

  return (
    <SubscribableControl
      {...getSubscribableControlProps({
        path: formName,
      })}
    >
      <Accordion
        id={id}
        anchorId={formName}
        title={stepDescription}
        initialAccordionState={initialAccordionState}
        isExpanded={isExpanded}
        onToggle={onToggleCollapse}
      >
        <MainTemplate
          indexOfMainBlock={indexOfStage}
          name={formName}
          isConstructionRender={isConstruction || isModernization || isReconstruction}
        />
      </Accordion>
    </SubscribableControl>
  )
}

export default ConstructionStage
