import { mapOfRegistrationReducerActions } from '@components/Forms/RegistrationForm/const'
import {
  RegistrationActionCreators,
  RegistrationReducerActions,
  RegistrationReducerState,
} from '@components/Forms/RegistrationForm/types'

const initialRegistrationReducerState: RegistrationReducerState = {
  status: null,
  statusTitle: null,
  statusDescription: null,
  statusActions: null,
  isLoading: false,
  error: null,
}

const registrationReducer = (
  state: RegistrationReducerState,
  action: RegistrationReducerActions,
) => {
  const { type } = action

  switch (type) {
    case mapOfRegistrationReducerActions.SETUP_STATUS:
      return {
        ...state,
        status: action.payload,
      }

    case mapOfRegistrationReducerActions.SETUP_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }

    case mapOfRegistrationReducerActions.SETUP_ERROR: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case mapOfRegistrationReducerActions.SETUP_CONTENT: {
      return {
        ...state,
        statusTitle: action.payload.statusTitle ?? state.statusTitle,
        statusDescription: action.payload.statusDescription ?? state.statusDescription,
        statusActions: action.payload.statusActions ?? state.statusActions,
      }
    }

    case mapOfRegistrationReducerActions.CHECK_STATUS: {
      return initialRegistrationReducerState
    }
  }
}

const registrationActionCreators: RegistrationActionCreators = {
  checkStatus: () => ({ type: mapOfRegistrationReducerActions.CHECK_STATUS }),
  setContent: (payload) => ({ type: mapOfRegistrationReducerActions.SETUP_CONTENT, payload }),
  setStatus: (payload) => ({ type: mapOfRegistrationReducerActions.SETUP_STATUS, payload }),
  setLoading: (payload) => ({ type: mapOfRegistrationReducerActions.SETUP_LOADING, payload }),
  setError: (payload) => ({ type: mapOfRegistrationReducerActions.SETUP_ERROR, payload }),
}

export { initialRegistrationReducerState, registrationActionCreators, registrationReducer }
