import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import Accordion from '@components/DocumentFormComponents/Accordion'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  parametersOfCostRecoveryApplicationBlockValues,
  parametersOfCostRecoveryApplicationConstValues,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjects from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects'
import { compensationStatementExpenseSubTypeValidationMap } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/validation'
import { ParametersOfCostRecoveryApplicationExpenseSubTypesArrayPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

import styles from './ExpenseSubTypes.module.scss'

interface ParametersOfCostRecoveryApplicationExpenseSubTypesProps {
  canDelete: boolean
  id: string
  title: string
  indexOfType: number
  name: ParametersOfCostRecoveryApplicationExpenseSubTypesArrayPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
  onAccordionToggle: (value: boolean) => void
  initialAccordionState?: boolean
  initialAccordionMountState?: boolean
}

const ParametersOfCostRecoveryApplicationExpenseSubTypes: FC<
  ParametersOfCostRecoveryApplicationExpenseSubTypesProps
> = ({
  canDelete,
  id,
  name,
  indexOfType,
  formInstance,
  title,
  onAccordionToggle,
  initialAccordionState,
  initialAccordionMountState,
}) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithValue,
      handleRemoveItemFromList,
    },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
  })

  const { fields: compensationObjects } = useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.compensationObjects`,
    keyName: 'keyNameId',
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const statementFormName = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementForm,
    control: formInstance.control,
  })

  const expenseSubTypeValue = useWatch({
    name: `${name}.compensationStatementExpenseSubTypeValueSystemName`,
    control: formInstance.control,
  })

  const isSzpkFieldsRender =
    expenseSubTypeValue ===
      parametersOfCostRecoveryApplicationConstValues.BUILD_INFRASTRUCTURE_PROVIDING ||
    expenseSubTypeValue === parametersOfCostRecoveryApplicationConstValues.DESTRUCTION ||
    expenseSubTypeValue ===
      parametersOfCostRecoveryApplicationConstValues.BUILD_INFRASTRUCTURE_ACCOMPANYING

  const isAgreementFieldsRender =
    expenseSubTypeValue ===
      parametersOfCostRecoveryApplicationConstValues.DEBT_INFRASTRUCTURE_PROVIDING_LOAN_INTEREST ||
    expenseSubTypeValue ===
      parametersOfCostRecoveryApplicationConstValues.DEBT_INFRASTRUCTURE_ACCOMPANYING_LOAN_INTEREST ||
    expenseSubTypeValue ===
      parametersOfCostRecoveryApplicationConstValues.DEBT_MAIN_OBJECT_LOAN_INTEREST

  const isBondFieldsRender =
    expenseSubTypeValue ===
      parametersOfCostRecoveryApplicationConstValues.DEBT_INFRASTRUCTURE_PROVIDING_COUPON_INCOME ||
    expenseSubTypeValue ===
      parametersOfCostRecoveryApplicationConstValues.DEBT_INFRASTRUCTURE_ACCOMPANYING_COUPON_INCOME ||
    expenseSubTypeValue ===
      parametersOfCostRecoveryApplicationConstValues.DEBT_MAIN_OBJECT_COUPON_INCOME

  const isTaxDeductionRender =
    statementFormName === parametersOfCostRecoveryApplicationConstValues.TAX_DEDUCTION

  const handleRemoveExpense = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseSubTypes}.${index}`,
      parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseSubTypes,
    )
  }

  const handleAddFromSzpkObject = async () => {
    await handleAddItemToListWithValue?.(`${name}.compensationObjects`, {
      isProjectObject: true,
      compensationObjectType: {
        id: formInstance.getValues(
          parametersOfCostRecoveryApplicationBlockValues.additionalFields
            .compensationObjectFromSzpkId,
        ),
      },
    })
  }

  const handleAddFromAbsentObject = async () => {
    await handleAddItemToListWithValue?.(`${name}.compensationObjects`, {
      isProjectObject: false,
      compensationObjectType: {
        id: formInstance.getValues(
          parametersOfCostRecoveryApplicationBlockValues.additionalFields
            .compensationObjectAbsentSzpkId,
        ),
      },
    })
  }

  const handleAddCreditAgreementObject = async () => {
    await handleAddItemToListWithValue?.(`${name}.compensationObjects`, {
      isProjectObject: false,
      compensationObjectType: {
        id: formInstance.getValues(
          parametersOfCostRecoveryApplicationBlockValues.additionalFields.creditAgreementId,
        ),
      },
    })
  }

  const handleAddLoanAgreementObject = async () => {
    await handleAddItemToListWithValue?.(`${name}.compensationObjects`, {
      isProjectObject: false,
      compensationObjectType: {
        id: formInstance.getValues(
          parametersOfCostRecoveryApplicationBlockValues.additionalFields.loanAgreementId,
        ),
      },
    })
  }

  const handleAddBondObject = async () => {
    await handleAddItemToListWithValue?.(`${name}.compensationObjects`, {
      isProjectObject: false,
      compensationObjectType: {
        id: formInstance.getValues(
          parametersOfCostRecoveryApplicationBlockValues.additionalFields.bondId,
        ),
      },
    })
  }

  const handleRemoveCompensationObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.compensationObjects.${index}`,
      `${name}.compensationObjects`,
    )
  }

  return (
    <Accordion
      id={id}
      title={title}
      initialAccordionState={initialAccordionState}
      initialAccordionMountState={initialAccordionMountState}
      onToggle={onAccordionToggle}
      onRemove={editMode && canDelete ? handleRemoveExpense(indexOfType) : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <CollapseWrapper isExpanded={isTaxDeductionRender} defaultExpanded={isTaxDeductionRender}>
          <RowWithBorder
            disableBottomDefaultStyles
            rowClassName={styles.taxDeduction__row}
            title="Срок налогового вычета по указанным затратам"
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.compensationStatementExpenseSubTypeTaxRefundPeriod`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.compensationStatementExpenseSubTypeTaxRefundPeriod`,
                  rules:
                    compensationStatementExpenseSubTypeValidationMap.compensationStatementExpenseSubTypeTaxRefundPeriod,
                  inputProps: {
                    integersOnly: true,
                    hideSuffix: true,
                    hasNegativeValue: false,
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        `${name}.compensationStatementExpenseSubTypeTaxRefundPeriod`,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        `${name}.compensationStatementExpenseSubTypeTaxRefundPeriod`,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </RowWithBorder>
        </CollapseWrapper>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: parametersOfCostRecoveryApplicationBlockValues['4']
              .compensationStatementExpenseSubTypes,
          })}
        >
          <FlipperList list={compensationObjects}>
            {compensationObjects.map((compensationObject, index) => {
              const formName = `${name}.compensationObjects.${index}` as const

              return (
                <ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjects
                  key={compensationObject.id}
                  id={compensationObject.id}
                  title={compensationObject.compensationObjectTypeName}
                  name={formName}
                  formInstance={formInstance}
                  onRemove={editMode ? handleRemoveCompensationObject(index) : undefined}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <>
            <CollapseWrapper isExpanded={isSzpkFieldsRender} defaultExpanded={isSzpkFieldsRender}>
              <Stack direction={'vertical'} gap={3}>
                <Row className={'px-3'}>
                  <Col xs={12}>
                    <AsyncWrapper promise={handleAddFromSzpkObject}>
                      {({ isLoading, wrappedPromise }) => {
                        return (
                          <Button
                            disabled={isLoading}
                            leadingIcon={{ src: CircleAddIcon }}
                            variant={'buttonSMedium'}
                            size={'2xs'}
                            view={'plain'}
                            loaderProps={{
                              loading: isLoading,
                              placement: 'trailing',
                              variant: 'lite',
                            }}
                            onClick={wrappedPromise}
                          >
                            Указать затраты по объекту/мероприятию, указанному в СЗПК
                          </Button>
                        )
                      }}
                    </AsyncWrapper>
                  </Col>
                </Row>
                <Row className={'px-3'}>
                  <Col xs={12}>
                    <AsyncWrapper promise={handleAddFromAbsentObject}>
                      {({ isLoading, wrappedPromise }) => {
                        return (
                          <Button
                            disabled={isLoading}
                            leadingIcon={{ src: CircleAddIcon }}
                            variant={'buttonSMedium'}
                            size={'2xs'}
                            view={'plain'}
                            loaderProps={{
                              loading: isLoading,
                              placement: 'trailing',
                              variant: 'lite',
                            }}
                            onClick={wrappedPromise}
                          >
                            Указать затраты по объекту/мероприятию, который отсутствует в СЗПК
                          </Button>
                        )
                      }}
                    </AsyncWrapper>
                  </Col>
                </Row>
              </Stack>
            </CollapseWrapper>
            <CollapseWrapper
              isExpanded={isAgreementFieldsRender}
              defaultExpanded={isAgreementFieldsRender}
            >
              <Stack direction={'vertical'} gap={3}>
                <Row className={'px-3'}>
                  <Col xs={12}>
                    <AsyncWrapper promise={handleAddCreditAgreementObject}>
                      {({ isLoading, wrappedPromise }) => {
                        return (
                          <Button
                            disabled={isLoading}
                            leadingIcon={{ src: CircleAddIcon }}
                            variant={'buttonSMedium'}
                            size={'2xs'}
                            view={'plain'}
                            loaderProps={{
                              loading: isLoading,
                              placement: 'trailing',
                              variant: 'lite',
                            }}
                            onClick={wrappedPromise}
                          >
                            Указать данные кредитного договора
                          </Button>
                        )
                      }}
                    </AsyncWrapper>
                  </Col>
                </Row>
                <Row className={'px-3'}>
                  <Col xs={12}>
                    <AsyncWrapper promise={handleAddLoanAgreementObject}>
                      {({ isLoading, wrappedPromise }) => {
                        return (
                          <Button
                            disabled={isLoading}
                            leadingIcon={{ src: CircleAddIcon }}
                            variant={'buttonSMedium'}
                            size={'2xs'}
                            view={'plain'}
                            loaderProps={{
                              loading: isLoading,
                              placement: 'trailing',
                              variant: 'lite',
                            }}
                            onClick={wrappedPromise}
                          >
                            Указать данные договора займа
                          </Button>
                        )
                      }}
                    </AsyncWrapper>
                  </Col>
                </Row>
              </Stack>
            </CollapseWrapper>
            <CollapseWrapper isExpanded={isBondFieldsRender} defaultExpanded={isBondFieldsRender}>
              <Row className={'px-3'}>
                <Col xs={12}>
                  <AsyncWrapper promise={handleAddBondObject}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <Button
                          disabled={isLoading}
                          leadingIcon={{ src: CircleAddIcon }}
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          view={'plain'}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Указать информацию по облигациям
                        </Button>
                      )
                    }}
                  </AsyncWrapper>
                </Col>
              </Row>
            </CollapseWrapper>
          </>
        )}
      </Stack>
    </Accordion>
  )
}

export default ParametersOfCostRecoveryApplicationExpenseSubTypes
