import { NewDfosType } from '@constants/types'
import { GroupType } from '@services/Projects/Project.entity'

//Добавление ходатайства без группировок
const isGroupingChangesToDfoIsAvailable = (dfoMayCreate: string[]) => {
  return dfoMayCreate.every(
    (item) =>
      item === NewDfosType.RELATED_CONTRACT_LIST || item === NewDfosType.MUNICIPAL_ACCESSION,
  )
}

const isSubsidyStatementWillBeCreate = (dfoMayCreate: string[]) => {
  return dfoMayCreate.some(
    (item) =>
      item.includes(GroupType.CLAIM_SUBSIDY_PERMIT) || item.includes(GroupType.CLAIM_TAX_REFUND),
  )
}

const isOtherDonationsWillBeCreate = (dfoMayCreate: string[]) => {
  return dfoMayCreate.some((item) => item === NewDfosType.OTHER_DONATIONS)
}

export {
  isGroupingChangesToDfoIsAvailable,
  isOtherDonationsWillBeCreate,
  isSubsidyStatementWillBeCreate,
}
