import React from 'react'
import { useFormContext } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aaVolumeOfCapitalInvestmentsBlockValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/const'
import { sixthSectionValidationMap } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/6/validation'
import { useAAVolumeOfCapitalInvestmentsManager } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Manager'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Six = () => {
  const formInstance = useFormContext<AAVolumeOfCapitalInvestmentsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAVolumeOfCapitalInvestmentsManager()

  const {
    getSubscribableControlProps,
    getCalendarInputProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Col xs={12}>
          <Row className={'px-2'}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Текущий объем капиталовложений по проекту'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aaVolumeOfCapitalInvestmentsBlockValues[6].investorCapitalCommited,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: aaVolumeOfCapitalInvestmentsBlockValues[6].investorCapitalCommited,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Row>
        </Col>
        <Col xs={12}>
          <Row className={'px-2'}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Измененное значение объема капиталовложений по проекту'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aaVolumeOfCapitalInvestmentsBlockValues[6].investorCapital,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: aaVolumeOfCapitalInvestmentsBlockValues[6].investorCapital,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Row>
        </Col>
        <Border />

        <Col xs={12}>
          <Row className={'px-2'}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Текущий объем капитальных вложений по проекту'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aaVolumeOfCapitalInvestmentsBlockValues[6].totalCapitalCommited,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: aaVolumeOfCapitalInvestmentsBlockValues[6].totalCapitalCommited,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Row>
        </Col>

        <Col xs={12}>
          <Row className={'px-2'}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Измененное значение объема капитальных вложений по проекту'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aaVolumeOfCapitalInvestmentsBlockValues[6].totalCapital,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: aaVolumeOfCapitalInvestmentsBlockValues[6].totalCapital,
                    rules: sixthSectionValidationMap.totalCapital,

                    onBlur: () =>
                      setTimeout(() =>
                        handleChangeValue?.(
                          aaVolumeOfCapitalInvestmentsBlockValues[6].totalCapital,
                        ),
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          aaVolumeOfCapitalInvestmentsBlockValues[6].totalCapital,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Row>
        </Col>
        <Border />

        <Col xs={12}>
          <Row className={'px-2'}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Текущий объем капиталовложений, подлежащий осуществлению'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aaVolumeOfCapitalInvestmentsBlockValues[6].remainsInvestorCapitalCommited,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: aaVolumeOfCapitalInvestmentsBlockValues[6].remainsInvestorCapitalCommited,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Row>
        </Col>
        <Col xs={12}>
          <Row className={'px-2'}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Измененное значение объема капиталовложений, подлежащего осуществлению'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aaVolumeOfCapitalInvestmentsBlockValues[6].remainsInvestorCapital,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: aaVolumeOfCapitalInvestmentsBlockValues[6].remainsInvestorCapital,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Row>
        </Col>
        <Col xs={12}>
          <Row className={'px-2'}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              title={'Текущий срок осуществления капиталовложений в полном объеме'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aaVolumeOfCapitalInvestmentsBlockValues[6].madeInvestorCapitalDateCommited,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: aaVolumeOfCapitalInvestmentsBlockValues[6]
                      .madeInvestorCapitalDateCommited,
                    calendarInputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Row>
        </Col>

        <Col xs={12}>
          <Row className={'px-2'}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              title={'Измененный срок осуществления капиталовложений в полном объеме'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aaVolumeOfCapitalInvestmentsBlockValues[6].madeInvestorCapitalDate,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: aaVolumeOfCapitalInvestmentsBlockValues[6].madeInvestorCapitalDate,
                    rules: sixthSectionValidationMap.totalCapital,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          aaVolumeOfCapitalInvestmentsBlockValues[6].madeInvestorCapitalDate,
                        )
                      }, 0),
                    onInputChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          aaVolumeOfCapitalInvestmentsBlockValues[6].madeInvestorCapitalDate,
                        )
                      }, 0),
                    onCalendarChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          aaVolumeOfCapitalInvestmentsBlockValues[6].madeInvestorCapitalDate,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Row>
        </Col>
      </Stack>
    </Container>
  )
}

export default Six
