import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForAAgreementForceCircumstancesForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedStages = (() => {
    if (
      !isArray(objectFromPropertyState.steps?.value) ||
      !objectFromPropertyState.steps.value.length
    )
      return []

    return objectFromPropertyState.steps.value.map((stage) => ({
      propertyId: stage.propertyId,
      lastUpdateDt: stage.lastUpdateDt,
      type: stage.type,
      value: {
        stepNumber: {
          propertyId: stage.value.stepNumber?.propertyId,
          type: stage.value.stepNumber?.type,
          lastUpdateDt: stage.value.stepNumber?.lastUpdateDt,
        },
        stepDescription: {
          propertyId: stage.value.stepDescription?.propertyId,
          type: stage.value.stepDescription?.type,
          lastUpdateDt: stage.value.stepDescription?.lastUpdateDt,
        },
        stepEndDate: {
          propertyId: stage.value.stepEndDate?.propertyId,
          type: stage.value.stepEndDate?.type,
          lastUpdateDt: stage.value.stepEndDate?.lastUpdateDt,
        },
      },
    }))
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      stages: {
        propertyId: objectFromPropertyState.steps.propertyId,
        lastUpdateDt: objectFromPropertyState.steps.lastUpdateDt,
        type: objectFromPropertyState.steps.type,
        value: preparedStages,
      },
    },
    '6': {
      projectEndDate: {
        propertyId: objectFromPropertyState?.projectEndDate?.propertyId,
        type: objectFromPropertyState?.projectEndDate?.type,
        lastUpdateDt: objectFromPropertyState?.projectEndDate?.lastUpdateDt,
      },
      agreementLinkProjectPeriod: {
        propertyId: objectFromPropertyState?.agreementLinkProjectPeriod?.propertyId,
        type: objectFromPropertyState?.agreementLinkProjectPeriod?.type,
        lastUpdateDt: objectFromPropertyState?.agreementLinkProjectPeriod?.lastUpdateDt,
      },
    },
    '7': {
      forceMajeureName: {
        propertyId: objectFromPropertyState?.forceMajeureName?.propertyId,
        type: objectFromPropertyState?.forceMajeureName?.type,
        lastUpdateDt: objectFromPropertyState?.forceMajeureName?.lastUpdateDt,
      },
      forceMajeureProof: {
        propertyId: objectFromPropertyState?.forceMajeureProof?.propertyId,
        type: objectFromPropertyState?.forceMajeureProof?.type,
        lastUpdateDt: objectFromPropertyState?.forceMajeureProof?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForAAgreementForceCircumstancesForm }
