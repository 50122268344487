import { memo, useCallback, useMemo } from 'react'

import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import { FormModalContextHandlers } from '@components/DocumentFormComponents/types'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import Stage from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/Stage'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'

interface StageWithSwapperProps {
  id: string
  index: number
  lastIndex: number
  editMode: boolean
  initialAccordionState?: boolean
  onRemoveItemFromList: FormModalContextHandlers<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>['handleRemoveItemFromList']
  onUpElementInList: FormModalContextHandlers<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>['handleUpElementInList']
  onDownElementInList: FormModalContextHandlers<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>['handleDownElementInList']
}

const StageWithSwapper = ({
  id,
  index,
  lastIndex,
  editMode,
  initialAccordionState,
  onRemoveItemFromList,
  onUpElementInList,
  onDownElementInList,
}: StageWithSwapperProps) => {
  const handleRemoveStage = useCallback(async () => {
    await onRemoveItemFromList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${index}`,
      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
    )
  }, [onRemoveItemFromList, index])

  const handleChangeElementOrderToUp = useCallback(async () => {
    await onUpElementInList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${index}`,
    )
  }, [onUpElementInList, index])

  const handleChangeElementOrderToDown = useCallback(async () => {
    await onDownElementInList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${index}`,
    )
  }, [onDownElementInList, index])

  const fieldArraySwapperItem = useMemo(
    () => (
      <FieldArraySwapper
        index={index}
        lastIndex={lastIndex}
        onMoveTop={handleChangeElementOrderToUp}
        onMoveBottom={handleChangeElementOrderToDown}
      />
    ),
    [handleChangeElementOrderToUp, handleChangeElementOrderToDown, index, lastIndex],
  )

  return (
    <Stage
      id={id}
      indexOfStage={index}
      initialAccordionState={initialAccordionState}
      initialAccordionMountState={initialAccordionState}
      additionalAccordionNode={editMode && fieldArraySwapperItem}
      onRemoveStage={editMode ? handleRemoveStage : undefined}
    />
  )
}

export default memo(StageWithSwapper)
