import React, { FC, useCallback, useMemo } from 'react'
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Sidebar from '@components/NewDesign/Sidebar'
import {
  organizationUserDetailsFormNames,
  organizationUserDetailsProcurationFieldValues,
} from '@components/Sidebars/OrganizationUserDetails/constants'
import OrganizationUserDetailsController from '@components/Sidebars/OrganizationUserDetails/Controller'
import { OrganizationUserDetailsFormValues } from '@components/Sidebars/OrganizationUserDetails/types'
import { UserProfiles } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { Entries } from '@globalTypes/typeFest'
import { isNotEmptyString, isString } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import {
  ICurrentOrganizationUser,
  IOrganizationUserDetails,
} from '@services/Organization/organization.entity'

import styles from './OrganizationUserDetails.module.scss'

interface OrganizationUserDetailsProps {
  user: ICurrentOrganizationUser
  onSave?: VoidFunction
}

type OrganizationUserDetailsSidebarProps = OrganizationUserDetailsProps & PopupProps

const OrganizationUserDetailsSidebar: FC<OrganizationUserDetailsSidebarProps> = ({
  user,
  isOpen,
  onSave,
  onClose,
}) => {
  const {
    isEnabled,
    isDefaultAuthorizedPerson,
    profile,
    position,
    firstName,
    lastName,
    id: userId,
    email,
    isAuthorizedPerson,
    fioGenitive,
    positionGenitive,
    reasonDocument,
    reasonProcurationId,
    reasonProcurationNumber,
    reasonProcurationEndDate,
  } = user

  const isSignPermission = profile === UserProfiles.HEADER

  const {
    organizationApi: { changeOrganizationUserDetails },
  } = useAPIContext()

  const { booleanState: isRequestLoading, setBooleanState: setRequestLoading } = useBooleanState()

  const preparedDefaultFormValues: OrganizationUserDetailsFormValues = useMemo(
    () => ({
      isAuthorizedPerson,
      procuration: reasonDocument
        ? organizationUserDetailsProcurationFieldValues.reasonDocument
        : organizationUserDetailsProcurationFieldValues.reasonProcurationId,
      position: position ?? '',
      positionGenitive: positionGenitive ?? '',
      fioGenitive: fioGenitive ?? '',
      reasonDocument: reasonDocument ?? '',
      reasonProcurationId: reasonProcurationId ?? '',
      reasonProcurationNumber: reasonProcurationNumber ?? '',
      reasonProcurationEndDate: reasonProcurationEndDate ?? '',
    }),
    [],
  )

  const formInstance = useForm<OrganizationUserDetailsFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: preparedDefaultFormValues,
  })

  const { isDirty, errors } = formInstance.formState

  const procurationValue = formInstance.watch(organizationUserDetailsFormNames.procuration)
  const reasonProcurationIdValue = formInstance.watch(
    organizationUserDetailsFormNames.reasonProcurationId,
  )

  const handleSuccessUpdateUserDetails = useCallback(() => {
    onClose?.()
    onSave?.()
  }, [onClose, onSave])

  const handleUpdateUserDetails: SubmitHandler<OrganizationUserDetailsFormValues> = useCallback(
    async ({
      procuration,
      reasonProcurationId,
      reasonDocument,
      reasonProcurationNumber,
      reasonProcurationEndDate,
      ...userDetails
    }) => {
      setRequestLoading(true)

      const isReasonProcurationId =
        procuration === organizationUserDetailsProcurationFieldValues.reasonProcurationId
      const isReasonDocument =
        procuration === organizationUserDetailsProcurationFieldValues.reasonDocument

      const currentUserDetails: IOrganizationUserDetails = {
        ...userDetails,
        isDefaultAuthorizedPerson,
        reasonProcurationId: isSignPermission && isReasonProcurationId ? reasonProcurationId : '',
        reasonDocument: isSignPermission && isReasonDocument ? reasonDocument : '',
      }

      const preparedUserDetailsToUpdate = (
        Object.entries(currentUserDetails) as Entries<IOrganizationUserDetails>
      ).reduce((previousValue, [key, value]) => {
        if (!isString(value)) {
          return {
            ...previousValue,
            [key]: value,
          }
        }

        const trimmedValue = value.trim()

        return {
          ...previousValue,
          [key]: isNotEmptyString(trimmedValue) ? trimmedValue : null,
        }
      }, {} as Partial<ICurrentOrganizationUser>)

      try {
        await changeOrganizationUserDetails({
          id: userId,
          userDetails: preparedUserDetailsToUpdate,
        })

        handleSuccessUpdateUserDetails()
      } catch (error) {
        throw error
      } finally {
        setRequestLoading(false)
      }
    },
    [
      changeOrganizationUserDetails,
      handleSuccessUpdateUserDetails,
      isDefaultAuthorizedPerson,
      isSignPermission,
      setRequestLoading,
      userId,
    ],
  )

  const handleCheckFormError = useCallback(
    (
      procuration: keyof typeof organizationUserDetailsProcurationFieldValues,
      formErrors: FieldErrors<OrganizationUserDetailsFormValues>,
    ) => {
      const { reasonProcurationId, reasonDocument, ...restFormErrors } = formErrors || {}

      const isReasonProcurationId =
        procuration === organizationUserDetailsProcurationFieldValues.reasonProcurationId
      const isReasonDocument =
        procuration === organizationUserDetailsProcurationFieldValues.reasonDocument

      const currentFormErrors = {
        ...restFormErrors,
        ...(isReasonProcurationId && {
          reasonProcurationId,
        }),
        ...(isReasonDocument && {
          reasonDocument,
        }),
      }

      return Object.values(currentFormErrors).some(Boolean)
    },
    [],
  )

  const sidebarActions: ButtonProps[] = useMemo(() => {
    const loadingProps = {
      loaderProps: {
        loading: isRequestLoading,
        variant: 'lite',
        placement: 'trailing',
      },
    } as const

    const hasFormError = handleCheckFormError(procurationValue, errors)

    const isEmptyReasonProcurationId =
      isSignPermission &&
      procurationValue === organizationUserDetailsProcurationFieldValues.reasonProcurationId &&
      !reasonProcurationIdValue

    return [
      {
        children: 'Отмена',
        view: 'gray',
        color: 'negative',
        disabled: isRequestLoading,
        onClick: onClose,
      },
      {
        children: 'Сохранить',
        onClick: formInstance.handleSubmit(handleUpdateUserDetails),
        disabled:
          isEmptyReasonProcurationId || !isEnabled || hasFormError || !isDirty || isRequestLoading,
        loaderProps: loadingProps.loaderProps,
      },
    ] as ButtonProps[]
  }, [
    errors.reasonDocument,
    errors.reasonProcurationId,
    errors.position,
    errors.positionGenitive,
    errors.fioGenitive,

    isRequestLoading,
    isSignPermission,
    handleCheckFormError,
    procurationValue,
    reasonProcurationIdValue,
    onClose,
    formInstance,
    handleUpdateUserDetails,
    isDirty,
  ])

  return (
    <Sidebar
      isOpen={isOpen}
      title={`${firstName} ${lastName}`}
      subtitle={email}
      actions={sidebarActions}
      headerClassName={styles.organizationUserDetailsSidebar__header}
      onClose={onClose}
    >
      <div className={styles.organizationUserDetailsSidebar__container}>
        <OrganizationUserDetailsController
          editMode={isEnabled}
          profile={profile}
          userId={userId}
          formInstance={formInstance}
        />
      </div>
    </Sidebar>
  )
}

const useOrganizationUserDetailsSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenOrganizationUserDetailsSidebar = (props: OrganizationUserDetailsProps) => {
    popupManager.open(OrganizationUserDetailsSidebar, props)
  }

  return {
    handleOpenOrganizationUserDetailsSidebar,
  }
}

export { useOrganizationUserDetailsSidebar }
export default OrganizationUserDetailsSidebar
