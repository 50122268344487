import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForPetitionRecognizePreviouslyConcludedContractAsBoundForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      relatedContractNumber: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractNumber?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractNumber?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractNumber?.lastUpdateDt,
      },
      relatedContractDate: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractDate?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractDate?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractDate?.lastUpdateDt,
      },
      relatedContractKind: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractKind?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractKind?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractKind?.lastUpdateDt,
      },
      projectName: {
        propertyId: objectFromPropertyState?.dfoRelatedContract?.value?.projectName?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.projectName?.type,
        lastUpdateDt: objectFromPropertyState?.dfoRelatedContract?.value?.projectName?.lastUpdateDt,
      },
      relatedContractMemberInstrumentals: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractMembersInstrumental
            ?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractMembersInstrumental
          ?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractMembersInstrumental
            ?.lastUpdateDt,
        value: isArray(
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractMembersInstrumental
            ?.value,
        )
          ? objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractMembersInstrumental?.value.map(
              (contract) => ({
                propertyId: contract.propertyId,
                type: contract.type,
                lastUpdateDt: contract.lastUpdateDt,
                value: {
                  item: {
                    propertyId: contract.propertyId,
                    type: contract.type,
                    lastUpdateDt: contract.lastUpdateDt,
                  },
                },
              }),
            )
          : [],
      },
    },
  }
}

export { generatePropertiesObjectForPetitionRecognizePreviouslyConcludedContractAsBoundForm }
