import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AssignmentMonetaryOrPledgeAFormValues } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof AssignmentMonetaryOrPledgeAFormValues['2'],
  AssignmentMonetaryOrPledgeAFormValues
> = {
  ruleLinkCession: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  cessionFormName: {
    required: defaultRHFValidation.required,
  },
  cessionValue: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  cessionReason: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  cessionMember: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  cessionAvailability: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { secondSectionValidationMap }
