import { THUMBPRINT } from '@components/Table/constants'
import storage from 'store2'

import { StorageFields } from '../models'

export const getClientId = () => storage.get(StorageFields.clientId)

export const resetKapClient = () => {
  localStorage.setItem(StorageFields.clientId, '')
}

export const getUserThumbprint = () => {
  return localStorage.getItem(THUMBPRINT) as string
}
