import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const stageTaxYearsFinancialModelValidationMap: FormValuesValidationSection<
  keyof FinancialModelFormValues['4']['stageTaxYears'][number],
  FinancialModelFormValues
> = {
  stageTaxYearValue: () => ({
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  }),
}

export { stageTaxYearsFinancialModelValidationMap }
