import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { applicationInclusionOfAARegisterSZPKBlockValues } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/const'
import { secondSectionValidationMap } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/Forms/2/validation'
import { useApplicationInclusionOfAARegisterSZPKManager } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/Manager'
import { ApplicationInclusionOfAARegisterSZPKFormValues } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/types'
import { ApplicationInclusionOfAARegisterSZPKFieldsControlUpdateWatcher } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'

const Second = () => {
  const formInstance = useFormContext<ApplicationInclusionOfAARegisterSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useApplicationInclusionOfAARegisterSZPKManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ApplicationInclusionOfAARegisterSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Col xs={12}>
        <Row>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: applicationInclusionOfAARegisterSZPKBlockValues['2'].extraStatementName,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: applicationInclusionOfAARegisterSZPKBlockValues['2'].extraStatementName,
                rules: secondSectionValidationMap.extraStatementName,
                textareaProps: {
                  label: 'Наименование дополнительного соглашения',
                  caption: 'не обязательно',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      applicationInclusionOfAARegisterSZPKBlockValues['2'].extraStatementName,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      applicationInclusionOfAARegisterSZPKBlockValues['2'].extraStatementName,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Row>
      </Col>
    </Container>
  )
}

export default Second
