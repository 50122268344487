import { usePopupManager } from 'react-popup-manager'

import type { DefaultModalProps } from '@globalTypes/base'

import RevokeModal, { TRevokeModal } from '.'

const useRevokeModal = () => {
  const popupManager = usePopupManager()

  const handleOpenRevokeModal = ({
    actionId,
    projectId,
    handleRevokeSuccess,
    handleRevokeError,
    headerText,
    uiActionTrigger,
    textBody,
    onClose,
  }: TRevokeModal & DefaultModalProps) => {
    popupManager.open(RevokeModal, {
      actionId,
      projectId,
      uiActionTrigger,
      textBody:
        textBody ||
        'После отзыва рассмотрение соглашения будет прекращено. На основе отозванного соглашения вы сможете создать новую версию соглашения и отправить её на согласование',
      headerText: headerText || 'Вы действительно хотите отозвать соглашение',
      handleRevokeSuccess,
      handleRevokeError,
      onClose: onClose ?? (() => null),
    })
  }

  return {
    handleOpenRevokeModal,
  }
}

export { useRevokeModal }
