import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { TNpaTypesFromDict } from '@services/NPA/NPA.entity'
import useSWRImmutable from 'swr/immutable'

const useNpaTypes = ({ key, config }: SWRHookProps<TNpaTypesFromDict | undefined>) => {
  const {
    npaApi: { getNpaTypes },
  } = useAPIContext()

  const {
    data: npaTypes,
    error,
    mutate,
    isValidating: isLoadingNpaTypes,
  } = useSWRImmutable(key, getNpaTypes, config)

  const mapOfNpaTypes = useMemo(() => {
    if (!npaTypes) return

    return npaTypes.reduce(
      (prevValue, currentValue) => ({
        ...prevValue,
        [currentValue.type]: currentValue.description,
      }),
      {},
    )
  }, [npaTypes])

  return {
    npaTypes,
    mapOfNpaTypes,
    error,
    mutate,
    isLoadingNpaTypes,
  }
}

export { useNpaTypes }
