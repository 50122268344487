import React from 'react'
import { useFormContext } from 'react-hook-form'

import InfoAboutDocumentSection from '@components/DocumentFormComponents/FormSections/InfoAboutDocumentSection'
import { useCertificateApplicationConclusionOfAARequirementsManager } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/Manager'
import { CertificateApplicationConclusionOfAARequirementsFormValues } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/types'
import { CertificateApplicationConclusionOfAARequirementsFieldsControlUpdateWatcher } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/watcher'

const First = () => {
  const formInstance = useFormContext<CertificateApplicationConclusionOfAARequirementsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateApplicationConclusionOfAARequirementsManager()

  if (!formInstance) return null

  return (
    <InfoAboutDocumentSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={CertificateApplicationConclusionOfAARequirementsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}
export default First
