import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectsFormValues,
  ObjectRegion,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const objectRegionsValidationMap: FormValuesValidationSection<
  keyof ObjectRegion,
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  objectRegion: {
    required: defaultRHFValidation.required,
  },
}

export { objectRegionsValidationMap }
