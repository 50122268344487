import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForAAgreementIncludeRelatedTermsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '4': {
      relatedContractNumber: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractNumber?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractNumber?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractNumber?.lastUpdateDt,
      },
      relatedContractDate: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractDate?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractDate?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractDate?.lastUpdateDt,
      },
      relatedContractTerms: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractTerms?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractTerms?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractTerms?.lastUpdateDt,
      },
      relatedContractPublicLawMember: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractPublicLawMember
            ?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractPublicLawMember
          ?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractPublicLawMember
            ?.lastUpdateDt,
      },
      relatedContractPublicLawMemberInstrumental: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value
            ?.relatedContractPublicLawMemberInstrumental?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value
          ?.relatedContractPublicLawMemberInstrumental?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value
            ?.relatedContractPublicLawMemberInstrumental?.lastUpdateDt,
      },
      agreementLinkDamageCompensations: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.agreementLinkDamageCompensations
            ?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.agreementLinkDamageCompensations
          ?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.agreementLinkDamageCompensations
            ?.lastUpdateDt,
      },
      isSubsidyInvestmentViolation: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.isSubsidyInvestmentViolation
            ?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.isSubsidyInvestmentViolation
          ?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.isSubsidyInvestmentViolation
            ?.lastUpdateDt,
      },
      isSubsidyCreditViolation: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.isSubsidyCreditViolation?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.isSubsidyCreditViolation?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.isSubsidyCreditViolation
            ?.lastUpdateDt,
      },
      isLongTermParametersChanging: {
        propertyId:
          objectFromPropertyState?.dfoRelatedContract?.value?.isLongTermParametersChanging
            ?.propertyId,
        type: objectFromPropertyState?.dfoRelatedContract?.value?.isLongTermParametersChanging
          ?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoRelatedContract?.value?.isLongTermParametersChanging
            ?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForAAgreementIncludeRelatedTermsForm }
