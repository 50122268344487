import { useMemo } from 'react'

import { isPermissionForChange } from '@components/Projects/[id]/ActionBanner/Manager'
import { getDfoById, getDfoByType } from '@components/Projects/[id]/helpers'
import { accessPermissions, DocumentsType, NewDfosType } from '@constants/types'
import { changeFirstLetter } from '@helpers/string/makeFirstLetterInUC'
import { useDfoDocuments } from '@hooks/new/swr/useDfoDocuments'
import { useProject } from '@hooks/new/swr/useProject'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useQueryManager } from '@hooks/useQueryManager'
import { actionsInitialProps } from '@services/DocumentActions/DocumentActions.const'
import { DocumentActionEnum } from '@services/DocumentActions/DocumentActions.entity'
import { IDocumentSetInfo } from '@services/Documents/documents.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { GroupType } from '@services/Projects/Project.entity'

const useDocumentActionsEnvironment = (projectId: string) => {
  const { queryUtils } = useQueryManager()
  const dfoId = queryUtils.getQuery('dfoId')

  const { project } = useProject({
    key: {
      projectId,
      _key: 'project',
    },
    config: {
      isPaused: () => !projectId,
    },
  })
  const { projectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useDocumentActionsEnvironment',
          moduleName: 'useDocumentActions',
          componentType: 'hook',
        },
      }),
    },
  })

  const currentDfo = useMemo(() => {
    if (!projectDfos || !dfoId) return

    return getDfoById(projectDfos, dfoId)
  }, [dfoId, projectDfos])

  const SZPKDfo = useMemo(() => {
    if (!projectDfos || !dfoId) return

    return getDfoByType(projectDfos, NewDfosType.SZPK)
  }, [dfoId, projectDfos])

  const redirectDfoIdForDeleteProps = useMemo(() => {
    return projectDfos
      ?.filter((dfo) => {
        return dfo.id !== dfoId
      })
      .pop()?.id
  }, [dfoId, projectDfos])

  const nameDfoForDeleteProps = useMemo(() => {
    if (currentDfo?.type.startsWith(NewDfosType.CESSION)) {
      return 'уступку денежных требований/передачу прав в залог'
    }

    if (
      currentDfo?.type.startsWith(GroupType.CLAIM_SUBSIDY_PERMIT) ||
      currentDfo?.type.startsWith(GroupType.CLAIM_TAX_REFUND)
    )
      return 'заявление на возмещение'

    return currentDfo?.typeTitle ? changeFirstLetter(currentDfo?.typeTitle, 'lower') : ''
  }, [currentDfo?.type, currentDfo?.typeTitle])

  const { dfoDocuments: SZPKDocuments } = useDfoDocuments({
    key: { dfoId: SZPKDfo?.id, projectId, _key: 'document-sets' },
    config: {
      isPaused: () => !projectId || !SZPKDfo?.id,
    },
  })

  const { dfoDocuments: currentDfoDocuments } = useDfoDocuments({
    key: { dfoId: currentDfo?.id, projectId, _key: 'document-sets' },
    config: {
      isPaused: () => !projectId || !currentDfo?.id,
    },
  })

  const SZPKDocumentsSetId = useMemo(() => {
    if (!SZPKDocuments) return

    const SZPKDocumentsWithWriteAccess = Object.values(SZPKDocuments).reduce(
      (previousValue, currentValue) => {
        previousValue.push(
          ...currentValue.filter(
            (item) =>
              item.permissions === accessPermissions.READ_ADD_SIGN ||
              item.permissions === accessPermissions.READ_EDIT,
          ),
        )

        return previousValue
      },
      [] as IDocumentSetInfo[],
    )

    if (!SZPKDocumentsWithWriteAccess.length) return SZPKDocuments?.SZPK?.[0]?.documentSetId

    const SZPKDocumentSetWithStatementAndActiveDraft = SZPKDocumentsWithWriteAccess.find((item) =>
      item.documentTypeInfo.find(
        (documentInfo) =>
          documentInfo.description === DocumentsType.STATEMENT && documentInfo.isDraftExists,
      ),
    )

    return (
      SZPKDocumentSetWithStatementAndActiveDraft?.documentSetId ||
      SZPKDocuments?.SZPK?.[0]?.documentSetId
    )
  }, [SZPKDocuments])

  const isDocumentChange = useMemo(() => {
    if (!currentDfoDocuments) return false

    const keyChange = Object.keys(currentDfoDocuments).find((key) => key.includes('_CHANGES'))

    if (!keyChange) return false

    return isPermissionForChange(currentDfoDocuments[keyChange][0])
  }, [currentDfoDocuments])

  const isProjectFederal = useMemo(() => project?.isFederal, [project?.isFederal])

  const fullObjectProps = useMemo(
    () => ({
      projectDfos,
      isDocumentChange,
      SZPKDocumentsSetId,
      redirectDfoIdForDeleteProps,
      nameDfoForDeleteProps,
      isProjectFederal,
    }),
    [
      SZPKDocumentsSetId,
      isDocumentChange,
      isProjectFederal,
      nameDfoForDeleteProps,
      projectDfos,
      redirectDfoIdForDeleteProps,
    ],
  )

  const getEnvironmentByType = (documentActionType: DocumentActionEnum) => {
    const propsToNeed = actionsInitialProps[documentActionType]

    if (!propsToNeed) return fullObjectProps

    const initialPropsToReturn = propsToNeed.reduce((previousValue, currentValue) => {
      if (currentValue in fullObjectProps) {
        return {
          ...previousValue,
          [currentValue]: fullObjectProps[currentValue],
        }
      }

      return previousValue
    }, [])

    return initialPropsToReturn
  }

  return {
    getEnvironmentByType,
  }
}

export { useDocumentActionsEnvironment }
