import { mapOfActionAllowed, mapOfStageStatementFilters } from '@routes/NewStatements/const'
import {
  BasketFilterValue,
  FilterFactory,
  StatementFilterFormValues,
} from '@routes/NewStatements/types'

class MERFilterController implements FilterFactory {
  readonly mapOfFiltersByType: Partial<
    Record<BasketFilterValue, Partial<StatementFilterFormValues>>
  > = {
    all: {
      stage: 'all',
      statementLvl: 'all',
      actionAllowed: 'all',
    },
    concluded: {
      stage: mapOfStageStatementFilters.ACTIVE,
      isRegistered: true,
    },
    signing: {
      actionAllowed: mapOfActionAllowed.needAttention,
      stage: mapOfStageStatementFilters.SIGNING,
    },
    consideration: {
      stage: mapOfStageStatementFilters.CHECKING,
    },
    drafts: {
      stage: mapOfStageStatementFilters.DRAFT,
    },
    archive: {
      stage: mapOfStageStatementFilters.ARCHIVE,
    },
  }

  getAllMapOfFilters() {
    return this.mapOfFiltersByType
  }

  getFilters(tabValue: BasketFilterValue): StatementFilterFormValues | Record<string, unknown> {
    if (!this.mapOfFiltersByType[tabValue]) return {}
    return this.mapOfFiltersByType[tabValue] as StatementFilterFormValues
  }
}

export { MERFilterController }
