import React, { ChangeEvent, memo } from 'react'
import { useForm } from 'react-hook-form'

import Icon from '@components/Icon/Icon'
import KnowledgeBaseList from '@components/KnowledgeBase/Desktop/List'
import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import searchIcon from '@icons/action/search.svg'

import styles from './Menu.module.scss'

const KnowledgeBaseDesktopMenu = () => {
  const {
    state: { currentSearchPattern },
    handlers: { handleChangeSearchPattern },
  } = useKnowledgeBase()

  const { control } = useForm({
    defaultValues: {
      knowledgeBaseSearch: currentSearchPattern,
    },
  })

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value.trim()

    handleChangeSearchPattern?.(currentValue)
  }

  return (
    <div className={styles.menu}>
      <ControlledInput
        name="knowledgeBaseSearch"
        control={control}
        inputProps={{
          size: 's',
          fixWidth: true,
          view: 'secondary',
          leftAddons: <Icon src={searchIcon} className={styles.menu__icon} size="initial" />,
          clear: true,
          placeholder: 'Поиск',
        }}
        rules={{
          onChange: onSearchInputChange,
        }}
      />
      <KnowledgeBaseList />
    </div>
  )
}

export default memo(KnowledgeBaseDesktopMenu)
