import { useRegions } from '@hooks/new/swr/useRegions'
import { useRegionsByProject } from '@hooks/new/swr/useRegionsByProject'

const useFormRegions = (projectId: string) => {
  useRegions({
    key: { _key: 'projectRegions' },
  })

  useRegionsByProject({
    key: {
      projectId,
      _key: 'projectRegions',
    },
    config: {
      revalidateOnMount: true,
    },
  })
}

export { useFormRegions }
