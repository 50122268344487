import React, { FC } from 'react'

import { WebFormAddonBaseProps } from '@components/Attachments/WebForm/Addon/types'
import { documentsWebFormStatesMap } from '@services/Documents/documents.const'

const WebFormBaseGovernmentController: FC<WebFormAddonBaseProps> = ({
  documentProps,
  children,
}) => {
  const { forms, documents } = documentProps || {}

  const formInfo = forms?.[0]

  if (
    !formInfo ||
    !formInfo.id ||
    !documents?.length ||
    formInfo.state === documentsWebFormStatesMap.EDITABLE
  )
    return null

  return <>{children}</>
}

export default WebFormBaseGovernmentController
