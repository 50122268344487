import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  IEconomicSphereFederalReport,
  SummaryReportOfMonitoringResultsFormValues,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const summaryReportOfMonitoringResultsEconomicSpheresValidationMap: FormValuesValidationSection<
  keyof IEconomicSphereFederalReport,
  SummaryReportOfMonitoringResultsFormValues
> = {
  economicSphereNameFederalReport: {
    required: defaultRHFValidation.required,
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { summaryReportOfMonitoringResultsEconomicSpheresValidationMap }
