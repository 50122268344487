import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeOfProjectRejectConcludeSZPKBlockValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/const'
import { thirdSectionValidationMap } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/Forms/3/validation'
import { useNoticeOfProjectRejectConcludeSZPKManager } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/Manager'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'
import { NoticeOfProjectRejectConcludeSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance = useFormContext<NoticeOfProjectRejectConcludeSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOfProjectRejectConcludeSZPKManager()

  const { getSubscribableControlProps, getControllerProps, getInputProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: NoticeOfProjectRejectConcludeSZPKFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: noticeOfProjectRejectConcludeSZPKBlockValues['3'].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование проекта'}
                controllerProps={getControllerProps({
                  name: noticeOfProjectRejectConcludeSZPKBlockValues['3'].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title="Пункт Правил, на основании которого направляется уведомление об отказе в заключении СЗПК"
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: noticeOfProjectRejectConcludeSZPKBlockValues['3'].ruleLinkStatementRejectDraft,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: noticeOfProjectRejectConcludeSZPKBlockValues['3']
                  .ruleLinkStatementRejectDraft,
                rules: thirdSectionValidationMap.ruleLinkStatementRejectDraft,
                inputProps: {
                  caption: 'рекомендация: 27',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      noticeOfProjectRejectConcludeSZPKBlockValues['3']
                        .ruleLinkStatementRejectDraft,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      noticeOfProjectRejectConcludeSZPKBlockValues['3']
                        .ruleLinkStatementRejectDraft,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title="Основания, в связи с которыми не может быть заключено СЗПК"
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: noticeOfProjectRejectConcludeSZPKBlockValues['3'].statementRejectReasonDraft,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: noticeOfProjectRejectConcludeSZPKBlockValues['3'].statementRejectReasonDraft,
                rules: thirdSectionValidationMap.statementRejectReasonDraft,
                textareaProps: {},
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      noticeOfProjectRejectConcludeSZPKBlockValues['3'].statementRejectReasonDraft,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      noticeOfProjectRejectConcludeSZPKBlockValues['3'].statementRejectReasonDraft,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title="Пункт правил, в соответствии с которым можно повторно подать заявление на СЗПК"
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: noticeOfProjectRejectConcludeSZPKBlockValues['3'].ruleLinkStatementRepeatDraft,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: noticeOfProjectRejectConcludeSZPKBlockValues['3']
                  .ruleLinkStatementRepeatDraft,
                rules: thirdSectionValidationMap.ruleLinkStatementRepeatDraft,
                inputProps: {
                  caption: 'рекомендация: 36',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      noticeOfProjectRejectConcludeSZPKBlockValues['3']
                        .ruleLinkStatementRepeatDraft,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      noticeOfProjectRejectConcludeSZPKBlockValues['3']
                        .ruleLinkStatementRepeatDraft,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </Container>
  )
}

export default Third
