import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'

const signSZPKpermission = 'Подписывать документы СЗПК'

const procurationComponentInfo: ComponentInfo = {
  componentName: 'Procurations',
  moduleName: 'ProcurationForm',
  componentType: 'authByProcurationCertificate',
}

export { procurationComponentInfo, signSZPKpermission }
