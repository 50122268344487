import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { IOrganizationInfo } from '@services/Organization/organization.entity'
import useSWRImmutable from 'swr/immutable'

const useOrganizationInfo = ({ key, config }: SWRHookProps<IOrganizationInfo | undefined>) => {
  const {
    organizationApi: { getOrganizationInfo },
  } = useAPIContext()

  const {
    data: organizationInfo,
    error,
    mutate,
    isValidating: isLoadingOrganization,
  } = useSWRImmutable(key, getOrganizationInfo, config)

  return {
    organizationInfo,
    isLoadingOrganization,
    error,
    mutate,
  }
}

export { useOrganizationInfo }
