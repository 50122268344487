import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useApplicationConclusionAdditionalAgreementFormManager } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/Manager'
import { ApplicationConclusionAdditionalAgreementFormFormValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/types'

import { mapOfApplicationConclusionAdditionalAgreementFormMenu } from '../const'
import ApplicationConclusionAdditionalAgreementFormForms from '../Forms'

const mapOfApplicationConclusionAdditionalAgreementFormForms: FormItemProps = {
  '1': {
    node: <ApplicationConclusionAdditionalAgreementFormForms.First />,
  },
  '2': {
    node: <ApplicationConclusionAdditionalAgreementFormForms.Second />,
  },
} as const

const ApplicationConclusionAdditionalAgreementForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<ApplicationConclusionAdditionalAgreementFormFormValues>()

  const {
    state: { formIsLoading },
  } = useApplicationConclusionAdditionalAgreementFormManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfApplicationConclusionAdditionalAgreementFormMenu}
      mapOfForms={mapOfApplicationConclusionAdditionalAgreementFormForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(ApplicationConclusionAdditionalAgreementForm)
