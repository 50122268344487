import React, { ReactNode } from 'react'

import {
  ItemOfListToTransform,
  useTransformToListIds,
} from '@components/DocumentFormComponents/hooks/useTransformToListIds'
import Stack from '@components/ReactBootstrap/Stack'
import { Flipper } from '@components/ReactFlipToolkit'
import { FlipperProps } from '@components/ReactFlipToolkit/types'

interface FlipperListProps<KeyId extends string = 'id'> extends Omit<FlipperProps, 'flipKey'> {
  list: ItemOfListToTransform<KeyId>[]
  children: ReactNode
  listIdName?: KeyId
  id?: string
  direction?: 'vertical' | 'horizontal'
  gap?: number
  stackClassName?: string
}

const FlipperList = <KeyId extends string = 'id'>({
  listIdName = 'id' as KeyId,
  gap = 3,
  direction = 'vertical',
  id,
  list,
  children,
  stackClassName,
  ...flipperProps
}: FlipperListProps<KeyId>) => {
  const { listIds } = useTransformToListIds(list, listIdName)

  return (
    <Flipper {...flipperProps} flipKey={listIds.join()}>
      <Stack id={id} direction={direction} gap={gap} className={stackClassName}>
        {children}
      </Stack>
    </Flipper>
  )
}

export default FlipperList
