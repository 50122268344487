import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidObject,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const ridFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidObject,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  ridKind: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  ridName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  ridAddress: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  ridOwner: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  ridDetails: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
}

export { ridFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
