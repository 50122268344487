import React, { useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementForceCircumstancesBlockValues } from '@components/Forms/AAgreementForceCircumstancesForm/const'
import AAgreementForceCircumstancesStage from '@components/Forms/AAgreementForceCircumstancesForm/Forms/5/AdditionalForms/Stage'
import { AAgreementForceCircumstancesStagesPathName } from '@components/Forms/AAgreementForceCircumstancesForm/Forms/5/types'
import { useAAgreementForceCircumstancesManager } from '@components/Forms/AAgreementForceCircumstancesForm/Manager'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import {
  AAgreementForceCircumstancesFieldArrayControlUpdateWatcher,
  AAgreementForceCircumstancesFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementForceCircumstancesForm/watcher'
import Container from '@components/ReactBootstrap/Container'

const Fifth = () => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useAAgreementForceCircumstancesManager()

  const { fields: stages } = useFieldArraySubscribableControl<
    AAgreementForceCircumstancesFormValues,
    AAgreementForceCircumstancesStagesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aAgreementForceCircumstancesBlockValues['5'].stages,
    keyName: 'keyNameId',
    watcher: AAgreementForceCircumstancesFieldArrayControlUpdateWatcher,
  })

  const accordionStateRefs = useRef<boolean[]>([])

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementForceCircumstancesFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleUpdateAccordionStateOnToggle = (index: number) => (value: boolean) => {
    accordionStateRefs.current[index] = value
  }

  return (
    <Container className={'p-0'}>
      <SubscribableControl
        {...getSubscribableControlProps({
          path: aAgreementForceCircumstancesBlockValues['5'].stages,
        })}
      >
        <FlipperList list={stages}>
          {stages.map((stage, index) => {
            const formName =
              `${aAgreementForceCircumstancesBlockValues['5'].stages}.${index}` as const

            return (
              <SubscribableControl
                key={stage.id}
                {...getSubscribableControlProps({
                  path: formName,
                })}
              >
                <Accordion
                  id={stage.id}
                  title={`${stage.stepNumber} ${stage.stepDescription}`}
                  initialAccordionState={accordionStateRefs.current[index]}
                  onToggle={handleUpdateAccordionStateOnToggle(index)}
                >
                  <AAgreementForceCircumstancesStage formName={formName} />
                </Accordion>
              </SubscribableControl>
            )
          })}
        </FlipperList>
      </SubscribableControl>
    </Container>
  )
}

export default Fifth
