import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'

const certificateOnMonitoringResultsReasonGroupList = [
  {
    name: certificateOnMonitoringResultsBlockValues['12'].relatedContractReason,
    label: 'Включение в соглашение сведений об условиях связанного договора',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].extendStabilizationReason,
    label: 'Увеличение срока применения\n' + 'стабилизационной оговорки',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].municipalAccessionReason,
    label: 'Присоединение муниципальных образований',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].rightTransferReason,
    label: 'Передача прав и обязанностей',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].infoChangesReason,
    label: 'Изменение реквизитов сторон соглашения',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].forceMajeureReason,
    label:
      'Невозможность реализации проекта в установленные сроки в\n' +
      'результате возникновения обстоятельств непреодолимой силы',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].agreementDutyReason,
    label:
      'Включение в соглашение сведений о договоре о распределении затрат на объекты инфраструктуры',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].concessionFailReason,
    label: 'Незаключение организацией, реализующей\n' + 'проект, концессионного соглашения',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].constructionChangeReason,
    label:
      'Изменение характеристик создаваемых либо реконструируемых и (или) модернизируемых в рамках проекта объектов недвижимости',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].supportingInfrastructureReason,
    label:
      'Внесение изменений в инвестиционный\n' +
      'проект в связи с необходимостью\n' +
      'создания либо реконструкции и (или) модернизации иных объектов недвижимости',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].investmentChangeReason,
    label: 'Изменение объема капиталовложений',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].compensationChangeReason,
    label: 'Изменение объема планируемых к\n' + 'возмещению затрат',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].changeCompensationFormatReason,
    label: 'Изменение формы меры государственной\n' + 'поддержки',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].lawChangeReason,
    label:
      'Изменения в законодательстве\n' +
      'Российской Федерации, улучшающие\n' +
      'положение организации, реализующей\n' +
      'проект, и имеющие обратную силу',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].noMadeInvestorCapitalReason,
    label:
      'Неосуществление капиталовложений,\n' +
      'предусмотренных условиями соглашения,\n' +
      'в течение более чем 2 лет по истечении\n' +
      'предусмотренного соглашением срока\n' +
      'осуществления капиталовложений',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].legalFactsReason,
    label:
      'Ненаступление отдельных юридических\n' +
      'фактов, предусмотренных условиями\n' +
      'соглашения, в течение более чем 2 лет по\n' +
      'истечении предусмотренного соглашением\n' +
      'срока осуществления капиталовложений',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].lawViolationReason,
    label:
      'Нарушение организацией, реализующей\n' +
      'проект, или ее должностными лицами\n' +
      'законодательства Российской Федерации',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].suspensionOrganizationActivityReason,
    label:
      'В отношении организации, реализующей\n' +
      'проект, открыто конкурсное производство\n' +
      'в соответствии с Федеральным законом\n' +
      '"О несостоятельности (банкротстве)"',
  },
  {
    name: certificateOnMonitoringResultsBlockValues['12'].liquidationOrganisationReason,
    label: 'Принято решение о ликвидации\n' + 'организации, реализующей проект',
  },
]

export { certificateOnMonitoringResultsReasonGroupList }
