import { objOfDateFormats } from '@constants/dateFormats'
import dayjs, { Dayjs } from 'dayjs'
import qs from 'qs'

type DayjsFormatParameter = Parameters<Dayjs['format']>[0]

export const formatDate = (
  date: Dayjs | string,
  format: DayjsFormatParameter = objOfDateFormats.defaultFormat,
) => getDateInLocalTimezoneFormat(date, format)

export const getDateInLocalTimezoneFormat = (
  date: Dayjs | string,
  format: DayjsFormatParameter,
) => {
  const localTimezone = dayjs.tz.guess()

  return dayjs(date).utc(!!date).tz(localTimezone).format(format)
}

export const stringifyQuery: typeof qs.stringify = (obj, options) => {
  return qs.stringify(obj, {
    arrayFormat: 'repeat',
    ...options,
  })
}

export const formatCurrency = (num: string | number) => {
  num = Math.abs(Number(num))

  if (num >= 1.0e12) {
    return (num / 1.0e12).toFixed(1) + ' трлн'
  }

  if (num >= 1.0e9 && num < 1.0e12) {
    return (num / 1.0e9).toFixed(1) + ' млрд'
  }

  if (num >= 1.0e6 && num < 1.0e9) {
    return (num / 1.0e6).toFixed(1) + ' млн'
  }

  return (num / 1.0e3).toFixed(1) + ' тыс'
}

export const dateWithTime = (date: Dayjs, hours: number, minutes: number) =>
  date
    .set('hour', hours || 0)
    .set('minute', minutes || 0)
    .set('second', 0)
    .toISOString()
