import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { getAmountValueFromStrWithoutRound } from '@components/NewDesign/AmountInput/utils'
import {
  defaultRHFValidation,
  lengthValidate,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const seventhSectionValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  stepNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  stepDescription: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  stepStartDate: {
    required: defaultRHFValidation.required,
  },
  stepEndDate: {
    required: defaultRHFValidation.required,
  },
  stepInvestorCapitalValue: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  stepCompensationValue: ({ form }) => ({
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
    onBlur: async () => {
      const stages = form.getValues(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages,
      )

      const projectSupportCompensation = form.getValues(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
          .projectSupportCompensation,
      )
      const projectRelatedCompensation = form.getValues(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
          .projectRelatedCompensation,
      )

      const numberProjectSupportCompensation = getAmountValueFromStrWithoutRound(
        projectSupportCompensation,
      )
      const numberProjectRelatedCompensation = getAmountValueFromStrWithoutRound(
        projectRelatedCompensation,
      )

      stages.forEach((_, index) => {
        form.trigger(
          `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${index}.stepCompensationValue`,
        )
      })

      if (numberProjectSupportCompensation) {
        form.clearErrors(
          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
            .projectSupportCompensation,
        )
      }

      if (numberProjectRelatedCompensation) {
        form.clearErrors(
          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['6']
            .projectRelatedCompensation,
        )
      }
    },
  }),
  stepInvestorCapitalDate: {
    required: defaultRHFValidation.required,
  },
  oldStepInvestorCapitalSources: {
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { seventhSectionValidationMap }
