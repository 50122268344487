const additionalDfoTypesToShowActualProjectSZPKMap = {
  EXTRA_CONCESSION_FAIL: 'EXTRA_CONCESSION_FAIL',
  EXTRA_CONSTRUCTION_CHANGE: 'EXTRA_CONSTRUCTION_CHANGE',
  EXTRA_DISTRIBUTION_EXPEND: 'EXTRA_DISTRIBUTION_EXPEND',
  EXTRA_FORCE_MAJEURE: 'EXTRA_FORCE_MAJEURE',
  EXTRA_INVESTMENT_CHANGE: 'EXTRA_INVESTMENT_CHANGE',
  EXTRA_REIMBURSEMENT_CHANGE: 'EXTRA_REIMBURSEMENT_CHANGE',
  EXTRA_SUPPORTING_INFRASTRUCTURE: 'EXTRA_SUPPORTING_INFRASTRUCTURE',
  EXTRA_TERM_EXTENSION: 'EXTRA_TERM_EXTENSION',
  SZPK_RIGHTS_TRANSFER: 'SZPK_RIGHTS_TRANSFER',
  SZPK_SIGNER_INFO_CHANGES: 'SZPK_SIGNER_INFO_CHANGES',
  ORG_REORGANIZATION: 'ORG_REORGANIZATION',
  ORG_REORGANIZATION_SPLIT_OFF: 'ORG_REORGANIZATION_SPLIT_OFF',
} as const

export { additionalDfoTypesToShowActualProjectSZPKMap }
