import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Collapse from '@components/Collapse'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import { ControlledSingleSelect } from '@components/NewDesign/Select'
import Sidebar from '@components/NewDesign/Sidebar'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import ControlledOMSUSelect from '@components/PreparedSelects/OMSUSelect'
import { mapOfNpaLvlSelectOptions } from '@components/Sidebars/NPA/Edit/const'
import { useNpaSelectValues } from '@components/Sidebars/NPA/hooks/useNpaSelectValues'
import { useNpaViewSidebar } from '@components/Sidebars/NPA/View'
import FileView, { FileItemViewProps } from '@components/Sidebars/NPA/View/FileView'
import WithCaption from '@components/WithCaption'
import WithUpload from '@components/WithUpload'
import { objOfDateFormats } from '@constants/dateFormats'
import { RolesTypes } from '@constants/types'
import { defaultRHFValidation, lengthValidate, Patterns } from '@constants/validations'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { isEmptyString, isObjectType, isString } from '@helpers/checkTypes'
import { disableFutureDates } from '@helpers/date/disableOfDates'
import { splitFileName } from '@helpers/fileHelpers'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useOMSUList } from '@hooks/new/swr/useOMSUList'
import { useOrganizationInfo } from '@hooks/new/swr/useOrganizationInfo'
import { useBooleanState } from '@hooks/useBooleanState'
import CircleAddIcon from '@icons/AddCircleIcon.svg'
import DayjsService from '@services/Dayjs/Dayjs.service'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { INpaRedaction, TMasterTypeNpa } from '@services/NPA/NPA.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import styles from './NPAEdit.module.scss'
import StabilizedPositions from './StabilizedPositions'

export interface NpaEditComponentProps {
  npa?: INpaRedaction

  onReloadGrid?: () => void
  onReloadNpa?: () => void
}

type NpaEditSidebarProps = PopupProps & NpaEditComponentProps

export interface NpaFormValues {
  name: string
  level: 'FEDERAL' | 'REGIONAL' | 'MUNICIPAL'
  file: {
    file: FileItemViewProps
    downloadedId: string
  } | null

  date: string

  redactionDate: string
  redactionNumber: string
  region: string
  oktmo: string
  link: string

  type: TMasterTypeNpa | ''

  number: string

  parts: { part: string; classificationId: string }[]

  complexName: string
}

const NPAModeTypes = {
  addNPA: 'addNPA',
  editNPA: 'editNPA',
} as const

const useNpaEditSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenNpaEditSidebar = (npaEditProps: NpaEditComponentProps) => {
    popupManager.open(NpaEditSidebar, npaEditProps)
  }

  return {
    handleOpenNpaEditSidebar,
  }
}

//Если npa передан - editMode, иначе createMode

const NpaEditSidebar: FC<NpaEditSidebarProps> = ({
  npa,
  onReloadGrid,
  onReloadNpa,
  isOpen,
  onClose,
}) => {
  const {
    npaApi: { createNpa, updateNpa, createNpaFile },
  } = useAPIContext()

  const sidebarRef = useRef<HTMLDivElement | null>(null)

  const { handleOpenNpaViewSidebar } = useNpaViewSidebar()

  const { checkingRole } = useAuthContext()

  const { preparedTypesForSelect, preparedRegionsWithCodesForSelect } = useNpaSelectValues()

  const isMER = !!checkingRole?.(RolesTypes.MER)
  const isOIV = !!checkingRole?.(RolesTypes.OIV)
  const isOMSU = !!checkingRole?.(RolesTypes.OMSU)

  const { organizationInfo, isLoadingOrganization } = useOrganizationInfo({
    key: { _key: 'organizationInfo' },
  })

  const { booleanState: fileIsLoading, setBooleanState: changeFileIsLoading } = useBooleanState()

  const initialOktmo = useMemo(() => {
    if (isOMSU && !npa && organizationInfo) {
      return String(organizationInfo.municipalityOktmo)
    }

    if (npa) return String(npa.oktmo)

    return ''
  }, [isOMSU, npa, organizationInfo])

  const npaFormInstance = useForm<NpaFormValues>({
    defaultValues: {
      name: npa?.name ?? '',
      file: npa?.documentId
        ? {
            file: {
              documentExtension: npa.documentExtension,
              documentSize: npa.documentSize,
              documentName: npa.documentName,
            },
            downloadedId: npa.documentId,
          }
        : null,
      level: npa?.level ?? (isMER ? 'FEDERAL' : isOMSU ? 'MUNICIPAL' : 'REGIONAL'),
      date: npa
        ? DayjsService.dayjs(npa.date, objOfDateFormats.defaultNativeDateFormat).format(
            objOfDateFormats.defaultFormat,
          )
        : '',
      link: npa?.link,
      redactionNumber: npa?.redactionNumber ?? '',
      redactionDate: npa
        ? DayjsService.dayjs(npa.redactionDate, objOfDateFormats.defaultNativeDateFormat).format(
            objOfDateFormats.defaultFormat,
          )
        : '',
      region: npa?.region ? String(npa?.region) : '',
      oktmo: initialOktmo,
      type: npa?.type ?? '',
      parts: npa?.parts || [{ part: '', classificationId: '' }],
      number: npa?.number || '',
      complexName: npa?.complexName || '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const {
    booleanState: isRequestLoading,
    setBooleanStateToFalse: disableLoading,
    setBooleanStateToTrue: enableLoading,
  } = useBooleanState()

  const initialFocusRef = useRef<HTMLInputElement | null>(null)

  const currentFileInfo = npaFormInstance.watch('file.file')
  const currentFile = npaFormInstance.watch('file')
  const currentLvl = npaFormInstance.watch('level')
  const currentRegion = npaFormInstance.watch('region')
  const currentOktmo = npaFormInstance.watch('oktmo')

  const OMSUOrganizationsKey = useCallback(
    (page) => {
      const oktmo = isEmptyString(currentOktmo) ? currentOktmo : JSON.parse(currentOktmo)
      const oktmoToRequest = isObjectType(oktmo) ? oktmo.municipalityOktmo : oktmo

      //Нет oktmo - отключает фетчер
      if (!oktmoToRequest && !organizationInfo?.municipalityOktmo) return null

      return {
        page,
        size: 1,
        type: RolesTypes.OMSU,
        searchString: oktmoToRequest || organizationInfo?.municipalityOktmo,
        _key: 'OMSU_List',
      }
    },
    [currentOktmo, organizationInfo?.municipalityOktmo],
  )

  const { OMSUOrganizationsList } = useOMSUList({
    key: OMSUOrganizationsKey,
    config: {
      revalidateOnMount: true,
      isPaused: () => currentLvl !== 'MUNICIPAL',
    },
  })

  const regionCodesForOMSUFilter = useMemo(() => {
    if (!currentRegion || !currentRegion.length) return

    return currentRegion
  }, [currentRegion])

  const sidebarLoadingCondition = isLoadingOrganization

  useEffect(() => {
    //Мод редактирования. Регион и октмо содержится в самом нпа
    if (npa && !isOIV && OMSUOrganizationsList?.length) {
      npaFormInstance.setValue('oktmo', JSON.stringify(OMSUOrganizationsList[0]))

      return
    }

    if (!isMER && organizationInfo?.regionCode)
      npaFormInstance.setValue('region', String(organizationInfo?.regionCode))

    if (isOMSU && OMSUOrganizationsList?.length) {
      npaFormInstance.setValue('oktmo', JSON.stringify(OMSUOrganizationsList[0]))
    }
  }, [OMSUOrganizationsList, isMER, isOIV, isOMSU, npa, npaFormInstance, organizationInfo])

  const isExpanded = currentLvl === 'REGIONAL' || currentLvl === 'MUNICIPAL'

  const { getCollapseProps } = useCollapse({
    isExpanded,
  })

  const handeDecorateSubmit = useCallback(
    (callback, mode: keyof typeof NPAModeTypes) => async (npaId?: string) => {
      const formValues = npaFormInstance.getValues()

      if (!formValues.type) return

      const preparedOktmo =
        isString(formValues.oktmo) && !isEmptyString(formValues.oktmo)
          ? JSON.parse(formValues.oktmo)
          : formValues.oktmo

      const preparedBody = {
        documentId: formValues.file?.downloadedId || undefined,
        link: formValues.link || undefined,
        date: DayjsService.dayjs(formValues.date, objOfDateFormats.defaultFormat).format(
          objOfDateFormats.defaultNativeDateFormat,
        ),
        level: formValues.level,
        name: formValues.name,
        redactionDate: DayjsService.dayjs(
          formValues.redactionDate,
          objOfDateFormats.defaultFormat,
        ).format(objOfDateFormats.defaultNativeDateFormat),
        region: formValues.level === 'FEDERAL' ? undefined : formValues.region || undefined,
        oktmo: formValues.level === 'MUNICIPAL' ? preparedOktmo?.oktmo || undefined : undefined,
        type: formValues.type,
        number: formValues.number,
        redactionNumber: formValues.redactionNumber,
        complexName: formValues.complexName,
        parts: mode === NPAModeTypes.addNPA ? formValues.parts : undefined,
      }

      enableLoading()

      try {
        const data = await callback(preparedBody, npaId)

        await onReloadGrid?.()

        onClose?.()

        return data
      } catch (error) {
        const additionInfo = {
          npaId,
          mode,
          body: preparedBody,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'NpaEditSidebar',
            componentType: mode,
          },
        })(error)

        throw error
      } finally {
        disableLoading()
      }
    },
    [disableLoading, enableLoading, npaFormInstance, onClose, onReloadGrid],
  )

  const preparedOnCloseHandler = () => {
    onClose?.()
  }

  const handleCreateNPA = useCallback(async () => {
    const { id } = await handeDecorateSubmit(createNpa, NPAModeTypes.addNPA)()

    if (!id) return

    handleOpenNpaViewSidebar({
      npaId: id,
      onReloadGrid: onReloadGrid,
    })
  }, [createNpa, handeDecorateSubmit, handleOpenNpaViewSidebar, onReloadGrid])

  const handleEditNPA = useCallback(async () => {
    const { id } = await handeDecorateSubmit(updateNpa, NPAModeTypes.editNPA)(npa?.id)

    if (!id) return

    if (id === npa?.id) {
      await onReloadNpa?.()
    }

    handleOpenNpaViewSidebar({
      npaId: id,
      onReloadGrid: onReloadGrid,
    })
  }, [handeDecorateSubmit, handleOpenNpaViewSidebar, npa?.id, onReloadGrid, onReloadNpa, updateNpa])

  const currentActions = useMemo(() => {
    if (!isMER && !isOIV && !isOMSU) return

    const cancelAction = {
      children: 'Отмена',
      onClick: onClose,
      view: 'gray',
      disabled: isRequestLoading,
    } as const

    const loadingProps = {
      loaderProps: {
        loading: isRequestLoading,
        variant: 'lite',
        placement: 'trailing',
      },
    } as const

    return !npa
      ? ([
          cancelAction,
          {
            children: 'Добавить',
            disabled: fileIsLoading || isRequestLoading,
            onClick: npaFormInstance.handleSubmit(handleCreateNPA),
            loaderProps: loadingProps.loaderProps,
          },
        ] as ButtonProps[])
      : [
          cancelAction,
          {
            children: 'Сохранить',
            disabled: fileIsLoading || isRequestLoading,
            onClick: npaFormInstance.handleSubmit(handleEditNPA),
            loaderProps: loadingProps.loaderProps,
          },
        ]
  }, [
    fileIsLoading,
    handleCreateNPA,
    handleEditNPA,
    isMER,
    isOIV,
    isOMSU,
    isRequestLoading,
    npa,
    npaFormInstance,
    onClose,
  ])

  const municipalityDisabledCondition =
    isOMSU || currentLvl !== 'MUNICIPAL' || !currentRegion?.length

  const regionRules =
    isMER && currentLvl !== 'FEDERAL'
      ? {
          required: defaultRHFValidation.required,
          onChange: () => {
            npaFormInstance.setValue('oktmo', '')
          },
        }
      : undefined

  const handleDropFile = async (files: File[]) => {
    const file = files[0]

    const [fileName, fileExtension] = splitFileName(file.name)

    npaFormInstance.setValue('file.file', {
      documentExtension: fileExtension,
      documentSize: file.size,
      documentName: fileName,
    })

    changeFileIsLoading(true)

    try {
      const { documentId } = await createNpaFile(file)

      npaFormInstance.setValue('file.downloadedId', documentId)
    } catch {
      npaFormInstance.setValue('file', null)
    } finally {
      changeFileIsLoading(false)
    }
  }

  const handleDeleteFile = () => {
    npaFormInstance.setValue('file', null)
  }

  return (
    <Sidebar
      disableOnClose
      ref={sidebarRef}
      headerClassName={styles['NPAEdit__sidebar-header']}
      contentClassName={styles['NPAEdit__sidebar-content']}
      initialFocus={initialFocusRef}
      title={npa ? 'Редактирование НПА' : 'Создание НПА'}
      isOpen={isOpen}
      actions={currentActions}
      onClose={preparedOnCloseHandler}
    >
      <Loader loading={sidebarLoadingCondition}>
        <div className={styles.NPAEdit}>
          <Typography.Body className={styles.NPAEdit__title} variant={'bodyLMedium'}>
            Основная информация
          </Typography.Body>
          <div className={styles.NPAEdit__content}>
            <div className={styles.NPAEdit__control}>
              <ControlledInput
                name={'name'}
                control={npaFormInstance.control}
                rules={{
                  required: defaultRHFValidation.required,
                  validate: (value) => isString(value) && lengthValidate(value, 2000),
                }}
                inputProps={{
                  ref: initialFocusRef,
                  label: 'Наименование',
                  fixWidth: true,
                  view: 'secondary',
                }}
              />
            </div>
            <div className={styles.NPAEdit__control}>
              <ControlledSingleSelect
                disabled={isOMSU || isOIV}
                options={mapOfNpaLvlSelectOptions}
                popoverProps={{
                  zIndex: 51,
                }}
                controllerProps={{
                  name: 'level',
                  control: npaFormInstance.control,
                  rules: {
                    required: defaultRHFValidation.required,
                  },
                }}
                inputProps={{
                  label: 'Уровень акта',
                  fixWidth: true,
                  view: 'secondary',
                }}
              />
            </div>
            <div className={styles.NPAEdit__control}>
              <ControlledSingleSelect
                options={preparedTypesForSelect}
                popoverProps={{
                  zIndex: 51,
                }}
                controllerProps={{
                  name: 'type',
                  control: npaFormInstance.control,
                  rules: {
                    required: defaultRHFValidation.required,
                  },
                }}
                inputProps={{
                  label: 'Вид НПА',
                  fixWidth: true,
                  view: 'secondary',
                }}
              />
            </div>

            <div className={cn(styles.NPAEdit__control, styles['NPAEdit__control-row'])}>
              <ControlledInput
                name={'number'}
                control={npaFormInstance.control}
                rules={{
                  required: defaultRHFValidation.required,
                  validate: (value) => isString(value) && lengthValidate(value, 50),
                }}
                inputProps={{
                  label: 'Номер',
                  size: 'xl',
                  view: 'secondary',
                }}
              />
              <ControlledCalendarInput
                control={npaFormInstance.control}
                name={'date'}
                rules={{
                  required: defaultRHFValidation.required,
                }}
                calendarProps={{
                  disabledDate: (date) => disableFutureDates(date),
                }}
                calendarInputProps={{
                  label: 'Принят',
                }}
                tooltipProps={{
                  offset: [0, 0],
                  position: 'bottom-end',
                }}
              />
            </div>

            <div className={cn(styles.NPAEdit__control, styles['NPAEdit__control-row'])}>
              <ControlledMaskInput
                name={'redactionNumber'}
                control={npaFormInstance.control}
                inputProps={{
                  view: 'secondary',
                  size: 'xl',
                  mask: '9999999999',
                  maskPlaceholder: '',
                  label: '№ редакции',
                  fixWidth: true,
                }}
                rules={{
                  required: defaultRHFValidation.required,
                  pattern: {
                    value: Patterns.Capital,
                    message: 'должен быть больше 0',
                  },
                }}
              />
              <ControlledCalendarInput
                name={'redactionDate'}
                control={npaFormInstance.control}
                rules={{
                  required: defaultRHFValidation.required,
                  validate: {
                    positive: (value) => {
                      const dateOfCreateNpa = npaFormInstance.watch('date')

                      if (
                        npaFormInstance &&
                        !!value &&
                        dateOfCreateNpa &&
                        isString(value) &&
                        dayjs(value, objOfDateFormats.defaultFormat) <
                          dayjs(dateOfCreateNpa, objOfDateFormats.defaultFormat)
                      )
                        return 'дата редакции должна быть больше даты создания НПА'
                    },
                  },
                }}
                calendarProps={{
                  disabledDate: (date) => disableFutureDates(date),
                }}
                calendarInputProps={{
                  label: 'Дата редакции',
                }}
                tooltipProps={{
                  offset: [0, 0],
                  position: 'bottom-end',
                }}
              />
            </div>

            <div className={styles.NPAEdit__control}>
              <ControlledTextarea
                control={npaFormInstance.control}
                name="complexName"
                rules={{
                  required: defaultRHFValidation.required,
                  validate: (value) => isString(value) && lengthValidate(value, 2500),
                }}
                textareaProps={{
                  rootClassName: styles.NPAEdit__textarea,
                  label: 'Полное наименование акта',
                  minRows: 3,
                }}
              />
              <Typography.Caption
                className={styles['NPAEdit__textarea-caption']}
                color={'text-base-secondary'}
                variant={'captionSRegular'}
              >
                для отображения в приложении № 5{' '}
              </Typography.Caption>
            </div>

            <div className={styles.NPAEdit__partOfContent}>
              <Typography.Body className={styles.NPAEdit__title} variant={'bodyLMedium'}>
                Дополнительная информация
              </Typography.Body>
              <div {...getCollapseProps()}>
                <div className={styles['NPAEdit__control--collapsed']}>
                  <ControlledSingleSelect
                    withContextSearch
                    disabled={isOIV || isOMSU}
                    options={preparedRegionsWithCodesForSelect}
                    overrideDisplayValue={(value: string) =>
                      value.split('-').slice(1).join('-').trim()
                    }
                    popoverProps={{
                      zIndex: 51,
                    }}
                    controllerProps={{
                      name: 'region',
                      control: npaFormInstance.control,
                      rules: regionRules,
                    }}
                    optionsProps={{
                      enableOptionTooltips: {
                        valueLength: 40,
                        value: true,
                      },
                    }}
                    inputProps={{
                      label: 'Регион применения акта',
                      fixWidth: true,
                      view: 'secondary',
                    }}
                  />
                </div>
              </div>
              <Collapse height={currentLvl !== 'MUNICIPAL' ? 0 : 'auto'}>
                {(isMER || isOMSU) && (
                  <div className={styles['NPAEdit__control-withTopMR']}>
                    <WithCaption
                      captionClassName={styles['NPAEdit__omsuCaption']}
                      caption={!currentRegion?.length ? 'сначала укажите регион' : undefined}
                    >
                      <ControlledOMSUSelect
                        disabled={municipalityDisabledCondition}
                        regionCodes={regionCodesForOMSUFilter}
                        controllerProps={{
                          name: 'oktmo',
                          control: npaFormInstance.control,
                          rules:
                            isMER && currentLvl === 'MUNICIPAL'
                              ? {
                                  required: defaultRHFValidation.required,
                                }
                              : undefined,
                        }}
                      />
                    </WithCaption>
                  </div>
                )}
              </Collapse>
              <div
                className={cn(styles.NPAEdit__control, {
                  [styles['NPAEdit__control-withTopMR']]: currentLvl !== 'FEDERAL',
                })}
              >
                <ControlledInput
                  name={'link'}
                  control={npaFormInstance.control}
                  inputProps={{
                    label: 'Ссылка на источник',
                    fixWidth: true,
                    view: 'secondary',
                  }}
                />
              </div>
              <div className={cn(styles.NPAEdit__control, styles['NPAEdit__control-download'])}>
                {!currentFile && (
                  <WithUpload
                    noKeyboard
                    extensionTooltipTargetRef={sidebarRef}
                    onDropAccepted={handleDropFile}
                  >
                    <Button
                      className={styles.NPAEdit__file}
                      size={'s'}
                      view={'plain'}
                      leadingIcon={{ src: CircleAddIcon }}
                    >
                      Добавить файл
                    </Button>
                  </WithUpload>
                )}
                {currentFile && (
                  <FileView
                    file={currentFileInfo}
                    isLoading={fileIsLoading}
                    onReplace={handleDropFile}
                    onRemove={handleDeleteFile}
                  />
                )}
              </div>
              {!npa && (
                <div className={styles.NPAEdit__partOfContent}>
                  <Typography.Body className={styles.NPAEdit__title} variant={'bodyLMedium'}>
                    Стабилизируемые положения
                  </Typography.Body>
                  <StabilizedPositions formInstance={npaFormInstance} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Loader>
    </Sidebar>
  )
}

export { useNpaEditSidebar }
export default memo(NpaEditSidebar)
