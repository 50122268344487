import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAAnotherStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const eighthSectionValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAAnotherStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  otherActivityName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 2500),
  },
  stepNumber: {
    required: defaultRHFValidation.required,
  },
  otherActivityEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { eighthSectionValidationMap }
