import React, { Children, FC, ReactNode } from 'react'

import Chip from '@components/Chip'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import Regional from '@components/PreparedTooltips/Regional'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { ProjectStatus } from '@hooks/new/statuses/useProjectStatusOnTheProjectPage'
import { useBooleanState } from '@hooks/useBooleanState'
import ArrowDownIcon from '@icons/ArrowDownIcon.svg'
import cn from 'classnames'

import styles from './Item.module.scss'

interface ProjectItemInfo {
  isOldStatement: boolean

  projectName: string
  projectStatus: string

  isFederal: boolean

  projectDetail?: string
}

interface ProjectItemProps {
  projectInfo: ProjectItemInfo
  status: ProjectStatus
  children: ReactNode
}

const ProjectItem: FC<ProjectItemProps> = ({ projectInfo, children, status }) => {
  const { projectName, projectDetail, isFederal, isOldStatement } = projectInfo

  const {
    booleanState: isMountChildren,
    setBooleanStateToTrue: mountChildren,
    setBooleanStateToFalse: unmountChildren,
  } = useBooleanState()

  const { isExpanded, getCollapseProps, getToggleProps } = useCollapse({
    defaultExpanded: false,
    duration: 500,
    onExpandStart: () => {
      mountChildren()
    },
    onCollapseEnd: () => {
      unmountChildren()
    },
  })

  const isSuccessStatus = status.status === 'Заключено'

  const [firstChild, ...restChildren] = Children.toArray(children)

  return (
    <div role={'presentation'} className={styles.projectItem}>
      <Chip.Base
        disableClickableEffect
        label={projectInfo.projectStatus}
        className={styles.projectItem__status}
        {...status.chipProps}
        size={'xs'}
      />
      {projectDetail && (
        <Typography.Body
          color={isSuccessStatus ? 'text-accent-success' : undefined}
          className={styles.projectItem__detail}
          as={'span'}
          variant={'bodyMMedium'}
        >
          {projectDetail}
        </Typography.Body>
      )}
      <div className={styles.projectItem__block}>
        {!isFederal && <Regional offset={[40, 10]} />}
        <Typography.Headline as={'h2'} variant={'headlineH2'} className={styles.projectItem__title}>
          {`${isOldStatement ? '[подано до 01.04.2021]' : ''} ${projectName}`}
        </Typography.Headline>
      </div>
      <div className={styles.projectItem__statements}>
        {React.isValidElement(firstChild) && (
          <div
            className={cn(
              styles['projectItem__statements-item'],
              styles['projectItem__statements-item--first'],
            )}
          >
            {React.cloneElement(firstChild)}
          </div>
        )}
        <div {...getCollapseProps()}>
          {isMountChildren && (
            <div>
              {restChildren.map((child, index) => {
                if (!React.isValidElement(child)) return null

                return (
                  <div key={index} className={styles['projectItem__statements-item']}>
                    {React.cloneElement(child)}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <Button
        geometry={'square'}
        size={'s'}
        view={'plain'}
        className={styles.projectItem__collapse}
        trailingIcon={{
          src: ArrowDownIcon,
          className: cn(styles['projectItem__collapse-icon'], {
            [styles['projectItem__collapse-icon--collapsed']]: !isExpanded,
          }),
        }}
        {...getToggleProps()}
      >
        {!isExpanded ? 'Развернуть' : 'Свернуть'}
      </Button>
    </div>
  )
}

export default ProjectItem
