import env from '@config'

const municipalityCaptionTexts = {
  federalText:
    'Если является участником (у МО возникают обязательства по стабилизируемым актам или возмещению затрат), подайте дополнительное соглашение о присоединении муниципалитета',
  regionalText:
    'являются участниками, если у них возникают обязательства по стабилизируемым актам или возмещению',
}

const municipalityLabelTexts = {
  regionalText: '(если участвует в соглашении)',
}

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

export { ECONOMY_SUPPORT_EMAIL, municipalityCaptionTexts, municipalityLabelTexts }
