import { FieldValues } from 'react-hook-form'
import { usePopupManager } from 'react-popup-manager'

import AddAuthorizedPersonModal from '@components/NewDesignedModals/AddAuthorizedPersonModal'

import { AddAuthorizedPersonModalProps, HandleOpenAddAuthorizedPersonModalProps } from './types'

const useAuthorizedPersonModal = () => {
  const popupManager = usePopupManager()

  const handleOpenAddAuthorizedPersonModal = <T extends FieldValues>({
    onClose = () => null,
    ...props
  }: HandleOpenAddAuthorizedPersonModalProps<T>) => {
    popupManager.open<AddAuthorizedPersonModalProps<T>>(AddAuthorizedPersonModal, {
      ...props,
      onClose,
    })
  }

  return {
    handleOpenAddAuthorizedPersonModal,
  }
}

export default useAuthorizedPersonModal
