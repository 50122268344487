import { memo } from 'react'

import FormModifierProviderWrapper from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import {
  defaultRegistrationCertificateFormModifierValues,
  defaultRegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/const'
import RegistrationCertificateLayout from '@components/Forms/RegistrationCertificateForm/Layout'

const RegistrationCertificateLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultRegistrationCertificateFormValues}>
      <FormModifierProviderWrapper defaultValues={defaultRegistrationCertificateFormModifierValues}>
        <RegistrationCertificateLayout {...props} />
      </FormModifierProviderWrapper>
    </FormProviderWrapper>
  )
}

export default memo(RegistrationCertificateLayoutWithFormProvider)
