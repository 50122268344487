import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isObject, isString } from '@helpers/checkTypes'

const firstSectionStatementConsiderationInvestmentAuthorizedValidationMap: FormValuesValidationSection<
  keyof StatementConsiderationInvestmentAuthorizedFormValues['1'],
  StatementConsiderationInvestmentAuthorizedFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
  investorHeaderEmail: {
    required: defaultRHFValidation.required,
    pattern: defaultRHFValidation.email,
    validate: (multipleInputValue) => {
      if (!isObject(multipleInputValue)) return

      if ('value' in multipleInputValue) {
        const { value } = multipleInputValue

        if (isString(value) && !defaultRHFValidation.email.value.test(value)) {
          return defaultRHFValidation.email.message
        }

        return isString(value) ? lengthValidate(value, 500, 'string') : true
      }

      return
    },
  },
  investorHeaderPhone: {
    required: defaultRHFValidation.required,
    validate: {
      positivePhoneNumber: (value) => {
        if (!isString(value)) return

        return !value.includes('_') || 'некорректный номер'
      },
    },
  },
}

export { firstSectionStatementConsiderationInvestmentAuthorizedValidationMap }
