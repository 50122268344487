import { FocusEvent, forwardRef, useCallback, useEffect, useState } from 'react'

import Typography from '@components/NewDesign/Typography'
import { isNullOrUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

import Input from '../Input'

import styles from './AmountInput.module.scss'
import { AmountInputProps } from './types'
import {
  formatAmount,
  getAmountValueFromStr,
  getFormattedValue,
  isMinorPartFilled,
  NEGATIVE_AMOUNT_SYMBOL,
  trimSpacesAndReplaceSymbols,
} from './utils'

const AmountInput = forwardRef<HTMLInputElement, AmountInputProps>(
  (
    {
      integerLength = 14,
      minority = 100,
      currency = 'RUR',
      suffix = currency,
      placeholder = suffix === currency ? '' : `0\u2009${suffix}`,
      integersOnly,
      bold,
      offAmountSplitting,
      className,
      clear,
      hideCurrency,
      hideSuffix,
      minorPartView = 'default',
      value = minorPartView === 'withZeroMinorPart' ? 0 : null,
      hasNegativeValue,
      onChange,
      onClear,
      onBlur,
      id,
      ...restProps
    },
    ref,
  ) => {
    const mustShowCurrency = !(hideCurrency || !suffix)

    const mustRenderRubleSign = suffix === currency
    const currencyToRender = mustRenderRubleSign ? '₽' : suffix

    // Подвязывается на value, которое изменяется в onChange
    const getFormattedAmount = useCallback(() => {
      if (value === '' || value === null) return ''

      const formattedStr = getAmountValueFromStr(value.toString(), minority)

      // Проверка для того, что убирать минус у 0, если минорность тоже вся в 0
      const preparedFormattedStrToApply =
        formattedStr === 0 && 1 / formattedStr === -Infinity ? Math.abs(formattedStr) : formattedStr

      if (preparedFormattedStrToApply === null || preparedFormattedStrToApply === undefined)
        return ''

      const formattedAmount = formatAmount({
        value: preparedFormattedStrToApply,
        currency,
        minority,
        view: minorPartView,
        offAmountSplitting,
        integersOnly,
      })

      return !mustShowCurrency ? formattedAmount.formatted : formattedAmount.formattedWithCurrency
    }, [
      value,
      minority,
      currency,
      minorPartView,
      offAmountSplitting,
      integersOnly,
      mustShowCurrency,
    ])

    const [inputValue, setInputValue] = useState<string>(getFormattedAmount())

    const currencySymbol = '₽'

    useEffect(() => {
      const currentAmountValue = getAmountValueFromStr(inputValue, minority)

      const formattedValue =
        value !== null ? getAmountValueFromStr(value.toString(), minority) : value

      if (currentAmountValue !== formattedValue) {
        setInputValue(getFormattedAmount())
        return
      }

      return () => undefined
    }, [getFormattedAmount])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target

      let enteredValue = trimSpacesAndReplaceSymbols(input.value, '.', ',').replace(
        currencySymbol,
        '',
      )

      if (hasNegativeValue) {
        const foundNegativeSymbolsInValue = enteredValue.match(/-/g)?.length ?? 0

        if (foundNegativeSymbolsInValue === 1 && enteredValue[0] !== NEGATIVE_AMOUNT_SYMBOL) {
          enteredValue =
            NEGATIVE_AMOUNT_SYMBOL + enteredValue.replaceAll(NEGATIVE_AMOUNT_SYMBOL, '')
        } else if (foundNegativeSymbolsInValue > 1) {
          enteredValue = enteredValue.replaceAll(NEGATIVE_AMOUNT_SYMBOL, '')
        }
      }

      if (integersOnly) {
        ;[enteredValue] = enteredValue.split(',')
      }

      const negativeSymbol = hasNegativeValue ? NEGATIVE_AMOUNT_SYMBOL + '?' : ''
      const isCorrectEnteredValue = new RegExp(
        `(^${negativeSymbol}[0-9]{1,${integerLength}}(,([0-9]+)?)?$|^\\s*$)`,
      ).test(enteredValue)

      if (hasNegativeValue && enteredValue === NEGATIVE_AMOUNT_SYMBOL && onChange) {
        onChange(e, {
          value: null,
          valueString: '',
        })

        setInputValue(NEGATIVE_AMOUNT_SYMBOL)

        return
      }

      if (isCorrectEnteredValue) {
        const newFormattedValue = getFormattedValue(
          enteredValue,
          'RUB',
          minority,
          !!offAmountSplitting,
        )

        const oldInputValue = inputValue

        if (newFormattedValue === oldInputValue) {
          const caret =
            !!input.selectionStart && input.selectionStart > oldInputValue.length
              ? input.selectionStart - 1
              : input.selectionStart

          window.requestAnimationFrame(() => {
            input.selectionStart = caret
            input.selectionEnd = caret
          })
        } else {
          /**
           * Поддержка положения каретки
           * Поскольку при форматировании введенного значения могут появляться символы типа пробела
           * или запятая - каретка прыгает в конец и ее необходимо ставить в правильное место
           */

          // Узнаем длину оригинального инпута с условием обрезания лишних символов

          const [head, tail] = input.value.split(/\.|,/)
          let notFormattedEnteredValueLength = head.length

          if (tail) {
            notFormattedEnteredValueLength += 1 // запятая или точка
            notFormattedEnteredValueLength += tail.slice(0, minority.toString().length - 1).length // символы в минорной части
          }

          const diff = newFormattedValue.length - notFormattedEnteredValueLength
          const caret = (input.selectionStart as number) + diff

          window.requestAnimationFrame(() => {
            input.selectionStart = caret
            input.selectionEnd = caret
          })
        }

        const valueToWork = getAmountValueFromStr(newFormattedValue, minority)

        //Даём заполнять минус у нуля, если
        const preparedInputValue =
          !isNullOrUndefined(valueToWork) &&
          Math.abs(valueToWork) === 0 &&
          isMinorPartFilled(newFormattedValue, minority)
            ? newFormattedValue.replace('-', '')
            : newFormattedValue

        setInputValue(preparedInputValue)

        onChange?.(e, {
          value: getAmountValueFromStr(preparedInputValue, minority),
          valueString: preparedInputValue,
        })
      } else {
        // Не двигаем каретку когда вставляется невалидный символ
        const caret = (input.selectionStart as number) - 1

        window.requestAnimationFrame(() => {
          input.selectionStart = caret
          input.selectionEnd = caret
        })
      }
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      if (minorPartView === 'withZeroMinorPart') {
        const formattedValue = getFormattedAmount()

        setInputValue(formattedValue)
      }

      onBlur?.(event)
    }

    const handleClear = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setInputValue('')

        onClear?.(event)
      },
      [onClear],
    )

    return (
      <div
        id={id}
        className={cn(styles.container, {
          [styles.bold]: bold,
          [styles.filled]: Boolean(inputValue),
        })}
      >
        <Input
          clear={clear}
          placeholder={placeholder}
          value={inputValue}
          className={cn(styles.component, className)}
          inputMode="decimal"
          ref={ref}
          suffix={
            !hideSuffix && (
              <Typography
                color={'text-base-tertiary'}
                variant={'bodyXLMedium'}
                fontWeight={'normal'}
              >
                {currencyToRender}
              </Typography>
            )
          }
          onChange={handleChange}
          onClear={handleClear}
          onBlur={handleBlur}
          {...restProps}
        />
      </div>
    )
  },
)

export default AmountInput
