import React from 'react'
import { useFormContext } from 'react-hook-form'

import InvestorSection from '@components/DocumentFormComponents/FormSections/InvestorSection'
import { useCertificateApplicationConclusionOfAARequirementsManager } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/Manager'
import { CertificateApplicationConclusionOfAARequirementsFormValues } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/types'
import { CertificateApplicationConclusionOfAARequirementsFieldsControlUpdateWatcher } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/watcher'

const Third = () => {
  const formInstance = useFormContext<CertificateApplicationConclusionOfAARequirementsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateApplicationConclusionOfAARequirementsManager()

  if (!formInstance) return null

  return (
    <InvestorSection
      fullNameGenitiveRenderCondition
      authorizedPersonGroupRenderCondition={false}
      nameOrganizationChangeTooltipRenderCondition={false}
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'3'}
      formInstance={formInstance}
      watcher={CertificateApplicationConclusionOfAARequirementsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Third
