import { FC } from 'react'

import ProjectContainer from '@components/Projects/Containers/ProjectContainer'
import ProjectsContainer from '@components/Projects/Containers/ProjectsContainer'
import ProjectItem from '@components/Projects/Item'

interface ProjectsProps extends FC {
  ProjectContainer: typeof ProjectContainer
  Container: typeof ProjectsContainer
  Item: typeof ProjectItem
}

const Projects: ProjectsProps = () => null

Projects.Item = ProjectItem
Projects.Container = ProjectsContainer
Projects.ProjectContainer = ProjectContainer

export default Projects
