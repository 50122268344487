import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'

const {
  getDefaultRfFieldState,
  getDefaultSubjectsFieldState,
  getDefaultMunicipalitiesFieldState,
  getDefaultInvestorFieldState,
} = FieldStateHelpers

const mapOfAgreementStabilizationNegotiationsMenu = [
  {
    id: '1',
    title: 'Уполномоченный федеральный орган исполнительной власти ',
  },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация, реализующая проект' },
  { id: '5', title: 'Изменение срока стабилизационной оговорки' },
]

const defaultAgreementStabilizationNegotiationsFormValue: AgreementStabilizationNegotiationsFormValues =
  {
    '1': getDefaultRfFieldState(),
    '2': {
      subjects: getDefaultSubjectsFieldState(),
    },
    '3': {
      municipalities: getDefaultMunicipalitiesFieldState(),
    },
    '4': getDefaultInvestorFieldState(),
    '5': {
      agreementLinkStabilizationPeriod: '',
      stabilizationPeriod: '',
      stabilizationPeriodExtend: '',
      ruleLinkChangeStabilization: '',
      newStabilizationPeriod: '',
      reasonChangeStabilization: '',
    },
    additionalFields: {
      isFederal: true,
      initialMunicipalities: [],
    },
  }

const agreementStabilizationNegotiationsBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorFullName: '4.investorFullName',
    investorHeaderName: '4.investorHeaderName',
    investorHeaderNameGenitive: '4.investorHeaderNameGenitive',
    investorHeaderPosition: '4.investorHeaderPosition',
    investorHeaderPositionGenitive: '4.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '4.investorHeaderReasonGenitive',
  },
  '5': {
    ruleLinkReasonStabilization: '5.ruleLinkReasonStabilization',
    agreementLinkStabilizationPeriod: '5.agreementLinkStabilizationPeriod',
    stabilizationPeriod: '5.stabilizationPeriod',
    stabilizationPeriodExtend: '5.stabilizationPeriodExtend',
    ruleLinkChangeStabilization: '5.ruleLinkChangeStabilization',
    newStabilizationPeriod: '5.newStabilizationPeriod',
    reasonChangeStabilization: '5.reasonChangeStabilization',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
  },
} as const

export {
  agreementStabilizationNegotiationsBlockValues,
  defaultAgreementStabilizationNegotiationsFormValue,
  mapOfAgreementStabilizationNegotiationsMenu,
}
