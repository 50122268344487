import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import { useParams } from 'react-router'

import Attachments from '@components/Attachments'
import { FileProvider } from '@components/Attachments/FileProvider'
import DefaultSetHeader from '@components/Attachments/Set/DefaultSetHeader'
import Error500Fallback from '@components/DataFallback/500Error'
import Typography from '@components/NewDesign/Typography'
import { useChangesMadeContext } from '@components/Projects/[id]/DocumentLayout/ChangesMade/Context'
import styles from '@components/Projects/[id]/DocumentLayout/View/DocumentsView.module.scss'
import StandardDfoLayoutLoader from '@components/Projects/[id]/DocumentLayout/View/Layouts/StandardDfoLayoutTemplate/Loader'
import { RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { isNullOrUndefined } from '@helpers/checkTypes'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { useDfosDigitizing } from '@hooks/new/swr/useDfosDigitizing'
import { useDocumentsSetDictTypes } from '@hooks/new/swr/useDocumentsSetDictTypes'
import { DEFAULT_SYSTEM_VERSION } from '@services/Dfo/Dfo.const'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import {
  IModifiedDocumentSetInfoForRender,
  IModifiedDocumentSetInfoForRenderToArray,
} from '@services/Documents/documents.entity'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import { useDfoDocumentSets } from '../../store'

const { isDocumentReadOnlyPermission } = DocumentsHelpersService

export interface StandardDfoLayoutProps {
  dfo: IDfoListItem | null
  isLoading: boolean

  mapOfSetsToRender?: IModifiedDocumentSetInfoForRenderToArray[]
  preparedSetsOfDocuments?: Map<string, IModifiedDocumentSetInfoForRender[]>

  documentSetsClassName?: string
  actionsClassName?: string
  disableDownloadDocuments?: boolean
  disableNameOfSets?: boolean

  headNode?: ReactNode

  children?: ReactNode
}

const StandardDfoLayoutTemplate: FC<StandardDfoLayoutProps> = ({
  dfo,
  disableNameOfSets,
  documentSetsClassName,
  mapOfSetsToRender,
  headNode,
  isLoading,
  children,
}) => {
  const { projectId } = useParams()

  const { checkingRole } = useAuthContext()

  const isMER = !!checkingRole?.(RolesTypes.MER)

  //TODO: Тяжелая подписка, лучше пропсы + memo
  const { documentsError, isLoadingDocumentsSetsDict } = useDfoDocumentSets({
    dfo,
    dfoConfig: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'StandardDfoLayoutTemplate',
        },
      }),
    },
  })

  const { isVersionsMadeTab, isChangesMadeTab, mustShowOnlyChangesSetOfDocuments } =
    useChangesMadeContext()
  const { documentsSetsDict } = useDocumentsSetDictTypes({
    key: {
      _key: 'documentsSetsDictTypes',
    },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'StandardDfoLayoutTemplate',
        },
      }),
    },
  })

  const { dfosDigitizing, isLoadingDfosDigitizing } = useDfosDigitizing({
    key: {
      projectId,
      _key: 'dfosDigitizing',
    },
    config: {
      isPaused: () => !projectId || !isMER,
    },
  })

  const isDocumentForRevision = useCallback(
    (props?: IModifiedDocumentSetInfoForRenderToArray) => {
      if (!props || !dfo) return false

      const currentDocumentSetsDict =
        documentsSetsDict?.[dfo.systemVersion ?? DEFAULT_SYSTEM_VERSION]

      return !!currentDocumentSetsDict?.[dfo.type]?.[props.documentSetsType]?.documentSetForRevision
    },
    [dfo, documentsSetsDict],
  )

  const mapOfSetsForRevisionToRender = mapOfSetsToRender?.filter(isDocumentForRevision)
  const mapOfSetsExcludeSetsForRevisionToRender = mapOfSetsToRender?.filter(
    (set) => !isDocumentForRevision(set),
  )

  const currentMapOfSetsToRender = isChangesMadeTab
    ? mapOfSetsForRevisionToRender
    : mapOfSetsExcludeSetsForRevisionToRender

  const digitizingInProcess: boolean | undefined = useMemo(() => {
    if (!dfosDigitizing || !dfosDigitizing.length) return

    const currentDfosDigitizing = dfosDigitizing?.[0]

    if (!currentDfosDigitizing?.dfos || !currentDfosDigitizing?.dfos?.length) return

    const { digitizingInProcess: digitizingInProcessDfo } =
      currentDfosDigitizing.dfos.find(({ dfoId }) => dfoId === dfo?.id) || {}

    return digitizingInProcessDfo
  }, [dfo?.id, dfosDigitizing])

  const hasServerError = isIntervalServerError(documentsError?.response?.status)

  //TODO: Низко loading сделан, расчёт всего необходимого начинается гораздо раньше
  const isLoadingAllDfoData = isLoading || isLoadingDocumentsSetsDict || isLoadingDfosDigitizing

  if (isLoadingAllDfoData) return <StandardDfoLayoutLoader />

  if (!dfo?.id) return null

  return (
    <>
      {headNode}
      <Error500Fallback title={'Не удалось загрузить документы'} error={hasServerError}>
        <div
          key={String(isVersionsMadeTab)}
          className={cn(styles.documentsView__sets, documentSetsClassName)}
        >
          {currentMapOfSetsToRender?.map((allDocumentsToRender) => {
            return allDocumentsToRender.documentSets.map((documentSetProps) => {
              const isEmptyDocsAndReadOnly =
                isDocumentReadOnlyPermission(documentSetProps.permissions) &&
                !documentSetProps.documentTypeInfo.some((info) => {
                  const hasDocuments = !!info?.documents?.length
                  const hasDocumentsInFolders = info?.folders?.some(
                    (folder) => !!folder?.documents?.length,
                  )
                  const hasForms = !!info?.forms?.length

                  return hasDocuments || hasDocumentsInFolders || hasForms
                })

              if (documentSetProps.documentSetIsSystem || isEmptyDocsAndReadOnly)
                return (
                  <FileProvider
                    initialDocuments={documentSetProps}
                    key={documentSetProps.documentSetId}
                  />
                )

              if (
                !isNullOrUndefined(mustShowOnlyChangesSetOfDocuments) &&
                mustShowOnlyChangesSetOfDocuments &&
                !isNullOrUndefined(documentSetProps.hasChangedDocuments) &&
                !documentSetProps.hasChangedDocuments
              )
                return (
                  <FileProvider
                    initialDocuments={documentSetProps}
                    key={documentSetProps.documentSetId}
                  >
                    <section className={styles.documentsView__setsEmpty}>
                      <DefaultSetHeader title={documentSetProps.documentSetTitle} />
                      <Typography
                        variant={'bodyLMedium'}
                        className={styles.documentsView__setsEmptyTitle}
                      >
                        Мы не нашли изменений по отношению к прошлой версии
                      </Typography>
                    </section>
                  </FileProvider>
                )

              return (
                <FileProvider
                  initialDocuments={documentSetProps}
                  key={documentSetProps.documentSetId}
                >
                  <Attachments.Set
                    currentDfoId={dfo.id}
                    initialDocuments={documentSetProps}
                    typeOfSets={allDocumentsToRender.documentSetsType}
                    disableSetOfName={disableNameOfSets}
                    showChangedSetDocuments={mustShowOnlyChangesSetOfDocuments}
                    isChangesMadeTab={isChangesMadeTab}
                    digitizingInProcess={digitizingInProcess}
                  />
                </FileProvider>
              )
            })
          })}
        </div>
      </Error500Fallback>
      {children}
    </>
  )
}

export default StandardDfoLayoutTemplate
