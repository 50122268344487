import React, { FC, memo } from 'react'
import ReactMarkdown from 'react-markdown'

import Markdown from '@components/Markdown'
import remarkGfm from 'remark-gfm'

type MarkdownProps = React.ComponentProps<typeof ReactMarkdown>

const MarkdownView: FC<MarkdownProps> = ({ children, ...rest }) => {
  return (
    <ReactMarkdown
      {...rest}
      children={children}
      remarkPlugins={[remarkGfm]}
      components={{
        h1: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="h1"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        h2: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="h2"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        h3: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="h3"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        h4: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="h4"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        h5: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="h5"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        h6: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="h6"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        a: ({ node, ...props }) => (
          <Markdown.NodeFactory tagName="a" children={props.children} anotherElementProps={props} />
        ),
        blockquote: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="blockquote"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        br: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="br"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        code: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="code"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        em: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="em"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        hr: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="hr"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        img: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="img"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        li: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="li"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        ol: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="ol"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        p: ({ node, ...props }) => (
          <Markdown.NodeFactory tagName="p" children={props.children} anotherElementProps={props} />
        ),
        pre: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="pre"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        strong: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="strong"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        ul: ({ ...props }) => (
          <Markdown.NodeFactory
            tagName="ul"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        del: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="del"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        input: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="input"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        table: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="table"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        tbody: ({ ...props }) => (
          <Markdown.NodeFactory
            tagName="tbody"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        thead: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="thead"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        td: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="td"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        th: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="th"
            children={props.children}
            anotherElementProps={props}
          />
        ),
        tr: ({ node, ...props }) => (
          <Markdown.NodeFactory
            tagName="tr"
            children={props.children}
            anotherElementProps={props}
          />
        ),
      }}
    />
  )
}

export default memo(MarkdownView)
