import React, { memo } from 'react'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { blockValues } from '@components/Forms/CreateStatementOld/const'
import formStyles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import { generalInfoValidation } from '@components/Forms/CreateStatementOld/Forms/GeneralInfo/validation'
import { investorGeneralInfoFieldsValidation } from '@components/Forms/CreateStatementOld/Forms/InvestorGeneralInfo/validation'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import ControlledMultipleInput from '@components/NewDesign/MultipleInput/ControlledMultipleInput'
import Typography from '@components/NewDesign/Typography'
import { Patterns } from '@constants/validations'
import cn from 'classnames'

import styles from './InvestorAddress.module.scss'

const { validationAdapter } = DocumentFormHelpers

const InvestorAddressForm = () => {
  const {
    state: { formInstance, blockViewIsValidating },
  } = useCreateStatementManager()

  if (!formInstance) return null

  return (
    <div className={styles.form__container}>
      <div className={styles.investorAddress__columns}>
        <div className={cn(styles['investorAddress__columns-left'], formStyles.form__container)}>
          <Typography.Body color={'text-base-tertiary'} variant={'bodyMMedium'}>
            Данные в именительном падеже
          </Typography.Body>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorAddress.head}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Фамилия, Имя, Отчество',
                caption: 'например, Иванов Иван Иванович',
                maxLength: 500,
                dataTestId: `InvestorAddressForm-${blockValues.investorAddress.head}-input`,
              }}
              rules={validationAdapter(investorGeneralInfoFieldsValidation.head, {
                form: formInstance,
                name: blockValues.investorAddress.head,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorAddress.position}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Должность',
                caption: 'например, Генеральный директор',
                maxLength: 500,
                dataTestId: `InvestorAddressForm-${blockValues.investorAddress.position}-input`,
              }}
              rules={validationAdapter(investorGeneralInfoFieldsValidation.position, {
                form: formInstance,
                name: blockValues.investorAddress.position,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorAddress.reason}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Основание действий',
                caption: 'например, Устав',
                maxLength: 100,
                dataTestId: `InvestorAddressForm-${blockValues.investorAddress.reason}-input`,
              }}
              rules={validationAdapter(investorGeneralInfoFieldsValidation.reason, {
                form: formInstance,
                name: blockValues.investorAddress.reason,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
        </div>
        <div className={cn(styles['investorAddress__columns-right'], formStyles.form__container)}>
          <Typography.Body color={'text-base-tertiary'} variant={'bodyMMedium'}>
            Данные в родительном падеже
          </Typography.Body>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorAddress.headGenitive}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Фамилия, Имя, Отчество',
                caption: 'например, Иванова Ивана Ивановича',
                maxLength: 500,
                dataTestId: `InvestorAddressForm-${blockValues.investorAddress.headGenitive}-input`,
              }}
              rules={validationAdapter(investorGeneralInfoFieldsValidation.headGenitive, {
                form: formInstance,
                name: blockValues.investorAddress.headGenitive,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorAddress.positionGenitive}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Занимает должность',
                caption: 'например, Генерального директора',
                maxLength: 500,
                dataTestId: `InvestorAddressForm-${blockValues.investorAddress.positionGenitive}-input`,
              }}
              rules={validationAdapter(investorGeneralInfoFieldsValidation.positionGenitive, {
                form: formInstance,
                name: blockValues.investorAddress.positionGenitive,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorAddress.reasonGenitive}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Действует на основании',
                caption: 'например, Устава',
                maxLength: 100,
                dataTestId: `InvestorAddressForm-${blockValues.investorAddress.reasonGenitive}-input`,
              }}
              rules={validationAdapter(investorGeneralInfoFieldsValidation.reasonGenitive, {
                form: formInstance,
                name: blockValues.investorAddress.reasonGenitive,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
        </div>
      </div>
      <div className={cn(styles.investorAddress__other, formStyles.form__container)}>
        <div className={formStyles.form__item}>
          <ControlledMultipleInput
            control={formInstance.control}
            name={blockValues.investorAddress.headEmail}
            inputProps={{
              view: 'secondary',
              fixWidth: true,
              label: 'Электронная почта для связи',
              caption:
                'добавьте одну или несколько, чтобы другие участники соглашения могли связаться с вашей организацией при необходимости',
              dataTestId: `InvestorAddressForm-${blockValues.investorAddress.headEmail}-multipleInput`,
            }}
            multipleProps={{
              keyboardButton: 'Space',
              parseByString: ',',
              onCustomValidate: (value) => Patterns.Email.test(value),
            }}
            rules={validationAdapter(generalInfoValidation.headEmail, {
              form: formInstance,
              name: blockValues.investorAddress.headEmail,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
        <div className={formStyles.form__row}>
          <div className={formStyles.form__item}>
            <ControlledMaskInput
              control={formInstance.control}
              name={blockValues.investorAddress.headPhone}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                mask: '+7 (999) 999-99-99',
                label: 'Телефон для связи',
                dataTestId: `InvestorAddressForm-${blockValues.investorAddress.headPhone}-maskInput`,
              }}
              rules={validationAdapter(generalInfoValidation.headPhone, {
                form: formInstance,
                name: blockValues.investorAddress.headPhone,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledAmountInput
              name={blockValues.investorAddress.headAdditionalPhone}
              control={formInstance.control}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                suffix: '',
                placeholder: '',
                integersOnly: true,
                label: 'Добавочный',
                caption: 'если есть',
                offAmountSplitting: true,
                integerLength: 30,
                hideCurrency: true,
                dataTestId: `InvestorAddressForm-${blockValues.investorAddress.headAdditionalPhone}-amountInput`,
              }}
              rules={validationAdapter(generalInfoValidation.headAdditionalPhone, {
                form: formInstance,
                name: blockValues.investorAddress.headAdditionalPhone,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(InvestorAddressForm)
