import { useCallback, useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { SWRIniniteHookProps } from '@interfaces'
import { TGetAllProjects } from '@services/Projects/Project.entity'
import useSWRInfinite from 'swr/infinite'

const useProjects = ({
  key,
  config,
  pageSize,
}: SWRIniniteHookProps<TGetAllProjects> & { pageSize?: number }) => {
  const {
    projectsApi: { getAllProjects },
  } = useAPIContext()

  const {
    data: projects,
    error,
    mutate,
    isValidating: isLoadingProjects,
    size,
    setSize,
  } = useSWRInfinite(key, getAllProjects, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    ...config,
  })

  const hasMoreData = useMemo(() => {
    return projects && projects?.[projects?.length - 1]?.length === (pageSize || 11)
  }, [pageSize, projects])

  const goToNextPage = useCallback(() => {
    hasMoreData && setSize((prev) => prev + 1)
  }, [hasMoreData, setSize])

  //Каждый проект знает про страницу, на которой он находится
  const preparedProjects = useMemo(() => {
    return projects
      ?.map((project, indexOfPage) => {
        return project.map((projectItem) => ({
          ...projectItem,
          projectPage: indexOfPage,
        }))
      })
      .flat()
  }, [projects])

  return {
    projects: preparedProjects,
    initialHookProjects: projects,

    error,
    isLoadingProjects,

    hasMoreData,

    mutate,
    incPage: setSize,
    currentPage: size,
    goToNextPage,
  }
}

export { useProjects }
