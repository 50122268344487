import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionValidationMap: FormValuesValidationSection<
  keyof NoticeOfProjectRejectConcludeSZPKFormValues['3'],
  NoticeOfProjectRejectConcludeSZPKFormValues
> = {
  ruleLinkStatementRepeatDraft: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  statementRejectReasonDraft: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 5000),
  },
  ruleLinkStatementRejectDraft: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { thirdSectionValidationMap }
