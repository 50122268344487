import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfChangeDocumentsBlockValues } from '@components/Forms/NotificationOfChangeDocumentsForm/const'
import CharacteristicOfDocumentItem from '@components/Forms/NotificationOfChangeDocumentsForm/Forms/2/AdditionalForms/CharacteristicOfDocumentItem'
import { useNotificationOfChangeDocumentsManager } from '@components/Forms/NotificationOfChangeDocumentsForm/Manager'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'
import {
  NotificationOfChangeDocumentsFieldArrayControlUpdateWatcher,
  NotificationOfChangeDocumentsFieldsControlUpdateWatcher,
} from '@components/Forms/NotificationOfChangeDocumentsForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CharacteristicsOfDocumentProps {
  formName: '2.characteristicOfDocuments'
  formInstance: UseFormReturn<NotificationOfChangeDocumentsFormValues>
}

const { isFormFieldError } = DocumentFormHelpers

const CharacteristicOfDocuments: FC<CharacteristicsOfDocumentProps> = ({
  formName,
  formInstance,
}) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useNotificationOfChangeDocumentsManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NotificationOfChangeDocumentsFieldsControlUpdateWatcher,
  })

  const { fields: characteristicsOfDocument } = useFieldArraySubscribableControl<
    NotificationOfChangeDocumentsFormValues,
    '2.characteristicOfDocuments',
    'keyNameId'
  >({
    control: formInstance.control,
    name: formName,
    watcher: NotificationOfChangeDocumentsFieldArrayControlUpdateWatcher,
  })

  const characteristicsOfDocumentError = formInstance.getFieldState(
    notificationOfChangeDocumentsBlockValues['2'].characteristicOfDocuments,
  )?.error

  const handleAddCharacteristicOfDocument = async () => {
    await handleAddItemToListWithOutValue?.(
      notificationOfChangeDocumentsBlockValues['2'].characteristicOfDocuments,
    )

    if (!characteristicsOfDocument.length) {
      formInstance.clearErrors(
        notificationOfChangeDocumentsBlockValues['2'].characteristicOfDocuments,
      )
    }
  }

  const handleRemoveCharacteristicOfDocument = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${notificationOfChangeDocumentsBlockValues['2'].characteristicOfDocuments}.${index}`,
      notificationOfChangeDocumentsBlockValues['2'].characteristicOfDocuments,
    )
  }

  return (
    <Group disableBottomBorder title={'Измененные документы'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            {!editMode && !characteristicsOfDocument.length && <EmptyDataText />}
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfChangeDocumentsBlockValues['2'].characteristicOfDocuments,
                })}
              >
                <FlipperList list={characteristicsOfDocument}>
                  {characteristicsOfDocument.map((stage, index) => (
                    <CharacteristicOfDocumentItem
                      key={stage.id}
                      id={stage.id}
                      anchorId={stage.anchorId}
                      name={
                        `${notificationOfChangeDocumentsBlockValues['2'].characteristicOfDocuments}.${index}` as const
                      }
                      onRemoveCharacteristicOfDocument={handleRemoveCharacteristicOfDocument(index)}
                    />
                  ))}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </Col>
          {isFormFieldError(characteristicsOfDocumentError) && !characteristicsOfDocument.length && (
            <Row className={'px-1'}>
              <FormError rowClassName={'px-4'} colClassName={'px-3'}>
                {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
              </FormError>
            </Row>
          )}
        </Row>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddCharacteristicOfDocument}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default CharacteristicOfDocuments
