import { useCallback } from 'react'

import { AuthorizedOrganizations } from '@components/AuthorizedOrganizations/Manager/types'
import {
  IAuthorizedOrganizationItem,
  useAuthorizedOrganizationsApi,
} from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { useBooleanState } from '@hooks/useBooleanState'
import { useOpenState } from '@hooks/useOpenState'

import { ItemOIVFormValues } from './model'

interface UseRequests {
  values: ItemOIVFormValues
  onClose: VoidFunction
  organization?: IAuthorizedOrganizationItem
}

export const useRequests = ({ organization, values, onClose }: UseRequests) => {
  const {
    createAuthorizedOrganization,
    updateAuthorizedOrganization,
    updateINNAuthorizedOrganization,
  } = useAuthorizedOrganizationsApi()

  const { isEntityOpen, closeEntity, openEntity } = useOpenState()

  const {
    booleanState: isRequestLoading,
    setBooleanStateToFalse: endLoading,
    setBooleanStateToTrue: startLoading,
  } = useBooleanState()

  const { organizationName, inn, link } = values

  const handleUpdateOIVWithNewInn = async () => {
    if (!organization) return

    try {
      await updateINNAuthorizedOrganization({
        inn: organization.inn,
        link,
        newInn: inn,
        organizationName,
      })
      onClose()
    } finally {
      closeEntity()
    }
  }

  const handleUpdateOIV = useCallback(async () => {
    if (!organization) return

    const wasInnChanged = organization.inn !== inn

    if (wasInnChanged) return openEntity()

    startLoading()

    try {
      await updateAuthorizedOrganization({
        inn: organization.inn,
        link: link,
        organizationName: organizationName,
      })

      onClose()
    } finally {
      endLoading()
    }
  }, [
    organization,
    inn,
    startLoading,
    openEntity,
    updateAuthorizedOrganization,
    link,
    organizationName,
    onClose,
    endLoading,
  ])

  const handleCreateOIV = useCallback(async () => {
    startLoading()

    try {
      await createAuthorizedOrganization({
        ...values,
        type: AuthorizedOrganizations.OIV,
      })

      onClose()
    } finally {
      endLoading()
    }
  }, [createAuthorizedOrganization, endLoading, onClose, startLoading, values])

  const closeModalHandler = () => {
    closeEntity()
    endLoading()
  }

  return {
    isModalOpen: isEntityOpen,
    closeModal: closeModalHandler,
    openModal: openEntity,
    handleCreateOIV,
    handleUpdateOIV,
    handleUpdateOIVWithNewInn,
    isRequestLoading,
  }
}
