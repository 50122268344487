import React from 'react'
import { useFormContext } from 'react-hook-form'

import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import { eleventhSectionRegistrationCertificateValidationMap } from '@components/Forms/RegistrationCertificateForm/Forms/11/validation'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Eleventh = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const formModifierInstance = useFormModifierContext<RegistrationCertificateFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue, handleChangePublicValue },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { getFormFieldControlProps, getSingleSelectProps, getTextareaProps } =
    useFormComponentPresets({
      isNew: true,
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: RegistrationCertificateFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['11'].agreementTerminationReason,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.name || '',
                        value: item.id,
                      }),
                      selectProps: {
                        inputProps: {
                          label: 'Основания прекращения действия соглашения',
                        },
                        onChangeFormValue: () =>
                          handleChangeValue?.(
                            registrationCertificateBlockValues['11'].agreementTerminationReason,
                          ),
                      },
                      controllerProps: {
                        name: registrationCertificateBlockValues['11'].agreementTerminationReason,
                      },
                    })}
                  />
                )
              }}
            </FormFieldControl>
          </Col>
        </Row>
        <ContainerWithBorder>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['11']
                  .agreementTerminationReasonDescription,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['11']
                        .agreementTerminationReasonDescription,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['11']
                    .agreementTerminationReasonDescription,
                  rules:
                    eleventhSectionRegistrationCertificateValidationMap.agreementTerminationReasonDescription,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label: 'Описание основания прекращения действия соглашения',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        registrationCertificateBlockValues['11']
                          .agreementTerminationReasonDescription,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['11']
                          .agreementTerminationReasonDescription,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </ContainerWithBorder>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['11']
                  .agreementTerminationReasonInvalidPart,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['11']
                        .agreementTerminationReasonInvalidPart,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['11']
                    .agreementTerminationReasonInvalidPart,
                  rules:
                    eleventhSectionRegistrationCertificateValidationMap.agreementTerminationReasonInvalidPart,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label:
                      'Сведения о признании соглашения или его отдельных условий недействительными',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        registrationCertificateBlockValues['11']
                          .agreementTerminationReasonInvalidPart,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['11']
                          .agreementTerminationReasonInvalidPart,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Eleventh
