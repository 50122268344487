import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionValidationMap: FormValuesValidationSection<
  keyof NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues['3'],
  NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues
> = {
  investorFullNameInstrumental: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1050),
  },
}

export { thirdSectionValidationMap }
