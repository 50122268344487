import React, { memo } from 'react'

import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import Markdown from '@components/Markdown'
import Typography from '@components/NewDesign/Typography'

import styles from './Preview.module.scss'

const KnowledgeBaseDesktopPreview = () => {
  const {
    state: { currentBaseItem },
    handlers: { handleChooseBaseItem },
  } = useKnowledgeBase()

  //Рендер списка статей внутри главной
  const isOnlyListRender =
    currentBaseItem && !currentBaseItem.markdown && currentBaseItem.children.length

  if (!currentBaseItem) return null

  return (
    <div className={styles.preview}>
      {isOnlyListRender ? (
        <>
          <Typography.Headline variant="headlineH2" as={'h2'} className={styles.preview__title}>
            {currentBaseItem.name}
          </Typography.Headline>
          <ol className={styles.preview__list}>
            {currentBaseItem.children.map((baseItem) => {
              return (
                <li
                  key={baseItem.id}
                  className={styles['preview__list-item']}
                  role="presentation"
                  onClick={() => handleChooseBaseItem?.(baseItem)}
                >
                  {baseItem.name}
                </li>
              )
            })}
          </ol>
        </>
      ) : (
        <Markdown.FileWrapper src={currentBaseItem.markdown}>
          {(readyText) => {
            return <Markdown.View>{readyText}</Markdown.View>
          }}
        </Markdown.FileWrapper>
      )}
    </div>
  )
}

export default memo(KnowledgeBaseDesktopPreview)
