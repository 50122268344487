import React, { FC, KeyboardEvent, MouseEvent, SyntheticEvent, useCallback, useMemo } from 'react'

import Icon from '@components/Icon/Icon'
import Input from '@components/NewDesign/Input'
import { PopoverProps } from '@components/NewDesign/Popover'
import {
  isOptionsGroup,
  SingleSelectProps,
  SingleSelectState,
} from '@components/NewDesign/Select/model'
import { Options } from '@components/NewDesign/Select/Options'
import { useSelectState } from '@components/NewDesign/Select/useSelectState'
import Textarea from '@components/NewDesign/Textarea'
import { isArray, isString } from '@helpers/checkTypes'
import arrowBackIcon from '@icons/hardware/keyboard_arrow_down.svg'
import arrowUpIcon from '@icons/hardware/keyboard_arrow_up.svg'
import cn from 'classnames'

import styles from '../SingleSelect.module.scss'

export const SingleSelect: FC<SingleSelectProps> = (props) => {
  const {
    type = 'input',
    error,
    onBlurForm,
    onChangeForm,
    onChangeFormValue,
    popoverProps,
    inputProps,
    options: receivedOptions,
    withSearch,
    withContextSearch,
    withInput,
    optionsContainer,
    defaultSelected = '',
    disabled,
    onChangeSearchValue,
    optionsProps,
    defaultOptionsSelected,
    overrideDisplayValue,
  } = props

  const inputActive = !!(withInput || withSearch || withContextSearch)

  const inputCursor = withSearch || withContextSearch ? undefined : 'pointer'

  const {
    selected,
    setSelected,
    allOptionsInArray,
    inputContainerRef,
    popoverRef,
    isPopoverOpen,
    searchValue,
    setSearchValue,
    clue,
    availableOptions,
    closePopover,
    openPopover,
    inputRef,
    valueIsLongerThenInput,
  } = useSelectState<SingleSelectState>({
    defaultSelected,
    receivedOptions,
    defaultOptionsSelected,
    withSearch,
    withContextSearch,
    disableOptionsMutate: !!onChangeSearchValue,
    onBlur: onBlurForm,
  })

  const closePopoverHandler = useCallback(() => {
    onBlurForm?.()
    closePopover()
  }, [closePopover, onBlurForm])

  const inputArrow = (
    <Icon src={isPopoverOpen ? arrowUpIcon : arrowBackIcon} className={styles.Arrow} />
  )

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const isTargetButtonPressed = ['Enter'].includes(e.key)

    if (isTargetButtonPressed && clue) {
      const [firstOption] = availableOptions
      const nextValue = isOptionsGroup(firstOption)
        ? firstOption.options[0].displayValue
        : firstOption.displayValue

      e.preventDefault()

      setSelected(nextValue)
      onChangeForm?.(nextValue)

      closePopoverHandler()
    }
  }

  const onChangeInput = ({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    /**
     * Если значение уже выбранно, а пользователь начал искать что-то, обнуляем выбранное значение
     */
    if (value) openPopover()

    // type guard isEmptyString в этом случае отрабатывает неправильно
    const isNotEmptyStringSelected = isString(selected) && !!selected.length
    const isNotEmptyArraySelected = isArray(selected) && !!selected.length

    if (isNotEmptyStringSelected || isNotEmptyArraySelected) {
      setSelected('')
      onChangeForm?.('')
      onChangeFormValue?.('')
      setSearchValue('')
      onChangeSearchValue?.('')

      return
    }

    setSearchValue(value)

    onChangeSearchValue?.(value)
  }

  const onClearSearch = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      inputProps?.onClear?.(e)

      if (selected) {
        setSelected('')
        onChangeForm?.('')
        onChangeFormValue?.('')
      }

      setSearchValue('')

      onChangeSearchValue?.('')
    },
    [
      onChangeSearchValue,
      setSearchValue,
      inputProps,
      onChangeForm,
      onChangeFormValue,
      selected,
      setSelected,
    ],
  )

  const onClickOption = useCallback(
    (value) => {
      const nextValue = selected === value ? '' : value

      setSearchValue('')

      onChangeSearchValue?.('')

      setSelected(nextValue)
      onChangeForm?.(nextValue)
      onChangeFormValue?.(nextValue)

      if (nextValue || selected !== value) {
        inputRef.current?.blur()
        closePopoverHandler()
      }
    },
    [
      closePopoverHandler,
      inputRef,
      onChangeForm,
      onChangeFormValue,
      onChangeSearchValue,
      selected,
      setSearchValue,
      setSelected,
    ],
  )

  const staticPopoverProps = {
    open: isPopoverOpen,
    position: 'bottom-start' as PopoverProps['position'],
    anchorElement: inputContainerRef.current,
    ref: popoverRef,
    ...popoverProps,
  }

  const selectedDisplayValue = useMemo(() => {
    const optionToView = allOptionsInArray.find((option) =>
      isArray(selected) ? selected.includes(option.value) : option.value === selected,
    )?.displayValue

    if (!optionToView) return

    if (overrideDisplayValue) return overrideDisplayValue(optionToView)

    return optionToView
  }, [allOptionsInArray, overrideDisplayValue, selected])

  const isInput = type === 'input'

  return (
    <>
      {isInput ? (
        <Input
          disabled={disabled}
          inputActive={inputActive}
          rootClassName={cn(styles.Input, { [styles.Input__disabled]: disabled })}
          inputCursor={inputCursor}
          // searchValue используется при включенном поиске
          value={selectedDisplayValue || searchValue || ''}
          rightAddons={!disabled && inputArrow}
          view={'secondary'}
          inputClue={selected || valueIsLongerThenInput ? '' : clue}
          error={error}
          inputContainerRef={inputContainerRef}
          ref={inputRef}
          onChange={onChangeInput}
          onClear={onClearSearch}
          onKeyDown={onKeyDown}
          {...inputProps}
          enableConditionToShowTooltip={false}
        />
      ) : (
        <Textarea
          textAreaInactive
          autoComplete="off"
          fixWidth={inputProps?.fixWidth}
          label={inputProps?.label}
          inputContainerRef={inputContainerRef}
          rootClassName={cn(styles.Input, { [styles.Input__disabled]: disabled })}
          value={selectedDisplayValue || searchValue || ''}
          rightAddons={!disabled && inputArrow}
          error={error}
          dataTestId={inputProps?.dataTestId}
        />
      )}
      <Options
        options={availableOptions}
        selected={selected}
        popoverProps={staticPopoverProps}
        optionsContainer={optionsContainer}
        onClick={onClickOption}
        {...optionsProps}
      />
    </>
  )
}
