import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'

const {
  getDefaultRfFieldState,
  getDefaultSubjectsFieldState,
  getDefaultMunicipalitiesFieldState,
  getDefaultInvestorFieldState,
} = FieldStateHelpers

const mapOfAAgreementForceCircumstancesMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация, реализующая проект' },
  { id: '5', title: 'Этапы' },
  { id: '6', title: 'Изменение сроков проекта' },
  { id: '7', title: 'Обстоятельства непреодолимой силы' },
]

const defaultAAgreementForceCircumstancesFormValue: AAgreementForceCircumstancesFormValues = {
  '1': getDefaultRfFieldState(),
  '2': {
    subjects: getDefaultSubjectsFieldState(),
  },
  '3': {
    municipalities: getDefaultMunicipalitiesFieldState(),
  },
  '4': getDefaultInvestorFieldState(),
  '5': {
    stages: [],
  },
  '6': {
    projectEndDateCommited: '',
    projectEndDate: '',
    agreementLinkProjectPeriod: '',
  },
  '7': {
    forceMajeureName: '',
    forceMajeureProof: '',
  },
  additionalFields: {
    isFederal: true,
    initialMunicipalities: [],
  },
}

const aAgreementForceCircumstancesBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorFullName: '4.investorFullName',
    investorHeaderName: '4.investorHeaderName',
    investorHeaderNameGenitive: '4.investorHeaderNameGenitive',
    investorHeaderPosition: '4.investorHeaderPosition',
    investorHeaderPositionGenitive: '4.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '4.investorHeaderReasonGenitive',
  },
  '5': {
    stages: '5.stages',
  },
  '6': {
    projectEndDateCommited: '6.projectEndDateCommited',
    projectEndDate: '6.projectEndDate',
    agreementLinkProjectPeriod: '6.agreementLinkProjectPeriod',
  },
  '7': {
    forceMajeureName: '7.forceMajeureName',
    forceMajeureProof: '7.forceMajeureProof',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
  },
} as const

export {
  aAgreementForceCircumstancesBlockValues,
  defaultAAgreementForceCircumstancesFormValue,
  mapOfAAgreementForceCircumstancesMenu,
}
