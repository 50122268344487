import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementAlreadyInvestmentsBlockValues } from '@components/Forms/StatementAlreadyInvestments/const'
import { oneSectionStatementAlreadyInvestmentsValidationMap } from '@components/Forms/StatementAlreadyInvestments/Forms/1/validation'
import { useStatementAlreadyInvestmentsManager } from '@components/Forms/StatementAlreadyInvestments/Manager'
import { StatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { StatementAlreadyFieldsControlUpdateWatcher } from '../../watcher'

const One = () => {
  const formInstance = useFormContext<StatementAlreadyInvestmentsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useStatementAlreadyInvestmentsManager()

  const { getSubscribableControlProps, getControllerProps, getInputProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: StatementAlreadyFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementAlreadyInvestmentsBlockValues['1'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Наименование"
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
                controllerProps={getControllerProps({
                  name: statementAlreadyInvestmentsBlockValues['1'].investorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementAlreadyInvestmentsBlockValues['1'].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ИНН"
                controllerProps={getControllerProps({
                  name: statementAlreadyInvestmentsBlockValues['1'].investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementAlreadyInvestmentsBlockValues['1'].investorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ОГРН"
                controllerProps={getControllerProps({
                  name: statementAlreadyInvestmentsBlockValues['1'].investorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementAlreadyInvestmentsBlockValues['1'].investorAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Адрес'}
                formIconTooltipProps={{
                  tooltipContent: 'Адрес можно изменить в разделе "Моя организация"',
                }}
                controllerProps={getControllerProps({
                  name: statementAlreadyInvestmentsBlockValues['1'].investorAddress,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Уполномоченное лицо организации'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementAlreadyInvestmentsBlockValues['1'].investorHeaderName,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.headerName || '',
                            value: item.id,
                          }),

                          controllerProps: {
                            name: statementAlreadyInvestmentsBlockValues['1'].investorHeaderName,
                            rules:
                              oneSectionStatementAlreadyInvestmentsValidationMap.investorHeaderName,
                          },
                          selectProps: {
                            inputProps: {
                              label: 'Фамилия, Имя, Отчество',
                              dataTestId: 'investorHeaderName',
                            },
                            onChangeFormValue: () =>
                              setTimeout(() => {
                                handleChangeValue?.(
                                  statementAlreadyInvestmentsBlockValues['1'].investorHeaderName,
                                )
                              }, 0),
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementAlreadyInvestmentsBlockValues['1'].investorHeaderPosition,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: statementAlreadyInvestmentsBlockValues['1'].investorHeaderPosition,
                      rules:
                        oneSectionStatementAlreadyInvestmentsValidationMap.investorHeaderPosition,
                      inputProps: {
                        disabled: true,
                        label: 'Должность',
                        dataTestId: 'investorHeaderPosition',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Border />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementAlreadyInvestmentsBlockValues['1'].investorHeaderNameGenitive,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: statementAlreadyInvestmentsBlockValues['1'].investorHeaderNameGenitive,
                      rules:
                        oneSectionStatementAlreadyInvestmentsValidationMap.investorHeaderNameGenitive,
                      inputProps: {
                        disabled: true,
                        caption: 'в родительном падеже',
                        label: 'Фамилия, Имя, Отчество',
                        dataTestId: 'investorHeaderNameGenitive',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementAlreadyInvestmentsBlockValues['1']
                      .investorHeaderPositionGenitive,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: statementAlreadyInvestmentsBlockValues['1']
                        .investorHeaderPositionGenitive,
                      rules:
                        oneSectionStatementAlreadyInvestmentsValidationMap.investorHeaderPositionGenitive,
                      inputProps: {
                        disabled: true,
                        caption: 'в родительном падеже',
                        label: 'Должность',
                        dataTestId: 'investorHeaderPositionGenitive',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementAlreadyInvestmentsBlockValues['1'].investorHeaderReasonGenitive,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: statementAlreadyInvestmentsBlockValues['1']
                        .investorHeaderReasonGenitive,
                      rules:
                        oneSectionStatementAlreadyInvestmentsValidationMap.investorHeaderReasonGenitive,
                      inputProps: {
                        disabled: true,
                        caption: 'в родительном падеже',
                        label: 'Основание действий',
                        dataTestId: 'investorHeaderReasonGenitive',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default One
