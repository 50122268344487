import { FC } from 'react'

import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import NpaMunicipalLayoutWithFormProvider from '@components/Forms/NpaMunicipalForm/Layout/LayoutWithFormProvider'

import NpaMunicipalActions from './Actions'
import NpaMunicipalManager from './Manager'
import NpaMunicipalStatement from './Statement'

interface NpaMunicipalFormFC extends FC<NpaFormLayoutProps> {
  Manager: typeof NpaMunicipalManager
  Actions: typeof NpaMunicipalActions
  Statement: typeof NpaMunicipalStatement
}

const NpaMunicipalForm: NpaMunicipalFormFC = (props) => (
  <NpaMunicipalLayoutWithFormProvider {...props} />
)

NpaMunicipalForm.Manager = NpaMunicipalManager
NpaMunicipalForm.Actions = NpaMunicipalActions
NpaMunicipalForm.Statement = NpaMunicipalStatement

export default NpaMunicipalForm
