import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

interface UiControlContextProps {
  state: {
    eventSidebarControlIsDisabled: boolean
  }
  handlers: {
    handleChangeEventControl?: (value: boolean) => void
  }
}

const UiControlContext = createContext<UiControlContextProps>({
  state: {
    eventSidebarControlIsDisabled: false,
  },
  handlers: {},
})

const UiControlManager = ({ children }) => {
  const [eventControlIsDisabled, setEventControl] = useState<boolean>(false)

  const handleChangeEventControl = useCallback((value: boolean) => {
    setEventControl(value)
  }, [])

  const providerValue = useMemo(
    () => ({
      state: {
        eventSidebarControlIsDisabled: eventControlIsDisabled,
      },
      handlers: {
        handleChangeEventControl,
      },
    }),
    [eventControlIsDisabled, handleChangeEventControl],
  )

  return <UiControlContext.Provider value={providerValue}>{children}</UiControlContext.Provider>
}

const useUiControlManager = () => {
  return useContext(UiControlContext)
}

export { useUiControlManager }
export default UiControlManager
