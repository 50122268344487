import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import DataOnFulfillmentOfTermsSZPKMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import DataOnFulfillmentOfTermsSZPKLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import DataOnFulfillmentOfTermsSZPKModalManager from './Manager'
import DataOnFulfillmentOfTermsSZPKMenu from './Menu'
import DataOnFulfillmentOfTermsSZPKStatementForm from './Statement'

interface ICreateDataOnFulfillmentOfTermsSZPK extends FC {
  LayoutWithFormProvider: typeof DataOnFulfillmentOfTermsSZPKLayoutWithFormProvider
  Menu: typeof DataOnFulfillmentOfTermsSZPKMenu
  StatementForm: typeof DataOnFulfillmentOfTermsSZPKStatementForm
  Manager: typeof DataOnFulfillmentOfTermsSZPKModalManager
  MenuManager: typeof DataOnFulfillmentOfTermsSZPKMenuManager
}

const useDataOnFulfillmentOfTermsSZPKControl = () => {
  const popupManager = usePopupManager()

  const openDataOnFulfillmentOfTermsSZPKModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(DataOnFulfillmentOfTermsSZPKLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openDataOnFulfillmentOfTermsSZPKModal }
}

const DataOnFulfillmentOfTermsSZPK: ICreateDataOnFulfillmentOfTermsSZPK = () => null

DataOnFulfillmentOfTermsSZPK.Menu = DataOnFulfillmentOfTermsSZPKMenu
DataOnFulfillmentOfTermsSZPK.LayoutWithFormProvider = DataOnFulfillmentOfTermsSZPKLayoutWithFormProvider
DataOnFulfillmentOfTermsSZPK.StatementForm = DataOnFulfillmentOfTermsSZPKStatementForm
DataOnFulfillmentOfTermsSZPK.Manager = DataOnFulfillmentOfTermsSZPKModalManager
DataOnFulfillmentOfTermsSZPK.MenuManager = DataOnFulfillmentOfTermsSZPKMenuManager

export { useDataOnFulfillmentOfTermsSZPKControl }
export default DataOnFulfillmentOfTermsSZPK
