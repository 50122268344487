import { FC } from 'react'

import HeaderInfo from './HeaderInfo'
import NPA from './NPA'

interface NpaFormsProps extends FC {
  HeaderInfo: typeof HeaderInfo
  NPA: typeof NPA
}

const NpaForms: NpaFormsProps = () => null

NpaForms.HeaderInfo = HeaderInfo
NpaForms.NPA = NPA

export default NpaForms
