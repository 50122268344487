import React, { FC, memo } from 'react'
import { FieldErrors, useFormContext, useFormState } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormMenu.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormNavigationMenu } from '@components/DocumentFormComponents/hooks/useFormNavigation'
import { useFormSubmitInStatement } from '@components/DocumentFormComponents/hooks/useFormSubmit'
import DocumentFormMenu from '@components/DocumentFormComponents/Menu'
import MenuActions from '@components/DocumentFormComponents/Menu/Actions'
import { useFlatMenuManager as useCreateStatementMenuManager } from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalMenuProps } from '@components/DocumentFormComponents/types'
import { mapOFMenu, statementBlockValues } from '@components/Forms/CreateStatement/const'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'

const { isBlockHaveError } = DocumentFormHelpers

const CreateStatementMenu: FC<FormModalMenuProps> = ({
  children,
  ...rest
}) => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    actions: { handleCheckForm, handlePreviewForm },
    handlers: { applyFormChanges },
  } = useCreateStatementManager()

  const { currentAnchorId, handleChangeCurrentAnchorId } = useCreateStatementMenuManager()

  const { handleMenuItemClick } = useFormNavigationMenu()
  const { handleSubmitForm } = useFormSubmitInStatement()

  const handleSubmit = async () => {
    if (!handleCheckForm || !applyFormChanges) return

    handleChangeCurrentAnchorId?.(mapOFMenu[0].id)

    try {
      await handleCheckForm()
    } catch (e) {
      throw e
    }
  }

  const handleIsBlockValues = (id: string, blockErrors: FieldErrors<StatementFormValues>) => {
    return isBlockHaveError(id, statementBlockValues, blockErrors)
  }

  const { errors } = useFormState({ control: formInstance?.control })

  if (!formInstance) return null

  return (
    <div className={styles.menu}>
      {children}
      <DocumentFormMenu
        {...rest}
        mapOfMenu={mapOFMenu}
        blockErrors={errors}
        blockViewIsValidating={blockViewIsValidating}
        activeMenuId={currentAnchorId}
        onBlockCheckError={handleIsBlockValues}
        onMenuClick={handleMenuItemClick(handleChangeCurrentAnchorId)}
      />
      <MenuActions
        previewButtonProps={
          editMode
            ? {
                onClick: handlePreviewForm,
              }
            : undefined
        }
        submitButtonProps={
          editMode
            ? {
                onClick: handleSubmitForm(handleSubmit),
              }
            : undefined
        }
      />
    </div>
  )
}

export default memo(CreateStatementMenu)
