import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import InfrFacilitiesCostsExpectedReimbursedLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import InfrFacilitiesCostsExpectedReimbursedModalManager from './Manager'
import InfrFacilitiesCostsExpectedReimbursedMenu from './Menu'
import InfrFacilitiesCostsExpectedReimbursedStatementForm from './Statement'

interface IInfrFacilitiesCostsExpectedReimbursed extends FC {
  LayoutWithFormProvider: typeof InfrFacilitiesCostsExpectedReimbursedLayoutWithFormProvider
  Menu: typeof InfrFacilitiesCostsExpectedReimbursedMenu
  StatementForm: typeof InfrFacilitiesCostsExpectedReimbursedStatementForm
  Manager: typeof InfrFacilitiesCostsExpectedReimbursedModalManager
}

const useInfrFacilitiesCostsExpectedReimbursedControl = () => {
  const popupManager = usePopupManager()

  const openInfrFacilitiesCostsExpectedReimbursedModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(InfrFacilitiesCostsExpectedReimbursedLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openInfrFacilitiesCostsExpectedReimbursedModal }
}

const InfrFacilitiesCostsExpectedReimbursed: IInfrFacilitiesCostsExpectedReimbursed = () => null

InfrFacilitiesCostsExpectedReimbursed.Menu = InfrFacilitiesCostsExpectedReimbursedMenu
InfrFacilitiesCostsExpectedReimbursed.LayoutWithFormProvider = InfrFacilitiesCostsExpectedReimbursedLayoutWithFormProvider
InfrFacilitiesCostsExpectedReimbursed.StatementForm =
  InfrFacilitiesCostsExpectedReimbursedStatementForm
InfrFacilitiesCostsExpectedReimbursed.Manager = InfrFacilitiesCostsExpectedReimbursedModalManager

export { useInfrFacilitiesCostsExpectedReimbursedControl }
export default InfrFacilitiesCostsExpectedReimbursed
