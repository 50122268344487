import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import Modal from '@components/NewDesign/Modal'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import { defaultMessageSupport, errorModalBodyTexts, errorModalButtonTexts } from '@constants/texts'
import { DfoAttributeTypes, DfoPropertiesTypes, RolesTypes } from '@constants/types'
import { defaultRHFValidation } from '@constants/validations'
import { useAPIContext } from '@context/APIContext'
import { isNotEmptyString, isNullOrUndefined } from '@helpers/checkTypes'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'

import styles from './AddInnModal.module.scss'

import { PostDfoAttributeProps, PutDfoAttributeProps, PutDfoPropertiesProps } from '@/context/APIContext/hooks/useDFOSApi'
import { DEFAULT_SYSTEM_VERSION } from '@/services/Dfo/Dfo.const'
import { IDfoListItem } from '@/services/Dfo/Dfo.entity'
import LoggerHelpersService from '@/services/LoggerService/LoggerService.helpers'

interface AddINNProps {
  projectId: string
  dfo: IDfoListItem
  partnerOrganizationId?: string
  onSave?: () => void
}

type AddINNModalProps = PopupProps & AddINNProps

const useInnModal = () => {
  const popupManager = usePopupManager()

  const handleOpenAddINNModal = (props: AddINNProps) => {
    popupManager.open<AddINNProps>(AddINNModal, props)
  }

  return { handleOpenAddINNModal }
}

const AddINNModal: FC<AddINNModalProps> = ({
  isOpen,
  onClose,
  projectId,
  dfo,
  partnerOrganizationId,
  onSave,
}) => {
  const dfoId = dfo.id
  const systemVersion = dfo.systemVersion ?? DEFAULT_SYSTEM_VERSION
  const [loading, setIsLoading] = useState(false)

  const {
    dfosApi: { postDfoAttribute, putDfoAttribute, putDfoProperties },
    organizationsApi: { getOrganizationsList },
  } = useAPIContext()

  const popupManager = usePopupManager()

  const { handleOpenErrorModal } = useErrorModal()

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      inn: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const addInnDfoAttribute = useCallback(
    async (attributeProps: PostDfoAttributeProps) => {
      const { name, value } = attributeProps.body

      try {
        await putDfoAttribute(attributeProps)

        popupManager.closeAll()
        onSave?.()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'AddINNModal',
            componentType: 'addInnDfoAttribute',
          },
          additionInfo: {
            attributeName: name,
            attributeValue: value,
            dfo
          },
        })(error)

        throw error
      }
    },
    [putDfoAttribute, onSave, popupManager, dfo])

  const updateInnDfoAttribute = useCallback(
    async (attributeProps: PutDfoAttributeProps) => {
      const { name, value } = attributeProps.body

      try {
        await postDfoAttribute(attributeProps)

        popupManager.closeAll()
        onSave?.()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'AddINNModal',
            componentType: 'updateInnDfoAttribute',
          },
          additionInfo: {
            attributeName: name,
            attributeValue: value,
            dfo
          },
        })(error)

        throw error
      }
    },
    [postDfoAttribute, onSave, popupManager, dfo])

  const updateInnDfoProperty = useCallback(
    async (propertiesProps: PutDfoPropertiesProps) => {
      const { id: innId } = propertiesProps.body.value

      try {
        await putDfoProperties(propertiesProps)

        popupManager.closeAll()
        onSave?.()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'AddINNModal',
            componentType: 'updateInnDfoProperty',
          },
          additionInfo: {
            innId,
            dfo
          },
        })(error)

        throw error
      }
    },
    [putDfoProperties, onSave, popupManager, dfo])

  const handleSavePartnerOrganizationViaAttributes = useCallback(
    async (foundInnOrgId: string) => {
      const postRequestPayload: PostDfoAttributeProps = {
        projectId,
        dfoId,
        body: {
          name: DfoAttributeTypes.partnerOrganizationId,
          value: foundInnOrgId,
        },
      } as const

      const putRequestPayload: PutDfoAttributeProps = postRequestPayload

      if (isNullOrUndefined(partnerOrganizationId))
        return handleOpenErrorModal({
          headerText: 'Произошла ошибка',
          bodyText: defaultMessageSupport,
        })

      try {
        if (isNotEmptyString(partnerOrganizationId)) {
          return await addInnDfoAttribute(postRequestPayload)
        }

        return await updateInnDfoAttribute(putRequestPayload)
      } catch {}
    },
    [
      handleOpenErrorModal,
      addInnDfoAttribute,
      updateInnDfoAttribute,
      projectId,
      dfoId,
      partnerOrganizationId
    ])

  const handleSavePartnerOrganizationViaProperties = useCallback(
    async (foundInnOrgId: string) => {
      const requestPayload: PutDfoPropertiesProps = {
        dfoId,
        body: {
          propertyPath: DfoPropertiesTypes.investorPartnerMemberOrganizationId,
          value: {
            id: foundInnOrgId,
          },
        },
      } as const

      try {
        return await updateInnDfoProperty(requestPayload)
      } catch {}
    },
    [updateInnDfoProperty, dfoId])

  const findInnOrganizationByInn = useCallback(async (innToSearch: string) => {
    try {
      const organizationsByInn = await getOrganizationsList({
        type: RolesTypes.INVESTOR,
        searchFields: 'inn',
        searchString: innToSearch,
        page: 0,
        size: 10,
      })

      const foundInnOrg = organizationsByInn?.organizations.find(
        (organization) => organization.inn === innToSearch,
      )

      return foundInnOrg
    } catch (error) {
      throw error
    }
  }, [getOrganizationsList])

  const handleFindInnOrganization = useCallback(async () => {
    const innToSearch = getValues().inn

    const foundInnOrg = await findInnOrganizationByInn(innToSearch)

    if (!foundInnOrg) {
      handleOpenErrorModal({
        headerText: `ИНН ${innToSearch} не найден`,
        bodyText: errorModalBodyTexts.innIncorrect,
        customActions: {
          actions: [
            {
              dataTestId: 'ErrorModal-back-button',
              children: errorModalButtonTexts.backMessage,
              fixWidth: true,
              view: 'gray',
              bindOnClose: true,
            },
            {
              dataTestId: 'ErrorModal-default-button',
              children: errorModalButtonTexts.defaultMessage,
              fixWidth: true,
              onClick: () => {
                popupManager.closeAll()
              },
            },
          ],
          mergeDefault: false,
        },
      })

      return null
    }

    return foundInnOrg
  }, [getValues, findInnOrganizationByInn, handleOpenErrorModal, popupManager])

  const handleChooseSavePartnerOrganizationHandler = useCallback(async () => {
    setIsLoading(true)

    try {
      const foundInnOrg = await handleFindInnOrganization()

      if (!foundInnOrg) {
        setIsLoading(false)
        return
      }

      if (systemVersion >= 3) {
        return await handleSavePartnerOrganizationViaProperties(foundInnOrg.id)
      }

      return await handleSavePartnerOrganizationViaAttributes(foundInnOrg.id)
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    } finally {
      setIsLoading(false)
    }
  }, [
    handleSavePartnerOrganizationViaAttributes,
    handleSavePartnerOrganizationViaProperties,
    handleFindInnOrganization,
    systemVersion,
  ])

  const actionsForModal = useMemo(() => {
    return [
      {
        dataTestId: `AddINNModal-cancel-button`,
        children: 'Отмена',
        disabled: loading,
        view: 'gray',
        onClick: () => {
          popupManager.closeAll()
        },
      },
      {
        dataTestId: `AddINNModal-saveOrganization-button`,
        children: 'Сохранить',
        disabled: !isValid || loading,
        loaderProps: {
          loading,
          variant: 'lite',
          placement: 'trailing',
        },
        onClick: handleSubmit(handleChooseSavePartnerOrganizationHandler),
      },
    ] as ButtonProps[]
  }, [handleChooseSavePartnerOrganizationHandler, handleSubmit, isValid, loading, popupManager])

  const preparedHandleClose = () => {
    onClose?.()
  }

  return (
    <Modal.Action
      disableOnClose={loading}
      hideCloseIcon={loading}
      isOpen={isOpen}
      title={'Укажите ИНН организации'}
      actions={actionsForModal}
      dataTestId="AddINNModal-modal"
      closeButtonDataTestId="AddINNModal-modal-closeButton"
      simpleModalContainerClassName={styles.innModal}
      actionModalContentClassName={styles.innModal__content}
      onClose={preparedHandleClose}
    >
      <ControlledMaskInput
        name={'inn'}
        control={control}
        inputProps={{
          fixWidth: true,
          view: 'secondary',
          size: 'xl',
          mask: '9999999999',
          maskPlaceholder: '',
          label: 'ИНН',
          dataTestId: 'AddINNModal-inn-maskInput',
        }}
        rules={{
          required: defaultRHFValidation.required,
          pattern: {
            value: /^(\d{10})$/,
            message: 'ИНН должен состоять из 10 цифр',
          },
        }}
      />
    </Modal.Action>
  )
}

export { useInnModal }
export default memo(AddINNModal)
