import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { RelatedContractsArrayPathName } from '@components/Forms/RegistrationCertificateForm/Forms/8/types'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

interface RegistrationCertificateRelatedContractItemProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formName: RelatedContractsArrayPathName
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  subscribableControl: SubscribableFormControlProps<RegistrationCertificateFormValues>
  onPublicList?: (pathNames: string[], newIsPublic: boolean) => void
}

const RegistrationCertificateRelatedContractItem: FC<
  RegistrationCertificateRelatedContractItemProps
> = ({
  editMode,
  blockViewIsValidating,
  formInstance,
  formModifierInstance,
  formName,
  subscribableControl,
  onPublicList,
}) => {
  const { getSubscribableControlProps, getControllerProps, getSwitchProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      watcher: RegistrationCertificateFieldsControlUpdateWatcher,
      subscribableControl,
    })

  const {
    handlers: { handleChangeValue },
  } = useRegistrationCertificateManager()

  return (
    <FieldView.ContainerWithPublic
      editMode={editMode}
      control={formModifierInstance.control}
      publicFormName={`${formName}.isPublic`}
      controlledFormNames={[
        `${formName}.relatedContractNumber`,
        `${formName}.relatedContractDate`,
        `${formName}.relatedContractKind`,
        `${formName}.relatedContractTerms`,
        `${formName}.relatedContractPublicLawMember`,
      ]}
      formIconWithTooltipProps={{
        tooltipContent: 'Данные будут отображены в публичной части реестра СЗПК',
      }}
      onPublic={onPublicList}
    >
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.relatedContractRegistrationDocument`,
            })}
          >
            <ControlledSwitch
              {...getSwitchProps({
                name: `${formName}.relatedContractRegistrationDocument`,
                switchProps: {
                  label: 'Перенести в пункт 22 справки',
                },
                onChange: () =>
                  setTimeout(
                    () => handleChangeValue?.(`${formName}.relatedContractRegistrationDocument`),
                    0,
                  ),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.relatedContractNumber`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Номер'}
              controllerProps={getControllerProps({
                name: `${formName}.relatedContractNumber`,
              })}
            />
          </SubscribableControl>
        </Col>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.relatedContractDate`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Дата заключения'}
              controllerProps={getControllerProps({
                name: `${formName}.relatedContractDate`,
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.relatedContractTermAndKind`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Предмет договора'}
              controllerProps={getControllerProps({
                name: `${formName}.relatedContractTermAndKind`,
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.relatedContractPublicLawMember`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Публично правовое образование, которому подлежит возмещать ущерб'}
              controllerProps={getControllerProps({
                name: `${formName}.relatedContractPublicLawMember`,
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
    </FieldView.ContainerWithPublic>
  )
}

export default RegistrationCertificateRelatedContractItem
