import { useEffect } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'

import { useFormBlockViewIsValidating } from '@components/DocumentFormComponents/hooks/useFormBlockViewIsValidating'
import { IViolation } from '@components/DocumentFormComponents/types'

interface UseApplyErrorsFromViolationsProps<T extends FieldValues> {
  formInstance: UseFormReturn<T>
  formIsLoading: boolean
  initialErrorsFromViolations?: IViolation[]
  applyErrorsFromViolations: (violations: IViolation[]) => void
}

const useApplyErrorsFromViolations = <T extends FieldValues>({
  formIsLoading,
  formInstance,
  initialErrorsFromViolations,
  applyErrorsFromViolations,
}: UseApplyErrorsFromViolationsProps<T>) => {
  const { blockViewIsValidating, handleChangeBlockValidation } = useFormBlockViewIsValidating()

  useEffect(() => {
    if (formIsLoading || !initialErrorsFromViolations) return

    applyErrorsFromViolations(initialErrorsFromViolations)

    handleChangeBlockValidation(true)
  }, [
    applyErrorsFromViolations,
    initialErrorsFromViolations,
    handleChangeBlockValidation,
    formIsLoading,
  ])

  return {
    blockViewIsValidating,
    handleChangeBlockValidation,
  }
}

export { useApplyErrorsFromViolations }
