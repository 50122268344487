import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementConsiderationInvestmentAuthorizedBlockValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/const'
import { firstSectionStatementConsiderationInvestmentAuthorizedValidationMap } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/Forms/1/validation'
import { useStatementConsiderationInvestmentAuthorizedManager } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/Manager'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'
import { StatementConsiderationInvestmentAuthorizedFieldsControlUpdateWatcher } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/watcher'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import ControlledMultipleInput from '@components/NewDesign/MultipleInput/ControlledMultipleInput'
import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

const { transformRHFPathInProperties } = DocumentFormHelpers

const One = () => {
  const formInstance = useFormContext<StatementConsiderationInvestmentAuthorizedFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      getPropertiesProps,
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithValue,
      handleRemoveCustomValue,
    },
    preparedProps: { subscribableControl },
  } = useStatementConsiderationInvestmentAuthorizedManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getCheckboxProps,
    getMultipleInputProps,
    getMaskInputProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: StatementConsiderationInvestmentAuthorizedFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleRemoveEmailOnChipClick = (
    _payload: MultipleInputValue[],
    valueToRemove: MultipleInputValue,
  ) => {
    const propertyInvestorHeaderEmail = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(
        statementConsiderationInvestmentAuthorizedBlockValues['1'].investorHeaderEmail,
      ),
    )

    if (!propertyInvestorHeaderEmail) return

    const propertyParentToRemove = {
      id: propertyInvestorHeaderEmail.propertyId,
      lastUpdateDt: propertyInvestorHeaderEmail.lastUpdateDt,
    }

    handleRemoveCustomValue?.(propertyParentToRemove, valueToRemove.value)
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Наименование"
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorName,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Сокращенное наименование"
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ИНН"
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ОГРН"
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorKpp,
              })}
            >
              <ControlledDocumentDataView
                suptitle="КПП"
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorKpp,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorOkpo,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ОКПО"
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorOkpo,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorOkved,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ОКВЭД"
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorOkved,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorCapital,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Размер уставного капитала"
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorCapital,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <AsyncWrapper promise={handleChangeValue}>
          {({ isLoading: isParticipantChecking, wrappedPromise: handleCheckParticipant }) => (
            <>
              <Row className={styles.form__checkboxRow}>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: statementConsiderationInvestmentAuthorizedBlockValues['1'].isVed,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: statementConsiderationInvestmentAuthorizedBlockValues['1'].isVed,
                        checkBoxProps: {
                          disabled: isParticipantChecking || !editMode,
                          label: 'Участник внешнеэкономической деятельности',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckParticipant?.(
                              statementConsiderationInvestmentAuthorizedBlockValues['1'].isVed,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row className={styles.form__checkboxRow}>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: statementConsiderationInvestmentAuthorizedBlockValues['1']
                        .isProjectCompany,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: statementConsiderationInvestmentAuthorizedBlockValues['1']
                          .isProjectCompany,
                        checkBoxProps: {
                          disabled: isParticipantChecking || !editMode,
                          label: 'Проектная компания',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckParticipant?.(
                              statementConsiderationInvestmentAuthorizedBlockValues['1']
                                .isProjectCompany,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </>
          )}
        </AsyncWrapper>
        <Group disableBottomBorder title="Юридический адрес">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1'].region,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Субъект"
                    formIconTooltipProps={{
                      tooltipContent: 'Адрес можно изменить в разделе "Моя организация"',
                    }}
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1'].region,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1'].city,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Населенный пункт (город)"
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1'].city,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1'].district,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Муниципальный район или округ"
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1'].district,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1'].area,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Поселение, межселенная территория, внутригородской район"
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1'].area,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1']
                      .planningStructureElement,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Элемент планировочной структуры"
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1']
                        .planningStructureElement,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1']
                      .roadNetworkElement,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Улица"
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1']
                        .roadNetworkElement,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1'].house,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Дом"
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1'].house,
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1'].room,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Квартира или офис"
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1'].room,
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1'].roomPart,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Комната"
                    controllerProps={getControllerProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1'].roomPart,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное лицо организации'}
          formInstance={formInstance}
          formSection={'1'}
          prefixFormFieldName={'investor'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementConsiderationInvestmentAuthorizedBlockValues['1'].investorHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1']
                        .investorHeaderName,
                      rules:
                        firstSectionStatementConsiderationInvestmentAuthorizedValidationMap.investorHeaderName,
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            statementConsiderationInvestmentAuthorizedBlockValues['1']
                              .investorHeaderName,
                          )
                        }, 0),
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
        <Group disableBottomBorder title="Контакты уполномоченного лица">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1']
                      .investorHeaderEmail,
                  })}
                >
                  <ControlledMultipleInput
                    {...getMultipleInputProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1']
                        .investorHeaderEmail,
                      rules:
                        firstSectionStatementConsiderationInvestmentAuthorizedValidationMap.investorHeaderEmail,
                      inputProps: {
                        label: 'Электронная почта для связи',
                        caption:
                          'добавьте одну или несколько, чтобы другие участники соглашения могли связаться с вашей организацией при необходимости',
                        dataTestId: `Investor-${statementConsiderationInvestmentAuthorizedBlockValues['1'].investorHeaderEmail}-multipleInput`,
                      },
                      onRemoveChip: handleRemoveEmailOnChipClick,
                      onAddChip: (_payload: MultipleInputValue[], newValue: MultipleInputValue) =>
                        setTimeout(() => {
                          handleAddItemToListWithValue?.(
                            statementConsiderationInvestmentAuthorizedBlockValues['1']
                              .investorHeaderEmail,
                            newValue.displayValue,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1']
                      .investorHeaderPhone,
                  })}
                >
                  <ControlledMaskInput
                    {...getMaskInputProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1']
                        .investorHeaderPhone,
                      rules:
                        firstSectionStatementConsiderationInvestmentAuthorizedValidationMap.investorHeaderPhone,
                      inputProps: {
                        mask: '+7 (999) 999-99-99',
                        label: 'Телефон для связи',
                        dataTestId: `Investor-${statementConsiderationInvestmentAuthorizedBlockValues['1'].investorHeaderPhone}-maskInput`,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            statementConsiderationInvestmentAuthorizedBlockValues['1']
                              .investorHeaderPhone,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            statementConsiderationInvestmentAuthorizedBlockValues['1']
                              .investorHeaderPhone,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementConsiderationInvestmentAuthorizedBlockValues['1']
                      .investorHeaderAddPhone,
                  })}
                >
                  <ControlledMaskInput
                    {...getMaskInputProps({
                      name: statementConsiderationInvestmentAuthorizedBlockValues['1']
                        .investorHeaderAddPhone,
                      rules:
                        firstSectionStatementConsiderationInvestmentAuthorizedValidationMap.investorHeaderAddPhone,

                      inputProps: {
                        mask: '9999999999',
                        maskPlaceholder: '',
                        label: 'Добавочный номер',
                        caption: 'если есть',
                        dataTestId: `Investor-${statementConsiderationInvestmentAuthorizedBlockValues['1'].investorHeaderAddPhone}-amountInput`,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            statementConsiderationInvestmentAuthorizedBlockValues['1']
                              .investorHeaderAddPhone,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            statementConsiderationInvestmentAuthorizedBlockValues['1']
                              .investorHeaderAddPhone,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default One
