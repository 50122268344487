import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'

interface NoticeOfProjectRejectConcludeSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
}

const NoticeOfProjectRejectConcludeSZPK: NoticeOfProjectRejectConcludeSZPKFormsProps = () => null

NoticeOfProjectRejectConcludeSZPK.First = First
NoticeOfProjectRejectConcludeSZPK.Second = Second
NoticeOfProjectRejectConcludeSZPK.Third = Third

export default NoticeOfProjectRejectConcludeSZPK
