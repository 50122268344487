import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import {
  GroupOfOptions,
  isOptionsGroup,
  OptionProps,
  SelectStateNew,
  SingleSelectProps,
} from '@components/NewDesign/Select/model'
import { compact } from '@helpers/array/compact'
import { isArray } from '@helpers/checkTypes'
import { deepEqual } from '@helpers/equals'
import escapeRegExp from '@helpers/lodash/escapeRegExp'
import { useBooleanState } from '@hooks/useBooleanState'
import useOnClickOutside from '@hooks/useOutsideClick'
import { usePreviousValue } from '@hooks/usePreviousValue'
import { unstable_serialize } from 'swr'

type ReceivedOptions = SingleSelectProps['options']

interface UseSelectStateProps {
  receivedOptions: ReceivedOptions
  defaultSelected: OptionProps | OptionProps[] | null

  defaultOptionsSelected?: ReceivedOptions
  disableOptionsMutate?: boolean
  disabled?: boolean
  withSearch?: boolean
  withContextSearch?: boolean

  onBlur?: () => void
  onFocus?: () => void
}

export const useSelectStateNew = <T extends SelectStateNew>({
  receivedOptions,
  defaultSelected,
  disabled,

  defaultOptionsSelected,
  withSearch,
  withContextSearch,
  disableOptionsMutate,

  onBlur,
  onFocus,
}: UseSelectStateProps) => {
  const [selected, setSelected] = useState<T['selected'] | null>(defaultSelected)
  const [searchValue, setSearchValue] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<(GroupOfOptions | OptionProps)[]>()

  const prevDefaultSelected = usePreviousValue(defaultSelected)

  const filteredSelectedOptions = useMemo(() => {
    return (selectedOptions ?? [])
      .map(
        (selectedOption) =>
          ![...receivedOptions, ...(defaultOptionsSelected ?? [])].some((option) =>
            deepEqual(option, selectedOption),
          ) && selectedOption,
      )
      .filter(Boolean) as (GroupOfOptions | OptionProps)[]
  }, [receivedOptions, selectedOptions, defaultOptionsSelected])

  const preparedReceivedOptions = useMemo(() => {
    const prepearedNewOptions = defaultOptionsSelected
      ? [...defaultOptionsSelected, ...receivedOptions]
      : receivedOptions

    return [...prepearedNewOptions, ...filteredSelectedOptions]
  }, [defaultOptionsSelected, receivedOptions, filteredSelectedOptions])

  const getAllOptionsInArray = useCallback((value: (GroupOfOptions | OptionProps)[]) => {
    const optionsToReturn: OptionProps[] = []
    value.forEach((option) => {
      if (isOptionsGroup(option)) {
        optionsToReturn.push(...option.options)
        return getAllOptionsInArray(option.options)
      }
      optionsToReturn.push(option)
    })

    return optionsToReturn
  }, [])

  const allOptionsInArray = useMemo(() => {
    return getAllOptionsInArray(preparedReceivedOptions)
  }, [getAllOptionsInArray, preparedReceivedOptions])

  const fromOptionInInputValue = useCallback(
    (value: string) => {
      const option = allOptionsInArray.find((option) => option.value === value)

      if (!option) return
      return {
        displayValue: option?.displayValue,
        value: option?.value,
      }
    },
    [allOptionsInArray],
  )

  const readyInputValues = useMemo(() => {
    if (!selected) return

    const returnValue = isArray(selected)
      ? (compact(
          selected.map(({ value }) => fromOptionInInputValue(value)),
        ) as Array<MultipleInputValue>)
      : (compact([fromOptionInInputValue(selected.value)]) as Array<MultipleInputValue>)

    return !Array.isArray(returnValue)
      ? returnValue
      : (returnValue.length && returnValue) || undefined
  }, [fromOptionInInputValue, selected])

  const inputContainerRef = useRef<HTMLInputElement | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)

  const {
    booleanState: isPopoverOpen,
    setBooleanStateToFalse: closePopover,
    setBooleanStateToTrue: openPopover,
    setBooleanState: setPopover,
    reverseBooleanState,
  } = useBooleanState()

  const handleOpenPopover = useCallback(() => {
    onFocus?.()
    openPopover()
  }, [onFocus, openPopover])

  const handleReversePopover = useCallback(() => {
    if (!isPopoverOpen) {
      onFocus?.()
    }

    reverseBooleanState()
  }, [isPopoverOpen, onFocus, reverseBooleanState])

  useEffect(() => {
    if (unstable_serialize(defaultSelected) !== unstable_serialize(prevDefaultSelected)) {
      if (isArray(defaultSelected) && receivedOptions) {
        const arrayOptionsBeforeCleaning: (OptionProps | GroupOfOptions)[] = []

        for (const mapOfNewAndSelectedOptions of [...receivedOptions, ...filteredSelectedOptions]) {
          if (
            mapOfNewAndSelectedOptions &&
            !isOptionsGroup(mapOfNewAndSelectedOptions) &&
            defaultSelected.some(({ value }) => value === mapOfNewAndSelectedOptions.value)
          ) {
            arrayOptionsBeforeCleaning.push(mapOfNewAndSelectedOptions)
          }
        }

        setSelectedOptions(arrayOptionsBeforeCleaning)
      }

      setSelected(defaultSelected)
    }
  }, [defaultSelected, prevDefaultSelected])

  useOnClickOutside(
    [inputContainerRef, popoverRef],
    useCallback(() => {
      if (isPopoverOpen) {
        onBlur?.()
        closePopover()
      }
    }, [closePopover, isPopoverOpen, onBlur]),
  )

  const handleOnClickContainer = useCallback(
    (e) => {
      if (e.target.tagName !== 'INPUT' || e.target.tagName !== 'SPAN') {
        return handleReversePopover()
      }

      if (!isPopoverOpen) handleOpenPopover()
    },
    [isPopoverOpen, handleOpenPopover, handleReversePopover],
  )

  useEffect(() => {
    if (!!disabled) return

    const currentRef = inputContainerRef?.current

    currentRef?.addEventListener('click', handleOnClickContainer)
    return () => {
      currentRef?.removeEventListener('click', handleOnClickContainer)
    }
  }, [inputContainerRef, handleOnClickContainer, closePopover])

  const filterOptionsBySelectedValues = useCallback(
    (options: (GroupOfOptions | OptionProps)[], selectedValues: string[]) => {
      return options.filter((option) => {
        if (isOptionsGroup(option))
          return filterOptionsBySelectedValues(option.options, selectedValues)

        return !selectedValues.includes(option.value)
      })
    },
    [],
  )

  const prepareReceivedOptions = useCallback(
    (options: (GroupOfOptions | OptionProps)[]) => {
      if (!selected) return options

      const selectedArray = isArray(selected) ? selected : [selected]

      const selectedValues = selectedArray.map(({ value }) => value)

      const filteredOptions = filterOptionsBySelectedValues(options, selectedValues)

      return [...selectedArray, ...filteredOptions]
    },
    [filterOptionsBySelectedValues, selected],
  )

  const availableVisibleOptions = useMemo<ReceivedOptions>(() => {
    // Если селект без поиска, то лишнюю работу не делаем
    if (!searchValue || !(withSearch || withContextSearch) || disableOptionsMutate)
      return [
        ...prepareReceivedOptions(receivedOptions),
        ...(searchValue ? [] : filteredSelectedOptions),
      ]

    const startRegExp = withContextSearch ? '' : '^'

    const regExp = new RegExp(`${startRegExp}${escapeRegExp(searchValue.toLowerCase())}`)

    /** Рекурсивная функция, ищущая option'ы, подходящие под искомое пользователем значение
     * Алгоритм для каждой итерации
     * 1. Если значение является простым Option объектом, то смотрим совпадает ли displayName с искомым значением и возращаем его при положительном исходе
     * 2. Если значение является группой, то делаем рекурсивный вызов, передавая массив Option'ов группы
     * 2.1. Если в группе есть подходящие значения, то возвращаем группу с этими значениями
     */
    const hasMatchedOptions = (options: ReceivedOptions) =>
      options.reduce<ReceivedOptions>((matchedOptions, option) => {
        if (isOptionsGroup(option)) {
          const matchedOptionsFromGroup = hasMatchedOptions(option.options)
          return matchedOptionsFromGroup.length
            ? [...matchedOptions, { ...option, options: matchedOptionsFromGroup }]
            : matchedOptions
        }

        if (isArray(selected)) {
          const foundSelected = selected.find(({ value }) => value === option.value)

          return !foundSelected && regExp.test(option.displayValue.toLowerCase())
            ? [...matchedOptions, option]
            : matchedOptions
        }

        return regExp.test(option.displayValue.toLowerCase())
          ? [...matchedOptions, option]
          : matchedOptions
      }, [])

    return hasMatchedOptions(receivedOptions)
  }, [
    searchValue,
    withSearch,
    withContextSearch,
    disableOptionsMutate,
    receivedOptions,
    selected,
    filteredSelectedOptions,
  ])

  // Если значение инпута не вмещается и создает скролл.
  const valueIsLongerThenInput =
    inputRef.current &&
    Math.ceil(inputRef.current.getClientRects()[0]?.width) < inputRef.current.scrollWidth

  const clue = useMemo(() => {
    if (!availableVisibleOptions.length || !searchValue || !withSearch) return ''

    const [firstOption] = availableVisibleOptions

    const clue = isOptionsGroup(firstOption)
      ? firstOption.options[0].displayValue
      : firstOption.displayValue

    return availableVisibleOptions[0] ? searchValue + clue.slice(searchValue.length) : ''
  }, [availableVisibleOptions, searchValue, withSearch])

  return {
    selected,
    isPopoverOpen,
    allOptionsInArray,
    availableOptions: availableVisibleOptions,
    searchValue,
    inputContainerRef,
    popoverRef,
    clue,
    inputRef,
    valueIsLongerThenInput,
    setSearchValue,
    setSelected,
    closePopover,
    openPopover: handleOpenPopover,
    reverseIsPopoverOpen: handleReversePopover,
    readyInputValues,
  }
}
