import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  SeventhPermissionsObjectNamePermissionsPathName,
  SeventhPermissionsObjectsArrayPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

import { objectNamePermissionsValidationMap } from './validation'

const { isFormFieldError } = DocumentFormHelpers

interface ObjectNamesListProps {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: SeventhPermissionsObjectsArrayPathName
}

const ObjectNamesList: FC<ObjectNamesListProps> = ({
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getSingleSelectProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: objectNamePermissions } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhPermissionsObjectNamePermissionsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${formName}.objectNamePermissions`,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const objectNamePermissionsError = formInstance.getFieldState(
    `${formName}.objectNamePermissions`,
  )?.error

  const handleAddNewObjectNamePermission = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.objectNamePermissions`)

    if (!objectNamePermissions.length) {
      formInstance.clearErrors(`${formName}.objectNamePermissions`)
    }
  }

  const handleRemoveObjectNamePermission = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${formName}.objectNamePermissions.${index}`,
      `${formName}.objectNamePermissions`,
    )
  }

  return (
    <Group disableBottomBorder>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.objectNamePermissions`,
          })}
        >
          <FlipperList list={objectNamePermissions}>
            {objectNamePermissions.map((objectNamePermission, index) => {
              const objectPermissionFormName = `${formName}.objectNamePermissions.${index}` as const

              return (
                <FieldView.RowWithRemoveButton
                  key={objectNamePermission.id}
                  id={objectNamePermission.id}
                  dataTestId='permissionFormObjectsListItem'
                  onRemove={editMode ? handleRemoveObjectNamePermission(index) : undefined}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${objectPermissionFormName}.objectOption`,
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.objectName,
                              value: item.id,
                            }),
                            controllerProps: {
                              name: `${objectPermissionFormName}.objectOption`,
                              rules: objectNamePermissionsValidationMap.objectOption,
                            },
                            selectProps: {
                              inputProps: {
                                dataTestId: 'objectDropdown',
                                label: 'Объект',
                              },
                              onChangeFormValue: () =>
                                setTimeout(
                                  () =>
                                    handleChangeValue?.(`${objectPermissionFormName}.objectOption`),
                                  0,
                                ),
                            },
                          })}
                        />
                      )
                    }}
                  </SubscribableControl>
                </FieldView.RowWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(objectNamePermissionsError) && !objectNamePermissions.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddNewObjectNamePermission}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Указать в разрешении объект
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default ObjectNamesList
