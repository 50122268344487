import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'
import CreateStatementLayoutWithFormProvider from '@components/Forms/CreateStatement/Layout/LayoutWithFormProvider'
import CreateStatementModalManager from '@components/Forms/CreateStatement/Manager'
import CreateStatementMenu from '@components/Forms/CreateStatement/Menu'
import CreateStatementForm from '@components/Forms/CreateStatement/Statement'

interface ICreateStatement extends FC {
  LayoutWithFormProvider: typeof CreateStatementLayoutWithFormProvider
  Menu: typeof CreateStatementMenu
  StatementForm: typeof CreateStatementForm
  Manager: typeof CreateStatementModalManager
}

const useCreateStatementControl = () => {
  const popupManager = usePopupManager()

  const openStatementModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(CreateStatementLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openStatementModal }
}

const CreateStatement: ICreateStatement = () => null

CreateStatement.Menu = CreateStatementMenu
CreateStatement.LayoutWithFormProvider = CreateStatementLayoutWithFormProvider
CreateStatement.StatementForm = CreateStatementForm
CreateStatement.Manager = CreateStatementModalManager

export { useCreateStatementControl }
export default CreateStatement
