import { useAPIContext } from '@context/APIContext'
import type { GetReferenceBooksReturnType } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import type { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useReferenceBooksByDictionaryName = ({
  key,
  config,
}: SWRHookProps<GetReferenceBooksReturnType>) => {
  const {
    referenceBooksApi: { getReferenceBooks },
  } = useAPIContext()

  const {
    data: referenceBooks,
    mutate,
    error: referenceBooksError,
    isValidating: isReferenceBooksLoading,
  } = useSWRImmutable(key, getReferenceBooks, config)

  return {
    referenceBooks,
    referenceBooksError,
    mutate,
    isReferenceBooksLoading,
  }
}

export { useReferenceBooksByDictionaryName }
