import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'
import SummaryReportOfMonitoringResultsLayoutWithFormProvider from '@components/Forms/SummaryReportOfMonitoringResultsForm/Layout/LayoutWithFormProvider'
import SummaryReportOfMonitoringResultsModalManager from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import SummaryReportOfMonitoringResultsMenu from '@components/Forms/SummaryReportOfMonitoringResultsForm/Menu'
import SummaryReportOfMonitoringResultsStatementForm from '@components/Forms/SummaryReportOfMonitoringResultsForm/Statement'

interface ICreateFormTemplate extends FC {
  LayoutWithFormProvider: typeof SummaryReportOfMonitoringResultsLayoutWithFormProvider
  Menu: typeof SummaryReportOfMonitoringResultsMenu
  StatementForm: typeof SummaryReportOfMonitoringResultsStatementForm
  Manager: typeof SummaryReportOfMonitoringResultsModalManager
}

const useSummaryReportOfMonitoringResultsControl = () => {
  const popupManager = usePopupManager()

  const openSummaryReportOfMonitoringResultsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        SummaryReportOfMonitoringResultsLayoutWithFormProvider,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openSummaryReportOfMonitoringResultsModal }
}

const SummaryReportOfMonitoringResults: ICreateFormTemplate = () => null

SummaryReportOfMonitoringResults.Menu = SummaryReportOfMonitoringResultsMenu
SummaryReportOfMonitoringResults.LayoutWithFormProvider =
  SummaryReportOfMonitoringResultsLayoutWithFormProvider
SummaryReportOfMonitoringResults.StatementForm = SummaryReportOfMonitoringResultsStatementForm
SummaryReportOfMonitoringResults.Manager = SummaryReportOfMonitoringResultsModalManager

export { useSummaryReportOfMonitoringResultsControl }
export default SummaryReportOfMonitoringResults
