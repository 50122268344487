import React, { FC, memo, useMemo } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps, MapOfMenu } from '@components/DocumentFormComponents/types'
import AdditionalAgreementOnTerminationFfSZPK from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK'
import {
  additionalAgreementOnTerminationFfSZPKValues,
  defaultAdditionalAgreementOnTerminationFfSZPKFormValues,
  mapOfAdditionalAgreementOnTerminationFfSZPKMenu,
} from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/const'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'
import { AdditionalAgreementOnTerminationFfSZPKFieldsControlUpdateWatcher } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/watcher'
import Typography from '@components/NewDesign/Typography'

import AdditionalAgreementOnTerminationFfSZPKLayoutWrapper from './Wrapper'

const AdditionalAgreementOnTerminationFfSZPKLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<AdditionalAgreementOnTerminationFfSZPKFormValues>({
    defaultValues: defaultAdditionalAgreementOnTerminationFfSZPKFormValues,
  })

  const handleOnClose = () => {
    AdditionalAgreementOnTerminationFfSZPKFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  const isFederalSZPK = useWatch({
    name: additionalAgreementOnTerminationFfSZPKValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: additionalAgreementOnTerminationFfSZPKValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const currentMenu: MapOfMenu[] = useMemo(() => {
    return [...mapOfAdditionalAgreementOnTerminationFfSZPKMenu].reduce<MapOfMenu[]>(
      (previousValue, currentValue) => {
        if (!isFederalSZPK && currentValue.id === '1') return previousValue

        if (!isMunicipalitiesRender && currentValue.id === '3') return previousValue

        return [...previousValue, currentValue]
      },
      [],
    )
  }, [isFederalSZPK, isMunicipalitiesRender])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <AdditionalAgreementOnTerminationFfSZPK.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={currentMenu[0]?.id}>
            <AdditionalAgreementOnTerminationFfSZPKLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Дополнительное соглашение о расторжении СЗПК
                </Typography.Headline>
                <AdditionalAgreementOnTerminationFfSZPK.StatementForm
                  initialMenu={currentMenu}
                  className={styles.layout__form}
                />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <AdditionalAgreementOnTerminationFfSZPK.Menu
                  initialMenu={currentMenu}
                  className={styles.layout__menu}
                />
              </div>
            </AdditionalAgreementOnTerminationFfSZPKLayoutWrapper>
          </FlatMenuManager>
        </AdditionalAgreementOnTerminationFfSZPK.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(AdditionalAgreementOnTerminationFfSZPKLayout)
