import React, { FC, useCallback, useContext, useEffect } from 'react'

import FileDropzone from '@components/FileDropzone'
import { INotificationItem } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/Attachment/NotificationAttachment'
import { DocumentsSetsType, MimeTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import { useDfoDocuments } from '@hooks/new/swr/useDfoDocuments'
import { useBooleanState } from '@hooks/useBooleanState'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import { NotificationsOfRequisitesChangesContext } from '../NotificationsOfRequisitesChanges'
import styles from '../NotificationsOfRequisitesChanges.module.scss'

import { LoadingAttachment } from './LoadingAttachment'

type ActiveNotificationAttachmentProps = Pick<INotificationItem, 'projectId' | 'dfoId'>

export const ActiveNotificationAttachment: FC<ActiveNotificationAttachmentProps> = ({
  projectId,
  dfoId,
}) => {
  const {
    documentsApi: { saveDocument },
    dfosApi: { downloadDocumentTemplateByType },
  } = useAPIContext()

  const {
    booleanState: isLoading,
    setBooleanStateToTrue: startLoading,
    setBooleanStateToFalse: endLoading,
  } = useBooleanState()

  const { revalidateProjects, isDeletingInProcess } = useContext(
    NotificationsOfRequisitesChangesContext,
  )

  const { dfoDocuments, mutate: revalidateDfoDocuments } = useDfoDocuments({
    key: {
      _key: 'dfoDocuments',
      projectId,
      dfoId,
    },
  })

  const documentSet = dfoDocuments?.[DocumentsSetsType.ORGANIZATION_INFO_CHANGES][0]

  const hasPermissions = documentSet?.permissions && [3, 7].includes(documentSet.permissions)

  const handleDownloadTemplateWithToast = useCallback(() => {
    return withDownloadToastPromise(
      downloadDocumentTemplateByType(DocumentsSetsType.ORGANIZATION_INFO_CHANGES),
    )
  }, [downloadDocumentTemplateByType])

  const saveAddedNotificationAttachmentHandler = async (files: File[]) => {
    if (!dfoDocuments) return

    try {
      startLoading()
      await saveDocument(
        {
          dfoId,
          projectId,
          type: DocumentsSetsType.ORGANIZATION_INFO_CHANGES,
          documentSetId: dfoDocuments[DocumentsSetsType.ORGANIZATION_INFO_CHANGES][0].documentSetId,
        },
        files,
      )

      await revalidateDfoDocuments()
      await revalidateProjects()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ActiveNotificationAttachment',
          componentType: 'saveAddedNotificationAttachmentHandler',
          moduleName: 'NotificationItem',
        },
        additionInfo: {
          type: DocumentsSetsType.ORGANIZATION_INFO_CHANGES,
          documentSetId: dfoDocuments[DocumentsSetsType.ORGANIZATION_INFO_CHANGES][0].documentSetId,
        },
      })(error)
    } finally {
      endLoading()
    }
  }

  useEffect(() => {
    return () => {
      endLoading()
    }
  }, [])

  const mustShowLoader = isDeletingInProcess || isLoading

  return (
    <div
      className={cn(styles.activeNotification, {
        [styles.activeNotification__disabled]: !hasPermissions,
      })}
    >
      {mustShowLoader ? (
        <LoadingAttachment loading text={isDeletingInProcess ? 'Удаление' : 'Добавление файла'} />
      ) : (
        <FileDropzone.Primary
          documentTemplateType
          title={'Уведомление о смене реквизитов'}
          dropZoneProps={{
            onDrop: saveAddedNotificationAttachmentHandler,
            accept: MimeTypes,
            multiple: false,
          }}
          onDownloadTemplateClick={handleDownloadTemplateWithToast}
        />
      )}
    </div>
  )
}
