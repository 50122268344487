import { ChooseNpaFormValues } from '@components/RegistryNpa/Choose/types'

const defaultChooseNpaFormValues: ChooseNpaFormValues = {
  normalizedData: {
    directions: [],
    npas: [],
    parts: [],
  },
  threeToRender: [],
  chosenThree: [],
  chosenParts: [],
}

const chooseNpaBlockValues = {
  normalizedData: 'normalizedData',
  threeToRender: 'threeToRender',
  chosenThree: 'chosenThree',
  chosenParts: 'chosenParts',
} as const

const DEFAULT_MASTER_ITEM_HEIGHT = 61

export { chooseNpaBlockValues, DEFAULT_MASTER_ITEM_HEIGHT, defaultChooseNpaFormValues }
