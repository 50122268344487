import { useAPIContext } from '@context/APIContext'
import { GetDfoProperties } from '@context/APIContext/hooks/useDFOSApi'
import { SWRHookProps } from '@interfaces'
import { GetDfoPropertiesBody } from '@services/Dfo/Dfo.entity'
import useSWRImmutable from 'swr/immutable'

type UseDfoPropertiesProps<BodyType extends GetDfoPropertiesBody> = {
  key: { body: BodyType } | null
} & SWRHookProps<GetDfoProperties<BodyType>>

const useDfoProperties = <BodyType extends GetDfoPropertiesBody>({
  key,
  config,
}: UseDfoPropertiesProps<BodyType>) => {
  const {
    dfosApi: { getDfoProperties },
  } = useAPIContext()

  const {
    data: dfoProperties,
    error,
    mutate,
    isValidating: isLoadingDfoProperties,
  } = useSWRImmutable(key, (key) => getDfoProperties<BodyType>(key), config)

  return {
    dfoProperties,
    isLoadingDfoProperties,
    mutate,
    error,
  }
}

export { useDfoProperties }
