interface SubscriberValueProps {
  onCollapse: (value: ((prevState: boolean) => boolean) | boolean) => void
}

interface SubscriberNotifyProps {
  path: string
  value: boolean
}

class FieldCollapseControlUpdateWatcher {
  private readonly subscribers: Record<string, SubscriberValueProps>

  constructor() {
    this.subscribers = {}
  }

  subscribe(path: string, callbacks: SubscriberValueProps) {
    this.subscribers[path] = callbacks
  }

  unsubscribe(path: string) {
    if (!this.subscribers[path]) return
    delete this.subscribers[path]
  }

  notifySubscriber({ path, value }: SubscriberNotifyProps) {
    if (!this.subscribers[path]) return

    this.subscribers[path].onCollapse(value)
  }

  notifyAllSubscribers(data: SubscriberNotifyProps[]) {
    data.forEach((subscriberValue) => {
      this.notifySubscriber(subscriberValue)
    })
  }
}

export { FieldCollapseControlUpdateWatcher }
