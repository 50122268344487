import { Path } from 'react-hook-form'

import { validationOrganizationByRoles } from '@components/OrganizationInfo/MainInfo/constants'
import { RolesTypes } from '@constants/types'
import { IOrganizationInfo } from '@services/Organization/organization.entity'

const getMissingFieldsByValidation = (
  role: keyof typeof RolesTypes,
  organizationInfo: IOrganizationInfo,
): Path<IOrganizationInfo>[] | undefined => {
  const isFieldEmpty = (field: unknown): boolean => {
    return field === null || field === ''
  }

  const roleValidation = validationOrganizationByRoles[role]
  if (!roleValidation) return

  const missingFields: string[] = []
  for (const key in roleValidation) {
    if (roleValidation[key] && isFieldEmpty(organizationInfo[key])) {
      missingFields.push(key)
    }
  }

  return missingFields as Path<IOrganizationInfo>[]
}

export { getMissingFieldsByValidation }
