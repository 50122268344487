import { DetailedHTMLProps, FC, forwardRef, HTMLAttributes, ReactNode } from 'react'

import Loader from '@components/Loader'
import { Popover, PopoverProps } from '@components/NewDesign/Popover'
import { isOptionsGroup, SelectProps } from '@components/NewDesign/Select/model'
import styles from '@components/NewDesign/Select/SingleSelect.module.scss'
import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import { isBoolean } from '@helpers/checkTypes'
import cn from 'classnames'

import { OptionProps } from './model'

const DEFAULT_NOT_FOUND_TEXT = 'Нет доступных опций для выбора'

export interface OptionsProps extends Pick<SelectProps<string | string[]>, 'options'> {
  popoverProps: PopoverProps
  onClick: (value: string) => void
  selected: string | string[]

  variant?: 'primary' | 'secondary'
  view?: 'normal' | 'reversed'
  className?: string
  optionClassName?: string

  DisplayValueFC?: FC<OptionProps>

  optionsContainer?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

  enableOptionTooltips?:
    | boolean
    | {
        value: boolean
        valueLength?: number
      }

  tailNode?: ReactNode

  scrollableOptionsContainerClassName?: string

  isLoading?: boolean

  dataTestId?: string

  notFoundText?: string
}

const OPTION_TEXT_MIN_TOOLTIP_LENGTH = 45

export const Options: FC<OptionsProps> = ({
  variant = 'primary',
  view = 'normal',
  enableOptionTooltips,
  popoverProps,
  options,
  selected,
  onClick,
  className,
  optionClassName,
  optionsContainer,
  tailNode,
  scrollableOptionsContainerClassName,
  DisplayValueFC,
  isLoading,
  dataTestId,
  notFoundText,
}) => {
  const defaultTooltipLimitLength = isBoolean(enableOptionTooltips)
    ? OPTION_TEXT_MIN_TOOLTIP_LENGTH
    : enableOptionTooltips?.valueLength || OPTION_TEXT_MIN_TOOLTIP_LENGTH
  const isPrimary = variant === 'primary'

  const Option = forwardRef<HTMLDivElement, OptionProps>(
    ({ displayValue, value, children, ...divRest }, ref) => {
      const isOptionSelected = Array.isArray(selected)
        ? selected.includes(value)
        : selected === value

      return (
        <div
          {...divRest}
          key={displayValue}
          ref={ref}
          role={'none'}
          className={cn(styles.Option, styles.Option__text, optionClassName, {
            [styles.OptionSecondary]: !isPrimary,
            [styles.Option_selected]: isOptionSelected,
            [styles.Option_selectedSecondary]: isOptionSelected && !isPrimary,
          })}
          onClick={(e) => {
            e.stopPropagation()
            onClick(value)
          }}
        >
          {children}
        </div>
      )
    },
  )

  return (
    <Popover
      useAnchorWidth
      className={styles.Popover}
      popperClassName={styles.Popover__inner}
      offset={[0, 10]}
      {...popoverProps}
    >
      <div
        {...optionsContainer}
        data-testid={dataTestId}
        className={cn(scrollableOptionsContainerClassName, styles.OptionsContainer)}
      >
        {!!options.length && (
          <div
            className={cn(
              styles.Options,
              {
                [styles.OptionsSecondary]: !isPrimary,
              },
              className,
            )}
          >
            {options.map((option, index) => {
              return isOptionsGroup(option) ? (
                <div
                  key={option.title}
                  className={cn(styles.OptionsGroup, {
                    [styles.OptionsGroupSecondary]: !isPrimary,
                  })}
                >
                  <Typography.Caption
                    variant={isPrimary ? 'captionAllcaps' : 'captionSRegular'}
                    className={styles.OptionsGroupTitle}
                    color={isPrimary ? 'text-base-tertiary' : 'text-base-secondary'}
                  >
                    {option.title}
                  </Typography.Caption>
                  {option.options.map((option) =>
                    enableOptionTooltips &&
                    option.displayValue.length >= defaultTooltipLimitLength ? (
                      <Tooltip
                        key={index}
                        targetClassName={styles['Options__tooltip-target']}
                        popoverClassName={styles.Options__tooltip}
                        position={'left'}
                        trigger={'hover'}
                        content={option.displayValue}
                      >
                        <Option {...option} dataTestId={`Option-item-${option.value}`}>
                          {option.displayValue}
                        </Option>
                      </Tooltip>
                    ) : (
                      <Option {...option} dataTestId={`Option-item-${option.value}`}>
                        {option.displayValue}
                      </Option>
                    ),
                  )}
                </div>
              ) : enableOptionTooltips &&
                option.displayValue.length >= defaultTooltipLimitLength ? (
                <Tooltip
                  key={option.value}
                  targetClassName={styles['Options__tooltip-target']}
                  popoverClassName={styles.Options__tooltip}
                  position={'left'}
                  trigger={'hover'}
                  content={option.displayValue}
                >
                  <Option {...option} dataTestId={`Option-item-${option.value}`}>
                    {option.displayValue}
                  </Option>
                </Tooltip>
              ) : (
                <Option key={option.value} {...option} dataTestId={`Option-item-${option.value}`}>
                  {option.label ? (
                    <>
                      {view === 'normal' && (
                        <>
                          <Typography
                            className={styles.Option__text}
                            color={'text-base-secondary'}
                            variant={'captionSRegular'}
                          >
                            {option.label}
                          </Typography>
                          {DisplayValueFC ? (
                            <DisplayValueFC {...option}>{option.displayValue}</DisplayValueFC>
                          ) : (
                            <Typography className={styles.Option__text} variant={'bodyMRegular'}>
                              {option.displayValue}
                            </Typography>
                          )}
                        </>
                      )}
                      {view === 'reversed' && (
                        <>
                          {DisplayValueFC ? (
                            <DisplayValueFC {...option}>{option.displayValue}</DisplayValueFC>
                          ) : (
                            <Typography className={styles.Option__text} variant={'bodyMRegular'}>
                              {option.displayValue}
                            </Typography>
                          )}
                          <Typography
                            className={styles.Option__text}
                            color={'text-base-secondary'}
                            variant={'captionSRegular'}
                          >
                            {option.label}
                          </Typography>
                        </>
                      )}
                    </>
                  ) : (
                    option.displayValue
                  )}
                </Option>
              )
            })}
          </div>
        )}
        {!options.length && !isLoading && (
          <div className={styles.Options__notFoundContainer}>
            <Typography.Body variant="bodyMMedium" className={cn(styles.Options__notFound)}>
              {notFoundText || DEFAULT_NOT_FOUND_TEXT}
            </Typography.Body>
          </div>
        )}
        <Loader className={styles.Options__loading} variant="lite" loading={isLoading} />
        {tailNode}
      </div>
    </Popover>
  )
}
