import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFindFormValueDifferences } from '@components/DocumentFormComponents/hooks/useFindFormValueDifferences'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import {
  FormModalContextProps,
  FormModalManagerProps,
} from '@components/DocumentFormComponents/types'
import { useCreateStatementAdapters } from '@components/Forms/CreateStatement/adapters'
import { rewriteCreateStatementFieldsAfterApplyDiffs } from '@components/Forms/CreateStatement/const'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import {
  CreateStatementControlUpdateWatcher,
  CreateStatementFieldArrayControlUpdateWatcher,
} from '@components/Forms/CreateStatement/watcher'
import { isSelectOption } from '@components/NewDesign/Select/helpers'
import { isArray, isNull, isNumber, isString } from '@helpers/checkTypes'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'

const { getDefaultFormModalContextValue } = DocumentFormHelpers

export const CreateStatementModalContext = createContext<
  FormModalContextProps<StatementFormValues>
>(getDefaultFormModalContextValue<StatementFormValues>())

const CreateStatementModalManager: FC<FormModalManagerProps> = ({
  formId,
  projectId,
  editMode,
  initialErrorsFromViolations,
  onSetLastUpdateFormToNow,
  rolesOfUser,
  onClose,
  children,
}) => {
  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  const { updateLastRFHBeforeValue, getLastRHFBeforeValue } =
    useFormPreviousValue<StatementFormValues>()

  const formInstance = useFormContext<StatementFormValues>()
  const { setValue, reset: resetForm, clearErrors } = formInstance

  const { processOverrideProps, getOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const { formIsLoading, handleMountForm } = useFormMount(formId)

  const { generateRHFObject, generatePropertiesObject, generatePrefetchedPropsArray } =
    useCreateStatementAdapters()

  const { getBasePropsFromObjectAdapter, getAdvancedPropsFromObjectAdapter } =
    useGenerateValuesFromObjectAdapter({
      generateRHFObject,
      generatePropertiesObject,
      generatePrefetchedPropsArray,
    })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
    rolesOfUser,
  })

  const { handleNotifyAllSubscribersWithAdvancedProps } = useNotifyAllSubscribers({
    watcher: CreateStatementControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const { applyDifferences } = useFindFormValueDifferences({
    watcher: CreateStatementFieldArrayControlUpdateWatcher,
    setValue,
  })

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()

      if (isNull(prevRHFValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const { prefetchedIds } = await getAdvancedPropsFromObjectAdapter(props.objectForAdapter)

      const readyOverrideProps = await processOverrideProps(
        props.overridePropsFromChanges,
        prefetchedIds,
      )

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      unstable_batchedUpdates(() => {
        applyDifferences(prevRHFValue, RHFValueForReset)
        rewriteCreateStatementFieldsAfterApplyDiffs.forEach((rewriteField) => {
          const valueToApply = getObjectValue(RHFValueForReset, rewriteField)

          if (
            isNumber(valueToApply) ||
            isString(valueToApply) ||
            isArray(valueToApply) ||
            isSelectOption(valueToApply)
          ) {
            setValue(rewriteField, valueToApply)
          }
        })

        updateLastRFHBeforeValue(RHFValueForReset)

        setTimeout(() => {
          CreateStatementControlUpdateWatcher.notifyAllSubscribers(readyOverrideProps)
        }, 0)

        onSetLastUpdateFormToNow?.()
      })
    },
    [
      propertiesPropsRef,
      lastFieldUpdateTime,
      setValue,
      applyDifferences,
      getAdvancedPropsFromObjectAdapter,
      getBasePropsFromObjectAdapter,
      getLastRHFBeforeValue,
      onSetLastUpdateFormToNow,
      updateLastRFHBeforeValue,
      processOverrideProps,
    ],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const wrappedHandleApplyFormChanges = useMemo(
    () => applyFormChanges(handleApplyChanges),
    [applyFormChanges, handleApplyChanges],
  )

  const {
    handleAddItemToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    handleChangeValue,
    debouncedHandleChangeValue,
  } = useFormUpdate({
    formId,
    getPropertiesProps,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: wrappedHandleApplyFormChanges,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const { handleCheckForm, handlePreviewForm } = useFormActions({
    formId,
    watcher: CreateStatementControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    handleApplyChanges: checkFormChanges,
    onClearError: clearErrors,
  })

  const handleSuccessInitializeForm = useCallback(
    async ({ propertyInstance, lastChangesDt }: SuccessInitialCallbackReturn) => {
      lastFieldUpdateTime.current = lastChangesDt

      propertyInstanceRef.current = propertyInstance

      const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

      const RHFValueForReset = generateRHFObject(objectForAdapters)

      const propertiesProps = generatePropertiesObject(objectForAdapters)

      //Установка propertiesProps
      propertiesPropsRef.current = cloneDeep(propertiesProps)

      resetForm(RHFValueForReset)
      updateLastRFHBeforeValue(RHFValueForReset)
    },
    [
      propertyInstanceRef,
      propertiesPropsRef,
      lastFieldUpdateTime,
      resetForm,
      updateLastRFHBeforeValue,
      generatePropertiesObject,
      generateRHFObject,
    ],
  )

  const handleAfterInitializeForm = useMemo(
    () => handleNotifyAllSubscribersWithAdvancedProps(getAdvancedPropsFromObjectAdapter),
    [getAdvancedPropsFromObjectAdapter, handleNotifyAllSubscribersWithAdvancedProps],
  )

  //Инициализация формы
  useEffect(() => {
    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleAfterInitializeForm,
    })
  }, [])

  const preparedValue: FormModalContextProps<StatementFormValues> = useMemo(
    () => ({
      state: {
        formIsLoading,
        blockViewIsValidating,
        initialErrorsFromViolations,
        editMode,
      },
      handlers: {
        getPropertiesProps,
        getOverrideProps,
        checkFormChanges,
        handleChangeValue,
        debouncedHandleChangeValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        handleAddItemToListWithOutValue,
        handleAddItemToListWithValue,
        handleRemoveItemFromList,
        applyFormChanges: wrappedHandleApplyFormChanges,
        handleChangeBlockValidation,
      },
      actions: {
        handleFormClose: onClose,
        handleCheckForm,
        handlePreviewForm,
      },
      preparedProps: {
        subscribableControl,
      },
    }),
    [
      formIsLoading,
      blockViewIsValidating,
      initialErrorsFromViolations,
      editMode,
      getPropertiesProps,
      getOverrideProps,
      checkFormChanges,
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddCustomValue,
      handleRemoveCustomValue,
      handleAddItemToListWithOutValue,
      handleAddItemToListWithValue,
      handleRemoveItemFromList,
      handleChangeBlockValidation,
      onClose,
      handleCheckForm,
      handlePreviewForm,
      subscribableControl,
      wrappedHandleApplyFormChanges,
    ],
  )

  return (
    <CreateStatementModalContext.Provider value={preparedValue}>
      {children}
    </CreateStatementModalContext.Provider>
  )
}

const useCreateStatementManager = () => {
  return useContext(CreateStatementModalContext)
}

export { useCreateStatementManager }

export default CreateStatementModalManager
