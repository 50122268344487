import { FC } from 'react'

import { CountdownCircleTimer } from '@components/Countdown/Countdown'
import Typography from '@components/NewDesign/Typography'

import styles from './Organizations.module.scss'

interface IBlockCell {
  onEndTimer: () => void
  onCancelDeleting: () => void
}

export const BlockCell: FC<IBlockCell> = ({ onEndTimer, onCancelDeleting }) => {
  return (
    <div className={styles.blockCell}>
      <CountdownCircleTimer
        isPlaying
        duration={5}
        size={17}
        trailColor={'rgba(224, 70, 75, 0.1)'} // accent-error-faint-quaternary
        strokeWidth={1}
        colors={'#E83040'} // text-accent-error-secondary
        onComplete={onEndTimer}
      >
        {({ remainingTime }) => (
          <Typography color={'text-accent-error-secondary'} variant={'bodySMedium'}>
            {remainingTime}
          </Typography>
        )}
      </CountdownCircleTimer>
      <button type="button" className={styles.blockCellText} onClick={onCancelDeleting}>
        ОТМЕНИТЬ
      </button>
    </div>
  )
}
