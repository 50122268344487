import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { CertificateOfComplianceContractForRecognitionConcludedAFormValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/types'

const { getDefaultUOFieldState, getDefaultInfoAboutDocument, getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfCertificateOfComplianceContractForRecognitionConcludedAMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченная организация' },
  { id: '3', title: 'Организация, реализующая проект' },
]

const defaultCertificateOfComplianceContractForRecognitionConcludedAFormValues: CertificateOfComplianceContractForRecognitionConcludedAFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': getDefaultUOFieldState(),
    '3': {
      ...getDefaultInvestorFieldState(),
      investorFullNameInstrumental: '',
      investorAddress: '',
      investorInn: '',
      investorOgrn: '',
    },
  }

const certificateOfComplianceContractForRecognitionConcludedABlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    uoFullName: '2.uoFullName',
    uoHeaderName: '2.uoHeaderName',
    uoHeaderPosition: '2.uoHeaderPosition',
    uoHeaderNameGenitive: '2.uoHeaderNameGenitive',
    uoHeaderPositionGenitive: '2.uoHeaderPositionGenitive',
    uoHeaderReasonGenitive: '2.uoHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameInstrumental: '3.investorFullNameInstrumental',
    investorAddress: '3.investorAddress',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
  },
} as const

export {
  certificateOfComplianceContractForRecognitionConcludedABlockValues,
  defaultCertificateOfComplianceContractForRecognitionConcludedAFormValues,
  mapOfCertificateOfComplianceContractForRecognitionConcludedAMenu,
}
