import MqList from './mq.json'

type AvailableMediaQueries = keyof typeof MqList

type QueriesPool = Record<string, MediaQueryList>

type Counters = Record<string, number>

const pool: QueriesPool = {}
const refCounters: Counters = {}

export function getMatchMedia(queryProp: string): MediaQueryList {
  const query = MqList[queryProp as AvailableMediaQueries] || queryProp

  if (pool[query]) {
    refCounters[query] += 1
  } else {
    pool[query] = window.matchMedia(query)
    refCounters[query] = 1
  }

  return pool[query]
}

export function releaseMatchMedia(queryProp: string): void {
  const query = MqList[queryProp as AvailableMediaQueries] || queryProp

  refCounters[query] -= 1

  if (pool[query] && refCounters[query] === 0) {
    delete pool[query]
    delete refCounters[query]
  }
}
