import React, { ButtonHTMLAttributes, forwardRef, useRef } from 'react'

import { mergeRefs } from '@helpers/ref/mergeRefs'
import { useFocus } from '@hooks/useFocus'
import cn from 'classnames'

import styles from './Tag.module.scss'

type NativeProps = ButtonHTMLAttributes<HTMLButtonElement>

export type TagItemProps = Omit<NativeProps, 'onClick'> & {
  /**
   * Отображение кнопки в отмеченном (зажатом) состоянии
   */
  checked?: boolean

  /**
   * Обработчик нажатия
   */
  onClick?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    payload?: {
      checked: boolean
      name?: string
    },
  ) => void
}

const TagItem = forwardRef<HTMLButtonElement, TagItemProps>(
  ({ children, checked, className, name, onClick, ...restProps }, ref) => {
    const tagRef = useRef<HTMLButtonElement>(null)

    const [focused] = useFocus(tagRef, 'keyboard')

    const tagProps = {
      className: cn(
        styles.component,
        {
          [styles.checked]: checked,
          [styles.focused]: focused,
        },
        className,
      ),
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        onClick(event, { name, checked: !checked })
      }
    }

    return (
      <button
        ref={mergeRefs([tagRef, ref])}
        type="button"
        onClick={handleClick}
        {...tagProps}
        {...restProps}
      >
        {children && <span>{children}</span>}
      </button>
    )
  },
)

export default TagItem
