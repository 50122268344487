import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { IConstructionStage, ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const eExploitationProjectSZPKValidationMap: FormValuesValidationSection<
  keyof IConstructionStage['exploitation'],
  ProjectSZPKFormValues
> = {
  objectCommissioningEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { eExploitationProjectSZPKValidationMap }
