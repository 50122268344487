import { FC, memo } from 'react'

import Typography from '@components/NewDesign/Typography'
import env from '@config'
import cn from 'classnames'

import styles from './MunicipalityOptionsTailNode.module.scss'

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

interface MunicipalityOptionsTailNodeProps {
  municipalityOptionsLength: number
}

const MunicipalityOptionsTailNode: FC<MunicipalityOptionsTailNodeProps> = ({
  municipalityOptionsLength,
}) => {
  return (
    <Typography.Caption
      variant="captionMMedium"
      className={cn({
        [styles.municipality__caption]: municipalityOptionsLength,
        [styles.municipality__caption_zero]: !municipalityOptionsLength,
      })}
    >
      Если Вы не нашли муниципальное образование, возможно, оно еще не добавлено. Обратитесь в орган
      местного самоуправления для уточнения регистрации в ГИС «Капиталовложения»
    </Typography.Caption>
  )
}

export default memo(MunicipalityOptionsTailNode)
