import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'

import { NewDfosType } from '@constants/types'
import { isString } from '@helpers/checkTypes'
import { useDfoDocuments } from '@hooks/new/swr/useDfoDocuments'
import { useDocumentsSetDictTypes } from '@hooks/new/swr/useDocumentsSetDictTypes'
import { useProject } from '@hooks/new/swr/useProject'
import { IDocumentsList } from '@interfaces/documents'
import { DEFAULT_SYSTEM_VERSION } from '@services/Dfo/Dfo.const'
import { IDfoListItem, TGetAllDocumentsOfDfo } from '@services/Dfo/Dfo.entity'
import { IModifiedDocumentSetInfoForRenderToArray } from '@services/Documents/documents.entity'
import DocumentsService from '@services/Documents/documents.service'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import LoggerServiceHelpers from '@services/LoggerService/LoggerService.helpers'
import { SWRConfiguration } from 'swr/dist/types'

export interface UseDfoDocumentSetsProps {
  dfo: IDfoListItem | null
  dfoConfig?: SWRConfiguration<TGetAllDocumentsOfDfo | undefined>
}

const DocumentsUtils = DocumentsService

const useDfoDocumentSets = ({ dfo, dfoConfig }: UseDfoDocumentSetsProps) => {
  const { projectId } = useParams()
  const { project } = useProject({
    key: { projectId: projectId, _key: 'project' },
    config: {
      isPaused: () => !projectId,
    },
  })

  const {
    dfoDocuments,
    isLoadingDfoDocuments,
    error: documentsError,
    mutate: updateCurrentDocumentSets,
  } = useDfoDocuments({
    key: { dfoId: dfo?.id, projectId, _key: 'document-sets' },
    config: {
      isPaused: () => !projectId || !dfo?.id,
      ...dfoConfig,
    },
  })

  const { documentsSetsDict, isLoadingDocumentsSetsDict } = useDocumentsSetDictTypes({
    key: {
      _key: 'documentsSetsDictTypes',
    },
    config: {
      isPaused: () => !dfo,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useDfoDocumentSets',
          componentType: 'hook',
        },
      }),
    },
  })

  const preparedSets = useMemo(() => {
    if (!documentsSetsDict || !dfo || !dfoDocuments) return

    const currentDocumentSetsDict = documentsSetsDict[dfo.systemVersion ?? DEFAULT_SYSTEM_VERSION]

    try {
      return DocumentsUtils.prepareDocumentsSetFromDictAndDocumentsResponse({
        dfo,
        documents: dfoDocuments,
        dictOfDocuments: currentDocumentSetsDict,
        isFederal: !!project?.isFederal,
      })
    } catch (error) {
      LoggerServiceHelpers.handleMultipleLogError({
        componentInfo: {
          componentName: 'useDfoDocumentSets',
          componentType: 'preparedSets',
        },
        additionInfo: {
          dfo,
          documents: dfoDocuments,
          dictOfDocuments: documentsSetsDict,
          isFederal: !!project?.isFederal,
        },
      })(error)

      throw error
    }
  }, [dfo, dfoDocuments, documentsSetsDict, project?.isFederal])

  const mapOfSetsToRender = useMemo(() => {
    if (!preparedSets) return

    try {
      return DocumentsUtils.preparedSetOfDocumentsToArray({
        mapOfSets: preparedSets,
        dfoType: NewDfosType.SZPK,
      })
    } catch (error) {
      LoggerServiceHelpers.handleMultipleLogError({
        componentInfo: {
          componentName: 'useDfoDocumentSets',
          componentType: 'mapOfSetsToRender',
        },
        additionInfo: {
          mapOfSets: preparedSets,
          dfoType: NewDfosType.SZPK,
        },
      })(error)
      throw error
    }
  }, [preparedSets])

  /**
   * Вытаскиваем из отображаемый сетов документы
   */
  const extractDocumentFromSets = useCallback(
    (mapOfSets: Array<IModifiedDocumentSetInfoForRenderToArray> | undefined) => {
      return mapOfSets?.reduce<IDocumentsList>((documentsList, setToRender) => {
        const notSystemDocumentSets = setToRender.documentSets.filter(
          (set) => !set.documentSetIsSystem,
        )

        const documentsTypeInfoFromVisibleSets = notSystemDocumentSets?.reduce(
          (documentsInfo, set) => {
            const visibleDocumentsFromSet = set.documentTypeInfo?.reduce(
              (visibleDocuments, documentTypeInfo) => {
                const isDocVisible =
                  documentTypeInfo.documentInfoFromDict.isVisible &&
                  isString(documentTypeInfo.description)

                return isDocVisible
                  ? {
                      ...visibleDocuments,
                      [documentTypeInfo.description as string]: documentTypeInfo,
                    }
                  : visibleDocuments
              },
              {},
            )

            return { ...documentsInfo, ...visibleDocumentsFromSet }
          },
          {},
        )

        return { ...documentsList, ...documentsTypeInfoFromVisibleSets }
      }, {} as IDocumentsList)
    },
    [],
  )

  const documentsList = useMemo(
    () => extractDocumentFromSets(mapOfSetsToRender),
    [extractDocumentFromSets, mapOfSetsToRender],
  )

  return {
    updateDocumentSets: updateCurrentDocumentSets,
    extractDocumentFromSets,
    mapOfSetsToRender,
    preparedSets,
    documentsList,
    setsIsLoading: isLoadingDfoDocuments,
    isLoadingDocumentsSetsDict,
    documentsError,
  }
}

export { useDfoDocumentSets }
