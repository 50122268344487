import { objOfDateFormats } from '@constants/dateFormats'
import DayjsService from '@services/Dayjs/Dayjs.service'

// в формате: № szpkRegisteredNumber от szpkRegisteredDate (dd month YYYY)
// пример: № 12345/678 от 15 сентября 2022
const buildSZPKStatus = (szpkRegisteredNumber, szpkRegisteredDate: Date) =>
  `№ ${szpkRegisteredNumber} от ${DayjsService.dayjs(szpkRegisteredDate).format(
    objOfDateFormats.formatWithFullMonthButSingleDay,
  )} `

export { buildSZPKStatus }
