import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForInformationOfExecutionConditionsSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedStepsMonitoringValue = isArray(objectFromPropertyState.stepsMonitoring?.value)
    ? objectFromPropertyState.stepsMonitoring?.value.map((step) => ({
        propertyId: step.propertyId,
        type: step.type,
        lastUpdateDt: step.lastUpdateDt,
        value: {
          planStepTotalCapitalValue: {
            propertyId: step.value.planStepTotalCapitalValue?.propertyId,
            lastUpdateDt: step.value.planStepTotalCapitalValue?.lastUpdateDt,
            type: step.value.planStepTotalCapitalValue?.type,
          },
          planStepTotalCapitalVatValue: {
            propertyId: step.value.planStepTotalCapitalVatValue?.propertyId,
            lastUpdateDt: step.value.planStepTotalCapitalVatValue?.lastUpdateDt,
            type: step.value.planStepTotalCapitalVatValue?.type,
          },
          factStepTotalCapitalValue: {
            propertyId: step.value.factStepTotalCapitalValue?.propertyId,
            lastUpdateDt: step.value.factStepTotalCapitalValue?.lastUpdateDt,
            type: step.value.factStepTotalCapitalValue?.type,
          },
          factStepTotalCapitalVatValue: {
            propertyId: step.value.factStepTotalCapitalVatValue?.propertyId,
            lastUpdateDt: step.value.factStepTotalCapitalVatValue?.lastUpdateDt,
            type: step.value.factStepTotalCapitalVatValue?.type,
          },
          factStepInvestorCapitalValue: {
            propertyId: step.value.factStepInvestorCapitalValue?.propertyId,
            lastUpdateDt: step.value.factStepInvestorCapitalValue?.lastUpdateDt,
            type: step.value.factStepInvestorCapitalValue?.type,
          },
          factStepInvestorCapitalVatValue: {
            propertyId: step.value.factStepInvestorCapitalVatValue?.propertyId,
            lastUpdateDt: step.value.factStepInvestorCapitalVatValue?.lastUpdateDt,
            type: step.value.factStepInvestorCapitalVatValue?.type,
          },
          factStepCompensationValue: {
            propertyId: step.value.factStepCompensationValue?.propertyId,
            lastUpdateDt: step.value.factStepCompensationValue?.lastUpdateDt,
            type: step.value.factStepCompensationValue?.type,
          },
          factStepCompensationValueVat: {
            propertyId: step.value.factStepCompensationVatValue?.propertyId,
            lastUpdateDt: step.value.factStepCompensationVatValue?.lastUpdateDt,
            type: step.value.factStepCompensationVatValue?.type,
          },
          factStepCompensationPeriod: {
            propertyId: step.value.factStepCompensationPeriod?.propertyId,
            lastUpdateDt: step.value.factStepCompensationPeriod?.lastUpdateDt,
            type: step.value.factStepCompensationPeriod?.type,
          },
          factStepCompensationEndPeriod: {
            propertyId: step.value.factStepCompensationEndPeriod?.propertyId,
            lastUpdateDt: step.value.factStepCompensationEndPeriod?.lastUpdateDt,
            type: step.value.factStepCompensationEndPeriod?.type,
          },
          planStepWorkPlace: {
            propertyId: step.value.planStepWorkPlace?.propertyId,
            lastUpdateDt: step.value.planStepWorkPlace?.lastUpdateDt,
            type: step.value.planStepWorkPlace?.type,
          },
          factStepWorkPlace: {
            propertyId: step.value.factStepWorkPlace?.propertyId,
            lastUpdateDt: step.value.factStepWorkPlace?.lastUpdateDt,
            type: step.value.factStepWorkPlace?.type,
          },
          factStepStartDate: {
            propertyId: step.value.factStepStartDate?.propertyId,
            lastUpdateDt: step.value.factStepStartDate?.lastUpdateDt,
            type: step.value.factStepStartDate?.type,
          },
          factStepEndDate: {
            propertyId: step.value.factStepEndDate?.propertyId,
            lastUpdateDt: step.value.factStepEndDate?.lastUpdateDt,
            type: step.value.factStepEndDate?.type,
          },
          stepInfoInvestor: {
            propertyId: step.value.stepInfoInvestor?.propertyId,
            lastUpdateDt: step.value.stepInfoInvestor?.lastUpdateDt,
            type: step.value.stepInfoInvestor?.type,
          },
        },
      }))
    : []

  const preparedCreditsValue = isArray(objectFromPropertyState.percentCredits?.value)
    ? objectFromPropertyState.percentCredits?.value.map((credit) => ({
        propertyId: credit.propertyId,
        type: credit.type,
        lastUpdateDt: credit.lastUpdateDt,
        value: {
          percentCreditName: {
            propertyId: credit.value.percentCreditName.propertyId,
            type: credit.value.percentCreditName.type,
            lastUpdateDt: credit.value.percentCreditName.lastUpdateDt,
          },
          percentCreditDate: {
            propertyId: credit.value.percentCreditDate.propertyId,
            type: credit.value.percentCreditDate.type,
            lastUpdateDt: credit.value.percentCreditDate.lastUpdateDt,
          },
          percentCreditNumber: {
            propertyId: credit.value.percentCreditNumber.propertyId,
            type: credit.value.percentCreditNumber.type,
            lastUpdateDt: credit.value.percentCreditNumber.lastUpdateDt,
          },
          percentCreditMembers: {
            propertyId: credit.value.percentCreditMembers.propertyId,
            type: credit.value.percentCreditMembers.type,
            lastUpdateDt: credit.value.percentCreditMembers.lastUpdateDt,
            value: isArray(credit.value.percentCreditMembers?.value)
              ? credit.value.percentCreditMembers?.value.map((member) => ({
                  propertyId: member.propertyId,
                  type: member.type,
                  lastUpdateDt: member.lastUpdateDt,
                  value: {
                    percentCreditMember: {
                      propertyId: member.propertyId,
                      type: member.type,
                      lastUpdateDt: member.lastUpdateDt,
                    },
                  },
                }))
              : [],
          },
        },
      }))
    : []

  const preparedCompensationsMonitoringValue = (() => {
    if (
      !isArray(objectFromPropertyState.compensationsMonitoring?.value) ||
      !objectFromPropertyState.compensationsMonitoring?.value.length
    )
      return []

    return objectFromPropertyState.compensationsMonitoring?.value.map((compensation) => ({
      propertyId: compensation.propertyId,
      type: compensation.type,
      lastUpdateDt: compensation.lastUpdateDt,
      value: {
        compensationYear: {
          propertyId: compensation.value.compensationYear.propertyId,
          type: compensation.value.compensationYear.type,
          lastUpdateDt: compensation.value.compensationYear.lastUpdateDt,
        },

        compensationRelatedInfrastructure: {
          propertyId: compensation.value.compensationsRelatedInfrastructure?.propertyId,
          type: compensation.value.compensationsRelatedInfrastructure?.type,
          lastUpdateDt: compensation.value.compensationsRelatedInfrastructure?.lastUpdateDt,
          value: isArray(compensation.value.compensationsRelatedInfrastructure?.value)
            ? compensation.value.compensationsRelatedInfrastructure?.value.map(
                (compensationRelated) => ({
                  propertyId: compensationRelated.propertyId,
                  type: compensationRelated.type,
                  lastUpdateDt: compensationRelated.lastUpdateDt,
                  value: {
                    relatedInfrastructureObjectName: {
                      propertyId:
                        compensationRelated.value.relatedInfrastructureObject.value.objectName
                          .propertyId,
                      type: compensationRelated.value.relatedInfrastructureObject.value.objectName
                        .type,
                      lastUpdateDt:
                        compensationRelated.value.relatedInfrastructureObject.value.objectName
                          .lastUpdateDt,
                    },
                    relatedInfrastructureObjectValue: {
                      propertyId:
                        compensationRelated.value.relatedInfrastructureObjectValue.propertyId,
                      type: compensationRelated.value.relatedInfrastructureObjectValue.type,
                      lastUpdateDt:
                        compensationRelated.value.relatedInfrastructureObjectValue.lastUpdateDt,
                    },
                  },
                }),
              )
            : [],
        },

        generalCompensationRelatedInfrastructure: {
          propertyId: compensation.value.generalCompensationRelatedInfrastructure.propertyId,
          type: compensation.value.generalCompensationRelatedInfrastructure.type,
          lastUpdateDt: compensation.value.generalCompensationRelatedInfrastructure.lastUpdateDt,
        },

        compensationSupportingInfrastructure: {
          propertyId: compensation.value.compensationsSupportingInfrastructure?.propertyId,
          type: compensation.value.compensationsSupportingInfrastructure?.type,
          lastUpdateDt: compensation.value.compensationsSupportingInfrastructure?.lastUpdateDt,
          value: isArray(compensation.value.compensationsSupportingInfrastructure?.value)
            ? compensation.value.compensationsSupportingInfrastructure?.value.map(
                (compensationSupporting) => ({
                  propertyId: compensationSupporting.propertyId,
                  type: compensationSupporting.type,
                  lastUpdateDt: compensationSupporting.lastUpdateDt,
                  value: {
                    supportingInfrastructureObjectName: {
                      propertyId:
                        compensationSupporting.value.supportingInfrastructureObject.value.objectName
                          .propertyId,
                      type: compensationSupporting.value.supportingInfrastructureObject.value
                        .objectName.type,
                      lastUpdateDt:
                        compensationSupporting.value.supportingInfrastructureObject.value.objectName
                          .lastUpdateDt,
                    },
                    supportingInfrastructureObjectValue: {
                      propertyId:
                        compensationSupporting.value.supportingInfrastructureObjectValue.propertyId,
                      type: compensationSupporting.value.supportingInfrastructureObjectValue.type,
                      lastUpdateDt:
                        compensationSupporting.value.supportingInfrastructureObjectValue
                          .lastUpdateDt,
                    },
                  },
                }),
              )
            : [],
        },

        generalCompensationSupportingInfrastructure: {
          propertyId: compensation.value.generalCompensationSupportingInfrastructure.propertyId,
          type: compensation.value.generalCompensationSupportingInfrastructure.type,
          lastUpdateDt: compensation.value.generalCompensationSupportingInfrastructure.lastUpdateDt,
        },

        compensationPercentCredit: {
          propertyId: compensation.value.compensationsPercentCredit?.propertyId,
          type: compensation.value.compensationsPercentCredit?.type,
          lastUpdateDt: compensation.value.compensationsPercentCredit?.lastUpdateDt,
          value: isArray(compensation.value.compensationsPercentCredit?.value)
            ? compensation.value.compensationsPercentCredit?.value.map((compensationCredit) => ({
                propertyId: compensationCredit.propertyId,
                type: compensationCredit.type,
                lastUpdateDt: compensationCredit.lastUpdateDt,
                value: {
                  percentCreditFullName: {
                    propertyId: compensationCredit.value.compensationPercentCreditId.propertyId,
                    type: compensationCredit.value.compensationPercentCreditId.type,
                    lastUpdateDt: compensationCredit.value.compensationPercentCreditId.lastUpdateDt,
                  },
                  compensationRelatedContractValue: {
                    propertyId:
                      compensationCredit.value.compensationRelatedContractValue.propertyId,
                    type: compensationCredit.value.compensationRelatedContractValue.type,
                    lastUpdateDt:
                      compensationCredit.value.compensationRelatedContractValue.lastUpdateDt,
                  },
                },
              }))
            : [],
        },

        generalCompensationsPercentCredit: {
          propertyId: compensation.value.generalCompensationsPercentCredit.propertyId,
          type: compensation.value.generalCompensationsPercentCredit.type,
          lastUpdateDt: compensation.value.generalCompensationsPercentCredit.lastUpdateDt,
        },

        compensationDismantling: {
          propertyId: compensation.value.compensationsDismantling?.propertyId,
          type: compensation.value.compensationsDismantling?.type,
          lastUpdateDt: compensation.value.compensationsDismantling?.lastUpdateDt,
          value: isArray(compensation.value.compensationsDismantling?.value)
            ? compensation.value.compensationsDismantling?.value.map((compensationDismantling) => ({
                propertyId: compensationDismantling.propertyId,
                type: compensationDismantling.type,
                lastUpdateDt: compensationDismantling.lastUpdateDt,
                value: {
                  dismantlingObjectName: {
                    propertyId: compensationDismantling.value.dismantlingObjectId.value.propertyId,
                    type: compensationDismantling.value.dismantlingObjectId.value.type,
                    lastUpdateDt:
                      compensationDismantling.value.dismantlingObjectId.value.lastUpdateDt,
                  },
                  dismantlingObjectValue: {
                    propertyId: compensationDismantling.value.dismantlingObjectValue.propertyId,
                    type: compensationDismantling.value.dismantlingObjectValue.type,
                    lastUpdateDt: compensationDismantling.value.dismantlingObjectValue.lastUpdateDt,
                  },
                },
              }))
            : [],
        },

        generalCompensationsDismantling: {
          propertyId: compensation.value.generalCompensationsDismantling.propertyId,
          type: compensation.value.generalCompensationsDismantling.type,
          lastUpdateDt: compensation.value.generalCompensationsDismantling.lastUpdateDt,
        },

        compensationExpense: {
          propertyId: compensation.value.compensationsExpenses?.propertyId,
          type: compensation.value.compensationsExpenses?.type,
          lastUpdateDt: compensation.value.compensationsExpenses?.lastUpdateDt,
          value: isArray(compensation.value.compensationsExpenses?.value)
            ? compensation.value.compensationsExpenses?.value.map((compensationExpenses) => ({
                propertyId: compensationExpenses.propertyId,
                type: compensationExpenses.type,
                lastUpdateDt: compensationExpenses.lastUpdateDt,
                value: {
                  expenseName: {
                    propertyId: compensationExpenses.value.expenseId.value.propertyId,
                    type: compensationExpenses.value.expenseId.value.type,
                    lastUpdateDt: compensationExpenses.value.expenseId.value.lastUpdateDt,
                  },
                  expenseValue: {
                    propertyId: compensationExpenses.value.expenseValue.propertyId,
                    type: compensationExpenses.value.expenseValue.type,
                    lastUpdateDt: compensationExpenses.value.expenseValue.lastUpdateDt,
                  },
                },
              }))
            : [],
        },

        generalCompensationsExpenses: {
          propertyId: compensation.value.generalCompensationsExpenses.propertyId,
          type: compensation.value.generalCompensationsExpenses.type,
          lastUpdateDt: compensation.value.generalCompensationsExpenses.lastUpdateDt,
        },
        compensationSubsidyFormat: {
          propertyId: compensation.value.compensationSubsidyFormat.propertyId,
          type: compensation.value.compensationSubsidyFormat.type,
          lastUpdateDt: compensation.value.compensationSubsidyFormat.lastUpdateDt,
        },
        compensationTaxDeductionFormat: {
          propertyId: compensation.value.compensationTaxDeductionFormat.propertyId,
          type: compensation.value.compensationTaxDeductionFormat.type,
          lastUpdateDt: compensation.value.compensationTaxDeductionFormat.lastUpdateDt,
        },
        compensationDamageFormat: {
          propertyId: compensation.value.compensationDamageFormat.propertyId,
          type: compensation.value.compensationDamageFormat.type,
          lastUpdateDt: compensation.value.compensationDamageFormat.lastUpdateDt,
        },
        generalCompensation: {
          propertyId: compensation.value.generalCompensation.propertyId,
          type: compensation.value.generalCompensation.type,
          lastUpdateDt: compensation.value.generalCompensation.lastUpdateDt,
        },
      },
    }))
  })()

  const preparedCompensationMonitoringCheckerValue = (() => {
    if (
      !isArray(objectFromPropertyState.compensationsMonitoring?.value) ||
      !objectFromPropertyState.compensationsMonitoring?.value.length
    )
      return []

    return objectFromPropertyState.compensationsMonitoring?.value.map((compensation) => ({
      propertyId: compensation.propertyId,
      type: compensation.type,
      lastUpdateDt: compensation.lastUpdateDt,
      value: {
        compensationYear: {
          propertyId: compensation.value.compensationYear.propertyId,
          type: compensation.value.compensationYear.type,
          lastUpdateDt: compensation.value.compensationYear.lastUpdateDt,
        },
        incomeTaxFederal: {
          propertyId: compensation.value.incomeTaxFederal.propertyId,
          type: compensation.value.incomeTaxFederal.type,
          lastUpdateDt: compensation.value.incomeTaxFederal.lastUpdateDt,
        },
        incomeTaxRegional: {
          propertyId: compensation.value.incomeTaxRegional.propertyId,
          type: compensation.value.incomeTaxRegional.type,
          lastUpdateDt: compensation.value.incomeTaxRegional.lastUpdateDt,
        },
        propertyTax: {
          propertyId: compensation.value.propertyTax.propertyId,
          type: compensation.value.propertyTax.type,
          lastUpdateDt: compensation.value.propertyTax.lastUpdateDt,
        },
        landTax: {
          propertyId: compensation.value.landTax.propertyId,
          type: compensation.value.landTax.type,
          lastUpdateDt: compensation.value.landTax.lastUpdateDt,
        },
        vatTax: {
          propertyId: compensation.value.vatTax.propertyId,
          type: compensation.value.vatTax.type,
          lastUpdateDt: compensation.value.vatTax.lastUpdateDt,
        },
        customDuty: {
          propertyId: compensation.value.customDuty.propertyId,
          type: compensation.value.customDuty.type,
          lastUpdateDt: compensation.value.customDuty.lastUpdateDt,
        },
        generalTaxValue: {
          propertyId: compensation.value.generalTaxValue.propertyId,
          type: compensation.value.generalTaxValue.type,
          lastUpdateDt: compensation.value.generalTaxValue.lastUpdateDt,
        },
      },
    }))
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorInn: {
        propertyId: objectFromPropertyState.investor?.value.investorInn?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.investorInn?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.investorInn?.type,
      },
      investorOgrn: {
        propertyId: objectFromPropertyState.investor?.value.investorOgrn?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.investorOgrn?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.investorOgrn?.type,
      },
      investorAddress: {
        propertyId: objectFromPropertyState.investor?.value.investorAddress?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.investorAddress?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.investorAddress?.type,
      },
    },
    '2': {
      projectName: {
        propertyId: objectFromPropertyState?.projectName?.propertyId,
        lastUpdateDt: objectFromPropertyState?.projectName?.lastUpdateDt,
        type: objectFromPropertyState?.projectName?.type,
      },
      projectDecisionDepartament: {
        propertyId: objectFromPropertyState?.projectDecisionDepartament?.propertyId,
        lastUpdateDt: objectFromPropertyState?.projectDecisionDepartament?.lastUpdateDt,
        type: objectFromPropertyState?.projectDecisionDepartament?.type,
      },
      economicSphere: {
        propertyId: objectFromPropertyState?.economicSphere?.propertyId,
        lastUpdateDt: objectFromPropertyState?.economicSphere?.lastUpdateDt,
        type: objectFromPropertyState?.economicSphere?.type,
      },
      projectOkved: {
        propertyId: objectFromPropertyState.projectOkvedMonitoring?.propertyId,
        lastUpdateDt: objectFromPropertyState.projectOkvedMonitoring?.lastUpdateDt,
        type: objectFromPropertyState.projectOkvedMonitoring?.type,
      },
      szpkRegistrationDate: {
        propertyId: objectFromPropertyState?.szpkRegistrationDate?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkRegistrationDate?.lastUpdateDt,
        type: objectFromPropertyState?.szpkRegistrationDate?.type,
      },
      startPreinvestStage: {
        propertyId: objectFromPropertyState?.startPreinvestStage?.propertyId,
        lastUpdateDt: objectFromPropertyState?.startPreinvestStage?.lastUpdateDt,
        type: objectFromPropertyState?.startPreinvestStage?.type,
      },
      endPreinvestStage: {
        propertyId: objectFromPropertyState?.endPreinvestStage?.propertyId,
        lastUpdateDt: objectFromPropertyState?.endPreinvestStage?.lastUpdateDt,
        type: objectFromPropertyState?.endPreinvestStage?.type,
      },
      startInvestStage: {
        propertyId: objectFromPropertyState?.startInvestStage?.propertyId,
        lastUpdateDt: objectFromPropertyState?.startInvestStage?.lastUpdateDt,
        type: objectFromPropertyState?.startInvestStage?.type,
      },
      endInvestStage: {
        propertyId: objectFromPropertyState?.endInvestStage?.propertyId,
        lastUpdateDt: objectFromPropertyState?.endInvestStage?.lastUpdateDt,
        type: objectFromPropertyState?.endInvestStage?.type,
      },
      startExploitationStage: {
        propertyId: objectFromPropertyState?.startExploitationStage?.propertyId,
        lastUpdateDt: objectFromPropertyState?.startExploitationStage?.lastUpdateDt,
        type: objectFromPropertyState?.startExploitationStage?.type,
      },
      endExploitationStage: {
        propertyId: objectFromPropertyState?.endExploitationStage?.propertyId,
        lastUpdateDt: objectFromPropertyState?.endExploitationStage?.lastUpdateDt,
        type: objectFromPropertyState?.endExploitationStage?.type,
      },
      isVatMoney: {
        propertyId: objectFromPropertyState?.isVatMoney?.propertyId || '',
        lastUpdateDt: objectFromPropertyState?.isVatMoney?.lastUpdateDt || '',
        type: objectFromPropertyState?.isVatMoney?.type || '',
      },
      totalCapital: {
        propertyId: objectFromPropertyState?.totalCapital?.propertyId || '',
        lastUpdateDt: objectFromPropertyState?.totalCapital?.lastUpdateDt || '',
        type: objectFromPropertyState?.totalCapital?.type || '',
      },
      totalCapitalVAT: {
        propertyId: objectFromPropertyState?.totalCapitalVAT?.propertyId || '',
        lastUpdateDt: objectFromPropertyState?.totalCapitalVAT?.lastUpdateDt || '',
        type: objectFromPropertyState?.totalCapitalVAT?.type || '',
      },

      investorCapital: {
        propertyId: objectFromPropertyState?.investorCapital?.propertyId || '',
        lastUpdateDt: objectFromPropertyState?.investorCapital?.lastUpdateDt || '',
        type: objectFromPropertyState?.investorCapital?.type || '',
      },
      investorCapitalVAT: {
        propertyId: objectFromPropertyState?.investorCapitalVAT?.propertyId || '',
        lastUpdateDt: objectFromPropertyState?.investorCapitalVAT?.lastUpdateDt || '',
        type: objectFromPropertyState?.investorCapitalVAT?.type || '',
      },

      newWorkPlaces: {
        propertyId: objectFromPropertyState?.newWorkPlaces?.propertyId,
        lastUpdateDt: objectFromPropertyState?.newWorkPlaces?.lastUpdateDt,
        type: objectFromPropertyState?.newWorkPlaces?.type,
      },
      projectResultEffect: {
        propertyId: objectFromPropertyState?.projectResultEffect?.propertyId,
        lastUpdateDt: objectFromPropertyState?.projectResultEffect?.lastUpdateDt,
        type: objectFromPropertyState?.projectResultEffect?.type,
      },
    },
    '3': {
      stepsMonitoring: {
        propertyId: objectFromPropertyState.stepsMonitoring?.propertyId,
        type: objectFromPropertyState.stepsMonitoring?.type,
        lastUpdateDt: objectFromPropertyState.stepsMonitoring?.lastUpdateDt,
        value: preparedStepsMonitoringValue,
      },
    },
    '4': {
      percentCredits: {
        propertyId: objectFromPropertyState.percentCredits?.propertyId,
        type: objectFromPropertyState.percentCredits?.type,
        lastUpdateDt: objectFromPropertyState.percentCredits?.lastUpdateDt,
        value: preparedCreditsValue,
      },
    },
    '5': {
      dismantlingObjects: {
        propertyId: objectFromPropertyState.dismantlingObjects?.propertyId,
        type: objectFromPropertyState.dismantlingObjects?.type,
        lastUpdateDt: objectFromPropertyState.dismantlingObjects?.lastUpdateDt,
        value: isArray(objectFromPropertyState.dismantlingObjects?.value)
          ? objectFromPropertyState.dismantlingObjects?.value.map((object) => ({
              propertyId: object.propertyId,
              type: object.type,
              lastUpdateDt: object.lastUpdateDt,
              value: {
                dismantlingObjectName: {
                  propertyId: object.value?.dismantlingObjectName?.propertyId,
                  lastUpdateDt: object.value?.dismantlingObjectName?.lastUpdateDt,
                  type: object.value?.dismantlingObjectName?.type,
                },
              },
            }))
          : [],
      },
    },
    '6': {
      expenses: {
        propertyId: objectFromPropertyState.expenses?.propertyId,
        type: objectFromPropertyState.expenses?.type,
        lastUpdateDt: objectFromPropertyState.expenses?.lastUpdateDt,
        value: isArray(objectFromPropertyState.expenses?.value)
          ? objectFromPropertyState.expenses?.value.map((expense) => ({
              propertyId: expense.propertyId,
              type: expense.type,
              lastUpdateDt: expense.lastUpdateDt,
              value: {
                expenseName: {
                  propertyId: expense.value?.expenseName?.propertyId,
                  lastUpdateDt: expense.value?.expenseName?.lastUpdateDt,
                  type: expense.value?.expenseName?.type,
                },
              },
            }))
          : [],
      },
    },
    '7': {
      compensationsMonitoring: {
        propertyId: objectFromPropertyState.compensationsMonitoring?.propertyId,
        type: objectFromPropertyState.compensationsMonitoring?.type,
        lastUpdateDt: objectFromPropertyState.compensationsMonitoring?.lastUpdateDt,
        value: preparedCompensationsMonitoringValue,
      },
    },
    '8': {
      compensationsMonitoringChecker: {
        propertyId: objectFromPropertyState.compensationsMonitoring?.propertyId,
        type: objectFromPropertyState.compensationsMonitoring?.type,
        lastUpdateDt: objectFromPropertyState.compensationsMonitoring?.lastUpdateDt,
        value: preparedCompensationMonitoringCheckerValue,
      },
    },
    '9': {
      generalStateSupportValue: {
        propertyId: objectFromPropertyState.generalStateSupportValue?.propertyId,
        type: objectFromPropertyState.generalStateSupportValue?.type,
        lastUpdateDt: objectFromPropertyState.generalStateSupportValue?.lastUpdateDt,
      },
      stateSupports: {
        propertyId: objectFromPropertyState.stateSupports?.propertyId,
        type: objectFromPropertyState.stateSupports?.type,
        lastUpdateDt: objectFromPropertyState.stateSupports?.lastUpdateDt,
        value: isArray(objectFromPropertyState.stateSupports?.value)
          ? objectFromPropertyState.stateSupports?.value.map((support) => ({
              propertyId: support.propertyId,
              type: support.type,
              lastUpdateDt: support.lastUpdateDt,
              value: {
                stateSupportName: {
                  propertyId: support.value.stateSupportName.propertyId,
                  lastUpdateDt: support.value.stateSupportName.lastUpdateDt,
                  type: support.value.stateSupportName.type,
                },
                stateSupportReason: {
                  propertyId: support.value.stateSupportReason.propertyId,
                  lastUpdateDt: support.value.stateSupportReason.lastUpdateDt,
                  type: support.value.stateSupportReason.type,
                },
                stateSupportValue: {
                  propertyId: support.value.stateSupportValue.propertyId,
                  lastUpdateDt: support.value.stateSupportValue.lastUpdateDt,
                  type: support.value.stateSupportValue.type,
                },
              },
            }))
          : [],
      },
    },
    '10': {
      agreementDutyReason: {
        propertyId: objectFromPropertyState.agreementDutyReason?.propertyId,
        type: objectFromPropertyState.agreementDutyReason?.type,
        lastUpdateDt: objectFromPropertyState.agreementDutyReason?.lastUpdateDt,
      },
      agreementDutyReasonDescription: {
        propertyId: objectFromPropertyState.agreementDutyReasonDescription?.propertyId,
        type: objectFromPropertyState.agreementDutyReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.agreementDutyReasonDescription?.lastUpdateDt,
      },
      changeCompensationFormatReason: {
        propertyId: objectFromPropertyState.changeCompensationFormatReason?.propertyId,
        type: objectFromPropertyState.changeCompensationFormatReason?.type,
        lastUpdateDt: objectFromPropertyState.changeCompensationFormatReason?.lastUpdateDt,
      },
      changeCompensationFormatReasonDescription: {
        propertyId: objectFromPropertyState.changeCompensationFormatReasonDescription?.propertyId,
        type: objectFromPropertyState.changeCompensationFormatReasonDescription?.type,
        lastUpdateDt:
          objectFromPropertyState.changeCompensationFormatReasonDescription?.lastUpdateDt,
      },
      changeSuggestions: {
        propertyId: objectFromPropertyState.changeSuggestions?.propertyId,
        type: objectFromPropertyState.changeSuggestions?.type,
        lastUpdateDt: objectFromPropertyState.changeSuggestions?.lastUpdateDt,
      },
      compensationChangeReason: {
        propertyId: objectFromPropertyState.compensationChangeReason?.propertyId,
        type: objectFromPropertyState.compensationChangeReason?.type,
        lastUpdateDt: objectFromPropertyState.compensationChangeReason?.lastUpdateDt,
      },
      compensationChangeReasonDescription: {
        propertyId: objectFromPropertyState.compensationChangeReasonDescription?.propertyId,
        type: objectFromPropertyState.compensationChangeReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.compensationChangeReasonDescription?.lastUpdateDt,
      },
      concessionFailReason: {
        propertyId: objectFromPropertyState.concessionFailReason?.propertyId,
        type: objectFromPropertyState.concessionFailReason?.type,
        lastUpdateDt: objectFromPropertyState.concessionFailReason?.lastUpdateDt,
      },
      concessionFailReasonDescription: {
        propertyId: objectFromPropertyState.concessionFailReasonDescription?.propertyId,
        type: objectFromPropertyState.concessionFailReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.concessionFailReasonDescription?.lastUpdateDt,
      },
      constructionChangeReason: {
        propertyId: objectFromPropertyState.constructionChangeReason?.propertyId,
        type: objectFromPropertyState.constructionChangeReason?.type,
        lastUpdateDt: objectFromPropertyState.constructionChangeReason?.lastUpdateDt,
      },
      constructionChangeReasonDescription: {
        propertyId: objectFromPropertyState.constructionChangeReasonDescription?.propertyId,
        type: objectFromPropertyState.constructionChangeReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.constructionChangeReasonDescription?.lastUpdateDt,
      },
      extendStabilizationReason: {
        propertyId: objectFromPropertyState.extendStabilizationReason?.propertyId,
        type: objectFromPropertyState.extendStabilizationReason?.type,
        lastUpdateDt: objectFromPropertyState.extendStabilizationReason?.lastUpdateDt,
      },
      extendStabilizationReasonDescription: {
        propertyId: objectFromPropertyState.extendStabilizationReasonDescription?.propertyId,
        type: objectFromPropertyState.extendStabilizationReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.extendStabilizationReasonDescription?.lastUpdateDt,
      },
      forceMajeureReason: {
        propertyId: objectFromPropertyState.forceMajeureReason?.propertyId,
        type: objectFromPropertyState.forceMajeureReason?.type,
        lastUpdateDt: objectFromPropertyState.forceMajeureReason?.lastUpdateDt,
      },
      forceMajeureReasonDescription: {
        propertyId: objectFromPropertyState.forceMajeureReasonDescription?.propertyId,
        type: objectFromPropertyState.forceMajeureReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.forceMajeureReasonDescription?.lastUpdateDt,
      },
      infoChangesReason: {
        propertyId: objectFromPropertyState.infoChangesReason?.propertyId,
        type: objectFromPropertyState.infoChangesReason?.type,
        lastUpdateDt: objectFromPropertyState.infoChangesReason?.lastUpdateDt,
      },
      infoChangesReasonDescription: {
        propertyId: objectFromPropertyState.infoChangesReasonDescription?.propertyId,
        type: objectFromPropertyState.infoChangesReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.infoChangesReasonDescription?.lastUpdateDt,
      },
      investmentChangeReason: {
        propertyId: objectFromPropertyState.investmentChangeReason?.propertyId,
        type: objectFromPropertyState.investmentChangeReason?.type,
        lastUpdateDt: objectFromPropertyState.investmentChangeReason?.lastUpdateDt,
      },
      investmentChangeReasonDescription: {
        propertyId: objectFromPropertyState.investmentChangeReasonDescription?.propertyId,
        type: objectFromPropertyState.investmentChangeReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.investmentChangeReasonDescription?.lastUpdateDt,
      },
      lawChangeReason: {
        propertyId: objectFromPropertyState.lawChangeReason?.propertyId,
        type: objectFromPropertyState.lawChangeReason?.type,
        lastUpdateDt: objectFromPropertyState.lawChangeReason?.lastUpdateDt,
      },
      lawChangeReasonDescription: {
        propertyId: objectFromPropertyState.lawChangeReasonDescription?.propertyId,
        type: objectFromPropertyState.lawChangeReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.lawChangeReasonDescription?.lastUpdateDt,
      },
      lawViolationReason: {
        propertyId: objectFromPropertyState.lawViolationReason?.propertyId,
        type: objectFromPropertyState.lawViolationReason?.type,
        lastUpdateDt: objectFromPropertyState.lawViolationReason?.lastUpdateDt,
      },
      lawViolationReasonDescription: {
        propertyId: objectFromPropertyState.lawViolationReasonDescription?.propertyId,
        type: objectFromPropertyState.lawViolationReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.lawViolationReasonDescription?.lastUpdateDt,
      },
      legalFactsReason: {
        propertyId: objectFromPropertyState.legalFactsReason?.propertyId,
        type: objectFromPropertyState.legalFactsReason?.type,
        lastUpdateDt: objectFromPropertyState.legalFactsReason?.lastUpdateDt,
      },
      legalFactsReasonDescription: {
        propertyId: objectFromPropertyState.legalFactsReasonDescription?.propertyId,
        type: objectFromPropertyState.legalFactsReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.legalFactsReasonDescription?.lastUpdateDt,
      },
      liquidationOrganisationReason: {
        propertyId: objectFromPropertyState.liquidationOrganisationReason?.propertyId,
        type: objectFromPropertyState.liquidationOrganisationReason?.type,
        lastUpdateDt: objectFromPropertyState.liquidationOrganisationReason?.lastUpdateDt,
      },
      liquidationOrganisationReasonDescription: {
        propertyId: objectFromPropertyState.liquidationOrganisationReasonDescription?.propertyId,
        type: objectFromPropertyState.liquidationOrganisationReasonDescription?.type,
        lastUpdateDt:
          objectFromPropertyState.liquidationOrganisationReasonDescription?.lastUpdateDt,
      },
      municipalAccessionReason: {
        propertyId: objectFromPropertyState.municipalAccessionReason?.propertyId,
        type: objectFromPropertyState.municipalAccessionReason?.type,
        lastUpdateDt: objectFromPropertyState.municipalAccessionReason?.lastUpdateDt,
      },
      municipalAccessionReasonDescription: {
        propertyId: objectFromPropertyState.municipalAccessionReasonDescription?.propertyId,
        type: objectFromPropertyState.municipalAccessionReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.municipalAccessionReasonDescription?.lastUpdateDt,
      },
      noMadeInvestorCapitalReason: {
        propertyId: objectFromPropertyState.noMadeInvestorCapitalReason?.propertyId,
        type: objectFromPropertyState.noMadeInvestorCapitalReason?.type,
        lastUpdateDt: objectFromPropertyState.noMadeInvestorCapitalReason?.lastUpdateDt,
      },
      noMadeInvestorCapitalReasonDescription: {
        propertyId: objectFromPropertyState.noMadeInvestorCapitalReasonDescription?.propertyId,
        type: objectFromPropertyState.noMadeInvestorCapitalReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.noMadeInvestorCapitalReasonDescription?.lastUpdateDt,
      },
      otherReason: {
        propertyId: objectFromPropertyState.otherReason?.propertyId,
        type: objectFromPropertyState.otherReason?.type,
        lastUpdateDt: objectFromPropertyState.otherReason?.lastUpdateDt,
      },
      otherReasonDescription: {
        propertyId: objectFromPropertyState.otherReasonDescription?.propertyId,
        type: objectFromPropertyState.otherReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.otherReasonDescription?.lastUpdateDt,
      },
      otherReasonName: {
        propertyId: objectFromPropertyState.otherReasonName?.propertyId,
        type: objectFromPropertyState.otherReasonName?.type,
        lastUpdateDt: objectFromPropertyState.otherReasonName?.lastUpdateDt,
      },
      relatedContractReason: {
        propertyId: objectFromPropertyState.relatedContractReason?.propertyId,
        type: objectFromPropertyState.relatedContractReason?.type,
        lastUpdateDt: objectFromPropertyState.relatedContractReason?.lastUpdateDt,
      },
      relatedContractReasonDescription: {
        propertyId: objectFromPropertyState.relatedContractReasonDescription?.propertyId,
        type: objectFromPropertyState.relatedContractReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.relatedContractReasonDescription?.lastUpdateDt,
      },
      rightTransferReason: {
        propertyId: objectFromPropertyState.rightTransferReason?.propertyId,
        type: objectFromPropertyState.rightTransferReason?.type,
        lastUpdateDt: objectFromPropertyState.rightTransferReason?.lastUpdateDt,
      },
      rightTransferReasonDescription: {
        propertyId: objectFromPropertyState.rightTransferReasonDescription?.propertyId,
        type: objectFromPropertyState.rightTransferReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.rightTransferReasonDescription?.lastUpdateDt,
      },
      supportingInfrastructureReason: {
        propertyId: objectFromPropertyState.supportingInfrastructureReason?.propertyId,
        type: objectFromPropertyState.supportingInfrastructureReason?.type,
        lastUpdateDt: objectFromPropertyState.supportingInfrastructureReason?.lastUpdateDt,
      },
      supportingInfrastructureReasonDescription: {
        propertyId: objectFromPropertyState.supportingInfrastructureReasonDescription?.propertyId,
        type: objectFromPropertyState.supportingInfrastructureReasonDescription?.type,
        lastUpdateDt:
          objectFromPropertyState.supportingInfrastructureReasonDescription?.lastUpdateDt,
      },
      suspensionOrganizationActivityReason: {
        propertyId: objectFromPropertyState.suspensionOrganizationActivityReason?.propertyId,
        type: objectFromPropertyState.suspensionOrganizationActivityReason?.type,
        lastUpdateDt: objectFromPropertyState.suspensionOrganizationActivityReason?.lastUpdateDt,
      },
      suspensionOrganizationActivityReasonDescription: {
        propertyId:
          objectFromPropertyState.suspensionOrganizationActivityReasonDescription?.propertyId,
        type: objectFromPropertyState.suspensionOrganizationActivityReasonDescription?.type,
        lastUpdateDt:
          objectFromPropertyState.suspensionOrganizationActivityReasonDescription?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForInformationOfExecutionConditionsSZPKForm }
