import React from 'react'
import { useFormContext } from 'react-hook-form'

import MunicipalitiesSection from '@components/DocumentFormComponents/FormSections/MunicipalitiesSection'
import { additionalAgreementOnTerminationFfSZPKValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/const'
import { useAdditionalAgreementOnTerminationFfSZPKManager } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/Manager'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'
import { AdditionalAgreementOnTerminationFfSZPKFieldsControlUpdateWatcher } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/watcher'

const currentBlockValues = additionalAgreementOnTerminationFfSZPKValues['3']

const Third = () => {
  const formInstance = useFormContext<AdditionalAgreementOnTerminationFfSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAdditionalAgreementOnTerminationFfSZPKManager()

  if (!formInstance) return null

  return (
    <MunicipalitiesSection
      formName={currentBlockValues.municipalities}
      formInstance={formInstance}
      blockViewIsValidating={blockViewIsValidating}
      editMode={editMode}
      watcher={AdditionalAgreementOnTerminationFfSZPKFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Third
