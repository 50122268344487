import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
  IConstructionStage,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { compact } from '@helpers/array/compact'
import { isNotEmptyString, isUndefined } from '@helpers/checkTypes'

const generateMenuListForAAgreementOnChangingCharacteristicsOfObjects = (
  formValues:
    | AAgreementOnChangingCharacteristicsOfObjectsFormValues
    | UnpackNestedValue<AAgreementOnChangingCharacteristicsOfObjectsFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<AAgreementOnChangingCharacteristicsOfObjectsFormValues>,
  formModifier:
    | AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues
    | UnpackNestedValue<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>,
): NestedMapOfMenu[] => {
  const getMenuSectionWithError = (numberSectionOfMenu: keyof NestedMenuHashMap) => {
    if (isUndefined(initialHashMap[numberSectionOfMenu])) return

    const preparedChildrenSections =
      initialHashMap[numberSectionOfMenu]?.children?.map((item) => ({
        ...item,
        hasError: !!formErrors?.[item.id],
      })) || []

    return {
      ...initialHashMap[numberSectionOfMenu],
      hasError:
        !!formErrors?.[numberSectionOfMenu] ||
        preparedChildrenSections.some((item) => item.hasError),
      children: preparedChildrenSections,
    }
  }

  const getPreparedConstructionStagesAnchorMenu = (
    step: IConstructionStage,
    indexOfStep: number,
  ): NestedMapOfMenu => {
    const constructionStagePath = `${initialHashMap['5'].path}.constructionStages.${indexOfStep}`
    const stepError = formErrors?.['5']?.constructionStages?.[indexOfStep]
    const stepModifier = formModifier?.['5'].constructionStages?.[indexOfStep]

    const preparedConstructionStageMenu = ((): NestedMapOfMenu[] => {
      if (!step.isConstruction && !step.isReconstruction && !step.isModernization) return []

      const constructionObjectsPath = `${constructionStagePath}.constructionObjects`
      const permissionsObjectsPath = `${constructionStagePath}.permissionsObjects`
      const exploitationObjectsPath = `${constructionStagePath}.exploitation.exploitationObjects`
      const registrationObjectsPath = `${constructionStagePath}.registration.registrationObjects`

      const constructionObjectsState = stepModifier.constructionObjectsState
      const permissionsObjectsState = stepModifier.permissionsObjectsState
      const exploitationObjectsState = stepModifier.exploitation.exploitationObjectsState
      const registrationObjectsState = stepModifier.registration.registrationObjectsState

      const preparedConstructionObjects = ((): NestedMapOfMenu[] => {
        if (!step.constructionObjects || !step.constructionObjects.length) return []

        return step.constructionObjects.map((object, indexOfObject) => {
          const objectModifier = stepModifier?.constructionObjects?.[indexOfObject]

          const objectPath = `${constructionObjectsPath}.${indexOfObject}`

          return {
            id: objectPath,
            path: objectPath,
            title: isNotEmptyString(object.objectName)
              ? object.objectName
              : `Основной объект №${indexOfObject + 1}`,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            isCollapsed: false,
            hasError: !!stepError?.constructionObjects?.[indexOfObject],
            parentBaseId: constructionObjectsPath,
            children: [],
          }
        })
      })()

      const preparedPermissionObjects = ((): NestedMapOfMenu[] => {
        if (!step.permissionsObjects || !step.permissionsObjects.length) return []

        return step.permissionsObjects.map((object, indexOfObject) => {
          const objectModifier = stepModifier.permissionsObjects?.[indexOfObject]

          const objectPath = `${permissionsObjectsPath}.${indexOfObject}`

          return {
            id: objectPath,
            path: objectPath,
            parentBaseId: permissionsObjectsPath,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!stepError?.permissionsObjects?.[indexOfObject],
            title: isNotEmptyString(object.permissionKind)
              ? object.permissionKind
              : `Разрешение №${indexOfObject + 1}`,
            children: [],
          }
        })
      })()

      const preparedExploitationObjects = ((): NestedMapOfMenu[] => {
        if (!step.exploitation.exploitationObjects || !step.exploitation.exploitationObjects.length)
          return []

        return step?.exploitation?.exploitationObjects.map((object, indexOfObject) => {
          const objectModifier = stepModifier.exploitation.exploitationObjects?.[indexOfObject]

          const objectPath = `${exploitationObjectsPath}.${indexOfObject}`

          return {
            id: objectPath,
            path: objectPath,
            parentBaseId: exploitationObjectsPath,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!stepError?.exploitation?.exploitationObjects?.[indexOfObject],
            title: isNotEmptyString(object.objectName)
              ? object.objectName
              : `Эксплуатация №${indexOfObject + 1}`,
            children: [],
          }
        })
      })()

      const preparedRegistrationObjects = ((): NestedMapOfMenu[] => {
        if (!step.registration.registrationObjects || !step.registration.registrationObjects.length)
          return []

        return step.registration.registrationObjects.map((object, indexOfObject) => {
          const objectModifier = stepModifier.registration.registrationObjects?.[indexOfObject]

          const objectPath = `${registrationObjectsPath}.${indexOfObject}`

          return {
            id: objectPath,
            path: objectPath,
            parentBaseId: registrationObjectsPath,
            isCollapsed: false,
            isChanged: !!objectModifier?.isChanged || !!objectModifier?.isNew,
            hasError: !!stepError?.registration?.registrationObjects?.[indexOfObject],
            title: isNotEmptyString(object.objectName)
              ? object.objectName
              : `Регистрация №${indexOfObject + 1}`,
            children: [],
          }
        })
      })()

      return [
        {
          id: constructionObjectsPath,
          path: constructionObjectsPath,
          title: 'Объекты недвижимости',
          parentBaseId: constructionStagePath,
          isCollapsed: false,
          isChanged:
            !!constructionObjectsState?.deletedElementFromList ||
            preparedConstructionObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!stepError?.constructionObjects?.length,
          children: preparedConstructionObjects,
        },
        {
          id: permissionsObjectsPath,
          path: permissionsObjectsPath,
          title: 'Разрешения на объекты',
          parentBaseId: constructionStagePath,
          isCollapsed: false,
          isChanged:
            !!permissionsObjectsState?.deletedElementFromList ||
            preparedPermissionObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!stepError?.permissionsObjects?.length,
          children: preparedPermissionObjects,
        },
        {
          id: exploitationObjectsPath,
          path: exploitationObjectsPath,
          title: 'Эксплуатация объектов',
          isCollapsed: false,
          isChanged:
            !!exploitationObjectsState?.deletedElementFromList ||
            !!stepModifier?.exploitation?.isChanged ||
            preparedExploitationObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!stepError?.exploitation,
          parentBaseId: constructionStagePath,
          children: preparedExploitationObjects,
        },
        {
          id: registrationObjectsPath,
          path: registrationObjectsPath,
          title: 'Регистрация объектов',
          isCollapsed: false,
          isChanged:
            !!registrationObjectsState?.deletedElementFromList ||
            !!stepModifier?.registration?.isChanged ||
            preparedRegistrationObjects.some(({ isChanged }) => !!isChanged),
          hasError: !!stepError?.registration,
          parentBaseId: constructionStagePath,
          children: preparedRegistrationObjects,
        },
      ]
    })()

    return {
      path: constructionStagePath,
      id: constructionStagePath,
      title: isNotEmptyString(step.stepDescription)
        ? step.stepDescription
        : `Мероприятие ${indexOfStep + 1}`,
      isCollapsed: false,
      isChanged:
        !!stepModifier?.isNew ||
        !!stepModifier?.isChanged ||
        !!preparedConstructionStageMenu?.some(({ isChanged }) => isChanged),
      hasError: !!stepError,
      parentBaseId: initialHashMap['5'].id,
      children: preparedConstructionStageMenu,
    }
  }

  const preparedChildrenOfConstructionStages = formValues['5'].constructionStages.map(
    getPreparedConstructionStagesAnchorMenu,
  )

  const preparedHashMap: Record<string, NestedMapOfMenu | undefined> = {
    '1': getMenuSectionWithError('1'),
    '2': getMenuSectionWithError('2'),
    '3': getMenuSectionWithError('3'),
    '4': getMenuSectionWithError('4'),
    '5': {
      ...initialHashMap['5'],
      hasError: preparedChildrenOfConstructionStages.some(({ hasError }) => hasError),
      isChanged: preparedChildrenOfConstructionStages.some(({ isChanged }) => isChanged),
      children: preparedChildrenOfConstructionStages,
    },
  }

  return compact([
    preparedHashMap['1'],
    preparedHashMap['2'],
    preparedHashMap['3'],
    preparedHashMap['4'],
    preparedHashMap['5'],
  ]) as NestedMapOfMenu[]
}

export { generateMenuListForAAgreementOnChangingCharacteristicsOfObjects }
