import React, { FC, memo, ReactNode } from 'react'

import AsyncWrapper from '@components/AsyncWrapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import { isEmptyString, isNotEmptyString, isUndefined } from '@helpers/checkTypes'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

import styles from './GroupWithRemoveButton.module.scss'

interface GroupWithRemoveButtonProps {
  id: string
  children: ReactNode
  disableBottomBorder?: boolean
  disableTitlePaddingLeft?: boolean
  disableTitlePaddingRight?: boolean
  groupClassName?: string
  titleClassName?: string
  title?: string
  titleSize?: number
  removeButtonSize?: number
  onRemove?: VoidFunction
  dataTestId?: string
  leftAddons?: ReactNode
}

const GroupWithRemoveButton: FC<GroupWithRemoveButtonProps> = ({
  onRemove,
  titleSize = !isUndefined(onRemove) ? 9 : 12,
  removeButtonSize = 3,
  disableTitlePaddingLeft = false,
  disableTitlePaddingRight = false,
  id,
  title,
  disableBottomBorder,
  groupClassName,
  titleClassName,
  children,
  leftAddons,
  dataTestId,
}) => {
  const leftAddonsRenderCondition = !isUndefined(leftAddons)

  const isShowTitle = isNotEmptyString(title)

  const isShowRemoveButton = !isUndefined(onRemove)

  const handleDelete = async (e) => {
    e.preventDefault()

    await onRemove?.()
  }

  return (
    <FlippedWithCollapse dataTestId={dataTestId} flipId={id}>
      <Container
        id={id}
        className={cn(
          styles.group,
          'p-0',
          {
            [styles['groupWithRemoveButton--disableMarginTop']]: !title || isEmptyString(title),
          },
          groupClassName,
        )}
      >
        <Row>
          <Col xs={12}>
            {isShowTitle && (
              <Row
                className={cn(styles['groupWithRemoveButton__title-wrapper'], {
                  [styles['groupWithRemoveButton__title-wrapper--withRemoveButton']]:
                    !isUndefined(onRemove),
                })}
              >
                <Col className={styles['groupWithRemoveButton__title-col']} xs={titleSize}>
                  {leftAddonsRenderCondition && (
                    <div className={styles['groupWithRemoveButton__title-leftAddons']}>
                      {leftAddons}
                    </div>
                  )}
                  <Typography.Body
                    variant={'bodyMMedium'}
                    className={cn(
                      styles.groupWithRemoveButton__title,
                      {
                        [styles['groupWithRemoveButton__title--disablePaddingLeft']]:
                          disableTitlePaddingLeft,
                        [styles['groupWithRemoveButton__title--disablePaddingRight']]:
                          disableTitlePaddingRight,
                      },
                      titleClassName,
                    )}
                  >
                    {title}
                  </Typography.Body>
                </Col>
                {isShowRemoveButton && (
                  <AsyncWrapper promise={handleDelete}>
                    {({ isLoading, wrappedPromise }) => (
                      <Col xs={removeButtonSize}>
                        <Button
                          fixWidth
                          disabled={isLoading}
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          color={'negative'}
                          view={'plain'}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          leadingIcon={{
                            src: DeleteIcon,
                          }}
                          onClick={wrappedPromise}
                        >
                          Удалить
                        </Button>
                      </Col>
                    )}
                  </AsyncWrapper>
                )}
              </Row>
            )}
            {children}
            {!disableBottomBorder && <Border className={styles.group__border} />}
          </Col>
        </Row>
      </Container>
    </FlippedWithCollapse>
  )
}

export default memo(GroupWithRemoveButton)
