import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultDataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/const'
import DataOnFulfillmentOfTermsSZPKLayout from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Layout'

const DataOnFulfillmentOfTermsSZPKLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultDataOnFulfillmentOfTermsSZPKFormValues}>
      <DataOnFulfillmentOfTermsSZPKLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(DataOnFulfillmentOfTermsSZPKLayoutWithFormProvider)
