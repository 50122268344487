import { FC, memo, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'

import Icon from '@components/Icon'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import { ModalActionWithoutActionProps } from '@components/NewDesign/Modal/Action'
import Typography from '@components/NewDesign/Typography'
import { CustomActionsProps } from '@components/NewDesignedModals/ErrorModal'
import { successModalButtonTexts } from '@constants/texts'
import doneAllIcon from '@icons/action/done_all.svg'
import cn from 'classnames'

import styles from './SuccessModal.module.scss'

export interface SuccessModalComponentProps {
  text: string
  headerText: string
  customActions?: {
    actions: CustomActionsProps[]
    mergeDefault: boolean
  }
  modalActionsProps?: Partial<ModalActionWithoutActionProps>
}

type SuccessModalProps = Required<PopupProps> & SuccessModalComponentProps

const SuccessModal: FC<SuccessModalProps> = ({
  headerText,
  text = '',
  isOpen,
  onClose,
  customActions,
  modalActionsProps,
}) => {
  const doneActions = useMemo(() => {
    const defaultActions = [
      {
        dataTestId: 'SuccessModal-done-button',
        children: successModalButtonTexts.defaultMessage,
        fixWidth: true,
        onClick: onClose,
        className: styles.success,
      },
    ] as ButtonProps[]

    if (!customActions) return defaultActions as ButtonProps[]

    const preparedActions = customActions.actions.map((action) => ({
      ...action,
      onClick: action.bindOnClose
        ? (e) => {
            action.onClick?.(e)
            onClose?.()
          }
        : action.onClick,
    }))

    if (customActions.mergeDefault) {
      return [...defaultActions, ...preparedActions] as ButtonProps[]
    }

    return preparedActions as ButtonProps[]
  }, [customActions, onClose])

  return (
    <Modal.Action
      {...modalActionsProps}
      hideCloseIcon
      actions={doneActions}
      isOpen={isOpen}
      title={''}
      dataTestId="SuccessModal-modal"
      closeButtonDataTestId="SuccessModal-modal-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      onClose={onClose}
    >
      <div className={cn(styles.icon, styles.icon__done)}>
        <Icon src={doneAllIcon} size={'4xl'} />
      </div>
      {headerText && (
        <Typography.Headline variant="headlineH3" className={styles.header}>
          {headerText}
        </Typography.Headline>
      )}
      <Typography.Body variant="bodyMRegular" className={styles.body}>
        {text}
      </Typography.Body>
    </Modal.Action>
  )
}

export default memo(SuccessModal)
