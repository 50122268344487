import { FC, memo, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps, MapOfMenu } from '@components/DocumentFormComponents/types'
import AAChangingVolumeReimbursement from '@components/Forms/AAChangingVolumeReimbursementForm'
import {
  aAChangingVolumeReimbursementBlockValues,
  mapOfAAChangingVolumeReimbursementMenu,
} from '@components/Forms/AAChangingVolumeReimbursementForm/const'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import { AAChangingVolumeReimbursementFieldsControlUpdateWatcher } from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import AAChangingVolumeReimbursementLayoutWrapper from './Wrapper'

const AAChangingVolumeReimbursementLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormContext<AAChangingVolumeReimbursementFormValues>()

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const isFederalSZPK = useWatch({
    name: aAChangingVolumeReimbursementBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: aAChangingVolumeReimbursementBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const isPaper = useWatch({
    name: aAChangingVolumeReimbursementBlockValues.additionalFields.isPaper,
    control: formInstance.control,
  })

  const currentMenu: MapOfMenu[] = useMemo(() => {
    return [...mapOfAAChangingVolumeReimbursementMenu].reduce<MapOfMenu[]>(
      (previousValue, currentValue) => {
        if (!isFederalSZPK && currentValue.id === '1') return previousValue

        if (!isMunicipalitiesRender && currentValue.id === '3') return previousValue

        if (!isPaper && currentValue.id === '7') return previousValue

        return [...previousValue, currentValue]
      },
      [],
    )
  }, [isFederalSZPK, isMunicipalitiesRender, isPaper])

  const handleOnClose = useCallback(() => {
    AAChangingVolumeReimbursementFieldsControlUpdateWatcher.resetAllState()

    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <AAChangingVolumeReimbursement.Manager
        formId={formId}
        projectId={projectId}
        initialErrorsFromViolations={initialErrorsFromViolations}
        editMode={editMode}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
        onClose={handleOnClose}
      >
        <FlatMenuManager key={String(isFederalSZPK)} initialCurrentAnchorId={currentMenu[0].id}>
          <AAChangingVolumeReimbursementLayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Дополнительное соглашение об изменении объема планируемых к возмещению затрат
              </Typography.Headline>
              <AAChangingVolumeReimbursement.StatementForm
                initialMenu={currentMenu}
                className={styles.layout__form}
              />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}>
              <AAChangingVolumeReimbursement.Menu
                initialMenu={currentMenu}
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </AAChangingVolumeReimbursementLayoutWrapper>
        </FlatMenuManager>
      </AAChangingVolumeReimbursement.Manager>
    </DocumentFormLayout>
  )
}

export default memo(AAChangingVolumeReimbursementLayout)
