import React from 'react'
import { useFormContext } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/EmptyDataText/EmptyDataText.module.scss'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionAgreementEventsBlockValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/const'
import InformationOfExecutionAgreementEventsStepMonitoring from '@components/Forms/InformationOfExecutionAgreementEventsForm/Forms/1/StepMonitoring'
import { InformationOfExecutionAgreementEventsStepsMonitoringPathName } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Forms/1/types'
import { useInformationOfExecutionAgreementEventsManager } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Manager'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import {
  InformationOfExecutionAgreementEventsFieldArrayControlUpdateWatcher,
  InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionAgreementEventsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const First = () => {
  const formInstance = useFormContext<InformationOfExecutionAgreementEventsFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionAgreementEventsManager()

  const { fields: stepsMonitoring } = useFieldArraySubscribableControl<
    InformationOfExecutionAgreementEventsFormValues,
    InformationOfExecutionAgreementEventsStepsMonitoringPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: informationOfExecutionAgreementEventsBlockValues['1'].stepsMonitoring,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionAgreementEventsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher,
  })

  const isEmptyStepsMonitoring = !stepsMonitoring.length

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: informationOfExecutionAgreementEventsBlockValues['1'].stepsMonitoring,
          })}
        >
          <FlipperList list={stepsMonitoring}>
            {stepsMonitoring.map((stepMonitoring, index) => {
              const formName =
                `${informationOfExecutionAgreementEventsBlockValues['1'].stepsMonitoring}.${index}` as const

              return (
                <InformationOfExecutionAgreementEventsStepMonitoring
                  key={stepMonitoring.id}
                  id={stepMonitoring.id}
                  anchorId={stepMonitoring.anchorId}
                  formInstance={formInstance}
                  name={formName}
                />
              )
            })}
          </FlipperList>
          {isEmptyStepsMonitoring && (
            <Typography.Body variant={'bodyMRegular'} className={styles.emptyDataText__padding}>
              Нет данных для заполнения информации по мероприятиям соглашения
            </Typography.Body>
          )}
        </SubscribableControl>
      </Stack>
    </Container>
  )
}

export default First
