import React, { useCallback, useEffect, useState } from 'react'
import { usePopupManager } from 'react-popup-manager'
import { Outlet, useNavigate } from 'react-router'

import Footer from '@components/Layout/Footer'
import Header from '@components/Layout/Header'
import Menu from '@components/Layout/Header/Menu'
import SimpleModal from '@components/Modals/SimpleModal'
import TechnicalWork from '@components/TechnicalWork'
import { Paths } from '@constants/paths'
import {
  currentOrigin,
  governmentDomain as governmentDomain,
  isGovermentDomain as isGovernmentDomain,
  isProdFlag,
  isYandexBrowser,
} from '@constants/system'
import {
  getLKHeaderOrganization,
  messageToOtherBrowser,
  messageToYandexBrowser,
} from '@constants/texts'
import { useAuthContext } from '@context/AuthContext'
import { usePaths } from '@hooks/usePaths'
import AgreementInfoSection from '@routes/Home/AgreementInfoSection'
import MainSection from '@routes/Home/MainSection'
import ServiceInfoSection from '@routes/Home/ServiceInfoSection'
import { useTechnicalWork } from '@services/TechnicalWork/TechnicalWork.entity'

import styles from './Home.module.scss'

export const MenuLinks = [
  {
    to: Paths.AuthorizedList,
    label: 'Уполномоченные ОИВ',
  },
  {
    to: Paths.Acts,
    label: 'Документы',
  },
  { to: Paths.KnowledgeBase, label: 'Cправка' },
]

const HomePage = () => {
  const navigate = useNavigate()

  const { authState } = useAuthContext()

  const { isHome, isAuthorizedList } = usePaths()

  const popupManager = usePopupManager()

  const technicalWorkState = useTechnicalWork()

  const [needsDomain, setNeedsDomain] = useState<string | null>()
  const [modalHeader, setModalHeader] = useState<string>('')
  const [modalDescription, setModalDescription] = useState<string>('')

  const handleGoToInvestorLK = useCallback(() => {
    navigate(authState?.token ? Paths.Projects : Paths.Login)
  }, [navigate, authState])

  const handleGoToCommissionerLK = useCallback(
    (header: string | null, description?: string | null) => () => {
      const domain = isGovernmentDomain ? '' : governmentDomain

      if (isYandexBrowser) {
        location.replace(`${isProdFlag ? currentOrigin : domain}/login`)
        return
      }

      setNeedsDomain(isProdFlag ? currentOrigin : governmentDomain)

      if (header) setModalHeader(header)
      if (description) setModalDescription(description)
    },
    [setNeedsDomain],
  )

  const clearStates = () => {
    setNeedsDomain(null)

    setModalHeader('')
    setModalDescription('')
  }

  useEffect(() => {
    if (needsDomain) {
      const textHeader = getLKHeaderOrganization(modalHeader || undefined)
      const description = messageToOtherBrowser(
        modalHeader && modalDescription ? `${modalHeader} ${modalDescription}` : undefined,
      )

      popupManager.open(SimpleModal, {
        textHeader: textHeader,
        descriptionClassname: styles.goverModal__description,
        description: isYandexBrowser ? messageToYandexBrowser : description,
        linkWithCopyAfterDescription: needsDomain,
        contentClassname: styles.goverModal,
        buttonSubmitText: 'Перейти',
        href: `${needsDomain}/login`,
        hideCancelButton: true,
        ...(!isYandexBrowser && { hideSubmitButton: true }),
        target: '_blank',
        isCloseModalIcon: true,
        onClose: clearStates,
      })
    }
  }, [needsDomain, modalHeader, modalDescription, popupManager])

  return (
    <div className={styles.wrapper}>
      <Header
        logoProps={{
          sticky: !(isHome || isAuthorizedList),
        }}
      >
        <TechnicalWork.HeaderBanner
          wrapperClassname="mr-4"
          fallbackStatus={technicalWorkState.fallbackStatus}
          startDate={technicalWorkState.startDate}
          endDate={technicalWorkState.endDate}
          timeToStart={technicalWorkState.timeToStart}
          timeToEnd={technicalWorkState.timeToEnd}
          isRunning={technicalWorkState.isRunning}
          isSetup={technicalWorkState.isSetup}
        />
        {MenuLinks.map((item, key) => (
          <Menu.Link to={item.to} key={key}>
            {item.label}
          </Menu.Link>
        ))}
      </Header>
      <div>
        {isHome && (
          <>
            <MainSection
              handleGoToInvestorLK={handleGoToInvestorLK}
              handleGoToCommissionerLK={handleGoToCommissionerLK}
            />
            <ServiceInfoSection />
            <AgreementInfoSection />
          </>
        )}
        <Outlet />
      </div>
      <Footer handleGoToCommissionerLK={handleGoToCommissionerLK} />
    </div>
  )
}

export default HomePage
