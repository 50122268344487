import { ReactElement } from 'react'
import toast from 'react-hot-toast'

const DEFAULT_TOASTS_LIMIT = 3
const DEFAULT_TOAST_DURATION = 4000

type AcceptedNode = ReactElement
type ToastId = string
type Queue = Array<[AcceptedNode, ToastOptions?] | AcceptedNode>

interface IUseToasterManagerPublicApi {
  toast: (node: AcceptedNode, options?: ToastOptions) => void
}

type HandleToast = IUseToasterManagerPublicApi['toast']

const globalQueue: Queue = []

interface ToastOptions {
  duration?: number
  id?: string
}

export class ToastManager {
  queue: Queue
  private activeToasts: ToastId[] = []
  readonly maxElements

  constructor(maxElements = 1, queue = globalQueue) {
    this.maxElements = maxElements
    this.queue = queue
  }

  private handleCloseToast = (toastId: ToastId) => {
    toast.dismiss(toastId)
    this.activeToasts = this.activeToasts.filter((id) => id !== toastId)

    this.handleReleaseQueue()
  }

  private handleReleaseQueue = () => {
    // Вызывается при появлении свободного места
    if (!this.queue.length || this.activeToasts.length === this.maxElements) return // Если есть активный toast или очередь пуста, то ничего не делаем

    const freePlace = this.maxElements - this.activeToasts.length
    const elementsToToast = this.queue.slice(0, freePlace)

    this.queue = this.queue.slice(elementsToToast.length)

    elementsToToast.forEach((el) =>
      Array.isArray(el) ? this.handleToast(...el) : this.handleToast(el),
    )
  }

  private handleToast: HandleToast = (component, options) => {
    const toastId = toast(component, { duration: Infinity, ...options })
    this.activeToasts.push(toastId)

    const duration = options?.duration || DEFAULT_TOAST_DURATION

    // Если duration === Infinity, значит toast будет закрываться из вне, автоматически закрывать не нужно
    if (duration < Infinity) setTimeout(() => this.handleCloseToast(toastId), duration)

    return toastId
  }

  close = this.handleCloseToast

  toast: IUseToasterManagerPublicApi['toast'] = (component, options) => {
    if (this.activeToasts.length === this.maxElements) {
      this.queue.push([component, options]) // либо добавляем в очередь
    } else return this.handleToast(component, options) // либо сразу вызываем toast
  }
}

export const MainToastManager = new ToastManager(DEFAULT_TOASTS_LIMIT)
