import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useInfrFacilitiesCostsExpectedReimbursedManager } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Manager'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'

import { mapOfInfrFacilitiesCostsExpectedReimbursedMenu } from '../const'
import InfrFacilitiesCostsExpectedReimbursedForms from '../Forms'

import styles from './Statement.module.scss'

const mapOfInfrFacilitiesCostsExpectedReimbursedForms: FormItemProps = {
  '1': {
    node: <InfrFacilitiesCostsExpectedReimbursedForms.One />,
  },
  '2': {
    node: <InfrFacilitiesCostsExpectedReimbursedForms.Two />,
  },
  '3': {
    node: <InfrFacilitiesCostsExpectedReimbursedForms.Three />,
    overrideBlockProps: {
      className: styles.overrideBlock__third,
      contentClassname: styles.overrideBlock__thirdContent,
      titleClassname: styles.overrideBlock__title,
    },
  },
  '4': {
    node: <InfrFacilitiesCostsExpectedReimbursedForms.Four />,
  },
} as const

const InfrFacilitiesCostsExpectedReimbursedForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<InfrFacilitiesCostsExpectedReimbursedFormValues>()

  const {
    state: { formIsLoading },
  } = useInfrFacilitiesCostsExpectedReimbursedManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfInfrFacilitiesCostsExpectedReimbursedMenu}
      mapOfForms={mapOfInfrFacilitiesCostsExpectedReimbursedForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(InfrFacilitiesCostsExpectedReimbursedForm)
