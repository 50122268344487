import { IAttribute } from '@services/Attribute/Attribute.entity'
import { IDfoListItem, TAllDfoTypes, TShortDfoInfo } from '@services/Dfo/Dfo.entity'
import { IProjectMember } from '@services/Members/Members.entity'

const getOrganizationTypeMembers = (type, members: IProjectMember[]) => {
  return members.filter((member) => member.type === type)
}

const getProjectAttributeByName = (name, attributes: IAttribute[]) => {
  return attributes.filter((attribute) => attribute.name === name)
}

const getActualProjectAttributeByName = (name, attributes: IAttribute[]) => {
  const attrs = getProjectAttributeByName(name, attributes)

  if (attrs.length <= 1) {
    return attrs[0]
  }

  return attrs.find((action) => action.isTemp)
}

const getDfoByType = <T extends IDfoListItem | TShortDfoInfo>(
  dfos: T[],
  type: TAllDfoTypes,
): T | undefined => {
  return dfos?.find((dfo) => dfo.type === type)
}

const getDfoById = <T extends IDfoListItem | TShortDfoInfo>(
  dfos: T[],
  id: string,
): T | undefined => {
  return dfos?.find((dfo) => dfo.id === id)
}

export {
  getActualProjectAttributeByName,
  getDfoById,
  getDfoByType,
  getOrganizationTypeMembers,
  getProjectAttributeByName,
}
