import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import ApplicationInclusionOfAARegisterSZPK from '@components/Forms/ApplicationInclusionOfAARegisterSZPK'
import {
  defaultApplicationInclusionOfAARegisterSZPKFormValues,
  mapOfApplicationInclusionOfAARegisterSZPKMenu,
} from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/const'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import ApplicationInclusionOfAARegisterSZPKLayoutWrapper from './Wrapper'

const ApplicationInclusionOfAARegisterSZPKLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps({
    defaultValues: defaultApplicationInclusionOfAARegisterSZPKFormValues,
  })

  const handleOnClose = () => {
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <ApplicationInclusionOfAARegisterSZPK.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfApplicationInclusionOfAARegisterSZPKMenu[0].id}
          >
            <ApplicationInclusionOfAARegisterSZPKLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Заявление о включении дополнительного соглашения к СЗПК в реестр СЗПК
                </Typography.Headline>
                <ApplicationInclusionOfAARegisterSZPK.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <ApplicationInclusionOfAARegisterSZPK.Menu
                  className={cn(styles.layout__menu, styles['layout__menu--nested'])}
                />
              </div>
            </ApplicationInclusionOfAARegisterSZPKLayoutWrapper>
          </FlatMenuManager>
        </ApplicationInclusionOfAARegisterSZPK.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(ApplicationInclusionOfAARegisterSZPKLayout)
