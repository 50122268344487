import { FC, memo, useCallback, useMemo, useState } from 'react'
import { PopupProps } from 'react-popup-manager'

import Icon from '@components/Icon'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import LoadingModal from '@components/NewDesignedModals/LoadingModal'
import { defaultMessageSupport } from '@constants/texts'
import { useAPIContext } from '@context/APIContext'
import { isAxiosError } from '@helpers/checkTypes'
import { isIntervalServerError } from '@helpers/errorHelpers'
import errorIcon from '@icons/alert/error_outline.svg'
import { ButtonTextByRevokeAction } from '@interfaces/statement'
import { RevokeActionEnum } from '@services/DocumentActions/DocumentActions.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'
import cn from 'classnames'

import styles from './RevokeModal.module.scss'

export type TRevokeModal = {
  actionId: string

  handleRevokeSuccess: (value: void | PromiseLike<void>) => void
  handleRevokeError: (e: AxiosError<unknown>) => void
  textBody?: string
  headerText?: string
  projectId?: string
  uiActionTrigger?: RevokeActionEnum
  disableCreateSystemDocument?: boolean
}

export type RevokeModalProps = Required<PopupProps> & TRevokeModal

const RevokeModal: FC<RevokeModalProps> = ({
  headerText,
  textBody,
  uiActionTrigger,
  isOpen,
  actionId,
  projectId,
  disableCreateSystemDocument,
  onClose,
  handleRevokeSuccess,
  handleRevokeError,
}) => {
  const {
    documentsApi: { createSystemDocument },
  } = useAPIContext()

  const [error, setError] = useState('')
  const [loading, setIsLoading] = useState(false)

  const handleRevoke = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!disableCreateSystemDocument) {
        await createSystemDocument({
          actionId,
          projectId: projectId ?? '',
        })
      }

      handleRevokeSuccess()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'RevokeModal',
          componentType: 'modal',
        },
        additionInfo: {
          actionId,
        },
      })(error)

      if (!isAxiosError(error)) throw error

      if (isIntervalServerError(error.response?.status)) {
        setError(defaultMessageSupport)
      } else {
        setError(error.response?.data?.detail || error?.message)
      }

      handleRevokeError(error)
    } finally {
      setIsLoading(false)
    }
  }, [createSystemDocument, actionId, projectId, handleRevokeSuccess, handleRevokeError])

  const actions = useMemo(() => {
    return [
      {
        view: 'gray',
        dataTestId: 'RevokeModal-cancel-button',
        children: 'Отмена',
        onClick: onClose,
      },
      {
        dataTestId: 'RevokeModal-revoke-button',
        children: uiActionTrigger
          ? ButtonTextByRevokeAction[uiActionTrigger] || 'Подписать отзыв'
          : 'Подписать отзыв',
        color: 'negative',
        onClick: handleRevoke,
      },
    ] as ButtonProps[]
  }, [onClose, uiActionTrigger, handleRevoke])

  const errorActions = useMemo(() => {
    return [
      {
        children: 'Закрыть',
        fixWidth: true,
        onClick: onClose,
      },
    ] as ButtonProps[]
  }, [onClose])

  if (loading) {
    return <LoadingModal text={'Выполняется действие'} isOpen={isOpen} onClose={onClose} />
  }

  if (error) {
    return (
      <Modal.Action
        hideCloseIcon
        actions={errorActions}
        isOpen={isOpen}
        title={''}
        dataTestId="RevokeModal-errorModal"
        closeButtonDataTestId="RevokeModal-errorModal-closeButton"
        simpleModalContainerClassName={cn(styles.modalWrapper, styles.modalWrapper__mini)}
        simpleModalBodyClassName={styles.modalBody}
        onClose={onClose}
      >
        <div className={styles.icon__error}>
          <Icon src={errorIcon} size={'4xl'} />
        </div>
        <Typography.Headline variant="headlineH3" className={styles.head}>
          Ошибка
        </Typography.Headline>
        <Typography.Body variant="bodyMRegular" className={cn(styles.body, styles.text)}>
          {error}
        </Typography.Body>
      </Modal.Action>
    )
  }

  return (
    <Modal.Action
      actions={actions}
      isOpen={isOpen}
      title={headerText ?? ''}
      dataTestId="RevokeModal-modal"
      closeButtonDataTestId="RevokeModal-modal-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      onClose={onClose}
    >
      <Typography.Body variant="bodyMRegular" className={styles.text}>
        {textBody}
      </Typography.Body>
    </Modal.Action>
  )
}

export default memo(RevokeModal)
