import { useRef } from 'react'

import { isNullOrUndefined, isNumber } from '@helpers/checkTypes'

interface Navigator {
  userAgent: string
  platform: string
  maxTouchPoints?: number
}

interface PlatformResult {
  apple: {
    phone: boolean
    ipod: boolean
    tablet: boolean
    universal: boolean
    device: boolean
  }
  android: {
    phone: boolean
    tablet: boolean
    device: boolean
  }
  windows: {
    phone: boolean
    tablet: boolean
    device: boolean
  }
  other: {
    blackberry: boolean
    blackberry10: boolean
    opera: boolean
    firefox: boolean
    chrome: boolean
    device: boolean
  }
}

type CreateMatchProps = (userAgent: string) => (regex: RegExp) => boolean

const appleIphone = /iPhone/i
const appleIpod = /iPod/i
const appleTablet = /iPad/i
const appleUniversal = /\biOS-universal(?:.+)Mac\b/i
const androidPhone = /\bAndroid(?:.+)Mobile\b/i
const androidTablet = /Android/i
const amazonPhone = /(?:SD4930UR|\bSilk(?:.+)Mobile\b)/i
const amazonTablet = /Silk/i
const windowsPhone = /Windows Phone/i
const windowsTablet = /\bWindows(?:.+)ARM\b/i
const otherBlackBerry = /BlackBerry/i
const otherBlackBerry10 = /BB10/i
const otherOpera = /Opera Mini/i
const otherChrome = /\b(CriOS|Chrome)(?:.+)Mobile/i
const otherFirefox = /Mobile(?:.+)Firefox\b/i

const isAppleTabletOnIos13 = (navigator?: Navigator): boolean => {
  return (
    !isNullOrUndefined(navigator) &&
    navigator.platform === 'MacIntel' &&
    isNumber(navigator.maxTouchPoints) &&
    navigator.maxTouchPoints > 1
  )
}

const createMatch: CreateMatchProps = (userAgent) => (regex) => regex.test(userAgent)

const checkPlatform = (navigator: Navigator) => {
  const match = createMatch(navigator.userAgent)

  const result: PlatformResult = {
    apple: {
      phone: match(appleIphone) && !match(windowsPhone),
      ipod: match(appleIpod),
      tablet:
        !match(appleIphone) &&
        (match(appleTablet) || isAppleTabletOnIos13(navigator)) &&
        !match(windowsPhone),
      universal: match(appleUniversal),
      device:
        (match(appleIphone) ||
          match(appleIpod) ||
          match(appleTablet) ||
          match(appleUniversal) ||
          isAppleTabletOnIos13(navigator)) &&
        !match(windowsPhone),
    },
    android: {
      phone:
        (!match(windowsPhone) && match(amazonPhone)) ||
        (!match(windowsPhone) && match(androidPhone)),
      tablet:
        !match(windowsPhone) &&
        !match(amazonPhone) &&
        !match(androidPhone) &&
        (match(amazonTablet) || match(androidTablet)),
      device:
        (!match(windowsPhone) &&
          (match(amazonPhone) ||
            match(amazonTablet) ||
            match(androidPhone) ||
            match(androidTablet))) ||
        match(/\bokhttp\b/i),
    },
    windows: {
      phone: match(windowsPhone),
      tablet: match(windowsTablet),
      device: match(windowsPhone) || match(windowsTablet),
    },
    other: {
      blackberry: match(otherBlackBerry),
      blackberry10: match(otherBlackBerry10),
      opera: match(otherOpera),
      firefox: match(otherFirefox),
      chrome: match(otherChrome),
      device:
        match(otherBlackBerry) ||
        match(otherBlackBerry10) ||
        match(otherOpera) ||
        match(otherFirefox) ||
        match(otherChrome),
    },
  }

  const isMobileVersion = result.apple.phone || result.android.phone || result.windows.phone
  const isTabletVersion = result.apple.tablet || result.android.tablet || result.windows.tablet

  return {
    isMobileVersion,
    isTabletVersion,
  }
}

const useMobile = () => {
  const mobileVersion = useRef(checkPlatform(window.navigator).isMobileVersion)
  const tabletVersion = useRef(checkPlatform(window.navigator).isTabletVersion)

  return {
    isMobile: mobileVersion.current,
    isTablet: tabletVersion.current,
  }
}

export default useMobile
