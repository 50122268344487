import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/AdditionalFormItem.module.scss'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import RegistrationFormItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CRegistrationForm/RegistrationFormItem'
import { SeventhRegistrationObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface RegistrationItemProps {
  id: string
  anchorId: string
  formName: SeventhRegistrationObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  indexOfRegistration: number
  onDeleteRegistration: VoidFunction
  positiveStepEndDate: (text: string) => (value: unknown) => string | undefined
}

const RegistrationItem: FC<RegistrationItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
  indexOfRegistration,
  onDeleteRegistration,
  positiveStepEndDate,
}) => {
  return (
    <ObjectStepWrapper
      disableBorder
      stepId={id}
      id={anchorId}
      headerNode={
        <Typography.Body variant={'bodyMSemibold'}>
          Регистрация №{indexOfRegistration + 1}
        </Typography.Body>
      }
      onRemoveObjectStep={editMode ? onDeleteRegistration : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <RegistrationFormItem
            formName={formName}
            blockViewIsValidating={blockViewIsValidating}
            formInstance={formInstance}
            positiveStepEndDate={positiveStepEndDate}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Border className={styles.additionalFormItem__border} />
          </Col>
        </Row>
      </Stack>
    </ObjectStepWrapper>
  )
}

export default RegistrationItem
