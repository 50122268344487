import { useCallback } from 'react'

import { generatePropertiesObjectForSupplementalCostSharingAForm } from '@components/Forms/SupplementalCostSharingAForm/adapters/PropertiesProps.adapter'
import { generateRHFForSupplementalCostSharingAForm } from '@components/Forms/SupplementalCostSharingAForm/adapters/RHF.adapter'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'

const useSupplementalCostSharingAAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): SupplementalCostSharingAFormValues =>
      generateRHFForSupplementalCostSharingAForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForSupplementalCostSharingAForm(objectFromPropertyState),
    [],
  )

  return {
    generateRHFObject,
    generatePropertiesObject,
  }
}

export { useSupplementalCostSharingAAdapters }
