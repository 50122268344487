import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOnMonitoringResultsCompensationDismantling,
  CertificateOnMonitoringResultsFormValues,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { lengthValidate,standardAmountValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const compensationDismantlingCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsCompensationDismantling,
  CertificateOnMonitoringResultsFormValues
> = {
  dismantlingObjectName: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  dismantlingObjectValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { compensationDismantlingCertificateOnMonitoringResultsValidationMap }
