import React, { FC, ReactNode, useCallback, useMemo } from 'react'

import { useVersionManager } from '@components/Attachments/Version/manager'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { useChangesMadeContext } from '@components/Projects/[id]/DocumentLayout/ChangesMade'
import LayoutHeader from '@components/Projects/[id]/DocumentLayout/View/Layouts/LayoutHeader'
import { StandardDfoLayoutProps } from '@components/Projects/[id]/DocumentLayout/View/Layouts/StandardDfoLayout'
import StandardDfoLayoutTemplate from '@components/Projects/[id]/DocumentLayout/View/Layouts/StandardDfoLayoutTemplate'
import DownloadIcon from '@icons/DownloadIcon.svg'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import {
  IModifiedDocumentSetInfoForRender,
  IModifiedDocumentSetInfoForRenderToArray,
} from '@services/Documents/documents.entity'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'
import cn from 'classnames'

import styles from './RelatedDfoLayout.module.scss'

const { isDocumentDownloadingForbidden, isDocumentReadDownloadingForbidden } =
  DocumentsHelpersService

interface RelatedDfoLayoutProps {
  dfo: IDfoListItem | null
  preparedSetsOfDocuments?: Map<string, IModifiedDocumentSetInfoForRender[]>

  topAddition?: ReactNode

  children?: ReactNode

  mapOfSetsToRender?: IModifiedDocumentSetInfoForRenderToArray[]

  standardDfoLayoutProps?: Partial<
    Pick<
      StandardDfoLayoutProps,
      | 'disableNameOfSets'
      | 'disableDownloadDocuments'
      | 'documentSetsClassName'
      | 'actionsClassName'
    >
  >

  disableLayoutHeader?: boolean

  isLoading: boolean
}

const RelatedDfoLayout: FC<RelatedDfoLayoutProps> = ({
  dfo,
  preparedSetsOfDocuments,
  mapOfSetsToRender,
  topAddition,
  children,
  standardDfoLayoutProps,
  disableLayoutHeader,
  isLoading,
}) => {
  const {
    handlers: { onArchiveDownload },
  } = useVersionManager()

  const handleArchiveDownload = useCallback(async () => onArchiveDownload?.(), [onArchiveDownload])
  const { handleShowAllSetOfDocuments } = useChangesMadeContext()

  const hasPermissionsWithDownload = useMemo(
    () =>
      mapOfSetsToRender?.some((item) =>
        item.documentSets?.some(
          (documentItem) =>
            !isDocumentDownloadingForbidden(documentItem.permissions) ||
            !isDocumentReadDownloadingForbidden(documentItem.permissions),
        ),
      ),
    [mapOfSetsToRender],
  )

  const downloadButton = useMemo(
    () =>
      hasPermissionsWithDownload && (
        <Button
          className={styles.relatedDfoLayout__download}
          geometry={'square'}
          size={'s'}
          view={'plain'}
          dataTestId="RelatedDfoLayout-downloadArchive-button"
          leadingIcon={{
            src: DownloadIcon,
            size: 'initial',
            className: styles.documentsView__icon,
          }}
          onClick={handleArchiveDownload}
        >
          Cкачать весь комплект
        </Button>
      ),
    [handleArchiveDownload, hasPermissionsWithDownload],
  )
  return (
    <LayoutHeader
      mapOfSetsToRender={mapOfSetsToRender}
      downloadButton={downloadButton}
      handleResetOnlyChangedDocumentsSwitch={handleShowAllSetOfDocuments}
      dfo={dfo}
    >
      {!disableLayoutHeader && (dfo?.typeTitle || dfo?.typeSubtitle) && (
        <div className={styles.relatedDfoLayout__header}>
          {dfo?.typeTitle && (
            <Typography.Headline variant={'headlineH2'}>{dfo.typeTitle}</Typography.Headline>
          )}
          {dfo?.typeSubtitle && (
            <Typography.Body className={styles.relatedDfoLayout__subtitle} variant={'bodyXLMedium'}>
              {dfo?.typeSubtitle}
            </Typography.Body>
          )}
        </div>
      )}

      <div className={cn({ [styles.relatedDfoLayout__content]: !disableLayoutHeader })}>
        {topAddition && (
          <div
            className={cn({
              [styles.relatedDfoLayout__topAddition]: isLoading,
            })}
          >
            {topAddition}
          </div>
        )}
        <StandardDfoLayoutTemplate
          dfo={dfo}
          preparedSetsOfDocuments={preparedSetsOfDocuments}
          mapOfSetsToRender={mapOfSetsToRender}
          isLoading={isLoading}
          actionsClassName={cn(
            { [styles.relatedDfoLayout__documents]: !disableLayoutHeader },
            standardDfoLayoutProps?.actionsClassName,
          )}
          documentSetsClassName={cn({
            [styles.relatedDfoLayout__documentsList]: topAddition,
          })}
          {...standardDfoLayoutProps}
        />
      </div>

      {!isLoading && children}
    </LayoutHeader>
  )
}

export default RelatedDfoLayout
