import OMSU_Podpisanie_DS from '@components/KnowledgeBase/__mock__/chapterData/3/3_1/3_1_1/OMSU_Podpisanie_DS.md'
import OMSU_Vozvrat_na_dorabotku_DS from '@components/KnowledgeBase/__mock__/chapterData/3/3_1/3_1_2/OMSU_Vozvrat_na_dorabotku_DS.md'
import OMSU_Otkaz_v_zaklyuchenii_DS from '@components/KnowledgeBase/__mock__/chapterData/3/3_1/3_1_3/OMSU_Otkaz_v_zaklyuchenii_DS.md'
import OMSU_Rassmotrenie_DS from '@components/KnowledgeBase/__mock__/chapterData/3/3_1/OMSU_Rassmotrenie_DS.md'
import OMSU_Redaktirovanie_NPA from '@components/KnowledgeBase/__mock__/chapterData/3/3_2/3_2_1/OMSU_Redaktirovanie_NPA.md'
import OMSU_Isklyuchenie_NPA from '@components/KnowledgeBase/__mock__/chapterData/3/3_2/3_2_2/OMSU_Isklyuchenie_NPA.md'
import OMSU_Vedenie_reestra_NPA from '@components/KnowledgeBase/__mock__/chapterData/3/3_2/OMSU_Vedenie_reestra_NPA.md'
import OMSU_Dostup_LK from '@components/KnowledgeBase/__mock__/chapterData/3/OMSU_Dostup_LK.md'
import UO_Prinyatie_zayavleniya from '@components/KnowledgeBase/__mock__/chapterData/4/4_1/4_1_1/UO_Prinyatie_zayavleniya.md'
import UO_Vozvrat_na_dorabotku from '@components/KnowledgeBase/__mock__/chapterData/4/4_1/4_1_2/UO_Vozvrat_na_dorabotku.md'
import UO_Otricatelnoe_zaklyuchenie from '@components/KnowledgeBase/__mock__/chapterData/4/4_1/4_1_3/UO_Otricatelnoe_zaklyuchenie.md'
import UO_Rassmotrenie_zayavleniya from '@components/KnowledgeBase/__mock__/chapterData/4/4_1/UO_Rassmotrenie_zayavleniya.md'
import UO_Rassmotrenie_hodatajstv from '@components/KnowledgeBase/__mock__/chapterData/4/4_2/UO_Rassmotrenie_hodatajstv.md'
import ROIV_Rassmotrenie_IP from '@components/KnowledgeBase/__mock__/chapterData/5/5_1/ROIV_Rassmotrenie_IP.md'
import ROIV_Rassmotrenie_NPA from '@components/KnowledgeBase/__mock__/chapterData/5/5_2/ROIV_Rassmotrenie_NPA.md'
import ROIV_Podpisanie_SZPK from '@components/KnowledgeBase/__mock__/chapterData/5/5_3/5_3_1/ROIV_Podpisanie_SZPK.md'
import ROIV_Otkaz_v_zaklyuchenii_SZPK from '@components/KnowledgeBase/__mock__/chapterData/5/5_3/5_3_2/ROIV_Otkaz_v_zaklyuchenii_SZPK.md'
import ROIV_Rassmotrenie_SZPK from '@components/KnowledgeBase/__mock__/chapterData/5/5_3/ROIV_Rassmotrenie_SZPK.md'
import ROIV_Rassmotrenie_NPA_regionalnogo from '@components/KnowledgeBase/__mock__/chapterData/5/5_4/5_4_1/ROIV_Rassmotrenie_NPA_regionalnogo.md'
import ROIV_Prinyatie_regionalnogo_SZPK from '@components/KnowledgeBase/__mock__/chapterData/5/5_4/5_4_2/ROIV_Prinyatie_regionalnogo_SZPK.md'
import ROIV_Vozvrat_regionalnogo_SZPK from '@components/KnowledgeBase/__mock__/chapterData/5/5_4/5_4_3/ROIV_Vozvrat_regionalnogo_SZPK.md'
import ROIV_Otricatelnoe_zaklyuchenie from '@components/KnowledgeBase/__mock__/chapterData/5/5_4/5_4_4/ROIV_Otricatelnoe_zaklyuchenie.md'
import ROIV_Regionalnye_SZPK from '@components/KnowledgeBase/__mock__/chapterData/5/5_4/ROIV_Regionalnye_SZPK.md'
import ROIV_Rassmotrenie_hodatajstv from '@components/KnowledgeBase/__mock__/chapterData/5/5_5/ROIV_Rassmotrenie_hodatajstv.md'
import ROIV_Redaktirovanie_NPA from '@components/KnowledgeBase/__mock__/chapterData/5/5_6/5_6_1/ROIV_Redaktirovanie_NPA.md'
import ROIV_Isklyuchenie_NPA from '@components/KnowledgeBase/__mock__/chapterData/5/5_6/5_6_2/ROIV_Isklyuchenie_NPA.md'
import ROIV_Vedenie_reestra_NPA from '@components/KnowledgeBase/__mock__/chapterData/5/5_6/ROIV_Vedenie_reestra_NPA.md'
import FOIV_Rassmotrenie_NPA from '@components/KnowledgeBase/__mock__/chapterData/6/6_1/FOIV_Rassmotrenie_NPA.md'
import FOIV_Otkaz_po_zaklyucheniyu_UO from '@components/KnowledgeBase/__mock__/chapterData/6/6_2/FOIV_Otkaz_po_zaklyucheniyu_UO.md'
import FOIV_Podpisanie_SZPK from '@components/KnowledgeBase/__mock__/chapterData/6/6_3/6_3_1/FOIV_Podpisanie_SZPK.md'
import FOIV_Otkaz_v_zaklyuchenii_SZPK from '@components/KnowledgeBase/__mock__/chapterData/6/6_3/6_3_2/FOIV_Otkaz_v_zaklyuchenii_SZPK.md'
import FOIV_Rassmotrenie_SZPK from '@components/KnowledgeBase/__mock__/chapterData/6/6_3/FOIV_Rassmotrenie_SZPK.md'
import FOIV_Rassmotrenie_hodatajstv from '@components/KnowledgeBase/__mock__/chapterData/6/6_4/FOIV_Rassmotrenie_hodatajstv.md'
import FOIV_Redaktirovanie_NPA from '@components/KnowledgeBase/__mock__/chapterData/6/6_5/6_5_1/FOIV_Redaktirovanie_NPA.md'
import FOIV_Isklyuchenie_NPA from '@components/KnowledgeBase/__mock__/chapterData/6/6_5/6_5_2/FOIV_Isklyuchenie_NPA.md'
import FOIV_Vedenie_reestra_NPA from '@components/KnowledgeBase/__mock__/chapterData/6/6_5/FOIV_Vedenie_reestra_NPA.md'
import Registraciya_v_reestre from '@components/KnowledgeBase/__mock__/chapterData/7/7_1/Registraciya_v_reestre.md'
import Registraciya_v_reestre_hodatajstva from '@components/KnowledgeBase/__mock__/chapterData/7/7_2/Registraciya_v_reestre_hodatajstva.md'
import Registraciya_v_reestre_DS from '@components/KnowledgeBase/__mock__/chapterData/7/7_3/Registraciya_v_reestre_DS.md'
import type { IKnowledgeBaseItem } from '@components/KnowledgeBase/types'

import Do_vhoda_v_sistemu from './chapterData/1/1_1/Do_vhoda_v_sistemu.md'
import Registraciya_v_sisteme from './chapterData/1/1_2/Registraciya_v_sisteme.md'
import Vhod_s_ukep from './chapterData/1/1_3/Vhod_s_ukep.md'
import Vhod_s_mchd from './chapterData/1/1_4/Vhod_s_mchd.md'
import Sozdanie_proekta from './chapterData/2/2_1/Sozdanie_proekta.md'
import Izmenenie_resheniya_ob_uchastii from './chapterData/2/2_2/2_2_1/Izmenenie_resheniya_ob_uchastii_RF.md'
import Ukazanie_uchastnikov from './chapterData/2/2_2/Ukazanie_uchastnikov.md'
import Zapolnenie_ekrannych_form from './chapterData/2/2_3/2_3_1/Zapolnenie_ekrannych_form.md'
import Zagruzka_dokumentov from './chapterData/2/2_3/2_3_2/Zagruzka_dokumentov.md'
import Dobavlenie_npa from './chapterData/2/2_3/2_3_3/Dobavlenie_npa.md'
import Zapolnenie_komplektov_dokumentov from './chapterData/2/2_3/Zapolnenie_komplektov_dokumentov.md'
import Otpravka_dokumentov_na_ocenku_be from './chapterData/2/2_4/2_4_1/Otpravka_dokumentov_na_ocenku_be.md'
import Odnovremennaya_otpravka from './chapterData/2/2_4/2_4_2/Odnovremennaya_otpravka.md'
import Otpravka_DS_OMSU from './chapterData/2/2_4/2_4_3/Otpravka_DS_OMSU.md'
import Otpravka_na_rassmotrenie from './chapterData/2/2_4/Otpravka_na_rassmotrenie.md'
import Uvedomlenie_o_zaklyuchenii_SZPK from './chapterData/2/2_5/2_5_1/Uvedomlenie_o_zaklyuchenii_SZPK.md'
import Korrektirovka_dokumentov from './chapterData/2/2_5/2_5_2/Korrektirovka_dokumentov.md'
import Otzyv_zayavleniya_na_SZPK from './chapterData/2/2_5/2_5_3/Otzyv_zayavleniya_na_SZPK.md'
import zamena_po_34 from './chapterData/2/2_5/2_5_4/zamena_po_34.md'
import Hod_rassmotreniya_hodatajstv from './chapterData/2/2_5/2_5_5/Hod_rassmotreniya_hodatajstv.md'
import Hod_rassmotreniya_DS_OMSU from './chapterData/2/2_5/2_5_6/Hod_rassmotreniya_DS_OMSU.md'
import Hod_rassmotreniya_dokumentov from './chapterData/2/2_5/Hod_rassmotreniya_dokumentov.md'

//Порядок не важен, главное наличие. Работает вместо запроса при формировании дерева
const mockKnowledgeHashMap: Record<string, IKnowledgeBaseItem> = Object.freeze({
  '1673': {
    id: '1673',
    name: 'Начало работы',
    parentBaseId: null,
    children: [
      {
        id: '1447',
        name: 'До входа в систему',
        parentBaseId: '1673',
        children: [],
        markdown: Do_vhoda_v_sistemu,
        position: '1.1',
      },
      {
        id: '1309',
        name: 'Регистрация в системе',
        parentBaseId: '1673',
        children: [],

        markdown: Registraciya_v_sisteme,
        position: '1.2',
      },
      {
        id: '1256',
        name: 'Вход с УКЭП',
        parentBaseId: '1673',
        children: [],
        markdown: Vhod_s_ukep,
        position: '1.3',
      },
      {
        id: '1548',
        name: 'Вход с УКЭП и машиночитаемой доверенностью',
        parentBaseId: '1673',
        children: [],
        markdown: Vhod_s_mchd,
        position: '1.4',
      },
    ],
    markdown: '',
    position: '1.',
  },

  '1447': {
    id: '1447',
    name: 'До входа в систему',
    parentBaseId: '1673',
    children: [],
    markdown: Do_vhoda_v_sistemu,
    position: '1.1',
  },
  '1309': {
    id: '1309',
    name: 'Регистрация в системе',
    parentBaseId: '1673',
    children: [],
    markdown: Registraciya_v_sisteme,
    position: '1.2',
  },
  '1256': {
    id: '1256',
    name: 'Вход с УКЭП',
    parentBaseId: '1673',
    children: [],
    markdown: Vhod_s_ukep,
    position: '1.3',
  },
  '1548': {
    id: '1548',
    name: 'Вход с УКЭП и машиночитаемой доверенностью',
    parentBaseId: '1673',
    children: [],
    markdown: Vhod_s_mchd,
    position: '1.4',
  },

  '1884': {
    id: '1884',
    name: 'Инвестору',
    parentBaseId: null,
    children: [
      {
        id: '1635',
        name: 'Создание проекта',
        parentBaseId: '1884',
        children: [],
        markdown: Sozdanie_proekta,
        position: '2.1',
      },
      {
        id: '1822',
        name: 'Указание участников',
        parentBaseId: '1884',
        children: [
          {
            id: '1865',
            name: 'Изменение решения об участии Российской Федерации в соглашении',
            parentBaseId: '1822',
            children: [],
            markdown: Izmenenie_resheniya_ob_uchastii,
            position: '2.2.1',
          },
        ],
        markdown: Ukazanie_uchastnikov,
        position: '2.2',
      },
      {
        id: '1823',
        name: 'Заполнение комплектов документов',
        parentBaseId: '1884',
        children: [
          {
            id: '1952',
            name: 'Заполнение экранных форм',
            parentBaseId: '1823',
            children: [],
            markdown: Zapolnenie_ekrannych_form,
            position: '2.3.1',
          },
          {
            id: '1047',
            name: 'Загрузка документов',
            parentBaseId: '1823',
            children: [],
            markdown: Zagruzka_dokumentov,
            position: '2.3.2',
          },
          {
            id: '1911',
            name: 'Добавление НПА',
            parentBaseId: '1823',
            children: [],
            markdown: Dobavlenie_npa,
            position: '2.3.3',
          },
        ],
        markdown: Zapolnenie_komplektov_dokumentov,
        position: '2.3',
      },
      {
        id: '1824',
        name: 'Отправка на рассмотрение',
        parentBaseId: '1884',
        children: [
          {
            id: '1861',
            name: 'Отправка документов на оценку по критериям бюджетной эффективности',
            parentBaseId: '1824',
            children: [],
            markdown: Otpravka_dokumentov_na_ocenku_be,
            position: '2.4.1',
          },
          {
            id: '1862',
            name: 'Одновременная отправка всех комплектов документов',
            parentBaseId: '1824',
            children: [],
            markdown: Odnovremennaya_otpravka,
            position: '2.4.2',
          },
          {
            id: '1876',
            name: 'Подача дополнительного соглашения о присоединении муниципального образования',
            parentBaseId: '1824',
            children: [],
            markdown: Otpravka_DS_OMSU,
            position: '2.4.3',
          },
        ],
        markdown: Otpravka_na_rassmotrenie,
        position: '2.4',
      },
      {
        id: '1825',
        name: 'Ход рассмотрения',
        parentBaseId: '1884',
        children: [
          {
            id: '1871',
            name: 'Уведомление о заключении СЗПК',
            parentBaseId: '1825',
            children: [],
            markdown: Uvedomlenie_o_zaklyuchenii_SZPK,
            position: '2.5.1',
          },
          {
            id: '1872',
            name: 'Корректировка документов, возвращённых на доработку',
            parentBaseId: '1825',
            children: [],
            markdown: Korrektirovka_dokumentov,
            position: '2.5.2',
          },
          {
            id: '1873',
            name: 'Отзыв заявления на СЗПК',
            parentBaseId: '1825',
            children: [],
            markdown: Otzyv_zayavleniya_na_SZPK,
            position: '2.5.3',
          },
          {
            id: '1875',
            name: 'Замена документов по п.34 Правил',
            parentBaseId: '1825',
            children: [],
            markdown: zamena_po_34,
            position: '2.5.4',
          },
          {
            id: '1874',
            name: 'Ход рассмотрения ходатайств о связанности',
            parentBaseId: '1825',
            children: [],
            markdown: Hod_rassmotreniya_hodatajstv,
            position: '2.5.5',
          },
          {
            id: '1877',
            name: 'Ход рассмотрения дополнительного соглашения о присоединении муниципального образования',
            parentBaseId: '1825',
            children: [],
            markdown: Hod_rassmotreniya_DS_OMSU,
            position: '2.5.6',
          },
        ],
        markdown: Hod_rassmotreniya_dokumentov,
        position: '2.5',
      },
    ],
    markdown: '',
    position: '2.',
  },
  '1635': {
    id: '1635',
    name: 'Создание проекта',
    parentBaseId: '1884',
    children: [],
    markdown: Sozdanie_proekta,
    position: '2.1',
  },
  '1822': {
    id: '1822',
    name: 'Указание участников',
    parentBaseId: '1884',
    children: [
      {
        id: '1865',
        name: 'Изменение решения об участии Российской Федерации в соглашении',
        parentBaseId: '1822',
        children: [],
        markdown: Izmenenie_resheniya_ob_uchastii,
        position: '2.2.1',
      },
    ],
    markdown: Ukazanie_uchastnikov,
    position: '2.2',
  },
  '1823': {
    id: '1823',
    name: 'Заполнение комплектов документов',
    parentBaseId: '1884',
    children: [
      {
        id: '1952',
        name: 'Заполнение экранных форм',
        parentBaseId: '1823',
        children: [],
        markdown: Zapolnenie_ekrannych_form,
        position: '2.3.1',
      },
      {
        id: '1047',
        name: 'Загрузка документов',
        parentBaseId: '1823',
        children: [],
        markdown: Zagruzka_dokumentov,
        position: '2.3.2',
      },
      {
        id: '1911',
        name: 'Добавление НПА',
        parentBaseId: '1823',
        children: [],
        markdown: Dobavlenie_npa,
        position: '2.3.3',
      },
    ],
    markdown: Zapolnenie_komplektov_dokumentov,
    position: '2.3',
  },
  '1824': {
    id: '1824',
    name: 'Отправка на рассмотрение',
    parentBaseId: '1884',
    children: [
      {
        id: '1861',
        name: 'Отправка документов на оценку по критериям бюджетной эффективности',
        parentBaseId: '1824',
        children: [],
        markdown: Otpravka_dokumentov_na_ocenku_be,
        position: '2.4.1',
      },
      {
        id: '1862',
        name: 'Одновременная отправка всех комплектов документов',
        parentBaseId: '1824',
        children: [],
        markdown: Odnovremennaya_otpravka,
        position: '2.4.2',
      },
      {
        id: '1876',
        name: 'Подача дополнительного соглашения о присоединении муниципального образования',
        parentBaseId: '1824',
        children: [],
        markdown: Otpravka_DS_OMSU,
        position: '2.4.3',
      },
    ],
    markdown: Otpravka_na_rassmotrenie,
    position: '2.4',
  },
  '1825': {
    id: '1825',
    name: 'Ход рассмотрения',
    parentBaseId: '1884',
    children: [
      {
        id: '1871',
        name: 'Уведомление о заключении СЗПК',
        parentBaseId: '1825',
        children: [],
        markdown: Uvedomlenie_o_zaklyuchenii_SZPK,
        position: '2.5.1',
      },
      {
        id: '1872',
        name: 'Корректировка документов, возвращённых на доработку',
        parentBaseId: '1825',
        children: [],
        markdown: Korrektirovka_dokumentov,
        position: '2.5.2',
      },
      {
        id: '1873',
        name: 'Отзыв заявления на СЗПК',
        parentBaseId: '1825',
        children: [],
        markdown: Otzyv_zayavleniya_na_SZPK,
        position: '2.5.3',
      },
      {
        id: '1875',
        name: 'Замена документов по п.34 Правил',
        parentBaseId: '1825',
        children: [],
        markdown: zamena_po_34,
        position: '2.5.4',
      },
      {
        id: '1874',
        name: 'Ход рассмотрения ходатайств о связанности',
        parentBaseId: '1825',
        children: [],
        markdown: Hod_rassmotreniya_hodatajstv,
        position: '2.5.5',
      },
      {
        id: '1877',
        name: 'Ход рассмотрения дополнительного соглашения о присоединении муниципального образования',
        parentBaseId: '1825',
        children: [],
        markdown: Hod_rassmotreniya_DS_OMSU,
        position: '2.5.6',
      },
    ],
    markdown: Hod_rassmotreniya_dokumentov,
    position: '2.5',
  },
  '1865': {
    id: '1865',
    name: 'Изменение решения об участии Российской Федерации в соглашении',
    parentBaseId: '1822',
    children: [],
    markdown: Izmenenie_resheniya_ob_uchastii,
    position: '2.2.1',
  },
  '1952': {
    id: '1952',
    name: 'Заполнение экранных форм',
    parentBaseId: '1823',
    children: [],
    markdown: Zapolnenie_ekrannych_form,
    position: '2.3.1',
  },
  '1047': {
    id: '1047',
    name: 'Загрузка документов',
    parentBaseId: '1823',
    children: [],
    markdown: Zagruzka_dokumentov,
    position: '2.3.2',
  },
  '1911': {
    id: '1911',
    name: 'Добавление НПА',
    parentBaseId: '1823',
    children: [],
    markdown: Dobavlenie_npa,
    position: '2.3.3',
  },
  '1861': {
    id: '1861',
    name: 'Отправка документов на оценку по критериям бюджетной эффективности',
    parentBaseId: '1824',
    children: [],
    markdown: Otpravka_dokumentov_na_ocenku_be,
    position: '2.4.1',
  },
  '1862': {
    id: '1862',
    name: 'Одновременная отправка всех комплектов документов',
    parentBaseId: '1824',
    children: [],
    markdown: Odnovremennaya_otpravka,
    position: '2.4.2',
  },
  '1876': {
    id: '1876',
    name: 'Подача дополнительного соглашения о присоединении муниципального образования',
    parentBaseId: '1824',
    children: [],
    markdown: Otpravka_DS_OMSU,
    position: '2.4.3',
  },
  '1871': {
    id: '1871',
    name: 'Уведомление о заключении СЗПК',
    parentBaseId: '1825',
    children: [],
    markdown: Uvedomlenie_o_zaklyuchenii_SZPK,
    position: '2.5.1',
  },
  '1872': {
    id: '1872',
    name: 'Корректировка документов, возвращённых на доработку',
    parentBaseId: '1825',
    children: [],
    markdown: Korrektirovka_dokumentov,
    position: '2.5.2',
  },
  '1873': {
    id: '1873',
    name: 'Отзыв заявления на СЗПК',
    parentBaseId: '1825',
    children: [],
    markdown: Otzyv_zayavleniya_na_SZPK,
    position: '2.5.3',
  },
  '1874': {
    id: '1874',
    name: 'Ход рассмотрения ходатайств о связанности',
    parentBaseId: '1825',
    children: [],
    markdown: Hod_rassmotreniya_hodatajstv,
    position: '2.5.5',
  },
  '1877': {
    id: '1877',
    name: 'Ход рассмотрения дополнительного соглашения о присоединении муниципального образования',
    parentBaseId: '1825',
    children: [],
    markdown: Hod_rassmotreniya_DS_OMSU,
    position: '2.5.6',
  },
  '1875': {
    id: '1875',
    name: 'Замена документов по п.34 Правил',
    parentBaseId: '1825',
    children: [],
    markdown: zamena_po_34,
    position: '2.5.4',
  },
  '2001': {
    id: '2001',
    name: 'Органу местного самоуправления',
    parentBaseId: null,
    children: [
      {
        id: '2002',
        name: 'Рассмотрение дополнительного соглашения о присоединении муниципального образования',
        parentBaseId: '2001',
        children: [
          {
            id: '2003',
            name: 'Подписание соглашения',
            parentBaseId: '2002',
            children: [],
            markdown: OMSU_Podpisanie_DS,
            position: '3.1.1',
          },
          {
            id: '2004',
            name: 'Возврат на доработку',
            parentBaseId: '2002',
            children: [],
            markdown: OMSU_Vozvrat_na_dorabotku_DS,
            position: '3.1.2',
          },
          {
            id: '2005',
            name: 'Отказ в заключении соглашения',
            parentBaseId: '2002',
            children: [],
            markdown: OMSU_Otkaz_v_zaklyuchenii_DS,
            position: '3.1.3',
          },
        ],
        markdown: OMSU_Rassmotrenie_DS,
        position: '3.1',
      },
      {
        id: '2006',
        name: 'Ведение реестра НПА',
        parentBaseId: '2001',
        children: [
          {
            id: '2007',
            name: 'Редактирование НПА и отдельных положений',
            parentBaseId: '2006',
            children: [],
            markdown: OMSU_Redaktirovanie_NPA,
            position: '3.2.1',
          },
          {
            id: '2008',
            name: 'Исключение НПА и отдельных положений из реестра НПА',
            parentBaseId: '2006',
            children: [],
            markdown: OMSU_Isklyuchenie_NPA,
            position: '3.2.2',
          },
        ],
        markdown: OMSU_Vedenie_reestra_NPA,
        position: '3.2',
      },
    ],
    markdown: OMSU_Dostup_LK,
    position: '3.',
  },
  '2002': {
    id: '2002',
    name: 'Рассмотрение дополнительного соглашения о присоединении муниципального образования',
    parentBaseId: '2001',
    children: [
      {
        id: '2003',
        name: 'Подписание соглашения',
        parentBaseId: '2002',
        children: [],
        markdown: OMSU_Podpisanie_DS,
        position: '3.1.1',
      },
      {
        id: '2004',
        name: 'Возврат на доработку',
        parentBaseId: '2002',
        children: [],
        markdown: OMSU_Vozvrat_na_dorabotku_DS,
        position: '3.1.2',
      },
      {
        id: '2005',
        name: 'Отказ в заключении соглашения',
        parentBaseId: '2002',
        children: [],
        markdown: OMSU_Otkaz_v_zaklyuchenii_DS,
        position: '3.1.3',
      },
    ],
    markdown: OMSU_Rassmotrenie_DS,
    position: '3.1',
  },
  '2003': {
    id: '2003',
    name: 'Подписание соглашения',
    parentBaseId: '2002',
    children: [],
    markdown: OMSU_Podpisanie_DS,
    position: '3.1.1',
  },
  '2004': {
    id: '2004',
    name: 'Возврат на доработку',
    parentBaseId: '2002',
    children: [],
    markdown: OMSU_Vozvrat_na_dorabotku_DS,
    position: '3.1.2',
  },
  '2005': {
    id: '2005',
    name: 'Отказ в заключении соглашения',
    parentBaseId: '2002',
    children: [],
    markdown: OMSU_Otkaz_v_zaklyuchenii_DS,
    position: '3.1.3',
  },
  '2006': {
    id: '2006',
    name: 'Ведение реестра НПА',
    parentBaseId: '2001',
    children: [
      {
        id: '2007',
        name: 'Редактирование НПА и отдельных положений',
        parentBaseId: '2006',
        children: [],
        markdown: OMSU_Redaktirovanie_NPA,
        position: '3.2.1',
      },
      {
        id: '2008',
        name: 'Исключение НПА и отдельных положений из реестра НПА',
        parentBaseId: '2006',
        children: [],
        markdown: OMSU_Isklyuchenie_NPA,
        position: '3.2.2',
      },
    ],
    markdown: OMSU_Vedenie_reestra_NPA,
    position: '3.2',
  },
  '2007': {
    id: '2007',
    name: 'Редактирование НПА и отдельных положений',
    parentBaseId: '2006',
    children: [],
    markdown: OMSU_Redaktirovanie_NPA,
    position: '3.2.1',
  },
  '2008': {
    id: '2008',
    name: 'Исключение НПА и отдельных положений из реестра НПА',
    parentBaseId: '2006',
    children: [],
    markdown: OMSU_Isklyuchenie_NPA,
    position: '3.2.2',
  },
  '1813': {
    id: '1813',
    name: 'Уполномоченной организации',
    parentBaseId: null,
    children: [
      {
        id: '1704',
        name: 'Рассмотрение заявления',
        parentBaseId: '1813',
        children: [
          {
            id: '1490',
            name: 'Принятие заявления',
            parentBaseId: '1704',
            children: [],
            markdown: UO_Prinyatie_zayavleniya,
            position: '4.1.1',
          },
          {
            id: '1782',
            name: 'Возврат на доработку',
            parentBaseId: '1704',
            children: [],
            markdown: UO_Vozvrat_na_dorabotku,
            position: '4.1.2',
          },
          {
            id: '1308',
            name: 'Отрицательное заключение по заявлению',
            parentBaseId: '1704',
            children: [],
            markdown: UO_Otricatelnoe_zaklyuchenie,
            position: '4.1.3',
          },
        ],
        markdown: UO_Rassmotrenie_zayavleniya,
        position: '4.1',
      },
      {
        id: '1732',
        name: 'Рассмотрение ходатайств о связанности',
        parentBaseId: '1813',
        children: [],
        markdown: UO_Rassmotrenie_hodatajstv,
        position: '4.2',
      },
    ],
    markdown: '',
    position: '4.',
  },
  '1704': {
    id: '1704',
    name: 'Рассмотрение заявления',
    parentBaseId: '1813',
    children: [
      {
        id: '1490',
        name: 'Принятие заявления',
        parentBaseId: '1704',
        children: [],
        markdown: UO_Prinyatie_zayavleniya,
        position: '4.1.1',
      },
      {
        id: '1782',
        name: 'Возврат на доработку',
        parentBaseId: '1704',
        children: [],
        markdown: UO_Vozvrat_na_dorabotku,
        position: '4.1.2',
      },
      {
        id: '1308',
        name: 'Отрицательное заключение по заявлению',
        parentBaseId: '1704',
        children: [],
        markdown: UO_Otricatelnoe_zaklyuchenie,
        position: '4.1.3',
      },
    ],
    markdown: UO_Rassmotrenie_zayavleniya,
    position: '4.1',
  },
  '1732': {
    id: '1732',
    name: 'Рассмотрение ходатайств о связанности',
    parentBaseId: '1813',
    children: [],
    markdown: UO_Rassmotrenie_hodatajstv,
    position: '4.2',
  },
  '1490': {
    id: '1490',
    name: 'Принятие заявления',
    parentBaseId: '1704',
    children: [],
    markdown: UO_Prinyatie_zayavleniya,
    position: '4.1.1',
  },
  '1782': {
    id: '1782',
    name: 'Возврат на доработку',
    parentBaseId: '1704',
    children: [],
    markdown: UO_Vozvrat_na_dorabotku,
    position: '4.1.2',
  },
  '1308': {
    id: '1308',
    name: 'Отрицательное заключение по заявлению',
    parentBaseId: '1704',
    children: [],
    markdown: UO_Otricatelnoe_zaklyuchenie,
    position: '4.1.3',
  },
  '1471': {
    id: '1471',
    name: 'Региональному органу исполнительной власти',
    parentBaseId: null,
    children: [
      {
        id: '1441',
        name: 'Рассмотрение инвестиционного проекта',
        parentBaseId: '1471',
        children: [],
        markdown: ROIV_Rassmotrenie_IP,
        position: '5.1',
      },
      {
        id: '1761',
        name: 'Рассмотрение списка НПА',
        parentBaseId: '1471',
        children: [],
        markdown: ROIV_Rassmotrenie_NPA,
        position: '5.2',
      },
      {
        id: '1253',
        name: 'Рассмотрение СЗПК',
        parentBaseId: '1471',
        children: [
          {
            id: '1341',
            name: 'Подписание СЗПК',
            parentBaseId: '1253',
            children: [],
            markdown: ROIV_Podpisanie_SZPK,
            position: '5.3.1',
          },
          {
            id: '1558',
            name: 'Отказ в заключении СЗПК',
            parentBaseId: '1253',
            children: [],
            markdown: ROIV_Otkaz_v_zaklyuchenii_SZPK,
            position: '5.3.2',
          },
        ],
        markdown: ROIV_Rassmotrenie_SZPK,
        position: '5.3',
      },
      {
        id: '1444',
        name: 'Особенности рассмотрения региональных СЗПК',
        parentBaseId: '1471',
        children: [
          {
            id: '1481',
            name: 'Рассмотрение списка НПА регионального СЗПК',
            parentBaseId: '1444',
            children: [],
            markdown: ROIV_Rassmotrenie_NPA_regionalnogo,
            position: '5.4.1',
          },
          {
            id: '1472',
            name: 'Принятие заявления регионального СЗПК',
            parentBaseId: '1444',
            children: [],
            markdown: ROIV_Prinyatie_regionalnogo_SZPK,
            position: '5.4.2',
          },
          {
            id: '1473',
            name: 'Возврат на доработку регионального СЗПК',
            parentBaseId: '1444',
            children: [],
            markdown: ROIV_Vozvrat_regionalnogo_SZPK,
            position: '5.4.3',
          },
          {
            id: '1474',
            name: 'Отрицательное заключение по заявлению',
            parentBaseId: '1444',
            children: [],
            markdown: ROIV_Otricatelnoe_zaklyuchenie,
            position: '5.4.4',
          },
        ],
        markdown: ROIV_Regionalnye_SZPK,
        position: '5.4',
      },
      {
        id: '1445',
        name: 'Рассмотрение ходатайств о связанности',
        parentBaseId: '1471',
        children: [],
        markdown: ROIV_Rassmotrenie_hodatajstv,
        position: '5.5',
      },
      {
        id: '1448',
        name: 'Ведение реестра НПА',
        parentBaseId: '1471',
        children: [
          {
            id: '1449',
            name: 'Редактирование НПА и отдельных положений',
            parentBaseId: '1448',
            children: [],
            markdown: ROIV_Redaktirovanie_NPA,
            position: '5.6.1',
          },
          {
            id: '1450',
            name: 'Исключение НПА и отдельных положений из реестра НПА',
            parentBaseId: '1448',
            children: [],
            markdown: ROIV_Isklyuchenie_NPA,
            position: '5.6.2',
          },
        ],
        markdown: ROIV_Vedenie_reestra_NPA,
        position: '5.6',
      },
    ],
    markdown: '',
    position: '5.',
  },
  '1441': {
    id: '1441',
    name: 'Рассмотрение инвестиционного проекта',
    parentBaseId: '1471',
    children: [],
    markdown: ROIV_Rassmotrenie_IP,
    position: '5.1',
  },
  '1761': {
    id: '1761',
    name: 'Рассмотрение списка НПА',
    parentBaseId: '1471',
    children: [],
    markdown: ROIV_Rassmotrenie_NPA,
    position: '5.2',
  },
  '1253': {
    id: '1253',
    name: 'Рассмотрение СЗПК',
    parentBaseId: '1471',
    children: [
      {
        id: '1341',
        name: 'Подписание СЗПК',
        parentBaseId: '1253',
        children: [],
        markdown: ROIV_Podpisanie_SZPK,
        position: '5.3.1',
      },
      {
        id: '1558',
        name: 'Отказ в заключении СЗПК',
        parentBaseId: '1253',
        children: [],
        markdown: ROIV_Otkaz_v_zaklyuchenii_SZPK,
        position: '5.3.2',
      },
    ],
    markdown: ROIV_Rassmotrenie_SZPK,
    position: '5.3',
  },
  '1444': {
    id: '1444',
    name: 'Особенности рассмотрения региональных СЗПК',
    parentBaseId: '1471',
    children: [
      {
        id: '1481',
        name: 'Рассмотрение списка НПА регионального СЗПК',
        parentBaseId: '1444',
        children: [],
        markdown: ROIV_Rassmotrenie_NPA_regionalnogo,
        position: '5.4.1',
      },
      {
        id: '1472',
        name: 'Принятие заявления регионального СЗПК',
        parentBaseId: '1444',
        children: [],
        markdown: ROIV_Prinyatie_regionalnogo_SZPK,
        position: '5.4.2',
      },
      {
        id: '1473',
        name: 'Возврат на доработку регионального СЗПК',
        parentBaseId: '1444',
        children: [],
        markdown: ROIV_Vozvrat_regionalnogo_SZPK,
        position: '5.4.3',
      },
      {
        id: '1474',
        name: 'Отрицательное заключение по заявлению',
        parentBaseId: '1444',
        children: [],
        markdown: ROIV_Otricatelnoe_zaklyuchenie,
        position: '5.4.4',
      },
    ],
    markdown: ROIV_Regionalnye_SZPK,
    position: '5.4',
  },
  '1445': {
    id: '1445',
    name: 'Рассмотрение ходатайств о связанности',
    parentBaseId: '1471',
    children: [],
    markdown: ROIV_Rassmotrenie_hodatajstv,
    position: '5.5',
  },
  '1448': {
    id: '1448',
    name: 'Ведение реестра НПА',
    parentBaseId: '1471',
    children: [
      {
        id: '1449',
        name: 'Редактирование НПА и отдельных положений',
        parentBaseId: '1448',
        children: [],
        markdown: ROIV_Redaktirovanie_NPA,
        position: '5.6.1',
      },
      {
        id: '1450',
        name: 'Исключение НПА и отдельных положений из реестра НПА',
        parentBaseId: '1448',
        children: [],
        markdown: ROIV_Isklyuchenie_NPA,
        position: '5.6.2',
      },
    ],
    markdown: ROIV_Vedenie_reestra_NPA,
    position: '5.6',
  },
  '1449': {
    id: '1449',
    name: 'Редактирование НПА и отдельных положений',
    parentBaseId: '1448',
    children: [],
    markdown: ROIV_Redaktirovanie_NPA,
    position: '5.6.1',
  },
  '1450': {
    id: '1450',
    name: 'Исключение НПА и отдельных положений из реестра НПА',
    parentBaseId: '1448',
    children: [],
    markdown: ROIV_Isklyuchenie_NPA,
    position: '5.6.2',
  },
  '1341': {
    id: '1341',
    name: 'Подписание СЗПК',
    parentBaseId: '1253',
    children: [],
    markdown: ROIV_Podpisanie_SZPK,
    position: '5.3.1',
  },
  '1558': {
    id: '1558',
    name: 'Отказ в заключении СЗПК',
    parentBaseId: '1253',
    children: [],
    markdown: ROIV_Otkaz_v_zaklyuchenii_SZPK,
    position: '5.3.2',
  },
  '1481': {
    id: '1481',
    name: 'Рассмотрение списка НПА регионального СЗПК',
    parentBaseId: '1444',
    children: [],
    markdown: ROIV_Rassmotrenie_NPA_regionalnogo,
    position: '5.4.1',
  },
  '1472': {
    id: '1472',
    name: 'Принятие заявления регионального СЗПК',
    parentBaseId: '1444',
    children: [],
    markdown: ROIV_Prinyatie_regionalnogo_SZPK,
    position: '5.4.2',
  },
  '1473': {
    id: '1473',
    name: 'Принятие заявления регионального СЗПК',
    parentBaseId: '1444',
    children: [],
    markdown: ROIV_Vozvrat_regionalnogo_SZPK,
    position: '5.4.2',
  },
  '1474': {
    id: '1474',
    name: 'Отрицательное заключение по заявлению',
    parentBaseId: '1444',
    children: [],
    markdown: ROIV_Otricatelnoe_zaklyuchenie,
    position: '5.4.4',
  },
  '1239': {
    id: '1239',
    name: 'Федеральному органу исполнительной власти',
    parentBaseId: null,
    children: [
      {
        id: '1502',
        name: 'Рассмотрение списка НПА',
        parentBaseId: '1239',
        children: [],
        markdown: FOIV_Rassmotrenie_NPA,
        position: '6.1',
      },
      {
        id: '1467',
        name: 'Отказ по заключению УО',
        parentBaseId: '1239',
        children: [],
        markdown: FOIV_Otkaz_po_zaklyucheniyu_UO,
        position: '6.2',
      },
      {
        id: '1011',
        name: 'Рассмотрение СЗПК',
        parentBaseId: '1239',
        children: [
          {
            id: '1298',
            name: 'Подписание СЗПК',
            parentBaseId: '1011',
            children: [],
            markdown: FOIV_Podpisanie_SZPK,
            position: '6.3.1',
          },
          {
            id: '1804',
            name: 'Отказ в заключении СЗПК',
            parentBaseId: '1011',
            children: [],
            markdown: FOIV_Otkaz_v_zaklyuchenii_SZPK,
            position: '6.3.2',
          },
        ],
        markdown: FOIV_Rassmotrenie_SZPK,
        position: '6.3',
      },
      {
        id: '1254',
        name: 'Рассмотрение ходатайств о связанности',
        parentBaseId: '1239',
        children: [],
        markdown: FOIV_Rassmotrenie_hodatajstv,
        position: '6.4',
      },
      {
        id: '1255',
        name: 'Ведение реестра НПА',
        parentBaseId: '1239',
        children: [
          {
            id: '1257',
            name: 'Редактирование НПА и отдельных положений',
            parentBaseId: '1255',
            children: [],
            markdown: FOIV_Redaktirovanie_NPA,
            position: '6.5.1',
          },
          {
            id: '1258',
            name: 'Исключение НПА и отдельных положений из реестра НПА',
            parentBaseId: '1255',
            children: [],
            markdown: FOIV_Isklyuchenie_NPA,
            position: '6.5.2',
          },
        ],
        markdown: FOIV_Vedenie_reestra_NPA,
        position: '6.5',
      },
    ],
    markdown: '',
    position: '6.',
  },
  '1502': {
    id: '1502',
    name: 'Рассмотрение списка НПА',
    parentBaseId: '1239',
    children: [],
    markdown: FOIV_Rassmotrenie_NPA,
    position: '6.1',
  },
  '1467': {
    id: '1467',
    name: 'Отказ по заключению УО',
    parentBaseId: '1239',
    children: [],
    markdown: FOIV_Otkaz_po_zaklyucheniyu_UO,
    position: '6.2',
  },
  '1254': {
    id: '1254',
    name: 'Рассмотрение ходатайств о связанности',
    parentBaseId: '1239',
    children: [],
    markdown: FOIV_Rassmotrenie_hodatajstv,
    position: '6.4',
  },
  '1255': {
    id: '1255',
    name: 'Ведение реестра НПА',
    parentBaseId: '1239',
    children: [
      {
        id: '1257',
        name: 'Редактирование НПА и отдельных положений',
        parentBaseId: '1255',
        children: [],
        markdown: FOIV_Redaktirovanie_NPA,
        position: '6.5.1',
      },
      {
        id: '1258',
        name: 'Исключение НПА и отдельных положений из реестра НПА',
        parentBaseId: '1255',
        children: [],
        markdown: FOIV_Isklyuchenie_NPA,
        position: '6.5.2',
      },
    ],
    markdown: FOIV_Vedenie_reestra_NPA,
    position: '6.5',
  },
  '1257': {
    id: '1257',
    name: 'Редактирование НПА и отдельных положений',
    parentBaseId: '1255',
    children: [],
    markdown: FOIV_Redaktirovanie_NPA,
    position: '6.5.1',
  },
  '1258': {
    id: '1258',
    name: 'Исключение НПА и отдельных положений из реестра НПА',
    parentBaseId: '1255',
    children: [],
    markdown: FOIV_Isklyuchenie_NPA,
    position: '6.5.2',
  },
  '1011': {
    id: '1011',
    name: 'Рассмотрение СЗПК',
    parentBaseId: '1239',
    children: [
      {
        id: '1298',
        name: 'Подписание СЗПК',
        parentBaseId: '1011',
        children: [],
        markdown: FOIV_Podpisanie_SZPK,
        position: '6.3.1',
      },
      {
        id: '1804',
        name: 'Отказ в заключении СЗПК',
        parentBaseId: '1011',
        children: [],
        markdown: FOIV_Otkaz_v_zaklyuchenii_SZPK,
        position: '6.3.2',
      },
    ],
    markdown: FOIV_Rassmotrenie_SZPK,
    position: '6.3',
  },
  '1298': {
    id: '1298',
    name: 'Подписание СЗПК',
    parentBaseId: '1011',
    children: [],
    markdown: FOIV_Podpisanie_SZPK,
    position: '6.3.1',
  },
  '1804': {
    id: '1804',
    name: 'Отказ в заключении СЗПК',
    parentBaseId: '1011',
    children: [],
    markdown: FOIV_Otkaz_v_zaklyuchenii_SZPK,
    position: '6.3.2',
  },
  '1581': {
    id: '1581',
    name: 'Федеральному казначейству',
    parentBaseId: null,
    children: [
      {
        id: '1668',
        name: 'Внесение регистрационных данных СЗПК',
        parentBaseId: '1581',
        children: [],
        markdown: Registraciya_v_reestre,
        position: '7.1',
      },
      {
        id: '1562',
        name: 'Внесение регистрационных данных дополнительного соглашения в рамках ходатайства о связанности',
        parentBaseId: '1581',
        children: [],
        markdown: Registraciya_v_reestre_hodatajstva,
        position: '7.2',
      },
      {
        id: '3000',
        name: 'Внесение регистрационных данных дополнительного соглашения о присоединении муниципального образования',
        parentBaseId: '1581',
        children: [],
        markdown: Registraciya_v_reestre_DS,
        position: '7.3',
      },
    ],
    markdown: '',
    position: '7.',
  },
  '1668': {
    id: '1668',
    name: 'Внесение регистрационных данных СЗПК',
    parentBaseId: '1581',
    children: [],
    markdown: Registraciya_v_reestre,
    position: '7.1',
  },
  '1562': {
    id: '1562',
    name: 'Внесение регистрационных данных дополнительного соглашения в рамках ходатайства о связанности',
    parentBaseId: '1581',
    children: [],
    markdown: Registraciya_v_reestre_hodatajstva,
    position: '7.2',
  },
  '3000': {
    id: '3000',
    name: 'Внесение регистрационных данных дополнительного соглашения о присоединении муниципального образования',
    parentBaseId: '1581',
    children: [],
    markdown: Registraciya_v_reestre_DS,
    position: '7.3',
  },
})

//Формируется порядок справочника, id - уникальный
const mockKnowledgeBase: IKnowledgeBaseItem[] = [
  mockKnowledgeHashMap['1673'],
  mockKnowledgeHashMap['1884'],
  mockKnowledgeHashMap['2001'],
  mockKnowledgeHashMap['1813'],
  mockKnowledgeHashMap['1471'],
  mockKnowledgeHashMap['1239'],
  mockKnowledgeHashMap['1581'],
]

export { mockKnowledgeBase, mockKnowledgeHashMap }
