import React from 'react'
import { Path, useFormContext } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { financialModelBlockValues } from '@components/Forms/FinancialModelForm/const'
import { thirdSectionFinancialModelValidationMap } from '@components/Forms/FinancialModelForm/Forms/3/validation'
import { useFinancialModelManager } from '@components/Forms/FinancialModelForm/Manager'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { FinancialModelFieldsControlUpdateWatcher } from '@components/Forms/FinancialModelForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

const { transformRHFPathInProperties, getRublesFromBillion } = DocumentFormHelpers

const Three = () => {
  const formInstance = useFormContext<FinancialModelFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      getPropertiesProps,
      handleChangeCustomValue,
    },
    preparedProps: { subscribableControl },
  } = useFinancialModelManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: FinancialModelFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleUpdateBillionValue = async (pathName: Path<FinancialModelFormValues>) => {
    const propertyPropsPath = transformRHFPathInProperties(pathName)

    const propertyProps = getObjectValue(getPropertiesProps?.(), propertyPropsPath)

    if (!propertyProps) return

    const value = formInstance.getValues(pathName) as string

    await handleChangeCustomValue?.({
      property: {
        id: propertyProps.propertyId,
        lastUpdateDt: propertyProps.lastUpdateDt,
      },
      type: propertyProps.type,
      newValue: Math.round(getRublesFromBillion(value) * 100),
    })
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: financialModelBlockValues['3'].netPresentValue,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: financialModelBlockValues['3'].netPresentValue,
                  rules: thirdSectionFinancialModelValidationMap.netPresentValue,
                  inputProps: {
                    minority: 1000000,
                    hasNegativeValue: true,
                    label: 'Чистая приведенная стоимость, млрд',
                    dataTestId: 'netPresentValue',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleUpdateBillionValue?.(financialModelBlockValues['3'].netPresentValue),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: financialModelBlockValues['3'].internalRateReturn,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: financialModelBlockValues['3'].internalRateReturn,
                  rules: thirdSectionFinancialModelValidationMap.internalRateReturn,

                  inputProps: {
                    hasNegativeValue: true,
                    suffix: '%',
                    placeholder: '',
                    label: 'Внутренняя норма доходности',
                    dataTestId: 'internalRateReturn',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        financialModelBlockValues['3'].internalRateReturn,
                        undefined,
                        undefined,
                        { hasNegativeValue: true },
                      )
                    }, 0),

                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        financialModelBlockValues['3'].internalRateReturn,
                        undefined,
                        undefined,
                        { hasNegativeValue: true },
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Three
