import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'

import { removePx } from '@helpers/manipulateWithPx'

const useReferenceBooksDynamicHeader = (dependencyToUpdateHeaderHeight?: DependencyList) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0)

  const headerRef = useRef<HTMLElement | null>(null)

  const handleUpdateHeight = useCallback((headerElement: HTMLElement) => {
    const { height } = window.getComputedStyle(headerElement)

    const heightOfHeader = removePx(height)

    setHeaderHeight(heightOfHeader)
  }, [])

  const handleUpdateHeaderOnResize = useCallback(() => {
    if (!headerRef.current) return

    handleUpdateHeight(headerRef.current)
  }, [handleUpdateHeight])

  useEffect(() => {
    if (!headerRef.current) return

    handleUpdateHeight(headerRef.current)
  }, dependencyToUpdateHeaderHeight)

  useEffect(() => {
    window.addEventListener('resize', handleUpdateHeaderOnResize)

    return () => {
      window.removeEventListener('resize', handleUpdateHeaderOnResize)
    }
  }, [])

  return {
    headerRef,
    headerHeight,
  }
}

export { useReferenceBooksDynamicHeader }
