import { FC } from 'react'

import First from './1'
import Second from './2'

interface NotificationDrawalAppConclusionSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const NotificationDrawalAppConclusionSZPKForms: NotificationDrawalAppConclusionSZPKFormsProps =
  () => null

NotificationDrawalAppConclusionSZPKForms.First = First
NotificationDrawalAppConclusionSZPKForms.Second = Second

export default NotificationDrawalAppConclusionSZPKForms
