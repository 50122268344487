import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import LoginByESIA from '@components/Forms/LoginForm/LoginByESIA'
import { ESIARedirectTypes } from '@components/Forms/LoginForm/LoginByESIA/constants'
import { mapOfTypeRedirect } from '@components/Forms/RegistrationForm/const'
import { RegistrationLocationState } from '@components/Forms/RegistrationForm/types'
import { isLoginByLP } from '@constants/system'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { useQueryManager } from '@hooks/useQueryManager'

import LoginByAuth from './LoginByAuth'
import LoginByCrypt from './LoginByCrypt'

const LoginForm = () => {
  const { resetKapClient } = useAuthContext()
  const { queryUtils } = useQueryManager()

  const {
    ESIAApi: { getESIALoginLink },
  } = useAPIContext()

  const location = useLocation()
  const locationState = location.state as RegistrationLocationState | undefined

  const isShowAuthForm = isLoginByLP
  const isShowESIAForm = ESIARedirectTypes.includes(queryUtils.getQuery('redirect_from') || '')

  useEffect(() => {
    resetKapClient?.()

    //Редирект с регистрации на ESIA.
    if (locationState?.from !== mapOfTypeRedirect.ESIA) return
    ;(async () => {
      await getESIALoginLink()
    })()
  }, [])

  if (isShowESIAForm) return <LoginByESIA />

  if (isShowAuthForm) return <LoginByAuth />

  return <LoginByCrypt />
}

export default LoginForm
