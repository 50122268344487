import React, { useMemo } from 'react'

import Error500Fallback from '@components/DataFallback/500Error'
import Button from '@components/NewDesign/Button'
import { useCreateProjectModal } from '@components/NewDesignedModals/CreateProjectModal/manager'
import Projects from '@components/Projects'
import ProjectItemPreview from '@components/Projects/Item/Preview'
import { Skeleton } from '@components/Skeleton/Skeleton'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { useAvailableConfigsByRole } from '@hooks/new/configs/useAvailableDfoConfigsByRole'
import { useProjectStatusesOnTheProjectsPage } from '@hooks/new/statuses/useProjectStatusOnTheProjectPage'
import { useDfoTypes } from '@hooks/new/swr/useDfoTypes'
import { useProjects } from '@hooks/new/swr/useProjects'
import AddButtonIcon from '@icons/AddButtonIcon.svg'
import Layouts from '@layouts'
import CreateProjectPage from '@routes/Projects/CreateProjectPage'
import { keyForUseProjects } from '@routes/Projects/helpers'
import { GetRightsToSZPK } from '@routes/Projects/shared/GetRightToSZPK/GetRightsToSZPK'
import ProjectsPageLoader from '@routes/Projects/shared/Loader'
import { ProjectService } from '@services/Projects/Project.service'

import styles from './ProjectsPage.module.scss'

const { prepareProjectsToRender } = ProjectService

const previewVariantByColor = {
  green: 'success',
  orange: 'warning',
  red: 'error',
} as const

const PAGINATION_BOTTOM_TRIGGER = 100
const DEFAULT_PROJECTS_PAGESIZE = 15

const ProjectsPage = () => {
  //Проверяются наличие проектов у пользователя (инвестора)
  const {
    projects,
    currentPage,
    hasMoreData,
    incPage,
    isLoadingProjects,
    error: projectsError,
  } = useProjects({
    key: keyForUseProjects,
    config: {
      revalidateOnMount: true,
      revalidateFirstPage: false,
    },
    pageSize: DEFAULT_PROJECTS_PAGESIZE,
  })

  const { handleOpenCreateProjectModal } = useCreateProjectModal()
  const projectsStatuses = useProjectStatusesOnTheProjectsPage()

  const { dfoTypes } = useDfoTypes({ key: { _key: 'dfoTypes' } })
  const { isLoadingRoleConfig, getConfigForDfo } = useAvailableConfigsByRole()

  const onHeaderContentScroll = async (e) => {
    const requestCondition = !projectsError && !isLoadingProjects && hasMoreData

    if (
      e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) <
        PAGINATION_BOTTOM_TRIGGER &&
      requestCondition
    ) {
      await incPage((size) => size + 1)
    }
  }

  const preparedProjects = useMemo(() => {
    if (!projects || !dfoTypes || isLoadingRoleConfig) return

    return prepareProjectsToRender({ projects, dfoTypes })
  }, [dfoTypes, projects, isLoadingRoleConfig])

  const projectsIsReadyToRender = !isLoadingProjects && !projectsError
  const hasProjectsServerError = isIntervalServerError(projectsError?.response?.status)

  //Если нет, то открывается страница с созданием первого проекта
  if (!projects?.length && projectsIsReadyToRender) {
    return <CreateProjectPage />
  }

  return (
    <Layouts.Main
      headerContentContainer={{
        onScroll: onHeaderContentScroll,
      }}
    >
      <Projects.Container className={styles.projectsPage__container}>
        <Skeleton
          animate
          className={styles.projectsPage__buttonSkeleton}
          visible={isLoadingProjects && !projectsError}
        >
          {projectsIsReadyToRender && (
            <Button
              dataTestId="createProjectSzpk"
              size={'s'}
              view={'plain'}
              leadingIcon={{ src: AddButtonIcon }}
              className={styles.projectsPage__add}
              onClick={handleOpenCreateProjectModal}
            >
              Создать соглашение
            </Button>
          )}
        </Skeleton>
        <Error500Fallback title={'Не удалось загрузить соглашения'} error={hasProjectsServerError}>
          <div className={styles.projectsPage__items}>
            {preparedProjects?.map((project, index) => {
              const status = projectsStatuses[project.id]

              return (
                <Projects.Item
                  key={index}
                  status={status}
                  projectInfo={{
                    isOldStatement: project.isOldStatement,
                    projectName: project.name,
                    projectStatus: status.status,
                    projectDetail: status.additionalText,
                    isFederal: project.isFederal,
                  }}
                >
                  {project.dfos.map((dfo, index) => {
                    const params = getConfigForDfo({
                      dfo: {
                        stage: dfo.stage,
                        actionAllowed: dfo.actionAllowed,
                        type: dfo.type,
                        version: dfo.version,
                      },
                      isFederal: project.isFederal,
                    })

                    if (!params) return null

                    return (
                      <ProjectItemPreview
                        key={index}
                        deadlineInfo={dfo.deadline}
                        parentProjectInfo={{
                          id: project.id,
                        }}
                        dfoInfo={{
                          id: dfo.id,
                          name: dfo.typeName,
                          type: dfo.type,
                          status: params?.stageDescription || dfo.stage,
                        }}
                        config={{
                          color: params?.stageStyle,
                          icon: params?.stageIcon,
                        }}
                        variant={
                          previewVariantByColor[params?.stageStyle] || previewVariantByColor.green
                        }
                      />
                    )
                  })}
                </Projects.Item>
              )
            })}
            {(isLoadingProjects || isLoadingRoleConfig) && (
              //При дозагрузке новых проектов, показываем скелетон в виде одного проекта
              <ProjectsPageLoader countOfEntity={currentPage < 2 ? 3 : 1} />
            )}
          </div>
        </Error500Fallback>
        {!projectsError && !isLoadingProjects && !isLoadingRoleConfig && <GetRightsToSZPK />}
      </Projects.Container>
    </Layouts.Main>
  )
}

export default ProjectsPage
