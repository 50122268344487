import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import styles from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/StepCompensations/StepCompensations.module.scss'
import { SeventhStagesStepCompensationArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Typography from '@components/NewDesign/Typography'
import { documentsLinks } from '@constants/documents'

import { stepCompensationValidationMap } from './validation'

interface StepCompensationItemProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  name: SeventhStagesStepCompensationArrayPathName
  index: number
  leftAddons?: ReactNode
  onRemove?: () => Promise<void>
}

const StepCompensationItem: FC<StepCompensationItemProps> = ({
  editMode,
  blockViewIsValidating,
  index,
  name,
  formInstance,
  formModifierInstance,
  leftAddons,
  onRemove,
}) => {
  const {
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${name}.isNew`,
    control: formModifierInstance.control,
  })

  const currentValueOfType = useWatch({
    name: `${name}.stepCompensationType`,
    control: formInstance.control,
  })
  const projectCompensationPeriod = useWatch({
    name: `${name}.stepCompensationPeriod`,
    control: formInstance.control,
  })

  const { getFormFieldControlProps, getSingleSelectProps } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  return (
    <GroupWithRemoveButton
      disableBottomBorder
      id={name}
      title={`Вид №${index + 1}`}
      leftAddons={leftAddons}
      onRemove={onRemove}
    >
      <FormFieldControl
        {...getFormFieldControlProps({
          path: `${name}.stepCompensationType`,
          formFieldTooltipProps: {
            onChange: () =>
              setTimeout(() => handleChangeValue?.(`${name}.stepCompensationType`), 0),
            onDifference: handleUpdateChanges,
          },
        })}
      >
        {({ overrideProps }) => {
          const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
            ? overrideProps.fetcherOptions
            : undefined

          return (
            <ControlledFormSingleSelect
              {...getSingleSelectProps({
                fetcherProps,
                optionsAdapter: (item) => ({
                  displayValue: item.name,
                  value: item.id,
                }),
                selectProps: {
                  inputProps: {
                    label: 'Вид затрат',
                  },
                  onChangeFormValue: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.stepCompensationType`), 0),
                },
                controllerProps: {
                  name: `${name}.stepCompensationType`,
                  rules: stepCompensationValidationMap.stepCompensationType,
                },
              })}
            />
          )
        }}
      </FormFieldControl>
      {currentValueOfType && projectCompensationPeriod && (
        <Typography.Caption
          className={styles.compensation__caption}
          color={'text-base-tertiary'}
          variant={'captionSRegular'}
        >
          {`Срок возмещения затрат составит до ${projectCompensationPeriod}`}
          <Typography.Caption as={'span'} variant={'captionSRegular'} color={'text-accent-brand'}>
            <a href={documentsLinks.FZ_N69} target="_blank" rel="noopener noreferrer">
              &nbsp;(часть 6 статьи 15 69-ФЗ)
            </a>
          </Typography.Caption>
        </Typography.Caption>
      )}
    </GroupWithRemoveButton>
  )
}

export default StepCompensationItem
