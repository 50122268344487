import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NotificationFillingAppConcludingFormValues } from '@components/Forms/NotificationFillingAppConcludingForm/types'

const { getDefaultUOFieldState, getDefaultInvestorFieldState, getDefaultInfoAboutDocument } =
  FieldStateHelpers

const mapOfNotificationFillingAppConcludingMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченная организация' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Выявленные нарушения' },
]

const defaultNotificationFillingAppConcludingFormValues: NotificationFillingAppConcludingFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': getDefaultUOFieldState(),
    '3': {
      ...getDefaultInvestorFieldState(),
      investorFullNameGenitive: '',
      investorInn: '',
      investorOgrn: '',
      investorOktmo: '',
      investorPaymentDetails: '',
      investorDetails: '',
      investorLocation: '',
      investorAddress: '',
    },
    '4': {
      projectName: '',
      ruleLinkExtraError: '',
      extraTechError: '',
      extraSetDocumentError: '',
      extraRuleLinkError: '',
      extraOtherError: '',
      extraOtherDetails: '',
      extraDaysRevision: '',
    },
  }

const notificationFillingAppConcludingBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    uoFullName: '2.uoFullName',
    uoHeaderName: '2.uoHeaderName',
    uoHeaderNameGenitive: '2.uoHeaderNameGenitive',
    uoHeaderPosition: '2.uoHeaderPosition',
    uoHeaderPositionGenitive: '2.uoHeaderPositionGenitive',
    uoHeaderReasonGenitive: '2.uoHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameGenitive: '3.investorFullNameGenitive',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorOktmo: '3.investorOktmo',
    investorPaymentDetails: '3.investorPaymentDetails',
    investorDetails: '3.investorDetails',
    investorLocation: '3.investorLocation',
    investorAddress: '3.investorAddress',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
  },
  '4': {
    projectName: '4.projectName',
    ruleLinkExtraError: '4.ruleLinkExtraError',
    extraTechError: '4.extraTechError',
    extraSetDocumentError: '4.extraSetDocumentError',
    extraRuleLinkError: '4.extraRuleLinkError',
    extraOtherError: '4.extraOtherError',
    extraOtherDetails: '4.extraOtherDetails',
    extraDaysRevision: '4.extraDaysRevision',
  },
} as const

export {
  defaultNotificationFillingAppConcludingFormValues,
  mapOfNotificationFillingAppConcludingMenu,
  notificationFillingAppConcludingBlockValues,
}
