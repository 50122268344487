import { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import CertificateOfComplianceContractForRecognitionConcludedA from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms'
import {
  defaultCertificateOfComplianceContractForRecognitionConcludedAFormValues,
  mapOfCertificateOfComplianceContractForRecognitionConcludedAMenu,
} from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/const'
import { CertificateOfComplianceContractForRecognitionConcludedAFormValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/types'
import { CertificateOfComplianceContractForRecognitionConcludedFieldsControlUpdateWatcher } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import CertificateOfComplianceContractForRecognitionConcludedALayoutWrapper from './Wrapper'

const CertificateOfComplianceContractForRecognitionConcludedALayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance =
    useFormWithDefaultProps<CertificateOfComplianceContractForRecognitionConcludedAFormValues>({
      defaultValues: defaultCertificateOfComplianceContractForRecognitionConcludedAFormValues,
    })

  const handleOnClose = () => {
    CertificateOfComplianceContractForRecognitionConcludedFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <CertificateOfComplianceContractForRecognitionConcludedA.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={
              mapOfCertificateOfComplianceContractForRecognitionConcludedAMenu[0].id
            }
          >
            <CertificateOfComplianceContractForRecognitionConcludedALayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Справка о соответствии ходатайства о признании ранее заключенного договора
                  связанным договором требованиям
                </Typography.Headline>
                <CertificateOfComplianceContractForRecognitionConcludedA.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <CertificateOfComplianceContractForRecognitionConcludedA.Menu
                  className={styles.layout__menu}
                />
              </div>
            </CertificateOfComplianceContractForRecognitionConcludedALayoutWrapper>
          </FlatMenuManager>
        </CertificateOfComplianceContractForRecognitionConcludedA.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(CertificateOfComplianceContractForRecognitionConcludedALayout)
