import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { investorFieldsValidation } from '@components/Forms/CreateStatement/Forms/Investor/validation'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { CreateStatementControlUpdateWatcher } from '@components/Forms/CreateStatement/watcher'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import ControlledMultipleInput from '@components/NewDesign/MultipleInput/ControlledMultipleInput'
import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

const { transformRHFPathInProperties } = DocumentFormHelpers

const InvestorForm = () => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      getPropertiesProps,
      debouncedHandleChangeValue,
      handleChangeValue,
      handleAddItemToListWithValue,
      handleRemoveCustomValue,
    },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getCheckboxProps,
    getSingleSelectProps,
    getMultipleInputProps,
    getMaskInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    watcher: CreateStatementControlUpdateWatcher,
    subscribableControl,
  })

  if (!formInstance) return null

  const handleRemoveEmailOnChipClick = (
    _payload: MultipleInputValue[],
    valueToRemove: MultipleInputValue,
  ) => {
    const propertyInvestorHeaderEmail = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(statementBlockValues.investor.investorHeaderEmail),
    )

    if (!propertyInvestorHeaderEmail) return

    const propertyParentToRemove = {
      id: propertyInvestorHeaderEmail.propertyId,
      lastUpdateDt: propertyInvestorHeaderEmail.lastUpdateDt,
    }

    handleRemoveCustomValue?.(propertyParentToRemove, valueToRemove.value)
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.investor.investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Наименование"
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
                controllerProps={getControllerProps({
                  name: statementBlockValues.investor.investorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.investor.investorName,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Сокращенное наименование"
                controllerProps={getControllerProps({
                  name: statementBlockValues.investor.investorName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.investor.investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ИНН"
                controllerProps={getControllerProps({
                  name: statementBlockValues.investor.investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.investor.investorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ОГРН"
                controllerProps={getControllerProps({
                  name: statementBlockValues.investor.investorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.investor.investorKpp,
              })}
            >
              <ControlledDocumentDataView
                suptitle="КПП"
                controllerProps={getControllerProps({
                  name: statementBlockValues.investor.investorKpp,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.investor.investorOkpo,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ОКПО"
                controllerProps={getControllerProps({
                  name: statementBlockValues.investor.investorOkpo,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.investor.investorOkved,
              })}
            >
              <ControlledDocumentDataView
                suptitle="ОКВЭД (основной)"
                controllerProps={getControllerProps({
                  name: statementBlockValues.investor.investorOkved,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.investor.investorCapital,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Уставной капитал"
                controllerProps={getControllerProps({
                  name: statementBlockValues.investor.investorCapital,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <AsyncWrapper promise={handleChangeValue}>
          {({ isLoading: isParticipantChecking, wrappedPromise: handleCheckParticipant }) => (
            <>
              <Row className={styles.form__checkboxRow}>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: statementBlockValues.investor.isProjectCompany,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: statementBlockValues.investor.isProjectCompany,
                        checkBoxProps: {
                          disabled: isParticipantChecking || !editMode,
                          label: 'Проектная компания',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckParticipant(statementBlockValues.investor.isProjectCompany)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row className={styles.form__checkboxRow}>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: statementBlockValues.investor.isVed,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: statementBlockValues.investor.isVed,
                        checkBoxProps: {
                          disabled: isParticipantChecking || !editMode,
                          label: 'Участник внешнеэкономической деятельности',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckParticipant(statementBlockValues.investor.isVed)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row className={styles.form__checkboxRow}>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: statementBlockValues.investor.isKgn,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: statementBlockValues.investor.isKgn,
                        checkBoxProps: {
                          disabled: isParticipantChecking || !editMode,
                          label: 'Участник консолидированной группы налогоплательщиков',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckParticipant(statementBlockValues.investor.isKgn)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </>
          )}
        </AsyncWrapper>
        <Group disableBottomBorder title="Юридический адрес">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.region,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Субъект"
                    formIconTooltipProps={{
                      tooltipContent: 'Адрес можно изменить в разделе "Моя организация"',
                    }}
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.region,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.city,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Населенный пункт (город)"
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.city,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.district,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Муниципальный район или округ"
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.district,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.area,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Поселение, межселенная территория, внутригородской район"
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.area,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.planningStructureElement,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Элемент планировочной структуры"
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.planningStructureElement,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.roadNetworkElement,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Улица"
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.roadNetworkElement,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.house,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Дом"
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.house,
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.room,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Квартира или офис"
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.room,
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={4}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.roomPart,
                  })}
                >
                  <ControlledDocumentDataView
                    suptitle="Комната"
                    controllerProps={getControllerProps({
                      name: statementBlockValues.investor.roomPart,
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное лицо организации'}
          formInstance={formInstance}
          formSection={'investor'}
          prefixFormFieldName={'investor'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementBlockValues.investor.investorHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: statementBlockValues.investor.investorHeaderName,
                      rules: investorFieldsValidation.investorHeaderName,
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                        dataTestId: 'investorHeaderName',
                      },
                      onChangeFormValue: () =>
                        setTimeout(() => {
                          handleChangeValue?.(statementBlockValues.investor.investorHeaderName)
                        }, 0),
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
        <Group disableBottomBorder title="Контакты уполномоченного лица">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.investorHeaderEmail,
                  })}
                >
                  <ControlledMultipleInput
                    {...getMultipleInputProps({
                      name: statementBlockValues.investor.investorHeaderEmail,
                      rules: investorFieldsValidation.investorHeaderEmail,
                      inputProps: {
                        label: 'Электронная почта для связи',
                        dataTestId: 'investorHeaderEmail',
                        caption:
                          'добавьте одну или несколько, чтобы другие участники соглашения могли связаться с вашей организацией при необходимости',
                      },
                      onRemoveChip: handleRemoveEmailOnChipClick,
                      onAddChip: (_payload: MultipleInputValue[], newValue: MultipleInputValue) =>
                        setTimeout(() => {
                          handleAddItemToListWithValue?.(
                            statementBlockValues.investor.investorHeaderEmail,
                            newValue.displayValue,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.investorHeaderPhone,
                  })}
                >
                  <ControlledMaskInput
                    {...getMaskInputProps({
                      name: statementBlockValues.investor.investorHeaderPhone,
                      rules: investorFieldsValidation.investorHeaderPhone,
                      inputProps: {
                        mask: '+7 (999) 999-99-99',
                        label: 'Телефон для связи',
                        dataTestId: 'investorHeaderPhone',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(statementBlockValues.investor.investorHeaderPhone)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            statementBlockValues.investor.investorHeaderPhone,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.investor.investorHeaderAddPhone,
                  })}
                >
                  <ControlledMaskInput
                    {...getMaskInputProps({
                      name: statementBlockValues.investor.investorHeaderAddPhone,
                      rules: investorFieldsValidation.investorHeaderAddPhone,
                      inputProps: {
                        mask: '9999999999',
                        maskPlaceholder: '',
                        label: 'Добавочный номер',
                        caption: 'если есть',
                        dataTestId: 'investorHeaderAddPhone',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(statementBlockValues.investor.investorHeaderAddPhone)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            statementBlockValues.investor.investorHeaderAddPhone,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default memo(InvestorForm)
