import { RolesTypes } from '@constants/types'

const defaultValidation = {
  phone: true,
  email: true,
}

const validationOrganizationByRoles: Record<
  keyof Omit<typeof RolesTypes, 'OPERATOR'>,
  Record<string, boolean>
> = {
  [RolesTypes.INVESTOR]: {
    ...defaultValidation,
    paymentDetails: true,
    okpo: true,
    oktmo: true,
  },
  [RolesTypes.MER]: {
    ...defaultValidation,
    paymentDetails: true,
    okpo: true,
    oktmo: true,
  },
  [RolesTypes.ROIV]: {
    ...defaultValidation,
    paymentDetails: true,
    okpo: true,
    oktmo: true,
  },
  [RolesTypes.OIV]: {
    ...defaultValidation,
    paymentDetails: true,
    okpo: true,
    oktmo: true,
  },
  [RolesTypes.UO]: defaultValidation,
  [RolesTypes.FK]: defaultValidation,
  [RolesTypes.OMSU]: defaultValidation,
  [RolesTypes.OIV_EXTRA]: defaultValidation,
}

export { validationOrganizationByRoles }
