import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfCertificateOfComplianceContractForRecognitionConcludedAMenu } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/const'
import CertificateOfComplianceContractForRecognitionConcludedAForms from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/Forms'
import { useCertificateOfComplianceContractForRecognitionConcludedAManager } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/Manager'
import { CertificateOfComplianceContractForRecognitionConcludedAFormValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/types'

const mapOfCertificateOfComplianceContractForRecognitionConcludedAForms: FormItemProps = {
  '1': {
    node: <CertificateOfComplianceContractForRecognitionConcludedAForms.First />,
  },
  '2': {
    node: <CertificateOfComplianceContractForRecognitionConcludedAForms.Second />,
  },
  '3': {
    node: <CertificateOfComplianceContractForRecognitionConcludedAForms.Third />,
  },
} as const

const CertificateOfComplianceContractForRecognitionConcludedAForm: FC<FormModalStatementProps> = ({
  ...rest
}) => {
  const formInstance =
    useFormContext<CertificateOfComplianceContractForRecognitionConcludedAFormValues>()

  const {
    state: { formIsLoading },
  } = useCertificateOfComplianceContractForRecognitionConcludedAManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfCertificateOfComplianceContractForRecognitionConcludedAMenu}
      mapOfForms={mapOfCertificateOfComplianceContractForRecognitionConcludedAForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(CertificateOfComplianceContractForRecognitionConcludedAForm)
