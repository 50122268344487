import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionRegistration,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const eRegistrationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionRegistration,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  objectType: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  objectName: {
    required: defaultRHFValidation.required,
  },
  estateObjectStateRegistrationDate: {
    required: defaultRHFValidation.required,
  },
  estateObjectStateRegistrationNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  estateObjectStateRegistrationOrganization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { eRegistrationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
