import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const fourthSectionCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsFormValues['4'],
  CertificateOnMonitoringResultsFormValues
> = {
  projectResultEffectChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
}

export { fourthSectionCertificateOnMonitoringResultsValidationMap }
