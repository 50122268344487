import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { useNotificationOfIdentifiedNPAListErrorsManager } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/Manager'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/types'
import {
  NotificationOfIdentifiedNPAListErrorsFieldCollapseControlUpdateWatcher,
  NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher,
} from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { NpaErrorPathName } from '../../types'

import styles from './NpaError.module.scss'
import { npaErrorValidationMap } from './validation'

interface NpaErrorProps {
  formName: NpaErrorPathName
  npaName: string
  indexOfError: number
  parentAnchorId: string
}

const NpaError: FC<NpaErrorProps> = ({ formName, npaName, indexOfError, parentAnchorId }) => {
  const formInstance = useFormContext<NotificationOfIdentifiedNPAListErrorsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedNPAListErrorsManager()

  const { getSubscribableControlProps, getControllerProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher,
    })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name: formName,
    watcher: NotificationOfIdentifiedNPAListErrorsFieldCollapseControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <SubscribableControl
      {...getSubscribableControlProps({
        path: formName,
      })}
    >
      <Accordion
        id={String(indexOfError)}
        title={npaName}
        isExpanded={isExpanded}
        anchorId={`${parentAnchorId}.${indexOfError + 1}`}
        onToggle={onToggleCollapse}
      >
        <Stack direction={'vertical'} gap={3} className={styles.npaError__stack}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.npaName`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Наименование стабилизируемого акта'}
                  controllerProps={getControllerProps({
                    name: `${formName}.npaName`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.npaParts`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Положения акта из реестра НПА'}
                  controllerProps={getControllerProps({
                    name: `${formName}.npaParts`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.npaJustification`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Обоснование'}
                  controllerProps={getControllerProps({
                    name: `${formName}.npaJustification`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.npaCorrection`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Внесенное ОРП положение'}
                  controllerProps={getControllerProps({
                    name: `${formName}.npaCorrection`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.errorText`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.errorText`,
                    rules: npaErrorValidationMap.errorText,
                    textareaProps: {
                      label: 'Выявленные несоответствия',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.errorText`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.errorText`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </Accordion>
    </SubscribableControl>
  )
}

export default NpaError
