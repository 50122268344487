import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo } from 'react'
import { ReactSVG } from 'react-svg'
import { Props as ReactSVGProps } from 'react-svg/dist/types'

import styles from '@components/Chip/Base/Chip.module.scss'
import ChipCloseIcon from '@icons/ChipCloseIcon.svg'
import cn from 'classnames'

type NativeProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

interface ChipTagProps extends NativeProps {
  label: string
  variant?: 'brand' | 'normal'
  size?: 'l' | 'm'
  leftAddon?: Omit<ReactSVGProps, 'ref'>
  leftAddonClassName?: string
  contentClassName?: string
  onClose?: () => void

  dataTestId?: string
  iconDataTestId?: string
}

const ChipTag: FC<ChipTagProps> = ({
  label,
  variant = 'brand',
  size = 'l',
  type = 'button',
  leftAddon,
  leftAddonClassName,
  contentClassName,
  onClose,
  dataTestId,
  iconDataTestId,
  ...rest
}) => {
  return (
    <button
      {...rest}
      type={type}
      data-testid={dataTestId}
      className={cn(styles.container, styles[size], styles[variant], rest.className)}
    >
      {leftAddon && (
        <ReactSVG
          {...leftAddon}
          src={leftAddon.src as string}
          className={cn(styles.leftAddon, styles[variant], leftAddonClassName)}
        />
      )}
      <div className={cn(styles.content, contentClassName)}>{label}</div>
      {onClose && (
        <ReactSVG
          className={cn(styles.closeIcon, styles[variant])}
          src={ChipCloseIcon}
          data-testid={iconDataTestId}
          wrapper="span"
          onClick={onClose}
        />
      )}
    </button>
  )
}

export default memo(ChipTag)
