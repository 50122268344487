import React, { memo, useCallback } from 'react'

import DocumentsSet from '@components/DocumentsSet'
import styles from '@components/Projects/[id]/DocumentLayout/Navigation/Actions/AddChangesToProject/AddChangesToProject.module.scss'
import { NewDfosType, RolesTypes } from '@constants/types'
import withRolesRender from '@hoc/withRolesRender'
import { useCreateDfo } from '@hooks/new/dfo/useCreateDfo'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

const OtherDonationsAddon = ({ projectId }) => {
  const { handleCreateDfo } = useCreateDfo(projectId)

  const handleCreateOtherDonation = useCallback(async () => {
    try {
      await handleCreateDfo(NewDfosType.OTHER_DONATIONS)()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'OtherDonationsAddon',
          componentType: 'handleCreateOtherDonation',
          moduleName: 'DocumentContentGroup',
        },
        additionInfo: {
          type: NewDfosType.OTHER_DONATIONS,
        },
      })(error)
    }
  }, [handleCreateDfo])

  return (
    <DocumentsSet.ItemCreate
      className={styles.button}
      title={'Указать иные меры поддержки'}
      positionOfIcon={'left'}
      onClick={handleCreateOtherDonation}
    />
  )
}

export default withRolesRender(memo(OtherDonationsAddon), [RolesTypes.INVESTOR])
