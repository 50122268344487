import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeOfProjectRefuseConcludeAABlockValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/const'
import { useNoticeOfProjectRefuseConcludeAAManager } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/Manager'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'
import { NoticeOfProjectRefuseConcludeAAFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { fourthSectionValidationMap } from './validation'

const currentBlockValues = noticeOfProjectRefuseConcludeAABlockValues['4']

const Fourth = () => {
  const formInstance = useFormContext<NoticeOfProjectRefuseConcludeAAFormValues>()
  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOfProjectRefuseConcludeAAManager()

  const { getSubscribableControlProps, getInputProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NoticeOfProjectRefuseConcludeAAFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункт правил, на основании которого подготовлено уведомление об отказе в заключении дополнительного соглашения'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: currentBlockValues.ruleLinkExtraReject,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: currentBlockValues.ruleLinkExtraReject,
                    rules: fourthSectionValidationMap.ruleLinkExtraReject,
                    inputProps: {},
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(currentBlockValues.ruleLinkExtraReject),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(currentBlockValues.ruleLinkExtraReject)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.extraRejectReason,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: currentBlockValues.extraRejectReason,
                  rules: fourthSectionValidationMap.extraRejectReason,
                  textareaProps: {
                    label: 'Основания, в связи с которыми не может быть заключено соглашение',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(currentBlockValues.extraRejectReason), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(currentBlockValues.extraRejectReason)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Fourth
