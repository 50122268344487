import React, { ElementType, FC, Fragment, ReactNode } from 'react'

import { Transition, TransitionClasses, TransitionEvents } from '@headlessui/react'

import styles from './Slide.module.scss'

type TransitionFadeProps = TransitionClasses &
  TransitionEvents & {
    show: boolean
    children: ReactNode
    as?: ElementType
  } & {
    position?: 'left' | 'right'
  }

const mapOfAnimateClasses = {
  left: {
    enter: styles.enterLeft,
    enterTo: styles.enterLeftTo,
    enterFrom: styles.enterLeftFrom,
    leave: styles.leaveLeft,
    leaveTo: styles.leaveLeftTo,
    leaveFrom: styles.leaveLeftFrom,
  },

  right: {
    enter: styles.enterRight,
    enterTo: styles.enterRightTo,
    enterFrom: styles.enterRightFrom,
    leave: styles.leaveRight,
    leaveTo: styles.leaveRightTo,
    leaveFrom: styles.leaveRightFrom,
  },
} as const

const TransitionSlide: FC<TransitionFadeProps> = ({
  show,
  children,
  position = 'right',
  as = Fragment,
  ...restProps
}) => {
  const currentClasses = mapOfAnimateClasses[position]

  return (
    <Transition
      show={show}
      enter={currentClasses.enter}
      enterFrom={currentClasses.enterFrom}
      enterTo={currentClasses.enterTo}
      leave={currentClasses.leave}
      leaveFrom={currentClasses.leaveFrom}
      leaveTo={currentClasses.leaveTo}
      {...restProps}
      as={as}
    >
      {children}
    </Transition>
  )
}

export default TransitionSlide
