import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { filterArrayIntoTwoChunks } from '@helpers/array/filterArrayIntoChunks'
import { isArray } from '@helpers/checkTypes'

import { getCallbackFilterSzpkReportsInfoByAdapterType } from './helpers'

const generatePropertiesObjectForDataOnFulfillmentOfTermsSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  // актуальные проекты - те, которые имеют заполненные данные
  // заполненность данными можно узнать например по полю investorFullName
  const actualSzpkReportsInfo = objectFromPropertyState.szpkReportsInfo?.value.filter(
    (szpkReportInfo) => !!szpkReportInfo.value?.investorFullName?.value,
  )

  const szpkReportsInfoProperties = {
    type: objectFromPropertyState.szpkReportsInfo?.type,
    propertyId: objectFromPropertyState.szpkReportsInfo?.propertyId,
    lastUpdateDt: objectFromPropertyState.szpkReportsInfo?.lastUpdateDt,
  }

  const getPreparedSzpkReportsInfoByBackendList = (szpkReportsInfoList) =>
    szpkReportsInfoList.map((szpkReportInfo) => ({
      propertyId: szpkReportInfo.propertyId,
      lastUpdateDt: szpkReportInfo.lastUpdateDt,
      type: szpkReportInfo.type,
      value: {
        szpkRegistrationDate: {
          propertyId: szpkReportInfo.value.szpkRegistrationDate?.propertyId,
          lastUpdateDt: szpkReportInfo.value.szpkRegistrationDate?.lastUpdateDt,
          type: szpkReportInfo.value.szpkRegistrationDate?.type,
        },
        projectOkvedMonitoring: {
          propertyId: szpkReportInfo.value.projectOkvedMonitoring?.propertyId,
          lastUpdateDt: szpkReportInfo.value.projectOkvedMonitoring?.lastUpdateDt,
          type: szpkReportInfo.value.projectOkvedMonitoring?.type,
        },

        totalCapitalFact: {
          propertyId: szpkReportInfo.value.totalCapitalFact?.propertyId,
          lastUpdateDt: szpkReportInfo.value.totalCapitalFact?.lastUpdateDt,
          type: szpkReportInfo.value.totalCapitalFact?.type,
        },
        totalCapitalFactChecker: {
          propertyId: szpkReportInfo.value.totalCapitalFactChecker?.propertyId,
          lastUpdateDt: szpkReportInfo.value.totalCapitalFactChecker?.lastUpdateDt,
          type: szpkReportInfo.value.totalCapitalFactChecker?.type,
        },
        totalCapitalPlan: {
          propertyId: szpkReportInfo.value.totalCapitalPlan?.propertyId,
          lastUpdateDt: szpkReportInfo.value.totalCapitalPlan?.lastUpdateDt,
          type: szpkReportInfo.value.totalCapitalPlan?.type,
        },
        totalCapitalPlanChecker: {
          propertyId: szpkReportInfo.value.totalCapitalPlanChecker?.propertyId,
          lastUpdateDt: szpkReportInfo.value.totalCapitalPlanChecker?.lastUpdateDt,
          type: szpkReportInfo.value.totalCapitalPlanChecker?.type,
        },

        investorCapitalFact: {
          propertyId: szpkReportInfo.value.investorCapitalFact?.propertyId,
          lastUpdateDt: szpkReportInfo.value.investorCapitalFact?.lastUpdateDt,
          type: szpkReportInfo.value.investorCapitalFact?.type,
        },
        investorCapitalFactChecker: {
          propertyId: szpkReportInfo.value.investorCapitalFactChecker?.propertyId,
          lastUpdateDt: szpkReportInfo.value.investorCapitalFactChecker?.lastUpdateDt,
          type: szpkReportInfo.value.investorCapitalFactChecker?.type,
        },
        investorCapitalPlan: {
          propertyId: szpkReportInfo.value.investorCapitalPlan?.propertyId,
          lastUpdateDt: szpkReportInfo.value.investorCapitalPlan?.lastUpdateDt,
          type: szpkReportInfo.value.investorCapitalPlan?.type,
        },
        investorCapitalPlanChecker: {
          propertyId: szpkReportInfo.value.investorCapitalPlanChecker?.propertyId,
          lastUpdateDt: szpkReportInfo.value.investorCapitalPlanChecker?.lastUpdateDt,
          type: szpkReportInfo.value.investorCapitalPlanChecker?.type,
        },
        newWorkPlacesFact: {
          propertyId: szpkReportInfo.value.newWorkPlacesFact?.propertyId,
          lastUpdateDt: szpkReportInfo.value.newWorkPlacesFact?.lastUpdateDt,
          type: szpkReportInfo.value.newWorkPlacesFact?.type,
        },
        newWorkPlacesFactChecker: {
          propertyId: szpkReportInfo.value.newWorkPlacesFactChecker?.propertyId,
          lastUpdateDt: szpkReportInfo.value.newWorkPlacesFactChecker?.lastUpdateDt,
          type: szpkReportInfo.value.newWorkPlacesFactChecker?.type,
        },
        newWorkPlacesPlan: {
          propertyId: szpkReportInfo.value.newWorkPlacesPlan?.propertyId,
          lastUpdateDt: szpkReportInfo.value.newWorkPlacesPlan?.lastUpdateDt,
          type: szpkReportInfo.value.newWorkPlacesPlan?.type,
        },
        newWorkPlacesPlanChecker: {
          propertyId: szpkReportInfo.value.newWorkPlacesPlanChecker?.propertyId,
          lastUpdateDt: szpkReportInfo.value.newWorkPlacesPlanChecker?.lastUpdateDt,
          type: szpkReportInfo.value.newWorkPlacesPlanChecker?.type,
        },
        compensationValueFact: {
          propertyId: szpkReportInfo.value.compensationValueFact?.propertyId,
          lastUpdateDt: szpkReportInfo.value.compensationValueFact?.lastUpdateDt,
          type: szpkReportInfo.value.compensationValueFact?.type,
        },
        compensationValueFactChecker: {
          propertyId: szpkReportInfo.value.compensationValueFactChecker?.propertyId,
          lastUpdateDt: szpkReportInfo.value.compensationValueFactChecker?.lastUpdateDt,
          type: szpkReportInfo.value.compensationValueFactChecker?.type,
        },
        compensationValuePlan: {
          propertyId: szpkReportInfo.value.compensationValuePlan?.propertyId,
          lastUpdateDt: szpkReportInfo.value.compensationValuePlan?.lastUpdateDt,
          type: szpkReportInfo.value.compensationValuePlan?.type,
        },
        compensationValuePlanChecker: {
          propertyId: szpkReportInfo.value.compensationValuePlanChecker?.propertyId,
          lastUpdateDt: szpkReportInfo.value.compensationValuePlanChecker?.lastUpdateDt,
          type: szpkReportInfo.value.compensationValuePlanChecker?.type,
        },
      },
    }))

  const preparedSzpkReportsInfo = (() => {
    if (!isArray(actualSzpkReportsInfo) || !actualSzpkReportsInfo?.length) return []

    return getPreparedSzpkReportsInfoByBackendList(actualSzpkReportsInfo)
  })()

  const preparedEconomicSpheresRegionalReport = (() => {
    if (
      !isArray(objectFromPropertyState.economicSpheresRegionalReport?.value) ||
      !objectFromPropertyState.economicSpheresRegionalReport?.value?.length
    )
      return []

    return objectFromPropertyState.economicSpheresRegionalReport?.value.map(
      (economicSphereRegionalReport) => {
        const preparedEconomicSphereOkvedsRegionalReport = (() => {
          const economicSphereOkvedsRegionalReportItems =
            economicSphereRegionalReport?.value?.economicSphereOkvedsRegionalReport

          if (
            !isArray(economicSphereOkvedsRegionalReportItems?.value) ||
            !economicSphereOkvedsRegionalReportItems?.value?.length
          )
            return []

          return economicSphereOkvedsRegionalReportItems?.value?.map(
            (economicSphereOkvedRegionalReport) => {
              const preparedRegionsOkvedReportInfo = (() => {
                const szpkReportsInfo = objectFromPropertyState.szpkReportsInfo?.value
                if (!isArray(szpkReportsInfo) || !szpkReportsInfo) return []

                const currentSzpkReportsInfo = szpkReportsInfo?.filter((szpkReportInfo) => {
                  const szpkReportInfoProjectOkvedMonitoringId =
                    szpkReportInfo?.value?.projectOkvedMonitoring?.value?.id
                  const economicSphereOkvedCodeRegionalReportId =
                    economicSphereOkvedRegionalReport?.value?.economicSphereOkvedCodeRegionalReport
                      ?.value?.id

                  return (
                    !!szpkReportInfoProjectOkvedMonitoringId &&
                    !!economicSphereOkvedCodeRegionalReportId &&
                    szpkReportInfoProjectOkvedMonitoringId ===
                      economicSphereOkvedCodeRegionalReportId
                  )
                })

                return getPreparedSzpkReportsInfoByBackendList(currentSzpkReportsInfo)
              })()

              return {
                propertyId: economicSphereOkvedRegionalReport.propertyId,
                lastUpdateDt: economicSphereOkvedRegionalReport.lastUpdateDt,
                type: economicSphereOkvedRegionalReport.type,
                value: {
                  economicSphereOkvedCodeRegionalReport: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.economicSphereOkvedCodeRegionalReport?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.economicSphereOkvedCodeRegionalReport?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.economicSphereOkvedCodeRegionalReport?.type,
                  },
                  countEconomicSphereOkvedRegionalReport: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.countEconomicSphereOkvedRegionalReport?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.countEconomicSphereOkvedRegionalReport?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.countEconomicSphereOkvedRegionalReport?.type,
                  },
                  countEconomicSphereOkvedRegionalReportCorrect: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.countEconomicSphereOkvedRegionalReportCorrect?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.countEconomicSphereOkvedRegionalReportCorrect?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.countEconomicSphereOkvedRegionalReportCorrect?.type,
                  },

                  totalCapitalEconomicSphereOkvedRegionalReport: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.totalCapitalEconomicSphereOkvedRegionalReport?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.totalCapitalEconomicSphereOkvedRegionalReport?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.totalCapitalEconomicSphereOkvedRegionalReport?.type,
                  },
                  totalCapitalEconomicSphereOkvedRegionalReportChecker: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.totalCapitalEconomicSphereOkvedRegionalReportChecker?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.totalCapitalEconomicSphereOkvedRegionalReportChecker?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.totalCapitalEconomicSphereOkvedRegionalReportChecker?.type,
                  },
                  totalCapitalEconomicSphereOkvedRegionalReportCorrect: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.totalCapitalEconomicSphereOkvedRegionalReportCorrect?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.totalCapitalEconomicSphereOkvedRegionalReportCorrect?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.totalCapitalEconomicSphereOkvedRegionalReportCorrect?.type,
                  },
                  investorCapitalEconomicSphereOkvedRegionalReport: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.investorCapitalEconomicSphereOkvedRegionalReport?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.investorCapitalEconomicSphereOkvedRegionalReport?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.investorCapitalEconomicSphereOkvedRegionalReport?.type,
                  },
                  investorCapitalEconomicSphereOkvedRegionalReportChecker: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.investorCapitalEconomicSphereOkvedRegionalReportChecker?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.investorCapitalEconomicSphereOkvedRegionalReportChecker?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.investorCapitalEconomicSphereOkvedRegionalReportChecker?.type,
                  },
                  investorCapitalEconomicSphereOkvedRegionalReportCorrect: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.investorCapitalEconomicSphereOkvedRegionalReportCorrect?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.investorCapitalEconomicSphereOkvedRegionalReportCorrect?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.investorCapitalEconomicSphereOkvedRegionalReportCorrect?.type,
                  },
                  newWorkPlacesEconomicSphereOkvedRegionalReport: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.newWorkPlacesEconomicSphereOkvedRegionalReport?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.newWorkPlacesEconomicSphereOkvedRegionalReport?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.newWorkPlacesEconomicSphereOkvedRegionalReport?.type,
                  },
                  newWorkPlacesEconomicSphereOkvedRegionalReportChecker: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.newWorkPlacesEconomicSphereOkvedRegionalReportChecker?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.newWorkPlacesEconomicSphereOkvedRegionalReportChecker?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.newWorkPlacesEconomicSphereOkvedRegionalReportChecker?.type,
                  },
                  newWorkPlacesEconomicSphereOkvedRegionalReportCorrect: {
                    propertyId:
                      economicSphereOkvedRegionalReport?.value
                        ?.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect?.propertyId,
                    lastUpdateDt:
                      economicSphereOkvedRegionalReport?.value
                        ?.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect?.lastUpdateDt,
                    type: economicSphereOkvedRegionalReport?.value
                      ?.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect?.type,
                  },
                  szpkReportsInfo: {
                    ...szpkReportsInfoProperties,
                    value: preparedRegionsOkvedReportInfo,
                  },
                },
              }
            },
          )
        })()

        return {
          propertyId: economicSphereRegionalReport.propertyId,
          lastUpdateDt: economicSphereRegionalReport.lastUpdateDt,
          type: economicSphereRegionalReport.type,
          value: {
            economicSphereNameRegionalReport: {
              propertyId:
                economicSphereRegionalReport?.value?.economicSphereNameRegionalReport?.propertyId,
              lastUpdateDt:
                economicSphereRegionalReport?.value?.economicSphereNameRegionalReport?.lastUpdateDt,
              type: economicSphereRegionalReport?.value?.economicSphereNameRegionalReport?.type,
            },
            economicSphereOkvedsRegionalReport: {
              propertyId:
                economicSphereRegionalReport?.value?.economicSphereOkvedsRegionalReport?.propertyId,
              lastUpdateDt:
                economicSphereRegionalReport?.value?.economicSphereOkvedsRegionalReport
                  ?.lastUpdateDt,
              type: economicSphereRegionalReport?.value?.economicSphereOkvedsRegionalReport?.type,
              value: preparedEconomicSphereOkvedsRegionalReport,
            },
          },
        }
      },
    )
  })()

  const preparedSzpksChangeTerminateRegionalReport = (() => {
    if (
      !isArray(objectFromPropertyState.szpksChangeTerminate?.value) ||
      !objectFromPropertyState.szpksChangeTerminate?.value?.length
    )
      return []

    return objectFromPropertyState.szpksChangeTerminate.value.map((szpkChangeTerminate) => ({
      propertyId: szpkChangeTerminate.propertyId,
      lastUpdateDt: szpkChangeTerminate.lastUpdateDt,
      type: szpkChangeTerminate.type,
      value: {
        isChange: {
          propertyId: szpkChangeTerminate.value.isChanges?.propertyId,
          type: szpkChangeTerminate.value.isChanges?.type,
          lastUpdateDt: szpkChangeTerminate.value.isChanges?.lastUpdateDt,
        },
        isTerminate: {
          propertyId: szpkChangeTerminate.value.isTerminate?.propertyId,
          type: szpkChangeTerminate.value.isTerminate?.type,
          lastUpdateDt: szpkChangeTerminate.value.isTerminate?.lastUpdateDt,
        },
        totalCapitalCorrect: {
          propertyId: szpkChangeTerminate.value.totalCapitalCorrect?.propertyId,
          type: szpkChangeTerminate.value.totalCapitalCorrect?.type,
          lastUpdateDt: szpkChangeTerminate.value.totalCapitalCorrect?.lastUpdateDt,
        },
        investorCapitalCorrect: {
          propertyId: szpkChangeTerminate.value.investorCapitalCorrect?.propertyId,
          type: szpkChangeTerminate.value.investorCapitalCorrect?.type,
          lastUpdateDt: szpkChangeTerminate.value.investorCapitalCorrect?.lastUpdateDt,
        },
        changeTerminateReason: {
          propertyId: szpkChangeTerminate.value.changeTerminateReason?.propertyId,
          type: szpkChangeTerminate.value.changeTerminateReason?.type,
          lastUpdateDt: szpkChangeTerminate.value.changeTerminateReason?.lastUpdateDt,
        },
      },
    }))
  })()

  const [preparedFederalSzpkReportsInfo, preparedNotFederalSzpkReportsInfo] =
    filterArrayIntoTwoChunks(
      preparedSzpkReportsInfo,
      (szpkReport: any) => szpkReport.value?.isFederal,
    )

  const preparedFederalSzpkReportsInfoWithinMonitoringDates = preparedFederalSzpkReportsInfo.filter(
    getCallbackFilterSzpkReportsInfoByAdapterType(objectFromPropertyState, 'PropertiesObject'),
  )

  const preparedNotFederalSzpkReportsInfoWithinMonitoringDates =
    preparedNotFederalSzpkReportsInfo.filter(
      getCallbackFilterSzpkReportsInfoByAdapterType(objectFromPropertyState, 'PropertiesObject'),
    )

  return {
    '1': PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    '2': PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '3': {
      szpkCountRegionalReportPlan: {
        propertyId: objectFromPropertyState?.szpkCountRegionalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkCountRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.szpkCountRegionalReportPlan?.type,
      },
      szpkCountRegionalReportFact: {
        propertyId: objectFromPropertyState?.szpkCountRegionalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkCountRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.szpkCountRegionalReportFact?.type,
      },

      federalSzpkCountRegionalReportPlan: {
        propertyId: objectFromPropertyState?.federalSzpkCountRegionalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.federalSzpkCountRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkCountRegionalReportPlan?.type,
      },
      federalSzpkCountRegionalReportPlanCorrect: {
        propertyId: objectFromPropertyState?.federalSzpkCountRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkCountRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkCountRegionalReportPlanCorrect?.type,
      },
      federalSzpkCountRegionalReportFact: {
        propertyId: objectFromPropertyState?.federalSzpkCountRegionalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.federalSzpkCountRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkCountRegionalReportFact?.type,
      },
      federalSzpkCountRegionalReportFactCorrect: {
        propertyId: objectFromPropertyState?.federalSzpkCountRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkCountRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkCountRegionalReportFactCorrect?.type,
      },

      regionalSzpkCountRegionalReportPlan: {
        propertyId: objectFromPropertyState?.regionalSzpkCountRegionalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.regionalSzpkCountRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkCountRegionalReportPlan?.type,
      },
      regionalSzpkCountRegionalReportPlanCorrect: {
        propertyId: objectFromPropertyState?.regionalSzpkCountRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkCountRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkCountRegionalReportPlanCorrect?.type,
      },
      regionalSzpkCountRegionalReportFact: {
        propertyId: objectFromPropertyState?.regionalSzpkCountRegionalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.regionalSzpkCountRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkCountRegionalReportFact?.type,
      },
      regionalSzpkCountRegionalReportFactCorrect: {
        propertyId: objectFromPropertyState?.regionalSzpkCountRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkCountRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkCountRegionalReportFactCorrect?.type,
      },
    },
    '4': {
      szpkPeriodCountRegionalReportPlan: {
        propertyId: objectFromPropertyState?.szpkPeriodCountRegionalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkPeriodCountRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.szpkPeriodCountRegionalReportPlan?.type,
      },
      szpkPeriodCountRegionalReportFact: {
        propertyId: objectFromPropertyState?.szpkPeriodCountRegionalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkPeriodCountRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.szpkPeriodCountRegionalReportFact?.type,
      },

      federalSzpkPeriodCountRegionalReportPlan: {
        propertyId: objectFromPropertyState?.federalSzpkPeriodCountRegionalReportPlan?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkPeriodCountRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkPeriodCountRegionalReportPlan?.type,
      },
      federalSzpkPeriodCountRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalSzpkPeriodCountRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkPeriodCountRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkPeriodCountRegionalReportPlanCorrect?.type,
      },
      federalSzpkPeriodCountRegionalReportFact: {
        propertyId: objectFromPropertyState?.federalSzpkPeriodCountRegionalReportFact?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkPeriodCountRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkPeriodCountRegionalReportFact?.type,
      },
      federalSzpkPeriodCountRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalSzpkPeriodCountRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkPeriodCountRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkPeriodCountRegionalReportFactCorrect?.type,
      },

      regionalSzpkPeriodCountRegionalReportPlan: {
        propertyId: objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportPlan?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportPlan?.type,
      },
      regionalSzpkPeriodCountRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportPlanCorrect?.type,
      },
      regionalSzpkPeriodCountRegionalReportFact: {
        propertyId: objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportFact?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportFact?.type,
      },
      regionalSzpkPeriodCountRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportFactCorrect?.type,
      },
    },
    '5': {
      totalCapitalRegionalReportPlan: {
        propertyId: objectFromPropertyState?.totalCapitalRegionalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.totalCapitalRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.totalCapitalRegionalReportPlan?.type,
      },
      totalCapitalRegionalReportFact: {
        propertyId: objectFromPropertyState?.totalCapitalRegionalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.totalCapitalRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.totalCapitalRegionalReportFact?.type,
      },

      federalTotalCapitalRegionalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalRegionalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalRegionalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalRegionalReportPlanChecker?.type,
      },
      federalTotalCapitalRegionalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalRegionalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalRegionalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalRegionalReportPlanRegion?.type,
      },
      federalTotalCapitalRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalRegionalReportPlanCorrect?.type,
      },
      federalTotalCapitalRegionalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalRegionalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalRegionalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalRegionalReportFactChecker?.type,
      },
      federalTotalCapitalRegionalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalRegionalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalRegionalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalRegionalReportFactRegion?.type,
      },
      federalTotalCapitalRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalRegionalReportFactCorrect?.type,
      },

      regionalTotalCapitalRegionalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanChecker?.type,
      },
      regionalTotalCapitalRegionalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanRegion?.type,
      },
      regionalTotalCapitalRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanCorrect?.type,
      },
      regionalTotalCapitalRegionalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalRegionalReportFactChecker?.type,
      },
      regionalTotalCapitalRegionalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalRegionalReportFactRegion?.type,
      },
      regionalTotalCapitalRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalRegionalReportFactCorrect?.type,
      },
    },
    '6': {
      investorCapitalRegionalReportPlan: {
        propertyId: objectFromPropertyState?.investorCapitalRegionalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.investorCapitalRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.investorCapitalRegionalReportPlan?.type,
      },
      investorCapitalRegionalReportFact: {
        propertyId: objectFromPropertyState?.investorCapitalRegionalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.investorCapitalRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.investorCapitalRegionalReportFact?.type,
      },

      federalInvestorCapitalRegionalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanChecker?.type,
      },
      federalInvestorCapitalRegionalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanRegion?.type,
      },
      federalInvestorCapitalRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanCorrect?.type,
      },
      federalInvestorCapitalRegionalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalRegionalReportFactChecker?.type,
      },
      federalInvestorCapitalRegionalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalRegionalReportFactRegion?.type,
      },
      federalInvestorCapitalRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalRegionalReportFactCorrect?.type,
      },

      regionalInvestorCapitalRegionalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanChecker?.type,
      },
      regionalInvestorCapitalRegionalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanRegion?.type,
      },
      regionalInvestorCapitalRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanCorrect?.type,
      },
      regionalInvestorCapitalRegionalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactChecker?.type,
      },
      regionalInvestorCapitalRegionalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactRegion?.type,
      },
      regionalInvestorCapitalRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactCorrect?.type,
      },
    },
    '7': {
      newWorkPlacesRegionalReportPlan: {
        propertyId: objectFromPropertyState?.newWorkPlacesRegionalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.newWorkPlacesRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.newWorkPlacesRegionalReportPlan?.type,
      },
      newWorkPlacesRegionalReportFact: {
        propertyId: objectFromPropertyState?.newWorkPlacesRegionalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.newWorkPlacesRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.newWorkPlacesRegionalReportFact?.type,
      },

      federalNewWorkPlacesRegionalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanChecker?.type,
      },
      federalNewWorkPlacesRegionalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanRegion?.type,
      },
      federalNewWorkPlacesRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanCorrect?.type,
      },
      federalNewWorkPlacesRegionalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactChecker?.type,
      },
      federalNewWorkPlacesRegionalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactRegion?.type,
      },
      federalNewWorkPlacesRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactCorrect?.type,
      },

      regionalNewWorkPlacesRegionalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanRegion?.type,
      },
      regionalNewWorkPlacesRegionalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanChecker?.type,
      },
      regionalNewWorkPlacesRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanCorrect?.type,
      },
      regionalNewWorkPlacesRegionalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactChecker?.type,
      },
      regionalNewWorkPlacesRegionalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactRegion?.type,
      },
      regionalNewWorkPlacesRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactCorrect?.type,
      },
    },
    '8': {
      compensationValueRegionalReportPlan: {
        propertyId: objectFromPropertyState?.compensationValueRegionalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.compensationValueRegionalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.compensationValueRegionalReportPlan?.type,
      },
      compensationValueRegionalReportFact: {
        propertyId: objectFromPropertyState?.compensationValueRegionalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.compensationValueRegionalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.compensationValueRegionalReportFact?.type,
      },

      federalCompensationValueRegionalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueRegionalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueRegionalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueRegionalReportPlanChecker?.type,
      },
      federalCompensationValueRegionalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueRegionalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueRegionalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueRegionalReportPlanRegion?.type,
      },
      federalCompensationValueRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueRegionalReportPlanCorrect?.type,
      },
      federalCompensationValueRegionalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueRegionalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueRegionalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueRegionalReportFactChecker?.type,
      },
      federalCompensationValueRegionalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueRegionalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueRegionalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueRegionalReportFactRegion?.type,
      },
      federalCompensationValueRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueRegionalReportFactCorrect?.type,
      },

      regionalCompensationValueRegionalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueRegionalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueRegionalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueRegionalReportPlanChecker?.type,
      },
      regionalCompensationValueRegionalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueRegionalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueRegionalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueRegionalReportPlanRegion?.type,
      },
      regionalCompensationValueRegionalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueRegionalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueRegionalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueRegionalReportPlanCorrect?.type,
      },
      regionalCompensationValueRegionalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueRegionalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueRegionalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueRegionalReportFactChecker?.type,
      },
      regionalCompensationValueRegionalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueRegionalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueRegionalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueRegionalReportFactRegion?.type,
      },
      regionalCompensationValueRegionalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueRegionalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueRegionalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueRegionalReportFactCorrect?.type,
      },
    },
    '9': {
      economicSpheresRegionalReport: {
        propertyId: objectFromPropertyState.economicSpheresRegionalReport?.propertyId,
        type: objectFromPropertyState.economicSpheresRegionalReport?.type,
        lastUpdateDt: objectFromPropertyState.economicSpheresRegionalReport?.lastUpdateDt,
        value: preparedEconomicSpheresRegionalReport,
      },
    },
    '10': {
      szpksChangeTerminate: {
        propertyId: objectFromPropertyState.szpksChangeTerminate?.propertyId,
        type: objectFromPropertyState.szpksChangeTerminate?.type,
        lastUpdateDt: objectFromPropertyState.szpksChangeTerminate?.lastUpdateDt,
        value: preparedSzpksChangeTerminateRegionalReport,
      },
    },
    '11': {
      federalChangeTerminationSzpkCountRegionalReport: {
        propertyId:
          objectFromPropertyState.federalChangeTerminationSzpkCountRegionalReport?.propertyId,
        type: objectFromPropertyState.federalChangeTerminationSzpkCountRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeTerminationSzpkCountRegionalReport?.lastUpdateDt,
      },
      federalChangeTerminationSzpkTotalCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.federalChangeTerminationSzpkTotalCapitalRegionalReport
            ?.propertyId,
        type: objectFromPropertyState.federalChangeTerminationSzpkTotalCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeTerminationSzpkTotalCapitalRegionalReport
            ?.lastUpdateDt,
      },
      federalChangeTerminationSzpkInvestorCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.federalChangeTerminationSzpkInvestorCapitalRegionalReport
            ?.propertyId,
        type: objectFromPropertyState.federalChangeTerminationSzpkInvestorCapitalRegionalReport
          ?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeTerminationSzpkInvestorCapitalRegionalReport
            ?.lastUpdateDt,
      },
      federalChangeTerminationReasonCountRegionalReport: {
        propertyId:
          objectFromPropertyState.federalChangeTerminationReasonCountRegionalReport?.propertyId,
        type: objectFromPropertyState.federalChangeTerminationReasonCountRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeTerminationReasonCountRegionalReport?.lastUpdateDt,
      },

      federalChangeSzpkCountRegionalReport: {
        propertyId: objectFromPropertyState.federalChangeSzpkCountRegionalReport?.propertyId,
        type: objectFromPropertyState.federalChangeSzpkCountRegionalReport?.type,
        lastUpdateDt: objectFromPropertyState.federalChangeSzpkCountRegionalReport?.lastUpdateDt,
      },
      federalChangeSzpkTotalCapitalRegionalReport: {
        propertyId: objectFromPropertyState.federalChangeSzpkTotalCapitalRegionalReport?.propertyId,
        type: objectFromPropertyState.federalChangeSzpkTotalCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeSzpkTotalCapitalRegionalReport?.lastUpdateDt,
      },
      federalChangeSzpkInvestorCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.federalChangeSzpkInvestorCapitalRegionalReport?.propertyId,
        type: objectFromPropertyState.federalChangeSzpkInvestorCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeSzpkInvestorCapitalRegionalReport?.lastUpdateDt,
      },
      federalChangeReasonCountRegionalReport: {
        propertyId: objectFromPropertyState.federalChangeReasonCountRegionalReport?.propertyId,
        type: objectFromPropertyState.federalChangeReasonCountRegionalReport?.type,
        lastUpdateDt: objectFromPropertyState.federalChangeReasonCountRegionalReport?.lastUpdateDt,
      },

      federalTerminationSzpkCountRegionalReport: {
        propertyId: objectFromPropertyState.federalTerminationSzpkCountRegionalReport?.propertyId,
        type: objectFromPropertyState.federalTerminationSzpkCountRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalTerminationSzpkCountRegionalReport?.lastUpdateDt,
      },
      federalTerminationSzpkTotalCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.federalTerminationSzpkTotalCapitalRegionalReport?.propertyId,
        type: objectFromPropertyState.federalTerminationSzpkTotalCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalTerminationSzpkTotalCapitalRegionalReport?.lastUpdateDt,
      },
      federalTerminationSzpkInvestorCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.federalTerminationSzpkInvestorCapitalRegionalReport?.propertyId,
        type: objectFromPropertyState.federalTerminationSzpkInvestorCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalTerminationSzpkInvestorCapitalRegionalReport?.lastUpdateDt,
      },
      federalTerminationReasonCountRegionalReport: {
        propertyId: objectFromPropertyState.federalTerminationReasonCountRegionalReport?.propertyId,
        type: objectFromPropertyState.federalTerminationReasonCountRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalTerminationReasonCountRegionalReport?.lastUpdateDt,
      },
    },
    '12': {
      regionalChangeTerminationSzpkCountRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeTerminationSzpkCountRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeTerminationSzpkCountRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeTerminationSzpkCountRegionalReport?.lastUpdateDt,
      },
      regionalChangeTerminationSzpkTotalCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeTerminationSzpkTotalCapitalRegionalReport
            ?.propertyId,
        type: objectFromPropertyState.regionalChangeTerminationSzpkTotalCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeTerminationSzpkTotalCapitalRegionalReport
            ?.lastUpdateDt,
      },
      regionalChangeTerminationSzpkInvestorCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeTerminationSzpkInvestorCapitalRegionalReport
            ?.propertyId,
        type: objectFromPropertyState.regionalChangeTerminationSzpkInvestorCapitalRegionalReport
          ?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeTerminationSzpkInvestorCapitalRegionalReport
            ?.lastUpdateDt,
      },
      regionalChangeTerminationReasonCountRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeTerminationReasonCountRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeTerminationReasonCountRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeTerminationReasonCountRegionalReport?.lastUpdateDt,
      },

      regionalChangeSzpkCountRegionalReport: {
        propertyId: objectFromPropertyState.regionalChangeSzpkCountRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeSzpkCountRegionalReport?.type,
        lastUpdateDt: objectFromPropertyState.regionalChangeSzpkCountRegionalReport?.lastUpdateDt,
      },
      regionalChangeSzpkTotalCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeSzpkTotalCapitalRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeSzpkTotalCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeSzpkTotalCapitalRegionalReport?.lastUpdateDt,
      },
      regionalChangeSzpkInvestorCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeSzpkInvestorCapitalRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeSzpkInvestorCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeSzpkInvestorCapitalRegionalReport?.lastUpdateDt,
      },
      regionalChangeReasonCountRegionalReport: {
        propertyId: objectFromPropertyState.regionalChangeReasonCountRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeReasonCountRegionalReport?.type,
        lastUpdateDt: objectFromPropertyState.regionalChangeReasonCountRegionalReport?.lastUpdateDt,
      },

      regionalTerminationSzpkCountRegionalReport: {
        propertyId: objectFromPropertyState.regionalTerminationSzpkCountRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalTerminationSzpkCountRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalTerminationSzpkCountRegionalReport?.lastUpdateDt,
      },
      regionalTerminationSzpkTotalCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalTerminationSzpkTotalCapitalRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalTerminationSzpkTotalCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalTerminationSzpkTotalCapitalRegionalReport?.lastUpdateDt,
      },
      regionalTerminationSzpkInvestorCapitalRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalTerminationSzpkInvestorCapitalRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalTerminationSzpkInvestorCapitalRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalTerminationSzpkInvestorCapitalRegionalReport
            ?.lastUpdateDt,
      },
      regionalTerminationReasonCountRegionalReport: {
        propertyId:
          objectFromPropertyState.regionalTerminationReasonCountRegionalReport?.propertyId,
        type: objectFromPropertyState.regionalTerminationReasonCountRegionalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalTerminationReasonCountRegionalReport?.lastUpdateDt,
      },
    },
    additionalForms: {
      federalSzpkReportsInfo: {
        ...szpkReportsInfoProperties,
        value: preparedFederalSzpkReportsInfo,
      },
      federalSzpkReportsInfoWithinMonitoringDates: {
        ...szpkReportsInfoProperties,
        value: preparedFederalSzpkReportsInfoWithinMonitoringDates,
      },
      notFederalSzpkReportsInfo: {
        ...szpkReportsInfoProperties,
        value: preparedNotFederalSzpkReportsInfo,
      },
      notFederalSzpkReportsInfoWithinMonitoringDates: {
        ...szpkReportsInfoProperties,
        value: preparedNotFederalSzpkReportsInfoWithinMonitoringDates,
      },
    },
  }
}

export { generatePropertiesObjectForDataOnFulfillmentOfTermsSZPKForm }
