import React, { FC, memo } from 'react'

import { useWebFormAPIContext } from '@components/Attachments/WebForm/APIProvider'
import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import { WebFormStateControllerProps } from '@components/Attachments/WebForm/WIthoutDocumentController/types'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import Button from '@components/NewDesign/Button'
import env from '@config'
import { isUndefined } from '@helpers/checkTypes'

const WebFormCompletedStateController: FC<WebFormStateControllerProps> = ({
  disabled,
  permissions,
  documentInfoFromDict,
  formId,
  onSuccess,
  onOpenForm,
}) => {
  const { description: title, isRequired } = documentInfoFromDict

  const { isWebFormReadAddSignPermission, isWebFormReadEditPermission } =
    useWebFormPermissions(permissions)

  const {
    state: { webFormIsLoading },
    handlers: { handleDeleteWebFormDocument },
  } = useWebFormAPIContext()

  const openFormButtonRenderCondition = !isUndefined(onOpenForm)

  const handleDeleteFormDocumentOnClick = async () => {
    if (!formId) return

    await handleDeleteWebFormDocument?.({
      formId,
      onSuccess,
    })
  }

  const mainTextForRequiredControl =
    isWebFormReadEditPermission || isWebFormReadAddSignPermission
      ? 'Форма заполнена. Вы можете выполнить предпросмотр документа'
      : 'Форма заполнена'

  if (isRequired) {
    return (
      <ControlContainer
        title={title}
        leadingNodeContent={<ControlContainerStatusIcon iconType="done" />}
        trailingNodeContent={
          openFormButtonRenderCondition && (
            <Button
              fixWidth
              disabled={disabled}
              view="tined"
              onClick={onOpenForm(isWebFormReadEditPermission || isWebFormReadAddSignPermission)}
            >
              Открыть форму
            </Button>
          )
        }
        additionalMainContent={
          <ControlContainerSubtitle>{mainTextForRequiredControl}</ControlContainerSubtitle>
        }
      />
    )
  }

  if (!isRequired && (isWebFormReadAddSignPermission || isWebFormReadEditPermission)) {
    return (
      <ControlContainer
        title={title}
        leadingNodeContent={<ControlContainerStatusIcon iconType="done" />}
        trailingNodeContent={
          openFormButtonRenderCondition && (
            <Button
              fixWidth
              disabled={webFormIsLoading || disabled}
              view="tined"
              loaderProps={{
                variant: 'lite',
                placement: 'leading',
                loading: webFormIsLoading,
              }}
              onClick={onOpenForm(true)}
            >
              Открыть форму
            </Button>
          )
        }
        additionalMainContent={
          <ControlContainerSubtitle
            additionalContent={
              <Button
                disabled={webFormIsLoading || disabled}
                size="xs"
                view="text"
                color="negative"
                variant="buttonSMedium"
                onClick={handleDeleteFormDocumentOnClick}
              >
                не подавать документ
              </Button>
            }
          >
            Чтобы отказаться от подачи, нажмите
          </ControlContainerSubtitle>
        }
      />
    )
  }

  if (!isRequired && (!isWebFormReadAddSignPermission || !isWebFormReadEditPermission)) {
    return (
      <ControlContainer
        title={title}
        leadingNodeContent={<ControlContainerStatusIcon iconType="done" />}
        trailingNodeContent={
          openFormButtonRenderCondition && (
            <Button fixWidth disabled={disabled} view="tined" onClick={onOpenForm()}>
              Открыть форму
            </Button>
          )
        }
        additionalMainContent={
          <ControlContainerSubtitle>
            Документ необязательный и его могут не подать
          </ControlContainerSubtitle>
        }
      />
    )
  }

  return (
    <ControlContainer
      color="negative"
      title={title}
      leadingNodeContent={<ControlContainerStatusIcon iconType="error" />}
      additionalMainContent={
        <ControlContainerSubtitle
          additionalContent={
            <Button
              href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}
              size="xs"
              view="text"
              variant="buttonSMedium"
              color="negative"
            >
              Сообщить в поддержку
            </Button>
          }
        >
          Произошла ошибка получения формы документа.
        </ControlContainerSubtitle>
      }
    />
  )
}

export default memo(WebFormCompletedStateController)
