import { FC, memo } from 'react'

import type { ErrorProps } from '@components/Forms/LoginForm/types'
import Typography from '@components/NewDesign/Typography/Typography'

import styles from './ErrorTemplate.module.scss'

interface ErrorTemplateProps {
  error: ErrorProps | null
}

const ErrorTemplate: FC<ErrorTemplateProps> = ({ error }) => {
  return (
    <>
      {error?.body && error?.header && (
        <div className={styles.wrapper}>
          <Typography.Headline variant="headlineH2" className={styles.errorHeader}>
            {error.header}
          </Typography.Headline>
          <Typography.Body variant="bodyMRegular" className={styles.errorBody}>
            {error.body}
          </Typography.Body>
        </div>
      )}
    </>
  )
}

export default memo(ErrorTemplate)
