import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForNpaSolicitationInclusionForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedChosenParts = (() => {
    if (
      !isArray(objectFromPropertyState.stabilizationUpdateNpas?.value) ||
      !objectFromPropertyState.stabilizationUpdateNpas?.value.length
    )
      return null

    const npaParts = objectFromPropertyState.stabilizationUpdateNpas.value.map((npa) => ({
      propertyId: npa?.propertyId,
      type: npa?.type,
      lastUpdateDt: npa?.lastUpdateDt,
      value: {
        justification: {
          propertyId: npa?.value?.stabilizationUpdateNpaJustification?.propertyId,
          type: npa?.value?.stabilizationUpdateNpaJustification?.type,
          lastUpdateDt: npa?.value?.stabilizationUpdateNpaJustification?.lastUpdateDt,
        },
        partCorrection: {
          propertyId: npa?.value?.stabilizationUpdateNpaCorrection?.propertyId,
          type: npa?.value?.stabilizationUpdateNpaCorrection?.type,
          lastUpdateDt: npa?.value?.stabilizationUpdateNpaCorrection?.lastUpdateDt,
        },
        npaId: {
          propertyId: npa?.value?.stabilizationUpdateNpaId?.propertyId,
          type: npa?.value?.stabilizationUpdateNpaId?.type,
          lastUpdateDt: npa?.value?.stabilizationUpdateNpaId?.lastUpdateDt,
        },
        partId: {
          propertyId: npa?.value?.stabilizationUpdateNpaPartId?.propertyId,
          type: npa?.value?.stabilizationUpdateNpaPartId?.type,
          lastUpdateDt: npa?.value?.stabilizationUpdateNpaPartId?.lastUpdateDt,
        },
      },
    }))

    return {
      npaParts,
    }
  })()

  return {
    headerInfo: {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    npa: {
      chosenParts: {
        propertyId: objectFromPropertyState.stabilizationUpdateNpas?.propertyId,
        lastUpdateDt: objectFromPropertyState.stabilizationUpdateNpas?.lastUpdateDt,
        type: objectFromPropertyState.stabilizationUpdateNpas?.type,
        value: preparedChosenParts,
      },
    },
  }
}

export { generatePropertiesObjectForNpaSolicitationInclusionForm }
