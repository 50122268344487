import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NoticeOfRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof NoticeOfRejectConcludeSZPKFormValues['2'],
  NoticeOfRejectConcludeSZPKFormValues
> = {
  initiatorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { secondSectionValidationMap }
