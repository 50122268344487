import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { sixteenthSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/16/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Sixteenth = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },

    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getInputProps, getSwitchProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const courtNameRenderCondition = useWatch({
    name: projectSZPKBlockValues['16'].courtChoice,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['16'].courtChoice,
              })}
            >
              <ControlledSwitch
                {...getSwitchProps({
                  name: projectSZPKBlockValues['16'].courtChoice,
                  switchProps: {
                    wrapperClassName: styles.form__switch,
                    label: 'Спор будет разрешен третейским судом',
                  },
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(projectSZPKBlockValues['16'].courtChoice)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <CollapseWrapper
          defaultExpanded={courtNameRenderCondition}
          isExpanded={courtNameRenderCondition}
        >
          <Group
            disableBottomBorder
            title={
              'Третейский суд на территории РФ, в котором стороны намереваются разрешать споры из соглашения'
            }
          >
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['16'].courtName,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: projectSZPKBlockValues['16'].courtName,
                        rules: sixteenthSectionValidationMap.courtName,
                        inputProps: {
                          label: 'Наименование суда',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(projectSZPKBlockValues['16'].courtName)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(projectSZPKBlockValues['16'].courtName)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </Stack>
          </Group>
        </CollapseWrapper>
      </Stack>
    </Container>
  )
}

export default Sixteenth
