import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { AATransferRightsAndObligationsFormValues } from '@components/Forms/AATransferRightsAndObligations/types'

const generateRHFObjectForAATransferRightsAndObligationsForm = (
  objectFromPropertyState: Record<string, any>,
): AATransferRightsAndObligationsFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      ...RHFBasicAdapters.getInvestorPartnerAdapter(objectFromPropertyState),
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  }
}

export { generateRHFObjectForAATransferRightsAndObligationsForm }
