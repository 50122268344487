import { usePopupManager } from 'react-popup-manager'

import type { DefaultModalProps } from '@globalTypes/base'

import ContinueProjectModal, { ContinueProjectModalType } from '.'

const useSendChangeProjectModal = () => {
  const popupManager = usePopupManager()

  const handleOpenSendChangeProjectModal = ({
    onSuccess,
    onClose,
  }: ContinueProjectModalType & DefaultModalProps) => {
    popupManager.open(ContinueProjectModal, {
      onClose: onClose ?? (() => null),
      onSuccess,
    })
  }

  return {
    handleOpenSendChangeProjectModal,
  }
}

export { useSendChangeProjectModal }
