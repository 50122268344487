import { useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import ErrorModal from '@components/NewDesignedModals/ErrorModal'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import { mapOfCustomErrorCodes } from '@constants/errorCodes'
import { defaultMessageSupport } from '@constants/texts'
import usePropertiesApi from '@context/APIContext/hooks/usePropertiesApi'
import { isAxiosError, isBlob } from '@helpers/checkTypes'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { parse } from 'content-disposition-header'
import { nanoid } from 'nanoid'

interface UseFormPreviewProps {
  formId: string
}

const useFormPreview = ({ formId }: UseFormPreviewProps) => {
  const { getFormPreview } = usePropertiesApi()
  const { open: openPopup } = usePopupManager()
  const { handleOpenLoadingModal } = useLoadingModal()

  const handleGetFormPreview = useCallback(async () => {
    let fileNameToDownload = nanoid()

    const closeHandler = handleOpenLoadingModal('Формирование документа')

    try {
      const { file, headers } = await getFormPreview({
        formId,
      })

      if (headers['content-disposition']) {
        const {
          parameters: { filename },
        } = parse(headers['content-disposition'])

        fileNameToDownload = filename
      }

      const fileToOpen = new File([file], fileNameToDownload, {
        type: headers?.['content-type'],
      })

      const fileUrl = URL.createObjectURL(fileToOpen)

      window.open(fileUrl)
      URL.revokeObjectURL(fileUrl)

      closeHandler?.()?.()
    } catch (e) {
      closeHandler?.()?.()

      if (!isAxiosError(e)) throw e

      let errorResponseData = e.response?.data
      if (isBlob(e.response?.data)) {
        errorResponseData = JSON.parse((await e.response?.data.text()) || '')
      }

      if (
        e.response?.status === 400 &&
        errorResponseData.errorCode === mapOfCustomErrorCodes.FORM_PREVIEW_TEMPLATE_ERROR
      ) {
        openPopup(ErrorModal, {
          headerText: 'Шаблон документа не найден',
          bodyText: defaultMessageSupport,
          onClose: () => null,
        })

        return
      }

      handleErrorWithPopup(e, true)
    }
  }, [formId, getFormPreview, openPopup, handleOpenLoadingModal])

  return {
    handleGetFormPreview,
  }
}

export { useFormPreview }
