import React, { memo, useCallback, useMemo } from 'react'

import Icon from '@components/Icon/Icon'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import HeaderNPAFilters from '@components/RegistryNpa/Header/Filters'
import { useNpaManager } from '@components/RegistryNpa/Manager'
import { useNpaEditSidebar } from '@components/Sidebars/NPA/Edit'
import { usePartNpaSidebar } from '@components/Sidebars/NPA/Part'
import { RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import searchIcon from '@icons/action/search.svg'

import styles from './Header.module.scss'

const RegistryNPAHeader = () => {
  const {
    state: { filtersInstance, gridSwrInstance },
  } = useNpaManager()

  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
  } = useManualTooltipControl()

  const { checkingRole } = useAuthContext()

  const isMER = !!checkingRole?.(RolesTypes.MER)
  const isOIV = !!checkingRole?.(RolesTypes.OIV)
  const isOMSU = !!checkingRole?.(RolesTypes.OMSU)

  const { handleOpenNpaEditSidebar } = useNpaEditSidebar()
  const { handleOpenPartSidebar } = usePartNpaSidebar()

  const handleOpenNpaEdit = useCallback(() => {
    handleOpenNpaEditSidebar({
      onReloadGrid: gridSwrInstance?.mutate,
    })
  }, [gridSwrInstance?.mutate, handleOpenNpaEditSidebar])

  const handleOpenPartEdit = useCallback(() => {
    handleOpenPartSidebar({
      onReloadGrid: gridSwrInstance?.mutate,
    })
  }, [gridSwrInstance?.mutate, handleOpenPartSidebar])

  const addButtonTooltipContent = useMemo(
    () => (
      <div className={styles.tooltip__addButton}>
        <Button
          className={styles['tooltip__addButton-item']}
          size={'s'}
          view={'plain'}
          geometry={'square'}
          onClick={handleOpenPartEdit}
        >
          <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
            Положение
          </Typography.Body>
        </Button>

        <Button
          className={styles.tooltip__buttons}
          size={'s'}
          view={'plain'}
          geometry={'square'}
          onClick={handleOpenNpaEdit}
        >
          <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
            НПА
          </Typography.Body>
        </Button>
      </div>
    ),
    [handleOpenNpaEdit, handleOpenPartEdit],
  )

  if (!filtersInstance) return null

  return (
    <div className={styles.header}>
      <Typography.Headline variant={'headlineH2'}>Реестр НПА</Typography.Headline>
      <div className={styles.header__actions}>
        <HeaderNPAFilters />
        <ControlledInput
          control={filtersInstance.control}
          name="searchString"
          inputProps={{
            rootClassName: styles.header__search,
            size: 'm',
            placeholder: 'Поиск по наименованию и номеру акта',
            clear: true,
            view: 'secondary',
            leftAddons: (
              <Icon noCurrentColorSvgFill src={searchIcon} size="s" className={styles.searchIcon} />
            ),
          }}
        />

        {(isMER || isOIV || isOMSU) && (
          <Tooltip
            offset={[0, 6]}
            popoverClassName={styles['tooltip__popover--small']}
            contentClassName={styles.tooltip__content}
            arrowClassName={styles.tooltip__arrow}
            trigger={'click'}
            position={'bottom-start'}
            open={tooltipIsOpen}
            content={addButtonTooltipContent}
            onClose={handleCloseTooltip}
          >
            <Button className={styles.header__addButton} onClick={handleOpenTooltip}>
              Добавить
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default memo(RegistryNPAHeader)
