import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface AAConnectionReorganizationImplementingFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const AAConnectionReorganizationImplementingForms: AAConnectionReorganizationImplementingFormsProps =
  () => null

AAConnectionReorganizationImplementingForms.First = First
AAConnectionReorganizationImplementingForms.Second = Second
AAConnectionReorganizationImplementingForms.Third = Third
AAConnectionReorganizationImplementingForms.Fourth = Fourth

export default AAConnectionReorganizationImplementingForms
