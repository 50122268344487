import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import styles from '@components/Forms/CreateStatement/Forms/Compensations/Compensation/Compensation.module.scss'
import { StagesArrayCompensationsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/6/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'
import { documentsLinks } from '@constants/documents'

import { projectStepCompensationValidationMap } from './validation'

interface ProjectStageStepCompensationItemProps {
  blockViewIsValidating: boolean
  editMode: boolean
  id: string
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: StagesArrayCompensationsArrayPathName
  onRemove?: VoidFunction
}

const ProjectStageStepCompensationItem: FC<ProjectStageStepCompensationItemProps> = ({
  id,
  editMode,
  blockViewIsValidating,
  formInstance,
  formName,
  onRemove,
}) => {
  const {
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getSingleSelectProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const currentValueOfType = useWatch({
    name: `${formName}.stepCompensationType`,
  })

  const projectCompensationPeriod = useWatch({
    name: `${formName}.stepCompensationPeriod`,
  })

  return (
    <FieldView.RowWithRemoveButton
      id={id}
      dataTestId="stepCompensationListItem"
      onRemove={onRemove}
    >
      <SubscribableControl
        {...getSubscribableControlProps({
          path: `${formName}.stepCompensationType`,
        })}
      >
        {({ overrideProps }) => {
          const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
            ? overrideProps.fetcherOptions
            : undefined

          return (
            <ControlledFormSingleSelect
              {...getSingleSelectProps({
                fetcherProps,
                optionsAdapter: (item) => ({
                  displayValue: item.name,
                  value: item.id,
                }),
                selectProps: {
                  disabled: !editMode,
                  inputProps: {
                    dataTestId: 'stepCompensationType',
                    label: 'Вид затрат',
                  },
                  onChangeFormValue: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.stepCompensationType`), 0),
                },
                controllerProps: {
                  name: `${formName}.stepCompensationType`,
                  rules: projectStepCompensationValidationMap.stepCompensationType,
                },
              })}
            />
          )
        }}
      </SubscribableControl>
      {currentValueOfType && projectCompensationPeriod && (
        <Typography.Caption
          className={styles.compensation__caption}
          color={'text-base-tertiary'}
          variant={'captionSRegular'}
        >
          {`Срок возмещения затрат составит до ${projectCompensationPeriod}`}
          <Typography.Caption as={'span'} variant={'captionSRegular'} color={'text-accent-brand'}>
            <a href={documentsLinks.FZ_N69} target="_blank" rel="noopener noreferrer">
              &nbsp;(часть 6 статьи 15 69-ФЗ)
            </a>
          </Typography.Caption>
        </Typography.Caption>
      )}
    </FieldView.RowWithRemoveButton>
  )
}

export default ProjectStageStepCompensationItem
