import { compact } from '@helpers/array/compact'
import { isString } from '@helpers/checkTypes'

import { AmountType, CurrencyCodes } from './types'

/**
 * Дробит мажорную часть суммы на части по указанному символу.
 *
 * @param amount Сумма для разбивки на части
 * @param partSize Размер частей суммы
 * @param splitter Символ, разбивающий части суммы
 * @param splitFrom Длина суммы, начиная с которой необходимо осуществлять разбивку. По-умолчанию длина
 * равняется пяти. Пример: 2900 - не разбивается,
 * 29 000 - разбивается.
 */
export const splitAmount = (
  amount: string,
  partSize = 3,
  splitter: string,
  splitFrom = 5,
): string => {
  const splittingRegExp = `\\B(?=(\\d{${partSize}})+(?!\\d))`

  // Если длина суммы меньше требуемой, не форматируем сумму
  if (amount.length < splitFrom) {
    return amount
  }

  return amount.replace(new RegExp(splittingRegExp, 'g'), splitter)
}

const AMOUNT_MAJOR_PART_SIZE = 3
const AMOUNT_SPLIT_CODE_FROM = 4
export const NEGATIVE_AMOUNT_SYMBOL = '-'

export const THINSP = String.fromCharCode(8201)
export const AMOUNT_MAJOR_MINOR_PARTS_SEPARATOR = ','

const formatWithCurrency = (value: string | null, currencySymbol?: string) =>
  compact([value, currencySymbol]).join(THINSP)

/**
 * Форматирует значение суммы из значения 1234500
 * где 00 является дробной частью при minority = 100
 */
export const formatAmount = ({
  value,
  currency,
  minority,
  view,
  offAmountSplitting,
  integersOnly,
}: AmountType) => {
  const currencySymbol = currency ? '₽' : ''

  if (value === null) {
    return {
      majorPart: '',
      minorPart: '',
      formatted: '',
      currencySymbol,
      formattedWithCurrency: formatWithCurrency(value, currencySymbol),
    }
  }

  minority = minority === 0 ? 1 : minority // because Math.log(0) => -Infinity

  // Преобразует последние цифры в дробную часть (1234500 -> 12345,00 при minority = 100)
  const fractionDigits = Math.log(minority) * Math.LOG10E
  const valueAbsStr = (Math.abs(value) / minority).toFixed(fractionDigits)

  const [majorPart] = valueAbsStr.split('.')
  let [, minorPart] = valueAbsStr.split('.')

  if ((view === 'default' && value % minority === 0) || integersOnly) {
    minorPart = ''
  }

  const majorPartSplitted = offAmountSplitting
    ? majorPart
    : splitAmount(majorPart, AMOUNT_MAJOR_PART_SIZE, THINSP, AMOUNT_SPLIT_CODE_FROM)

  const majorPartFormatted =
    value < 0 || (value === 0 && 1 / value === -Infinity)
      ? NEGATIVE_AMOUNT_SYMBOL + majorPartSplitted
      : majorPartSplitted

  const formattedValueStr = minorPart
    ? majorPartFormatted + AMOUNT_MAJOR_MINOR_PARTS_SEPARATOR + minorPart
    : majorPartFormatted

  return {
    majorPart: majorPartFormatted,
    minorPart,
    currencySymbol,
    formatted: formattedValueStr,
    formattedWithCurrency: formatWithCurrency(formattedValueStr, currencySymbol),
  }
}

const regExpPositive = /[^0-9.]/g

const regExpPositiveAndNegative = /[^-0-9.]/g

export function getAmountValueFromStr(str: string, minority: number) {
  return str === ''
    ? null
    : Math.round(Number(str.replace(',', '.').replace(regExpPositiveAndNegative, '')) * minority)
}

export function getAmountValueFromStrWithoutRound(
  str: string | number,
  hasNegativeValue?: boolean,
) {
  if (!isString(str)) return str

  const formatRegexp = hasNegativeValue ? regExpPositiveAndNegative : regExpPositive

  return str === '' ? null : Number(str.replace(',', '.').replace(formatRegexp, ''))
}

export function getStrValueFromNumber(number: number) {
  return String(number).replace('.', ',')
}

export function getFormattedValue(
  enteredValue: string,
  currency: CurrencyCodes,
  minority: number,
  offAmountSplitting: boolean,
) {
  const [head, tail] = enteredValue.split(',')

  const { majorPart } = formatAmount({
    value: Number(head) * minority,
    currency,
    minority,
    offAmountSplitting,
  })

  if (!enteredValue) {
    return enteredValue
  }

  if (!tail && enteredValue.includes(',')) {
    return majorPart.concat(',')
  }

  if (tail) {
    return majorPart.concat(',', tail.slice(0, minority.toString().length - 1))
  }

  return majorPart
}

export const trimSpacesBetweenWords = (value: string) => value.replace(/\s/g, '')

export const replaceSymbolsBetweenWords = (value: string, oldSymbol: string, newSymbol: string) =>
  value.replace(oldSymbol, newSymbol)

export const trimSpacesAndReplaceSymbols = (
  value: string,
  oldSymbol: string,
  newSymbol: string,
) => {
  const newValue = trimSpacesBetweenWords(value)
  return replaceSymbolsBetweenWords(newValue, oldSymbol, newSymbol)
}

export const isMinorPartFilled = (value: string, minority: number, delimiter = ',') => {
  // Преобразуем число в строку
  const strValue = value.toString()

  // Найдем позицию десятичного разделителя
  const decimalIndex = strValue.indexOf(delimiter)

  // Если десятичного разделителя нет, минорной части нет
  if (decimalIndex === -1) {
    return false
  }

  // Получаем дробную часть
  const minorPart = strValue.slice(decimalIndex + 1)

  // Вычисляем количество знаков после запятой, исходя из minorOrder
  const expectedMinorLength = Math.log10(minority)

  // Проверяем, что длина дробной части равна ожидаемой
  return minorPart.length === expectedMinorLength
}
