import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import RFormItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RForm/RFormItem'
import { SeventhRidObjectsPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const { isFormFieldError } = DocumentFormHelpers

interface RFormProps {
  name: SeventhRidObjectsPathName
  control: Control<ProjectSZPKFormValues>
}

const RForm: FC<RFormProps> = ({ name, control }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: ridForms } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhRidObjectsPathName,
    'keyNameId'
  >({
    name,
    control,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const ridFormsError = formInstance.getFieldState(name)?.error

  const handleAddRidForm = async () => {
    await handleAddItemToListWithOutValue?.(name)

    if (!ridForms.length) {
      formInstance.clearErrors(name)
    }
  }

  const handleRemoveRidForm = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  return (
    <Container className="p-0">
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: name,
                })}
              >
                <FlipperList list={ridForms}>
                  {ridForms.map((ridForm, index) => {
                    return (
                      <SubscribableControl
                        key={ridForm.id}
                        path={`${name}.${index}`}
                        watcher={ProjectSZPKFieldsControlUpdateWatcher}
                        {...subscribableControl}
                      >
                        <RFormItem
                          id={ridForm.id}
                          anchorId={ridForm.anchorId}
                          formName={`${name}.${index}`}
                          blockViewIsValidating={blockViewIsValidating}
                          editMode={editMode}
                          formInstance={formInstance}
                          indexOfRidForm={index}
                          onDeleteRidForm={handleRemoveRidForm(index)}
                        />
                      </SubscribableControl>
                    )
                  })}
                </FlipperList>
              </SubscribableControl>
              {isFormFieldError(ridFormsError) && !ridForms.length && (
                <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
              )}
              {editMode && (
                <Row className={'px-1'}>
                  <Col xs={12}>
                    <AsyncWrapper promise={handleAddRidForm}>
                      {({ isLoading, wrappedPromise }) => {
                        return (
                          <Button
                            disabled={isLoading}
                            variant={'buttonSMedium'}
                            leadingIcon={{ src: CircleAddIcon }}
                            size={'2xs'}
                            view={'plain'}
                            loaderProps={{
                              loading: isLoading,
                              placement: 'trailing',
                              variant: 'lite',
                            }}
                            onClick={wrappedPromise}
                          >
                            Добавить РИД
                          </Button>
                        )
                      }}
                    </AsyncWrapper>
                  </Col>
                </Row>
              )}
            </Stack>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default RForm
