import React, { useCallback, useEffect, useRef } from 'react'

import { Tooltip } from '@components/NewDesign/Tooltip'
import { TypographyComponentWithTooltip } from '@components/NewDesign/Typography/types'
import { useResizeObserver } from '@hooks/new/observers/useResizeObserver'
import { useTextClamp } from '@hooks/new/string/useTextClamp'
import { useBooleanState } from '@hooks/useBooleanState'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'

import Typography from './Typography'

export const TypographyWithTooltip: TypographyComponentWithTooltip = ({
  className,
  children,
  tooltipProps,
  textClampProps,
  ...props
}) => {
  const { booleanState: isTooltipNeeded, setBooleanState: setTooltipNeeded } = useBooleanState()

  const ref = useRef<HTMLParagraphElement | null>(null)

  const { currentClampInstance, bindElementRef } = useTextClamp({
    initialElement: ref.current,
    multipleClampProps: textClampProps,
  })

  const bindRef = (paragraphRef: HTMLParagraphElement | null) => {
    if (!paragraphRef) return

    ref.current = paragraphRef
    bindElementRef(paragraphRef)
  }

  const recalculateTooltip = useCallback(() => {
    currentClampInstance?.reload()

    if (!ref.current) return setTooltipNeeded(false)

    const { scrollHeight, clientHeight } = ref.current

    setTooltipNeeded(scrollHeight > clientHeight)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      recalculateTooltip()
    }, 0)
  }, [])

  useResizeObserver({ elementsToObserve: ref.current, resizeListener: recalculateTooltip })

  const typographyElement = (
    <Typography elRef={bindRef} className={className} {...props}>
      {children}
    </Typography>
  )

  if (isTooltipNeeded)
    return (
      <Tooltip content={children} {...tooltipProps}>
        {typographyElement}
      </Tooltip>
    )

  return typographyElement
}

TypographyWithTooltip.Headline = ({ variant, children, as = 'h1', ...props }) => {
  return (
    <TypographyWithTooltip variant={variant} as={as} {...props}>
      {children}
    </TypographyWithTooltip>
  )
}

TypographyWithTooltip.Body = ({ variant, children, ...props }) => {
  return (
    <TypographyWithTooltip variant={variant} {...props}>
      {children}
    </TypographyWithTooltip>
  )
}

TypographyWithTooltip.Button = ({ variant, children, ...props }) => {
  return (
    <TypographyWithTooltip variant={variant} {...props}>
      {children}
    </TypographyWithTooltip>
  )
}

TypographyWithTooltip.Caption = ({ variant, children, ...props }) => {
  return (
    <TypographyWithTooltip variant={variant} {...props}>
      {children}
    </TypographyWithTooltip>
  )
}
