import { FC } from 'react'

import HeaderInfo from './HeaderInfo'
import NPA from './NPA'

interface NpaMunicipalFormsProps extends FC {
  HeaderInfo: typeof HeaderInfo
  NPA: typeof NPA
}

const NpaMunicipalForms: NpaMunicipalFormsProps = () => null

NpaMunicipalForms.HeaderInfo = HeaderInfo
NpaMunicipalForms.NPA = NPA

export default NpaMunicipalForms
