import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { RegistrationCertificateFormValues } from '@components/Forms/RegistrationCertificateForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const eleventhSectionRegistrationCertificateValidationMap: FormValuesValidationSection<
  keyof RegistrationCertificateFormValues['11'],
  RegistrationCertificateFormValues
> = {
  agreementTerminationReasonDescription: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  agreementTerminationReasonInvalidPart: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { eleventhSectionRegistrationCertificateValidationMap }
