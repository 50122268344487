import React, { FC, memo, useMemo } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps, MapOfMenu } from '@components/DocumentFormComponents/types'
import AATransferRightsAndObligations from '@components/Forms/AATransferRightsAndObligations'
import {
  aATransferRightsAndObligationsBlockValues,
  defaultAATransferRightsAndObligationsFormValues,
  mapOfAATransferRightsAndObligationsMenu,
} from '@components/Forms/AATransferRightsAndObligations/const'
import { AATransferRightsAndObligationsFormValues } from '@components/Forms/AATransferRightsAndObligations/types'
import { AATransferRightsAndObligationsFieldsControlUpdateWatcher } from '@components/Forms/AATransferRightsAndObligations/watcher'
import Typography from '@components/NewDesign/Typography'

import AATransferRightsAndObligationsLayoutWrapper from './Wrapper'

const AATransferRightsAndObligationsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<AATransferRightsAndObligationsFormValues>({
    defaultValues: defaultAATransferRightsAndObligationsFormValues,
  })

  const handleOnClose = () => {
    AATransferRightsAndObligationsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  const isFederalSZPK = useWatch({
    name: aATransferRightsAndObligationsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: aATransferRightsAndObligationsBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const currentMenu: MapOfMenu[] = useMemo(() => {
    return [...mapOfAATransferRightsAndObligationsMenu].reduce<MapOfMenu[]>(
      (previousValue, currentValue) => {
        if (!isFederalSZPK && currentValue.id === '1') return previousValue

        if (!isMunicipalitiesRender && currentValue.id === '3') return previousValue

        return [...previousValue, currentValue]
      },
      [],
    )
  }, [isFederalSZPK, isMunicipalitiesRender])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <AATransferRightsAndObligations.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager key={String(isFederalSZPK)} initialCurrentAnchorId={currentMenu[0].id}>
            <AATransferRightsAndObligationsLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Дополнительное соглашение о передаче прав и обязанностей
                </Typography.Headline>
                <AATransferRightsAndObligations.StatementForm
                  initialMenu={currentMenu}
                  className={styles.layout__form}
                />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <AATransferRightsAndObligations.Menu
                  initialMenu={currentMenu}
                  className={styles.layout__menu}
                />
              </div>
            </AATransferRightsAndObligationsLayoutWrapper>
          </FlatMenuManager>
        </AATransferRightsAndObligations.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(AATransferRightsAndObligationsLayout)
