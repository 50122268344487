import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'

export const AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()
export const AAgreementOnChangingCharacteristicsOfObjectsModifierFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>()
export const AAgreementOnChangingCharacteristicsOfObjectsFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
