import { generatePropertiesObjectForNotificationDrawalAppConclusionSZPKForm } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNotificationDrawalAppConclusionSZPKForm } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/adapters/RHF.adapter'
import { NotificationDrawalAppConclusionSZPKFormValues } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/types'

const useNotificationDrawalAppConclusionSZPKAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NotificationDrawalAppConclusionSZPKFormValues =>
    generateRHFObjectForNotificationDrawalAppConclusionSZPKForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNotificationDrawalAppConclusionSZPKForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNotificationDrawalAppConclusionSZPKAdapters }
