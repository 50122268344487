import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import PublicWrapper from '@components/PublicWrapper'
import { useAPIContext } from '@context/APIContext'
import useMediaQuery from '@hooks/useMediaQuery'
import IconOpen from '@icons/action/open_in_new.svg'
import OriginalLinkIcon from '@icons/OriginalLinkIcon.svg'
import cn from 'classnames'
import useSWRImmutable from 'swr/immutable'

import styles from './AuthorizedList.module.scss'

const KNOWLEDGE_DESKTOP_RESOLUTION = '(min-width: 1024px)'
const AuthorizedListPage = () => {
  const {
    innsApi: { getList },
  } = useAPIContext()

  const isDesktopMediaMatches = useMediaQuery(KNOWLEDGE_DESKTOP_RESOLUTION)

  const { data, isValidating: isLoading } = useSWRImmutable({ key: '_inns' }, getList)

  return (
    <PublicWrapper
      variant="default"
      position="center"
      title="Уполномоченные органы исполнительной власти (УОИВ), ответственные за работу с СЗПК"
      titleClassName={cn(styles.title, styles.space)}
      wrapperClassName={styles.wrapper}
      containerSize="xl"
    >
      <Loader loading={isLoading} className={styles.loader}>
        {data?.map(({ link, name, region, image, regionName }, index) => (
          <div
            data-testid={`AuthorizedList__organizations__${link ? 'withLink' : ''}`}
            className={styles.box}
            key={index + (regionName || '')}
          >
            <div className={styles.box__info}>
              <div className={styles.image}>
                {image && <img src={`data:image/jpeg;base64,${image}`} alt="" />}
              </div>
              <div className={styles.region}>{region}</div>
              <div className={styles.regionContainer}>
                <div className={styles.regionName}>{regionName}</div>
                <div className={styles.name}>{name}</div>
              </div>
            </div>
            {link && (
              <div className={styles.box__link}>
                {isDesktopMediaMatches ? (
                  <Button
                    geometry={'square'}
                    size={'s'}
                    view={'outline'}
                    trailingIcon={{ src: IconOpen, className: styles['box__link-icon'] }}
                    variant={'buttonSMedium'}
                    href={link.trim()}
                    target={'_blank'}
                  >
                    Перейти на сайт
                  </Button>
                ) : (
                  <IconButton
                    href={link}
                    target={'_blank'}
                    geometry={'square'}
                    view={'basic'}
                    size={'m'}
                    icon={{
                      src: OriginalLinkIcon,
                      noCurrentColorSvgFill: true,
                      size: 'xs',
                    }}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </Loader>
    </PublicWrapper>
  )
}

export default AuthorizedListPage
