import React from 'react'

import Indicator, { IndicatorProps } from '@components/NewDesign/Indicator/Indicator'
import { IndicatorOptions } from '@components/NewDesign/Indicator/types'
import cn from 'classnames'

import styles from './FormBlockTitleIndicator.module.scss'

interface FormBlockTitleIndicatorProps {
  indicatorOptions?: IndicatorOptions
  activeValue?: IndicatorProps['activeValue']
  wrapperClassName?: string
}

const FormBlockTitleIndicator = ({
  indicatorOptions,
  activeValue,
  wrapperClassName,
}: FormBlockTitleIndicatorProps) => {
  return (
    <Indicator
      wrapperClassName={cn(styles.formBlockTitleIndicator, wrapperClassName)}
      options={indicatorOptions}
      activeValue={activeValue}
    />
  )
}

export default FormBlockTitleIndicator
