import React, { FC, memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultNpaFormValues } from '@components/Forms/NpaForm/const'
import NpaFormLayout from '@components/Forms/NpaForm/Layout/index'

const NpaFormLayoutWithFormProvider: FC<NpaFormLayoutProps> = (props) => {
  return (
    <FormProviderWrapper defaultValues={defaultNpaFormValues}>
      <NpaFormLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(NpaFormLayoutWithFormProvider)
