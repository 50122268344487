import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Icon from '@components/Icon'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import { TCreateProjectFederalRadio } from '@components/NewDesignedModals/CreateProjectModal'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import Radio from '@components/Radio'
import { documentsLinks } from '@constants/documents'
import { useAPIContext } from '@context/APIContext'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useDfoAvailableForCreate } from '@hooks/new/swr/useDfoAvailableForCreate'
import { useDfoDocuments } from '@hooks/new/swr/useDfoDocuments'
import { useProject } from '@hooks/new/swr/useProject'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useProjectActions } from '@hooks/new/swr/useUiActions'
import WarningIcon from '@icons/alert/warning.svg'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import CreateProjectExample from '../CreateProjectModal/Example'

import styles from './RenameProject.module.scss'

interface EditProjectFormProps {
  nameOfProject: string

  typeOfProject: TCreateProjectFederalRadio
}

export interface RenameProjectModalProps {
  projectId: string
  dfoId: string
  projectName: string
  isFederalProject: boolean
  isFederalAccess: boolean
}

export type IRenameProjectModal = Required<PopupProps> & RenameProjectModalProps

const mapOfExamples = [
  {
    title: 'Строительство',
    subtitle: 'тип проекта',
  },
  {
    title: 'завода',
    subtitle: 'объект',
  },
  {
    title: 'для ремонта поездов',
    subtitle: 'цель объекта',
  },
  {
    title: 'на территории Московской области',
    subtitle: 'местоположение',
  },
] as const

const projectAttributeNames = {
  isFederal: 'isFederal',
  projectName: 'projectName',
} as const

const projectTypes = {
  isFederal: 'isFederal',
  isNotFederal: 'isNotFederal',
} as const

const federalAccessTexts = {
  federalText:
    'Если отменить участие РФ в соглашении, то его рассмотрят на региональном уровне. Документы, которые не понадобятся для согласования, будут удалены',
  notFederalText:
    'Если добавить участие РФ в соглашении, то его рассмотрят на федеральном уровне. Документы, которые не понадобятся для согласования, будут удалены',
} as const

const RenameProjectModal: FC<IRenameProjectModal> = ({
  onClose,
  isOpen,
  projectId,
  dfoId,
  projectName,
  isFederalProject,
  isFederalAccess,
}) => {
  const {
    projectsApi: { changeProjectAttribute },
  } = useAPIContext()

  const popupManager = usePopupManager()

  const { handleOpenLoadingModal } = useLoadingModal()

  const [nameOfProjectIsMutate, setNameOfProjectIsMutate] = useState<boolean>(false)
  const [projectTypeIsMutate, setProjectTypeIsMutate] = useState<boolean>(false)

  const { mutate: revalidateProjectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'RenameProjectModal',
          moduleName: 'UiActionsManager',
        },
      }),
    },
  })

  //Обновит статусы дфо на новые после статусного перехода.
  const { mutate: revalidateProjectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
    config: {
      isPaused: () => !projectId,
    },
  })

  const { mutate: revalidateProjectActions } = useProjectActions({
    key: {
      projectId,
      _key: 'projectActions',
    },
    config: {
      isPaused: () => !projectId,
    },
  })

  const { mutate: revalidateDocuments } = useDfoDocuments({
    key: { dfoId, projectId, _key: 'document-sets' },
    config: {
      isPaused: () => !projectId || !dfoId,
    },
  })

  const { mutate: revalidateProject } = useProject({
    key: { projectId, _key: 'project' },
    config: {
      isPaused: () => !projectId,
    },
  })

  const { mutate: revalidateAvailableDfoForCreate } = useDfoAvailableForCreate({
    key: {
      projectId,
      _key: 'availableDfoForCreate',
    },
    config: {
      isPaused: () => !projectId,
    },
  })

  const {
    formState: { isSubmitting, isValid },
    handleSubmit,
    getValues,
    control,
    watch,
  } = useForm<EditProjectFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nameOfProject: projectName,
      typeOfProject: isFederalProject ? projectTypes.isFederal : projectTypes.isNotFederal,
    },
  })

  const textAreaRef = useRef<HTMLInputElement | null>(null)

  const nameOfProjectInForm = watch('nameOfProject')
  const isFederalProjectInForm = watch('typeOfProject')

  useUpdateEffect(() => {
    const preparedIsFederal = isFederalProjectInForm === projectTypes.isFederal

    setNameOfProjectIsMutate(nameOfProjectInForm.trim() !== projectName.trim())

    setProjectTypeIsMutate(isFederalProject !== preparedIsFederal)
  }, [nameOfProjectInForm, isFederalProjectInForm])

  useEffect(() => {
    textAreaRef.current?.focus()
  }, [])

  const handleModalClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleEditProject = useCallback(async () => {
    const { nameOfProject, typeOfProject } = getValues()

    handleOpenLoadingModal()

    try {
      if (projectTypeIsMutate) {
        await changeProjectAttribute(projectId, {
          name: projectAttributeNames.isFederal,
          value: String(typeOfProject === projectTypes.isFederal),
        })
      }

      if (nameOfProjectIsMutate) {
        await changeProjectAttribute(projectId, {
          name: projectAttributeNames.projectName,
          value: nameOfProject,
        })
      }

      //TODO: Предполагаю, что есть у проект не изменилась мутации на projectType, то не нужны ревалидации кроме Project  и ProjectAttributes

      try {
        await revalidateDocuments()
        await revalidateProjectDfos()
        await revalidateAvailableDfoForCreate()
        await revalidateProjectAttributes()
        await revalidateProject()
        await revalidateProjectActions()
      } catch {}
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          projectName,
          isFederalProject,
          isFederalAccess,
        },
        componentInfo: {
          componentName: 'RenameProjectModal',
          moduleName: 'UiActionsManager',
          componentType: 'renameProject',
        },
      })(error)
    } finally {
      popupManager.closeAll()
    }
    onClose?.()
  }, [
    getValues,
    handleOpenLoadingModal,
    onClose,
    projectTypeIsMutate,
    nameOfProjectIsMutate,
    changeProjectAttribute,
    projectId,
    revalidateProjectAttributes,
    revalidateProject,
    revalidateProjectActions,
    revalidateProjectDfos,
    revalidateAvailableDfoForCreate,
    revalidateDocuments,
    popupManager,
  ])

  const actions = useMemo(() => {
    const projectIsMutated = nameOfProjectIsMutate || projectTypeIsMutate

    return [
      {
        view: 'gray',
        dataTestId: 'RenameProjectModal-cancel-button',
        children: 'Отмена',
        onClick: handleModalClose,
      },
      {
        dataTestId: 'RenameProjectModal-renameProject-button',
        children: 'Сохранить',
        disabled: !projectIsMutated || !nameOfProjectInForm.length || !isValid || isSubmitting,
        onClick: handleEditProject,
      },
    ] as ButtonProps[]
  }, [
    handleEditProject,
    handleModalClose,
    isSubmitting,
    isValid,
    nameOfProjectInForm.length,
    nameOfProjectIsMutate,
    projectTypeIsMutate,
  ])

  return (
    <Modal.Action
      isOpen={isOpen}
      title={'Редактировать соглашение'}
      dataTestId="RenameProjectModal-modal"
      closeButtonDataTestId="RenameProjectModal-modal-closeButton"
      actions={actions}
      onClose={onClose}
    >
      <form className={styles.editProjectModal} onSubmit={handleSubmit(handleEditProject)}>
        <ControlledTextarea
          name={'nameOfProject'}
          control={control}
          textareaProps={{
            label: 'Официальное наименование инвестиционного проекта',
            caption: 'наименование проекта должно соответствовать решению об утверждении бюджета',
            fixWidth: true,
            dataTestId: 'RenameProjectModal-nameOfProject-textarea',
          }}
          rules={{
            maxLength: {
              value: 1000,
              message: 'Название проекта соглашения не может превышать 1000 символов',
            },
          }}
        />
        <div
          className={cn(styles.editProjectModal__example, {
            [styles['editProjectModal__example--regional']]: !isFederalAccess,
          })}
        >
          <Typography.Body color={'text-base-secondary'} variant={'bodySMedium'}>
            Пример заполнения
          </Typography.Body>
          <div className={styles['editProjectModal__example-items']}>
            {mapOfExamples.map((example, index) => {
              return (
                <CreateProjectExample
                  key={index}
                  title={example.title}
                  subtitle={example.subtitle}
                />
              )
            })}
          </div>
        </div>
        {isFederalAccess && (
          <div className={styles.editProjectModal__isFederal}>
            <Typography.Body
              variant={'bodyMMedium'}
              className={styles['editProjectModal__isFederal-title']}
            >
              Российская Федерация участвует в соглашении?
            </Typography.Body>
            <div className={styles['editProjectModal__isFederal-box']}>
              <Icon className={styles.warning} src={WarningIcon} size="s" />
              <Typography.Caption
                variant="captionMMedium"
                color="text-accent-orange-secondary"
                className={styles['warning-text']}
              >
                {isFederalProjectInForm === projectTypes.isFederal
                  ? federalAccessTexts.federalText
                  : federalAccessTexts.notFederalText}
              </Typography.Caption>
            </div>
            <div className={styles['editProjectModal__isFederal-radio']}>
              <Radio.ControlledGroup control={control} name={'typeOfProject'}>
                <Radio.Button value={projectTypes.isFederal} label={'участвует'} />
                <Radio.Button value={projectTypes.isNotFederal} label={'не участвует'} />
              </Radio.ControlledGroup>
            </div>

            <Typography.Caption
              className={styles['editProjectModal__isFederal-caption']}
              variant={'captionSRegular'}
            >
              {
                'РФ может стать участником соглашения при объеме капиталовложений от 750 млн руб. в зависимости от '
              }
              <Typography.Caption
                as={'span'}
                variant={'captionSRegular'}
                color={'text-accent-brand'}
              >
                <a href={documentsLinks.FZ_N69} target="_blank" rel="noopener noreferrer">
                  сферы экономики
                </a>
              </Typography.Caption>
              {' (п. 2 ч. 4 ст. 9 69-ФЗ)'}
            </Typography.Caption>
          </div>
        )}
      </form>
    </Modal.Action>
  )
}

export default RenameProjectModal
