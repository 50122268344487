import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectRegion,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const objectRegionsValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectRegion,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  objectRegion: {
    required: defaultRHFValidation.required,
  },
}

export { objectRegionsValidationMap }
