import { useCallback } from 'react'

import { Urls } from '@constants/urls'
import { GetOrganizationForm, GetRegions } from '@context/APIContext/hooks/useDictionariesApi/types'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'

const useDictionariesApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const getRegions = useCallback(async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetRegions>({
        url: `${Urls.Dfo}/dictionaries/regions`,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }, [])

  const getReorganizationsForm = useCallback(async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetOrganizationForm>({
        url: `${Urls.Dfo}/dictionaries/org-reorganization-types`,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }, [])

  return {
    getRegions,
    getReorganizationsForm,
  }
}

export default useDictionariesApi
