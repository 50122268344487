import React, { FC, memo, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'

import Icon from '@components/Icon/Icon'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import NpaModalFiltersOld from '@components/RegistryNpa/ChooseOld/FiltersOld'
import NpaModalGridOld from '@components/RegistryNpa/ChooseOld/GridOld'
import { useChooseNpaManagerOld } from '@components/RegistryNpa/ChooseOld/ManagerOld'
import env from '@config'
import { formatCurrency } from '@helpers/format'
import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import searchIcon from '@icons/action/search.svg'
import closeIcon from '@icons/navigation/close.svg'

import styles from './ModalOld.module.scss'

type RegistryNpaChooseModalOldProps = PopupProps

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

const RegistryNpaChooseModalOld: FC<RegistryNpaChooseModalOldProps> = ({ isOpen, onClose }) => {
  const {
    state: {
      filtersInstance,
      npaReducer: [state],
      viewMode,
      gridSwrInstance,
    },
    actions: { handlePutNpaDraft },
  } = useChooseNpaManagerOld()

  const { mapOfNpaDirections } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const preparedOnClose = () => {
    onClose?.()
  }

  const direction = filtersInstance?.watch('filters.direction')

  const investmentRenderCondition = useMemo(() => {
    return (
      !gridSwrInstance?.isNpaListLoading &&
      viewMode === 'default' &&
      state.threeToRender?.[0]?.minInvestmentAmount
    )
  }, [gridSwrInstance?.isNpaListLoading, state.threeToRender, viewMode])

  return (
    <Modal.Base
      containerClassName={styles.registryNpaModal__container}
      isOpen={isOpen}
      dataTestId="RegistryNpaChooseModal-modal"
      onClose={preparedOnClose}
    >
      <div className={styles.registryNpaModal}>
        <header className={styles.registryNpaModal__header}>
          <Typography.Headline variant={'headlineH2'}>Выберите НПА</Typography.Headline>
          <div className={styles['registryNpaModal__header-rightPart']}>
            <ControlledInput
              control={filtersInstance?.control}
              name="searchString"
              inputProps={{
                rootClassName: styles.registryNpaModal__search,
                size: 'm',
                placeholder: 'Поиск по наименованию и номеру акта',
                clear: true,
                view: 'secondary',
                dataTestId: 'RegistryNpaChooseModal-searchString-input',
                leftAddons: (
                  <Icon
                    noCurrentColorSvgFill
                    src={searchIcon}
                    size="s"
                    className={styles.searchIcon}
                  />
                ),
              }}
            />
            <Button
              className={styles.registryNpaModal__addButton}
              disabled={!state.chosenParts.length || state.requestLoading}
              dataTestId="RegistryNpaChooseModal-addNpa-button"
              loaderProps={{
                loading: state.requestLoading,
                placement: 'trailing',
                variant: 'lite',
              }}
              onClick={handlePutNpaDraft}
            >
              Добавить
            </Button>
          </div>
        </header>
        <div className={styles.registryNpaModal__controls}>
          <NpaModalFiltersOld />
        </div>

        {investmentRenderCondition && direction && (
          <div className={styles.registryNpaModal__investmentSum}>
            <Typography.Body color={'text-accent-brand'} variant={'bodyMMedium'}>
              {`Чтобы стабилизировать НПА «${
                mapOfNpaDirections?.[direction]
              }», объём капиталовложений проекта должен быть выше ${
                state.threeToRender[0].minInvestmentAmount
                  ? formatCurrency(state.threeToRender[0].minInvestmentAmount)
                  : ''
              } ₽`}
            </Typography.Body>
          </div>
        )}

        <div className={styles.registryNpaModal__grid}>
          <NpaModalGridOld />
        </div>
        <div className={styles.registryNpaModal__info}>
          <Typography.Body as={'span'} variant={'bodyMMedium'}>
            Если Вы не нашли нужное положение или акт, напишите в Минэкономразвития России:{' '}
          </Typography.Body>
          <Typography.Body variant={'bodyMMedium'} color={'text-accent-brand'}>
            <a href={`mailto:${ECONOMY_SUPPORT_EMAIL}`}>{ECONOMY_SUPPORT_EMAIL}</a>
          </Typography.Body>
        </div>
      </div>
      <IconButton
        className={styles.registryNpaModal__close}
        view={'basic'}
        size={'xl'}
        geometry={'round'}
        dataTestId="RegistryNpaChooseModal-closeModal-iconButton"
        icon={{ src: closeIcon, noCurrentColorSvgFill: true }}
        onClick={preparedOnClose}
      />
    </Modal.Base>
  )
}

export default memo(RegistryNpaChooseModalOld)
