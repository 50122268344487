import React, { useCallback, useEffect, useMemo } from 'react'

import { SingleSelect } from '@components/NewDesign/Select'
import Typography from '@components/NewDesign/Typography'
import { TransfersData } from '@context/APIContext/hooks/useOrganizationApi'
import { IOrganization } from '@context/APIContext/hooks/useOrganizationsApi'
import { useTransfers } from '@hooks/swr/useTransfers'
import { FOUNDED_FOR_TRANSFERS, PAGE_SIZE } from '@routes/Projects/shared/GetRightToSZPK/constants'
import { useSWRConfig } from 'swr'

import { TStep } from '../model'
import { ExistingOptionsValues } from '../SpecifyingSZPKData'

import styles from './styles.module.scss'

const transfersToOptions = (transfers?: TransfersData[]) =>
  transfers?.map(({ projectInfo, transferId }: TransfersData) => {
    const label = `${projectInfo.registeredNumber} от ${projectInfo.registeredDate}`

    return {
      label,
      value: transferId,
      displayValue: projectInfo.name,
    }
  })

const PAGINATION_BOTTOM_TRIGGER = 100

export const ChooseSZPK: TStep = ({ onChange, values }) => {
  const { cache } = useSWRConfig()

  const organization = cache.get(FOUNDED_FOR_TRANSFERS) as IOrganization | undefined

  const preparedSwrKey = useCallback(
    (page) => ({
      page,
      size: PAGE_SIZE,
      organizationId: organization?.id,
      orgReorganizationType: values.formOfReorganization as ExistingOptionsValues,
      _key: 'transfers',
    }),
    [organization?.id, values.formOfReorganization],
  )

  const { transfers, error, isValidating, hasMoreData, goToNextPage } = useTransfers({
    key: preparedSwrKey,
    limitSize: PAGE_SIZE,
    config: {
      revalidateFirstPage: false,
    },
  })

  const onContentScroll = async (e) => {
    const requestCondition = hasMoreData && !isValidating && !error

    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
        PAGINATION_BOTTOM_TRIGGER &&
      requestCondition
    ) {
      goToNextPage()
    }
  }

  const options = useMemo(() => transfersToOptions(transfers) || [], [transfers])
  const isSelectDisabled = isValidating || !options.length
  const defaultSelected = options.length === 1 ? options[0].value : undefined

  useEffect(() => {
    if (!defaultSelected) return

    onChange('transferId', defaultSelected)

    const targetTransfer = transfers?.find((transfer) => transfer.transferId === defaultSelected)

    if (!targetTransfer) return

    const { dfoId, projectId } = targetTransfer.projectInfo

    onChange('projectInfo', {
      dfoId,
      projectId,
    })
  }, [defaultSelected, onChange, transfers])

  return (
    <SingleSelect
      disabled={isSelectDisabled}
      options={options}
      defaultSelected={defaultSelected}
      inputProps={{
        inputCursor: isSelectDisabled ? 'default' : isValidating ? 'wait' : 'pointer',
        fixWidth: true,
        label: 'СЗПК',
      }}
      optionsContainer={{
        onScroll: onContentScroll,
      }}
      popoverProps={{
        zIndex: 52,
      }}
      optionsProps={{
        scrollableOptionsContainerClassName: styles.container,
        optionClassName: styles.option,
        DisplayValueFC: ({ displayValue }) => (
          <Typography variant={'bodyMRegular'} className={styles.optionDisplayValue}>
            {displayValue}
          </Typography>
        ),
      }}
      onChangeForm={(value) => {
        onChange('transferId', value)
        const targetTransfer = transfers?.find((transfer) => transfer.transferId === value)

        if (targetTransfer) {
          onChange('projectInfo', {
            dfoId: targetTransfer.projectInfo.dfoId,
            projectId: targetTransfer.projectInfo.projectId,
          })
        }
      }}
    />
  )
}
