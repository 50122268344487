import React, { FC, memo } from 'react'
import { Path, useWatch } from 'react-hook-form'

import { blockValues } from '@components/Forms/CreateStatementOld/const'
import FieldView from '@components/Forms/CreateStatementOld/FieldView'
import globalFormStyles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { StatementOldFormValues } from '@components/Forms/CreateStatementOld/types'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import styles from './Stage.module.scss'

interface IStepStage {
  name: string
  positionOfStage: number
  onRemoveStage: (index: number) => void

  onBeforeRemoveStage?: () => void
}

const Stage: FC<IStepStage> = ({ name, onRemoveStage, positionOfStage, onBeforeRemoveStage }) => {
  const {
    state: { formInstance, blockViewIsValidating },
  } = useCreateStatementManager()

  const startDate = useWatch({
    name: blockValues.stages.startDate,
    control: formInstance?.control,
  })

  const projectEndDate = useWatch({
    name: blockValues.stages.projectEndDate,
    control: formInstance?.control,
  })

  const positionStartDate = useWatch({
    name: `${name}.${positionOfStage}.startDate` as Path<StatementOldFormValues>,
    control: formInstance?.control,
  })

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate
      dataTestId={'stagesListItem'}
      style={{ marginTop: '16px' }}
      onBeforeRemoveStep={onBeforeRemoveStage}
      onRemoveStep={() => onRemoveStage(positionOfStage)}
    >
      <div className={globalFormStyles.form__item}>
        <ControlledInput
          control={formInstance.control}
          name={`${name}.${positionOfStage}.name` as Path<StatementOldFormValues>}
          inputProps={{
            view: 'secondary',
            size: 'xl',
            fixWidth: true,
            label: 'Этап проекта',
            maxLength: 500,
            dataTestId: `Stages-${name}.${positionOfStage}.name-input`,
          }}
          rules={{
            required: defaultRHFValidation.required,
            validate: (v) => isString(v) && lengthValidate(v, 500),
            onChange: () => {
              if (blockViewIsValidating)
                formInstance.trigger(
                  `${name}.${positionOfStage}.name` as Path<StatementOldFormValues>,
                )
            },
          }}
        />
      </div>

      <div className={globalFormStyles.form__row}>
        <div className={cn(globalFormStyles.form__item, styles.stage__leftPart)}>
          <ControlledCalendarInput
            control={formInstance.control}
            name={`${name}.${positionOfStage}.startDate` as Path<StatementOldFormValues>}
            calendarInputProps={{
              size: 'xl',
              view: 'secondary',
              label: 'Дата начала этапа',
              dataTestId: `Stages-${name}.${positionOfStage}.startDate-calendarInput`,
            }}
            rules={{
              required: defaultRHFValidation.required,
              validate: {
                positiveProjectStart: (v) =>
                  (isString(v) &&
                    dayjs(v, objOfDateFormats.defaultFormat) >=
                      dayjs(startDate, objOfDateFormats.defaultFormat)) ||
                  'дата начала этапа должна быть больше или равна дате начала проекта',
                positiveProjectEnd: (v) =>
                  (isString(v) &&
                    dayjs(v, objOfDateFormats.defaultFormat) <=
                      dayjs(projectEndDate, objOfDateFormats.defaultFormat)) ||
                  'дата начала этапа должна быть меньше или равна дате окончания проекта',
              },
              onChange: () => {
                if (blockViewIsValidating)
                  formInstance.trigger(
                    `${name}.${positionOfStage}.startDate` as Path<StatementOldFormValues>,
                  )
              },
            }}
          />
        </div>

        <div className={cn(globalFormStyles.form__item, styles.stage__rightPart)}>
          <ControlledCalendarInput
            control={formInstance.control}
            name={`${name}.${positionOfStage}.endDate` as Path<StatementOldFormValues>}
            calendarInputProps={{
              size: 'xl',
              view: 'secondary',
              label: 'Дата окончания этапа',
              dataTestId: `Stages-${name}.${positionOfStage}.endDate-calendarInput`,
            }}
            rules={{
              required: defaultRHFValidation.required,
              validate: {
                positiveProjectStart: (v) =>
                  (isDateValidForDayjs(v) &&
                    dayjs(v, objOfDateFormats.defaultFormat) >=
                      dayjs(startDate, objOfDateFormats.defaultFormat)) ||
                  (v && positionStartDate && positionStartDate <= v) ||
                  `дата окончания этапа не может быть меньше даты начала проекта и даты начала этапа`,
                positiveProject: (v) =>
                  (isDateValidForDayjs(v) &&
                    dayjs(v, objOfDateFormats.defaultFormat) <=
                      dayjs(projectEndDate, objOfDateFormats.defaultFormat)) ||
                  'дата окончания этапа должна быть меньше или равна дате окончания проекта',
                positiveStep: (v) =>
                  (isDateValidForDayjs(v) &&
                    dayjs(positionStartDate as unknown as string, objOfDateFormats.defaultFormat) <
                      dayjs(v, objOfDateFormats.defaultFormat)) ||
                  'дата окончания этапа должна быть больше даты начала этапа',
              },
              onChange: () => {
                if (blockViewIsValidating) {
                  formInstance.trigger(
                    `${name}.${positionOfStage}.endDate` as Path<StatementOldFormValues>,
                  )
                }
              },
            }}
          />
        </div>
      </div>
    </FieldView.StepTemplate>
  )
}

export default memo(Stage)
