import { forwardRef, memo } from 'react'

import { INotificationItem } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/Attachment/NotificationAttachment'
import useRevalidateUntilFormsLoaded from '@hooks/new/documents/useRevalidateUntilFormsLoaded'
import { useDfoDocuments } from '@hooks/new/swr/useDfoDocuments'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import { useNotificationsDocumentTypeDictInfo } from './hooks/useNotificationsDocumentTypeDictInfo'
import { CURRENT_DOCUMENT_SET_TYPE } from './constants'
import NotificationsItemDocument from './NotificationsItemDocument'

export const getNotificationItemDfoDocumentsKey= (
  projectId: string,
  dfoId: string) => ({
    _key: 'dfoDocuments',
    projectId,
    dfoId,
  })

type NotificationItemProps = { attachmentLoading: INotificationItem['loading'] } & Pick<
  INotificationItem,
  'projectId' | 'dfoId'
>

const NotificationItem = forwardRef<HTMLDivElement, NotificationItemProps>(
  ({ dfoId, projectId, attachmentLoading }, ref) => {
    const {
      dfoDocuments,
      mutate: revalidateDfoDocuments,
      isLoadingDfoDocuments,
    } = useDfoDocuments({
      key: getNotificationItemDfoDocumentsKey(projectId, dfoId),
      config: {
        isPaused: () => !dfoId,
        onError: LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'NotificationItem',
            componentType: 'NotificationsList',
          },
        }),
      },
    })

    //В каждый dfo приходит только один set
    const documentSet = dfoDocuments?.[CURRENT_DOCUMENT_SET_TYPE][0]

    const documentTypeDictInfo = useNotificationsDocumentTypeDictInfo(projectId, documentSet)

    const forms = documentTypeDictInfo
      ?.map((documentTypeDictInfoItem) => documentTypeDictInfoItem?.forms || [])
      .flat()

    useRevalidateUntilFormsLoaded({
      isRevalidatePaused: isLoadingDfoDocuments,
      revalidateCallback: revalidateDfoDocuments,
      forms,
    })

    if (!dfoDocuments) return null

    return (
      <div ref={ref}>
        {documentTypeDictInfo.map((documentTypeDictInfoItem, index) => (
          <NotificationsItemDocument
            key={index}
            projectId={projectId}
            dfoId={dfoId}
            documentSet={documentSet}
            documentTypeDictInfoItem={documentTypeDictInfoItem}
            attachmentLoading={attachmentLoading}
          />
        ))}
      </div>
    )
  },
)

export default memo(NotificationItem)
