import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { usePopupManager } from 'react-popup-manager'

import EventsSidebar, { EventsSidebarProps } from '@components/Sidebars/Events/index'
import { EventItem } from '@context/APIContext/hooks/useProjectsApi'
import { compact } from '@helpers/array/compact'
import { useDfoTypes } from '@hooks/new/swr/useDfoTypes'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import useProjectEvents from '@hooks/new/swr/useProjectEvents'
import DayjsService from '@services/Dayjs/Dayjs.service'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

interface SideBarFilters {
  [key: string]: {
    name: string
    value: string
  }
}

export interface EventToDisplay extends EventItem {
  eventTitle: string
}

const useEventSidebarControl = (projectId?: string) => {
  const popupManager = usePopupManager()

  const openEventSideBarPanel = async () => {
    if (!projectId) return

    popupManager.open<EventsSidebarProps>(EventsSidebar, {
      projectId: projectId || '',
    })
  }

  return { openEventSideBarPanel }
}

const useEventsSidebar = (projectId: string) => {
  const filtersFormInstance = useForm<SideBarFilters>()

  const filterValues = filtersFormInstance.watch()

  const currentDfoTypesToRequest = useMemo(() => {
    return compact(
      Object.entries(filterValues).map(([dfoType, dfoInfo]) => (dfoInfo.value ? dfoType : null)),
    )
  }, [filterValues])

  const { projectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          filterValues,
        },
        componentInfo: {
          componentName: 'useEventsSidebar',
          moduleName: 'EventsSidebar',
          componentType: 'hook',
        },
      }),
    },
  })

  //Значение сразу должно браться из кеша. Запись в кеш при перед открытием
  const { events, eventIsLoading } = useProjectEvents({
    key: Object.keys(filterValues).length
      ? {
          projectId,
          dfoTypes: currentDfoTypesToRequest,
          _key: 'projectEvents',
        }
      : null,
    config: {
      revalidateOnMount: true,
    },
  })

  const { dfoTypes } = useDfoTypes({ key: { _key: 'dfoTypes' } })

  //Формируем фильтры.
  useEffect(() => {
    if (!dfoTypes) return

    const newFilters = projectDfos?.reduce((prevValue, currentDfo) => {
      return {
        ...prevValue,
        [currentDfo.type]: {
          name: dfoTypes[currentDfo.type]?.name,
          value: true,
        },
      }
    }, {})

    filtersFormInstance.reset(newFilters)
  }, [dfoTypes, filtersFormInstance, projectDfos])

  const prepareEvents = useMemo(() => {
    if (!projectDfos || !dfoTypes || !events) return

    return events.map((event) => {
      const currentFormat =
        DayjsService.dayjsWithFormatToMSK().year() ===
        DayjsService.dayjs(event.eventDateTime).year()
          ? 'DD MMMM · HH:mm'
          : 'DD MMMM YYYY · HH:mm'

      const preparedDate = DayjsService.dayjs(event.eventDateTime).format(currentFormat)

      return {
        ...event,
        eventDateTime: preparedDate,
        eventTitle: dfoTypes[event.dfoType]?.name,
      } as EventToDisplay
    })
  }, [events, projectDfos])

  return {
    eventsIsEmpty: !events?.length,
    events: prepareEvents,
    eventIsLoading,
    filtersFormInstance,
  }
}

export { useEventSidebarControl, useEventsSidebar }
