import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import { useSupplementalCostSharingAManager } from '@components/Forms/SupplementalCostSharingAForm/Manager'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'

import { mapOfSupplementalCostSharingAMenu, supplementalCostSharingABlockValues } from '../const'
import SupplementalCostSharingAForms from '../Forms'

const mapOfSupplementalCostSharingAForms: FormItemProps = {
  '1': {
    node: <SupplementalCostSharingAForms.One />,
  },
  '2': {
    node: <SupplementalCostSharingAForms.Two />,
  },
  '3': {
    node: <SupplementalCostSharingAForms.Three />,
  },
  '4': {
    node: <SupplementalCostSharingAForms.Four />,
  },
  '5': {
    node: <SupplementalCostSharingAForms.Five />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '6': {
    node: <SupplementalCostSharingAForms.Six />,
  },
  '7': {
    node: <SupplementalCostSharingAForms.Seven />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const SupplementalCostSharingAForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<SupplementalCostSharingAFormValues>()

  const isFederalSZPK = useWatch({
    name: supplementalCostSharingABlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: supplementalCostSharingABlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const {
    state: { formIsLoading },
  } = useSupplementalCostSharingAManager()

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfSupplementalCostSharingAForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={initialMenu ?? mapOfSupplementalCostSharingAMenu}
      mapOfForms={currentForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(SupplementalCostSharingAForm)
