import { accessPermissions } from '@constants/types'

class DocumentsHelpersService {
  static isDocumentReadOnlyPermission(permissions: number): boolean {
    return permissions === accessPermissions.READ
  }

  static isDocumentEditPermission(permissions: number): boolean {
    return permissions === accessPermissions.EDIT
  }

  static isDocumentReadEditPermission(permissions: number): boolean {
    return permissions === accessPermissions.READ_EDIT
  }

  static isDocumentSignPermission(permissions: number): boolean {
    return permissions === accessPermissions.SIGN
  }

  static isDocumentReadSignPermission(permissions: number): boolean {
    return permissions === accessPermissions.READ_SIGN
  }

  static isDocumentReadAddSignPermission(permissions: number): boolean {
    return permissions === accessPermissions.READ_ADD_SIGN
  }

  static isDocumentReadSignMainPermission(permissions: number): boolean {
    return permissions === accessPermissions.READ_SIGN_MAIN
  }

  static isDocumentReadApprovePermission(permissions: number): boolean {
    return permissions === accessPermissions.READ_APPROVE
  }

  static isDocumentDownloadingForbidden(permissions: number): boolean {
    return permissions === accessPermissions.DOWNLOADING_DOCUMENTS_FORBIDDEN
  }

  static isDocumentReadDownloadingForbidden(permissions: number): boolean {
    return permissions === accessPermissions.READ_DOWNLOADING_DOCUMENTS_FORBIDDEN
  }

  static isDocumentReadDigitizing(permissions: number): boolean {
    return permissions === accessPermissions.READ_DIGITIZING
  }
}

export { DocumentsHelpersService }
