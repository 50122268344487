import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionAAgreementOnCreationOtherEstateObjectsValidationMap: FormValuesValidationSection<
  keyof AAgreementOnCreationOtherEstateObjectsFormValues['3']['municipalities'][number],
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  municipalityFullNameGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },

  municipalityHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { thirdSectionAAgreementOnCreationOtherEstateObjectsValidationMap }
