import { letterHeadPartsBaseTexts } from '@constants/texts'
import { LetterHeadPartsUrls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import saveAs from 'file-saver'

import { LetterHeadFile, loadedLetterHeadData } from './types'

export const useHeaderLetterPartsApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const uploadLetterHeadPartFile = async (data: FormData) => {
    try {
      return await FetcherWithInterceptors.post<loadedLetterHeadData>({
        url: {
          url: LetterHeadPartsUrls.UpLoadPart,
        },
        data,
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const saveLetterHeadPart = async (data: string) => {
    try {
      return await FetcherWithInterceptors.put({
        url: {
          url: LetterHeadPartsUrls.Parts,
        },
        data,
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getOrganizationLetterHeadParts = async (organizationId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<LetterHeadFile[]>({
        url: {
          url: LetterHeadPartsUrls.OrganizationParts,
          params: {
            organizationId,
          },
        },
      })
      return data
    } catch (error) {
      throw error
    }
  }

  const getOrganizationLetterHeadPartFile = async (
    fileId: string,
    name: string,
    extension: string,
  ) => {
    try {
      const { data } = await FetcherWithInterceptors.get<File>({
        url: {
          url: LetterHeadPartsUrls.DownloadFile,
          params: {
            fileId,
          },
        },
        config: {
          responseType: 'blob' as ResponseType,
          params: {
            name,
            extension,
          },
        },
      })

      saveAs(data, `${name}.${extension}`)

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)
      throw error
    }
  }

  const getOrganizationLetterHeadBlankPreview = async (data: string) => {
    try {
      const { data: previewData } = await FetcherWithInterceptors.post<Blob>({
        url: {
          url: LetterHeadPartsUrls.Preview,
        },
        data,
        config: {
          responseType: 'blob',
        },
      })

      const fileToOpen = new File([previewData], letterHeadPartsBaseTexts.previewFileName, {
        type: 'application/pdf',
      })

      const fileUrl = URL.createObjectURL(fileToOpen)

      window.open(fileUrl)
      URL.revokeObjectURL(fileUrl)

      return previewData
    } catch (error) {
      handleErrorWithPopup(error, true)
      throw error
    }
  }

  return {
    uploadLetterHeadPartFile,
    getOrganizationLetterHeadParts,
    saveLetterHeadPart,
    getOrganizationLetterHeadPartFile,
    getOrganizationLetterHeadBlankPreview,
  }
}
