import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import { DependentUrls, Urls } from '@constants/urls'
import { variantsSearchFieldsParams } from '@context/APIContext/hooks/useOrganizationsApi/const '
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { stringifyQuery } from '@helpers/format'
import type { IOrganizationInfo } from '@services/Organization/organization.entity'
import { unstable_serialize, useSWRConfig } from 'swr'

import type {
  IGetAllOrganizationsListParams,
  IGetOrganizationsListParams,
  IOrganizations,
} from './types'

const useOrganizationsApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const { cache } = useSWRConfig()

  const getOrganizationById = async (organizationId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<IOrganizationInfo>({
        url: {
          url: DependentUrls.OrganizationById,
          params: {
            organizationId,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getOrganizationsList = async (params: IGetOrganizationsListParams) => {
    const preparedParams = {
      ...params,
      searchString: params?.searchString || undefined,
      ...(params?.searchFields?.length && {
        searchFields: params.searchString ? variantsSearchFieldsParams[params.searchFields] : [],
      }),
    }

    try {
      const { data } = await FetcherWithInterceptors.get<IOrganizations>({
        url: Urls.Organizations,
        config: {
          params: preparedParams,
          paramsSerializer: stringifyQuery,
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getAllOrganizationsList = async ({
    _key,
    ...params
  }: IGetAllOrganizationsListParams): Promise<IOrganizations> => {
    try {
      const organizations = await getOrganizationsList(params)

      cache.set(unstable_serialize({ ...params, _key }), organizations)

      if (!organizations.totalPage) return organizations

      const allOrganizations = await Promise.all(
        new Array(organizations.totalPage).fill(null).map(async (_, pageIndex) => {
          const paramsWithNextPage: IGetOrganizationsListParams = { ...params, page: pageIndex + 1 }
          const organizationsFromNextPage = await getOrganizationsList(paramsWithNextPage)

          cache.set(unstable_serialize({ ...paramsWithNextPage, _key }), organizationsFromNextPage)

          return organizationsFromNextPage
        }),
      )

      const newOrganizations = allOrganizations.reduce((previous, current) => ({
        ...previous,
        ...current,
        organizations: [...previous.organizations, ...current.organizations],
      }))

      return {
        ...newOrganizations,
        organizations: [...organizations.organizations, ...newOrganizations.organizations],
      }
    } catch (error) {
      throw error
    }
  }

  const block = async (id: string) => {
    try {
      return await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.BlockOrganization,
          params: { id },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)
    }
  }

  const unblock = async (id: string) => {
    try {
      return await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.UnblockOrganization,
          params: { id },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)
    }
  }

  //Наверху оборачивать в try/catch, если 2**, то считается, что инвестор содержит старый проект, если выпадает в catch, то не содержит
  const checkUserToOldStatement = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get({
        url: `${Urls.Organizations}/old-statement-allowed`,
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const getMunicipalityMembersById = async (ids: string[]): Promise<IOrganizationInfo[]> => {
    try {
      const allSettledResult = await Promise.allSettled(ids.map((id) => getOrganizationById(id)))

      const fulfilledMembers = (
        allSettledResult.filter(
          ({ status }) => status === 'fulfilled',
        ) as PromiseFulfilledResult<IOrganizationInfo>[]
      )?.map(({ value }) => value)

      const rejectedMembers = allSettledResult.filter(({ status }) => status === 'rejected')

      if (rejectedMembers.length && rejectedMembers.length < allSettledResult.length) {
        toast(
          <Alert transparent variant="warning">
            Не все организации были загружены
          </Alert>,
        )
      }

      if (allSettledResult.length === rejectedMembers.length) {
        toast(
          <Alert transparent variant="error">
            Организации не были загружены
          </Alert>,
        )
      }

      return fulfilledMembers
    } catch (error) {
      throw error
    }
  }

  return {
    getOrganizationsList,
    getAllOrganizationsList,
    getOrganizationById,
    block,
    unblock,
    checkUserToOldStatement,
    getMunicipalityMembersById,
  }
}

export default useOrganizationsApi
