import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useInitialCollapseStateList } from '@components/DocumentFormComponents/hooks/useInitialCollapseStateList'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aaVolumeOfCapitalInvestmentsBlockValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/const'
import CapitalStep from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/AdditionalForms/MainTemplate'
import { FifthCapitalStepsPathName } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/types'
import { useAAVolumeOfCapitalInvestmentsManager } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Manager'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import {
  AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher,
  AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
} from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import { isUndefined } from '@helpers/checkTypes'

const Fifth = () => {
  const formInstance = useFormContext<AAVolumeOfCapitalInvestmentsFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useAAVolumeOfCapitalInvestmentsManager()

  const { fields: formFields } = useFieldArraySubscribableControl<
    AAVolumeOfCapitalInvestmentsFormValues,
    FifthCapitalStepsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aaVolumeOfCapitalInvestmentsBlockValues['5'].capitalSteps,
    keyName: 'keyNameId',
    watcher: AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher,
  })

  const preparedStages = useWatch({
    name: aaVolumeOfCapitalInvestmentsBlockValues['5'].capitalSteps,
    control: formInstance?.control,
  })

  const conditionToFormFieldsRender =
    !!preparedStages?.length && !!formFields.length && preparedStages?.length === formFields.length

  const { initialCollapseState } = useInitialCollapseStateList(formFields)

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Row>
        <Col xs={12}>
          {!conditionToFormFieldsRender && <EmptyDataText />}
          <SubscribableControl
            {...getSubscribableControlProps({
              path: aaVolumeOfCapitalInvestmentsBlockValues['5'].capitalSteps,
            })}
          >
            <FlipperList list={formFields} gap={1}>
              {conditionToFormFieldsRender &&
                formFields.map((field, index) => {
                  const currentInitState = !isUndefined(initialCollapseState[field.id])
                    ? initialCollapseState[field.id]
                    : true

                  const formName =
                    `${aaVolumeOfCapitalInvestmentsBlockValues['5'].capitalSteps}.${index}` as const

                  return (
                    <SubscribableControl
                      key={field.id}
                      {...getSubscribableControlProps({
                        path: formName,
                      })}
                    >
                      <CapitalStep
                        id={field.id}
                        initialAccordionState={currentInitState}
                        name={formName}
                      />
                    </SubscribableControl>
                  )
                })}
            </FlipperList>
          </SubscribableControl>
        </Col>
      </Row>
    </Container>
  )
}

export default Fifth
