import { FC } from 'react'

import One from './1'
import Two from './2'
import Three from './3'
import Four from './4'

interface FinancialModelFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Three: typeof Three
  Four: typeof Four
}

const FinancialModelForms: FinancialModelFormsProps = () => null

FinancialModelForms.One = One
FinancialModelForms.Two = Two
FinancialModelForms.Three = Three
FinancialModelForms.Four = Four

export default FinancialModelForms
