import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { npaSolicitationInclusionFormBlockValues } from '@components/Forms/NpaSolicitationInclusionForm/const'
import { useNpaSolicitationInclusionFormManager } from '@components/Forms/NpaSolicitationInclusionForm/Manager'
import { NpaSolicitationInclusionFormValues } from '@components/Forms/NpaSolicitationInclusionForm/types'
import { NpaSolicitationInclusionFieldsControlUpdateWatcher } from '@components/Forms/NpaSolicitationInclusionForm/watcher'
import Button from '@components/NewDesign/Button'
import useAuthorizedPersonModal from '@components/NewDesignedModals/AddAuthorizedPersonModal/manager'
import Container from '@components/ReactBootstrap/Container'
import PersonAddIcon from '@icons/PersonAddIcon.svg'

const HeaderInfo = () => {
  const formInstance = useFormContext<NpaSolicitationInclusionFormValues>()

  const {
    state: { blockViewIsValidating },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useNpaSolicitationInclusionFormManager()

  const { handleOpenAddAuthorizedPersonModal } = useAuthorizedPersonModal()

  const { getSubscribableControlProps } = useFormComponentPresets({
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NpaSolicitationInclusionFieldsControlUpdateWatcher,
  })

  const headerInfoErrorRenderCondition = !!formInstance.getFieldState('headerInfo')?.error

  const currentControlContainerColor = !headerInfoErrorRenderCondition ? 'primary' : 'negative'
  const currentControlIcon = !headerInfoErrorRenderCondition ? PersonAddIcon : undefined
  const currentIconType = !headerInfoErrorRenderCondition ? '' : 'error'

  const handleOpenModal = () => {
    const isFederalSZPK = formInstance.getValues(
      npaSolicitationInclusionFormBlockValues.additionalFields.isFederal,
    )

    handleOpenAddAuthorizedPersonModal({
      blockViewIsValidating,
      isFederalSZPK,
      formInstance,
      subscribableControl,
      name: 'headerInfo',
      watcher: NpaSolicitationInclusionFieldsControlUpdateWatcher,
      onChangeValue: handleChangeValue,
    })
  }

  return (
    <Container className={'p-0'}>
      <SubscribableControl
        {...getSubscribableControlProps({
          path: npaSolicitationInclusionFormBlockValues.headerInfo.investorHeaderName,
        })}
      >
        <ControlContainer
          color={currentControlContainerColor}
          title={'Укажите уполномоченное лицо организации'}
          additionalMainContent={
            headerInfoErrorRenderCondition && (
              <ControlContainerSubtitle>не заполнены обязательные поля</ControlContainerSubtitle>
            )
          }
          leadingNodeContent={
            <ControlContainerStatusIcon
              controlIcon={currentControlIcon}
              iconType={currentIconType}
            />
          }
          trailingNodeContent={
            <Button
              fixWidth
              view="tined"
              color={headerInfoErrorRenderCondition ? 'negative' : 'default'}
              onClick={handleOpenModal}
            >
              Выбрать
            </Button>
          }
        />
      </SubscribableControl>
    </Container>
  )
}

export default HeaderInfo
