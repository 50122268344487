import { useEffect, useRef } from 'react'

import { useBooleanState } from '@hooks/useBooleanState'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'

const useDebounceLoading = (
  loadingTrigger: boolean,
  dependencies: boolean[],
  fakeThrottling?: number,
) => {
  const currentTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const { booleanState: isLoading, setBooleanState: setLoading } = useBooleanState()

  const debouncedOffLoading = useDebouncedCallback(() => {
    if (fakeThrottling) {
      return (currentTimeoutRef.current = setTimeout(() => {
        setLoading(false)
      }, fakeThrottling))
    }

    setLoading(false)
  }, 500)

  useEffect(() => {
    if (loadingTrigger) {
      setLoading(true)
    } else {
      debouncedOffLoading()
    }

    return () => {
      if (currentTimeoutRef.current) clearTimeout(currentTimeoutRef.current)
    }
  }, dependencies)

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  return { isLoading }
}

export { useDebounceLoading }
