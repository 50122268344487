import { FC } from 'react'

import First from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/Forms/1'
import Second from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/Forms/2'
import Third from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/Forms/3'
import Fourth from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/Forms/4'

interface CertificateApplicationConclusionOfAARequirementsFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const CertificateApplicationConclusionOfAARequirementsForms: CertificateApplicationConclusionOfAARequirementsFormsProps =
  () => null

CertificateApplicationConclusionOfAARequirementsForms.First = First
CertificateApplicationConclusionOfAARequirementsForms.Second = Second
CertificateApplicationConclusionOfAARequirementsForms.Third = Third
CertificateApplicationConclusionOfAARequirementsForms.Fourth = Fourth

export default CertificateApplicationConclusionOfAARequirementsForms
