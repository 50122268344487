import { GetPropertiesData } from '@context/APIContext/hooks/usePropertiesApi'
import { optimizedFlat } from '@helpers/array/optimizedFlat'
import { isArray } from '@helpers/checkTypes'
import { PropertyStateCustom } from '@services/Properties/OOP/PropertyCustom'
import { PropertyStateSimple } from '@services/Properties/OOP/PropertySimple'
import {
  PropertyStateStructure,
  PropertyStateStructureValue,
} from '@services/Properties/OOP/PropertyStructure'
import {
  IChangedPropertyObject,
  IOverridePropsFromClass,
  PropertyTypeEnum,
  TPropertyValue,
  TPropertyValueCustom,
  TPropertyValueSimple,
} from '@services/Properties/Properties.entity'
import {
  PropertiesWatcher,
  SubscriberPropertiesWatcherNotifyProps,
} from '@services/Properties/Properties.watcher'

export interface ApplyChangesReturn {
  objectForAdapter: Record<string, unknown>
  overridePropsFromChanges: IOverridePropsFromClass[]
  lastUpdateDt: string
}

class Property {
  private _parsedProperties: (
    | PropertyStateStructure
    | PropertyStateCustom
    | PropertyStateSimple
  )[] = []

  private _propertiesProps: Record<string, unknown> = {}

  private readonly _propertyWatcher: PropertiesWatcher = new PropertiesWatcher()

  constructor(properties: GetPropertiesData['@properties']) {
    this._parsedProperties = this.convertPropertiesApiInState(properties)
  }

  get getPropertiesProps() {
    return this._propertiesProps
  }

  private convertPropertiesApiInState(properties: GetPropertiesData['@properties']) {
    return Object.entries(properties).map(([propertyKey, propertyValue]) => {
      if (
        propertyValue['@type'] === PropertyTypeEnum.LIST ||
        propertyValue['@type'] === PropertyTypeEnum.MAP
      ) {
        return new PropertyStateStructure(propertyKey, propertyValue, this._propertyWatcher)
      }

      if (
        propertyValue['@type'] === PropertyTypeEnum.MONEY ||
        propertyValue['@type'] === PropertyTypeEnum.ORGANIZATION ||
        propertyValue['@type'] === PropertyTypeEnum.NPA ||
        propertyValue['@type'] === PropertyTypeEnum.NPA_PART ||
        propertyValue['@type'].startsWith(PropertyTypeEnum.CATALOG) ||
        propertyValue['@type'].startsWith(PropertyTypeEnum.LOOKUP) ||
        propertyValue['@type'].startsWith(PropertyTypeEnum.FIXED_LOOKUP)
      ) {
        return new PropertyStateCustom(
          propertyKey,
          propertyValue as TPropertyValueCustom,
          this._propertyWatcher,
        )
      }

      return new PropertyStateSimple(
        propertyKey,
        propertyValue as TPropertyValueSimple,
        this._propertyWatcher,
      )
    })
  }

  private generateArrayOfUpdatesOverrideProps(update: IChangedPropertyObject) {
    const changedUpdates: IOverridePropsFromClass[] = (() => {
      return update.changed
        .map((changedItem) => {
          const localPropertyModel = new Property({ changedUpdates: changedItem as TPropertyValue })

          return localPropertyModel.generateOverrideProps()
        })
        .flat(1)
    })()

    const addedUpdates: IOverridePropsFromClass[] = (() => {
      return update.added
        .map((addedUpdate) => {
          return addedUpdate.elements.map((element) => {
            const localPropertyModel = new Property({
              changedUpdates: element as TPropertyValue,
            })

            return localPropertyModel.generateOverrideProps()
          })
        })
        .flat(2)
    })()

    return [...changedUpdates, ...addedUpdates]
  }

  private generateArrayOfUpdates(update: IChangedPropertyObject) {
    const changedUpdates: SubscriberPropertiesWatcherNotifyProps[] = update.changed.map(
      (changedItem) => {
        const preparedValue = (prevValue) => {
          return {
            ...prevValue,
            '@lastUpdateDt': changedItem['@lastUpdateDt'],
            '@commitedValue': changedItem['@commitedValue'],
            '@isPublic': changedItem['@isPublic'],
            '@value': changedItem['@value'],
            '@order': changedItem['@order'],
            ...((changedItem['@new'] || prevValue['@new']) && {
              '@new': changedItem['@new'] ?? prevValue['@new'],
            }),
          }
        }

        return {
          id: changedItem['@id'],
          value: {
            updateValue: preparedValue,
          },
        }
      },
    )

    const deletedUpdates = update.deleted.map((deletedAction) => {
      const preparedValue: (
        prevValue: PropertyStateStructureValue,
      ) => PropertyStateStructureValue = (prevValue) => {
        if (!isArray(prevValue['@value'])) return prevValue

        return {
          ...prevValue,
          '@deletedElementFromList': !!deletedAction.parentProperty?.['@hasDeletedElements'],
          '@lastUpdateDt': deletedAction.parentProperty['@lastUpdateDt'],
          '@value': prevValue['@value'].filter(
            (item) => item.getPropertyId !== deletedAction.childPropertyId,
          ),
        }
      }

      return {
        id: deletedAction.parentProperty['@id'],
        value: {
          updateValue: preparedValue,
        },
      }
    })

    const addedUpdates = update.added.map((addUpdate) => {
      const preparedValue: (
        prevValue: PropertyStateStructureValue,
      ) => PropertyStateStructureValue = (prevValue) => {
        if (!isArray(prevValue['@value'])) return prevValue

        const preparedElements = addUpdate.elements
          .map((element) => {
            return this.convertPropertiesApiInState({
              ['newValue']: element,
            })
          })
          .flat(2)

        return {
          ...prevValue,
          '@lastUpdateDt': addUpdate.parentProperty['@lastUpdateDt'],
          '@value': [...prevValue['@value'], ...preparedElements],
        }
      }

      return {
        id: addUpdate.parentProperty['@id'],
        value: {
          updateValue: preparedValue,
        },
      }
    })

    return [...deletedUpdates, ...addedUpdates, ...changedUpdates]
  }

  generatePropertiesProps(): Record<string, unknown> {
    const result: Record<string, unknown> = {}

    this._parsedProperties.forEach((property) => {
      const key = property.getPropertyKey
      const type = property.getType

      const value = property.generatePropertiesProps() as Record<string, unknown>

      if (type === PropertyTypeEnum.MAP) {
        result[key] = Object.entries(value).reduce(
          (prevValue: Record<string, unknown>, [propertyKey, propertyValue]: [string, unknown]) => {
            prevValue[propertyKey] = propertyValue
            return prevValue
          },
          {},
        )
      } else {
        result[key] = value
      }
    })

    return result
  }

  generateObjectForAdapterFromPropertiesState(): Record<string, unknown> {
    const result: Record<string, unknown> = {}

    this._parsedProperties.forEach((property) => {
      const key = property.getPropertyKey
      const type = property.getType

      const value = property.generateObjectForAdapterFromPropertiesState() as Record<
        string,
        unknown
      >

      if (type === PropertyTypeEnum.MAP) {
        result[key] = Object.entries(value).reduce(
          (prevValue: Record<string, unknown>, [propertyKey, propertyValue]: [string, unknown]) => {
            prevValue[propertyKey] = propertyValue
            return prevValue
          },
          {},
        )
      } else {
        result[key] = value
      }
    })

    return result
  }

  applyAllChanges(changesFromAPI: IChangedPropertyObject[]): ApplyChangesReturn {
    let lastUpdateDt = ''

    const overridePropsFromChanges: IOverridePropsFromClass[] = []

    changesFromAPI.forEach((change) => {
      const generatedUpdates = this.generateArrayOfUpdates(change)
      const generatedProps = this.generateArrayOfUpdatesOverrideProps(change)

      overridePropsFromChanges.push(...generatedProps)

      lastUpdateDt = change.lastNoticedChangesDt

      this._propertyWatcher.notifyAllSubscribers(generatedUpdates)
    })

    return {
      objectForAdapter: this.generateObjectForAdapterFromPropertiesState(),
      overridePropsFromChanges,
      lastUpdateDt,
    }
  }

  generateOverrideProps(): IOverridePropsFromClass[] {
    return optimizedFlat(
      this._parsedProperties.map((property) => property.getOverrideProps()),
    ).filter(Boolean)
  }
}

export { Property }
