import React, { memo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import StepStage from '@components/Forms/CreateStatement/Forms/Stage/StepOfStage'
import { StepOfStagesPathName } from '@components/Forms/CreateStatement/Forms/Stage/types'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import {
  CreateStatementControlUpdateWatcher,
  CreateStatementFieldArrayControlUpdateWatcher,
} from '@components/Forms/CreateStatement/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { isFormFieldError } = DocumentFormHelpers

const StepProjectArrayForm = () => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()

  const { fields: stages } = useFieldArraySubscribableControl<
    StatementFormValues,
    StepOfStagesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: statementBlockValues.stage.stepOfStages,
    keyName: 'keyNameId',
    watcher: CreateStatementFieldArrayControlUpdateWatcher,
  })

  const handleAppendStage = useCallback(
    async (e) => {
      e.preventDefault()

      await handleAddItemToListWithOutValue?.(statementBlockValues.stage.stepOfStages)

      if (!stages.length) {
        formInstance.clearErrors(statementBlockValues.stage.stepOfStages)
      }
    },
    [formInstance, handleAddItemToListWithOutValue, stages.length],
  )

  const handleRemoveStage = useCallback(
    (index: number) => async () => {
      await handleRemoveItemFromList?.(
        `${statementBlockValues.stage.stepOfStages}.${index}`,
        statementBlockValues.stage.stepOfStages,
      )
    },
    [handleRemoveItemFromList],
  )

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CreateStatementControlUpdateWatcher,
  })
  if (!formInstance) return null

  const stagesError = formInstance.getFieldState(statementBlockValues.stage.stepOfStages)?.error

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          <Stack direction={'vertical'} gap={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementBlockValues.stage.stepOfStages,
              })}
            >
              <FlipperList list={stages}>
                {stages.map((stage, index) => {
                  const formName = `${statementBlockValues.stage.stepOfStages}.${index}` as const

                  return (
                    <StepStage
                      key={stage.id}
                      id={stage.id}
                      name={formName}
                      onRemoveStage={handleRemoveStage(index)}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Stack>
        </Col>
        {isFormFieldError(stagesError) && !stages.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
      </Row>

      {editMode && (
        <Row>
          <AsyncWrapper promise={handleAppendStage}>
            {({ isLoading, wrappedPromise }) => {
              return (
                <FieldView.ActionButton
                  disableWrapperPaddingLeft
                  wrapperClassName={'px-0'}
                  dataTestId="StepProjectArrayForm-add-button"
                  disabled={isLoading}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  onClick={wrappedPromise}
                >
                  Добавить этап
                </FieldView.ActionButton>
              )
            }}
          </AsyncWrapper>
        </Row>
      )}
    </Stack>
  )
}

export default memo(StepProjectArrayForm)
