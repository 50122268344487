import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForBeneficiaryForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedBeneficiars = (() => {
    const preparedBeneficiarsValue = (() => {
      if (
        !isArray(objectFromPropertyState.beneficiars?.value) ||
        !objectFromPropertyState.beneficiars?.value.length
      )
        return []

      return objectFromPropertyState.beneficiars.value.map((beneficiar) => {
        const preparedBeneficiarSecondNationalityCountries =
          isArray(beneficiar.value?.beneficiarSecondNationalityCountries?.value) &&
          beneficiar.value?.beneficiarSecondNationalityCountries?.value.length
            ? beneficiar.value?.beneficiarSecondNationalityCountries?.value.map((country) => ({
                propertyId: country.propertyId,
                lastUpdateDt: country.lastUpdateDt,
                type: country.type,
                value: {
                  beneficiarSecondNationalityCountry: {
                    propertyId: country.propertyId,
                    lastUpdateDt: country.lastUpdateDt,
                    type: country.type,
                  },
                },
              }))
            : []

        return {
          propertyId: beneficiar.propertyId,
          type: beneficiar.type,
          lastUpdateDt: beneficiar.lastUpdateDt,
          value: {
            beneficiarName: {
              propertyId: beneficiar.value.beneficiarName?.propertyId,
              type: beneficiar.value.beneficiarName?.type,
              lastUpdateDt: beneficiar.value.beneficiarName?.lastUpdateDt,
            },
            beneficiarBirthPlace: {
              propertyId: beneficiar.value.beneficiarBirthPlace?.propertyId,
              type: beneficiar.value.beneficiarBirthPlace?.type,
              lastUpdateDt: beneficiar.value.beneficiarBirthPlace?.lastUpdateDt,
            },
            beneficiarBirthDate: {
              propertyId: beneficiar.value.beneficiarBirthDate?.propertyId,
              type: beneficiar.value.beneficiarBirthDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarBirthDate?.lastUpdateDt,
            },
            beneficiarNationality: {
              propertyId: beneficiar.value.beneficiarNationality?.propertyId,
              type: beneficiar.value.beneficiarNationality?.type,
              lastUpdateDt: beneficiar.value.beneficiarNationality?.lastUpdateDt,
            },
            otherNationality: {
              propertyId: beneficiar.value.otherNationality?.propertyId,
              type: beneficiar.value.otherNationality?.type,
              lastUpdateDt: beneficiar.value.otherNationality?.lastUpdateDt,
            },
            beneficiarNationalityCountry: {
              propertyId: beneficiar.value.beneficiarNationalityCountry?.propertyId,
              type: beneficiar.value.beneficiarNationalityCountry?.type,
              lastUpdateDt: beneficiar.value.beneficiarNationalityCountry?.lastUpdateDt,
            },
            beneficiarSecondNationalityCountries: {
              propertyId: beneficiar.value.beneficiarSecondNationalityCountries?.propertyId,
              type: beneficiar.value.beneficiarSecondNationalityCountries?.type,
              lastUpdateDt: beneficiar.value.beneficiarSecondNationalityCountries?.lastUpdateDt,
              value: preparedBeneficiarSecondNationalityCountries,
            },
            beneficiarInn: {
              propertyId: beneficiar.value.beneficiarInn?.propertyId,
              type: beneficiar.value.beneficiarInn?.type,
              lastUpdateDt: beneficiar.value.beneficiarInn?.lastUpdateDt,
            },
            beneficiarDocument: {
              propertyId: beneficiar.value.beneficiarDocument?.propertyId,
              type: beneficiar.value.beneficiarDocument?.type,
              lastUpdateDt: beneficiar.value.beneficiarDocument?.lastUpdateDt,
            },
            beneficiarDocumentSeries: {
              propertyId: beneficiar.value.beneficiarDocumentSeries?.propertyId,
              type: beneficiar.value.beneficiarDocumentSeries?.type,
              lastUpdateDt: beneficiar.value.beneficiarDocumentSeries?.lastUpdateDt,
            },
            beneficiarDocumentNumber: {
              propertyId: beneficiar.value.beneficiarDocumentNumber?.propertyId,
              type: beneficiar.value.beneficiarDocumentNumber?.type,
              lastUpdateDt: beneficiar.value.beneficiarDocumentNumber?.lastUpdateDt,
            },
            beneficiarDocumentDate: {
              propertyId: beneficiar.value.beneficiarDocumentDate?.propertyId,
              type: beneficiar.value.beneficiarDocumentDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarDocumentDate?.lastUpdateDt,
            },
            beneficiarDocumentOrganization: {
              propertyId: beneficiar.value.beneficiarDocumentOrganization?.propertyId,
              type: beneficiar.value.beneficiarDocumentOrganization?.type,
              lastUpdateDt: beneficiar.value.beneficiarDocumentOrganization?.lastUpdateDt,
            },
            beneficiarDocumentCodeOrganization: {
              propertyId: beneficiar.value.beneficiarDocumentCodeOrganization?.propertyId,
              type: beneficiar.value.beneficiarDocumentCodeOrganization?.type,
              lastUpdateDt: beneficiar.value.beneficiarDocumentCodeOrganization?.lastUpdateDt,
            },
            beneficiarAddress: {
              propertyId: beneficiar.value.beneficiarAddress?.propertyId,
              type: beneficiar.value.beneficiarAddress?.type,
              lastUpdateDt: beneficiar.value.beneficiarAddress?.lastUpdateDt,
            },
            beneficiarMigrationNumber: {
              propertyId: beneficiar.value.beneficiarMigrationNumber?.propertyId,
              type: beneficiar.value.beneficiarMigrationNumber?.type,
              lastUpdateDt: beneficiar.value.beneficiarMigrationNumber?.lastUpdateDt,
            },
            beneficiarMigrationStartDate: {
              propertyId: beneficiar.value.beneficiarMigrationStartDate?.propertyId,
              type: beneficiar.value.beneficiarMigrationStartDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarMigrationStartDate?.lastUpdateDt,
            },
            beneficiarMigrationEndDate: {
              propertyId: beneficiar.value.beneficiarMigrationEndDate?.propertyId,
              type: beneficiar.value.beneficiarMigrationEndDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarMigrationEndDate?.lastUpdateDt,
            },
            beneficiarResidenceNumber: {
              propertyId: beneficiar.value.beneficiarResidenceNumber?.propertyId,
              type: beneficiar.value.beneficiarResidenceNumber?.type,
              lastUpdateDt: beneficiar.value.beneficiarResidenceNumber?.lastUpdateDt,
            },
            beneficiarResidenceStartDate: {
              propertyId: beneficiar.value.beneficiarResidenceStartDate?.propertyId,
              type: beneficiar.value.beneficiarMigrationStartDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarResidenceStartDate?.lastUpdateDt,
            },
            beneficiarResidenceEndDate: {
              propertyId: beneficiar.value.beneficiarResidenceEndDate?.propertyId,
              type: beneficiar.value.beneficiarResidenceEndDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarResidenceEndDate?.lastUpdateDt,
            },
            beneficiarPart: {
              propertyId: beneficiar.value.beneficiarPart?.propertyId,
              type: beneficiar.value.beneficiarPart?.type,
              lastUpdateDt: beneficiar.value.beneficiarPart?.lastUpdateDt,
            },
            beneficiarReason: {
              propertyId: beneficiar.value.beneficiarReason?.propertyId,
              type: beneficiar.value.beneficiarReason?.type,
              lastUpdateDt: beneficiar.value.beneficiarReason?.lastUpdateDt,
            },
          },
        }
      })
    })()

    return {
      propertyId: objectFromPropertyState.beneficiars?.propertyId,
      type: objectFromPropertyState.beneficiars?.type,
      lastUpdateDt: objectFromPropertyState.beneficiars?.lastUpdateDt,
      value: preparedBeneficiarsValue,
    }
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      beneficiars: preparedBeneficiars,
    },
  }
}

export { generatePropertiesObjectForBeneficiaryForm }
