import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { useParams } from 'react-router'

import {
  getDfoByType,
  getOrganizationTypeMembers,
  getProjectAttributeByName,
} from '@components/Projects/[id]/helpers'
import { fromOrganizationInfoToProjectMember } from '@components/Projects/[id]/ParticipantsOfStatement/helpers'
import { useAddMemberModalInfo } from '@components/Projects/[id]/ParticipantsOfStatement/hooks'
import { useExtraMembersFromAttributes } from '@components/Projects/[id]/ParticipantsOfStatement/hooks/useExtraMembersFromAttributes'
import { useMembersFromAttributes } from '@components/Projects/[id]/ParticipantsOfStatement/hooks/useMembersFromAttributes'
import { IMunicipalityMembers } from '@components/Projects/[id]/ParticipantsOfStatement/hooks/useMunicipalityMembers'
import { DfosStage, NewDfosType, RolesTypes } from '@constants/types'
import { isBoolean, isNullOrUndefined } from '@helpers/checkTypes'
import { useProject } from '@hooks/new/swr/useProject'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useProjectMembers } from '@hooks/new/swr/useProjectMembers'
import { useVersions } from '@hooks/new/swr/useVersions'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { IProjectMember } from '@services/Members/Members.entity'

interface ViewParticipantsContextProps {
  OIVMembers: IProjectMember[]

  MERMembers: IProjectMember[]

  OMSUMembers: IProjectMember[]

  OIVExtraViewMembers: IProjectMember[]

  isFederal: boolean

  investorInfo: IProjectMember

  isLoadingProjectMembers?: boolean

  isLoadingProjectExtraMembers?: boolean
}

interface EditParticipantsContextProps {
  subjects: string[]
  subjectsExtra: string[]
  municipalityMembers: IMunicipalityMembers

  postedAttributes: string[]

  isFederal: boolean
  isRFInvolved?: boolean
  isLoadingMunicipalityMembers?: boolean
}

interface ParticipantsContextProps {
  editParticipantsState: EditParticipantsContextProps
  viewParticipantsState: ViewParticipantsContextProps
}

type GetViewMembersProps = 'OMSU' | 'OIV'

type GetViewExtraMembersProps = 'OIV_EXTRA'

const ParticipantsContext = createContext<ParticipantsContextProps>({
  editParticipantsState: {
    subjects: [],
    subjectsExtra: [],
    municipalityMembers: {
      ids: [],
      defaultOptionsSelected: [],
    },
    isFederal: false,
    postedAttributes: [],
  },
  viewParticipantsState: {
    investorInfo: {
      name: '',
      fullName: '',
      type: '',
      organizationId: '',
    },
    MERMembers: [],
    OIVMembers: [],
    OMSUMembers: [],
    OIVExtraViewMembers: [],
    isFederal: false,
  },
})

const useParticipantsManager = () => {
  return useContext(ParticipantsContext)
}

const ParticipantsOfStatementManager = ({ children }) => {
  const { projectId } = useParams()

  const {
    municipalityMembers: { municipalityMembersIsLoading, ...municipalityMembers },
    postedAttributes,
    projectAttributes,
    subjectsToEdit,
    subjectsExtraToEdit,
  } = useAddMemberModalInfo(projectId || '')

  const { projectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ParticipantsOfStatementManager',
          moduleName: 'ProjectStructure',
          componentType: 'manager',
        },
      }),
    },
  })

  const SZPKDfoInfo = useMemo(() => {
    if (!projectDfos) return

    return getDfoByType(projectDfos, NewDfosType.SZPK) as IDfoListItem
  }, [projectDfos])

  const { versionNumber } = useVersions({
    key: { dfoId: SZPKDfoInfo?.id, projectId, _key: 'versions' },
    config: {
      isPaused: () => !projectId || !SZPKDfoInfo?.id,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ParticipantsOfStatementManager',
          moduleName: 'ProjectStructure',
          componentType: 'manager',
        },
      }),
    },
  })

  const isNotFirstVersionSZPKDfo = versionNumber && versionNumber > 1

  // В этом случае участники берутся из хука useMembers
  const membersFromAttributesRenderCondition =
    isNotFirstVersionSZPKDfo && SZPKDfoInfo?.stage === DfosStage.DRAFT

  const { project } = useProject({ key: { projectId, _key: 'project' } })
  //Отсюда берутся проекты, если версия СЗПК равна единице или проект находится в драфте
  const { projectMembers, isLoadingProjectMembers } = useProjectMembers({
    key: {
      projectId,
      _key: 'projectMembers',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ParticipantsOfStatementManager',
          moduleName: 'ProjectStructure',
          componentType: 'manager',
        },
      }),
    },
  })

  const { membersFromAttributes, membersFromAttributesIsLoading } = useMembersFromAttributes(
    projectId,
    isNullOrUndefined(versionNumber) || !isNotFirstVersionSZPKDfo,
  )

  const { extraMembersFromAttributes, extraMembersFromAttributesIsLoading } =
    useExtraMembersFromAttributes(projectId, !!project?.isFederal)

  //Все 3 свойства относятся к участникам, когда редактирование уже невозможно
  const investorViewInfo = useMemo(() => {
    if (!projectMembers) return []

    return getOrganizationTypeMembers(RolesTypes.INVESTOR, projectMembers)
  }, [projectMembers])

  const getViewMembersByRole = useCallback(
    (role: GetViewMembersProps) => {
      if (membersFromAttributesRenderCondition && Object.keys(membersFromAttributes).length)
        return (
          membersFromAttributes[role]?.map((member) =>
            fromOrganizationInfoToProjectMember(member),
          ) || []
        )

      if (projectMembers) {
        return getOrganizationTypeMembers(role, projectMembers)
      }

      return []
    },
    [membersFromAttributes, membersFromAttributesRenderCondition, projectMembers],
  )

  const getViewExtraMembersByRole = useCallback(
    (role: GetViewExtraMembersProps) => {
      return (
        extraMembersFromAttributes[role]?.map((extraMember) =>
          fromOrganizationInfoToProjectMember(extraMember),
        ) || []
      )
    },
    [extraMembersFromAttributes],
  )

  const MERViewMembers = useMemo(() => {
    if (!projectMembers) return []

    return getOrganizationTypeMembers(RolesTypes.MER, projectMembers)
  }, [projectMembers])

  const OIVViewMembers = useMemo(() => {
    return getViewMembersByRole(RolesTypes.OIV)
  }, [getViewMembersByRole])

  const OMSUViewMembers = useMemo(() => {
    return getViewMembersByRole(RolesTypes.OMSU)
  }, [getViewMembersByRole])

  const OIVExtraViewMembers = useMemo(() => {
    return getViewExtraMembersByRole(RolesTypes.OIV_EXTRA)
  }, [getViewExtraMembersByRole])

  //Ниже свойства, когда участников проекта можно отредактировать
  const isRfEditInvolved = useMemo(() => {
    if (!projectAttributes) return

    const valueToReturn = getProjectAttributeByName('isRfInvolved', projectAttributes)[0]?.value

    const returnCondition = isBoolean(valueToReturn)

    return returnCondition ? valueToReturn : false
  }, [projectAttributes])

  const providerValue = useMemo(
    () => ({
      editParticipantsState: {
        isFederal: !!project?.isFederal,
        isRFInvolved: isRfEditInvolved,
        subjects: subjectsToEdit,
        subjectsExtra: subjectsExtraToEdit,
        municipalityMembers,
        postedAttributes,
        isLoadingMunicipalityMembers: isLoadingProjectMembers || municipalityMembersIsLoading,
      },
      viewParticipantsState: {
        investorInfo: investorViewInfo[0],
        OIVMembers: OIVViewMembers,
        MERMembers: MERViewMembers,
        OMSUMembers: OMSUViewMembers,
        OIVExtraViewMembers: OIVExtraViewMembers,
        isFederal: !!project?.isFederal,
        isLoadingProjectMembers: isLoadingProjectMembers || membersFromAttributesIsLoading,
        isLoadingProjectExtraMembers: extraMembersFromAttributesIsLoading,
      },
    }),
    [
      isLoadingProjectMembers,
      municipalityMembersIsLoading,
      membersFromAttributesIsLoading,
      extraMembersFromAttributesIsLoading,
      subjectsToEdit,
      subjectsExtraToEdit,
      postedAttributes,
      investorViewInfo,
      OIVViewMembers,
      MERViewMembers,
      OMSUViewMembers,
      OIVExtraViewMembers,
      municipalityMembers,
      project?.isFederal,
      isRfEditInvolved,
    ],
  )

  return (
    <ParticipantsContext.Provider value={providerValue}>{children}</ParticipantsContext.Provider>
  )
}

export type { ParticipantsContextProps }
export { ParticipantsContext, useParticipantsManager }
export default ParticipantsOfStatementManager
