import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Alert from '@components/Alert'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import { useSuccessModal } from '@components/NewDesignedModals/SuccessModal/manager'
import { defaultMessage, successModalBodyTexts, successModalHeaderTexts } from '@constants/texts'
import { defaultRHFValidation } from '@constants/validations'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { isAxiosError } from '@helpers/checkTypes'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useOrganizationUsers } from '@hooks/new/swr/useOrganizationUsers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import storage from 'store2'

import styles from './ChangeUserEmailModal.module.scss'

interface FormValueProps {
  email: string
}

interface ChangeUserEmailModalProps {
  userInfo: {
    id: string
    firstName: string
    lastName: string
    profile: string
  }
  initialEmail: string
}

type ChangeUserEmailVerifiedProps = PopupProps & ChangeUserEmailModalProps

const useChangeUserEmailModal = () => {
  const popupManager = usePopupManager()

  const handleOpenChangeUserEmail = (props: ChangeUserEmailModalProps) => {
    popupManager.open(ChangeUserEmailModal, props)
  }

  return {
    handleOpenChangeUserEmail,
  }
}

const ChangeUserEmailModal: FC<ChangeUserEmailVerifiedProps> = ({
  userInfo,
  initialEmail,
  isOpen,
  onClose,
}) => {
  const {
    organizationApi: { editUser, askEmailApproving },
  } = useAPIContext()

  const popupManager = usePopupManager()

  const { handleOpenLoadingModal } = useLoadingModal()
  const { handleOpenSuccessModal } = useSuccessModal()

  const { mutate: revalidateAllUsers } = useOrganizationUsers({
    key: {
      _key: 'organizationUsers',
    },
  })

  const { refreshToken } = useAuthContext()

  const emailRef = useRef<HTMLInputElement | null>(null)
  const [emailIsMutated, setEmailIsMutated] = useState<boolean>(false)

  const {
    control,
    getValues,
    watch,
    formState: { isValid },
  } = useForm<FormValueProps>({
    defaultValues: {
      email: initialEmail,
    },
    mode: 'onChange',
  })

  const currentEmailValue = watch('email')

  useUpdateEffect(() => {
    setEmailIsMutated(currentEmailValue !== initialEmail)
  }, [currentEmailValue])

  const handleTryApproveEmail = useCallback(
    async (onSuccess: () => void) => {
      try {
        await askEmailApproving()
        onSuccess()
      } catch {}
    },
    [askEmailApproving],
  )

  const handleEmailApprove = useCallback(async () => {
    try {
      await handleTryApproveEmail(() =>
        handleOpenSuccessModal({
          text: `${successModalBodyTexts.checkEmail} ${currentEmailValue}`,
          headerText: successModalHeaderTexts.checkEmail,
        }),
      )
    } catch {}
  }, [currentEmailValue, handleOpenSuccessModal, handleTryApproveEmail])

  const handleChangeEmail = useCallback(async () => {
    const emailValue = getValues().email

    handleOpenLoadingModal()

    try {
      await editUser(userInfo.id, {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: emailValue,
        profile: userInfo.profile,
      })

      popupManager.closeAll()

      try {
        await handleEmailApprove()
      } catch {}

      try {
        await revalidateAllUsers()
        // Обновляем токен, так как в нем приходит признак подтвержденной почты
        await refreshToken?.({
          refresh_token: storage.get('auth_state')?.refreshToken,
        })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'ChangeUserEmailModal',
            componentType: 'handleChangeEmail',
          },
        })(error)
      }
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ChangeUserEmailModal',
          componentType: 'handleChangeEmail',
        },
      })(error)

      popupManager.closeAll()

      if (!isAxiosError(error) || !error.response) {
        return toast(
          <Alert transparent variant="error">
            {defaultMessage}
          </Alert>,
        )
      }
    }
  }, [
    editUser,
    getValues,
    handleEmailApprove,
    handleOpenLoadingModal,
    popupManager,
    refreshToken,
    revalidateAllUsers,
    userInfo.firstName,
    userInfo.id,
    userInfo.lastName,
    userInfo.profile,
  ])

  const preparedOnClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  const changeEmailActions = useMemo(() => {
    const emailCondition = !watch('email')

    return [
      {
        view: 'gray',
        dataTestId: 'ChangeUserEmailModal-cancel-button',
        children: 'Отмена',
        onClick: preparedOnClose,
      },
      {
        dataTestId: 'ChangeUserEmailModal-changeEmail-button',
        children: 'Изменить',
        onClick: handleChangeEmail,
        disabled: emailCondition || !isValid || !emailIsMutated,
      },
    ] as ButtonProps[]
  }, [emailIsMutated, handleChangeEmail, isValid, preparedOnClose, watch])

  return (
    <Modal.Action
      isOpen={isOpen}
      title={'Изменить почту'}
      actions={changeEmailActions}
      dataTestId="ChangeUserEmailModal-modal"
      closeButtonDataTestId="ChangeUserEmailModal-modal-closeButton"
      simpleModalContainerClassName={styles.changeUserEmail}
      simpleModalBodyClassName={styles.changeUserEmail__body}
      initialFocus={emailRef}
      onClose={preparedOnClose}
    >
      <Typography.Body variant={'bodyMRegular'}>
        Ссылку для подтверждения отправим на почту, чтобы Вы продолжали получать уведомления о ходе
        рассмотрения соглашения
      </Typography.Body>

      <div className={styles.changeUserEmail__input}>
        <ControlledInput
          control={control}
          name={'email'}
          rules={{
            required: defaultRHFValidation.required,
            pattern: defaultRHFValidation.email,
          }}
          inputProps={{
            view: 'secondary',
            fixWidth: true,
            size: 'xl',
            label: 'Электронная почта для связи',
            ref: emailRef,
            dataTestId: 'ChangeUserEmailModal-email-input',
          }}
        />
      </div>
    </Modal.Action>
  )
}

export { useChangeUserEmailModal }
export default memo(ChangeUserEmailModal)
