import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import { CheckboxDefaultPayload } from '@components/Checkbox/types'
import styles from '@components/DocumentFormComponents/FormStyles/MainTemplate.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { getFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import FormController from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/FormController'
import { SeventhEventsOfStagesArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

interface MainTemplateProps {
  parentId: string
  name: SeventhEventsOfStagesArrayPathName
  control: Control<ProjectSZPKFormValues>
  isConstructionRender: boolean
  isRidRender: boolean
  indexOfMainBlock: number
}

const { transformRHFPathInProperties } = DocumentFormHelpers

const MainTemplate: FC<MainTemplateProps> = ({
  parentId,
  control,
  name,
  isConstructionRender,
  isRidRender,
  indexOfMainBlock,
}) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: {
      handleAddCustomValue,
      handleRemoveCustomValue,
      getPropertiesProps,
      getOverrideProps,
    },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getCheckboxProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  //Обработчик для фейкового поля
  useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    `${SeventhEventsOfStagesArrayPathName}.stepActivityNames`
  >({
    name: `${name}.stepActivityNames`,
    control: formInstance.control,
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const stepActivityNames = useWatch({
    name: `${name}.stepActivityNames`,
  })

  const handleCheckEventType = async (
    eventPath: string,
    eventName: string,
    payload?: CheckboxDefaultPayload,
  ) => {
    const propertyStepActivityNames = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(eventPath),
    )

    const allControlProps = getOverrideProps?.()[propertyStepActivityNames?.propertyId]

    const overrideProps = getFetcherProps(allControlProps)
    if (!overrideProps) return null

    if (!propertyStepActivityNames || !overrideProps.length) return

    const idOfAddProperty = overrideProps.find((item) => item.name === eventName)?.id

    const propertyInfo = {
      id: propertyStepActivityNames.propertyId,
      lastUpdateDt: propertyStepActivityNames.lastUpdateDt,
    }

    if (payload?.checked) {
      return await handleAddCustomValue?.(propertyInfo, {
        id: idOfAddProperty,
      })
    }

    const idToRemove = stepActivityNames?.find((item) => item.value.name === eventName).propertyId

    if (!idToRemove) return
    return await handleRemoveCustomValue?.(propertyInfo, idToRemove)
  }

  return (
    <Container className={styles.mainTemplate}>
      <Col xs={12} className={styles.mainTemplate__container}>
        <AsyncWrapper promise={handleCheckEventType}>
          {({ isLoading: isEventTypeChecking, wrappedPromise: wrappedHandleCheckEventType }) => (
            <Stack direction={'vertical'} gap={3} className={styles.mainTemplate__stack}>
              <Row>
                <Col xs={12}>
                  <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
                    Тип мероприятия
                  </Typography.Body>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.isConstruction`,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${name}.isConstruction`,
                        checkBoxProps: {
                          label: 'Строительство',
                          disabled: isEventTypeChecking || !editMode,
                        },
                        onChange: async (e, payload) =>
                          wrappedHandleCheckEventType(
                            `${name}.isConstruction`,
                            'Строительство (создание)',
                            payload,
                          ),
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={4}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.isReconstruction`,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${name}.isReconstruction`,
                        checkBoxProps: {
                          label: 'Реконструкция',
                          disabled: isEventTypeChecking || !editMode,
                        },
                        onChange: async (e, payload) =>
                          wrappedHandleCheckEventType(
                            `${name}.isReconstruction`,
                            'Реконструкция',
                            payload,
                          ),
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={4}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.isModernization`,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${name}.isModernization`,
                        checkBoxProps: {
                          label: 'Модернизация',
                          disabled: isEventTypeChecking || !editMode,
                        },
                        onChange: async (e, payload) =>
                          wrappedHandleCheckEventType(
                            `${name}.isModernization`,
                            'Модернизация',
                            payload,
                          ),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.isCreateRID`,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${name}.isCreateRID`,
                        checkBoxProps: {
                          label: 'Создание РИД',
                          disabled: isEventTypeChecking || !editMode,
                        },
                        onChange: async (e, payload) =>
                          handleCheckEventType(`${name}.isCreateRID`, 'Создание РИД', payload),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </Stack>
          )}
        </AsyncWrapper>
      </Col>
      <Row>
        <Col xs={12}>
          <FormController
            parentId={parentId}
            indexOfMainBlock={indexOfMainBlock}
            isConstructionRender={isConstructionRender}
            isRidRender={isRidRender}
            name={name}
            control={control}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default MainTemplate
