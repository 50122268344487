import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu, NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import cloneDeep from 'clone-deep'

const aAgreementOnChangingCharacteristicsOfObjectsMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Уполномоченный федеральный орган исполнительной власти',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'Уполномоченный орган субъекта Российской Федерации',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '3': {
    id: '3',
    path: '3',
    title: 'Муниципальное образование',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '4': {
    id: '4',
    path: '4',
    title: 'Организация, реализующая проект',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '5': {
    id: '5',
    path: '5',
    title: 'Этапы',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
}

const getAAgreementOnChangingCharacteristicsOfObjectsMenuByStatus = (
  isFederal: boolean,
  isMunicipalitiesRender: boolean,
) => {
  const initialAAgreementOnCreationOtherEstateObjectsMenuHashMap = cloneDeep(
    aAgreementOnChangingCharacteristicsOfObjectsMenuHashMap,
  )

  const aAgreementOnCreationOtherEstateObjectsMenuState = [
    aAgreementOnChangingCharacteristicsOfObjectsMenuHashMap['1'],
    aAgreementOnChangingCharacteristicsOfObjectsMenuHashMap['2'],
    aAgreementOnChangingCharacteristicsOfObjectsMenuHashMap['3'],
    aAgreementOnChangingCharacteristicsOfObjectsMenuHashMap['4'],
    aAgreementOnChangingCharacteristicsOfObjectsMenuHashMap['5'],
  ]

  const initialAAgreementOnCreationOtherEstateObjectsMenuState =
    aAgreementOnCreationOtherEstateObjectsMenuState.reduce<NestedMapOfMenu[]>(
      (previousValue, currentValue) => {
        if (!isFederal && currentValue.id === '1') return previousValue

        if (!isMunicipalitiesRender && currentValue.id === '3') return previousValue

        return [...previousValue, currentValue]
      },
      [],
    )

  const mapOfMenu = initialAAgreementOnCreationOtherEstateObjectsMenuState.map(
    (menu): MapOfMenu => ({
      id: menu.id,
      title: menu.title,
    }),
  )

  const {
    ['1']: firstSection,
    ['3']: thirdSection,
    ...changedMenuHashMap
  } = initialAAgreementOnCreationOtherEstateObjectsMenuHashMap

  return {
    initialAAgreementOnCreationOtherEstateObjectsMenuHashMap: {
      ...changedMenuHashMap,
      ...(isFederal && {
        '1': firstSection,
      }),
      ...(isMunicipalitiesRender && {
        '3': thirdSection,
      }),
    },
    initialAAgreementOnCreationOtherEstateObjectsMenuState,
    mapOfMenu,
  }
}

export { getAAgreementOnChangingCharacteristicsOfObjectsMenuByStatus }
