import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'
import Sixth from './6'
import Seventh from './7'
import Eighth from './8'
import Ninth from './9'
import Tenth from './10'
import Eleventh from './11'
import Twelfth from './12'

interface CertificateOnMonitoringResultsFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
  Sixth: typeof Sixth
  Seventh: typeof Seventh
  Eighth: typeof Eighth
  Ninth: typeof Ninth
  Tenth: typeof Tenth
  Eleventh: typeof Eleventh
  Twelfth: typeof Twelfth
}

const CertificateOnMonitoringResultsForms: CertificateOnMonitoringResultsFormsProps = () => null

CertificateOnMonitoringResultsForms.First = First
CertificateOnMonitoringResultsForms.Second = Second
CertificateOnMonitoringResultsForms.Third = Third
CertificateOnMonitoringResultsForms.Fourth = Fourth
CertificateOnMonitoringResultsForms.Fifth = Fifth
CertificateOnMonitoringResultsForms.Sixth = Sixth
CertificateOnMonitoringResultsForms.Seventh = Seventh
CertificateOnMonitoringResultsForms.Eighth = Eighth
CertificateOnMonitoringResultsForms.Ninth = Ninth
CertificateOnMonitoringResultsForms.Tenth = Tenth
CertificateOnMonitoringResultsForms.Eleventh = Eleventh
CertificateOnMonitoringResultsForms.Twelfth = Twelfth

export default CertificateOnMonitoringResultsForms
