import { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NoticeOfProjectRejectConcludeSZPK from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm'
import {
  defaultNoticeOfProjectRejectConcludeSZPKFormValues,
  mapOfNoticeOfProjectRejectConcludeSZPKMenu,
} from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/const'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'
import { NoticeOfProjectRejectConcludeSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NoticeOfProjectRejectConcludeSZPKLayoutWrapper from './Wrapper'

const NoticeOfProjectRejectConcludeSZPKLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NoticeOfProjectRejectConcludeSZPKFormValues>({
    defaultValues: defaultNoticeOfProjectRejectConcludeSZPKFormValues,
  })

  const handleOnClose = () => {
    NoticeOfProjectRejectConcludeSZPKFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NoticeOfProjectRejectConcludeSZPK.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfNoticeOfProjectRejectConcludeSZPKMenu[0].id}
          >
            <NoticeOfProjectRejectConcludeSZPKLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об отказе в заключении СЗПК
                </Typography.Headline>
                <NoticeOfProjectRejectConcludeSZPK.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}
              >
                <NoticeOfProjectRejectConcludeSZPK.Menu className={styles.layout__menu} />
              </div>
            </NoticeOfProjectRejectConcludeSZPKLayoutWrapper>
          </FlatMenuManager>
        </NoticeOfProjectRejectConcludeSZPK.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NoticeOfProjectRejectConcludeSZPKLayout)
