import { FC, memo, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import AAVolumeOfCapitalInvestments from '@components/Forms/AAVolumeOfCapitalInvestmentsForm'
import { aaVolumeOfCapitalInvestmentsBlockValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/const'
import { getAAVolumeOfCapitalInvestmentsMenuByStatus } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Menu/const'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'
import Typography from '@components/NewDesign/Typography'

import AAVolumeOfCapitalInvestmentsLayoutWrapper from './Wrapper'

const AAVolumeOfCapitalInvestmentsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormContext<AAVolumeOfCapitalInvestmentsFormValues>()

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const isFederalSZPK = useWatch({
    name: aaVolumeOfCapitalInvestmentsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })
  const isMunicipalitiesRender = !!useWatch({
    name: aaVolumeOfCapitalInvestmentsBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const initialMenuState = useMemo(() => {
    return getAAVolumeOfCapitalInvestmentsMenuByStatus(isFederalSZPK, isMunicipalitiesRender)
  }, [isFederalSZPK, isMunicipalitiesRender])

  const handleOnClose = useCallback(() => {
    AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <AAVolumeOfCapitalInvestments.MenuManager
        key={String(isFederalSZPK)}
        initialMenuHash={initialMenuState.initialAAVolumeOfCapitalInvestmentsMenuState}
        initialSectionId={initialMenuState.initialAAVolumeOfCapitalInvestmentsMenuState[0].id}
      >
        <AAVolumeOfCapitalInvestments.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
          onClose={handleOnClose}
        >
          <AAVolumeOfCapitalInvestmentsLayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Дополнительное соглашение о внесении изменений в объем капиталовложений
              </Typography.Headline>
              <AAVolumeOfCapitalInvestments.StatementForm
                className={styles.layout__form}
                initialMenu={initialMenuState.mapOfMenu}
              />
            </div>
            <div className={styles['layout__menu-wrapper']}>
              <AAVolumeOfCapitalInvestments.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </AAVolumeOfCapitalInvestmentsLayoutWrapper>
        </AAVolumeOfCapitalInvestments.Manager>
      </AAVolumeOfCapitalInvestments.MenuManager>
    </DocumentFormLayout>
  )
}

export default memo(AAVolumeOfCapitalInvestmentsLayout)
