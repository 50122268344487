import { useCallback, useRef } from 'react'

import { Property } from '@services/Properties/OOP/Property'

const useFormPropertyRefs = () => {
  const lastFieldUpdateTime = useRef<string>()
  const propertyInstanceRef = useRef<Property | null>(null)

  const propertiesPropsRef = useRef<Record<string, any> | null>(null)

  const getPropertiesProps = useCallback(() => propertiesPropsRef.current, [])

  const getLastFieldUpdateTime = useCallback(() => lastFieldUpdateTime.current, [])

  const getPropertyInstance = useCallback(() => propertyInstanceRef.current, [])

  return {
    lastFieldUpdateTime,
    propertyInstanceRef,
    propertiesPropsRef,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  }
}

export { useFormPropertyRefs }
