import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { isNotEmptyString } from '@helpers/checkTypes'

const menuAnchorConverterMap = {
  '1.1': '1',
  '1.2': '2',
  '1.3': '3',
  '2.1': '4',
  '2.2': '5',
  '3.1': '6',
  '3.2': '7',
  '3.3': '8',
  '3.4': '9',
  '3.5': '10',
  '4.1': '11',
  '4.2': '12',
  '4.3': '13',
  '5.1': '14',
  '5.2': '15',
  '5.3': '16',
}

const generateMenuListForProjectSZPK = (
  formValues: ProjectSZPKFormValues | UnpackNestedValue<ProjectSZPKFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<ProjectSZPKFormValues>,
): NestedMapOfMenu[] => {
  const eventOfStagesAnchorId = '3.2'
  const stepActivityOthersAnchorId = '3.3'

  const getMenuSectionWithError = (menuSection: NestedMapOfMenu) => {
    const preparedChildrenSections = menuSection.children.map((item) => ({
      ...item,
      hasError: !!formErrors?.[menuAnchorConverterMap[item.id]],
    }))

    return {
      ...menuSection,
      hasError: preparedChildrenSections.some((item) => item.hasError),
      children: preparedChildrenSections,
    }
  }

  const getPreparedEventOfStagesMenu = (item: NestedMapOfMenu): NestedMapOfMenu => {
    if (!formValues['7'].eventsOfStages || !formValues['7'].eventsOfStages.length)
      return {
        ...item,
        hasError: !!formErrors?.['7']?.eventsOfStages?.length,
      }

    return {
      ...item,
      hasError: !!formErrors?.['7']?.eventsOfStages?.length,
      children: formValues['7'].eventsOfStages.map((step, indexOfStep): NestedMapOfMenu => {
        const stepError = formErrors?.['7']?.eventsOfStages?.[indexOfStep]
        const stepId = `${item.path}.${indexOfStep}`

        const preparedConstructionStageMenu = ((): NestedMapOfMenu[] => {
          if (!step.isConstruction && !step.isReconstruction && !step.isModernization) return []

          const constructionStageId = `${stepId}.constructionStage`
          const constructionStageError = stepError?.constructionStage

          const constructionObjectsId = `${constructionStageId}.constructionObjects`
          const permissionsObjectsId = `${constructionStageId}.permissionsObjects`
          const exploitationObjectsId = `${constructionStageId}.exploitation.exploitationObjects`
          const registrationObjectsId = `${constructionStageId}.registration.registrationObjects`

          const preparedConstructionObjects = ((): NestedMapOfMenu[] => {
            if (
              !step.constructionStage.constructionObjects ||
              !step.constructionStage.constructionObjects.length
            )
              return []

            return step.constructionStage.constructionObjects.map((object, indexOfObject) => ({
              id: `${step.anchorId}.1.${indexOfObject + 1}`,
              path: `${constructionObjectsId}.${indexOfObject}`,
              title: isNotEmptyString(object.objectName)
                ? object.objectName
                : `Основной объект №${indexOfObject + 1}`,
              isCollapsed: false,
              hasError: !!constructionStageError?.constructionObjects?.[indexOfObject],
              parentBaseId: `${step.anchorId}.1`,
              children: [],
            }))
          })()

          const preparedPermissionObjects = ((): NestedMapOfMenu[] => {
            if (
              !step.constructionStage.permissionsObjects ||
              !step.constructionStage.permissionsObjects.length
            )
              return []

            return step.constructionStage.permissionsObjects.map((object, indexOfObject) => ({
              id: `${step.anchorId}.2.${indexOfObject + 1}`,
              path: `${permissionsObjectsId}.${indexOfObject}`,
              parentBaseId: `${step.anchorId}.2`,
              isCollapsed: false,
              hasError: !!constructionStageError?.permissionsObjects?.[indexOfObject],
              title: isNotEmptyString(object.permissionKind)
                ? object.permissionKind
                : `Разрешение №${indexOfObject + 1}`,
              children: [],
            }))
          })()

          const preparedExploitationObjects = ((): NestedMapOfMenu[] => {
            if (
              !step.constructionStage.exploitation.exploitationObjects ||
              !step.constructionStage.exploitation.exploitationObjects.length
            )
              return []

            return step.constructionStage?.exploitation?.exploitationObjects.map(
              (object, indexOfObject) => ({
                id: `${step.anchorId}.3.${indexOfObject + 1}`,
                path: `${exploitationObjectsId}.${indexOfObject}`,
                parentBaseId: `${step.anchorId}.3`,
                isCollapsed: false,
                hasError:
                  !!constructionStageError?.exploitation?.exploitationObjects?.[indexOfObject],
                title: isNotEmptyString(object.objectName)
                  ? object.objectName
                  : `Эксплуатация №${indexOfObject + 1}`,
                children: [],
              }),
            )
          })()

          const preparedRegistrationObjects = ((): NestedMapOfMenu[] => {
            if (
              !step.constructionStage.registration.registrationObjects ||
              !step.constructionStage.registration.registrationObjects.length
            )
              return []

            return step.constructionStage.registration.registrationObjects.map(
              (object, indexOfObject) => ({
                id: `${step.anchorId}.4.${indexOfObject + 1}`,
                path: `${registrationObjectsId}.${indexOfObject}`,
                parentBaseId: `${step.anchorId}.4`,
                isCollapsed: false,
                hasError:
                  !!constructionStageError?.registration?.registrationObjects?.[indexOfObject],
                title: isNotEmptyString(object.objectName)
                  ? object.objectName
                  : `Регистрация №${indexOfObject + 1}`,
                children: [],
              }),
            )
          })()

          return [
            {
              id: `${step.anchorId}.1`,
              path: constructionObjectsId,
              title: 'Объекты недвижимости',
              parentBaseId: step.anchorId,
              isCollapsed: false,
              hasError: !!constructionStageError?.constructionObjects?.length,
              children: preparedConstructionObjects,
            },
            {
              id: `${step.anchorId}.2`,
              path: permissionsObjectsId,
              title: 'Разрешения на объекты',
              parentBaseId: step.anchorId,
              isCollapsed: false,
              hasError: !!constructionStageError?.permissionsObjects?.length,
              children: preparedPermissionObjects,
            },
            {
              id: `${step.anchorId}.3`,
              path: exploitationObjectsId,
              title: 'Эксплуатация объектов',
              isCollapsed: false,
              hasError: !!constructionStageError?.exploitation,
              parentBaseId: step.anchorId,
              children: preparedExploitationObjects,
            },
            {
              id: `${step.anchorId}.4`,
              path: registrationObjectsId,
              title: 'Регистрация объектов',
              isCollapsed: false,
              hasError: !!constructionStageError?.registration,
              parentBaseId: step.anchorId,
              children: preparedRegistrationObjects,
            },
          ]
        })()

        const preparedRidStageMenu = ((): NestedMapOfMenu[] => {
          if (!step.isCreateRID) return []

          const ridStageId = `${stepId}.ridStage`
          const ridError = stepError?.ridStage

          const ridObjectsId = `${ridStageId}.ridObjects`
          const ridExploitationObjectsId = `${ridStageId}.exploitation.ridExploitationObjects`
          const ridRegistrationObjectsId = `${ridStageId}.registration.ridRegistrationsObjects`

          const preparedRidObjects = ((): NestedMapOfMenu[] => {
            if (!step.ridStage?.ridObjects || !step.ridStage?.ridObjects?.length) return []

            return step.ridStage?.ridObjects?.map((object, indexOfRid) => ({
              id: `${step.anchorId}.5.${indexOfRid + 1}`,
              path: `${ridObjectsId}.${indexOfRid}`,
              title: isNotEmptyString(object.ridName) ? object.ridName : `РИД ${indexOfRid + 1}`,
              isCollapsed: false,
              hasError: !!ridError?.ridObjects?.[indexOfRid],
              parentBaseId: `${step.anchorId}.5`,
              children: [],
            }))
          })()

          const preparedRidExploitationObjects = ((): NestedMapOfMenu[] => {
            if (
              !step.ridStage?.exploitation?.ridExploitationObjects ||
              !step.ridStage?.exploitation?.ridExploitationObjects.length
            )
              return []

            return step.ridStage?.exploitation?.ridExploitationObjects.map(
              (object, indexOfRid) => ({
                id: `${step.anchorId}.6.${indexOfRid + 1}`,
                path: `${ridExploitationObjectsId}.${indexOfRid}`,
                title: isNotEmptyString(object.ridName)
                  ? object.ridName
                  : `Эксплуатация РИД ${indexOfRid + 1}`,
                isCollapsed: false,
                hasError: !!ridError?.exploitation?.ridExploitationObjects?.[indexOfRid],
                parentBaseId: `${step.anchorId}.6`,
                children: [],
              }),
            )
          })()

          const preparedRidRegistrationObjects = ((): NestedMapOfMenu[] => {
            if (
              !step.ridStage?.registration?.ridRegistrationsObjects ||
              !step.ridStage?.registration?.ridRegistrationsObjects.length
            )
              return []

            return step.ridStage?.registration?.ridRegistrationsObjects.map(
              (object, indexOfRid) => ({
                id: `${step.anchorId}.7.${indexOfRid + 1}`,
                path: `${ridRegistrationObjectsId}.${indexOfRid}`,
                title: isNotEmptyString(object.ridName)
                  ? object.ridName
                  : `Регистрация РИД ${indexOfRid + 1}`,
                isCollapsed: false,
                hasError: !!ridError?.registration?.ridRegistrationsObjects?.[indexOfRid],
                parentBaseId: `${step.anchorId}.7`,
                children: [],
              }),
            )
          })()

          return [
            {
              id: `${step.anchorId}.5`,
              path: ridObjectsId,
              title: 'Результаты интеллектуальной деятельности (РИД)',
              isCollapsed: false,
              hasError: !!ridError?.ridObjects?.length,
              parentBaseId: step.anchorId,
              children: preparedRidObjects,
            },
            {
              id: `${step.anchorId}.6`,
              path: ridExploitationObjectsId,
              title: 'Эксплуатация РИД',
              isCollapsed: false,
              hasError: !!ridError?.exploitation,
              parentBaseId: step.anchorId,
              children: preparedRidExploitationObjects,
            },
            {
              id: `${step.anchorId}.7`,
              path: ridRegistrationObjectsId,
              title: 'Регистрация РИД',
              isCollapsed: false,
              hasError: !!ridError?.registration,
              parentBaseId: step.anchorId,
              children: preparedRidRegistrationObjects,
            },
          ]
        })()

        return {
          path: stepId,
          id: step.anchorId,
          title: isNotEmptyString(step.tabName) ? step.tabName : `Мероприятие ${indexOfStep + 1}`,
          isCollapsed: false,
          hasError: !!stepError,
          parentBaseId: item.id,
          children: [...preparedConstructionStageMenu, ...preparedRidStageMenu],
        }
      }),
    }
  }

  const getPreparedStepActivityOthersMenu = (item: NestedMapOfMenu): NestedMapOfMenu => {
    if (!formValues['8'].stepActivityOthers || !formValues['8'].stepActivityOthers.length)
      return item

    return {
      ...item,
      hasError: !!formErrors?.['8']?.stepActivityOthers?.length,
      children: formValues['8'].stepActivityOthers?.flatMap((step, indexOfStep) =>
        step.activityOthers?.map((activity, indexOfActivity): NestedMapOfMenu => {
          const stepAnchorId = `${item.path}.${indexOfStep}`

          const activityAnchorId = `${stepAnchorId}.activityOthers.${indexOfActivity + 1}`

          const activityId = `${item.id}.${indexOfStep + 1}.${indexOfActivity + 1}`

          const activityError =
            formErrors?.['8']?.stepActivityOthers?.[indexOfStep]?.activityOthers?.[indexOfActivity]

          const preparedMeasureObjectsMenu = ((): NestedMapOfMenu[] => {
            if (!activity.measureObjects || !activity.measureObjects.length) return []

            return activity.measureObjects.map((object, indexOfObject) => ({
              path: `${activityAnchorId}.${indexOfObject}`,
              id: `${activityId}.${indexOfObject + 1}`,
              parentBaseId: activityAnchorId,
              isCollapsed: false,
              hasError: !!activityError?.measureObjects?.length,
              title: isNotEmptyString(object.objectName)
                ? object.objectName
                : `Объект №${indexOfObject + 1}`,
              children: [],
            }))
          })()

          return {
            path: activityAnchorId,
            id: activityId,
            parentBaseId: `${item.id}.${indexOfStep + 1}`,
            title: isNotEmptyString(activity.otherActivityName)
              ? activity.otherActivityName
              : `Мероприятие ${indexOfActivity + 1}`,
            isCollapsed: false,
            hasError: !!activityError,
            children: preparedMeasureObjectsMenu,
          }
        }),
      ),
    }
  }

  const preparedChildrenOfThirdSection = (() => {
    return initialHashMap['3'].children.map((item) => {
      if (item.id === eventOfStagesAnchorId) return getPreparedEventOfStagesMenu(item)

      if (item.id === stepActivityOthersAnchorId) return getPreparedStepActivityOthersMenu(item)

      return {
        ...item,
        hasError: !!formErrors?.[menuAnchorConverterMap[item.id]],
      }
    })
  })()

  const prepraredHashMap: NestedMenuHashMap = {
    '1': getMenuSectionWithError(initialHashMap['1']),
    '2': getMenuSectionWithError(initialHashMap['2']),
    '4': getMenuSectionWithError(initialHashMap['4']),
    '5': getMenuSectionWithError(initialHashMap['5']),
    '3': {
      ...initialHashMap['3'],
      hasError: preparedChildrenOfThirdSection.some(({ hasError }) => hasError),
      children: preparedChildrenOfThirdSection,
    },
  }

  return [
    prepraredHashMap['1'],
    prepraredHashMap['2'],
    prepraredHashMap['3'],
    prepraredHashMap['4'],
    prepraredHashMap['5'],
  ]
}

export { generateMenuListForProjectSZPK }
