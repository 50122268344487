import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOnMonitoringResultsFormValues,
  CertificateOnMonitoringResultsStepMonitoringChecker,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsStepMonitoringChecker,
  CertificateOnMonitoringResultsFormValues
> = {
  planStepTotalCapitalValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  diffStepTotalCapitalChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  factStepInvestorCapitalValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepInvestorCapitalVatValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  diffStepInvestorCapitalValueChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  factStepTotalCapitalValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepTotalCapitalVatValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepCompensationValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  factStepCompensationVatValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  diffStepCompensationValueChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  factStepWorkPlaceChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  diffStepWorkPlaceChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  diffStepDateChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  stepInfo: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  stepInfoChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { stepMonitoringCheckerCertificateOnMonitoringResultsValidationMap }
