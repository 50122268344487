import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import {
  useNestedMenuHandlersManager,
  useNestedMenuManager,
} from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import StepAnotherObject from '@components/Forms/ProjectSZPKForm/Forms/8/AdditionalForms/AnotherObjectForm/StepAnotherObject'
import {
  EighthAnotherStagesArrayPathName,
  EighthMeasureObjectsPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/8/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

interface AnotherObjectFormProps {
  isAddAnotherObjectButtonDisabled: boolean
  name: EighthAnotherStagesArrayPathName
  objectName: string
  parentAnchorId: string
}

const { transformRHFPathInProperties } = DocumentFormHelpers

const AnotherObjectForm: FC<AnotherObjectFormProps> = ({
  isAddAnotherObjectButtonDisabled,
  name,
  objectName,
  parentAnchorId,
}) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { getPropertiesProps, handleAddCustomValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const {
    state: { currentMenuItemId },
  } = useNestedMenuManager()

  const { handleChangeCurrentMenuItemId } = useNestedMenuHandlersManager()

  const { fields: anotherObjects } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    EighthMeasureObjectsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.measureObjects`,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleAddAnotherObject = async () => {
    const objectsIdToAddProps = getObjectValue(getPropertiesProps?.(), objectName)
    const stepActivityOtherProps = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(name),
    )

    if (!objectsIdToAddProps || !stepActivityOtherProps) return

    await handleAddCustomValue?.(
      {
        id: objectsIdToAddProps.propertyId,
        lastUpdateDt: objectsIdToAddProps.lastUpdateDt,
      },
      {
        isMainObject: false,
        objectOtherActivityName: stepActivityOtherProps.permanentId,
      },
    )

    const anotherObjectAnchorId = `${parentAnchorId}.${anotherObjects.length + 1}`

    if (currentMenuItemId !== parentAnchorId) return

    handleChangeCurrentMenuItemId?.(anotherObjectAnchorId)
  }

  const handleRemoveAnotherObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.measureObjects.${index}`, `${name}.measureObjects`)
  }

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          {!editMode && !anotherObjects.length && <EmptyDataText />}
          <Stack direction={'vertical'} gap={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.measureObjects`,
              })}
            >
              <FlipperList list={anotherObjects}>
                {anotherObjects.map((item, index) => {
                  const formName = `${name}.measureObjects.${index}` as const

                  return (
                    <StepAnotherObject
                      key={item.id}
                      name={formName}
                      indexOfObject={index}
                      anchorId={`${parentAnchorId}.${index + 1}`}
                      id={item.id}
                      onRemoveObject={handleRemoveAnotherObject(index)}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Stack>
        </Col>
      </Row>
      {editMode && (
        <Row className={'px-0.5'}>
          <AsyncWrapper promise={handleAddAnotherObject}>
            {({ isLoading, wrappedPromise }) => {
              return (
                <FieldView.ActionButton
                  disableWrapperPaddingLeft
                  disabled={isLoading || isAddAnotherObjectButtonDisabled}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  onClick={wrappedPromise}
                >
                  Добавить объект
                </FieldView.ActionButton>
              )
            }}
          </AsyncWrapper>
        </Row>
      )}
    </Stack>
  )
}

export default AnotherObjectForm
