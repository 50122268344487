import React, { FC, useMemo } from 'react'

import { statusTextsMap, statusTypesMap } from '@components/Attachments/constants'
import { Tooltip, TooltipProps } from '@components/NewDesign/Tooltip'
import { useHover } from '@hooks/new/events/useHover'
import cn from 'classnames'

import styles from './DocumentStatus.module.scss'

interface WebFormDocumentStatusProps {
  type: keyof typeof statusTypesMap
  tooltipProps?: Omit<TooltipProps, 'open' | 'content' | 'children'>
}

const WebFormDocumentStatus: FC<WebFormDocumentStatusProps> = ({ type, tooltipProps }) => {
  const {
    position = 'top',
    offset = [0, 0],
    targetClassName,
    ...restStatusTooltipProps
  } = tooltipProps || {}

  const [hoverRef, isHovered] = useHover<HTMLDivElement>()

  const statusTooltipContent = useMemo(() => <span>{statusTextsMap[type]}</span>, [type])

  return (
    <Tooltip
      open={isHovered}
      offset={offset}
      position={position}
      content={statusTooltipContent}
      targetClassName={cn(styles.tooltip__target, targetClassName)}
      {...restStatusTooltipProps}
    >
      <div ref={hoverRef} className={styles['webFormDocumentStatusProps__icon-wrapper']}>
        <span
          className={cn(styles.webFormDocumentStatusProps__icon, {
            [styles[`webFormDocumentStatusProps__icon--${type}`]]: type,
          })}
        />
      </div>
    </Tooltip>
  )
}

export default WebFormDocumentStatus
