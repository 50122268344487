import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { usePetitionRecognizePreviouslyConcludedContractAsBoundManager } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/Manager'
import { PetitionRecognizePreviouslyConcludedContractAsBoundFormValues } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/types'

import { mapOfPetitionRecognizePreviouslyConcludedContractAsBoundMenu } from '../const'
import PetitionRecognizePreviouslyConcludedContractAsBoundForms from '../Forms'

const mapOfPetitionRecognizePreviouslyConcludedContractAsBoundForms: FormItemProps = {
  '1': {
    node: <PetitionRecognizePreviouslyConcludedContractAsBoundForms.First />,
  },
  '2': {
    node: <PetitionRecognizePreviouslyConcludedContractAsBoundForms.Second />,
  },
} as const

const PetitionRecognizePreviouslyConcludedContractAsBoundForm: FC<FormModalStatementProps> = ({
  ...rest
}) => {
  const formInstance =
    useFormContext<PetitionRecognizePreviouslyConcludedContractAsBoundFormValues>()

  const {
    state: { formIsLoading },
  } = usePetitionRecognizePreviouslyConcludedContractAsBoundManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfPetitionRecognizePreviouslyConcludedContractAsBoundMenu}
      mapOfForms={mapOfPetitionRecognizePreviouslyConcludedContractAsBoundForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(PetitionRecognizePreviouslyConcludedContractAsBoundForm)
