import { DocumentsHelpersService } from '@services/Documents/documents.helpers'

const {
  isDocumentReadOnlyPermission,
  isDocumentReadSignPermission,
  isDocumentReadSignMainPermission,
  isDocumentReadEditPermission,
  isDocumentReadAddSignPermission,
  isDocumentReadApprovePermission,
  isDocumentDownloadingForbidden,
  isDocumentReadDownloadingForbidden,
  isDocumentReadDigitizing,
} = DocumentsHelpersService

interface UseAttachmentPermissionsProps {
  permissions: number
}

const useAttachmentPermissions = ({ permissions }: UseAttachmentPermissionsProps) => {
  const isReadOnlyPermission = isDocumentReadOnlyPermission(permissions)
  const isReadSignPermission = isDocumentReadSignPermission(permissions)
  const isReadSignMainPermission = isDocumentReadSignMainPermission(permissions)
  const isReadEditPermission = isDocumentReadEditPermission(permissions)
  const isReadAddSignPermission = isDocumentReadAddSignPermission(permissions)
  const isReadApprovePermission = isDocumentReadApprovePermission(permissions)
  const isDownloadingDocumentsForbidden = isDocumentDownloadingForbidden(permissions)
  const isReadDownloadingDocumentsForbidden = isDocumentReadDownloadingForbidden(permissions)
  const isReadDigitizingPermission = isDocumentReadDigitizing(permissions)

  const isReadAttachmentPermission =
    isReadOnlyPermission ||
    isReadSignPermission ||
    isReadSignMainPermission ||
    isReadDownloadingDocumentsForbidden

  const isWriteAttachmentPermission = isReadEditPermission || isReadAddSignPermission

  const isDownloadForbiddenAttachmentPermission =
    isDownloadingDocumentsForbidden || isReadDownloadingDocumentsForbidden

  return {
    isReadOnlyAttachmentPermission: isReadOnlyPermission,
    isReadAttachmentPermission,
    isWriteAttachmentPermission,
    isDownloadForbiddenAttachmentPermission,
    isReadApproveAttachmentPermission: isReadApprovePermission,
    isReadDigitizingPermission,
  }
}

export { useAttachmentPermissions }
