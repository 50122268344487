import React, { ElementType, FC, ReactNode } from 'react'

import { Transition, TransitionClasses, TransitionEvents } from '@headlessui/react'

import styles from './Fade.module.scss'

type TransitionFadeProps = TransitionClasses &
  TransitionEvents & {
    show: boolean
    children: ReactNode
    as?: ElementType
  }

const TransitionFade: FC<TransitionFadeProps> = ({ show, children, as, ...restProps }) => {
  return (
    <Transition
      show={show}
      enter={styles.enter}
      enterFrom={styles.enterFrom}
      enterTo={styles.enterTo}
      leave={styles.leave}
      leaveFrom={styles.leaveFrom}
      leaveTo={styles.leaveTo}
      {...restProps}
      as={as}
    >
      {children}
    </Transition>
  )
}

export default TransitionFade
