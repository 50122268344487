import { useCallback } from 'react'
import { type UseFormReturn, useFieldArray } from 'react-hook-form'

import {
  HandleAddFileItemProps,
  HandleRemoveFileItemProps,
  HandleUpdateFileItemProps,
} from '@components/Attachments/Attachment/FileItem/AttachmentFileItem/types'
import { attachmentsFormNames } from '@components/Attachments/constants'
import type { AttachmentsFormValue } from '@components/Attachments/types'

const useAttachmentFileItemFieldArray = (formInstance: UseFormReturn<AttachmentsFormValue>) => {
  const {
    fields: attachments,
    append,
    update,
    remove,
  } = useFieldArray({
    control: formInstance.control,
    name: attachmentsFormNames.fileItems,
    keyName: 'keyNameId',
  })

  const handleAddFileItem = useCallback(
    ({ attachmentFileItem }: HandleAddFileItemProps) => {
      append(attachmentFileItem)
    },
    [append],
  )

  const handleUpdateFileItem = useCallback(
    ({ attachmentFileItem, attachmentFileIndex }: HandleUpdateFileItemProps) => {
      update(attachmentFileIndex, attachmentFileItem)
    },
    [update],
  )

  const handleRemoveFileItem = useCallback(
    ({ attachmentFileIndex }: HandleRemoveFileItemProps) => {
      remove(attachmentFileIndex)
    },
    [remove],
  )

  return {
    attachments,
    handleAddFileItem,
    handleUpdateFileItem,
    handleRemoveFileItem,
  }
}

export { useAttachmentFileItemFieldArray }
