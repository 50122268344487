import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms: NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormsProps =
  () => null

NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms.First = First
NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms.Second = Second
NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms.Third = Third
NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms.Fourth = Fourth

export default NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms
