import { IDfoListItem, TGetAllDocumentsOfDfo } from '@services/Dfo/Dfo.entity'
import {
  IDocumentDictInfo,
  IDocumentSetsDictTypes,
} from '@services/Dictionaries/dictionaries.entity'
import {
  IDocumentTypeInfo,
  IDocumentTypeInfoWithDict,
  IModifiedDocumentSetInfoForRender,
  IModifiedDocumentSetInfoForRenderToArray,
} from '@services/Documents/documents.entity'

interface PrepareDocumentsFuncProps {
  documents: TGetAllDocumentsOfDfo
  dictOfDocuments: IDocumentSetsDictTypes
  dfo: IDfoListItem

  isFederal: boolean
}

interface PrepareDocumentsToArrayProps {
  mapOfSets: Map<string, IModifiedDocumentSetInfoForRender[]>
  dfoType: string
}

class DocumentsService {
  preparedSetOfDocumentsToArray({
    mapOfSets,
  }: PrepareDocumentsToArrayProps): IModifiedDocumentSetInfoForRenderToArray[] {
    return [...mapOfSets.entries()].map(([documentSetsType, documentSets]) => {
      return {
        documentSetsType,
        documentSets,
      }
    })
  }

  prepareDocumentTypeDictInfo(
    documentDictInfo: IDocumentDictInfo[],
    documentTypeInfo: IDocumentTypeInfo[],
  ): IDocumentTypeInfoWithDict[] {
    return (
      documentDictInfo.map((documentDictItem) => {
        const documentExistedInSet = documentTypeInfo.find(
          (documentTypeItem) => documentTypeItem.description === documentDictItem.documentType,
        )

        if (!!documentExistedInSet) {
          return {
            ...documentExistedInSet,
            documentInfoFromDict: documentDictItem,
          }
        }

        return {
          description: documentDictItem.documentType,
          documents: [],
          folders: [],
          forms: [],
          documentInfoFromDict: documentDictItem,
        }
      }) || []
    )
  }

  prepareDocumentsSetFromDictAndDocumentsResponse({
    documents,
    dictOfDocuments,
    dfo,
    isFederal,
  }: PrepareDocumentsFuncProps) {
    return Object.entries(documents).reduce<Map<string, IModifiedDocumentSetInfoForRender[]>>(
      (previousValue, currentValue) => {
        const [documentSetType, documentSets] = currentValue

        const documentSetFromDict = dictOfDocuments[dfo.type]?.[documentSetType]

        const preparedDocumentSets = documentSets.map((documentSet) => {
          const preparedDocumentTypeInfo: IDocumentTypeInfoWithDict[] =
            this.prepareDocumentTypeDictInfo(
              documentSetFromDict?.documentTypeDictInfo.filter(
                (item) => item.isFederal === isFederal,
              ),
              documentSet.documentTypeInfo,
            )

          const preparedReturnValue: IModifiedDocumentSetInfoForRender = {
            ...documentSet,
            documentSetIcon: documentSetFromDict?.documentSetIcon,
            documentSetIsSystem: documentSetFromDict?.documentSetIsSystem,
            documentTypeInfo: preparedDocumentTypeInfo,
            hasChangedDocuments: preparedDocumentTypeInfo?.some(
              (typeInfo) =>
                typeInfo.documentInfoFromDict.isVisible &&
                (typeInfo.documents?.some(
                  (document) =>
                    document?.versionChangeState && document?.versionChangeState !== 'FIXED',
                ) ||
                  typeInfo?.folders?.some((item) =>
                    item?.documents?.some(
                      (document) =>
                        document?.versionChangeState && document.versionChangeState !== 'FIXED',
                    ),
                  )),
            ),
          }

          return preparedReturnValue
        })

        previousValue.set(documentSetType, preparedDocumentSets)

        return previousValue
      },
      new Map(),
    )
  }
}

export default new DocumentsService()
