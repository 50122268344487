import { DependentUrls, NewUrls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { stringifyQuery } from '@helpers/format'

import type {
  IFiltersOfAuthorizedOrganizations,
  IPostAuthorizedOrganizationsBody,
  IPutAuthorizedOrganizationsBody,
  IPutAuthorizedOrganizationsBodyWithNewInn,
  TGetListAuthorizedOrganizationResponse,
} from './types'

export const useAuthorizedOrganizationsApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  /**
   * Метод получения списка уполномоченных организаций с указанным типом, добавленных в реестр
   * @param filters - {
   * @param type - Тип организации;
   * @param regionNumber - фильтр по региону нахождения ОМСУ;
   * @param searchString - Пользовательский свободный фильтр по полям:
   ОКТМО,
   ИНН,
   наименование уполномоченной организации,
   наименование МО,
   номеру региона;
   * @param page - Параметр постраничного отображения. Номер страницы (0...N);
   * @param size - Параметр постраничного отображения данных. Количество записей на странице;
   *
   * @returns organizationName - наименование уполномоченной организации;
   * @returns inn - ИНН уполномоченной организации;
   * @returns municipalityName - наименование муниципалитета;
   * @returns oktmo - код ОКТМО муниципалитета;
   * @returns region - номер региона;
   * @returns regionName - наименование региона;
   * @returns link - ссылка на сайт уполномоченной организации;
   * @returns image - изображение герба региона;
   */
  const getListOfAuthorizedOrganizations = async (filters: IFiltersOfAuthorizedOrganizations) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetListAuthorizedOrganizationResponse>({
        url: NewUrls.AuthorizedOrganizations,
        config: {
          params: filters,
          paramsSerializer: stringifyQuery,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  /**
   * Добавление уполномоченной организации в реестр
   * @param type - Тип организации
   * @param inn - ИНН МО. Всегда 10 символов
   * @param organizationName - название организации, ответственной за работу с СЗПК в  МО
   * @param oktmo - код Общероссийского классификатора территорий муниципальных образований. Всегда 8 или 11 символов
   * @param municipalityName - наименование МО
   * @param regionNumber - код региона, в котором находится МО
   * @param link - ссылка на сайт МО
   */
  const createAuthorizedOrganization = async (
    authorizedOrganizationsBody: IPostAuthorizedOrganizationsBody,
  ) => {
    try {
      return await FetcherWithInterceptors.post({
        url: NewUrls.AuthorizedOrganizations,
        data: authorizedOrganizationsBody,
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  /**
   * Изменение метаинформации организации в реестре
   * @param inn  -  ИНН организации, которая была уполномоченной до смены
   * @param newInn  - ИНН текущей уполномоченной организации
   * @param organizationName - название уполномоченной организации, ответственной за работу с СЗПК
   * @param oktmo  - код Общероссийского классификатора территорий муниципальных образований. Всегда 8 или 11 символов
   * @param municipalityName - наименование МО
   * @param link - ссылка на сайт уполномоченной организации
   */
  const updateINNAuthorizedOrganization = async ({
    inn,
    ...props
  }: IPutAuthorizedOrganizationsBodyWithNewInn) => {
    try {
      return await FetcherWithInterceptors.post({
        url: {
          url: DependentUrls.UpdateINNAuthorizedOrganizations,
          params: { inn },
        },
        data: props,
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  /**
   * Изменение метаинформации организации в реестре
   * @param inn  -  ИНН организации, которая была уполномоченной до смены
   * @param organizationName - название уполномоченной организации, ответственной за работу с СЗПК
   * @param oktmo  - код Общероссийского классификатора территорий муниципальных образований. Всегда 8 или 11 символов
   * @param municipalityName - наименование МО
   * @param link - ссылка на сайт уполномоченной организации
   */
  const updateAuthorizedOrganization = async ({
    inn,
    ...props
  }: IPutAuthorizedOrganizationsBody) => {
    try {
      return await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.UpdateAuthorizedOrganizations,
          params: { inn },
        },
        data: props,
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    getListOfAuthorizedOrganizations,
    updateINNAuthorizedOrganization,
    createAuthorizedOrganization,
    updateAuthorizedOrganization,
  }
}
