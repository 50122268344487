import React from 'react'

import {
  FormIntervalReturn,
  useFormInterval,
} from '@components/DocumentFormComponents/hooks/useFormInterval'
import { useBooleanState } from '@hooks/useBooleanState'

interface FormControlReturn {
  formState: {
    isFormLoading: boolean
    setFormLoading: React.Dispatch<React.SetStateAction<boolean>>
  }
  intervalState: FormIntervalReturn
}

const useFormControl = () => {
  const { booleanState: isFormLoading, setBooleanState: setFormLoading } = useBooleanState(true)

  const intervalState = useFormInterval()

  return {
    formState: {
      isFormLoading,
      setFormLoading,
    },
    intervalState,
  }
}
export type { FormControlReturn }
export { useFormControl }
