import { ChangeEvent } from 'react'

import { InputProps } from '../Input/types'

export type DateInputProps = Omit<InputProps, 'onChange'> & {
  /**
   * Обработчик изменения значения
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>, payload: { date: Date; value: string }) => void

  inputMask?: (string | RegExp)[]

  inputPattern?: string

  format?: string
  
  dataTestId?: string
}

export const DATE_MASK = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]

export const YEAR_MASK = [/\d/, /\d/, /\d/, /\d/]
