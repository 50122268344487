import React from 'react'

import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import AddButtonIcon from '@icons/AddButtonIcon.svg'

export const NotNotifications = ({ onInitNotificationAdding, isLoading }) => {
  return (
    <>
      <Loader variant={'lite'} loading={isLoading} />
      {!isLoading && (
        <Button
          view={'plain'}
          type="button"
          disabled={isLoading}
          leadingIcon={{
            src: AddButtonIcon,
          }}
          onClick={onInitNotificationAdding}
        >
          Добавить уведомление о смене реквизитов
        </Button>
      )}
    </>
  )
}
