import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'

const { getDefaultInfoAboutDocument } = FieldStateHelpers

const mapOfCertificateOnMonitoringResultsMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченная организация' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'О проекте' },
  { id: '5', title: 'Информация о реализации этапов' },
  {
    id: '6',
    title:
      'Кредиты и займы, купонный доход, облигационные займы, затраты по которым понесены при реализации инвестиционного проекта',
  },
  {
    id: '7',
    title: 'Объекты жилищного строительства, расположенные на территориях военных городков',
  },
  {
    id: '8',
    title:
      'Документально подтвержденные и фактические понесенные расходы, возникшие вследствие нарушения условий стабилизационной оговорки',
  },
  { id: '9', title: 'Информация о возмещении затрат' },
  { id: '10', title: 'Объем уплаченных налогов и таможенных платежей' },
  { id: '11', title: 'Информация об осуществленных мерах государственной поддержки' },
  { id: '12', title: 'Информация о наличии оснований для изменения или расторжения СЗПК' },
]

const defaultCertificateOnMonitoringResultsFormValues: CertificateOnMonitoringResultsFormValues = {
  '1': getDefaultInfoAboutDocument(),
  '2': {
    initiatorFullName: '',
    initiatorFullNameInstrumental: '',
    initiatorInn: '',
    initiatorOgrn: '',
    initiatorAddressFullEgrulFormat: '',
    initiatorHeaderName: null,
    initiatorHeaderPosition: '',
  },
  '3': {
    investorFullName: '',
    investorFullNameGenitive: '',
    investorInn: '',
    investorOgrn: '',
    investorAddress: '',
  },
  '4': {
    projectName: '',
    projectDecisionDepartmentGenitive: '',
    economicSphere: '',
    szpkRegistrationDate: '',
    startPreinvestStage: '',
    endPreinvestStage: '',
    startInvestStage: '',
    endInvestStage: '',
    startExploitationStage: '',
    endExploitationStage: '',
    isVatMoney: false,
    totalCapital: '',
    totalCapitalVAT: '',
    investorCapital: '',
    investorCapitalVAT: '',
    newWorkPlaces: '',
    projectResultEffect: '',
    projectResultEffectChecker: '',
  },
  '5': {
    stepsMonitoringChecker: [],
  },
  '6': {
    percentCreditsChecker: [],
  },
  '7': {
    dismantlingObjectsChecker: [],
  },
  '8': {
    expensesChecker: [],
  },
  '9': {
    compensationsMonitoringChecker: [],
  },
  '10': {
    compensationsMonitoring: [],
  },
  '11': {
    stateSupportsChecker: [],
    generalStateSupportValue: '',
    generalStateSupportValueChecker: '',
  },
  '12': {
    isChanges: false,
    isTerminate: false,
    relatedContractReasonChecker: false,
    relatedContractReasonDescription: '',
    relatedContractReasonDescriptionChecker: '',
    extendStabilizationReasonChecker: false,
    extendStabilizationReasonDescription: '',
    extendStabilizationReasonDescriptionChecker: '',
    municipalAccessionReasonChecker: false,
    municipalAccessionReasonDescription: '',
    municipalAccessionReasonDescriptionChecker: '',
    rightTransferReasonChecker: false,
    rightTransferReasonDescription: '',
    rightTransferReasonDescriptionChecker: '',
    infoChangesReasonChecker: false,
    infoChangesReasonDescription: '',
    infoChangesReasonDescriptionChecker: '',
    forceMajeureReasonChecker: false,
    forceMajeureReasonDescription: '',
    forceMajeureReasonDescriptionChecker: '',
    agreementDutyReasonChecker: false,
    agreementDutyReasonDescription: '',
    agreementDutyReasonDescriptionChecker: '',
    concessionFailReasonChecker: false,
    concessionFailReasonDescription: '',
    concessionFailReasonDescriptionChecker: '',
    constructionChangeReasonChecker: false,
    constructionChangeReasonDescription: '',
    constructionChangeReasonDescriptionChecker: '',
    supportingInfrastructureReasonChecker: false,
    supportingInfrastructureReasonDescription: '',
    supportingInfrastructureReasonDescriptionChecker: '',
    investmentChangeReasonChecker: false,
    investmentChangeReasonDescription: '',
    investmentChangeReasonDescriptionChecker: '',
    compensationChangeReasonChecker: false,
    compensationChangeReasonDescription: '',
    compensationChangeReasonDescriptionChecker: '',
    changeCompensationFormatReasonChecker: false,
    changeCompensationFormatReasonDescription: '',
    changeCompensationFormatReasonDescriptionChecker: '',
    lawChangeReasonChecker: false,
    lawChangeReasonDescription: '',
    lawChangeReasonDescriptionChecker: '',
    noMadeInvestorCapitalReasonChecker: false,
    noMadeInvestorCapitalReasonDescription: '',
    noMadeInvestorCapitalReasonDescriptionChecker: '',
    legalFactsReasonChecker: false,
    legalFactsReasonDescription: '',
    legalFactsReasonDescriptionChecker: '',
    lawViolationReasonChecker: false,
    lawViolationReasonDescription: '',
    lawViolationReasonDescriptionChecker: '',
    suspensionOrganizationActivityReasonChecker: false,
    suspensionOrganizationActivityReasonDescription: '',
    suspensionOrganizationActivityReasonDescriptionChecker: '',
    liquidationOrganisationReasonChecker: false,
    liquidationOrganisationReasonDescription: '',
    liquidationOrganisationReasonDescriptionChecker: '',
    otherReasonChecker: false,
    otherReasonName: '',
    otherReasonDescription: '',
    otherReasonNameChecker: '',
    otherReasonDescriptionChecker: '',
    changeSuggestions: '',
    changeRecommendations: '',

    relatedContractReason: false,
    extendStabilizationReason: false,
    municipalAccessionReason: false,
    rightTransferReason: false,
    infoChangesReason: false,
    forceMajeureReason: false,
    agreementDutyReason: false,
    concessionFailReason: false,
    constructionChangeReason: false,
    supportingInfrastructureReason: false,
    investmentChangeReason: false,
    compensationChangeReason: false,
    changeCompensationFormatReason: false,
    lawChangeReason: false,
    noMadeInvestorCapitalReason: false,
    legalFactsReason: false,
    lawViolationReason: false,
    suspensionOrganizationActivityReason: false,
    liquidationOrganisationReason: false,
    otherReason: false,
  },
}

const certificateOnMonitoringResultsBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
    initiatorFullNameInstrumental: '2.initiatorFullNameInstrumental',
    initiatorInn: '2.initiatorInn',
    initiatorOgrn: '2.initiatorOgrn',
    initiatorAddressFullEgrulFormat: '2.initiatorAddressFullEgrulFormat',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameGenitive: '3.investorFullNameGenitive',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorAddress: '3.investorAddress',
  },
  '4': {
    projectName: '4.projectName',
    projectDecisionDepartmentGenitive: '4.projectDecisionDepartmentGenitive',
    economicSphere: '4.economicSphere',
    szpkRegistrationDate: '4.szpkRegistrationDate',
    startPreinvestStage: '4.startPreinvestStage',
    endPreinvestStage: '4.endPreinvestStage',
    startInvestStage: '4.startInvestStage',
    endInvestStage: '4.endInvestStage',
    startExploitationStage: '4.startExploitationStage',
    endExploitationStage: '4.endExploitationStage',
    isVatMoney: '4.isVatMoney',
    totalCapital: '4.totalCapital',
    totalCapitalVAT: '4.totalCapitalVAT',
    investorCapital: '4.investorCapital',
    investorCapitalVAT: '4.investorCapitalVAT',
    newWorkPlaces: '4.newWorkPlaces',
    projectResultEffect: '4.projectResultEffect',
    projectResultEffectChecker: '4.projectResultEffectChecker',
  },
  '5': {
    stepsMonitoringChecker: '5.stepsMonitoringChecker',
  },
  '6': {
    percentCreditsChecker: '6.percentCreditsChecker',
  },
  '7': {
    dismantlingObjectsChecker: '7.dismantlingObjectsChecker',
  },
  '8': {
    expensesChecker: '8.expensesChecker',
  },
  '9': {
    compensationsMonitoringChecker: '9.compensationsMonitoringChecker',
  },
  '10': {
    compensationsMonitoring: '10.compensationsMonitoring',
  },
  '11': {
    stateSupportsChecker: '11.stateSupportsChecker',
    generalStateSupportValue: '11.generalStateSupportValue',
    generalStateSupportValueChecker: '11.generalStateSupportValueChecker',
  },
  '12': {
    isChanges: '12.isChanges',
    isTerminate: '12.isTerminate',
    relatedContractReasonChecker: '12.relatedContractReasonChecker',
    relatedContractReasonDescription: '12.relatedContractReasonDescription',
    relatedContractReasonDescriptionChecker: '12.relatedContractReasonDescriptionChecker',
    extendStabilizationReasonChecker: '12.extendStabilizationReasonChecker',
    extendStabilizationReasonDescription: '12.extendStabilizationReasonDescription',
    extendStabilizationReasonDescriptionChecker: '12.extendStabilizationReasonDescriptionChecker',
    municipalAccessionReasonChecker: '12.municipalAccessionReasonChecker',
    municipalAccessionReasonDescription: '12.municipalAccessionReasonDescription',
    municipalAccessionReasonDescriptionChecker: '12.municipalAccessionReasonDescriptionChecker',
    rightTransferReasonChecker: '12.rightTransferReasonChecker',
    rightTransferReasonDescription: '12.rightTransferReasonDescription',
    rightTransferReasonDescriptionChecker: '12.rightTransferReasonDescriptionChecker',
    infoChangesReasonChecker: '12.infoChangesReasonChecker',
    infoChangesReasonDescription: '12.infoChangesReasonDescription',
    infoChangesReasonDescriptionChecker: '12.infoChangesReasonDescriptionChecker',
    forceMajeureReasonChecker: '12.forceMajeureReasonChecker',
    forceMajeureReasonDescription: '12.forceMajeureReasonDescription',
    forceMajeureReasonDescriptionChecker: '12.forceMajeureReasonDescriptionChecker',
    agreementDutyReasonChecker: '12.agreementDutyReasonChecker',
    agreementDutyReasonDescription: '12.agreementDutyReasonDescription',
    agreementDutyReasonDescriptionChecker: '12.agreementDutyReasonDescriptionChecker',
    concessionFailReasonChecker: '12.concessionFailReasonChecker',
    concessionFailReasonDescription: '12.concessionFailReasonDescription',
    concessionFailReasonDescriptionChecker: '12.concessionFailReasonDescriptionChecker',
    constructionChangeReasonChecker: '12.constructionChangeReasonChecker',
    constructionChangeReasonDescription: '12.constructionChangeReasonDescription',
    constructionChangeReasonDescriptionChecker: '12.constructionChangeReasonDescriptionChecker',
    supportingInfrastructureReasonChecker: '12.supportingInfrastructureReasonChecker',
    supportingInfrastructureReasonDescription: '12.supportingInfrastructureReasonDescription',
    supportingInfrastructureReasonDescriptionChecker:
      '12.supportingInfrastructureReasonDescriptionChecker',
    investmentChangeReasonChecker: '12.investmentChangeReasonChecker',
    investmentChangeReasonDescription: '12.investmentChangeReasonDescription',
    investmentChangeReasonDescriptionChecker: '12.investmentChangeReasonDescriptionChecker',
    compensationChangeReasonChecker: '12.compensationChangeReasonChecker',
    compensationChangeReasonDescription: '12.compensationChangeReasonDescription',
    compensationChangeReasonDescriptionChecker: '12.compensationChangeReasonDescriptionChecker',
    changeCompensationFormatReasonChecker: '12.changeCompensationFormatReasonChecker',
    changeCompensationFormatReasonDescription: '12.changeCompensationFormatReasonDescription',
    changeCompensationFormatReasonDescriptionChecker:
      '12.changeCompensationFormatReasonDescriptionChecker',
    lawChangeReasonChecker: '12.lawChangeReasonChecker',
    lawChangeReasonDescription: '12.lawChangeReasonDescription',
    lawChangeReasonDescriptionChecker: '12.lawChangeReasonDescriptionChecker',
    noMadeInvestorCapitalReasonChecker: '12.noMadeInvestorCapitalReasonChecker',
    noMadeInvestorCapitalReasonDescription: '12.noMadeInvestorCapitalReasonDescription',
    noMadeInvestorCapitalReasonDescriptionChecker:
      '12.noMadeInvestorCapitalReasonDescriptionChecker',
    legalFactsReasonChecker: '12.legalFactsReasonChecker',
    legalFactsReasonDescription: '12.legalFactsReasonDescription',
    legalFactsReasonDescriptionChecker: '12.legalFactsReasonDescriptionChecker',
    lawViolationReasonChecker: '12.lawViolationReasonChecker',
    lawViolationReasonDescription: '12.lawViolationReasonDescription',
    lawViolationReasonDescriptionChecker: '12.lawViolationReasonDescriptionChecker',
    suspensionOrganizationActivityReasonChecker: '12.suspensionOrganizationActivityReasonChecker',
    suspensionOrganizationActivityReasonDescription:
      '12.suspensionOrganizationActivityReasonDescription',
    suspensionOrganizationActivityReasonDescriptionChecker:
      '12.suspensionOrganizationActivityReasonDescriptionChecker',
    liquidationOrganisationReasonChecker: '12.liquidationOrganisationReasonChecker',
    liquidationOrganisationReasonDescription: '12.liquidationOrganisationReasonDescription',
    liquidationOrganisationReasonDescriptionChecker:
      '12.liquidationOrganisationReasonDescriptionChecker',
    otherReasonChecker: '12.otherReasonChecker',
    otherReasonName: '12.otherReasonName',
    otherReasonDescription: '12.otherReasonDescription',
    otherReasonNameChecker: '12.otherReasonNameChecker',
    otherReasonDescriptionChecker: '12.otherReasonDescriptionChecker',
    changeSuggestions: '12.changeSuggestions',
    changeRecommendations: '12.changeRecommendations',

    relatedContractReason: '12.relatedContractReason',
    extendStabilizationReason: '12.extendStabilizationReason',
    municipalAccessionReason: '12.municipalAccessionReason',
    rightTransferReason: '12.rightTransferReason',
    infoChangesReason: '12.infoChangesReason',
    forceMajeureReason: '12.forceMajeureReason',
    agreementDutyReason: '12.agreementDutyReason',
    concessionFailReason: '12.concessionFailReason',
    constructionChangeReason: '12.constructionChangeReason',
    supportingInfrastructureReason: '12.supportingInfrastructureReason',
    investmentChangeReason: '12.investmentChangeReason',
    compensationChangeReason: '12.compensationChangeReason',
    changeCompensationFormatReason: '12.changeCompensationFormatReason',
    lawChangeReason: '12.lawChangeReason',
    noMadeInvestorCapitalReason: '12.noMadeInvestorCapitalReason',
    legalFactsReason: '12.legalFactsReason',
    lawViolationReason: '12.lawViolationReason',
    suspensionOrganizationActivityReason: '12.suspensionOrganizationActivityReason',
    liquidationOrganisationReason: '12.liquidationOrganisationReason',
    otherReason: '12.otherReason',
  },
} as const

export {
  certificateOnMonitoringResultsBlockValues,
  defaultCertificateOnMonitoringResultsFormValues,
  mapOfCertificateOnMonitoringResultsMenu,
}
