import { useCallback, useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { TGetListAuthorizedOrganizationResponse } from '@context/APIContext/hooks/useAuthorizedOrganizationsApi/types'
import { SWRIniniteHookProps } from '@interfaces'
import useSWRInfinite from 'swr/infinite'

const useAuthorizedOrganizationsList = ({
  key,
  config,
}: SWRIniniteHookProps<TGetListAuthorizedOrganizationResponse>) => {
  const {
    authorizedOrganizationsApi: { getListOfAuthorizedOrganizations },
  } = useAPIContext()

  const {
    data: authorizedOrganizationsList,
    error,
    mutate,
    isValidating: isAuthorizedOrganizationsListLoading,
    setSize,
    size,
  } = useSWRInfinite(key, getListOfAuthorizedOrganizations, {
    ...config,
    revalidateFirstPage: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  // @ts-ignore
  const { size: currentSize } = key(size, authorizedOrganizationsList?.[size - 1])

  const lastPageData = useMemo(
    () => authorizedOrganizationsList?.[authorizedOrganizationsList?.length - 1],
    [authorizedOrganizationsList],
  )

  const hasMoreData = useMemo(() => {
    return authorizedOrganizationsList && lastPageData?.length === currentSize
  }, [authorizedOrganizationsList, currentSize, lastPageData?.length])

  const goToNextPage = useCallback(() => {
    if (hasMoreData && !isAuthorizedOrganizationsListLoading) setSize((prev) => prev + 1)
  }, [hasMoreData, isAuthorizedOrganizationsListLoading, setSize])

  const computedAuthorizedOrganizationsList = useMemo(() => {
    return authorizedOrganizationsList?.flat()
  }, [authorizedOrganizationsList])

  const gridSwrInstance = useMemo(
    () => ({
      authorizedOrganizationsList: computedAuthorizedOrganizationsList,
      error,
      mutate,
      isAuthorizedOrganizationsListLoading,
      hasMoreData,
      goToNextPage,
    }),
    [
      computedAuthorizedOrganizationsList,
      error,
      hasMoreData,
      isAuthorizedOrganizationsListLoading,
      mutate,
      setSize,
      goToNextPage,
    ],
  )

  return gridSwrInstance
}

export { useAuthorizedOrganizationsList }
