import { DetailedHTMLProps, FC, HTMLAttributes, memo, useEffect, useState } from 'react'

import Highlighted from '@components/Highlighted/Highlighted'
import Icon from '@components/Icon'
import { useHierarchyReferenceBookManager } from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Manager'
import ProportionalBox from '@components/ProportionalBox'
import { SkeletonText } from '@components/Skeleton/Text/SkeletonText'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { useReferenceBookChildsByDictionaryNameAndId } from '@hooks/new/swr/useReferenceBookChildsByDictionaryNameAndId'
import groupIcon from '@icons/list/groupList.svg'
import cn from 'classnames'
import { unstable_serialize, useSWRConfig } from 'swr'

import styles from './Item.module.scss'

interface HierarchyReferenceBookItemProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  referenceBookItem: ReferenceBookItem
  onReferenceBookItemChange: (referenceBookItem: ReferenceBookItem) => void
  initialPadding?: number
  stepOfPadding?: number
}

const INITIAL_PADDING = 60
const STEP_OF_PADDING = 10

const HierarchyReferenceBookItem: FC<HierarchyReferenceBookItemProps> = ({
  referenceBookItem,
  onReferenceBookItemChange,
  className,
  initialPadding = INITIAL_PADDING,
  stepOfPadding = STEP_OF_PADDING,
  ...rest
}) => {
  const { cache } = useSWRConfig()

  const { state, handlers } = useHierarchyReferenceBookManager()

  const isChosenSelectedItem = referenceBookItem.id === state.selectedItemId
  const selectedItemWithChildren = isChosenSelectedItem && referenceBookItem.hasChild
  const isRenderFromSearchThree = !!state.searchThree
  const disableCheckChildren = !referenceBookItem.parentId && referenceBookItem.hasChild

  const threeIsOpenFromInitial =
    (!!referenceBookItem.id && !!state.three?.get(referenceBookItem.id)) ||
    (!!referenceBookItem.parentId &&
      !!selectedItemWithChildren &&
      !!state.three?.get(referenceBookItem.parentId))

  const [isExpanded, setIsExpanded] = useState(isRenderFromSearchThree || threeIsOpenFromInitial)

  const currentReferenceBook = useReferenceBookChildsByDictionaryNameAndId({
    key:
      isExpanded && referenceBookItem.hasChild
        ? {
            id: referenceBookItem.id,
            dictionaryName: state.dictionaryName,
            _key: 'referenceBookChildsById',
          }
        : null,
  })

  const isListFromFetcher = !!referenceBookItem.hasChild && !referenceBookItem.children
  const isListFromHybrid = !!referenceBookItem.hasChild

  // Является ли текущий элемент родителем других элементов
  const currentIsList = isRenderFromSearchThree ? isListFromHybrid : isListFromFetcher

  // Условие продолжения рекурсии
  const recursionCondition = currentIsList && isExpanded

  //Запрашиваем элементы списка
  useEffect(() => {
    const needUpdateThreeInsideParent = isExpanded && referenceBookItem.hasChild

    if (
      needUpdateThreeInsideParent &&
      cache.get(
        unstable_serialize({
          id: referenceBookItem.id,
          dictionaryName: state.dictionaryName,
          _key: 'referenceBookChildsById',
        }),
      )
    ) {
      cache.delete(
        unstable_serialize({
          id: referenceBookItem.id,
          dictionaryName: state.dictionaryName,
          _key: 'referenceBookChildsById',
        }),
      )

      currentReferenceBook.mutate()
    }
  }, [referenceBookItem.hasChild, isExpanded])

  //Если дерево поиска сформирован или выделенный элемент является родителем других, раскрываем элемент и выделяем соответствия (если бы не было выделено)
  useEffect(() => {
    if (!state.searchThree) {
      return setIsExpanded(false)
    }

    if (isRenderFromSearchThree && referenceBookItem.hasChild) {
      setIsExpanded(true)
      return
    }
  }, [state.searchThree])

  // Проверяем по карте кодов, какие элементы необходимо раскрыть и произвести все запросы
  useEffect(() => {
    if (threeIsOpenFromInitial) {
      setIsExpanded(true)

      currentReferenceBook.mutate()
    }
  }, [threeIsOpenFromInitial])

  const handleExpandOnClick = () => {
    const expandedTrueInitialCondition =
      isRenderFromSearchThree && isExpanded && !isChosenSelectedItem && !disableCheckChildren

    //С поиска все группы сразу приходят открытые, при нажатии на открытую группу, сначала элемент должен выделиться, иначе будет реверс.
    if (expandedTrueInitialCondition) {
      return setIsExpanded(true)
    }

    //Условие работает для вложенных элементов, если элемент раскрыт, то по клику он становится активный, следующий клик уже закрывает элемент
    if (isExpanded && !isChosenSelectedItem && !disableCheckChildren) return

    setIsExpanded((prev) => !prev)
  }

  const handleOnChange = (referenceBookItem: ReferenceBookItem) => {
    onReferenceBookItemChange(referenceBookItem)
  }

  //Открываем элемент, запускаем фетчеры, пропс disableCheckChildren необходим на первого уровня
  const handleExpandedClick = (referenceBookItem: ReferenceBookItem) => {
    handleExpandOnClick()

    //Если не первый уровень или у первого уровня нет детей, то группу можно будет выбрать
    if (!disableCheckChildren) {
      return handleOnChange(referenceBookItem)
    }

    if (isExpanded || isRenderFromSearchThree) return

    return currentReferenceBook.mutate()
  }

  // Если список - открываем, если значение - выбираем
  const preparedHandleExpandedClick = () => {
    if (
      !referenceBookItem ||
      (isRenderFromSearchThree && currentIsList && !referenceBookItem.hasChild)
    )
      return

    if (currentIsList) {
      return handleExpandedClick(referenceBookItem)
    }

    return handleOnChange(referenceBookItem)
  }

  //Интерфейс для привязывания рефа к менеджеру
  const bindCurrentReferenceBookRef = (ref: HTMLDivElement | null) => {
    if (!handlers.onSelectRef || !isChosenSelectedItem || !ref) return

    handlers.onSelectRef(ref)
  }

  //Выбираем значения для рендера
  const currentMapItems = referenceBookItem.children || currentReferenceBook.referenceBooks
  const nextInitialPaddingLeft = initialPadding + stepOfPadding

  const trimmedSearchPattern = state.searchPattern.trim() || ''

  return (
    <>
      <div
        ref={bindCurrentReferenceBookRef}
        data-id={referenceBookItem.id}
        role="presentation"
        className={cn(
          styles.referenceBookItem,
          {
            [styles['referenceBookItem__chosen']]: isChosenSelectedItem,
          },
          className,
        )}
        onClick={preparedHandleExpandedClick}
        {...rest}
      >
        <ProportionalBox width={5} height={6} className={styles.referenceBookItem__proportional}>
          {currentIsList && (
            <Icon
              src={groupIcon}
              size="initial"
              wrapper="span"
              className={cn(styles.referenceBookItem__icon, {
                [styles['referenceBookItem__icon--expanded']]: isExpanded,
              })}
            />
          )}
        </ProportionalBox>
        <span className={styles.referenceBookItem__code}>
          <Highlighted highlight={trimmedSearchPattern}>{referenceBookItem.code || ''}</Highlighted>
        </span>
        <span className={styles.referenceBookItem__codeName}>
          <Highlighted highlight={trimmedSearchPattern}>{referenceBookItem.name}</Highlighted>
        </span>
      </div>

      {recursionCondition &&
        currentMapItems?.map((nestedReferenceBookItem) => (
          <HierarchyReferenceBookItem
            key={nestedReferenceBookItem.id}
            referenceBookItem={nestedReferenceBookItem}
            style={{ paddingLeft: nextInitialPaddingLeft }}
            initialPadding={nextInitialPaddingLeft}
            onReferenceBookItemChange={onReferenceBookItemChange}
          />
        ))}

      {recursionCondition && !currentMapItems && (
        <div className={styles.referenceBookItem} style={{ paddingLeft: nextInitialPaddingLeft }}>
          <SkeletonText
            animate
            visible
            className={cn(styles.skeleton__code, styles.referenceBookItem__code)}
          />
          <SkeletonText
            animate
            visible
            className={cn(styles.skeleton__name, styles.referenceBookItem__codeName)}
          />
        </div>
      )}
    </>
  )
}

export default memo(HierarchyReferenceBookItem)
