import { useMemo } from 'react'

import { useAAChangingVolumeReimbursementControl } from '@components/Forms/AAChangingVolumeReimbursementForm'
import { useAAConnectionReorganizationImplementingControl } from '@components/Forms/AAConnectionReorganizationImplementingForm'
import { useAAgreementAccessionMunicipalControl } from '@components/Forms/AAgreementAccessionMunicipalForm'
import { useAAgreementForceCircumstancesControl } from '@components/Forms/AAgreementForceCircumstancesForm'
import { useAAgreementIncludeRelatedTermsControl } from '@components/Forms/AAgreementIncludeRelatedTermsForm'
import { useAAgreementOnChangingCharacteristicsOfObjectsControl } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm'
import { useAAgreementOnCreationOtherEstateObjectsControl } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAControl } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm'
import { useAATransferRightsAndObligationsControl } from '@components/Forms/AATransferRightsAndObligations'
import { useAAVolumeOfCapitalInvestmentsControl } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm'
import { useAdditionalAgreementOnTerminationFfSZPKControl } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK'
import { useApplicationConclusionAdditionalAgreementFormControl } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm'
import { useApplicationInclusionOfAARegisterSZPKControl } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK'
import { useApplicationOfConclusionAgreementSZPKRegisterControl } from '@components/Forms/ApplicationOfConclusionAgreementSZPKForm'
import { useAssignmentMonetaryOrPledgeAControl } from '@components/Forms/AssignmentMonetaryOrPledgeAForm'
import { useBeneficiaryInformationControl } from '@components/Forms/BeneficiaryInformationForm'
import { useBeneficiaryOfSuccessorOrganizationInformationControl } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation'
import { useCertificateApplicationConclusionOfAARequirementsControl } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm'
import { useCertificateOfComplianceContractForRecognitionConcludedAControl } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms'
import { useCertificateOfImplementationInvestprojectStagesControl } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm'
import { useCertificateOnMonitoringResultsControl } from '@components/Forms/CertificateOnMonitoringResultsForm'
import { useCreateStatementControl } from '@components/Forms/CreateStatement'
import { useDataOnFulfillmentOfTermsSZPKControl } from '@components/Forms/DataOnFulfillmentOfTermsSZPK'
import { useFinancialModelControl } from '@components/Forms/FinancialModelForm'
import { useInclusionAgreementOfRelatedSZPKRegisterControl } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm'
import { useIndicatorsOfUnfulfilledAgreementReportControl } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport'
import { useInformationOfExecutionAgreementEventsControl } from '@components/Forms/InformationOfExecutionAgreementEventsForm'
import { useInformationOfExecutionConditionsSZPKControl } from '@components/Forms/InformationOfExecutionConditionsSZPKForm'
import { useInfrFacilitiesCostsExpectedReimbursedControl } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm'
import { useNoticeIntentionTerminateSZPKControl } from '@components/Forms/NoticeIntentionTerminateSZPKForm'
import { useNoticeOfProjectRefuseConcludeAAControl } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm'
import { useNoticeOfProjectRejectConcludeSZPKControl } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm'
import { useNoticeOfRefuseConcludeAAControl } from '@components/Forms/NoticeOfRefuseConcludeAAForm'
import { useNoticeOfRejectConcludeSZPKControl } from '@components/Forms/NoticeOfRejectConcludeSZPKForm'
import { useNoticeOneSideRejectSZPKControl } from '@components/Forms/NoticeOneSideRejectSZPKForm'
import { useNotificationDrawalAppConclusionSZPKFormControl } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm'
import { useNotificationFillingAppConcludingControl } from '@components/Forms/NotificationFillingAppConcludingForm'
import { useNotificationOfChangeDocumentsControl } from '@components/Forms/NotificationOfChangeDocumentsForm'
import { useNotificationOfChangesInDetailsOfPartiesControl } from '@components/Forms/NotificationOfChangesInDetailsOfParties'
import { useNotificationOfIdentifiedNPAListErrorsFormControl } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm'
import { useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAControl } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm'
import { useNotificationOfIdentifiedViolationsControl } from '@components/Forms/NotificationOfIdentifiedViolationsForm'
import { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyControl } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm'
import { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyControl } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm'
import { useParametersApplicationReimbursementExpensesControl } from '@components/Forms/ParametersApplicationReimbursementExpensesForm'
import { useParametersOfCostRecoveryApplicationControl } from '@components/Forms/ParametersOfCostRecoveryApplicationForm'
import { usePetitionRecognizePreviouslyConcludedContractAsBoundControl } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound'
import { usePetitionTransferRightControl } from '@components/Forms/PetitionTransferRightForm'
import { useProjectSZPKControl } from '@components/Forms/ProjectSZPKForm'
import { useRegistrationCertificateControl } from '@components/Forms/RegistrationCertificateForm'
import { useStatementAlreadyInvestmentsControl } from '@components/Forms/StatementAlreadyInvestments'
import { useStatementByHeadOfMunicipalityControl } from '@components/Forms/StatementByHeadOfMunicipality'
import { useStatementConsiderationInvestmentAuthorizedControl } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm'
import { useSummaryReportOfMonitoringResultsControl } from '@components/Forms/SummaryReportOfMonitoringResultsForm'
import { useSupplementalCostSharingAControl } from '@components/Forms/SupplementalCostSharingAForm'
import { useAgreementStabilizationNegotiationsControl } from '@components/Forms/АgreementStabilizationNegotiationsForm'
import { WebFormTypes } from '@constants/types'

interface UseWebFormControlProps {
  documentType: string
}

const useWebFormControl = ({ documentType }: UseWebFormControlProps) => {
  const { openStatementModal } = useCreateStatementControl()
  const { openProjectSZPKModal } = useProjectSZPKControl()
  const { openStatementAlreadyInvestmentsModal } = useStatementAlreadyInvestmentsControl()
  const { openBeneficiaryInformationModal } = useBeneficiaryInformationControl()
  const { openFinancialModelModal } = useFinancialModelControl()
  const { openInfrFacilitiesCostsExpectedReimbursedModal } =
    useInfrFacilitiesCostsExpectedReimbursedControl()
  const { openAAgreementOnChangingCharacteristicsOfObjectsModal } =
    useAAgreementOnChangingCharacteristicsOfObjectsControl()

  const { openAAgreementOnCreationOtherEstateObjectsModal } =
    useAAgreementOnCreationOtherEstateObjectsControl()
  const { openAATransferRightsAndObligationsModal } = useAATransferRightsAndObligationsControl()
  const { openAAChangingVolumeReimbursementModal } = useAAChangingVolumeReimbursementControl()
  const { openAAVolumeOfCapitalInvestmentsModal } = useAAVolumeOfCapitalInvestmentsControl()
  const { openAgreementStabilizationNegotiationsModal } =
    useAgreementStabilizationNegotiationsControl()
  const { openBeneficiaryOfSuccessorOrganizationInformationModal } =
    useBeneficiaryOfSuccessorOrganizationInformationControl()
  const { openAAgreementForceCircumstancesModal } = useAAgreementForceCircumstancesControl()
  const { openAAgreementAccessionMunicipalModal } = useAAgreementAccessionMunicipalControl()
  const { openStatementConsiderationInvestmentAuthorizedModal } =
    useStatementConsiderationInvestmentAuthorizedControl()
  const { openStatementByHeadOfMunicipalityModal } = useStatementByHeadOfMunicipalityControl()
  const { openNoticeOfRejectConcludeSZPKModal } = useNoticeOfRejectConcludeSZPKControl()
  const { openNoticeOfProjectRejectConcludeSZPKModal } =
    useNoticeOfProjectRejectConcludeSZPKControl()
  const { openNotificationOfIdentifiedViolationsStatementModal } =
    useNotificationOfIdentifiedViolationsControl()
  const { openInclusionAgreementOfRelatedSZPKRegisterModal } =
    useInclusionAgreementOfRelatedSZPKRegisterControl()
  const { openNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAModal } =
    useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAControl()
  const {
    openNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyModal,
  } =
    useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyControl()
  const { openNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyModal } =
    useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyControl()
  const { openCertificateOfComplianceContractForRecognitionConcludedAModal } =
    useCertificateOfComplianceContractForRecognitionConcludedAControl()

  const { openCertificateApplicationConclusionOfAARequirementsModal } =
    useCertificateApplicationConclusionOfAARequirementsControl()

  const { openSupplementalCostSharingAModal } = useSupplementalCostSharingAControl()

  const { openNotificationFillingAppConcludingModal } = useNotificationFillingAppConcludingControl()
  const { openNoticeOneSideRejectSZPKModal } = useNoticeOneSideRejectSZPKControl()

  const { openNoticeIntentionTerminateSZPKModal } = useNoticeIntentionTerminateSZPKControl()

  const { openAdditionalAgreementOnTerminationFfSZPKModal } =
    useAdditionalAgreementOnTerminationFfSZPKControl()

  const { openPetitionTransferRightModal } = usePetitionTransferRightControl()

  const { openAAgreementOnNonConclusionOrNonPerformanceOfConcessionAModal } =
    useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAControl()

  const { openNoticeOfRefuseConcludeAAModal } = useNoticeOfRefuseConcludeAAControl()
  const { openNoticeOfProjectRefuseConcludeAAModal } = useNoticeOfProjectRefuseConcludeAAControl()

  const { openCertificateOnMonitoringResultsModal } = useCertificateOnMonitoringResultsControl()

  const { openSummaryReportOfMonitoringResultsModal } = useSummaryReportOfMonitoringResultsControl()

  const { openInformationOfExecutionConditionsSZPKModal } =
    useInformationOfExecutionConditionsSZPKControl()

  const { openApplicationConclusionAdditionalAgreementModal } =
    useApplicationConclusionAdditionalAgreementFormControl()

  const { openNotificationDrawalAppConclusionSZPKModal } =
    useNotificationDrawalAppConclusionSZPKFormControl()

  const { openAAConnectionReorganizationImplementingModal } =
    useAAConnectionReorganizationImplementingControl()

  const { openAAgreementIncludeRelatedTermsModal } = useAAgreementIncludeRelatedTermsControl()

  const { openNotificationOfChangeDocumentsModal } = useNotificationOfChangeDocumentsControl()
  const { openPetitionRecognizePreviouslyConcludedContractAsBoundModal } =
    usePetitionRecognizePreviouslyConcludedContractAsBoundControl()

  const { openApplicationOfConclusionAgreementSZPKRegisterModal } =
    useApplicationOfConclusionAgreementSZPKRegisterControl()

  const { openRegistrationCertificateModal } = useRegistrationCertificateControl()

  const { openApplicationInclusionOfAARegisterSZPKModal } =
    useApplicationInclusionOfAARegisterSZPKControl()

  const { openAssignmentMonetaryOrPledgeAModal } = useAssignmentMonetaryOrPledgeAControl()
  const { openParametersApplicationReimbursementExpensesModal } =
    useParametersApplicationReimbursementExpensesControl()

  const { openParametersOfCostRecoveryApplicationModal } =
    useParametersOfCostRecoveryApplicationControl()

  const { openDataOnFulfillmentOfTermsSZPKModal } = useDataOnFulfillmentOfTermsSZPKControl()
  const { openNotificationOfIdentifiedNPAListErrorsModal } =
    useNotificationOfIdentifiedNPAListErrorsFormControl()

  const { openIndicatorsOfUnfulfilledAgreementReportModal } =
    useIndicatorsOfUnfulfilledAgreementReportControl()

  const { openInformationOfExecutionAgreementEventsModal } =
    useInformationOfExecutionAgreementEventsControl()
  const { openCertificateOfImplementationInvestprojectStagesModal } =
    useCertificateOfImplementationInvestprojectStagesControl()

  const { openNotificationOfChangesInDetailsOfPartiesModal } =
    useNotificationOfChangesInDetailsOfPartiesControl()

  const webFormOpenHandlersMap = useMemo(
    () => ({
      [WebFormTypes.STATEMENT]: openStatementModal,
      [WebFormTypes.PROJECT]: openProjectSZPKModal,
      [WebFormTypes.INVESTMENTS_MADE_STATEMENT]: openStatementAlreadyInvestmentsModal,
      [WebFormTypes.BENEFICIAL_OWNERS_APPLICANT]: openBeneficiaryInformationModal,
      [WebFormTypes.INVESTPROJECT_FINANCIAL_MODEL_PROPERTIES]: openFinancialModelModal,
      [WebFormTypes.REIMBURSABLE_INFRASTRUCTURE_LIST]:
        openInfrFacilitiesCostsExpectedReimbursedModal,

      // Второй этап
      [WebFormTypes.PROJECT_SUPPORTING_INFRASTRUCTURE]:
        openAAgreementOnCreationOtherEstateObjectsModal,
      [WebFormTypes.PROJECT_RIGHTS_TRANSFER]: openAATransferRightsAndObligationsModal,
      [WebFormTypes.PROJECT_REIMBURSEMENT_CHANGE]: openAAChangingVolumeReimbursementModal,
      [WebFormTypes.PROJECT_INVESTMENT_CHANGE]: openAAVolumeOfCapitalInvestmentsModal,
      [WebFormTypes.PROJECT_TERM_EXTENSION]: openAgreementStabilizationNegotiationsModal,
      [WebFormTypes.PROJECT_CONSTRUCTION_CHANGE]:
        openAAgreementOnChangingCharacteristicsOfObjectsModal,
      [WebFormTypes.BENEFICIAL_OWNERS_APPLICANT_PARTNER]:
        openBeneficiaryOfSuccessorOrganizationInformationModal,
      [WebFormTypes.PROJECT_FORCE_MAJEURE]: openAAgreementForceCircumstancesModal,
      [WebFormTypes.PROJECT_MUNICIPAL_ACCESSION]: openAAgreementAccessionMunicipalModal,
      [WebFormTypes.INVESTPROJECT_STATEMENT]: openStatementConsiderationInvestmentAuthorizedModal,
      [WebFormTypes.MUNICIPALITY_STATEMENT]: openStatementByHeadOfMunicipalityModal,

      // Третий этап
      [WebFormTypes.UO_RETURN_CONCLUSION]: openNotificationOfIdentifiedViolationsStatementModal,
      [WebFormTypes.RETURN_CONCLUSION]: openNotificationOfIdentifiedViolationsStatementModal,
      [WebFormTypes.RESULT_DENY]: openNoticeOfRejectConcludeSZPKModal,
      [WebFormTypes.PROJECT_RESULT_DENY]: openNoticeOfProjectRejectConcludeSZPKModal,
      [WebFormTypes.STATEMENT_RELATED_CONTRACT_INCLUSION]:
        openInclusionAgreementOfRelatedSZPKRegisterModal,
      [WebFormTypes.RESULT_RETURN_RELATED_CONTRACT]:
        openNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAModal,
      [WebFormTypes.RESULT_DENY_RELATED_CONTRACT]:
        openNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyModal,
      [WebFormTypes.PROJECT_RESULT_DENY_RELATED_CONTRACT]:
        openNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyModal,
      [WebFormTypes.RESULT_APPROVE_RELATED_CONTRACT]:
        openCertificateOfComplianceContractForRecognitionConcludedAModal,
      [WebFormTypes.PROJECT_DISTRIBUTION_EXPEND]: openSupplementalCostSharingAModal,
      [WebFormTypes.RESULT_APPROVE_EXTRA]:
        openCertificateApplicationConclusionOfAARequirementsModal,
      [WebFormTypes.SZPK_TERMINATE_ONE_SIDE_NOTICE]: openNoticeOneSideRejectSZPKModal,
      [WebFormTypes.UO_RETURN_CONCLUSION_EXTRA]: openNotificationFillingAppConcludingModal,
      [WebFormTypes.TERMINATION_NOTICE]: openNoticeIntentionTerminateSZPKModal,
      [WebFormTypes.PROJECT_SZPK_TERMINATION]: openAdditionalAgreementOnTerminationFfSZPKModal,
      [WebFormTypes.SOLICITATION_RIGHTS_TRANSFER]: openPetitionTransferRightModal,
      [WebFormTypes.RESULT_DENY_EXTRA]: openNoticeOfRefuseConcludeAAModal,
      [WebFormTypes.PROJECT_RESULT_DENY_EXTRA]: openNoticeOfProjectRefuseConcludeAAModal,
      [WebFormTypes.PROJECT_CONCESSION_FAIL]:
        openAAgreementOnNonConclusionOrNonPerformanceOfConcessionAModal,
      [WebFormTypes.STATEMENT_EXTRA]: openApplicationConclusionAdditionalAgreementModal,
      [WebFormTypes.SZPK_REVOKE]: openNotificationDrawalAppConclusionSZPKModal,
      [WebFormTypes.PROJECT_ORG_REORGANIZATION]: openAAConnectionReorganizationImplementingModal,
      [WebFormTypes.PROJECT_RELATED_CONTRACT]: openAAgreementIncludeRelatedTermsModal,
      [WebFormTypes.CHANGES_NOTICE]: openNotificationOfChangeDocumentsModal,
      [WebFormTypes.SOLICITATION_RELATED_CONTRACT]:
        openPetitionRecognizePreviouslyConcludedContractAsBoundModal,
      [WebFormTypes.STATEMENT_RELATED_CONTRACT]:
        openApplicationOfConclusionAgreementSZPKRegisterModal,
      [WebFormTypes.STATEMENT_EXTRA_REGISTER]: openApplicationInclusionOfAARegisterSZPKModal,
      [WebFormTypes.CESSION_NOTICE]: openAssignmentMonetaryOrPledgeAModal,
      [WebFormTypes.NPA_RETURNING_NOTICE]: openNotificationOfIdentifiedNPAListErrorsModal,
      [WebFormTypes.ORGANIZATION_INFO_CHANGES]: openNotificationOfChangesInDetailsOfPartiesModal,

      // Мониторинг
      [WebFormTypes.MONITORING_REPORT_FEDERAL]: openSummaryReportOfMonitoringResultsModal,
      [WebFormTypes.MONITORING_REPORT_REGIONAL]: openDataOnFulfillmentOfTermsSZPKModal,
      [WebFormTypes.MONITORING_REPORT_SZPK]: openInformationOfExecutionConditionsSZPKModal,
      [WebFormTypes.MONITORING_REPORT_SZPK_APPROVE]: openCertificateOnMonitoringResultsModal,
      [WebFormTypes.MONITORING_REPORT_SZPK_INDICATORS]:
        openIndicatorsOfUnfulfilledAgreementReportModal,
      [WebFormTypes.MONITORING_REPORT_SZPK_EVENTS]: openInformationOfExecutionAgreementEventsModal,
      [WebFormTypes.MONITORING_REPORT_SZPK_STAGE_REFERENCE]:
        openCertificateOfImplementationInvestprojectStagesModal,

      [WebFormTypes.REGISTRATION_DOCUMENT]: openRegistrationCertificateModal,

      //Возмещение затрат
      [WebFormTypes.CLAIM_PROPERTIES]: openParametersOfCostRecoveryApplicationModal,
      [WebFormTypes.COSTS_REFUND_FACTS]: openParametersApplicationReimbursementExpensesModal,
    }),
    [],
  )

  return {
    currentOpenWebFormHandler: webFormOpenHandlersMap[documentType],
  }
}

export { useWebFormControl }
