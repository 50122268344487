import { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm'
import {
  defaultNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues,
  mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu,
} from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/const'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyLayoutWrapper from './Wrapper'

const NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyLayout: FC<
  FormModalLayoutProps
> = ({ projectId, formId, isOpen, onClose, initialErrorsFromViolations, editMode }) => {
  const formInstance =
    useFormWithDefaultProps<NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues>(
      {
        defaultValues:
          defaultNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues,
      },
    )

  const handleOnClose = () => {
    NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={
              mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu[0]
                .id
            }
          >
            <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление о невозможности удовлетворения ходатайства о признании ранее
                  заключенного договора связанным договором
                </Typography.Headline>
                <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--xl'])}
              >
                <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny.Menu
                  className={styles.layout__menu}
                />
              </div>
            </NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyLayoutWrapper>
          </FlatMenuManager>
        </NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyLayout,
)
