import { FolderFileItem } from '@components/Attachments/Attachment/FolderItem/types'
import { defaultMessage } from '@constants/texts'
import { isAxiosError, isFunction, isNullOrUndefined } from '@helpers/checkTypes'

import { multipleItemReducerActionMap } from './constants'
import { MultipleItemService } from './helpers'
import type {
  MultipleFileItem,
  MultipleFolderItem,
  MultipleItemAction,
  MultipleItemReducerActionCreators,
  MultipleItemReducerState,
} from './types'

const initialMultipleItemState: MultipleItemReducerState = {
  items: [],
}

const {
  replaceFileInFolderAndReturnAllState,
  replaceFileWithoutFolderAndReturnState,
  replaceFolderStateAndReturnState,
  getMultipleItemIndexById,
} = MultipleItemService

// TODO: Добавить экшен ADD_REJECTED_FILE
const multipleItemReducer = (
  state: MultipleItemReducerState,
  action: MultipleItemAction,
): MultipleItemReducerState => {
  const { type, payload } = action

  switch (type) {
    case multipleItemReducerActionMap.SETUP_FILE_LOADING:
      const folderIdWithFile = payload.folderId

      if (folderIdWithFile) {
        const replacedState = replaceFileInFolderAndReturnAllState({
          fileId: payload.fileId,
          fileFolderItem: {
            isLoading: payload.isLoading,
          },
          folderId: folderIdWithFile,
          itemsReducerState: state.items,
        })

        return {
          items: replacedState,
        }
      }

      const replacedByFileState = replaceFileWithoutFolderAndReturnState({
        fileId: payload.fileId,
        fileState: {
          isLoading: payload.isLoading,
        },
        itemsReducerState: state.items,
      })

      return {
        items: replacedByFileState,
      }

    case multipleItemReducerActionMap.SETUP_FILE_ERROR:
      const folderIdWithSetupFile = payload.folderId

      const errorMessage: string = !isAxiosError(payload.error)
        ? defaultMessage
        : payload.error.response?.data.detail || defaultMessage

      if (folderIdWithSetupFile) {
        const replacedState = replaceFileInFolderAndReturnAllState({
          fileId: payload.fileId,
          fileFolderItem: {
            errorMessage,
          },
          folderId: folderIdWithSetupFile,
          itemsReducerState: state.items,
        })

        return {
          items: replacedState,
        }
      }

      const replacedItemsState = replaceFileWithoutFolderAndReturnState({
        fileId: payload.fileId,
        fileState: {
          errorMessage,
        },
        itemsReducerState: state.items,
      })

      return {
        items: replacedItemsState,
      }

    case multipleItemReducerActionMap.ADD_FILE:
      const fileWillBeAddToFolder = payload.folderId

      const fileToAdd: MultipleFileItem = payload.fileState

      if (fileWillBeAddToFolder) {
        const folderIndexToModify = getMultipleItemIndexById(fileWillBeAddToFolder, state.items)

        if (folderIndexToModify === -1) return state

        const fileToFolderAdd: FolderFileItem = {
          id: payload.fileState.id,
          fileState: {
            file: payload.fileState.file,
            isDeletedFile: payload.fileState.isDeletedFile,
            rejectedFile: null,
            fileSize: payload.fileState.fileSize,
            errorMessage: payload.fileState.errorMessage,
            isLoading: payload.fileState.isLoading,
          },
        }

        const folderToModify = state.items[folderIndexToModify] as MultipleFolderItem

        const folderToReplace: MultipleFolderItem = {
          ...folderToModify,
          files: [...(folderToModify.files || []), fileToFolderAdd],
        }

        return {
          items: [
            ...state.items.slice(0, folderIndexToModify),
            folderToReplace,
            ...state.items.slice(folderIndexToModify + 1, state.items.length),
          ],
        }
      }

      return {
        items: [...state.items, fileToAdd],
      }

    case multipleItemReducerActionMap.ADD_FOLDER:
      const filesToReplaceInFolder = payload.folder.files

      if (!isNullOrUndefined(filesToReplaceInFolder) && filesToReplaceInFolder.length) {
        const idsOfRemoveItem = filesToReplaceInFolder.map((item) => item.id)
        const newItemsState = state.items.filter((item) => {
          !idsOfRemoveItem.includes(item.id)
        })

        return {
          items: [...newItemsState, payload.folder],
        }
      }

      return {
        items: [...state.items, payload.folder],
      }

    case multipleItemReducerActionMap.INSERT_ITEM:
      if (payload.type === 'file') {
        const folderIdIsInsertToFolderFile = payload.folderId

        if (folderIdIsInsertToFolderFile) {
          const stateWithInsertedFile = replaceFolderStateAndReturnState({
            folderId: folderIdIsInsertToFolderFile,
            newFolderState: (prevFolderState) => {
              if (!prevFolderState.files) return prevFolderState

              const fileToApply: FolderFileItem = {
                id: payload.file.id,
                fileState: {
                  file: payload.file.file,
                  isDeletedFile: payload.file.isDeletedFile,
                  rejectedFile: null,
                  fileSize: payload.file.fileSize,
                  isLoading: payload.file.isLoading,
                  errorMessage: payload.file.errorMessage,
                },
              }

              return {
                files: [
                  ...prevFolderState.files.slice(0, payload.indexOfInsert),
                  fileToApply,
                  ...prevFolderState.files.slice(payload.indexOfInsert),
                ],
              }
            },
            itemsReducerState: state.items,
          })

          return {
            items: stateWithInsertedFile,
          }
        }

        return {
          items: [
            ...state.items.slice(0, payload.indexOfInsert),
            payload.file,
            ...state.items?.slice(payload.indexOfInsert),
          ],
        }
      }

      return {
        items: [
          ...state.items.slice(0, payload.indexOfInsert),
          payload.folder,
          ...state.items?.slice(payload.indexOfInsert),
        ],
      }
    case multipleItemReducerActionMap.REPLACE_ITEM:
      //3 cлучая - файл без папки, файл в папке, папка

      if (payload.type === 'folder') {
        const folderChangedState = replaceFolderStateAndReturnState({
          folderId: payload.folderId,
          newFolderState: payload.newFolderState,
          itemsReducerState: state.items,
        })
        return {
          items: folderChangedState,
        }
      }

      if (payload.fileId && payload.folderId) {
        const newState = replaceFileInFolderAndReturnAllState({
          fileId: payload.fileId,
          folderId: payload.folderId,
          fileFolderItem: payload.newFileState,
          itemsReducerState: state.items,
        })

        return {
          items: newState,
        }
      }

      const updatedStateFromFile = replaceFileWithoutFolderAndReturnState({
        fileId: payload.fileId,
        fileState: payload.newFileState,
        itemsReducerState: state.items,
      })

      return {
        items: updatedStateFromFile,
      }

    case multipleItemReducerActionMap.REMOVE_FILE:
      const folderIdByRemoveFrom = payload.folderId

      if (folderIdByRemoveFrom) {
        const folderIndexById = getMultipleItemIndexById(folderIdByRemoveFrom, state.items)

        if (folderIndexById === -1) return state

        const folderById = state.items[folderIndexById] as MultipleFolderItem

        const folderToReplace: MultipleFolderItem = {
          ...folderById,
          files: [
            ...(folderById.files || []).filter((folderItem) => folderItem.id !== payload.fileId),
          ],
        }

        return {
          items: [
            ...state.items.slice(0, folderIndexById),
            folderToReplace,
            ...state.items.slice(folderIndexById + 1, state.items.length),
          ],
        }
      }

      return {
        items: [...state.items.filter((item) => item.id !== payload.fileId)],
      }

    case multipleItemReducerActionMap.REMOVE_FOLDER:
      return {
        items: [...state.items.filter((item) => item.id !== payload.folderId)],
      }

    case multipleItemReducerActionMap.SETUP_FOLDER_NAME:
      const newStateByRenameFolder = replaceFolderStateAndReturnState({
        folderId: payload.folderId,
        newFolderState: {
          folderName: payload.newName,
        },
        itemsReducerState: state.items,
      })

      return {
        items: newStateByRenameFolder,
      }

    case multipleItemReducerActionMap.SETUP_FOLD_STATE:
      const newStateByFoldFolder = replaceFolderStateAndReturnState({
        folderId: payload.folderId,
        newFolderState: (prevFolderState) => {
          return {
            ...prevFolderState,
            isFold: isFunction(payload.isFold)
              ? payload.isFold(prevFolderState.isFold)
              : payload.isFold,
          }
        },
        itemsReducerState: state.items,
      })

      return {
        items: newStateByFoldFolder,
      }

    default:
      return state
  }
}

const multipleItemCreators: MultipleItemReducerActionCreators = {
  addFile: (payload) => ({ type: multipleItemReducerActionMap.ADD_FILE, payload }),
  insertItem: (payload) => ({ type: multipleItemReducerActionMap.INSERT_ITEM, payload }),
  removeFile: (payload) => ({
    type: multipleItemReducerActionMap.REMOVE_FILE,
    payload,
  }),
  replaceItem: (payload) => ({ type: multipleItemReducerActionMap.REPLACE_ITEM, payload }),
  setFileLoading: (payload) => ({
    type: multipleItemReducerActionMap.SETUP_FILE_LOADING,
    payload,
  }),
  setFileError: (payload) => ({
    type: multipleItemReducerActionMap.SETUP_FILE_ERROR,
    payload,
  }),
  addFolder: (payload) => ({
    type: multipleItemReducerActionMap.ADD_FOLDER,
    payload,
  }),
  removeFolder: (payload) => ({
    type: multipleItemReducerActionMap.REMOVE_FOLDER,
    payload,
  }),
  setFolderName: (payload) => ({
    type: multipleItemReducerActionMap.SETUP_FOLDER_NAME,
    payload,
  }),
  setFoldFolderState: (payload) => ({
    type: multipleItemReducerActionMap.SETUP_FOLD_STATE,
    payload,
  }),
}

export { initialMultipleItemState, multipleItemCreators, multipleItemReducer }
