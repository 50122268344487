import { useEffect, useState } from 'react'

import { switchBetweenSetsViewTabsIndexesByName } from '../constants'
import { Tab } from '../model'

export const useTabs = (documentsSetsDict, mapOfSetsToRender, isDocumentForRevision) => {
  const isFirstSetToRevision = Boolean(
    mapOfSetsToRender && isDocumentForRevision(mapOfSetsToRender[0]),
  )

  const [activeTab, setActiveTab] = useState<Tab>(switchBetweenSetsViewTabsIndexesByName.versions)

  useEffect(() => {
    if (!mapOfSetsToRender || !documentsSetsDict) return

    /* если первый набор имеет documentSetForRevision = true – открыть вкладку "Вносимые изменения" */
    setActiveTab(
      isFirstSetToRevision
        ? switchBetweenSetsViewTabsIndexesByName.changesMade
        : switchBetweenSetsViewTabsIndexesByName.versions,
    )
  }, [documentsSetsDict, isFirstSetToRevision, mapOfSetsToRender])

  const handleClickVersionTab = (e) => {
    if (activeTab !== switchBetweenSetsViewTabsIndexesByName.versions) e.stopPropagation()

    return setActiveTab(switchBetweenSetsViewTabsIndexesByName.versions)
  }

  const handleClickChangesMadeTab = () => {
    setActiveTab(switchBetweenSetsViewTabsIndexesByName.changesMade)
  }

  const isChangesMadeTab = activeTab === switchBetweenSetsViewTabsIndexesByName.changesMade
  const isVersionsMadeTab = activeTab === switchBetweenSetsViewTabsIndexesByName.versions

  return {
    activeTab,
    handleChangeTab: setActiveTab,
    handleClickVersionTab,
    handleClickChangesMadeTab,
    isChangesMadeTab,
    isVersionsMadeTab,
  }
}
