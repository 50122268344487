import React, { memo } from 'react'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { blockValues } from '@components/Forms/CreateStatementOld/const'
import formStyles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import { generalInfoValidation } from '@components/Forms/CreateStatementOld/Forms/GeneralInfo/validation'
import StepProjectArrayForm from '@components/Forms/CreateStatementOld/Forms/Stages/StageBlock'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './Stages.module.scss'

const { validationAdapter } = DocumentFormHelpers

const StagesBlockForm = () => {
  const {
    state: { formInstance, blockViewIsValidating },
  } = useCreateStatementManager()

  if (!formInstance) return null

  return (
    <div className={styles.stages}>
      <div className={styles['stages__dates-block']}>
        <div className={styles.stages__dates}>
          <div className={cn(formStyles.form__item, styles.stages__date)}>
            <ControlledCalendarInput
              control={formInstance.control}
              name={blockValues.stages.startDate}
              calendarInputProps={{
                label: 'Дата начала проекта',
                dataTestId: `StagesBlockForm-${blockValues.stages.startDate}-calendarInput`,
              }}
              rules={validationAdapter(generalInfoValidation.startDate, {
                form: formInstance,
                name: blockValues.stages.startDate,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={cn(formStyles.form__item, styles.stages__date)}>
            <ControlledCalendarInput
              control={formInstance.control}
              name={blockValues.stages.projectEndDate}
              calendarInputProps={{
                label: 'Дата окончания проекта',
                dataTestId: `StagesBlockForm-${blockValues.stages.projectEndDate}-calendarInput`,
              }}
              rules={validationAdapter(generalInfoValidation.projectEndDate, {
                form: formInstance,
                name: blockValues.stages.projectEndDate,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
        </div>

        <div className={cn(styles['stages__dates-statement'])}>
          <Typography.Body variant={'bodyMRegular'}>
            Планируемая дата окончания соглашения
          </Typography.Body>
          <ControlledCalendarInput
            control={formInstance.control}
            name={blockValues.stages.szpkEndDate}
            calendarInputProps={{
              label: 'ДД.ММ.ГГГГ',
              dataTestId: `StagesBlockForm-${blockValues.stages.szpkEndDate}-calendarInput`,
            }}
            rules={validationAdapter(generalInfoValidation.szpkEndDate, {
              form: formInstance,
              name: blockValues.stages.szpkEndDate,
              needTrigger: blockViewIsValidating,
            })}
          />
        </div>
      </div>

      <div className={styles.stages__items}>
        <StepProjectArrayForm />
      </div>
    </div>
  )
}

export default memo(StagesBlockForm)
