import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import NestedMenuItem from '@components/DocumentFormComponents/NestedMenu/Item'
import NestedMenuList, {
  NestedMenuListProps,
} from '@components/DocumentFormComponents/NestedMenu/List'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import Typography from '@components/NewDesign/Typography'
import CustomTransition from '@components/Transition'
import cn from 'classnames'

import styles from './NestedMenu.module.scss'

interface DocumentFormMenuProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  blockViewIsValidating: boolean
  lastUpdateDraftTime?: string
  nestedMapOfMenu: NestedMapOfMenu[]
  currentSearchPattern?: string
  activeMenuId?: string
  menuItemClassName?: string
  listProps?: NestedMenuListProps['listProps']
  onMenuClick: (menuItem: NestedMapOfMenu) => Promise<void>
  getInitialExpandedCondition?: (menuItemId: string) => boolean
}

const DocumentFormNestedMenu: FC<DocumentFormMenuProps> = ({
  blockViewIsValidating,
  currentSearchPattern,
  nestedMapOfMenu,
  lastUpdateDraftTime,
  activeMenuId,
  onMenuClick,
  getInitialExpandedCondition,
  menuItemClassName,
  listProps,
  ...rest
}) => {
  return (
    <div {...rest} className={cn(styles.menu, rest.className)}>
      {nestedMapOfMenu.map((item) => {
        const blockNotHaveError = !item.hasError

        if (!item.children || !item.children.length) {
          return (
            <NestedMenuItem
              key={item.id}
              item={item}
              className={cn(styles.menu__item, menuItemClassName)}
              blockViewIsValidating={blockViewIsValidating}
              dataTestId={item.id}
              isOpen={getInitialExpandedCondition?.(item.id)}
              currentSearchPattern={currentSearchPattern}
              activeMenuId={activeMenuId}
              typographyProps={{
                variant: 'bodyMMedium',
                className: cn({
                  [styles['menu__item--error']]: item.hasError && blockViewIsValidating,
                  [styles['menu__item--success']]: blockNotHaveError && blockViewIsValidating,
                }),
              }}
              onClick={onMenuClick}
            />
          )
        }

        return (
          <NestedMenuList
            key={item.id}
            activeMenuId={activeMenuId}
            blockViewIsValidating={blockViewIsValidating}
            menuItem={item}
            currentSearchPattern={currentSearchPattern}
            getInitialExpandedCondition={getInitialExpandedCondition}
            listProps={listProps}
            onMenuItemClick={onMenuClick}
          />
        )
      })}
      <CustomTransition.Fade show={!!lastUpdateDraftTime}>
        <Typography.Body
          className={styles.menu__save}
          color={'text-base-secondary'}
          variant={'bodyMRegular'}
        >
          {`Изменения автоматически сохранены ${lastUpdateDraftTime}`}
        </Typography.Body>
      </CustomTransition.Fade>
    </div>
  )
}

export default DocumentFormNestedMenu
