import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { TGetDfoTypes } from '@services/Dictionaries/dictionaries.entity'
import useSWRImmutable from 'swr/immutable'

const useDfoTypes = ({ key, config }: SWRHookProps<TGetDfoTypes | undefined>) => {
  const {
    dfosApi: { getDfoTypes },
  } = useAPIContext()

  const {
    data: dfoTypes,
    error,
    mutate,
    isValidating: isLoadingDfoTypes,
  } = useSWRImmutable(key, getDfoTypes, config)

  return {
    dfoTypes,
    error,
    mutate,
    isLoadingDfoTypes,
  }
}

export { useDfoTypes }
