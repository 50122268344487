import { FC, memo, useCallback } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useExtendedFormModeByUserRoles } from '@components/DocumentFormComponents/hooks/useExtendedFormModeByUserRoles'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import CreateStatement from '@components/Forms/CreateStatement'
import { mapOFMenu } from '@components/Forms/CreateStatement/const'
import LayoutWrapper from '@components/Forms/CreateStatement/Layout/Wrapper'
import { CreateStatementControlUpdateWatcher } from '@components/Forms/CreateStatement/watcher'
import { Switch } from '@components/NewDesign/Switch'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

const CreateStatementLayout: FC<FormModalLayoutProps> = ({
  formId,
  projectId,
  editMode,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  rolesOfUser,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const {
    currentDebugMode,
    handleChangeDebugMode,
    conditionToActivateDebugMode,
    disabledDebugMode,
  } = useExtendedFormModeByUserRoles(rolesOfUser)

  const handleOnClose = useCallback(() => {
    disabledDebugMode()
    CreateStatementControlUpdateWatcher.resetAllState()

    onClose?.()
  }, [disabledDebugMode, onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <CreateStatement.Manager
        editMode={editMode}
        formId={formId}
        projectId={projectId}
        rolesOfUser={rolesOfUser}
        initialErrorsFromViolations={initialErrorsFromViolations}
        onClose={handleOnClose}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
      >
        <FlatMenuManager initialCurrentAnchorId={mapOFMenu[0].id}>
          <LayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline variant={'headlineH1'} align={'center'} as={'h1'}>
                Заявление о заключении СЗПК
              </Typography.Headline>
              <CreateStatement.StatementForm className={styles.layout__form} />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}>
              <CreateStatement.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              >
                {conditionToActivateDebugMode && (
                  <Switch
                    defaultState={currentDebugMode}
                    wrapperClassName={styles.layout__debug}
                    label={'Просмотр мета информации'}
                    onChange={handleChangeDebugMode}
                  />
                )}
              </CreateStatement.Menu>
            </div>
          </LayoutWrapper>
        </FlatMenuManager>
      </CreateStatement.Manager>
    </DocumentFormLayout>
  )
}

export default memo(CreateStatementLayout)
