import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { TechnologicalRequirementsPathName } from '@components/Forms/ProjectSZPKForm/Forms/10/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import TechnologicalRequirementsForm from './AdditionalForms/TechnologicalRequirementsForm'

const { isFormFieldError } = DocumentFormHelpers

const Tenth = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    TechnologicalRequirementsPathName
  >({
    control: formInstance?.control,
    name: projectSZPKBlockValues['10'].technologicalRequirements,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const fieldsError = formInstance.getFieldState(
    projectSZPKBlockValues['10'].technologicalRequirements,
  )?.error

  const handleAddTechnologicalRequirementsForm = async () => {
    await handleAddItemToListWithOutValue?.(projectSZPKBlockValues['10'].technologicalRequirements)

    if (!fields.length) {
      formInstance.clearErrors(projectSZPKBlockValues['10'].technologicalRequirements)
    }
  }

  const handleRemoveForm = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${projectSZPKBlockValues['10'].technologicalRequirements}.${index}`,
      projectSZPKBlockValues['10'].technologicalRequirements,
    )
  }

  return (
    <Container className={'p-0'}>
      {!editMode && !fields.length && <EmptyDataText />}
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: projectSZPKBlockValues['10'].technologicalRequirements,
          })}
        >
          <FlipperList list={fields}>
            {fields.map((field, index) => (
              <TechnologicalRequirementsForm
                key={field.id}
                id={field.id}
                control={formInstance.control}
                name={`${projectSZPKBlockValues['10'].technologicalRequirements}.${index}` as const}
                onRemoveObjectStep={index === 0 || !editMode ? undefined : handleRemoveForm(index)}
              />
            ))}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(fieldsError) && !fields.length && (
          <Row className={'px-1'}>
            <FormError rowClassName={'px-4'} colClassName={'px-2'}>
              {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
            </FormError>
          </Row>
        )}
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddTechnologicalRequirementsForm}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Tenth
