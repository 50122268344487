import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { defaultBeneficiarItemValue } from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/constants'
import {
  BeneficiarSecondNationalityCountry,
  BeneficiarStep,
} from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/types'
import { BeneficiaryOfSuccessorOrganizationInformationFormValues } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForBeneficiaryOfSuccessorOrganizationInformationForm = (
  objectFromPropertyState: Record<string, any>,
): BeneficiaryOfSuccessorOrganizationInformationFormValues => {
  const preparedBeneficiarsPartner = (() => {
    if (
      !isArray(objectFromPropertyState.beneficiarsPartner?.value) ||
      !objectFromPropertyState.beneficiarsPartner?.value.length
    )
      return []

    return objectFromPropertyState.beneficiarsPartner.value.map((beneficiar): BeneficiarStep => {
      const preparedBeneficiarSecondNationalityCountries =
        isArray(beneficiar.value?.beneficiarPartnerSecondNationalityCountries?.value) &&
        beneficiar.value?.beneficiarPartnerSecondNationalityCountries?.value.length
          ? beneficiar.value?.beneficiarPartnerSecondNationalityCountries?.value.map(
              (country): BeneficiarSecondNationalityCountry => ({
                id: country?.propertyId,
                beneficiarSecondNationalityCountry: !!country?.value?.id
                  ? {
                      value: country?.value?.id || '',
                      displayValue: country?.value?.name || '',
                    }
                  : null,
              }),
            )
          : []

      return {
        id: beneficiar.propertyId,
        beneficiarName:
          beneficiar.value.beneficiarPartnerName?.value ||
          defaultBeneficiarItemValue.beneficiarName,
        beneficiarBirthPlace:
          beneficiar.value.beneficiarPartnerBirthPlace?.value ||
          defaultBeneficiarItemValue.beneficiarBirthPlace,
        beneficiarBirthDate:
          beneficiar.value.beneficiarPartnerBirthDate?.value ||
          defaultBeneficiarItemValue.beneficiarBirthDate,
        beneficiarNationality: beneficiar.value.beneficiarPartnerNationality.value?.id
          ? {
              value: beneficiar.value.beneficiarPartnerNationality.value?.id || '',
              displayValue: beneficiar.value.beneficiarPartnerNationality.value?.name || '',
            }
          : defaultBeneficiarItemValue.beneficiarNationality,
        otherNationality:
          beneficiar.value.otherNationalityPartner?.value ||
          defaultBeneficiarItemValue.otherNationality,
        beneficiarNationalityCountry: !!beneficiar.value.beneficiarPartnerNationalityCountry.value
          ?.id
          ? {
              value: beneficiar.value.beneficiarPartnerNationalityCountry.value?.id || '',
              displayValue: beneficiar.value.beneficiarPartnerNationalityCountry.value?.name || '',
            }
          : defaultBeneficiarItemValue.beneficiarNationalityCountry,
        beneficiarSecondNationalityCountries: preparedBeneficiarSecondNationalityCountries,
        beneficiarInn:
          beneficiar.value.beneficiarPartnerInn?.value || defaultBeneficiarItemValue.beneficiarInn,
        beneficiarDocument: !!beneficiar.value.beneficiarPartnerDocument.value?.id
          ? {
              value: beneficiar.value.beneficiarPartnerDocument.value?.id,
              displayValue: beneficiar.value.beneficiarPartnerDocument.value?.name,
            }
          : defaultBeneficiarItemValue.beneficiarDocument,
        beneficiarDocumentSeries:
          beneficiar.value.beneficiarPartnerDocumentSeries?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentSeries,
        beneficiarDocumentNumber:
          beneficiar.value.beneficiarPartnerDocumentNumber?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentNumber,
        beneficiarDocumentDate:
          beneficiar.value.beneficiarPartnerDocumentDate?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentDate,
        beneficiarDocumentOrganization:
          beneficiar.value.beneficiarPartnerDocumentOrganization?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentOrganization,
        beneficiarDocumentCodeOrganization:
          beneficiar.value.beneficiarPartnerDocumentCodeOrganization?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentCodeOrganization,
        beneficiarAddress:
          beneficiar.value.beneficiarPartnerAddress?.value ||
          defaultBeneficiarItemValue.beneficiarAddress,
        beneficiarMigrationNumber:
          beneficiar.value.beneficiarPartnerMigrationNumber?.value ||
          defaultBeneficiarItemValue.beneficiarMigrationNumber,
        beneficiarMigrationStartDate:
          beneficiar.value.beneficiarPartnerMigrationStartDate?.value ||
          defaultBeneficiarItemValue.beneficiarMigrationStartDate,
        beneficiarMigrationEndDate:
          beneficiar.value.beneficiarPartnerMigrationEndDate?.value ||
          defaultBeneficiarItemValue.beneficiarMigrationEndDate,
        beneficiarResidenceStartDate:
          beneficiar.value.beneficiarPartnerResidenceStartDate?.value ||
          defaultBeneficiarItemValue.beneficiarResidenceStartDate,
        beneficiarResidenceEndDate:
          beneficiar.value.beneficiarPartnerResidenceEndDate?.value ||
          defaultBeneficiarItemValue.beneficiarResidenceEndDate,
        beneficiarResidenceNumber:
          beneficiar.value.beneficiarPartnerResidenceNumber?.value ||
          defaultBeneficiarItemValue.beneficiarResidenceNumber,
        beneficiarPart:
          beneficiar.value.beneficiarPartnerPart?.value ||
          defaultBeneficiarItemValue.beneficiarPart,
        beneficiarReason:
          beneficiar.value.beneficiarPartnerReason?.value ||
          defaultBeneficiarItemValue.beneficiarReason,
      }
    })
  })()

  return {
    '1': RHFBasicAdapters.getInvestorPartnerAdapter(objectFromPropertyState),
    '2': {
      beneficiars: preparedBeneficiarsPartner,
    },
  }
}

export { generateRHFObjectForBeneficiaryOfSuccessorOrganizationInformationForm }
