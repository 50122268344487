import { FC, memo, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useExtendedFormModeByUserRoles } from '@components/DocumentFormComponents/hooks/useExtendedFormModeByUserRoles'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import ProjectSZPK from '@components/Forms/ProjectSZPKForm'
import { getProjectMenuByStatus } from '@components/Forms/ProjectSZPKForm/Menu/const'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { Switch } from '@components/NewDesign/Switch'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import ProjectSZPKLayoutWrapper from './Wrapper'

const ProjectSZPKLayout: FC<FormModalLayoutProps> = ({
  formId,
  editMode,
  projectId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  rolesOfUser,
}) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    currentDebugMode,
    handleChangeDebugMode,
    conditionToActivateDebugMode,
    disabledDebugMode,
  } = useExtendedFormModeByUserRoles(rolesOfUser)

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const projectIsFederal = useWatch({
    name: 'additionalFields.isFederal',
    control: formInstance?.control,
  })

  const handleOnClose = useCallback(() => {
    disabledDebugMode()
    ProjectSZPKFieldsControlUpdateWatcher.resetAllState()

    onClose?.()
  }, [disabledDebugMode, onClose])

  const initialMenuState = useMemo(() => {
    return getProjectMenuByStatus(projectIsFederal)
  }, [projectIsFederal])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <ProjectSZPK.MenuManager
        key={String(projectIsFederal)}
        initialMenuHash={initialMenuState.initialProjectSZPKMenuState}
        initialSectionId={initialMenuState.initialProjectSZPKMenuState[0].children[0].id}
      >
        <ProjectSZPK.Manager
          editMode={editMode}
          projectId={projectId}
          formId={formId}
          rolesOfUser={rolesOfUser}
          initialErrorsFromViolations={initialErrorsFromViolations}
          onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
          onClose={handleOnClose}
        >
          <ProjectSZPKLayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline variant={'headlineH1'} align={'center'} as={'h1'}>
                Проект СЗПК
              </Typography.Headline>
              <ProjectSZPK.StatementForm className={styles.layout__form} />
            </div>
            <div
              className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--small'])}
            >
              <ProjectSZPK.Menu
                className={styles['layout__menu--nested']}
                lastUpdateDraftTime={lastUpdateDraftTime}
              >
                {conditionToActivateDebugMode && (
                  <Switch
                    defaultState={currentDebugMode}
                    wrapperClassName={styles.layout__debug}
                    label={'Просмотр мета информации'}
                    onChange={handleChangeDebugMode}
                  />
                )}
              </ProjectSZPK.Menu>
            </div>
          </ProjectSZPKLayoutWrapper>
        </ProjectSZPK.Manager>
      </ProjectSZPK.MenuManager>
    </DocumentFormLayout>
  )
}

export default memo(ProjectSZPKLayout)
