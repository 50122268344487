import { FileRejection } from 'react-dropzone'

export const handleDropRejected = (fileRejections: FileRejection[], callback) => {
  fileRejections.forEach(({ file, errors }) =>
    errors.forEach((e) => {
      if (e.code === 'file-invalid-type') {
        callback(file)
      }
    }),
  )
}
