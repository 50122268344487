import { useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import SimpleModal from '@components/Modals/SimpleModal'
import { isYandexBrowser } from '@constants/system'
import { getLKHeaderOrganization, messageToOtherBrowser } from '@constants/texts'

const useGovermentDomainModal = () => {
  const popupManager = usePopupManager()

  const handleOpenRedirectGovermentDomainModal = useCallback(
    (domain: string, onClose?: VoidFunction) => {
      popupManager.open(SimpleModal, {
        textHeader: getLKHeaderOrganization(),
        description: messageToOtherBrowser(),
        linkWithCopyAfterDescription: domain,
        buttonSubmitText: 'Перейти',
        href: domain,
        hideCancelButton: true,
        isCloseModalIcon: true,
        ...(!isYandexBrowser && { hideSubmitButton: true }),
        target: '_blank',
        onClose,
      })
    },
    [popupManager],
  )

  return {
    handleOpenRedirectGovermentDomainModal,
  }
}

export default useGovermentDomainModal
