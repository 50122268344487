import { ErrorHeadersTexts } from '@components/Forms/LoginForm/constants'
import {
  handleBadRequestAuthError,
  handleForbiddenAuthError,
  handleInternalServerError,
  handleUnauthorizedError,
} from '@components/Forms/LoginForm/helpers'
import { defaultMessageSupportWithLink } from '@constants/texts'
import { isNull } from '@helpers/checkTypes'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import { procurationReducerActionMap, procurationReducerComponentInfo } from './constants'
import type {
  ProcurationActionCreators,
  ProcurationReducerAction,
  ProcurationReducerState,
} from './types'

const initialProcurationState: ProcurationReducerState = {
  isLoading: false,
  isOtherProcuration: false,
  caption: '',
  error: null,
}

const procurationReducer = (
  state: ProcurationReducerState,
  action: ProcurationReducerAction,
): ProcurationReducerState => {
  const { type } = action

  switch (type) {
    case procurationReducerActionMap.SETUP_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case procurationReducerActionMap.SETUP_OTHER_PROCURATION:
      return {
        ...state,
        isOtherProcuration: action.payload,
      }

    case procurationReducerActionMap.SETUP_CAPTION:
      return {
        ...state,
        caption: action.payload,
      }

    case procurationReducerActionMap.SETUP_NATIVE_ERROR:
      const nativeError = action.payload

      return {
        ...state,
        error: {
          header: ErrorHeadersTexts.errorMessage,
          body: nativeError.message || defaultMessageSupportWithLink,
        },
      }

    case procurationReducerActionMap.SETUP_ERROR:
      if (isNull(action.payload)) {
        return {
          ...state,
          error: action.payload,
        }
      }

      const error = action.payload
      const { error_description: errorDescription, detail: errorDetail } =
        error?.response?.data ?? {}

      LoggerHelpersService.handleInternalLogError({
        componentInfo: procurationReducerComponentInfo,
      })(error)

      const internalServerError = handleInternalServerError(error)

      if (internalServerError) {
        return {
          ...state,
          error: internalServerError,
        }
      }

      const unauthorizedError = handleUnauthorizedError(error)

      if (unauthorizedError) {
        return {
          ...state,
          error: unauthorizedError,
        }
      }

      const forbiddenError = handleForbiddenAuthError(error)

      if (forbiddenError) {
        return {
          ...state,
          error: forbiddenError,
        }
      }

      const badRequestError = handleBadRequestAuthError(error)

      if (badRequestError) {
        return {
          ...state,
          error: badRequestError,
        }
      }

      return {
        ...state,
        error: {
          header: ErrorHeadersTexts.accessIdDenied,
          body: errorDescription || errorDetail || defaultMessageSupportWithLink,
        },
      }

    default:
      return state
  }
}

const procurationActionCreators: ProcurationActionCreators = {
  setLoading: (payload) => ({ type: procurationReducerActionMap.SETUP_LOADING, payload }),
  setOtherProcuration: (payload) => ({
    type: procurationReducerActionMap.SETUP_OTHER_PROCURATION,
    payload,
  }),
  setCaption: (payload) => ({ type: procurationReducerActionMap.SETUP_CAPTION, payload }),
  setError: (payload) => ({ type: procurationReducerActionMap.SETUP_ERROR, payload }),
  setNativeError: (payload) => ({ type: procurationReducerActionMap.SETUP_NATIVE_ERROR, payload }),
}

export { initialProcurationState, procurationActionCreators, procurationReducer }
