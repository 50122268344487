import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import IndicatorsOfUnfulfilledAgreementReportLayout from './Layout'
import IndicatorsOfUnfulfilledAgreementReportModalManager from './Manager'
import IndicatorsOfUnfulfilledAgreementReportMenu from './Menu'
import IndicatorsOfUnfulfilledAgreementReportStatementForm from './Statement'

interface ICreateIndicatorsOfUnfulfilledAgreementReport extends FC {
  Layout: typeof IndicatorsOfUnfulfilledAgreementReportLayout
  Menu: typeof IndicatorsOfUnfulfilledAgreementReportMenu
  StatementForm: typeof IndicatorsOfUnfulfilledAgreementReportStatementForm
  Manager: typeof IndicatorsOfUnfulfilledAgreementReportModalManager
}

const useIndicatorsOfUnfulfilledAgreementReportControl = () => {
  const popupManager = usePopupManager()

  const openIndicatorsOfUnfulfilledAgreementReportModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(IndicatorsOfUnfulfilledAgreementReportLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openIndicatorsOfUnfulfilledAgreementReportModal }
}

const IndicatorsOfUnfulfilledAgreementReport: ICreateIndicatorsOfUnfulfilledAgreementReport = () => null

IndicatorsOfUnfulfilledAgreementReport.Menu = IndicatorsOfUnfulfilledAgreementReportMenu
IndicatorsOfUnfulfilledAgreementReport.Layout = IndicatorsOfUnfulfilledAgreementReportLayout
IndicatorsOfUnfulfilledAgreementReport.StatementForm = IndicatorsOfUnfulfilledAgreementReportStatementForm
IndicatorsOfUnfulfilledAgreementReport.Manager = IndicatorsOfUnfulfilledAgreementReportModalManager

export { useIndicatorsOfUnfulfilledAgreementReportControl }
export default IndicatorsOfUnfulfilledAgreementReport
