import { DependentUrls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { INpaDraftBody } from '@services/NPA/NPA.entity'
import { parse } from 'content-disposition-header'
import { nanoid } from 'nanoid'

import type { ConvertDraftProps, DraftDefaultProps, DraftPutProps } from './types'

const useDraftApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const getDraft = async <T = INpaDraftBody,>({
    dfoId,
    documentSetId,
    documentType,
    projectId,
  }: DraftDefaultProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<T>({
        url: {
          url: DependentUrls.Drafts,
          params: {
            dfoId,
            documentSetId,
            documentType,
            projectId,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const putDraft = async <T = INpaDraftBody,>({
    dfoId,
    documentSetId,
    documentType,
    projectId,
    body,
  }: DraftPutProps<T>) => {
    try {
      const { data } = await FetcherWithInterceptors.put<T>({
        url: {
          url: DependentUrls.Drafts,
          params: {
            dfoId,
            documentSetId,
            documentType,
            projectId,
          },
        },
        data: { type: documentType, ...body },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const getDraftPreview = async (
    { dfoId, documentSetId, documentType, projectId }: DraftDefaultProps,
    withOpen = true,
  ) => {
    try {
      const { data, headers } = await FetcherWithInterceptors.get<File>({
        url: {
          url: `${DependentUrls.Drafts}/preview`,
          params: {
            dfoId,
            documentSetId,
            documentType,
            projectId,
          },
        },
        config: {
          responseType: 'blob',
        },
      })

      const {
        parameters: { filename },
      } = parse(headers['content-disposition'] || '')

      if (withOpen) {
        const fileToOpen = new File([data], filename || nanoid(), {
          type: headers?.['content-type'],
        })

        const fileUrl = URL.createObjectURL(fileToOpen)

        window.open(fileUrl)
        URL.revokeObjectURL(fileUrl)
      }

      return data
    } catch (error) {
      throw error
    }
  }

  const convertDraftToDocument = async ({ projectId, actionId }: ConvertDraftProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post({
        url: {
          url: DependentUrls.ConvertDraft,
          params: {
            projectId,
            actionId,
          },
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  return {
    getDraft,
    getDraftPreview,
    putDraft,
    convertDraftToDocument,
  }
}

export default useDraftApi
