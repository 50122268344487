import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForApplicationConclusionAdditionalAgreementFormForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '2': {
      ruleLinkExtraReason: {
        propertyId: objectFromPropertyState.ruleLinkExtraReason?.propertyId,
        type: objectFromPropertyState.ruleLinkExtraReason?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkExtraReason?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForApplicationConclusionAdditionalAgreementFormForm }
