import {
  BasketFilterValue,
  FilterFactory,
  StatementFilterFormValues,
} from '@routes/NewStatements/types'

class OMSUFilterController implements FilterFactory {
  readonly mapOfFiltersByType: Partial<
    Record<BasketFilterValue, Partial<StatementFilterFormValues>>
  > = {}

  getAllMapOfFilters() {
    return this.mapOfFiltersByType
  }

  getFilters(tabValue: BasketFilterValue): StatementFilterFormValues | Record<string, unknown> {
    if (!this.mapOfFiltersByType[tabValue]) return {}
    return this.mapOfFiltersByType[tabValue] as StatementFilterFormValues
  }
}

export { OMSUFilterController }
