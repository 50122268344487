import env from '@config'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { getMigrationsByPrevAndCurrentVersions } from '@services/ReleaseMigration/migrations'

import ReleaseMigrationLSService from './ReleaseMigration.LS'

const LSMigrationService = ReleaseMigrationLSService

class ReleaseMigrationService {
  //Может быть пустой строкой, если не указали в конфиге.
  private _appVersion = ''

  constructor(appVersion: string) {
    this._appVersion = appVersion
  }

  private __applyMigrations(
    prevUserVersionOfLastMigrate: string | undefined,
    currentVersionApp: string | undefined,
  ) {
    //В этом случае миграции не нужны
    if (prevUserVersionOfLastMigrate === currentVersionApp) return

    const migrateFunc = getMigrationsByPrevAndCurrentVersions(
      prevUserVersionOfLastMigrate,
      currentVersionApp,
    )

    let lastAppliedVersion = ''

    try {
      migrateFunc.forEach(({ version, migration }) => {
        const applyVersion = version === 'default' ? this._appVersion : version
        migration()

        lastAppliedVersion = applyVersion
        LSMigrationService.setLastMigrationVersion(applyVersion)
      })
    } catch (e) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          moduleName: 'applyMigrations',
          componentName: 'ReleaseMigrationService',
        },
        additionInfo: {
          prevUserVersionOfLastMigrate,
          currentVersionApp,
          versionOfErrorMigration: lastAppliedVersion,
        },
      })
    }
  }

  applyAllMigrations() {
    const prevUserVersion = LSMigrationService.getLastMigrationVersion()
    const currentVersionApp = this._appVersion

    this.__applyMigrations(prevUserVersion, currentVersionApp)
  }
}

export default new ReleaseMigrationService(env.REACT_APP_RELEASE_VERSION)
