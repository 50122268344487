import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import AssignmentMonetaryOrPledgeA from '@components/Forms/AssignmentMonetaryOrPledgeAForm'
import {
  defaultAssignmentMonetaryOrPledgeAFormValues,
  mapOfAssignmentMonetaryOrPledgeAMenu,
} from '@components/Forms/AssignmentMonetaryOrPledgeAForm/const'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import AssignmentMonetaryOrPledgeALayoutWrapper from './Wrapper'

const AssignmentMonetaryOrPledgeALayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps({
    defaultValues: defaultAssignmentMonetaryOrPledgeAFormValues,
  })

  const handleOnClose = () => {
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <AssignmentMonetaryOrPledgeA.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfAssignmentMonetaryOrPledgeAMenu[0].id}>
            <AssignmentMonetaryOrPledgeALayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об уступке денежных требований по соглашению или о передаче в залог
                  денежных требований по соглашению
                </Typography.Headline>
                <AssignmentMonetaryOrPledgeA.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--xl'])}
              >
                <AssignmentMonetaryOrPledgeA.Menu className={styles.layout__menu} />
              </div>
            </AssignmentMonetaryOrPledgeALayoutWrapper>
          </FlatMenuManager>
        </AssignmentMonetaryOrPledgeA.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(AssignmentMonetaryOrPledgeALayout)
