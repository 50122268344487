import { IFiltersFormValues } from '@components/RegistryNpa/Manager'
import { isUndefined } from '@helpers/checkTypes'

const getCountOfActiveFiltersOld = (filters: IFiltersFormValues['filters']) => {
  const preparedIsFederal = filters.isFederal && filters.isRegional ? undefined : filters.isFederal
  const preparedRegions =
    filters.region !== '' && filters.region?.length ? filters?.region : undefined

  const preparedMunicipals =
    filters.oktmo !== '' && filters.oktmo?.length ? filters?.oktmo : undefined

  const preparedType = filters.type !== '' && filters.type?.length ? filters.type : undefined
  const preparedPart = filters.part !== '' ? filters.part : undefined
  const preparedDirection =
    filters.direction !== '' && filters.direction?.length ? filters.direction : undefined

  return Object.values({
    type: preparedType,
    part: preparedPart,
    direction: preparedDirection,
    isFederal: preparedIsFederal,
    region: preparedRegions,
    oktmo: preparedMunicipals,
    page: undefined,
    size: undefined,
  }).filter((value) => !isUndefined(value)).length
}

const getCountOfActiveFilters = (filters: IFiltersFormValues['filters']) => {
  const preparedCheckboxFilter =
    filters.isFederal || filters.isRegional || filters.isMunicipal || undefined
  const preparedRegions =
    filters.region !== '' && filters.region?.length ? filters?.region : undefined

  const preparedMunicipals =
    filters.oktmo !== '' && filters.oktmo?.length ? filters?.oktmo : undefined

  const preparedType = filters.type !== '' && filters.type?.length ? filters.type : undefined
  const preparedPart = filters.part !== '' ? filters.part : undefined
  const preparedDirection =
    filters.direction !== '' && filters.direction?.length ? filters.direction : undefined

  return Object.values({
    type: preparedType,
    part: preparedPart,
    direction: preparedDirection,
    preparedCheckboxFilter: preparedCheckboxFilter,
    region: preparedRegions,
    oktmo: preparedMunicipals,
    page: undefined,
    size: undefined,
  }).filter((value) => !isUndefined(value)).length
}

export { getCountOfActiveFilters, getCountOfActiveFiltersOld }
