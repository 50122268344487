import { usePopupManager } from 'react-popup-manager'

import DeleteProjectModal, { IDeleteProjectModal } from '.'

const useDeleteProjectModal = () => {
  const popupManager = usePopupManager()

  const handleOpenDeleteProjectModal = (props: IDeleteProjectModal) => {
    popupManager.open(DeleteProjectModal, {
      onClose: () => null,
      ...props,
    })
  }

  return {
    handleOpenDeleteProjectModal,
  }
}

export { useDeleteProjectModal }
