import React, { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'

import { MenuHeaderIconProps } from '@components/Sidebars/MainHeaderMenu/PreparedIcons/types'
import cn from 'classnames'

import styles from './MainHeaderMenuIcon.module.scss'

const MainHeaderMenuIcon: FC<MenuHeaderIconProps> = ({ src, isActive }) => {
  return (
    <ReactSVG
      src={src}
      className={cn(styles.mainHeaderMenuIcon, {
        [styles.mainHeaderMenuIcon__active]: isActive,
      })}
    />
  )
}

export default memo(MainHeaderMenuIcon)
