import { FC, memo } from 'react'

import Button from '@components/Button'
import Icon from '@components/Icon'
import Loader from '@components/Loader'
import icon_successful from '@icons/action/successful_completion.svg'
import cn from 'classnames'

import styles from './Formation.module.scss'

interface FormationProps {
  title: string
  isLoading: boolean
  handleClose: () => void
  dfoId: string
  subtitle?: string
  buttonText?: string
}

const Formation: FC<FormationProps> = ({
  title,
  isLoading,
  handleClose,
  subtitle,
  buttonText = 'Отлично',
}) => {
  return (
    <div className={styles.window}>
      <div className={styles.container}>
        {!isLoading && <Icon color="#39BF71" src={icon_successful} className={styles.icon} />}
        <div
          className={cn(styles.title, {
            [styles['title--border']]: !isLoading,
          })}
        >
          {title}
        </div>
        <Loader
          dataTestId={`CreateStatementForm.Formation-loader`}
          className={styles.loader}
          loading={isLoading}
        />
        <div className={styles.label}>
          {isLoading ? 'Это может занять некоторое время' : subtitle}
        </div>
        {!isLoading && (
          <div className={styles.button}>
            <Button size="xs" onClick={handleClose}>
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(Formation)
