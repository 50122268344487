import { useEffect, useMemo, useRef, useState } from 'react'

import { OptionProps } from '@components/NewDesign/Select/model'
import { getActualProjectAttributeByName } from '@components/Projects/[id]/helpers'
import { IOrganization } from '@context/APIContext/hooks/useOrganizationsApi'
import { compact } from '@helpers/array/compact'
import { isString } from '@helpers/checkTypes'
import { useAllOrganizationsList } from '@hooks/new/swr/useAllOrganizationsList'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import useDebounce from '@hooks/useDebounce'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import { useMunicipalityMembers } from './useMunicipalityMembers'

const ORGANIZATIONS_INITIAL_PAGE = 0
const ORGANIZATIONS_PAGE_SIZE = 100
const PAGINATION_BOTTOM_TRIGGER = 100
const ALL_ORGANIZATION_TYPES = ['OIV']

const useOrganizationMembers = () => {
  const [searchOrganizationsPattern, setSearchOrganizationsPattern] = useState<string>('')
  const [organizationPage, setOrganizationPage] = useState(ORGANIZATIONS_INITIAL_PAGE)
  const [organizationsInfinite, setOrganizationsInfinite] = useState<IOrganization[]>([])

  const totalPagesRef = useRef<number>(0)

  const debouncedSearchString = useDebounce(searchOrganizationsPattern, 300)

  const { allOrganizations, isLoadingAllOrganizations, error } = useAllOrganizationsList({
    key: {
      type: ALL_ORGANIZATION_TYPES,
      page: organizationPage,
      size: ORGANIZATIONS_PAGE_SIZE,
      searchString: debouncedSearchString || undefined,
      _key: 'organizations',
    },
  })

  useEffect(() => {
    if (!allOrganizations) return

    setOrganizationsInfinite((prevState) => [...prevState, ...allOrganizations?.organizations])

    totalPagesRef.current = allOrganizations?.totalPage || 0

    if (!totalPagesRef.current || organizationPage >= totalPagesRef.current) return

    setOrganizationPage((prev) => prev + 1)
  }, [allOrganizations, organizationPage])

  const handleBindInputValue = (value) => {
    setSearchOrganizationsPattern(value)
  }

  const onSelectContainerScroll = async (e) => {
    const hasMoreData = organizationPage < totalPagesRef.current

    const requestCondition = !error && !isLoadingAllOrganizations && hasMoreData

    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
        PAGINATION_BOTTOM_TRIGGER &&
      requestCondition
    ) {
      setOrganizationPage((size) => size + 1)
    }
  }

  const preparedOrganizationsForSelect = useMemo(() => {
    if (!organizationsInfinite.length) return []

    return compact(
      organizationsInfinite.map((organization) => {
        if (organization.isBlocked || (!organization.regionName && !organization.name)) return null

        return {
          displayValue: organization.regionName || organization.name,
          value: organization.id,
        }
      }),
    ) as OptionProps[]
  }, [organizationsInfinite])

  return {
    isLoadingAllOrganizations,
    organizations: organizationsInfinite,
    preparedOrganizationsForSelect,
    handleBindInputValue,
    onSelectContainerScroll,
  }
}

const useAddMemberModalInfo = (projectId: string) => {
  const { projectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      revalidateOnMount: true,
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useAddMemberModalInfo',
          componentType: 'hook',
        },
      }),
    },
  })

  const postedAttributes = useMemo(
    () => projectAttributes?.map((attribute) => attribute.name) || [],
    [projectAttributes],
  )

  const subjectsToEdit = useMemo(() => {
    if (!projectAttributes) return []

    const subjectsToReturn = getActualProjectAttributeByName('subjects', projectAttributes)?.value

    const returnCondition = Array.isArray(subjectsToReturn)

    return !returnCondition
      ? []
      : compact(subjectsToReturn.map((value) => (isString(value) ? value : '')))
  }, [projectAttributes])

  const subjectsExtraToEdit = useMemo(() => {
    if (!projectAttributes) return []

    const subjectsExtraToReturn = getActualProjectAttributeByName(
      'subjectsExtra',
      projectAttributes,
    )?.value

    const returnCondition = Array.isArray(subjectsExtraToReturn)

    return !returnCondition
      ? []
      : compact(subjectsExtraToReturn.map((value) => (isString(value) ? value : '')))
  }, [projectAttributes])

  const municipalityMembers = useMunicipalityMembers({ projectAttributes })

  return {
    subjectsToEdit,
    subjectsExtraToEdit,
    municipalityMembers,
    postedAttributes,
    projectAttributes,
  }
}

export { useAddMemberModalInfo, useOrganizationMembers }
