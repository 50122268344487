import React, { FC, memo, useCallback } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import TextWithTooltip from '@components/NewDesign/TextWithTooltip'
import {
  FormLayoutValues,
  ICardViewRefs,
} from '@components/Projects/[id]/DocumentLayout/Addons/NpaAddonOld'
import styles from '@components/Projects/[id]/DocumentLayout/Addons/NpaAddonOld/NpaThreeOld/NpaThreeOld.module.scss'
import { isObject } from '@helpers/checkTypes'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import LoggerServiceHelpers from '@services/LoggerService/LoggerService.helpers'
import {
  ChosenPart,
  INpaDraftBody,
  IPartClassificationLayout,
  PartCustomProps,
} from '@services/NPA/NPA.entity'

import PartCardViewOld from '../PartCardOld'

const DESCRIPTION_LIMIT = 43

interface ClassificationOldProps {
  nameOfFieldArray: `npaStructure.${number}.classifications.${number}`
  classification: IPartClassificationLayout
  layoutThree: UseFormReturn<FormLayoutValues>
  handleBindCardRef: (callback: (prevState: ICardViewRefs | null) => ICardViewRefs) => void
  onUpdateDraftModel: (updatedChosenParts: INpaDraftBody) => Promise<void>
  updateAllLayoutThree: (partToScrollAfterUpdate: string) => Promise<void>
  removePartClassification: () => void
}

const ClassificationViewOld: FC<ClassificationOldProps> = ({
  layoutThree,
  nameOfFieldArray,
  handleBindCardRef,
  classification,
  onUpdateDraftModel,
  updateAllLayoutThree,
  removePartClassification,
}) => {
  const { fields, update, remove } = useFieldArray<
    FormLayoutValues,
    `npaStructure.${number}.classifications.${number}.parts`,
    'fieldId'
  >({
    control: layoutThree.control,
    name: `${nameOfFieldArray}.parts`,
    keyName: 'fieldId',
  })

  const updateDraftModel = useCallback(
    async (partId: string, action: 'update' | 'remove', newPartData?: PartCustomProps) => {
      const chosenParts = layoutThree.watch('chosenParts')?.npaParts
      const partsFromFields = layoutThree.watch(`${nameOfFieldArray}.parts`)

      if (!chosenParts) return

      if (action === 'update')
        return onUpdateDraftModel({
          npaParts: chosenParts.map((part) => {
            if (part.partId === partId) {
              return {
                ...part,
                ...newPartData,
              }
            }

            return part
          }),
        })

      const partsAfterRemove = partsFromFields.filter((part) => part.id !== partId)

      try {
        await onUpdateDraftModel({
          npaParts: chosenParts.filter((part) => part.partId !== partId),
        })

        return Promise.resolve({ needDeletePartClassification: !partsAfterRemove.length })
      } catch {}
    },
    [layoutThree, nameOfFieldArray, onUpdateDraftModel],
  )

  const handleUpdatePart = useCallback(
    (indexForField: number, partId: string) =>
      async ({ partCorrection, justification }: PartCustomProps) => {
        const notUpdatedInfo = fields[indexForField]

        try {
          await updateDraftModel(partId, 'update', { partCorrection, justification })

          update(indexForField, { ...notUpdatedInfo, justification, partCorrection })
        } catch (error) {
          const additionInfo = {
            partId,
            notUpdatedInfo,
            action: 'update',
            partData: {
              partCorrection,
              justification,
            },
          }

          LoggerHelpersService.handleMultipleLogError({
            additionInfo,
            componentInfo: {
              componentName: 'ClassificationView',
              moduleName: 'ClassificationsView',
              componentType: 'updateNPAPart',
            },
          })(error)

          throw error
        }
      },
    [fields, update, updateDraftModel],
  )

  const handleRemovePart = useCallback(
    (indexForField: number, partId: string) => async () => {
      try {
        const promiseData = await updateDraftModel(partId, 'remove')

        remove(indexForField)

        if (isObject(promiseData) && promiseData.needDeletePartClassification) {
          removePartClassification()
        }
      } catch (error) {
        const additionInfo = {
          partId,
          action: 'remove',
        }

        LoggerServiceHelpers.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'ClassificationView',
            moduleName: 'ClassificationsView',
            componentType: 'removeNPAPart',
          },
        })(error)

        throw error
      }
    },
    [remove, removePartClassification, updateDraftModel],
  )

  const handleReplaceChosenPart = useCallback(
    (partId) => async (newChosen: Omit<ChosenPart, 'classificationId'>) => {
      const chosenValues = layoutThree.getValues().chosenParts?.npaParts

      if (!chosenValues) return

      await onUpdateDraftModel({
        npaParts: chosenValues.map((chosen) => {
          if (chosen.partId === partId) return newChosen

          return chosen
        }),
      })

      await updateAllLayoutThree(partId)
    },
    [layoutThree, onUpdateDraftModel, updateAllLayoutThree],
  )

  const bindRef = useCallback(
    (partId: string) => (ref: HTMLDivElement | null) => {
      if (ref) {
        return handleBindCardRef?.((prevState) => {
          if (!prevState) {
            return { [partId]: ref.offsetTop }
          }

          return {
            ...prevState,
            [partId]: ref.offsetTop,
          }
        })
      }
    },
    [handleBindCardRef],
  )

  return (
    <>
      <TextWithTooltip
        variant="bodyXLMedium"
        value={classification.description}
        className={styles['npaThree__description']}
        limit={DESCRIPTION_LIMIT}
        tooltipProps={{
          contentClassName: styles.tooltip__content,
          targetClassName: styles.tooltip__target,
          popoverClassName: styles.tooltip__popover,
          arrowClassName: styles.tooltip__arrow,
          position: 'top',
        }}
      />
      <div className={styles.npaThree__parts}>
        {fields.map((part, index) => (
          <PartCardViewOld
            key={part.id}
            part={part}
            partCardRef={bindRef(part.id)}
            onReplaceChosenPart={handleReplaceChosenPart(part.id)}
            onUpdatePart={handleUpdatePart(index, part.id)}
            onRemovePart={handleRemovePart(index, part.id)}
          />
        ))}
      </div>
    </>
  )
}

export default memo(ClassificationViewOld)
