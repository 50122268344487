import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import type { StatementByHeadOfMunicipalityFormValues } from '@components/Forms/StatementByHeadOfMunicipality/types'

const generateRHFObjectForStatementByHeadOfMunicipalityForm = (
  objectFromPropertyState: Record<string, any>,
): StatementByHeadOfMunicipalityFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getNewMunicipalityAdapter(objectFromPropertyState),
      municipalityFullNameGenitive:
        objectFromPropertyState?.newMunicipalityFullNameGenitive?.value || '',
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '4': {
      projectName: objectFromPropertyState.projectName.value || '',
    },
  }
}

export { generateRHFObjectForStatementByHeadOfMunicipalityForm }
