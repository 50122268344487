import React, { FC, memo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { stepOfStageFieldsValidation } from '@components/Forms/CreateStatement/Forms/Stage/StepOfStage/validation'
import { StepOfStagesArrayPathName } from '@components/Forms/CreateStatement/Forms/Stage/types'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { CreateStatementControlUpdateWatcher } from '@components/Forms/CreateStatement/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

interface StepOfStageProps {
  id: string
  name: StepOfStagesArrayPathName
  onRemoveStage: VoidFunction
}

const StepOfStage: FC<StepOfStageProps> = ({ id, name, onRemoveStage }) => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()

  const { getSubscribableControlProps, getCalendarInputProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: CreateStatementControlUpdateWatcher,
    })

  const stepStartDate = useWatch({
    name: `${name}.stepStartDate`,
    control: formInstance.control,
  })

  const projectEndDate = useWatch({
    name: statementBlockValues.stage.projectEndDate,
    control: formInstance.control,
  })

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate
      id={id}
      dataTestId={'stagesListItem'}
      onRemoveStep={editMode ? onRemoveStage : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepDescription`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.stepDescription`,
                  rules: stepOfStageFieldsValidation.stepDescription,
                  textareaProps: {
                    label: 'Этап проекта',
                    dataTestId: 'stepDescription',
                  },
                  onBlur: () => setTimeout(() => handleChangeValue?.(`${name}.stepDescription`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepDescription`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepStartDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.stepStartDate`,
                  rules: stepOfStageFieldsValidation.stepStartDate,
                  calendarInputProps: {
                    label: 'Дата начала этапа',
                    dataTestId: 'stepStartDate',
                  },

                  onBlur: () => setTimeout(() => handleChangeValue?.(`${name}.stepStartDate`), 0),
                  onInputChange: () =>
                    setTimeout(() => debouncedHandleChangeValue?.(`${name}.stepStartDate`), 0),
                  onCalendarChange: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.stepStartDate`), 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepEndDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.stepEndDate`,
                  rules: {
                    ...stepOfStageFieldsValidation.stepEndDate,
                    validate: {
                      positiveStepStartDate: (value) => {
                        return (
                          (isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) >
                              dayjs(stepStartDate, objOfDateFormats.defaultFormat)) ||
                          'дата окончания этапа должна быть больше даты начала этапа'
                        )
                      },
                      positiveProjectEndDate: (value) => {
                        return (
                          (isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) <=
                              dayjs(projectEndDate, objOfDateFormats.defaultFormat)) ||
                          'дата окончания этапа должна быть меньше или равна дате окончания проекта'
                        )
                      },
                    },
                  },
                  calendarInputProps: {
                    label: 'Дата окончания этапа',
                    dataTestId: 'stepEndDate',
                  },
                  onBlur: () => setTimeout(() => handleChangeValue?.(`${name}.stepEndDate`), 0),
                  onInputChange: () =>
                    setTimeout(() => debouncedHandleChangeValue?.(`${name}.stepEndDate`), 0),
                  onCalendarChange: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.stepEndDate`), 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default memo(StepOfStage)
