import { AxiosRequestConfig } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import storage from 'store2'

import { axiosInstanceWithInterceptors } from './instances'

const onRequestSuccess = (config: AxiosRequestConfig) => {
  const context = storage.get('auth_state')

  if (config.headers) {
    config.headers.Authorization = `Bearer ${context?.token}`
  }

  return config
}

export const initializeAuthRefresh = (refreshTokenRequest) => {
  axiosInstanceWithInterceptors.interceptors.request.use(onRequestSuccess)

  createAuthRefreshInterceptor(axiosInstanceWithInterceptors, refreshTokenRequest, {
    pauseInstanceWhileRefreshing: false,
  })
}
