import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AssignmentMonetaryOrPledgeAFormValues } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const firstSectionValidationMap: FormValuesValidationSection<
  keyof AssignmentMonetaryOrPledgeAFormValues['1'],
  AssignmentMonetaryOrPledgeAFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { firstSectionValidationMap }
