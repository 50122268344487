import { useCallback, useEffect, useRef } from 'react'

interface QueryRequestProps {
  id: string
  promise: () => Promise<unknown>
}

interface ProcessedQueryProps extends QueryRequestProps {
  data: unknown
  error: unknown
}

const useFormRequestManager = () => {
  //В данный момент одно активное действие, дальше возможен пакет батчей.
  const processQuery = useRef<QueryRequestProps[]>([])
  const callQuery = useRef<QueryRequestProps[]>([])
  const processedQuery = useRef<ProcessedQueryProps[]>([])

  const isProcessing = useRef(false)

  const handleAddQueryRequest = useCallback(async (promise: QueryRequestProps) => {
    await new Promise<unknown>((resolve, reject) => {
      callQuery.current.push(promise)

      const interval = setInterval(() => {
        const resolvedPromiseByRequest = processedQuery.current.find(
          (processedPromise) => promise.id === processedPromise.id,
        )

        if (!resolvedPromiseByRequest) return

        if (resolvedPromiseByRequest.error) {
          reject(resolvedPromiseByRequest.error)
          clearInterval(interval)
          return
        } else {
          resolve(resolvedPromiseByRequest.data)
          clearInterval(interval)
        }
      }, 200)
    })
  }, [])

  useEffect(() => {
    const intervalId = startRequestLoop()

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const processNextPromiseQuery = useCallback(async () => {
    if (isProcessing.current || !processQuery.current.length) return

    isProcessing.current = true

    const promiseToProcess = processQuery.current.shift()

    try {
      const result = await promiseToProcess?.promise?.()

      if (promiseToProcess) {
        processedQuery.current.push({ ...promiseToProcess, data: result, error: null })
      }
    } catch (error) {
      console.error(error)

      if (promiseToProcess) {
        processedQuery.current.push({ ...promiseToProcess, data: null, error })
      }

      throw error
    } finally {
      isProcessing.current = false
    }
  }, [])

  const startRequestLoop = useCallback(() => {
    return setInterval(() => {
      if (isProcessing.current || processQuery.current.length || !callQuery.current.length) return

      const nextPromiseToProcess = callQuery.current.shift()

      if (!nextPromiseToProcess) return

      processQuery.current.push(nextPromiseToProcess)
      processNextPromiseQuery()
    }, 200)
  }, [processNextPromiseQuery])

  return {
    handleAddQueryRequest,
  }
}

export { useFormRequestManager }
export type { ProcessedQueryProps, QueryRequestProps }
