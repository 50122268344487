import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import ControlledFormMultipleSelect from '@components/DocumentFormComponents/FormSelect/Multi/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import ManufactoringOfGoods from '@components/Forms/ProjectSZPKForm/Forms/9/Forms/ManufactoringOfGoods'
import MeasuresNegativeImpactForm from '@components/Forms/ProjectSZPKForm/Forms/9/Forms/MeasuresNegativeImpact'
import { NinthManufactoringOfGoodsPathName } from '@components/Forms/ProjectSZPKForm/Forms/9/types'
import { ninthSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/9/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import { OptionProps } from '@components/NewDesign/Select/model'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

const { transformRHFPathInProperties } = DocumentFormHelpers

const OTHER_PROJECT_RESULT_ACTIVITY_OPTION_NAME = 'Иное'

const MEASURES_NEGATIVE_IMPACT_OPTION_NAME =
  'Выполнение мероприятий по снижению негативного воздействия на окружающую среду'

const MANUFACTORING_OF_GOODS_OPTION_NAMES = [
  'Производство товаров',
  'Выполнение работ',
  'Создание РИД',
  'Оказание услуг',
]

const { isFormFieldError } = DocumentFormHelpers

const Ninth = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      getPropertiesProps,
      handleRemoveCustomValue,
      handleAddItemToListWithValue,
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleChangeValue,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getTextareaProps, getMultipleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      watcher: ProjectSZPKFieldsControlUpdateWatcher,
      subscribableControl,
    })

  const { fields } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    NinthManufactoringOfGoodsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: projectSZPKBlockValues['9'].projectResults,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const projectResultActivities = useWatch({
    name: projectSZPKBlockValues['9'].projectResultActivity,
  })

  const fieldsError = formInstance.getFieldState(projectSZPKBlockValues['9'].projectResults)?.error

  const manufactofingOfGoodsRenderCondition = useMemo(() => {
    if (!projectResultActivities || !projectResultActivities.length) return false

    return projectResultActivities.some(
      ({ displayValue }) =>
        MANUFACTORING_OF_GOODS_OPTION_NAMES.includes(displayValue) ||
        displayValue === OTHER_PROJECT_RESULT_ACTIVITY_OPTION_NAME,
    )
  }, [projectResultActivities])

  const measuresNegativeImpactRenderCondition = useMemo(() => {
    if (!projectResultActivities || !projectResultActivities.length) return false

    return projectResultActivities.some(
      ({ displayValue }) => displayValue === MEASURES_NEGATIVE_IMPACT_OPTION_NAME,
    )
  }, [projectResultActivities])

  const otherProjectActivityRenderCondition = useMemo(() => {
    if (!projectResultActivities || !projectResultActivities.length) return false

    return projectResultActivities.some(
      ({ displayValue }) => displayValue === OTHER_PROJECT_RESULT_ACTIVITY_OPTION_NAME,
    )
  }, [projectResultActivities])

  const currentGapValue =
    manufactofingOfGoodsRenderCondition && measuresNegativeImpactRenderCondition
      ? 3
      : manufactofingOfGoodsRenderCondition || measuresNegativeImpactRenderCondition
      ? 2
      : 0

  const clearManufactoringGoodErrors = () => {
    formInstance.clearErrors(projectSZPKBlockValues['9'].projectResults)
  }

  const handleAddManufactoringGood = async () => {
    await handleAddItemToListWithOutValue?.(projectSZPKBlockValues['9'].projectResults)

    if (!fields.length) {
      clearManufactoringGoodErrors()
    }
  }

  const handleRemoveRequirementsForm = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${projectSZPKBlockValues['9'].projectResults}.${index}`,
      projectSZPKBlockValues['9'].projectResults,
    )

    clearManufactoringGoodErrors()
  }

  const onChangeProjectResultActivityValue = async (
    _currentList: OptionProps[],
    currentValue: OptionProps,
    prevList: OptionProps[],
  ) => {
    const foundCurrentValueIndex = prevList?.findIndex((val) => val.value === currentValue.value)

    if (foundCurrentValueIndex === -1) {
      await handleAddItemToListWithValue?.(projectSZPKBlockValues['9'].projectResultActivity, {
        id: currentValue.value,
      })

      return
    }

    const propertyProjectResultActivities = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(projectSZPKBlockValues['9'].projectResultActivity),
    )

    if (!propertyProjectResultActivities || !propertyProjectResultActivities?.value) return

    const propertyProjectResultActivity =
      propertyProjectResultActivities.value?.[foundCurrentValueIndex]

    if (!propertyProjectResultActivity) return

    const propertyParentToRemove = {
      id: propertyProjectResultActivities.propertyId,
      lastUpdateDt: propertyProjectResultActivities.lastUpdateDt,
    }

    handleRemoveCustomValue?.(propertyParentToRemove, propertyProjectResultActivity.propertyId)

    if (currentValue.displayValue === OTHER_PROJECT_RESULT_ACTIVITY_OPTION_NAME) {
      formInstance.clearErrors(projectSZPKBlockValues['9'].otherProjectResultActivity)
    }
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={currentGapValue}>
        <Row>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['9'].projectResultActivity,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormMultipleSelect
                        {...getMultipleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.name,
                            value: item.id,
                          }),
                          selectProps: {
                            withContextSearch: true,
                            chipNameLengthToShowTooltip: 75,
                            popoverProps: {
                              zIndex: 51,
                            },
                            inputProps: {
                              view: 'secondary',
                              label: 'Планируемый вид деятельности',
                              dataTestId: 'projectResultActivity',
                            },
                            onChangeForm: onChangeProjectResultActivityValue,
                          },
                          controllerProps: {
                            name: projectSZPKBlockValues['9'].projectResultActivity,
                            rules: ninthSectionValidationMap.projectResultActivity,
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
            </Row>
            <CollapseWrapper
              defaultExpanded={otherProjectActivityRenderCondition}
              isExpanded={otherProjectActivityRenderCondition}
            >
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: projectSZPKBlockValues['9'].otherProjectResultActivity,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: projectSZPKBlockValues['9'].otherProjectResultActivity,
                        rules: ninthSectionValidationMap.otherProjectResultActivity,

                        textareaProps: {
                          label: 'Планируемый вид деятельности',
                          dataTestId: 'otherProjectResultActivity',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              projectSZPKBlockValues['9'].otherProjectResultActivity,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              projectSZPKBlockValues['9'].otherProjectResultActivity,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </CollapseWrapper>
          </Stack>
        </Row>
        <CollapseWrapper
          defaultExpanded={manufactofingOfGoodsRenderCondition}
          isExpanded={manufactofingOfGoodsRenderCondition}
        >
          <Border />
          <Group disableBottomBorder>
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={12}>
                  <Stack direction={'vertical'} gap={3}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: projectSZPKBlockValues['9'].projectResults,
                      })}
                    >
                      <FlipperList list={fields}>
                        {fields.map((field, index) => (
                          <ManufactoringOfGoods
                            key={field.id}
                            id={field.id}
                            control={formInstance.control}
                            name={`${projectSZPKBlockValues['9'].projectResults}.${index}` as const}
                            onRemoveStep={handleRemoveRequirementsForm(index)}
                          />
                        ))}
                      </FlipperList>
                    </SubscribableControl>
                  </Stack>
                </Col>
              </Row>
              {isFormFieldError(fieldsError) && !fields.length && (
                <Row>
                  <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
                </Row>
              )}
              {editMode && (
                <Row className={'px-0.5'}>
                  <AsyncWrapper promise={handleAddManufactoringGood}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <FieldView.ActionButton
                          disableWrapperPaddingLeft
                          disabled={isLoading}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Указать товар/работу/услугу/РИД
                        </FieldView.ActionButton>
                      )
                    }}
                  </AsyncWrapper>
                </Row>
              )}
            </Stack>
          </Group>
        </CollapseWrapper>
        <CollapseWrapper
          defaultExpanded={measuresNegativeImpactRenderCondition}
          isExpanded={measuresNegativeImpactRenderCondition}
        >
          <Border />
          <Group disableBottomBorder title={'Мероприятия по снижению негативного воздействия'}>
            <Row>
              <Col xs={12}>
                <MeasuresNegativeImpactForm />
              </Col>
            </Row>
          </Group>
        </CollapseWrapper>
      </Stack>
    </Container>
  )
}

export default Ninth
