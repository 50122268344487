const registrationReorgDefaultFormValues = {
  ORG_REORGANIZATION: {
    date: '',
    number: '',
  },
  SZPK_RIGHTS_TRANSFER: {
    date: '',
    number: '',
  },
}

export { registrationReorgDefaultFormValues }
