import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'

const generateRHFObjectForAdditionalAgreementOnTerminationFfSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): AdditionalAgreementOnTerminationFfSZPKFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      ruleLinkAgreementTerminationExtra:
        objectFromPropertyState.ruleLinkAgreementTerminationExtra?.value || '',
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  } as AdditionalAgreementOnTerminationFfSZPKFormValues
}

export { generateRHFObjectForAdditionalAgreementOnTerminationFfSZPKForm }
