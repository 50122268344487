import { useEffect, useRef } from 'react'

export const usePreviousValue = <T>(value: T, initialValue?: T): T | undefined => {
  const prev = useRef<T | undefined>(initialValue)

  useEffect(() => {
    prev.current = value
  }, [value])

  return prev.current
}
