import React, { FC, useEffect } from 'react'
import { Outlet } from 'react-router'

import { useScrollState } from '@hooks/new/scroll/useScrollState'

interface HeaderRouteProps {
  reversed?: boolean
}

const HtmlRouteLayout: FC<HeaderRouteProps> = ({ reversed }) => {
  const { handleUnblockScroll, handleBlockScroll } = useScrollState()

  useEffect(() => {
    reversed ? handleBlockScroll() : handleUnblockScroll()

    return () => {
      reversed ? handleUnblockScroll() : handleBlockScroll()
    }
  }, [handleBlockScroll, handleUnblockScroll, reversed])

  return <Outlet />
}

export default HtmlRouteLayout
