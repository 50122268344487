import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  IFolderChangeState,
  IFolderInitializeEntity,
  TInitialStateOfFolderStore,
} from './FolderState.entity'

const initialState: TInitialStateOfFolderStore = {}

const slice = createSlice({
  name: 'folderState',
  initialState,
  reducers: {
    initializeFolderState: (state, { payload }: PayloadAction<IFolderInitializeEntity>) => {
      const currentDfoFolders = state[payload.dfoId]?.folders

      const objOfFoldersToApply = payload.folders.reduce((prevValue, currentValue) => {
        if (currentDfoFolders && currentValue.id in currentDfoFolders) {
          return prevValue
        }

        return {
          ...prevValue,
          [currentValue.id]: { isFolded: false },
        }
      }, currentDfoFolders || {})

      state[payload.dfoId] = {
        folders: objOfFoldersToApply,
      }
    },

    replaceFoldersState: (state, { payload }: PayloadAction<TInitialStateOfFolderStore>) => {
      return payload
    },

    changeFolderState: (state, { payload }: PayloadAction<IFolderChangeState>) => {
      state[payload.dfoId] = {
        folders: {
          ...state[payload.dfoId]?.folders,
          [payload.folderId]: payload.newFolderState,
        },
      }
    },
    removeFolder: (
      state,
      { payload }: PayloadAction<Omit<IFolderChangeState, 'newFolderState'>>,
    ) => {
      const modifiedState = { ...state }

      delete modifiedState[payload.dfoId].folders[payload.folderId]

      state[payload.dfoId] = {
        folders: {
          ...modifiedState[payload.dfoId]?.folders,
        },
      }
    },
  },
})

const FolderStateActions = slice.actions

export { FolderStateActions }

export default slice
