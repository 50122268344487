const folderItemReducerActionMap = {
  SETUP_FILE_LOADING: 'SETUP_FILE_LOADING',
  SETUP_FILE_ERROR: 'SETUP_FILE_ERROR',
  SETUP_FOLD_STATE: 'SETUP_FOLD_STATE',
  SETUP_FOLDER_NAME: 'SETUP_FOLDER_NAME',
  ADD_FILE: 'ADD_FILE',
  REPLACE_FILE: 'REPLACE_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  INSERT_FILE: 'INSERT_FILE',
} as const

const FOLDER_NAME_MAX_LENGTH = 100

export { FOLDER_NAME_MAX_LENGTH, folderItemReducerActionMap }
