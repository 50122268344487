import React, { FC, memo, useCallback, useEffect, useReducer } from 'react'
import { usePopupManager } from 'react-popup-manager'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { ErrorTypes, ESIA_IS_RENDER, loginFormTitle } from '@components/Forms/LoginForm/constants'
import useGovermentDomainModal from '@components/Forms/LoginForm/hooks/useGovermentDomainModal'
import useLoginErrorHandler from '@components/Forms/LoginForm/hooks/useLoginErrorHandler'
import {
  CertificateRedirectRegistrationProps,
  CertificateSignErrorProps,
} from '@components/Forms/LoginForm/LoginByCrypt/CertificateForm/types'
import {
  fromHexInnToTextInn,
  isValidFlINN,
  isValidUlINN,
} from '@components/Forms/LoginForm/LoginByCrypt/CertificateForm/utils'
import LoginError from '@components/Forms/LoginForm/shared/LoginError'
import type { ICertificate } from '@components/Forms/LoginForm/types'
import { mapOfTypeRedirect } from '@components/Forms/RegistrationForm/const'
import { RegistrationLocationState } from '@components/Forms/RegistrationForm/types'
import { RegistrationSubmitProps } from '@components/Modals/ConfidentialModal/manager'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { THUMBPRINT } from '@components/Table/constants'
import { Paths } from '@constants/paths'
import { isInvestorDomain } from '@constants/system'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { signInByCertificateTypes } from '@context/AuthContext/hooks/useAuth'
import type { ProcurationData } from '@context/AuthContext/types'
import { compact } from '@helpers/array/compact'
import { isAxiosError, isHexString, isJsError } from '@helpers/checkTypes'
import { removeQuotes } from '@helpers/string/removeQuotes'
import { useQueryManager } from '@hooks/useQueryManager'
import DayjsService from '@services/Dayjs/Dayjs.service'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import type { AxiosError } from 'axios'
import cn from 'classnames'
import { createAttachedSignature, getSystemInfo, getUserCertificates } from 'crypto-pro'

import styles from './CertificateForm.module.scss'
import { certificateComponentInfo, OIDINN, OIDINNLE } from './constants'
import DigitalSignatures from './DigitalSignatures'
import { certificateActionCreators, certificateReducer, initialCertificateState } from './reducer'

interface CertificateFormProps {
  onProcuration: (procurationList: ProcurationData, signature: string) => void
  onError: (error: unknown | null) => void
}

const CertificateForm: FC<CertificateFormProps> = ({ onProcuration, onError }) => {
  const { getDataToSign, signInByCertificate, saveCertificateData, saveProcuration } =
    useAuthContext()
  const {
    ESIAApi: { getESIALoginLink },
  } = useAPIContext()

  const navigate = useNavigate()

  const location = useLocation()
  const locationState = location.state as RegistrationLocationState | undefined

  const { queryUtils } = useQueryManager()
  const popupManager = usePopupManager()

  const [state, dispatch] = useReducer(certificateReducer, initialCertificateState)

  const { handleOpenRedirectGovermentDomainModal } = useGovermentDomainModal()

  const handleNativeReducerError = useCallback(
    (error: unknown) => {
      LoggerHelpersService.handleInternalLogError({
        componentInfo: certificateComponentInfo,
      })(error)

      if (!isJsError(error)) throw error

      dispatch(certificateActionCreators.setNativeError(error))
      onError(error)
    },
    [onError],
  )

  const handleDefaultProcessError = useCallback(
    (error: unknown) => {
      if (!isAxiosError(error)) {
        handleNativeReducerError(error)

        return
      }

      dispatch(certificateActionCreators.setError(error))

      onError(error)
    },
    [handleNativeReducerError, onError],
  )

  const handleGetInitialCertificateInfo = async () => {
    dispatch(certificateActionCreators.setLoading(true))

    try {
      await getSystemInfo()

      try {
        await getCertificates()
      } catch (error) {
        dispatch(certificateActionCreators.setCertificateMissingError())
        onError(error)
      }
    } catch (error) {
      dispatch(certificateActionCreators.setCryptoPluginError())
      onError(error)

      throw error
    } finally {
      dispatch(certificateActionCreators.setLoading(false))
    }
  }

  useEffect(() => {
    if (
      locationState?.from === mapOfTypeRedirect.UKEP &&
      locationState.state.certificate &&
      locationState.state.signature
    ) {
      (async () => {
        dispatch(certificateActionCreators.setLoading(true))

        try {
          if (locationState.state.certificate.isUL) {
            await certificateSignIn(locationState.state.signature, locationState.state.certificate)
            return
          }

          await procurationSignIn(locationState.state.signature, locationState.state.certificate)
        } catch (error) {
          await handleGetInitialCertificateInfo()

          handleDefaultProcessError(error)
          throw error
        } finally {
          dispatch(certificateActionCreators.setLoading(false))
        }
      })()

      return
    }

    (async () => {
      await handleGetInitialCertificateInfo()
    })()

    return () => {
      callbaseErrorAction()
    }
  }, [])

  const callbaseErrorAction = useCallback(() => {
    dispatch(certificateActionCreators.setLoading(false))
    dispatch(certificateActionCreators.setCaption(''))
  }, [])

  const handleClearReducerError = useCallback(() => {
    dispatch(certificateActionCreators.setLoading(false))
    dispatch(certificateActionCreators.setError(null))
  }, [])

  const handleReducerError = useCallback(
    (error: AxiosError) => {
      dispatch(certificateActionCreators.setError(error))

      onError(error)
    },
    [onError],
  )

  const handleUserContinue = useCallback(
    async (signature: string, thumbprint: string) => {
      try {
        await signInByCertificate?.({
          type: signInByCertificateTypes.default,
          body: {
            data: signature,
            client_id: 'signature-kap-client',
            grant_type: 'password',
            namePublicityAccepted: true,
          },
        })

        localStorage.setItem(THUMBPRINT, thumbprint)
      } catch (error) {
        if (!isAxiosError(error)) {
          handleNativeReducerError(error)

          throw error
        }

        dispatch(certificateActionCreators.setError(error))
        onError(error)

        throw error
      } finally {
        callbaseErrorAction()
      }
    },
    [callbaseErrorAction, handleNativeReducerError, onError, signInByCertificate],
  )

  const onGovermentDomainModalOpen = useCallback(
    (domain: string) => {
      callbaseErrorAction()
      dispatch(certificateActionCreators.setError(null))

      handleOpenRedirectGovermentDomainModal(domain)
    },
    [callbaseErrorAction, handleOpenRedirectGovermentDomainModal],
  )

  const notAllowedDomainHandler = useCallback(
    (error: AxiosError) => {
      const { error_description: domain, error: error_type } = error.response?.data || {}

      if (domain && error_type === ErrorTypes.domain_not_allowed) {
        onGovermentDomainModalOpen(domain)
        return
      }

      dispatch(certificateActionCreators.setError(error))
    },
    [onGovermentDomainModalOpen],
  )

  const handleCloseConfidentialModal = useCallback(() => {
    popupManager.closeAll()
    handleClearReducerError()
  }, [handleClearReducerError, popupManager])

  const handleSubmitConfidentialModal = useCallback(
    async ({ isUserContinue, signature, thumbprint }: RegistrationSubmitProps) => {
      if (isUserContinue && signature && thumbprint) {
        popupManager.closeAll()
        return await handleUserContinue(signature, thumbprint)
      }
    },
    [handleUserContinue, navigate, popupManager],
  )

  const handleRegistrationRedirect = useCallback(
    ({ certificate, signature, authenticationKey }: CertificateRedirectRegistrationProps) => {
      if (!authenticationKey || !certificate || !signature) return

      const stateToRegistration = {
        from: mapOfTypeRedirect.UKEP,
        authenticationKey,
        state: {
          certificate: {
            ...certificate,
            certificate: null,
          },
          signature,
        },
      }

      navigate(Paths.Registration, {
        state: stateToRegistration,
      })
    },
    [navigate],
  )

  const { handleErrorByType } = useLoginErrorHandler({
    notAllowedDomainHandler,
  })

  const handleSignInError = useCallback(
    ({ error, certificate, signature, thumbprint }: CertificateSignErrorProps) => {
      if (!isAxiosError(error)) {
        handleNativeReducerError(error)

        throw error
      }

      const { response } = error
      const authenticationKey = response?.data?.authenticationKey as string | undefined

      handleErrorByType({
        error,
        onRegistrationRedirect: () =>
          authenticationKey
            ? handleRegistrationRedirect({
                authenticationKey,
                certificate,
                signature,
              })
            : undefined,
        onSubmit: ({ isUser }) =>
          handleSubmitConfidentialModal({
            signature,
            thumbprint,
            isUserContinue: isUser,
          }),
        onClose: handleCloseConfidentialModal,
        onError: handleReducerError,
      })
    },
    [
      handleCloseConfidentialModal,
      handleErrorByType,
      handleNativeReducerError,
      handleReducerError,
      handleRegistrationRedirect,
      handleSubmitConfidentialModal,
    ],
  )

  const certificateSignIn = useCallback(
    async (signature: string, certificate: ICertificate) => {
      try {
        await signInByCertificate?.({
          type: signInByCertificateTypes.default,
          body: {
            data: signature,
          },
        })
        saveCertificateData?.(certificate)

        localStorage.setItem(THUMBPRINT, certificate.fingerprint || '')
      } catch (error) {
        handleSignInError({ error, signature, certificate, thumbprint: certificate.fingerprint })
      }
    },
    [handleSignInError, saveCertificateData, signInByCertificate],
  )

  const procurationSignIn = useCallback(
    async (signature: string, certificate: ICertificate) => {
      try {
        console.log(JSON.parse(JSON.stringify(signature)))
        const data: ProcurationData = await saveProcuration?.({ data: signature })
        onProcuration(data, signature)

        localStorage.setItem(THUMBPRINT, certificate.fingerprint || '')
      } catch (error) {
        handleSignInError({ certificate, signature, error, thumbprint: certificate.fingerprint })
      }
    },
    [handleSignInError, onProcuration, saveProcuration],
  )

  const getSignature = useCallback(
    async (dataUid: string, certificate: ICertificate) => {
      try {
        console.log(certificate.fingerprint, dataUid)
        const signature = await createAttachedSignature(certificate.fingerprint, dataUid)
        console.log(
          JSON.parse(
            JSON.stringify({
              fingerprint: certificate.fingerprint,
              dataUid,
              signature,
              isUL: certificate.isUL,
            }),
          ),
        )

        if (signature && certificate.isUL) {
          return await certificateSignIn(signature, certificate)
        }

        return await procurationSignIn(signature, certificate)
      } catch (error) {
        handleDefaultProcessError(error)
        throw error
      }
    },
    [certificateSignIn, handleDefaultProcessError, procurationSignIn],
  )

  const filterCertificatesByINN = useCallback((certificates: (ICertificate | null)[]) => {
    return compact(certificates).sort(
      (firstCertificate, lastCertificate) =>
        Number(firstCertificate?.inn || 0) - Number(lastCertificate?.inn || 0),
    ) as ICertificate[]
  }, [])

  const getOIDWithPrefix = (OIDKey: string) => `OID.${OIDKey}`

  const getCertificates = async () => {
    try {
      const certificates = await getUserCertificates()
      const certs = certificates?.filter(async (item) => await item.isValid())

      const OIDINNLEWithPrefix = getOIDWithPrefix(OIDINNLE)
      const OIDINNWithPrefix = getOIDWithPrefix(OIDINN)

      const formatCertificates = certs?.map(async (item): Promise<ICertificate | null> => {
        const info = await item.getOwnerInfo()

        let ulInn =
          info.find(({ title }) => title === OIDINNLE)?.description ||
          info.find(({ title }) => title === OIDINNLEWithPrefix)?.description ||
          info.find(({ title }) => title === 'ИНН ЮЛ')?.description ||
          info.find(({ title }) => title === 'INNLE')?.description

        let flInn =
          info.find(({ title }) => title === OIDINN)?.description ||
          info.find(({ title }) => title === OIDINNWithPrefix)?.description ||
          info.find(({ title }) => title === 'ИНН')?.description ||
          info.find(({ title }) => title === 'INN')?.description

        const validTo = item?.validTo

        const isValidDate =
          DayjsService.dayjsWithFormatToMSK(validTo) >= DayjsService.dayjsWithFormatToMSK()

        if ((!ulInn && !flInn) || !isValidDate) return null

        const isUL = (() => {
          if (!ulInn) return false

          if (isHexString(ulInn)) {
            ulInn = fromHexInnToTextInn(ulInn)
          }

          return isValidUlINN(ulInn)
        })()

        const isFl = (() => {
          if (!flInn) return false

          if (isHexString(flInn)) {
            flInn = fromHexInnToTextInn(flInn)
          }

          return isValidFlINN(flInn)
        })()

        if (!isUL && !isFl) return null

        const name = info.find(({ title }) => title === 'Имя Отчество')?.description
        const surName = info.find(({ title }) => title === 'Фамилия')?.description
        const owner = info.find(({ title }) => title === 'Владелец')?.description
        const thumbprint = item?.thumbprint
        const company = info.find(({ title }) => title === 'Компания')?.description

        return {
          isUL,
          id: thumbprint,
          company: removeQuotes(company),
          inn: isUL ? ulInn : flInn,
          name: !surName && !name ? removeQuotes(owner) : `${surName} ${name}`,
          fingerprint: thumbprint,
          date: validTo,
          certificate: item._cadesCertificate,
        }
      })

      const awaitedCertificates = await Promise.all(formatCertificates)

      if (!certificates.length) throw new Error()

      const filteredCertificates = filterCertificatesByINN(awaitedCertificates)
      if (!filteredCertificates.length) throw new Error()

      dispatch(certificateActionCreators.setCertificates(filteredCertificates))
    } catch (error) {
      LoggerHelpersService.handleInternalLogError({
        componentInfo: certificateComponentInfo,
      })(error)
      dispatch(certificateActionCreators.setCertificateMissingError())

      onError(error)
      throw error
    }
  }

  const handleGoToESIA = async () => {
    try {
      await getESIALoginLink()
    } catch (error) {
      handleDefaultProcessError(error)
      throw error
    }
  }

  const handleBackButton = useCallback(() => {
    if (!state.certificates.length) {
      dispatch(certificateActionCreators.setCertificateMissingError())

      return
    }

    handleClearReducerError()
    onError(null)
  }, [handleClearReducerError, onError, state.certificates.length])

  const getDataUid = useCallback(async () => {
    try {
      const { data } = await getDataToSign?.()

      return data
    } catch (error) {
      handleDefaultProcessError(error)
      throw error
    }
  }, [getDataToSign, handleDefaultProcessError])

  const handleSelectCertificate = useCallback(
    async (certificate: ICertificate) => {
      dispatch(certificateActionCreators.setLoading(true))

      try {
        const dataUid = await getDataUid()
        await getSignature(dataUid, certificate)
      } catch (error) {
        onError(error)
        throw error
      } finally {
        dispatch(certificateActionCreators.setLoading(false))
      }
    },
    [getDataUid, getSignature, onError],
  )

  const isShowLoginError = Boolean(state.error) || state.isLoading

  const esiaButtonParam = queryUtils.getQuery('showMeEsiaButton')
  const ESIAButtonIsRender = (!ESIA_IS_RENDER && esiaButtonParam === 'true') || ESIA_IS_RENDER

  const loginTitle = isInvestorDomain ? loginFormTitle.investor : loginFormTitle.goverment

  if (isShowLoginError) {
    return (
      <>
        <LoginError
          isLoading={state.isLoading}
          isHideButtons={state.isHideButtons}
          error={state.error}
          caption={state.caption}
          goToFormHandler={handleBackButton}
          className={cn({
            [styles['login-error__container']]:
              ESIAButtonIsRender && state.isHideButtons && !state.isLoading,
          })}
        />
        {ESIAButtonIsRender && state.isHideButtons && !state.isLoading && (
          <div className={styles.button__ESIA}>
            <Button variant="buttonSMedium" view="plain" onClick={handleGoToESIA}>
              Войти через ЕСИА
            </Button>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div className={cn(styles.stretch)}>
        <Typography.Headline variant="headlineH2" align="center" className={styles.title}>
          {loginTitle}
        </Typography.Headline>
        <div className={styles.form}>
          <DigitalSignatures
            certificates={state.certificates}
            onSelectCertificate={handleSelectCertificate}
          />
        </div>
        {ESIAButtonIsRender && (
          <div className={styles.button__ESIA}>
            <Button
              variant="buttonSMedium"
              view="plain"
              dataTestId="CertificateForm-ESIA-button"
              onClick={handleGoToESIA}
            >
              Войти через ЕСИА
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default memo(CertificateForm)
