import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { IOrganizationInfo } from '@services/Organization/organization.entity'
import useSWRImmutable from 'swr/immutable'

const useMunicipalityMembersById = ({ key, config }: SWRHookProps<IOrganizationInfo[]>) => {
  const {
    organizationsApi: { getMunicipalityMembersById },
  } = useAPIContext()

  const {
    data: municipalityMembers,
    error,
    isValidating: isLoadingMunicipalityMembers,
    mutate: revalidateMunicipalityMembers,
  } = useSWRImmutable(
    key,
    ({ organizationId }) => getMunicipalityMembersById(organizationId),
    config,
  )

  return {
    municipalityMembers,
    error,
    isLoadingMunicipalityMembers,
    revalidateMunicipalityMembers,
  }
}

export { useMunicipalityMembersById }
