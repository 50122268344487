import React, { FC } from 'react'

import cn from 'classnames'

import styles from './Container.module.scss'

export interface ContainerProps {
  className?: string
}

const Container: FC<ContainerProps> = ({ children, className }) => {
  return <div className={cn(styles.container, className)}>{children}</div>
}

export default Container
