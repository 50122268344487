import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForNpaForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedChosenParts = (() => {
    if (
      !isArray(objectFromPropertyState.stabilizationNpas?.value) ||
      !objectFromPropertyState.stabilizationNpas?.value.length
    )
      return null

    const npaParts = objectFromPropertyState.stabilizationNpas.value.map((npa) => ({
      propertyId: npa?.propertyId,
      type: npa?.type,
      lastUpdateDt: npa?.lastUpdateDt,
      value: {
        justification: {
          propertyId: npa?.value?.stabilizationNpaJustification?.propertyId,
          type: npa?.value?.stabilizationNpaJustification?.type,
          lastUpdateDt: npa?.value?.stabilizationNpaJustification?.lastUpdateDt,
        },
        partCorrection: {
          propertyId: npa?.value?.stabilizationNpaCorrection?.propertyId,
          type: npa?.value?.stabilizationNpaCorrection?.type,
          lastUpdateDt: npa?.value?.stabilizationNpaCorrection?.lastUpdateDt,
        },
        npaId: {
          propertyId: npa?.value?.stabilizationNpaId?.propertyId,
          type: npa?.value?.stabilizationNpaId?.type,
          lastUpdateDt: npa?.value?.stabilizationNpaId?.lastUpdateDt,
        },
        partId: {
          propertyId: npa?.value?.stabilizationNpaPartId?.propertyId,
          type: npa?.value?.stabilizationNpaPartId?.type,
          lastUpdateDt: npa?.value?.stabilizationNpaPartId?.lastUpdateDt,
        },
      },
    }))

    return {
      npaParts,
    }
  })()

  return {
    headerInfo: {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    npa: {
      chosenParts: {
        propertyId: objectFromPropertyState.stabilizationNpas?.propertyId,
        lastUpdateDt: objectFromPropertyState.stabilizationNpas?.lastUpdateDt,
        type: objectFromPropertyState.stabilizationNpas?.type,
        value: preparedChosenParts,
      },
    },
  }
}

export { generatePropertiesObjectForNpaForm }
