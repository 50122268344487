import { FC } from 'react'

import useAuthUser from '@hooks/useAuthUser'
import EmailApproved from '@layouts/Header/Message/EmailApproved'
import { EmailApproving } from '@layouts/Header/Message/EmailApproving'
import { EmailIndication } from '@layouts/Header/Message/EmailIndication'

interface EmailMessageProps {
  isModalOpen: boolean
  openModal: VoidFunction
  closeModal: VoidFunction
}

const EmailMessage: FC<EmailMessageProps> = ({ isModalOpen, openModal, closeModal }) => {
  const { currentUserFullInfo, email, isEmailVerified, isEmailApproved } = useAuthUser()

  if (!currentUserFullInfo) return null

  // если подтвердили почту через письмо. Единоразовые сообщения.
  if (isEmailApproved) return <EmailApproved />

  // если почта не указана
  if (!email)
    return (
      <EmailIndication
        userInfo={currentUserFullInfo}
        isOpen={isModalOpen}
        onOpen={openModal}
        onClose={closeModal}
      />
    )

  // если почта указана, но не подтверждена
  if (!isEmailVerified)
    return (
      <EmailApproving
        userInfo={currentUserFullInfo}
        isOpen={isModalOpen}
        onOpen={openModal}
        onClose={closeModal}
      />
    )

  return null
}

export default EmailMessage
