import React from 'react'
import { useFormContext } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { supplementalCostSharingABlockValues } from '@components/Forms/SupplementalCostSharingAForm/const'
import { useSupplementalCostSharingAManager } from '@components/Forms/SupplementalCostSharingAForm/Manager'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import { SupplementalCostSharingAFieldsControlUpdateWatcher } from '@components/Forms/SupplementalCostSharingAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { sixthSectionValidationMap } from './validation'

const Sixth = () => {
  const formInstance = useFormContext<SupplementalCostSharingAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSupplementalCostSharingAManager()

  const { getSubscribableControlProps, getAmountInputProps, getInputProps, getCalendarInputProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: SupplementalCostSharingAFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: supplementalCostSharingABlockValues['6'].contractDutyNumber,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: supplementalCostSharingABlockValues['6'].contractDutyNumber,
                  rules: sixthSectionValidationMap.contractDutyNumber,
                  inputProps: {
                    label: 'Номер договора',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        supplementalCostSharingABlockValues['6'].contractDutyNumber,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        supplementalCostSharingABlockValues['6'].contractDutyNumber,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: supplementalCostSharingABlockValues['6'].contractDutyDate,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: supplementalCostSharingABlockValues['6'].contractDutyDate,
                  rules: sixthSectionValidationMap.contractDutyDate,
                  calendarInputProps: {
                    label: 'Дата заключения договора',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          supplementalCostSharingABlockValues['6'].contractDutyDate,
                        ),
                      0,
                    ),
                  onInputChange: () =>
                    setTimeout(
                      () =>
                        debouncedHandleChangeValue?.(
                          supplementalCostSharingABlockValues['6'].contractDutyDate,
                        ),
                      0,
                    ),
                  onCalendarChange: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          supplementalCostSharingABlockValues['6'].contractDutyDate,
                        ),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: supplementalCostSharingABlockValues['6'].contractDutyTerms,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: supplementalCostSharingABlockValues['6'].contractDutyTerms,
                  rules: sixthSectionValidationMap.contractDutyTerms,
                  textareaProps: {
                    label: 'Иные существенные условия договора',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        supplementalCostSharingABlockValues['6'].contractDutyTerms,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        supplementalCostSharingABlockValues['6'].contractDutyTerms,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Объем осуществленных затрат на объекты'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: supplementalCostSharingABlockValues['6'].objectsInfrastructureInvestorCost,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: supplementalCostSharingABlockValues['6'].objectsInfrastructureInvestorCost,
                rules: sixthSectionValidationMap.objectsInfrastructureInvestorCost,
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      supplementalCostSharingABlockValues['6'].objectsInfrastructureInvestorCost,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      supplementalCostSharingABlockValues['6'].objectsInfrastructureInvestorCost,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </Container>
  )
}

export default Sixth
