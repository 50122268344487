import env from '@config'

export const IS_DEVELOPMENT = env.NODE_ENV === 'development'

export const governmentDomain = env.REACT_APP_GOVERNMENT_DOMAIN
export const isProdFlag = env.REACT_APP_IS_PROD_FLAG === 'true'

export const currentOrigin = window.location.origin

export const isGovermentDomain = governmentDomain === currentOrigin
export const isInvestorDomain = !isGovermentDomain

// @ts-ignore
export const isYandexBrowser = window?.yandex

export const isLoginByLP = env.REACT_APP_BUILD_TYPE === 'login_password'
export const isLoginByCrypt = env.REACT_APP_BUILD_TYPE === 'signature'
