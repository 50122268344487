import React, { FC } from 'react'
import { Path, useFormContext } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { secondSectionValidationMap } from '@components/Forms/NotificationOfChangeDocumentsForm/Forms/2/validation'
import { useNotificationOfChangeDocumentsManager } from '@components/Forms/NotificationOfChangeDocumentsForm/Manager'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'
import { NotificationOfChangeDocumentsFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfChangeDocumentsForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CharacteristisOfDocumentItemProps {
  id: string
  anchorId: string
  name: string
  onRemoveCharacteristicOfDocument: VoidFunction
}

const CharacteristicOfDocumentItem: FC<CharacteristisOfDocumentItemProps> = ({
  id,
  anchorId,
  name,
  onRemoveCharacteristicOfDocument,
}) => {
  const formInstance = useFormContext<NotificationOfChangeDocumentsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfChangeDocumentsManager()

  const { getSubscribableControlProps, getInputProps, getTextareaProps } =
    useFormComponentPresets<NotificationOfChangeDocumentsFormValues>({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: NotificationOfChangeDocumentsFieldsControlUpdateWatcher,
    })

  return (
    <FieldView.StepTemplate
      id={id}
      anchorId={anchorId}
      onRemoveStep={editMode ? onRemoveCharacteristicOfDocument : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.changeDocumentName` as Path<NotificationOfChangeDocumentsFormValues>,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.changeDocumentName` as Path<NotificationOfChangeDocumentsFormValues>,
                  rules: secondSectionValidationMap.changeDocumentName,
                  textareaProps: {
                    label: 'Наименование документа',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          `${name}.changeDocumentName` as Path<NotificationOfChangeDocumentsFormValues>,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        `${name}.changeDocumentName` as Path<NotificationOfChangeDocumentsFormValues>,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.changeDocumentCharacteristic` as Path<NotificationOfChangeDocumentsFormValues>,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.changeDocumentCharacteristic` as Path<NotificationOfChangeDocumentsFormValues>,
                  rules: secondSectionValidationMap.changeDocumentCharacteristic,
                  textareaProps: {
                    label: 'Характеристика изменений',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          `${name}.changeDocumentCharacteristic` as Path<NotificationOfChangeDocumentsFormValues>,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        `${name}.changeDocumentCharacteristic` as Path<NotificationOfChangeDocumentsFormValues>,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default CharacteristicOfDocumentItem
