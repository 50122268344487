import { useAPIContext } from '@context/APIContext'
import useSWRImmutable from 'swr/immutable'

const useOrganizationActions = ({ key, config }) => {
  const {
    organizationApi: { getOrganizationActions },
  } = useAPIContext()

  const {
    data: actions,
    error,
    mutate,
    isValidating: isLoading,
  } = useSWRImmutable(key, getOrganizationActions, config)

  return {
    actions,
    error,
    mutate,
    isLoading,
  }
}

export { useOrganizationActions }
