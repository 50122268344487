import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'

const generateMenuListForInformationOfExecutionAgreementEventsForm = (
  formValues:
    | InformationOfExecutionAgreementEventsFormValues
    | UnpackNestedValue<InformationOfExecutionAgreementEventsFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<InformationOfExecutionAgreementEventsFormValues>,
): NestedMapOfMenu[] => {
  const preparedStepsMonitoring: NestedMapOfMenu[] =
    formValues?.['1']?.stepsMonitoring.map((step, index) => {
      const stepError = formErrors['1']?.stepsMonitoring?.[index]
      const stepId = `${initialHashMap['1'].path}.stepsMonitoring.${index}`

      return {
        path: stepId,
        id: step.anchorId,
        hasError: !!stepError,
        isCollapsed: false,
        title: `${step.stepTitle}`,
        parentBaseId: initialHashMap['1'].id,
        children: [],
      }
    }) || []

  const preparedHashMap = {
    '1': {
      ...initialHashMap['1'],
      hasError: preparedStepsMonitoring.some(({ hasError }) => hasError),
      children: preparedStepsMonitoring,
    },
  }

  return [preparedHashMap['1']]
}

export { generateMenuListForInformationOfExecutionAgreementEventsForm }
