import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu } from '@components/DocumentFormComponents/types'

const parametersApplicationReimbursementExpensesMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Организация, реализующая проект',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'О проекте',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '3': {
    id: '3',
    path: '3',
    title: 'Данные о предоставленном возмещении',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
}

const parametersApplicationReimbursementExpensesMenuState = [
  parametersApplicationReimbursementExpensesMenuHashMap['1'],
  parametersApplicationReimbursementExpensesMenuHashMap['2'],
  parametersApplicationReimbursementExpensesMenuHashMap['3'],
]

const mapOfMenu = parametersApplicationReimbursementExpensesMenuState.map(
  (menu): MapOfMenu => ({
    id: menu.id,
    title: menu.title,
  }),
)

export {
  mapOfMenu,
  parametersApplicationReimbursementExpensesMenuHashMap,
  parametersApplicationReimbursementExpensesMenuState,
}
