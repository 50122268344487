import { Dayjs } from 'dayjs'

const generateMonthDays = (date: Dayjs) => {
  const daysInMonth = date.daysInMonth()

  return Array.from(
    {
      length: daysInMonth,
    },
    (_, i) => i + 1,
  )
}

export { generateMonthDays }
