import { usePopupManager } from 'react-popup-manager'

import { IViolation } from '@components/DocumentFormComponents/types'
import { useCreateStatementOldControl } from '@components/Forms/CreateStatementOld'
import { NpaMunicipalAddonThreeManager } from '@components/Forms/NpaMunicipalForm/watcher'
import ErrorModal from '@components/NewDesignedModals/ErrorModal'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import type { DraftEnumError } from '@constants/errorCodes'
import { mapOfCustomErrorCodes } from '@constants/errorCodes'
import {
  defaultMessageSupport,
  errorModalBodyTexts,
  errorModalButtonTexts,
  errorModalHeaderTexts,
} from '@constants/texts'
import { NewDfosType } from '@constants/types'
import { NpaAddonThreeManager } from '@helpers/watcher'
import { useQueryManager } from '@hooks/useQueryManager'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import { AxiosError } from 'axios'

interface DraftError {
  violations: IViolation[]
  detail: string
  errorCode: DraftEnumError | null
}

interface DraftErrorProps {
  projectId?: string
}

interface HandleDraftErrorProps {
  error: AxiosError<DraftError>
  SZPKDocumentsSetId?: string
  projectDfos?: IDfoListItem[]
}

const useDraftError = ({ projectId }: DraftErrorProps) => {
  const { handleOpenErrorModal } = useErrorModal()
  const popupManager = usePopupManager()

  const { openStatementOldModal } = useCreateStatementOldControl()

  const { queryUtils } = useQueryManager()

  const handleErrorFromDraftConvert = ({
    error,
    SZPKDocumentsSetId,
    projectDfos,
  }: HandleDraftErrorProps) => {
    switch (error.response?.status) {
      case 400:
        switch (error.response?.data.errorCode) {
          // TODO: Доделать onClick
          case mapOfCustomErrorCodes.STATEMENT_DRAFT:
            handleOpenErrorModal({
              bodyText: errorModalBodyTexts.statementFixNecessary,
              headerText: errorModalHeaderTexts.statementIncorrect,
              customActions: {
                actions: [
                  {
                    dataTestId: 'ErrorModal-fix-button',
                    children: errorModalButtonTexts.fixMessage,
                    fixWidth: true,
                    onClick: () => {
                      //Находим СЗПК с заявлением
                      const dfoToRedirect = projectDfos?.find(
                        (dfo) => dfo.type === NewDfosType.SZPK,
                      )

                      //Если нет редиректа, то закрываем модалку
                      if (!dfoToRedirect || !SZPKDocumentsSetId) {
                        return popupManager.open(ErrorModal, {
                          headerText: errorModalHeaderTexts.defaultMessage,
                          bodyText: defaultMessageSupport,
                          onClose: () => null,
                        })
                      }

                      //Редиректим на dfo
                      queryUtils.addQuery({
                        query: {
                          dfoId: dfoToRedirect.id,
                        },
                      })

                      popupManager.closeAll()

                      //Открываем заявление
                      openStatementOldModal(
                        {
                          dfoId: dfoToRedirect.id,
                          projectId,
                          documentSetId: SZPKDocumentsSetId,
                        },
                        (error as AxiosError)?.response?.data.violations,
                      )
                    },
                  },
                ],
                mergeDefault: false,
              },
            })
            break

          case mapOfCustomErrorCodes.NPA_DRAFT:
            handleOpenErrorModal({
              headerText: errorModalHeaderTexts.npaListIncorrect,
              bodyText: error.response?.data.detail || errorModalBodyTexts.defaultMessage,
              customActions: {
                actions: [
                  {
                    dataTestId: 'ErrorModal-returnToNpaList-button',
                    children: errorModalButtonTexts.returnToNpaList,
                    fixWidth: true,
                    onClick: () => {
                      //Находим СЗПК с НПА
                      const dfoToRedirect = projectDfos?.find(
                        (dfo) => dfo.type === NewDfosType.NPA_LIST,
                      )

                      //Если нет редиректа, то закрываем модалку
                      if (!dfoToRedirect) return popupManager.closeAll()

                      //Если текущий dfoId, это НПА, то необходимо пересобрать дерево
                      if (dfoToRedirect.id === queryUtils.getQuery('dfoId')) {
                        NpaAddonThreeManager.setStaleStatus(true)
                        NpaMunicipalAddonThreeManager.setStaleStatus(true)
                      } else {
                        //Редиректим на dfo
                        queryUtils.addQuery({
                          query: {
                            dfoId: dfoToRedirect.id,
                          },
                        })
                      }

                      popupManager.closeAll()
                    },
                  },
                ],
                mergeDefault: false,
              },
            })
            break

          default:
            throw error
        }
        break

      default:
        throw error
    }
  }

  return {
    handleErrorFromDraftConvert,
  }
}

export { useDraftError }
