import React, { FC, memo } from 'react'

import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import WebFormDocumentItem from '@components/Attachments/WebForm/WithDocumentController/DocumentItem'
import WebFormDocumentStatus from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DocumentStatus'
import WebFormDocumentDownloadButton from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DownloadButton'
import WebFormDocumentFormButton from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/FormButton'
import type { WebFormStateWithDocumentControllerStateProps } from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/types'

const WebFormMovingStateWithDocumentController: FC<
  WebFormStateWithDocumentControllerStateProps
> = ({
  downloadProps,
  digitizingInProcess,
  permissions,
  document,
  documentInfoFromDict,
  statusType,
  onControlClick,
}) => {
  const { description: title } = documentInfoFromDict
  const { isWebFormReadDownloadingForbidden } = useWebFormPermissions(permissions)

  if (!digitizingInProcess && isWebFormReadDownloadingForbidden) {
    return (
      <WebFormDocumentItem
        title={title}
        document={document}
        leadingNodeContent={statusType && <WebFormDocumentStatus type={statusType} />}
        onClick={onControlClick}
      />
    )
  }

  return (
    <WebFormDocumentItem
      title={title}
      document={document}
      trailingNodeContent={
        <>
          <WebFormDocumentFormButton
            disabled
            tooltipProps={{
              content: 'Идет подготовка формы, пожалуйста, подождите',
            }}
            loaderProps={{
              placement: 'leading',
              loading: true,
              variant: 'lite',
            }}
          >
            Открыть форму
          </WebFormDocumentFormButton>
          <WebFormDocumentDownloadButton {...downloadProps} />
        </>
      }
      onClick={onControlClick}
    />
  )
}

export default memo(WebFormMovingStateWithDocumentController)
