import React, { FC } from 'react'

import { spring } from '@components/ReactFlipToolkit'
import { Flipped } from '@components/ReactFlipToolkit'
import { FlippedProps } from '@components/ReactFlipToolkit/types'
import { isObject, isUndefined } from '@helpers/checkTypes'

interface FlippedWithCollapse extends Omit<FlippedProps, 'onAppear' | 'onExit'> {
  unmountOnExit?: boolean
  dataTestId?: string
  disableExitAnimation?: boolean
  disableAppearAnimation?: boolean
  /**
   * Кастомный хендлер, который перезаписывает анимацию на onAppear
   */
  onCustomAppear?: FlippedProps['onAppear']

  /**
   * Кастомный хендлер, который перезаписывает анимацию на onExit
   */
  onCustomExit?: FlippedProps['onExit']
}

const defaultSpringOption: FlippedProps['spring'] = {
  overshootClamping: true,
}

const FlippedWithCollapse: FC<FlippedWithCollapse> = ({
  unmountOnExit = true,
  disableExitAnimation = false,
  disableAppearAnimation = false,
  onCustomAppear,
  onCustomExit,
  spring: springOption,
  children,
  dataTestId,
  ...rest
}) => {
  const handleAppearAnimation: FlippedProps['onAppear'] = (element: HTMLElement, index) => {
    if (disableAppearAnimation) {
      element.style.opacity = '1'
      element.style.height = 'auto'
      return
    }

    element.style.height = '0px'

    return spring({
      config: {
        overshootClamping: true,
      },
      values: {
        height: [0, element.scrollHeight],
        opacity: [0, 1],
      },
      onUpdate: (values) => {
        if (!isObject(values)) return

        if ('height' in values) {
          element.style.height = `${values['height']}px`
        }

        if ('opacity' in values) {
          element.style.opacity = `${values['opacity']}`
        }
      },
      onComplete: () => {
        element.style.height = 'auto'
      },
    })
  }

  const handleExitAnimation: FlippedProps['onExit'] = (
    element: HTMLElement,
    _index: number,
    removeElement: VoidFunction,
  ) => {
    if (disableExitAnimation) {
      element.style.height = '0px'
      element.style.opacity = '0'
      element.style.transform = `scaleY(0)`

      if (unmountOnExit) removeElement()

      return
    }

    const height = element.scrollHeight

    element.style.transformOrigin = 'top'

    spring({
      config: {
        overshootClamping: true,
      },
      values: {
        height: [height, 0],
        scaleY: [1, 0],
        opacity: [1, 0],
      },
      onUpdate: (values) => {
        if (!isObject(values)) return

        if ('height' in values) {
          element.style.height = `${values['height']}px`
        }

        if ('scaleY' in values) {
          element.style.transform = `scaleY(${values.scaleY})`
        }

        if ('opacity' in values) {
          element.style.opacity = `${values['opacity']}`
        }
      },
      onComplete: () => {
        if (!unmountOnExit) return

        removeElement()
      },
    })

    return () => {
      element.style.transform = ''
      element.style.height = ''
      element.style.opacity = ''

      removeElement()
    }
  }

  return (
    <Flipped
      {...rest}
      spring={!isUndefined(springOption) ? springOption : defaultSpringOption}
      onAppear={!isUndefined(onCustomAppear) ? onCustomAppear : handleAppearAnimation}
      onExit={!isUndefined(onCustomExit) ? onCustomExit : handleExitAnimation}
    >
      <div data-testid={dataTestId}>{children}</div>
    </Flipped>
  )
}

export default FlippedWithCollapse
