import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import ParametersApplicationReimbursementExpensesMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import ParametersApplicationReimbursementExpensesLayout from './Layout'
import ParametersApplicationReimbursementExpensesModalManager from './Manager'
import ParametersApplicationReimbursementExpensesMenu from './Menu'
import ParametersApplicationReimbursementExpensesStatementForm from './Statement'

interface IParametersApplicationReimbursementExpenses extends FC {
  Layout: typeof ParametersApplicationReimbursementExpensesLayout
  Menu: typeof ParametersApplicationReimbursementExpensesMenu
  StatementForm: typeof ParametersApplicationReimbursementExpensesStatementForm
  Manager: typeof ParametersApplicationReimbursementExpensesModalManager
  MenuManager: typeof ParametersApplicationReimbursementExpensesMenuManager
}

const useParametersApplicationReimbursementExpensesControl = () => {
  const popupManager = usePopupManager()

  const openParametersApplicationReimbursementExpensesModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(ParametersApplicationReimbursementExpensesLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openParametersApplicationReimbursementExpensesModal }
}

const ParametersApplicationReimbursementExpenses: IParametersApplicationReimbursementExpenses =
  () => null

ParametersApplicationReimbursementExpenses.Menu = ParametersApplicationReimbursementExpensesMenu
ParametersApplicationReimbursementExpenses.Layout = ParametersApplicationReimbursementExpensesLayout
ParametersApplicationReimbursementExpenses.StatementForm =
  ParametersApplicationReimbursementExpensesStatementForm
ParametersApplicationReimbursementExpenses.Manager =
  ParametersApplicationReimbursementExpensesModalManager
ParametersApplicationReimbursementExpenses.MenuManager =
  ParametersApplicationReimbursementExpensesMenuManager

export { useParametersApplicationReimbursementExpensesControl }
export default ParametersApplicationReimbursementExpenses
