import { FC, memo, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { SubmitInnChangeModal } from '@components/Modals/SubmitInnChangeModal/Component'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import { ControlledSingleSelect } from '@components/NewDesign/Select'
import Sidebar from '@components/NewDesign/Sidebar'
import { useRequests } from '@components/Sidebars/AuthorizedOrganizations/OIV/useRequests'
import styles from '@components/Sidebars/AuthorizedOrganizations/OMSU/Item.module.scss'
import { defaultRHFValidation } from '@constants/validations'
import { IAuthorizedOrganizationItem } from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { useRegionsToSelect } from '@hooks/useRegionsToSelect'

interface ItemAuthorizedOrganizationsProps {
  organization?: IAuthorizedOrganizationItem

  onReloadGrid?: () => void
}

type ItemOIVFormValues = {
  regionCode: string
  organizationName: string
  inn: string
  link: string
}

type ItemOMSUAuthorizedOrganizationsSidebarProps = PopupProps & ItemAuthorizedOrganizationsProps

const useItemOIVAuthorizedOrganizationsSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenItemOIVAuthorizedOrganizationsSidebar = (
    props: ItemAuthorizedOrganizationsProps,
  ) => {
    popupManager.open(ItemOIVAuthorizedOrganizationsSidebar, props)
  }

  return {
    handleOpenItemOIVAuthorizedOrganizationsSidebar,
  }
}

const ItemOIVAuthorizedOrganizationsSidebar: FC<ItemOMSUAuthorizedOrganizationsSidebarProps> = ({
  organization,
  onReloadGrid,
  isOpen,
  onClose,
}) => {
  const regionsToSelect = useRegionsToSelect()

  const closeHandler = () => onClose?.()

  const handleCloseWithRevalidateGrid = () => {
    onReloadGrid?.()
    closeHandler()
  }

  const { control, handleSubmit, watch } = useForm<ItemOIVFormValues>({
    defaultValues: {
      regionCode: organization?.region?.toString() ?? '',
      organizationName: organization?.organizationName ?? '',
      inn: organization?.inn ?? '',
      link: organization?.link ?? '',
    },
  })

  const inn = watch('inn')
  const organizationName = watch('organizationName')
  const regionCode = watch('regionCode')
  const link = watch('link')

  const {
    closeModal,
    handleCreateOIV,
    handleUpdateOIV,
    isModalOpen,
    isRequestLoading,
    handleUpdateOIVWithNewInn,
  } = useRequests({
    organization: organization,
    onClose: handleCloseWithRevalidateGrid,
    values: {
      inn,
      regionCode,
      link,
      organizationName,
    },
  })

  const isInnCorrect = inn.length === 10
  const canCreateOIV = Boolean(regionCode && organizationName && isInnCorrect)

  const currentActions = useMemo(() => {
    const loadingProps = {
      loaderProps: {
        loading: isRequestLoading || isModalOpen,
        variant: 'lite',
        placement: 'trailing',
      },
    } as const

    return organization
      ? ([
          ...[
            {
              children: 'Сохранить',
              onClick: handleSubmit(handleUpdateOIV),
              disabled: isRequestLoading,
              loaderProps: loadingProps.loaderProps,
            },
          ],
        ] as ButtonProps[])
      : ([
          {
            children: 'Отмена',
            view: 'gray',
            onClick: onClose,
          },
          {
            children: 'Добавить',
            onClick: handleSubmit(handleCreateOIV),
            disabled: !canCreateOIV,
          },
        ] as ButtonProps[])
  }, [
    isRequestLoading,
    isModalOpen,
    organization,
    handleSubmit,
    handleUpdateOIV,
    onClose,
    handleCreateOIV,
    canCreateOIV,
  ])

  return (
    <Sidebar
      isOpen={isOpen}
      title={organization ? 'Просмотр РОИВ' : 'Добавление РОИВ'}
      actions={currentActions}
      onClose={closeHandler}
    >
      <div className={styles.sidebar__wrapper}>
        <div className={styles.field}>
          <ControlledSingleSelect
            withSearch
            disabled={!!organization}
            options={regionsToSelect}
            popoverProps={{
              zIndex: 51,
            }}
            controllerProps={{
              control,
              name: 'regionCode',
              rules: {
                required: defaultRHFValidation.required,
              },
            }}
            inputProps={{
              label: 'Регион',
              fixWidth: true,
              clear: true,
            }}
          />
        </div>
        <div className={styles.field}>
          <ControlledInput
            control={control}
            name={'organizationName'}
            inputProps={{
              view: 'secondary',
              size: 'xl',
              label: 'Наименование',
              fixWidth: true,
              clear: true,
              maxLength: 1000,
            }}
            rules={{
              required: defaultRHFValidation.required,
            }}
          />
        </div>
        <div className={styles.field}>
          <ControlledMaskInput
            name={'inn'}
            control={control}
            inputProps={{
              view: 'secondary',
              size: 'xl',
              mask: '9999999999',
              maskPlaceholder: '',
              label: 'ИНН',
              clear: true,
              fixWidth: true,
              caption: organization
                ? 'если изменить ИНН, действующий орган власти потеряет доступ в систему и больше не сможет участвовать в рассмотрении соглашений'
                : '',
            }}
            rules={{
              required: defaultRHFValidation.required,
              pattern: {
                value: /^(\d{10})$/,
                message: 'ИНН должен состоять из 10 цифр',
              },
            }}
          />
        </div>
        <div className={styles.field}>
          <ControlledInput
            control={control}
            name={'link'}
            inputProps={{
              view: 'secondary',
              size: 'xl',
              label: 'Ссылка на официальный сайт',
              fixWidth: true,
              clear: true,
              maxLength: 500,
            }}
          />
        </div>
      </div>
      <SubmitInnChangeModal
        isOpen={isModalOpen}
        onSubmit={handleUpdateOIVWithNewInn}
        onClose={closeModal}
      />
    </Sidebar>
  )
}

export default memo(ItemOIVAuthorizedOrganizationsSidebar)
export { useItemOIVAuthorizedOrganizationsSidebar }
