import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const STABILIZATION_PERIOD_LIMIT = 20

const eleventhSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues['11'],
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  extraMunicipalAccessionLinkStabilization: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  extraMunicipalAccessionLinkCompensation: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  stabilizationPeriod: {
    validate: (value) => {
      if (!isString(value)) return

      return (
        Number(value) <= STABILIZATION_PERIOD_LIMIT ||
        `значение не должно превышать  максимально допустимое (${STABILIZATION_PERIOD_LIMIT})`
      )
    },
  },
}

export { eleventhSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
