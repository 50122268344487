import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { ReactSVG } from 'react-svg'

import errorIcon from '@icons/alert/error.svg'
import neutralIcon from '@icons/alert/neutral.svg'
import successIcon from '@icons/alert/success.svg'
import warningIcon from '@icons/alert/warning.svg'
import cn from 'classnames'

import styles from './style.module.scss'

interface AlertProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  transparent?: boolean
  variant?: 'neutral' | 'warning' | 'success' | 'error'
}

const mapOfAlertProps = {
  neutral: neutralIcon,
  success: successIcon,
  warning: warningIcon,
  error: errorIcon,
} as const

const Alert: FC<AlertProps> = ({ variant = 'neutral', children, transparent, ...rest }) => {
  const currentIconSrc = mapOfAlertProps[variant] || ''

  return (
    <div
      {...rest}
      role="presentation"
      className={cn(
        styles.alert,
        styles[variant],
        {
          [styles[`${variant}--transparent`]]: transparent,
        },
        rest.className,
      )}
    >
      <span
        className={cn(styles.icon, styles[`icon-${variant}`], {
          [styles[`icon-${variant}--transparent`]]: transparent,
        })}
      >
        <ReactSVG src={currentIconSrc} wrapper={'span'} />
      </span>
      <span
        className={cn(styles['alert-content'], {
          [styles['alert-content--transparent']]: transparent,
        })}
      >
        {children}
      </span>
    </div>
  )
}

export default Alert
