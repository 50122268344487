import { createContext, FC, ReactNode, useContext } from 'react'

import {
  FormNavigationReturn,
  useFormNavigation,
} from '@components/DocumentFormComponents/hooks/useFormNavigation'

const FlatMenuContext = createContext<FormNavigationReturn>({
  currentAnchorId: '',
  handleChangeCurrentAnchorId: async () => {},
})

interface FlatMenuManagerProps {
  initialCurrentAnchorId?: string
  children: ReactNode
}

const useFlatMenuManager = () => {
  return useContext(FlatMenuContext)
}

const FlatMenuManager: FC<FlatMenuManagerProps> = ({ initialCurrentAnchorId, children }) => {
  const formNavigation = useFormNavigation(initialCurrentAnchorId)

  return <FlatMenuContext.Provider value={formNavigation}>{children}</FlatMenuContext.Provider>
}

export { useFlatMenuManager }

export default FlatMenuManager
