import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNoticeOfRefuseConcludeAAForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: {
        propertyId: objectFromPropertyState?.investorFullNameGenitive?.propertyId,
        type: objectFromPropertyState?.investorFullNameGenitive?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameGenitive?.lastUpdateDt,
      },
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
    },
    '4': {
      ruleLinkExtraReject: {
        propertyId: objectFromPropertyState.ruleLinkExtraReject?.propertyId,
        type: objectFromPropertyState.ruleLinkExtraReject?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkExtraReject?.lastUpdateDt,
      },
      extraRejectReason: {
        propertyId: objectFromPropertyState.extraRejectReason?.propertyId,
        type: objectFromPropertyState.extraRejectReason?.type,
        lastUpdateDt: objectFromPropertyState.extraRejectReason?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNoticeOfRefuseConcludeAAForm }
