import type { FC } from 'react'

import type { OrganizationVerify } from '@context/APIContext/hooks/useOrganizationApi/types'
import OrganizationMessageVerifyNegative from '@layouts/Header/OrganizationMessage/VerifyNegative'

interface OrganizationMessageProps {
  organizationVerify: OrganizationVerify
}

const OrganizationMessage: FC<OrganizationMessageProps> = ({ organizationVerify }) => {
  if (!organizationVerify.isPositive) {
    return <OrganizationMessageVerifyNegative />
  }

  return null
}

export default OrganizationMessage
