import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedALayout from './Layout'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyModalManager from './Manager'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu from './Menu'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyStatementForm from './Statement'

interface NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyControlFC
  extends FC {
  Layout: typeof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedALayout
  Menu: typeof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu
  StatementForm: typeof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyStatementForm
  Manager: typeof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyModalManager
}

const useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyControl =
  () => {
    const popupManager = usePopupManager()

    const openNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyModal =
      useCallback(
        (props: FormModalControlProps) => {
          const { formId, onClose, ...rest } = props

          if (!formId) {
            onClose?.()

            return
          }

          popupManager.open<FormModalControlProps>(
            NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedALayout,
            {
              ...rest,
              formId,
              onClose: onClose || (() => null),
            },
          )
        },
        [popupManager],
      )

    return { openNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyModal }
  }

const NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny: NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyControlFC =
  () => null

NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny.Menu =
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny.Layout =
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedALayout
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny.StatementForm =
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyStatementForm
NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny.Manager =
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyModalManager

export { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyControl }
export default NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDeny
