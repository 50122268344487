import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller'

import { ChipBaseProps } from '@components/Chip/Base'
import { InputProps } from '@components/NewDesign/Input/types'
import { PopoverProps } from '@components/NewDesign/Popover'
import { OptionsProps } from '@components/NewDesign/Select/Options'
import { OptionsNewProps } from '@components/NewDesign/Select/OptionsNew/types'
import { SingleSelectNewProps } from '@components/NewDesign/Select/SingleSelectNew'
import { isArray } from '@helpers/checkTypes'

export type OptionsVariant = 'primary' | 'secondary'
export type OptionsView = 'normal' | 'reversed'

export interface OptionProps {
  value: string
  displayValue: string
  label?: string
  children?: ReactNode
  dataTestId?: string
}

export interface GroupOfOptions {
  title: string
  options: OptionProps[]
}

export interface SelectState<T extends string | string[] = string | string[]> {
  selected: T
}

export interface SelectStateNew<
  T extends OptionProps | null | OptionProps[] = OptionProps | null | OptionProps[],
> {
  selected: T
}

type TOptionsProps = Omit<OptionsProps, 'popoverProps' | 'onClick' | 'selected' | 'options'>
type TOptionsNewProps = Omit<OptionsNewProps, 'popoverProps' | 'onClick' | 'selected' | 'options'>

export interface SelectProps<T extends string | string[]> {
  type?: 'input' | 'textarea'
  optionsProps?: TOptionsProps

  inputProps?: Omit<InputProps, 'inputContainerRef' | 'rightAddons' | 'ref'>
  popoverProps?: Omit<PopoverProps, 'anchorElement' | 'open'>
  /**
   * Можно передать функцию, в нее будет передан класс, содержащий дефолтные стили Option с макета
   */
  options: (GroupOfOptions | OptionProps)[]
  withSearch?: boolean
  withContextSearch?: boolean
  withInput?: boolean
  onChangeSearchValue?: (newValue: string) => void

  defaultSelected?: T

  defaultOptionsSelected?: (GroupOfOptions | OptionProps)[]
  /**
   * Прокидывается сверху
   */
  onChangeFormValue?: (value: string) => void
  /**
   * Обновляет контрол
   */
  onChangeForm?: ControllerRenderProps['onChange']
  onBlurForm?: ControllerRenderProps['onBlur']
  error?: ControllerFieldState['error']

  disabled?: boolean

  optionsContainer?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  overrideDisplayValue?: (value: string) => string
}

export interface SelectNewProps<T extends OptionProps | string | (OptionProps | string)[]> {
  id?:string
  type?: 'input' | 'textarea'
  optionsProps?: TOptionsNewProps

  inputProps?: Omit<InputProps, 'inputContainerRef' | 'rightAddons' | 'ref'>
  popoverProps?: Omit<PopoverProps, 'anchorElement' | 'open'>
  /**
   * Можно передать функцию, в нее будет передан класс, содержащий дефолтные стили Option с макета
   */
  options: (GroupOfOptions | OptionProps)[]
  withSearch?: boolean
  withContextSearch?: boolean
  withInput?: boolean
  onChangeSearchValue?: (newValue: string) => void

  defaultSelected?: T

  defaultOptionsSelected?: (GroupOfOptions | OptionProps)[]
  /**
   * Прокидывается сверху
   */
  onChangeFormValue?: (value: OptionProps | null) => void
  /**
   * Обновляет контрол
   */
  onChangeForm?: ControllerRenderProps['onChange']
  onBlurForm?: ControllerRenderProps['onBlur']
  onFocusForm?: VoidFunction

  error?: ControllerFieldState['error']

  disabled?: boolean

  optionsContainer?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

export type SingleSelectProps = SelectProps<string>
export type SingleSelectState = SelectState<string>

export type MultipleSelectProps = SelectProps<string[]> & {
  closeOptionsAfterOnChange?: boolean
  staticWidth?: boolean
  multipleClassName?: string
  chipProps?: Omit<ChipBaseProps, 'label' | 'key' | 'className' | 'onClose'>
  onAddValue?: () => void
  onRemoveChip?: (payload: string[], removedValue: string) => void
  overrideDisplayValue?: (value: string) => string
}
export type MultipleSelectState = SelectState<string[]>

export const isOptionsGroup = (
  options: SingleSelectProps['options'][0],
): options is GroupOfOptions => 'title' in options

export const isOptions = (options: unknown): options is SingleSelectNewProps['options'] =>
  isArray(options) && options.every((option) => 'displayValue' in option && 'value' in option)
