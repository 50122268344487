import React, { FC, memo, useCallback } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import { chooseNpaBlockValues } from '@components/RegistryNpa/Choose/const'
import NpaModalChosenThreeNpaList from '@components/RegistryNpa/Choose/Grid/ChosenThree/NpaList'
import DirectionItem from '@components/RegistryNpa/Choose/Items/DirectionItem'
import { ChooseNpaFormValues } from '@components/RegistryNpa/Choose/types'
import { MapOfNpaDirections } from '@hooks/new/swr/useNpaDirections'
import { INpaItem } from '@services/NPA/NPA.entity'

interface NpaModalDefaultThreeProps {
  formInstance: UseFormReturn<ChooseNpaFormValues>
  classificationHeader: (part: INpaItem) => string
  mapOfNpaDirections?: MapOfNpaDirections
}

const NpaModalChosenThree: FC<NpaModalDefaultThreeProps> = ({
  formInstance,
  classificationHeader,
  mapOfNpaDirections,
}) => {
  const { fields: chosenThree, update } = useFieldArray({
    control: formInstance.control,
    name: chooseNpaBlockValues.chosenThree,
    keyName: 'keyNameId',
  })

  const handleToggleDirectionItem = useCallback(
    (index: number) => (isCollapsed: boolean) => {
      const currentItem = formInstance.getValues(`${chooseNpaBlockValues.chosenThree}.${index}`)

      update(index, {
        ...currentItem,
        isCollapsed,
      })
    },
    [update],
  )

  return (
    <div>
      {chosenThree.map(({ isCollapsed, id, direction, minInvestmentAmount }, index) => {
        return (
          <DirectionItem
            key={id}
            isCollapsed={isCollapsed}
            direction={direction}
            investmentSum={minInvestmentAmount}
            type={mapOfNpaDirections?.[direction] || ''}
            onToggle={handleToggleDirectionItem(index)}
          >
            <NpaModalChosenThreeNpaList
              formInstance={formInstance}
              direction={direction}
              name={`${chooseNpaBlockValues.chosenThree}.${index}` as const}
              classificationHeader={classificationHeader}
            />
          </DirectionItem>
        )
      })}
    </div>
  )
}

export default memo(NpaModalChosenThree)
