import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'

import { NotificationOfChangesInDetailsOfPartiesFormValues } from './types'

const { getDefaultInitiatorFieldState, getFullInitiatorFieldState } = FieldStateHelpers

const mapOfNotificationOfChangesInDetailsOfPartiesMenu = [
  { id: '1', title: 'Сторона соглашения' },
  { id: '2', title: 'Реквизиты в новой редакции' },
]

const defaultNotificationOfChangesInDetailsOfPartiesFormValues: NotificationOfChangesInDetailsOfPartiesFormValues =
  {
    '1': getDefaultInitiatorFieldState(),
    '2': {
      ruleLinkInfoChanges: '',
      ...getFullInitiatorFieldState(),
    },
  }

const notificationOfChangesInDetailsOfPartiesBlockValues = {
  '1': {
    initiatorFullName: '1.initiatorFullName',
    initiatorHeaderName: '1.initiatorHeaderName',
    initiatorHeaderNameGenitive: '1.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '1.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '1.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '1.initiatorHeaderReasonGenitive',
  },
  '2': {
    ruleLinkInfoChanges: '2.ruleLinkInfoChanges',
    initiatorFullName: '2.initiatorFullName',
    initiatorName: '2.initiatorName',
    initiatorInn: '2.initiatorInn',
    initiatorKpp: '2.initiatorKpp',
    initiatorOgrn: '2.initiatorOgrn',
    initiatorOkpo: '2.initiatorOkpo',
    initiatorOktmo: '2.initiatorOktmo',
    initiatorOkved: '2.initiatorOkved',
    initiatorPaymentDetails: '2.initiatorPaymentDetails',
    initiatorOtherDetails: '2.initiatorOtherDetails',
    initiatorAddressEgrul: '2.initiatorAddressEgrul',
    initiatorLocation: '2.initiatorLocation',
    initiatorAddress: '2.initiatorAddress',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderNameGenitive: '2.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '2.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '2.initiatorHeaderReasonGenitive',
    initiatorHeaderEmail: '2.initiatorHeaderEmail',
    initiatorHeaderPhone: '2.initiatorHeaderPhone',
    initiatorHeaderAddPhone: '2.initiatorHeaderAddPhone',
  },
} as const

export {
  defaultNotificationOfChangesInDetailsOfPartiesFormValues,
  mapOfNotificationOfChangesInDetailsOfPartiesMenu,
  notificationOfChangesInDetailsOfPartiesBlockValues,
}
