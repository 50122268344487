import React from 'react'
import { useFormContext } from 'react-hook-form'

import InitiatorSection from '@components/DocumentFormComponents/FormSections/InitiatorSection'
import { useNoticeIntentionTerminateSZPKManager } from '@components/Forms/NoticeIntentionTerminateSZPKForm/Manager'
import { NoticeIntentionTerminateSZPKFormValues } from '@components/Forms/NoticeIntentionTerminateSZPKForm/types'
import { NoticeIntentionTerminateSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeIntentionTerminateSZPKForm/watcher'

const Second = () => {
  const formInstance = useFormContext<NoticeIntentionTerminateSZPKFormValues>()

  const {
    handlers: { debouncedHandleChangeValue, handleChangeValue },
    preparedProps: { subscribableControl },
    state: { blockViewIsValidating, editMode },
  } = useNoticeIntentionTerminateSZPKManager()

  if (!formInstance) return null

  return (
    <InitiatorSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'2'}
      formInstance={formInstance}
      watcher={NoticeIntentionTerminateSZPKFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
