import { FC, memo, useEffect } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import CollapseCardWithPreview from '@components/DocumentFormComponents/CollapseCardWithPreview'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import formFieldsStyles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { SzpkChangeTerminateArrayPathName } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/5/types'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import {
  DataOnFulfillmentOfTermsSZPKFieldCollapseControlUpdateWatcher,
  DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { szpkChangeTerminateStepValidationMap } from './validation'

interface SzpkChangeTerminateStepProps {
  itemId: string
  formName: SzpkChangeTerminateArrayPathName
  formInstance: UseFormReturn<DataOnFulfillmentOfTermsSZPKFormValues>
  anchorId?: string
}

const SzpkChangeTerminateStep: FC<SzpkChangeTerminateStepProps> = ({
  itemId,
  formName,
  formInstance,
  anchorId,
}) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const {
    getCheckboxProps,
    getSubscribableControlProps,
    getInputProps,
    getCalendarInputProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  const isChangeTerminate = useWatch({
    name: `${formName}.isChange`,
    control: formInstance.control,
  })
  const isTerminate = useWatch({
    name: `${formName}.isTerminate`,
    control: formInstance.control,
  })

  const {
    isExpanded,
    onCollapse: collapseForm,
    onExpand: expandForm,
  } = useFormCollapseControl({
    initialExpanded: isChangeTerminate || isTerminate,
    name: `${anchorId}`,
    watcher: DataOnFulfillmentOfTermsSZPKFieldCollapseControlUpdateWatcher,
  })

  useEffect(() => {
    if (isChangeTerminate || isTerminate) {
      expandForm()
      return
    }

    collapseForm()
  }, [collapseForm, expandForm, isChangeTerminate, isTerminate])

  const changeReasons = useWatch({
    name: `${formName}.changeReasons`,
    control: formInstance.control,
  })
  const changeReasonsChecker = useWatch({
    name: `${formName}.changeReasonsChecker`,
    control: formInstance.control,
  })

  return (
    <CollapseCardWithPreview
      key={itemId}
      id={itemId}
      isExpanded={isExpanded}
      previewNode={
        <>
          <Row id={anchorId}>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.projectName`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.projectName`,
                    inputProps: {
                      disabled: true,
                      label: 'Наименование проекта',
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <AsyncWrapper promise={handleChangeValue}>
            {({ isLoading: isReasonsChecking, wrappedPromise: handleCheckReasons }) => (
              <Row className={formFieldsStyles.form__checkboxRow}>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.isChange`,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${formName}.isChange`,
                        checkBoxProps: {
                          disabled: isReasonsChecking || !editMode,
                          label: 'Основания для изменения',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckReasons?.(`${formName}.isChange`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.isTerminate`,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${formName}.isTerminate`,
                        checkBoxProps: {
                          disabled: isReasonsChecking || !editMode,
                          label: 'Основания для расторжения',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckReasons?.(`${formName}.isTerminate`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            )}
          </AsyncWrapper>
        </>
      }
      onCollapseNode={collapseForm}
      onExpandForm={expandForm}
    >
      <Stack direction="vertical" gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.registrationDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${formName}.registrationDate`,
                  calendarInputProps: {
                    disabled: true,
                    label: 'Дата регистрации',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.szpkNumber`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.szpkNumber`,
                  inputProps: {
                    disabled: true,
                    label: 'Регистрационный номер',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title="Объем капитальных вложений">
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем по отчетам ОРП'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.totalCapital`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.totalCapital`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.totalCapitalChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.totalCapitalChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.totalCapitalCorrect`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.totalCapitalCorrect`,
                    rules: szpkChangeTerminateStepValidationMap.totalCapitalCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.totalCapitalCorrect`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.totalCapitalCorrect`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <Group disableBottomBorder title="Объем капиталовложений">
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем по отчетам ОРП'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorCapital`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.investorCapital`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем по справкам проверяющей организации'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorCapitalChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.investorCapitalChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем для переноса в отчет'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorCapitalCorrect`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.investorCapitalCorrect`,
                    rules: szpkChangeTerminateStepValidationMap.investorCapitalCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.investorCapitalCorrect`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.investorCapitalCorrect`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <Group disableBottomBorder title="Основания для изменения или расторжения СЗПК">
          <Stack direction={'vertical'} gap={3}>
            {changeReasons.map((_, index) => (
              <Row key={index}>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.changeReasons.${index}`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${formName}.changeReasons.${index}`,
                        inputProps: {
                          disabled: true,
                          label: 'Основания по отчетам ОРП',
                        },
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            ))}
            {changeReasonsChecker.map((_, index) => (
              <Row key={index}>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.changeReasonsChecker.${index}`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${formName}.changeReasonsChecker.${index}`,
                        inputProps: {
                          disabled: true,
                          label: 'Основания по справкам проверяющей организации',
                        },
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            ))}
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.changeTerminateReason`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.changeTerminateReason`,
                      rules: szpkChangeTerminateStepValidationMap.changeTerminateReason,
                      inputProps: {
                        label: 'Основания для переноса в отчет',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.changeTerminateReason`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.changeTerminateReason`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </CollapseCardWithPreview>
  )
}

export default memo(SzpkChangeTerminateStep)
