import { generatePropertiesObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/adapters/RHF.adapter'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'

const useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues =>
    generateRHFObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm(
      objectFromPropertyState,
    )

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm(
      objectFromPropertyState,
    )

  return { generateRHFObject, generatePropertiesObject }
}

export { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAAdapters }
