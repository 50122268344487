export const increment = (n: number) => n + 1

export const decrement = (n: number) => n - 1

export const incrementToTen = (n: number) => n + 10

export const decrementToTen = (n: number) => n - 10

export const toggle = (b: any) => !b

export const findById = (id?: string | number) => (el: any) => {
  return el?.id === id
}

export const getFirstPath = (pathname: string) => {
  return `/${pathname.split('/')[1]}`
}

export const truncate = (s: string, max = 170) => {
  return s?.length > max ? `${s?.substring(0, max)}...` : s
}

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export const findOne = <T = any>(haystack: T[], arr: T[]) => {
  return arr?.some((v) => haystack.includes(v))
}

export const filterNested = <T = any>(arr: T[][], sub: T[]) => {
  return arr?.filter((a) => !a.some((v) => sub.includes(v)))
}

export const includesNested = <T = any>(arr: T[][], sub: T[]) => {
  return arr?.some((a) => a.some((v) => sub.includes(v)))
}

export const mergeUrlWithParams = (url: string, objParams: object) => {
  let fullUrl = url + '?'
  const length = Object.keys(objParams)?.length
  length &&
    Object.keys(objParams).forEach((key, index) => {
      fullUrl +=
        objParams[key] !== null && objParams[key] !== undefined && objParams[key] !== ''
          ? `${key}=${objParams[key]}${index !== length && '&'}`
          : ''
    })
  return fullUrl
}

export const filterBySearch = (elements: any[], search: string) => {
  return elements.filter((element) => {
    for (const key in element) {
      const elementKey = element[key].toLowerCase()
      const searchLower = search.toLowerCase()

      if (elementKey.startsWith(searchLower)) {
        return true
      }
    }

    return false
  })
}

export const equals = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

export const closestNum = (arr: number[], num: number) => {
  return arr?.reduce((prev, cur) => {
    const _prev = Math.abs(prev - num)
    const _cur = Math.abs(cur - num)
    return _cur < _prev ? cur : prev
  }, 0)
}

export const getUrlUID = (str: string) => str.substring(str.lastIndexOf('/') + 1)
