import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import OldStepExpenseItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/OldStepExpenses/OldStepExpenseItem'
import { SeventhStagesOldStepExpensesPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

interface AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpensesProps {
  blockViewIsValidating: boolean
  editMode: boolean
  stageId: string
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  formName: SeventhStagesOldStepExpensesPathName
}

const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpenses: FC<
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpensesProps
> = ({
  blockViewIsValidating,
  editMode,
  stageId,
  formInstance,
  formModifierInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { fields: stepOldExpenses } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhStagesOldStepExpensesPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: formName,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
    SeventhStagesOldStepExpensesPathName
  >({
    control: formModifierInstance.control,
    name: formName,
    watcher:
      AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
  })

  const handleAddStepOldExpense = async () => {
    await handleAddItemToListWithValue?.(formName, {
      oldStepIdExpense: stageId,
    })
  }
  const handleRemoveOldStepExpense = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${formName}.${index}`, formName)
  }

  return (
    <FormListTooltipControl
      disablePaddingRight
      enablePaddingLeft
      name={formName}
      control={formModifierInstance.control}
      onDifference={handleUpdateChanges}
    >
      {({ iconWithTooltip }) => (
        <Group disableBottomBorder title="Виды расходов" leftAddons={iconWithTooltip}>
          <Stack id={formName} direction={'vertical'} gap={!!stepOldExpenses.length ? 3 : 0}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: formName,
              })}
            >
              <FlipperList list={stepOldExpenses}>
                {stepOldExpenses.map((step, index) => {
                  const stepOldExpenseName = `${formName}.${index}` as const

                  return (
                    <FormObjectTooltipControl
                      key={step.id}
                      name={stepOldExpenseName}
                      control={formModifierInstance.control}
                      onDifference={handleUpdateChanges}
                    >
                      <OldStepExpenseItem
                        editMode={editMode}
                        blockViewIsValidating={blockViewIsValidating}
                        formInstance={formInstance}
                        formModifierInstance={formModifierInstance}
                        name={stepOldExpenseName}
                        index={index}
                        onRemove={handleRemoveOldStepExpense(index)}
                      />
                    </FormObjectTooltipControl>
                  )
                })}
              </FlipperList>
            </SubscribableControl>
            {editMode && (
              <Row className={'px-1'}>
                <Col xs={12}>
                  <AsyncWrapper promise={handleAddStepOldExpense}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <Button
                          leadingIcon={{ src: CircleAddIcon }}
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          view={'plain'}
                          disabled={isLoading}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Добавить
                        </Button>
                      )
                    }}
                  </AsyncWrapper>
                </Col>
              </Row>
            )}
          </Stack>
        </Group>
      )}
    </FormListTooltipControl>
  )
}

export default AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpenses
