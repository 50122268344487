import React, { FC, KeyboardEvent, MouseEvent, SyntheticEvent, useCallback } from 'react'

import Icon from '@components/Icon/Icon'
import Input from '@components/NewDesign/Input'
import { PopoverProps } from '@components/NewDesign/Popover'
import { isSelectOption } from '@components/NewDesign/Select/helpers'
import {
  GroupOfOptions,
  isOptionsGroup,
  OptionProps,
  SelectNewProps,
  SelectStateNew,
} from '@components/NewDesign/Select/model'
import Options from '@components/NewDesign/Select/OptionsNew'
import { useSelectStateNew } from '@components/NewDesign/Select/useSelectStateNew'
import Textarea from '@components/NewDesign/Textarea'
import { isArray, isEmptyString, isString } from '@helpers/checkTypes'
import arrowBackIcon from '@icons/hardware/keyboard_arrow_down.svg'
import arrowUpIcon from '@icons/hardware/keyboard_arrow_up.svg'
import cn from 'classnames'

import styles from '../SingleSelect.module.scss'

type SingleSelectNewProps = SelectNewProps<OptionProps | string>
type SingleSelectNewState = SelectStateNew<OptionProps>

const SingleSelectNew: FC<SingleSelectNewProps> = ({
  id,
  type = 'input',
  defaultSelected = null,
  error,
  onFocusForm,
  onBlurForm,
  onChangeForm,
  onChangeFormValue,
  popoverProps,
  inputProps,
  options: receivedOptions,
  withSearch,
  withContextSearch,
  withInput,
  optionsContainer,
  disabled,
  onChangeSearchValue,
  optionsProps,
  defaultOptionsSelected,
}) => {
  const { enableConditionToShowTooltip = true, ...restInputProps } = inputProps || {}

  const inputActive = !!(withInput || withSearch || withContextSearch)

  const inputCursor = withSearch || withContextSearch ? undefined : 'pointer'

  const findOptionBySelectedValue = (
    options: (GroupOfOptions | OptionProps)[],
    selectedValue: string,
  ): OptionProps | null => {
    return options.reduce<OptionProps | null>((previousValue, currentValue) => {
      if (isOptionsGroup(currentValue)) {
        return findOptionBySelectedValue(currentValue.options, selectedValue)
      }

      if (currentValue.value === selectedValue) {
        return currentValue
      }

      return previousValue
    }, null)
  }

  const handlePrepareDefaultSelected = (defaultSelectedValue: OptionProps | string | null) => {
    if (!defaultSelectedValue) return null

    if (isString(defaultSelectedValue)) {
      const defaultOption: OptionProps = {
        value: defaultSelectedValue,
        displayValue: '',
      }

      // если не найдена опция в функции, то возвращается дефолтная опция
      return findOptionBySelectedValue(receivedOptions, defaultSelectedValue) || defaultOption
    }

    if (isSelectOption(defaultSelectedValue) && isEmptyString(defaultSelectedValue.displayValue)) {
      // если не найдена опция в функции, то возвращается дефолтная опция
      return (
        findOptionBySelectedValue(receivedOptions, defaultSelectedValue.value) ||
        defaultSelectedValue
      )
    }

    return defaultSelectedValue
  }

  const {
    selected,
    setSelected,
    inputContainerRef,
    popoverRef,
    isPopoverOpen,
    searchValue,
    setSearchValue,
    clue,
    availableOptions,
    closePopover,
    openPopover,
    inputRef,
    valueIsLongerThenInput,
  } = useSelectStateNew<SingleSelectNewState>({
    defaultSelected: handlePrepareDefaultSelected(defaultSelected),
    receivedOptions,
    defaultOptionsSelected,
    disabled: disabled || inputProps?.disabled,
    withSearch,
    withContextSearch,
    disableOptionsMutate: !!onChangeSearchValue,
    onBlur: onBlurForm,
    onFocus: onFocusForm,
  })

  const closePopoverHandler = useCallback(() => {
    onBlurForm?.()
    closePopover()
  }, [closePopover, onBlurForm])

  const inputArrow = (
    <Icon src={isPopoverOpen ? arrowUpIcon : arrowBackIcon} className={styles.Arrow} />
  )

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const isTargetButtonPressed = ['Enter'].includes(e.key)

    if (isTargetButtonPressed && clue) {
      const [firstOption] = availableOptions
      const nextValue = isOptionsGroup(firstOption) ? firstOption.options[0] : firstOption

      e.preventDefault()

      setSelected(nextValue)
      onChangeForm?.(nextValue)

      closePopoverHandler()
    }
  }

  const onChangeInput = ({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    /**
     * Если значение уже выбранно, а пользователь начал искать что-то, обнуляем выбранное значение
     */
    if (value) openPopover()

    // type guard isEmptyString в этом случае отрабатывает неправильно
    const isNotEmptyStringSelected = isString(selected) && !!selected.length
    const isNotEmptyArraySelected = isArray(selected) && !!selected.length

    if (isNotEmptyStringSelected || isNotEmptyArraySelected) {
      setSelected(null)
      onChangeForm?.('')
      onChangeFormValue?.(null)
      setSearchValue('')
      onChangeSearchValue?.('')

      return
    }

    setSearchValue(value)

    onChangeSearchValue?.(value)
  }

  const onClearSearch = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      inputProps?.onClear?.(e)

      if (selected) {
        setSelected(null)
        onChangeForm?.(null)
        onChangeFormValue?.(null)
      }

      setSearchValue('')

      onChangeSearchValue?.('')
    },
    [
      onChangeSearchValue,
      setSearchValue,
      inputProps,
      onChangeForm,
      onChangeFormValue,
      selected,
      setSelected,
    ],
  )

  const onClickOption = useCallback(
    (option: OptionProps) => {
      const nextValue = selected?.value === option.value ? null : option

      setSearchValue('')

      onChangeSearchValue?.('')

      setSelected(nextValue)
      onChangeForm?.(nextValue)
      onChangeFormValue?.(nextValue)

      if (nextValue || selected?.value !== option.value) {
        inputRef.current?.blur()
        closePopoverHandler()
      }
    },
    [
      closePopoverHandler,
      inputRef,
      onChangeForm,
      onChangeFormValue,
      onChangeSearchValue,
      selected,
      setSearchValue,
      setSelected,
    ],
  )

  const staticPopoverProps = {
    open: isPopoverOpen,
    position: 'bottom-start' as PopoverProps['position'],
    anchorElement: inputContainerRef.current,
    ref: popoverRef,
    ...popoverProps,
  }

  const isInput = type === 'input'

  // Если включено условие и открыты опции, то тултип закрывается
  const enableConditionInputTooltip = enableConditionToShowTooltip
    ? !isPopoverOpen
    : enableConditionToShowTooltip

  return (
    <>
      {isInput ? (
        <Input
          id={id}
          disabled={disabled}
          inputActive={inputActive}
          rootClassName={cn(styles.Input, { [styles.Input__disabled]: disabled })}
          value={selected?.displayValue || searchValue || ''}
          inputCursor={inputCursor}
          // searchValue используется при включенном поиске
          rightAddons={!disabled && inputArrow}
          view={'secondary'}
          inputClue={selected || valueIsLongerThenInput ? '' : clue}
          error={error}
          inputContainerRef={inputContainerRef}
          ref={inputRef}
          onChange={onChangeInput}
          onClear={onClearSearch}
          onKeyDown={onKeyDown}
          {...restInputProps}
          enableConditionToShowTooltip={enableConditionInputTooltip}
        />
      ) : (
        <Textarea
          textAreaInactive
          id={id}
          autoComplete="off"
          fixWidth={inputProps?.fixWidth}
          label={inputProps?.label}
          inputContainerRef={inputContainerRef}
          rootClassName={cn(styles.Input, { [styles.Input__disabled]: disabled })}
          value={selected?.displayValue || searchValue || ''}
          rightAddons={!disabled && inputArrow}
          labelLeftAddons={inputProps?.labelLeftAddons}
          labelRightAddons={inputProps?.labelRightAddons}
          labelClassName={inputProps?.labelClassName}
          labelTextClassName={inputProps?.labelTextClassName}
          labelWrapperClassName={inputProps?.labelWrapperClassName}
          error={error}
          dataTestId={inputProps?.dataTestId}
        />
      )}
      <Options
        options={availableOptions}
        selected={selected}
        popoverProps={staticPopoverProps}
        optionsContainer={optionsContainer}
        onClick={onClickOption}
        {...optionsProps}
      />
    </>
  )
}

export type { SingleSelectNewProps, SingleSelectNewState }
export default SingleSelectNew
