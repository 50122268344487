import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AdditionalAgreementOnTerminationFfSZPKLayout from './Layout'
import AdditionalAgreementOnTerminationFfSZPKModalManager from './Manager'
import AdditionalAgreementOnTerminationFfSZPKMenu from './Menu'
import AdditionalAgreementOnTerminationFfSZPKStatementForm from './Statement'

interface IAdditionalAgreementOnTerminationFfSZPK extends FC {
  Layout: typeof AdditionalAgreementOnTerminationFfSZPKLayout
  Menu: typeof AdditionalAgreementOnTerminationFfSZPKMenu
  StatementForm: typeof AdditionalAgreementOnTerminationFfSZPKStatementForm
  Manager: typeof AdditionalAgreementOnTerminationFfSZPKModalManager
}

const useAdditionalAgreementOnTerminationFfSZPKControl = () => {
  const popupManager = usePopupManager()

  const openAdditionalAgreementOnTerminationFfSZPKModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AdditionalAgreementOnTerminationFfSZPKLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAdditionalAgreementOnTerminationFfSZPKModal }
}

const AdditionalAgreementOnTerminationFfSZPK: IAdditionalAgreementOnTerminationFfSZPK = () => null

AdditionalAgreementOnTerminationFfSZPK.Menu = AdditionalAgreementOnTerminationFfSZPKMenu
AdditionalAgreementOnTerminationFfSZPK.Layout = AdditionalAgreementOnTerminationFfSZPKLayout
AdditionalAgreementOnTerminationFfSZPK.StatementForm =
  AdditionalAgreementOnTerminationFfSZPKStatementForm
AdditionalAgreementOnTerminationFfSZPK.Manager = AdditionalAgreementOnTerminationFfSZPKModalManager

export { useAdditionalAgreementOnTerminationFfSZPKControl }
export default AdditionalAgreementOnTerminationFfSZPK
