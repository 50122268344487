import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { ExpensesArrayPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/6/types'
import { sixthSectionValidationMap } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/6/validation'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface ExpenseItemProps {
  expenseId: string
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<InformationOfExecutionConditionsSZPKFormValues>
  indexOfExpense: number
  name: ExpensesArrayPathName
  onDeleteExpense: VoidFunction
}

const ExpenseItem: FC<ExpenseItemProps> = ({
  expenseId,
  blockViewIsValidating,
  editMode,
  formInstance,
  indexOfExpense,
  name,
  onDeleteExpense,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  return (
    <FieldView.StepTemplate
      id={expenseId}
      title={`Направление расхода №${indexOfExpense + 1}`}
      onRemoveStep={editMode ? onDeleteExpense : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.expenseName`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.expenseName`,
                  rules: sixthSectionValidationMap.expenseName,
                  textareaProps: {
                    label: 'Направление расхода',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.expenseName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.expenseName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default ExpenseItem
