import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FieldValues, Path } from 'react-hook-form'

import {
  collapseStatusDataAttributeName,
  mapOfCollapseStatuses,
} from '@components/DocumentFormComponents/CollapseWrapper/constants'
import { ProjectSZPKMenuHelpers } from '@components/DocumentFormComponents/NestedMenu/Manager/helpers'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import useInput from '@hooks/useInput'
import cloneDeep from 'clone-deep'
import { unstable_serialize, useSWRConfig } from 'swr'

import { NestedMenuContextProps, NestedMenuHandlersContextProps } from './types'

const {
  findParentNodeByMenuItem,
  sortThreeFunction,
  removeItemFromThree,
  addItemInThree,
  updateItemInThree,
  treeToHashMap,
} = ProjectSZPKMenuHelpers

type TInitialThreeManager = Map<string, boolean>

interface NestedMenuManagerProps {
  initialMenuHash: NestedMapOfMenu[]
  initialSectionId: string
  children: ReactNode
}

const NestedMenuManagerContext = createContext<NestedMenuContextProps>({
  state: {
    formMenu: [],
    currentMenuItemId: '',
    searchThreeIsLoading: false,
    searchThree: null,
    currentSearchPattern: '',
    listOfChanges: [],
  },
  handlers: {},
})

const NestedMenuHandlersManagerContext = createContext<NestedMenuHandlersContextProps>({})

const NestedMenuManager: FC<NestedMenuManagerProps> = ({
  initialMenuHash,
  initialSectionId,
  children,
}) => {
  const { cache } = useSWRConfig()

  const [formMenu, setFormMenu] = useState<NestedMapOfMenu[]>(initialMenuHash)

  const currentProjectSZPKHashMap = useMemo(() => {
    return treeToHashMap(formMenu)
  }, [formMenu])

  const [currentMenuItemId, setCurrentMenuItemId] =
    useState<NestedMapOfMenu['id']>(initialSectionId)

  const [initializationThree, setInitializationThree] = useState(new Map())
  const [searchThree, setSearchThree] = useState<NestedMapOfMenu[] | null>(null)
  const [searchThreeIsLoading, setSearchThreeIsLoading] = useState<boolean>(false)

  const currentSearchPattern = useInput('')

  const [listOfChanges, setListOfChanges] = useState<string[]>([])

  const handleScrollToTarget = useCallback(
    async (menuItemId: NestedMapOfMenu['id'] | NestedMapOfMenu['path']) => {
      const formLayoutElement = window.document.getElementById('form-layout')
      const target = window.document.getElementById(menuItemId)

      if (!formLayoutElement || !target) return

      let previousTop: number | null = null

      await new Promise((resolve) => {
        const timerId = setInterval(() => {
          const currentTop = target.getBoundingClientRect().top + formLayoutElement.scrollTop
          const layoutScrollHeight = formLayoutElement.scrollHeight
          const windowHeight = window.innerHeight

          const isCanScrollToTargetTop = layoutScrollHeight - windowHeight > currentTop
          const isExpandingElementExistOnForm = !!formLayoutElement.querySelector(
            `[${collapseStatusDataAttributeName}=${mapOfCollapseStatuses.expanding}]`,
          )

          if (
            previousTop === currentTop &&
            (isCanScrollToTargetTop || !isExpandingElementExistOnForm)
          ) {
            formLayoutElement.scrollTo({
              top: currentTop,
              behavior: 'smooth',
            })

            clearInterval(timerId)

            return resolve(true)
          }

          previousTop = currentTop
        }, 100)
      })
    },
    [],
  )

  const handleChangeCurrentMenuItemId = useCallback(
    async (menuItemId: NestedMapOfMenu['id'] | NestedMapOfMenu['path']) => {
      await handleScrollToTarget(menuItemId)

      setCurrentMenuItemId(menuItemId)
    },
    [],
  )

  // Если у активного элемента меню есть вложенности, то активное состояние меняется на первый вложенный элемент
  const changeCurrentMenuIdToChild = (formMenu: NestedMapOfMenu[]) => {
    formMenu.forEach((menuItem) => {
      if (menuItem?.id !== currentMenuItemId && !menuItem?.children.length) return

      if (menuItem?.id === currentMenuItemId && !menuItem?.children.length) return

      if (menuItem?.id !== currentMenuItemId && menuItem?.children.length) {
        changeCurrentMenuIdToChild(menuItem.children)
        return
      }

      setCurrentMenuItemId(menuItem?.children[0].id)
    })
  }

  const generateThreeFromSearch = useCallback(
    (initialThree: NestedMapOfMenu[]): NestedMapOfMenu[] | undefined => {
      if (!initialThree.length) {
        setSearchThree(null)
        return
      }

      const searchInChildren = (node: NestedMapOfMenu): NestedMapOfMenu | null => {
        const matchInChildren = node.children.reduce<NestedMapOfMenu[]>((acc, child) => {
          const result = searchInChildren(child)

          if (result) acc.push(result)
          return acc
        }, [])

        if (
          node.title.toLowerCase().includes(currentSearchPattern.value.toLowerCase()) ||
          matchInChildren.length > 0
        ) {
          return {
            ...node,
            children: matchInChildren.length ? matchInChildren : node.children,
          }
        }

        return null
      }

      return initialThree.reduce<NestedMapOfMenu[]>((acc, node) => {
        const result = searchInChildren(node)
        if (result) acc.push(result)
        return acc
      }, [])
    },
    [currentSearchPattern.value],
  )

  const handleUpdateSearchThree = useCallback(
    (formMenuList: NestedMapOfMenu[], key: string) => {
      const readyThree = generateThreeFromSearch(formMenuList)

      if (readyThree?.length) {
        const sortedReadyThree = readyThree.sort(sortThreeFunction)

        cache.set(key, sortedReadyThree)

        setSearchThree(readyThree)

        return setSearchThreeIsLoading(false)
      }

      setSearchThree(null)

      setSearchThreeIsLoading(false)
    },
    [cache, generateThreeFromSearch],
  )

  const debouncedGenerateSearchThree = useDebouncedCallback(() => {
    const keyForSearchThree = unstable_serialize({
      searchPattern: currentSearchPattern.value,
      _key: 'projectSZPKMenu',
    })

    const cachedSearchThree = cache.get(keyForSearchThree)

    if (cachedSearchThree) {
      setSearchThreeIsLoading(false)

      return setSearchThree(cachedSearchThree)
    }

    handleUpdateSearchThree(formMenu, keyForSearchThree)
  }, 500)

  const handleRecalculateSearchThree = useCallback(
    (newFormMenu: NestedMapOfMenu[]) => {
      const keyForSearchThree = unstable_serialize({
        searchPattern: currentSearchPattern.value,
        _key: 'projectSZPKMenu',
      })

      handleUpdateSearchThree(newFormMenu, keyForSearchThree)
    },
    [handleUpdateSearchThree, currentSearchPattern.value],
  )

  const handleRecalculateSearchThreeDebounced = useDebouncedCallback(
    handleRecalculateSearchThree,
    500,
  )

  const startGenerateSearchThree = useCallback(() => {
    setSearchThreeIsLoading(true)

    debouncedGenerateSearchThree()
  }, [debouncedGenerateSearchThree])

  const handleChangeSearchPattern = useCallback(
    (value: string) => {
      currentSearchPattern.setValue(value)
    },
    [currentSearchPattern],
  )

  const getInitialExpandedMenuCondition = useCallback(
    (menuItemId: string) =>
      !!initializationThree.get(menuItemId) ||
      treeToHashMap(initialMenuHash)?.[menuItemId]?.isCollapsed,
    [initialMenuHash, initializationThree],
  )

  const generateThree = (code: string | null, map: TInitialThreeManager = new Map()) => {
    if (code) {
      const data = cloneDeep(currentProjectSZPKHashMap[code])

      if (data.parentBaseId) {
        const newMap = new Map(map.set(data.parentBaseId, true))

        generateThree(data.parentBaseId, newMap)
      } else {
        setInitializationThree(map)
      }
    }
  }

  const handleAddNewItemInThree = useCallback((parentId: string, newItem: NestedMapOfMenu) => {
    setFormMenu((currentProjectSZPKMenu) =>
      addItemInThree(currentProjectSZPKMenu, parentId, newItem),
    )
  }, [])

  const handleUpdateItemInThree = useCallback(
    (menuItemId: string, itemToUpdate: (menuItem: NestedMapOfMenu) => NestedMapOfMenu) => {
      setFormMenu((currentProjectSZPKMenu) =>
        updateItemInThree(currentProjectSZPKMenu, menuItemId, itemToUpdate),
      )
    },
    [],
  )

  const handleRemoveItemFromThree = useCallback(
    (menuItemId: string) => {
      setFormMenu((currentProjectSZPKMenu) =>
        removeItemFromThree(currentProjectSZPKMenu, menuItemId),
      )

      setTimeout(() => {
        if (!currentProjectSZPKHashMap[currentMenuItemId]) {
          const parent = findParentNodeByMenuItem(formMenu, menuItemId)

          if (!parent) return

          handleChangeCurrentMenuItemId?.(parent.id)
        }
      })
    },
    [currentMenuItemId, currentProjectSZPKHashMap, handleChangeCurrentMenuItemId, formMenu],
  )

  const handleRecalculateFormMenu = useCallback(
    (formMenu: NestedMapOfMenu[]) => {
      setFormMenu(formMenu)

      if (!currentSearchPattern.value) return

      setSearchThreeIsLoading(true)

      handleRecalculateSearchThreeDebounced(formMenu)
    },
    [currentSearchPattern.value, handleRecalculateSearchThreeDebounced],
  )

  const handleUpdateChanges = useCallback(
    (path: Path<FieldValues> | string, isChanged: boolean) => {
      if (!isChanged) {
        return setListOfChanges((prevArray) => prevArray.filter((item) => item !== path))
      }

      setListOfChanges((prevArray) => Array.from(new Set([...prevArray, path])))
    },
    [],
  )

  useEffectAfterMount(() => {
    if (currentSearchPattern.value) {
      return startGenerateSearchThree()
    }

    setSearchThreeIsLoading(false)

    return setSearchThree(null)
  }, [currentSearchPattern.value])

  useEffect(() => {
    if (currentProjectSZPKHashMap[initialSectionId]) {
      generateThree(initialSectionId)

      return
    }
  }, [])

  useEffectAfterMount(() => {
    if (currentSearchPattern.value) return

    changeCurrentMenuIdToChild(formMenu)
  }, [formMenu])

  const projectSZPKMenuValue: NestedMenuContextProps = useMemo(
    () => ({
      state: {
        formMenu,
        currentMenuItemId,
        searchThreeIsLoading,
        searchThree,
        currentSearchPattern: currentSearchPattern.value,
        listOfChanges,
      },
      handlers: {
        handleChangeSearchPattern,
        getInitialExpandedMenuCondition,
        handleRemoveItemFromThree,
        handleRecalculateFormMenu,
      },
    }),
    [
      currentMenuItemId,
      currentSearchPattern.value,
      getInitialExpandedMenuCondition,
      handleChangeSearchPattern,
      handleRemoveItemFromThree,
      handleRecalculateFormMenu,
      formMenu,
      listOfChanges,
      searchThree,
      searchThreeIsLoading,
    ],
  )

  const NestedMenuChangesContext: NestedMenuHandlersContextProps = useMemo(
    () => ({
      handleUpdateChanges,
      handleAddNewItemInThree,
      handleUpdateItemInThree,
      handleScrollToTarget,
      handleChangeCurrentMenuItemId,
    }),
    [
      handleUpdateChanges,
      handleAddNewItemInThree,
      handleUpdateItemInThree,
      handleScrollToTarget,
      handleChangeCurrentMenuItemId,
    ],
  )

  return (
    <NestedMenuManagerContext.Provider value={projectSZPKMenuValue}>
      <NestedMenuHandlersManagerContext.Provider value={NestedMenuChangesContext}>
        {children}
      </NestedMenuHandlersManagerContext.Provider>
    </NestedMenuManagerContext.Provider>
  )
}

const useNestedMenuManager = () => {
  return useContext(NestedMenuManagerContext)
}

const useNestedMenuHandlersManager = () => {
  return useContext(NestedMenuHandlersManagerContext)
}

export {
  NestedMenuHandlersManagerContext,
  NestedMenuManagerContext,
  useNestedMenuHandlersManager,
  useNestedMenuManager,
}
export default NestedMenuManager
