import { FC } from 'react'

import TagItem from '@components/TagNew/Item'
import TagsWrapper from '@components/TagNew/Wrapper'

interface TagProps extends FC {
  Item: typeof TagItem
  Wrapper: typeof TagsWrapper
}

const Tag: TagProps = () => null

Tag.Item = TagItem
Tag.Wrapper = TagsWrapper

export default Tag
