import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import type { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'

import { mapOfInformationOfExecutionConditionsSZPKMenu } from '../const'
import InformationOfExecutionConditionsSZPKForms from '../Forms'

const mapOfInformationOfExecutionConditionsSZPKForms: FormItemProps = {
  '1': {
    node: <InformationOfExecutionConditionsSZPKForms.First />,
  },
  '2': {
    node: <InformationOfExecutionConditionsSZPKForms.Second />,
  },
  '3': {
    node: <InformationOfExecutionConditionsSZPKForms.Third />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '4': {
    node: <InformationOfExecutionConditionsSZPKForms.Fourth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '5': {
    node: <InformationOfExecutionConditionsSZPKForms.Fifth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '6': {
    node: <InformationOfExecutionConditionsSZPKForms.Sixth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '7': {
    node: <InformationOfExecutionConditionsSZPKForms.Seventh />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '8': {
    node: <InformationOfExecutionConditionsSZPKForms.Eighth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '9': {
    node: <InformationOfExecutionConditionsSZPKForms.Ninth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '10': {
    node: <InformationOfExecutionConditionsSZPKForms.Tenth />,
  },
} as const

const InformationOfExecutionConditionsSZPKStatementForm: FC<FormModalStatementProps> = ({
  ...rest
}) => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { formIsLoading },
  } = useInformationOfExecutionConditionsSZPKManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfInformationOfExecutionConditionsSZPKMenu}
      mapOfForms={mapOfInformationOfExecutionConditionsSZPKForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(InformationOfExecutionConditionsSZPKStatementForm)
