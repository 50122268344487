import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useInformationOfExecutionAgreementEventsManager } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Manager'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'

import { mapOfInformationOfExecutionAgreementEventsMenu } from '../const'
import InformationOfExecutionAgreementEventsForms from '../Forms'

const mapOfInformationOfExecutionAgreementEventsForms: FormItemProps = {
  '1': {
    node: <InformationOfExecutionAgreementEventsForms.First />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const InformationOfExecutionAgreementEventsForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<InformationOfExecutionAgreementEventsFormValues>()

  const {
    state: { formIsLoading },
  } = useInformationOfExecutionAgreementEventsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfInformationOfExecutionAgreementEventsMenu}
      mapOfForms={mapOfInformationOfExecutionAgreementEventsForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(InformationOfExecutionAgreementEventsForm)
