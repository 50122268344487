import React, { FC } from 'react'

import Loader from '@components/Loader'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from '../NotificationsOfRequisitesChanges.module.scss'

export interface LoadingAttachmentProps {
  loading: boolean
  text?: string
}

export const LoadingAttachment: FC<LoadingAttachmentProps> = ({ loading, text = 'Загрузка' }) => {
  return (
    <div className={styles.Row}>
      <Typography
        variant={'bodyMMedium'}
        className={cn(styles.Row_item, styles.Row_item_name__loading)}
      >
        Уведомление о смене реквизитов
      </Typography>
      <div className={styles.Loading}>
        <Loader
          variant="lite"
          loading={loading}
          className={styles.LoadingLoader}
          wrapperClassName={styles.LoadingLoaderWrapper}
        />
        <Typography color={'text-base-tertiary'} variant={'bodySMedium'}>
          {text}
        </Typography>
      </div>
    </div>
  )
}
