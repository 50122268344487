import { Urls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { stringifyQuery } from '@helpers/format'
import { IGetReportsParams, TGetReportsResponse } from '@services/Monitoring/monitoring.entity'

const useMonitoringApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const getReports = async (params: IGetReportsParams, signal?: AbortSignal) => {
    try {
      const reports = await FetcherWithInterceptors.get<TGetReportsResponse>({
        url: Urls.Reports,
        config: {
          signal,
          params,
          paramsSerializer: stringifyQuery,
        },
      })

      return reports.data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    getReports,
  }
}

export default useMonitoringApi
