import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import PetitionTransferRight from '@components/Forms/PetitionTransferRightForm'
import {
  defaultPetitionTransferRightFormValues,
  mapOfPetitionTransferRightMenu,
} from '@components/Forms/PetitionTransferRightForm/const'
import { PetitionTransferRightFormValues } from '@components/Forms/PetitionTransferRightForm/types'
import { PetitionTransferRightFieldsControlUpdateWatcher } from '@components/Forms/PetitionTransferRightForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import PetitionTransferRightLayoutWrapper from './Wrapper'

const PetitionTransferRightLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<PetitionTransferRightFormValues>({
    defaultValues: defaultPetitionTransferRightFormValues,
  })

  const handleOnClose = () => {
    PetitionTransferRightFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <PetitionTransferRight.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfPetitionTransferRightMenu[0].id}>
            <PetitionTransferRightLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Ходатайство о заключении дополнительного соглашения о передаче прав
                </Typography.Headline>
                <PetitionTransferRight.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <PetitionTransferRight.Menu className={styles.layout__menu} />
              </div>
            </PetitionTransferRightLayoutWrapper>
          </FlatMenuManager>
        </PetitionTransferRight.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(PetitionTransferRightLayout)
