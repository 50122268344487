import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { DismantlingObjectsArrayPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/5/types'
import { fifthSectionValidationMap } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/5/validation'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface ObjectItemProps {
  blockViewIsValidating: boolean
  editMode: boolean
  objectId: string
  indexOfObject: number
  name: DismantlingObjectsArrayPathName
  formInstance: UseFormReturn<InformationOfExecutionConditionsSZPKFormValues>
  onDeleteObject: VoidFunction
}

const ObjectItem: FC<ObjectItemProps> = ({
  blockViewIsValidating,
  editMode,
  objectId,
  indexOfObject,
  name,
  formInstance,
  onDeleteObject,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  return (
    <FieldView.StepTemplate
      id={objectId}
      title={`Объект №${indexOfObject + 1}`}
      onRemoveStep={editMode ? onDeleteObject : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.dismantlingObjectName`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.dismantlingObjectName`,
                  rules: fifthSectionValidationMap.dismantlingObjectName,
                  textareaProps: {
                    label: 'Наименование объекта',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.dismantlingObjectName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.dismantlingObjectName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default ObjectItem
