import React, { createContext, FC, useCallback, useContext, useMemo } from 'react'
import { useParams } from 'react-router'

import { useDeleteProjectModal } from '@components/NewDesignedModals/DeleteProjectModal/manager'
import { useRejectSZPKModal } from '@components/NewDesignedModals/RejectSZPKModal'
import { useProcessRejectTypes } from '@components/NewDesignedModals/RejectSZPKModal/store'
import { useRenameProjectModal } from '@components/NewDesignedModals/RenameProject/manager'
import { getDfoByType } from '@components/Projects/[id]/helpers'
import { useLayoutMode } from '@components/Projects/[id]/LayoutMods'
import { DfosStage, NewDfosType, Roles, RolesByActions, RolesTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { getUserRole } from '@context/AuthContext/workers/rolesWorkers'
import { compact } from '@helpers/array/compact'
import { findOne } from '@helpers/commonHelpers'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import { useCreateDfo } from '@hooks/new/dfo/useCreateDfo'
import { useDfoAvailableForCreate } from '@hooks/new/swr/useDfoAvailableForCreate'
import { useProject } from '@hooks/new/swr/useProject'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useProjectActions } from '@hooks/new/swr/useUiActions'
import { useQueryManager } from '@hooks/useQueryManager'
import deleteIcon from '@icons/DeleteIcon.svg'
import DfoRejectIcon from '@icons/DfoRejectIcon.svg'
import DuplicateIcon from '@icons/DuplicateIcon.svg'
import editIcon from '@icons/EditIcon.svg'
import downloadIcon from '@icons/file/download.svg'
import projectRejectIcon from '@icons/ProjectRejectIcon.svg'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import DfoService from '@services/Dfo/Dfo.service'
import {
  IDefaultUserAction,
  IDocumentAction,
  mapOfDocumentActions,
} from '@services/DocumentActions/DocumentActions.entity'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'
import { IDocumentSetInfo } from '@services/Documents/documents.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { TGetProjectActions } from '@services/Projects/Project.entity'

import { useParticipantsOfStatement } from '../../ParticipantsOfStatement/store'

export interface ICurrentDfoActionProjectPanel extends Omit<IDocumentAction, 'position'> {
  position: 'statusPanel'
}

export interface ICurrentDfoActionHamburgerButton extends Omit<IDocumentAction, 'position'> {
  position: 'hamburgerButton'
  icon?: string
}

export interface ICurrentDfoActionDocumentsButton extends Omit<IDocumentAction, 'position'> {
  position: 'documentsBottom'
}

export interface ICurrentDfoActionSetArea extends Omit<IDocumentAction, 'position'> {
  position: 'setArea'
}

type UiActionsContextProps = {
  state: {
    isLoadingProjectActions: boolean
    projectActions: TGetProjectActions
    currentDfoActions: IDocumentAction[]
    projectPanelActions: ICurrentDfoActionProjectPanel[]
    hamburgerActions: (ICurrentDfoActionHamburgerButton | IDefaultUserAction)[]
    setAreaActions: ICurrentDfoActionSetArea[]
  }
}

export const isPermissionForChange = ({ permissions }: IDocumentSetInfo) =>
  permissions === 3 || permissions === 7

const UiActionsContext = createContext<UiActionsContextProps>({
  state: {
    isLoadingProjectActions: false,

    projectActions: [],
    currentDfoActions: [],
    hamburgerActions: [],
    projectPanelActions: [],
    setAreaActions: [],
  },
})

const { comparePanelFunctions } = DocumentActionsService
const { isDfoRejectWillBeCreate } = DfoService

const UiActionsManager: FC = ({ children }) => {
  const {
    documentsApi: { downloadProjectArchive },
  } = useAPIContext()

  const { isChangesMadeMode } = useLayoutMode()

  const { projectId } = useParams()
  const { queryUtils } = useQueryManager()

  const dfoId = queryUtils.getQuery('dfoId')

  const { handleCreateDfo } = useCreateDfo(projectId || '')

  const {
    state: { hasTempAttribute, hasPermissions, isFirstVersionInvestProject },
  } = useParticipantsOfStatement()

  const { roles } = getUserRole?.()

  const isFKOrUO = findOne(roles, RolesByActions.CantCreateNewODO)
  const isInvestor = findOne(roles, Roles.Investor)

  const { projectActions, isLoadingProjectActions } = useProjectActions({
    key: {
      projectId,
      _key: 'projectActions',
    },
  })

  const { project } = useProject({ key: { projectId: projectId, _key: 'project' } })

  const { dfoAvailableForCreate } = useDfoAvailableForCreate({
    key: isFKOrUO
      ? null
      : {
          projectId,
          _key: 'availableDfoForCreate',
        },
  })

  const { projectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'UiActionsManager',
          componentType: 'manager',
        },
      }),
    },
  })

  const currentRejectTypes = useProcessRejectTypes(projectId, dfoAvailableForCreate)

  const { handleOpenRejectSZPKModal } = useRejectSZPKModal()

  const INVESTPROJECTDfo = useMemo(() => {
    if (!projectDfos) return

    return getDfoByType(projectDfos, NewDfosType.INVESTPROJECT) as IDfoListItem
  }, [projectDfos])

  const isInvestProjectInDraft = useMemo(() => {
    return INVESTPROJECTDfo?.stage === DfosStage.DRAFT
  }, [INVESTPROJECTDfo?.stage])

  const { handleOpenRenameProjectModal } = useRenameProjectModal()
  const { handleOpenDeleteProjectModal } = useDeleteProjectModal()

  const handleDownloadProjectArchiveWithToast = useCallback(() => {
    return withDownloadToastPromise(downloadProjectArchive({ projectId: projectId ?? '' }))
  }, [downloadProjectArchive, projectId])

  const handleEditAction = useCallback(
    (isFederalAccess) => {
      handleOpenRenameProjectModal({
        projectId: projectId || '',
        dfoId: dfoId || '',
        projectName: project?.name || '',
        isFederalProject: !!project?.isFederal,
        isFederalAccess,
      })
    },
    [projectId, dfoId, project?.name, project?.isFederal],
  )

  const handleDeleteAction = useCallback(() => {
    handleOpenDeleteProjectModal({
      projectId: projectId || '',
    })
  }, [projectId, handleOpenDeleteProjectModal])

  const handleRejectButtonCallback = useCallback(async () => {
    if (!roles || !projectId || !currentRejectTypes.length) return

    if (isInvestor || currentRejectTypes.length === 1) {
      try {
        await handleCreateDfo(currentRejectTypes[0])()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          additionInfo: {
            type: currentRejectTypes[0],
          },
          componentInfo: {
            componentName: 'UiActionsManager',
            componentType: 'terminateStatement',
          },
        })(error)

        throw error
      }

      return
    }

    handleOpenRejectSZPKModal(currentRejectTypes)
  }, [currentRejectTypes, handleCreateDfo, handleOpenRejectSZPKModal, isInvestor, projectId, roles])

  const customActionsForAll = useMemo(
    () => ({
      downloadAction: {
        displayName: 'Скачать',
        type: 'DOWNLOAD',
        callback: handleDownloadProjectArchiveWithToast,
        icon: downloadIcon,
      },
    }),
    [handleDownloadProjectArchiveWithToast],
  )

  const actionsForChangeStage: IDefaultUserAction | undefined = useMemo(
    () =>
      isChangesMadeMode
        ? {
            displayName: 'Редактировать',
            type: 'EDIT',
            callback: () => handleEditAction(false),
            icon: editIcon,
          }
        : undefined,
    [isChangesMadeMode, handleEditAction],
  )

  const actionsForOnlyFirstVersionsAndInvestorRole: Record<
    'editAction' | 'deleteAction',
    IDefaultUserAction | undefined
  > = useMemo(() => {
    const mainCondition = isInvestor && isInvestProjectInDraft && !!isFirstVersionInvestProject

    const isEnabledEdit =
      isInvestor && (isInvestProjectInDraft || (hasTempAttribute && hasPermissions))

    return {
      editAction: isEnabledEdit
        ? {
            displayName: 'Редактировать',
            type: 'EDIT',
            callback: () => handleEditAction(isFirstVersionInvestProject),
            icon: editIcon,
          }
        : undefined,

      deleteAction: mainCondition
        ? {
            displayName: 'Удалить',
            type: 'DELETE',
            callback: handleDeleteAction,
            icon: deleteIcon,
          }
        : undefined,
    }
  }, [
    isInvestor,
    isInvestProjectInDraft,
    isFirstVersionInvestProject,
    hasTempAttribute,
    hasPermissions,
    handleDeleteAction,
    handleEditAction,
  ])

  const defaultHamburgerActions: IDefaultUserAction[] = useMemo(
    () =>
      compact([
        actionsForChangeStage || actionsForOnlyFirstVersionsAndInvestorRole.editAction,
        {
          displayName: 'Скачать',
          type: 'DOWNLOAD',
          callback: handleDownloadProjectArchiveWithToast,
          icon: downloadIcon,
        },
        actionsForOnlyFirstVersionsAndInvestorRole.deleteAction,
      ]) as IDefaultUserAction[],
    [
      actionsForChangeStage,
      actionsForOnlyFirstVersionsAndInvestorRole.deleteAction,
      actionsForOnlyFirstVersionsAndInvestorRole.editAction,
      handleDownloadProjectArchiveWithToast,
    ],
  )

  const currentDfoActions = useMemo(() => {
    if (!projectActions) return []

    return compact(
      projectActions.map((projectAction) => {
        if (projectAction.dfos.findIndex((item) => item.dfoId === dfoId) === -1) return null

        return {
          actionId: projectAction.actionId,
          action: projectAction.action,
          enable: projectAction.enable,
          uiActions: projectAction.uiActions,
          displayName: projectAction.displayName,
          position: projectAction.position,
          dfos: projectAction.dfos,
        }
      }),
    ) as IDocumentAction[]
  }, [projectActions, dfoId])

  const hamburgerActions = useMemo(() => {
    //Actions, доступные любой роли, как пример, скачивания проекта
    const readActions = [customActionsForAll.downloadAction]

    const rejectButtonRender = dfoAvailableForCreate &&
      isDfoRejectWillBeCreate(dfoAvailableForCreate) && {
        displayName: 'Расторгнуть соглашение',
        type: 'TERMINATE',
        callback: handleRejectButtonCallback,
        icon: DfoRejectIcon,
      }

    if (!projectActions) return []

    const allowedActions = projectActions
      .filter((item) => item.position === 'hamburgerButton' && item.enable)
      .map((action) => {
        if (action.type === 'REVOKE') {
          return {
            ...action,
            icon: projectRejectIcon,
          } as unknown as ICurrentDfoActionHamburgerButton
        }

        if (action.uiActions.includes(mapOfDocumentActions.COPY_PROJECT)) {
          return {
            ...action,
            icon: DuplicateIcon,
          }
        }

        return action
      }) as ICurrentDfoActionHamburgerButton[]

    const isInvestProjectInDraftAndInvestor =
      isInvestProjectInDraft && roles.includes(RolesTypes.INVESTOR)

    if (isInvestProjectInDraftAndInvestor || isChangesMadeMode) {
      return [...defaultHamburgerActions, ...allowedActions]
    }

    return [...readActions, ...allowedActions, ...(rejectButtonRender ? [rejectButtonRender] : [])]
  }, [
    isChangesMadeMode,
    customActionsForAll.downloadAction,
    defaultHamburgerActions,
    dfoAvailableForCreate,
    handleRejectButtonCallback,
    isInvestProjectInDraft,
    projectActions,
    roles,
  ])

  const projectPanelActions = useMemo(() => {
    if (!currentDfoActions.length) return []

    return (
      currentDfoActions.filter(
        (item) => item.position === 'statusPanel',
      ) as ICurrentDfoActionProjectPanel[]
    ).sort(comparePanelFunctions)
  }, [currentDfoActions])

  //Добавляется только к сету с типом DEPARTMENTS_RESPONSES
  const setAreaActions = useMemo(() => {
    return currentDfoActions.filter(
      (item) => item.position === 'setArea',
    ) as ICurrentDfoActionSetArea[]
  }, [currentDfoActions])

  const providerValue = useMemo(
    () => ({
      state: {
        isLoadingProjectActions,
        projectActions: projectActions || [],
        currentDfoActions,
        hamburgerActions,
        projectPanelActions,
        setAreaActions,
      },
    }),
    [
      isLoadingProjectActions,
      projectActions,
      currentDfoActions,
      hamburgerActions,
      projectPanelActions,
      setAreaActions,
    ],
  )

  return <UiActionsContext.Provider value={providerValue}>{children}</UiActionsContext.Provider>
}

const useUiActionsManager = () => {
  return useContext(UiActionsContext)
}

export { useUiActionsManager }
export default UiActionsManager
