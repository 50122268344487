import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const projectResultEcoActivityValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['9']['projectResultEcoActivity'],
  ProjectSZPKFormValues
> = {
  ecoActivityName: {
    required: defaultRHFValidation.required,
  },
  ecoActivityEndDate: {
    required: defaultRHFValidation.required,
  },
  ecoActivityProgramNameInstrumental: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100000),
  },
}

const ninthSectionValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['9'],
  ProjectSZPKFormValues
> = {
  projectResultActivity: {
    required: defaultRHFValidation.required,
  },
  otherProjectResultActivity: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { ninthSectionValidationMap, projectResultEcoActivityValidationMap }
