import { usePopupManager } from 'react-popup-manager'

import type { AddMunicipalityDfoModalProps, HandleOpenAddOMSUModalProps } from './types'
import AddMunicipalityDfoModal from '.'

const useMunicipalityDfoModal = () => {
  const popupManager = usePopupManager()

  const handleOpenAddMunicipalityDfoModal = (props: HandleOpenAddOMSUModalProps) => {
    popupManager.open<AddMunicipalityDfoModalProps>(AddMunicipalityDfoModal, {
      ...props,
      onClose: props.onClose ?? (() => null),
    })
  }

  return {
    handleOpenAddMunicipalityDfoModal,
  }
}

export default useMunicipalityDfoModal
