import React from 'react'
import { useFormContext } from 'react-hook-form'

import RFSection from '@components/DocumentFormComponents/FormSections/RFSection'
import { useAATransferRightsAndObligationsManager } from '@components/Forms/AATransferRightsAndObligations/Manager'
import { AATransferRightsAndObligationsFormValues } from '@components/Forms/AATransferRightsAndObligations/types'
import { AATransferRightsAndObligationsFieldsControlUpdateWatcher } from '@components/Forms/AATransferRightsAndObligations/watcher'

const First = () => {
  const formInstance = useFormContext<AATransferRightsAndObligationsFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAATransferRightsAndObligationsManager()

  if (!formInstance) return null

  return (
    <RFSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={AATransferRightsAndObligationsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default First
