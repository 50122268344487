import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import styles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import SummaryReportOfMonitoringResultsRegionReportInfoCollapse from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoCollapse'
import { summaryReportOfMonitoringResultsEconomicSphereOkvedsValidationMap } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/4/EconomicSphereOkvedsList/validation'
import { SummaryReportOfMonitoringResultsEconomicSphereOkvedsArrayPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/4/types'
import { SummaryReportOfMonitoringResultsRegionReportInfoPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/4/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import Stack from '@components/ReactBootstrap/Stack'
import { useBooleanState } from '@hooks/useBooleanState'
import DeleteIcon from '@icons/DeleteIcon.svg'
import arrowSubdirectoryIcon from '@icons/navigation/arrow-subdirectory.svg'
import cn from 'classnames'

import SummaryReportOfMonitoringResultsEconomicSphereOkvedRegionItem from './RegionItem'

const { getOkvedTitle } = DocumentFormHelpers

interface SummaryReportOfMonitoringResultsEconomicSphereOkvedItemProps {
  blockViewIsValidating: boolean
  expandedRenderMode: boolean
  editMode: boolean
  subObjectId: string
  indexOfObject: number
  name: SummaryReportOfMonitoringResultsEconomicSphereOkvedsArrayPathName
  formInstance: UseFormReturn<SummaryReportOfMonitoringResultsFormValues>
  onDeleteSubObject: VoidFunction
}

const SummaryReportOfMonitoringResultsEconomicSphereOkvedItem: FC<
  SummaryReportOfMonitoringResultsEconomicSphereOkvedItemProps
> = ({
  blockViewIsValidating,
  expandedRenderMode,
  editMode,
  subObjectId,
  indexOfObject,
  name,
  formInstance,
  onDeleteSubObject,
}) => {
  const { fields: regionReportsInfo } = useFieldArraySubscribableControl<
    SummaryReportOfMonitoringResultsFormValues,
    SummaryReportOfMonitoringResultsRegionReportInfoPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.regionsOkvedReportInfo`,
    keyName: 'keyNameId',
    watcher: SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const {
    booleanState: isShowObjectItemContent,
    reverseBooleanState: toggleShowObjectItemContent,
  } = useBooleanState(expandedRenderMode)

  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const totalCapitalEconomicSphereOkvedFederalReportRegionDeviation = useWatch({
    name: `${name}.totalCapitalEconomicSphereOkvedFederalReportRegionDeviation`,
    control: formInstance?.control,
  })

  const economicSphereOkvedCodeFederalReport = useWatch({
    name: `${name}.economicSphereOkvedCodeFederalReport`,
    control: formInstance?.control,
  })

  const investorCapitalEconomicSphereOkvedFederalReportRegionDeviation = useWatch({
    name: `${name}.investorCapitalEconomicSphereOkvedFederalReportRegionDeviation`,
    control: formInstance?.control,
  })

  const newWorkPlacesEconomicSphereOkvedFederalReportRegionDeviation = useWatch({
    name: `${name}.newWorkPlacesEconomicSphereOkvedFederalReportRegionDeviation`,
    control: formInstance?.control,
  })

  const { getSubscribableControlProps, getHierarchyReferenceBookSelectProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
    })

  const handleRemoveEconomicSphereOkved = async () => {
    await onDeleteSubObject?.()
  }

  return (
    <FlippedWithCollapse flipId={subObjectId}>
      <div className={cn(styles.objectItem, styles['objectItem--subObject'])}>
        <div className={styles.objectItem__header}>
          <ObjectStepWrapperHeaderNode
            buttonProps={{
              leadingIcon: {
                src: arrowSubdirectoryIcon,
                size: 'xs',
              },
              onClick: toggleShowObjectItemContent,
              trailingIcon: {
                className: cn({
                  [styles['objectItem__header-button__icon--active']]: isShowObjectItemContent,
                }),
              },
            }}
          >
            {getOkvedTitle(indexOfObject + 1, economicSphereOkvedCodeFederalReport?.code)}
          </ObjectStepWrapperHeaderNode>
          {editMode && (
            <AsyncWrapper promise={handleRemoveEconomicSphereOkved}>
              {({ isLoading, wrappedPromise }) => (
                <Button
                  disabled={isLoading}
                  variant={'buttonSMedium'}
                  size={'2xs'}
                  color={'negative'}
                  view={'plain'}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  leadingIcon={{
                    src: DeleteIcon,
                  }}
                  onClick={wrappedPromise}
                >
                  Удалить
                </Button>
              )}
            </AsyncWrapper>
          )}
        </div>
        <div className={styles.objectItem__content}>
          <CollapseWrapper
            defaultExpanded={isShowObjectItemContent}
            isExpanded={isShowObjectItemContent}
          >
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.economicSphereOkvedCodeFederalReport`,
                })}
              >
                <ControlledHierarchyReferenceBookSelect
                  {...getHierarchyReferenceBookSelectProps({
                    dictionaryName: 'okved',
                    controllerProps: {
                      name: `${name}.economicSphereOkvedCodeFederalReport`,
                      rules:
                        summaryReportOfMonitoringResultsEconomicSphereOkvedsValidationMap.economicSphereOkvedCodeFederalReport,
                    },
                    inputProps: {
                      label: 'Код ОКВЭД',
                    },
                    onChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.economicSphereOkvedCodeFederalReport`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
              <Group disableBottomBorder title="Количество проектов">
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Количество по региональным отчетам'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.countEconomicSphereOkvedFederalReport`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.countEconomicSphereOkvedFederalReport`,
                        inputProps: {
                          disabled: true,
                          suffix: '',
                          integersOnly: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Количество для переноса в отчет'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.countEconomicSphereOkvedFederalReportCorrect`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.countEconomicSphereOkvedFederalReportCorrect`,
                        inputProps: {
                          suffix: '',
                          integersOnly: true,
                        },
                        rules:
                          summaryReportOfMonitoringResultsEconomicSphereOkvedsValidationMap.countEconomicSphereOkvedFederalReportCorrect,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${name}.countEconomicSphereOkvedFederalReportCorrect`,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${name}.countEconomicSphereOkvedFederalReportCorrect`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
              </Group>
              <Group disableBottomBorder title="Объем капитальных вложений">
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Объем по справкам проверяющей организации'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.totalCapitalEconomicSphereOkvedFederalReportChecker`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.totalCapitalEconomicSphereOkvedFederalReportChecker`,
                        inputProps: {
                          disabled: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Объем по региональным отчетам'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.totalCapitalEconomicSphereOkvedFederalReportRegion`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.totalCapitalEconomicSphereOkvedFederalReportRegion`,
                        inputProps: {
                          disabled: true,
                          leftAddons: (
                            <FormIconWithTooltip
                              tooltipContent={
                                totalCapitalEconomicSphereOkvedFederalReportRegionDeviation
                              }
                            />
                          ),
                        },
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Объем для переноса в отчет'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.totalCapitalEconomicSphereOkvedFederalReportCorrect`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.totalCapitalEconomicSphereOkvedFederalReportCorrect`,
                        rules:
                          summaryReportOfMonitoringResultsEconomicSphereOkvedsValidationMap.totalCapitalEconomicSphereOkvedFederalReportCorrect,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${name}.totalCapitalEconomicSphereOkvedFederalReportCorrect`,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${name}.totalCapitalEconomicSphereOkvedFederalReportCorrect`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
              </Group>
              <Group disableBottomBorder title="Объем капиталовложений">
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Объем по справкам проверяющей организации'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.investorCapitalEconomicSphereOkvedFederalReportChecker`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.investorCapitalEconomicSphereOkvedFederalReportChecker`,
                        inputProps: {
                          disabled: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Объем по региональным отчетам'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.investorCapitalEconomicSphereOkvedFederalReportRegion`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.investorCapitalEconomicSphereOkvedFederalReportRegion`,
                        inputProps: {
                          disabled: true,
                          leftAddons: (
                            <FormIconWithTooltip
                              tooltipContent={
                                investorCapitalEconomicSphereOkvedFederalReportRegionDeviation
                              }
                            />
                          ),
                        },
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Объем для переноса в отчет'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.investorCapitalEconomicSphereOkvedFederalReportCorrect`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.investorCapitalEconomicSphereOkvedFederalReportCorrect`,
                        rules:
                          summaryReportOfMonitoringResultsEconomicSphereOkvedsValidationMap.investorCapitalEconomicSphereOkvedFederalReportCorrect,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${name}.investorCapitalEconomicSphereOkvedFederalReportCorrect`,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${name}.investorCapitalEconomicSphereOkvedFederalReportCorrect`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
              </Group>
              <Group title="Количество рабочих мест">
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Количество по справкам проверяющей организации'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.newWorkPlacesEconomicSphereOkvedFederalReportChecker`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.newWorkPlacesEconomicSphereOkvedFederalReportChecker`,
                        inputProps: {
                          disabled: true,
                          suffix: '',
                          integersOnly: true,
                        },
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Количество по региональным отчетам'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.newWorkPlacesEconomicSphereOkvedFederalReportRegion`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.newWorkPlacesEconomicSphereOkvedFederalReportRegion`,
                        inputProps: {
                          disabled: true,
                          suffix: '',
                          integersOnly: true,
                          leftAddons: (
                            <FormIconWithTooltip
                              tooltipContent={
                                newWorkPlacesEconomicSphereOkvedFederalReportRegionDeviation
                              }
                            />
                          ),
                        },
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Количество для переноса в отчет'}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.newWorkPlacesEconomicSphereOkvedFederalReportCorrect`,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.newWorkPlacesEconomicSphereOkvedFederalReportCorrect`,
                        inputProps: {
                          suffix: '',
                          integersOnly: true,
                        },
                        rules:
                          summaryReportOfMonitoringResultsEconomicSphereOkvedsValidationMap.newWorkPlacesEconomicSphereOkvedFederalReportCorrect,
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              `${name}.newWorkPlacesEconomicSphereOkvedFederalReportCorrect`,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              `${name}.newWorkPlacesEconomicSphereOkvedFederalReportCorrect`,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
              </Group>

              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.regionsOkvedReportInfo`,
                })}
              >
                <FlipperList list={regionReportsInfo}>
                  {regionReportsInfo.map((item, index) => {
                    const formName = `${name}.regionsOkvedReportInfo.${index}` as const

                    return (
                      <SummaryReportOfMonitoringResultsRegionReportInfoCollapse
                        indexOfReport={index}
                        title={`${item.regionCode} ${item.regionName}`}
                      >
                        <SummaryReportOfMonitoringResultsEconomicSphereOkvedRegionItem
                          blockViewIsValidating={blockViewIsValidating}
                          editMode={editMode}
                          formInstance={formInstance}
                          name={formName}
                        />
                      </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
                    )
                  })}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </CollapseWrapper>
        </div>
      </div>
    </FlippedWithCollapse>
  )
}

export default SummaryReportOfMonitoringResultsEconomicSphereOkvedItem
