import { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useExtendedFormModeByUserRoles } from '@components/DocumentFormComponents/hooks/useExtendedFormModeByUserRoles'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import BeneficiaryInformation from '@components/Forms/BeneficiaryInformationForm'
import {
  defaultBeneficiaryFormValues,
  mapOfBeneficiaryInformationMenu,
} from '@components/Forms/BeneficiaryInformationForm/const'
import { BeneficiaryInformationFormValues } from '@components/Forms/BeneficiaryInformationForm/types'
import { BeneficiaryInformationFieldsControlUpdateWatcher } from '@components/Forms/BeneficiaryInformationForm/watcher'
import { Switch } from '@components/NewDesign/Switch'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import BeneficiaryInformationLayoutWrapper from './Wrapper'

const BeneficiaryInformationLayout: FC<FormModalLayoutProps> = ({
  projectId,
  editMode,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  rolesOfUser,
}) => {
  const formInstance = useFormWithDefaultProps<BeneficiaryInformationFormValues>({
    defaultValues: defaultBeneficiaryFormValues,
  })

  const {
    currentDebugMode,
    handleChangeDebugMode,
    conditionToActivateDebugMode,
    disabledDebugMode,
  } = useExtendedFormModeByUserRoles(rolesOfUser)

  const handleOnClose = () => {
    disabledDebugMode()
    BeneficiaryInformationFieldsControlUpdateWatcher.resetAllState()

    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <BeneficiaryInformation.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          rolesOfUser={rolesOfUser}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfBeneficiaryInformationMenu[0].id}>
            <BeneficiaryInformationLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Информация о бенефициарах
                </Typography.Headline>
                <BeneficiaryInformation.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}
              >
                <BeneficiaryInformation.Menu
                  className={cn(styles.layout__menu, styles['layout__menu--nested'])}
                >
                  {conditionToActivateDebugMode && (
                    <Switch
                      defaultState={currentDebugMode}
                      wrapperClassName={styles.layout__debug}
                      label={'Просмотр мета информации'}
                      onChange={handleChangeDebugMode}
                    />
                  )}
                </BeneficiaryInformation.Menu>
              </div>
            </BeneficiaryInformationLayoutWrapper>
          </FlatMenuManager>
        </BeneficiaryInformation.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(BeneficiaryInformationLayout)
