import React, { useCallback, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import Loader from '@components/Loader'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import SystemVariablesItem from '@components/SystemVariables/List/Item'
import { useSystemVariablesManager } from '@components/SystemVariables/Manager'
import { SystemVariablesFormValues } from '@components/SystemVariables/types'
import { TransformedSystemVariableItem } from '@context/APIContext/hooks/useOperatorApi'

const SystemVariablesList = () => {
  const formInstance = useFormContext<SystemVariablesFormValues>()

  const {
    state: { isLoading, error },
    handlers: { updateSystemVariable },
  } = useSystemVariablesManager()

  const { fields: variables, update } = useFieldArray({
    name: 'variables',
    control: formInstance?.control,
  })

  const noDataFallbackRenderCondition = useMemo(
    () => !isLoading && !error && !variables.length,
    [error, isLoading, variables.length],
  )

  const handleErrorUpdateSystemVariable = useCallback(
    (index: number) => (variableItem: TransformedSystemVariableItem) => {
      formInstance.setValue(`variables.${index}.value`, variableItem.value)

      update(index, variableItem)
    },
    [formInstance, update],
  )

  return (
    <Row>
      <Col xs={12}>
        <Stack gap={3} direction={'vertical'}>
          {noDataFallbackRenderCondition && <NoDataPlaceholder />}
          {variables.map((item, index) => (
            <SystemVariablesItem
              key={item.type}
              name={`variables.${index}` as const}
              item={item}
              formInstance={formInstance}
              onUpdate={updateSystemVariable}
              onError={handleErrorUpdateSystemVariable(index)}
            />
          ))}
          <Loader loading={isLoading} />
        </Stack>
      </Col>
    </Row>
  )
}

export default SystemVariablesList
