import { compact } from '@helpers/array/compact'

const generatePrefetchedPropsForParametersOfCostRecoveryApplicationForm = (
  objectFromPropertyState: Record<string, any>,
): string[] => {
  return compact([
    objectFromPropertyState.compensationStatementBudgetLevels?.propertyId,
    objectFromPropertyState.compensationStatementExpenseGroup?.propertyId,
  ])
}

export { generatePrefetchedPropsForParametersOfCostRecoveryApplicationForm }
