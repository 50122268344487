import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useCertificateOfImplementationInvestprojectStagesManager } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Manager'
import { CertificateOfImplementationInvestprojectStagesFormValues } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'

import { mapOfCertificateOfImplementationInvestprojectStagesMenu } from '../const'
import CertificateOfImplementationInvestprojectStageseForms from '../Forms'

const mapOfCertificateOfImplementationInvestprojectStagesForms: FormItemProps = {
  '1': {
    node: <CertificateOfImplementationInvestprojectStageseForms.First />,
  },
  '2': {
    node: <CertificateOfImplementationInvestprojectStageseForms.Second />,
  },
  '3': {
    node: <CertificateOfImplementationInvestprojectStageseForms.Third />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const CertificateOfImplementationInvestprojectStagesForm: FC<FormModalStatementProps> = ({
  ...rest
}) => {
  const formInstance = useFormContext<CertificateOfImplementationInvestprojectStagesFormValues>()

  const {
    state: { formIsLoading },
  } = useCertificateOfImplementationInvestprojectStagesManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfCertificateOfImplementationInvestprojectStagesMenu}
      mapOfForms={mapOfCertificateOfImplementationInvestprojectStagesForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(CertificateOfImplementationInvestprojectStagesForm)
