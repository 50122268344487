import { FC, memo, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { usePopupManager } from 'react-popup-manager'

import Form from '@components/Form'
import type { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import { ControlledSingleSelect } from '@components/NewDesign/Select'
import { extractValueFromOption } from '@components/NewDesign/Select/helpers'
import { OptionProps } from '@components/NewDesign/Select/model'
import { DfoAttributeTypes, DfoPropertiesTypes } from '@constants/types'
import { defaultRHFValidation } from '@constants/validations'
import MunicipalityOptionsTailNode from '@containers/MunicipalityOptionsTailNode'
import { useAPIContext } from '@context/APIContext'
import type {
  PostDfoAttributeProps,
  PutDfoAttributeProps,
  PutDfoPropertiesProps,
} from '@context/APIContext/hooks/useDFOSApi'
import { getUniqueListBy } from '@helpers/array/getUniqueValues'
import { isUndefined } from '@helpers/checkTypes'
import { useOrganizationInfoById } from '@hooks/new/swr/useOrganizationInfoById'
import { useRegions } from '@hooks/new/swr/useRegions'
import { useBooleanState } from '@hooks/useBooleanState'
import { useMunicipalitySelectConfig } from '@hooks/useMunicipalitySelectConfig'
import { DEFAULT_SYSTEM_VERSION } from '@services/Dfo/Dfo.const'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './AddMunicipalityDfoModal.module.scss'
import { municipalityDfoFormNames } from './constants'
import type { AddMunicipalityDfoModalProps, MunicipalityDfoFormValues } from './types'

const AddMunicipalityDfoModal: FC<AddMunicipalityDfoModalProps> = ({
  isOpen,
  projectId,
  dfo,
  onClose,
  municipalityOrganizationId,
  onSave,
}) => {
  const dfoId = dfo.id
  const systemVersion = dfo.systemVersion ?? DEFAULT_SYSTEM_VERSION

  const {
    dfosApi: { postDfoAttribute, putDfoAttribute, putDfoProperties },
  } = useAPIContext()

  const popupManager = usePopupManager()

  const {
    control,
    watch,
    formState: { isValid, isSubmitting },
    getValues,
  } = useForm<MunicipalityDfoFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      municipality: municipalityOrganizationId || '',
    },
  })

  const {
    preparedMunicipalityOption,
    onContentScroll,
    onChangeSearchValue,
    municipalityData: { isMunicipalityLoading },
  } = useMunicipalitySelectConfig()

  const { regions } = useRegions({
    key: {
      _key: 'regions',
    },
  })

  const currentMunicipallity = extractValueFromOption(watch('municipality'))

  const { organization } = useOrganizationInfoById({
    key: currentMunicipallity
      ? {
          organizationId: currentMunicipallity,
          _key: 'organizationInfo',
        }
      : null,
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AddMunicipalityDfoModal',
          moduleName: 'MunicipalAccession',
          componentType: 'modal',
        },
        additionInfo: {
          organizationId: currentMunicipallity,
        },
      }),
    },
  })

  const {
    booleanState: isLoading,
    setBooleanStateToFalse: disableLoading,
    setBooleanStateToTrue: enableLoading,
  } = useBooleanState()

  const defaultSelectedMunicipalityOrganization: OptionProps[] = useMemo(() => {
    if (!organization) return []

    const selectedOption: OptionProps = {
      label: organization.regionCode ? regions?.[organization.regionCode] : undefined,
      displayValue: organization.municipalityName || '',
      value: organization.id,
    }

    return [selectedOption]
  }, [organization, regions])

  const currentMunicipalityOptions = useMemo(() => {
    return getUniqueListBy(
      [...defaultSelectedMunicipalityOrganization, ...preparedMunicipalityOption],
      'value',
    ) as OptionProps[]
  }, [defaultSelectedMunicipalityOrganization, preparedMunicipalityOption])

  const updateMunicipalityDfoProperties = useCallback(
    async (propertiesProps: PutDfoPropertiesProps) => {
      const {
        value: { id: municipalityId },
      } = propertiesProps.body

      try {
        await putDfoProperties(propertiesProps)

        popupManager.closeAll()
        onSave?.()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'AddMunicipalityDfoModal',
            componentType: 'updateMunicipalityDfoProperties',
            moduleName: 'MunicipalAccession',
          },
          additionInfo: {
            municipalityId,
            dfo,
          },
        })(error)

        throw error
      }
    },
    [putDfoProperties, onSave, dfo, popupManager],
  )

  const addMunicipalityDfoAttribute = useCallback(
    async (attributeProps: PostDfoAttributeProps) => {
      const { name, value } = attributeProps.body

      try {
        await postDfoAttribute(attributeProps)

        popupManager.closeAll()
        onSave?.()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'AddMunicipalityDfoModal',
            componentType: 'addMunicipalDfoAttribute',
            moduleName: 'MunicipalAccession',
          },
          additionInfo: {
            attributeName: name,
            attributeValue: value,
            dfo,
          },
        })(error)

        throw error
      }
    },
    [postDfoAttribute, onSave, popupManager, dfo],
  )

  const updateMunicipalityDfoAttribute = useCallback(
    async (attributeProps: PutDfoAttributeProps) => {
      const { name, value } = attributeProps.body

      try {
        await putDfoAttribute(attributeProps)

        popupManager.closeAll()
        onSave?.()
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'AddMunicipalityDfoModal',
            componentType: 'updateMunicipalityDfoAttribute',
            moduleName: 'MunicipalAccession',
          },
          additionInfo: {
            attributeName: name,
            attributeValue: value,
            dfo,
          },
        })(error)

        throw error
      }
    },
    [putDfoAttribute, onSave, popupManager, dfo],
  )

  const handleSaveMunicipalityOrganizationViaAttributes = useCallback(
    async (selectedMunicipalityId: string) => {
      const postRequestPayload: PostDfoAttributeProps = {
        projectId,
        dfoId,
        body: {
          name: DfoAttributeTypes.newMunicipalityOrganizationId,
          value: selectedMunicipalityId,
        },
      }

      const putRequestPayload: PutDfoAttributeProps = postRequestPayload

      try {
        enableLoading()

        if (municipalityOrganizationId) {
          return await updateMunicipalityDfoAttribute(putRequestPayload)
        }

        return await addMunicipalityDfoAttribute(postRequestPayload)
      } finally {
        disableLoading()
      }
    },
    [
      addMunicipalityDfoAttribute,
      updateMunicipalityDfoAttribute,
      dfoId,
      disableLoading,
      enableLoading,
      municipalityOrganizationId,
      projectId,
    ],
  )

  const handleSaveMunicipalityOrganizationViaProperties = useCallback(
    async (selectedMunicipalityId: string) => {
      const requestPayload: PutDfoPropertiesProps = {
        dfoId,
        body: {
          propertyPath: DfoPropertiesTypes.newMunicipalityMemberOrganizationId,
          value: {
            id: selectedMunicipalityId,
          },
        },
      }

      try {
        enableLoading()

        return await updateMunicipalityDfoProperties(requestPayload)
      } finally {
        disableLoading()
      }
    },
    [dfoId, enableLoading, disableLoading, updateMunicipalityDfoProperties],
  )

  const handleChooseSaveMunicipalityOrganizationHandler = useCallback(async () => {
    const selectedMunicipalityId = extractValueFromOption(
      getValues(municipalityDfoFormNames.municipality),
    ) as string

    if (isUndefined(selectedMunicipalityId)) return

    if (systemVersion >= 3) {
      return await handleSaveMunicipalityOrganizationViaProperties(selectedMunicipalityId)
    }

    return await handleSaveMunicipalityOrganizationViaAttributes(selectedMunicipalityId)
  }, [
    handleSaveMunicipalityOrganizationViaAttributes,
    handleSaveMunicipalityOrganizationViaProperties,
    getValues,
    systemVersion,
  ])

  const actions = useMemo(
    () =>
      [
        {
          children: 'Отмена',
          disabled: isLoading,
          view: 'gray',
          onClick: onClose,
        },
        {
          children: 'Сохранить',
          disabled: isLoading || !isValid || isSubmitting,
          loaderProps: {
            loading: isLoading,
            variant: 'lite',
            placement: 'trailing',
          },
          onClick: handleChooseSaveMunicipalityOrganizationHandler,
        },
      ] as ButtonProps[],
    [handleChooseSaveMunicipalityOrganizationHandler, isLoading, isSubmitting, isValid, onClose],
  )

  return (
    <Modal.Action
      hideCloseIcon={isLoading}
      disableOnClose={isLoading}
      isOpen={isOpen}
      simpleModalContainerClassName={styles.modal__container}
      actionModalContentClassName={styles['modal__content-action']}
      simpleModalHeaderClassName={styles.modal__header}
      simpleModalTitleClassName={styles['modal__header-title']}
      title="Укажите муниципальное образование"
      actions={actions}
      onClose={onClose}
    >
      <Form>
        <ControlledSingleSelect
          withContextSearch
          defaultOptionsSelected={defaultSelectedMunicipalityOrganization}
          options={currentMunicipalityOptions}
          optionsContainer={{
            onScroll: onContentScroll,
          }}
          popoverProps={{
            zIndex: 51,
          }}
          inputProps={{
            fixWidth: true,
            label: 'Муниципальное образование',
          }}
          controllerProps={{
            control,
            name: municipalityDfoFormNames.municipality,
            rules: {
              required: defaultRHFValidation.required,
            },
          }}
          optionsProps={{
            isLoading: isMunicipalityLoading,
            tailNode: (
              <MunicipalityOptionsTailNode
                municipalityOptionsLength={preparedMunicipalityOption.length}
              />
            ),
          }}
          onChangeSearchValue={onChangeSearchValue}
        />
      </Form>
    </Modal.Action>
  )
}

export default memo(AddMunicipalityDfoModal)
