import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementOnChangingCharacteristicsOfObjectsFormValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionAAgreementOnChangingCharacteristicsOfObjectsValidationMap: FormValuesValidationSection<
  keyof AAgreementOnChangingCharacteristicsOfObjectsFormValues['4'],
  AAgreementOnChangingCharacteristicsOfObjectsFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionAAgreementOnChangingCharacteristicsOfObjectsValidationMap }
