import React, { DetailedHTMLProps, FC, HTMLAttributes, memo, ReactNode } from 'react'
import { ReactSVG } from 'react-svg'

import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './AddDocumentContainer.module.scss'

interface AddDocumentContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon: string

  action: ButtonProps

  title?: string
  subtitle?: ReactNode
}

const AddDocumentContainer: FC<AddDocumentContainerProps> = ({
  title,
  icon,
  action,
  subtitle,
  children,
  ...divProps
}) => {
  return (
    <div {...divProps} className={cn(styles.container, divProps.className)}>
      <div className={styles.container__content}>
        <div className={styles['container__content-icon']}>
          <ReactSVG src={icon} />
        </div>
        <div className={styles['container__content-info']}>
          {children ?? (
            <>
              {title && <Typography.Body variant={'bodyLMedium'}>{title}</Typography.Body>}
              {subtitle && (
                <div className={styles['container__content-subtitle']}>
                  {subtitle && (
                    <Typography.Body
                      as={'span'}
                      variant={'bodyMRegular'}
                      color={'on-surface-secondary'}
                    >
                      {subtitle}
                    </Typography.Body>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        <Button
          {...action}
          className={cn(styles['container__content-action'], action.className)}
          view={'gray'}
        >
          {action.children}
        </Button>
      </div>
    </div>
  )
}

export default memo(AddDocumentContainer)
