import React, { FC, memo, useCallback, useMemo } from 'react'

import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import { Tooltip, TooltipProps } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import { ManualControlProps, TooltipClassNames } from '@components/PreparedTooltips/types'
import ModalCloseIcon from '@icons/ModalCloseIcon.svg'
import cn from 'classnames'

import styles from './FilterTooltip.module.scss'

interface TooltipContentProps {
  containerClassName?: string
  headlineClassName?: string
  filtersContentClassName?: string
  actionsClassName?: string
}

interface FilterTooltipProps {
  manualControl: ManualControlProps
  filterContent: TooltipProps['content']
  children: TooltipProps['children']
  onApplyFilters: VoidFunction
  onResetFilters: VoidFunction
  tooltipProps?: TooltipClassNames & Pick<TooltipProps, 'zIndex' | 'offset'>
  tooltipContentProps?: TooltipContentProps
  onClose?: VoidFunction
}

const FilterTooltip: FC<FilterTooltipProps> = ({
  manualControl,
  filterContent,
  onApplyFilters,
  onResetFilters,
  children,
  tooltipProps,
  tooltipContentProps,
  onClose,
}) => {
  const { tooltipIsOpen, handleCloseTooltip } = manualControl

  const {
    offset,
    zIndex,
    targetClassName,
    popoverClassName,
    contentClassName,
    arrowClassName,
    popperClassName,
  } = tooltipProps ?? {}

  const { containerClassName, headlineClassName, filtersContentClassName, actionsClassName } =
    tooltipContentProps ?? {}

  const handleManualCloseTooltip = useCallback(() => {
    handleCloseTooltip()
    onClose?.()
  }, [handleCloseTooltip, onClose])

  const handleLocalApplyFilter = useCallback(() => {
    onApplyFilters()
    handleCloseTooltip()
  }, [onApplyFilters, handleCloseTooltip])

  const tooltipContent = useMemo(
    () => (
      <div className={cn(styles.filters, containerClassName)}>
        <header className={cn(styles.filters__headline, headlineClassName)}>
          <Typography.Headline variant={'headlineH3'} className={styles['filters__headline-title']}>
            Фильтры
          </Typography.Headline>
          <IconButton
            className={styles.filters__closeIcon}
            geometry={'square'}
            view={'basic'}
            size={'m'}
            dataTestId="FilterTooltip-closeFilters-iconButton"
            icon={{
              src: ModalCloseIcon,
              noCurrentColorSvgFill: true,
              size: 'initial',
            }}
            onClick={handleManualCloseTooltip}
          />
        </header>
        <div className={cn(styles.filters__content, filtersContentClassName)}>{filterContent}</div>
        <div className={cn(styles.filters__actions, actionsClassName)}>
          <Button
            view={'plain'}
            dataTestId="FilterTooltip-clearAllFilters-button"
            onClick={onResetFilters}
          >
            <Typography.Button color={'text-accent-brand'} variant={'buttonMMedium'}>
              Очистить всё
            </Typography.Button>
          </Button>
          <div className={styles['filters__actions-group']}>
            <Button
              view={'gray'}
              dataTestId="FilterTooltip-cancel-button"
              onClick={handleManualCloseTooltip}
            >
              Отмена
            </Button>
            <Button dataTestId="FilterTooltip-applyFilters-button" onClick={handleLocalApplyFilter}>
              Применить
            </Button>
          </div>
        </div>
      </div>
    ),
    [
      containerClassName,
      headlineClassName,
      handleManualCloseTooltip,
      filtersContentClassName,
      filterContent,
      actionsClassName,
      onResetFilters,
      handleLocalApplyFilter,
    ],
  )

  return (
    <Tooltip
      trigger={'click'}
      open={tooltipIsOpen}
      position={'bottom-end'}
      content={tooltipContent}
      contentClassName={cn(styles.filters__tooltipContent, contentClassName)}
      popoverClassName={cn(styles.filters__tooltipPopover, popoverClassName)}
      arrowClassName={cn(styles.filters__tooltipArrow, arrowClassName)}
      targetClassName={cn(styles.filters__tooltipTarget, targetClassName)}
      popperClassName={cn(styles.filters__tooltipPopper, popperClassName)}
      offset={offset ?? [0, 10]}
      zIndex={zIndex}
      onClose={handleManualCloseTooltip}
    >
      {children}
    </Tooltip>
  )
}

export default memo(FilterTooltip)
