import { RolesTypes } from '@constants/types'
import { AuditListPagination, AuditListProps } from '@context/APIContext/hooks/useOperatorApi'
import { ORGANIZATION_ROLE_OPTIONS_NEW_DESIGN } from '@routes/Users/ParticipantsTable/constants'

interface TableFormField {
  dateFrom: {
    date: string
    time: string
  }
  dateTo: {
    date: string
    time: string
  }
  userId: string
  actionType: string
  organizationRole: string
  searchString: string
}

const formNames = {
  dateFrom: {
    index: 'dateFrom',
    date: 'dateFrom.date',
    time: 'dateFrom.time',
  },
  dateTo: {
    index: 'dateTo',
    date: 'dateTo.date',
    time: 'dateTo.time',
  },
  userId: 'userId',
  actionType: 'actionType',
  organizationRole: 'organizationRole',
  searchString: 'searchString',
} as const

const formState = {
  dateFrom: {
    date: '',
    time: '',
  },
  dateTo: {
    date: '',
    time: '',
  },
  userId: '',
  actionType: '',
  organizationRole: '',
  searchString: '',
}

const initialTableState: AuditListPagination = {
  pageIndex: 0,
  pageSize: 15,
} as const

const initialFilterState: AuditListProps = {
  from: '',
  to: '',
} as const

const ROLE_OPTIONS = [
  ...ORGANIZATION_ROLE_OPTIONS_NEW_DESIGN,
  {
    displayValue: 'Оператор',
    value: RolesTypes.OPERATOR,
  },
]

export type { TableFormField }
export { formNames, formState, initialFilterState, initialTableState, ROLE_OPTIONS }
