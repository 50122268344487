import React from 'react'

import Caption from '@components/Caption'
import Section from '@components/Layout/Section'
import Typography from '@components/Typography'
import CaseIcon from '@icons/case.svg'
import GerbIcon from '@icons/gerb.svg'
import Spiral from '@images/Spiral.png'

import styles from './ServiceInfoSection.module.scss'
const ServiceInfoSection = () => {
  return (
    <Section containerClassName={styles.infoSection}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={Spiral} alt="spiral" />
        </div>
        <div className={styles.info}>
          <div className={styles.infoText}>
            <Typography variant="h4" className={styles.serviceInfo}>
              Сервис обеспечивает <br />
              электронный документооборот между&nbsp;
              <span className={styles.greenGradient}>бизнесом</span>{' '}
              <span>
                и&nbsp;<span className={styles.blueGradient}>государственными органами</span>
              </span>
              <br />в вопросах поощрений инвестиций
            </Typography>
            <div className={styles.captionContainer}>
              <Caption icon={CaseIcon}>Для организаций, реализующих инвестиционные проекты</Caption>
              <Caption icon={GerbIcon}>
                Для региональных и федеральных органов исполнительной власти, выступающих сторонами
                соглашения
              </Caption>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ServiceInfoSection
