import { createContext, FC, useContext, useState } from 'react'

interface VersionState {
  version: string
  id: string
  versionDateTime: string
  versionNumber: number
}

interface IVersionContext extends VersionState {
  set(newState: VersionState): void
  reset: VoidFunction
}

const versionStateInitialValue: VersionState = {
  version: '',
  id: '',
  versionDateTime: '',
  versionNumber: 0,
}

const versionControllerInitialValue = {
  ...versionStateInitialValue,
  reset: () => null,
  set: () => null,
}

const VersionController = createContext<IVersionContext>(versionControllerInitialValue)

export const useVersionController = () => useContext(VersionController)

export const VersionControllerProvider: FC = ({ children }) => {
  const [versionState, setVersionState] = useState(versionStateInitialValue)

  const handleResetVersionState = () => setVersionState(versionStateInitialValue)

  return (
    <VersionController.Provider
      value={{ ...versionState, set: setVersionState, reset: handleResetVersionState }}
    >
      {children}
    </VersionController.Provider>
  )
}
