import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectsFormValues,
  IConstructionExploitation,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const eExploitationFormItemAAgreementOnCreationOtherEstateObjectsValidationMap: FormValuesValidationSection<
  keyof IConstructionExploitation,
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  objectName: {
    required: defaultRHFValidation.required,
  },
  objectCommissioningDate: {
    required: defaultRHFValidation.required,
  },
  objectPermissionCommissionNumber: {
    required: defaultRHFValidation.required,
  },
}

export { eExploitationFormItemAAgreementOnCreationOtherEstateObjectsValidationMap }
