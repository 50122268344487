import { usePopupManager } from 'react-popup-manager'

import DeleteFolderModal, {
  DeleteFolderModalProps,
} from '@components/NewDesignedModals/DeleteFolderModal'

const useDeleteFolderModal = () => {
  const popupManager = usePopupManager()

  const handleOpenDeleteFolderModal = ({
    header,
    description,
    onDelete,
  }: DeleteFolderModalProps) => {
    popupManager.open(DeleteFolderModal, {
      header,
      description,
      onDelete,
      onClose: () => null,
    })
  }

  return {
    handleOpenDeleteFolderModal,
  }
}

export { useDeleteFolderModal }
