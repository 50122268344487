import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NoticeOfProjectRejectConcludeSZPKLayout from './Layout'
import NoticeOfProjectRejectConcludeSZPKModalManager from './Manager'
import NoticeOfProjectRejectConcludeSZPKMenu from './Menu'
import NoticeOfProjectRejectConcludeSZPKStatementForm from './Statement'

interface NoticeOfProjectRejectConcludeSZPKFC extends FC {
  Layout: typeof NoticeOfProjectRejectConcludeSZPKLayout
  Menu: typeof NoticeOfProjectRejectConcludeSZPKMenu
  StatementForm: typeof NoticeOfProjectRejectConcludeSZPKStatementForm
  Manager: typeof NoticeOfProjectRejectConcludeSZPKModalManager
}

const useNoticeOfProjectRejectConcludeSZPKControl = () => {
  const popupManager = usePopupManager()

  const openNoticeOfProjectRejectConcludeSZPKModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(NoticeOfProjectRejectConcludeSZPKLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openNoticeOfProjectRejectConcludeSZPKModal }
}

const NoticeOfProjectRejectConcludeSZPK: NoticeOfProjectRejectConcludeSZPKFC = () => null

NoticeOfProjectRejectConcludeSZPK.Menu = NoticeOfProjectRejectConcludeSZPKMenu
NoticeOfProjectRejectConcludeSZPK.Layout = NoticeOfProjectRejectConcludeSZPKLayout
NoticeOfProjectRejectConcludeSZPK.StatementForm = NoticeOfProjectRejectConcludeSZPKStatementForm
NoticeOfProjectRejectConcludeSZPK.Manager = NoticeOfProjectRejectConcludeSZPKModalManager

export { useNoticeOfProjectRejectConcludeSZPKControl }
export default NoticeOfProjectRejectConcludeSZPK
