import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import Error500Fallback from '@components/DataFallback/500Error'
import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import Loader from '@components/Loader'
import Typography from '@components/NewDesign/Typography'
import { ORGANIZATION_ROLES } from '@components/OrganizationInfo/constants'
import { OrganizationInfoHelpersService } from '@components/OrganizationInfo/helpers'
import { OrganizationUserItem } from '@components/OrganizationInfo/Users/Item'
import Col from '@components/ReactBootstrap/Col'
import Stack from '@components/ReactBootstrap/Stack'
import { isLoginByLP } from '@constants/system'
import { getUserRole } from '@context/AuthContext/workers/rolesWorkers'
import { findOne } from '@helpers/commonHelpers'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { useOrganizationUsers } from '@hooks/new/swr/useOrganizationUsers'
import { useOrganizationVerify } from '@hooks/new/swr/useOrganizationVerify'
import cn from 'classnames'
import { unstable_serialize, useSWRConfig } from 'swr'

import styles from './Users.module.scss'

const { mainUserToFirstPositionCompare } = OrganizationInfoHelpersService

type OrganizationUsers = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const OrganizationUsers: FC<OrganizationUsers> = ({ ...divProps }) => {
  const { cache } = useSWRConfig()

  const { roles } = getUserRole?.()

  const {
    organizationUsers,
    error: organizationUsersError,
    isLoadingOrganizationUsers,
    mutate,
  } = useOrganizationUsers({
    key: { _key: 'organizationUsers' },
  })

  const { mutate: revalidateOrganizationVerify } = useOrganizationVerify({
    key: {
      _key: 'organizationVerify',
    },
    config: {
      isPaused: () => isLoginByLP,
    },
  })

  const isAuthorizedRole = findOne(roles, ORGANIZATION_ROLES)

  const isNotAuthorizedPersons: boolean = useMemo(() => {
    if (!organizationUsers || !organizationUsers.length) return false

    return organizationUsers
      .filter(({ isEnabled }) => isEnabled)
      .every(({ isAuthorizedPerson }) => !isAuthorizedPerson)
  }, [organizationUsers])

  const preparedOrganizationUsers = useMemo(() => {
    return organizationUsers?.sort(mainUserToFirstPositionCompare)
  }, [organizationUsers])

  const handleSave = useCallback(async () => {
    try {
      await mutate()
      await revalidateOrganizationVerify()
    } catch (error) {
      throw error
    }
  }, [mutate, revalidateOrganizationVerify])

  useEffect(() => {
    return () => {
      cache.delete(
        unstable_serialize({
          _key: 'organizationUsers',
        }),
      )
    }
  }, [])

  return (
    <div {...divProps} className={cn(styles.organizationUsers, divProps.className)}>
      <Error500Fallback
        error={isIntervalServerError(organizationUsersError?.response?.status)}
        title={'Не удалось загрузить пользователей'}
      >
        <Stack direction="horizontal" gap={0} className="pl-3 pr-4">
          <Col xs={6}>
            <Typography.Headline variant={'headlineH3'}>Пользователи</Typography.Headline>
          </Col>
          {isAuthorizedRole && isNotAuthorizedPersons && (
            <Col xs={6}>
              <Typography.Body
                variant={'bodyMMedium'}
                color="text-accent-orange-secondary"
                className={styles.organizationUsers__text}
              >
                Укажите как минимум одного пользователя в качестве лица, уполномоченного действовать
                от имени организации
              </Typography.Body>
            </Col>
          )}
        </Stack>

        <Loader loading={isLoadingOrganizationUsers}>
          <div className={styles.organizationUsers__items}>
            {!organizationUsersError &&
              !isLoadingOrganizationUsers &&
              !organizationUsers?.length && <NoDataPlaceholder />}
            {preparedOrganizationUsers?.map((userProps) => (
              <OrganizationUserItem key={userProps.id} user={userProps} onSave={handleSave} />
            ))}
          </div>
        </Loader>
      </Error500Fallback>
    </div>
  )
}

export default memo(OrganizationUsers)
