import { useMemo } from 'react'

import { ElementOrElementsToObserve } from './types'
import { useObserver } from './useObserver'

type UseResizeObserverProps = {
  elementsToObserve: ElementOrElementsToObserve
  resizeListener: ResizeObserverCallback
  conditionToSkip?: boolean
}

export const useResizeObserver = ({
  elementsToObserve,
  resizeListener,
  conditionToSkip,
}: UseResizeObserverProps) => {
  const resizeObserver = useMemo(
    () => (window.ResizeObserver ? new window.ResizeObserver(resizeListener) : null),
    [resizeListener],
  )

  useObserver({ observerInstance: resizeObserver, elementsToObserve, conditionToSkip })
}
