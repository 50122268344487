import { fileAlignmnet, fileItemReducerActionMap } from './const'
import {
  FileItemAction,
  FileItemReducerActionCreators,
  FileReducerState,
  OrganizationLetterFilesReducerState,
} from './types'

const initialFileState: FileReducerState = {
  type: 'first_header',
  alignment: fileAlignmnet.center,
  fileExtension: '',
  fileName: '',
  fileSize: 0,
  fileId: '',
  isLoading: false,
  isNew: true,
}

export const initialFilesState: OrganizationLetterFilesReducerState = {
  first_header: initialFileState,
  first_footer: { ...initialFileState, type: 'first_footer' },
  general_header: { ...initialFileState, type: 'general_header' },
  general_footer: { ...initialFileState, type: 'general_footer' },
}

export const fileItemsReducer = (
  state: OrganizationLetterFilesReducerState,
  action: FileItemAction,
): OrganizationLetterFilesReducerState => {
  const {
    type,
    payload: { fileItemName, pData },
  } = action
  const currentFileItem = state[fileItemName]

  switch (type) {
    case fileItemReducerActionMap.SETUP_LOADING:
      return {
        ...state,
        [fileItemName]: {
          ...currentFileItem,
          isLoading: pData,
        },
      }

    case fileItemReducerActionMap.SETUP_FILE:
      if (!!pData && typeof pData !== 'boolean' && 'fileId' in pData) {
        const { fileId, fileSize, fileExtension, fileName } = pData
        return {
          ...state,
          [fileItemName]: {
            ...currentFileItem,
            fileId,
            fileSize: fileSize || 0,
            isLoading: false,
            isNew: true,
            type: fileItemName,
            fileExtension,
            fileName,
          },
        }
      } else return { ...state }

    case fileItemReducerActionMap.SETUP_ALIGNMENT:
      if (!!pData && typeof pData !== 'boolean' && 'alignment' in pData) {
        const { alignment } = pData
        return {
          ...state,
          [fileItemName]: {
            ...currentFileItem,
            alignment,
          },
        }
      } else return { ...state }

    case fileItemReducerActionMap.RESET_FILE:
      return {
        ...state,
        [fileItemName]: initialFileState,
      }

    default:
      return state
  }
}

export const fileItemCreators: FileItemReducerActionCreators = {
  setLoading: (payload) => ({ type: fileItemReducerActionMap.SETUP_LOADING, payload }),
  setFile: (payload) => ({ type: fileItemReducerActionMap.SETUP_FILE, payload }),
  resetFile: (payload) => ({ type: fileItemReducerActionMap.RESET_FILE, payload }),
  setAlignment: (payload) => ({ type: fileItemReducerActionMap.SETUP_ALIGNMENT, payload }),
}
