import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { usePetitionTransferRightManager } from '@components/Forms/PetitionTransferRightForm/Manager'
import { PetitionTransferRightFormValues } from '@components/Forms/PetitionTransferRightForm/types'

import { mapOfPetitionTransferRightMenu } from '../const'
import PetitionTransferRightForms from '../Forms'

const mapOfPetitionTransferRightForms: FormItemProps = {
  '1': {
    node: <PetitionTransferRightForms.First />,
  },
  '2': {
    node: <PetitionTransferRightForms.Second />,
  },
  '3': {
    node: <PetitionTransferRightForms.Third />,
  },
} as const

const PetitionTransferRightForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<PetitionTransferRightFormValues>()

  const {
    state: { formIsLoading },
  } = usePetitionTransferRightManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfPetitionTransferRightMenu}
      mapOfForms={mapOfPetitionTransferRightForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(PetitionTransferRightForm)
