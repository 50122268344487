import React, { memo } from 'react'

import { Skeleton } from '@components/Skeleton/Skeleton'

import styles from './NavigationLoader.module.scss'

export const ROW_SKELETON_COUNT = 5

const NavigationLoader = () => {
  return (
    <div className={styles.navigationLoader}>
      {Array(ROW_SKELETON_COUNT)
        .fill(0)
        .map((_, index) => (
          <Skeleton
            animate
            visible
            key={index}
            dataTestId={`NavigationLoader-loader-${index}`}
            className={styles.navigationLoader__item}
          />
        ))}
    </div>
  )
}

export default memo(NavigationLoader)
