import { FC, memo, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { AuthorizedOrganizations } from '@components/AuthorizedOrganizations/Manager/types'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import { ControlledSingleSelect } from '@components/NewDesign/Select'
import Sidebar from '@components/NewDesign/Sidebar'
import Typography from '@components/NewDesign/Typography'
import { useChangeINNModal } from '@components/NewDesignedModals/ChangeINNModal'
import { defaultRHFValidation } from '@constants/validations'
import {
  IAuthorizedOrganizationItem,
  useAuthorizedOrganizationsApi,
} from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { useRegions } from '@hooks/new/swr/useRegions'
import { useBooleanState } from '@hooks/useBooleanState'

import styles from './Item.module.scss'

interface ItemAuthorizedOrganizationsProps {
  organization?: IAuthorizedOrganizationItem

  onReloadGrid?: VoidFunction
}

type ItemOMSUFormValues = {
  regionCode: string
  municipalityName: string
  oktmo: string
  organizationName: string
  inn: string
  link: string
}

export type ItemAuthorizedOrganizationsSidebarProps = PopupProps & ItemAuthorizedOrganizationsProps

const useItemAuthorizedOrganizationsOMSUSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenOMSUItemAuthorizedOrganizationsSidebar = (
    props: ItemAuthorizedOrganizationsProps,
  ) => {
    popupManager.open(ItemAuthorizedOrganizationsSidebar, props)
  }

  return {
    handleOpenOMSUItemAuthorizedOrganizationsSidebar,
  }
}

const ItemAuthorizedOrganizationsSidebar: FC<ItemAuthorizedOrganizationsSidebarProps> = ({
  isOpen,
  organization,
  onReloadGrid,
  onClose,
}) => {
  const { inn, organizationName, link, oktmo, municipalityName, region } = organization ?? {}

  const { createAuthorizedOrganization, updateAuthorizedOrganization } =
    useAuthorizedOrganizationsApi()

  const { regions } = useRegions({
    key: { _key: 'projectRegions' },
  })

  const regionsToSelect = useMemo(() => {
    if (!regions) return []

    return Object.entries(regions).map(([id, value]) => ({ displayValue: value, value: id }))
  }, [regions])

  const {
    booleanState: isRequestLoading,
    setBooleanStateToFalse: disableLoading,
    setBooleanStateToTrue: enableLoading,
  } = useBooleanState()

  const { handleOpenChangeINNModal } = useChangeINNModal()

  const { control, handleSubmit, getValues } = useForm<ItemOMSUFormValues>({
    defaultValues: {
      regionCode: region?.toString() ?? '',
      municipalityName: municipalityName ?? '',
      oktmo: oktmo ?? '',
      organizationName: organizationName ?? '',
      inn: inn ?? '',
      link: link ?? '',
    },
  })

  const preparedOnCloseHandler = () => {
    onClose?.()
  }

  const handleChangeSuccess = () => {
    onReloadGrid?.()
    preparedOnCloseHandler()
  }

  const handleCheckOnChangeProps = (props: Omit<ItemOMSUFormValues, 'regionCode'>) => {
    return Object.entries(props).reduce((prevValue, [key, value]) => {
      return Object.assign(prevValue, {
        [key]: getValues()[key] !== organization?.[key] ? value : undefined,
      })
    }, {})
  }

  const handleUpdateOMSU = async () => {
    if (!organization) return

    const { regionCode, ...formValues } = getValues()

    if (inn !== formValues.inn) {
      return handleOpenChangeINNModal({
        ...handleCheckOnChangeProps(formValues),
        headerText: 'Изменить ИНН?',
        text: `Организация потеряет доступ в систему и больше не сможет участвовать в рассмотрении соглашений.
    Все полномочия получит новый орган местного самоуправления`,
        inn: inn ?? '',
        newInn: formValues.inn,
        handleChangeSuccess,
      })
    }

    enableLoading()

    try {
      await updateAuthorizedOrganization({
        ...formValues,
        inn: organization.inn,
      })

      onReloadGrid?.()

      preparedOnCloseHandler()
    } finally {
      disableLoading()
    }
  }

  const handleCreateOMSU = async () => {
    const formValues = getValues()

    enableLoading()

    try {
      await createAuthorizedOrganization({
        ...formValues,
        type: AuthorizedOrganizations.OMSU,
      })

      onReloadGrid?.()

      preparedOnCloseHandler()
    } finally {
      disableLoading()
    }
  }

  const currentActions = useMemo(() => {
    const loadingProps = {
      loaderProps: {
        loading: isRequestLoading,
        variant: 'lite',
        placement: 'trailing',
      },
    } as const

    if (organization) {
      return [
        {
          children: 'Сохранить',
          onClick: handleSubmit(handleUpdateOMSU),
          disabled: isRequestLoading,
          loaderProps: loadingProps.loaderProps,
        },
      ] as ButtonProps[]
    }

    return [
      {
        children: 'Отмена',
        view: 'gray',
        onClick: onClose,
      },
      {
        children: 'Добавить',
        onClick: handleSubmit(handleCreateOMSU),
        loaderProps: loadingProps.loaderProps,
      },
    ] as ButtonProps[]
  }, [isRequestLoading, organization, onClose, handleSubmit, handleUpdateOMSU])

  return (
    <Sidebar
      isOpen={isOpen}
      title={organization ? 'Просмотр ОМСУ' : 'Добавление ОМСУ'}
      actions={currentActions}
      onClose={preparedOnCloseHandler}
    >
      <div className={styles.sidebar__wrapper}>
        <div className={styles.municipality}>
          <Typography.Body
            variant={'bodyLMedium'}
            color={'text-base-primary'}
            className={styles.header}
          >
            Муниципальное образование
          </Typography.Body>
          <div className={styles.field}>
            <ControlledSingleSelect
              withContextSearch
              disabled={!!organization}
              options={regionsToSelect}
              popoverProps={{
                zIndex: 51,
              }}
              controllerProps={{
                control,
                name: 'regionCode',
                rules: {
                  required: defaultRHFValidation.required,
                },
              }}
              inputProps={{
                label: 'Регион',
                fixWidth: true,
                clear: true,
              }}
            />
          </div>
          <div className={styles.field}>
            <ControlledInput
              control={control}
              name={'municipalityName'}
              inputProps={{
                fixWidth: true,
                clear: true,
                view: 'secondary',
                size: 'xl',
                label: 'Муниципальное образование',
                maxLength: 1000,
              }}
              rules={{
                required: defaultRHFValidation.required,
              }}
            />
          </div>
          <div className={styles.field}>
            <ControlledMaskInput
              control={control}
              name={'oktmo'}
              inputProps={{
                fixWidth: true,
                clear: true,
                view: 'secondary',
                size: 'xl',
                label: 'ОКТМО',
                mask: '99999999999',
                maskPlaceholder: '',
              }}
              rules={{
                required: defaultRHFValidation.required,
                pattern: {
                  value: /^(\d{8}|\d{11})$/,
                  message: 'ОКТМО должен состоять из 8 или 11 цифр',
                },
              }}
            />
          </div>
        </div>
        <div className={styles.organization}>
          <Typography.Body
            variant={'bodyLMedium'}
            color={'text-base-primary'}
            className={styles.header}
          >
            Орган местного самоуправления
          </Typography.Body>
          <div className={styles.field}>
            <ControlledInput
              control={control}
              name={'organizationName'}
              inputProps={{
                fixWidth: true,
                clear: true,
                view: 'secondary',
                size: 'xl',
                label: 'Наименование',
                maxLength: 1000,
              }}
              rules={{
                required: defaultRHFValidation.required,
              }}
            />
          </div>
          <div className={styles.field}>
            <ControlledMaskInput
              control={control}
              name={'inn'}
              inputProps={{
                clear: true,
                fixWidth: true,
                view: 'secondary',
                size: 'xl',
                mask: '9999999999',
                maskPlaceholder: '',
                label: 'ИНН',
                caption: inn
                  ? 'если изменить ИНН, действующий орган власти потеряет доступ в систему и больше не сможет участвовать в рассмотрении соглашений'
                  : '',
              }}
              rules={{
                required: defaultRHFValidation.required,
                pattern: {
                  value: /^(\d{10})$/,
                  message: 'ИНН должен состоять из 10 цифр',
                },
              }}
            />
          </div>
          <div className={styles.field}>
            <ControlledInput
              control={control}
              name={'link'}
              inputProps={{
                fixWidth: true,
                clear: true,
                view: 'secondary',
                size: 'xl',
                label: 'Ссылка на официальный сайт',
                maxLength: 500,
              }}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  )
}

export default memo(ItemAuthorizedOrganizationsSidebar)
export { useItemAuthorizedOrganizationsOMSUSidebar }
