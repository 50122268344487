import React from 'react'
import { useFormContext } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { summaryReportOfMonitoringResultsBlockValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/const'
import SummaryReportOfMonitoringResultsRegionFederalReport from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/5/RegionFederalReport'
import { SummaryReportOfMonitoringResultsFederalRegionsPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/5/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const Fifth = () => {
  const formInstance = useFormContext<SummaryReportOfMonitoringResultsFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const { fields: regionsFederalReport } = useFieldArraySubscribableControl<
    SummaryReportOfMonitoringResultsFormValues,
    SummaryReportOfMonitoringResultsFederalRegionsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: summaryReportOfMonitoringResultsBlockValues['5'].regionsFederalReport,
    keyName: 'keyNameId',
    watcher: SummaryReportOfMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: summaryReportOfMonitoringResultsBlockValues['5'].regionsFederalReport,
          })}
        >
          <FlipperList list={regionsFederalReport}>
            {regionsFederalReport.map((regionFederal, index) => {
              const formName =
                `${summaryReportOfMonitoringResultsBlockValues['5'].regionsFederalReport}.${index}` as const
              return (
                <SummaryReportOfMonitoringResultsRegionFederalReport
                  id={regionFederal.id}
                  key={regionFederal.id}
                  formInstance={formInstance}
                  name={formName}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
      </Stack>
    </Container>
  )
}

export default Fifth
