import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues['3']['municipalities'][number],
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  municipalityFullNameGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  municipalityHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { thirdSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
