import * as React from 'react'

import cn from 'classnames'

import createUtilityClassName, { ResponsiveUtilityValue } from '../createUtilityClasses'
import { BsPrefixProps, BsPrefixRefForwardingComponent } from '../helpers'
import {
  useBootstrapBreakpoints,
  useBootstrapMinBreakpoint,
  useBootstrapPrefix,
} from '../ThemeProvider'
import { GapValue } from '../types'

export type StackDirection = 'horizontal' | 'vertical'

export interface StackProps extends BsPrefixProps, React.HTMLAttributes<HTMLElement> {
  direction?: StackDirection
  gap?: ResponsiveUtilityValue<GapValue>
}

const Stack: BsPrefixRefForwardingComponent<'span', StackProps> = React.forwardRef<
  HTMLElement,
  StackProps
>(({ as: Component = 'div', bsPrefix, className, direction, gap, ...props }, ref) => {
  bsPrefix = useBootstrapPrefix(bsPrefix, direction === 'horizontal' ? 'hstack' : 'vstack')
  const breakpoints = useBootstrapBreakpoints()
  const minBreakpoint = useBootstrapMinBreakpoint()

  return (
    <Component
      {...props}
      ref={ref}
      className={cn(
        className,
        bsPrefix,
        ...createUtilityClassName(
          {
            gap,
          },
          breakpoints,
          minBreakpoint,
        ),
      )}
    />
  )
})

Stack.displayName = 'Stack'

export default Stack
