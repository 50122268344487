import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { IAttribute } from '@services/Attribute/Attribute.entity'
import useSWRImmutable from 'swr/immutable'

const useProjectAttributes = ({ key, config }: SWRHookProps<IAttribute[] | undefined>) => {
  const {
    projectsApi: { getProjectAttributesById },
  } = useAPIContext()

  const {
    data: projectAttributes,
    error,
    mutate,
    isValidating: isLoadingProjectAttributes,
  } = useSWRImmutable<IAttribute[] | undefined>(
    key,
    ({ projectId }) => getProjectAttributesById(projectId),
    config,
  )

  return {
    projectAttributes,
    error,
    mutate,
    isLoadingProjectAttributes,
  }
}

export { useProjectAttributes }
