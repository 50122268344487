import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import { parseDateString } from '@components/NewDesign/DateInput/DateInput'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation, standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

const compensationFactValidationMap: FormValuesValidationSection<
  keyof ParametersApplicationReimbursementExpensesFormValues['3']['compensationsFacts'][number],
  ParametersApplicationReimbursementExpensesFormValues
> = {
  compensationFactYear: {
    required: defaultRHFValidation.required,
    validate: {
      positiveCurrentDate: (value) => {
        if (!isString(value)) return

        const currentYear = DayjsService.dayjsWithFormatToMSK().format(
          objOfDateFormats.yearFormat.yearOnly,
        )

        return (
          (isDateValidForDayjs(value) &&
            DayjsService.dayjs(
              parseDateString(value, objOfDateFormats.yearFormat.yearOnly),
              objOfDateFormats.defaultFormat,
            ) <=
              DayjsService.dayjs(
                parseDateString(currentYear, objOfDateFormats.yearFormat.yearOnly),
                objOfDateFormats.defaultFormat,
              )) ||
          'дата не должна превышать текущую'
        )
      },
    },
  },
  compensationFactFormValue: {
    required: defaultRHFValidation.required,
  },
  compensationFactExpenseGroupValue: {
    required: defaultRHFValidation.required,
  },
  compensationFactSum: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  compensationFactDate: {
    required: defaultRHFValidation.required,
  },
}

export { compensationFactValidationMap }
