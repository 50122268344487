import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'

const { getDefaultRfFieldState, getDefaultMunicipalitiesFieldState, getDefaultInvestorFieldState } =
  FieldStateHelpers

const mapOfAAVolumeOfCapitalInvestmentsMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация, реализующая проект' },
  { id: '5', title: 'Капиталовложения' },
  { id: '6', title: 'Капиталовложения по проекту' },
]

const defaultAAVolumeOfCapitalInvestmentsValues: AAVolumeOfCapitalInvestmentsFormValues = {
  '1': getDefaultRfFieldState(),
  '2': {
    subjects: [
      {
        id: '1',
        subjectFullName: '',
        subjectRegion: '',
        subjectNameGenitive: '',
        subjectHeaderName: null,
        subjectHeaderNameGenitive: '',
        subjectHeaderPosition: '',
        subjectHeaderPositionGenitive: '',
        subjectHeaderReasonGenitive: '',
      },
    ],
  },
  '3': {
    municipalities: getDefaultMunicipalitiesFieldState(),
  },
  '4': getDefaultInvestorFieldState(),
  '5': {
    capitalSteps: [],
  },
  '6': {
    investorCapital: '',
    investorCapitalCommited: '',
    totalCapital: '',
    totalCapitalCommited: '',
    remainsInvestorCapital: '',
    remainsInvestorCapitalCommited: '',
    madeInvestorCapitalDate: '',
    madeInvestorCapitalDateCommited: '',
  },
  additionalFields: {
    isFederal: true,
    isPaper: false,
    initialMunicipalities: [],
  },
}

const aaVolumeOfCapitalInvestmentsBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorFullName: '4.investorFullName',
    investorHeaderName: '4.investorHeaderName',
    investorHeaderNameGenitive: '4.investorHeaderNameGenitive',
    investorHeaderPosition: '4.investorHeaderPosition',
    investorHeaderPositionGenitive: '4.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '4.investorHeaderReasonGenitive',
  },
  '5': {
    capitalSteps: '5.capitalSteps',
  },
  '6': {
    investorCapital: '6.investorCapital',
    investorCapitalCommited: '6.investorCapitalCommited',
    totalCapital: '6.totalCapital',
    totalCapitalCommited: '6.totalCapitalCommited',
    remainsInvestorCapital: '6.remainsInvestorCapital',
    remainsInvestorCapitalCommited: '6.remainsInvestorCapitalCommited',
    madeInvestorCapitalDate: '6.madeInvestorCapitalDate',
    madeInvestorCapitalDateCommited: '6.madeInvestorCapitalDateCommited',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    isPaper: 'additionalFields.isPaper',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
  },
} as const

export {
  aaVolumeOfCapitalInvestmentsBlockValues,
  defaultAAVolumeOfCapitalInvestmentsValues,
  mapOfAAVolumeOfCapitalInvestmentsMenu,
}
