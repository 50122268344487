import React, { FC, memo, useCallback, useMemo } from 'react'

import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import { NpaMunicipalAddonThreeManager } from '@components/Forms/NpaMunicipalForm/watcher'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import useMunicipalityDfoModal from '@components/NewDesignedModals/AddMunicipalityDfoModal/manager'
import { DfoAttributeTypes, DfoPropertiesTypes, DfosStage, RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { isUndefined } from '@helpers/checkTypes'
import { useCurrentOrganizationId } from '@hooks/new/organisation/useCurrentOrganizationId'
import { useOrganizationInfoById } from '@hooks/new/swr/useOrganizationInfoById'
import { useVersions } from '@hooks/new/swr/useVersions'
import IconReload from '@icons/attachment/actions/ReloadAttachmentIcon.svg'
import EditIcon from '@icons/EditIcon.svg'
import PersonAddIcon from '@icons/PersonAddIcon.svg'
import type { IDfoListItem } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './MunicipalAccession.module.scss'

const componentInfo = {
  componentName: 'MunicipalAccession',
  moduleName: 'DocumentsView',
}

const DEFAULT_MUNICIPALITY_TITLE = 'Присоединяющееся муниципальное образование'
const DEFAULT_MUNICIPALITY_ERROR_TEXT = 'Не удалось загрузить данные муниципального образования'

interface MunicipalAccessionProps {
  projectId: string
  dfo: IDfoListItem
}

const MunicipalAccession: FC<MunicipalAccessionProps> = ({ projectId, dfo }) => {
  const { checkingRole } = useAuthContext()

  const { handleOpenAddMunicipalityDfoModal } = useMunicipalityDfoModal()

  const { versions } = useVersions({
    key: {
      projectId,
      dfoId: dfo.id,
      _key: 'versions',
    },
    config: {
      isPaused: () => !projectId || !dfo.id,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo,
      }),
    },
  })

  const {
    currentOrganizationId: currentMunicipalityOrganizationId,
    isLoadingCurrentOrganizationId: isLoadingCurrentMunicipalityOrganizationId,
    revalidateCurrentOrganizationId: revalidateCurrentMunicipalityOrganizationId,
  } = useCurrentOrganizationId({
    projectId,
    dfo,
    dfoAttributeType: DfoAttributeTypes.newMunicipalityOrganizationId,
    dfoPropertyType: DfoPropertiesTypes.newMunicipalityMemberOrganizationId,
    handleLogErrorParams: {
      componentInfo,
    },
  })

  const {
    organization,
    error: organizationError,
    isLoadingOrganization,
    mutate: revalidateOrganizationInfo,
  } = useOrganizationInfoById({
    key: {
      organizationId: currentMunicipalityOrganizationId,
      _key: 'organizationInfo',
    },
    config: {
      isPaused: () => !currentMunicipalityOrganizationId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo,
        additionInfo: {
          organizationId: currentMunicipalityOrganizationId,
        },
      }),
    },
  })

  const isActualDfo = useMemo(() => {
    const actualFirstVersion = versions?.find(
      (version) => version.versionNumber === 1 && version.isActual,
    )

    return dfo.stage === DfosStage.DRAFT && !!actualFirstVersion && dfo.actionAllowed
  }, [versions, dfo.stage, dfo.actionAllowed])

  const isINVESTOR = checkingRole?.(RolesTypes.INVESTOR) || false

  const isShowMunicipalityControlContainer =
    isINVESTOR && !currentMunicipalityOrganizationId && isActualDfo
  const isShowEditMunicipalityButton =
    isINVESTOR && !!currentMunicipalityOrganizationId && isActualDfo

  const organizationText = organization?.municipalityName || ''
  const organizationRenderText = !organizationError
    ? organizationText
    : DEFAULT_MUNICIPALITY_ERROR_TEXT

  const isOrganizationTextLoading =
    isLoadingOrganization || isLoadingCurrentMunicipalityOrganizationId

  const handleRevalidateOrganization = async () => {
    await revalidateOrganizationInfo()
  }

  const handleRevalidateCurrentMunicipalityOrganizationId = useCallback(async () => {
    await revalidateCurrentMunicipalityOrganizationId()

    // Триггерит пересбор дерева НПА, после выбора МО
    NpaMunicipalAddonThreeManager.setStaleStatus(true)
  }, [revalidateCurrentMunicipalityOrganizationId])

  const handleAddMunicipalityOrganization = () => {
    handleOpenAddMunicipalityDfoModal({
      projectId,
      dfo,
      municipalityOrganizationId: currentMunicipalityOrganizationId,
      onSave: handleRevalidateCurrentMunicipalityOrganizationId,
    })
  }

  if (isShowMunicipalityControlContainer) {
    return (
      <ControlContainer
        title="Укажите присоединяющееся муниципальное образование"
        leadingNodeContent={<ControlContainerStatusIcon controlIcon={PersonAddIcon} iconType="" />}
        trailingNodeContent={
          <Button fixWidth view="tined" onClick={handleAddMunicipalityOrganization}>
            Выбрать участника
          </Button>
        }
      />
    )
  }

  return (
    <div className={styles.municipalAccession__edit}>
      <div className={styles['municipalAccession__edit-info']}>
        <Loader loading={isOrganizationTextLoading} variant="lite">
          <Typography.Caption variant="captionSRegular" color="text-base-tertiary">
            {DEFAULT_MUNICIPALITY_TITLE}
          </Typography.Caption>
          <div className={styles['municipalAccession__edit-view']}>
            <Typography.Body variant="bodyMMedium">{organizationRenderText}</Typography.Body>
            {organizationError && (
              <IconButton
                className={styles.municipalAccession__reload}
                size="s"
                icon={{
                  src: IconReload,
                }}
                onClick={handleRevalidateOrganization}
              />
            )}
          </div>
        </Loader>
      </div>
      {isShowEditMunicipalityButton &&
        !isOrganizationTextLoading &&
        isUndefined(organizationError) && (
          <div className={styles['municipalAccession-edit__info-buttonWrapper']}>
            <Button
              size="s"
              geometry="round"
              view="plain"
              leadingIcon={{
                src: EditIcon,
              }}
              onClick={handleAddMunicipalityOrganization}
            >
              Изменить участника
            </Button>
          </div>
        )}
    </div>
  )
}

export default memo(MunicipalAccession)
