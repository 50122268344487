import { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import StatementConsiderationInvestmentAuthorized from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm'
import {
  defaultStatementConsiderationInvestmentAuthorizedFormValues,
  mapOfStatementConsiderationInvestmentAuthorizedMenu,
} from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/const'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'
import { StatementConsiderationInvestmentAuthorizedFieldsControlUpdateWatcher } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/watcher'
import Typography from '@components/NewDesign/Typography'

import StatementConsiderationInvestmentAuthorizedLayoutWrapper from './Wrapper'

const StatementConsiderationInvestmentAuthorizedLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance =
    useFormWithDefaultProps<StatementConsiderationInvestmentAuthorizedFormValues>({
      defaultValues: defaultStatementConsiderationInvestmentAuthorizedFormValues,
    })

  const handleOnClose = () => {
    StatementConsiderationInvestmentAuthorizedFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <StatementConsiderationInvestmentAuthorized.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfStatementConsiderationInvestmentAuthorizedMenu[0].id}
          >
            <StatementConsiderationInvestmentAuthorizedLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Заявление о рассмотрении инвестиционного проекта субъектом РФ
                </Typography.Headline>
                <StatementConsiderationInvestmentAuthorized.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <StatementConsiderationInvestmentAuthorized.Menu className={styles.layout__menu} />
              </div>
            </StatementConsiderationInvestmentAuthorizedLayoutWrapper>
          </FlatMenuManager>
        </StatementConsiderationInvestmentAuthorized.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(StatementConsiderationInvestmentAuthorizedLayout)
