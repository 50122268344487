import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Eleventh = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getCheckboxProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Group
        disableBottomBorder
        groupClassName={'mt-0'}
        title={
          'В течении срока действия стабилизационной оговорки к инвестиционному проекту не будут применяться следующие акты:'
        }
      >
        <Group title={'Субъект РФ'}>
          <Stack direction={'vertical'} gap={3}>
            <Row className={styles.form__checkboxRow}>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['11'].rightDutySubjectWaterResource,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: projectSZPKBlockValues['11'].rightDutySubjectWaterResource,
                      checkBoxProps: {
                        disabled: true,
                        label:
                          'Акты, предполагающие увеличение ставок платы и (или) расширение платежной базы, используемых при определении платы за пользование водными объектами, в течение 3 лет со дня вступления таких актов в силу',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row className={styles.form__checkboxRow}>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['11'].rightDutySubjectForestResource,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: projectSZPKBlockValues['11'].rightDutySubjectForestResource,
                      checkBoxProps: {
                        disabled: true,
                        label:
                          'Акты, предполагающие увеличение ставок платы за единицу объема лесных ресурсов и изменения размера арендной платы, устанавливаемых при использовании лесного участка с изъятием лесных ресурсов, в течение 3 лет со дня вступления таких актов в силу',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Российская Федерация'}>
          <Stack direction={'vertical'} gap={3}>
            <Row className={styles.form__checkboxRow}>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['11'].rightDutyRfWaterResource,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: projectSZPKBlockValues['11'].rightDutyRfWaterResource,
                      checkBoxProps: {
                        disabled: true,
                        label:
                          'Акты, предполагающие увеличение ставок платы и (или) расширение платежной базы, используемых при определении размера платы за негативное воздействие на окружающую среду, платы за пользование водными объектами и экологического сбора, в течение 3 лет со дня вступления таких актов в силу',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row className={styles.form__checkboxRow}>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['11'].rightDutyRfForestResource,
                  })}
                >
                  <ControlledCheckbox
                    {...getCheckboxProps({
                      name: projectSZPKBlockValues['11'].rightDutyRfForestResource,
                      checkBoxProps: {
                        disabled: true,
                        label:
                          'Акты, предполагающие увеличение ставок платы за единицу объема лесных ресурсов, изменения порядка определения и общего размера арендной платы, устанавливаемых при использовании лесного участка с изъятием лесных ресурсов, в течение 3 лет со дня вступления соответствующих актов в силу',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Group>
    </Container>
  )
}

export default Eleventh
