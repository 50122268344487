import React, { memo, VFC } from 'react'

import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './DownloadDocumentTooltipContent.module.scss'

interface DownloadDocumentTooltipContentProps {
  handleDownloadFile: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleDownloadFileWithStamps: (e: React.MouseEvent<HTMLButtonElement>) => void
  additionalButtonsRender?: ButtonProps[]
}

const DownloadDocumentTooltipContent: VFC<DownloadDocumentTooltipContentProps> = ({
  handleDownloadFile,
  handleDownloadFileWithStamps,
  additionalButtonsRender,
}) => {
  const conditionToRenderButtons = additionalButtonsRender && !!additionalButtonsRender.length

  return (
    <div className={styles.tooltip}>
      <Button
        className={styles.tooltip__buttons}
        size={'s'}
        view={'plain'}
        geometry={'square'}
        dataTestId={'DownloadDocumentTooltipContent-download-file-button'}
        onClick={handleDownloadFile}
      >
        <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
          Оригинал документа
        </Typography.Body>
      </Button>
      <Button
        className={styles.tooltip__buttons}
        size={'s'}
        view={'plain'}
        geometry={'square'}
        dataTestId={'DownloadDocumentTooltipContent-download-file-with-stamps-button'}
        onClick={handleDownloadFileWithStamps}
      >
        <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
          Документ со штампом
        </Typography.Body>
      </Button>
      {conditionToRenderButtons &&
        additionalButtonsRender.map(({ children, ...button }) => {
          return (
            <Button {...button} className={cn(styles.tooltip__buttons, button.className)}>
              <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
                {children}
              </Typography.Body>
            </Button>
          )
        })}
    </div>
  )
}

export default memo(DownloadDocumentTooltipContent)
