import React, { cloneElement, FC, isValidElement, useState } from 'react'

import Loader from '@components/Loader'
import cn from 'classnames'

const WithImageLoading: FC = ({ children }) => {
  const [imageIsLoaded, setImageIsLoaded] = useState<boolean>(false)

  const handleImageLoad = () => {
    setImageIsLoaded(true)
  }

  if (!isValidElement(children)) return null

  return (
    <>
      <Loader loading={!imageIsLoaded} />
      <div className={cn({ hidden: !imageIsLoaded })}>
        {cloneElement(children, { ...children.props, onLoad: handleImageLoad })}
      </div>
    </>
  )
}

export default WithImageLoading
