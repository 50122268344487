import { FC, memo, useMemo } from 'react'
import type { PopupProps } from 'react-popup-manager'

import Icon from '@components/Icon/Icon'
import type { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { signModalButtonTexts } from '@constants/texts'
import { isUndefined } from '@helpers/checkTypes'
import errorIcon from '@icons/alert/error_outline.svg'

import styles from './ErrorModal.module.scss'

interface ErrorSignModalProps extends Required<PopupProps> {
  title: string
  onClick: VoidFunction
  bodyText?: string
}

const ErrorSignModal: FC<ErrorSignModalProps> = ({ isOpen, title, bodyText, onClick, onClose }) => {
  const actions = useMemo(() => {
    return [
      {
        children: signModalButtonTexts.failedMessage,
        dataTestId: 'ErrorSignModal-retry-button',
        fixWidth: true,
        onClick,
        className: styles.failedSign,
      },
    ] as ButtonProps[]
  }, [onClick])

  return (
    <Modal.Action
      hideCloseIcon
      isOpen={isOpen}
      title={''}
      dataTestId="ErrorSignModal-errorSign-modal"
      closeButtonDataTestId="ErrorSignModal-errorSign-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      actions={actions}
      onClose={onClose}
    >
      <div className={styles.icon}>
        <Icon src={errorIcon} size={'4xl'} />
      </div>
      <Typography.Headline variant="headlineH3" className={styles.head}>
        {title}
      </Typography.Headline>
      {!isUndefined(bodyText) && (
        <Typography.Body variant="bodyMRegular" align="center">
          {bodyText}
        </Typography.Body>
      )}
    </Modal.Action>
  )
}

export default memo(ErrorSignModal)
