import { useCallback, useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { DFOSStage } from '@context/APIContext/hooks/useDFOSApi'
import { GetVersionData } from '@context/APIContext/types'
import { isString } from '@helpers/checkTypes'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useQueryManager } from '@hooks/useQueryManager'
import { IDfoAttribute } from '@services/Attribute/Attribute.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { unstable_serialize, useSWRConfig } from 'swr'

const REGEXP_TO_EJECT_VALUE = /{([^}]+)}/g
const INDEX_OF_OBJECT_KEY = 3

const useStageLineProcess = (dfoParams?: DFOSStage | null, projectId?: string) => {
  const { cache } = useSWRConfig()

  const { queryUtils } = useQueryManager()
  const dfoId = queryUtils.getQuery('dfoId')

  const {
    dfosApi: { getDfoAttributes: getDfoAttributesFromAPI, getVersions },
  } = useAPIContext()

  const { projectDfos } = useProjectDfos({
    key: {
      projectId: projectId,
      _key: 'projectDfos',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          dfoParams,
        },
        componentInfo: {
          componentName: 'useStageLineProcess',
          moduleName: 'useActionBannerStatus',
        },
      }),
    },
  })

  const mapOfDfoIdsByType = useMemo(() => {
    return projectDfos?.reduce((prevValue, currentValue) => {
      if (currentValue.stage === 'REVOKE') return prevValue

      return {
        ...prevValue,
        [currentValue.type]: currentValue.id,
      }
    }, {})
  }, [projectDfos])

  const getDfoAttributes = useCallback(
    async (dfoId: string) => {
      const currentKey = unstable_serialize({
        projectId: projectId || '',
        _key: 'dfoAttributes',
        dfoId,
        dfoParams,
      })

      const attributesInCache = cache.get(currentKey) as IDfoAttribute[] | undefined

      if (attributesInCache) return attributesInCache

      try {
        const attributes = await getDfoAttributesFromAPI({
          projectId: projectId ?? '',
          dfoId,
        })

        cache.set(currentKey, attributes)

        return attributes
      } catch (e) {
        throw e
      }
    },
    [cache, dfoParams, getDfoAttributesFromAPI, projectId],
  )

  //Находим актуальный current dfoId. В качестве параметра функция принимает dfoId любой версии
  const getActualCurrentDfoId = useCallback(
    async (currentDfoId: string | null) => {
      if (!projectId || !currentDfoId) return ''

      const keyOfSwr = unstable_serialize({
        projectId,
        dfoId,
        _key: 'versions',
      })

      const versionsOfDfoIdInCache = cache.get(keyOfSwr) as GetVersionData[] | undefined

      if (versionsOfDfoIdInCache)
        return versionsOfDfoIdInCache?.find((version) => version.isActual)?.id

      try {
        const versionsDfo = await getVersions({ projectId, dfoId: currentDfoId })

        cache.set(keyOfSwr, versionsDfo)

        return versionsDfo?.find((version) => version.isActual)?.id
      } catch (e) {
        throw e
      }
    },
    [cache, dfoId, getVersions, projectId],
  )

  const processGroup = useCallback(
    async (groupValue: string) => {
      const splitedValue = groupValue.split('.')

      const [dfoType, attributeName, objectKeyToValue] = [
        splitedValue[1],
        splitedValue[2],
        splitedValue.slice(INDEX_OF_OBJECT_KEY).join('.'),
      ]

      let dfoIdForRequest = mapOfDfoIdsByType[dfoType]

      if (dfoType === 'current') {
        await (async () => {
          try {
            dfoIdForRequest = await getActualCurrentDfoId(dfoId)
          } catch (e) {
            dfoIdForRequest = ''
            throw e
          }
        })()
      }

      if (!dfoIdForRequest) return

      try {
        const dfoAttributes = await getDfoAttributes(dfoIdForRequest)

        const foundAttributeByName = dfoAttributes?.find(
          (attribute) => attribute.name === attributeName,
        )

        if (!foundAttributeByName) return

        if (!objectKeyToValue) return prepareAttribute(foundAttributeByName)

        return prepareAttribute({
          name: objectKeyToValue.split('.').pop() || foundAttributeByName.name,
          value: getObjectValue(foundAttributeByName.value, objectKeyToValue) as unknown as string,
        })
      } catch (e) {
        throw e
      }
    },
    [dfoId, getActualCurrentDfoId, getDfoAttributes, mapOfDfoIdsByType],
  )

  const parseStageLineInfo = useCallback(
    async (value = dfoParams?.stageLineDescription || '') => {
      if (!value) return ''

      const mapOfGroupIndexes = new Map()
      let valueToReturn = value
      let dynamicReplaceKey = 0

      //Находим вхождения между {}, интерпретируется, как переменные
      const findGroupsByRegexp = value.match(REGEXP_TO_EJECT_VALUE)

      if (!findGroupsByRegexp?.length) return valueToReturn

      const preparedGroups = findGroupsByRegexp.map((value) => value.replaceAll(/[{}]/g, ''))

      valueToReturn = valueToReturn.replaceAll(REGEXP_TO_EJECT_VALUE, (_, group) => {
        dynamicReplaceKey += 1
        mapOfGroupIndexes.set(group, `$${dynamicReplaceKey}`)

        return `$${dynamicReplaceKey}`
      })

      try {
        for (const group of preparedGroups) {
          const processedGroup = await processGroup(group)

          const preparedProcessedGroup =
            processedGroup && isString(processedGroup) ? processedGroup : ''

          valueToReturn = valueToReturn.replace(
            mapOfGroupIndexes.get(group),
            preparedProcessedGroup,
          )
        }

        return valueToReturn
      } catch (e) {
        throw e
      }
    },
    [dfoParams?.stageLineDescription, processGroup],
  )

  const prepareAttribute = (dfoAttribute: IDfoAttribute) => {
    switch (dfoAttribute.name) {
      case 'registeredDate':
      case 'date':
        if (isString(dfoAttribute.value))
          return DayjsService.dayjs(dfoAttribute.value).format('DD MMMM YYYY')
        break

      default:
        return dfoAttribute.value
    }
  }

  return {
    parseStageLineInfo,
  }
}

export { useStageLineProcess }
