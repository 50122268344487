import { NpaMunicipalFormValues } from './types'

const defaultNpaMunicipalFormValues: NpaMunicipalFormValues = {
  headerInfo: {
    investorHeaderName: null,
    investorHeaderPosition: '',
    municipalityHeaderName: null,
    municipalityHeaderPosition: '',
  },
  npa: {
    npaStructure: [],
    chosenParts: null,
  },
  additionalFields: {
    isFederal: true,
    municipalities: [],
  },
}

const npaMunicipalFormBlockValues = {
  headerInfo: {
    investorHeaderName: 'headerInfo.investorHeaderName',
    investorHeaderPosition: 'headerInfo.investorHeaderPosition',
    municipalityHeaderName: 'headerInfo.municipalityHeaderName',
    municipalityHeaderPosition: 'headerInfo.municipalityHeaderPosition',
  },
  npa: {
    npaStructure: 'npa.npaStructure',
    chosenParts: 'npa.chosenParts',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    municipalities: 'additionalFields.municipalities',
  },
} as const

export { defaultNpaMunicipalFormValues, npaMunicipalFormBlockValues }
