import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { RolesTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useBooleanState } from '@hooks/useBooleanState'
import { IAttribute } from '@services/Attribute/Attribute.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { IOrganizationInfo } from '@services/Organization/organization.entity'
import { unstable_serialize, useSWRConfig } from 'swr'

const useExtraMembersFromAttributes = (projectId?: string, isPaused?: boolean) => {
  const {
    booleanState: extraMembersFromAttributesIsLoading,
    setBooleanState: setExtraMembersFromAttributesLoading,
  } = useBooleanState()
  const [extraMembersFromAttributes, setExtraMembersFromAttributes] = useState<
    Record<string, IOrganizationInfo[]>
  >({})
  const lastProcessedAttributes = useRef<IAttribute[]>()

  const {
    organizationsApi: { getOrganizationById },
  } = useAPIContext()

  const { cache } = useSWRConfig()

  const { projectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      isPaused: () => !projectId || !!isPaused,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useExtraMembersFromAttributes',
          moduleName: 'ParticipantsOfStatementManager',
          componentType: 'hook',
        },
      }),
    },
  })

  const allExtraMembersFromAttributes = useMemo(() => {
    if (isPaused || !projectAttributes) return []

    return projectAttributes.reduce<string[]>((previousValue, currentValue) => {
      if (currentValue.isTemp || currentValue.name !== 'subjectsExtra') return previousValue

      const organizationIdsFromValues: string[] =
        currentValue.type === 'List' && Array.isArray(currentValue.value) ? currentValue.value : []

      return [...previousValue, ...organizationIdsFromValues]
    }, [])
  }, [isPaused, projectAttributes])

  const getExtraMembersFromAttributes = useCallback(async () => {
    if (!allExtraMembersFromAttributes) return

    try {
      const organizationsPromises = await Promise.all(
        allExtraMembersFromAttributes.map(async (organizationId) => {
          const key = {
            organizationId,
            _key: 'organizationMembers',
          }
          const organization = await getOrganizationById(organizationId)
          cache.set(unstable_serialize(key), organization)

          return organization
        }),
      )

      return organizationsPromises.reduce<Record<string, IOrganizationInfo[]>>(
        (previousValue, currentValue) => {
          const preparedType = `${currentValue.type}_EXTRA`
          const preparedCurrentValue: IOrganizationInfo = {
            ...currentValue,
            type: RolesTypes.OIV_EXTRA,
          }

          if (preparedCurrentValue.type in previousValue) {
            const existedOrganizationArray = previousValue[preparedCurrentValue.type]

            return {
              ...previousValue,
              [preparedType]: [...existedOrganizationArray, preparedCurrentValue],
            }
          }

          return {
            ...previousValue,
            [preparedType]: [preparedCurrentValue],
          }
        },
        {},
      )
    } catch (error) {
      throw error
    }
  }, [allExtraMembersFromAttributes, cache, getOrganizationById])

  useEffect(() => {
    if (
      isPaused ||
      unstable_serialize(lastProcessedAttributes.current) === unstable_serialize(projectAttributes)
    ) {
      return
    }

    (async () => {
      setExtraMembersFromAttributesLoading(true)

      try {
        const organizations = await getExtraMembersFromAttributes()

        if (!organizations) return

        lastProcessedAttributes.current = projectAttributes
        setExtraMembersFromAttributes(organizations)
      } catch (error) {
        const additionInfo = {
          projectAttributes,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'useExtraMembersFromAttributes',
            moduleName: 'ParticipantsOfStatementManager',
            componentType: 'hook',
          },
        })(error)

        throw error
      } finally {
        setExtraMembersFromAttributesLoading(false)
      }
    })()
  }, [
    isPaused,
    projectAttributes,
    getExtraMembersFromAttributes,
    setExtraMembersFromAttributesLoading,
  ])

  return useMemo(
    () => ({
      extraMembersFromAttributes,
      extraMembersFromAttributesIsLoading,
    }),
    [extraMembersFromAttributes, extraMembersFromAttributesIsLoading],
  )
}

export { useExtraMembersFromAttributes }
