import DayjsService from '@services/Dayjs/Dayjs.service'

export const getNotificationDateView = (date: Date) => {
  const currentDate = DayjsService.dayjsWithFormatToMSK()
  const localDate = DayjsService.dayjs(date)

  const isToday = currentDate.diff(localDate, 'd') === 0

  const currentHour = currentDate.diff(localDate, 'h') === 0
  const lessTwoMinute = currentDate.diff(localDate, 'm') <= 2

  // Если разницы даты уведомления и текущего времени меньше 2минут - показывать текст "только что"
  if (currentHour && lessTwoMinute) return 'только что'

  if (isToday) {
    // Если дата уведомления равна текущему дню, то отображается только время в формате "HH:MM" (без секунд и миллисекунд)
    return localDate.format('HH:mm')
  }

  if (currentDate.year() === localDate.year()) {
    // Если дата уведомления не равно текущему дню, но равна  году отображается в формате "DD {monthNameshort} HH:MM"
    return localDate.format(`DD MMM · HH:mm`)
  }

  return localDate.format(`DD MMM YYYY · HH:mm`)
}
