import React, { useCallback, useReducer } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import Form from '@components/Form'
import { loginFormTitle } from '@components/Forms/LoginForm/constants'
import {
  authActionCreators,
  authReducer,
  initialAuthState,
} from '@components/Forms/LoginForm/LoginByAuth/reducer'
import LoginWrapper from '@components/Forms/LoginForm/shared/LoginWrapper'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Typography from '@components/NewDesign/Typography'
import { isInvestorDomain } from '@constants/system'
import { defaultRHFValidation, Patterns } from '@constants/validations'
import { useAuthContext } from '@context/AuthContext'
import { isAxiosError, isJsError } from '@helpers/checkTypes'
import { encodingsConverter } from '@helpers/encodings/converter'

import { authDefaultFormValues } from './constants'
import styles from './LoginByAuth.module.scss'
import type { AuthFormValues } from './types'

const LoginByAuth = () => {
  const { signIn } = useAuthContext()

  const [state, dispatch] = useReducer(authReducer, initialAuthState)

  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
  } = useForm<AuthFormValues>({
    mode: 'onChange',
    defaultValues: authDefaultFormValues,
  })

  const handleNativeReducerError = useCallback((error: unknown) => {
    if (!isJsError(error)) throw error

    dispatch(authActionCreators.setNativeError(error))
  }, [])

  const submitForm: SubmitHandler<AuthFormValues> = async ({ email, password }) => {
    dispatch(authActionCreators.setError(null))

    try {
      await signIn?.({
        email: encodingsConverter.ToUnicode(email),
        password,
      })
    } catch (error) {
      if (!isAxiosError(error)) {
        handleNativeReducerError(error)

        throw error
      }

      dispatch(authActionCreators.setError(error))

      throw error
    }
  }

  return (
    <LoginWrapper view={isInvestorDomain ? 'secondary' : 'primary'}>
      <div className={styles.stretch}>
        <Typography.Headline variant="headlineH2" className={styles.title}>
          {loginFormTitle.operator}
        </Typography.Headline>
        <Form
          noValidate
          className={styles.form}
          dataTestId="LoginByAuth-form"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className={styles.fields}>
            <ControlledInput
              name="email"
              control={control}
              inputProps={{
                view: 'secondary',
                placeholder: 'name@mail.ru',
                type: 'text',
                label: 'E-MAIL',
                fixWidth: true,
                clear: true,
                dataTestId: 'LoginByAuth-email-input',
              }}
              rules={{
                required: defaultRHFValidation.required,
                pattern: {
                  value: Patterns.Email,
                  message: 'Неправильный формат введенной почты',
                },
              }}
            />
            <div>
              <ControlledInput
                name="password"
                control={control}
                rules={{ required: defaultRHFValidation.required }}
                inputProps={{
                  fixWidth: true,
                  type: 'password',
                  label: 'ПАРОЛЬ',
                  placeholder: '********',
                  dataTestId: 'LoginByAuth-password-input',
                }}
              />
            </div>
          </div>
          <Button
            fixWidth
            type="submit"
            dataTestId="LoginByAuth-submitButton"
            disabled={isSubmitting}
            className={styles.button}
          >
            Войти
          </Button>
          {state.error && (
            <div className={styles.error}>
              <p>{state.error.message}</p>
            </div>
          )}
        </Form>
      </div>
    </LoginWrapper>
  )
}

export default LoginByAuth
