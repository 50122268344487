import { useEffect } from 'react'
import { FieldValues, Path } from 'react-hook-form'

import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { isUndefined } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'

interface UseFormCollapseControlProps<T extends FieldValues> {
  name: Path<T>
  initialExpanded?: boolean
  watcher?: FieldCollapseControlUpdateWatcher
}

const useFormCollapseControl = <T extends FieldValues>({
  initialExpanded = false,
  name,
  watcher,
}: UseFormCollapseControlProps<T>) => {
  const {
    booleanState: isExpanded,
    reverseBooleanState: toggleCollapse,
    setBooleanState: handleUpdateCollapse,
    setBooleanStateToTrue: handleExpand,
    setBooleanStateToFalse: handleCollapse,
  } = useBooleanState(initialExpanded)

  useEffect(() => {
    if (isUndefined(watcher)) return

    watcher.subscribe(name, {
      onCollapse: handleUpdateCollapse,
    })

    return () => {
      watcher.unsubscribe(name)
    }
  }, [name])

  return {
    isExpanded,
    onToggleCollapse: toggleCollapse,
    onUpdateCollapse: handleUpdateCollapse,
    onExpand: handleExpand,
    onCollapse: handleCollapse,
  }
}

export { useFormCollapseControl }
