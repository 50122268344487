import React, { DetailedHTMLProps, FC, LiHTMLAttributes, memo, useState } from 'react'

import Highlighted from '@components/Highlighted/Highlighted'
import Icon from '@components/Icon/Icon'
import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import type { IKnowledgeBaseItem } from '@components/KnowledgeBase/types'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import groupIcon from '@icons/list/groupList.svg'
import cn from 'classnames'

import styles from './Item.module.scss'

interface KnowledgeBaseDesktopItemProps
  extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  baseItem: IKnowledgeBaseItem
  initialPadding?: number
  stepOfPadding?: number
}

const KnowledgeBaseDesktopItem: FC<KnowledgeBaseDesktopItemProps> = ({
  baseItem,
  stepOfPadding = 10,
  initialPadding = 0,
  ...rest
}) => {
  const {
    handlers: { handleChooseBaseItem, handleSelectRef },
    state: { currentBaseItem, initializationThree, currentSearchPattern },
  } = useKnowledgeBase()

  const initialExpandedCondition = !!initializationThree.get(baseItem.id)
  const isRenderFromSearchThree = currentSearchPattern.length

  const [isExpanded, setIsExpanded] = useState(isRenderFromSearchThree || initialExpandedCondition)

  const isList = !!baseItem.children.length
  const isChosen = currentBaseItem?.id === baseItem.id

  const recursionCondition = isList && isExpanded

  const nextInitialPaddingLeft = initialPadding + stepOfPadding

  const handleExpandedClick = () => {
    setIsExpanded((prev) => !prev)
  }

  const preparedHandleItemClick = () => {
    if (!isList) return handleChooseBaseItem?.(baseItem)

    const equalItem = baseItem.id === currentBaseItem?.id

    if (isList && isExpanded && !equalItem) {
      return handleChooseBaseItem?.(baseItem)
    }

    if (isList && !isExpanded && !equalItem) {
      handleExpandedClick()
      return handleChooseBaseItem?.(baseItem)
    }

    return handleExpandedClick()
  }

  const bindBaseItemRef = (baseItemRef: HTMLLIElement | null) => {
    if (!handleSelectRef || baseItemRef) return

    if (baseItemRef) {
      return handleSelectRef(baseItemRef)
    }
  }

  useEffectAfterMount(() => {
    if (isRenderFromSearchThree || initialExpandedCondition) {
      return setIsExpanded(true)
    }
  }, [isRenderFromSearchThree, initialExpandedCondition])

  useEffectAfterMount(() => {
    if (currentSearchPattern.length) return

    if (initialExpandedCondition) {
      return setIsExpanded(true)
    }

    return setIsExpanded(false)
  }, [currentSearchPattern])

  const lvlOfList = baseItem.position.match(/\./g)?.length

  return (
    <>
      <li
        {...rest}
        className={styles.baseItem}
        role="presentation"
        ref={bindBaseItemRef}
        onClick={preparedHandleItemClick}
      >
        {isList ? (
          <Icon
            noCurrentColorSvgFill
            src={groupIcon}
            size="initial"
            wrapper="span"
            className={cn(styles.baseItem__icon, {
              [styles['baseItem__icon--expanded']]: isExpanded,
            })}
          />
        ) : (
          <span className={styles['baseItem__icon--isNotList']} />
        )}

        <span
          className={cn(styles.baseItem__name, {
            [styles['baseItem__name--chosen']]: isChosen,
          })}
        >
          <Highlighted highlight={currentSearchPattern} color="inherit" backgroundColor="#FFFF00">
            {baseItem.name}
          </Highlighted>
        </span>
      </li>
      {recursionCondition &&
        baseItem.children.map((item) => {
          return (
            <KnowledgeBaseDesktopItem
              key={item.id}
              baseItem={item}
              initialPadding={nextInitialPaddingLeft}
              style={{ paddingLeft: nextInitialPaddingLeft }}
            />
          )
        })}
    </>
  )
}

export default memo(KnowledgeBaseDesktopItem)
