import { FC } from 'react'
import { Path, useFormContext } from 'react-hook-form'

import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group, { GroupProps } from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { FormRules } from '@components/DocumentFormComponents/types'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface GroupWithDoublePlanAndFactReportsProps {
  groupTitle: string
  groupProps?: Omit<GroupProps, 'children'>

  editablePlanFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  editablePlanRules?: FormRules<DataOnFulfillmentOfTermsSZPKFormValues>
  notEditablePlanFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>

  editableFactFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  editableFactRules?: FormRules<DataOnFulfillmentOfTermsSZPKFormValues>
  notEditableFactFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
}

const GroupWithDoublePlanAndFactReports: FC<GroupWithDoublePlanAndFactReportsProps> = ({
  groupTitle,
  groupProps,
  editablePlanFormName,
  editablePlanRules,
  notEditablePlanFormName,
  editableFactFormName,
  editableFactRules,
  notEditableFactFormName,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  return (
    <Group title={groupTitle} {...groupProps}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notEditablePlanFormName,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: notEditablePlanFormName,
                  inputProps: {
                    disabled: true,
                    integersOnly: true,
                    suffix: '',
                    label: 'Плановое значение',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: editablePlanFormName,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: editablePlanFormName,
                  rules: editablePlanRules,
                  inputProps: {
                    integersOnly: true,
                    suffix: '',
                    label: 'Плановое значение',
                    leftAddons: (
                      <FormIconWithTooltip
                        tooltipContent={'Данное значение будет перенесено в документ'}
                      />
                    ),
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(editablePlanFormName)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(editablePlanFormName)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notEditableFactFormName,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: notEditableFactFormName,
                  inputProps: {
                    disabled: true,
                    integersOnly: true,
                    suffix: '',
                    label: 'Фактическое значение',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: editableFactFormName,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: editableFactFormName,
                  rules: editableFactRules,
                  inputProps: {
                    integersOnly: true,
                    suffix: '',
                    label: 'Фактическое значение',
                    leftAddons: (
                      <FormIconWithTooltip
                        tooltipContent={'Данное значение будет перенесено в документ'}
                      />
                    ),
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(editableFactFormName)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(editableFactFormName)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Group>
  )
}

export default GroupWithDoublePlanAndFactReports
