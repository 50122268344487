import React, { FC } from 'react'
import { Control } from 'react-hook-form/dist/types/form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/RidObject.module.scss'
import RExploitationForm from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RExploitationForm'
import RForm from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RForm'
import RRegistrationForm from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RRegistrationForm'
import { SeventhRidStagePathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

interface RidStageProps {
  parentId: string
  indexOfMainBlock: number
  name: SeventhRidStagePathName
  control: Control<ProjectSZPKFormValues>
}

const RidObject: FC<RidStageProps> = ({ parentId, indexOfMainBlock, name, control }) => {
  return (
    <div className={styles.ridObject}>
      <div
        id={`${parentId}.5`}
        className={cn(styles.ridObject__header, styles['ridObject__object-header'])}
      >
        <Typography.Headline variant={'headlineH3'}>
          Результаты интеллектуальной деятельности (РИД)
        </Typography.Headline>
      </div>

      <div className={styles.ridObject__items}>
        <RForm name={`${name}.ridObjects`} control={control} />
      </div>

      <Border className={styles.ridObject__border} />

      <div id={`${parentId}.6`} className={styles.ridObject__exploitation}>
        <div className={styles.ridObject__header}>
          <Typography.Headline variant={'headlineH4'}>Эксплуатация РИД</Typography.Headline>
        </div>
        <RExploitationForm
          indexOfBlock={indexOfMainBlock}
          name={`${name}.exploitation`}
          control={control}
        />
      </div>

      <Border className={styles.ridObject__border} />

      <div id={`${parentId}.7`} className={styles.ridObject__registration}>
        <div className={styles.ridObject__header}>
          <Typography.Headline variant={'headlineH4'}>Регистрация РИД</Typography.Headline>
        </div>
        <RRegistrationForm
          name={`${name}.registration`}
          control={control}
          indexOfBlock={indexOfMainBlock}
        />
      </div>
    </div>
  )
}

export default RidObject
