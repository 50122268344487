import React, { FC, memo } from 'react'

import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import Logo from '@components/Layout/Logo/Logo'
import BurgerButton from '@components/NewDesign/BurgerButton'
import Button from '@components/NewDesign/Button'
import MobileMenu from '@components/NewDesign/MobileMenu'
import { useScrollState } from '@hooks/new/scroll/useScrollState'
import { useBooleanState } from '@hooks/useBooleanState'
import backArrowIcon from '@icons/navigation/arrow_back.svg'

import styles from './Header.module.scss'

interface KnowledgeBaseMobileHeaderProps {
  onBack: VoidFunction
}

const KnowledgeBaseMobileHeader: FC<KnowledgeBaseMobileHeaderProps> = ({ onBack }) => {
  const {
    state: { currentBaseItem },
    handlers: { handleClearBaseItem },
  } = useKnowledgeBase()

  const { booleanState: mobileMenuIsOpen, setBooleanState: setMobileMenuIsOpen } = useBooleanState()

  const { handleBlockScroll, handleUnblockScroll } = useScrollState()

  const handleChangeMobileMenu = () => {
    if (mobileMenuIsOpen) {
      handleUnblockScroll()
    } else {
      handleBlockScroll()
    }

    setMobileMenuIsOpen((prev) => !prev)
  }

  const handleCloseMobileMenu = () => {
    if (!mobileMenuIsOpen) return

    handleUnblockScroll()

    setMobileMenuIsOpen(false)
  }

  const handleBack = () => {
    handleClearBaseItem?.()
    onBack()
  }

  return (
    <div className={styles.header}>
      <div className={styles['header-wrapper']}>
        <Logo onLogoClick={handleCloseMobileMenu} />
        <BurgerButton isOpen={mobileMenuIsOpen} onClick={handleChangeMobileMenu} />
      </div>
      {!!currentBaseItem && (
        <div className={styles['header-navigation']}>
          <Button
            geometry="square"
            view="text"
            size="xs"
            color="default"
            variant="buttonSMedium"
            className={styles['header-navigation__button']}
            leadingIcon={{
              src: backArrowIcon,
              size: 'm',
              className: styles['header-navigation__button-icon'],
            }}
            onClick={handleBack}
          >
            Назад
          </Button>
        </div>
      )}
      <MobileMenu isOpen={mobileMenuIsOpen} onLinkClick={handleChangeMobileMenu} />
    </div>
  )
}

export default memo(KnowledgeBaseMobileHeader)
