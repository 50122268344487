import { isString } from '@helpers/checkTypes'
import { ValueOf } from '@helpers/ValueOf'
import { ConfigType, Dayjs, OptionType } from 'dayjs'

import { mapOfInvalidDateTypes } from './Dayjs.entity'
import DayjsService from './Dayjs.service'

export type FormatPropForValidationCheck<T> = T extends string
  ? OptionType
  : OptionType | undefined | null

class DayjsHelpersService {
  private static isDateNonExistent<T>(
    dayjsDate: Dayjs,
    date: T & ConfigType,
    format: FormatPropForValidationCheck<T>,
  ) {
    //несуществующая дата пересчитывается и остается валидной!
    if (!dayjsDate.isValid() || !isString(date) || !isString(format)) return false

    return dayjsDate.format(format) !== date
  }

  private static isDateInvalidByDayjsNativeMethod(dayjsDate: Dayjs) {
    return !dayjsDate.isValid()
  }

  static getInvalidDateType<T>(
    date: T & ConfigType,
    format: FormatPropForValidationCheck<T>,
    locale?: string,
    strict?: boolean,
  ): ValueOf<typeof mapOfInvalidDateTypes> | null {
    const dayjsDate = DayjsService.dayjs(date, format ?? undefined, locale, strict)

    if (this.isDateInvalidByDayjsNativeMethod(dayjsDate))
      return mapOfInvalidDateTypes.DATE_FORMAT_INCORRECT

    if (this.isDateNonExistent(dayjsDate, date, format))
      return mapOfInvalidDateTypes.DATE_NON_EXISTENT

    return null
  }
}

export default DayjsHelpersService
