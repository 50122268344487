import React, { RefObject, useRef } from 'react'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import { getNotificationId } from '@components/NewNotifications/getNotificationId'
import { NotificationId } from '@components/NewNotifications/model'
import NotificationsStack from '@components/NewNotifications/NotificationsStack'
import SingleNotification from '@components/NewNotifications/SingleNotification'
import { defaultPageSize, useNotifications } from '@components/NewNotifications/useNotifications'
import { firstPageKey } from '@components/NewNotifications/useNotificationsManager'
import { isLoginByLP } from '@constants/system'
// eslint-disable-next-line jest/no-mocks-import
import { getDateTime } from '@helpers/date/getDateTime'
import { useOrganizationVerify } from '@hooks/new/swr/useOrganizationVerify'
import { useNotificationsCount } from '@hooks/swr/useNotificationsCount'
import useAuthUser from '@hooks/useAuthUser'
import { useBooleanState } from '@hooks/useBooleanState'
import useOnClickOutside from '@hooks/useOutsideClick'
import CloseIcon from '@icons/navigation/close.svg'
import NewNotificationsIcon from '@icons/notification/new_notifications_icon.svg'
import NotificationsIcon from '@icons/NotificationIcon.svg'
import EmailMessage from '@layouts/Header/Message'
import OrganizationMessage from '@layouts/Header/OrganizationMessage'
import cn from 'classnames'
import { useSWRConfig } from 'swr'

import styles from './Header.module.scss'

interface HeaderNotificationsProps {
  headerRef: RefObject<HTMLElement>
}

const baseOrganizationVerifyKey = {
  _key: 'organizationVerify',
}

const notNewNotifications = () =>
  toast(
    <Alert transparent variant="warning">
      Новых уведомлений нет
    </Alert>,
  )

const HeaderNotifications = ({ headerRef }: HeaderNotificationsProps) => {
  const {
    booleanState: isPanelOpen,
    setBooleanStateToFalse: closePanel,
    setBooleanStateToTrue: openPanel,
  } = useBooleanState()

  const {
    booleanState: isEmailModalOpen,
    setBooleanStateToFalse: closeEmailModal,
    setBooleanStateToTrue: openEmailModal,
  } = useBooleanState()

  const {
    notifications,
    goToNextPage,
    canGoToNextPage,
    page,
    hideNotifications,
    isNotificationsLoading,
    isFirstPageOfNotificationsLoading,
    removeNotification,
    isLastPageDataLoading,
    firstPageError,
  } = useNotifications({
    isPanelOpen,
    onLoadLastPage: notNewNotifications,
  })

  const { notificationsCount, readNotifications, readNotification } = useNotificationsCount({
    key: 'notificationsCount',
    config: {
      isPaused: () => isLoginByLP,
    },
  })

  const { organizationVerify } = useOrganizationVerify({
    key: baseOrganizationVerifyKey,
    config: {
      isPaused: () => isLoginByLP,
    },
  })

  const { currentUserFullInfo } = useAuthUser()

  const conditionEmailMessageRender = !!currentUserFullInfo

  const readNotificationHandler = (id: NotificationId) => {
    removeNotification(id)
    readNotification()
  }

  const hasUnreadNotifications = notificationsCount
    ? readNotifications < notificationsCount.count
    : false

  const { cache } = useSWRConfig()

  const panelCloseHandler = () => {
    closePanel()
    hideNotifications()
  }

  const { mutate } = useSWRConfig()

  const panelRef = useRef<HTMLDivElement>(null)

  const openPanelHandler = () => {
    if (!cache.get(firstPageKey)?.length) mutate(firstPageKey, undefined, { revalidate: true })
    openPanel()
  }

  useOnClickOutside([panelRef, headerRef], () => !isEmailModalOpen && closePanel())

  return (
    <>
      <div className={styles.ToggleButton}>
        {isFirstPageOfNotificationsLoading ? (
          <Loader
            variant={'lite'}
            loading={true}
            dataTestId="HeaderNotifications-notificationLoader"
          />
        ) : (
          <IconButton
            disabled={firstPageError}
            className={styles.NotificationsButton}
            dataTestId="HeaderNotifications-notification-button"
            icon={{
              className: cn(styles.NotificationsIcon, {
                [styles.NotificationsIcon_new]: !isPanelOpen && hasUnreadNotifications,
                [styles.NotificationsIcon_close]: isPanelOpen,
              }),
              src: isPanelOpen
                ? CloseIcon
                : hasUnreadNotifications
                ? NewNotificationsIcon
                : NotificationsIcon,
              view: 'basic',
              geometry: 'round',
              size: 'xl',
              noCurrentColorSvgFill: true,
            }}
            onClick={isPanelOpen ? panelCloseHandler : openPanelHandler}
          />
        )}
      </div>
      {isPanelOpen && (
        <div
          ref={panelRef}
          className={cn(styles.NotificationsContainer, styles.NotificationsAnimation, {
            [styles.NotificationsPlaceholder]:
              !notifications.length && !conditionEmailMessageRender,
          })}
        >
          <div className={styles.NotificationsList}>
            {organizationVerify && <OrganizationMessage organizationVerify={organizationVerify} />}
            {!!currentUserFullInfo && (
              <EmailMessage
                isModalOpen={isEmailModalOpen}
                openModal={openEmailModal}
                closeModal={closeEmailModal}
              />
            )}
            {notifications.length
              ? notifications.map((notification) => {
                  const { lastNotification, dfoId, projectName, projectId } = notification
                  const { dateTime, title, text: description, link, level } = lastNotification

                  const generalProps = {
                    dfoId,
                    notificationId: getNotificationId(notification),
                    projectId,
                    key: getNotificationId(notification),
                    date: getDateTime(dateTime),
                    subtitle: projectName,
                    title,
                    description,
                    link,
                    level,
                  }

                  return (
                    <div
                      key={generalProps.notificationId}
                      className={cn(styles.NotificationsAnimation)}
                    >
                      {notification.notificationsCount > 1 ? (
                        <NotificationsStack
                          onReadStack={readNotificationHandler}
                          {...generalProps}
                        />
                      ) : (
                        <SingleNotification onRead={readNotificationHandler} {...generalProps} />
                      )}
                    </div>
                  )
                })
              : !conditionEmailMessageRender && (
                  <Typography.Body color={'text-base-secondary'} variant={'bodySMedium'}>
                    Уведомлений нет
                  </Typography.Body>
                )}

            {isNotificationsLoading ||
            isFirstPageOfNotificationsLoading ||
            isLastPageDataLoading ? (
              <Loader
                loading
                variant={'lite'}
                dataTestId="HeaderNotifications-nextPage-loader"
                wrapperClassName={styles.Loader}
              />
            ) : (
              canGoToNextPage && (
                <Button
                  view={'gray'}
                  size={'s'}
                  variant={'buttonSMedium'}
                  dataTestId="HeaderNotifications-nextPage-button"
                  className={styles['button-load']}
                  onClick={goToNextPage}
                >
                  Показать предыдущие
                </Button>
              )
            )}
          </div>
          <div className={styles.NotificationsFooter}>
            {!!(page && notifications.length > defaultPageSize) && (
              <Button
                view={'gray'}
                size={'s'}
                variant={'buttonSMedium'}
                className={styles['button-load']}
                dataTestId="HeaderNotifications-hideNotification-button"
                onClick={hideNotifications}
              >
                Показать меньше
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default HeaderNotifications
