import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import objectItemStyles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { parametersOfCostRecoveryApplicationConstValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectBond from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/Forms/BondObjectForm'
import ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectCredit from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/Forms/CreditObjectForm'
import ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectSzpk from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/Forms/SzpkObjectForm'
import { ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import Container from '@components/ReactBootstrap/Container'
import cn from 'classnames'

import styles from './Fourth.module.scss'

interface ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsProps {
  id: string
  title: string
  name: ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
  onRemove?: VoidFunction
}

const ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjects: FC<
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsProps
> = ({ id, title, name, formInstance, onRemove }) => {
  const objectTypeSystemName = useWatch({
    name: `${name}.compensationObjectTypeSystemName`,
    control: formInstance.control,
  })

  const isSzpkFieldRender =
    objectTypeSystemName ===
      parametersOfCostRecoveryApplicationConstValues.COMPENSATION_OBJECT_FROM_SZPK ||
    objectTypeSystemName ===
      parametersOfCostRecoveryApplicationConstValues.COMPENSATION_OBJECT_ABSENT_SZPK

  const isAgreementFieldRender =
    objectTypeSystemName === parametersOfCostRecoveryApplicationConstValues.CREDIT_AGREEMENT ||
    objectTypeSystemName === parametersOfCostRecoveryApplicationConstValues.LOAN_AGREEMENT

  const isBondFieldRender =
    objectTypeSystemName === parametersOfCostRecoveryApplicationConstValues.BOND

  return (
    <Container className={cn(objectItemStyles.objectItem, styles.objectItem)}>
      <CollapseWrapper isExpanded={isSzpkFieldRender} defaultExpanded={isSzpkFieldRender}>
        <ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectSzpk
          id={id}
          title={title}
          formInstance={formInstance}
          name={name}
          onRemove={onRemove}
        />
      </CollapseWrapper>
      <CollapseWrapper isExpanded={isAgreementFieldRender} defaultExpanded={isAgreementFieldRender}>
        <ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectCredit
          id={id}
          title={title}
          formInstance={formInstance}
          name={name}
          onRemove={onRemove}
        />
      </CollapseWrapper>
      <CollapseWrapper isExpanded={isBondFieldRender} defaultExpanded={isBondFieldRender}>
        <ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectBond
          id={id}
          title={title}
          formInstance={formInstance}
          name={name}
          onRemove={onRemove}
        />
      </CollapseWrapper>
    </Container>
  )
}

export default ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjects
