import { useBooleanState } from '@hooks/useBooleanState'

export const useShowAllDocumentsSwitch = () => {
  const {
    booleanState: mustShowOnlyChangesSetOfDocuments,
    setBooleanStateToFalse: handleShowAllSetOfDocuments,
    setBooleanState: handleChangeSwitchState,
  } = useBooleanState()

  const handleResetSwitchStatus = () => {
    handleShowAllSetOfDocuments()
  }

  return {
    handleResetSwitchStatus,
    handleShowAllSetOfDocuments,
    mustShowOnlyChangesSetOfDocuments,
    handleChangeSwitchState,
  }
}
