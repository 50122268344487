import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const fifteenthSectionValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['15'],
  ProjectSZPKFormValues
> = {
  ruleLinkMonitoring: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  subjectNpaReasonMonitoring: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
}

export { fifteenthSectionValidationMap }
