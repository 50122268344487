import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { dataOnFulfillmentOfTermsSZPKBlockValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/const'
import GroupWithDoublePlanAndFactReports from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/GroupWithDoublePlanAndFactReports'
import GroupWithPlanAndFactReports from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/GroupWithPlanAndFactReports'
import GroupWithTriplePlanAndFactReports from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/GroupWithTriplePlanAndFactReports'
import SzpkReportInfoGroup from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/SzpkReportInfo/SzpkReportInfoGroup'
import { thirdSectionValidationMap } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/validation'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import Typography from '@components/NewDesign/Typography'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const signedSzpkReportsSubtitle = useWatch({
    name: dataOnFulfillmentOfTermsSZPKBlockValues['3'].signedSzpkReportsSubtitle,
    control: formInstance?.control,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Typography.Body className={styles.form__signedSubtitle} variant={'bodyMMedium'}>
          {signedSzpkReportsSubtitle}
        </Typography.Body>

        <GroupWithPlanAndFactReports
          isIntegersOnly
          groupTitle="Общее количество СЗПК"
          planFormName={dataOnFulfillmentOfTermsSZPKBlockValues['3'].szpkCountRegionalReportPlan}
          factFormName={dataOnFulfillmentOfTermsSZPKBlockValues['3'].szpkCountRegionalReportFact}
          groupProps={{
            disableBottomBorder: true,
            id: '3.1',
          }}
        />

        <GroupWithDoublePlanAndFactReports
          groupTitle="СЗПК, стороной которых является Российская Федерация"
          editablePlanRules={thirdSectionValidationMap.federalSzpkCountRegionalReportPlanCorrect}
          editableFactRules={thirdSectionValidationMap.federalSzpkCountRegionalReportFactCorrect}
          editablePlanFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['3'].federalSzpkCountRegionalReportPlanCorrect
          }
          notEditablePlanFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['3'].federalSzpkCountRegionalReportPlan
          }
          editableFactFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['3'].federalSzpkCountRegionalReportFactCorrect
          }
          notEditableFactFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['3'].federalSzpkCountRegionalReportFact
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal
          isSzpkReportsMustBeWithinMonitoringDates={false}
        />

        <GroupWithDoublePlanAndFactReports
          groupTitle="СЗПК, стороной которых не является Российская Федерация"
          editablePlanRules={thirdSectionValidationMap.regionalSzpkCountRegionalReportPlanCorrect}
          editableFactRules={thirdSectionValidationMap.regionalSzpkCountRegionalReportFactCorrect}
          editablePlanFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['3'].regionalSzpkCountRegionalReportPlanCorrect
          }
          notEditablePlanFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['3'].regionalSzpkCountRegionalReportPlan
          }
          editableFactFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['3'].regionalSzpkCountRegionalReportFactCorrect
          }
          notEditableFactFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['3'].regionalSzpkCountRegionalReportFact
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal={false}
          isSzpkReportsMustBeWithinMonitoringDates={false}
        />

        <GroupWithPlanAndFactReports
          isIntegersOnly
          groupTitle="Общее количество СЗПК за отчетный период"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4'].szpkPeriodCountRegionalReportPlan
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4'].szpkPeriodCountRegionalReportFact
          }
          groupProps={{
            disableBottomBorder: true,
            id: '3.2',
          }}
        />

        <GroupWithDoublePlanAndFactReports
          groupTitle="СЗПК, заключенные в отчетном периоде, стороной которых является Российская Федерация"
          editablePlanFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4']
              .federalSzpkPeriodCountRegionalReportPlanCorrect
          }
          notEditablePlanFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4'].federalSzpkPeriodCountRegionalReportPlan
          }
          editableFactFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4']
              .federalSzpkPeriodCountRegionalReportFactCorrect
          }
          notEditableFactFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4'].federalSzpkPeriodCountRegionalReportFact
          }
          editablePlanRules={
            thirdSectionValidationMap.federalSzpkPeriodCountRegionalReportPlanCorrect
          }
          editableFactRules={
            thirdSectionValidationMap.federalSzpkPeriodCountRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup isSzpkReportsFederal isSzpkReportsMustBeWithinMonitoringDates={true} />

        <GroupWithDoublePlanAndFactReports
          groupTitle="СЗПК, заключенные в отчетном периоде, стороной которых не является Российская Федерация"
          editablePlanFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4']
              .regionalSzpkPeriodCountRegionalReportPlanCorrect
          }
          notEditablePlanFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4'].regionalSzpkPeriodCountRegionalReportPlan
          }
          editableFactFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4']
              .regionalSzpkPeriodCountRegionalReportFactCorrect
          }
          notEditableFactFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['4'].regionalSzpkPeriodCountRegionalReportFact
          }
          editablePlanRules={
            thirdSectionValidationMap.regionalSzpkPeriodCountRegionalReportPlanCorrect
          }
          editableFactRules={
            thirdSectionValidationMap.regionalSzpkPeriodCountRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal={false}
          isSzpkReportsMustBeWithinMonitoringDates={true}
        />

        <GroupWithPlanAndFactReports
          groupTitle="Объем осуществленных капитальных вложений"
          planFormName={dataOnFulfillmentOfTermsSZPKBlockValues['5'].totalCapitalRegionalReportPlan}
          factFormName={dataOnFulfillmentOfTermsSZPKBlockValues['5'].totalCapitalRegionalReportFact}
          groupProps={{
            disableBottomBorder: true,
            id: '3.3',
          }}
        />

        <GroupWithTriplePlanAndFactReports
          groupTitle="Объем осуществленных капитальных вложений по СЗПК, стороной которых является Российская Федерация"
          planCorrectRules={thirdSectionValidationMap.federalTotalCapitalRegionalReportPlanCorrect}
          factCorrectRules={thirdSectionValidationMap.federalTotalCapitalRegionalReportFactCorrect}
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5'].federalTotalCapitalRegionalReportPlan
          }
          planCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5']
              .federalTotalCapitalRegionalReportPlanChecker
          }
          planCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5']
              .federalTotalCapitalRegionalReportPlanCorrect
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5'].federalTotalCapitalRegionalReportFact
          }
          factCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5']
              .federalTotalCapitalRegionalReportFactChecker
          }
          factCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5']
              .federalTotalCapitalRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal
          isSzpkReportsMustBeWithinMonitoringDates={false}
          capitalValueGroupCardProps={{
            groupTitle: 'Объем капитальных вложений',
            planAppendix: 'totalCapitalPlan',
            planCheckerAppendix: 'totalCapitalPlanChecker',
            factAppendix: 'totalCapitalFact',
            factCheckerAppendix: 'totalCapitalFactChecker',
          }}
        />

        <GroupWithTriplePlanAndFactReports
          groupTitle="Объем осуществленных капитальных вложений по СЗПК, стороной которых не является Российская Федерация"
          planCorrectRules={thirdSectionValidationMap.regionalTotalCapitalRegionalReportPlanCorrect}
          factCorrectRules={thirdSectionValidationMap.regionalTotalCapitalRegionalReportFactCorrect}
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5'].regionalTotalCapitalRegionalReportPlan
          }
          planCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5']
              .regionalTotalCapitalRegionalReportPlanChecker
          }
          planCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5']
              .regionalTotalCapitalRegionalReportPlanCorrect
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5'].regionalTotalCapitalRegionalReportFact
          }
          factCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5']
              .regionalTotalCapitalRegionalReportFactChecker
          }
          factCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['5']
              .regionalTotalCapitalRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal={false}
          isSzpkReportsMustBeWithinMonitoringDates={false}
          capitalValueGroupCardProps={{
            groupTitle: 'Объем капитальных вложений',
            planAppendix: 'totalCapitalPlan',
            planCheckerAppendix: 'totalCapitalPlanChecker',
            factAppendix: 'totalCapitalFact',
            factCheckerAppendix: 'totalCapitalFactChecker',
          }}
        />

        <GroupWithPlanAndFactReports
          groupTitle="Объем осуществленных капиталовложений"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6'].investorCapitalRegionalReportPlan
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6'].investorCapitalRegionalReportFact
          }
          groupProps={{
            disableBottomBorder: true,
            id: '3.4',
          }}
        />

        <GroupWithTriplePlanAndFactReports
          groupTitle="Объем осуществленных капиталовложений по СЗПК, стороной которых является Российская Федерация"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6'].federalInvestorCapitalRegionalReportPlan
          }
          planCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6']
              .federalInvestorCapitalRegionalReportPlanChecker
          }
          planCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6']
              .federalInvestorCapitalRegionalReportPlanCorrect
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6'].federalInvestorCapitalRegionalReportFact
          }
          factCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6']
              .federalInvestorCapitalRegionalReportFactChecker
          }
          factCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6']
              .federalInvestorCapitalRegionalReportFactCorrect
          }
          planCorrectRules={
            thirdSectionValidationMap.federalInvestorCapitalRegionalReportPlanCorrect
          }
          factCorrectRules={
            thirdSectionValidationMap.federalInvestorCapitalRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal
          isSzpkReportsMustBeWithinMonitoringDates={false}
          capitalValueGroupCardProps={{
            groupTitle: 'Объем капиталовложений',
            planAppendix: 'investorCapitalPlan',
            planCheckerAppendix: 'investorCapitalPlanChecker',
            factAppendix: 'investorCapitalFact',
            factCheckerAppendix: 'investorCapitalFactChecker',
          }}
        />

        <GroupWithTriplePlanAndFactReports
          groupTitle="Объем осуществленных капиталовложений по СЗПК, стороной которых не является Российская Федерация"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6'].regionalInvestorCapitalRegionalReportPlan
          }
          planCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6']
              .regionalInvestorCapitalRegionalReportPlanChecker
          }
          planCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6']
              .regionalInvestorCapitalRegionalReportPlanCorrect
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6'].regionalInvestorCapitalRegionalReportFact
          }
          factCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6']
              .regionalInvestorCapitalRegionalReportFactChecker
          }
          factCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['6']
              .regionalInvestorCapitalRegionalReportFactCorrect
          }
          planCorrectRules={
            thirdSectionValidationMap.regionalInvestorCapitalRegionalReportPlanCorrect
          }
          factCorrectRules={
            thirdSectionValidationMap.regionalInvestorCapitalRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal={false}
          isSzpkReportsMustBeWithinMonitoringDates={false}
          capitalValueGroupCardProps={{
            groupTitle: 'Объем капиталовложений',
            planAppendix: 'investorCapitalPlan',
            planCheckerAppendix: 'investorCapitalPlanChecker',
            factAppendix: 'investorCapitalFact',
            factCheckerAppendix: 'investorCapitalFactChecker',
          }}
        />

        <GroupWithPlanAndFactReports
          isIntegersOnly
          groupTitle="Количество созданных рабочих мест"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7'].newWorkPlacesRegionalReportPlan
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7'].newWorkPlacesRegionalReportFact
          }
          groupProps={{
            disableBottomBorder: true,
            id: '3.5',
          }}
        />

        <GroupWithTriplePlanAndFactReports
          isIntegersOnly
          groupTitle="Количество созданных рабочих мест по СЗПК, стороной которых является Российская Федерация"
          planCorrectRules={thirdSectionValidationMap.federalNewWorkPlacesRegionalReportPlanCorrect}
          factCorrectRules={thirdSectionValidationMap.federalNewWorkPlacesRegionalReportFactCorrect}
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7'].federalNewWorkPlacesRegionalReportPlan
          }
          planCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7']
              .federalNewWorkPlacesRegionalReportPlanChecker
          }
          planCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7']
              .federalNewWorkPlacesRegionalReportPlanCorrect
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7'].federalNewWorkPlacesRegionalReportFact
          }
          factCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7']
              .federalNewWorkPlacesRegionalReportFactChecker
          }
          factCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7']
              .federalNewWorkPlacesRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal
          isSzpkReportsMustBeWithinMonitoringDates={false}
          newWorkPlacesGroupCardProps={{
            groupTitle: 'Количество рабочих мест',
            planAppendix: 'newWorkPlacesPlan',
            planCheckerAppendix: 'newWorkPlacesPlanChecker',
            factAppendix: 'newWorkPlacesFact',
            factCheckerAppendix: 'newWorkPlacesFactChecker',
          }}
        />

        <GroupWithTriplePlanAndFactReports
          isIntegersOnly
          groupTitle="Количество созданных рабочих мест по СЗПК, стороной которых не является Российская Федерация"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7'].regionalNewWorkPlacesRegionalReportPlan
          }
          planCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7']
              .regionalNewWorkPlacesRegionalReportPlanChecker
          }
          planCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7']
              .regionalNewWorkPlacesRegionalReportPlanCorrect
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7'].regionalNewWorkPlacesRegionalReportFact
          }
          factCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7']
              .regionalNewWorkPlacesRegionalReportFactChecker
          }
          factCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['7']
              .regionalNewWorkPlacesRegionalReportFactCorrect
          }
          planCorrectRules={
            thirdSectionValidationMap.regionalNewWorkPlacesRegionalReportPlanCorrect
          }
          factCorrectRules={
            thirdSectionValidationMap.regionalNewWorkPlacesRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal={false}
          isSzpkReportsMustBeWithinMonitoringDates={false}
          newWorkPlacesGroupCardProps={{
            groupTitle: 'Количество рабочих мест',
            planAppendix: 'newWorkPlacesPlan',
            planCheckerAppendix: 'newWorkPlacesPlanChecker',
            factAppendix: 'newWorkPlacesFact',
            factCheckerAppendix: 'newWorkPlacesFactChecker',
          }}
        />

        <GroupWithPlanAndFactReports
          groupTitle="Объем возмещенных затрат организаций, реализующих инвестиционные проекты"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8'].compensationValueRegionalReportPlan
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8'].compensationValueRegionalReportFact
          }
          groupProps={{
            disableBottomBorder: true,
            id: '3.6',
          }}
        />

        <GroupWithTriplePlanAndFactReports
          groupTitle="Объем возмещенных затрат по СЗПК, стороной которых является Российская Федерация"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8'].federalCompensationValueRegionalReportPlan
          }
          planCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8']
              .federalCompensationValueRegionalReportPlanChecker
          }
          planCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8']
              .federalCompensationValueRegionalReportPlanCorrect
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8'].federalCompensationValueRegionalReportFact
          }
          factCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8']
              .federalCompensationValueRegionalReportFactChecker
          }
          factCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8']
              .federalCompensationValueRegionalReportFactCorrect
          }
          planCorrectRules={
            thirdSectionValidationMap.federalCompensationValueRegionalReportPlanCorrect
          }
          factCorrectRules={
            thirdSectionValidationMap.federalCompensationValueRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal
          isSzpkReportsMustBeWithinMonitoringDates={false}
          capitalValueGroupCardProps={{
            groupTitle: 'Объем возмещенных затрат',
            planAppendix: 'compensationValuePlan',
            planCheckerAppendix: 'compensationValuePlanChecker',
            factAppendix: 'compensationValueFact',
            factCheckerAppendix: 'compensationValueFactChecker',
          }}
        />

        <GroupWithTriplePlanAndFactReports
          groupTitle="Объем возмещенных затрат по СЗПК, стороной которых не является Российская Федерация"
          planFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8'].regionalCompensationValueRegionalReportPlan
          }
          factFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8'].regionalCompensationValueRegionalReportFact
          }
          planCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8']
              .regionalCompensationValueRegionalReportPlanChecker
          }
          planCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8']
              .regionalCompensationValueRegionalReportPlanCorrect
          }
          factCheckerFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8']
              .regionalCompensationValueRegionalReportFactChecker
          }
          factCorrectFormName={
            dataOnFulfillmentOfTermsSZPKBlockValues['8']
              .regionalCompensationValueRegionalReportFactCorrect
          }
          planCorrectRules={
            thirdSectionValidationMap.regionalCompensationValueRegionalReportPlanCorrect
          }
          factCorrectRules={
            thirdSectionValidationMap.regionalCompensationValueRegionalReportFactCorrect
          }
        />

        <SzpkReportInfoGroup
          isSzpkReportsFederal={false}
          isSzpkReportsMustBeWithinMonitoringDates={false}
          capitalValueGroupCardProps={{
            groupTitle: 'Объем возмещенных затрат',
            planAppendix: 'compensationValuePlan',
            planCheckerAppendix: 'compensationValuePlanChecker',
            factAppendix: 'compensationValueFact',
            factCheckerAppendix: 'compensationValueFactChecker',
          }}
          groupProps={{
            disableBottomBorder: true,
          }}
        />
      </Stack>
    </Container>
  )
}

export default Third
