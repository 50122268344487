import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectOption,
  AAgreementOnCreationOtherEstateObjectsFormValues,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const objectNamePermissionsValidationMap: FormValuesValidationSection<
  keyof AAgreementOnCreationOtherEstateObjectOption,
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  objectOption: {
    required: defaultRHFValidation.required,
  },
}

export { objectNamePermissionsValidationMap }
