import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import {
  FormModalStatementWithInitialMenuProps,
} from '@components/DocumentFormComponents/types'
import {
  aAgreementForceCircumstancesBlockValues,
  mapOfAAgreementForceCircumstancesMenu,
} from '@components/Forms/AAgreementForceCircumstancesForm/const'
import AAgreementForceCircumstancesForms from '@components/Forms/AAgreementForceCircumstancesForm/Forms'
import { useAAgreementForceCircumstancesManager } from '@components/Forms/AAgreementForceCircumstancesForm/Manager'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'

const mapOfAAgreementForceCircumstancesForms: FormItemProps = {
  '1': {
    node: <AAgreementForceCircumstancesForms.One />,
  },
  '2': {
    node: <AAgreementForceCircumstancesForms.Two />,
  },
  '3': {
    node: <AAgreementForceCircumstancesForms.Three />,
  },
  '4': {
    node: <AAgreementForceCircumstancesForms.Four />,
  },
  '5': {
    node: <AAgreementForceCircumstancesForms.Five />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '6': {
    node: <AAgreementForceCircumstancesForms.Six />,
  },
  '7': {
    node: <AAgreementForceCircumstancesForms.Seven />,
  },
} as const

const AAgreementForceCircumstancesForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const isFederalSZPK = useWatch({
    name: aAgreementForceCircumstancesBlockValues.additionalFields.isFederal,
    control: formInstance.control
  })
  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementForceCircumstancesBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control
  }).length

  const {
    state: { formIsLoading },
  } = useAAgreementForceCircumstancesManager()

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAAgreementForceCircumstancesForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={initialMenu ?? mapOfAAgreementForceCircumstancesMenu}
      mapOfForms={currentForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(AAgreementForceCircumstancesForm)
