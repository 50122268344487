import React, { FC, forwardRef } from 'react'
import type { ListProps } from 'react-virtuoso'

interface VirtuosoListProps extends ListProps {
  className?: string
}

const VirtuosoList: FC<VirtuosoListProps> = forwardRef(({ ...props }, ref) => {
  return <div {...props} ref={ref} />
})

export default VirtuosoList
