import env from '@config'
import { createSlice } from '@reduxjs/toolkit'
import DayjsService from '@services/Dayjs/Dayjs.service'
import store from 'store2'

import { IInitialTechnicalWork } from './TechnicalWork.entity'

const initialState: IInitialTechnicalWork = {
  isSetup: false,
  isRunning: false,
  fallbackStatus: false,
  startDate: env.REACT_APP_TECHNICAL_WORK_DATE_START,
  endDate: env.REACT_APP_TECHNICAL_WORK_DATE_END,
  timeToStart: {
    days: null,
    hours: null,
    minutes: null,
    chosen: null,
  },
  timeToEnd: {
    days: null,
    hours: null,
    minutes: null,
    chosen: null,
  },
}

const slice = createSlice({
  initialState,
  name: 'TechnicalWork',
  reducers: {
    initializeState: (state) => {
      //Если дата не пришла, или не валидна, обнуляется state  c переменных и выход из исполнения

      //Если указан определённый ключ в SessionStorage, то пропускать пользователя через технические работы
      const isAccessToIgnoreTW = store.session.has('needTestAccess')
      if (isAccessToIgnoreTW) return

      state.isSetup = !!(state.startDate && state.endDate)

      const startDateFromDayjs = DayjsService.dayjs(state.startDate)
      const endDateFromDayJs = DayjsService.dayjs(state.endDate)
      const dateNow = DayjsService.dayjsWithFormatToMSK()

      const dateIsInvalid = !startDateFromDayjs.isValid() || !endDateFromDayJs.isValid()
      const startIsBiggerThanEnd = endDateFromDayJs.diff(startDateFromDayjs, 's') < 0

      const technicalWorkIsNotIndicated = !state.startDate && !state.endDate
      const technicalWorkIsEnd = !dateIsInvalid && endDateFromDayJs < dateNow

      //Фолбек на случай, если неправильно указали сроки, но технические работы должны блокировать вход в ЛК
      if (!technicalWorkIsNotIndicated && (startIsBiggerThanEnd || dateIsInvalid)) {
        state.fallbackStatus = true
        state.isRunning = true
      }

      // Обнуляет даты, UI не рисует технические работы, но фолбек выше отрабатывает
      if (
        technicalWorkIsNotIndicated ||
        dateIsInvalid ||
        startIsBiggerThanEnd ||
        technicalWorkIsEnd
      ) {
        state.startDate = undefined
        state.endDate = undefined

        return
      }

      const formattedStartDate = startDateFromDayjs.format()
      const formattedEndDate = endDateFromDayJs.format()

      const diffInSecondsBetweenNowAndStartDate = dateNow.diff(formattedStartDate, 's')
      const diffInSecondsBetweenNowAndEndDate = dateNow.diff(formattedEndDate, 's')

      //Технические работы ещё не начались
      if (diffInSecondsBetweenNowAndStartDate < 0) {
        const diffInMinutesBetweenNowAndStartDate = dateNow.diff(formattedStartDate, 'm')

        const diffInHoursBetweenNowAndStartDate = dateNow.diff(formattedStartDate, 'h')

        const diffInDaysBetweenNowAndStartDate = dateNow.diff(formattedStartDate, 'd')

        //Ставиться будет только активный промежуток, который впоследствии будет отображаться в компонентах
        if (diffInMinutesBetweenNowAndStartDate < -60 && diffInDaysBetweenNowAndStartDate === 0) {
          state.timeToStart.hours = Math.abs(diffInHoursBetweenNowAndStartDate)
          state.timeToStart.chosen = 'hours'
        } else if (
          diffInDaysBetweenNowAndStartDate < 0 &&
          diffInHoursBetweenNowAndStartDate <= -24
        ) {
          state.timeToStart.days = Math.abs(dateNow.diff(formattedStartDate, 'd'))
          state.timeToStart.chosen = 'days'
        } else {
          state.timeToStart.minutes = Math.abs(diffInMinutesBetweenNowAndStartDate)
          state.timeToStart.chosen = 'minutes'
        }
      }

      //Условие, что текущий промежуток попадает в промежуток технических работ
      if (diffInSecondsBetweenNowAndStartDate > 0 && diffInSecondsBetweenNowAndEndDate < 0) {
        const hoursToEnd = endDateFromDayJs.diff(dateNow, 'h')
        const minutesToEnd = endDateFromDayJs.diff(dateNow, 'm')
        const daysToEnd = endDateFromDayJs.diff(dateNow, 'd')

        if (minutesToEnd < 60 && hoursToEnd < 1) {
          state.timeToEnd.minutes = minutesToEnd
          state.timeToEnd.chosen = 'minutes'
        } else if (daysToEnd <= 0 && hoursToEnd <= 24) {
          state.timeToEnd.hours = hoursToEnd
          state.timeToEnd.chosen = 'hours'
        } else {
          state.timeToEnd.days = daysToEnd
          state.timeToEnd.chosen = 'days'
        }

        state.timeToStart.days = null
        state.timeToStart.minutes = null
        state.timeToStart.hours = null
        state.timeToStart.chosen = null

        state.isRunning = true
      }
    },
  },
})

const TechnicalWorkActions = slice.actions

export { TechnicalWorkActions }

export default slice
