import React, { FC, memo } from 'react'

import Attachments from '@components/Attachments'
import AttachmentsSetActions from '@components/Attachments/Set/AttachmentsSetActions'
import CustomSetHeader from '@components/Attachments/Set/CustomSetHeader'
import DefaultSetHeader from '@components/Attachments/Set/DefaultSetHeader'
import { IModifiedDocumentSetInfoForRender } from '@services/Documents/documents.entity'

import styles from './Container.module.scss'

interface AttachmentContainerProps {
  typeOfSets: string
  currentDfoId: string
  initialDocuments: IModifiedDocumentSetInfoForRender
  isChangesMadeTab?: boolean
  digitizingInProcess?: boolean
  disableSetOfName?: boolean
  showChangedSetDocuments?: boolean
}

const AttachmentsSet: FC<AttachmentContainerProps> = ({
  disableSetOfName,
  showChangedSetDocuments,
  initialDocuments,
  typeOfSets,
  currentDfoId,
  isChangesMadeTab,
  digitizingInProcess,
}) => {
  const {
    documentSetTitle,
    documentSetSubtitle,
    documentSetIcon,
    documentTypeInfo,
    ownerOrganizationName,
    ownerOrganizationFullName,
    permissions,
    documentSetId,
  } = initialDocuments

  return (
    <section>
      {!disableSetOfName && (
        <div className={styles.attachmentHeader}>
          {documentSetIcon ? (
            <CustomSetHeader
              documentSetIcon={documentSetIcon}
              subtitle={documentSetSubtitle}
              title={documentSetTitle}
              organizationOwnerName={ownerOrganizationName || ''}
              organizationFullOwnerName={ownerOrganizationFullName}
            />
          ) : (
            <DefaultSetHeader title={documentSetTitle} subtitle={documentSetSubtitle} />
          )}
          <AttachmentsSetActions typeOfSets={typeOfSets} />
        </div>
      )}
      <Attachments.SetItems
        typeOfSets={typeOfSets}
        documentSetId={documentSetId}
        showChangedSetDocuments={showChangedSetDocuments}
        permissions={permissions}
        currentDfoId={currentDfoId}
        documentTypeInfo={documentTypeInfo}
        isChangesMadeTab={isChangesMadeTab}
        digitizingInProcess={digitizingInProcess}
      />
    </section>
  )
}

export default memo(AttachmentsSet)
