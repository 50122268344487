import React, { FC } from 'react'

import styles from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/WebFormDocumentItem.module.scss'
import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { Tooltip, TooltipProps } from '@components/NewDesign/Tooltip'
import { isNullOrUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

interface WebFormDocumentFormButtonProps extends Omit<ButtonProps, 'onClick'> {
  tooltipProps?: Omit<TooltipProps, 'children'>
  disabled?: boolean
  dataTestId?: string
  onClick?: (
    event?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void
}

const WebFormDocumentFormButton: FC<WebFormDocumentFormButtonProps> = ({
  variant = 'buttonSMedium',
  size = 's',
  view = 'tined',
  fixWidth = true,
  loaderProps,
  onClick,
  children,
  tooltipProps,
  dataTestId,
  ...restButtonProps
}) => {
  const {
    position = 'top',
    popperClassName,
    contentClassName,
    arrowClassName,
    popoverClassName,
    targetClassName,
    content,
    ...restTooltipProps
  } = tooltipProps || {}

  const {
    placement = 'leading',
    className: loaderOpenFormButtonClassName,
    ...restLoaderProps
  } = loaderProps || {}

  const buttonElement = (
    <Button
      {...(restButtonProps as ButtonProps)}
      fixWidth={fixWidth}
      variant={variant}
      size={size}
      view={view}
      dataTestId={dataTestId}
      loaderProps={{
        ...restLoaderProps,
        placement,
        className: cn(
          styles['webFormDocumentItem__action-buttonLoader'],
          loaderOpenFormButtonClassName,
        ),
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  )

  return !isNullOrUndefined(content) ? (
    <Tooltip
      {...(restTooltipProps as TooltipProps)}
      content={content}
      position={position}
      arrowClassName={cn(styles.tooltip__arrow, arrowClassName)}
      popoverClassName={cn(styles.tooltip__popover, popoverClassName)}
      contentClassName={cn(styles.tooltip__content, contentClassName)}
      targetClassName={cn(styles.tooltip__target, targetClassName)}
      popperClassName={cn(styles.tooltip__popper, popperClassName)}
    >
      {buttonElement}
    </Tooltip>
  ) : (
    buttonElement
  )
}

export default WebFormDocumentFormButton
