import * as React from 'react'

import { isString } from '@helpers/checkTypes'
import classNames from 'classnames'

import { BsPrefixProps, BsPrefixRefForwardingComponent } from '../helpers'
import { useBootstrapPrefix } from '../ThemeProvider'

export interface ContainerProps extends BsPrefixProps, React.HTMLAttributes<HTMLElement> {
  fluid?: boolean | string | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
}

const Container: BsPrefixRefForwardingComponent<'div', ContainerProps> = React.forwardRef<
  HTMLElement,
  ContainerProps
>(
  (
    {
      bsPrefix,
      fluid = false,
      // Need to define the default "as" during prop destructuring to be compatible with styled-components github.com/react-bootstrap/react-bootstrap/issues/3595
      as: Component = 'div',
      className,
      ...props
    },
    ref,
  ) => {
    const prefix = useBootstrapPrefix(bsPrefix, 'container')
    const suffix = isString(fluid) ? `-${fluid}` : '-fluid'
    return (
      <Component
        ref={ref}
        {...props}
        className={classNames(className, fluid ? `${prefix}${suffix}` : prefix)}
      />
    )
  },
)

Container.displayName = 'Container'

export default Container
