import { defaultIndicatorColors, defaultIndicatorOptions } from './const'
import { IndicatorColorInfo, IndicatorOption, IndicatorOptions } from './types'

const getMapOfIndicatorOptionsByActiveColor = (
  options: IndicatorOptions,
  colors: IndicatorColorInfo[],
) =>
  colors.reduce<Record<IndicatorColorInfo['activeColor'], IndicatorOption>>((acc, color) => {
    const currentOption = options.find((option) => option.activeColor === color.activeColor)
    if (currentOption) acc[color.activeColor] = currentOption
    return acc
  }, {})

const defaultMapOfIndicatorOptionsByActiveColor = getMapOfIndicatorOptionsByActiveColor(
  defaultIndicatorOptions,
  Object.values(defaultIndicatorColors),
)

export { defaultMapOfIndicatorOptionsByActiveColor, getMapOfIndicatorOptionsByActiveColor }
