import React, { ChangeEvent, FC, memo, useCallback, useEffect, useRef } from 'react'
import { PopupProps } from 'react-popup-manager'

import Icon from '@components/Icon'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import Input from '@components/NewDesign/Input'
import Modal from '@components/NewDesign/Modal'
import { useHierarchyReferenceBookManager } from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Manager'
import HierarchyReferenceBookList from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Modal/List'
import Typography from '@components/NewDesign/Typography'
import { referenceBookTitlesMap } from '@components/ReferenceBooks/constants'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import searchIcon from '@icons/action/search.svg'
import closeIcon from '@icons/navigation/close.svg'
import cn from 'classnames'

import styles from './Modal.module.scss'

interface HierarchyReferenceBookModalProps extends Partial<PopupProps> {
  disabled: boolean
  onSelect: (referenceBookItem: ReferenceBookItem) => void
  onChange: (referenceBookItem: ReferenceBookItem) => void
}

const HierarchyReferenceBookModal: FC<HierarchyReferenceBookModalProps> = ({
  disabled,
  onSelect,
  onChange,
  onClose,
  ...rest
}) => {
  const { state, refs, handlers } = useHierarchyReferenceBookManager()

  const initialFocusRef = useRef(null)
  const currentValueOfSelect = useRef<ReferenceBookItem | null>(null)

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    handlers.onChangeSearchPattern?.(e.target.value)

  const handleAddButton = () => {
    if (currentValueOfSelect.current) {
      onChange?.(currentValueOfSelect.current)
    }

    onClose?.()
  }

  const bindSelectValue = useCallback((referenceBookItem: ReferenceBookItem) => {
    currentValueOfSelect.current = referenceBookItem

    onSelect(referenceBookItem)
  }, [])

  const bindContentModalRef = (ref: HTMLDivElement | null) => {
    if (!refs.contentModalRef || !ref) return

    refs.contentModalRef.current = ref
  }

  useEffect(() => {
    handlers.onModalMount?.()
  }, [])

  return (
    <Modal.Base
      contentClassName={styles.modal}
      containerClassName={styles.modalWrapper}
      initialFocus={initialFocusRef}
      onClose={onClose}
      {...rest}
    >
      <IconButton
        accent
        size={'s'}
        geometry={'round'}
        view={'basic'}
        icon={{ src: closeIcon }}
        className={styles.modalClose}
        onClick={onClose}
      />
      <div className={styles.modalHeader}>
        <Typography.Headline variant="headlineH2">
          Справочник {referenceBookTitlesMap[state.dictionaryName || '']}
        </Typography.Headline>
      </div>
      <div className={styles.modalSearch}>
        <Input
          fixWidth
          ref={initialFocusRef}
          view={'secondary'}
          value={state.searchPattern}
          label={'Введите ключевое слово или номер кода'}
          dataTestId={'modalSearch'}
          leftAddons={<Icon src={searchIcon} size="m" />}
          onChange={onSearchInputChange}
        />
      </div>
      <div
        ref={bindContentModalRef}
        className={cn(styles.modalContent, {
          [styles.modalContent__disableScroll]: state.threeIsLoading,
        })}
      >
        <HierarchyReferenceBookList onReferenceBookItemChange={bindSelectValue} />
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.modalFooter__buttons}>
          <Button geometry={'square'} view={'outline'} size="m" onClick={onClose}>
            Отмена
          </Button>
          <Button
            geometry={'square'}
            size="m"
            disabled={disabled || !state.selectedItemId}
            onClick={handleAddButton}
          >
            Добавить
          </Button>
        </div>
      </div>
    </Modal.Base>
  )
}

export type { HierarchyReferenceBookModalProps }
export default memo(HierarchyReferenceBookModal)
