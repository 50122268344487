import { TPropertyValueRequired } from '@services/Properties/Properties.entity'

class BasePropertyState {
  private readonly _propertyKey: string = ''
  private readonly _propertyBase: TPropertyValueRequired | null = null

  constructor(propertyKey: string, property: TPropertyValueRequired) {
    this._propertyBase = property
    this._propertyKey = propertyKey
  }

  get getLastUpdateDate() {
    return this._propertyBase?.['@lastUpdateDt']
  }

  get getPropertyId() {
    return this._propertyBase?.['@id']
  }

  get getOrder() {
    return this._propertyBase?.['@order']
  }

  get getIsPublic() {
    return this._propertyBase?.['@isPublic']
  }

  get getPropertyKey() {
    return this._propertyKey
  }

  get getPermanentId() {
    return this._propertyBase?.['@permanentId']
  }

  setLastUpdateDt(newValue: string) {
    if (!this._propertyBase) return
    this._propertyBase['@lastUpdateDt'] = newValue
  }

  setOrder(newValue: number) {
    if (!this._propertyBase) return
    this._propertyBase['@order'] = newValue
  }
}

export { BasePropertyState }
