import { useAppDispatch } from '@store'

import {
  IFolderChangeState,
  IFolderInitializeEntity,
  TInitialStateOfFolderStore,
} from './FolderState.entity'
import { FolderStateActions } from './FolderState.store'

const useFolderStateManager = () => {
  const dispatch = useAppDispatch()

  const initializeFolderState = (payload: IFolderInitializeEntity) => {
    return dispatch(FolderStateActions.initializeFolderState(payload))
  }

  const replaceFoldersState = (payload: TInitialStateOfFolderStore) => {
    return dispatch(FolderStateActions.replaceFoldersState(payload))
  }

  const changeFolderState = (payload: IFolderChangeState) => {
    return dispatch(FolderStateActions.changeFolderState(payload))
  }

  const removeFolder = (payload: Omit<IFolderChangeState, 'newFolderState'>) => {
    return dispatch(FolderStateActions.removeFolder(payload))
  }

  return {
    initializeFolderState,
    changeFolderState,
    replaceFoldersState,
    removeFolder,
  }
}

export { useFolderStateManager }
