import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { ApplicationInclusionOfAARegisterSZPKFormValues } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/types'
import { lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof ApplicationInclusionOfAARegisterSZPKFormValues['2'],
  ApplicationInclusionOfAARegisterSZPKFormValues
> = {
  extraStatementName: {
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { secondSectionValidationMap }
