import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfStatementConsiderationInvestmentAuthorizedMenu } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/const'
import StatementConsiderationInvestmentAuthorizedForms from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/Forms'
import { useStatementConsiderationInvestmentAuthorizedManager } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/Manager'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'

const mapOfStatementConsiderationInvestmentAuthorizedForms: FormItemProps = {
  '1': {
    node: <StatementConsiderationInvestmentAuthorizedForms.One />,
  },
  '2': {
    node: <StatementConsiderationInvestmentAuthorizedForms.Two />,
  },
} as const

const StatementConsiderationInvestmentAuthorizedForm: FC<FormModalStatementProps> = ({
  ...rest
}) => {
  const formInstance = useFormContext<StatementConsiderationInvestmentAuthorizedFormValues>()

  const {
    state: { formIsLoading },
  } = useStatementConsiderationInvestmentAuthorizedManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfStatementConsiderationInvestmentAuthorizedMenu}
      mapOfForms={mapOfStatementConsiderationInvestmentAuthorizedForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(StatementConsiderationInvestmentAuthorizedForm)
