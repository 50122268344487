import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof AdditionalAgreementOnTerminationFfSZPKFormValues['4'],
  AdditionalAgreementOnTerminationFfSZPKFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionValidationMap }
