import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react'

import { hideContent, showContent } from '@components/Collapse/helpers'
import { isNumberFromString } from '@helpers/checkTypes'
import classNames from 'classnames'

export type CollapseHeightProps = 'auto' | number | `${number}%`
type TimeoutProps = ReturnType<typeof setTimeout>
type OverflowProps = 'auto' | 'visible' | 'hidden' | undefined
type AnimationStateClasses = {
  animating: string
  animatingUp: string
  animatingDown: string
  animatingToHeightZero: string
  animatingToHeightAuto: string
  animatingToHeightSpecific: string
  static: string
  staticHeightZero: string
  staticHeightAuto: string
  staticHeightSpecific: string
}

const ANIMATION_STATE_CLASSES: AnimationStateClasses = {
  animating: 'rah-animating',
  animatingUp: 'rah-animating--up',
  animatingDown: 'rah-animating--down',
  animatingToHeightZero: 'rah-animating--to-height-zero',
  animatingToHeightAuto: 'rah-animating--to-height-auto',
  animatingToHeightSpecific: 'rah-animating--to-height-specific',
  static: 'rah-static',
  staticHeightZero: 'rah-static--height-zero',
  staticHeightAuto: 'rah-static--height-auto',
  staticHeightSpecific: 'rah-static--height-specific',
}

const isPercentage = (height: CollapseHeightProps) => {
  return (
    typeof height === 'string' &&
    height[height.length - 1] === '%' &&
    isNumberFromString(height.substring(0, height.length - 1))
  )
}

const getStaticStateClasses = (
  animationStateClasses: AnimationStateClasses,
  height: CollapseHeightProps,
) => {
  return classNames({
    [animationStateClasses.static]: true,
    [animationStateClasses.staticHeightZero]: height === 0,
    [animationStateClasses.staticHeightSpecific]: height > 0,
    [animationStateClasses.staticHeightAuto]: height === 'auto',
  })
}

type OmitCSSProperties = 'display' | 'height'

export interface CollapseProps extends React.HTMLAttributes<HTMLDivElement> {
  animateOpacity?: boolean
  animationStateClasses?: AnimationStateClasses
  applyInlineTransitions?: boolean
  contentClassName?: string
  delay?: number
  duration?: number
  easing?: string
  height: CollapseHeightProps
  onHeightAnimationEnd?: (newHeight: CollapseHeightProps) => any
  onHeightAnimationStart?: (newHeight: CollapseHeightProps) => any
  style?: Omit<CSSProperties, OmitCSSProperties>
  dataTestId?: string
}

const AnimateHeight: FC<CollapseProps> = ({
  animateOpacity = false,
  animationStateClasses = {},
  applyInlineTransitions = true,
  children,
  className = '',
  contentClassName,
  delay: userDelay = 0,
  duration: userDuration = 500,
  easing = 'ease',
  height,
  onHeightAnimationEnd,
  onHeightAnimationStart,
  style,
  dataTestId,
  ...props
}) => {
  const prevHeight = useRef<CollapseHeightProps>(height)
  const contentElement = useRef<HTMLDivElement>(null)

  const ref = useRef<HTMLDivElement>(null)

  const animationClassesTimeoutID = useRef<TimeoutProps>()
  const timeoutID = useRef<TimeoutProps>()

  const stateClasses = useRef<AnimationStateClasses>({
    ...ANIMATION_STATE_CLASSES,
    ...animationStateClasses,
  })

  const isBrowser = typeof window !== 'undefined'

  const prefersReducedMotion = useRef<boolean>(
    isBrowser && window.matchMedia ? window.matchMedia('(prefers-reduced-motion)').matches : false,
  )

  const delay = prefersReducedMotion.current ? 0 : userDelay
  const duration = prefersReducedMotion.current ? 0 : userDuration

  let initHeight: CollapseHeightProps = height
  let initOverflow: OverflowProps = 'visible'

  if (typeof initHeight === 'number') {
    initHeight = height < 0 ? 0 : height
    initOverflow = 'hidden'
  } else if (isPercentage(initHeight)) {
    initHeight = height === '0%' ? 0 : height
    initOverflow = 'hidden'
  }

  const [currentHeight, setCurrentHeight] = useState<CollapseHeightProps>(initHeight)
  const [overflow, setOverflow] = useState<OverflowProps>(initOverflow)
  const [useTransitions, setUseTransitions] = useState<boolean>(false)
  const [animationStateClassNames, setAnimationStateClassNames] = useState<string>(
    getStaticStateClasses(stateClasses.current, height),
  )

  useEffect(() => {
    hideContent(contentElement.current, currentHeight)
  }, [currentHeight])

  useEffect(() => {
    if (height !== prevHeight.current && contentElement.current) {
      showContent(contentElement.current, prevHeight.current)

      contentElement.current.style.overflow = 'hidden'
      const contentHeight = contentElement.current.offsetHeight
      contentElement.current.style.overflow = ''

      const totalDuration = duration + delay

      let newHeight: CollapseHeightProps
      let timeoutHeight: CollapseHeightProps
      let timeoutOverflow: OverflowProps = 'hidden'
      let timeoutUseTransitions: boolean

      const isCurrentHeightAuto = prevHeight.current === 'auto'

      if (typeof height === 'number') {
        newHeight = height < 0 ? 0 : height
        timeoutHeight = newHeight
      } else if (isPercentage(height)) {
        newHeight = height === '0%' ? 0 : height
        timeoutHeight = newHeight
      } else {
        newHeight = contentHeight
        timeoutHeight = 'auto'
        timeoutOverflow = undefined
      }

      if (isCurrentHeightAuto) {
        timeoutHeight = newHeight

        newHeight = contentHeight
      }

      const newAnimationStateClassNames = classNames({
        [stateClasses.current.animating]: true,
        [stateClasses.current.animatingUp]:
          prevHeight.current === 'auto' || height < prevHeight.current,
        [stateClasses.current.animatingDown]: height === 'auto' || height > prevHeight.current,
        [stateClasses.current.animatingToHeightZero]: timeoutHeight === 0,
        [stateClasses.current.animatingToHeightAuto]: timeoutHeight === 'auto',
        [stateClasses.current.animatingToHeightSpecific]: timeoutHeight > 0,
      })

      const timeoutAnimationStateClasses = getStaticStateClasses(
        stateClasses.current,
        timeoutHeight,
      )

      setCurrentHeight(newHeight)
      setOverflow('hidden')
      setUseTransitions(!isCurrentHeightAuto)
      setAnimationStateClassNames(newAnimationStateClassNames)

      clearTimeout(timeoutID.current as TimeoutProps)
      clearTimeout(animationClassesTimeoutID.current as TimeoutProps)

      if (isCurrentHeightAuto) {
        timeoutUseTransitions = true

        timeoutID.current = setTimeout(() => {
          setCurrentHeight(timeoutHeight)
          setOverflow(timeoutOverflow)
          setUseTransitions(timeoutUseTransitions)

          onHeightAnimationStart?.(timeoutHeight)
        }, 50)

        animationClassesTimeoutID.current = setTimeout(() => {
          setUseTransitions(false)
          setAnimationStateClassNames(timeoutAnimationStateClasses)

          hideContent(contentElement.current, timeoutHeight)

          onHeightAnimationEnd?.(timeoutHeight)
        }, totalDuration)
      } else {
        onHeightAnimationStart?.(newHeight)

        timeoutID.current = setTimeout(() => {
          setCurrentHeight(timeoutHeight)
          setOverflow(timeoutOverflow)
          setUseTransitions(false)
          setAnimationStateClassNames(timeoutAnimationStateClasses)

          if (height !== 'auto') {
            hideContent(contentElement.current, newHeight)
          }

          onHeightAnimationEnd?.(newHeight)
        }, totalDuration)
      }
    }

    prevHeight.current = height

    return () => {
      clearTimeout(timeoutID.current as TimeoutProps)
      clearTimeout(animationClassesTimeoutID.current as TimeoutProps)
    }
  }, [height])

  const preparedComponentStyles = useMemo(() => {
    const componentStyle: CSSProperties = {
      ...style,
      height: currentHeight,
      overflow: overflow || style?.overflow,
    }

    if (useTransitions && applyInlineTransitions) {
      componentStyle.transition = `height ${duration}ms ${easing} ${delay}ms`

      if (style?.transition) {
        componentStyle.transition = `${style.transition}, ${componentStyle.transition}`
      }

      componentStyle.WebkitTransition = componentStyle.transition
    }

    return componentStyle
  }, [
    applyInlineTransitions,
    currentHeight,
    delay,
    duration,
    easing,
    overflow,
    style,
    useTransitions,
  ])

  const preparedContentStyles = useMemo(() => {
    const contentStyle: CSSProperties = {}

    if (animateOpacity) {
      contentStyle.transition = `opacity ${duration}ms ${easing} ${delay}ms`

      contentStyle.WebkitTransition = contentStyle.transition

      if (currentHeight === 0) {
        contentStyle.opacity = 0
      }
    }

    return contentStyle
  }, [animateOpacity, currentHeight, delay, duration, easing])

  const hasAriaHiddenProp = typeof props['aria-hidden'] !== 'undefined'
  const ariaHidden = hasAriaHiddenProp ? props['aria-hidden'] : height === 0

  return (
    <div
      ref={ref}
      {...props}
      data-testid={dataTestId}
      aria-hidden={ariaHidden}
      className={`${animationStateClassNames} ${className}`}
      style={preparedComponentStyles}
    >
      <div className={contentClassName} style={preparedContentStyles} ref={contentElement}>
        {children}
      </div>
    </div>
  )
}

export default AnimateHeight
