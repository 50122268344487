import React, { FC, memo } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import ChooseModalManagerOld from '@components/RegistryNpa/ChooseOld/ManagerOld'
import type { ChosenPart } from '@components/RegistryNpa/ChooseOld/ManagerOld/types'
import RegistryNpaChooseModalOld from '@components/RegistryNpa/ChooseOld/ModalOld'
import { IFiltersFormValues } from '@components/RegistryNpa/Manager'

interface IDefaultStatementUrlParams {
  projectId: string
  dfoId: string
  documentSetId: string
}

export type RegistryNpaChooseComponentProps = IDefaultStatementUrlParams & {
  onUpdateNpaLayout: VoidFunction
  initialFilters?: Partial<IFiltersFormValues['filters']>
  chosenParts?: ChosenPart[]
  excludedPartIds?: string[]
}

type RegistryNPAChooseOld = PopupProps & RegistryNpaChooseComponentProps

const useRegistryNpaChooseOldModal = () => {
  const popupManager = usePopupManager()

  const handleOpenRegistryNpaChooseOldModal = (props: RegistryNpaChooseComponentProps) => {
    popupManager.open(RegistryNPAChooseOld, props)
  }

  return {
    handleOpenRegistryNpaChooseOldModal,
  }
}

const RegistryNPAChooseOld: FC<RegistryNPAChooseOld> = ({ isOpen, onClose, ...managerProps }) => {
  return (
    <ChooseModalManagerOld onClose={onClose} {...managerProps}>
      <RegistryNpaChooseModalOld isOpen={isOpen} onClose={onClose} />
    </ChooseModalManagerOld>
  )
}

export { useRegistryNpaChooseOldModal }
export default memo(RegistryNPAChooseOld)
