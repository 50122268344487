import React, { FC } from 'react'

import type { OptionProps, OptionsVariant } from '@components/NewDesign/Select/model'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import { isString } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './OptionItem.module.scss'

interface OptionComponentProps extends OptionProps {
  variant?: OptionsVariant
  onClick: (value: OptionProps) => void
  selected: OptionProps | OptionProps[] | null
  optionClassName?: string
  textClassName?: string
}

const OptionItem: FC<OptionComponentProps> = ({
  variant = 'primary',
  displayValue,
  label,
  value,
  children,
  selected,
  dataTestId,
  optionClassName,
  textClassName,
  onClick,
}) => {
  const isOptionSelected = Array.isArray(selected)
    ? !!selected?.find((selectedItem) => selectedItem.value === value)
    : selected?.value === value

  const handleOptionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    onClick({
      value,
      displayValue,
      label,
    })
  }

  if (isString(children)) {
    return (
      <div
        key={displayValue}
        role={'none'}
        className={cn(styles.optionItem, optionClassName, {
          [styles[`optionItem--${variant}`]]: variant,
          [styles['optionItem-selected']]: isOptionSelected,
          [styles[`optionItem-selected--${variant}`]]: isOptionSelected && variant,
        })}
      >
        <TypographyWithTooltip
          variant={'bodyMRegular'}
          tooltipProps={{
            targetClassName: styles.tooltip__target,
            contentClassName: styles.tooltip__content,
            popoverClassName: styles.tooltip__popover,
            fallbackPlacements: ['left', 'right', 'top', 'bottom'],
          }}
          className={cn(styles.optionItem__text, textClassName, {
            [styles[`optionItem__text--${variant}`]]: variant,
            [styles['optionItem__text-selected']]: isOptionSelected,
            [styles[`optionItem__text-selected--${variant}`]]: isOptionSelected && variant,
          })}
          onClick={handleOptionClick}
        >
          {children}
        </TypographyWithTooltip>
      </div>
    )
  }

  return (
    <div
      key={displayValue}
      role={'none'}
      data-testid={dataTestId}
      className={cn(styles.optionItem, optionClassName, {
        [styles[`optionItem--${variant}`]]: variant,
        [styles['optionItem-selected']]: isOptionSelected,
        [styles[`optionItem-selected--${variant}`]]: isOptionSelected && variant,
      })}
      onClick={handleOptionClick}
    >
      {children}
    </div>
  )
}

export default OptionItem
