import React, { memo } from 'react'

import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import DataView from '@components/Projects/[id]/ParticipantsOfStatement/DataView'
import { useParticipantsManager } from '@components/Projects/[id]/ParticipantsOfStatement/Manager'
import cn from 'classnames'

import styles from './ParticipantsViewMode.module.scss'

const ViewParticipantsOfStatementMode = () => {
  const {
    viewParticipantsState: {
      OIVMembers,
      OMSUMembers,
      OIVExtraViewMembers,
      isFederal,
      MERMembers,
      investorInfo,
    },
  } = useParticipantsManager()

  const OIVSubtitleConditionToRegional =
    !OIVExtraViewMembers || !OIVExtraViewMembers?.length
      ? 'Уполномоченный орган субъекта РФ'
      : 'Уполномоченный орган субъекта РФ (координатор)'

  const preparedOIVSubtitle = isFederal
    ? 'Уполномоченный орган субъекта РФ'
    : OIVSubtitleConditionToRegional

  return (
    <div className={styles.participantsOfStatementView}>
      <Typography.Body variant={'bodyLMedium'}>Участники соглашения</Typography.Body>
      <div
        className={cn(styles.participantsOfStatementView__content, {
          [styles['participantsOfStatementView__content--baseline']]: isFederal,
        })}
      >
        {investorInfo?.name && (
          <div className={styles['participantsOfStatementView__content-leftPart']}>
            <DataView suptitle={'Инвестор'}>{investorInfo.name}</DataView>
          </div>
        )}
        {isFederal ? (
          MERMembers.map((merMember, index) => {
            return (
              <div
                key={merMember.name}
                className={cn(styles['participantsOfStatementView__content-rightPart'])}
              >
                {merMember.fullName ? (
                  <Tooltip
                    position="top-start"
                    offset={[0, 2]}
                    content={merMember.fullName}
                    targetClassName={styles.tooltip__target}
                    popoverClassName={styles.tooltip__popover}
                    arrowClassName={styles.tooltip__arrow}
                  >
                    <DataView key={index} suptitle="Федеральный орган исполнительной власти">
                      {merMember.name}
                    </DataView>
                  </Tooltip>
                ) : (
                  <DataView key={index} suptitle="Федеральный орган исполнительной власти">
                    {merMember.name}
                  </DataView>
                )}
              </div>
            )
          })
        ) : (
          <div />
        )}
        {OIVMembers.map((oivMember, index) => {
          const isRightSideElement = index % 2

          return (
            <div
              key={oivMember.name}
              className={cn(styles.participantsOfStatementView__item, {
                [styles['participantsOfStatementView__content-rightPart']]: isRightSideElement,
                [styles['participantsOfStatementView__content-leftPart']]: !isRightSideElement,
              })}
            >
              {oivMember.fullName ? (
                <Tooltip
                  position="top-start"
                  offset={[0, 2]}
                  content={oivMember.fullName}
                  targetClassName={styles.tooltip__target}
                  popoverClassName={styles.tooltip__popover}
                  arrowClassName={styles.tooltip__arrow}
                >
                  <DataView suptitle={preparedOIVSubtitle}>{oivMember.name}</DataView>
                </Tooltip>
              ) : (
                <DataView suptitle={preparedOIVSubtitle}>{oivMember.name}</DataView>
              )}
            </div>
          )
        })}
        {OMSUMembers.map((omsuMember, index) => {
          const isRightSideElement = (OIVMembers.length + index) % 2

          return (
            <div
              key={omsuMember.name}
              className={cn(styles.participantsOfStatementView__item, {
                [styles['participantsOfStatementView__content-rightPart']]: isRightSideElement,
                [styles['participantsOfStatementView__content-leftPart']]: !isRightSideElement,
              })}
            >
              {omsuMember.fullName ? (
                <Tooltip
                  position="top-start"
                  offset={[0, 2]}
                  content={omsuMember.fullName}
                  targetClassName={styles.tooltip__target}
                  popoverClassName={styles.tooltip__popover}
                  arrowClassName={styles.tooltip__arrow}
                >
                  <DataView suptitle="Уполномоченный орган местного самоуправления">
                    {omsuMember.name}
                  </DataView>
                </Tooltip>
              ) : (
                <DataView suptitle="Уполномоченный орган местного самоуправления">
                  {omsuMember.name}
                </DataView>
              )}
            </div>
          )
        })}
        {!isFederal &&
          OIVExtraViewMembers.map((oivExtraMember, index) => {
            const isLeftSideElement = (OMSUMembers.length + index) % 2

            return (
              <div
                key={oivExtraMember.organizationId}
                className={cn(styles.participantsOfStatementView__item, {
                  [styles['participantsOfStatementView__content-rightPart']]: !isLeftSideElement,
                  [styles['participantsOfStatementView__content-leftPart']]: isLeftSideElement,
                })}
              >
                {oivExtraMember?.fullName ? (
                  <Tooltip
                    position="top-start"
                    offset={[0, 2]}
                    content={oivExtraMember.fullName}
                    targetClassName={styles.tooltip__target}
                    popoverClassName={styles.tooltip__popover}
                    arrowClassName={styles.tooltip__arrow}
                  >
                    <DataView suptitle="Уполномоченный орган субъекта РФ">
                      {oivExtraMember.name}
                    </DataView>
                  </Tooltip>
                ) : (
                  <DataView suptitle="Уполномоченный орган субъекта РФ">
                    {oivExtraMember.name}
                  </DataView>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default memo(ViewParticipantsOfStatementMode)
