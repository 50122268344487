import { VFC } from 'react'
import { To, useNavigate } from 'react-router'

import Icon from '@components/Icon'
import IconButton, { IconButtonProps } from '@components/IconButton'
import arrowBackIcon from '@icons/navigation/arrow_back.svg'
import cn from 'classnames'

import styles from './BackButton.module.scss'

export interface BackButtonProps extends Omit<IconButtonProps, 'icon' | 'onClick'> {
  to?: To
  customColor?: 'surface'
}

const BackButton: VFC<BackButtonProps> = ({ to, children, size, customColor, ...rest }) => {
  const navigate = useNavigate()

  const handleGoBack = () => (to ? navigate(to) : navigate(-1))

  if (children) {
    return (
      <button
        type="button"
        className={cn(styles.backButton, {
          [styles[`backButton--${size}`]]: size,
          [styles[`backButton--${customColor}`]]: customColor,
        })}
        onClick={handleGoBack}
        {...rest}
      >
        <Icon src={arrowBackIcon} className={styles.icon} />
        {children}
      </button>
    )
  }
  return <IconButton icon={arrowBackIcon} size={size} onClick={handleGoBack} {...rest} />
}

export default BackButton
