import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'

import { Paths } from '@constants/paths'
import ReleaseMigrationService from '@services/ReleaseMigration/ReleaseMigration.service'
import { useTechnicalWork } from '@services/TechnicalWork/TechnicalWork.entity'
import { useTechnicalWorkManager } from '@services/TechnicalWork/TechnicalWork.manager'

const releaseMigrationService = ReleaseMigrationService

const InitializeNotAuthenticatedGlobalState: FC = () => {
  const technicalWorkManager = useTechnicalWorkManager()
  const { isRunning } = useTechnicalWork()

  const navigate = useNavigate()

  useEffect(() => {
    technicalWorkManager.initializeState()
    releaseMigrationService.applyAllMigrations()
  }, [])

  useEffect(() => {
    //Сброс идентификации, при технических работах
    if (isRunning) {
      localStorage.removeItem('authState')
      navigate(Paths.Home, { replace: true })
    }
  }, [isRunning])

  return <Outlet />
}

export default InitializeNotAuthenticatedGlobalState
