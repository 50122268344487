import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu } from '@components/DocumentFormComponents/types'

const notificationOfChangeDocumentsAMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Организация, реализующая проект',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'Изменение документов',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
}

const notificationOfChangeDocumentsMenuState = [
  notificationOfChangeDocumentsAMenuHashMap['1'],
  notificationOfChangeDocumentsAMenuHashMap['2'],
]

const mapOfMenu = notificationOfChangeDocumentsMenuState.map(
  (menu): MapOfMenu => ({
    id: menu.id,
    title: menu.title,
  }),
)

export {
  mapOfMenu,
  notificationOfChangeDocumentsAMenuHashMap,
  notificationOfChangeDocumentsMenuState,
}
