import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'

const { getDefaultInvestorFullFieldState } = FieldStateHelpers

const mapOfStatementConsiderationInvestmentAuthorizedMenu = [
  { id: '1', title: 'Сведения об организации, реализующей проект' },
  { id: '2', title: 'Информация о проекте' },
]

const defaultStatementConsiderationInvestmentAuthorizedFormValues: StatementConsiderationInvestmentAuthorizedFormValues =
  {
    '1': {
      ...getDefaultInvestorFullFieldState(),
      investorCapital: null,
      isProjectCompany: false,
      isVed: false,
      region: '',
      city: '',
      district: '',
      area: '',
      planningStructureElement: '',
      roadNetworkElement: '',
      house: '',
      room: '',
      roomPart: '',
    },
    '2': {
      projectName: '',
      lawLinkInvestProject: '',
      ruleLinkInvestProject: '',
    },
  }

const statementConsiderationInvestmentAuthorizedBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorName: '1.investorName',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorKpp: '1.investorKpp',
    investorOkpo: '1.investorOkpo',
    investorOkved: '1.investorOkved',
    investorCapital: '1.investorCapital',
    isProjectCompany: '1.isProjectCompany',
    isVed: '1.isVed',
    isKgn: '1.isKgn',
    region: '1.region',
    city: '1.city',
    district: '1.district',
    area: '1.area',
    planningStructureElement: '1.planningStructureElement',
    roadNetworkElement: '1.roadNetworkElement',
    house: '1.house',
    room: '1.room',
    roomPart: '1.roomPart',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
    investorHeaderEmail: '1.investorHeaderEmail',
    investorHeaderPhone: '1.investorHeaderPhone',
    investorHeaderAddPhone: '1.investorHeaderAddPhone',
  },
  '2': {
    projectName: '2.projectName',
    lawLinkInvestProject: '2.lawLinkInvestProject',
    ruleLinkInvestProject: '2.ruleLinkInvestProject',
  },
} as const

export {
  defaultStatementConsiderationInvestmentAuthorizedFormValues,
  mapOfStatementConsiderationInvestmentAuthorizedMenu,
  statementConsiderationInvestmentAuthorizedBlockValues,
}
