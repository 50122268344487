import { type FC, type ReactNode, memo } from 'react'

interface AttachmentAddonMERControllerProps {
  children: ReactNode
}

const AttachmentAddonMERController: FC<AttachmentAddonMERControllerProps> = ({ children }) => {
  return <>{children}</>
}

export default memo(AttachmentAddonMERController)
