import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import CertificateApplicationConclusionOfAARequirementsLayout, {
  CertificateApplicationConclusionOfAARequirementsModalProps,
} from './Layout'
import CertificateApplicationConclusionOfAARequirementsModalManager from './Manager'
import CertificateApplicationConclusionOfAARequirementsMenu from './Menu'
import CertificateApplicationConclusionOfAARequirementsStatementForm from './Statement'

interface ICreateCertificateApplicationConclusionOfAARequirements extends FC {
  Layout: typeof CertificateApplicationConclusionOfAARequirementsLayout
  Menu: typeof CertificateApplicationConclusionOfAARequirementsMenu
  StatementForm: typeof CertificateApplicationConclusionOfAARequirementsStatementForm
  Manager: typeof CertificateApplicationConclusionOfAARequirementsModalManager
}

const useCertificateApplicationConclusionOfAARequirementsControl = () => {
  const popupManager = usePopupManager()

  const openCertificateApplicationConclusionOfAARequirementsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<CertificateApplicationConclusionOfAARequirementsModalProps>(
        CertificateApplicationConclusionOfAARequirementsLayout,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openCertificateApplicationConclusionOfAARequirementsModal }
}

const CertificateApplicationConclusionOfAARequirements: ICreateCertificateApplicationConclusionOfAARequirements =
  () => null

CertificateApplicationConclusionOfAARequirements.Menu =
  CertificateApplicationConclusionOfAARequirementsMenu
CertificateApplicationConclusionOfAARequirements.Layout =
  CertificateApplicationConclusionOfAARequirementsLayout
CertificateApplicationConclusionOfAARequirements.StatementForm =
  CertificateApplicationConclusionOfAARequirementsStatementForm
CertificateApplicationConclusionOfAARequirements.Manager =
  CertificateApplicationConclusionOfAARequirementsModalManager

export { useCertificateApplicationConclusionOfAARequirementsControl }
export default CertificateApplicationConclusionOfAARequirements
