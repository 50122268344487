import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NotificationOfChangesInDetailsOfPartiesFormValues } from '@components/Forms/NotificationOfChangesInDetailsOfParties/types'
import { OrganizationInfoHelpersService } from '@components/OrganizationInfo/helpers'

import { defaultNotificationOfChangesInDetailsOfPartiesFormValues } from '../const'

const generateRHFObjectForNotificationOfChangesInDetailsOfPartiesForm = (
  objectFromPropertyState: Record<string, any>,
): NotificationOfChangesInDetailsOfPartiesFormValues => {
  return {
    '1': RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '2': {
      ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
      ruleLinkInfoChanges:
        objectFromPropertyState.ruleLinkInfoChanges?.value ||
        defaultNotificationOfChangesInDetailsOfPartiesFormValues['2'].ruleLinkInfoChanges,
      initiatorOtherDetails: OrganizationInfoHelpersService.generateOtherDetails({
        memberEmail:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberEmail,
        memberPhone:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberPhone,
        memberPhoneExtension:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value
            ?.memberPhoneExtension,
        memberFax: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberFax,
      }),
    },
  } as NotificationOfChangesInDetailsOfPartiesFormValues
}

export { generateRHFObjectForNotificationOfChangesInDetailsOfPartiesForm }
