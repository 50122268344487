import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import FormError from '@components/DocumentFormComponents/FormError'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import PermissionsItem from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm/PermissionsItem'
import { PermissionsObjectsPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import {
  AAgreementOnCreationOtherEstateObjectsFormModifierValues,
  AAgreementOnCreationOtherEstateObjectsFormValues,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import {
  AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  AAgreementOnCreationOtherEstateObjectsModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const { isFormFieldError } = DocumentFormHelpers

interface CPermissionsProps {
  indexOfBlock: number
  name: PermissionsObjectsPathName
}

const CPermissionsForm: FC<CPermissionsProps> = ({ name, indexOfBlock }) => {
  const formInstance = useFormContext<AAgreementOnCreationOtherEstateObjectsFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnCreationOtherEstateObjectsFormModifierValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { fields: permissionForms } = useFieldArraySubscribableControl<
    AAgreementOnCreationOtherEstateObjectsFormValues,
    PermissionsObjectsPathName,
    'keyNameId'
  >({
    name,
    control: formInstance.control,
    keyName: 'keyNameId',
    watcher: AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnCreationOtherEstateObjectsFormModifierValues,
    PermissionsObjectsPathName
  >({
    control: formModifierInstance.control,
    name,
    watcher: AAgreementOnCreationOtherEstateObjectsModifierFieldArrayControlUpdateWatcher,
  })

  const handleAddPermission = async () => {
    await handleAddItemToListWithOutValue?.(name)

    if (!permissionForms.length) {
      formInstance.clearErrors(name)
    }
  }

  const handleRemovePermission = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(`${name}.${index}`)
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(`${name}.${index}`)
  }

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  const permissionFormsError = formInstance.getFieldState(name)?.error

  return (
    <Container className="p-0">
      <Stack direction={'vertical'} gap={!!permissionForms.length ? 3 : 0} className={'mt-3'}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: name,
          })}
        >
          <FlipperList list={permissionForms}>
            {permissionForms.map((permissionForm, index) => {
              const formName = `${name}.${index}` as const

              return (
                <SubscribableControl
                  key={permissionForm.id}
                  {...getSubscribableControlProps({
                    path: formName,
                  })}
                >
                  <FormObjectTooltipControl
                    name={formName}
                    control={formModifierInstance.control}
                    onDifference={handleUpdateChanges}
                  >
                    <PermissionsItem
                      id={permissionForm.id}
                      blockViewIsValidating={blockViewIsValidating}
                      editMode={editMode}
                      formInstance={formInstance}
                      formName={formName}
                      indexOfBlock={indexOfBlock}
                      indexOfPermission={index}
                      lastIndexOfPermission={permissionForms.length - 1}
                      onDeleteObject={handleRemovePermission(index)}
                      onMoveTop={handleChangeElementOrderToUp(index)}
                      onMoveBottom={handleChangeElementOrderToDown(index)}
                    />
                  </FormObjectTooltipControl>
                </SubscribableControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(permissionFormsError) && !permissionForms.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddPermission}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить разрешение
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default CPermissionsForm
