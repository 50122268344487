import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type {
  SupplementalCostSharingAContractDutyMembers,
  SupplementalCostSharingAFormValues,
} from '@components/Forms/SupplementalCostSharingAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const supplementalCostSharingAContractDutyMembersValidationMap: FormValuesValidationSection<
  keyof SupplementalCostSharingAContractDutyMembers,
  SupplementalCostSharingAFormValues
> = {
  contractDutyMemberName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  contractDutyMemberOgrn: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 13),
  },
  contractDutyMemberInn: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 10),
  },
  contractDutyMemberAddress: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 2000),
  },
}

export { supplementalCostSharingAContractDutyMembersValidationMap }
