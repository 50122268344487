import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import ApplicationConclusionAdditionalAgreementFormLayout from './Layout'
import ApplicationConclusionAdditionalAgreementFormModalManager from './Manager'
import ApplicationConclusionAdditionalAgreementFormMenu from './Menu'
import ApplicationConclusionAdditionalAgreementFormStatementForm from './Statement'

interface IApplicationConclusionAdditionalAgreementForm extends FC {
  Layout: typeof ApplicationConclusionAdditionalAgreementFormLayout
  Menu: typeof ApplicationConclusionAdditionalAgreementFormMenu
  StatementForm: typeof ApplicationConclusionAdditionalAgreementFormStatementForm
  Manager: typeof ApplicationConclusionAdditionalAgreementFormModalManager
}

const useApplicationConclusionAdditionalAgreementFormControl = () => {
  const popupManager = usePopupManager()

  const openApplicationConclusionAdditionalAgreementModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(ApplicationConclusionAdditionalAgreementFormLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openApplicationConclusionAdditionalAgreementModal }
}

const ApplicationConclusionAdditionalAgreementForm: IApplicationConclusionAdditionalAgreementForm =
  () => null

ApplicationConclusionAdditionalAgreementForm.Menu = ApplicationConclusionAdditionalAgreementFormMenu
ApplicationConclusionAdditionalAgreementForm.Layout =
  ApplicationConclusionAdditionalAgreementFormLayout
ApplicationConclusionAdditionalAgreementForm.StatementForm =
  ApplicationConclusionAdditionalAgreementFormStatementForm
ApplicationConclusionAdditionalAgreementForm.Manager =
  ApplicationConclusionAdditionalAgreementFormModalManager

export { useApplicationConclusionAdditionalAgreementFormControl }
export default ApplicationConclusionAdditionalAgreementForm
