import React from 'react'
import { useFormContext } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAChangingVolumeReimbursementBlockValues } from '@components/Forms/AAChangingVolumeReimbursementForm/const'
import Step from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/6/Step'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import {
  AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher,
  AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
} from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Sixth = () => {
  const formInstance = useFormContext<AAChangingVolumeReimbursementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useAAChangingVolumeReimbursementManager()

  const { fields: stepCompensations } = useFieldArraySubscribableControl<
    AAChangingVolumeReimbursementFormValues,
    typeof aAChangingVolumeReimbursementBlockValues['6']['steps'],
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aAChangingVolumeReimbursementBlockValues['6'].steps,
    keyName: 'keyNameId',
    watcher: AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={!!stepCompensations.length ? 3 : 0}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAChangingVolumeReimbursementBlockValues['6'].steps,
              })}
            >
              <FlipperList list={stepCompensations} gap={1}>
                {stepCompensations.map((step, index) => {
                  const formName =
                    `${aAChangingVolumeReimbursementBlockValues['6'].steps}.${index}` as const

                  return (
                    <SubscribableControl
                      key={step.id}
                      {...getSubscribableControlProps({
                        path: formName,
                      })}
                    >
                      <Accordion id={step.id} title={step.stepDescription}>
                        <Step
                          editMode={editMode}
                          blockViewIsValidating={blockViewIsValidating}
                          formName={formName}
                          subscribableControl={subscribableControl}
                          formInstance={formInstance}
                        />
                      </Accordion>
                    </SubscribableControl>
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Sixth
