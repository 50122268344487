import React, { FC, memo, useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'
import { Virtuoso } from 'react-virtuoso'

import { DEFAULT_MASTER_ITEM_HEIGHT } from '@components/RegistryNpa/Choose/const'
import { NpaModalThreeNpaListProps } from '@components/RegistryNpa/Choose/Grid/types'
import MasterNpaItem from '@components/RegistryNpa/Choose/Items/MasterNpaItem'
import PartNpaList from '@components/RegistryNpa/Choose/Items/PartINpaItem/List'

const NpaModalDefaultThreeNpaList: FC<NpaModalThreeNpaListProps> = ({
  formInstance,
  direction,
  mainDirection,
  name,
  classificationHeader,
}) => {
  const { fields: npas, update } = useFieldArray({
    control: formInstance.control,
    name: `${name}.npas`,
    keyName: 'keyNameId',
  })

  const handleToggleNpaItem = useCallback(
    (index: number) => (isCollapsed: boolean) => {
      const currentNpaItem = formInstance.getValues(`${name}.npas.${index}`)

      update(index, {
        ...currentNpaItem,
        isCollapsed,
      })
    },
    [name, update],
  )

  const virtuosoElement = (
    <Virtuoso
      data={npas}
      totalCount={npas.length}
      defaultItemHeight={DEFAULT_MASTER_ITEM_HEIGHT}
      computeItemKey={(_index, npa) => npa.id}
      itemContent={(index, npa) => {
        return (
          <MasterNpaItem key={npa.id} npa={npa} onToggle={handleToggleNpaItem(index)}>
            <PartNpaList
              name={`${name}.npas.${index}` as const}
              formInstance={formInstance}
              npaId={npa.id}
              direction={direction}
              classificationHeader={classificationHeader}
            />
          </MasterNpaItem>
        )
      }}
    />
  )

  if (!mainDirection) {
    return (
      <div>
        {npas.map((npa, index) => {
          return (
            <MasterNpaItem key={npa.id} npa={npa} onToggle={handleToggleNpaItem(index)}>
              <PartNpaList
                name={`${name}.npas.${index}` as const}
                formInstance={formInstance}
                classificationHeader={classificationHeader}
                npaId={npa.id}
                direction={direction}
              />
            </MasterNpaItem>
          )
        })}
      </div>
    )
  }

  return virtuosoElement
}

export default memo(NpaModalDefaultThreeNpaList)
