import React from 'react'
import { useFormContext } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import documentStyles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { oneSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/1/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const One = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getControllerProps, getSingleSelectProps, getSubscribableControlProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: ProjectSZPKFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfFullName,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Сокращенное наименование'}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfName,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Место нахождения'}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfAddress,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfInn,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfOgrn,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfKpp,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'КПП'}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfKpp,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfOktmo,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОКТМО'}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfOktmo,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfPaymentDetails,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Платежные реквизиты'}
                contentTypographyProps={{
                  className: documentStyles.form__documentViewSpaceWrap,
                }}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfPaymentDetails,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: projectSZPKBlockValues['1'].rfOtherDetails,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Прочие реквизиты'}
                contentTypographyProps={{
                  className: documentStyles.form__documentViewSpaceWrap,
                }}
                controllerProps={{
                  ...getControllerProps({
                    name: projectSZPKBlockValues['1'].rfOtherDetails,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное должностное лицо'}
          formInstance={formInstance}
          formSection={'1'}
          prefixFormFieldName={'rf'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: projectSZPKBlockValues['1'].rfHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: projectSZPKBlockValues['1'].rfHeaderName,
                      rules: oneSectionValidationMap.rfHeaderName,
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () => {
                        setTimeout(() => {
                          handleChangeValue?.(projectSZPKBlockValues['1'].rfHeaderName)
                        }, 0)
                      },
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
      </Stack>
    </Container>
  )
}

export default One
