import React, { FC, memo, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Outlet, useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import Error500Fallback from '@components/DataFallback/500Error'
import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Icon from '@components/Icon/Icon'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import Tag from '@components/TagNew'
import { Paths } from '@constants/paths'
import { RolesTypes } from '@constants/types'
import PageContainer from '@containers/PageContainer'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { isAxiosError, isNullOrUndefined } from '@helpers/checkTypes'
import { isIntervalServerError } from '@helpers/errorHelpers'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useDfosDigitizing } from '@hooks/new/swr/useDfosDigitizing'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import { useQueryManager } from '@hooks/useQueryManager'
import searchIcon from '@icons/action/search.svg'
import Layouts from '@layouts'
import { mapOfViewReportByRole } from '@routes/Reports/constants'
import ReportFilters from '@routes/Reports/Filters'
import { mapOfStatusSelect } from '@routes/Reports/Filters/const'
import ReportItem from '@routes/Reports/Item'
import type { ReportsFormValues } from '@routes/Reports/types'
import DayjsService from '@services/Dayjs/Dayjs.service'
import {
  EnumOfStageReports,
  EnumOfTypeReports,
  IGetReportsParams,
  IReportEntity,
  mapOfTypeReports,
} from '@services/Monitoring/monitoring.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import { initialReportState, reportActionCreators, reportReducer } from './reducer'
import styles from './Reports.module.scss'
import FiltersReportsService from './utils'

const { prepareQueryForApply, transformFormFiltersToParams } = FiltersReportsService

const ReportsPage: FC = () => {
  const [state, dispatch] = useReducer(reportReducer, initialReportState)
  const abortControllerRef = useRef<AbortController | null>(null)

  const location = useLocation()
  const { queryUtils } = useQueryManager()

  const {
    monitoringApi: { getReports },
  } = useAPIContext()

  const navigate = useNavigate()

  const { getRolesByToken } = useAuthContext()
  const roles = getRolesByToken?.()

  const roleFromRolesTypes = Object.values(RolesTypes).find((role) => roles?.includes(role))
  const isMER = roleFromRolesTypes === RolesTypes.MER
  const isOIV = roleFromRolesTypes === RolesTypes.OIV

  const typeToReportPageView = roleFromRolesTypes ? mapOfViewReportByRole[roleFromRolesTypes] : null

  const initialFiltersFromQuery = useMemo(() => {
    const isFederal = queryUtils.getQuery('isFederal')
    const regions = queryUtils.getQuery('regions')
    const typeOfReport =
      (queryUtils.getQuery('type') as EnumOfTypeReports | undefined) || mapOfTypeReports.investor

    const dateOfReport =
      typeOfReport !== typeToReportPageView
        ? queryUtils.getQuery('year') || String(DayjsService.dayjs().year() - 1)
        : ''

    const initialFilters: ReportsFormValues = {
      typeOfReport,
      filters: {
        dateOfReport,
        stageOfReport: (queryUtils.getQuery('stage') as EnumOfStageReports) || '',
        indicatorValue: '',
        isFederal: isNullOrUndefined(isFederal) ? true : Boolean(isFederal),
        regions: regions ? JSON.parse(regions) : [],
        searchText: queryUtils.getQuery('searchText') || '',
        isRegional: isNullOrUndefined(isFederal) ? true : !isFederal,
      },
    }

    //На региональных не должно быть выбора чекбокса, необходимо обнулять кверики, на отчётах инвестора не должно быть регионов
    if (initialFilters.typeOfReport === mapOfTypeReports.investor) {
      initialFilters.filters.regions = []
    } else {
      initialFilters.filters.isRegional = true
      initialFilters.filters.isFederal = true
    }

    return initialFilters
  }, [])

  const filterInstance = useForm<ReportsFormValues>({
    defaultValues: initialFiltersFromQuery,
  })

  const {
    state: { tooltipIsOpen },
    handlers: { handleCloseTooltip, handleOpenTooltip },
  } = useManualTooltipControl()

  const formFilters = filterInstance.watch()
  const currentSearchValue = filterInstance.watch('filters.searchText').trim()
  const currentType = filterInstance.watch('typeOfReport')

  useEffect(() => {
    if (initialFiltersFromQuery.typeOfReport !== typeToReportPageView) {
      handleFilterApply()
      return
    }

    ;(async () => {
      await handleTypeReportChange(typeToReportPageView)
    })()
  }, [])

  useEffectAfterMount(() => {
    if (location.search) return

    navigate(-1)
  }, [location.search])

  const currentFilters: IGetReportsParams = useMemo(() => {
    return transformFormFiltersToParams(formFilters)
  }, [formFilters])

  const handleFilterApply = useCallback(
    (initialFilters?: IGetReportsParams) => {
      const abortController = new AbortController()
      abortControllerRef.current = abortController
      ;(async () => {
        const filters = initialFilters ?? currentFilters

        dispatch(reportActionCreators.resetAllData())

        //type и searchText не считаются в счётчик
        const filtersCount = Object.values({
          ...filters,
          type: undefined,
          searchText: undefined,
        }).filter((value) => value !== undefined).length

        dispatch(reportActionCreators.setFiltersCount(filtersCount))

        const initialQuery = queryUtils.clearAllQuery({
          exceptions: ['type'],
          applyInstantly: false,
        })
        queryUtils.addQuery({
          query: prepareQueryForApply(filters),
          initialSearchParams: initialQuery,
        })

        try {
          const reports = await getReports(filters, abortController.signal)

          if (filters.searchText?.trim().length && !reports.length) {
            dispatch(reportActionCreators.setSearchPlaceholder(true))
          }

          if (!filters.searchText?.trim().length && !reports.length) {
            dispatch(reportActionCreators.setNoDataPlaceholder(true))
          }

          dispatch(reportActionCreators.setReports(reports))
        } catch (error) {
          if (!isAxiosError(error)) throw error

          dispatch(reportActionCreators.setError(error))
        } finally {
          dispatch(reportActionCreators.setLoading(false))
        }
      })()
    },
    [currentFilters, getReports],
  )

  const debouncedFiltersApply = useDebouncedCallback(handleFilterApply, 300)

  useUpdateEffect(() => {
    dispatch(reportActionCreators.removePlaceholders())

    abortControllerRef.current?.abort()

    debouncedFiltersApply()
  }, [currentSearchValue])

  const handleTypeReportChange = async (newTypeOfReport) => {
    if (newTypeOfReport === typeToReportPageView) {
      const initialQuery = queryUtils.clearAllQuery({
        exceptions: ['type'],
        applyInstantly: false,
      })
      queryUtils.addQuery({
        query: prepareQueryForApply({ type: newTypeOfReport }),
        initialSearchParams: initialQuery,
      })

      try {
        dispatch(reportActionCreators.resetAllData())

        const reports = await handleGetParamsToReportRedirect()

        const redirectReport = reports?.[0]
        if (!redirectReport) return dispatch(reportActionCreators.setNoDataPlaceholder(true))

        navigate({
          pathname: `${Paths.Reports}/${redirectReport.projectId}`,
          search: new URLSearchParams({
            type: newTypeOfReport,
            dfoId: redirectReport.dfo.dfoId,
          }).toString(),
        })
      } catch (error) {
        if (!isAxiosError(error)) throw error

        dispatch(reportActionCreators.setError(error))
      } finally {
        dispatch(reportActionCreators.setLoading(false))
      }

      return
    }

    const preparedSelect = mapOfStatusSelect[newTypeOfReport]

    filterInstance.setValue(
      'filters.stageOfReport',
      preparedSelect.find((option) => {
        return option.value !== '' && option.value === formFilters.filters.stageOfReport
      })
        ? (formFilters.filters.stageOfReport as EnumOfStageReports)
        : '',
    )

    handleFilterApply({
      ...currentFilters,
      year: currentFilters.year ? currentFilters.year : String(dayjs().year() - 1),
      regions: newTypeOfReport === mapOfTypeReports.investor ? undefined : currentFilters.regions,
      isFederal: newTypeOfReport === mapOfTypeReports.region ? undefined : currentFilters.isFederal,
      stage: preparedSelect.find((option) => {
        return option.value !== '' && option.value === formFilters.filters.stageOfReport
      })
        ? (formFilters.filters.stageOfReport as EnumOfStageReports)
        : undefined,
      type: newTypeOfReport,
    })
  }

  const handleGetParamsToReportRedirect = async () => {
    try {
      const reportToRedirect = await getReports({
        year: String(DayjsService.dayjs().year() - 1),
        type: isMER ? mapOfTypeReports.federal : mapOfTypeReports.region,
      })

      return reportToRedirect
    } catch (error) {
      throw error
    }
  }

  const { checkingRole } = useAuthContext()

  const isRoleMER = useMemo(() => checkingRole?.(RolesTypes.MER), [checkingRole])

  const { dfosDigitizing } = useDfosDigitizing({
    key: isRoleMER
      ? {
          _key: 'dfosDigitizing',
        }
      : null,
    config: {
      revalidateOnMount: true,
    },
  })

  const preparedReports: IReportEntity[] = useMemo(() => {
    if (!state.reports || !state.reports.length) return []

    return state.reports.map((report) => {
      const foundDfosDigitizing = (dfosDigitizing || [])?.find(
        ({ projectId }) => projectId === report.projectId,
      )?.dfos

      const digitizingInProcess = foundDfosDigitizing?.find(
        ({ dfoId }) => dfoId === report.dfo.dfoId,
      )?.digitizingInProcess

      const preparedDfo = {
        ...report.dfo,
        digitizingInProcess,
      }

      return {
        ...report,
        dfo: preparedDfo,
      }
    })
  }, [state.reports, dfosDigitizing])

  return (
    <Layouts.Main>
      <div className={styles.reports}>
        <PageContainer
          className={cn(styles.reports__header, {
            [styles.reports__header__consolidated]: currentType === typeToReportPageView,
          })}
        >
          <ControlledRadioGroup
            control={filterInstance.control}
            name={'typeOfReport'}
            radioGroupProps={{
              type: 'tag',
              direction: 'horizontal',
            }}
            onChange={handleTypeReportChange}
          >
            <Tag.Item
              disabled={state.reportsIsLoading}
              className={styles.reports__tags}
              value={mapOfTypeReports.investor}
            >
              Отчёты инвесторов
            </Tag.Item>
            {(isMER || isOIV) && (
              <Tag.Item
                disabled={state.reportsIsLoading}
                className={styles.reports__tags}
                value={mapOfTypeReports.region}
              >
                Отчёты региона
              </Tag.Item>
            )}
            {isMER && (
              <Tag.Item
                disabled={state.reportsIsLoading}
                className={styles.reports__tags}
                value={mapOfTypeReports.consolidated}
              >
                Cводные отчёты
              </Tag.Item>
            )}
          </ControlledRadioGroup>

          {currentType !== typeToReportPageView && (
            <div className={styles.reports__filters}>
              {
                <ReportFilters
                  formInstance={filterInstance}
                  isMER={isMER}
                  filtersIsOpen={tooltipIsOpen}
                  handleCloseTooltip={handleCloseTooltip}
                  onApplyFilters={handleFilterApply}
                >
                  <Button
                    className={styles.reports__buttonFilter}
                    disabled={state.reportsIsLoading}
                    view={'plain'}
                    onClick={handleOpenTooltip}
                  >
                    <Typography.Body
                      as={'span'}
                      className={styles.reports__filterCount}
                      variant={'bodyMMedium'}
                      color={'on-accent-on-surface-primary'}
                    >
                      {state.countOfFilters}
                    </Typography.Body>
                    <span className={styles.reports__filter}>Фильтры</span>
                  </Button>
                </ReportFilters>
              }

              <ControlledInput
                name="filters.searchText"
                control={filterInstance.control}
                inputProps={{
                  rootClassName: styles.reports__search,
                  size: 'm',
                  placeholder: 'Поиск',
                  clear: true,
                  view: 'secondary',
                  leftAddons: <Icon src={searchIcon} size="s" className={styles.searchIcon} />,
                }}
              />
            </div>
          )}
        </PageContainer>
        <Error500Fallback
          title={'Не удалось загрузить отчёты'}
          className={styles.reports__fallbackError}
          error={isIntervalServerError(state?.reportsError?.response?.status)}
        >
          <PageContainer>
            <Loader loading={state.reportsIsLoading}>
              {(() => {
                switch (currentType) {
                  case typeToReportPageView:
                    return (
                      <>
                        {state.noDataPlaceholder ? (
                          <NoDataPlaceholder className={styles.reports__searchPlaceholder} />
                        ) : (
                          <Outlet />
                        )}
                      </>
                    )

                  default:
                    return (
                      <>
                        {state.searchPlaceholder && (
                          <SearchPlaceholder className={styles.reports__searchPlaceholder}>
                            {currentSearchValue}
                          </SearchPlaceholder>
                        )}
                        {state.noDataPlaceholder && (
                          <NoDataPlaceholder className={styles.reports__searchPlaceholder} />
                        )}
                        {!!preparedReports.length && (
                          <div className={styles.reports__items}>
                            {preparedReports.map((report) => (
                              <ReportItem
                                key={report.projectId}
                                isMER={isMER}
                                report={report}
                                typeOfReport={currentType}
                              />
                            ))}
                          </div>
                        )}
                      </>
                    )
                }
              })()}
            </Loader>
          </PageContainer>
        </Error500Fallback>
      </div>
    </Layouts.Main>
  )
}

export default memo(ReportsPage)
