import { ComponentInfo } from '@services/LoggerService/LoggerService.entity'

const otherProcurationFormNamesMap = {
  MChDNumber: 'MChDNumber',
  organizationINN: 'organizationINN',
} as const

const UUID_MAX_LENGTH = 36

const otherProcurationDefaultFormValues = {
  MChDNumber: '',
  organizationINN: '',
}

const otherProcurationComponentInfo: ComponentInfo = {
  componentName: 'OtherProcuration',
  moduleName: 'ProcurationForm',
  componentType: 'authByProcurationForm',
}

export {
  otherProcurationComponentInfo,
  otherProcurationDefaultFormValues,
  otherProcurationFormNamesMap,
  UUID_MAX_LENGTH,
}
