import React, { memo } from 'react'

import { SkeletonText } from '@components/Skeleton/Text/SkeletonText'

import styles from './ParticipantsOfStatementLoader.module.scss'

const ROW_COUNT = 2
const ITEM_IN_ROW_COUNT = 3

const ParticipantsOfStatementLoader = () => {
  return (
    <div className={styles.loader}>
      {Array(ROW_COUNT)
        .fill(0)
        .map((_, index) => (
          <div key={index} className={styles.loader__row}>
            {Array(ITEM_IN_ROW_COUNT)
              .fill(0)
              .map((_, index) => (
                <SkeletonText
                  animate
                  visible
                  key={index}
                  dataTestId={`ParticipantsOfStatementLoader-skeletonText-${index}`}
                  className={styles.loader__item}
                />
              ))}
          </div>
        ))}
    </div>
  )
}

export default memo(ParticipantsOfStatementLoader)
