import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  ICompensationStatementPaidTaxValue,
  ParametersOfCostRecoveryApplicationFormValues,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const compensationStatementPaidTaxValuesValidationMap: FormValuesValidationSection<
  keyof ICompensationStatementPaidTaxValue,
  ParametersOfCostRecoveryApplicationFormValues
> = {
  paidTaxYearValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { compensationStatementPaidTaxValuesValidationMap }
