import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { infrFacilitiesCostsExpectedReimbursedBlockValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import MainProjectsList from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Forms/2/MainProjectsList'
import { secondSectionInfrFacilitiesCostsExpectedReimbursedValidationMap } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Forms/2/validation'
import { useInfrFacilitiesCostsExpectedReimbursedManager } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Manager'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import styles from './Second.module.scss'

const Two = () => {
  const formInstance = useFormContext<InfrFacilitiesCostsExpectedReimbursedFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInfrFacilitiesCostsExpectedReimbursedManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getCalendarInputProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Наименование проекта"
                controllerProps={getControllerProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <MainProjectsList
          blockViewIsValidating={blockViewIsValidating}
          editMode={editMode}
          formInstance={formInstance}
          formName={infrFacilitiesCostsExpectedReimbursedBlockValues['2'].mainProjectObjects}
        />
        <Group
          disableBottomBorder
          title={'Общий объем капиталовложений, включая осуществленные капиталовложения'}
          leftAddons={
            <FormIconWithTooltip
              tooltipContent='Объем капиталовложений (без НДС) заполняется автоматически на основе сведений об объеме капиталовложений по этапам, указанных в форме "Проект СЗПК"'
              iconProps={{
                className: styles.tooltip,
              }}
            />
          }
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].investorCapitalVAT,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: infrFacilitiesCostsExpectedReimbursedBlockValues['2']
                        .investorCapitalVAT,
                      inputProps: {
                        label: 'с НДС',
                        dataTestId: 'investorCapitalVAT',
                      },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(
                              infrFacilitiesCostsExpectedReimbursedBlockValues['2']
                                .investorCapitalVAT,
                            ),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            infrFacilitiesCostsExpectedReimbursedBlockValues['2']
                              .investorCapitalVAT,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].investorCapital,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].investorCapital,
                      rules:
                        secondSectionInfrFacilitiesCostsExpectedReimbursedValidationMap.investorCapital,
                      inputProps: {
                        disabled: true,
                        dataTestId: 'investorCapital',
                        label: 'без НДС',
                      },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(
                              infrFacilitiesCostsExpectedReimbursedBlockValues['2'].investorCapital,
                            ),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            infrFacilitiesCostsExpectedReimbursedBlockValues['2'].investorCapital,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Общий объем капитальных вложений (инвестиций)'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].totalCapitalVAT,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].totalCapitalVAT,
                      rules:
                        secondSectionInfrFacilitiesCostsExpectedReimbursedValidationMap.totalCapitalVAT,
                      inputProps: {
                        label: 'с НДС',
                        dataTestId: 'totalCapitalVAT',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            infrFacilitiesCostsExpectedReimbursedBlockValues['2'].totalCapitalVAT,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            infrFacilitiesCostsExpectedReimbursedBlockValues['2'].totalCapitalVAT,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].totalCapital,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].totalCapital,
                      rules:
                        secondSectionInfrFacilitiesCostsExpectedReimbursedValidationMap.totalCapital,
                      inputProps: {
                        label: 'без НДС',
                        dataTestId: 'totalCapital',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            infrFacilitiesCostsExpectedReimbursedBlockValues['2'].totalCapital,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            infrFacilitiesCostsExpectedReimbursedBlockValues['2'].totalCapital,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableTitleLeftPadding
                  disableBottomDefaultStyles
                  titleClassName={styles.row__title}
                  title={'Предполагаемый год окончания\nинвестиционной стадии'}
                >
                  <div>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: infrFacilitiesCostsExpectedReimbursedBlockValues['2'].endInvestStage,
                      })}
                    >
                      <ControlledCalendarInput
                        {...getCalendarInputProps({
                          name: infrFacilitiesCostsExpectedReimbursedBlockValues['2']
                            .endInvestStage,
                          rules:
                            secondSectionInfrFacilitiesCostsExpectedReimbursedValidationMap.endInvestStage,

                          calendarInputProps: {
                            dataTestId: 'endInvestStage',
                            inputMask: YEAR_MASK,
                            label: 'Укажите год',
                          },
                          calendarProps: {
                            typeOfPicker: 'years',
                            dateFormat: 'YYYY',
                          },
                          onBlur: () =>
                            setTimeout(() => {
                              handleChangeValue?.(
                                infrFacilitiesCostsExpectedReimbursedBlockValues['2']
                                  .endInvestStage,
                              )
                            }, 0),
                          onInputChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(
                                infrFacilitiesCostsExpectedReimbursedBlockValues['2']
                                  .endInvestStage,
                              )
                            }, 0),
                          onCalendarChange: () =>
                            setTimeout(() => {
                              handleChangeValue?.(
                                infrFacilitiesCostsExpectedReimbursedBlockValues['2']
                                  .endInvestStage,
                              )
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </div>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default Two
