import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { AllowedPathsNew, Paths } from '@constants/paths'
import { RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { findOne, getFirstPath } from '@helpers/commonHelpers'
import { ValueOf } from '@helpers/ValueOf'
import { UserRole } from '@interfaces'

type TPaths = typeof Paths[keyof typeof Paths]

export interface Rule {
  paths: ValueOf<typeof Paths>[]
  roles: UserRole[]
  to: ValueOf<typeof Paths>
}

const useRedirectByRoles = () => {
  const { pathname } = useLocation()
  const { getUserRole } = useAuthContext()
  const rules = useMemo<Rule[]>(
    () => [
      {
        paths: [...AllowedPathsNew.UO],
        roles: [RolesTypes.UO],
        to: Paths.Projects,
      },
      {
        paths: [...AllowedPathsNew.MER],
        roles: [RolesTypes.MER],
        to: Paths.Projects,
      },
      {
        paths: [...AllowedPathsNew.OIV],
        roles: [RolesTypes.OIV],
        to: Paths.Projects,
      },
      {
        paths: [...AllowedPathsNew.OMSU],
        roles: [RolesTypes.OMSU],
        to: Paths.Projects,
      },
      {
        paths: [...AllowedPathsNew.FK],
        roles: [RolesTypes.FK],
        to: Paths.Projects,
      },
      {
        paths: [...AllowedPathsNew.Operator],
        roles: [RolesTypes.OPERATOR],
        to: Paths.Users,
      },
      {
        paths: [...AllowedPathsNew.Investor],
        roles: [RolesTypes.INVESTOR],
        to: Paths.Projects,
      },
    ],
    [],
  )

  const redirectByRoles = (callback: (rule: Rule) => void) => {
    const { roles } = getUserRole?.()
    const _pathname = getFirstPath(pathname) as TPaths

    for (const rule of rules) {
      if (!rule.paths.includes(_pathname) && findOne(roles, rule.roles)) {
        return callback(rule)
      }
    }

    return false
  }

  return { redirectByRoles }
}

export default useRedirectByRoles
