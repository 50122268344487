import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

export interface IRegions {
  [key: string]: string
}

const useRegionsByProject = ({ key, config }: SWRHookProps<IRegions | undefined>) => {
  const {
    projectsApi: { getRegionsByProject },
  } = useAPIContext()

  const {
    data: projectRegions,
    error,
    isValidating: isRegionsLoading,
    mutate,
  } = useSWRImmutable(key, ({ projectId }) => getRegionsByProject(projectId), config)

  //По умолчанию: код - город, данный объект --- город - код
  const reversedRegionsObject = useMemo(() => {
    if (!projectRegions) return

    return Object.entries(projectRegions).reduce((prevValue, [regionCode, regionValue]) => {
      return { ...prevValue, [regionValue]: regionCode }
    }, {})
  }, [projectRegions])

  return {
    projectRegions,
    reversedRegionsObject,
    error,
    isRegionsLoading,
    mutate,
  }
}

export { useRegionsByProject }
