import { NewDfosType } from '@constants/types'
import { DependentUrls, Urls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import {
  ICurrentOrganizationUser,
  IndicationEmailRequiredFields,
  IOrganizationInfo,
  IOrganizationUser,
} from '@services/Organization/organization.entity'

import {
  ChangeOrganizationInfoProps,
  ChangeOrganizationUserDetailsProps,
  CheckRegistrationGetResponse,
  CheckRegistrationParams,
  GetOrganizationActionsReturnType,
  GetOrganizationVerifyReturnType,
  OrganizationActions,
  OrganizationForTransfers,
  OrganizationVerify,
  RegisterOrganizationPostProps,
  RegisterOrganizationPostResponse,
  TransfersData,
  UpdatedUser,
} from './types'

const useOrganizationApi = () => {
  const { FetcherWithInterceptors, Fetcher } = useAuthContext()

  const getOrganizationInfo = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<IOrganizationInfo>({
        url: `${Urls.Organization}/data`,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  //Запрос на создание заявки EGRUL
  const createOrganizationChangeInfo = async () => {
    try {
      const { data } = await FetcherWithInterceptors.post({
        url: `${Urls.Organization}/data`,
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const getTransfers = async ({
    size,
    organizationId,
    orgReorganizationType,
    page,
  }: OrganizationForTransfers) => {
    try {
      const { data } = await FetcherWithInterceptors.get<Array<TransfersData>>({
        url: Urls.Transfers,
        config: {
          params: {
            size,
            organizationId,
            orgReorganizationType,
            page,
            type: NewDfosType.SZPK,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const postTransfer = async (transferId: TransfersData['transferId']) => {
    try {
      return await FetcherWithInterceptors.post({
        url: {
          url: Urls.TransfersById,
          params: { transferId },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const autoRegisterUser = async (body: RegisterOrganizationPostProps) => {
    try {
      const { data } = await Fetcher.post<RegisterOrganizationPostResponse>({
        url: Urls.RegistrationOrganization,
        data: body,
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const getRegistrationStatus = async (params: CheckRegistrationParams) => {
    try {
      const { data } = await Fetcher.get<CheckRegistrationGetResponse>({
        url: Urls.CheckRegistrationStatus,
        config: {
          params,
        },
      })

      return data
    } catch (e) {
      throw e
    }
  }

  const getUsers = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<IOrganizationUser[]>({
        url: `${Urls.Organization}/users`,
        header: 'Default',
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getUserById = async (id: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<ICurrentOrganizationUser>({
        url: {
          url: DependentUrls.OrganizationUserById,
          params: {
            id,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const editUser = async (id: string, body: UpdatedUser) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.EmailIndication,
          params: {
            id,
          },
        },
        data: JSON.stringify(body),
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const indicationEmail = async ({ id, ...restData }: IndicationEmailRequiredFields) => {
    return await FetcherWithInterceptors.put({
      url: {
        url: DependentUrls.EmailIndication,
        params: {
          id,
        },
      },
      data: restData,
    })
  }

  const changeOrganizationInfo = async (newOrganizationInfo: ChangeOrganizationInfoProps) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.ChangeOrganizationInfo,
        },
        data: newOrganizationInfo,
      })

      return data
    } catch (e) {
      handleErrorWithPopup(e, true)
      throw e
    }
  }

  const changeOrganizationUserDetails = async ({
    id,
    userDetails,
  }: ChangeOrganizationUserDetailsProps) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.ChangeOrganizationUserDetails,
          params: {
            id,
          },
        },
        data: userDetails,
      })

      return data
    } catch (e) {
      handleErrorWithPopup(e, true)
      throw e
    }
  }

  const askEmailApproving = async () => {
    const { hostname, protocol } = window.location

    try {
      return await FetcherWithInterceptors.post({
        url: Urls.AskEmailApproving,
        config: {
          headers: {
            Origin: `${protocol}//${hostname}`,
          },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getOrganizationVerify = async (): Promise<GetOrganizationVerifyReturnType> => {
    try {
      const { data } = await FetcherWithInterceptors.get<OrganizationVerify>({
        url: Urls.OrganizationVerify,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, false)

      throw error
    }
  }

  const getOrganizationActions = async (): Promise<GetOrganizationActionsReturnType> => {
    try {
      const { data } = await FetcherWithInterceptors.get<OrganizationActions>({
        url: DependentUrls.OrganizationActions,
      })
      
      return data
    } catch (error) {
      handleErrorWithPopup(error, false)

      throw error
    }
  }

  return {
    getTransfers,
    postTransfer,
    indicationEmail,
    getUsers,
    getUserById,
    editUser,
    autoRegisterUser,
    getOrganizationInfo,
    getOrganizationActions,
    createOrganizationChangeInfo,
    askEmailApproving,
    getRegistrationStatus,
    changeOrganizationInfo,
    changeOrganizationUserDetails,
    getOrganizationVerify,
  }
}

export default useOrganizationApi
