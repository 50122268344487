const multipleItemReducerActionMap = {
  SETUP_FILE_LOADING: 'SETUP_FILE_LOADING',
  SETUP_FILE_ERROR: 'SETUP_FILE_ERROR',
  SETUP_FOLD_STATE: 'SETUP_FOLD_STATE',
  SETUP_FOLDER_NAME: 'SETUP_FOLDER_NAME',
  ADD_FILE: 'ADD_FILE',
  ADD_REJECTED_FILE: 'ADD_REJECTED_FILE',
  REPLACE_ITEM: 'REPLACE_ITEM',
  REMOVE_FILE: 'REMOVE_FILE',
  INSERT_ITEM: 'INSERT_ITEM',
  ADD_FOLDER: 'ADD_FOLDER',
  REMOVE_FOLDER: 'REMOVE_FOLDER',
} as const

export { multipleItemReducerActionMap }
