import { useMemo } from 'react'

type ItemOfListToTransform<KeyId extends string> = {
  [K in KeyId]: unknown
}

const useTransformToListIds = <KeyId extends string>(
  list?: ItemOfListToTransform<KeyId>[],
  customName = 'id',
) => {
  const listIds = useMemo((): string[] => {
    if (!list || !list.length) return []

    return list.map((item): string => item?.[customName])
  }, [list, list?.length])

  return { listIds }
}

export type { ItemOfListToTransform }
export { useTransformToListIds }
