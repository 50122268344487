import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const secondSectionValidationMap: FormValuesValidationSection<
  'changeDocumentCharacteristic' | 'ruleLinkStatementChanges' | 'changeDocumentName',
  NotificationOfChangeDocumentsFormValues
> = {
  ruleLinkStatementChanges: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 50),
  },
  changeDocumentName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  changeDocumentCharacteristic: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { secondSectionValidationMap }
