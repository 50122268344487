import React from 'react'

import { TabListProps } from '../../types'

import { PrimaryTabList } from './Component'
import styles from './primaryTabList.module.scss'

export const PrimaryTabListDesktop = ({ size = 'm', ...restProps }: TabListProps) => (
  <PrimaryTabList {...restProps} size={size} styles={styles} platform="desktop" />
)
