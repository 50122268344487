import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnCreationOtherEstateObjectsBlockValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import ObjectNamesList from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm/PermissionsFormItem/ObjectNamesList'
import { ePermissionAAgreementOnCreationOtherEstateValidationMap } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm/validation'
import { PermissionsObjectsArrayPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isEmptyString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

interface PermissionsFormItemProps {
  isShowPermissionItemContent: boolean
  blockViewIsValidating: boolean
  editMode: boolean
  indexOfBlock: number
  formInstance: UseFormReturn<AAgreementOnCreationOtherEstateObjectsFormValues>
  formName: PermissionsObjectsArrayPathName
}

const PermissionsFormItem: FC<PermissionsFormItemProps> = ({
  isShowPermissionItemContent,
  blockViewIsValidating,
  editMode,
  indexOfBlock,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formInstance.control,
  })

  const permissionRenderCondition = useWatch({
    name: `${formName}.isReceivedPermission`,
    control: formInstance.control,
  })

  const permissionKind = useWatch({
    name: `${formName}.permissionKind`,
    control: formInstance.control,
  })

  const isPaper = useWatch({
    name: aAgreementOnCreationOtherEstateObjectsBlockValues.additionalFields.isPaper,
    control: formInstance.control,
  })

  const stepEndDate = useWatch({
    name: `${aAgreementOnCreationOtherEstateObjectsBlockValues['5'].constructionStages}.${indexOfBlock}.stepEndDate`,
    control: formInstance.control,
  })

  const {
    getSubscribableControlProps,
    getControllerProps,
    getInputProps,
    getCalendarInputProps,
    getSwitchProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode: isNew && editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  })

  return (
    <div>
      <Col xs={12}>
        {!isShowPermissionItemContent && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.permissionOrganization`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={permissionKind?.displayValue || ''}
                  controllerProps={getControllerProps({
                    name: `${formName}.permissionOrganization`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <CollapseWrapper
          defaultExpanded={isShowPermissionItemContent}
          isExpanded={isShowPermissionItemContent}
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.permissionKind`,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.name,
                            value: item.id,
                          }),
                          controllerProps: {
                            name: `${formName}.permissionKind`,
                            rules:
                              ePermissionAAgreementOnCreationOtherEstateValidationMap.permissionKind,
                          },
                          selectProps: {
                            inputProps: {
                              label: 'Наименование',
                            },
                            onChangeFormValue: () =>
                              setTimeout(
                                () => handleChangeValue?.(`${formName}.permissionKind`),
                                0,
                              ),
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.permissionOrganization`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.permissionOrganization`,
                      rules:
                        ePermissionAAgreementOnCreationOtherEstateValidationMap.permissionOrganization,
                      inputProps: {
                        label: 'Орган уполномоченный на выдачу разрешения',
                        caption: 'в творительном падеже',
                      },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.permissionOrganization`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.permissionOrganization`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <RowWithBorder disableBottomDefaultStyles title={'Разрешение будет получено до'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.permissionDate`,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${formName}.permissionDate`,
                    rules: {
                      ...ePermissionAAgreementOnCreationOtherEstateValidationMap.permissionDate,
                      validate: {
                        positiveStepEndDate: (value) => {
                          if (permissionRenderCondition) return

                          if (
                            isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) >
                              dayjs(stepEndDate, objOfDateFormats.defaultFormat)
                          )
                            return 'дата получения разрешения должна быть меньше или равна дате окончания этапа'
                        },
                      },
                    },
                    calendarInputProps: {
                      disabled: permissionRenderCondition || !editMode || !isNew,
                    },
                    onBlur: () => debouncedHandleChangeValue?.(`${formName}.permissionDate`),
                    onInputChange: () => debouncedHandleChangeValue?.(`${formName}.permissionDate`),
                    onCalendarChange: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.permissionDate`), 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            {!isPaper && (
              <Row>
                <Col xs={12}>
                  <Stack direction={'vertical'} gap={2}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.isReceivedPermission`,
                      })}
                    >
                      <ControlledSwitch
                        {...getSwitchProps({
                          name: `${formName}.isReceivedPermission`,
                          switchProps: {
                            wrapperClassName: styles.form__switch,
                            label: 'Разрешение уже получено',
                          },
                          onChange: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${formName}.isReceivedPermission`),
                              0,
                            ),
                        })}
                      />
                    </SubscribableControl>
                    <Stack direction={'vertical'} gap={3}>
                      <CollapseWrapper
                        defaultExpanded={permissionRenderCondition}
                        isExpanded={permissionRenderCondition}
                      >
                        <Row>
                          <Col xs={8}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.permissionNumber`,
                              })}
                            >
                              <ControlledInput
                                {...getInputProps({
                                  name: `${formName}.permissionNumber`,
                                  rules:
                                    ePermissionAAgreementOnCreationOtherEstateValidationMap.permissionNumber,
                                  inputProps: {
                                    label: 'Номер разрешения',
                                    leftAddons: (
                                      <FormIconWithTooltip tooltipContent="Символ № указывать не требуется" />
                                    ),
                                  },
                                  onBlur: () =>
                                    setTimeout(
                                      () => handleChangeValue?.(`${formName}.permissionNumber`),
                                      0,
                                    ),
                                  onChange: () =>
                                    setTimeout(() => {
                                      debouncedHandleChangeValue?.(`${formName}.permissionNumber`)
                                    }, 0),
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                          <Col xs={4}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.receivedPermissionDate`,
                              })}
                            >
                              <ControlledCalendarInput
                                {...getCalendarInputProps({
                                  name: `${formName}.receivedPermissionDate`,
                                  rules: {
                                    ...ePermissionAAgreementOnCreationOtherEstateValidationMap.receivedPermissionDate,
                                    validate: {
                                      positiveCurrentDate: (value) => {
                                        if (isEmptyString(value)) return

                                        const currentDateWithoutTime =
                                          DayjsService.dayjsWithFormatToMSK().format(
                                            objOfDateFormats.defaultFormat,
                                          )

                                        return (
                                          (isDateValidForDayjs(value) &&
                                            dayjs(value, objOfDateFormats.defaultFormat) <=
                                              dayjs(
                                                currentDateWithoutTime,
                                                objOfDateFormats.defaultFormat,
                                              )) ||
                                          'дата выдачи разрешения должна быть меньше или равна текущей дате'
                                        )
                                      },
                                    },
                                  },
                                  calendarInputProps: {
                                    label: 'Дата получения',
                                  },
                                  onBlur: () =>
                                    setTimeout(
                                      () =>
                                        handleChangeValue?.(`${formName}.receivedPermissionDate`),
                                      0,
                                    ),
                                  onInputChange: () =>
                                    debouncedHandleChangeValue?.(
                                      `${formName}.receivedPermissionDate`,
                                    ),
                                  onCalendarChange: () =>
                                    setTimeout(
                                      () =>
                                        handleChangeValue?.(`${formName}.receivedPermissionDate`),
                                      0,
                                    ),
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                        </Row>
                      </CollapseWrapper>
                    </Stack>
                  </Stack>
                </Col>
              </Row>
            )}
            <ObjectNamesList
              blockViewIsValidating={blockViewIsValidating}
              editMode={isNew && editMode}
              formInstance={formInstance}
              formName={formName}
            />
          </Stack>
        </CollapseWrapper>
      </Col>
    </div>
  )
}

export default PermissionsFormItem
