import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { sixSectionValidationMap } from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/7/OldAgreementLinkChangeItem/validation'
import { OldAgreementLinkChangesArrayPathName } from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/7/types'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import { AAChangingVolumeReimbursementFieldsControlUpdateWatcher } from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface OldAgreementLinkChangeItemProps {
  id: string
  formName: OldAgreementLinkChangesArrayPathName
  onRemoveOldAgreementLink: VoidFunction
}

const OldAgreementLinkChangeItem: FC<OldAgreementLinkChangeItemProps> = ({
  id,
  formName,
  onRemoveOldAgreementLink,
}) => {
  const formInstance = useFormContext<AAChangingVolumeReimbursementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAChangingVolumeReimbursementManager()

  const { getSubscribableControlProps, getInputProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate id={id} onRemoveStep={editMode ? onRemoveOldAgreementLink : undefined}>
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Пункт соглашения'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.oldAgreementLink`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.oldAgreementLink`,
                  rules: sixSectionValidationMap.oldAgreementLink,
                  inputProps: {},
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.oldAgreementLink`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.oldAgreementLink`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.oldAgreementLinkNewRedaction`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.oldAgreementLinkNewRedaction`,
                    rules: sixSectionValidationMap.oldAgreementLinkNewRedaction,
                    textareaProps: {
                      label: 'Пункт соглашения в новой редакции',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.oldAgreementLinkNewRedaction`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.oldAgreementLinkNewRedaction`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default OldAgreementLinkChangeItem
