import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NoticeIntentionTerminateSZPKLayout from './Layout'
import NoticeIntentionTerminateSZPKModalManager from './Manager'
import NoticeIntentionTerminateSZPKMenu from './Menu'
import NoticeIntentionTerminateSZPKStatementForm from './Statement'

interface INoticeIntentionTerminateSZPK extends FC {
  Layout: typeof NoticeIntentionTerminateSZPKLayout
  Menu: typeof NoticeIntentionTerminateSZPKMenu
  StatementForm: typeof NoticeIntentionTerminateSZPKStatementForm
  Manager: typeof NoticeIntentionTerminateSZPKModalManager
}

const useNoticeIntentionTerminateSZPKControl = () => {
  const popupManager = usePopupManager()

  const openNoticeIntentionTerminateSZPKModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(NoticeIntentionTerminateSZPKLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openNoticeIntentionTerminateSZPKModal }
}

const NoticeIntentionTerminateSZPK: INoticeIntentionTerminateSZPK = () => null

NoticeIntentionTerminateSZPK.Menu = NoticeIntentionTerminateSZPKMenu
NoticeIntentionTerminateSZPK.Layout = NoticeIntentionTerminateSZPKLayout
NoticeIntentionTerminateSZPK.StatementForm = NoticeIntentionTerminateSZPKStatementForm
NoticeIntentionTerminateSZPK.Manager = NoticeIntentionTerminateSZPKModalManager

export { useNoticeIntentionTerminateSZPKControl }
export default NoticeIntentionTerminateSZPK
