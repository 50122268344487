import React, { FC, ReactNode } from 'react'

import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { Transition } from '@headlessui/react'
import { isFunction } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import { IDocumentAction } from '@services/DocumentActions/DocumentActions.entity'
import cn from 'classnames'

import styles from '../NotificationsOfRequisitesChanges.module.scss'

type ActionsFn = (actionsContainerClassName: string) => ReactNode

export interface INotificationItem {
  actions: ReactNode | ReactNode[] | ActionsFn
  name: string
  projectId: string
  dfoId: string
  date?: string
  fileInfo?: string
  loading?: boolean
  isActiveActions?: boolean
  uiAction?: {
    handler: () => void
    displayName: IDocumentAction['displayName']
    disabled: boolean
  }
}

export const NotificationAttachment: FC<INotificationItem> = ({
  actions,
  date,
  fileInfo,
  isActiveActions,
  uiAction,
}) => {
  const {
    booleanState: isActionsShown,
    setBooleanStateToFalse: hideActions,
    setBooleanStateToTrue: showActions,
  } = useBooleanState()

  const mustShowActions = isActionsShown || isActiveActions

  return (
    <div className={styles.Row} onMouseEnter={showActions} onMouseLeave={hideActions}>
      <Typography variant={'bodyMMedium'} className={cn(styles.Row_item, styles.Row_item_name)}>
        Уведомление о смене реквизитов
      </Typography>
      <div className={styles['Row_item-container']}>
        <Typography
          color={'text-base-tertiary'}
          variant={'bodySMedium'}
          className={cn(styles.Row_item, styles.Row_item_date, {
            [styles.loading]: typeof date === undefined,
          })}
        >
          {date}
        </Typography>
        <Typography
          color={'text-base-tertiary'}
          variant={'bodySMedium'}
          className={cn(styles.Row_item, styles.Row_item_fileInfo, {
            [styles.loading]: typeof fileInfo === undefined,
          })}
        >
          {fileInfo}
        </Typography>
        <Transition
          show={mustShowActions}
          className={styles.actionsWrapper}
          enter={styles.transition__enter}
          enterFrom={styles.transition__enterFrom}
          enterTo={styles.transition__enterTo}
          leave={styles.transition__leave}
          leaveFrom={styles.transition__leaveFrom}
          leaveTo={styles.transition__leaveTo}
        >
          <div
            role={'presentation'}
            className={cn(styles.Row_item, styles.Row_item_action)}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {isFunction(actions) ? actions(styles.actions) : actions}
          </div>
        </Transition>
        {uiAction && (
          <Button
            className={styles.actions__uiAction}
            size={'s'}
            color={'default'}
            variant={'buttonSMedium'}
            view={'plain'}
            disabled={uiAction.disabled}
            onClick={uiAction.handler}
          >
            {uiAction?.displayName}
          </Button>
        )}
      </div>
    </div>
  )
}
