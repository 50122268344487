import { PUBLIC_AUTHORIZED_USERS } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'

import { List } from './types'

const useInnsApi = () => {
  const { Fetcher } = useAuthContext()

  const getList = async () => {
    try {
      const { data }: List = await Fetcher.get({
        url: PUBLIC_AUTHORIZED_USERS,
        header: 'Default',
      })
      return data
    } catch (error) {
      throw error
    }
  }

  return {
    getList,
  }
}

export default useInnsApi
