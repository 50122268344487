import React, { FC, memo, useCallback } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import {
  FormLayoutValues,
  ICardViewRefs,
} from '@components/Projects/[id]/DocumentLayout/Addons/NpaAddonOld'
import { INpaDraftBody } from '@services/NPA/NPA.entity'

import ClassificationViewOld from '../ClassificationOld'

interface ClassificationsViewOldProps {
  directionIndex: number
  layoutThree: UseFormReturn<FormLayoutValues>
  handleBindCardRef: (callback: (prevState: ICardViewRefs | null) => ICardViewRefs) => void
  onUpdateDraftModel: (updatedChosenParts: INpaDraftBody) => Promise<void>
  updateAllLayoutThree: (partToScrollAfterUpdate: string) => Promise<void>
  removePartDirection: () => void
}

const ClassificationsViewOld: FC<ClassificationsViewOldProps> = ({
  layoutThree,
  directionIndex,
  handleBindCardRef,
  onUpdateDraftModel,
  updateAllLayoutThree,
  removePartDirection,
}) => {
  const { fields: classifications, remove } = useFieldArray({
    name: `npaStructure.${directionIndex}.classifications`,
    control: layoutThree.control,
    keyName: 'classificationId',
  })

  const removePartClassification = useCallback(
    (index) => () => {
      remove(index)

      const copyClassifications = Array.from(classifications)
      copyClassifications.splice(index, 1)

      if (!copyClassifications.length) removePartDirection()
    },
    [classifications, remove, removePartDirection],
  )

  return (
    <>
      {classifications.map((classification, index) => (
        <ClassificationViewOld
          key={classification.classificationId}
          nameOfFieldArray={`npaStructure.${directionIndex}.classifications.${index}`}
          classification={classification}
          layoutThree={layoutThree}
          handleBindCardRef={handleBindCardRef}
          updateAllLayoutThree={updateAllLayoutThree}
          removePartClassification={removePartClassification(index)}
          onUpdateDraftModel={onUpdateDraftModel}
        />
      ))}
    </>
  )
}

export default memo(ClassificationsViewOld)
