import { useCallback } from 'react'

import { useFormPreview } from '@components/DocumentFormComponents/hooks/useFormPreview'
import {
  UseFormSubmitInManager,
  useFormSubmitInManager,
} from '@components/DocumentFormComponents/hooks/useFormSubmit'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'

interface UseFormActionsProps extends UseFormSubmitInManager {
  handleApplyChanges: () => Promise<void>
  onErrorCheckForm?: VoidFunction
  onClearError?: VoidFunction
}

const useFormActions = ({
  formId,
  onChangeBlockValidation,
  handleApplyChanges,
  onErrorCheckForm,
  onClearError,
  watcher,
}: UseFormActionsProps) => {
  const { handleCheckForm: handleCheckFormAfterApply } = useFormSubmitInManager({
    formId,
    watcher,
    onChangeBlockValidation,
  })
  const { handleGetFormPreview } = useFormPreview({ formId })

  const { handleOpenLoadingModal } = useLoadingModal()

  const preparedHandleCheckForm = useCallback(async () => {
    const closeHandler = handleOpenLoadingModal('Проверка корректности заполнения')

    try {
      await handleApplyChanges()
    } catch {}

    try {
      await handleCheckFormAfterApply()
    } catch {
      onErrorCheckForm?.()
    } finally {
      closeHandler?.()?.()
    }
  }, [handleApplyChanges, handleCheckFormAfterApply, handleOpenLoadingModal, onErrorCheckForm])

  const preparedHandlePreviewForm = useCallback(async () => {
    try {
      await preparedHandleCheckForm()
    } catch {}

    try {
      await handleGetFormPreview()
    } catch {}
  }, [preparedHandleCheckForm, handleGetFormPreview])

  const handleCheckForm = useCallback(async () => {
    onClearError?.()

    await preparedHandleCheckForm()
  }, [onClearError, preparedHandleCheckForm])

  const handlePreviewForm = useCallback(async () => {
    onClearError?.()

    await preparedHandlePreviewForm()
  }, [onClearError, preparedHandlePreviewForm])

  return {
    handleCheckForm,
    handlePreviewForm,
  }
}

export { useFormActions }
