import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { TechnologicalRequirementsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/10/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { technologicalRequirementsValidationMap } from './validation'

interface TechnologicalRequirementsFormProps {
  id: string
  title: string
  indexOfRequirement: number
  lastIndexOfRequirement: number
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  name: TechnologicalRequirementsArrayPathName
  onRemoveObjectStep?: VoidFunction
  onMoveTop?: () => Promise<void>
  onMoveBottom?: () => Promise<void>
  leftAddons?: ReactNode
}

const TechnologicalRequirementsForm: FC<TechnologicalRequirementsFormProps> = ({
  id,
  title,
  indexOfRequirement,
  lastIndexOfRequirement,
  formInstance,
  formModifierInstance,
  name,
  onRemoveObjectStep,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    control: formModifierInstance.control,
    name: `${name}.isNew`,
  })

  const { getFormFieldControlProps, getTextareaProps } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  return (
    <FieldView.StepTemplate
      title={title}
      dataTestId="projectNpaListItem"
      leftAddons={leftAddons}
      id={id}
      anchorId={name}
      additionalNode={
        editMode && (
          <FieldArraySwapper
            index={indexOfRequirement}
            lastIndex={lastIndexOfRequirement}
            onMoveTop={onMoveTop}
            onMoveBottom={onMoveBottom}
          />
        )
      }
      onRemoveStep={editMode ? onRemoveObjectStep : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.projectNpaName`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectNpaName`)
                    }, 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.projectNpaName`,
                  rules: technologicalRequirementsValidationMap.projectNpaName,
                  textareaProps: {
                    dataTestId: 'projectNpaName',
                    label: 'Наименование и реквизиты акта',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectNpaName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.projectNpaName`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default TechnologicalRequirementsForm
