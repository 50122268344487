import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForNotificationOfIdentifiedNPAListErrorsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    '2': PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameInstrumental: {
        propertyId: objectFromPropertyState.investorFullNameInstrumental?.propertyId,
        type: objectFromPropertyState.investorFullNameInstrumental?.type,
        lastUpdateDt: objectFromPropertyState.investorFullNameInstrumental?.lastUpdateDt,
      },
    },
    '4': {
      ruleLinkNpaReturn: {
        propertyId: objectFromPropertyState.ruleLinkNpaReturn?.propertyId,
        type: objectFromPropertyState.ruleLinkNpaReturn?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkNpaReturn?.lastUpdateDt,
      },
      npaErrors: {
        propertyId: objectFromPropertyState.stabilizationNpaErrors?.propertyId,
        type: objectFromPropertyState.stabilizationNpaErrors?.type,
        lastUpdateDt: objectFromPropertyState.stabilizationNpaErrors?.lastUpdateDt,
        value: isArray(objectFromPropertyState.stabilizationNpaErrors?.value)
          ? objectFromPropertyState.stabilizationNpaErrors?.value.map((error) => ({
              propertyId: error.propertyId,
              lastUpdateDt: error.lastUpdateDt,
              value: {
                errorText: {
                  propertyId: error.value?.stabilizationNpaErrorText?.propertyId,
                  type: error.value?.stabilizationNpaErrorText?.type,
                  lastUpdateDt: error.value?.stabilizationNpaErrorText?.lastUpdateDt,
                },
              },
            }))
          : [],
      },
    },
  }
}

export { generatePropertiesObjectForNotificationOfIdentifiedNPAListErrorsForm }
