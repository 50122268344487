import React, { FC } from 'react'

import Typography from '@components/NewDesign/Typography'

import styles from './CreateProjectExample.module.scss'

interface CreateProjectExampleProps {
  title: string
  subtitle: string
}

const CreateProjectExample: FC<CreateProjectExampleProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.example}>
      <Typography variant={'bodyMMedium'}>{title}</Typography>
      <Typography.Caption
        className={styles.example__subtitle}
        color={'accent-brand-faded-secondary'}
        variant={'captionSRegular'}
      >
        {subtitle}
      </Typography.Caption>
    </div>
  )
}

export default CreateProjectExample
