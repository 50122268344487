import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { defaultStatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/const'
import { StatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/types'

const generateRHFObjectForStatementInvestments = (
  objectFromPropertyState: Record<string, any>,
): StatementAlreadyInvestmentsFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      madeInvestorCapital:
        objectFromPropertyState.madeInvestorCapital?.value ||
        defaultStatementAlreadyInvestmentsFormValues['2'].madeInvestorCapital,
      remainsInvestorCapital:
        objectFromPropertyState.remainsInvestorCapital?.value ||
        defaultStatementAlreadyInvestmentsFormValues['2'].remainsInvestorCapital,
    },
  }
}

export { generateRHFObjectForStatementInvestments }
