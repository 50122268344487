import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfChangesInDetailsOfPartiesBlockValues } from '@components/Forms/NotificationOfChangesInDetailsOfParties/const'
import { useNotificationOfChangesInDetailsOfPartiesManager } from '@components/Forms/NotificationOfChangesInDetailsOfParties/Manager'
import { NotificationOfChangesInDetailsOfPartiesFormValues } from '@components/Forms/NotificationOfChangesInDetailsOfParties/types'
import { NotificationOfChangesInDetailsOfPartiesFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfChangesInDetailsOfParties/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { secondSectionValidationMap } from './validation'

const Second = () => {
  const formInstance = useFormContext<NotificationOfChangesInDetailsOfPartiesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfChangesInDetailsOfPartiesManager()

  const { getSubscribableControlProps, getControllerProps, getInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: NotificationOfChangesInDetailsOfPartiesFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Пункт Правил, на основании которого выполняется смена реквизитов'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].ruleLinkInfoChanges,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationOfChangesInDetailsOfPartiesBlockValues['2']
                      .ruleLinkInfoChanges,
                    rules: secondSectionValidationMap.ruleLinkInfoChanges,
                    inputProps: {
                      caption: 'рекомендация: 58',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfChangesInDetailsOfPartiesBlockValues['2']
                              .ruleLinkInfoChanges,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfChangesInDetailsOfPartiesBlockValues['2']
                            .ruleLinkInfoChanges,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Полное наименование'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorAddressEgrul,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Адрес организации'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2']
                    .initiatorAddressEgrul,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorLocation,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Место нахождения'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorLocation,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorKpp,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'КПП'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorKpp,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorOktmo,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОКТМО'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorOktmo,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2']
                  .initiatorPaymentDetails,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Платежные реквизиты'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2']
                    .initiatorPaymentDetails,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfChangesInDetailsOfPartiesBlockValues['2'].initiatorOtherDetails,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Прочие реквизиты'}
                controllerProps={getControllerProps({
                  name: notificationOfChangesInDetailsOfPartiesBlockValues['2']
                    .initiatorOtherDetails,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
