import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { percentCreditCheckerCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/6/PercentCreditChecker/PercentCreditMembersCheckerList/validation'
import { CertificateOnMonitoringResultsPercentCreditMembersCheckerPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/6/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import Button from '@components/NewDesign/Button'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

interface CertificateOnMonitoringResultsPercentCreditMembersCheckerListProps {
  editMode: boolean
  blockViewIsValidating: boolean
  name: CertificateOnMonitoringResultsPercentCreditMembersCheckerPathName
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
}

const CertificateOnMonitoringResultsPercentCreditMembersCheckerList: FC<
  CertificateOnMonitoringResultsPercentCreditMembersCheckerListProps
> = ({ editMode, blockViewIsValidating, name, formInstance }) => {
  const {
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleChangeValue,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  const { fields: percentCreditMembersChecker } = useFieldArraySubscribableControl<
    CertificateOnMonitoringResultsFormValues,
    CertificateOnMonitoringResultsPercentCreditMembersCheckerPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name,
    keyName: 'keyNameId',
    watcher: CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const handleAddPercentCreditMembersChecker = async () => {
    await handleAddItemToListWithOutValue?.(name)
  }

  const handleRemovePercentCreditMembersChecker = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  return (
    <Group disableBottomBorder title="Стороны договора">
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: name,
          })}
        >
          <FlipperList list={percentCreditMembersChecker}>
            {percentCreditMembersChecker.map((member, index) => {
              const memberName = `${name}.${index}` as const

              return (
                <FieldView.RowWithRemoveButton
                  key={member.id}
                  id={member.id}
                  onRemove={editMode ? handleRemovePercentCreditMembersChecker(index) : undefined}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${memberName}.percentCreditMemberChecker`,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: `${memberName}.percentCreditMemberChecker`,
                        rules:
                          percentCreditCheckerCertificateOnMonitoringResultsValidationMap.percentCreditMemberChecker,
                        textareaProps: {
                          label: 'Сторона договора',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${memberName}.percentCreditMemberChecker`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${memberName}.percentCreditMemberChecker`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </FieldView.RowWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddPercentCreditMembersChecker}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить сторону договора
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default CertificateOnMonitoringResultsPercentCreditMembersCheckerList
