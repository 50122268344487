import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'

const generateRHFObjectForNoticeOfProjectRejectConcludeSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): NoticeOfProjectRejectConcludeSZPKFormValues => {
  return {
    '1': {
      documentReceiversDraft: objectFromPropertyState?.documentReceiversDraft?.value || '',
    },
    '2': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive:
        objectFromPropertyState.investorFullNameGenitiveDraft?.value || '',
      investorFullNameInstrumental:
        objectFromPropertyState.investorFullNameInstrumentalDraft?.value || '',
    },
    '3': {
      projectName: objectFromPropertyState.projectName?.value || '',
      ruleLinkStatementRejectDraft:
        objectFromPropertyState.ruleLinkStatementRejectDraft?.value || '',
      statementRejectReasonDraft: objectFromPropertyState.statementRejectReasonDraft?.value || '',
      ruleLinkStatementRepeatDraft:
        objectFromPropertyState.ruleLinkStatementRepeatDraft?.value || '',
    },
  }
}

export { generateRHFObjectForNoticeOfProjectRejectConcludeSZPKForm }
