import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOnMonitoringResultsDismantlingObjectChecker,
  CertificateOnMonitoringResultsFormValues,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const dismantlingObjectCheckerCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsDismantlingObjectChecker,
  CertificateOnMonitoringResultsFormValues
> = {
  dismantlingObjectNameChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { dismantlingObjectCheckerCertificateOnMonitoringResultsValidationMap }
