import { useEffect } from 'react'

import { isArray, isReactRef } from '@helpers/checkTypes'

import { ElementOrElementsToObserve } from './types'

type UseObserverProps = {
  observerInstance: ResizeObserver | IntersectionObserver | null
  elementsToObserve: ElementOrElementsToObserve
  conditionToSkip?: boolean
}

export const useObserver = ({
  observerInstance,
  elementsToObserve,
  conditionToSkip,
}: UseObserverProps) => {
  useEffect(() => {
    const targetElements = isArray(elementsToObserve) ? elementsToObserve : [elementsToObserve]

    if (!targetElements || conditionToSkip || !observerInstance) return

    targetElements.forEach((element) => {
      const currentElement = isReactRef(element) ? element.current : element
      if (currentElement) observerInstance.observe(currentElement)
    })

    return () => {
      observerInstance.disconnect()
    }
  }, [observerInstance, elementsToObserve, conditionToSkip])
}
