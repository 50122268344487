import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import ObjectNamesList from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm/PermissionsFormItem/ObjectNamesList'
import { ePermissionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm/validation'
import { SeventhPermissionsObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isEmptyString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

interface PermissionsFormItemProps {
  isShowPermissionItemContent: boolean
  blockViewIsValidating: boolean
  editMode: boolean
  indexOfBlock: number
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  formName: SeventhPermissionsObjectsArrayPathName
}

const PermissionsFormItem: FC<PermissionsFormItemProps> = ({
  isShowPermissionItemContent,
  blockViewIsValidating,
  editMode,
  indexOfBlock,
  formInstance,
  formModifierInstance,
  formName,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const permissionRenderCondition = useWatch({
    name: `${formName}.isReceivedPermission`,
    control: formInstance.control,
  })

  const permissionKind = useWatch({
    name: `${formName}.permissionKind`,
    control: formInstance.control,
  })

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formModifierInstance.control,
  })

  const stepEndDate = useWatch({
    name: `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${indexOfBlock}.stepEndDate`,
    control: formInstance.control,
  })

  const {
    getSubscribableControlProps,
    getFormFieldControlProps,
    getControllerProps,
    getCalendarInputProps,
    getInputProps,
    getSwitchProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  return (
    <div>
      <Col xs={12}>
        {!isShowPermissionItemContent && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.permissionOrganization`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={permissionKind?.displayValue || ''}
                  controllerProps={getControllerProps({
                    name: `${formName}.permissionOrganization`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <CollapseWrapper
          defaultExpanded={isShowPermissionItemContent}
          isExpanded={isShowPermissionItemContent}
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.permissionKind`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.permissionKind`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.name,
                            value: item.id,
                          }),
                          controllerProps: {
                            name: `${formName}.permissionKind`,
                            rules:
                              ePermissionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.permissionKind,
                          },
                          selectProps: {
                            inputProps: {
                              label: 'Наименование',
                            },
                            onChangeFormValue: () =>
                              setTimeout(
                                () => handleChangeValue?.(`${formName}.permissionKind`),
                                0,
                              ),
                          },
                        })}
                      />
                    )
                  }}
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.permissionOrganization`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.permissionOrganization`),
                          0,
                        ),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.permissionOrganization`,
                      rules:
                        ePermissionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.permissionOrganization,
                      inputProps: {
                        label: 'Орган, уполномоченный на выдачу разрешения',
                        caption: 'в творительном падеже',
                      },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.permissionOrganization`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.permissionOrganization`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <RowWithBorder disableBottomDefaultStyles title={'Разрешение будет получено до'}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.permissionDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.permissionDate`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${formName}.permissionDate`,
                    rules: {
                      ...ePermissionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.permissionDate,
                      validate: {
                        positiveStepEndDate: (value) => {
                          if (permissionRenderCondition) return

                          return (
                            (isDateValidForDayjs(value) &&
                              DayjsService.dayjs(value, objOfDateFormats.defaultFormat) <=
                                DayjsService.dayjs(stepEndDate, objOfDateFormats.defaultFormat)) ||
                            'дата получения разрешения должна быть меньше или равна дате окончания этапа'
                          )
                        },
                      },
                    },
                    calendarInputProps: {
                      disabled: permissionRenderCondition || !editMode,
                    },
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.permissionDate`), 0),
                    onCalendarChange: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.permissionDate`), 0),
                    onInputChange: () => debouncedHandleChangeValue?.(`${formName}.permissionDate`),
                  })}
                />
              </FormFieldControl>
            </RowWithBorder>
            <Row>
              <Col xs={12}>
                <Stack direction={'vertical'} gap={2}>
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: `${formName}.isReceivedPermission`,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(
                            () => handleChangeValue?.(`${formName}.isReceivedPermission`),
                            0,
                          ),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledSwitch
                      {...getSwitchProps({
                        name: `${formName}.isReceivedPermission`,
                        switchProps: {
                          wrapperClassName: styles.form__switch,
                          label: 'Разрешение уже получено',
                        },
                        onChange: () =>
                          setTimeout(
                            () => handleChangeValue?.(`${formName}.isReceivedPermission`),
                            0,
                          ),
                      })}
                    />
                  </FormFieldControl>
                  <Stack direction={'vertical'} gap={3}>
                    <CollapseWrapper
                      defaultExpanded={permissionRenderCondition}
                      isExpanded={permissionRenderCondition}
                    >
                      <Row>
                        <Col xs={8}>
                          <FormFieldControl
                            {...getFormFieldControlProps({
                              path: `${formName}.permissionNumber`,
                              formFieldTooltipProps: {
                                onChange: () =>
                                  setTimeout(
                                    () => handleChangeValue?.(`${formName}.permissionNumber`),
                                    0,
                                  ),
                                onDifference: handleUpdateChanges,
                              },
                            })}
                          >
                            <ControlledInput
                              {...getInputProps({
                                name: `${formName}.permissionNumber`,
                                rules:
                                  ePermissionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.permissionNumber,
                                inputProps: {
                                  label: 'Серия (если применимо) и номер разрешения',
                                  leftAddons: (
                                    <FormIconWithTooltip tooltipContent='Если требуется указать серию разрешения, то укажите в формате "серии ___ № ___", для номера разрешения укажите символ №' />
                                  ),
                                },
                                onBlur: () =>
                                  setTimeout(
                                    () => handleChangeValue?.(`${formName}.permissionNumber`),
                                    0,
                                  ),
                                onChange: () =>
                                  setTimeout(() => {
                                    debouncedHandleChangeValue?.(`${formName}.permissionNumber`)
                                  }, 0),
                              })}
                            />
                          </FormFieldControl>
                        </Col>
                        <Col xs={4}>
                          <FormFieldControl
                            {...getFormFieldControlProps({
                              path: `${formName}.receivedPermissionDate`,
                              formFieldTooltipProps: {
                                onChange: () =>
                                  setTimeout(
                                    () => handleChangeValue?.(`${formName}.receivedPermissionDate`),
                                    0,
                                  ),
                                onDifference: handleUpdateChanges,
                              },
                            })}
                          >
                            <ControlledCalendarInput
                              {...getCalendarInputProps({
                                name: `${formName}.receivedPermissionDate`,
                                rules: {
                                  ...ePermissionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.receivedPermissionDate,
                                  validate: {
                                    positiveCurrentDate: (value) => {
                                      if (isEmptyString(value)) return

                                      const currentDateWithoutTime =
                                        DayjsService.dayjsWithFormatToMSK().format(
                                          objOfDateFormats.defaultFormat,
                                        )

                                      return (
                                        (isDateValidForDayjs(value) &&
                                          DayjsService.dayjs(
                                            value,
                                            objOfDateFormats.defaultFormat,
                                          ) <=
                                            DayjsService.dayjs(
                                              currentDateWithoutTime,
                                              objOfDateFormats.defaultFormat,
                                            )) ||
                                        'дата выдачи разрешения должна быть меньше, чем текущая дата'
                                      )
                                    },
                                  },
                                },
                                calendarInputProps: {
                                  label: 'Дата получения',
                                },
                                onBlur: () =>
                                  setTimeout(
                                    () => handleChangeValue?.(`${formName}.receivedPermissionDate`),
                                    0,
                                  ),
                                onInputChange: () =>
                                  debouncedHandleChangeValue?.(
                                    `${formName}.receivedPermissionDate`,
                                  ),
                                onCalendarChange: () =>
                                  setTimeout(
                                    () => handleChangeValue?.(`${formName}.receivedPermissionDate`),
                                    0,
                                  ),
                              })}
                            />
                          </FormFieldControl>
                        </Col>
                      </Row>
                    </CollapseWrapper>
                  </Stack>
                </Stack>
              </Col>
            </Row>
            <ObjectNamesList
              blockViewIsValidating={blockViewIsValidating}
              editMode={editMode}
              formInstance={formInstance}
              formModifierInstance={formModifierInstance}
              formName={formName}
            />
          </Stack>
        </CollapseWrapper>
      </Col>
    </div>
  )
}

export default PermissionsFormItem
