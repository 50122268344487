import { useCallback } from 'react'

import { SetFormLoadingHandler } from '@components/DocumentFormComponents/hooks/useFormMount'
import { GetAdvancedPropsFromObjectAdapterPropsHandler } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SubscriberProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import { Property } from '@services/Properties/OOP/Property'
import { IOverridePropsFromClass } from '@services/Properties/Properties.entity'

interface UseNotifyAllSubscribersProps {
  watcher: FieldsControlUpdateWatcher
  getPropertyInstance: () => Property | null
  processOverrideProps: (
    arrayOfProps: IOverridePropsFromClass[],
    prefetchedIds?: string[],
  ) => Promise<SubscriberProps[]>
}

const useNotifyAllSubscribers = ({
  watcher,
  getPropertyInstance,
  processOverrideProps,
}: UseNotifyAllSubscribersProps) => {
  const handleNotifyAllSubscribers = useCallback(
    async (setFormLoading: SetFormLoadingHandler) => {
      const propertyInstance = getPropertyInstance()

      if (!propertyInstance) return

      const overrideProps = await processOverrideProps(propertyInstance.generateOverrideProps())

      setFormLoading(false)

      setTimeout(() => {
        watcher.notifyAllSubscribers(overrideProps)
      }, 0)
    },
    [getPropertyInstance, processOverrideProps, watcher],
  )

  const handleNotifyAllSubscribersWithAdvancedProps = useCallback(
    (getAdvancedPropsFromObjectAdapter: GetAdvancedPropsFromObjectAdapterPropsHandler) =>
      async (setFormLoading: SetFormLoadingHandler) => {
        const propertyInstance = getPropertyInstance()

        if (!propertyInstance) return

        const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

        const { customProps, prefetchedIds } = await getAdvancedPropsFromObjectAdapter(
          objectForAdapters,
        )

        const overrideProps = await processOverrideProps(
          [...propertyInstance.generateOverrideProps(), ...customProps],
          prefetchedIds,
        )

        setFormLoading(false)

        setTimeout(() => {
          watcher.notifyAllSubscribers(overrideProps)
        }, 0)
      },
    [getPropertyInstance, processOverrideProps, watcher],
  )

  return {
    handleNotifyAllSubscribers,
    handleNotifyAllSubscribersWithAdvancedProps,
  }
}

export { useNotifyAllSubscribers }
