import { usePopupManager } from 'react-popup-manager'

import ManageOperatorSidebar from '@components/Sidebars/Users/ManageOperatorSidebar'
import ManageParticipantSidebar from '@components/Sidebars/Users/ManageParticipantSidebar'
import type { Operator, Participant } from '@context/APIContext/hooks/useOperatorApi'

interface ManageUsersSidebar<Data extends Participant | Operator> {
  onSuccess: () => Promise<void>
  user?: Data
}

const useManageUsersSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenManageParticipantSidebar = ({
    user,
    onSuccess,
  }: ManageUsersSidebar<Participant>) => {
    popupManager.open(ManageParticipantSidebar, {
      onClose: () => {},
      onSuccess,
      user,
    })
  }

  const handleOpenManageOperatorSidebar = ({ user, onSuccess }: ManageUsersSidebar<Operator>) => {
    popupManager.open(ManageOperatorSidebar, {
      onClose: () => {},
      onSuccess,
      user,
    })
  }

  return {
    handleOpenManageParticipantSidebar,
    handleOpenManageOperatorSidebar,
  }
}

export default useManageUsersSidebar
