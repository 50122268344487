import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { AAConnectionReorganizationImplementingFormValues } from '@components/Forms/AAConnectionReorganizationImplementingForm/types'

const generateRHFObjectForAAConnectionReorganizationImplementingForm = (
  objectFromPropertyState: Record<string, any>,
): AAConnectionReorganizationImplementingFormValues => {
  return {
    '1': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...RHFBasicAdapters.getInvestorPartnerAdapter(objectFromPropertyState),
      investorPartnerEgrulAddress:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value
          ?.addressFullEgrulFormat,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  } as AAConnectionReorganizationImplementingFormValues
}

export { generateRHFObjectForAAConnectionReorganizationImplementingForm }
