import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'

import { ApplicationOfConclusionAgreementSZPKFormValues } from '../types'

const generateRHFObjectForApplicationOfConclusionAgreementSZPKRegisterForm = (
  objectFromPropertyState: Record<string, any>,
): ApplicationOfConclusionAgreementSZPKFormValues => {
  return {
    '1': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      projectName: objectFromPropertyState.projectName?.value || '',
    },
  }
}

export { generateRHFObjectForApplicationOfConclusionAgreementSZPKRegisterForm }
