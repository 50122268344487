import { Path } from 'react-hook-form'

import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfProjectSZPKMenu = [
  { id: '1.1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '1.2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '1.3', title: 'Организация, реализующая проект' },
  { id: '2.1', title: 'Предмет соглашения' },
  { id: '2.2', title: 'Описание проекта' },
  { id: '3.1', title: 'Этапы реализации проекта' },
  { id: '3.2', title: 'Основные мероприятия этапа' },
  { id: '3.3', title: 'Иные мероприятия этапа ' },
  { id: '3.4', title: 'Результаты реализации проекта' },
  { id: '3.5', title: 'Технологические и экологические требования' },
  { id: '4.1', title: 'Права и обязанности публично-правовых образований' },
  { id: '4.3', title: 'Стабилизационная оговорка' },
  { id: '5.1', title: 'Условия связанных договоров' },
  {
    id: '5.2',
    title: 'Информация об исполнении условий соглашения',
  },
  { id: '5.3', title: 'Разрешение споров' },
]

const defaultProjectSZPKFormValue: ProjectSZPKFormValues = {
  '1': {
    rfFullName: '',
    rfName: '',
    rfAddress: '',
    rfInn: '',
    rfKpp: '',
    rfOgrn: '',
    rfOktmo: '',
    rfPaymentDetails: '',
    rfOtherDetails: '',
    rfHeaderName: null,
    rfHeaderNameGenitive: '',
    rfHeaderPosition: '',
    rfHeaderPositionGenitive: '',
    rfHeaderReasonGenitive: '',
  },
  '2': {
    subjects: [
      {
        id: '1',
        subjectFullName: '',
        subjectRegion: '',
        subjectAddress: '',
        subjectInn: '',
        subjectKpp: '',
        subjectOgrn: '',
        subjectOktmo: '',
        subjectOtherDetails: '',
        subjectPaymentDetails: '',
        subjectHeaderName: null,
        subjectHeaderNameGenitive: '',
        subjectHeaderPosition: '',
        subjectHeaderPositionGenitive: '',
        subjectHeaderReasonGenitive: '',
      },
    ],
  },
  '3': {
    ...getDefaultInvestorFieldState(),
    investorInn: '',
    investorOgrn: '',
    investorKpp: '',
    investorOktmo: '',
    investorPaymentDetails: '',
    investorDetails: '',
    investorLocation: '',
    investorAddress: '',
  },
  '4': {
    ruleSubjectSzpkInstrumental: '',
    ruleLinkStabilizationNpa: '',
  },
  '5': {
    projectName: '',
    projectRegion: null,
    economicSphere: null,
    otherEconomicSphere: '',
    projectPurpose: '',
    projectStartDate: '',
    projectDecisionDepartmentGenitive: '',
    totalCapital: '',
    investorCapital: '',
    madeInvestorCapital: '',
    remainsInvestorCapital: '',
    madeInvestorCapitalDate: '',
    projectSupportCompensation: '',
    projectRelatedCompensation: '',
  },
  '6': {
    stages: [],
  },
  '7': {
    eventsOfStages: [],
  },
  '8': {
    stepActivityOthers: [],
  },
  '9': {
    projectResultEcoActivity: {
      ecoActivityName: null,
      ecoActivityProgramNameInstrumental: '',
      ecoActivityEndDate: '',
    },
    otherProjectResultActivity: '',
    projectResultActivity: [],
    projectResults: [],
  },
  '10': {
    technologicalRequirements: [
      {
        id: '',
        projectNpaName: '',
      },
    ],
  },
  '11': {
    rightDutySubjectForestResource: false,
    rightDutySubjectWaterResource: false,
    rightDutyRfForestResource: false,
    rightDutyRfWaterResource: false,
  },
  '13': {
    extraMunicipalAccessionLinkCompensation: '',
    extraMunicipalAccessionLinkStabilization: '',
    stabilizationPeriod: '',
  },
  '14': {
    ruleLinkRelatedContract: '',
    subjectRuleLinkRelatedContract: '',
  },
  '15': {
    subjectNpaReasonMonitoring: '',
    ruleLinkMonitoring: '',
  },
  '16': {
    courtChoice: false,
    courtName: '',
  },
  additionalFields: {
    isFederal: true,
    isMunicipalityParticipant: true,
    projectEndDate: '',
    anotherStageOptions: [],
    allProjectObjectsLength: 0,
  },
}

const projectSZPKBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfName: '1.rfName',
    rfAddress: '1.rfAddress',
    rfInn: '1.rfInn',
    rfOgrn: '1.rfOgrn',
    rfKpp: '1.rfKpp',
    rfOktmo: '1.rfOktmo',
    rfPaymentDetails: '1.rfPaymentDetails',
    rfOtherDetails: '1.rfOtherDetails',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorKpp: '3.investorKpp',
    investorOktmo: '3.investorOktmo',
    investorPaymentDetails: '3.investorPaymentDetails',
    investorDetails: '3.investorDetails',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
    investorLocation: '3.investorLocation',
    investorAddress: '3.investorAddress',
  },
  '4': {
    ruleSubjectSzpkInstrumental: '4.ruleSubjectSzpkInstrumental',
    ruleLinkStabilizationNpa: '4.ruleLinkStabilizationNpa',
  },
  '5': {
    projectName: '5.projectName',
    projectRegion: '5.projectRegion',
    economicSphere: '5.economicSphere',
    otherEconomicSphere: '5.otherEconomicSphere',
    projectPurpose: '5.projectPurpose',
    projectStartDate: '5.projectStartDate',
    projectDecisionDepartmentGenitive: '5.projectDecisionDepartmentGenitive',
    totalCapital: '5.totalCapital',
    investorCapital: '5.investorCapital',
    madeInvestorCapital: '5.madeInvestorCapital',
    remainsInvestorCapital: '5.remainsInvestorCapital',
    madeInvestorCapitalDate: '5.madeInvestorCapitalDate',
    projectSupportCompensation: '5.projectSupportCompensation',
    projectRelatedCompensation: '5.projectRelatedCompensation',
  },
  '6': {
    stages: '6.stages',
  },
  '7': {
    eventsOfStages: '7.eventsOfStages',
  },
  '8': {
    stepActivityOthers: '8.stepActivityOthers',
  },
  '9': {
    projectResultActivity: '9.projectResultActivity',
    otherProjectResultActivity: '9.otherProjectResultActivity',
    projectResults: '9.projectResults',
    projectResultEcoActivity: '9.projectResultEcoActivity',
    ecoActivityName: '9.projectResultEcoActivity.ecoActivityName',
    ecoActivityEndDate: '9.projectResultEcoActivity.ecoActivityEndDate',
    ecoActivityProgramNameInstrumental:
      '9.projectResultEcoActivity.ecoActivityProgramNameInstrumental',
  },
  '10': {
    technologicalRequirements: '10.technologicalRequirements',
  },
  '11': {
    rightDutySubjectWaterResource: '11.rightDutySubjectWaterResource',
    rightDutySubjectForestResource: '11.rightDutySubjectForestResource',
    rightDutyRfWaterResource: '11.rightDutyRfWaterResource',
    rightDutyRfForestResource: '11.rightDutyRfForestResource',
  },
  '13': {
    extraMunicipalAccessionLinkStabilization: '13.extraMunicipalAccessionLinkStabilization',
    extraMunicipalAccessionLinkCompensation: '13.extraMunicipalAccessionLinkCompensation',
    stabilizationPeriod: '13.stabilizationPeriod',
  },
  '14': {
    ruleLinkRelatedContract: '14.ruleLinkRelatedContract',
    subjectRuleLinkRelatedContract: '14.subjectRuleLinkRelatedContract',
  },
  '15': {
    ruleLinkMonitoring: '15.ruleLinkMonitoring',
    subjectNpaReasonMonitoring: '15.subjectNpaReasonMonitoring',
  },
  '16': {
    courtChoice: '16.courtChoice',
    courtName: '16.courtName',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
  },
} as const

const rewriteProjectSZPKFieldsAfterApplyDiffs: Path<ProjectSZPKFormValues>[] = [
  projectSZPKBlockValues['9'].projectResultActivity,
]

export {
  defaultProjectSZPKFormValue,
  mapOfProjectSZPKMenu,
  projectSZPKBlockValues,
  rewriteProjectSZPKFieldsAfterApplyDiffs,
}
