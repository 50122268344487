import { DependentUrls, Urls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { downloadFile } from '@helpers/fileHelpers'
import { stringifyQuery } from '@helpers/format'
import { IRegions } from '@hooks/new/swr/useRegions'
import {
  IMasterNpaItem,
  INpaItem,
  INpaItemView,
  INpaPartVersion,
  IPartClassification,
  NpaStatusProps,
  TNpaDirectionsFromDict,
  TNpaTypesFromDict,
} from '@services/NPA/NPA.entity'
import { ResponseType } from 'axios'

import type {
  DownloadNpaFileProps,
  IDefaultPartNPAProps,
  IFiltersOfGetNpa,
  IPostNpaBody,
  IPostNpaPart,
  NpaCreateAndUpdateResponse,
  NpaCreateFileResponse,
  TGetListNpaResponse,
  TGetModalListNpaResponse,
  TPutNpaBody,
  TPutNpaPartBody,
} from './types'

const useNpaApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const getListOfNpa = async (filters: IFiltersOfGetNpa) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetListNpaResponse>({
        url: Urls.NPA,
        config: {
          params: filters,
          paramsSerializer: stringifyQuery,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getListOfNpaModal = async (filters: IFiltersOfGetNpa, excludedPartIds?: string[]) => {
    try {
      const { data } = await FetcherWithInterceptors.post<TGetModalListNpaResponse>({
        url: `${Urls.NPA}/grouped/get`,
        config: {
          params: filters,
          paramsSerializer: stringifyQuery,
        },
        data: {
          excludePartIds: excludedPartIds,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getNpaTypes = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<TNpaTypesFromDict>({
        url: `${Urls.NPA}/dictionaries/npa-types`,
      })

      return data
    } catch (e) {
      throw e
    }
  }

  const getNpaDirections = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<TNpaDirectionsFromDict>({
        url: `${Urls.NPA}/dictionaries/npa-directions`,
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const getNpaPartClassifications = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<IPartClassification[]>({
        url: `${Urls.NPA}/dictionaries/npa-part-classification`,
      })

      return data
    } catch (e) {
      throw e
    }
  }

  const getNpaPartRedactions = async ({ npaId, partId }: IDefaultPartNPAProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<INpaPartVersion[]>({
        url: {
          url: `${DependentUrls.NPAPartById}/versions`,
          params: {
            npaId,
            partId,
          },
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const getNpaById = async (npaId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<INpaItemView>({
        url: {
          url: DependentUrls.NPAById,
          params: {
            npaId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const createNpa = async (npaBody: IPostNpaBody) => {
    try {
      const { data } = await FetcherWithInterceptors.post<NpaCreateAndUpdateResponse>({
        url: Urls.NPA,
        data: npaBody,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const createNpaPart = async (npaId: string, newNpaBody: IPostNpaPart) => {
    try {
      const { data } = await FetcherWithInterceptors.post<NpaCreateAndUpdateResponse>({
        url: {
          url: `${DependentUrls.NPAById}/parts`,
          params: {
            npaId,
          },
        },
        data: newNpaBody,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const createNpaFile = async (file: File) => {
    const formData = new FormData()
    formData.append('document', file, file.name)

    try {
      const { data } = await FetcherWithInterceptors.post<NpaCreateFileResponse>({
        url: `${Urls.FLC}/npas`,
        data: formData,
        header: 'Form',
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  //Сначала body идёт специально, чтобы сделать опциональным для декоратора.
  const updateNpa = async (newBody: TPutNpaBody, npaId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.put<NpaCreateAndUpdateResponse>({
        url: {
          url: DependentUrls.NPAById,
          params: {
            npaId,
          },
        },
        data: newBody,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const updateNpaPart = async (params: IDefaultPartNPAProps, newBody: TPutNpaPartBody) => {
    const { npaId, partId } = params

    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.NPAPartById,
          params: {
            npaId,
            partId,
          },
        },
        data: newBody,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const excludeNpa = async (npaId: string) => {
    try {
      return await FetcherWithInterceptors.delete({
        url: {
          url: DependentUrls.NPAById,
          params: {
            npaId,
          },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const excludeNpaPart = async ({ npaId, partId }: IDefaultPartNPAProps) => {
    try {
      return await FetcherWithInterceptors.delete({
        url: {
          url: DependentUrls.NPAPartById,
          params: {
            npaId,
            partId,
          },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const downloadNpaFile = async ({ documentId, name, extension }: DownloadNpaFileProps) => {
    try {
      const { data: blob, headers } = await FetcherWithInterceptors.get<File>({
        url: {
          url: DependentUrls.NPADocumentById,
          params: {
            documentId,
          },
          queries: {
            name,
            extension,
          },
        },
        config: {
          responseType: 'blob' as ResponseType,
        },
      })

      downloadFile(blob, headers)

      return blob
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getNpasList = async (idsOfNpas: string[]) => {
    try {
      const { data } = await FetcherWithInterceptors.post<Omit<IMasterNpaItem, 'parts'>[]>({
        url: `${Urls.NPA}/get`,
        data: idsOfNpas,
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const getNpaPartsById = async (npaId: string, partsId: string[]) => {
    try {
      const { data } = await FetcherWithInterceptors.post<INpaItem & NpaStatusProps>({
        url: {
          url: DependentUrls.NPAPartsById,
          params: {
            npaId,
          },
        },
        data: partsId,
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const getRegions = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<IRegions>({
        url: `${Urls.Dfo}/dictionaries/regions`,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    getListOfNpa,
    getListOfNpaModal,

    getNpaPartRedactions,

    getNpaTypes,
    getNpaDirections,
    getNpaPartClassifications,
    getNpaById,

    createNpaPart,
    createNpa,
    createNpaFile,

    updateNpa,
    updateNpaPart,

    excludeNpa,
    excludeNpaPart,

    downloadNpaFile,

    getNpasList,
    getNpaPartsById,

    getRegions,
  }
}

export default useNpaApi
