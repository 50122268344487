import { useMemo } from 'react'

import {
  CURRENT_DOCUMENT_SET_TYPE,
  CURRENT_DOCUMENT_TYPE,
} from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/constants'
import { useDocumentsSetDictTypes } from '@hooks/new/swr/useDocumentsSetDictTypes'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { DEFAULT_SYSTEM_VERSION } from '@services/Dfo/Dfo.const'
import { IDocumentSetInfo } from '@services/Documents/documents.entity'
import DocumentsService from '@services/Documents/documents.service'

export const useNotificationsDocumentTypeDictInfo = (
  projectId: string,
  documentSet: IDocumentSetInfo | undefined,
) => {
  const { projectDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
  })

  const systemVersion = projectDfos[0]?.systemVersion ?? DEFAULT_SYSTEM_VERSION

  const { documentsSetsDict } = useDocumentsSetDictTypes({
    key: { _key: 'documentsSetsDictTypes' },
  })

  const currentDocumentSetsDict = documentsSetsDict?.[systemVersion]
  const documentInfoFromDict =
    currentDocumentSetsDict?.[CURRENT_DOCUMENT_SET_TYPE]?.[CURRENT_DOCUMENT_TYPE]
      ?.documentTypeDictInfo || null

  const documentTypeDictInfo = useMemo(() => {
    return DocumentsService.prepareDocumentTypeDictInfo(
      documentInfoFromDict || [],
      documentSet?.documentTypeInfo || [],
    )
  }, [documentInfoFromDict, documentSet])

  return documentTypeDictInfo
}
