import React, { useRef } from 'react'
import { usePopupManager } from 'react-popup-manager'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { fromEmailStringToEmailJSX } from '@components/Forms/RegistrationForm/helpers'
import ProcessOrderModalModal from '@components/Modals/ProcessOrderModal'
import { useStaleActionsModal } from '@components/Modals/StaleActionsModal/useStaleActionsModal'
import Typography from '@components/NewDesign/Typography'
import { useCreateMembersModal } from '@components/NewDesignedModals/CreateMembersModal/manager'
import useCreateProcessModal from '@components/NewDesignedModals/CreateProcessModal/manager'
import { useDeleteDfoModal } from '@components/NewDesignedModals/DeleteDfoModal'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import useRegistrationSignModal from '@components/NewDesignedModals/RegistrationSignModal/manager'
import { useReorganizationRegistrationModal } from '@components/NewDesignedModals/ReorganizationRegistrationModal'
import { useRevokeModal } from '@components/NewDesignedModals/RevokeModal/manager'
import { useSendChangeProjectModal } from '@components/NewDesignedModals/SendChangeProjectModal/manager'
import { useSignModal } from '@components/NewDesignedModals/SignModal/manager'
import { useSuccessModal } from '@components/NewDesignedModals/SuccessModal/manager'
import { useWarningModal } from '@components/NewDesignedModals/WarningModal'
import { useWarningRegistrationTerminateModal } from '@components/NewDesignedModals/WarningRegistrateTerminateModal'
import { getDfoByType } from '@components/Projects/[id]/helpers'
import env from '@config'
import { mapOfCustomErrorCodes } from '@constants/errorCodes'
import { Paths } from '@constants/paths'
import { HttpStatusCode } from '@constants/statusCodes'
import {
  defaultMessageSupport,
  errorModalHeaderTexts,
  signModalBodyTexts,
  successModalHeaderTexts,
} from '@constants/texts'
import { NewDfosType, RolesTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { GetVersionData } from '@context/APIContext/types'
import { isAxiosError } from '@helpers/checkTypes'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { useDraftError } from '@hooks/new/draft'
import { useQueryManager } from '@hooks/useQueryManager'
import { BodyTextByRevokeAction, HeaderTextByRevokeAction } from '@interfaces/statement'
import DeadlineService from '@services/Deadline/Deadline.service'
import { TDfoTerminationType } from '@services/Dfo/Dfo.entity'
import { useDocumentActionsEnvironment } from '@services/DocumentActions/DocumentAction.env'
import { stackErrorMap } from '@services/DocumentActions/DocumentActions.const'
import {
  DocumentActionEnum,
  IDocumentActionsError,
  mapOfDocumentActions,
  RevokeActionEnum,
  TDocumentStackAction,
} from '@services/DocumentActions/DocumentActions.entity'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'
import {
  ADD_MEMBERS_InitialProps,
  CREATE_INVENTORY_InitialProps,
  DefaultInitialProps,
  DELETE_DFO_InitialProps,
  REGISTRATION_InitialProps,
  REVOKE_InitialProps,
  STATEMENT_CREATE_InitialProps,
} from '@services/DocumentActions/DocumentActions.initialProps'
import {
  DocumentActionStack,
  DocumentActionState,
} from '@services/DocumentActions/DocumentActions.service'
import { useUpdateDataAfterDocumentActions } from '@services/DocumentActions/DocumentActions.updater'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { unstable_serialize, useSWRConfig } from 'swr'
import { Key } from 'swr/dist/types'

type GeneralRevokeActionsHandler = (
  documentStack: DocumentActionStack,
  disableCreateSystemDocument?: boolean,
) => Promise<void>

const { getDifferenceBetweenCurrentAndDeadlineDate } = DeadlineService

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

const MAP_OF_EMAILS = {
  REACT_APP_ECONOMY_SUPPORT_EMAIL: ECONOMY_SUPPORT_EMAIL,
}

const useDocumentActions = (extraProjectId?: string) => {
  const stackOfActions = useRef<DocumentActionStack | null>(null)
  const needRevalidateErrorStack = useRef<string[]>([])

  const {
    dfosApi: { checkNewFLC, createInventoryFile },
    projectsApi: {
      changeProjectAction,
      createNewProjectFromCopy,
      getProjectMembersById,
      updateProcessingOrder,
      getProjectActions,
      removeSigningBlock,
    },
    documentsApi: { createSystemDocument },
    draftApi: { convertDraftToDocument },
  } = useAPIContext()

  const params = useParams()
  const navigate = useNavigate()

  const popupManager = usePopupManager()
  const { queryUtils } = useQueryManager()

  const { cache, mutate } = useSWRConfig()

  const dfoId = queryUtils.getQuery('dfoId') || ''
  const projectId = extraProjectId ?? params.projectId

  const { getEnvironmentByType } = useDocumentActionsEnvironment(projectId || '')
  const { updateAllProjectData, updateProjectActions, updateProjectDfos } =
    useUpdateDataAfterDocumentActions(projectId || '', dfoId)

  const { handleOpenDeleteDfoModal } = useDeleteDfoModal()
  const { handleOpenCreateMembersModal } = useCreateMembersModal()
  const { handleOpenSignModal } = useSignModal()
  const { handleOpenRegistrationModal, handleOpenRegistrationRejectModal } =
    useRegistrationSignModal()
  const { handleOpenRevokeModal } = useRevokeModal()

  const { handleOpenLoadingModal } = useLoadingModal()
  const { handleOpenSuccessModal } = useSuccessModal()
  const { handleOpenErrorModal } = useErrorModal()
  const { handleOpenWarningModal } = useWarningModal()

  const { handleOpenWarningRegistrationTerminateModal } = useWarningRegistrationTerminateModal()

  const { handleOpenCreateProcessModal } = useCreateProcessModal()
  const { handleOpenReorganizationRegModal } = useReorganizationRegistrationModal()
  const { handleOpenSendChangeProjectModal } = useSendChangeProjectModal()
  const { handleOpenStaleActionsModal } = useStaleActionsModal()

  const { handleErrorFromDraftConvert } = useDraftError({ projectId })

  const closeAllModals = () => {
    popupManager.closeAll()
  }

  const mutateAllKeysAfterErrorStack = async () => {
    const revalidatePromises = needRevalidateErrorStack.current.map((key) => mutate(key))

    await Promise.allSettled(revalidatePromises)
  }

  const handleAddRevalidateKeyToErrorStack = (key: Key) => {
    needRevalidateErrorStack.current.push(unstable_serialize(key))
  }

  const handleCancelAction = (rejectCallback: (reason?: any) => void) => {
    return rejectCallback(stackErrorMap.actionWasCanceled)
  }

  const handleErrorModal = (error: unknown) => {
    if (isAxiosError(error)) {
      //Ошибка при устаревших действиях обрабатывается внутри action, поэтому  в тригере  текущей функции нет необходимости
      if (
        error?.response?.status === HttpStatusCode.GONE &&
        error?.response.data.errorCode === mapOfCustomErrorCodes.ACTIONS_NOT_FOUND
      ) {
        throw error
      }

      popupManager.closeAll()

      const { detail } = error.response?.data

      const errorText = isIntervalServerError(error?.response?.status)
        ? defaultMessageSupport
        : fromEmailStringToEmailJSX(detail, MAP_OF_EMAILS)

      handleOpenErrorModal({
        bodyText: errorText,
        headerText: errorModalHeaderTexts.defaultMessage,
      })
    }
  }

  const updateToLastDfoVersion = (versions: GetVersionData[] | undefined) => {
    const version = versions?.find((version) => version.isActual)

    if (version && version.id !== dfoId) {
      queryUtils.addQuery({
        query: {
          dfoId: version.id,
        },
      })
    }
  }

  const createActionsStack = (actions: TDocumentStackAction[]) => {
    const preparedActionsForStack = actions.map((action) => new DocumentActionState(action))
    const readyStack = new DocumentActionStack(preparedActionsForStack)

    stackOfActions.current = readyStack

    return {
      readyStack,
      runStack,
    }
  }

  const runStack = async () => {
    try {
      await __runNextAction()
    } catch (e) {
      throw e
    }
  }

  const generalRevokeActionsHandler: GeneralRevokeActionsHandler = async function (
    documentActionStack: DocumentActionStack,
    disableCreateSystemDocument?: boolean,
  ) {
    closeAllModals()

    const actionProps = documentActionStack.processingAction?.initialProps as REVOKE_InitialProps

    if (!projectId || !actionProps) return

    const { actionId, projectDfos, isProjectFederal } = actionProps

    const currentDfoClaimBudgetType = projectDfos.find(
      (dfo) => dfo.id === dfoId,
    )?.claimSubsidyBudgetType

    const uiActionTrigger = documentActionStack.processingAction?.type as RevokeActionEnum
    if (!uiActionTrigger) return

    let headerText: string = HeaderTextByRevokeAction[uiActionTrigger]
    let textBody: string = BodyTextByRevokeAction[uiActionTrigger]

    if (uiActionTrigger === mapOfDocumentActions.REVOKE_CLAIM_SUBSIDY_PERMIT) {
      const { revokeClaimSubsidyHeaderText, revokeClaimSubsidyBodyText } =
        DocumentActionsService.getRevokeClaimSubsidyPermitText(
          isProjectFederal,
          currentDfoClaimBudgetType,
        )

      headerText = revokeClaimSubsidyHeaderText
      textBody = revokeClaimSubsidyBodyText
    }

    try {
      await new Promise((resolve, reject) => {
        handleOpenRevokeModal({
          actionId,
          projectId,
          uiActionTrigger,
          textBody,
          headerText,
          disableCreateSystemDocument,
          handleRevokeSuccess: resolve,
          handleRevokeError: reject,
          onClose: () => handleCancelAction(reject),
        })
      })
    } catch (e) {
      throw e
    }
  }

  const __runNextAction = async () => {
    try {
      await stackOfActions.current?.runNextAction()

      const actionToCall = stackOfActions.current?.processingAction
      if (!actionToCall) return Promise.reject(stackErrorMap.stackShiftError)

      const currentActionType = actionToCall?.type
      if (!currentActionType) return Promise.reject(stackErrorMap.stackShiftError)

      const environmentForCurrentAction = getEnvironmentByType(currentActionType)
      const currentActionCallback = actions[currentActionType]

      if (!currentActionCallback) return Promise.reject(stackErrorMap.stackShiftError)

      //Сначала раскладываю пропсы с environment, затем предыдущие, потому что они уже изменились в очереди на вернувшиеся из прошлого action
      actionToCall.changeInitialProps((prevInitialProps) => ({
        ...environmentForCurrentAction,
        ...prevInitialProps,
      }))

      if (!stackOfActions.current) return Promise.reject(stackErrorMap.refLostContext)

      try {
        await currentActionCallback.call(stackOfActions.current)
        return __runNextAction()
      } catch (e) {
        //На этом уровне брать значения из конфига и написать логику переоткрытия action
        throw e
      }
    } catch (e) {
      const error = e as IDocumentActionsError['error']
      const fullStackProps = {
        ...(stackOfActions.current?.fullStackProps as IDocumentActionsError['stackInfo']),
      }

      const preparedStackError: IDocumentActionsError = {
        error,
        stackInfo: fullStackProps,
      }
      const preparedLogInfo = {
        additionInfo: fullStackProps,
        componentInfo: {
          componentName: 'useDocumentActions',
          componentType: 'hook',
        },
      }

      if (isAxiosError(error)) {
        //Центр управления ошибками, внутри actions, обрабатываются ошибки,
        // связанные непоресредственно с action(например обработка черновика), общие ошибки со всей очереди обрабатываются здесь
        if (
          error?.response?.status === HttpStatusCode.GONE &&
          error?.response.data.errorCode === mapOfCustomErrorCodes.ACTIONS_NOT_FOUND
        ) {
          handleOpenStaleActionsModal(async () => {
            await Promise.all([updateProjectActions(), updateProjectDfos()])
          })

          throw preparedStackError
        }

        if (
          error?.response?.status === HttpStatusCode.BAD_REQUEST &&
          (error?.response.data.errorCode === mapOfCustomErrorCodes.STATEMENT_DRAFT ||
            error?.response.data.errorCode === mapOfCustomErrorCodes.NPA_DRAFT)
        ) {
          throw preparedStackError
        }

        await mutateAllKeysAfterErrorStack()
        handleErrorModal(error)
        throw preparedStackError
      }

      switch (error) {
        case stackErrorMap.stackIsOver:
          //TODO: Перенести в конфиг всего стака
          //Здесь указаны actions, после которых не нужны ревалидации.
          if (
            fullStackProps.processingAction?.type &&
            [mapOfDocumentActions.DELETE_DFO, mapOfDocumentActions.OPEN_SZPK_PROJECTS].includes(
              fullStackProps.processingAction.type,
            )
          )
            return

          const versions = await updateAllProjectData()
          if (versions && versions.length) updateToLastDfoVersion(versions)

          LoggerHelpersService.handleMultipleLogError(preparedLogInfo)(error)

          break

        case stackErrorMap.actionWasCanceled:
          await mutateAllKeysAfterErrorStack()
          throw preparedStackError

        case stackErrorMap.stackShiftError:
          LoggerHelpersService.handleMultipleLogError(preparedLogInfo)(error)

          break

        default:
          LoggerHelpersService.handleMultipleLogError(preparedLogInfo)(error)

          throw preparedStackError
      }
    }
  }

  //В качестве this будет предоставляться stack
  const actions: Record<DocumentActionEnum, (this: DocumentActionStack) => Promise<void>> = {
    STATEMENT_CREATE: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps as STATEMENT_CREATE_InitialProps

      if (!dfoId || !projectId || !actionProps) return

      const { actionId, SZPKDocumentsSetId, projectDfos } = actionProps

      try {
        handleOpenLoadingModal()

        await convertDraftToDocument({
          actionId,
          projectId,
        })

        const SZPKDfoId = getDfoByType(projectDfos, NewDfosType.SZPK)?.id

        handleAddRevalidateKeyToErrorStack({ dfoId: SZPKDfoId, projectId, _key: 'document-sets' })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useDocumentActions',
            componentType: 'hook',
          },
          additionInfo: {
            actionId,
          },
        })(error)

        if (!isAxiosError(error)) throw error

        popupManager.closeAll()

        handleErrorFromDraftConvert({ error, SZPKDocumentsSetId, projectDfos })
        throw error
      }
    },

    FLC: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps

      if (!projectId || !actionProps) return

      handleOpenLoadingModal()

      try {
        //Перед проверкой flc снимаем блокировку
        await removeSigningBlock(projectId)

        await checkNewFLC({
          actionId: actionProps.actionId,
          projectId,
        })
      } catch (e) {
        throw e
      }
    },

    CREATE_INVENTORY: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps as CREATE_INVENTORY_InitialProps

      if (!projectId || !actionProps) return
      const handleCreateInventoryFile = async () => {
        closeAllModals()
        handleOpenLoadingModal()

        try {
          await createInventoryFile({
            projectId: projectId || '',
            actionId: actionProps.actionId,
          })
        } catch (e) {
          throw e
        }
      }

      try {
        if (actionProps?.isDocumentChange) {
          closeAllModals()

          return await new Promise<void>((resolve, reject) => {
            handleOpenSendChangeProjectModal({
              onSuccess: async () => {
                try {
                  await handleCreateInventoryFile()
                  resolve()
                } catch (e) {
                  reject(e)
                }
              },
              onClose: () => {
                handleCancelAction(reject)
              },
            })
          })
        }

        await handleCreateInventoryFile()
      } catch (e) {
        throw e
      }
    },

    ADD_MEMBERS: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps as ADD_MEMBERS_InitialProps

      if (!projectId || !actionProps) return

      const { isProjectFederal, isDocumentChange } = actionProps

      try {
        await new Promise<void>((resolve, reject) => {
          handleOpenCreateMembersModal({
            projectId: projectId || '',
            isProjectFederal,
            isDocumentChange,
            onAddMembers: async () => {
              handleAddRevalidateKeyToErrorStack({ projectId, _key: 'projectAttributes' })

              resolve()
            },
            onClose: async () => {
              handleCancelAction(reject)
            },
          })
        })
      } catch (e) {
        throw e
      }
    },

    SIGN: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps as DefaultInitialProps
      if (!projectId || !actionProps) return

      const { actionType, actionId } = actionProps

      try {
        //Перед подписанием снимаем блокировку
        await removeSigningBlock(projectId)

        const projectActions = await getProjectActions(projectId)

        await new Promise<void>((resolve, reject) => {
          handleOpenSignModal({
            actionType,
            actionId,
            projectActions,
            projectId,
            dfoId,
            onSuccessSignFiles: resolve,
            onErrorSignFiles: reject,
            onClose: () => handleCancelAction(reject),
          })
        })
      } catch (e) {
        throw e
      }
    },

    SIGN_NOTIFICATION_CUSTOM: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps

      if (!projectId || !actionProps) return

      const { actionType, actionId } = actionProps

      try {
        const projectActions = await getProjectActions(projectId)

        await new Promise<void>((resolve, reject) => {
          handleOpenSignModal({
            actionType,
            actionId,
            projectActions,
            projectId,
            dfoId,
            bodyText: signModalBodyTexts.szpkNotification,
            onSuccessSignFiles: resolve,
            onErrorSignFiles: reject,
            onClose: () => handleCancelAction(reject),
          })
        })
      } catch (e) {
        throw e
      }
    },

    CHANGE_STAGE: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps as DefaultInitialProps

      if (!projectId || !actionProps) return

      const { dfos, actionId } = actionProps

      handleOpenLoadingModal()

      const processesArray =
        dfos?.map((item) =>
          item.processActions.map((processAction) => processAction.processId).join(),
        ) ?? []

      try {
        const data = await changeProjectAction({
          actionId,
          projectId: projectId || '',
          processIds: processesArray,
        })

        const isLastUiAction = !this.nextAction

        if (!isLastUiAction) return Promise.resolve()

        const prevAction = this.prevAction?.type

        closeAllModals()

        if (prevAction === mapOfDocumentActions.SIGN) {
          handleOpenSuccessModal({
            text: data?.message,
            headerText: successModalHeaderTexts.documentsSigned,
          })
        }

        if (prevAction === mapOfDocumentActions.REGISTRATION) {
          handleOpenSuccessModal({
            text: data?.message,
            headerText: successModalHeaderTexts.agreementIntoRegister,
          })
        }
      } catch (e) {
        throw e
      }
    },

    DELETE_DFO: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps as DELETE_DFO_InitialProps

      if (!projectId || !actionProps) return

      const { redirectDfoIdForDeleteProps, nameDfoForDeleteProps } = actionProps

      const preparedDfoInfo = {
        name: nameDfoForDeleteProps,
        id: dfoId,
        redirectId: redirectDfoIdForDeleteProps,
      } as const

      try {
        await new Promise<void>((resolve, reject) => {
          handleOpenDeleteDfoModal({
            projectId,
            dfoInfo: preparedDfoInfo,
            onSuccessDeleteDfo: resolve,
            onErrorDeleteDfo: reject,
            onClose: () => handleCancelAction(reject),
          })
        })
      } catch (e) {
        throw e
      }
    },

    CREATE_PROCESS: async function (this: DocumentActionStack) {
      const actionProps = this.processingAction?.initialProps

      if (!projectId || !actionProps) return

      const { actionId } = actionProps

      try {
        await new Promise<void>((resolve, reject) => {
          handleOpenCreateProcessModal({
            actionId,
            projectId: projectId ?? '',
            onCreateProcess: resolve,
            onErrorProcess: reject,
            onClose: () => handleCancelAction(reject),
          })
        })
      } catch (e) {
        throw e
      }
    },

    CREATE_SYSTEM_DOCUMENT: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps

      if (!projectId || !actionProps) return

      const { actionId } = actionProps

      try {
        await createSystemDocument({
          actionId: actionId ?? '',
          projectId: projectId ?? '',
        })
      } catch (e) {
        throw e
      }
    },

    REVOKE_SZPK: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this, true)
    },
    REVOKE_EXTRA: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this)
    },
    REVOKE_MONITORING_REPORT_SZPK: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this)
    },
    REVOKE_TERMINATE: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this)
    },
    REVOKE_CLAIM_SUBSIDY_PERMIT: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this)
    },
    REVOKE_OTHER_DONATIONS: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this)
    },
    REVOKE_COMMON: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this)
    },
    REVOKE_MUNICIPAL_ACCESSION: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this)
    },
    REVOKE_CLAIM_TAX_REFUND: function (this: DocumentActionStack) {
      return generalRevokeActionsHandler.call(undefined, this)
    },
    REGISTRATION: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps as REGISTRATION_InitialProps

      if (!projectId || !actionProps) return

      const { actionId, actionType, projectDfos } = actionProps

      const currentDfo = projectDfos?.find((dfo) => dfo.id === dfoId)
      const currentDfoType = currentDfo?.type

      //Если пытаются расторгнуть за 5+ дней до предполагаемой даты рассторжения
      const warningRenderModalCondition =
        currentDfo?.deadline?.overdueDate &&
        getDifferenceBetweenCurrentAndDeadlineDate(currentDfo?.deadline?.overdueDate) + 5 < 0

      try {
        switch (currentDfoType) {
          case NewDfosType.SZPK_TERMINATE_ONE_SIDE:
          case NewDfosType.SZPK_TERMINATE:
          case NewDfosType.SZPK_TERMINATE_JUDGMENT:
            await new Promise<void>((resolve, reject) => {
              return handleOpenRegistrationRejectModal({
                dfoType: currentDfoType,
                actionId,
                actionType,
                projectId,
                dfoId,
                onSuccessSignFiles: resolve,
                onErrorSignFiles: reject,
                onClose: () => handleCancelAction(reject),
              })
            })
            break

          default:
            await new Promise<void>((resolve, reject) => {
              handleOpenRegistrationModal({
                actionType,
                projectId,
                actionId,
                dfoId,
                onSuccessSignFiles: resolve,
                onErrorSignFiles: reject,
                onClose: () => handleCancelAction(reject),
              })
            })
        }
      } catch (e) {
        throw e
      }
    },

    REGISTRATION_JUDGMENT_TERMINATE_DATE: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps as REGISTRATION_InitialProps

      if (!projectId || !actionProps) return

      const { actionId, actionType, projectDfos } = actionProps

      try {
        const currentDfoType = projectDfos?.find((dfo) => dfo.id === dfoId)?.type

        if (currentDfoType !== NewDfosType.SZPK_TERMINATE_JUDGMENT) return Promise.reject()

        await new Promise<void>((resolve, reject) => {
          handleOpenRegistrationRejectModal({
            dfoType: NewDfosType.SZPK_TERMINATE_JUDGMENT as TDfoTerminationType,
            actionType,
            actionId,
            projectId,
            dfoId,
            onSuccessSignFiles: resolve,
            onErrorSignFiles: reject,
            onClose: () => handleCancelAction(reject),
          })
        })
      } catch (e) {
        throw e
      }
    },

    REGISTRATION_ORG_REORGANIZATION: async function (this: DocumentActionStack) {
      closeAllModals()

      const actionProps = this.processingAction?.initialProps
      if (!projectId || !actionProps) return

      const { actionId } = actionProps

      if (!projectId) return

      try {
        await new Promise<void>((resolve, reject) => {
          handleOpenReorganizationRegModal({
            actionId,
            projectId,
            onSuccess: async () => {
              handleOpenSuccessModal({
                text: '',
                headerText: successModalHeaderTexts.agreementIntoRegister,
              })
              resolve()
            },
            onError: reject,
            onClose: () => handleCancelAction(reject),
          })
        })
      } catch (e) {
        throw e
      }
    },

    COPY_PROJECT: async function () {
      closeAllModals()
      handleOpenLoadingModal()

      try {
        if (!projectId) return

        const response = await createNewProjectFromCopy(projectId)

        if (!response.projectId || !response.dfos.length) return Promise.resolve()

        const redirectDfo = response.dfos[0].id
        const urlToRedirect = `${Paths.Projects}/${response.projectId}`

        navigate({
          pathname: urlToRedirect,
          search: new URLSearchParams({ dfoId: redirectDfo }).toString(),
        })
      } catch (e) {
        throw e
      } finally {
        closeAllModals()
      }
    },

    EDIT_PROCESSING_ORDER: async function () {
      closeAllModals()
      try {
        const projectMembers = await getProjectMembersById(projectId || '')

        const oivMembers = projectMembers.filter((member) => member.type === RolesTypes.OIV)

        if (oivMembers.length === 1) {
          return Promise.resolve()
        }

        await new Promise<void>((resolve, reject) => {
          popupManager.open(ProcessOrderModalModal, {
            oivMembers,
            header: 'Выберите способ получения документов региональными ОИВ',
            onSubmit: async (data) => {
              try {
                resolve(await updateProcessingOrder(projectId || '', dfoId || '', data))
              } catch (e) {
                reject(e)
              }
            },
            onClose: () => handleCancelAction(reject),
          })
        })
      } catch (e) {
        throw e
      }
    },
    RIGHTS_LOSS_WARNING: async function (this: DocumentActionStack) {
      closeAllModals()

      try {
        await new Promise((resolve, reject) => {
          handleOpenWarningModal({
            children: (
              <Typography.Body align={'center'} variant="bodyMRegular">
                Просмотр и редактирование проекта станут недоступны.
              </Typography.Body>
            ),
            onConfirm: resolve,
            onClose: () => handleCancelAction(reject),
          })
        })
      } catch (e) {
        throw e
      }
    },
    OPEN_SZPK_PROJECTS: async function (this: DocumentActionStack) {
      closeAllModals()

      cache.clear()
      navigate(Paths.Projects, { replace: true })
    },
  }

  return {
    createActionsStack,
  }
}

export { useDocumentActions }
