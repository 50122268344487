import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

interface ICustomEvent<T> {
  target: {
    value: T
    [key: string]: any
  }
  [key: string]: any
}
type TUseInput<T> = {
  value: T
  setValue: Dispatch<SetStateAction<T>>
  onChange(e: ICustomEvent<T>): void
  bind: {
    value: T
    onChange(e: ICustomEvent<T>): void
  }
}

const useInput = <T>(initVal: T): TUseInput<T> => {
  const [value, setValue] = useState<T>(initVal)

  const handlerChange = useCallback((e: ICustomEvent<T>): void => setValue(e.target.value), [])

  const valueState = useMemo(
    () => ({
      value,
      setValue,
      onChange: handlerChange,
      bind: {
        value,
        onChange: handlerChange,
      },
    }),
    [value, setValue, handlerChange],
  )

  return valueState
}

export default useInput
