import React, { FC, memo, useCallback } from 'react'

import LoginItem from '@components/Forms/LoginForm/shared/LoginItem'
import type { ICertificate } from '@components/Forms/LoginForm/types'
import Typography from '@components/NewDesign/Typography/Typography'

import styles from './UserCertificate.module.scss'

interface UserCertificateProps {
  certificate: ICertificate
  onSelect: (certificate: ICertificate) => void
  className?: string
}

const UserCertificate: FC<UserCertificateProps> = ({ certificate, onSelect, className }) => {
  const { company, name, isUL, inn, id } = certificate

  const handleClick = useCallback(() => {
    onSelect(certificate)
  }, [onSelect, certificate])

  return (
    <LoginItem
      className={className}
      footer={isUL && name}
      dataTestId={`UserCertificate-certificate-${id}-button`}
      iconButtonDataTestId={`UserCertificate-certificate-${id}-iconButton`}
      header={
        <div className={styles.head__text}>
          {(company || name) && (
            <Typography.Headline variant="headlineH3" className={styles.title}>
              {isUL ? company : name}
            </Typography.Headline>
          )}
          {inn && (
            <Typography.Body variant="bodyMRegular" className={styles.inn}>
              ИНН: {inn}
            </Typography.Body>
          )}
        </div>
      }
      onClick={handleClick}
    />
  )
}

export default memo(UserCertificate)
