import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { CertificateOfComplianceContractForRecognitionConcludedAFormValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/types'
import { defaultRHFValidation } from '@constants/validations'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof CertificateOfComplianceContractForRecognitionConcludedAFormValues['2'],
  CertificateOfComplianceContractForRecognitionConcludedAFormValues
> = {
  uoHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { secondSectionValidationMap }
