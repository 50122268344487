import React, { forwardRef } from 'react'

import FormControlLabel from '@components/NewDesign/FormControl/Label'
import IconButton from '@components/NewDesign/IconButton'
import { IconButtonProps } from '@components/NewDesign/IconButton/types'
import Typography from '@components/NewDesign/Typography'
import { isUndefined } from '@helpers/checkTypes'
import { useHover } from '@hooks/new/events/useHover'
import closeIcon from '@icons/navigation/close_fill.svg'
import cn from 'classnames'

import styles from './FormControl.module.scss'
import { FormControlProps } from './types'

const getIconSizeByButtonSize = (size: IconButtonProps['size']) => {
  switch (size) {
    case 'm':
    case 's':
      return 'xs'

    default:
      return 's'
  }
}

const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  (
    {
      view = 'primary',
      hideBorder = false,
      disabled,
      fixWidth,
      label,
      leftAddons,
      rightAddons,
      labelLeftAddons,
      labelRightAddons,
      size = 'xl',
      error,
      focused,
      filled,
      caption,
      clear,
      textareaCounter,
      onClear,
      onAddonsClick,
      inputContainerRef,

      rootClassName,

      labelClassName,
      labelWrapperClassName,
      labelTextClassName,
      addonsClassName,
      captionClassName,
      labelLeftAddonClassName,
      labelRightAddonClassName,
      className,
      children,

      dataTestId,
    },
    ref,
  ) => {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>()

    const labelLeftAddonsElement =
      isHovered && !isUndefined(labelLeftAddons) ? labelLeftAddons : undefined
    const labelRightAddonsElement =
      isHovered && !isUndefined(labelRightAddons) ? labelRightAddons : undefined

    return (
      <div
        ref={hoverRef}
        className={cn(styles.box, rootClassName, {
          [styles[size]]: size,
          [styles['box--fix-width']]: fixWidth,
        })}
      >
        <div
          role={'presentation'}
          ref={inputContainerRef}
          tabIndex={0}
          data-testid={`${dataTestId}-container`}
          className={cn(styles.container, styles[view], className, {
            [styles['container--error']]: error,
            [styles.filled]: filled || focused,
            [styles.focused]: focused,
            [styles.hideBorder]: hideBorder,
            [styles.disabled]: disabled,
          })}
        >
          {!!leftAddons && (
            <span
              role={'presentation'}
              className={cn(styles.addons, styles.leftAddons, addonsClassName)}
              data-testid={`${dataTestId}-leftAddon`}
              onClick={onAddonsClick}
            >
              {leftAddons}
            </span>
          )}

          <div data-testid={`${dataTestId}-label`} className={styles.wrapper} ref={ref}>
            {label && (
              <FormControlLabel
                leftAddons={labelLeftAddonsElement}
                rightAddons={labelRightAddonsElement}
                className={cn(styles.label, labelClassName)}
                textClassName={cn(styles['label-inner'], labelTextClassName)}
                wrapperClassName={cn(styles['label-wrapper'], labelWrapperClassName)}
                leftAddonClassName={cn(styles['label-leftAddon'], labelLeftAddonClassName)}
                rightAddonClassName={cn(styles['label-rightAddon'], labelRightAddonClassName)}
              >
                {label}
              </FormControlLabel>
            )}

            <div className={styles.input}>{children}</div>

            {clear && (
              <div
                data-testid={`${dataTestId}-clear`}
                className={cn(styles.addons, styles.clearButton)}
              >
                <IconButton
                  disabled={disabled}
                  size={getIconSizeByButtonSize(size)}
                  type="button"
                  color="default"
                  geometry="round"
                  view="basic"
                  className={styles.action}
                  tabIndex={-1}
                  dataTestId={`${dataTestId}-clearButton`}
                  icon={{
                    src: closeIcon,
                    noCurrentColorSvgFill: true,
                  }}
                  onClick={onClear}
                />
              </div>
            )}
          </div>

          {rightAddons && (
            <div
              role={'presentation'}
              className={cn(styles.addons, styles.rightAddons, addonsClassName)}
              data-testid={`${dataTestId}-rightAddon`}
              onClick={onAddonsClick}
            >
              {rightAddons}
            </div>
          )}
        </div>

        <div data-testid={`${dataTestId}-caption`} className={styles.captionBox}>
          {caption && (
            <Typography.Caption
              variant="captionSRegular"
              className={cn(
                styles.caption,
                {
                  [styles[`caption--${view}`]]: view,
                  [styles['caption--error']]: error,
                  [styles.caption__disabled]: disabled,
                },
                captionClassName,
              )}
            >
              {caption}
            </Typography.Caption>
          )}

          {textareaCounter && (
            <Typography.Caption
              data-testid={`${dataTestId}-textAreaCounter`}
              variant="captionSRegular"
              className={cn(styles.caption, styles[`caption__counter`])}
            >
              {textareaCounter}
            </Typography.Caption>
          )}
        </div>
      </div>
    )
  },
)

export default FormControl
