import { generatePropertiesObjectForApplicationConclusionAdditionalAgreementFormForm } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForApplicationConclusionAdditionalAgreementFormForm } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/adapters/RHF.adapter'
import { ApplicationConclusionAdditionalAgreementFormFormValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/types'

const useApplicationConclusionAdditionalAgreementFormAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): ApplicationConclusionAdditionalAgreementFormFormValues =>
    generateRHFObjectForApplicationConclusionAdditionalAgreementFormForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForApplicationConclusionAdditionalAgreementFormForm(
      objectFromPropertyState,
    )

  return { generateRHFObject, generatePropertiesObject }
}

export { useApplicationConclusionAdditionalAgreementFormAdapters }
