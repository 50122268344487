import React, { FC } from 'react'

import { ReactComponent as Step_final } from '@icons/action/step_final.svg'
import { ReactComponent as Step_five } from '@icons/action/step_five.svg'
import { ReactComponent as Step_four } from '@icons/action/step_four.svg'
import { ReactComponent as Step_one } from '@icons/action/step_one.svg'
import { ReactComponent as Step_six } from '@icons/action/step_six.svg'
import { ReactComponent as Step_three } from '@icons/action/step_three.svg'
import { ReactComponent as Step_two } from '@icons/action/step_two.svg'
import cn from 'classnames'

import styles from './Step.module.scss'

export interface ActProps {
  title: string
  step: string
  label?: string
  className?: string
}

const Step: FC<ActProps> = ({ title, label, step, className }) => {
  const stepNumber = () => {
    switch (step) {
      case 'one':
        return <Step_one />
      case 'two':
        return <Step_two />
      case 'three':
        return <Step_three />
      case 'four':
        return <Step_four />
      case 'five':
        return <Step_five />
      case 'six':
        return <Step_six />
      case 'final':
        return <Step_final />
    }
  }

  return (
    <div>
      <div className="flex items-start">
        {stepNumber()}
        <div className={styles.wrapper}>
          <div>
            <div className={cn(styles.title, className)}>{title}</div>
            {label && <div className={styles.label}>{label}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step
