import React, { DetailedHTMLProps, FC, memo, ReactNode } from 'react'

import ControlContainer from '@components/ControlContainer'
import Typography from '@components/NewDesign/Typography'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isUndefined } from '@helpers/checkTypes'
import { IDocumentItem } from '@interfaces/documents'
import DayjsService from '@services/Dayjs/Dayjs.service'
import cn from 'classnames'

import styles from './WebFormDocumentItem.module.scss'

interface WebFormDocumentItemProps
  extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  document: IDocumentItem
  title: string
  leadingNodeContent?: ReactNode
  trailingNodeContent?: ReactNode
}

const WebFormDocumentItem: FC<WebFormDocumentItemProps> = ({
  document,
  title,
  leadingNodeContent,
  trailingNodeContent,
  onClick,
  className,
  ...restProps
}) => {
  const { updateDatetime } = document

  const lastModifiedDate =
    updateDatetime &&
    DayjsService.dayjsWithFormatToMSK(updateDatetime).format(objOfDateFormats.formatWithFullMonth)

  return (
    <ControlContainer
      {...restProps}
      title={title}
      color="secondary"
      contentDirection="row"
      titleClassName={styles['webFormDocumentItem__controlContainer-title']}
      leadingNodeContent={leadingNodeContent}
      leadingNodeWrapperClassName={styles['webFormDocumentItem__leadingNode-wrapper']}
      trailingNodeWrapperClassName={styles['webFormDocumentItem__trailingNode-wrapper']}
      className={cn(
        styles['webFormDocumentItem__controlContainer'],
        {
          [styles['webFormDocumentItem__controlContainer--clickable']]: !isUndefined(onClick),
        },
        className,
      )}
      trailingNodeContent={
        <Stack
          direction="horizontal"
          gap={3}
          className={styles['webFormDocumentItem__trailingNode']}
        >
          {lastModifiedDate && (
            <Typography color={'text-base-tertiary'} variant={'bodySMedium'}>
              {lastModifiedDate}
            </Typography>
          )}
          <Stack direction="horizontal" gap={2} className={styles['webFormDocumentItem__action']}>
            {trailingNodeContent}
          </Stack>
        </Stack>
      }
      onClick={onClick}
    />
  )
}

export default memo(WebFormDocumentItem)
