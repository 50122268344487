import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fourthSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap: FormValuesValidationSection<
  keyof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues['4'],
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues
> = {
  ruleLinkDenySolicitation: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  reasonDenySolicitation: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { fourthSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap }
