import React, { ChangeEvent, FC, useRef } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormMenu.module.scss'
import { useFormNavigationMenu } from '@components/DocumentFormComponents/hooks/useFormNavigation'
import { useFormSubmitInStatement } from '@components/DocumentFormComponents/hooks/useFormSubmit'
import { useShowMenuLinearGradientManager } from '@components/DocumentFormComponents/hooks/useShowMenuLinearGradientManager'
import MenuActions from '@components/DocumentFormComponents/Menu/Actions'
import DocumentFormNestedMenu from '@components/DocumentFormComponents/NestedMenu'
import {
  useNestedMenuHandlersManager,
  useNestedMenuManager,
} from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalMenuProps } from '@components/DocumentFormComponents/types'
import { useParametersApplicationReimbursementExpensesManager } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Manager'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import { ParametersApplicationReimbursementExpensesFieldCollapseControlUpdateWatcher } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/watcher'
import Icon from '@components/Icon/Icon'
import Loader from '@components/Loader'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import NoItemText from '@components/NoItemText'
import { useResizeObserver } from '@hooks/new/observers/useResizeObserver'
import searchIcon from '@icons/action/search.svg'
import cn from 'classnames'

const ParametersApplicationReimbursementExpensesMenu: FC<FormModalMenuProps> = ({ ...rest }) => {
  const formInstance = useFormContext<ParametersApplicationReimbursementExpensesFormValues>()

  const {
    state: { blockViewIsValidating, lastUpdateDraftTime, editMode },
    actions: { handleCheckForm, handlePreviewForm },
    handlers: { applyFormChanges },
  } = useParametersApplicationReimbursementExpensesManager()

  const {
    state: { formMenu, searchThree, searchThreeIsLoading, currentSearchPattern, currentMenuItemId },
    handlers: { getInitialExpandedMenuCondition, handleChangeSearchPattern },
  } = useNestedMenuManager()

  const { handleChangeCurrentMenuItemId } = useNestedMenuHandlersManager()

  const { control } = useForm({
    defaultValues: {
      parametersApplicationReimbursementExpensesMenuSearch: currentSearchPattern,
    },
  })

  const { handleMenuItemClick } = useFormNavigationMenu(
    ParametersApplicationReimbursementExpensesFieldCollapseControlUpdateWatcher,
  )

  const { handleSubmitForm } = useFormSubmitInStatement()

  const { isShowLineGradient, handleOnScroll, handleResizeObserverCallback } =
    useShowMenuLinearGradientManager()

  const menuWrapperRef = useRef<HTMLDivElement | null>(null)

  useResizeObserver({
    elementsToObserve: menuWrapperRef,
    resizeListener: handleResizeObserverCallback,
  })

  const currentParametersApplicationReimbursementExpensesMenu = currentSearchPattern.length
    ? searchThree
    : formMenu

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value.trim()

    handleChangeSearchPattern?.(currentValue)
  }

  const handleSubmit = async () => {
    if (!handleCheckForm || !applyFormChanges) return

    try {
      await handleCheckForm()
    } catch (e) {
      throw e
    }
  }

  if (!formInstance) return null

  return (
    <div className={cn(styles.menu, styles['menu-nested'])}>
      <ControlledInput
        name="parametersApplicationReimbursementExpensesMenuSearch"
        control={control}
        inputProps={{
          rootClassName: styles.menu__searchRoot,
          controlClassName: styles.menu__searchControl,
          size: 's',
          view: 'secondary',
          leftAddons: <Icon src={searchIcon} className={styles.menu__searchIcon} size="s" />,
          fixWidth: true,
          clear: true,
          placeholder: 'Поиск по разделам',
        }}
        rules={{
          onChange: onSearchInputChange,
        }}
      />
      <div className={styles.menu__container}>
        <div ref={menuWrapperRef} className={styles.menu__wrapper} onScroll={handleOnScroll}>
          <Loader loading={searchThreeIsLoading}>
            {currentParametersApplicationReimbursementExpensesMenu ? (
              <DocumentFormNestedMenu
                {...rest}
                blockViewIsValidating={blockViewIsValidating}
                lastUpdateDraftTime={lastUpdateDraftTime}
                nestedMapOfMenu={currentParametersApplicationReimbursementExpensesMenu}
                currentSearchPattern={currentSearchPattern}
                activeMenuId={currentMenuItemId}
                getInitialExpandedCondition={getInitialExpandedMenuCondition}
                onMenuClick={handleMenuItemClick(handleChangeCurrentMenuItemId)}
              />
            ) : (
              <NoItemText>Поиск не дал результатов</NoItemText>
            )}
          </Loader>
        </div>
        {isShowLineGradient && <div className={styles['menu__container-line']} />}
      </div>
      <MenuActions
        previewButtonProps={
          editMode
            ? {
                onClick: handlePreviewForm,
              }
            : undefined
        }
        submitButtonProps={
          editMode
            ? {
                onClick: handleSubmitForm(handleSubmit),
              }
            : undefined
        }
      />
    </div>
  )
}

export default ParametersApplicationReimbursementExpensesMenu
