import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { ApplicationConclusionAdditionalAgreementFormFormValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/types'
import { defaultRHFValidation } from '@constants/validations'

const oneSectionValidationMap: FormValuesValidationSection<
  keyof ApplicationConclusionAdditionalAgreementFormFormValues['1'],
  ApplicationConclusionAdditionalAgreementFormFormValues
> = {
  initiatorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { oneSectionValidationMap }
