import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { AgreementStabilizationNegotiationsMunicipalityArrayPathName } from '@components/Forms/АgreementStabilizationNegotiationsForm/Forms/3/types'
import { thirdSectionAgreementStabilizationNegotiationsValidationMap } from '@components/Forms/АgreementStabilizationNegotiationsForm/Forms/3/validation'
import { useAgreementStabilizationNegotiationsManager } from '@components/Forms/АgreementStabilizationNegotiationsForm/Manager'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import { AgreementStabilizationNegotiationsFieldsControlUpdateWatcher } from '@components/Forms/АgreementStabilizationNegotiationsForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface AAgreementOnCreationOtherEstateObjectsMunicipalityFormProps {
  formName: AgreementStabilizationNegotiationsMunicipalityArrayPathName
  formInstance: UseFormReturn<AgreementStabilizationNegotiationsFormValues>
}

const AgreementStabilizationNegotiationsMunicipalityForm: FC<
  AAgreementOnCreationOtherEstateObjectsMunicipalityFormProps
> = ({ formInstance, formName }) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAgreementStabilizationNegotiationsManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getTextareaProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AgreementStabilizationNegotiationsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.municipalityFullName`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Наименование'}
              controllerProps={getControllerProps({
                name: `${formName}.municipalityFullName`,
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.municipalityFullNameGenitive`,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: `${formName}.municipalityFullNameGenitive`,
                rules:
                  thirdSectionAgreementStabilizationNegotiationsValidationMap.municipalityFullNameGenitive,
                textareaProps: {
                  maxRows: Infinity,
                  label: 'Наименование в родительном падеже',
                },
                onBlur: () =>
                  setTimeout(
                    () => handleChangeValue?.(`${formName}.municipalityFullNameGenitive`),
                    0,
                  ),
                onChange: () =>
                  setTimeout(
                    () => debouncedHandleChangeValue?.(`${formName}.municipalityFullNameGenitive`),
                    0,
                  ),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <AuthorizedOfficialGroup
        groupTitle={'Уполномоченное должностное лицо'}
        formInstance={formInstance}
        formSection={formName}
        prefixFormFieldName={'municipality'}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.municipalityHeaderName`,
          })}
        >
          {({ overrideProps }) => {
            const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
              ? overrideProps.fetcherOptions
              : undefined

            return (
              <ControlledFormSingleSelect
                {...getSingleSelectProps({
                  fetcherProps,
                  optionsAdapter: (item) => ({
                    displayValue: item.headerName || '',
                    value: item.id,
                  }),
                  controllerProps: {
                    name: `${formName}.municipalityHeaderName`,
                    rules:
                      thirdSectionAgreementStabilizationNegotiationsValidationMap.municipalityHeaderName,
                  },
                  selectProps: {
                    inputProps: {
                      label: 'Фамилия, Имя, Отчество',
                    },
                    onChangeFormValue: () => {
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.municipalityHeaderName`)
                      }, 0)
                    },
                  },
                })}
              />
            )
          }}
        </SubscribableControl>
      </AuthorizedOfficialGroup>
    </Stack>
  )
}

export default AgreementStabilizationNegotiationsMunicipalityForm
