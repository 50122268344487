import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode, useRef } from 'react'

import AsyncWrapper from '@components/AsyncWrapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import Loader from '@components/Loader'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import Stack from '@components/ReactBootstrap/Stack'
import { isNotEmptyString, isUndefined } from '@helpers/checkTypes'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

import styles from './StepTemplate.module.scss'

interface StepTemplateProps
  extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'id'> {
  id: string
  children: ReactNode
  anchorId?: string
  title?: string
  titleClassName?: string
  titleWrapperClassName?: string
  contentClassName?: string
  dataTestId?: string
  additionalNode?: ReactNode
  leftAddons?: ReactNode
  rightAddons?: ReactNode
  onRemoveStep?: VoidFunction
}

const StepTemplate: FC<StepTemplateProps> = ({
  id,
  children,
  anchorId,
  title,
  titleClassName,
  titleWrapperClassName,
  contentClassName,
  dataTestId,
  additionalNode,
  onRemoveStep,
  leftAddons,
  rightAddons,
  className,
  ...divRest
}) => {
  const divRef = useRef<HTMLDivElement | null>(null)

  const isShowRemoveButton = !isUndefined(onRemoveStep)

  const isShowTitle = !isUndefined(title) || isNotEmptyString(title)

  const isShowLeftAddons = !isUndefined(leftAddons)
  const isShowRightAddons = !isUndefined(rightAddons)

  const handleDelete = async (e) => {
    e.preventDefault()

    await onRemoveStep?.()
  }

  return (
    <FlippedWithCollapse flipId={id}>
      <div
        {...divRest}
        id={anchorId}
        data-testId={dataTestId}
        className={cn(styles.stepTemplate__wrapper, className)}
      >
        <div
          ref={divRef}
          className={cn(
            styles.stepTemplate,
            {
              [styles['stepTemplate--withTitle']]: isShowTitle,
            },
            contentClassName,
          )}
        >
          <Stack direction={'vertical'} gap={2}>
            {isShowTitle && (
              <div className={cn(styles['stepTemplate__title-wrapper'], titleWrapperClassName)}>
                {isShowLeftAddons && (
                  <div className={styles['stepTemplate__title-addon--left']}>{leftAddons}</div>
                )}
                <Typography.Body variant={'bodyMMedium'} className={titleClassName}>
                  {title}
                </Typography.Body>
                {isShowRightAddons && (
                  <div className={styles['stepTemplate__title-addon--right']}>{rightAddons}</div>
                )}
              </div>
            )}
            <div>{children}</div>
          </Stack>
        </div>
        <Stack
          direction={'horizontal'}
          gap={isShowRemoveButton ? 2 : 0}
          className={cn(styles['stepTemplate__header-wrapper'], {
            [styles['stepTemplate__header-wrapper--withRemove']]: isShowRemoveButton,
          })}
        >
          {additionalNode}
          {isShowRemoveButton && (
            <AsyncWrapper promise={handleDelete}>
              {({ isLoading, wrappedPromise }) => {
                if (isLoading) {
                  return (
                    <div className={styles['stepTemplate__loader-wrapper']}>
                      <Loader loading variant={'lite'} className={styles['stepTemplate__loader']} />
                    </div>
                  )
                }

                return (
                  <IconButton
                    disabled={isLoading}
                    geometry={'round'}
                    view={'tined'}
                    color={'negative'}
                    dataTestId="FieldView.StepTemplate-delete-iconButton"
                    size={'s'}
                    icon={{ src: DeleteIcon }}
                    onClick={wrappedPromise}
                  />
                )
              }}
            </AsyncWrapper>
          )}
        </Stack>
      </div>
    </FlippedWithCollapse>
  )
}

export default StepTemplate
