import { FC } from 'react'

import First from './1'
import Second from './2'

interface BeneficiaryOfSuccessorOrganizationInformationFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const BeneficiaryOfSuccessorOrganizationInformationForms: BeneficiaryOfSuccessorOrganizationInformationFormsProps =
  () => null

BeneficiaryOfSuccessorOrganizationInformationForms.First = First
BeneficiaryOfSuccessorOrganizationInformationForms.Second = Second

export default BeneficiaryOfSuccessorOrganizationInformationForms
