import { TInitialStateOfFolderStore } from './FolderState.entity'

const FolderStateService = {
  getFolderStateFromLC: () => {
    const folderState = localStorage.getItem('folderState')

    return folderState ? JSON.parse(folderState) : null
  },

  setLCFolderState: (folderState: TInitialStateOfFolderStore) => {
    const preparedFolderStateToLC = JSON.stringify(folderState)

    localStorage.setItem('folderState', preparedFolderStateToLC)
  },
}

export { FolderStateService }
