import React, { FC, memo, ReactNode } from 'react'

import Chip from '@components/Chip'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import { isUndefined } from '@helpers/checkTypes'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useBooleanState } from '@hooks/useBooleanState'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from './Direction.module.scss'

interface DirectionViewProps {
  isOpen: boolean
  needEdit: boolean
  blockViewIsValidating: boolean
  count: number
  direction: string
  children: ReactNode
  onChange: (value: boolean) => void
  hasError?: boolean
}

const DirectionView: FC<DirectionViewProps> = ({
  isOpen,
  needEdit,
  blockViewIsValidating,
  count,
  direction,
  children,
  onChange,
  hasError,
}) => {
  const { booleanState: directionIsCollapsed, reverseBooleanState: toggleCollapse } =
    useBooleanState(isOpen)

  const directionClassNames = !isUndefined(hasError)
    ? {
        [styles['direction__direction--success']]: !hasError && blockViewIsValidating,
        [styles['direction__direction--error']]: hasError && blockViewIsValidating,
      }
    : undefined

  const { getCollapseProps } = useCollapse({
    isExpanded: directionIsCollapsed,
  })

  const handleToggleCollapse = () => {
    toggleCollapse()

    onChange(directionIsCollapsed)
  }

  return (
    <>
      <button type="button" className={styles.direction__button} onClick={handleToggleCollapse}>
        <IconButton
          className={styles.direction__control}
          size={'s'}
          view={'basic'}
          icon={{
            className: cn(styles['direction__control-item'], {
              [styles['direction__control--toggled']]: directionIsCollapsed,
            }),
            src: chevronRightIcon,
          }}
        />
        <Typography
          className={cn(styles.direction__direction, directionClassNames)}
          variant={'bodyXLMedium'}
          color={'text-base-secondary'}
        >
          {direction}
        </Typography>

        {!needEdit ? (
          <Typography.Body
            className={styles.direction__count}
            variant={'bodyLMedium'}
            color={'text-accent-brand'}
          >
            {count}
          </Typography.Body>
        ) : (
          <Chip.Base
            disableClickableEffect
            className={styles.direction__chip}
            priority={'secondary'}
            variant={'warning'}
            label={'Внесены изменения в реестр НПА'}
          />
        )}
      </button>
      <div {...getCollapseProps()}>
        <div className={styles.direction__content}>{children}</div>
      </div>
    </>
  )
}

export default memo(DirectionView)
