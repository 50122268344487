import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/const'
import NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/Forms'
import { useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAManager } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/Manager'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'

const mapOfNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms: FormItemProps =
  {
    '1': {
      node: <NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms.First />,
    },
    '2': {
      node: <NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms.Second />,
    },
    '3': {
      node: <NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms.Third />,
    },
    '4': {
      node: <NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms.Fourth />,
    },
  } as const

const NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm: FC<
  FormModalStatementProps
> = ({ ...rest }) => {
  const formInstance =
    useFormContext<NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues>()

  const {
    state: { formIsLoading },
  } = useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu}
      mapOfForms={mapOfNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm)
