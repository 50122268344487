import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfNoticeOfProjectRejectConcludeSZPKMenu } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/const'
import NoticeOfProjectRejectConcludeSZPK from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/Forms'
import { useNoticeOfProjectRejectConcludeSZPKManager } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/Manager'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'

const mapOfNoticeOfProjectRejectConcludeSZPKForms: FormItemProps = {
  '1': {
    node: <NoticeOfProjectRejectConcludeSZPK.First />,
  },
  '2': {
    node: <NoticeOfProjectRejectConcludeSZPK.Second />,
  },
  '3': {
    node: <NoticeOfProjectRejectConcludeSZPK.Third />,
  },
} as const

const NoticeOfProjectRejectConcludeSZPKForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<NoticeOfProjectRejectConcludeSZPKFormValues>()

  const {
    state: { formIsLoading },
  } = useNoticeOfProjectRejectConcludeSZPKManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNoticeOfProjectRejectConcludeSZPKMenu}
      mapOfForms={mapOfNoticeOfProjectRejectConcludeSZPKForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NoticeOfProjectRejectConcludeSZPKForm)
