import { FC, memo } from 'react'

import type { ErrorProps } from '@components/Forms/LoginForm/types'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography/Typography'
import env from '@config'
import { mapOfEmails } from '@constants/email'
import cn from 'classnames'

import ErrorTemplate from '../ErrorTemplate'

import styles from './LoginError.module.scss'

export interface LoginErrorProps {
  isLoading: boolean
  error: ErrorProps | null
  caption: string
  isHideButtons?: boolean
  className?: string
  customLoadingMessage?: string
  goToFormHandler?: VoidFunction
}

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

const LoginError: FC<LoginErrorProps> = ({
  isLoading,
  error,
  caption,
  isHideButtons,
  className,
  customLoadingMessage,
  goToFormHandler,
}) => {
  const isShowMERButton = !isLoading && error?.MERButton && !error?.supportButton
  const isShowSupportButton = !isLoading && error?.supportButton && !error?.MERButton

  return (
    <div className={cn(styles.container, className)}>
      {isLoading && (
        <div className={styles.loading}>
          <Loader
            loading
            variant="lite"
            dataTestId="LoginError-loader"
            className={styles.loading__loader}
          />
          <Typography.Headline variant="headlineH3" className={styles.loading__text}>
            {customLoadingMessage || 'Обращение к сертификату'}
          </Typography.Headline>
          {caption && (
            <Typography.Body variant="bodyMRegular" className={styles.loading__caption}>
              {caption}
            </Typography.Body>
          )}
        </div>
      )}
      {!isLoading && <ErrorTemplate error={error} />}
      {!isHideButtons && (
        <div className={styles.buttons}>
          {!isLoading && (
            <Button
              fixWidth={!error?.MERButton}
              view={error?.MERButton ? 'gray' : 'filled'}
              className={cn(styles.buttons__back, styles.buttons__item)}
              dataTestId="LoginError-back-button"
              disabled={isLoading}
              onClick={goToFormHandler}
            >
              Назад
            </Button>
          )}
          {isShowMERButton && (
            <Button
              fixWidth
              href={`mailto:${mapOfEmails.REACT_APP_ECONOMY_SUPPORT_EMAIL}`}
              dataTestId="LoginError-MER-button"
            >
              Написать в МЭР
            </Button>
          )}
          {isShowSupportButton && (
            <Button
              fixWidth
              href={`mailto:${mapOfEmails.REACT_APP_SUPPORT_EMAIL}`}
              dataTestId="LoginError-support-button"
            >
              Написать в поддержку
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(LoginError)
