import React from 'react'

import SystemVariables from '@components/SystemVariables'
import Layouts from '@layouts'

const SystemVariablesPage = () => {
  return (
    <Layouts.Main>
      <SystemVariables />
    </Layouts.Main>
  )
}

export default SystemVariablesPage
