import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import Typography from '@components/NewDesign/Typography'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import DeadlineProjectsTooltip from '@components/PreparedTooltips/DeadlineProjects'
import {
  colorVariableNameBySimpleName,
  ConfigColors,
  ConfigIcons,
  iconByName,
} from '@constants/config'
import { Paths } from '@constants/paths'
import { buildUrl } from '@packages/api/fetcher/helpers/buildUrl'
import { deadlineMayBeExtendedWithDfoType } from '@services/Deadline/Deadline.const'
import { IDfoDeadline } from '@services/Deadline/Deadline.entity'
import { useProjectsDeadline } from '@services/Deadline/Deadline.hook'
import cn from 'classnames'

import styles from './Preview.module.scss'

interface DfoProps {
  id: string
  name: string
  type: string
  status: string
}

interface ProjectParentProps {
  id: string
}

interface ProjectItemPreviewProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  dfoInfo: DfoProps
  parentProjectInfo: ProjectParentProps
  variant: 'success' | 'error' | 'warning'
  config: {
    color: ConfigColors
    icon: ConfigIcons
  }
  deadlineInfo?: IDfoDeadline | null
}

const ProjectItemPreview: FC<ProjectItemPreviewProps> = ({
  dfoInfo,
  parentProjectInfo,
  deadlineInfo,
  config,
  ...rest
}) => {
  const { isDeadlineRender, deadlineTooltipIsRender, preparedDeadlineDate } =
    useProjectsDeadline(deadlineInfo)

  const deadlineMayBeExtendedTooltipIsRender =
    !!deadlineTooltipIsRender && dfoInfo.type.startsWith(deadlineMayBeExtendedWithDfoType.CLAIM_)

  const preparedLinkHref = buildUrl({
    url: Paths.ProjectPage,
    params: {
      projectId: parentProjectInfo.id,
    },
    queries: {
      dfoId: dfoInfo.id,
    },
  })

  const handleClickTooltip = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const renderDeadlineTooltip = () => {
    if (deadlineMayBeExtendedTooltipIsRender) return <DeadlineProjectsTooltip type={'EXTENDED'} />

    return <DeadlineProjectsTooltip />
  }

  return (
    <Link className={cn(styles.preview, rest.className)} to={preparedLinkHref}>
      <TypographyWithTooltip
        className={styles.preview__name}
        variant={'bodyMMedium'}
        tooltipProps={{
          position: 'top-start',
          targetClassName: styles.tooltip__target,
          contentClassName: styles.tooltip__content,
        }}
      >
        {dfoInfo.name}
      </TypographyWithTooltip>
      <div className={styles.preview__status}>
        <div className={styles.preview__icon}>
          <ReactSVG
            src={iconByName[config.icon] || iconByName.done}
            className={cn(styles[`preview__icon_${config.color}`], styles.size)}
          />
        </div>
        <div className={styles['preview__status-info']}>
          {isDeadlineRender && (
            <div className={styles.preview__deadline}>
              <Typography.Caption color={'text-base-tertiary'} variant={'captionSRegular'}>
                {preparedDeadlineDate}
              </Typography.Caption>
              {!!deadlineTooltipIsRender && (
                <button
                  className={styles['preview__deadline-tooltip']}
                  onClick={handleClickTooltip}
                >
                  {renderDeadlineTooltip()}
                </button>
              )}
            </div>
          )}
          <Typography.Body
            variant={'bodySMedium'}
            fontWeight={'normal'}
            color={
              colorVariableNameBySimpleName[config.color] || colorVariableNameBySimpleName.green
            }
          >
            {dfoInfo.status}
          </Typography.Body>
        </div>
      </div>
    </Link>
  )
}

export default memo(ProjectItemPreview)
