import { useCallback, useMemo, useState, VFC } from 'react'
import { useForm } from 'react-hook-form'

import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Modal from '@components/NewDesign/Modal'
import { defaultRHFValidation, NewPatterns } from '@constants/validations'
import FieldCondition from '@containers/FieldCondition'
import { useAPIContext } from '@context/APIContext'

import styles from './PasswordChangeModal.module.scss'
import type { FormState, PasswordChangeProps } from './types'

const PasswordChangeModal: VFC<PasswordChangeProps> = ({ isOpen, onClose, userId, onSuccess }) => {
  const {
    operatorApi: { changeOperatorPasswordById },
  } = useAPIContext()
  const [isLoading, setLoading] = useState(false)
  const { getValues, reset, watch, control, handleSubmit } = useForm<FormState>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { password: '' },
  })

  const handleSave = useCallback(async () => {
    setLoading(true)
    try {
      await changeOperatorPasswordById(userId, getValues().password)

      onClose()
      await onSuccess?.()
    } catch (e) {
      throw e
    } finally {
      setLoading(false)
    }
  }, [userId])

  const onSubmit = useCallback(
    async () => await handleSubmit(handleSave)(),
    [handleSubmit, handleSave],
  )

  const onCancel = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  const doneActions = useMemo(() => {
    return [
      {
        view: 'plain',
        dataTestId: 'PasswordChangeModal-cancel-button',
        onClick: onCancel,
        children: 'Отменить',
      },
      {
        dataTestId: 'PasswordChangeModal-changePassword-button',
        children: 'Сохранить',
        onClick: onSubmit,
        loaderProps: { loading: isLoading, variant: 'lite', placement: 'trailing' },
      },
    ] as ButtonProps[]
  }, [onCancel, onSubmit, isLoading])

  return (
    <Modal.Action
      actions={doneActions}
      isOpen={isOpen}
      title={'Укажите новый пароль'}
      dataTestId="PasswordChangeModal-modal"
      closeButtonDataTestId="PasswordChangeModal-modal-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      onClose={onClose}
    >
      <ControlledInput
        name="password"
        control={control}
        inputProps={{
          view: 'secondary',
          label: 'Пароль',
          fixWidth: true,
          dataTestId: 'PasswordChangeModal-password-input',
        }}
        rules={{
          pattern: NewPatterns.Password,
          required: defaultRHFValidation.required,
        }}
      />
      <FieldCondition value={watch('password')} />
    </Modal.Action>
  )
}

export default PasswordChangeModal
