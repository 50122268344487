import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { TGetAllDocumentsOfDfo } from '@services/Dfo/Dfo.entity'
import useSWRImmutable from 'swr/immutable'

const useDfoDocuments = ({ key, config }: SWRHookProps<TGetAllDocumentsOfDfo | undefined>) => {
  const {
    dfosApi: { getDfoDocumentsById },
  } = useAPIContext()

  const {
    data: dfoDocuments,
    error,
    mutate,
    isValidating: isLoadingDfoDocuments,
  } = useSWRImmutable(key, getDfoDocumentsById, {
    shouldRetryOnError: false,
    ...config,
  })

  return {
    dfoDocuments,
    error,
    mutate,
    isLoadingDfoDocuments,
  }
}

export { useDfoDocuments }
