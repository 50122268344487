import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useNoticeIntentionTerminateSZPKManager } from '@components/Forms/NoticeIntentionTerminateSZPKForm/Manager'
import { NoticeIntentionTerminateSZPKFormValues } from '@components/Forms/NoticeIntentionTerminateSZPKForm/types'

import { mapOfNoticeIntentionTerminateSZPKMenu } from '../const'
import NoticeIntentionTerminateSZPKForms from '../Forms'

const mapOfNoticeIntentionTerminateSZPKForms: FormItemProps = {
  '1': {
    node: <NoticeIntentionTerminateSZPKForms.First />,
  },
  '2': {
    node: <NoticeIntentionTerminateSZPKForms.Second />,
  },
  '3': {
    node: <NoticeIntentionTerminateSZPKForms.Third />,
  },
} as const

const NoticeIntentionTerminateSZPKForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<NoticeIntentionTerminateSZPKFormValues>()

  const {
    state: { formIsLoading },
  } = useNoticeIntentionTerminateSZPKManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNoticeIntentionTerminateSZPKMenu}
      mapOfForms={mapOfNoticeIntentionTerminateSZPKForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NoticeIntentionTerminateSZPKForm)
