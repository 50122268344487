import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import CreateStatementOldLayout, {
  CreateStatementOldModalProps,
} from '@components/Forms/CreateStatementOld/Layout'
import CreateStatementOldModalManager from '@components/Forms/CreateStatementOld/Manager'
import CreateStatementOldMenu from '@components/Forms/CreateStatementOld/Menu'
import CreateStatementOldForm from '@components/Forms/CreateStatementOld/Statement'
import { IViolation } from '@components/Forms/CreateStatementOld/types'

interface ICreateStatementOld extends FC {
  Layout: typeof CreateStatementOldLayout
  Menu: typeof CreateStatementOldMenu
  StatementForm: typeof CreateStatementOldForm
  Manager: typeof CreateStatementOldModalManager
}

const useCreateStatementOldControl = () => {
  const popupManager = usePopupManager()

  const openStatementOldModal = useCallback(
    (
      { dfoId, projectId, documentSetId }: Partial<CreateStatementOldModalProps>,
      initialErrorsFromViolations?: IViolation[],
    ) => {
      if (!dfoId || !projectId || !documentSetId) return

      popupManager.open<CreateStatementOldModalProps>(CreateStatementOldLayout, {
        dfoId,
        projectId,
        documentSetId,
        initialErrorsFromViolations,
      })
    },
    [popupManager],
  )

  return { openStatementOldModal }
}

const CreateStatementOld: ICreateStatementOld = () => null

CreateStatementOld.Menu = CreateStatementOldMenu
CreateStatementOld.Layout = CreateStatementOldLayout
CreateStatementOld.StatementForm = CreateStatementOldForm
CreateStatementOld.Manager = CreateStatementOldModalManager

export { useCreateStatementOldControl }
export default CreateStatementOld
