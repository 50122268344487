import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementConsiderationInvestmentAuthorizedBlockValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/const'
import { secondSectionStatementConsiderationInvestmentAuthorizedValidationMap } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/Forms/2/validation'
import { useStatementConsiderationInvestmentAuthorizedManager } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/Manager'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'
import { StatementConsiderationInvestmentAuthorizedFieldsControlUpdateWatcher } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Two = () => {
  const formInstance = useFormContext<StatementConsiderationInvestmentAuthorizedFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useStatementConsiderationInvestmentAuthorizedManager()

  const { getSubscribableControlProps, getControllerProps, getInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: StatementConsiderationInvestmentAuthorizedFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementConsiderationInvestmentAuthorizedBlockValues['2'].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle="Наименование инвестиционного проекта"
                controllerProps={getControllerProps({
                  name: statementConsiderationInvestmentAuthorizedBlockValues['2'].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Статьи ФЗ, на основании которых рассматривается инвестиционный проект на соответствие критериям бюджетной эффективности'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementConsiderationInvestmentAuthorizedBlockValues['2']
                    .lawLinkInvestProject,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: statementConsiderationInvestmentAuthorizedBlockValues['2']
                      .lawLinkInvestProject,
                    rules:
                      secondSectionStatementConsiderationInvestmentAuthorizedValidationMap.lawLinkInvestProject,
                    inputProps: {
                      caption: 'рекомендация: 4 и 7',
                      dataTestId: 'lawLinkInvestProject',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          statementConsiderationInvestmentAuthorizedBlockValues['2']
                            .lawLinkInvestProject,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          statementConsiderationInvestmentAuthorizedBlockValues['2']
                            .lawLinkInvestProject,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункты Правил, на основании которых рассматривается инвестиционный проект на соответствие критериям бюджетной эффективности'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementConsiderationInvestmentAuthorizedBlockValues['2']
                    .ruleLinkInvestProject,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: statementConsiderationInvestmentAuthorizedBlockValues['2']
                      .ruleLinkInvestProject,
                    rules:
                      secondSectionStatementConsiderationInvestmentAuthorizedValidationMap.ruleLinkInvestProject,
                    inputProps: {
                      caption: 'рекомендация: 16-21',
                      dataTestId: 'ruleLinkInvestProject',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          statementConsiderationInvestmentAuthorizedBlockValues['2']
                            .ruleLinkInvestProject,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          statementConsiderationInvestmentAuthorizedBlockValues['2']
                            .ruleLinkInvestProject,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Two
