import { type FC, type ReactNode, memo } from 'react'

import { RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'

import AttachmentAddonInvestorController from './InvestorController'
import AttachmentAddonMERController from './MERController'

const SELECTED_ROLES = [RolesTypes.INVESTOR, RolesTypes.MER]

interface AttachmentAddonControllerProps {
  isDraftExists: boolean
  documentType: string
  permissions: number
  projectId: string
  dfoId: string
  documentSetId: string
  children: ReactNode
}

const AttachmentAddonController: FC<AttachmentAddonControllerProps> = ({ children, ...props }) => {
  const { findRoleBySelectedRoles } = useAuthContext()

  const role = findRoleBySelectedRoles?.(SELECTED_ROLES)

  return (() => {
    switch (role) {
      case RolesTypes.INVESTOR:
        return (
          <AttachmentAddonInvestorController {...props}>
            {children}
          </AttachmentAddonInvestorController>
        )
      case RolesTypes.MER:
        return <AttachmentAddonMERController>{children}</AttachmentAddonMERController>

      default:
        return <>{children}</>
    }
  })()
}

export default memo(AttachmentAddonController)
