import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationOfChangesInDetailsOfParties from '@components/Forms/NotificationOfChangesInDetailsOfParties'
import {
  defaultNotificationOfChangesInDetailsOfPartiesFormValues,
  mapOfNotificationOfChangesInDetailsOfPartiesMenu,
} from '@components/Forms/NotificationOfChangesInDetailsOfParties/const'
import { NotificationOfChangesInDetailsOfPartiesFormValues } from '@components/Forms/NotificationOfChangesInDetailsOfParties/types'
import { NotificationOfChangesInDetailsOfPartiesFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfChangesInDetailsOfParties/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NotificationOfChangesInDetailsOfPartiesLayoutWrapper from './Wrapper'

const NotificationOfChangesInDetailsOfPartiesLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  rolesOfUser,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NotificationOfChangesInDetailsOfPartiesFormValues>({
    defaultValues: defaultNotificationOfChangesInDetailsOfPartiesFormValues,
  })

  const handleOnClose = () => {
    NotificationOfChangesInDetailsOfPartiesFieldsControlUpdateWatcher.resetAllState()

    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationOfChangesInDetailsOfParties.Manager
          formId={formId}
          projectId={projectId}
          rolesOfUser={rolesOfUser}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfNotificationOfChangesInDetailsOfPartiesMenu[0].id}
          >
            <NotificationOfChangesInDetailsOfPartiesLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об изменении реквизитов сторон
                </Typography.Headline>
                <NotificationOfChangesInDetailsOfParties.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}
              >
                <NotificationOfChangesInDetailsOfParties.Menu className={styles.layout__menu} />
              </div>
            </NotificationOfChangesInDetailsOfPartiesLayoutWrapper>
          </FlatMenuManager>
        </NotificationOfChangesInDetailsOfParties.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NotificationOfChangesInDetailsOfPartiesLayout)
