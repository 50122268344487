import React, { FC, memo, useCallback } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import RegistrationCertificate from '@components/Forms/RegistrationCertificateForm'
import { mapOfRegistrationCertificateMenu } from '@components/Forms/RegistrationCertificateForm/const'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import Typography from '@components/NewDesign/Typography'

import RegistrationCertificateLayoutWrapper from './Wrapper'

const RegistrationCertificateLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  rolesOfUser,
  initialErrorsFromViolations,
  editMode,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const handleOnClose = useCallback(() => {
    RegistrationCertificateFieldsControlUpdateWatcher.resetAllState()

    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <RegistrationCertificate.Manager
        formId={formId}
        projectId={projectId}
        rolesOfUser={rolesOfUser}
        initialErrorsFromViolations={initialErrorsFromViolations}
        editMode={editMode}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
        onClose={handleOnClose}
      >
        <FlatMenuManager initialCurrentAnchorId={mapOfRegistrationCertificateMenu[0].id}>
          <RegistrationCertificateLayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Справка о включении сведений о СЗПК в реестр Федерального казначейства
              </Typography.Headline>
              <RegistrationCertificate.StatementForm className={styles.layout__form} />
            </div>
            <div className={styles['layout__menu-wrapper']}>
              <RegistrationCertificate.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </RegistrationCertificateLayoutWrapper>
        </FlatMenuManager>
      </RegistrationCertificate.Manager>
    </DocumentFormLayout>
  )
}

export default memo(RegistrationCertificateLayout)
