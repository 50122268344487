import Fields from '@components/Fields'
import Icon from '@components/Icon/Icon'
import IconStatus from '@components/IconStatus'
import Alert from '@components/NewDesign/Alert/Alert'
import TextWithTooltip from '@components/NewDesign/TextWithTooltip'
import Tag, { TagProps } from '@components/Tag'
import TextWithCopy from '@components/TextWithCopy'
import { UserProfiles } from '@constants/types'
import { limitWithTooltip } from '@helpers/limitWithTooltip'
import { getTagPropsByRole } from '@helpers/tagHelpers'
import lockOutlineIcon from '@icons/action/lock_outline.svg'
import cn from 'classnames'

import { LOCK_ICON_COLOR } from '../constants'

import styles from './ParticipantsTable.module.scss'

const DISABLED_COLOR: TagProps['type'] = 'black'

export const columns = [
  {
    Header: 'Сотрудник・Email',
    accessor: 'firstName',
    className: cn('w-[16.2%]', styles.column),
    Cell: ({
      row: {
        original: { firstName, lastName, email },
      },
    }) => {
      const limit = 30
      const fullName = `${firstName} ${lastName}`

      return (
        <div className="text-sm font-normal" {...limitWithTooltip(fullName, limit)}>
          <div className={styles.nameCell}>{fullName}</div>
          <div className={cn(styles.nameCell, styles.email)}>
            <Fields.Email>{email}</Fields.Email>
          </div>
        </div>
      )
    },
  },
  {
    Header: 'Организация・ИНН',
    accessor: 'organization.name',
    className: cn('w-[15.4%]', styles.column),
    Cell: ({
      row: {
        original: { organization },
      },
    }) => {
      const limit = 45
      const { name, inn } = organization

      return (
        <div className="text-sm font-normal" {...limitWithTooltip(name, limit)}>
          <div className={styles.nameCell}>{name}</div>
          <div className={cn(styles.nameCell, styles.inn)}>
            <p>{inn}</p>
          </div>
        </div>
      )
    },
  },
  {
    Header: 'Должность',
    accessor: 'position',
    className: cn('w-[14.2%]', styles.column),
    Cell: ({ value }) => <TextWithTooltip variant="bodyMRegular" value={value} limit={40} />,
  },
  {
    Header: 'Право подписи',
    accessor: 'profile',
    className: cn('w-[9.3%]', styles.column),
    Cell: ({ value: profile, row: { original } }) => {
      const { isEnabled } = original

      return (
        <div className={styles.iconWrapper}>
          {profile === UserProfiles.HEADER ? (
            <IconStatus status="concert" className={cn({ [styles.disabled]: !isEnabled })} />
          ) : (
            <IconStatus status="error" className={cn({ [styles.disabled]: !isEnabled })} />
          )}
        </div>
      )
    },
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: cn('w-[16.8%]', styles.column),
    Cell: ({ value }) => (
      <TextWithCopy
        value={value}
        view="plain"
        className={styles.copyWrapper}
        iconWrapperClassName={styles.copyIconWrapper}
        SuccessAlert={<Alert variant="success">Сертификат скопирован в буфер обмена</Alert>}
        ErrorAlert={<Alert variant="error">Не удалось скопировать сертификат</Alert>}
      >
        <TextWithTooltip variant="bodyMRegular" value={value} limit={40} className={styles.text} />
      </TextWithCopy>
    ),
  },
  {
    Header: 'Роль',
    accessor: 'organization.role',
    className: styles.column,
    Cell: ({ value: role, row: { original } }) => {
      const [text, type] = getTagPropsByRole(role)
      const { isEnabled } = original

      return (
        <div className={styles.roleWrapper}>
          <Tag
            size="m"
            key={role}
            type={isEnabled ? type : DISABLED_COLOR}
            className={styles.pointer}
          >
            {text}
          </Tag>
          {!isEnabled && <Icon src={lockOutlineIcon} color={LOCK_ICON_COLOR} size="s" />}
        </div>
      )
    },
  },
]
