import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { notificationOfIdentifiedNPAListErrorsFormBlockValues } from '../../const'
import { useNotificationOfIdentifiedNPAListErrorsManager } from '../../Manager'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '../../types'
import {
  NotificationOfIdentifiedNPAListErrorsFieldArrayControlUpdateWatcher,
  NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher,
} from '../../watcher'

import NpaError from './AdditionalForms/NpaError'
import { NpaErrorsPathName } from './types'
import { fourthSectionValidationMap } from './validation'

const Fourth = () => {
  const formInstance = useFormContext<NotificationOfIdentifiedNPAListErrorsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedNPAListErrorsManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher,
  })

  const { fields: npaErrors } = useFieldArraySubscribableControl<
    NotificationOfIdentifiedNPAListErrorsFormValues,
    NpaErrorsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: notificationOfIdentifiedNPAListErrorsFormBlockValues['4'].npaErrors,
    watcher: NotificationOfIdentifiedNPAListErrorsFieldArrayControlUpdateWatcher,
  })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={4}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Пункт Правил, на основании которого направляется уведомление'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedNPAListErrorsFormBlockValues['4'].ruleLinkNpaReturn,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationOfIdentifiedNPAListErrorsFormBlockValues['4']
                      .ruleLinkNpaReturn,
                    rules: fourthSectionValidationMap.ruleLinkNpaReturn,
                    inputProps: {
                      caption: 'рекомендация: 23 или 75',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedNPAListErrorsFormBlockValues['4']
                              .ruleLinkNpaReturn,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedNPAListErrorsFormBlockValues['4']
                            .ruleLinkNpaReturn,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedNPAListErrorsFormBlockValues['4'].npaErrors,
                })}
              >
                <FlipperList list={npaErrors}>
                  {npaErrors.map((error, index) => (
                    <NpaError
                      key={index}
                      formName={`${notificationOfIdentifiedNPAListErrorsFormBlockValues['4'].npaErrors}.${index}`}
                      npaName={error.npaName}
                      indexOfError={index}
                      parentAnchorId={'4'}
                    />
                  ))}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Fourth
