import React, { FC, memo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import type { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'

import { mapOfProjectSZPKMenu } from '../const'
import ProjectSZPKForms from '../Forms'

const mapOfProjectSZPKForms: FormItemProps = {
  '1.1': {
    node: <ProjectSZPKForms.One />,
  },
  '1.2': {
    node: <ProjectSZPKForms.Two />,
  },
  '1.3': {
    node: <ProjectSZPKForms.Three />,
  },
  '2.1': {
    node: <ProjectSZPKForms.Four />,
  },
  '2.2': {
    node: <ProjectSZPKForms.Five />,
  },
  '3.1': {
    node: <ProjectSZPKForms.Six />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '3.2': {
    node: <ProjectSZPKForms.Seven />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '3.3': {
    node: <ProjectSZPKForms.Eight />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '3.4': {
    node: <ProjectSZPKForms.Nine />,
  },
  '3.5': {
    node: <ProjectSZPKForms.Ten />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '4.1': {
    node: <ProjectSZPKForms.Eleven />,
  },
  '4.3': {
    node: <ProjectSZPKForms.Thirteen />,
  },
  '5.1': {
    node: <ProjectSZPKForms.Fourteen />,
  },
  '5.2': {
    node: <ProjectSZPKForms.Fifteen />,
  },
  '5.3': {
    node: <ProjectSZPKForms.Sixteen />,
  },
} as const

const mapOfProjectRegionalSZPKForms = {
  ...mapOfProjectSZPKForms,
}
delete mapOfProjectRegionalSZPKForms['1.1']

const mapOfRegionalProjectSZPKMenu = mapOfProjectSZPKMenu.slice(1)

const ProjectSZPKForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const isFederalSZPK = useWatch({
    control: formInstance.control,
    name: 'additionalFields.isFederal',
  })

  const {
    state: { formIsLoading },
  } = useProjectSZPKManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={isFederalSZPK ? mapOfProjectSZPKMenu : mapOfRegionalProjectSZPKMenu}
      mapOfForms={isFederalSZPK ? mapOfProjectSZPKForms : mapOfProjectRegionalSZPKForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(ProjectSZPKForm)
