import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import DocumentDataView from '@components/DocumentFormComponents/DocumentDataView'
import { DocumentDataViewProps } from '@components/DocumentFormComponents/DocumentDataView/model'
import WithCaption from '@components/WithCaption'
import cn from 'classnames'

import styles from './ControlledDocumentDataView.module.scss'

export interface ControlledDocumentDataViewProps<T extends FieldValues>
  extends DocumentDataViewProps {
  controllerProps: Omit<ControllerProps<T>, 'render'>
}

export const ControlledDocumentDataView = <T extends FieldValues>(
  props: ControlledDocumentDataViewProps<T>,
) => {
  const { controllerProps, suptitleTypographyProps, contentTypographyProps, ...restProps } = props
  const { className: suptitleClassName, ...restSuptitleTypographyProps } =
    suptitleTypographyProps || {}
  const { className: contentClassName, ...restContentTypographyProps } =
    contentTypographyProps || {}

  const {
    field: { value },
    fieldState: { error },
  } = useController<T>(controllerProps)

  return (
    <WithCaption
      captionClassName={styles.controlledDocumentDataView__caption}
      caption={error?.message}
    >
      <DocumentDataView
        {...restProps}
        suptitleTypographyProps={{
          ...restSuptitleTypographyProps,
          className: cn(suptitleClassName, {
            [styles['controlledDocumentDataView__subtitle--error']]: !!error,
          }),
        }}
        contentTypographyProps={{
          ...restContentTypographyProps,
          className: cn(contentClassName, {
            [styles['controlledDocumentDataView__content--error']]: !!error,
          }),
        }}
      >
        {value}
      </DocumentDataView>
    </WithCaption>
  )
}

ControlledDocumentDataView.displayName = 'ControlledDocumentDataView'
