import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AssignmentMonetaryOrPledgeALayout from './Layout'
import AssignmentMonetaryOrPledgeAModalManager from './Manager'
import AssignmentMonetaryOrPledgeAMenu from './Menu'
import AssignmentMonetaryOrPledgeAStatementForm from './Statement'

interface IAssignmentMonetaryOrPledgeA extends FC {
  Layout: typeof AssignmentMonetaryOrPledgeALayout
  Menu: typeof AssignmentMonetaryOrPledgeAMenu
  StatementForm: typeof AssignmentMonetaryOrPledgeAStatementForm
  Manager: typeof AssignmentMonetaryOrPledgeAModalManager
}

const useAssignmentMonetaryOrPledgeAControl = () => {
  const popupManager = usePopupManager()

  const openAssignmentMonetaryOrPledgeAModal = useCallback(
    ({
      editMode = false,
      formId,
      projectId,
      initialErrorsFromViolations,
      rolesOfUser,
      documentsTypeOfSet,
      onClose,
    }: FormModalControlProps) => {
      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AssignmentMonetaryOrPledgeALayout, {
        projectId,
        formId,
        editMode,
        initialErrorsFromViolations,
        rolesOfUser,
        documentsTypeOfSet,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAssignmentMonetaryOrPledgeAModal }
}

const AssignmentMonetaryOrPledgeA: IAssignmentMonetaryOrPledgeA = () => null

AssignmentMonetaryOrPledgeA.Menu = AssignmentMonetaryOrPledgeAMenu
AssignmentMonetaryOrPledgeA.Layout = AssignmentMonetaryOrPledgeALayout
AssignmentMonetaryOrPledgeA.StatementForm = AssignmentMonetaryOrPledgeAStatementForm
AssignmentMonetaryOrPledgeA.Manager = AssignmentMonetaryOrPledgeAModalManager

export { useAssignmentMonetaryOrPledgeAControl }
export default AssignmentMonetaryOrPledgeA
