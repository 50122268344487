import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { objectAreaValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectAreasList/validation'
import {
  SeventhConstructionObjectAreasPathName,
  SeventhConstructionObjectsArrayPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

interface ObjectAreasListProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: SeventhConstructionObjectsArrayPathName
}

const ObjectAreasList: FC<ObjectAreasListProps> = ({
  editMode,
  blockViewIsValidating,
  formInstance,
  formName,
}) => {
  const {
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleChangeValue,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const {
    getSubscribableControlProps,
    getInputProps,

    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: objectAreas } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhConstructionObjectAreasPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${formName}.objectAreas`,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const handleAddNewObjectArea = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.objectAreas`)
  }

  const handleRemoveObjectArea = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${formName}.objectAreas.${index}`, `${formName}.objectAreas`)
  }

  return (
    <Group disableBottomBorder title="Земельные участки">
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.objectAreas`,
          })}
        >
          <FlipperList list={objectAreas}>
            {objectAreas.map((objectArea, index) => {
              const objectAreaFormName = `${formName}.objectAreas.${index}` as const

              return (
                <GroupWithRemoveButton
                  disableBottomBorder
                  key={objectArea.id}
                  id={objectArea.id}
                  title={`Земельный участок №${index + 1}`}
                  onRemove={editMode ? handleRemoveObjectArea(index) : undefined}
                >
                  <Row>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${objectAreaFormName}.objectAreaNumber`,
                        })}
                      >
                        <ControlledInput
                          {...getInputProps({
                            name: `${objectAreaFormName}.objectAreaNumber`,
                            rules: objectAreaValidationMap.objectAreaNumber,
                            inputProps: {
                              dataTestId: 'objectAreaNumber',
                              label: 'Кадастровый номер з/у',
                              caption: 'если применимо',
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${objectAreaFormName}.objectAreaNumber`)
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${objectAreaFormName}.objectAreaNumber`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${objectAreaFormName}.objectAreaSquare`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${objectAreaFormName}.objectAreaSquare`,
                            rules: objectAreaValidationMap.objectAreaSquare,
                            inputProps: {
                              dataTestId: 'objectAreaSquare',
                              label: 'Площадь з/у, кв. м',
                              suffix: '',
                              caption: 'если применимо',
                              minorPartView: 'withZeroMinorPart',
                              minority: 100000,
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${objectAreaFormName}.objectAreaSquare`)
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${objectAreaFormName}.objectAreaSquare`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                </GroupWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddNewObjectArea}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить данные об участке (если применимо)
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default ObjectAreasList
