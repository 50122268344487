import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { defaultRHFValidation } from '@constants/validations'

export const secondSectionValidationMap: FormValuesValidationSection<
  keyof DataOnFulfillmentOfTermsSZPKFormValues['2'],
  DataOnFulfillmentOfTermsSZPKFormValues
> = {
  initiatorHeaderName: {
    required: defaultRHFValidation.required,
  },
}
