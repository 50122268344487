import React, {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
} from 'react'
import { ControllerProps, FieldValues, Path, useController } from 'react-hook-form'
import { FieldPathValue, UnpackNestedValue } from 'react-hook-form/dist/types'

import { formFieldSuffixMap } from '@components/DocumentFormComponents/FormControls/FormFieldTooltipControl/const'
import FormActionIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormActionIconWithTooltip'
import TooltipContentWithValue from '@components/DocumentFormComponents/FormTooltip/FormActionIconWithTooltip/TooltipContentWithValue'
import { formActionTypes } from '@components/DocumentFormComponents/FormTooltip/FormActionIconWithTooltip/TooltipContentWithValue/const'
import { isSelectOption } from '@components/NewDesign/Select/helpers'
import { OptionProps } from '@components/NewDesign/Select/model'
import { isFunction, isUndefined } from '@helpers/checkTypes'
import { makeFirstLetterInUC } from '@helpers/string/makeFirstLetterInUC'
import { ValueOf } from '@helpers/ValueOf'
import useDebounce from '@hooks/useDebounce'

const uiComponentNames = [
  'ControllerTextarea',
  'ControlledInput',
  'ControlledMultipleInput',
  'ControlledCalendarInput',
  'ControlledMaskInput',
  'ControlledAmountInput',
  'ControlledSwitch',
  'ControlledCheckbox',
  'ControlledFormSingleSelect',
  'ControlledFormMultipleSelect',
  'ControlledHierarchyReferenceBookSelect',
]

interface FormFieldTooltipControlProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'> {
  children: ReactNode
  type?: ValueOf<typeof formActionTypes>
  suffix?: ValueOf<typeof formFieldSuffixMap>
  onChange?: (value: string | boolean | OptionProps | null) => void
  onDifference?: (path: Path<T> | string, isChanged: boolean) => void
}

const FormFieldTooltipControl = <T extends FieldValues>({
  type = formActionTypes.RETURN,
  suffix = formFieldSuffixMap.commited,
  name,
  children,
  onChange,
  onDifference,
  ...controllerProps
}: FormFieldTooltipControlProps<T>) => {
  const formName = useMemo(() => {
    const preparedSuffix = makeFirstLetterInUC(suffix, ' ')

    return `${name}${preparedSuffix}` as Path<T>
  }, [name, suffix])

  const {
    field: { value: fixedValue },
  } = useController({
    ...controllerProps,
    name: formName,
  })

  const {
    field: { value, onChange: onChangeValue },
  } = useController({
    ...controllerProps,
    name,
  })

  const debouncedValue: UnpackNestedValue<FieldPathValue<T, typeof name>> = useDebounce(value, 500)

  const handleActionClick = (
    value: string | boolean | OptionProps | null,
    actionType: ValueOf<typeof formActionTypes>,
  ) => {
    if (actionType === formActionTypes.RETURN) {
      onChangeValue(value)

      onChange?.(value)
    }
  }

  useEffect(() => {
    if (isUndefined(onDifference)) return

    const isIdenticalSelectOption =
      isSelectOption(fixedValue) &&
      isSelectOption(debouncedValue) &&
      debouncedValue.value === fixedValue.value

    const isIdenticalSimpleTypeValue =
      !isUndefined(fixedValue) && !isUndefined(value) && debouncedValue === fixedValue

    const isNotChanged =
      isUndefined(fixedValue) || isIdenticalSelectOption || isIdenticalSimpleTypeValue

    onDifference(name, !isNotChanged)

    return () => onDifference(name, false)
  }, [debouncedValue, name])

  const cloneSelectComponent = (children: ReactElement, value: OptionProps) => {
    return cloneElement(children, {
      ...children.props,
      selectProps: {
        ...children.props.selectProps,
        inputProps: {
          ...children.props.selectProps.inputProps,
          leftAddons: (
            <FormActionIconWithTooltip
              tooltipContent={
                <TooltipContentWithValue
                  type={type}
                  value={value}
                  onActionClick={
                    !children.props.selectProps.disabled ? handleActionClick : undefined
                  }
                />
              }
            />
          ),
        },
      },
    })
  }

  const cloneCheckboxComponentByPropsName = (children: ReactElement, value: string) => {
    return cloneElement(children, {
      ...children.props,
      checkBoxProps: {
        ...children.props.checkBoxProps,
        leftAddons: (
          <FormActionIconWithTooltip
            tooltipContent={
              <TooltipContentWithValue
                type={type}
                value={value}
                onActionClick={
                  !children.props.checkBoxProps.disabled ? handleActionClick : undefined
                }
              />
            }
            tooltipProps={{
              offset: [30, 10],
            }}
          />
        ),
      },
    })
  }

  const cloneComponentByPropsName = (children: ReactElement, propsName: string, value: string) => {
    return cloneElement(children, {
      ...children.props,
      [propsName]: {
        ...children.props[propsName],
        leftAddons: (
          <FormActionIconWithTooltip
            tooltipContent={
              <TooltipContentWithValue
                type={type}
                value={value}
                onActionClick={!children.props[propsName]?.disabled ? handleActionClick : undefined}
              />
            }
          />
        ),
      },
    })
  }

  const cloneChild = (child: ReactElement) => {
    const isIdenticalSelectOption =
      isSelectOption(fixedValue) &&
      isSelectOption(debouncedValue) &&
      debouncedValue.value === fixedValue.value

    const isIdenticalSimpleTypeValue =
      !isUndefined(fixedValue) && !isUndefined(value) && debouncedValue === fixedValue

    if (isUndefined(fixedValue) || isIdenticalSelectOption || isIdenticalSimpleTypeValue)
      return child

    if ('selectProps' in child.props) {
      return cloneSelectComponent(child, fixedValue)
    }

    if ('textareaProps' in child.props) {
      return cloneComponentByPropsName(child, 'textareaProps', fixedValue)
    }

    if ('calendarInputProps' in child.props) {
      return cloneComponentByPropsName(child, 'calendarInputProps', fixedValue)
    }

    if ('switchProps' in child.props) {
      return cloneComponentByPropsName(child, 'switchProps', fixedValue)
    }

    if ('checkBoxProps' in child.props) {
      return cloneCheckboxComponentByPropsName(child, fixedValue)
    }

    if ('inputProps' in child.props) {
      return cloneComponentByPropsName(child, 'inputProps', fixedValue)
    }

    return child
  }

  const renderRecursiveMap = (children: ReactNode) => {
    return React.Children.map(children, (child) => {
      if (!isValidElement(child)) return child

      if (
        isFunction(child.type) &&
        'displayName' in child.type &&
        uiComponentNames.includes(String(child.type?.['displayName']))
      ) {
        return cloneChild(child)
      }

      if (!!child?.props?.children) {
        return cloneElement(child, {
          ...child?.props,
          children: renderRecursiveMap(child.props.children),
        })
      }

      return cloneElement(child, child?.props)
    })
  }

  return (
    <div id={name} key={name}>
      {renderRecursiveMap(children)}
    </div>
  )
}

export type { FormFieldTooltipControlProps }
export default FormFieldTooltipControl
