import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import RegistrationCertificateMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import RegistrationCertificateLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import RegistrationCertificateModalManager from './Manager'
import RegistrationCertificateMenu from './Menu'
import RegistrationCertificateStatementForm from './Statement'

interface IRegistrationCertificate extends FC {
  LayoutWithFormProvider: typeof RegistrationCertificateLayoutWithFormProvider
  Menu: typeof RegistrationCertificateMenu
  StatementForm: typeof RegistrationCertificateStatementForm
  Manager: typeof RegistrationCertificateModalManager
  MenuManager: typeof RegistrationCertificateMenuManager
}

const useRegistrationCertificateControl = () => {
  const popupManager = usePopupManager()

  const openRegistrationCertificateModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(RegistrationCertificateLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openRegistrationCertificateModal }
}

const RegistrationCertificate: IRegistrationCertificate = () => null

RegistrationCertificate.Menu = RegistrationCertificateMenu
RegistrationCertificate.LayoutWithFormProvider = RegistrationCertificateLayoutWithFormProvider
RegistrationCertificate.StatementForm = RegistrationCertificateStatementForm
RegistrationCertificate.Manager = RegistrationCertificateModalManager
RegistrationCertificate.MenuManager = RegistrationCertificateMenuManager

export { useRegistrationCertificateControl }
export default RegistrationCertificate
