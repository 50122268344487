import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultCertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import CertificateOnMonitoringResultsLayout from '@components/Forms/CertificateOnMonitoringResultsForm/Layout'

const CertificateOnMonitoringResultsLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultCertificateOnMonitoringResultsFormValues}>
      <CertificateOnMonitoringResultsLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(CertificateOnMonitoringResultsLayoutWithFormProvider)
