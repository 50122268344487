import React, { FC, memo, useCallback, useEffect, useReducer } from 'react'
import { usePopupManager } from 'react-popup-manager'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { ErrorTypes } from '@components/Forms/LoginForm/constants'
import useGovermentDomainModal from '@components/Forms/LoginForm/hooks/useGovermentDomainModal'
import useLoginErrorHandler from '@components/Forms/LoginForm/hooks/useLoginErrorHandler'
import { certificateActionCreators } from '@components/Forms/LoginForm/LoginByCrypt/CertificateForm/reducer'
import { procurationComponentInfo } from '@components/Forms/LoginForm/LoginByCrypt/ProcurationForm/constants'
import OtherProcuration from '@components/Forms/LoginForm/LoginByCrypt/ProcurationForm/OtherProcuration'
import {
  HandleSubmitFormProps,
  ProcurationRegistrationRedirectProps,
} from '@components/Forms/LoginForm/LoginByCrypt/ProcurationForm/types'
import LoginError from '@components/Forms/LoginForm/shared/LoginError'
import { mapOfTypeRedirect } from '@components/Forms/RegistrationForm/const'
import { RegistrationLocationState } from '@components/Forms/RegistrationForm/types'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography/Typography'
import { Paths } from '@constants/paths'
import { useAuthContext } from '@context/AuthContext'
import { signInByCertificateTypes } from '@context/AuthContext/hooks/useAuth'
import { ProcurationData } from '@context/AuthContext/types'
import { isAxiosError, isJsError } from '@helpers/checkTypes'
import arrowBackIcon from '@icons/navigation/arrow_back.svg'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'

import styles from './ProcurationForm.module.scss'
import Procurations from './Procurations'
import { initialProcurationState, procurationActionCreators, procurationReducer } from './reducer'

interface ProcurationFormProps {
  procurationList: ProcurationData
  signature: string
  onProcurationChange: (isShowProcuration: boolean) => void
  onError: (error: unknown | null) => void
  onSuccessAuthorization: VoidFunction
}

const ProcurationForm: FC<ProcurationFormProps> = ({
  procurationList,
  signature,
  onProcurationChange,
  onError,
  onSuccessAuthorization,
}) => {
  const { signInByCertificate } = useAuthContext()

  const location = useLocation()
  const locationState = location.state as RegistrationLocationState | undefined

  const navigate = useNavigate()
  const popupManager = usePopupManager()

  const [state, dispatch] = useReducer(procurationReducer, {
    ...initialProcurationState,
    isOtherProcuration: !procurationList?.length,
  })

  const { handleOpenRedirectGovermentDomainModal } = useGovermentDomainModal()

  useEffect(() => {
    if (
      locationState?.from === mapOfTypeRedirect.MCHD &&
      locationState.state.MCHDNumber &&
      locationState.state.organizationINN
    ) {
      (async () => {
        await handleSubmitForm({
          MChDNumber: locationState.state.MCHDNumber,
          organizationINN: locationState.state.organizationINN,
        })
      })()

      return
    }
    if (Boolean(procurationList.length)) return

    dispatch(procurationActionCreators.setOtherProcuration(true))

    return () => {
      callbaseErrorAction()
    }
  }, [])

  const callbaseErrorAction = useCallback(() => {
    dispatch(procurationActionCreators.setLoading(false))
    dispatch(procurationActionCreators.setCaption(''))
  }, [])

  const handleClearReducerError = useCallback(() => {
    dispatch(procurationActionCreators.setLoading(false))
    dispatch(procurationActionCreators.setError(null))
  }, [])

  const handleNativeReducerError = useCallback(
    (error: unknown) => {
      LoggerHelpersService.handleInternalLogError({
        componentInfo: procurationComponentInfo,
      })(error)

      if (!isJsError(error)) throw error

      dispatch(procurationActionCreators.setNativeError(error))
      onError(error)
    },
    [onError],
  )

  const handleReducerError = useCallback(
    (error: AxiosError) => {
      dispatch(certificateActionCreators.setError(error))

      onError(error)
    },
    [onError],
  )

  const onGovermentDomainModalOpen = useCallback(
    (domain: string) => {
      callbaseErrorAction()
      dispatch(procurationActionCreators.setError(null))

      handleOpenRedirectGovermentDomainModal(domain)

      onError(null)
    },
    [callbaseErrorAction, handleOpenRedirectGovermentDomainModal, onError],
  )

  const notAllowedDomainHandler = useCallback(
    (error: AxiosError) => {
      const { error_description: domain, error: error_type } = error.response?.data || {}

      if (error_type === ErrorTypes.domain_not_allowed) {
        onGovermentDomainModalOpen(domain)

        return
      }

      dispatch(procurationActionCreators.setError(error))
    },
    [onGovermentDomainModalOpen],
  )

  const { handleErrorByType } = useLoginErrorHandler({
    notAllowedDomainHandler,
  })

  const handleCloseConfidentialModal = useCallback(() => {
    popupManager.closeAll()
    handleClearReducerError()
    onError(null)
  }, [handleClearReducerError, onError, popupManager])

  const handleRegistrationRedirect = useCallback(
    async ({
      MChDNumber,
      organizationINN,
      authenticationKey,
      signature,
    }: ProcurationRegistrationRedirectProps) => {
      if (!authenticationKey) return handleCloseConfidentialModal()

      if (!MChDNumber || !organizationINN || !signature) return

      const stateToRegistration: RegistrationLocationState = {
        from: mapOfTypeRedirect.MCHD,
        authenticationKey,
        state: {
          MCHDNumber: MChDNumber,
          organizationINN,
          signature,
          procurationList,
        },
      }

      navigate(Paths.Registration, {
        state: stateToRegistration,
      })
    },
    [handleCloseConfidentialModal, navigate, procurationList],
  )

  const handleSubmitForm = useCallback(
    async ({ MChDNumber, organizationINN }: HandleSubmitFormProps) => {
      dispatch(procurationActionCreators.setLoading(true))

      try {
        await signInByCertificate?.(
          {
            type: signInByCertificateTypes.default,
            body: {
              data: signature,
              number: MChDNumber,
              issuerINN: organizationINN,
            },
          },
          true,
        )
        onSuccessAuthorization()
      } catch (error) {
        if (!isAxiosError(error)) {
          handleNativeReducerError(error)

          throw error
        }

        const { response } = error
        const authenticationKey = response?.data?.authenticationKey as string | undefined

        handleErrorByType({
          error,
          onRegistrationRedirect: () =>
            authenticationKey
              ? handleRegistrationRedirect({
                  authenticationKey,
                  organizationINN,
                  MChDNumber,
                  signature,
                })
              : undefined,
          onError: handleReducerError,
        })
      } finally {
        callbaseErrorAction()
      }
    },
    [
      signInByCertificate,
      signature,
      onSuccessAuthorization,
      handleErrorByType,
      handleCloseConfidentialModal,
      handleReducerError,
      handleNativeReducerError,
      callbaseErrorAction,
    ],
  )

  const handleBackButton = useCallback(() => {
    handleClearReducerError()

    if (state.isOtherProcuration && procurationList.length) {
      return dispatch(procurationActionCreators.setOtherProcuration(false))
    }

    onProcurationChange(false)
    onError(null)
  }, [
    handleClearReducerError,
    onError,
    onProcurationChange,
    procurationList.length,
    state.isOtherProcuration,
  ])

  const handleProcurationSelect = useCallback(
    async (MChDNumber: string, organizationINN: string) => {
      await handleSubmitForm({ MChDNumber, organizationINN })
    },
    [handleSubmitForm],
  )

  const handleSetOtherProcuration = useCallback(() => {
    dispatch(procurationActionCreators.setOtherProcuration(true))
  }, [])

  const goToFormHandler = useCallback(() => {
    handleClearReducerError()
    onError(null)
  }, [handleClearReducerError, onError])

  const isShowLoginError = Boolean(state.error?.header) || state.isLoading

  if (isShowLoginError) {
    return (
      <LoginError
        error={state.error}
        isLoading={state.isLoading}
        caption={state.caption}
        goToFormHandler={goToFormHandler}
      />
    )
  }

  return (
    <div className={styles.wrapper}>
      {!state.isOtherProcuration && (
        <>
          <div className={styles.container}>
            <div className={styles.title}>
              <IconButton
                size="m"
                className={styles.title__icon}
                dataTestId="ProcurationForm-back-button"
                icon={{
                  src: arrowBackIcon,
                }}
                onClick={handleBackButton}
              />
              <Typography.Headline variant="headlineH2" align="center">
                Выберите доверенность
              </Typography.Headline>
            </div>
            <Typography.Body variant="bodyMRegular" className={styles.text} align="center">
              Чтобы действовать от лица организации, выберите машиночитаемую доверенность, выданную
              на ваше имя или{' '}
              <span
                aria-hidden
                className={styles.otherProcuration}
                data-testid="ProcurationForm-otherProcuration-button"
                onClick={handleSetOtherProcuration}
              >
                Укажите реквизиты другой доверенности
              </span>
            </Typography.Body>
          </div>
          <div className={styles.form}>
            <div className={styles.procurationWrapper}>
              <div className={styles.procurationWrapper__container}>
                {!!procurationList.length &&
                  procurationList.map((procuration) => (
                    <Procurations
                      key={procuration.number}
                      procuration={procuration}
                      className={styles.procurations}
                      onChooseProcuration={handleProcurationSelect}
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
      {state.isOtherProcuration && (
        <OtherProcuration onBackButton={handleBackButton} onSubmitForm={handleSubmitForm} />
      )}
    </div>
  )
}

export default memo(ProcurationForm)
