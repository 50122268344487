import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import AAgreementOnCreationOtherEstateObjectsMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'
import AAgreementOnCreationOtherEstateObjectsLayoutWithFormProvider from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Layout/LayoutWithFormProvider'
import AAgreementOnCreationOtherEstateObjectsModalManager from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import AAgreementOnCreationOtherEstateObjectsMenu from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Menu'
import AAgreementOnCreationOtherEstateObjectsForm from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Statement'

interface AAgreementOnCreationOtherEstateObjectsProps extends FC {
  LayoutWithFormProvider: typeof AAgreementOnCreationOtherEstateObjectsLayoutWithFormProvider
  Menu: typeof AAgreementOnCreationOtherEstateObjectsMenu
  StatementForm: typeof AAgreementOnCreationOtherEstateObjectsForm
  Manager: typeof AAgreementOnCreationOtherEstateObjectsModalManager
  MenuManager: typeof AAgreementOnCreationOtherEstateObjectsMenuManager
}

const useAAgreementOnCreationOtherEstateObjectsControl = () => {
  const popupManager = usePopupManager()

  const openAAgreementOnCreationOtherEstateObjectsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AAgreementOnCreationOtherEstateObjectsLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAAgreementOnCreationOtherEstateObjectsModal }
}

const AAgreementOnCreationOtherEstateObjects: AAgreementOnCreationOtherEstateObjectsProps = () =>
  null

AAgreementOnCreationOtherEstateObjects.Menu = AAgreementOnCreationOtherEstateObjectsMenu
AAgreementOnCreationOtherEstateObjects.LayoutWithFormProvider = AAgreementOnCreationOtherEstateObjectsLayoutWithFormProvider
AAgreementOnCreationOtherEstateObjects.StatementForm = AAgreementOnCreationOtherEstateObjectsForm
AAgreementOnCreationOtherEstateObjects.Manager = AAgreementOnCreationOtherEstateObjectsModalManager
AAgreementOnCreationOtherEstateObjects.MenuManager =
  AAgreementOnCreationOtherEstateObjectsMenuManager

export { useAAgreementOnCreationOtherEstateObjectsControl }
export default AAgreementOnCreationOtherEstateObjects
