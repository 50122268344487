import React, { memo, SyntheticEvent } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useFlatMenuManager as useCreateStatementMenuManager } from '@components/DocumentFormComponents/Menu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { taxFieldsValidation } from '@components/Forms/CreateStatement/Forms/PlannedTax/Taxes/validation'
import {
  TaxesTypeArrayPathName,
  TaxesTypePathName,
} from '@components/Forms/CreateStatement/Forms/PlannedTax/types'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import {
  CreateStatementControlUpdateWatcher,
  CreateStatementFieldArrayControlUpdateWatcher,
} from '@components/Forms/CreateStatement/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import cn from 'classnames'
import dayjs from 'dayjs'

import styles from './Taxes.module.scss'

const Taxes = () => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()
  const { handleChangeCurrentAnchorId } = useCreateStatementMenuManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CreateStatementControlUpdateWatcher,
  })

  const { fields: plannedTaxes } = useFieldArraySubscribableControl<
    StatementFormValues,
    TaxesTypePathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: statementBlockValues.plannedTax.taxesType,
    keyName: 'keyNameId',
    watcher: CreateStatementFieldArrayControlUpdateWatcher,
  })

  const startProjectDate = useWatch({
    name: statementBlockValues.stage.projectStartDate,
    control: formInstance?.control,
  })
  const agreementEndDate = useWatch({
    name: statementBlockValues.stage.agreementEndDate,
    control: formInstance?.control,
  })

  const formattedStartDate = dayjs(startProjectDate, objOfDateFormats.defaultFormat)
  const formattedEndDate = dayjs(agreementEndDate, objOfDateFormats.defaultFormat)

  const reverseVersionTaxesDate = useWatch({
    name: statementBlockValues.stage.projectEndDate,
    control: formInstance?.control,
  })

  const conditionToCalculateTaxes =
    formattedStartDate.isValid() &&
    formattedEndDate.isValid() &&
    formattedEndDate >= formattedStartDate

  const conditionToRenderTaxes =
    conditionToCalculateTaxes ||
    (!!startProjectDate && !!reverseVersionTaxesDate && !!plannedTaxes.length)

  if (!formInstance) return null

  const goToTaxesAnchorHandler = (e: SyntheticEvent) => {
    e.preventDefault()

    const sectionName = 'stage'

    handleChangeCurrentAnchorId?.(sectionName)
  }

  return (
    <Row>
      <Col
        xs={12}
        className={cn({
          [styles['taxes__wrapper--only-text']]: !conditionToRenderTaxes,
        })}
      >
        {!conditionToRenderTaxes ? (
          <div className={styles.taxes__description}>
            <Typography.Body variant={'bodyMRegular'}>
              Укажите планируемую дату окончания соглашения и сроки реализации проекта в разделе
              <button className={styles.BrandText} onClick={goToTaxesAnchorHandler}>
                &nbsp;«Этапы реализации проекта»&nbsp;
              </button>
              для того, чтобы добавить объём налогов и иных обязательных платежей из расчёта на
              каждый год
            </Typography.Body>
          </div>
        ) : (
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={
              <div className={styles.taxes__description}>
                <Typography
                  className={styles['taxes__description-item']}
                  variant={'bodyMRegular'}
                  as={'span'}
                >
                  Укажите{' '}
                  <Typography
                    className={styles['taxes__description-item']}
                    variant={'bodyMSemibold'}
                    as={'span'}
                  >
                    прогнозируемый объем налогов и иных обязательных платежей{' '}
                  </Typography>
                  в связи с реализацией проекта из расчета{' '}
                  <Typography
                    className={styles['taxes__description-item']}
                    variant={'bodyMSemibold'}
                    as={'span'}
                  >
                    на каждый год реализации проекта{' '}
                  </Typography>
                  в период действия соглашения
                </Typography>
              </div>
            }
          >
            <CollapseWrapper
              isExpanded={conditionToRenderTaxes}
              defaultExpanded={conditionToRenderTaxes}
            >
              <Stack direction={'vertical'} gap={3}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.plannedTax.taxesType,
                  })}
                >
                  {plannedTaxes.map((item, index) => {
                    const formName =
                      `${statementBlockValues.plannedTax.taxesType}.${index}` as TaxesTypeArrayPathName

                    return (
                      <SubscribableControl
                        key={item.id}
                        {...getSubscribableControlProps({
                          path: `${formName}.taxYearValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${formName}.taxYearValue`,
                            rules: taxFieldsValidation.taxYearValue,

                            inputProps: {
                              label: dayjs(item.taxYear).year().toString(),
                              hasNegativeValue: true,
                              dataTestId: `${formName}.taxYearValue-amountInput`,
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${formName}.taxYearValue`, 'taxes')
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(`${formName}.taxYearValue`, 'taxes')
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    )
                  })}
                </SubscribableControl>
              </Stack>
            </CollapseWrapper>
          </FieldView.RowWithBottomBorder>
        )}
      </Col>
    </Row>
  )
}

export default memo(Taxes)
