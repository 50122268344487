import { ComponentInfo } from '@services/LoggerService/LoggerService.entity'

const procurationReducerActionMap = {
  SETUP_LOADING: 'SETUP_LOADING',
  SETUP_OTHER_PROCURATION: 'SETUP_OTHER_PROCURATION',
  SETUP_CAPTION: 'SETUP_CAPTION',
  SETUP_ERROR: 'SETUP_ERROR',
  SETUP_REGISTRATION_ERROR: 'SETUP_REGISTRATION_ERROR',
  SETUP_NATIVE_ERROR: 'SETUP_NATIVE_ERROR',
} as const

const procurationComponentInfo: ComponentInfo = {
  componentName: 'ProcurationForm',
  moduleName: 'LoginByCrypt',
  componentType: 'authByProcurationCertificate',
}

const procurationReducerComponentInfo: ComponentInfo = {
  componentName: 'procurationReducer',
  moduleName: 'ProcurationForm',
  componentType: 'reducer',
}

const procurationConfidentialModalComponentInfo: ComponentInfo = {
  componentName: 'ConfidentialModal',
  moduleName: 'ProcurationForm',
  componentType: 'registrationOrganization',
}

export {
  procurationComponentInfo,
  procurationConfidentialModalComponentInfo,
  procurationReducerActionMap,
  procurationReducerComponentInfo,
}
