import { DependentUrls, Urls } from '@constants/urls'
import { GetRegions } from '@context/APIContext/hooks/useDictionariesApi/types'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { stringifyQuery } from '@helpers/format'
import { IPostAttributeBody } from '@services/Attribute/Attribute.entity'
import AttributeService from '@services/Attribute/Attribute.service'
import {
  CreateProjectDfoParams,
  IDfoNewList,
  IResponseFromCreateDfoRequest,
  TAllDfoTypes,
} from '@services/Dfo/Dfo.entity'
import { TGetProjectMembers } from '@services/Members/Members.entity'
import { TPutProcessingOrders } from '@services/Processes/processes.entity'
import {
  IProject,
  TGetAllProjects,
  TGetAllProjectsCounter,
  TGetProjectActions,
  TGetProjectAttributes,
  TPostOrganizationIngoChangesProjectBody,
  TPostProjectBody,
  TPostProjectResponse,
} from '@services/Projects/Project.entity'

import type {
  CreateProjectRegistrateProps,
  EventItem,
  GetEventsByProjectIdParams,
  ProjectActionData,
  ProjectActionProps,
  ProjectListProps,
  UpdateProjectNameData,
} from './types'

const { fromBackendAttributeToJsTypes } = AttributeService

const useProjectsApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const getAllProjects = async ({
    type,
    page,
    size,
    dfoGroupType,
    searchString,
    actionAllowed,
    orderBy,
    dfoStageExclude,
    dfoStageInclude,
    stage,
    isRegistered,
    isFederal,
  }: ProjectListProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetAllProjects>({
        url: `${Urls.ProjectsNew}`,
        config: {
          params: {
            page,
            size,
            dfoGroupType,
            type,
            searchString,
            actionAllowed,
            orderBy,
            dfoStageExclude,
            dfoStageInclude,
            stage,
            isRegistered,
            isFederal,
          },
          paramsSerializer: stringifyQuery,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getAllProjectsCount = async ({
    type,
    page,
    size,
    dfoGroupType,
    searchString,
    actionAllowed,
    orderBy,
    dfoStageExclude,
    dfoStageInclude,
    stage,
    isRegistered,
    isFederal,
  }: ProjectListProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetAllProjectsCounter>({
        url: `${Urls.ProjectsNew}/count`,
        config: {
          params: {
            page,
            size,
            dfoGroupType,
            type,
            searchString,
            actionAllowed,
            orderBy,
            dfoStageExclude,
            dfoStageInclude,
            stage,
            isRegistered,
            isFederal,
          },
          paramsSerializer: stringifyQuery,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getProjectById = async (projectId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<IProject>({
        url: {
          url: DependentUrls.ProjectsById,
          params: {
            projectId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getProjectDfosById = async (projectId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<IDfoNewList[]>({
        url: {
          url: DependentUrls.ProjectDfos,
          params: {
            projectId,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const createProjectDfo = async ({ projectId, type, parameters }: CreateProjectDfoParams) => {
    try {
      return await FetcherWithInterceptors.post<IResponseFromCreateDfoRequest>({
        url: {
          url: DependentUrls.ProjectDfos,
          params: {
            projectId,
          },
        },
        data: {
          type,
          parameters,
        },
      })
    } catch (error) {
      handleErrorWithPopup(error)

      throw error
    }
  }

  const getProjectMembersById = async (projectId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetProjectMembers>({
        url: {
          url: DependentUrls.Members,
          params: {
            projectId,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getProjectAttributesById = async (projectId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetProjectAttributes>({
        url: {
          url: DependentUrls.ProjectAttribute,
          params: {
            projectId,
          },
        },
      })
      if (!Array.isArray(data)) return data

      return data.map((attribute) => {
        try {
          return { ...attribute, value: fromBackendAttributeToJsTypes(attribute) }
        } catch {
          return attribute
        }
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  /**
   * Метод изменения атрибута проекта
   * @param projectId Идентификатор проекта
   * @param name Название атрибута
   * @param value Значение атрибута
   * @returns
   */
  const changeProjectAttribute = async (projectId: string, { name, value }: IPostAttributeBody) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.ProjectAttribute,
          params: {
            projectId,
          },
        },
        data: {
          name,
          value,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  /**
   * Метод используется для получения списка доступных пользователю действий над всеми его ОДО
   * @param projectId Идентификатор проекта, к которому относится ДО
   * @returns
   */
  const getProjectActions = async (projectId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetProjectActions>({
        url: {
          url: DependentUrls.GetProjectActions,
          params: {
            projectId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  /**
   * Метод используется для выполнения действия над процессом ДО.
   * @param projectId Идентификатор проекта
   * @param processIds Массив идентификаторов процессов
   * @param action Действие над процессами
   * @returns
   */
  const changeProjectAction = async ({ projectId, actionId }: ProjectActionProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post<ProjectActionData>({
        url: {
          url: DependentUrls.ChangeProjectAction,
          params: {
            projectId,
            actionId,
          },
        },
        header: 'JSON',
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const updateProjectInfo = async ({ projectId, name }: UpdateProjectNameData) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.ProjectsById,
          params: {
            projectId,
          },
        },
        data: {
          name,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const createProject = async (
    props: TPostProjectBody | TPostOrganizationIngoChangesProjectBody,
  ) => {
    try {
      const { data } = await FetcherWithInterceptors.post<TPostProjectResponse>({
        url: `${Urls.ProjectsNew}`,
        data: props,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getAvailableDfoForCreate = async (projectId: string) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TAllDfoTypes[]>({
        url: {
          url: `${DependentUrls.ProjectDfos}/available-for-create`,
          params: {
            projectId,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const deleteProjectById = async (projectId: string) => {
    if (!projectId) return

    try {
      const { data } = await FetcherWithInterceptors.delete({
        url: {
          url: DependentUrls.ProjectsById,
          params: {
            projectId,
          },
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getEventsByProjectId = async ({ projectId, dfoTypes }: GetEventsByProjectIdParams) => {
    try {
      const { data } = await FetcherWithInterceptors.get<EventItem[]>({
        url: {
          url: DependentUrls.EventsByProjectId,
          params: {
            projectId,
          },
        },
        config: {
          params: {
            dfoType: dfoTypes,
          },
          paramsSerializer: stringifyQuery,
        },
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const createProjectRegistrate = async ({
    actionId,
    projectId,
    registeredDate,
    registeredNumber,
    registeredData,
  }: CreateProjectRegistrateProps) => {
    if (!projectId) return

    const preparedBody = registeredDate
      ? {
          registeredDate,
          registeredNumber,
        }
      : {
          registeredData,
        }

    try {
      const { data } = await FetcherWithInterceptors.post({
        url: {
          url: DependentUrls.ProjectRegistrate,
          params: {
            actionId,
            projectId,
          },
        },
        data: preparedBody,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const createNewProjectFromCopy = async (projectId: string) => {
    try {
      const response = await FetcherWithInterceptors.post<TPostProjectResponse>({
        url: {
          url: DependentUrls.CopyProject,
          params: {
            projectId,
          },
        },
      })

      return response.data
    } catch (error) {
      throw error
    }
  }

  const updateProcessingOrder = async (
    projectId: string,
    dfoId: string,
    processingOrderInfo: TPutProcessingOrders,
  ) => {
    try {
      const response = await FetcherWithInterceptors.put<void>({
        url: {
          url: DependentUrls.ProcessingOrder,
          params: {
            projectId,
            dfoId,
          },
        },
        data: processingOrderInfo,
      })

      return response.data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getRegionsByProject = async (projectId: string) => {
    const { data } = await FetcherWithInterceptors.get<GetRegions>({
      url: {
        url: DependentUrls.RegionsByProjectId,
        params: {
          projectId,
        },
      },
    })

    return data
  }

  const removeSigningBlock = async (projectId: string) => {
    const { data, status } = await FetcherWithInterceptors.delete({
      url: {
        url: DependentUrls.SigningBlock,
        params: {
          projectId,
        },
      },
    })

    return { data, status }
  }

  return {
    getAllProjects,
    getAllProjectsCount,
    getProjectById,
    getProjectDfosById,
    getProjectMembersById,
    getProjectAttributesById,
    getProjectActions,
    getAvailableDfoForCreate,
    getRegionsByProject,

    deleteProjectById,

    changeProjectAction,
    updateProjectInfo,

    createProjectRegistrate,

    changeProjectAttribute,
    createProject,

    getEventsByProjectId,

    createProjectDfo,

    createNewProjectFromCopy,

    updateProcessingOrder,

    removeSigningBlock,
  }
}

export default useProjectsApi
