import { Roles } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { findOne } from '@helpers/commonHelpers'
import { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useCheckUserToOldStatement = ({ key, config }: SWRHookProps<boolean | undefined>) => {
  const {
    organizationsApi: { checkUserToOldStatement },
  } = useAPIContext()

  const { getUserRole } = useAuthContext()

  const { roles } = getUserRole?.() || {}

  const isInvestor = roles && findOne(roles, Roles.Investor)

  const {
    error,
    mutate,
    isValidating: isLoadingCheckUserToOldStatement,
  } = useSWRImmutable(key, checkUserToOldStatement, config)

  const isOldStatement = isInvestor ? !error && !isLoadingCheckUserToOldStatement : false

  return {
    isOldStatement,
    error,
    mutate,
    isLoadingCheckUserToOldStatement,
  }
}

export { useCheckUserToOldStatement }
