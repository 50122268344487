import { memo } from 'react'

import styles from './AuthorizedOrganizations.module.scss'
import CompetentAuthoritiesGrid from './Grid'
import CompetentAuthoritiesHeader from './Header'
import CompetentAuthoritiesManager from './Manager'

const AuthorizedOrganizations = () => {
  return (
    <CompetentAuthoritiesManager>
      <div className={styles.general}>
        <header className={styles.general__header}>
          <CompetentAuthoritiesHeader />
        </header>
        <main className={styles.general__body}>
          <CompetentAuthoritiesGrid />
        </main>
      </div>
    </CompetentAuthoritiesManager>
  )
}

export default memo(AuthorizedOrganizations)
