import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationDrawalAppConclusionSZPKForm from '@components/Forms/NotificationDrawalAppConclusionSZPKForm'
import {
  defaultNotificationDrawalAppConclusionSZPKFormValues,
  mapOfNotificationDrawalAppConclusionSZPKFormMenu,
} from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/const'
import { NotificationDrawalAppConclusionSZPKFieldsControlUpdateWatcher } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'

import NotificationDrawalAppConclusionSZPKFormLayoutWrapper from './Wrapper'

const NotificationDrawalAppConclusionSZPKFormLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps({
    defaultValues: defaultNotificationDrawalAppConclusionSZPKFormValues,
  })

  const handleOnClose = () => {
    NotificationDrawalAppConclusionSZPKFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationDrawalAppConclusionSZPKForm.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfNotificationDrawalAppConclusionSZPKFormMenu[0].id}
          >
            <NotificationDrawalAppConclusionSZPKFormLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об отзыве заявления о заключении СЗПК
                </Typography.Headline>
                <NotificationDrawalAppConclusionSZPKForm.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <NotificationDrawalAppConclusionSZPKForm.Menu className={styles.layout__menu} />
              </div>
            </NotificationDrawalAppConclusionSZPKFormLayoutWrapper>
          </FlatMenuManager>
        </NotificationDrawalAppConclusionSZPKForm.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NotificationDrawalAppConclusionSZPKFormLayout)
