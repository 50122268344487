import React from 'react'
import { FieldValues, Path } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { BaseSectionProps } from '@components/DocumentFormComponents/FormSections/types'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const ProjectNameSection = <T extends FieldValues>({
  formInstance,
  formName,
  watcher,
  subscribableControl,
}: BaseSectionProps<T>) => {
  const { getSubscribableControlProps, getControllerProps } = useFormComponentPresets({
    watcher,
    subscribableControl,
    formInstance,
  })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.projectName` as Path<T>,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование проекта'}
                controllerProps={getControllerProps({
                  name: `${formName}.projectName` as Path<T>,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default ProjectNameSection
