import React from 'react'
import { ArrayPath, FieldValues } from 'react-hook-form'

import Container from '@components/Container'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import SubjectForm from '@components/DocumentFormComponents/FormSections/SubjectsSection/AdditionalForms/SubjectForm'
import {
  ArrayWithId,
  SectionWithArray,
} from '@components/DocumentFormComponents/FormSections/types'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import Row from '@components/ReactBootstrap/Row'

const SubjectsSection = <T extends FieldValues>({
  editMode,
  blockViewIsValidating,
  formInstance,
  formName,
  subscribableControl,
  watcher,
  onChangeValue,
  fieldArrayWatcher,
}: SectionWithArray<T>) => {
  const { fields: subjects } = useFieldArraySubscribableControl<T, ArrayPath<T>, 'keyNameId'>({
    control: formInstance.control,
    name: formName as ArrayPath<T>,
    keyName: 'keyNameId',
    watcher: fieldArrayWatcher,
  })

  return (
    <Container className={'p-0'}>
      {(subjects as ArrayWithId<T, ArrayPath<T>>).map((subject, index) => (
        <Row key={subject.id}>
          <SubjectForm
            editMode={editMode}
            blockViewIsValidating={blockViewIsValidating}
            formName={`${formName}.${index}` as const}
            formInstance={formInstance}
            watcher={watcher}
            subscribableControl={subscribableControl}
            onChangeValue={onChangeValue}
          />
          {index !== subjects.length - 1 && <Border />}
        </Row>
      ))}
    </Container>
  )
}

export default SubjectsSection
