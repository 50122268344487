import { generatePropertiesObjectForNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValuesForm } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/adapters/PropertiesProps.adapter'
import { generateRHFObjectForNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValuesForm } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/adapters/RHF.adapter'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'

const useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues =>
    generateRHFObjectForNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValuesForm(
      objectFromPropertyState,
    )

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValuesForm(
      objectFromPropertyState,
    )

  return { generateRHFObject, generatePropertiesObject }
}

export { useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAdapters }
