import { FC, memo, ReactNode } from 'react'
import type { PopupProps } from 'react-popup-manager'

import Loader from '@components/Loader'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { isEmptyString } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './LoaderModal.module.scss'

interface LoaderSignModalProps extends Required<PopupProps> {
  title: string
  bodyText: ReactNode | string
}

const LoaderSignModal: FC<LoaderSignModalProps> = ({ isOpen, title, bodyText, onClose }) => {
  return (
    <Modal.Simple
      disableOnClose
      hideCloseIcon
      isOpen={isOpen}
      title={title}
      dataTestId="LoaderSignModal-loader-modal"
      closeButtonDataTestId="LoaderSignModal-loader-closeButton"
      simpleModalBodyClassName={styles.modalLoaderWrapper}
      simpleModalHeaderClassName={styles.modalHeader__loader}
      simpleModalTitleClassName={styles.modalHeader__loaderTitle}
      simpleModalContainerClassName={cn(styles.modalWrapper, {
        [styles['modalWrapper--hiddenHeader']]: isEmptyString(title),
      })}
      onClose={onClose}
    >
      <Loader
        loading
        variant="lite"
        className={styles.loader}
        dataTestId="LoaderSignModal-loader"
      />
      <Typography.Headline variant="headlineH3" className={styles.loadingText}>
        {bodyText}
      </Typography.Headline>
    </Modal.Simple>
  )
}

export default memo(LoaderSignModal)
