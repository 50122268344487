import KnowledgeBaseMobileHeader from '@components/KnowledgeBase/Mobile/Header'
import KnowledgeBaseMobileInfo from '@components/KnowledgeBase/Mobile/Info'
import KnowledgeBaseMobileMenu from '@components/KnowledgeBase/Mobile/Menu'
import KnowledgeBaseMobilePreview from '@components/KnowledgeBase/Mobile/Preview'
import KnowledgeBaseMobileRootList from '@components/KnowledgeBase/Mobile/RootList'

interface KnowledgeBaseMobileProps {
  Header: typeof KnowledgeBaseMobileHeader
  Info: typeof KnowledgeBaseMobileInfo
  Menu: typeof KnowledgeBaseMobileMenu
  Preview: typeof KnowledgeBaseMobilePreview
  RootList: typeof KnowledgeBaseMobileRootList
}

const KnowledgeBaseMobile: KnowledgeBaseMobileProps = () => null

KnowledgeBaseMobile.Header = KnowledgeBaseMobileHeader
KnowledgeBaseMobile.Info = KnowledgeBaseMobileInfo
KnowledgeBaseMobile.Menu = KnowledgeBaseMobileMenu
KnowledgeBaseMobile.Preview = KnowledgeBaseMobilePreview
KnowledgeBaseMobile.RootList = KnowledgeBaseMobileRootList

export default KnowledgeBaseMobile
