import { usePopupManager } from 'react-popup-manager'

import ErrorModal, { ErrorComponentProps } from '.'

const useErrorModal = () => {
  const popupManager = usePopupManager()

  const handleOpenErrorModal = ({
    bodyText = '',
    headerText = '',
    customActions,
  }: ErrorComponentProps) => {
    popupManager.open(ErrorModal, {
      bodyText,
      headerText,
      customActions,
      onClose: () => null,
    })
  }

  return {
    handleOpenErrorModal,
  }
}

export { useErrorModal }
