import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import InformationOfExecutionAgreementEventsMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import InformationOfExecutionAgreementEventsLayout from './Layout'
import InformationOfExecutionAgreementEventsModalManager from './Manager'
import InformationOfExecutionAgreementEventsMenu from './Menu'
import InformationOfExecutionAgreementEventsStatementForm from './Statement'

interface IInformationOfExecutionAgreementEvents extends FC {
  Layout: typeof InformationOfExecutionAgreementEventsLayout
  Menu: typeof InformationOfExecutionAgreementEventsMenu
  StatementForm: typeof InformationOfExecutionAgreementEventsStatementForm
  Manager: typeof InformationOfExecutionAgreementEventsModalManager
  MenuManager: typeof InformationOfExecutionAgreementEventsMenuManager
}

const useInformationOfExecutionAgreementEventsControl = () => {
  const popupManager = usePopupManager()

  const openInformationOfExecutionAgreementEventsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(InformationOfExecutionAgreementEventsLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openInformationOfExecutionAgreementEventsModal }
}

const InformationOfExecutionAgreementEvents: IInformationOfExecutionAgreementEvents = () => null

InformationOfExecutionAgreementEvents.Menu = InformationOfExecutionAgreementEventsMenu
InformationOfExecutionAgreementEvents.Layout = InformationOfExecutionAgreementEventsLayout
InformationOfExecutionAgreementEvents.StatementForm =
  InformationOfExecutionAgreementEventsStatementForm
InformationOfExecutionAgreementEvents.Manager = InformationOfExecutionAgreementEventsModalManager
InformationOfExecutionAgreementEvents.MenuManager = InformationOfExecutionAgreementEventsMenuManager

export { useInformationOfExecutionAgreementEventsControl }
export default InformationOfExecutionAgreementEvents
