import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { compensationMonitoringCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/10/CompensationMonitoring/validation'
import { CertificateOnMonitoringResultsCompensationsMonitoringArrayPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/10/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldCollapseControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

interface CertificateOnMonitoringResultsCompensationMonitoringProps {
  id: string
  initialAccordionState?: boolean
  name: CertificateOnMonitoringResultsCompensationsMonitoringArrayPathName
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
}

const CertificateOnMonitoringResultsCompensationMonitoring: FC<
  CertificateOnMonitoringResultsCompensationMonitoringProps
> = ({ initialAccordionState, id, formInstance, name }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { compensationYearChecker, anchorId } = useWatch({
    control: formInstance.control,
    name,
  })

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    initialExpanded: initialAccordionState,
    watcher: CertificateOnMonitoringResultsFieldCollapseControlUpdateWatcher,
    name,
  })

  return (
    <Accordion
      id={id}
      anchorId={anchorId}
      title={`Год уплаты налогов - ${compensationYearChecker}`}
      initialAccordionState={initialAccordionState}
      isExpanded={isExpanded}
      onToggle={onToggleCollapse}
    >
      <Container>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Налог на прибыль организаций (федеральный бюджет)'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.incomeTaxFederal`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.incomeTaxFederal`,
                  inputProps: {
                    disabled: true,
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Налог на прибыль организаций (федеральный бюджет)'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.incomeTaxFederalChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.incomeTaxFederalChecker`,
                  rules:
                    compensationMonitoringCertificateOnMonitoringResultsValidationMap.incomeTaxFederalChecker,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.incomeTaxFederalChecker`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.incomeTaxFederalChecker`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Налог на прибыль организаций\n' + '(бюджет субъекта РФ)'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.incomeTaxRegional`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.incomeTaxRegional`,
                  inputProps: {
                    disabled: true,
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Налог на прибыль организаций\n' + '(бюджет субъекта РФ)'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.incomeTaxRegionalChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.incomeTaxRegionalChecker`,
                  rules:
                    compensationMonitoringCertificateOnMonitoringResultsValidationMap.incomeTaxRegionalChecker,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.incomeTaxRegionalChecker`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.incomeTaxRegionalChecker`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Налог на имущество организаций'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.propertyTax`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.propertyTax`,
                  inputProps: {
                    disabled: true,
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Налог на имущество организаций'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.propertyTaxChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.propertyTaxChecker`,
                  rules:
                    compensationMonitoringCertificateOnMonitoringResultsValidationMap.propertyTaxChecker,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.propertyTaxChecker`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.propertyTaxChecker`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Земельный налог'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.landTax`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.landTax`,
                  inputProps: {
                    disabled: true,
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Земельный налог'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.landTaxChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.landTaxChecker`,
                  rules:
                    compensationMonitoringCertificateOnMonitoringResultsValidationMap.landTaxChecker,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.landTaxChecker`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.landTaxChecker`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Налог на добавленную стоимость\n' + '(за вычетом возмещенного налога)'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.vatTax`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.vatTax`,
                  inputProps: {
                    disabled: true,
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Налог на добавленную стоимость\n' + '(за вычетом возмещенного налога)'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.vatTaxChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.vatTaxChecker`,
                  rules:
                    compensationMonitoringCertificateOnMonitoringResultsValidationMap.vatTaxChecker,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.vatTaxChecker`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.vatTaxChecker`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Ввозные таможенные пошлины'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.customDuty`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.customDuty`,
                  inputProps: {
                    disabled: true,
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Ввозные таможенные пошлины'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.customDutyChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.customDutyChecker`,
                  rules:
                    compensationMonitoringCertificateOnMonitoringResultsValidationMap.customDutyChecker,
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.customDutyChecker`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.customDutyChecker`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Общий объем уплаченных налогов'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.generalTaxValue`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.generalTaxValue`,
                  inputProps: {
                    disabled: true,
                    caption: 'рассчитывается на основе данных, введенных инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Общий объем уплаченных налогов'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.generalTaxValueChecker`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.generalTaxValueChecker`,
                  inputProps: {
                    disabled: true,
                    caption:
                      'рассчитывается на основе данных, введенных уполномоченной организацией',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
        </Stack>
      </Container>
    </Accordion>
  )
}

export default CertificateOnMonitoringResultsCompensationMonitoring
