import { useCallback } from 'react'

import { RolesTypes } from '@constants/types'
import { findOne } from '@helpers/commonHelpers'
import { useQueryManager } from '@hooks/useQueryManager'

const useExtendedFormModeByUserRoles = (userRoles: string[]) => {
  const { queryUtils } = useQueryManager()

  const currentDebugMode = !!queryUtils.getQuery('debugMode')

  const conditionToActivateDebugMode = findOne(userRoles, [RolesTypes.MER])

  const enableDebugMode = useCallback(() => {
    queryUtils.addQuery({
      query: {
        debugMode: 'true',
      },
    })
  }, [queryUtils])

  const disabledDebugMode = useCallback(() => {
    queryUtils.removeQuery({
      query: 'debugMode',
    })
  }, [queryUtils])

  const handleChangeDebugMode = useCallback(() => {
    const debugMode = queryUtils.getQuery('debugMode')
    if (!debugMode) {
      enableDebugMode()
      return
    }

    disabledDebugMode()
  }, [queryUtils, enableDebugMode, disabledDebugMode])

  return {
    currentDebugMode,
    conditionToActivateDebugMode,
    handleChangeDebugMode,
    disabledDebugMode,
    enableDebugMode,
  }
}

export { useExtendedFormModeByUserRoles }
