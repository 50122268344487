import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { StatementConsiderationInvestmentAuthorizedFormValues } from '@components/Forms/StatementConsiderationInvestmentAuthorizedForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const secondSectionStatementConsiderationInvestmentAuthorizedValidationMap: FormValuesValidationSection<
  keyof StatementConsiderationInvestmentAuthorizedFormValues['2'],
  StatementConsiderationInvestmentAuthorizedFormValues
> = {
  ruleLinkInvestProject: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  lawLinkInvestProject: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { secondSectionStatementConsiderationInvestmentAuthorizedValidationMap }
