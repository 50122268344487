import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useTransformToListIds } from '@components/DocumentFormComponents/hooks/useTransformToListIds'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import StepCompensation from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/6/Step/StepCompensation'
import { stepValidationMap } from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/6/Step/validation'
import {
  StepArrayPathName,
  StepCompensationsPathName,
} from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/6/types'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import {
  AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher,
  AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
} from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { Flipper } from '@components/ReactFlipToolkit'

interface StepProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formName: StepArrayPathName
  formInstance: UseFormReturn<AAChangingVolumeReimbursementFormValues>
  subscribableControl: SubscribableFormControlProps<AAChangingVolumeReimbursementFormValues>
}

const Step: FC<StepProps> = ({
  editMode,
  blockViewIsValidating,
  formName,
  formInstance,
  subscribableControl,
}) => {
  const {
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleChangeValue,
      debouncedHandleChangeValue,
    },
  } = useAAChangingVolumeReimbursementManager()

  const { fields: stepCompensations } = useFieldArraySubscribableControl<
    AAChangingVolumeReimbursementFormValues,
    StepCompensationsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${formName}.stepCompensations`,
    keyName: 'keyNameId',
    watcher: AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher,
  })

  const { listIds: stepCompensationIds } = useTransformToListIds(stepCompensations)

  const stepCompensationsRenderCondition = !!useWatch({
    name: `${formName}.stepCompensationValue`,
    control: formInstance.control,
  })

  const handleAddStepCompensation = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.stepCompensations`)
  }

  const handleRemoveStepCompensation = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${formName}.stepCompensations.${index}`,
      `${formName}.stepCompensations`,
    )
  }

  const { getSubscribableControlProps, getInputProps, getSingleSelectProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
    })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Текущий объем планируемых к возмещению затрат'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.stepCompensationValueCommited`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.stepCompensationValueCommited`,
                inputProps: {
                  disabled: true,
                },
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Измененное значение объема планируемых к возмещению затрат'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.stepCompensationValue`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.stepCompensationValue`,
                rules: stepValidationMap.stepCompensationValue,
                onBlur: () =>
                  setTimeout(() => handleChangeValue?.(`${formName}.stepCompensationValue`), 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.stepCompensationValue`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <CollapseWrapper
          isExpanded={stepCompensationsRenderCondition}
          defaultExpanded={stepCompensationsRenderCondition}
        >
          <Group disableBottomBorder title={'Форма возмещения'}>
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.stepCompensationFormatCommited`,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: `${formName}.stepCompensationFormatCommited`,
                        inputProps: {
                          disabled: true,
                          label: 'Текущее значение',
                        },
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.stepCompensationFormat`,
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.name || '',
                              value: item.id,
                            }),
                            controllerProps: {
                              name: `${formName}.stepCompensationFormat`,
                            },
                            selectProps: {
                              inputProps: {
                                label: 'Измененное значение',
                              },
                              onChangeFormValue: () => {
                                setTimeout(() => {
                                  handleChangeValue?.(`${formName}.stepCompensationFormat`)
                                }, 0)
                              },
                            },
                          })}
                        />
                      )
                    }}
                  </SubscribableControl>
                </Col>
              </Row>
            </Stack>
          </Group>
          <Group disableBottomBorder title={'Виды затрат, планируемых к возмещению'}>
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={12}>
                  <Stack>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.stepCompensations`,
                      })}
                    >
                      <Flipper flipKey={stepCompensationIds.join('')}>
                        <Stack direction={'vertical'} gap={3}>
                          {stepCompensations.map((compensation, index) => {
                            const compensationFormName =
                              `${formName}.stepCompensations.${index}` as const

                            return (
                              <StepCompensation
                                id={compensation.id}
                                key={compensation.id}
                                editMode={editMode}
                                formName={compensationFormName}
                                formInstance={formInstance}
                                subscribableControl={subscribableControl}
                                onRemoveStep={handleRemoveStepCompensation(index)}
                              />
                            )
                          })}
                        </Stack>
                      </Flipper>
                    </SubscribableControl>
                  </Stack>
                </Col>
              </Row>
              {editMode && (
                <Row className={'px-1'}>
                  <Col xs={12}>
                    <AsyncWrapper promise={handleAddStepCompensation}>
                      {({ isLoading, wrappedPromise }) => (
                        <FieldView.ActionButton
                          disableWrapperPaddingLeft
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          view={'plain'}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Добавить
                        </FieldView.ActionButton>
                      )}
                    </AsyncWrapper>
                  </Col>
                </Row>
              )}
            </Stack>
          </Group>
        </CollapseWrapper>
      </Stack>
    </Container>
  )
}

export default Step
