import styles from '@components/Forms/LoginForm/LoginByCrypt/ProcurationForm/ProcurationForm.module.scss'
import { isString } from '@helpers/checkTypes'

const ENV_EJECT_REGXP = /\${([^}]+)}/
const SYMBOL_TO_CLOSE_VALUE = '}'

const fromEmailStringToEmailJSX = (text: string, replacedValues: Record<string, unknown>) => {
  const matches = text.match(ENV_EJECT_REGXP)
  if (!matches) return text

  const [entry, valueEntry] = matches
  const linkToInsert = replacedValues[valueEntry]

  if (!isString(linkToInsert)) return text

  const indexToFirstReplaceSymbol = matches['index']
  const indexToLastReplaceSymbol = text.indexOf(SYMBOL_TO_CLOSE_VALUE)

  const textBeforeReplace = text.slice(0, indexToFirstReplaceSymbol)
  const textAfterReplace = text.slice(indexToLastReplaceSymbol + 1)

  return (
    <>
      {textBeforeReplace}
      <a className={styles.merLink} href={`mailto:${linkToInsert}`}>
        {linkToInsert}
      </a>
      {textAfterReplace}
    </>
  )
}

export { fromEmailStringToEmailJSX }
