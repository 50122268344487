import React, { FC, useCallback, useEffect } from 'react'

import IconButton from '@components/NewDesign/IconButton'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import DownloadDocumentTooltipContent from '@containers/DownloadDocumentTooltipContent'
import { Transition } from '@headlessui/react'
import { formatBytes } from '@helpers/fileHelpers'
import { useHover } from '@hooks/new/events/useHover'
import DownloadAttachmentIcon from '@icons/attachment/actions/DownloadAttachmentIcon.svg'
import { DownloadFileParams } from '@interfaces/params'
import { IDocumentReportEntity } from '@services/Monitoring/monitoring.entity'

import styles from './DocumentView.module.scss'

interface DocumentViewProps {
  document: IDocumentReportEntity

  onDownloadDocument: (withSigned: boolean) => void
  ownerOrganizationName?: string
}

const DocumentView: FC<DocumentViewProps> = ({
  document,
  ownerOrganizationName,
  onDownloadDocument,
}) => {
  const extensionAndSizeOfAttachment = `${(document.documentExt || '').toUpperCase()} · ${
    document.documentSize && formatBytes(+document.documentSize)
  }`

  const {
    state: { tooltipIsOpen, targetTooltipRef },
    handlers: { handleOpenTooltip, handleCloseTooltip },
    bindHandlersRef: { bindRefOfClickableOutsideZone },
  } = useManualTooltipControl()

  const [hoverDocumentRef, isDocumentHovered] = useHover<HTMLDivElement>()

  const handleDownloadFile = useCallback(
    ({ withSigned }: DownloadFileParams) =>
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        onDownloadDocument(withSigned)

        handleCloseTooltip()
      },

    [onDownloadDocument],
  )

  useEffect(() => {
    bindRefOfClickableOutsideZone(window.document.body)
  }, [bindRefOfClickableOutsideZone])

  return (
    <div className={styles.documentView} ref={hoverDocumentRef}>
      <Typography.Body variant="bodyMMedium" className={styles.documentView__name}>
        {`${document.documentName}`}
      </Typography.Body>

      <Typography.Body
        className={styles.documentView__ownerOrganizationName}
        color={'text-base-tertiary'}
        variant={'bodySMedium'}
      >
        {ownerOrganizationName}
      </Typography.Body>

      <Typography.Body
        className={styles.documentView__meta}
        color={'text-base-tertiary'}
        variant={'bodySMedium'}
      >
        {extensionAndSizeOfAttachment}
      </Typography.Body>

      <div className={styles.documentView__actionsWrapper}>
        <Transition
          show={isDocumentHovered || tooltipIsOpen}
          enter={styles.transition__enter}
          enterFrom={styles.transition__enterFrom}
          enterTo={styles.transition__enterTo}
          leave={styles.transition__leave}
          leaveFrom={styles.transition__leaveFrom}
          leaveTo={styles.transition__leaveTo}
        >
          <div
            role={'presentation'}
            className={styles.documentView__actions}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <Tooltip
              popoverClassName={styles.tooltip__popover}
              contentClassName={styles.tooltip__content}
              arrowClassName={styles.tooltip__arrow}
              targetRef={targetTooltipRef}
              open={tooltipIsOpen}
              position={'bottom-start'}
              content={
                <DownloadDocumentTooltipContent
                  handleDownloadFile={handleDownloadFile({ withSigned: false })}
                  handleDownloadFileWithStamps={handleDownloadFile({ withSigned: true })}
                />
              }
            >
              <IconButton
                geometry={'round'}
                size={'s'}
                view={'plain'}
                icon={{
                  src: DownloadAttachmentIcon,
                }}
                onClick={handleOpenTooltip}
              />
            </Tooltip>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default DocumentView
