import { FormModifierAdapters } from '@components/DocumentFormComponents/adapters/Modifier.adapter'
import {
  FormObjectStates,
  FormObjectStatesWithChanges,
} from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAAnotherStageModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObjectModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermissionModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObjectModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObjectModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResultModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStageModifier,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { isArray } from '@helpers/checkTypes'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

const { getModifierStatesFormPropertyState, comparePropertyValueWithCommitedValue, mergeChanges } =
  FormModifierAdapters

const generateFormModifierValuesForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues => {
  const preparedStages =
    ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageModifier[] => {
      if (
        !isArray(objectFromPropertyState.steps?.value) ||
        !objectFromPropertyState.steps?.value?.length
      )
        return []

      return objectFromPropertyState.steps?.value.map(
        (step): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageModifier => {
          const preparedStepCompensations = ((): FormObjectStatesWithChanges[] => {
            if (
              !isArray(step.value.stepCompensations?.value) ||
              !step.value.stepCompensations?.value?.length
            )
              return []

            return step.value.stepCompensations?.value.map(
              (stepCompensation): FormObjectStatesWithChanges => {
                const stepCompensationState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(stepCompensation)
                const isChangedStepCompensationFields = !stepCompensationState?.isNew
                  ? [
                      stepCompensation.value.stepCompensationType,
                      stepCompensation.value?.stepCompensationPeriod,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...stepCompensationState,
                  isChanged: isChangedStepCompensationFields,
                }
              },
            )
          })()

          const preparedOldStepExpenses = ((): FormObjectStatesWithChanges[] => {
            if (
              !isArray(objectFromPropertyState.oldStepExpenses?.value) ||
              !objectFromPropertyState.oldStepExpenses?.value?.length
            )
              return []

            return objectFromPropertyState.oldStepExpenses?.value
              .filter(
                (oldStepExpense) =>
                  oldStepExpense.value?.oldStepExpenses?.value?.id === step.permanentId,
              )
              .map((oldStepExpense): FormObjectStatesWithChanges => {
                const oldStepExpenseState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(oldStepExpense)

                return {
                  ...oldStepExpenseState,
                  isChanged: !oldStepExpenseState.isNew
                    ? [
                        oldStepExpense.value.oldStepExpenseName,
                        oldStepExpense.value?.oldStepExpenseValue,
                      ].some(comparePropertyValueWithCommitedValue)
                    : false,
                }
              })
          })()

          const preparedConstructionObjects =
            ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObjectModifier[] => {
              const objects =
                step.value.objects?.value.filter((object) => {
                  return (
                    object.value.isMainObject?.value &&
                    !object.value.isSubObject?.value &&
                    !object.value.objectOtherActivityName?.value?.id
                  )
                }) || []

              const subObjects =
                step.value.objects?.value.filter((object) => {
                  return (
                    object.value.isSubObject?.value &&
                    !object.value.objectOtherActivityName?.value?.id &&
                    object.value.parentSubObject?.value
                  )
                }) || []

              return (
                objects.map(
                  (
                    object,
                  ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObjectModifier => {
                    const preparedSubObjects: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObjectModifier[] =
                      subObjects
                        .filter(
                          (subObject) =>
                            object.permanentId === subObject.value.parentSubObject?.value?.id,
                        )
                        .map(
                          (
                            subObject,
                          ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObjectModifier => {
                            const preparedObjectRegions: FormObjectStatesWithChanges[] =
                              isArray(subObject.value?.objectRegions?.value) &&
                              subObject.value?.objectRegions?.value?.length
                                ? subObject.value?.objectRegions?.value.map(
                                    (objectRegion): FormObjectStatesWithChanges => {
                                      const objectRegionState =
                                        getModifierStatesFormPropertyState<
                                          typeof PropertyTypeEnum.MAP
                                        >(objectRegion)
                                      return {
                                        ...objectRegionState,
                                        isChanged: !objectRegionState.isNew
                                          ? comparePropertyValueWithCommitedValue(
                                              objectRegion?.value?.objectRegionCode,
                                            )
                                          : false,
                                      }
                                    },
                                  )
                                : []

                            const objectRegionsState = getModifierStatesFormPropertyState<
                              typeof PropertyTypeEnum.LIST
                            >(subObject.value?.objectRegions)

                            const subObjectState =
                              getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                subObject,
                              )

                            const isChangedObjectRegions = mergeChanges(
                              preparedObjectRegions,
                              objectRegionsState,
                            )

                            const isChangedSubObjectFields = !subObjectState?.isNew
                              ? [
                                  subObject.value.objectName,
                                  subObject.value?.objectConstructionKind,
                                  subObject.value?.objectPurpose,
                                  subObject.value?.objectConstructionSquare,
                                  subObject.value?.objectSquare,
                                  subObject.value?.objectNonLivingAreaSquare,
                                  subObject.value?.objectLivingAreaSquare,
                                  subObject.value?.objectFloor,
                                  subObject.value?.objectUndergroundFloor,
                                  subObject.value?.objectHeight,
                                  subObject.value?.objectDetails,
                                  subObject.value?.isInfrastructure,
                                  subObject.value?.infrastructureType,
                                  subObject.value?.oldIsCapitalObject,
                                  subObject.value?.oldCreatedDocument,
                                  subObject.value?.oldCreatedOrganization,
                                ].some(comparePropertyValueWithCommitedValue)
                              : false

                            const isChanged = isChangedSubObjectFields || isChangedObjectRegions

                            return {
                              ...subObjectState,
                              isChanged,
                              objectRegions: preparedObjectRegions,
                              objectRegionsState,
                            }
                          },
                        )

                    const preparedObjectAreas: FormObjectStatesWithChanges[] =
                      isArray(object.value?.objectAreas?.value) &&
                      object.value?.objectAreas?.value?.length
                        ? object.value?.objectAreas?.value.map(
                            (objectArea): FormObjectStatesWithChanges => {
                              const objectAreaState =
                                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                  objectArea,
                                )

                              const isChangedObjectAreaFields = !objectAreaState.isNew
                                ? [
                                    objectArea.value.objectAreaNumber,
                                    objectArea.value.objectAreaSquare,
                                  ].some(comparePropertyValueWithCommitedValue)
                                : false

                              return {
                                ...objectAreaState,
                                isChanged: isChangedObjectAreaFields,
                              }
                            },
                          )
                        : []

                    const preparedObjectRegions: FormObjectStatesWithChanges[] =
                      isArray(object.value?.objectRegions?.value) &&
                      object.value?.objectRegions?.value?.length
                        ? object.value?.objectRegions?.value.map(
                            (objectRegion): FormObjectStatesWithChanges => {
                              const objectRegionState =
                                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                  objectRegion,
                                )

                              return {
                                ...objectRegionState,
                                isChanged: !objectRegionState.isNew
                                  ? comparePropertyValueWithCommitedValue(
                                      objectRegion?.value?.objectRegionCode,
                                    )
                                  : false,
                              }
                            },
                          )
                        : []

                    const objectState =
                      getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(object)

                    const objectAreasState = getModifierStatesFormPropertyState<
                      typeof PropertyTypeEnum.LIST
                    >(object.value?.objectAreas)

                    const objectRegionsState = getModifierStatesFormPropertyState<
                      typeof PropertyTypeEnum.LIST
                    >(object.value?.objectRegions)

                    const subObjectsState =
                      getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(subObjects)

                    const isChangedObjectAreas = mergeChanges(preparedObjectAreas, objectAreasState)

                    const isChangedObjectRegions = mergeChanges(
                      preparedObjectRegions,
                      objectRegionsState,
                    )

                    const isChangedSubObjects = mergeChanges(preparedSubObjects, subObjectsState)

                    const isChangedObjectFields = !objectState.isNew
                      ? [
                          object.value.objectName,
                          object.value?.objectConstructionKind,
                          object.value?.objectPurpose,
                          object.value?.objectConstructionSquare,
                          object.value?.objectSquare,
                          object.value?.objectNonLivingAreaSquare,
                          object.value?.objectLivingAreaSquare,
                          object.value?.objectFloor,
                          object.value?.objectUndergroundFloor,
                          object.value?.objectHeight,
                          object.value?.objectDetails,
                          object.value?.isInfrastructure,
                          object.value?.infrastructureType,
                          object.value?.oldIsCapitalObject,
                          object.value?.oldCreatedDocument,
                          object.value?.oldCreatedOrganization,
                        ].some(comparePropertyValueWithCommitedValue)
                      : false

                    const isChanged =
                      isChangedObjectFields ||
                      isChangedObjectAreas ||
                      isChangedObjectRegions ||
                      isChangedSubObjects

                    return {
                      ...objectState,
                      isChanged,
                      objectAreas: preparedObjectAreas,
                      objectAreasState,
                      objectRegions: preparedObjectRegions,
                      objectRegionsState,
                      subObjects: preparedSubObjects,
                      subObjectsState,
                    }
                  },
                ) || []
              )
            })()

          const preparedPermissions =
            ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermissionModifier[] => {
              if (!isArray(step.value.permissions?.value) || !step.value.permissions?.value.length)
                return []

              return step.value.permissions?.value.map(
                (
                  permission,
                ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermissionModifier => {
                  const preparedObjectNamePermissions: FormObjectStates[] = (() => {
                    if (
                      !isArray(permission.value.permissionObjects?.value) ||
                      !permission.value.permissionObjects?.value.length
                    )
                      return []

                    return permission.value.permissionObjects?.value.map(
                      (permissionObject): FormObjectStates => ({
                        id: permissionObject.propertyId,
                        isNew: !!permissionObject?.new,
                      }),
                    )
                  })()

                  const permissionState =
                    getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(permission)

                  const objectNamePermissionsState = getModifierStatesFormPropertyState<
                    typeof PropertyTypeEnum.LIST
                  >(permission.value.permissionObjects)

                  const isChangedObjectNamePermissions =
                    objectNamePermissionsState.deletedElementFromList ||
                    objectNamePermissionsState.addedElementInList

                  const isChangedPermissionFields = !permissionState?.isNew
                    ? [
                        permission.value.permissionKind,
                        permission.value.permissionOrganization,
                        permission.value.isReceivedPermission,
                        permission.value.permissionDate,
                        permission.value.permissionNumber,
                        permission.value.receivedPermissionDate,
                      ].some(comparePropertyValueWithCommitedValue) ||
                      isChangedObjectNamePermissions
                    : false

                  return {
                    ...permissionState,
                    isChanged: isChangedPermissionFields,
                    objectNamePermissions: preparedObjectNamePermissions,
                    objectNamePermissionsState,
                  }
                },
              )
            })()

          const preparedExploitationObjects: FormObjectStatesWithChanges[] = (() => {
            if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
              return []

            return step.value.commissionObjects?.value.map(
              (exploitation): FormObjectStatesWithChanges => {
                const exploitationState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(exploitation)

                const isChangedExploitationFields = !exploitationState?.isNew
                  ? [
                      exploitation.value?.commissionObjectId,
                      exploitation.value?.commissionObjectDate,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...exploitationState,
                  isChanged: isChangedExploitationFields,
                }
              },
            )
          })()

          const preparedRegistrationObjects: FormObjectStatesWithChanges[] = (() => {
            if (
              !step.value.registrationObjects?.value ||
              !step.value.registrationObjects?.value.length
            )
              return []

            return step.value.registrationObjects?.value.map(
              (registration): FormObjectStatesWithChanges => {
                const registrationState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(registration)

                const isChangedRegistrationFields = !registrationState?.isNew
                  ? [
                      registration.value?.stateRegistrationType,
                      registration.value?.objectStateRegistrationDate,
                      registration.value?.objectStateRegistrationNumber,
                      registration.value?.objectStateRegistrationOrganization,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...registrationState,
                  isChanged: isChangedRegistrationFields,
                }
              },
            )
          })()

          const preparedRids = ((): FormObjectStatesWithChanges[] => {
            if (!isArray(step.value.rids?.value) || !step.value.rids?.value.length) return []

            return step.value.rids?.value.map((rid): FormObjectStatesWithChanges => {
              const ridState = getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(rid)

              const isChangedRidFields = !ridState?.isNew
                ? [
                    rid.value?.ridKind,
                    rid.value?.ridName,
                    rid.value?.ridAddress,
                    rid.value?.ridOwner,
                    rid.value?.ridDetails,
                  ].some(comparePropertyValueWithCommitedValue)
                : false

              return {
                ...ridState,
                isChanged: isChangedRidFields,
              }
            })
          })()

          const preparedExploitationRids = ((): FormObjectStatesWithChanges[] => {
            if (!step.value.commissionRids?.value || !step.value.commissionRids?.value.length)
              return []

            return step.value.commissionRids?.value.map(
              (exploitation): FormObjectStatesWithChanges => {
                const exploitationState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(exploitation)

                const isChangedRidFields = !exploitationState?.isNew
                  ? [
                      exploitation.value?.ridCommissioningDate,
                      exploitation.value?.ridCommissionNumber,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...exploitationState,
                  isChanged: isChangedRidFields,
                }
              },
            )
          })()

          const preparedRidNames = (() => {
            if (
              !step.value?.ridStateRegistrationIds?.value ||
              !step.value.ridStateRegistrationIds?.value.length
            )
              return []

            return step.value.ridStateRegistrationIds?.value.map((rid) => ({
              id: rid.propertyId,
              isNew: !!rid?.new,
            }))
          })()

          const preparedRegistrationRids = (() => {
            if (!step.value.registrationRids?.value || !step.value.registrationRids?.value.length)
              return []

            return step.value.registrationRids?.value.map(
              (registration): FormObjectStatesWithChanges => {
                const registrationState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(registration)

                const isChangedRegistrationFields = !registrationState?.isNew
                  ? [
                      registration.value?.ridStateRegistrationNumber,
                      registration.value?.ridStateRegistrationOrganization,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...registrationState,
                  isChanged: isChangedRegistrationFields,
                }
              },
            )
          })()

          const stepCompensationsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.stepCompensations)

          const stepActivityNamesState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.stepActivityNames)

          const constructionObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.objects)

          const permissionsObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.permissions)

          const exploitationObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.commissionObjects)

          const registrationObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.registrationObjects)

          const ridObjectsState = getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(
            step.value.rids,
          )

          const ridExploitationObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.commissionRids)

          const ridNamesState = getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(
            step.value?.ridStateRegistrationIds,
          )

          const ridRegistrationsObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.registrationRids)

          const isChangedExploitationFields = comparePropertyValueWithCommitedValue(
            step.value.objectCommissioningEndDate,
          )

          const isChangedRegistrationFields = [
            step.value.typeObjectStateRegistration,
            step.value.objectStateRegistrationEndDate,
          ].some(comparePropertyValueWithCommitedValue)

          const isChangedStepCompensation = mergeChanges(
            preparedStepCompensations,
            stepCompensationsState,
          )

          const isChangedConstructionObjects = mergeChanges(
            preparedConstructionObjects,
            constructionObjectsState,
          )

          const isChangedPermissions = mergeChanges(preparedPermissions, permissionsObjectsState)

          const isChangedExploitation =
            isChangedExploitationFields ||
            mergeChanges(preparedExploitationObjects, exploitationObjectsState)

          const isChangedRegistration =
            isChangedRegistrationFields ||
            mergeChanges(preparedRegistrationObjects, registrationObjectsState)

          const isChangedRidObjects = mergeChanges(preparedRids, ridObjectsState)

          const isChangedExploitationRidsFields = comparePropertyValueWithCommitedValue(
            step.value.ridCommissioningEndDate,
          )

          const isChangedExploitationRids =
            isChangedExploitationRidsFields ||
            mergeChanges(preparedExploitationRids, ridExploitationObjectsState)

          const isChangedRegistrationRidsFields = comparePropertyValueWithCommitedValue(
            step.value.ridStateRegistrationEndDate,
          )

          const isChangedRegistrationRids =
            isChangedRegistrationRidsFields ||
            mergeChanges(preparedRegistrationRids, ridRegistrationsObjectsState) ||
            mergeChanges(preparedRidNames, ridNamesState)

          const stepState = getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(step)

          const isChangedStepFields = [
            step.value.stepNumber,
            step.value.stepDescription,
            step.value.stepStartDate,
            step.value.stepEndDate,
            !!objectFromPropertyState.isPaper?.value
              ? step.value.stepInvestorCapitalValueVAT
              : step.value.stepInvestorCapitalValue,
            step.value.stepCompensationValue,
            step.value.stepCompensationForm,
            step.value.stepInvestorCapitalDate,
          ].some(comparePropertyValueWithCommitedValue)

          const isChanged =
            isChangedStepFields ||
            stepActivityNamesState.addedElementInList ||
            stepActivityNamesState.deletedElementFromList ||
            isChangedStepCompensation ||
            isChangedConstructionObjects ||
            isChangedPermissions ||
            isChangedExploitation ||
            isChangedRegistration ||
            isChangedRidObjects ||
            isChangedExploitationRids ||
            isChangedRegistrationRids

          const isChangedConstructionStage =
            isChangedStepCompensation ||
            isChangedConstructionObjects ||
            isChangedPermissions ||
            isChangedExploitation ||
            isChangedRegistration

          const isChangedRidStage =
            isChangedRidObjects || isChangedExploitationRids || isChangedRegistrationRids

          const checkboxes = (() => {
            const defaultState = {
              id: '',
              isNew: false,
            }

            let chosenCheckboxesStates = {
              isConstruction: defaultState,
              isReconstruction: defaultState,
              isModernization: defaultState,
              isCreateRID: defaultState,
            }

            step.value.stepActivityNames?.value.forEach((stepActivity) => {
              if (stepActivity.value?.name === 'Строительство (создание)') {
                chosenCheckboxesStates = {
                  ...chosenCheckboxesStates,
                  ['isConstruction']: {
                    id: stepActivity.value?.id,
                    isNew: !!stepActivity?.new,
                  },
                }
              }

              if (stepActivity.value?.name === 'Реконструкция') {
                chosenCheckboxesStates = {
                  ...chosenCheckboxesStates,
                  ['isReconstruction']: {
                    id: stepActivity.value?.id,
                    isNew: !!stepActivity?.new,
                  },
                }
              }

              if (stepActivity.value?.name === 'Модернизация') {
                chosenCheckboxesStates = {
                  ...chosenCheckboxesStates,
                  ['isModernization']: {
                    id: stepActivity.value?.id,
                    isNew: !!stepActivity?.new,
                  },
                }
              }

              if (stepActivity.value?.name === 'Создание РИД') {
                chosenCheckboxesStates = {
                  ...chosenCheckboxesStates,
                  ['isCreateRID']: {
                    id: stepActivity.value?.id,
                    isNew: !!stepActivity?.new,
                  },
                }
              }
            })

            return chosenCheckboxesStates
          })()

          return {
            ...stepState,
            ...checkboxes,
            isChanged: !stepState?.isNew ? isChanged : false,
            stepCompensations: preparedStepCompensations,
            stepCompensationsState,
            oldStepExpenses: preparedOldStepExpenses,
            stepActivityNames: [],
            stepActivityNamesState,
            constructionStage: {
              isChanged: !stepState?.isNew ? isChangedConstructionStage : false,
              constructionObjects: preparedConstructionObjects,
              constructionObjectsState,

              permissionsObjects: preparedPermissions,
              permissionsObjectsState,

              exploitation: {
                isChanged: !stepState?.isNew ? isChangedExploitation : false,
                exploitationObjects: preparedExploitationObjects,
                exploitationObjectsState,
              },

              registration: {
                isChanged: !stepState?.isNew ? isChangedRegistration : false,
                registrationObjects: preparedRegistrationObjects,
                registrationObjectsState,
              },
            },
            ridStage: {
              isChanged: !stepState?.isNew ? isChangedRidStage : false,
              ridObjects: preparedRids,
              ridObjectsState,

              exploitation: {
                isChanged: !stepState?.isNew ? isChangedExploitationRids : false,
                ridExploitationObjects: preparedExploitationRids,
                ridExploitationObjectsState,
              },

              registration: {
                isChanged: !stepState?.isNew ? isChangedRegistrationRids : false,
                ridNames: preparedRidNames,
                ridNamesState,
                ridRegistrationsObjects: preparedRegistrationRids,
                ridRegistrationsObjectsState,
              },
            },
          }
        },
      )
    })()

  const preparedStepActivityOthers =
    ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStageModifier[] => {
      if (
        !isArray(objectFromPropertyState.steps?.value) ||
        !objectFromPropertyState.steps?.value.length
      )
        return []

      return objectFromPropertyState.steps?.value.map(
        (step): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStageModifier => {
          return {
            id: step.propertyId,
            activityOthers: step.value.stepActivityOthers?.value.map(
              (
                stepActivity,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAAnotherStageModifier => {
                const preparedMeasureObjects: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObjectModifier[] =
                  step.value.objects?.value
                    .filter((object) => {
                      return (
                        object.value.objectOtherActivityName?.value.id === stepActivity.permanentId
                      )
                    })
                    .map(
                      (
                        object,
                      ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObjectModifier => {
                        const preparedObjectAreas: FormObjectStatesWithChanges[] =
                          isArray(object.value?.objectAreas?.value) &&
                          object.value?.objectAreas?.value?.length
                            ? object.value?.objectAreas?.value.map(
                                (objectArea): FormObjectStatesWithChanges => {
                                  const objectAreaState =
                                    getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                      objectArea,
                                    )
                                  const isChangedObjectAreaFields = !objectAreaState.isNew
                                    ? [
                                        objectArea.value.objectAreaNumber,
                                        objectArea.value.objectAreaSquare,
                                      ].some(comparePropertyValueWithCommitedValue)
                                    : false

                                  return {
                                    ...objectAreaState,
                                    isChanged: isChangedObjectAreaFields,
                                  }
                                },
                              )
                            : []

                        const preparedObjectRegions: FormObjectStatesWithChanges[] =
                          isArray(object.value?.objectRegions?.value) &&
                          object.value?.objectRegions?.value?.length
                            ? object.value?.objectRegions?.value.map(
                                (objectRegion): FormObjectStatesWithChanges => {
                                  const objectRegionState =
                                    getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                      objectRegion,
                                    )

                                  return {
                                    ...objectRegionState,
                                    isChanged: !objectRegionState.isNew
                                      ? comparePropertyValueWithCommitedValue(
                                          objectRegion?.value?.objectRegionCode,
                                        )
                                      : false,
                                  }
                                },
                              )
                            : []

                        const objectState =
                          getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(object)

                        const objectAreasState = getModifierStatesFormPropertyState<
                          typeof PropertyTypeEnum.LIST
                        >(object.value?.objectAreas)

                        const objectRegionsState = getModifierStatesFormPropertyState<
                          typeof PropertyTypeEnum.LIST
                        >(object.value?.objectRegions)

                        const isChangedObjectAreas = mergeChanges(
                          preparedObjectAreas,
                          objectAreasState,
                        )

                        const isChangedObjectRegions = mergeChanges(
                          preparedObjectRegions,
                          objectRegionsState,
                        )

                        const isChangedObjectFields = !objectState.isNew
                          ? [
                              object.value.objectName,
                              object.value?.objectConstructionKind,
                              object.value?.objectPurpose,
                              object.value?.objectConstructionSquare,
                              object.value?.objectSquare,
                              object.value?.objectNonLivingAreaSquare,
                              object.value?.objectLivingAreaSquare,
                              object.value?.objectFloor,
                              object.value?.objectUndergroundFloor,
                              object.value?.objectHeight,
                              object.value?.objectDetails,
                              object.value?.isInfrastructure,
                              object.value?.infrastructureType,
                            ].some(comparePropertyValueWithCommitedValue)
                          : false

                        const isChanged =
                          isChangedObjectFields || isChangedObjectAreas || isChangedObjectRegions

                        return {
                          ...objectState,
                          isChanged,
                          objectRegions: preparedObjectRegions,
                          objectRegionsState,
                          objectAreas: preparedObjectAreas,
                          objectAreasState,
                        }
                      },
                    )

                const measureObjectsState = getModifierStatesFormPropertyState<
                  typeof PropertyTypeEnum.LIST
                >(step.value.objects)

                const stepActivityState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(stepActivity)

                const isChangedMeasureObjects = mergeChanges(
                  preparedMeasureObjects,
                  measureObjectsState,
                )

                const isChangedStepActivityOthersFields = !stepActivityState.isNew
                  ? [
                      stepActivity.value.otherActivityName,
                      stepActivity.value.otherActivityEndDate,
                      stepActivity.value.isOtherActivityInfrastructure,
                    ].some(comparePropertyValueWithCommitedValue) || isChangedMeasureObjects
                  : false

                return {
                  ...stepActivityState,
                  isChanged: isChangedStepActivityOthersFields,
                  measureObjects: preparedMeasureObjects,
                  measureObjectsState,
                }
              },
            ),
          }
        },
      )
    })()

  const preparedProjectResults =
    ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResultModifier[] => {
      if (
        !isArray(objectFromPropertyState.projectResults?.value) ||
        !objectFromPropertyState.projectResults?.value.length
      )
        return []

      return objectFromPropertyState.projectResults?.value.map(
        (item): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResultModifier => {
          const preparedVolumeOfProduction: FormObjectStatesWithChanges[] = (() => {
            if (
              !isArray(item.value.projectResultValues?.value) ||
              !item.value.projectResultValues?.value
            )
              return []

            return item.value.projectResultValues?.value
              .sort((projectResultA, projectResultB) => projectResultA.order - projectResultB.order)
              .map((projectResult): FormObjectStatesWithChanges => {
                const projectResultState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(projectResult)

                const isChangedRegistrationFields = !projectResult?.isNew
                  ? [
                      projectResult.value?.projectResultYearValue,
                      projectResult.value?.projectResultValueUnit,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...projectResultState,
                  isChanged: isChangedRegistrationFields,
                }
              })
          })()

          const projectResultsState =
            getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(item)

          const volumeOfProductionState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(item.value.projectResultValues)

          const isChangedVolumeOfProduction = mergeChanges(
            preparedVolumeOfProduction,
            volumeOfProductionState,
          )

          const isChangedVolumeOfProductionFields = [
            item.value.projectResultName,
            item.value.isStabilizationResult,
            item.value.projectResultCodeTnVed,
          ].some(comparePropertyValueWithCommitedValue)

          const isChanged = isChangedVolumeOfProduction || isChangedVolumeOfProductionFields

          return {
            ...projectResultsState,
            isChanged: !projectResultsState?.isNew ? isChanged : false,
            volumeOfProduction: preparedVolumeOfProduction,
            volumeOfProductionState,
          }
        },
      )
    })()

  const preparedTechnologicalRequirements = ((): FormObjectStatesWithChanges[] => {
    if (
      !isArray(objectFromPropertyState.projectNpas?.value) ||
      !objectFromPropertyState.projectNpas?.value.length
    )
      return []

    return objectFromPropertyState.projectNpas.value.map((npa): FormObjectStatesWithChanges => {
      const npaState = {
        id: npa?.propertyId,
        isNew: !!npa?.new,
      }

      return {
        ...npaState,
        isChanged: !npaState.isNew ? comparePropertyValueWithCommitedValue(npa) : false,
      }
    })
  })()

  const preparedSixthFormModifierValues = (() => {
    const isChangedSixthFormModifierValues = [
      objectFromPropertyState.projectPurpose,
      objectFromPropertyState.totalCapital,
      !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.investorCapitalVAT
        : objectFromPropertyState.investorCapital,
      !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.remainsInvestorCapitalVAT
        : objectFromPropertyState.remainsInvestorCapital,
      (isArray(objectFromPropertyState.projectCompensations?.value)
        ? objectFromPropertyState.projectCompensations?.value.find(
            (item) =>
              item.value.projectCompensationType?.value?.name === 'Обеспечивающая инфраструктура',
          )?.value.projectCompensationValue
        : '') || '',
      (isArray(objectFromPropertyState.projectCompensations?.value)
        ? objectFromPropertyState.projectCompensations?.value.find(
            (item) =>
              item.value.projectCompensationType?.value?.name === 'Сопутствующая инфраструктура',
          )?.value.projectCompensationValue
        : '') || '',
    ].some(comparePropertyValueWithCommitedValue)

    return {
      isChanged: isChangedSixthFormModifierValues,
    }
  })()

  const preparedEleventhFormModifierValues = (() => {
    const isChangedEleventhFormModifierValues = [
      objectFromPropertyState.extraMunicipalAccessionLinkCompensation,
      objectFromPropertyState.extraMunicipalAccessionLinkStabilization,
      objectFromPropertyState.stabilizationPeriod,
      objectFromPropertyState.oldStabilizationPeriodTaxDuty,
      objectFromPropertyState.oldStabilizationPeriodFederalStateSupport,
      objectFromPropertyState.oldStabilizationPeriodRegionalStateSupport,
    ].some(comparePropertyValueWithCommitedValue)

    return {
      isChanged: isChangedEleventhFormModifierValues,
    }
  })()

  const projectResultsState = getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(
    objectFromPropertyState.projectResults,
  )

  const isChangedNinthForm: boolean = (() => {
    const isChangedProjectResults = mergeChanges(preparedProjectResults, projectResultsState)

    const isChangedProjectResultsFields = [
      objectFromPropertyState?.otherProjectResultActivity,
      objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityEndDate,
      objectFromPropertyState.projectResultEcoActivity?.value.ecoActyvityProgrammNameInstrumental,
      objectFromPropertyState.projectResultEcoActivity?.value?.ecoActivityName,
    ].some(comparePropertyValueWithCommitedValue)

    return isChangedProjectResults || isChangedProjectResultsFields
  })()

  const preparedTwelfthFormModifierValues = (() => {
    const isChangedTwelfthFormModifierValues = [
      objectFromPropertyState.rightDutySubjectWaterResource,
      objectFromPropertyState.rightDutySubjectForestResource,
      objectFromPropertyState.rightDutyRfWaterResource,
      objectFromPropertyState.rightDutyRfForestResource,
    ].some(comparePropertyValueWithCommitedValue)

    return {
      isChanged: isChangedTwelfthFormModifierValues,
    }
  })()

  return {
    '6': preparedSixthFormModifierValues,
    '7': {
      stages: preparedStages,
    },
    '8': {
      stepActivityOthers: preparedStepActivityOthers,
    },
    '9': {
      isChanged: isChangedNinthForm,
      projectResults: preparedProjectResults,
      projectResultsState,
    },
    '10': {
      technologicalRequirements: preparedTechnologicalRequirements,
    },
    '11': preparedEleventhFormModifierValues,
    '12': preparedTwelfthFormModifierValues,
  }
}

export { generateFormModifierValuesForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm }
