import { StatementCompensation } from '@components/Forms/CreateStatement/types'
import { compact } from '@helpers/array/compact'

const generatePrefetchedPropsForStatement = (
  objectFromPropertyState: Record<string, any>,
): string[] => {
  return compact(
    objectFromPropertyState.projectCompensations?.value.map(
      (compensation): StatementCompensation =>
        compensation.value.projectCompensationType?.propertyId,
    ),
  )
}

export { generatePrefetchedPropsForStatement }
