import React, { createContext, useEffect, useState } from 'react'
import { Routes } from 'react-router'
import { Route } from 'react-router-dom'

import ScrollToTop from '@components/ScrollToTop'
import WithUserSupport from '@components/UserSupport/WithUserSupport'
import { Paths } from '@constants/paths'
import StatementRedirectRouter from '@helpers/url/StatementsRedirectRouter'
import HtmlRouteLayout from '@layouts/HtmlLayout'
import ActsPage from '@routes/Acts'
import Approve from '@routes/Approv'
import Audits from '@routes/Audits/Audits'
import AuthorizedListPage from '@routes/AuthorizedList'
import AuthorizedOrganizationsPage from '@routes/AuthorizedOrganizations'
import HomePage from '@routes/Home'
import InitializeGlobalState from '@routes/InitializeEntities/GlobalState'
import InitializeLC from '@routes/InitializeEntities/LC'
import KnowledgeBasePage from '@routes/KnoledgeBase'
import LoginPage from '@routes/LoginPage'
import NotFound from '@routes/NotFound'
import OrganizationNewPage from '@routes/OrganizationNew/OrganizationNew'
import Organizations from '@routes/Organizations'
import PoliticsPage from '@routes/Politics'
import ProjectPage from '@routes/Projects/[id]/index'
import ProjectsRoleManager from '@routes/ProjectsRoleManager'
import DictionaryNamePage from '@routes/ReferenceBooks/[dictionaryName]'
import RegistrationPage from '@routes/RegistrationPage'
import RegistryNpaPage from '@routes/RegistryNpa'
import ReportsPage from '@routes/Reports'
import ReportPage from '@routes/Reports/[id]'
import StatementSchemePage from '@routes/StatementScheme'
import SystemVariablesPage from '@routes/SystemVariablesPage'
import Users from '@routes/Users/Users'

//Глобальные вещи, которые не должны изменяться после маунта.
export const AppContext = createContext(false)

const App = () => {
  const [isAppMounted, setAppMounted] = useState(false)

  //Первый маунт приложения
  useEffect(() => {
    setAppMounted(true)
  }, [])

  return (
    <Routes>
      <Route element={<InitializeGlobalState.NotAuthenticated />}>
        <Route element={<HtmlRouteLayout reversed />}>
          <Route path={Paths.Login} element={<LoginPage />} />
          <Route path={Paths.Registration} element={<RegistrationPage />} />
          <Route path={Paths.Organizations} element={<Organizations />} />
          <Route element={<InitializeLC />}>
            <Route
              element={
                <AppContext.Provider value={isAppMounted}>
                  <InitializeGlobalState.Authenticated />
                </AppContext.Provider>
              }
            >
              <Route path={Paths.EmailApproving} element={<Approve />} />
              <Route element={<WithUserSupport />}>
                <Route path={Paths.Projects}>
                  <Route index element={<ProjectsRoleManager />} />
                  <Route path={':projectId/*'} element={<ProjectPage />} />
                </Route>
                <Route path={`${Paths.Statements}/*`} element={<StatementRedirectRouter />} />
                <Route path={Paths.Reports} element={<ReportsPage />}>
                  <Route path={':projectId/*'} element={<ReportPage />} />
                </Route>
                <Route path={Paths.Organization} element={<OrganizationNewPage />} />
                <Route path={Paths.Users} element={<Users />} />
                <Route path={Paths.Audits} element={<Audits />} />
                <Route path={Paths.SystemVariablesPage} element={<SystemVariablesPage />} />
                <Route path={Paths.RegistryNpa} element={<RegistryNpaPage />} />

                <Route
                  path={Paths.AuthorizedOrganizations}
                  element={<AuthorizedOrganizationsPage />}
                />

                <Route path={Paths.ReferenceBooks}>
                  <Route index element={<NotFound />} />
                  <Route path={':dictionaryName'} element={<DictionaryNamePage />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route
          element={
            <ScrollToTop>
              <HtmlRouteLayout />
            </ScrollToTop>
          }
        >
          <Route path={Paths.Home} element={<HomePage />}>
            <Route path={Paths.Acts} element={<ActsPage />} />
            <Route path={Paths.AuthorizedList} element={<AuthorizedListPage />} />
            <Route path={Paths.Politics} element={<PoliticsPage />} />
          </Route>

          <Route path={Paths.KnowledgeBase} element={<KnowledgeBasePage />} />
          <Route path={Paths.SchemeSZPK} element={<StatementSchemePage />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default App
