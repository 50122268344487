import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
  MutableRefObject,
  useCallback,
} from 'react'
import { useFieldArray } from 'react-hook-form'

import { npaBlockValues } from '@components/DocumentFormComponents/NpaFormAddon/const'
import DirectionItem, {
  DirectionItemProps,
} from '@components/DocumentFormComponents/NpaFormAddon/NpaThree/DirectionItem'
import { BaseNpaFormValues } from '@components/DocumentFormComponents/NpaFormAddon/types'
import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import cn from 'classnames'

import styles from './NpaThree.module.scss'

interface IDirectionStateViewRef {
  [key: string]: boolean
}

interface NpaThreeProps<T extends BaseNpaFormValues>
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  blockViewIsValidating: boolean
  stateOfDirections: MutableRefObject<IDirectionStateViewRef | null>
  formInstance: DirectionItemProps<T>['formInstance']
  onBindCardRef: DirectionItemProps<T>['onBindCardRef']
  onScrollToCardView: DirectionItemProps<T>['onScrollToCardView']
  onRemoveNpaPart?: DirectionItemProps<T>['onRemoveNpaPart']
  onUpdateNpaPart?: DirectionItemProps<T>['onUpdateNpaPart']
}

const NpaThree = <T extends BaseNpaFormValues>({
  blockViewIsValidating,
  formInstance,
  onBindCardRef,
  stateOfDirections,
  onScrollToCardView,
  onUpdateNpaPart,
  onRemoveNpaPart,
  ...divProps
}: NpaThreeProps<T>) => {
  const { fields: directions } = useFieldArray({
    name: npaBlockValues.npaStructure,
    control: formInstance.control,
    keyName: 'structureId',
  })

  const { mapOfNpaDirections } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const handleDirectionStateChange = useCallback(
    (direction: string) => (value: boolean) => {
      stateOfDirections.current = {
        ...stateOfDirections.current,
        [direction]: value,
      }
    },
    [stateOfDirections],
  )

  if (!directions.length) return null

  return (
    <div {...divProps} className={cn(styles.npaThree, divProps.className)}>
      {directions.map((direction, directionIndex) => (
        <DirectionItem
          key={directionIndex}
          direction={mapOfNpaDirections?.[direction.direction] || ''}
          directionIndex={directionIndex}
          blockViewIsValidating={blockViewIsValidating}
          isDirectionOpen={!!stateOfDirections.current?.[direction.direction]}
          isDirectionNeedEdit={direction.isChangesNpaRegistry}
          formInstance={formInstance}
          onDirectionChange={handleDirectionStateChange(direction.direction)}
          onBindCardRef={onBindCardRef}
          onScrollToCardView={onScrollToCardView}
          onUpdateNpaPart={onUpdateNpaPart}
          onRemoveNpaPart={onRemoveNpaPart}
        />
      ))}
    </div>
  )
}

export default memo(NpaThree)
