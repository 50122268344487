import {
  StatementOldFormValues,
  TStatementRules,
} from '@components/Forms/CreateStatementOld/types'
import {
  amountValidate,
  defaultRHFValidation,
  lengthValidate,
  Patterns,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const investorGeneralInfoFieldsValidation: Record<
  keyof StatementOldFormValues['investorGeneralInfo'],
  TStatementRules
> = {
  fullName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  okved: {
    required: defaultRHFValidation.required,
  },
  name: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  inn: {
    required: defaultRHFValidation.required,
    pattern: {
      value: Patterns.Numbers && /^(\d{10})$/,
      message: 'ИНН должен состоять из 10 цифр',
    },
  },
  ogrn: {
    required: defaultRHFValidation.required,
    pattern: {
      value: Patterns.Numbers && /^(\d{13})$/,
      message: 'ОГРН должен состоять из 13 цифр',
    },
  },
  kpp: {
    required: defaultRHFValidation.required,
    pattern: {
      value: Patterns.Numbers && /^(\d{9})$/,
      message: 'КПП должен состоять из 9 цифр',
    },
  },
  head: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  headGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  position: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  positionGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  reason: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  reasonGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  okpo: {
    required: defaultRHFValidation.required,
    pattern: {
      value: /^(\d{8}|\d{10})$/,
      message: 'ОКПО должен состоять из 8 или 10 цифр',
    },
  },
  capital: {
    required: defaultRHFValidation.required,
    pattern: defaultRHFValidation.capital,
    validate: (value) => {
      if (!isString(value)) return

      return amountValidate(value)
    },
  },
  isVed: {},
  isProjectCompany: {},
}

export { investorGeneralInfoFieldsValidation }
