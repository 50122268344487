import { THUMBPRINT } from '@components/Table/constants'

export interface IJwtDecode {
  id: string
  acr: string
  'allowed-origins': any[]
  azp: string
  email: string
  email_verified: boolean
  exp: number
  family_name: string
  given_name: string
  iat: number
  inn: string
  iss: string
  jti: string
  name: string
  organizationId: string
  position: string
  preferred_username: string
  realm_access: { roles: string[] }
  scope: string
  session_state: string
  sid: string
  sub: string
  thumberprint: string
  resource_access: any
  typ: string
}

export type IJwtDecodeKeys = keyof IJwtDecode

export const StorageFields = {
  authState: 'auth_state',
  userInfo: 'user_info',
  clientId: 'client_id',
  inn: 'inn',
  userUuid: 'user_uuid',
  thumbprint: THUMBPRINT,
} as const

export const SessionStorageFields = {
  lastAuthedUser: 'lastAuthedUser',
} as const
