import React, { FC, memo } from 'react'

import WebFormDocumentController from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController'
import { IDocumentItem } from '@interfaces/documents'
import { IDocumentDictInfo } from '@services/Dictionaries/dictionaries.entity'
import { WebFormInfo } from '@services/Documents/documents.entity'

interface WebFormWithDocumentControllerProps {
  disabled: boolean
  projectId: string
  currentDfoId: string
  documentSetId: string
  documentInfoFromDict: IDocumentDictInfo
  documents: IDocumentItem[]
  formInfo?: WebFormInfo
  permissions: number
  onOpenForm?: (editMode?: boolean) => VoidFunction
  digitizingInProcess?: boolean
}

const WebFormWithDocumentController: FC<WebFormWithDocumentControllerProps> = ({
  disabled,
  projectId,
  currentDfoId,
  documentSetId,
  documents,
  permissions,
  formInfo,
  documentInfoFromDict,
  onOpenForm,
  digitizingInProcess,
}) => {
  return (
    <>
      {documents.map((document) => (
        <WebFormDocumentController
          key={document.id}
          document={document}
          disabled={disabled}
          projectId={projectId}
          dfoId={currentDfoId}
          documentSetId={documentSetId}
          permissions={permissions}
          formInfo={formInfo}
          documentInfoFromDict={documentInfoFromDict}
          digitizingInProcess={digitizingInProcess}
          onOpenForm={onOpenForm}
        />
      ))}
    </>
  )
}

export default memo(WebFormWithDocumentController)
