import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'
import Sixth from './6'
import Seventh from './7'
import Eighth from './8'
import Ninth from './9'
import Tenth from './10'
import Eleventh from './11'
import Twelfth from './12'

interface AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
  Sixth: typeof Sixth
  Seventh: typeof Seventh
  Eighth: typeof Eighth
  Ninth: typeof Ninth
  Tenth: typeof Tenth
  Eleventh: typeof Eleventh
  Twelfth: typeof Twelfth
}

const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormsProps =
  () => null

AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.First = First
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Second = Second
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Third = Third
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Fourth = Fourth
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Fifth = Fifth
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Sixth = Sixth
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Seventh = Seventh
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Eighth = Eighth
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Ninth = Ninth
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Tenth = Tenth
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Eleventh = Eleventh
AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Twelfth = Twelfth

export default AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms
