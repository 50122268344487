import React, { SyntheticEvent } from 'react'
import { useFormContext } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/Stages.module.scss'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import StepActivityOtherBlock from '@components/Forms/ProjectSZPKForm/Forms/8/AdditionalForms/StepActivityOtherBlock'
import { EighthAnotherStagesBlockName } from '@components/Forms/ProjectSZPKForm/Forms/8/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldArrayControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Stack from '@components/ReactBootstrap/Stack'
import cn from 'classnames'

const Eight = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const { handleChangeCurrentMenuItemId } = useNestedMenuHandlersManager()

  const { fields: stepActivityOtherBlocks } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    EighthAnotherStagesBlockName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: projectSZPKBlockValues['8'].stepActivityOthers,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const handleMoveToSixSection = (e: SyntheticEvent) => {
    e.preventDefault()

    const sectionToMove = '3.1'

    handleChangeCurrentMenuItemId?.(sectionToMove)
  }

  if (!stepActivityOtherBlocks.length)
    return (
      <Col xs={12} className={styles.stages__placeholder}>
        <Typography.Body variant={'bodyMRegular'}>
          Укажите планируемые этапы в разделе
          <button
            type="button"
            className={cn(
              styles['stages__placeholder-text'],
              styles['stages__placeholder-text--brand'],
            )}
            onClick={handleMoveToSixSection}
          >
            &nbsp;«Этапы реализации проекта»&nbsp;
          </button>
          для того, чтобы добавить мероприятия
        </Typography.Body>
      </Col>
    )

  return (
    <Stack direction={'vertical'} gap={3}>
      {stepActivityOtherBlocks.map((block, index) => (
        <StepActivityOtherBlock
          key={block.id}
          isShowButton={index === stepActivityOtherBlocks.length - 1}
          indexOfStep={index}
          parentAnchorId={`3.3.${index + 1}`}
          name={
            `${projectSZPKBlockValues['8'].stepActivityOthers}.${index}.activityOthers` as const
          }
        />
      ))}
    </Stack>
  )
}

export default Eight
