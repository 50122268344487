import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'

const generateRHFObjectForAgreementStabilizationNegotiationsForm = (
  objectFromPropertyState: Record<string, any>,
): AgreementStabilizationNegotiationsFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },

    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      agreementLinkStabilizationPeriod: objectFromPropertyState.ruleLinkStabilization?.value,
      stabilizationPeriod: objectFromPropertyState.stabilizationPeriod?.value,
      stabilizationPeriodExtend: objectFromPropertyState.stabilizationPeriodExtend?.value,
      newStabilizationPeriod: objectFromPropertyState.newStabilizationPeriod?.value,
      ruleLinkChangeStabilization: objectFromPropertyState.ruleLinkChangeStabilization?.value,
      reasonChangeStabilization: objectFromPropertyState.reasonChangeStabilization?.value,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  }
}

export { generateRHFObjectForAgreementStabilizationNegotiationsForm }
