import { generatePropertiesObjectForAAConnectionReorganizationImplementingForm } from '@components/Forms/AAConnectionReorganizationImplementingForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAAConnectionReorganizationImplementingForm } from '@components/Forms/AAConnectionReorganizationImplementingForm/adapters/RHF.adapter'
import { AAConnectionReorganizationImplementingFormValues } from '@components/Forms/AAConnectionReorganizationImplementingForm/types'

const useAAConnectionReorganizationImplementingAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): AAConnectionReorganizationImplementingFormValues =>
    generateRHFObjectForAAConnectionReorganizationImplementingForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForAAConnectionReorganizationImplementingForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useAAConnectionReorganizationImplementingAdapters }
