import React from 'react'
import { useFormContext } from 'react-hook-form'

import InvestorSection from '@components/DocumentFormComponents/FormSections/InvestorSection'
import { useNoticeOfProjectRejectConcludeSZPKManager } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/Manager'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'
import { NoticeOfProjectRejectConcludeSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/watcher'

const Two = () => {
  const formInstance = useFormContext<NoticeOfProjectRejectConcludeSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOfProjectRejectConcludeSZPKManager()

  return (
    <InvestorSection
      fullNameGenitiveRenderCondition
      fullNameInstrumentalRenderCondition
      nameOrganizationChangeTooltipRenderCondition={false}
      authorizedPersonGroupRenderCondition={false}
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'2'}
      formInstance={formInstance}
      watcher={NoticeOfProjectRejectConcludeSZPKFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Two
