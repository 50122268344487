import { FC, memo, MouseEvent } from 'react'
import { PopupProps } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import ModalAction from '@components/NewDesign/Modal/Action'
import Typography from '@components/NewDesign/Typography'

import styles from './DeleteFolderModal.module.scss'

export interface DeleteFolderModalProps {
  header: string
  description: string
  onDelete: (e: MouseEvent) => Promise<void>
}

type DeleteFolderModal = DeleteFolderModalProps & Required<PopupProps>

const DeleteFolderModal: FC<DeleteFolderModal> = ({
  header,
  description,
  isOpen,
  onDelete,
  onClose,
}) => {
  const preparedSubmitHandler = (event: MouseEvent<HTMLButtonElement>) => {
    onClose()
    onDelete(event)
  }

  const actionContent: ButtonProps[] = [
    {
      dataTestId: 'DeleteFolderModal-cancel-button',
      children: 'Отмена',
      onClick: onClose,
      view: 'gray',
    },
    {
      dataTestId: 'DeleteFolderModal-deleteFolder-button',
      children: 'Удалить',
      onClick: preparedSubmitHandler,
    },
  ]
  return (
    <ModalAction
      simpleModalContainerClassName={styles.modal}
      actionModalContentClassName={styles.body}
      isOpen={isOpen}
      title={header}
      dataTestId="DeleteFolderModal-modal"
      closeButtonDataTestId="DeleteFolderModal-modal-closeButton"
      actions={actionContent}
      onClose={onClose}
    >
      <Typography.Body variant={'bodyLMedium'} fontWeight={'normal'}>
        {description}
      </Typography.Body>
    </ModalAction>
  )
}

export default memo(DeleteFolderModal)
