import React, { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Alert from '@components/Alert'
import Loader from '@components/Loader'
import { ButtonProps } from '@components/NewDesign/Button/types'
import IconButton from '@components/NewDesign/IconButton'
import Modal from '@components/NewDesign/Modal'
import { solicitationStepMap } from '@components/NewDesignedModals/AddSolicitationMembersModal/constants'
import ChooseMembersStep from '@components/NewDesignedModals/AddSolicitationMembersModal/Steps/ChooseMembers/ChooseMembers'
import ChooseSolicitationStep from '@components/NewDesignedModals/AddSolicitationMembersModal/Steps/ChooseSoliciation/ChooseSoliciation'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import { useAddMemberModalInfo } from '@components/Projects/[id]/ParticipantsOfStatement/hooks'
import { useAPIContext } from '@context/APIContext'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { usePossibleSolicitationMembers } from '@hooks/new/swr/usePossibleSolicitationMembers'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useSolicitationMembers } from '@hooks/new/swr/useSolicitationMembers'
import backArrowIcon from '@icons/BackArrow.svg'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './AddSolicitationMembersModal.module.scss'

export interface AddSolicitationMembersModalProps extends PopupProps {
  projectId: string
  dfoId: string
}

export interface MembersFormValues {
  currentStep: keyof typeof solicitationStepMap
  chooseMembers: {
    municipality: string[]
    subjects: string[]
  }
  chooseSolicitationMember: {
    organizationId: string
  }
}

const useSolicitationMembersModal = () => {
  const popupManager = usePopupManager()

  const handleOpenAddSolicitationMembersModal = (props: AddSolicitationMembersModalProps) => {
    popupManager.open(AddSolicitationMembersModal, props)
  }

  return { handleOpenAddSolicitationMembersModal }
}

const AddSolicitationMembersModal: FC<AddSolicitationMembersModalProps> = ({
  isOpen,
  projectId,
  dfoId,
  onClose,
}) => {
  const {
    dfosApi: { addSolicitationMembersByDfoId, removeSolicitationMembersByDfoId },
  } = useAPIContext()

  const { handleOpenLoadingModal } = useLoadingModal()
  const popupManager = usePopupManager()

  const { solicitationMembers, mutate: revalidateSolicitation } = useSolicitationMembers({
    key: {
      projectId,
      dfoId,
      _key: 'solicitationMembers',
    },
    config: {
      isPaused: () => !projectId || !dfoId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AddSolicitationMembersModal',
          moduleName: 'SolicitationMembers',
        },
      }),
    },
  })

  const {
    possibleSolicitationMembers,
    isLoadingPossibleSolicitationMembers,
    mutate: revalidatePossibleSolicitation,
  } = usePossibleSolicitationMembers({
    key: {
      projectId,
      dfoId,
      _key: 'possibleMembers',
    },
    config: {
      revalidateOnMount: true,
      isPaused: () => !projectId || !dfoId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AddSolicitationMembersModal',
          moduleName: 'SolicitationMembers',
        },
      }),
    },
  })

  const { mutate: revalidateProjectAttributes, isLoadingProjectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AddSolicitationMembersModal',
          moduleName: 'SolicitationMembers',
        },
      }),
    },
  })

  const { postedAttributes, subjectsToEdit } = useAddMemberModalInfo(projectId || '')

  const chooseMembersSkipCondition =
    !!subjectsToEdit.length && postedAttributes.includes('isFederal')

  const formInstance = useForm<MembersFormValues>({
    defaultValues: {
      currentStep: chooseMembersSkipCondition
        ? solicitationStepMap.solicitationMember
        : solicitationStepMap.chooseMembers,
      chooseMembers: {
        subjects: subjectsToEdit.length ? subjectsToEdit : [],
      },
      chooseSolicitationMember: {
        organizationId: solicitationMembers?.[0]?.organizationId || '',
      },
    },
  })

  const currentStep = formInstance.watch('currentStep')

  const isChooseMemberStep = currentStep === solicitationStepMap.chooseMembers

  useEffect(() => {
    formInstance.reset({
      currentStep: chooseMembersSkipCondition
        ? solicitationStepMap.solicitationMember
        : solicitationStepMap.chooseMembers,
      chooseMembers: {
        subjects: subjectsToEdit.length ? subjectsToEdit : [],
      },
      chooseSolicitationMember: {
        organizationId: solicitationMembers?.[0]?.organizationId || '',
      },
    })
  }, [chooseMembersSkipCondition, formInstance, solicitationMembers, subjectsToEdit])

  useUpdateEffect(() => {
    if (!solicitationMembers) return

    formInstance.reset({
      chooseSolicitationMember: {
        organizationId: solicitationMembers[0]?.organizationId,
      },
    })
  }, [solicitationMembers])

  const subjects = formInstance.watch('chooseMembers.subjects')
  const solicitation = formInstance.watch('chooseSolicitationMember.organizationId')

  const handleChangeStep = (value: MembersFormValues['currentStep']) => {
    formInstance.setValue('currentStep', value)
  }

  const handleModalClose = useCallback(async () => {
    onClose?.()
    await revalidateProjectAttributes()
  }, [onClose, revalidateProjectAttributes])

  const handleAddSolicitationMember = useCallback(async () => {
    const organizationId = formInstance.watch('chooseSolicitationMember.organizationId')

    try {
      await addSolicitationMembersByDfoId({ dfoId, projectId, organizationId })
      await revalidateSolicitation()
    } catch (e) {
      toast(
        <Alert transparent variant={'warning'}>
          Не удалось добавить финального согласующего
        </Alert>,
      )
    }
  }, [addSolicitationMembersByDfoId, dfoId, formInstance, projectId, revalidateSolicitation])

  const handleDeleteSolicitationMember = useCallback(async () => {
    const memberToDelete = solicitationMembers?.[0]?.id

    if (!memberToDelete) return Promise.resolve()

    try {
      return await removeSolicitationMembersByDfoId({ projectId, dfoId, memberId: memberToDelete })
    } catch (e) {
      toast(
        <Alert transparent variant={'warning'}>
          Не удалось удалить финального согласующего
        </Alert>,
      )
    }
  }, [dfoId, projectId, removeSolicitationMembersByDfoId, solicitationMembers])

  const handleSaveSolicitationMember = useCallback(async () => {
    handleOpenLoadingModal()

    try {
      await handleDeleteSolicitationMember()

      try {
        await handleAddSolicitationMember()
      } catch {
        toast(
          <Alert transparent variant={'warning'}>
            Финальный согласующий не был добавлен, повторите попытку
          </Alert>,
        )
      }
    } catch {
      toast(
        <Alert transparent variant={'warning'}>
          Финальный согласующий не был изменён
        </Alert>,
      )
    } finally {
      await handleModalClose()

      popupManager.closeAll()
    }
  }, [
    handleAddSolicitationMember,
    handleDeleteSolicitationMember,
    handleModalClose,
    handleOpenLoadingModal,
    popupManager,
  ])

  const currentModalActions: ButtonProps[] = useMemo(() => {
    return isChooseMemberStep
      ? [
          {
            view: 'gray',
            dataTestId: 'AddSolicitationMembersModal-cancel-button',
            children: 'Отмена',
            onClick: handleModalClose,
          },
          {
            dataTestId: 'AddSolicitationMembersModal-continue-button',
            children: 'Продолжить',
            disabled: !subjects?.length,
            onClick: async () => {
              await revalidatePossibleSolicitation()
              handleChangeStep(solicitationStepMap.solicitationMember)
            },
          },
        ]
      : [
          {
            view: 'gray',
            dataTestId: 'AddSolicitationMembersModal-cancel-button',
            children: 'Отмена',
            onClick: handleModalClose,
          },
          {
            dataTestId: 'AddSolicitationMembersModal-saveSolicitationMember-button',
            children: 'Сохранить',
            disabled: !solicitation,
            onClick: handleSaveSolicitationMember,
          },
        ]
  }, [
    isChooseMemberStep,
    handleModalClose,
    subjects?.length,
    solicitation,
    handleSaveSolicitationMember,
    revalidatePossibleSolicitation,
  ])

  const currentModalProps = useMemo(() => {
    return {
      title: isChooseMemberStep ? (
        'Укажите участников соглашения'
      ) : (
        <div className={styles.solicitationMembersModal__header}>
          {!chooseMembersSkipCondition && (
            <IconButton
              className={styles.solicitationMembersModal__backIcon}
              view={'basic'}
              color={'default'}
              geometry={'square'}
              size={'s'}
              dataTestId="AddSolicitationMembersModal-back-iconButton"
              icon={{
                src: backArrowIcon,
                className: styles['solicitationMembersModal__backIcon-icon'],
              }}
              onClick={() => handleChangeStep(solicitationStepMap.chooseMembers)}
            />
          )}
          Кто будет согласовывать ходатайство?
        </div>
      ),

      actions: currentModalActions,
    }
  }, [chooseMembersSkipCondition, currentModalActions, isChooseMemberStep])

  return (
    <Modal.Action
      isOpen={isOpen}
      dataTestId="AddSolicitationMembersModal-modal"
      closeButtonDataTestId="AddSolicitationMembersModal-modal-closeButton"
      simpleModalContainerClassName={styles.solicitationMembersModal__container}
      simpleModalTitleClassName={styles['solicitationMembersModal__container-title']}
      actionModalContentClassName={styles['solicitationMembersModal__container-content']}
      simpleModalBodyClassName={styles['solicitationMembersModal__container-body']}
      onClose={handleModalClose}
      {...currentModalProps}
    >
      <Loader
        loading={isLoadingProjectAttributes || isLoadingPossibleSolicitationMembers}
        variant={'lite'}
        dataTestId="AddSolicitationMembersModal-loader"
      >
        {currentStep === solicitationStepMap.chooseMembers ? (
          <ChooseMembersStep
            formInstance={formInstance}
            projectId={projectId}
            subjects={subjectsToEdit}
            postedAttributes={postedAttributes}
          />
        ) : (
          <ChooseSolicitationStep
            nameOfSolicitationControl={'chooseSolicitationMember.organizationId'}
            formInstance={formInstance}
            possibleSolicitationMembers={possibleSolicitationMembers}
          />
        )}
      </Loader>
    </Modal.Action>
  )
}

export { useSolicitationMembersModal }
export default memo(AddSolicitationMembersModal)
