import { dayPlurals, hourPlurals, minutePlurals } from '@constants/pluralsOfDates'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { IInitialTechnicalWork } from '@services/TechnicalWork/TechnicalWork.entity'
import plural from 'plural-ru'

class TechnicalWorkService {
  private _startDate
  private _endDate

  private _timeToStart
  private _timeToEnd

  private readonly _isRunning
  private readonly _timeOfStart
  private readonly _timeOfEnd

  private readonly mapOfPlurals = {
    hours: hourPlurals,
    days: dayPlurals,
    minutes: minutePlurals,
  }

  constructor({
    startDate,
    endDate,
    isRunning,
    timeToStart,
    timeToEnd,
  }: Pick<IInitialTechnicalWork, 'startDate' | 'endDate' | 'isRunning'> &
    Partial<Pick<IInitialTechnicalWork, 'timeToStart' | 'timeToEnd'>>) {
    this._startDate = startDate
    this._endDate = endDate
    this._isRunning = isRunning

    this._timeToStart = timeToStart
    this._timeToEnd = timeToEnd

    if (timeToStart) {
      this._timeOfStart = timeToStart.chosen && timeToStart[timeToStart.chosen]
    }

    if (timeToEnd) {
      this._timeOfEnd = timeToEnd.chosen && timeToEnd[timeToEnd.chosen]
    }
  }

  //Отдавать только московское время
  getFormattedAllTime(formatterTemplate: string) {
    const formattedStartDate = DayjsService.dayjs(this._startDate).format(formatterTemplate)
    const formattedEndDate = DayjsService.dayjs(this._endDate).format(formatterTemplate)

    return `${formattedStartDate} - ${formattedEndDate}`
  }

  getFormattedOffsetOfStartTime(template?: string) {
    if (this._timeOfStart) {
      const readyToReplaceValue = plural(
        this._timeOfStart,
        ...this.mapOfPlurals[this._timeToStart.chosen],
      )

      if (!template) {
        return readyToReplaceValue
      }

      return template.replace('%formatted', readyToReplaceValue)
    }
  }

  getFormattedOffsetOfEndTime(template?: string) {
    if (this._timeOfEnd) {
      const readyToReplaceValue = plural(
        this._timeOfEnd,
        ...this.mapOfPlurals[this._timeToEnd.chosen],
      )

      if (!template) {
        return readyToReplaceValue
      }

      return template.replace('%formatted', readyToReplaceValue)
    }
  }
}

export { TechnicalWorkService }
