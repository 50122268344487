import React, { DetailedHTMLProps, FC, memo, ReactNode } from 'react'

import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './PartItem.module.scss'

interface PartItemProps
  extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  part: string
  classificationHeader: string
  contentClassName?: string
  children?: ReactNode
}

const MAX_LENGTH_PART = 60
const MAX_LENGTH_CLASSIFICATION_HEADER = 155

const PartItem: FC<PartItemProps> = ({
  part,
  classificationHeader,
  contentClassName,
  children,
  ...divRest
}) => {
  const firstColumnTooltip = useManualTooltipControl()

  const secondColumnTooltip = useManualTooltipControl()

  return (
    <div {...divRest} className={cn(styles.partItem, divRest.className)}>
      {children}
      <div className={cn(styles.partItem__content, contentClassName)}>
        <div className={styles.wrapper__tooltip}>
          <Tooltip
            availableHeight
            open={part.length > MAX_LENGTH_PART && firstColumnTooltip.state.tooltipIsOpen}
            position={'right'}
            content={part}
            popoverClassName={styles.tooltip__content}
            onOpen={firstColumnTooltip.handlers.handleOpenTooltip}
            onClose={firstColumnTooltip.handlers.handleCloseTooltip}
          >
            <Typography.Body className={styles.partItem__part} variant={'bodyMMedium'}>
              {part}
            </Typography.Body>
          </Tooltip>
        </div>

        <div className={styles.wrapper__tooltip}>
          <Tooltip
            availableHeight
            position={'top'}
            content={classificationHeader}
            popoverClassName={styles.tooltip__content}
            open={
              classificationHeader.length > MAX_LENGTH_CLASSIFICATION_HEADER &&
              secondColumnTooltip.state.tooltipIsOpen
            }
            onOpen={secondColumnTooltip.handlers.handleOpenTooltip}
            onClose={secondColumnTooltip.handlers.handleCloseTooltip}
          >
            <Typography.Body
              variant={'bodyMMedium'}
              className={styles.partItem__classificationHeader}
            >
              {classificationHeader}
            </Typography.Body>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default memo(PartItem)
