import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAPIContext } from '@context/APIContext'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useBooleanState } from '@hooks/useBooleanState'
import { IAttribute } from '@services/Attribute/Attribute.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { IOrganizationInfo } from '@services/Organization/organization.entity'
import { unstable_serialize, useSWRConfig } from 'swr'

const useMembersFromAttributes = (projectId?: string, isPaused?: boolean) => {
  const {
    booleanState: membersFromAttributesIsLoading,
    setBooleanState: setMembersFromAttributesLoading,
  } = useBooleanState()
  const [membersFromAttributes, setMembersFromAttributes] = useState<
    Record<string, IOrganizationInfo[]>
  >({})
  const lastProcessedAttributes = useRef<IAttribute[]>()

  const {
    organizationsApi: { getOrganizationById },
  } = useAPIContext()

  const { cache } = useSWRConfig()

  const { projectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      isPaused: () => !projectId || !!isPaused,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useMembersFromAttributes',
          moduleName: 'ParticipantsOfStatementManager',
          componentType: 'hook',
        },
      }),
    },
  })

  const allProjectMembersFromAttributes = useMemo(() => {
    if (isPaused || !projectAttributes) return

    const allOrganizationIdsFromAttributes = projectAttributes.reduce(
      (previousValue, currentValue) => {
        //Собираем в массив все subjects и municipality, чтобы вследствии забрать их id для дальнейшего поиска в организации.
        if (
          !currentValue.isTemp &&
          (currentValue.name === 'subjects' || currentValue.name === 'municipality')
        ) {
          const organizationIdsFromValues =
            currentValue.type === 'List' && Array.isArray(currentValue.value)
              ? currentValue.value
              : []

          return [...previousValue, ...organizationIdsFromValues]
        }

        return previousValue
      },
      [] as string[],
    )

    return allOrganizationIdsFromAttributes
  }, [isPaused, projectAttributes])

  const getProjectMembersFromAttributes = useCallback(async () => {
    if (!allProjectMembersFromAttributes) return

    try {
      const organizationsPromises = await Promise.all(
        allProjectMembersFromAttributes.map(async (organizationId) => {
          const key = {
            organizationId,
            _key: 'organizationMembers',
          }
          const organization = await getOrganizationById(organizationId)
          cache.set(unstable_serialize(key), organization)

          return organization
        }),
      )

      const organizations = organizationsPromises.reduce((previousValue, currentValue) => {
        if (currentValue.type in previousValue) {
          const existedOrganizationArray = previousValue[currentValue.type]

          return {
            ...previousValue,
            [currentValue.type]: [...existedOrganizationArray, currentValue],
          }
        }

        return {
          ...previousValue,
          [currentValue.type]: [currentValue],
        }
      }, {} as Record<string, IOrganizationInfo[]>)

      return organizations
    } catch (error) {
      throw error
    }
  }, [allProjectMembersFromAttributes, cache, getOrganizationById])

  useEffect(() => {
    //При срабатывании зависимости с isPaused, если аттрибуты не изменились в замыкании, то пересчёт делать не нужно.
    if (
      isPaused ||
      unstable_serialize(lastProcessedAttributes.current) === unstable_serialize(projectAttributes)
    )
      return
    ;(async () => {
      setMembersFromAttributesLoading(true)

      try {
        const organizations = await getProjectMembersFromAttributes()

        if (!organizations) return

        lastProcessedAttributes.current = projectAttributes
        setMembersFromAttributes(organizations)
      } catch (error) {
        const additionInfo = {
          projectAttributes,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'useMembersFromAttributes',
            moduleName: 'ParticipantsOfStatementManager',
            componentType: 'hook',
          },
        })(error)

        throw error
      } finally {
        setMembersFromAttributesLoading(false)
      }
    })()
  }, [
    getProjectMembersFromAttributes,
    isPaused,
    projectAttributes,
    setMembersFromAttributesLoading,
  ])

  return useMemo(
    () => ({
      membersFromAttributes,
      membersFromAttributesIsLoading,
    }),
    [membersFromAttributes, membersFromAttributesIsLoading],
  )
}

export { useMembersFromAttributes }
