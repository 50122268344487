import React from 'react'
import { useFormContext } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useInitialCollapseStateList } from '@components/DocumentFormComponents/hooks/useInitialCollapseStateList'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnCreationOtherEstateObjectsBlockValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import AAgreementOnCreationOtherEstateObjectsStage from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/Stage'
import { ConstructionStagesPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import {
  AAgreementOnCreationOtherEstateObjectsFormModifierValues,
  AAgreementOnCreationOtherEstateObjectsFormValues,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import {
  AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  AAgreementOnCreationOtherEstateObjectsModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isUndefined } from '@helpers/checkTypes'

const Fifth = () => {
  const formInstance = useFormContext<AAgreementOnCreationOtherEstateObjectsFormValues>()
  const formModifierInstance =
    useFormModifierContext<AAgreementOnCreationOtherEstateObjectsFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const { fields: constructionStages } = useFieldArraySubscribableControl<
    AAgreementOnCreationOtherEstateObjectsFormValues,
    ConstructionStagesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aAgreementOnCreationOtherEstateObjectsBlockValues['5'].constructionStages,
    keyName: 'keyNameId',
    watcher: AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnCreationOtherEstateObjectsFormModifierValues,
    ConstructionStagesPathName
  >({
    control: formModifierInstance?.control,
    name: aAgreementOnCreationOtherEstateObjectsBlockValues['5'].constructionStages,
    watcher: AAgreementOnCreationOtherEstateObjectsModifierFieldArrayControlUpdateWatcher,
  })

  const { initialCollapseState } = useInitialCollapseStateList(constructionStages)

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Row>
        <Col xs={12}>
          <Stack direction={'vertical'} gap={!constructionStages.length ? 0 : 3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementOnCreationOtherEstateObjectsBlockValues['5'].constructionStages,
              })}
            >
              <FlipperList list={constructionStages} gap={1}>
                {constructionStages.map((stage, index) => {
                  const currentInitState = !isUndefined(initialCollapseState[stage.id])
                    ? initialCollapseState[stage.id]
                    : true

                  return (
                    <AAgreementOnCreationOtherEstateObjectsStage
                      key={stage.id}
                      id={stage.id}
                      indexOfStage={index}
                      initialAccordionState={currentInitState}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}

export default Fifth
