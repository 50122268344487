import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'

const mapOfFinancialModelMenu = [
  { id: '1', title: 'Производственные и экономические характеристики' },
  { id: '2', title: 'Общий объем инвестиций по годам' },
  { id: '3', title: 'Общие финансовые характеристики проекта' },
  { id: '4', title: 'Налоговые показатели' },
]

const financialModelBlockValues = {
  '1': {
    startInvestStage: '1.startInvestStage',
    endInvestStage: '1.endInvestStage',
    investStagePeriod: '1.investStagePeriod',
    startExploitationStage: '1.startExploitationStage',
    projectCapacityYear: '1.projectCapacityYear',
  },
  '2': {
    investmentYears: '2.investmentYears',
    investmentFederalBudget: '2.investmentFederalBudget',
    investmentRegionalBudget: '2.investmentRegionalBudget',
    investmentMunicipalBudget: '2.investmentMunicipalBudget',
    investmentBudgetFinance: '2.investmentBudgetFinance',
    investmentInvestorFinance: '2.investmentInvestorFinance',
    investmentRaiseFinance: '2.investmentRaiseFinance',
    investmentBankCredits: '2.investmentBankCredits',
    investmentOtherRaiseFinance: '2.investmentOtherRaiseFinance',
  },
  '3': {
    netPresentValue: '3.netPresentValue',
    internalRateReturn: '3.internalRateReturn',
  },
  '4': {
    investStageTaxValue: '4.investStageTaxValue',
    exploitationStageTaxValue: '4.exploitationStageTaxValue',
    stageTaxYears: '4.stageTaxYears',
  },
} as const

const defaultFinancialModelFormValue: FinancialModelFormValues = {
  '1': {
    startInvestStage: '',
    endInvestStage: '',
    investStagePeriod: '',
    startExploitationStage: '',
    projectCapacityYear: '',
  },
  '2': {
    investmentYears: [],
    investmentFederalBudget: '',
    investmentRegionalBudget: '',
    investmentMunicipalBudget: '',
    investmentBudgetFinance: '',
    investmentInvestorFinance: '',
    investmentRaiseFinance: '',
    investmentBankCredits: '',
    investmentOtherRaiseFinance: '',
  },
  '3': {
    netPresentValue: '',
    internalRateReturn: '',
  },
  '4': {
    stageTaxYears: [],
  },
}

export { defaultFinancialModelFormValue, financialModelBlockValues, mapOfFinancialModelMenu }
