import React, { FC, memo, useMemo } from 'react'

import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import { useInnModal } from '@components/NewDesignedModals/AddINNModal'
import { DfoAttributeTypes, DfoPropertiesTypes, RolesTypes } from '@constants/types'
import { checkRoleOnExistence } from '@context/AuthContext/workers/rolesWorkers'
import { useOrganizationInfoById } from '@hooks/new/swr/useOrganizationInfoById'
import { useVersions } from '@hooks/new/swr/useVersions'
import IconReload from '@icons/attachment/actions/ReloadAttachmentIcon.svg'
import EditIcon from '@icons/EditIcon.svg'
import PersonAddIcon from '@icons/PersonAddIcon.svg'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './TransferMembers.module.scss'

import { useCurrentOrganizationId } from '@/hooks/new/organisation/useCurrentOrganizationId'

const componentInfo = {
  componentName: 'TransferMembers',
  componentType: 'DocumentsView',
}

interface TransferMembersProps {
  projectId: string
  transferDfo: IDfoListItem
  additionalWriteCondition?: boolean
}

const DEFAULT_TITLE = 'Организация, которой будут переданы права'
const DEFAULT_ERROR_TEXT = 'Не удалось загрузить данные организации'

const TransferMembers: FC<TransferMembersProps> = ({
  projectId,
  transferDfo,
  additionalWriteCondition = true,
}) => {
  const { handleOpenAddINNModal } = useInnModal()

  const { actualVersionId } = useVersions({
    key: {
      dfoId: transferDfo.id,
      projectId,
      _key: 'versions',
    },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo,
      }),
    },
  })

  const isINVESTOR = checkRoleOnExistence(RolesTypes.INVESTOR)

  const {
    currentOrganizationId: currentPartnerOrganizationId,
    errorCurrentOrganizationId: errorCurrentPartnerOrganizationId,
    revalidateCurrentOrganizationId: revalidateCurrentPartnerOrganizationId,
    isLoadingCurrentOrganizationId: isLoadingCurrentPartnerOrganizationId,
  } = useCurrentOrganizationId({
    projectId,
    dfo: transferDfo,
    dfoAttributeType: DfoAttributeTypes.partnerOrganizationId,
    dfoPropertyType: DfoPropertiesTypes.investorPartnerMemberOrganizationId,
    handleLogErrorParams: { componentInfo },
  })

  const {
    organization,
    error: organizationError,
    isLoadingOrganization,
    mutate: revalidateOrganizationInfo,
  } = useOrganizationInfoById({
    key: {
      organizationId: currentPartnerOrganizationId,
      _key: 'organizationInfo ',
    },
    config: {
      isPaused: () => !currentPartnerOrganizationId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo,
        additionInfo: {
          organizationId: currentPartnerOrganizationId,
        },
      }),
    },
  })

  const organizationRenderCondition = organization && !organizationError
  const organizationReadModeRender = !organizationError
    ? organization?.name || 'Не указана'
    : DEFAULT_ERROR_TEXT

  const organizationExistAndWriteModeRender = organizationRenderCondition
    ? organization?.name || 'Не найдена'
    : DEFAULT_ERROR_TEXT

  const isOrganizationRenderModeLoading =
    isLoadingOrganization || isLoadingCurrentPartnerOrganizationId

  const writeTransferMemberCondition = useMemo(() => {
    if (!transferDfo) return

    return (
      additionalWriteCondition &&
      isINVESTOR &&
      !organizationError &&
      actualVersionId === transferDfo.id &&
      transferDfo.actionAllowed
    )
  }, [actualVersionId, additionalWriteCondition, organizationError, isINVESTOR, transferDfo])

  const handleRevalidateOrganization = async () => {
    try {
      await revalidateOrganizationInfo()
    } catch {}
  }

  const handleChangeOrganization = () => {
    handleOpenAddINNModal({
      projectId,
      dfo: transferDfo,
      partnerOrganizationId: currentPartnerOrganizationId ?? '',
      onSave: async () => {
        try {
          await revalidateCurrentPartnerOrganizationId()
          await revalidateOrganizationInfo()
        } catch {}
      },
    })
  }

  if (writeTransferMemberCondition) {
    if (!!currentPartnerOrganizationId) {
      return (
        <div className={styles.transferMembers}>
          <div className={styles.transferMembers__organization}>
            <Loader
              loading={isOrganizationRenderModeLoading}
              variant={'lite'}
              dataTestId="TransferMembers-loader"
            >
              <Typography.Caption variant={'captionSRegular'} color={'text-base-tertiary'}>
                {DEFAULT_TITLE}
              </Typography.Caption>
              <div className={styles['transferMembers__organization-view']}>
                <Typography.Body variant={'bodyMMedium'}>
                  {organizationExistAndWriteModeRender}
                </Typography.Body>
                {organizationError && (
                  <IconButton
                    className={styles.transferMembers__reload}
                    size={'s'}
                    dataTestId="TransferMembers-revalidateOrganization-iconButton"
                    icon={{
                      src: IconReload,
                    }}
                    onClick={handleRevalidateOrganization}
                  />
                )}
              </div>
            </Loader>
          </div>
          {!isOrganizationRenderModeLoading && !organizationError && (
            <Button
              size={'s'}
              geometry={'round'}
              view={'plain'}
              dataTestId="TransferMembers-changeOrganization-button"
              leadingIcon={{
                src: EditIcon,
              }}
              onClick={handleChangeOrganization}
            >
              Изменить организацию
            </Button>
          )}
        </div>
      )
    }

    return (
      <ControlContainer
        title={'Укажите организацию, которой хотите передать права'}
        leadingNodeContent={<ControlContainerStatusIcon controlIcon={PersonAddIcon} />}
        trailingNodeContent={
          <Button
            fixWidth
            view="gray"
            dataTestId="TransferMembers-selectOrganization-button"
            onClick={handleChangeOrganization}
          >
            Указать организацию
          </Button>
        }
      />
    )
  }

  return (
    <div className={styles.transferMembers}>
      <div className={styles.transferMembers__organization}>
        <Loader
          loading={isOrganizationRenderModeLoading}
          variant={'lite'}
          dataTestId="TransferMembers-loader"
        >
          <Typography.Caption variant={'captionSRegular'} color={'text-base-tertiary'}>
            {DEFAULT_TITLE}
          </Typography.Caption>
          <div className={styles['transferMembers__organization-view']}>
            <Typography.Body variant={'bodyMMedium'}>{organizationReadModeRender}</Typography.Body>
            {organizationError && (
              <IconButton
                className={styles.transferMembers__reload}
                size={'s'}
                dataTestId="TransferMembers-revalidateOrganization-iconButton"
                icon={{
                  src: IconReload,
                }}
                onClick={handleRevalidateOrganization}
              />
            )}
          </div>
        </Loader>
      </div>
    </div>
  )
}

export default memo(TransferMembers)
