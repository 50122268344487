import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'

interface NoticeOneSideRejectSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
}

const NoticeOneSideRejectSZPKForms: NoticeOneSideRejectSZPKFormsProps = () => null

NoticeOneSideRejectSZPKForms.First = First
NoticeOneSideRejectSZPKForms.Second = Second
NoticeOneSideRejectSZPKForms.Third = Third

export default NoticeOneSideRejectSZPKForms
