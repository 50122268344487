import { FC, memo } from 'react'

import FormModifierProviderWrapper from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import {
  defaultAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  defaultAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayout from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Layout/index'

const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAWithFormProvider: FC<
  FormModalLayoutProps
> = (props) => {
  return (
    <FormProviderWrapper
      defaultValues={defaultAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues}
    >
      <FormModifierProviderWrapper
        defaultValues={
          defaultAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues
        }
      >
        <AAgreementOnNonConclusionOrNonPerformanceOfConcessionALayout {...props} />
      </FormModifierProviderWrapper>
    </FormProviderWrapper>
  )
}

export default memo(AAgreementOnNonConclusionOrNonPerformanceOfConcessionAWithFormProvider)
