import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu, NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import cloneDeep from 'clone-deep'

const aAVolumeOfCapitalInvestmentsMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Уполномоченный федеральный орган исполнительной власти',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'Уполномоченный орган субъекта Российской Федерации',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '3': {
    id: '3',
    path: '3',
    title: 'Муниципальное образование',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '4': {
    id: '4',
    path: '4',
    title: 'Организация, реализующая проект',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '5': {
    id: '5',
    path: '5',
    title: 'Капиталовложения',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '6': {
    id: '6',
    path: '6',
    title: 'Капиталовложения по проекту',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
}

const getAAVolumeOfCapitalInvestmentsMenuByStatus = (
  isFederal: boolean,
  isMunicipalitiesRender: boolean,
) => {
  const initialAAVolumeOfCapitalInvestmentsMenuHashMap = cloneDeep(
    aAVolumeOfCapitalInvestmentsMenuHashMap,
  )

  const aAVolumeOfCapitalInvestmentsMenuState = [
    aAVolumeOfCapitalInvestmentsMenuHashMap['1'],
    aAVolumeOfCapitalInvestmentsMenuHashMap['2'],
    aAVolumeOfCapitalInvestmentsMenuHashMap['3'],
    aAVolumeOfCapitalInvestmentsMenuHashMap['4'],
    aAVolumeOfCapitalInvestmentsMenuHashMap['5'],
    aAVolumeOfCapitalInvestmentsMenuHashMap['6'],
  ]

  const initialAAVolumeOfCapitalInvestmentsMenuState = aAVolumeOfCapitalInvestmentsMenuState.reduce<
    NestedMapOfMenu[]
  >((previousValue, currentValue) => {
    if (!isFederal && currentValue.id === '1') return previousValue

    if (!isMunicipalitiesRender && currentValue.id === '3') return previousValue

    return [...previousValue, currentValue]
  }, [])

  const mapOfMenu = initialAAVolumeOfCapitalInvestmentsMenuState.map(
    (menu): MapOfMenu => ({
      id: menu.id,
      title: menu.title,
    }),
  )

  const {
    ['1']: firstSection,
    ['3']: thirdSection,
    ...changedMenuHashMap
  } = initialAAVolumeOfCapitalInvestmentsMenuHashMap

  return {
    initialAAVolumeOfCapitalInvestmentsMenuHashMap: {
      ...changedMenuHashMap,
      ...(isFederal && {
        '1': firstSection,
      }),
      ...(isMunicipalitiesRender && {
        '3': thirdSection,
      }),
    },
    initialAAVolumeOfCapitalInvestmentsMenuState,
    mapOfMenu,
  }
}

export { getAAVolumeOfCapitalInvestmentsMenuByStatus }
