import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['4'],
  ProjectSZPKFormValues
> = {
  ruleSubjectSzpkInstrumental: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  ruleLinkStabilizationNpa: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
}

export { fourthSectionValidationMap }
