import { DfoGroupTypes } from '@context/APIContext/types'
import {
  MERFilterController as MERExtraFilterController,
  OIVFilterController as OIVExtraFilterController,
  OMSUFilterController as OMSUExtraFilterController,
  ROIVFilterController as ROIVExtraFilterController,
  UOFilterController as UOExtraFilterController,
} from '@routes/NewStatements/filters/EXTRA'
import {
  MERFilterController,
  OIVFilterController,
  OMSUFilterController,
  ROIVFilterController,
  UOFilterController,
} from '@routes/NewStatements/filters/SZPK'
import { TProjectTags } from '@services/Projects/Project.entity'

class FilterStatementController {
  static getFilterInstanceByTagGroup(tagGroup: 'all' | TProjectTags) {
    if (tagGroup === DfoGroupTypes.SZPK) {
      return {
        MERFilterController: new MERFilterController(),
        OIVFilterController: new OIVFilterController(),
        UOFilterController: new UOFilterController(),
        OMSUFilterController: new OMSUFilterController(),
        ROIVFilterController: new ROIVFilterController(),
      }
    }

    if (tagGroup === DfoGroupTypes.EXTRA) {
      return {
        MERFilterController: new MERExtraFilterController(),
        OIVFilterController: new OIVExtraFilterController(),
        UOFilterController: new UOExtraFilterController(),
        OMSUFilterController: new OMSUExtraFilterController(),
        ROIVFilterController: new ROIVExtraFilterController(),
      }
    }
  }
}

export { FilterStatementController }
