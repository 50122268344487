import { blockValues } from '@components/Forms/CreateStatementOld/const'
import {
  StatementOldFormValues,
  TStatementRules,
} from '@components/Forms/CreateStatementOld/types'
import { objOfDateFormats } from '@constants/dateFormats'
import {
  bigIntIsValidate,
  defaultRHFValidation,
  lengthValidate,
  Patterns,
} from '@constants/validations'
import { isNumber, isString } from '@helpers/checkTypes'
import dayjs from 'dayjs'

const generalInfoValidation: Record<keyof StatementOldFormValues['generalInfo'], TStatementRules> =
  {
    characteristic: ({ form }) => ({
      required: defaultRHFValidation.required,
      validate: (value) => isString(value) && lengthValidate(value, 500),
      onChange: (e) => {
        form?.setValue(blockValues.generalInfo.characteristic, e.target.value.trimStart())
      },
    }),

    startDate: ({ form }) => ({
      required: defaultRHFValidation.required,
      onChange: async () => {
        const allStages = form.getValues(blockValues.stages.stages)

        allStages.forEach((stage, index) => {
          form.trigger(`stages.${index}.startDate`)
          form.trigger(`stages.${index}.endDate`)
        })

        if (form?.getValues(blockValues.stages.projectEndDate) !== '') {
          form?.trigger(blockValues.stages.projectEndDate)
        }
      },
    }),

    projectEndDate: ({ form }) => ({
      required: defaultRHFValidation.required,
      validate: {
        positive: (value) => {
          const startDate = form.getValues(blockValues.stages.startDate)

          if (
            form &&
            !!value &&
            startDate &&
            isString(value) &&
            dayjs(value, objOfDateFormats.defaultFormat) <
              dayjs(startDate, objOfDateFormats.defaultFormat)
          )
            return 'дата окончания проекта должна быть больше даты начала проекта'
        },
      },

      onChange: async () => {
        const allStages = form.getValues(blockValues.stages.stages)

        allStages.forEach((stage, index) => {
          form.trigger(`stages.${index}.startDate`)
          form.trigger(`stages.${index}.endDate`)
        })
      },
    }),

    szpkEndDate: ({ form }) => ({
      required: defaultRHFValidation.required,
      validate: {
        positive: (value) => {
          const startDate = form.getValues(blockValues.stages.startDate)

          if (
            form &&
            !!value &&
            startDate &&
            isString(value) &&
            dayjs(value, objOfDateFormats.defaultFormat) <
              dayjs(startDate, objOfDateFormats.defaultFormat)
          )
            return 'дата окончания соглашения должна быть больше даты начала проекта'
        },
      },
    }),

    activityKind: {
      required: defaultRHFValidation.required,
    },

    activityKindOkved: {},

    newWorkplaces: {
      required: defaultRHFValidation.required,
      pattern: {
        value: Patterns.Capital,
        message: 'значение должно быть больше нуля',
      },
      validate: (value) => (isString(value) || isNumber(value)) && bigIntIsValidate(value),
    },

    newBuildingWorkplaces: {
      required: defaultRHFValidation.required,
      pattern: {
        value: Patterns.Capital,
        message: 'значение должно быть больше нуля',
      },
      validate: (value) => (isString(value) || isNumber(value)) && bigIntIsValidate(value),
    },

    headAdditionalPhone: {
      validate: (value) => isString(value) && lengthValidate(value, 30),
    },

    headEmail: {
      required: defaultRHFValidation.required,
      pattern: defaultRHFValidation.email,
      validate: (multipleInputValue: any) => {
        const { value } = multipleInputValue

        if (isString(value) && !defaultRHFValidation.email.value.test(value)) {
          return defaultRHFValidation.email.message
        }

        return isString(value) ? lengthValidate(value, 500, 'string') : true
      },
    },

    headPhone: {
      required: defaultRHFValidation.required,
      validate: (value) => {
        if (!isString(value)) return

        return !value.includes('_') || 'некорректный номер'
      },
    },

    ruleParagraph: {
      required: defaultRHFValidation.required,
      validate: (value) => isString(value) && lengthValidate(value, 200),
    },
  }

export { generalInfoValidation }
