import { useCallback } from 'react'

import { ErrorTypes } from '@components/Forms/LoginForm/constants'
import { SubmitConfidentialFormProps } from '@components/Forms/LoginForm/LoginByCrypt/CertificateForm/types'
import type { ErrorFromRequest } from '@components/Forms/LoginForm/types'
import useConfidentialModal from '@components/Modals/ConfidentialModal/manager'
import { mapOfCustomErrorCodes } from '@constants/errorCodes'
import { HttpStatusCode } from '@constants/statusCodes'
import type { AxiosError } from 'axios'

interface UseLoginErrorHandlerProps {
  notAllowedDomainHandler: (error: AxiosError<ErrorFromRequest>) => void
}

interface HandleErrorByTypeProps {
  error: AxiosError
  onRegistrationRedirect?: () => void
  onSubmit?: ({ isUser }: SubmitConfidentialFormProps) => void | Promise<void>
  onClose?: VoidFunction
  onError: (error: AxiosError) => void
}

const useLoginErrorHandler = ({ notAllowedDomainHandler }: UseLoginErrorHandlerProps) => {
  const { handleOpenConfidentialModal } = useConfidentialModal()

  const handleErrorByType = useCallback(
    ({ error,  onSubmit, onClose, onError, onRegistrationRedirect }: HandleErrorByTypeProps) => {
      const { response } = error

      if (response?.status === HttpStatusCode.BAD_REQUEST) {
        return notAllowedDomainHandler(error)
      }

      if (
        response?.status === HttpStatusCode.FORBIDDEN &&
        response?.data?.errorCode === mapOfCustomErrorCodes.ORGANIZATION_NOT_REGISTERED
      ) {
        onRegistrationRedirect?.()
      }

      if (response?.data?.error === ErrorTypes.user_not_registered) {
        handleOpenConfidentialModal({
          onSubmit: () =>
            onSubmit?.({
              isUser: true,
            }),
          onClose: () => onClose?.(),
        })
      }

      return onError(error)
    },
    [handleOpenConfidentialModal, notAllowedDomainHandler],
  )

  return {
    handleErrorByType,
  }
}

export default useLoginErrorHandler
