import { FC, SyntheticEvent } from 'react'
import { useNavigate } from 'react-router'

import { NotificationWithButton } from '@components/NewNotifications/NotificationWithButton'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { useBooleanState } from '@hooks/useBooleanState'
import { useQueryManager } from '@hooks/useQueryManager'

import { readNotification } from './helpers'
import type { SingleNotification as ISingleNotification } from './model'

const SingleNotification: FC<ISingleNotification> = (props) => {
  const navigate = useNavigate()
  const { queryUtils } = useQueryManager()

  const dfoId = queryUtils.getQuery('dfoId')

  const { booleanState: isLoading, setBooleanStateToTrue: startLoading } = useBooleanState()

  const { notificationId, link, onRead } = props

  const readNotificationHandler = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    startLoading()

    try {
      await readNotification(notificationId)
      onRead?.(notificationId)
    } catch (e) {
      handleErrorWithPopup(e)
    }
  }

  const notificationClickHandler = () => {
    if (!link) return

    const [url, searchParams] = link.split('?')

    if (!searchParams) {
      return navigate({
        pathname: link,
      })
    }

    const searchParamsObj = Object.fromEntries(new URLSearchParams(searchParams))

    const changedSearchParams = {
      ...searchParamsObj,
      ...(searchParamsObj.regions ? { regions: JSON.stringify([searchParamsObj.regions]) } : {}),
    } as Record<string, string>

    const preparedUrl = url.replace('statements', 'projects')

    //Функция navigate не сравнивает разность пути.
    if (dfoId === changedSearchParams?.dfoId) return

    navigate({
      pathname: preparedUrl,
      search: new URLSearchParams(changedSearchParams).toString(),
    })
  }

  return (
    <NotificationWithButton
      {...props}
      dataTestId={`SingleNotification-item-${notificationId}`}
      loaderDataTestId={`SingleNotification-itemLoader-${notificationId}`}
      isLoading={isLoading}
      buttonProps={{
        text: 'Прочитано',
        dataTestId: `SingleNotification-readButton-${notificationId}`,
        onClick: readNotificationHandler,
      }}
      onClick={notificationClickHandler}
    />
  )
}

export default SingleNotification
