import { FC, Fragment } from 'react'

import escapeRegExp from '@helpers/lodash/escapeRegExp'

import styles from './Highlighted.module.scss'

interface HighlightedProps {
  highlight: string
  children: string
  backgroundColor?: string
  color?: string
}

const Highlighted: FC<HighlightedProps> = ({
  highlight = '',
  color,
  backgroundColor,
  children,
}) => {
  if (!highlight.trim()) {
    return <>{children}</>
  }

  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi')
  const parts = children.split(regex)

  return (
    <>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <mark
              key={i}
              className={styles.marked}
              style={{
                color,
                backgroundColor,
              }}
            >
              {part}
            </mark>
          ) : (
            <Fragment key={i}>{part}</Fragment>
          ),
        )}
    </>
  )
}

export default Highlighted
