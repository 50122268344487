import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import cn from 'classnames'

import Typography from '../Typography/Typography'

import styles from './Alert.module.scss'

interface AlertProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  className?: string
  variant?: 'success' | 'error'
}

const Alert: FC<AlertProps> = ({ variant = 'success', children, className, ...props }) => {
  return (
    <div
      {...props}
      className={cn(styles.alert, className, styles[`alert--${variant}`])}
      role="presentation"
    >
      <Typography variant="bodyMMedium" className={styles.text} align="center" fontWeight="medium">
        {children}
      </Typography>
    </div>
  )
}

export default Alert
