import React, { memo, useMemo, useState } from 'react'

import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Loader from '@components/Loader'
import { useNpaManager } from '@components/RegistryNpa/Manager'
import MasterItem from '@components/RegistryNpa/MasterItem'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import useLoadMore from '@hooks/useLoadMore'

import styles from './Grid.module.scss'

const RegistryNPAGrid = () => {
  const {
    state: { gridSwrInstance, debouncedSearchValue },
  } = useNpaManager()

  const {
    npaList,
    isNpaListLoading,
    error,
    mutate: revalidateNpaList,
    onLoadMore,
  } = gridSwrInstance ?? {}

  const [searchPlaceholderCondition, setSearchPlaceholderCondition] = useState<boolean>(false)

  const noDataCondition = useMemo(() => {
    return !error && !isNpaListLoading && !npaList?.length && !debouncedSearchValue.length
  }, [debouncedSearchValue.length, error, isNpaListLoading, npaList?.length])

  const { getObserveRef } = useLoadMore({ onLoadMore })

  useUpdateEffect(() => {
    setSearchPlaceholderCondition(false)

    if (debouncedSearchValue.length && !npaList?.length) setSearchPlaceholderCondition(true)
  }, [debouncedSearchValue, npaList?.length])

  return (
    <div className={styles.grid}>
      {noDataCondition && <NoDataPlaceholder className={styles.placeholder} />}
      {searchPlaceholderCondition && !isNpaListLoading && (
        <SearchPlaceholder className={styles.placeholder}>{debouncedSearchValue}</SearchPlaceholder>
      )}
      {npaList?.map((npa, index) => (
        <MasterItem
          key={npa.id}
          npa={npa}
          ref={getObserveRef(index, npaList.length - 1)}
          onReloadGrid={revalidateNpaList}
        />
      ))}
      <Loader loading={isNpaListLoading} />
    </div>
  )
}

export default memo(RegistryNPAGrid)
