import { useDfoDocumentSets } from '@components/Projects/[id]/DocumentLayout/View/store'
import { useDocumentsSetDictTypes } from '@hooks/new/swr/useDocumentsSetDictTypes'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

const componentInfo: ComponentInfo = {
  componentName: 'useChangesMadeRequests',
  moduleName: 'ChangesMadeContextProvider',
  componentType: 'hook',
}

export const useChangesMadeRequests = (currentDfoInfo: IDfoListItem | null) => {
  const documentsSetDictTypes = useDocumentsSetDictTypes({
    key: {
      _key: 'documentsSetsDictTypes',
    },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo,
      }),
    },
  })

  const dfoDocumentSets = useDfoDocumentSets({
    dfo: currentDfoInfo,
    dfoConfig: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo,
        additionInfo: {
          currentDfoInfo,
        },
      }),
    },
  })

  return {
    ...dfoDocumentSets,
    ...documentsSetDictTypes,
  }
}
