import { FC } from 'react'
import { Path, useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Stack from '@components/ReactBootstrap/Stack'

export interface NewWorkPlacesGroupProps {
  groupTitle: string

  planFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  planCheckerFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>

  factFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  factCheckerFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
}

const NewWorkPlacesGroup: FC<NewWorkPlacesGroupProps> = ({
  groupTitle,
  planFormName,
  planCheckerFormName,
  factFormName,
  factCheckerFormName,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  return (
    <Group title={groupTitle}>
      <Stack direction={'vertical'} gap={3}>
        <RowWithBorder
          disableBottomDefaultStyles
          titleHeightFallback="40px"
          title={'Количество, введенное инвестором. Фактическое значение'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: factFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: factFormName,
                inputProps: {
                  disabled: true,
                  integersOnly: true,
                  suffix: '',
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          titleHeightFallback="60px"
          title={'Количество, введенное уполномоченной организацией. Фактическое значение'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: factCheckerFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: factCheckerFormName,
                inputProps: {
                  disabled: true,
                  integersOnly: true,
                  suffix: '',
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          titleHeightFallback="40px"
          title={'Количество, введенное инвестором. Плановое значение'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: planFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: planFormName,
                inputProps: {
                  disabled: true,
                  integersOnly: true,
                  suffix: '',
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableBottomDefaultStyles
          titleHeightFallback="60px"
          title={'Количество, введенное уполномоченной организацией. Плановое значение'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: planCheckerFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: planCheckerFormName,
                inputProps: {
                  disabled: true,
                  integersOnly: true,
                  suffix: '',
                },
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
      </Stack>
    </Group>
  )
}

export default NewWorkPlacesGroup
