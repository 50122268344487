import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NoticeIntentionTerminateSZPK from '@components/Forms/NoticeIntentionTerminateSZPKForm'
import {
  defaultNoticeIntentionTerminateSZPKFormValues,
  mapOfNoticeIntentionTerminateSZPKMenu,
} from '@components/Forms/NoticeIntentionTerminateSZPKForm/const'
import { NoticeIntentionTerminateSZPKFormValues } from '@components/Forms/NoticeIntentionTerminateSZPKForm/types'
import { NoticeIntentionTerminateSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeIntentionTerminateSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'

import NoticeIntentionTerminateSZPKLayoutWrapper from './Wrapper'

const NoticeIntentionTerminateSZPKLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NoticeIntentionTerminateSZPKFormValues>({
    defaultValues: defaultNoticeIntentionTerminateSZPKFormValues,
  })

  const handleOnClose = () => {
    NoticeIntentionTerminateSZPKFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NoticeIntentionTerminateSZPK.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfNoticeIntentionTerminateSZPKMenu[0].id}>
            <NoticeIntentionTerminateSZPKLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление о намерении расторгнуть СЗПК
                </Typography.Headline>
                <NoticeIntentionTerminateSZPK.StatementForm className={styles.layout__form} />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <NoticeIntentionTerminateSZPK.Menu className={styles.layout__menu} />
              </div>
            </NoticeIntentionTerminateSZPKLayoutWrapper>
          </FlatMenuManager>
        </NoticeIntentionTerminateSZPK.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NoticeIntentionTerminateSZPKLayout)
