import { ChangeEvent, FocusEvent, MouseEvent, MutableRefObject } from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import { mergeRefs } from '@helpers/ref/mergeRefs'

import Input from './Input'
import { InputProps } from './types'

export type ControlledInputProps = Omit<
  InputProps,
  'onBlur' | 'onChange' | 'onFocus' | 'onPaste' | 'onClear' | 'onKeyDown' | 'value' | 'error'
> & {
  ref?: MutableRefObject<HTMLInputElement | null>
}

export type TControlledInputProps<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> &
  Pick<
    InputProps,
    'onBlur' | 'onChange' | 'onFocus' | 'onPaste' | 'onClear' | 'onKeyDown' | 'id'
  > & {
    inputProps: ControlledInputProps
  }

export const ControlledInput = <T extends FieldValues>(props: TControlledInputProps<T>) => {
  const {
    onBlur,
    onFocus,
    onChange,
    onPaste,
    onClear,
    onKeyDown,
    inputProps,
    id,
    ...controllerProps
  } = props

  const {
    field: { onChange: onFormChange, onBlur: onFormBlur, value, ref },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const mergedOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e)
    onFormBlur()
  }

  const mergedOnClear = (e: MouseEvent<HTMLButtonElement>) => {
    onClear?.(e)

    onFormChange('')
  }

  const mergedOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e)

    onFormChange(e)
  }

  const refToInput = inputProps.ref ? mergeRefs([ref, inputProps.ref]) : ref

  return (
    <Input
      id={id}
      value={value}
      error={error}
      onBlur={mergedOnBlur}
      onChange={mergedOnChange}
      onFocus={onFocus}
      onPaste={onPaste}
      onClear={mergedOnClear}
      onKeyDown={onKeyDown}
      {...inputProps}
      ref={refToInput}
    />
  )
}

ControlledInput.displayName = 'ControlledInput'
