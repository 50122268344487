import React from 'react'
import { useFormContext } from 'react-hook-form'

import InitiatorSection from '@components/DocumentFormComponents/FormSections/InitiatorSection'
import { useApplicationInclusionOfAARegisterSZPKManager } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/Manager'
import { ApplicationInclusionOfAARegisterSZPKFormValues } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/types'
import { ApplicationInclusionOfAARegisterSZPKFieldsControlUpdateWatcher } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/watcher'

const First = () => {
  const formInstance = useFormContext<ApplicationInclusionOfAARegisterSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useApplicationInclusionOfAARegisterSZPKManager()

  if (!formInstance) return null

  return (
    <InitiatorSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={ApplicationInclusionOfAARegisterSZPKFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}
export default First
