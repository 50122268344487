import { usePopupManager } from 'react-popup-manager'

import RenameProjectModal, { RenameProjectModalProps } from '.'

const useRenameProjectModal = () => {
  const popupManager = usePopupManager()

  const handleOpenRenameProjectModal = ({
    isFederalProject,
    projectId,
    dfoId,
    projectName,
    isFederalAccess,
  }: RenameProjectModalProps) => {
    popupManager.open(RenameProjectModal, {
      onClose: () => null,
      isFederalProject,
      projectId,
      dfoId,
      projectName,
      isFederalAccess,
    })
  }

  return {
    handleOpenRenameProjectModal,
  }
}

export { useRenameProjectModal }
