import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { parseDateString } from '@components/NewDesign/DateInput/DateInput'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation, standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

const secondSectionInfrFacilitiesCostsExpectedReimbursedValidationMap: FormValuesValidationSection<
  keyof InfrFacilitiesCostsExpectedReimbursedFormValues['2'],
  InfrFacilitiesCostsExpectedReimbursedFormValues
> = {
  investorCapitalVAT: {
    required: defaultRHFValidation.required,
    validate: {
      amountValidate: (value) => {
        if (!isString(value)) return

        return standardAmountValidate(value)
      },
    },
  },

  totalCapital: {
    required: defaultRHFValidation.required,
    validate: {
      amountValidate: (value) => {
        if (!isString(value)) return

        return standardAmountValidate(value)
      },
    },
  },
  totalCapitalVAT: {
    required: defaultRHFValidation.required,
    validate: {
      amountValidate: (value) => {
        if (!isString(value)) return

        return standardAmountValidate(value)
      },
    },
  },
  endInvestStage: {
    required: defaultRHFValidation.required,
    validate: {
      positiveCurrentDate: (value) => {
        if (!isString(value)) return

        const currentYear = DayjsService.dayjsWithFormatToMSK().format(
          objOfDateFormats.yearFormat.yearOnly,
        )

        return (
          (isDateValidForDayjs(value) &&
            dayjs(
              parseDateString(value, objOfDateFormats.yearFormat.yearOnly),
              objOfDateFormats.defaultFormat,
            ) >=
              dayjs(
                parseDateString(currentYear, objOfDateFormats.yearFormat.yearOnly),
                objOfDateFormats.defaultFormat,
              )) ||
          'плановый год окончания стадии должен быть больше или равен текущему году'
        )
      },
    },
  },
}

export { secondSectionInfrFacilitiesCostsExpectedReimbursedValidationMap }
