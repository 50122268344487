import { memo } from 'react'

import FormModifierProviderWrapper from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import {
  defaultAAgreementOnCreationOtherEstateObjectsFormModifierValues,
  defaultAAgreementOnCreationOtherEstateObjectsFormValues,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import AAgreementOnCreationOtherEstateObjectsLayout from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Layout'

const AAgreementOnCreationOtherEstateObjectsLayoutWithFormProvider = (
  props: FormModalLayoutProps,
) => {
  return (
    <FormProviderWrapper defaultValues={defaultAAgreementOnCreationOtherEstateObjectsFormValues}>
      <FormModifierProviderWrapper
        defaultValues={defaultAAgreementOnCreationOtherEstateObjectsFormModifierValues}
      >
        <AAgreementOnCreationOtherEstateObjectsLayout {...props} />
      </FormModifierProviderWrapper>
    </FormProviderWrapper>
  )
}

export default memo(AAgreementOnCreationOtherEstateObjectsLayoutWithFormProvider)
