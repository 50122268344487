import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useParametersOfCostRecoveryApplicationManager as useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'

import { mapOfParametersOfCostRecoveryApplicationMenu } from '../const'
import ParametersOfCostRecoveryApplicationForms from '../Forms'

const mapOfParametersOfCostRecoveryApplicationForms: FormItemProps = {
  '1': {
    node: <ParametersOfCostRecoveryApplicationForms.First />,
  },
  '2': {
    node: <ParametersOfCostRecoveryApplicationForms.Second />,
  },
  '3': {
    node: <ParametersOfCostRecoveryApplicationForms.Third />,
  },
  '4': {
    node: <ParametersOfCostRecoveryApplicationForms.Fourth />,
  },
  '5': {
    node: <ParametersOfCostRecoveryApplicationForms.Fifth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const ParametersOfCostRecoveryApplicationForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<ParametersOfCostRecoveryApplicationFormValues>()

  const {
    state: { formIsLoading },
  } = useParametersOfCostRecoveryApplicationManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfParametersOfCostRecoveryApplicationMenu}
      mapOfForms={mapOfParametersOfCostRecoveryApplicationForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(ParametersOfCostRecoveryApplicationForm)
