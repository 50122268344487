import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { Path, useForm } from 'react-hook-form'
import { PopupProps } from 'react-popup-manager'

import Loader from '@components/Loader'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import { ControlledMultipleSelect, ControlledSingleSelect } from '@components/NewDesign/Select'
import { getActualProjectAttributeByName } from '@components/Projects/[id]/helpers'
import {
  useAddMemberModalInfo,
  useOrganizationMembers,
} from '@components/Projects/[id]/ParticipantsOfStatement/hooks'
import MunicipalityOptionsTailNode from '@containers/MunicipalityOptionsTailNode'
import { useAPIContext } from '@context/APIContext'
import { isEmptyString, isString } from '@helpers/checkTypes'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useMunicipalitySelectConfig } from '@hooks/useMunicipalitySelectConfig'
import { IPostAttributeBody } from '@services/Attribute/Attribute.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './CreateMembersModal.module.scss'

export type TCreateMembersModal = {
  isProjectFederal: boolean
  isDocumentChange: boolean
  onAddMembers: VoidFunction
  projectId?: string
}

interface CreateMemberFormValues {
  subjects?: string[]
  subjectsExtra?: string[]
  municipality?: string[]
}

export type SignModalProps = Required<PopupProps> & TCreateMembersModal

const CreateMembersModal: FC<SignModalProps> = ({
  isOpen,
  projectId,
  isProjectFederal,
  isDocumentChange,
  onAddMembers,
  onClose,
}) => {
  const {
    projectsApi: { changeProjectAttribute },
  } = useAPIContext()

  const {
    subjectsToEdit: subjects,
    subjectsExtraToEdit: subjectsExtra,
    municipalityMembers,
    projectAttributes,
  } = useAddMemberModalInfo(projectId || '')

  const { preparedOrganizationsForSelect, onSelectContainerScroll } = useOrganizationMembers()

  const isShowSubjects = isProjectFederal || !isDocumentChange
  const isShowMunicipality = !isProjectFederal

  const subjectsAttributes =
    projectAttributes && getActualProjectAttributeByName('subjects', projectAttributes)

  const {
    formState: { isSubmitting, isValid },
    control,
    watch,
    reset,
    setValue,
  } = useForm<CreateMemberFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      municipality: municipalityMembers.ids,
      subjectsExtra: subjectsExtra.length ? subjectsExtra : [],
      ...(isShowSubjects && {
        subjects: subjects.length ? subjects : [],
      }),
    },
  })

  const formValues = watch()

  const previousFormValues = useRef<CreateMemberFormValues>(formValues)

  useEffect(() => {
    reset({
      municipality: municipalityMembers.ids,
      subjects,
      subjectsExtra,
    })
  }, [municipalityMembers.ids, reset, subjects, subjectsExtra])

  const { mutate: revalidateProjectAttributes, isLoadingProjectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'CreateMembersModal',
          moduleName: 'useDocumentActions',
        },
      }),
    },
  })

  const {
    municipalityData: { error, isMunicipalityLoading },
    onChangeSearchValue,
    onContentScroll,
    preparedMunicipalityOption,
  } = useMunicipalitySelectConfig({
    isProjectFederal,
  })

  const handleSetAttribute = useCallback(
    (name: IPostAttributeBody['name']) => async () => {
      const formName = name as Path<CreateMemberFormValues>

      const value = watch(formName)

      try {
        await changeProjectAttribute(projectId || '', {
          name,
          value: isString(value) ? value : JSON.stringify(value),
        })

        previousFormValues.current = { ...previousFormValues.current, [formName]: value }
      } catch (error) {
        const additionInfo = {
          projectAttributeName: name,
          projectAttributeValue: value,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'CreateMembersModal',
            moduleName: 'useDocumentActions',
            componentType: 'chooseMembers',
          },
        })(error)

        setValue(formName, previousFormValues.current[formName])

        throw error
      }
    },
    [changeProjectAttribute, projectId, setValue, watch],
  )

  const onFieldRemoveChip = useCallback(
    (name: IPostAttributeBody['name']) => async (payload: string[], _removedValue: string) => {
      const formName = name as Path<CreateMemberFormValues>

      try {
        await changeProjectAttribute(projectId || '', {
          name,
          value: JSON.stringify(payload),
        })

        previousFormValues.current = { ...previousFormValues.current, [formName]: payload }
      } catch (error) {
        const additionInfo = {
          projectAttributeName: name,
          projectAttributeValue: payload,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'EditParticipantsOfStatementMode',
            moduleName: 'ParticipantsOfStatement',
            componentType: 'chooseMembers',
          },
        })(error)

        setValue(formName, previousFormValues.current[formName])

        throw error
      }
    },
    [changeProjectAttribute, projectId, setValue, watch],
  )

  const handleChangeSingleFormValue = useCallback(
    (name: IPostAttributeBody['name']) => async (value: string) => {
      const formName = name as Path<CreateMemberFormValues>

      const preparedValue: string[] = isEmptyString(value) ? [] : [value]

      try {
        await changeProjectAttribute(projectId || '', {
          name,
          value: JSON.stringify(preparedValue),
        })

        previousFormValues.current = { ...previousFormValues.current, [formName]: preparedValue }
      } catch (error) {
        const additionInfo = {
          projectAttributeName: name,
          projectAttributeValue: value,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'CreateMembersModal',
            moduleName: 'useDocumentActions',
            componentType: 'chooseMembers',
          },
        })(error)

        setValue(formName, previousFormValues.current[formName])

        throw error
      }
    },
    [changeProjectAttribute, projectId, setValue],
  )

  const handleModalClose = useCallback(async () => {
    onClose()
    await revalidateProjectAttributes()
  }, [onClose, revalidateProjectAttributes])

  const actions = useMemo(() => {
    return [
      {
        view: 'gray',
        dataTestId: 'CreateMembersModal-cancel-button',
        children: 'Отмена',
        onClick: handleModalClose,
      },
      {
        dataTestId: 'CreateMembersModal-addMember-button',
        children: 'Продолжить',
        disabled: !isValid || isSubmitting,
        onClick: onAddMembers,
      },
    ] as ButtonProps[]
  }, [onAddMembers, handleModalClose, isSubmitting, isValid])

  const currentModalTitle =
    subjectsAttributes?.type === 'List' && subjectsAttributes.value.length
      ? 'Подтвердите участников соглашения'
      : 'Укажите участников соглашения'

  return (
    <Modal.Action
      simpleModalContainerClassName={styles.box__container}
      simpleModalTitleClassName={styles['box__container-title']}
      actionModalContentClassName={styles['box__container-content']}
      actions={actions}
      dataTestId="CreateMembersModal-modal"
      closeButtonDataTestId="CreateMembersModal-modal-closeButton"
      title={currentModalTitle}
      isOpen={isOpen}
      onClose={handleModalClose}
    >
      <Loader
        loading={isLoadingProjectAttributes}
        dataTestId="CreateMembersModal-loader"
        variant={'lite'}
      >
        <div className={styles.createMembersModal__wrapper}>
          {isProjectFederal && (
            <div className={styles.subject}>
              <ControlledMultipleSelect
                withContextSearch
                closeOptionsAfterOnChange
                options={preparedOrganizationsForSelect}
                popoverProps={{
                  zIndex: 51,
                }}
                controllerProps={{
                  control,
                  name: 'subjects',
                  rules: {
                    required: true,
                  },
                }}
                inputProps={{
                  fixWidth: true,
                  label: 'Субъект(-ы) РФ, на территории которого(-ых) реализуется проект',
                  view: 'secondary',
                  dataTestId: 'CreateMembersModal-subjects-multipleSelect',
                  formControlDataTestId: 'CreateMembersModal-subjectsFormControl',
                }}
                chipProps={{
                  dataTestId: 'CreateMembersModal-subjects-chip',
                  iconDataTestId: 'CreateMembersModal-subjects-remove-chip',
                }}
                optionsContainer={{
                  onScroll: onSelectContainerScroll,
                }}
                optionsProps={{
                  dataTestId: 'CreateMembersModal-subjects-optionsContainer',
                }}
                onBlurForm={handleSetAttribute('subjects')}
                onRemoveChip={onFieldRemoveChip('subjects')}
              />
            </div>
          )}
          {!isProjectFederal && !isDocumentChange && (
            <div className={styles.subject}>
              <ControlledSingleSelect
                withContextSearch
                options={preparedOrganizationsForSelect}
                controllerProps={{
                  control,
                  name: 'subjects',
                }}
                popoverProps={{
                  zIndex: 51,
                }}
                inputProps={{
                  fixWidth: true,
                  label: 'Субъект РФ, на территории которого реализуется проект',
                  caption:
                    'в случае, если субъектов РФ-сторон соглашения несколько, выбранный регион будет координировать рассмотрение документов',
                  view: 'secondary',
                  dataTestId: 'CreateMembersModal-subjects-single-select',
                }}
                optionsContainer={{
                  onScroll: onSelectContainerScroll,
                }}
                onChangeFormValue={handleChangeSingleFormValue('subjects')}
              />
            </div>
          )}
          {!isProjectFederal && !isDocumentChange && (
            <div className={styles.subject}>
              <ControlledMultipleSelect
                withContextSearch
                options={preparedOrganizationsForSelect}
                controllerProps={{
                  control,
                  name: 'subjectsExtra',
                }}
                popoverProps={{
                  zIndex: 51,
                }}
                inputProps={{
                  fixWidth: true,
                  label: 'Иные субъекты РФ, на территории которых реализуется проект',
                  caption:
                    'указываются, если сторонами соглашения являются более одного субъекта РФ',
                  view: 'secondary',
                  dataTestId: 'CreateMembersModal-subjectsExtra-multiple-select',
                }}
                chipProps={{
                  dataTestId: 'CreateMembersModal-subjectsExtra-chip',
                  iconDataTestId: 'CreateMembersModal-subjectsExtra-delete-chip',
                }}
                optionsContainer={{
                  onScroll: onSelectContainerScroll,
                }}
                onBlurForm={handleSetAttribute('subjectsExtra')}
                onRemoveChip={onFieldRemoveChip('subjectsExtra')}
              />
            </div>
          )}
          {isShowMunicipality && (
            <div className={styles.municipality}>
              <ControlledMultipleSelect
                withInput
                disabled={!!error}
                defaultOptionsSelected={municipalityMembers.defaultOptionsSelected}
                options={preparedMunicipalityOption}
                optionsContainer={{
                  onScroll: onContentScroll,
                }}
                popoverProps={{
                  zIndex: 51,
                }}
                controllerProps={{
                  control,
                  name: 'municipality',
                }}
                inputProps={{
                  fixWidth: true,
                  label: 'Муниципальное образование (если участвует в соглашении)',
                  caption:
                    'являются участниками, если у них возникают обязательства по стабилизируемым актам или возмещению',
                  dataTestId: 'CreateMembersModal-municipality-multipleSelect',
                  formControlDataTestId: 'CreateMembersModal-municipalityFormControl',
                }}
                optionsProps={{
                  isLoading: isMunicipalityLoading,
                  dataTestId: 'CreateMembersModal-municipality-optionsContainer',
                  tailNode: (
                    <MunicipalityOptionsTailNode
                      municipalityOptionsLength={preparedMunicipalityOption.length}
                    />
                  ),
                }}
                onChangeSearchValue={onChangeSearchValue}
                onBlurForm={handleSetAttribute('municipality')}
                onRemoveChip={onFieldRemoveChip('municipality')}
              />
            </div>
          )}
        </div>
      </Loader>
    </Modal.Action>
  )
}

export default memo(CreateMembersModal)
