import React, { type MouseEvent, FC, memo, useCallback, useReducer, useRef } from 'react'
import { DropzoneRef, FileRejection } from 'react-dropzone'

import FileItemLeadingNode from '@components/Attachments/Attachment/FileItem/LeadingNode'
import {
  fileItemCreators,
  fileItemReducer,
  initialFileItemState,
} from '@components/Attachments/Attachment/FileItem/reducer'
import FileItemTrailingNode from '@components/Attachments/Attachment/FileItem/TrailingNode'
import EntityItem, { EntityItemProps, IDropzone } from '@components/EntityItem'
import type { EntityItemActionsProps } from '@components/EntityItem/Actions'
import FileDropzone from '@components/FileDropzone'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import { rejectedFileErrorCodesMap } from '@constants/documents'
import { MimeTypes } from '@constants/types'
import { useHover } from '@hooks/new/events/useHover'
import cn from 'classnames'

import FileItemActions from './Actions'
import styles from './FileItem.module.scss'
import type {
  DownloadProps,
  FileItemDropzoneProps,
  FileItemLeadingAdditionProps,
  FileItemReducerState,
  FileItemTrailingAdditionProps,
  RejectFileProps,
  SignFileProps,
} from './types'

interface FileItemProps {
  onLoadFile?: (file: File) => Promise<void>
  isDownloadForbidden?: boolean
  readOnly?: boolean
  hasDocumentTemplateType?: boolean
  contentNode?: string
  initialFileState?: FileItemReducerState
  fileState?: FileItemReducerState
  downloadProps?: DownloadProps
  signFileProps?: SignFileProps
  trailingAdditionProps?: FileItemTrailingAdditionProps
  leadingAdditionProps?: FileItemLeadingAdditionProps
  entityProps?: Pick<
    EntityItemProps,
    'className' | 'trailingClassName' | 'leadingClassName' | 'onClick' | 'dataTestId'
  >
  rejectFileProps?: RejectFileProps
  dropzoneProps?: FileItemDropzoneProps
  onDropSuccess?: (file: File) => void
  onDropError?: (rejectedFile: FileRejection[], files?: File[]) => void
  onFileEdit?: (event: MouseEvent<HTMLButtonElement>) => void
  onFileRemove?: () => Promise<void>
  onFileNotLoadedRemove?: VoidFunction
  onFileReplace?: VoidFunction
  onFileReload?: VoidFunction
  onDownloadTemplate?: VoidFunction
}

const FileItem: FC<FileItemProps> = ({
  dropzoneProps,
  initialFileState,
  fileState,
  isDownloadForbidden,
  readOnly,
  hasDocumentTemplateType,
  contentNode,
  downloadProps,
  signFileProps,
  entityProps,
  rejectFileProps,
  trailingAdditionProps,
  leadingAdditionProps,
  onLoadFile,
  onFileEdit,
  onFileRemove,
  onFileNotLoadedRemove,
  onFileReplace,
  onFileReload,
  onDropSuccess,
  onDropError,
  onDownloadTemplate,
}) => {
  const {
    multiple = false,
    accept = MimeTypes,
    onDrop,
    onRejectDrop,
    validator,
    ...restDropzoneProps
  } = dropzoneProps || {}
  const { signCondition, onFileDownload, downloadTooltipContent } = downloadProps || {}
  const { onFileDropSign, signFileTooltipContent } = signFileProps || {}
  const { statusProps, additionalLeadingNodeContent } = leadingAdditionProps || {}
  const { dataTestId } = entityProps || {}
  const { className: rejectFileClassName } = rejectFileProps || {}

  const [state, dispatch] = useReducer(fileItemReducer, initialFileState || initialFileItemState)
  const dropZoneRef = useRef<DropzoneRef | null>(null)

  const {
    state: { tooltipIsOpen: downloadTooltipIsOpen, targetTooltipRef: targetDownloadTooltipRef },
    handlers: {
      handleOpenTooltip: handleOpenDownloadTooltip,
      handleCloseTooltip: handleCloseDownloadTooltip,
    },
  } = useManualTooltipControl()

  const [hoverRef, isHovered] = useHover<HTMLDivElement>()

  const uncontrolledItem = !fileState
  const currentState = fileState ?? state

  const bindDropzoneRef = (ref: DropzoneRef | null) => {
    if (!ref || !dropZoneRef) return

    dropZoneRef.current = ref
  }

  const handleSuccessDrop = (fileToUpload: File) => {
    dispatch(fileItemCreators.setFile(fileToUpload))
  }

  const handleLoadFile = useCallback(
    async (file: File) => {
      dispatch(fileItemCreators.setLoading(true))
      try {
        await onLoadFile?.(file)
      } catch (error) {
        dispatch(fileItemCreators.setError(error))
        throw error
      } finally {
        dispatch(fileItemCreators.setLoading(false))
      }
    },
    [onLoadFile],
  )

  const currentLoadHandler = uncontrolledItem ? handleLoadFile : onLoadFile
  const currentSuccessDrop = uncontrolledItem ? handleSuccessDrop : onDropSuccess

  //Accepted files в данном случае - 1 элемент, т.к контрол вмещает только 1 файл.
  const handleDropFile = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length || acceptedFiles.length > 1)
        return onDropError?.(rejectedFiles, acceptedFiles)

      const fileToUpload = acceptedFiles[0]

      currentSuccessDrop?.(fileToUpload)

      try {
        return await currentLoadHandler?.(fileToUpload)
      } catch (e) {
        throw e
      }
    },
    [currentLoadHandler, currentSuccessDrop, onDropError],
  )

  const handleRejectDropFile = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length || acceptedFiles.length > 1)
        return onDropError?.(rejectedFiles, acceptedFiles)

      const fileToUpload = acceptedFiles[0]

      currentSuccessDrop?.(fileToUpload)

      try {
        return await currentLoadHandler?.(fileToUpload)
      } catch (e) {
        throw e
      }
    },
    [currentLoadHandler, currentSuccessDrop, onDropError],
  )

  const handleReloadFile = async () => {
    if (!state.file) return

    dispatch(fileItemCreators.setError(null))
    try {
      await handleLoadFile(state.file)
    } catch (e) {
      throw e
    }
  }

  const handleRemoveFile = async () => {
    if (!state.file) return

    dispatch(fileItemCreators.setError(null))

    try {
      dispatch(fileItemCreators.setFile(null))
      await onFileRemove?.()
    } catch (e) {
      dispatch(fileItemCreators.setFile(state.file))
      dispatch(fileItemCreators.setError(e))
      throw e
    }
  }

  const handleFileDownload = async () => {
    try {
      onFileDownload?.()
    } catch (e) {
      throw e
    }
  }

  const handleRemoveNotLoadedFile = () => {
    dispatch(fileItemCreators.setFile(null))
    dispatch(fileItemCreators.setError(null))
    dispatch(fileItemCreators.setLoading(false))
  }

  const handleEnableEditModeOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    onFileEdit?.(event)
  }

  const handleOpenDropzoneOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    onFileReplace?.()

    dropZoneRef.current?.open()
  }

  const currentOnDropHandler = uncontrolledItem ? handleDropFile : onDrop
  const currentOnRejectDropHandler = uncontrolledItem ? handleRejectDropFile : onRejectDrop

  const currentDownloadHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (uncontrolledItem) return handleFileDownload()

    onFileDownload?.()
  }

  const handleDownloadActionClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    handleOpenDownloadTooltip()
  }

  const currentRemoveHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (uncontrolledItem) return handleRemoveFile()

    onFileRemove?.()
  }

  const currentReloadHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (uncontrolledItem) return handleReloadFile()

    onFileReload?.()
  }

  const currentNotLoadedRemoveFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (uncontrolledItem) return handleRemoveNotLoadedFile()

    onFileNotLoadedRemove?.()
  }

  const generalIconProps = {
    className: styles['fileItem__actions-icon'],
  }

  const tooltipRenderCondition = isHovered || downloadTooltipIsOpen
  const preparedDropzoneProps: IDropzone = {
    dropZoneProps: {
      ...restDropzoneProps,
      multiple,
      accept,
      onDrop: currentState.rejectedFile ? currentOnRejectDropHandler : currentOnDropHandler,
      validator,
    },
  }

  const currentActionsProps: EntityItemActionsProps = {
    isOpen: tooltipRenderCondition,
    actions: !currentState.errorMessage ? (
      <div
        className={cn(styles.fileItem__actions, {
          [styles['fileItem__actions--open']]: tooltipRenderCondition,
        })}
      >
        <div className={styles['fileItem__actions-gradient']} />
        {!readOnly && onFileEdit && (
          <FileItemActions.Edit
            icon={generalIconProps}
            dataTestId={dataTestId && `${dataTestId}-action-edit`}
            onClick={handleEnableEditModeOnClick}
          />
        )}
        {onFileDownload && (
          <Tooltip
            open={downloadTooltipIsOpen}
            targetRef={targetDownloadTooltipRef}
            position={'bottom-start'}
            content={downloadTooltipContent}
            arrowClassName={styles.tooltip__arrow}
            popoverClassName={styles.tooltip__popover}
            contentClassName={cn(styles.tooltip__content, styles['tooltip__content-download'])}
          >
            <FileItemActions.Download
              icon={generalIconProps}
              disabled={currentState.isLoading}
              dataTestId={dataTestId && `${dataTestId}-action-download`}
              onBlur={handleCloseDownloadTooltip}
              onClick={signCondition ? handleDownloadActionClick : currentDownloadHandler}
            />
          </Tooltip>
        )}
        {!readOnly && onFileReplace && (
          <FileItemActions.Replace
            disabled={currentState.isLoading}
            dataTestId={dataTestId && `${dataTestId}-action-replace`}
            icon={generalIconProps}
            onClick={handleOpenDropzoneOnClick}
          />
        )}
        {onFileDropSign && (
          <Tooltip
            content={signFileTooltipContent}
            position="top"
            fallbackPlacements={['top', 'top-end']}
          >
            <FileItemActions.Sign
              disabled={currentState.isLoading}
              icon={generalIconProps}
              dataTestId={dataTestId && `${dataTestId}-action-sign`}
              onDropSign={onFileDropSign}
            />
          </Tooltip>
        )}
        {!readOnly && onFileRemove && (
          <FileItemActions.Remove
            disabled={currentState.isLoading}
            icon={generalIconProps}
            dataTestId={dataTestId && `${dataTestId}-action-remove`}
            onClick={currentRemoveHandler}
          />
        )}
      </div>
    ) : (
      <>
        {onFileReload && (
          <div className={styles.fileItem__actions}>
            <FileItemActions.Reload
              icon={generalIconProps}
              dataTestId={dataTestId && `${dataTestId}-action-reload`}
              onClick={currentReloadHandler}
            />
            <FileItemActions.Remove
              icon={generalIconProps}
              dataTestId={dataTestId && `${dataTestId}-action-cancel`}
              onClick={currentNotLoadedRemoveFile}
            />
          </div>
        )}
      </>
    ),
  }

  if (!currentState.file && !!readOnly) return null

  if (currentState.file)
    return (
      <EntityItem
        {...entityProps}
        dropZone={preparedDropzoneProps}
        dropZoneRef={bindDropzoneRef}
        entityItemRef={hoverRef}
        mainContentClassName={styles.fileItem__mainContent}
        actionsProps={isDownloadForbidden ? undefined : currentActionsProps}
        leadingClassName={cn(styles.fileItem__leadingNode, entityProps?.leadingClassName)}
        className={cn(
          styles['fileItem__entityItem'],
          {
            [styles['fileItem__entityItem--deleted']]: currentState.isDeletedFile,
          },
          entityProps?.className,
        )}
        trailingAddition={
          <FileItemTrailingNode {...trailingAdditionProps} fileState={currentState} />
        }
        leadingAddition={
          <FileItemLeadingNode
            additionalLeadingNodeContent={additionalLeadingNodeContent}
            statusProps={statusProps}
          />
        }
        trailingClassName={cn(
          styles.fileItem__metaInfo,
          {
            [styles['fileItem__metaInfo--loading']]: currentState.isLoading,
          },
          entityProps?.trailingClassName,
        )}
        mainContentNode={
          <TypographyWithTooltip.Body
            variant={'bodyMMedium'}
            color={currentState.isDeletedFile ? 'text-base-tertiary' : ''}
            dataTestId={dataTestId && `${dataTestId}-fileName`}
            tooltipProps={{
              position: 'top',
              offset: [0, 10],
              targetClassName: styles['fileItem__tooltip-target'],
              contentClassName: styles['fileItem__tooltip-content'],
            }}
          >
            {contentNode || currentState.file.name}
          </TypographyWithTooltip.Body>
        }
      />
    )

  if (currentState.rejectedFile)
    return (
      <FileDropzone.ErrorPrimary
        className={cn(styles.fileItem__rejectedFile, rejectFileClassName)}
        rejectedFile={currentState.rejectedFile.file}
        titleReject={currentState.rejectedFile.message}
        buttonReject={currentState.rejectedFile.buttonMessage}
        subTitleReject={'Перетащите файл'}
        disableTooltipButton={
          rejectedFileErrorCodesMap.FILE_INVALID_TYPE !== currentState.rejectedFile.code
        }
        {...preparedDropzoneProps}
      />
    )

  return (
    <FileDropzone.Primary
      title={contentNode}
      documentTemplateType={hasDocumentTemplateType}
      onDownloadTemplateClick={onDownloadTemplate}
      {...preparedDropzoneProps}
    />
  )
}

export type { FileItemProps }

export default memo(FileItem)
