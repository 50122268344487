import React from 'react'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { PrefixFormFieldNames } from '@components/DocumentFormComponents/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface AuthorizedOfficialGroupProps<T extends FieldValues> {
  formInstance: UseFormReturn<T>
  formSection: string
  prefixFormFieldName: PrefixFormFieldNames
  disableBottomBorder?: boolean
  groupTitle?: string
  children?: React.ReactNode
}

const AuthorizedOfficialGroup = <T extends FieldValues>({
  disableBottomBorder = true,
  groupTitle,
  formInstance,
  formSection,
  prefixFormFieldName,
  children,
}: AuthorizedOfficialGroupProps<T>) => {
  const { getInputProps } = useFormComponentPresets({
    formInstance,
  })

  return (
    <Group disableBottomBorder={disableBottomBorder} title={groupTitle}>
      <Stack direction={'vertical'} gap={3}>
        <ContainerWithBorder disablePaddingTop>
          {children && <Col xs={6}>{children}</Col>}
          <Col xs={!!children ? 6 : 12}>
            <ControlledInput
              {...getInputProps({
                name: `${formSection}.${prefixFormFieldName}HeaderPosition` as Path<T>,
                inputProps: {
                  disabled: true,
                  dataTestId: 'headerPosition',
                  label: 'Должность',
                },
              })}
            />
          </Col>
        </ContainerWithBorder>
        <Row>
          <Col xs={6}>
            <ControlledInput
              {...getInputProps({
                name: `${formSection}.${prefixFormFieldName}HeaderNameGenitive` as Path<T>,
                inputProps: {
                  disabled: true,
                  label: 'Фамилия, Имя, Отчество',
                  dataTestId: 'headerNameGenitive',
                  caption: 'в родительном падеже',
                },
              })}
            />
          </Col>
          <Col xs={6}>
            <ControlledInput
              {...getInputProps({
                name: `${formSection}.${prefixFormFieldName}HeaderPositionGenitive` as Path<T>,
                inputProps: {
                  disabled: true,
                  label: 'Должность',
                  caption: 'в родительном падеже',
                  dataTestId: 'headerPositionGenitive',
                },
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ControlledInput
              {...getInputProps({
                name: `${formSection}.${prefixFormFieldName}HeaderReasonGenitive` as Path<T>,
                inputProps: {
                  disabled: true,
                  label: 'Документ, удостоверяющий полномочия',
                  dataTestId: 'headerReasonGenitive',
                  caption: 'в родительном падеже',
                },
              })}
            />
          </Col>
        </Row>
      </Stack>
    </Group>
  )
}

export default AuthorizedOfficialGroup
