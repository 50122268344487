import React from 'react'

import { useMoveItem } from '@components/DocumentFormComponents/FieldArraySwapper/hooks'
import { FieldArraySwapperProps } from '@components/DocumentFormComponents/FieldArraySwapper/types'
import Loader from '@components/Loader'
import IconButton from '@components/NewDesign/IconButton'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from './FieldArraySwapper.module.scss'

const FieldArraySwapper = <T,>({
  index,
  lastIndex,
  list,
  onMoveTop,
  onMoveBottom,
  className,
  ...restProps
}: FieldArraySwapperProps<T>) => {
  const isFirstItemInList = index === 0
  const isLastItemInList = index === lastIndex

  const { isMoveBottomLoading, isMoveTopLoading, onMoveTopButtonClick, onMoveBottomButtonClick } =
    useMoveItem({
      index,
      list,
      onMoveTop,
      onMoveBottom,
    })

  return (
    <div {...restProps} className={cn(styles['fieldItemSwapper__button-wrapper'], className)}>
      <Loader
        loading={isMoveTopLoading}
        variant="lite"
        wrapperClassName={cn(
          styles['fieldItemSwapper__button-loader'],
          styles['fieldItemSwapper__button-loader--top'],
        )}
      >
        <IconButton
          disabled={isFirstItemInList || isMoveBottomLoading}
          view="tined"
          geometry="round"
          size="s"
          color="default"
          className={styles.fieldItemSwapper__button}
          icon={{
            className: cn(
              styles['fieldItemSwapper__button-icon'],
              styles['fieldItemSwapper__button-icon--top'],
            ),
            src: chevronRightIcon,
          }}
          onClick={onMoveTopButtonClick}
        />
      </Loader>
      <Loader
        loading={isMoveBottomLoading}
        variant="lite"
        wrapperClassName={cn(
          styles['fieldItemSwapper__button-loader'],
          styles['fieldItemSwapper__button-loader--bottom'],
        )}
      >
        <IconButton
          disabled={isLastItemInList || isMoveTopLoading}
          view="tined"
          geometry="round"
          size="s"
          color="default"
          className={styles.fieldItemSwapper__button}
          icon={{
            className: cn(
              styles['fieldItemSwapper__button-icon'],
              styles['fieldItemSwapper__button-icon--bottom'],
            ),
            src: chevronRightIcon,
          }}
          onClick={onMoveBottomButtonClick}
        />
      </Loader>
    </div>
  )
}

export default FieldArraySwapper
