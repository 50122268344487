import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AssignmentMonetaryOrPledgeAFormValues } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfAssignmentMonetaryOrPledgeAMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Передача денежных средств' },
]

const defaultAssignmentMonetaryOrPledgeAFormValues: AssignmentMonetaryOrPledgeAFormValues = {
  '1': {
    ...getDefaultInvestorFieldState(),
    investorInn: '',
    investorOgrn: '',
  },
  '2': {
    ruleLinkCession: '',
    cessionValue: '',
    cessionFormName: null,
    cessionReason: '',
    cessionMember: '',
    cessionAvailability: '',
    cessionDate: '',
  },
}

const assignmentMonetaryOrPledgeABlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorHeaderName: '1.investorHeaderName',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
  },
  '2': {
    ruleLinkCession: '2.ruleLinkCession',
    cessionValue: '2.cessionValue',
    cessionFormName: '2.cessionFormName',
    cessionReason: '2.cessionReason',
    cessionMember: '2.cessionMember',
    cessionAvailability: '2.cessionAvailability',
    cessionDate: '2.cessionDate',
  },
} as const

export {
  assignmentMonetaryOrPledgeABlockValues,
  defaultAssignmentMonetaryOrPledgeAFormValues,
  mapOfAssignmentMonetaryOrPledgeAMenu,
}
