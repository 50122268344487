import Divider from '@components/Divider'
import PublicWrapper from '@components/PublicWrapper'
import cn from 'classnames'

import { politicList } from './mapping'
import styles from './Politics.module.scss'

const PoliticsPage = () => {
  return (
    <PublicWrapper
      title="Политика обработки персональных данных"
      position="center"
      variant="default"
      wrapperClassName={styles.politics_wrapper}
      titleClassName={cn(styles.title, styles.space)}
      containerSize="md"
    >
      <Divider spacing="preMedium" />
      <div className={styles.wrapper}>
        <ol>
          {politicList.map(({ id, title, description }) => (
            <div key={id}>
              {title && <h2 className={styles.subtitle}>{title}</h2>}
              {description && (
                <ol>
                  {description.map((item) => (
                    <div key={`${id}_${item.id}`}>
                      <div className={styles.description}>{item.title}</div>
                      <ul>
                        {item.description.map((childItem, index) => (
                          <li key={`${id}_${item.id}_${index}`} className={styles.dash}>
                            {childItem}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </ol>
              )}
            </div>
          ))}
        </ol>
      </div>
    </PublicWrapper>
  )
}

export default PoliticsPage
