import { FC } from 'react'

import AttachmentItem from '@components/Attachments/Attachment'
import AttachmentsSetItems from '@components/Attachments/Items'
import AttachmentSetItemController from '@components/Attachments/Items/Controller'
import AttachmentsSet from '@components/Attachments/Set'

interface AttachmentsProps extends FC {
  Item: typeof AttachmentItem
  ItemController: typeof AttachmentSetItemController
  Set: typeof AttachmentsSet
  SetItems: typeof AttachmentsSetItems
}

const Attachments: AttachmentsProps = () => null

Attachments.Item = AttachmentItem
Attachments.ItemController = AttachmentSetItemController
Attachments.Set = AttachmentsSet
Attachments.SetItems = AttachmentsSetItems

export default Attachments
