const download = (link: string, fileName = '') => {
  const linkElement = document.createElement('a')

  linkElement.href = link

  linkElement.setAttribute('download', fileName)

  document.body.appendChild(linkElement)

  linkElement.click()
  linkElement.remove()
}

export default download
