import { IDfoDeadline } from '@services/Deadline/Deadline.entity'
import DeadlineService from '@services/Deadline/Deadline.service'

const { getDefaultFormattedDate, checkRenderTooltipByViewType } = DeadlineService

const useProjectsDeadline = (deadlineInfo?: IDfoDeadline | null) => {
  const isDeadlineRender = deadlineInfo && deadlineInfo.overdueDate
  const preparedDeadlineDate =
    isDeadlineRender &&
    deadlineInfo.overdueDate &&
    getDefaultFormattedDate(deadlineInfo.overdueDate)

  const deadlineTooltipIsRender =
    deadlineInfo?.viewType && checkRenderTooltipByViewType(deadlineInfo?.viewType)

  return {
    isDeadlineRender,
    preparedDeadlineDate,
    deadlineTooltipIsRender,
  }
}

export { useProjectsDeadline }
