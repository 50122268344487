import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  InformationOfExecutionAgreementEventsFormValues,
  IStepMonitoring,
} from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const stepMonitoringValidationMap: FormValuesValidationSection<
  keyof IStepMonitoring,
  InformationOfExecutionAgreementEventsFormValues
> = {
  notContainsCompletedEventsComment: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 5000) : undefined),
  },
}

export { stepMonitoringValidationMap }
