import React from 'react'
import { useFormContext } from 'react-hook-form'

import SubjectsSection from '@components/DocumentFormComponents/FormSections/SubjectsSection'
import { additionalAgreementOnTerminationFfSZPKValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/const'
import { useAdditionalAgreementOnTerminationFfSZPKManager } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/Manager'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'
import { AdditionalAgreementOnTerminationFfSZPKFieldsControlUpdateWatcher } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/watcher'

const currentBlockValues = additionalAgreementOnTerminationFfSZPKValues['2']

const Second = () => {
  const formInstance = useFormContext<AdditionalAgreementOnTerminationFfSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAdditionalAgreementOnTerminationFfSZPKManager()

  if (!formInstance) return null

  return (
    <SubjectsSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={currentBlockValues.subjects}
      formInstance={formInstance}
      watcher={AdditionalAgreementOnTerminationFfSZPKFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
