import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidExploitation,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const ridExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidExploitation,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  ridName: {
    required: defaultRHFValidation.required,
  },
  ridCommissioningDate: {
    required: defaultRHFValidation.required,
  },
  ridCommissionNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { ridExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
