import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import AnotherObjectForm from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/AdditionalForms/AnotherObjectForm'
import {
  EighthAnotherStagesArrayPathName,
  EighthAnotherStagesPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/types'
import { eighthSectionValidationMap } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/validation'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { OptionProps } from '@components/NewDesign/Select/model'
import ControlledSingleSelectNew from '@components/NewDesign/Select/SingleSelectNew/Controlled'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

interface AnotherStageProps {
  indexOfStep: number
  name: EighthAnotherStagesArrayPathName
  parentName: EighthAnotherStagesPathName
  objectName: string
  onSuccessSelectStepNumber: VoidFunction
}

const { validationAdapter } = DocumentFormHelpers

const AnotherStage: FC<AnotherStageProps> = ({
  name,
  indexOfStep,
  parentName,
  objectName,
  onSuccessSelectStepNumber,
}) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const conditionToRenderOtherFields = !!useWatch({
    name: `${name}.stepNumber`,
    control: formInstance.control,
  })

  const isOtherActivityInfrastructure = !!useWatch({
    name: `${name}.isOtherActivityInfrastructure`,
    control: formInstance.control,
  })

  const measureObjects = useWatch({
    name: `${name}.measureObjects`,
    control: formInstance.control,
  })

  const anotherStageOptions = useWatch({
    name: 'additionalFields.anotherStageOptions',
    control: formInstance.control,
  })

  const conditionToRenderOtherActivityField = !measureObjects.length

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      handleAddCustomValue,
      getPropertiesProps,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const isNew = useWatch({
    control: formModifierInstance.control,
    name: `${name}.isNew`,
  })

  const {
    getSubscribableControlProps,
    getInputProps,
    getCalendarInputProps,
    getSingleSelectProps,
    getSwitchProps,
    getFormFieldControlProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const stepEndDate = useWatch({
    name: `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${indexOfStep}.stepEndDate`,
    control: formInstance.control,
  })

  if (!formInstance) return null

  if (!conditionToRenderOtherFields)
    return (
      <Container className={'p-0'}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parentName,
              })}
            >
              <ControlledSingleSelectNew
                disabled={!editMode}
                options={anotherStageOptions}
                controllerProps={{
                  name: `${name}.stepNumber`,
                  control: formInstance.control,
                  rules: validationAdapter(eighthSectionValidationMap.stepNumber, {
                    form: formInstance,
                    name: `${name}.stepNumber`,
                    needTrigger: blockViewIsValidating,
                  }),
                }}
                inputProps={{
                  fixWidth: true,
                  view: 'secondary',
                  label: 'Номер этапа',
                }}
                onChangeFormValue={async (option: OptionProps | null) => {
                  const findOfStepActivityOthersProps = getObjectValue(
                    getPropertiesProps?.(),
                    '8.stepActivityOthers.value',
                  )?.find((stepActivity) => stepActivity.propertyId === option?.value)

                  if (!findOfStepActivityOthersProps) return

                  onSuccessSelectStepNumber()

                  await handleAddCustomValue?.({
                    id: findOfStepActivityOthersProps.propertyId,
                    lastUpdateDt: findOfStepActivityOthersProps.lastUpdateDt,
                  })
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Container>
    )

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={8}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.otherActivityName`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.otherActivityName`), 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${name}.otherActivityName`,
                  rules: eighthSectionValidationMap.otherActivityName,
                  inputProps: {
                    label: 'Наименование мероприятия',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.otherActivityName`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.otherActivityName`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
          <Col xs={4}>
            <ControlledSingleSelectNew
              disabled
              options={anotherStageOptions}
              controllerProps={{
                name: `${name}.stepNumber`,
                control: formInstance.control,
                rules: validationAdapter(eighthSectionValidationMap.stepNumber, {
                  form: formInstance,
                  name: `${name}.stepNumber`,
                  needTrigger: blockViewIsValidating,
                }),
              }}
              inputProps={{
                fixWidth: true,
                view: 'secondary',
                label: 'Номер этапа',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              titleHeightFallback="20px"
              title={'Срок завершения реализации иного мероприятия'}
            >
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.otherActivityEndDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.otherActivityEndDate`)
                      }, 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.otherActivityEndDate`,
                    rules: {
                      ...eighthSectionValidationMap.otherActivityEndDate,
                      validate: {
                        positiveStepEndDate: (value) => {
                          return (
                            (isDateValidForDayjs(value) &&
                              DayjsService.dayjs(value, objOfDateFormats.defaultFormat) <=
                                DayjsService.dayjs(stepEndDate, objOfDateFormats.defaultFormat)) ||
                            'дата завершения иного мероприятия должна быть меньше или равна дате окончания этапа'
                          )
                        },
                      },
                    },
                    calendarInputProps: {
                      label: 'ДД.ММ.ГГГГ',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.otherActivityEndDate`)
                      }, 0),
                    onInputChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.otherActivityEndDate`)
                      }, 0),
                    onCalendarChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.otherActivityEndDate`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </RowWithBorder>
          </Col>
        </Row>
        <CollapseWrapper
          defaultExpanded={conditionToRenderOtherActivityField}
          isExpanded={conditionToRenderOtherActivityField}
        >
          <Row>
            <Col xs={12}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.isOtherActivityInfrastructure`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.isOtherActivityInfrastructure`)
                      }, 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledSwitch
                  {...getSwitchProps({
                    name: `${name}.isOtherActivityInfrastructure`,
                    switchProps: {
                      label: 'Мероприятие относится к инфраструктуре',
                    },
                    onChange: () => handleChangeValue?.(`${name}.isOtherActivityInfrastructure`),
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
        </CollapseWrapper>
        <CollapseWrapper
          defaultExpanded={isOtherActivityInfrastructure}
          isExpanded={isOtherActivityInfrastructure}
        >
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.otherActivityInfrastructureTypeName`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item.name,
                          value: item.id,
                        }),
                        controllerProps: {
                          name: `${name}.otherActivityInfrastructureTypeName`,
                          rules: eighthSectionValidationMap.otherActivityInfrastructureTypeName,
                        },
                        selectProps: {
                          inputProps: {
                            dataTestId: 'otherActivityInfrastructureTypeName',
                            label: 'Тип инфраструктуры',
                          },
                          onChangeFormValue: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${name}.otherActivityInfrastructureTypeName`)
                            }),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
        </CollapseWrapper>
        <Row>
          <FormListTooltipControl
            disablePaddingRight
            enablePaddingLeft
            control={formModifierInstance.control}
            name={`${name}.measureObjects`}
            onDifference={handleUpdateChanges}
          >
            {({ iconWithTooltip }) => (
              <Group
                disableBottomBorder
                groupClassName={'p-2'}
                title={'Объекты иного мероприятия'}
                leftAddons={iconWithTooltip}
              >
                <Col xs={12}>
                  <AnotherObjectForm
                    isAddAnotherObjectButtonDisabled={isOtherActivityInfrastructure}
                    objectName={objectName}
                    name={name}
                  />
                </Col>
              </Group>
            )}
          </FormListTooltipControl>
        </Row>
      </Stack>
    </Container>
  )
}

export default AnotherStage
