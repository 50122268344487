import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { compact } from '@helpers/array/compact'
import { isNotEmptyString, isUndefined } from '@helpers/checkTypes'

const generateMenuListForAAVolumeOfCapitalInvestments = (
  formValues:
    | AAVolumeOfCapitalInvestmentsFormValues
    | UnpackNestedValue<AAVolumeOfCapitalInvestmentsFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<AAVolumeOfCapitalInvestmentsFormValues>,
): NestedMapOfMenu[] => {
  const getMenuSectionWithError = (numberSectionOfMenu: keyof NestedMenuHashMap) => {
    if (isUndefined(initialHashMap[numberSectionOfMenu])) return

    const preparedChildrenSections =
      initialHashMap[numberSectionOfMenu]?.children?.map((item) => ({
        ...item,
        hasError: !!formErrors?.[item.id],
      })) || []

    return {
      ...initialHashMap[numberSectionOfMenu],
      hasError:
        !!formErrors?.[numberSectionOfMenu] ||
        preparedChildrenSections.some((item) => item.hasError),
      children: preparedChildrenSections,
    }
  }

  const preparedCapitalSteps = formValues['5'].capitalSteps.map((step, index): NestedMapOfMenu => {
    const stepId = `${initialHashMap['5'].path}.capitalSteps.${index}`
    const stepError = formErrors['5']?.capitalSteps?.[index]

    return {
      id: step.anchorId,
      path: stepId,
      title: isNotEmptyString(step.stepDescription)
        ? step.stepDescription
        : `Мероприятие ${index + 1}`,
      isCollapsed: false,
      hasError: !!stepError,
      parentBaseId: initialHashMap['5'].id,
      children: [],
    }
  })

  const preparedHashMap: Record<string, NestedMapOfMenu | undefined> = {
    '1': getMenuSectionWithError('1'),
    '2': getMenuSectionWithError('2'),
    '3': getMenuSectionWithError('3'),
    '4': getMenuSectionWithError('4'),
    '6': getMenuSectionWithError('6'),
    '5': {
      ...initialHashMap['5'],
      hasError: preparedCapitalSteps.some(({ hasError }) => hasError),
      children: preparedCapitalSteps,
    },
  }

  return compact([
    preparedHashMap['1'],
    preparedHashMap['2'],
    preparedHashMap['3'],
    preparedHashMap['4'],
    preparedHashMap['5'],
    preparedHashMap['6'],
  ]) as NestedMapOfMenu[]
}

export { generateMenuListForAAVolumeOfCapitalInvestments }
