import React, { ReactNode } from 'react'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import Button from '@components/NewDesign/Button'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

import styles from './CollapseCardWithPreview.module.scss'

interface CollapseCardWithPreviewProps {
  isExpanded: boolean
  children: ReactNode
  previewNode: ReactNode
  onExpandForm: () => void
  onCollapseNode: () => void
  id?: string
}

const CollapseCardWithPreview = ({
  isExpanded,
  children,
  previewNode,
  onExpandForm,
  onCollapseNode,
  id = '1',
}: CollapseCardWithPreviewProps) => {
  return (
    <FieldView.StepTemplate id={id}>
      <Container className="p-0">
        <Stack direction={'vertical'} gap={3}>
          {previewNode}
          <CollapseWrapper isExpanded={isExpanded}>{children}</CollapseWrapper>
          <Button
            view={'tined'}
            className={styles['collapseCardWithPreview__collapse-button']}
            onClick={isExpanded ? onCollapseNode : onExpandForm}
          >
            {isExpanded ? 'Свернуть' : 'Развернуть'}
          </Button>
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default CollapseCardWithPreview
