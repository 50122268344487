import React from 'react'
import { useFormContext } from 'react-hook-form'

import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import RegistrationCertificateExtraRegisteredDocumentValueItem from '@components/Forms/RegistrationCertificateForm/Forms/6/ExtraRegisteredDocumentValueItem'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import {
  RegistrationCertificateFieldArrayControlUpdateWatcher,
  RegistrationCertificateFieldsControlUpdateWatcher,
} from '@components/Forms/RegistrationCertificateForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const Sixth = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const formModifierInstance = useFormModifierContext<RegistrationCertificateFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue, handleChangePublicValueInList },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { fields: extraRegisteredDocuments } = useFieldArraySubscribableControl<
    RegistrationCertificateFormValues,
    typeof registrationCertificateBlockValues['6']['extraRegisteredDocuments']
  >({
    control: formInstance.control,
    name: registrationCertificateBlockValues['6'].extraRegisteredDocuments,
    watcher: RegistrationCertificateFieldArrayControlUpdateWatcher,
  })

  const extraRegisteredDocumentsRenderCondition = !!extraRegisteredDocuments.length

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      {!extraRegisteredDocumentsRenderCondition && (
        <EmptyDataText text={'К СЗПК еще нет заключенных дополнительных соглашений'} />
      )}
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: registrationCertificateBlockValues['6'].extraRegisteredDocuments,
          })}
        >
          <FlipperList list={extraRegisteredDocuments}>
            {extraRegisteredDocuments.map((extraRegisteredDocument, index) => {
              const formName =
                `${registrationCertificateBlockValues['6'].extraRegisteredDocuments}.${index}` as const

              return (
                <SubscribableControl
                  key={extraRegisteredDocument.id}
                  {...getSubscribableControlProps({
                    path: formName,
                  })}
                >
                  <FlippedWithCollapse flipId={extraRegisteredDocument.id}>
                    <RegistrationCertificateExtraRegisteredDocumentValueItem
                      editMode={editMode}
                      blockViewIsValidating={blockViewIsValidating}
                      formName={formName}
                      formInstance={formInstance}
                      formModifierInstance={formModifierInstance}
                      subscribableControl={subscribableControl}
                      debouncedChangeValue={debouncedHandleChangeValue}
                      onChangeValue={handleChangeValue}
                      onPublicList={handleChangePublicValueInList}
                    />
                  </FlippedWithCollapse>
                </SubscribableControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
      </Stack>
    </Container>
  )
}

export default Sixth
