import React, { FC } from 'react'

import Container from '@components/Layout/Container'
import cn from 'classnames'

import styles from './Section.module.scss'

export interface ISection {
  type?: 'primary' | 'secondary'
  className?: string
  containerClassName?: string
}

const Section: FC<ISection> = ({ children, className, containerClassName, type = 'primary' }) => {
  return (
    <div
      className={cn(styles.section, className, {
        [styles[`section--${type}`]]: type,
      })}
    >
      <div className={cn(styles.sectionContainer, containerClassName)}>
        <Container>{children}</Container>
      </div>
    </div>
  )
}

export default Section
