import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'

import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from './types'

const { getDefaultRfFieldState, getDefaultMunicipalitiesFieldState, getDefaultInvestorFieldState } =
  FieldStateHelpers

const mapOfAAgreementOnChangingCharacteristicsOfObjectsMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация, реализующая проект' },
  { id: '5', title: 'Этапы' },
]

const defaultAAgreementOnChangingCharacteristicsOfObjectsFormValues: AAgreementOnChangingCharacteristicsOfObjectsFormValues =
  {
    '1': getDefaultRfFieldState(),
    '2': {
      subjects: [
        {
          id: '',
          subjectFullName: '',
          subjectNameGenitive: '',
          subjectHeaderName: null,
          subjectHeaderPosition: '',
          subjectHeaderNameGenitive: '',
          subjectHeaderPositionGenitive: '',
          subjectHeaderReasonGenitive: '',
        },
      ],
    },
    '3': {
      municipalities: getDefaultMunicipalitiesFieldState(),
    },
    '4': getDefaultInvestorFieldState(),
    '5': {
      constructionStages: [],
    },
    additionalFields: {
      projectEndDate: '',
      initialMunicipalities: [],
      isFederal: true,
      isPaper: false,
      allProjectObjectsLength: 0,
    },
  }

const defaultAAgreementOnChangingCharacteristicsOfObjectsFormModifierValues: AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues =
  {
    '5': {
      constructionStages: [],
    },
  }

const aAgreementOnChangingCharacteristicsOfObjectsBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorFullName: '4.investorFullName',
    investorHeaderName: '4.investorHeaderName',
    investorHeaderPosition: '4.investorHeaderPosition',
    investorHeaderNameGenitive: '4.investorHeaderNameGenitive',
    investorHeaderPositionGenitive: '4.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '4.investorHeaderReasonGenitive',
  },
  '5': {
    constructionStages: '5.constructionStages',
  },
  additionalFields: {
    projectEndDate: 'additionalFields.projectEndDate',
    isFederal: 'additionalFields.isFederal',
    isPaper: 'additionalFields.isPaper',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
    allProjectObjectsLength: 'additionalFields.allProjectObjectsLength',
  },
} as const

export {
  aAgreementOnChangingCharacteristicsOfObjectsBlockValues,
  defaultAAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  defaultAAgreementOnChangingCharacteristicsOfObjectsFormValues,
  mapOfAAgreementOnChangingCharacteristicsOfObjectsMenu,
}
