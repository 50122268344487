import React, { FC, memo } from 'react'

import Typography from '@components/NewDesign/Typography'

import styles from './DefaultSetHeader.module.scss'

interface DefaultSetHeaderProps {
  title: string
  subtitle?: string
}

const DefaultSetHeader: FC<DefaultSetHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.defaultSetHeader}>
      <Typography.Headline variant={'headlineH2'} as={'h2'}>
        {title}
      </Typography.Headline>
      {subtitle && <Typography.Body variant={'bodyXLMedium'}>{subtitle}</Typography.Body>}
    </div>
  )
}

export default memo(DefaultSetHeader)
