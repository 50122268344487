interface DefaultPropertiesProps {
  formId: string
}

const PropertyTypeEnumSimple = {
  STRING: 'STRING',
  INT: 'INT',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  DOUBLE: 'DOUBLE',
} as const
const PropertyTypeEnumStructure = {
  LIST: 'LIST',
  MAP: 'MAP',
} as const

const PropertyTypeEnumCustom = {
  MONEY: 'MONEY',
  PERCENTAGE: 'PERCENTAGE',
  LOOKUP: 'LOOK_UP',
  FIXED_LOOKUP: 'FIXED_LOOK_UP',
  CATALOG: 'CATALOG_VALUE',
  ORGANIZATION: 'ORGANIZATION',
  CUSTOM: 'CUSTOM',
  PRECONFIGURED: 'PRECONFIGURED',
  NPA: 'NPA',
  NPA_PART: 'NPA_PART',
} as const

const PropertyTypeEnum = {
  ...PropertyTypeEnumSimple,
  ...PropertyTypeEnumStructure,
  ...PropertyTypeEnumCustom,
} as const

type TPropertyKey = string

type TPropertyValueRequired = {
  '@id': string
  '@permanentId': string
  //date
  '@lastUpdateDt': string
  '@isPublic': boolean
  '@order': number | null
}

type TPropertyValuePartial = {
  '@new'?: boolean
}

type TPropertyValueString = {
  '@type': typeof PropertyTypeEnum['STRING'] | typeof PropertyTypeEnum['DATE']
  '@isPublic': boolean
  '@value': string
  '@commitedValue': string
}

type TPropertyValueNumber = {
  '@type': typeof PropertyTypeEnum['INT']
  '@isPublic': boolean
  '@value': number
  '@commitedValue': number
}

type TPropertyValueBoolean = {
  '@type': typeof PropertyTypeEnum['BOOLEAN']
  '@isPublic': boolean
  '@value': boolean
  '@commitedValue': boolean
}

type TPropertyValueList = {
  '@type': typeof PropertyTypeEnum['LIST']
  '@elementType': keyof typeof PropertyTypeEnum
  // Возможно, нужно поменять тип
  '@deletedElements': unknown[]
  '@isPublic': boolean
  '@value': TPropertyValue[]
}
type TPropertyValueMap = {
  '@isPublic': boolean
  '@type': typeof PropertyTypeEnum['MAP']
  '@value': TProperty
}

type TPropertyValueMoney = {
  '@isPublic': boolean
  '@type': typeof PropertyTypeEnum['MONEY']
  '@value': number
  '@commitedValue': number
}

type TPropertyValueDouble = {
  '@isPublic': boolean
  '@type': typeof PropertyTypeEnum['DOUBLE']
  '@value': number
  '@commitedValue': number
}

type TPropertyValueCatalog = {
  '@isPublic': boolean
  '@type': typeof PropertyTypeEnum['CATALOG']
  '@value': Record<string, unknown>
}

type TPropertyValueLookup = {
  '@type': typeof PropertyTypeEnum['LOOKUP'] | typeof PropertyTypeEnum['FIXED_LOOKUP']
  '@value': Record<string, unknown>
}

type TPropertyValueNpa = {
  '@type': typeof PropertyTypeEnum['NPA'] | typeof PropertyTypeEnum['NPA_PART']
  '@value': Record<string, unknown>
}

type TPropertyValueOrganization = {
  '@type': typeof PropertyTypeEnum['ORGANIZATION']
  '@value': {
    id: string
    addressFullEgrulFormat?: string
    city?: string
    house?: string
    index?: string
    memberCapital?: number
    memberFullName?: string
    memberInn?: string
    memberKpp?: string
    memberName?: string
    memberOgrn?: string
    memberOkpo?: string
    memberOktmo?: string
    memberOkved?: string
    memberOtherDetails?: string
    memberPaymentDetails?: string
    region?: string
    roadNetworkElement?: string
    room?: string
  }
}

interface IPropertyProps<T> {
  propertyId: string
  type: string
  lastUpdateDt: string
  value: T
}

interface IObjectForAdapter {
  propertyId: string
  lastUpdateDt: string
  type: typeof PropertyTypeEnum
  value: unknown
  commitedValue: unknown
}

interface IChangedPropertyObject {
  lastNoticedChangesDt: string
  changed: IChangedProperty[]
  added: IAddedChangeProperty[]
  deleted: IDeletedChangeProperty[]
}

interface IChangedProperty extends TPropertyValueRequired {
  '@type': keyof typeof PropertyTypeEnum
  '@value': unknown
}

interface IAddedChangeProperty {
  parentProperty: TPropertyValue
  elements: TPropertyValue[]
}

interface IDeletedChangeProperty {
  parentProperty: TPropertyValue
  childPropertyId: string
}

type TPreconfiguredSetups = 'regions'

interface IRegionsSetupPropsObject {
  filteredRegions: Record<string, string>
}

type IOverridePropsFromClass =
  | {
      type: typeof PropertyTypeEnum.CATALOG
      initialType: string
      propertyId: string
      dictionaryName: string
    }
  | {
      type: typeof PropertyTypeEnum.LOOKUP
      initialType: string
      propertyId: string
    }
  | {
      type: typeof PropertyTypeEnum.FIXED_LOOKUP
      initialType: string
      propertyId: string
    }
  | {
      type: 'CUSTOM'
      propertyId: string
      customProps: unknown
    }
  | {
      type: 'PRECONFIGURED'
      setup: TPreconfiguredSetups
      propertyId: string
    }

interface IPropertyFormError {
  violations: string[]
  id: string | null
  parentId: string | null
  name: string
  type: string
  critical: boolean
}

type TPropertyValueSimple =
  | TPropertyValueString
  | TPropertyValueNumber
  | TPropertyValueBoolean
  | TPropertyValueDouble
type TPropertyValueStructure = TPropertyValueList | TPropertyValueMap
type TPropertyValueCustom =
  | TPropertyValueMoney
  | TPropertyValueLookup
  | TPropertyValueCatalog
  | TPropertyValueOrganization
  | TPropertyValueNpa

type TPropertyValue = (TPropertyValueSimple | TPropertyValueStructure | TPropertyValueCustom) &
  TPropertyValueRequired &
  TPropertyValuePartial

type TProperty = Record<TPropertyKey, TPropertyValue>

type TPropertyValueFieldName = '@value' | '@commitedValue'

export type {
  DefaultPropertiesProps,
  IAddedChangeProperty,
  IChangedProperty,
  IChangedPropertyObject,
  IDeletedChangeProperty,
  IObjectForAdapter,
  IOverridePropsFromClass,
  IPropertyFormError,
  IPropertyProps,
  IRegionsSetupPropsObject,
  TProperty,
  TPropertyKey,
  TPropertyValue,
  TPropertyValueCustom,
  TPropertyValueFieldName,
  TPropertyValueList,
  TPropertyValueOrganization,
  TPropertyValueRequired,
  TPropertyValueSimple,
  TPropertyValueStructure,
}
export { PropertyTypeEnum }
