import React from 'react'
import { useFormContext } from 'react-hook-form'

import SubjectsSection from '@components/DocumentFormComponents/FormSections/SubjectsSection'
import { agreementStabilizationNegotiationsBlockValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/const'
import { useAgreementStabilizationNegotiationsManager } from '@components/Forms/АgreementStabilizationNegotiationsForm/Manager'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import {
  AgreementStabilizationNegotiationsFieldArrayControlUpdateWatcher,
  AgreementStabilizationNegotiationsFieldsControlUpdateWatcher,
} from '@components/Forms/АgreementStabilizationNegotiationsForm/watcher'

const Second = () => {
  const formInstance = useFormContext<AgreementStabilizationNegotiationsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAgreementStabilizationNegotiationsManager()

  if (!formInstance) return null

  return (
    <SubjectsSection
      formName={agreementStabilizationNegotiationsBlockValues['2']['subjects']}
      formInstance={formInstance}
      blockViewIsValidating={blockViewIsValidating}
      editMode={editMode}
      watcher={AgreementStabilizationNegotiationsFieldsControlUpdateWatcher}
      fieldArrayWatcher={AgreementStabilizationNegotiationsFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
