import { ROLES_FOR_REGISTER, RolesTypes } from '@constants/types'
import { IJwtDecode } from '@context/AuthContext/models'

import { jwtDecodeFacade } from '../helpers/jwtDecodeFacade'

export const getRolesByToken = () =>
  jwtDecodeFacade.get({
    name: ['realm_access', 'roles'] as unknown as keyof IJwtDecode,
    onCatch: () => [''],
  })

export const isRoleAvailableForRegistration = () => {
  const roles = getRolesByToken()
  return !!roles?.length && roles.some((role) => ROLES_FOR_REGISTER.includes(role))
}

export const checkRoleOnExistence = (role: string) => {
  const roles = getRolesByToken()

  return roles ? roles.includes(role) : false
}

export const findRoleBySelectedRoles = (
  selectedRoles: (keyof typeof RolesTypes)[],
): string | undefined => {
  const roles = getRolesByToken()

  return !!roles.length && roles.find((role) => selectedRoles.includes(role))
}

export const getUserRole = () => {
  return jwtDecodeFacade.get({
    name: 'realm_access',
    onCatch: () => '',
  })
}

export const getUserIOandSurname = () => {
  return {
    IO: jwtDecodeFacade.get({
      name: 'given_name',
      onCatch: () => '',
    }),
    surname: jwtDecodeFacade.get({
      name: 'family_name',
      onCatch: () => '',
    }),
  }
}

export const getMainRole = () => {
  const roles = getRolesByToken()

  return roles.find(
    (role) =>
      role.includes(RolesTypes.FK) ||
      role.includes(RolesTypes.MER) ||
      role.includes(RolesTypes.UO) ||
      role.includes(RolesTypes.OIV) ||
      role.includes(RolesTypes.INVESTOR) ||
      role.includes(RolesTypes.ROIV) ||
      role.includes(RolesTypes.OPERATOR) ||
      role.includes(RolesTypes.OMSU),
  )
}

export const getEmailStatus = () => {
  return jwtDecodeFacade.get({ name: 'email_verified' })
}
