import { usePopupManager } from 'react-popup-manager'

import CreateProjectModal from '@components/NewDesignedModals/CreateProjectModal/index'

const useCreateProjectModal = () => {
  const popupManager = usePopupManager()

  const handleOpenCreateProjectModal = () => {
    popupManager.open(CreateProjectModal, {
      onClose: () => {},
    })
  }

  return {
    handleOpenCreateProjectModal,
  }
}

export { useCreateProjectModal }
