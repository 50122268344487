import { useCallback } from 'react'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForAAVolumeOfCapitalInvestments } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/adapters/Menu.adapter'
import { generatePropertiesObjectForAAVolumeOfCapitalInvestmentsForm } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAAVolumeOfCapitalInvestmentsForm } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/adapters/RHF.adapter'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'

const useAAVolumeOfCapitalInvestmentsAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): AAVolumeOfCapitalInvestmentsFormValues =>
      generateRHFObjectForAAVolumeOfCapitalInvestmentsForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForAAVolumeOfCapitalInvestmentsForm(objectFromPropertyState),
    [],
  )

  const generateMenuListByFormValues = useCallback(
    (
      formValues:
        | AAVolumeOfCapitalInvestmentsFormValues
        | UnpackNestedValue<AAVolumeOfCapitalInvestmentsFormValues>,
      initialHashMap: NestedMenuHashMap,
      formErrors: FieldErrors<AAVolumeOfCapitalInvestmentsFormValues>,
    ): NestedMapOfMenu[] =>
      generateMenuListForAAVolumeOfCapitalInvestments(formValues, initialHashMap, formErrors),
    [],
  )

  return { generateRHFObject, generatePropertiesObject, generateMenuListByFormValues }
}

export { useAAVolumeOfCapitalInvestmentsAdapters }
