import React, { FC, memo, useMemo } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'
import { ReactSVG } from 'react-svg'

import Drawer from '@components/Drawer'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import { MainMenuLinkItem, mapOfMainMenuByRoles } from '@components/Sidebars/MainHeaderMenu/const'
import MainHeaderMenuItem from '@components/Sidebars/MainHeaderMenu/MainHeaderMenuItem'
import { Paths } from '@constants/paths'
import { RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { ValueOf } from '@helpers/ValueOf'
import CloseMainMenuIcon from '@icons/CloseMainMenuIcon.svg'
import Logo from '@icons/layout/logo.svg'
import { useProjectsPageStateManager } from '@services/ProjectsState/ProjectsState.manager'
import cn from 'classnames'

import styles from './MainHeaderMenu.module.scss'

type MainHeaderMenuSidebarProps = PopupProps

const useMainMenuSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenMainMenuSidebar = () => {
    popupManager.open(MainHeaderMenuSidebar)
  }

  return {
    handleOpenMainMenuSidebar,
  }
}

const MainHeaderMenuSidebar: FC<MainHeaderMenuSidebarProps> = ({ isOpen, onClose }) => {
  const { getUserRole } = useAuthContext()
  const { clearProjectsPageState } = useProjectsPageStateManager()

  const currentMainRole = useMemo((): ValueOf<typeof RolesTypes> => {
    const currentRolesArray = getUserRole?.()?.roles

    const currentMainRole =
      Array.isArray(currentRolesArray) &&
      currentRolesArray.find(
        (role) =>
          role === RolesTypes.INVESTOR ||
          role === RolesTypes.OIV ||
          role === RolesTypes.OMSU ||
          role === RolesTypes.FK ||
          role === RolesTypes.MER ||
          role === RolesTypes.UO ||
          role === RolesTypes.ROIV ||
          role === RolesTypes.OPERATOR,
      )

    return currentMainRole
  }, [getUserRole])

  const currentMapOfMenuByMainRole = useMemo((): MainMenuLinkItem[][] | undefined => {
    if (!currentMainRole) return

    return [
      mapOfMainMenuByRoles[currentMainRole].mainLinks,
      mapOfMainMenuByRoles[currentMainRole].secondaryLinks,
    ]
  }, [currentMainRole])

  const handleClickLink = (link: string) => () => {
    if (link === Paths.Projects && currentMainRole !== RolesTypes.INVESTOR) clearProjectsPageState()
    onClose?.()
  }

  return (
    <Drawer
      isOpen={isOpen}
      dataTestId="MainHeaderMenuSidebar-sidebar"
      position={'left'}
      drawerClassName={styles.mainMenu}
      onClose={onClose}
    >
      <header className={styles.mainMenu__header}>
        <ReactSVG src={Logo} className={styles['mainMenu__header-logo']} />
        <Typography.Caption className={styles['mainMenu__header-title']} variant={'captionAllcaps'}>
          ГИС «КАПИТАЛОВЛОЖЕНИЯ»
        </Typography.Caption>
        <IconButton
          size={'s'}
          className={styles['mainMenu__header-closeMainMenu']}
          dataTestId="MainHeaderMenuSidebar-sidebar-closeButton"
          icon={{
            src: CloseMainMenuIcon,
            noCurrentColorSvgFill: true,
          }}
          onClick={onClose}
        />
      </header>

      {currentMapOfMenuByMainRole?.map((links, linksIndex) => {
        if (links.length < 1) return null

        return (
          <ul key={linksIndex} className={cn(styles.mainMenu__linksWrapper)}>
            {links.map((linkItem, linkItemIndex) => {
              if (!linkItem.link && !linkItem.children) return

              return (
                <MainHeaderMenuItem
                  key={linkItemIndex}
                  linkItem={linkItem}
                  onLinkClick={handleClickLink}
                />
              )
            })}
          </ul>
        )
      })}
    </Drawer>
  )
}

export { useMainMenuSidebar }
export default memo(MainHeaderMenuSidebar)
