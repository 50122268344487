import { useMemo, useState } from 'react'

export const useBooleanState = (defaultState = false) => {
  const [booleanState, setBooleanState] = useState(defaultState)

  const { reverseBooleanState, setBooleanStateToFalse, setBooleanStateToTrue } = useMemo(() => {
    const setBooleanStateToTrue = () => setBooleanState(true)
    const setBooleanStateToFalse = () => setBooleanState(false)

    const reverseBooleanState = () => {
      setBooleanState((prev) => !prev)
    }

    return { setBooleanStateToTrue, setBooleanStateToFalse, reverseBooleanState }
  }, [])

  return {
    booleanState,
    setBooleanState,
    setBooleanStateToTrue,
    setBooleanStateToFalse,
    reverseBooleanState,
  }
}
