import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { bigIntIsValidate, defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const anotherObjectValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['8']['stepActivityOthers'][number]['activityOthers'][number]['measureObjects'][number],
  ProjectSZPKFormValues
> = {
  objectName: {
    required: defaultRHFValidation.required,
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  objectConstructionKind: {
    validate: (value) => (isString(value) ? lengthValidate(value, 100) : undefined),
  },
  objectPurpose: {
    validate: (value) => (isString(value) ? lengthValidate(value, 100) : undefined),
  },
  objectConstructionSquare: {
    validate: bigIntIsValidate,
  },
  objectSquare: {
    validate: bigIntIsValidate,
  },
  objectNonLivingAreaSquare: {
    validate: bigIntIsValidate,
  },
  objectLivingAreaSquare: {
    validate: bigIntIsValidate,
  },
  objectFloor: {
    validate: (value) => (isString(value) ? lengthValidate(value, 10) : undefined),
  },
  objectUndergroundFloor: {
    validate: (value) => (isString(value) ? lengthValidate(value, 10) : undefined),
  },
  objectHeight: {
    validate: bigIntIsValidate,
  },
  objectDetails: {
    validate: (value) => isString(value) && lengthValidate(value, 100000),
  },
  objectInfrastructureType: {
    required: defaultRHFValidation.required,
  },
}

export { anotherObjectValidationMap }
