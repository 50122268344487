import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NpaSolicitationInclusionFormValues } from '@components/Forms/NpaSolicitationInclusionForm/types'
import { isArray } from '@helpers/checkTypes'
import { INpaBody, NpaPartForBody } from '@services/NPA/NPA.entity'

const generateRHFObjectForNpaSolicitationInclusionForm = (
  objectFromPropertyState: Record<string, any>,
): NpaSolicitationInclusionFormValues => {
  const preparedChosenParts = ((): INpaBody | null => {
    if (
      !isArray(objectFromPropertyState.stabilizationUpdateNpas?.value) ||
      !objectFromPropertyState.stabilizationUpdateNpas?.value.length
    )
      return null

    const npaParts = objectFromPropertyState.stabilizationUpdateNpas.value.map(
      (npa): NpaPartForBody => {
        return {
          id: npa.propertyId,
          justification: npa?.value?.stabilizationUpdateNpaJustification?.value,
          partCorrection: npa?.value?.stabilizationUpdateNpaCorrection?.value,
          npaId: npa?.value?.stabilizationUpdateNpaPartId?.value?.npaId,
          partId: npa?.value?.stabilizationUpdateNpaPartId?.value?.id,
        }
      },
    )

    return {
      npaParts,
    }
  })()

  return {
    headerInfo: {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    npa: {
      npaStructure: [],
      chosenParts: preparedChosenParts,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
    },
  }
}

export { generateRHFObjectForNpaSolicitationInclusionForm }
