import React, { ChangeEvent, forwardRef, InputHTMLAttributes, memo, ReactNode, useRef } from 'react'

import { mergeRefs } from '@helpers/ref/mergeRefs'
import { useFocus } from '@hooks/useFocus'
import cn from 'classnames'

import styles from './RadioButton.module.scss'

type NativeProps = InputHTMLAttributes<HTMLInputElement>
type Align = 'start' | 'center'

type Variant =
  | 'buttonLMedium'
  | 'buttonLRegular'
  | 'buttonMMedium'
  | 'buttonMRegular'
  | 'buttonSMedium'

export type RadioProps = Omit<
  NativeProps,
  'size' | 'type' | 'onChange' | 'checked' | 'disabled' | 'name' | 'className'
> & {
  /**
   * Текст подписи
   */
  label?: ReactNode

  /**
   * Размер шрифта
   */
  variant?: Variant

  /**
   * Управление состоянием отмечен/не отмечен
   */
  checked?: boolean

  /**
   * Управление состоянием включен / выключен
   */
  disabled?: boolean

  /**
   * Html аттрибут name инпута
   */
  name?: string

  /**
   * Класс компонента
   */
  className?: string

  /**
   * Доп. класс радио кнопки
   */
  circleClassName?: string

  /**
   * Доп. класс контента
   */
  contentClassName?: string

  /**
   * Выравнивание
   */
  align?: Align

  /**
   * Растягивать ли компонент на всю ширину
   */
  block?: boolean

  /**
   * Обработчик на выбор элемента
   */
  onChange?: (
    event?: ChangeEvent<HTMLInputElement>,
    payload?: {
      checked: boolean
      name?: string
    },
  ) => void
}

const RadioButton = forwardRef<HTMLLabelElement, RadioProps>(
  (
    {
      variant = 'buttonLRegular',
      onChange,
      className,
      circleClassName,
      contentClassName,
      name,
      disabled,
      label,
      checked,
      align = 'start',
      block,
      ...restProps
    },
    ref,
  ) => {
    const labelRef = useRef<HTMLLabelElement>(null)

    const [focused] = useFocus(labelRef, 'keyboard')

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event, { checked: event.target.checked, name })
      }
    }

    return (
      <label
        ref={mergeRefs([labelRef, ref])}
        className={cn(
          styles.container,
          styles[align],
          {
            [styles.disabled]: disabled,
            [styles.checked]: checked,
            [styles.focused]: focused,
            [styles.block]: block,
            [styles[variant]]: variant,
          },
          className,
        )}
      >
        <input
          type="radio"
          disabled={disabled}
          checked={checked}
          name={name}
          onChange={handleChange}
          {...restProps}
        />
        <span className={cn(styles.circle, circleClassName)} />
        {label && (
          <span className={cn(styles.content, contentClassName)}>
            <span className={styles.label}>{label}</span>
          </span>
        )}
      </label>
    )
  },
)

export default memo(RadioButton)
