import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  DataOnFulfillmentOfTermsSZPKFormValues,
  EconomicSphereRegionalReportCard,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

export const economicSphereValidationMap: FormValuesValidationSection<
  keyof EconomicSphereRegionalReportCard,
  DataOnFulfillmentOfTermsSZPKFormValues
> = {
  economicSphereNameRegionalReport: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}
