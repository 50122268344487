import { FC } from 'react'

import SuccessModal from '@components/NewDesignedModals/SuccessModal'
import { NotificationWithButton } from '@components/NewNotifications/NotificationWithButton'
import { successModalBodyTexts, successModalHeaderTexts } from '@constants/texts'
import { notificationLevelType } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { MessageWithUserDataAndPopup } from '@layouts/Header/Message/model'

import styles from './Message.module.scss'

export const EmailApproving: FC<MessageWithUserDataAndPopup> = ({
  isOpen,
  onOpen,
  onClose,
  userInfo,
}) => {
  const {
    organizationApi: { askEmailApproving },
  } = useAPIContext()

  const handleTryApproveEmail = async (onSuccess: () => void) => {
    try {
      await askEmailApproving()
      onSuccess()
    } catch {}
  }

  return (
    <>
      <SuccessModal
        headerText={successModalHeaderTexts.checkEmail}
        text={`${successModalBodyTexts.checkEmail} ${userInfo?.email}`}
        isOpen={isOpen}
        onClose={onClose}
      />
      <NotificationWithButton
        className={styles.SingleMessage}
        level={notificationLevelType.WARNING}
        dataTestId={'EmailApproving-container'}
        title={'Подтвердите свою почту'}
        titleDataTestId={'EmailApproving-title'}
        descriptionDataTestId={'EmailApproving-description'}
        description={`После подтверждения вы сможете получать уведомления о ходе рассмотрения. Письмо со ссылкой для подтверждения отправили на ${
          userInfo?.email || 'вашу почту'
        }`}
        buttonProps={{
          text: 'Отправить ссылку еще раз',
          dataTestId: 'EmailApproving-retry-button',
          className: styles.SingleMessage__button,
          onClick: () => handleTryApproveEmail(onOpen),
        }}
      />
    </>
  )
}
