const signDocumentsReducerActionMap = {
  SETUP_LOADING: 'SETUP_LOADING',
  SETUP_DONE: 'SETUP_DONE',
  SETUP_SIGN_DOCS_PROGRESS: 'SETUP_SIGN_DOCS_PROGRESS',
  SETUP_DEFAULT_ERROR: 'SETUP_DEFAULT_ERROR',
  SETUP_AXIOS_ERROR: 'SETUP_AXIOS_ERROR',
  SETUP_DOCS_TO_SIGN_COUNTER: 'SETUP_DOCS_TO_SIGN_COUNTER',
  START_SIGN_DOCS: 'START_SIGN_DOCS',
  INCREMENT_SIGNED_DOCUMENT: 'INCREMENT_SIGNED_DOCUMENT',
} as const

export { signDocumentsReducerActionMap }
