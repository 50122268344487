import React, { FC } from 'react'

import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Col from '@components/ReactBootstrap/Col'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import cn from 'classnames'

import styles from './ActionButton.module.scss'

type ActionButtonProps = ButtonProps & {
  disableWrapperPaddingLeft?: boolean
  wrapperClassName?: string
  wrapperSize?: number
}

const ActionButton: FC<ActionButtonProps> = ({
  disableWrapperPaddingLeft = false,
  size = 'm',
  view = 'outline',
  wrapperSize = 6,
  children,
  wrapperClassName,
  leadingIcon,
  ...props
}) => {
  const { src = CircleAddIcon, ...restLeadingIconProps } = leadingIcon || {}

  return (
    <Col
      xs={wrapperSize}
      className={cn(
        styles.actionButton__wrapper,
        {
          [styles['actionButton__wrapper--disableWrapperPaddingLeft']]: disableWrapperPaddingLeft,
        },
        wrapperClassName,
      )}
    >
      <Button
        {...props}
        size={size}
        view={view}
        leadingIcon={{
          ...restLeadingIconProps,
          noCurrentColorSvgFill: false,
          src,
        }}
      >
        {children}
      </Button>
    </Col>
  )
}

export default ActionButton
