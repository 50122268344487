import React, { FC, memo } from 'react'

import Icon from '@components/Icon/Icon'
import Typography from '@components/NewDesign/Typography'
import DeadlineProjectsTooltip from '@components/PreparedTooltips/DeadlineProjects'
import { colorVariableNameBySimpleName, iconByName } from '@constants/config'
import { DFOSStage } from '@context/APIContext/hooks/useDFOSApi'
import { deadlineMayBeExtendedWithDfoType } from '@services/Deadline/Deadline.const'
import { useProjectsDeadline } from '@services/Deadline/Deadline.hook'
import { IDfoListItemFromAllProjects } from '@services/Dfo/Dfo.entity'
import cn from 'classnames'

import styles from './Status.module.scss'

interface StatementDfoProps {
  dfo: IDfoListItemFromAllProjects
  params?: DFOSStage
}

const StatementDfoStatus: FC<StatementDfoProps> = ({ dfo, params }) => {
  const { isDeadlineRender, deadlineTooltipIsRender, preparedDeadlineDate } = useProjectsDeadline(
    dfo.deadline,
  )

  const deadlineMayBeExtendedTooltipIsRender =
    !!deadlineTooltipIsRender && dfo.type.startsWith(deadlineMayBeExtendedWithDfoType.CLAIM_)

  const currentStatusColor =
    (params && colorVariableNameBySimpleName[params?.stageStyle]) ||
    colorVariableNameBySimpleName.green

  const renderDeadlineTooltip = () => {
    if (deadlineMayBeExtendedTooltipIsRender) return <DeadlineProjectsTooltip type={'EXTENDED'} />

    return <DeadlineProjectsTooltip />
  }

  return (
    <div className={styles.deadline}>
      {params?.stageIcon && (
        <div className={cn(styles.icon, styles[`icon_${params.stageStyle}`])}>
          <Icon noCurrentColorSvgFill src={iconByName[params.stageIcon]} size="xs" />
        </div>
      )}
      <div className={styles.deadline__info}>
        {isDeadlineRender && (
          <div className={styles['deadline__info-date']}>
            <Typography.Caption color={'text-base-tertiary'} variant={'captionSRegular'}>
              {preparedDeadlineDate}
            </Typography.Caption>
            {deadlineTooltipIsRender && (
              <button
                className={styles['deadline__info-tooltip']}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
              >
                {renderDeadlineTooltip()}
              </button>
            )}
          </div>
        )}
        <Typography.Body variant="bodySMedium" color={currentStatusColor}>
          {params?.stageDescription || ''}
        </Typography.Body>
      </div>
    </div>
  )
}

export default memo(StatementDfoStatus)
