import React, { FC, memo } from 'react'

import RegistryNpa from '@components/RegistryNpa'
import Layouts from '@layouts'

import styles from './RegistryNpa.module.scss'

const RegistryNpaPage: FC = () => {
  return (
    <Layouts.Main
      headerContentContainer={{
        className: styles.registryNpa,
      }}
    >
      <RegistryNpa />
    </Layouts.Main>
  )
}

export default memo(RegistryNpaPage)
