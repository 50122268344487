import dayjs, { ConfigType, OptionType } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import objectSupport from 'dayjs/plugin/objectSupport'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import DayjsHelpersService, { FormatPropForValidationCheck } from './Dayjs.helpers'

class DayjsService {
  private _dayJs: typeof dayjs
  constructor(dayJs: typeof dayjs) {
    this._dayJs = dayJs

    this._dayJs.extend(customParseFormat)
    this._dayJs.extend(objectSupport)
    this._dayJs.extend(utc)
    this._dayJs.extend(weekday)
    this._dayJs.extend(weekOfYear)
    this._dayJs.extend(timezone)
    this._dayJs.extend(isToday)
    this._dayJs.extend(isYesterday)

    this._dayJs?.tz?.setDefault('Europe/Moscow')
  }

  dayjs(date?: ConfigType, format?: OptionType, locale?: string, strict?: boolean) {
    return this._dayJs(date, format, locale, strict).tz('Europe/Moscow', true)
  }

  dayjsWithFormatToMSK(date?: ConfigType, format?: OptionType, locale?: string, strict?: boolean) {
    return this._dayJs(date, format, locale, strict).tz('Europe/Moscow')
  }

  // кастомный isDateValid ибо нативный пересчитывает несуществующие даты в существующие и выдает за валидные
  isDateValid<T>(
    date: T & ConfigType,
    format: FormatPropForValidationCheck<T>,
    locale?: string,
    strict?: boolean,
  ) {
    const invalidDateType = DayjsHelpersService.getInvalidDateType(date, format, locale, strict)

    return !invalidDateType
  }
}

export default new DayjsService(dayjs)
