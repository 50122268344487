import { ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import { objOfDateFormats } from '@constants/dateFormats'
import { mergeRefs } from '@helpers/ref/mergeRefs'
import dayjs from 'dayjs'

import Input from '../Input'

import { DATE_MASK, DateInputProps, YEAR_MASK } from './types'

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export const isCompleteDateInput = (input: string, mask: (string | RegExp)[]) =>
  input.length === mask.length

/**
 * Метод, который форматирует непонятный ввод в контрол текста НЕ вида DATE_MASK
 * !! работает ТОЛЬКО с DATE_MASK форматом, вида DD.MM.YYYY
 * @param value Значение вида DATE_MASK
 * @returns
 */
const formatByFullDate = (value: string): string =>
  value
    .replace(/^(\d\d)(\d)$/, '$1.$2') // 121 => 12.1
    .replace(/^(\d\d)\.(\d\d)(\d)$/, '$1.$2.$3') // 12.122 => 12.12.2
    .replace(/^(\d\d)\d\.(.*)/, '$1.$2') // 123.12.2005 => 12.12.2005
    .replace(/^(\d\d\.\d\d)\d\.(.*)/, '$1.$2') // 12.123.2005 => 12.12.2005
    .replace(/^(\d\d\.\d\d\.\d\d\d\d).*/, '$1') // 12.12.20056 => 12.12.2005
    .replace(/\.$/, '') // 12. => 12
    .replace(/^(\d\d\.\d\d)(\d\d\d\d)/, '$1.$2') // 12.122005 => 12.12.2005
    .replace(/^(\d\d)(\d\d\.\d\d\d\d)/, '$1.$2') // 1212.2005 => 12.12.2005

export const parseDateString = (value: string, format: string = objOfDateFormats.defaultFormat) =>
  dayjs(value, format, 'ru', true)

const isValid = (
  inputValue?: string,
  mask: (string | RegExp)[] = DATE_MASK,
  format: string = objOfDateFormats.defaultFormat,
) =>
  !inputValue ||
  (isCompleteDateInput(inputValue, mask) && parseDateString(inputValue, format).isValid())

const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      defaultValue = '',
      onChange,
      value: propValue,
      error,
      inputMask = DATE_MASK,
      inputPattern = '[0-9.]*',
      format,
      ...restProps
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const [value, setValue] = useState(propValue || defaultValue)

    const [stateError, setStateError] = useState(!isValid(propValue, inputMask))

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const { value: newValue } = event.target

        // Позволяем вводить только цифры и точки
        if (inputMask === DATE_MASK && /[^\d.]/.test(newValue)) {
          return
        }

        if (inputMask === YEAR_MASK && newValue !== '' && !/^(?:[1-9]\d{0,3})$/.test(newValue)) {
          return
        }

        const dots = newValue.match(/\./g)

        // Не даем вводить больше, чем 2 точки
        if (dots && dots.length > 2) {
          return
        }

        // Форматируем введенное значение (добавляем точки)
        let formattedValue = ''

        switch (inputMask) {
          case DATE_MASK: {
            formattedValue = formatByFullDate(newValue)
            break
          }

          default: {
            formattedValue = newValue
          }
        }

        const date = new Date(parseDateString(formattedValue, format).date())

        setValue(formattedValue)

        onChange?.(event, { date, value: formattedValue })

        if (isCompleteDateInput(formattedValue, inputMask)) {
          const valid = formattedValue.length > 0 && isValid(formattedValue, inputMask, format)

          if (!valid) return
        }
      },
      [onChange, inputMask, format],
    )

    const handleValueValidity = useCallback(
      (inputValue: string) => {
        // Валидируем незаполненное значение только если инпут не в фокусе (блюр, либо установка значения снаружи)
        const validateIncomplete = inputRef.current && document.activeElement !== inputRef.current

        if (!inputValue || validateIncomplete || inputValue.length >= inputMask.length) {
          setStateError(!isValid(inputValue, inputMask, format))
        }
      },
      [inputMask, format],
    )

    useEffect(() => {
      handleValueValidity(value)
    }, [handleValueValidity, value])

    useEffect(() => {
      if (typeof propValue !== 'undefined') {
        setValue(propValue)
      }
    }, [propValue])

    return (
      <Input
        {...restProps}
        view="secondary"
        value={value}
        inputMode="decimal"
        pattern={inputPattern}
        error={error || stateError}
        ref={mergeRefs([inputRef, ref])}
        onChange={handleChange}
      />
    )
  },
)

export default DateInput
