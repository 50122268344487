import React, { useMemo, useState } from 'react'

import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Loader from '@components/Loader'
import { updateSearchThreeById } from '@components/ReferenceBooks/HierarchyType/helpers'
import HierarchyListItem from '@components/ReferenceBooks/HierarchyType/Item'
import { useReferenceBooksHierarchyManager } from '@components/ReferenceBooks/HierarchyType/Manager'
import { useHierarchyEntrySidebar } from '@components/Sidebars/ReferenceBooks/HierarchyType/Entry'
import { useHierarchySectionSidebar } from '@components/Sidebars/ReferenceBooks/HierarchyType/Section'
import { useReferenceBooksApi } from '@context/APIContext/hooks/useReferenceBooksApi'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { isAxiosError } from '@helpers/checkTypes'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useReferenceBooksByDictionaryName } from '@hooks/new/swr/useReferenceBooksByDictionaryName'
import { useSWRConfig } from 'swr'

import styles from './HierarchyList.module.scss'

const HierarchyList = () => {
  const [searchPlaceholderCondition, setSearchPlaceholderCondition] = useState<boolean>(false)

  const { getReferenceBookChildsById } = useReferenceBooksApi()

  const { mutate } = useSWRConfig()

  const { state, handlers } = useReferenceBooksHierarchyManager()
  const { handleOpenEntrySidebar } = useHierarchyEntrySidebar()
  const { handleOpenSectionSidebar } = useHierarchySectionSidebar()

  const { referenceBooks, referenceBooksError, isReferenceBooksLoading } =
    useReferenceBooksByDictionaryName({
      key: state.dictionaryName
        ? {
            dictionaryName: state.dictionaryName,
            _key: 'referenceBooks',
          }
        : null,
    })

  const currentThreeOfRender = state.searchThree ?? referenceBooks

  const noDataCondition = useMemo(() => {
    return (
      !referenceBooksError &&
      !isReferenceBooksLoading &&
      !state.threeIsLoading &&
      !referenceBooks?.length &&
      !state.searchString?.length
    )
  }, [
    referenceBooksError,
    isReferenceBooksLoading,
    state.threeIsLoading,
    state.searchString?.length,
    referenceBooks?.length,
  ])

  useUpdateEffect(() => {
    setSearchPlaceholderCondition(false)

    if (state.searchString?.length && !state.searchThree?.length)
      setSearchPlaceholderCondition(true)
  }, [state.searchString, state.searchThree])

  if (noDataCondition) {
    return <NoDataPlaceholder className={styles.placeholder} />
  }

  if (searchPlaceholderCondition && !isReferenceBooksLoading && !state.threeIsLoading) {
    return (
      <SearchPlaceholder className={styles.placeholder}>{state.searchString}</SearchPlaceholder>
    )
  }

  const handleOpenCode = (
    referenceBookItem: ReferenceBookItem,
    parentReferenceBookItem?: ReferenceBookItem,
  ) => {
    if (!state.dictionaryName) return

    if (parentReferenceBookItem) {
      handleOpenEntrySidebar({
        dictionaryName: state.dictionaryName,
        initialEntry: referenceBookItem,
        parentInitialEntry: parentReferenceBookItem,
        onSuccess: async (newReferenceBook, action) => {
          if (!parentReferenceBookItem || !state.dictionaryName) return

          if (!state.searchThree || !referenceBookItem.fromSearchThree) {
            try {
              const elements = await getReferenceBookChildsById({
                id: parentReferenceBookItem.id,
                dictionaryName: state.dictionaryName,
              })

              await mutate(
                {
                  id: parentReferenceBookItem.id,
                  dictionaryName: state.dictionaryName,
                  _key: 'referenceBookChildsById',
                },
                elements,
                { revalidate: false },
              )
            } catch (error) {
              if (!isAxiosError(error)) throw error

              if (error.response?.status !== 404) throw error

              if (parentReferenceBookItem.parentId) {
                await mutate({
                  id: parentReferenceBookItem.parentId,
                  dictionaryName: state.dictionaryName,
                  _key: 'referenceBookChildsById',
                })

                throw error
              }

              await mutate({
                dictionaryName: state.dictionaryName,
                _key: 'referenceBooks',
              })

              throw error
            }

            try {
              await mutate({
                id: referenceBookItem.id,
                dictionaryName: state.dictionaryName,
                _key: 'referenceBookChildsById',
              })
            } catch {}
          }

          if (!!state.searchThree && newReferenceBook && referenceBookItem.fromSearchThree) {
            handlers.handleSetupSearchThree?.((prevThree) => {
              return prevThree
                ? updateSearchThreeById(prevThree, parentReferenceBookItem.id, (prevStateObj) => {
                    if (action === 'delete') {
                      const childrenAfterDelete =
                        prevStateObj.children &&
                        prevStateObj.children.filter((item) => item.id !== newReferenceBook.id)

                      return {
                        ...prevStateObj,
                        hasChild: !!childrenAfterDelete?.length,
                        children: childrenAfterDelete?.length ? childrenAfterDelete : null,
                      }
                    }

                    const childrenAfterUpdate =
                      prevStateObj.children &&
                      prevStateObj.children.filter((item) => item.id !== referenceBookItem.id)

                    return {
                      ...prevStateObj,
                      children: [...(childrenAfterUpdate ?? []), newReferenceBook].filter(Boolean),
                    }
                  })
                : prevThree
            })
          }
        },
      })
      return
    }

    handleOpenSectionSidebar({
      dictionaryName: state.dictionaryName,
      initialSection: referenceBookItem,
      onSuccess: async () => {
        await mutate({
          dictionaryName: state.dictionaryName,
          _key: 'referenceBooks',
        })
      },
    })
  }

  const handleAddCode = (
    referenceBookItem: ReferenceBookItem,
    parentReferenceBookItem?: ReferenceBookItem,
  ) => {
    if (!state.dictionaryName) return

    handleOpenEntrySidebar({
      type: 'create',
      dictionaryName: state.dictionaryName,
      parentInitialEntry: parentReferenceBookItem,
      onSuccess: async (createdReferenceBookItem) => {
        await mutate({
          id: parentReferenceBookItem?.id,
          dictionaryName: state.dictionaryName,
          _key: 'referenceBookChildsById',
        })

        await mutate({
          id: referenceBookItem?.id,
          dictionaryName: state.dictionaryName,
          _key: 'referenceBookChildsById',
        })

        //Ревалидация всех разделов
        if (!referenceBookItem.parentCode && !state.searchThree) {
          await mutate(
            {
              dictionaryName: state.dictionaryName,
              _key: 'referenceBooks',
            },
            undefined,
            {
              revalidate: true,
            },
          )
        }

        if (!!state.searchThree && createdReferenceBookItem && referenceBookItem.fromSearchThree) {
          handlers.handleSetupSearchThree?.((prevThree) =>
            prevThree
              ? updateSearchThreeById(prevThree, referenceBookItem.id, (prevStateObj) => ({
                  ...prevStateObj,
                  hasChild: true,
                  children: prevStateObj.children
                    ? ([...prevStateObj.children, createdReferenceBookItem].filter(
                        Boolean,
                      ) as ReferenceBookItem[])
                    : [createdReferenceBookItem],
                }))
              : prevThree,
          )
        }

        handleOpenCode(createdReferenceBookItem, referenceBookItem)
      },
      initialEntry: referenceBookItem,
    })
  }

  return (
    <Loader loading={state.threeIsLoading || isReferenceBooksLoading}>
      <div className={styles.hierarchyList}>
        {currentThreeOfRender?.map((codeObj) => {
          return (
            <HierarchyListItem
              key={codeObj.id}
              codeObj={codeObj}
              onCodeOpen={handleOpenCode}
              onCodeAdd={handleAddCode}
            />
          )
        })}
      </div>
    </Loader>
  )
}

export default HierarchyList
