import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { IDfoAttribute } from '@services/Attribute/Attribute.entity'
import useSWRImmutable from 'swr/immutable'

type MapOfAttributes = Record<IDfoAttribute["name"], IDfoAttribute["value"]>

const useDfoAttributes = ({ key, config }: SWRHookProps<IDfoAttribute[] | undefined>) => {
  const {
    dfosApi: { getDfoAttributes },
  } = useAPIContext()

  const {
    data: dfoAttributes,
    error,
    mutate,
    isValidating: isLoadingDfoAttributes,
  } = useSWRImmutable(key, getDfoAttributes, config)

  const mapOfAttributes = useMemo(() => {
    return dfoAttributes?.reduce<MapOfAttributes>((prevValue, nextValue) => {
      return {
        ...prevValue,
        [nextValue.name]: nextValue.value,
      }
    }, {})
  }, [dfoAttributes])

  return {
    dfoAttributes,
    mapOfAttributes,
    isLoadingDfoAttributes,
    mutate,
    error,
  }
}

export { useDfoAttributes }
