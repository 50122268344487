import { memo, useEffect } from 'react'

import { NotificationWithButton } from '@components/NewNotifications/NotificationWithButton'
import doneIcon from '@icons/action/done.svg'
import { emailVerifiedControl } from '@layouts/Header/Message/constants'

import styles from './Message.module.scss'

const ICON_COLOR = '#ffffff' // surface-primary

const EmailApproved = () => {
  useEffect(() => {
    return () => emailVerifiedControl.delete()
  }, [])

  return (
    <NotificationWithButton
      className={styles.SingleMessage}
      title={'Почта подтверждена'}
      description={`Теперь вы будете получать уведомления о ходе рассмотрения соглашения`}
      titleDataTestId={'EmailApproved-title'}
      descriptionDataTestId={'EmailApproved-description'}
      iconProps={{
        src: doneIcon,
        color: ICON_COLOR,
        className: styles.SingleMessage__icon,
      }}
    />
  )
}

export default memo(EmailApproved)
