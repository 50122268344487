import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { dismantlingObjectCheckerCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/7/DismantlingObjectChecker/validation'
import { CertificateOnMonitoringResultsDismantlingObjectsCheckerArrayPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/7/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CertificateOnMonitoringResultsDismantlingObjectCheckerProps {
  id: string
  name: CertificateOnMonitoringResultsDismantlingObjectsCheckerArrayPathName
  title: string
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
  onRemove: VoidFunction
}

const CertificateOnMonitoringResultsDismantlingObjectChecker: FC<
  CertificateOnMonitoringResultsDismantlingObjectCheckerProps
> = ({ id, formInstance, title, name, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  return (
    <FieldView.StepTemplate id={id} title={title} onRemoveStep={onRemove}>
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.dismantlingObjectNameChecker`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${name}.dismantlingObjectNameChecker`,
                    rules:
                      dismantlingObjectCheckerCertificateOnMonitoringResultsValidationMap.dismantlingObjectNameChecker,
                    textareaProps: {
                      label: 'Наименование объекта',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.dismantlingObjectNameChecker`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.dismantlingObjectNameChecker`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default CertificateOnMonitoringResultsDismantlingObjectChecker
