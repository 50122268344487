import { createContext, FC, memo, ReactNode, useContext, useMemo } from 'react'
import { useParams } from 'react-router'

import { DictionaryNameFromParams } from '@components/ReferenceBooks/constants'
import type { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { useReferenceBooksByDictionaryName } from '@hooks/new/swr/useReferenceBooksByDictionaryName'
import { useQueryManager } from '@hooks/useQueryManager'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'
import { KeyedMutator } from 'swr'

interface ReferenceBooksManagerProps {
  children: ReactNode
}

interface ReferenceBooksContextProps {
  state: {
    gridSwrInstance: {
      isReferenceBooksLoading: boolean
      referenceBooks?: ReferenceBookItem[]
      referenceBooksError?: AxiosError<unknown, unknown>
      mutate: KeyedMutator<ReferenceBookItem[] | undefined>
    } | null

    searchValue: string
  }
}

const ReferenceBooksContext = createContext<ReferenceBooksContextProps>({
  state: {
    gridSwrInstance: null,

    searchValue: '',
  },
})

const ReferenceBooksFlatManager: FC<ReferenceBooksManagerProps> = ({ children }) => {
  const { queryUtils } = useQueryManager()

  const { dictionaryName } = useParams<DictionaryNameFromParams>()

  const searchValue = queryUtils.getQuery('searchString') || ''

  const preparedKey = useMemo(
    () => ({
      dictionaryName,
      searchString: searchValue.length ? searchValue : undefined,
      _key: 'referenceBooks',
    }),
    [dictionaryName, searchValue],
  )

  const gridSwrInstance = useReferenceBooksByDictionaryName({
    key: preparedKey,
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          searchString: searchValue,
        },
        componentInfo: {
          componentName: 'ReferenceBooksFlatManager',
          moduleName: 'ReferenceBooks',
          componentType: 'manager',
        },
      }),
    },
  })

  const providerValue: ReferenceBooksContextProps = useMemo(
    () => ({
      state: {
        gridSwrInstance,
        searchValue,
      },
    }),
    [searchValue, gridSwrInstance],
  )

  return (
    <ReferenceBooksContext.Provider value={providerValue}>
      {children}
    </ReferenceBooksContext.Provider>
  )
}

const useReferenceBooksFlatManager = () => {
  return useContext(ReferenceBooksContext)
}

export { useReferenceBooksFlatManager }
export default memo(ReferenceBooksFlatManager)
