import React, { FC } from 'react'
import { Control } from 'react-hook-form/dist/types/form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/FormController.module.scss'
import mainTemplateStyles from '@components/DocumentFormComponents/FormStyles/MainTemplate.module.scss'
import ConstructionObject from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject'
import RidObject from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject'
import { SeventhEventsOfStagesArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

interface FormControllerProps {
  parentId: string
  indexOfMainBlock: number
  name: SeventhEventsOfStagesArrayPathName
  control: Control<ProjectSZPKFormValues>
  isConstructionRender: boolean
  isRidRender: boolean
}

const FormController: FC<FormControllerProps> = ({
  parentId,
  indexOfMainBlock,
  name,
  control,
  isConstructionRender,
  isRidRender,
}) => {
  return (
    <div className={styles.templateController}>
      <Col xs={12}>
        <CollapseWrapper
          defaultExpanded={isConstructionRender}
          isExpanded={isConstructionRender}
        >
          <Row className={styles.templateController__construction}>
            <Row>
              <Border className={mainTemplateStyles.mainTemplate__border} />
            </Row>
            <Row className={mainTemplateStyles.mainTemplate__container}>
              <ConstructionObject
                parentId={parentId}
                indexOfMainBlock={indexOfMainBlock}
                name={`${name}.constructionStage`}
                control={control}
              />
            </Row>
          </Row>
        </CollapseWrapper>
      </Col>
      <Row>
        <Col xs={12}>
          <CollapseWrapper defaultExpanded={isRidRender} isExpanded={isRidRender}>
            <Row className={styles.templateController__construction}>
              <Row>
                <Border className={mainTemplateStyles.mainTemplate__border} />
              </Row>
              <Row className={mainTemplateStyles.mainTemplate__container}>
                <RidObject
                  parentId={parentId}
                  indexOfMainBlock={indexOfMainBlock}
                  name={`${name}.ridStage`}
                  control={control}
                />
              </Row>
            </Row>
          </CollapseWrapper>
        </Col>
      </Row>
    </div>
  )
}

export default FormController
