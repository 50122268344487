import { FC } from 'react'

import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import NpaFormLayoutWithFormProvider from '@components/Forms/NpaForm/Layout/LayoutWithFormProvider'

import NpaFormActions from './Actions'
import NpaFormManager from './Manager'
import NpaFormStatement from './Statement'

interface NpaFormFC extends FC<NpaFormLayoutProps> {
  Manager: typeof NpaFormManager
  Actions: typeof NpaFormActions
  Statement: typeof NpaFormStatement
}

const NpaForm: NpaFormFC = (props) => <NpaFormLayoutWithFormProvider {...props} />

NpaForm.Manager = NpaFormManager
NpaForm.Actions = NpaFormActions
NpaForm.Statement = NpaFormStatement

export default NpaForm
