import React, { FC, memo } from 'react'

import Icon from '@components/Icon'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import technicalIcon from '@icons/TechnicalWork.svg'
import { IInitialTechnicalWork } from '@services/TechnicalWork/TechnicalWork.entity'
import { TechnicalWorkService } from '@services/TechnicalWork/TechnicalWork.service'
import dayjs from 'dayjs'

import styles from './HomeNotification.module.scss'

//TW - TechnicalWork

require('dayjs/locale/ru')
dayjs.locale('ru')

type TWHomeBannerProps = Omit<IInitialTechnicalWork, 'timeToStart' | 'timeToEnd'>

const TWHomeBanner: FC<TWHomeBannerProps> = ({ startDate, endDate, isRunning, fallbackStatus }) => {
  const bannerText = isRunning
    ? 'Cистема будет доступна после окончания технических работ'
    : 'Запланированы технические работы'

  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
  } = useManualTooltipControl<HTMLDivElement>()

  const technicalWorkService = new TechnicalWorkService({ startDate, endDate, isRunning })

  const formattedDate = technicalWorkService.getFormattedAllTime('D MMMM, HH:mm')

  const [formattedStartDate, formattedEndDate] = formattedDate.split('-')

  const dateText = isRunning ? formattedEndDate : formattedDate

  if (!startDate && !endDate && !fallbackStatus) return null

  return (
    <div className={styles.homeNotification}>
      <div className={styles.technicalIcon_wrapper}>
        <Icon src={technicalIcon} size="initial" className={styles.technicalIcon} wrapper="span" />
      </div>
      <div
        className={styles.homeNotification_content}
        onMouseEnter={handleOpenTooltip}
        onMouseLeave={handleCloseTooltip}
      >
        <Tooltip
          open={!fallbackStatus && tooltipIsOpen}
          position={'top'}
          targetClassName={styles.tooltip__target}
          content={
            <>
              начало: {formattedStartDate} <br /> окончание: {formattedEndDate}
            </>
          }
        >
          <div className={styles.bannerText}>
            <span>{bannerText}</span>
            {!fallbackStatus && (
              <span>
                <span className="font-semibold">{dateText}</span> (МСК,GMT+3)
              </span>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default memo(TWHomeBanner)
