import { DependentUrls } from '@constants/urls'
import { FetcherWithInterceptors } from '@packages/api/fetcher'

const readNotification = (notificationId: string) =>
  FetcherWithInterceptors.put({
    url: {
      url: DependentUrls.ReadNotification,
      params: { notificationId },
    },
  })

export { readNotification }
