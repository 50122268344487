import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import switchStyles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import objectItemStyles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  parametersOfCostRecoveryApplicationBlockValues,
  parametersOfCostRecoveryApplicationConstValues,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import styles from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/Forms/Forms.module.scss'
import ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFields from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/Forms/GeneralFields'
import {
  compensationObjectPaidTaxValidationMap,
  compensationStatementExpenseSubTypeValidationMap,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/validation'
import { ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import {
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsPaidTaxLandValuesPathName,
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsPaidTaxLegalPropertyPathName,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { useBooleanState } from '@hooks/useBooleanState'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

interface ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectSzpkProps {
  id: string
  title: string
  name: ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
  onRemove?: VoidFunction
}

const ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectSzpk: FC<
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectSzpkProps
> = ({ id, title, name, formInstance, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const {
    getHierarchyReferenceBookSelectProps,
    getSubscribableControlProps,
    getCalendarInputProps,
    getSingleSelectProps,
    getAmountInputProps,
    getTextareaProps,
    getSwitchProps,
    getInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
  })

  const { fields: paidTaxLegalProperties } = useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsPaidTaxLegalPropertyPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.compensationObjectPaidTaxLegalPropertyValues`,
    keyName: 'keyNameId',
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const { fields: paidTaxLandProperties } = useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsPaidTaxLandValuesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.compensationObjectPaidTaxLandValues`,
    keyName: 'keyNameId',
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const statementFormName = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementForm,
    control: formInstance?.control,
  })

  const objectTypeSystemName = useWatch({
    name: `${name}.compensationObjectTypeSystemName`,
    control: formInstance?.control,
  })

  const isTaxLegalPropertyObject = useWatch({
    name: `${name}.isTaxLegalPropertyObject`,
    control: formInstance?.control,
  })

  const isTaxLandPropertyObject = useWatch({
    name: `${name}.isTaxLandPropertyObject`,
    control: formInstance?.control,
  })

  const isObjectFromSzpkFieldRender =
    objectTypeSystemName ===
    parametersOfCostRecoveryApplicationConstValues.COMPENSATION_OBJECT_FROM_SZPK

  const isObjectAbsentSzpkFieldRender =
    objectTypeSystemName ===
    parametersOfCostRecoveryApplicationConstValues.COMPENSATION_OBJECT_ABSENT_SZPK

  const isTaxFieldsRender =
    statementFormName === parametersOfCostRecoveryApplicationConstValues.TAX_DEDUCTION

  const isShowPaidTaxLegalCaption = !paidTaxLegalProperties.length

  const isShowPaidTaxLandCaption = !paidTaxLandProperties.length

  const {
    booleanState: isShowObjectItemContent,
    reverseBooleanState: toggleShowObjectItemContent,
  } = useBooleanState(true)

  const handleRemoveObject = async () => {
    await onRemove?.()
  }

  return (
    <FlippedWithCollapse flipId={id}>
      <Stack direction={'vertical'}>
        <div className={objectItemStyles.objectItem__header}>
          <ObjectStepWrapperHeaderNode
            buttonProps={{
              onClick: toggleShowObjectItemContent,
              trailingIcon: {
                className: cn({
                  [objectItemStyles['objectItem__header-button__icon--active']]:
                    isShowObjectItemContent,
                }),
              },
            }}
          >
            {title}
          </ObjectStepWrapperHeaderNode>

          {editMode && (
            <AsyncWrapper promise={handleRemoveObject}>
              {({ isLoading, wrappedPromise }) => (
                <Button
                  disabled={isLoading}
                  variant={'buttonSMedium'}
                  size={'2xs'}
                  color={'negative'}
                  view={'plain'}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  leadingIcon={{
                    noCurrentColorSvgFill: true,
                    src: DeleteIcon,
                  }}
                  onClick={wrappedPromise}
                >
                  Удалить
                </Button>
              )}
            </AsyncWrapper>
          )}
        </div>
        <div className={objectItemStyles.objectItem__content}>
          <CollapseWrapper isExpanded={isShowObjectItemContent}>
            <Stack className={styles.object__contentStack} direction={'vertical'} gap={3}>
              {isObjectFromSzpkFieldRender && (
                <FieldView.RowWithBottomBorder
                  disableTopPadding
                  disableBottomDefaultStyles
                  firstColumnSize={4}
                  secondColumnSize={8}
                  title={'Объект (мероприятие)'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.projectObjectValueName`,
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.objectName || item.otherActivityName || '',
                              value: item.id,
                            }),
                            controllerProps: {
                              name: `${name}.projectObjectValueName`,
                              rules:
                                compensationStatementExpenseSubTypeValidationMap.projectObjectValueName,
                            },
                            selectProps: {
                              onChangeFormValue: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${name}.projectObjectValueName`)
                                }, 0),
                            },
                          })}
                        />
                      )
                    }}
                  </SubscribableControl>
                </FieldView.RowWithBottomBorder>
              )}
              {isObjectAbsentSzpkFieldRender && (
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.objectName`,
                      })}
                    >
                      <ControlledTextarea
                        {...getTextareaProps({
                          name: `${name}.objectName`,
                          rules: compensationStatementExpenseSubTypeValidationMap.objectName,
                          textareaProps: {
                            label: 'Наименование объекта (мероприятия), которое отсутствует в СЗПК',
                          },
                          onBlur: () =>
                            setTimeout(() => handleChangeValue?.(`${name}.objectName`), 0),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(`${name}.objectName`)
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.finalBalanceHolder`,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: `${name}.finalBalanceHolder`,
                        rules: compensationStatementExpenseSubTypeValidationMap.finalBalanceHolder,
                        textareaProps: {
                          label: 'Конечный балансодержатель объекта инфраструктуры',
                        },
                        onBlur: () =>
                          setTimeout(() => handleChangeValue?.(`${name}.finalBalanceHolder`), 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${name}.finalBalanceHolder`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <FieldView.RowWithBottomBorder
                firstColumnSize={8}
                secondColumnSize={4}
                title={'Номер из перечня объектов инфраструктуры'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.infrastructureKindCode`,
                  })}
                >
                  <ControlledHierarchyReferenceBookSelect
                    {...getHierarchyReferenceBookSelectProps({
                      controllerProps: {
                        name: `${name}.infrastructureKindCode`,
                        rules:
                          compensationStatementExpenseSubTypeValidationMap.infrastructureKindCode,
                      },
                      dictionaryName: 'infrastructure_objects',
                      customDisplayValueFC: ({ code }) => code || '',
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${name}.infrastructureKindCode`), 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFields
                formInstance={formInstance}
                name={name}
              />
              {isTaxFieldsRender && (
                <>
                  <Row>
                    <Col xs={12}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.isTaxLegalPropertyObject`,
                        })}
                      >
                        <ControlledSwitch
                          {...getSwitchProps({
                            name: `${name}.isTaxLegalPropertyObject`,
                            switchProps: {
                              wrapperClassName: switchStyles.form__switch,
                              label: 'Является объектом налогообложения по налогу на имущество',
                            },
                            onChange: () =>
                              setTimeout(
                                () => handleChangeValue?.(`${name}.isTaxLegalPropertyObject`),
                                0,
                              ),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                  <CollapseWrapper
                    isExpanded={isTaxLegalPropertyObject}
                    defaultExpanded={isTaxLegalPropertyObject}
                  >
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={8}
                      secondColumnSize={4}
                      title={'Срок налогового вычета'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.compensationObjectTaxLegalPropertyRefundPeriod`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${name}.compensationObjectTaxLegalPropertyRefundPeriod`,
                            rules:
                              compensationStatementExpenseSubTypeValidationMap.compensationObjectTaxLegalPropertyRefundPeriod,
                            inputProps: {
                              integersOnly: true,
                              hideSuffix: true,
                              hasNegativeValue: false,
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(
                                  `${name}.compensationObjectTaxLegalPropertyRefundPeriod`,
                                )
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${name}.compensationObjectTaxLegalPropertyRefundPeriod`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={8}
                      secondColumnSize={4}
                      title={'Кадастровый номер объекта недвижимого имущества'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.compensationObjectTaxLegalPropertyCadastralNumber`,
                        })}
                      >
                        <ControlledInput
                          {...getInputProps({
                            name: `${name}.compensationObjectTaxLegalPropertyCadastralNumber`,
                            rules:
                              compensationStatementExpenseSubTypeValidationMap.compensationObjectTaxLegalPropertyCadastralNumber,
                            inputProps: {},
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(
                                  `${name}.compensationObjectTaxLegalPropertyCadastralNumber`,
                                )
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${name}.compensationObjectTaxLegalPropertyCadastralNumber`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={8}
                      secondColumnSize={4}
                      title={'Иной идентификационный номер'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.compensationObjectTaxLegalPropertyOtherIdNumber`,
                        })}
                      >
                        <ControlledInput
                          {...getInputProps({
                            name: `${name}.compensationObjectTaxLegalPropertyOtherIdNumber`,
                            rules:
                              compensationStatementExpenseSubTypeValidationMap.compensationObjectTaxLegalPropertyOtherIdNumber,
                            inputProps: {},
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(
                                  `${name}.compensationObjectTaxLegalPropertyOtherIdNumber`,
                                )
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${name}.compensationObjectTaxLegalPropertyOtherIdNumber`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={8}
                      secondColumnSize={4}
                      title={'Общая сумма налога на имущество по объекту'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.compensationObjectPaidTaxLegalPropertyTotalValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${name}.compensationObjectPaidTaxLegalPropertyTotalValue`,
                            rules:
                              compensationStatementExpenseSubTypeValidationMap.compensationObjectPaidTaxLegalPropertyTotalValue,
                            inputProps: {
                              disabled: true,
                              leftAddons: (
                                <FormIconWithTooltip
                                  tooltipContent={
                                    'Общая сумма будет посчитана автоматически на основе данных об уплаченном налоге по годам'
                                  }
                                />
                              ),
                            },
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.compensationObjectPaidTaxLegalPropertyValues`,
                      })}
                    >
                      <Group
                        disableBottomBorder
                        title={'Налог на имущество по объекту (мероприятию)'}
                      >
                        <FlipperList list={paidTaxLegalProperties}>
                          {paidTaxLegalProperties.map((paidTaxLegalItem, index) => {
                            const formName =
                              `${name}.compensationObjectPaidTaxLegalPropertyValues.${index}` as const

                            return (
                              <Row key={paidTaxLegalItem.id}>
                                <Col xs={3}>
                                  <SubscribableControl
                                    {...getSubscribableControlProps({
                                      path: `${formName}.paidTaxYear`,
                                    })}
                                  >
                                    <ControlledCalendarInput
                                      {...getCalendarInputProps({
                                        name: `${formName}.paidTaxYear`,
                                        calendarInputProps: {
                                          disabled: true,
                                          inputMask: YEAR_MASK,
                                          label: 'Год',
                                        },
                                        calendarProps: {
                                          typeOfPicker: 'years',
                                          dateFormat: 'YYYY',
                                        },
                                      })}
                                    />
                                  </SubscribableControl>
                                </Col>
                                <Col xs={9}>
                                  <SubscribableControl
                                    {...getSubscribableControlProps({
                                      path: `${formName}.paidTaxYearValue`,
                                    })}
                                  >
                                    <ControlledAmountInput
                                      {...getAmountInputProps({
                                        name: `${formName}.paidTaxYearValue`,
                                        rules:
                                          compensationObjectPaidTaxValidationMap.paidTaxYearValue,
                                        inputProps: {
                                          label: 'Объем за год',
                                        },
                                        onBlur: () =>
                                          setTimeout(() => {
                                            handleChangeValue?.(`${formName}.paidTaxYearValue`)
                                          }, 0),
                                        onChange: () =>
                                          setTimeout(() => {
                                            debouncedHandleChangeValue?.(
                                              `${formName}.paidTaxYearValue`,
                                            )
                                          }, 0),
                                      })}
                                    />
                                  </SubscribableControl>
                                </Col>
                              </Row>
                            )
                          })}
                        </FlipperList>
                        <CollapseWrapper
                          isExpanded={isShowPaidTaxLegalCaption}
                          defaultExpanded={isShowPaidTaxLegalCaption}
                        >
                          <Row>
                            <Col xs={12}>
                              <Typography.Caption
                                variant="captionSRegular"
                                className={styles.caption__text}
                              >
                                Укажите год, когда были понесены затраты по объекту <br />
                                После этого вы сможете указать объемы налогов по годам
                              </Typography.Caption>
                            </Col>
                          </Row>
                        </CollapseWrapper>
                      </Group>
                    </SubscribableControl>
                  </CollapseWrapper>
                  <Row>
                    <Col xs={12}>
                      <Border />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.isTaxLandPropertyObject`,
                        })}
                      >
                        <ControlledSwitch
                          {...getSwitchProps({
                            name: `${name}.isTaxLandPropertyObject`,
                            switchProps: {
                              wrapperClassName: switchStyles.form__switch,
                              label: 'Является объектом налогообложения по земельному налогу',
                            },
                            onChange: () =>
                              setTimeout(
                                () => handleChangeValue?.(`${name}.isTaxLandPropertyObject`),
                                0,
                              ),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                  <CollapseWrapper
                    isExpanded={isTaxLandPropertyObject}
                    defaultExpanded={isTaxLandPropertyObject}
                  >
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={8}
                      secondColumnSize={4}
                      title={'Срок налогового вычета'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.compensationObjectTaxLegalPropertyRefundPeriod`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${name}.compensationObjectTaxLandRefundPeriod`,
                            rules:
                              compensationStatementExpenseSubTypeValidationMap.compensationObjectTaxLandRefundPeriod,
                            inputProps: {
                              integersOnly: true,
                              hideSuffix: true,
                              hasNegativeValue: false,
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.compensationObjectTaxLandRefundPeriod`)
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${name}.compensationObjectTaxLandRefundPeriod`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={8}
                      secondColumnSize={4}
                      title={'Кадастровый номер земельного участка'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.compensationObjectTaxLandAreaNumber`,
                        })}
                      >
                        <ControlledInput
                          {...getInputProps({
                            name: `${name}.compensationObjectTaxLandAreaNumber`,
                            rules:
                              compensationStatementExpenseSubTypeValidationMap.compensationObjectTaxLandAreaNumber,
                            inputProps: {},
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.compensationObjectTaxLandAreaNumber`)
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${name}.compensationObjectTaxLandAreaNumber`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={8}
                      secondColumnSize={4}
                      title={'Общая сумма земельного налога по объекту'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.compensationObjectPaidTaxLandTotalValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${name}.compensationObjectPaidTaxLandTotalValue`,
                            rules:
                              compensationStatementExpenseSubTypeValidationMap.compensationObjectPaidTaxLandTotalValue,
                            inputProps: {
                              disabled: true,
                              leftAddons: (
                                <FormIconWithTooltip
                                  tooltipContent={
                                    'Общая сумма будет посчитана автоматически на основе данных об уплаченном налоге по годам'
                                  }
                                />
                              ),
                            },
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.compensationObjectPaidTaxLandValues`,
                      })}
                    >
                      <Group disableBottomBorder title={'Земельный налог по объекту (мероприятию)'}>
                        <FlipperList list={paidTaxLandProperties}>
                          {paidTaxLandProperties.map((paidTaxLandItem, index) => {
                            const formName =
                              `${name}.compensationObjectPaidTaxLandValues.${index}` as const

                            return (
                              <Row key={paidTaxLandItem.id}>
                                <Col xs={3}>
                                  <SubscribableControl
                                    {...getSubscribableControlProps({
                                      path: `${formName}.paidTaxYear`,
                                    })}
                                  >
                                    <ControlledCalendarInput
                                      {...getCalendarInputProps({
                                        name: `${formName}.paidTaxYear`,
                                        calendarInputProps: {
                                          disabled: true,
                                          inputMask: YEAR_MASK,
                                          label: 'Год',
                                        },
                                        calendarProps: {
                                          typeOfPicker: 'years',
                                          dateFormat: 'YYYY',
                                        },
                                      })}
                                    />
                                  </SubscribableControl>
                                </Col>
                                <Col xs={9}>
                                  <SubscribableControl
                                    {...getSubscribableControlProps({
                                      path: `${formName}.paidTaxYearValue`,
                                    })}
                                  >
                                    <ControlledAmountInput
                                      {...getAmountInputProps({
                                        name: `${formName}.paidTaxYearValue`,
                                        rules:
                                          compensationObjectPaidTaxValidationMap.paidTaxYearValue,
                                        inputProps: {
                                          label: 'Объем за год',
                                        },
                                        onBlur: () =>
                                          setTimeout(() => {
                                            handleChangeValue?.(`${formName}.paidTaxYearValue`)
                                          }, 0),
                                        onChange: () =>
                                          setTimeout(() => {
                                            debouncedHandleChangeValue?.(
                                              `${formName}.paidTaxYearValue`,
                                            )
                                          }, 0),
                                      })}
                                    />
                                  </SubscribableControl>
                                </Col>
                              </Row>
                            )
                          })}
                        </FlipperList>
                        <CollapseWrapper
                          isExpanded={isShowPaidTaxLandCaption}
                          defaultExpanded={isShowPaidTaxLandCaption}
                        >
                          <Row>
                            <Col xs={12}>
                              <Typography.Caption
                                variant="captionSRegular"
                                className={styles.caption__text}
                              >
                                Укажите год, когда были понесены затраты по объекту <br />
                                После этого вы сможете указать объемы налогов по годам
                              </Typography.Caption>
                            </Col>
                          </Row>
                        </CollapseWrapper>
                      </Group>
                    </SubscribableControl>
                  </CollapseWrapper>
                </>
              )}
            </Stack>
          </CollapseWrapper>
        </div>
      </Stack>
    </FlippedWithCollapse>
  )
}

export default ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectSzpk
