import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import {
  aAgreementOnCreationOtherEstateObjectsBlockValues,
  mapOfAAgreementOnCreationOtherEstateObjectsMenu,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import AAgreementOnCreationOtherEstateObjectsForms from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import type { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'

const mapOfAAgreementOnCreationOtherEstateObjectsForms: FormItemProps = {
  '1': {
    node: <AAgreementOnCreationOtherEstateObjectsForms.One />,
  },
  '2': {
    node: <AAgreementOnCreationOtherEstateObjectsForms.Second />,
  },
  '3': {
    node: <AAgreementOnCreationOtherEstateObjectsForms.Third />,
  },
  '4': {
    node: <AAgreementOnCreationOtherEstateObjectsForms.Fourth />,
  },
  '5': {
    node: <AAgreementOnCreationOtherEstateObjectsForms.Fifth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const AAgreementOnCreationOtherEstateObjectsForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AAgreementOnCreationOtherEstateObjectsFormValues>()

  const {
    state: { formIsLoading },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const isFederalSZPK = useWatch({
    name: aAgreementOnCreationOtherEstateObjectsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementOnCreationOtherEstateObjectsBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  }).length

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAAgreementOnCreationOtherEstateObjectsForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      formIsLoading={formIsLoading}
      mapOfForms={currentForms}
      mapOfMenu={initialMenu ?? mapOfAAgreementOnCreationOtherEstateObjectsMenu}
    />
  )
}

export default memo(AAgreementOnCreationOtherEstateObjectsForm)
