import { useCallback } from 'react'
import { type UseFormReturn, useFieldArray } from 'react-hook-form'

import type {
  HandleAddFileItemProps,
  HandleInsertFileItemProps,
  HandleRemoveFileItemProps,
  HandleUpdateFileItemProps,
} from '@components/Attachments/Attachment/FolderItem/AttachmentFolderItem/types'
import { attachmentsFormNames } from '@components/Attachments/constants'
import type { AttachmentsFormValue } from '@components/Attachments/types'

const useAttachmentFolderItemFieldArray = (formInstance: UseFormReturn<AttachmentsFormValue>) => {
  const {
    fields: documents,
    append,
    update,
    insert,
    remove,
  } = useFieldArray({
    control: formInstance.control,
    name: attachmentsFormNames.folderItems,
    keyName: 'keyNameId',
  })

  const handleAddFileItemInFolder = useCallback(
    ({ attachmentFileItem }: HandleAddFileItemProps) => {
      append(attachmentFileItem)
    },
    [append],
  )

  const handleUpdateFileItemInFolder = useCallback(
    ({ attachmentFileItem, attachmentFileIndex }: HandleUpdateFileItemProps) => {
      update(attachmentFileIndex, attachmentFileItem)
    },
    [update],
  )

  const handleInsertFileItemInFolder = useCallback(
    ({ attachmentFileItem, attachmentFileIndex }: HandleInsertFileItemProps) => {
      insert(attachmentFileIndex, attachmentFileItem)
    },
    [insert],
  )

  const handleRemoveFileItemInFolder = useCallback(
    ({ attachmentFileIndex }: HandleRemoveFileItemProps) => {
      remove(attachmentFileIndex)
    },
    [remove],
  )

  return {
    documents,
    handleAddFileItemInFolder,
    handleUpdateFileItemInFolder,
    handleInsertFileItemInFolder,
    handleRemoveFileItemInFolder,
  }
}

export { useAttachmentFolderItemFieldArray }
