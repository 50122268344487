import type { Notification as NotificationProps } from '@components/NewNotifications/model'
import { Notification } from '@components/NewNotifications/Notification'
import cn from 'classnames'

import styles from '../MultipleNotification.module.scss'

interface StackPreviewProps extends NotificationProps {
  dataTestId?: string
}

export const StackPreview = ({ dataTestId, ...props }: StackPreviewProps) => (
  <div data-testid={dataTestId} className={styles.Preview}>
    <Notification {...props} />
    <div className={cn(styles.Layer)} />
    <div className={cn(styles.Layer)} />
  </div>
)
