import React, { FC, memo } from 'react'

import UiActionButton from '@components/Projects/[id]/ActionBanner/Button'
import { useUiActionsManager } from '@components/Projects/[id]/ActionBanner/Manager'
import { DocumentsSetsType } from '@constants/types'
import EditIcon from '@icons/EditIcon.svg'
import SignedDocumentIcon from '@icons/SignedDocumentIcon.svg'
import { useDocumentActions } from '@services/DocumentActions/DocumentAction.hook'
import { TDocumentStackAction } from '@services/DocumentActions/DocumentActions.entity'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'

interface AttachmentsSetActionsProps {
  typeOfSets: string
}

const { fromActionToActionsStack } = DocumentActionsService

const AttachmentsSetActions: FC<AttachmentsSetActionsProps> = ({ typeOfSets }) => {
  const {
    state: { setAreaActions },
  } = useUiActionsManager()

  const { createActionsStack } = useDocumentActions()

  const isDepartmentResponsesSet = typeOfSets === DocumentsSetsType.DEPARTMENTS_RESPONSES

  const handleUiActionClick = (actions: TDocumentStackAction[]) => async () => {
    try {
      await createActionsStack(actions).runStack()
    } catch {}
  }

  if (!isDepartmentResponsesSet) return null

  return (
    <div>
      {setAreaActions.map((action, index) => {
        const isCancelAction = action.action === 'cancel'
        const actionsForStack = fromActionToActionsStack(action)

        return (
          <UiActionButton
            key={index}
            uiAction={action}
            buttonProps={{
              color: 'default',
              geometry: 'square',
              size: 'm',
              view: 'plain',
              dataTestId: `AttachmentsSet-action-button-${action.actionId}`,
              leadingIcon: {
                src: isCancelAction ? EditIcon : SignedDocumentIcon,
              },
              onClick: handleUiActionClick(actionsForStack),
            }}
          />
        )
      })}
    </div>
  )
}

export default memo(AttachmentsSetActions)
