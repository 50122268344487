import React, { DetailedHTMLProps, FC, HTMLAttributes, memo, ReactNode } from 'react'

import cn from 'classnames'

import styles from './LoginWrapper.module.scss'

interface LoginWrapperProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  view: 'primary' | 'secondary'
  children: ReactNode
}

const LoginWrapper: FC<LoginWrapperProps> = ({ view = 'primary', children, ...divRest }) => {
  const isPrimary = view === 'primary'

  return (
    <div
      {...divRest}
      className={cn(
        styles.wrapper,
        {
          [styles.wrapper_primary]: isPrimary,
          [styles.wrapper_secondary]: !isPrimary,
        },
        divRest.className,
      )}
    >
      {children}
    </div>
  )
}

export default memo(LoginWrapper)
