import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import {
  FormModalStatementWithInitialMenuProps,
} from '@components/DocumentFormComponents/types'
import { useAAVolumeOfCapitalInvestmentsManager } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Manager'

import {
  aaVolumeOfCapitalInvestmentsBlockValues,
  mapOfAAVolumeOfCapitalInvestmentsMenu,
} from '../const'
import AAVolumeOfCapitalInvestmentsForms from '../Forms'
import { AAVolumeOfCapitalInvestmentsFormValues } from '../types'

const mapOfAAVolumeOfCapitalInvestmentsForms: FormItemProps = {
  '1': {
    node: <AAVolumeOfCapitalInvestmentsForms.One />,
  },
  '2': {
    node: <AAVolumeOfCapitalInvestmentsForms.Two />,
  },
  '3': {
    node: <AAVolumeOfCapitalInvestmentsForms.Three />,
  },
  '4': {
    node: <AAVolumeOfCapitalInvestmentsForms.Four />,
  },
  '5': {
    node: <AAVolumeOfCapitalInvestmentsForms.Five />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '6': {
    node: <AAVolumeOfCapitalInvestmentsForms.Six />,
  },
} as const

const AAVolumeOfCapitalInvestmentsForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AAVolumeOfCapitalInvestmentsFormValues>()

  const isFederalSZPK = useWatch({
    name: aaVolumeOfCapitalInvestmentsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })
  const isMunicipalitiesRender = !!useWatch({
    name: aaVolumeOfCapitalInvestmentsBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAAVolumeOfCapitalInvestmentsForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender])

  const {
    state: { formIsLoading },
  } = useAAVolumeOfCapitalInvestmentsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={initialMenu ?? mapOfAAVolumeOfCapitalInvestmentsMenu}
      mapOfForms={currentForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(AAVolumeOfCapitalInvestmentsForm)
