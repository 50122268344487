import React, { memo } from 'react'
import { useParams } from 'react-router'

import Error500Fallback from '@components/DataFallback/500Error'
import Projects from '@components/Projects'
import Project from '@components/Projects/[id]'
import UiActionsManager from '@components/Projects/[id]/ActionBanner/Manager'
import DfosManager from '@components/Projects/[id]/DfosManager'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { useProject } from '@hooks/new/swr/useProject'

import styles from './Report.module.scss'

const ReportPage = () => {
  const { projectId } = useParams()

  const { error: projectError } = useProject({
    key: { projectId, _key: 'project' },
  })

  return (
    <DfosManager>
      <UiActionsManager>
        <Error500Fallback
          title={'Не удалось загрузить отчет'}
          error={isIntervalServerError(projectError?.response?.status)}
          className={styles.error__template}
        >
          <div className={styles.report__actionBanner}>
            <Project.ActionBanner />
          </div>
          <Projects.ProjectContainer className={styles.report__documentLayout}>
            <Project.DocumentsLayout />
          </Projects.ProjectContainer>
        </Error500Fallback>
      </UiActionsManager>
    </DfosManager>
  )
}

export default memo(ReportPage)
