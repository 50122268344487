import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useInclusionAgreementOfRelatedSZPKRegisterManager } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/Manager'
import { InclusionAgreementOfRelatedSZPKRegisterFormValues } from '@components/Forms/InclusionAgreementOfRelatedSZPKRegisterForm/types'

import { mapOfInclusionAgreementOfRelatedSZPKRegisterMenu } from '../const'
import InclusionAgreementOfRelatedSZPKRegisterForms from '../Forms'

const mapOfInclusionAgreementOfRelatedSZPKRegisterForms: FormItemProps = {
  '1': {
    node: <InclusionAgreementOfRelatedSZPKRegisterForms.First />,
  },
  '2': {
    node: <InclusionAgreementOfRelatedSZPKRegisterForms.Second />,
  },
} as const

const InclusionAgreementOfRelatedSZPKRegisterForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<InclusionAgreementOfRelatedSZPKRegisterFormValues>()

  const {
    state: { formIsLoading },
  } = useInclusionAgreementOfRelatedSZPKRegisterManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      isAutoGeneratedForm
      mapOfMenu={mapOfInclusionAgreementOfRelatedSZPKRegisterMenu}
      mapOfForms={mapOfInclusionAgreementOfRelatedSZPKRegisterForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(InclusionAgreementOfRelatedSZPKRegisterForm)
