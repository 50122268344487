import React from 'react'
import { useFormContext } from 'react-hook-form'

import SubjectsSection from '@components/DocumentFormComponents/FormSections/SubjectsSection'
import { useSupplementalCostSharingAManager } from '@components/Forms/SupplementalCostSharingAForm/Manager'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import {
  SupplementalCostSharingAFieldArrayControlUpdateWatcher,
  SupplementalCostSharingAFieldsControlUpdateWatcher,
} from '@components/Forms/SupplementalCostSharingAForm/watcher'

const Two = () => {
  const formInstance = useFormContext<SupplementalCostSharingAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSupplementalCostSharingAManager()

  if (!formInstance) return null

  return (
    <SubjectsSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'2.subjects'}
      formInstance={formInstance}
      watcher={SupplementalCostSharingAFieldsControlUpdateWatcher}
      fieldArrayWatcher={SupplementalCostSharingAFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Two
