import React, { FC, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import { ControlledSingleSelect } from '@components/NewDesign/Select/SingleSelect/ControlledSingleSelect'
import Typography from '@components/NewDesign/Typography'
import DependencyTypeSelect from '@components/NewDesignedModals/CreateAgreementModal/Addons/DependencyTypeSelect'
import { dfoAttributesMap } from '@components/NewDesignedModals/CreateAgreementModal/const'
import {
  CategoriesNames,
  compareCategoryFunction,
  IAgreementIntoCategories,
} from '@components/NewDesignedModals/CreateAgreementModal/helpers'
import { NewDfosType } from '@constants/types'
import { useCreateDfo } from '@hooks/new/dfo/useCreateDfo'
import { useDfoAvailableForCreate } from '@hooks/new/swr/useDfoAvailableForCreate'
import { useDfoTypes } from '@hooks/new/swr/useDfoTypes'
import { CreateProjectDfoParameters, TAllDfoTypes } from '@services/Dfo/Dfo.entity'
import { ReorganizationTypesMap } from '@services/Dictionaries/dictionaries.const'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './CreateAgreementModal.module.scss'

export type CreateAgreementModalProps = PopupProps & {
  projectId: string
  readySplitCategories: IAgreementIntoCategories
}

export interface CreateAgreementFormValues {
  typeOfAgreement: string
  dependencyTypeValue?: string
}

//Map для категорий селекта.
const mapOfDfoTypesAndName = {
  EXTRA: 'Дополнительное соглашение',
  PETITION: 'Ходатайство',
  OTHER: 'Прочее',
} as const

//Хук для вызова модального окна
const useCreateAgreementControlModal = () => {
  const popupManager = usePopupManager()

  const handleOpenAgreementModal = (
    projectId: string,
    readySplitCategories: IAgreementIntoCategories,
  ) => {
    popupManager.open<CreateAgreementModalProps>(CreateAgreementModal, {
      projectId: projectId || '',
      readySplitCategories,
    })
  }

  return { handleOpenAgreementModal }
}

const CreateAgreementModal: FC<CreateAgreementModalProps> = ({
  isOpen,
  onClose,
  projectId,
  readySplitCategories,
}) => {
  const { handleCreateDfo } = useCreateDfo(projectId)

  const { control, watch, getValues, handleSubmit } = useForm<CreateAgreementFormValues>({
    defaultValues: {
      typeOfAgreement: '',
      dependencyTypeValue: '',
    },
  })

  const { dfoTypes } = useDfoTypes({ key: { _key: 'dfoTypes' } })
  const { dfoAvailableForCreate } = useDfoAvailableForCreate({
    key: {
      projectId,
      _key: 'availableDfoForCreate',
    },
    config: {
      isPaused: () => !projectId,
    },
  })

  const typeOfAgreement = watch('typeOfAgreement')
  const dependencyType = watch('dependencyTypeValue')

  const preparedOnClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  const handleApplyChanges = useCallback(async () => {
    const allFormValues = getValues()
    const currentTypeOfFormValues =
      allFormValues.dependencyTypeValue === ReorganizationTypesMap.SPLIT_OFF
        ? NewDfosType.ORG_REORGANIZATION_SPLIT_OFF
        : (allFormValues.typeOfAgreement as TAllDfoTypes)

    const currentParametersDfoToRequest =
      dfoAttributesMap[allFormValues.typeOfAgreement] &&
      allFormValues.dependencyTypeValue !== ReorganizationTypesMap.SPLIT_OFF
        ? [
            {
              name: dfoAttributesMap[allFormValues.typeOfAgreement],
              value: allFormValues.dependencyTypeValue,
            } as CreateProjectDfoParameters,
          ]
        : undefined

    try {
      await handleCreateDfo(currentTypeOfFormValues, currentParametersDfoToRequest)()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          type: allFormValues.typeOfAgreement,
          dfoParameters: currentParametersDfoToRequest,
        },
        componentInfo: {
          componentName: 'CreateAgreementModal',
          componentType: 'handleApplyChanges',
          moduleName: 'CreateAgreementModal',
        },
      })(error)
    }
  }, [getValues, handleCreateDfo])

  const preparedOptionsToRender = useMemo(() => {
    if (!dfoTypes || !dfoAvailableForCreate) return []

    return Object.entries(readySplitCategories)
      .sort(compareCategoryFunction)
      .map(([titleKey, optionsObject]) => {
        const preparedOptions = Object.entries(optionsObject).map(([value, displayValue]) => {
          return {
            displayValue:
              titleKey === CategoriesNames.EXTRA || titleKey === CategoriesNames.PETITION
                ? displayValue.dfoSubtitle || ''
                : displayValue.name || '',
            value,
          }
        })

        return {
          title: mapOfDfoTypesAndName[titleKey],
          options: preparedOptions,
        }
      })
  }, [dfoAvailableForCreate, dfoTypes])

  const modalActions = useMemo(() => {
    const disabledAddChangesCondition =
      !typeOfAgreement || (dfoAttributesMap[typeOfAgreement] && !dependencyType)

    return [
      {
        view: 'gray',
        children: 'Отмена',
        dataTestId: 'CreateAgreementModal-cancel-button',
        onClick: onClose,
      },
      {
        children: 'Добавить изменение',
        dataTestId: 'CreateAgreementModal-apply-button',
        onClick: handleSubmit(handleApplyChanges),
        disabled: disabledAddChangesCondition,
      },
    ] as ButtonProps[]
  }, [dependencyType, handleApplyChanges, handleSubmit, onClose, typeOfAgreement])

  return (
    <Modal.Action
      isOpen={isOpen}
      title={'Добавление изменения к СЗПК'}
      actions={modalActions}
      dataTestId="CreateAgreementModal-modal"
      closeButtonDataTestId="CreateAgreementModal-modal-closeButton"
      onClose={preparedOnClose}
    >
      <Typography.Body variant={'bodyMMedium'}>
        Выберите дополнительное соглашение, ходатайство или другое изменение
      </Typography.Body>
      <div className={styles.createAgreementModal__select}>
        <ControlledSingleSelect
          options={preparedOptionsToRender}
          optionsProps={{
            enableOptionTooltips: {
              value: true,
              valueLength: 76,
            },
            scrollableOptionsContainerClassName: styles['createAgreementModal__select-options'],
            dataTestId: 'CreateAgreementModal-typeOfAgreement-optionsContainer',
          }}
          controllerProps={{
            name: 'typeOfAgreement',
            control: control,
          }}
          inputProps={{
            fixWidth: true,
            label: 'Изменение к СЗПК',
            dataTestId: 'CreateAgreementModal-typeOfAgreement-singleSelect',
            formControlDataTestId: 'CreateAgreementModal-typeOfAgreement-formControl',
          }}
          popoverProps={{
            zIndex: 51,
          }}
        />
      </div>
      <DependencyTypeSelect typeOfChosenDfoType={typeOfAgreement} control={control} />
    </Modal.Action>
  )
}

export default CreateAgreementModal
export { useCreateAgreementControlModal }
