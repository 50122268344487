import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof NoticeOfProjectRefuseConcludeAAFormValues['4'],
  NoticeOfProjectRefuseConcludeAAFormValues
> = {
  ruleLinkExtraReject: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  extraRejectReason: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 5000),
  },
}

export { fourthSectionValidationMap }
