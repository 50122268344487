import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageCompensation,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const stepCompensationValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageCompensation,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  stepCompensationType: {
    required: defaultRHFValidation.required,
  },
}

export { stepCompensationValidationMap }
