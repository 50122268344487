import React from 'react'
import { useFormContext } from 'react-hook-form'

import BeneficialOwnersInfoSection from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection'
import { beneficiaryOfSuccessorOrganizationInformationBlockValues } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/const'
import { useBeneficiaryOfSuccessorOrganizationInformationManager } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/Manager'
import { BeneficiaryOfSuccessorOrganizationInformationFormValues } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/types'
import {
  BeneficiaryOfSuccessorOrganizationInformationFieldArrayControlUpdateWatcher,
  BeneficiaryOfSuccessorOrganizationInformationFieldsControlUpdateWatcher,
} from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/watcher'

const Second = () => {
  const formInstance = useFormContext<BeneficiaryOfSuccessorOrganizationInformationFormValues>()

  const beneficiaryOfSuccessorOrganizationManager =
    useBeneficiaryOfSuccessorOrganizationInformationManager()

  if (!formInstance) return null

  return (
    <BeneficialOwnersInfoSection
      formName={beneficiaryOfSuccessorOrganizationInformationBlockValues['2'].beneficiars}
      formInstance={formInstance}
      manager={beneficiaryOfSuccessorOrganizationManager}
      watcher={BeneficiaryOfSuccessorOrganizationInformationFieldsControlUpdateWatcher}
      fieldArrayWatcher={BeneficiaryOfSuccessorOrganizationInformationFieldArrayControlUpdateWatcher}
    />
  )
}

export default Second
