import { FC, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'

import styles from './ConfidentialModal.module.scss'

export interface ConfidentialModalProps extends PopupProps {
  onSubmit: () => void
}

const ConfidentialModal: FC<ConfidentialModalProps> = ({ isOpen, onSubmit, onClose }) => {
  const handleClose = () => {
    onClose?.()
  }

  const modalActions = useMemo(() => {
    return [
      { children: 'Отказаться', size: 's', view: 'gray', onClick: onClose },
      {
        children: 'Разрешить',
        size: 's',
        onClick: onSubmit,
      },
    ] as ButtonProps[]
  }, [onClose, onSubmit])

  return (
    <Modal.Action
      disableOnClose
      hideCloseIcon
      isOpen={isOpen}
      title={'Разрешение на использование конфиденциальной информации'}
      actionModalContentClassName={styles.modal__content}
      actions={modalActions}
      onClose={handleClose}
    >
      <Typography.Body className={styles['modal__content-text']} variant={'bodyLMedium'}>
        Настоящим заявитель в соответствии с пунктом 3 части 2 статьи 6.1 Федерального закона от
        29.07.2004 №98-ФЗ «О коммерческой тайне» разрешает должностным лицам органов государственной
        власти Российской Федерации, органов государственной власти субъектов Российской Федерации,
        органов местного самоуправления, работникам организаций, уполномоченных осуществлять
        создание и эксплуатацию государственной информационной системы «Капиталовложения», доступ к
        информации, составляющей коммерческую тайну заявителя, направляемой заявителем в
        государственную информационную систему «Капиталовложения», и разрешает использование
        указанными лицами информации, составляющей коммерческую тайну заявителя, в объёме и для
        целей, необходимых для реализации указанными лицами полномочий, предусмотренных Федеральным
        законом от 01.04.2020 №69-ФЗ «О защите и поощрении капиталовложений в Российской Федерации»
        и принятыми в соответствии с ним нормативными правовыми актами, при условии соблюдения
        обязанностей по охране её конфиденциальности.
      </Typography.Body>
    </Modal.Action>
  )
}

export default ConfidentialModal
