import React, { FC, memo } from 'react'

import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'

import styles from './MenuActions.module.scss'

interface MenuActionsProps {
  submitButtonProps?: ButtonProps
  previewButtonProps?: ButtonProps
}

const MenuActions: FC<MenuActionsProps> = ({ submitButtonProps, previewButtonProps }) => {
  const { children: submitText = 'Проверить заполнение', ...restSubmitProps } =
    submitButtonProps || {}

  const {
    children: previewText = 'Предпросмотр документа',
    view = 'tined',
    ...restPreviewProps
  } = previewButtonProps || {}

  return (
    <div className={styles.menu__actions}>
      {submitButtonProps && (
        <Button {...restSubmitProps} dataTestId="form-submit-button">
          {submitText}
        </Button>
      )}
      {previewButtonProps && (
        <Button {...restPreviewProps} dataTestId="form-preview-button" view={view}>
          {previewText}
        </Button>
      )}
    </div>
  )
}

export default memo(MenuActions)
