import React, { FC } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { FormModalLayoutWrapperProps } from '@components/DocumentFormComponents/types'
import { useApplicationConclusionAdditionalAgreementFormManager } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/Manager'

const ApplicationConclusionAdditionalAgreementFormLayoutWrapper: FC<
  FormModalLayoutWrapperProps
> = ({ children, ...rest }) => {
  const {
    state: { lastUpdateDraftTime },
    actions: { handleFormClose },
  } = useApplicationConclusionAdditionalAgreementFormManager()

  const handleClose = () => {
    handleFormClose?.()
  }
  return (
    <>
      <DocumentLayoutContent {...rest} draftIsSaved={!!lastUpdateDraftTime} onClose={handleClose}>
        {children}
      </DocumentLayoutContent>
    </>
  )
}

export default ApplicationConclusionAdditionalAgreementFormLayoutWrapper
