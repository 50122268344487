import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForCertificateOfComplianceContractForRecognitionConcludedForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getUOAdapter(objectFromPropertyState),
    },
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameInstrumental: {
        propertyId: objectFromPropertyState?.investorFullNameInstrumental?.propertyId,
        type: objectFromPropertyState?.investorFullNameInstrumental?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameInstrumental?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForCertificateOfComplianceContractForRecognitionConcludedForm }
