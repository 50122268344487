import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
  VFC,
} from 'react'
import {
  Row,
  TableProps,
  usePagination,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
} from 'react-table'

import Checkbox from '@components/Checkbox/Base/Checkbox'
import Icon from '@components/Icon'
import IconButton from '@components/IconButton'
import Loader from '@components/Loader'
import { closestNum, equals } from '@helpers/commonHelpers'
import { onlyNumberKey } from '@helpers/eventHelpers'
import { useBooleanState } from '@hooks/useBooleanState'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import useLoadMore from '@hooks/useLoadMore'
import sortIcon from '@icons/content/sort.svg'
import dropDownIcon from '@icons/navigation/arrow_drop_down.svg'
import chevronLeftIcon from '@icons/navigation/chevron_left.svg'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'
import { nanoid } from 'nanoid'

import { PAGE_SIZES } from './constants'
import styles from './Table.module.scss'
import { _TableInstance, _TableOptions, _TableState } from './types'

export interface _TableProps extends _TableOptions, TableProps {
  sticky?: boolean
  getRowProps?: any
  resetDeps?: any[]
  totalElements?: number
  hidePagination?: boolean
  isRowSelection?: boolean
  selectedRows?: Row<any>[]
  infinitePagination?: boolean
  currentState?: _TableState
  onRowClick?: (row: Row<any>) => void
  handleNext?: (state: _TableState) => void
  onChangeState?: (state: _TableState) => void
  handlePrevious?: (state: _TableState) => void
  handleChangePage?: (state: _TableState) => void
  setSelectedRows?: Dispatch<SetStateAction<Row<any>[]>>
  handleGoToPage?: (gotoPage: (value: any) => void) => void
  handleChangePageSize?: (state: _TableState, value: number) => void
  onLoadMore?: () => void
  footerContent?: React.ReactNode
  loading?: boolean
  showTotalElements?: boolean
  paginationClassName?: string
  containerClassName?: string
  noDataFallback?: React.ReactNode
}

const Table: VFC<_TableProps> = ({
  data,
  sticky,
  columns,
  className,
  resetDeps,
  getRowProps,
  manualSortBy,
  selectedRows,
  totalElements,
  hidePagination,
  isRowSelection,
  infinitePagination,
  footerContent,
  currentState,
  loading = false,
  paginationClassName,
  containerClassName,
  handleChangePageSize: setSize,
  handleGoToPage: setPage,
  onRowClick,
  handleNext,
  onChangeState,
  handlePrevious,
  setSelectedRows,
  onLoadMore,
  showTotalElements = true,
  noDataFallback,
  ...options
}) => {
  const [shouldShowPagination, setShouldShowPagination] = useState(false)
  const selectRef = useRef<HTMLSelectElement | null>(null)

  const [selectedRowIds, setSelectedRowIds] = useState(() => {
    return (selectedRows ?? []).map((row) => row.id)
  })

  const {
    booleanState: isSelectInFocus,
    setBooleanStateToFalse: setSelectFocusToFalse,
    setBooleanStateToTrue: setSelectFocusToTrue,
  } = useBooleanState()

  const handleStateReducer = useCallback(
    (newState, action, prevState) => {
      if (!equals(newState, prevState)) {
        onChangeState?.({
          ...currentState,
          pageIndex: newState.pageIndex,
          pageSize: newState.pageSize,
        })
      }

      return newState as _TableState
    },
    [currentState, onChangeState],
  )

  const {
    page,
    state,
    canNextPage,
    headerGroups,
    pageCount,
    canPreviousPage,
    nextPage,
    gotoPage,
    prepareRow,
    setPageSize,
    previousPage,
    getTableProps,
    getTableBodyProps,
  } = useTable(
    {
      stateReducer: handleStateReducer,
      data,
      columns,
      // @ts-ignore
      manualSortBy,
      ...options,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState,
    (hooks) => {
      if (!isRowSelection) {
        return
      }

      hooks.visibleColumnsDeps.push(() => [selectedRowIds])
      hooks.visibleColumns.push((_columns) => {
        return [
          {
            className: 'w-[1%]',
            id: `selection-${nanoid()}`,
            Cell: ({ row }) => {
              return (
                <Checkbox
                  // @ts-ignore
                  checked={selectedRowIds.includes(row.original.id)}
                  onChange={handleRowSelect(row.original)}
                />
              )
            },
          },
          ..._columns,
        ]
      })
    },
  ) as _TableInstance
  const inputRef = useRef<HTMLInputElement>(null)
  const { pageIndex, pageSize } = state

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = `${pageIndex + 1}`
    }
  }, [pageIndex, loading])

  useEffectAfterMount(() => {
    if (loading) return
    gotoPage(0)
  }, [pageSize, ...(resetDeps ?? [])])

  useEffectAfterMount(() => {
    const isEmptyDataOnPaginationChange =
      infinitePagination && !data?.length && pageIndex > 0 && !loading

    if (isEmptyDataOnPaginationChange) {
      gotoPage(0)
      onChangeState?.({
        ...currentState,
        pageIndex: 0,
        pageSize: pageSize,
      })
    }
  }, [data, infinitePagination, loading])

  useEffect(() => {
    if (loading) return
    setPage?.(gotoPage)
  }, [setPage])

  useEffect(() => {
    setSelectedRowIds((selectedRows ?? []).map((row) => row.id))
  }, [selectedRows])

  const paginationClickHandler = (fn: () => void) => {
    if (!shouldShowPagination) setShouldShowPagination(true)
    fn()
  }

  const handleRowSelect = (selectedRow: Row<any>) => () => {
    setSelectedRows?.((prevRows) => {
      const prevRowsIds = prevRows.map((row) => row.id)

      if (prevRowsIds.includes(selectedRow.id)) {
        return prevRows.filter((row) => row.id !== selectedRow.id)
      }

      return prevRows.concat([selectedRow])
    })
  }

  const handleRowClick = (row: Row<any>) => () => {
    const selection = window.getSelection()

    if (selection?.toString().length) {
      return
    }

    onRowClick?.(row)
  }

  const handleGoToPage = ({ target }: FocusEvent<HTMLInputElement>) => {
    let _page = Number(target.value)

    _page = _page <= 0 ? 1 : _page
    _page = _page > pageCount ? 1 : _page

    if (inputRef.current) {
      inputRef.current.value = `${_page}`
    }

    gotoPage(_page - 1)
  }

  const handleChangePageSize = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const _pageSize = Number(target.value)
    setPageSize(_pageSize)
    setSize?.(state, _pageSize)

    selectRef.current?.blur()
  }

  const handleGoBack = () => {
    if (loading) return
    previousPage()
    handlePrevious?.(state)
  }

  const handleGoNext = () => {
    if (loading) return
    nextPage()
    handleNext?.(state)
  }

  const { getObserveRef } = useLoadMore({ onLoadMore })

  return (
    <div
      className={cn(
        styles.wrapper,
        {
          [styles['wrapper--sticky']]: !hidePagination && sticky,
        },
        className,
      )}
    >
      <div className={cn(styles.container, containerClassName)}>
        <table {...getTableProps({ className: styles.table })}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(
                      !manualSortBy
                        ? // @ts-ignore
                          column.getSortByToggleProps({
                            // @ts-ignore
                            className: column.className,
                          })
                        : {
                            // @ts-ignore
                            className: column.className,
                          },
                    )}
                  >
                    <div className="flex">
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {/* @ts-ignore */}
                      {column.isSorted && (
                        <Icon
                          size="m"
                          src={sortIcon}
                          // @ts-ignore
                          className={cn(styles.sortIcon, 'visible', {
                            // @ts-ignore
                            ['transform rotate-180 -scale-x-100']: !column.isSortedDesc,
                          })}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {!data.length && noDataFallback}
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row)
              const rowParams = {
                // @ts-ignore
                className: cn(row.className, styles.row, {
                  [styles['row--pointer']]: onRowClick,
                }),
                ...(onRowClick && {
                  onClick: handleRowClick(row),
                }),
              }

              return (
                <tr
                  {...row.getRowProps(
                    getRowProps ? getRowProps(rowParams, row.original) : rowParams,
                  )}
                  ref={getObserveRef(index, page.length - 1)}
                >
                  {row.cells.map((cell) => {
                    const isTruncate =
                      cell.column.limitText && (cell.value?.length ?? 0) > cell.column.limitText

                    return (
                      <td
                        {...(cell.column.id.startsWith('selection') && {
                          onClick: (e) => e.stopPropagation(),
                        })}
                        {...cell.getCellProps({
                          // @ts-ignore
                          className: cell.column.className,
                        })}
                      >
                        <div
                          className={cn({
                            [styles.cell]: isTruncate,
                          })}
                        >
                          {cell.render('Cell')}
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            <Loader
              variant={'lite'}
              className={styles.loader}
              wrapperClassName={styles.loaderWrapper}
              loading={loading}
            />
          </tbody>
        </table>
      </div>
      {!hidePagination && (shouldShowPagination || !loading) && (
        <div className={cn(styles.pagination, paginationClassName)}>
          <div className="flex items-center space-x-2">
            <div className={cn(styles.text, 'mr-1')}>Страница</div>
            <div className={styles.navigation}>
              <IconButton
                size="l"
                priority="secondary"
                icon={chevronLeftIcon}
                disabled={!canPreviousPage || loading}
                onClick={() => paginationClickHandler(handleGoBack)}
              />
              <input
                type="text"
                ref={inputRef}
                className={cn(styles.field, 'w-12', 'p-0.5')}
                disabled={loading}
                onKeyPress={onlyNumberKey}
                onBlur={handleGoToPage}
              />
              {!infinitePagination && (
                <div className={styles.text}>из&nbsp;&nbsp;{pageCount || 1}</div>
              )}
              <IconButton
                size="l"
                priority="secondary"
                icon={chevronRightIcon}
                disabled={!canNextPage || loading || (data ?? []).length < pageSize}
                onClick={() => paginationClickHandler(handleGoNext)}
              />
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <div className={styles.text}>Показывать по</div>
            <div className="relative flex items-center">
              <select
                ref={selectRef}
                value={closestNum(PAGE_SIZES, pageSize)}
                className={cn(styles.field, styles.select, 'py-1', 'px-2')}
                onChange={handleChangePageSize}
                onFocus={setSelectFocusToTrue}
                onBlur={setSelectFocusToFalse}
              >
                {PAGE_SIZES.map((_pageSize) => (
                  <option key={_pageSize} value={_pageSize}>
                    {_pageSize}
                  </option>
                ))}
              </select>
              <Icon
                src={dropDownIcon}
                color="#8391A0"
                className={cn(styles.iconWrapper, 'shrink-0', {
                  [styles.activeIcon]: isSelectInFocus,
                })}
              />
            </div>
            <div className={styles.text}>записей на странице</div>
          </div>
          <div className="flex items-center">
            {showTotalElements && (
              <div
                className={cn(styles.text, {
                  invisible: infinitePagination,
                })}
              >
                Найдено {totalElements || (data ?? []).length} записей
              </div>
            )}
            {footerContent && <div className={styles.footerContent}>{footerContent}</div>}
          </div>
        </div>
      )}
    </div>
  )
}

Table.defaultProps = {
  sticky: true,
  resetDeps: [],
  pageCount: 9999,
  selectedRows: [],
}

export default memo(Table)
