import React, { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode } from 'react'

import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './Block.module.scss'

export interface FormBlockProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  titleOfBLock: ReactNode | string
  children: ReactNode
  isNestedBlock?: boolean
  withBlockTitleIndicator?: boolean
  direction?: 'column' | 'row'
  contentClassname?: string
  titleClassname?: string
}

const FormBlock = forwardRef<HTMLDivElement, FormBlockProps>(
  (
    {
      isNestedBlock = false,
      withBlockTitleIndicator = false,
      direction = 'column',
      titleOfBLock,
      children,
      contentClassname,
      titleClassname,
      ...rest
    },
    ref,
  ) => {
    const conditionBlockRow = direction === 'row'

    return (
      <div
        {...rest}
        ref={ref}
        className={cn(
          styles.block,
          {
            [styles['block--row']]: conditionBlockRow,
            [styles['block--nested']]: isNestedBlock,
            [styles['block--withBlockTitleIndicator']]: withBlockTitleIndicator,
          },
          rest.className,
        )}
      >
        <Typography.Headline
          as={'h3'}
          variant={'headlineH3'}
          className={cn(
            styles.block__title,
            {
              [styles['block__title-row']]: conditionBlockRow,
              [styles['block__title--nested']]: isNestedBlock,
            },
            titleClassname,
          )}
        >
          {titleOfBLock}
        </Typography.Headline>
        <div
          className={cn(
            styles.block__content,
            {
              [styles['block__content--row']]: conditionBlockRow,
            },
            contentClassname,
          )}
        >
          {children}
        </div>
      </div>
    )
  },
)

export default FormBlock
