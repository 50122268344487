import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { dataOnFulfillmentOfTermsSZPKBlockValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/const'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { secondSectionValidationMap } from './validation'

const currentBlockValues = dataOnFulfillmentOfTermsSZPKBlockValues['2']

const Second = () => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getControllerProps, getSingleSelectProps, getSubscribableControlProps, getInputProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.initiatorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Полное наименование'}
                controllerProps={getControllerProps({
                  name: currentBlockValues.initiatorFullName,
                })}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Уполномоченное лицо организации'}>
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: currentBlockValues.initiatorHeaderName,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item.headerName || '',
                          value: item.id,
                        }),
                        controllerProps: {
                          name: currentBlockValues.initiatorHeaderName,
                          rules: secondSectionValidationMap.initiatorHeaderName,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Фамилия, Имя, Отчество',
                          },
                          onChangeFormValue: () =>
                            setTimeout(
                              () => handleChangeValue?.(currentBlockValues.initiatorHeaderName),
                              0,
                            ),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: currentBlockValues.initiatorHeaderPosition,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: currentBlockValues.initiatorHeaderPosition,
                    inputProps: {
                      label: 'Должность',
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Group>
      </Stack>
    </Container>
  )
}

export default Second
