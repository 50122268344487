import { FC } from 'react'

import ReferenceBooksHeader from '@components/ReferenceBooks/Header'

interface ReferenceBooksProps extends FC {
  Header: typeof ReferenceBooksHeader
}

const ReferenceBooks: ReferenceBooksProps = () => null

ReferenceBooks.Header = ReferenceBooksHeader

export default ReferenceBooks
