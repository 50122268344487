import React from 'react'
import { useFormContext } from 'react-hook-form'

import InitiatorSection from '@components/DocumentFormComponents/FormSections/InitiatorSection'
import { useNoticeOneSideRejectSZPKManager } from '@components/Forms/NoticeOneSideRejectSZPKForm/Manager'
import { NoticeOneSideRejectSZPKFormValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/types'
import { NoticeOneSideRejectSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOneSideRejectSZPKForm/watcher'

const Second = () => {
  const formInstance = useFormContext<NoticeOneSideRejectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOneSideRejectSZPKManager()

  return (
    <InitiatorSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'2'}
      formInstance={formInstance}
      watcher={NoticeOneSideRejectSZPKFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
