import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import Group, { GroupProps } from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import {
  CapitalValueGroupCardProps,
  NewWorkplacesGroupCardProps,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/SzpkReportInfo/SzpkReportInfoCardItem'
import { useCurrentSzpkReportsListFormName } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/SzpkReportInfo/SzpkReportInfoGroup/hooks'
import SzpkReportListByFormName from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/SzpkReportInfo/SzpkReportListByFormName'
import { SzpkReportInfoArrayPathName } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/types'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'

interface SzpkReportInfoGroupProps {
  isSzpkReportsFederal: boolean
  isSzpkReportsMustBeWithinMonitoringDates?: boolean
  capitalValueGroupCardProps?: CapitalValueGroupCardProps
  newWorkPlacesGroupCardProps?: NewWorkplacesGroupCardProps
  groupProps?: Omit<GroupProps, 'children'>
}

const SzpkReportInfoGroup: FC<SzpkReportInfoGroupProps> = ({
  isSzpkReportsFederal,
  isSzpkReportsMustBeWithinMonitoringDates,
  capitalValueGroupCardProps,
  newWorkPlacesGroupCardProps,
  groupProps,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const currentListFormName = useCurrentSzpkReportsListFormName(
    isSzpkReportsFederal,
    isSzpkReportsMustBeWithinMonitoringDates,
  )

  useFieldArraySubscribableControl<
    DataOnFulfillmentOfTermsSZPKFormValues,
    SzpkReportInfoArrayPathName,
    'keyNameId'
  >({
    name: currentListFormName,
    control: formInstance?.control,
    keyName: 'keyNameId',
    watcher: DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Group title={'Информация по подписанным отчетам ОРП'} {...groupProps}>
      <SzpkReportListByFormName
        listFormName={currentListFormName}
        capitalValueGroupCardProps={capitalValueGroupCardProps}
        newWorkPlacesGroupCardProps={newWorkPlacesGroupCardProps}
      />
    </Group>
  )
}

export default SzpkReportInfoGroup
