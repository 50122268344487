import React, { FC } from 'react'
import { FieldErrors, useFormContext, useFormState } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormMenu.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormNavigationMenu } from '@components/DocumentFormComponents/hooks/useFormNavigation'
import { useFormSubmitInStatement } from '@components/DocumentFormComponents/hooks/useFormSubmit'
import DocumentFormMenu from '@components/DocumentFormComponents/Menu'
import MenuActions from '@components/DocumentFormComponents/Menu/Actions'
import { useFlatMenuManager } from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalMenuProps } from '@components/DocumentFormComponents/types'
import {
  certificateOfComplianceContractForRecognitionConcludedABlockValues,
  mapOfCertificateOfComplianceContractForRecognitionConcludedAMenu,
} from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/const'
import { useCertificateOfComplianceContractForRecognitionConcludedAManager } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/Manager'
import { CertificateOfComplianceContractForRecognitionConcludedAFormValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/types'

const { isBlockHaveError } = DocumentFormHelpers

const CertificateOfComplianceContractForRecognitionConcludedAMenu: FC<FormModalMenuProps> = ({
  ...rest
}) => {
  const formInstance =
    useFormContext<CertificateOfComplianceContractForRecognitionConcludedAFormValues>()

  const { errors } = useFormState({
    control: formInstance?.control,
  })

  const {
    state: { blockViewIsValidating, lastUpdateDraftTime, editMode },
    actions: { handleCheckForm, handlePreviewForm },
    handlers: { applyFormChanges },
  } = useCertificateOfComplianceContractForRecognitionConcludedAManager()

  const { currentAnchorId, handleChangeCurrentAnchorId } = useFlatMenuManager()
  const { handleMenuItemClick } = useFormNavigationMenu()

  const { handleSubmitForm } = useFormSubmitInStatement()

  const handleSubmit = async () => {
    if (!handleCheckForm || !applyFormChanges) return

    try {
      await handleCheckForm()
    } catch (e) {
      throw e
    }
  }

  const handleIsBlockValues = (
    id: string,
    blockErrors: FieldErrors<CertificateOfComplianceContractForRecognitionConcludedAFormValues>,
  ) => {
    return isBlockHaveError(
      id,
      certificateOfComplianceContractForRecognitionConcludedABlockValues,
      blockErrors,
    )
  }

  if (!formInstance) return null

  return (
    <div className={styles.menu}>
      <DocumentFormMenu
        {...rest}
        mapOfMenu={mapOfCertificateOfComplianceContractForRecognitionConcludedAMenu}
        activeMenuId={currentAnchorId}
        blockErrors={errors}
        blockViewIsValidating={blockViewIsValidating}
        lastUpdateDraftTime={lastUpdateDraftTime}
        onBlockCheckError={handleIsBlockValues}
        onMenuClick={handleMenuItemClick(handleChangeCurrentAnchorId)}
      />
      <MenuActions
        previewButtonProps={
          editMode
            ? {
                onClick: handlePreviewForm,
              }
            : undefined
        }
        submitButtonProps={
          editMode
            ? {
                onClick: handleSubmitForm(handleSubmit),
              }
            : undefined
        }
      />
    </div>
  )
}

export default CertificateOfComplianceContractForRecognitionConcludedAMenu
