const collapseStatusDataAttributeName = 'data-collapse-status'

const mapOfCollapseStatuses = {
  expanding: 'expanding',
  expanded: 'expanded',
  collapsing: 'collapsing',
  collapsed: 'collapsed',
}

export { collapseStatusDataAttributeName, mapOfCollapseStatuses }
