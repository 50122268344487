const errorTypeMap = {
  axiosError: 'axiosError',
  nativeJSError: 'nativeJSError',
} as const

const STACK_TRACE_SEPARATOR = '\n'
const STACK_TRACE_LINE_START = 0
const STACK_TRACE_LINE_END = 6

export { errorTypeMap, STACK_TRACE_LINE_END, STACK_TRACE_LINE_START, STACK_TRACE_SEPARATOR }
