import React, { FC, memo, useMemo } from 'react'

import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import {
  ICurrentDfoActionHamburgerButton,
  useUiActionsManager,
} from '@components/Projects/[id]/ActionBanner/Manager'
import { useHeaderCollapseManager } from '@components/Projects/[id]/HeaderCollapse'
import MenuOpenIcon from '@icons/MenuOpenIcon.svg'
import { useDocumentActions } from '@services/DocumentActions/DocumentAction.hook'
import { IDefaultUserAction } from '@services/DocumentActions/DocumentActions.entity'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'
import cn from 'classnames'

import styles from './HamburgerActions.module.scss'

const { fromActionToActionsStack } = DocumentActionsService

export const instanceOfDfoAction = (
  key: ICurrentDfoActionHamburgerButton | IDefaultUserAction,
): key is ICurrentDfoActionHamburgerButton => {
  return 'action' in key || 'uiActions' in key
}

interface HamburgerActionsButtonProps {
  inHeader?: boolean
}

const HamburgerActionsButton: FC<HamburgerActionsButtonProps> = ({ inHeader }) => {
  const {
    state: { hamburgerActions },
  } = useUiActionsManager()

  const { isCollapsed } = useHeaderCollapseManager()
  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip, withCloseTooltip },
  } = useManualTooltipControl()

  const { createActionsStack } = useDocumentActions()

  const tooltipContent = useMemo(() => {
    return (
      <div className={styles.hamburger__list}>
        {hamburgerActions.map((action) => {
          const icon = 'icon' in action && action.icon

          const handlerAction = instanceOfDfoAction(action)
            ? withCloseTooltip(async () => {
                try {
                  await createActionsStack(fromActionToActionsStack(action)).runStack()
                } catch {}
              })
            : undefined

          const clickCallback =
            'callback' in action ? withCloseTooltip(action.callback) : handlerAction

          return (
            <Button
              fixWidth
              key={action.displayName}
              className={styles.hamburger__button}
              size={'s'}
              view={'plain'}
              geometry={'square'}
              leadingIcon={{ src: icon || '' }}
              dataTestId={`HamburgerActionsButton-action-${action.type}-button`}
              onClick={clickCallback}
            >
              <Typography variant={'bodySMedium'} color={'text-base-secondary'}>
                {action.displayName}
              </Typography>
            </Button>
          )
        })}
      </div>
    )
  }, [createActionsStack, hamburgerActions, withCloseTooltip])

  return (
    <Tooltip
      open={tooltipIsOpen}
      contentClassName={styles.hamburger__content}
      targetClassName={styles.hamburger__target}
      popperClassName={cn({ [styles.popover__collapsed]: isCollapsed && !inHeader })}
      trigger={'click'}
      position={'bottom-start'}
      content={tooltipContent}
      arrowClassName={styles.hamburger__arrow}
      onClose={handleCloseTooltip}
    >
      <IconButton
        className={styles['projectPage__header-action']}
        size={'m'}
        view={'gray'}
        geometry={'round'}
        disabled={!hamburgerActions.length}
        dataTestId="HamburgerActionsButton-tooltipMenu-button"
        icon={{
          src: MenuOpenIcon,
          noCurrentColorSvgFill: true,
          className: styles.hamburger__icon,
        }}
        onClick={handleOpenTooltip}
      />
    </Tooltip>
  )
}

export default memo(HamburgerActionsButton)
