import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'

const { getDefaultInvestorFieldState, getDefaultRfFieldState, getDefaultInfoAboutDocument } =
  FieldStateHelpers

const mapOfNoticeOfProjectRefuseConcludeAAMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченный федеральный орган исполнительной власти ' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Отказ в заключении' },
]

const defaultNoticeOfProjectRefuseConcludeAAFormValues: NoticeOfProjectRefuseConcludeAAFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': getDefaultRfFieldState(),
    '3': {
      ...getDefaultInvestorFieldState(),
      investorFullNameGenitive: '',
      investorInn: '',
      investorOgrn: '',
      investorAddress: '',
      projectName: '',
    },
    '4': {
      extraRejectReason: '',
      ruleLinkExtraReject: '',
    },
  }

const noticeOfProjectRefuseConcludeAABlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    rfFullName: '2.rfFullName',
    rfHeaderName: '2.rfHeaderName',
    rfHeaderNameGenitive: '2.rfHeaderNameGenitive',
    rfHeaderPosition: '2.rfHeaderPosition',
    rfHeaderPositionGenitive: '2.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '2.rfHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameGenitive: '3.investorFullNameGenitive',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorAddress: '3.investorAddress',
    projectName: '3.projectName',
  },
  '4': {
    extraRejectReason: '4.extraRejectReason',
    ruleLinkExtraReject: '4.ruleLinkExtraReject',
  },
} as const

export {
  defaultNoticeOfProjectRefuseConcludeAAFormValues,
  mapOfNoticeOfProjectRefuseConcludeAAMenu,
  noticeOfProjectRefuseConcludeAABlockValues,
}
