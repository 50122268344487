import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  SeventhRidRegistrationPathName,
  SeventhRidRegistrationRidNamePermissionsPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

import { ridNamePermissionsValidationMap } from './validation'

const { isFormFieldError } = DocumentFormHelpers

interface RidNamesListProps {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: SeventhRidRegistrationPathName
}

const RidNamesList: FC<RidNamesListProps> = ({
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getSingleSelectProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: ridNames } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhRidRegistrationRidNamePermissionsPathName,
    'keyNameId'
  >({
    name: `${formName}.ridNames`,
    control: formInstance.control,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const ridNamesError = formInstance.getFieldState(`${formName}.ridNames`)?.error

  const handleAddNewRidName = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.ridNames`)

    if (!ridNames.length) {
      formInstance.clearErrors(`${formName}.ridNames`)
    }
  }

  const handleRemoveRidName = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${formName}.ridNames.${index}`, `${formName}.ridNames`)
  }

  return (
    <Group disableBottomBorder>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.ridNames`,
          })}
        >
          <FlipperList list={ridNames}>
            {ridNames.map((ridRegistration, index) => {
              const ridRegistrationFormName = `${formName}.ridNames.${index}` as const

              return (
                <FieldView.RowWithRemoveButton
                  key={ridRegistration.id}
                  id={ridRegistration.id}
                  onRemove={editMode ? handleRemoveRidName(index) : undefined}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${ridRegistrationFormName}.ridOption`,
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.ridName,
                              value: item.id,
                            }),
                            controllerProps: {
                              name: `${ridRegistrationFormName}.ridOption`,
                              rules: ridNamePermissionsValidationMap.ridOption,
                            },
                            selectProps: {
                              inputProps: {
                                label: 'Наименование РИД, подлежащего государственной регистрации',
                              },
                              onChangeFormValue: () =>
                                setTimeout(
                                  () => handleChangeValue?.(`${ridRegistrationFormName}.ridOption`),
                                  0,
                                ),
                            },
                          })}
                        />
                      )
                    }}
                  </SubscribableControl>
                </FieldView.RowWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(ridNamesError) && !ridNames.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddNewRidName}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Указать РИД
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default RidNamesList
