import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AATransferRightsAndObligationsLayout from './Layout'
import AATransferRightsAndObligationsModalManager from './Manager'
import AATransferRightsAndObligationsMenu from './Menu'
import AATransferRightsAndObligationsForm from './Statement'

interface ICreateAATransferRightsAndObligations extends FC {
  Layout: typeof AATransferRightsAndObligationsLayout
  Menu: typeof AATransferRightsAndObligationsMenu
  StatementForm: typeof AATransferRightsAndObligationsForm
  Manager: typeof AATransferRightsAndObligationsModalManager
}

const useAATransferRightsAndObligationsControl = () => {
  const popupManager = usePopupManager()

  const openAATransferRightsAndObligationsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AATransferRightsAndObligationsLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAATransferRightsAndObligationsModal }
}

const AATransferRightsAndObligations: ICreateAATransferRightsAndObligations = () => null

AATransferRightsAndObligations.Menu = AATransferRightsAndObligationsMenu
AATransferRightsAndObligations.Layout = AATransferRightsAndObligationsLayout
AATransferRightsAndObligations.StatementForm = AATransferRightsAndObligationsForm
AATransferRightsAndObligations.Manager = AATransferRightsAndObligationsModalManager

export { useAATransferRightsAndObligationsControl }
export default AATransferRightsAndObligations
