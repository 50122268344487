import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo, useEffect, useMemo } from 'react'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import {
  HIGHLIGHTED_BACKGROUND_COLOR,
  HIGHLIGHTED_TEXT_COLOR,
} from '@components/DocumentFormComponents/NestedMenu/constants'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import Highlighted from '@components/Highlighted/Highlighted'
import Icon, { IconProps } from '@components/Icon/Icon'
import Loader from '@components/Loader'
import Typography from '@components/NewDesign/Typography'
import { ITypographyBody } from '@components/NewDesign/Typography/types'
import { useBooleanState } from '@hooks/useBooleanState'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import editIcon from '@icons/EditIcon.svg'
import groupIcon from '@icons/list/groupList.svg'
import cn from 'classnames'

import styles from './NestedMenuItem.module.scss'

const changedIconBaseProps: IconProps = {
  src: editIcon,
  size: 'xs',
  className: styles.menuItem__leftAddon,
}

interface NestedMenuItemProps
  extends Omit<
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'onClick'
  > {
  blockViewIsValidating: boolean
  item: NestedMapOfMenu
  isOpen?: boolean
  currentSearchPattern?: string
  dataTestId?: string
  activeMenuId?: string
  typographyProps?: Omit<ITypographyBody, 'children'>
  onClick?: (menuItem: NestedMapOfMenu) => Promise<void>
}

const NestedMenuItem: FC<NestedMenuItemProps> = ({
  isOpen = false,
  blockViewIsValidating,
  item,
  currentSearchPattern,
  dataTestId,
  activeMenuId,
  className,
  typographyProps,
  onClick,
  ...props
}) => {
  const { variant = 'bodyMMedium', className: typographyClassName } = typographyProps || {}

  const isActive = activeMenuId === item.id

  const isRenderFromSearch = !!currentSearchPattern?.length

  const {
    booleanState: isExpanded,
    setBooleanState: setExpanded,
    reverseBooleanState: toggleExpanded,
  } = useBooleanState()

  const blockHaveError = item.hasError
  const blockNotHaveError = !blockHaveError

  const handleChangeActiveMenuOnClick = async () => {
    await onClick?.(item)
  }

  const isActiveChild = useMemo(() => {
    if (!item.children.length) return false

    return item.children.some((nestedItem) => nestedItem.id === activeMenuId)
  }, [activeMenuId])

  useEffect(() => {
    if (
      isRenderFromSearch ||
      isOpen ||
      isActiveChild ||
      (blockHaveError && blockViewIsValidating)
    ) {
      return setExpanded(true)
    }
  }, [isRenderFromSearch, isOpen, isActiveChild, blockHaveError, blockViewIsValidating])

  useEffectAfterMount(() => {
    if (currentSearchPattern?.length) return

    if (isOpen || (blockHaveError && blockViewIsValidating)) {
      return setExpanded(true)
    }

    return setExpanded(false)
  }, [currentSearchPattern])

  if (!item.children || !item.children.length) {
    return (
      <AsyncWrapper promise={handleChangeActiveMenuOnClick}>
        {({ isLoading, wrappedPromise }) => {
          return (
            <button
              {...props}
              type="button"
              data-testid={`MenuItem-item-${dataTestId}`}
              disabled={isLoading || !!props?.disabled}
              className={cn(
                styles.menuItem,
                styles['menuItem--flat'],
                {
                  [styles['menuItem--active']]: isActive,
                },
                className,
              )}
              onClick={wrappedPromise}
            >
              <div className={styles['menuItem__content']}>
                {!!item?.isChanged && <Icon {...changedIconBaseProps} />}
                <Typography.Body
                  variant={variant}
                  className={cn(
                    styles.menuItem__text,
                    {
                      [styles['menuItem__text--active']]: isActive,
                      [styles['menuItem__text--error']]: blockHaveError && blockViewIsValidating,
                      [styles['menuItem__text--success']]:
                        blockNotHaveError && blockViewIsValidating,
                      [styles['menuItem__text--disabled']]: isLoading || !!props?.disabled,
                    },
                    typographyClassName,
                  )}
                >
                  <Highlighted
                    highlight={currentSearchPattern || ''}
                    color={HIGHLIGHTED_TEXT_COLOR}
                    backgroundColor={HIGHLIGHTED_BACKGROUND_COLOR}
                  >
                    {item.title}
                  </Highlighted>
                </Typography.Body>
              </div>
              <Loader
                loading={isLoading}
                variant={'lite'}
                wrapperClassName={styles['menuItem__loader-wrapper']}
                className={styles['menuItem__loader']}
              />
            </button>
          )
        }}
      </AsyncWrapper>
    )
  }

  return (
    <>
      <li
        role="presentation"
        className={cn(styles.menuItem, styles['menuItem--nested'])}
        data-testid={`${dataTestId}-list`}
        onClick={toggleExpanded}
      >
        <Icon
          noCurrentColorSvgFill
          src={groupIcon}
          size="s"
          className={cn(styles.menuItem__icon, {
            [styles['menuItem__icon--expanded']]: isExpanded,
          })}
        />
        {!!item?.isChanged && <Icon {...changedIconBaseProps} />}
        <Typography
          variant={variant}
          className={cn(
            styles.menuItem__text,
            {
              [styles['menuItem__text--error']]: blockHaveError && blockViewIsValidating,
              [styles['menuItem__text--success']]: blockNotHaveError && blockViewIsValidating,
            },
            typographyClassName,
          )}
        >
          <Highlighted
            highlight={currentSearchPattern || ''}
            color={HIGHLIGHTED_TEXT_COLOR}
            backgroundColor={HIGHLIGHTED_BACKGROUND_COLOR}
          >
            {item.title}
          </Highlighted>
        </Typography>
      </li>
      <CollapseWrapper unmountOnCollapse defaultExpanded={isExpanded} isExpanded={isExpanded}>
        <ul className={styles.menuItem__list}>
          {item.children.map((nestedItem) => (
            <NestedMenuItem
              {...props}
              key={nestedItem.id}
              item={nestedItem}
              dataTestId={nestedItem.id}
              activeMenuId={activeMenuId}
              currentSearchPattern={currentSearchPattern}
              blockViewIsValidating={blockViewIsValidating}
              typographyProps={{
                variant: 'bodySMedium',
                className: typographyClassName,
              }}
              onClick={onClick}
            />
          ))}
        </ul>
      </CollapseWrapper>
    </>
  )
}

export default memo(NestedMenuItem)
