import React, { FC } from 'react'
import { FieldErrors, useFormContext, useFormState } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormMenu.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormNavigationMenu } from '@components/DocumentFormComponents/hooks/useFormNavigation'
import { useFormSubmitInStatement } from '@components/DocumentFormComponents/hooks/useFormSubmit'
import DocumentFormMenu from '@components/DocumentFormComponents/Menu'
import MenuActions from '@components/DocumentFormComponents/Menu/Actions'
import { useFlatMenuManager } from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalMenuProps } from '@components/DocumentFormComponents/types'
import {
  financialModelBlockValues,
  mapOfFinancialModelMenu,
} from '@components/Forms/FinancialModelForm/const'
import { useFinancialModelManager } from '@components/Forms/FinancialModelForm/Manager'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'

const { isBlockHaveError } = DocumentFormHelpers

const FinancialModelMenu: FC<FormModalMenuProps> = ({ children, ...rest }) => {
  const formInstance = useFormContext<FinancialModelFormValues>()

  const { errors } = useFormState({
    control: formInstance?.control,
  })

  const {
    state: { blockViewIsValidating, lastUpdateDraftTime, editMode },
    actions: { handleCheckForm },
    handlers: { applyFormChanges },
  } = useFinancialModelManager()

  const { handleMenuItemClick } = useFormNavigationMenu()
  const { handleSubmitForm } = useFormSubmitInStatement()

  const { currentAnchorId, handleChangeCurrentAnchorId } = useFlatMenuManager()

  const handleSubmit = async () => {
    if (!handleCheckForm || !applyFormChanges) return

    try {
      await handleCheckForm()
    } catch (e) {
      throw e
    }
  }

  const handleIsBlockValues = (id: string, blockErrors: FieldErrors<FinancialModelFormValues>) => {
    return isBlockHaveError(id, financialModelBlockValues, blockErrors)
  }

  if (!formInstance) return null

  return (
    <div className={styles.menu}>
      {children}
      <DocumentFormMenu
        {...rest}
        mapOfMenu={mapOfFinancialModelMenu}
        blockErrors={errors}
        activeMenuId={currentAnchorId}
        blockViewIsValidating={blockViewIsValidating}
        lastUpdateDraftTime={lastUpdateDraftTime}
        onBlockCheckError={handleIsBlockValues}
        onMenuClick={handleMenuItemClick(handleChangeCurrentAnchorId)}
      />
      <MenuActions
        submitButtonProps={
          editMode
            ? {
                onClick: handleSubmitForm(handleSubmit),
              }
            : undefined
        }
      />
    </div>
  )
}

export default FinancialModelMenu
