import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

import KnowledgeBase from '@components/KnowledgeBase'
import { mockKnowledgeBase } from '@components/KnowledgeBase/__mock__'
import KnowledgeBaseDesktop from '@components/KnowledgeBase/Desktop'
import Menu from '@components/Layout/Header/Menu'
import Logo from '@components/Layout/Logo/Logo'
import NavigationPanel from '@components/NavigationPanel'
import { Paths } from '@constants/paths'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import { useQueryManager } from '@hooks/useQueryManager'

import styles from './DesktopKnowledgeBase.module.scss'

const MENU = [
  {
    to: Paths.AuthorizedList,
    label: 'Уполномоченные ОИВ',
  },
  {
    to: Paths.Acts,
    label: 'Документы',
  },
]

const DesktopKnowledgeBase = () => {
  const { queryUtils } = useQueryManager()

  const location = useLocation()
  const navigate = useNavigate()

  const pageViewId = useMemo(() => {
    return queryUtils.getQuery('pageViewId')
  }, [queryUtils])

  useEffect(() => {
    if (pageViewId) return

    //Если в объекте не найдена запись, меняем кверик на первый элемент  и ставим его  baseItem
    queryUtils.addQuery({
      query: {
        pageViewId: mockKnowledgeBase[0].id,
      },
    })
  }, [])

  useEffectAfterMount(() => {
    if (location.search) return

    navigate(-1)
  }, [location.search])

  return (
    <KnowledgeBase.Manager initializationPageViewId={pageViewId}>
      <div className={styles.knowledgeBase__header}>
        <div className={styles['knowledgeBase__header-left']}>
          <Logo />
          <NavigationPanel className="ml-10" />
        </div>
        <ul className={styles['knowledgeBase__header-right']}>
          {MENU.map((item, key) => (
            <Menu.Link to={item.to} key={key}>
              {item.label}
            </Menu.Link>
          ))}
        </ul>
      </div>
      <div className={styles.knowledgeBase__container}>
        <KnowledgeBaseDesktop.Menu />
        <KnowledgeBaseDesktop.Preview />
      </div>
    </KnowledgeBase.Manager>
  )
}

export default DesktopKnowledgeBase
