import React from 'react'
import { FieldValues, Path, UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

interface ReasonGroupProps<T extends FieldValues> {
  editMode: boolean
  blockViewIsValidating: boolean
  checkboxLabel: string
  reasonName: Path<T>
  formInstance: UseFormReturn<T>
  subscribableControl: SubscribableFormControlProps<T>
  watcher: FieldsControlUpdateWatcher
  onChangeValue?: (pathName: Path<T>, batchBlockId?: string) => Promise<void>
  debouncedChangeValue?: (pathName: Path<T>, batchBlockId?: string) => void
}

const ReasonGroup = <T extends FieldValues>({
  editMode,
  blockViewIsValidating,
  formInstance,
  checkboxLabel,
  reasonName,
  watcher,
  subscribableControl,
  onChangeValue,
  debouncedChangeValue,
}: ReasonGroupProps<T>) => {
  const { getSubscribableControlProps, getTextareaProps, getCheckboxProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher,
    })

  const reason = useWatch({
    control: formInstance?.control,
    name: reasonName,
  }) as boolean

  const reasonChecker = useWatch({
    control: formInstance?.control,
    name: `${reasonName}Checker` as Path<T>,
  }) as boolean

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row className={styles.form__checkboxRow}>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${reasonName}Checker` as Path<T>,
            })}
          >
            <AsyncWrapper promise={async () => onChangeValue?.(`${reasonName}Checker` as Path<T>)}>
              {({ isLoading, wrappedPromise }) => (
                <ControlledCheckbox
                  {...getCheckboxProps({
                    name: `${reasonName}Checker` as Path<T>,
                    checkBoxProps: {
                      disabled: isLoading || !editMode,
                      label: checkboxLabel,
                    },
                    onChange: () => setTimeout(() => wrappedPromise(), 0),
                  })}
                />
              )}
            </AsyncWrapper>
          </SubscribableControl>
        </Col>
      </Row>
      <CollapseWrapper isExpanded={reason} defaultExpanded={reason}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${reasonName}Description` as Path<T>,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${reasonName}Description` as Path<T>,
                  textareaProps: {
                    disabled: true,
                    label: 'Описание',
                    caption: 'значение, введенное инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </CollapseWrapper>
      <CollapseWrapper isExpanded={reasonChecker} defaultExpanded={reasonChecker}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${reasonName}DescriptionChecker` as Path<T>,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${reasonName}DescriptionChecker` as Path<T>,
                  rules: {
                    validate: (value) =>
                      isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined,
                  },
                  textareaProps: {
                    label: 'Описание',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      onChangeValue?.(`${reasonName}DescriptionChecker` as Path<T>)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedChangeValue?.(`${reasonName}DescriptionChecker` as Path<T>)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </CollapseWrapper>
    </Stack>
  )
}

export default ReasonGroup
