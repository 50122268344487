import { FC, useState } from 'react'

import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import type {
  NotificationId,
  NotificationsStack as INotificationsStack,
} from '@components/NewNotifications/model'
import { StackPreview } from '@components/NewNotifications/NotificationsStack/StackPreview'
import { useStackPreview } from '@components/NewNotifications/NotificationsStack/useStackPreview'
import SingleNotification from '@components/NewNotifications/SingleNotification'
import { INotificationFromStack } from '@context/APIContext/hooks/useNotificationsApi'
import { getDateTime } from '@helpers/date/getDateTime'
import { useNotificationsStack } from '@hooks/swr/useNotificationsStack'
import { useBooleanState } from '@hooks/useBooleanState'
import cn from 'classnames'
import { useSWRConfig } from 'swr'

import styles from '../MultipleNotification.module.scss'

const NotificationsStack: FC<INotificationsStack> = (props) => {
  const {
    booleanState: isStackOpen,
    setBooleanStateToFalse: closeStack,
    setBooleanStateToTrue: openStack,
    reverseBooleanState: reverseIsStackOpen,
  } = useBooleanState()
  const {
    description,
    subtitle,
    projectId,
    title,
    date,
    dfoId,
    link,
    level,
    notificationId: stackPreviewNotificationId,
    onReadNotification,
    onReadStack,
  } = props

  const { cache } = useSWRConfig()

  const fetchKey = `notifications/${dfoId}/${stackPreviewNotificationId}`

  const stackWasLoaded: INotificationFromStack | undefined = cache.get(fetchKey)

  const [unreadNotifications, setUnreadNotifications] = useState<
    INotificationFromStack['notifications']
  >(
    stackWasLoaded?.notifications || [
      {
        ...props,
        link,
        text: description,
        dateTime: date?.toISOString(),
      },
    ],
  )

  const { isNotificationsStackLoading } = useNotificationsStack({
    key: isStackOpen && !stackWasLoaded && fetchKey,
    dfoId: dfoId,
    config: {
      onSuccess: (data) => setUnreadNotifications(data.notifications),
    },
  })

  const stackPreviewNotification = useStackPreview(unreadNotifications, stackPreviewNotificationId)

  const notificationReadHandler = (notificationId: NotificationId) => {
    setUnreadNotifications((prevUnreadNotifications) =>
      prevUnreadNotifications.filter(
        (unreadNotification) => unreadNotification.notificationId !== notificationId,
      ),
    )

    onReadNotification?.(notificationId)
  }

  const stackClickHandler = () => {
    reverseIsStackOpen()
    // mutate((currentData) => ({ ...currentData, k }))
  }

  // Если осталось одно непрочитанное уведомление, то оно становится одиночным
  if (unreadNotifications?.length === 1 && stackWasLoaded)
    return <SingleNotification onRead={onReadStack} {...props} />

  const isStackLoadedSuccess = isStackOpen && !isNotificationsStackLoading

  return (
    <button
      data-testid={`NotificationsStack-item-${stackPreviewNotificationId}`}
      type="button"
      disabled={isNotificationsStackLoading}
      className={cn(styles.MultipleNotification, {
        [styles.MultipleNotification_open]: isStackLoadedSuccess,
      })}
      onClick={isStackLoadedSuccess ? undefined : stackClickHandler}
    >
      <Loader
        dataTestId={`NotificationsStack-itemLoader-${stackPreviewNotificationId}`}
        loading={isNotificationsStackLoading}
        variant={'lite'}
        wrapperClassName={styles.Loader}
      />
      {isStackLoadedSuccess && (
        <div className={styles.Header}>
          <Typography variant={'headlineH4'} className={styles.Title}>
            {title}
          </Typography>
          <Button
            size={'s'}
            variant={'buttonSMedium'}
            fontWeight={'medium'}
            geometry={'round'}
            view={'gray'}
            dataTestId={`NotificationsStack-closeButton-${stackPreviewNotificationId}`}
            className={styles.Less}
            onClick={closeStack}
          >
            Свернуть
          </Button>
        </div>
      )}
      {isStackLoadedSuccess ? (
        <div className={styles.Stack}>
          {unreadNotifications.map(({ dateTime, title, text, notificationId, link, level }) => (
            <SingleNotification
              level={level}
              link={link}
              projectId={projectId}
              key={notificationId}
              notificationId={notificationId}
              dfoId={dfoId}
              date={getDateTime(dateTime)}
              title={title}
              subtitle={subtitle}
              description={text}
              onRead={notificationReadHandler}
            />
          ))}
        </div>
      ) : (
        <StackPreview
          dataTestId={`NotificationsStack-stackPreviewItem-${stackPreviewNotificationId}`}
          level={level}
          description={stackPreviewNotification.text}
          title={stackPreviewNotification.title}
          className={styles.firstLayer}
          subtitle={subtitle}
          date={getDateTime(stackPreviewNotification.dateTime)}
        />
      )}
    </button>
  )
}

export default NotificationsStack
