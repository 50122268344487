import React, { FC, memo } from 'react'

import { WebFormStateControllerProps } from '@components/Attachments/WebForm/WIthoutDocumentController/types'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import Button from '@components/NewDesign/Button'
import { isUndefined } from '@helpers/checkTypes'

const WebFormConvertedStateController: FC<WebFormStateControllerProps> = ({
  disabled,
  documentInfoFromDict,
  onOpenForm,
}) => {
  const { description: title } = documentInfoFromDict

  const openFormButtonRenderCondition = !isUndefined(onOpenForm)

  return (
    <ControlContainer
      title={title}
      leadingNodeContent={<ControlContainerStatusIcon iconType="done" />}
      trailingNodeContent={
        openFormButtonRenderCondition && (
          <Button fixWidth disabled={disabled} view="tined" onClick={onOpenForm()}>
            Открыть форму
          </Button>
        )
      }
    />
  )
}

export default memo(WebFormConvertedStateController)
