import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { infrFacilitiesCostsExpectedReimbursedBlockValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import InfractureObjectStep from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Forms/3/AdditionalForms/InfractureObjectStep'
import { InfrastructureObjectsPathName } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Forms/3/types'
import { useInfrFacilitiesCostsExpectedReimbursedManager } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Manager'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import {
  InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher,
} from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/watcher'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import cn from 'classnames'
import plural from 'plural-ru'

import styles from './ThirdForm.module.scss'

const Third = () => {
  const formInstance = useFormContext<InfrFacilitiesCostsExpectedReimbursedFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useInfrFacilitiesCostsExpectedReimbursedManager()

  const { getSubscribableControlProps, getControllerProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher,
  })

  const { fields: infrastructureObjects } = useFieldArraySubscribableControl<
    InfrFacilitiesCostsExpectedReimbursedFormValues,
    InfrastructureObjectsPathName,
    'keyNameId'
  >({
    name: infrFacilitiesCostsExpectedReimbursedBlockValues['3'].infrastructureObjects,
    control: formInstance?.control,
    keyName: 'keyNameId',
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Row className={cn('pl-0', styles.thirdForm__subtitle)}>
        <Typography.Body variant={'bodyMMedium'}>
          Заполните форму каждого объекта инфраструктуры
        </Typography.Body>
      </Row>
      <Stack className={styles.thirdForm__content} direction={'vertical'} gap={4}>
        {infrastructureObjects.map((infrObject, index) => {
          const formName =
            `${infrFacilitiesCostsExpectedReimbursedBlockValues['3'].infrastructureObjects}.${index}` as const

          return (
            <InfractureObjectStep
              key={infrObject.id}
              blockViewIsValidating={blockViewIsValidating}
              formName={formName}
              formInstance={formInstance}
            />
          )
        })}
      </Stack>
      <Row className={styles.thirdForm__container}>
        <Typography.Body
          className={styles.thirdForm__result}
          fontWeight={'semibold'}
          variant={'bodyXLMedium'}
        >
          {`Всего ${infrastructureObjects.length} ${plural(
            infrastructureObjects.length,
            'объект',
            'объекта',
            'объектов',
          )} инфраструктуры`}
        </Typography.Body>

        <Row className={styles.thirdForm__down}>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['3'].objectGeneralCost,
              })}
            >
              <ControlledDocumentDataView
                disableContainerPaddingLeft
                suptitle={'Планируемая стоимость'}
                controllerProps={getControllerProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['3'].objectGeneralCost,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['3']
                  .objectGeneralCompensations,
              })}
            >
              <ControlledDocumentDataView
                disableContainerPaddingRight
                suptitle={'Планируемый объем возмещения затрат'}
                controllerProps={getControllerProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['3']
                    .objectGeneralCompensations,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Row>
    </Container>
  )
}

export default Third
