import React, { DetailedHTMLProps, FC, FormHTMLAttributes, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { useCertificateApplicationConclusionOfAARequirementsManager } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/Manager'
import { CertificateApplicationConclusionOfAARequirementsFormValues } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/types'

import { mapOfCertificateApplicationConclusionOfAARequirementsMenu } from '../const'
import CertificateApplicationConclusionOfAARequirementsForms from '../Forms'

type CertificateApplicationConclusionOfAARequirementsFormProps = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>

const mapOfCertificateApplicationConclusionOfAARequirementsForms: FormItemProps = {
  '1': {
    node: <CertificateApplicationConclusionOfAARequirementsForms.First />,
  },
  '2': {
    node: <CertificateApplicationConclusionOfAARequirementsForms.Second />,
  },
  '3': {
    node: <CertificateApplicationConclusionOfAARequirementsForms.Third />,
  },
  '4': {
    node: <CertificateApplicationConclusionOfAARequirementsForms.Fourth />,
  },
} as const

const CertificateApplicationConclusionOfAARequirementsForm: FC<
  CertificateApplicationConclusionOfAARequirementsFormProps
> = ({ ...rest }) => {
  const formInstance = useFormContext<CertificateApplicationConclusionOfAARequirementsFormValues>()

  const {
    state: { formIsLoading },
  } = useCertificateApplicationConclusionOfAARequirementsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfCertificateApplicationConclusionOfAARequirementsMenu}
      mapOfForms={mapOfCertificateApplicationConclusionOfAARequirementsForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(CertificateApplicationConclusionOfAARequirementsForm)
