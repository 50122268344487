import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import InvestorSection from '@components/DocumentFormComponents/FormSections/InvestorSection'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeOfRefuseConcludeAABlockValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/const'
import { useNoticeOfRefuseConcludeAAManager } from '@components/Forms/NoticeOfRefuseConcludeAAForm/Manager'
import { NoticeOfRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/types'
import { NoticeOfRefuseConcludeAAFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfRefuseConcludeAAForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const currentBlockValues = noticeOfRefuseConcludeAABlockValues['3']

const Third = () => {
  const formInstance = useFormContext<NoticeOfRefuseConcludeAAFormValues>()
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOfRefuseConcludeAAManager()

  const { getSubscribableControlProps, getControllerProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NoticeOfRefuseConcludeAAFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <InvestorSection
          fullNameGenitiveRenderCondition
          nameOrganizationChangeTooltipRenderCondition={false}
          authorizedPersonGroupRenderCondition={false}
          editMode={editMode}
          blockViewIsValidating={blockViewIsValidating}
          formName={'3'}
          formInstance={formInstance}
          watcher={NoticeOfRefuseConcludeAAFieldsControlUpdateWatcher}
          subscribableControl={subscribableControl}
          debouncedChangeValue={debouncedHandleChangeValue}
          onChangeValue={handleChangeValue}
        />
        <Border />
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование проекта'}
                controllerProps={getControllerProps({
                  name: currentBlockValues.projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
