import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const firstSectionValidationMap: FormValuesValidationSection<
  keyof NotificationOfChangeDocumentsFormValues['1'],
  NotificationOfChangeDocumentsFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { firstSectionValidationMap }
