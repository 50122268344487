import React, { FC, useRef } from 'react'
import { PopupProps } from 'react-popup-manager'

import type { ExtractProps } from '@globalTypes/headlessUi'
import { Dialog } from '@headlessui/react'
import cn from 'classnames'

import styles from './BaseModal.module.scss'

export type ModalBaseComponentProps = Omit<ExtractProps<typeof Dialog>, 'onClose'> & {
  modalClassName?: string
  containerClassName?: string
  contentClassName?: string
  disableOnClose?: boolean
  dataTestId?: string
}

type ModalBaseProps = ModalBaseComponentProps & PopupProps

const ModalBase: FC<ModalBaseProps> = ({
  modalClassName,
  containerClassName,
  contentClassName,
  onClose,
  isOpen,
  disableOnClose,
  children,
  initialFocus,
  dataTestId,
  ...dialogRest
}) => {
  const fakeFocusRef = useRef<HTMLButtonElement | null>(null)

  const handleClose = () => {
    if (disableOnClose) return

    onClose?.()
  }

  return (
    <Dialog
      open={isOpen}
      data-testid={dataTestId}
      onClose={handleClose}
      {...dialogRest}
      initialFocus={initialFocus ?? fakeFocusRef}
      className={cn(styles.modal, modalClassName)}
    >
      <div className={styles.modal__overlay} />
      <div className={cn(styles.modal__container, containerClassName)}>
        <Dialog.Panel className={contentClassName}>
          {
            <>
              <button ref={fakeFocusRef} className={styles.modal__fakeButton} />
              {children}
            </>
          }
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default ModalBase
