import { FormModifierAdapters } from '@components/DocumentFormComponents/adapters/Modifier.adapter'
import {
  FormObjectStates,
  FormObjectStatesWithChanges,
} from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsConstructionObjectModifier,
  AAgreementOnChangingCharacteristicsOfObjectsConstructionSubObjectModifier,
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsPermissionModifier,
  AAgreementOnChangingCharacteristicsOfObjectsStageModifier,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { isArray } from '@helpers/checkTypes'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

const { getModifierStatesFormPropertyState, comparePropertyValueWithCommitedValue, mergeChanges } =
  FormModifierAdapters

const generateFormModifierValuesForAAgreementOnChangingCharacteristicsOfObjectsForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues => {
  const preparedConstructionStages =
    ((): AAgreementOnChangingCharacteristicsOfObjectsStageModifier[] => {
      if (
        !isArray(objectFromPropertyState.steps?.value) ||
        !objectFromPropertyState.steps?.value?.length
      )
        return []

      return objectFromPropertyState.steps?.value.map(
        (step): AAgreementOnChangingCharacteristicsOfObjectsStageModifier => {
          const preparedStepCompensations = ((): FormObjectStatesWithChanges[] => {
            if (
              !isArray(step.value.stepCompensations?.value) ||
              !step.value.stepCompensations?.value?.length
            )
              return []

            return step.value.stepCompensations?.value.map(
              (stepCompensation): FormObjectStatesWithChanges => {
                const stepCompensationState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(stepCompensation)

                const isChangedStepCompensationFields = !stepCompensationState?.isNew
                  ? [
                      stepCompensation.value.stepCompensationType,
                      stepCompensation.value?.stepCompensationPeriod,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...stepCompensationState,
                  isChanged: isChangedStepCompensationFields,
                }
              },
            )
          })()

          const preparedConstructionObjects =
            ((): AAgreementOnChangingCharacteristicsOfObjectsConstructionObjectModifier[] => {
              const objects =
                step.value.objects?.value.filter((object) => {
                  return (
                    object.value.isMainObject?.value &&
                    !object.value.isSubObject?.value &&
                    !object.value.objectOtherActivityName?.value?.id
                  )
                }) || []

              const subObjects =
                step.value.objects?.value.filter((object) => {
                  return (
                    object.value.isSubObject?.value &&
                    !object.value.objectOtherActivityName?.value?.id &&
                    object.value.parentSubObject?.value
                  )
                }) || []

              return objects.map(
                (
                  object,
                ): AAgreementOnChangingCharacteristicsOfObjectsConstructionObjectModifier => {
                  const preparedSubObjects =
                    ((): AAgreementOnChangingCharacteristicsOfObjectsConstructionSubObjectModifier[] =>
                      subObjects
                        .filter(
                          (subObject) =>
                            object.permanentId === subObject.value.parentSubObject?.value?.id,
                        )
                        .map(
                          (
                            subObject,
                          ): AAgreementOnChangingCharacteristicsOfObjectsConstructionSubObjectModifier => {
                            const preparedObjectRegions: FormObjectStatesWithChanges[] =
                              isArray(subObject.value?.objectRegions?.value) &&
                              subObject.value?.objectRegions?.value?.length
                                ? subObject.value?.objectRegions?.value.map(
                                    (objectRegion): FormObjectStatesWithChanges => {
                                      const objectRegionState =
                                        getModifierStatesFormPropertyState<
                                          typeof PropertyTypeEnum.MAP
                                        >(objectRegion)

                                      return {
                                        ...objectRegionState,
                                        isChanged: !objectRegionState.isNew
                                          ? comparePropertyValueWithCommitedValue(
                                              objectRegion?.value?.objectRegionCode,
                                            )
                                          : false,
                                      }
                                    },
                                  )
                                : []

                            const objectRegionsState = getModifierStatesFormPropertyState<
                              typeof PropertyTypeEnum.LIST
                            >(subObject.value?.objectRegions)

                            const subObjectState =
                              getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                subObject,
                              )

                            const isChangedObjectRegions = mergeChanges(
                              preparedObjectRegions,
                              objectRegionsState,
                            )

                            const isChangedSubObjectFields = !subObjectState?.isNew
                              ? [
                                  subObject.value.objectName,
                                  subObject.value?.objectConstructionKind,
                                  subObject.value?.objectPurpose,
                                  subObject.value?.objectConstructionSquare,
                                  subObject.value?.objectSquare,
                                  subObject.value?.objectNonLivingAreaSquare,
                                  subObject.value?.objectLivingAreaSquare,
                                  subObject.value?.objectFloor,
                                  subObject.value?.objectUndergroundFloor,
                                  subObject.value?.objectHeight,
                                  subObject.value?.objectDetails,
                                  subObject.value?.isInfrastructure,
                                  subObject.value?.infrastructureType,
                                  subObject.value?.oldIsCapitalObject,
                                  subObject.value?.oldCreatedDocument,
                                  subObject.value?.oldCreatedOrganization,
                                ].some(comparePropertyValueWithCommitedValue)
                              : false

                            const isChanged = isChangedSubObjectFields || isChangedObjectRegions

                            return {
                              ...subObjectState,
                              isChanged,
                              objectRegions: preparedObjectRegions,
                              objectRegionsState,
                            }
                          },
                        ))()

                  const preparedObjectAreas: FormObjectStatesWithChanges[] =
                    isArray(object.value?.objectAreas?.value) &&
                    object.value?.objectAreas?.value?.length
                      ? object.value?.objectAreas?.value.map(
                          (objectArea): FormObjectStatesWithChanges => {
                            const objectAreaState =
                              getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                objectArea,
                              )

                            const isChangedObjectAreaFields = !objectAreaState.isNew
                              ? [
                                  objectArea.value.objectAreaNumber,
                                  objectArea.value.objectAreaSquare,
                                ].some(comparePropertyValueWithCommitedValue)
                              : false

                            return {
                              ...objectAreaState,
                              isChanged: isChangedObjectAreaFields,
                            }
                          },
                        )
                      : []

                  const preparedObjectRegions: FormObjectStatesWithChanges[] =
                    isArray(object.value?.objectRegions?.value) &&
                    object.value?.objectRegions?.value?.length
                      ? object.value?.objectRegions?.value.map(
                          (objectRegion): FormObjectStatesWithChanges => {
                            const objectRegionState =
                              getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                objectRegion,
                              )

                            return {
                              ...objectRegionState,
                              isChanged: !objectRegionState.isNew
                                ? comparePropertyValueWithCommitedValue(
                                    objectRegion?.value?.objectRegionCode,
                                  )
                                : false,
                            }
                          },
                        )
                      : []

                  const objectState =
                    getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(object)

                  const objectAreasState = getModifierStatesFormPropertyState<
                    typeof PropertyTypeEnum.LIST
                  >(object.value?.objectAreas)

                  const objectRegionsState = getModifierStatesFormPropertyState<
                    typeof PropertyTypeEnum.LIST
                  >(object.value?.objectRegions)

                  const subObjectsState =
                    getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(subObjects)

                  const isChangedObjectAreas = mergeChanges(preparedObjectAreas, objectAreasState)

                  const isChangedObjectRegions = mergeChanges(
                    preparedObjectRegions,
                    objectRegionsState,
                  )

                  const isChangedSubObjects = mergeChanges(preparedSubObjects, subObjectsState)

                  const isChangedObjectFields = !objectState.isNew
                    ? [
                        object.value.objectName,
                        object.value?.objectConstructionKind,
                        object.value?.objectPurpose,
                        object.value?.objectConstructionSquare,
                        object.value?.objectSquare,
                        object.value?.objectNonLivingAreaSquare,
                        object.value?.objectLivingAreaSquare,
                        object.value?.objectFloor,
                        object.value?.objectUndergroundFloor,
                        object.value?.objectHeight,
                        object.value?.objectDetails,
                        object.value?.isInfrastructure,
                        object.value?.infrastructureType,
                        object.value?.oldIsCapitalObject,
                        object.value?.oldCreatedDocument,
                        object.value?.oldCreatedOrganization,
                      ].some(comparePropertyValueWithCommitedValue)
                    : false

                  const isChanged =
                    isChangedObjectFields ||
                    isChangedObjectAreas ||
                    isChangedObjectRegions ||
                    isChangedSubObjects

                  return {
                    ...objectState,
                    isChanged,
                    objectAreas: preparedObjectAreas,
                    objectAreasState,
                    objectRegions: preparedObjectRegions,
                    objectRegionsState,
                    subObjects: preparedSubObjects,
                    subObjectsState,
                  }
                },
              )
            })()

          const preparedPermissions =
            ((): AAgreementOnChangingCharacteristicsOfObjectsPermissionModifier[] => {
              if (!isArray(step.value.permissions?.value) || !step.value.permissions?.value.length)
                return []

              return step.value.permissions?.value.map(
                (permission): AAgreementOnChangingCharacteristicsOfObjectsPermissionModifier => {
                  const preparedObjectNamePermissions: FormObjectStates[] = (() => {
                    if (
                      !isArray(permission.value.permissionObjects?.value) ||
                      !permission.value.permissionObjects?.value.length
                    )
                      return []

                    return permission.value.permissionObjects?.value.map(
                      (permissionObject): FormObjectStates => {
                        return {
                          id: permissionObject.propertyId,
                          isNew: !!permissionObject?.new,
                        }
                      },
                    )
                  })()

                  const permissionState =
                    getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(permission)

                  const objectNamePermissionsState = getModifierStatesFormPropertyState<
                    typeof PropertyTypeEnum.LIST
                  >(permission.value.permissionObjects)

                  const isChangedObjectNamePermissions =
                    objectNamePermissionsState.deletedElementFromList ||
                    objectNamePermissionsState.addedElementInList

                  const isChangedPermissionFields = !permissionState?.isNew
                    ? [
                        permission.value.permissionKind,
                        permission.value.permissionOrganization,
                        permission.value.isReceivedPermission,
                        permission.value.permissionDate,
                        permission.value.permissionNumber,
                        permission.value.receivedPermissionDate,
                      ].some(comparePropertyValueWithCommitedValue) ||
                      isChangedObjectNamePermissions
                    : false

                  return {
                    ...permissionState,
                    isChanged: isChangedPermissionFields,
                    objectNamePermissions: preparedObjectNamePermissions,
                    objectNamePermissionsState,
                  }
                },
              )
            })()

          const preparedExploitationObjects: FormObjectStatesWithChanges[] = (() => {
            if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
              return []

            return step.value.commissionObjects?.value.map(
              (exploitation): FormObjectStatesWithChanges => {
                const exploitationState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(exploitation)

                const isChangedExploitationFields = !exploitationState?.isNew
                  ? [
                      exploitation.value?.commissionObjectId,
                      exploitation.value?.commissionObjectDate,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...exploitationState,
                  isChanged: isChangedExploitationFields,
                }
              },
            )
          })()

          const preparedRegistrationObjects: FormObjectStatesWithChanges[] = (() => {
            if (
              !step.value.registrationObjects?.value ||
              !step.value.registrationObjects?.value.length
            )
              return []

            return step.value.registrationObjects?.value.map(
              (registration): FormObjectStatesWithChanges => {
                const registrationState =
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(registration)

                const isChangedRegistrationFields = !registrationState?.isNew
                  ? [
                      registration.value?.stateRegistrationType,
                      registration.value?.objectStateRegistrationDate,
                      registration.value?.objectStateRegistrationNumber,
                      registration.value?.objectStateRegistrationOrganization,
                    ].some(comparePropertyValueWithCommitedValue)
                  : false

                return {
                  ...registrationState,
                  isChanged: isChangedRegistrationFields,
                }
              },
            )
          })()

          const stepCompensationsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.stepCompensations)

          const constructionObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.objects)

          const permissionsObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.permissions)

          const exploitationObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.commissionObjects)

          const registrationObjectsState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.registrationObjects)

          const stepActivityNamesState = getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.stepActivityNames)

          const isChangedExploitationFields = comparePropertyValueWithCommitedValue(
            step.value.objectCommissioningEndDate,
          )

          const isChangedRegistrationFields = [
            step.value.typeObjectStateRegistration,
            step.value.objectStateRegistrationEndDate,
          ].some(comparePropertyValueWithCommitedValue)

          const isChangedStepCompensation = mergeChanges(
            preparedStepCompensations,
            stepCompensationsState,
          )

          const isChangedConstructionObjects = mergeChanges(
            preparedConstructionObjects,
            constructionObjectsState,
          )

          const isChangedPermissions = mergeChanges(preparedPermissions, permissionsObjectsState)

          const isChangedExploitation =
            isChangedExploitationFields ||
            mergeChanges(preparedExploitationObjects, exploitationObjectsState)

          const isChangedRegistration =
            isChangedRegistrationFields ||
            mergeChanges(preparedRegistrationObjects, registrationObjectsState)

          const stepState = getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(step)

          const isChangedStepFields = [
            step.value.stepNumber,
            step.value.stepDescription,
            step.value.stepCompensationValue,
            step.value.stepCompensationForm,
            step.value.stepEndDate,
          ].some(comparePropertyValueWithCommitedValue)

          const isChanged =
            stepActivityNamesState.addedElementInList ||
            stepActivityNamesState.deletedElementFromList ||
            isChangedStepCompensation ||
            isChangedConstructionObjects ||
            isChangedPermissions ||
            isChangedExploitation ||
            isChangedRegistration ||
            isChangedStepFields

          const checkboxes = (() => {
            const defaultState = {
              id: '',
              isNew: false,
            }

            let chosenCheckboxesStates = {
              isConstruction: defaultState,
              isReconstruction: defaultState,
              isModernization: defaultState,
              isCreateRID: defaultState,
            }

            step.value.stepActivityNames?.value.forEach((stepActivity) => {
              if (stepActivity.value?.name === 'Строительство (создание)') {
                chosenCheckboxesStates = {
                  ...chosenCheckboxesStates,
                  ['isConstruction']: {
                    id: stepActivity.value?.id,
                    isNew: !!stepActivity?.new,
                  },
                }
              }

              if (stepActivity.value?.name === 'Реконструкция') {
                chosenCheckboxesStates = {
                  ...chosenCheckboxesStates,
                  ['isReconstruction']: {
                    id: stepActivity.value?.id,
                    isNew: !!stepActivity?.new,
                  },
                }
              }

              if (stepActivity.value?.name === 'Модернизация') {
                chosenCheckboxesStates = {
                  ...chosenCheckboxesStates,
                  ['isModernization']: {
                    id: stepActivity.value?.id,
                    isNew: !!stepActivity?.new,
                  },
                }
              }

              if (stepActivity.value?.name === 'Создание РИД') {
                chosenCheckboxesStates = {
                  ...chosenCheckboxesStates,
                  ['isCreateRID']: {
                    id: stepActivity.value?.id,
                    isNew: !!stepActivity?.new,
                  },
                }
              }
            })

            return chosenCheckboxesStates
          })()

          return {
            ...stepState,
            ...checkboxes,
            isChanged: !stepState?.isNew ? isChanged : false,
            stepActivityNames: [],
            stepActivityNamesState,
            stepCompensations: preparedStepCompensations,
            stepCompensationsState,
            constructionObjects: preparedConstructionObjects,
            constructionObjectsState,
            permissionsObjects: preparedPermissions,
            permissionsObjectsState,

            exploitation: {
              isChanged: !stepState?.isNew ? isChangedExploitationFields : false,
              exploitationObjects: preparedExploitationObjects,
              exploitationObjectsState,
            },

            registration: {
              isChanged: !stepState?.isNew ? isChangedRegistrationFields : false,
              registrationObjects: preparedRegistrationObjects,
              registrationObjectsState,
            },
          }
        },
      )
    })()

  return {
    '5': {
      constructionStages: preparedConstructionStages,
    },
  }
}

export { generateFormModifierValuesForAAgreementOnChangingCharacteristicsOfObjectsForm }
