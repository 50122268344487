import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NoticeOneSideRejectSZPKFormValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/types'

const { getDefaultInitiatorFieldState, getDefaultInfoAboutDocument } = FieldStateHelpers

const mapOfNoticeOneSideRejectSZPKMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченная организация' },
  { id: '3', title: 'Отказ от СЗПК' },
]

const defaultNoticeOneSideRejectSZPKFormValues: NoticeOneSideRejectSZPKFormValues = {
  '1': getDefaultInfoAboutDocument(),
  '2': getDefaultInitiatorFieldState(),
  '3': {
    ruleLinkOneSideRejectReason: '',
    oneSideRejectReasonProof: '',
  },
}

const noticeOneSideRejectSZPKBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderNameGenitive: '2.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '2.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '2.initiatorHeaderReasonGenitive',
  },
  '3': {
    ruleLinkOneSideRejectReason: '3.ruleLinkOneSideRejectReason',
    oneSideRejectReasonProof: '3.oneSideRejectReasonProof',
  },
} as const

export {
  defaultNoticeOneSideRejectSZPKFormValues,
  mapOfNoticeOneSideRejectSZPKMenu,
  noticeOneSideRejectSZPKBlockValues,
}
