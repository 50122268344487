import { FC, ReactNode } from 'react'

import { isString } from '@helpers/checkTypes'
import cn from 'classnames'

import { ITypographyBody } from '../Typography/types'
import Typography from '../Typography/Typography'

import styles from './FieldInfo.module.scss'

export interface FieldInfoProps {
  label: string
  children: ReactNode | string
  variant?: 'primary' | 'secondary' | 'link'
  className?: string
  labelClassName?: string
  textProps?: Omit<ITypographyBody, 'children'>
}

const FieldInfo: FC<FieldInfoProps> = ({
  variant = 'primary',
  labelClassName,
  className,
  label,
  children,
  textProps,
}) => {
  const isSecondary = variant === 'secondary'
  const isLink = variant === 'link'

  return (
    <div
      className={cn(
        {
          [styles[`fieldInfo--${variant}`]]: variant,
        },
        className,
      )}
    >
      {!isSecondary && (
        <Typography.Caption variant="captionSRegular" className={cn(styles.label, labelClassName)}>
          {label}
        </Typography.Caption>
      )}
      {!isLink ? (
        isString(children) ? (
          <Typography.Body
            {...textProps}
            variant={textProps?.variant || 'bodyMMedium'}
            className={textProps?.className}
          >
            {children}
          </Typography.Body>
        ) : (
          <>{children}</>
        )
      ) : (
        <a
          className={styles.valueLink}
          {...(isString(children) ? { href: children } : { nohref: true })}
          target="_blank"
        >
          Источник
        </a>
      )}
    </div>
  )
}

export default FieldInfo
