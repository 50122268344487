import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Tooltip } from '@components/NewDesign/Tooltip'
import { mergeRefs } from '@helpers/ref/mergeRefs'
import { useBooleanState } from '@hooks/useBooleanState'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import cn from 'classnames'

import FormControl from '../FormControl'

import styles from './Input.module.scss'
import { InputProps, TErrorInput } from './types'

export const defineTypeError = (error: TErrorInput) => {
  switch (typeof error) {
    case 'string':
      return error
    case 'boolean':
      return ''

    default:
      return error.message
  }
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      enableConditionToShowTooltip = true,
      view = 'primary',
      caption,
      clear,
      disabled,
      inputActive = true,
      fixWidth,
      label,
      leftAddons,
      labelLeftAddons,
      rightAddons,
      labelRightAddons,
      size = 'xl',
      type,
      value,
      defaultValue,
      readOnly,
      error,
      chipContent,
      suffix,
      hideBorder,
      inputContainerRef,

      rootClassName,

      onBlur,
      onChange,
      onClear,
      onFocus,
      onKeyDown,
      onPaste,

      inputClue,
      inputCursor,

      className,
      labelClassName,
      labelTextClassName,
      labelWrapperClassName,
      addonsClassName,
      controlClassName,
      wrapperClassName,
      captionClassName,
      labelLeftAddonClassName,
      labelRightAddonClassName,
      beforeInput,

      tooltipProps,

      dataTestId,
      formControlDataTestId,
      id,
      ...props
    },
    ref,
  ) => {
    const {
      position = 'top',
      offset = !label ? [0, 10] : [0, 0],
      targetClassName,
      arrowClassName,
      contentClassName,
      popoverClassName,
      popperClassName,
      ...restTooltipProps
    } = tooltipProps || {}

    const isUncontrolledState = value === undefined

    const inputRef = useRef<HTMLInputElement>(null)
    const hiddenSpanRef = useRef<HTMLSpanElement>(null)

    const resizeObserver = useRef<ResizeObserver | null>(null)

    const containerRef = useRef<HTMLDivElement>(null)

    const [stateValue, setStateValue] = useState(defaultValue || '')
    const [focused, setFocused] = useState(props.autoFocus)

    const { booleanState: isShowTooltip, setBooleanState: setShowTooltip } = useBooleanState()

    const inputValue = isUncontrolledState ? stateValue : value

    const filled = Boolean(inputValue || chipContent)

    // отображаем крестик только для заполненного и активного инпута
    const clearButtonVisible = clear && filled && focused && !disabled && !readOnly

    const captionText = error ? defineTypeError(error) : caption

    const recalculateTooltip = useCallback(() => {
      if (!inputRef.current || !hiddenSpanRef.current) return

      if (!inputValue || !!focused || !enableConditionToShowTooltip) {
        setShowTooltip(false)

        return
      }

      const numberInputWidth = inputRef.current.clientWidth
      const numberHiddenSpanWidth = hiddenSpanRef.current.clientWidth

      setShowTooltip(!(numberHiddenSpanWidth <= numberInputWidth))
    }, [enableConditionToShowTooltip, focused, inputValue, setShowTooltip])

    const debouncedRecalculateTooltip = useDebouncedCallback(recalculateTooltip, 500)

    useEffect(() => {
      recalculateTooltip()

      if (!inputRef.current || !hiddenSpanRef.current) return

      resizeObserver.current = new ResizeObserver(debouncedRecalculateTooltip)
      resizeObserver.current.observe(inputRef.current)
      resizeObserver.current.observe(hiddenSpanRef.current)

      return () => {
        resizeObserver.current?.disconnect()
        resizeObserver.current = null
      }
    }, [debouncedRecalculateTooltip])

    const handleInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)

        if (isUncontrolledState) {
          setStateValue(event.target.value)
        }
      },
      [onChange, isUncontrolledState],
    )

    const handleClickToFocusInput = (e) => {
      e.stopPropagation()

      if (!focused) return inputRef.current?.focus()
    }

    const handleInputFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (!readOnly) {
          setFocused(true)
        }

        onFocus?.(event)
      },
      [onFocus, readOnly],
    )

    const handleInputBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (inputRef.current && !readOnly && containerRef.current?.contains(event.relatedTarget)) {
          return inputRef.current.focus()
        }

        setFocused(false)

        onBlur?.(event)
      },
      [onBlur, readOnly],
    )

    const handleClear = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (!clearButtonVisible) return

        if (isUncontrolledState) {
          setStateValue('')
        }

        onClear?.(event)

        if (!focused) return inputRef.current?.focus()
      },
      [clearButtonVisible, focused, onClear, isUncontrolledState],
    )

    const handleAddonsClick = (e) => {
      e.stopPropagation()
    }

    const inputContent = (
      <input
        disabled={disabled || !inputActive}
        readOnly={readOnly}
        value={inputValue}
        type={type}
        ref={mergeRefs([ref, inputRef])}
        data-testid={dataTestId}
        className={cn(styles.input, styles[view], className, {
          [styles.hasLabel]: label,
          [styles[view + '--disabled']]: disabled && !error,
          [styles['input--invalid']]: !disabled && error,
          [styles['input--before']]: !!leftAddons,
          [styles.error]: error,
        })}
        style={{
          cursor: inputCursor,
        }}
        onPaste={onPaste}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        {...props}
      />
    )

    return (
      <FormControl
        view={view}
        hideBorder={hideBorder}
        disabled={disabled}
        fixWidth={fixWidth}
        filled={filled}
        clear={clearButtonVisible}
        label={label}
        leftAddons={leftAddons}
        rightAddons={rightAddons}
        size={size}
        error={error}
        focused={focused}
        dataTestId={formControlDataTestId ?? dataTestId}
        caption={captionText}
        labelClassName={labelClassName}
        labelTextClassName={labelTextClassName}
        labelLeftAddons={labelLeftAddons}
        labelRightAddons={labelRightAddons}
        addonsClassName={addonsClassName}
        captionClassName={captionClassName}
        labelLeftAddonClassName={labelLeftAddonClassName}
        labelRightAddonClassName={labelRightAddonClassName}
        rootClassName={rootClassName}
        className={controlClassName}
        inputContainerRef={inputContainerRef}
        ref={containerRef}
        labelWrapperClassName={cn(
          {
            [styles['input__label-wrapper--withSuffix']]: !!suffix,
          },
          labelWrapperClassName,
        )}
        onAddonsClick={handleAddonsClick}
        onClear={handleClear}
      >
        <div
          id={id}
          role={'presentation'}
          tabIndex={0}
          className={cn(styles.inputWrapper, wrapperClassName, {
            [styles.chips]: chipContent,
            [styles[`${size}`]]: size,
            [styles['inputWrapper--disabled']]: disabled,
          })}
          style={{
            cursor: inputCursor,
          }}
          onClick={handleClickToFocusInput}
        >
          {chipContent}
          <div data-testid={`${dataTestId}-beforeInput`} className={styles.inputContainer}>
            {beforeInput}
            {isShowTooltip ? (
              <Tooltip
                {...restTooltipProps}
                content={inputValue}
                position={position}
                zIndex={55}
                fallbackPlacements={['bottom', 'left', 'right']}
                offset={offset}
                targetClassName={cn(styles.tooltip__target, targetClassName)}
                arrowClassName={cn(styles.tooltip__arrow, arrowClassName)}
                contentClassName={cn(styles.tooltip__content, contentClassName)}
                popperClassName={cn(styles.tooltip__popper, popperClassName)}
                popoverClassName={cn(styles.tooltip__popover, popoverClassName)}
              >
                {inputContent}
              </Tooltip>
            ) : (
              inputContent
            )}
            <span ref={hiddenSpanRef} className={styles['input-value--hidden']}>
              {inputValue}
            </span>
            {inputClue && (
              <span
                data-testid={`${dataTestId}-inputClue`}
                className={cn(styles.input, styles.backgroundText, className, {
                  [styles.backgroundTextWithLabel]: label,
                  [styles.hasLabel]: label,
                  [styles['input--invalid']]: !disabled && error,
                  [styles['input--before']]: !!leftAddons,
                  [styles.error]: error,
                })}
              >
                {inputClue}
              </span>
            )}
          </div>
          {suffix}
        </div>
      </FormControl>
    )
  },
)

export default Input
