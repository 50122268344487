import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import LoginForm from '@components/Forms/LoginForm'
import Typography from '@components/NewDesign/Typography/Typography'
import WithTechnicalWork from '@components/TechnicalWork/Wrapper'
import { Paths } from '@constants/paths'
import { useAuthContext } from '@context/AuthContext'
import { SessionStorageFields, StorageFields } from '@context/AuthContext/models'
import { UserInfoState } from '@context/AuthContext/types'
import { isNullOrUndefined } from '@helpers/checkTypes'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import useRedirectByRoles, { Rule } from '@hooks/useRedirectByRoles'
import AuthLayout from '@layouts/Auth'
import storage from 'store2'

import styles from './LoginPage.module.scss'

interface LocationState {
  redirectFrom?: Location
}

const LoginPage = () => {
  const { authState } = useAuthContext()

  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as LocationState
  const { redirectByRoles } = useRedirectByRoles()

  const redirectCallback = (rule: Rule) => {
    return navigate(rule.to, {
      replace: true,
    })
  }

  useUpdateEffect(() => {
    if (!authState?.token) return

    const currentUserInfo = storage.get(StorageFields.userInfo) as UserInfoState | undefined
    const lastAuthedUserId = sessionStorage.getItem(SessionStorageFields.lastAuthedUser)

    const isAuthRetry =
      currentUserInfo &&
      sessionStorage.getItem(SessionStorageFields.lastAuthedUser) === currentUserInfo.sub

    if ((isNullOrUndefined(lastAuthedUserId) || isAuthRetry) && locationState?.redirectFrom) {
      navigate(locationState.redirectFrom)
    } else {
      redirectByRoles(redirectCallback)
    }
  }, [authState])

  return (
    <WithTechnicalWork>
      <AuthLayout>
        <div className={styles.loginPage}>
          <LoginForm />
        </div>
        <Typography.Body variant="bodyLMedium" className={styles.politics}>
          Входя в личный кабинет, вы соглашаетесь
          <br />
          {'с '}
          <Link to={Paths.Politics} target="_blank" className={styles.politics__link}>
            политикой обработки персональных данных
          </Link>
        </Typography.Body>
      </AuthLayout>
    </WithTechnicalWork>
  )
}

export default LoginPage
