import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import ProjectStageStepCompensations from '@components/Forms/ProjectSZPKForm/Forms/6/AdditionalForms/ProjectStage/StepCompensations'
import { StagesArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/6/types'
import { sixthSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/6/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isNotEmptyString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

interface ProjectStageProps {
  id: string
  indexOfStep: number
  name: StagesArrayPathName
  onRemoveStage: VoidFunction
}

const STEP_COMPENSATION_FORM_TO_IGNORE = ['Налоговый вычет', 'Субсидия и (или) налоговый вычет']

const ProjectStage: FC<ProjectStageProps> = ({ id, name, indexOfStep, onRemoveStage }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const {
    getSubscribableControlProps,
    getTextareaProps,
    getCalendarInputProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const stepCompensationForm = useWatch({
    name: `${name}.stepCompensationValue`,
  })

  const isFederal = useWatch({
    name: projectSZPKBlockValues.additionalFields.isFederal,
    control: formInstance?.control,
  })

  if (!formInstance) return null

  const stepCompensationBlockRenderCondition = isNotEmptyString(stepCompensationForm)

  return (
    <FieldView.StepTemplate id={id} onRemoveStep={editMode ? onRemoveStage : undefined}>
      <Stack direction={'vertical'} gap={3}>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          title={'Номер этапа'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.stepNumber`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${name}.stepNumber`,
                rules: sixthSectionValidationMap.stepNumber,
                inputProps: {
                  integersOnly: true,
                  hideSuffix: true,
                  hideCurrency: true,
                  hasNegativeValue: false,
                },
                onBlur: () => setTimeout(() => handleChangeValue?.(`${name}.stepNumber`), 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${name}.stepNumber`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepDescription`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.stepDescription`,
                  rules: sixthSectionValidationMap.stepDescription,
                  textareaProps: {
                    label: 'Описание этапа',
                  },
                  onBlur: () => setTimeout(() => handleChangeValue?.(`${name}.stepDescription`)),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepDescription`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepStartDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.stepStartDate`,
                  rules: {
                    ...sixthSectionValidationMap.stepStartDate,
                    validate: {
                      positiveProjectStartDate: (value) => {
                        const projectStartDate = formInstance.getValues(
                          projectSZPKBlockValues['5'].projectStartDate,
                        )

                        return (
                          (isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) >=
                              dayjs(projectStartDate, objOfDateFormats.defaultFormat)) ||
                          'дата начала этапа должна быть больше или равна дате начала проекта'
                        )
                      },
                    },
                    onChange: () => {
                      formInstance.trigger(`${name}.stepEndDate`)
                    },
                  },
                  calendarInputProps: {
                    label: 'Дата начала этапа',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepStartDate`)
                    }, 0),
                  onInputChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepStartDate`)
                    }, 0),
                  onCalendarChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.stepStartDate`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepEndDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.stepEndDate`,
                  rules: {
                    ...sixthSectionValidationMap.stepEndDate,
                    validate: {
                      positiveProjectStartDate: (value) => {
                        const projectEndDate = formInstance.getValues(
                          `additionalFields.projectEndDate`,
                        )

                        if (
                          isDateValidForDayjs(value) &&
                          dayjs(value, objOfDateFormats.defaultFormat) >
                            dayjs(projectEndDate, objOfDateFormats.defaultFormat)
                        )
                          return 'дата окончания этапа должна быть меньше или равна дате окончания проекта'
                      },
                      positiveStepStartDate: (value) => {
                        const stepStartDate = formInstance.getValues(
                          `${name}.stepStartDate`,
                        ) as string

                        return (
                          (isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) >
                              dayjs(stepStartDate, objOfDateFormats.defaultFormat)) ||
                          'дата окончания этапа должна быть больше даты начала этапа'
                        )
                      },
                    },
                    onChange: () => {
                      const activityOthers = formInstance.getValues(
                        `${projectSZPKBlockValues['8'].stepActivityOthers}.${indexOfStep}.activityOthers`,
                      )

                      activityOthers.forEach((activity, index) => {
                        formInstance.trigger(
                          `${projectSZPKBlockValues['8'].stepActivityOthers}.${indexOfStep}.activityOthers.${index}.otherActivityEndDate`,
                        )
                      })
                    },
                  },
                  calendarInputProps: {
                    label: 'Дата окончания этапа',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepEndDate`)
                    }, 0),
                  onInputChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepEndDate`)
                    }, 0),
                  onCalendarChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.stepEndDate`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row className={'px-2'}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Объем капиталовложений (без НДС)'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepInvestorCapitalValue`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.stepInvestorCapitalValue`,
                  rules: sixthSectionValidationMap.stepInvestorCapitalValue,
                  inputProps: {
                    dataTestId: 'stepInvestorCapitalValue',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.stepInvestorCapitalValue`)),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepInvestorCapitalValue`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
        </Row>
        <Row>
          <Col xs={12}>
            <Group
              title={'Планируемые к возмещению затраты'}
              disableBottomBorder={!stepCompensationBlockRenderCondition}
            >
              <Stack direction={'vertical'} gap={3}>
                <Row className={'px-2'}>
                  <FieldView.RowWithBottomBorder
                    disableBottomDefaultStyles
                    firstColumnSize={6}
                    secondColumnSize={6}
                    title={'Объем планируемых к возмещению затрат (без НДС)'}
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.stepCompensationValue`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${name}.stepCompensationValue`,
                          rules: sixthSectionValidationMap.stepCompensationValue,
                          inputProps: {
                            dataTestId: 'stepCompensationValue',
                          },
                          onBlur: () => handleChangeValue?.(`${name}.stepCompensationValue`),
                          onChange: () =>
                            setTimeout(() => {
                              debouncedHandleChangeValue?.(`${name}.stepCompensationValue`)
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </FieldView.RowWithBottomBorder>
                </Row>
                <CollapseWrapper
                  defaultExpanded={stepCompensationBlockRenderCondition}
                  isExpanded={stepCompensationBlockRenderCondition}
                >
                  <Row>
                    <Col xs={12}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.stepCompensationForm`,
                        })}
                      >
                        {({ overrideProps }) => {
                          const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                            ? overrideProps.fetcherOptions
                            : undefined

                          return (
                            <ControlledFormSingleSelect
                              {...getSingleSelectProps({
                                fetcherProps,
                                optionsAdapter: (item) => {
                                  if (
                                    !isFederal &&
                                    STEP_COMPENSATION_FORM_TO_IGNORE.some(
                                      (value) => value == item.name,
                                    )
                                  )
                                    return null

                                  return {
                                    displayValue: item.name || '',
                                    value: item.id,
                                  }
                                },
                                selectProps: {
                                  withContextSearch: true,
                                  inputProps: {
                                    dataTestId: 'stepCompensationForm',
                                    label: 'В форме',
                                  },
                                  onChangeFormValue: () =>
                                    handleChangeValue?.(`${name}.stepCompensationForm`),
                                },
                                controllerProps: {
                                  name: `${name}.stepCompensationForm`,
                                },
                              })}
                            />
                          )
                        }}
                      </SubscribableControl>
                    </Col>
                  </Row>
                </CollapseWrapper>
              </Stack>
            </Group>
          </Col>
        </Row>
        <CollapseWrapper
          defaultExpanded={stepCompensationBlockRenderCondition}
          isExpanded={stepCompensationBlockRenderCondition}
        >
          <Row>
            <Col xs={12}>
              <ProjectStageStepCompensations
                formName={`${name}.stepCompensations`}
                editMode={editMode}
                formInstance={formInstance}
                blockViewIsValidating={blockViewIsValidating}
              />
            </Col>
          </Row>
        </CollapseWrapper>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default ProjectStage
