import React, { FC, memo, MouseEvent, ReactNode, useMemo } from 'react'

import VersionButton from '@components/Attachments/Version'
import { useVersionManager } from '@components/Attachments/Version/manager'
import Button from '@components/NewDesign/Button'
import { Switch } from '@components/NewDesign/Switch'
import { useChangesMadeContext } from '@components/Projects/[id]/DocumentLayout/ChangesMade/Context'
import styles from '@components/Projects/[id]/DocumentLayout/View/DocumentsView.module.scss'
import { VersionControllerProvider } from '@components/Projects/[id]/VersionConroller'
import { DfosStage } from '@constants/types'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import { IModifiedDocumentSetInfoForRenderToArray } from '@services/Documents/documents.entity'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'
import cn from 'classnames'

const { isDocumentReadEditPermission, isDocumentReadAddSignPermission } = DocumentsHelpersService

interface SharedHeadOfDfoLayoutProps {
  dfo: IDfoListItem | null
  handleResetOnlyChangedDocumentsSwitch: VoidFunction

  actionsClassName?: string
  downloadButton?: ReactNode
  hasServerError?: boolean
  mapOfSetsToRender?: IModifiedDocumentSetInfoForRenderToArray[]

  children: ReactNode
}

const LayoutHeader: FC<SharedHeadOfDfoLayoutProps> = ({
  dfo,
  mapOfSetsToRender,
  actionsClassName,
  downloadButton,
  hasServerError,
  handleResetOnlyChangedDocumentsSwitch,
  children,
}) => {
  const {
    state: { versions, versionName },
  } = useVersionManager()

  const {
    isChangesMadeTab,
    isVersionsMadeTab,
    mustShowOnlyChangesSetOfDocuments,
    handleClickChangesMadeTab,
    handleClickVersionTab,
    updateDocumentSets,
    handleChangeSwitchState,
    handleResetSwitchStatus,
    isDocumentForRevision,
  } = useChangesMadeContext()

  /**
   * Находим любой документ с правами на редактирование или подпись
   */
  const hasPermissionsWithAction = useMemo(
    () =>
      mapOfSetsToRender?.some((item) =>
        item?.documentSets?.some(
          (item) =>
            item?.permissions &&
            (isDocumentReadEditPermission(item.permissions) ||
              isDocumentReadAddSignPermission(item.permissions)),
        ),
      ),
    [mapOfSetsToRender],
  )

  const mapOfSetsForRevisionToRender = mapOfSetsToRender?.filter(isDocumentForRevision)

  const openButtonVersionProps = useMemo(
    () => ({
      textClassName: !isVersionsMadeTab ? styles.documentsView__disabledColor : undefined,
    }),
    [isVersionsMadeTab],
  )

  const handleChangesMadeTab = async (e) => {
    await updateDocumentSets()

    handleResetSwitchStatus()
    handleClickChangesMadeTab(e)
  }

  const handleTabVersionClick = async (e: MouseEvent<HTMLButtonElement>) => {
    if (!isChangesMadeTab) return

    try {
      handleClickVersionTab(e)
    } catch (e) {}
  }

  const hasVersionChangeSwitch = dfo?.stage !== DfosStage.DRAFT && (versions?.length ?? 0) > 1
  const hasChangesMadeTab = Boolean(mapOfSetsForRevisionToRender?.length)

  return (
    <VersionControllerProvider>
      <div>
        <div
          className={cn(styles.documentsView__buttons, actionsClassName, {
            [styles['documentsView__buttons-error']]: hasServerError,
            [styles['documentsView__buttons-versionOnly']]: !downloadButton,
          })}
        >
          {downloadButton}
          {hasVersionChangeSwitch && !isChangesMadeTab && !hasChangesMadeTab && (
            <Switch
              wrapperClassName={styles.documentsView__switch}
              labelClassName={styles['documentsView__switch-label']}
              label={'Показывать только изменения'}
              value={mustShowOnlyChangesSetOfDocuments}
              dataTestId="LayoutHeader-onlyChanges-switch"
              onChange={handleChangeSwitchState}
            />
          )}
          <div className={styles.documentsView__buttons__block}>
            {Boolean(mapOfSetsForRevisionToRender?.length) && (
              <Button
                geometry="round"
                size="s"
                fontWeight="medium"
                variant="buttonSMedium"
                view="gray"
                dataTestId="LayoutHeader-onlyChanges-button"
                textClassName={!isChangesMadeTab ? styles.documentsView__disabledColor : undefined}
                className={styles.documentsViewChangesMadeButton}
                onClick={handleChangesMadeTab}
              >
                Вносимые изменения
              </Button>
            )}

            <button onClickCapture={handleTabVersionClick}>
              <VersionButton
                dfo={dfo}
                versionName={versionName}
                versions={versions}
                openButtonProps={openButtonVersionProps}
                shouldRenderLessTwoVersions={Boolean(mapOfSetsForRevisionToRender?.length)}
                hasPermissionsWithAction={hasPermissionsWithAction}
                updateDocumentSets={updateDocumentSets}
                onResetOnlyChangedDocumentsSwitch={handleResetOnlyChangedDocumentsSwitch}
              />
            </button>
          </div>
        </div>
        {children}
      </div>
    </VersionControllerProvider>
  )
}

export default memo(LayoutHeader)
