import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import styles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import StepAnotherObjectForm from '@components/Forms/ProjectSZPKForm/Forms/8/AdditionalForms/AnotherObjectForm/StepAnotherObjectForm'
import { EighthMeasureObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/8/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldCollapseControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

interface StepAnotherObjectProps {
  id: string
  anchorId: string
  name: EighthMeasureObjectsArrayPathName
  indexOfObject: number
  onRemoveObject: VoidFunction
}

const StepAnotherObject: FC<StepAnotherObjectProps> = ({
  id,
  anchorId,
  indexOfObject,
  name,
  onRemoveObject,
}) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getControllerProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { isExpanded: isShowObjectItemContent, onToggleCollapse: toggleShowObjectItemContent } =
    useFormCollapseControl({
      initialExpanded: true,
      name,
      watcher: ProjectSZPKFieldCollapseControlUpdateWatcher,
    })

  const handleRemoveObject = async () => {
    await onRemoveObject()
  }

  return (
    <FlippedWithCollapse flipId={id}>
      <Row id={anchorId}>
        <Col xs={12}>
          <div className={styles.objectItem}>
            <div className={styles.objectItem__header}>
              <ObjectStepWrapperHeaderNode
                buttonProps={{
                  onClick: toggleShowObjectItemContent,
                  trailingIcon: {
                    className: cn({
                      [styles['objectItem__header-button__icon--active']]: isShowObjectItemContent,
                    }),
                  },
                }}
              >
                ОБЪЕКТ №{indexOfObject + 1}
              </ObjectStepWrapperHeaderNode>

              {editMode && (
                <AsyncWrapper promise={handleRemoveObject}>
                  {({ isLoading, wrappedPromise }) => (
                    <Button
                      disabled={isLoading}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      color={'negative'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      leadingIcon={{
                        noCurrentColorSvgFill: true,
                        src: DeleteIcon,
                      }}
                      onClick={wrappedPromise}
                    >
                      Удалить
                    </Button>
                  )}
                </AsyncWrapper>
              )}
            </div>
            <div className={styles.objectItem__content}>
              {!isShowObjectItemContent && (
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.objectName`,
                      })}
                    >
                      <ControlledDocumentDataView
                        suptitle={'Наименование'}
                        controllerProps={getControllerProps({
                          name: `${name}.objectName`,
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
              <CollapseWrapper isExpanded={isShowObjectItemContent}>
                <StepAnotherObjectForm formName={name} />
              </CollapseWrapper>
            </div>
          </div>
        </Col>
      </Row>
    </FlippedWithCollapse>
  )
}

export default StepAnotherObject
