//Переиспользуемые адаптеры

import { OrganizationInfoHelpersService } from '@components/OrganizationInfo/helpers'

class RHFBasicAdapters {
  static getRFAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedRfHeaderInfo = (() => {
      const defaultRfHeaderValue = {
        rfHeaderName: null,
        rfHeaderNameGenitive: '',
        rfHeaderPosition: '',
        rfHeaderPositionGenitive: '',
        rfHeaderReasonGenitive: '',
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultRfHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id === objectFromPropertyState.rf?.permanentId
        )
      })

      if (!headerInfo) return defaultRfHeaderValue

      return {
        rfHeaderName: !!headerInfo.value.signer?.value?.id
          ? {
              value: headerInfo.value.signer?.value?.id || '',
              displayValue: headerInfo.value.signer?.value?.headerName || '',
            }
          : null,
        rfHeaderNameGenitive: headerInfo.value.signer?.value?.headerNameGenitive || '',
        rfHeaderPosition: headerInfo.value.signer?.value?.headerPosition || '',
        rfHeaderPositionGenitive: headerInfo.value.signer?.value?.headerPositionGenitive || '',
        rfHeaderReasonGenitive: headerInfo.value.signer?.value?.headerReasonGenitive || '',
      }
    })()

    return {
      rfFullName: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberFullName,
      rfName: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberName,
      rfAddress: OrganizationInfoHelpersService.generateAddressInOneString({
        region: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.region,
        city: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.city,
        district: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.district,
      }),
      rfInn: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberInn,
      rfKpp: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberKpp,
      rfOgrn: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberOgrn,
      rfOktmo: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberOktmo,
      rfPaymentDetails:
        objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberPaymentDetails,
      rfOtherDetails: OrganizationInfoHelpersService.generateOtherDetails({
        memberEmail: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberEmail,
        memberPhone: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberPhone,
        memberFax: objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberFax,
        memberPhoneExtension:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.memberPhoneExtension,
        memberAddressEgrul:
          objectFromPropertyState.rf?.value?.memberOrganizationId?.value?.addressFullEgrulFormat,
      }),
      ...preparedRfHeaderInfo,
    }
  }

  static getSubjectsAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedSubjects = (() => {
      const defaultSubjectValue = [
        {
          subjectFullName: '',
          subjectRegion: '',
          subjectAddress: '',
          subjectInn: '',
          subjectKpp: '',
          subjectOgrn: '',
          subjectOktmo: '',
          subjectOtherDetails: '',
          subjectPaymentDetails: '',
          subjectHeaderName: null,
          subjectHeaderNameGenitive: '',
          subjectHeaderPosition: '',
          subjectHeaderPositionGenitive: '',
          subjectHeaderReasonGenitive: '',
        },
      ]

      if (!objectFromPropertyState.subjects || !objectFromPropertyState.subjects?.value.length)
        return defaultSubjectValue

      const preparedForDocSigners = objectFromPropertyState.subjects?.value.map((subject) => ({
        id: subject.permanentId,
        subjectFullName: subject.value?.memberOrganizationId?.value?.memberFullName,
        subjectRegion: subject.value?.memberOrganizationId?.value?.region,
        subjectAddress: OrganizationInfoHelpersService.generateAddressInOneString({
          region: subject.value?.memberOrganizationId?.value?.region,
          city: subject.value?.memberOrganizationId?.value?.city,
          district: subject.value?.memberOrganizationId?.value?.district,
        }),
        subjectInn: subject.value?.memberOrganizationId?.value?.memberInn,
        subjectKpp: subject.value?.memberOrganizationId?.value?.memberKpp,
        subjectOgrn: subject.value?.memberOrganizationId?.value?.memberOgrn,
        subjectOktmo: subject.value?.memberOrganizationId?.value?.memberOktmo,
        subjectOtherDetails: OrganizationInfoHelpersService.generateOtherDetails({
          memberEmail: subject.value?.memberOrganizationId?.value?.memberEmail,
          memberPhone: subject.value?.memberOrganizationId?.value?.memberPhone,
          memberFax: subject.value?.memberOrganizationId?.value?.memberFax,
          memberPhoneExtension: subject.value?.memberOrganizationId?.value?.memberPhoneExtension,
          memberAddressEgrul: subject.value?.memberOrganizationId?.value?.addressFullEgrulFormat,
        }),
        subjectPaymentDetails: subject.value?.memberOrganizationId?.value?.memberPaymentDetails,
        subjectHeaderName: null,
        subjectHeaderNameGenitive: '',
        subjectHeaderPosition: '',
        subjectHeaderPositionGenitive: '',
        subjectHeaderReasonGenitive: '',
      }))

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return preparedForDocSigners

      const preparedSubjects = preparedForDocSigners.map((subjectWithoutHeaderInfo) => {
        const headerInfo = docSigners.find((docSigner) => {
          return docSigner.value?.signerOrganization?.value.id === subjectWithoutHeaderInfo.id
        })

        if (!headerInfo) return subjectWithoutHeaderInfo

        return {
          ...subjectWithoutHeaderInfo,
          //Для селекта используется id в качестве значения
          subjectHeaderName: !!headerInfo.value.signer?.value?.id
            ? {
                value: headerInfo.value.signer?.value.id || '',
                displayValue: headerInfo.value.signer?.value?.headerName || '',
              }
            : null,
          subjectHeaderNameGenitive: headerInfo.value.signer?.value?.headerNameGenitive || '',
          subjectHeaderPosition: headerInfo.value.signer?.value?.headerPosition || '',
          subjectHeaderPositionGenitive:
            headerInfo.value.signer?.value?.headerPositionGenitive || '',
          subjectHeaderReasonGenitive: headerInfo.value.signer?.value?.headerReasonGenitive || '',
        }
      })

      return preparedSubjects
    })()

    return preparedSubjects
  }

  static getMunicipalitiesAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedMunicipalities = (() => {
      const defaultMunicipalityValue = [
        {
          municipalityFullName: '',
          municipalityNameGenitive: '',
          municipalityHeaderName: null,
          municipalityHeaderPosition: '',
          municipalityHeaderNameGenitive: '',
          municipalityHeaderPositionGenitive: '',
          municipalityHeaderReasonGenitive: '',
        },
      ]

      if (
        !objectFromPropertyState.municipalities ||
        !objectFromPropertyState.municipalities?.value.length
      )
        return defaultMunicipalityValue

      const preparedForDocSigners = objectFromPropertyState.municipalities?.value.map(
        (municipality) => ({
          id: municipality.permanentId,
          municipalityFullName: municipality.value?.memberOrganizationId?.value?.memberFullName,
          municipalityNameGenitive: municipality.value.memberNameGenitive?.value,
          municipalityFullNameGenitive: municipality?.value?.memberFullNameGenitive?.value,
          municipalityHeaderName: null,
          municipalityHeaderPosition: '',
          municipalityHeaderNameGenitive: '',
          municipalityHeaderPositionGenitive: '',
          municipalityHeaderReasonGenitive: '',
        }),
      )

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return preparedForDocSigners

      const preparedMunicipalities = preparedForDocSigners.map((municipalityWithoutHeaderInfo) => {
        const headerInfo = docSigners.find((docSigner) => {
          return docSigner.value?.signerOrganization?.value.id === municipalityWithoutHeaderInfo.id
        })

        if (!headerInfo) return municipalityWithoutHeaderInfo

        return {
          ...municipalityWithoutHeaderInfo,
          //Для селекта используется id в качестве значения
          municipalityHeaderName: !!headerInfo.value.signer?.value?.id
            ? {
                value: headerInfo.value.signer?.value.id || '',
                displayValue: headerInfo.value.signer?.value.headerName || '',
              }
            : null,
          municipalityHeaderNameGenitive: headerInfo.value.signer?.value?.headerNameGenitive || '',
          municipalityHeaderPosition: headerInfo.value.signer?.value?.headerPosition || '',
          municipalityHeaderPositionGenitive:
            headerInfo.value.signer?.value?.headerPositionGenitive || '',
          municipalityHeaderReasonGenitive:
            headerInfo.value.signer?.value?.headerReasonGenitive || '',
        }
      })

      return preparedMunicipalities
    })()

    return preparedMunicipalities
  }

  static getNewMunicipalityAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedNewMunicipalityHeaderInfo = (() => {
      const defaultNewMunicipalityHeaderValue = {
        municipalityHeaderName: null,
        municipalityHeaderNameGenitive: '',
        municipalityHeaderPosition: '',
        municipalityHeaderPositionGenitive: '',
        municipalityHeaderReasonGenitive: '',
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultNewMunicipalityHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.newMunicipality?.permanentId
        )
      })

      if (!headerInfo) return defaultNewMunicipalityHeaderValue

      return {
        municipalityHeaderName: !!headerInfo.value.signer.value?.id
          ? {
              value: headerInfo.value.signer.value?.id || '',
              displayValue: headerInfo.value.signer.value?.headerName || '',
            }
          : null,
        municipalityHeaderNameGenitive: headerInfo.value.signer.value?.headerNameGenitive,
        municipalityHeaderPosition: headerInfo.value.signer.value?.headerPosition,
        municipalityHeaderPositionGenitive: headerInfo.value.signer.value?.headerPositionGenitive,
        municipalityHeaderReasonGenitive: headerInfo.value.signer.value?.headerReasonGenitive,
      }
    })()

    return {
      municipalityFullName:
        objectFromPropertyState.newMunicipality?.value?.memberOrganizationId?.value?.memberFullName,
      municipalityFullNameGenitive:
        objectFromPropertyState.newMunicipality?.value?.memberFullNameGenitive?.value,
      municipalityFullNameInstrumental:
        objectFromPropertyState.newMunicipality?.value?.memberFullNameInstrumental?.value,
      ...preparedNewMunicipalityHeaderInfo,
    }
  }

  static getInvestorAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInvestorHeaderInfo = (() => {
      const defaultInvestorHeaderValue = {
        investorHeaderName: null,
        investorHeaderNameGenitive: '',
        investorHeaderPosition: '',
        investorHeaderPositionGenitive: '',
        investorHeaderReasonGenitive: '',
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultInvestorHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.investor?.permanentId
        )
      })

      if (!headerInfo) return defaultInvestorHeaderValue

      return {
        investorHeaderName: !!headerInfo.value.signer?.value?.id
          ? {
              value: headerInfo.value.signer?.value.id || '',
              displayValue: headerInfo.value.signer?.value.headerName || '',
            }
          : null,
        investorHeaderNameGenitive: headerInfo.value.signer?.value?.headerNameGenitive || '',
        investorHeaderPosition: headerInfo.value.signer?.value?.headerPosition || '',
        investorHeaderPositionGenitive:
          headerInfo.value.signer?.value?.headerPositionGenitive || '',
        investorHeaderReasonGenitive: headerInfo.value.signer?.value?.headerReasonGenitive || '',
      }
    })()

    return {
      investorFullName:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberFullName,
      investorName:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberName,
      investorFullNameGenitive:
        objectFromPropertyState.investor?.value.investorFullNameGenitive?.value,
      investorFullNameInstrumental:
        objectFromPropertyState.investor?.value?.investorFullNameInstrumental?.value,
      investorInn: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberInn,
      investorOgrn:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberOgrn,
      investorKpp: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberKpp,
      investorOktmo:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberOktmo,
      investorOkpo:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberOkpo,
      investorCapital: !!objectFromPropertyState.investor?.value?.memberOrganizationId?.value
        ?.memberCapital
        ? `${objectFromPropertyState.investor?.value?.memberOrganizationId?.value.memberCapital} ₽`
        : '',
      investorOkved:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberOkved,
      investorPaymentDetails:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberPaymentDetails,
      investorDetails: OrganizationInfoHelpersService.generateOtherDetails({
        memberEmail:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberEmail,
        memberPhone:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberPhone,
        memberFax: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.memberFax,
        memberPhoneExtension:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value
            ?.memberPhoneExtension,
      }),
      investorLocation: OrganizationInfoHelpersService.generateAddressInOneString({
        region: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.region,
        city: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.city,
        district: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.district,
      }),
      investorAddress: OrganizationInfoHelpersService.generateAddressInOneString({
        index: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.index,
        region: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.region,
        city: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.city,
        district: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.district,
        area: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.area,
        planningStructureElement:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value
            ?.planningStructureElement,
        house: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.house,
        room: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.room,
        roadNetworkElement:
          objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.roadNetworkElement,
        roomPart: objectFromPropertyState.investor?.value?.memberOrganizationId?.value?.roomPart,
      }),
      ...preparedInvestorHeaderInfo,
    }
  }

  static getUOAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedUoHeaderInfo = (() => {
      const defaultUoHeaderValue = {
        uoHeaderName: null,
        uoHeaderNameGenitive: '',
        uoHeaderPosition: '',
        uoHeaderPositionGenitive: '',
        uoHeaderReasonGenitive: '',
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultUoHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id === objectFromPropertyState.uo?.permanentId
        )
      })

      if (!headerInfo) return defaultUoHeaderValue

      return {
        uoHeaderName: !!headerInfo.value.signer?.value?.id
          ? {
              value: headerInfo.value.signer?.value.id || '',
              displayValue: headerInfo.value.signer?.value?.headerName || '',
            }
          : null,
        uoHeaderNameGenitive: headerInfo.value.signer?.value?.headerNameGenitive || '',
        uoHeaderPosition: headerInfo.value.signer?.value?.headerPosition || '',
        uoHeaderPositionGenitive: headerInfo.value.signer?.value?.headerPositionGenitive || '',
        uoHeaderReasonGenitive: headerInfo.value.signer?.value?.headerReasonGenitive || '',
      }
    })()

    return {
      uoFullName: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberFullName,
      uoName: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberName,
      uoAddress: OrganizationInfoHelpersService.generateAddressInOneString({
        region: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.region,
        city: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.city,
        district: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.district,
      }),
      uoInn: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberInn,
      uoKpp: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberKpp,
      uoOgrn: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberOgrn,
      uoOktmo: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberOktmo,
      uoPaymentDetails:
        objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberPaymentDetails,
      uoOtherDetails: OrganizationInfoHelpersService.generateOtherDetails({
        memberEmail: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberEmail,
        memberPhone: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberPhone,
        memberFax: objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberFax,
        memberPhoneExtension:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.memberPhoneExtension,
        memberAddressEgrul:
          objectFromPropertyState.uo?.value?.memberOrganizationId?.value?.addressFullEgrulFormat,
      }),
      ...preparedUoHeaderInfo,
    }
  }

  static getInvestorPartnerAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInvestorPartnerHeaderInfo = (() => {
      const defaultInvestorPartnerHeaderValue = {
        investorPartnerHeaderName: null,
        investorPartnerHeaderNameGenitive: '',
        investorPartnerHeaderPosition: '',
        investorPartnerHeaderPositionGenitive: '',
        investorPartnerHeaderReasonGenitive: '',
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultInvestorPartnerHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.investorPartner?.permanentId
        )
      })

      if (!headerInfo) return defaultInvestorPartnerHeaderValue

      return {
        investorPartnerHeaderName: !!headerInfo.value.signer?.value?.id
          ? {
              value: headerInfo.value.signer?.value.id || '',
              displayValue: headerInfo.value.signer?.value.headerName || '',
            }
          : null,
        investorPartnerHeaderNameGenitive: headerInfo.value.signer?.value?.headerNameGenitive || '',
        investorPartnerHeaderPosition: headerInfo.value.signer?.value?.headerPosition || '',
        investorPartnerHeaderPositionGenitive:
          headerInfo.value.signer?.value?.headerPositionGenitive || '',
        investorPartnerHeaderReasonGenitive:
          headerInfo.value.signer?.value?.headerReasonGenitive || '',
      }
    })()

    return {
      investorPartnerFullName:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberFullName,
      investorPartnerName:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberName,
      investorPartnerAddress: OrganizationInfoHelpersService.generateAddressInOneString({
        region: objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.region,
        city: objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.city,
        district:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.district,
      }),
      investorPartnerInn:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberInn,
      investorPartnerKpp:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberKpp,
      investorPartnerOgrn:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberOgrn,
      investorPartnerOktmo:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberOktmo,
      investorPartnerPaymentDetails:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value
          ?.memberPaymentDetails,
      investorPartnerAddressFullEgrulFormat:
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value
          ?.addressFullEgrulFormat,
      investorPartnerOtherDetails: OrganizationInfoHelpersService.generateOtherDetails({
        memberEmail:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberEmail,
        memberPhone:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberPhone,
        memberFax:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value?.memberFax,
        memberPhoneExtension:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value
            ?.memberPhoneExtension,
        memberAddressEgrul:
          objectFromPropertyState.investorPartner?.value?.memberOrganizationId?.value
            ?.addressFullEgrulFormat,
      }),
      ...preparedInvestorPartnerHeaderInfo,
    }
  }

  static getInitiatorAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInitiatorHeaderInfo = (() => {
      const defaultInitiatorHeaderValue = {
        initiatorHeaderName: null,
        initiatorHeaderNameGenitive: '',
        initiatorHeaderPosition: '',
        initiatorHeaderPositionGenitive: '',
        initiatorHeaderReasonGenitive: '',
      }

      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length) return defaultInitiatorHeaderValue

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.initiator?.permanentId
        )
      })

      if (!headerInfo) return defaultInitiatorHeaderValue

      return {
        initiatorHeaderName: !!headerInfo.value.signer?.value?.id
          ? {
              value: headerInfo.value.signer?.value.id || '',
              displayValue: headerInfo.value.signer?.value.headerName || '',
            }
          : null,
        initiatorHeaderNameGenitive: headerInfo.value.signer?.value?.headerNameGenitive || '',
        initiatorHeaderPosition: headerInfo.value.signer?.value?.headerPosition || '',
        initiatorHeaderPositionGenitive:
          headerInfo.value.signer?.value?.headerPositionGenitive || '',
        initiatorHeaderReasonGenitive: headerInfo.value.signer?.value?.headerReasonGenitive || '',
      }
    })()

    return {
      initiatorFullName:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberFullName,
      initiatorInn:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberInn,
      initiatorOgrn:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberOgrn,
      initiatorKpp:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberKpp,
      initiatorOktmo:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberOktmo,
      initiatorAddressEgrul:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value
          ?.addressFullEgrulFormat,
      initiatorLocation: OrganizationInfoHelpersService.generateAddressInOneString({
        region: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.region,
        city: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.city,
        district: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.district,
      }),
      initiatorAddress: OrganizationInfoHelpersService.generateAddressInOneString({
        index: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.index,
        region: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.region,
        city: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.city,
        district: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.district,
        area: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.area,
        planningStructureElement:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value
            ?.planningStructureElement,
        house: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.house,
        room: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.room,
        roadNetworkElement:
          objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.roadNetworkElement,
        roomPart: objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.roomPart,
      }),
      initiatorPaymentDetails:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberPaymentDetails,
      initiatorOtherDetails:
        objectFromPropertyState.initiator?.value?.memberOrganizationId?.value?.memberOtherDetails,
      ...preparedInitiatorHeaderInfo,
    }
  }

  static getInfoAboutDocumentAdapter(objectFromPropertyState: Record<string, any>) {
    return {
      documentDateSign: objectFromPropertyState?.documentDateSign?.value || '',
      documentNumber: objectFromPropertyState?.documentNumber?.value || '',
      documentReceivers: objectFromPropertyState?.documentReceivers?.value || '',
    }
  }
}

export { RHFBasicAdapters }
