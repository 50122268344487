import { FC, ReactNode, useRef, useState } from 'react'

import {
  collapseStatusDataAttributeName,
  mapOfCollapseStatuses,
} from '@components/DocumentFormComponents/CollapseWrapper/constants'
import { CollapseStatus } from '@components/DocumentFormComponents/CollapseWrapper/types'
import { isFunction } from '@helpers/checkTypes'
import { useCollapse, UseCollapseParams } from '@hooks/new/collapse/useCollapse'
import { useBooleanState } from '@hooks/useBooleanState'

interface CollapseWrapperChildrenProps {
  collapseStatus: CollapseStatus
}

interface CollapseWrapperProps extends Omit<UseCollapseParams, 'children'> {
  children: ((props: CollapseWrapperChildrenProps) => ReactNode) | ReactNode
  unmountOnCollapse?: boolean
  className?: string
}

const CollapseWrapper: FC<CollapseWrapperProps> = ({
  unmountOnCollapse,
  children,
  defaultExpanded,
  isExpanded,
  onExpandStart,
  onCollapseEnd,
  className,
  ...collapseProps
}) => {
  const collapseRef = useRef<HTMLDivElement>(null)
  const [collapseStatus, setCollapseStatus] = useState<CollapseStatus>(
    defaultExpanded ?? isExpanded
      ? mapOfCollapseStatuses.expanded
      : mapOfCollapseStatuses.collapsed,
  )

  const {
    booleanState: isMountChildren,
    setBooleanStateToFalse: unmountChildren,
    setBooleanStateToTrue: mountChildren,
  } = useBooleanState(defaultExpanded ?? isExpanded ?? true)

  const { getCollapseProps } = useCollapse({
    ...collapseProps,
    isExpanded,
    defaultExpanded,
    onExpandStart: () => {
      setCollapseStatus(mapOfCollapseStatuses.expanding)
      onExpandStart?.()

      if (unmountOnCollapse) mountChildren()
    },
    onExpandEnd: () => {
      setCollapseStatus(mapOfCollapseStatuses.expanded)
    },
    onCollapseStart: () => {
      setCollapseStatus(mapOfCollapseStatuses.collapsing)
    },
    onCollapseEnd: () => {
      setCollapseStatus(mapOfCollapseStatuses.collapsed)
      onCollapseEnd?.()

      if (unmountOnCollapse) unmountChildren()
    },
  })

  const collapseElementProps = {
    ...getCollapseProps({ ref: collapseRef }),
    [collapseStatusDataAttributeName]: collapseStatus,
  }

  return (
    <div className={className} {...collapseElementProps}>
      {(!unmountOnCollapse || (unmountOnCollapse && isMountChildren)) &&
        (isFunction(children) ? children({ collapseStatus }) : children)}
    </div>
  )
}

export default CollapseWrapper
