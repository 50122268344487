import { FC } from 'react'

import First from './1'
import Second from './2'

interface ApplicationConclusionAdditionalAgreementFormFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const ApplicationConclusionAdditionalAgreementFormForms: ApplicationConclusionAdditionalAgreementFormFormsProps =
  () => null

ApplicationConclusionAdditionalAgreementFormForms.First = First
ApplicationConclusionAdditionalAgreementFormForms.Second = Second

export default ApplicationConclusionAdditionalAgreementFormForms
