import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router'

import { dictionariesNames, DictionaryNameFromParams } from '@components/ReferenceBooks/constants'
import { useReferenceBooksApi } from '@context/APIContext/hooks/useReferenceBooksApi'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { useReferenceBooksByDictionaryName } from '@hooks/new/swr/useReferenceBooksByDictionaryName'
import { useQueryManager } from '@hooks/useQueryManager'

interface ReferenceBooksHierarchyManagerProps {
  children: ReactNode
}

interface ReferenceBooksHierarchyManagerContextProps {
  state: {
    searchString: string | null
    searchThree: ReferenceBookItem[] | null
    threeIsLoading: boolean
    dictionaryName?: keyof typeof dictionariesNames
  }
  handlers: {
    handleSetupSearchThree: React.Dispatch<React.SetStateAction<ReferenceBookItem[] | null>> | null
    handleSetupThreeLoading: ((value: boolean) => void) | null
    handleGenerateSearchThree: VoidFunction | null
  }
}

const ReferenceBooksHierarchyContext = createContext<ReferenceBooksHierarchyManagerContextProps>({
  state: {
    searchThree: null,
    searchString: '',
    threeIsLoading: true,
  },
  handlers: {
    handleSetupThreeLoading: null,
    handleSetupSearchThree: null,
    handleGenerateSearchThree: null,
  },
})

const ReferenceBooksHierarchyManager: FC<ReferenceBooksHierarchyManagerProps> = ({ children }) => {
  const { getReferenceBooks } = useReferenceBooksApi()
  const { queryUtils } = useQueryManager()

  const { dictionaryName } = useParams<DictionaryNameFromParams>()

  const searchString = queryUtils.getQuery('searchString')

  const [searchThree, setSearchThree] = useState<ReferenceBookItem[] | null>(null)

  const [threeIsLoading, setThreeIsLoading] = useState(true)

  const { referenceBooks } = useReferenceBooksByDictionaryName({
    key: !searchString?.length
      ? {
          dictionaryName,
          _key: 'referenceBooks',
        }
      : null,
  })

  const handleSetupSearchThree = (value: React.SetStateAction<ReferenceBookItem[] | null>) => {
    setSearchThree(value)
  }

  const handleSetupThreeLoading = (value: boolean) => {
    setThreeIsLoading(value)
  }

  const generateSearchThree = useCallback(async () => {
    if (!dictionaryName) return

    setThreeIsLoading(true)

    try {
      const readyThree = await getReferenceBooks({
        dictionaryName,
        searchString: searchString?.trim(),
      })

      const putSearchMarkOnThree = (three: ReferenceBookItem[]): ReferenceBookItem[] => {
        const addKeyToChildren = (node: ReferenceBookItem): ReferenceBookItem => {
          // Если у узла есть дочерние элементы, добавляем ключ и обновляем дочерние элементы
          if (node.children && node.children.length > 0) {
            // Добавляем ключ fromSearchThree и рекурсивно обновляем дочерние элементы
            return {
              ...node,
              fromSearchThree: true,
              children: node.children.map((child) => addKeyToChildren(child)),
            }
          }
          // Возвращаем узел без изменений, если у него нет дочерних элементов
          return node
        }

        return three.map((node) => addKeyToChildren(node))
      }

      if (readyThree?.length) {
        return setSearchThree(putSearchMarkOnThree(readyThree))
      }

      //При нулевом дереве ничего не показываем, когда дерево = null, работает fetcher
      setSearchThree(null)
    } catch {
      setSearchThree(null)
    } finally {
      setThreeIsLoading(false)
    }
  }, [dictionaryName, getReferenceBooks, searchString])

  useEffect(() => {
    if (referenceBooks) {
      setThreeIsLoading(false)
    }
  }, [referenceBooks])

  useEffect(() => {
    if (!searchString) {
      setSearchThree(null)
      setThreeIsLoading(false)

      return
    }

    (async () => {
      await generateSearchThree()
    })()
  }, [searchString])

  const providerValue = useMemo(
    () => ({
      state: {
        dictionaryName,
        searchThree,
        threeIsLoading,
        searchString,
      },
      handlers: {
        handleSetupSearchThree,
        handleSetupThreeLoading,
        handleGenerateSearchThree: generateSearchThree,
      },
    }),
    [dictionaryName, searchString, searchThree, threeIsLoading],
  )

  return (
    <ReferenceBooksHierarchyContext.Provider value={providerValue}>
      {children}
    </ReferenceBooksHierarchyContext.Provider>
  )
}

const useReferenceBooksHierarchyManager = () => {
  return useContext(ReferenceBooksHierarchyContext)
}

export { useReferenceBooksHierarchyManager }

export default ReferenceBooksHierarchyManager
