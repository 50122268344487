import { FC } from 'react'

import One from './1'
import Two from './2'
import Three from './3'
import Four from './4'
import Five from './5'
import Six from './6'


interface AAVolumeOfCapitalInvestmentsFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Three: typeof Three
  Four: typeof Four
  Five: typeof Five
  Six: typeof Six
}

const AAVolumeOfCapitalInvestmentsForms: AAVolumeOfCapitalInvestmentsFormsProps = () => null

AAVolumeOfCapitalInvestmentsForms.One = One
AAVolumeOfCapitalInvestmentsForms.Two = Two
AAVolumeOfCapitalInvestmentsForms.Three = Three
AAVolumeOfCapitalInvestmentsForms.Four = Four
AAVolumeOfCapitalInvestmentsForms.Five = Five
AAVolumeOfCapitalInvestmentsForms.Six = Six

export default AAVolumeOfCapitalInvestmentsForms
