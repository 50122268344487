import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useNoticeOneSideRejectSZPKManager } from '@components/Forms/NoticeOneSideRejectSZPKForm/Manager'
import { NoticeOneSideRejectSZPKFormValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/types'

import { mapOfNoticeOneSideRejectSZPKMenu } from '../const'
import NoticeOneSideRejectSZPKForms from '../Forms'

const mapOfNoticeOneSideRejectSZPKForms: FormItemProps = {
  '1': {
    node: <NoticeOneSideRejectSZPKForms.First />,
  },
  '2': {
    node: <NoticeOneSideRejectSZPKForms.Second />,
  },
  '3': {
    node: <NoticeOneSideRejectSZPKForms.Third />,
  },
} as const

const NoticeOneSideRejectSZPKForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<NoticeOneSideRejectSZPKFormValues>()

  const {
    state: { formIsLoading },
  } = useNoticeOneSideRejectSZPKManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNoticeOneSideRejectSZPKMenu}
      mapOfForms={mapOfNoticeOneSideRejectSZPKForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NoticeOneSideRejectSZPKForm)
