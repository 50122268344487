import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForSupplementalCostSharingAForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedContractDutyMembers = (() => {
    if (
      !isArray(objectFromPropertyState?.dfoContractDuty?.value?.contractDutyMembers?.value) ||
      !objectFromPropertyState?.dfoContractDuty?.value?.contractDutyMembers?.value?.length
    )
      return []

    return objectFromPropertyState.dfoContractDuty.value.contractDutyMembers.value.map(
      (contractDutyMember) => ({
        propertyId: contractDutyMember.propertyId,
        type: contractDutyMember.type,
        lastUpdateDt: contractDutyMember.lastUpdateDt,
        value: {
          contractDutyMemberName: {
            propertyId: contractDutyMember?.value?.contractDutyMemberName?.propertyId,
            lastUpdateDt: contractDutyMember?.value?.contractDutyMemberName?.lastUpdateDt,
            type: contractDutyMember?.value?.contractDutyMemberName?.type,
          },
          contractDutyMemberOgrn: {
            propertyId: contractDutyMember?.value?.contractDutyMemberOgrn?.propertyId,
            lastUpdateDt: contractDutyMember?.value?.contractDutyMemberOgrn?.lastUpdateDt,
            type: contractDutyMember?.value?.contractDutyMemberOgrn?.type,
          },
          contractDutyMemberInn: {
            propertyId: contractDutyMember?.value?.contractDutyMemberInn?.propertyId,
            lastUpdateDt: contractDutyMember?.value?.contractDutyMemberInn?.lastUpdateDt,
            type: contractDutyMember?.value?.contractDutyMemberInn?.type,
          },
          contractDutyMemberAddress: {
            propertyId: contractDutyMember?.value?.contractDutyMemberAddress?.propertyId,
            lastUpdateDt: contractDutyMember?.value?.contractDutyMemberAddress?.lastUpdateDt,
            type: contractDutyMember?.value?.contractDutyMemberAddress?.type,
          },
        },
      }),
    )
  })()

  const preparedContractDutyObjects = (() => {
    if (
      !isArray(objectFromPropertyState?.dfoContractDuty?.value?.contractDutyObjects?.value) ||
      !objectFromPropertyState?.dfoContractDuty?.value?.contractDutyObjects?.value?.length
    )
      return []

    return objectFromPropertyState?.dfoContractDuty?.value?.contractDutyObjects.value.map(
      (contractDutyObject) => {
        return {
          propertyId: contractDutyObject.propertyId,
          type: contractDutyObject.type,
          lastUpdateDt: contractDutyObject.lastUpdateDt,
          value: {
            objectName: {
              propertyId: contractDutyObject?.value?.contractDutyObjectName?.propertyId,
              type: contractDutyObject?.value?.contractDutyObjectName?.type,
              lastUpdateDt: contractDutyObject?.value?.contractDutyObjectName?.lastUpdateDt,
            },
            contractDutyObjectActivityType: {
              propertyId: contractDutyObject?.value?.contractDutyObjectActivityType?.propertyId,
              type: contractDutyObject?.value?.contractDutyObjectActivityType?.type,
              lastUpdateDt: contractDutyObject?.value?.contractDutyObjectActivityType?.lastUpdateDt,
            },
            contractDutyObjectActivityEndDate: {
              propertyId: contractDutyObject?.value?.contractDutyObjectActivityEndDate?.propertyId,
              type: contractDutyObject?.value?.contractDutyObjectActivityEndDate?.type,
              lastUpdateDt:
                contractDutyObject?.value?.contractDutyObjectActivityEndDate?.lastUpdateDt,
            },
            contractDutyObjectCost: {
              propertyId: contractDutyObject?.value?.contractDutyObjectCost?.propertyId,
              type: contractDutyObject?.value?.contractDutyObjectCost?.type,
              lastUpdateDt: contractDutyObject?.value?.contractDutyObjectCost?.lastUpdateDt,
            },
            contractDutyObjectInvestorCost: {
              propertyId: contractDutyObject?.value?.contractDutyObjectInvestorCost?.propertyId,
              type: contractDutyObject?.value?.contractDutyObjectInvestorCost?.type,
              lastUpdateDt: contractDutyObject?.value?.contractDutyObjectInvestorCost?.lastUpdateDt,
            },
          },
        }
      },
    )
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      contractDutyMembers: {
        propertyId: objectFromPropertyState?.dfoContractDuty.value.contractDutyMembers?.propertyId,
        type: objectFromPropertyState?.dfoContractDuty.value.contractDutyMembers?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoContractDuty.value.contractDutyMembers?.lastUpdateDt,
        value: preparedContractDutyMembers,
      },
    },
    '6': {
      contractDutyTerms: {
        propertyId: objectFromPropertyState?.dfoContractDuty?.value?.contractDutyTerms?.propertyId,
        type: objectFromPropertyState?.dfoContractDuty?.value?.contractDutyTerms?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoContractDuty?.value?.contractDutyTerms?.lastUpdateDt,
      },
      contractDutyNumber: {
        propertyId: objectFromPropertyState?.dfoContractDuty?.value?.contractDutyNumber?.propertyId,
        type: objectFromPropertyState?.dfoContractDuty?.value?.contractDutyNumber?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoContractDuty?.value?.contractDutyNumber?.lastUpdateDt,
      },
      contractDutyDate: {
        propertyId: objectFromPropertyState?.dfoContractDuty?.value?.contractDutyDate?.propertyId,
        type: objectFromPropertyState?.dfoContractDuty?.value?.contractDutyDate?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoContractDuty?.value?.contractDutyDate?.lastUpdateDt,
      },
      objectsInfrastructureInvestorCost: {
        propertyId: objectFromPropertyState?.objectsInfrastructureInvestorCost?.propertyId,
        type: objectFromPropertyState?.objectsInfrastructureInvestorCost?.type,
        lastUpdateDt: objectFromPropertyState?.objectsInfrastructureInvestorCost?.lastUpdateDt,
      },
    },
    '7': {
      contractDutyObjects: {
        propertyId:
          objectFromPropertyState?.dfoContractDuty?.value?.contractDutyObjects?.propertyId,
        type: objectFromPropertyState?.dfoContractDuty?.value?.contractDutyObjects?.type,
        lastUpdateDt:
          objectFromPropertyState?.dfoContractDuty?.value?.contractDutyObjects?.lastUpdateDt,
        value: preparedContractDutyObjects,
      },
    },
  }
}

export { generatePropertiesObjectForSupplementalCostSharingAForm }
