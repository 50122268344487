import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { infrFacilitiesCostsExpectedReimbursedBlockValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import { fourthSectionInfrFacilitiesCostsExpectedReimbursedValidationMap } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Forms/4/validation'
import { useInfrFacilitiesCostsExpectedReimbursedManager } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Manager'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import ControlledMultipleInput from '@components/NewDesign/MultipleInput/ControlledMultipleInput'
import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

const { transformRHFPathInProperties } = DocumentFormHelpers

const Fourth = () => {
  const formInstance = useFormContext<InfrFacilitiesCostsExpectedReimbursedFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      getPropertiesProps,
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithValue,
      handleRemoveCustomValue,
    },
    preparedProps: { subscribableControl },
  } = useInfrFacilitiesCostsExpectedReimbursedManager()

  const {
    getSubscribableControlProps,
    getSingleSelectProps,
    getMultipleInputProps,
    getInputProps,
    getMaskInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleRemoveEmailOnChipClick = (
    _payload: MultipleInputValue[],
    valueToRemove: MultipleInputValue,
  ) => {
    const propertyInvestorHeaderEmail = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(
        infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerEmail,
      ),
    )

    if (!propertyInvestorHeaderEmail) return

    const propertyParentToRemove = {
      id: propertyInvestorHeaderEmail.propertyId,
      lastUpdateDt: propertyInvestorHeaderEmail.lastUpdateDt,
    }

    handleRemoveCustomValue?.(propertyParentToRemove, valueToRemove.value)
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerName,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.headerName || '',
                        value: item.id,
                      }),
                      controllerProps: {
                        name: infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerName,
                        rules:
                          fourthSectionInfrFacilitiesCostsExpectedReimbursedValidationMap.headerName,
                      },
                      selectProps: {
                        inputProps: {
                          label: 'ФИО',
                          dataTestId: 'headerName', 
                        },
                        onChangeFormValue: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerName,
                              ),
                            0,
                          ),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerPhone,
              })}
            >
              <ControlledMaskInput
                {...getMaskInputProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerPhone,
                  inputProps: {
                    mask: '+7 (999) 999-99-99',
                    dataTestId: 'headerPhone',
                    label: 'Телефон',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerPhone,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerPhone,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerPosition,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerPosition,
                  inputProps: {
                    label: 'Должность',
                    dataTestId: 'headerPosition',
                    disabled: true,
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerPosition,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerPosition,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerEmail,
              })}
            >
              <ControlledMultipleInput
                {...getMultipleInputProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerEmail,
                  rules:
                    fourthSectionInfrFacilitiesCostsExpectedReimbursedValidationMap.headerEmail,

                  inputProps: {
                    label: 'Email',
                    dataTestId: 'headerEmail',
                    autoComplete: 'off',
                  },

                  onRemoveChip: handleRemoveEmailOnChipClick,
                  onAddChip: (_payload: MultipleInputValue[], newValue: MultipleInputValue) =>
                    setTimeout(
                      () =>
                        handleAddItemToListWithValue?.(
                          infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerEmail,
                          newValue.displayValue,
                        ),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Fourth
