import React from 'react'

import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { useCreateProjectModal } from '@components/NewDesignedModals/CreateProjectModal/manager'
import { useAuthContext } from '@context/AuthContext'
import { getAllPartsOfName } from '@helpers/name/getAllPartsOfName'
import BigLogoImg from '@images/BigLogo.png'
import { GetRightsToSZPK } from '@routes/Projects/shared/GetRightToSZPK'

import Layouts from '../../../layouts'

import styles from './CreateProjectPage.module.scss'

const CreateProjectPage = () => {
  const { userInfoState } = useAuthContext()
  const { handleOpenCreateProjectModal } = useCreateProjectModal()

  const partNameObject = getAllPartsOfName(userInfoState?.name)

  return (
    <Layouts.Main>
      <div className={styles.page}>
        <div className={styles.page__banner}>
          <div className={styles['page__banner-info']}>
            <Typography.Headline variant={'headlineH1'} color={'text-accent-success'}>
              Добро пожаловать, {partNameObject?.name}
            </Typography.Headline>
            <Typography.Body variant={'bodyMMedium'} className={styles['page__banner-subtitle']}>
              Всё готово для начала заключения соглашений о защите и поощрении капиталовложений
            </Typography.Body>
            <Button
              dataTestId='createFirstProject'
              className={styles['page__banner-create']}
              color={'green'}
              onClick={handleOpenCreateProjectModal}
            >
              Создать СЗПК
            </Button>
          </div>
          <img src={BigLogoImg} alt="logo" className={styles['page__banner-img']} />
        </div>
        <GetRightsToSZPK />
      </div>
    </Layouts.Main>
  )
}

export default CreateProjectPage
