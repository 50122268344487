import { isUndefined } from '@helpers/checkTypes'
import type {
  AttachmentErrorProps,
  IAttachmentFileItem,
  IAttachmentFolderItem,
  IDocumentItem,
  RejectedFile,
} from '@interfaces/documents'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { AxiosResponseHeaders } from 'axios'
import { parse as contentDisposition } from 'content-disposition-header'
import { saveAs } from 'file-saver'
import { nanoid } from 'nanoid'

interface HandleCreateFileItemProps {
  file?: File | null
  rejectedFile?: RejectedFile | null
  isLoading?: boolean
  error?: AttachmentErrorProps | null
  documentInfo?: IDocumentItem
  checked?: boolean
}

interface CreateFolderProps {
  isFold?: boolean
  folderId?: string
  updateDt?: string
  documents?: IAttachmentFileItem[]
  name?: string
}

export const splitFileName = (name: string): [string, string] => {
  const extensionIdx = name.lastIndexOf('.')
  const _name = name.substring(0, extensionIdx)
  const _extension = name.substring(extensionIdx + 1)

  return [_name, _extension]
}

export const formatBytes = (b: number, dm = 2) => {
  if (b == 0) return '0 b'

  const k = 1024
  const i = Math.floor(Math.log(b) / Math.log(k))
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']
  const calc = (b / Math.pow(k, i)).toFixed(dm)

  return `${parseFloat(calc)} ${sizes[i]}`
}

export const createFileItem = ({
  isLoading = false,
  error = null,
  file = null,
  rejectedFile = null,
  documentInfo,
  checked,
}: HandleCreateFileItemProps): IAttachmentFileItem => {
  const id = nanoid()

  return {
    isLoading,
    error,
    file,
    rejectedFile,
    id,
    documentInfo,
    ...(!isUndefined(checked) && {
      checked,
    }),
  }
}

export const parseDocument = (document: IDocumentItem, checked?: boolean): IAttachmentFileItem => {
  const { extension, name } = document

  const decodedName = decodeURI(`${name.replace('%', '')}.${extension.replace('%', '')}`)

  const blob = new Blob([], {
    type: 'octet/stream',
  })

  const file = new File([blob], decodedName, {
    lastModified: DayjsService.dayjsWithFormatToMSK(document.updateDatetime).valueOf(),
  })

  return createFileItem({
    file,
    documentInfo: document,
    checked,
  })
}

// Need to enable CORS to get all headers
export const downloadFile = (blob: Blob, headers: AxiosResponseHeaders) => {
  let fileNameToDownload = nanoid()

  if (headers['content-disposition']) {
    const {
      parameters: { filename },
    } = contentDisposition(headers['content-disposition'])

    fileNameToDownload = filename
  }

  const _filename = decodeURIComponent(fileNameToDownload)
  saveAs(blob, _filename)
}

export const formatFileName = (file: File, showBytes = true, fileSize: string | number = 15) => {
  const [fileName, extension] = splitFileName(file.name)
  const newFileName = `${fileName}.${extension}`
  const size = formatBytes(+fileSize)
  return showBytes ? `${newFileName} · ${size}` : `${newFileName}`
}

export const createFolderItem = ({
  isFold = false,
  name = 'Новая папка',
  documents = [],
  folderId,
  updateDt,
}: CreateFolderProps): IAttachmentFolderItem => {
  const id = nanoid()

  return {
    isFold,
    name,
    id,
    folderId,
    documents,
    updateDt,
  }
}

export const createMockFile = (fileName: string) => {
  return new File([''], `${fileName}.pdf`, {
    type: 'application/pdf',
    lastModified: DayjsService.dayjsWithFormatToMSK().millisecond(),
  })
}
