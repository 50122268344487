import { useEffect, useRef, useState } from 'react'

import outerHeight from '@helpers/outerHeight'

const useScrollable = <T extends HTMLElement>() => {
  const [isScrollable, setIsScrollable] = useState(false)
  const scrollableRef = useRef<T>(null)

  useEffect(() => {
    window.addEventListener('load', checkScrollable)
    window.addEventListener('resize', checkScrollable)
    return () => {
      window.removeEventListener('load', checkScrollable)
      window.removeEventListener('resize', checkScrollable)
    }
  }, [])

  const checkScrollable = () => {
    if (!scrollableRef?.current) return
    const height = outerHeight(scrollableRef.current)
    setIsScrollable(height >= window.innerHeight)
  }

  return { isScrollable, scrollableRef }
}

export default useScrollable
