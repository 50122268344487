import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const oneSectionValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['1'],
  ProjectSZPKFormValues
> = {
  rfHeaderName: {
    required: defaultRHFValidation.required,
  },
  rfHeaderNameGenitive: {
    required: defaultRHFValidation.required,
  },
  rfHeaderPosition: {
    required: defaultRHFValidation.required,
  },
  rfHeaderPositionGenitive: {
    required: defaultRHFValidation.required,
  },
  rfHeaderReasonGenitive: {
    required: defaultRHFValidation.required,
  },
}

export { oneSectionValidationMap }
