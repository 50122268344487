import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectsFormValues,
  IConstructionStage,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const eExploitationAAgreementOnCreationOtherEstateObjectsValidationMap: FormValuesValidationSection<
  keyof IConstructionStage['exploitation'],
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  objectCommissioningEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { eExploitationAAgreementOnCreationOtherEstateObjectsValidationMap }
