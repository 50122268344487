import { createElement, forwardRef, useRef } from 'react'

import Icon, { IconProps } from '@components/Icon'
import { IconButtonProps } from '@components/IconButton'
import Loader from '@components/Loader'
import { mergeRefs } from '@helpers/ref/mergeRefs'
import { useFocus } from '@hooks/useFocus'
import cn from 'classnames'

import Typography from '../Typography'

import accentColor from './accent.module.scss'
import styles from './Button.module.scss'
import defaultColor from './default.module.scss'
import greenColor from './green.module.scss'
import negativeColor from './negative.module.scss'
import { ButtonProps, LoaderPlacement } from './types'

const colorStyles = {
  default: defaultColor,
  negative: negativeColor,
  green: greenColor,
  accent: accentColor,
} as const

const getIconSizeByButtonSize = (size: IconButtonProps['size']) => {
  switch (size) {
    case 'xl':
    case 'l':
    case 'm':
      return 'm'

    case 's':
    case 'xs':
    case '2xs':
      return 's'

    default:
      return 'm'
  }
}

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'l',
      color = 'default',
      fontWeight = 'semibold',
      variant = 'buttonMMedium',
      view = 'filled',
      geometry = 'square',
      type = 'button',
      icon,
      rounded,
      className,
      textClassName,
      fixWidth,
      children,
      leadingIcon,
      trailingIcon,
      href = '',
      target = '',
      loaderProps,
      dataTestId,
      disabled,
      ...props
    },
    ref,
  ) => {
    const focusRef = useRef<HTMLButtonElement>(null)
    const [focused] = useFocus(focusRef, 'keyboard')

    const iconComponent = (props: IconProps) => {
      const { src, noCurrentColorSvgFill = true } = props

      return (
        <Icon
          size={getIconSizeByButtonSize(size)}
          {...props}
          noCurrentColorSvgFill={noCurrentColorSvgFill}
          src={src}
          className={cn(styles.leading__icon, props.className)}
        />
      )
    }

    const classNameToElements = cn(
      styles.button,
      styles[size],
      styles[view],
      styles[geometry],
      colorStyles[color][view],
      {
        [styles['button--rounded']]: rounded,
        [styles['button--fix-width']]: fixWidth,
        [styles['button--icon-l']]: !icon && leadingIcon && !trailingIcon,
        [styles['button--icon-t']]: !icon && trailingIcon && !leadingIcon,
        [styles['button--icon-lt']]: !icon && trailingIcon && leadingIcon,
        [styles['button--icon']]: icon,
        [styles[`button--${fontWeight}`]]: fontWeight,
        [styles['button--blured']]: !focused,
      },
      className,
    )

    const buttonProps = {
      className: classNameToElements,
      ...(href && {
        href,
        target,
        rel: 'noopener noreferrer',
      }),
      type,
      disabled,
      ...props,
    }

    const getIconToButton = (
      iconToButton?: IconProps,
      hasCurrentPlacement?: boolean,
      loading?: boolean,
    ) =>
      hasCurrentPlacement && loading ? (
        <Loader {...loaderProps} />
      ) : (
        !icon && iconToButton && iconComponent(iconToButton)
      )

    const buttonTemplate = (
      <>
        {getIconToButton(
          leadingIcon,
          loaderProps?.placement === LoaderPlacement.leading,
          loaderProps?.loading,
        )}
        {!icon && children && (
          <Typography.Button
            variant={variant}
            className={cn(styles.text, textClassName, {
              [styles.strethText]: !(leadingIcon || trailingIcon),
            })}
          >
            {children}
          </Typography.Button>
        )}
        {icon}
        {getIconToButton(
          trailingIcon,
          loaderProps?.placement === LoaderPlacement.trailing,
          loaderProps?.loading,
        )}
      </>
    )

    return createElement(
      href ? 'a' : 'button',
      { ...buttonProps, 'data-testid': dataTestId, ref: mergeRefs([focusRef, ref]) },
      buttonTemplate,
    )
  },
)

export default Button
