import { FC, memo, useCallback, useMemo, useState } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import {
  IPutAuthorizedOrganizationsBodyWithNewInn,
  useAuthorizedOrganizationsApi,
} from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'

import styles from './ChangeINNModal.module.scss'

type TOpenChangeINNModal = IPutAuthorizedOrganizationsBodyWithNewInn & {
  text: string
  headerText: string
  handleChangeSuccess: VoidFunction
}

type TChangeINNModal = Required<PopupProps> & TOpenChangeINNModal

const useChangeINNModal = () => {
  const popupManager = usePopupManager()

  const handleOpenChangeINNModal = ({ ...props }: TOpenChangeINNModal) => {
    popupManager.open(ChangeINNModal, { ...props, onClose: () => null })
  }

  return { handleOpenChangeINNModal }
}

const ChangeINNModal: FC<TChangeINNModal> = (props) => {
  const { headerText, text, isOpen, onClose, handleChangeSuccess, ...authorizedOrganizationProps } =
    props

  const { updateINNAuthorizedOrganization } = useAuthorizedOrganizationsApi()

  const [isLoading, setIsLoading] = useState(false)

  const handleChangeINN = useCallback(async () => {
    setIsLoading(true)

    try {
      await updateINNAuthorizedOrganization(authorizedOrganizationProps)

      handleChangeSuccess()
    } finally {
      setIsLoading(false)
      onClose()
    }
  }, [authorizedOrganizationProps, updateINNAuthorizedOrganization, handleChangeSuccess, onClose])

  const doneActions = useMemo(() => {
    return [
      {
        dataTestId: 'ChangeINNModal-cancel-button',
        children: 'Отмена',
        variant: 'buttonMMedium',
        view: 'gray',
        geometry: 'square',
        size: 'l',
        onClick: onClose,
        className: styles.cancel,
      },
      {
        dataTestId: 'ChangeINNModal-changeINN-button',
        children: 'Изменить ИНН',
        color: 'negative',
        view: 'filled',
        size: 'l',
        onClick: handleChangeINN,
        className: styles.done,
        loaderProps: {
          loading: isLoading,
          variant: 'lite',
          placement: 'trailing',
        },
      },
    ] as ButtonProps[]
  }, [onClose, handleChangeINN, isLoading])

  return (
    <Modal.Action
      disableOnClose={isLoading}
      hideCloseIcon={isLoading}
      actions={doneActions}
      isOpen={isOpen}
      title={headerText}
      dataTestId="ChangeINNModal-modal"
      closeButtonDataTestId="ChangeINNModal-modal-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      actionModalContentClassName={styles.modalActions}
      onClose={onClose}
    >
      <Typography.Body variant="bodyMRegular" className={styles.body}>
        {text}
      </Typography.Body>
    </Modal.Action>
  )
}

export { useChangeINNModal }
export default memo(ChangeINNModal)
