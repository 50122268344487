import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAChangingVolumeReimbursementFormValues,
  IOldAgreementLinkChange,
} from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const sixSectionValidationMap: FormValuesValidationSection<
  keyof IOldAgreementLinkChange,
  AAChangingVolumeReimbursementFormValues
> = {
  oldAgreementLink: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  oldAgreementLinkNewRedaction: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { sixSectionValidationMap }
