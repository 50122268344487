import { ComponentType } from 'react'
import { Navigate } from 'react-router'

import useRedirectByRoles, { Rule } from '@hooks/useRedirectByRoles'

function withRolesProtect<T = any>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const { redirectByRoles } = useRedirectByRoles()

    const redirectCallback = (rule: Rule) => {
      return <Navigate to={rule.to} />
    }

    return redirectByRoles(redirectCallback) || <WrappedComponent {...props} />
  }
}

export default withRolesProtect
