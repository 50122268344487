import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'
import { defaultRHFValidation } from '@constants/validations'

const firstSectionValidationMap: FormValuesValidationSection<
  keyof AdditionalAgreementOnTerminationFfSZPKFormValues['1'],
  AdditionalAgreementOnTerminationFfSZPKFormValues
> = {
  rfHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { firstSectionValidationMap }
