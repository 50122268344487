import { useCallback } from 'react'
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import type { IViolation } from '@components/DocumentFormComponents/types'

const { parseErrorsFromViolations } = DocumentFormHelpers

const useParseFormError = <T extends FieldValues>(formInstance: UseFormReturn<T>) => {
  const { setError } = formInstance

  const applyErrorsFromViolations = useCallback(
    (initialErrorsFromViolations: IViolation[]) => {
      const errorsFromHelper = parseErrorsFromViolations(initialErrorsFromViolations)

      errorsFromHelper.map((item) => {
        const itemField = item.field as Path<T>

        setError(itemField, { message: item.message })
      })
    },
    [setError],
  )

  return {
    applyErrorsFromViolations,
  }
}

export { useParseFormError }
