import { compact } from '@helpers/array/compact'
import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'

const COMPONENT_FIELDS_SEPARATOR = '_'

const generateTestComponentId = ({
  componentName,
  moduleName,
  componentType,
  id,
}: ComponentInfo) => {
  const componentFields = [moduleName, componentName, componentType, id]

  return compact(componentFields).join(COMPONENT_FIELDS_SEPARATOR)
}

export { generateTestComponentId }
