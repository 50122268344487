import { useCallback, useReducer, useRef } from 'react'

import { HandleSaveSignedDocumentProps } from '@components/NewDesignedModals/SignModal/types'
import {
  initialSignDocumentsState,
  signDocumentsActionCreators,
  signDocumentsReducer,
} from '@components/NewDesignedModals/StatusSignModal/reducer'
import { HttpStatusCode } from '@constants/statusCodes'
import { useAPIContext } from '@context/APIContext'
import { DocumentForSign, FormDocumentForSign } from '@context/APIContext/hooks/useDocumentsApi'
import { getUserThumbprint } from '@context/AuthContext/workers/clientWorkers'
import { isAxiosError, isJsError, isUndefined } from '@helpers/checkTypes'
import type { TDocumentActionType } from '@services/DocumentActions/DocumentActions.entity'
import LoggerServiceHelpers from '@services/LoggerService/LoggerService.helpers'
import type { AxiosError } from 'axios'
import { createDetachedSignature } from 'crypto-pro'

interface UseSignDocumentsProps {
  actionType: TDocumentActionType
  actionId: string
  onSuccessSignFiles: VoidFunction
  projectId?: string
  dfoId?: string
  onErrorSignFiles?: (error: AxiosError<unknown>) => void
}

const useSignDocuments = ({
  actionType,
  actionId,
  onSuccessSignFiles,
  projectId,
  dfoId,
  onErrorSignFiles,
}: UseSignDocumentsProps) => {
  const {
    projectsApi: { removeSigningBlock },
    documentsApi: {
      getUnsignedDocuments,
      getDocumentHashToSign,
      saveDetachedSignature,
      saveSignedDocument,
    },
    webFormApi: { getFormDocumentHashToSign },
  } = useAPIContext()

  const [state, dispatch] = useReducer(signDocumentsReducer, initialSignDocumentsState)
  const failedSignDocumentsCounter = useRef(0)
  const allDocumentsToSignCounter = useRef(0)

  const handleNativeJsError = useCallback((error: unknown) => {
    if (!isJsError(error)) throw error

    dispatch(signDocumentsActionCreators.setDefaultError(error))
  }, [])

  const handleSignDocumentsError = useCallback(
    (error: unknown) => {
      if (!isAxiosError(error)) {
        handleNativeJsError(error)

        throw error
      }

      dispatch(signDocumentsActionCreators.setError(error))
    },
    [handleNativeJsError],
  )

  const getDocumentsToSign = useCallback(async () => {
    if (!actionType) return

    try {
      return await getUnsignedDocuments?.({
        projectId: projectId || '',
        actionId,
      })
    } catch (error) {
      if (!isAxiosError(error)) throw error

      dispatch(signDocumentsActionCreators.setError(error))

      onErrorSignFiles?.(error)
      throw error
    }
  }, [actionType, getUnsignedDocuments, projectId, actionId, onErrorSignFiles])

  const handleCreateDetachedSignature = useCallback(async (thumbprint: string, hash: string) => {
    try {
      const response = await createDetachedSignature(thumbprint, hash)

      const signFileName = 'Подпись.sig'
      const blob = new Blob([response])

      return new File([blob], signFileName)
    } catch (error) {
      throw error
    }
  }, [])

  const handleSaveSignedDocument = useCallback(
    async ({ dfoId, documentSetId, documentId, hash }: HandleSaveSignedDocumentProps) => {
      try {
        const thumbprint = getUserThumbprint()

        const sigFile = await handleCreateDetachedSignature(thumbprint, hash)

        const { documentId: signedDocumentId } = await saveDetachedSignature({
          documentSetId,
          file: sigFile,
          projectId: projectId || '',
        })

        await saveSignedDocument({
          dfoId,
          documentId,
          documentSetId,
          projectId: projectId || '',
          signatureId: signedDocumentId,
        })
      } catch (error) {
        throw error
      }
    },
    [handleCreateDetachedSignature, projectId, saveDetachedSignature, saveSignedDocument],
  )

  const signAllFormDocuments = useCallback(
    async (formDocumentToSign?: FormDocumentForSign[]) => {
      if (!formDocumentToSign) return

      const allSettledResult = await Promise.allSettled(
        formDocumentToSign.map(async ({ dfoId, documentSetId, formId }) => {
          try {
            const { data: hashToSign, status } = await getFormDocumentHashToSign({
              formId,
              actionId,
            })

            if (status === HttpStatusCode.NO_CONTENT) {
              dispatch(signDocumentsActionCreators.incrementSignedDocumentsCounter())

              return
            }

            const { hash, id: documentId } = hashToSign || {}

            if (!hash || !documentId) return

            await handleSaveSignedDocument?.({
              dfoId,
              documentSetId,
              hash,
              documentId,
            })

            dispatch(signDocumentsActionCreators.incrementSignedDocumentsCounter())
          } catch (error) {
            throw error
          }
        }),
      )

      const rejectedSignedFormDocuments = allSettledResult.filter(
        ({ status }) => status === 'rejected',
      ) as unknown as PromiseRejectedResult[]

      failedSignDocumentsCounter.current += rejectedSignedFormDocuments.length

      const error = rejectedSignedFormDocuments.find(({ reason }) => reason)?.reason

      if (!isUndefined(error)) throw error
    },
    [actionId, getFormDocumentHashToSign, handleSaveSignedDocument],
  )

  const signAllDocuments = useCallback(
    async (documentsToSign?: DocumentForSign[]) => {
      if (!documentsToSign) return

      const allSettledResult = await Promise.allSettled(
        documentsToSign.map(async ({ dfoId, documentId, documentSetId, versionId }) => {
          try {
            const hashToSign = await getDocumentHashToSign({
              projectId: projectId || '',
              documentId,
              documentSetId,
              versionId,
            })

            const { hash } = hashToSign || {}

            if (!hash) return

            await handleSaveSignedDocument?.({
              dfoId,
              documentSetId,
              hash,
              documentId,
            })

            dispatch(signDocumentsActionCreators.incrementSignedDocumentsCounter())
          } catch (error) {
            throw error
          }
        }),
      )

      const rejectedSignedDocs = allSettledResult.filter(
        ({ status }) => status === 'rejected',
      ) as unknown as PromiseRejectedResult[]

      failedSignDocumentsCounter.current = rejectedSignedDocs.length

      const error = rejectedSignedDocs.find(({ reason }) => reason)?.reason

      if (!isUndefined(error)) throw error
    },
    [getDocumentHashToSign, handleSaveSignedDocument, projectId],
  )

  const startSignDocuments = useCallback(async () => {
    try {
      const documentsToSign = await getDocumentsToSign()

      const { documents, forms } = documentsToSign || {}

      allDocumentsToSignCounter.current = (forms?.length || 0) + (documents?.length || 0)
      dispatch(signDocumentsActionCreators.setSignDocumentsProgress(true))

      await signAllFormDocuments(forms)
      await signAllDocuments(documents)

      onSuccessSignFiles()
    } catch (error) {
      LoggerServiceHelpers.handleMultipleLogError({
        componentInfo: {
          componentName: 'useSignDocuments',
          componentType: 'startSignDocuments',
        },
      })(error)

      handleSignDocumentsError(error)

      throw error
    } finally {
      dispatch(signDocumentsActionCreators.setLoading(false))
      dispatch(signDocumentsActionCreators.setSignDocumentsProgress(false))
      dispatch(signDocumentsActionCreators.setDone(true))
    }
  }, [
    getDocumentsToSign,
    handleSignDocumentsError,
    onSuccessSignFiles,
    signAllDocuments,
    signAllFormDocuments,
  ])

  const handleSignDocuments = useCallback(async () => {
    if (!projectId) return

    dispatch(signDocumentsActionCreators.startSignDocuments())
    failedSignDocumentsCounter.current = 0
    allDocumentsToSignCounter.current = 0

    await startSignDocuments()
  }, [projectId, startSignDocuments])

  const handleRetrySign = useCallback(async () => {
    try {
      await removeSigningBlock(projectId || '')

      await handleSignDocuments()
    } catch (error) {
      throw error
    }
  }, [handleSignDocuments, projectId, removeSigningBlock])

  return {
    isLoadingSignDocuments: state.isLoading,
    isDoneSignDocuments: state.isDone,
    signDocumentsInProgress: state.signDocumentsInProgress,
    signDocumentsErrorMessage: state.errorMessage,
    signedDocumentsCounter: state.signedDocumentsCounter,
    failedSignDocumentsCounter: failedSignDocumentsCounter.current,
    allDocumentsToSignCounter: allDocumentsToSignCounter.current,
    handleSignDocuments,
    handleRetrySign,
  }
}

export default useSignDocuments
