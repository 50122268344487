import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import cloneDeep from 'clone-deep'

const informationOfExecutionAgreementEventsFormMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Мероприятия этапов соглашения',
    parentBaseId: null,
    isCollapsed: true,
    hasError: false,
    children: [],
  },
}

const getInformationOfExecutionAgreementEventsMenuHashMapMenuByStatus = () => {
  const initialInformationOfExecutionAgreementEventsMenuHashMap = cloneDeep(
    informationOfExecutionAgreementEventsFormMenuHashMap,
  )

  const informationOfExecutionAgreementEventsMenuState = [
    informationOfExecutionAgreementEventsFormMenuHashMap['1'],
  ]

  return {
    initialInformationOfExecutionAgreementEventsMenuHashMap,
    initialInformationOfExecutionAgreementEventsMenuState:
      informationOfExecutionAgreementEventsMenuState,
  }
}

export { getInformationOfExecutionAgreementEventsMenuHashMapMenuByStatus }
