type EType = ProgressEvent<FileReader>

export interface ReadBlob {
    blob: Blob
}

export const readBlob = ({ blob }: ReadBlob): Promise<EType> => {
    const reader = new FileReader()

    return new Promise((res, rej) => {
        reader.onload = res
        reader.onerror = rej

        reader.readAsText(blob)
    })
}