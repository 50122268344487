import React, { ReactNode } from 'react'

import Typography from '@components/NewDesign/Typography'
import { isString, isUndefined } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import cn from 'classnames'

import styles from './Switch.module.scss'

type SwitchSizes = 'M' | 'S' // "M" as default
type SwitchLabelPositions = 'left' | 'right' // "left" as default

interface SwitchState {
  isActive: boolean
  disabled?: boolean
}

interface SwitchEvents {
  onChange?: (isActive: boolean) => void
  onReset?: () => void
}

export interface SwitchParams extends Pick<SwitchState, 'disabled'>, SwitchEvents {
  size?: SwitchSizes
  label?:
    | string
    | {
        text: string
        position: SwitchLabelPositions
      }
  defaultState?: boolean
  value?: boolean
  leftAddons?: ReactNode

  wrapperClassName?: string
  labelClassName?: string
  className?: string
  dataTestId?: string
  id?: string
}

export const Switch: React.FC<SwitchParams> = ({
  label,
  disabled,
  size = 'M',
  defaultState,
  value,
  leftAddons,

  onChange,

  wrapperClassName,
  labelClassName,
  className,
  dataTestId,
  id,
}) => {
  const { booleanState: isActive, reverseBooleanState: toggleSwitch } =
    useBooleanState(defaultState)

  const isUncontrolledState = isUndefined(value)

  const switchValue = isUncontrolledState ? isActive : value

  const isLabelSimple = isString(label)

  const changeHandler = () => {
    if (isUncontrolledState) {
      toggleSwitch()
    }

    const updatedValue = isUncontrolledState ? !isActive : !value

    onChange?.(updatedValue)
  }

  return (
    <div id={id} className={cn(styles.Wrapper, wrapperClassName)}>
      {leftAddons}
      <div
        className={cn(styles.Wrapper__content, {
          [styles['Wrapper__content--rightLabel']]: !isLabelSimple && label?.position === 'right',
        })}
      >
        {label && (
          <Typography.Body variant={'bodyMMedium'} className={cn(styles.Label, labelClassName)}>
            {isLabelSimple ? label : label.text}
          </Typography.Body>
        )}
        <div
          role="none"
          data-testid={dataTestId}
          className={cn(styles.Switcher, styles[`Switcher_${size}`], {
            [styles.Switcher_active]: switchValue,
            [styles[switchValue ? 'Switcher_active_disabled' : 'Switcher_disabled']]: disabled,
          })}
          onClick={changeHandler}
        >
          <div
            className={cn(styles.Switch, className, styles[`Switch_${size}`], {
              [styles[`Switch_${size}_active`]]: switchValue,
            })}
          />
        </div>
      </div>
    </div>
  )
}
