import { DependencyList, EffectCallback, useEffect } from 'react'

import useIsFirstRender from './useIsFirstRender'

const useUpdateEffect = (callback: EffectCallback, deps?: DependencyList) => {
  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (!isFirstRender) {
      return callback()
    }
  }, deps)
}

export default useUpdateEffect
