import React, { FC, memo, useCallback, useEffect, useReducer } from 'react'

import Error500Fallback from '@components/DataFallback/500Error'
import Loader from '@components/Loader'
import DataView from '@components/Projects/[id]/ParticipantsOfStatement/DataView'
import { DfoAttributeTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { isAxiosError, isString } from '@helpers/checkTypes'
import { useDfoAttributes } from '@hooks/new/swr/useDfoAttributes'
import { ClaimSubsidyBudgetType } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'
import { unstable_serialize, useSWRConfig } from 'swr'

import { subsidyMemberReducerActionMap } from './constants'
import {
  initialSubsidyMembersState,
  subsidyMembersActionCreators,
  subsidyMembersReducer,
} from './reducer'
import styles from './SubsidyMember.module.scss'

interface SubsidyMembersAdditionProps {
  projectId: string
  dfoId: string
  subsidyBudgetType?: ClaimSubsidyBudgetType
}

const SubsidyMembersAddition: FC<SubsidyMembersAdditionProps> = ({
  projectId,
  dfoId,
  subsidyBudgetType,
}) => {
  const [state, dispatch] = useReducer(subsidyMembersReducer, initialSubsidyMembersState)

  const {
    organizationsApi: { getOrganizationById },
  } = useAPIContext()

  const { cache } = useSWRConfig()

  const {
    dfoAttributes,
    mapOfAttributes,
    isLoadingDfoAttributes,
    error: dfoAttributesError,
  } = useDfoAttributes({
    key: {
      projectId,
      dfoId,
      _key: 'dfoAttributes',
    },
    config: {
      isPaused: () => !projectId || !dfoId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'SubsidyMembersAddition',
          moduleName: 'DocumentsView',
        },
      }),
    },
  })

  const handleSubsidyMemberError = (error: AxiosError<unknown> | string) => {
    dispatch(subsidyMembersActionCreators.setError(error))
  }

  const getSubsidyMemberById = useCallback(
    async (organizationId: string) => {
      dispatch(subsidyMembersActionCreators.setLoading(true))

      try {
        const key = {
          organizationId,
          _key: 'organizationMembers',
        }

        const organization = await getOrganizationById(organizationId)

        cache.set(unstable_serialize(key), organization)

        dispatch({
          type: subsidyMemberReducerActionMap.SETUP_ORGANIZATION,
          payload: organization,
        })
      } catch (error) {
        if (!isAxiosError(error)) throw error

        handleSubsidyMemberError(error)
      } finally {
        dispatch(subsidyMembersActionCreators.setLoading(false))
      }
    },
    [getOrganizationById],
  )

  const getSubsidyMember = useCallback(async () => {
    if (!dfoAttributes || !mapOfAttributes) return

    const considerAttributeId = mapOfAttributes[DfoAttributeTypes.consider]

    if (!isString(considerAttributeId))
      return handleSubsidyMemberError('Не удалось получить согласующий орган')

    await getSubsidyMemberById(considerAttributeId)
  }, [dfoAttributes, getSubsidyMemberById, mapOfAttributes])

  useEffect(() => {
    (async () => {
      await getSubsidyMember()
    })()
  }, [getSubsidyMember])

  const currentError = dfoAttributesError || state.error

  const currentRenderSuptitle =
    subsidyBudgetType === 'REGIONAL' ? 'Согласующий РОИВ' : 'Согласующий ОМСУ '

  return (
    <Error500Fallback title={'Не удалось получить согласующий орган'} error={!!currentError}>
      <div className={styles.subsidyMember}>
        <Loader
          loading={isLoadingDfoAttributes || state.isLoading}
          dataTestId="SubsidyMembersAddition-loader"
          variant={'lite'}
        >
          <DataView suptitle={currentRenderSuptitle}>{state.organization?.name}</DataView>
        </Loader>
      </div>
    </Error500Fallback>
  )
}

export default memo(SubsidyMembersAddition)
