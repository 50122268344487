import React, { memo, SyntheticEvent } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'

import Collapse from '@components/Collapse'
import { useFlatMenuManager as useCreateStatementOldMenuManager } from '@components/DocumentFormComponents/Menu/Manager'
import { blockValues } from '@components/Forms/CreateStatementOld/const'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { IStatementTax, StatementOldFormValues } from '@components/Forms/CreateStatementOld/types'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Typography from '@components/NewDesign/Typography'
import { objOfDateFormats } from '@constants/dateFormats'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import dayjs, { Dayjs } from 'dayjs'

import styles from './Taxes.module.scss'

const Taxes = () => {
  const {
    state: { formInstance },
  } = useCreateStatementManager()
  const { handleChangeCurrentAnchorId } = useCreateStatementOldMenuManager()

  const { fields: plannedTaxes, replace: replaceTaxes } = useFieldArray<
    StatementOldFormValues,
    'participantsAndInvestments.taxes',
    'id'
  >({
    control: formInstance?.control,
    name: 'participantsAndInvestments.taxes',
  })

  const startProjectDate = useWatch({
    name: blockValues.stages.startDate,
    control: formInstance?.control
  })
  const szpkEndDate = useWatch({
    name: blockValues.stages.szpkEndDate,
    control: formInstance?.control
  })

  const formattedStartDate = dayjs(startProjectDate, objOfDateFormats.defaultFormat)
  const formattedEndDate = dayjs(szpkEndDate, objOfDateFormats.defaultFormat)

  const reverseVersionTaxesDate = useWatch({
    name: blockValues.stages.projectEndDate,
    control: formInstance?.control
  })

  const conditionToCalculateTaxes =
    formattedStartDate.isValid() &&
    formattedEndDate.isValid() &&
    formattedEndDate >= formattedStartDate

  const conditionToRenderTaxes =
    conditionToCalculateTaxes ||
    (startProjectDate && reverseVersionTaxesDate && plannedTaxes.length)

  useUpdateEffect(() => {
    if (conditionToCalculateTaxes) {
      const startYear = formattedStartDate.year()
      const endYear = formattedEndDate.year()

      const taxesToReplace: IStatementTax[] = []

      const currentTaxesFromFormInstance =
        formInstance?.getValues().participantsAndInvestments.taxes

      if (!currentTaxesFromFormInstance?.length) {
        for (let currentYear = startYear; currentYear <= endYear; currentYear++) {
          taxesToReplace.push({
            plannedTax: '',
            taxYear: dayjs({ year: currentYear }).startOf('year'),
          })
        }
      } else {
        const mapOfExistedYears = currentTaxesFromFormInstance?.reduce(
          (previousValue, nextValue) => {
            return {
              ...previousValue,
              [String(dayjs(nextValue.taxYear as Dayjs).year())]: nextValue.plannedTax,
            }
          },
          {},
        )

        for (let currentYear = startYear; currentYear <= endYear; currentYear++) {
          taxesToReplace.push({
            plannedTax: mapOfExistedYears[String(currentYear)] ?? '',
            taxYear: dayjs({ year: currentYear }).startOf('year'),
          })
        }
      }

      replaceTaxes(taxesToReplace)
    }
  }, [startProjectDate, szpkEndDate, replaceTaxes])

  if (!formInstance) return null

  const heightOfTaxes = conditionToRenderTaxes ? 'auto' : 0

  const goToTaxesAnchorHandler = (e: SyntheticEvent) => {
    e.preventDefault()
    handleChangeCurrentAnchorId?.('stages')
  }

  return (
    <div className={styles.taxes}>
      <div className={styles.taxes__description}>
        {conditionToRenderTaxes ? (
          <Typography
            className={styles['taxes__description-item']}
            variant={'bodyMRegular'}
            as={'span'}
          >
            Укажите{' '}
            <Typography
              className={styles['taxes__description-item']}
              variant={'bodyMSemibold'}
              as={'span'}
            >
              прогнозируемый объем налогов и иных обязательных платежей{' '}
            </Typography>
            в связи с реализацией проекта из расчета{' '}
            <Typography
              className={styles['taxes__description-item']}
              variant={'bodyMSemibold'}
              as={'span'}
            >
              на каждый год реализации проекта{' '}
            </Typography>
            в период действия соглашения
          </Typography>
        ) : (
          <>
            <Typography.Body variant={'bodyMRegular'}>
              Укажите планируемую дату окончания соглашения и сроки реализации проекта в разделе
              <button className={styles.BrandText} onClick={goToTaxesAnchorHandler}>
                &nbsp;«Этапы реализации проекта»&nbsp;
              </button>
              для того, чтобы добавить объём налогов и иных обязательных платежей из расчёта на
              каждый год
            </Typography.Body>
          </>
        )}
      </div>
      <Collapse
        dataTestId="Taxes-collapse"
        className={styles.taxes__collapseContent}
        height={heightOfTaxes}
      >
        <div className={styles.taxes__items}>
          {plannedTaxes.map((item, index) => {
            return (
              <ControlledAmountInput
                key={item.id}
                control={formInstance.control}
                name={`participantsAndInvestments.taxes.${index}.plannedTax`}
                inputProps={{
                  view: 'secondary',
                  size: 'xl',
                  label: dayjs(item.taxYear).year().toString(),
                  minorPartView: 'withZeroMinorPart',
                  hasNegativeValue: true,
                  dataTestId: `Taxes-participantsAndInvestments.taxes.${index}.plannedTax-amountInput`,
                }}
              />
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}

export default memo(Taxes)
