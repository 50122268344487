import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'

import Alert from '@components/Alert'
import { defaultMessage } from '@constants/texts'
import { useAPIContext } from '@context/APIContext'
import { isAxiosError } from '@helpers/checkTypes'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { useQueryManager } from '@hooks/useQueryManager'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import type {
  HandleAddFileProps,
  HandleAddFileReturnType,
  HandleAddSignFileProps,
  HandleChangeFileNameProps,
  HandleChangeFileNameReturnType,
  HandleChangeFileProps,
  HandleChangeFileReturnType,
  HandleDownloadFileProps,
  HandleDownloadFileReturnType,
  HandleRemoveFileProps,
  HandleRemoveFileReturnType,
} from './types'

const useAttachmentFileApi = () => {
  const {
    documentsApi: {
      saveDocument,
      replaceDocument,
      deleteDocument,
      changeDocumentAttribute,
      saveForeignSignature,
      saveDetachedSignature,
    },
    dfosApi: { downloadDocumentTemplateByType },
  } = useAPIContext()

  const { projectId } = useParams()

  const { queryUtils } = useQueryManager()

  const dfoId = queryUtils.getQuery('dfoId') || ''

  const handleAddFile = useCallback(
    async ({
      file,
      type,
      folderId,
      documentSetId,
    }: HandleAddFileProps): HandleAddFileReturnType => {
      if (!projectId || !dfoId || !documentSetId) return

      try {
        return await saveDocument?.(
          {
            projectId,
            dfoId,
            documentSetId,
            folderId,
            type,
          },
          [file],
        )
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useAttachmentFileApi',
            componentType: 'handleAddFile',
            moduleName: 'AttachmentAPIProvider',
          },
          additionInfo: {
            documentSetId,
            type,
            folderId,
            file,
          },
        })(error)

        if (isAxiosError(error) && isIntervalServerError(error?.response?.status)) {
          toast(
            <Alert transparent variant="error">
              {defaultMessage}
            </Alert>,
          )
        }

        throw error
      }
    },
    [dfoId, projectId, saveDocument],
  )

  const handleChangeFile = useCallback(
    async ({ file, fileId, documentSetId }: HandleChangeFileProps): HandleChangeFileReturnType => {
      try {
        if (!projectId || !documentSetId) return

        return await replaceDocument?.(
          {
            dfoId,
            projectId,
            documentSetId,
            replacedDocumentId: fileId,
          },
          [file],
        )
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useAttachmentFileApi',
            componentType: 'handleChangeFile',
            moduleName: 'AttachmentAPIProvider',
          },
          additionInfo: {
            documentSetId,
            fileId,
            file,
          },
        })(error)

        if (isAxiosError(error) && isIntervalServerError(error?.response?.status)) {
          toast(
            <Alert transparent variant="error">
              {defaultMessage}
            </Alert>,
          )
        }

        throw error
      }
    },
    [dfoId, projectId, replaceDocument],
  )

  const handleRemoveFile = useCallback(
    async ({ fileId, documentSetId }: HandleRemoveFileProps): HandleRemoveFileReturnType => {
      try {
        if (!projectId || !dfoId || !documentSetId) return

        return await deleteDocument?.({
          dfoId,
          projectId,
          documentSetId,
          documentId: fileId,
        })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useAttachmentFileApi',
            componentType: 'handleRemoveFile',
            moduleName: 'AttachmentAPIProvider',
          },
          additionInfo: {
            documentSetId,
            fileId,
          },
        })(error)

        handleErrorWithPopup(error, true)

        throw error
      }
    },
    [deleteDocument, dfoId, projectId],
  )

  const handleDownloadTemplate = useCallback(
    async ({ documentType }: HandleDownloadFileProps): HandleDownloadFileReturnType => {
      try {
        return await downloadDocumentTemplateByType(documentType)
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          additionInfo: {
            documentType,
          },
          componentInfo: {
            componentName: 'useAttachmentFileApi',
            componentType: 'handleDownloadTemplate',
            moduleName: 'AttachmentAPIProvider',
          },
        })(error)

        throw error
      }
    },
    [downloadDocumentTemplateByType],
  )

  const handleChangeFileName = useCallback(
    async ({
      documentSetId,
      fileId,
      name,
    }: HandleChangeFileNameProps): HandleChangeFileNameReturnType => {
      if (!projectId || !dfoId || !name) return

      try {
        await changeDocumentAttribute?.({
          documentId: fileId,
          projectId,
          dfoId,
          documentSetId,
          name,
        })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useAttachmentFileApi',
            componentType: 'handleChangeFileName',
            moduleName: 'AttachmentAPIProvider',
          },
          additionInfo: {
            documentSetId,
            fileId,
            name,
          },
        })(error)

        throw error
      }
    },
    [changeDocumentAttribute, dfoId, projectId],
  )

  const handleForeignSign = useCallback(
    async ({ documentId, documentSetId, sigFile }: HandleAddSignFileProps) => {
      if (!projectId || !dfoId) return

      try {
        const { documentId: signedDocumentId } = await saveDetachedSignature({
          documentSetId,
          file: sigFile,
          projectId,
        })

        await saveForeignSignature({
          dfoId,
          documentId,
          documentSetId,
          projectId,
          signatureId: signedDocumentId,
        })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useFileUploaderNew',
            componentType: 'handleForeignSign',
            moduleName: 'FileUploader',
          },
          additionInfo: {
            documentSetId,
            documentId,
            sigFile,
          },
        })(error)

        throw error
      }
    },
    [dfoId, projectId, saveDetachedSignature, saveForeignSignature],
  )

  return {
    handleAddFile,
    handleChangeFile,
    handleRemoveFile,
    handleDownloadTemplate,
    handleChangeFileName,
    handleForeignSign,
  }
}

export default useAttachmentFileApi
