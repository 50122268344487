import { useRef } from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'

import useEffectAfterMount from '@hooks/useEffectAfterMount'

const useNestedMenuFormErrors = <T extends FieldValues>(errors: FieldErrors<T>) => {
  const formErrorsRef = useRef<FieldErrors<T>>(errors)

  useEffectAfterMount(() => {
    formErrorsRef.current = errors
  }, [errors])

  return {
    formErrorsRef,
  }
}

export { useNestedMenuFormErrors }
