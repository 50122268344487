import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { ApplicationInclusionOfAARegisterSZPKFormValues } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/types'

const generateRHFObjectForApplicationInclusionOfAARegisterSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): ApplicationInclusionOfAARegisterSZPKFormValues => {
  return {
    '1': RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '2': {
      extraStatementName: objectFromPropertyState.extraStatementName.value || '',
    },
  }
}

export { generateRHFObjectForApplicationInclusionOfAARegisterSZPKForm }
