import { FC } from 'react'

import HeaderInfo from './HeaderInfo'
import NPA from './NPA'

interface NpaSolicitationInclusionFormsProps extends FC {
  HeaderInfo: typeof HeaderInfo
  NPA: typeof NPA
}

const NpaSolicitationInclusionForms: NpaSolicitationInclusionFormsProps = () => null

NpaSolicitationInclusionForms.HeaderInfo = HeaderInfo
NpaSolicitationInclusionForms.NPA = NPA

export default NpaSolicitationInclusionForms
