import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ProjectStageStepCompensationItem from '@components/Forms/ProjectSZPKForm/Forms/6/AdditionalForms/ProjectStage/StepCompensations/StepCompensationItem'
import { StagesArrayCompensationsPathName } from '@components/Forms/ProjectSZPKForm/Forms/6/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

interface ProjectStageStepCompensationsProps {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: StagesArrayCompensationsPathName
}

const ProjectStageStepCompensations: FC<ProjectStageStepCompensationsProps> = ({
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithOutValue, handleChangeValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getSingleSelectProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: stepCompensations } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    StagesArrayCompensationsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: formName,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const handleAddStepCompensation = async () => {
    await handleAddItemToListWithOutValue?.(formName)
  }
  const handleRemoveStepCompensation = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${formName}.${index}`, formName)
  }

  return (
    <Group disableBottomBorder title={'Виды затрат, планируемых к возмещению'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: formName,
          })}
        >
          <FlipperList list={stepCompensations}>
            {stepCompensations.map((stepCompensation, index) => {
              const stepCompensationName = `${formName}.${index}` as const

              return (
                <ProjectStageStepCompensationItem
                  key={stepCompensation.id}
                  id={stepCompensation.id}
                  blockViewIsValidating={blockViewIsValidating}
                  editMode={editMode}
                  formInstance={formInstance}
                  formName={stepCompensationName}
                  onRemove={editMode ? handleRemoveStepCompensation(index) : undefined}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddStepCompensation}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      disabled={isLoading}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default ProjectStageStepCompensations
