import { useFormContext, useWatch } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { dataOnFulfillmentOfTermsSZPKBlockValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/const'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import {
  DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import Stack from '@components/ReactBootstrap/Stack'

import SzpkChangeTerminateStep from './AdditionalForms/SzpkChangeTerminateStep'
import SzpkRegionalReportGroup from './AdditionalForms/SzpkRegionalReportGroup'
import styles from './Fifth.module.scss'
import { SzpkChangeTerminatePathName } from './types'

const Fifth = () => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    DataOnFulfillmentOfTermsSZPKFormValues,
    SzpkChangeTerminatePathName,
    'keyNameId'
  >({
    name: dataOnFulfillmentOfTermsSZPKBlockValues['10'].szpksChangeTerminate,
    control: formInstance?.control,
    keyName: 'keyNameId',
    watcher: DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  })

  const szpksChangeTerminate = useWatch({
    name: dataOnFulfillmentOfTermsSZPKBlockValues['10'].szpksChangeTerminate,
    control: formInstance?.control,
  })

  if (!formInstance) return null

  return (
    <Stack direction={'vertical'} gap={4} id={'5.1'}>
      <SubscribableControl
        {...getSubscribableControlProps({
          path: dataOnFulfillmentOfTermsSZPKBlockValues['10'].szpksChangeTerminate,
        })}
      >
        <FlipperList list={szpksChangeTerminate} className={styles.szpksChangeTerminateList}>
          {szpksChangeTerminate.map((szpkTerminate, index) => {
            const formName =
              `${dataOnFulfillmentOfTermsSZPKBlockValues['10'].szpksChangeTerminate}.${index}` as const

            return (
              <SzpkChangeTerminateStep
                key={szpkTerminate.id}
                anchorId={`5.1.${index + 1}`}
                itemId={szpkTerminate.id}
                formName={formName}
                formInstance={formInstance}
              />
            )
          })}
        </FlipperList>
      </SubscribableControl>

      <SzpkRegionalReportGroup
        groupTitle="Соглашения, по которым выявлены нарушения, стороной которых является Российская Федерация"
        groupProps={{
          id: '5.2',
        }}
        countFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11']
            .federalChangeTerminationSzpkCountRegionalReport
        }
        reasonCountFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11']
            .federalChangeTerminationReasonCountRegionalReport
        }
        totalCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11']
            .federalChangeTerminationSzpkTotalCapitalRegionalReport
        }
        investorCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11']
            .federalChangeTerminationSzpkInvestorCapitalRegionalReport
        }
      />

      <SzpkRegionalReportGroup
        groupTitle={'Соглашения, по которым выявлены основания для их изменения'}
        countFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11'].federalChangeSzpkCountRegionalReport
        }
        totalCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11'].federalChangeSzpkTotalCapitalRegionalReport
        }
        reasonCountFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11'].federalChangeReasonCountRegionalReport
        }
        investorCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11']
            .federalChangeSzpkInvestorCapitalRegionalReport
        }
      />

      <SzpkRegionalReportGroup
        groupTitle={'Соглашения, по которым выявлены основания для их расторжения'}
        countFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11'].federalTerminationSzpkCountRegionalReport
        }
        totalCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11']
            .federalTerminationSzpkTotalCapitalRegionalReport
        }
        reasonCountFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11'].federalTerminationReasonCountRegionalReport
        }
        investorCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['11']
            .federalTerminationSzpkInvestorCapitalRegionalReport
        }
      />

      <SzpkRegionalReportGroup
        countFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12']
            .regionalChangeTerminationSzpkCountRegionalReport
        }
        reasonCountFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12']
            .regionalChangeTerminationReasonCountRegionalReport
        }
        totalCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12']
            .regionalChangeTerminationSzpkTotalCapitalRegionalReport
        }
        investorCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12']
            .regionalChangeTerminationSzpkInvestorCapitalRegionalReport
        }
        groupTitle={
          'Соглашения, по которым выявлены нарушения, стороной которых не является Российская Федерация'
        }
        groupProps={{
          id: '5.3',
        }}
      />

      <SzpkRegionalReportGroup
        groupTitle={'Соглашения, по которым выявлены основания для их изменения'}
        countFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12'].regionalChangeSzpkCountRegionalReport
        }
        totalCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12'].regionalChangeSzpkTotalCapitalRegionalReport
        }
        reasonCountFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12'].regionalChangeReasonCountRegionalReport
        }
        investorCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12']
            .regionalChangeSzpkInvestorCapitalRegionalReport
        }
      />

      <SzpkRegionalReportGroup
        groupTitle={'Соглашения, по которым выявлены основания для их расторжения'}
        countFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12'].regionalTerminationSzpkCountRegionalReport
        }
        reasonCountFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12'].regionalTerminationReasonCountRegionalReport
        }
        totalCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12']
            .regionalTerminationSzpkTotalCapitalRegionalReport
        }
        groupProps={{
          disableBottomBorder: true,
        }}
        investorCapitalFormName={
          dataOnFulfillmentOfTermsSZPKBlockValues['12']
            .regionalTerminationSzpkInvestorCapitalRegionalReport
        }
      />
    </Stack>
  )
}

export default Fifth
