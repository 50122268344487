import React, { FC, memo } from 'react'

import { WebFormStateControllerProps } from '@components/Attachments/WebForm/WIthoutDocumentController/types'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusLoader from '@components/ControlContainer/Status/Loader'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'

const WebFormNewStateController: FC<WebFormStateControllerProps> = ({ documentInfoFromDict }) => {
  const { description: title } = documentInfoFromDict

  return (
    <ControlContainer
      title={title}
      leadingNodeContent={<ControlContainerStatusLoader />}
      additionalMainContent={
        <ControlContainerSubtitle>
          Идет создание формы, пожалуйста, подождите
        </ControlContainerSubtitle>
      }
    />
  )
}

export default memo(WebFormNewStateController)
