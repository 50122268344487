import { FC, memo, useCallback, useEffect, useMemo } from 'react'

import usePasswordChangeModal from '@components/NewDesignedModals/PasswordChangeModal/usePasswordChangeModal'
import Table from '@components/Table'
import { useAPIContext } from '@context/APIContext'
import type { Operator } from '@context/APIContext/hooks/useOperatorApi'
import useUsers from '@hooks/new/swr/useUsers'
import cn from 'classnames'

import { usersTabs } from '../constants'
import useManageUsersSidebar from '../useManageUsersSidebar'
import type { FilterActions } from '../Users'

import { columns } from './columns'
import styles from './OperatorsTable.module.scss'

const initialTableState = {
  _key: usersTabs.operators,
  pageSize: 15,
  pageIndex: 0,
} as const

interface OperatorsTableProps {
  filterActions: FilterActions
  setUpdater: (updater: VoidFunction) => VoidFunction
}

const OperatorsTable: FC<OperatorsTableProps> = ({ filterActions, setUpdater }) => {
  const {
    operatorApi: { getOperatorsList },
  } = useAPIContext()

  const getKey = useCallback(
    (pageIndex: number) => ({
      ...initialTableState,
      pageIndex,
      ...(filterActions === 'onlyDisabled' && {
        isEnabled: false,
      }),
    }),
    [filterActions],
  )

  const {
    data: operators,
    isValidating: isLoadingOperators,
    mutate: updateOperators,
    goToNextPage,
  } = useUsers<Operator[]>({
    key: getKey,
    fetcher: getOperatorsList,
    pageSize: initialTableState.pageSize,
    config: {
      revalidateFirstPage: false,
    },
  })

  const successCallback = useCallback(async () => {
    await updateOperators()
  }, [updateOperators])

  const { handleOpenManageOperatorSidebar } = useManageUsersSidebar()

  const { handleOpenPasswordChangeModal } = usePasswordChangeModal(successCallback)

  const handleRowClick = useCallback(
    (row: Operator) => {
      handleOpenManageOperatorSidebar({
        user: row,
        onSuccess: successCallback,
      })
    },
    [handleOpenManageOperatorSidebar, successCallback],
  )

  const handleRowProps = useCallback(
    (row, data) => {
      return {
        ...row,
        className: cn(row.className, {
          [styles.rowIsBlocked]: !data.isEnabled,
        }),
        onClick: () => {
          handleRowClick(data)
        },
      }
    },
    [handleRowClick],
  )

  useEffect(() => {
    setUpdater(updateOperators)
  }, [])

  const resetDeps = useMemo(() => [filterActions], [filterActions])

  return (
    <>
      <Table
        manualSortBy
        manualPagination
        hidePagination
        autoResetPage={false}
        loading={isLoadingOperators}
        columns={columns(handleOpenPasswordChangeModal)}
        className={styles.table}
        initialState={initialTableState}
        data={operators ?? []}
        resetDeps={resetDeps}
        getRowProps={handleRowProps}
        onLoadMore={goToNextPage}
      />
    </>
  )
}

export default memo(OperatorsTable)
