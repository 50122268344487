import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { eExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CExploitationForm/ExploitationFormItem/validation'
import { SeventhObjectExploitationObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

interface ExploitationFormItemProps {
  formName: SeventhObjectExploitationObjectsArrayPathName
  indexOfBlock: number
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
}

const ExploitationFormItem: FC<ExploitationFormItemProps> = ({
  indexOfBlock,
  blockViewIsValidating,
  formInstance,
  formModifierInstance,
  formName,
}) => {
  const {
    state: { editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formModifierInstance.control,
  })

  const stepEndDate = useWatch({
    name: `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${indexOfBlock}.stepEndDate`,
    control: formInstance.control,
  })

  const {
    getFormFieldControlProps,
    getSubscribableControlProps,
    getCalendarInputProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const positiveStepEndDate = (text: string) => (value: unknown) => {
    if (
      isDateValidForDayjs(value) &&
      dayjs(value, objOfDateFormats.defaultFormat) >
        dayjs(stepEndDate, objOfDateFormats.defaultFormat)
    )
      return text
  }

  return (
    <div>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectName`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item?.objectName || '',
                          value: item.id,
                        }),
                        controllerProps: {
                          name: `${formName}.objectName`,
                          rules:
                            eExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.objectName,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Объект недвижимости',
                          },
                          onChangeFormValue: () =>
                            setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectPermissionCommissionNumber`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: `${item.permissionNumber ?? ''} ${
                            item.permissionKind?.name ?? ''
                          }`,
                          value: item.id,
                        }),
                        controllerProps: {
                          name: `${formName}.objectPermissionCommissionNumber`,
                          rules:
                            eExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.objectPermissionCommissionNumber,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Номер разрешения',
                          },
                          onChangeFormValue: () =>
                            setTimeout(
                              () =>
                                handleChangeValue?.(`${formName}.objectPermissionCommissionNumber`),
                              0,
                            ),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
            <Col xs={4}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.objectCommissioningDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.objectCommissioningDate`),
                        0,
                      ),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${formName}.objectCommissioningDate`,
                    rules: {
                      ...eExploitationFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.objectCommissioningDate,
                      validate: {
                        positiveCurrentDate: (value) => {
                          const currentDate = DayjsService.dayjsWithFormatToMSK()

                          return (
                            (isDateValidForDayjs(value) &&
                              dayjs(value, objOfDateFormats.defaultFormat) <= currentDate) ||
                            'дата ввода в эксплуатацию объекта должна быть меньше или равна текущей дате'
                          )
                        },
                        positiveStepEndDate: positiveStepEndDate(
                          'дата ввода в эксплуатацию объекта должна быть меньше даты окончания этапа',
                        ),
                      },
                    },
                    calendarInputProps: {
                      label: 'Дата ввода',
                    },
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.objectCommissioningDate`),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.objectCommissioningDate`),
                        0,
                      ),
                    onInputChange: () =>
                      debouncedHandleChangeValue?.(`${formName}.objectCommissioningDate`),
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
        </Stack>
      </Col>
    </div>
  )
}

export default ExploitationFormItem
