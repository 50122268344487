import React from 'react'
import { useFormContext } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useInitialCollapseStateList } from '@components/DocumentFormComponents/hooks/useInitialCollapseStateList'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import CertificateOnMonitoringResultsCompensationMonitoringChecker from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker'
import { CertificateOnMonitoringResultsCompensationsMonitoringCheckerPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'
import { isUndefined } from '@helpers/checkTypes'

const Ninth = () => {
  const formInstance = useFormContext<CertificateOnMonitoringResultsFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { fields: compensationsMonitoringChecker } = useFieldArraySubscribableControl<
    CertificateOnMonitoringResultsFormValues,
    CertificateOnMonitoringResultsCompensationsMonitoringCheckerPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: certificateOnMonitoringResultsBlockValues['9'].compensationsMonitoringChecker,
    keyName: 'keyNameId',
    watcher: CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const { initialCollapseState } = useInitialCollapseStateList(compensationsMonitoringChecker)

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: certificateOnMonitoringResultsBlockValues['9'].compensationsMonitoringChecker,
          })}
        >
          <FlipperList list={compensationsMonitoringChecker} gap={1}>
            {compensationsMonitoringChecker.map((compensationMonitoring, index) => {
              const currentInitState = !isUndefined(initialCollapseState[compensationMonitoring.id])
                ? initialCollapseState[compensationMonitoring.id]
                : true

              const formName =
                `${certificateOnMonitoringResultsBlockValues['9'].compensationsMonitoringChecker}.${index}` as const

              return (
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: formName,
                  })}
                >
                  <CertificateOnMonitoringResultsCompensationMonitoringChecker
                    key={compensationMonitoring.id}
                    id={compensationMonitoring.id}
                    initialAccordionState={currentInitState}
                    formInstance={formInstance}
                    name={formName}
                  />
                </SubscribableControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
      </Stack>
    </Container>
  )
}

export default Ninth
