import React, {
  type ChangeEvent,
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  type ReactNode,
  forwardRef,
  memo,
  useRef,
} from 'react'

import { CheckboxDefaultPayload } from '@components/Checkbox/types'
import Icon, { IconProps } from '@components/Icon'
import Typography from '@components/NewDesign/Typography'
import type { ITypographyBody } from '@components/NewDesign/Typography/types'
import { mergeRefs } from '@helpers/ref/mergeRefs'
import { useFocus } from '@hooks/useFocus'
import checkCircleIcon from '@icons/action/check_circle.svg'
import cn from 'classnames'

import styles from './ChipCheckbox.module.scss'

type NativeProps = InputHTMLAttributes<HTMLInputElement>

interface ChipCheckboxProps extends Omit<NativeProps, 'onChange' | 'type' | 'size'> {
  /**
   * Текст подписи к чекбоксу
   */
  label: ReactNode

  /**
   * Управление состоянием вкл/выкл чекбокса (native prop)
   */
  checked?: boolean

  /**
   * Управление состоянием включен / выключен
   */
  disabled?: boolean

  priority?: 'primary' | 'secondary'
  variant?: 'success' | 'brand' | 'outline' | 'error' | 'warning'
  size?: 'xs' | 's' | 'm'

  labelWrapperProps?: DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >

  labelTypographyProps?: ITypographyBody

  iconProps?: IconProps

  /**
   * Доп. класс чекбокса
   */
  checkboxClassName?: string

  dataTestId?: string

  /**
   * Обработчик переключения чекбокса
   */
  onChange?: (event?: ChangeEvent<HTMLInputElement>, payload?: CheckboxDefaultPayload) => void
}

const ChipCheckbox = forwardRef<HTMLLabelElement, ChipCheckboxProps>(
  (
    {
      size = 'm',
      priority = 'primary',
      variant = 'brand',
      checked,
      disabled,
      label,
      name,
      dataTestId,
      className,
      checkboxClassName,
      labelWrapperProps,
      labelTypographyProps,
      iconProps,
      onChange,
      ...restProps
    },
    ref,
  ) => {
    const {
      variant: typographyVariant = 'bodyMMedium',
      className: typographyClassName,
      ...restLabelTypographyProps
    } = labelTypographyProps || {}

    const {
      className: iconClassName,
      size: iconSize = 'initial',
      src = checkCircleIcon,
      ...restIconProps
    } = iconProps || {}

    const labelRef = useRef<HTMLLabelElement>(null)

    const [focused] = useFocus(labelRef, 'keyboard')

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event, { checked: event.target.checked, name })
      }
    }

    return (
      <label
        {...labelWrapperProps}
        ref={mergeRefs([labelRef, ref])}
        className={cn(styles.chipCheckbox, className, {
          [styles[`chipCheckbox--${disabled}`]]: disabled,
          [styles[`chipCheckbox--${checked}`]]: checked,
          [styles[`chipCheckbox--${focused}`]]: focused,
          [styles[`chipCheckbox--${size}`]]: size,
          [styles[`chipCheckbox--${priority}`]]: !!checked && priority,
          [styles[`chipCheckbox--${variant}`]]: !!checked && variant,
        })}
      >
        <input
          type="checkbox"
          name={name}
          disabled={disabled}
          checked={checked}
          data-testid={dataTestId}
          onChange={handleChange}
          {...restProps}
        />

        <span className={cn(styles['chipCheckbox__checkbox'], checkboxClassName)}>
          {checked && (
            <Icon
              {...restIconProps}
              size={iconSize}
              src={src}
              className={cn(styles['chipCheckbox__checkbox-icon'], iconClassName)}
            />
          )}
        </span>

        <Typography
          {...restLabelTypographyProps}
          variant={typographyVariant}
          className={cn(styles['chipCheckbox__content'], typographyClassName)}
        >
          {label}
        </Typography>
      </label>
    )
  },
)

export type { ChipCheckboxProps }
export default memo(ChipCheckbox)
