import React, { FC, memo, ReactNode } from 'react'

import { FileItemReducerState } from '@components/Attachments/Attachment/FileItem/types'
import Loader from '@components/Loader'
import Typography from '@components/NewDesign/Typography'
import { objOfDateFormats } from '@constants/dateFormats'
import { formatBytes, splitFileName } from '@helpers/fileHelpers'
import DayjsService from '@services/Dayjs/Dayjs.service'
import cn from 'classnames'

import styles from './FileItemTrailingNode.module.scss'

interface FileItemContentProps {
  fileState: FileItemReducerState
  sizeClassName?: string
  trailingNode?: ReactNode
  additionalTrailingNode?: ReactNode
  leadingNode?: ReactNode
}

const FileItemTrailingNode: FC<FileItemContentProps> = ({
  fileState,
  sizeClassName,
  trailingNode,
  additionalTrailingNode,
  leadingNode,
}) => {
  const { file, fileSize, errorMessage, isLoading } = fileState

  const metaInfoRenderCondition = !isLoading && !errorMessage

  const lastModifiedDate =
    file &&
    DayjsService.dayjsWithFormatToMSK(file.lastModified).format(
      objOfDateFormats.formatWithFullMonth,
    )

  const splitedFileName = file && splitFileName(file?.name)

  const extensionAndSizeOfAttachment =
    splitedFileName && `${splitedFileName[1].toUpperCase()} · ${formatBytes(fileSize)}`

  return (
    <>
      {!isLoading && errorMessage && (
        <Typography.Body color={'text-accent-error-secondary'} variant={'bodySMedium'}>
          {errorMessage}
        </Typography.Body>
      )}
      {isLoading && (
        <>
          <Loader
            loading
            wrapperClassName={styles.trailingNode__loading}
            className={styles['trailingNode__loading-icon']}
            variant={'lite'}
          />
          <Typography.Body
            className={styles['trailingNode__loading-addFileText']}
            color={'text-base-tertiary'}
            variant={'bodySMedium'}
          >
            Добавление файла
          </Typography.Body>
        </>
      )}
      {metaInfoRenderCondition && (
        <div className={styles['trailingNode__container']}>
          {trailingNode}

          <div className={styles['trailingNode__item-wrapper']}>
            {additionalTrailingNode ? (
              <>{additionalTrailingNode}</>
            ) : (
              <>
                {lastModifiedDate ? (
                  <Typography.Body
                    color={'text-base-tertiary'}
                    variant={'bodySMedium'}
                    className={styles['trailingNode__item-date']}
                  >
                    {lastModifiedDate}
                  </Typography.Body>
                ) : (
                  <div />
                )}

                <Typography.Body
                  color={'text-base-tertiary'}
                  variant={'bodySMedium'}
                  className={cn(styles['trailingNode__item-size'], sizeClassName)}
                >
                  {extensionAndSizeOfAttachment}
                </Typography.Body>

                {leadingNode}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export type { FileItemContentProps }
export default memo(FileItemTrailingNode)
