import { generateTestComponentId } from '@helpers/generateTestComponentId'

import type { HandleLogErrorParams } from './LoggerService.entity'
import LoggerService from './LoggerService.service'

class LoggerHelpersService {
  static handleMultipleLogError({ additionInfo, componentInfo }: HandleLogErrorParams) {
    const componentId = generateTestComponentId(componentInfo)

    return (error: unknown) => {
      LoggerService.multipleLog({ error, additionInfo, componentId })
    }
  }

  static handleInternalLogError({ additionInfo, componentInfo }: HandleLogErrorParams) {
    const componentId = generateTestComponentId(componentInfo)

    return (error: unknown) => {
      LoggerService.internalLog({ error, additionInfo, componentId })
    }
  }

  static handleExternalLogError({ additionInfo, componentInfo }: HandleLogErrorParams) {
    const componentId = generateTestComponentId(componentInfo)

    return (error: unknown) => {
      LoggerService.externalLog({ error, additionInfo, componentId })
    }
  }
}

export default LoggerHelpersService
