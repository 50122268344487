import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'

import DataOnFulfillmentOfTermsSZPKForms from '../Forms'
import { useDataOnFulfillmentOfTermsSZPKManager } from '../Manager'
import { mapOfDataOnFulfillmentOfTermsSZPKMenu } from '../Menu/const'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '../types'

const mapOfDataOnFulfillmentOfTermsSZPKForms: FormItemProps = {
  '1': {
    node: <DataOnFulfillmentOfTermsSZPKForms.First />,
  },
  '2': {
    node: <DataOnFulfillmentOfTermsSZPKForms.Second />,
  },
  '3': {
    node: <DataOnFulfillmentOfTermsSZPKForms.Third />,
  },
  '4': {
    node: <DataOnFulfillmentOfTermsSZPKForms.Fourth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '5': {
    node: <DataOnFulfillmentOfTermsSZPKForms.Fifth />,
  },
} as const

const DataOnFulfillmentOfTermsSZPKForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { formIsLoading },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfDataOnFulfillmentOfTermsSZPKMenu}
      mapOfForms={mapOfDataOnFulfillmentOfTermsSZPKForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(DataOnFulfillmentOfTermsSZPKForm)
