import { DetailedHTMLProps, FC, HTMLAttributes, memo } from 'react'

import cn from 'classnames'

import styles from './BurgerButton.module.scss'

interface BurgerButtonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOpen: boolean
}

const BurgerButton: FC<BurgerButtonProps> = ({ isOpen, ...rest }) => {
  return (
    <div {...rest} className={cn(styles.burger, rest.className)}>
      <div
        className={cn(styles.burger__item, {
          [styles['burger__item-first']]: isOpen,
        })}
      />
      <div
        className={cn(styles.burger__item, styles['burger__item-second'], {
          [styles['burger__item--closed']]: isOpen,
          [styles['burger__item-second--closed']]: isOpen,
        })}
      />
      <div
        className={cn(styles.burger__item, {
          [styles['burger__item-third']]: isOpen,
        })}
      />
    </div>
  )
}

export default memo(BurgerButton)
