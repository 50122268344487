import { usePopupManager } from 'react-popup-manager'

import SuccessModal, { SuccessModalComponentProps } from '.'

const useSuccessModal = () => {
  const popupManager = usePopupManager()

  const handleOpenSuccessModal = (props: SuccessModalComponentProps) => {
    popupManager.open(SuccessModal, {
      ...props,
      onClose: () => null,
    })
  }

  return {
    handleOpenSuccessModal,
  }
}

export { useSuccessModal }
