import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const percentCreditMemberValidationMap: FormValuesValidationSection<
  keyof InformationOfExecutionConditionsSZPKFormValues['4']['percentCredits'][number]['percentCreditMembers'][number],
  InformationOfExecutionConditionsSZPKFormValues
> = {
  percentCreditMember: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { percentCreditMemberValidationMap }
