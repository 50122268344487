import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { PetitionTransferRightFormValues } from '@components/Forms/PetitionTransferRightForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const oneSectionValidationMap: FormValuesValidationSection<
  keyof PetitionTransferRightFormValues['1'],
  PetitionTransferRightFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
  investorFullNameGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { oneSectionValidationMap }
