import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import Group from '@components/DocumentFormComponents/Group'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { CreditsMembersPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/4/types'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

import { percentCreditMemberValidationMap } from './validation'

interface CreditMemberFormProps {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<InformationOfExecutionConditionsSZPKFormValues>
  name: CreditsMembersPathName
}

const { isFormFieldError } = DocumentFormHelpers

const CreditMemberForm: FC<CreditMemberFormProps> = ({
  blockViewIsValidating,
  editMode,
  formInstance,
  name,
}) => {
  const {
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleChangeValue,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })
  const { fields: creditMembers } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    CreditsMembersPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: name,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  const creditMembersError = formInstance.getFieldState(name)?.error

  const handleAddStepCompensation = async () => {
    await handleAddItemToListWithOutValue?.(name)
  }
  const handleRemoveStepCompensation = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  return (
    <Group disableBottomBorder title={creditMembers.length ? 'Стороны договора' : ''}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: name,
          })}
        >
          <FlipperList list={creditMembers}>
            {creditMembers.map((creditMember, index) => {
              const creditMemberName = `${name}.${index}` as const
              return (
                <GroupWithRemoveButton
                  disableBottomBorder
                  key={creditMember.id}
                  id={creditMember.id}
                  title={`Сторона договора №${index + 1}`}
                  onRemove={editMode ? handleRemoveStepCompensation(index) : undefined}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${creditMemberName}.percentCreditMember`,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: `${creditMemberName}.percentCreditMember`,
                        rules: percentCreditMemberValidationMap.percentCreditMember,
                        textareaProps: {
                          label: 'Сторона договора',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(`${creditMemberName}.percentCreditMember`)
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${creditMemberName}.percentCreditMember`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </GroupWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(creditMembersError) && !creditMembers.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddStepCompensation}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      disabled={isLoading}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить сторону договора
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default CreditMemberForm
