import { useCallback, useRef } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { loaderModalHeaderTexts } from '@constants/texts'

import LoadingModal from '.'

const useLoadingModal = () => {
  const { open: openPopup } = usePopupManager()
  const closeModalRef = useRef<VoidFunction | null>(null)

  const bindCloseRef = useCallback((ref: VoidFunction) => {
    closeModalRef.current = ref
  }, [])

  const handleOpenLoadingModal = useCallback(
    (text: string = loaderModalHeaderTexts.defaultMessage) => {
      openPopup(LoadingModal, {
        text,
        bindCloseRef,
      })

      return () => closeModalRef.current
    },
    [openPopup, bindCloseRef],
  )

  return {
    handleOpenLoadingModal,
  }
}

export { useLoadingModal }
