import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap: FormValuesValidationSection<
  keyof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues['3'],
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues
> = {
  investorFullNameInstrumental: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1050),
  },
}

export { thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap }
