import React from 'react'
import { useFormContext } from 'react-hook-form'

import InitiatorSection from '@components/DocumentFormComponents/FormSections/InitiatorSection'
import { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyManager } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/Manager'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/watcher'

const Second = () => {
  const formInstance =
    useFormContext<NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyManager()

  if (!formInstance) return null

  return (
    <InitiatorSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formInstance={formInstance}
      formName={'2'}
      subscribableControl={subscribableControl}
      watcher={
        NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFieldsControlUpdateWatcher
      }
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
