import { useCallback, useMemo } from 'react'

import { PAGE_SIZE_FOR_NPA } from '@components/RegistryNpa/const'
import { useAPIContext } from '@context/APIContext'
import { TGetListNpaResponse } from '@context/APIContext/hooks/useNpaApi'
import { SWRIniniteHookProps } from '@interfaces'
import useSWRInfinite from 'swr/infinite'

const useNpaList = ({ key, config }: SWRIniniteHookProps<TGetListNpaResponse>) => {
  const {
    npaApi: { getListOfNpa },
  } = useAPIContext()

  const {
    data: npaList,
    error,
    mutate,
    isValidating: isNpaListLoading,
    setSize,
  } = useSWRInfinite(key, getListOfNpa, {
    ...config,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  const hasMoreData = useMemo(() => {
    return npaList && npaList?.[npaList?.length - 1]?.length > PAGE_SIZE_FOR_NPA - 1
  }, [npaList])

  const goToNextPage = useCallback(() => {
    hasMoreData && setSize((prev) => prev + 1)
  }, [hasMoreData, setSize])

  const computedNpaList = useMemo(() => {
    return npaList?.flat()
  }, [npaList])

  return {
    npaList: computedNpaList,
    error,
    mutate,
    isNpaListLoading,
    hasMoreData,
    incPage: setSize,
    onLoadMore: goToNextPage,
  }
}

export { useNpaList }
