import { FieldArraySwapperProps } from '@components/DocumentFormComponents/FieldArraySwapper/types'
import { isUndefined } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'

const swapItemsInList = <T>(list: T[], sourceIndex: number, destinationIndex: number): T[] => {
  const newList = list.slice()

  ;[newList[sourceIndex], newList[destinationIndex]] = [
    newList[destinationIndex],
    newList[sourceIndex],
  ]

  return newList
}

type UseMoveItemProps<T> = Pick<
  FieldArraySwapperProps<T>,
  'index' | 'list' | 'onMoveTop' | 'onMoveBottom'
>

export const useMoveItem = <T>({ index, list, onMoveTop, onMoveBottom }: UseMoveItemProps<T>) => {
  const { booleanState: isMoveTopLoading, setBooleanState: setMoveTopLoading } = useBooleanState()

  const { booleanState: isMoveBottomLoading, setBooleanState: setMoveBottomLoading } =
    useBooleanState()

  const moveTop = async () => {
    if (isUndefined(list) || !list.length) {
      await onMoveTop?.(index, index - 1)

      return
    }

    const updatedList = swapItemsInList(list, index, index - 1)

    await onMoveTop?.(index, index - 1, updatedList)

    return
  }

  const handleMoveTop = async () => {
    setMoveTopLoading(true)

    await moveTop()

    setMoveTopLoading(false)
  }

  const moveBottom = async () => {
    if (isUndefined(list) || !list.length) {
      await onMoveBottom?.(index, index + 1)

      return
    }

    const updatedList = swapItemsInList(list, index, index + 1)

    await onMoveBottom?.(index, index + 1, updatedList)
  }

  const handleMoveBottom = async () => {
    setMoveBottomLoading(true)

    await moveBottom()

    setMoveBottomLoading(false)
  }

  return {
    isMoveTopLoading,
    isMoveBottomLoading,
    onMoveTopButtonClick: handleMoveTop,
    onMoveBottomButtonClick: handleMoveBottom,
  }
}
