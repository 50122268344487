import React, { FC } from 'react'
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ObjectItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectItem'
import { SeventhConstructionObjectsPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const { isFormFieldError } = DocumentFormHelpers

interface CObjectsFormProps {
  name: SeventhConstructionObjectsPathName
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
}

const CObjectsForm: FC<CObjectsFormProps> = ({ name, formModifierInstance }) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { fields: objectForms } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhConstructionObjectsPathName,
    'keyNameId'
  >({
    name,
    control: formInstance.control,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
    SeventhConstructionObjectsPathName
  >({
    name,
    control: formModifierInstance.control,
    watcher:
      AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
  })

  const objectFormsError = formInstance.getFieldState(name)?.error

  const expandedObjectsRenderMode =
    useWatch({
      name: 'additionalFields.allProjectObjectsLength',
      control: formInstance.control,
    }) < 70

  const handleAddObject = async () => {
    await handleAddItemToListWithOutValue?.(name)

    if (!objectForms.length) {
      formInstance.clearErrors(name)
    }
  }

  const handleRemoveObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(`${name}.${index}`)
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(`${name}.${index}`)
  }

  if (!formInstance) return null

  return (
    <Container className="p-0">
      <Stack direction={'vertical'} gap={!!objectForms.length ? 3 : 0}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: name,
          })}
        >
          <FlipperList list={objectForms}>
            {objectForms.map((form, index) => {
              const formName = `${name}.${index}` as const

              return (
                <SubscribableControl
                  key={form.id}
                  {...getSubscribableControlProps({
                    path: formName,
                  })}
                >
                  <FormObjectTooltipControl
                    name={formName}
                    control={formModifierInstance.control}
                    onDifference={handleUpdateChanges}
                  >
                    <ObjectItem
                      objectId={form.id}
                      blockViewIsValidating={blockViewIsValidating}
                      expandedRenderMode={expandedObjectsRenderMode}
                      editMode={editMode}
                      formInstance={formInstance}
                      formModifierInstance={formModifierInstance}
                      name={formName}
                      indexOfObject={index}
                      lastIndexOfObject={objectForms.length - 1}
                      onDeleteObject={handleRemoveObject(index)}
                      onMoveTop={handleChangeElementOrderToUp(index)}
                      onMoveBottom={handleChangeElementOrderToDown(index)}
                    />
                  </FormObjectTooltipControl>
                </SubscribableControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(objectFormsError) && !objectForms.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddObject}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      {`Добавить основной объект №${objectForms.length + 1}`}
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default CObjectsForm
