import { generatePropertiesObjectForNoticeIntentionTerminateSZPKForm } from '@components/Forms/NoticeIntentionTerminateSZPKForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNoticeIntentionTerminateSZPKForm } from '@components/Forms/NoticeIntentionTerminateSZPKForm/adapters/RHF.adapter'
import { NoticeIntentionTerminateSZPKFormValues } from '@components/Forms/NoticeIntentionTerminateSZPKForm/types'

const useNoticeIntentionTerminateSZPKAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NoticeIntentionTerminateSZPKFormValues =>
    generateRHFObjectForNoticeIntentionTerminateSZPKForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNoticeIntentionTerminateSZPKForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNoticeIntentionTerminateSZPKAdapters }
