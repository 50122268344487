import { FC } from 'react'

import cn from 'classnames'

import styles from './Loader.module.scss'

export interface LoaderProps {
  loading?: boolean
  variant?: 'l' | 's' | 'lite'
  className?: string
  wrapperClassName?: string
  dataTestId?: string
}

type StyledLoaderProps = Pick<LoaderProps, 'variant' | 'className' | 'dataTestId'>

const StyledLoader: FC<StyledLoaderProps> = ({ variant, className, dataTestId, children }) => (
  <div className={cn(styles[`loader-${variant}`], className)} data-testid={dataTestId}>
    {children}
  </div>
)

const loaderItems = Array.from({ length: 12 }, (_, i) => <div key={i} />)

const Loader: FC<LoaderProps> = ({
  loading,
  variant = 'l',
  children,
  className,
  wrapperClassName,
  dataTestId,
}) =>
  loading ? (
    <div className={cn(styles.loader, styles.loaderCenter, wrapperClassName)}>
      <StyledLoader className={className} variant={variant} dataTestId={dataTestId}>
        {variant === 's' && loaderItems}
      </StyledLoader>
    </div>
  ) : (
    <>{children}</>
  )

export default Loader
