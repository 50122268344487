import React, { memo, useMemo } from 'react'
import { ReactSVG } from 'react-svg'

import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import env from '@config'
import { objOfDateFormats } from '@constants/dateFormats'
import { documentsLinks } from '@constants/documents'
import QuestionIcon from '@icons/QuestionIcon.svg'
import DayjsService from '@services/Dayjs/Dayjs.service'
import cn from 'classnames'

import styles from './UserSupport.module.scss'

const SUPPORT_EMAIL = env.REACT_APP_SUPPORT_EMAIL

const UserSupport = () => {
  const { state: triggerClick, handlers } = useManualTooltipControl()

  const hoverTooltipContent = useMemo(
    () => (
      <Typography.Caption variant={'captionMMedium'}>Поддержка пользователей</Typography.Caption>
    ),
    [],
  )

  return (
    <Tooltip
      position={'top-start'}
      trigger={'click'}
      open={triggerClick.tooltipIsOpen}
      popperClassName={styles.tooltip__popper}
      arrowClassName={styles.tooltip__arrow}
      contentClassName={styles.tooltip}
      zIndex={51}
      content={
        <div className={styles.userSupport__content}>
          <div className={styles.userSupport__block}>
            <Typography.Headline
              variant={'headlineH3'}
              className={cn(styles.userSupport__title, styles.userSupport__whiteColor)}
            >
              Что-то не работает?
            </Typography.Headline>
            <Typography.Body
              variant={'bodyMRegular'}
              fontWeight={'normal'}
              className={styles.userSupport__grayColor}
            >
              Пишите нам:{' '}
              <a className={styles.userSupport__action} href={`mailto:${SUPPORT_EMAIL}`}>
                {SUPPORT_EMAIL}
              </a>
            </Typography.Body>
          </div>
          <div className={styles.userSupport__block}>
            <Typography.Headline
              variant={'headlineH3'}
              className={cn(styles.userSupport__title, styles.userSupport__whiteColor)}
            >
              Нужна помощь?
            </Typography.Headline>
            <Typography.Button
              as={'span'}
              variant={'buttonSMedium'}
              className={styles.userSupport__text}
              fontWeight={'normal'}
            >
              <a download className={styles.userSupport__action} href={documentsLinks.GIS_KAP_RP}>
                Скачать руководство пользователя
              </a>
            </Typography.Button>
          </div>
          <div className={styles.userSupport__block}>
            <Typography.Body
              variant={'bodyMRegular'}
              className={cn(styles.userSupport__text, styles.userSupport__grayColor)}
            >
              Все события и сроки в системе отображаются по московскому времени
            </Typography.Body>
            <Typography.Caption
              variant="captionSRegular"
              fontWeight="medium"
              className={styles.userSupport__whiteColor}
            >
              {DayjsService.dayjsWithFormatToMSK().format(
                objOfDateFormats.currentYear.weekdayFullFormat,
              )}{' '}
              (UTC +03:00)
            </Typography.Caption>
          </div>
        </div>
      }
      onClose={handlers.handleCloseTooltip}
    >
      <button type="button" onClick={handlers.handleToggleTooltip}>
        <Tooltip
          trigger={'hover'}
          position={'top-start'}
          content={hoverTooltipContent}
          popperClassName={cn({
            [styles.userSupport__invisible]: triggerClick.tooltipIsOpen,
          })}
        >
          <button type="button" className={styles.userSupport}>
            <ReactSVG src={QuestionIcon} />
          </button>
        </Tooltip>
      </button>
    </Tooltip>
  )
}

export default memo(UserSupport)
