import { useCallback, useState } from 'react'

import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { NestedMenuHelpers } from '@components/DocumentFormComponents/NestedMenu/helpers'
import { MapOfMenu, NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { isUndefined } from '@helpers/checkTypes'

const { isNestedMenu, fillArrayWithSeparatedPaths } = NestedMenuHelpers

interface FormNavigationReturn {
  currentAnchorId: string
  handleChangeCurrentAnchorId: (id: string) => Promise<void>
}

/**
 * Используется в Manager компоненте
 */
const useFormNavigation = (initialAnchorId = ''): FormNavigationReturn => {
  const [currentAnchorId, setCurrentAnchorId] = useState<string>(initialAnchorId)

  const handleChangeCurrentAnchorId = async (id: string) => {
    setCurrentAnchorId(id)

    const target = window.document.getElementById(id)

    if (target) {
      target.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return {
    currentAnchorId,
    handleChangeCurrentAnchorId,
  }
}

/**
 * Используется в Menu компоненте
 */
const useFormNavigationMenu = (watcher?: FieldCollapseControlUpdateWatcher) => {
  const handleOpenCollapses = useCallback(
    (path: string) => {
      if (isUndefined(watcher)) return

      const paths = fillArrayWithSeparatedPaths(path)
        .slice(0, -1)
        .map((path) => ({
          path,
          value: true,
        }))

      watcher.notifyAllSubscribers(paths)
    },
    [watcher],
  )

  const handleMenuItemClick = useCallback(
    (callback?: (id: string) => Promise<void>) => async (item: MapOfMenu | NestedMapOfMenu) => {
      if (!isNestedMenu(item)) return callback?.(item.id)

      handleOpenCollapses(item.path)

      await callback?.(item.id)
    },
    [handleOpenCollapses],
  )

  const handleNavigationChangeClick = useCallback(
    (callback?: (path: string) => Promise<void>) => async (path: string) => {
      handleOpenCollapses(path)

      await callback?.(path)
    },
    [handleOpenCollapses],
  )

  return {
    handleMenuItemClick,
    handleNavigationChangeClick,
  }
}
export type { FormNavigationReturn }
export { useFormNavigation, useFormNavigationMenu }
