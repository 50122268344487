import { useMemo, useState } from 'react'

import { useAPIContext } from '@context/APIContext'
import { useOrganizationInfo } from '@hooks/new/swr/useOrganizationInfo'
import { SWRHookProps } from '@interfaces'
import { IOrganizationInfo } from '@services/Organization/organization.entity'

const useOrganizationERGUL = ({ key, config }: SWRHookProps<IOrganizationInfo | undefined>) => {
  const {
    organizationApi: { createOrganizationChangeInfo },
  } = useAPIContext()

  const [EGRULRequestIsLoading, setEGRULRequestLoading] = useState<boolean>(false)
  const [EGRULRequestError, setEGRULRequestError] = useState<unknown | null>(null)

  const organizationSwr = useOrganizationInfo({ key, config })

  const lastEGRULUpdateInfo = useMemo(() => {
    if (!organizationSwr.organizationInfo) return

    return organizationSwr.organizationInfo.lastUpdateRequest
  }, [organizationSwr.organizationInfo])

  const updateEGRUL = async () => {
    setEGRULRequestLoading(true)
    setEGRULRequestError(null)
    try {
      await createOrganizationChangeInfo()
    } catch (error) {
      setEGRULRequestError(error)
      throw error
    } finally {
      setEGRULRequestLoading(false)
    }
  }

  return {
    organizationSwr,
    lastEGRULUpdateInfo,
    updateEGRUL,
    EGRULRequestError,
    EGRULRequestIsLoading,
  }
}

export { useOrganizationERGUL }
