import { ArrayPath, FieldValues, UnpackNestedValue, UseFieldArrayAppend } from 'react-hook-form'
import { FieldArray } from 'react-hook-form/dist/types/fieldArray'

import { isUndefined } from '@helpers/checkTypes'

interface FieldArrayControlWatcherNotifyValue<
  T extends FieldValues,
  P extends ArrayPath<T> = ArrayPath<T>,
> {
  appendProps:
    | Partial<UnpackNestedValue<FieldArray<T, P>>>
    | Partial<UnpackNestedValue<FieldArray<T, P>>>[]
  removeProps: number | number[]
  swapProps: {
    oldIndex: number
    newIndex: number
  }
}

interface SubscriberValueProps<T extends FieldValues, P extends ArrayPath<T> = ArrayPath<T>> {
  appendPropsCallback: UseFieldArrayAppend<T, P>
  removePropsCallback: (index: number | number[]) => void
  swapPropsCallback: (newIndex: number, oldIndex: number) => void
}

type SubscriberFieldsNotifyProps<T extends FieldValues, P extends ArrayPath<T> = ArrayPath<T>> = {
  path: P
  value: Partial<FieldArrayControlWatcherNotifyValue<T, P>>
}

class FieldArrayControlUpdateWatcher<T extends FieldValues, P extends ArrayPath<T> = ArrayPath<T>> {
  private readonly subscribers: Record<string, SubscriberValueProps<T, P>> = {}

  constructor() {
    this.subscribers = {}
  }

  subscribe(path: P, callbacks: SubscriberValueProps<T, P>) {
    this.subscribers[path] = callbacks
  }

  unsubscribe(path: P) {
    if (!this.subscribers[path]) return
    delete this.subscribers[path]
  }

  notifySubscriber({ path, value }: SubscriberFieldsNotifyProps<T, P>) {
    if (!this.subscribers[path]) return

    if (!isUndefined(value?.appendProps)) {
      this.subscribers[path].appendPropsCallback(value.appendProps)
    }

    if (!isUndefined(value?.removeProps)) {
      this.subscribers[path].removePropsCallback(value.removeProps)
    }

    if (!isUndefined(value?.swapProps)) {
      this.subscribers[path].swapPropsCallback(value.swapProps.newIndex, value.swapProps.oldIndex)
    }
  }

  notifyAllSubscribers(data: SubscriberFieldsNotifyProps<T, P>[]) {
    data.forEach((subscriberValue) => {
      this.notifySubscriber(subscriberValue)
    })
  }
}

export type { FieldArrayControlWatcherNotifyValue, SubscriberFieldsNotifyProps }
export { FieldArrayControlUpdateWatcher }
