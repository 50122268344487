import React, { memo, useCallback, useState } from 'react'
import { useFieldArray } from 'react-hook-form'

import { blockValues, defaultStepOfCompensation } from '@components/Forms/CreateStatementOld/const'
import StepCompensation from '@components/Forms/CreateStatementOld/Forms/Compensations/Compensation'
import styles from '@components/Forms/CreateStatementOld/Forms/Stages/StageBlock/StageBlock.module.scss'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { StatementOldFormValues } from '@components/Forms/CreateStatementOld/types'
import Button from '@components/NewDesign/Button'
import CustomTransition from '@components/Transition'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const ExpensesCompensationArrayForm = () => {
  const {
    state: { formInstance },
  } = useCreateStatementManager()

  const [addButtonIsRender, setButtonIsRender] = useState<boolean>(true)

  const {
    fields: compensations,
    append: appendCompensation,
    remove: removeCompensation,
  } = useFieldArray<StatementOldFormValues, 'compensations', 'id'>({
    control: formInstance?.control,
    name: blockValues.compensations,
  })

  const handleAppendCompensation = useCallback(
    (e) => {
      e.preventDefault()

      appendCompensation(defaultStepOfCompensation)
    },
    [appendCompensation],
  )

  const handleRemoveCompensation = useCallback(
    (index: number) => {
      removeCompensation(index)

      setTimeout(() => {
        setButtonIsRender(true)
      }, 0)
    },
    [removeCompensation],
  )

  const handleChangeAddButtonView = (value: boolean) => {
    setButtonIsRender(value)
  }

  if (!formInstance) return null

  return (
    <div className={styles.stages}>
      {compensations.map((stage, index) => {
        return (
          <div key={stage.id} className={styles.stages__item}>
            <StepCompensation
              name={blockValues.compensations}
              positionOfCompensation={index}
              onRemoveCompensation={handleRemoveCompensation}
              onBeforeRemoveCompensation={() => {
                handleChangeAddButtonView(false)
              }}
            />
          </div>
        )
      })}
      <div className={styles.stages__add}>
        <CustomTransition.Fade show={addButtonIsRender}>
          <Button
            leadingIcon={{ src: CircleAddIcon }}
            size={'m'}
            view={'outline'}
            dataTestId="ExpensesCompensationArrayForm-add-button"
            onClick={handleAppendCompensation}
          >
            Добавить
          </Button>
        </CustomTransition.Fade>
      </div>
    </div>
  )
}

export default memo(ExpensesCompensationArrayForm)
