import React, { MutableRefObject, RefObject, useEffect } from 'react'

export type InputMethod = 'keyboard' | 'mouse'

let prevInputMethod: InputMethod

function handleKeyDown(event: KeyboardEvent) {
  if (event.key === 'Tab') {
    prevInputMethod = 'keyboard'
  }
}

function handleMouseDown() {
  prevInputMethod = 'mouse'
}

function handleTouchStart() {
  prevInputMethod = 'mouse'
}

function addGlobalListeners() {
  document.addEventListener('keydown', handleKeyDown)
  document.addEventListener('mousedown', handleMouseDown)
  document.addEventListener('touchstart', handleTouchStart)
}

export function useFocus<T extends HTMLElement>(
  ref: MutableRefObject<T> | RefObject<T>,
  inputMethod?: InputMethod,
): [boolean] {
  const [focus, setFocus] = React.useState(false)

  const handleFocus = React.useCallback(() => {
    if (!inputMethod || inputMethod === prevInputMethod) {
      setFocus(true)
    }
  }, [inputMethod])

  const handleBlur = React.useCallback(() => {
    setFocus(false)
  }, [])

  useEffect(() => {
    const node = ref.current

    if (node) {
      node.addEventListener('focusin', handleFocus)
      node.addEventListener('focusout', handleBlur)
    }

    return () => {
      if (node) {
        node.removeEventListener('focusin', handleFocus)
        node.removeEventListener('focusout', handleBlur)
      }
    }
  }, [handleBlur, handleFocus, ref])

  useEffect(addGlobalListeners, [])

  return [focus]
}
