import React, { FC, memo } from 'react'

import styles from '@components/Attachments/Attachment/FileItem/TrailingNode/FileItemTrailingNode.module.scss'
import Chip from '@components/Chip'
import Icon from '@components/Icon/Icon'
import { documentVersionChangeState } from '@constants/types'
import SignedDocumentIcon from '@icons/SignedDocumentIcon.svg'
import { VersionChangeState } from '@interfaces/documents'
import cn from 'classnames'

interface LeadingAdditionInTrailingNodeProps {
  readOnly: boolean
  versionNumber: number
  isDepartmentResponseSigned?: boolean
  versionChangeState?: VersionChangeState
}

const LeadingAdditionInTrailingNode: FC<LeadingAdditionInTrailingNodeProps> = ({
  isDepartmentResponseSigned = false,
  readOnly,
  versionNumber,
  versionChangeState,
}) => {
  const isDeletedVersionDocument = versionChangeState === 'DELETED'
  const isChangedVersionDocument = versionChangeState === 'CHANGED'
  const isNewVersionDocument = versionChangeState === 'NEW'
  const isFixedVersionDocument = versionChangeState === 'FIXED'

  const showVersionChangeState =
    versionNumber > 1 &&
    !!versionChangeState &&
    !isFixedVersionDocument &&
    !isDeletedVersionDocument &&
    readOnly

  return (
    <>
      {isDepartmentResponseSigned && (
        <Icon
          noCurrentColorSvgFill
          className={styles['trailingNode__icon']}
          src={SignedDocumentIcon}
          size={'initial'}
        />
      )}
      <div
        className={cn(styles.versionState, {
          [styles['versionState--hidden']]: !showVersionChangeState,
        })}
      >
        {showVersionChangeState && (
          <Chip.Base
            priority={'secondary'}
            variant={'success'}
            size={'xs'}
            label={documentVersionChangeState[versionChangeState]}
            className={cn({
              [styles['versionState__chip-new']]: isNewVersionDocument,
              [styles['versionState__chip-changed']]: isChangedVersionDocument,
            })}
            contentClassName={cn({
              [styles['versionState__content-new']]: isNewVersionDocument,
              [styles['versionState__content-changed']]: isChangedVersionDocument,
            })}
          />
        )}
      </div>
    </>
  )
}

export default memo(LeadingAdditionInTrailingNode)
