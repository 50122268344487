import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { ApplicationConclusionAdditionalAgreementFormFormValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/types'

const { getDefaultInitiatorFieldState } = FieldStateHelpers

const mapOfApplicationConclusionAdditionalAgreementFormMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Заявление о заключении дополнительного соглашения' },
]

const defaultApplicationConclusionAdditionalAgreementFormValues: ApplicationConclusionAdditionalAgreementFormFormValues =
  {
    '1': {
      ...getDefaultInitiatorFieldState(),
      initiatorInn: '',
      initiatorOgrn: '',
      initiatorAddress: '',
    },
    '2': {
      ruleLinkExtraReason: '',
    },
  }

const applicationConclusionAdditionalAgreementFormBlockValues = {
  '1': {
    initiatorFullName: '1.initiatorFullName',
    initiatorInn: '1.initiatorInn',
    initiatorOgrn: '1.initiatorOgrn',
    initiatorAddress: '1.initiatorAddress',
    initiatorHeaderName: '1.initiatorHeaderName',
    initiatorHeaderNameGenitive: '1.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '1.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '1.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '1.initiatorHeaderReasonGenitive',
  },
  '2': {
    ruleLinkExtraReason: '2.ruleLinkExtraReason',
  },
} as const

export {
  applicationConclusionAdditionalAgreementFormBlockValues,
  defaultApplicationConclusionAdditionalAgreementFormValues,
  mapOfApplicationConclusionAdditionalAgreementFormMenu,
}
