import React from 'react'

import Section from '@components/Layout/Section'
import Typography from '@components/NewDesign/Typography'
import StepCard from '@routes/Home/StepCard'

import styles from './AgreementInfoSection.module.scss'

const AgreementInfoSection = () => {
  return (
    <Section type="secondary" className={styles.agreementSection}>
      <div className={styles.sectionContainer}>
        <div className={styles.title}>
          <Typography.Headline as={'h2'} variant={'headlineH2'} align="center">
            Как заключить соглашение
            <br />о защите и поощрении капиталовложений
          </Typography.Headline>
        </div>
        <div className={styles.steps}>
          <StepCard type="100" step="1">
            <StepCard.Title>Войдите в личный кабинет и создайте заявление</StepCard.Title>
            <div className="flex flex-col justify-between flex-1">
              <StepCard.Body>
                Заполните форму заявления, загрузите проект соглашения и сопроводительные документы
              </StepCard.Body>
              <StepCard.Bottom>Шаблоны документов доступны для скачивания</StepCard.Bottom>
            </div>
          </StepCard>
          <StepCard type="200" step="2">
            <StepCard.Title>Направьте документы на проверку</StepCard.Title>
            <div className="flex flex-col justify-between flex-1">
              <StepCard.Body>
                Подпишите комплект документов с помощью УКЭП и отправьте его на проверку
              </StepCard.Body>
              <StepCard.Bottom>
                Вы можете отозвать документы для доработки до окончания проверки
              </StepCard.Bottom>
            </div>
          </StepCard>
          <StepCard type="300" step="3">
            <StepCard.Title>Получите заключение о соответствии проекта</StepCard.Title>
            <div className="flex flex-col justify-between flex-1">
              <StepCard.Body>
                Вы получите его после проверки комплекта документов на соответствие 69-ФЗ
                и нормативам
              </StepCard.Body>
              <StepCard.Bottom>До 30 рабочих дней</StepCard.Bottom>
            </div>
          </StepCard>
          <StepCard type="400" step="4">
            <StepCard.Title>Дождитесь подписания органами исполнительной власти</StepCard.Title>
            <div className="flex flex-col justify-between flex-1">
              <StepCard.Body>
                Участники соглашения со стороны государства подпишут соглашение
              </StepCard.Body>
              <StepCard.Bottom>До 10 рабочих дней</StepCard.Bottom>
            </div>
          </StepCard>
          <StepCard type="500" step="5">
            <StepCard.Title>Получите уведомление о регистрации СЗПК</StepCard.Title>
            <div className="flex flex-col justify-between flex-1">
              <StepCard.Body>
                Федеральное казначейство зарегистрирует СЗПК в реестре. После чего оно вступит в
                законную силу
              </StepCard.Body>
              <StepCard.Bottom>До 5 рабочих дней</StepCard.Bottom>
            </div>
          </StepCard>
          <StepCard type="600" step="6">
            <StepCard.Title>Продолжайте взаимодействие</StepCard.Title>
            <div className="flex flex-col justify-between flex-1">
              <StepCard.Body>
                Заключайте дополнительные соглашения к существующим и новые СЗПК по другим проектам
              </StepCard.Body>
              <StepCard.Bottom>В любое время</StepCard.Bottom>
            </div>
          </StepCard>
        </div>
      </div>
    </Section>
  )
}

export default AgreementInfoSection
