import React, { FC, memo, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'
import { useParams } from 'react-router'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import { mapOfRejectTypes } from '@components/NewDesignedModals/RejectSZPKModal/store'
import Radio from '@components/Radio'
import { useCreateDfo } from '@hooks/new/dfo/useCreateDfo'
import { TAllDfoTypes, TDfoTerminationType } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './RejectModal.module.scss'

type RejectSZPKModalProps = PopupProps & {
  projectId: string
  initialRejectTypes?: TDfoTerminationType[]
}

const useRejectSZPKModal = () => {
  const popupManager = usePopupManager()
  const { projectId } = useParams()

  const handleOpenRejectSZPKModal = (initialRejectTypes?: TDfoTerminationType[]) => {
    if (!projectId) return

    popupManager.open(RejectSZPKModal, {
      projectId,
      initialRejectTypes,
    })
  }

  return { handleOpenRejectSZPKModal }
}

const RejectSZPKModal: FC<RejectSZPKModalProps> = ({
  isOpen,
  onClose,
  projectId,
  initialRejectTypes,
}) => {
  const { handleCreateDfo } = useCreateDfo(projectId)

  const { handleOpenLoadingModal } = useLoadingModal()

  const formInstance = useForm({
    defaultValues: {
      typeOfReject: initialRejectTypes?.[0] || '',
    },
  })

  const preparedOnClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  const handleContinueModal = useCallback(async () => {
    const typeOfReject = formInstance.getValues().typeOfReject

    if (!projectId || !typeOfReject) return

    handleOpenLoadingModal()

    try {
      await handleCreateDfo(typeOfReject as TAllDfoTypes)()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          type: typeOfReject,
        },
        componentInfo: {
          componentName: 'RejectSZPKModal',
          componentType: 'handleContinueModal',
          moduleName: 'UiActionsManager',
        },
      })(error)
    }
  }, [formInstance, handleCreateDfo, handleOpenLoadingModal, projectId])

  const modalActions = useMemo(() => {
    const typeOfReject = formInstance.watch('typeOfReject')

    return [
      {
        view: 'gray',
        dataTestId: 'RejectSZPKModal-cancel-button',
        children: 'Отмена',
        onClick: preparedOnClose,
      },
      {
        dataTestId: 'RejectSZPKModal-rejectSZPK-button',
        children: 'Продолжить',
        disabled: !typeOfReject,
        onClick: handleContinueModal,
      },
    ] as ButtonProps[]
  }, [formInstance, handleContinueModal, preparedOnClose])

  return (
    <Modal.Action
      isOpen={isOpen}
      title={'Расторгнуть соглашение?'}
      dataTestId="RejectSZPKModal-modal"
      closeButtonDataTestId="RejectSZPKModal-modal-closeButton"
      actions={modalActions}
      onClose={preparedOnClose}
    >
      <Radio.ControlledGroup
        control={formInstance.control}
        name={'typeOfReject'}
        radioGroupProps={{
          direction: 'vertical',
          className: styles.rejectModal__controls,
        }}
      >
        {initialRejectTypes?.map((type) => {
          return (
            <Radio.Button
              value={type}
              label={
                <>
                  <Typography.Body variant={'bodyMMedium'} className={styles.rejectModal__text}>
                    {mapOfRejectTypes[type]?.title}
                  </Typography.Body>

                  <Typography.Caption
                    variant={'captionSRegular'}
                    className={styles.rejectModal__subtext}
                    color={'text-base-secondary'}
                  >
                    {mapOfRejectTypes[type]?.description}
                  </Typography.Caption>
                </>
              }
            />
          )
        })}
      </Radio.ControlledGroup>
    </Modal.Action>
  )
}

export { useRejectSZPKModal }
export default memo(RejectSZPKModal)
