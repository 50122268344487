import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForFinancialModel = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      startInvestStage: {
        propertyId: objectFromPropertyState.startInvestStage?.propertyId,
        type: objectFromPropertyState.startInvestStage?.type,
        lastUpdateDt: objectFromPropertyState.startInvestStage?.lastUpdateDt,
      },
      endInvestStage: {
        propertyId: objectFromPropertyState.endInvestStage?.propertyId,
        type: objectFromPropertyState.endInvestStage?.type,
        lastUpdateDt: objectFromPropertyState.endInvestStage?.lastUpdateDt,
      },
      startExploitationStage: {
        propertyId: objectFromPropertyState.startExploitationStage?.propertyId,
        type: objectFromPropertyState.startExploitationStage?.type,
        lastUpdateDt: objectFromPropertyState.startExploitationStage?.lastUpdateDt,
      },
      projectCapacityYear: {
        propertyId: objectFromPropertyState.projectCapacityYear?.propertyId,
        type: objectFromPropertyState.projectCapacityYear?.type,
        lastUpdateDt: objectFromPropertyState.projectCapacityYear?.lastUpdateDt,
      },
    },
    '2': {
      investmentYears: {
        propertyId: objectFromPropertyState.investments?.propertyId,
        type: objectFromPropertyState.investments?.type,
        lastUpdateDt: objectFromPropertyState.investments?.lastUpdateDt,
        value: isArray(objectFromPropertyState.investments?.value)
          ? objectFromPropertyState.investments?.value
              .sort((investmentA, investmentB) => investmentA.order - investmentB.order)
              .map((investment) => ({
                propertyId: investment.propertyId,
                type: investment.type,
                lastUpdateDt: investment.lastUpdateDt,
                value: {
                  investmentValue: {
                    propertyId: investment.value.investmentYearValue?.propertyId,
                    type: investment.value.investmentYearValue?.type,
                    lastUpdateDt: investment.value.investmentYearValue?.lastUpdateDt,
                  },
                },
              }))
          : [],
      },
      investmentFederalBudget: {
        propertyId: objectFromPropertyState.investmentFederalBudget?.propertyId,
        type: objectFromPropertyState.investmentFederalBudget?.type,
        lastUpdateDt: objectFromPropertyState.investmentFederalBudget?.lastUpdateDt,
      },
      investmentRegionalBudget: {
        propertyId: objectFromPropertyState.investmentRegionalBudget?.propertyId,
        type: objectFromPropertyState.investmentRegionalBudget?.type,
        lastUpdateDt: objectFromPropertyState.investmentRegionalBudget?.lastUpdateDt,
      },
      investmentMunicipalBudget: {
        propertyId: objectFromPropertyState.investmentMunicipalBudget?.propertyId,
        type: objectFromPropertyState.investmentMunicipalBudget?.type,
        lastUpdateDt: objectFromPropertyState.investmentMunicipalBudget?.lastUpdateDt,
      },
      investmentInvestorFinance: {
        propertyId: objectFromPropertyState.investmentInvestorFinance?.propertyId,
        type: objectFromPropertyState.investmentInvestorFinance?.type,
        lastUpdateDt: objectFromPropertyState.investmentInvestorFinance?.lastUpdateDt,
      },
      investmentBankCredits: {
        propertyId: objectFromPropertyState.investmentBankCredits?.propertyId,
        type: objectFromPropertyState.investmentBankCredits?.type,
        lastUpdateDt: objectFromPropertyState.investmentBankCredits?.lastUpdateDt,
      },
      investmentOtherRaiseFinance: {
        propertyId: objectFromPropertyState.investmentOtherRaiseFinance?.propertyId,
        type: objectFromPropertyState.investmentOtherRaiseFinance?.type,
        lastUpdateDt: objectFromPropertyState.investmentOtherRaiseFinance?.lastUpdateDt,
      },
    },
    '3': {
      netPresentValue: {
        propertyId: objectFromPropertyState.netPresentValue?.propertyId,
        type: objectFromPropertyState.netPresentValue?.type,
        lastUpdateDt: objectFromPropertyState.netPresentValue?.lastUpdateDt,
      },
      internalRateReturn: {
        propertyId: objectFromPropertyState.internalRateReturn?.propertyId,
        type: objectFromPropertyState.internalRateReturn?.type,
        lastUpdateDt: objectFromPropertyState.internalRateReturn?.lastUpdateDt,
      },
    },
    '4': {
      stageTaxYears: {
        propertyId: objectFromPropertyState.stageTax?.propertyId,
        type: objectFromPropertyState.stageTax?.type,
        lastUpdateDt: objectFromPropertyState.stageTax?.lastUpdateDt,
        value: isArray(objectFromPropertyState.stageTax?.value)
          ? objectFromPropertyState.stageTax?.value
              .sort((taxA, taxB) => taxA.order - taxB.order)
              .map((tax) => ({
                propertyId: tax.propertyId,
                type: tax.type,
                lastUpdateDt: tax.lastUpdateDt,
                value: {
                  stageTaxYearValue: {
                    propertyId: tax.value.taxYearValue?.propertyId,
                    type: tax.value.taxYearValue?.type,
                    lastUpdateDt: tax.value.taxYearValue?.lastUpdateDt,
                  },
                },
              }))
          : [],
      },
    },
  }
}

export { generatePropertiesObjectForFinancialModel }
