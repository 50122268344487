import { useEffect, useRef } from 'react'

export const usePreviousTrueValue = (value: any) => {
  const prevValue = useRef()

  useEffect(() => {
    if (!!value) prevValue.current = value
  }, [value])

  return prevValue.current
}
