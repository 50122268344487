import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface NotificationOfIdentifiedNPAListErrorsFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const NotificationOfIdentifiedNPAListErrorsForms: NotificationOfIdentifiedNPAListErrorsFormsProps =
  () => null

NotificationOfIdentifiedNPAListErrorsForms.First = First
NotificationOfIdentifiedNPAListErrorsForms.Second = Second
NotificationOfIdentifiedNPAListErrorsForms.Third = Third
NotificationOfIdentifiedNPAListErrorsForms.Fourth = Fourth

export default NotificationOfIdentifiedNPAListErrorsForms
