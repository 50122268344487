import { generatePropertiesObjectForNotificationFillingAppConcludingForm } from '@components/Forms/NotificationFillingAppConcludingForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNotificationFillingAppConcludingForm } from '@components/Forms/NotificationFillingAppConcludingForm/adapters/RHF.adapter'
import { NotificationFillingAppConcludingFormValues } from '@components/Forms/NotificationFillingAppConcludingForm/types'

const useNotificationFillingAppConcludingAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NotificationFillingAppConcludingFormValues =>
    generateRHFObjectForNotificationFillingAppConcludingForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNotificationFillingAppConcludingForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNotificationFillingAppConcludingAdapters }
