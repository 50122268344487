import React, { FC, useRef } from 'react'
import { Path, UseFormReturn } from 'react-hook-form'

import { ControlledMultipleSelect } from '@components/NewDesign/Select'
import { MembersFormValues } from '@components/NewDesignedModals/AddSolicitationMembersModal'
import styles from '@components/NewDesignedModals/CreateMembersModal/CreateMembersModal.module.scss'
import { useOrganizationMembers } from '@components/Projects/[id]/ParticipantsOfStatement/hooks'
import { useAPIContext } from '@context/APIContext'
import { isString } from '@helpers/checkTypes'
import { IPostAttributeBody } from '@services/Attribute/Attribute.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

export interface ChooseMembersStepProps {
  projectId: string

  subjects: string[]
  postedAttributes: string[]

  formInstance: UseFormReturn<MembersFormValues, any>
}

const ChooseMembersStep: FC<ChooseMembersStepProps> = ({ formInstance, projectId }) => {
  const {
    projectsApi: { changeProjectAttribute },
  } = useAPIContext()

  const { preparedOrganizationsForSelect, onSelectContainerScroll } = useOrganizationMembers()

  const { control, watch, setValue } = formInstance

  const formValues: MembersFormValues['chooseMembers'] = watch('chooseMembers')

  const previousFormValues = useRef<MembersFormValues['chooseMembers']>(formValues)

  const handleSetAttribute = (name: IPostAttributeBody['name']) => async () => {
    const formName = `chooseMembers.${name}` as Path<MembersFormValues>

    const value = watch(formName)

    try {
      await changeProjectAttribute(projectId, {
        name,
        value: isString(value) ? value : JSON.stringify(value),
      })

      previousFormValues.current = { ...previousFormValues.current, [formName]: value }
    } catch (error) {
      const additionInfo = {
        projectAttributeName: name,
        projectAttributeValue: value,
      }

      LoggerHelpersService.handleMultipleLogError({
        additionInfo,
        componentInfo: {
          componentName: 'ChooseMembersStep',
          moduleName: 'AddSolicitationMembersModal',
          componentType: 'chooseMembers',
        },
      })(error)

      setValue(formName, previousFormValues.current[formName])

      throw error
    }
  }

  return (
    <div className={styles.createMembersModal__wrapper}>
      <div className={styles.subject}>
        <ControlledMultipleSelect
          withContextSearch
          closeOptionsAfterOnChange
          options={preparedOrganizationsForSelect}
          popoverProps={{
            zIndex: 51,
          }}
          controllerProps={{
            name: 'chooseMembers.subjects',
            control,
          }}
          inputProps={{
            fixWidth: true,
            label: 'Субъект(-ы) РФ, на территории которого(-ых) реализуется проект',
            view: 'secondary',
            dataTestId: 'ChooseMembersStep-chooseMembers.subjects-multipleSelect',
            formControlDataTestId: 'ChooseMembersStep-chooseMembers.subjectsFormControl',
          }}
          chipProps={{
            dataTestId: 'ChooseMembersStep-chooseMembers.subjects-chip',
            iconDataTestId: 'ChooseMembersStep-chooseMembers.subjects-remove-chip',
          }}
          optionsContainer={{
            onScroll: onSelectContainerScroll,
          }}
          optionsProps={{
            dataTestId: 'ChooseMembersStep-chooseMembers.subjects-optionsContainer',
          }}
          onChangeForm={handleSetAttribute('subjects')}
        />
      </div>
    </div>
  )
}

export default ChooseMembersStep
