import React from 'react'
import { useFormContext } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import { ninthSectionRegistrationCertificateValidationMap } from '@components/Forms/RegistrationCertificateForm/Forms/9/validation'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Ninth = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const formModifierInstance = useFormModifierContext<RegistrationCertificateFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue, handleChangePublicValue },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { getFormFieldControlProps, getTextareaProps } = useFormComponentPresets({
    isNew: true,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['9'].infoMonitoringReport,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['9'].infoMonitoringReport,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['9'].infoMonitoringReport,
                  rules: ninthSectionRegistrationCertificateValidationMap.infoMonitoringReport,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label:
                      'Информация о представленных уполномоченной организацией и уполномоченным органом субъекта РФ отчетах',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        registrationCertificateBlockValues['9'].infoMonitoringReport,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['9'].infoMonitoringReport,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['9'].infoMonitoringResult,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['9'].infoMonitoringResult,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['9'].infoMonitoringResult,
                  rules: ninthSectionRegistrationCertificateValidationMap.infoMonitoringResult,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label: 'Информация о наличии оснований для расторжения соглашения',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        registrationCertificateBlockValues['9'].infoMonitoringResult,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['9'].infoMonitoringResult,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Ninth
