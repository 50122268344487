import React, { memo, useCallback, useMemo } from 'react'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import Chip from '@components/Chip'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMultipleSelect } from '@components/NewDesign/Select'
import Typography from '@components/NewDesign/Typography'
import FilterTooltip from '@components/PreparedTooltips/Filter'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import { useChooseNpaManagerOld, ViewModalType } from '@components/RegistryNpa/ChooseOld/ManagerOld'
import styles from '@components/RegistryNpa/ChooseOld/ModalOld/ModalOld.module.scss'
import { TFiltersModalFormValues } from '@components/RegistryNpa/Header/Filters'
import { useReusableFiltersTooltipLogicOld } from '@components/RegistryNpa/hooks/useReusableFiltersTooltipLogicOld'
import { getCountOfActiveFiltersOld } from '@components/RegistryNpa/utils'
import TagItem from '@components/TagNew/Item'
import SortSmallIcon from '@icons/SortSmallIcon.svg'
import cn from 'classnames'

import filtersStyles from './FiltersOld.module.scss'

const NpaModalFiltersOld = () => {
  const {
    state: {
      filtersInstance,
      forSelect: { directionOptions, typeOptions, regionOptions },
      npaReducer: [state],
      viewMode,
    },
    handlers: { handleApplyFilters, resetMasterNpaCollapseStates, handleChangeViewMode },
  } = useChooseNpaManagerOld()

  const handleApplyNpaModalFilters = useCallback(
    (formValues: TFiltersModalFormValues) => {
      const direction = filtersInstance?.getValues('filters.direction')

      handleApplyFilters?.({ ...formValues, direction })

      resetMasterNpaCollapseStates?.()
    },
    [filtersInstance, handleApplyFilters, resetMasterNpaCollapseStates],
  )

  const {
    control,
    tooltipIsOpen,
    handleOpenTooltip,
    handleCloseTooltip,
    handleCloseFilters,
    handleResetButton,
    handleApplyButton,
    isRegional,
    isFederal,
    isMunicipal,
  } = useReusableFiltersTooltipLogicOld({
    handleApplyFilters: handleApplyNpaModalFilters,
    initialFilters: {
      isFederal: filtersInstance?.watch('filters.isFederal'),
      isRegional: filtersInstance?.watch('filters.isRegional'),
      isMunicipal: filtersInstance?.watch('filters.isMunicipal'),
      direction: filtersInstance?.watch('filters.direction'),
      part: filtersInstance?.watch('filters.part'),
      type: filtersInstance?.watch('filters.type'),
      region: filtersInstance?.watch('filters.region'),
    },
  })

  const allFilters = filtersInstance?.watch('filters')

  const countOfActiveFilters = useMemo(() => {
    if (!allFilters) return

    const { direction, ...restFilters } = allFilters

    return getCountOfActiveFiltersOld(restFilters)
  }, [allFilters])

  const filtersTooltipContent = useMemo(
    () =>
      filtersInstance && (
        <>
          <Typography.Body
            className={styles['filters__checkboxes-container']}
            variant={'bodyMMedium'}
            color={'text-base-tertiary'}
          >
            Уровень акта
          </Typography.Body>
          <div
            className={cn(filtersStyles.filters__checkboxes, [
              filtersStyles['filters__checkboxes-container'],
            ])}
          >
            <ControlledCheckbox
              name={'isFederal'}
              control={control}
              checkBoxProps={{
                dataTestId: 'NpaModalFilters-isFederal-checkbox',
                disabled: !isRegional,
                label: 'Федеральный',
              }}
            />
            <ControlledCheckbox
              name={'isRegional'}
              control={control}
              checkBoxProps={{
                dataTestId: 'NpaModalFilters-isRegional-checkbox',
                disabled: !isFederal,
                label: 'Региональный',
              }}
            />
          </div>
          <div className={filtersStyles.filters__control}>
            <ControlledMultipleSelect
              closeOptionsAfterOnChange
              options={typeOptions}
              controllerProps={{
                name: 'type',
                control: control,
              }}
              inputProps={{
                dataTestId: 'NpaModalFilters-type-multipleSelect',
                label: 'Вид акта',
                fixWidth: true,
              }}
            />
          </div>
          <div className={filtersStyles.filters__control}>
            <ControlledInput
              name={'part'}
              control={control}
              inputProps={{
                dataTestId: 'NpaModalFilters-part-input',
                view: 'secondary',
                label: 'Положение',
                fixWidth: true,
              }}
            />
          </div>
          <div className={filtersStyles.filters__control}>
            <ControlledMultipleSelect
              closeOptionsAfterOnChange
              withContextSearch
              options={regionOptions}
              controllerProps={{
                name: 'region',
                control: control,
              }}
              inputProps={{
                dataTestId: 'NpaModalFilters-region-multipleSelect',
                label: 'Регион применения акта',
                fixWidth: true,
              }}
            />
          </div>
        </>
      ),
    [filtersInstance, control, isRegional, isFederal, typeOptions, regionOptions],
  )

  const preparedHandleChangeViewMode = (mode: ViewModalType) => () => {
    handleChangeViewMode?.(mode)
  }

  if (!filtersInstance) return null

  return (
    <>
      <Chip.Base
        label={`Выбрано: ${state.chosenParts.length}`}
        variant={'brand'}
        className={cn(styles.registryNpaModal__chosenButton, {
          [filtersStyles['filters__chosenButton--chosen']]: viewMode === 'chosen',
        })}
        onClick={preparedHandleChangeViewMode('chosen')}
      />
      <ControlledRadioGroup
        name="filters.direction"
        control={filtersInstance?.control}
        radioGroupProps={{
          className: styles.registryNpaModal__filters,
          disableActiveState: viewMode === 'chosen',
          type: 'tag',
          direction: 'horizontal',
        }}
        onChange={preparedHandleChangeViewMode('default')}
      >
        <TagItem className={styles.tag} value={''}>
          Все
        </TagItem>
        {directionOptions.map((option, index) => (
          <TagItem key={index} className={styles.tag} value={option.value}>
            {option.displayValue}
          </TagItem>
        ))}
      </ControlledRadioGroup>
      <FilterTooltip
        filterContent={filtersTooltipContent}
        manualControl={{
          handleCloseTooltip,
          tooltipIsOpen,
        }}
        tooltipProps={{
          zIndex: 51,
          offset: [0, 6],
        }}
        onApplyFilters={handleApplyButton}
        onResetFilters={handleResetButton}
        onClose={handleCloseFilters}
      >
        <button
          type="button"
          className={styles.registryNpaModal__filterButton}
          data-testid="NpaModalFilters-filterTooltip-button"
          onClick={handleOpenTooltip}
        >
          {!countOfActiveFilters ? (
            <IconButton
              geometry={'round'}
              view={'tined'}
              size={'m'}
              dataTestId="NpaModalFilters-iconButton"
              icon={{
                src: SortSmallIcon,
                noCurrentColorSvgFill: true,
              }}
            />
          ) : (
            <Button
              className={styles['registryNpaModal__filterButton--active']}
              geometry={'round'}
              view={'tined'}
              dataTestId="NpaModalFilters-countButton"
              size={'m'}
            >
              {countOfActiveFilters}
            </Button>
          )}
        </button>
      </FilterTooltip>
    </>
  )
}

export default memo(NpaModalFiltersOld)
