import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { ApplicationConclusionAdditionalAgreementFormFormValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/types'

const generateRHFObjectForApplicationConclusionAdditionalAgreementFormForm = (
  objectFromPropertyState: Record<string, any>,
): ApplicationConclusionAdditionalAgreementFormFormValues => {
  return {
    '1': RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '2': {
      ruleLinkExtraReason: objectFromPropertyState.ruleLinkExtraReason.value || '',
    },
  }
}

export { generateRHFObjectForApplicationConclusionAdditionalAgreementFormForm }
