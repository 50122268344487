import type { RegistrationSignModalFormValues } from './types'

const registrationSignModalDefaultValues: RegistrationSignModalFormValues = {
  registeredNumber: '',
  registeredDate: '',
}

const registrationSignModalFormNames = {
  registeredNumber: 'registeredNumber',
  registeredDate: 'registeredDate',
} as const

const mapOfRejectInfo = {
  SZPK_TERMINATE: {
    title: 'Внесение в реестр доп. соглашения о расторжении',
    description:
      'Пожалуйста, укажите регистрационный номер доп. соглашения о расторжении по соглашению сторон и дату внесения в реестр',
    disabledNumberInput: false,
  },
  SZPK_TERMINATE_ONE_SIDE: {
    title: 'Внесение в реестр доп. соглашения о расторжении',
    description:
      'Пожалуйста, укажите регистрационный номер уведомления о расторжении в одностороннем порядке и дату внесения в реестр',
    disabledNumberInput: false,
  },
  SZPK_TERMINATE_JUDGMENT: {
    title: 'Внесение в реестр изменений о расторжении',
    description: 'Пожалуйста, укажите дату прекращения действия СЗПК, не подлежащую обжалованию',
    disabledNumberInput: true,
  },
} as const

export { mapOfRejectInfo, registrationSignModalDefaultValues, registrationSignModalFormNames }
