import { DfosStage } from '@constants/types'
import type {
  StatementFilterFormValues,
  StatementSortFormValues,
  TFormStatement,
} from '@routes/NewStatements/types'

const statementFiltersDefaultValues: StatementFilterFormValues = {
  actionAllowed: 'all',
  statementLvl: 'all',
  stage: 'all',
  isRegistered: false,
  dfoStageExclude: [
    DfosStage.DRAFT,
    DfosStage.INITIATOR_REVOKED,
    DfosStage.INITIATOR_REVOKED_NOTICE,
    DfosStage.REJECTED,
    DfosStage.PARTNER_REVOKED,
    DfosStage.PARTNER_REJECTED,
    DfosStage.STOPPED,
    DfosStage.FIRST_SIGNER_REJECTED,
    DfosStage.FIRST_CHECKER_REJECTED,
  ],
  dfoStageInclude: null,
}

const mapOfBaskets = {
  all: 'all',
  concluded: 'concluded',
  signing: 'signing',
  consideration: 'consideration',
  drafts: 'drafts',
  archive: 'archive',
}

const mapOfActionAllowed = {
  all: 'all',
  needAttention: 'needAttention',
} as const

const mapOfStageStatementFilters = {
  all: 'all',
  ACTIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
  CHECKING: 'CHECKING',
  DRAFT: 'DRAFT',
  SIGNING: 'SIGNING',
  ARCHIVE: 'ARCHIVE',
} as const

const mapOfStatementLvl = {
  all: 'all',
  federal: 'federal',
  regional: 'regional',
} as const

const mapOfFilterTooltipNames = {
  actionAllowed: 'actionAllowed',
  statementLvl: 'statementLvl',
  stage: 'stage',
  isRegistered: 'isRegistered',
  dfoStageExclude: 'dfoStageExclude',
} as const

const mapOfSortTooltipNames = {
  filterActions: 'filterActions',
  sortActions: 'sortActions',
} as const
const mapOfStatementFilterActions = {
  initial: 'initial',
  actionAllowed: 'actionAllowed',
  dfoUpdate: 'dfoUpdate',
  investmentSum: 'investmentSum',
  deadline: 'deadlineOverdueDate',
} as const

const mapOfStatementSortActions = {
  desc: 'desc',
  asc: 'asc',
} as const

const mapOfDefaultSortActionsByFilterActions = {
  [mapOfStatementFilterActions.deadline]: mapOfStatementSortActions.asc,
  [mapOfStatementFilterActions.investmentSum]: mapOfStatementSortActions.desc,
}

const statementSortDefaultValues: StatementSortFormValues = {
  filterActions: mapOfStatementFilterActions.initial,
  sortActions: mapOfStatementSortActions.desc,
} as const

const statementTableFormNames = {
  tagRadioGroup: 'tagRadioGroup',
  basketTagGroup: 'basketTagGroup',
  searchString: 'searchString',
  sortState: 'sortState',
  filtersState: 'filtersState',
} as const

const statementDefaultFormValues: TFormStatement = {
  tagRadioGroup: 'all',
  basketTagGroup: 'all',
  searchString: '',
  sortState: statementSortDefaultValues,
  filtersState: statementFiltersDefaultValues,
} as const

const statementBasketsDefaultOptions = [
  {
    title: 'Все соглашения',
    id: mapOfBaskets.all,
  },
  {
    title: 'Заключены',
    id: mapOfBaskets.concluded,
  },
  {
    title: 'На подписании',
    id: mapOfBaskets.signing,
  },
  {
    title: 'На рассмотрении',
    id: mapOfBaskets.consideration,
  },
  {
    title: 'Черновики',
    id: mapOfBaskets.drafts,
  },
  {
    title: 'Архив',
    id: mapOfBaskets.archive,
  },
]

export {
  mapOfActionAllowed,
  mapOfBaskets,
  mapOfDefaultSortActionsByFilterActions,
  mapOfFilterTooltipNames,
  mapOfSortTooltipNames,
  mapOfStageStatementFilters,
  mapOfStatementFilterActions,
  mapOfStatementLvl,
  mapOfStatementSortActions,
  statementBasketsDefaultOptions,
  statementDefaultFormValues,
  statementFiltersDefaultValues,
  statementSortDefaultValues,
  statementTableFormNames,
}
