import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  IConstructionPermission,
  ProjectSZPKFormValues,
} from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const ePermissionsProjectSZPKValidationMap: FormValuesValidationSection<
  keyof IConstructionPermission,
  ProjectSZPKFormValues
> = {
  permissionKind: {
    required: defaultRHFValidation.required,
  },
  permissionOrganization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  permissionNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  receivedPermissionDate: {
    required: defaultRHFValidation.required,
  },
}

export { ePermissionsProjectSZPKValidationMap }
