import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { summaryReportOfMonitoringResultsRegionFederalReportValidationMap } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/5/RegionFederalReport/validation'
import { SummaryReportOfMonitoringResultsFederalRegionsArrayPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/5/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import { SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface SummaryReportOfMonitoringResultsRegionFederalReportProps {
  id: string
  name: SummaryReportOfMonitoringResultsFederalRegionsArrayPathName
  formInstance: UseFormReturn<SummaryReportOfMonitoringResultsFormValues>
}

const SummaryReportOfMonitoringResultsRegionFederalReport: FC<
  SummaryReportOfMonitoringResultsRegionFederalReportProps
> = ({ id, name, formInstance }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const totalCapitalRegionFederalReportRegionDeviation = useWatch({
    name: `${name}.totalCapitalRegionFederalReportRegionDeviation`,
    control: formInstance?.control,
  })

  const investorCapitalRegionFederalReportRegionDeviation = useWatch({
    name: `${name}.investorCapitalRegionFederalReportRegionDeviation`,
    control: formInstance?.control,
  })

  const newWorkPlacesRegionFederalReportRegionDeviation = useWatch({
    name: `${name}.newWorkPlacesRegionFederalReportRegionDeviation`,
    control: formInstance?.control,
  })

  const { getSubscribableControlProps, getTextareaProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
    })

  return (
    <FieldView.StepTemplate id={id}>
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.regionName`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${name}.regionName`,
                    rules:
                      summaryReportOfMonitoringResultsRegionFederalReportValidationMap.regionName,
                    textareaProps: {
                      disabled: true,
                      label: 'Наименование субъекта Российской Федерации',
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>

          <Group disableBottomBorder title={'Количество проектов'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Количество по региональным отчетам'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.countRegionFederalReport`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.countRegionFederalReport`,
                      inputProps: {
                        disabled: true,
                        suffix: '',
                        integersOnly: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Количество для переноса в отчет'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.countRegionFederalReportCorrect`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.countRegionFederalReportCorrect`,
                      rules:
                        summaryReportOfMonitoringResultsRegionFederalReportValidationMap.countRegionFederalReportCorrect,
                      inputProps: {
                        suffix: '',
                        integersOnly: true,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.countRegionFederalReportCorrect`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.countRegionFederalReportCorrect`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>

          <Group disableBottomBorder title={'Объем капитальных вложений'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Объем по справкам проверяющей организации'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.totalCapitalRegionFederalReportChecker`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.totalCapitalRegionFederalReportChecker`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Объем по региональным отчетам'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.totalCapitalRegionFederalReportRegion`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.totalCapitalRegionFederalReportRegion`,
                      inputProps: {
                        disabled: true,
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={totalCapitalRegionFederalReportRegionDeviation}
                          />
                        ),
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Объем для переноса в отчет'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.totalCapitalRegionFederalReportCorrect`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.totalCapitalRegionFederalReportCorrect`,
                      rules:
                        summaryReportOfMonitoringResultsRegionFederalReportValidationMap.totalCapitalRegionFederalReportCorrect,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.totalCapitalRegionFederalReportCorrect`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${name}.totalCapitalRegionFederalReportCorrect`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
          <Group disableBottomBorder title={'Объем капиталовложений'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Объем по справкам проверяющей организации'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.investorCapitalRegionFederalReportChecker`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.investorCapitalRegionFederalReportChecker`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Объем по региональным отчетам'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.investorCapitalRegionFederalReportRegion`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.investorCapitalRegionFederalReportRegion`,
                      inputProps: {
                        disabled: true,
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={investorCapitalRegionFederalReportRegionDeviation}
                          />
                        ),
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Объем для переноса в отчет'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.investorCapitalRegionFederalReportCorrect`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.investorCapitalRegionFederalReportCorrect`,
                      rules:
                        summaryReportOfMonitoringResultsRegionFederalReportValidationMap.investorCapitalRegionFederalReportCorrect,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.investorCapitalRegionFederalReportCorrect`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${name}.investorCapitalRegionFederalReportCorrect`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
          <Group disableBottomBorder title={'Количество рабочих мест'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Количество по справкам проверяющей организации'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.newWorkPlacesRegionFederalReportChecker`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.newWorkPlacesRegionFederalReportChecker`,
                      inputProps: {
                        disabled: true,
                        suffix: '',
                        integersOnly: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Количество по региональным отчетам'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.newWorkPlacesRegionFederalReportRegion`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.newWorkPlacesRegionFederalReportRegion`,
                      inputProps: {
                        disabled: true,
                        suffix: '',
                        integersOnly: true,
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={newWorkPlacesRegionFederalReportRegionDeviation}
                          />
                        ),
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Количество для переноса в отчет'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.newWorkPlacesRegionFederalReportCorrect`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.newWorkPlacesRegionFederalReportCorrect`,
                      rules:
                        summaryReportOfMonitoringResultsRegionFederalReportValidationMap.newWorkPlacesRegionFederalReportCorrect,
                      inputProps: {
                        suffix: '',
                        integersOnly: true,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.newWorkPlacesRegionFederalReportCorrect`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${name}.newWorkPlacesRegionFederalReportCorrect`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default SummaryReportOfMonitoringResultsRegionFederalReport
