import React from 'react'
import { useFormContext } from 'react-hook-form'

import MunicipalitiesSection from '@components/DocumentFormComponents/FormSections/MunicipalitiesSection'
import { aATransferRightsAndObligationsBlockValues } from '@components/Forms/AATransferRightsAndObligations/const'
import { useAATransferRightsAndObligationsManager } from '@components/Forms/AATransferRightsAndObligations/Manager'
import { AATransferRightsAndObligationsFormValues } from '@components/Forms/AATransferRightsAndObligations/types'
import {
  AATransferRightsAndObligationsFieldArrayControlUpdateWatcher,
  AATransferRightsAndObligationsFieldsControlUpdateWatcher,
} from '@components/Forms/AATransferRightsAndObligations/watcher'

const Third = () => {
  const formInstance = useFormContext<AATransferRightsAndObligationsFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAATransferRightsAndObligationsManager()

  if (!formInstance) return null

  return (
    <MunicipalitiesSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={aATransferRightsAndObligationsBlockValues['3'].municipalities}
      formInstance={formInstance}
      watcher={AATransferRightsAndObligationsFieldsControlUpdateWatcher}
      fieldArrayWatcher={AATransferRightsAndObligationsFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Third
