import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { CertificateApplicationConclusionOfAARequirementsFormValues } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/types'

const generateRHFObjectForCertificateApplicationConclusionOfAARequirementsForm = (
  objectFromPropertyState: Record<string, any>,
): CertificateApplicationConclusionOfAARequirementsFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: objectFromPropertyState.investorFullNameGenitive?.value || '',
    },
    '4': {
      projectName: objectFromPropertyState.projectName.value || '',
    },
  }
}

export { generateRHFObjectForCertificateApplicationConclusionOfAARequirementsForm }
