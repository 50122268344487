import React from 'react'

import { Skeleton } from '@components/Skeleton/Skeleton'

import styles from './StandardDfoLayoutLoader.module.scss'

const COUNT_OF_PACKDOCS = 2
const COUNT_OF_DOCUMENTS_INPACK = 3

const StandardDfoLayoutLoader = () => {
  return (
    <div className={styles.layoutLoader}>
      <Skeleton visible animate className={styles.layoutLoader__header} />
      <div className={styles.layoutLoader__sets}>
        {Array(COUNT_OF_PACKDOCS)
          .fill(0)
          .map((_, index) => (
            <React.Fragment key={index}>
              <Skeleton visible animate key={index} className={styles.layoutLoader__title} />
              <div className={styles.layoutLoader__documents}>
                {Array(COUNT_OF_DOCUMENTS_INPACK)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton
                      animate
                      visible
                      key={index}
                      className={styles['layoutLoader__documents-item']}
                    />
                  ))}
              </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

export default StandardDfoLayoutLoader
