import React, { memo, useCallback, useMemo, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Loader from '@components/Loader'
import { getFullClassification } from '@components/NewDesign/InputSearchTree/InputSearchTree'
import Typography from '@components/NewDesign/Typography'
import DirectionItemOld from '@components/RegistryNpa/ChooseOld/Items/DirectionItemOld'
import MasterNpaItemOld from '@components/RegistryNpa/ChooseOld/Items/MasterNpaItemOld'
import PartNpaItemOld, {
  PartFormValues,
} from '@components/RegistryNpa/ChooseOld/Items/PartINpaItemOld'
import { useChooseNpaManagerOld } from '@components/RegistryNpa/ChooseOld/ManagerOld'
import { NPAModalActionKindOld } from '@components/RegistryNpa/ChooseOld/ManagerOld/constants'
import type { ChosenPart } from '@components/RegistryNpa/ChooseOld/ManagerOld/types'
import styles from '@components/RegistryNpa/Grid/Grid.module.scss'
import env from '@config'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import { INpaItem } from '@services/NPA/NPA.entity'
import NPAService from '@services/NPA/NPA.service'

const { buildThreeFromChosen } = NPAService

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

const DEFAULT_MASTER_ITEM_HEIGHT = 61

const NpaModalGridOld = () => {
  const {
    state: {
      filtersInstance,
      gridSwrInstance,
      debouncedSearchValue,
      viewMode,
      npaReducer: [state, dispatch],
      masterNpaCollapseStatesMap,
    },
    handlers: { handleChangeMasterNpaCollapseStates },
  } = useChooseNpaManagerOld()

  const mainDirection = filtersInstance?.watch('filters.direction')

  const { mapOfNpaDirections } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const { partClassificationInObjectMap, partClassifications } = useNpaPartClassification({
    key: {
      _key: 'partClassification',
    },
  })

  const [searchPlaceholderCondition, setSearchPlaceholderCondition] = useState<boolean>(false)

  useUpdateEffect(() => {
    setSearchPlaceholderCondition(false)

    if (debouncedSearchValue.length && !state.threeToRender?.length)
      setSearchPlaceholderCondition(true)
  }, [debouncedSearchValue, state.threeToRender])

  useUpdateEffect(() => {
    if (viewMode === 'default') return

    const data = buildThreeFromChosen({
      npas: state.normalizedData.npas,
      chosenParts: state.chosenParts,
      parts: state.normalizedData.parts,
      directions: state.normalizedData.directions,
      mapOfClassificationPart: partClassificationInObjectMap,
    })

    dispatch({
      type: NPAModalActionKindOld.SETUP_CHOSEN_THREE,
      payload: data,
    })
  }, [viewMode])

  const noDataCondition = useMemo(() => {
    return (
      !gridSwrInstance?.error &&
      !gridSwrInstance?.isNpaListLoading &&
      !state.threeToRender?.length &&
      !debouncedSearchValue.length
    )
  }, [
    debouncedSearchValue.length,
    gridSwrInstance?.error,
    gridSwrInstance?.isNpaListLoading,
    state.threeToRender?.length,
  ])

  const handleChoosePart = useCallback(
    (part: Omit<ChosenPart, 'partCorrection' | 'justification'>) => () => {
      dispatch({
        type: NPAModalActionKindOld.CHANGE_CHOSEN,
        payload: {
          ...part,
          partCorrection: '',
          justification: '',
        },
      })
    },
    [],
  )

  const handleChangePart = useCallback(
    (part: Omit<ChosenPart, 'partCorrection' | 'justification'>) =>
      ({ partCorrection, justification }: PartFormValues) => {
        dispatch({
          type: NPAModalActionKindOld.CHANGE_CHOSEN_DATA,
          payload: { ...part, partCorrection, justification },
        })
      },
    [],
  )

  const classificationHeader = useCallback(
    (part: INpaItem) =>
      getFullClassification({
        partClassifications: partClassifications ?? [],
        value: part.classificationId,
      }),
    [partClassifications],
  )

  const chosenMap = useMemo(() => {
    return state.chosenParts.reduce((prevValue, nextValue) => {
      return {
        ...prevValue,
        [nextValue.partId]: {
          justification: nextValue.justification,
          partCorrection: nextValue.partCorrection,
        },
      }
    }, {})
  }, [state.chosenParts, viewMode, mainDirection])

  const renderChosenThree = useMemo(() => {
    return state.chosenThree.map((direction, index) => {
      const directionObject = state.chosenPartsError?.get(direction.direction)

      return (
        <DirectionItemOld
          key={index}
          direction={direction.direction}
          initialCollapseState={!!directionObject}
          investmentSum={direction.minInvestmentAmount}
          type={mapOfNpaDirections?.[direction.direction] || ''}
        >
          {direction.npas.map((npa) => {
            const errorsOfNpa = directionObject?.[npa.id]
            const initialCollapseMasterState = errorsOfNpa && !!Object.keys(errorsOfNpa).length

            return (
              <MasterNpaItemOld
                key={npa.id}
                npa={npa}
                initialCollapseState={
                  initialCollapseMasterState || masterNpaCollapseStatesMap?.current?.[npa.id]
                }
                onUpdateCollapseState={handleChangeMasterNpaCollapseStates}
              >
                {npa.parts.map((part) => {
                  const currentPartError = errorsOfNpa?.[part.id]

                  return (
                    <PartNpaItemOld
                      key={part.id}
                      initialChoseErrors={currentPartError}
                      partId={part.id}
                      part={part.part}
                      classificationHeader={classificationHeader(part)}
                      initialChoseValues={{
                        partCorrection: part.partCorrection,
                        justification: part.justification,
                      }}
                      onChangePart={handleChangePart({
                        parentDirection: direction.direction,
                        partId: part.id,
                        npaId: npa.id,
                        classificationId: part.classificationId,
                      })}
                      onChoosePart={handleChoosePart({
                        parentDirection: direction.direction,
                        partId: part.id,
                        npaId: npa.id,
                        classificationId: part.classificationId,
                      })}
                    />
                  )
                })}
              </MasterNpaItemOld>
            )
          })}
        </DirectionItemOld>
      )
    })
  }, [
    masterNpaCollapseStatesMap?.current,
    classificationHeader,
    handleChangePart,
    handleChoosePart,
    mapOfNpaDirections,
    state.chosenThree,
    state.chosenPartsError,
  ])

  const renderDefaultThree = useMemo(() => {
    return state.threeToRender.map((direction, index) => (
      <DirectionItemOld
        direction={direction.direction}
        disabled={!!mainDirection}
        key={index}
        type={mapOfNpaDirections?.[direction.direction] || ''}
        investmentSum={direction.minInvestmentAmount}
      >
        {!mainDirection ? (
          direction.npas.map((npa) => (
            <MasterNpaItemOld
              key={npa.id}
              npa={npa}
              initialCollapseState={masterNpaCollapseStatesMap?.current?.[npa.id]}
              onUpdateCollapseState={handleChangeMasterNpaCollapseStates}
            >
              {npa.parts.map((part) => (
                <PartNpaItemOld
                  key={part.id}
                  partId={part.id}
                  initialChoseValues={chosenMap[part.id]}
                  part={part.part}
                  classificationHeader={classificationHeader(part)}
                  onChangePart={handleChangePart({
                    parentDirection: direction.direction,
                    partId: part.id,
                    npaId: npa.id,
                    classificationId: part.classificationId,
                  })}
                  onChoosePart={handleChoosePart({
                    parentDirection: direction.direction,
                    partId: part.id,
                    npaId: npa.id,
                    classificationId: part.classificationId,
                  })}
                />
              ))}
            </MasterNpaItemOld>
          ))
        ) : (
          <Virtuoso
            data={direction.npas}
            totalCount={direction.npas.length}
            computeItemKey={(_index, npa) => npa.id}
            defaultItemHeight={DEFAULT_MASTER_ITEM_HEIGHT}
            itemContent={(_index, npa) => (
              <MasterNpaItemOld
                initialCollapseState={masterNpaCollapseStatesMap?.current?.[npa.id]}
                key={npa.id}
                npa={npa}
                onUpdateCollapseState={handleChangeMasterNpaCollapseStates}
              >
                {npa.parts.map((part) => {
                  return (
                    <PartNpaItemOld
                      key={part.id}
                      partId={part.id}
                      initialChoseValues={chosenMap[part.id]}
                      part={part.part}
                      classificationHeader={classificationHeader(part)}
                      onChangePart={handleChangePart({
                        parentDirection: direction.direction,
                        partId: part.id,
                        npaId: npa.id,
                        classificationId: part.classificationId,
                      })}
                      onChoosePart={handleChoosePart({
                        parentDirection: direction.direction,
                        partId: part.id,
                        npaId: npa.id,
                        classificationId: part.classificationId,
                      })}
                    />
                  )
                })}
              </MasterNpaItemOld>
            )}
          />
        )}
      </DirectionItemOld>
    ))
  }, [
    masterNpaCollapseStatesMap?.current,
    chosenMap,
    classificationHeader,
    handleChangePart,
    handleChoosePart,
    mainDirection,
    mapOfNpaDirections,
    state.threeToRender,
  ])

  return (
    <div className={styles.grid__choose}>
      <Loader loading={gridSwrInstance?.isNpaListLoading} dataTestId="NpaModalGrid-gridLoader">
        {noDataCondition && <NoDataPlaceholder className={styles.placeholder} />}
        {searchPlaceholderCondition && !gridSwrInstance?.isNpaListLoading && (
          <SearchPlaceholder
            className={styles.placeholder}
            contentClassName={styles.placeholder__content}
            bottomRender={
              <>
                Проверьте фильтры и то, что все слова написаны без ошибок. <br />
                Возможно, положение акта, которое вы ищете, ещё не добавлено. <br />
                Отправьте информацию о стабилизируемом положении на почту{' '}
                <Typography.Body as={'span'} variant={'bodyLMedium'} color={'text-accent-brand'}>
                  <a href={`mailto:${ECONOMY_SUPPORT_EMAIL}`}>{ECONOMY_SUPPORT_EMAIL}</a>{' '}
                </Typography.Body>
              </>
            }
          >
            {debouncedSearchValue}
          </SearchPlaceholder>
        )}
        {(() => {
          switch (viewMode) {
            case 'chosen':
              return renderChosenThree

            default:
              return renderDefaultThree
          }
        })()}
      </Loader>
    </div>
  )
}

export default memo(NpaModalGridOld)
