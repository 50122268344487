import { useCallback, useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { LetterHeadFile } from '@context/APIContext/hooks/useLetterHeadPartsApi/types'
import { useBooleanState } from '@hooks/useBooleanState'

export const useOrganizationBlankPreview = () => {
  const {
    headerLetterPartsApi: { getOrganizationLetterHeadBlankPreview },
  } = useAPIContext()

  const {
    booleanState: isPreviewPending,
    setBooleanStateToFalse,
    setBooleanStateToTrue,
  } = useBooleanState(false)

  const getBlankPreview = useCallback(
    async (files: LetterHeadFile[]) => {
      setBooleanStateToTrue()
      try {
        await getOrganizationLetterHeadBlankPreview(JSON.stringify(files))
        setBooleanStateToFalse()
      } catch (e) {
        setBooleanStateToFalse()
      }
    },
    [getOrganizationLetterHeadBlankPreview, setBooleanStateToFalse, setBooleanStateToTrue],
  )

  return useMemo(
    () => ({
      isPreviewPending,
      getBlankPreview,
    }),
    [getBlankPreview, isPreviewPending],
  )
}
