import React, { FC, memo } from 'react'

import ReorganizationTypeAddition from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout/ReorganizationType'
import TransferMembers from '@components/Projects/[id]/DocumentLayout/View/Layouts/RelatedDfoLayout/TransferMembers'
import { DfosStage } from '@constants/types'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import cn from 'classnames'

import styles from './ReorganizationSplitOff.module.scss'

interface ReorganizationSplitOffProps {
  projectId: string
  dfo: IDfoListItem
}

const ReorganizationSplitOff: FC<ReorganizationSplitOffProps> = ({ projectId, dfo }) => {
  const transferMemberCondition =
    dfo?.stage !== DfosStage.DRAFT &&
    dfo?.stage !== DfosStage.NOTIFIED &&
    dfo?.stage !== DfosStage.INITIATOR_REVOKED_NOTICE

  return (
    <div className={styles.reorganizationSplitOff}>
      <ReorganizationTypeAddition
        projectId={projectId}
        dfoId={dfo.id}
        className={cn({
          [styles.reorganizationSplitOff__addition]: transferMemberCondition,
        })}
      />
      {transferMemberCondition && (
        <TransferMembers
          projectId={projectId}
          transferDfo={dfo}
          additionalWriteCondition={dfo?.stage === DfosStage.PREPARING}
        />
      )}
    </div>
  )
}

export default memo(ReorganizationSplitOff)
