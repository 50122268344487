const ReorganizationTypesMap = {
  MERGE: 'MERGE',
  ACCESSION: 'ACCESSION',
  SEPARATION: 'SEPARATION',
  SPLIT_OFF: 'SPLIT_OFF',
  TRANSFORMATION: 'TRANSFORMATION',
} as const

const loadingTypesMap = {
  DRAFT: 'DRAFT',
  FORM: 'FORM',
  NORMAL: 'NORMAL',
} as const

export { loadingTypesMap, ReorganizationTypesMap }
