import KnowledgeBaseDesktopMenu from '@components/KnowledgeBase/Desktop/Menu'
import KnowledgeBaseDesktopPreview from '@components/KnowledgeBase/Desktop/Preview'

interface KnowledgeBaseDesktopProps {
  Menu: typeof KnowledgeBaseDesktopMenu
  Preview: typeof KnowledgeBaseDesktopPreview
}

const KnowledgeBaseDesktop: KnowledgeBaseDesktopProps = () => null

KnowledgeBaseDesktop.Menu = KnowledgeBaseDesktopMenu
KnowledgeBaseDesktop.Preview = KnowledgeBaseDesktopPreview

export default KnowledgeBaseDesktop
