import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import InformationOfExecutionConditionsSZPKMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'
import InformationOfExecutionConditionsSZPKLayoutWithFormProvider from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Layout/LayoutWithFormProvider'
import InformationOfExecutionConditionsSZPKModalManager from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import InformationOfExecutionConditionsSZPKMenu from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Menu'
import InformationOfExecutionConditionsSZPKStatementForm from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Statement'

interface ICreateInformationOfExecutionConditionsSZPK extends FC {
  LayoutWithFormProvider: typeof InformationOfExecutionConditionsSZPKLayoutWithFormProvider
  Menu: typeof InformationOfExecutionConditionsSZPKMenu
  StatementForm: typeof InformationOfExecutionConditionsSZPKStatementForm
  Manager: typeof InformationOfExecutionConditionsSZPKModalManager
  MenuManager: typeof InformationOfExecutionConditionsSZPKMenuManager
}

const useInformationOfExecutionConditionsSZPKControl = () => {
  const popupManager = usePopupManager()

  const openInformationOfExecutionConditionsSZPKModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        InformationOfExecutionConditionsSZPKLayoutWithFormProvider,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openInformationOfExecutionConditionsSZPKModal }
}

const InformationOfExecutionConditionsSZPK: ICreateInformationOfExecutionConditionsSZPK = () => null

InformationOfExecutionConditionsSZPK.Menu = InformationOfExecutionConditionsSZPKMenu
InformationOfExecutionConditionsSZPK.LayoutWithFormProvider =
  InformationOfExecutionConditionsSZPKLayoutWithFormProvider
InformationOfExecutionConditionsSZPK.StatementForm =
  InformationOfExecutionConditionsSZPKStatementForm
InformationOfExecutionConditionsSZPK.Manager = InformationOfExecutionConditionsSZPKModalManager
InformationOfExecutionConditionsSZPK.MenuManager = InformationOfExecutionConditionsSZPKMenuManager

export { useInformationOfExecutionConditionsSZPKControl }
export default InformationOfExecutionConditionsSZPK
