import React, { FC, memo, useMemo } from 'react'
import { Control, Path } from 'react-hook-form'

import { ControlledSingleSelect } from '@components/NewDesign/Select'
import { CreateAgreementFormValues } from '@components/NewDesignedModals/CreateAgreementModal'
import { NewDfosType } from '@constants/types'
import { defaultRHFValidation } from '@constants/validations'
import { useReorganizationForm } from '@hooks/new/swr/useReorganizationForm'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from './DependencyTypeSelect.module.scss'

interface DependencyTypeSelectProps {
  typeOfChosenDfoType: string
  control: Control<CreateAgreementFormValues>
}

const DependencyTypeSelect: FC<DependencyTypeSelectProps> = ({ typeOfChosenDfoType, control }) => {
  const { formOptionsList } = useReorganizationForm({
    key: 'reorganizationForm',
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DependencyTypeSelect',
          moduleName: 'CreateAgreementModal',
        },
      }),
    },
  })

  const preparedOptions = useMemo(() => {
    switch (typeOfChosenDfoType) {
      case NewDfosType.ORG_REORGANIZATION:
        return formOptionsList

      default:
        return []
    }
  }, [formOptionsList, typeOfChosenDfoType])

  const defaultControllerProps = useMemo(
    () => ({
      controllerProps: {
        name: 'dependencyTypeValue' as Path<CreateAgreementFormValues>,
        control,
        rules: {
          required: defaultRHFValidation.required,
        },
      },
      popoverProps: {
        zIndex: 51,
      },
      inputProps: {
        fixWidth: true,
        dataTestId: 'DependencyTypeSelect-singleSelect',
      },
    }),
    [control],
  )

  if (typeOfChosenDfoType !== NewDfosType.ORG_REORGANIZATION) return null

  return (
    <div className={styles.dependencySelect}>
      <ControlledSingleSelect
        controllerProps={defaultControllerProps.controllerProps}
        popoverProps={defaultControllerProps.popoverProps}
        options={preparedOptions}
        inputProps={{
          ...defaultControllerProps.inputProps,
          label: 'Форма реорганизации',
          dataTestId: 'DependencyTypeSelect-dependencyTypeValue-singleSelect',
          formControlDataTestId: 'DependencyTypeSelect-dependencyTypeValue-formControl',
        }}
        optionsProps={{
          dataTestId: 'DependencyTypeSelect-dependencyTypeValue-optionsContainer',
        }}
      />
    </div>
  )
}

export default memo(DependencyTypeSelect)
