import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementIncludeRelatedTermsFormValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/types'
import { currentDateValidate, defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof AAgreementIncludeRelatedTermsFormValues['4'],
  AAgreementIncludeRelatedTermsFormValues
> = {
  relatedContractNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 50),
  },
  relatedContractDate: {
    required: defaultRHFValidation.required,
    validate: (value) =>
      isString(value) && currentDateValidate(value, 'дата не должна превышать текущую'),
  },
  relatedContractTerms: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1500),
  },
  relatedContractPublicLawMember: {
    required: defaultRHFValidation.required,
  },
  relatedContractPublicLawMemberInstrumental: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1050),
  },
  agreementLinkDamageCompensations: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { fourthSectionValidationMap }
