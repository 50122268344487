import React from 'react'
import { useFormContext } from 'react-hook-form'

import InvestorPartnerSection from '@components/DocumentFormComponents/FormSections/InvestorPartnerSection'
import { useAATransferRightsAndObligationsManager } from '@components/Forms/AATransferRightsAndObligations/Manager'
import { AATransferRightsAndObligationsFormValues } from '@components/Forms/AATransferRightsAndObligations/types'
import { AATransferRightsAndObligationsFieldsControlUpdateWatcher } from '@components/Forms/AATransferRightsAndObligations/watcher'

const Fifth = () => {
  const formInstance = useFormContext<AATransferRightsAndObligationsFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAATransferRightsAndObligationsManager()

  if (!formInstance) return null

  return (
    <InvestorPartnerSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'5'}
      formInstance={formInstance}
      watcher={AATransferRightsAndObligationsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Fifth
