import { memo, VFC } from 'react'
import { Props, ReactSVG } from 'react-svg'

import cn from 'classnames'

import styles from './Icon.module.scss'

export interface IconProps extends Props {
  size?: '4xl' | '3xl' | '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs' | '2xs' | 'initial'
  color?: string
  noCurrentColorSvgFill?: boolean
}

const Icon: VFC<IconProps> = ({
  className,
  size = 'l',
  color,
  noCurrentColorSvgFill = false,
  ...props
}) => {
  return (
    // @ts-ignore
    <ReactSVG
      style={{ color }}
      {...props}
      className={cn(
        styles.wrapper,
        styles[`wrapper--${size}`],
        { [styles.fillByCurrentColor]: !noCurrentColorSvgFill },
        className,
      )}
    />
  )
}

export default memo(Icon)
