import { useCallback } from 'react'

import usePropertiesApi from '@context/APIContext/hooks/usePropertiesApi'
import { IDefaultFormUrlParams } from '@context/APIContext/types'
import { Property } from '@services/Properties/OOP/Property'

type SuccessInitialCallbackReturn = {
  lastChangesDt: string
  propertyInstance: Property
}

interface UseInitializeFormProps extends IDefaultFormUrlParams {
  onSuccessInitializeCallback?: (props: SuccessInitialCallbackReturn) => Promise<void>
}

const useInitializeForm = ({ formId }: UseInitializeFormProps) => {
  const { getFormProperties } = usePropertiesApi()

  const initializeForm = useCallback(
    async (
      onSuccessInitializeCallback?: (props: SuccessInitialCallbackReturn) => Promise<void>,
    ) => {
      try {
        const formProperties = await getFormProperties({ formId })

        await onSuccessInitializeCallback?.({
          lastChangesDt: formProperties['@lastNoticedChangesDt'],
          propertyInstance: new Property(formProperties['@properties']),
        })
      } catch (e) {
        throw e
      }
    },
    [formId, getFormProperties],
  )

  return { initializeForm }
}

export type { SuccessInitialCallbackReturn }
export { useInitializeForm }
