const mapOfNpaLvlSelectOptions = [
  {
    displayValue: 'Федеральный',
    value: 'FEDERAL',
  },
  {
    displayValue: 'Региональный',
    value: 'REGIONAL',
  },
  {
    displayValue: 'Муниципальный',
    value: 'MUNICIPAL',
  },
]

export { mapOfNpaLvlSelectOptions }
