import { useCallback, useRef } from 'react'

const useDebouncedCallback = (callback: (...args: any[]) => unknown, delay: number) => {
  const timer = useRef<ReturnType<typeof setTimeout>>()

  return useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay],
  )
}

export { useDebouncedCallback }
