import { useCallback, useEffect, useState } from 'react'

import { isNull, isNullOrUndefined } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'

interface UseNavigationToChangeProps {
  listOfChanges: string[]
  onNavigate?: (path: string) => Promise<void>
}

const useNavigationToChange = ({ listOfChanges, onNavigate }: UseNavigationToChangeProps) => {
  const {
    booleanState: isNavigateToPreviousChangeLoading,
    setBooleanState: setNavigateToPreviousChangeLoading,
  } = useBooleanState()

  const {
    booleanState: isNavigateToNextChangeLoading,
    setBooleanState: setNavigateToNextChangeLoading,
  } = useBooleanState()

  const [currentStepOfChanges, setCurrentStepOfChanges] = useState<number | null>(null)

  const handleNavigateToPreviousChange = useCallback(async () => {
    if (isNull(currentStepOfChanges) || !currentStepOfChanges) {
      setCurrentStepOfChanges(listOfChanges.length ? listOfChanges.length - 1 : null)

      return onNavigate?.(listOfChanges?.[listOfChanges.length - 1])
    }

    setCurrentStepOfChanges(currentStepOfChanges - 1)

    return onNavigate?.(listOfChanges[currentStepOfChanges - 1])
  }, [currentStepOfChanges, listOfChanges, onNavigate])

  const handleNavigateToNextChange = useCallback(async () => {
    if (
      isNull(currentStepOfChanges) ||
      (!isNullOrUndefined(listOfChanges) && currentStepOfChanges >= listOfChanges.length - 1)
    ) {
      setCurrentStepOfChanges(0)

      return onNavigate?.(listOfChanges?.[0])
    }

    setCurrentStepOfChanges(currentStepOfChanges + 1)

    return onNavigate?.(listOfChanges?.[currentStepOfChanges + 1])
  }, [currentStepOfChanges, listOfChanges, onNavigate])

  const handleNextChange = useCallback(async () => {
    setNavigateToNextChangeLoading(true)

    await handleNavigateToNextChange()

    setNavigateToNextChangeLoading(false)
  }, [handleNavigateToNextChange, setNavigateToNextChangeLoading])

  const handlePreviousChange = useCallback(async () => {
    setNavigateToPreviousChangeLoading(true)

    await handleNavigateToPreviousChange()

    setNavigateToPreviousChangeLoading(false)
  }, [handleNavigateToPreviousChange, setNavigateToPreviousChangeLoading])

  // Если после обновления listOfChanges текущий шаг больше, чем длина listOfChanges, то текущий шаг === длине listOfChanges
  useEffect(() => {
    if (
      isNull(currentStepOfChanges) ||
      currentStepOfChanges <= listOfChanges.length - 1 ||
      !listOfChanges.length
    )
      return

    setCurrentStepOfChanges(listOfChanges.length - 1)
  }, [currentStepOfChanges, listOfChanges.length])

  return {
    currentStepOfChanges,
    isNavigateToPreviousChangeLoading,
    isNavigateToNextChangeLoading,
    handleNextChange,
    handlePreviousChange,
  }
}

export { useNavigationToChange }
