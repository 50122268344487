import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import {
  aAgreementAccessionMunicipalBlockValues,
  mapOfAAgreementAccessionMunicipalMenu,
} from '@components/Forms/AAgreementAccessionMunicipalForm/const'
import AAgreementAccessionMunicipalForms from '@components/Forms/AAgreementAccessionMunicipalForm/Forms'
import { useAAgreementAccessionMunicipalManager } from '@components/Forms/AAgreementAccessionMunicipalForm/Manager'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'

const mapOfAAgreementAccessionMunicipalForms: FormItemProps = {
  '1': {
    node: <AAgreementAccessionMunicipalForms.One />,
  },
  '2': {
    node: <AAgreementAccessionMunicipalForms.Two />,
  },
  '3': {
    node: <AAgreementAccessionMunicipalForms.Three />,
  },
  '4': {
    node: <AAgreementAccessionMunicipalForms.Fourth />,
  },
  '5': {
    node: <AAgreementAccessionMunicipalForms.Fifth />,
  },
  '6': {
    node: <AAgreementAccessionMunicipalForms.Sixth />,
  },
} as const

const AAgreementAccessionMunicipalStatementForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AAgreementAccessionMunicipalFormValues>()

  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementAccessionMunicipalBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control
  }).length

  const {
    state: { formIsLoading },
  } = useAAgreementAccessionMunicipalManager()

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAAgreementAccessionMunicipalForms }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isMunicipalitiesRender])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      formIsLoading={formIsLoading}
      mapOfMenu={initialMenu ?? mapOfAAgreementAccessionMunicipalMenu}
      mapOfForms={currentForms}
    />
  )
}

export default memo(AAgreementAccessionMunicipalStatementForm)
