import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { defaultInfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import {
  IInfrastructureObject,
  InfrFacilitiesCostsExpectedReimbursedFormValues,
  MainProjectObject,
} from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import { isArray } from '@helpers/checkTypes'

const { getYearOfDateFromPropertyDateValue } = DocumentFormHelpers

const generateRHFObjectForInfrFacilitiesCosts = (
  objectFromPropertyState: Record<string, any>,
): InfrFacilitiesCostsExpectedReimbursedFormValues => {
  const preparedInvestorHeaderInfo = (() => {
    const defaultInvestorHeaderValue = {
      headerName: null,
      headerPosition: '',
    }

    const docSigners = objectFromPropertyState.docSigners?.value
    if (!docSigners || !docSigners.length) return defaultInvestorHeaderValue

    const headerInfo = docSigners.find((docSigner) => {
      return (
        docSigner.value?.signerOrganization?.value?.id ===
        objectFromPropertyState.investor?.permanentId
      )
    })

    if (!headerInfo) return defaultInvestorHeaderValue

    return {
      headerName: !!headerInfo.value.signer?.value?.id
        ? {
            value: headerInfo.value.signer?.value?.id || '',
            displayValue: headerInfo.value.signer?.value?.headerName || '',
          }
        : defaultInfrFacilitiesCostsExpectedReimbursedFormValues['4'].headerName,
      headerPosition:
        headerInfo.value.signer?.value?.headerPosition ||
        defaultInfrFacilitiesCostsExpectedReimbursedFormValues['4'].headerPosition,
    }
  })()

  const preparedHeaderEmail = ((): MultipleInputValue[] | null => {
    if (
      !isArray(objectFromPropertyState.docSignerEmails?.value) ||
      !objectFromPropertyState.docSignerEmails.value.length
    )
      return null

    return objectFromPropertyState.docSignerEmails?.value.map((email) => ({
      value: email.propertyId,
      displayValue: email.value,
    }))
  })()

  const preparedInfrObjects = ((): IInfrastructureObject[] => {
    if (
      !isArray(objectFromPropertyState.infrastructureObjects?.value) ||
      !objectFromPropertyState.infrastructureObjects?.value.length
    )
      return []

    return objectFromPropertyState.infrastructureObjects.value.map((infrastructureObject) => ({
      id: infrastructureObject.propertyId,
      objectName:
        infrastructureObject?.value?.infrastructureObjectName?.value?.objectName ||
        infrastructureObject?.value?.infrastructureOtherActivityName?.value?.otherActivityName ||
        '',
      hasInfrastructureOtherName: !!infrastructureObject?.value?.infrastructureOtherName?.value,
      infrastructureOtherName: infrastructureObject?.value?.infrastructureOtherName?.value || '',
      infrastructureKind: infrastructureObject?.value?.infrastructureKind?.value || null,
      infrastructureName: infrastructureObject?.value?.infrastructureKind?.value?.name || '',
      infrastructureCommissioningYear: getYearOfDateFromPropertyDateValue(
        infrastructureObject?.value?.infrastructureCommissioningYear?.value,
      ),
      infrastructureCharacteristicsDescription:
        infrastructureObject?.value?.infrastructureCharacteristicsDescription?.value || '',
      infrastructureFunctional: infrastructureObject?.value?.infrastructureFunctional?.value || '',
      infrastructureCost: infrastructureObject?.value?.infrastructureCost?.value || '',
      infrastructureType: (() => {
        const value =
          infrastructureObject?.value?.infrastructureObjectName?.value?.infrastructureType?.name ||
          infrastructureObject?.value?.infrastructureOtherActivityName?.value
            ?.otherActivityInfrastructureType?.name
        if (!value) return ''

        return value === 'Обеспечивающая' ? '50%' : '100%'
      })(),
      infrastructureReason: infrastructureObject?.value?.infrastructureReason?.value || '',
      infrastructureBalanceHolder:
        infrastructureObject?.value?.infrastructureBalanceHolder?.value || '',
      infrastructureCompensationPercent:
        infrastructureObject?.value?.infrastructureCompensationPercent?.value || '',
      infrastructureCompensationValue:
        infrastructureObject?.value?.infrastructureCompensationValue?.value || '',
      infrastructureCompensationStartDate:
        infrastructureObject?.value?.infrastructureCompensationStartDate?.value || '',
      infrastructureCompensationPeriod:
        infrastructureObject?.value?.infrastructureCompensationPeriod?.value?.name || '',
      infrastructureCompensationForm: !!infrastructureObject?.value?.infrastructureCompensationForm
        ?.value?.id
        ? {
            value: infrastructureObject?.value?.infrastructureCompensationForm?.value?.id || '',
            displayValue:
              infrastructureObject?.value?.infrastructureCompensationForm?.value?.name || '',
          }
        : null,

      documentInfrastructure:
        infrastructureObject?.value?.infrastructureDocumentInfrastructure?.value || '',
      infrastructureNote: infrastructureObject?.value?.infrastructureNote?.value || '',
    }))
  })()

  const preparedMainProjectObjects =
    isArray(objectFromPropertyState.mainProjectObjects?.value) &&
    objectFromPropertyState.mainProjectObjects?.value?.length
      ? objectFromPropertyState.mainProjectObjects?.value.map(
          (object): MainProjectObject => ({
            id: object?.propertyId,
            mainObjectOption: !!object?.value?.id
              ? {
                  value: object?.value?.id || '',
                  displayValue: object?.value?.objectName || '',
                }
              : null,
          }),
        )
      : []

  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      projectName: objectFromPropertyState.projectName?.value,
      mainProjectObjects: preparedMainProjectObjects,
      investorCapital: objectFromPropertyState.investorCapital?.value,
      investorCapitalVAT: objectFromPropertyState.investorCapitalVAT?.value,
      totalCapital: objectFromPropertyState.totalCapital?.value,
      totalCapitalVAT: objectFromPropertyState.totalCapitalVAT?.value,
      endInvestStage: getYearOfDateFromPropertyDateValue(
        objectFromPropertyState.endInvestStage?.value,
      ),
    },
    '3': {
      infrastructureObjects: preparedInfrObjects,
      objectGeneralCost: !!objectFromPropertyState.objectGeneralCost?.value
        ? `${objectFromPropertyState.objectGeneralCost?.value} ₽`
        : '',
      objectGeneralCompensations: !!objectFromPropertyState.objectGeneralCompensations?.value
        ? `${objectFromPropertyState.objectGeneralCompensations?.value} ₽`
        : '',
    },
    '4': {
      headerPhone:
        objectFromPropertyState.docSignerPhone?.value ||
        defaultInfrFacilitiesCostsExpectedReimbursedFormValues['4'].headerPhone,
      headerEmail: preparedHeaderEmail,
      ...preparedInvestorHeaderInfo,
    },
    additionalFields: {
      isFederal: objectFromPropertyState?.isFederal?.value,
    },
  }
}

export { generateRHFObjectForInfrFacilitiesCosts }
