import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NoticeOfRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/types'

const { getDefaultInvestorFieldState, getDefaultInitiatorFieldState, getDefaultInfoAboutDocument } =
  FieldStateHelpers

const mapOfNoticeOfRefuseConcludeAAMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченный орган' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Отказ в заключении' },
]

const defaultNoticeOfRefuseConcludeAAFormValues: NoticeOfRefuseConcludeAAFormValues = {
  '1': getDefaultInfoAboutDocument(),
  '2': getDefaultInitiatorFieldState(),
  '3': {
    ...getDefaultInvestorFieldState(),
    investorFullNameGenitive: '',
    investorInn: '',
    investorOgrn: '',
    investorAddress: '',
    projectName: '',
  },
  '4': {
    extraRejectReason: '',
    ruleLinkExtraReject: '',
  },
}

const noticeOfRefuseConcludeAABlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
    initiatorHeaderNameGenitive: '2.initiatorHeaderNameGenitive',
    initiatorHeaderPositionGenitive: '2.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '2.initiatorHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameGenitive: '3.investorFullNameGenitive',
    investorHeaderName: '3.investorHeaderName',
    investorHeaderNameGenitive: '3.investorHeaderNameGenitive',
    investorHeaderPosition: '3.investorHeaderPosition',
    investorHeaderPositionGenitive: '3.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '3.investorHeaderReasonGenitive',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorAddress: '3.investorAddress',
    projectName: '3.projectName',
  },
  '4': {
    extraRejectReason: '4.extraRejectReason',
    ruleLinkExtraReject: '4.ruleLinkExtraReject',
  },
} as const

export {
  defaultNoticeOfRefuseConcludeAAFormValues,
  mapOfNoticeOfRefuseConcludeAAMenu,
  noticeOfRefuseConcludeAABlockValues,
}
