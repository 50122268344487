import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { usePopupManager } from 'react-popup-manager'
import { useLocation } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'

import { handleInternalServerError } from '@components/Forms/LoginForm/helpers'
import LoginLogo from '@components/Forms/LoginForm/shared/LoginLogo'
import LoginWrapper from '@components/Forms/LoginForm/shared/LoginWrapper'
import {
  defaultErrorStatusViewProps,
  defaultStatusViewProps,
  mapOfRegistrationStatus,
  registrationFormComponentInfo,
} from '@components/Forms/RegistrationForm/const'
import { fromEmailStringToEmailJSX } from '@components/Forms/RegistrationForm/helpers'
import {
  initialRegistrationReducerState,
  registrationActionCreators,
  registrationReducer,
} from '@components/Forms/RegistrationForm/reducer'
import StatusView, { StatusViewProps } from '@components/Forms/RegistrationForm/StatusView'
import {
  RegistrationLocationState,
  RegistrationProcessStatus,
} from '@components/Forms/RegistrationForm/types'
import useConfidentialModal from '@components/Modals/ConfidentialModal/manager'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { mapOfEmails } from '@constants/email'
import { mapOfCustomErrorCodes } from '@constants/errorCodes'
import { Paths } from '@constants/paths'
import { HttpStatusCode } from '@constants/statusCodes'
import { isInvestorDomain } from '@constants/system'
import { defaultMessage, defaultMessageSupport } from '@constants/texts'
import { useAPIContext } from '@context/APIContext'
import { isAxiosError, isString } from '@helpers/checkTypes'
import { useScrollState } from '@hooks/new/scroll/useScrollState'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'

import styles from './RegistrationForm.module.scss'

const TIME_OF_RECALL = 15000

const RegistrationForm = () => {
  const {
    organizationApi: { getRegistrationStatus, autoRegisterUser },
  } = useAPIContext()

  const navigate = useNavigate()

  const location = useLocation()
  const locationState = location?.state as RegistrationLocationState | undefined

  const [state, dispatch] = useReducer(registrationReducer, initialRegistrationReducerState)
  const recallCheckStatusRef = useRef<NodeJS.Timeout | null>(null)

  const { handleOpenConfidentialModal } = useConfidentialModal()
  const { handleUnblockScroll } = useScrollState()

  const popupManager = usePopupManager()

  const handleResetRegistration = useCallback(() => {
    popupManager.closeAll()
    navigate(Paths.Login, { replace: true })
  }, [navigate, popupManager])

  const handleProcessCheckStatusErrors = useCallback(
    (error: AxiosError) => {
      const errorStatus = error.response?.status

      if (errorStatus === HttpStatusCode.FORBIDDEN) {
        handleResetRegistration()
        return
      }

      LoggerHelpersService.handleInternalLogError({
        componentInfo: registrationFormComponentInfo,
      })(error)

      dispatch(registrationActionCreators.setError(error))

      const internalServerRegistrationError = handleInternalServerError(error)
      if (internalServerRegistrationError) {
        dispatch(
          registrationActionCreators.setContent({
            statusTitle: internalServerRegistrationError.header,
            statusDescription: internalServerRegistrationError.body,
          }),
        )

        return
      }
    },
    [handleResetRegistration],
  )

  const handleCheckRegistrationStatus = useCallback(async () => {
    if (!locationState?.authenticationKey) return

    if (state.status !== mapOfRegistrationStatus.IN_PROGRESS) {
      if (recallCheckStatusRef.current) {
        clearInterval(recallCheckStatusRef.current)
      }

      dispatch(registrationActionCreators.checkStatus())
    }

    try {
      const response = await getRegistrationStatus({
        authenticationKey: locationState.authenticationKey,
      })

      if (response.status === mapOfRegistrationStatus.IN_PROGRESS) {
        recallCheckStatusRef.current = setInterval(() => {
          handleCheckRegistrationStatus()
        }, TIME_OF_RECALL)
      }

      if (response.status === mapOfRegistrationStatus.FAILED) {
        dispatch(registrationActionCreators.setError(response.errorCode))
      }

      dispatch(registrationActionCreators.setStatus(response.status))
      dispatch(
        registrationActionCreators.setContent({
          statusTitle: response.title,
          statusDescription: response.description,
        }),
      )

      return response
    } catch (error) {
      if (isAxiosError(error)) {
        handleProcessCheckStatusErrors(error)
      }

      throw error
    } finally {
      dispatch(registrationActionCreators.setLoading(false))
    }
  }, [
    getRegistrationStatus,
    handleProcessCheckStatusErrors,
    locationState?.authenticationKey,
    state.status,
  ])

  const handleSubmitRegistration = useCallback(async () => {
    popupManager.closeAll()

    if (!locationState?.authenticationKey) return

    dispatch(registrationActionCreators.checkStatus())
    dispatch(
      registrationActionCreators.setContent({
        statusTitle: 'Идёт регистрация',
      }),
    )

    try {
      try {
        await autoRegisterUser({
          authenticationKey: locationState.authenticationKey,
        })
      } catch (e) {
        if (isAxiosError(e)) {
          handleProcessCheckStatusErrors(e)
        }
        throw e
      } finally {
        dispatch(registrationActionCreators.setLoading(false))
      }

      await handleCheckRegistrationStatus()
    } catch (e) {
      throw e
    }
  }, [
    autoRegisterUser,
    handleCheckRegistrationStatus,
    handleProcessCheckStatusErrors,
    locationState?.authenticationKey,
    popupManager,
  ])

  const handleOpenModalWithStartRegistration = useCallback(() => {
    handleOpenConfidentialModal({
      onClose: handleResetRegistration,
      onSubmit: handleSubmitRegistration,
    })
  }, [handleOpenConfidentialModal, handleResetRegistration, handleSubmitRegistration])

  const handleProcessMountStatus = useCallback(async () => {
    try {
      const response = await handleCheckRegistrationStatus()

      if (response?.status === mapOfRegistrationStatus.NOT_STARTED) {
        handleOpenModalWithStartRegistration()
      }
    } catch (error) {
      throw error
    }
  }, [handleCheckRegistrationStatus, handleOpenModalWithStartRegistration])

  useEffect(() => {
    (async () => {
      await handleProcessMountStatus()
    })()
  }, [])

  const handleSuccessRegistration = useCallback(() => {
    navigate(Paths.Login, {
      replace: true,
      state: locationState,
    })
  }, [locationState, navigate])

  const mapOfLoginErrorPropsByStatus: Record<RegistrationProcessStatus, StatusViewProps> =
    useMemo(() => {
      const defaultTitle = state.statusTitle || ''
      const defaultDescription = state.statusDescription || ''

      return {
        [mapOfRegistrationStatus.NOT_STARTED]: {
          title: defaultTitle,
          description: defaultDescription,
          isLoading: false,
          actions: [
            {
              children: 'Зарегистрироваться',
              fixWidth: true,
              onClick: handleOpenModalWithStartRegistration,
            },
          ],
        },
        [mapOfRegistrationStatus.SUCCEEDED]: {
          title: defaultTitle,
          description: defaultDescription,
          isLoading: false,
          actions: [{ children: 'Войти', fixWidth: true, onClick: handleSuccessRegistration }],
        },
        [mapOfRegistrationStatus.FAILED]: {
          title: state.statusTitle || defaultMessage,
          description: (() => {
            return (
              (isString(state.statusDescription) &&
                fromEmailStringToEmailJSX(state.statusDescription, mapOfEmails)) ||
              state.statusDescription ||
              defaultMessageSupport
            )
          })(),
          isLoading: false,
          actions: (() => {
            const defaultBackAction = {
              children: 'Назад',
              view: 'gray',
              onClick: handleResetRegistration,
            } as ButtonProps

            if (state.error === mapOfCustomErrorCodes.ORGANIZATION_NOT_WILL_REGISTERED_MER)
              return [
                defaultBackAction,
                {
                  children: 'Написать в МЭР',
                  fixWidth: true,
                  href: `mailto:${mapOfEmails.REACT_APP_ECONOMY_SUPPORT_EMAIL}`,
                },
              ]

            if (
              state.error === mapOfCustomErrorCodes.ORGANIZATION_NOT_WILL_REGISTERED ||
              state.error === mapOfCustomErrorCodes.ORGANIZATION_DETERMINING_AUTHORIZED_PERSONS
            ) {
              return [
                defaultBackAction,
                {
                  children: 'Написать в поддержку',
                  fixWidth: true,
                  href: `mailto:${mapOfEmails.REACT_APP_SUPPORT_EMAIL}`,
                },
              ]
            }

            return [
              defaultBackAction,
              {
                children: 'Начать регистрацию заново',
                fixWidth: true,
                className: styles['registrationForm__actionButton--retry'],
                onClick: handleOpenModalWithStartRegistration,
              },
            ]
          })(),
        },
        [mapOfRegistrationStatus.IN_PROGRESS]: {
          isLoading: true,
          title: defaultTitle,
          description: defaultDescription,
        },
      }
    }, [
      handleOpenModalWithStartRegistration,
      handleResetRegistration,
      handleSuccessRegistration,
      state.error,
      state.statusDescription,
      state.statusTitle,
    ])

  const isErrorStatus = state.status === mapOfRegistrationStatus.FAILED || !!state.error
  const currentStatusViewProps = useMemo(() => {
    if (state.status && !state.isLoading)
      return mapOfLoginErrorPropsByStatus[state.status] || defaultErrorStatusViewProps

    if (isAxiosError(state.error))
      return {
        ...defaultErrorStatusViewProps,
        title: state.statusTitle ?? defaultErrorStatusViewProps.title,
        description: state.statusDescription ?? defaultErrorStatusViewProps.description,
        actions: [
          {
            children: 'Назад',
            view: 'gray',
            onClick: handleResetRegistration,
          },
          {
            children: 'Начать регистрацию заново',
            fixWidth: true,
            className: styles['registrationForm__actionButton--retry'],
            onClick: handleProcessMountStatus,
          },
        ],
      }

    return {
      ...defaultStatusViewProps,
      title: state.statusTitle ?? defaultStatusViewProps.title,
      description: state.statusDescription ?? defaultStatusViewProps.description,
      actions: state.statusActions ?? defaultStatusViewProps.actions,
    }
  }, [
    handleProcessMountStatus,
    handleResetRegistration,
    mapOfLoginErrorPropsByStatus,
    state.error,
    state.isLoading,
    state.status,
    state.statusActions,
    state.statusDescription,
    state.statusTitle,
  ])

  const currentLoginWrapperView = isInvestorDomain ? 'secondary' : 'primary'

  return (
    <LoginWrapper view={currentLoginWrapperView}>
      <Link to={Paths.Home} onClick={handleUnblockScroll}>
        <LoginLogo isShowErrorLogo={isErrorStatus} />
      </Link>
      <StatusView {...currentStatusViewProps} />
    </LoginWrapper>
  )
}

export default memo(RegistrationForm)
