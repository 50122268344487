import { FC } from 'react'

import First from './1'
import Second from './2'

interface ApplicationInclusionOfAARegisterSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const ApplicationInclusionOfAARegisterSZPKForms: ApplicationInclusionOfAARegisterSZPKFormsProps =
  () => null

ApplicationInclusionOfAARegisterSZPKForms.First = First
ApplicationInclusionOfAARegisterSZPKForms.Second = Second

export default ApplicationInclusionOfAARegisterSZPKForms
