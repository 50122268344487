import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof ParametersOfCostRecoveryApplicationFormValues['4'],
  ParametersOfCostRecoveryApplicationFormValues
> = {
  compensationStatementExpenseGroupName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionValidationMap }
