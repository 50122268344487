import useMediaQuery from '@hooks/useMediaQuery'
import DesktopKnowledgeBase from '@routes/KnoledgeBase/DesktopVersion'
import MobileKnowledgeBase from '@routes/KnoledgeBase/MobileVesrion'

const KNOWLEDGE_DESKTOP_RESOLUTION = '(min-width: 1024px)'

const KnowledgeBasePage = () => {
  const isDesktopMediaMatches = useMediaQuery(KNOWLEDGE_DESKTOP_RESOLUTION)

  if (!isDesktopMediaMatches) {
    return <MobileKnowledgeBase />
  }

  return <DesktopKnowledgeBase />
}

export default KnowledgeBasePage
