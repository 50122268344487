import React, { FC } from 'react'

import styles from './AuthLayout.module.scss'

const AuthLayout: FC = ({ children }) => {
  return (
    <div className={styles.authLayout}>
      <div className={styles.authLayout__container}>
        <div className={styles.authLayout__content}>{children}</div>
      </div>
    </div>
  )
}

export default AuthLayout
