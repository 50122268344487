import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import cloneDeep from 'clone-deep'

const projectSZPKMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '',
    title: 'УЧАСТНИКИ СОГЛАШЕНИЯ',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [
      {
        id: '1.1',
        path: '1',
        title: 'Уполномоченный ФОИВ',
        parentBaseId: '1',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '1.2',
        path: '2',
        title: 'Уполномоченный РОИВ',
        parentBaseId: '1',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '1.3',
        path: '3',
        title: 'Организация, реализующая проект',
        parentBaseId: '1',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
    ],
  },

  '2': {
    id: '2',
    path: '',
    title: 'О ПРОЕКТЕ',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [
      {
        id: '2.1',
        path: '4',
        title: 'Предмет соглашения',
        parentBaseId: '2',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '2.2',
        path: '5',
        title: 'Описание проекта',
        parentBaseId: '2',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
    ],
  },

  '3': {
    id: '3',
    path: '',
    title: 'СОДЕРЖАНИЕ ПРОЕКТА',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [
      {
        id: '3.1',
        path: '6.stages',
        title: 'Этапы реализации проекта',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '3.2',
        path: '7.eventsOfStages',
        title: 'Основные мероприятия',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [
          {
            id: '3.2',
            path: '7.eventsOfStages.0',
            title: 'Наименования этапов из раздела "Этапы реализации проекта" будут подразделами',
            parentBaseId: '3.2',
            isCollapsed: false,
            hasError: false,
            children: [],
          },
        ],
      },
      {
        id: '3.3',
        path: '8.stepActivityOthers',
        title: 'Иные мероприятия',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '3.4',
        path: '9',
        title: 'Результаты реализации',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '3.5',
        path: '10',
        title: 'Технологические и экологические требования',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
    ],
  },
  '4': {
    id: '4',
    path: '',
    title: 'ПРАВА И ОБЯЗАННОСТИ УЧАСТНИКОВ',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [
      {
        id: '4.1',
        path: '11',
        title: 'Права и обязанности субъекта Российской Федерации',
        parentBaseId: '4',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '4.3',
        path: '12',
        title: 'Стабилизационная оговорка',
        parentBaseId: '4',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
    ],
  },

  '5': {
    id: '5',
    path: '',
    title: 'ПРОЧИЕ УСЛОВИЯ',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [
      {
        id: '5.1',
        path: '13',
        title: 'Условия связанных договоров',
        parentBaseId: '5',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '5.2',
        path: '14',
        title: 'Информация об исполнении условий соглашения',
        parentBaseId: '5',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '5.3',
        path: '15',
        title: 'Разрешение споров',
        parentBaseId: '5',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
    ],
  },
}

const getProjectMenuByStatus = (isFederal: boolean) => {
  const initialProjectSZPKMenuHashMap = cloneDeep(projectSZPKMenuHashMap)

  if (isFederal) {
    return {
      initialProjectSZPKMenuHashMap,
      initialProjectSZPKMenuState: [
        initialProjectSZPKMenuHashMap['1'],
        initialProjectSZPKMenuHashMap['2'],
        initialProjectSZPKMenuHashMap['3'],
        initialProjectSZPKMenuHashMap['4'],
        initialProjectSZPKMenuHashMap['5'],
      ],
    }
  }

  const changedMenu = {
    ...initialProjectSZPKMenuHashMap,
    '1': {
      ...initialProjectSZPKMenuHashMap['1'],
      children: initialProjectSZPKMenuHashMap['1'].children.slice(1),
    },
  }

  return {
    initialProjectSZPKMenuHashMap: changedMenu,
    initialProjectSZPKMenuState: [
      changedMenu['1'],
      changedMenu['2'],
      changedMenu['3'],
      changedMenu['4'],
      changedMenu['5'],
    ],
  }
}

export { getProjectMenuByStatus }
