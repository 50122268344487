import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOnMonitoringResultsFormValues,
  CertificateOnMonitoringResultsPercentCreditMembersChecker,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const percentCreditCheckerCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsPercentCreditMembersChecker,
  CertificateOnMonitoringResultsFormValues
> = {
  percentCreditMemberChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { percentCreditCheckerCertificateOnMonitoringResultsValidationMap }
