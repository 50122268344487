import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForNotificationOfChangeDocumentsMenuState } from '@components/Forms/NotificationOfChangeDocumentsForm/adapters/Menu.adapter'
import { generatePropertiesObjectForNotificationOfChangeDocumentsForm } from '@components/Forms/NotificationOfChangeDocumentsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNotificationOfChangeDocumentsForm } from '@components/Forms/NotificationOfChangeDocumentsForm/adapters/RHF.adapter'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'

const useNotificationOfChangeDocumentsAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfChangeDocumentsFormValues =>
    generateRHFObjectForNotificationOfChangeDocumentsForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNotificationOfChangeDocumentsForm(objectFromPropertyState)

  const generateMenuListByFormValues = (
    formValues:
      | NotificationOfChangeDocumentsFormValues
      | UnpackNestedValue<NotificationOfChangeDocumentsFormValues>,
    initialHashMap: NestedMenuHashMap,
    formErrors: FieldErrors<NotificationOfChangeDocumentsFormValues>,
  ): NestedMapOfMenu[] =>
    generateMenuListForNotificationOfChangeDocumentsMenuState(
      formValues,
      initialHashMap,
      formErrors,
    )

  return { generateRHFObject, generatePropertiesObject, generateMenuListByFormValues }
}

export { useNotificationOfChangeDocumentsAdapters }
