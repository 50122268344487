import Fields from '@components/Fields'
import Icon from '@components/Icon'
import Alert from '@components/NewDesign/Alert'
import Button from '@components/NewDesign/Button/Button'
import TextWithTooltip from '@components/NewDesign/TextWithTooltip'
import type { HandleOpenPasswordChangeModal } from '@components/NewDesignedModals/PasswordChangeModal'
import TextWithCopy from '@components/TextWithCopy'
import { limitWithTooltip } from '@helpers/limitWithTooltip'
import lockOutlineIcon from '@icons/action/lock_outline.svg'

import { LOCK_ICON_COLOR } from '../constants'

import styles from './OperatorsTable.module.scss'

export const columns = (handleOpenPasswordChangeModal: HandleOpenPasswordChangeModal) => [
  {
    Header: 'Сотрудник・Email',
    accessor: 'firstName',
    className: 'w-[19%]',
    Cell: ({
      row: {
        original: { firstName, lastName, email },
      },
    }) => {
      const fullName = `${firstName} ${lastName}`

      return (
        <div className="text-sm" {...limitWithTooltip(fullName, 49)}>
          <div className={styles.nameCell}>
            {firstName} {lastName}
          </div>
          <div className="text-text-base-tertiary">
            <Fields.Email>{email}</Fields.Email>
          </div>
        </div>
      )
    },
  },
  {
    Header: 'Должность',
    accessor: 'position',
    className: 'w-[17%]',
    Cell: ({ value }) => <TextWithTooltip variant="bodyMRegular" value={value} limit={40} />,
  },
  {
    Header: 'ID',
    className: 'w-fit',
    accessor: 'id',
    Cell: ({ value }) => (
      <div className={styles.copy}>
        <TextWithCopy
          value={value}
          view="plain"
          className={styles.copyWrapper}
          iconWrapperClassName={styles.copyIconWrapper}
          SuccessAlert={<Alert variant="success">Сертификат скопирован в буфер обмена</Alert>}
          ErrorAlert={<Alert variant="error">Не удалось скопировать сертификат</Alert>}
        >
          <TextWithTooltip
            variant="bodyMRegular"
            value={value}
            limit={40}
            className={styles.text}
          />
        </TextWithCopy>
      </div>
    ),
  },
  {
    className: styles.lockedUser,
    Header: 'Заблокированный пользователь',
    Cell: ({ row: { original } }) => {
      return original?.isEnabled ? (
        <Button
          view="plain"
          size="s"
          type={'button'}
          className={styles.buttonPassword}
          textClassName={styles.buttonPassword}
          onClick={handleOpenPasswordChangeModal({ userId: original.id })}
        >
          Сменить пароль
        </Button>
      ) : (
        <Icon src={lockOutlineIcon} color={LOCK_ICON_COLOR} size="s" />
      )
    },
  },
]
