import { FieldErrors } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  blockValues,
  defaultFormValues,
  mapOfCompensationsTypeBeforeMigrate,
} from '@components/Forms/CreateStatementOld/const'
import {
  DlcForResponse,
  IViolation,
  StatementOldFormValues,
} from '@components/Forms/CreateStatementOld/types'
import { getStrValueFromNumber } from '@components/NewDesign/AmountInput/utils'
import { transformValueToMultipleInputValue } from '@components/NewDesign/MultipleInput/MultipleInput'
import { objOfDateFormats } from '@constants/dateFormats'
import { IGetStatementOldResponse } from '@context/APIContext/types'
import { compact } from '@helpers/array/compact'
import {
  isEmptyString,
  isNullOrUndefined,
  isObject,
  isString,
  isUndefined,
} from '@helpers/checkTypes'
import DayjsService from '@services/Dayjs/Dayjs.service'

class StatementHelpers {
  formatSeparatedValue(value: string, extended?: boolean) {
    if (extended)
      return value
        .replace(/\s/g, '')
        .replace(/[^0-9]/g, '')
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')

    return value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
  }

  transformValueFromRHF(value) {
    const preparedValue = isString(value) ? value.trim() : value

    if (
      isUndefined(value) ||
      isEmptyString(preparedValue) ||
      (Array.isArray(preparedValue) && !preparedValue.length) ||
      (isObject(preparedValue) && !Object.keys(preparedValue).length)
    )
      return null

    return preparedValue
  }

  transformValueFromApi(value) {
    if (value === null) return ''

    return value
  }

  getRHFObjectFromStatementApi(
    response: IGetStatementOldResponse,
    dlcForResponse: DlcForResponse,
  ): StatementOldFormValues {
    const preparedGeneralInfoActivityKind = response.generalInfo.activityKind
      ? response.generalInfo.activityKind
      : response.generalInfo.activityKind

    const preparedInvestorGeneralInfo = response.investorGeneralInfo
      ? {
          fullName: this.transformValueFromApi(response.investorGeneralInfo.fullName),
          name: this.transformValueFromApi(response.investorGeneralInfo.name),
          inn: this.transformValueFromApi(response.investorGeneralInfo.inn),
          ogrn: this.transformValueFromApi(response.investorGeneralInfo.ogrn),
          kpp: this.transformValueFromApi(response.investorGeneralInfo.kpp),
          head: this.transformValueFromApi(response.investorGeneralInfo.head),
          position: this.transformValueFromApi(response.investorGeneralInfo.position),
          reason: this.transformValueFromApi(response.investorGeneralInfo.reason),
          okpo: this.transformValueFromApi(response.investorGeneralInfo.okpo),
          okved: dlcForResponse.okved,
          capital:
            response.investorGeneralInfo.capital !== null
              ? this.formatSeparatedValue(
                  getStrValueFromNumber(response.investorGeneralInfo.capital),
                )
              : null,

          isProjectCompany: response.investorGeneralInfo.isProjectCompany,
          isVed: response.investorGeneralInfo.isVed,
          headGenitive: this.transformValueFromApi(response.investorGeneralInfo.headGenitive),
          positionGenitive: this.transformValueFromApi(
            response.investorGeneralInfo.positionGenitive,
          ),
          reasonGenitive: this.transformValueFromApi(response.investorGeneralInfo.reasonGenitive),
        }
      : defaultFormValues.investorGeneralInfo

    const preparedInvestorAddress = response.investorAddress
      ? {
          subject: response.investorAddress.subject ?? '',

          district: this.transformValueFromApi(response.investorAddress.district),
          area: this.transformValueFromApi(response.investorAddress.area),
          city: this.transformValueFromApi(response.investorAddress.city),
          planningStructureElement: this.transformValueFromApi(
            response.investorAddress.planningStructureElement,
          ),
          roadNetworkElement: this.transformValueFromApi(
            response.investorAddress.roadNetworkElement,
          ),
          house: this.transformValueFromApi(response.investorAddress.house),
          room: this.transformValueFromApi(response.investorAddress.room),
          roomPart: this.transformValueFromApi(response.investorAddress.roomPart),
        }
      : defaultFormValues.investorAddress

    const preparedGeneralInfo = response.generalInfo
      ? {
          activityKindOkved: dlcForResponse.activityKindOkved,
          ruleParagraph: this.transformValueFromApi(response.generalInfo.ruleParagraph),
          headPhone: this.transformValueFromApi(response.generalInfo.headPhone),
          headAdditionalPhone: this.transformValueFromApi(response.generalInfo.headAdditionalPhone),
          headEmail: (() => {
            return !isNullOrUndefined(response.generalInfo.headEmail)
              ? transformValueToMultipleInputValue(response.generalInfo.headEmail)
              : null
          })(),
          characteristic: this.transformValueFromApi(response.generalInfo.characteristic),
          startDate:
            response.generalInfo.startDate !== null
              ? DayjsService.dayjs(response.generalInfo.startDate).format(
                  objOfDateFormats.defaultFormat,
                )
              : '',
          projectEndDate:
            response.generalInfo.projectEndDate !== null
              ? DayjsService.dayjs(response.generalInfo.projectEndDate).format(
                  objOfDateFormats.defaultFormat,
                )
              : '',

          szpkEndDate:
            response.generalInfo.szpkEndDate !== null
              ? DayjsService.dayjs(response.generalInfo.szpkEndDate).format(
                  objOfDateFormats.defaultFormat,
                )
              : '',

          activityKind: this.transformValueFromApi(preparedGeneralInfoActivityKind),

          newWorkplaces:
            response.generalInfo.newWorkplaces !== null
              ? this.formatSeparatedValue(String(response.generalInfo.newWorkplaces))
              : '',

          newBuildingWorkplaces:
            response.generalInfo.newBuildingWorkplaces !== null
              ? this.formatSeparatedValue(String(response.generalInfo.newBuildingWorkplaces))
              : '',
        }
      : defaultFormValues.generalInfo

    const preparedParticipantsAndInvestments = response.participantsAndInvestments
      ? {
          decisionDate:
            response.participantsAndInvestments.decisionDate !== null
              ? DayjsService.dayjs(response.participantsAndInvestments.decisionDate).format(
                  objOfDateFormats.defaultFormat,
                )
              : '',
          endDate:
            response.participantsAndInvestments.endDate !== null
              ? DayjsService.dayjs(response.participantsAndInvestments.endDate).format(
                  objOfDateFormats.defaultFormat,
                )
              : '',
          investorCapital:
            response.participantsAndInvestments.investorCapital !== null
              ? this.formatSeparatedValue(
                  getStrValueFromNumber(response.participantsAndInvestments.investorCapital),
                )
              : '',
          investorCapitalVAT:
            response.participantsAndInvestments.investorCapitalVAT !== null
              ? this.formatSeparatedValue(
                  getStrValueFromNumber(response.participantsAndInvestments.investorCapitalVAT),
                )
              : '',
          totalCapital:
            response.participantsAndInvestments.totalCapital !== null
              ? this.formatSeparatedValue(
                  getStrValueFromNumber(response.participantsAndInvestments.totalCapital),
                )
              : '',
          totalCapitalVAT:
            response.participantsAndInvestments.totalCapitalVAT !== null
              ? this.formatSeparatedValue(
                  getStrValueFromNumber(response.participantsAndInvestments.totalCapitalVAT),
                )
              : '',
          taxes: response.participantsAndInvestments?.taxes.length
            ? response.participantsAndInvestments?.taxes.map((item) => ({
                plannedTax:
                  item.plannedTax !== null
                    ? this.formatSeparatedValue(getStrValueFromNumber(item.plannedTax))
                    : '',
                taxYear: DayjsService.dayjs(item.taxYear),
              }))
            : defaultFormValues.participantsAndInvestments.taxes,
        }
      : defaultFormValues.participantsAndInvestments

    const preparedStepProjectArray = response.stages?.length
      ? response.stages.map((item) => ({
          name: this.transformValueFromApi(item.name),
          startDate: item.startDate
            ? DayjsService.dayjs(item.startDate).format(objOfDateFormats.defaultFormat)
            : '',
          endDate: item.endDate
            ? DayjsService.dayjs(item.endDate).format(objOfDateFormats.defaultFormat)
            : '',
        }))
      : defaultFormValues.stages

    const preparedCompensations = response.compensations?.length
      ? response.compensations.map((item) => {
          const preparedType = item.type
            ? this.resetCompensationFieldAfterMigrate(item.type)
            : this.transformValueFromApi(item.type)

          const preparedFormat = item.format
            ? this.resetCompensationFieldAfterMigrate(item.format)
            : this.transformValueFromApi(item.format)

          const preparedValue =
            item.value !== null ? this.formatSeparatedValue(getStrValueFromNumber(item.value)) : ''

          return {
            type: preparedType,
            format: preparedFormat,
            value: preparedValue,
          }
        })
      : defaultFormValues.compensations

    return {
      generalInfo: preparedGeneralInfo,
      participantsAndInvestments: preparedParticipantsAndInvestments,
      compensations: preparedCompensations,
      stages: preparedStepProjectArray,
      investorAddress: preparedInvestorAddress,
      investorGeneralInfo: preparedInvestorGeneralInfo,
    }
  }

  parseErrorsFromViolations(violations: IViolation[]): IViolation[] {
    return violations.map((violation) => {
      if (violation.field.includes('[')) {
        const fieldName = violation.field.replace('[', '.').replace(']', '')

        return {
          ...violation,
          field: fieldName,
        }
      }
      return violation
    })
  }

  isBlockHaveError(id: string, errors: FieldErrors<StatementOldFormValues>): boolean {
    return DocumentFormHelpers.isBlockHaveError<StatementOldFormValues>(id, blockValues, errors)
  }

  generateAddressInOneString({
    city,
    district,
    planningStructureElement,
    roadNetworkElement,
    room,
    roomPart,
    subject,
    house,
    area,
  }: StatementOldFormValues['investorAddress']): string {
    return compact([
      subject,
      district,
      area,
      city,
      planningStructureElement,
      roadNetworkElement,
      house,
      room,
      roomPart,
    ]).join(', ')
  }

  resetCompensationFieldAfterMigrate(item: string) {
    if (
      item === mapOfCompensationsTypeBeforeMigrate.BOND ||
      item === mapOfCompensationsTypeBeforeMigrate.LOAN
    )
      return ''

    return item
  }
}

export default new StatementHelpers()
