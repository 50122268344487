import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fifthSectionAAgreementForceCircumstancesValidationMap: FormValuesValidationSection<
  keyof AAgreementForceCircumstancesFormValues['5']['stages'][number],
  AAgreementForceCircumstancesFormValues
> = {
  stepEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { fifthSectionAAgreementForceCircumstancesValidationMap }
