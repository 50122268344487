import React from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import HierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect'
import {
  HierarchyReferenceBookSelectProps,
  HierarchyTypeSelectOption,
} from '@components/NewDesign/Select/HierarchyReferenceBookSelect/types'

interface ControlledHierarchyReferenceBookSelectProps<T extends FieldValues>
  extends Omit<HierarchyReferenceBookSelectProps<T>, 'name'> {
  controllerProps: Omit<ControllerProps<T>, 'render'>
}

const ControlledHierarchyReferenceBookSelect = <T extends FieldValues>({
  controllerProps,
  defaultSelected,
  onChange,
  onBlur,
  id,
  ...props
}: ControlledHierarchyReferenceBookSelectProps<T>) => {
  const { name } = controllerProps

  const {
    field: { onChange: onChangeForm, onBlur: onBlurForm, value: formValue },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const onMergedChange = (value: HierarchyTypeSelectOption | null) => {
    onChangeForm(value)

    onChange?.(value)
  }

  const onMergedBlur = () => {
    onBlurForm()

    onBlur?.()
  }

  return (
    <HierarchyReferenceBookSelect
      {...props}
      id={id}
      name={name}
      error={error}
      defaultSelected={defaultSelected || formValue}
      onChange={onMergedChange}
      onBlur={onMergedBlur}
    />
  )
}

ControlledHierarchyReferenceBookSelect.displayName = 'ControlledHierarchyReferenceBookSelect'

export type { ControlledHierarchyReferenceBookSelectProps }
export default ControlledHierarchyReferenceBookSelect
