import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import ParametersOfCostRecoveryApplicationLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import ParametersOfCostRecoveryApplicationModalManager from './Manager'
import ParametersOfCostRecoveryApplicationMenu from './Menu'
import ParametersOfCostRecoveryApplicationStatementForm from './Statement'

interface ICreateParametersOfCostRecoveryApplication extends FC {
  LayoutWithFormProvider: typeof ParametersOfCostRecoveryApplicationLayoutWithFormProvider
  Menu: typeof ParametersOfCostRecoveryApplicationMenu
  StatementForm: typeof ParametersOfCostRecoveryApplicationStatementForm
  Manager: typeof ParametersOfCostRecoveryApplicationModalManager
}

const useParametersOfCostRecoveryApplicationControl = () => {
  const popupManager = usePopupManager()

  const openParametersOfCostRecoveryApplicationModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(ParametersOfCostRecoveryApplicationLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return {  openParametersOfCostRecoveryApplicationModal }
}

const ParametersOfCostRecoveryApplication: ICreateParametersOfCostRecoveryApplication = () => null

ParametersOfCostRecoveryApplication.Menu = ParametersOfCostRecoveryApplicationMenu
ParametersOfCostRecoveryApplication.LayoutWithFormProvider = ParametersOfCostRecoveryApplicationLayoutWithFormProvider
ParametersOfCostRecoveryApplication.StatementForm = ParametersOfCostRecoveryApplicationStatementForm
ParametersOfCostRecoveryApplication.Manager = ParametersOfCostRecoveryApplicationModalManager

export { useParametersOfCostRecoveryApplicationControl  }
export default ParametersOfCostRecoveryApplication
