import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import FormError from '@components/DocumentFormComponents/FormError'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  SeventhPermissionsObjectNamePermissionsPathName,
  SeventhPermissionsObjectsArrayPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

import { objectNamePermissionsValidationMap } from './validation'

const { isFormFieldError } = DocumentFormHelpers

interface ObjectNamesListProps {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  formName: SeventhPermissionsObjectsArrayPathName
}

const ObjectNamesList: FC<ObjectNamesListProps> = ({
  blockViewIsValidating,
  editMode,
  formInstance,
  formModifierInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formModifierInstance.control,
  })

  const { getSubscribableControlProps, getSingleSelectProps } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { fields: objectNamePermissions } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhPermissionsObjectNamePermissionsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${formName}.objectNamePermissions`,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
    SeventhPermissionsObjectNamePermissionsPathName
  >({
    control: formModifierInstance.control,
    name: `${formName}.objectNamePermissions`,
    watcher:
      AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
  })

  const objectNamePermissionsError = formInstance.getFieldState(
    `${formName}.objectNamePermissions`,
  )?.error

  const handleAddNewObjectNamePermission = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.objectNamePermissions`)

    if (!objectNamePermissions.length) {
      formInstance.clearErrors(`${formName}.objectNamePermissions`)
    }
  }

  const handleRemoveObjectNamePermission = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${formName}.objectNamePermissions.${index}`,
      `${formName}.objectNamePermissions`,
    )
  }

  return (
    <FormListTooltipControl
      disablePaddingRight
      enablePaddingLeft
      name={`${formName}.objectNamePermissions`}
      control={formModifierInstance.control}
      onDifference={handleUpdateChanges}
    >
      {({ iconWithTooltip }) => (
        <Group
          disableBottomBorder
          id={`${formName}.objectNamePermissions`}
          title={'Объекты в разрешении'}
          leftAddons={iconWithTooltip}
        >
          <Stack direction={'vertical'} gap={!!objectNamePermissions.length ? 3 : 0}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectNamePermissions`,
              })}
            >
              <FlipperList list={objectNamePermissions}>
                {objectNamePermissions.map((objectNamePermission, index) => {
                  const objectPermissionFormName =
                    `${formName}.objectNamePermissions.${index}` as const

                  return (
                    <FormObjectTooltipControl
                      key={objectNamePermission.id}
                      name={objectPermissionFormName}
                      control={formModifierInstance.control}
                      onDifference={handleUpdateChanges}
                    >
                      <GroupWithRemoveButton
                        disableBottomBorder
                        id={objectPermissionFormName}
                        title={`Объект №${index + 1}`}
                        onRemove={editMode ? handleRemoveObjectNamePermission(index) : undefined}
                      >
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${objectPermissionFormName}.objectOption`,
                          })}
                        >
                          {({ overrideProps }) => {
                            const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                              ? overrideProps.fetcherOptions
                              : undefined

                            return (
                              <ControlledFormSingleSelect
                                {...getSingleSelectProps({
                                  fetcherProps,
                                  optionsAdapter: (item) => ({
                                    displayValue: item.objectName,
                                    value: item.id,
                                  }),
                                  controllerProps: {
                                    name: `${objectPermissionFormName}.objectOption`,
                                    rules: objectNamePermissionsValidationMap.objectOption,
                                  },
                                  selectProps: {
                                    inputProps: {
                                      label: 'Объект',
                                    },
                                    onChangeFormValue: () =>
                                      setTimeout(
                                        () =>
                                          handleChangeValue?.(
                                            `${objectPermissionFormName}.objectOption`,
                                          ),
                                        0,
                                      ),
                                  },
                                })}
                              />
                            )
                          }}
                        </SubscribableControl>
                      </GroupWithRemoveButton>
                    </FormObjectTooltipControl>
                  )
                })}
              </FlipperList>
            </SubscribableControl>
            {isFormFieldError(objectNamePermissionsError) && !objectNamePermissions.length && (
              <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
            )}
            {editMode && (
              <Row className={'px-1'}>
                <Col xs={12}>
                  <AsyncWrapper promise={handleAddNewObjectNamePermission}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <Button
                          disabled={isLoading}
                          leadingIcon={{ src: CircleAddIcon }}
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          view={'plain'}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Указать в разрешении объект
                        </Button>
                      )
                    }}
                  </AsyncWrapper>
                </Col>
              </Row>
            )}
          </Stack>
        </Group>
      )}
    </FormListTooltipControl>
  )
}

export default ObjectNamesList
