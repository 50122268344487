import { TagProps } from '@components/Tag'
import { UserRole } from '@interfaces'
import { Audit } from '@routes/Audits/types'
import { mapOfDocumentActions } from '@services/DocumentActions/DocumentActions.entity'

const DEFAULT_COLOR_ACTION: TagProps['type'] = 'navy'

const DEFAULT_COLOR: TagProps['type'] = 'green'

const INVESTOR_COLOR: TagProps['type'] = 'blue'

export const getTagPropsByRole = (role?: UserRole) => {
  const props: Record<UserRole, [string, TagProps['type']]> = {
    FK: ['ФК', DEFAULT_COLOR],
    OIV: ['ОИВ', DEFAULT_COLOR],
    MER: ['МЭР', DEFAULT_COLOR],
    UO: ['УО', DEFAULT_COLOR],
    OMSU: ['ОМСУ', DEFAULT_COLOR],
    ADMIN: ['Администратор', DEFAULT_COLOR],
    OPERATOR: ['Оператор', DEFAULT_COLOR],
    INVESTOR: ['Инвестор', INVESTOR_COLOR],
    HEADER: ['Руководитель', DEFAULT_COLOR],
    EMPLOYEE_AUTHORIZED: ['Уполномоченный сотрудник', DEFAULT_COLOR],
    EMPLOYEE: ['Сотрудник', DEFAULT_COLOR],
  }

  return props[role || 'EMPLOYEE']
}

export const getTagPropsByActionType = (actionType?: Audit['actionType']) => {
  // @ts-ignore
  const props: Record<Audit['actionType'], [string, TagProps['type']]> = {
    LOG_IN: ['Вход в систему', DEFAULT_COLOR_ACTION],
    LOG_OUT: ['Выход из системы', DEFAULT_COLOR_ACTION],
    CREATE_SZPK: ['Создание СЗПК', DEFAULT_COLOR_ACTION],
    CREATE_SZPK_EXTRA: ['Создание доп соглашения к СЗПК', DEFAULT_COLOR_ACTION],
    SIGN_DOCUMENT: ['Подписание документа', DEFAULT_COLOR_ACTION],
    DOWNLOAD_DOCUMENT: ['Скачивание документа', DEFAULT_COLOR_ACTION],
    DOWNLOAD_ARCHIVE: ['Скачивание архива документов', DEFAULT_COLOR_ACTION],
    CHANGE_STAGE: ['Смена статуса', DEFAULT_COLOR_ACTION],
    ADD_NPA: ['Добавление НПА или версии НПА', DEFAULT_COLOR_ACTION],
    EXCLUDE_NPA: ['Исключение НПА', DEFAULT_COLOR_ACTION],
    REGISTERED_ORGANIZATION: ['Регистрация организации', DEFAULT_COLOR_ACTION],
    BLOCKED_ORGANIZATION: ['Блокировка организации', DEFAULT_COLOR_ACTION],
    UNBLOCKED_ORGANIZATION: ['Снятие блокировки организации', DEFAULT_COLOR_ACTION],
    ADD_USER: ['Добавление пользователя', DEFAULT_COLOR_ACTION],
    EDIT_USER: ['Редактирование пользователя ', DEFAULT_COLOR_ACTION],
    BLOCKED_USER: ['Блокировка пользователя', DEFAULT_COLOR_ACTION],
    UNBLOCKED_USER: ['Снятие блокировки пользователя', DEFAULT_COLOR_ACTION],
    ADD_OPERATOR: ['Добавление оператора', DEFAULT_COLOR_ACTION],
    EDIT_OPERATOR: ['Редактирование оператора', DEFAULT_COLOR_ACTION],
    BLOCKED_OPERATOR: ['Блокировка оператора', DEFAULT_COLOR_ACTION],
    UNBLOCKED_OPERATOR: ['Снятие блокировки оператора', DEFAULT_COLOR_ACTION],
  }

  return props[actionType || mapOfDocumentActions.CHANGE_STAGE]
}
