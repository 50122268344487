import { FC, memo, useCallback, useMemo, useState } from 'react'
import { PopupProps } from 'react-popup-manager'

import Icon from '@components/Icon'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import DataView from '@components/Projects/[id]/ParticipantsOfStatement/DataView'
import arrowUpIcon from '@icons/hardware/keyboard_arrow_up.svg'
import parallelIcon from '@icons/parallel.svg'
import sequentialIcon from '@icons/sequental.svg'
import { IProjectMember, TGetProjectMembers } from '@services/Members/Members.entity'
import { TPutProcessingOrders } from '@services/Processes/processes.entity'
import cn from 'classnames'

import styles from './ProcessOrderModal.module.scss'

interface ProcessOrderModalModal extends PopupProps {
  header: string
  oivMembers: TGetProjectMembers
  onSubmit: (data: TPutProcessingOrders) => void
}

interface IProjectMembersOrdered extends IProjectMember {
  order: number
}

const SIGN_TYPES = {
  SEQUENTIAL: {
    title: 'Последовательное',
    description: 'по очереди',
    icon: sequentialIcon,
  },
  PARALLEL: {
    title: 'Параллельное',
    description: 'одновременно',
    icon: parallelIcon,
  },
} as const

type TSignTypes = keyof typeof SIGN_TYPES
const SIGN_TYPES_ARRAY = Object.keys(SIGN_TYPES) as TSignTypes[]

const ProcessOrderModalModal: FC<ProcessOrderModalModal> = ({
  header,
  oivMembers,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [selectedSignType, setSelectedSignType] = useState<TSignTypes>('PARALLEL')
  const [orderedOivMembers, setOrderedOivMembers] = useState<IProjectMembersOrdered[]>(() =>
    oivMembers.map((member, index) => ({
      ...member,
      order: index,
    })),
  )

  const handleClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  const handleSubmit = useCallback(() => {
    const payload: TPutProcessingOrders = {
      processingOrderType: selectedSignType,
    }

    if (selectedSignType === 'SEQUENTIAL') {
      payload.membersOrder = orderedOivMembers.map((member) => ({
        organizationId: member.organizationId,
        order: member.order,
      }))
    }

    onSubmit(payload)
  }, [onSubmit, orderedOivMembers, selectedSignType])

  const actions = useMemo(() => {
    return [
      { view: 'gray', children: 'Отмена', onClick: onClose },
      {
        children: 'Продолжить',
        onClick: handleSubmit,
      },
    ] as ButtonProps[]
  }, [handleSubmit, onClose])

  return (
    <Modal.Action
      position="center"
      isOpen={isOpen}
      title={header}
      actions={actions}
      windowClassName={styles.wrapper}
      onClose={handleClose}
    >
      <div className={styles.signType_container}>
        {SIGN_TYPES_ARRAY.map((key) => {
          const typeInfo = SIGN_TYPES[key]
          return (
            <div
              key={key}
              role="button"
              tabIndex={0}
              className={cn(styles.signType_item, {
                [styles['signType_item-selected']]: selectedSignType === key,
              })}
              onClick={() => setSelectedSignType(key)}
            >
              <Icon
                className={styles.signType_icon}
                size={'2xl'}
                src={typeInfo.icon}
                color="default"
              />
              <div className={styles.signType_info}>
                <Typography.Body variant={'bodyMMedium'}>{typeInfo.title}</Typography.Body>
                <Typography.Body color="text-base-tertiary" variant={'bodySMedium'}>
                  {typeInfo.description}
                </Typography.Body>
              </div>
            </div>
          )
        })}
      </div>

      <div className={styles.memberOrder_container}>
        <Typography.Body
          className={styles.memberOrder_title}
          variant={'bodyMMedium'}
          color={'text-base-secondary'}
        >
          {selectedSignType === 'SEQUENTIAL'
            ? 'Укажите последовательность рассмотрения и подписания соглашения'
            : 'Рассмотрение и подписание соглашения будет происходить параллельно'}
        </Typography.Body>
        <div className={styles.memberOrder_members_wrapper}>
          {orderedOivMembers
            ?.sort((a, b) => a.order - b.order)
            .map((oivMember, index, currentArray) => {
              const isFirst = index === 0
              const isLast = index === orderedOivMembers.length - 1

              const updateOrderPosition = (amount: number) => {
                const newOrder = [...orderedOivMembers]
                const newIndexOrder = newOrder[index].order + amount

                currentArray[index + amount].order = newOrder[index].order
                newOrder[index].order = newIndexOrder

                return newOrder
              }

              return (
                <div className={styles.memberOrder_member} key={oivMember.organizationId}>
                  {selectedSignType === 'SEQUENTIAL' && (
                    <div>
                      <Typography.Body
                        className={styles.memberOrder_members_number}
                        variant={'bodyMMedium'}
                      >
                        {index + 1}
                      </Typography.Body>
                    </div>
                  )}

                  <div>
                    <DataView suptitle="Региональный орган исполнительной власти">
                      {oivMember?.name}
                    </DataView>
                  </div>

                  {selectedSignType === 'SEQUENTIAL' && (
                    <div className={styles.memberOrder_sorter}>
                      <Icon
                        size={'m'}
                        src={arrowUpIcon}
                        className={cn(styles.memberOrder_icon, {
                          [styles['memberOrder_icon-disabled']]: isFirst,
                        })}
                        onClick={() => !isFirst && setOrderedOivMembers(updateOrderPosition(-1))}
                      />
                      <Icon
                        size={'m'}
                        src={arrowUpIcon}
                        className={cn(styles.memberOrder_icon, styles['memberOrder_icon-down'], {
                          [styles['memberOrder_icon-disabled']]: isLast,
                        })}
                        onClick={() => !isLast && setOrderedOivMembers(updateOrderPosition(1))}
                      />
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </Modal.Action>
  )
}

export default memo(ProcessOrderModalModal)
