import { useEffect, useMemo } from 'react'

import { isNull } from '@helpers/checkTypes'
import { documentsWebFormStatesMap } from '@services/Documents/documents.const'
import { WebFormInfo } from '@services/Documents/documents.entity'

const POLLING_DOCUMENT_SETS_INTERVAL = 5000

interface UsePollingUntilFormsLoadedProps {
  revalidateCallback: () => Promise<unknown>
  forms: WebFormInfo[] | undefined
  isRevalidatePaused?: boolean
}

const useRevalidateUntilFormsLoaded = ({
  revalidateCallback,
  forms,
  isRevalidatePaused,
}: UsePollingUntilFormsLoadedProps) => {
  const conditionToRevalidate = useMemo(
    () =>
      forms?.some(
        (formItem) =>
          formItem.state == documentsWebFormStatesMap.NEW ||
          formItem.state == documentsWebFormStatesMap.MOVING ||
          formItem.state == documentsWebFormStatesMap.CONVERTING,
      ),
    [forms],
  )

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    if (!conditionToRevalidate) return

    intervalId = setInterval(async () => {
      if (isRevalidatePaused) return

      await revalidateCallback()
    }, POLLING_DOCUMENT_SETS_INTERVAL)

    return () => {
      if (!isNull(intervalId)) clearInterval(intervalId)
    }
  }, [conditionToRevalidate, isRevalidatePaused, revalidateCallback])
}

export default useRevalidateUntilFormsLoaded
