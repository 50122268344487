import Icon from '@components/Icon'
import Typography from '@components/NewDesign/Typography'
import errorIcon from '@icons/alert/error_outline.svg'

import styles from './styles.module.scss'

export const NotFound = () => (
  <div className={styles.notFound}>
    <Icon noCurrentColorSvgFill src={errorIcon} className={styles.errorIcon} />
    <Typography variant={'headlineH3'} className={styles.title}>
      СЗПК не найдены
    </Typography>
    <Typography variant={'bodyMRegular'} className={styles.description}>
      Проверьте, что номер ИНН и форма реорганизации указаны верно
    </Typography>
  </div>
)
