import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo } from 'react'
import { ReactSVG } from 'react-svg'

import Typography from '@components/NewDesign/Typography'
import AddButtonIcon from '@icons/AddButtonIcon.svg'
import cn from 'classnames'

import styles from './ItemCreate.module.scss'

interface DocumentSetItemProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  title: string
  subtitle?: string

  positionOfIcon?: 'left' | 'right'
  dataTestId?: string
}

const DocumentSetItemCreate: FC<DocumentSetItemProps> = ({
  type = 'button',
  positionOfIcon = 'right',
  title,
  subtitle,
  dataTestId,
  ...restProps
}) => {
  const isLeftPosition = positionOfIcon === 'left'
  const isRightPosition = positionOfIcon === 'right'

  return (
    <button
      type={type}
      {...restProps}
      data-testid={dataTestId}
      className={cn(
        styles.documentItemCreate,
        {
          [styles['documentItemCreate--leftPositionIcon']]: isLeftPosition,
        },
        restProps.className,
      )}
      onClick={restProps.onClick}
    >
      {isLeftPosition && (
        <div
          className={cn(styles.documentItemCreate__icon, {
            [styles['documentItemCreate__icon--leftPosition']]: isLeftPosition,
          })}
        >
          <ReactSVG src={AddButtonIcon} as={'span'} />
        </div>
      )}
      <div className={styles.documentItemCreate__info}>
        <Typography.Body color={'text-accent-brand'} variant={'bodyMMedium'}>
          {title}
        </Typography.Body>
        {subtitle && (
          <Typography.Caption color={'text-accent-brand'} variant={'captionSRegular'}>
            {subtitle}
          </Typography.Caption>
        )}
      </div>

      {isRightPosition && (
        <div className={styles.documentItemCreate__icon}>
          <ReactSVG src={AddButtonIcon} as={'span'} className={styles.documentItemCreate__svg} />
        </div>
      )}
    </button>
  )
}

export default memo(DocumentSetItemCreate)
