import Layout from '@layouts'
import styles from '@routes/Projects/[id]/Project.module.scss'
import ProjectLayout from '@routes/Projects/[id]/ProjectLayout'
import ProjectPermissionsManager from '@routes/Projects/shared/ProjectPermissionManager'

const ProjectPage = () => {
  return (
    <ProjectPermissionsManager>
      <Layout.Main
        headerContentContainer={{
          className: styles['projectPage__header-container'],
        }}
      >
        <ProjectLayout />
      </Layout.Main>
    </ProjectPermissionsManager>
  )
}

export default ProjectPage
