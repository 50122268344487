import React, { FC, memo, useEffect } from 'react'
import { ReactSVG } from 'react-svg'

import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import type { InitialChoseErrors } from '@components/RegistryNpa/ChooseOld/ManagerOld/types'
import PartItem from '@components/RegistryNpa/MasterItem/Parts/Item'
import { defaultRHFValidation } from '@constants/validations'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useHover } from '@hooks/new/events/useHover'
import { useBooleanState } from '@hooks/useBooleanState'
import AddButtonIcon from '@icons/AddButtonIcon.svg'
import ChosenIcon from '@icons/ChoosenIcon.svg'
import cn from 'classnames'

import styles from './PartNpaItemOld.module.scss'

export interface PartFormValues {
  justification: string
  partCorrection: string
}

interface PartNpaItemOldProps {
  partId: string
  part: string
  classificationHeader: string
  onChoosePart: VoidFunction
  onChangePart: (newValue: PartFormValues) => void

  initialChoseValues?: PartFormValues
  initialChoseErrors?: InitialChoseErrors
}

const PartNpaItemOld: FC<PartNpaItemOldProps> = ({
  partId,
  part,
  classificationHeader,
  initialChoseValues,
  initialChoseErrors,
  onChoosePart,
  onChangePart,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>()

  const { booleanState: isChosen, setBooleanState: setIsChosen } = useBooleanState(
    !!initialChoseValues,
  )

  const {
    booleanState: isMountChildren,
    setBooleanStateToTrue: mountChildren,
    setBooleanStateToFalse: unmountChildren,
  } = useBooleanState(!!initialChoseValues)

  const { control, getValues, setError, setFocus } = useFormWithDefaultProps<PartFormValues>({
    defaultValues: {
      justification: initialChoseValues?.justification || '',
      partCorrection: initialChoseValues?.partCorrection || '',
    },
  })

  const { getCollapseProps } = useCollapse({
    isExpanded: isChosen,
    onExpandStart: () => {
      mountChildren()
    },
    onCollapseEnd: () => {
      unmountChildren()
    },
  })

  useEffect(() => {
    if (initialChoseErrors?.justification) {
      setError('justification', {
        type: 'error',
        message: initialChoseErrors?.justification,
      })
    }

    if (initialChoseErrors?.partCorrection) {
      setError('partCorrection', {
        type: 'error',
        message: initialChoseErrors?.partCorrection,
      })
    }
  }, [initialChoseErrors?.justification, initialChoseErrors?.partCorrection, setError])

  const handleChangePart = () => {
    const formValues = getValues()

    onChangePart(formValues)
  }

  const handleTogglePart = () => {
    setIsChosen((state) => !state)

    onChoosePart()
  }

  useEffect(() => {
    if (!isChosen || !isMountChildren) return

    setTimeout(() => setFocus('justification'), 0)
  }, [isChosen, isMountChildren, setFocus])

  return (
    <button
      type="button"
      ref={hoverRef}
      data-testid={`PartNpaItem-part-${partId}-itemButton`}
      className={cn(styles.partNpaItem, {
        [styles['partNpaItem--hovered']]: isHovered || isChosen,
      })}
    >
      <PartItem
        contentClassName={styles.partNpaItem__content}
        part={part}
        classificationHeader={classificationHeader}
      >
        <ReactSVG
          data-testid={`PartNpaItem-part-${partId}-togglePartItem`}
          src={isChosen ? ChosenIcon : AddButtonIcon}
          onClick={handleTogglePart}
        />
      </PartItem>

      <div className={styles.partNpaItem__collapse} {...getCollapseProps()}>
        {isMountChildren && (
          <div className={styles.partNpaItem__collapse}>
            <div className={styles.partNpaItem__inputs}>
              <ControlledInput
                name={'justification'}
                control={control}
                rules={{
                  required: defaultRHFValidation.required,
                }}
                inputProps={{
                  rootClassName: styles['partNpaItem__inputs-justification'],
                  size: 's',
                  placeholder: 'Обоснование (обязательно)',
                  dataTestId: `PartNpaItem-part-${partId}-justification-input`,
                }}
                onBlur={handleChangePart}
              />
              <ControlledInput
                name={'partCorrection'}
                control={control}
                inputProps={{
                  rootClassName: styles['partNpaItem__inputs-partCorrection'],
                  size: 's',
                  placeholder:
                    'Положения (пункты и статьи), которые хотите стабилизировать (например, ч. 1 ст. 5)',
                  dataTestId: `PartNpaItem-part-${partId}-partCorrection-input`,
                }}
                onBlur={handleChangePart}
              />
            </div>
          </div>
        )}
      </div>
    </button>
  )
}

export default memo(PartNpaItemOld)
