import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useNotificationOfChangeDocumentsManager } from '@components/Forms/NotificationOfChangeDocumentsForm/Manager'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'

import { mapOfNotificationOfChangeDocumentsMenu } from '../const'
import NotificationOfChangeDocumentsForms from '../Forms'

const mapOfNotificationOfChangeDocumentsForms: FormItemProps = {
  '1': {
    node: <NotificationOfChangeDocumentsForms.First />,
  },
  '2': {
    node: <NotificationOfChangeDocumentsForms.Second />,
  },
} as const

const NotificationOfChangeDocumentsForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<NotificationOfChangeDocumentsFormValues>()

  const {
    state: { formIsLoading },
  } = useNotificationOfChangeDocumentsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNotificationOfChangeDocumentsMenu}
      mapOfForms={mapOfNotificationOfChangeDocumentsForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NotificationOfChangeDocumentsForm)
