import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFindFormValueDifferences } from '@components/DocumentFormComponents/hooks/useFindFormValueDifferences'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import {
  FormModalContextProps,
  FormModalManagerProps,
} from '@components/DocumentFormComponents/types'
import {
  ApplicationOfConclusionAgreementSZPKRegisterFieldArrayControlUpdateWatcher,
  ApplicationOfConclusionAgreementSZPKRegisterFieldsControlUpdateWatcher,
} from '@components/Forms/ApplicationOfConclusionAgreementSZPKForm/watcher'
import { isNull } from '@helpers/checkTypes'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'

import { useApplicationOfConclusionAgreementSZPKRegisterAdapters } from '../adapters'
import { ApplicationOfConclusionAgreementSZPKFormValues } from '../types'

const { getDefaultFormModalContextValue } = DocumentFormHelpers

export const ApplicationOfConclusionAgreementSZPKRegisterModalContext = createContext<
  FormModalContextProps<ApplicationOfConclusionAgreementSZPKFormValues>
>(getDefaultFormModalContextValue())

const useApplicationOfConclusionAgreementSZPKRegisterManager = () => {
  return useContext(ApplicationOfConclusionAgreementSZPKRegisterModalContext)
}

const ApplicationOfConclusionAgreementSZPKRegisterModalManager: FC<FormModalManagerProps> = ({
  projectId,
  formId,
  editMode,
  initialErrorsFromViolations,
  onClose,
  children,
}) => {
  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  const { updateLastRFHBeforeValue, getLastRHFBeforeValue } =
    useFormPreviousValue<ApplicationOfConclusionAgreementSZPKFormValues>()

  const formInstance = useFormContext<ApplicationOfConclusionAgreementSZPKFormValues>()
  const { processOverrideProps, getOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const { formIsLoading, handleMountForm } = useFormMount(formId)
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const { generatePropertiesObject, generateRHFObject } =
    useApplicationOfConclusionAgreementSZPKRegisterAdapters()

  const { getBasePropsFromObjectAdapter } = useGenerateValuesFromObjectAdapter({
    generateRHFObject,
    generatePropertiesObject,
  })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
  })

  const { handleNotifyAllSubscribers } = useNotifyAllSubscribers({
    watcher: ApplicationOfConclusionAgreementSZPKRegisterFieldsControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const { applyDifferences } = useFindFormValueDifferences({
    watcher: ApplicationOfConclusionAgreementSZPKRegisterFieldArrayControlUpdateWatcher,
    setValue: formInstance.setValue,
  })

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()

      if (isNull(prevRHFValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const readyOverrideProps = await processOverrideProps(props.overridePropsFromChanges)

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      unstable_batchedUpdates(() => {
        applyDifferences(prevRHFValue, RHFValueForReset)
        updateLastRFHBeforeValue(RHFValueForReset)

        setTimeout(() => {
          ApplicationOfConclusionAgreementSZPKRegisterFieldsControlUpdateWatcher.notifyAllSubscribers(
            readyOverrideProps,
          )
        }, 0)

        handleSetLastUpdateFormToNow()
      })
    },
    [
      formInstance,
      generatePropertiesObject,
      generateRHFObject,
      handleSetLastUpdateFormToNow,
      processOverrideProps,
      updateLastRFHBeforeValue,
    ],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const {
    handleAddItemToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleChangeValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    debouncedHandleChangeValue,
  } = useFormUpdate({
    formId,
    getPropertiesProps: () => propertiesPropsRef.current,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: applyFormChanges(handleApplyChanges),
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const { handleCheckForm, handlePreviewForm } = useFormActions({
    formId,
    watcher: ApplicationOfConclusionAgreementSZPKRegisterFieldsControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    handleApplyChanges: checkFormChanges,
    onClearError: formInstance.clearErrors,
  })

  const handleSuccessInitializeForm = async ({
    lastChangesDt,
    propertyInstance,
  }: SuccessInitialCallbackReturn) => {
    lastFieldUpdateTime.current = lastChangesDt

    propertyInstanceRef.current = propertyInstance

    const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

    const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(objectForAdapters)

    //Установка propertiesProps
    propertiesPropsRef.current = cloneDeep(propertiesProps)

    formInstance.reset(RHFValueForReset)
    updateLastRFHBeforeValue(RHFValueForReset)
  }

  //Инициализация формы
  useEffect(() => {
    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleNotifyAllSubscribers,
    })
  }, [])

  const preparedValue: FormModalContextProps<ApplicationOfConclusionAgreementSZPKFormValues> =
    useMemo(
      () => ({
        state: {
          formIsLoading,
          blockViewIsValidating,
          lastUpdateDraftTime,
          initialErrorsFromViolations,
          editMode,
        },
        handlers: {
          checkFormChanges,
          getPropertiesProps,
          getOverrideProps,
          handleChangeValue,
          handleAddCustomValue,
          handleRemoveCustomValue,
          debouncedHandleChangeValue,
          handleAddItemToListWithOutValue,
          handleAddItemToListWithValue,
          handleRemoveItemFromList,
          applyFormChanges: applyFormChanges(handleApplyChanges),
          handleChangeBlockValidation,
        },
        actions: {
          handleFormClose: onClose,
          handleCheckForm,
          handlePreviewForm,
        },
        preparedProps: {
          subscribableControl,
        },
      }),
      [
        formIsLoading,
        blockViewIsValidating,
        lastUpdateDraftTime,
        initialErrorsFromViolations,
        editMode,
        checkFormChanges,
        getPropertiesProps,
        getOverrideProps,
        handleChangeValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        debouncedHandleChangeValue,
        handleAddItemToListWithOutValue,
        handleAddItemToListWithValue,
        handleRemoveItemFromList,
        applyFormChanges,
        handleApplyChanges,
        handleChangeBlockValidation,
        onClose,
        handleCheckForm,
        handlePreviewForm,
        subscribableControl,
      ],
    )

  return (
    <ApplicationOfConclusionAgreementSZPKRegisterModalContext.Provider value={preparedValue}>
      {children}
    </ApplicationOfConclusionAgreementSZPKRegisterModalContext.Provider>
  )
}

export { useApplicationOfConclusionAgreementSZPKRegisterManager }

export default ApplicationOfConclusionAgreementSZPKRegisterModalManager
