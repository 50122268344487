import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultAAVolumeOfCapitalInvestmentsValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/const'
import AAVolumeOfCapitalInvestmentsLayout from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Layout'

const AAVolumeOfCapitalInvestmentsLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultAAVolumeOfCapitalInvestmentsValues}>
      <AAVolumeOfCapitalInvestmentsLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(AAVolumeOfCapitalInvestmentsLayoutWithFormProvider)
