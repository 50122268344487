import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  ICompensationObjectExpense,
  ICompensationObjectPaidTaxLegalPropertyValue,
  ICompensationObjects,
  ParametersOfCostRecoveryApplicationFormValues,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation, standardAmountValidate } from '@constants/validations'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

const compensationStatementExpenseSubTypeValidationMap: FormValuesValidationSection<
  keyof ICompensationObjects,
  ParametersOfCostRecoveryApplicationFormValues
> = {
  projectObjectValueName: {
    required: defaultRHFValidation.required,
  },
  objectName: {
    required: defaultRHFValidation.required,
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  finalBalanceHolder: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  infrastructureKindCode: {
    required: defaultRHFValidation.required,
  },
  compensationObjectTaxLegalPropertyRefundPeriod: {
    required: defaultRHFValidation.required,
  },
  compensationObjectTaxLegalPropertyCadastralNumber: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  compensationObjectTaxLegalPropertyOtherIdNumber: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },

  compensationObjectTaxLandRefundPeriod: {
    required: defaultRHFValidation.required,
  },
  compensationObjectTaxLandAreaNumber: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  compensationObjectCreditorName: {
    required: defaultRHFValidation.required,
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  compensationObjectAgreementNumber: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  compensationObjectAgreementDate: {
    required: defaultRHFValidation.required,

    validate: {
      positiveCurrentDate: (value) => {
        if (!isString(value)) return

        const currentDate = DayjsService.dayjsWithFormatToMSK()

        return (
          (isDateValidForDayjs(value) &&
            DayjsService.dayjs(value, objOfDateFormats.defaultFormat) <= currentDate) ||
          'дата должна быть меньше или равна текущей дате'
        )
      },
    },
  },
  compensationObjectBondNumber: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
}

const compensationObjectExpenseValidationMap: FormValuesValidationSection<
  keyof ICompensationObjectExpense,
  ParametersOfCostRecoveryApplicationFormValues
> = {
  compensationObjectExpenseYear: {
    required: defaultRHFValidation.required,
  },
  compensationObjectExpenseValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

const compensationObjectPaidTaxValidationMap: FormValuesValidationSection<
  keyof ICompensationObjectPaidTaxLegalPropertyValue,
  ParametersOfCostRecoveryApplicationFormValues
> = {
  paidTaxYearValue: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export {
  compensationObjectExpenseValidationMap,
  compensationObjectPaidTaxValidationMap,
  compensationStatementExpenseSubTypeValidationMap,
}
