import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeOfProjectRefuseConcludeAABlockValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/const'
import { useNoticeOfProjectRefuseConcludeAAManager } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/Manager'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'
import { NoticeOfProjectRefuseConcludeAAFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const currentBlockValues = noticeOfProjectRefuseConcludeAABlockValues['2']

const Second = () => {
  const formInstance = useFormContext<NoticeOfProjectRefuseConcludeAAFormValues>()
  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useNoticeOfProjectRefuseConcludeAAManager()

  const { getSubscribableControlProps, getControllerProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NoticeOfProjectRefuseConcludeAAFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: currentBlockValues.rfFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                controllerProps={{
                  ...getControllerProps({
                    name: currentBlockValues.rfFullName,
                  }),
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
