import { mapOfAdapterTypes } from '@components/DocumentFormComponents/adapters/const'
import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { InitiatorDefaultFormField } from '@components/DocumentFormComponents/FieldState/FieldState.entity'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  DataOnFulfillmentOfTermsSZPKFormValues,
  EconomicSphereOkvedRegionalReportCard,
  EconomicSphereRegionalReportCard,
  SzpkChangeTerminateCard,
  SzpkReportInfoCard,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { filterArrayIntoTwoChunks } from '@helpers/array/filterArrayIntoChunks'
import { isArray } from '@helpers/checkTypes'
import { convertStringToDateFormat } from '@helpers/date/convertStringToDateFormat'
import plural from 'plural-ru'

import { getCallbackFilterSzpkReportsInfoByAdapterType } from './helpers'
const { formatBackendMoneyToString } = DocumentFormHelpers

const generateRHFObjectForDataOnFulfillmentOfTermsSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): DataOnFulfillmentOfTermsSZPKFormValues => {
  const preparedSigner: Partial<InitiatorDefaultFormField> = (() => {
    const defaultSignerValue = {
      initiatorHeaderName: null,
      initiatorHeaderPosition: '',
    }

    const docSigners = objectFromPropertyState.docSigners?.value
    if (!docSigners || !docSigners.length) return defaultSignerValue

    const headerInfo = docSigners.find((docSigner) => {
      return (
        docSigner.value?.signerOrganization?.value.id ===
        objectFromPropertyState.initiator?.permanentId
      )
    })

    if (!headerInfo) return defaultSignerValue

    return {
      initiatorHeaderName: !!headerInfo.value.signer?.value?.id
        ? {
            value: headerInfo.value.signer?.value?.id || '',
            displayValue: headerInfo.value.signer?.value?.headerName || '',
          }
        : null,
      initiatorHeaderPosition: headerInfo.value.signer?.value?.headerPosition || '',
    }
  })()

  const initialSzpkReportsInfo = isArray(objectFromPropertyState.szpkReportsInfo?.value)
    ? objectFromPropertyState.szpkReportsInfo?.value
    : []

  // актуальные проекты - те, которые имеют заполненные данные
  // заполненность данными можно узнать например по полю investorFullName
  const actualSzpkReportsInfo = initialSzpkReportsInfo.filter(
    (szpkReportInfo) => !!szpkReportInfo.value?.investorFullName?.value,
  )

  const getPreparedSzpkReportsInfoByBackendList = (szpkReportsInfoList) =>
    szpkReportsInfoList.map(
      (szpkReportInfo): SzpkReportInfoCard => ({
        id: szpkReportInfo.propertyId,
        isFederal: szpkReportInfo.value?.isFederal?.value,
        investorFullName: szpkReportInfo.value?.investorFullName?.value,
        projectName: szpkReportInfo.value?.projectName?.value,
        szpkRegistrationDate: szpkReportInfo.value?.szpkRegistrationDate?.value,
        reportSignDate: szpkReportInfo.value?.reportSignDate?.value,

        totalCapitalFact: szpkReportInfo.value?.totalCapitalFact?.value,
        totalCapitalFactChecker: szpkReportInfo.value?.totalCapitalFactChecker?.value,
        totalCapitalPlan: szpkReportInfo.value?.totalCapitalPlan?.value,
        totalCapitalPlanChecker: szpkReportInfo.value?.totalCapitalPlanChecker?.value,

        investorCapitalFact: szpkReportInfo.value?.investorCapitalFact?.value,
        investorCapitalFactChecker: szpkReportInfo.value?.investorCapitalFactChecker?.value,
        investorCapitalPlan: szpkReportInfo.value?.investorCapitalPlan?.value,
        investorCapitalPlanChecker: szpkReportInfo.value?.investorCapitalPlanChecker?.value,

        newWorkPlacesFact: szpkReportInfo.value?.newWorkPlacesFact?.value,
        newWorkPlacesFactChecker: szpkReportInfo.value?.newWorkPlacesFactChecker?.value,
        newWorkPlacesPlan: szpkReportInfo.value?.newWorkPlacesPlan?.value,
        newWorkPlacesPlanChecker: szpkReportInfo.value?.newWorkPlacesPlanChecker?.value,

        compensationValueFact: szpkReportInfo.value?.compensationValueFact?.value,
        compensationValueFactChecker: szpkReportInfo.value?.compensationValueFactChecker?.value,
        compensationValuePlan: szpkReportInfo.value?.compensationValuePlan?.value,
        compensationValuePlanChecker: szpkReportInfo.value?.compensationValuePlanChecker?.value,

        projectOkvedMonitoring: szpkReportInfo.value?.projectOkvedMonitoring?.value,
      }),
    )

  const preparedSzpkReportsInfo: SzpkReportInfoCard[] = (() => {
    if (!isArray(actualSzpkReportsInfo) || !actualSzpkReportsInfo.length) return []

    return getPreparedSzpkReportsInfoByBackendList(actualSzpkReportsInfo)
  })()

  const preparedEconomicSpheresRegionalReport: EconomicSphereRegionalReportCard[] = (() => {
    if (
      !isArray(objectFromPropertyState.economicSpheresRegionalReport?.value) ||
      !objectFromPropertyState.economicSpheresRegionalReport?.value?.length
    )
      return []

    return objectFromPropertyState.economicSpheresRegionalReport?.value.map(
      (
        economicSphereRegionalReport,
        indexOfEconomicSphereRegionalReport,
      ): EconomicSphereRegionalReportCard => {
        const preparedEconomicSphereOkvedsRegionalReport = (() => {
          const economicSphereOkvedsRegionalReportItems =
            economicSphereRegionalReport?.value?.economicSphereOkvedsRegionalReport

          if (
            !isArray(economicSphereOkvedsRegionalReportItems?.value) ||
            !economicSphereOkvedsRegionalReportItems?.value?.length
          )
            return []

          return economicSphereOkvedsRegionalReportItems?.value?.map(
            (
              economicSphereOkvedRegionalReport,
              indexOfEconomicSphereOkvedRegionalReport,
            ): EconomicSphereOkvedRegionalReportCard => {
              const preparedRegionsOkvedReportInfo = (() => {
                const szpkReportsInfo = objectFromPropertyState.szpkReportsInfo?.value
                if (!isArray(szpkReportsInfo) || !szpkReportsInfo) return []

                const currentSzpkReportsInfo = szpkReportsInfo?.filter((szpkReportInfo) => {
                  const szpkReportInfoOkvedCode =
                    String(szpkReportInfo?.value?.projectOkvedMonitoring?.value?.code) || ''
                  const szpkReportInfoOkvedCodeBeforeFirstPoint =
                    szpkReportInfoOkvedCode.split('.')[0]

                  const economicSphereOkvedCode =
                    String(
                      economicSphereOkvedRegionalReport?.value
                        ?.economicSphereOkvedCodeRegionalReport?.value?.code,
                    ) || ''
                  const economicSphereOkvedCodeBeforeFirstPoint =
                    economicSphereOkvedCode.split('.')[0]

                  return (
                    !!szpkReportInfoOkvedCodeBeforeFirstPoint &&
                    !!economicSphereOkvedCodeBeforeFirstPoint &&
                    szpkReportInfoOkvedCodeBeforeFirstPoint ===
                      economicSphereOkvedCodeBeforeFirstPoint
                  )
                })

                return getPreparedSzpkReportsInfoByBackendList(currentSzpkReportsInfo)
              })()

              return {
                id: economicSphereOkvedRegionalReport.permanentId,
                economicSphereOkvedCodeRegionalReport:
                  economicSphereOkvedRegionalReport?.value?.economicSphereOkvedCodeRegionalReport
                    ?.value,

                countEconomicSphereOkvedRegionalReport:
                  economicSphereOkvedRegionalReport?.value?.countEconomicSphereOkvedRegionalReport
                    ?.value,
                countEconomicSphereOkvedRegionalReportCorrect:
                  economicSphereOkvedRegionalReport?.value
                    ?.countEconomicSphereOkvedRegionalReportCorrect?.value,

                totalCapitalEconomicSphereOkvedRegionalReport:
                  economicSphereOkvedRegionalReport?.value
                    ?.totalCapitalEconomicSphereOkvedRegionalReport?.value,
                totalCapitalEconomicSphereOkvedRegionalReportChecker:
                  economicSphereOkvedRegionalReport?.value
                    ?.totalCapitalEconomicSphereOkvedRegionalReportChecker?.value,
                totalCapitalEconomicSphereOkvedRegionalReportCorrect:
                  economicSphereOkvedRegionalReport?.value
                    ?.totalCapitalEconomicSphereOkvedRegionalReportCorrect?.value,

                investorCapitalEconomicSphereOkvedRegionalReport:
                  economicSphereOkvedRegionalReport?.value
                    ?.investorCapitalEconomicSphereOkvedRegionalReport?.value,
                investorCapitalEconomicSphereOkvedRegionalReportChecker:
                  economicSphereOkvedRegionalReport?.value
                    ?.investorCapitalEconomicSphereOkvedRegionalReportChecker?.value,
                investorCapitalEconomicSphereOkvedRegionalReportCorrect:
                  economicSphereOkvedRegionalReport?.value
                    ?.investorCapitalEconomicSphereOkvedRegionalReportCorrect?.value,

                newWorkPlacesEconomicSphereOkvedRegionalReport:
                  economicSphereOkvedRegionalReport?.value
                    ?.newWorkPlacesEconomicSphereOkvedRegionalReport?.value,
                newWorkPlacesEconomicSphereOkvedRegionalReportChecker:
                  economicSphereOkvedRegionalReport?.value
                    ?.newWorkPlacesEconomicSphereOkvedRegionalReportChecker?.value,
                newWorkPlacesEconomicSphereOkvedRegionalReportCorrect:
                  economicSphereOkvedRegionalReport?.value
                    ?.newWorkPlacesEconomicSphereOkvedRegionalReportCorrect?.value,

                szpkReportsInfo: preparedRegionsOkvedReportInfo,
              }
            },
          )
        })()

        return {
          id: economicSphereRegionalReport.propertyId,
          economicSphereNameRegionalReport:
            economicSphereRegionalReport?.value?.economicSphereNameRegionalReport?.value,
          economicSphereOkvedsRegionalReport: preparedEconomicSphereOkvedsRegionalReport,
        }
      },
    )
  })()

  const preparedSzpkChangeTerminate: SzpkChangeTerminateCard[] = (() => {
    if (
      !isArray(objectFromPropertyState.szpksChangeTerminate?.value) ||
      !objectFromPropertyState.szpksChangeTerminate.value.length
    )
      return []

    return objectFromPropertyState.szpksChangeTerminate?.value.map(
      (szpkChangeTerminate): SzpkChangeTerminateCard => ({
        id: szpkChangeTerminate.propertyId,
        isChange: szpkChangeTerminate.value?.isChanges?.value,
        isTerminate: szpkChangeTerminate.value?.isTerminate?.value,
        //hided
        registrationDate: convertStringToDateFormat(
          szpkChangeTerminate.value?.szpkReportId.value?.szpkRegistrationDate,
        ),
        projectName: szpkChangeTerminate.value?.szpkReportId.value?.projectName,
        szpkNumber: szpkChangeTerminate.value?.szpkReportId.value?.szpkNumber,
        //group
        totalCapital: formatBackendMoneyToString(
          szpkChangeTerminate.value?.szpkReportId.value?.totalCapitalFact,
        ),
        totalCapitalChecker: formatBackendMoneyToString(
          szpkChangeTerminate.value?.szpkReportId.value?.totalCapitalFactChecker,
        ),
        totalCapitalCorrect: szpkChangeTerminate.value?.totalCapitalCorrect?.value,
        //group
        investorCapital: formatBackendMoneyToString(
          szpkChangeTerminate.value?.szpkReportId.value?.investorCapitalFact,
        ),
        investorCapitalChecker: formatBackendMoneyToString(
          szpkChangeTerminate.value?.szpkReportId.value?.investorCapitalFactChecker,
        ),
        investorCapitalCorrect: szpkChangeTerminate.value?.investorCapitalCorrect?.value,
        //group
        changeReasons:
          isArray(szpkChangeTerminate.value?.szpkReportId.value?.changeReasons) &&
          szpkChangeTerminate.value?.szpkReportId.value?.changeReasons.length
            ? szpkChangeTerminate.value?.szpkReportId.value?.changeReasons.map(
                (reason: string) => reason,
              )
            : [],
        changeReasonsChecker:
          isArray(szpkChangeTerminate.value?.szpkReportId.value?.changeReasonsChecker) &&
          szpkChangeTerminate.value?.szpkReportId.value?.changeReasonsChecker.length
            ? szpkChangeTerminate.value?.szpkReportId.value?.changeReasonsChecker.map(
                (reason: string) => reason,
              )
            : [],
        changeTerminateReason: szpkChangeTerminate.value?.changeTerminateReason?.value,
      }),
    )
  })()

  const [preparedFederalSzpkReportsInfo, preparedNotFederalSzpkReportsInfo] =
    filterArrayIntoTwoChunks(preparedSzpkReportsInfo, (szpkReport) => szpkReport.isFederal)

  const preparedFederalSzpkReportsInfoWithinMonitoringDates = preparedFederalSzpkReportsInfo.filter(
    getCallbackFilterSzpkReportsInfoByAdapterType(objectFromPropertyState, mapOfAdapterTypes.RHF),
  )

  const preparedNotFederalSzpkReportsInfoWithinMonitoringDates =
    preparedNotFederalSzpkReportsInfo.filter(
      getCallbackFilterSzpkReportsInfoByAdapterType(objectFromPropertyState, mapOfAdapterTypes.RHF),
    )

  const preparedSignedSzpkReportsSubtitle = (() => {
    const allSzpkReportsCount = initialSzpkReportsInfo.length
    const signedSzpkReportsCount = preparedSzpkReportsInfo.filter(
      (szpkReport) => !!szpkReport.reportSignDate,
    ).length

    return `${plural(signedSzpkReportsCount, 'Подан', 'Подано', 'Подано')} ${signedSzpkReportsCount}
    ${plural(signedSzpkReportsCount, 'отчет', 'отчета', 'отчетов')} ОРП из ${allSzpkReportsCount}`
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      initiatorFullName:
        objectFromPropertyState?.initiator?.value?.memberOrganizationId?.value?.memberFullName ||
        '',
      ...preparedSigner,
    },
    '3': {
      signedSzpkReportsSubtitle: preparedSignedSzpkReportsSubtitle,

      szpkCountRegionalReportPlan:
        objectFromPropertyState?.szpkCountRegionalReportPlan?.value || '',
      szpkCountRegionalReportFact:
        objectFromPropertyState?.szpkCountRegionalReportFact?.value || '',
      federalSzpkCountRegionalReportPlan:
        objectFromPropertyState?.federalSzpkCountRegionalReportPlan?.value || '',
      federalSzpkCountRegionalReportPlanCorrect:
        objectFromPropertyState?.federalSzpkCountRegionalReportPlanCorrect?.value || '',
      federalSzpkCountRegionalReportFact:
        objectFromPropertyState?.federalSzpkCountRegionalReportFact?.value || '',
      federalSzpkCountRegionalReportFactCorrect:
        objectFromPropertyState?.federalSzpkCountRegionalReportFactCorrect?.value || '',
      regionalSzpkCountRegionalReportPlan:
        objectFromPropertyState?.regionalSzpkCountRegionalReportPlan?.value || '',
      regionalSzpkCountRegionalReportPlanCorrect:
        objectFromPropertyState?.regionalSzpkCountRegionalReportPlanCorrect?.value || '',
      regionalSzpkCountRegionalReportFact:
        objectFromPropertyState?.regionalSzpkCountRegionalReportFact?.value || '',
      regionalSzpkCountRegionalReportFactCorrect:
        objectFromPropertyState?.regionalSzpkCountRegionalReportFactCorrect?.value || '',
    },
    '4': {
      szpkPeriodCountRegionalReportPlan:
        objectFromPropertyState?.szpkPeriodCountRegionalReportPlan?.value || '',
      szpkPeriodCountRegionalReportFact:
        objectFromPropertyState?.szpkPeriodCountRegionalReportFact?.value || '',

      federalSzpkPeriodCountRegionalReportPlan:
        objectFromPropertyState?.federalSzpkPeriodCountRegionalReportPlan?.value || '',
      federalSzpkPeriodCountRegionalReportPlanCorrect:
        objectFromPropertyState?.federalSzpkPeriodCountRegionalReportPlanCorrect?.value || '',
      federalSzpkPeriodCountRegionalReportFact:
        objectFromPropertyState?.federalSzpkPeriodCountRegionalReportFact?.value || '',
      federalSzpkPeriodCountRegionalReportFactCorrect:
        objectFromPropertyState?.federalSzpkPeriodCountRegionalReportFactCorrect?.value || '',

      regionalSzpkPeriodCountRegionalReportPlan:
        objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportPlan?.value || '',
      regionalSzpkPeriodCountRegionalReportPlanCorrect:
        objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportPlanCorrect?.value || '',
      regionalSzpkPeriodCountRegionalReportFact:
        objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportFact?.value || '',
      regionalSzpkPeriodCountRegionalReportFactCorrect:
        objectFromPropertyState?.regionalSzpkPeriodCountRegionalReportFactCorrect?.value || '',
    },
    '5': {
      totalCapitalRegionalReportPlan:
        objectFromPropertyState?.totalCapitalRegionalReportPlan?.value || '',
      totalCapitalRegionalReportFact:
        objectFromPropertyState?.totalCapitalRegionalReportFact?.value || '',

      federalTotalCapitalRegionalReportPlan:
        objectFromPropertyState?.federalTotalCapitalRegionalReportPlan?.value || '',
      federalTotalCapitalRegionalReportPlanChecker:
        objectFromPropertyState?.federalTotalCapitalRegionalReportPlanChecker?.value || '',
      federalTotalCapitalRegionalReportPlanCorrect:
        objectFromPropertyState?.federalTotalCapitalRegionalReportPlanCorrect?.value || '',
      federalTotalCapitalRegionalReportFact:
        objectFromPropertyState?.federalTotalCapitalRegionalReportFact?.value || '',
      federalTotalCapitalRegionalReportFactChecker:
        objectFromPropertyState?.federalTotalCapitalRegionalReportFactChecker?.value || '',
      federalTotalCapitalRegionalReportFactCorrect:
        objectFromPropertyState?.federalTotalCapitalRegionalReportFactCorrect?.value || '',

      regionalTotalCapitalRegionalReportPlan:
        objectFromPropertyState?.regionalTotalCapitalRegionalReportPlan?.value || '',
      regionalTotalCapitalRegionalReportPlanChecker:
        objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanChecker?.value || '',
      regionalTotalCapitalRegionalReportPlanCorrect:
        objectFromPropertyState?.regionalTotalCapitalRegionalReportPlanCorrect?.value || '',
      regionalTotalCapitalRegionalReportFact:
        objectFromPropertyState?.regionalTotalCapitalRegionalReportFact?.value || '',
      regionalTotalCapitalRegionalReportFactChecker:
        objectFromPropertyState?.regionalTotalCapitalRegionalReportFactChecker?.value || '',
      regionalTotalCapitalRegionalReportFactCorrect:
        objectFromPropertyState?.regionalTotalCapitalRegionalReportFactCorrect?.value || '',
    },
    '6': {
      investorCapitalRegionalReportPlan:
        objectFromPropertyState?.investorCapitalRegionalReportPlan?.value || '',
      investorCapitalRegionalReportFact:
        objectFromPropertyState?.investorCapitalRegionalReportFact?.value || '',

      federalInvestorCapitalRegionalReportPlan:
        objectFromPropertyState?.federalInvestorCapitalRegionalReportPlan?.value || '',
      federalInvestorCapitalRegionalReportPlanChecker:
        objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanChecker?.value || '',
      federalInvestorCapitalRegionalReportPlanCorrect:
        objectFromPropertyState?.federalInvestorCapitalRegionalReportPlanCorrect?.value || '',
      federalInvestorCapitalRegionalReportFact:
        objectFromPropertyState?.federalInvestorCapitalRegionalReportFact?.value || '',
      federalInvestorCapitalRegionalReportFactChecker:
        objectFromPropertyState?.federalInvestorCapitalRegionalReportFactChecker?.value || '',
      federalInvestorCapitalRegionalReportFactCorrect:
        objectFromPropertyState?.federalInvestorCapitalRegionalReportFactCorrect?.value || '',

      regionalInvestorCapitalRegionalReportPlan:
        objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlan?.value || '',
      regionalInvestorCapitalRegionalReportPlanChecker:
        objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanChecker?.value || '',
      regionalInvestorCapitalRegionalReportPlanCorrect:
        objectFromPropertyState?.regionalInvestorCapitalRegionalReportPlanCorrect?.value || '',
      regionalInvestorCapitalRegionalReportFact:
        objectFromPropertyState?.regionalInvestorCapitalRegionalReportFact?.value || '',
      regionalInvestorCapitalRegionalReportFactChecker:
        objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactChecker?.value || '',
      regionalInvestorCapitalRegionalReportFactCorrect:
        objectFromPropertyState?.regionalInvestorCapitalRegionalReportFactCorrect?.value || '',
    },
    '7': {
      newWorkPlacesRegionalReportPlan:
        objectFromPropertyState?.newWorkPlacesRegionalReportPlan?.value || '',
      newWorkPlacesRegionalReportFact:
        objectFromPropertyState?.newWorkPlacesRegionalReportFact?.value || '',

      federalNewWorkPlacesRegionalReportPlan:
        objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlan?.value || '',
      federalNewWorkPlacesRegionalReportPlanChecker:
        objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanChecker?.value || '',
      federalNewWorkPlacesRegionalReportPlanCorrect:
        objectFromPropertyState?.federalNewWorkPlacesRegionalReportPlanCorrect?.value || '',
      federalNewWorkPlacesRegionalReportFact:
        objectFromPropertyState?.federalNewWorkPlacesRegionalReportFact?.value || '',
      federalNewWorkPlacesRegionalReportFactChecker:
        objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactChecker?.value || '',
      federalNewWorkPlacesRegionalReportFactCorrect:
        objectFromPropertyState?.federalNewWorkPlacesRegionalReportFactCorrect?.value || '',

      regionalNewWorkPlacesRegionalReportPlan:
        objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlan?.value || '',
      regionalNewWorkPlacesRegionalReportPlanChecker:
        objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanChecker?.value || '',
      regionalNewWorkPlacesRegionalReportPlanCorrect:
        objectFromPropertyState?.regionalNewWorkPlacesRegionalReportPlanCorrect?.value || '',
      regionalNewWorkPlacesRegionalReportFact:
        objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFact?.value || '',
      regionalNewWorkPlacesRegionalReportFactChecker:
        objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactChecker?.value || '',
      regionalNewWorkPlacesRegionalReportFactCorrect:
        objectFromPropertyState?.regionalNewWorkPlacesRegionalReportFactCorrect?.value || '',
    },
    '8': {
      compensationValueRegionalReportPlan:
        objectFromPropertyState?.compensationValueRegionalReportPlan?.value || '',
      compensationValueRegionalReportFact:
        objectFromPropertyState?.compensationValueRegionalReportFact?.value || '',

      federalCompensationValueRegionalReportPlan:
        objectFromPropertyState?.federalCompensationValueRegionalReportPlan?.value || '',
      federalCompensationValueRegionalReportPlanChecker:
        objectFromPropertyState?.federalCompensationValueRegionalReportPlanChecker?.value || '',
      federalCompensationValueRegionalReportPlanCorrect:
        objectFromPropertyState?.federalCompensationValueRegionalReportPlanCorrect?.value || '',
      federalCompensationValueRegionalReportFact:
        objectFromPropertyState?.federalCompensationValueRegionalReportFact?.value || '',
      federalCompensationValueRegionalReportFactChecker:
        objectFromPropertyState?.federalCompensationValueRegionalReportFactChecker?.value || '',
      federalCompensationValueRegionalReportFactCorrect:
        objectFromPropertyState?.federalCompensationValueRegionalReportFactCorrect?.value || '',

      regionalCompensationValueRegionalReportPlan:
        objectFromPropertyState?.regionalCompensationValueRegionalReportPlan?.value || '',
      regionalCompensationValueRegionalReportPlanChecker:
        objectFromPropertyState?.regionalCompensationValueRegionalReportPlanChecker?.value || '',
      regionalCompensationValueRegionalReportPlanCorrect:
        objectFromPropertyState?.regionalCompensationValueRegionalReportPlanCorrect?.value || '',
      regionalCompensationValueRegionalReportFact:
        objectFromPropertyState?.regionalCompensationValueRegionalReportFact?.value || '',
      regionalCompensationValueRegionalReportFactChecker:
        objectFromPropertyState?.regionalCompensationValueRegionalReportFactChecker?.value || '',
      regionalCompensationValueRegionalReportFactCorrect:
        objectFromPropertyState?.regionalCompensationValueRegionalReportFactCorrect?.value || '',
    },
    '9': {
      economicSpheresRegionalReport: preparedEconomicSpheresRegionalReport,
    },
    '10': {
      szpksChangeTerminate: preparedSzpkChangeTerminate,
    },
    '11': {
      federalChangeTerminationSzpkCountRegionalReport:
        objectFromPropertyState?.federalChangeTerminationSzpkCountRegionalReport?.value || '',
      federalChangeTerminationSzpkTotalCapitalRegionalReport:
        objectFromPropertyState?.federalChangeTerminationSzpkTotalCapitalRegionalReport?.value ||
        '',
      federalChangeTerminationSzpkInvestorCapitalRegionalReport:
        objectFromPropertyState?.federalChangeTerminationSzpkInvestorCapitalRegionalReport?.value ||
        '',
      federalChangeTerminationReasonCountRegionalReport:
        objectFromPropertyState?.federalChangeTerminationReasonCountRegionalReport?.value || '',

      federalChangeSzpkCountRegionalReport:
        objectFromPropertyState?.federalChangeSzpkCountRegionalReport?.value || '',
      federalChangeSzpkTotalCapitalRegionalReport:
        objectFromPropertyState?.federalChangeSzpkTotalCapitalRegionalReport?.value || '',
      federalChangeSzpkInvestorCapitalRegionalReport:
        objectFromPropertyState?.federalChangeSzpkInvestorCapitalRegionalReport?.value || '',
      federalChangeReasonCountRegionalReport:
        objectFromPropertyState?.federalChangeReasonCountRegionalReport?.value || '',

      federalTerminationSzpkCountRegionalReport:
        objectFromPropertyState?.federalTerminationSzpkCountRegionalReport?.value || '',
      federalTerminationSzpkTotalCapitalRegionalReport:
        objectFromPropertyState?.federalTerminationSzpkTotalCapitalRegionalReport?.value || '',
      federalTerminationSzpkInvestorCapitalRegionalReport:
        objectFromPropertyState?.federalTerminationSzpkInvestorCapitalRegionalReport?.value || '',
      federalTerminationReasonCountRegionalReport:
        objectFromPropertyState?.federalTerminationReasonCountRegionalReport?.value || '',
    },
    '12': {
      regionalChangeTerminationSzpkCountRegionalReport:
        objectFromPropertyState?.regionalChangeTerminationSzpkCountRegionalReport?.value || '',
      regionalChangeTerminationSzpkTotalCapitalRegionalReport:
        objectFromPropertyState?.regionalChangeTerminationSzpkTotalCapitalRegionalReport?.value ||
        '',
      regionalChangeTerminationSzpkInvestorCapitalRegionalReport:
        objectFromPropertyState?.regionalChangeTerminationSzpkInvestorCapitalRegionalReport
          ?.value || '',
      regionalChangeTerminationReasonCountRegionalReport:
        objectFromPropertyState?.regionalChangeTerminationReasonCountRegionalReport?.value || '',

      regionalChangeSzpkCountRegionalReport:
        objectFromPropertyState?.regionalChangeSzpkCountRegionalReport?.value || '',
      regionalChangeSzpkTotalCapitalRegionalReport:
        objectFromPropertyState?.regionalChangeSzpkTotalCapitalRegionalReport?.value || '',
      regionalChangeSzpkInvestorCapitalRegionalReport:
        objectFromPropertyState?.regionalChangeSzpkInvestorCapitalRegionalReport?.value || '',
      regionalChangeReasonCountRegionalReport:
        objectFromPropertyState?.regionalChangeReasonCountRegionalReport?.value || '',

      regionalTerminationSzpkCountRegionalReport:
        objectFromPropertyState?.regionalTerminationSzpkCountRegionalReport?.value || '',
      regionalTerminationSzpkTotalCapitalRegionalReport:
        objectFromPropertyState?.regionalTerminationSzpkTotalCapitalRegionalReport?.value || '',
      regionalTerminationSzpkInvestorCapitalRegionalReport:
        objectFromPropertyState?.regionalTerminationSzpkInvestorCapitalRegionalReport?.value || '',
      regionalTerminationReasonCountRegionalReport:
        objectFromPropertyState?.regionalTerminationReasonCountRegionalReport?.value || '',
    },
    additionalForms: {
      federalSzpkReportsInfo: preparedFederalSzpkReportsInfo,
      notFederalSzpkReportsInfo: preparedNotFederalSzpkReportsInfo,
      federalSzpkReportsInfoWithinMonitoringDates:
        preparedFederalSzpkReportsInfoWithinMonitoringDates,
      notFederalSzpkReportsInfoWithinMonitoringDates:
        preparedNotFederalSzpkReportsInfoWithinMonitoringDates,
    },
  } as DataOnFulfillmentOfTermsSZPKFormValues
}

export { generateRHFObjectForDataOnFulfillmentOfTermsSZPKForm }
