import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fourthSectionAAgreementAccessionMunicipalValidationMap: FormValuesValidationSection<
  keyof AAgreementAccessionMunicipalFormValues['4'],
  AAgreementAccessionMunicipalFormValues
> = {
  municipalityFullNameGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  municipalityFullNameInstrumental: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { fourthSectionAAgreementAccessionMunicipalValidationMap }
