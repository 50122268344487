import React from 'react'
import { useFormContext } from 'react-hook-form'

import SubjectsSection from '@components/DocumentFormComponents/FormSections/SubjectsSection'
import { aAConnectionReorganizationImplementingBlockValues } from '@components/Forms/AAConnectionReorganizationImplementingForm/const'
import { useAAConnectionReorganizationImplementingManager } from '@components/Forms/AAConnectionReorganizationImplementingForm/Manager'
import { AAConnectionReorganizationImplementingFormValues } from '@components/Forms/AAConnectionReorganizationImplementingForm/types'
import { AAConnectionReorganizationImplementingFieldsControlUpdateWatcher } from '@components/Forms/AAConnectionReorganizationImplementingForm/watcher'

const Second = () => {
  const formInstance = useFormContext<AAConnectionReorganizationImplementingFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAConnectionReorganizationImplementingManager()

  if (!formInstance) return null

  return (
    <SubjectsSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={aAConnectionReorganizationImplementingBlockValues['2'].subjects}
      formInstance={formInstance}
      watcher={AAConnectionReorganizationImplementingFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
