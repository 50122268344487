import React, { FC, memo, MutableRefObject, useEffect, useRef } from 'react'
import Dropzone, { DropzoneProps, DropzoneRef } from 'react-dropzone'
import { ReactSVG } from 'react-svg'

import Button from '@components/NewDesign/Button'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import AttachmentIcon from '@icons/AttachmentIcon.svg'
import cn from 'classnames'

import { useDragAndDropHook } from '../useDragAndDropHook'
import FileDropzone from '..'

import styles from './FileDropzonePrimary.module.scss'

export interface FileDropzonePrimaryProps {
  dropZoneProps?: DropzoneProps
  title?: string
  subtitle?: string
  documentTemplateType?: boolean
  onDownloadTemplateClick?: VoidFunction
  subTitleReject?: string
  titleReject?: string
  dropzoneControlRef?: MutableRefObject<DropzoneRef | null>
}

const FileDropzonePrimary: FC<FileDropzonePrimaryProps> = ({
  title,
  subtitle,
  documentTemplateType,
  dropZoneProps,
  titleReject = 'Недопустимое расширение файла',
  subTitleReject = 'Перетащите файл',
  onDownloadTemplateClick,
  dropzoneControlRef,
}) => {
  const dropzoneRef = useRef<DropzoneRef | null>(null)
  const { handleClose, handleRejectedFiles, rejectedFile } = useDragAndDropHook()

  useEffect(() => {
    handleClose()
  }, [dropZoneProps])

  const subtitleRenderCondition = subtitle || documentTemplateType

  const openFileDialog = () => {
    if (!dropzoneRef?.current) return
    dropzoneRef.current.open()

    handleClose()
  }

  const preparedSubtitle =
    subtitle ?? !documentTemplateType
      ? `Перетащите файл в эту область или`
      : `Перетащите файл в эту область, если нужно`

  const bindDropzoneRef = (ref: DropzoneRef | null) => {
    if (!ref) return

    dropzoneRef.current = ref

    if (dropzoneControlRef) dropzoneControlRef.current = ref
  }

  return (
    <Dropzone
      {...dropZoneProps}
      noClick
      noKeyboard
      ref={bindDropzoneRef}
      onDropRejected={handleRejectedFiles}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        if (rejectedFile) {
          return (
            <FileDropzone.ErrorPrimary
              rejectedFile={rejectedFile}
              dropZoneProps={dropZoneProps}
              subTitleReject={subTitleReject}
              titleReject={titleReject}
            />
          )
        }
        return (
          <div
            role={'presentation'}
            {...getRootProps({
              className: cn(styles.dropzone__container, {
                [styles['dropzone__container--dragActive']]: isDragActive,
              }),
            })}
          >
            <div
              role={'presentation'}
              className={styles.dropzone__content}
              onClick={(e) => e.preventDefault()}
            >
              <input {...getInputProps()} />
              <div className={styles['dropzone__content-icon']}>
                <ReactSVG src={AttachmentIcon} />
              </div>
              <div className={styles['dropzone__content-info']}>
                {title && (
                  <TypographyWithTooltip.Body
                    variant={'bodyXLMedium'}
                    className={styles['dropzone__content-text']}
                    tooltipProps={{
                      position: 'top',
                      offset: [0, 10],
                      targetClassName: styles.tooltip__target,
                      popoverClassName: styles.tooltip__popover,
                    }}
                  >
                    {title}
                  </TypographyWithTooltip.Body>
                )}
                {subtitleRenderCondition && (
                  <div className={styles['dropzone__content-subtitle-container']}>
                    <TypographyWithTooltip.Body
                      as={'span'}
                      variant={'bodyMRegular'}
                      color={'on-surface-secondary'}
                      className={styles['dropzone__content-subtitle']}
                      tooltipProps={{
                        position: 'top',
                        offset: [0, 10],
                        targetClassName: styles.tooltip__target,
                        popoverClassName: styles.tooltip__popover,
                      }}
                    >
                      {preparedSubtitle}
                    </TypographyWithTooltip.Body>
                    {documentTemplateType ? (
                      <Button
                        className={styles['dropzone__content-downloadTemplate']}
                        size={'xs'}
                        variant={'buttonSMedium'}
                        view={'text'}
                        dataTestId="FileDropzonePrimary-downloadTemplate-button"
                        onClick={onDownloadTemplateClick}
                      >
                        скачайте шаблон
                      </Button>
                    ) : (
                      <Button
                        className={styles['dropzone__content-downloadTemplate']}
                        size={'xs'}
                        view={'text'}
                        dataTestId="FileDropzonePrimary-fileUpload-textButton"
                        onClick={openFileDialog}
                      >
                        выберите на устройстве
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <Button
                className={styles['dropzone__content-download']}
                view={'tined'}
                color={'default'}
                dataTestId="FileDropzonePrimary-fileUpload-button"
                onClick={openFileDialog}
              >
                Загрузить документ
              </Button>
            </div>
          </div>
        )
      }}
    </Dropzone>
  )
}

export default memo(FileDropzonePrimary)
