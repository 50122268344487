import React from 'react'
import { ArrayPath, FieldValues, Path, UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import BeneficiarItem from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/BeneficiarItem/index'
import { BeneficiarArrayFormName } from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/BeneficiarItem/types'
import { ArrayWithId } from '@components/DocumentFormComponents/FormSections/types'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { FormModalContextProps } from '@components/DocumentFormComponents/types'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface BeneficialOwnersInfoSectionProps<T extends FieldValues> {
  formName: Path<any>
  manager: FormModalContextProps<any>
  formInstance: UseFormReturn<T>
  watcher: FieldsControlUpdateWatcher
  fieldArrayWatcher: FieldArrayControlUpdateWatcher<T, ArrayPath<T>>
}

const BeneficialOwnersInfoSection = <T extends FieldValues>({
  formName,
  formInstance,
  manager,
  watcher,
  fieldArrayWatcher,
}: BeneficialOwnersInfoSectionProps<T>) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = manager

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher,
  })

  const { fields: beneficiars } = useFieldArraySubscribableControl<T, ArrayPath<T>, 'keyNameId'>({
    name: formName as ArrayPath<T>,
    keyName: 'keyNameId',
    watcher: fieldArrayWatcher,
    control: formInstance?.control,
  })

  const handleAddBeneficiar = async () => {
    await handleAddItemToListWithOutValue?.(formName)
  }

  const handleRemoveBeneficiar = (index: number) => async () => {
    const itemFormName = `${formName}.${index}`
    await handleRemoveItemFromList?.(itemFormName as Path<T>, formName)
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            {!editMode && !beneficiars.length && <EmptyDataText />}
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: formName as Path<T>,
                })}
              >
                <FlipperList list={beneficiars}>
                  {(beneficiars as ArrayWithId<T, ArrayPath<T>>).map((beneficiar, index) => {
                    const beneficiarItemFormName = `${formName}.${index}` as BeneficiarArrayFormName

                    return (
                      <BeneficiarItem
                        key={beneficiar.id}
                        id={beneficiar.id}
                        formName={beneficiarItemFormName}
                        watcher={watcher}
                        fieldArrayWatcher={fieldArrayWatcher}
                        formInstance={formInstance}
                        manager={manager}
                        onRemoveStep={handleRemoveBeneficiar(index)}
                      />
                    )
                  })}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </Col>
        </Row>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddBeneficiar}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default BeneficialOwnersInfoSection
