import { VFC } from 'react'

import cn from 'classnames'

import styles from './Divider.module.scss'

type TDividerSpacing = 'zero' | 'extraSmall' | 'small' | 'preMedium' | 'medium' | 'large'

export interface DividerProps {
  className?: string
  spacing: TDividerSpacing
  color?: 'light' | 'standard'
}

const Divider: VFC<DividerProps> = ({ className = '', spacing = 'small', color = 'standard' }) => (
  <hr
    className={cn(styles.divider, spacing && styles[spacing], className, {
      [styles[`divider--${color}`]]: color,
    })}
  />
)

export default Divider
