import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'

interface AdditionalAgreementOnTerminationFfSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
}

const AdditionalAgreementOnTerminationFfSZPKForms: AdditionalAgreementOnTerminationFfSZPKFormsProps =
  () => null

AdditionalAgreementOnTerminationFfSZPKForms.First = First
AdditionalAgreementOnTerminationFfSZPKForms.Second = Second
AdditionalAgreementOnTerminationFfSZPKForms.Third = Third
AdditionalAgreementOnTerminationFfSZPKForms.Fourth = Fourth
AdditionalAgreementOnTerminationFfSZPKForms.Fifth = Fifth

export default AdditionalAgreementOnTerminationFfSZPKForms
