import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import {
  useNestedMenuHandlersManager,
  useNestedMenuManager,
} from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  EighthAnotherStagesArrayPathName,
  EighthMeasureObjectsPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

import StepAnotherObject from './StepAnotherObject'

interface AnotherObjectFormProps {
  isAddAnotherObjectButtonDisabled: boolean
  name: EighthAnotherStagesArrayPathName
  objectName: string
}

const { transformRHFPathInProperties } = DocumentFormHelpers

const AnotherObjectForm: FC<AnotherObjectFormProps> = ({
  isAddAnotherObjectButtonDisabled,
  name,
  objectName,
}) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: {
      getPropertiesProps,
      handleAddCustomValue,
      handleRemoveItemFromList,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()
  const {
    state: { currentMenuItemId },
  } = useNestedMenuManager()

  const { handleChangeCurrentMenuItemId, handleUpdateChanges } = useNestedMenuHandlersManager()

  const { fields: anotherObjects } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    EighthMeasureObjectsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.measureObjects`,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
    EighthMeasureObjectsPathName
  >({
    control: formModifierInstance?.control,
    name: `${name}.measureObjects`,
    watcher:
      AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleAddAnotherObject = async () => {
    const objectsIdToAddProps = getObjectValue(getPropertiesProps?.(), objectName)
    const stepActivityOtherProps = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(name),
    )

    if (!objectsIdToAddProps || !stepActivityOtherProps) return

    await handleAddCustomValue?.(
      {
        id: objectsIdToAddProps.propertyId,
        lastUpdateDt: objectsIdToAddProps.lastUpdateDt,
      },
      {
        isMainObject: false,
        objectOtherActivityName: stepActivityOtherProps.permanentId,
      },
    )

    const anotherObjectAnchorId = `${name}.${anotherObjects.length + 1}`

    if (currentMenuItemId !== name) return

    handleChangeCurrentMenuItemId?.(anotherObjectAnchorId)
  }

  const handleRemoveAnotherObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.measureObjects.${index}`, `${name}.measureObjects`)
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(`${name}.measureObjects.${index}`)
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(`${name}.measureObjects.${index}`)
  }

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          {!editMode && !anotherObjects.length && <EmptyDataText />}
          <Stack direction={'vertical'} gap={!!anotherObjects.length ? 3 : 0}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.measureObjects`,
              })}
            >
              <FlipperList list={anotherObjects}>
                {anotherObjects.map((item, index) => {
                  const formName = `${name}.measureObjects.${index}` as const

                  return (
                    <SubscribableControl
                      key={item.id}
                      {...getSubscribableControlProps({
                        path: formName,
                      })}
                    >
                      <FormObjectTooltipControl
                        control={formModifierInstance.control}
                        name={formName}
                        onDifference={handleUpdateChanges}
                      >
                        <StepAnotherObject
                          name={formName}
                          indexOfObject={index}
                          lastIndexOfObject={anotherObjects.length - 1}
                          id={item.id}
                          onRemoveObject={handleRemoveAnotherObject(index)}
                          onMoveTop={handleChangeElementOrderToUp(index)}
                          onMoveBottom={handleChangeElementOrderToDown(index)}
                        />
                      </FormObjectTooltipControl>
                    </SubscribableControl>
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Stack>
        </Col>
      </Row>
      {editMode && (
        <Row className={'px-0.5'}>
          <AsyncWrapper promise={handleAddAnotherObject}>
            {({ isLoading, wrappedPromise }) => {
              return (
                <FieldView.ActionButton
                  disableWrapperPaddingLeft
                  disabled={isLoading || isAddAnotherObjectButtonDisabled}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  onClick={wrappedPromise}
                >
                  Добавить объект
                </FieldView.ActionButton>
              )
            }}
          </AsyncWrapper>
        </Row>
      )}
    </Stack>
  )
}

export default AnotherObjectForm
