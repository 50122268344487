import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { AAgreementIncludeRelatedTermsFormValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/types'

const generateRHFObjectForAAgreementIncludeRelatedTermsForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementIncludeRelatedTermsFormValues => {
  return {
    '1': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '4': {
      relatedContractNumber:
        objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractNumber?.value || '',
      relatedContractDate:
        objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractDate?.value || '',
      relatedContractTerms:
        objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractTerms?.value || '',
      relatedContractPublicLawMember: !!objectFromPropertyState?.dfoRelatedContract?.value
        ?.relatedContractPublicLawMember?.value?.memberOrganizationId?.id
        ? {
            displayValue:
              objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractPublicLawMember
                ?.value?.memberOrganizationId?.memberFullName,
            value:
              objectFromPropertyState?.dfoRelatedContract?.value?.relatedContractPublicLawMember
                ?.value?.id,
          }
        : null,
      relatedContractPublicLawMemberInstrumental:
        objectFromPropertyState?.dfoRelatedContract?.value
          ?.relatedContractPublicLawMemberInstrumental?.value || '',
      agreementLinkDamageCompensations:
        objectFromPropertyState?.dfoRelatedContract?.value?.agreementLinkDamageCompensations
          ?.value || '',
      isSubsidyInvestmentViolation:
        objectFromPropertyState?.dfoRelatedContract?.value?.isSubsidyInvestmentViolation?.value ??
        false,
      isSubsidyCreditViolation:
        objectFromPropertyState?.dfoRelatedContract?.value?.isSubsidyCreditViolation?.value ??
        false,
      isLongTermParametersChanging:
        objectFromPropertyState?.dfoRelatedContract?.value?.isLongTermParametersChanging?.value ??
        false,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
    },
  } as AAgreementIncludeRelatedTermsFormValues
}

export { generateRHFObjectForAAgreementIncludeRelatedTermsForm }
