import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { Row } from 'react-table'

import Table from '@components/Table'
import { useAPIContext } from '@context/APIContext'
import type { Participant, ParticipantsData } from '@context/APIContext/hooks/useOperatorApi'
import useUsers from '@hooks/new/swr/useUsers'
import cn from 'classnames'

import { usersTabs } from '../constants'
import useManageUsersSidebar from '../useManageUsersSidebar'
import type { FilterActions } from '../Users'

import { columns } from './columns'
import styles from './ParticipantsTable.module.scss'

interface ParticipantsTableProps {
  debouncedSearch: string
  filterActions: FilterActions
  setUpdater: (updater: VoidFunction) => VoidFunction
}

const initialTableState = {
  _key: usersTabs.participants,
  pageSize: 15,
  pageIndex: 0,
} as const

const ParticipantsTable: FC<ParticipantsTableProps> = ({
  debouncedSearch,
  filterActions,
  setUpdater,
}) => {
  const {
    operatorApi: { getUsersList },
  } = useAPIContext()

  const getKey = useCallback(
    (pageIndex: number) => ({
      ...initialTableState,
      pageIndex,
      searchString: debouncedSearch,
      ...(filterActions === 'onlyDisabled' && {
        isEnabled: false,
      }),
    }),
    [debouncedSearch, filterActions],
  )

  const {
    data: participants,
    isValidating: isLoadingParticipants,
    mutate: updateParticipants,
    goToNextPage,
  } = useUsers<ParticipantsData>({
    key: getKey,
    fetcher: getUsersList,
    pageSize: initialTableState.pageSize,
    config: {
      revalidateFirstPage: false,
    },
  })

  const resetDeps = useMemo(
    () => [debouncedSearch, filterActions],
    [debouncedSearch, filterActions],
  )

  const handleRowProps = useCallback((row, data) => {
    return {
      ...row,
      className: cn(row.className, {
        [styles.rowIsBlocked]: !data.isEnabled,
      }),
    }
  }, [])

  const { handleOpenManageParticipantSidebar } = useManageUsersSidebar()

  const successCallback = useCallback(async () => {
    await updateParticipants()
  }, [updateParticipants])

  const handleRowClick = useCallback(
    (row: Row<Participant>) => {
      handleOpenManageParticipantSidebar({
        user: row.original,
        onSuccess: successCallback,
      })
    },
    [handleOpenManageParticipantSidebar, successCallback],
  )

  useEffect(() => {
    setUpdater(updateParticipants)
  }, [])

  return (
    <>
      <Table
        manualSortBy
        manualPagination
        hidePagination
        infinitePagination
        autoResetPage={false}
        loading={isLoadingParticipants}
        columns={columns}
        className={styles.table}
        data={participants ?? []}
        getRowProps={handleRowProps}
        initialState={initialTableState}
        resetDeps={resetDeps}
        onRowClick={handleRowClick}
        onLoadMore={goToNextPage}
      />
    </>
  )
}

export default memo(ParticipantsTable)
