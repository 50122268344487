import { defaultIndicatorColors } from '@components/NewDesign/Indicator/const'
import { defaultMapOfIndicatorOptionsByActiveColor } from '@components/NewDesign/Indicator/utils'

const selectStatusOptionsOfInvestorReports = [
  { displayValue: 'Все', value: '' },
  { displayValue: 'Отчёт не предоставлен', value: 'EMPTY' },
  { displayValue: 'На рассмотрении', value: 'CHECKING' },
  { displayValue: 'На доработке', value: 'RETURNED' },
  { displayValue: 'Принято', value: 'ACCEPTED' },
  { displayValue: 'Расторгнуто', value: 'TERMINATED' },
]

const selectStatusOptionsOfRegionalReports = [
  { displayValue: 'Все', value: '' },
  { displayValue: 'Отчёт не предоставлен', value: 'EMPTY' },
  { displayValue: 'Принято', value: 'ACCEPTED' },
]

const selectIndicatorValueOptions = [
  { displayValue: 'Все', value: '' },
  {
    displayValue: 'Зеленый',
    value: String(
      defaultMapOfIndicatorOptionsByActiveColor[defaultIndicatorColors.GREEN.activeColor].value,
    ),
  },
  {
    displayValue: 'Жёлтый',
    value: String(
      defaultMapOfIndicatorOptionsByActiveColor[defaultIndicatorColors.YELLOW.activeColor].value,
    ),
  },
  {
    displayValue: 'Оранжевый',
    value: String(
      defaultMapOfIndicatorOptionsByActiveColor[defaultIndicatorColors.ORANGE.activeColor].value,
    ),
  },
  {
    displayValue: 'Красный',
    value: String(
      defaultMapOfIndicatorOptionsByActiveColor[defaultIndicatorColors.RED.activeColor].value,
    ),
  },
]

const mapOfStatusSelect = {
  investor: selectStatusOptionsOfInvestorReports,
  region: selectStatusOptionsOfRegionalReports,
} as const

export {
  mapOfStatusSelect,
  selectIndicatorValueOptions,
  selectStatusOptionsOfInvestorReports,
  selectStatusOptionsOfRegionalReports,
}
