import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForStatementConsiderationInvestmentAuthorizedForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedInvestorHeaderEmail = (() => {
    if (
      !isArray(objectFromPropertyState.docSignerEmails?.value) ||
      !objectFromPropertyState.docSignerEmails.value.length
    )
      return []

    return objectFromPropertyState.docSignerEmails.value.map((email) => ({
      propertyId: email.propertyId,
      lastUpdateDt: email.lastUpdateDt,
      type: email.type,
    }))
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      region: {
        propertyId: objectFromPropertyState.investor?.value.region?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.region?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.region?.type,
      },
      city: {
        propertyId: objectFromPropertyState.investor?.value.city?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.city?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.city?.type,
      },
      district: {
        propertyId: objectFromPropertyState.investor?.value.district?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.district?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.district?.type,
      },
      area: {
        propertyId: objectFromPropertyState.investor?.value.area?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.area?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.area?.type,
      },
      planningStructureElement: {
        propertyId: objectFromPropertyState.investor?.value.planningStructureElement?.propertyId,
        lastUpdateDt:
          objectFromPropertyState.investor?.value.planningStructureElement?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.planningStructureElement?.type,
      },
      roadNetworkElement: {
        propertyId: objectFromPropertyState.investor?.value.roadNetworkElement?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.roadNetworkElement?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.roadNetworkElement?.type,
      },
      house: {
        propertyId: objectFromPropertyState.investor?.value.house?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.house?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.house?.type,
      },
      room: {
        propertyId: objectFromPropertyState.investor?.value.room?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.room?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.room?.type,
      },
      roomPart: {
        propertyId: objectFromPropertyState.investor?.value.roomPart?.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.roomPart?.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.roomPart?.type,
      },
      isProjectCompany: {
        propertyId: objectFromPropertyState.investor?.value.isProjectCompany.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.isProjectCompany.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.isProjectCompany.type,
      },
      isVed: {
        propertyId: objectFromPropertyState.investor?.value.isVed.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.isVed.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.isVed.type,
      },
      isKgn: {
        propertyId: objectFromPropertyState.investor?.value.isKgn.propertyId,
        lastUpdateDt: objectFromPropertyState.investor?.value.isKgn.lastUpdateDt,
        type: objectFromPropertyState.investor?.value.isKgn.type,
      },
      investorHeaderEmail: {
        propertyId: objectFromPropertyState.docSignerEmails?.propertyId,
        lastUpdateDt: objectFromPropertyState.docSignerEmails?.lastUpdateDt,
        value: preparedInvestorHeaderEmail,
      },
      investorHeaderPhone: {
        propertyId: objectFromPropertyState.docSignerPhone.propertyId,
        lastUpdateDt: objectFromPropertyState.docSignerPhone.lastUpdateDt,
        type: objectFromPropertyState.docSignerPhone.type,
      },
      investorHeaderAddPhone: {
        propertyId: objectFromPropertyState.docSignerAddPhone.propertyId,
        lastUpdateDt: objectFromPropertyState.docSignerAddPhone.lastUpdateDt,
        type: objectFromPropertyState.docSignerAddPhone.type,
      },
    },
    '2': {
      projectName: {
        propertyId: objectFromPropertyState?.projectName?.propertyId,
        lastUpdateDt: objectFromPropertyState?.projectName?.lastUpdateDt,
        type: objectFromPropertyState?.projectName?.type,
      },
      lawLinkInvestProject: {
        propertyId: objectFromPropertyState?.lawLinkInvestProject?.propertyId,
        lastUpdateDt: objectFromPropertyState?.lawLinkInvestProject?.lastUpdateDt,
        type: objectFromPropertyState?.lawLinkInvestProject?.type,
      },
      ruleLinkInvestProject: {
        propertyId: objectFromPropertyState?.ruleLinkInvestProject?.propertyId,
        lastUpdateDt: objectFromPropertyState?.ruleLinkInvestProject?.lastUpdateDt,
        type: objectFromPropertyState?.ruleLinkInvestProject?.type,
      },
    },
  }
}

export { generatePropertiesObjectForStatementConsiderationInvestmentAuthorizedForm }
