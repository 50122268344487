import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext } from 'react-hook-form'

import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import {
  FormModalContextProps,
  NpaFormContextProps,
  NpaFormManagerProps,
} from '@components/DocumentFormComponents/types'
import { useNpaSolicitationInclusionFormAdapters } from '@components/Forms/NpaSolicitationInclusionForm/adapters'
import { NpaSolicitationInclusionFormValues } from '@components/Forms/NpaSolicitationInclusionForm/types'
import { NpaSolicitationInclusionFieldsControlUpdateWatcher } from '@components/Forms/NpaSolicitationInclusionForm/watcher'
import { getProjectAttributeByName } from '@components/Projects/[id]/helpers'
import { WebFormTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { compact } from '@helpers/array/compact'
import { isNull, isString, isUndefined } from '@helpers/checkTypes'
import { NpaAddonThreeManager } from '@helpers/watcher'
import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useRegions } from '@hooks/new/swr/useRegions'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { INpaDraftBody } from '@services/NPA/NPA.entity'
import NPAService from '@services/NPA/NPA.service'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'

const { buildThreeForNpaLayout } = NPAService

const NpaSolicitationInclusionFormContext = createContext<
  NpaFormContextProps<NpaSolicitationInclusionFormValues>
>({
  state: {
    formIsLoading: true,
    blockViewIsValidating: false,
    documentType: WebFormTypes.NPA_LIST,
    lastUpdateDraftTime: '',
  },
  handlers: {},
  actions: {},
  preparedProps: {} as FormModalContextProps<NpaSolicitationInclusionFormValues>['preparedProps'],
})

const NpaSolicitationInclusionFormManager: FC<NpaFormManagerProps> = ({
  projectId,
  formId,
  initialErrorsFromViolations,
  documentType,
  children,
  onRemoveNpaForm,
}) => {
  const {
    npaApi: { getNpasList, getNpaPartsById },
  } = useAPIContext()

  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  const { updateLastRFHBeforeValue, getLastRHFBeforeValue } =
    useFormPreviousValue<NpaSolicitationInclusionFormValues>()

  const formInstance = useFormContext<NpaSolicitationInclusionFormValues>()
  const { reset: resetForm, clearErrors } = formInstance

  const { processOverrideProps, getOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const { formIsLoading, handleMountForm } = useFormMount(formId)

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const { npaDirectionsWithOrder } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const { partClassificationInObjectMap } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  const { projectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'NpaSolicitationInclusionFormManager',
          moduleName: 'NpaSolicitationInclusionForm',
        },
      }),
    },
  })

  const { reversedRegionsObject } = useRegions({
    key: {
      _key: 'regions',
    },
  })

  const regionsToNpaModal: string | string[] | undefined = useMemo(() => {
    if (!projectAttributes) return []

    const subjectsToReturn = getProjectAttributeByName('subjects', projectAttributes)[0]?.value

    const returnCondition = Array.isArray(subjectsToReturn)

    return returnCondition && reversedRegionsObject
      ? compact(subjectsToReturn.map((value) => isString(value) && reversedRegionsObject[value]))
      : []
  }, [projectAttributes, reversedRegionsObject])

  const { generatePropertiesObject, generateRHFObject } = useNpaSolicitationInclusionFormAdapters()

  const { getBasePropsFromObjectAdapter } = useGenerateValuesFromObjectAdapter({
    generateRHFObject,
    generatePropertiesObject,
  })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
  })

  const { handleNotifyAllSubscribers } = useNotifyAllSubscribers({
    watcher: NpaSolicitationInclusionFieldsControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const handleBuildThree = useCallback(
    async (chosenParts: INpaDraftBody | null) => {
      if (!chosenParts?.npaParts || !partClassificationInObjectMap || !npaDirectionsWithOrder)
        return

      return buildThreeForNpaLayout({
        initialParts: chosenParts.npaParts,
        directions: npaDirectionsWithOrder,
        mapOfClassificationPart: partClassificationInObjectMap,
        helpers: {
          getPartsByNpaID: getNpaPartsById,
          getNpaList: getNpasList,
        },
      })
    },
    [getNpaPartsById, getNpasList, npaDirectionsWithOrder, partClassificationInObjectMap],
  )

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()

      if (isNull(prevRHFValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const readyOverrideProps = await processOverrideProps(props.overridePropsFromChanges)

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      const npaStructure = await handleBuildThree(RHFValueForReset.npa.chosenParts)

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      const currentRHFValue =
        !npaStructure || !npaStructure.length
          ? RHFValueForReset
          : {
              ...RHFValueForReset,
              npa: {
                ...RHFValueForReset.npa,
                npaStructure,
              },
            }

      unstable_batchedUpdates(() => {
        resetForm(currentRHFValue, {
          keepErrors: true,
        })
        updateLastRFHBeforeValue(currentRHFValue)

        setTimeout(() => {
          NpaSolicitationInclusionFieldsControlUpdateWatcher.notifyAllSubscribers(
            readyOverrideProps,
          )
        }, 0)

        handleSetLastUpdateFormToNow()
      })
    },
    [
      getBasePropsFromObjectAdapter,
      getLastRHFBeforeValue,
      handleBuildThree,
      handleSetLastUpdateFormToNow,
      lastFieldUpdateTime,
      processOverrideProps,
      propertiesPropsRef,
      resetForm,
      updateLastRFHBeforeValue,
    ],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const {
    handleAddItemToListWithValue,
    handleAddMultipleItemsToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleChangeValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    debouncedHandleChangeValue,
    handleChangeCustomValue,
  } = useFormUpdate({
    formId,
    getPropertiesProps,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: applyFormChanges(handleApplyChanges),
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const { handlePreviewForm, handleCheckForm } = useFormActions({
    formId,
    watcher: NpaSolicitationInclusionFieldsControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    handleApplyChanges: checkFormChanges,
    onClearError: clearErrors,
  })

  const handleUpdateStaleStatus = (value = false) => {
    NpaAddonThreeManager.setStaleStatus(value)
  }

  const handleSuccessInitializeForm = useCallback(
    async ({ lastChangesDt, propertyInstance }: SuccessInitialCallbackReturn) => {
      lastFieldUpdateTime.current = lastChangesDt

      propertyInstanceRef.current = propertyInstance

      const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(objectForAdapters)

      const npaStructure = await handleBuildThree(RHFValueForReset.npa.chosenParts)

      //Установка propertiesProps
      propertiesPropsRef.current = cloneDeep(propertiesProps)

      const currentRHFValue =
        !npaStructure || !npaStructure.length
          ? RHFValueForReset
          : {
              ...RHFValueForReset,
              npa: {
                ...RHFValueForReset.npa,
                npaStructure,
              },
            }

      resetForm(currentRHFValue)
      updateLastRFHBeforeValue(currentRHFValue)

      handleUpdateStaleStatus()
    },
    [
      getBasePropsFromObjectAdapter,
      handleBuildThree,
      lastFieldUpdateTime,
      propertiesPropsRef,
      propertyInstanceRef,
      resetForm,
      updateLastRFHBeforeValue,
    ],
  )

  //Инициализация формы
  useEffect(() => {
    if (isUndefined(partClassificationInObjectMap) && isUndefined(npaDirectionsWithOrder)) return

    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleNotifyAllSubscribers,
      onErrorInitializeForm: handleUpdateStaleStatus,
    })
  }, [partClassificationInObjectMap, npaDirectionsWithOrder])

  //Следит за ошибками из ActionBanner. Если пришла оттуда ошибка, необходимо пересобрать дерево
  useEffect(() => {
    if (isUndefined(partClassificationInObjectMap) && isUndefined(npaDirectionsWithOrder)) return

    const listener = (isStale) =>
      isStale &&
      handleMountForm({
        onInitializeForm: handleSuccessInitializeForm,
        onAfterInitializeForm: handleNotifyAllSubscribers,
        onErrorInitializeForm: handleUpdateStaleStatus,
      })

    NpaAddonThreeManager.subscribe(listener)

    return () => NpaAddonThreeManager.unsubscribe(listener)
  }, [partClassificationInObjectMap, npaDirectionsWithOrder, handleMountForm])

  const preparedValue: NpaFormContextProps<NpaSolicitationInclusionFormValues> = useMemo(
    () => ({
      state: {
        formIsLoading,
        blockViewIsValidating,
        initialErrorsFromViolations,
        lastUpdateDraftTime,
        regionsToNpaModal,
        documentType,
      },
      handlers: {
        checkFormChanges,
        getPropertiesProps,
        getOverrideProps,
        handleChangeValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        debouncedHandleChangeValue,
        handleAddItemToListWithOutValue,
        getLastRHFBeforeValue,
        handleChangeCustomValue,
        handleAddItemToListWithValue,
        handleAddMultipleItemsToListWithValue,
        handleRemoveItemFromList,
        applyFormChanges: applyFormChanges(handleApplyChanges),
        handleChangeBlockValidation,
        handleRemoveNpaForm: onRemoveNpaForm,
      },
      actions: {
        handlePreviewForm,
        handleCheckForm,
      },
      preparedProps: {
        subscribableControl,
      },
    }),
    [
      onRemoveNpaForm,
      applyFormChanges,
      blockViewIsValidating,
      checkFormChanges,
      debouncedHandleChangeValue,
      documentType,
      formIsLoading,
      getLastRHFBeforeValue,
      getOverrideProps,
      getPropertiesProps,
      handleAddCustomValue,
      handleAddItemToListWithOutValue,
      handleAddItemToListWithValue,
      handleAddMultipleItemsToListWithValue,
      handleApplyChanges,
      handleChangeBlockValidation,
      handleChangeCustomValue,
      handleChangeValue,
      handleCheckForm,
      handlePreviewForm,
      handleRemoveCustomValue,
      handleRemoveItemFromList,
      initialErrorsFromViolations,
      lastUpdateDraftTime,
      regionsToNpaModal,
      subscribableControl,
    ],
  )

  return (
    <NpaSolicitationInclusionFormContext.Provider value={preparedValue}>
      {children}
    </NpaSolicitationInclusionFormContext.Provider>
  )
}

const useNpaSolicitationInclusionFormManager = () => {
  return useContext(NpaSolicitationInclusionFormContext)
}

export { NpaSolicitationInclusionFormContext, useNpaSolicitationInclusionFormManager }
export default NpaSolicitationInclusionFormManager
