import React, { FC } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { FormModalLayoutWrapperProps } from '@components/DocumentFormComponents/types'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'

const DataOnFulfillmentOfTermsSZPKLayoutWrapper: FC<FormModalLayoutWrapperProps> = ({
  lastUpdateDraftTime,
  children,
  ...rest
}) => {
  const {
    actions: { handleFormClose },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const handleCloseForm = () => {
    handleFormClose?.()
  }

  return (
    <DocumentLayoutContent {...rest} draftIsSaved={!!lastUpdateDraftTime} onClose={handleCloseForm}>
      {children}
    </DocumentLayoutContent>
  )
}

export default DataOnFulfillmentOfTermsSZPKLayoutWrapper
