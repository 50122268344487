import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnCreationOtherEstateObjectsBlockValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import { ObjectExploitationObjectsArrayPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

import { eExploitationFormItemAAgreementOnCreationOtherEstateObjectsValidationMap } from './validation'

interface ExploitationFormItemProps {
  formName: ObjectExploitationObjectsArrayPathName
  indexOfBlock: number
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnCreationOtherEstateObjectsFormValues>
}

const ExploitationFormItem: FC<ExploitationFormItemProps> = ({
  indexOfBlock,
  blockViewIsValidating,
  formInstance,
  formName,
}) => {
  const {
    state: { editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formInstance.control,
  })

  const { getSubscribableControlProps, getSingleSelectProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode: isNew && editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
    })

  const stepEndDate = useWatch({
    name: `${aAgreementOnCreationOtherEstateObjectsBlockValues['5'].constructionStages}.${indexOfBlock}.stepEndDate`,
    control: formInstance.control,
  })

  const positiveStepEndDate = (text: string) => (value: unknown) => {
    if (
      isDateValidForDayjs(value) &&
      dayjs(value, objOfDateFormats.defaultFormat) >
        dayjs(stepEndDate, objOfDateFormats.defaultFormat)
    )
      return text
  }

  return (
    <div>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectName`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item?.objectName || '',
                          value: item.id,
                        }),
                        controllerProps: {
                          name: `${formName}.objectName`,
                          rules:
                            eExploitationFormItemAAgreementOnCreationOtherEstateObjectsValidationMap.objectName,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Объект недвижимости',
                          },
                          onChangeFormValue: () =>
                            setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectPermissionCommissionNumber`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: `${item.permissionNumber ?? ''} ${
                            item.permissionKind?.name ?? ''
                          }`,
                          value: item.id,
                        }),
                        controllerProps: {
                          name: `${formName}.objectPermissionCommissionNumber`,
                          rules:
                            eExploitationFormItemAAgreementOnCreationOtherEstateObjectsValidationMap.objectPermissionCommissionNumber,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Номер разрешения',
                          },
                          onChangeFormValue: () =>
                            setTimeout(
                              () =>
                                handleChangeValue?.(`${formName}.objectPermissionCommissionNumber`),
                              0,
                            ),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
            <Col xs={4}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectCommissioningDate`,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${formName}.objectCommissioningDate`,
                    rules: {
                      ...eExploitationFormItemAAgreementOnCreationOtherEstateObjectsValidationMap.objectCommissioningDate,
                      validate: {
                        positiveCurrentDate: (value) => {
                          const currentDate = DayjsService.dayjsWithFormatToMSK()

                          return (
                            (isDateValidForDayjs(value) &&
                              dayjs(value, objOfDateFormats.defaultFormat) <= currentDate) ||
                            'дата ввода в эксплуатацию объекта должна быть меньше или равна текущей дате'
                          )
                        },
                        positiveStepEndDate: positiveStepEndDate(
                          'дата ввода в эксплуатацию объекта должна быть меньше даты окончания этапа',
                        ),
                      },
                    },
                    calendarInputProps: {
                      label: 'Дата ввода',
                    },
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.objectCommissioningDate`),
                        0,
                      ),
                    onInputChange: () =>
                      debouncedHandleChangeValue?.(`${formName}.objectCommissioningDate`),
                    onCalendarChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.objectCommissioningDate`),
                        0,
                      ),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </Col>
    </div>
  )
}

export default ExploitationFormItem
