import { ButtonHTMLAttributes, FC, HTMLAttributeAnchorTarget } from 'react'

import Icon from '@components/Icon'
import { getIconSizeByButtonSize } from '@helpers/buttonHelpers'
import cn from 'classnames'

import styles from './Button.module.scss'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  rounded?: boolean
  fixWidth?: boolean
  leadingIcon?: string
  trailingIcon?: string
  icon?: string
  className?: string
  loading?: boolean
  priority?:
    | 'primary-positive'
    | 'primary-negative'
    | 'secondary-positive'
    | 'secondary-negative'
    | 'tertiary-positive'
    | 'tertiary-negative'
    | 'on-accent-positive'
    | 'on-accent-negative'
    | 'ghost-positive'
    | 'ghost-negative'
    | 'entry-positive'
    | 'primary-positive-gradient-blue'
    | 'primary-positive-gradient-green'
    | 'primary-dark'
  size?: 'l' | 'm' | 's' | 'xs'
  href?: string
  target?: HTMLAttributeAnchorTarget
  fontWeight?: 'normal' | 'medium' | 'semibold' | 'bold'
  dataTestId?: string
}

const Button: FC<ButtonProps> = ({
  href = '',
  target = '',
  fontWeight = 'semibold',
  type = 'button',
  size = 'm',
  icon,
  rounded = false,
  className,
  priority = 'primary-positive',
  fixWidth = false,
  children,
  leadingIcon,
  trailingIcon,
  dataTestId,
  ...props
}) => {
  const iconComponent = (_src: string) => {
    return <Icon size={getIconSizeByButtonSize(size)} src={_src} />
  }

  const classNameToElements = cn(
    styles.button,
    styles[`button--${size}`],
    styles[`button--${priority}`],
    {
      [styles['button--rounded']]: rounded,
      [styles['button--fix-width']]: fixWidth,
      [styles['button--icon-l']]: !icon && leadingIcon && !trailingIcon,
      [styles['button--icon-t']]: !icon && trailingIcon && !leadingIcon,
      [styles['button--icon-lt']]: !icon && trailingIcon && leadingIcon,
      [styles['button--icon']]: icon,
      [styles[`button--${fontWeight}`]]: fontWeight,
    },
    className,
  )

  const childrens = !icon && children && <div className={styles.text}>{children}</div>

  return (
    <>
      {(!href && (
        <button type={type} className={classNameToElements} data-testid={dataTestId} {...props}>
          {!icon && leadingIcon && iconComponent(leadingIcon)}
          {childrens}
          {icon && iconComponent(icon)}
          {!icon && trailingIcon && iconComponent(trailingIcon)}
        </button>
      )) || (
        <a
          href={href}
          target={target}
          rel="noopener noreferrer"
          className={classNameToElements}
          data-testid={dataTestId}
        >
          {!icon && children && <div className={styles.text}>{childrens}</div>}
          {!icon && trailingIcon && iconComponent(trailingIcon)}
        </a>
      )}
    </>
  )
}

export default Button
