import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForCertificateOfImplementationInvestprojectStagesForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedStepsMonitoringEnded = (() => {
    if (
      !isArray(objectFromPropertyState.stepsMonitoringEnded?.value) ||
      !objectFromPropertyState.stepsMonitoringEnded?.value?.length
    )
      return []

    return objectFromPropertyState.stepsMonitoringEnded?.value.map((stepMonitoringEnded) => ({
      propertyId: stepMonitoringEnded.propertyId,
      type: stepMonitoringEnded.type,
      lastUpdateDt: stepMonitoringEnded.lastUpdateDt,
      value: {
        stepNumber: {
          propertyId: stepMonitoringEnded.value?.stepMonitoringCheckerId.propertyId,
          type: stepMonitoringEnded.value?.stepMonitoringCheckerId.type,
          lastUpdateDt: stepMonitoringEnded.value?.stepMonitoringCheckerId.lastUpdateDt,
        },
        endedStepTotalCapitalValue: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          stepMonitoringEnded.value?.endedStepTotalCapitalValue,
        ),
        endedStepInvestorCapitalValue:
          PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            stepMonitoringEnded.value?.endedStepInvestorCapitalValue,
          ),
        endedStepActivityName: {
          propertyId: stepMonitoringEnded.value?.endedStepActivityName.propertyId,
          type: stepMonitoringEnded.value?.endedStepActivityName.type,
          lastUpdateDt: stepMonitoringEnded.value?.endedStepActivityName.lastUpdateDt,
        },
        endedStepActivityValue: {
          propertyId: stepMonitoringEnded.value?.endedStepActivityValue.propertyId,
          type: stepMonitoringEnded.value?.endedStepActivityValue.type,
          lastUpdateDt: stepMonitoringEnded.value?.endedStepActivityValue.lastUpdateDt,
        },
        isEndedStepActivityChange: {
          propertyId: stepMonitoringEnded.value?.endedStepActivityName.propertyId,
          type: stepMonitoringEnded.value?.endedStepActivityName.type,
          lastUpdateDt: stepMonitoringEnded.value?.endedStepActivityName.lastUpdateDt,
        },
      },
    }))
  })()

  return {
    '1': PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    '2': PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '3': {
      stepsMonitoringEnded: {
        propertyId: objectFromPropertyState?.stepsMonitoringEnded?.propertyId,
        type: objectFromPropertyState?.stepsMonitoringEnded?.type,
        lastUpdateDt: objectFromPropertyState?.stepsMonitoringEnded?.lastUpdateDt,
        value: preparedStepsMonitoringEnded,
      },
    },
  }
}

export { generatePropertiesObjectForCertificateOfImplementationInvestprojectStagesForm }
