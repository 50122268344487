import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import CreditMembersForm from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/4/AdditionalForms/CreditForm/CreditMemberForm'
import { CreditsArrayPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/4/types'
import { fourthSectionValidationMap } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/4/validation'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CreditFormProps {
  blockViewIsValidating: boolean
  editMode: boolean
  creditItemId: string
  indexOfCredit: number
  name: CreditsArrayPathName
  formInstance: UseFormReturn<InformationOfExecutionConditionsSZPKFormValues>
  onDeleteCredit: VoidFunction
}

const CreditForm: FC<CreditFormProps> = ({
  blockViewIsValidating,
  editMode,
  creditItemId,
  indexOfCredit,
  name,
  formInstance,
  onDeleteCredit,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getInputProps, getCalendarInputProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
    })

  return (
    <FieldView.StepTemplate
      id={creditItemId}
      title={`Договор №${indexOfCredit + 1}`}
      onRemoveStep={editMode ? onDeleteCredit : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.percentCreditName`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${name}.percentCreditName`,
                  rules: fourthSectionValidationMap.percentCreditName,
                  inputProps: {
                    label: 'Наименование',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.percentCreditName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.percentCreditName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.percentCreditDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.percentCreditDate`,
                  rules: fourthSectionValidationMap.percentCreditDate,
                  calendarInputProps: {
                    label: 'Дата заключения договора',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.percentCreditDate`)
                    }, 0),
                  onCalendarChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.percentCreditDate`)
                    }, 0),
                  onInputChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.percentCreditDate`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.percentCreditNumber`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${name}.percentCreditNumber`,
                  rules: fourthSectionValidationMap.percentCreditNumber,
                  inputProps: {
                    label: 'Номер договора',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.percentCreditNumber`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.percentCreditNumber`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <CreditMembersForm
              name={`${name}.percentCreditMembers`}
              editMode={editMode}
              formInstance={formInstance}
              blockViewIsValidating={blockViewIsValidating}
            />
          </Col>
        </Row>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default CreditForm
