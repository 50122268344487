import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/RidFormItem.module.scss'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import RidFormItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RForm/RidFormItem'
import { SeventhRidObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldCollapseControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import cn from 'classnames'

interface RFormItemProps {
  id: string
  anchorId: string
  formName: SeventhRidObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  indexOfRidForm: number
  onDeleteRidForm: VoidFunction
}

const RFormItem: FC<RFormItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formName,
  formInstance,
  indexOfRidForm,
  onDeleteRidForm,
}) => {
  const { isExpanded: isShowRidFormItemContent, onToggleCollapse: toggleShowRidFormItemContent } =
    useFormCollapseControl({
      initialExpanded: true,
      name: formName,
      watcher: ProjectSZPKFieldCollapseControlUpdateWatcher,
    })

  return (
    <ObjectStepWrapper
      disableHeaderPaddingLeft
      disableBorder
      stepId={id}
      id={anchorId}
      headerNode={
        <ObjectStepWrapperHeaderNode
          buttonProps={{
            onClick: toggleShowRidFormItemContent,
            trailingIcon: {
              className: cn({
                [styles['ridFormItem__header-button__icon--active']]: isShowRidFormItemContent,
              }),
            },
          }}
        >
          РИД №{indexOfRidForm + 1}
        </ObjectStepWrapperHeaderNode>
      }
      onRemoveObjectStep={editMode ? onDeleteRidForm : undefined}
    >
      <Row>
        <Col xs={12}>
          <Stack direction="vertical" gap={3}>
            <Row>
              <RidFormItem
                isShowRidFormItemContent={isShowRidFormItemContent}
                blockViewIsValidating={blockViewIsValidating}
                editMode={editMode}
                formInstance={formInstance}
                formName={formName}
              />
            </Row>
            <Row>
              <Col xs={12}>
                <Border />
              </Col>
            </Row>
          </Stack>
        </Col>
      </Row>
    </ObjectStepWrapper>
  )
}

export default RFormItem
