import React from 'react'
import { useFormContext } from 'react-hook-form'

import RFSection from '@components/DocumentFormComponents/FormSections/RFSection'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'

const One = () => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  if (!formInstance) return null

  return (
    <RFSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      onChangeValue={handleChangeValue}
    />
  )
}

export default One
