import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { defaultFinancialModelFormValue } from '@components/Forms/FinancialModelForm/const'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { isArray } from '@helpers/checkTypes'

const { getYearOfDateFromPropertyDateValue, getPreparedBillionValue } = DocumentFormHelpers

const generateRHFObjectForFinancialModel = (
  objectFromPropertyState: Record<string, any>,
): FinancialModelFormValues => {
  return {
    '1': {
      startInvestStage: getYearOfDateFromPropertyDateValue(
        objectFromPropertyState.startInvestStage?.value,
      ),
      endInvestStage: getYearOfDateFromPropertyDateValue(
        objectFromPropertyState.endInvestStage?.value,
      ),
      investStagePeriod:
        objectFromPropertyState.investStagePeriod?.value ||
        defaultFinancialModelFormValue['1'].investStagePeriod,
      startExploitationStage: getYearOfDateFromPropertyDateValue(
        objectFromPropertyState.startExploitationStage?.value,
      ),
      projectCapacityYear: getYearOfDateFromPropertyDateValue(
        objectFromPropertyState.projectCapacityYear?.value,
      ),
    },
    '2': {
      investmentYears: isArray(objectFromPropertyState.investments?.value)
        ? objectFromPropertyState.investments?.value
            .sort((investmentA, investmentB) => investmentA.order - investmentB.order)
            .map((investment) => ({
              investmentYear: investment.value.investmentYear?.value || '',
              investmentValue: getPreparedBillionValue(investment.value.investmentYearValue?.value),
            }))
        : [],
      investmentFederalBudget: getPreparedBillionValue(
        objectFromPropertyState.investmentFederalBudget?.value,
      ),
      investmentRegionalBudget: getPreparedBillionValue(
        objectFromPropertyState.investmentRegionalBudget?.value,
      ),
      investmentMunicipalBudget: getPreparedBillionValue(
        objectFromPropertyState.investmentMunicipalBudget?.value,
      ),
      investmentBudgetFinance: getPreparedBillionValue(
        objectFromPropertyState.investmentBudgetFinance?.value,
      ),
      investmentInvestorFinance: getPreparedBillionValue(
        objectFromPropertyState.investmentInvestorFinance?.value,
      ),
      investmentRaiseFinance: getPreparedBillionValue(
        objectFromPropertyState.investmentRaiseFinance?.value,
      ),
      investmentBankCredits: getPreparedBillionValue(
        objectFromPropertyState.investmentBankCredits?.value,
      ),
      investmentOtherRaiseFinance: getPreparedBillionValue(
        objectFromPropertyState.investmentOtherRaiseFinance?.value,
      ),
    },
    '3': {
      netPresentValue: getPreparedBillionValue(objectFromPropertyState.netPresentValue?.value),
      internalRateReturn:
        objectFromPropertyState.internalRateReturn?.value ||
        defaultFinancialModelFormValue['3'].internalRateReturn,
    },
    '4': {
      stageTaxYears: isArray(objectFromPropertyState.stageTax?.value)
        ? objectFromPropertyState.stageTax?.value
            .sort((taxA, taxB) => taxA.order - taxB.order)
            .map((tax) => ({
              stageTaxYear: tax.value.taxYear?.value || '',
              stageTaxYearValue: tax.value.taxYearValue?.value || '',
            }))
        : [],
    },
  }
}

export { generateRHFObjectForFinancialModel }
