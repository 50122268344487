import React, { forwardRef } from 'react'
import { PopupProps } from 'react-popup-manager'

import Modal from '@components/NewDesign/Modal'
import { ModalBaseComponentProps } from '@components/NewDesign/Modal/Base'
import cn from 'classnames'

import styles from './Drawer.module.scss'

export type DrawerComponentProps = ModalBaseComponentProps & {
  drawerClassName?: string
  position?: 'left' | 'right'
  dataTestId?: string
}

type DrawerProps = PopupProps & DrawerComponentProps

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  (
    { onClose, isOpen, drawerClassName, position = 'right', children, ...modalBaseComponentProps },
    ref,
  ) => {
    const isLeftPosition = position === 'left'
    const isRightPosition = position === 'right'

    return (
      <Modal.Base {...modalBaseComponentProps} isOpen={isOpen} onClose={onClose}>
        <div
          ref={ref}
          className={cn(
            styles.drawer,
            {
              [styles['drawer--leftPosition']]: isLeftPosition,
              [styles['drawer--rightPosition']]: isRightPosition,
            },
            drawerClassName,
          )}
        >
          {children}
        </div>
      </Modal.Base>
    )
  },
)

export default Drawer
