import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NoticeOfRefuseConcludeAA from '@components/Forms/NoticeOfRefuseConcludeAAForm'
import {
  defaultNoticeOfRefuseConcludeAAFormValues,
  mapOfNoticeOfRefuseConcludeAAMenu,
} from '@components/Forms/NoticeOfRefuseConcludeAAForm/const'
import { NoticeOfRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/types'
import { NoticeOfRefuseConcludeAAFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfRefuseConcludeAAForm/watcher'
import Typography from '@components/NewDesign/Typography'

import NoticeOfRefuseConcludeAALayoutWrapper from './Wrapper'

const NoticeOfRefuseConcludeAALayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NoticeOfRefuseConcludeAAFormValues>({
    defaultValues: defaultNoticeOfRefuseConcludeAAFormValues,
  })

  const handleOnClose = () => {
    NoticeOfRefuseConcludeAAFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NoticeOfRefuseConcludeAA.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfNoticeOfRefuseConcludeAAMenu[0].id}>
            <NoticeOfRefuseConcludeAALayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об отказе в заключении дополнительного соглашения
                </Typography.Headline>
                <NoticeOfRefuseConcludeAA.StatementForm className={styles.layout__form} />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <NoticeOfRefuseConcludeAA.Menu className={styles.layout__menu} />
              </div>
            </NoticeOfRefuseConcludeAALayoutWrapper>
          </FlatMenuManager>
        </NoticeOfRefuseConcludeAA.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NoticeOfRefuseConcludeAALayout)
