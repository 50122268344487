import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const thirdSectionValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['3'],
  ProjectSZPKFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
  investorHeaderNameGenitive: {
    required: defaultRHFValidation.required,
  },
  investorHeaderPosition: {
    required: defaultRHFValidation.required,
  },
  investorHeaderPositionGenitive: {
    required: defaultRHFValidation.required,
  },
  investorHeaderReasonGenitive: {
    required: defaultRHFValidation.required,
  },
}

export { thirdSectionValidationMap }
