import React, { FC, memo, useCallback } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import MasterNpaItem from '@components/RegistryNpa/Choose/Items/MasterNpaItem'
import PartNpaList from '@components/RegistryNpa/Choose/Items/PartINpaItem/List'
import {
  ChooseNpaFormValues,
  ChooseNpaThreeArrayPathName,
} from '@components/RegistryNpa/Choose/types'
import { INpaItemNew, TMasterDirectionTypeNpa } from '@services/NPA/NPA.entity'

interface NpaModalDefaultThreeNpaListProps {
  formInstance: UseFormReturn<ChooseNpaFormValues>
  direction: TMasterDirectionTypeNpa
  name: ChooseNpaThreeArrayPathName
  classificationHeader: (part: INpaItemNew) => string
}

const NpaModalChosenThreeNpaList: FC<NpaModalDefaultThreeNpaListProps> = ({
  formInstance,
  direction,
  name,
  classificationHeader,
}) => {
  const { fields: npas, update } = useFieldArray({
    control: formInstance.control,
    name: `${name}.npas`,
    keyName: 'keyNameId',
  })

  const handleToggleNpaItem = useCallback(
    (index: number) => (isCollapsed: boolean) => {
      const currentNpaItem = formInstance.getValues(`${name}.npas.${index}`)

      update(index, {
        ...currentNpaItem,
        isCollapsed,
      })
    },
    [name, update],
  )

  return (
    <div>
      {npas.map((npa, index) => {
        return (
          <MasterNpaItem key={npa.id} npa={npa} onToggle={handleToggleNpaItem(index)}>
            <PartNpaList
              name={`${name}.npas.${index}` as const}
              formInstance={formInstance}
              npaId={npa.id}
              direction={direction}
              classificationHeader={classificationHeader}
            />
          </MasterNpaItem>
        )
      })}
    </div>
  )
}

export default memo(NpaModalChosenThreeNpaList)
