import { FieldValues, Path, UseFormReturn, useWatch } from 'react-hook-form'

import { DocumentFormHelpers } from '../helpers'

const { getDeviationText } = DocumentFormHelpers

export const useDeviationText = <T extends FieldValues>(
  formInstance: UseFormReturn<T, any>,
  valueFormName: Path<T>,
  valueCheckerFormName: Path<T>,
  deviationPrefix = 'Расхождение',
) => {
  const valueStr = useWatch({
    name: valueFormName,
    control: formInstance.control,
  })

  const valueCheckerStr = useWatch({
    name: valueCheckerFormName,
    control: formInstance.control,
  })

  const deviationText = getDeviationText(valueCheckerStr, valueStr, deviationPrefix)
  return deviationText
}
