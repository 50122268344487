import { generatePath } from 'react-router'

import { RequestUrlAsObject } from '@context/AuthContext/types'

export const buildUrl = ({ url, params, queries }: RequestUrlAsObject): string => {
  const path = params ? generatePath(url, params) : url

  return queries
    ? `${path}?${String(new URLSearchParams(queries))}`
    : path
}
