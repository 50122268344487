import { Path } from 'react-hook-form'

import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'

const mapOfInfrFacilitiesCostsExpectedReimbursedMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'О проекте' },
  { id: '3', title: 'Объекты инфраструктуры' },
  { id: '4', title: 'Лицо, ответственное за заполнение формы' },
]

const infrFacilitiesCostsExpectedReimbursedBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorName: '1.investorName',
    investorAddress: '1.investorAddress',
  },
  '2': {
    projectName: '2.projectName',
    mainProjectObjects: '2.mainProjectObjects',
    investorCapital: '2.investorCapital',
    investorCapitalVAT: '2.investorCapitalVAT',
    totalCapital: '2.totalCapital',
    totalCapitalVAT: '2.totalCapitalVAT',
    endInvestStage: '2.endInvestStage',
  },
  '3': {
    infrastructureObjects: '3.infrastructureObjects',
    objectGeneralCost: '3.objectGeneralCost',
    objectGeneralCompensations: '3.objectGeneralCompensations',
    objectGeneralPercentage: '3.objectGeneralPercentage',
  },
  '4': {
    headerName: '4.headerName',
    headerPosition: '4.headerPosition',
    headerPhone: '4.headerPhone',
    headerEmail: '4.headerEmail',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
  },
} as const

const defaultInfrFacilitiesCostsExpectedReimbursedFormValues: InfrFacilitiesCostsExpectedReimbursedFormValues =
  {
    '1': {
      investorFullName: '',
      investorName: '',
      investorAddress: '',
    },
    '2': {
      projectName: '',
      mainProjectObjects: [],
      investorCapital: '',
      investorCapitalVAT: '',
      totalCapital: '',
      totalCapitalVAT: '',
      endInvestStage: '',
    },
    '3': {
      infrastructureObjects: [],
      objectGeneralCost: '',
      objectGeneralCompensations: '',
    },
    '4': {
      headerName: null,
      headerPosition: '',
      headerPhone: '',
      headerEmail: null,
    },
    additionalFields: {
      isFederal: true,
    },
  }

const rewriteInfrFacilitiesCostsExpectedReimbursedFieldsAfterApplyDiffs: Path<InfrFacilitiesCostsExpectedReimbursedFormValues>[] =
  [infrFacilitiesCostsExpectedReimbursedBlockValues['4'].headerEmail]

export {
  defaultInfrFacilitiesCostsExpectedReimbursedFormValues,
  infrFacilitiesCostsExpectedReimbursedBlockValues,
  mapOfInfrFacilitiesCostsExpectedReimbursedMenu,
  rewriteInfrFacilitiesCostsExpectedReimbursedFieldsAfterApplyDiffs,
}
