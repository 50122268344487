import React from 'react'
import { useFormContext } from 'react-hook-form'

import RFSection from '@components/DocumentFormComponents/FormSections/RFSection'
import { useAAgreementIncludeRelatedTermsManager } from '@components/Forms/AAgreementIncludeRelatedTermsForm/Manager'
import { AAgreementIncludeRelatedTermsFormValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/types'
import { AAgreementIncludeRelatedTermsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementIncludeRelatedTermsForm/watcher'

const First = () => {
  const formInstance = useFormContext<AAgreementIncludeRelatedTermsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementIncludeRelatedTermsManager()

  if (!formInstance) return null

  return (
    <RFSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={AAgreementIncludeRelatedTermsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default First
