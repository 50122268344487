import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnCreationOtherEstateObjectsBlockValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import ObjectAreasList from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectAreasList'
import ObjectRegionsList from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectRegionsList'
import { eObjectAAgreementOnCreationOtherEstateObjectsValidationMap } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/validation'
import {
  ConstructionObjectsArrayPathName,
  ConstructionSubObjectsArrayPathName,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface ObjectFormItemProps {
  isShowObjectItemContent: boolean
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAgreementOnCreationOtherEstateObjectsFormValues>
  formName: ConstructionObjectsArrayPathName | ConstructionSubObjectsArrayPathName
  isParentObject?: boolean
}

const ObjectFormItem: FC<ObjectFormItemProps> = ({
  isParentObject = false,
  isShowObjectItemContent,
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formInstance.control,
  })

  const isCompensationRenderCondition = useWatch({
    name: `${formName}.objectIsInfrastructure`,
    control: formInstance.control,
  })
  const isOldCreatedRenderCondition = useWatch({
    name: `${formName}.oldIsCapitalObject`,
    control: formInstance.control,
  })
  const isPaper = useWatch({
    name: aAgreementOnCreationOtherEstateObjectsBlockValues.additionalFields.isPaper,
    control: formInstance.control,
  })

  const {
    getSubscribableControlProps,
    getControllerProps,
    getTextareaProps,
    getAmountInputProps,
    getInputProps,
    getSwitchProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode: isNew && editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  })

  return (
    <Container className={'p-0'}>
      <Col xs={12}>
        {!isShowObjectItemContent && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectName`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Наименование'}
                  controllerProps={getControllerProps({
                    name: `${formName}.objectName`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <CollapseWrapper
          defaultExpanded={isShowObjectItemContent}
          isExpanded={isShowObjectItemContent}
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectName`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.objectName`,
                      rules: eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectName,
                      textareaProps: {
                        label: 'Наименование',
                        leftAddons: (
                          <FormIconWithTooltip
                            tooltipContent={
                              'Информация о характеристиках объекта вносится в соответствии с РНС, ГПЗУ, проектом планировки территории или иным документом'
                            }
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectName`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <ObjectRegionsList
              blockViewIsValidating={blockViewIsValidating}
              editMode={isNew && editMode}
              formInstance={formInstance}
              formName={formName}
            />
            {isParentObject && (
              <ObjectAreasList
                editMode={isNew && editMode}
                blockViewIsValidating={blockViewIsValidating}
                formInstance={formInstance}
                formName={formName as ConstructionObjectsArrayPathName}
              />
            )}
            <Row>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectConstructionKind`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.objectConstructionKind`,
                    rules:
                      eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectConstructionKind,

                    textareaProps: {
                      label: 'Вид объекта капитального строительства',
                      caption: 'например: здание, сооружение, строение',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.objectConstructionKind`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.objectConstructionKind`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Row>
            <Row>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectPurpose`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.objectPurpose`,
                    rules: eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectPurpose,

                    textareaProps: {
                      label: 'Назначение объекта',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.objectPurpose`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.objectPurpose`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectConstructionSquare`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectConstructionSquare`,
                      rules:
                        eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectConstructionSquare,
                      inputProps: {
                        label: 'Площадь застройки, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectConstructionSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectConstructionSquare`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectSquare`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectSquare`,
                      rules:
                        eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectSquare,
                      inputProps: {
                        label: 'Площадь, кв.м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectSquare`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectNonLivingAreaSquare`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectNonLivingAreaSquare`,
                      rules:
                        eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectNonLivingAreaSquare,
                      inputProps: {
                        label: 'Площадь нежилых помещений, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectLivingAreaSquare`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectLivingAreaSquare`,
                      rules:
                        eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectLivingAreaSquare,
                      inputProps: {
                        label: 'Площадь жилых помещений, кв. м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectFloor`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.objectFloor`,
                      rules: eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectFloor,
                      inputProps: {
                        label: 'Количество этажей',
                        caption: 'если применимо',
                        suffix: '',
                        maxLength: 10,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectFloor`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectFloor`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectUndergroundFloor`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.objectUndergroundFloor`,
                      rules:
                        eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectUndergroundFloor,
                      inputProps: {
                        label: 'в том числе количество подземных',
                        caption: 'если применимо',
                        suffix: '',
                        maxLength: 10,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectUndergroundFloor`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectUndergroundFloor`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectHeight`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.objectHeight`,
                      rules:
                        eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectHeight,
                      inputProps: {
                        label: 'Высота, м',
                        caption: 'если применимо',
                        suffix: '',
                        minority: 100000,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectHeight`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectHeight`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.objectDetails`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.objectDetails`,
                      rules:
                        eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectDetails,
                      textareaProps: {
                        label: 'Иные характеристики объекта',
                        caption: 'если применимо',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.objectDetails`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.objectDetails`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Stack direction={'vertical'} gap={2}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.objectIsInfrastructure`,
                    })}
                  >
                    <ControlledSwitch
                      {...getSwitchProps({
                        name: `${formName}.objectIsInfrastructure`,
                        switchProps: {
                          wrapperClassName: styles.form__switch,
                          label: 'Является объектом инфраструктуры',
                        },
                        onChange: () => handleChangeValue?.(`${formName}.objectIsInfrastructure`),
                      })}
                    />
                  </SubscribableControl>
                  <CollapseWrapper
                    defaultExpanded={isCompensationRenderCondition}
                    isExpanded={isCompensationRenderCondition}
                  >
                    <Stack direction={'vertical'} gap={3}>
                      <Col xs={12}>
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${formName}.objectInfrastructureType`,
                          })}
                        >
                          {({ overrideProps }) => {
                            const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                              ? overrideProps.fetcherOptions
                              : undefined

                            return (
                              <ControlledFormSingleSelect
                                {...getSingleSelectProps({
                                  fetcherProps,
                                  optionsAdapter: (item) => ({
                                    displayValue: item.name,
                                    value: item.id,
                                  }),
                                  controllerProps: {
                                    name: `${formName}.objectInfrastructureType`,
                                    rules:
                                      eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.objectInfrastructureType,
                                  },
                                  selectProps: {
                                    inputProps: {
                                      label: 'Тип инфраструктуры',
                                    },
                                    onChangeFormValue: () =>
                                      setTimeout(() => {
                                        handleChangeValue?.(`${formName}.objectInfrastructureType`)
                                      }),
                                  },
                                })}
                              />
                            )
                          }}
                        </SubscribableControl>
                      </Col>
                    </Stack>
                  </CollapseWrapper>
                </Stack>
              </Col>
            </Row>
            {isPaper && (
              <Row>
                <Col xs={12}>
                  <Stack direction={'vertical'} gap={2}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.oldIsCapitalObject`,
                      })}
                    >
                      <ControlledSwitch
                        {...getSwitchProps({
                          name: `${formName}.oldIsCapitalObject`,
                          switchProps: {
                            wrapperClassName: styles.form__switch,
                            label: 'Является объектом капитального строительства',
                          },
                          onChange: () => handleChangeValue?.(`${formName}.oldIsCapitalObject`),
                        })}
                      />
                    </SubscribableControl>
                    <CollapseWrapper
                      defaultExpanded={!isOldCreatedRenderCondition}
                      isExpanded={!isOldCreatedRenderCondition}
                    >
                      <Stack direction={'vertical'} gap={3}>
                        <Row>
                          <Col xs={12}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.oldCreatedDocument`,
                              })}
                            >
                              <ControlledTextarea
                                {...getTextareaProps({
                                  name: `${formName}.oldCreatedDocument`,
                                  rules:
                                    eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.oldCreatedDocument,
                                  textareaProps: {
                                    label:
                                      'Документ, подтверждающий строительство, реконструкцию, модернизацию',
                                    caption: 'в творительном падеже',
                                  },
                                  onBlur: () =>
                                    setTimeout(() => {
                                      handleChangeValue?.(`${formName}.oldCreatedDocument`)
                                    }, 0),
                                  onChange: () =>
                                    setTimeout(() => {
                                      debouncedHandleChangeValue?.(`${formName}.oldCreatedDocument`)
                                    }, 0),
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <SubscribableControl
                              {...getSubscribableControlProps({
                                path: `${formName}.oldCreatedOrganization`,
                              })}
                            >
                              <ControlledTextarea
                                {...getTextareaProps({
                                  name: `${formName}.oldCreatedOrganization`,
                                  rules:
                                    eObjectAAgreementOnCreationOtherEstateObjectsValidationMap.oldCreatedOrganization,

                                  textareaProps: {
                                    label: 'Орган, уполномоченный на выдачу',
                                    caption: 'в творительном падеже',
                                  },
                                  onBlur: () =>
                                    setTimeout(() => {
                                      handleChangeValue?.(`${formName}.oldCreatedOrganization`)
                                    }, 0),
                                  onChange: () =>
                                    setTimeout(() => {
                                      debouncedHandleChangeValue?.(
                                        `${formName}.oldCreatedOrganization`,
                                      )
                                    }, 0),
                                })}
                              />
                            </SubscribableControl>
                          </Col>
                        </Row>
                      </Stack>
                    </CollapseWrapper>
                  </Stack>
                </Col>
              </Row>
            )}
          </Stack>
        </CollapseWrapper>
      </Col>
    </Container>
  )
}

export default ObjectFormItem
