import React, { FC, memo } from 'react'

import AttachmentItem from '@components/Attachments/Attachment'
import WebForm from '@components/Attachments/WebForm'
import { isNullOrUndefined } from '@helpers/checkTypes'
import { loadingTypesMap } from '@services/Dictionaries/dictionaries.const'
import type { IDocumentTypeInfoWithDict } from '@services/Documents/documents.entity'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'

const { isDocumentReadDigitizing } = DocumentsHelpersService

interface AttachmentSetItemControllerProps {
  permissions: number
  documentSetId: string
  currentDfoId: string
  typeOfSets: string
  documentProps: IDocumentTypeInfoWithDict
  showChangedSetDocuments?: boolean
  isChangesMadeTab?: boolean
  digitizingInProcess?: boolean
}

const AttachmentSetItemController: FC<AttachmentSetItemControllerProps> = ({
  permissions,
  currentDfoId,
  documentSetId,
  typeOfSets,
  documentProps,
  isChangesMadeTab,
  showChangedSetDocuments,
  digitizingInProcess,
}) => {
  const { documentInfoFromDict, forms } = documentProps
  const { loadingType } = documentInfoFromDict

  const hasForms = !isNullOrUndefined(forms) && !!forms.length

  const isDocumentReadDigitizingPermission = isDocumentReadDigitizing(permissions)

  const digitizingInProcessCondition =
    (loadingType === loadingTypesMap.NORMAL || loadingType === loadingTypesMap.DRAFT) &&
    !!digitizingInProcess

  // Условие для рендера кейсов d2 и d3 в оцифровке
  if (digitizingInProcessCondition && (!hasForms || !isDocumentReadDigitizingPermission)) {
    return (
      <AttachmentItem.Controller
        parentSetType={typeOfSets}
        documentProps={documentProps}
        permissions={permissions}
        isChangesMadeTab={isChangesMadeTab}
        documentSetId={documentSetId || ''}
        currentDfoId={currentDfoId}
        showChangedSetDocuments={showChangedSetDocuments}
      />
    )
  }

  if (loadingType === loadingTypesMap.FORM || digitizingInProcessCondition) {
    return (
      <WebForm.Controller
        documentSetId={documentSetId}
        currentDfoId={currentDfoId}
        documentSetType={typeOfSets}
        documentProps={documentProps}
        permissions={permissions}
        digitizingInProcess={digitizingInProcess}
      />
    )
  }

  return (
    <AttachmentItem.Controller
      parentSetType={typeOfSets}
      documentProps={documentProps}
      permissions={permissions}
      isChangesMadeTab={isChangesMadeTab}
      documentSetId={documentSetId || ''}
      currentDfoId={currentDfoId}
      showChangedSetDocuments={showChangedSetDocuments}
    />
  )
}

export default memo(AttachmentSetItemController)
