import { FC } from 'react'

import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import cn from 'classnames'

import styles from './EmptyDataText.module.scss'

interface EmptyDataTextProps {
  text?: string
  wrapperClassName?: string
  className?: string
}

const EmptyDataText: FC<EmptyDataTextProps> = ({
  text = 'Раздел не был заполнен',
  wrapperClassName,
  className,
}) => {
  return (
    <Col xs={12} className={cn(styles.emptyDataText__padding, wrapperClassName)}>
      <Typography.Body variant={'bodyMRegular'} className={className}>
        {text}
      </Typography.Body>
    </Col>
  )
}

export default EmptyDataText
