import NPAService from '@services/NPA/NPA.service'

import { NPAModalActionKindOld } from './constants'
import type {
  NPAModalModuleOldState,
  NPAModalReducerActionOldCreators,
  NPAModalReducerOldAction,
} from './types'

const initialNpaModalOldState: NPAModalModuleOldState = {
  requestLoading: false,
  normalizedData: {
    directions: [],
    npas: [],
    parts: [],
  },
  chosenThree: [],
  threeToRender: [],
  chosenParts: [],
  chosenPartsError: null,
}

const { normalizeNpaData } = NPAService

const npaNormalizedOldReducer = (
  state: NPAModalModuleOldState,
  action: NPAModalReducerOldAction,
): NPAModalModuleOldState => {
  const { type, payload } = action

  switch (type) {
    case NPAModalActionKindOld.SETUP_READY_THREE:
      return {
        ...state,
        threeToRender: payload,
      }

    case NPAModalActionKindOld.NORMALIZE_DATA:
      const preparedData = normalizeNpaData(payload)

      return {
        ...state,
        normalizedData: {
          directions: preparedData.preparedDirections,
          npas: preparedData.preparedNpas,
          parts: preparedData.preparedParts,
        },
      }

    case NPAModalActionKindOld.CHANGE_CHOSEN:
      const isChosen = state.chosenParts.find((part) => part.partId === payload.partId)

      const preparedChosenParts = !isChosen
        ? [...state.chosenParts, payload]
        : state.chosenParts.filter((part) => part.partId !== payload.partId)

      return {
        ...state,
        chosenParts: preparedChosenParts,
      }

    case NPAModalActionKindOld.CHANGE_CHOSEN_DATA:
      return {
        ...state,
        chosenParts: state.chosenParts.map((part) => {
          if (part.partId === payload.partId) return payload

          return part
        }),
      }

    case NPAModalActionKindOld.CHANGE_CHOSEN_ERRORS:
      return {
        ...state,
        chosenPartsError: payload,
      }

    case NPAModalActionKindOld.SETUP_CHOSEN_THREE:
      return {
        ...state,
        chosenThree: payload,
      }

    case NPAModalActionKindOld.TOGGLE_REQUEST_LOADING:
      return {
        ...state,
        requestLoading: !state.requestLoading,
      }

    default:
      return state
  }
}

const npaNormalizedActionOldCreators: NPAModalReducerActionOldCreators = {
  setThreeToRender: (payload) => ({ type: NPAModalActionKindOld.SETUP_READY_THREE, payload }),
  setNormalizeData: (payload) => ({ type: NPAModalActionKindOld.NORMALIZE_DATA, payload }),
  setChosenParts: (payload) => ({ type: NPAModalActionKindOld.CHANGE_CHOSEN, payload }),
  setChosenPartsData: (payload) => ({ type: NPAModalActionKindOld.CHANGE_CHOSEN_DATA, payload }),
  setChosenPartsError: (payload) => ({ type: NPAModalActionKindOld.CHANGE_CHOSEN_ERRORS, payload }),
  setChosenThree: (payload) => ({ type: NPAModalActionKindOld.SETUP_CHOSEN_THREE, payload }),
  setRequestLoading: (payload) => ({ type: NPAModalActionKindOld.TOGGLE_REQUEST_LOADING, payload }),
}

export { initialNpaModalOldState, npaNormalizedActionOldCreators, npaNormalizedOldReducer }
