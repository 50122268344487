import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { oldStepExpensesSectionValidationMap } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/AdditionalForms/MainTemplate/FormController/OldStepExpenses/validation'
import {
  FifthCapitalStepsArrayPathName,
  FifthOldStepExpensesPathName,
} from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/types'
import { useAAVolumeOfCapitalInvestmentsManager } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Manager'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import {
  AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher,
  AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
} from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

interface OldStepExpensesProps {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAVolumeOfCapitalInvestmentsFormValues>
  formName: FifthOldStepExpensesPathName
  parentStepName: FifthCapitalStepsArrayPathName
}

const { transformRHFPathInProperties } = DocumentFormHelpers

const OldStepExpenses: FC<OldStepExpensesProps> = ({
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
  parentStepName,
}) => {
  const {
    handlers: {
      handleAddItemToListWithValue,
      handleChangeValue,
      handleRemoveItemFromList,
      debouncedHandleChangeValue,
      getPropertiesProps,
    },
    preparedProps: { subscribableControl },
  } = useAAVolumeOfCapitalInvestmentsManager()

  const { getSubscribableControlProps, getTextareaProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
    })

  const { fields: oldStepExpenses } = useFieldArraySubscribableControl<
    AAVolumeOfCapitalInvestmentsFormValues,
    FifthOldStepExpensesPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: formName,
    keyName: 'keyNameId',
    watcher: AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher,
  })

  const handleAddOldStepExpense = async () => {
    const permanentIdOfCurrentStep = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(parentStepName),
    )?.permanentId

    if (!permanentIdOfCurrentStep) return

    await handleAddItemToListWithValue?.(formName, {
      oldStepIdExpense: permanentIdOfCurrentStep,
    })
  }

  const handleRemoveOldStepExpense = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${formName}.${index}`, formName)
  }

  return (
    <Group disableBottomBorder title={'Виды расходов'}>
      <Stack direction={'vertical'} gap={!!oldStepExpenses?.length ? 3 : 0}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: formName,
          })}
        >
          <FlipperList list={oldStepExpenses}>
            {oldStepExpenses.map((oldStepExpense, index) => {
              const oldStepExpenseName = `${formName}.${index}` as const

              return (
                <GroupWithRemoveButton
                  disableBottomBorder
                  key={oldStepExpense.id}
                  id={oldStepExpense.id}
                  title={`Вид расходов №${index + 1}`}
                  onRemove={editMode ? handleRemoveOldStepExpense(index) : undefined}
                >
                  <Stack direction={'vertical'} gap={3}>
                    <Row>
                      <Col xs={12}>
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${oldStepExpenseName}.oldStepExpenseNameCommited`,
                          })}
                        >
                          <ControlledTextarea
                            {...getTextareaProps({
                              name: `${oldStepExpenseName}.oldStepExpenseNameCommited`,
                              textareaProps: {
                                disabled: true,
                                label: 'Текущее значение вида расходов',
                              },
                            })}
                          />
                        </SubscribableControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${oldStepExpenseName}.oldStepExpenseName`,
                          })}
                        >
                          <ControlledTextarea
                            {...getTextareaProps({
                              name: `${oldStepExpenseName}.oldStepExpenseName`,
                              rules: oldStepExpensesSectionValidationMap.oldStepExpenseName,
                              textareaProps: {
                                label: 'Измененное значение вида расходов',
                              },
                              onBlur: () =>
                                setTimeout(
                                  () =>
                                    handleChangeValue?.(`${oldStepExpenseName}.oldStepExpenseName`),
                                  0,
                                ),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${oldStepExpenseName}.oldStepExpenseName`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </Col>
                    </Row>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={6}
                      secondColumnSize={6}
                      title={'Текущий объем расходов'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${oldStepExpenseName}.oldStepExpenseValueCommited`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${oldStepExpenseName}.oldStepExpenseValueCommited`,
                            inputProps: {
                              disabled: true,
                            },
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                    <FieldView.RowWithBottomBorder
                      disableBottomDefaultStyles
                      firstColumnSize={6}
                      secondColumnSize={6}
                      title={'Измененный объем расходов'}
                    >
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${oldStepExpenseName}.oldStepExpenseValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${oldStepExpenseName}.oldStepExpenseValue`,
                            rules: oldStepExpensesSectionValidationMap.oldStepExpenseValue,
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${oldStepExpenseName}.oldStepExpenseValue`)
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${oldStepExpenseName}.oldStepExpenseValue`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </FieldView.RowWithBottomBorder>
                  </Stack>
                </GroupWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddOldStepExpense}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      disabled={isLoading}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default OldStepExpenses
