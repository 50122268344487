import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation } from '@constants/validations'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

const stageFieldValidation: FormValuesValidationSection<
  keyof StatementFormValues['stage'],
  StatementFormValues
> = {
  projectStartDate: ({ form }) => ({
    required: defaultRHFValidation.required,
    onChange: async () => {
      const allStages = form.getValues(statementBlockValues.stage.stepOfStages)

      allStages.forEach((stage, index) => {
        form.trigger(`stage.stepOfStages.${index}.stepStartDate`)
        form.trigger(`stage.stepOfStages.${index}.stepEndDate`)
      })

      form.trigger(statementBlockValues.stage.agreementEndDate)

      if (form?.getValues(statementBlockValues.stage.projectEndDate) !== '') {
        form?.trigger(statementBlockValues.stage.projectEndDate)
      }
    },
  }),

  projectEndDate: ({ form }) => ({
    required: defaultRHFValidation.required,
    validate: {
      positive: (value) => {
        const projectStartDate = form.getValues(statementBlockValues.stage.projectStartDate)

        return (
          (isDateValidForDayjs(value) &&
            dayjs(value, objOfDateFormats.defaultFormat) >=
              dayjs(projectStartDate, objOfDateFormats.defaultFormat)) ||
          'дата окончания проекта должна быть больше или равна дате начала проекта'
        )
      },
    },

    onChange: async () => {
      const allStages = form.getValues(statementBlockValues.stage.stepOfStages)

      allStages.forEach((stage, index) => {
        form.trigger(`stage.stepOfStages.${index}.stepStartDate`)
        form.trigger(`stage.stepOfStages.${index}.stepEndDate`)
      })

      form.trigger(statementBlockValues.participantsAndInvestments.projectDecisionDate)
    },
  }),

  agreementEndDate: ({ form }) => ({
    required: defaultRHFValidation.required,
    validate: {
      positiveProjectStartDate: (value) => {
        const projectStartDate = form.getValues(statementBlockValues.stage.projectStartDate)

        return (
          (isDateValidForDayjs(value) &&
            dayjs(value, objOfDateFormats.defaultFormat) >=
              dayjs(projectStartDate, objOfDateFormats.defaultFormat)) ||
          'дата окончания соглашения должна быть больше или равна дате начала проекта'
        )
      },
    },
  }),
}

export { stageFieldValidation }
