import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useBeneficiaryOfSuccessorOrganizationInformationManager } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/Manager'
import { BeneficiaryOfSuccessorOrganizationInformationFormValues } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/types'

import { mapOfBeneficiaryOfSuccessorOrganizationInformationMenu } from '../const'
import BeneficiaryOfSuccessorOrganizationInformationForms from '../Forms'

const mapOfBeneficiaryOfSuccessorOrganizationInformationForms: FormItemProps = {
  '1': {
    node: <BeneficiaryOfSuccessorOrganizationInformationForms.First />,
  },
  '2': {
    node: <BeneficiaryOfSuccessorOrganizationInformationForms.Second />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const BeneficiaryOfSuccessorOrganizationInformationForm: FC<FormModalStatementProps> = ({
  ...rest
}) => {
  const formInstance = useFormContext<BeneficiaryOfSuccessorOrganizationInformationFormValues>()

  const {
    state: { formIsLoading },
  } = useBeneficiaryOfSuccessorOrganizationInformationManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfBeneficiaryOfSuccessorOrganizationInformationMenu}
      mapOfForms={mapOfBeneficiaryOfSuccessorOrganizationInformationForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(BeneficiaryOfSuccessorOrganizationInformationForm)
