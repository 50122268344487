import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfStatementByHeadOfMunicipalityMenu } from '@components/Forms/StatementByHeadOfMunicipality/const'
import StatementByHeadOfMunicipalityForms from '@components/Forms/StatementByHeadOfMunicipality/Forms'
import { useStatementByHeadOfMunicipalityManager } from '@components/Forms/StatementByHeadOfMunicipality/Manager'
import { StatementByHeadOfMunicipalityFormValues } from '@components/Forms/StatementByHeadOfMunicipality/types'

const mapOfStatementByHeadOfMunicipalityForms: FormItemProps = {
  '1': {
    node: <StatementByHeadOfMunicipalityForms.First />,
  },
  '2': {
    node: <StatementByHeadOfMunicipalityForms.Second />,
  },
  '3': {
    node: <StatementByHeadOfMunicipalityForms.Third />,
  },
  '4': {
    node: <StatementByHeadOfMunicipalityForms.Fourth />,
  },
} as const

const StatementByHeadOfMunicipalityForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<StatementByHeadOfMunicipalityFormValues>()

  const {
    state: { formIsLoading },
  } = useStatementByHeadOfMunicipalityManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      isAutoGeneratedForm
      mapOfMenu={mapOfStatementByHeadOfMunicipalityMenu}
      mapOfForms={mapOfStatementByHeadOfMunicipalityForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(StatementByHeadOfMunicipalityForm)
