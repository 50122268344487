//Переиспользуемые адаптеры

import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

class PropertiesPropsBasicAdapters {
  static getRFAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedRfHeaderName = (() => {
      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id === objectFromPropertyState.rf?.permanentId
        )
      })

      if (!headerInfo)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      return PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(headerInfo.value.signer)
    })()

    return {
      rfHeaderName: preparedRfHeaderName,
      rfFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.rf?.value?.memberOrganizationId,
      ),
    }
  }

  static getSubjectsAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedSubjects = (() => {
      const defaultSubjectValue = {
        propertyId: '',
        lastUpdateDt: '',
        value: [
          {
            propertyId: '',
            lastUpdateDt: '',
            value: null,
          },
        ],
      }

      if (!objectFromPropertyState.subjects || !objectFromPropertyState.subjects?.value.length)
        return defaultSubjectValue

      const docSigners = objectFromPropertyState.docSigners?.value

      const preparedSubjects = objectFromPropertyState.subjects?.value.map((subject) => {
        const headerInfo = docSigners.find((docSigner) => {
          return docSigner.value?.signerOrganization?.value.id === subject.permanentId
        })

        if (!headerInfo)
          return {
            propertyId: '',
            lastUpdateDt: '',
            value: null,
          }

        return {
          ...PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(headerInfo),
          value: {
            subjectFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
              subject.value?.memberOrganizationId,
            ),
            subjectHeaderName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
              headerInfo.value.signer,
            ),
          },
        }
      })

      return {
        ...PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          objectFromPropertyState.subjects,
        ),
        value: preparedSubjects,
      }
    })()

    return preparedSubjects
  }

  static getMunicipalitiesAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedMunicipalities = (() => {
      const defaultMunicipalityValue = {
        propertyId: '',
        lastUpdateDt: '',
        value: [
          {
            propertyId: '',
            lastUpdateDt: '',
            value: null,
          },
        ],
      }

      if (
        !objectFromPropertyState.municipalities ||
        !objectFromPropertyState.municipalities?.value.length
      )
        return defaultMunicipalityValue

      const docSigners = objectFromPropertyState.docSigners?.value

      const preparedMunicipalities = objectFromPropertyState.municipalities?.value.map(
        (municipality) => {
          const headerInfo = docSigners.find((docSigner) => {
            return docSigner.value?.signerOrganization?.value.id === municipality.permanentId
          })

          if (!headerInfo)
            return {
              propertyId: '',
              lastUpdateDt: '',
              value: null,
            }

          return {
            ...PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(headerInfo),
            value: {
              municipalityHeaderName:
                PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
                  headerInfo.value.signer,
                ),
              municipalityFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
                municipality.value?.memberOrganizationId,
              ),
              municipalityNameGenitive:
                PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
                  municipality.value.memberNameGenitive,
                ),
              municipalityFullNameGenitive:
                PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
                  municipality?.value?.memberFullNameGenitive,
                ),
            },
          }
        },
      )

      return {
        ...PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          objectFromPropertyState.municipalities,
        ),
        value: preparedMunicipalities,
      }
    })()

    return preparedMunicipalities
  }

  static getNewMunicipalityAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedNewMunicipalityHeaderName = (() => {
      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.newMunicipality?.permanentId
        )
      })

      if (!headerInfo)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      return PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(headerInfo.value.signer)
    })()

    return {
      municipalityHeaderName: preparedNewMunicipalityHeaderName,
      municipalityFullNameGenitive: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.newMunicipality?.value.memberFullNameGenitive,
      ),
      municipalityFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.newMunicipality?.value?.memberOrganizationId,
      ),
      municipalityFullNameInstrumental:
        PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          objectFromPropertyState?.newMunicipality?.value?.memberFullNameInstrumental,
        ),
    }
  }

  static getInvestorAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInvestorHeaderName = (() => {
      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.investor?.permanentId
        )
      })

      if (!headerInfo)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      return PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(headerInfo.value.signer)
    })()

    return {
      investorHeaderName: preparedInvestorHeaderName,
      investorFullNameGenitive: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.investorFullNameGenitive,
      ),
      investorFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorFullNameInstrumental: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.investorFullNameInstrumental,
      ),
    }
  }

  static getUOAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedUoHeaderName = (() => {
      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id === objectFromPropertyState.uo?.permanentId
        )
      })

      if (!headerInfo)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      return PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(headerInfo.value.signer)
    })()

    return {
      uoHeaderName: preparedUoHeaderName,
      uoFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.uo?.value?.memberOrganizationId,
      ),
    }
  }

  static getInvestorPartnerAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInvestorPartnerHeaderName = (() => {
      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.investorPartner?.permanentId
        )
      })

      if (!headerInfo)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      return PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(headerInfo.value.signer)
    })()

    return {
      investorPartnerHeaderName: preparedInvestorPartnerHeaderName,
      investorPartnerFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investorPartner?.value?.memberOrganizationId,
      ),
    }
  }

  static getInitiatorAdapter(objectFromPropertyState: Record<string, any>) {
    const preparedInitiatorHeaderName = (() => {
      const docSigners = objectFromPropertyState.docSigners?.value
      if (!docSigners || !docSigners.length)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      const headerInfo = docSigners.find((docSigner) => {
        return (
          docSigner.value?.signerOrganization?.value.id ===
          objectFromPropertyState.initiator?.permanentId
        )
      })

      if (!headerInfo)
        return {
          propertyId: '',
          lastUpdateDt: '',
        }

      return PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(headerInfo.value.signer)
    })()

    return {
      initiatorHeaderName: preparedInitiatorHeaderName,
      initiatorFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.initiator?.value?.memberOrganizationId,
      ),
    }
  }

  static getInfoAboutDocumentAdapter(objectFromPropertyState: Record<string, any>) {
    return {
      documentDateSign: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.documentDateSign,
      ),
      documentNumber: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.documentNumber,
      ),
      documentReceivers: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.documentReceivers,
      ),
    }
  }

  static getPropertyPropsFromPropertyState(propertyState?: Record<string, any>) {
    const basePropertyProps = {
      propertyId: propertyState?.propertyId || '',
      type: propertyState?.type || '',
      lastUpdateDt: propertyState?.lastUpdateDt || '',
      isPublic: propertyState?.isPublic || false,
    }

    if (propertyState?.type === PropertyTypeEnum.LIST) {
      return {
        ...basePropertyProps,
        deletedElementFromList: !!propertyState?.deletedElementFromList,
      }
    }

    if (propertyState?.type === PropertyTypeEnum.MAP) {
      return {
        ...basePropertyProps,
        new: !!propertyState?.new,
      }
    }

    return basePropertyProps
  }
}

export { PropertiesPropsBasicAdapters }
