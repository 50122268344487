import { memo, useCallback } from 'react'

import Icon from '@components/Icon'
import Loader from '@components/Loader'
import Typography from '@components/NewDesign/Typography'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import DeleteIcon from '@icons/DeleteFilled.svg'
import DownloadIcon from '@icons/Download.svg'
import AlignCenterIcon from '@icons/editor/format_align_center.svg'
import AlignLeftIcon from '@icons/editor/format_align_left.svg'
import AlignRightIcon from '@icons/editor/format_align_right.svg'
import FileIcon from '@icons/File.svg'
import RotateRightIcon from '@icons/RotateRight.svg'
import cn from 'classnames'

import { fileAlignmnet } from '../const'

import styles from './LetterHeadPartsDropItem.module.scss'
import PanelIconButton from './PanelIconButton'
import { LetterHeadFilledItemProps } from './types'

const LetterHeadFilledItem = ({
  state,
  title,
  actions: { resetFile, setFileAlignment, reloadFile, downloadFile },
}: LetterHeadFilledItemProps) => {
  const handleFileDownload = () => {
    if (!state.fileId) return

    downloadFile(state)
  }

  const isLeft = state.alignment === fileAlignmnet.left
  const isCenter = state.alignment === fileAlignmnet.center
  const isRight = state.alignment === fileAlignmnet.right

  const handleSetLeftAlignment = useCallback(
    () => setFileAlignment(fileAlignmnet.left),
    [setFileAlignment],
  )
  const handleSetCenterAlignment = useCallback(
    () => setFileAlignment(fileAlignmnet.center),
    [setFileAlignment],
  )
  const handleSetRightAlignment = useCallback(
    () => setFileAlignment(fileAlignmnet.right),
    [setFileAlignment],
  )

  return (
    <div className={styles.filledItemRoot}>
      <Loader loading={state.isLoading}>
        <div className={styles.fileInfo}>
          <Icon noCurrentColorSvgFill src={FileIcon} className={styles.fileInfoIcon} />
          <div>
            <Typography variant="bodyMMedium" className={styles.fileInfoTexts}>
              {title}
            </Typography>
            <TypographyWithTooltip.Body
              variant="bodySMedium"
              color="on-surface-secondary"
              tooltipProps={{
                availableHeight: true,
                preventOverflow: false,
                zIndex: 51,
                position: 'top',
                offset: [0, 10],
                targetClassName: styles.tooltip__target,
                contentClassName: styles.tooltip__content,
                popoverClassName: styles.tooltip__popover,
                arrowClassName: styles.tooltip__arrow,
              }}
            >
              {state.fileName}
            </TypographyWithTooltip.Body>
          </div>
          <div className={styles.controlPanel}>
            <PanelIconButton icon={RotateRightIcon} onClick={reloadFile} />
            {!state?.isNew && <PanelIconButton icon={DownloadIcon} onClick={handleFileDownload} />}
            <PanelIconButton icon={DeleteIcon} onClick={resetFile} />
          </div>
        </div>
        <div className={styles.aligmentControl}>
          <Typography variant="bodyMRegular" color="on-surface-secondary">
            Положение на странице
          </Typography>
          <div className={styles.controlPanel}>
            <PanelIconButton
              icon={AlignLeftIcon}
              className={cn({ [styles.isAlign]: isLeft })}
              onClick={handleSetLeftAlignment}
            />
            <PanelIconButton
              icon={AlignCenterIcon}
              className={cn({ [styles.isAlign]: isCenter })}
              onClick={handleSetCenterAlignment}
            />
            <PanelIconButton
              icon={AlignRightIcon}
              className={cn({ [styles.isAlign]: isRight })}
              onClick={handleSetRightAlignment}
            />
          </div>
        </div>
      </Loader>
    </div>
  )
}

export default memo(LetterHeadFilledItem)
