import { ChangeEvent, FocusEvent, MouseEvent } from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

import { CalendarProps } from '../Calendar/Calendar'

import CalendarInput from './CalendarInput'
import { getErrorMessageByDate } from './helpers'
import { CalendarInputProps } from './types'

export type ControlledCalendarInputProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> &
  Pick<CalendarInputProps, 'onChange' | 'onBlur' | 'onInputChange' | 'onCalendarChange' | 'id'> & {
    /**
     * Доп. пропсы для инпута
     */
    calendarInputProps?: Omit<
      CalendarInputProps,
      'onChange' | 'onInputChange' | 'onCalendarChange' | 'error' | 'value' | 'ref'
    >

    /**
     * Доп. пропсы для календаря
     */
    calendarProps?: Omit<CalendarProps, 'show' | 'onChange' | 'value'>

    /**
     * Доп. пропсы для тултипа, в котором открывается календарь
     */
    tooltipProps?: CalendarInputProps['tooltipProps']

    dataTestId?: string
  }

export const ControlledCalendarInput = <T extends FieldValues>(
  props: ControlledCalendarInputProps<T>,
) => {
  const {
    calendarInputProps = {},
    calendarProps,
    tooltipProps,
    onBlur,
    onChange,
    onInputChange,
    onCalendarChange,
    dataTestId,
    id,
    ...controllerProps
  } = props

  const {
    field: { onChange: onFormChange, onBlur: onFormBlur, value: selectedDate, ref },
    fieldState: { error },
  } = useController({
    ...controllerProps,
    rules: {
      ...controllerProps.rules,
      validate: {
        validDate: (dateValue) => {
          if (isDateValidForDayjs(dateValue)) {
            const format = calendarProps?.dateFormat || objOfDateFormats.defaultFormat
            const errorMessage = getErrorMessageByDate(
              dateValue,
              format,
              calendarProps?.typeOfPicker,
            )

            return errorMessage
          }
        },
        ...controllerProps.rules?.validate,
      },
    },
  })

  const mergedOnChange = (
    event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement> | null,
    payload: { date: Date; value: string },
  ) => {
    onChange?.(event, payload)

    onFormChange(payload.value)
  }

  const mergedCalendarOnChange = (value) => {
    if (onCalendarChange) {
      onCalendarChange(value)
    }

    onFormChange(dayjs(value).format(objOfDateFormats.defaultFormat))
  }

  const mergedInputOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    payload: { date: Date; value: string },
  ) => {
    onInputChange?.(event, payload)

    onFormChange(payload.value)
  }

  const mergedCalendarOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur?.(event)

    onFormBlur()
  }

  return (
    <CalendarInput
      {...calendarInputProps}
      value={selectedDate}
      id={id}
      error={error}
      ref={ref}
      tooltipProps={tooltipProps}
      calendarProps={calendarProps}
      onChange={mergedOnChange}
      onBlurForm={onFormBlur}
      onBlur={mergedCalendarOnBlur}
      onInputChange={mergedInputOnChange}
      onCalendarChange={mergedCalendarOnChange}
    />
  )
}

ControlledCalendarInput.displayName = 'ControlledCalendarInput'
