import { useCallback } from 'react'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForProjectSZPK } from '@components/Forms/ProjectSZPKForm/adapters/Menu.adapter'
import { generateOverridePropsForProjectSZPK } from '@components/Forms/ProjectSZPKForm/adapters/OverrideProps.adapter'
import { generatePrefetchedPropsForProjectSZPK } from '@components/Forms/ProjectSZPKForm/adapters/PrefetchedProps.adapter'
import { generatePropertiesObjectForProjectSZPKForm } from '@components/Forms/ProjectSZPKForm/adapters/PropertiesProps.adapter'
import { generateRHFForProjectSZPK } from '@components/Forms/ProjectSZPKForm/adapters/RHF.adapter'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { IOverridePropsFromClass } from '@services/Properties/Properties.entity'

const useProjectSZPKAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): ProjectSZPKFormValues =>
      generateRHFForProjectSZPK(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForProjectSZPKForm(objectFromPropertyState),
    [],
  )

  const generateOverrideProps = useCallback(
    (objectFromPropertyState: Record<string, any>): IOverridePropsFromClass[] =>
      generateOverridePropsForProjectSZPK(objectFromPropertyState),
    [],
  )

  const generateMenuListByFormValues = useCallback(
    (
      formValues: ProjectSZPKFormValues | UnpackNestedValue<ProjectSZPKFormValues>,
      initialHashMap: NestedMenuHashMap,
      formErrors: FieldErrors<ProjectSZPKFormValues>,
    ): NestedMapOfMenu[] => generateMenuListForProjectSZPK(formValues, initialHashMap, formErrors),
    [],
  )

  const generatePrefetchedPropsArray = useCallback(
    (objectFromPropertyState: Record<string, any>): string[] =>
      generatePrefetchedPropsForProjectSZPK(objectFromPropertyState),
    [],
  )

  return {
    generateRHFObject,
    generatePropertiesObject,
    generateOverrideProps,
    generateMenuListByFormValues,
    generatePrefetchedPropsArray,
  }
}

export { useProjectSZPKAdapters }
