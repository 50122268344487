import { DetailedHTMLProps, FC, HTMLAttributes, memo } from 'react'

import cn from 'classnames'

import styles from './Border.module.scss'

interface BorderProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  disableMarginLeft?: boolean
  disableMarginRight?: boolean
  fixWidth?: boolean
}

const Border: FC<BorderProps> = ({
  disableMarginLeft = false,
  disableMarginRight = false,
  fixWidth = false,
  className,
}) => {
  return (
    <span
      className={cn(
        styles.border,
        {
          [styles['border--disableMarginLeft']]: disableMarginLeft,
          [styles['border--disableMarginRight']]: disableMarginRight,
          [styles['border--fixWidth']]: fixWidth,
        },
        className,
      )}
    />
  )
}

export default memo(Border)
