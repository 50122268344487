import { FC, memo } from 'react'

import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { useItemOIVAuthorizedOrganizationsSidebar } from '@components/Sidebars/AuthorizedOrganizations/OIV'
import { IAuthorizedOrganizationItem } from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { Transition } from '@headlessui/react'
import { useHover } from '@hooks/new/events/useHover'
import IconOpen from '@icons/action/open_in_new.svg'
import cn from 'classnames'

import styles from './OIVItem.module.scss'

interface OIVItemProps {
  authorizedOrganization: IAuthorizedOrganizationItem
  onReloadGrid?: VoidFunction
  className?: string
}

const OIVItem: FC<OIVItemProps> = ({ authorizedOrganization, className, onReloadGrid }) => {
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>()

  const { handleOpenItemOIVAuthorizedOrganizationsSidebar } =
    useItemOIVAuthorizedOrganizationsSidebar()

  const handleOpenChangeAuthorizedOrganizationsSidebar = () => {
    handleOpenItemOIVAuthorizedOrganizationsSidebar({
      organization: authorizedOrganization,
      onReloadGrid,
    })
  }

  const hrefToSite =
    authorizedOrganization.link && /^http(s)?/.test(authorizedOrganization.link)
      ? authorizedOrganization.link
      : `//${authorizedOrganization.link}`

  return (
    <button
      type="button"
      className={cn(styles.Container, className)}
      ref={hoverRef}
      onClick={handleOpenChangeAuthorizedOrganizationsSidebar}
    >
      <img
        className={styles.ContainerIcon}
        src={`data:image/png;base64, ${authorizedOrganization.image}`}
        alt="Иконка"
      />
      <Typography variant={'captionAllcaps'} className={styles.ContainerRegion}>
        {authorizedOrganization.region}
      </Typography>
      <div className={styles.ContainerInfo}>
        <Typography variant={'bodyMRegular'}>{authorizedOrganization.regionName}</Typography>
        <Typography variant={'bodyMRegular'} color={'text-base-secondary'}>
          {authorizedOrganization.inn} · {authorizedOrganization.organizationName}
        </Typography>
      </div>
      {authorizedOrganization.link && (
        <div className={styles.ContainerLink}>
          <Transition
            show={isHovered}
            enter={styles.transition__enter}
            enterFrom={styles.transition__enterFrom}
            enterTo={styles.transition__enterTo}
            leave={styles.transition__leave}
            leaveFrom={styles.transition__leaveFrom}
            leaveTo={styles.transition__leaveTo}
          >
            <div className={styles.ContainerLink__wrapper}>
              <Button
                geometry={'square'}
                size={'s'}
                view={'outline'}
                trailingIcon={{ src: IconOpen, className: styles.buttonLink__icon }}
                variant={'buttonSMedium'}
                href={hrefToSite}
                target={'_blank'}
              >
                Перейти на сайт
              </Button>
            </div>
          </Transition>
        </div>
      )}
    </button>
  )
}

export default memo(OIVItem)
