import {
  ICompensationStatementExpenseSubTypeNotExist,
  ParametersOfCostRecoveryApplicationFormValues,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  GetReferenceBooksProps,
  GetReferenceBooksReturnType,
  ReferenceBooksDictionaryNameKeys,
} from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { isArray, isNullOrUndefined } from '@helpers/checkTypes'

export const getRHFValuesWithPreparedExpenseSubTypesNotExist = async (
  objectFromPropertyState: Record<string, any>,
  RHFValueForReset: Promise<ParametersOfCostRecoveryApplicationFormValues>,
  getReferenceBooks: ({
    dictionaryName,
    searchString,
    id,
    signal,
  }: GetReferenceBooksProps) => Promise<GetReferenceBooksReturnType>,
): Promise<ParametersOfCostRecoveryApplicationFormValues> => {
  const RHFAwaitedValues = await RHFValueForReset
  const compensationStatementExpenseGroupSystemName =
    RHFAwaitedValues['4'].compensationStatementExpenseGroupSystemName
  const compensationStatementExpenseGroupName =
    RHFAwaitedValues['4'].compensationStatementExpenseGroupName

  const catalogExpenseSubtypes = await getReferenceBooks({
    dictionaryName: 'compensation_expense_subtype' as ReferenceBooksDictionaryNameKeys,
    searchString: compensationStatementExpenseGroupSystemName,
  })

  const preparedExpenseSubTypesNotExist: ICompensationStatementExpenseSubTypeNotExist[] = (() => {
    if (
      !isArray(catalogExpenseSubtypes) ||
      !catalogExpenseSubtypes?.length ||
      isNullOrUndefined(compensationStatementExpenseGroupName)
    )
      return []

    let expenseSubTypesNotExist = catalogExpenseSubtypes
    if (
      isArray(objectFromPropertyState.compensationStatementExpenseSubTypes?.value) &&
      objectFromPropertyState.compensationStatementExpenseSubTypes?.value?.length
    ) {
      expenseSubTypesNotExist = catalogExpenseSubtypes?.filter((catalogExpenseSubtype) => {
        return !objectFromPropertyState.compensationStatementExpenseSubTypes?.value.some(
          (expenseSubTypes) => {
            return (
              expenseSubTypes?.value?.compensationStatementExpenseSubTypeValue?.value?.id ===
              catalogExpenseSubtype.id
            )
          },
        )
      })
    }

    return expenseSubTypesNotExist.map(
      (expenseSubTypeNotExist): ICompensationStatementExpenseSubTypeNotExist => {
        return {
          id: expenseSubTypeNotExist.id,
          compensationStatementExpenseSubTypeName: expenseSubTypeNotExist.name,
        }
      },
    )
  })()

  return {
    ...RHFAwaitedValues,
    '4': {
      ...RHFAwaitedValues['4'],
      compensationStatementExpenseSubTypesNotExist: preparedExpenseSubTypesNotExist,
    },
  }
}
