import { generatePrefetchedPropsForBeneficiary } from '@components/Forms/BeneficiaryInformationForm/adapters/PrefetchedProps.adapter'
import { generatePropertiesObjectForBeneficiaryForm } from '@components/Forms/BeneficiaryInformationForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForBeneficiaryForm } from '@components/Forms/BeneficiaryInformationForm/adapters/RHF.adapter'
import { BeneficiaryInformationFormValues } from '@components/Forms/BeneficiaryInformationForm/types'

const useBeneficiaryInformationAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): BeneficiaryInformationFormValues =>
    generateRHFObjectForBeneficiaryForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> => generatePropertiesObjectForBeneficiaryForm(objectFromPropertyState)

  const generatePrefetchedPropsArray = (objectFromPropertyState: Record<string, any>): string[] =>
    generatePrefetchedPropsForBeneficiary(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject, generatePrefetchedPropsArray }
}

export { useBeneficiaryInformationAdapters }
