import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import PetitionRecognizePreviouslyConcludedContractAsBoundLayout from './Layout'
import PetitionRecognizePreviouslyConcludedContractAsBoundModalManager from './Manager'
import PetitionRecognizePreviouslyConcludedContractAsBoundMenu from './Menu'
import PetitionRecognizePreviouslyConcludedContractAsBoundStatementForm from './Statement'

interface ICreatePetitionRecognizePreviouslyConcludedContractAsBound extends FC {
  Layout: typeof PetitionRecognizePreviouslyConcludedContractAsBoundLayout
  Menu: typeof PetitionRecognizePreviouslyConcludedContractAsBoundMenu
  StatementForm: typeof PetitionRecognizePreviouslyConcludedContractAsBoundStatementForm
  Manager: typeof PetitionRecognizePreviouslyConcludedContractAsBoundModalManager
}

const usePetitionRecognizePreviouslyConcludedContractAsBoundControl = () => {
  const popupManager = usePopupManager()

  const openPetitionRecognizePreviouslyConcludedContractAsBoundModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        PetitionRecognizePreviouslyConcludedContractAsBoundLayout,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openPetitionRecognizePreviouslyConcludedContractAsBoundModal }
}

const PetitionRecognizePreviouslyConcludedContractAsBound: ICreatePetitionRecognizePreviouslyConcludedContractAsBound =
  () => null

PetitionRecognizePreviouslyConcludedContractAsBound.Menu =
  PetitionRecognizePreviouslyConcludedContractAsBoundMenu
PetitionRecognizePreviouslyConcludedContractAsBound.Layout =
  PetitionRecognizePreviouslyConcludedContractAsBoundLayout
PetitionRecognizePreviouslyConcludedContractAsBound.StatementForm =
  PetitionRecognizePreviouslyConcludedContractAsBoundStatementForm
PetitionRecognizePreviouslyConcludedContractAsBound.Manager =
  PetitionRecognizePreviouslyConcludedContractAsBoundModalManager

export { usePetitionRecognizePreviouslyConcludedContractAsBoundControl }
export default PetitionRecognizePreviouslyConcludedContractAsBound
