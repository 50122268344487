import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import type { GetVersionData } from '@context/APIContext/types'
import type { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useVersions = ({ key, config }: SWRHookProps<GetVersionData[] | undefined>) => {
  const {
    dfosApi: { getVersions },
  } = useAPIContext()

  const {
    data: versions,
    error,
    mutate,
    isValidating: isLoadingVersions,
  } = useSWRImmutable(key, getVersions, { ...config, shouldRetryOnError: false })

  const foundActualVersion = useMemo(
    () => (versions?.length ? versions?.find(({ isActual }) => isActual) : undefined),
    [versions],
  )

  return {
    versions,
    versionName: foundActualVersion?.version,
    versionNumber: foundActualVersion?.versionNumber,
    actualVersionId: foundActualVersion?.id ?? '',
    actualVersionHasReadPermission: foundActualVersion?.dfoReadPermission ?? false,
    error,
    mutate,
    isLoadingVersions,
  }
}

export { useVersions }
