import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  AAChangingVolumeReimbursementFormValues,
  IStepCompensation,
} from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForAAChangingVolumeReimbursementForm = (
  objectFromPropertyState: Record<string, any>,
): AAChangingVolumeReimbursementFormValues => {
  const preparedSteps = (() => {
    if (
      !isArray(objectFromPropertyState.steps?.value) ||
      !objectFromPropertyState.steps.value.length
    )
      return []

    return objectFromPropertyState.steps?.value.map((step) => {
      return {
        id: step.propertyId,
        stepNumber: step.value.stepNumber?.value,
        stepDescription: step.value.stepDescription?.value,
        stepCompensationValueCommited: step.value.stepCompensationValue?.commitedValue,
        stepCompensationValue: step.value.stepCompensationValue?.value,
        stepCompensationFormatCommited: step.value.stepCompensationForm?.commitedValue?.name || '',
        stepCompensationFormat: !!step.value.stepCompensationForm?.value?.id
          ? {
              value: step.value.stepCompensationForm?.value?.id || '',
              displayValue: step.value.stepCompensationForm?.value?.name || '',
            }
          : null,
        stepCompensations:
          isArray(step.value.stepCompensations?.value) && step.value.stepCompensations?.value.length
            ? step.value.stepCompensations?.value
                .sort((compensationA, compensationB) => compensationA.order - compensationB.order)
                .map(
                  (stepCompensation): IStepCompensation => ({
                    id: stepCompensation.propertyId,
                    isNew: stepCompensation.new ?? false,
                    stepCompensationTypeCommited:
                      stepCompensation.value.stepCompensationType?.commitedValue?.name || '',
                    stepCompensationPeriod:
                      stepCompensation.value.stepCompensationPeriod?.value?.name || '',
                    stepCompensationType: !!stepCompensation.value.stepCompensationType?.value?.id
                      ? {
                          displayValue:
                            stepCompensation.value?.stepCompensationType?.value?.name || '',
                          value: stepCompensation.value?.stepCompensationType?.value?.id || '',
                        }
                      : null,
                  }),
                )
            : [],
      }
    })
  })()

  const preparedOldAgreementLinkChanges = (() => {
    if (
      !isArray(objectFromPropertyState.oldAgreementLinkChanges?.value) ||
      !objectFromPropertyState.oldAgreementLinkChanges.value.length
    )
      return []

    return objectFromPropertyState.oldAgreementLinkChanges?.value.map((linkChange) => ({
      id: linkChange.propertyId,
      oldAgreementLink: linkChange.value.oldAgreementLink.value,
      oldAgreementLinkNewRedaction: linkChange.value.oldAgreementLinkNewRedaction.value,
    }))
  })()

  return {
    '1': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '5': {
      isSMBusinessesInvolved: objectFromPropertyState?.isSMBusinessesInvolved?.value,
    },
    '6': {
      steps: preparedSteps,
    },
    '7': {
      oldAgreementLinkChanges: preparedOldAgreementLinkChanges,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
      isPaper: objectFromPropertyState.isPaper?.value,
    },
  }
}

export { generateRHFObjectForAAChangingVolumeReimbursementForm }
