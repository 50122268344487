import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { BeneficiaryInformationFormValues } from '@components/Forms/BeneficiaryInformationForm/types'
import { defaultRHFValidation } from '@constants/validations'

const oneSectionValidationMap: FormValuesValidationSection<
  keyof BeneficiaryInformationFormValues['1'],
  BeneficiaryInformationFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { oneSectionValidationMap }
