import React, { FC, memo, useCallback } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import DataOnFulfillmentOfTermsSZPK from '@components/Forms/DataOnFulfillmentOfTermsSZPK'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import { dataOnFulfillmentOfTermsSZPKMenuState } from '../Menu/const'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '../watcher'

import DataOnFulfillmentOfTermsSZPKLayoutWrapper from './Wrapper'

const DataOnFulfillmentOfTermsSZPKLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const handleOnClose = useCallback(() => {
    DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <DataOnFulfillmentOfTermsSZPK.MenuManager
        initialMenuHash={dataOnFulfillmentOfTermsSZPKMenuState}
        initialSectionId={dataOnFulfillmentOfTermsSZPKMenuState[0].id}
      >
        <DataOnFulfillmentOfTermsSZPK.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
          onClose={handleOnClose}
        >
          <DataOnFulfillmentOfTermsSZPKLayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Отчет о результатах мониторинга
              </Typography.Headline>
              <DataOnFulfillmentOfTermsSZPK.StatementForm className={styles.layout__form} />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}>
              <DataOnFulfillmentOfTermsSZPK.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </DataOnFulfillmentOfTermsSZPKLayoutWrapper>
        </DataOnFulfillmentOfTermsSZPK.Manager>
      </DataOnFulfillmentOfTermsSZPK.MenuManager>
    </DocumentFormLayout>
  )
}

export default memo(DataOnFulfillmentOfTermsSZPKLayout)
