import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const { getPropertyPropsFromPropertyState } = PropertiesPropsBasicAdapters

const generatePropertiesObjectForCertificateOnMonitoringResultsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedStepsMonitoringChecker = (() => {
    if (
      !isArray(objectFromPropertyState.stepsMonitoringChecker?.value) ||
      !objectFromPropertyState.stepsMonitoringChecker?.value?.length
    )
      return []

    return objectFromPropertyState.stepsMonitoringChecker.value.map((stepMonitoringChecker) => ({
      propertyId: stepMonitoringChecker.propertyId,
      lastUpdateDt: stepMonitoringChecker.lastUpdateDt,
      type: stepMonitoringChecker.type,
      value: {
        factStepTotalCapitalValueChecker: {
          propertyId: stepMonitoringChecker.value.factStepTotalCapitalValueChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepTotalCapitalValueChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.factStepTotalCapitalValueChecker?.lastUpdateDt,
        },
        factStepTotalCapitalVatValueChecker: {
          propertyId: stepMonitoringChecker.value.factStepTotalCapitalVatValueChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepTotalCapitalVatValueChecker?.type,
          lastUpdateDt:
            stepMonitoringChecker.value.factStepTotalCapitalVatValueChecker?.lastUpdateDt,
        },
        diffStepTotalCapitalChecker: {
          propertyId: stepMonitoringChecker.value.diffStepTotalCapitalChecker?.propertyId,
          type: stepMonitoringChecker.value.diffStepTotalCapitalChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.diffStepTotalCapitalChecker?.lastUpdateDt,
        },
        factStepInvestorCapitalValueChecker: {
          propertyId: stepMonitoringChecker.value.factStepInvestorCapitalValueChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepInvestorCapitalValueChecker?.type,
          lastUpdateDt:
            stepMonitoringChecker.value.factStepInvestorCapitalValueChecker?.lastUpdateDt,
        },
        factStepInvestorCapitalVatValueChecker: {
          propertyId:
            stepMonitoringChecker.value.factStepInvestorCapitalVatValueChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepInvestorCapitalVatValueChecker?.type,
          lastUpdateDt:
            stepMonitoringChecker.value.factStepInvestorCapitalVatValueChecker?.lastUpdateDt,
        },
        diffStepInvestorCapitalValueChecker: {
          propertyId: stepMonitoringChecker.value.diffStepInvestorCapitalValueChecker?.propertyId,
          type: stepMonitoringChecker.value.diffStepInvestorCapitalValueChecker?.type,
          lastUpdateDt:
            stepMonitoringChecker.value.diffStepInvestorCapitalValueChecker?.lastUpdateDt,
        },
        factStepCompensationValueChecker: {
          propertyId: stepMonitoringChecker.value.factStepCompensationValueChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepCompensationValueChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.factStepCompensationValueChecker?.lastUpdateDt,
        },
        factStepCompensationVatValueChecker: {
          propertyId: stepMonitoringChecker.value.factStepCompensationVatValueChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepCompensationVatValueChecker?.type,
          lastUpdateDt:
            stepMonitoringChecker.value.factStepCompensationVatValueChecker?.lastUpdateDt,
        },
        diffStepCompensationValueChecker: {
          propertyId: stepMonitoringChecker.value.diffStepCompensationValueChecker?.propertyId,
          type: stepMonitoringChecker.value.diffStepCompensationValueChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.diffStepCompensationValueChecker?.lastUpdateDt,
        },
        factStepCompensationPeriodChecker: {
          propertyId: stepMonitoringChecker.value.factStepCompensationPeriodChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepCompensationPeriodChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.factStepCompensationPeriodChecker?.lastUpdateDt,
        },
        factStepCompensationPeriodEndChecker: {
          propertyId: stepMonitoringChecker.value.factStepCompensationPeriodEndChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepCompensationPeriodEndChecker?.type,
          lastUpdateDt:
            stepMonitoringChecker.value.factStepCompensationPeriodEndChecker?.lastUpdateDt,
        },
        factStepWorkPlaceChecker: {
          propertyId: stepMonitoringChecker.value.factStepWorkPlaceChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepWorkPlaceChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.factStepWorkPlaceChecker?.lastUpdateDt,
        },
        diffStepWorkPlaceChecker: {
          propertyId: stepMonitoringChecker.value.diffStepWorkPlaceChecker?.propertyId,
          type: stepMonitoringChecker.value.diffStepWorkPlaceChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.diffStepWorkPlaceChecker?.lastUpdateDt,
        },
        factStepStartDateChecker: {
          propertyId: stepMonitoringChecker.value.factStepStartDateChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepStartDateChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.factStepStartDateChecker?.lastUpdateDt,
        },
        factStepEndDateChecker: {
          propertyId: stepMonitoringChecker.value.factStepEndDateChecker?.propertyId,
          type: stepMonitoringChecker.value.factStepEndDateChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.factStepEndDateChecker?.lastUpdateDt,
        },
        diffStepDateChecker: {
          propertyId: stepMonitoringChecker.value.diffStepDateChecker?.propertyId,
          type: stepMonitoringChecker.value.diffStepDateChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.diffStepDateChecker?.lastUpdateDt,
        },
        stepInfoChecker: {
          propertyId: stepMonitoringChecker.value.stepInfoChecker?.propertyId,
          type: stepMonitoringChecker.value.stepInfoChecker?.type,
          lastUpdateDt: stepMonitoringChecker.value.stepInfoChecker?.lastUpdateDt,
        },
      },
    }))
  })()

  const preparedPercentCreditsChecker = (() => {
    if (
      !isArray(objectFromPropertyState.percentCreditsChecker?.value) ||
      !objectFromPropertyState.percentCreditsChecker?.value?.length
    )
      return []

    return objectFromPropertyState.percentCreditsChecker.value.map((percentCredit) => {
      const preparedPercentCreditMembersChecker = (() => {
        if (
          !isArray(percentCredit.value?.percentCreditMembersChecker?.value) ||
          !percentCredit.value?.percentCreditMembersChecker?.value?.length
        )
          return []

        return percentCredit.value?.percentCreditMembersChecker?.value.map(
          (percentCreditMember) => ({
            propertyId: percentCreditMember.propertyId,
            type: percentCreditMember.type,
            lastUpdateDt: percentCreditMember.lastUpdateDt,
            value: {
              percentCreditMemberChecker: {
                propertyId: percentCreditMember?.propertyId,
                type: percentCreditMember?.type,
                lastUpdateDt: percentCreditMember?.lastUpdateDt,
              },
            },
          }),
        )
      })()

      return {
        propertyId: percentCredit.propertyId,
        type: percentCredit.type,
        lastUpdateDt: percentCredit.lastUpdateDt,
        value: {
          percentCreditNameChecker: {
            propertyId: percentCredit.value.percentCreditNameChecker?.propertyId,
            type: percentCredit.value.percentCreditNameChecker?.type,
            lastUpdateDt: percentCredit.value.percentCreditNameChecker?.lastUpdateDt,
          },
          percentCreditDateChecker: {
            propertyId: percentCredit.value.percentCreditDateChecker?.propertyId,
            type: percentCredit.value.percentCreditDateChecker?.type,
            lastUpdateDt: percentCredit.value.percentCreditDateChecker?.lastUpdateDt,
          },
          percentCreditNumberChecker: {
            propertyId: percentCredit.value.percentCreditNumberChecker?.propertyId,
            type: percentCredit.value.percentCreditNumberChecker?.type,
            lastUpdateDt: percentCredit.value.percentCreditNumberChecker?.lastUpdateDt,
          },
          percentCreditMembersChecker: {
            propertyId: percentCredit.value.percentCreditMembersChecker?.propertyId,
            type: percentCredit.value.percentCreditMembersChecker?.type,
            lastUpdateDt: percentCredit.value.percentCreditMembersChecker?.lastUpdateDt,
            value: preparedPercentCreditMembersChecker,
          },
        },
      }
    })
  })()

  const preparedDismantlingObjectsChecker = (() => {
    if (
      !isArray(objectFromPropertyState.dismantlingObjectsChecker?.value) ||
      !objectFromPropertyState.dismantlingObjectsChecker?.value?.length
    )
      return []

    return objectFromPropertyState.dismantlingObjectsChecker?.value.map((dismantlingObject) => ({
      propertyId: dismantlingObject.propertyId,
      type: dismantlingObject.type,
      lastUpdateDt: dismantlingObject.lastUpdateDt,
      value: {
        dismantlingObjectNameChecker: {
          propertyId: dismantlingObject.value.dismantlingObjectNameChecker?.propertyId,
          type: dismantlingObject.value.dismantlingObjectNameChecker?.type,
          lastUpdateDt: dismantlingObject.value.dismantlingObjectNameChecker?.lastUpdateDt,
        },
      },
    }))
  })()

  const preparedExpensesChecker = (() => {
    if (
      !isArray(objectFromPropertyState.expensesChecker?.value) ||
      !objectFromPropertyState.expensesChecker?.value?.length
    )
      return []

    return objectFromPropertyState.expensesChecker?.value.map((expense) => ({
      propertyId: expense?.propertyId,
      type: expense?.type,
      lastUpdateDt: expense?.lastUpdateDt,
      value: {
        expenseNameChecker: {
          propertyId: expense?.value.expenseNameChecker?.propertyId,
          type: expense?.value.expenseNameChecker?.type,
          lastUpdateDt: expense?.value.expenseNameChecker?.lastUpdateDt,
        },
      },
    }))
  })()

  const preparedCompensationsMonitoringChecker = (() => {
    if (
      !isArray(objectFromPropertyState.compensationsMonitoringChecker?.value) ||
      !objectFromPropertyState.compensationsMonitoringChecker?.value?.length
    )
      return []

    return objectFromPropertyState.compensationsMonitoringChecker.value.map((compensation) => {
      const preparedCompensationsRelatedInfrastructureChecker = (() => {
        if (
          !isArray(compensation?.value?.compensationsRelatedInfrastructureChecker?.value) ||
          !compensation?.value?.compensationsRelatedInfrastructureChecker?.value?.length
        )
          return []

        return compensation.value?.compensationsRelatedInfrastructureChecker?.value.map(
          (compensationRelated) => ({
            propertyId: compensationRelated.propertyId,
            type: compensationRelated.type,
            lastUpdateDt: compensationRelated.lastUpdateDt,
            value: {
              objectName: {
                propertyId:
                  compensationRelated.value.compensationRelatedInfrastructureInvestor?.propertyId,
                type: compensationRelated.value.compensationRelatedInfrastructureInvestor?.type,
                lastUpdateDt:
                  compensationRelated.value.compensationRelatedInfrastructureInvestor?.lastUpdateDt,
              },
              relatedInfrastructureObjectValueChecker: {
                propertyId:
                  compensationRelated.value.relatedInfrastructureObjectValueChecker?.propertyId,
                type: compensationRelated.value.relatedInfrastructureObjectValueChecker?.type,
                lastUpdateDt:
                  compensationRelated.value.relatedInfrastructureObjectValueChecker?.lastUpdateDt,
              },
            },
          }),
        )
      })()

      const preparedCompensationsSupportingInfrastructureChecker = (() => {
        if (
          !isArray(compensation?.value?.compensationsSupportingInfrastructureChecker?.value) ||
          !compensation?.value?.compensationsSupportingInfrastructureChecker?.value?.length
        )
          return []

        return compensation.value?.compensationsSupportingInfrastructureChecker?.value.map(
          (compensationSupporting) => ({
            propertyId: compensationSupporting.propertyId,
            type: compensationSupporting.type,
            lastUpdateDt: compensationSupporting.lastUpdateDt,
            value: {
              objectName: {
                propertyId:
                  compensationSupporting.value.compensationSupportingInfrastructureInvestor
                    ?.propertyId,
                type: compensationSupporting.value.compensationSupportingInfrastructureInvestor
                  ?.type,
                lastUpdateDt:
                  compensationSupporting.value.compensationSupportingInfrastructureInvestor
                    ?.lastUpdateDt,
              },
              supportingInfrastructureObjectValueChecker: {
                propertyId:
                  compensationSupporting.value.supportingInfrastructureObjectValueChecker
                    ?.propertyId,
                type: compensationSupporting.value.supportingInfrastructureObjectValueChecker?.type,
                lastUpdateDt:
                  compensationSupporting.value.supportingInfrastructureObjectValueChecker
                    ?.lastUpdateDt,
              },
            },
          }),
        )
      })()

      const preparedCompensationsPercentCreditChecker = (() => {
        if (
          !isArray(compensation?.value?.compensationsPercentCreditChecker?.value) ||
          !compensation?.value?.compensationsPercentCreditChecker?.value?.length
        )
          return []

        return compensation.value?.compensationsPercentCreditChecker?.value.map(
          (compensationPercent) => ({
            propertyId: compensationPercent.propertyId,
            type: compensationPercent.type,
            lastUpdateDt: compensationPercent.lastUpdateDt,
            value: {
              compensationRelatedContractValueChecker: {
                propertyId:
                  compensationPercent.value.compensationRelatedContractValueChecker?.propertyId,
                type: compensationPercent.value.compensationRelatedContractValueChecker?.type,
                lastUpdateDt:
                  compensationPercent.value.compensationRelatedContractValueChecker?.lastUpdateDt,
              },
            },
          }),
        )
      })()

      const preparedCompensationsDismantlingChecker = (() => {
        if (
          !isArray(compensation?.value?.compensationsDismantlingChecker?.value) ||
          !compensation?.value?.compensationsDismantlingChecker?.value?.length
        )
          return []

        return compensation.value?.compensationsDismantlingChecker?.value.map(
          (compensationDismantling) => ({
            propertyId: compensationDismantling.propertyId,
            type: compensationDismantling.type,
            lastUpdateDt: compensationDismantling.lastUpdateDt,
            value: {
              dismantlingObjectValueChecker: {
                propertyId: compensationDismantling.value.dismantlingObjectValueChecker?.propertyId,
                type: compensationDismantling.value.dismantlingObjectValueChecker?.type,
                lastUpdateDt:
                  compensationDismantling.value.dismantlingObjectValueChecker?.lastUpdateDt,
              },
            },
          }),
        )
      })()

      const preparedCompensationsExpensesChecker = (() => {
        if (
          !isArray(compensation?.value?.compensationsExpensesChecker?.value) ||
          !compensation?.value?.compensationsExpensesChecker?.value?.length
        )
          return []

        return compensation.value?.compensationsExpensesChecker?.value.map(
          (compensationExpense) => ({
            propertyId: compensationExpense.propertyId,
            type: compensationExpense.type,
            lastUpdateDt: compensationExpense.lastUpdateDt,
            value: {
              expenseValueChecker: {
                propertyId: compensationExpense.value.expenseValueChecker?.propertyId,
                type: compensationExpense.value.expenseValueChecker?.type,
                lastUpdateDt: compensationExpense.value.expenseValueChecker?.lastUpdateDt,
              },
            },
          }),
        )
      })()

      return {
        propertyId: compensation.propertyId,
        type: compensation.type,
        lastUpdateDt: compensation.lastUpdateDt,
        value: {
          compensationsRelatedInfrastructureChecker: {
            propertyId: compensation.value.compensationsRelatedInfrastructureChecker?.propertyId,
            type: compensation.value.compensationsRelatedInfrastructureChecker?.type,
            lastUpdateDt:
              compensation.value.compensationsRelatedInfrastructureChecker?.lastUpdateDt,
            value: preparedCompensationsRelatedInfrastructureChecker,
          },

          compensationsSupportingInfrastructureChecker: {
            propertyId: compensation.value.compensationsSupportingInfrastructureChecker?.propertyId,
            type: compensation.value.compensationsSupportingInfrastructureChecker?.type,
            lastUpdateDt:
              compensation.value.compensationsSupportingInfrastructureChecker?.lastUpdateDt,
            value: preparedCompensationsSupportingInfrastructureChecker,
          },

          compensationsPercentCreditChecker: {
            propertyId: compensation.value.compensationsPercentCreditChecker?.propertyId,
            type: compensation.value.compensationsPercentCreditChecker?.type,
            lastUpdateDt: compensation.value.compensationsPercentCreditChecker?.lastUpdateDt,
            value: preparedCompensationsPercentCreditChecker,
          },

          compensationsDismantlingChecker: {
            propertyId: compensation.value.compensationsDismantlingChecker?.propertyId,
            type: compensation.value.compensationsDismantlingChecker?.type,
            lastUpdateDt: compensation.value.compensationsDismantlingChecker?.lastUpdateDt,
            value: preparedCompensationsDismantlingChecker,
          },

          compensationsExpensesChecker: {
            propertyId: compensation.value.compensationsExpensesChecker?.propertyId,
            type: compensation.value.compensationsExpensesChecker?.type,
            lastUpdateDt: compensation.value.compensationsExpensesChecker?.lastUpdateDt,
            value: preparedCompensationsExpensesChecker,
          },

          incomeTaxFederalChecker: {
            propertyId: compensation.value.incomeTaxFederalChecker?.propertyId,
            type: compensation.value.incomeTaxFederalChecker?.type,
            lastUpdateDt: compensation.value.incomeTaxFederalChecker?.lastUpdateDt,
          },
          incomeTaxRegionalChecker: {
            propertyId: compensation.value.incomeTaxRegionalChecker?.propertyId,
            type: compensation.value.incomeTaxRegionalChecker?.type,
            lastUpdateDt: compensation.value.incomeTaxRegionalChecker?.lastUpdateDt,
          },
          propertyTaxChecker: {
            propertyId: compensation.value.propertyTaxChecker?.propertyId,
            type: compensation.value.propertyTaxChecker?.type,
            lastUpdateDt: compensation.value.propertyTaxChecker?.lastUpdateDt,
          },
          landTaxChecker: {
            propertyId: compensation.value.landTaxChecker?.propertyId,
            type: compensation.value.landTaxChecker?.type,
            lastUpdateDt: compensation.value.landTaxChecker?.lastUpdateDt,
          },
          vatTaxChecker: {
            propertyId: compensation.value.vatTaxChecker?.propertyId,
            type: compensation.value.vatTaxChecker?.type,
            lastUpdateDt: compensation.value.vatTaxChecker?.lastUpdateDt,
          },
          customDutyChecker: {
            propertyId: compensation.value.customDutyChecker?.propertyId,
            type: compensation.value.customDutyChecker?.type,
            lastUpdateDt: compensation.value.customDutyChecker?.lastUpdateDt,
          },
          compensationSubsidyFormatChecker: {
            propertyId: compensation.value.compensationSubsidyFormatChecker?.propertyId,
            type: compensation.value.compensationSubsidyFormatChecker?.type,
            lastUpdateDt: compensation.value.compensationSubsidyFormatChecker?.lastUpdateDt,
          },
          compensationTaxDeductionFormatChecker: {
            propertyId: compensation.value.compensationTaxDeductionFormatChecker?.propertyId,
            type: compensation.value.compensationTaxDeductionFormatChecker?.type,
            lastUpdateDt: compensation.value.compensationTaxDeductionFormatChecker?.lastUpdateDt,
          },
          compensationDamageFormatChecker: {
            propertyId: compensation.value.compensationDamageFormatChecker?.propertyId,
            type: compensation.value.compensationDamageFormatChecker?.type,
            lastUpdateDt: compensation.value.compensationDamageFormatChecker?.lastUpdateDt,
          },
        },
      }
    })
  })()

  const preparedStepMonitoring = (() => {
    if (
      !isArray(objectFromPropertyState.compensationsMonitoringChecker?.value) ||
      !objectFromPropertyState.compensationsMonitoringChecker?.value?.length
    )
      return []

    return objectFromPropertyState.compensationsMonitoringChecker?.value.map((compensation) => ({
      propertyId: compensation.propertyId,
      type: compensation.type,
      lastUpdateDt: compensation.lastUpdateDt,
      value: {
        incomeTaxFederalChecker: {
          propertyId: compensation.value.incomeTaxFederalChecker?.propertyId,
          type: compensation.value.incomeTaxFederalChecker?.type,
          lastUpdateDt: compensation.value.incomeTaxFederalChecker?.lastUpdateDt,
        },
        incomeTaxRegionalChecker: {
          propertyId: compensation.value.incomeTaxRegionalChecker?.propertyId,
          type: compensation.value.incomeTaxRegionalChecker?.type,
          lastUpdateDt: compensation.value.incomeTaxRegionalChecker?.lastUpdateDt,
        },
        propertyTaxChecker: {
          propertyId: compensation.value.propertyTaxChecker?.propertyId,
          type: compensation.value.propertyTaxChecker?.type,
          lastUpdateDt: compensation.value.propertyTaxChecker?.lastUpdateDt,
        },
        landTaxChecker: {
          propertyId: compensation.value.landTaxChecker?.propertyId,
          type: compensation.value.landTaxChecker?.type,
          lastUpdateDt: compensation.value.landTaxChecker?.lastUpdateDt,
        },
        vatTaxChecker: {
          propertyId: compensation.value.vatTaxChecker?.propertyId,
          type: compensation.value.vatTaxChecker?.type,
          lastUpdateDt: compensation.value.vatTaxChecker?.lastUpdateDt,
        },
        customDutyChecker: {
          propertyId: compensation.value.customDutyChecker?.propertyId,
          type: compensation.value.customDutyChecker?.type,
          lastUpdateDt: compensation.value.customDutyChecker?.lastUpdateDt,
        },
      },
    }))
  })()

  const preparedStateSupportsChecker = (() => {
    if (
      !isArray(objectFromPropertyState.stateSupportsChecker?.value) ||
      !objectFromPropertyState.stateSupportsChecker?.value?.length
    )
      return []

    return objectFromPropertyState.stateSupportsChecker?.value.map((stateSupport) => ({
      propertyId: stateSupport?.propertyId,
      type: stateSupport?.type,
      lastUpdateDt: stateSupport?.lastUpdateDt,
      value: {
        stateSupportNameChecker: {
          propertyId: stateSupport.value.stateSupportNameChecker?.propertyId,
          type: stateSupport.value.stateSupportNameChecker?.type,
          lastUpdateDt: stateSupport.value.stateSupportNameChecker?.lastUpdateDt,
        },
        stateSupportReasonChecker: {
          propertyId: stateSupport.value.stateSupportReasonChecker?.propertyId,
          type: stateSupport.value.stateSupportReasonChecker?.type,
          lastUpdateDt: stateSupport.value.stateSupportReasonChecker?.lastUpdateDt,
        },
        stateSupportValueChecker: {
          propertyId: stateSupport.value.stateSupportValueChecker?.propertyId,
          type: stateSupport.value.stateSupportValueChecker?.type,
          lastUpdateDt: stateSupport.value.stateSupportValueChecker?.lastUpdateDt,
        },
      },
    }))
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
      initiatorFullNameInstrumental: {
        propertyId:
          objectFromPropertyState.initiator?.value?.memberFullNameInstrumental?.propertyId,
        type: objectFromPropertyState.initiator?.value?.memberFullNameInstrumental?.type,
        lastUpdateDt:
          objectFromPropertyState.initiator?.value?.memberFullNameInstrumental?.lastUpdateDt,
      },
    },
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: {
        ...getPropertyPropsFromPropertyState(objectFromPropertyState?.investorFullNameGenitive),
      },
    },
    '4': {
      projectResultEffectChecker: {
        propertyId: objectFromPropertyState.projectResultEffectChecker?.propertyId,
        type: objectFromPropertyState.projectResultEffectChecker?.type,
        lastUpdateDt: objectFromPropertyState.projectResultEffectChecker?.lastUpdateDt,
      },
      isVatMoney: {
        propertyId: objectFromPropertyState?.isVatMoney?.propertyId,
        type: objectFromPropertyState?.isVatMoney?.type,
        lastUpdateDt: objectFromPropertyState?.isVatMoney?.lastUpdateDt,
      },
    },
    '5': {
      stepsMonitoringChecker: {
        propertyId: objectFromPropertyState.stepsMonitoringChecker?.propertyId,
        type: objectFromPropertyState.stepsMonitoringChecker?.type,
        lastUpdateDt: objectFromPropertyState.stepsMonitoringChecker?.lastUpdateDt,
        value: preparedStepsMonitoringChecker,
      },
    },
    '6': {
      percentCreditsChecker: {
        propertyId: objectFromPropertyState.percentCreditsChecker?.propertyId,
        type: objectFromPropertyState.percentCreditsChecker?.type,
        lastUpdateDt: objectFromPropertyState.percentCreditsChecker?.lastUpdateDt,
        value: preparedPercentCreditsChecker,
      },
    },
    '7': {
      dismantlingObjectsChecker: {
        propertyId: objectFromPropertyState.dismantlingObjectsChecker?.propertyId,
        type: objectFromPropertyState.dismantlingObjectsChecker?.type,
        lastUpdateDt: objectFromPropertyState.dismantlingObjectsChecker?.lastUpdateDt,
        value: preparedDismantlingObjectsChecker,
      },
    },
    '8': {
      expensesChecker: {
        propertyId: objectFromPropertyState.expensesChecker?.propertyId,
        type: objectFromPropertyState.expensesChecker?.type,
        lastUpdateDt: objectFromPropertyState.expensesChecker?.lastUpdateDt,
        value: preparedExpensesChecker,
      },
    },
    '9': {
      compensationsMonitoringChecker: {
        propertyId: objectFromPropertyState.compensationsMonitoringChecker?.propertyId,
        type: objectFromPropertyState.compensationsMonitoringChecker?.type,
        lastUpdateDt: objectFromPropertyState.compensationsMonitoringChecker?.lastUpdateDt,
        value: preparedCompensationsMonitoringChecker,
      },
    },
    '10': {
      compensationsMonitoring: {
        propertyId: objectFromPropertyState.compensationsMonitoringChecker?.propertyId,
        type: objectFromPropertyState.compensationsMonitoringChecker?.type,
        lastUpdateDt: objectFromPropertyState.compensationsMonitoringChecker?.lastUpdateDt,
        value: preparedStepMonitoring,
      },
    },
    '11': {
      stateSupportsChecker: {
        propertyId: objectFromPropertyState.stateSupportsChecker?.propertyId,
        type: objectFromPropertyState.stateSupportsChecker?.type,
        lastUpdateDt: objectFromPropertyState.stateSupportsChecker?.lastUpdateDt,
        value: preparedStateSupportsChecker,
      },
    },
    '12': {
      isChanges: {
        propertyId: objectFromPropertyState.isChanges?.propertyId,
        type: objectFromPropertyState.isChanges?.type,
        lastUpdateDt: objectFromPropertyState.isChanges?.lastUpdateDt,
      },
      isTerminate: {
        propertyId: objectFromPropertyState.isTerminate?.propertyId,
        type: objectFromPropertyState.isTerminate?.type,
        lastUpdateDt: objectFromPropertyState.isTerminate?.lastUpdateDt,
      },
      relatedContractReasonChecker: {
        propertyId: objectFromPropertyState.relatedContractReasonChecker?.propertyId,
        type: objectFromPropertyState.relatedContractReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.relatedContractReasonChecker?.lastUpdateDt,
      },
      relatedContractReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.relatedContractReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.relatedContractReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.relatedContractReasonDescriptionChecker?.lastUpdateDt,
      },
      extendStabilizationReasonChecker: {
        propertyId: objectFromPropertyState.extendStabilizationReasonChecker?.propertyId,
        type: objectFromPropertyState.extendStabilizationReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.extendStabilizationReasonChecker?.lastUpdateDt,
      },
      extendStabilizationReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.extendStabilizationReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.extendStabilizationReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.extendStabilizationReasonDescriptionChecker?.lastUpdateDt,
      },
      municipalAccessionReasonChecker: {
        propertyId: objectFromPropertyState.municipalAccessionReasonChecker?.propertyId,
        type: objectFromPropertyState.municipalAccessionReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.municipalAccessionReasonChecker?.lastUpdateDt,
      },
      municipalAccessionReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.municipalAccessionReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.municipalAccessionReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.municipalAccessionReasonDescriptionChecker?.lastUpdateDt,
      },
      rightTransferReasonChecker: {
        propertyId: objectFromPropertyState.rightTransferReasonChecker?.propertyId,
        type: objectFromPropertyState.rightTransferReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.rightTransferReasonChecker?.lastUpdateDt,
      },
      rightTransferReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.rightTransferReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.rightTransferReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.rightTransferReasonDescriptionChecker?.lastUpdateDt,
      },
      infoChangesReasonChecker: {
        propertyId: objectFromPropertyState.infoChangesReasonChecker?.propertyId,
        type: objectFromPropertyState.infoChangesReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.infoChangesReasonChecker?.lastUpdateDt,
      },
      infoChangesReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.infoChangesReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.infoChangesReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.infoChangesReasonDescriptionChecker?.lastUpdateDt,
      },
      forceMajeureReasonChecker: {
        propertyId: objectFromPropertyState.forceMajeureReasonChecker?.propertyId,
        type: objectFromPropertyState.forceMajeureReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.forceMajeureReasonChecker?.lastUpdateDt,
      },
      forceMajeureReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.forceMajeureReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.forceMajeureReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.forceMajeureReasonDescriptionChecker?.lastUpdateDt,
      },
      agreementDutyReasonChecker: {
        propertyId: objectFromPropertyState.agreementDutyReasonChecker?.propertyId,
        type: objectFromPropertyState.agreementDutyReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.agreementDutyReasonChecker?.lastUpdateDt,
      },
      agreementDutyReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.agreementDutyReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.agreementDutyReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.agreementDutyReasonDescriptionChecker?.lastUpdateDt,
      },
      concessionFailReasonChecker: {
        propertyId: objectFromPropertyState.concessionFailReasonChecker?.propertyId,
        type: objectFromPropertyState.concessionFailReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.concessionFailReasonChecker?.lastUpdateDt,
      },
      concessionFailReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.concessionFailReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.concessionFailReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.concessionFailReasonDescriptionChecker?.lastUpdateDt,
      },
      constructionChangeReasonChecker: {
        propertyId: objectFromPropertyState.constructionChangeReasonChecker?.propertyId,
        type: objectFromPropertyState.constructionChangeReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.constructionChangeReasonChecker?.lastUpdateDt,
      },
      constructionChangeReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.constructionChangeReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.constructionChangeReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.constructionChangeReasonDescriptionChecker?.lastUpdateDt,
      },
      supportingInfrastructureReasonChecker: {
        propertyId: objectFromPropertyState.supportingInfrastructureReasonChecker?.propertyId,
        type: objectFromPropertyState.supportingInfrastructureReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.supportingInfrastructureReasonChecker?.lastUpdateDt,
      },
      supportingInfrastructureReasonDescriptionChecker: {
        propertyId:
          objectFromPropertyState.supportingInfrastructureReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.supportingInfrastructureReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.supportingInfrastructureReasonDescriptionChecker?.lastUpdateDt,
      },
      investmentChangeReasonChecker: {
        propertyId: objectFromPropertyState.investmentChangeReasonChecker?.propertyId,
        type: objectFromPropertyState.investmentChangeReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.investmentChangeReasonChecker?.lastUpdateDt,
      },
      investmentChangeReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.investmentChangeReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.investmentChangeReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.investmentChangeReasonDescriptionChecker?.lastUpdateDt,
      },
      compensationChangeReasonChecker: {
        propertyId: objectFromPropertyState.compensationChangeReasonChecker?.propertyId,
        type: objectFromPropertyState.compensationChangeReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.compensationChangeReasonChecker?.lastUpdateDt,
      },
      compensationChangeReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.compensationChangeReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.compensationChangeReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.compensationChangeReasonDescriptionChecker?.lastUpdateDt,
      },
      changeCompensationFormatReasonChecker: {
        propertyId: objectFromPropertyState.changeCompensationFormatReasonChecker?.propertyId,
        type: objectFromPropertyState.changeCompensationFormatReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.changeCompensationFormatReasonChecker?.lastUpdateDt,
      },
      changeCompensationFormatReasonDescriptionChecker: {
        propertyId:
          objectFromPropertyState.changeCompensationFormatReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.changeCompensationFormatReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.changeCompensationFormatReasonDescriptionChecker?.lastUpdateDt,
      },
      lawChangeReasonChecker: {
        propertyId: objectFromPropertyState.lawChangeReasonChecker?.propertyId,
        type: objectFromPropertyState.lawChangeReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.lawChangeReasonChecker?.lastUpdateDt,
      },
      lawChangeReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.lawChangeReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.lawChangeReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.lawChangeReasonDescriptionChecker?.lastUpdateDt,
      },
      noMadeInvestorCapitalReasonChecker: {
        propertyId: objectFromPropertyState.noMadeInvestorCapitalReasonChecker?.propertyId,
        type: objectFromPropertyState.noMadeInvestorCapitalReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.noMadeInvestorCapitalReasonChecker?.lastUpdateDt,
      },
      noMadeInvestorCapitalReasonDescriptionChecker: {
        propertyId:
          objectFromPropertyState.noMadeInvestorCapitalReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.noMadeInvestorCapitalReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.noMadeInvestorCapitalReasonDescriptionChecker?.lastUpdateDt,
      },
      legalFactsReasonChecker: {
        propertyId: objectFromPropertyState.legalFactsReasonChecker?.propertyId,
        type: objectFromPropertyState.legalFactsReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.legalFactsReasonChecker?.lastUpdateDt,
      },
      legalFactsReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.legalFactsReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.legalFactsReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.legalFactsReasonDescriptionChecker?.lastUpdateDt,
      },
      lawViolationReasonChecker: {
        propertyId: objectFromPropertyState.lawViolationReasonChecker?.propertyId,
        type: objectFromPropertyState.lawViolationReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.lawViolationReasonChecker?.lastUpdateDt,
      },
      lawViolationReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.lawViolationReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.lawViolationReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.lawViolationReasonDescriptionChecker?.lastUpdateDt,
      },
      suspensionOrganizationActivityReasonChecker: {
        propertyId: objectFromPropertyState.suspensionOrganizationActivityReasonChecker?.propertyId,
        type: objectFromPropertyState.suspensionOrganizationActivityReasonChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.suspensionOrganizationActivityReasonChecker?.lastUpdateDt,
      },
      suspensionOrganizationActivityReasonDescriptionChecker: {
        propertyId:
          objectFromPropertyState.suspensionOrganizationActivityReasonDescriptionChecker
            ?.propertyId,
        type: objectFromPropertyState.suspensionOrganizationActivityReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.suspensionOrganizationActivityReasonDescriptionChecker
            ?.lastUpdateDt,
      },
      liquidationOrganisationReasonChecker: {
        propertyId: objectFromPropertyState.liquidationOrganisationReasonChecker?.propertyId,
        type: objectFromPropertyState.liquidationOrganisationReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.liquidationOrganisationReasonChecker?.lastUpdateDt,
      },
      liquidationOrganisationReasonDescriptionChecker: {
        propertyId:
          objectFromPropertyState.liquidationOrganisationReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.liquidationOrganisationReasonDescriptionChecker?.type,
        lastUpdateDt:
          objectFromPropertyState.liquidationOrganisationReasonDescriptionChecker?.lastUpdateDt,
      },
      otherReasonChecker: {
        propertyId: objectFromPropertyState.otherReasonChecker?.propertyId,
        type: objectFromPropertyState.otherReasonChecker?.type,
        lastUpdateDt: objectFromPropertyState.otherReasonChecker?.lastUpdateDt,
      },
      otherReasonNameChecker: {
        propertyId: objectFromPropertyState.otherReasonNameChecker?.propertyId,
        type: objectFromPropertyState.otherReasonNameChecker?.type,
        lastUpdateDt: objectFromPropertyState.otherReasonNameChecker?.lastUpdateDt,
      },
      otherReasonDescriptionChecker: {
        propertyId: objectFromPropertyState.otherReasonDescriptionChecker?.propertyId,
        type: objectFromPropertyState.otherReasonDescriptionChecker?.type,
        lastUpdateDt: objectFromPropertyState.otherReasonDescriptionChecker?.lastUpdateDt,
      },
      changeRecommendations: {
        propertyId: objectFromPropertyState.changeRecommendations?.propertyId,
        type: objectFromPropertyState.changeRecommendations?.type,
        lastUpdateDt: objectFromPropertyState.changeRecommendations?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForCertificateOnMonitoringResultsForm }
