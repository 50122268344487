import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import {
  IFiltersOfGetNpa,
  TGetModalListNpaResponse,
  TMasterModalNpaItemWithId,
} from '@context/APIContext/hooks/useNpaApi'
import { isObject } from '@helpers/checkTypes'
import { SWRHookProps } from '@interfaces'
import { nanoid } from 'nanoid'
import useSWRImmutable from 'swr/immutable'

const useNpaModalList = ({ key, config }: SWRHookProps<TGetModalListNpaResponse>) => {
  const {
    npaApi: { getListOfNpaModal },
  } = useAPIContext()

  const {
    data: npaList,
    error,
    mutate,
    isValidating: isNpaListLoading,
  } = useSWRImmutable(
    key,
    (filters) => {
      const filtersWithoutKey = (
        isObject(filters)
          ? { ...filters, excludedPartIds: undefined, size: undefined, _key: undefined }
          : {}
      ) as IFiltersOfGetNpa
      const excludedPartIds = isObject(filters)
        ? ((filters as unknown as Record<string, unknown>).excludedPartIds as string[])
        : undefined

      return getListOfNpaModal(filtersWithoutKey, excludedPartIds)
    },
    config,
  )

  const npaListWithIds: TMasterModalNpaItemWithId[] = useMemo(() => {
    if (!npaList || !npaList.length) return []

    return npaList.map((npa) => ({
      ...npa,
      id: nanoid(),
    }))
  }, [npaList, npaList?.length])

  return {
    npaList,
    npaListWithIds,
    error,
    mutate,
    isNpaListLoading,
  }
}

export { useNpaModalList }
