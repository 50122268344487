import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'

export const AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<AAVolumeOfCapitalInvestmentsFormValues>()
export const AAVolumeOfCapitalInvestmentsFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
