import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { SeventhRidObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { ridFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from './validation'

interface RidFormItemProps {
  isShowRidFormItemContent: boolean
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  formName: SeventhRidObjectsArrayPathName
}

const RidFormItem: FC<RidFormItemProps> = ({
  isShowRidFormItemContent,
  blockViewIsValidating,
  editMode,
  formInstance,
  formModifierInstance,
  formName,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const ridKind = useWatch({
    name: `${formName}.ridKind`,
    control: formInstance.control,
  })

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formModifierInstance.control,
  })

  const {
    getSubscribableControlProps,
    getFormFieldControlProps,
    getControllerProps,
    getInputProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  return (
    <div>
      <Col xs={12}>
        {!isShowRidFormItemContent && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.ridName`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={ridKind || ''}
                  controllerProps={getControllerProps({
                    name: `${formName}.ridName`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <CollapseWrapper
          defaultExpanded={isShowRidFormItemContent}
          isExpanded={isShowRidFormItemContent}
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.ridKind`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridKind`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridKind`,
                      rules:
                        ridFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridKind,
                      inputProps: {
                        label: 'Вид',
                      },
                      onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.ridKind`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridKind`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.ridName`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridName`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridName`,
                      rules:
                        ridFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridName,

                      inputProps: {
                        label: 'Наименование',
                        leftAddons: (
                          <FormIconWithTooltip tooltipContent="Заполняется в соответствии с ГК РФ и бизнес-планом инвестиционного проекта" />
                        ),
                      },
                      onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.ridName`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridName`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.ridAddress`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridAddress`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridAddress`,
                      rules:
                        ridFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridAddress,
                      inputProps: {
                        label: 'Адрес места выполнения работ по созданию РИД',
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridAddress`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridAddress`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.ridOwner`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridOwner`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridOwner`,
                      rules:
                        ridFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridOwner,
                      inputProps: {
                        label: 'Обладатель исключительного права',
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridOwner`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridOwner`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${formName}.ridDetails`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridDetails`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridDetails`,
                      rules:
                        ridFormItemAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridDetails,
                      inputProps: {
                        label: 'Иные характеристики',
                        caption: 'если применимо',
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridDetails`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridDetails`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </Col>
            </Row>
          </Stack>
        </CollapseWrapper>
      </Col>
    </div>
  )
}

export default RidFormItem
