import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['2']['subjects'][number],
  ProjectSZPKFormValues
> = {
  subjectHeaderName: {
    required: defaultRHFValidation.required,
  },
  subjectHeaderNameGenitive: {
    required: defaultRHFValidation.required,
  },
  subjectHeaderPosition: {
    required: defaultRHFValidation.required,
  },
  subjectHeaderPositionGenitive: {
    required: defaultRHFValidation.required,
  },
  subjectHeaderReasonGenitive: {
    required: defaultRHFValidation.required,
  },
}

export { secondSectionValidationMap }
