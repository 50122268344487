import { useEffect, useState } from 'react'

export const useTimer = <T extends () => any, R = ReturnType<T>>(
  receivedFunction: T,
  interval?: number
): R => {
  const [state, setState] = useState<R>(receivedFunction)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setState(receivedFunction())
    }, interval || 1000)

    return () => clearInterval(intervalId)
  }, [interval, receivedFunction])

  return state
}
