import Alert from '@components/Alert'
import { HttpStatusCode } from '@constants/statusCodes'
import { defaultMessage, errorsMessages } from '@constants/texts'
import { isAxiosError, isString } from '@helpers/checkTypes'
import { isErrorsWithBackendMessage, isIntervalServerError } from '@helpers/errorHelpers'
import { readBlob } from '@helpers/readBlob'
import { MainToastManager } from '@helpers/ToastManager'
import { AxiosError } from 'axios'

export const errorDataFromBackend = async (e: AxiosError) => {
  const { response } = e

  if (response?.data instanceof Blob) {
    await readBlob({ blob: response.data }).then((e) => {
      const { result } = e.target ?? {}

      if (isString(result)) {
        response.data = JSON.parse(result)
      }
    })
  }

  const isSizeLimitError = response?.status === HttpStatusCode.PAYLOAD_TOO_LARGE

  if (response?.status && (isSizeLimitError || isErrorsWithBackendMessage(response.status)))
    MainToastManager.toast(
      <Alert transparent variant="warning">
        {response.data?.detail ||
          response?.data?.violations?.map(({ message }) => message + ' ') ||
          errorsMessages[response.status] ||
          defaultMessage}
      </Alert>,
    )
}

export const handleInternalErrorWithPopup = (e: AxiosError) => {
  const { response } = e

  if (response?.status && isIntervalServerError(response.status)) {
    MainToastManager.toast(
      <Alert transparent variant="error">
        {defaultMessage}
      </Alert>,
    )
  }
}

export const handleErrorWithPopup = (error: unknown, hasInternal?: boolean) => {
  if (!isAxiosError(error)) return

  if (error?.response?.status === HttpStatusCode.UNAUTHORIZED) return

  errorDataFromBackend(error)

  if (hasInternal) handleInternalErrorWithPopup(error)
}
