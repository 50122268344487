import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAManufactoringGood,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { bigIntIsValidate, lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const volumeOfProductionValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAManufactoringGood,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  projectResultValue: {
    validate: bigIntIsValidate,
  },
  projectResultUnit: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 50) : undefined),
  },
}

export { volumeOfProductionValidationMap }
