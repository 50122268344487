import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import IndicatorsOfUnfulfilledAgreementReport from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport'
import {
  defaultIndicatorsOfUnfulfilledAgreementReportFormValues,
  mapOfIndicatorsOfUnfulfilledAgreementReportMenu,
} from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/const'
import { IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '../types'

import { useMonitoringReportDateSubtitle } from './hooks'
import IndicatorsOfUnfulfilledAgreementReportLayoutWrapper from './Wrapper'

const IndicatorsOfUnfulfilledAgreementReportLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  rolesOfUser,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<IndicatorsOfUnfulfilledAgreementReportFormValues>({
    defaultValues: defaultIndicatorsOfUnfulfilledAgreementReportFormValues,
  })

  const handleOnClose = () => {
    IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher.resetAllState()

    onClose?.()
  }

  const monitoringReportDateSubtitle = useMonitoringReportDateSubtitle(formInstance)

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <IndicatorsOfUnfulfilledAgreementReport.Manager
          formId={formId}
          projectId={projectId}
          rolesOfUser={rolesOfUser}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={mapOfIndicatorsOfUnfulfilledAgreementReportMenu[0].id}
          >
            <IndicatorsOfUnfulfilledAgreementReportLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Индикаторы отчета об исполнении соглашения
                </Typography.Headline>
                <Typography.Body
                  className={styles.layout__subtitle}
                  variant={'bodyMRegular'}
                  align={'center'}
                  as={'h2'}
                >
                  {monitoringReportDateSubtitle}
                </Typography.Body>
                <IndicatorsOfUnfulfilledAgreementReport.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <IndicatorsOfUnfulfilledAgreementReport.Menu className={styles.layout__menu} />
              </div>
            </IndicatorsOfUnfulfilledAgreementReportLayoutWrapper>
          </FlatMenuManager>
        </IndicatorsOfUnfulfilledAgreementReport.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(IndicatorsOfUnfulfilledAgreementReportLayout)
