import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { parametersApplicationReimbursementExpensesBlockValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/const'
import { useParametersApplicationReimbursementExpensesManager } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Manager'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import { ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const First = () => {
  const formInstance = useFormContext<ParametersApplicationReimbursementExpensesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useParametersApplicationReimbursementExpensesManager()

  const { getSubscribableControlProps, getControllerProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersApplicationReimbursementExpensesBlockValues['1'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Полное наименование'}
                controllerProps={getControllerProps({
                  name: parametersApplicationReimbursementExpensesBlockValues['1'].investorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersApplicationReimbursementExpensesBlockValues['1'].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: parametersApplicationReimbursementExpensesBlockValues['1'].investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersApplicationReimbursementExpensesBlockValues['1'].investorKpp,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'КПП'}
                controllerProps={getControllerProps({
                  name: parametersApplicationReimbursementExpensesBlockValues['1'].investorKpp,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default First
