import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  NotificationOfIdentifiedNPAListErrorsFormValues,
  NotificationOfIdentifiedNPAListErrorsNpaError,
} from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/types'
import { isArray } from '@helpers/checkTypes'

import { defaultNotificationOfIdentifiedNPAListErrorsFormValues } from '../const'

const generateRHFObjectForNotificationOfIdentifiedNPAListErrorsForm = (
  objectFromPropertyState: Record<string, any>,
): NotificationOfIdentifiedNPAListErrorsFormValues => {
  return {
    '1': RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    '2': RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameInstrumental:
        objectFromPropertyState.investorFullNameInstrumental?.value ||
        defaultNotificationOfIdentifiedNPAListErrorsFormValues['3'].investorFullNameInstrumental,
    },
    '4': {
      ruleLinkNpaReturn:
        objectFromPropertyState.ruleLinkNpaReturn?.value ||
        defaultNotificationOfIdentifiedNPAListErrorsFormValues['4'].ruleLinkNpaReturn,
      npaErrors: isArray(objectFromPropertyState.stabilizationNpaErrors?.value)
        ? objectFromPropertyState.stabilizationNpaErrors.value.map(
            (error): NotificationOfIdentifiedNPAListErrorsNpaError => ({
              npaName:
                error.value?.stabilizationNpaErrorName?.value?.stabilizationNpaId?.name ||
                error.value?.stabilizationUpdateNpaErrorName?.value?.stabilizationUpdateNpaId
                  ?.name ||
                '',
              npaParts:
                error.value?.stabilizationNpaErrorName?.value?.stabilizationNpaPartId?.part ||
                error.value?.stabilizationUpdateNpaErrorName?.value?.stabilizationUpdateNpaPartId
                  ?.part ||
                '',
              npaCorrection:
                error.value?.stabilizationNpaErrorName?.value?.stabilizationNpaId
                  ?.stabilizationNpaJustification ||
                error.value?.stabilizationUpdateNpaErrorName?.value?.stabilizationUpdateNpaId
                  ?.stabilizationUpdateNpaJustification,
              npaJustification:
                error.value?.stabilizationNpaErrorName?.value?.stabilizationNpaId
                  ?.stabilizationNpaCorrection ||
                error.value?.stabilizationUpdateNpaErrorName?.value?.stabilizationUpdateNpaId
                  ?.stabilizationUpdateNpaCorrection,
              errorText: error.value?.stabilizationNpaErrorText?.value,
            }),
          )
        : defaultNotificationOfIdentifiedNPAListErrorsFormValues['4'].npaErrors,
    },
  } as NotificationOfIdentifiedNPAListErrorsFormValues
}

export { generateRHFObjectForNotificationOfIdentifiedNPAListErrorsForm }
