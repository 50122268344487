import React from 'react'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import FieldView from '@components/DocumentFormComponents/FieldView'
import { BeneficiarSecondNationalityCountry } from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/types'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useTransformToListIds } from '@components/DocumentFormComponents/hooks/useTransformToListIds'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { FormModalContextProps } from '@components/DocumentFormComponents/types'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { Flipper } from '@components/ReactFlipToolkit'
import { defaultRHFValidation } from '@constants/validations'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

type BeneficiarySecondNationalityCountriesFormValues = {
  secondNationalityCountries: BeneficiarSecondNationalityCountry[]
}
type BeneficiarySecondNationalityCountriesFormName = 'secondNationalityCountries'

interface BeneficiarSecondNationalityCountriesListProps<T extends FieldValues> {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<any>
  formName: Path<T>
  manager: FormModalContextProps<any>
  watcher: FieldsControlUpdateWatcher
  fieldArrayWatcher: FieldArrayControlUpdateWatcher<any>
}

const BeneficiarSecondNationalityCountriesList = <T extends FieldValues>({
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
  manager,
  watcher,
  fieldArrayWatcher,
}: BeneficiarSecondNationalityCountriesListProps<T>) => {
  const {
    handlers: { handleChangeValue, handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = manager

  const { getSubscribableControlProps, getSingleSelectProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher,
  })

  const { fields: beneficiarSecondNationalityCountries } = useFieldArraySubscribableControl<
    BeneficiarySecondNationalityCountriesFormValues,
    BeneficiarySecondNationalityCountriesFormName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${formName}.beneficiarSecondNationalityCountries` as any,
    keyName: 'keyNameId',
    watcher: fieldArrayWatcher,
  })

  const { listIds: beneficiarSecondNationalityCountryIds } = useTransformToListIds(
    beneficiarSecondNationalityCountries,
  )

  const clearBeneficiarSecondNationalityCountriesError = () => {
    formInstance.clearErrors(`${formName}.beneficiarSecondNationalityCountries`)
  }

  const handleAddNewBeneficiarSecondNationalityCountry = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.beneficiarSecondNationalityCountries`)

    clearBeneficiarSecondNationalityCountriesError()
  }

  const handleRemoveBeneficiarSecondNationalityCountry = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${formName}.beneficiarSecondNationalityCountries.${index}`,
      `${formName}.beneficiarSecondNationalityCountries`,
    )

    clearBeneficiarSecondNationalityCountriesError()
  }

  return (
    <Group disableBottomBorder>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.beneficiarSecondNationalityCountries`,
          })}
        >
          <Flipper flipKey={beneficiarSecondNationalityCountryIds.join('')}>
            <Stack direction={'vertical'} gap={3}>
              {beneficiarSecondNationalityCountries.map((country, index) => {
                const beneficiarSecondNationalityCountryName =
                  `${formName}.beneficiarSecondNationalityCountries.${index}` as const

                return (
                  <FieldView.RowWithRemoveButton
                    key={country.id}
                    id={country.id}
                    onRemove={
                      editMode ? handleRemoveBeneficiarSecondNationalityCountry(index) : undefined
                    }
                  >
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${beneficiarSecondNationalityCountryName}.beneficiarSecondNationalityCountry`,
                      })}
                    >
                      {({ overrideProps }) => {
                        const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                          ? overrideProps.fetcherOptions
                          : undefined

                        return (
                          <ControlledFormSingleSelect
                            {...getSingleSelectProps({
                              fetcherProps,
                              optionsAdapter: (item) => ({
                                displayValue: item.name,
                                value: item.id,
                              }),
                              controllerProps: {
                                name: `${beneficiarSecondNationalityCountryName}.beneficiarSecondNationalityCountry`,
                                rules: { required: defaultRHFValidation.required },
                              },
                              selectProps: {
                                inputProps: {
                                  label: 'Страна иного гражданства',
                                },
                                onChangeFormValue: () =>
                                  setTimeout(async () => {
                                    await handleChangeValue?.(
                                      `${beneficiarSecondNationalityCountryName}.beneficiarSecondNationalityCountry`,
                                    )

                                    clearBeneficiarSecondNationalityCountriesError()
                                  }, 0),
                              },
                            })}
                          />
                        )
                      }}
                    </SubscribableControl>
                  </FieldView.RowWithRemoveButton>
                )
              })}
            </Stack>
          </Flipper>
        </SubscribableControl>
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddNewBeneficiarSecondNationalityCountry}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить иное гражданство
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default BeneficiarSecondNationalityCountriesList
