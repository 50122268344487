import React, { DetailedHTMLProps, FC, FormHTMLAttributes, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { mapOfNotificationOfChangesInDetailsOfPartiesMenu } from '@components/Forms/NotificationOfChangesInDetailsOfParties/const'
import NotificationOfChangesInDetailsOfPartiesForms from '@components/Forms/NotificationOfChangesInDetailsOfParties/Forms'
import { useNotificationOfChangesInDetailsOfPartiesManager } from '@components/Forms/NotificationOfChangesInDetailsOfParties/Manager'
import { NotificationOfChangesInDetailsOfPartiesFormValues } from '@components/Forms/NotificationOfChangesInDetailsOfParties/types'

type NotificationOfChangesInDetailsOfPartiesFormProps = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>

const mapOfNotificationOfChangesInDetailsOfPartiesForms: FormItemProps = {
  '1': {
    node: <NotificationOfChangesInDetailsOfPartiesForms.First />,
  },
  '2': {
    node: <NotificationOfChangesInDetailsOfPartiesForms.Second />,
  },
} as const

const NotificationOfChangesInDetailsOfPartiesForm: FC<
  NotificationOfChangesInDetailsOfPartiesFormProps
> = ({ ...rest }) => {
  const formInstance = useFormContext<NotificationOfChangesInDetailsOfPartiesFormValues>()

  const {
    state: { formIsLoading },
  } = useNotificationOfChangesInDetailsOfPartiesManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNotificationOfChangesInDetailsOfPartiesMenu}
      mapOfForms={mapOfNotificationOfChangesInDetailsOfPartiesForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NotificationOfChangesInDetailsOfPartiesForm)
