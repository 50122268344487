import React, { VFC } from 'react'
import { PopupProps } from 'react-popup-manager'

import Button from '@components/Button'
import Modal from '@components/Modal'

import styles from './BlockOrganization.module.scss'

export interface RegistryItemModalProps extends PopupProps {
  type: 'block' | 'unblock'
  orgName: string
  onSuccess?: () => void
}

const BlockOrganizationModal: VFC<RegistryItemModalProps> = ({
  type,
  orgName,
  onSuccess,
  ...rest
}) => {
  const isBlock = type === 'block'
  const actionText = `${isBlock ? 'За' : 'Раз'}блокировать`
  const title = `${actionText} организацию ${orgName}?`
  const body = isBlock
    ? 'Все пользователи данной организации потеряют доступ к системе'
    : 'Пользователи данной организации снова получат доступ к системе'
  return (
    <Modal title={title} {...rest}>
      <div className={styles.body}>{body}</div>
      <div className={styles.btnBottom}>
        <Button priority="secondary-positive" className="mr-4" onClick={rest?.onClose}>
          Отмена
        </Button>
        <Button priority={isBlock ? 'primary-negative' : 'primary-positive'} onClick={onSuccess}>
          {actionText}
        </Button>
      </div>
    </Modal>
  )
}

export default BlockOrganizationModal
