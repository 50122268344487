import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { NinthVolumeOfProductionArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/9/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import DayjsService from '@services/Dayjs/Dayjs.service'

import { volumeOfProductionValidationMap } from './validation'

interface ManufactoringOfGoodsItemProps {
  name: NinthVolumeOfProductionArrayPathName
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
}

const ManufactoringOfGoodsItem: FC<ManufactoringOfGoodsItemProps> = ({
  name,
  formInstance,
  formModifierInstance,
}) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${name}.isNew`,
    control: formModifierInstance.control,
  })

  const projectResultYear = useWatch({
    name: `${name}.projectResultYear`,
    control: formInstance.control,
  })

  const preparedYearText = DayjsService.dayjs(projectResultYear).year().toString()

  const { getFormFieldControlProps, getInputProps, getAmountInputProps } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  return (
    <FormObjectTooltipControl
      name={name}
      control={formModifierInstance.control}
      onDifference={handleUpdateChanges}
    >
      <Group disableBottomBorder title={preparedYearText}>
        <Row>
          <Col xs={6}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.projectResultValue`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectResultValue`)
                    }, 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.projectResultValue`,
                  rules: volumeOfProductionValidationMap.projectResultValue,
                  inputProps: {
                    suffix: '',
                    label: 'Объем',
                    hasNegativeValue: false,
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectResultValue`, 'projectResults')
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.projectResultValue`, 'projectResults')
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
          <Col xs={6}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.projectResultUnit`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectResultUnit`)
                    }, 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${name}.projectResultUnit`,
                  rules: volumeOfProductionValidationMap.projectResultUnit,
                  inputProps: {
                    label: 'Единица измерения',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectResultUnit`, 'projectResults')
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.projectResultUnit`, 'projectResults')
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
      </Group>
    </FormObjectTooltipControl>
  )
}

export default ManufactoringOfGoodsItem
