import { WebFormTypes } from '@constants/types'
import { npaPropertyTypes } from '@services/NPA/NPA.const'

import { NpaFormTypes, NpaPropertyTypes, TPartClassificationLayoutItem } from './NPA.entity'
import { INpaThreeLayoutWithChangesNpaRegistry, IPartClassificationLayout } from './NPA.entity'

class NPAHelpers {
  static isNotActualPart(part: TPartClassificationLayoutItem) {
    return !part.isActual || !part.npaInfo.isActual
  }

  static isExcludedPart(part: TPartClassificationLayoutItem) {
    return part.isExcluded || part.npaInfo.isExcluded
  }

  static compareClassifications(
    classification: IPartClassificationLayout,
    nextClassification: IPartClassificationLayout,
  ) {
    return classification.chapter.localeCompare(nextClassification.chapter)
  }

  static compareNpaThree(
    three: INpaThreeLayoutWithChangesNpaRegistry,
    nextThree: INpaThreeLayoutWithChangesNpaRegistry,
  ) {
    return (
      Number(nextThree.isChangesNpaRegistry) - Number(three.isChangesNpaRegistry) ||
      three.order - nextThree.order
    )
  }

  static getNpaPropertyPrefixByDocumentType(documentType: NpaFormTypes): NpaPropertyTypes {
    const npaPropertyPrefixMap = {
      [WebFormTypes.NPA_LIST]: npaPropertyTypes.stabilizationNpa,
      [WebFormTypes.SOLICITATION_INCLUSION_NPA]: npaPropertyTypes.stabilizationUpdateNpa,
      [WebFormTypes.NPA_LIST_MUNICIPAL]: npaPropertyTypes.municipalNpa,
    }

    return npaPropertyPrefixMap[documentType]
  }
}

export default NPAHelpers
