import React, { FC, useCallback, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSingleSelect } from '@components/NewDesign/Select'
import Sidebar from '@components/NewDesign/Sidebar'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import { useRemoveModal } from '@components/NewDesignedModals/RemoveModal'
import { dictionariesNames } from '@components/ReferenceBooks/constants'
import { codePatterns } from '@components/Sidebars/ReferenceBooks/HierarchyType/Entry/const'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import FieldCondition from '@containers/FieldCondition'
import { useReferenceBooksApi } from '@context/APIContext/hooks/useReferenceBooksApi'
import {
  CreatedReferenceBookItem,
  ReferenceBookItem,
  UpdatedReferenceBookItem,
} from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { isAxiosError, isString } from '@helpers/checkTypes'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { useBooleanState } from '@hooks/useBooleanState'

import styles from './Entry.module.scss'

type THierarchySidebarAction = 'create' | 'delete' | 'update'

interface HierarchyEntryProps {
  dictionaryName: keyof typeof dictionariesNames
  initialEntry: ReferenceBookItem
  onSuccess: (createItem: ReferenceBookItem, action?: THierarchySidebarAction) => void
  parentInitialEntry?: ReferenceBookItem
  type?: 'create' | 'view'
}

type HierarchyEntrySidebarProps = HierarchyEntryProps & PopupProps
type ReferenceItemFormValues = Omit<ReferenceBookItem, 'children'>

const useHierarchyEntrySidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenEntrySidebar = (props: HierarchyEntryProps) => {
    popupManager.open(HierarchyEntrySidebar, props)
  }

  return {
    handleOpenEntrySidebar,
  }
}

const HierarchyEntrySidebar: FC<HierarchyEntrySidebarProps> = ({
  parentInitialEntry,
  initialEntry,
  dictionaryName,
  isOpen,
  type = 'view',
  onClose,
  onSuccess,
}) => {
  const popupManager = usePopupManager()

  const { createReferenceBook, updateReferenceBook, deleteReferenceBook } = useReferenceBooksApi()

  const { handleOpenRemoveModal } = useRemoveModal()

  const isCreateMode = type === 'create'
  const isTNVED = dictionaryName === dictionariesNames.tnved

  const defaultFormValuesByType = useMemo(() => {
    if (isCreateMode)
      return {
        parentCode: initialEntry.code,
        measureType: isTNVED ? '' : undefined,
        code: '',
        name: '',
      }

    return {
      parentCode: initialEntry.parentCode ?? '',
      measureType: isTNVED ? initialEntry.measureType ?? '' : undefined,
      code: initialEntry.code ?? '',
      name: initialEntry.name ?? '',
    }
  }, [
    initialEntry.code,
    initialEntry.measureType,
    initialEntry.parentCode,
    initialEntry.name,
    isCreateMode,
    isTNVED,
  ])

  const initialFocusRef = useRef<HTMLInputElement | null>(null)

  const formInstance = useForm<ReferenceItemFormValues>({
    defaultValues: defaultFormValuesByType,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { booleanState: isRequestLoading, setBooleanState: setRequestLoading } = useBooleanState()

  const handleSuccessRequest = useCallback(
    (referenceBookItem: ReferenceBookItem, action: THierarchySidebarAction = 'create') => {
      popupManager.closeAll()
      onSuccess(referenceBookItem, action)
    },
    [onSuccess, popupManager],
  )

  const handleCreateEntry = useCallback(async () => {
    const formValues = formInstance.getValues()

    setRequestLoading(true)

    try {
      const referenceBookItem: CreatedReferenceBookItem = {
        parentId: initialEntry.id,
        code: formValues.code?.trim() || '',
        name: formValues.name.trim(),
        measureType: formValues.measureType || null,
      }

      const idOfCreateReferenceBook = await createReferenceBook({
        dictionaryName,
        newReferenceBookBody: referenceBookItem,
      })

      handleSuccessRequest({
        ...referenceBookItem,
        id: idOfCreateReferenceBook,
        parentCode: formValues.parentCode?.trim() || '',
        fromSearchThree: !!initialEntry?.fromSearchThree,
        hasChild: null,
        children: null,
      })
    } catch (error) {
      if (!isAxiosError(error)) throw error

      if (!Array.isArray(error.response?.data.violations)) {
        handleErrorWithPopup(error, true)
        throw error
      }

      applyErrorsFromViolations(error.response?.data.violations)
    } finally {
      setRequestLoading(false)
    }
  }, [createReferenceBook, dictionaryName, formInstance, handleSuccessRequest, setRequestLoading])

  const handleUpdateEntry = useCallback(async () => {
    if (!initialEntry) return

    const formValues = formInstance.getValues()

    setRequestLoading(true)

    try {
      const referenceBookItem: UpdatedReferenceBookItem = {
        id: initialEntry.id,
        measureType: formValues.measureType || null,
        parentId: initialEntry.parentId,
        code: formValues.code?.trim() || '',
        name: formValues.name.trim(),
      }

      const newIdAfterUpdate = await updateReferenceBook({
        dictionaryName,
        updatedReferenceBookBody: referenceBookItem,
      })

      handleSuccessRequest(
        {
          ...referenceBookItem,
          id: newIdAfterUpdate,
          parentCode: formValues.parentCode?.trim() || '',
          hasChild: initialEntry.hasChild,
          fromSearchThree: initialEntry.fromSearchThree,
          children: null,
        },
        'update',
      )
    } catch (error) {
      throw error
    } finally {
      setRequestLoading(false)
    }
  }, [
    dictionaryName,
    formInstance,
    handleSuccessRequest,
    initialEntry,
    setRequestLoading,
    updateReferenceBook,
  ])

  const handleDeleteEntry = useCallback(async () => {
    if (!initialEntry) return

    setRequestLoading(true)

    try {
      await deleteReferenceBook({
        dictionaryName,
        id: initialEntry.id,
      })

      handleSuccessRequest(initialEntry, 'delete')
    } catch (error) {
      throw error
    } finally {
      setRequestLoading(false)
    }
  }, [deleteReferenceBook, dictionaryName, handleSuccessRequest, initialEntry, setRequestLoading])

  const sidebarActions = useMemo(() => {
    const loadingProps = {
      loaderProps: {
        loading: isRequestLoading,
        variant: 'lite',
        placement: 'trailing',
      },
    } as const

    return isCreateMode
      ? ([
          {
            children: 'Отмена',
            view: 'gray',
            onClick: onClose,
          },
          {
            children: 'Добавить',
            onClick: formInstance.handleSubmit(handleCreateEntry),
            disabled: isRequestLoading,
            loaderProps: loadingProps.loaderProps,
          },
        ] as ButtonProps[])
      : [
          {
            children: 'Удалить',
            view: 'gray',
            color: 'negative',
            onClick: () =>
              handleOpenRemoveModal({
                title: 'Удалить запись из справочника?',
                description:
                  'Запись будет удалена из справочника и недоступна \nдля выбора инвестором',
                onConfirm: handleDeleteEntry,
              }),
            disabled: false,
          },
          {
            children: 'Сохранить',
            onClick: formInstance.handleSubmit(handleUpdateEntry),
            disabled: isRequestLoading || !formInstance.formState.isDirty,
            loaderProps: loadingProps.loaderProps,
          },
        ]
  }, [
    formInstance,
    handleCreateEntry,
    handleDeleteEntry,
    handleOpenRemoveModal,
    handleUpdateEntry,
    isCreateMode,
    isRequestLoading,
    onClose,
  ])

  return (
    <Sidebar
      headerClassName={styles['hierarchyEntry__sidebar-header']}
      contentClassName={styles['hierarchyEntry__sidebar-content']}
      title={isCreateMode ? 'Добавление записи' : 'Просмотр записи'}
      initialFocus={initialFocusRef}
      isOpen={isOpen}
      actions={sidebarActions}
      onClose={onClose}
    >
      <div className={styles.hierarchyEntry}>
        <div className={styles.hierarchyEntry__control}>
          <ControlledSingleSelect
            disabled
            options={[
              isCreateMode
                ? { displayValue: initialEntry.code ?? '', value: initialEntry.code ?? '' }
                : {
                    displayValue: initialEntry.parentCode ?? '',
                    value: initialEntry.parentCode ?? '',
                  },
            ]}
            inputProps={{
              disabled: true,
              label: 'Код родительской записи',
              view: 'secondary',
              fixWidth: true,
            }}
            controllerProps={{
              name: 'parentCode',
              control: formInstance.control,
              rules: {
                required: defaultRHFValidation.required,
              },
            }}
          />
        </div>

        <div className={styles.hierarchyEntry__control}>
          <ControlledInput
            control={formInstance.control}
            name={'code'}
            rules={{
              required: defaultRHFValidation.required,
              pattern: {
                value: /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/,
                message: 'неверно заполнено поле',
              },
              validate: (value) => isString(value) && lengthValidate(value, 50),
            }}
            inputProps={{
              ref: initialFocusRef,
              label: isCreateMode ? 'Код новой записи' : 'Код записи',
              view: 'secondary',
              fixWidth: true,
            }}
          />
          <FieldCondition displayValue={'Код может содержать:'} config={codePatterns} />
        </div>
        <div className={styles.hierarchyEntry__control}>
          <ControlledTextarea
            control={formInstance.control}
            name={'name'}
            rules={{
              required: defaultRHFValidation.required,
              validate: (value) => isString(value) && lengthValidate(value, 1500),
            }}
            textareaProps={{
              label: 'Значение справочника',
              fixWidth: true,
            }}
          />
        </div>

        {isTNVED && (
          <div className={styles.hierarchyEntry__control}>
            <ControlledInput
              control={formInstance.control}
              name={'measureType'}
              rules={{
                validate: (value) => isString(value) && lengthValidate(value, 100),
              }}
              inputProps={{
                label: 'Единицы измерения',
                caption: 'необязательно',
                view: 'secondary',
                fixWidth: true,
              }}
            />
          </div>
        )}
      </div>
    </Sidebar>
  )
}

export { useHierarchyEntrySidebar }
export default HierarchyEntrySidebar
