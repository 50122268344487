import React, { FC, memo } from 'react'

import Icon from '@components/Icon'
import styles from '@components/TechnicalWork/HeaderBanner/HeaderBanner.module.scss'
import technicalIcon from '@icons/TechnicalWork.svg'
import { IInitialTechnicalWork } from '@services/TechnicalWork/TechnicalWork.entity'
import { TechnicalWorkService } from '@services/TechnicalWork/TechnicalWork.service'
import cn from 'classnames'

type TWHeaderBannerProps = IInitialTechnicalWork & {
  wrapperClassname?: string
}

const HeaderBanner: FC<TWHeaderBannerProps> = ({
  startDate,
  endDate,
  timeToStart,
  timeToEnd,
  isRunning,
  wrapperClassname,
  fallbackStatus,
}) => {
  const technicalWorkService = new TechnicalWorkService({
    startDate,
    endDate,
    isRunning,
    timeToStart,
    timeToEnd,
  })

  const bannerText =
    isRunning && !fallbackStatus
      ? technicalWorkService.getFormattedOffsetOfEndTime('Идут технические работы. Ещё %formatted')
      : technicalWorkService.getFormattedOffsetOfStartTime('Технические работы через %formatted')

  if (!bannerText && !fallbackStatus) return null

  return (
    <div
      className={cn(
        styles.headerBanner,
        { [styles['headerBanner--running']]: isRunning },
        wrapperClassname,
      )}
    >
      <div className={styles.technicalIcon_wrapper}>
        <Icon
          src={technicalIcon}
          size="initial"
          className={cn(styles.technicalIcon, { [styles['technicalIcon--running']]: isRunning })}
          wrapper="span"
        />
      </div>
      <span
        className={cn(styles.headerBanner_content, {
          [styles['headerBanner_content--running']]: isRunning,
        })}
      >
        {!fallbackStatus ? bannerText : 'Идут технические работы'}
      </span>
    </div>
  )
}

export default memo(HeaderBanner)
