import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNoticeIntentionTerminateSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    '2': PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '3': {
      ruleLinkAgreementTermination: {
        propertyId: objectFromPropertyState.ruleLinkAgreementTermination?.propertyId,
        type: objectFromPropertyState.ruleLinkAgreementTermination?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkAgreementTermination?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNoticeIntentionTerminateSZPKForm }
