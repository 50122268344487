import step100Icon from '@icons/steps/step-100.svg'
import step200Icon from '@icons/steps/step-200.svg'
import step300Icon from '@icons/steps/step-300.svg'
import step400Icon from '@icons/steps/step-400.svg'
import step500Icon from '@icons/steps/step-500.svg'
import step600Icon from '@icons/steps/step-600.svg'

export const icons = {
  '100': step100Icon,
  '200': step200Icon,
  '300': step300Icon,
  '400': step400Icon,
  '500': step500Icon,
  '600': step600Icon,
}
