import DayjsService from '@services/Dayjs/Dayjs.service'

export const objOfDateFormats = {
  defaultNativeDateFormat: 'YYYY-MM-DD',

  defaultFormat: 'DD.MM.YYYY',
  formatWithFullMonth: 'DD MMMM YYYY',

  formatWithFullMonthButSingleDay: 'D MMMM YYYY',

  onlyMonth: 'DD MMMM',

  currentYear: {
    fullFormat: 'DD MMMM · HH:mm',
    weekdayFullFormat: 'dd, DD MMMM, HH:mm',
    limitedFormat: 'DD MMM · HH:mm',
  },
  sameElse: {
    fullFormat: 'DD MMMM YYYY · HH:mm',
    limitedFormat: 'DD MMM YYYY · HH:mm',
    normalFormat: 'DD.MM.YYYY · HH:mm',
  },
  yearFormat: {
    yearOnly: 'YYYY',
  },
} as const

export const getBackendDateFormat = (date: Date) => DayjsService.dayjs(date).format('YYYY-MM-DD')
