const { diff } = require('@helpers/object/diff.js')

class DeepDiffService {
  /**
   * Для включенного флага isCustomArrayDiffEnabled рекомендуется, чтобы в массивах у их элементов были уникальные id
   * В ином случае сравнение элементов массива будет через deepEqual, что нежелательно
  */
  static getDifferences(prevValue: unknown, nextValue: unknown, isCustomArrayDiffEnabled = true) {
    return diff(prevValue, nextValue, null, null, isCustomArrayDiffEnabled)
  }
}

export { DeepDiffService }
