import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import ProjectSZPKMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import ProjectSZPKLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import ProjectSZPKModalManager from './Manager'
import ProjectSZPKMenu from './Menu'
import ProjectSZPKStatementForm from './Statement'

interface IProjectSZPK extends FC {
  LayoutWithFormProvider: typeof ProjectSZPKLayoutWithFormProvider
  Menu: typeof ProjectSZPKMenu
  StatementForm: typeof ProjectSZPKStatementForm
  Manager: typeof ProjectSZPKModalManager
  MenuManager: typeof ProjectSZPKMenuManager
}

const useProjectSZPKControl = () => {
  const popupManager = usePopupManager()

  const openProjectSZPKModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, projectId, onClose, ...rest } = props

      if (!formId || !projectId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(ProjectSZPKLayoutWithFormProvider, {
        ...rest,
        projectId,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openProjectSZPKModal }
}

const ProjectSZPK: IProjectSZPK = () => null

ProjectSZPK.Menu = ProjectSZPKMenu
ProjectSZPK.LayoutWithFormProvider = ProjectSZPKLayoutWithFormProvider
ProjectSZPK.StatementForm = ProjectSZPKStatementForm
ProjectSZPK.Manager = ProjectSZPKModalManager
ProjectSZPK.MenuManager = ProjectSZPKMenuManager

export { useProjectSZPKControl }
export default ProjectSZPK
