import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidRegistration,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const ridRegistrationsAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidRegistration,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  ridName: {
    required: defaultRHFValidation.required,
  },
  ridStateRegistrationNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  ridStateRegistrationOrganization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { ridRegistrationsAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
