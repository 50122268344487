import React, { forwardRef, RefObject, useEffect, useState } from 'react'

import IconButton from '@components/NewDesign/IconButton'
import _debounce from '@helpers/lodash/debounce'
import ChevronLeftIcon from '@icons/navigation/chevron_left.svg'
import ChevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import { TabsProps } from '../../types'

import styles from './scrollControls.module.scss'
import { getDisabledState, scrollIntoFirstTab, scrollIntoLastTab } from './utils'

type ScrollControlsProps = {
  className?: string
  view: Exclude<TabsProps['view'], undefined>
  size: TabsProps['size']
  containerRef: RefObject<HTMLDivElement>
}

export const ScrollControls = forwardRef<HTMLDivElement, ScrollControlsProps>(
  ({ containerRef, view, size: sizeProp, className }, ref) => {
    const container = containerRef.current
    const [disabledState, updateDisabledState] = useState(() => getDisabledState(container))

    useEffect(() => {
      const handleScroll = _debounce(() => updateDisabledState(getDisabledState(container)), 100, {
        leading: true,
        maxWait: 100,
        trailing: true,
      })

      container?.addEventListener('scroll', handleScroll)

      return () => container?.removeEventListener('scroll', handleScroll)
    }, [container])

    const getSize = () => {
      if (view === 'primary') {
        return sizeProp === 'xl' ? 'xs' : 'xxs'
      }

      return sizeProp && ['s', 'm', 'l', 'xl'].includes(sizeProp) ? 's' : 'xs'
    }

    const handleScrollLeft = () => scrollIntoFirstTab(container)

    const handleScrollRight = () => scrollIntoLastTab(container)

    const commonButtonProps = {
      className: styles.button,
      size: getSize(),
    } as const

    return (
      <div
        ref={ref}
        className={cn(styles.component, styles[view], sizeProp && styles[sizeProp], className, {
          [styles.borderVisible]: !disabledState.toRight,
        })}
      >
        <IconButton
          {...commonButtonProps}
          disabled={disabledState.toLeft}
          icon={{
            src: ChevronLeftIcon,
          }}
          onClick={handleScrollLeft}
        />
        <IconButton
          {...commonButtonProps}
          disabled={disabledState.toRight}
          icon={{
            src: ChevronRightIcon,
          }}
          onClick={handleScrollRight}
        />
      </div>
    )
  },
)
