import { useCallback, useMemo, useReducer } from 'react'

import { ButtonProps } from '@components/NewDesign/Button/types'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import {
  defaultModalButtonTexts,
  errorModalButtonTexts,
  letterHeadPartsBaseTexts,
} from '@constants/texts'
import { useAPIContext } from '@context/APIContext'
import {
  AlignnmentFile,
  LetterHeadFile,
  LetterHeadFileItemName,
} from '@context/APIContext/hooks/useLetterHeadPartsApi/types'

import { fileItemCreators, fileItemsReducer, initialFilesState } from './reducer'
import {
  DrawerControlsInterface,
  FileReducerState,
  OrganizationLetterFilesReducerState,
} from './types'

const { setFile, setLoading, resetFile, setAlignment } = fileItemCreators

const useFileDropLoader = (organizationFilesState?: LetterHeadFile[]) => {
  const {
    headerLetterPartsApi: { uploadLetterHeadPartFile, getOrganizationLetterHeadPartFile },
  } = useAPIContext()

  const { handleOpenErrorModal } = useErrorModal()

  const preparedOrganizationInitState: OrganizationLetterFilesReducerState = (
    organizationFilesState || []
  ).reduce(
    (initialState, fileItem) => ({ ...initialState, [fileItem.type]: fileItem }),
    initialFilesState,
  )

  const [{ first_footer, first_header, general_footer, general_header }, dispatch] = useReducer(
    fileItemsReducer,
    preparedOrganizationInitState,
  )

  const loadFile = useCallback(
    async (fileItemName: LetterHeadFileItemName, file: File) => {
      dispatch(setLoading({ fileItemName, pData: true }))

      const formData = new FormData()
      formData.append('document', file, file?.name)
      try {
        const {
          data: { fileId },
        } = await uploadLetterHeadPartFile(formData)
        const [fileName, fileExtension] = file.name.split('.')

        dispatch(
          setFile({
            fileItemName,
            pData: { fileId, fileName, fileExtension, fileSize: file.size },
          }),
        )
      } catch (error) {
        dispatch(setLoading({ fileItemName, pData: false }))
      }
    },
    [uploadLetterHeadPartFile],
  )

  const handleFileDrop = useCallback(
    (fileItemName: LetterHeadFileItemName) => (acceptedFiles: File[]) => {
      const fileToUpload = acceptedFiles[0]

      if (!fileToUpload) {
        handleOpenErrorModal({
          headerText: letterHeadPartsBaseTexts.errorUploadFileMessage,
          bodyText: letterHeadPartsBaseTexts.messageSupportWithLink,
          customActions: {
            actions: [
              {
                dataTestId: 'ErrorModal-continueMessage-button',
                children: errorModalButtonTexts.continueMessage,
                fixWidth: true,
                bindOnClose: true,
              },
            ],
            mergeDefault: false,
          },
        })
        return
      } else {
        loadFile(fileItemName, fileToUpload)
      }
    },
    [loadFile],
  )

  const handleFiledownload = useCallback(
    ({ fileId, fileName, fileExtension }: FileReducerState) => {
      if (!fileId || !fileName || !fileExtension) return

      getOrganizationLetterHeadPartFile(fileId, fileName, fileExtension)
    },
    [getOrganizationLetterHeadPartFile],
  )

  const handleFileReset = useCallback(
    (fileItemName: LetterHeadFileItemName) => () => dispatch(resetFile({ fileItemName })),
    [],
  )

  const handleFileSetAlignment = useCallback(
    (fileItemName: LetterHeadFileItemName) => (alignment: AlignnmentFile) =>
      dispatch(setAlignment({ fileItemName, pData: { alignment } })),
    [],
  )

  const isPreviewEnabled = useMemo(
    () => [first_footer, first_header, general_footer, general_header].find((part) => part.fileId),
    [first_footer, first_header, general_footer, general_header],
  )

  return useMemo(
    () => ({
      first_footer,
      first_header,
      general_footer,
      general_header,
      fileActions: { handleFileDrop, handleFileReset, handleFileSetAlignment, handleFiledownload },
      isPreviewEnabled,
    }),
    [
      first_footer,
      first_header,
      general_footer,
      general_header,
      handleFileDrop,
      handleFileReset,
      handleFileSetAlignment,
      handleFiledownload,
      isPreviewEnabled,
    ],
  )
}

const useDrawerControls = ({ onClose, isPending, onSave }: DrawerControlsInterface) =>
  useMemo(() => {
    return [
      {
        children: defaultModalButtonTexts.cancelMessage,
        fixWidth: true,
        onClick: onClose,
        view: 'gray',
        color: 'negative',
      },
      {
        children: defaultModalButtonTexts.successMessage,
        fixWidth: true,
        onClick: onSave,
        disabled: isPending,
        loaderProps: {
          loading: isPending,
          placement: 'trailing',
          variant: 'lite',
        },
      },
    ] as ButtonProps[]
  }, [isPending, onClose, onSave])

export { useDrawerControls, useFileDropLoader }
