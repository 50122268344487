import React, {
  type ChangeEvent,
  type FC,
  type FocusEvent,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import type { FileRejection } from 'react-dropzone'
import { type UseFormReturn } from 'react-hook-form'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import { useAttachmentAPIContext } from '@components/Attachments/Attachment/APIProvider'
import { AttachmentFileItemService } from '@components/Attachments/Attachment/FileItem/AttachmentFileItem/helpers'
import { FileItemService } from '@components/Attachments/Attachment/FileItem/helpers'
import LeadingAdditionInTrailingNode from '@components/Attachments/Attachment/FileItem/TrailingNode/Addition/LeadingNodeTrailing'
import TrailingAdditionInTrailingNode from '@components/Attachments/Attachment/FileItem/TrailingNode/Addition/TrailingNodeAddition'
import trailingNodeStyles from '@components/Attachments/Attachment/FileItem/TrailingNode/FileItemTrailingNode.module.scss'
import type {
  FileItemLeadingAdditionProps,
  FileItemTrailingAdditionProps,
  RejectedFile,
} from '@components/Attachments/Attachment/FileItem/types'
import type {
  FolderFileItem,
  FolderNameProps,
} from '@components/Attachments/Attachment/FolderItem/types'
import { useAttachmentPermissions } from '@components/Attachments/Attachment/hooks/useAttachmentPermissions'
import { useDropzoneValidation } from '@components/Attachments/Attachment/hooks/useDropzoneValidation'
import MultipleItem from '@components/Attachments/Attachment/MultipleItem'
import {
  alertTextsMap,
  folderNameRulesMap,
} from '@components/Attachments/Attachment/MultipleItem/AttachmentMultipleItem/constants'
import { useAttachmentMultipleItemFieldArray } from '@components/Attachments/Attachment/MultipleItem/AttachmentMultipleItem/hooks/useAttachmentMultipleItemFieldArray'
import type {
  CombinedMultipleItem,
  MultipleFileItem,
  MultipleFolderItem,
  MultipleItemDropzoneProps,
  MultipleItemReducerState,
} from '@components/Attachments/Attachment/MultipleItem/types'
import { attachmentsFormNames, statusTypesMap } from '@components/Attachments/constants'
import { useFileContext } from '@components/Attachments/FileProvider'
import type { AttachmentsFormValue, TMultipleItem } from '@components/Attachments/types'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import Typography from '@components/NewDesign/Typography'
import { useDeleteFolderModal } from '@components/NewDesignedModals/DeleteFolderModal/manager'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import { useVersionController } from '@components/Projects/[id]/VersionConroller'
import {
  ChangeDocumentAttributeProps,
  useDocumentViewSidebarControl,
} from '@components/Sidebars/DocumentView'
import { documentActionsMap } from '@constants/documents'
import { DocumentsSetsType, DocumentsType, MimeTypes, RolesTypes } from '@constants/types'
import DownloadDocumentTooltipContent from '@containers/DownloadDocumentTooltipContent'
import { checkRoleOnExistence } from '@context/AuthContext/workers/rolesWorkers'
import { compact } from '@helpers/array/compact'
import { filterArrayIntoTwoChunks } from '@helpers/array/filterArrayIntoChunks'
import { isBoolean, isNull, isNullOrUndefined } from '@helpers/checkTypes'
import { createFileItem, createFolderItem, splitFileName } from '@helpers/fileHelpers'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import useDownloadFile from '@hooks/new/documents/useDownloadFile'
import { useVersions } from '@hooks/new/swr/useVersions'
import type {
  AttachmentErrorProps,
  IAttachmentFileItem,
  IAttachmentFolderItem,
  IDocumentItem,
} from '@interfaces/documents'
import DayjsService from '@services/Dayjs/Dayjs.service'
import type { IDocumentDictInfo, IFolderInfo } from '@services/Documents/documents.entity'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import styles from './AttachmentMultipleItem.module.scss'
import { AttachmentMultipleItemService } from './helpers'
import type {
  HandleAddFileProps,
  HandleChangeFileAttributeProps,
  HandleChangeFileNameProps,
  HandleDeleteFileProps,
  HandleDeleteFolderProps,
  HandleDownloadMultipleFileProps,
  HandleRejectedFileProps,
  HandleUpdateFileProps,
  ReplacedMultipleItem,
} from './types'

const MAX_FILE_SIZE_IN_BYTES = 200 * 1024 * 1024

const {
  isAttachmentMultipleItem,
  findMultipleItemIndexById,
  findFolderItemById,
  getFolderItemWithNewFile,
  getFolderItemWithUpdatedFile,
  getFolderItemWithoutRemovedFile,
  getAttachmentFileItem,
} = AttachmentMultipleItemService
const { convertRejectedFile, convertMultipleRejectedFile } = FileItemService
const { isSignedDocument, parseFileItemError, parseFileItemSignError } = AttachmentFileItemService

const { isDocumentReadAddSignPermission, isDocumentReadSignMainPermission } =
  DocumentsHelpersService

interface AttachmentMultipleItemProps {
  documentInfoFromDict: Omit<IDocumentDictInfo, 'isFolderAvailable' | 'isMultiple'>
  formInstance: UseFormReturn<AttachmentsFormValue>
  projectId: string
  dfoId: string
  documentSetId: string
  permissions: number
  parentSetType: string
  isChangesMadeTab?: boolean
}

const AttachmentMultipleItem: FC<AttachmentMultipleItemProps> = ({
  documentInfoFromDict,
  formInstance,
  projectId,
  dfoId,
  documentSetId,
  permissions,
  parentSetType,
  isChangesMadeTab,
}) => {
  const { isFederal, isMain, templateExists, description, documentType } = documentInfoFromDict

  const {
    handleAddFileToSet,
    handleAddFolderToSet,
    handleRemoveFolderFromSet,
    handleRemoveFileFromSet,
    handleUpdateFileFromSet,
  } = useFileContext()

  const {
    attachmentFolderApi: { handleAddFolder, handleRemoveFolder, handleRenameFolder },
    attachmentFileApi: {
      handleAddFile,
      handleRemoveFile,
      handleChangeFileName,
      handleChangeFile,
      handleForeignSign,
    },
  } = useAttachmentAPIContext()

  const {
    multipleItems,
    handleReplaceMultipleItems,
    handleUpdateFileItem,
    handleAddFileItem,
    handleRemoveFileItem,
    handleUpdateFolderItem,
    handleInsertFileItem,
    handleRemoveFolderItem,
    handleInsertFolderItem,
  } = useAttachmentMultipleItemFieldArray(formInstance)

  const { versions } = useVersions({
    key: { dfoId, projectId, _key: 'versions' },
    config: {
      isPaused: () => !dfoId || !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AttachmentMultipleItem',
          moduleName: 'AttachmentsController',
        },
      }),
    },
  })

  const versionState = useVersionController()

  const { download } = useDownloadFile()

  const { openDocumentViewSidebar } = useDocumentViewSidebarControl()
  const { handleOpenDeleteFolderModal } = useDeleteFolderModal()
  const { handleOpenErrorModal } = useErrorModal()

  const { dropzoneValidator } = useDropzoneValidation({
    maxFileSize: MAX_FILE_SIZE_IN_BYTES,
    acceptExtensions: MimeTypes['text/csv'],
  })

  const {
    isReadOnlyAttachmentPermission,
    isReadAttachmentPermission,
    isReadApproveAttachmentPermission,
    isWriteAttachmentPermission,
    isDownloadForbiddenAttachmentPermission,
    isReadDigitizingPermission,
  } = useAttachmentPermissions({
    permissions,
  })

  const isReadAddSignPermission = isDocumentReadAddSignPermission(permissions)
  const isReadSignMainPermission = isDocumentReadSignMainPermission(permissions)

  const isSignAttachmentPermission =
    (isReadAddSignPermission && documentType === DocumentsType.ADDITIONAL_DOCUMENT) ||
    (isReadSignMainPermission && isMain)

  const baseDropzoneProps: MultipleItemDropzoneProps = {
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    accept: MimeTypes,
    validator: dropzoneValidator(),
    multiple: false,
  }

  const replacedMultipleItemRef = useRef<ReplacedMultipleItem | null>(null)

  const conditionToSignedMarkRender =
    isReadOnlyAttachmentPermission && parentSetType === DocumentsSetsType.DEPARTMENTS_RESPONSES

  const isOIV = checkRoleOnExistence(RolesTypes.OIV)

  const isShowSignButton = isOIV && !isFederal && isSignAttachmentPermission

  const hasDocumentTemplateType = isWriteAttachmentPermission && templateExists

  const isStatic =
    isReadAttachmentPermission || isReadApproveAttachmentPermission || isReadDigitizingPermission

  const handleToastAlert = useCallback((text = alertTextsMap.notFoundDocumentText) => {
    toast(
      <Alert transparent variant="error">
        {text}
      </Alert>,
    )
  }, [])

  const preparedMultipleItemsState: MultipleItemReducerState = useMemo(() => {
    if (!multipleItems.length)
      return {
        items: [],
      }

    const preparedItems: CombinedMultipleItem[] = multipleItems.map((item) => {
      if (isAttachmentMultipleItem(item)) {
        const { file, rejectedFile, documentInfo, id } = item

        return {
          id,
          file,
          isDeletedFile: documentInfo?.versionChangeState === 'DELETED',
          rejectedFile,
          fileSize: documentInfo?.size ?? 0,
          errorMessage: item.error?.message || null,
          isLoading: item.isLoading,
        } as MultipleFileItem
      }

      const { name, id, documents } = item

      const preparedFilesInFolder: FolderFileItem[] = (documents ?? []).map(
        ({ id, file, rejectedFile, isLoading, error, documentInfo }) => ({
          id,
          fileState: {
            file,
            isDeletedFile: documentInfo?.versionChangeState === 'DELETED',
            rejectedFile,
            fileSize: documentInfo?.size ?? 0,
            errorMessage: error?.message || null,
            isLoading,
          },
        }),
      )

      return {
        id,
        folderName: name,
        files: preparedFilesInFolder,
        isFold: item.isFold,
      } as MultipleFolderItem
    })

    return {
      items: preparedItems,
    }
  }, [multipleItems])

  const signConditionOfDocuments: (boolean | boolean[])[] = useMemo(() => {
    if (!multipleItems.length) return []

    return multipleItems.map((item) => {
      if (isAttachmentMultipleItem(item)) return isSignedDocument(item.documentInfo)

      return item.documents.map((document) => isSignedDocument(document.documentInfo))
    })
  }, [multipleItems])

  const readOnlyOfDocuments: (boolean | boolean[])[] = useMemo(() => {
    if (!multipleItems.length) return []

    return multipleItems.map((item) => {
      if (isAttachmentMultipleItem(item)) return isStatic || !!item.documentInfo?.isApproved

      return item.documents.map(({ documentInfo }) => isStatic || !!documentInfo?.isApproved)
    })
  }, [isStatic, multipleItems])

  const handleToggleFold = useCallback(
    (item: MultipleFolderItem) => () => {
      const folderIndex = findMultipleItemIndexById(multipleItems, item.id)

      const currentFolder = multipleItems[folderIndex] as IAttachmentFolderItem

      handleUpdateFolderItem({
        folderIndex,
        folder: {
          ...currentFolder,
          isFold: !currentFolder.isFold,
        },
      })
    },
    [handleUpdateFolderItem, multipleItems],
  )

  const handleFolderAdd = useCallback(async () => {
    let currentMultipleItems = formInstance.getValues(attachmentsFormNames.multipleItems)

    const [checkedDocuments, updatedMultipleItems] = filterArrayIntoTwoChunks(
      currentMultipleItems,
      (item) => !!(isAttachmentMultipleItem(item) && item?.documentInfo && item?.checked),
    ) as [IAttachmentFileItem[], TMultipleItem[]]

    const preparedCheckedDocuments: IAttachmentFileItem[] = checkedDocuments.map(
      ({ checked, ...restDocument }) => restDocument,
    )
    const allCheckedDocumentInfoIds = checkedDocuments.map(({ documentInfo }) => documentInfo?.id)

    const createdFolder = createFolderItem({
      documents: preparedCheckedDocuments,
    })

    currentMultipleItems = [...updatedMultipleItems, createdFolder]

    handleReplaceMultipleItems(currentMultipleItems)

    const folderIndex = findMultipleItemIndexById(currentMultipleItems, createdFolder.id)

    try {
      const folderInfo = await handleAddFolder({
        name: createdFolder.name,
        documentSetId,
        documentType,
        documentIds: allCheckedDocumentInfoIds,
      })

      if (!folderInfo) return

      const updateDt = DayjsService.dayjsWithFormatToMSK().toISOString()

      const updatedFolder: IAttachmentFolderItem = {
        ...createdFolder,
        folderId: folderInfo.id,
        updateDt,
      }

      handleUpdateFolderItem({
        folderIndex,
        folder: updatedFolder,
      })

      const preparedFolder: IFolderInfo = {
        id: folderInfo.id,
        name: updatedFolder.name,
        updateDt,
        documents: compact(
          preparedCheckedDocuments.map((document) => document.documentInfo),
        ) as IDocumentItem[],
      }

      handleAddFolderToSet?.({
        type: documentType,
        folder: preparedFolder,
      })
    } catch (error) {
      handleReplaceMultipleItems(multipleItems)

      throw error
    }
  }, [
    documentSetId,
    documentType,
    formInstance,
    handleAddFolder,
    handleAddFolderToSet,
    handleReplaceMultipleItems,
    handleUpdateFolderItem,
    multipleItems,
  ])

  const handleAddNewFile = useCallback(
    async ({ folder, file }: HandleAddFileProps) => {
      const createdAttachmentFileItem = createFileItem({
        file,
        isLoading: true,
        checked: !folder?.id ? false : undefined,
      })

      let updatedAttachmentFileItem = createdAttachmentFileItem
      let updatedFolder = getFolderItemWithNewFile(updatedAttachmentFileItem, folder)

      handleAddFileItem({
        attachmentFileItem: createdAttachmentFileItem,
        folder: updatedFolder,
      })

      try {
        const document = await handleAddFile({
          file,
          folderId: folder?.folderId,
          documentSetId,
          type: documentType,
        })

        if (!document) return

        const { type, ...documentInfo } = document

        updatedAttachmentFileItem = {
          ...updatedAttachmentFileItem,
          documentInfo,
        }
        updatedFolder = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, updatedFolder)

        handleUpdateFileItem({
          attachmentFileItem: updatedAttachmentFileItem,
          folder: updatedFolder,
        })

        handleAddFileToSet?.({
          document: documentInfo,
          type,
          folderId: updatedFolder?.folderId,
        })
      } catch (error) {
        const preparedError: AttachmentErrorProps = {
          actionType: documentActionsMap.ADD,
          message: parseFileItemError(error),
        }

        updatedAttachmentFileItem = {
          ...createdAttachmentFileItem,
          error: preparedError,
        }
        updatedFolder = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, updatedFolder)

        handleUpdateFileItem({
          attachmentFileItem: updatedAttachmentFileItem,
          folder: updatedFolder,
        })

        throw error
      } finally {
        updatedAttachmentFileItem = {
          ...updatedAttachmentFileItem,
          isLoading: false,
        }
        updatedFolder = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, updatedFolder)

        handleUpdateFileItem({
          attachmentFileItem: updatedAttachmentFileItem,
          folder: updatedFolder,
        })
      }
    },
    [
      documentSetId,
      documentType,
      handleAddFile,
      handleAddFileItem,
      handleAddFileToSet,
      handleUpdateFileItem,
    ],
  )

  const handleUpdateFile = useCallback(
    async ({ attachmentFileItem, updatedFile, folder }: HandleUpdateFileProps) => {
      const { documentInfo } = attachmentFileItem

      if (!documentInfo) return handleToastAlert()

      let updatedAttachmentFileItem: IAttachmentFileItem = {
        ...attachmentFileItem,
        isLoading: true,
        error: null,
      }

      handleUpdateFileItem({
        attachmentFileItem: updatedAttachmentFileItem,
        folder,
      })

      const [fileName, fileExtension] = splitFileName(updatedFile.name)

      updatedAttachmentFileItem = {
        ...updatedAttachmentFileItem,
        file: updatedFile,
        documentInfo: {
          ...documentInfo,
          name: fileName,
          extension: fileExtension,
          size: updatedFile.size,
        },
      }

      let updatedFolder = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, folder)

      handleUpdateFileItem({
        attachmentFileItem: updatedAttachmentFileItem,
        folder: updatedFolder,
      })

      try {
        const updatedDocumentInfo = await handleChangeFile({
          fileId: documentInfo?.id,
          file: updatedFile,
          documentSetId,
        })

        if (!updatedDocumentInfo) return

        updatedAttachmentFileItem = {
          ...updatedAttachmentFileItem,
          documentInfo: updatedDocumentInfo,
        }

        updatedFolder = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, updatedFolder)

        handleUpdateFileItem({
          attachmentFileItem: updatedAttachmentFileItem,
          folder: updatedFolder,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
          folderId: updatedFolder?.folderId,
        })

        replacedMultipleItemRef.current = null
      } catch (error) {
        const preparedError: AttachmentErrorProps = {
          actionType: documentActionsMap.UPDATE,
          message: parseFileItemError(error),
        }

        replacedMultipleItemRef.current = {
          attachmentFileItem: {
            ...updatedAttachmentFileItem,
            file: updatedFile,
            error: preparedError,
          },
          folder: updatedFolder,
        }

        updatedAttachmentFileItem = {
          ...attachmentFileItem,
          error: preparedError,
        }
        updatedFolder = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, updatedFolder)

        handleUpdateFileItem({
          attachmentFileItem: updatedAttachmentFileItem,
          folder: updatedFolder,
        })

        throw error
      } finally {
        updatedAttachmentFileItem = {
          ...updatedAttachmentFileItem,
          isLoading: false,
        }
        updatedFolder = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, updatedFolder)

        handleUpdateFileItem({
          attachmentFileItem: updatedAttachmentFileItem,
          folder: updatedFolder,
        })
      }
    },
    [
      documentSetId,
      documentType,
      handleChangeFile,
      handleToastAlert,
      handleUpdateFileFromSet,
      handleUpdateFileItem,
    ],
  )

  const handleRejectedFile = useCallback(
    ({ rejectedFiles, files, attachmentFileItem, folder }: HandleRejectedFileProps) => {
      if (!folder) return

      let preparedRejectedFile: RejectedFile | null = null

      if (
        rejectedFiles.length > 1 ||
        !!files?.length ||
        (rejectedFiles.length && !!files?.length)
      ) {
        preparedRejectedFile = convertMultipleRejectedFile(files?.[0] || rejectedFiles[0].file)
      } else {
        preparedRejectedFile = convertRejectedFile(rejectedFiles[0])
      }

      if (!replacedMultipleItemRef.current && attachmentFileItem) {
        const { file, documentInfo, ...restAttachmentFileItem } = attachmentFileItem

        const rejectedAttachmentFileItem: IAttachmentFileItem = {
          ...restAttachmentFileItem,
          file: null,
          rejectedFile: preparedRejectedFile,
        }

        const updatedFolder = getFolderItemWithUpdatedFile(rejectedAttachmentFileItem, folder)

        return handleUpdateFileItem({
          attachmentFileItem: rejectedAttachmentFileItem,
          folder: updatedFolder,
        })
      }

      if (!replacedMultipleItemRef.current || !attachmentFileItem) {
        const attachmentFileItem = createFileItem({
          rejectedFile: preparedRejectedFile,
        })

        const updatedFolder = getFolderItemWithNewFile(attachmentFileItem, folder)

        return handleAddFileItem({
          attachmentFileItem,
          folder: updatedFolder,
        })
      }

      const { attachmentFileItem: replacedAttachmentFileItem, folder: replacedFolder } =
        replacedMultipleItemRef.current
      const { file, documentInfo, ...restAttachmentFileItem } = replacedAttachmentFileItem

      const rejectedAttachmentItem: IAttachmentFileItem = {
        ...restAttachmentFileItem,
        file: null,
        rejectedFile: preparedRejectedFile,
      }

      const updatedFolder = getFolderItemWithUpdatedFile(rejectedAttachmentItem, replacedFolder)

      handleUpdateFileItem({
        attachmentFileItem: rejectedAttachmentItem,
        folder: updatedFolder,
      })
    },
    [handleAddFileItem, handleUpdateFileItem],
  )

  const handleRejectDropFile = useCallback(
    (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) =>
      async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        const { attachmentFileItem, attachmentFileIndex, folder } = getAttachmentFileItem(
          multipleItems,
          item,
          folderFileItem,
        )

        if (fileRejections.length || acceptedFiles.length > 1) {
          return handleRejectedFile({
            rejectedFiles: fileRejections,
            files: acceptedFiles,
            attachmentFileItem,
            folder,
          })
        }

        const fileToUploadWithCurrentDate = new File([acceptedFiles[0]], acceptedFiles[0].name)

        if (!attachmentFileItem || attachmentFileIndex === -1) return handleToastAlert()

        const folderItem = getFolderItemWithoutRemovedFile(attachmentFileItem.id, folder)

        handleRemoveFileItem({
          attachmentFileItem,
          folder: folderItem,
        })

        await handleAddNewFile({
          file: fileToUploadWithCurrentDate,
          folder: folderItem,
        })
      },
    [handleAddNewFile, handleRejectedFile, handleRemoveFileItem, handleToastAlert, multipleItems],
  )

  const handleDropFile = useCallback(
    (item?: MultipleFolderItem) =>
      async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length || acceptedFiles.length > 1) {
          const foundFolder = findFolderItemById(multipleItems, item?.id)

          return handleRejectedFile({
            rejectedFiles: fileRejections,
            files: acceptedFiles,
            folder: foundFolder,
          })
        }

        const fileToUploadWithCurrentDate = new File([acceptedFiles[0]], acceptedFiles[0].name)

        if (!replacedMultipleItemRef.current) {
          const foundFolder = findFolderItemById(multipleItems, item?.id)

          return handleAddNewFile({
            file: fileToUploadWithCurrentDate,
            folder: foundFolder,
          })
        }

        return handleUpdateFile({
          ...replacedMultipleItemRef.current,
          updatedFile: fileToUploadWithCurrentDate,
        })
      },
    [handleAddNewFile, handleRejectedFile, handleUpdateFile, multipleItems],
  )

  const handleDeleteFolder = useCallback(
    ({ folder, folderIndex }: HandleDeleteFolderProps) =>
      async () => {
        handleRemoveFolderItem({
          folderIndex,
        })

        try {
          await handleRemoveFolder({
            folderId: folder.folderId || '',
            documentSetId,
          })

          handleRemoveFolderFromSet?.({
            folderId: folder.folderId || '',
            type: documentType,
          })
        } catch (error) {
          handleInsertFolderItem({
            folder,
            folderIndex,
          })

          throw error
        }
      },
    [
      documentSetId,
      documentType,
      handleInsertFolderItem,
      handleRemoveFolder,
      handleRemoveFolderFromSet,
      handleRemoveFolderItem,
    ],
  )

  const handleDeleteFile = useCallback(
    ({ attachmentFileItem, attachmentFileIndex, folder }: HandleDeleteFileProps) =>
      async () => {
        const { id } = attachmentFileItem.documentInfo || {}

        const folderItem = getFolderItemWithoutRemovedFile(attachmentFileItem.id, folder)

        handleRemoveFileItem({
          attachmentFileItem,
          folder: folderItem,
        })

        try {
          await handleRemoveFile({
            fileId: id || '',
            documentSetId,
          })

          handleRemoveFileFromSet?.({
            documentId: id || '',
            type: documentType,
            folderId: folder?.folderId,
          })
        } catch (error) {
          const preparedError: AttachmentErrorProps = {
            actionType: documentActionsMap.DELETE,
            message: parseFileItemError(error),
          }

          handleInsertFileItem({
            attachmentFileItem: {
              ...attachmentFileItem,
              error: preparedError,
            },
            attachmentFileIndex,
            folder: folderItem,
          })

          throw error
        }
      },
    [
      documentSetId,
      documentType,
      handleInsertFileItem,
      handleRemoveFile,
      handleRemoveFileFromSet,
      handleRemoveFileItem,
    ],
  )

  const handleDeleteFolderWithModal = useCallback(
    (item: MultipleFolderItem) => async () => {
      const folderIndex = findMultipleItemIndexById(multipleItems, item.id)
      const folder = multipleItems[folderIndex] as IAttachmentFolderItem | undefined

      if (!folder || !folder.folderId || folderIndex === -1)
        return handleToastAlert(alertTextsMap.notFoundFolderText)

      handleOpenDeleteFolderModal({
        header: 'Подтверждение удаления папки',
        description: `Вы действительно хотите удалить папку "${folder.name}" и всё её содержимое?`,
        onDelete: handleDeleteFolder({
          folder,
          folderIndex,
        }),
      })
    },
    [handleDeleteFolder, handleOpenDeleteFolderModal, handleToastAlert, multipleItems],
  )

  const handleDeleteFileWithModal = useCallback(
    (item: CombinedMultipleItem) => async (folderFileItem?: FolderFileItem) => {
      const { attachmentFileItem, attachmentFileIndex, folder } = getAttachmentFileItem(
        multipleItems,
        item,
        folderFileItem,
      )

      if (!attachmentFileItem || !attachmentFileItem.documentInfo) return handleToastAlert()

      const preparedFileNameText = attachmentFileItem.file?.name
        ? ` "${attachmentFileItem.file.name}"`
        : ''

      handleOpenDeleteFolderModal({
        header: 'Подтверждение удаления документа',
        description: `Вы действительно хотите удалить документ${preparedFileNameText}?`,
        onDelete: handleDeleteFile({
          attachmentFileItem,
          attachmentFileIndex,
          folder,
        }),
      })
    },
    [handleDeleteFile, handleOpenDeleteFolderModal, handleToastAlert, multipleItems],
  )

  const handleChangeFileNameOnBlur = useCallback(
    ({ attachmentFileItem, attachmentFileIndex, folder }: HandleChangeFileNameProps) =>
      async (event: FocusEvent<HTMLInputElement>) => {
        const { documentInfo, file, ...restAttachment } = attachmentFileItem

        if (!documentInfo || !file || attachmentFileIndex === -1) return handleToastAlert()

        const { value } = event.target

        const [_fileName, fileExtension] = splitFileName(file.name)

        const updatedFile = new File([file], `${value}.${fileExtension}`)
        const updatedDocumentInfo = {
          ...documentInfo,
          name: value,
        }

        const updatedAttachmentFileItem: IAttachmentFileItem = {
          ...restAttachment,
          file: updatedFile,
          documentInfo: updatedDocumentInfo,
        }

        const folderItem = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, folder)

        handleUpdateFileItem({
          attachmentFileItem: updatedAttachmentFileItem,
          folder: folderItem,
        })

        try {
          await handleChangeFileName?.({
            fileId: documentInfo.id,
            documentSetId,
            name: value,
          })

          handleUpdateFileFromSet?.({
            document: updatedDocumentInfo,
            type: documentType,
            folderId: folderItem?.folderId,
          })
        } catch (error) {
          handleUpdateFileItem({
            attachmentFileItem,
            folder,
          })

          throw error
        }
      },
    [
      documentSetId,
      documentType,
      handleChangeFileName,
      handleToastAlert,
      handleUpdateFileFromSet,
      handleUpdateFileItem,
    ],
  )

  const handleChangeFileAttribute = useCallback(
    ({ attachmentFileItem, folder }: HandleChangeFileAttributeProps) =>
      ({ attributeName, value }: ChangeDocumentAttributeProps) => {
        if (!attachmentFileItem.documentInfo) return handleToastAlert()

        const updatedDocumentInfo: IAttachmentFileItem['documentInfo'] = {
          ...attachmentFileItem.documentInfo,
          [attributeName]: value,
        }

        const updatedAttachmentFileItem: IAttachmentFileItem = {
          ...attachmentFileItem,
          documentInfo: updatedDocumentInfo,
        }

        const updatedFolder = getFolderItemWithUpdatedFile(updatedAttachmentFileItem, folder)

        handleUpdateFileItem({
          attachmentFileItem: updatedAttachmentFileItem,
          folder: updatedFolder,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
        })
      },
    [documentType, handleToastAlert, handleUpdateFileFromSet, handleUpdateFileItem],
  )

  const handleOpenDocumentViewSidebar = useCallback(
    (item: CombinedMultipleItem) => (folderFileItem?: FolderFileItem) => () => {
      const { attachmentFileItem, attachmentFileIndex, folder } = getAttachmentFileItem(
        multipleItems,
        item,
        folderFileItem,
      )

      if (!attachmentFileItem || !attachmentFileItem.documentInfo) return handleToastAlert()

      const { documentInfo, error } = attachmentFileItem

      if (!!error || documentInfo?.versionChangeState === 'DELETED') return

      openDocumentViewSidebar({
        isApproved: documentInfo.isApproved,
        isStatic: isStatic || !!documentInfo?.isApproved,
        accessToApprove: isReadApproveAttachmentPermission,
        documentId: documentInfo.id,
        projectId,
        dfoId,
        documentSetId,
        typeOfDocument: documentInfo.name,
        edit: {
          value: documentInfo.name,
          onBlur: handleChangeFileNameOnBlur({
            attachmentFileItem,
            attachmentFileIndex,
            folder,
          }),
        },
        onChangeDocumentAttribute: handleChangeFileAttribute({ attachmentFileItem, folder }),
      })
    },
    [
      dfoId,
      documentSetId,
      handleChangeFileAttribute,
      handleChangeFileNameOnBlur,
      handleToastAlert,
      isReadApproveAttachmentPermission,
      isStatic,
      multipleItems,
      openDocumentViewSidebar,
      projectId,
    ],
  )

  const handleChangeFolderNameOnBlur = useCallback(
    (item: MultipleFolderItem) => async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      const folderIndex = findMultipleItemIndexById(multipleItems, item.id)
      const folder = multipleItems[folderIndex] as IAttachmentFolderItem | undefined

      if (!folder || !folder.folderId || folderIndex === -1)
        return handleToastAlert(alertTextsMap.notFoundFolderText)

      handleUpdateFolderItem({
        folderIndex,
        folder: {
          ...folder,
          name: value,
        },
      })

      try {
        await handleRenameFolder({
          folderId: folder.folderId,
          folderName: value,
          documentSetId,
        })
      } catch (error) {
        handleUpdateFolderItem({
          folderIndex,
          folder,
        })

        formInstance.clearErrors(`${attachmentsFormNames.multipleItems}.${folderIndex}.name`)

        throw error
      }
    },
    [
      documentSetId,
      formInstance,
      handleRenameFolder,
      handleToastAlert,
      handleUpdateFolderItem,
      multipleItems,
    ],
  )

  const handleReplaceFile = useCallback(
    (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) => {
      const { attachmentFileItem, folder } = getAttachmentFileItem(
        multipleItems,
        item,
        folderFileItem,
      )

      if (!attachmentFileItem) return handleToastAlert()

      replacedMultipleItemRef.current = {
        attachmentFileItem,
        folder,
      }
    },
    [handleToastAlert, multipleItems],
  )

  const handleDownloadFile = useCallback(
    (props?: HandleDownloadMultipleFileProps) =>
      async (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) => {
        const { withSigned = false, event } = props || {}

        event?.stopPropagation()

        const { attachmentFileItem } = getAttachmentFileItem(multipleItems, item, folderFileItem)

        if (!attachmentFileItem || !attachmentFileItem.documentInfo) return handleToastAlert()

        const { name, extension, id, versionId } = attachmentFileItem.documentInfo

        const fileNameWithExtension = `${name}.${extension}`

        return withDownloadToastPromise(
          download({
            documentSetId,
            projectId,
            fileId: id,
            fileNameWithExtension,
            versionId,
            withSigned,
            type: 'save',
          }),
        )
      },
    [documentSetId, download, handleToastAlert, multipleItems, projectId],
  )

  const handleDropFileSign = useCallback(
    (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) =>
      async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (!acceptedFiles.length || rejectedFiles.length) return

        const { attachmentFileItem } = getAttachmentFileItem(multipleItems, item, folderFileItem)

        const { documentInfo } = attachmentFileItem || {}

        if (!documentInfo) return handleToastAlert()

        const sigFile = acceptedFiles[0]

        try {
          await handleForeignSign({
            sigFile,
            documentSetId,
            documentId: documentInfo.id,
          })
        } catch (error) {
          const { headerText, bodyText } = parseFileItemSignError(error)

          handleOpenErrorModal({
            headerText,
            bodyText,
          })

          throw error
        }
      },
    [documentSetId, handleForeignSign, handleOpenErrorModal, handleToastAlert, multipleItems],
  )

  const handleReloadFile = useCallback(
    async (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) => {
      const { attachmentFileItem, attachmentFileIndex, folder } = getAttachmentFileItem(
        multipleItems,
        item,
        folderFileItem,
      )

      if (!attachmentFileItem || !attachmentFileItem.file || attachmentFileIndex === -1)
        return handleToastAlert()

      const { actionType } = attachmentFileItem.error || {}

      if (actionType === documentActionsMap.ADD) {
        const file = attachmentFileItem.file

        const updatedFolder = getFolderItemWithoutRemovedFile(attachmentFileItem.id, folder)

        handleRemoveFileItem({
          attachmentFileItem,
          folder: updatedFolder,
        })

        return handleAddNewFile({
          file,
          folder: updatedFolder,
        })
      }

      if (actionType === documentActionsMap.DELETE) {
        return handleDeleteFile({
          attachmentFileItem,
          attachmentFileIndex,
          folder,
        })()
      }

      if (actionType === documentActionsMap.UPDATE) {
        const replacedAttachmentFile = replacedMultipleItemRef.current?.attachmentFileItem
        if (!replacedAttachmentFile || !replacedAttachmentFile.file) return

        return handleUpdateFile({
          attachmentFileItem,
          folder,
          updatedFile: replacedAttachmentFile.file,
        })
      }
    },
    [
      handleAddNewFile,
      handleDeleteFile,
      handleRemoveFileItem,
      handleToastAlert,
      handleUpdateFile,
      multipleItems,
    ],
  )

  const handleRemoveNotLoadedFile = useCallback(
    (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) => {
      const { attachmentFileItem, folder } = getAttachmentFileItem(
        multipleItems,
        item,
        folderFileItem,
      )

      if (!attachmentFileItem || !attachmentFileItem.file) return handleToastAlert()

      const { actionType } = attachmentFileItem.error || {}

      if (actionType === documentActionsMap.ADD) {
        const updatedFolder = getFolderItemWithoutRemovedFile(attachmentFileItem.id, folder)

        return handleRemoveFileItem({
          attachmentFileItem,
          folder: updatedFolder,
        })
      }

      if (actionType === documentActionsMap.UPDATE) {
        replacedMultipleItemRef.current = null
      }

      const attachmentFileItemWithoutError: IAttachmentFileItem = {
        ...attachmentFileItem,
        error: null,
      }

      const updatedFolder = getFolderItemWithUpdatedFile(attachmentFileItemWithoutError, folder)

      handleUpdateFileItem({
        attachmentFileItem: attachmentFileItemWithoutError,
        folder: updatedFolder,
      })
    },
    [handleRemoveFileItem, handleToastAlert, handleUpdateFileItem, multipleItems],
  )

  const renderLeadingAdditionProps = useCallback(
    (
      item: CombinedMultipleItem,
      folderFileItem?: FolderFileItem,
    ): FileItemLeadingAdditionProps | undefined => {
      const { attachmentFileItem, attachmentFileIndex, folder } = getAttachmentFileItem(
        multipleItems,
        item,
        folderFileItem,
      )

      const { documentInfo } = attachmentFileItem || {}

      const isDeletedVersionDocument = documentInfo?.versionChangeState === 'DELETED'

      const conditionToCheckboxRender =
        !isDeletedVersionDocument && !folder && !attachmentFileItem?.error && !isStatic

      const preparedStatusProps: FileItemLeadingAdditionProps['statusProps'] =
        documentInfo && !isNullOrUndefined(documentInfo.isApproved)
          ? {
              statusType: documentInfo.isApproved
                ? statusTypesMap.APPROVED
                : statusTypesMap.REJECTED,
            }
          : undefined

      return {
        additionalLeadingNodeContent: conditionToCheckboxRender && (
          <ControlledCheckbox
            control={formInstance.control}
            name={`${attachmentsFormNames.multipleItems}.${attachmentFileIndex}.checked`}
            checkBoxProps={{
              labelWrapperProps: {
                onClick: (event) => event.stopPropagation(),
              },
            }}
          />
        ),
        statusProps: preparedStatusProps,
      }
    },
    [formInstance.control, isStatic, multipleItems],
  )

  const renderTrailingAdditionProps = useCallback(
    (
      item: CombinedMultipleItem,
      folderFileItem?: FolderFileItem,
    ): FileItemTrailingAdditionProps | undefined => {
      const { attachmentFileItem } = getAttachmentFileItem(multipleItems, item, folderFileItem)

      if (!attachmentFileItem) return

      const { isEditable, versionChangeState, version } = attachmentFileItem.documentInfo || {}

      const isDepartmentResponseSigned =
        conditionToSignedMarkRender && isBoolean(isEditable) && !isEditable

      const hasCorrectVersionsLength = (versions?.length ?? 0) > 1

      const filteredVersionChangeState =
        isChangesMadeTab || hasCorrectVersionsLength ? versionChangeState : undefined

      const isDeletedVersionDocument = filteredVersionChangeState === 'DELETED'
      const isFixedVersionDocument = filteredVersionChangeState === 'FIXED'

      const showVersionChangeState =
        (isChangesMadeTab || versionState.versionNumber > 1) &&
        !!filteredVersionChangeState &&
        !isFixedVersionDocument &&
        !isDeletedVersionDocument

      return {
        sizeClassName: cn({
          [trailingNodeStyles['trailingNode__item-size--small']]:
            isDepartmentResponseSigned || showVersionChangeState,
        }),
        trailingNode: <TrailingAdditionInTrailingNode version={version} />,
        additionalTrailingNode: isDeletedVersionDocument && (
          <Typography.Body variant="bodySMedium" color={'text-base-tertiary'}>
            Документ был удален инвестором
          </Typography.Body>
        ),
        leadingNode: !isDeletedVersionDocument && (
          <LeadingAdditionInTrailingNode
            readOnly={isStatic && isNull(attachmentFileItem.documentInfo?.isApproved)}
            isDepartmentResponseSigned={isDepartmentResponseSigned}
            versionChangeState={versionChangeState}
            versionNumber={versionState.versionNumber}
          />
        ),
      }
    },
    [
      conditionToSignedMarkRender,
      isChangesMadeTab,
      isStatic,
      multipleItems,
      versionState.versionNumber,
      versions?.length,
    ],
  )

  const handleCheckDeletedVersionDocument = useCallback(
    (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) => {
      const { attachmentFileItem } = getAttachmentFileItem(multipleItems, item, folderFileItem)

      if (!attachmentFileItem) return false

      const { versionChangeState } = attachmentFileItem.documentInfo || {}

      const hasCorrectVersionsLength = (versions?.length ?? 0) > 1

      const filteredVersionChangeState =
        isChangesMadeTab || hasCorrectVersionsLength ? versionChangeState : undefined

      return filteredVersionChangeState === 'DELETED' || isDownloadForbiddenAttachmentPermission
    },
    [multipleItems, isChangesMadeTab, isDownloadForbiddenAttachmentPermission, versions?.length],
  )

  const prepareFolderNameProps = useCallback(
    (item: MultipleFolderItem): FolderNameProps<AttachmentsFormValue> | undefined => {
      const folderIndex = findMultipleItemIndexById(multipleItems, item.id)

      if (folderIndex === -1) return

      return {
        readOnlyFolderName: isStatic,
        controllerFolderNameProps: {
          name: `${attachmentsFormNames.multipleItems}.${folderIndex}.name`,
          control: formInstance.control,
          rules: folderNameRulesMap,
        },
      }
    },
    [formInstance.control, isStatic, multipleItems],
  )

  return (
    <MultipleItem
      multipleItemsState={preparedMultipleItemsState}
      readOnly={isStatic}
      readOnlyItems={readOnlyOfDocuments}
      title={description}
      trailingAdditionProps={renderTrailingAdditionProps}
      leadingAdditionProps={renderLeadingAdditionProps}
      folderNameProps={prepareFolderNameProps}
      hasDocumentTemplateType={hasDocumentTemplateType}
      folderDataTestId="AttachmentMultipleItem-folder"
      dataTestId={`${documentType}.AttachmentMultipleItem-items`}
      isDownloadForbidden={isDownloadForbiddenAttachmentPermission}
      checkMultipleItemDownloadForbidden={handleCheckDeletedVersionDocument}
      entityProps={{
        className: styles['multiple-item__entity-item'],
        onClick: handleOpenDocumentViewSidebar,
        dataTestId: 'AttachmentMultipleItem-attachment',
      }}
      downloadProps={{
        signConditionOfDocuments,
        onFileDownload: handleDownloadFile(),
        downloadTooltipContent: (item, folderFileItem) => (
          <DownloadDocumentTooltipContent
            handleDownloadFile={(event) => handleDownloadFile({ event })(item, folderFileItem)}
            handleDownloadFileWithStamps={(event) =>
              handleDownloadFile({
                event,
                withSigned: true,
              })(item, folderFileItem)
            }
          />
        ),
      }}
      signFileProps={{
        signFileTooltipContent: 'Добавить открепленную подпись',
        onFileDropSign: isShowSignButton ? handleDropFileSign : undefined,
      }}
      dropzoneProps={{
        ...baseDropzoneProps,
        onFileDrop: handleDropFile,
        onRejectFileDrop: handleRejectDropFile,
      }}
      onFileRemoveNotLoaded={handleRemoveNotLoadedFile}
      onFileReload={handleReloadFile}
      onFoldUnfold={handleToggleFold}
      onFileRemove={handleDeleteFileWithModal}
      onFolderAdd={handleFolderAdd}
      onFolderRename={handleChangeFolderNameOnBlur}
      onFolderRemove={handleDeleteFolderWithModal}
      onFileReplace={handleReplaceFile}
    />
  )
}

export default AttachmentMultipleItem
