import { useAPIContext } from '@context/APIContext'
import { INotification } from '@context/APIContext/hooks/useNotificationsApi'
import { SWRHookProps } from '@interfaces'
import useSWR from 'swr'

export interface UseNotifications extends SWRHookProps {
  page: number
}

export const useNotifications = ({ config, key, page }: UseNotifications) => {
  const {
    notificationsApi: { getNotifications },
  } = useAPIContext()

  const {
    data,
    error,
    mutate,
    isValidating: isNotificationsLoading,
  } = useSWR<INotification[]>(key, () => getNotifications(page), config)

  return {
    notifications: data,
    error,
    mutate,
    isNotificationsLoading,
  }
}
