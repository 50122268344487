import React from 'react'
import { FieldValues, Path } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { BaseSectionProps } from '@components/DocumentFormComponents/FormSections/types'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

interface InvestorSectionProps<T extends FieldValues> extends BaseSectionProps<T> {
  investorFullNameSuptitle?: string
  fullNameGenitiveRenderCondition?: boolean
  fullNameInstrumentalRenderCondition?: boolean
  nameOrganizationChangeTooltipRenderCondition?: boolean
  authorizedPersonGroupRenderCondition?: boolean
}

const InvestorSection = <T extends FieldValues>({
  fullNameGenitiveRenderCondition = false,
  fullNameInstrumentalRenderCondition = false,
  nameOrganizationChangeTooltipRenderCondition = true,
  authorizedPersonGroupRenderCondition = true,
  investorFullNameSuptitle,
  editMode,
  blockViewIsValidating,
  formInstance,
  formName,
  watcher,
  subscribableControl,
  onChangeValue,
  debouncedChangeValue,
}: InvestorSectionProps<T>) => {
  const {
    getSubscribableControlProps,
    getControllerProps,
    getTextareaProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher,
    formInstance,
  })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.investorFullName` as Path<T>,
              })}
            >
              <ControlledDocumentDataView
                suptitle={investorFullNameSuptitle ?? 'Полное наименование'}
                controllerProps={getControllerProps({
                  name: `${formName}.investorFullName` as Path<T>,
                })}
                formIconTooltipProps={
                  nameOrganizationChangeTooltipRenderCondition
                    ? {
                        tooltipContent:
                          'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                      }
                    : undefined
                }
              />
            </SubscribableControl>
          </Col>
        </Row>
        {fullNameGenitiveRenderCondition && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorFullNameGenitive` as Path<T>,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.investorFullNameGenitive` as Path<T>,
                    rules: {
                      required: defaultRHFValidation.required,
                      validate: (value) => isString(value) && lengthValidate(value, 1050),
                    },
                    textareaProps: {
                      label: 'Полное наименование в родительном падеже',
                    },
                    onBlur: () =>
                      setTimeout(
                        () => onChangeValue?.(`${formName}.investorFullNameGenitive` as Path<T>),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedChangeValue?.(`${formName}.investorFullNameGenitive` as Path<T>)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        {fullNameInstrumentalRenderCondition && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorFullNameInstrumental` as Path<T>,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.investorFullNameInstrumental` as Path<T>,
                    rules: {
                      required: defaultRHFValidation.required,
                      validate: (value) => isString(value) && lengthValidate(value, 1050),
                    },
                    textareaProps: {
                      label: 'Полное наименование в творительном падеже',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          onChangeValue?.(`${formName}.investorFullNameInstrumental` as Path<T>),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedChangeValue?.(
                          `${formName}.investorFullNameInstrumental` as Path<T>,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.investorInn` as Path<T>,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: `${formName}.investorInn` as Path<T>,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.investorOgrn` as Path<T>,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: `${formName}.investorOgrn` as Path<T>,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.investorAddress` as Path<T>,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Юридический адрес'}
                controllerProps={getControllerProps({
                  name: `${formName}.investorAddress` as Path<T>,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        {authorizedPersonGroupRenderCondition && (
          <AuthorizedOfficialGroup
            groupTitle={'Уполномоченное лицо организации'}
            formInstance={formInstance}
            formSection={formName}
            prefixFormFieldName={'investor'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.investorHeaderName` as Path<T>,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.headerName || '',
                        value: item.id,
                      }),
                      selectProps: {
                        inputProps: {
                          label: 'Фамилия, Имя, Отчество',
                        },
                        onChangeFormValue: () => {
                          setTimeout(() => {
                            onChangeValue?.(`${formName}.investorHeaderName` as Path<T>)
                          }, 0)
                        },
                      },
                      controllerProps: {
                        name: `${formName}.investorHeaderName` as Path<T>,
                        rules: {
                          required: defaultRHFValidation.required,
                        },
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </AuthorizedOfficialGroup>
        )}
      </Stack>
    </Container>
  )
}

export default InvestorSection
