import { FC } from 'react'

import One from './1'
import Two from './2'
import Three from './3'
import Four from './4'
import Five from './5'
import Six from './6'
import Seven from './7'

interface SupplementalCostSharingAFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Three: typeof Three
  Four: typeof Four
  Five: typeof Five
  Six: typeof Six
  Seven: typeof Seven
}

const SupplementalCostSharingAForms: SupplementalCostSharingAFormsProps = () => null

SupplementalCostSharingAForms.One = One
SupplementalCostSharingAForms.Two = Two
SupplementalCostSharingAForms.Three = Three
SupplementalCostSharingAForms.Four = Four
SupplementalCostSharingAForms.Five = Five
SupplementalCostSharingAForms.Six = Six
SupplementalCostSharingAForms.Seven = Seven

export default SupplementalCostSharingAForms
