import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AAgreementForceCircumstancesLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import AAgreementForceCircumstancesModalManager from './Manager'
import AAgreementForceCircumstancesMenu from './Menu'
import AAgreementForceCircumstancesStatementForm from './Statement'

interface IAAgreementForceCircumstances extends FC {
  LayoutWithFormProvider: typeof AAgreementForceCircumstancesLayoutWithFormProvider
  Menu: typeof AAgreementForceCircumstancesMenu
  StatementForm: typeof AAgreementForceCircumstancesStatementForm
  Manager: typeof AAgreementForceCircumstancesModalManager
}

const useAAgreementForceCircumstancesControl = () => {
  const popupManager = usePopupManager()

  const openAAgreementForceCircumstancesModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AAgreementForceCircumstancesLayoutWithFormProvider, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAAgreementForceCircumstancesModal }
}

const AAgreementForceCircumstances: IAAgreementForceCircumstances = () => null

AAgreementForceCircumstances.Menu = AAgreementForceCircumstancesMenu
AAgreementForceCircumstances.LayoutWithFormProvider = AAgreementForceCircumstancesLayoutWithFormProvider
AAgreementForceCircumstances.StatementForm = AAgreementForceCircumstancesStatementForm
AAgreementForceCircumstances.Manager = AAgreementForceCircumstancesModalManager

export { useAAgreementForceCircumstancesControl }
export default AAgreementForceCircumstances
