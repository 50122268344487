import { isNullOrUndefined } from '@helpers/checkTypes'
import {
  PropertyTypeEnum,
  TPropertyValue,
  TPropertyValueCustom,
  TPropertyValueSimple,
  TPropertyValueStructure,
} from '@services/Properties/Properties.entity'

const isPropertyValue = (obj: unknown): obj is TPropertyValue => {
  if (typeof obj !== 'object' || obj === null) return false

  const hasRequiredProps =
    '@id' in obj && '@lastUpdateAt' in obj && '@isPublic' in obj && '@type' in obj

  if (!hasRequiredProps) return false

  switch (obj['@type']) {
    case 'STRING':
    case 'DATE':
      return typeof obj['@value'] === 'string'
    case 'INT':
      return typeof obj['@value'] === 'number'
    case 'BOOLEAN':
      return typeof obj['@value'] === 'boolean'
    case 'LIST':
      return Array.isArray(obj['@value']) && obj['@value'].every(isPropertyValue)
    case 'MAP':
      return (
        obj['@value'] &&
        typeof obj['@value'] === 'object' &&
        Object.values(obj['@value']).every(isPropertyValue)
      )
    case 'MONEY':
      return typeof obj['@value'] === 'number'
    default:
      return false // Для неизвестных типов считаем, что объект не соответствует TPropertyValue
  }
}

const mapOfPropertyDefaultValues = {
  [PropertyTypeEnum.LIST]: [],
  [PropertyTypeEnum.MAP]: {},
  [PropertyTypeEnum.MONEY]: '',
  [PropertyTypeEnum.PERCENTAGE]: '',
  [PropertyTypeEnum.INT]: '',
  [PropertyTypeEnum.BOOLEAN]: false,
  [PropertyTypeEnum.STRING]: '',
  [PropertyTypeEnum.DATE]: '',
}

const generateDefaultValueByType = (
  property: TPropertyValueSimple | TPropertyValueStructure | TPropertyValueCustom,
) => {
  if (
    isNullOrUndefined(property['@value']) &&
    !property['@type'].startsWith(PropertyTypeEnum.CATALOG) &&
    !property['@type'].startsWith(PropertyTypeEnum.LOOKUP) &&
    !property['@type'].startsWith(PropertyTypeEnum.FIXED_LOOKUP) &&
    !property['@type'].startsWith(PropertyTypeEnum.ORGANIZATION) &&
    !property['@type'].startsWith(PropertyTypeEnum.NPA) &&
    !property['@type'].startsWith(PropertyTypeEnum.NPA_PART)
  ) {
    return mapOfPropertyDefaultValues[property['@type']]
  }

  if (
    isNullOrUndefined(property['@value']) &&
    (property['@type'].startsWith(PropertyTypeEnum.CATALOG) ||
      property['@type'].startsWith(PropertyTypeEnum.LOOKUP) ||
      property['@type'].startsWith(PropertyTypeEnum.FIXED_LOOKUP) ||
      property['@type'].startsWith(PropertyTypeEnum.ORGANIZATION) ||
      property['@type'].startsWith(PropertyTypeEnum.NPA) ||
      property['@type'].startsWith(PropertyTypeEnum.NPA_PART))
  )
    return {}

  return property['@value']
}
export { generateDefaultValueByType, isPropertyValue }
