import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForParametersApplicationReimbursementExpensesMenuState } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/adapters/Menu.adapter'
import { generatePropertiesObjectForParametersApplicationReimbursementExpensesForm } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForParametersApplicationReimbursementExpensesForm } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/adapters/RHF.adapter'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'

const useParametersApplicationReimbursementExpensesAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): ParametersApplicationReimbursementExpensesFormValues =>
    generateRHFObjectForParametersApplicationReimbursementExpensesForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForParametersApplicationReimbursementExpensesForm(
      objectFromPropertyState,
    )

  const generateMenuListByFormValues = (
    formValues:
      | ParametersApplicationReimbursementExpensesFormValues
      | UnpackNestedValue<ParametersApplicationReimbursementExpensesFormValues>,
    initialHashMap: NestedMenuHashMap,
    formErrors: FieldErrors<ParametersApplicationReimbursementExpensesFormValues>,
  ): NestedMapOfMenu[] =>
    generateMenuListForParametersApplicationReimbursementExpensesMenuState(
      formValues,
      initialHashMap,
      formErrors,
    )

  return { generateRHFObject, generatePropertiesObject, generateMenuListByFormValues }
}

export { useParametersApplicationReimbursementExpensesAdapters }
