import { FC } from 'react'

import Typography from '@components/NewDesign/Typography'

interface NoDataFieldFallbackProps {
  text?: string
  className?: string
}

const NoDataFieldFallback: FC<NoDataFieldFallbackProps> = ({ text = 'нет данных', className }) => (
  <Typography variant={'bodyMRegular'} className={className}>
    {text}
  </Typography>
)

export default NoDataFieldFallback
