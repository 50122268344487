import { useCallback } from 'react'

import { generatePrefetchedPropsForParametersOfCostRecoveryApplicationForm } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/adapters/PrefetchedProps.adapter'
import { generatePropertiesObjectForParametersOfCostRecoveryApplicationForm } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForParametersOfCostRecoveryApplicationForm } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/adapters/RHF.adapter'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { useAPIContext } from '@context/APIContext'
import { ReferenceBooksDictionaryNameKeys } from '@context/APIContext/hooks/useReferenceBooksApi/types'

const useParametersOfCostRecoveryApplicationAdapters = () => {
  const {
    referenceBooksApi: { getReferenceBooks },
  } = useAPIContext()

  const generateRHFObject = useCallback(
    async (
      objectFromPropertyState: Record<string, any>,
    ): Promise<ParametersOfCostRecoveryApplicationFormValues> => {
      const catalogObjectTypes = await getReferenceBooks({
        dictionaryName: 'compensation_object_type' as ReferenceBooksDictionaryNameKeys,
      })
      return generateRHFObjectForParametersOfCostRecoveryApplicationForm(
        objectFromPropertyState,
        catalogObjectTypes,
      )
    },
    [getReferenceBooks],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForParametersOfCostRecoveryApplicationForm(objectFromPropertyState),
    [],
  )

  const generatePrefetchedPropsArray = useCallback(
    (objectFromPropertyState: Record<string, any>): string[] =>
      generatePrefetchedPropsForParametersOfCostRecoveryApplicationForm(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generatePrefetchedPropsArray, generatePropertiesObject }
}

export { useParametersOfCostRecoveryApplicationAdapters }
