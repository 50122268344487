import { FC, memo } from 'react'

import Button from '@components/NewDesign/Button'
import Toast from '@components/Toast'

import styles from './Toaster.module.scss'

interface VersionToasterProps {
  hasAccessToActualVersion?: boolean
  hasPermissionsWithAction?: boolean
  isInvestor?: boolean
  versionNumber?: number
  versionDateTime?: string
  handleCloseToast?(): void
}

const VersionToaster: FC<VersionToasterProps> = ({
  hasAccessToActualVersion = true,
  hasPermissionsWithAction = false,
  isInvestor,
  versionNumber,
  versionDateTime,
  handleCloseToast,
}) => {
  const investorBannerText = `Вы просматриваете ${versionNumber} версию от ${versionDateTime}.`
  const notAccessToActualVersionText =
    'Актуальная версия документов недоступна, потому что у вас недостаточно прав '

  const getStateBodiesBannerText = (hasPermission: boolean) =>
    `${investorBannerText}${hasPermission ? ', чтобы принять решение' : ''}`

  const currentRenderText = `${investorBannerText} ${
    !hasAccessToActualVersion ? notAccessToActualVersionText : ''
  }`

  return (
    <Toast
      contentClassname={styles.versionToast__content}
      wrapperClassname={styles.versionToast__wrapper}
    >
      {!isInvestor &&
        getStateBodiesBannerText(hasPermissionsWithAction && hasAccessToActualVersion)}
      {isInvestor && currentRenderText}{' '}
      {hasAccessToActualVersion && (
        <Button
          geometry="square"
          color="default"
          size="s"
          view="plain"
          className={styles.versionToast__button}
          onClick={handleCloseToast}
        >
          вернитесь к актуальной версии
        </Button>
      )}
    </Toast>
  )
}

export default memo(VersionToaster)
