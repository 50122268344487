import { RolesTypes } from '@constants/types'

const ORGANIZATION_ROLES = [
  RolesTypes.MER,
  RolesTypes.OIV,
  RolesTypes.OMSU,
  RolesTypes.INVESTOR,
  RolesTypes.UO,
]

export { ORGANIZATION_ROLES }
