import React, { FC } from 'react'
import { Path, UseFormReturn } from 'react-hook-form'

import Typography from '@components/NewDesign/Typography'
import { MembersFormValues } from '@components/NewDesignedModals/AddSolicitationMembersModal'
import Radio from '@components/Radio'
import { IProjectMember, ISolicitationMembersEntity } from '@services/Members/Members.entity'
import { mapOfMembers } from '@services/Members/Members.maps'

import styles from './ChooseSoliciation.module.scss'

type ChooseSolicitationStepProps = {
  formInstance: UseFormReturn<MembersFormValues>

  nameOfSolicitationControl: string

  possibleSolicitationMembers?: (ISolicitationMembersEntity | IProjectMember)[]
}

const ChooseSolicitationStep: FC<ChooseSolicitationStepProps> = ({
  formInstance,
  possibleSolicitationMembers,
  nameOfSolicitationControl,
}) => {
  const { control } = formInstance

  return (
    <Radio.ControlledGroup
      control={control}
      name={nameOfSolicitationControl as Path<MembersFormValues>}
      radioGroupProps={{
        className: styles.chooseSoliciation__group,
        direction: 'vertical',
      }}
    >
      {possibleSolicitationMembers?.map((member) => {
        return (
          <Radio.Button
            key={member.organizationId}
            value={member.organizationId}
            label={
              <>
                <Typography.Caption variant={'captionSRegular'} color={'text-base-tertiary'}>
                  {mapOfMembers[member.type]}
                </Typography.Caption>

                <Typography.Body
                  className={styles.chooseSoliciation__soliciation}
                  variant={'bodyMMedium'}
                >
                  {member.name}
                </Typography.Body>
              </>
            }
          />
        )
      })}
    </Radio.ControlledGroup>
  )
}

export default ChooseSolicitationStep
