import { useCallback } from 'react'

import { HttpStatusCode } from '@constants/statusCodes'
import { DependentUrls } from '@constants/urls'
import {
  ChangePropertyBody,
  GetChangePropertiesData,
  GetChangePropertiesProps,
  GetFormErrorsData,
  GetLookupData,
  GetLookupProps,
  GetPropertiesData,
  GetPropertiesProps,
} from '@context/APIContext/hooks/usePropertiesApi/types'
import { isAxiosError } from '@helpers/checkTypes'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { stringifyQuery } from '@helpers/format'
import { FetcherWithInterceptors } from '@packages/api/fetcher'
import { DefaultPropertiesProps } from '@services/Properties/Properties.entity'

const usePropertiesApi = () => {
  const getFormProperties = useCallback(async (formProps: GetPropertiesProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetPropertiesData>({
        url: {
          url: DependentUrls.FormProperties,
          params: {
            formId: formProps.formId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }, [])

  const getFormPropertiesChanges = useCallback(async (formProps: GetChangePropertiesProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetChangePropertiesData>({
        url: {
          url: DependentUrls.ChangesFormProperties,
          params: {
            formId: formProps.formId,
          },
        },
        config: {
          params: {
            lastNoticedChangesDt: formProps.lastChangesDt,
          },
          paramsSerializer: stringifyQuery,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }, [])

  const getFormErrors = useCallback(async (props: DefaultPropertiesProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetFormErrorsData>({
        url: {
          url: DependentUrls.FormFLC,
          params: {
            formId: props.formId,
          },
        },
      })
      return data
    } catch (e) {
      throw e
    }
  }, [])

  const getLookupData = useCallback(async (props: GetLookupProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetLookupData>({
        url: {
          url: DependentUrls.Lookup,
          params: props,
        },
      })

      return data
    } catch (e) {
      throw e
    }
  }, [])

  const getFormPreview = useCallback(async (props: DefaultPropertiesProps) => {
    try {
      const { data, headers } = await FetcherWithInterceptors.get<File>({
        url: {
          url: DependentUrls.FormPreview,
          params: {
            formId: props.formId,
          },
        },
        config: {
          responseType: 'blob',
        },
      })

      return {
        file: data,
        headers,
      }
    } catch (e) {
      throw e
    }
  }, [])

  const changeProperties = useCallback(
    async (formId: string, propertyBody: ChangePropertyBody[]) => {
      try {
        const { data } = await FetcherWithInterceptors.post({
          url: {
            url: DependentUrls.ChangesFormProperties,
            params: {
              formId,
            },
          },
          data: propertyBody,
        })

        return data
      } catch (error) {
        //Скрываем ошибку о неполученных изменениях
        if (!(isAxiosError(error) && error.response?.status == HttpStatusCode.BAD_REQUEST)) {
          handleErrorWithPopup(error, true)
        }

        throw error
      }
    },
    [],
  )

  return {
    getFormProperties,
    getFormPropertiesChanges,
    getLookupData,
    getFormPreview,
    getFormErrors,
    changeProperties,
  }
}

export default usePropertiesApi
