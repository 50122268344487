import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { TAllDfoTypes } from '@services/Dfo/Dfo.entity'
import useSWRImmutable from 'swr/immutable'

const useDfoAvailableForCreate = ({ key, config }: SWRHookProps<TAllDfoTypes[] | undefined>) => {
  const {
    projectsApi: { getAvailableDfoForCreate },
  } = useAPIContext()

  const {
    data: dfoAvailableForCreate,
    error,
    mutate,
    isValidating: isLoadingDfoAvailableForCreate,
  } = useSWRImmutable(key, ({ projectId }) => getAvailableDfoForCreate(projectId), config)

  return {
    dfoAvailableForCreate,
    error,
    mutate,
    isLoadingDfoAvailableForCreate,
  }
}

export { useDfoAvailableForCreate }
