import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForApplicationInclusionOfAARegisterSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '2': {
      extraStatementName: {
        propertyId: objectFromPropertyState.extraStatementName?.propertyId,
        type: objectFromPropertyState.extraStatementName?.type,
        lastUpdateDt: objectFromPropertyState.extraStatementName?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForApplicationInclusionOfAARegisterSZPKForm }
