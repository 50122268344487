import { ButtonHTMLAttributes, FC, MouseEvent } from 'react'

import Icon from '@components/Icon'
import cn from 'classnames'

import { getIconSizeByTagSize } from './helpers'
import styles from './Tag.module.scss'

export interface TagProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  leadingIcon?: string
  trailingIcon?: string
  type?:
    | 'purple'
    | 'navy'
    | 'blue'
    | 'light_blue'
    | 'green'
    | 'orange'
    | 'red'
    | 'yellow'
    | 'black'
    | 'primary-base'
  size?: 'l' | 'm'
  className?: string
}

const leftSpace = '-ml-0.5'

const Tag: FC<TagProps> = ({
  size = 'm',
  type = 'purple',
  children,
  disabled,
  leadingIcon,
  trailingIcon,
  className,
  onClick,
  ...props
}) => {
  const interactive = onClick

  const iconComponent = (_src: string, _className?: string) => {
    return <Icon className={_className} size={getIconSizeByTagSize(size)} src={_src} />
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClick?.(e)
  }

  return (
    <button
      type="button"
      disabled={!interactive || disabled}
      className={cn(styles.wrapper, className, {
        [styles['wrapper--interactive']]: interactive,
        [styles[`wrapper--${type}`]]: type,
        [styles[`wrapper--${size}`]]: size,
      })}
      onClick={handleClick}
      {...props}
    >
      {leadingIcon && iconComponent(leadingIcon, leftSpace)}
      {children && <div className={styles.tagName}>{children}</div>}
      {trailingIcon && iconComponent(trailingIcon, leftSpace)}
    </button>
  )
}

export default Tag
