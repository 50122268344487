import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeOneSideRejectSZPKBlockValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/const'
import { thirdSectionValidationMap } from '@components/Forms/NoticeOneSideRejectSZPKForm/Forms/3/validation'
import { useNoticeOneSideRejectSZPKManager } from '@components/Forms/NoticeOneSideRejectSZPKForm/Manager'
import { NoticeOneSideRejectSZPKFormValues } from '@components/Forms/NoticeOneSideRejectSZPKForm/types'
import { NoticeOneSideRejectSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOneSideRejectSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance = useFormContext<NoticeOneSideRejectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOneSideRejectSZPKManager()

  const { getSubscribableControlProps, getTextareaProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NoticeOneSideRejectSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <RowWithBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={
            'Пункт части 14 статьи 11 69-ФЗ, указывающий на основания для одностороннего отказа от СЗПК'
          }
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: noticeOneSideRejectSZPKBlockValues['3'].ruleLinkOneSideRejectReason,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: noticeOneSideRejectSZPKBlockValues['3'].ruleLinkOneSideRejectReason,
                rules: thirdSectionValidationMap.ruleLinkOneSideRejectReason,
                inputProps: {},
                onBlur: () =>
                  setTimeout(
                    () =>
                      handleChangeValue?.(
                        noticeOneSideRejectSZPKBlockValues['3'].ruleLinkOneSideRejectReason,
                      ),
                    0,
                  ),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      noticeOneSideRejectSZPKBlockValues['3'].ruleLinkOneSideRejectReason,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
        <Row>
          <Col xs={13}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: noticeOneSideRejectSZPKBlockValues['3'].oneSideRejectReasonProof,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: noticeOneSideRejectSZPKBlockValues['3'].oneSideRejectReasonProof,
                  rules: thirdSectionValidationMap.oneSideRejectReasonProof,
                  textareaProps: {
                    label: 'Подтверждение обстоятельства',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          noticeOneSideRejectSZPKBlockValues['3'].oneSideRejectReasonProof,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        noticeOneSideRejectSZPKBlockValues['3'].oneSideRejectReasonProof,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
