import React, { memo } from 'react'

import { mockKnowledgeBase } from '@components/KnowledgeBase/__mock__'
import KnowledgeBaseItem from '@components/KnowledgeBase/Desktop/Item'
import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import Loader from '@components/Loader'
import NoItemText from '@components/NoItemText'

import styles from './List.module.scss'

const KnowledgeBaseDesktopList = () => {
  const {
    state: { searchThree, currentSearchPattern, searchThreeIsLoading },
  } = useKnowledgeBase()

  const currentRenderList = currentSearchPattern.length ? searchThree : mockKnowledgeBase

  return (
    <div className={styles.list__wrapper}>
      <Loader loading={searchThreeIsLoading}>
        <ul className={styles.list}>
          {currentRenderList ? (
            currentRenderList.map((item) => {
              return <KnowledgeBaseItem key={item.id} baseItem={item} />
            })
          ) : (
            <NoItemText className="mt-1">Поиск не дал результатов</NoItemText>
          )}
        </ul>
      </Loader>
    </div>
  )
}

export default memo(KnowledgeBaseDesktopList)
