import React, { type ChangeEvent, type FocusEvent, memo, useMemo, useReducer, useRef } from 'react'
import type { FileRejection } from 'react-dropzone'
import type { FieldValues } from 'react-hook-form'

import FileItem from '@components/Attachments/Attachment/FileItem'
import type {
  CreateLoadingFolderFileItemProps,
  FileItemReducerState,
  FileItemTrailingAdditionProps,
} from '@components/Attachments/Attachment/FileItem/types'
import FolderItemContextMenu from '@components/Attachments/Attachment/FolderItem/ContextMenu'
import { FolderItemService } from '@components/Attachments/Attachment/FolderItem/helpers'
import {
  folderItemCreators,
  folderItemReducer,
  initialFolderItemState,
} from '@components/Attachments/Attachment/FolderItem/reducer'
import { useTitleMode } from '@components/Attachments/Attachment/hooks/useTitleMode'
import FileDropzone from '@components/FileDropzone'
import IconButton from '@components/NewDesign/IconButton'
import Input from '@components/NewDesign/Input'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { InputProps } from '@components/NewDesign/Input/types'
import { DefaultActionProps } from '@components/NewDesign/Modal/Base/Actions'
import Typography from '@components/NewDesign/Typography'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import { MimeTypes } from '@constants/types'
import { isNullOrUndefined } from '@helpers/checkTypes'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'
import plural from 'plural-ru'

import { FOLDER_NAME_MAX_LENGTH } from './constants'
import styles from './FolderItem.module.scss'
import type {
  EntityFolderItemProps,
  FolderFileItem,
  FolderItemDownloadProps,
  FolderItemDropzoneProps,
  FolderItemLeadingAdditionProps,
  FolderItemReducerState,
  FolderItemSignFileProps,
  FolderNameProps,
} from './types'

//initialFiles - файлы инициалиазации для бесконтрольного состояния
//files - файлы инициализации уже контрольного состояния сверху
interface FolderItemProps<FormValues extends FieldValues> {
  folderState?: FolderItemReducerState
  initialFiles?: FileItemReducerState[]
  isDownloadForbidden?: boolean
  readOnly?: boolean
  hasDocumentTemplateType?: boolean
  readOnlyDocuments?: boolean[]
  dataTestId?: string
  dropzoneProps?: FolderItemDropzoneProps
  downloadProps?: FolderItemDownloadProps
  signFileProps?: FolderItemSignFileProps
  checkFileDownloadForbidden?: (file: FolderFileItem) => boolean
  trailingAdditionProps?: (file: FolderFileItem) => FileItemTrailingAdditionProps | undefined
  leadingAdditionProps?: (file: FolderFileItem) => FolderItemLeadingAdditionProps | undefined
  entityProps?: EntityFolderItemProps
  folderClassName?: string
  folderContextActions?: DefaultActionProps[]
  folderNameProps?: FolderNameProps<FormValues>
  onLoadFiles?: (file: File[]) => Promise<void>
  onDropSuccess?: (file: File[]) => void
  onDropError?: (rejectedFile: FileRejection[], files?: File[]) => void
  onFileEdit?: (file: FolderFileItem) => void
  onFileRemove?: (file: FolderFileItem) => Promise<void>
  onFileReplace?: (file: FolderFileItem) => void
  onFileReload?: (file: FolderFileItem) => Promise<void>
  onFileRemoveNotLoaded?: (file: FolderFileItem) => void
  onFoldUnfold?: VoidFunction
  onFolderRename?: (event: ChangeEvent<HTMLInputElement>) => void
  onFolderRemove?: (filesToRemove: FolderFileItem[]) => void
}

const DEFAULT_FUNC = () => null

const { fromFilesItemToFolderItem, createLoadingFolderFileItem, getFileIndexById } =
  FolderItemService

const FolderItem = <FormValues extends FieldValues>({
  folderState,
  initialFiles,
  isDownloadForbidden,
  readOnly,
  hasDocumentTemplateType,
  readOnlyDocuments,
  dataTestId,
  dropzoneProps,
  downloadProps,
  signFileProps,
  checkFileDownloadForbidden,
  trailingAdditionProps,
  leadingAdditionProps,
  entityProps,
  folderClassName,
  folderContextActions,
  folderNameProps,
  onDropSuccess,
  onDropError,
  onLoadFiles,
  onFileReload,
  onFileRemove,
  onFileReplace,
  onFileRemoveNotLoaded,
  onFileEdit,
  onFolderRemove,
  onFoldUnfold,
  onFolderRename,
}: FolderItemProps<FormValues>) => {
  const {
    multiple = false,
    accept = MimeTypes,
    onDrop,
    onRejectDrop,
    ...restDropzoneProps
  } = dropzoneProps || {}
  const { onFileDownload, downloadTooltipContent, signConditionOfDocuments } = downloadProps || {}
  const { signFileTooltipContent, onFileDropSign } = signFileProps || {}
  const { dataTestId: entityDataTestId } = entityProps || {}

  const { readOnlyFolderName, controllerFolderNameProps } = folderNameProps || {}

  const [state, dispatch] = useReducer(folderItemReducer, {
    ...initialFolderItemState,
    files: fromFilesItemToFolderItem(initialFiles || []),
  })

  const currentState = folderState ?? state

  const { getCollapseProps } = useCollapse({
    isExpanded: !currentState.isFold,
  })

  const acceptedFilesLength = useMemo(() => {
    if (!currentState.files || !currentState.files?.length) return 0

    return currentState.files.filter((file) => !file.fileState.rejectedFile).length
  }, [currentState.files])

  const replacedFileRef = useRef<FolderFileItem | null>(null)

  const uncontrolled = !folderState

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentInputValue = event.target.value

    if (!currentInputValue.trim().length) return

    dispatch(folderItemCreators.setFolderName(currentInputValue))
  }

  const currentTitleChange = uncontrolled ? handleTitleChange : onFolderRename

  const {
    inputRef,
    state: { editMode },
    handlers: { handleEditClick, handleBlurInput },
  } = useTitleMode({
    onBlur: currentTitleChange || DEFAULT_FUNC,
  })

  const handleRenameFolderOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const currentInputValue = event.target.value

    if (!currentInputValue.trim().length || readOnly) return

    const { name, control } = controllerFolderNameProps || {}

    if (!name || !control) return handleBlurInput(event)

    const { error } = control.getFieldState(name)

    if (error) return

    handleBlurInput(event)
  }

  const handleFoldUnfold = () => {
    dispatch(folderItemCreators.setFolded(!state.isFold))
  }

  const handleCreateNewFolderFileItem = (props: CreateLoadingFolderFileItemProps) => {
    const folderFileItem = createLoadingFolderFileItem(props)
    dispatch(folderItemCreators.addFile(folderFileItem))

    return folderFileItem
  }

  const handleLoadFiles = async (filesToUploader: File[]) => {
    //TODO: Сделать множественную загрузку файлов
    const file = filesToUploader[0]
    let currentFolderFileItem: FolderFileItem | null = null

    //Разграничитель между первой загрузкой файла и его заменой, один onDrop на две разные операции.
    if (!replacedFileRef.current) {
      currentFolderFileItem = handleCreateNewFolderFileItem({
        file,
        rejectedFile: null,
      })
    } else {
      dispatch(
        folderItemCreators.replaceFile({
          id: replacedFileRef.current?.id,
          fileState: {
            isDeletedFile: false,
            file,
            rejectedFile: null,
            fileSize: file.size,
            isLoading: true,
            errorMessage: null,
          },
        }),
      )

      currentFolderFileItem = replacedFileRef.current
    }

    try {
      await onLoadFiles?.([file])
    } catch (error) {
      dispatch(
        folderItemCreators.setFileError({
          id: currentFolderFileItem.id,
          error,
        }),
      )

      throw error
    } finally {
      dispatch(
        folderItemCreators.setFileLoading({ id: currentFolderFileItem.id, isLoading: false }),
      )

      replacedFileRef.current = null
    }
  }

  const currentLoadFilesHandler = uncontrolled ? handleLoadFiles : onLoadFiles

  const handleDropFiles = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    //TODO: Обрабатывать в интерфейсе незагруженные файлы
    if (rejectedFiles.length || acceptedFiles.length > 1)
      onDropError?.(rejectedFiles, acceptedFiles)
    if (!acceptedFiles.length) return

    onDropSuccess?.(acceptedFiles)
    try {
      await currentLoadFilesHandler?.(acceptedFiles)
    } catch (e) {
      throw e
    }
  }

  const handleRejectDropFiles =
    (folderFileItem: FolderFileItem) =>
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      //TODO: Обрабатывать в интерфейсе незагруженные файлы
      if (rejectedFiles.length) return onDropError?.(rejectedFiles)
      if (!acceptedFiles.length) return

      onDropSuccess?.(acceptedFiles)
      try {
        await currentLoadFilesHandler?.(acceptedFiles)
      } catch (e) {
        throw e
      }
    }

  const handleReplaceFile = (folderFileItem: FolderFileItem) => () => {
    if (!state.files) return

    try {
      onFileReplace?.(folderFileItem)

      replacedFileRef.current = folderFileItem
    } catch (e) {
      throw e
    }
  }

  const handleDownloadFile = (folderFileItem: FolderFileItem) => () => {
    onFileDownload?.(folderFileItem)
  }

  const handleReloadFile = (folderFileItem: FolderFileItem) => async () => {
    if (!state.files) return

    const indexOfReload = getFileIndexById(folderFileItem.id, state.files)
    const fileItemToReload = state.files[indexOfReload]

    const fileToReload = state.files[indexOfReload].fileState.file

    if (!fileToReload) return

    dispatch(
      folderItemCreators.setFileError({
        id: fileItemToReload.id,
        error: null,
      }),
    )

    dispatch(
      folderItemCreators.setFileLoading({
        id: fileItemToReload.id,
        isLoading: true,
      }),
    )

    try {
      await onFileReload?.(fileItemToReload)
      dispatch(
        folderItemCreators.replaceFile({
          id: fileItemToReload.id,
          fileState: {
            isDeletedFile: false,
            file: fileItemToReload.fileState.file,
            rejectedFile: null,
            fileSize: fileItemToReload.fileState.fileSize,
            errorMessage: null,
            isLoading: false,
          },
        }),
      )
    } catch (error) {
      folderItemCreators.setFileError({
        id: fileItemToReload.id,
        error,
      })
    } finally {
      dispatch(
        folderItemCreators.setFileLoading({
          id: fileItemToReload.id,
          isLoading: false,
        }),
      )
    }
  }

  const handleRemoveNotLoadedFile =
    (folderFileItem: FolderFileItem) => (): [number, FolderFileItem] | undefined => {
      if (!state.files) return

      const indexOfInsert = getFileIndexById(folderFileItem.id, state.files)
      const file = state.files[indexOfInsert]

      dispatch(folderItemCreators.removeFile(folderFileItem.id))

      return [indexOfInsert, file]
    }

  const handleRemoveFile = (folderFileItem: FolderFileItem) => async () => {
    const propsFromRemoveFile = handleRemoveNotLoadedFile(folderFileItem)()

    const [indexOfInsert, file] = propsFromRemoveFile || []

    try {
      await onFileRemove?.(folderFileItem)
    } catch (error) {
      if (isNullOrUndefined(indexOfInsert) || !file) throw error
      dispatch(
        folderItemCreators.insertFile({
          indexOfInsert,
          file,
        }),
      )
      throw error
    }
  }

  const customHandleDropFilesHandler = uncontrolled ? handleDropFiles : onDrop
  const customHandleRejectDropFilesHandler = uncontrolled ? handleRejectDropFiles : onRejectDrop
  const currentFoldUnfoldHandler = uncontrolled ? handleFoldUnfold : onFoldUnfold
  const currentFileReloadHandler = uncontrolled
    ? handleReloadFile
    : (folderFileItem: FolderFileItem) => async () => {
        await onFileReload?.(folderFileItem)
      }
  const currentRemoveNotLoadedFileHandler = uncontrolled
    ? handleRemoveNotLoadedFile
    : (folderFileItem: FolderFileItem) => () => {
        onFileRemoveNotLoaded?.(folderFileItem)
      }
  const currentFileRemoveHandler = uncontrolled
    ? handleRemoveFile
    : (folderFileItem: FolderFileItem) => async () => {
        await onFileRemove?.(folderFileItem)
      }
  const currentFileReplaceHandler = uncontrolled
    ? handleReplaceFile
    : (folderFileItem: FolderFileItem) => () => onFileReplace?.(folderFileItem)

  const handleFolderRemove = (filesToRemove: FolderFileItem[] | null) => () => {
    if (!filesToRemove) return
    onFolderRemove?.(filesToRemove)
  }

  const currentFilesLengthStatus = !acceptedFilesLength
    ? 'Нет документов'
    : `${plural(acceptedFilesLength, '%d документ', '%d документа', '%d документов')}`

  const defaultFolderContextAction = {
    size: 's',
    view: 'plain',
    geometry: 'square',
    color: 'negative',
    disabled: readOnly,
    onClick: handleFolderRemove(state.files),
    children: (
      <Typography.Body
        variant={'bodyMMedium'}
        dataTestId={dataTestId && `${dataTestId}-action-remove`}
        color={'accent-error-normal'}
      >
        Удалить папку
      </Typography.Body>
    ),
  } as DefaultActionProps

  const baseFolderNameInputName: InputProps = {
    placeholder: 'Введите название папки',
    dataTestId: dataTestId && `${dataTestId}-folderName-input`,
    maxLength: FOLDER_NAME_MAX_LENGTH,
  }

  if (readOnly && !currentState.files?.length) return null

  return (
    <div data-testid={dataTestId} className={cn(styles.folderItem, folderClassName)}>
      <div className={styles.folderItem__header}>
        <IconButton
          size={'s'}
          view={'basic'}
          dataTestId={dataTestId && `${dataTestId}-fold-unfold`}
          icon={{
            className: cn({ [styles.folderItem__fold]: !currentState.isFold }),
            src: chevronRightIcon,
          }}
          onClick={currentFoldUnfoldHandler}
        />
        <div className={styles.folderItem__info}>
          {editMode ? (
            <>
              {controllerFolderNameProps ? (
                <ControlledInput
                  {...controllerFolderNameProps}
                  inputProps={{
                    ...baseFolderNameInputName,
                    ref: inputRef,
                  }}
                  onBlur={handleRenameFolderOnBlur}
                />
              ) : (
                <Input
                  {...baseFolderNameInputName}
                  defaultValue={currentState.folderName}
                  ref={inputRef}
                  onBlur={handleRenameFolderOnBlur}
                />
              )}
            </>
          ) : (
            <TypographyWithTooltip.Body
              variant={'bodyMMedium'}
              className={styles['folderItem__info-name']}
              dataTestId={dataTestId && `${dataTestId}-folderName`}
              tooltipProps={{
                position: 'bottom',
                offset: [0, 10],
                targetClassName: styles.tooltip__target,
                contentClassName: styles.tooltip__content,
                popoverClassName: styles.tooltip__popover,
                arrowClassName: styles.tooltip__arrow,
              }}
              onClick={!readOnlyFolderName ? handleEditClick : undefined}
            >
              {currentState.folderName}
            </TypographyWithTooltip.Body>
          )}
        </div>
        <div className={styles['folderItem__info-status']}>
          <Typography.Body
            dataTestId={dataTestId && `${dataTestId}-file-count`}
            color={'accent-brand-faded-primary'}
            variant={'bodySMedium'}
          >
            {currentFilesLengthStatus}
          </Typography.Body>
        </div>
        {!readOnly && !isDownloadForbidden && onFolderRemove && (
          <FolderItemContextMenu
            actions={[defaultFolderContextAction, ...(folderContextActions || [])]}
          />
        )}
      </div>
      <div {...getCollapseProps()}>
        <div>
          <div className={styles['folderItem__files-wrapper']}>
            {!!currentState.files?.length && (
              <ul className={styles.folderItem__files}>
                {currentState.files?.map((fileInFolder, index) => {
                  const readOnlyDocument = !!readOnlyDocuments?.[index]
                  const signCondition = !!signConditionOfDocuments?.[index]

                  return (
                    <li aria-hidden key={fileInFolder.id}>
                      <FileItem
                        readOnly={readOnlyDocument}
                        hasDocumentTemplateType={hasDocumentTemplateType}
                        fileState={fileInFolder.fileState}
                        trailingAdditionProps={trailingAdditionProps?.(fileInFolder)}
                        leadingAdditionProps={leadingAdditionProps?.(fileInFolder)}
                        contentNode={fileInFolder.fileState.file?.name}
                        isDownloadForbidden={checkFileDownloadForbidden?.(fileInFolder)}
                        entityProps={{
                          className: cn(styles.folderItem__file, entityProps?.className),
                          dataTestId: entityDataTestId && `${fileInFolder.id}.${entityDataTestId}`,
                          trailingClassName: entityProps?.trailingClassName,
                          leadingClassName: cn(
                            styles['folderItem__file-info-leading'],
                            entityProps?.leadingClassName,
                          ),
                          onClick: !fileInFolder.fileState.errorMessage
                            ? entityProps?.onClick?.(fileInFolder)
                            : undefined,
                        }}
                        rejectFileProps={{
                          className: styles.fileItem__rejectedFile,
                        }}
                        downloadProps={{
                          signCondition,
                          downloadTooltipContent: downloadTooltipContent?.(fileInFolder),
                          onFileDownload:
                            downloadProps?.onFileDownload && handleDownloadFile(fileInFolder),
                        }}
                        signFileProps={{
                          signFileTooltipContent,
                          onFileDropSign: onFileDropSign?.(fileInFolder),
                        }}
                        dropzoneProps={{
                          ...restDropzoneProps,
                          accept,
                          multiple,
                          onDrop: customHandleDropFilesHandler,
                          onRejectDrop: customHandleRejectDropFilesHandler?.(fileInFolder),
                        }}
                        onFileReplace={onFileReplace && currentFileReplaceHandler?.(fileInFolder)}
                        onFileReload={currentFileReloadHandler?.(fileInFolder)}
                        onFileRemove={onFileRemove && currentFileRemoveHandler?.(fileInFolder)}
                        onFileNotLoadedRemove={currentRemoveNotLoadedFileHandler(fileInFolder)}
                      />
                    </li>
                  )
                })}
              </ul>
            )}
            {!readOnly && !isDownloadForbidden && (
              <FileDropzone.Folder
                {...restDropzoneProps}
                multiple={multiple}
                disabled={readOnly}
                accept={accept}
                onDrop={customHandleDropFilesHandler}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(FolderItem) as typeof FolderItem
