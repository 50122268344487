import { FieldValues } from 'react-hook-form'

import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

type InfoAboutValidation<T extends FieldValues> = FormValuesValidationSection<'documentNumber' | 'documentReceivers', T>

const getInfoAboutValidation = <T extends FieldValues>() => {
  return {
    documentNumber: {
      validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
    },
    documentReceivers: {
      validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 5000) : undefined),
    },
  } as InfoAboutValidation<T>
}

export { getInfoAboutValidation }
