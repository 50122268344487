import React, { FC } from 'react'

import Loader from '@components/Loader'
import { Popover } from '@components/NewDesign/Popover'
import Options from '@components/NewDesign/Select/OptionsNew'
import type { OptionsNewProps } from '@components/NewDesign/Select/OptionsNew/types'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './OptionsGroup.module.scss'

const DEFAULT_NOT_FOUND_TEXT = 'Нет доступных опций для выбора'

  const OptionsGroup: FC<OptionsNewProps> = ({
  variant = 'primary',
  view = 'normal',
  popoverProps,
  options,
  selected,
  onClick,
  className,
  optionClassName,
  optionsContainer,
  tailNode,
  scrollableOptionsContainerClassName,
  DisplayValueFC,
  isLoading,
  notFoundText,
  dataTestId,
}) => {
  return (
    <Popover
      useAnchorWidth
      className={styles.popover}
      popperClassName={styles.popover__inner}
      offset={[0, 10]}
      {...popoverProps}
    >
      <div
        {...optionsContainer}
        data-testid={dataTestId}
        className={cn(scrollableOptionsContainerClassName, styles.optionsGroup__container)}
      >
        {!!options.length && (
          <div
            className={cn(
              styles.optionsGroup,
              {
                [styles[`optionsGroup--${variant}`]]: variant,
              },
              className,
            )}
          >
            {options.map((option, index) => (
              <Options.Controller
                key={index}
                option={option}
                view={view}
                selected={selected}
                variant={variant}
                optionClassName={optionClassName}
                DisplayValueFC={DisplayValueFC}
                onClick={onClick}
              />
            ))}
          </div>
        )}
        {!options.length && !isLoading && (
          <div className={styles.optionsGroup__notFoundContainer}>
            <Typography.Body variant="bodyMMedium" className={cn(styles.optionsGroup__notFound)}>
              {notFoundText || DEFAULT_NOT_FOUND_TEXT}
            </Typography.Body>
          </div>
        )}
        <Loader className={styles.optionsGroup__loading} variant="lite" loading={isLoading} />
        {tailNode}
      </div>
    </Popover>
  )
}

export default OptionsGroup
