import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import { mergeRefs } from '@helpers/ref/mergeRefs'
import Inputmask from 'inputmask'

import Input from '../Input'

import { MaskedInputProps } from './types'

export const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  (
    {
      autoUnmask = false,
      mask,
      regex,
      maskPlaceholder,
      value,
      defaultValue,
      className,
      onChange,
      onClear,
      ...restProps
    },
    ref,
  ) => {
    const inputMaskProps = {
      mask: mask,
      ...(regex ? { regex: regex } : null),
      showMaskOnHover: false,
      placeholder: maskPlaceholder,
      autoUnmask
    } as const

    const currentInputMask = Inputmask(inputMaskProps)

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (inputRef.current && Inputmask) {
        currentInputMask.mask(inputRef.current)
      }
    }, [])

    const mergedRef = mergeRefs([ref, inputRef])

    const [inputValue, setInputValue] = useState(value || defaultValue || '')

    const update = useCallback((value: string) => {
      if (inputRef.current) {
        setInputValue(value)
      }
    }, [])

    // очистка состояния если value приходит пустой
    // обновление состояние если value корректное
    useEffect(() => {
      if (typeof value !== 'string') return

      if (!value) {
        return update('')
      }

      update(value)
    }, [value])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      update(event.target.value)

      if (onChange) {
        onChange(event)
      }
    }

    const handleClear = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        update('')

        if (onClear) onClear(event)
      },
      [onClear, update],
    )

    return (
      <Input
        {...restProps}
        className={className}
        value={inputValue}
        ref={mergedRef}
        onChange={handleInputChange}
        onClear={handleClear}
      />
    )
  },
)

export default MaskedInput
