import React, { memo } from 'react'

import formStyles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import Taxes from '@components/Forms/CreateStatementOld/Forms/PlannedTaxes/Taxes'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'

const PlannedTaxes = () => {
  const {
    state: { formInstance },
  } = useCreateStatementManager()

  if (!formInstance) return null

  return (
    <div className={formStyles.form__container}>
      <Taxes />
    </div>
  )
}

export default memo(PlannedTaxes)
