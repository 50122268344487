import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fifthSectionAAgreementAccessionMunicipalValidationMap: FormValuesValidationSection<
  keyof AAgreementAccessionMunicipalFormValues['5'],
  AAgreementAccessionMunicipalFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fifthSectionAAgreementAccessionMunicipalValidationMap }
