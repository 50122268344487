import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof AAVolumeOfCapitalInvestmentsFormValues['4'],
  AAVolumeOfCapitalInvestmentsFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionValidationMap }
