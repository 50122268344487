import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { infrFacilitiesCostsExpectedReimbursedBlockValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import { useInfrFacilitiesCostsExpectedReimbursedManager } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Manager'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const One = () => {
  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useInfrFacilitiesCostsExpectedReimbursedManager()

  const formInstance = useFormContext<InfrFacilitiesCostsExpectedReimbursedFormValues>()

  const { getSubscribableControlProps, getControllerProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['1'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                controllerProps={getControllerProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['1'].investorFullName,
                })}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                  tooltipProps: {
                    width: 'm',
                  },
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['1'].investorName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Сокращенное наименование'}
                controllerProps={getControllerProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['1'].investorName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: infrFacilitiesCostsExpectedReimbursedBlockValues['1'].investorAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Юридический адрес'}
                controllerProps={getControllerProps({
                  name: infrFacilitiesCostsExpectedReimbursedBlockValues['1'].investorAddress,
                })}
                formIconTooltipProps={{
                  tooltipContent: 'Адрес можно изменить в разделе "Моя организация"',
                  tooltipProps: {
                    width: 'm',
                  },
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default One
