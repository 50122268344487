import React from 'react'
import { useFormContext } from 'react-hook-form'

import InvestorSection from '@components/DocumentFormComponents/FormSections/InvestorSection'
import { useNoticeOfRejectConcludeSZPKManager } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/Manager'
import { NoticeOfRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/types'
import { NoticeOfRejectConcludeSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/watcher'

const Third = () => {
  const formInstance = useFormContext<NoticeOfRejectConcludeSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOfRejectConcludeSZPKManager()

  return (
    <InvestorSection
      fullNameGenitiveRenderCondition
      fullNameInstrumentalRenderCondition
      nameOrganizationChangeTooltipRenderCondition={false}
      authorizedPersonGroupRenderCondition={false}
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'3'}
      formInstance={formInstance}
      watcher={NoticeOfRejectConcludeSZPKFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Third
