import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { parametersOfCostRecoveryApplicationBlockValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Second = () => {
  const formInstance = useFormContext<ParametersOfCostRecoveryApplicationFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getControllerProps, getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
  })

  const szpkNumber = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['2'].szpkNumber,
    control: formInstance.control
  })
  
  const szpkRegistrationDate = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['2'].szpkRegistrationDate,
    control: formInstance.control
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['2'].szpkNumber,
              })}
            >
              <ControlledDocumentDataView
                suptitle={szpkNumber ? '' : '№'}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['2'].szpkNumber,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['2'].szpkRegistrationDate,
              })}
            >
              <ControlledDocumentDataView
                suptitle={szpkRegistrationDate ? '' : 'от'}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['2'].szpkRegistrationDate,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['2'].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование проекта'}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['2'].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
