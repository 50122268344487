import { PopupProps } from 'react-popup-manager'

import { IViolation } from '@components/DocumentFormComponents/types'
import { NpaFormTypes } from '@services/NPA/NPA.entity'
import { Dayjs } from 'dayjs'

export const DfoGroupTypes = {
  SZPK: 'SZPK',
  EXTRA: 'EXTRA',
  MONITORING: 'MONITORING',
  COST_RECOVERY: 'COST_RECOVERY',
} as const

export type GetVersionData = {
  id: string
  isActual: boolean
  version: string
  versionNumber: number
  versionDateTime: string
  dfoReadPermission: boolean
}

export interface DocumentSetInfo {
  documentSetId: string
  permissions: number
  isOwner: boolean
  ownerOrganizationId: string
  ownerOrganizationName: string
  ownerOrganizationFullName: string | null
  documentTypeInfo?: DocumentTypeInfo[]
}

export interface DocumentTypeInfo {
  description?: string
  isDraftExists?: boolean
  documents?: DocumentInfo[]
  folders?: FolderInfo[] | null
}

export interface DocumentInfo {
  id: string
  number?: string
  fields?: any //Map<String, Object> ???
  versionId: string
  name: string
  extension: string
  size: number
  isEditable: boolean
  version?: number
  versionChangeState?: string
  // isApproved ???
}

export interface FolderInfo {
  id: string
  name: string
  updateDt: string
  documents: DocumentInfo[]
}

export const PROJECT_COMPENSATION_PERIOD = {
  _5_YEARS: '_5_YEARS',
  _10_YEARS: '_10_YEARS',
} as const

export interface IStatementCompensationFromBackend {
  projectCompensationType: string
  projectCompensationForm: string
  projectCompensationValue: number
  projectCompensationPeriod: string
}

export interface IStatementOldCompensationFromBackend {
  type: string
  format: string
  value: number
  reimbursementPeriod: string
}

export interface IStatementCompensation
  extends Pick<
    IStatementCompensationFromBackend,
    'projectCompensationType' | 'projectCompensationForm'
  > {
  id: string
  projectCompensationValue: string
}

export interface IStatementOldCompensation
  extends Pick<IStatementOldCompensationFromBackend, 'type' | 'format'> {
  value: string
}

export interface IStatementStage {
  name: string
  startDate: string | Dayjs
  endDate: string | Dayjs
}

export interface IStatementStepOfStage {
  id: string
  stepDescription: string
  stepStartDate: string | Dayjs
  stepEndDate: string | Dayjs
}

export interface IStatementTaxFromBackend {
  taxYear: string
  taxYearValue: number | null
}

export interface IStatementOldTaxFromBackend {
  taxYear: string
  plannedTax: number | null
}

export interface IGetStatementOldResponse {
  investorGeneralInfo: {
    fullName: string
    name: string
    inn: string
    kpp: string | null
    ogrn: string
    head: string
    position: string | null
    reasonGenitive: string | null
    positionGenitive: string | null
    headGenitive: string | null
    reason: string | null
    okpo: string | null
    okved: string | null
    capital: number | null
    isProjectCompany: boolean
    isVed: boolean
  }

  investorAddress: {
    subject: string | null
    district: string | null
    area: string | null
    city: string | null
    planningStructureElement: string | null
    roadNetworkElement: string | null
    house: string | null
    room: string | null
    roomPart: string | null
  }

  generalInfo: {
    ruleParagraph: string | null
    headPhone: string | null
    headAdditionalPhone: string | null
    headEmail: string[] | null
    characteristic: string | null
    activityKind: string | null
    activityKindOkved: string | null
    startDate: string | null
    projectEndDate: string | Dayjs | null
    szpkEndDate: string | null
    newWorkplaces: number | null
    newBuildingWorkplaces: number | null
  }

  participantsAndInvestments: {
    decisionDate: string | null
    endDate: string | Dayjs | null
    investorCapital: number | null
    investorCapitalVAT: number | null
    totalCapital: number | null
    totalCapitalVAT: number | null
    taxes: IStatementOldTaxFromBackend[]
  }

  stages: IStatementStage[]
  compensations: IStatementOldCompensationFromBackend[]
}

export interface IDefaultStatementOldUrlParams {
  projectId: string
  dfoId: string
  documentSetId: string
}

export interface IDefaultFormUrlParams {
  formId: string
}

export interface IExtendedFormUrlParams extends IDefaultFormUrlParams {
  projectId: string
}

export interface IFormModalParams extends IExtendedFormUrlParams {
  editMode: boolean
  rolesOfUser: string[]
  documentsTypeOfSet: string
  onClose: PopupProps['onClose']
  initialErrorsFromViolations?: IViolation[]
}

export interface INpaFormParams extends IExtendedFormUrlParams {
  documentType: NpaFormTypes
  initialErrorsFromViolations?: IViolation[]
  onRemoveNpaForm?: () => Promise<void>
}

export interface IDfoDeadline {
  dfoId: string
  deadline: string
  deadlineRemind: string
  organizationId: string
  organizationName: string
}

export interface IDfoDeadlineWithOrganizationNames extends IDfoDeadline {
  organizationNames: string[]
}
