import { generatePropertiesObjectForPetitionRecognizePreviouslyConcludedContractAsBoundForm } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/adapters/PropertiesObject.adapter'
import { generateRHFObjectForPetitionRecognizePreviouslyConcludedContractAsBoundForm } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/adapters/RHF.adapter'
import { PetitionRecognizePreviouslyConcludedContractAsBoundFormValues } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/types'

const usePetitionRecognizePreviouslyConcludedContractAsBoundAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): PetitionRecognizePreviouslyConcludedContractAsBoundFormValues =>
    generateRHFObjectForPetitionRecognizePreviouslyConcludedContractAsBoundForm(
      objectFromPropertyState,
    )

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForPetitionRecognizePreviouslyConcludedContractAsBoundForm(
      objectFromPropertyState,
    )

  return { generateRHFObject, generatePropertiesObject }
}

export { usePetitionRecognizePreviouslyConcludedContractAsBoundAdapters }
