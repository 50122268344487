import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForAAVolumeOfCapitalInvestmentsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedInvestmentsSteps = (() => {
    if (
      !isArray(objectFromPropertyState.steps?.value) ||
      !objectFromPropertyState.steps?.value.length
    )
      return []

    return objectFromPropertyState.steps?.value.map((step) => {
      const oldStepExpenses = objectFromPropertyState?.oldStepExpenses?.value?.filter(
        (oldStepExpense) => oldStepExpense?.value?.oldStepIdExpense?.value?.id === step.permanentId,
      )

      const preparedOldStepExpenses = (() => {
        if (!isArray(oldStepExpenses) || !oldStepExpenses.length) return []

        return oldStepExpenses.map((oldStepExpenses) => ({
          propertyId: oldStepExpenses.propertyId,
          type: oldStepExpenses.type,
          lastUpdateDt: oldStepExpenses.lastUpdateDt,
          value: {
            oldStepExpenseName: {
              propertyId: oldStepExpenses?.value.oldStepExpenseName?.propertyId,
              type: oldStepExpenses?.value.oldStepExpenseName?.type,
              lastUpdateDt: oldStepExpenses?.value.oldStepExpenseName?.lastUpdateDt,
            },
            oldStepExpenseValue: {
              propertyId: oldStepExpenses?.value.oldStepExpenseValue?.propertyId,
              type: oldStepExpenses?.value.oldStepExpenseValue?.type,
              lastUpdateDt: oldStepExpenses?.value.oldStepExpenseValue?.lastUpdateDt,
            },
          },
        }))
      })()

      const preparedOldStepInvestorCapitalSources = (() => {
        if (
          !isArray(step?.value.oldStepInvestorCapitalSources?.value) ||
          !step?.value.oldStepInvestorCapitalSources?.value.length
        )
          return []

        return step?.value.oldStepInvestorCapitalSources?.value.map(
          (oldStepInvestorCapitalSource) => ({
            propertyId: oldStepInvestorCapitalSource.propertyId,
            type: oldStepInvestorCapitalSource.type,
            lastUpdateDt: oldStepInvestorCapitalSource.lastUpdateDt,
            value: {
              oldStepInvestorCapitalSource: {
                propertyId: oldStepInvestorCapitalSource?.propertyId,
                type: oldStepInvestorCapitalSource?.type,
                lastUpdateDt: oldStepInvestorCapitalSource?.lastUpdateDt,
              },
            },
          }),
        )
      })()

      return {
        propertyId: step?.propertyId,
        permanentId: step?.permanentId,
        type: step?.type,
        lastUpdateDt: step?.lastUpdateDt,
        value: {
          stepNumber: {
            propertyId: step?.value.stepNumber?.propertyId,
            type: step?.value.stepNumber?.type,
            lastUpdateDt: step?.value.stepNumber?.lastUpdateDt,
          },
          stepDescription: {
            propertyId: step?.value.stepDescription?.propertyId,
            type: step?.value.stepDescription?.type,
            lastUpdateDt: step?.value.stepDescription?.lastUpdateDt,
          },
          stepInvestorCapitalValue: !!objectFromPropertyState?.isPaper?.value
            ? {
                propertyId: step?.value.stepInvestorCapitalValueVAT?.propertyId,
                type: step?.value.stepInvestorCapitalValueVAT?.type,
                lastUpdateDt: step?.value.stepInvestorCapitalValueVAT?.lastUpdateDt,
              }
            : {
                propertyId: step?.value.stepInvestorCapitalValue?.propertyId,
                type: step?.value.stepInvestorCapitalValue?.type,
                lastUpdateDt: step?.value.stepInvestorCapitalValue?.lastUpdateDt,
              },
          stepInvestorCapitalDate: {
            propertyId: step?.value.stepInvestorCapitalDate?.propertyId,
            type: step?.value.stepInvestorCapitalDate?.type,
            lastUpdateDt: step?.value.stepInvestorCapitalDate?.lastUpdateDt,
          },
          oldStepInvestorCapitalSources: {
            propertyId: step?.value.oldStepInvestorCapitalSources?.propertyId,
            type: step?.value.oldStepInvestorCapitalSources?.type,
            lastUpdateDt: step?.value.oldStepInvestorCapitalSources?.lastUpdateDt,
            value: preparedOldStepInvestorCapitalSources,
          },
          oldStepExpenses: {
            propertyId: objectFromPropertyState?.oldStepExpenses?.propertyId,
            type: objectFromPropertyState?.oldStepExpenses?.type,
            lastUpdateDt: objectFromPropertyState?.oldStepExpenses?.lastUpdateDt,
            value: preparedOldStepExpenses,
          },
        },
      }
    })
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      capitalSteps: {
        propertyId: objectFromPropertyState.steps?.propertyId,
        type: objectFromPropertyState.steps?.type,
        lastUpdateDt: objectFromPropertyState.steps?.lastUpdateDt,
        value: preparedInvestmentsSteps,
      },
    },
    '6': {
      totalCapital: !!objectFromPropertyState.isPaper?.value
        ? {
            propertyId: objectFromPropertyState.totalCapitalVAT?.propertyId,
            type: objectFromPropertyState.totalCapitalVAT?.type,
            lastUpdateDt: objectFromPropertyState.totalCapitalVAT?.lastUpdateDt,
          }
        : {
            propertyId: objectFromPropertyState.totalCapital?.propertyId,
            type: objectFromPropertyState.totalCapital?.type,
            lastUpdateDt: objectFromPropertyState.totalCapital?.lastUpdateDt,
          },
      investorCapital: !!objectFromPropertyState.isPaper?.value
        ? {
            propertyId: objectFromPropertyState.investorCapitalVAT?.propertyId,
            type: objectFromPropertyState.investorCapitalVAT?.type,
            lastUpdateDt: objectFromPropertyState.investorCapitalVAT?.lastUpdateDt,
          }
        : {
            propertyId: objectFromPropertyState.investorCapital?.propertyId,
            type: objectFromPropertyState.investorCapital?.type,
            lastUpdateDt: objectFromPropertyState.investorCapital?.lastUpdateDt,
          },
      remainsInvestorCapital: !!objectFromPropertyState.isPaper?.value
        ? {
            propertyId: objectFromPropertyState.remainsInvestorCapitalVAT?.propertyId,
            type: objectFromPropertyState.remainsInvestorCapitalVAT?.type,
            lastUpdateDt: objectFromPropertyState.remainsInvestorCapitalVAT?.lastUpdateDt,
          }
        : {
            propertyId: objectFromPropertyState.remainsInvestorCapital?.propertyId,
            type: objectFromPropertyState.remainsInvestorCapital?.type,
            lastUpdateDt: objectFromPropertyState.remainsInvestorCapital?.lastUpdateDt,
          },
      madeInvestorCapitalDate: {
        propertyId: objectFromPropertyState.madeInvestorCapitalDate?.propertyId,
        type: objectFromPropertyState.madeInvestorCapitalDate?.type,
        lastUpdateDt: objectFromPropertyState.madeInvestorCapitalDate?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForAAVolumeOfCapitalInvestmentsForm }
