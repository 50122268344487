import { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm'
import {
  defaultNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues,
  mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu,
} from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/const'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayoutWrapper from './Wrapper'

const NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayout: FC<
  FormModalLayoutProps
> = ({ projectId, formId, isOpen, onClose, initialErrorsFromViolations, editMode }) => {
  const formInstance =
    useFormWithDefaultProps<NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues>(
      {
        defaultValues:
          defaultNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues,
      },
    )

  const handleOnClose = () => {
    NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={
              mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu[0]
                .id
            }
          >
            <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление о невозможности удовлетворения ходатайства о признании ранее
                  заключенного договора связанным договором
                </Typography.Headline>
                <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--xl'])}
              >
                <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny.Menu
                  className={styles.layout__menu}
                />
              </div>
            </NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayoutWrapper>
          </FlatMenuManager>
        </NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDeny.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyLayout,
)
