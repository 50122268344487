import {
  NewStage,
  OnCompletionStage,
  OnSignatureStage,
  RegistratedStage,
  RejectedStage,
  RevokedStage,
  SuccessStage,
  UserRole,
} from '@interfaces'

export const NewDfosType = {
  SZPK: 'SZPK',
  INVESTPROJECT: 'INVESTPROJECT',
  SZPK_TERMINATE: 'SZPK_TERMINATE',
  SZPK_TERMINATE_ONE_SIDE: 'SZPK_TERMINATE_ONE_SIDE',
  SZPK_TERMINATE_JUDGMENT: 'SZPK_TERMINATE_JUDGMENT',
  RELATED_CONTRACT_LIST: 'RELATED_CONTRACT_LIST',
  NPA_LIST: 'NPA_LIST',
  NPA_LIST_UPDATE: 'NPA_LIST_UPDATE',
  NPA_LIST_REGIONAL: 'NPA_LIST_REGIONAL',
  EXTRA_TERM_EXTENSION: 'EXTRA_TERM_EXTENSION',
  EXTRA_MUNICIPAL_ACCESSION: 'EXTRA_MUNICIPAL_ACCESSION',
  EXTRA_RIGHTS_TRANSFER: 'EXTRA_RIGHTS_TRANSFER',
  EXTRA_FORCE_MAJEURE: 'EXTRA_FORCE_MAJEURE',
  EXTRA_CONCESSION_FAIL: 'EXTRA_CONCESSION_FAIL',
  EXTRA_CONSTRUCTION_CHANGE: 'EXTRA_CONSTRUCTION_CHANGE',
  EXTRA_SUPPORTING_INFRASTRUCTURE: 'EXTRA_SUPPORTING_INFRASTRUCTURE',
  EXTRA_INVESTMENT_CHANGE: 'EXTRA_INVESTMENT_CHANGE',
  EXTRA_REIMBURSEMENT_CHANGE: 'EXTRA_REIMBURSEMENT_CHANGE',
  EXTRA_ORP_REORGANIZATION: 'EXTRA_ORP_REORGANIZATION',
  EXTRA_DISTRIBUTION_EXPEND: 'EXTRA_DISTRIBUTION_EXPEND',
  EXTRA_RELATED_CONTRACT: 'EXTRA_RELATED_CONTRACT',
  RCL: 'RCL',

  MONITORING_REPORT_REGIONAL: 'MONITORING_REPORT_REGIONAL',
  MONITORING_REPORT_FEDERAL: 'MONITORING_REPORT_FEDERAL',
  MONITORING_REPORT_SZPK: 'MONITORING_REPORT_SZPK',

  CLAIM_SUBSIDY_PERMIT_INFRASTRUCTURE: 'CLAIM_SUBSIDY_PERMIT_INFRASTRUCTURE',
  CLAIM_SUBSIDY_PERMIT_DEBT: 'CLAIM_SUBSIDY_PERMIT_DEBT',
  CLAIM_SUBSIDY_PERMIT_DESTRUCTION: 'CLAIM_SUBSIDY_PERMIT_DESTRUCTION',
  CLAIM_SUBSIDY_PERMIT_BUILDING_APARTMENTS: 'CLAIM_SUBSIDY_PERMIT_BUILDING_APARTMENTS',

  OTHER_DONATIONS: 'OTHER_DONATIONS',

  SZPK_RIGHTS_TRANSFER: 'SZPK_RIGHTS_TRANSFER',
  SZPK_SIGNER_INFO_CHANGES: 'SZPK_SIGNER_INFO_CHANGES',

  CESSION: 'CESSION',
  CESSION_APPLY: 'CESSION_APPLY',
  CESSION_REGISTER: 'CESSION_REGISTER',
  CESSION_RETURN: 'CESSION_RETURN',
  CESSION_RETURN_HISTORICAL: 'CESSION_RETURN_HISTORICAL',
  CESSION_RETURN_STOP: 'CESSION_RETURN_STOP',
  CESSION_REVISION: 'CESSION_REVISION',
  CESSION_REVOKE: 'CESSION_REVOKE',
  SYSTEM_PROCURATIONS: 'SYSTEM_PROCURATIONS',

  ORG_REORGANIZATION: 'ORG_REORGANIZATION',
  ORG_REORGANIZATION_SPLIT_OFF: 'ORG_REORGANIZATION_SPLIT_OFF',

  MUNICIPAL_ACCESSION: 'MUNICIPAL_ACCESSION',
  MUNICIPAL_ACCESSION_UPDATE: 'MUNICIPAL_ACCESSION_UPDATE',
} as const

export const DfosType = Object.freeze({
  SZPK: 'SZPK',
  APPLY: 'APPLY',
  REJECT: 'REJECT',
  RETURN: 'RETURN',
  APPROVE: 'APPROVE',
  SZPK_EXTRA: 'SZPK_EXTRA',
  APPLY_RETURN: 'APPLY_RETURN',
  SZPK_TERMINATE: 'SZPK_TERMINATE',
  REGISTRATION: 'REGISTRATION',
  SYSTEM_PROCURATIONS: 'SYSTEM_PROCURATIONS',
  SZPK_TERMINATE_UNILAT: 'SZPK_TERMINATE_UNILAT',
})

export const DfosStage = {
  DRAFT: 'DRAFT',
  INITIATOR_SIGNED: 'INITIATOR_SIGNED',
  CHECKING: 'CHECKING',
  FINAL_CHECKING: 'FINAL_CHECKING',
  COMPLETING: 'COMPLETING',
  ACCEPTED: 'ACCEPTED',
  ANSWERING: 'ANSWERING',
  ANSWERED: 'ANSWERED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REJECTING_AFTER_INVESTPROJECT_REJECT: 'REJECTING_AFTER_INVESTPROJECT_REJECT',
  REJECTING_AFTER_CHECKING: 'REJECTING_AFTER_CHECKING',
  APPLYING: 'APPLYING',
  RETURNED: 'RETURNED',
  EXPIRED_AFTER_RETURN: 'EXPIRED_AFTER_RETURN',
  STOPPED: 'STOPPED',
  INITIATOR_REVOKED: 'INITIATOR_REVOKED',
  SIGNING: 'SIGNING',
  REGISTERED: 'REGISTERED',
  TERMINATE: 'TERMINATE',
  SIGNED: 'SIGNED',
  SIGNED_AFTER_DEADLINE: 'SIGNED_AFTER_DEADLINE',
  COMPLEMENTING: 'COMPLEMENTING',
  PARTNER_REVOKED: 'PARTNER_REVOKED',
  PARTNER_REJECTED: 'PARTNER_REJECTED',
  REVISIONING: 'REVISIONING',
  REVISIONING_PARTNER: 'REVISIONING_PARTNER',
  REVISONED: 'REVISONED',
  PREPARING: 'PREPARING',
  NOTIFIED: 'NOTIFIED',
  TERMINATING: 'TERMINATING',
  TERMINATED: 'TERMINATED',
  INITIATOR_REVOKED_NOTICE: 'INITIATOR_REVOKED_NOTICE',
  FIRST_SIGNER_REJECTED: 'FIRST_SIGNER_REJECTED',
  FIRST_CHECKER_REJECTED: 'FIRST_CHECKER_REJECTED',
} as const

export const DocumentsSetsType = {
  SZPK: 'SZPK',
  SZPK_APPROVE: 'SZPK_APPROVE',
  SZPK_REJECT_DRAFT: 'SZPK_REJECT_DRAFT',
  SZPK_WORKGROUP_REJECT: 'SZPK_WORKGROUP_REJECT',
  SZPK_RETURN: 'SZPK_RETURN',
  SZPK_REJECT: 'SZPK_REJECT',
  SZPK_REVOKE: 'SZPK_REVOKE',
  SZPK_REGISTER: 'SZPK_REGISTER',
  SZPK_CHANGES: 'SZPK_CHANGES',
  SZPK_CHANGES_NOTICE: 'SZPK_CHANGES_NOTICE',
  SZPK_CHANGES_NOTICE_TEMP: 'SZPK_CHANGES_NOTICE_TEMP',

  INVESTPROJECT: 'INVESTPROJECT',
  INVESTPROJECT_APPROVE: 'INVESTPROJECT_APPROVE',
  INVESTPROJECT_REJECT: 'INVESTPROJECT_REJECT',
  INVESTPROJECT_CHANGES: 'INVESTPROJECT_CHANGES',
  INVESTPROJECT_DECISION_CONFIRMATION: 'INVESTPROJECT_DECISION_CONFIRMATION',

  NPA_LIST: 'NPA_LIST',
  NPA_LIST_APPROVE: 'NPA_LIST_APPROVE',
  NPA_LIST_RETURN: 'NPA_LIST_RETURN',
  NPA_LIST_CHANGES: 'NPA_LIST_CHANGES',

  NPA_LIST_UPDATE: 'NPA_LIST_UPDATE',
  NPA_LIST_UPDATE_REJECT: 'NPA_LIST_UPDATE_REJECT',
  NPA_LIST_UPDATE_APPLY: 'NPA_LIST_UPDATE_APPLY',
  NPA_LIST_UPDATE_APPLIED: 'NPA_LIST_UPDATE_APPLIED',
  NPA_LIST_UPDATE_REGISTER: 'NPA_LIST_UPDATE_REGISTER',

  RELATED_CONTRACT_LIST: 'RELATED_CONTRACT_LIST',
  RELATED_CONTRACT_LIST_APPROVE: 'RELATED_CONTRACT_LIST_APPROVE',
  RELATED_CONTRACT_LIST_AGREED: 'RELATED_CONTRACT_LIST_AGREED',
  RELATED_CONTRACT_LIST_RETURN: 'RELATED_CONTRACT_LIST_RETURN',
  RELATED_CONTRACT_LIST_REJECT_DRAFT: 'RELATED_CONTRACT_LIST_REJECT_DRAFT',
  RELATED_CONTRACT_LIST_REJECT: 'RELATED_CONTRACT_LIST_REJECT',
  RELATED_CONTRACT_LIST_REGISTER: 'RELATED_CONTRACT_LIST_REGISTER',
  RELATE_CONTRACT_LIST_CHANGES: 'RELATE_CONTRACT_LIST_CHANGES',
  RELATED_CONTRACT_LIST_REVOKE: 'RELATED_CONTRACT_LIST_REVOKE',
  RELATED_CONTRACT_LIST_CHANGES: 'RELATED_CONTRACT_LIST_CHANGES',

  SZPK_EXTRA_APPROVE: 'SZPK_EXTRA_APPROVE',
  SZPK_EXTRA_REVOKE: 'SZPK_EXTRA_REVOKE',
  SZPK_EXTRA_REJECT: 'SZPK_EXTRA_REJECT',
  SZPK_EXTRA_REJECT_DRAFT: 'SZPK_EXTRA_REJECT_DRAFT',
  SZPK_EXTRA_RETURN: 'SZPK_EXTRA_RETURN',
  SZPK_EXTRA_REGISTER: 'SZPK_EXTRA_REGISTER',

  SZPK_TERMINATE: 'SZPK_TERMINATE',
  SZPK_TERMINATE_REJECT: 'SZPK_TERMINATE_REJECT',
  SZPK_TERMINATE_REGISTER: 'SZPK_TERMINATE_REGISTER',
  SZPK_TERMINATE_REVOKE: 'SZPK_TERMINATE_REVOKE',

  SZPK_TERMINATE_ONE_SIDE: 'SZPK_TERMINATE_ONE_SIDE',
  SZPK_TERMINATE_ONE_SIDE_REGISTER: 'SZPK_TERMINATE_ONE_SIDE_REGISTER',
  SZPK_TERMINATE_ONE_SIDE_REVOKE: 'SZPK_TERMINATE_ONE_SIDE_REVOKE',

  SZPK_TERMINATE_JUDGMENT: 'SZPK_TERMINATE_JUDGMENT',
  SZPK_TERMINATE_JUDGMENT_REGISTER: 'SZPK_TERMINATE_JUDGMENT_REGISTER',
  SZPK_TERMINATE_JUDGMENT_REVOKE: 'SZPK_TERMINATE_JUDGMENT_REVOKE',

  MONITORING_REPORT_SZPK: 'MONITORING_REPORT_SZPK',
  MONITORING_REPORT_SZPK_RETURN: 'MONITORING_REPORT_SZPK_RETURN',
  MONITORING_REPORT_SZPK_APPROVE: 'MONITORING_REPORT_SZPK_APPROVE',
  MONITORING_REPORT_SZPK_ACCEPT: 'MONITORING_REPORT_SZPK_ACCEPT',
  MONITORING_REPORT_SZPK_REJECT: 'MONITORING_REPORT_SZPK_REJECT',
  MONITORING_REPORT_SZPK_WARNING: 'MONITORING_REPORT_SZPK_WARNING',
  MONITORING_REPORT_SZPK_STAGE_REFERENCE: 'MONITORING_REPORT_SZPK_STAGE_REFERENCE',
  MONITORING_REPORT_SZPK_INVESTOR_ANSWER: 'MONITORING_REPORT_SZPK_INVESTOR_ANSWER',
  MONITORING_REPORT_SZPK_REGISTER: 'MONITORING_REPORT_SZPK_REGISTER',
  MONITORING_REPORT_SZPK_REVOKE: 'MONITORING_REPORT_SZPK_REVOKE',
  MONITORING_REPORT_SZPK_NO_COMPLETE: 'MONITORING_REPORT_SZPK_NO_COMPLETE',

  MONITORING_REPORT_REGIONAL: 'MONITORING_REPORT_REGIONAL',

  MONITORING_REPORT_FEDERAL: 'MONITORING_REPORT_FEDERAL',

  ORGANIZATION_INFO_CHANGES: 'ORGANIZATION_INFO_CHANGES',

  OTHER_DONATIONS: 'OTHER_DONATIONS',
  OTHER_DONATIONS_REVOKE: 'OTHER_DONATIONS_REVOKE',

  CLAIM_SUBSIDY_PERMIT_ANALYSIS: 'CLAIM_SUBSIDY_PERMIT_ANALYSIS',
  CLAIM_SUBSIDY_PERMIT_APPROVE: 'CLAIM_SUBSIDY_PERMIT_APPROVE',
  CLAIM_SUBSIDY_PERMIT_BUILDING_APARTMENTS: 'CLAIM_SUBSIDY_PERMIT_BUILDING_APARTMENTS',
  CLAIM_SUBSIDY_PERMIT_DEBT: 'CLAIM_SUBSIDY_PERMIT_DEBT',
  CLAIM_SUBSIDY_PERMIT_DESTRUCTION: 'CLAIM_SUBSIDY_PERMIT_DESTRUCTION',
  CLAIM_SUBSIDY_PERMIT_INFRASTRUCTURE: 'CLAIM_SUBSIDY_PERMIT_INFRASTRUCTURE',
  CLAIM_SUBSIDY_PERMIT_REJECT: 'CLAIM_SUBSIDY_PERMIT_REJECT',
  CLAIM_SUBSIDY_PERMIT_RETURN: 'CLAIM_SUBSIDY_PERMIT_RETURN',
  CLAIM_SUBSIDY_PERMIT_REVOKE: 'CLAIM_SUBSIDY_PERMIT_REVOKE',
  DEPARTMENTS_RESPONSES: 'DEPARTMENTS_RESPONSES',

  CESSION: 'CESSION',
  CESSION_REVISION: 'CESSION_REVISION',
  CESSION_APPLY: 'CESSION_APPLY',
  CESSION_REGISTER: 'CESSION_REGISTER',
  CESSION_RETURN: 'CESSION_RETURN',
  CESSION_RETURN_HISTORICAL: 'CESSION_RETURN_HISTORICAL',
  CESSION_RETURN_STOP: 'CESSION_RETURN_STOP',
  CESSION_RETURN_STOP_HISTORICAL: 'CESSION_RETURN_STOP_HISTORICAL',
  CESSION_REVOKE: 'CESSION_REVOKE',

  SZPK_RIGHTS_TRANSFER: 'SZPK_RIGHTS_TRANSFER',
  SZPK_RIGHTS_TRANSFER_INITIATOR: 'SZPK_RIGHTS_TRANSFER_INITIATOR',
  SZPK_RIGHTS_TRANSFER_PARTNER: 'SZPK_RIGHTS_TRANSFER_PARTNER',
  SZPK_RIGHTS_TRANSFER_SOLICITATION: 'SZPK_RIGHTS_TRANSFER_SOLICITATION',
  SZPK_RIGHTS_TRANSFER_PARTNER_REJECT: 'SZPK_RIGHTS_TRANSFER_PARTNER_REJECT',
  SZPK_RIGHTS_TRANSFER_REVISION: 'SZPK_RIGHTS_TRANSFER_REVISION',
  SZPK_RIGHTS_TRANSFER_INITIATOR_REVISION: 'SZPK_RIGHTS_TRANSFER_INITIATOR_REVISION',
  SZPK_RIGHTS_TRANSFER_PARTNER_REVISION: 'SZPK_RIGHTS_TRANSFER_PARTNER_REVISION',
  SZPK_RIGHTS_TRANSFER_SOLICITATION_REVISION: 'SZPK_RIGHTS_TRANSFER_SOLICITATION_REVISION',
} as const

export const ExcludedDocumentsSetsTypes = {
  SZPK_REGISTER: 'SZPK_REGISTER',
} as const

export const DocumentsType = {
  PROJECT: 'PROJECT',
  NPA_LIST: 'NPA_LIST',
  NPA_LIST_REGIONAL: 'NPA_LIST_REGIONAL',
  STATEMENT: 'STATEMENT',
  ADDITIONAL_DOCUMENT: 'ADDITIONAL_DOCUMENT',
  SOLICITATION_RELATED_CONTRACT: 'SOLICITATION_RELATED_CONTRACT',
  SOLICITATION_ADDITIONAL_DUTY: 'SOLICITATION_ADDITIONAL_DUTY',
  ORGANIZATION_INFO_CHANGES: 'ORGANIZATION_INFO_CHANGES',
} as const

export const WebFormTypes = {
  // Первый этап
  STATEMENT: 'STATEMENT',
  PROJECT: 'PROJECT',
  INVESTMENTS_MADE_STATEMENT: 'INVESTMENTS_MADE_STATEMENT',
  INVESTPROJECT_FINANCIAL_MODEL_PROPERTIES: 'INVESTPROJECT_FINANCIAL_MODEL_PROPERTIES',
  BENEFICIAL_OWNERS_APPLICANT: 'BENEFICIAL_OWNERS_APPLICANT',
  REIMBURSABLE_INFRASTRUCTURE_LIST: 'REIMBURSABLE_INFRASTRUCTURE_LIST',

  // Второй этап
  PROJECT_SUPPORTING_INFRASTRUCTURE: 'PROJECT_SUPPORTING_INFRASTRUCTURE',
  PROJECT_RIGHTS_TRANSFER: 'PROJECT_RIGHTS_TRANSFER',
  PROJECT_REIMBURSEMENT_CHANGE: 'PROJECT_REIMBURSEMENT_CHANGE',
  PROJECT_INVESTMENT_CHANGE: 'PROJECT_INVESTMENT_CHANGE',
  PROJECT_TERM_EXTENSION: 'PROJECT_TERM_EXTENSION',
  PROJECT_CONSTRUCTION_CHANGE: 'PROJECT_CONSTRUCTION_CHANGE',
  BENEFICIAL_OWNERS_APPLICANT_PARTNER: 'BENEFICIAL_OWNERS_APPLICANT_PARTNER',
  PROJECT_FORCE_MAJEURE: 'PROJECT_FORCE_MAJEURE',
  PROJECT_MUNICIPAL_ACCESSION: 'PROJECT_MUNICIPAL_ACCESSION',
  INVESTPROJECT_STATEMENT: 'INVESTPROJECT_STATEMENT',
  MUNICIPALITY_STATEMENT: 'MUNICIPALITY_STATEMENT',

  // Третий этап
  UO_RETURN_CONCLUSION: 'UO_RETURN_CONCLUSION',
  RETURN_CONCLUSION: 'RETURN_CONCLUSION',
  RESULT_DENY: 'RESULT_DENY',
  PROJECT_RESULT_DENY: 'PROJECT_RESULT_DENY',
  STATEMENT_RELATED_CONTRACT_INCLUSION: 'STATEMENT_RELATED_CONTRACT_INCLUSION',
  RESULT_RETURN_RELATED_CONTRACT: 'RESULT_RETURN_RELATED_CONTRACT',
  RESULT_DENY_RELATED_CONTRACT: 'RESULT_DENY_RELATED_CONTRACT',
  PROJECT_RESULT_DENY_RELATED_CONTRACT: 'PROJECT_RESULT_DENY_RELATED_CONTRACT',
  RESULT_APPROVE_RELATED_CONTRACT: 'RESULT_APPROVE_RELATED_CONTRACT',
  PROJECT_DISTRIBUTION_EXPEND: 'PROJECT_DISTRIBUTION_EXPEND',
  RESULT_APPROVE_EXTRA: 'RESULT_APPROVE_EXTRA',
  UO_RETURN_CONCLUSION_EXTRA: 'UO_RETURN_CONCLUSION_EXTRA',
  SZPK_EXTRA_RETURN: 'SZPK_EXTRA_RETURN',
  SZPK_TERMINATE_ONE_SIDE_NOTICE: 'SZPK_TERMINATE_ONE_SIDE_NOTICE',
  TERMINATION_NOTICE: 'TERMINATION_NOTICE',
  PROJECT_SZPK_TERMINATION: 'PROJECT_SZPK_TERMINATION',
  SOLICITATION_RIGHTS_TRANSFER: 'SOLICITATION_RIGHTS_TRANSFER',
  RESULT_DENY_EXTRA: 'RESULT_DENY_EXTRA',
  PROJECT_RESULT_DENY_EXTRA: 'PROJECT_RESULT_DENY_EXTRA',
  PROJECT_CONCESSION_FAIL: 'PROJECT_CONCESSION_FAIL',
  STATEMENT_EXTRA: 'STATEMENT_EXTRA',
  SZPK_REVOKE: 'SZPK_REVOKE',
  PROJECT_ORG_REORGANIZATION: 'PROJECT_ORG_REORGANIZATION',
  PROJECT_RELATED_CONTRACT: 'PROJECT_RELATED_CONTRACT',
  CHANGES_NOTICE: 'CHANGES_NOTICE',
  SOLICITATION_RELATED_CONTRACT: 'SOLICITATION_RELATED_CONTRACT',
  STATEMENT_RELATED_CONTRACT: 'STATEMENT_RELATED_CONTRACT',
  STATEMENT_EXTRA_REGISTER: 'STATEMENT_EXTRA_REGISTER',
  NPA_RETURNING_NOTICE: 'NPA_RETURNING_NOTICE',
  CESSION_NOTICE: 'CESSION_NOTICE',
  ORGANIZATION_INFO_CHANGES: 'ORGANIZATION_INFO_CHANGES',

  // Мониторинг
  MONITORING_REPORT_FEDERAL: 'MONITORING_REPORT_FEDERAL',
  MONITORING_REPORT_REGIONAL: 'MONITORING_REPORT_REGIONAL',
  MONITORING_REPORT_SZPK: 'MONITORING_REPORT_SZPK',
  MONITORING_REPORT_SZPK_APPROVE: 'MONITORING_REPORT_SZPK_APPROVE',
  MONITORING_REPORT_SZPK_STAGE_REFERENCE: 'MONITORING_REPORT_SZPK_STAGE_REFERENCE',
  MONITORING_REPORT_SZPK_INDICATORS: 'MONITORING_REPORT_SZPK_INDICATORS',
  MONITORING_REPORT_SZPK_EVENTS: 'MONITORING_REPORT_SZPK_EVENTS',

  REGISTRATION_DOCUMENT: 'REGISTRATION_DOCUMENT',

  // Возмещение затрат
  CLAIM_PROPERTIES: 'CLAIM_PROPERTIES',
  COSTS_REFUND_FACTS: 'COSTS_REFUND_FACTS',

  // НПА
  NPA_LIST: 'NPA_LIST',
  SOLICITATION_INCLUSION_NPA: 'SOLICITATION_INCLUSION_NPA',
  NPA_LIST_MUNICIPAL: 'NPA_LIST_MUNICIPAL',
} as const

// Типы веб-форм, которые нужно исключить из рендера
export const ExcludedWebFormTypes = {
  SYSTEM_REGNUM_PROPERTY: 'SYSTEM_REGNUM_PROPERTY',
} as const

// Типы веб-форм, для которых нужен отдельный текст
export const defaultTextEditableWebFormTypes = {
  MONITORING_REPORT_SZPK_INDICATORS: 'Открыть форму',
} as const

export const RolesTypes = {
  INVESTOR: 'INVESTOR',
  UO: 'UO',
  OIV: 'OIV',
  // Создан на стороне клиента
  OIV_EXTRA: 'OIV_EXTRA',
  MER: 'MER',
  FK: 'FK',
  OMSU: 'OMSU',
  ROIV: 'ROIV',
  OPERATOR: 'OPERATOR',
} as const

export const DfoAttributeTypes = {
  ownerOrganizationId: 'ownerOrganizationId',
  partnerOrganizationId: 'partnerOrganizationId',
  consider: 'consider',
  newMunicipalityOrganizationId: 'newMunicipalityOrganizationId',
} as const

export const DfoPropertiesTypes = {
  newMunicipalityMemberOrganizationId: 'newMunicipality.memberOrganizationId',
  investorPartnerMemberOrganizationId: 'investorPartner.memberOrganizationId',
} as const

export const SystemVariablesTypes = {
  MONITORING_SZPK_REPORT_APPROVE_ENDS_AT: 'MONITORING_SZPK_REPORT_APPROVE_ENDS_AT',
} as const

export const Roles = Object.freeze<Record<string, UserRole[]>>({
  Operator: ['OPERATOR'],
  Investor: ['INVESTOR'],
  Commissioner: ['UO', 'OIV', 'MER', 'FK', 'OMSU'],
  Header: ['HEADER'],
})

export const UserProfiles = {
  HEADER: 'HEADER',
  EMPLOYEE: 'EMPLOYEE',
  EMPLOYEE_AUTHORIZED: 'EMPLOYEE_AUTHORIZED',
} as const

export const RolesByActions = Object.freeze<Record<string, UserRole[]>>({
  CantCreateNewODO: ['UO', 'FK'],
})

export const ROLES_FOR_REGISTER = ['INVESTOR', 'UO', 'OIV', 'MER', 'FK']

export const StageParams = Object.freeze<{
  Registrated: RegistratedStage[]
  OnSignature: OnSignatureStage[]
  Rejected: RejectedStage[]
  OnCompletion: OnCompletionStage[]
  Revoked: RevokedStage[]
  Success: SuccessStage[]
  New: NewStage[]
}>({
  Registrated: ['REGISTRATED'],
  OnSignature: ['FOR_OIV_SIGN'],
  OnCompletion: ['UO_RETURNED'],
  Revoked: ['INVESTOR_REVOKED'],
  Rejected: ['UO_REJECTED', 'OIV_REJECTED', 'OIV_REJECTING'],
  Success: ['FOR_REGISTRATE', 'APPLY_EDITING', 'FK_RETURNED', 'SIGNED'],
  New: ['INVESTOR_SIGNED', 'UO_APPROVING', 'UO_RETURNING', 'UO_REJECTING', 'DRAFT'],
})

export const defaultStageStatuses = Object.freeze({
  REGISTERED: 'REGISTERED',
  TERMINATING: 'TERMINATING',
  TERMINATED: 'TERMINATED',
})

export const NPAMimeTypes =
  '.rtf,.txt,.doc,.docx,.odt,.xls,.xlsx,.ods,.csv,.pdf,.jpg,.jpeg,.jfif,.pjpeg,.pjp,.png,.tiff'

export const MimeTypes = {
  'text/csv': [
    '.txt',
    '.csv',

    '.png',
    '.jpg',
    '.jpeg',
    '.pjp',
    '.tiff',
    '.jfif',
    '.dwg',
    '.pjpeg',

    '.pdf',
    '.rtf',
    '.doc',

    '.xls',
    '.xlsm',

    '.ppt',
    '.pps',

    '.odt',
    '.ods',
    '.odp',

    '.xlsx',

    '.pptx',
    '.ppsx',

    '.docx',

    '.sig',

    '.rar',
    '.zip',
    '.tar',
    '.7z',
  ],
}

export const accessPermissions = Object.freeze({
  READ: 1,
  EDIT: 2,
  READ_EDIT: 3,
  READ_ADD_SIGN: 7,
  SIGN: 4,
  SIGN_MAIN: 8,
  READ_SIGN: 5,
  READ_SIGN_MAIN: 9,
  READ_APPROVE: 17,
  DOWNLOADING_DOCUMENTS_FORBIDDEN: 32,
  READ_DOWNLOADING_DOCUMENTS_FORBIDDEN: 33,
  READ_DIGITIZING: 65,
})

export const listOfFileTypes = [
  {
    title: 'Текст',
    types: ['RTF', 'TXT', 'DOC', 'DOCX', 'ODT'],
  },
  {
    title: 'Таблицы',
    types: ['XLS', 'XLSX', 'XLSM', 'ODS', 'CSV'],
  },
  {
    title: 'Презентации',
    types: ['XPPT', 'PPTX', 'PPS', 'PPSX', 'ODP'],
  },
  {
    title: 'Фиксированная разметка',
    types: ['PDF'],
  },
  {
    title: 'Изображения',
    types: ['JPG', 'JPEG', 'JFIF', 'PJPEG', 'PJP', 'PNG', 'TIFF'],
  },
  {
    title: 'Проектные данные',
    types: ['DWG'],
  },
  {
    title: 'Архивы',
    types: ['RAR', 'ZIP', 'TAR', '7Z'],
  },
] as const

export const INTERVAL_DURATION = {
  Ten: 10,
} as const

export const documentVersionChangeState = Object.freeze({
  DELETED: 'Удален',
  CHANGED: 'Изменен',
  FIXED: 'Неизменен',
  NEW: 'Новый',
})

export const filterProjectsByActions = Object.freeze({
  initial: 'По умолчанию',
  deadlineOverdueDate: 'По сроку рассмотрения',
  investmentSum: 'По сумме инвестиций',
})
export const sortProjectsByActions = Object.freeze({
  desc: 'Убыванию',
  asc: 'Возрастанию',
})

export const notificationLevelType = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  CRITICAL: 'CRITICAL',
} as const
