import KnowledgeBaseManager from '@components/KnowledgeBase/Manager'

interface KnowledgeBaseProps {
  Manager: typeof KnowledgeBaseManager
}

const KnowledgeBase: KnowledgeBaseProps = () => null

KnowledgeBase.Manager = KnowledgeBaseManager

export default KnowledgeBase
