import React, { FC, memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultNpaMunicipalFormValues } from '@components/Forms/NpaMunicipalForm/const'
import NpaMunicipalLayout from '@components/Forms/NpaMunicipalForm/Layout/index'

const NpaMunicipalLayoutWithFormProvider: FC<NpaFormLayoutProps> = (props) => {
  return (
    <FormProviderWrapper defaultValues={defaultNpaMunicipalFormValues}>
      <NpaMunicipalLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(NpaMunicipalLayoutWithFormProvider)
