import { useCallback, useMemo, useState } from 'react'
import { usePopupManager } from 'react-popup-manager'
import { Column } from 'react-table'

import Icon from '@components/Icon'
import BlockOrganizationModal from '@components/Modals/BlockOrganizationModal'
import Typography from '@components/NewDesign/Typography'
import { _TableState } from '@components/Table'
import { RolesTypes } from '@constants/types'
import { BlockCell } from '@containers/Organizations/BlockCell'
import styles from '@containers/Organizations/Organizations.module.scss'
import { useBlockingOrgs } from '@containers/Organizations/useBlockingOrgs'
import { useAPIContext } from '@context/APIContext'
import { useBooleanState } from '@hooks/useBooleanState'
import useDebounce from '@hooks/useDebounce'
import useInput from '@hooks/useInput'
import lock_outline from '@icons/action/lock_outline.svg'
import cn from 'classnames'
import useSWRImmutable from 'swr/immutable'

const DEBOUNCE_DELAY = 500

export const INITIAL_TABLE_STATE: _TableState = {
  pageIndex: 0,
  pageSize: 10,
}

const uniqKeyService = (() => {
  let key = Math.random()
  return () => ({
    key,
    updateKey: () => (key = Math.random()),
  })
})()

export const useMapHook = () => {
  const popupManager = usePopupManager()

  const { key, updateKey } = uniqKeyService()

  const {
    booleanState: onlyBlocked,
    setBooleanStateToFalse: setOnlyBlockedToFalse,
    setBooleanStateToTrue: setOnlyBlockedToTrue,
  } = useBooleanState()

  const { value: searchString, bind: bindSearchInput, setValue: setSearchValue } = useInput('')

  const [tableState, setTableState] = useState(INITIAL_TABLE_STATE)

  const debouncedSearchString = useDebounce(searchString, DEBOUNCE_DELAY)

  const {
    organizationsApi: { getOrganizationsList, unblock, block },
  } = useAPIContext()

  const {
    data,
    mutate: updateList,
    isValidating,
  } = useSWRImmutable(
    {
      key: `organizations/${tableState.pageSize}}/${key}`,
      page: tableState.pageIndex,
      size: tableState.pageSize,
      blocked: onlyBlocked,
      searchString: debouncedSearchString,
    },
    () =>
      getOrganizationsList({
        type: RolesTypes.INVESTOR,
        page: tableState.pageIndex,
        size: tableState.pageSize,
        searchFields: 'default',
        searchString: debouncedSearchString,
        ...(onlyBlocked && { blocked: true }),
      }),
    {
      revalidateOnMount: true,
    },
  )

  const { addOrg, removeOrg, blockingInProcess } = useBlockingOrgs(updateList)

  const { organizations, totalElements, totalPage } = data ?? {}

  const handleSuccess = useCallback(
    ({ isBlocked, id }) => {
      popupManager.closeAll()
      ;(isBlocked ? unblock(id) : block(id)).then(() => {
        updateKey()
        updateList(undefined, { revalidate: true })
      })
    },
    [block, popupManager, unblock, updateKey, updateList],
  )

  const handleBlockSuccess = useCallback(
    ({ id }) => {
      addOrg(id)
      popupManager.closeAll()
    },
    [addOrg, popupManager],
  )

  const handleBlock = useCallback(
    (original) =>
      popupManager.open(BlockOrganizationModal, {
        orgName: original?.name,
        type: original?.isBlocked ? 'unblock' : 'block',
        onSuccess: () =>
          original?.isBlocked ? handleSuccess(original) : handleBlockSuccess(original),
      }),
    [handleBlockSuccess, handleSuccess, popupManager],
  )

  const handleChangeState = useCallback(
    (state) => {
      setTableState((prevState) => ({
        ...prevState,
        pageIndex: state.pageIndex,
        pageSize: state.pageSize,
      }))
    },
    [setTableState],
  )

  const handleRowProps = useCallback(
    (row, data) => ({
      ...row,
      className: cn(row.className, data.isBlocked ? styles.rowIsBlocked : styles.rowIsNotBlocked, {
        [styles.rowIsBlocking]: data.isBlocking,
      }),
    }),
    [],
  )

  // useEffectAfterMount(() => {
  //   handleUpdate(true)
  // }, [blocked])

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: 'Наименование',
        accessor: 'name',
        className: styles.cell_name,
        Cell: ({ row: { original } }) => (
          <span className={styles.organization}>{original?.name || ''}</span>
        ),
      },
      {
        Header: 'ИНН',
        accessor: 'inn',
        className: styles.cell_inn,
        Cell: ({ row: { original } }) =>
          original.isBlocking ? (
            <Typography
              className={styles.blockingText}
              variant={'bodyMMedium'}
              color={'text-accent-error-secondary'}
            >
              Организация заблокирована
            </Typography>
          ) : (
            original.inn
          ),
      },
      {
        Header: 'ОГРН',
        accessor: 'ogrn',
        className: styles.cell_ogrn,
        Cell: ({ row: { original } }) => !original.isBlocking && original.ogrn,
      },
      {
        Header: '',
        accessor: 'isBlocked',
        className: styles.cell_action,
        Cell: ({ row: { original } }) => {
          return original.isBlocking ? (
            <BlockCell
              onCancelDeleting={() => removeOrg(original.id)}
              onEndTimer={() => {
                blockingInProcess.current.delete(original.id)
                handleSuccess({ isBlocked: false, id: original.id })
              }}
            />
          ) : (
            <span
              aria-hidden
              className={cn(!original.isBlocked ? styles.isNotBlockedCell : styles.isBlockedCell)}
              role="button"
              onClick={() => handleBlock(original)}
            >
              {original.isBlocked && (
                <Icon noCurrentColorSvgFill src={lock_outline} className={styles.lock} />
              )}
              <span className={styles.lockText}>
                {!original.isBlocked ? 'Заблокировать' : 'Разблокировать'}
              </span>
            </span>
          )
        },
      },
    ],
    [],
  )

  return {
    setSearchValue,
    bindSearchInput,
    onlyBlocked,
    setOnlyBlockedToTrue,
    setOnlyBlockedToFalse,
    columns,
    totalPage,
    organizations,
    totalElements,
    handleRowProps,
    handleChangeState,
    isValidating,
    debouncedSearchString,
  }
}
