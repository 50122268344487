import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TInitialStateOfProjectsStateStore } from './ProjectsState.entity'

const initialState: TInitialStateOfProjectsStateStore = {
  transitionId: '',
  transitionPage: 0,
  transitionFilters: {},
  transitionFoldStates: {},
}

const slice = createSlice({
  name: 'projectsStatePage',
  initialState,
  reducers: {
    toggleTransitionFoldState: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        transitionFoldStates: {
          ...state.transitionFoldStates,
          [payload]: !state.transitionFoldStates[payload],
        },
      }
    },
    setTransitionProjectState: (
      state,
      { payload }: PayloadAction<Partial<TInitialStateOfProjectsStateStore>>,
    ) => {
      return {
        ...state,
        ...payload,
      }
    },

    clearProjectsPageState: () => {
      return initialState
    },
  },
})

const ProjectsPageStateActions = slice.actions

export { ProjectsPageStateActions, initialState as projectsStateInitial }

export default slice
