import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForNotificationOfChangeDocumentsForm = (
  objectFromPropertyState: Record<string, any>,
): NotificationOfChangeDocumentsFormValues => {
  return {
    '1': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      ruleLinkStatementChanges: objectFromPropertyState.ruleLinkStatementChanges.value || '',
      characteristicOfDocuments:
        isArray(objectFromPropertyState.changesDocuments?.value) &&
        objectFromPropertyState.changesDocuments?.value.length
          ? objectFromPropertyState.changesDocuments?.value.map((item, index) => ({
              id: item.propertyId,
              anchorId: `2.${index}`,
              changeDocumentName: item.value.changeDocumentName?.value || '',
              changeDocumentCharacteristic: item.value.changeDocumentCharacteristic?.value || '',
            }))
          : [],
    },
  } as NotificationOfChangeDocumentsFormValues
}

export { generateRHFObjectForNotificationOfChangeDocumentsForm }
