import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  IRegionFederalReport,
  SummaryReportOfMonitoringResultsFormValues,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const summaryReportOfMonitoringResultsRegionFederalReportValidationMap: FormValuesValidationSection<
  keyof IRegionFederalReport,
  SummaryReportOfMonitoringResultsFormValues
> = {
  regionName: {
    required: defaultRHFValidation.required,
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  countRegionFederalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  totalCapitalRegionFederalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  investorCapitalRegionFederalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  newWorkPlacesRegionFederalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
}

export { summaryReportOfMonitoringResultsRegionFederalReportValidationMap }
