import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForBeneficiaryOfSuccessorOrganizationInformationForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedBeneficiarsPartner = (() => {
    const preparedBeneficiarsPartnerValue = (() => {
      if (
        !isArray(objectFromPropertyState.beneficiarsPartner?.value) ||
        !objectFromPropertyState.beneficiarsPartner?.value.length
      )
        return []

      return objectFromPropertyState.beneficiarsPartner.value.map((beneficiar) => {
        const preparedBeneficiarSecondNationalityCountries =
          isArray(beneficiar.value?.beneficiarPartnerSecondNationalityCountries?.value) &&
          beneficiar.value?.beneficiarPartnerSecondNationalityCountries?.value.length
            ? beneficiar.value?.beneficiarPartnerSecondNationalityCountries?.value.map(
                (country) => ({
                  propertyId: country.propertyId,
                  lastUpdateDt: country.lastUpdateDt,
                  type: country.type,
                  value: {
                    beneficiarSecondNationalityCountry: {
                      propertyId: country.propertyId,
                      lastUpdateDt: country.lastUpdateDt,
                      type: country.type,
                    },
                  },
                }),
              )
            : []

        return {
          propertyId: beneficiar.propertyId,
          type: beneficiar.type,
          lastUpdateDt: beneficiar.lastUpdateDt,
          value: {
            beneficiarName: {
              propertyId: beneficiar.value.beneficiarPartnerName?.propertyId,
              type: beneficiar.value.beneficiarPartnerName?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerName?.lastUpdateDt,
            },
            beneficiarBirthPlace: {
              propertyId: beneficiar.value.beneficiarPartnerBirthPlace?.propertyId,
              type: beneficiar.value.beneficiarPartnerBirthPlace?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerBirthPlace?.lastUpdateDt,
            },
            beneficiarBirthDate: {
              propertyId: beneficiar.value.beneficiarPartnerBirthDate?.propertyId,
              type: beneficiar.value.beneficiarPartnerBirthDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerBirthDate?.lastUpdateDt,
            },
            beneficiarNationality: {
              propertyId: beneficiar.value.beneficiarPartnerNationality?.propertyId,
              type: beneficiar.value.beneficiarPartnerNationality?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerNationality?.lastUpdateDt,
            },
            otherNationality: {
              propertyId: beneficiar.value.otherNationalityPartner?.propertyId,
              type: beneficiar.value.otherNationalityPartner?.type,
              lastUpdateDt: beneficiar.value.otherNationalityPartner?.lastUpdateDt,
            },
            beneficiarNationalityCountry: {
              propertyId: beneficiar.value.beneficiarPartnerNationalityCountry?.propertyId,
              type: beneficiar.value.beneficiarPartnerNationalityCountry?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerNationalityCountry?.lastUpdateDt,
            },
            beneficiarSecondNationalityCountries: {
              propertyId: beneficiar.value.beneficiarPartnerSecondNationalityCountries?.propertyId,
              type: beneficiar.value.beneficiarPartnerSecondNationalityCountries?.type,
              lastUpdateDt:
                beneficiar.value.beneficiarPartnerSecondNationalityCountries?.lastUpdateDt,
              value: preparedBeneficiarSecondNationalityCountries,
            },
            beneficiarInn: {
              propertyId: beneficiar.value.beneficiarPartnerInn?.propertyId,
              type: beneficiar.value.beneficiarPartnerInn?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerInn?.lastUpdateDt,
            },
            beneficiarDocument: {
              propertyId: beneficiar.value.beneficiarPartnerDocument?.propertyId,
              type: beneficiar.value.beneficiarPartnerDocument?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerDocument?.lastUpdateDt,
            },
            beneficiarDocumentSeries: {
              propertyId: beneficiar.value.beneficiarPartnerDocumentSeries?.propertyId,
              type: beneficiar.value.beneficiarPartnerDocumentSeries?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerDocumentSeries?.lastUpdateDt,
            },
            beneficiarDocumentNumber: {
              propertyId: beneficiar.value.beneficiarPartnerDocumentNumber?.propertyId,
              type: beneficiar.value.beneficiarPartnerDocumentNumber?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerDocumentNumber?.lastUpdateDt,
            },
            beneficiarDocumentDate: {
              propertyId: beneficiar.value.beneficiarPartnerDocumentDate?.propertyId,
              type: beneficiar.value.beneficiarPartnerDocumentDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerDocumentDate?.lastUpdateDt,
            },
            beneficiarDocumentOrganization: {
              propertyId: beneficiar.value.beneficiarPartnerDocumentOrganization?.propertyId,
              type: beneficiar.value.beneficiarPartnerDocumentOrganization?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerDocumentOrganization?.lastUpdateDt,
            },
            beneficiarDocumentCodeOrganization: {
              propertyId: beneficiar.value.beneficiarPartnerDocumentCodeOrganization?.propertyId,
              type: beneficiar.value.beneficiarPartnerDocumentCodeOrganization?.type,
              lastUpdateDt:
                beneficiar.value.beneficiarPartnerDocumentCodeOrganization?.lastUpdateDt,
            },
            beneficiarAddress: {
              propertyId: beneficiar.value.beneficiarPartnerAddress?.propertyId,
              type: beneficiar.value.beneficiarPartnerAddress?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerAddress?.lastUpdateDt,
            },
            beneficiarMigrationNumber: {
              propertyId: beneficiar.value.beneficiarPartnerMigrationNumber?.propertyId,
              type: beneficiar.value.beneficiarPartnerMigrationNumber?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerMigrationNumber?.lastUpdateDt,
            },
            beneficiarMigrationStartDate: {
              propertyId: beneficiar.value.beneficiarPartnerMigrationStartDate?.propertyId,
              type: beneficiar.value.beneficiarPartnerMigrationStartDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerMigrationStartDate?.lastUpdateDt,
            },
            beneficiarMigrationEndDate: {
              propertyId: beneficiar.value.beneficiarPartnerMigrationEndDate?.propertyId,
              type: beneficiar.value.beneficiarPartnerMigrationEndDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerMigrationEndDate?.lastUpdateDt,
            },
            beneficiarResidenceNumber: {
              propertyId: beneficiar.value.beneficiarPartnerResidenceNumber?.propertyId,
              type: beneficiar.value.beneficiarPartnerResidenceNumber?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerResidenceNumber?.lastUpdateDt,
            },
            beneficiarResidenceStartDate: {
              propertyId: beneficiar.value.beneficiarPartnerResidenceStartDate?.propertyId,
              type: beneficiar.value.beneficiarPartnerMigrationStartDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerResidenceStartDate?.lastUpdateDt,
            },
            beneficiarResidenceEndDate: {
              propertyId: beneficiar.value.beneficiarPartnerResidenceEndDate?.propertyId,
              type: beneficiar.value.beneficiarPartnerResidenceEndDate?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerResidenceEndDate?.lastUpdateDt,
            },
            beneficiarPart: {
              propertyId: beneficiar.value.beneficiarPartnerPart?.propertyId,
              type: beneficiar.value.beneficiarPartnerPart?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerPart?.lastUpdateDt,
            },
            beneficiarReason: {
              propertyId: beneficiar.value.beneficiarPartnerReason?.propertyId,
              type: beneficiar.value.beneficiarPartnerReason?.type,
              lastUpdateDt: beneficiar.value.beneficiarPartnerReason?.lastUpdateDt,
            },
          },
        }
      })
    })()

    return {
      propertyId: objectFromPropertyState.beneficiarsPartner?.propertyId,
      type: objectFromPropertyState.beneficiarsPartner?.type,
      lastUpdateDt: objectFromPropertyState.beneficiarsPartner?.lastUpdateDt,
      value: preparedBeneficiarsPartnerValue,
    }
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInvestorPartnerAdapter(objectFromPropertyState),
    },
    '2': {
      beneficiars: preparedBeneficiarsPartner,
    },
  }
}

export { generatePropertiesObjectForBeneficiaryOfSuccessorOrganizationInformationForm }
