import React from 'react'
import { useFormContext } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aaVolumeOfCapitalInvestmentsBlockValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/const'
import { fourthSectionValidationMap } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/4/validation'
import { useAAVolumeOfCapitalInvestmentsManager } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Manager'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<AAVolumeOfCapitalInvestmentsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAVolumeOfCapitalInvestmentsManager()

  const { getSubscribableControlProps, getControllerProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aaVolumeOfCapitalInvestmentsBlockValues['4'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                controllerProps={getControllerProps({
                  name: aaVolumeOfCapitalInvestmentsBlockValues['4'].investorFullName,
                })}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное лицо организации'}
          formInstance={formInstance}
          formSection={'4'}
          prefixFormFieldName={'investor'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: aaVolumeOfCapitalInvestmentsBlockValues['4'].investorHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: aaVolumeOfCapitalInvestmentsBlockValues['4'].investorHeaderName,
                      rules: fourthSectionValidationMap.investorHeaderName,
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () => {
                        setTimeout(() => {
                          handleChangeValue?.(
                            aaVolumeOfCapitalInvestmentsBlockValues['4'].investorHeaderName,
                          )
                        }, 0)
                      },
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
      </Stack>
    </Container>
  )
}

export default Fourth
