import { useCallback, useState } from 'react'

const useFormBlockViewIsValidating = () => {
  const [blockViewIsValidating, setBlockViewIsValidating] = useState<boolean>(false)

  const handleChangeBlockValidation = useCallback((newState: boolean) => {
    setBlockViewIsValidating(newState)
  }, [])

  return {
    blockViewIsValidating,
    handleChangeBlockValidation,
  }
}

export { useFormBlockViewIsValidating }
