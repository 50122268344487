import { TInitialStateOfProjectsStateStore } from '@services/ProjectsState/ProjectsState.entity'
import { ProjectsPageStateActions } from '@services/ProjectsState/ProjectsState.store'
import { useAppDispatch } from '@store'

const useProjectsPageStateManager = () => {
  const dispatch = useAppDispatch()

  const setTransitionProjectsPageState = (payload: Partial<TInitialStateOfProjectsStateStore>) => {
    return dispatch(ProjectsPageStateActions.setTransitionProjectState(payload))
  }

  const toggleTransitionFoldState = (payload: string) => {
    return dispatch(ProjectsPageStateActions.toggleTransitionFoldState(payload))
  }

  const clearProjectsPageState = () => {
    return dispatch(ProjectsPageStateActions.clearProjectsPageState())
  }

  return {
    setTransitionProjectsPageState,
    clearProjectsPageState,
    toggleTransitionFoldState,
  }
}

export { useProjectsPageStateManager }
