import React, { useEffect, useMemo, useRef } from 'react'
import { FieldValues, Path, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { FormOption } from '@components/DocumentFormComponents/types'
import Loader from '@components/Loader'
import type { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Modal from '@components/NewDesign/Modal'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { defaultRHFValidation } from '@constants/validations'
import { isUndefined } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'

import styles from './AddAuthorizedPersonModal.module.scss'
import { AddAuthorizedPersonModalProps, HeadInfoFields } from './types'

const AddAuthorizedPersonModal = <T extends FieldValues>({
  isOpen,
  isFederalSZPK,
  blockViewIsValidating,
  name,
  formInstance,
  subscribableControl,
  watcher,
  onClose,
  onChangeValue,
}: AddAuthorizedPersonModalProps<T>) => {
  const {
    booleanState: isLoading,
    setBooleanStateToTrue: enableLoading,
    setBooleanStateToFalse: disableLoading,
  } = useBooleanState()

  const prevHeadInfoValues = useRef<HeadInfoFields | null>(null)

  const investorHeaderName: FormOption | null = useWatch({
    name: `${name}.investorHeaderName`,
    control: formInstance?.control,
  })

  const municipalityHeaderName: FormOption | null | undefined = useWatch({
    name: `${name}.municipalityHeaderName`,
    control: formInstance?.control,
  })

  const municipalityHeaderPosition: string | undefined = useWatch({
    name: `${name}.municipalityHeaderPosition`,
    control: formInstance?.control,
  })

  const { getSubscribableControlProps, getSingleSelectProps, getInputProps } =
    useFormComponentPresets({
      editMode: true,
      blockViewIsValidating,
      formInstance,
      watcher,
      subscribableControl,
    })

  const isShowMunicipalityHeaderInfo =
    !isUndefined(municipalityHeaderName) || !isUndefined(municipalityHeaderPosition)

  const handleCloseModal = () => {
    onClose()

    toast(
      <Alert transparent variant="success">
        Изменения сохранены
      </Alert>,
    )
  }

  const handleUpdateValue = async (pathName: Path<T>) => {
    enableLoading()

    try {
      await onChangeValue?.(pathName)
    } finally {
      disableLoading()
    }
  }

  const actions: ButtonProps[] = useMemo(() => {
    const isChangedValue =
      prevHeadInfoValues.current?.investorHeaderName?.value !== investorHeaderName?.value ||
      prevHeadInfoValues.current?.municipalityHeaderName?.value !== municipalityHeaderName?.value

    if (isLoading || !isChangedValue) return []

    return [
      {
        children: 'Закрыть',
        onClick: handleCloseModal,
      },
    ]
  }, [handleCloseModal, investorHeaderName?.value, isLoading, municipalityHeaderName?.value])

  useEffect(() => {
    const initialHeadInfoValues = formInstance.getValues(name)

    prevHeadInfoValues.current = {
      ...initialHeadInfoValues,
    }
  }, [])

  return (
    <Modal.Action
      hideCloseIcon={isLoading}
      disableOnClose={isLoading}
      isOpen={isOpen}
      actions={actions}
      actionsClassName={styles.addAuthorizedPerson__actions}
      simpleModalTitleClassName={styles.addAuthorizedPerson__title}
      title={'Укажите уполномоченное лицо'}
      onClose={onClose}
    >
      {!!formInstance && (
        <Stack direction={'vertical'} gap={3}>
          <Group
            disableBottomBorder
            groupClassName={'mt-0'}
            title={'Уполномоченное лицо организации, реализующей проект'}
          >
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.investorHeaderName`,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.headerName || '',
                            value: item.id,
                          }),
                          controllerProps: {
                            name: `${name}.investorHeaderName`,
                            rules: {
                              required: defaultRHFValidation.required,
                            },
                          },
                          selectProps: {
                            inputProps: {
                              label: 'Фамилия, Имя, Отчество',
                            },
                            popoverProps: {
                              zIndex: 51,
                            },
                            onChangeFormValue: () => {
                              setTimeout(
                                () => handleUpdateValue(`${name}.investorHeaderName` as Path<T>),
                                0,
                              )
                            },
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <ControlledInput
                  {...getInputProps({
                    name: `${name}.investorHeaderPosition`,
                    inputProps: {
                      disabled: true,
                      label: 'Должность',
                    },
                  })}
                />
              </Col>
            </Row>
          </Group>
          {isShowMunicipalityHeaderInfo && isFederalSZPK && (
            <Group
              disableBottomBorder
              groupClassName={'mt-0'}
              title={'Уполномоченное должностное лицо муниципального образования'}
            >
              <Row>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${name}.municipalityHeaderName`,
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.headerName || '',
                              value: item.id,
                            }),
                            controllerProps: {
                              name: `${name}.municipalityHeaderName`,
                              rules: {
                                required: defaultRHFValidation.required,
                              },
                            },
                            selectProps: {
                              inputProps: {
                                label: 'Фамилия, Имя, Отчество',
                              },
                              popoverProps: {
                                zIndex: 51,
                              },
                              onChangeFormValue: () => {
                                setTimeout(
                                  () =>
                                    handleUpdateValue(`${name}.municipalityHeaderName` as Path<T>),
                                  0,
                                )
                              },
                            },
                          })}
                        />
                      )
                    }}
                  </SubscribableControl>
                </Col>
                <Col xs={6}>
                  <ControlledInput
                    {...getInputProps({
                      name: `${name}.municipalityHeaderPosition`,
                      inputProps: {
                        disabled: true,
                        label: 'Должность',
                      },
                    })}
                  />
                </Col>
              </Row>
            </Group>
          )}
          {isLoading && (
            <Row>
              <Col xs={12}>
                <Loader
                  wrapperClassName={styles['addAuthorizedPerson__loader-wrapper']}
                  loading={isLoading}
                  variant={'lite'}
                />
              </Col>
            </Row>
          )}
        </Stack>
      )}
    </Modal.Action>
  )
}

export default AddAuthorizedPersonModal
