import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import styles from '@components/DocumentFormComponents/FormStyles/RidObject.module.scss'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import RExploitationForm from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RExploitationForm'
import RForm from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RForm'
import RRegistrationForm from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RRegistrationForm'
import { SeventhRidStagePathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

interface RidStageProps {
  isNew: boolean
  indexOfMainBlock: number
  name: SeventhRidStagePathName
}

const RidObject: FC<RidStageProps> = ({ isNew, indexOfMainBlock, name }) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  if (!formInstance) return null

  return (
    <div className={styles.ridObject}>
      <div
        id={`${name}.ridObjects`}
        className={cn(styles.ridObject__header, styles['ridObject__object-header'])}
      >
        <FormListTooltipControl
          name={`${name}.ridObjects`}
          control={formModifierInstance.control}
          onDifference={handleUpdateChanges}
        >
          <Typography.Headline variant={'headlineH3'}>
            Результаты интеллектуальной деятельности (РИД)
          </Typography.Headline>
        </FormListTooltipControl>
      </div>

      <div className={styles.ridObject__items}>
        <RForm
          name={`${name}.ridObjects`}
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
        />
      </div>

      <Border className={styles.ridObject__border} />

      <div
        id={`${name}.exploitation.ridExploitationObjects`}
        className={styles.ridObject__exploitation}
      >
        <FormListTooltipControl
          name={`${name}.exploitation.ridExploitationObjects`}
          control={formModifierInstance.control}
          wrapperClassName={styles.ridObject__header}
          onDifference={handleUpdateChanges}
        >
          <Typography.Headline variant={'headlineH4'}>Эксплуатация РИД</Typography.Headline>
        </FormListTooltipControl>
        <RExploitationForm
          isNew={isNew}
          indexOfBlock={indexOfMainBlock}
          name={`${name}.exploitation`}
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
        />
      </div>

      <Border className={styles.ridObject__border} />

      <div
        id={`${name}.registration.ridRegistrationsObjects`}
        className={styles.ridObject__registration}
      >
        <FormListTooltipControl
          name={`${name}.registration.ridRegistrationsObjects`}
          control={formModifierInstance.control}
          wrapperClassName={styles.ridObject__header}
          onDifference={handleUpdateChanges}
        >
          <Typography.Headline variant={'headlineH4'}>Регистрация РИД</Typography.Headline>
        </FormListTooltipControl>
        <RRegistrationForm
          isNew={isNew}
          name={`${name}.registration`}
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
          indexOfBlock={indexOfMainBlock}
        />
      </div>
    </div>
  )
}

export default RidObject
