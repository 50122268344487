import { useAppSelector } from '@store'

import { technicalWorkSelector } from './TechnicalWork.selector'

type TDatesViews = 'days' | 'hours' | 'minutes'

interface ITechnicalTime {
  days: number | null
  hours: number | null
  minutes: number | null
  chosen: TDatesViews | null
}

interface IInitialTechnicalWork {
  isRunning: boolean
  fallbackStatus: boolean
  isSetup: boolean

  timeToStart: ITechnicalTime
  timeToEnd: ITechnicalTime
  startDate?: string
  endDate?: string
}

const useTechnicalWork = () => {
  return useAppSelector(technicalWorkSelector)
}

export type { IInitialTechnicalWork }
export { useTechnicalWork }
