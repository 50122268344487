import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useApplicationInclusionOfAARegisterSZPKManager } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/Manager'
import { ApplicationInclusionOfAARegisterSZPKFormValues } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/types'

import { mapOfApplicationInclusionOfAARegisterSZPKMenu } from '../const'
import ApplicationInclusionOfAARegisterSZPKForms from '../Forms'

const mapOfApplicationInclusionOfAARegisterSZPKForms: FormItemProps = {
  '1': {
    node: <ApplicationInclusionOfAARegisterSZPKForms.First />,
  },
  '2': {
    node: <ApplicationInclusionOfAARegisterSZPKForms.Second />,
  },
} as const

const ApplicationInclusionOfAARegisterSZPKForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<ApplicationInclusionOfAARegisterSZPKFormValues>()

  const {
    state: { formIsLoading },
  } = useApplicationInclusionOfAARegisterSZPKManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfApplicationInclusionOfAARegisterSZPKMenu}
      mapOfForms={mapOfApplicationInclusionOfAARegisterSZPKForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(ApplicationInclusionOfAARegisterSZPKForm)
