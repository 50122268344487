import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'

interface NoticeIntentionTerminateSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
}

const NoticeIntentionTerminateSZPKForms: NoticeIntentionTerminateSZPKFormsProps = () => null

NoticeIntentionTerminateSZPKForms.First = First
NoticeIntentionTerminateSZPKForms.Second = Second
NoticeIntentionTerminateSZPKForms.Third = Third

export default NoticeIntentionTerminateSZPKForms
