import { useAPIContext } from '@context/APIContext'
import type { INotificationFromStack } from '@context/APIContext/hooks/useNotificationsApi'
import type { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useNotificationsStack = ({ key, config, dfoId }: SWRHookProps & { dfoId: string | null }) => {
  const {
    notificationsApi: { getNotificationsById },
  } = useAPIContext()

  const {
    data: notificationsStack,
    error,
    mutate,
    isValidating: isNotificationsStackLoading,
  } = useSWRImmutable<INotificationFromStack>(
    dfoId ? key : null,
    dfoId ? () => getNotificationsById(dfoId) : null,
    config,
  )

  return {
    notificationsStack,
    error,
    mutate,
    isNotificationsStackLoading,
  }
}

export { useNotificationsStack }
