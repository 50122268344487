import React from 'react'
import { useFormContext } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { parametersOfCostRecoveryApplicationBlockValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import ParametersOfCostRecoveryApplicationCompensationStatementPaidTax from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/5/CompensationStatementPaidTax'
import { ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxesPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/5/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const Fifth = () => {
  const formInstance = useFormContext<ParametersOfCostRecoveryApplicationFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { fields: compensationStatementPaidTaxes } = useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: parametersOfCostRecoveryApplicationBlockValues['5'].compensationStatementPaidTaxes,
    keyName: 'keyNameId',
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: parametersOfCostRecoveryApplicationBlockValues['5']
              .compensationStatementPaidTaxes,
          })}
        >
          <FlipperList list={compensationStatementPaidTaxes}>
            {compensationStatementPaidTaxes.map((compensationStatementPaidTax, index) => {
              const formName =
                `${parametersOfCostRecoveryApplicationBlockValues['5'].compensationStatementPaidTaxes}.${index}` as const

              return (
                <ParametersOfCostRecoveryApplicationCompensationStatementPaidTax
                  key={compensationStatementPaidTax.id}
                  id={compensationStatementPaidTax.id}
                  formInstance={formInstance}
                  name={formName}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
      </Stack>
    </Container>
  )
}

export default Fifth
