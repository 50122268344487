import React, { FC } from 'react'
import { useWatch } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import SummaryReportOfMonitoringResultsRegionReportInfoCollapse from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoCollapse'
import { BaseSummaryReportOfMonitoringResultsRegionReportItemProps } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/types'
import { SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Stack from '@components/ReactBootstrap/Stack'

const SummaryReportOfMonitoringResultsRegionReportInfoFederalInvestorCapitalItem: FC<
  BaseSummaryReportOfMonitoringResultsRegionReportItemProps
> = ({
  editMode,
  title,
  blockViewIsValidating,
  formName,
  index,
  formInstance,
  subscribableControl,
}) => {
  const federalInvestorCapitalFactDeviation = useWatch({
    name: `${formName}.federalInvestorCapitalFactDeviation`,
    control: formInstance.control,
  })

  const federalInvestorCapitalPlanDeviation = useWatch({
    name: `${formName}.federalInvestorCapitalPlanDeviation`,
    control: formInstance.control,
  })

  const { getAmountInputProps, getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
  })

  return (
    <SummaryReportOfMonitoringResultsRegionReportInfoCollapse indexOfReport={index} title={title}>
      <Stack direction={'vertical'} gap={3}>
        <Group title={'Объем капиталовложений'}>
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем из справок проверяющей организации. Фактическое значение'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalInvestorCapitalFactChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalInvestorCapitalFactChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем из регионального отчета. Фактическое значение'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalInvestorCapitalFact`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalInvestorCapitalFact`,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent={federalInvestorCapitalFactDeviation} />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем из справок проверяющей организации. Плановое значение'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalInvestorCapitalPlanChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalInvestorCapitalPlanChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем из регионального отчета. Плановое значение'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalInvestorCapitalPlan`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalInvestorCapitalPlan`,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent={federalInvestorCapitalPlanDeviation} />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
      </Stack>
    </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
  )
}

export default SummaryReportOfMonitoringResultsRegionReportInfoFederalInvestorCapitalItem
