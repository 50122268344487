import { FC, memo, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import AAgreementOnChangingCharacteristicsOfObjectsForm from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm'
import { aAgreementOnChangingCharacteristicsOfObjectsBlockValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import { getAAgreementOnChangingCharacteristicsOfObjectsMenuByStatus } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Menu/const'
import { AAgreementOnChangingCharacteristicsOfObjectsFormValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import AAgreementOnChangingCharacteristicsOfObjectsLayoutWrapper from './Wrapper'

const AAgreementOnChangingCharacteristicsOfObjectsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const isFederalSZPK = useWatch({
    name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues.additionalFields
      .initialMunicipalities,
    control: formInstance.control,
  }).length

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const handleOnClose = useCallback(() => {
    AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  const initialMenuState = useMemo(() => {
    return getAAgreementOnChangingCharacteristicsOfObjectsMenuByStatus(
      isFederalSZPK,
      isMunicipalitiesRender,
    )
  }, [isFederalSZPK, isMunicipalitiesRender])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <AAgreementOnChangingCharacteristicsOfObjectsForm.MenuManager
        key={String(isFederalSZPK)}
        initialMenuHash={initialMenuState.initialAAgreementOnCreationOtherEstateObjectsMenuState}
        initialSectionId={
          initialMenuState.initialAAgreementOnCreationOtherEstateObjectsMenuState[0].id
        }
      >
        <AAgreementOnChangingCharacteristicsOfObjectsForm.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
          onClose={handleOnClose}
        >
          <AAgreementOnChangingCharacteristicsOfObjectsLayoutWrapper
            lastUpdateDraftTime={lastUpdateDraftTime}
          >
            <div className={styles.layout__title}>
              <Typography.Headline variant={'headlineH1'} align={'center'} as={'h1'}>
                Дополнительное соглашение об изменении характеристик объектов недвижимости
              </Typography.Headline>
              <Typography.Body
                className={styles.layout__subtitle}
                variant={'bodyMRegular'}
                align={'center'}
                as={'h2'}
              >
                Внесите изменения в поля соглашения. На основании внесенных данных будет сформирован
                pdf файл дополнительного соглашения
              </Typography.Body>
              <AAgreementOnChangingCharacteristicsOfObjectsForm.StatementForm
                className={styles.layout__form}
                initialMenu={initialMenuState.mapOfMenu}
              />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--l'])}>
              <AAgreementOnChangingCharacteristicsOfObjectsForm.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </AAgreementOnChangingCharacteristicsOfObjectsLayoutWrapper>
        </AAgreementOnChangingCharacteristicsOfObjectsForm.Manager>
      </AAgreementOnChangingCharacteristicsOfObjectsForm.MenuManager>
    </DocumentFormLayout>
  )
}

export default memo(AAgreementOnChangingCharacteristicsOfObjectsLayout)
