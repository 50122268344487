import { useState } from 'react'

import { PageStateProps, useNotificationsManager } from '@components/NewNotifications/useNotificationsManager'

export const defaultPageSize = 3

export interface PageState {
  page: number,
  lastPage: number | null
}

const initPageState = {
  page: 0,
  lastPage: null
}

const useNotifications = ({ isPanelOpen, onLoadLastPage }: Pick<PageStateProps, 'isPanelOpen' | 'onLoadLastPage'>) => {
  /* lastPage - Либо номер последней страницы, если дошли до нее, либо null */
  const [pageState, setPage] = useState<PageState>(initPageState)

  const { page, lastPage } = pageState

  const { allNotifications, notifications, removeNotification, isNotificationsLoading, isFirstPageOfNotificationsLoading, isLastPageDataLoading, canGoToNextPage, firstPageError } = useNotificationsManager({
    page, lastPage, setPage, onLoadLastPage, isPanelOpen
  })

  const goToNextPage = () => {
    if (canGoToNextPage) setPage((prevState) => ({ ...prevState, page: page + 1 }))
  }

  const hideNotifications = () => {
    setPage((prevState) => ({ ...prevState, page: 0 }))
  }

  return {
    notifications: notifications.slice(0, (page + 1) * defaultPageSize),
    removeNotification,
    isLastPageDataLoading,
    firstPageError,
    allNotifications,
    // error,
    // mutate,
    isNotificationsLoading,
    isFirstPageOfNotificationsLoading,
    page,
    setPage,
    canGoToNextPage,
    goToNextPage,
    hideNotifications
  }
}

export { useNotifications }
