import React from 'react'
import { useFormContext } from 'react-hook-form'

import Container from '@components/Container'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationFillingAppConcludingBlockValues } from '@components/Forms/NotificationFillingAppConcludingForm/const'
import { useNotificationFillingAppConcludingManager } from '@components/Forms/NotificationFillingAppConcludingForm/Manager'
import { NotificationFillingAppConcludingFormValues } from '@components/Forms/NotificationFillingAppConcludingForm/types'
import { NotificationFillingAppConcludingFieldsControlUpdateWatcher } from '@components/Forms/NotificationFillingAppConcludingForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { fourthSectionValidationMap } from './validation'

const Fourth = () => {
  const formInstance = useFormContext<NotificationFillingAppConcludingFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationFillingAppConcludingManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getInputProps,
    getAmountInputProps,
    getTextareaProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NotificationFillingAppConcludingFieldsControlUpdateWatcher,
  })

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={4}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationFillingAppConcludingBlockValues[4].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование инвестиционного проекта'}
                controllerProps={getControllerProps({
                  name: notificationFillingAppConcludingBlockValues[4].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункты Правил на основании которых рассматривается заявление о заключении дополнительного соглашения'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationFillingAppConcludingBlockValues[4].ruleLinkExtraError,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationFillingAppConcludingBlockValues[4].ruleLinkExtraError,
                    rules: fourthSectionValidationMap.ruleLinkExtraError,
                    inputProps: {
                      caption: 'рекомендация: 26',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationFillingAppConcludingBlockValues['4'].ruleLinkExtraError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationFillingAppConcludingBlockValues['4'].ruleLinkExtraError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Group title={'Нарушения'}>
          <Stack direction={'vertical'} gap={4}>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={
                    'Заявление о заключении дополнительного соглашения содержит технические ошибки'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationFillingAppConcludingBlockValues[4].extraTechError,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: notificationFillingAppConcludingBlockValues[4].extraTechError,
                        rules: fourthSectionValidationMap.extraTechError,
                        textareaProps: {
                          caption: 'не обязательно',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationFillingAppConcludingBlockValues['4'].extraTechError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationFillingAppConcludingBlockValues['4'].extraTechError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={'Установлен некомплект представленных документов и материалов'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationFillingAppConcludingBlockValues[4].extraSetDocumentError,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: notificationFillingAppConcludingBlockValues[4].extraSetDocumentError,
                        rules: fourthSectionValidationMap.extraSetDocumentError,

                        textareaProps: {
                          caption: 'не обязательно',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationFillingAppConcludingBlockValues['4']
                                  .extraSetDocumentError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationFillingAppConcludingBlockValues['4']
                                .extraSetDocumentError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={'Не соблюдены в применимых случаях требования пунктов 5 и 6 Правил'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationFillingAppConcludingBlockValues[4].extraRuleLinkError,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: notificationFillingAppConcludingBlockValues[4].extraRuleLinkError,
                        rules: fourthSectionValidationMap.extraRuleLinkError,

                        textareaProps: {
                          caption: 'не обязательно',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationFillingAppConcludingBlockValues['4'].extraRuleLinkError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationFillingAppConcludingBlockValues['4'].extraRuleLinkError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={'Иные нарушения'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationFillingAppConcludingBlockValues[4].extraOtherError,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: notificationFillingAppConcludingBlockValues[4].extraOtherError,
                        rules: fourthSectionValidationMap.extraOtherError,
                        textareaProps: {
                          caption: 'не обязательно',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationFillingAppConcludingBlockValues['4'].extraOtherError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationFillingAppConcludingBlockValues['4'].extraOtherError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationFillingAppConcludingBlockValues[4].extraOtherDetails,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: notificationFillingAppConcludingBlockValues[4].extraOtherDetails,
                  rules: fourthSectionValidationMap.extraOtherDetails,
                  textareaProps: {
                    maxRows: Infinity,
                    label: 'Дополнительная информация для ОРП',
                    caption: 'не обязательно',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          notificationFillingAppConcludingBlockValues[4].extraOtherDetails,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        notificationFillingAppConcludingBlockValues[4].extraOtherDetails,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дней на устранение нарушений'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            path={notificationFillingAppConcludingBlockValues[4].extraDaysRevision}
            watcher={NotificationFillingAppConcludingFieldsControlUpdateWatcher}
            {...subscribableControl}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: notificationFillingAppConcludingBlockValues[4].extraDaysRevision,
                rules: fourthSectionValidationMap.extraDaysRevision,
                inputProps: {
                  integersOnly: true,
                  hideSuffix: true,
                  hasNegativeValue: false,
                },
                onBlur: () =>
                  setTimeout(
                    () =>
                      handleChangeValue?.(
                        notificationFillingAppConcludingBlockValues['4'].extraDaysRevision,
                      ),
                    0,
                  ),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      notificationFillingAppConcludingBlockValues['4'].extraDaysRevision,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
      </Stack>
    </Container>
  )
}

export default Fourth
