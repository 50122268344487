import { compact } from '@helpers/array/compact'

const generatePrefetchedPropsForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA = (
  objectFromPropertyState: Record<string, any>,
): string[] => {
  return compact(
    objectFromPropertyState.steps?.value.map((step) => step.value.stepActivityNames?.propertyId),
  )
}

export { generatePrefetchedPropsForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA }
