import { FC, memo, ReactNode, useEffect } from 'react'
import { PopupProps } from 'react-popup-manager'

import { Transition } from '@headlessui/react'
import { useScrollState } from '@hooks/new/scroll/useScrollState'
import cn from 'classnames'

import styles from './Layout.module.scss'

interface DocumentFormLayoutComponentProps {
  children: ReactNode
}

type DocumentFormLayoutProps = PopupProps & DocumentFormLayoutComponentProps

const DocumentFormLayout: FC<DocumentFormLayoutProps> = ({ isOpen, children }) => {
  const { handleUnblockScroll, handleBlockScroll } = useScrollState()

  //При открытии заявления закрываем возможность скроллиться body.
  useEffect(() => {
    if (isOpen) {
      handleBlockScroll()
      return
    }

    handleUnblockScroll()
  }, [handleBlockScroll, handleUnblockScroll, isOpen])

  return (
    <Transition
      show={isOpen}
      enter={styles['layout__animate--enter']}
      enterFrom={styles['layout__animate--enterFrom']}
      enterTo={styles['layout__animate--enterTo']}
      leave={styles['layout__animate--leave']}
      leaveFrom={styles['layout__animate--leaveFrom']}
      leaveTo={styles['layout__animate--leaveTo']}
      className={cn(styles.layout, {
        [styles.layout__opened]: isOpen,
      })}
    >
      {children}
    </Transition>
  )
}

export default memo(DocumentFormLayout)
