import { createContext, FC, useContext, useEffect, useState } from 'react'

import { mockKnowledgeHashMap } from '@components/KnowledgeBase/__mock__'
import { sortThreeFunction } from '@components/KnowledgeBase/helpers'
import type { IKnowledgeBaseItem } from '@components/KnowledgeBase/types'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import useInput from '@hooks/useInput'
import { useQueryManager } from '@hooks/useQueryManager'
import cloneDeep from 'clone-deep'
import { unstable_serialize, useSWRConfig } from 'swr'

type TInitialThreeManager = Map<string, boolean>

interface KnowledgeBaseContextProps {
  state: {
    currentBaseItem: IKnowledgeBaseItem | null
    initializationThree: TInitialThreeManager
    searchThree: IKnowledgeBaseItem[] | null
    searchThreeIsLoading: boolean
    baseItemRef: HTMLLIElement | null
    currentSearchPattern: string
  }
  handlers: {
    handleChooseBaseItem: ((baseItem: IKnowledgeBaseItem) => void) | null
    handleClearBaseItem: VoidFunction | null
    handleChangeSearchPattern: ((value: string) => void) | null
    handleSelectRef: ((baseItemRef: HTMLLIElement | null) => void) | null
  }
}

export const KnowledgeBaseContext = createContext<KnowledgeBaseContextProps>({
  state: {
    currentBaseItem: null,
    initializationThree: new Map(),
    searchThreeIsLoading: false,
    searchThree: null,
    baseItemRef: null,
    currentSearchPattern: '',
  },
  handlers: {
    handleChooseBaseItem: null,
    handleClearBaseItem: null,
    handleChangeSearchPattern: null,
    handleSelectRef: null,
  },
})

interface KnowledgeBaseManagerProps {
  initializationPageViewId: string | null
}

const KnowledgeBaseManager: FC<KnowledgeBaseManagerProps> = ({
  initializationPageViewId,
  children,
}) => {
  const { queryUtils } = useQueryManager()
  const { cache } = useSWRConfig()

  const [currentBaseItem, setCurrentBaseItem] = useState<IKnowledgeBaseItem | null>(null)

  const [initializationThree, setInitializationThree] = useState(new Map())
  const [searchThree, setSearchThree] = useState<IKnowledgeBaseItem[] | null>(null)

  const [searchThreeIsLoading, setSearchThreeIsLoading] = useState<boolean>(false)

  const [baseItemRef, setBaseItemRef] = useState<HTMLLIElement | null>(null)

  const currentSearchPattern = useInput('')

  useEffect(() => {
    if (initializationPageViewId && mockKnowledgeHashMap[initializationPageViewId]) {
      generateThree(initializationPageViewId)

      return setCurrentBaseItem(cloneDeep(mockKnowledgeHashMap[initializationPageViewId]))
    }
  }, [initializationPageViewId])

  //Скролл выделенного элемента во вью порт
  useEffectAfterMount(() => {
    if (baseItemRef) {
      baseItemRef.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [baseItemRef])

  const generateThree = (code: string | null, map: TInitialThreeManager = new Map()) => {
    if (code) {
      const data = cloneDeep(mockKnowledgeHashMap[code])

      if (data.parentBaseId) {
        const newMap = new Map(map.set(data.parentBaseId, true))

        generateThree(data.parentBaseId, newMap)
      } else {
        setInitializationThree(map)
      }
    }
  }

  const generateThreeFromSearch = (initialThree: IKnowledgeBaseItem[]) => {
    if (!initialThree.length) {
      setSearchThree(null)
      return
    }

    const parentCodes = initialThree.filter((item) => !item.parentBaseId)

    const generateChildren = (codes: IKnowledgeBaseItem[]) => {
      codes.forEach((item) => {
        const childrenOfItem = initialThree.filter(
          (childItem) => childItem.parentBaseId === item.id,
        )

        if (!item.parentBaseId && childrenOfItem.length) item.children = cloneDeep(childrenOfItem)

        const recursionCondition = childrenOfItem.some((item) => item.children.length)

        if (recursionCondition) {
          generateChildren(childrenOfItem)
        }
      })
    }

    generateChildren(parentCodes)

    return parentCodes
  }

  const debouncedGenerateSearchThree = useDebouncedCallback(() => {
    const keyForSearchThree = unstable_serialize({
      searchPattern: currentSearchPattern.value,
      _key: 'knowledgeBase',
    })

    const cachedSearchThree = cache.get(keyForSearchThree)

    if (cachedSearchThree) {
      setSearchThreeIsLoading(false)

      return setSearchThree(cachedSearchThree)
    }

    const disassembledTree = Object.values(mockKnowledgeHashMap).filter((item) => {
      return item.name.toLowerCase().includes(currentSearchPattern.value.toLowerCase())
    })

    const preparedDisassembledTree = addParentOfFindCodes(disassembledTree)

    const readyThree = generateThreeFromSearch(preparedDisassembledTree)

    if (readyThree?.length) {
      const sortedReadyThree = readyThree.sort(sortThreeFunction)

      cache.set(keyForSearchThree, sortedReadyThree)

      setSearchThree(sortedReadyThree)

      return setSearchThreeIsLoading(false)
    }

    setSearchThree(null)

    setSearchThreeIsLoading(false)
  }, 500)

  const addParentOfFindCodes = (three: IKnowledgeBaseItem[]) => {
    const mapOfFindParentCodes = new Map()

    three.forEach((item) => {
      mapOfFindParentCodes.set(item.id, true)
    })

    const findParentItem = (
      previousValue: IKnowledgeBaseItem[],
      currentValue: IKnowledgeBaseItem,
    ) => {
      if (!currentValue.parentBaseId) return previousValue

      const foundedCode = mapOfFindParentCodes.get(currentValue.parentBaseId)

      if (foundedCode) return previousValue

      mapOfFindParentCodes.set(currentValue.parentBaseId, true)

      const parentItem: IKnowledgeBaseItem = cloneDeep(
        mockKnowledgeHashMap[currentValue.parentBaseId],
      )

      return findParentItem([...previousValue, parentItem], parentItem)
    }

    return three.reduce(findParentItem, three)
  }

  useEffectAfterMount(() => {
    if (currentSearchPattern.value) {
      setSearchThreeIsLoading(true)
      return debouncedGenerateSearchThree()
    }

    setSearchThreeIsLoading(false)

    return setSearchThree(null)
  }, [currentSearchPattern.value])

  const handleChooseBaseItem = (baseItem: IKnowledgeBaseItem) => {
    queryUtils.addQuery({
      query: {
        pageViewId: baseItem.id,
      },
    })

    setCurrentBaseItem(baseItem)
  }

  const handleClearBaseItem = () => {
    queryUtils.removeQuery({
      query: 'pageViewId',
    })

    setCurrentBaseItem(null)
  }

  const handleChangeSearchPattern = (value: string) => {
    currentSearchPattern.setValue(value)
  }

  const handleSelectRef = (baseItemRef: HTMLLIElement | null) => {
    setBaseItemRef(baseItemRef)
  }

  return (
    <KnowledgeBaseContext.Provider
      value={{
        state: {
          currentBaseItem,
          initializationThree,
          searchThree,
          baseItemRef,
          searchThreeIsLoading,
          currentSearchPattern: currentSearchPattern.value,
        },
        handlers: {
          handleChooseBaseItem,
          handleClearBaseItem,
          handleChangeSearchPattern,
          handleSelectRef,
        },
      }}
    >
      {children}
    </KnowledgeBaseContext.Provider>
  )
}

export const useKnowledgeBase = () => {
  return useContext(KnowledgeBaseContext)
}

export default KnowledgeBaseManager
