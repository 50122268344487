import { cloneElement, FC, isValidElement } from 'react'

import * as constants from '@components/ReactFlipToolkit/constants'

const ExitContainer: FC = ({ children }) => {
  if (!isValidElement(children)) return null

  return cloneElement(children, {
    [constants.DATA_EXIT_CONTAINER]: true,
  })
}

export default ExitContainer
