import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import { PopupProps } from 'react-popup-manager'

import Form from '@components/Form'
import Icon from '@components/Icon/Icon'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { chooseNpaBlockValues } from '@components/RegistryNpa/Choose/const'
import NpaModalFilters from '@components/RegistryNpa/Choose/Filters'
import NpaModalGrid from '@components/RegistryNpa/Choose/Grid'
import { useChooseNpaManager } from '@components/RegistryNpa/Choose/Manager'
import { ChooseNpaFormValues } from '@components/RegistryNpa/Choose/types'
import env from '@config'
import { formatCurrency } from '@helpers/format'
import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import searchIcon from '@icons/action/search.svg'
import closeIcon from '@icons/navigation/close.svg'

import styles from './Modal.module.scss'

type RegistryNpaChooseModalProps = PopupProps

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

const RegistryNpaChooseModal: FC<RegistryNpaChooseModalProps> = ({ isOpen, onClose }) => {
  const formInstance = useFormContext<ChooseNpaFormValues>()

  const {
    state: { filtersInstance, requestLoading, viewMode, gridSwrInstance },
    actions: { handleSubmitNpa },
  } = useChooseNpaManager()

  const { mapOfNpaDirections } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const preparedOnClose = () => {
    onClose?.()
  }

  const { isValid } = useFormState({
    control: formInstance.control,
  })

  const threeToRender = useWatch({
    control: formInstance.control,
    name: chooseNpaBlockValues.threeToRender,
  })

  const chosenParts = useWatch({
    control: formInstance.control,
    name: chooseNpaBlockValues.chosenParts,
  })

  const direction = useWatch({
    control: filtersInstance?.control,
    name: 'filters.direction',
  })

  const investmentRenderCondition = useMemo(() => {
    return (
      !gridSwrInstance?.isNpaListLoading &&
      viewMode === 'default' &&
      threeToRender?.[0]?.minInvestmentAmount
    )
  }, [gridSwrInstance?.isNpaListLoading, threeToRender, viewMode])

  const handleSubmitForm = async () => {
    await formInstance.trigger()

    await handleSubmitNpa?.()
  }

  return (
    <Modal.Base
      containerClassName={styles.registryNpaModal__container}
      isOpen={isOpen}
      dataTestId="RegistryNpaChooseModal-modal"
      onClose={preparedOnClose}
    >
      <Form className={styles.registryNpaModal__form}>
        <div className={styles.registryNpaModal}>
          <header className={styles.registryNpaModal__header}>
            <Typography.Headline variant={'headlineH2'}>Выберите НПА</Typography.Headline>
            <div className={styles['registryNpaModal__header-rightPart']}>
              <ControlledInput
                control={filtersInstance?.control}
                name="searchString"
                inputProps={{
                  rootClassName: styles.registryNpaModal__search,
                  size: 'm',
                  placeholder: 'Поиск по наименованию и номеру акта',
                  clear: true,
                  view: 'secondary',
                  dataTestId: 'RegistryNpaChooseModal-searchString-input',
                  leftAddons: (
                    <Icon
                      noCurrentColorSvgFill
                      src={searchIcon}
                      size="s"
                      className={styles.searchIcon}
                    />
                  ),
                }}
              />
              <Button
                className={styles.registryNpaModal__addButton}
                dataTestId="RegistryNpaChooseModal-addNpa-button"
                disabled={
                  !chosenParts.length || (!isValid && viewMode === 'chosen') || requestLoading
                }
                loaderProps={{
                  loading: requestLoading,
                  placement: 'trailing',
                  variant: 'lite',
                }}
                onClick={handleSubmitForm}
              >
                Добавить
              </Button>
            </div>
          </header>
          <div className={styles.registryNpaModal__controls}>
            <NpaModalFilters />
          </div>

          {investmentRenderCondition && direction && (
            <div className={styles.registryNpaModal__investmentSum}>
              <Typography.Body color={'text-accent-brand'} variant={'bodyMMedium'}>
                {`Чтобы стабилизировать НПА «${
                  mapOfNpaDirections?.[direction]
                }», объём капиталовложений проекта должен быть выше ${
                  threeToRender[0].minInvestmentAmount
                    ? formatCurrency(threeToRender[0].minInvestmentAmount)
                    : ''
                } ₽`}
              </Typography.Body>
            </div>
          )}

          <div className={styles.registryNpaModal__grid}>
            <NpaModalGrid />
          </div>
          <div className={styles.registryNpaModal__info}>
            <Typography.Body as={'span'} variant={'bodyMMedium'}>
              Если Вы не нашли нужное положение или акт, напишите в Минэкономразвития России:{' '}
            </Typography.Body>
            <Typography.Body variant={'bodyMMedium'} color={'text-accent-brand'}>
              <a href={`mailto:${ECONOMY_SUPPORT_EMAIL}`}>{ECONOMY_SUPPORT_EMAIL}</a>
            </Typography.Body>
          </div>
        </div>
        <IconButton
          className={styles.registryNpaModal__close}
          view={'basic'}
          size={'xl'}
          geometry={'round'}
          dataTestId="RegistryNpaChooseModal-closeModal-iconButton"
          icon={{ src: closeIcon, noCurrentColorSvgFill: true }}
          onClick={preparedOnClose}
        />
      </Form>
    </Modal.Base>
  )
}

export default memo(RegistryNpaChooseModal)
