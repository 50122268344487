import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import CreditForm from './AdditionalForms/CreditForm'
import { CreditsPathName } from './types'

const { isFormFieldError } = DocumentFormHelpers

const Fourth = () => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleRemoveItemFromList, handleAddItemToListWithOutValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  const { fields: credits } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    CreditsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: informationOfExecutionConditionsSZPKBlockValues['4'].percentCredits,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const creditsError = formInstance.getFieldState(
    informationOfExecutionConditionsSZPKBlockValues['4'].percentCredits,
  )?.error

  const handleAddCredit = async () => {
    await handleAddItemToListWithOutValue?.(
      informationOfExecutionConditionsSZPKBlockValues['4'].percentCredits,
    )

    if (!credits.length) {
      formInstance.clearErrors(informationOfExecutionConditionsSZPKBlockValues['4'].percentCredits)
    }
  }

  const handleRemoveCredit = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${informationOfExecutionConditionsSZPKBlockValues['4'].percentCredits}.${index}`,
      informationOfExecutionConditionsSZPKBlockValues['4'].percentCredits,
    )
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['4'].percentCredits,
                })}
              >
                <FlipperList list={credits}>
                  {credits.map((credit, index) => {
                    const formName =
                      `${informationOfExecutionConditionsSZPKBlockValues['4'].percentCredits}.${index}` as const

                    return (
                      <CreditForm
                        key={credit.id}
                        blockViewIsValidating={blockViewIsValidating}
                        creditItemId={credit.id}
                        editMode={editMode}
                        formInstance={formInstance}
                        indexOfCredit={index}
                        name={formName}
                        onDeleteCredit={handleRemoveCredit(index)}
                      />
                    )
                  })}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </Col>
          {isFormFieldError(creditsError) && !credits.length && (
            <Row className={'px-1'}>
              <FormError rowClassName={'px-4'} colClassName={'px-3'}>
                {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
              </FormError>
            </Row>
          )}
        </Row>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddCredit}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    dataTestId="StepProjectArrayForm-add-button"
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить договор
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Fourth
