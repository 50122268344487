import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import cn from 'classnames'

import styles from './ProportionalBox.style.module.scss'

interface ProportionalBoxProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  width: number
  height: number
  children: ReactNode
}

const ProportionalBox: FC<ProportionalBoxProps> = ({
  height,
  width,
  children,
  className,
  ...rest
}) => {
  return (
    <div className={cn(styles.box, className)} {...rest}>
      <div
        className={styles.box_wrapper}
        style={{
          paddingBottom: `${(height * 100) / width}%`,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default ProportionalBox
