import FileDropzoneErrorPrimary from '@components/FileDropzone/ErrorPrimary'
import FileDropzoneErrorSecondary from '@components/FileDropzone/ErrorSecondary'
import FolderDropzone from '@components/FileDropzone/Folder'
import FileDropzonePrimary from '@components/FileDropzone/Primary'
import { FileDropzonePrimaryProps } from '@components/FileDropzone/Primary'
import FileDropzoneSecondary from '@components/FileDropzone/Secondary'
import { FileDropzoneSecondaryProps } from '@components/FileDropzone/Secondary'

interface FileDropzoneProps {
  Primary: typeof FileDropzonePrimary
  Secondary: typeof FileDropzoneSecondary
  Folder: typeof FolderDropzone
  ErrorPrimary: typeof FileDropzoneErrorPrimary
  ErrorSecondary: typeof FileDropzoneErrorSecondary
}

const FileDropzone: FileDropzoneProps = () => null

FileDropzone.Primary = FileDropzonePrimary
FileDropzone.Secondary = FileDropzoneSecondary
FileDropzone.Folder = FolderDropzone
FileDropzone.ErrorPrimary = FileDropzoneErrorPrimary
FileDropzone.ErrorSecondary = FileDropzoneErrorSecondary

export type { FileDropzonePrimaryProps, FileDropzoneSecondaryProps }

export default FileDropzone
