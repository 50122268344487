import { Link } from 'react-router-dom'

import Button from '@components/Button'
import Container from '@components/Container'
import { Paths } from '@constants/paths'
import SearchImg from '@images/Search.png'

import styles from './NotFound.module.scss'

const NotFound = () => {
  return (
    <div className={styles.wrapper}>
      <Container className={styles.container}>
        <div className={styles.info}>
          <div className={styles.content}>
            <h1 className={styles.title}>Похоже, этой страницы не существует</h1>
            <h5 className={styles.subtitle}>Мы не смогли найти страницу, которую вы ищете</h5>
            <Link to={Paths.Home}>
              <Button size="l">Вернуться на главную</Button>
            </Link>
          </div>
          <div className={styles.img}>
            <img src={SearchImg} alt="search" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NotFound
