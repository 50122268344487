import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { aAgreementOnCreationOtherEstateObjectsConsts } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import {
  AAgreementOnCreationOtherEstateObjectOption,
  AAgreementOnCreationOtherEstateObjectsFormValues,
  IConstructionExploitation,
  IConstructionObject,
  IConstructionPermission,
  IConstructionRegistration,
  IConstructionStage,
  IConstructionSubObject,
  ObjectArea,
  ObjectRegion,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForAAgreementOnCreationOtherEstateObjectsForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnCreationOtherEstateObjectsFormValues => {
  const getPreparedObjectRegions = (objectRegions: any[]): ObjectRegion[] => {
    if (!isArray(objectRegions) || !objectRegions.length) return []

    return objectRegions.map(
      (objectRegion): ObjectRegion => ({
        id: objectRegion?.value?.objectRegionCode?.propertyId,
        isNew: objectRegion?.new,
        objectRegion: objectRegion?.value?.objectRegionCode?.value || '',
      }),
    )
  }

  const preparedConstructionStages: IConstructionStage[] = (() => {
    return objectFromPropertyState.steps?.value.map((step, indexOfStep): IConstructionStage => {
      const stepAnchorId = `5.${indexOfStep + 1}`

      const preparedConstructionObjects = (() => {
        const objects = step.value.objects?.value?.filter((object) => {
          return (
            object.value.isMainObject?.value &&
            !object.value.isSubObject?.value &&
            !object.value.objectOtherActivityName?.value?.id
          )
        })

        const subObjects = step.value.objects?.value?.filter((object) => {
          return (
            object.value.isSubObject?.value &&
            !object.value.objectOtherActivityName?.value?.id &&
            object.value.parentSubObject?.value
          )
        })

        const preparedConstructionObjects: IConstructionObject[] = objects?.map(
          (object, indexOfObject): IConstructionObject => {
            const objectAnchorId = `${stepAnchorId}.1.${indexOfObject + 1}`

            const preparedSubObjects: IConstructionSubObject[] = subObjects
              ?.filter((subObject) => {
                return object.permanentId === subObject.value.parentSubObject?.value?.id
              })
              .map((subObject, indexOfSubObject): IConstructionSubObject => {
                return {
                  anchorId: `${objectAnchorId}.${indexOfSubObject + 1}`,
                  id: subObject.propertyId,
                  isNew: subObject?.new ?? false,
                  objectName: subObject.value.objectName?.value,
                  objectRegions: getPreparedObjectRegions(subObject.value?.objectRegions?.value),
                  objectConstructionKind: subObject.value?.objectConstructionKind?.value,
                  objectPurpose: subObject.value?.objectPurpose?.value,
                  objectConstructionSquare: subObject.value?.objectConstructionSquare?.value,
                  objectSquare: subObject.value.objectSquare?.value,
                  objectNonLivingAreaSquare: subObject.value?.objectNonLivingAreaSquare?.value,
                  objectLivingAreaSquare: subObject.value?.objectLivingAreaSquare?.value,
                  objectFloor: subObject.value.objectFloor?.value,
                  objectUndergroundFloor: subObject.value?.objectUndergroundFloor?.value,
                  objectHeight: subObject.value?.objectHeight?.value,
                  objectDetails: subObject.value.objectDetails?.value,
                  objectIsInfrastructure: subObject.value.isInfrastructure?.value,
                  objectInfrastructureType: !!subObject.value.infrastructureType?.value?.id
                    ? {
                        value: subObject.value.infrastructureType.value?.id || '',
                        displayValue: subObject.value.infrastructureType.value?.name || '',
                      }
                    : null,
                  oldIsCapitalObject: subObject.value.oldIsCapitalObject?.value,
                  oldCreatedDocument: subObject.value.oldCreatedDocument?.value,
                  oldCreatedOrganization: subObject.value.oldCreatedOrganization?.value,
                }
              })

            const preparedObjectAreas =
              isArray(object.value?.objectAreas?.value) && object.value?.objectAreas?.value?.length
                ? object.value?.objectAreas?.value.map((objectArea): ObjectArea => {
                    return {
                      id: objectArea.propertyId,
                      isNew: objectArea?.new,
                      objectAreaNumber: objectArea.value.objectAreaNumber?.value || '',
                      objectAreaSquare: objectArea.value.objectAreaSquare?.value || '',
                    }
                  })
                : []

            return {
              anchorId: objectAnchorId,
              id: object.permanentId,
              isNew: object?.new ?? false,
              objectName: object.value.objectName?.value,
              objectRegions: getPreparedObjectRegions(object.value?.objectRegions?.value),
              objectAreas: preparedObjectAreas,
              objectConstructionKind: object.value?.objectConstructionKind?.value,
              objectPurpose: object.value?.objectPurpose?.value,
              objectConstructionSquare: object.value?.objectConstructionSquare?.value,
              objectSquare: object.value.objectSquare?.value,
              objectNonLivingAreaSquare: object.value?.objectNonLivingAreaSquare?.value,
              objectLivingAreaSquare: object.value?.objectLivingAreaSquare?.value,
              objectFloor: object.value.objectFloor?.value,
              objectUndergroundFloor: object.value?.objectUndergroundFloor?.value,
              objectHeight: object.value?.objectHeight?.value,
              objectDetails: object.value.objectDetails?.value,
              objectIsInfrastructure: object.value.isInfrastructure?.value,
              objectInfrastructureType: !!object.value.infrastructureType?.value?.id
                ? {
                    value: object.value.infrastructureType.value?.id || '',
                    displayValue: object.value.infrastructureType.value?.name || '',
                  }
                : null,
              subObjects: preparedSubObjects,
              oldIsCapitalObject: object.value.oldIsCapitalObject?.value,
              oldCreatedDocument: object.value.oldCreatedDocument?.value,
              oldCreatedOrganization: object.value.oldCreatedOrganization?.value,
            }
          },
        )

        return preparedConstructionObjects
      })()

      const preparedPermissions = ((): IConstructionPermission[] => {
        if (!step.value.permissions?.value || !step.value.permissions.value.length) return []

        return step.value.permissions?.value.map(
          (permission, indexOfPermission): IConstructionPermission => {
            const preparedObjectNamePermissions =
              ((): AAgreementOnCreationOtherEstateObjectOption[] =>
                permission.value?.permissionObjects?.value?.map(
                  (permissionObject): AAgreementOnCreationOtherEstateObjectOption => ({
                    id: permissionObject.propertyId,
                    isNew: permissionObject?.new || true,
                    objectOption: !!permissionObject?.value?.id
                      ? {
                          value: permissionObject?.value?.id || '',
                          displayValue: permissionObject?.value?.objectName || '',
                        }
                      : null,
                  }),
                ))()

            return {
              anchorId: `${stepAnchorId}.2.${indexOfPermission + 1}`,
              id: permission.propertyId,
              isNew: permission?.new ?? false,
              permissionKind: !!permission.value.permissionKind?.value?.id
                ? {
                    value: permission.value.permissionKind?.value?.id || '',
                    displayValue: permission.value.permissionKind?.value?.name || '',
                  }
                : null,
              permissionOrganization: permission.value.permissionOrganization?.value,
              objectNamePermissions: preparedObjectNamePermissions,
              isReceivedPermission: permission.value.isReceivedPermission?.value,
              permissionDate: permission.value.permissionDate?.value,
              permissionNumber: permission.value.permissionNumber?.value,
              receivedPermissionDate: permission.value.receivedPermissionDate?.value,
            }
          },
        )
      })()

      const preparedExploitationObjects = ((): IConstructionExploitation[] => {
        if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
          return []

        return step.value.commissionObjects?.value.map(
          (exploitation, indexOfExploitation): IConstructionExploitation => ({
            anchorId: `${stepAnchorId}.3.${indexOfExploitation + 1}`,
            id: exploitation.propertyId,
            isNew: exploitation?.new ?? false,
            objectName: !!exploitation.value?.commissionObjectId?.value?.id
              ? {
                  value: exploitation.value?.commissionObjectId?.value?.id || '',
                  displayValue: exploitation.value?.commissionObjectId?.value?.objectName || '',
                }
              : null,
            objectCommissioningDate: exploitation.value?.commissionObjectDate?.value,
            objectPermissionCommissionNumber: !!exploitation.value?.commissionPermissionNumber
              ?.value?.id
              ? {
                  value: exploitation.value?.commissionPermissionNumber?.value?.id || '',
                  displayValue:
                    exploitation.value?.commissionPermissionNumber?.value?.permissionNumber || '',
                }
              : null,
          }),
        )
      })()

      const preparedRegistrationObjects = ((): IConstructionRegistration[] => {
        if (!step.value.registrationObjects?.value || !step.value.registrationObjects?.value.length)
          return []

        return step.value.registrationObjects?.value.map(
          (registration, indexOfRegistration): IConstructionRegistration => ({
            anchorId: `${stepAnchorId}.4.${indexOfRegistration + 1}`,
            id: registration.propertyId,
            isNew: registration?.new ?? false,
            objectName: !!registration.value?.registrationObjectId?.value?.id
              ? {
                  value: registration.value?.registrationObjectId?.value?.id || '',
                  displayValue: registration.value?.registrationObjectId?.value?.objectName || '',
                }
              : null,
            objectType: registration.value?.stateRegistrationType?.value,
            estateObjectStateRegistrationDate:
              registration.value?.objectStateRegistrationDate?.value,
            estateObjectStateRegistrationNumber:
              registration.value?.objectStateRegistrationNumber?.value,
            estateObjectStateRegistrationOrganization:
              registration.value?.objectStateRegistrationOrganization?.value,
          }),
        )
      })()

      const preparedIsShowConstructionObjects = ((): boolean => {
        if (
          !isArray(step.value.stepActivityNames?.value) ||
          !step.value.stepActivityNames?.value.length
        )
          return false

        const stepActivityNames = step.value.stepActivityNames?.value.filter(
          (stepActivityName) =>
            stepActivityName.value?.name ===
              aAgreementOnCreationOtherEstateObjectsConsts.CONSTRUCTION ||
            stepActivityName.value?.name ===
              aAgreementOnCreationOtherEstateObjectsConsts.RECONSTRUCTION ||
            stepActivityName.value?.name ===
              aAgreementOnCreationOtherEstateObjectsConsts.MODERNIZATION,
        )
        return !!stepActivityNames.length
      })()

      return {
        id: step.propertyId,
        anchorId: stepAnchorId,
        stepNumber: step.value.stepNumber?.value,
        stepDescription: step.value.stepDescription?.value,
        stepCompensationValue: step.value.stepCompensationValue?.value,
        stepCompensationValueCommited: step.value.stepCompensationValue?.commitedValue,
        stepCompensationForm: !!step.value.stepCompensationForm?.value?.id
          ? {
              value: step.value?.stepCompensationForm?.value?.id || '',
              displayValue: step.value?.stepCompensationForm?.value?.name || '',
            }
          : null,
        stepCompensationFormCommited: !!step.value.stepCompensationForm?.commitedValue?.id
          ? {
              value: step.value?.stepCompensationForm?.commitedValue?.id || '',
              displayValue: step.value?.stepCompensationForm?.commitedValue?.name || '',
            }
          : null,

        stepEndDate: step.value.stepEndDate?.value,

        isShowConstructionObjects: preparedIsShowConstructionObjects,
        constructionObjects: preparedConstructionObjects,
        permissionsObjects: preparedPermissions,

        exploitation: {
          objectCommissioningEndDate: step.value.objectCommissioningEndDate?.value,
          objectCommissioningEndDateCommited: step.value.objectCommissioningEndDate?.commitedValue,
          exploitationObjects: preparedExploitationObjects,
        },

        registration: {
          typeObjectStateRegistration: step.value.typeObjectStateRegistration?.value,
          typeObjectStateRegistrationCommited:
            step.value.typeObjectStateRegistration?.commitedValue,
          objectStateRegistrationEndDate: step.value.objectStateRegistrationEndDate?.value,
          objectStateRegistrationEndDateCommited:
            step.value.objectStateRegistrationEndDate?.commitedValue,
          registrationObjects: preparedRegistrationObjects,
        },
      }
    })
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      constructionStages: preparedConstructionStages,
    },
    additionalFields: {
      isFederal: objectFromPropertyState?.isFederal?.value,
      isPaper: objectFromPropertyState.isPaper?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
      allProjectObjectsLength: isArray(objectFromPropertyState.steps?.value)
        ? objectFromPropertyState.steps?.value.reduce((accum, currentValue) => {
            if (isArray(currentValue.value.objects?.value)) {
              return accum + currentValue.value.objects?.value.length
            }

            return accum
          }, 0)
        : 0,
    },
  }
}

export { generateRHFObjectForAAgreementOnCreationOtherEstateObjectsForm }
