import React, { FC } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { FormModalLayoutWrapperProps } from '@components/DocumentFormComponents/types'
import { useNotificationOfChangeDocumentsManager } from '@components/Forms/NotificationOfChangeDocumentsForm/Manager'

const NotificationOfChangeDocumentsLayoutWrapper: FC<FormModalLayoutWrapperProps> = ({
  children,
  ...rest
}) => {
  const {
    state: { lastUpdateDraftTime },
    actions: { handleFormClose },
  } = useNotificationOfChangeDocumentsManager()

  const handleClose = () => {
    handleFormClose?.()
  }

  return (
    <>
      <DocumentLayoutContent {...rest} draftIsSaved={!!lastUpdateDraftTime} onClose={handleClose}>
        {children}
      </DocumentLayoutContent>
    </>
  )
}

export default NotificationOfChangeDocumentsLayoutWrapper
