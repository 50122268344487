import React, { useRef } from 'react'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'

interface HandleIntervalProps {
  fetcher: () => Promise<void>
  errorAlertText?: string
}

interface FormIntervalReturn {
  intervalRef: React.MutableRefObject<NodeJS.Timeout | null>
  handleInterval: (props: HandleIntervalProps) => Promise<void>
  clearDraftSaveInterval: () => null | void
}

const useFormInterval = (): FormIntervalReturn => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const clearDraftSaveInterval = () => intervalRef.current && clearInterval(intervalRef.current)

  const handleInterval = async ({ fetcher, errorAlertText }: HandleIntervalProps) => {
    try {
      await fetcher()
    } catch (error: any) {
      if (error?.statementIsChanged) {
        toast(
          <Alert transparent variant="warning">
            {errorAlertText}
          </Alert>,
        )
      }
    }
  }

  return {
    intervalRef,
    handleInterval,
    clearDraftSaveInterval,
  }
}
export type { FormIntervalReturn }
export { useFormInterval }
