import { FC } from 'react'

import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import RadioGroup from '@components/Radio/Group'
import RadioButton from '@components/Radio/Item'

interface RadioProps extends FC {
  Button: typeof RadioButton
  Group: typeof RadioGroup

  ControlledGroup: typeof ControlledRadioGroup
}

const Radio: RadioProps = () => null

Radio.Button = RadioButton
Radio.Group = RadioGroup
Radio.ControlledGroup = ControlledRadioGroup

export default Radio
