import React, { FC, memo, useMemo } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps, MapOfMenu } from '@components/DocumentFormComponents/types'
import AAgreementAccessionMunicipal from '@components/Forms/AAgreementAccessionMunicipalForm'
import {
  aAgreementAccessionMunicipalBlockValues,
  defaultAAgreementAccessionMunicipalFormValues,
  mapOfAAgreementAccessionMunicipalMenu,
} from '@components/Forms/AAgreementAccessionMunicipalForm/const'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'
import { AAgreementAccessionMunicipalFieldsControlUpdateWatcher } from '@components/Forms/AAgreementAccessionMunicipalForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import AAgreementAccessionMunicipalLayoutWrapper from './Wrapper'

const AAgreementAccessionMunicipalLayout: FC<FormModalLayoutProps> = ({
  isOpen,
  formId,
  onClose,
  editMode,
  projectId,
  initialErrorsFromViolations,
}) => {
  const formInstance = useFormWithDefaultProps<AAgreementAccessionMunicipalFormValues>({
    defaultValues: defaultAAgreementAccessionMunicipalFormValues,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementAccessionMunicipalBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  }).length

  const currentMenu = useMemo(() => {
    return [...mapOfAAgreementAccessionMunicipalMenu].reduce<MapOfMenu[]>(
      (previousValue, currentValue) => {
        if (!isMunicipalitiesRender && currentValue.id === '3') return previousValue

        return [...previousValue, currentValue]
      },
      [],
    )
  }, [isMunicipalitiesRender])

  const handleOnClose = () => {
    AAgreementAccessionMunicipalFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <AAgreementAccessionMunicipal.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={currentMenu[0].id}>
            <AAgreementAccessionMunicipalLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Дополнительное соглашение о присоединении муниципального образования
                </Typography.Headline>
                <AAgreementAccessionMunicipal.StatementForm
                  className={styles.layout__form}
                  initialMenu={currentMenu}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <AAgreementAccessionMunicipal.Menu
                  className={styles.layout__menu}
                  initialMenu={currentMenu}
                />
              </div>
            </AAgreementAccessionMunicipalLayoutWrapper>
          </FlatMenuManager>
        </AAgreementAccessionMunicipal.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(AAgreementAccessionMunicipalLayout)
