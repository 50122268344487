import { objOfDateFormats } from '@constants/dateFormats'
import { isEmptyString, isNullOrUndefined } from '@helpers/checkTypes'
import DayjsService from '@services/Dayjs/Dayjs.service'

const convertStringToDateFormat = (value: string) => {
  return isEmptyString(value) || isNullOrUndefined(value)
    ? ''
    : DayjsService.dayjs(value, objOfDateFormats.defaultNativeDateFormat).format(
        objOfDateFormats.defaultFormat,
      )
}

export { convertStringToDateFormat }
