import { type FC, memo } from 'react'
import { useParams } from 'react-router'

import AttachmentItem from '@components/Attachments/Attachment'
import { DocumentsType } from '@constants/types'
import { loadingTypesMap } from '@services/Dictionaries/dictionaries.const'
import type { IDocumentTypeInfoWithDict } from '@services/Documents/documents.entity'

interface AttachmentControllerProps {
  parentSetType: string

  documentProps: IDocumentTypeInfoWithDict
  permissions: number

  documentSetId: string
  currentDfoId: string
  showChangedSetDocuments?: boolean
  isChangesMadeTab?: boolean
}

const AttachmentController: FC<AttachmentControllerProps> = ({
  parentSetType,
  documentProps,
  permissions,
  documentSetId,
  showChangedSetDocuments,
  isChangesMadeTab,
  currentDfoId,
}) => {
  const { documents, folders, isDraftExists, documentInfoFromDict } = documentProps
  const { projectId = '' } = useParams()
  const { isVisible, documentType, loadingType } = documentInfoFromDict

  const isStatementDocumentType = documentType === DocumentsType.STATEMENT
  const isNpaDocumentType = documentType === DocumentsType.NPA_LIST

  const isLoadingTypeDraft = loadingType === loadingTypesMap.DRAFT

  const isNotVisibleAttachment = !isVisible && !isLoadingTypeDraft

  const versionOfDocumentsNotFixed =
    !!documents?.length && documents?.some((document) => document.versionChangeState !== 'FIXED')

  const versionOfDocumentsInFolderNotFixed = folders?.some((item) =>
    item?.documents?.some((document) => document.versionChangeState !== 'FIXED'),
  )

  const hasChangedDocs = versionOfDocumentsNotFixed || versionOfDocumentsInFolderNotFixed

  if (isNotVisibleAttachment || (showChangedSetDocuments && !hasChangedDocs)) return null

  if (isStatementDocumentType || isNpaDocumentType) {
    return (
      <AttachmentItem.Addon
        documentType={documentType}
        permissions={permissions}
        documentSetId={documentSetId}
        dfoId={currentDfoId}
        projectId={projectId}
        isDraftExists={!!isDraftExists}
      >
        <AttachmentItem.APIProvider>
          <AttachmentItem.ItemController
            documentSetId={documentSetId}
            parentSetType={parentSetType}
            permissions={permissions}
            documentProps={documentProps}
            projectId={projectId}
            dfoId={currentDfoId}
            isChangesMadeTab={isChangesMadeTab}
          />
        </AttachmentItem.APIProvider>
      </AttachmentItem.Addon>
    )
  }

  return (
    <>
      <AttachmentItem.APIProvider>
        <AttachmentItem.ItemController
          documentSetId={documentSetId}
          parentSetType={parentSetType}
          permissions={permissions}
          documentProps={documentProps}
          projectId={projectId}
          dfoId={currentDfoId}
          isChangesMadeTab={isChangesMadeTab}
        />
      </AttachmentItem.APIProvider>
    </>
  )
}

export default memo(AttachmentController)
