import { createContext, ReactNode, useContext } from 'react'
import { DefaultValues, FieldValues, UseFormReturn } from 'react-hook-form'

import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'

interface FormModifierProviderWrapperProps<T extends FieldValues> {
  defaultValues: DefaultValues<T>
  children: ReactNode
}

const FormModifierContext = createContext<UseFormReturn | null>(null)

const useFormModifierContext = <TFieldValues extends FieldValues, TContext = any>(): UseFormReturn<
  TFieldValues,
  TContext
> => {
  return useContext(FormModifierContext) as UseFormReturn<TFieldValues, TContext>
}

const FormModifierProviderWrapper = <T extends FieldValues>({
  defaultValues,
  children,
}: FormModifierProviderWrapperProps<T>) => {
  const formInstance = useFormWithDefaultProps<T>({
    defaultValues,
  })

  return (
    <FormModifierContext.Provider value={formInstance as UseFormReturn}>
      {children}
    </FormModifierContext.Provider>
  )
}

export { FormModifierContext, useFormModifierContext }
export default FormModifierProviderWrapper
