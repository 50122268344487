import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfIdentifiedViolationsBlockValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/const'
import { thirdSectionValidationMap } from '@components/Forms/NotificationOfIdentifiedViolationsForm/Forms/3/validation'
import { useNotificationOfIdentifiedViolationsManager } from '@components/Forms/NotificationOfIdentifiedViolationsForm/Manager'
import { NotificationOfIdentifiedViolationsFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/types'
import { NotificationOfIdentifiedViolationsFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfIdentifiedViolationsForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance = useFormContext<NotificationOfIdentifiedViolationsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedViolationsManager()

  const { getSubscribableControlProps, getControllerProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: NotificationOfIdentifiedViolationsFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={4}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsBlockValues['3'].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Полное наименование'}
                controllerProps={getControllerProps({
                  name: notificationOfIdentifiedViolationsBlockValues['3'].investorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsBlockValues['3'].investorFullNameGenitive,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: notificationOfIdentifiedViolationsBlockValues['3'].investorFullNameGenitive,
                  rules: thirdSectionValidationMap.investorFullNameGenitive,
                  textareaProps: { 
                    dataTestId: 'investorFullNameGenitive',
                    label: 'Полное наименование в родительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['3']
                            .investorFullNameGenitive,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        notificationOfIdentifiedViolationsBlockValues['3'].investorFullNameGenitive,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
