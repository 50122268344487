export const makeFirstLetterInUC = (string: string, separator: string) => {
  return string
    .trim()
    .split(separator)
    .map((str) => str[0].toUpperCase() + str.substring(1))
    .join(separator)
}

export const changeFirstLetter = (str: string, method: 'upper' | 'lower' = 'upper') => {
  const trimString = str.trim()

  const changedFirstLetter =
    method === 'upper' ? trimString.charAt(0).toUpperCase() : trimString.charAt(0).toLowerCase()

  return changedFirstLetter + trimString.slice(1)
}
