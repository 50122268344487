import React, { DOMAttributes, FC } from 'react'

import cn from 'classnames'

import styles from './Typography.module.scss'

type TTextTag = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

export interface ITypography extends DOMAttributes<HTMLParagraphElement> {
  variant: TTextTag
  as?: TTextTag
  color?: 'primary' | 'secondary'
  align?: 'left' | 'center' | 'right'
  className?: string
  fontWeight?: 'normal' | 'medium' | 'semibold' | 'bold'
}

const Typography: FC<ITypography> = ({
  children,
  variant,
  color = 'primary',
  className,
  align = 'left',
  as,
  fontWeight,
  ...props
}) => {
  const modifiedClassName = cn(className, {
    [styles[variant]]: variant,
    [styles[color]]: color,
    [styles[align]]: align,
    ...(fontWeight && { [styles[fontWeight]]: fontWeight }),
  })

  return React.createElement(
    as ?? variant,
    { className: modifiedClassName, color, ...props },
    children,
  )
}

export default Typography
