import { FC, memo, useMemo } from 'react'
import { useParams } from 'react-router'

import AddDocumentContainer from '@components/AddDocumentContainer'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { useSolicitationMembersModal } from '@components/NewDesignedModals/AddSolicitationMembersModal'
import { getDfoByType } from '@components/Projects/[id]/helpers'
import DataView from '@components/Projects/[id]/ParticipantsOfStatement/DataView'
import { DfosStage, NewDfosType, RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useSolicitationMembers } from '@hooks/new/swr/useSolicitationMembers'
import { useProjectActions } from '@hooks/new/swr/useUiActions'
import { useVersions } from '@hooks/new/swr/useVersions'
import EditIcon from '@icons/EditIcon.svg'
import PersonAddIcon from '@icons/PersonAddIcon.svg'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { mapOfMembers } from '@services/Members/Members.maps'

import styles from './SolicitatonMembers.module.scss'

interface SolicitationMembersProps {
  dfo: IDfoListItem
}

const { isDfoTypeExistInActionsById } = DocumentActionsService

//!! Используется только для RELATED_CONTRACT_LIST
const SolicitationMembers: FC<SolicitationMembersProps> = ({ dfo }) => {
  const { projectId } = useParams()

  const { checkingRole } = useAuthContext()

  const { handleOpenAddSolicitationMembersModal } = useSolicitationMembersModal()

  const { solicitationMembers, isLoadingProjectMembers } = useSolicitationMembers({
    key: {
      projectId,
      dfoId: dfo.id,
      _key: 'solicitationMembers',
    },
    config: {
      isPaused: () => !projectId || !dfo.id,
    },
  })

  const { projectActions, isLoadingProjectActions } = useProjectActions({
    key: {
      projectId,
      _key: 'projectActions',
    },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'SolicitationMembers',
          moduleName: 'DocumentsView',
        },
      }),
    },
  })

  const { versions, isLoadingVersions } = useVersions({
    key: { dfoId: dfo?.id, projectId, _key: 'versions' },
    config: {
      isPaused: () => !dfo?.id || !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'SolicitationMembers',
          moduleName: 'DocumentsView',
        },
      }),
    },
  })

  const { projectDfos, isLoadingProjectDfos } = useProjectDfos({
    key: { projectId, _key: 'projectDfos' },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ReorganizationRegistrationModal',
          moduleName: 'useDocumentActions',
        },
      }),
    },
  })

  const SZPKDfo = useMemo(() => {
    if (!projectDfos) return

    return getDfoByType(projectDfos, NewDfosType.SZPK)
  }, [projectDfos])

  const isUO = checkingRole?.(RolesTypes.UO)
  const isInvestor = checkingRole?.(RolesTypes.INVESTOR)

  const ORPConditionToWriteMode = useMemo(() => {
    return (
      isInvestor &&
      dfo.stage === DfosStage.DRAFT &&
      !!versions?.find((version) => version.isActual && version.versionNumber === 1)
    )
  }, [dfo.stage, isInvestor, versions])

  const UOConditionToWriteMode = useMemo(() => {
    return (
      isUO &&
      projectActions &&
      isDfoTypeExistInActionsById(dfo.id, projectActions) &&
      dfo.stage === DfosStage.CHECKING
    )
  }, [dfo.id, dfo.stage, isUO, projectActions])

  const conditionToWriteMode = ORPConditionToWriteMode || UOConditionToWriteMode
  const writeControlTextRender =
    SZPKDfo &&
    ([DfosStage.REGISTERED, DfosStage.TERMINATING, DfosStage.TERMINATED] as string[]).includes(
      SZPKDfo.stage,
    )
      ? 'Выберите согласующий орган'
      : 'Укажите участников соглашения и выберите согласующий орган'

  const handleChangeMembers = () => {
    handleOpenAddSolicitationMembersModal({
      projectId: projectId || '',
      dfoId: dfo.id,
    })
  }

  if (!solicitationMembers) return null

  if (
    isLoadingVersions ||
    isLoadingProjectMembers ||
    isLoadingProjectActions ||
    isLoadingProjectDfos
  )
    return <Loader loading variant={'lite'} dataTestId="SolicitationMembers-loader" />

  if (conditionToWriteMode && !solicitationMembers.length)
    return (
      <AddDocumentContainer
        title={writeControlTextRender}
        icon={PersonAddIcon}
        action={{
          dataTestId: 'SolicitationMembers-selectMember-button',
          children: 'Выбрать участника',
          onClick: handleChangeMembers,
        }}
      />
    )

  return (
    <div className={styles.solicitationMembers__readMode}>
      <div className={styles['solicitationMembers__readMode-header']}>
        <Typography.Body variant={'bodyLMedium'} color={'text-base-secondary'}>
          Согласующий орган
        </Typography.Body>
        {conditionToWriteMode && (
          <Button
            size={'s'}
            geometry={'round'}
            dataTestId="SolicitationMembers-changeMember-button"
            view={'plain'}
            leadingIcon={{
              src: EditIcon,
            }}
            onClick={handleChangeMembers}
          >
            Изменить участника
          </Button>
        )}
      </div>

      {solicitationMembers.map((member) => (
        <DataView key={member.id} suptitle={mapOfMembers[member.type]}>
          {member.name}
        </DataView>
      ))}
    </div>
  )
}

export default memo(SolicitationMembers)
