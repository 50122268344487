export const usersTabs = {
  participants: 'participantsTable',
  operators: 'operatorsTable',
} as const

export const filterUsersByActions = Object.freeze({
  all: 'Все',
  onlyDisabled: 'Только заблокированные',
})

export const defaultUsersFormValues = {
  tagRadioGroup: usersTabs.participants,
  searchString: '',
  filterActions: 'all' as keyof typeof filterUsersByActions,
} as const

export const LOCK_ICON_COLOR = '#8391A0' // text-base-tertiary
