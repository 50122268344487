import React, { FC, useCallback, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Alert from '@components/Alert'
import { ButtonProps } from '@components/NewDesign/Button/types'
import Sidebar from '@components/NewDesign/Sidebar'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import { useRemoveModal } from '@components/NewDesignedModals/RemoveModal'
import { dictionariesNames } from '@components/ReferenceBooks/constants'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { useReferenceBooksApi } from '@context/APIContext/hooks/useReferenceBooksApi'
import {
  ReferenceBookItem,
  ReferenceBookItemFlatted,
} from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { isEmptyString, isString } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'

import styles from './Entry.module.scss'

interface FlatEntryProps {
  dictionaryName: keyof typeof dictionariesNames
  onSuccess: VoidFunction
  initialEntry?: ReferenceBookItem
}

type FlatEntrySidebarProps = FlatEntryProps & PopupProps

const useFlatEntrySidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenPartSidebar = (props: FlatEntryProps) => {
    popupManager.open(FlatEntrySidebar, props)
  }

  return {
    handleOpenPartSidebar,
  }
}

const FlatEntrySidebar: FC<FlatEntrySidebarProps> = ({
  initialEntry,
  dictionaryName,
  onSuccess,
  isOpen,
  onClose,
}) => {
  const popupManager = usePopupManager()

  const { createReferenceBook, updateReferenceBook, deleteReferenceBook } = useReferenceBooksApi()

  const { handleOpenRemoveModal } = useRemoveModal()

  const initialFocusRef = useRef<HTMLInputElement | null>(null)

  const formInstance = useForm<ReferenceBookItemFlatted>({
    defaultValues: {
      name: initialEntry?.name ?? '',
    },
  })

  const isCreateMode = !initialEntry

  const { booleanState: isRequestLoading, setBooleanState: setRequestLoading } = useBooleanState()

  const handleSuccessRequest = useCallback(() => {
    popupManager.closeAll()
    onSuccess()
  }, [onSuccess, popupManager])

  const handleToastAlert = useCallback((text = 'значение справочника не может быть пустым') => {
    toast(
      <Alert transparent variant="warning">
        {text}
      </Alert>,
    )
  }, [])

  const handleCreateEntry = useCallback(async () => {
    const formValues = formInstance.getValues()

    setRequestLoading(true)

    try {
      if (isEmptyString(formValues.name.trim())) {
        return handleToastAlert()
      }

      await createReferenceBook({
        dictionaryName,
        newReferenceBookBody: {
          code: formValues.code,
          name: formValues.name.trim(),
        },
      })

      handleSuccessRequest()
    } catch (error) {
      throw error
    } finally {
      setRequestLoading(false)
    }
  }, [
    createReferenceBook,
    dictionaryName,
    formInstance,
    handleSuccessRequest,
    handleToastAlert,
    setRequestLoading,
  ])

  const handleUpdateEntry = useCallback(async () => {
    if (!initialEntry) return

    const formValues = formInstance.getValues()

    setRequestLoading(true)

    try {
      if (isEmptyString(formValues.name.trim())) {
        return handleToastAlert()
      }

      await updateReferenceBook({
        dictionaryName,
        updatedReferenceBookBody: {
          id: initialEntry?.id,
          name: formValues.name,
        },
      })

      handleSuccessRequest()
    } catch (error) {
      throw error
    } finally {
      setRequestLoading(false)
    }
  }, [
    dictionaryName,
    formInstance,
    handleSuccessRequest,
    handleToastAlert,
    initialEntry,
    setRequestLoading,
    updateReferenceBook,
  ])

  const handleDeleteEntry = useCallback(async () => {
    if (!initialEntry) return

    setRequestLoading(true)

    try {
      await deleteReferenceBook({
        dictionaryName,
        id: initialEntry.id,
      })

      handleSuccessRequest()
    } catch (error) {
      throw error
    } finally {
      setRequestLoading(false)
    }
  }, [deleteReferenceBook, dictionaryName, handleSuccessRequest, initialEntry, setRequestLoading])

  const sidebarActions = useMemo(() => {
    const loadingProps = {
      loaderProps: {
        loading: isRequestLoading,
        variant: 'lite',
        placement: 'trailing',
      },
    } as const

    return isCreateMode
      ? ([
          {
            children: 'Отмена',
            view: 'gray',
            onClick: onClose,
          },
          {
            children: 'Добавить',
            onClick: formInstance.handleSubmit(handleCreateEntry),
            disabled: isRequestLoading,
            loaderProps: loadingProps.loaderProps,
          },
        ] as ButtonProps[])
      : [
          {
            children: 'Удалить',
            view: 'gray',
            color: 'negative',
            onClick: () =>
              handleOpenRemoveModal({
                title: 'Удалить запись из справочника?',
                description:
                  'Запись будет удалена из справочника и недоступна \nдля выбора инвестором',
                onConfirm: handleDeleteEntry,
              }),
            disabled: false,
          },
          {
            children: 'Сохранить',
            onClick: formInstance.handleSubmit(handleUpdateEntry),
            disabled: isRequestLoading,
            loaderProps: loadingProps.loaderProps,
          },
        ]
  }, [
    formInstance,
    handleCreateEntry,
    handleDeleteEntry,
    handleOpenRemoveModal,
    handleUpdateEntry,
    isCreateMode,
    isRequestLoading,
    onClose,
  ])

  const preparedOnCloseHandler = () => {
    onClose?.()
  }

  return (
    <Sidebar
      title={isCreateMode ? 'Добавление записи' : 'Просмотр записи'}
      initialFocus={initialFocusRef}
      isOpen={isOpen}
      actions={sidebarActions}
      onClose={preparedOnCloseHandler}
    >
      <div className={styles.flatEntry}>
        <ControlledTextarea
          control={formInstance.control}
          name={'name'}
          rules={{
            required: defaultRHFValidation.required,
            validate: (value) => isString(value) && lengthValidate(value, 1500),
          }}
          textareaProps={{
            label: 'Значение справочника',
            fixWidth: true,
          }}
        />
      </div>
    </Sidebar>
  )
}

export { useFlatEntrySidebar }
export default FlatEntrySidebar
