import { useCallback, useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import {
  AuditListPagination,
  AuditListProps,
  AuditsData,
} from '@context/APIContext/hooks/useOperatorApi'
import { SWRIniniteHookProps } from '@interfaces'
import useSWRInfinite from 'swr/infinite'

interface UseAuditsProps extends SWRIniniteHookProps<AuditsData> {
  pageSize?: number
}

type GetAuditListProps = AuditListPagination & AuditListProps

const useAudits = ({ key, config, pageSize }: UseAuditsProps) => {
  const {
    operatorApi: { getAuditList },
  } = useAPIContext()

  const handleGetAuditList = async ({ _key, ...props }: GetAuditListProps) => {
    const data = await getAuditList(props)
    return data?.content
  }

  const {
    data,
    isValidating: isLoadingAudits,
    setSize,
    size,
    error: errorAudits,
  } = useSWRInfinite(key, handleGetAuditList, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    ...config,
  })

  const preparedData = useMemo(() => {
    return data?.flat()
  }, [data])

  const hasMoreData = useMemo(() => {
    return Boolean(data?.length) && data?.[data.length - 1].length === (pageSize ?? 11)
  }, [data, pageSize])

  const goToNextPage = useCallback(() => {
    hasMoreData && setSize((prev) => prev + 1)
  }, [hasMoreData, setSize])

  return {
    audits: preparedData,
    size,
    goToNextPage,
    errorAudits,
    isLoadingAudits,
    hasMoreData,
  }
}

export { useAudits }
