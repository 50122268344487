import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import CollapsedCard from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/AdditionalForms/CollapsedCard/index'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { EconomicSphereRegionalOkvedsReportsInfoArrayItemPathName } from '../../types'

interface SzpkReportInfoCardProps {
  reportInd: number
  investorFullName: string
  formName: EconomicSphereRegionalOkvedsReportsInfoArrayItemPathName
}

const SzpkReportInfoCard: FC<SzpkReportInfoCardProps> = ({
  reportInd,
  investorFullName,
  formName,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps, getAmountInputProps, getControllerProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
    })

  return (
    <CollapsedCard cardNumber={reportInd + 1} investorFullName={investorFullName}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.projectName`,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование проекта'}
                controllerProps={getControllerProps({
                  name: `${formName}.projectName`,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Объем капитальных вложений'}>
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем введенный инвестором'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.totalCapitalFact`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.totalCapitalFact`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем введенный уполномоченной организацией'}
              titleHeightFallback={'40px'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.totalCapitalFactChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.totalCapitalFactChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Объем капиталовложений'}>
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем введенный инвестором'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorCapitalFact`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.investorCapitalFact`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем введенный уполномоченной организацией'}
              titleHeightFallback={'40px'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorCapitalFactChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.investorCapitalFactChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Количество рабочих мест'}>
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Количество, введенное инвестором'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.newWorkPlacesFact`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.newWorkPlacesFact`,
                    inputProps: {
                      disabled: true,
                      suffix: '',
                      integersOnly: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Количество, введенное уполномоченной организацией'}
              titleHeightFallback={'40px'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.newWorkPlacesFactChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.newWorkPlacesFactChecker`,
                    inputProps: {
                      disabled: true,
                      suffix: '',
                      integersOnly: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
      </Stack>
    </CollapsedCard>
  )
}

export default SzpkReportInfoCard
