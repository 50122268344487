import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import documentStyles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { SecondSubjectArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/2/types'
import { secondSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/2/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface SubjectFormProps {
  formName: SecondSubjectArrayPathName
}

const SubjectForm: FC<SubjectFormProps> = ({ formName }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getControllerProps, getSubscribableControlProps, getSingleSelectProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: ProjectSZPKFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectFullName`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Наименование'}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectFullName`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectRegion`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Наименование субъекта Российской Федерации'}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectRegion`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectAddress`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Место нахождения'}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectAddress`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectInn`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'ИНН'}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectInn`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
        <Col xs={3}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectOgrn`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'ОГРН'}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectOgrn`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
        <Col xs={3}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectKpp`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'КПП'}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectKpp`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
        <Col xs={3}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectOktmo`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'ОКТМО'}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectOktmo`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectPaymentDetails`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Платежные реквизиты'}
              contentTypographyProps={{
                className: documentStyles.form__documentViewSpaceWrap,
              }}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectPaymentDetails`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.subjectOtherDetails`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Прочие реквизиты'}
              contentTypographyProps={{
                className: documentStyles.form__documentViewSpaceWrap,
              }}
              controllerProps={{
                ...getControllerProps({
                  name: `${formName}.subjectOtherDetails`,
                }),
              }}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <AuthorizedOfficialGroup
        groupTitle={'Уполномоченное должностное лицо'}
        formInstance={formInstance}
        formSection={formName}
        prefixFormFieldName={'subject'}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.subjectHeaderName`,
          })}
        >
          {({ overrideProps }) => {
            const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
              ? overrideProps.fetcherOptions
              : undefined

            return (
              <ControlledFormSingleSelect
                {...getSingleSelectProps({
                  fetcherProps,
                  optionsAdapter: (item) => ({
                    displayValue: item.headerName || '',
                    value: item.id,
                  }),
                  controllerProps: {
                    name: `${formName}.subjectHeaderName`,
                    rules: secondSectionValidationMap.subjectHeaderName,
                  },
                  selectProps: {
                    inputProps: {
                      label: 'Фамилия, Имя, Отчество',
                    },
                    onChangeFormValue: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.subjectHeaderName`)
                      }, 0),
                  },
                })}
              />
            )
          }}
        </SubscribableControl>
      </AuthorizedOfficialGroup>
    </Stack>
  )
}

export default SubjectForm
