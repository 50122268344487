import OrganizationsContainer from '@containers/Organizations'
import HeaderLayout from '@layouts/Header'

const Organizations = () => {
  return (
    <HeaderLayout>
      <OrganizationsContainer />
    </HeaderLayout>
  )
}

export default Organizations
