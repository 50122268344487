import React, { DetailedHTMLProps, FC, FormHTMLAttributes, memo, useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { useFormSubmitInStatement } from '@components/DocumentFormComponents/hooks/useFormSubmit'
import { useFlatMenuManager as useCreateStatementOldMenuManager } from '@components/DocumentFormComponents/Menu/Manager'
import { mapOFMenu } from '@components/Forms/CreateStatementOld/const'
import CreateStatementOldForms from '@components/Forms/CreateStatementOld/Forms'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import Button from '@components/NewDesign/Button'

import styles from './Statement.module.scss'

type CreateStatementForm = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

const mapOfStatementForms = {
  investorGeneralInfo: {
    node: <CreateStatementOldForms.InvestorGeneralInfo />,
  },
  investorAddress: {
    node: <CreateStatementOldForms.InvestorAddress />,
  },
  generalInfo: {
    node: <CreateStatementOldForms.GeneralInfo />,
  },
  stages: {
    node: <CreateStatementOldForms.StagesForm />,
  },
  compensations: {
    node: <CreateStatementOldForms.ExpensesCompensationArray />,
  },
  participantsAndInvestments: {
    node: <CreateStatementOldForms.Investments />,
  },
  plannedTaxes: {
    node: <CreateStatementOldForms.PlannedTaxesForm />,
  },
} as const

const CreateStatementForm: FC<CreateStatementForm> = ({ ...rest }) => {
  const {
    handlers: { withCheckChangesBeforeApply },
    state: { statementIsLoading, formInstance },
    actions: { handleCheckForm },
  } = useCreateStatementManager()
  const { handleChangeCurrentAnchorId } = useCreateStatementOldMenuManager()

  const compensations = useWatch({
    name: 'compensations',
    control: formInstance?.control,
  })

  const { handleSubmitForm, handleRejectModalVerify } = useFormSubmitInStatement()

  const calculatedMapOfStatementForms = useMemo(
    () =>
      ({
        ...mapOfStatementForms,
        compensations: {
          ...mapOfStatementForms.compensations,
          overrideBlockProps: {
            direction: !compensations?.length ? 'row' : 'column',
          },
        },
      } as FormItemProps),
    [compensations?.length],
  )

  if (!formInstance) return null

  const handleSubmit = () => {
    if (!handleCheckForm) return

    handleChangeCurrentAnchorId?.('')

    withCheckChangesBeforeApply?.({
      submitCallback: handleCheckForm,
      rejectCallback: handleRejectModalVerify,
    })
  }

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOFMenu}
      mapOfForms={calculatedMapOfStatementForms}
      formIsLoading={statementIsLoading}
      additionalContent={
        <Button
          type="submit"
          dataTestId="CreateStatementForm-preview-button"
          className={styles.statementForm__confirmButton}
        >
          Предварительный просмотр заявления
        </Button>
      }
      onSubmitForm={handleSubmitForm(handleSubmit)}
    />
  )
}

export default memo(CreateStatementForm)
