import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForNotificationOfChangeDocumentsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      ruleLinkStatementChanges: {
        propertyId: objectFromPropertyState.ruleLinkStatementChanges.propertyId,
        type: objectFromPropertyState.ruleLinkStatementChanges.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStatementChanges.lastUpdateDt,
      },
      characteristicOfDocuments: {
        propertyId: objectFromPropertyState.changesDocuments.propertyId,
        type: objectFromPropertyState.changesDocuments.type,
        lastUpdateDt: objectFromPropertyState.changesDocuments.lastUpdateDt,
        value:
          isArray(objectFromPropertyState.changesDocuments?.value) &&
          objectFromPropertyState.changesDocuments?.value.length
            ? objectFromPropertyState.changesDocuments?.value.map((item) => {
                return {
                  propertyId: item.propertyId,
                  type: item.type,
                  lastUpdateDt: item.lastUpdateDt,
                  value: {
                    changeDocumentCharacteristic: {
                      propertyId: item.value.changeDocumentCharacteristic?.propertyId,
                      type: item.value.changeDocumentCharacteristic?.type,
                      lastUpdateDt: item.value.changeDocumentCharacteristic?.lastUpdateDt,
                    },
                    changeDocumentName: {
                      propertyId: item.value.changeDocumentName?.propertyId,
                      type: item.value.changeDocumentName?.type,
                      lastUpdateDt: item.value.changeDocumentName?.lastUpdateDt,
                    },
                  },
                }
              })
            : [],
      },
    },
  }
}

export { generatePropertiesObjectForNotificationOfChangeDocumentsForm }
