import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import fileSlice from '@services/FileState/FileState.store'
import folderSlice from '@services/FolderState/FolderState.store'
import projectsPageStateSlice from '@services/ProjectsState/ProjectsState.store'
import technicalWorkSlice from '@services/TechnicalWork/TechnicalWork.store'
import thunk from 'redux-thunk'

const combinedReducer = combineReducers({
  folderState: folderSlice.reducer,
  fileState: fileSlice.reducer,
  technicalWork: technicalWorkSlice.reducer,
  projectsStatePage: projectsPageStateSlice.reducer,
})

const store = configureStore({
  reducer: combinedReducer,
  middleware: [thunk],
})

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
export type RootState = ReturnType<typeof store.getState>

type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<TypedDispatch<RootState>>()

export default store
