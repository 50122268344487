import { ButtonProps } from '@components/Button'
import { IconProps } from '@components/Icon'

export const getIconSizeByButtonSize = (size: ButtonProps['size']): IconProps['size'] => {
  switch (size) {
    case 'l':
    case 'm':
      return 'm'

    case 's':
    case 'xs':
      return 's'

    default:
      return 'm'
  }
}
