import { useAPIContext } from '@context/APIContext'
import { GetOrganizationVerifyReturnType } from '@context/APIContext/hooks/useOrganizationApi'
import { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useOrganizationVerify = ({ key, config }: SWRHookProps<GetOrganizationVerifyReturnType>) => {
  const {
    organizationApi: { getOrganizationVerify },
  } = useAPIContext()

  const {
    data: organizationVerify,
    error,
    mutate,
    isValidating: isLoadingOrganizationVerify,
  } = useSWRImmutable(key, getOrganizationVerify, config)

  return {
    organizationVerify,
    error,
    mutate,
    isLoadingOrganizationVerify,
  }
}

export { useOrganizationVerify }
