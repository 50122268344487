import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfNoticeOfRejectConcludeSZPKMenu } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/const'
import NoticeOfRejectConcludeSZPK from '@components/Forms/NoticeOfRejectConcludeSZPKForm/Forms'
import { useNoticeOfRejectConcludeSZPKManager } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/Manager'
import { NoticeOfRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/types'

const mapOfNoticeOfRejectConcludeSZPKForms: FormItemProps = {
  '1': {
    node: <NoticeOfRejectConcludeSZPK.First />,
  },
  '2': {
    node: <NoticeOfRejectConcludeSZPK.Second />,
  },
  '3': {
    node: <NoticeOfRejectConcludeSZPK.Third />,
  },
  '4': {
    node: <NoticeOfRejectConcludeSZPK.Fourth />,
  },
} as const

const NoticeOfRejectConcludeSZPKForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<NoticeOfRejectConcludeSZPKFormValues>()

  const {
    state: { formIsLoading },
  } = useNoticeOfRejectConcludeSZPKManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNoticeOfRejectConcludeSZPKMenu}
      mapOfForms={mapOfNoticeOfRejectConcludeSZPKForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NoticeOfRejectConcludeSZPKForm)
