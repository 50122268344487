import React from 'react'
import { useFormContext } from 'react-hook-form'

import SubjectsSection from '@components/DocumentFormComponents/FormSections/SubjectsSection'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useAAgreementForceCircumstancesManager } from '@components/Forms/AAgreementForceCircumstancesForm/Manager'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import {
  AAgreementForceCircumstancesFieldArrayControlUpdateWatcher,
  AAgreementForceCircumstancesFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementForceCircumstancesForm/watcher'

const { validationAdapter } = DocumentFormHelpers

const Two = () => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementForceCircumstancesManager()

  if (!formInstance) return null

  return (
    <SubjectsSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'2.subjects'}
      formInstance={formInstance}
      watcher={AAgreementForceCircumstancesFieldsControlUpdateWatcher}
      fieldArrayWatcher={AAgreementForceCircumstancesFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Two
