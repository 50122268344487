import React, { FC, ReactNode } from 'react'

import cn from 'classnames'

import styles from './WithCaption.module.scss'

interface WithCaptionProps {
  children: ReactNode
  className?: string
  captionClassName?: string
  caption?: string
}

const WithCaption: FC<WithCaptionProps> = ({ className, captionClassName, children, caption }) => {
  if (!caption) return <>{children}</>

  return (
    <div className={cn(styles.caption, className)}>
      {children}
      <span className={cn(styles.caption__text, captionClassName)}>{caption}</span>
    </div>
  )
}

export default WithCaption
