import { memo } from 'react'

import FormModifierProviderWrapper from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import {
  defaultAAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  defaultAAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import AAgreementOnChangingCharacteristicsOfObjectsLayout from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Layout'

const AAgreementOnChangingCharacteristicsOfObjectsLayoutWithFormProvider = (
  props: FormModalLayoutProps,
) => {
  return (
    <FormProviderWrapper
      defaultValues={defaultAAgreementOnChangingCharacteristicsOfObjectsFormValues}
    >
      <FormModifierProviderWrapper
        defaultValues={defaultAAgreementOnChangingCharacteristicsOfObjectsFormModifierValues}
      >
        <AAgreementOnChangingCharacteristicsOfObjectsLayout {...props} />
      </FormModifierProviderWrapper>
    </FormProviderWrapper>
  )
}

export default memo(AAgreementOnChangingCharacteristicsOfObjectsLayoutWithFormProvider)
