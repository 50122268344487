import React from 'react'

import { AvatarComponent } from '@components/NewDesign/Avatar/types'
import Typography from '@components/NewDesign/Typography'
import { isString } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './Avatar.module.scss'

const Avatar: AvatarComponent = ({
  size = 'm',
  variant = 'headlineH4',
  color = 'text-accent-brand',
  children,
  className,
  textClassName,
  ...props
}) => {
  const avatarClassNames = cn(styles.avatar, styles[`avatar--${size}`], className)

  return (
    <div {...props} className={avatarClassNames}>
      {!isString(children) ? (
        children
      ) : (
        <Typography
          variant={variant}
          color={color}
          className={cn(styles.avatar__text, textClassName)}
        >
          {children}
        </Typography>
      )}
    </div>
  )
}

Avatar.Large = ({ variant, size, children, ...props }) => (
  <Avatar variant={variant} size={size} {...props}>
    {children}
  </Avatar>
)

Avatar.Medium = ({ variant, size, children, ...props }) => (
  <Avatar variant={variant} size={size} {...props}>
    {children}
  </Avatar>
)

Avatar.Small = ({ variant, size, children, ...props }) => (
  <Avatar variant={variant} size={size} {...props}>
    {children}
  </Avatar>
)

export default Avatar
