import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NpaMunicipalFormValues } from '@components/Forms/NpaMunicipalForm/types'
import { isArray } from '@helpers/checkTypes'
import { INpaBody, NpaPartForBody } from '@services/NPA/NPA.entity'

const generateRHFObjectForNpaMunicipalForm = (
  objectFromPropertyState: Record<string, any>,
): NpaMunicipalFormValues => {
  const preparedChosenParts = ((): INpaBody | null => {
    if (
      !isArray(objectFromPropertyState.municipalNpas?.value) ||
      !objectFromPropertyState.municipalNpas?.value.length
    )
      return null

    const npaParts = objectFromPropertyState.municipalNpas.value.map(
      (npa): NpaPartForBody => ({
        id: npa.propertyId,
        justification: npa?.value?.municipalNpaJustification?.value,
        partCorrection: npa?.value?.municipalNpaCorrection?.value,
        npaId: npa?.value?.municipalNpaPartId?.value?.npaId,
        partId: npa?.value?.municipalNpaPartId?.value?.id,
      }),
    )

    return {
      npaParts,
    }
  })()

  return {
    headerInfo: {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      ...RHFBasicAdapters.getNewMunicipalityAdapter(objectFromPropertyState),
    },
    npa: {
      npaStructure: [],
      chosenParts: preparedChosenParts,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      municipalities: objectFromPropertyState.municipalities?.value,
    },
  }
}

export { generateRHFObjectForNpaMunicipalForm }
