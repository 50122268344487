import Typography from '@components/NewDesign/Typography'

import { TStep } from '../model'

import styles from './styles.module.scss'

export const Info: TStep = () => {
  return (
    <>
      <Typography className={styles.Paragraph} variant={'bodyMRegular'}>
        Если ваша организация прошла процесс реорганизации, вы можете получить права на ранее
        заключенные соглашения.
      </Typography>
      <Typography className={styles.Paragraph} variant={'bodyMRegular'}>
        Для этого укажите ИНН реорганизованной организации, форму реорганизации и СЗПК. <br /> После
        заполнения и подписания доп. соглашение будет рассмотрено.
      </Typography>
      <Typography className={styles.Paragraph} variant={'bodyMRegular'}>
        Если все корректно, вы сможете участвовать в выбранном СЗПК.
      </Typography>
    </>
  )
}
