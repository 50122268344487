import { useCallback } from 'react'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForInformationOfExecutionConditionsSZPKForm } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/adapters/Menu.adapter'
import { generatePropertiesObjectForInformationOfExecutionConditionsSZPKForm } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForInformationOfExecutionConditionsSZPKForm } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/adapters/RHF.adapter'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'

const useInformationOfExecutionConditionsSZPKAdapters = () => {
  const generateRHFObject = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): InformationOfExecutionConditionsSZPKFormValues =>
      generateRHFObjectForInformationOfExecutionConditionsSZPKForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForInformationOfExecutionConditionsSZPKForm(objectFromPropertyState),
    [],
  )

  const generateMenuListByFormValues = useCallback(
    (
      formValues:
        | InformationOfExecutionConditionsSZPKFormValues
        | UnpackNestedValue<InformationOfExecutionConditionsSZPKFormValues>,
      initialHashMap: NestedMenuHashMap,
      formErrors: FieldErrors<InformationOfExecutionConditionsSZPKFormValues>,
    ): NestedMapOfMenu[] =>
      generateMenuListForInformationOfExecutionConditionsSZPKForm(
        formValues,
        initialHashMap,
        formErrors,
      ),
    [],
  )

  return { generateRHFObject, generatePropertiesObject, generateMenuListByFormValues }
}

export { useInformationOfExecutionConditionsSZPKAdapters }
