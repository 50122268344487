import React, { FC, useLayoutEffect } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import DocumentDataView from '@components/DocumentFormComponents/DocumentDataView'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { infrFacilitiesCostsExpectedReimbursedBlockValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import { InfrastructureObjectsArrayPathName } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Forms/3/types'
import { useInfrFacilitiesCostsExpectedReimbursedManager } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Manager'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import RadioButton from '@components/Radio/Item'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isNotEmptyString, isString } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import chevronDownSmallIcon from '@icons/ChevronDownSmallIcon.svg'
import DayjsService from '@services/Dayjs/Dayjs.service'
import cn from 'classnames'

import styles from './InfractureObjectStep.module.scss'
import { infractureObjectStepValidationMap } from './validation'

const INFRASTRUCTURE_COMPENSATION_FORM_TO_IGNORE = [
  'Налоговый вычет',
  'Субсидия и (или) налоговый вычет',
]

//Обеспечивающая
const INFRASTRUCTURE_TYPE_FIFTY_PERCENT = '50%'
//Сопутствующая
const INFRASTRUCTURE_TYPE_ONE_HUNDRED_PERCENT = '100%'

const COMPENSATION_PERIOD_FIVE_YEARS = '5 лет'
const COMPENSATION_PERIOD_TEN_YEARS = '10 лет'

interface InfractureObjectStepProps {
  blockViewIsValidating: boolean
  formName: InfrastructureObjectsArrayPathName
  formInstance: UseFormReturn<InfrFacilitiesCostsExpectedReimbursedFormValues>
}

const InfractureObjectStep: FC<InfractureObjectStepProps> = ({
  blockViewIsValidating,
  formName,
  formInstance,
}) => {
  const {
    state: { editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInfrFacilitiesCostsExpectedReimbursedManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getHierarchyReferenceBookSelectProps,
    getInputProps,
    getTextareaProps,
    getSwitchProps,
    getCalendarInputProps,
    getRadioGroupProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher,
  })

  const {
    booleanState: isExpanded,
    setBooleanStateToTrue: expandForm,
    setBooleanStateToFalse: collapseForm,
  } = useBooleanState()
  const { booleanState: isValid, setBooleanStateToTrue: enableValid } = useBooleanState()

  const objectName = useWatch({
    name: `${formName}.objectName`,
    control: formInstance.control,
  })

  const infrastructureType = useWatch({
    name: `${formName}.infrastructureType`,
    control: formInstance.control,
  })

  const infrastructureOtherNameRenderCondition = !!useWatch({
    name: `${formName}.hasInfrastructureOtherName`,
    control: formInstance.control,
  })

  const compensationValue = useWatch({
    name: `${formName}.infrastructureCompensationValue`,
    control: formInstance.control,
  })

  const compensationPercent = useWatch({
    name: `${formName}.infrastructureCompensationPercent`,
    control: formInstance.control,
  })

  const isFederal = useWatch({
    name: infrFacilitiesCostsExpectedReimbursedBlockValues.additionalFields.isFederal,
    control: formInstance?.control,
  })

  const isRelatedInfrastructure = infrastructureType === INFRASTRUCTURE_TYPE_ONE_HUNDRED_PERCENT

  const isExpandedForm = isExpanded
  const isNotFilledForm = !isExpanded && !isValid
  const isCollapsedForm = !isExpanded && isValid

  const isNotEmptyCompensationValueToRender =
    isNotEmptyString(compensationValue) && isNotEmptyString(compensationPercent)

  const plannedCostText = isNotEmptyCompensationValueToRender
    ? `${
        isString(compensationPercent) ? `${Number(compensationPercent) * 100}%` : ''
      } · ${compensationValue} ₽`
    : 'Будет рассчитан'

  const plannedCostCollapseText = isNotEmptyCompensationValueToRender
    ? `${Number(compensationPercent) * 100}% · ${compensationValue} ₽`
    : ''

  const handleClickSave = () => {
    formInstance.trigger(formName)

    setTimeout(() => {
      const { invalid } = formInstance.getFieldState(formName)

      if (invalid) return

      collapseForm()
      enableValid()
    }, 0)
  }

  // Проверка на валидность
  useLayoutEffect(() => {
    formInstance.trigger(formName)

    setTimeout(() => {
      const { invalid } = formInstance.getFieldState(formName)

      if (!invalid) return enableValid()

      formInstance.clearErrors(formName)
    }, 0)
  }, [])

  return (
    <div
      className={cn(styles.infractureObjectStep, {
        [styles['infractureObjectStep--save']]: isExpandedForm,
        [styles['infractureObjectStep--active']]: !isNotFilledForm || !editMode,
      })}
    >
      {isNotFilledForm && editMode && (
        <Row>
          <Typography.Body variant={'bodyLMedium'}>{objectName}</Typography.Body>
        </Row>
      )}
      {(isCollapsedForm || (!editMode && !isExpanded)) && (
        <Stack direction={'vertical'} gap={3}>
          <Row className={'px-1'}>
            <Col xs={12} className={'px-3'}>
              <Typography.Body variant={'bodyLMedium'}>{objectName}</Typography.Body>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.infrastructureCost`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Планируемая стоимость'}
                  controllerProps={getControllerProps({
                    name: `${formName}.infrastructureCost`,
                  })}
                />
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <DocumentDataView suptitle={'Планируемый объем возмещения затрат'}>
                {plannedCostCollapseText}
              </DocumentDataView>
            </Col>
          </Row>
        </Stack>
      )}
      <CollapseWrapper isExpanded={isExpanded}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectName`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={'Наименование объекта в проекте СЗПК'}
                  controllerProps={getControllerProps({
                    name: `${formName}.objectName`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.hasInfrastructureOtherName`,
                })}
              >
                <ControlledSwitch
                  {...getSwitchProps({
                    name: `${formName}.hasInfrastructureOtherName`,
                    switchProps: {
                      label: 'Указать иное наименование',
                      wrapperClassName: cn(styles.form__switch, styles.form__item),
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <CollapseWrapper isExpanded={infrastructureOtherNameRenderCondition}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.infrastructureOtherName`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.infrastructureOtherName`,
                      rules: infractureObjectStepValidationMap.infrastructureOtherName,
                      textareaProps: {
                        label: 'Наименование объекта',
                        caption:
                          'Наименование объекта будет отличаться в  тексте документов "Проект СЗПК" и "Перечень объектов инфраструктуры, затраты на которые предполагается возместить" ',
                        captionClassName: styles.infractureObjectStep__inputCaption,
                      },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.infrastructureOtherName`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.infrastructureOtherName`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </CollapseWrapper>
          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                title={'Номер из перечня объектов инфраструктуры'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.infrastructureKind`,
                  })}
                >
                  <ControlledHierarchyReferenceBookSelect
                    {...getHierarchyReferenceBookSelectProps({
                      controllerProps: {
                        name: `${formName}.infrastructureKind`,
                        rules: infractureObjectStepValidationMap.infrastructureKind,
                      },
                      dictionaryName: 'infrastructure_objects',
                      customDisplayValueFC: ({ code }) => code || '',
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.infrastructureKind`), 0),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.infrastructureName`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.infrastructureName`,
                    inputProps: {
                      label: 'Наименование объекта инфраструктуры, соответствующее номеру',
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                title={'Год ввода объекта в эксплуатацию'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.infrastructureCommissioningYear`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${formName}.infrastructureCommissioningYear`,
                      rules: infractureObjectStepValidationMap.infrastructureCommissioningYear,
                      calendarInputProps: { label: 'Укажите год', inputMask: YEAR_MASK },
                      calendarProps: { typeOfPicker: 'years', dateFormat: 'YYYY' },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.infrastructureCommissioningYear`),
                          0,
                        ),
                      onInputChange: () =>
                        setTimeout(
                          () =>
                            debouncedHandleChangeValue?.(
                              `${formName}.infrastructureCommissioningYear`,
                            ),
                          0,
                        ),
                      onCalendarChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.infrastructureCommissioningYear`),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.infrastructureCharacteristicsDescription`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.infrastructureCharacteristicsDescription`,
                    rules:
                      infractureObjectStepValidationMap.infrastructureCharacteristicsDescription,
                    textareaProps: {
                      label: 'Проектные характеристики',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.infrastructureCharacteristicsDescription`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          `${formName}.infrastructureCharacteristicsDescription`,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.infrastructureFunctional`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.infrastructureFunctional`,
                    rules: infractureObjectStepValidationMap.infrastructureFunctional,
                    textareaProps: {
                      label: 'Описание функционального предназначения',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.infrastructureFunctional`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.infrastructureFunctional`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Планируемая стоимость'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.infrastructureCost`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${formName}.infrastructureCost`,
                      rules: infractureObjectStepValidationMap.infrastructureCost,
                      inputProps: {
                        label: 'без НДС',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.infrastructureCost`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.infrastructureCost`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <Stack direction={'vertical'} gap={2}>
                    <Row className={cn(styles.form__item)}>
                      <Stack direction={'horizontal'} gap={1}>
                        <FormIconWithTooltip
                          tooltipContent={
                            'Тип инфраструктуры для объекта указывается в форме "Проект СЗПК"'
                          }
                        />
                        <Typography.Body
                          className={styles.form__typography}
                          as={'span'}
                          variant={'bodyMMedium'}
                          color={'text-base-secondary'}
                        >
                          Объект относится к
                        </Typography.Body>
                      </Stack>
                    </Row>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${formName}.infrastructureType`,
                      })}
                    >
                      <ControlledRadioGroup
                        {...getRadioGroupProps({
                          name: `${formName}.infrastructureType`,
                          radioGroupProps: {
                            disabled: true,
                            className: styles.form__radioButtonGroup,
                            direction: 'horizontal',
                          },
                          onChange: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${formName}.infrastructureType`)
                            }, 0),
                        })}
                      >
                        <Col xs={12}>
                          <Row>
                            <Col xs={6}>
                              <RadioButton
                                align="center"
                                variant="buttonMMedium"
                                value={INFRASTRUCTURE_TYPE_FIFTY_PERCENT}
                                label="Обеспечивающей инфраструктуре"
                                className={styles['infractureObjectStep__radioButton']}
                              />
                            </Col>
                            <Col xs={6}>
                              <RadioButton
                                align="center"
                                variant="buttonMMedium"
                                value={INFRASTRUCTURE_TYPE_ONE_HUNDRED_PERCENT}
                                label="Сопутствующей инфраструктуре"
                                className={styles['infractureObjectStep__radioButton']}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </ControlledRadioGroup>
                    </SubscribableControl>
                  </Stack>
                </Col>
              </Row>
            </Col>
          </Row>
          <CollapseWrapper
            defaultExpanded={isRelatedInfrastructure}
            isExpanded={isRelatedInfrastructure}
          >
            <Stack direction={'vertical'} gap={4}>
              <Row>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.infrastructureReason`,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: `${formName}.infrastructureReason`,
                        rules: infractureObjectStepValidationMap.infrastructureReason,

                        textareaProps: {
                          label: 'Основание отнесения объекта к сопутствующей инфраструктуре',
                        },
                        onBlur: () =>
                          setTimeout(
                            () => handleChangeValue?.(`${formName}.infrastructureReason`),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.infrastructureReason`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.infrastructureBalanceHolder`,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: `${formName}.infrastructureBalanceHolder`,
                        rules: infractureObjectStepValidationMap.infrastructureBalanceHolder,

                        textareaProps: {
                          label: 'Балансодержатель',
                        },
                        onBlur: () =>
                          setTimeout(
                            () => handleChangeValue?.(`${formName}.infrastructureBalanceHolder`),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${formName}.infrastructureBalanceHolder`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            </Stack>
          </CollapseWrapper>

          <Row className={'mt-2'}>
            <Col xs={12}>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                firstColumnSize={7}
                secondColumnSize={5}
                disableRightPadding={false}
                contentClassName={styles.form__content}
                titleWrapperClassName={styles.form__title}
                title={'Планируемый объем возмещения затрат'}
              >
                <Typography.Body align={'right'} variant={'bodyMMedium'}>
                  {plannedCostText}
                </Typography.Body>
              </RowWithBorder>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableTitleLeftPadding
                disableBottomDefaultStyles
                title={'Планируемая дата начала возмещения затрат'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.infrastructureCompensationStartDate`,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${formName}.infrastructureCompensationStartDate`,
                      rules: infractureObjectStepValidationMap.infrastructureCompensationStartDate,
                      calendarProps: {
                        disabledDatePeriod: 'end',
                        disabledDate: (date) =>
                          DayjsService.dayjsWithFormatToMSK().set('hour', 0) > date.set('hour', 23),
                      },
                      calendarInputProps: { label: 'Выберите дату' },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(`${formName}.infrastructureCompensationStartDate`),
                          0,
                        ),
                      onInputChange: () =>
                        setTimeout(
                          () =>
                            debouncedHandleChangeValue?.(
                              `${formName}.infrastructureCompensationStartDate`,
                            ),
                          0,
                        ),
                      onCalendarChange: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(`${formName}.infrastructureCompensationStartDate`),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Stack direction={'vertical'} gap={2}>
                <Row className={styles.form__item}>
                  <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
                    Планируемый период возмещения затрат
                  </Typography.Body>
                </Row>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.infrastructureCompensationPeriod`,
                  })}
                >
                  <ControlledRadioGroup
                    {...getRadioGroupProps({
                      name: `${formName}.infrastructureCompensationPeriod`,
                      rules: infractureObjectStepValidationMap.infrastructureCompensationPeriod,
                      radioGroupProps: {
                        className: styles.form__radioButtonGroup,
                        direction: 'horizontal',
                        disabled: true,
                      },
                      onChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${formName}.infrastructureCompensationPeriod`),
                          0,
                        ),
                    })}
                  >
                    <Col xs={12}>
                      <Row>
                        <Col xs={4}>
                          <RadioButton
                            align="center"
                            variant="buttonMMedium"
                            value={COMPENSATION_PERIOD_FIVE_YEARS}
                            label={'В течение 5 лет'}
                            className={styles['infractureObjectStep__radioButton']}
                          />
                        </Col>
                        <Col xs={4}>
                          <RadioButton
                            align="center"
                            variant="buttonMMedium"
                            value={COMPENSATION_PERIOD_TEN_YEARS}
                            label={'В течение 10 лет'}
                            className={styles['infractureObjectStep__radioButton']}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </ControlledRadioGroup>
                </SubscribableControl>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.infrastructureCompensationForm`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => {
                          if (
                            !isFederal &&
                            INFRASTRUCTURE_COMPENSATION_FORM_TO_IGNORE.some(
                              (value) => value == item.name,
                            )
                          )
                            return null

                          return {
                            displayValue: item.name || '',
                            value: item.id,
                          }
                        },
                        controllerProps: {
                          name: `${formName}.infrastructureCompensationForm`,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Планируемая форма возмещения затрат',
                          },
                          onChangeFormValue: () =>
                            setTimeout(
                              () =>
                                handleChangeValue?.(`${formName}.infrastructureCompensationForm`),
                              0,
                            ),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.documentInfrastructure`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.documentInfrastructure`,
                    rules: infractureObjectStepValidationMap.documentInfrastructure,
                    textareaProps: {
                      label: 'Реквизиты документов, подтверждающих технологическое присоединение',
                      caption: 'укажите при наличии',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.documentInfrastructure`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.documentInfrastructure`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.infrastructureNote`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.infrastructureNote`,
                    rules: infractureObjectStepValidationMap.infrastructureNote,
                    textareaProps: {
                      label: 'Примечание',
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.infrastructureNote`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.infrastructureNote`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </CollapseWrapper>
      <Row
        className={cn({
          [styles['infractureObjectStep__actionWrapper--save']]: isExpandedForm,
        })}
      >
        <Col className={'flex'} xs={4}>
          {isExpandedForm && (
            <Button
              view={'tined'}
              className={cn(
                styles.infractureObjectStep__action,
                styles['infractureObjectStep__actionSave'],
              )}
              onClick={editMode ? handleClickSave : collapseForm}
            >
              Свернуть
            </Button>
          )}
          {isNotFilledForm && editMode && (
            <Button
              className={styles.infractureObjectStep__action}
              view={'tined'}
              onClick={expandForm}
            >
              Заполнить форму
            </Button>
          )}
          {(isCollapsedForm || (!editMode && !isExpanded)) && (
            <Button
              variant={'buttonSMedium'}
              size={'xs'}
              textClassName={styles['infractureObjectStep__actionFilled-text']}
              view={'text'}
              className={styles.infractureObjectStep__action}
              leadingIcon={{
                src: chevronDownSmallIcon,
                size: 'xs',
              }}
              onClick={expandForm}
            >
              Развернуть
            </Button>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default InfractureObjectStep
