import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { IRidStage, ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const ridExploitationProjectSZPKValidationMap: FormValuesValidationSection<
  keyof IRidStage['exploitation'],
  ProjectSZPKFormValues
> = {
  ridCommissioningEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { ridExploitationProjectSZPKValidationMap }
