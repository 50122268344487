import React, { FC, memo, useMemo } from 'react'

import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import {
  ITypography,
  ITypographyBody,
  ITypographyCaption,
} from '@components/NewDesign/Typography/types'
import Typography from '@components/NewDesign/Typography/Typography'
import cn from 'classnames'

import { Tooltip, TooltipProps } from '../Tooltip'

import styles from './TextWithTooltip.module.scss'

type TextWithTooltip = ITypographyBody & {
  value: string
  limit: number
  tooltipProps?: Omit<TooltipProps, 'content' | 'children'>
  tooltipTypographyProps?: ITypography
}

const initialTooltipTypographyProps: ITypographyCaption = {
  variant: 'captionMMedium',
}

const TextWithTooltip: FC<TextWithTooltip> = ({
  value,
  limit,
  variant,
  tooltipProps,
  tooltipTypographyProps = initialTooltipTypographyProps,
  className,
  ...props
}) => {
  const {
    position = 'bottom',
    contentClassName,
    targetClassName,
    ...restTooltipProps
  } = tooltipProps || {}

  const biggerLimit = useMemo(() => value && value.length > limit, [value, limit])

  const {
    state: { tooltipIsOpen },
    handlers: { handleCloseTooltip, handleOpenTooltip },
  } = useManualTooltipControl()

  const tooltipContent = useMemo(
    () => <Typography {...tooltipTypographyProps}>{value}</Typography>,
    [value, tooltipTypographyProps],
  )

  const handleMouseEnter = () => {
    if (!biggerLimit) return

    handleOpenTooltip()
  }

  const handleMouseLeave = () => {
    if (!biggerLimit) return

    handleCloseTooltip()
  }

  return (
    <Tooltip
      open={tooltipIsOpen}
      content={tooltipContent}
      position={position}
      targetClassName={cn(styles.tooltip__target, targetClassName)}
      contentClassName={cn(styles.tooltip__content, contentClassName)}
      {...restTooltipProps}
    >
      <Typography.Body
        variant={variant}
        className={cn(styles.text, className)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      >
        {value}
      </Typography.Body>
    </Tooltip>
  )
}

export default memo(TextWithTooltip)
