import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fourthSectionAAgreementOnCreationOtherEstateObjectsValidationMap: FormValuesValidationSection<
  keyof AAgreementOnCreationOtherEstateObjectsFormValues['4'],
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { fourthSectionAAgreementOnCreationOtherEstateObjectsValidationMap }
