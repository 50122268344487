import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  RegistrationCertificateExtraRegisteredDocumentValue,
  RegistrationCertificateFormValues,
  RegistrationCertificateGeneralStepRegisteredDateValue,
  RegistrationCertificateMunicipality,
  RegistrationCertificatePlannedTax,
  RegistrationCertificateRelatedContract,
  RegistrationCertificateRelatedContractListRegisteredDocumentValue,
  RegistrationCertificateSubject,
} from '@components/Forms/RegistrationCertificateForm/types'
import { objOfDateFormats } from '@constants/dateFormats'
import { isArray } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

const { getFormattedValue, fromYearBackendToYEARCalendar, formatBackendMoneyToString } =
  DocumentFormHelpers

const generateRHFObjectForRegistrationCertificateForm = (
  objectFromPropertyState: Record<string, any>,
): RegistrationCertificateFormValues => {
  const preparedMunicipalities: RegistrationCertificateMunicipality[] =
    RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState)
      .filter((municipality) => !!municipality?.id)
      .map((municipality) => ({
        id: municipality?.id,
        municipalityFullName: municipality?.municipalityFullName,
        municipalityHeaderName: municipality?.municipalityHeaderName,
      }))

  const preparedSubjects: RegistrationCertificateSubject[] = RHFBasicAdapters.getSubjectsAdapter(
    objectFromPropertyState,
  ).map((subject) => ({
    id: subject?.id,
    subjectRegion: subject?.subjectRegion,
    subjectFullName: subject?.subjectFullName,
  }))

  const preparedPlannedTaxes = ((): RegistrationCertificatePlannedTax[] => {
    if (
      !isArray(objectFromPropertyState.plannedTax?.value) ||
      !objectFromPropertyState.plannedTax?.value?.length
    )
      return []

    return objectFromPropertyState.plannedTax.value
      .sort((taxA, taxB) => taxA.order - taxB.order)
      .map(
        (tax): RegistrationCertificatePlannedTax => ({
          id: tax.propertyId,
          taxYear: fromYearBackendToYEARCalendar(tax.value?.taxYear?.value || '') || '',
          taxYearValue: getFormattedValue({
            value: tax.value?.taxYearValue?.value || '',
            currency: 'RUR',
          }).formatted,
        }),
      )
  })()

  const preparedGeneralStepRegisteredDateValues: RegistrationCertificateGeneralStepRegisteredDateValue[] =
    (() => {
      if (
        !isArray(objectFromPropertyState.stepRegisteredDateValues?.value) ||
        !objectFromPropertyState.stepRegisteredDateValues?.value?.length
      )
        return []

      return objectFromPropertyState.stepRegisteredDateValues?.value.map(
        (step): RegistrationCertificateGeneralStepRegisteredDateValue => ({
          id: step.propertyId,
          tabName: `${step.value?.stepId?.value.stepNumber} ${step.value?.stepId?.value.stepDescription}`,
          stepStartDate: isDateValidForDayjs(step.value?.stepId?.value?.stepStartDate)
            ? dayjs(step.value?.stepId?.value?.stepStartDate).format(objOfDateFormats.defaultFormat)
            : '',
          stepEndDate: isDateValidForDayjs(step.value?.stepId?.value?.stepEndDate)
            ? dayjs(step.value?.stepId?.value?.stepEndDate).format(objOfDateFormats.defaultFormat)
            : '',
          stepInvestorCapitalValue: getFormattedValue({
            value:
              formatBackendMoneyToString(step.value?.stepId?.value?.stepInvestorCapitalValue) || '',
            currency: 'RUR',
          }).formattedWithCurrency,
          factStepInvestorCapital: step.value?.factStepInvestorCapital?.value || '',
          stepRemainsCapital: step.value?.stepRemainsCapital?.value || '',
        }),
      )
    })()

  const preparedExtraRegisteredDocumentValues: RegistrationCertificateExtraRegisteredDocumentValue[] =
    (() => {
      if (
        !isArray(objectFromPropertyState.extraRegisteredDocumentValues?.value) ||
        !objectFromPropertyState.extraRegisteredDocumentValues?.value?.length
      )
        return []

      return objectFromPropertyState.extraRegisteredDocumentValues?.value.map(
        (extraRegisteredDocument): RegistrationCertificateExtraRegisteredDocumentValue => {
          return {
            id: extraRegisteredDocument?.propertyId,
            extraTypeName: extraRegisteredDocument?.value?.extraTypeName?.value || '',
            extraRegistrationDate: `Зарегистрировано ${extraRegisteredDocument?.value?.extraRegistrationDate?.value}, № ${extraRegisteredDocument?.value?.extraNumber?.value}`,
            extraDescription: extraRegisteredDocument?.value?.extraDescription?.value || '',
            extraLastSignDate: extraRegisteredDocument?.value?.extraLastSignDate?.value || '',
          }
        },
      )
    })()

  const preparedRelatedContractListRegisteredDocumentValues =
    ((): RegistrationCertificateRelatedContractListRegisteredDocumentValue[] => {
      if (
        !isArray(objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value) ||
        !objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value?.length
      )
        return []

      return objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value.map(
        (documentValue): RegistrationCertificateRelatedContractListRegisteredDocumentValue => ({
          id: documentValue?.propertyId,
          relatedContractNumberRegisteredDocument:
            documentValue?.value?.relatedContractNumberRegisteredDocument?.value,
          relatedContractDateRegisteredDocument:
            documentValue?.value?.relatedContractDateRegisteredDocument?.value,
          relatedContractDescriptionRegisteredDocument:
            documentValue?.value?.relatedContractDescriptionRegisteredDocument?.value,
          relatedContractOrganizationRegisteredDocument:
            documentValue?.value?.relatedContractOrganizationRegisteredDocument?.value,
        }),
      )
    })()

  const preparedRelatedContracts: RegistrationCertificateRelatedContract[] = (() => {
    if (
      !isArray(objectFromPropertyState.relatedContracts?.value) ||
      !objectFromPropertyState.relatedContracts?.value?.length
    )
      return []

    return objectFromPropertyState.relatedContracts?.value.map(
      (relatedContract): RegistrationCertificateRelatedContract => {
        return {
          id: relatedContract?.propertyId,
          relatedContractNumber: relatedContract?.value?.relatedContractNumber?.value || '',
          relatedContractDate: relatedContract?.value?.relatedContractDate?.value || '',
          relatedContractPublicLawMember:
            relatedContract?.value?.relatedContractPublicLawMember?.value?.memberOrganizationId
              ?.memberFullName || '',
          relatedContractKind: relatedContract?.value?.relatedContractKind?.value,
          relatedContractTerms: relatedContract?.value?.relatedContractTerms?.value,
          relatedContractTermAndKind: `Вид: ${relatedContract?.value?.relatedContractKind?.value}, условия: ${relatedContract?.value?.relatedContractTerms?.value}`,
          relatedContractRegistrationDocument:
            relatedContract?.value?.relatedContractRegistrationDocument?.value,
        }
      },
    )
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      isProjectCompany: objectFromPropertyState.investor?.value.isProjectCompany?.value || false,
      investorAddressFullEgrulFormat:
        objectFromPropertyState.investor?.value.memberOrganizationId?.value
          .addressFullEgrulFormat || '',
      investorEmail:
        objectFromPropertyState.investor?.value.memberOrganizationId?.value.memberEmail || '',
      investorPhone:
        objectFromPropertyState.investor?.value.memberOrganizationId?.value.memberPhone || '',
    },
    '4': {
      rfFullName:
        objectFromPropertyState.rf?.value.memberOrganizationId?.value?.memberFullName || '',
      municipalities: preparedMunicipalities,
      subjects: preparedSubjects,
    },
    '5': {
      projectName: objectFromPropertyState.projectName?.value || '',
      szpkSignDate: objectFromPropertyState.szpkSignDate?.value || '',
      szpkRegistrationDate: objectFromPropertyState?.szpkRegistrationDate?.value
        ? `от ${objectFromPropertyState?.szpkRegistrationDate?.value}`
        : '',
      szpkNumber: objectFromPropertyState?.szpkNumber?.value
        ? `№ ${objectFromPropertyState?.szpkNumber?.value}`
        : '',
      investorCapital: getFormattedValue({
        value: objectFromPropertyState.investorCapital?.value || '',
        currency: 'RUR',
      }).formattedWithCurrency,
      factMadeInvestorCapital: !!objectFromPropertyState.factMadeInvestorCapital?.value
        ? getFormattedValue({
            value: objectFromPropertyState.factMadeInvestorCapital.value || '',
            currency: 'RUR',
          }).formattedWithCurrency
        : getFormattedValue({
            value: objectFromPropertyState.madeInvestorCapital?.value || '',
            currency: 'RUR',
          }).formattedWithCurrency,
      factRemainsInvestorCapital: !!objectFromPropertyState.factMadeInvestorCapital?.value
        ? getFormattedValue({
            value: objectFromPropertyState.factRemainsInvestorCapital?.value || '',
            currency: 'RUR',
          }).formattedWithCurrency
        : getFormattedValue({
            value: objectFromPropertyState.remainsInvestorCapital?.value || '',
            currency: 'RUR',
          }).formattedWithCurrency,
      stabilizationPeriod: objectFromPropertyState.stabilizationPeriod?.value || '',
      plannedTax: preparedPlannedTaxes,
      generalPlannedTax: getFormattedValue({
        value: objectFromPropertyState.generalPlannedTax?.value || '',
        currency: 'RUR',
      }).formatted,
      stepRegisteredDateValues: preparedGeneralStepRegisteredDateValues,
    },
    '6': {
      extraRegisteredDocuments: preparedExtraRegisteredDocumentValues,
    },
    '7': {
      relatedContractListRegisteredDocumentValues:
        preparedRelatedContractListRegisteredDocumentValues,
    },
    '8': {
      relatedContracts: preparedRelatedContracts,
    },
    '9': {
      infoMonitoringReport: objectFromPropertyState.infoMonitoringReport?.value || '',
      infoMonitoringResult: objectFromPropertyState.infoMonitoringResult?.value || '',
    },
    '10': {
      infoBudgetRF: objectFromPropertyState.infoBudgetRF?.value || '',
      infoDamageFormatCompensation:
        objectFromPropertyState.infoDamageFormatCompensation?.value || '',
      infoViolationStabilizationPeriod:
        objectFromPropertyState.infoViolationStabilizationPeriod?.value || '',
      infoPartner: objectFromPropertyState.infoPartner?.value || '',
      infoDeposit: objectFromPropertyState.infoDeposit?.value || '',
      infoCession: objectFromPropertyState.infoCession?.value || '',
    },
    '11': {
      agreementTerminationReason: !!objectFromPropertyState?.agreementTerminationReason?.value?.id
        ? {
            value: objectFromPropertyState?.agreementTerminationReason?.value?.id || '',
            displayValue: objectFromPropertyState?.agreementTerminationReason?.value?.name || '',
          }
        : null,
      agreementTerminationReasonDescription:
        objectFromPropertyState.agreementTerminationReasonDescription?.value || '',
      agreementTerminationReasonInvalidPart:
        objectFromPropertyState.agreementTerminationReasonInvalidPart?.value || '',
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
    },
  }
}

export { generateRHFObjectForRegistrationCertificateForm }
