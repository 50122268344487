import { useCallback } from 'react'

import { generatePropertiesObjectForNpaMunicipalForm } from '@components/Forms/NpaMunicipalForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNpaMunicipalForm } from '@components/Forms/NpaMunicipalForm/adapters/RHF.adapter'
import { NpaMunicipalFormValues } from '@components/Forms/NpaMunicipalForm/types'

const useNpaMunicipalFormAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): NpaMunicipalFormValues =>
      generateRHFObjectForNpaMunicipalForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForNpaMunicipalForm(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generatePropertiesObject }
}

export { useNpaMunicipalFormAdapters }
