import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOnMonitoringResultsFormValues,
  CertificateOnMonitoringResultsStateSupportChecker,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { lengthValidate, standardAmountValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const stateSupportCheckerCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsStateSupportChecker,
  CertificateOnMonitoringResultsFormValues
> = {
  stateSupportNameChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  stateSupportReasonChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  stateSupportValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { stateSupportCheckerCertificateOnMonitoringResultsValidationMap }
