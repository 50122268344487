import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'

import { NoticeOfProjectRejectConcludeSZPKFormValues } from './types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfNoticeOfProjectRejectConcludeSZPKMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Организация, реализующая проект' },
  { id: '3', title: 'Основания отказа в заключении' },
]

const defaultNoticeOfProjectRejectConcludeSZPKFormValues: NoticeOfProjectRejectConcludeSZPKFormValues =
  {
    '1': {
      documentReceiversDraft: '',
    },
    '2': {
      ...getDefaultInvestorFieldState(),
      investorFullNameGenitive: '',
      investorFullNameInstrumental: '',
      investorInn: '',
      investorOgrn: '',
      investorAddress: '',
    },
    '3': {
      projectName: '',
      ruleLinkStatementRejectDraft: '',
      ruleLinkStatementRepeatDraft: '',
      statementRejectReasonDraft: '',
    },
  }

const noticeOfProjectRejectConcludeSZPKBlockValues = {
  '1': {
    documentReceiversDraft: '1.documentReceiversDraft',
  },
  '2': {
    investorFullName: '2.investorFullName',
    investorFullNameGenitive: '2.investorFullNameGenitive',
    investorFullNameInstrumental: '2.investorFullNameInstrumental',
    investorInn: '2.investorInn',
    investorOgrn: '2.investorOgrn',
    investorAddress: '2.investorAddress',
    investorHeaderName: '2.investorHeaderName',
    investorHeaderNameGenitive: '2.investorHeaderNameGenitive',
    investorHeaderPosition: '2.investorHeaderPosition',
    investorHeaderPositionGenitive: '2.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '2.investorHeaderReasonGenitive',
  },
  '3': {
    projectName: '3.projectName',
    ruleLinkStatementRejectDraft: '3.ruleLinkStatementRejectDraft',
    ruleLinkStatementRepeatDraft: '3.ruleLinkStatementRepeatDraft',
    statementRejectReasonDraft: '3.statementRejectReasonDraft',
  },
} as const

export {
  defaultNoticeOfProjectRejectConcludeSZPKFormValues,
  mapOfNoticeOfProjectRejectConcludeSZPKMenu,
  noticeOfProjectRejectConcludeSZPKBlockValues,
}
