import { ReactNode } from 'react'
import { DefaultValues, FieldValues, FormProvider } from 'react-hook-form'

import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'

interface FormProviderWrapperProps<T> {
  defaultValues: DefaultValues<T>
  children: ReactNode
}

const FormProviderWrapper = <T extends FieldValues>({
  defaultValues,
  children,
}: FormProviderWrapperProps<T>) => {
  const formInstance = useFormWithDefaultProps<T>({
    defaultValues,
  })

  return <FormProvider {...formInstance}>{children}</FormProvider>
}

export default FormProviderWrapper
