import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { ObjectRegion, ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const objectRegionsValidationMap: FormValuesValidationSection<
  keyof ObjectRegion,
  ProjectSZPKFormValues
> = {
  objectRegion: {
    required: defaultRHFValidation.required,
  },
}

export { objectRegionsValidationMap }
