import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useInitialCollapseStateList } from '@components/DocumentFormComponents/hooks/useInitialCollapseStateList'
import { useTransformToListIds } from '@components/DocumentFormComponents/hooks/useTransformToListIds'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import { CompensationsPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/7/types'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { Flipper } from '@components/ReactFlipToolkit'
import { isUndefined } from '@helpers/checkTypes'

import CompensationItem from './AdditionalForms/CompensationItem'

const Seventh = () => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  const { fields: compensationsMonitoring } = useFieldArray<
    InformationOfExecutionConditionsSZPKFormValues,
    CompensationsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: informationOfExecutionConditionsSZPKBlockValues['7'].compensationsMonitoring,
    keyName: 'keyNameId',
  })
  const { listIds: compensationsMonitoringIds } =
    useTransformToListIds<'id'>(compensationsMonitoring)

  const { initialCollapseState } = useInitialCollapseStateList(compensationsMonitoring)

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: informationOfExecutionConditionsSZPKBlockValues['7'].compensationsMonitoring,
            })}
          >
            <Flipper flipKey={compensationsMonitoringIds.join('')}>
              <Stack direction={'vertical'} gap={1}>
                {compensationsMonitoring.map((field, index) => {
                  const formName =
                    `${informationOfExecutionConditionsSZPKBlockValues['7'].compensationsMonitoring}.${index}` as const

                  const currentInitState = !isUndefined(initialCollapseState[field.id])
                    ? initialCollapseState[field.id]
                    : true

                  return (
                    <SubscribableControl
                      key={field.id}
                      {...getSubscribableControlProps({
                        path: formName,
                      })}
                    >
                      <CompensationItem
                        compensationId={field.id}
                        formInstance={formInstance}
                        formName={formName}
                        initialAccordionState={currentInitState}
                      />
                    </SubscribableControl>
                  )
                })}
              </Stack>
            </Flipper>
          </SubscribableControl>
        </Col>
      </Row>
    </Container>
  )
}

export default Seventh
