import { Path } from 'react-hook-form'

import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { objOfDateFormats } from '@constants/dateFormats'
import {
  bigIntIsValidate,
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isNumber, isObject, isString } from '@helpers/checkTypes'
import DayjsService from '@services/Dayjs/Dayjs.service'

type InfractureObjectStepKey =
  keyof InfrFacilitiesCostsExpectedReimbursedFormValues['3']['infrastructureObjects'][number]

const infractureObjectStepValidationMap: FormValuesValidationSection<
  InfractureObjectStepKey,
  InfrFacilitiesCostsExpectedReimbursedFormValues
> = {
  infrastructureCommissioningYear: {
    required: defaultRHFValidation.required,
  },
  infrastructureCharacteristicsDescription: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 2000),
  },
  infrastructureFunctional: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  infrastructureCost: {
    required: defaultRHFValidation.required,
    validate: {
      bigIntValidate: (value) => (isString(value) || isNumber(value)) && bigIntIsValidate(value),
      amountValidate: (value) => {
        if (!isString(value)) return

        return standardAmountValidate(value)
      },
    },
  },
  infrastructureBalanceHolder: {
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  infrastructureOtherName: {
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  infrastructureReason: {
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  documentInfrastructure: {
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  infrastructureNote: {
    validate: (value) => isString(value) && lengthValidate(value, 1500),
  },
  infrastructureCompensationStartDate: {
    required: defaultRHFValidation.required,
    validate: {
      positive: (date) => {
        if (!isString(date)) return true

        return (
          DayjsService.dayjsWithFormatToMSK()
            .set('hour', 0)
            .set('minute', 0)
            .diff(
              DayjsService.dayjs(date, objOfDateFormats.defaultFormat)
                .set('hour', 0)
                .set('minute', 0),
              'd',
            ) <= 0 ||
          'планируемая дата начала возмещения затрат не может быть меньше, чем текущая дата'
        )
      },
    },
  },
  infrastructureCompensationPeriod: {
    required: defaultRHFValidation.required,
  },
  infrastructureCompensationForm: ({ form, name }) => ({
    validate: (fieldValues) => {
      if (!isObject(fieldValues)) return

      const isValidFieldValues = Object.values(fieldValues).some((value) => value)

      if (!isValidFieldValues) return defaultRHFValidation.required.message
    },
    onChange: () => {
      form.trigger(name as Path<InfrFacilitiesCostsExpectedReimbursedFormValues>)
    },
  }),
  infrastructureKind: {
    required: defaultRHFValidation.required,
  },
}

export { infractureObjectStepValidationMap }
