import React, { FC, memo, ReactNode, useCallback, useMemo } from 'react'

import { useVersionManager } from '@components/Attachments/Version/manager'
import Button from '@components/NewDesign/Button'
import { useChangesMadeContext } from '@components/Projects/[id]/DocumentLayout/ChangesMade/Context'
import styles from '@components/Projects/[id]/DocumentLayout/View/DocumentsView.module.scss'
import LayoutHeader from '@components/Projects/[id]/DocumentLayout/View/Layouts/LayoutHeader'
import StandardDfoLayoutTemplate from '@components/Projects/[id]/DocumentLayout/View/Layouts/StandardDfoLayoutTemplate'
import StandardDfoLayoutLoader from '@components/Projects/[id]/DocumentLayout/View/Layouts/StandardDfoLayoutTemplate/Loader'
import { isIntervalServerError } from '@helpers/errorHelpers'
import DownloadIcon from '@icons/DownloadIcon.svg'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import {
  IModifiedDocumentSetInfoForRender,
  IModifiedDocumentSetInfoForRenderToArray,
} from '@services/Documents/documents.entity'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'
import { AxiosError } from 'axios'

const { isDocumentReadDownloadingForbidden, isDocumentDownloadingForbidden } =
  DocumentsHelpersService

export interface StandardDfoLayoutProps {
  dfo: IDfoListItem | null
  isLoading: boolean

  preparedSetsOfDocuments?: Map<string, IModifiedDocumentSetInfoForRender[]>

  documentSetsClassName?: string
  actionsClassName?: string
  disableDownloadDocuments?: boolean
  disableNameOfSets?: boolean

  mapOfSetsToRender?: IModifiedDocumentSetInfoForRenderToArray[]
  documentsError?: AxiosError<unknown, unknown>

  headNode?: ReactNode

  children?: ReactNode
}

const StandardDfoLayout: FC<StandardDfoLayoutProps> = (props) => {
  const { mapOfSetsToRender, documentsError } = props

  const {
    handlers: { onArchiveDownload },
  } = useVersionManager()

  const { handleShowAllSetOfDocuments } = useChangesMadeContext()

  const handleResetOnlyChangedDocumentsSwitch = () => {
    handleShowAllSetOfDocuments()
  }

  const hasNotPermissionsWithDownload = useMemo(
    () =>
      mapOfSetsToRender?.every((item) =>
        item.documentSets?.every(
          (documentItem) =>
            isDocumentDownloadingForbidden(documentItem.permissions) ||
            isDocumentReadDownloadingForbidden(documentItem.permissions),
        ),
      ),
    [mapOfSetsToRender],
  )

  const hasServerError = isIntervalServerError(documentsError?.response?.status)

  const hasDownloadArchiveButton =
    !props.disableDownloadDocuments &&
    !hasServerError &&
    !!mapOfSetsToRender?.length &&
    !hasNotPermissionsWithDownload

  const handleArchiveDownload = useCallback(async () => onArchiveDownload?.(), [onArchiveDownload])

  const downloadButton = useMemo(
    () =>
      hasDownloadArchiveButton && (
        <Button
          className={styles.documentsView__download}
          geometry={'square'}
          size={'s'}
          view={'plain'}
          dataTestId="StandardDfoLayout-downloadArchive-button"
          leadingIcon={{
            src: DownloadIcon,
            size: 'initial',
            className: styles.documentsView__icon,
          }}
          onClick={handleArchiveDownload}
        >
          {'Скачать весь комплект'}
        </Button>
      ),
    [handleArchiveDownload, hasDownloadArchiveButton],
  )

  if (props.isLoading) return <StandardDfoLayoutLoader />

  return (
    <LayoutHeader
      dfo={props.dfo}
      mapOfSetsToRender={mapOfSetsToRender}
      downloadButton={downloadButton}
      handleResetOnlyChangedDocumentsSwitch={handleResetOnlyChangedDocumentsSwitch}
    >
      <StandardDfoLayoutTemplate {...props} />
    </LayoutHeader>
  )
}

export default memo(StandardDfoLayout)
