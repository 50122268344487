import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForAssignmentMonetaryOrPledgeAForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      ruleLinkCession: {
        propertyId: objectFromPropertyState.ruleLinkCession.propertyId || '',
        type: objectFromPropertyState.ruleLinkCession.type || '',
        lastUpdateDt: objectFromPropertyState.ruleLinkCession.lastUpdateDt || '',
      },
      cessionValue: {
        propertyId: objectFromPropertyState.cessionValue.propertyId || '',
        type: objectFromPropertyState.cessionValue.type || '',
        lastUpdateDt: objectFromPropertyState.cessionValue.lastUpdateDt || '',
      },
      cessionFormName: {
        propertyId: objectFromPropertyState.cessionForm.propertyId || '',
        type: objectFromPropertyState.cessionForm.type || '',
        lastUpdateDt: objectFromPropertyState.cessionForm.lastUpdateDt || '',
      },
      cessionReason: {
        propertyId: objectFromPropertyState.cessionReason.propertyId || '',
        type: objectFromPropertyState.cessionReason.type || '',
        lastUpdateDt: objectFromPropertyState.cessionReason.lastUpdateDt || '',
      },
      cessionMember: {
        propertyId: objectFromPropertyState.cessionMember.propertyId || '',
        type: objectFromPropertyState.cessionMember.type || '',
        lastUpdateDt: objectFromPropertyState.cessionMember.lastUpdateDt || '',
      },
      cessionAvailability: {
        propertyId: objectFromPropertyState.cessionAvailability.propertyId || '',
        type: objectFromPropertyState.cessionAvailability.type || '',
        lastUpdateDt: objectFromPropertyState.cessionAvailability.lastUpdateDt || '',
      },
      cessionDate: {
        propertyId: objectFromPropertyState.cessionDate.propertyId || '',
        type: objectFromPropertyState.cessionDate.type || '',
        lastUpdateDt: objectFromPropertyState.cessionDate.lastUpdateDt || '',
      },
    },
  }
}

export { generatePropertiesObjectForAssignmentMonetaryOrPledgeAForm }
