import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import SupplementalCostSharingAObjectAreasList from '@components/Forms/SupplementalCostSharingAForm/Forms/7/AdditionalForms/ContractDutyObject/ObjectAreasList'
import { seventhSectionStepObjectContractValidationMap } from '@components/Forms/SupplementalCostSharingAForm/Forms/7/AdditionalForms/ContractDutyObject/validation'
import { SupplementalCostSharingAContractDutyObjectsArrayPathName } from '@components/Forms/SupplementalCostSharingAForm/Forms/7/types'
import { useSupplementalCostSharingAManager } from '@components/Forms/SupplementalCostSharingAForm/Manager'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import { SupplementalCostSharingAFieldsControlUpdateWatcher } from '@components/Forms/SupplementalCostSharingAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { getAmountValueFromStrWithoutRound } from '@components/NewDesign/AmountInput/utils'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const CONTRACT_DUTY_OBJECT_ACTIVITY_TYPE_NAME_TO_IGNORE = 'Создание РИД'

interface SupplementalCostSharingAContractDutyObjectProps {
  id: string
  formName: SupplementalCostSharingAContractDutyObjectsArrayPathName
  onContractDutyObjectRemove: VoidFunction
}

const SupplementalCostSharingAContractDutyObject: FC<
  SupplementalCostSharingAContractDutyObjectProps
> = ({ id, formName, onContractDutyObjectRemove }) => {
  const formInstance = useFormContext<SupplementalCostSharingAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSupplementalCostSharingAManager()

  const {
    getSubscribableControlProps,
    getTextareaProps,
    getAmountInputProps,
    getInputProps,
    getSingleSelectProps,
    getCalendarInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: SupplementalCostSharingAFieldsControlUpdateWatcher,
  })

  const contractDutyObjectCost = useWatch({
    name: `${formName}.contractDutyObjectCost`,
    control: formInstance.control
  })

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate
      id={id}
      onRemoveStep={editMode ? onContractDutyObjectRemove : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectName`,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.objectName || '',
                        value: item.id,
                      }),
                      controllerProps: {
                        name: `${formName}.objectName`,
                        rules: seventhSectionStepObjectContractValidationMap.objectName,
                      },
                      selectProps: {
                        inputProps: {
                          label: 'Наименование объекта',
                        },
                        onChangeFormValue: () => handleChangeValue?.(`${formName}.objectName`),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.infrastructureType`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.infrastructureType`,
                  textareaProps: {
                    disabled: true,
                    label: 'Вид инфраструктуры',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <SupplementalCostSharingAObjectAreasList
          formInstance={formInstance}
          formName={`${formName}.objectAreas`}
        />
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectConstructionKind`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.objectConstructionKind`,
                  textareaProps: {
                    disabled: true,
                    label: 'Вид объекта капитального строительства',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectPurpose`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.objectPurpose`,
                  textareaProps: {
                    disabled: true,
                    label: 'Назначение объекта',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectConstructionSquare`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.objectConstructionSquare`,
                  inputProps: {
                    disabled: true,
                    label: 'Площадь застройки, кв. м',
                    suffix: '',
                    minority: 100000,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectSquare`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.objectSquare`,
                  inputProps: {
                    disabled: true,
                    label: 'Площадь, кв.м',
                    suffix: '',
                    minority: 100000,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectNonLivingAreaSquare`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.objectNonLivingAreaSquare`,
                  inputProps: {
                    disabled: true,
                    label: 'Площадь нежилых помещений, кв. м',
                    suffix: '',
                    minority: 100000,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectLivingAreaSquare`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.objectLivingAreaSquare`,
                  inputProps: {
                    disabled: true,
                    label: 'Площадь жилых помещений, кв. м',
                    suffix: '',
                    minority: 100000,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectFloor`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.objectFloor`,
                  inputProps: {
                    disabled: true,
                    label: 'Количество этажей',
                    suffix: '',
                    maxLength: 10,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectUndergroundFloor`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.objectUndergroundFloor`,
                  inputProps: {
                    disabled: true,
                    label: 'в том числе количество подземных',
                    suffix: '',
                    maxLength: 10,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectHeight`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.objectHeight`,
                  inputProps: {
                    disabled: true,
                    label: 'Высота, м',
                    suffix: '',
                    minority: 100000,
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectDetails`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.objectDetails`,
                  textareaProps: {
                    disabled: true,
                    label: 'Иные характеристики',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.contractDutyObjectActivityType`,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => {
                        if (item.name == CONTRACT_DUTY_OBJECT_ACTIVITY_TYPE_NAME_TO_IGNORE) return null

                        return ({
                          displayValue: item.name || '',
                          value: item.id,
                        })
                      },
                      controllerProps: {
                        name: `${formName}.contractDutyObjectActivityType`,
                        rules:
                          seventhSectionStepObjectContractValidationMap.contractDutyObjectActivityType,
                      },
                      selectProps: {
                        inputProps: {
                          label: 'Тип мероприятия',
                        },
                        onChangeFormValue: () =>
                          handleChangeValue?.(`${formName}.contractDutyObjectActivityType`),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.contractDutyObjectActivityEndDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${formName}.contractDutyObjectActivityEndDate`,
                  rules:
                    seventhSectionStepObjectContractValidationMap.contractDutyObjectActivityEndDate,
                  calendarInputProps: {
                    label: 'Дата выполнения мероприятия',
                  },
                  onBlur: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${formName}.contractDutyObjectActivityEndDate`),
                      0,
                    ),
                  onCalendarChange: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${formName}.contractDutyObjectActivityEndDate`),
                      0,
                    ),
                  onInputChange: () =>
                    setTimeout(
                      () =>
                        debouncedHandleChangeValue?.(
                          `${formName}.contractDutyObjectActivityEndDate`,
                        ),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title="Общий объем затрат на объект инфраструктуры"
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.contractDutyObjectCost`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.contractDutyObjectCost`,
                rules: {
                  ...seventhSectionStepObjectContractValidationMap.contractDutyObjectCost,
                  onBlur: () => {
                    formInstance.trigger(`${formName}.contractDutyObjectInvestorCost`)
                  },
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.contractDutyObjectCost`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.contractDutyObjectCost`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title="Объем затрат организации"
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.contractDutyObjectInvestorCost`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.contractDutyObjectInvestorCost`,
                rules: {
                  ...seventhSectionStepObjectContractValidationMap.contractDutyObjectInvestorCost,
                  validate: {
                    positiveStandardAmount: (value) => {
                      if (!isString(value)) return

                      return standardAmountValidate(value)
                    },
                    positiveContractDutyObjectInvestorCost: (value) => {
                      if (!isString(value)) return

                      const numberValue = getAmountValueFromStrWithoutRound(value) || 0
                      const numberContractDutyObjectCost =
                        getAmountValueFromStrWithoutRound(contractDutyObjectCost) || 0

                      return (
                        numberValue <= numberContractDutyObjectCost ||
                        'объем затрат организации не должен быть больше общего объема затрат'
                      )
                    },
                  },
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.contractDutyObjectInvestorCost`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.contractDutyObjectInvestorCost`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default SupplementalCostSharingAContractDutyObject
