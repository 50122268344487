import React, { FC, memo, MutableRefObject, useRef } from 'react'
import Dropzone, { DropzoneProps, DropzoneRef, FileRejection } from 'react-dropzone'
import { ReactSVG } from 'react-svg'

import mainDropZoneStyles from '@components/FileDropzone/Primary/FileDropzonePrimary.module.scss'
import Button from '@components/NewDesign/Button'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import DocumentsUploadIcon from '@icons/DocumentsUploadIcon.svg'
import { RejectedFile } from '@interfaces/documents'
import cn from 'classnames'

import styles from './FileDropzoneSecondary.module.scss'

export interface FileDropzoneSecondaryProps {
  title?: string
  subtitle?: string
  dropZoneProps?: DropzoneProps
  titleReject?: string
  subTitleReject?: string
  dropZoneControlRef?: MutableRefObject<DropzoneRef | null>
  getPreparedRejectedFile?: (fileRejection: FileRejection) => RejectedFile
}

const FileDropzoneSecondary: FC<FileDropzoneSecondaryProps> = ({
  title = 'Добавить документ',
  subtitle = 'Перетащите файл в эту область или ',
  dropZoneProps,
  dropZoneControlRef,
}) => {
  const dropzoneRef = useRef<DropzoneRef | null>(null)

  const openFileDialog = () => {
    if (!dropzoneRef?.current) return

    dropzoneRef.current.open()
  }

  const bindDropzoneRef = (ref: DropzoneRef | null) => {
    if (!ref) return

    dropzoneRef.current = ref

    if (dropZoneControlRef) dropZoneControlRef.current = ref
  }

  return (
    <Dropzone {...dropZoneProps} noClick noKeyboard ref={bindDropzoneRef}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            {...getRootProps({
              className: styles.dropzone,
            })}
          >
            <div
              role={'presentation'}
              className={cn(styles.dropzone__container, {
                [styles['dropzone__container--active']]: isDragActive,
              })}
            >
              <div
                role={'presentation'}
                className={mainDropZoneStyles.dropzone__content}
                onClick={(e) => e.preventDefault()}
              >
                <input {...getInputProps()} />
                <div
                  className={cn(
                    styles['dropzone__content-info'],
                    styles['dropzone__content-info-short'],
                  )}
                >
                  {title && (
                    <TypographyWithTooltip.Body
                      variant={'bodyXLMedium'}
                      className={mainDropZoneStyles['dropzone__content-text']}
                      tooltipProps={{
                        position: 'top',
                        offset: [0, 10],
                        targetClassName: styles.tooltip__target,
                        popoverClassName: styles.tooltip__popover,
                        arrowClassName: styles.tooltip__arrow,
                      }}
                    >
                      {title}
                    </TypographyWithTooltip.Body>
                  )}
                  <div className={mainDropZoneStyles['dropzone__content-subtitle-container']}>
                    {subtitle && (
                      <TypographyWithTooltip.Body
                        as={'span'}
                        variant={'bodyMRegular'}
                        color={'on-surface-secondary'}
                        className={mainDropZoneStyles['dropzone__content-subtitle']}
                        tooltipProps={{
                          position: 'top',
                          offset: [0, 10],
                          targetClassName: styles.tooltip__target,
                          popoverClassName: styles.tooltip__popover,
                          arrowClassName: styles.tooltip__arrow,
                        }}
                      >
                        {subtitle}
                      </TypographyWithTooltip.Body>
                    )}
                    <Button
                      className={mainDropZoneStyles['dropzone__content-downloadTemplate']}
                      size={'xs'}
                      view={'text'}
                      variant={'buttonSMedium'}
                      color={'default'}
                      data-testid="FileDropzoneSecondary-fileUpload-textButton"
                      onClick={openFileDialog}
                    >
                      выберите на устройстве
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <ReactSVG
              role={'button'}
              className={styles.dropzone__icon}
              src={DocumentsUploadIcon}
              data-testid="FileDropzoneSecondary-fileUpload-button"
              onClick={openFileDialog}
            />
          </div>
        )
      }}
    </Dropzone>
  )
}

export default memo(FileDropzoneSecondary)
