import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { supplementalCostSharingABlockValues } from '@components/Forms/SupplementalCostSharingAForm/const'
import SupplementalCostSharingAContractDutyObject from '@components/Forms/SupplementalCostSharingAForm/Forms/7/AdditionalForms/ContractDutyObject'
import { SupplementalCostSharingAContractDutyObjectsPathName } from '@components/Forms/SupplementalCostSharingAForm/Forms/7/types'
import { useSupplementalCostSharingAManager } from '@components/Forms/SupplementalCostSharingAForm/Manager'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import {
  SupplementalCostSharingAFieldArrayControlUpdateWatcher,
  SupplementalCostSharingAFieldsControlUpdateWatcher,
} from '@components/Forms/SupplementalCostSharingAForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { isFormFieldError } = DocumentFormHelpers

const Seventh = () => {
  const formInstance = useFormContext<SupplementalCostSharingAFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useSupplementalCostSharingAManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: SupplementalCostSharingAFieldsControlUpdateWatcher,
  })

  const { fields: contractDutyObjects } = useFieldArraySubscribableControl<
    SupplementalCostSharingAFormValues,
    SupplementalCostSharingAContractDutyObjectsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: supplementalCostSharingABlockValues['7'].contractDutyObjects,
    keyName: 'keyNameId',
    watcher: SupplementalCostSharingAFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleAddNewContractDutyMember = async () => {
    await handleAddItemToListWithOutValue?.(
      supplementalCostSharingABlockValues['7'].contractDutyObjects,
    )
  }

  const handleRemoveContractDutyMember = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${supplementalCostSharingABlockValues['7'].contractDutyObjects}.${index}`,
      supplementalCostSharingABlockValues['7'].contractDutyObjects,
    )
  }

  const contractDutyObjectsError = formInstance.getFieldState(
    supplementalCostSharingABlockValues['7'].contractDutyObjects,
  )?.error
  const contractDutyObjectsCondition =
    !contractDutyObjects.length && isFormFieldError(contractDutyObjectsError)

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: supplementalCostSharingABlockValues['7'].contractDutyObjects,
          })}
        >
          <FlipperList list={contractDutyObjects}>
            {contractDutyObjects.map((object, index) => {
              const formName =
                `${supplementalCostSharingABlockValues['7'].contractDutyObjects}.${index}` as const

              return (
                <SupplementalCostSharingAContractDutyObject
                  key={object.id}
                  id={object.id}
                  formName={formName}
                  onContractDutyObjectRemove={handleRemoveContractDutyMember(index)}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {contractDutyObjectsCondition && (
          <Row className={'px-1'}>
            <FormError rowClassName={'px-3'} colClassName={'px-3'}>
              {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
            </FormError>
          </Row>
        )}
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddNewContractDutyMember}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    dataTestId="ExpensesCompensationArrayForm-add-button"
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить объект
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Seventh
