import { useMemo } from 'react'

import { defaultIntersectionObserverOptions } from '@hooks/new/observers/constants'

import { ElementOrElementsToObserve } from './types'
import { useObserver } from './useObserver'

type UseIntersectionObserverProps = {
  elementsToObserve: ElementOrElementsToObserve
  intersectionListener: IntersectionObserverCallback
  observerOptions?: IntersectionObserverInit
  conditionToSkip?: boolean
}

export const useIntersectionObserver = ({
  elementsToObserve,
  intersectionListener,
  observerOptions: observerOptionsFromProps,
  conditionToSkip,
}: UseIntersectionObserverProps) => {
  const {
    threshold = defaultIntersectionObserverOptions.threshold,
    root = defaultIntersectionObserverOptions.root,
    rootMargin = defaultIntersectionObserverOptions.rootMargin,
  } = observerOptionsFromProps || {}

  const observerOptions = useMemo(() => {
    return {
      threshold,
      root,
      rootMargin,
    }
  }, [threshold, root, rootMargin])

  const intersectionObserver = useMemo(
    () =>
      window.IntersectionObserver
        ? new window.IntersectionObserver(intersectionListener, observerOptions)
        : null,
    [intersectionListener, observerOptions],
  )

  useObserver({ observerInstance: intersectionObserver, elementsToObserve, conditionToSkip })
}
