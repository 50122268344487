import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import styles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import ObjectFormItem from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem'
import { ConstructionSubObjectsArrayPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { AAgreementOnCreationOtherEstateObjectsFieldCollapseControlUpdateWatcher } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import Button from '@components/NewDesign/Button'
import Stack from '@components/ReactBootstrap/Stack'
import DeleteIcon from '@icons/DeleteIcon.svg'
import arrowSubdirectoryIcon from '@icons/navigation/arrow-subdirectory.svg'
import cn from 'classnames'

interface SubObjectItemProps {
  subObjectId: string
  blockViewIsValidating: boolean
  editMode: boolean
  //Если много объектов, необходимо все сворачивать и анмаунтить
  expandedRenderMode: boolean
  formInstance: UseFormReturn<AAgreementOnCreationOtherEstateObjectsFormValues>
  name: ConstructionSubObjectsArrayPathName
  indexOfObject: number
  lastIndexOfObject: number
  onDeleteSubObject?: VoidFunction

  onMoveTop?: () => Promise<void>
  onMoveBottom?: () => Promise<void>
  leftAddons?: ReactNode
}

const SubObjectItem: FC<SubObjectItemProps> = ({
  subObjectId,
  blockViewIsValidating,
  expandedRenderMode,
  editMode,
  indexOfObject,
  lastIndexOfObject,
  formInstance,
  name,
  onDeleteSubObject,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const { isExpanded: isShowObjectItemContent, onToggleCollapse: toggleShowObjectItemContent } =
    useFormCollapseControl({
      initialExpanded: expandedRenderMode,
      name,
      watcher: AAgreementOnCreationOtherEstateObjectsFieldCollapseControlUpdateWatcher,
    })

  const isNew = useWatch({
    name: `${name}.isNew`,
    control: formInstance.control,
  })

  const handleDeleteSubObject = async () => {
    await onDeleteSubObject?.()
  }

  return (
    <FlippedWithCollapse flipId={subObjectId}>
      <div id={name} className={cn(styles.objectItem, styles['objectItem--subObject'])}>
        <div className={styles.objectItem__header}>
          <ObjectStepWrapperHeaderNode
            leftAddons={leftAddons}
            buttonProps={{
              onClick: toggleShowObjectItemContent,
              leadingIcon: {
                src: arrowSubdirectoryIcon,
                size: 'xs',
              },
              trailingIcon: {
                className: cn({
                  [styles['objectItem__header-button__icon--active']]: isShowObjectItemContent,
                }),
              },
            }}
          >
            ОБЪЕКТ {indexOfObject + 1}
          </ObjectStepWrapperHeaderNode>
          {isNew && editMode && (
            <Stack direction={'horizontal'} gap={2}>
              <FieldArraySwapper
                index={indexOfObject}
                lastIndex={lastIndexOfObject}
                onMoveTop={onMoveTop}
                onMoveBottom={onMoveBottom}
              />
              <AsyncWrapper promise={handleDeleteSubObject}>
                {({ isLoading, wrappedPromise }) => (
                  <Button
                    disabled={isLoading}
                    variant={'buttonSMedium'}
                    size={'2xs'}
                    color={'negative'}
                    view={'plain'}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    leadingIcon={{
                      noCurrentColorSvgFill: true,
                      src: DeleteIcon,
                    }}
                    onClick={wrappedPromise}
                  >
                    Удалить
                  </Button>
                )}
              </AsyncWrapper>
            </Stack>
          )}
        </div>
        <div className={styles.objectItem__content}>
          <ObjectFormItem
            isShowObjectItemContent={isShowObjectItemContent}
            blockViewIsValidating={blockViewIsValidating}
            editMode={editMode}
            formInstance={formInstance}
            formName={name}
          />
        </div>
      </div>
    </FlippedWithCollapse>
  )
}

export default SubObjectItem
