import React from 'react'
import { useFormContext } from 'react-hook-form'

import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeOfProjectRejectConcludeSZPKBlockValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/const'
import { firstSectionValidationMap } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/Forms/1/validation'
import { useNoticeOfProjectRejectConcludeSZPKManager } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/Manager'
import { NoticeOfProjectRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/types'
import { NoticeOfProjectRejectConcludeSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfProjectRejectConcludeSZPKForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'

const First = () => {
  const formInstance = useFormContext<NoticeOfProjectRejectConcludeSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeOfProjectRejectConcludeSZPKManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NoticeOfProjectRejectConcludeSZPKFieldsControlUpdateWatcher,
  })

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: noticeOfProjectRejectConcludeSZPKBlockValues['1'].documentReceiversDraft,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: noticeOfProjectRejectConcludeSZPKBlockValues['1'].documentReceiversDraft,
                rules: firstSectionValidationMap.documentReceiversDraft,
                textareaProps: {
                  label: 'Получатель документа',
                  caption: 'не обязательно',
                  leftAddons: (
                    <FormIconWithTooltip
                      tooltipContent={
                        'Если требуется, укажите наименование и адрес организации получателя документа. Начните указание адреса со слова "Адрес:". Если получателей несколько, укажите сведения для каждого'
                      }
                    />
                  ),
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      noticeOfProjectRejectConcludeSZPKBlockValues['1'].documentReceiversDraft,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      noticeOfProjectRejectConcludeSZPKBlockValues['1'].documentReceiversDraft,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
    </Container>
  )
}

export default First
