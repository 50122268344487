import { generatePropertiesObjectForAAgreementIncludeRelatedTermsForm } from '@components/Forms/AAgreementIncludeRelatedTermsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAAgreementIncludeRelatedTermsForm } from '@components/Forms/AAgreementIncludeRelatedTermsForm/adapters/RHF.adapter'
import { AAgreementIncludeRelatedTermsFormValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/types'

const useAAgreementIncludeRelatedTermsAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): AAgreementIncludeRelatedTermsFormValues =>
    generateRHFObjectForAAgreementIncludeRelatedTermsForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForAAgreementIncludeRelatedTermsForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useAAgreementIncludeRelatedTermsAdapters }
