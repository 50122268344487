import React, { FC } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { FormModalLayoutWrapperProps } from '@components/DocumentFormComponents/types'
import { useCertificateOfImplementationInvestprojectStagesManager } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Manager'

const CertificateOfImplementationInvestprojectStagesLayoutWrapper: FC<
  FormModalLayoutWrapperProps
> = ({ lastUpdateDraftTime, children, ...rest }) => {
  const {
    actions: { handleFormClose },
  } = useCertificateOfImplementationInvestprojectStagesManager()

  const preparedHandleFormClose = () => {
    handleFormClose?.()
  }

  return (
    <DocumentLayoutContent
      {...rest}
      draftIsSaved={!!lastUpdateDraftTime}
      onClose={preparedHandleFormClose}
    >
      {children}
    </DocumentLayoutContent>
  )
}

export default CertificateOfImplementationInvestprojectStagesLayoutWrapper
