import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOther from '@components/Forms/InformationOfExecutionAgreementEventsForm/Forms/1/StepMonitoring/factStepActivityOther'
import InformationOfExecutionAgreementEventsStepsMonitoringFactStepPermission from '@components/Forms/InformationOfExecutionAgreementEventsForm/Forms/1/StepMonitoring/factStepPermission'
import { stepMonitoringValidationMap } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Forms/1/StepMonitoring/validation'
import {
  InformationOfExecutionAgreementEventsStepsMonitoringArrayPathName,
  InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOthersPathName,
  InformationOfExecutionAgreementEventsStepsMonitoringFactStepPermissionsPathName,
} from '@components/Forms/InformationOfExecutionAgreementEventsForm/Forms/1/types'
import { useInformationOfExecutionAgreementEventsManager } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Manager'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import {
  InformationOfExecutionAgreementEventsFieldArrayControlUpdateWatcher,
  InformationOfExecutionAgreementEventsFieldCollapseControlUpdateWatcher,
  InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionAgreementEventsForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isNotEmptyString } from '@helpers/checkTypes'

import styles from './StepMonitoring.module.scss'

interface InformationOfExecutionAgreementEventsStepMonitoringProps {
  id: string
  anchorId: string
  name: InformationOfExecutionAgreementEventsStepsMonitoringArrayPathName
  formInstance: UseFormReturn<InformationOfExecutionAgreementEventsFormValues>
}

const InformationOfExecutionAgreementEventsStepMonitoring: FC<
  InformationOfExecutionAgreementEventsStepMonitoringProps
> = ({ id, anchorId, name, formInstance }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionAgreementEventsManager()

  const {
    getSubscribableControlProps,
    getTextareaProps,
    getSwitchProps,
    getInputProps,
    getCalendarInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher,
  })

  const { fields: factStepPermissions } = useFieldArraySubscribableControl<
    InformationOfExecutionAgreementEventsFormValues,
    InformationOfExecutionAgreementEventsStepsMonitoringFactStepPermissionsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.factStepPermissions`,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionAgreementEventsFieldArrayControlUpdateWatcher,
  })

  const { fields: factStepActivityOthers } = useFieldArraySubscribableControl<
    InformationOfExecutionAgreementEventsFormValues,
    InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOthersPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: `${name}.factStepActivityOthers`,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionAgreementEventsFieldArrayControlUpdateWatcher,
  })

  const { clearErrors } = formInstance

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name,
    watcher: InformationOfExecutionAgreementEventsFieldCollapseControlUpdateWatcher,
  })

  const stepTitle = useWatch({
    name: `${name}.stepTitle`,
    control: formInstance.control,
  })

  const isNotContainsCompletedEvents = useWatch({
    name: `${name}.notContainsCompletedEvents`,
    control: formInstance.control,
  })

  const objectCommissioningEndDate = useWatch({
    name: `${name}.objectCommissioningEndDate`,
    control: formInstance.control,
  })

  const ridCommissioningEndDate = useWatch({
    name: `${name}.ridCommissioningEndDate`,
    control: formInstance.control,
  })

  const objectStateRegistrationEndDate = useWatch({
    name: `${name}.objectStateRegistrationEndDate`,
    control: formInstance.control,
  })

  const ridStateRegistrationEndDate = useWatch({
    name: `${name}.ridStateRegistrationEndDate`,
    control: formInstance.control,
  })

  const typeObjectStateRegistration = useWatch({
    name: `${name}.typeObjectStateRegistration`,
    control: formInstance.control,
  })

  const isShowFactStepPermissions = !!factStepPermissions.length

  const isShowCommissioning =
    isNotEmptyString(objectCommissioningEndDate) || isNotEmptyString(ridCommissioningEndDate)

  const isShowObjectCommissioningEndDate = isNotEmptyString(objectCommissioningEndDate)
  const isShowRidCommissioningEndDate = isNotEmptyString(ridCommissioningEndDate)

  const isShowRegistration =
    isNotEmptyString(objectStateRegistrationEndDate) ||
    isNotEmptyString(ridStateRegistrationEndDate)

  const isShowTypeObjectStateRegistration = isNotEmptyString(typeObjectStateRegistration)
  const isShowObjectStateRegistrationEndDate = isNotEmptyString(objectStateRegistrationEndDate)
  const isShowRidStateRegistrationEndDate = isNotEmptyString(ridStateRegistrationEndDate)

  const isShowFactStepActivityOthers = !!factStepActivityOthers.length

  return (
    <Accordion
      unmountOnCollapse={false}
      isExpanded={isExpanded}
      id={id}
      title={stepTitle}
      anchorId={anchorId}
      onToggle={onToggleCollapse}
    >
      <Container className={styles.stepMonitoring__container}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.notContainsCompletedEvents`,
                })}
              >
                <ControlledSwitch
                  {...getSwitchProps({
                    name: `${name}.notContainsCompletedEvents`,
                    captionClassName: styles.stepMonitoring__switch,
                    switchProps: {
                      label: 'По этапу нет реализованных мероприятий',
                    },
                    onChange: () => {
                      clearErrors(`${name}.notContainsCompletedEvents`)
                      handleChangeValue?.(`${name}.notContainsCompletedEvents`)
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <CollapseWrapper
            isExpanded={isNotContainsCompletedEvents}
            defaultExpanded={isNotContainsCompletedEvents}
          >
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.notContainsCompletedEventsComment`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${name}.notContainsCompletedEventsComment`,
                      rules: stepMonitoringValidationMap.notContainsCompletedEventsComment,
                      textareaProps: {
                        label: 'Примечание',
                      },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(`${name}.notContainsCompletedEventsComment`),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.notContainsCompletedEventsComment`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </CollapseWrapper>
        </Stack>
        {isShowFactStepPermissions && (
          <Group>
            <Typography.Body className={styles.stepMonitoring__title} variant="bodyLMedium">
              Полученные разрешения
            </Typography.Body>

            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.factStepPermissions`,
              })}
            >
              <FlipperList list={factStepPermissions}>
                {factStepPermissions.map((factStepPermission, index) => {
                  const formName = `${name}.factStepPermissions.${index}` as const

                  return (
                    <InformationOfExecutionAgreementEventsStepsMonitoringFactStepPermission
                      name={formName}
                      id={factStepPermission.id}
                      indexOfStep={index}
                      formInstance={formInstance}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Group>
        )}

        {isShowCommissioning && (
          <Group>
            <Typography.Body className={styles.stepMonitoring__title} variant="bodyLMedium">
              Ввод в эксплуатацию
            </Typography.Body>
            <Stack direction={'vertical'} gap={3}>
              {isShowObjectCommissioningEndDate && (
                <Group
                  disableBottomBorder
                  groupClassName={styles['stepMonitoring__group-disabledMargin']}
                  title={'Дата ввода в эксплуатацию всех объектов недвижимости этапа'}
                >
                  <Row>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.objectCommissioningEndDate`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${name}.objectCommissioningEndDate`,
                            calendarInputProps: {
                              disabled: true,
                              label: 'План',
                            },
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.factObjectCommissioningEndDate`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${name}.factObjectCommissioningEndDate`,
                            calendarInputProps: {
                              label: 'Факт',
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.factObjectCommissioningEndDate`)
                              }, 0),
                            onCalendarChange: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.factObjectCommissioningEndDate`)
                              }, 0),
                            onInputChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${name}.factObjectCommissioningEndDate`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                </Group>
              )}
              {isShowRidCommissioningEndDate && (
                <Group
                  disableBottomBorder
                  groupClassName={styles['stepMonitoring__group-disabledMargin']}
                  title={'Дата ввода в эксплуатацию всех РИД этапа'}
                >
                  <Row>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.ridCommissioningEndDate`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${name}.ridCommissioningEndDate`,
                            calendarInputProps: {
                              disabled: true,
                              label: 'План',
                            },
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.factRidCommissioningEndDate`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${name}.factRidCommissioningEndDate`,
                            calendarInputProps: {
                              label: 'Факт',
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.factRidCommissioningEndDate`)
                              }, 0),
                            onCalendarChange: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.factRidCommissioningEndDate`)
                              }, 0),
                            onInputChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(`${name}.factRidCommissioningEndDate`)
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                </Group>
              )}
            </Stack>
          </Group>
        )}
        {isShowRegistration && (
          <Group>
            <Typography.Body className={styles.stepMonitoring__title} variant="bodyLMedium">
              Регистрация
            </Typography.Body>
            <Stack direction={'vertical'} gap={3}>
              {isShowTypeObjectStateRegistration && (
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.typeObjectStateRegistration`,
                      })}
                    >
                      <ControlledInput
                        {...getInputProps({
                          name: `${name}.typeObjectStateRegistration`,
                          inputProps: {
                            disabled: true,
                            label: 'Вид права, подлежащего регистрации по этапу',
                          },
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
              {isShowObjectStateRegistrationEndDate && (
                <Group
                  disableBottomBorder
                  groupClassName={styles['stepMonitoring__group-disabledMargin']}
                  title={'Дата регистрации всех объектов недвижимости этапа'}
                >
                  <Row>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.objectStateRegistrationEndDate`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${name}.objectStateRegistrationEndDate`,
                            calendarInputProps: {
                              disabled: true,
                              label: 'План',
                            },
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.factObjectStateRegistrationDate`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${name}.factObjectStateRegistrationDate`,
                            calendarInputProps: {
                              label: 'Факт',
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.factObjectStateRegistrationDate`)
                              }, 0),
                            onCalendarChange: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.factObjectStateRegistrationDate`)
                              }, 0),
                            onInputChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${name}.factObjectStateRegistrationDate`,
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                </Group>
              )}
              {isShowRidStateRegistrationEndDate && (
                <Group
                  disableBottomBorder
                  groupClassName={styles['stepMonitoring__group-disabledMargin']}
                  title={'Дата регистрации всех РИД этапа'}
                >
                  <Row>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.ridStateRegistrationEndDate`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${name}.ridStateRegistrationEndDate`,
                            calendarInputProps: {
                              disabled: true,
                              label: 'План',
                            },
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.factRidStateRegistrationDate`,
                        })}
                      >
                        <ControlledCalendarInput
                          {...getCalendarInputProps({
                            name: `${name}.factRidStateRegistrationDate`,
                            calendarInputProps: {
                              label: 'Факт',
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.factRidStateRegistrationDate`)
                              }, 0),
                            onCalendarChange: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.factRidStateRegistrationDate`)
                              }, 0),
                            onInputChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(`${name}.factRidStateRegistrationDate`)
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                </Group>
              )}
            </Stack>
          </Group>
        )}

        {isShowFactStepActivityOthers && (
          <Group disableBottomBorder>
            <Typography.Body className={styles.stepMonitoring__title} variant="bodyLMedium">
              Иные мероприятия этапа
            </Typography.Body>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.objectCommissioningEndDate`,
              })}
            >
              <FlipperList list={factStepActivityOthers}>
                {factStepActivityOthers.map((factStepActivityOther, index) => {
                  const formName = `${name}.factStepActivityOthers.${index}` as const

                  return (
                    <InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOther
                      name={formName}
                      id={factStepActivityOther.id}
                      indexOfStep={index}
                      formInstance={formInstance}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Group>
        )}
      </Container>
    </Accordion>
  )
}

export default InformationOfExecutionAgreementEventsStepMonitoring
