import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { noticeIntentionTerminateSZPKBlockValues } from '@components/Forms/NoticeIntentionTerminateSZPKForm/const'
import { thirdSectionValidationMap } from '@components/Forms/NoticeIntentionTerminateSZPKForm/Forms/3/validation'
import { useNoticeIntentionTerminateSZPKManager } from '@components/Forms/NoticeIntentionTerminateSZPKForm/Manager'
import { NoticeIntentionTerminateSZPKFormValues } from '@components/Forms/NoticeIntentionTerminateSZPKForm/types'
import { NoticeIntentionTerminateSZPKFieldsControlUpdateWatcher } from '@components/Forms/NoticeIntentionTerminateSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance = useFormContext<NoticeIntentionTerminateSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNoticeIntentionTerminateSZPKManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NoticeIntentionTerminateSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункт Правил, на основании которого направляется уведомление о намерении расторгнуть СЗПК'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: noticeIntentionTerminateSZPKBlockValues['3'].ruleLinkAgreementTermination,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: noticeIntentionTerminateSZPKBlockValues['3'].ruleLinkAgreementTermination,
                    rules: thirdSectionValidationMap.ruleLinkAgreementTermination,
                    inputProps: {},
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            noticeIntentionTerminateSZPKBlockValues['3']
                              .ruleLinkAgreementTermination,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(
                        () =>
                          debouncedHandleChangeValue?.(
                            noticeIntentionTerminateSZPKBlockValues['3']
                              .ruleLinkAgreementTermination,
                          ),
                        0,
                      ),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
