import { IndicatorColorInfo, IndicatorOptions } from './types'

const defaultIndicatorColors: Record<string, IndicatorColorInfo> = {
  GREEN: {
    mainColor: 'rgba(0, 209, 87, 0.4)',
    activeColor: 'rgba(0, 209, 87, 1)',
  },
  YELLOW: {
    mainColor: 'rgba(255, 213, 26, 0.4)',
    activeColor: 'rgba(255, 213, 26, 1)',
  },
  ORANGE: {
    mainColor: 'rgba(255, 143, 31, 0.4)',
    activeColor: 'rgba(255, 143, 31, 1)',
  },
  RED: {
    mainColor: 'rgba(245, 76, 82, 0.4)',
    activeColor: 'rgba(245, 76, 82, 1)',
  },
} as const

const defaultIndicatorOptions: IndicatorOptions = [
  {
    value: 1,
    ...defaultIndicatorColors.GREEN,
  },
  {
    value: 2,
    ...defaultIndicatorColors.YELLOW,
  },
  {
    value: 3,
    ...defaultIndicatorColors.ORANGE,
  },
  {
    value: 4,
    ...defaultIndicatorColors.RED,
  },
]

export { defaultIndicatorColors, defaultIndicatorOptions }
