import { DetailedHTMLProps, FC, LiHTMLAttributes, memo, ReactNode } from 'react'

import type { IconProps } from '@components/Icon'
import Button from '@components/NewDesign/Button'
import type { ButtonProps } from '@components/NewDesign/Button/types'
import { isFunction, isUndefined } from '@helpers/checkTypes'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useBooleanState } from '@hooks/useBooleanState'
import cn from 'classnames'

import styles from './Accordion.module.scss'

interface AccordionState {
  isMountChildren: boolean
  isActive: boolean
}

type NewButtonProps = Omit<ButtonProps, 'className' | 'textClassName'> & {
  className?: ((state: AccordionState) => string | undefined) | string
  textClassName?: ((state: AccordionState) => string | undefined) | string
  renderTrailingIcon?: ((state: AccordionState) => IconProps | undefined) | IconProps
  renderLeadingIcon?: ((state: AccordionState) => IconProps | undefined) | IconProps
}

interface AccordionProps
  extends Omit<DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>, 'className'> {
  text: ReactNode
  children: ReactNode
  isOpen?: boolean
  unmountOnCollapse?: boolean
  controlledState?: boolean
  buttonProps?: NewButtonProps
  leftAddon?: ReactNode
  rightAddon?: ReactNode
  className?: ((state: AccordionState) => string | undefined) | string
  listClassName?: ((state: AccordionState) => string | undefined) | string
  borderClassName?: ((state: AccordionState) => string | undefined) | string
  leftAddonClassName?: ((state: AccordionState) => string | undefined) | string
  rightAddonClassName?: ((state: AccordionState) => string | undefined) | string
}

const Accordion: FC<AccordionProps> = ({
  isOpen = false,
  unmountOnCollapse = false,
  text,
  className,
  children,
  buttonProps,
  controlledState,
  leftAddon,
  rightAddon,
  leftAddonClassName,
  rightAddonClassName,
  listClassName,
  borderClassName,
  ...rest
}) => {
  const {
    fixWidth = true,
    geometry = 'square',
    view = 'text',
    size = 'xs',
    color = 'default',
    variant = 'buttonSMedium',
    className: buttonClassName,
    textClassName,
    renderTrailingIcon,
    renderLeadingIcon,
    onClick: onClickButton,
    ...restButtonProps
  } = buttonProps || {}

  const { booleanState: isShowContent, reverseBooleanState: toggleShowContent } =
    useBooleanState(isOpen)

  const leftAddonRenderCondition = !isUndefined(leftAddon)
  const rightAddonRenderCondition = !isUndefined(rightAddon)

  const {
    booleanState: isMountChildren,
    setBooleanStateToTrue: mountChildren,
    setBooleanStateToFalse: unmountChildren,
  } = useBooleanState()

  const { getCollapseProps } = useCollapse({
    isExpanded: !isUndefined(controlledState) ? controlledState : isShowContent,
    onExpandStart: () => {
      if (!unmountOnCollapse) return

      mountChildren()
    },
    onCollapseEnd: () => {
      if (!unmountOnCollapse) return

      unmountChildren()
    },
  })

  const preparedClassName: string | undefined = isFunction(className)
    ? className({
        isMountChildren,
        isActive: isShowContent,
      })
    : className

  const preparedListClassName: string | undefined = isFunction(listClassName)
    ? listClassName({
        isMountChildren,
        isActive: isShowContent,
      })
    : listClassName

  const preparedButtonClassName: string | undefined = isFunction(buttonClassName)
    ? buttonClassName({
        isMountChildren,
        isActive: isShowContent,
      })
    : buttonClassName

  const preparedTextClassName: string | undefined = isFunction(textClassName)
    ? textClassName({
        isMountChildren,
        isActive: isShowContent,
      })
    : textClassName

  const preparedBorderClassName: string | undefined = isFunction(borderClassName)
    ? borderClassName({
        isMountChildren,
        isActive: isShowContent,
      })
    : borderClassName

  const trailingIcon: IconProps | undefined = isFunction(renderTrailingIcon)
    ? renderTrailingIcon({
        isMountChildren,
        isActive: isShowContent,
      })
    : renderTrailingIcon

  const leadingIcon: IconProps | undefined = isFunction(renderLeadingIcon)
    ? renderLeadingIcon({
        isMountChildren,
        isActive: isShowContent,
      })
    : renderLeadingIcon

  const handleClick = (event) => {
    if (!isUndefined(controlledState)) return onClickButton?.(event)

    if (isUndefined(onClickButton) || !isShowContent) {
      toggleShowContent()
    }

    onClickButton?.(event)
  }

  return (
    <>
      <li className={cn(styles.accordion, preparedClassName)} {...rest}>
        {leftAddonRenderCondition && (
          <div
            className={cn(
              styles.accordion__addon,
              styles['accordion__addon--left'],
              leftAddonClassName,
            )}
          >
            {leftAddon}
          </div>
        )}
        <Button
          {...(restButtonProps as ButtonProps)}
          geometry={geometry}
          view={view}
          size={size}
          color={color}
          variant={variant}
          fixWidth={fixWidth}
          trailingIcon={trailingIcon}
          leadingIcon={leadingIcon}
          className={cn(styles.button, preparedButtonClassName)}
          textClassName={preparedTextClassName}
          onClick={handleClick}
        >
          {text}
        </Button>
        {rightAddonRenderCondition && (
          <div
            className={cn(
              styles.accordion__addon,
              styles['accordion__addon--right'],
              rightAddonClassName,
            )}
          >
            {rightAddon}
          </div>
        )}
      </li>
      <div {...getCollapseProps()}>
        {(!unmountOnCollapse || (unmountOnCollapse && isMountChildren)) && (
          <ul className={cn(styles.accordion__list, preparedListClassName)}>{children}</ul>
        )}
      </div>
      <div className={cn(styles.accordion__border, preparedBorderClassName)} />
    </>
  )
}

export type { AccordionProps, AccordionState }

export default memo(Accordion)
