import type { UseControllerProps } from 'react-hook-form'

import { FOLDER_NAME_MAX_LENGTH } from '@components/Attachments/Attachment/FolderItem/constants'
import type { AttachmentsFormValue } from '@components/Attachments/types'
import { Patterns } from '@constants/validations'

const alertTextsMap = {
  notFoundDocumentText: 'Не удалось получить информацию о документе',
  notFoundFolderText: 'Не удалось получить информацию о папке',
}

const folderNameRulesMap: UseControllerProps<AttachmentsFormValue>['rules'] = {
  required: {
    value: true,
    message: 'Необходимо заполнить название папки.',
  },
  pattern: {
    value: Patterns.TruthlyString,
    message: 'Использованы недопустимые символы.',
  },
  maxLength: {
    value: FOLDER_NAME_MAX_LENGTH,
    message: `Название папки может включать в себя не более ${FOLDER_NAME_MAX_LENGTH} символов.`,
  },
}

export { alertTextsMap, folderNameRulesMap }
