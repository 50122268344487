import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { SeventhStagesOldStepExpensesArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { stepOldExpenseValidationMap } from './validation'

interface OldStepExpenseItemProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  name: SeventhStagesOldStepExpensesArrayPathName
  index: number
  onRemove: () => Promise<void>
  leftAddons?: ReactNode
}

const OldStepExpenseItem: FC<OldStepExpenseItemProps> = ({
  editMode,
  blockViewIsValidating,
  index,
  name,
  formInstance,
  formModifierInstance,
  onRemove,
  leftAddons,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${name}.isNew`,
    control: formModifierInstance.control,
  })

  const { getFormFieldControlProps, getInputProps, getAmountInputProps } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  return (
    <GroupWithRemoveButton
      disableBottomBorder
      id={name}
      title={`Вид расходов №${index + 1}`}
      leftAddons={leftAddons}
      onRemove={onRemove}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.oldStepExpenseName`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.oldStepExpenseName`)
                    }, 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${name}.oldStepExpenseName`,
                  rules: stepOldExpenseValidationMap.oldStepExpenseName,
                  inputProps: {
                    label: 'Вид расходов',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.oldStepExpenseName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.oldStepExpenseName`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <FieldView.RowWithBottomBorder
          disableBottomDefaultStyles
          firstColumnSize={6}
          secondColumnSize={6}
          title={'Объем расходов'}
        >
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${name}.oldStepExpenseValue`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${name}.oldStepExpenseValue`)
                  }, 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${name}.oldStepExpenseValue`,
                rules: stepOldExpenseValidationMap.oldStepExpenseValue,
                onBlur: () =>
                  setTimeout(() => handleChangeValue?.(`${name}.oldStepExpenseValue`), 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${name}.oldStepExpenseValue`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </FieldView.RowWithBottomBorder>
      </Stack>
    </GroupWithRemoveButton>
  )
}

export default OldStepExpenseItem
