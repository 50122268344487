import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu } from '@components/DocumentFormComponents/types'

const szpksChangeTerminateMenuItemId = '5.1'

const dataOnFulfillmentOfTermsSZPKMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Сведения о документе',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'Уполномоченный орган субъекта Российской Федерации',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '3': {
    id: '3',
    path: '3',
    title: 'Общие сведения',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [
      {
        id: '3.1',
        path: '3.1',
        title: 'Общее количество СЗПК',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '3.2',
        path: '3.2',
        title: 'Общее количество СЗПК за отчетный период',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '3.3',
        path: '3.3',
        title: 'Объем осуществленных капитальных вложений',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '3.4',
        path: '3.4',
        title: 'Объем осуществленных капиталовложений',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '3.5',
        path: '3.5',
        title: 'Количество созданных рабочих мест',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '3.6',
        path: '3.6',
        title: 'Объем возмещенных затрат',
        parentBaseId: '3',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
    ],
  },
  '4': {
    id: '4',
    path: '4',
    title: 'Сведения о сферах реализации инвестиционных проектов',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '5': {
    id: '5',
    path: '5',
    title:
      'Сведения о выявленных по результатам мониторинга нарушениях и (или) основаниях для изменения либо расторжения соглашений',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [
      {
        id: szpksChangeTerminateMenuItemId,
        path: szpksChangeTerminateMenuItemId,
        title: 'Список СЗПК',
        parentBaseId: '5',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '5.2',
        path: '5.2',
        title:
          'Соглашения, по которым выявлены нарушения, стороной которых является Российская Федерация',
        parentBaseId: '5',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
      {
        id: '5.3',
        path: '5.3',
        title:
          'Соглашения, по которым выявлены нарушения, стороной которых не является Российская Федерация',
        parentBaseId: '5',
        isCollapsed: false,
        hasError: false,
        children: [],
      },
    ],
  },
}

const dataOnFulfillmentOfTermsSZPKMenuState = Object.values(dataOnFulfillmentOfTermsSZPKMenuHashMap)

const mapOfDataOnFulfillmentOfTermsSZPKMenu = dataOnFulfillmentOfTermsSZPKMenuState.map(
  (menu): MapOfMenu => ({
    id: menu.id,
    title: menu.title,
  }),
)

export {
  dataOnFulfillmentOfTermsSZPKMenuHashMap,
  dataOnFulfillmentOfTermsSZPKMenuState,
  mapOfDataOnFulfillmentOfTermsSZPKMenu,
  szpksChangeTerminateMenuItemId,
}
