import { useCallback } from 'react'
import { useParams } from 'react-router'

import { useAPIContext } from '@context/APIContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { useQueryManager } from '@hooks/useQueryManager'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import type {
  HandleAddFolderProps,
  HandleAddFolderReturnType,
  HandleRemoveFolderProps,
  HandleRemoveFolderReturnType,
  HandleRenameFolderProps,
} from './types'

const useAttachmentFolderApi = () => {
  const {
    documentsApi: { createFolder, deleteFolder, updateFolder },
  } = useAPIContext()

  const { projectId } = useParams()

  const { queryUtils } = useQueryManager()

  const dfoId = queryUtils.getQuery('dfoId') || ''

  const handleAddFolder = useCallback(
    async ({
      documentSetId,
      documentType,
      name,
      documentIds,
    }: HandleAddFolderProps): HandleAddFolderReturnType => {
      if (!projectId || !dfoId || !documentSetId || !documentType) return

      try {
        return await createFolder?.({
          projectId,
          dfoId,
          documentSetId,
          documentType,
          name,
          documentIds,
        })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useAttachmentFolderApi',
            componentType: 'handleAddFolder',
            moduleName: 'AttachmentAPIProvider',
          },
          additionInfo: {
            documentSetId,
            documentType,
            name,
          },
        })(error)

        handleErrorWithPopup(error, true)

        throw error
      }
    },
    [createFolder, dfoId, projectId],
  )

  const handleRenameFolder = useCallback(
    async ({ folderId, documentSetId, folderName }: HandleRenameFolderProps) => {
      if (!projectId || !dfoId || !documentSetId) return

      try {
        await updateFolder?.({
          projectId,
          dfoId,
          documentSetId,
          folderId,
          name: folderName,
        })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useAttachmentFolderApi',
            componentType: 'handleRenameFolder',
            moduleName: 'AttachmentAPIProvider',
          },
        })(error)

        handleErrorWithPopup(error, true)

        throw error
      }
    },
    [dfoId, projectId, updateFolder],
  )

  const handleRemoveFolder = useCallback(
    async ({ folderId, documentSetId }: HandleRemoveFolderProps): HandleRemoveFolderReturnType => {
      if (!projectId || !dfoId || !documentSetId) return

      try {
        return await deleteFolder?.({
          projectId,
          dfoId,
          documentSetId,
          folderId,
        })
      } catch (error) {
        LoggerHelpersService.handleMultipleLogError({
          componentInfo: {
            componentName: 'useAttachmentFolderApi',
            componentType: 'handleRemoveFolder',
            moduleName: 'AttachmentAPIProvider',
          },
          additionInfo: {
            documentSetId,
            folderId,
          },
        })(error)

        handleErrorWithPopup(error, true)

        throw error
      }
    },
    [deleteFolder, dfoId, projectId],
  )

  return {
    handleAddFolder,
    handleRemoveFolder,
    handleRenameFolder,
  }
}

export default useAttachmentFolderApi
