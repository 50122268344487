import { generatePropertiesObjectForStatementInvestments } from '@components/Forms/StatementAlreadyInvestments/adapters/PropertiesProps.adapter'
import { generateRHFObjectForStatementInvestments } from '@components/Forms/StatementAlreadyInvestments/adapters/RHF.adapter'
import { StatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/types'

const useStatementAlreadyInvestmentsAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): StatementAlreadyInvestmentsFormValues =>
    generateRHFObjectForStatementInvestments(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForStatementInvestments(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useStatementAlreadyInvestmentsAdapters }
