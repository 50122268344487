import React, { FC, memo } from 'react'
import { Path } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledInputSearchTree } from '@components/NewDesign/InputSearchTree/ControlledInputSearchTree'
import { NpaFormValues } from '@components/Sidebars/NPA/Edit'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import DeleteIcon from '@icons/DeleteIcon.svg'

import styles from './StabilizedPosition.module.scss'

interface StabilizedPositionProps {
  name: string
  control: Control<NpaFormValues, any>

  indexOfField: number
  onRemove: (index) => void
}

const StabilizedPosition: FC<StabilizedPositionProps> = ({
  control,
  name,
  indexOfField,
  onRemove,
}) => {
  const { partClassificationTreeAsOptions } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  return (
    <div className={styles.stabilizedPosition}>
      <ControlledInput
        name={`${name}.part` as Path<NpaFormValues>}
        control={control}
        rules={{
          required: defaultRHFValidation.required,
          validate: (value) => isString(value) && lengthValidate(value, 2000),
        }}
        inputProps={{
          label: 'Положение',
          fixWidth: true,
          view: 'secondary',
        }}
      />
      <ControlledInputSearchTree
        name={`${name}.classificationId` as Path<NpaFormValues>}
        control={control}
        rules={{
          required: defaultRHFValidation.required,
        }}
        searchProps={{
          zIndexInputSearchTree: 51,
          inputProps: {
            label: 'Класс стабилизируемого положения',
            view: 'secondary',
            clear: true,
          },
          tooltipProps: {
            zIndex: 52,
          },
          options: partClassificationTreeAsOptions || [],
        }}
      />
      <div>
        {!!indexOfField && (
          <Button
            size={'s'}
            color={'negative'}
            view={'plain'}
            leadingIcon={{
              noCurrentColorSvgFill: true,
              src: DeleteIcon,
            }}
            onClick={onRemove}
          >
            Удалить
          </Button>
        )}
      </div>
    </div>
  )
}

export default memo(StabilizedPosition)
