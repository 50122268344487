import React, { FC } from 'react'
import { Path, UseFormReturn, useWatch } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { extraRegisteredDocumentValueItemRegistrationCertificateValidationMap } from '@components/Forms/RegistrationCertificateForm/Forms/6/ExtraRegisteredDocumentValueItem/validation'
import { ExtraRegisteredDocumentValuesArrayPathName } from '@components/Forms/RegistrationCertificateForm/Forms/6/types'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

interface RegistrationCertificateExtraRegisteredDocumentValueItemProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formName: ExtraRegisteredDocumentValuesArrayPathName
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  subscribableControl: SubscribableFormControlProps<RegistrationCertificateFormValues>
  onChangeValue?: (
    pathName: Path<RegistrationCertificateFormValues>,
    batchBlockId?: string,
  ) => Promise<void>
  debouncedChangeValue?: (
    pathName: Path<RegistrationCertificateFormValues>,
    batchBlockId?: string,
  ) => void
  onPublicList?: (pathNames: string[], newIsPublic: boolean) => void
}

const RegistrationCertificateExtraRegisteredDocumentValueItem: FC<
  RegistrationCertificateExtraRegisteredDocumentValueItemProps
> = ({
  editMode,
  blockViewIsValidating,
  formInstance,
  formModifierInstance,
  formName,
  subscribableControl,
  onChangeValue,
  debouncedChangeValue,
  onPublicList,
}) => {
  const {
    getSubscribableControlProps,
    getControllerProps,
    getCalendarInputProps,
    getTextareaProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
    subscribableControl,
  })

  const extraTypeName = useWatch({
    control: formInstance.control,
    name: `${formName}.extraTypeName`,
  })

  return (
    <FieldView.ContainerWithPublic
      control={formModifierInstance.control}
      editMode={editMode}
      publicFormName={`${formName}.isPublic`}
      controlledFormNames={[
        `${formName}.extraTypeName`,
        `${formName}.extraRegistrationDate`,
        `${formName}.extraLastSignDate`,
        `${formName}.extraDescription`,
      ]}
      formIconWithTooltipProps={{
        tooltipContent: 'Данные будут отображены в публичной части реестра СЗПК',
      }}
      onPublic={onPublicList}
    >
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.extraRegistrationDate`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={extraTypeName}
              controllerProps={getControllerProps({
                name: `${formName}.extraRegistrationDate`,
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.extraLastSignDate`,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: `${formName}.extraLastSignDate`,
                calendarInputProps: {
                  disabled: true,
                  label: 'Дата подписания последней из сторон',
                },
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.extraDescription`,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: `${formName}.extraDescription`,
                rules:
                  extraRegisteredDocumentValueItemRegistrationCertificateValidationMap.extraDescription,
                textareaProps: {
                  autosize: true,
                  minRows: 1,
                  maxRows: Infinity,
                  label: 'Вид и предмет дополнительного соглашения',
                  leftAddons: (
                    <FormIconWithTooltip tooltipContent="Заполните для доп. соглашения, которое необходимо перенести в документ" />
                  ),
                },
                onBlur: () =>
                  setTimeout(() => {
                    onChangeValue?.(`${formName}.extraDescription`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedChangeValue?.(`${formName}.extraDescription`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
    </FieldView.ContainerWithPublic>
  )
}

export default RegistrationCertificateExtraRegisteredDocumentValueItem
