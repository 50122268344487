import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu, NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import cloneDeep from 'clone-deep'

interface GetAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatusReturnType {
  initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap: NestedMenuHashMap
  initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuState: NestedMapOfMenu[]
  mapOfMenu: MapOfMenu[]
}

const aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Уполномоченный федеральный орган исполнительной власти',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'Уполномоченный орган субъекта Российской Федерации',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '3': {
    id: '3',
    path: '3',
    title: 'Муниципальное образование',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '4': {
    id: '4',
    path: '4',
    title: 'Организация, реализующая проект',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '5': {
    id: '5',
    path: '5',
    title: 'Концессионное соглашение',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '6': {
    id: '6',
    path: '6',
    title: 'О проекте',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '7': {
    id: '7',
    path: '7',
    title: 'Этапы',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '8': {
    id: '8',
    path: '8',
    title: 'Иные мероприятия',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '9': {
    id: '9',
    path: '9',
    title: 'Результаты реализации проекта',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '10': {
    id: '10',
    path: '10',
    title: 'Технологические и экологические требования',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '11': {
    id: '11',
    path: '11',
    title: 'Стабилизационная оговорка',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '12': {
    id: '12',
    path: '12',
    title: 'Права и обязанности публично-правовых образований',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
}

const getAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatus = (
  isFederal: boolean,
  isMunicipalitiesParticipant: boolean,
): GetAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatusReturnType => {
  const initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap = cloneDeep(
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap,
  )

  const aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuState = [
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['1'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['2'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['3'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['4'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['5'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['6'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['7'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['8'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['9'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['10'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['11'],
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap['12'],
  ]

  const initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuState =
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuState.reduce<NestedMapOfMenu[]>(
      (previousValue, currentValue) => {
        if (!isFederal && currentValue.id === '1') return previousValue

        if (!isMunicipalitiesParticipant && currentValue.id === '3') return previousValue

        return [...previousValue, currentValue]
      },
      [],
    )

  const mapOfMenu = initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuState.map(
    (menu): MapOfMenu => ({
      id: menu.id,
      title: menu.title,
    }),
  )

  const {
    ['1']: firstSection,
    ['3']: thirdSection,
    ...changedMenuHashMap
  } = initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap

  return {
    initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap: {
      ...changedMenuHashMap,
      ...(isFederal && {
        '1': firstSection,
      }),
      ...(isMunicipalitiesParticipant && {
        '3': thirdSection,
      }),
    },
    initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuState,
    mapOfMenu,
  }
}

export { getAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatus }
