import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import CompensationFactObject from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Forms/3/AdditionalForms/CompensationFactObject'
import {
  CompensationArrayFactObjectPathName,
  CompensationArrayFactObjectsPathName,
} from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Forms/3/types'
import { useParametersApplicationReimbursementExpensesManager } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Manager'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import { ParametersApplicationReimbursementExpensesFieldArrayControlUpdateWatcher } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

interface CompensationFactObjectsProps {
  formInstance: UseFormReturn<ParametersApplicationReimbursementExpensesFormValues>
  name: CompensationArrayFactObjectsPathName
}

const CompensationFactObjects: FC<CompensationFactObjectsProps> = ({ formInstance, name }) => {
  const {
    state: { editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
  } = useParametersApplicationReimbursementExpensesManager()

  const { fields: compensationsFactObjects } =
    useFieldArraySubscribableControl<ParametersApplicationReimbursementExpensesFormValues>({
      name,
      control: formInstance?.control,
      keyName: 'keyNameId',
      watcher: ParametersApplicationReimbursementExpensesFieldArrayControlUpdateWatcher,
    })

  const handleAddCompensationFactObject = async () => {
    await handleAddItemToListWithOutValue?.(name)

    if (!compensationsFactObjects.length) {
      formInstance.clearErrors(name)
    }
  }

  const handleRemoveCompensationFactObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  return (
    <Group disableBottomBorder title={'Объекты'}>
      <Stack direction={'vertical'} gap={1}>
        <FlipperList list={compensationsFactObjects} gap={3}>
          {compensationsFactObjects.map((item, index) => {
            const formName = `${name}.${index}` as CompensationArrayFactObjectPathName

            return (
              <CompensationFactObject
                key={item.id}
                id={item.id}
                formInstance={formInstance}
                name={formName}
                compensationFactObjectIndex={index}
                onRemoveCompensationFactObject={handleRemoveCompensationFactObject(index)}
              />
            )
          })}
        </FlipperList>
        {editMode && (
          <Row>
            <Col xs={4}>
              <AsyncWrapper promise={handleAddCompensationFactObject}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить объект
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default CompensationFactObjects
