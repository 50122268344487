import React, { FC, memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultNpaSolicitationInclusionFormValues } from '@components/Forms/NpaSolicitationInclusionForm/const'
import NpaSolicitationInclusionFormLayout from '@components/Forms/NpaSolicitationInclusionForm/Layout/index'

const NpaSolicitationInclusionFormLayoutWithFormProvider: FC<NpaFormLayoutProps> = (props) => {
  return (
    <FormProviderWrapper defaultValues={defaultNpaSolicitationInclusionFormValues}>
      <NpaSolicitationInclusionFormLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(NpaSolicitationInclusionFormLayoutWithFormProvider)
