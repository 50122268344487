import React, { FC, memo, ReactNode, useEffect, useMemo } from 'react'

import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import type { HandleChangeMasterNpaItemCollapseStates } from '@components/RegistryNpa/ChooseOld/ManagerOld/types'
import rootStyles from '@components/RegistryNpa/MasterItem/MasterItem.module.scss'
import { objOfDateFormats } from '@constants/dateFormats'
import { isNullOrUndefined } from '@helpers/checkTypes'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import { useNpaTypes } from '@hooks/new/swr/useNpaTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import { IMasterNpaItem } from '@services/NPA/NPA.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import styles from './MasterNpaItemOld.module.scss'

interface MasterItemNPAOldProps {
  npa: IMasterNpaItem
  children: ReactNode
  initialCollapseState?: boolean
  onUpdateCollapseState?: HandleChangeMasterNpaItemCollapseStates
}

const MasterItemOld: FC<MasterItemNPAOldProps> = ({
  initialCollapseState,
  npa,
  children,
  onUpdateCollapseState,
}) => {
  const {
    booleanState: masterItemIsOpen,
    setBooleanState: setMasterItemState,
    reverseBooleanState: toggleMasterItem,
  } = useBooleanState(initialCollapseState)

  const {
    booleanState: isMountChildren,
    setBooleanStateToTrue: mountChildren,
    setBooleanStateToFalse: unmountChildren,
  } = useBooleanState(masterItemIsOpen)

  const { getCollapseProps } = useCollapse({
    isExpanded: masterItemIsOpen,
    onExpandStart: () => {
      mountChildren()
    },
    onCollapseEnd: () => {
      unmountChildren()
    },
  })

  const { mapOfNpaTypes } = useNpaTypes({
    key: {
      _key: 'npaTypes',
    },
  })

  const formattedNpaDate = useMemo(
    () => dayjs(npa.date).format(objOfDateFormats.defaultFormat),
    [npa.date],
  )

  const handleToggleMasterItem = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    toggleMasterItem()
    onUpdateCollapseState?.(npa.id, (prev) => !prev)
  }

  useUpdateEffect(() => {
    if (isNullOrUndefined(initialCollapseState)) return

    setMasterItemState(initialCollapseState)
    onUpdateCollapseState?.(npa.id, initialCollapseState)
  }, [initialCollapseState])

  useEffect(() => {
    onUpdateCollapseState?.(npa.id, !!initialCollapseState)
  }, [])

  return (
    <>
      <button
        type="button"
        data-testid={`MasterItem-npa-${npa.id}-itemButton`}
        className={cn(rootStyles.masterItem, styles.masterNpaItem)}
        onClick={handleToggleMasterItem}
      >
        <div className={rootStyles.masterItem__leftPart}>
          <IconButton
            className={rootStyles.masterItem__control}
            size={'s'}
            view={'basic'}
            dataTestId={`MasterItem-npa-${npa.id}-toggleCollapse-iconButton`}
            icon={{
              className: cn(rootStyles['masterItem__control-item'], {
                [rootStyles['masterItem__control--toggled']]: masterItemIsOpen,
              }),
              src: chevronRightIcon,
            }}
            onClick={handleToggleMasterItem}
          />

          <div className={rootStyles.masterItem__mainInfo}>
            <Typography.Caption variant={'captionMMedium'} color={'text-base-secondary'}>
              {mapOfNpaTypes?.[npa.type]} ・{' '}
              <Typography.Caption as={'span'} variant={'captionMMedium'}>
                {npa.number}
              </Typography.Caption>{' '}
              от {formattedNpaDate}
            </Typography.Caption>
            <Typography.Body variant={'bodyMMedium'}>{npa.name}</Typography.Body>
          </div>
        </div>
        <div className={rootStyles.masterItem__additionalInfo}>
          <Typography.Body as={'span'} variant={'bodyMRegular'}>
            Ред. № {npa.redactionNumber} от {formattedNpaDate}
          </Typography.Body>
        </div>
      </button>

      {npa.parts && (
        <div {...getCollapseProps()}>
          {isMountChildren && (
            <div
              className={cn({
                [rootStyles.masterItem__parts]: masterItemIsOpen,
              })}
            >
              {children}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default memo(MasterItemOld)
