import React, { FC, memo, useMemo } from 'react'

import ChipBase from '@components/Chip/Base'
import Icon from '@components/Icon/Icon'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import Indicator from '@components/NewDesign/Indicator'
import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import Regional from '@components/PreparedTooltips/Regional'
import Stack from '@components/ReactBootstrap/Stack'
import { Paths } from '@constants/paths'
import { useAPIContext } from '@context/APIContext'
import { Transition } from '@headlessui/react'
import { isBoolean } from '@helpers/checkTypes'
import { toggle } from '@helpers/commonHelpers'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useHover } from '@hooks/new/events/useHover'
import { useBooleanState } from '@hooks/useBooleanState'
import DownloadAttachmentIcon from '@icons/attachment/actions/DownloadAttachmentIcon.svg'
import dfoDigitizingIcon from '@icons/digitizing.svg'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import DocumentView from '@routes/Reports/Item/DocumentView'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import {
  EnumOfTypeReports,
  IReportEntity,
  mapOfTypeReports,
} from '@services/Monitoring/monitoring.entity'
import cn from 'classnames'

import { mapOfStage } from '../constants'

import styles from './ReportItem.module.scss'

const DFO_DIGITIZING_ICON_COLOR = '#FCFCFC' // on-accent-on-surface-primary

interface ReportItem {
  report: IReportEntity
  typeOfReport: EnumOfTypeReports
  isMER?: boolean
}

const ReportItem: FC<ReportItem> = ({ report, typeOfReport, isMER }) => {
  const {
    indicatorValue,
    isFederal,
    projectName,
    orgName,
    projectId,
    dfo: { stage, documentSets, digitizingInProcess, dfoId },
  } = report

  const {
    documentsApi: { downloadDocumentById, downloadDfoArchive },
  } = useAPIContext()

  const { booleanState: isToggled, setBooleanState: setIsToggled } = useBooleanState()

  const {
    booleanState: isMountChildren,
    setBooleanStateToTrue: mountChildren,
    setBooleanStateToFalse: unmountChildren,
  } = useBooleanState()

  const { getCollapseProps } = useCollapse({
    isExpanded: isToggled,
    onExpandStart: () => {
      mountChildren()
    },
    onCollapseEnd: () => {
      unmountChildren()
    },
  })

  const [hoverReportRef, isReportHovered] = useHover<HTMLButtonElement>()

  const reportUrl = `${Paths.Projects}/${projectId}?dfoId=${dfoId}`

  const handleFoldUnfold = (e) => {
    e.preventDefault()

    setIsToggled(toggle)
  }

  const handleDownloadDocument = async ({
    documentName,
    documentId,
    documentSetId,
    versionId,
    withSigned = false,
  }) => {
    try {
      if (!documentId || !documentSetId || !versionId || !documentName) return

      await downloadDocumentById(
        {
          withSigned,
          projectId: report.projectId || '',
          name: documentName,
          documentId,
          documentSetId,
          versionId,
        },
        'save',
      )
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ReportItem',
          moduleName: 'ReportsPage',
          componentType: 'downloadDocumentById',
        },
      })(error)
    }
  }

  const handleDownloadAllDfoDocuments = async (e) => {
    e.stopPropagation()

    try {
      await downloadDfoArchive({ dfoId: report.dfo.dfoId, projectId: report.projectId })
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ReportItem',
          moduleName: 'ReportsPage',
          componentType: 'downloadAllDfoDocuments',
        },
      })(error)
    }
  }

  const handleDownloadArchiveWithToast = (e) =>
    withDownloadToastPromise(handleDownloadAllDfoDocuments(e))

  const handleDownloadDocumentWithToast = (document) =>
    withDownloadToastPromise(handleDownloadDocument(document))

  const handleOpenReportProgect = () => {
    window.open(reportUrl)
  }

  const reportHaveDocuments = useMemo(() => {
    return documentSets.find((documentSet) => {
      return !!documentSet.documents?.length
    })
  }, [documentSets])

  const hideToRegionReports = typeOfReport !== mapOfTypeReports.region
  const indicatorValueRenderCondition =
    !!indicatorValue && isMER && typeOfReport === mapOfTypeReports.investor

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.wrapper}
        ref={hoverReportRef}
        onClick={handleFoldUnfold}
      >
        <IconButton
          size={'s'}
          view={'basic'}
          icon={{
            className: cn({ [styles.toggler__toggled]: isToggled }),
            src: chevronRightIcon,
          }}
        />

        <div className={styles.blockName}>
          {hideToRegionReports && !(isFederal ?? true) && <Regional />}
          <Typography.Body variant="bodyMMedium" className={cn(styles.name, styles.isRegional)}>
            {projectName}
          </Typography.Body>
        </div>

        {hideToRegionReports && (
          <Typography.Body variant="bodyMMedium" className={styles.organizationName}>
            {orgName}
          </Typography.Body>
        )}

        <Stack direction="horizontal" gap={2} className={styles.deadline}>
          <ChipBase
            disableClickableEffect
            size={'s'}
            label={mapOfStage[stage]?.label}
            priority={mapOfStage[stage]?.priority}
            variant={mapOfStage[stage]?.variant}
          />
          {isBoolean(digitizingInProcess) && digitizingInProcess && (
            <Tooltip
              position={'top'}
              offset={[0, 10]}
              content={
                <Typography.Caption color={'text-base-secondary'} variant={'captionMMedium'}>
                  Есть незаполненные формы
                </Typography.Caption>
              }
            >
              <Button
                textClassName={styles['deadline__digitizing-text']}
                className={cn(styles['deadline__digitizing'], {
                  [styles['deadline__digitizing--inProcess']]: digitizingInProcess,
                })}
                onClick={handleOpenReportProgect}
              >
                <Icon
                  src={dfoDigitizingIcon}
                  className={styles['deadline__digitizing-icon']}
                  size="initial"
                  color={DFO_DIGITIZING_ICON_COLOR}
                />
              </Button>
            </Tooltip>
          )}
        </Stack>

        {indicatorValueRenderCondition && <Indicator activeValue={indicatorValue} />}

        <div className={styles.actions__wrapper}>
          {reportHaveDocuments && (
            <Transition
              show={isReportHovered}
              enter={styles.transition__enter}
              enterFrom={styles.transition__enterFrom}
              enterTo={styles.transition__enterTo}
              leave={styles.transition__leave}
              leaveFrom={styles.transition__leaveFrom}
              leaveTo={styles.transition__leaveTo}
            >
              <div
                role={'presentation'}
                className={styles.actions}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <IconButton
                  geometry={'round'}
                  size={'s'}
                  icon={{
                    src: DownloadAttachmentIcon,
                  }}
                  onClick={handleDownloadArchiveWithToast}
                />
              </div>
            </Transition>
          )}
        </div>
      </button>
      <div {...getCollapseProps()}>
        {isMountChildren && (
          <div>
            <div className={styles.items}>
              {documentSets?.map((documentSet) => {
                return (
                  <div key={documentSet.documentSetId} className={styles.documentSet}>
                    {documentSet.documents?.map((document) => {
                      return (
                        <DocumentView
                          key={document.documentId}
                          ownerOrganizationName={documentSet.ownerOrganizationName}
                          document={document}
                          onDownloadDocument={(withSigned) => {
                            handleDownloadDocumentWithToast({
                              documentName: `${document.documentName}.${document.documentExt}`,
                              documentId: document.documentId,
                              documentSetId: documentSet.documentSetId,
                              versionId: document.versionId,
                              withSigned,
                            })
                          }}
                        />
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(ReportItem)
