import React from 'react'
import { useFormContext } from 'react-hook-form'

import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementForceCircumstancesBlockValues } from '@components/Forms/AAgreementForceCircumstancesForm/const'
import { useAAgreementForceCircumstancesManager } from '@components/Forms/AAgreementForceCircumstancesForm/Manager'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import { AAgreementForceCircumstancesFieldsControlUpdateWatcher } from '@components/Forms/AAgreementForceCircumstancesForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { seventhSectionAAgreementForceCircumstancesValidationMap } from './validation'

const Sixth = () => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { debouncedHandleChangeValue, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementForceCircumstancesManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementForceCircumstancesFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Group
          disableBottomBorder
          title={'Основанием для изменения сроков является возникновение следующих обстоятельств'}
        >
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aAgreementForceCircumstancesBlockValues['7'].forceMajeureName,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: aAgreementForceCircumstancesBlockValues['7'].forceMajeureName,
                    rules: seventhSectionAAgreementForceCircumstancesValidationMap.forceMajeureName,
                    textareaProps: {
                      maxRows: Infinity,
                      label: 'Обстоятельства',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['7'].forceMajeureName,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(
                        () =>
                          debouncedHandleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['7'].forceMajeureName,
                          ),
                        0,
                      ),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Group>
        <Group
          disableBottomBorder
          title={'Документы, подтверждающие наступление соответствующих обстоятельств'}
        >
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aAgreementForceCircumstancesBlockValues['7'].forceMajeureProof,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: aAgreementForceCircumstancesBlockValues['7'].forceMajeureProof,
                    rules:
                      seventhSectionAAgreementForceCircumstancesValidationMap.forceMajeureProof,
                    textareaProps: {
                      maxRows: Infinity,
                      label: 'Подтверждение наступления обстоятельств',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['7'].forceMajeureProof,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(
                        () =>
                          debouncedHandleChangeValue?.(
                            aAgreementForceCircumstancesBlockValues['7'].forceMajeureProof,
                          ),
                        0,
                      ),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Group>
      </Stack>
    </Container>
  )
}

export default Sixth
