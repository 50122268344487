import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementIncludeRelatedTermsBlockValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/const'
import { fourthSectionValidationMap } from '@components/Forms/AAgreementIncludeRelatedTermsForm/Forms/4/validation'
import { useAAgreementIncludeRelatedTermsManager } from '@components/Forms/AAgreementIncludeRelatedTermsForm/Manager'
import { AAgreementIncludeRelatedTermsFormValues } from '@components/Forms/AAgreementIncludeRelatedTermsForm/types'
import { AAgreementIncludeRelatedTermsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementIncludeRelatedTermsForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { DocumentsSetsType } from '@constants/types'
import { disableFutureDates } from '@helpers/date/disableOfDates'

const Fourth = () => {
  const formInstance = useFormContext<AAgreementIncludeRelatedTermsFormValues>()

  const {
    state: { blockViewIsValidating, editMode, documentsTypeOfSet },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementIncludeRelatedTermsManager()

  const {
    getSubscribableControlProps,
    getInputProps,
    getCalendarInputProps,
    getCheckboxProps,
    getSingleSelectProps,
    getTextareaProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementIncludeRelatedTermsFieldsControlUpdateWatcher,
  })

  const isRelatedListChanges =
    documentsTypeOfSet === DocumentsSetsType.RELATED_CONTRACT_LIST_CHANGES

  const handleCheckViolation = async (violationPath) => {
    await handleChangeValue?.(violationPath)
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractNumber,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractNumber,
                  rules: fourthSectionValidationMap.relatedContractNumber,
                  inputProps: {
                    label: 'Номер договора',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractNumber,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractNumber,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractDate,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractDate,
                  rules: fourthSectionValidationMap.relatedContractDate,
                  calendarInputProps: {
                    label: 'Дата заключения',
                  },
                  calendarProps: {
                    disabledDate: (date) => disableFutureDates(date),
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractDate,
                        ),
                      0,
                    ),
                  onCalendarChange: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractDate,
                        ),
                      0,
                    ),
                  onInputChange: () =>
                    debouncedHandleChangeValue?.(
                      aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractDate,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractTerms,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractTerms,
                  rules: fourthSectionValidationMap.relatedContractTerms,
                  textareaProps: {
                    label: 'Условия договора',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractTerms,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractTerms,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementIncludeRelatedTermsBlockValues['4'].relatedContractPublicLawMember,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.memberOrganizationId.memberFullName || '',
                        value: item.id,
                      }),
                      selectProps: {
                        inputProps: {
                          disabled: isRelatedListChanges || !editMode,
                          label: 'Согласующий орган',
                          caption: isRelatedListChanges
                            ? 'Согласующий орган нельзя изменить, поскольку он уже включен в процесс рассмотрения документов'
                            : '',
                          leftAddons: (
                            <FormIconWithTooltip
                              tooltipContent={
                                'Публично-правовое образование, являющееся стороной соглашения, за счет средств бюджета которого организации возмещается соответствующий реальный ущерб'
                              }
                            />
                          ),
                        },
                        onChangeFormValue: () => {
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementIncludeRelatedTermsBlockValues['4']
                                .relatedContractPublicLawMember,
                            )
                          }, 0)
                        },
                      },
                      controllerProps: {
                        name: aAgreementIncludeRelatedTermsBlockValues['4']
                          .relatedContractPublicLawMember,
                        rules: fourthSectionValidationMap.relatedContractPublicLawMember,
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementIncludeRelatedTermsBlockValues['4']
                  .relatedContractPublicLawMemberInstrumental,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: aAgreementIncludeRelatedTermsBlockValues['4']
                    .relatedContractPublicLawMemberInstrumental,
                  rules: fourthSectionValidationMap.relatedContractPublicLawMemberInstrumental,
                  textareaProps: {
                    disabled: isRelatedListChanges || !editMode,
                    label: 'Согласующий орган',
                    caption: 'в творительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          aAgreementIncludeRelatedTermsBlockValues['4']
                            .relatedContractPublicLawMemberInstrumental,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        aAgreementIncludeRelatedTermsBlockValues['4']
                          .relatedContractPublicLawMemberInstrumental,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementIncludeRelatedTermsBlockValues['4']
                  .agreementLinkDamageCompensations,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: aAgreementIncludeRelatedTermsBlockValues['4']
                    .agreementLinkDamageCompensations,
                  rules: fourthSectionValidationMap.agreementLinkDamageCompensations,
                  inputProps: {
                    label: 'Пункты соглашения',
                    leftAddons: (
                      <FormIconWithTooltip
                        tooltipContent={
                          'в соответствии с которыми публично-правовое образование обязуется возместить реальный ущерб'
                        }
                      />
                    ),
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          aAgreementIncludeRelatedTermsBlockValues['4']
                            .agreementLinkDamageCompensations,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        aAgreementIncludeRelatedTermsBlockValues['4']
                          .agreementLinkDamageCompensations,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group
          disableBottomBorder
          title={
            'Нарушения, при наличии которых публично-правовое образование будет обязано возместить ущерб'
          }
        >
          <AsyncWrapper promise={handleCheckViolation}>
            {({ isLoading: isViolationChecking, wrappedPromise: wrappedHandleCheckViolation }) => (
              <Stack direction={'vertical'} gap={3}>
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: aAgreementIncludeRelatedTermsBlockValues['4']
                          .isSubsidyInvestmentViolation,
                      })}
                    >
                      <ControlledCheckbox
                        {...getCheckboxProps({
                          name: aAgreementIncludeRelatedTermsBlockValues['4']
                            .isSubsidyInvestmentViolation,
                          checkBoxProps: {
                            disabled: isViolationChecking,
                            label:
                              'Нарушение сроков и (или) объемов предоставления субсидий, бюджетных инвестиций, предусмотренных договором',
                          },
                          onChange: () =>
                            setTimeout(
                              () =>
                                wrappedHandleCheckViolation(
                                  aAgreementIncludeRelatedTermsBlockValues['4']
                                    .isSubsidyInvestmentViolation,
                                ),
                              0,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: aAgreementIncludeRelatedTermsBlockValues['4']
                          .isSubsidyCreditViolation,
                      })}
                    >
                      <ControlledCheckbox
                        {...getCheckboxProps({
                          name: aAgreementIncludeRelatedTermsBlockValues['4']
                            .isSubsidyCreditViolation,
                          checkBoxProps: {
                            disabled: isViolationChecking,
                            label:
                              'Нарушение сроков и (или) объемов предоставления субсидий кредитору по договору',
                          },
                          onChange: () =>
                            setTimeout(
                              () =>
                                wrappedHandleCheckViolation(
                                  aAgreementIncludeRelatedTermsBlockValues['4']
                                    .isSubsidyCreditViolation,
                                ),
                              0,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: aAgreementIncludeRelatedTermsBlockValues['4']
                          .isLongTermParametersChanging,
                      })}
                    >
                      <ControlledCheckbox
                        {...getCheckboxProps({
                          name: aAgreementIncludeRelatedTermsBlockValues['4']
                            .isLongTermParametersChanging,
                          checkBoxProps: {
                            disabled: isViolationChecking,
                            label:
                              'Изменение значений долгосрочных параметров регулирования деятельности регулируемых организаций и (или) положений соглашений \n' +
                              'об условиях осуществления регулируемых видов деятельности, на основании которых были определены условия поставки товаров, выполнения работ или оказания услуг, приобретаемых (получаемых) организацией в соответствии с подпунктом "а" пункта 3 части 1 статьи 14 Федерального закона',
                          },
                          onChange: () =>
                            setTimeout(
                              () =>
                                wrappedHandleCheckViolation(
                                  aAgreementIncludeRelatedTermsBlockValues['4']
                                    .isLongTermParametersChanging,
                                ),
                              0,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              </Stack>
            )}
          </AsyncWrapper>
        </Group>
      </Stack>
    </Container>
  )
}

export default Fourth
