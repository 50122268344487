import { SyntheticEvent } from 'react'

import MaskedInput from '@components/NewDesign/MaskedInput'
import { SingleSelect } from '@components/NewDesign/Select'
import { useReorganizationForm } from '@hooks/new/swr/useReorganizationForm'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import { TStep } from '../model'

import styles from './styles.module.scss'

export const SpecifyingSZPKData: TStep = ({ values, onChange }) => {
  const { formOptionsList } = useReorganizationForm({
    key: 'reorganizationForm',
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'SpecifyingSZPKData',
          moduleName: 'GetRightsToSZPK',
        },
      }),
    },
  })

  const onChangeInnHandler = ({
    currentTarget: { value: newInn },
  }: SyntheticEvent<HTMLInputElement>) => {
    onChange('inn', newInn)
  }

  return (
    <>
      <MaskedInput
        fixWidth
        defaultValue={values.inn}
        view="secondary"
        label="ИНН"
        mask="9999999999"
        maskPlaceholder=""
        rootClassName={styles.Control}
        onChange={onChangeInnHandler}
      />
      <SingleSelect
        defaultSelected={values.formOfReorganization}
        options={formOptionsList}
        inputProps={{ label: 'Форма реорганизации', fixWidth: true, rootClassName: styles.Control }}
        popoverProps={{
          zIndex: 51,
        }}
        onChangeForm={(value) => onChange('formOfReorganization', value)}
      />
    </>
  )
}
