import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'

const updateSearchThreeById = (
  root: ReferenceBookItem[],
  id: string,
  updateFunction: (oldState: ReferenceBookItem) => ReferenceBookItem,
): ReferenceBookItem[] => {
  // Вспомогательная рекурсивная функция для поиска и обновления элемента
  // Вспомогательная рекурсивная функция для поиска и обновления элемента
  const updateHelper = (node: ReferenceBookItem): ReferenceBookItem => {
    if (node.id === id) {
      // Применяем функцию обновления, если нашли нужный id и возвращаем обновленный узел
      return updateFunction(node)
    }
    if (node.children) {
      // Обновляем дочерние элементы, если они есть
      node.children = node.children.map((child) => updateHelper(child))
    }
    return node // Возвращаем узел, так как он может содержать обновленные дочерние элементы
  }

  // Применяем updateHelper к каждому элементу в корневом массиве и возвращаем обновленный массив
  return root.map((node) => updateHelper(node))
}

export { updateSearchThreeById }
