import { isAxiosError, isJsError, isUndefined } from '@helpers/checkTypes'
import type { AxiosError } from 'axios'

import {
  errorTypeMap,
  STACK_TRACE_LINE_END,
  STACK_TRACE_LINE_START,
  STACK_TRACE_SEPARATOR,
} from './ErrorResolver.const'
import type { ErrorResolverParams } from './ErrorResolver.entity'

class ErrorResolver {
  status?: number
  type?: keyof typeof errorTypeMap
  title?: string
  description?: string
  traceId?: string
  stackTrace?: string
  additionInfo?: unknown

  constructor({ error, additionInfo }: ErrorResolverParams) {
    this.parseError({ error, additionInfo })
  }

  private parseError({ error, additionInfo }: ErrorResolverParams) {
    this.parseErrorAdditions(additionInfo)

    if (isAxiosError(error)) {
      return this.parseAxiosError(error)
    }

    if (isJsError(error)) {
      return this.parseJsError(error)
    }
  }

  private parseJsError(error: Error) {
    this.type = errorTypeMap.nativeJSError
    this.description = error.message
    this.stackTrace = error.stack
      ?.split(STACK_TRACE_SEPARATOR)
      .slice(STACK_TRACE_LINE_START, STACK_TRACE_LINE_END)
      .join(STACK_TRACE_SEPARATOR)
  }

  private parseAxiosError(axiosError: AxiosError) {
    const errorStatus = axiosError.response?.status
    const { detail, error, title, error_description, message, traceId } =
      axiosError.response?.data || {}

    this.status = errorStatus
    this.type = errorTypeMap.axiosError
    this.title = error || title
    this.description = error_description || detail || message
    this.traceId = traceId
  }

  private parseErrorAdditions(additionInfo: unknown) {
    if (isUndefined(additionInfo)) return

    this.additionInfo = additionInfo
  }
}

export default ErrorResolver
