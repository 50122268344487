import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const firstSectionValidationMap: FormValuesValidationSection<
  keyof InformationOfExecutionConditionsSZPKFormValues['1'],
  InformationOfExecutionConditionsSZPKFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { firstSectionValidationMap }
