import React, { FC } from 'react'
import { Path, UseFormReturn, useWatch } from 'react-hook-form'

import GroupWithPublic from '@components/DocumentFormComponents/GroupWithPublic'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldArrayControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import Typography from '@components/NewDesign/Typography'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import styles from './RegistrationCertificatePlannedTax.module.scss'

interface RegistrationCertificatePlannedTaxProps {
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  handleChangePublicValue?: (
    pathName: Path<RegistrationCertificateFormValues>,
    newIsPublic: boolean,
  ) => Promise<void>
}

const RegistrationCertificatePlannedTax: FC<RegistrationCertificatePlannedTaxProps> = ({
  formInstance,
  formModifierInstance,
  handleChangePublicValue,
}) => {
  const { fields: plannedTax } = useFieldArraySubscribableControl<
    RegistrationCertificateFormValues,
    typeof registrationCertificateBlockValues['5']['plannedTax']
  >({
    control: formInstance.control,
    name: registrationCertificateBlockValues['5'].plannedTax,
    watcher: RegistrationCertificateFieldArrayControlUpdateWatcher,
  })

  const generalPlannedTax = useWatch({
    control: formInstance.control,
    name: registrationCertificateBlockValues['5'].generalPlannedTax,
  })

  return (
    <GroupWithPublic
      disableBottomBorder
      name={registrationCertificateBlockValues['5'].plannedTax}
      control={formModifierInstance.control}
      title="Прогнозируемый объем налогов"
      onPublic={(_paths, value) =>
        handleChangePublicValue?.(registrationCertificateBlockValues['5'].plannedTax, value)
      }
    >
      <Stack direction="vertical" gap={2} className={styles.tax__stack}>
        <Stack direction="vertical" gap={1}>
          {plannedTax.map((tax) => {
            return (
              <Row key={tax.id} className={styles.tax__wrapper}>
                <Typography.Body variant={'bodyMMedium'} as={'div'} color="text-base-secondary">
                  {tax.taxYear} -{' '}
                  <Typography.Body variant={'bodyMMedium'} as={'span'}>
                    {tax.taxYearValue} ₽
                  </Typography.Body>
                </Typography.Body>
              </Row>
            )
          })}
        </Stack>
        <Row>
          <Typography.Body variant={'bodyMMedium'} as={'span'}>
            Всего: {generalPlannedTax} ₽
          </Typography.Body>
        </Row>
      </Stack>
    </GroupWithPublic>
  )
}

export default RegistrationCertificatePlannedTax
