import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NoticeOfProjectRefuseConcludeAA from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm'
import {
  defaultNoticeOfProjectRefuseConcludeAAFormValues,
  mapOfNoticeOfProjectRefuseConcludeAAMenu,
} from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/const'
import { NoticeOfProjectRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/types'
import { NoticeOfProjectRefuseConcludeAAFieldsControlUpdateWatcher } from '@components/Forms/NoticeOfProjectRefuseConcludeAAForm/watcher'
import Typography from '@components/NewDesign/Typography'

import NoticeOfProjectRefuseConcludeAALayoutWrapper from './Wrapper'

const NoticeOfProjectRefuseConcludeAALayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<NoticeOfProjectRefuseConcludeAAFormValues>({
    defaultValues: defaultNoticeOfProjectRefuseConcludeAAFormValues,
  })

  const handleOnClose = () => {
    NoticeOfProjectRefuseConcludeAAFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NoticeOfProjectRefuseConcludeAA.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfNoticeOfProjectRefuseConcludeAAMenu[0].id}>
            <NoticeOfProjectRefuseConcludeAALayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление об отказе в заключении дополнительного соглашения
                </Typography.Headline>
                <NoticeOfProjectRefuseConcludeAA.StatementForm className={styles.layout__form} />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <NoticeOfProjectRefuseConcludeAA.Menu className={styles.layout__menu} />
              </div>
            </NoticeOfProjectRefuseConcludeAALayoutWrapper>
          </FlatMenuManager>
        </NoticeOfProjectRefuseConcludeAA.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NoticeOfProjectRefuseConcludeAALayout)
