import { useCallback, useRef } from 'react'

import { useAPIContext } from '@context/APIContext'
import { ReferenceBooksDictionaryNameKeys } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { IExtendedFormUrlParams } from '@context/APIContext/types'
import { isArray, isNullOrUndefined, isObject } from '@helpers/checkTypes'
import { IOverridePropsFromClass, PropertyTypeEnum } from '@services/Properties/Properties.entity'
import cloneDeep from 'clone-deep'
import { unstable_serialize, useSWRConfig } from 'swr'

interface OverrideFetcherProps {
  fetcher: () => Promise<unknown>
  propertyId: string
  settings?: {
    prefetch: boolean
  }
  systemFlags?: {
    dataIsOld: boolean
  }
  lastDataFromFetcher: unknown | null
}

interface GetFetcherOptionsProps {
  fetchOptions: OverrideFetcherProps[]
  prefetchOptions: OverrideFetcherProps[]
}

interface SubscriberProps {
  id: string
  value: {
    updateProps: (prevOverrideProps: Record<string, unknown>) => Record<string, unknown>
    updateCachedProps: () => Record<string, unknown>
  }
}

const isFetcherProps = (props: unknown): props is OverrideFetcherProps => {
  return isObject(props) && 'fetcher' in props
}

const getFetcherProps = (props?: Record<string, unknown>) => {
  const overrideFetcherProps = isFetcherProps(props?.fetcherOptions)
    ? props?.fetcherOptions.lastDataFromFetcher
    : undefined

  if (
    isNullOrUndefined(overrideFetcherProps) ||
    !isArray(overrideFetcherProps) ||
    (isArray(overrideFetcherProps) && !overrideFetcherProps?.length)
  )
    return null

  return overrideFetcherProps
}

const useOverrideFormProps = ({ formId, projectId }: IExtendedFormUrlParams) => {
  const updateOverridePropsControlRef = useRef<Record<string, Record<string, unknown>>>({})

  const {
    propertiesApi: { getLookupData },
    referenceBooksApi: { getReferenceBooks },
    dictionariesApi: { getRegions },
  } = useAPIContext()

  const { cache } = useSWRConfig()

  const prefetchProps = useCallback(async (
    arrayOfFetcherOptions: OverrideFetcherProps[],
  ): Promise<OverrideFetcherProps[]> => {
    // Преобразуем массив объектов с промисами в массив промисов для использования в Promise.allSettled
    const promises = arrayOfFetcherOptions.map((item) => item.fetcher())

    const results = await Promise.allSettled(promises)

    return results.map((result, index) => {
      if (result.status === 'fulfilled') {
        return {
          ...arrayOfFetcherOptions[index],
          lastDataFromFetcher: result.value,
        }
      }

      return arrayOfFetcherOptions[index]
    })
  }, [])

  const getFetcherOptionsFromProps = useCallback(
    (
      arrayOfProps: IOverridePropsFromClass[],
      cachedProps: Record<string, Record<string, unknown>>,
      prefetchedIds?: string[],
    ): GetFetcherOptionsProps => {
      const readyOverrideFetcherProps = arrayOfProps
        .map((props) => {
          const isPrefetched = !!prefetchedIds?.some((id) => id === props.propertyId)

          //!TODO: Есть возможность сделать cache и брать из lastDataFromFetcher, но нужно делать зависимые свойства
          const dataIsOld = true

          if (
            props.type === PropertyTypeEnum.LOOKUP ||
            props.type === PropertyTypeEnum.FIXED_LOOKUP
          )
            return {
              fetcher: () => getLookupData({ projectId, formId, propertyId: props.propertyId }),
              propertyId: props.propertyId,
              settings: {
                prefetch: isPrefetched,
              },
              systemFlags: {
                dataIsOld,
              },
            }

          if (props.type === PropertyTypeEnum.CATALOG) {
            if (props.dictionaryName === 'regions') {
              return {
                fetcher: async () => {
                  const cachedRegionsKey = unstable_serialize({
                    type: PropertyTypeEnum.CATALOG,
                    dictionaryName: props.dictionaryName,
                  })

                  const cachedRegions = cache.get(cachedRegionsKey)

                  if (!cachedRegions) {
                    const regions = getRegions()
                    cache.set(cachedRegionsKey, regions)

                    return regions
                  }

                  return cachedRegions
                },
                propertyId: props.propertyId,
                settings: {
                  prefetch: isPrefetched,
                },
                systemFlags: {
                  dataIsOld,
                },
              }
            }

            return {
              fetcher: async () => {
                const catalogKey = unstable_serialize({
                  type: PropertyTypeEnum.CATALOG,
                  dictionaryName: props.dictionaryName,
                })

                const cachedCatalog = cache.get(catalogKey)

                if (!cachedCatalog) {
                  const catalog = await getReferenceBooks({
                    dictionaryName:
                      (props.dictionaryName as ReferenceBooksDictionaryNameKeys) || '',
                  })

                  cache.set(catalogKey, catalog)

                  return catalog
                }

                return cachedCatalog
              },
              propertyId: props.propertyId,
              settings: {
                prefetch: isPrefetched,
              },
              systemFlags: {
                dataIsOld,
              },
            }
          }

          if (props.type === PropertyTypeEnum.CUSTOM) {
            return {
              fetcher: async () => props.customProps,
              propertyId: props.propertyId,
              settings: {
                prefetch: true,
              },
              lastDataFromFetcher: (async () => props.customProps)(),
              systemFlags: {
                dataIsOld,
              },
            }
          }

          if (props.type === PropertyTypeEnum.PRECONFIGURED) {
            if (props.setup === 'regions') {
              return {
                fetcher: () => {
                  return {
                    regions: cache.get(
                      unstable_serialize({
                        _key: 'projectRegions',
                      }),
                    ),
                    filteredRegions: cache.get(
                      unstable_serialize({
                        projectId,
                        _key: 'projectRegions',
                      }),
                    ),
                  }
                },
                propertyId: props.propertyId,
                settings: {
                  prefetch: true,
                },
                systemFlags: {
                  dataIsOld,
                },
              }
            }
            return null
          }

          return null
        })
        .filter(Boolean) as OverrideFetcherProps[]

      const preparedPrefetchOptions = readyOverrideFetcherProps.filter(
        (fetcherProps) => fetcherProps.settings?.prefetch,
      )

      return {
        fetchOptions: readyOverrideFetcherProps,
        prefetchOptions: preparedPrefetchOptions,
      }
    },
    [projectId, formId, getLookupData, getReferenceBooks, getRegions, cache],
  )

  const getOverrideProps = useCallback((): Record<string, Record<string, unknown>> => {
    return updateOverridePropsControlRef.current
  }, [])

  const processOverrideProps = useCallback(
    async (arrayOfProps: IOverridePropsFromClass[], prefetchedIds?: string[]) => {
      const { fetchOptions, prefetchOptions } = getFetcherOptionsFromProps(
        arrayOfProps,
        updateOverridePropsControlRef.current,
        prefetchedIds,
      )

      const fetchOptionsWithPrefetch = await prefetchProps(prefetchOptions)

      const allProps = [...fetchOptions, ...fetchOptionsWithPrefetch]

      const readySubscriberProps: SubscriberProps[] = allProps.map((prop) => {
        const valueToReturn = {
          id: prop.propertyId,
          value: {
            updateProps: (prevOverrideProps: Record<string, unknown>) => ({
              ...prevOverrideProps,
              fetcherOptions: prop,
            }),
            updateCachedProps: () => ({
              fetcherOptions: prop,
            }),
          },
        }

        updateOverridePropsControlRef.current[prop.propertyId] = cloneDeep(
          valueToReturn.value.updateProps(updateOverridePropsControlRef.current[prop.propertyId]),
        )

        return valueToReturn
      })

      return readySubscriberProps
    },
    [getFetcherOptionsFromProps, prefetchProps],
  )

  return {
    updateOverridePropsControlRef,
    processOverrideProps,
    getOverrideProps,
  }
}

export type { OverrideFetcherProps, SubscriberProps }
export { getFetcherProps, isFetcherProps, useOverrideFormProps }
