import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import {
  aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues,
  mapOfAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenu,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'

const mapOfAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms: FormItemProps = {
  '1': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.First />,
  },
  '2': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Second />,
  },
  '3': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Third />,
  },
  '4': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Fourth />,
  },
  '5': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Fifth />,
  },
  '6': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Sixth />,
  },
  '7': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Seventh />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '8': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Eighth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '9': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Ninth />,
  },
  '10': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Tenth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '11': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Eleventh />,
  },
  '12': {
    node: <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms.Twelfth />,
  },
} as const

const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm: FC<
  FormModalStatementWithInitialMenuProps
> = ({ initialMenu, ...rest }) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { formIsLoading },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const isFederalSZPK = useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues.additionalFields
      .isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues.additionalFields
      .isMunicipalityParticipant,
    control: formInstance.control,
  })

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={initialMenu ?? mapOfAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenu}
      mapOfForms={currentForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm)
