import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesAAgreementAccessionMunicipalForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': PropertiesPropsBasicAdapters.getNewMunicipalityAdapter(objectFromPropertyState),
    '5': PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '6': {
      ruleLinkMunicipalNpa: {
        propertyId: objectFromPropertyState.ruleLinkMunicipalNpa?.propertyId,
        type: objectFromPropertyState.ruleLinkMunicipalNpa?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkMunicipalNpa?.lastUpdateDt,
      },
      extraMunicipalAccessionLinkCompensation: {
        propertyId: objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.propertyId,
        type: objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.type,
        lastUpdateDt: objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.lastUpdateDt,
      },
      extraMunicipalAccessionLinkStabilization: {
        propertyId: objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.propertyId,
        type: objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.type,
        lastUpdateDt:
          objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.lastUpdateDt,
      },
      isMunicipalityReadyToCompensate: {
        propertyId: objectFromPropertyState.isMunicipalityReadyToCompensate?.propertyId,
        type: objectFromPropertyState.isMunicipalityReadyToCompensate?.type,
        lastUpdateDt: objectFromPropertyState.isMunicipalityReadyToCompensate?.lastUpdateDt,
      },
      dateApproveMO: {
        propertyId: objectFromPropertyState.dateApproveMO?.propertyId,
        type: objectFromPropertyState.dateApproveMO?.type,
        lastUpdateDt: objectFromPropertyState.dateApproveMO?.lastUpdateDt,
      },
    },
    additionalFields: {
      szpkNumber: {
        propertyId: objectFromPropertyState.szpkNumber?.propertyId,
        type: objectFromPropertyState.szpkNumber?.type,
        lastUpdateDt: objectFromPropertyState.szpkNumber?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesAAgreementAccessionMunicipalForm }
