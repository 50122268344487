import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { ParametersOfCostRecoveryApplicationExpenseSubTypesNotExistArrayPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

import styles from '../ExpenseSubTypes.module.scss'

interface ParametersOfCostRecoveryApplicationExpenseSubTypesNotExistProps {
  name: ParametersOfCostRecoveryApplicationExpenseSubTypesNotExistArrayPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
  onAddExpense?: VoidFunction
}

const ParametersOfCostRecoveryApplicationExpenseSubTypesNotExist: FC<
  ParametersOfCostRecoveryApplicationExpenseSubTypesNotExistProps
> = ({ name, formInstance, onAddExpense }) => {
  const expenseSubTypeName = useWatch({
    name: `${name}.compensationStatementExpenseSubTypeName`,
    control: formInstance.control,
  })

  const handleAddExpense = async () => {
    await onAddExpense?.()
  }

  return (
    <Row className={'px-1'}>
      <Col xs={12}>
        <AsyncWrapper promise={handleAddExpense}>
          {({ isLoading, wrappedPromise }) => {
            return (
              <Button
                disabled={isLoading}
                className={styles.expenseSubTypeNotExist__button}
                textClassName={styles['expenseSubTypeNotExist__button-text']}
                leadingIcon={{ src: CircleAddIcon }}
                variant={'buttonSMedium'}
                size={'2xs'}
                view={'plain'}
                loaderProps={{
                  loading: isLoading,
                  placement: 'leading',
                  variant: 'lite',
                  wrapperClassName: styles['expenseSubTypeNotExist__button-loader'],
                }}
                onClick={wrappedPromise}
              >
                {`Внести затраты ${expenseSubTypeName}`}
              </Button>
            )
          }}
        </AsyncWrapper>
      </Col>
    </Row>
  )
}

export default ParametersOfCostRecoveryApplicationExpenseSubTypesNotExist
