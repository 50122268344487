import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'
import { defaultRHFValidation, lengthValidate, Patterns } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fifthSectionAgreementStabilizationNegotiationsValidationMap: FormValuesValidationSection<
  keyof AgreementStabilizationNegotiationsFormValues['5'],
  AgreementStabilizationNegotiationsFormValues
> = {
  newStabilizationPeriod: {
    validate: (value) =>
      Number(value) <= 20 || 'значение не должно превышать максимально допустимое (20)',
  },

  agreementLinkStabilizationPeriod: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  stabilizationPeriodExtend: {
    required: defaultRHFValidation.required,
    pattern: {
      value: Patterns.Capital,
      message: 'значение должно быть больше нуля',
    },
    validate: (value) =>
      Number(value) <= 20 || 'значение не должно превышать максимально допустимое (20)',
  },
  ruleLinkChangeStabilization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  reasonChangeStabilization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { fifthSectionAgreementStabilizationNegotiationsValidationMap }
