import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  ISzpkChangeTerminateFederalReport,
  SummaryReportOfMonitoringResultsFormValues,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const summaryReportOfMonitoringResultsSzpkChangeTerminateStepValidationMap: FormValuesValidationSection<
  keyof ISzpkChangeTerminateFederalReport,
  SummaryReportOfMonitoringResultsFormValues
> = {
  totalCapitalCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  investorCapitalCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  changeTerminateReason: {
    required: defaultRHFValidation.required,
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { summaryReportOfMonitoringResultsSzpkChangeTerminateStepValidationMap }
