import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/types'
import { lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const npaErrorValidationMap: FormValuesValidationSection<
  keyof NotificationOfIdentifiedNPAListErrorsFormValues['4']['npaErrors'][number],
  NotificationOfIdentifiedNPAListErrorsFormValues
> = {
  errorText: {
    validate: (value) => (isString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { npaErrorValidationMap }
