import { FC } from 'react'

import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import type { NotificationWithButton as INotificationWithButton } from '@components/NewNotifications/model'
import { Notification } from '@components/NewNotifications/Notification'
import notificationStyles from '@components/NewNotifications/Notifications.module.scss'
import styles from '@components/NewNotifications/SingleNotification.module.scss'
import { notificationLevelType } from '@constants/types'
import cn from 'classnames'

export const NotificationWithButton: FC<INotificationWithButton> = (props) => {
  const { isLoading, level, dataTestId, loaderDataTestId, buttonProps, onClick } = props

  const buttonColor = !level || level === notificationLevelType.INFO ? 'default' : 'accent'

  return (
    <button
      type="button"
      data-testid={dataTestId}
      className={styles.SingleNotification}
      onClick={onClick}
    >
      <Loader
        loading={isLoading}
        dataTestId={loaderDataTestId}
        wrapperClassName={styles.Loader}
        variant={'lite'}
      />
      <Notification {...props} />
      {buttonProps && (
        <Button
          size={'s'}
          view={'gray'}
          color={buttonColor}
          variant={'buttonSMedium'}
          fontWeight={'medium'}
          textClassName={styles.Button__text}
          dataTestId={buttonProps.dataTestId}
          className={cn(
            notificationStyles.Button,
            styles.Button,
            notificationStyles[`Button--${level || notificationLevelType.INFO}`],
            buttonProps.className,
          )}
          onClick={buttonProps.onClick}
        >
          {buttonProps.text}
        </Button>
      )}
    </button>
  )
}
