import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import MainTemplate from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate'
import { SeventhEventsOfStagesArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldCollapseControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'

interface EventItemProps {
  id: string
  indexOfMainBlock: number
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: SeventhEventsOfStagesArrayPathName
  initialAccordionState?: boolean
}

const EventItem: FC<EventItemProps> = ({
  initialAccordionState,
  id,
  indexOfMainBlock,
  formName,
  formInstance,
}) => {
  const anchorId = useWatch({
    name: `${formName}.anchorId`,
  })

  const tabName = useWatch({
    name: `${formName}.tabName`,
  })

  const isConstruction = useWatch({
    name: `${formName}.isConstruction`,
  })

  const isCreateRID = useWatch({
    name: `${formName}.isCreateRID`,
  })

  const isModernization = useWatch({
    name: `${formName}.isModernization`,
  })

  const isReconstruction = useWatch({
    name: `${formName}.isReconstruction`,
  })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name: formName,
    watcher: ProjectSZPKFieldCollapseControlUpdateWatcher,
    initialExpanded: initialAccordionState,
  })

  return (
    <Accordion
      id={id}
      anchorId={anchorId}
      title={tabName}
      isExpanded={isExpanded}
      initialAccordionState={initialAccordionState}
      onToggle={onToggleCollapse}
    >
      <MainTemplate
        parentId={anchorId}
        indexOfMainBlock={indexOfMainBlock}
        isRidRender={isCreateRID}
        control={formInstance.control}
        name={formName}
        isConstructionRender={isConstruction || isModernization || isReconstruction}
      />
    </Accordion>
  )
}

export default EventItem
