import { fileItemReducerActionMap } from '@components/Attachments/Attachment/FileItem/constants'
import { FileItemService } from '@components/Attachments/Attachment/FileItem/helpers'
import {
  FileItemAction,
  FileItemReducerActionCreators,
  FileItemReducerState,
} from '@components/Attachments/Attachment/FileItem/types'
import { defaultMessage } from '@constants/texts'
import { isAxiosError, isNull } from '@helpers/checkTypes'

const { convertRejectedFile } = FileItemService

const initialFileItemState: FileItemReducerState = {
  file: null,
  isDeletedFile: false,
  rejectedFile: null,
  fileSize: 0,
  errorMessage: null,
  isLoading: false,
}

const fileItemReducer = (
  state: FileItemReducerState,
  action: FileItemAction,
): FileItemReducerState => {
  const { type, payload } = action

  switch (type) {
    case fileItemReducerActionMap.SETUP_LOADING:
      return {
        ...state,
        isLoading: payload,
      }

    case fileItemReducerActionMap.SETUP_FILE:
      return {
        ...state,
        file: payload,
        fileSize: payload?.size || 0,
      }

    case fileItemReducerActionMap.SETUP_REJECTED_FILE:
      if (!payload) {
        return {
          ...state,
          rejectedFile: null,
          fileSize: 0,
        }
      }

      const rejectedFile = convertRejectedFile(payload)

      return {
        ...state,
        rejectedFile,
        fileSize: rejectedFile.file?.size || 0,
      }

    case fileItemReducerActionMap.SETUP_ERROR:
      if (isNull(payload)) {
        return {
          ...state,
          errorMessage: null,
        }
      }

      if (!isAxiosError(payload)) {
        return {
          ...state,
          errorMessage: defaultMessage,
        }
      }

      const { detail } = payload.response?.data || {}

      return {
        ...state,
        errorMessage: detail || defaultMessage,
      }

    default:
      return state
  }
}

const fileItemCreators: FileItemReducerActionCreators = {
  setLoading: (payload) => ({ type: fileItemReducerActionMap.SETUP_LOADING, payload }),
  setFile: (payload) => ({ type: fileItemReducerActionMap.SETUP_FILE, payload }),
  setRejectedFile: (payload) => ({ type: fileItemReducerActionMap.SETUP_REJECTED_FILE, payload }),
  setError: (payload) => ({ type: fileItemReducerActionMap.SETUP_ERROR, payload }),
}

export { fileItemCreators, fileItemReducer, initialFileItemState }
