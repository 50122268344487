import { useMemo } from 'react'

interface ObjectWithId {
  id: string
}

const useInitialCollapseStateList = <T extends ObjectWithId>(
  list?: T[],
  initState = false,
) => {
  const initialCollapseState = useMemo((): Record<string, boolean> => {
    if (!list || !list.length) return {}

    return list.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.id]: initState,
      }),
      {} as Record<string, boolean>,
    )
  }, [])

  return { initialCollapseState }
}

export { useInitialCollapseStateList }
