import { FC } from 'react'

import EmailField from '@components/Fields/Email'

interface FieldsProps extends FC {
  Email: typeof EmailField
}

const Fields: FieldsProps = () => {
  return null
}

Fields.Email = EmailField

export default Fields
