import React, { FC } from 'react'
import { Navigate } from 'react-router'

import { useTechnicalWork } from '@services/TechnicalWork/TechnicalWork.entity'

const WithTechnicalWork: FC = ({ children }) => {
  const { isRunning } = useTechnicalWork()

  return <>{isRunning ? <Navigate replace to="/" /> : children}</>
}

export default WithTechnicalWork
