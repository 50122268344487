import React, { FC } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { FormModalLayoutWrapperProps } from '@components/DocumentFormComponents/types'
import { useAAgreementIncludeRelatedTermsManager } from '@components/Forms/AAgreementIncludeRelatedTermsForm/Manager'

const AAgreementIncludeRelatedTermsLayoutWrapper: FC<FormModalLayoutWrapperProps> = ({
  children,
  ...rest
}) => {
  const {
    state: { lastUpdateDraftTime },
    actions: { handleFormClose },
  } = useAAgreementIncludeRelatedTermsManager()

  const handleClose = () => {
    handleFormClose?.()
  }

  return (
    <DocumentLayoutContent {...rest} draftIsSaved={!!lastUpdateDraftTime} onClose={handleClose}>
      {children}
    </DocumentLayoutContent>
  )
}

export default AAgreementIncludeRelatedTermsLayoutWrapper
