import { ErrorHeadersTexts, ErrorTypes } from '@components/Forms/LoginForm/constants'
import {
  handleForbiddenAuthError,
  handleInternalServerError,
  handleUnauthorizedError,
} from '@components/Forms/LoginForm/helpers'
import type {
  OrganizationsActionCreators,
  OrganizationsReducerAction,
  OrganizationsReducerState,
} from '@components/Forms/LoginForm/LoginByESIA/types'
import type { ErrorFromRequest } from '@components/Forms/LoginForm/types'
import { defaultMessageSupportWithLink } from '@constants/texts'
import { isNull } from '@helpers/checkTypes'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'

import {
  ESIA_ERROR_DESCRIPTION_TYPE,
  ESIAErrorsTexts,
  ESIAReducerComponentInfo,
  organizationReducerActionMap,
} from './constants'

const initialOrganizationsState: OrganizationsReducerState = {
  organizations: [],
  isLoading: false,
  isRedirectLoading: false,
  error: null,
}

const organizationsReducer = (
  state: OrganizationsReducerState,
  action: OrganizationsReducerAction,
) => {
  const { type } = action

  switch (type) {
    case organizationReducerActionMap.SETUP_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      }

    case organizationReducerActionMap.SETUP_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case organizationReducerActionMap.SETUP_REDIRECT_LOADING:
      return {
        ...state,
        isRedirectLoading: action.payload,
      }

    case organizationReducerActionMap.SETUP_NATIVE_ERROR:
      const nativeError = action.payload

      return {
        ...state,
        error: {
          header: ErrorHeadersTexts.errorMessage,
          body: nativeError.message || defaultMessageSupportWithLink,
        },
      }

    case organizationReducerActionMap.SETUP_ESIA_ERROR:
      return {
        ...state,
        error: ESIAErrorsTexts.access_denied,
      }

    case organizationReducerActionMap.SETUP_ERROR:
      if (isNull(action.payload)) {
        return {
          ...state,
          error: action.payload,
        }
      }

      const error = action.payload as AxiosError<ErrorFromRequest>
      const {
        error_description: errorDescription,
        detail: errorDetail,
        error: error_type,
      } = error?.response?.data ?? {}

      LoggerHelpersService.handleInternalLogError({
        componentInfo: ESIAReducerComponentInfo,
      })(error)

      const internalServerError = handleInternalServerError(error)

      if (internalServerError) {
        return {
          ...state,
          error: internalServerError,
        }
      }

      const unauthorizedError = handleUnauthorizedError(error)

      if (unauthorizedError) {
        return {
          ...state,
          error: unauthorizedError,
        }
      }

      const forbiddenError = handleForbiddenAuthError(error)

      if (forbiddenError) {
        return {
          ...state,
          error: forbiddenError,
        }
      }

      if (
        error_type === ErrorTypes.access_denied &&
        errorDescription === ESIA_ERROR_DESCRIPTION_TYPE
      ) {
        return {
          ...state,
          error: ESIAErrorsTexts.access_denied,
        }
      }

      if (error_type === ErrorTypes.temporarily_unavailable) {
        return {
          ...state,
          error: ESIAErrorsTexts.temporarily_unavailable,
        }
      }

      return {
        ...state,
        error: {
          header: ErrorHeadersTexts.accessIdDenied,
          body: errorDescription || errorDetail || defaultMessageSupportWithLink,
        },
      }

    case organizationReducerActionMap.SETUP_ARGUMENT_ERROR:
      return {
        ...state,
        error: {
          header: ErrorHeadersTexts.errorMessage,
          body: 'Недостаточно аргументов для выполнения запроса',
        },
      }

    default:
      return state
  }
}

const organizationsActionCreators: OrganizationsActionCreators = {
  setDefaultError: () => ({ type: organizationReducerActionMap.SETUP_ARGUMENT_ERROR }),
  setOrganizations: (payload) => ({
    type: organizationReducerActionMap.SETUP_ORGANIZATIONS,
    payload,
  }),
  setLoading: (payload) => ({ type: organizationReducerActionMap.SETUP_LOADING, payload }),
  setRedirectLoading: (payload) => ({
    type: organizationReducerActionMap.SETUP_REDIRECT_LOADING,
    payload,
  }),
  setError: (payload) => ({ type: organizationReducerActionMap.SETUP_ERROR, payload }),
  setESIAError: (payload) => ({ type: organizationReducerActionMap.SETUP_ESIA_ERROR, payload }),
  setNativeError: (payload) => ({ type: organizationReducerActionMap.SETUP_NATIVE_ERROR, payload }),
}

export { initialOrganizationsState, organizationsActionCreators, organizationsReducer }
