import {
  InfoAboutDocumentDefaultFormField,
  InitiatorDefaultFormField,
  InitiatorFullFormField,
  InvestorDefaultFormField,
  InvestorFullFormField,
  InvestorPartnerDefaultFormField,
  MunicipalityDefaultFormField,
  NewMunicipalityDefaultFormField,
  RfDefaultFormField,
  SubjectDefaultFormField,
  UODefaultFormField,
} from '@components/DocumentFormComponents/FieldState/FieldState.entity'
import { FormFieldStates } from '@components/DocumentFormComponents/types'

class FieldStateHelpers {
  static getDefaultRfFieldState(): RfDefaultFormField {
    return {
      rfFullName: '',
      rfHeaderName: null,
      rfHeaderNameGenitive: '',
      rfHeaderPosition: '',
      rfHeaderPositionGenitive: '',
      rfHeaderReasonGenitive: '',
    }
  }

  static getDefaultSubjectsFieldState(): SubjectDefaultFormField[] {
    return [
      {
        id: '1',
        subjectFullName: '',
        subjectRegion: '',
        subjectHeaderName: null,
        subjectHeaderNameGenitive: '',
        subjectHeaderPosition: '',
        subjectHeaderPositionGenitive: '',
        subjectHeaderReasonGenitive: '',
      },
    ]
  }

  static getDefaultMunicipalitiesFieldState(): MunicipalityDefaultFormField[] {
    return [
      {
        id: '1',
        municipalityFullName: '',
        municipalityFullNameGenitive: '',
        municipalityHeaderName: null,
        municipalityHeaderNameGenitive: '',
        municipalityHeaderPosition: '',
        municipalityHeaderPositionGenitive: '',
        municipalityHeaderReasonGenitive: '',
      },
    ]
  }

  static getDefaultNewMunicipalityFieldState(): NewMunicipalityDefaultFormField {
    return {
      municipalityFullName: '',
      municipalityFullNameGenitive: '',
      municipalityHeaderName: null,
      municipalityHeaderNameGenitive: '',
      municipalityHeaderPosition: '',
      municipalityHeaderPositionGenitive: '',
      municipalityHeaderReasonGenitive: '',
    }
  }

  static getDefaultInvestorFieldState(): InvestorDefaultFormField {
    return {
      investorFullName: '',
      investorHeaderName: null,
      investorHeaderNameGenitive: '',
      investorHeaderPosition: '',
      investorHeaderPositionGenitive: '',
      investorHeaderReasonGenitive: '',
    }
  }

  static getDefaultInvestorFullFieldState(): InvestorFullFormField {
    return {
      investorFullName: '',
      investorName: '',
      investorInn: '',
      investorKpp: '',
      investorOgrn: '',
      investorOkpo: '',
      investorOkved: '',
      investorHeaderName: null,
      investorHeaderNameGenitive: '',
      investorHeaderPosition: '',
      investorHeaderPositionGenitive: '',
      investorHeaderReasonGenitive: '',
      investorHeaderEmail: null,
      investorHeaderPhone: '',
      investorHeaderAddPhone: '',
    }
  }

  static getDefaultInvestorPartnerFieldStates(): InvestorPartnerDefaultFormField {
    return {
      investorPartnerFullName: '',
      investorPartnerHeaderName: null,
      investorPartnerHeaderNameGenitive: '',
      investorPartnerHeaderPosition: '',
      investorPartnerHeaderPositionGenitive: '',
      investorPartnerHeaderReasonGenitive: '',
    }
  }

  static getDefaultUOFieldState(): UODefaultFormField {
    return {
      uoFullName: '',
      uoHeaderName: null,
      uoHeaderNameGenitive: '',
      uoHeaderPosition: '',
      uoHeaderPositionGenitive: '',
      uoHeaderReasonGenitive: '',
    }
  }

  static getDefaultInitiatorFieldState(): InitiatorDefaultFormField {
    return {
      initiatorFullName: '',
      initiatorHeaderName: null,
      initiatorHeaderNameGenitive: '',
      initiatorHeaderPosition: '',
      initiatorHeaderPositionGenitive: '',
      initiatorHeaderReasonGenitive: '',
    }
  }

  static getFullInitiatorFieldState(): InitiatorFullFormField {
    return {
      initiatorFullName: '',
      initiatorInn: '',
      initiatorOgrn: '',
      initiatorKpp: '',
      initiatorOktmo: '',
      initiatorAddressEgrul: '',
      initiatorLocation: '',
      initiatorPaymentDetails: '',
      initiatorOtherDetails: '',
      initiatorHeaderName: null,
      initiatorHeaderNameGenitive: '',
      initiatorHeaderPosition: '',
      initiatorHeaderPositionGenitive: '',
      initiatorHeaderReasonGenitive: '',
    }
  }

  static getDefaultInfoAboutDocument(): InfoAboutDocumentDefaultFormField {
    return {
      documentDateSign: '',
      documentNumber: '',
      documentReceivers: '',
    }
  }

  static getDefaultFormFieldModifier(): FormFieldStates {
    return {
      id: '1',
      isPublic: false,
    }
  }
}

export { FieldStateHelpers }
