import { MutableRefObject, ReactElement, VFC } from 'react'
import Dropzone, { DropzoneProps, DropzoneState } from 'react-dropzone'

import FileDropzone from '@components/FileDropzone'
import { useDragAndDropHook } from '@components/FileDropzone/useDragAndDropHook'
import { MimeTypes } from '@constants/types'

type ChildrenWithState = (
  state: Omit<DropzoneState, 'getInputProps' | 'getRootProps'>,
) => ReactElement

interface WithUploadProps extends Omit<DropzoneProps, 'children'> {
  children: ChildrenWithState | ReactElement
  extensionTooltipTargetRef?: MutableRefObject<HTMLElement | null>
  isSecondaryUploader?: boolean
  initialRejectedFile?: File
}

const WithUpload: VFC<WithUploadProps> = ({
  children,
  accept = MimeTypes,
  isSecondaryUploader,
  initialRejectedFile,
  extensionTooltipTargetRef,
  ...props
}) => {
  const { handleRejectedFiles, rejectedFile } = useDragAndDropHook(initialRejectedFile)

  return (
    <Dropzone accept={accept} onDropRejected={handleRejectedFiles} {...props}>
      {({ getInputProps, getRootProps, ...state }) => (
        <>
          <div {...getRootProps({ tabIndex: -1 })} className="relative">
            {typeof children === 'function' ? children(state) : children}
            <input {...getInputProps()} />
            {rejectedFile && (
              <>
                {isSecondaryUploader ? (
                  <FileDropzone.ErrorSecondary
                    extensionTooltipTargetRef={extensionTooltipTargetRef}
                    rejectedFile={rejectedFile}
                    titleReject={'Недопустимое расширение файла'}
                    subTitleReject={'Перетащите файл'}
                    dropZoneProps={props}
                  />
                ) : (
                  <FileDropzone.ErrorPrimary
                    disableUploadButton
                    extensionTooltipTargetRef={extensionTooltipTargetRef}
                    rejectedFile={rejectedFile}
                    titleReject={'Недопустимое расширение файла'}
                    subTitleReject={'Перетащите файл'}
                    dropZoneProps={props}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </Dropzone>
  )
}

export default WithUpload
