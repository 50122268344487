import type { FC } from 'react'

import ReferenceBooksFlatGrid from '@components/ReferenceBooks/Flat/Grid'
import ReferenceBooksFlatItem from '@components/ReferenceBooks/Flat/Item'
import ReferenceBooksFlatManager from '@components/ReferenceBooks/Flat/Manager'
import ReferenceBooksFlatRoot from '@components/ReferenceBooks/Flat/Root'

interface ReferenceBooksFlatProps extends FC {
  Manager: typeof ReferenceBooksFlatManager
  Root: typeof ReferenceBooksFlatRoot
  Grid: typeof ReferenceBooksFlatGrid
  Item: typeof ReferenceBooksFlatItem
}

const ReferenceBooksFlat: ReferenceBooksFlatProps = () => null

ReferenceBooksFlat.Manager = ReferenceBooksFlatManager
ReferenceBooksFlat.Root = ReferenceBooksFlatRoot
ReferenceBooksFlat.Grid = ReferenceBooksFlatGrid
ReferenceBooksFlat.Item = ReferenceBooksFlatItem

export default ReferenceBooksFlat
