import { useCallback } from 'react'

import { useInView } from '@hooks/useInView'

interface UseLoadMoreDataProps {
  onLoadMore?: VoidFunction
}

const useLoadMore = ({ onLoadMore }: UseLoadMoreDataProps) => {
  const { observe } = useInView({
    threshold: 0.85,
    onEnter: ({ unobserve }) => {
      unobserve()
      onLoadMore?.()
    },
  })

  const getObserveRef = useCallback(
    (index: number, lastIndex: number) => {
      return index === lastIndex ? observe : undefined
    },
    [observe],
  )

  return {
    getObserveRef,
  }
}

export default useLoadMore
