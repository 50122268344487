import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { CompensationsCheckerArrayPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/8/types'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  InformationOfExecutionConditionsSZPKFieldCollapseControlUpdateWatcher,
  InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

import { compensationMonitoringCheckerValidationMap } from './validation'

interface CompensationCheckerItemProps {
  compensationId: string
  formInstance: UseFormReturn<InformationOfExecutionConditionsSZPKFormValues>
  formName: CompensationsCheckerArrayPathName
  initialAccordionState?: boolean
}

const CompensationCheckerItem: FC<CompensationCheckerItemProps> = ({
  compensationId,
  formInstance,
  formName,
  initialAccordionState,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  const { anchorId, compensationYear } = useWatch({
    name: formName,
    control: formInstance.control,
  })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name: formName,
    watcher: InformationOfExecutionConditionsSZPKFieldCollapseControlUpdateWatcher,
    initialExpanded: initialAccordionState,
  })

  return (
    <Accordion
      id={compensationId}
      anchorId={anchorId}
      isExpanded={isExpanded}
      title={`Год уплаты налогов - ${compensationYear}`}
      initialAccordionState={initialAccordionState}
      onToggle={onToggleCollapse}
    >
      <Container>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            titleHeightFallback={'40px'}
            title={'Налог на прибыль организаций (федеральный бюджет)'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.incomeTaxFederal`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.incomeTaxFederal`,
                  rules: compensationMonitoringCheckerValidationMap.incomeTaxFederal,
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.incomeTaxFederal`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.incomeTaxFederal`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            titleHeightFallback={'40px'}
            title={'Налог на прибыль организаций (бюджет субъекта РФ)'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.incomeTaxRegional`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.incomeTaxRegional`,
                  rules: compensationMonitoringCheckerValidationMap.incomeTaxRegional,
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.incomeTaxRegional`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.incomeTaxRegional`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Налог на имущество организаций'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.propertyTax`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.propertyTax`,
                  rules: compensationMonitoringCheckerValidationMap.propertyTax,
                  onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.propertyTax`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.propertyTax`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Земельный налог'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.landTax`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.landTax`,
                  rules: compensationMonitoringCheckerValidationMap.landTax,
                  onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.landTax`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.landTax`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Налог на добавленную стоимость (за вычетом возмещенного налога)'}
            titleHeightFallback={'40px'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.vatTax`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.vatTax`,
                  rules: compensationMonitoringCheckerValidationMap.vatTax,
                  onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.vatTax`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.vatTax`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            title={'Ввозные таможенные пошлины'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.customDuty`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.customDuty`,
                  rules: compensationMonitoringCheckerValidationMap.customDuty,
                  onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.customDuty`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.customDuty`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableTopPadding
            disableBottomDefaultStyles
            title={'Общий объем уплаченных налогов и иных платежей в бюджетную систему РФ'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.generalTaxValue`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.generalTaxValue`,
                  inputProps: { disabled: true },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
        </Stack>
      </Container>
    </Accordion>
  )
}

export default CompensationCheckerItem
