import React from 'react'
import { useFormContext } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { applicationConclusionAdditionalAgreementFormBlockValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/const'
import { oneSectionValidationMap } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/Forms/1/validation'
import { useApplicationConclusionAdditionalAgreementFormManager } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/Manager'
import { ApplicationConclusionAdditionalAgreementFormFormValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/types'
import { ApplicationConclusionAdditionalAgreementFormFieldsControlUpdateWatcher } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const First = () => {
  const formInstance = useFormContext<ApplicationConclusionAdditionalAgreementFormFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useApplicationConclusionAdditionalAgreementFormManager()

  const { getSubscribableControlProps, getControllerProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ApplicationConclusionAdditionalAgreementFormFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: applicationConclusionAdditionalAgreementFormBlockValues['1']
                  .initiatorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
                controllerProps={getControllerProps({
                  name: applicationConclusionAdditionalAgreementFormBlockValues['1']
                    .initiatorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: applicationConclusionAdditionalAgreementFormBlockValues['1'].initiatorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: applicationConclusionAdditionalAgreementFormBlockValues['1'].initiatorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: applicationConclusionAdditionalAgreementFormBlockValues['1'].initiatorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: applicationConclusionAdditionalAgreementFormBlockValues['1'].initiatorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: applicationConclusionAdditionalAgreementFormBlockValues['1'].initiatorAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Юридический адрес'}
                controllerProps={getControllerProps({
                  name: applicationConclusionAdditionalAgreementFormBlockValues['1']
                    .initiatorAddress,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное лицо организации'}
          formInstance={formInstance}
          formSection={'1'}
          prefixFormFieldName={'initiator'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: applicationConclusionAdditionalAgreementFormBlockValues['1']
                .initiatorHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: applicationConclusionAdditionalAgreementFormBlockValues['1']
                        .initiatorHeaderName,
                      rules: oneSectionValidationMap.initiatorHeaderName,
                    },
                    selectProps: {
                      inputProps: {
                        dataTestId: 'initiatorFio',
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            applicationConclusionAdditionalAgreementFormBlockValues['1']
                              .initiatorHeaderName,
                          )
                        }, 0),
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
      </Stack>
    </Container>
  )
}
export default First
