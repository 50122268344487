import { isLoginByLP } from '@constants/system'
import { useAuthContext } from '@context/AuthContext'
import { emailVerifiedControl } from '@layouts/Header/Message/constants'

import useOrganizationUser from './new/swr/useOrganizationUser'

const useAuthUser = () => {
  const { userInfoState } = useAuthContext()
  const { sub: userId } = userInfoState ?? {}

  const {
    organizationUser: currentUserFullInfo,
    error,
    isLoadingOrganizationUser,
    mutate,
  } = useOrganizationUser({
    key: {
      id: userId,
      _key: 'organizationUser',
    },
    config: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      isPaused: () => isLoginByLP || !userId,
    },
  })

  const email = currentUserFullInfo?.email

  const isEmailVerified = currentUserFullInfo?.emailVerified

  const isEmailApproved = isEmailVerified && emailVerifiedControl.get()

  return {
    isLoadingOrganizationUser,
    currentUserFullInfo,
    email,
    isEmailVerified,
    isEmailApproved,
    error,
    mutate,
  }
}

export default useAuthUser
