import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfIdentifiedViolationsBlockValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/const'
import { fourthSectionValidationMap } from '@components/Forms/NotificationOfIdentifiedViolationsForm/Forms/4/validation'
import { useNotificationOfIdentifiedViolationsManager } from '@components/Forms/NotificationOfIdentifiedViolationsForm/Manager'
import { NotificationOfIdentifiedViolationsFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/types'
import { NotificationOfIdentifiedViolationsFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfIdentifiedViolationsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<NotificationOfIdentifiedViolationsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedViolationsManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getTextareaProps,
    getAmountInputProps,
    getInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NotificationOfIdentifiedViolationsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={4}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsBlockValues['4'].projectName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование проекта'}
                controllerProps={getControllerProps({
                  name: notificationOfIdentifiedViolationsBlockValues['4'].projectName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункт Правил, на основании которого направляется уведомление о выявленных нарушениях 69-ФЗ'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4'].ruleLinkStatementReturn,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4']
                      .ruleLinkStatementReturn,
                    rules: fourthSectionValidationMap.ruleLinkStatementReturn,
                    inputProps: {
                      dataTestId: 'ruleLinkStatementReturn',
                      caption: 'рекомендация: 26',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4']
                              .ruleLinkStatementReturn,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4']
                            .ruleLinkStatementReturn,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Технические ошибки'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4'].statementTechError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4'].statementTechError,
                    rules: fourthSectionValidationMap.statementTechError,

                    textareaProps: {
                      maxRows: Infinity,
                      dataTestId: 'statementTechError',
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4'].statementTechError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4'].statementTechError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Некомплект представленных документов и материалов'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4']
                    .statementSetDocumentError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4']
                      .statementSetDocumentError,
                    rules: fourthSectionValidationMap.statementSetDocumentError,

                    textareaProps: {
                      dataTestId: 'statementSetDocumentError',
                      maxRows: Infinity,
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4']
                              .statementSetDocumentError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4']
                            .statementSetDocumentError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Несоответствие документов требованиям'}>
          <Stack direction={'vertical'} gap={4}>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={'Документ и (или) материалы не соответствуют требованиям пункта Правил'}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationOfIdentifiedViolationsBlockValues['4']
                        .statementDocumentError,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: notificationOfIdentifiedViolationsBlockValues['4']
                          .statementDocumentError,
                        rules: fourthSectionValidationMap.statementDocumentError,

                        textareaProps: {
                          dataTestId: 'statementDocumentError',
                          maxRows: Infinity,
                          caption: 'не обязательно',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationOfIdentifiedViolationsBlockValues['4']
                                  .statementDocumentError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationOfIdentifiedViolationsBlockValues['4']
                                .statementDocumentError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder firstColumnSize={6} secondColumnSize={6} title={'Пункт Правил'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationOfIdentifiedViolationsBlockValues['4']
                        .ruleLinkDocumentError,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: notificationOfIdentifiedViolationsBlockValues['4']
                          .ruleLinkDocumentError,
                        rules: fourthSectionValidationMap.ruleLinkDocumentError,
                        inputProps: {
                          caption: 'рекомендация: 11',
                          dataTestId: 'ruleLinkDocumentError',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationOfIdentifiedViolationsBlockValues['4']
                                  .ruleLinkDocumentError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationOfIdentifiedViolationsBlockValues['4']
                                .ruleLinkDocumentError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={
                'У УЛ заявителя отсутствуют полномочия действовать от имени и в интересах заявителя в связи с заключением соглашения'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4'].statementHeadError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4'].statementHeadError,
                    rules: fourthSectionValidationMap.statementHeadError,
                    textareaProps: {
                      maxRows: Infinity,
                      dataTestId: 'statementHeadError',
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4'].statementHeadError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4'].statementHeadError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Заявитель не соответствует требованиям, установленным пунктом 5 Правил'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4'].statementInvestorError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4'].statementInvestorError,
                    rules: fourthSectionValidationMap.statementInvestorError,
                    textareaProps: {
                      dataTestId: 'statementInvestorError',
                      maxRows: Infinity,
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4']
                              .statementInvestorError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4'].statementInvestorError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={
                'В учредительных документах заявителя, являющегося проектной компанией, отсутствуют положения о том, что предметом его деятельности является реализация инвестиционного проекта'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4']
                    .statementProjectCompanyError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4']
                      .statementProjectCompanyError,
                    rules: fourthSectionValidationMap.statementProjectCompanyError,
                    textareaProps: {
                      maxRows: Infinity,
                      dataTestId: 'statementProjectCompanyError',
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4']
                              .statementProjectCompanyError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4']
                            .statementProjectCompanyError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={
                'Инвестиционный проект не соответствует требованиям, установленным пунктом 6 Правил'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4'].statementProjectError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4'].statementProjectError,
                    rules: fourthSectionValidationMap.statementProjectError,
                    textareaProps: {
                      maxRows: Infinity,
                      dataTestId: 'statementProjectError',
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4']
                              .statementProjectError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4'].statementProjectError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={
                'Несоответствие списка актов (решений), предусмотренного подпунктом "и" пункта 11 Правил, требованиям статьи 9 ФЗ "О защите и поощрении капиталовложений в Российской Федерации"'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4'].statementNpaError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4'].statementNpaError,
                    rules: fourthSectionValidationMap.statementNpaError,
                    textareaProps: {
                      maxRows: Infinity,
                      dataTestId: 'statementNpaError',
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4'].statementNpaError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4'].statementNpaError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder firstColumnSize={6} secondColumnSize={6} title={'Иные нарушения'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsBlockValues['4'].statementOtherError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsBlockValues['4'].statementOtherError,
                    rules: fourthSectionValidationMap.statementOtherError,
                    textareaProps: {
                      maxRows: Infinity,
                      dataTestId: 'statementOtherError',
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsBlockValues['4'].statementOtherError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsBlockValues['4'].statementOtherError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsBlockValues['4'].statementOtherDetails,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: notificationOfIdentifiedViolationsBlockValues['4'].statementOtherDetails,
                  rules: fourthSectionValidationMap.statementOtherDetails,
                  textareaProps: {
                    label: 'Дополнительная информация для ОРП	',
                    caption: 'не обязательно',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(notificationOfIdentifiedViolationsBlockValues['4'].statementOtherDetails)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(notificationOfIdentifiedViolationsBlockValues['4'].statementOtherDetails)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <RowWithBorder
          disableBottomDefaultStyles
          title={'Дней на устранение нарушений'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: notificationOfIdentifiedViolationsBlockValues['4'].statementDaysRevision,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: notificationOfIdentifiedViolationsBlockValues['4'].statementDaysRevision,
                rules: fourthSectionValidationMap.statementDaysRevision,
                inputProps: {
                  dataTestId: 'statementDaysRevision',
                  integersOnly: true,
                  offAmountSplitting: true,
                  hasNegativeValue: false,
                  caption: ' рекомендация: 10',
                  placeholder: '',
                  suffix: '',
                },
                onBlur: () =>
                  setTimeout(
                    () =>
                      handleChangeValue?.(
                        notificationOfIdentifiedViolationsBlockValues['4'].statementDaysRevision,
                      ),
                    0,
                  ),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      notificationOfIdentifiedViolationsBlockValues['4'].statementDaysRevision,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </RowWithBorder>
      </Stack>
    </Container>
  )
}

export default Fourth
