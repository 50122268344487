import { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'

interface RegistrationCertificateSubjectItemProps {
  indexOfItem: number
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
}

const RegistrationCertificateSubjectItem: FC<RegistrationCertificateSubjectItemProps> = ({
  indexOfItem,
  formInstance,
  formModifierInstance,
}) => {
  const formName = `${registrationCertificateBlockValues['4'].subjects}.${indexOfItem}` as const

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
    handlers: { handleChangePublicValueType },
  } = useRegistrationCertificateManager()

  const { getControllerProps, getFormFieldControlProps } = useFormComponentPresets({
    isNew: true,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
  })

  const subjectRegion = useWatch({
    name: `${formName}.subjectRegion`,
    control: formInstance.control,
  })

  return (
    <FieldView.RowWithBottomBorder
      disableBottomDefaultStyles
      firstColumnSize={6}
      secondColumnSize={6}
      title={subjectRegion}
    >
      <FormFieldControl
        {...getFormFieldControlProps({
          path: `${formName}.subjectFullName`,
          formFieldPublicProps: {
            control: formModifierInstance.control,
            onPublic: (value) =>
              handleChangePublicValueType?.(`${formName}.subjectFullName`, value, 'memberFullName'),
          },
        })}
      >
        <ControlledDocumentDataView
          suptitle="Уполномоченный орган субъекта РФ"
          controllerProps={getControllerProps({
            name: `${formName}.subjectFullName`,
          })}
        />
      </FormFieldControl>
    </FieldView.RowWithBottomBorder>
  )
}

export default RegistrationCertificateSubjectItem
