import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo } from 'react'
import { ReactSVG } from 'react-svg'

import { IconStatusProps } from '@components/IconStatus'
import Typography from '@components/NewDesign/Typography'
import {
  colorVariableNameBySimpleName,
  ConfigColors,
  ConfigIcons,
  iconByName,
} from '@constants/config'
import cn from 'classnames'

import styles from './ItemExist.module.scss'

export interface DocumentSetItemProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isActive: boolean
  title: string
  subtitle?: string
  caption?: string
  dataTestId?: string
  config?: { icon: ConfigIcons; color: ConfigColors }
  iconStatusProps?: IconStatusProps
}

const DocumentSetItemExist: FC<DocumentSetItemProps> = ({
  type = 'button',
  title,
  caption,
  subtitle,
  iconStatusProps,
  isActive,
  config,
  dataTestId,
  ...restProps
}) => {
  return (
    <button
      type={type}
      {...restProps}
      data-testid={dataTestId}
      className={cn(
        styles.documentItemExist,
        { [styles['documentItemExist--isActive']]: isActive },
        restProps.className,
      )}
    >
      <div className={styles.documentItemExist__info}>
        <Typography.Body variant={'bodyMMedium'}>{title}</Typography.Body>
        {subtitle && (
          <Typography.Caption variant={'captionSRegular'}>{subtitle}</Typography.Caption>
        )}
        {caption && (
          <Typography.Caption
            className={styles.documentItemExist__caption}
            color={(config && colorVariableNameBySimpleName[config?.color]) || 'text-accent-brand'}
            variant={'captionSRegular'}
          >
            {caption}
          </Typography.Caption>
        )}
      </div>
      {iconStatusProps && (
        <div
          className={cn(
            styles.documentItemExist__icon,
            {
              [styles[`documentItemExist__icon_${config?.color || 'green'}`]]:
                config?.icon !== 'logo',
            },
            {
              [styles[`documentItemExist__icon-logo--${config?.color || 'green'}`]]:
                config?.icon === 'logo',
            },
          )}
        >
          <ReactSVG
            src={iconByName[config?.icon || 'add']}
            className={styles.documentItemExist__svg}
          />
        </div>
      )}
    </button>
  )
}

export default memo(DocumentSetItemExist)
