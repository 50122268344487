import { isNull } from '@helpers/checkTypes'

import { cryptReducerActionMap } from './constants'
import type { CryptActionCreators, CryptReducerAction, CryptReducerState } from './types'

const initialCryptState: CryptReducerState = {
  isProcuration: false,
  isShowLogoError: false,
  procurationList: [],
  signature: '',
}

const cryptReducer = (state: CryptReducerState, action: CryptReducerAction): CryptReducerState => {
  const { type } = action

  switch (type) {
    case cryptReducerActionMap.SETUP_IS_PROCURATION:
      return {
        ...state,
        isProcuration: action.payload,
      }

    case cryptReducerActionMap.SETUP_LOGO_ERROR:
      return {
        ...state,
        isShowLogoError: !isNull(action.payload),
      }

    case cryptReducerActionMap.SETUP_PROCURATION_LIST:
      return {
        ...state,
        procurationList: action.payload,
      }

    case cryptReducerActionMap.SETUP_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
      }

    default:
      return state
  }
}

const cryptActionCreators: CryptActionCreators = {
  setProcuration: (payload) => ({ type: cryptReducerActionMap.SETUP_IS_PROCURATION, payload }),
  setShowLogoError: (payload) => ({ type: cryptReducerActionMap.SETUP_LOGO_ERROR, payload }),
  setProcurationList: (payload) => ({
    type: cryptReducerActionMap.SETUP_PROCURATION_LIST,
    payload,
  }),
  setSignature: (payload) => ({ type: cryptReducerActionMap.SETUP_SIGNATURE, payload }),
}

export { cryptActionCreators, cryptReducer, initialCryptState }
