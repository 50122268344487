import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'

const { getDefaultInfoAboutDocument, getDefaultInitiatorFieldState } = FieldStateHelpers

const mapOfSummaryReportOfMonitoringResultsMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченный орган Российской Федерации' },
  { id: '3', title: 'Общие сведения' },
  { id: '4', title: 'Сведения о сферах реализации инвестиционных проектов' },
  {
    id: '5',
    title:
      'Сведения о территории реализации инвестиционных проектов, в отношении которых заключены соглашения',
  },
  {
    id: '6',
    title:
      'Сведения о выявленных по результатам мониторинга нарушениях и (или) основаниях для изменения либо расторжения соглашений',
  },
]

const defaultSummaryReportOfMonitoringResultsValues: SummaryReportOfMonitoringResultsFormValues = {
  '1': {
    ...getDefaultInfoAboutDocument(),
  },
  '2': {
    ...getDefaultInitiatorFieldState(),
  },
  '3': {
    signedRegionReportInfoSignSubtitle: '',
    regionsReportInfo: [],

    szpkCountFederalReportPlan: '',
    szpkCountFederalReportFact: '',
    federalSzpkCountFederalReportPlan: '',
    federalSzpkCountFederalReportPlanCorrect: '',
    federalSzpkCountFederalReportFact: '',
    federalSzpkCountFederalReportFactCorrect: '',

    regionalSzpkCountFederalReportPlan: '',
    regionalSzpkCountFederalReportPlanCorrect: '',
    regionalSzpkCountFederalReportFact: '',
    regionalSzpkCountFederalReportFactCorrect: '',

    szpkPeriodCountFederalReportPlan: '',
    szpkPeriodCountFederalReportFact: '',
    federalSzpkPeriodCountFederalReportPlan: '',
    federalSzpkPeriodCountFederalReportPlanCorrect: '',
    federalSzpkPeriodCountFederalReportFact: '',
    federalSzpkPeriodCountFederalReportFactCorrect: '',

    regionalSzpkPeriodCountFederalReportPlan: '',
    regionalSzpkPeriodCountFederalReportPlanCorrect: '',
    regionalSzpkPeriodCountFederalReportFact: '',
    regionalSzpkPeriodCountFederalReportFactCorrect: '',

    totalCapitalFederalReportPlan: '',
    totalCapitalFederalReportFact: '',
    federalTotalCapitalFederalReportPlanChecker: '',
    federalTotalCapitalFederalReportPlanRegion: '',
    federalTotalCapitalFederalReportPlanRegionDeviation: '',
    federalTotalCapitalFederalReportPlanCorrect: '',
    federalTotalCapitalFederalReportFactChecker: '',
    federalTotalCapitalFederalReportFactRegion: '',
    federalTotalCapitalFederalReportFactRegionDeviation: '',
    federalTotalCapitalFederalReportFactCorrect: '',

    regionalTotalCapitalFederalReportPlanChecker: '',
    regionalTotalCapitalFederalReportPlanRegion: '',
    regionalTotalCapitalFederalReportPlanRegionDeviation: '',
    regionalTotalCapitalFederalReportPlanCorrect: '',
    regionalTotalCapitalFederalReportFactChecker: '',
    regionalTotalCapitalFederalReportFactRegion: '',
    regionalTotalCapitalFederalReportFactRegionDeviation: '',
    regionalTotalCapitalFederalReportFactCorrect: '',

    investorCapitalFederalReportPlan: '',
    investorCapitalFederalReportFact: '',

    federalInvestorCapitalFederalReportPlanChecker: '',
    federalInvestorCapitalFederalReportPlanRegion: '',
    federalInvestorCapitalFederalReportPlanRegionDeviation: '',
    federalInvestorCapitalFederalReportPlanCorrect: '',
    federalInvestorCapitalFederalReportFactChecker: '',
    federalInvestorCapitalFederalReportFactRegion: '',
    federalInvestorCapitalFederalReportFactRegionDeviation: '',
    federalInvestorCapitalFederalReportFactCorrect: '',

    regionalInvestorCapitalFederalReportPlanChecker: '',
    regionalInvestorCapitalFederalReportPlanRegion: '',
    regionalInvestorCapitalFederalReportPlanRegionDeviation: '',
    regionalInvestorCapitalFederalReportPlanCorrect: '',
    regionalInvestorCapitalFederalReportFactChecker: '',
    regionalInvestorCapitalFederalReportFactRegion: '',
    regionalInvestorCapitalFederalReportFactRegionDeviation: '',
    regionalInvestorCapitalFederalReportFactCorrect: '',

    newWorkPlacesFederalReportPlan: '',
    newWorkPlacesFederalReportFact: '',

    federalNewWorkPlacesFederalReportPlanChecker: '',
    federalNewWorkPlacesFederalReportPlanRegion: '',
    federalNewWorkPlacesFederalReportPlanRegionDeviation: '',
    federalNewWorkPlacesFederalReportPlanCorrect: '',
    federalNewWorkPlacesFederalReportFactChecker: '',
    federalNewWorkPlacesFederalReportFactRegion: '',
    federalNewWorkPlacesFederalReportFactRegionDeviation: '',
    federalNewWorkPlacesFederalReportFactCorrect: '',

    regionalNewWorkPlacesFederalReportPlanRegion: '',
    regionalNewWorkPlacesFederalReportPlanRegionDeviation: '',
    regionalNewWorkPlacesFederalReportPlanChecker: '',
    regionalNewWorkPlacesFederalReportPlanCorrect: '',
    regionalNewWorkPlacesFederalReportFactChecker: '',
    regionalNewWorkPlacesFederalReportFactRegion: '',
    regionalNewWorkPlacesFederalReportFactRegionDeviation: '',
    regionalNewWorkPlacesFederalReportFactCorrect: '',

    compensationValueFederalReportPlan: '',
    compensationValueFederalReportFact: '',

    federalCompensationValueFederalReportPlanChecker: '',
    federalCompensationValueFederalReportPlanRegion: '',
    federalCompensationValueFederalReportPlanRegionDeviation: '',
    federalCompensationValueFederalReportPlanCorrect: '',
    federalCompensationValueFederalReportFactChecker: '',
    federalCompensationValueFederalReportFactRegion: '',
    federalCompensationValueFederalReportFactRegionDeviation: '',
    federalCompensationValueFederalReportFactCorrect: '',

    regionalCompensationValueFederalReportPlanChecker: '',
    regionalCompensationValueFederalReportPlanRegion: '',
    regionalCompensationValueFederalReportPlanRegionDeviation: '',
    regionalCompensationValueFederalReportPlanCorrect: '',
    regionalCompensationValueFederalReportFactChecker: '',
    regionalCompensationValueFederalReportFactRegion: '',
    regionalCompensationValueFederalReportFactRegionDeviation: '',
    regionalCompensationValueFederalReportFactCorrect: '',
  },
  '4': {
    economicSpheresFederalReport: [],
  },
  '5': {
    regionsFederalReport: [],
  },
  '6': {
    szpksChangeTerminateFederalReport: [],
    federalChangeSzpkCountFederalReport: '',
    federalChangeSzpkInvestorCapitalFederalReport: '',
    federalChangeSzpkTotalCapitalFederalReport: '',
    federalChangeTerminationSzpkCountFederalReport: '',
    federalChangeTerminationSzpkInvestorCapitalFederalReport: '',
    federalChangeTerminationSzpkTotalCapitalFederalReport: '',
    federalTerminationSzpkCountFederalReport: '',
    federalTerminationSzpkInvestorCapitalFederalReport: '',
    federalTerminationSzpkTotalCapitalFederalReport: '',
    regionalChangeSzpkCountFederalReport: '',
    regionalChangeSzpkInvestorCapitalFederalReport: '',
    regionalChangeSzpkTotalCapitalFederalReport: '',
    regionalChangeTerminationSzpkCountFederalReport: '',
    regionalChangeTerminationSzpkInvestorCapitalFederalReport: '',
    regionalChangeTerminationSzpkTotalCapitalFederalReport: '',
    regionalTerminationSzpkCountFederalReport: '',
    regionalTerminationSzpkInvestorCapitalFederalReport: '',
    regionalTerminationSzpkTotalCapitalFederalReport: '',
  },
}

const summaryReportOfMonitoringResultsBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderNameGenitive: '2.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '2.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '2.initiatorHeaderReasonGenitive',
  },
  '3': {
    signedRegionReportInfoSignSubtitle: '3.signedRegionReportInfoSignSubtitle',
    regionsReportInfo: '3.regionsReportInfo',

    szpkCountFederalReportPlan: '3.szpkCountFederalReportPlan',
    szpkCountFederalReportFact: '3.szpkCountFederalReportFact',
    federalSzpkCountFederalReportPlan: '3.federalSzpkCountFederalReportPlan',
    federalSzpkCountFederalReportPlanCorrect: '3.federalSzpkCountFederalReportPlanCorrect',
    federalSzpkCountFederalReportFact: '3.federalSzpkCountFederalReportFact',
    federalSzpkCountFederalReportFactCorrect: '3.federalSzpkCountFederalReportFactCorrect',

    regionalSzpkCountFederalReportPlan: '3.regionalSzpkCountFederalReportPlan',
    regionalSzpkCountFederalReportPlanCorrect: '3.regionalSzpkCountFederalReportPlanCorrect',
    regionalSzpkCountFederalReportFact: '3.regionalSzpkCountFederalReportFact',
    regionalSzpkCountFederalReportFactCorrect: '3.regionalSzpkCountFederalReportFactCorrect',

    szpkPeriodCountFederalReportPlan: '3.szpkPeriodCountFederalReportPlan',
    szpkPeriodCountFederalReportFact: '3.szpkPeriodCountFederalReportFact',
    federalSzpkPeriodCountFederalReportPlan: '3.federalSzpkPeriodCountFederalReportPlan',
    federalSzpkPeriodCountFederalReportPlanCorrect:
      '3.federalSzpkPeriodCountFederalReportPlanCorrect',
    federalSzpkPeriodCountFederalReportFact: '3.federalSzpkPeriodCountFederalReportFact',
    federalSzpkPeriodCountFederalReportFactCorrect:
      '3.federalSzpkPeriodCountFederalReportFactCorrect',

    regionalSzpkPeriodCountFederalReportPlan: '3.regionalSzpkPeriodCountFederalReportPlan',
    regionalSzpkPeriodCountFederalReportPlanCorrect:
      '3.regionalSzpkPeriodCountFederalReportPlanCorrect',
    regionalSzpkPeriodCountFederalReportFact: '3.regionalSzpkPeriodCountFederalReportFact',
    regionalSzpkPeriodCountFederalReportFactCorrect:
      '3.regionalSzpkPeriodCountFederalReportFactCorrect',

    totalCapitalFederalReportPlan: '3.totalCapitalFederalReportPlan',
    totalCapitalFederalReportFact: '3.totalCapitalFederalReportFact',

    federalTotalCapitalFederalReportPlanChecker: '3.federalTotalCapitalFederalReportPlanChecker',
    federalTotalCapitalFederalReportPlanRegion: '3.federalTotalCapitalFederalReportPlanRegion',
    federalTotalCapitalFederalReportPlanRegionDeviation:
      '3.federalTotalCapitalFederalReportPlanRegionDeviation',
    federalTotalCapitalFederalReportPlanCorrect: '3.federalTotalCapitalFederalReportPlanCorrect',
    federalTotalCapitalFederalReportFactChecker: '3.federalTotalCapitalFederalReportFactChecker',
    federalTotalCapitalFederalReportFactRegion: '3.federalTotalCapitalFederalReportFactRegion',
    federalTotalCapitalFederalReportFactRegionDeviation:
      '3.federalTotalCapitalFederalReportFactRegionDeviation',
    federalTotalCapitalFederalReportFactCorrect: '3.federalTotalCapitalFederalReportFactCorrect',

    regionalTotalCapitalFederalReportPlanChecker: '3.regionalTotalCapitalFederalReportPlanChecker',
    regionalTotalCapitalFederalReportPlanRegion: '3.regionalTotalCapitalFederalReportPlanRegion',
    regionalTotalCapitalFederalReportPlanRegionDeviation:
      '3.regionalTotalCapitalFederalReportPlanRegionDeviation',
    regionalTotalCapitalFederalReportPlanCorrect: '3.regionalTotalCapitalFederalReportPlanCorrect',
    regionalTotalCapitalFederalReportFactChecker: '3.regionalTotalCapitalFederalReportFactChecker',
    regionalTotalCapitalFederalReportFactRegion: '3.regionalTotalCapitalFederalReportFactRegion',
    regionalTotalCapitalFederalReportFactRegionDeviation:
      '3.regionalTotalCapitalFederalReportFactRegionDeviation',
    regionalTotalCapitalFederalReportFactCorrect: '3.regionalTotalCapitalFederalReportFactCorrect',

    investorCapitalFederalReportPlan: '3.investorCapitalFederalReportPlan',
    investorCapitalFederalReportFact: '3.investorCapitalFederalReportFact',

    federalInvestorCapitalFederalReportPlanChecker:
      '3.federalInvestorCapitalFederalReportPlanChecker',
    federalInvestorCapitalFederalReportPlanRegion:
      '3.federalInvestorCapitalFederalReportPlanRegion',
    federalInvestorCapitalFederalReportPlanRegionDeviation:
      '3.federalInvestorCapitalFederalReportPlanRegionDeviation',
    federalInvestorCapitalFederalReportPlanCorrect:
      '3.federalInvestorCapitalFederalReportPlanCorrect',
    federalInvestorCapitalFederalReportFactChecker:
      '3.federalInvestorCapitalFederalReportFactChecker',
    federalInvestorCapitalFederalReportFactRegion:
      '3.federalInvestorCapitalFederalReportFactRegion',
    federalInvestorCapitalFederalReportFactRegionDeviation:
      '3.federalInvestorCapitalFederalReportFactRegionDeviation',
    federalInvestorCapitalFederalReportFactCorrect:
      '3.federalInvestorCapitalFederalReportFactCorrect',

    regionalInvestorCapitalFederalReportPlanChecker:
      '3.regionalInvestorCapitalFederalReportPlanChecker',
    regionalInvestorCapitalFederalReportPlanRegion:
      '3.regionalInvestorCapitalFederalReportPlanRegion',
    regionalInvestorCapitalFederalReportPlanRegionDeviation:
      '3.regionalInvestorCapitalFederalReportPlanRegionDeviation',
    regionalInvestorCapitalFederalReportPlanCorrect:
      '3.regionalInvestorCapitalFederalReportPlanCorrect',
    regionalInvestorCapitalFederalReportFactChecker:
      '3.regionalInvestorCapitalFederalReportFactChecker',
    regionalInvestorCapitalFederalReportFactRegion:
      '3.regionalInvestorCapitalFederalReportFactRegion',
    regionalInvestorCapitalFederalReportFactRegionDeviation:
      '3.regionalInvestorCapitalFederalReportFactRegionDeviation',
    regionalInvestorCapitalFederalReportFactCorrect:
      '3.regionalInvestorCapitalFederalReportFactCorrect',

    newWorkPlacesFederalReportPlan: '3.newWorkPlacesFederalReportPlan',
    newWorkPlacesFederalReportFact: '3.newWorkPlacesFederalReportFact',

    federalNewWorkPlacesFederalReportPlanChecker: '3.federalNewWorkPlacesFederalReportPlanChecker',
    federalNewWorkPlacesFederalReportPlanRegion: '3.federalNewWorkPlacesFederalReportPlanRegion',
    federalNewWorkPlacesFederalReportPlanRegionDeviation:
      '3.federalNewWorkPlacesFederalReportPlanRegionDeviation',
    federalNewWorkPlacesFederalReportPlanCorrect: '3.federalNewWorkPlacesFederalReportPlanCorrect',
    federalNewWorkPlacesFederalReportFactChecker: '3.federalNewWorkPlacesFederalReportFactChecker',
    federalNewWorkPlacesFederalReportFactRegion: '3.federalNewWorkPlacesFederalReportFactRegion',
    federalNewWorkPlacesFederalReportFactRegionDeviation:
      '3.federalNewWorkPlacesFederalReportFactRegionDeviation',
    federalNewWorkPlacesFederalReportFactCorrect: '3.federalNewWorkPlacesFederalReportFactCorrect',

    regionalNewWorkPlacesFederalReportPlanRegion: '3.regionalNewWorkPlacesFederalReportPlanRegion',
    regionalNewWorkPlacesFederalReportPlanRegionDeviation:
      '3.regionalNewWorkPlacesFederalReportPlanRegionDeviation',
    regionalNewWorkPlacesFederalReportPlanChecker:
      '3.regionalNewWorkPlacesFederalReportPlanChecker',
    regionalNewWorkPlacesFederalReportPlanCorrect:
      '3.regionalNewWorkPlacesFederalReportPlanCorrect',
    regionalNewWorkPlacesFederalReportFactChecker:
      '3.regionalNewWorkPlacesFederalReportFactChecker',
    regionalNewWorkPlacesFederalReportFactRegion: '3.regionalNewWorkPlacesFederalReportFactRegion',
    regionalNewWorkPlacesFederalReportFactRegionDeviation:
      '3.regionalNewWorkPlacesFederalReportFactRegionDeviation',
    regionalNewWorkPlacesFederalReportFactCorrect:
      '3.regionalNewWorkPlacesFederalReportFactCorrect',

    compensationValueFederalReportPlan: '3.compensationValueFederalReportPlan',
    compensationValueFederalReportFact: '3.compensationValueFederalReportFact',

    federalCompensationValueFederalReportPlanChecker:
      '3.federalCompensationValueFederalReportPlanChecker',
    federalCompensationValueFederalReportPlanRegion:
      '3.federalCompensationValueFederalReportPlanRegion',
    federalCompensationValueFederalReportPlanRegionDeviation:
      '3.federalCompensationValueFederalReportPlanRegionDeviation',
    federalCompensationValueFederalReportPlanCorrect:
      '3.federalCompensationValueFederalReportPlanCorrect',
    federalCompensationValueFederalReportFactChecker:
      '3.federalCompensationValueFederalReportFactChecker',
    federalCompensationValueFederalReportFactRegion:
      '3.federalCompensationValueFederalReportFactRegion',
    federalCompensationValueFederalReportFactRegionDeviation:
      '3.federalCompensationValueFederalReportFactRegionDeviation',
    federalCompensationValueFederalReportFactCorrect:
      '3.federalCompensationValueFederalReportFactCorrect',

    regionalCompensationValueFederalReportPlanChecker:
      '3.regionalCompensationValueFederalReportPlanChecker',
    regionalCompensationValueFederalReportPlanRegion:
      '3.regionalCompensationValueFederalReportPlanRegion',
    regionalCompensationValueFederalReportPlanRegionDeviation:
      '3.regionalCompensationValueFederalReportPlanRegionDeviation',
    regionalCompensationValueFederalReportPlanCorrect:
      '3.regionalCompensationValueFederalReportPlanCorrect',
    regionalCompensationValueFederalReportFactChecker:
      '3.regionalCompensationValueFederalReportFactChecker',
    regionalCompensationValueFederalReportFactRegion:
      '3.regionalCompensationValueFederalReportFactRegion',
    regionalCompensationValueFederalReportFactRegionDeviation:
      '3.regionalCompensationValueFederalReportFactRegionDeviation',
    regionalCompensationValueFederalReportFactCorrect:
      '3.regionalCompensationValueFederalReportFactCorrect',
  },
  '4': {
    economicSpheresFederalReport: '4.economicSpheresFederalReport',
  },
  '5': {
    regionsFederalReport: '5.regionsFederalReport',
  },
  '6': {
    szpksChangeTerminateFederalReport: '6.szpksChangeTerminateFederalReport',
    federalChangeTerminationSzpkCountFederalReport:
      '6.federalChangeTerminationSzpkCountFederalReport',
    federalChangeTerminationSzpkTotalCapitalFederalReport:
      '6.federalChangeTerminationSzpkTotalCapitalFederalReport',
    federalChangeTerminationSzpkInvestorCapitalFederalReport:
      '6.federalChangeTerminationSzpkInvestorCapitalFederalReport',

    federalChangeSzpkCountFederalReport: '6.federalChangeSzpkCountFederalReport',
    federalChangeSzpkTotalCapitalFederalReport: '6.federalChangeSzpkTotalCapitalFederalReport',
    federalChangeSzpkInvestorCapitalFederalReport:
      '6.federalChangeSzpkInvestorCapitalFederalReport',

    federalTerminationSzpkCountFederalReport: '6.federalTerminationSzpkCountFederalReport',
    federalTerminationSzpkTotalCapitalFederalReport:
      '6.federalTerminationSzpkTotalCapitalFederalReport',
    federalTerminationSzpkInvestorCapitalFederalReport:
      '6.federalTerminationSzpkInvestorCapitalFederalReport',

    regionalChangeTerminationSzpkCountFederalReport:
      '6.regionalChangeTerminationSzpkCountFederalReport',
    regionalChangeTerminationSzpkTotalCapitalFederalReport:
      '6.regionalChangeTerminationSzpkTotalCapitalFederalReport',
    regionalChangeTerminationSzpkInvestorCapitalFederalReport:
      '6.regionalChangeTerminationSzpkInvestorCapitalFederalReport',

    regionalChangeSzpkCountFederalReport: '6.regionalChangeSzpkCountFederalReport',
    regionalChangeSzpkTotalCapitalFederalReport: '6.regionalChangeSzpkTotalCapitalFederalReport',
    regionalChangeSzpkInvestorCapitalFederalReport:
      '6.regionalChangeSzpkInvestorCapitalFederalReport',

    regionalTerminationSzpkCountFederalReport: '6.regionalTerminationSzpkCountFederalReport',
    regionalTerminationSzpkTotalCapitalFederalReport:
      '6.regionalTerminationSzpkTotalCapitalFederalReport',
    regionalTerminationSzpkInvestorCapitalFederalReport:
      '6.regionalTerminationSzpkInvestorCapitalFederalReport',
  },
} as const

export {
  defaultSummaryReportOfMonitoringResultsValues,
  mapOfSummaryReportOfMonitoringResultsMenu,
  summaryReportOfMonitoringResultsBlockValues,
}
