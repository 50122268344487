import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext, useFormState } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFindFormValueDifferences } from '@components/DocumentFormComponents/hooks/useFindFormValueDifferences'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormRegions } from '@components/DocumentFormComponents/hooks/useFormRegions'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNestedMenuFormErrors } from '@components/DocumentFormComponents/hooks/useNestedMenuFormErrors'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { useNestedMenuManager as useProjectSZPKMenuManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import {
  FormModalContextProps,
  FormModalManagerProps,
} from '@components/DocumentFormComponents/types'
import { useProjectSZPKAdapters } from '@components/Forms/ProjectSZPKForm/adapters'
import {
  projectSZPKBlockValues,
  rewriteProjectSZPKFieldsAfterApplyDiffs,
} from '@components/Forms/ProjectSZPKForm/const'
import { getProjectMenuByStatus } from '@components/Forms/ProjectSZPKForm/Menu/const'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import { isSelectOption } from '@components/NewDesign/Select/helpers'
import { isArray, isNull, isNumber, isString } from '@helpers/checkTypes'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'
import { nanoid } from 'nanoid'

const { getDefaultFormModalContextValue } = DocumentFormHelpers

export const ProjectSZPKModalContext = createContext<FormModalContextProps<ProjectSZPKFormValues>>(
  getDefaultFormModalContextValue<ProjectSZPKFormValues>(),
)

const useProjectSZPKManager = () => {
  return useContext(ProjectSZPKModalContext)
}

const ProjectSZPKModalManager: FC<FormModalManagerProps> = ({
  formId,
  editMode,
  projectId,
  initialErrorsFromViolations,
  rolesOfUser,
  onClose,
  onSetLastUpdateFormToNow,
  children,
}) => {
  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  //Инициализация регионов для селектов
  useFormRegions(projectId)

  const { updateLastRFHBeforeValue, getLastRHFBeforeValue } =
    useFormPreviousValue<ProjectSZPKFormValues>()

  const formInstance = useFormContext<ProjectSZPKFormValues>()
  const { setValue, getValues, reset: resetForm, clearErrors } = formInstance

  const { errors } = useFormState({
    control: formInstance?.control,
  })

  const { getOverrideProps, processOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const { formIsLoading, handleMountForm } = useFormMount(formId)

  const { formErrorsRef } = useNestedMenuFormErrors(errors)

  const {
    handlers: { handleRecalculateFormMenu },
  } = useProjectSZPKMenuManager()

  const {
    generateRHFObject,
    generatePropertiesObject,
    generateOverrideProps,
    generateMenuListByFormValues,
    generatePrefetchedPropsArray,
  } = useProjectSZPKAdapters()

  const { getBasePropsFromObjectAdapter, getAdvancedPropsFromObjectAdapter } =
    useGenerateValuesFromObjectAdapter({
      generateRHFObject,
      generatePropertiesObject,
      generatePrefetchedPropsArray,
      generateOverrideProps,
    })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
    rolesOfUser,
  })

  const { handleNotifyAllSubscribersWithAdvancedProps } = useNotifyAllSubscribers({
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const { applyDifferences } = useFindFormValueDifferences({
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
    setValue,
  })

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()

      if (isNull(prevRHFValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const stepActivityOthers = getValues(projectSZPKBlockValues['8'].stepActivityOthers)

      const { prefetchedIds, customProps } = await getAdvancedPropsFromObjectAdapter(
        props.objectForAdapter,
      )

      const readyOverrideProps = await processOverrideProps(
        [...props.overridePropsFromChanges, ...customProps],
        prefetchedIds,
      )

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      const preparedRHFValue: ProjectSZPKFormValues = {
        ...RHFValueForReset,
        ['8']: {
          ...RHFValueForReset['8'],
          stepActivityOthers: RHFValueForReset['8'].stepActivityOthers.map((step, indexOfStep) => ({
            ...step,
            activityOthers: step.activityOthers.map((activity, indexOfActivity) => ({
              ...activity,
              frontId:
                stepActivityOthers[indexOfStep].activityOthers[indexOfActivity]?.frontId ||
                nanoid(),
            })),
          })),
        },
      }

      unstable_batchedUpdates(() => {
        applyDifferences(prevRHFValue, preparedRHFValue)

        rewriteProjectSZPKFieldsAfterApplyDiffs.forEach((rewriteField) => {
          const valueToApply = getObjectValue(preparedRHFValue, rewriteField)

          if (
            isNumber(valueToApply) ||
            isString(valueToApply) ||
            isArray(valueToApply) ||
            isSelectOption(valueToApply)
          ) {
            setValue(rewriteField, valueToApply)
          }
        })

        updateLastRFHBeforeValue(preparedRHFValue)

        setTimeout(() => {
          ProjectSZPKFieldsControlUpdateWatcher.notifyAllSubscribers(readyOverrideProps)
        }, 0)

        onSetLastUpdateFormToNow?.()
      })
    },
    [
      propertiesPropsRef,
      lastFieldUpdateTime,
      getValues,
      setValue,
      applyDifferences,
      getAdvancedPropsFromObjectAdapter,
      getBasePropsFromObjectAdapter,
      getLastRHFBeforeValue,
      onSetLastUpdateFormToNow,
      updateLastRFHBeforeValue,
      processOverrideProps,
    ],
  )

  const handleRecalculateMenu = useCallback(() => {
    const formValues = getValues()

    const preparedMenuList = generateMenuListByFormValues(
      formValues,
      cloneDeep(
        getProjectMenuByStatus(formValues.additionalFields.isFederal).initialProjectSZPKMenuHashMap,
      ),
      formErrorsRef.current,
    )

    handleRecalculateFormMenu?.(preparedMenuList)
  }, [getValues, formErrorsRef, generateMenuListByFormValues, handleRecalculateFormMenu])

  const handleApplyChangesWithMenu = useCallback(
    async (props: ApplyChangesReturn) => {
      await handleApplyChanges(props)

      handleRecalculateMenu()
    },
    [handleApplyChanges, handleRecalculateMenu],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const wrappedHandleApplyFormChangesWithMenu = useMemo(
    () => applyFormChanges(handleApplyChangesWithMenu),
    [applyFormChanges, handleApplyChangesWithMenu],
  )

  const {
    handleAddItemToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleChangeValue,
    handleChangeCustomValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    debouncedHandleChangeValue,
    handleUpElementInList,
    handleDownElementInList,
  } = useFormUpdate<ProjectSZPKFormValues>({
    formId,
    getPropertiesProps,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: wrappedHandleApplyFormChangesWithMenu,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const wrappedHandleApplyFormChanges = useMemo(
    () => applyFormChanges(handleApplyChanges),
    [applyFormChanges, handleApplyChanges],
  )

  const { handleCheckForm, handlePreviewForm } = useFormActions({
    formId,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    handleApplyChanges: wrappedHandleApplyFormChanges,
    onErrorCheckForm: handleRecalculateMenu,
    onClearError: clearErrors,
  })

  const handleSuccessInitializeForm = useCallback(
    async (props: SuccessInitialCallbackReturn) => {
      const { lastChangesDt, propertyInstance } = props
      lastFieldUpdateTime.current = lastChangesDt

      propertyInstanceRef.current = propertyInstance

      const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(objectForAdapters)

      const preparedMenuList = generateMenuListByFormValues(
        RHFValueForReset,
        cloneDeep(
          getProjectMenuByStatus(RHFValueForReset.additionalFields.isFederal)
            .initialProjectSZPKMenuHashMap,
        ),
        errors,
      )

      const preparedRHFValue: ProjectSZPKFormValues = {
        ...RHFValueForReset,
        ['8']: {
          ...RHFValueForReset['8'],
          stepActivityOthers: RHFValueForReset['8'].stepActivityOthers.map((step) => ({
            ...step,
            activityOthers: step.activityOthers.map((activity) => ({
              ...activity,
              frontId: nanoid(),
            })),
          })),
        },
      }

      //Установка propertiesProps
      propertiesPropsRef.current = cloneDeep(propertiesProps)

      resetForm(preparedRHFValue)
      updateLastRFHBeforeValue(preparedRHFValue)

      handleRecalculateFormMenu?.(preparedMenuList)
    },
    [
      errors,
      propertyInstanceRef,
      propertiesPropsRef,
      lastFieldUpdateTime,
      resetForm,
      getBasePropsFromObjectAdapter,
      handleRecalculateFormMenu,
      updateLastRFHBeforeValue,
      generateMenuListByFormValues,
    ],
  )

  //Инициализация формы
  useEffect(() => {
    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleNotifyAllSubscribersWithAdvancedProps(
        getAdvancedPropsFromObjectAdapter,
      ),
    })
  }, [])

  const preparedValue: FormModalContextProps<ProjectSZPKFormValues> = useMemo(
    () => ({
      state: {
        formIsLoading,
        blockViewIsValidating,
        initialErrorsFromViolations,
        editMode,
      },
      handlers: {
        checkFormChanges,
        getPropertiesProps,
        getOverrideProps,
        handleChangeValue,
        handleChangeCustomValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        debouncedHandleChangeValue,
        handleUpElementInList,
        handleDownElementInList,
        handleAddItemToListWithOutValue,
        handleAddItemToListWithValue,
        handleRemoveItemFromList,
        applyFormChanges: wrappedHandleApplyFormChanges,
        handleChangeBlockValidation,
      },
      actions: {
        handleFormClose: onClose,
        handleCheckForm,
        handlePreviewForm,
      },
      preparedProps: {
        subscribableControl,
      },
    }),
    [
      formIsLoading,
      blockViewIsValidating,
      initialErrorsFromViolations,
      editMode,
      checkFormChanges,
      getPropertiesProps,
      getOverrideProps,
      handleChangeValue,
      handleChangeCustomValue,
      handleAddCustomValue,
      handleRemoveCustomValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithOutValue,
      handleAddItemToListWithValue,
      handleUpElementInList,
      handleDownElementInList,
      handleRemoveItemFromList,
      wrappedHandleApplyFormChanges,
      handleChangeBlockValidation,
      onClose,
      handleCheckForm,
      handlePreviewForm,
      subscribableControl,
    ],
  )

  return (
    <ProjectSZPKModalContext.Provider value={preparedValue}>
      {children}
    </ProjectSZPKModalContext.Provider>
  )
}

export { useProjectSZPKManager }
export default ProjectSZPKModalManager
