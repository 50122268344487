import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForAdditionalAgreementOnTerminationFfSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      ruleLinkAgreementTerminationExtra: {
        propertyId: objectFromPropertyState.ruleLinkAgreementTerminationExtra?.propertyId,
        type: objectFromPropertyState.ruleLinkAgreementTerminationExtra?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkAgreementTerminationExtra?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForAdditionalAgreementOnTerminationFfSZPKForm }
