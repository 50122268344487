import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import NotificationOfChangeDocumentsMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NotificationOfChangeDocumentsLayout from './Layout'
import NotificationOfChangeDocumentsModalManager from './Manager'
import NotificationOfChangeDocumentsMenu from './Menu'
import NotificationOfChangeDocumentsStatementForm from './Statement'

interface INotificationOfChangeDocuments extends FC {
  Layout: typeof NotificationOfChangeDocumentsLayout
  Menu: typeof NotificationOfChangeDocumentsMenu
  StatementForm: typeof NotificationOfChangeDocumentsStatementForm
  Manager: typeof NotificationOfChangeDocumentsModalManager
  MenuManager: typeof NotificationOfChangeDocumentsMenuManager
}

const useNotificationOfChangeDocumentsControl = () => {
  const popupManager = usePopupManager()

  const openNotificationOfChangeDocumentsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(NotificationOfChangeDocumentsLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openNotificationOfChangeDocumentsModal }
}

const NotificationOfChangeDocuments: INotificationOfChangeDocuments = () => null

NotificationOfChangeDocuments.Menu = NotificationOfChangeDocumentsMenu
NotificationOfChangeDocuments.Layout = NotificationOfChangeDocumentsLayout
NotificationOfChangeDocuments.StatementForm = NotificationOfChangeDocumentsStatementForm
NotificationOfChangeDocuments.Manager = NotificationOfChangeDocumentsModalManager
NotificationOfChangeDocuments.MenuManager = NotificationOfChangeDocumentsMenuManager

export { useNotificationOfChangeDocumentsControl }
export default NotificationOfChangeDocuments
