import { memo, useMemo } from 'react'

import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import Markdown from '@components/Markdown'

import styles from './Preview.module.scss'

const KnowledgeBaseMobilePreview = () => {
  const {
    state: { currentBaseItem },
  } = useKnowledgeBase()

  const markdown = useMemo(() => {
    const childWithMarkdown = currentBaseItem?.children.find(({ markdown }) => markdown)

    return currentBaseItem?.markdown || childWithMarkdown?.markdown
  }, [currentBaseItem])

  if (!currentBaseItem || !markdown) return null

  return (
    <div className={styles.preview}>
      <Markdown.FileWrapper src={markdown}>
        {(readyText) => {
          return <Markdown.View>{readyText}</Markdown.View>
        }}
      </Markdown.FileWrapper>
    </div>
  )
}

export default memo(KnowledgeBaseMobilePreview)
