import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IFileState } from './FileState.entity'

const initialState: IFileState = {
  isActive: false,
}

const fileSlice = createSlice({
  name: 'fileState',
  initialState,
  reducers: {
    changeFileState(state, { payload }: PayloadAction<IFileState>) {
      state.isActive = payload.isActive
    },
  },
})

const FileStateActions = fileSlice.actions

export { FileStateActions }

export default fileSlice
