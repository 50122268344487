import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'

import Taxes from '@components/Forms/CreateStatement/Forms/PlannedTax/Taxes'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import Container from '@components/ReactBootstrap/Container'

const PlannedTax = () => {
  const formInstance = useFormContext<StatementFormValues>()

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Taxes />
    </Container>
  )
}

export default memo(PlannedTax)
