import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface NoticeOfRejectConcludeSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const NoticeOfRejectConcludeSZPK: NoticeOfRejectConcludeSZPKFormsProps = () => null

NoticeOfRejectConcludeSZPK.First = First
NoticeOfRejectConcludeSZPK.Second = Second
NoticeOfRejectConcludeSZPK.Third = Third
NoticeOfRejectConcludeSZPK.Fourth = Fourth

export default NoticeOfRejectConcludeSZPK
