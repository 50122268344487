import React, { DetailedHTMLProps, FC, ReactNode } from 'react'

import Icon from '@components/Icon'
import Loader from '@components/Loader'
import closeIcon from '@icons/navigation/close.svg'
import cn from 'classnames'

import styles from './Toast.module.scss'

interface IToast extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  withLoader?: boolean
  wrapperClassname?: string
  contentClassname?: string
  onClose?: () => void
}

const Toast: FC<IToast> = ({
  withLoader,
  wrapperClassname,
  contentClassname,
  onClose,
  children,
  ...rest
}) => {
  return (
    <div {...rest} className={cn(styles.toast, wrapperClassname)}>
      {withLoader && <Loader loading wrapperClassName={styles.toastLoader} variant="lite" />}
      <div
        className={cn(
          {
            [styles.toastContent]: withLoader && onClose,
          },
          contentClassname,
        )}
      >
        {children}
      </div>
      {onClose && (
        <Icon src={closeIcon} className={styles.toastClose} wrapper="span" onClick={onClose} />
      )}
    </div>
  )
}

export default Toast
