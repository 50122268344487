import { informationOfExecutionConditionsSZPKBlockValues } from '../../const'

import { ninthSectionValidationMap } from './validation'

export const reasonObjects = [
  {
    id: '9.1',
    checkboxLabel: 'Включение в соглашение сведений об условиях связанного договора',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].relatedContractReason,
    reasonDescriptionRules: ninthSectionValidationMap.relatedContractReasonDescription,
  },
  {
    id: '9.2',
    checkboxLabel: 'Увеличение срока применения стабилизационной оговорки',
    reasonDescriptionRules: ninthSectionValidationMap.extendStabilizationReasonDescription,
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].extendStabilizationReason,
  },
  {
    id: '9.3',
    checkboxLabel: 'Присоединение муниципальных образований',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].municipalAccessionReason,
    reasonDescriptionRules: ninthSectionValidationMap.municipalAccessionReasonDescription,
  },
  {
    id: '9.4',
    checkboxLabel: 'Передача прав и обязанностей',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].rightTransferReason,
    reasonDescriptionRules: ninthSectionValidationMap.rightTransferReasonDescription,
  },
  {
    id: '9.5',
    checkboxLabel: 'Изменение реквизитов сторон соглашения',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].infoChangesReason,
    reasonDescriptionRules: ninthSectionValidationMap.infoChangesReasonDescription,
  },
  {
    id: '9.6',
    checkboxLabel:
      'Невозможность реализации проекта в установленные сроки в результате возникновения обстоятельств непреодолимой силы',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].forceMajeureReason,
    reasonDescriptionRules: ninthSectionValidationMap.forceMajeureReasonDescription,
  },
  {
    id: '9.7',
    checkboxLabel:
      'Включение в соглашение сведений о договоре о распределении затрат на объекты инфраструктуры',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].agreementDutyReason,
    reasonDescriptionRules: ninthSectionValidationMap.agreementDutyReasonDescription,
  },
  {
    id: '9.8',
    checkboxLabel: 'Незаключение организацией, реализующей проект, концессионного соглашения',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].concessionFailReason,
    reasonDescriptionRules: ninthSectionValidationMap.concessionFailReasonDescription,
  },
  {
    id: '9.9',
    checkboxLabel:
      'Изменение характеристик создаваемых либо реконструируемых и (или) модернизируемых в рамках проекта объектов недвижимости',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].constructionChangeReason,
    reasonDescriptionRules: ninthSectionValidationMap.constructionChangeReasonDescription,
  },
  {
    id: '9.10',
    checkboxLabel:
      'Внесение изменений в инвестиционный проект в связи с необходимостью создания либо реконструкции и (или) модернизации иных объектов недвижимости',
    reasonDescriptionRules: ninthSectionValidationMap.supportingInfrastructureReasonDescription,

    reasonName:
      informationOfExecutionConditionsSZPKBlockValues['10'].supportingInfrastructureReason,
  },
  {
    id: '9.11',
    checkboxLabel: 'Изменение объема капиталовложений',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].investmentChangeReason,
    reasonDescriptionRules: ninthSectionValidationMap.investmentChangeReasonDescription,
  },
  {
    id: '9.12',
    checkboxLabel: 'Изменение объема планируемых к возмещению затрат',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].compensationChangeReason,
    reasonDescriptionRules: ninthSectionValidationMap.compensationChangeReasonDescription,
  },
  {
    id: '9.13',
    checkboxLabel: 'Изменение формы меры государственной поддержки',
    reasonDescriptionRules: ninthSectionValidationMap.changeCompensationFormatReasonDescription,
    reasonName:
      informationOfExecutionConditionsSZPKBlockValues['10'].changeCompensationFormatReason,
  },
  {
    id: '9.14',
    checkboxLabel:
      'Изменения в законодательстве Российской Федерации, улучшающие положение организации, реализующей проект, и имеющие обратную силу',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].lawChangeReason,
    reasonDescriptionRules: ninthSectionValidationMap.lawChangeReasonDescription,
  },
  {
    id: '9.15',
    checkboxLabel:
      'Неосуществление капиталовложений, предусмотренных условиями соглашения, в течение более чем 2 лет по истечении предусмотренного соглашением срока осуществления капиталовложений',
    reasonDescriptionRules: ninthSectionValidationMap.noMadeInvestorCapitalReasonDescription,
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].noMadeInvestorCapitalReason,
  },
  {
    id: '9.16',
    checkboxLabel:
      'Ненаступление отдельных юридических фактов, предусмотренных условиями соглашения, в течение более чем 2 лет по истечении предусмотренного соглашением срока осуществления капиталовложений',
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].legalFactsReason,
    reasonDescriptionRules: ninthSectionValidationMap.legalFactsReasonDescription,
  },
  {
    id: '9.17',
    checkboxLabel:
      'Нарушение организацией, реализующей проект, или ее должностными лицами законодательства Российской Федерации',
    reasonDescriptionRules: ninthSectionValidationMap.lawViolationReasonDescription,
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].lawViolationReason,
  },
  {
    id: '9.18',
    checkboxLabel:
      'В отношении организации, реализующей проект, открыто конкурсное производство в соответствии с Федеральным законом "О несостоятельности (банкротстве)"',
    reasonDescriptionRules:
      ninthSectionValidationMap.suspensionOrganizationActivityReasonDescription,
    reasonName:
      informationOfExecutionConditionsSZPKBlockValues['10'].suspensionOrganizationActivityReason,
  },
  {
    id: '9.19',
    checkboxLabel: 'Принято решение о ликвидации организации, реализующей проект',
    reasonDescriptionRules: ninthSectionValidationMap.liquidationOrganisationReasonDescription,
    reasonName: informationOfExecutionConditionsSZPKBlockValues['10'].liquidationOrganisationReason,
  },
]
