import { FC } from 'react'

import StatementItem from './Item'

interface StatementsProps extends FC {
  Item: typeof StatementItem
}

const Statements: StatementsProps = () => null

Statements.Item = StatementItem

export default Statements
