import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import ParametersOfCostRecoveryApplicationLayout from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Layout'

const ParametersOfCostRecoveryApplicationLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultParametersOfCostRecoveryApplicationFormValues}>
      <ParametersOfCostRecoveryApplicationLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(ParametersOfCostRecoveryApplicationLayoutWithFormProvider)
