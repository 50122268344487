import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NotificationDrawalAppConclusionSZPKFormLayout from './Layout'
import NotificationDrawalAppConclusionSZPKFormModalManager from './Manager'
import NotificationDrawalAppConclusionSZPKFormMenu from './Menu'
import NotificationDrawalAppConclusionSZPKFormStatementForm from './Statement'

interface INotificationDrawalAppConclusionSZPKForm extends FC {
  Layout: typeof NotificationDrawalAppConclusionSZPKFormLayout
  Menu: typeof NotificationDrawalAppConclusionSZPKFormMenu
  StatementForm: typeof NotificationDrawalAppConclusionSZPKFormStatementForm
  Manager: typeof NotificationDrawalAppConclusionSZPKFormModalManager
}

const useNotificationDrawalAppConclusionSZPKFormControl = () => {
  const popupManager = usePopupManager()

  const openNotificationDrawalAppConclusionSZPKModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(NotificationDrawalAppConclusionSZPKFormLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openNotificationDrawalAppConclusionSZPKModal }
}

const NotificationDrawalAppConclusionSZPKForm: INotificationDrawalAppConclusionSZPKForm = () => null

NotificationDrawalAppConclusionSZPKForm.Menu = NotificationDrawalAppConclusionSZPKFormMenu
NotificationDrawalAppConclusionSZPKForm.Layout = NotificationDrawalAppConclusionSZPKFormLayout
NotificationDrawalAppConclusionSZPKForm.StatementForm =
  NotificationDrawalAppConclusionSZPKFormStatementForm
NotificationDrawalAppConclusionSZPKForm.Manager =
  NotificationDrawalAppConclusionSZPKFormModalManager

export { useNotificationDrawalAppConclusionSZPKFormControl }
export default NotificationDrawalAppConclusionSZPKForm
