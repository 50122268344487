import { FC, useMemo } from 'react'

import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { IDocumentAction } from '@services/DocumentActions/DocumentActions.entity'

interface UiActionButtonProps {
  uiAction: IDocumentAction

  className?: string
  buttonProps?: Omit<ButtonProps, 'className' | 'disabled' | 'variant' | 'fontWeight'>
}

const UiActionButton: FC<UiActionButtonProps> = ({ uiAction, className, buttonProps }) => {
  const btnProps = useMemo(() => {
    return {
      className,
      disabled: !uiAction.enable,
      variant: 'buttonMMedium',
      fontWeight: 'medium',
      color: uiAction.action === 'return' || uiAction.action === 'cancel' ? 'negative' : undefined,
      view: uiAction.action === 'return' ? 'plain' : undefined,
      ...buttonProps,
    } as ButtonProps
  }, [className, uiAction.action, uiAction.enable, buttonProps])

  return <Button {...btnProps}>{uiAction.displayName}</Button>
}

export default UiActionButton
