import { FC } from 'react'

import First from './1'
import Second from './2'

interface InclusionAgreementOfRelatedSZPKRegisterFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const InclusionAgreementOfRelatedSZPKRegisterForms: InclusionAgreementOfRelatedSZPKRegisterFormsProps =
  () => null

InclusionAgreementOfRelatedSZPKRegisterForms.First = First
InclusionAgreementOfRelatedSZPKRegisterForms.Second = Second

export default InclusionAgreementOfRelatedSZPKRegisterForms
