import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidStage,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const ridExploitationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidStage['exploitation'],
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  ridCommissioningEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { ridExploitationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
