import {
  DefaultPropertiesProps,
  IChangedPropertyObject,
  IPropertyFormError,
  TProperty,
} from '@services/Properties/Properties.entity'

type GetPropertiesData = {
  '@lastNoticedChangesDt': string
  '@properties': TProperty
}
type GetPropertiesProps = DefaultPropertiesProps

const ChangePropertyEnum = {
  CHANGE_PROPERTY_VALUE: 'CHANGE_PROPERTY_VALUE',
  APPEND_CHILD_ELEMENT: 'APPEND_CHILD_ELEMENT',
  DELETE_CHILD_ELEMENT: 'DELETE_CHILD_ELEMENT',
  ORDER_UP: 'ORDER_UP',
  ORDER_DOWN: 'ORDER_DOWN',
  CHANGE_IS_PUBLIC: 'CHANGE_IS_PUBLIC',
  CHANGE_IS_PUBLIC_ID_TYPE: 'CHANGE_IS_PUBLIC_ID_TYPE',
} as const

type ChangePropertyBodyRequired = {
  property: {
    id: string
    //date
    lastUpdateDt: string
  }
}
type ChangePropertyBodySimple = ChangePropertyBodyRequired & {
  type: typeof ChangePropertyEnum.CHANGE_PROPERTY_VALUE
  newValue: unknown
}
type ChangePropertyBodyStructureWithoutValue = ChangePropertyBodyRequired & {
  type:
    | typeof ChangePropertyEnum.APPEND_CHILD_ELEMENT
    | typeof ChangePropertyEnum.DELETE_CHILD_ELEMENT
    | typeof ChangePropertyEnum.ORDER_UP
    | typeof ChangePropertyEnum.ORDER_DOWN
}

type ChangePropertyBodyStructureWithContent = ChangePropertyBodyRequired & {
  type: typeof ChangePropertyEnum.APPEND_CHILD_ELEMENT
  content: Record<string, unknown> | unknown
}
type ChangeMultiplePropertyBodyStructureWithContent = ChangePropertyBodyRequired & {
  type: typeof ChangePropertyEnum.APPEND_CHILD_ELEMENT
  contents: (Record<string, unknown> | unknown)[]
}

type RemovePropertyBodyStructure = ChangePropertyBodyRequired & {
  type: typeof ChangePropertyEnum.DELETE_CHILD_ELEMENT
  childElementId: string
}

type ChangePublicValue = ChangePropertyBodyRequired & {
  type: typeof ChangePropertyEnum.CHANGE_IS_PUBLIC
  newIsPublic: boolean
}
type ChangePublicValueType = ChangePropertyBodyRequired & {
  type: typeof ChangePropertyEnum.CHANGE_IS_PUBLIC_ID_TYPE
  memberFieldName: string
  newIsPublic: boolean
}
type ChangePublicValueInList = ChangePublicValue[]

type ChangePropertyBody =
  | ChangePropertyBodySimple
  | ChangePropertyBodyStructureWithoutValue
  | ChangePropertyBodyStructureWithContent
  | ChangeMultiplePropertyBodyStructureWithContent
  | RemovePropertyBodyStructure
  | ChangePublicValue
  | ChangePublicValueType
  | ChangePublicValueInList

type GetChangePropertiesProps = GetPropertiesProps & {
  lastChangesDt: string
}

type GetChangePropertiesData = IChangedPropertyObject[]

type GetLookupProps = {
  projectId: string
  formId: string
  propertyId: string
}

type GetLookupData = Record<string, unknown>
type GetFormErrorsData = IPropertyFormError[]

export type {
  ChangeMultiplePropertyBodyStructureWithContent,
  ChangePropertyBody,
  ChangePropertyBodyRequired,
  ChangePropertyBodySimple,
  ChangePropertyBodyStructureWithContent,
  ChangePropertyBodyStructureWithoutValue,
  ChangePublicValue,
  ChangePublicValueInList,
  ChangePublicValueType,
  GetChangePropertiesData,
  GetChangePropertiesProps,
  GetFormErrorsData,
  GetLookupData,
  GetLookupProps,
  GetPropertiesData,
  GetPropertiesProps,
  RemovePropertyBodyStructure,
}

export { ChangePropertyEnum }
