const optimizedFlat = <T = unknown>(array: Array<T>): T[] => {
  let flattened: Array<T> = []

  array.forEach((item) => {
    if (Array.isArray(item)) {
      return (flattened = flattened.concat(optimizedFlat(item)))
    }

    return flattened.push(item)
  })

  return flattened
}

export { optimizedFlat }
