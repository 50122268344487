//@ts-nocheck

/**
 * Transformation matrices in the browser come in two flavors:
 *
 *  - `matrix` using 6 values (short)
 *  - `matrix3d` using 16 values (long)
 *
 * This utility follows this [conversion guide](https://goo.gl/EJlUQ1)
 * to expand short form matrices to their equivalent long form.
 *
 * @param  {array} source - Accepts both short and long form matrices.
 * @return {array}
 */
function format(source) {
  if (source.constructor !== Array) {
    throw new TypeError('Expected array.')
  }
  if (source.length === 16) {
    return source
  }
  if (source.length === 6) {
    const matrix = identity()
    matrix[0] = source[0]
    matrix[1] = source[1]
    matrix[4] = source[2]
    matrix[5] = source[3]
    matrix[12] = source[4]
    matrix[13] = source[5]
    return matrix
  }
  throw new RangeError('Expected array with either 6 or 16 values.')
}

/**
 * Returns a matrix representing no transformation. The product of any matrix
 * multiplied by the identity matrix will be the original matrix.
 *
 * > **Tip:** Similar to how `5 * 1 === 5`, where `1` is the identity.
 *
 * @return {array}
 */
function identity() {
  const matrix = []
  for (let i = 0; i < 16; i++) {
    i % 5 == 0 ? matrix.push(1) : matrix.push(0)
  }
  return matrix
}

/**
 * Returns a 4x4 matrix describing the combined transformations
 * of both arguments.
 *
 * > **Note:** Order is very important. For example, rotating 45°
 * along the Z-axis, followed by translating 500 pixels along the
 * Y-axis... is not the same as translating 500 pixels along the
 * Y-axis, followed by rotating 45° along on the Z-axis.
 *
 * @param  {array} m - Accepts both short and long form matrices.
 * @param  {array} x - Accepts both short and long form matrices.
 * @return {array}
 */
function multiply(m, x) {
  const fm = format(m)
  const fx = format(x)
  const product = []

  for (let i = 0; i < 4; i++) {
    const row = [fm[i], fm[i + 4], fm[i + 8], fm[i + 12]]
    for (let j = 0; j < 4; j++) {
      const k = j * 4
      const col = [fx[k], fx[k + 1], fx[k + 2], fx[k + 3]]
      const result = row[0] * col[0] + row[1] * col[1] + row[2] * col[2] + row[3] * col[3]

      product[i + k] = result
    }
  }

  return product
}

/**
 * Attempts to return a 4x4 matrix describing the CSS transform
 * matrix passed in, but will return the identity matrix as a
 * fallback.
 *
 * **Tip:** In virtually all cases, this method is used to convert
 * a CSS matrix (retrieved as a `string` from computed styles) to
 * its equivalent array format.
 *
 * @param  {string} source - String containing a valid CSS `matrix` or `matrix3d` property.
 * @return {array}
 */
function parse(source) {
  if (typeof source === 'string') {
    const match = source.match(/matrix(3d)?\(([^)]+)\)/)
    if (match) {
      const raw = match[2].split(', ').map(parseFloat)
      return format(raw)
    }
  }
  return identity()
}

/**
 * Returns a 4x4 matrix describing Z-axis rotation.
 *
 * @param  {number} angle - Measured in degrees.
 * @return {array}
 */
function rotate(angle) {
  return rotateZ(angle)
}

/**
 * Returns a 4x4 matrix describing Z-axis rotation.
 *
 * @param  {number} angle - Measured in degrees.
 * @return {array}
 */
function rotateZ(angle) {
  const theta = (Math.PI / 180) * angle
  const matrix = identity()

  matrix[0] = matrix[5] = Math.cos(theta)
  matrix[1] = matrix[4] = Math.sin(theta)
  matrix[4] *= -1

  return matrix
}

/**
 * Returns a 4x4 matrix describing 2D scaling. The first argument
 * is used for both X and Y-axis scaling, unless an optional
 * second argument is provided to explicitly define Y-axis scaling.
 *
 * @param  {number} scalar    - Decimal multiplier.
 * @param  {number} [scalarY] - Decimal multiplier.
 * @return {array}
 */
function scale(scalar, scalarY) {
  const matrix = identity()

  matrix[0] = scalar
  matrix[5] = typeof scalarY === 'number' ? scalarY : scalar

  return matrix
}

/**
 * Returns a 4x4 matrix describing X-axis scaling.
 *
 * @param  {number} scalar - Decimal multiplier.
 * @return {array}
 */
function scaleX(scalar) {
  const matrix = identity()
  matrix[0] = scalar
  return matrix
}

/**
 * Returns a 4x4 matrix describing Y-axis scaling.
 *
 * @param  {number} scalar - Decimal multiplier.
 * @return {array}
 */
function scaleY(scalar) {
  const matrix = identity()
  matrix[5] = scalar
  return matrix
}

/**
 * Returns a 4x4 matrix describing 2D translation. The first
 * argument defines X-axis translation, and an optional second
 * argument defines Y-axis translation.
 *
 * @param  {number} distanceX   - Measured in pixels.
 * @param  {number} [distanceY] - Measured in pixels.
 * @return {array}
 */
function translate(distanceX, distanceY) {
  const matrix = identity()
  matrix[12] = distanceX

  if (distanceY) {
    matrix[13] = distanceY
  }

  return matrix
}

/**
 * Returns a 4x4 matrix describing X-axis translation.
 *
 * @param  {number} distance - Measured in pixels.
 * @return {array}
 */
function translateX(distance) {
  const matrix = identity()
  matrix[12] = distance
  return matrix
}

/**
 * Returns a 4x4 matrix describing Y-axis translation.
 *
 * @param  {number} distance - Measured in pixels.
 * @return {array}
 */
function translateY(distance) {
  const matrix = identity()
  matrix[13] = distance
  return matrix
}

export {
  format,
  identity,
  multiply,
  parse,
  rotate,
  rotateZ,
  scale,
  scaleX,
  scaleY,
  translate,
  translateX,
  translateY,
}
