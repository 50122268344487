import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { FieldErrors } from 'react-hook-form'

import MenuItem from '@components/DocumentFormComponents/Menu/Item'
import { MapOfMenu } from '@components/DocumentFormComponents/types'
import Typography from '@components/NewDesign/Typography'
import CustomTransition from '@components/Transition'
import cn from 'classnames'

import styles from './Menu.module.scss'

interface DocumentFormMenuProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  mapOfMenu: MapOfMenu[]
  blockErrors: FieldErrors<any>
  blockViewIsValidating: boolean
  lastUpdateDraftTime?: string
  onMenuClick: (menuItem: MapOfMenu) => void
  activeMenuId?: string
  onBlockCheckError?: (id: string, blockErrors: FieldErrors<any>) => boolean
}

const DocumentFormMenu: FC<DocumentFormMenuProps> = ({
  blockViewIsValidating,
  lastUpdateDraftTime,
  mapOfMenu,
  blockErrors,
  activeMenuId,
  onBlockCheckError,
  onMenuClick,
  ...rest
}) => {
  return (
    <div {...rest} className={cn(styles.menu, rest.className)}>
      {mapOfMenu.map((item) => {
        const blockHaveError = onBlockCheckError?.(item.id, blockErrors)

        const blockNotHaveError = !blockHaveError

        return (
          <MenuItem
            key={item.id}
            item={item}
            className={styles.menu__item}
            activeMenuId={activeMenuId}
            dataTestId={item.id}
            typographyProps={{
              className: cn({
                [styles.menu__item__error]: blockHaveError && blockViewIsValidating,
                [styles.menu__item__success]: blockNotHaveError && blockViewIsValidating,
              }),
            }}
            onClick={onMenuClick}
          />
        )
      })}
      <CustomTransition.Fade show={!!lastUpdateDraftTime}>
        <Typography.Body
          className={styles.menu__save}
          color={'text-base-secondary'}
          variant={'bodyMRegular'}
        >
          {`Изменения автоматически сохранены ${lastUpdateDraftTime}`}
        </Typography.Body>
      </CustomTransition.Fade>
    </div>
  )
}

export default DocumentFormMenu
