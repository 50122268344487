import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import type { DefaultDictionaryType } from '@globalTypes/base'
import type { SWRHookProps } from '@interfaces'
import type { IReorganizationKey } from '@services/Dictionaries/dictionaries.entity'
import useSWRImmutable from 'swr/immutable'

const useReorganizationForm = ({
  key,
  config,
}: SWRHookProps<DefaultDictionaryType<IReorganizationKey>[] | undefined>) => {
  const {
    dictionariesApi: { getReorganizationsForm },
  } = useAPIContext()

  const {
    data: organizationForms,
    error,
    mutate,
    isValidating: isLoadingOrganizationForms,
  } = useSWRImmutable(key, getReorganizationsForm, config)

  const mapOfReorganizationForm = useMemo(() => {
    if (!organizationForms) return

    return organizationForms.reduce(
      (prevValue, currentValue) => ({
        ...prevValue,
        [currentValue.type]: currentValue.description,
      }),
      {},
    )
  }, [organizationForms])

  const formOptionsList = useMemo(() => {
    if (!organizationForms) return []

    return organizationForms.map((form) => ({
      displayValue: form.description,
      value: form.type,
    }))
  }, [organizationForms])

  return {
    organizationForms,
    mapOfReorganizationForm,
    formOptionsList,
    error,
    mutate,
    isLoadingOrganizationForms,
  }
}

export { useReorganizationForm }
