import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const eExploitationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionStage['exploitation'],
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  objectCommissioningEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { eExploitationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
