import React from 'react'
import { useFormContext } from 'react-hook-form'

import MunicipalitiesSection from '@components/DocumentFormComponents/FormSections/MunicipalitiesSection'
import { aAgreementForceCircumstancesBlockValues } from '@components/Forms/AAgreementForceCircumstancesForm/const'
import { useAAgreementForceCircumstancesManager } from '@components/Forms/AAgreementForceCircumstancesForm/Manager'
import { AAgreementForceCircumstancesFormValues } from '@components/Forms/AAgreementForceCircumstancesForm/types'
import {
  AAgreementForceCircumstancesFieldArrayControlUpdateWatcher,
  AAgreementForceCircumstancesFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementForceCircumstancesForm/watcher'

const Three = () => {
  const formInstance = useFormContext<AAgreementForceCircumstancesFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementForceCircumstancesManager()

  if (!formInstance) return null

  return (
    <MunicipalitiesSection
      formName={aAgreementForceCircumstancesBlockValues['3']['municipalities']}
      formInstance={formInstance}
      blockViewIsValidating={blockViewIsValidating}
      editMode={editMode}
      watcher={AAgreementForceCircumstancesFieldsControlUpdateWatcher}
      fieldArrayWatcher={AAgreementForceCircumstancesFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Three
