import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NotificationDrawalAppConclusionSZPKFormValues } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/types'

const generateRHFObjectForNotificationDrawalAppConclusionSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): NotificationDrawalAppConclusionSZPKFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorEgrulAddress:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value
          ?.addressFullEgrulFormat,
    },
    '2': {
      ruleLinkStatementRevoke: objectFromPropertyState.ruleLinkStatementRevoke?.value || '',
    },
  } as NotificationDrawalAppConclusionSZPKFormValues
}

export { generateRHFObjectForNotificationDrawalAppConclusionSZPKForm }
