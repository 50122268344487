import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  GetAmountHandlerProps,
  GetAmountHandlerReturnType,
  GetCalendarInputHandlerProps,
  GetCalendarInputHandlerReturnType,
  GetCheckboxHandlerProps,
  GetCheckboxHandlerReturnType,
  GetControlledHandlerProps,
  GetControlledHandlerReturnType,
  GetFormFieldControlHandlerProps,
  GetFormFieldControlHandlerReturnType,
  GetHierarchyReferenceBookSelectProps,
  GetHierarchyReferenceBookSelectReturnType,
  GetInputHandlerProps,
  GetInputHandlerReturnType,
  GetMaskInputHandlerProps,
  GetMaskInputHandlerReturnType,
  GetMultipleInputHandlerProps,
  GetMultipleInputHandlerReturnType,
  GetMultipleSelectHandlerProps,
  GetMultipleSelectHandlerReturnType,
  GetRadioGroupHandlerProps,
  GetRadioGroupHandlerReturnType,
  GetSingleSelectHandlerProps,
  GetSingleSelectHandlerReturnType,
  GetSubscribableControlHandlerProps,
  GetSubscribableControlHandlerReturnType,
  GetSwitchHandlerProps,
  GetSwitchHandlerReturnType,
  GetTextareaHandlerProps,
  GetTextareaHandlerReturnType,
} from '@components/DocumentFormComponents/hooks/useFormComponentPresets/types'
import { SubscribableFormControlProps } from '@components/DocumentFormComponents/SubscribableControl'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import { Patterns } from '@constants/validations'
import { isUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

const { validationAdapter } = DocumentFormHelpers

interface UseFormComponentPresetsProps<T extends FieldValues> {
  formInstance: UseFormReturn<T>
  isNew?: boolean
  editMode?: boolean
  blockViewIsValidating?: boolean
  watcher?: FieldsControlUpdateWatcher
  subscribableControl?: SubscribableFormControlProps<T>
  onChangeValue?: (pathName: Path<T>, batchBlockId?: string) => Promise<void>
  debouncedHandleChangeValue?: (pathName: Path<T>, batchBlockId?: string) => void
}

const useFormComponentPresets = <T extends FieldValues>({
  isNew = true,
  editMode = false,
  blockViewIsValidating = false,
  formInstance,
  watcher,
  subscribableControl,
  onChangeValue,
  debouncedHandleChangeValue,
}: UseFormComponentPresetsProps<T>) => {
  const debouncedChangeValue = (name: Path<T>) => () => {
    if (isUndefined(debouncedHandleChangeValue)) return

    setTimeout(() => debouncedHandleChangeValue(name), 0)
  }

  const handleChangeValue = (name: Path<T>) => () => {
    if (isUndefined(onChangeValue)) return

    setTimeout(() => onChangeValue(name), 0)
  }

  const getControllerProps = ({
    rules,
    name,
    ...restControllerProps
  }: GetControlledHandlerProps<T>): GetControlledHandlerReturnType<T> => {
    return {
      ...restControllerProps,
      control: formInstance?.control,
      name,
      ...(!isUndefined(rules) && {
        rules: validationAdapter(rules, {
          form: formInstance,
          name,
          needTrigger: blockViewIsValidating,
        }),
      }),
    }
  }

  const getSubscribableControlProps = ({
    path,
  }: GetSubscribableControlHandlerProps<T>): GetSubscribableControlHandlerReturnType<T> => {
    if (isUndefined(watcher) || isUndefined(subscribableControl))
      return {} as GetSubscribableControlHandlerReturnType<T>

    return {
      path,
      watcher: watcher,
      ...subscribableControl,
    }
  }

  const getFormFieldControlProps = <Modifier extends FieldValues | undefined = undefined>({
    path,
    formFieldTooltipProps,
    ...restProps
  }: GetFormFieldControlHandlerProps<T, Modifier>): GetFormFieldControlHandlerReturnType<
    T,
    Modifier
  > => {
    const { isNew: isNewFromFormFieldProps = isNew, ...restFormFieldTooltipProps } =
      formFieldTooltipProps || {}

    return {
      ...getSubscribableControlProps({
        path,
      }),
      formFieldTooltipProps: {
        ...restFormFieldTooltipProps,
        isNew: isNewFromFormFieldProps,
      },
      ...restProps,
    }
  }

  const getInputProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    inputProps,
    onBlur,
    onChange,
    ...restProps
  }: GetInputHandlerProps<T>): GetInputHandlerReturnType<T> => {
    const {
      fixWidth = true,
      disabled = !editMode,
      view = 'secondary',
      size = 'xl',
      ...restInputProps
    } = inputProps || {}

    return {
      ...restProps,
      inputProps: {
        ...restInputProps,
        fixWidth,
        disabled,
        view,
        size,
      },
      // Убрали onChange с задержкой перед показом
      onChange: (disabled || onBlur) ? undefined : onChange || debouncedChangeValue(name),
      onBlur: disabled ? undefined : onBlur || handleChangeValue(name),
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
    }
  }

  const getMultipleInputProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    multipleProps,
    inputProps,
    ...restProps
  }: GetMultipleInputHandlerProps<T>): GetMultipleInputHandlerReturnType<T> => {
    const {
      fixWidth = true,
      disabled = !editMode,
      view = 'secondary',
      size = 'xl',
      ...restInputProps
    } = inputProps || {}

    const {
      keyboardButton = 'Space',
      parseByString = ',',
      onCustomValidate = (value) => Patterns.Email.test(value),
      ...restMultipleProps
    } = multipleProps || {}

    return {
      ...restProps,
      multipleProps: {
        keyboardButton,
        parseByString,
        onCustomValidate,
        ...restMultipleProps,
      },
      inputProps: {
        ...restInputProps,
        fixWidth,
        disabled,
        view,
        size,
      },
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
    }
  }

  const getTextareaProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    textareaProps,
    onChange,
    onBlur,
    ...restProps
  }: GetTextareaHandlerProps<T>): GetTextareaHandlerReturnType<T> => {
    const {
      fixWidth = true,
      autosize = true,
      disabled = !editMode,
      minRows = 1,
      maxRows = Infinity,
      size = 'xl',
      ...restTextareaProps
    } = textareaProps || {}

    return {
      ...restProps,
      textareaProps: {
        ...restTextareaProps,
        disabled,
        fixWidth,
        autosize,
        minRows,
        maxRows,
        size,
      },
      // Убрали onChange с задержкой перед показом
      onChange: (disabled || onBlur) ? undefined : onChange || debouncedChangeValue(name),
      onBlur: disabled ? undefined : onBlur || handleChangeValue(name),
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
    }
  }

  const getAmountInputProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    inputProps,
    onChange,
    onBlur,
    ...restProps
  }: GetAmountHandlerProps<T>): GetAmountHandlerReturnType<T> => {
    const {
      hideCurrency = true,
      fixWidth = true,
      disabled = !editMode,
      view = 'secondary',
      minorPartView = 'withZeroMinorPart',
      size = 'xl',
      ...restInputProps
    } = inputProps || {}

    return {
      ...restProps,
      inputProps: {
        ...restInputProps,
        disabled,
        fixWidth,
        hideCurrency,
        view,
        size,
        minorPartView,
      },
      // Убрали onChange с задержкой перед показом
      onChange: (disabled || onBlur) ? undefined : onChange || debouncedChangeValue(name),
      onBlur: disabled ? undefined : onBlur || handleChangeValue(name),
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
    }
  }

  const getCalendarInputProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    calendarInputProps,
    onChange,
    onBlur,
    onCalendarChange,
    ...restProps
  }: GetCalendarInputHandlerProps<T>): GetCalendarInputHandlerReturnType<T> => {
    const {
      disabled = !editMode,
      fixWidth = true,
      view = 'secondary',
      size = 'xl',
      ...restCalendarInputProps
    } = calendarInputProps || {}

    return {
      ...restProps,
      calendarInputProps: {
        ...restCalendarInputProps,
        disabled,
        fixWidth,
        view,
        size,
      },
      // Убрали onInputChange и onCalendarChange с задержкой перед показом
      onInputChange: onBlur ? undefined : restProps.onInputChange,
      onChange: disabled ? undefined : onChange || debouncedChangeValue(name),
      onBlur: disabled ? undefined : onBlur || handleChangeValue(name),
      onCalendarChange: disabled ? undefined : onCalendarChange || handleChangeValue(name),
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
    }
  }

  const getMaskInputProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    inputProps,
    onChange,
    onBlur,
    ...restProps
  }: GetMaskInputHandlerProps<T>): GetMaskInputHandlerReturnType<T> => {
    const {
      disabled = !editMode,
      fixWidth = true,
      view = 'secondary',
      size = 'xl',
      ...restInputProps
    } = inputProps || {}

    return {
      ...restProps,
      inputProps: {
        ...restInputProps,
        disabled,
        view,
        fixWidth,
        size,
      },
      // Убрали onChange с задержкой перед показом
      onChange: (disabled || onBlur) ? undefined : onChange || debouncedChangeValue(name),
      onBlur: disabled ? undefined : onBlur || handleChangeValue(name),
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
    }
  }

  const getSwitchProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    switchProps,
    onChange,
    ...restProps
  }: GetSwitchHandlerProps<T>): GetSwitchHandlerReturnType<T> => {
    const { disabled = !editMode, wrapperClassName, ...restSwitchProps } = switchProps || {}

    return {
      ...restProps,
      switchProps: {
        ...restSwitchProps,
        disabled,
        wrapperClassName: cn(styles.form__switch, wrapperClassName),
      },
      onChange: disabled ? undefined : onChange || handleChangeValue(name),
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
    }
  }

  const getCheckboxProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    checkBoxProps,
    onChange,
    ...restProps
  }: GetCheckboxHandlerProps<T>): GetCheckboxHandlerReturnType<T> => {
    const { disabled = !editMode, ...restCheckboxProps } = checkBoxProps || {}

    return {
      ...restProps,
      checkBoxProps: {
        ...restCheckboxProps,
        disabled,
      },
      onChange: disabled ? undefined : onChange || handleChangeValue(name),
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
    }
  }

  const getSingleSelectProps = ({
    controllerProps,
    selectProps,
    ...restProps
  }: GetSingleSelectHandlerProps<T>): GetSingleSelectHandlerReturnType<T> => {
    const {
      disabled = !editMode,
      inputProps,
      onChangeFormValue,
      ...restSelectProps
    } = selectProps || {}

    const { fixWidth = true, view = 'secondary', size = 'xl', ...restInputProps } = inputProps || {}

    return {
      ...restProps,
      selectProps: {
        ...restSelectProps,
        disabled,
        onChangeFormValue: disabled
          ? undefined
          : onChangeFormValue || handleChangeValue(controllerProps.name),
        inputProps: {
          ...restInputProps,
          fixWidth,
          view,
          size,
        },
      },
      controllerProps: getControllerProps(controllerProps),
    }
  }

  const getMultipleSelectProps = ({
    controllerProps,
    selectProps,
    ...restProps
  }: GetMultipleSelectHandlerProps<T>): GetMultipleSelectHandlerReturnType<T> => {
    const { disabled = !editMode, inputProps, ...restSelectProps } = selectProps || {}

    const { fixWidth = true, view = 'secondary', size = 'xl', ...restInputProps } = inputProps || {}

    return {
      ...restProps,
      selectProps: {
        ...restSelectProps,
        disabled,
        inputProps: {
          ...restInputProps,
          fixWidth,
          view,
          size,
        },
      },
      controllerProps: getControllerProps(controllerProps),
    }
  }

  const getRadioGroupProps = ({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    radioGroupProps,
    onChange,
    children,
    ...restProps
  }: GetRadioGroupHandlerProps<T>): GetRadioGroupHandlerReturnType<T> => {
    const { disabled = !editMode, ...restRadioGroupProps } = radioGroupProps || {}

    return {
      ...restProps,
      radioGroupProps: {
        disabled,
        ...restRadioGroupProps,
      },
      onChange: disabled ? undefined : onChange || handleChangeValue(name),
      ...getControllerProps({
        name,
        rules,
        defaultValue,
        shouldUnregister,
      }),
      children,
    }
  }

  const getHierarchyReferenceBookSelectProps = ({
    controllerProps,
    inputProps,
    onChange,
    ...restProps
  }: GetHierarchyReferenceBookSelectProps<T>): GetHierarchyReferenceBookSelectReturnType<T> => {
    const {
      disabled = !editMode,
      fixWidth = true,
      view = 'secondary',
      size = 'xl',
      ...restInputProps
    } = inputProps || {}

    return {
      ...restProps,
      inputProps: {
        ...restInputProps,
        disabled,
        fixWidth,
        view,
        size,
      },
      onChange: disabled ? undefined : onChange || handleChangeValue(controllerProps.name),
      controllerProps: getControllerProps(controllerProps),
    }
  }

  return {
    getInputProps,
    getMultipleInputProps,
    getTextareaProps,
    getAmountInputProps,
    getCalendarInputProps,
    getMaskInputProps,
    getSwitchProps,
    getCheckboxProps,
    getSingleSelectProps,
    getMultipleSelectProps,
    getControllerProps,
    getRadioGroupProps,
    getSubscribableControlProps,
    getHierarchyReferenceBookSelectProps,
    getFormFieldControlProps,
  }
}

export { useFormComponentPresets }
