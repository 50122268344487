import React from 'react'
import { useFormContext } from 'react-hook-form'

import RFSection from '@components/DocumentFormComponents/FormSections/RFSection'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'

const First = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  if (!formInstance) return null

  return (
    <RFSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      onChangeValue={handleChangeValue}
    />
  )
}

export default First
