import { useMemo } from 'react'

import { SystemVariablesTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import {
  SystemVariablesData,
  TransformedSystemVariableItem,
} from '@context/APIContext/hooks/useOperatorApi'
import { ValueOf } from '@helpers/ValueOf'
import { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useSystemVariables = ({ key, config }: SWRHookProps<SystemVariablesData | undefined>) => {
  const {
    operatorApi: { getSystemVariables },
  } = useAPIContext()

  const {
    data: systemVariables,
    isValidating: isLoadingSystemVariables,
    error,
    mutate,
  } = useSWRImmutable(key, getSystemVariables, config)

  const preparedSystemVariables: TransformedSystemVariableItem[] = useMemo(() => {
    if (!systemVariables || !systemVariables.length) return []

    return systemVariables.map((item) => {
      const [type, value] = Object.entries(item)[0]

      return {
        type: type as ValueOf<typeof SystemVariablesTypes>,
        value: value || '',
      }
    })
  }, [systemVariables, systemVariables?.length])

  return {
    systemVariables: preparedSystemVariables,
    isLoadingSystemVariables,
    error,
    mutate,
  }
}

export { useSystemVariables }
