import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react'

import { IconProps } from '@components/Icon'
import { LoaderProps } from '@components/Loader/Loader'

type TGeometry = 'square' | 'round'

type TVariant = 'buttonMMedium' | 'buttonSMedium'

export type TSize = 'xl' | 'l' | 'm' | 's' | 'xs' | '2xs'

type TFontWeight = 'normal' | 'medium' | 'semibold' | 'bold'

type TView = 'filled' | 'outline' | 'tined' | 'gray' | 'plain' | 'text'

type TColor = 'default' | 'negative' | 'green' | 'accent'

type AnchorButtonProps = ComponentProps & AnchorHTMLAttributes<HTMLAnchorElement>
type NativeButtonProps = ComponentProps & ButtonHTMLAttributes<HTMLButtonElement>

export const LoaderPlacement = Object.freeze({
  trailing: 'trailing',
  leading: 'leading',
})

export type TLoaderPlacement = keyof typeof LoaderPlacement

export type ButtonProps = Partial<AnchorButtonProps | NativeButtonProps> & {
  disabled?: boolean
}

export interface ComponentProps {
  geometry?: TGeometry
  fixWidth?: boolean
  fontWeight?: TFontWeight
  href?: string
  target?: string
  icon?: JSX.Element
  leadingIcon?: IconProps
  loaderProps?: LoaderProps & { placement: TLoaderPlacement }
  color?: TColor
  rounded?: boolean
  size?: TSize
  trailingIcon?: IconProps
  variant?: TVariant
  view: TView
  accent?: boolean
  textClassName?: string
  dataTestId?: string
}
