import React from 'react'
import { useFormContext } from 'react-hook-form'

import SubjectsSection from '@components/DocumentFormComponents/FormSections/SubjectsSection'
import { aaVolumeOfCapitalInvestmentsBlockValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/const'
import { useAAVolumeOfCapitalInvestmentsManager } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Manager'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import {
  AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher,
  AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
} from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'

const Second = () => {
  const formInstance = useFormContext<AAVolumeOfCapitalInvestmentsFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAVolumeOfCapitalInvestmentsManager()

  if (!formInstance) return null

  return (
    <SubjectsSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={aaVolumeOfCapitalInvestmentsBlockValues['2']['subjects']}
      formInstance={formInstance}
      watcher={AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher}
      fieldArrayWatcher={AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Second
