import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { SeventhRidRegistrationObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { ridRegistrationsProjectSZPKValidationMap } from './validation'

interface RRegistrationFormItemProps {
  formName: SeventhRidRegistrationObjectsArrayPathName
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
}

const RRegistrationFormItem: FC<RRegistrationFormItemProps> = ({
  blockViewIsValidating,
  formInstance,
  formName,
}) => {
  const {
    state: { editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getSingleSelectProps, getInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ProjectSZPKFieldsControlUpdateWatcher,
    })

  return (
    <div>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.ridName`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          value: item.id,
                          displayValue: item.ridName,
                        }),
                        controllerProps: {
                          name: `${formName}.ridName`,
                          rules: ridRegistrationsProjectSZPKValidationMap.ridName,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Наименование зарегистрированного РИД',
                          },
                          onChangeFormValue: () =>
                            setTimeout(() => handleChangeValue?.(`${formName}.ridName`), 0),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.ridStateRegistrationNumber`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.ridStateRegistrationNumber`,
                    rules: ridRegistrationsProjectSZPKValidationMap.ridStateRegistrationNumber,

                    inputProps: {
                      label: 'Реквизиты документа, подтверждающего регистрацию',
                    },
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.ridStateRegistrationNumber`),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.ridStateRegistrationNumber`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.ridStateRegistrationOrganization`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.ridStateRegistrationOrganization`,
                    rules:
                      ridRegistrationsProjectSZPKValidationMap.ridStateRegistrationOrganization,
                    inputProps: {
                      label: 'Регистрирующий орган',
                      caption: 'в творительном падеже',
                    },
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.ridStateRegistrationOrganization`),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.ridStateRegistrationOrganization`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </Col>
    </div>
  )
}

export default RRegistrationFormItem
