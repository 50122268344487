import { type FC, createContext, useContext } from 'react'

import useAttachmentFileApi from '@components/Attachments/Attachment/APIProvider/hooks/useAttachmentFileApi'
import useAttachmentFolderApi from '@components/Attachments/Attachment/APIProvider/hooks/useAttachmentFolderApi'

interface AttachmentAPIContextProps {
  attachmentFileApi: ReturnType<typeof useAttachmentFileApi>
  attachmentFolderApi: ReturnType<typeof useAttachmentFolderApi>
}

const AttachmentAPIContext = createContext<AttachmentAPIContextProps>(
  {} as AttachmentAPIContextProps,
)

const AttachmentAPIProvider: FC = ({ children }) => {
  const attachmentFileApi = useAttachmentFileApi()
  const attachmentFolderApi = useAttachmentFolderApi()

  return (
    <AttachmentAPIContext.Provider
      value={{
        attachmentFileApi,
        attachmentFolderApi,
      }}
    >
      {children}
    </AttachmentAPIContext.Provider>
  )
}

const useAttachmentAPIContext = () => {
  return useContext(AttachmentAPIContext)
}

export { AttachmentAPIProvider, useAttachmentAPIContext }
