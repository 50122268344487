import React, { FC, memo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import Form from '@components/Form'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import Typography from '@components/NewDesign/Typography'
import { defaultRHFValidation } from '@constants/validations'
import arrowBackIcon from '@icons/navigation/arrow_back.svg'

import type { HandleSubmitFormProps, ProcurationFormValues } from '../types'

import {
  otherProcurationComponentInfo,
  otherProcurationDefaultFormValues,
  otherProcurationFormNamesMap,
  UUID_MAX_LENGTH,
} from './constants'
import styles from './OtherProcuration.module.scss'

interface OtherProcurationProps {
  onBackButton: VoidFunction
  onSubmitForm: (formValues: HandleSubmitFormProps) => Promise<void>
}

const OtherProcuration: FC<OtherProcurationProps> = ({ onBackButton, onSubmitForm }) => {
  const { watch, control, handleSubmit } = useForm<ProcurationFormValues>({
    defaultValues: otherProcurationDefaultFormValues,
  })

  const organizationINN = watch(otherProcurationFormNamesMap.organizationINN)
  const MChDNumber = watch(otherProcurationFormNamesMap.MChDNumber)

  const onSubmit: SubmitHandler<ProcurationFormValues> = async ({
    MChDNumber,
    organizationINN,
  }) => {
    await onSubmitForm({
      MChDNumber: MChDNumber.trim(),
      organizationINN,
      componentInfo: otherProcurationComponentInfo,
    })
  }

  return (
    <Form dataTestId="OtherProcuration-form" onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.container}>
        <div className={styles.title}>
          <IconButton
            size="m"
            className={styles.title__icon}
            dataTestId="OtherProcuration-back-button"
            icon={{
              src: arrowBackIcon,
            }}
            onClick={onBackButton}
          />
          <Typography.Headline variant="headlineH2" align="center">
            Укажите доверенность
          </Typography.Headline>
        </div>
        <Typography.Body variant="bodyMRegular" className={styles.text} align="center">
          Чтобы действовать от лица организации, введите реквизиты машиночитаемой доверенности,
          выданной на Ваше имя
        </Typography.Body>
      </div>
      <div className={styles.container}>
        <div className={styles.form}>
          <Form dataTestId="OtherProcuration-container-form">
            <ControlledInput
              name={otherProcurationFormNamesMap.MChDNumber}
              control={control}
              rules={{
                required: defaultRHFValidation.required,
              }}
              inputProps={{
                rootClassName: styles.formInput,
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Номер машиночитаемой доверенности',
                maxLength: UUID_MAX_LENGTH,
                dataTestId: `OtherProcuration-${otherProcurationFormNamesMap.MChDNumber}-input`,
              }}
            />
            <ControlledMaskInput
              name={otherProcurationFormNamesMap.organizationINN}
              control={control}
              rules={{
                required: defaultRHFValidation.required,
              }}
              inputProps={{
                rootClassName: styles.formInput,
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                mask: '9999999999',
                maskPlaceholder: '',
                label: 'ИНН организации',
                dataTestId: `OtherProcuration-${otherProcurationFormNamesMap.organizationINN}-input`,
              }}
            />
          </Form>
        </div>
        <Button
          fixWidth
          type="submit"
          dataTestId="OtherProcuration-submitButton"
          className={styles.submit}
          disabled={!MChDNumber || !organizationINN}
        >
          Войти
        </Button>
      </div>
    </Form>
  )
}

export default memo(OtherProcuration)
