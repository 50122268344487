import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'

import cn from 'classnames'

import styles from './Menu.module.scss'

type MenuComponent = FC<{ sticky?: boolean; className?: string }> & {
  Link: FC<{ to: string; className?: string }>
}

const Menu: MenuComponent = ({ children, sticky, className }) => {
  return (
    <ul
      className={cn(styles.list, className, {
        [styles.sticky]: sticky,
      })}
    >
      {children}
    </ul>
  )
}

Menu.Link = ({ children, to, className }) => {
  const { pathname } = useLocation()
  return (
    <li
      className={cn(styles.item, className, {
        [styles.active]: to === pathname,
      })}
    >
      <Link to={to}>{children}</Link>
    </li>
  )
}

export default Menu
