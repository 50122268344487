const fromHexToText = (hexStr: string): string => {
  // Удаляем префикс "0x", "0X" или "#", если он есть.
  const cleanHexStr = hexStr.replace(/^(0x|0X|#)/, '')
  // Разбиваем очищенную строку на массив из пар символов и конвертируем каждую пару в символ.
  const text = Array.from({ length: cleanHexStr.length / 2 }, (_, i) =>
    String.fromCharCode(parseInt(cleanHexStr.substr(i * 2, 2), 16)),
  ).join('')

  return text
}

export { fromHexToText }
