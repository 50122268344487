import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps } from 'react-popup-manager'

import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFormControl } from '@components/DocumentFormComponents/hooks/useFormControl'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { defaultFormValues } from '@components/Forms/CreateStatementOld/const'
import StatementDataConverter from '@components/Forms/CreateStatementOld/converter'
import StatementHelpers from '@components/Forms/CreateStatementOld/helpers'
import { useCheckStatementChanges } from '@components/Forms/CreateStatementOld/hooks/useCheckStatementChanges'
import {
  CreateStatementModalContextProps,
  DlcForResponse,
  IHandleStatementClose,
  IStatementFormModalState,
  IViolation,
  StatementOldFormValues,
} from '@components/Forms/CreateStatementOld/types'
import { HierarchyTypeSelectOption } from '@components/NewDesign/Select/HierarchyReferenceBookSelect/types'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import { errorModalBodyTexts, errorModalButtonTexts, errorModalHeaderTexts } from '@constants/texts'
import { DocumentsType } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { IGetStatementOldResponse } from '@context/APIContext/types'
import { isAxiosError, isBlob, isNullOrUndefined } from '@helpers/checkTypes'
import { useRegions } from '@hooks/new/swr/useRegions'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { ComponentInfo } from '@services/LoggerService/LoggerService.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { unstable_serialize } from 'swr'

const STATEMENT_REVALIDATE_TIME = 60 * 1000

export const CreateStatementModalContext = createContext<CreateStatementModalContextProps>({
  state: {
    dfoId: '',
    projectId: '',
    statementIsLoading: false,
    modalState: {
      title: '',
      isLoading: false,
      formationShow: false,
    },
    lastUpdateDraftTime: '',
    blockViewIsValidating: false,
    statementRegions: [],
  },
  handlers: {},
  actions: {},
})

interface CreateStatementModalManagerProps extends PopupProps {
  dfoId: string
  documentSetId: string

  projectId: string

  initialErrorsFromViolations?: IViolation[]
}

interface HandleUpdateStatementProps {
  applyErrors?: boolean
}

const CreateStatementModalManager: FC<CreateStatementModalManagerProps> = ({
  children,
  onClose,
  dfoId,
  documentSetId,
  projectId,
  initialErrorsFromViolations,
}) => {
  const {
    draftApi: { getDraft, putDraft, getDraftPreview },
    referenceBooksApi: { getReferenceBookByCode },
  } = useAPIContext()

  const { handleOpenErrorModal } = useErrorModal()

  const [statementIsLoading, setStatementIsLoading] = useState<boolean>(true)
  const [modalState, setModalState] = useState({
    title: '',
    formationShow: false,
    isLoading: true,
  })
  const [lastUpdateDraftTime, setLastUpdateDraftTime] = useState<string>('')
  const { formState, intervalState } = useFormControl()

  const { regions } = useRegions({
    key: {
      _key: 'regions',
    },
  })

  const {
    lastResponseKey,
    checkStatementToChanges,
    updateLastResponseKey,
    callSaveChangesModal,
    withCheckChangesBeforeApply,
  } = useCheckStatementChanges({ dfoId, projectId, documentSetId })

  const formInstance = useForm<StatementOldFormValues>({
    defaultValues: defaultFormValues,
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })

  const preparedRegionsForStatement = useMemo(() => {
    if (!regions) return []

    return Object.entries(regions).map(([, value]) => ({
      displayValue: value,
      value: value,
    }))
  }, [regions])

  const initializeRHFFromApi = (
    statement: IGetStatementOldResponse,
    dlcResponse: DlcForResponse,
  ) => {
    try {
      //Получаем готовую форму из statement.response
      const readyObjToApply = StatementHelpers.getRHFObjectFromStatementApi(statement, dlcResponse)

      //Сбрасываем форму c значениями из API
      formInstance.reset(readyObjToApply)

      return readyObjToApply
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          dlcResponse,
        },
        componentInfo: {
          componentName: 'CreateStatementModalManager',
          componentType: 'getRHFObject',
          moduleName: 'CreateStatementLayout',
        },
      })(error)
    }
  }

  const getSelectedOkvedByCode = async (
    code: string,
  ): Promise<HierarchyTypeSelectOption | null> => {
    const selectedOkved = await getReferenceBookByCode({
      dictionaryName: 'okved',
      code,
      fetcherOptions: {
        disableErrorHandling: true,
      },
    })

    if (!selectedOkved) return null

    return {
      id: selectedOkved.id,
      code: selectedOkved.code,
      name: selectedOkved.name,
    }
  }

  useEffect(() => {
    if (!statementIsLoading && formInstance) {
      formInstance.setFocus('investorGeneralInfo.fullName')
    }
  }, [formInstance, statementIsLoading])

  useEffect(() => {
    //Задержка открытия окна анимации
    const timeoutId = setTimeout(async () => {
      try {
        //Запрашиваем заявление
        const statement = await getDraft<IGetStatementOldResponse>({
          dfoId,
          projectId,
          documentSetId,
          documentType: DocumentsType.STATEMENT,
        })

        if (lastResponseKey) {
          lastResponseKey.current = unstable_serialize(statement)
        }

        //Сюда добавляются поля, которые должны запрашиваться отдельно от response и мутировать его для react hook form
        const dlcResponse: DlcForResponse = {
          okved: statement.investorGeneralInfo.okved
            ? {
                code: statement.investorGeneralInfo.okved,
                id: '',
                name: '',
              }
            : null,
          activityKindOkved: statement.generalInfo.activityKindOkved
            ? {
                code: statement.generalInfo.activityKindOkved,
                id: '',
                name: '',
              }
            : null,
        }

        try {
          //Запрашиваем окведы проекта
          if (statement.generalInfo.activityKindOkved) {
            const selectedActivityKindOkved = await getSelectedOkvedByCode(
              statement.generalInfo.activityKindOkved,
            )

            dlcResponse.activityKindOkved = !isNullOrUndefined(selectedActivityKindOkved)
              ? selectedActivityKindOkved
              : dlcResponse.activityKindOkved
          }

          //Запрашиваем окведы организации
          if (statement.investorGeneralInfo.okved) {
            const selectedOkved = await getSelectedOkvedByCode(statement.investorGeneralInfo.okved)

            dlcResponse.okved = !isNullOrUndefined(selectedOkved)
              ? selectedOkved
              : dlcResponse.okved
          }
        } catch (error) {
          const additionInfo = {
            dlcResponse,
          }

          LoggerHelpersService.handleMultipleLogError({
            additionInfo,
            componentInfo: {
              componentName: 'CreateStatementModalManager',
              moduleName: 'CreateStatementLayout',
              componentType: 'getDfoOKVED',
            },
          })(error)
        }

        initializeRHFFromApi(statement, dlcResponse)

        //Сначала ререндер, потом подводка к текущему якорю
        setStatementIsLoading(false)
      } catch (error) {
        const additionInfo = {
          documentSetId,
          documentType: DocumentsType.STATEMENT,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'CreateStatementModalManager',
            moduleName: 'CreateStatementLayout',
            componentType: 'getStatement',
          },
        })(error)

        throw error
      } finally {
        setStatementIsLoading(false)
      }
    }, 750)

    intervalState.intervalRef.current = setInterval(async () => {
      if (!dfoId) return

      await intervalState.handleInterval({
        fetcher: async () => {
          await checkStatementToChanges?.()

          await handleUpdateStatementDraft({ applyErrors: false })
        },
        errorAlertText: 'Заявление было обновлёно другим пользователем',
      })
    }, STATEMENT_REVALIDATE_TIME)

    return () => {
      clearTimeout(timeoutId)
      clearDraftSaveInterval()
    }
  }, [dfoId])

  const clearDraftSaveInterval = () =>
    intervalState.intervalRef.current && clearInterval(intervalState.intervalRef.current)

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading: formState.isFormLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  useEffect(() => {
    if (statementIsLoading || !initialErrorsFromViolations) return

    applyErrorsFromViolations(initialErrorsFromViolations)

    handleChangeBlockValidation(true)
  }, [applyErrorsFromViolations, formInstance, initialErrorsFromViolations, statementIsLoading])

  const handleChangeModalState = (newState: IStatementFormModalState) => {
    setModalState(newState)
  }

  const callErrorModal = () => {
    handleOpenErrorModal({
      headerText: errorModalHeaderTexts.szpkIncorrect,
      bodyText: errorModalBodyTexts.fixErrorsMessage,
      customActions: {
        actions: [
          {
            dataTestId: 'ErrorModal-returnToStatement-button',
            children: errorModalButtonTexts.returnToStatement,
            fixWidth: true,
            bindOnClose: true,
          },
        ],
        mergeDefault: false,
      },
    })
  }

  const handleUpdateStatementDraft = async ({ applyErrors = true }: HandleUpdateStatementProps) => {
    const dataFromForm = formInstance.getValues() as StatementOldFormValues
    const converter = new StatementDataConverter(formInstance.control, dataFromForm)

    try {
      await putDraft({
        dfoId,
        documentSetId,
        projectId,
        documentType: DocumentsType.STATEMENT,
        body: converter.fromRHFToApi(),
      })

      await updateLastResponseKey()

      handleChangeLastUpdateDraft(DayjsService.dayjsWithFormatToMSK().format('D\u00A0MMMM в HH:mm'))

      return Promise.resolve()
    } catch (error) {
      const additionInfo = {
        documentSetId,
        documentType: DocumentsType.STATEMENT,
        applyErrors,
        body: converter.fromRHFToApi(),
      }
      const componentInfo = {
        componentName: 'CreateStatementModalManager',
        moduleName: 'LayoutWrapper',
        componentType: 'saveStatement',
      }

      if (isAxiosError(error) && applyErrors) {
        const errorResponse = error.response?.data
        if (!errorResponse) return

        if (!Array.isArray(errorResponse.violations)) {
          handleOpenErrorModal({
            headerText: errorResponse.detail || errorModalHeaderTexts.defaultMessage,
            bodyText: '',
          })

          LoggerHelpersService.handleMultipleLogError({
            additionInfo,
            componentInfo,
          })(error)

          throw error
        }

        callErrorModal()

        applyErrorsFromViolations(error.response?.data.violations)
      }

      LoggerHelpersService.handleMultipleLogError({
        additionInfo,
        componentInfo,
      })(error)

      throw error
    }
  }

  const getPdfOfStatement = async () => {
    try {
      await getDraftPreview({
        dfoId,
        documentSetId,
        projectId,
        documentType: DocumentsType.STATEMENT,
      })
    } catch (error) {
      const additionInfo = {
        documentSetId,
        documentType: DocumentsType.STATEMENT,
      }
      const componentInfo: ComponentInfo = {
        componentName: 'CreateStatementModalManager',
        moduleName: 'CreateStatementLayout',
        componentType: 'getDraftPreview',
      }

      if (isAxiosError(error) && isBlob(error.response?.data)) {
        const errorResponse = JSON.parse((await error.response?.data.text()) || '')

        if (!errorResponse.violations) {
          handleOpenErrorModal({
            headerText: errorResponse.detail || errorModalHeaderTexts.defaultMessage,
            bodyText: '',
          })

          LoggerHelpersService.handleMultipleLogError({
            additionInfo,
            componentInfo,
          })(error)

          throw error
        }

        callErrorModal()

        applyErrorsFromViolations(errorResponse.violations)
      }

      LoggerHelpersService.handleMultipleLogError({
        additionInfo,
        componentInfo,
      })(error)

      throw error
    }
  }

  const handleCheckForm = async () => {
    if (!dfoId) return

    try {
      handleChangeModalState({
        title: 'Проверка корректности заполнения',
        formationShow: true,
        isLoading: true,
      })

      await handleUpdateStatementDraft({ applyErrors: true })

      await getPdfOfStatement()
    } catch (e) {
      handleChangeBlockValidation(true)
      throw e
    } finally {
      handleChangeModalState({
        title: '',
        formationShow: false,
        isLoading: false,
      })
    }
  }

  const handleStatementClose = async (props?: IHandleStatementClose) => {
    try {
      if (props?.saveDraft) {
        await handleUpdateStatementDraft({ applyErrors: false })
      }
    } finally {
      onClose?.()
    }
  }

  const handleChangeLastUpdateDraft = (newDate: string) => {
    setLastUpdateDraftTime(newDate)
  }

  return (
    <CreateStatementModalContext.Provider
      value={{
        state: {
          dfoId,
          projectId,
          formInstance,
          statementIsLoading,
          modalState,
          blockViewIsValidating,
          lastUpdateDraftTime,
          initialErrorsFromViolations,
          statementRegions: preparedRegionsForStatement,
        },
        handlers: {
          checkStatementToChanges,
          updateLastResponseKey,
          callSaveChangesModal,
          withCheckChangesBeforeApply,
          handleChangeModalState,
          handleChangeBlockValidation,
        },
        actions: {
          handleStatementClose,
          handleCheckForm,
        },
      }}
    >
      {children}
    </CreateStatementModalContext.Provider>
  )
}

const useCreateStatementManager = () => {
  return useContext(CreateStatementModalContext)
}

export { useCreateStatementManager }

export default CreateStatementModalManager
