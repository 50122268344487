import React, { memo, useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import Chip from '@components/Chip'
import Error500Fallback from '@components/DataFallback/500Error'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'
import Projects from '@components/Projects'
import Project from '@components/Projects/[id]'
import HamburgerActionsButton from '@components/Projects/[id]/ActionBanner/Button/HamburgerActions'
import UiActionsManager from '@components/Projects/[id]/ActionBanner/Manager'
import { useHeaderCollapseManager } from '@components/Projects/[id]/HeaderCollapse'
import { getDfoByType } from '@components/Projects/[id]/helpers'
import LayoutModeManager from '@components/Projects/[id]/LayoutMods'
import ParticipantsOfStatementManager from '@components/Projects/[id]/ParticipantsOfStatement/Manager'
import { useEventSidebarControl } from '@components/Sidebars/Events/store'
import { Skeleton } from '@components/Skeleton/Skeleton'
import { SkeletonText } from '@components/Skeleton/Text/SkeletonText'
import CustomTransition from '@components/Transition'
import { useUiControlManager } from '@components/UiControl'
import { Paths } from '@constants/paths'
import { DfosStage, NewDfosType } from '@constants/types'
import { RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { getProjectStatus } from '@hooks/new/statuses/helpers'
import { useProject } from '@hooks/new/swr/useProject'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useProjectMembers } from '@hooks/new/swr/useProjectMembers'
import { useVersions } from '@hooks/new/swr/useVersions'
import { useQueryManager } from '@hooks/useQueryManager'
import backArrowIcon from '@icons/BackArrow.svg'
import HistoryIcon from '@icons/HistoryIcon.svg'
import PlaceIcon from '@icons/PlaceIcon.svg'
import Layout from '@layouts'
import HeaderPortal from '@layouts/Header/Portal'
import { buildSZPKStatus } from '@routes/Projects/[id]/ProjectStructure/helpers'
import { TAllDfoTypes } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import styles from '../Project.module.scss'

const ProjectStructure = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { queryUtils } = useQueryManager()

  const {
    state: { eventSidebarControlIsDisabled },
    handlers: { handleChangeEventControl },
  } = useUiControlManager()

  const { isCollapsed, bindHeadlineRef } = useHeaderCollapseManager()
  const { openEventSideBarPanel } = useEventSidebarControl(projectId)

  const {
    project,
    isLoadingProject,
    error: projectError,
  } = useProject({
    key: { projectId: projectId, _key: 'project' },
  })

  const { projectDfos, error: projectDfosError } = useProjectDfos({
    key: { projectId, _key: 'projectDfos' },
    config: {
      isPaused: () => !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ProjectStructure',
          moduleName: 'ProjectLayout',
        },
      }),
    },
  })

  const dfoId = queryUtils.getQuery('dfoId')

  const { checkingRole } = useAuthContext()
  const isMER = !!checkingRole?.(RolesTypes.MER)
  const isRegionalMonitoringMER =
    projectDfos?.find(
      (dfo) => NewDfosType.MONITORING_REPORT_REGIONAL === dfo.type && dfo.id === dfoId,
    ) && isMER

  const SZPKDfo = useMemo(() => {
    if (!projectDfos) return

    return getDfoByType(projectDfos, NewDfosType.SZPK)
  }, [projectDfos])

  const INVESTPROJECTDfo = useMemo(() => {
    if (!projectDfos) return

    return getDfoByType(projectDfos, NewDfosType.INVESTPROJECT)
  }, [projectDfos])

  const MONITORINGDfo = projectDfos?.find(
    (dfo) =>
      (
        [
          NewDfosType.MONITORING_REPORT_FEDERAL,
          NewDfosType.MONITORING_REPORT_REGIONAL,
        ] as TAllDfoTypes[]
      ).includes(dfo.type) && dfo.id === dfoId,
  )

  const { versions } = useVersions({
    key: {
      projectId,
      dfoId: INVESTPROJECTDfo?.id,
      _key: 'versions',
    },
    config: {
      isPaused: () => !projectId || !INVESTPROJECTDfo?.id,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ProjectStructure',
          moduleName: 'ProjectLayout',
        },
      }),
    },
  })

  const actualProjectStatus = getProjectStatus({
    szpkRegisteredDate: SZPKDfo?.registeredDate,
    szpkRegisteredNumber: SZPKDfo?.registeredNumber,
    stage: SZPKDfo?.stage,
  })

  const isSZPKConcluded = actualProjectStatus.status === 'Заключено'

  const { registeredDate, registeredNumber } = SZPKDfo || {}

  const isInvestProjectInDraft = useMemo(() => {
    return INVESTPROJECTDfo?.stage === DfosStage.DRAFT
  }, [INVESTPROJECTDfo?.stage])

  const { error: projectMembersError } = useProjectMembers({
    key: {
      projectId,
      _key: 'projectMembers',
    },
    config: {
      isPaused: () => isInvestProjectInDraft ?? true,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'ProjectStructure',
          moduleName: 'ProjectLayout',
        },
        additionInfo: {
          isInvestProjectInDraft,
        },
      }),
    },
  })

  const handleNavigateToAllProjects = () => {
    navigate(Paths.Projects, {
      replace: true,
    })
  }

  const handleOpenSidebarPanel = useCallback(async () => {
    //После нажатия дизейблим кнопку на открытие
    handleChangeEventControl?.(true)

    try {
      await openEventSideBarPanel()
    } catch {
    } finally {
      handleChangeEventControl?.(false)
    }
  }, [handleChangeEventControl, openEventSideBarPanel])

  const hasProjectDfosInternalServerError = isIntervalServerError(
    projectDfosError?.response?.status,
  )
  const hasMembersInternalServerError = isIntervalServerError(projectMembersError?.response?.status)
  const hasProjectInternalServerError = isIntervalServerError(projectError?.response?.status)

  if (MONITORINGDfo && !isRegionalMonitoringMER) {
    return (
      <Layout.Main>
        <UiActionsManager>
          <Error500Fallback
            title={'Не удалось загрузить проект'}
            error={hasProjectInternalServerError}
            className={styles.error__template}
          >
            <HeaderPortal>
              <CustomTransition.Fade show={isCollapsed}>
                <div className={styles.projectPage__dynamicContent}>
                  <IconButton
                    className={styles['projectPage__dynamicContent-arrow']}
                    view={'basic'}
                    color={'default'}
                    geometry={'round'}
                    size={'m'}
                    dataTestId="ProjectStructure-back-iconButton"
                    icon={{
                      src: backArrowIcon,
                      className: styles['projectPage__dynamicContent-icon'],
                    }}
                    onClick={handleNavigateToAllProjects}
                  />
                </div>
              </CustomTransition.Fade>
            </HeaderPortal>
            {hasProjectDfosInternalServerError ? (
              <>
                <div className={styles.projectPage__sticky}>
                  <Project.ActionBanner />
                </div>
                <Projects.ProjectContainer className={styles.projectPage__documentsLayout}>
                  <Error500Fallback
                    className={styles.fallback}
                    title={'Не удалось загрузить документы'}
                    error={hasProjectDfosInternalServerError}
                  />
                </Projects.ProjectContainer>
              </>
            ) : (
              <div className={styles.projectPage__container_documents}>
                <div className={styles.projectPage__sticky}>
                  <Project.ActionBanner />
                </div>
                <Projects.ProjectContainer className={styles.projectPage__documentsLayout}>
                  <Project.DocumentsLayout />
                </Projects.ProjectContainer>
              </div>
            )}
          </Error500Fallback>
        </UiActionsManager>
      </Layout.Main>
    )
  }

  return (
    <LayoutModeManager key={projectId}>
      <UiActionsManager>
        <Error500Fallback
          title={'Не удалось загрузить проект'}
          error={hasProjectInternalServerError}
          className={styles.error__template}
        >
          <HeaderPortal>
            <CustomTransition.Fade show={isCollapsed}>
              <div className={styles.projectPage__dynamicContent}>
                <IconButton
                  className={styles['projectPage__dynamicContent-arrow']}
                  view={'basic'}
                  color={'default'}
                  geometry={'round'}
                  size={'m'}
                  dataTestId="ProjectStructure-back-iconButton"
                  icon={{
                    src: backArrowIcon,
                    className: styles['projectPage__dynamicContent-icon'],
                  }}
                  onClick={handleNavigateToAllProjects}
                />
                <TypographyWithTooltip.Headline
                  variant={'headlineH3'}
                  as={'h3'}
                  className={styles['projectPage__dynamicContent-title']}
                  tooltipProps={{
                    position: 'bottom',
                    offset: [0, 15],
                    targetClassName: styles.tooltip__target,
                    popoverClassName: styles.tooltip__popover,
                    arrowClassName: styles.tooltip__arrow,
                  }}
                >
                  {project?.name}
                </TypographyWithTooltip.Headline>

                <div className={styles['projectPage__header-actions']}>
                  <IconButton
                    className={styles['projectPage__header-action']}
                    disabled={eventSidebarControlIsDisabled}
                    size={'m'}
                    view={'gray'}
                    geometry={'round'}
                    dataTestId="ProjectStructure-history-iconButton"
                    icon={{
                      src: HistoryIcon,
                      noCurrentColorSvgFill: true,
                    }}
                    onClick={handleOpenSidebarPanel}
                  />
                  <HamburgerActionsButton inHeader />
                </div>
              </div>
            </CustomTransition.Fade>
          </HeaderPortal>
          <ParticipantsOfStatementManager>
            <Projects.ProjectContainer className={styles.projectPage__container_head}>
              {!isRegionalMonitoringMER && (
                <CustomTransition.Fade show={!isCollapsed}>
                  <div className={styles.projectPage__backArrow}>
                    <IconButton
                      view={'basic'}
                      color={'default'}
                      geometry={'round'}
                      size={'m'}
                      dataTestId="ProjectStructure-back-iconButton"
                      icon={{
                        src: backArrowIcon,
                        className: styles['projectPage__dynamicContent-icon'],
                      }}
                      onClick={handleNavigateToAllProjects}
                    />
                  </div>
                </CustomTransition.Fade>
              )}
              {!projectError && !isRegionalMonitoringMER && (
                <Skeleton animate visible={isLoadingProject} className={styles.skeleton__header}>
                  <div className={styles.projectPage__header} ref={bindHeadlineRef}>
                    <div className={styles.projectPage__chips}>
                      <span className={styles['projectPage__chips-status']}>
                        <Chip.Base
                          disableClickableEffect
                          label={actualProjectStatus.status}
                          {...actualProjectStatus.chipProps}
                          size={'xs'}
                        />
                        {isSZPKConcluded && registeredNumber && registeredDate && (
                          <Typography color={'text-accent-success'} variant={'bodyMMedium'}>
                            {buildSZPKStatus(registeredNumber, new Date(registeredDate))}
                          </Typography>
                        )}
                      </span>
                      {project && !project.isFederal && (
                        <Chip.Base
                          disableClickableEffect
                          className={styles['projectPage__chips-federalChip']}
                          label={'Региональное соглашение'}
                          priority={'secondary'}
                          variant={'accent'}
                          size={'xs'}
                          leftAddon={{
                            src: PlaceIcon,
                          }}
                        />
                      )}
                    </div>
                    <div className={styles['projectPage__header-actions']}>
                      <IconButton
                        className={styles['projectPage__header-action']}
                        disabled={eventSidebarControlIsDisabled}
                        size={'m'}
                        view={'gray'}
                        geometry={'round'}
                        dataTestId="ProjectStructure-history-iconButton"
                        icon={{
                          src: HistoryIcon,
                          noCurrentColorSvgFill: true,
                        }}
                        onClick={handleOpenSidebarPanel}
                      />
                      <HamburgerActionsButton />
                    </div>
                  </div>
                </Skeleton>
              )}
              {project && (
                <CustomTransition.Fade show={!isCollapsed}>
                  <SkeletonText
                    animate
                    visible={isLoadingProject}
                    className={styles.skeleton__projectTitle}
                  >
                    <Typography.Headline
                      className={styles.projectPage__title}
                      as={'h2'}
                      variant={'headlineH2'}
                    >
                      {`${project.isOldStatement ? '[подано до 01.04.2021]' : ''} ${project.name}`}
                    </Typography.Headline>
                  </SkeletonText>
                </CustomTransition.Fade>
              )}

              {!isRegionalMonitoringMER && (
                <div className={styles.projectPage__participants}>
                  <Error500Fallback
                    className={styles.fallback}
                    title={'Не удалось загрузить список участников'}
                    error={hasMembersInternalServerError}
                  >
                    <Project.ParticipantsOfStatement />
                  </Error500Fallback>
                </div>
              )}
            </Projects.ProjectContainer>
          </ParticipantsOfStatementManager>
          {hasProjectDfosInternalServerError ? (
            <>
              <div className={styles.projectPage__sticky}>
                <Project.ActionBanner />
              </div>
              <Projects.ProjectContainer className={styles.projectPage__documentsLayout}>
                <Error500Fallback
                  className={styles.fallback}
                  title={'Не удалось загрузить документы'}
                  error={hasProjectDfosInternalServerError}
                />
              </Projects.ProjectContainer>
            </>
          ) : (
            <div className={styles.projectPage__container_documents}>
              <div className={styles.projectPage__sticky}>
                <Project.ActionBanner />
              </div>
              <Projects.ProjectContainer className={styles.projectPage__documentsLayout}>
                <Project.DocumentsLayout />
              </Projects.ProjectContainer>
            </div>
          )}
        </Error500Fallback>
      </UiActionsManager>
    </LayoutModeManager>
  )
}

export default memo(ProjectStructure)
