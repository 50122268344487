import { useRef } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { mapOfDescriptionCheckModal } from '@components/Forms/CreateStatementOld/const'
import {
  ISaveChangesCallbacks,
  IWithSaveChangesDecorator,
} from '@components/Forms/CreateStatementOld/types'
import Modal from '@components/NewDesign/Modal'
import { DefaultActionProps } from '@components/NewDesign/Modal/Base/Actions'
import { DocumentsType } from '@constants/types'
import useDraftApi from '@context/APIContext/hooks/useDraftApi'
import { IGetStatementOldResponse } from '@context/APIContext/types'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { unstable_serialize } from 'swr'

import styles from '../Statement/Statement.module.scss'

const useCheckStatementChanges = ({ dfoId, projectId, documentSetId }) => {
  const lastResponseKey = useRef<string | null>(null)
  const checkChangesModalIsOpen = useRef<boolean>(false)

  const { getDraft } = useDraftApi()
  const popupManager = usePopupManager()

  //Проверяет, изменился ли черновик кем-то другим, пока пользователь его редактировал.
  const checkStatementToChanges = async () => {
    try {
      const lastDraftResponse = await getDraft<IGetStatementOldResponse>({
        dfoId,
        projectId,
        documentSetId,
        documentType: DocumentsType.STATEMENT,
      })

      if (lastDraftResponse) {
        const responseKey = unstable_serialize(lastDraftResponse)

        //Черновик не был изменён другим пользователем
        if (responseKey === lastResponseKey?.current) {
          return Promise.resolve()
        }

        return Promise.reject({ statementIsChanged: true })
      }
    } catch {}
  }

  const updateLastResponseKey = async () => {
    const newStatement = await getDraft({
      dfoId,
      projectId,
      documentSetId,
      documentType: DocumentsType.STATEMENT,
    })

    if (lastResponseKey) {
      lastResponseKey.current = unstable_serialize(newStatement)
    }
  }

  const withCheckChangesBeforeApply = async ({
    submitCallback,
    rejectCallback,
    description,
  }: IWithSaveChangesDecorator) => {
    try {
      await checkStatementToChanges?.()
      submitCallback?.()
    } catch (reason: any) {
      if (reason?.statementIsChanged && !checkChangesModalIsOpen.current) {
        return callSaveChangesModal({ submitCallback, rejectCallback, description })
      }

      handleErrorWithPopup(reason, true)

      throw reason
    }
  }

  const callSaveChangesModal = ({
    submitCallback,
    rejectCallback,
    description = mapOfDescriptionCheckModal.default,
  }: Partial<ISaveChangesCallbacks> & { description?: string }) => {
    checkChangesModalIsOpen.current = true

    const preparedRejectCallback = () => {
      rejectCallback?.()

      checkChangesModalIsOpen.current = false
    }

    const preparedSubmitCallback = () => {
      submitCallback?.()

      checkChangesModalIsOpen.current = false
    }

    const preparedActions = [
      {
        children: 'Не сохранять',
        view: 'gray',
        onClick: preparedRejectCallback,
        bindOnClose: true,
      },
      {
        children: 'Cохранить',
        onClick: preparedSubmitCallback,
        bindOnClose: true,
      },
    ] as DefaultActionProps[]

    popupManager.open(Modal.Action, {
      simpleModalContainerClassName: styles.checkChangesModal__container,
      title: 'Сохранение заявления',
      children: description,
      actions: preparedActions,
    })
  }

  return {
    lastResponseKey,
    callSaveChangesModal,
    withCheckChangesBeforeApply,
    checkStatementToChanges,
    updateLastResponseKey,
  }
}

export { useCheckStatementChanges }
