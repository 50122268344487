import React, { FC, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormMenu.module.scss'
import { useFormNavigationMenu } from '@components/DocumentFormComponents/hooks/useFormNavigation'
import { useFormSubmitInStatement } from '@components/DocumentFormComponents/hooks/useFormSubmit'
import { useShowMenuLinearGradientManager } from '@components/DocumentFormComponents/hooks/useShowMenuLinearGradientManager'
import MenuActions from '@components/DocumentFormComponents/Menu/Actions'
import DocumentFormNestedMenu from '@components/DocumentFormComponents/NestedMenu'
import {
  useNestedMenuHandlersManager,
  useNestedMenuManager,
} from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalMenuProps } from '@components/DocumentFormComponents/types'
import { useInformationOfExecutionAgreementEventsManager } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Manager'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import { InformationOfExecutionAgreementEventsFieldCollapseControlUpdateWatcher } from '@components/Forms/InformationOfExecutionAgreementEventsForm/watcher'
import Loader from '@components/Loader'
import NoItemText from '@components/NoItemText'
import { useResizeObserver } from '@hooks/new/observers/useResizeObserver'

const InformationOfExecutionAgreementEventsMenu: FC<FormModalMenuProps> = ({ ...rest }) => {
  const formInstance = useFormContext<InformationOfExecutionAgreementEventsFormValues>()
  const {
    state: { blockViewIsValidating, lastUpdateDraftTime, editMode },
    actions: { handleCheckForm },
    handlers: { applyFormChanges },
  } = useInformationOfExecutionAgreementEventsManager()

  const {
    state: { formMenu, searchThree, searchThreeIsLoading, currentSearchPattern, currentMenuItemId },
    handlers: { getInitialExpandedMenuCondition },
  } = useNestedMenuManager()

  const { handleChangeCurrentMenuItemId } = useNestedMenuHandlersManager()

  const { handleMenuItemClick } = useFormNavigationMenu(
    InformationOfExecutionAgreementEventsFieldCollapseControlUpdateWatcher,
  )

  const { handleSubmitForm } = useFormSubmitInStatement()

  const { isShowLineGradient, handleOnScroll, handleResizeObserverCallback } =
    useShowMenuLinearGradientManager()

  const menuWrapperRef = useRef<HTMLDivElement | null>(null)

  useResizeObserver({
    elementsToObserve: menuWrapperRef,
    resizeListener: handleResizeObserverCallback,
  })

  const currentCertificateOnMonitoringResultsMenuSearch = currentSearchPattern.length
    ? searchThree
    : formMenu

  const handleSubmit = async () => {
    if (!handleCheckForm || !applyFormChanges) return

    try {
      await handleCheckForm()
    } catch (e) {
      throw e
    }
  }

  if (!formInstance) return null

  return (
    <div className={styles.menu}>
      <div className={styles.menu__container}>
        <div ref={menuWrapperRef} className={styles.menu__wrapper} onScroll={handleOnScroll}>
          <Loader loading={searchThreeIsLoading}>
            {currentCertificateOnMonitoringResultsMenuSearch ? (
              <DocumentFormNestedMenu
                {...rest}
                blockViewIsValidating={blockViewIsValidating}
                lastUpdateDraftTime={lastUpdateDraftTime}
                nestedMapOfMenu={currentCertificateOnMonitoringResultsMenuSearch}
                currentSearchPattern={currentSearchPattern}
                activeMenuId={currentMenuItemId}
                getInitialExpandedCondition={getInitialExpandedMenuCondition}
                menuItemClassName={styles.menu__item}
                listProps={{
                  buttonProps: {
                    variant: 'buttonSMedium',
                    className: styles['menu__list-button'],
                    textClassName: styles['menu__list-buttonText'],
                  },
                }}
                onMenuClick={handleMenuItemClick(handleChangeCurrentMenuItemId)}
              />
            ) : (
              <NoItemText>Поиск не дал результатов</NoItemText>
            )}
          </Loader>
        </div>
        {isShowLineGradient && <div className={styles['menu__container-line']} />}
      </div>
      <MenuActions
        submitButtonProps={
          editMode
            ? {
                onClick: handleSubmitForm(handleSubmit),
              }
            : undefined
        }
      />
    </div>
  )
}

export default InformationOfExecutionAgreementEventsMenu
