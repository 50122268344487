import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectOption,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation } from '@constants/validations'

const objectNamePermissionsValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectOption,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  objectOption: {
    required: defaultRHFValidation.required,
  },
}

export { objectNamePermissionsValidationMap }
