import { useCallback } from 'react'

import { generatePropertiesObjectForAgreementStabilizationNegotiationsForm } from '@components/Forms/АgreementStabilizationNegotiationsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAgreementStabilizationNegotiationsForm } from '@components/Forms/АgreementStabilizationNegotiationsForm/adapters/RHF.adapter'
import { AgreementStabilizationNegotiationsFormValues } from '@components/Forms/АgreementStabilizationNegotiationsForm/types'

const useAgreementStabilizationNegotiationsAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): AgreementStabilizationNegotiationsFormValues =>
      generateRHFObjectForAgreementStabilizationNegotiationsForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForAgreementStabilizationNegotiationsForm(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generatePropertiesObject }
}

export { useAgreementStabilizationNegotiationsAdapters }
