import type { IDfoDeadline } from 'context/APIContext/types'

export const PAGE_SIZES = [10, 20, 30, 40, 50, 100]

export const THUMBPRINT = 'thumberprint'

export interface DateCellWithDeadlinesProps {
  date: string
  deadlines?: Omit<IDfoDeadline, 'dfoId'>
}
