import React, { FC, memo } from 'react'
import { useParams } from 'react-router'

import WebForm from '@components/Attachments/WebForm/index'
import { WebFormTypes } from '@constants/types'
import type { IDocumentTypeInfoWithDict } from '@services/Documents/documents.entity'

interface WebFormControllerProps {
  documentProps: IDocumentTypeInfoWithDict
  permissions: number
  currentDfoId: string
  documentSetId: string
  documentSetType: string
  digitizingInProcess?: boolean
}

const WebFormController: FC<WebFormControllerProps> = ({
  permissions,
  documentProps,
  currentDfoId,
  documentSetId,
  documentSetType,
  digitizingInProcess,
}) => {
  const { documentInfoFromDict } = documentProps

  const { projectId = '' } = useParams()

  const isNpaListWebFormType = documentInfoFromDict.documentType === WebFormTypes.NPA_LIST
  const isNpaListMunicipalWebFormType =
    documentInfoFromDict.documentType === WebFormTypes.NPA_LIST_MUNICIPAL
  const isSolicitationInclusionNpaWebFormType =
    documentInfoFromDict.documentType === WebFormTypes.SOLICITATION_INCLUSION_NPA

  if (
    isNpaListWebFormType ||
    isSolicitationInclusionNpaWebFormType ||
    isNpaListMunicipalWebFormType
  ) {
    return (
      <WebForm.APIProvider>
        <WebForm.Addon
          projectId={projectId}
          currentDfoId={currentDfoId}
          permissions={permissions}
          documentType={documentInfoFromDict.documentType}
          documentProps={documentProps}
        >
          <WebForm.ItemController
            digitizingInProcess={digitizingInProcess}
            permissions={permissions}
            projectId={projectId}
            currentDfoId={currentDfoId}
            documentSetId={documentSetId}
            documentSetType={documentSetType}
            documentProps={documentProps}
          />
        </WebForm.Addon>
      </WebForm.APIProvider>
    )
  }

  return (
    <WebForm.APIProvider>
      <WebForm.ItemController
        digitizingInProcess={digitizingInProcess}
        permissions={permissions}
        projectId={projectId}
        currentDfoId={currentDfoId}
        documentSetId={documentSetId}
        documentSetType={documentSetType}
        documentProps={documentProps}
      />
    </WebForm.APIProvider>
  )
}

export default memo(WebFormController)
