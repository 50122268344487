import { useState } from 'react'

import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import IconButton from '@components/NewDesign/IconButton'
import Modal from '@components/NewDesign/Modal'
import { ModalActionComponentProps } from '@components/NewDesign/Modal/Action'
import { BaseActionsModal } from '@components/NewDesign/Modal/Base/Actions'
import { useBooleanState } from '@hooks/useBooleanState'
import backArrowIcon from '@icons/BackArrow.svg'
import { FOUNDED_FOR_TRANSFERS } from '@routes/Projects/shared/GetRightToSZPK/constants'
import { useSWRConfig } from 'swr'

import styles from './GetRightsToSZPK.module.scss'
import { IStepsValues, Steps } from './steps'
import { useRequests } from './useRequests'

const ReturnButton = ({ onClick }) => (
  <IconButton
    className={styles.Button}
    icon={{
      src: backArrowIcon,
      className: styles.ButtonIcon,
    }}
    onClick={onClick}
  />
)

export type ExistingErrors = 'notFound'

const isInnCorrect = (inn: string) => inn.length === 10

const defaultValuesForGetRightToSZPKForm: IStepsValues = {
  inn: '',
  formOfReorganization: '',
  transferId: '',
  projectInfo: {
    dfoId: '',
    projectId: '',
  },
}

type ActionButtonProps = BaseActionsModal['actions'][number]

export const GetRightsToSZPK = () => {
  const {
    booleanState: isModalOpen,
    setBooleanStateToTrue: openModal,
    setBooleanStateToFalse: closeModal,
  } = useBooleanState()

  const { cache } = useSWRConfig()

  const [savedStepsValues, setSavedValues] = useState<IStepsValues>(
    defaultValuesForGetRightToSZPKForm,
  )

  const { booleanState: isLoading, setBooleanState: setLoading } = useBooleanState()
  const [error, setError] = useState<ExistingErrors | null>(null)

  const [stepIdx, setStepIdx] = useState(0)

  const closeHandler = () => {
    setStepIdx(0)
    closeModal()

    setError(null)
    setSavedValues(defaultValuesForGetRightToSZPKForm)

    cache.delete(FOUNDED_FOR_TRANSFERS)
  }

  const { searchSZPKHandler, postSelectedTransfer } = useRequests({
    setError,
    setLoading,
    setStepIdx,
    savedStepsValues,
    closeHandler,
  })

  const defaultCancelAction: ActionButtonProps = {
    textClassName: styles.ButtonNoPaddings,
    children: 'Отменить',
    view: 'gray',
    onClick: closeHandler,
  }

  const defaultContinueAction: ActionButtonProps = {
    children: 'Продолжить',
    onClick: () => setStepIdx((prev) => prev + 1),
    fixWidth: true,
    textClassName: styles.ButtonNoPaddings,
    loaderProps: {
      loading: isLoading,
      variant: 'lite',
      placement: 'trailing',
      className: styles.Button__loading,
    },
  }

  const modalProps: Record<number | ExistingErrors, Partial<ModalActionComponentProps>> = {
    0: {
      hideCloseIcon: true,
      title: 'Получить права на СЗПК',
      simpleModalHeaderClassName: styles.ModalHeaderInfo,
    },
    1: {
      title: 'Укажите ИНН и форму реорганизации',
      simpleModalHeaderClassName: styles.ModalHeaderFormOfReorg,
      actions: [
        defaultCancelAction,
        {
          ...defaultContinueAction,
          fixWidth: false,
          disabled:
            isLoading ||
            !isInnCorrect(savedStepsValues.inn) ||
            !savedStepsValues.formOfReorganization,
          onClick: searchSZPKHandler,
        },
      ] as ButtonProps[],
    },
    2: {
      leadHeaderIcon: <ReturnButton onClick={() => setStepIdx((prev) => prev - 1)} />,
      title: 'Выберите СЗПК',
      actions: [
        {
          ...defaultCancelAction,
          textClassName: styles.ButtonNoPaddings,
        },
        {
          ...defaultContinueAction,
          children: 'Получить права на СЗПК',
          onClick: postSelectedTransfer,
          disabled: isLoading || !savedStepsValues.transferId,
        },
      ] as ButtonProps[],
    },
    notFound: {
      title: null,
      hideCloseIcon: true,
      simpleModalHeaderClassName: styles.ModalHeaderFormOfReorg,
      actions: [
        {
          children: 'Назад',
          view: 'gray',
          onClick: () => setError(null),
        },
        {
          children: 'Понятно',
          fixWidth: true,
          onClick: closeHandler,
        },
      ] as ButtonProps[],
    },
  }

  return (
    <>
      <Modal.Action
        simpleModalContainerClassName={styles.Modal}
        simpleModalBodyClassName={styles.ModalBody}
        actionModalContentClassName={styles.ModalContent}
        isOpen={isModalOpen}
        actions={[defaultCancelAction, defaultContinueAction]}
        title={'Получить права на СЗПК'}
        onClose={closeHandler}
        {...modalProps[error || stepIdx]}
      >
        <Steps
          changeSavedStepsValuesHandler={setSavedValues}
          savedStepsValues={savedStepsValues}
          step={error || stepIdx}
        />
      </Modal.Action>
      <Button className={styles.Widget} view="text" variant="buttonSMedium" onClick={openModal}>
        Получить права на СЗПК
      </Button>
    </>
  )
}
