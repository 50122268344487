import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { defaultRHFValidation, standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fifthSectionValidationMap: FormValuesValidationSection<
  keyof AAVolumeOfCapitalInvestmentsFormValues['5']['capitalSteps'][number],
  AAVolumeOfCapitalInvestmentsFormValues
> = {
  stepInvestorCapitalValue: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  stepInvestorCapitalDate: {
    required: defaultRHFValidation.required,
  },
  oldStepInvestorCapitalSources: {
    required: defaultRHFValidation.required,
  },
}

export { fifthSectionValidationMap }
