import React, { FC } from 'react'

import { useFetchSelect } from '@components/DocumentFormComponents/FormSelect/hooks'
import { FormSelectProps } from '@components/DocumentFormComponents/FormSelect/types'
import SingleSelectNew, { SingleSelectNewProps } from '@components/NewDesign/Select/SingleSelectNew'

type FormSingleSelectProps = FormSelectProps<SingleSelectNewProps>

const FormSingleSelect: FC<FormSingleSelectProps> = ({
  id,
  selectProps,
  fetcherProps,
  optionsAdapter,
  transformOptionsData,
}) => {
  const { options, optionsIsLoading, handleFocusSelect } = useFetchSelect({
    fetcherProps,
    optionsAdapter,
    transformOptionsData,
  })

  const mergedOnFocusForm = async () => {
    selectProps?.onFocusForm?.()
    await handleFocusSelect()
  }

  return (
    <SingleSelectNew
      id={id}
      {...selectProps}
      options={options}
      optionsProps={{
        ...selectProps.optionsProps,
        isLoading: optionsIsLoading,
      }}
      onFocusForm={mergedOnFocusForm}
    />
  )
}
export type { FormSingleSelectProps }
export default FormSingleSelect
