import { useCallback, useRef } from 'react'
import { FieldValues } from 'react-hook-form'

import { getObjectValue } from '@helpers/object/getObjectValue'

const useFormPreviousValue = <
  T extends FieldValues,
  M extends FieldValues | undefined = undefined,
>() => {
  const RHFObjectBeforeUpdate = useRef<T | null>(null)
  const formModifierBeforeUpdate = useRef<M | null>(null)

  const getBeforeUpdateValueByPath = useCallback((path: string) => {
    if (!path || !RHFObjectBeforeUpdate.current) return null

    return getObjectValue(RHFObjectBeforeUpdate.current, path)
  }, [])

  const updateLastRFHBeforeValue = useCallback((newValue: T | null) => {
    RHFObjectBeforeUpdate.current = newValue
  }, [])

  const updateLastFormModifierBeforeValue = useCallback((newValue: M | null) => {
    formModifierBeforeUpdate.current = newValue
  }, [])

  const getLastRHFBeforeValue = useCallback(() => RHFObjectBeforeUpdate.current, [])

  const getLastFormModifierBeforeValue = useCallback(() => formModifierBeforeUpdate.current, [])

  return {
    getLastRHFBeforeValue,
    getBeforeUpdateValueByPath,
    updateLastRFHBeforeValue,
    getLastFormModifierBeforeValue,
    updateLastFormModifierBeforeValue,
  }
}

export { useFormPreviousValue }
