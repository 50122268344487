import React, { FC, memo, useLayoutEffect, useMemo, useState } from 'react'
import { Path, useWatch } from 'react-hook-form'

import {
  mapOfCompensationsFormat,
  mapOfCompensationsTypeByReimbursementPeriod,
  mapOfCompensationsTypeByTitle,
} from '@components/DocumentFormComponents/const'
import FieldView from '@components/Forms/CreateStatementOld/FieldView'
import globalFormStyles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { StatementOldFormValues } from '@components/Forms/CreateStatementOld/types'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { GroupOfOptions } from '@components/NewDesign/Select/model'
import { ControlledSingleSelect } from '@components/NewDesign/Select/SingleSelect/ControlledSingleSelect'
import Typography from '@components/NewDesign/Typography'
import { documentsLinks } from '@constants/documents'
import { bigIntIsValidate, defaultRHFValidation } from '@constants/validations'
import { PROJECT_COMPENSATION_PERIOD } from '@context/APIContext/types'
import { isString } from '@helpers/checkTypes'

import styles from './Compensation.module.scss'

interface IStepCompensation {
  name: string
  positionOfCompensation: number
  onRemoveCompensation: (index: number) => void

  onBeforeRemoveCompensation: () => void
}

const mapOfReimbursemenentText = {
  [PROJECT_COMPENSATION_PERIOD._5_YEARS]: 'до 5 лет',
  [PROJECT_COMPENSATION_PERIOD._10_YEARS]: 'до 10 лет',
} as const

const StepCompensation: FC<IStepCompensation> = ({
  positionOfCompensation,
  onRemoveCompensation,
  onBeforeRemoveCompensation,
  name,
}) => {
  const {
    state: { formInstance, blockViewIsValidating },
  } = useCreateStatementManager()

  //Данный код исключительно для textAreaAutosize, которая монтирует свой компонент без знаний высоты.
  // Происходит из-за портала хедлесса или модалки его же.
  const [isRerendered, setIsRerendered] = useState(false)
  useLayoutEffect(() => setIsRerendered(true), [])

  const currentValueOfType = useWatch({
    name: `${name}.${positionOfCompensation}.type` as Path<StatementOldFormValues>,
    control: formInstance?.control,
  })

  const preparedCompensationType = useMemo(() => {
    const _5YEARS_OPTIONS: GroupOfOptions = {
      title: 'Виды затрат со сроком возмещения до 5 лет',
      options: [],
    }

    const _10YEARS_OPTIONS: GroupOfOptions = {
      title: 'Виды затрат со сроком возмещения до 10 лет',
      options: [],
    }

    Object.entries(mapOfCompensationsTypeByTitle).forEach(([value, displayValue]) => {
      const yearByType = mapOfCompensationsTypeByReimbursementPeriod[value]

      if (yearByType === PROJECT_COMPENSATION_PERIOD._5_YEARS) {
        _5YEARS_OPTIONS.options.push({
          displayValue,
          value,
        })
      }

      if (yearByType === PROJECT_COMPENSATION_PERIOD._10_YEARS) {
        _10YEARS_OPTIONS.options.push({
          displayValue,
          value,
        })
      }
    })

    return [_5YEARS_OPTIONS, _10YEARS_OPTIONS]
  }, [])

  const preparedCompensationFormat = useMemo(() => {
    return Object.entries(mapOfCompensationsFormat).map(([value, displayValue]) => ({
      displayValue,
      value,
    }))
  }, [])

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate
      contentClassName={globalFormStyles.form__container}
      style={{
        marginTop: 16,
      }}
      onRemoveStep={() => onRemoveCompensation(positionOfCompensation)}
      onBeforeRemoveStep={onBeforeRemoveCompensation}
    >
      {isRerendered && (
        <div className={globalFormStyles.form__item}>
          <ControlledSingleSelect
            type={'textarea'}
            options={preparedCompensationType}
            inputProps={{
              label: 'Вид затрат',
              fixWidth: true,
              dataTestId: `StepCompensation-${name}.${positionOfCompensation}.type-singleSelect`,
            }}
            optionsProps={{
              variant: 'secondary',
              scrollableOptionsContainerClassName: styles.compensation__options,
              optionClassName: styles.compensation__option,
            }}
            controllerProps={{
              control: formInstance.control,
              name: `${name}.${positionOfCompensation}.type` as Path<StatementOldFormValues>,
              rules: {
                required: defaultRHFValidation.required,
                onChange: () => {
                  if (blockViewIsValidating)
                    formInstance.trigger(
                      `${name}.${positionOfCompensation}.type` as Path<StatementOldFormValues>,
                    )
                },
              },
            }}
          />

          {currentValueOfType && (
            <Typography.Caption
              className={styles.compensation__caption}
              color={'text-base-secondary'}
              variant={'captionSRegular'}
            >
              {`Срок возмещения затрат составит ${
                (isString(currentValueOfType) &&
                  mapOfReimbursemenentText[
                    mapOfCompensationsTypeByReimbursementPeriod[currentValueOfType]
                  ]) ||
                ''
              }`}
              <Typography.Caption
                as={'span'}
                variant={'captionSRegular'}
                color={'text-accent-brand'}
              >
                <a href={documentsLinks.FZ_N69} target="_blank" rel="noopener noreferrer">
                  &nbsp;(часть 6 статьи 15 69-ФЗ)
                </a>
              </Typography.Caption>
            </Typography.Caption>
          )}
        </div>
      )}

      <div className={globalFormStyles.form__row}>
        <div className={globalFormStyles.form__item}>
          <ControlledSingleSelect
            options={preparedCompensationFormat}
            inputProps={{
              view: 'secondary',
              fixWidth: true,
              label: 'Форма возмещения затрат',
              dataTestId: `StepCompensation-${name}.${positionOfCompensation}.format-singleSelect`,
            }}
            controllerProps={{
              name: `${name}.${positionOfCompensation}.format` as Path<StatementOldFormValues>,
              control: formInstance.control,
              rules: {
                required: defaultRHFValidation.required,
              },
            }}
          />
        </div>

        <div className={globalFormStyles.form__item}>
          <ControlledAmountInput
            control={formInstance.control}
            name={`${name}.${positionOfCompensation}.value` as Path<StatementOldFormValues>}
            inputProps={{
              view: 'secondary',
              label: 'Объем затрат, без учета НДС',
              minorPartView: 'withZeroMinorPart',
              dataTestId: `StepCompensation-${name}.${positionOfCompensation}.value-amountInput`,
            }}
            rules={{
              required: defaultRHFValidation.required,
              validate: bigIntIsValidate,
              pattern: defaultRHFValidation.capital,
            }}
          />
        </div>
      </div>
    </FieldView.StepTemplate>
  )
}

export default memo(StepCompensation)
