import { memo, useCallback, VFC } from 'react'
import { useForm } from 'react-hook-form'

import useCurrentTableUpdater from '@hooks/useCurrentTableUpdater'
import useDebounce from '@hooks/useDebounce'
import Layouts from '@layouts'

import Filters from './Filters/Filters'
import OperatorsTable from './OperatorsTable/OperatorsTable'
import ParticipantsTable from './ParticipantsTable/ParticipantsTable'
import { defaultUsersFormValues, filterUsersByActions, usersTabs } from './constants'
import useManageUsersSidebar from './useManageUsersSidebar'
import styles from './Users.module.scss'

interface UsersProps {
  className?: string
}

export type FilterActions = keyof typeof filterUsersByActions

export type TFormUsers = {
  tagRadioGroup: 'participantsTable' | 'operatorsTable'
  searchString: string
  filterActions: FilterActions
}

const Users: VFC<UsersProps> = ({ className }) => {
  const { control, watch, setValue } = useForm<TFormUsers>({
    defaultValues: defaultUsersFormValues,
  })

  const { updater, setUpdater } = useCurrentTableUpdater()

  const { handleOpenManageOperatorSidebar, handleOpenManageParticipantSidebar } =
    useManageUsersSidebar()

  const groupTagValue = watch('tagRadioGroup')
  const searchString = watch('searchString').trim()
  const filterActions = watch('filterActions')
  const debouncedSearchString: string = useDebounce(searchString, 500)

  const successCallback = useCallback(async () => {
    updater.current?.()
  }, [updater])

  const handleAddParticipant = useCallback(() => {
    handleOpenManageParticipantSidebar({
      onSuccess: successCallback,
    })
  }, [handleOpenManageParticipantSidebar, successCallback])

  const handleAddOperator = useCallback(() => {
    handleOpenManageOperatorSidebar({
      onSuccess: successCallback,
    })
  }, [handleOpenManageOperatorSidebar, successCallback])

  return (
    <Layouts.Main>
      <div className={styles.page}>
        <Filters
          control={control}
          groupTagValue={groupTagValue}
          filterActions={filterActions}
          handleAddParticipant={handleAddParticipant}
          handleAddOperator={handleAddOperator}
          setValue={setValue}
        />
        {groupTagValue === usersTabs.participants && (
          <ParticipantsTable
            debouncedSearch={debouncedSearchString}
            filterActions={filterActions}
            setUpdater={setUpdater}
          />
        )}
        {groupTagValue === usersTabs.operators && (
          <OperatorsTable filterActions={filterActions} setUpdater={setUpdater} />
        )}
      </div>
    </Layouts.Main>
  )
}

export default memo(Users)
