import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { NotificationOfChangeDocumentsFormValues } from '@components/Forms/NotificationOfChangeDocumentsForm/types'
import { isNotEmptyString } from '@helpers/checkTypes'

const generateMenuListForNotificationOfChangeDocumentsMenuState = (
  formValues:
    | NotificationOfChangeDocumentsFormValues
    | UnpackNestedValue<NotificationOfChangeDocumentsFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<NotificationOfChangeDocumentsFormValues>,
): NestedMapOfMenu[] => {
  const getPreparedCharactericticsOfObject = (item: NestedMapOfMenu): NestedMapOfMenu => {
    const characteristicError = formErrors?.['2']?.characteristicOfDocuments

    if (
      !formValues['2'].characteristicOfDocuments ||
      !formValues['2'].characteristicOfDocuments.length
    )
      return {
        ...item,
        hasError: !!characteristicError,
      }

    return {
      ...item,
      hasError: !!characteristicError,
      children: formValues['2'].characteristicOfDocuments.map(
        (characteristic, indexOfCharacteristic): NestedMapOfMenu => {
          const characteristicsError =
            formErrors?.['2']?.characteristicOfDocuments?.[indexOfCharacteristic]

          return {
            id: `2.${indexOfCharacteristic}`,
            path: `2.characteristicOfDocuments.${indexOfCharacteristic}`,
            title: isNotEmptyString(characteristic.changeDocumentName)
              ? characteristic.changeDocumentName
              : `Документ №${indexOfCharacteristic + 1}`,
            isCollapsed: false,
            hasError: !!characteristicsError,
            parentBaseId: '2',
            children: [],
          }
        },
      ),
    }
  }

  const preparedHashMap = {
    '1': {
      ...initialHashMap['1'],
      hasError: !!formErrors?.[initialHashMap['1'].id],
    },
    '2': getPreparedCharactericticsOfObject(initialHashMap['2']),
  }

  return [preparedHashMap['1'], preparedHashMap['2']]
}

export { generateMenuListForNotificationOfChangeDocumentsMenuState }
