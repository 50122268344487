import env from '@config'

export const politicList = [
  {
    id: 0,
    title: 'Общие положения',
    description: [
      {
        id: 1,
        title:
          'Настоящая политика обработки персональных данных (далее – Политика) составлена в соответствии с требованиями Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных» (далее – ФЗ о ПДн) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Оператором ГИС «Капиталовложения».',
        description: [],
      },
      {
        id: 2,
        title:
          'Политика применяется ко всей информации, которую Оператор может получить о пользователях ГИС «Капиталовложения».',
        description: [],
      },
    ],
  },
  {
    id: 1,
    title: 'Основные понятия, используемые в Политике',
    description: [
      {
        id: 1,
        title:
          'Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.',
        description: [],
      },
      {
        id: 2,
        title:
          'Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).',
        description: [],
      },
      {
        id: 3,
        title:
          'Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.',
        description: [],
      },
      {
        id: 4,
        title:
          'Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.',
        description: [],
      },
      {
        id: 5,
        title: 'Оператор ГИС «Капиталовложения» – ФНС России.',
        description: [],
      },
      {
        id: 6,
        title:
          'Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю ГИС «Капиталовложения».',
        description: [],
      },
      {
        id: 7,
        title:
          'Персональные данные, разрешенные субъектом персональных данных для распространения – персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном ФЗ о ПДн (далее - персональные данные, разрешенные для распространения).',
        description: [],
      },
      {
        id: 8,
        title: 'Пользователь – любой зарегистрированный посетитель ГИС «Капиталовложения».',
        description: [],
      },
      {
        id: 9,
        title:
          'Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.',
        description: [],
      },
      {
        id: 10,
        title:
          'Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.',
        description: [],
      },
      {
        id: 11,
        title:
          'Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.',
        description: [],
      },
      {
        id: 12,
        title:
          'Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.',
        description: [],
      },
    ],
  },
  {
    id: 2,
    title: 'Основные права и обязанности Оператора',
    description: [
      {
        id: 1,
        title: 'Оператор имеет право:',
        description: [
          'получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;',
          'в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в ФЗ о ПДн;',
          'самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных ФЗ о ПДн и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено ФЗ о ПДн или другими федеральными законами.',
        ],
      },
      {
        id: 2,
        title: 'Оператор обязан:',
        description: [
          'предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;',
          'организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;',
          'отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями ФЗ о ПДн;',
          'публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;',
          'принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;',
          'прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных ФЗ о ПДн;',
          'исполнять иные обязанности, предусмотренные ФЗ о ПДн.',
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Основные права и обязанности субъектов персональных данных',
    description: [
      {
        id: 1,
        title: 'Субъекты персональных данных имеют право:',
        description: [
          'получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен ФЗ о ПДн;',
          'требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;',
          'выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;',
          'на отзыв согласия на обработку персональных данных;',
          'обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;',
          'на осуществление иных прав, предусмотренных законодательством РФ.',
        ],
      },
      {
        id: 2,
        title: 'Субъекты персональных данных обязаны:',
        description: [
          'предоставлять Оператору достоверные данные о себе в объеме, необходимом для реализации целей функционирования ГИС «Капиталовложения»;',
          'сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных, в случае если это необходимо для реализации целей функционирования ГИС «Капиталовложения».',
        ],
      },
      {
        id: 3,
        title:
          'Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.',
        description: [],
      },
    ],
  },
  {
    id: 4,
    title: 'Состав обрабатываемых персональных данных ',
    description: [
      {
        id: 1,
        title: 'Оператор может обрабатывать следующие персональные данные Пользователя:',
        description: [
          'фамилия, имя, отчество;',
          'место работы и должность;',
          'также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie»).',
        ],
      },
      {
        id: 2,
        title:
          'Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.',
        description: [],
      },
      {
        id: 3,
        title:
          'В ГИС «Капиталовложения» не осуществляется обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни.',
        description: [],
      },
      {
        id: 4,
        title:
          'В ГИС «Капиталовложения» не осуществляется обработка биометрических персональных данных.',
        description: [],
      },
      {
        id: 5,
        title:
          'Оператор не осуществляет распространение персональных данных, полученных в ходе функционирования ГИС «Капиталовложения».',
        description: [],
      },
    ],
  },
  {
    id: 5,
    title: 'Принципы обработки персональных данных',
    description: [
      {
        id: 1,
        title: 'Обработка персональных данных осуществляется на законной и справедливой основе.',
        description: [],
      },
      {
        id: 2,
        title:
          'Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.',
        description: [],
      },
      {
        id: 3,
        title:
          'Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.',
        description: [],
      },
      {
        id: 4,
        title:
          'Обработке подлежат только персональные данные, которые отвечают целям их обработки.',
        description: [],
      },
      {
        id: 5,
        title:
          'Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.',
        description: [],
      },
      {
        id: 6,
        title:
          'При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.',
        description: [],
      },
      {
        id: 7,
        title:
          'Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.',
        description: [],
      },
    ],
  },
  {
    id: 6,
    title: 'Цели обработки персональных данных',
    description: [
      {
        id: 1,
        title: 'Цель обработки персональных данных Пользователя:',
        description: [
          'предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся в ГИС «Капиталовложения».',
        ],
      },
      {
        id: 2,
        title:
          'Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте ГИС «Капиталовложения», улучшения качества сайта и его содержания.',
        description: [],
      },
    ],
  },
  {
    id: 7,
    title: 'Правовые основания обработки персональных данных',
    description: [
      {
        id: 1,
        title: 'Правовыми основаниями обработки персональных данных Оператором являются:',
        description: [
          'уставные документы Оператора;',
          'договоры, заключаемые между оператором и субъектом персональных данных;',
          'федеральные законы, иные нормативно-правовые акты в сфере защиты персональных данных;',
          'согласия Пользователей на обработку их персональных данных, на обработку персональных данных, разрешенных для распространения.',
        ],
      },
      {
        id: 2,
        title:
          'Оператор обрабатывает персональные данные Пользователя в случае их заполнения и/или отправки Пользователем самостоятельно через сервисы ГИС «Капиталовложения» или иные информационные системы Оператора или иных Федеральных органов исполнительной власти РФ.',
        description: [],
      },
      {
        id: 3,
        title:
          'Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.',
        description: [],
      },
      {
        id: 4,
        title:
          'Субъект персональных данных самостоятельно принимает решение о предоставлении его персональных данных и дает согласие свободно, своей волей и в своем интересе.',
        description: [],
      },
    ],
  },
  {
    id: 8,
    title: 'Условия обработки персональных данных',
    description: [
      {
        id: 1,
        title:
          'Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.',
        description: [],
      },
      {
        id: 2,
        title:
          'Обработка персональных данных необходима для достижения целей, предусмотренных Федеральным законом от 1 апреля 2020 г. N 69-ФЗ «О защите и поощрении капиталовложений в Российской Федерации».',
        description: [],
      },
      {
        id: 3,
        title:
          'Обработка персональных данных необходима для исполнения соглашения о защите и поощрении капиталовложений (СЗПК), стороной которого является субъект персональных данных, а также для иных случаев, предусмотренных Федеральным законом от 1 апреля 2020 г. N 69-ФЗ «О защите и поощрении капиталовложений в Российской Федерации».',
        description: [],
      },
      {
        id: 4,
        title:
          'Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.',
        description: [],
      },
    ],
  },
  {
    id: 9,
    title: 'Порядок сбора, хранения, передачи и других видов обработки персональных данных',
    description: [
      {
        id: 1,
        title:
          'Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.',
        description: [],
      },
      {
        id: 2,
        title:
          'Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.',
        description: [],
      },
      {
        id: 3,
        title:
          'Персональные данные Пользователя не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства, либо в случае, если субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.',
        description: [],
      },
      {
        id: 4,
        title: `Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора ${env.REACT_APP_SUPPORT_EMAIL} с пометкой «Отзыв согласия на обработку персональных данных».`,
        description: [],
      },
      {
        id: 5,
        title:
          'Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных интересах, определенных законодательством РФ.',
        description: [],
      },
      {
        id: 6,
        title:
          'Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.',
        description: [],
      },
      {
        id: 7,
        title:
          'Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.',
        description: [],
      },
      {
        id: 8,
        title:
          'Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных или отзыв согласия субъектом персональных данных, а также выявление неправомерной обработки персональных данных.',
        description: [],
      },
    ],
  },
  {
    id: 10,
    title: 'Перечень действий, производимых Оператором с полученными персональными данными',
    description: [
      {
        id: 1,
        title:
          'Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.',
        description: [],
      },
      {
        id: 2,
        title:
          'Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.',
        description: [],
      },
    ],
  },
  {
    id: 11,
    title: 'Трансграничная передача персональных данных',
    description: [
      {
        id: 1,
        title:
          'Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.',
        description: [],
      },
      {
        id: 2,
        title:
          'Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.',
        description: [],
      },
    ],
  },
  {
    id: 12,
    title: 'Конфиденциальность персональных данных',
    description: [
      {
        id: 1,
        title:
          'Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.',
        description: [],
      },
    ],
  },
  {
    id: 13,
    title: 'Заключительные положения',
    description: [
      {
        id: 1,
        title: `Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты ${env.REACT_APP_SUPPORT_EMAIL}`,
        description: [],
      },
      {
        id: 2,
        title:
          'В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.',
        description: [],
      },
      {
        id: 3,
        title:
          'Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://invest.nalog.gov.ru/politics.',
        description: [],
      },
    ],
  },
]
