import { useMemo } from 'react'

import { OptionProps } from '@components/NewDesign/Select/model'
import { getActualProjectAttributeByName } from '@components/Projects/[id]/helpers'
import { compact } from '@helpers/array/compact'
import { isString } from '@helpers/checkTypes'
import { useMunicipalityMembersById } from '@hooks/new/swr/useMunicipalityMembersById'
import { IAttribute } from '@services/Attribute/Attribute.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

interface UseMunicipalityMembersProps {
  projectAttributes?: IAttribute[]
}

export interface IMunicipalityMembers {
  ids?: string[]
  defaultOptionsSelected?: OptionProps[]
  municipalityMembersIsLoading?: boolean
}

const useMunicipalityMembers = ({
  projectAttributes,
}: UseMunicipalityMembersProps): IMunicipalityMembers => {
  const municipalityMembersId = useMemo(() => {
    if (!projectAttributes) return []

    const municipalityToReturn = getActualProjectAttributeByName(
      'municipality',
      projectAttributes,
    )?.value

    const returnCondition = Array.isArray(municipalityToReturn)

    return returnCondition
      ? compact(municipalityToReturn.map((value) => (isString(value) ? value : '')))
      : []
  }, [projectAttributes])

  const { municipalityMembers, isLoadingMunicipalityMembers: municipalityMembersIsLoading } =
    useMunicipalityMembersById({
      key: {
        organizationId: municipalityMembersId,
        _key: 'organizationMembers',
      },
      config: {
        isPaused: () => !municipalityMembersId.length,
        onError: LoggerHelpersService.handleMultipleLogError({
          additionInfo: {
            municipalityMembersId,
            projectAttributes,
          },
          componentInfo: {
            componentName: 'useMunicipalityMembers',
            componentType: 'hook',
          },
        }),
      },
    })

  const municipalityMembersOrganization = useMemo(() => {
    if (!municipalityMembers || !municipalityMembers.length) return []

    return municipalityMembers.map(
      ({ name, id }): OptionProps => ({
        displayValue: name || '',
        value: id,
      }),
    )
  }, [municipalityMembers])

  return {
    ids: municipalityMembersId,
    defaultOptionsSelected: municipalityMembersOrganization,
    municipalityMembersIsLoading,
  }
}

export { useMunicipalityMembers }
