import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOfComplianceContractForRecognitionConcludedABlockValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/const'
import { thirdSectionValidationMap } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/Forms/3/validation'
import { useCertificateOfComplianceContractForRecognitionConcludedAManager } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/Manager'
import { CertificateOfComplianceContractForRecognitionConcludedAFormValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/types'
import { CertificateOfComplianceContractForRecognitionConcludedFieldsControlUpdateWatcher } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { validationAdapter } = DocumentFormHelpers

const Third = () => {
  const formInstance =
    useFormContext<CertificateOfComplianceContractForRecognitionConcludedAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { debouncedHandleChangeValue, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOfComplianceContractForRecognitionConcludedAManager()

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              path={
                certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                  .investorFullName
              }
              watcher={
                CertificateOfComplianceContractForRecognitionConcludedFieldsControlUpdateWatcher
              }
              {...subscribableControl}
            >
              <ControlledDocumentDataView
                suptitle={'Полное наименование'}
                controllerProps={{
                  control: formInstance.control,
                  name: certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                    .investorFullName,
                }}
                formIconTooltipProps={{
                  tooltipContent:
                    'Наименование и реквизиты можно изменить в разделе "Моя организация"',
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              watcher={
                CertificateOfComplianceContractForRecognitionConcludedFieldsControlUpdateWatcher
              }
              path={
                certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                  .investorFullNameInstrumental
              }
              {...subscribableControl}
            >
              <ControlledTextarea
                control={formInstance.control}
                name={
                  certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                    .investorFullNameInstrumental
                }
                rules={validationAdapter(thirdSectionValidationMap.investorFullNameInstrumental, {
                  form: formInstance,
                  name: certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                    .investorFullNameInstrumental,
                  needTrigger: blockViewIsValidating,
                })}
                textareaProps={{
                  disabled: !editMode,
                  minRows: 1,
                  autosize: true,
                  size: 'xl',
                  label: 'Полное наименование',
                  caption: 'в творительном падеже',
                  fixWidth: true,
                }}
                onBlur={() =>
                  setTimeout(
                    () =>
                      handleChangeValue?.(
                        certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                          .investorFullNameInstrumental,
                      ),
                    0,
                  )
                }
                onChange={() =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                        .investorFullNameInstrumental,
                    )
                  }, 0)
                }
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              watcher={
                CertificateOfComplianceContractForRecognitionConcludedFieldsControlUpdateWatcher
              }
              path={
                certificateOfComplianceContractForRecognitionConcludedABlockValues['3'].investorInn
              }
              {...subscribableControl}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={{
                  control: formInstance.control,
                  name: certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                    .investorInn,
                }}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              watcher={
                CertificateOfComplianceContractForRecognitionConcludedFieldsControlUpdateWatcher
              }
              path={
                certificateOfComplianceContractForRecognitionConcludedABlockValues['3'].investorOgrn
              }
              {...subscribableControl}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={{
                  control: formInstance.control,
                  name: certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                    .investorOgrn,
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              watcher={
                CertificateOfComplianceContractForRecognitionConcludedFieldsControlUpdateWatcher
              }
              path={
                certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                  .investorAddress
              }
              {...subscribableControl}
            >
              <ControlledDocumentDataView
                suptitle={'Юридический адрес'}
                controllerProps={{
                  control: formInstance.control,
                  name: certificateOfComplianceContractForRecognitionConcludedABlockValues['3']
                    .investorAddress,
                }}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
