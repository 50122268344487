import { memo } from 'react'

import OrganizationMainInfo from '@components/OrganizationInfo/MainInfo'
import OrganizationUsers from '@components/OrganizationInfo/Users/Users'

import styles from './OrganizationInfo.module.scss'

const OrganizationInfoLayout = () => {
  return (
    <div className={styles.organizationInfo}>
      <OrganizationMainInfo />
      <OrganizationUsers className={styles.organizationInfo__users} />
    </div>
  )
}

export default memo(OrganizationInfoLayout)
