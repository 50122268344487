import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { projectResultEcoActivityValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/9/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const MeasuresNegativeImpactForm = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const {
    getSubscribableControlProps,
    getTextareaProps,
    getCalendarInputProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={8}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: projectSZPKBlockValues['9'].ecoActivityName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      value: item.id,
                      displayValue: item.name,
                    }),

                    controllerProps: {
                      name: projectSZPKBlockValues['9'].ecoActivityName,
                      rules: projectResultEcoActivityValidationMap.ecoActivityName,
                    },
                    selectProps: {
                      withContextSearch: true,
                      inputProps: {
                        label: 'Наименование мероприятий',
                        dataTestId: 'ecoActivityName', 
                      },
                      onChangeFormValue: () =>
                        setTimeout(() => {
                          handleChangeValue?.(projectSZPKBlockValues['9'].ecoActivityName)
                        }, 0),
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </Col>
        <Col xs={4}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: projectSZPKBlockValues['9'].ecoActivityEndDate,
            })}
          >
            <ControlledCalendarInput
              {...getCalendarInputProps({
                name: projectSZPKBlockValues['9'].ecoActivityEndDate,
                rules: projectResultEcoActivityValidationMap.ecoActivityEndDate,
                calendarInputProps: {
                  label: 'Дата реализации',
                  dataTestId: 'ecoActivityEndDate', 
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(projectSZPKBlockValues['9'].ecoActivityEndDate)
                  }, 0),
                onCalendarChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(projectSZPKBlockValues['9'].ecoActivityEndDate)
                  }, 0),
                onInputChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(projectSZPKBlockValues['9'].ecoActivityEndDate)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: projectSZPKBlockValues['9'].ecoActivityProgramNameInstrumental,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: projectSZPKBlockValues['9'].ecoActivityProgramNameInstrumental,
                rules: projectResultEcoActivityValidationMap.ecoActivityProgramNameInstrumental,
                textareaProps: {
                  label: 'Наименование и реквизиты программы',
                  dataTestId: 'ecoActivityProgramNameInstrumental', 
                  caption: 'в творительном падеже',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(
                      projectSZPKBlockValues['9'].ecoActivityProgramNameInstrumental,
                    )
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(
                      projectSZPKBlockValues['9'].ecoActivityProgramNameInstrumental,
                    )
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
    </Stack>
  )
}

export default MeasuresNegativeImpactForm
