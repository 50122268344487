import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const fifthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues['5'],
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  concessionFail: {
    required: defaultRHFValidation.required,
  },
  concessionFailDocument: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 200),
  },
}

export { fifthSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
