import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'

import { DataOnFulfillmentOfTermsSZPKFormValues } from './types'

export const DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<DataOnFulfillmentOfTermsSZPKFormValues>()
export const DataOnFulfillmentOfTermsSZPKFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
