import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import BeneficiaryOfSuccessorOrganizationInformationLayout from './Layout'
import BeneficiaryOfSuccessorOrganizationInformationModalManager from './Manager'
import BeneficiaryOfSuccessorOrganizationInformationMenu from './Menu'
import BeneficiaryOfSuccessorOrganizationInformationForm from './Statement'

interface ICreateBeneficiaryOfSuccessorOrganizationInformation extends FC {
  Layout: typeof BeneficiaryOfSuccessorOrganizationInformationLayout
  Menu: typeof BeneficiaryOfSuccessorOrganizationInformationMenu
  StatementForm: typeof BeneficiaryOfSuccessorOrganizationInformationForm
  Manager: typeof BeneficiaryOfSuccessorOrganizationInformationModalManager
}

const useBeneficiaryOfSuccessorOrganizationInformationControl = () => {
  const popupManager = usePopupManager()

  const openBeneficiaryOfSuccessorOrganizationInformationModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        BeneficiaryOfSuccessorOrganizationInformationLayout,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openBeneficiaryOfSuccessorOrganizationInformationModal }
}

const BeneficiaryOfSuccessorOrganizationInformation: ICreateBeneficiaryOfSuccessorOrganizationInformation =
  () => null

BeneficiaryOfSuccessorOrganizationInformation.Menu =
  BeneficiaryOfSuccessorOrganizationInformationMenu
BeneficiaryOfSuccessorOrganizationInformation.Layout =
  BeneficiaryOfSuccessorOrganizationInformationLayout
BeneficiaryOfSuccessorOrganizationInformation.StatementForm =
  BeneficiaryOfSuccessorOrganizationInformationForm
BeneficiaryOfSuccessorOrganizationInformation.Manager =
  BeneficiaryOfSuccessorOrganizationInformationModalManager

export { useBeneficiaryOfSuccessorOrganizationInformationControl }
export default BeneficiaryOfSuccessorOrganizationInformation
