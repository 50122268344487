const PROPERTIES = ['marginTop', 'marginBottom'] as const

const outerHeight = <T extends HTMLElement>(element: T) => {
  const height = element.offsetHeight
  const style = window.getComputedStyle(element)

  return PROPERTIES?.map((property) => parseFloat(style[property]))?.reduce(
    (total, side) => total + side,
    height,
  )
}

export default outerHeight
