import { useCallback, useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { TransfersData } from '@context/APIContext/hooks/useOrganizationApi'
import { SWRIniniteHookProps } from '@interfaces'
import useSWRInfinite from 'swr/infinite'

interface UseTransfersProps extends SWRIniniteHookProps<TransfersData[]> {
  limitSize: number
}

export const useTransfers = ({ key, limitSize, config }: UseTransfersProps) => {
  const {
    organizationApi: { getTransfers },
  } = useAPIContext()

  const {
    data: transfers,
    error,
    mutate,
    isValidating,
    setSize,
  } = useSWRInfinite(key, getTransfers, {
    ...config,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  const hasMoreData = useMemo(() => {
    return transfers && transfers?.[transfers?.length - 1]?.length === limitSize
  }, [limitSize, transfers])

  const goToNextPage = useCallback(() => {
    hasMoreData && setSize((prev) => prev + 1)
  }, [hasMoreData, setSize])

  const preparedTransfers = useMemo(() => transfers?.flat(), [transfers])

  return {
    transfers: preparedTransfers,

    error,
    isValidating,

    hasMoreData,

    mutate,
    goToNextPage,
  }
}
