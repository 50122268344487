import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { RegistrationCertificateFormValues } from '@components/Forms/RegistrationCertificateForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const tenthSectionRegistrationCertificateValidationMap: FormValuesValidationSection<
  keyof RegistrationCertificateFormValues['10'],
  RegistrationCertificateFormValues
> = {
  infoBudgetRF: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  infoDamageFormatCompensation: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  infoViolationStabilizationPeriod: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  infoPartner: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  infoDeposit: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  infoCession: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { tenthSectionRegistrationCertificateValidationMap }
