import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'
import Sixth from './6'
// import Seventh from './7'
import Eighth from './8'
import Ninth from './9'
import Tenth from './10'
import Eleventh from './11'

interface RegistrationCertificateFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
  Sixth: typeof Sixth
  // Seventh: typeof Seventh
  Eighth: typeof Eighth
  Ninth: typeof Ninth
  Tenth: typeof Tenth
  Eleventh: typeof Eleventh
}

const RegistrationCertificateForms: RegistrationCertificateFormsProps = () => null

RegistrationCertificateForms.First = First
RegistrationCertificateForms.Second = Second
RegistrationCertificateForms.Third = Third
RegistrationCertificateForms.Fourth = Fourth
RegistrationCertificateForms.Fifth = Fifth
RegistrationCertificateForms.Sixth = Sixth
// RegistrationCertificateForms.Seventh = Seventh
RegistrationCertificateForms.Eighth = Eighth
RegistrationCertificateForms.Ninth = Ninth
RegistrationCertificateForms.Tenth = Tenth
RegistrationCertificateForms.Eleventh = Eleventh

export default RegistrationCertificateForms
