import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentDataView from '@components/DocumentFormComponents/DocumentDataView'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/const'
import { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyManager } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/Manager'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap } from './validation'

const Third = () => {
  const formInstance =
    useFormContext<NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyManager()

  const { getSubscribableControlProps, getControllerProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher:
        NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFieldsControlUpdateWatcher,
    })

  const investorFullName = useWatch({
    name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues['3']
    .investorFullName,
    control: formInstance.control,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <DocumentDataView suptitle={'Полное наименование'}>{investorFullName}</DocumentDataView>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                  '3'
                ].investorFullNameInstrumental,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                    '3'
                  ].investorFullNameInstrumental,
                  rules:
                    thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap.investorFullNameInstrumental,
                  textareaProps: {
                    maxRows: Infinity,
                    label: 'Полное наименование',
                    caption: 'в творительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                          '3'
                        ].investorFullNameInstrumental,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                          '3'
                        ].investorFullNameInstrumental,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                  '3'
                ].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                    '3'
                  ].investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                  '3'
                ].investorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                    '3'
                  ].investorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                  '3'
                ].investorAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Юридический адрес'}
                controllerProps={getControllerProps({
                  name: notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyBlockValues[
                    '3'
                  ].investorAddress,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
