import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForInformationOfExecutionAgreementEventsForm } from '@components/Forms/InformationOfExecutionAgreementEventsForm/adapters/Menu.adapter'
import { generatePropertiesObjectForInformationOfExecutionAgreementEventsForm } from '@components/Forms/InformationOfExecutionAgreementEventsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForInformationOfExecutionAgreementEventsForm } from '@components/Forms/InformationOfExecutionAgreementEventsForm/adapters/RHF.adapter'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'

const useInformationOfExecutionAgreementEventsAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): InformationOfExecutionAgreementEventsFormValues =>
    generateRHFObjectForInformationOfExecutionAgreementEventsForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForInformationOfExecutionAgreementEventsForm(objectFromPropertyState)

  const generateMenuListByFormValues = (
    formValues:
      | InformationOfExecutionAgreementEventsFormValues
      | UnpackNestedValue<InformationOfExecutionAgreementEventsFormValues>,
    initialHashMap: NestedMenuHashMap,
    formErrors: FieldErrors<InformationOfExecutionAgreementEventsFormValues>,
  ): NestedMapOfMenu[] =>
    generateMenuListForInformationOfExecutionAgreementEventsForm(
      formValues,
      initialHashMap,
      formErrors,
    )

  return { generateRHFObject, generatePropertiesObject, generateMenuListByFormValues }
}

export { useInformationOfExecutionAgreementEventsAdapters }
