import { downloadSignedExtension } from '@components/Attachments/constants'
import { defaultMessage, defaultMessageSupport, errorModalHeaderTexts } from '@constants/texts'
import { isAxiosError, isJsError, isNullOrUndefined, isString } from '@helpers/checkTypes'
import { isIntervalServerError } from '@helpers/errorHelpers'
import type { IAttachmentFileItem, IDocumentItem } from '@interfaces/documents'

interface ParseFileItemSignErrorReturnType {
  headerText: string
  bodyText: string
}

class AttachmentFileItemService {
  static getFileIndexById(documents: IAttachmentFileItem[], id: string) {
    return documents.findIndex((document) => document.id === id)
  }

  static isSignedDocument(documentInfo?: IDocumentItem): boolean {
    if (!documentInfo) return false

    const { isEditable, extension } = documentInfo

    const fileWasSigned = !isNullOrUndefined(isEditable) && !isEditable

    const preparedExtension = (isString(extension) && extension.toLowerCase()) || ''

    return downloadSignedExtension.includes(preparedExtension) && fileWasSigned
  }

  static parseFileItemError(error: unknown): string {
    if (!isAxiosError(error)) return defaultMessage

    const { detail } = error.response?.data || {}

    return detail || defaultMessage
  }

  static parseFileItemSignError(error: unknown): ParseFileItemSignErrorReturnType {
    const headerText = errorModalHeaderTexts.defaultMessage

    if (!isAxiosError(error)) {
      const bodyText = isJsError(error) && error.message ? error.message : defaultMessageSupport

      return {
        headerText,
        bodyText,
      }
    }

    const { status } = error.response || {}
    const { detail, error_description } = error.response?.data || {}

    const bodyText = detail || error_description || defaultMessageSupport

    return {
      headerText,
      bodyText: isIntervalServerError(status) ? defaultMessageSupport : bodyText,
    }
  }
}

export { AttachmentFileItemService }
