import { MutableRefObject, RefObject } from 'react'

const PORTAL_CONTAINER_ATTRIBUTE = 'custom-portal-container'

const createPortalContainer = () => {
  const portalContainer = document.createElement('div')

  portalContainer.setAttribute(PORTAL_CONTAINER_ATTRIBUTE, '')

  document.body.appendChild(portalContainer)

  return portalContainer
}

const getDefaultPortalContainer = (): Element =>
  document.querySelector(`[${PORTAL_CONTAINER_ATTRIBUTE}]`) || createPortalContainer()

const setRef = <T>(
  ref: RefObject<T> | ((instance: T | null) => void) | null | undefined,
  value: T | null,
): void => {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    (ref as MutableRefObject<T | null>).current = value
  }
}

export { getDefaultPortalContainer, PORTAL_CONTAINER_ATTRIBUTE, setRef }
