import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import FormMultiSelect, {
  FormMultiSelectProps,
} from '@components/DocumentFormComponents/FormSelect/Multi'
import { OptionProps } from '@components/NewDesign/Select/model'

interface ControlledFormMultipleSelectProps<T extends FieldValues> extends FormMultiSelectProps {
  controllerProps: Omit<ControllerProps<T>, 'render'>
}

const ControlledFormMultipleSelect = <T extends FieldValues>(
  props: ControlledFormMultipleSelectProps<T>,
) => {
  const { controllerProps, selectProps, ...restProps } = props

  const {
    field: { onChange: onChangeForm, onBlur: onBlurForm, value: formValue },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const onMergedBlur = () => {
    onBlurForm()

    selectProps?.onBlurForm?.()
  }

  const onMergedChange = (
    currentList: OptionProps[],
    currentValue: OptionProps,
    prevList: OptionProps[],
  ) => {
    onChangeForm(currentList)

    selectProps?.onChangeForm?.(currentList, currentValue, prevList)
  }

  const onMergedRemoveChip = (payload: OptionProps[], removedValue: OptionProps) => {
    selectProps.onRemoveChip?.(payload, removedValue)

    if (!payload.length) {
      onBlurForm()
      onChangeForm(payload)

      return
    }

    onChangeForm(payload)
  }

  return (
    <FormMultiSelect
      {...restProps}
      selectProps={{
        ...selectProps,
        error,
        defaultSelected: selectProps.defaultSelected || formValue,
        onChangeForm: onMergedChange,
        onBlurForm: onMergedBlur,
        onRemoveChip: onMergedRemoveChip,
      }}
    />
  )
}

ControlledFormMultipleSelect.displayName = 'ControlledFormMultipleSelect'

export type { ControlledFormMultipleSelectProps }
export default ControlledFormMultipleSelect
