import { generatePrefetchedPropsForBeneficiary } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/adapters/PrefetchedProps.adapter'
import { generatePropertiesObjectForBeneficiaryOfSuccessorOrganizationInformationForm } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/adapters/PropertiesObject.adapter'
import { generateRHFObjectForBeneficiaryOfSuccessorOrganizationInformationForm } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/adapters/RHF.adapter'
import { BeneficiaryOfSuccessorOrganizationInformationFormValues } from '@components/Forms/BeneficiaryOfSuccessorOrganizationInformation/types'

const useBeneficiaryOfSuccessorOrganizationInformationAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): BeneficiaryOfSuccessorOrganizationInformationFormValues =>
    generateRHFObjectForBeneficiaryOfSuccessorOrganizationInformationForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForBeneficiaryOfSuccessorOrganizationInformationForm(
      objectFromPropertyState,
    )

  const generatePrefetchedPropsArray = (objectFromPropertyState: Record<string, any>): string[] =>
    generatePrefetchedPropsForBeneficiary(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject, generatePrefetchedPropsArray }
}

export { useBeneficiaryOfSuccessorOrganizationInformationAdapters }
