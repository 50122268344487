import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import ExpenseItem from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/6/AdditionalForms/ExpenseItem'
import { ExpensesPathName } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Forms/6/types'
import { useInformationOfExecutionConditionsSZPKManager } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Manager'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import {
  InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { isFormFieldError } = DocumentFormHelpers

const Sixth = () => {
  const formInstance = useFormContext<InformationOfExecutionConditionsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleRemoveItemFromList, handleAddItemToListWithOutValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionConditionsSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher,
  })

  const { fields: expenses } = useFieldArraySubscribableControl<
    InformationOfExecutionConditionsSZPKFormValues,
    ExpensesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: informationOfExecutionConditionsSZPKBlockValues['6'].expenses,
    keyName: 'keyNameId',
    watcher: InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const expensesError = formInstance.getFieldState(
    informationOfExecutionConditionsSZPKBlockValues['6'].expenses,
  )?.error

  const handleAddExpense = async () => {
    await handleAddItemToListWithOutValue?.(
      informationOfExecutionConditionsSZPKBlockValues['6'].expenses,
    )

    if (!expenses.length) {
      formInstance.clearErrors(informationOfExecutionConditionsSZPKBlockValues['6'].expenses)
    }
  }
  const handleRemoveExpense = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${informationOfExecutionConditionsSZPKBlockValues['6'].expenses}.${index}`,
      informationOfExecutionConditionsSZPKBlockValues['6'].expenses,
    )
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: informationOfExecutionConditionsSZPKBlockValues['6'].expenses,
                })}
              >
                <FlipperList list={expenses}>
                  {expenses.map((expense, index) => {
                    const formName =
                      `${informationOfExecutionConditionsSZPKBlockValues['6'].expenses}.${index}` as const

                    return (
                      <ExpenseItem
                        expenseId={expense.id}
                        key={expense.id}
                        blockViewIsValidating={blockViewIsValidating}
                        editMode={editMode}
                        formInstance={formInstance}
                        indexOfExpense={index}
                        name={formName}
                        onDeleteExpense={handleRemoveExpense(index)}
                      />
                    )
                  })}
                </FlipperList>
              </SubscribableControl>
            </Stack>
          </Col>
          {isFormFieldError(expensesError) && !expenses.length && (
            <Row className={'px-1'}>
              <FormError rowClassName={'px-4'} colClassName={'px-3'}>
                {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
              </FormError>
            </Row>
          )}
        </Row>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddExpense}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    dataTestId="StepProjectArrayForm-add-button"
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить направление расхода
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Sixth
