import { mapOfAdapterTypes } from '@components/DocumentFormComponents/adapters/const'
import { objOfDateFormats } from '@constants/dateFormats'
import { ValueOf } from '@helpers/ValueOf'
import DayjsService from '@services/Dayjs/Dayjs.service'

export const getCallbackFilterSzpkReportsInfoByAdapterType =
  (objectFromPropertyState: Record<string, any>, adapterType: ValueOf<typeof mapOfAdapterTypes>) =>
  (szpkReport: any) => {
    const monitoringPeriodStartDate = objectFromPropertyState.monitoringPeriodStartDate?.value
    const monitoringReportDate = objectFromPropertyState.monitoringReportDate?.value
    const szpkRegistrationDate =
      adapterType === mapOfAdapterTypes.RHF
        ? szpkReport.szpkRegistrationDate
        : szpkReport?.value?.szpkRegistrationDate?.value

    if (
      !DayjsService.isDateValid(monitoringPeriodStartDate, objOfDateFormats.defaultFormat) ||
      !DayjsService.isDateValid(monitoringReportDate, objOfDateFormats.defaultFormat) ||
      !DayjsService.isDateValid(szpkRegistrationDate, objOfDateFormats.defaultFormat)
    )
      return false

    const monitoringPeriodStartDateDayjs = DayjsService.dayjs(
      monitoringPeriodStartDate,
      objOfDateFormats.defaultFormat,
    )
    const monitoringReportDateDayjs = DayjsService.dayjs(
      monitoringReportDate,
      objOfDateFormats.defaultFormat,
    )
    const szpkRegistrationDateDayjs = DayjsService.dayjs(
      szpkRegistrationDate,
      objOfDateFormats.defaultFormat,
    )

    return (
      szpkRegistrationDateDayjs.diff(monitoringPeriodStartDateDayjs) > 0 &&
      szpkRegistrationDateDayjs.diff(monitoringReportDateDayjs) < 0
    )
  }
