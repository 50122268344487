import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOthersArrayPathName } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Forms/1/types'
import { useInformationOfExecutionAgreementEventsManager } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Manager'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import { InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionAgreementEventsForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOtherProps {
  id: string
  indexOfStep: number
  name: InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOthersArrayPathName
  formInstance: UseFormReturn<InformationOfExecutionAgreementEventsFormValues>
}

const InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOther: FC<
  InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOtherProps
> = ({ id, indexOfStep, name, formInstance }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useInformationOfExecutionAgreementEventsManager()

  const { getSubscribableControlProps, getInputProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher,
    })

  return (
    <FieldView.StepTemplate id={id} title={`Мероприятие №${indexOfStep + 1}`}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.otherActivityName`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${name}.otherActivityName`,
                  inputProps: {
                    disabled: true,
                    label: 'Наименование мероприятия',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title={' Дата завершения мероприятия'}>
          <Row>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.otherActivityEndDate`,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.otherActivityEndDate`,
                    calendarInputProps: {
                      disabled: true,
                      label: 'План',
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.factOtherActivityEndDate`,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.factOtherActivityEndDate`,
                    calendarInputProps: {
                      label: 'Факт',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.factOtherActivityEndDate`)
                      }, 0),
                    onCalendarChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.factOtherActivityEndDate`)
                      }, 0),
                    onInputChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.factOtherActivityEndDate`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </Group>
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default InformationOfExecutionAgreementEventsStepsMonitoringFactStepActivityOther
