import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultAgreementStabilizationNegotiationsFormValue } from '@components/Forms/АgreementStabilizationNegotiationsForm/const'
import AgreementStabilizationNegotiationsLayout from '@components/Forms/АgreementStabilizationNegotiationsForm/Layout'

const AgreementStabilizationNegotiationsLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultAgreementStabilizationNegotiationsFormValue}>
      <AgreementStabilizationNegotiationsLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(AgreementStabilizationNegotiationsLayoutWithFormProvider)
