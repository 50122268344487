import { FC, memo } from 'react'

import WebForm from '@components/Attachments/WebForm'
import { IDocumentSetInfo, IDocumentTypeInfoWithDict } from '@services/Documents/documents.entity'

import { ActiveNotificationAttachment } from './Attachment/ActiveNotificationAttachment'
import AddedNotificationAttachment from './Attachment/AddedNotificationAttachment'
import { INotificationItem } from './Attachment/NotificationAttachment'
import { CURRENT_DOCUMENT_SET_TYPE } from './constants'

export interface NotificationsItemDocumentProps {
  projectId: string
  dfoId: string
  attachmentLoading: INotificationItem['loading']
  documentSet: IDocumentSetInfo | undefined
  documentTypeDictInfoItem: IDocumentTypeInfoWithDict
}

const NotificationsItemDocument: FC<NotificationsItemDocumentProps> = ({
  projectId,
  dfoId,
  attachmentLoading,
  documentSet,
  documentTypeDictInfoItem,
}) => {
  const formInfo = documentTypeDictInfoItem?.forms?.[0]

  if (formInfo && documentSet) {
    return (
      <WebForm.APIProvider>
        <WebForm.ItemController
          projectId={projectId}
          documentSetId={documentSet.documentSetId}
          currentDfoId={dfoId}
          documentSetType={CURRENT_DOCUMENT_SET_TYPE}
          documentProps={documentTypeDictInfoItem}
          permissions={documentSet.permissions}
        />
      </WebForm.APIProvider>
    )
  }

  const documentInfo = documentTypeDictInfoItem?.documents?.[0]

  return (
    <>
      {documentInfo ? (
        <AddedNotificationAttachment
          loading={attachmentLoading}
          name={documentInfo.name}
          projectId={projectId}
          dfoId={dfoId}
        />
      ) : (
        <ActiveNotificationAttachment dfoId={dfoId} projectId={projectId} />
      )}
    </>
  )
}

export default memo(NotificationsItemDocument)
