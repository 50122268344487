import { IJwtDecode } from '@context/AuthContext/models'
import { AuthState } from '@context/AuthContext/types'
import { isObject } from '@helpers/checkTypes'
import jwtDecode from 'jwt-decode'

type JwtDecodeKeys = keyof IJwtDecode

interface GetByJwtDecodeProps {
  name: JwtDecodeKeys | JwtDecodeKeys[]
  onCatch?: (e: unknown) => void
  manyFields?: boolean
}

const getNestedField = (object: Record<any, any>, fields: string[]) => {
  if (!isObject(object)) return {}

  const isTargetField = fields.length === 1
  const nextValue = object[fields[0]]

  if (nextValue) {
    if (isTargetField) return nextValue

    fields.shift()
    return getNestedField(nextValue, fields)
  }
}

export const jwtDecodeFacade = (() => {
  let authState: AuthState

  return {
    initialize: (inputAuthState) => (authState = inputAuthState),
    get: ({ name, manyFields, onCatch }: GetByJwtDecodeProps) => {
      if (!authState) return
      try {
        const decoded = jwtDecode<IJwtDecode>(authState.token ?? '')
        if (Array.isArray(name)) {
          return manyFields
            ? name.reduce((acc, name) => ({ ...acc, [name]: decoded[name] }), {})
            : getNestedField(decoded, name)
        }
        return decoded[name]
      } catch (e) {
        onCatch?.(e)
      }
    },
  }
})()
