import React, { FC, memo, MutableRefObject, useEffect, useRef } from 'react'
import Dropzone, { DropzoneProps, DropzoneRef } from 'react-dropzone'
import { ReactSVG } from 'react-svg'

import { useDragAndDropHook } from '@components/FileDropzone/useDragAndDropHook'
import Button from '@components/NewDesign/Button'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import { listOfFileTypes } from '@constants/types'
import DocumentsUploadErrorIcon from '@icons/DocumentsUploadErrorIcon.svg'
import cn from 'classnames'

import styles from './FileDropzoneErrorSecondary.module.scss'

interface FileDropzoneErrorSecondaryProps {
  extensionTooltipTargetRef?: MutableRefObject<HTMLElement | null>
  rejectedFile?: File | null
  disableTooltipButton?: boolean
  titleReject?: string
  buttonReject?: string
  subTitleReject?: string
  className?: string
  dropZoneProps?: DropzoneProps
  dropzoneControlRef?: MutableRefObject<DropzoneRef | null>
}

const FileDropzoneErrorSecondary: FC<FileDropzoneErrorSecondaryProps> = ({
  rejectedFile = null,
  titleReject = 'Недопустимое расширение файла',
  buttonReject = 'с допустимым расширением',
  subTitleReject = 'Перетащите файл',
  disableTooltipButton,
  extensionTooltipTargetRef,
  dropZoneProps,
  dropzoneControlRef,
  className,
}) => {
  const dropzoneRef = useRef<DropzoneRef | null>(null)
  const { handleClose, handleRejectedFiles } = useDragAndDropHook(rejectedFile)

  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
    bindHandlersRef: { bindRefOfClickableOutsideZone },
  } = useManualTooltipControl()

  const openFileDialog = () => {
    if (!dropzoneRef?.current) return
    dropzoneRef.current?.open()

    handleClose()
  }

  const bindDropzoneRef = (ref: DropzoneRef | null) => {
    if (!ref) return

    dropzoneRef.current = ref
    if (dropzoneControlRef) dropzoneControlRef.current = ref
  }

  const handleExtensionClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    handleOpenTooltip()
  }

  useEffect(() => {
    if (!extensionTooltipTargetRef?.current) return

    bindRefOfClickableOutsideZone(extensionTooltipTargetRef.current)
  }, [bindRefOfClickableOutsideZone, extensionTooltipTargetRef])

  const tooltipContent = (
    <div className={styles.tooltip__container}>
      <Typography.Headline
        variant="headlineH3"
        color="text-accent-brand-normal"
        className={styles.title}
      >
        Допустимые расширения файлов
      </Typography.Headline>

      <div className={styles['tooltip__container-content']}>
        {listOfFileTypes.map(({ title, types }) => (
          <div className={styles['tooltip__container-wrapper']}>
            <Typography.Body variant={'bodyMRegular'} color={'text-base-secondary'}>
              {title}
            </Typography.Body>
            <Typography.Body variant={'bodyMMedium'} color={'text-base-primary'}>
              {types.join(', ')}
            </Typography.Body>
          </div>
        ))}
      </div>
    </div>
  )

  const errorTextWithTooltipButton = (
    <>
      <Tooltip
        trigger={'click'}
        open={tooltipIsOpen}
        popoverClassName={styles.tooltip__popover}
        position={'top'}
        content={tooltipContent}
        offset={[0, 10]}
        fallbackPlacements={['left', 'right', 'top-start', 'bottom-start']}
        zIndex={100}
        onClose={handleCloseTooltip}
      >
        <Button
          disabled={disableTooltipButton}
          color={'negative'}
          size={'xs'}
          view={'text'}
          variant={'buttonSMedium'}
          className={styles.tooltipButton}
          dataTestId="FileDropzoneError-fileExtension-button"
          onClick={handleExtensionClick}
        >
          {buttonReject}
        </Button>
      </Tooltip>
      <Typography.Body as={'span'} variant={'bodyMRegular'} color={'on-surface-secondary'}>
        в эту область
      </Typography.Body>
    </>
  )

  return (
    <Dropzone
      {...dropZoneProps}
      noClick
      noKeyboard
      ref={bindDropzoneRef}
      onDropRejected={dropZoneProps?.onDropRejected || handleRejectedFiles}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className: cn(styles.dropzone, styles['dropzone-secondary'], className),
          })}
        >
          <div
            role={'presentation'}
            className={cn(styles.dropzone__container, styles['dropzone__container-secondary'])}
          >
            <div
              role={'presentation'}
              className={styles.dropzone__content}
              onClick={(e) => e.preventDefault()}
            >
              <input {...getInputProps()} />
              <div
                className={cn(
                  styles['dropzone__content-info'],
                  styles['dropzone__content-info-short'],
                )}
              >
                {titleReject && (
                  <Typography.Body variant={'bodyXLMedium'}>{titleReject}</Typography.Body>
                )}
                <div className={styles['dropzone__content-subtitle']}>
                  {subTitleReject && (
                    <Typography.Body
                      as={'span'}
                      variant={'bodyMRegular'}
                      color={'on-surface-secondary'}
                    >
                      {subTitleReject}
                    </Typography.Body>
                  )}
                  {errorTextWithTooltipButton}
                  <Typography.Body
                    as={'span'}
                    variant={'bodyMRegular'}
                    color={'on-surface-secondary'}
                  >
                    &nbsp;или
                  </Typography.Body>
                  <Button
                    className={styles['dropzone__content-downloadTemplate']}
                    size={'xs'}
                    view={'text'}
                    variant={'buttonSMedium'}
                    color={'negative'}
                    dataTestId="FileDropzoneError-fileUpload-textButton"
                    onClick={openFileDialog}
                  >
                    выберите на устройстве
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <ReactSVG
            role={'button'}
            className={styles.dropzone__icon}
            src={DocumentsUploadErrorIcon}
            dataTestId="FileDropzoneError-fileUpload-button"
            onClick={openFileDialog}
          />
        </div>
      )}
    </Dropzone>
  )
}

export default memo(FileDropzoneErrorSecondary)
