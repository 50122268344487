import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { informationOfExecutionConditionsSZPKBlockValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof InformationOfExecutionConditionsSZPKFormValues['2'],
  InformationOfExecutionConditionsSZPKFormValues
> = {
  projectDecisionDepartament: {
    required: defaultRHFValidation.required,

    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  projectOkved: {
    required: defaultRHFValidation.required,
  },
  projectResultEffect: {
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  endPreinvestStage: ({ form }) => ({
    validate: {
      positiveStartPreinvestStage: (value) => {
        const startPreinvestStage = form.getValues(
          informationOfExecutionConditionsSZPKBlockValues['2'].startPreinvestStage,
        )

        return (
          (isDateValidForDayjs(value) &&
            DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >=
              DayjsService.dayjs(startPreinvestStage, objOfDateFormats.defaultFormat)) ||
          'Дата окончания стадии должна быть больше или равна дате начала стадии'
        )
      },
    },
  }),
  startInvestStage: ({ form }) => ({
    validate: {
      positiveEndPreinvestStage: (value) => {
        const endPreinvestStage = form.getValues(
          informationOfExecutionConditionsSZPKBlockValues['2'].endPreinvestStage,
        )

        return (
          (isDateValidForDayjs(value) &&
            DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >=
              DayjsService.dayjs(endPreinvestStage, objOfDateFormats.defaultFormat)) ||
          'Инвестиционная стадия должна начинаться после завершения предынвестиционной стадии'
        )
      },
    },
  }),
  endInvestStage: ({ form }) => ({
    validate: {
      positiveStartInvestStage: (value) => {
        const startInvestStage = form.getValues(
          informationOfExecutionConditionsSZPKBlockValues['2'].startInvestStage,
        )

        return (
          (isDateValidForDayjs(value) &&
            DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >=
              DayjsService.dayjs(startInvestStage, objOfDateFormats.defaultFormat)) ||
          'Дата окончания стадии должна быть больше или равна дате начала стадии'
        )
      },
    },
  }),
  startExploitationStage: ({ form }) => ({
    validate: {
      positiveEndInvestStage: (value) => {
        const endInvestStage = form.getValues(
          informationOfExecutionConditionsSZPKBlockValues['2'].endInvestStage,
        )

        return (
          (isDateValidForDayjs(value) &&
            DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >=
              DayjsService.dayjs(endInvestStage, objOfDateFormats.defaultFormat)) ||
          'Эксплуатационная стадия должна начинаться после завершения инвестиционной стадии'
        )
      },
    },
  }),
  endExploitationStage: ({ form }) => ({
    validate: {
      positiveStartExploitationStage: (value) => {
        const startExploitationStage = form.getValues(
          informationOfExecutionConditionsSZPKBlockValues['2'].startExploitationStage,
        )

        return (
          (isDateValidForDayjs(value) &&
            DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >=
              DayjsService.dayjs(startExploitationStage, objOfDateFormats.defaultFormat)) ||
          'Дата окончания стадии должна быть больше или равна дате начала стадии'
        )
      },
    },
  }),
}

export { secondSectionValidationMap }
