import React, { ReactNode, useEffect } from 'react'
import { FieldValues, Path, useController } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types'

import { formListTooltipTextsMap } from '@components/DocumentFormComponents/FormControls/FormListTooltipControl/const'
import FormActionIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormActionIconWithTooltip'
import { isFunction, isNullOrUndefined, isUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './FormListTooltipControl.module.scss'

interface ChildrenProps {
  listId: string
  iconWithTooltip: ReactNode
}

interface FormListTooltipControlProps<T extends FieldValues> {
  control: Control<T>
  name: Path<T>
  children: ReactNode | ((props: ChildrenProps) => ReactNode)
  disablePaddingRight?: boolean
  enablePaddingLeft?: boolean
  className?: string
  wrapperClassName?: string
  onDifference?: (path: Path<T> | string, isChanged: boolean) => void
}

const FormListTooltipControl = <T extends FieldValues>({
  disablePaddingRight = false,
  enablePaddingLeft = false,
  name,
  control,
  children,
  className,
  wrapperClassName,
  onDifference,
}: FormListTooltipControlProps<T>) => {
  const {
    field: { value: fieldState },
  } = useController({
    control,
    name: `${name}State` as Path<T>,
  })

  const deletedElementFromListCondition = fieldState
    ? 'deletedElementFromList' in fieldState && !!fieldState['deletedElementFromList']
    : false
  const addedElementInListCondition = fieldState
    ? 'addedElementInList' in fieldState && !!fieldState['addedElementInList']
    : false

  useEffect(() => {
    if (isUndefined(onDifference)) return

    onDifference(name, deletedElementFromListCondition)

    return () => onDifference(name, false)
  }, [deletedElementFromListCondition])

  const renderTooltip = (): JSX.Element | null => {
    if (isNullOrUndefined(fieldState)) return null

    const classNames = cn(
      styles['formListTooltipControl__icon'],
      {
        [styles['formListTooltipControl__icon--disablePaddingRight']]: disablePaddingRight,
        [styles['formListTooltipControl__icon--enablePaddingLeft']]: enablePaddingLeft,
      },
      className,
    )

    // deletedElementFromList приоритетнее
    if (deletedElementFromListCondition) {
      return (
        <div className={classNames}>
          <FormActionIconWithTooltip tooltipContent={formListTooltipTextsMap.DELETED_ELEMENT} />
        </div>
      )
    }

    if (addedElementInListCondition) {
      return (
        <div className={classNames}>
          <FormActionIconWithTooltip tooltipContent={formListTooltipTextsMap.ADDED_ELEMENT} />
        </div>
      )
    }

    return null
  }

  if (isFunction(children)) {
    return (
      <div id={name} key={name}>
        {children({
          iconWithTooltip: renderTooltip(),
        })}
      </div>
    )
  }

  return (
    <div
      id={name}
      key={name}
      className={cn(styles['formListTooltipControl__wrapper'], wrapperClassName)}
    >
      {renderTooltip()}
      {children}
    </div>
  )
}

export default FormListTooltipControl
