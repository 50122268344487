import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'
import { useNavigate } from 'react-router-dom'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import CreateProjectExample from '@components/NewDesignedModals/CreateProjectModal/Example'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import { getDfoByType } from '@components/Projects/[id]/helpers'
import Radio from '@components/Radio'
import { documentsLinks } from '@constants/documents'
import { Paths } from '@constants/paths'
import { NewDfosType, Roles } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { findOne } from '@helpers/commonHelpers'
import { useCheckUserToOldStatement } from '@hooks/new/swr/useCheckUserToOldStatement'
import { ProjectTypes } from '@services/Projects/Project.entity'
import cn from 'classnames'

import styles from './CreateProjectModal.module.scss'

interface CreatProjectFormProps {
  nameOfProject: string
  typeOfCreatement: TCreateProjectRadioState

  typeOfProject: TCreateProjectFederalRadio
}

const mapOfExamples = [
  {
    title: 'Строительство',
    subtitle: 'тип проекта',
  },
  {
    title: 'завода',
    subtitle: 'объект',
  },
  {
    title: 'для ремонта поездов',
    subtitle: 'цель объекта',
  },
  {
    title: 'на территории Московской области',
    subtitle: 'местоположение',
  },
] as const

export type TCreateProjectRadioState = 'createProject' | 'createStatement'

export type TCreateProjectFederalRadio = 'isFederal' | 'isNotFederal'

const CreateProjectModal: FC<Required<PopupProps>> = ({ isOpen, onClose }) => {
  const { getUserRole } = useAuthContext()
  const {
    projectsApi: { createProject },
  } = useAPIContext()

  const navigate = useNavigate()

  const popupManager = usePopupManager()
  const { handleOpenLoadingModal } = useLoadingModal()

  const { roles } = getUserRole?.() || {}
  const isInvestor = roles && findOne(roles, Roles.Investor)

  const {
    formState: { isSubmitting, isValid },
    handleSubmit,
    getValues,
    control,
    watch,
  } = useForm<CreatProjectFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nameOfProject: '',
      typeOfCreatement: 'createProject',
      typeOfProject: 'isFederal',
    },
  })

  const { isOldStatement } = useCheckUserToOldStatement({
    key: { _key: 'oldStatement' },
    config: {
      isPaused: () => !isInvestor,
    },
  })

  const textAreaRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    textAreaRef.current?.focus()
  }, [])

  const nameOfProjectInForm = watch('nameOfProject')

  const handleCreateProject = useCallback(async () => {
    const { nameOfProject, typeOfCreatement, typeOfProject } = getValues()

    if (!nameOfProject) return

    handleOpenLoadingModal()

    try {
      const data = await createProject({
        name: nameOfProject,
        isOldStatement: typeOfCreatement === 'createStatement',
        isFederal: typeOfProject === 'isFederal',
        type: ProjectTypes.SZPK,
      })

      if (!data) return

      const findInvestProjectDfo = getDfoByType(data.dfos, NewDfosType.INVESTPROJECT)

      const dfoUrlSearchParams = new URLSearchParams({
        dfoId: findInvestProjectDfo ? findInvestProjectDfo.id : data.dfos[0].id,
      }).toString()

      navigate({
        pathname: `${Paths.Projects}/${data.projectId}`,
        search: dfoUrlSearchParams,
      })

      onClose()
    } catch {
    } finally {
      popupManager.closeAll()
    }
  }, [createProject, getValues, handleOpenLoadingModal, navigate, onClose, popupManager])

  const handleModalClose = useCallback(() => {
    onClose()
  }, [onClose])

  const actions = useMemo(() => {
    return [
      {
        view: 'gray',
        dataTestId: 'CreateProjectModal-cancel-button',
        children: 'Отмена',
        onClick: handleModalClose,
      },
      {
        dataTestId: 'CreateProjectModal-createProject-button',
        children: 'Создать соглашение',
        disabled: !nameOfProjectInForm.length || !isValid || isSubmitting,
        onClick: handleCreateProject,
      },
    ] as ButtonProps[]
  }, [handleCreateProject, handleModalClose, isSubmitting, isValid, nameOfProjectInForm.length])

  return (
    <Modal.Action
      simpleModalContainerClassName={styles.createProjectModal}
      isOpen={isOpen}
      title={'Создание СЗПК'}
      dataTestId="CreateProjectModal-modal"
      closeButtonDataTestId="CreateProjectModal-modal-closeButton"
      actions={actions}
      onClose={onClose}
    >
      <form className={styles.createProjectModal} onSubmit={handleSubmit(handleCreateProject)}>
        {isOldStatement && (
          <Radio.ControlledGroup name={'typeOfCreatement'} control={control}>
            <Radio.Button label={'Создать новое'} value={'createProject'} />
            <Radio.Button
              label={'Добавить заявление, поданное до 01.04.2021'}
              value={'createStatement'}
            />
          </Radio.ControlledGroup>
        )}
        <div className={cn({ [styles.createProjectModal__textarea]: isOldStatement })}>
          <ControlledTextarea
            name={'nameOfProject'}
            control={control}
            textareaProps={{
              label: 'Официальное наименование инвестиционного проекта',
              caption: 'наименование проекта должно соответствовать решению об утверждении бюджета',
              fixWidth: true,
              dataTestId: 'CreateProjectModal-nameOfProject-textarea',
            }}
            rules={{
              maxLength: {
                value: 1000,
                message: 'Название проекта соглашения не может превышать 1000 символов',
              },
            }}
          />
        </div>
        <div className={styles.createProjectModal__example}>
          <Typography.Body color={'text-base-secondary'} variant={'bodyMMedium'}>
            Пример заполнения
          </Typography.Body>
          <div className={styles['createProjectModal__example-items']}>
            {mapOfExamples.map((example, index) => {
              return (
                <CreateProjectExample
                  key={index}
                  title={example.title}
                  subtitle={example.subtitle}
                />
              )
            })}
          </div>
        </div>

        <div className={styles.createProjectModal__isFederal}>
          <Typography.Body variant={'bodyMMedium'}>
            Российская Федерация участвует в соглашении?
          </Typography.Body>
          <div className={styles['createProjectModal__isFederal-radio']}>
            <Radio.ControlledGroup control={control} name={'typeOfProject'}>
              <Radio.Button value={'isFederal'} label={'участвует'} />
              <Radio.Button value={'isNotFederal'} label={'не участвует'} />
            </Radio.ControlledGroup>
          </div>

          <Typography.Caption
            className={styles['createProjectModal__isFederal-caption']}
            variant={'captionSRegular'}
          >
            {
              'РФ может стать участником соглашения при объеме капиталовложений от 750 млн руб. в зависимости от '
            }
            <Typography.Caption as={'span'} variant={'captionSRegular'} color={'text-accent-brand'}>
              <a href={documentsLinks.FZ_N69} target="_blank" rel="noopener noreferrer">
                сферы экономики
              </a>
            </Typography.Caption>
            {' (п. 2 ч. 4 ст. 9 69-ФЗ)'}
          </Typography.Caption>
        </div>
      </form>
    </Modal.Action>
  )
}

export default memo(CreateProjectModal)
