import React, { FC, useRef } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import FieldView from '@components/DocumentFormComponents/FieldView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { stepsMonitoringEndedValidationMap } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Forms/3/StepsMonitoringEnded/validation'
import { CertificateOfImplementationInvestprojectStagesStepsMonitoringEndedArrayPathName } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Forms/3/types'
import { useCertificateOfImplementationInvestprojectStagesManager } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/Manager'
import {
  CertificateOfImplementationInvestprojectStagesFormModifierValues,
  CertificateOfImplementationInvestprojectStagesFormValues,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'
import { CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher } from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface CertificateOfImplementationInvestprojectStagesStepsMonitoringEndedProps {
  id: string
  indexOfStep: number
  formName: CertificateOfImplementationInvestprojectStagesStepsMonitoringEndedArrayPathName
  formInstance: UseFormReturn<CertificateOfImplementationInvestprojectStagesFormValues>
  formModifierInstance: UseFormReturn<CertificateOfImplementationInvestprojectStagesFormModifierValues>
  onRemove: VoidFunction
}

const CertificateOfImplementationInvestprojectStagesStepsMonitoringEnded: FC<
  CertificateOfImplementationInvestprojectStagesStepsMonitoringEndedProps
> = ({ id, indexOfStep, formInstance, formModifierInstance, formName, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleChangePublicValueInList,
      handleChangePublicValue,
    },
    preparedProps: { subscribableControl },
  } = useCertificateOfImplementationInvestprojectStagesManager()

  const {
    getSubscribableControlProps,
    getAmountInputProps,
    getTextareaProps,
    getInputProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    watcher: CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher,
    subscribableControl,
  })

  const stepNumber = useWatch({
    control: formInstance.control,
    name: `${formName}.stepNumber`,
  })

  const accordionStateRefs = useRef<boolean[]>([])

  const handleUpdateAccordionStateOnToggle = (index: number) => (value: boolean) => {
    accordionStateRefs.current[index] = value
  }

  return (
    <Accordion
      title={stepNumber?.displayValue || ''}
      id={id}
      onRemove={onRemove}
      onToggle={handleUpdateAccordionStateOnToggle(indexOfStep)}
    >
      <Container className={'p-2'}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.stepNumber`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item.stepMonitoringInvestor?.planStepId?.stepNumber || '',
                          value: item?.id,
                        }),
                        controllerProps: {
                          name: `${formName}.stepNumber`,
                          rules: stepsMonitoringEndedValidationMap.stepNumber,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Этап',
                          },
                          onChangeFormValue: () => {
                            setTimeout(() => {
                              handleChangeValue?.(`${formName}.stepNumber`)
                            }, 0)
                          },
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <FormFieldControl
              path={`${formName}.endedStepTotalCapitalValue`}
              watcher={CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher}
              formFieldTooltipProps={{
                isNew: true,
              }}
              formFieldPublicProps={{
                control: formModifierInstance.control,
                onPublic: (value) =>
                  handleChangePublicValue?.(`${formName}.endedStepTotalCapitalValue`, value),
              }}
              {...subscribableControl}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.endedStepTotalCapitalValue`,
                  rules: stepsMonitoringEndedValidationMap.endedStepTotalCapitalValue,
                  inputProps: {
                    label: 'Объем капитальных вложений',
                  },
                  onBlur: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${formName}.endedStepTotalCapitalValue`),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.endedStepTotalCapitalValue`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Row>
          <Row>
            <FormFieldControl
              path={`${formName}.endedStepInvestorCapitalValue`}
              watcher={CertificateOfImplementationInvestprojectStagesFieldsControlUpdateWatcher}
              formFieldTooltipProps={{
                isNew: true,
              }}
              formFieldPublicProps={{
                control: formModifierInstance.control,
                onPublic: (value) =>
                  handleChangePublicValue?.(`${formName}.endedStepInvestorCapitalValue`, value),
              }}
              {...subscribableControl}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${formName}.endedStepInvestorCapitalValue`,
                  rules: stepsMonitoringEndedValidationMap.endedStepInvestorCapitalValue,
                  inputProps: {
                    label: 'Объем капиталовложений',
                  },
                  onBlur: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${formName}.endedStepInvestorCapitalValue`),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.endedStepInvestorCapitalValue`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Row>
          <Row>
            <Col xs={12}>
              <Border />
            </Col>
          </Row>
        </Stack>
      </Container>

      <FieldView.ContainerWithPublic
        control={formModifierInstance.control}
        editMode={editMode}
        containerClassName={'p-2'}
        publicFormName={`${formName}.isEndedStepActivityChange`}
        onPublic={handleChangePublicValueInList}
      >
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.endedStepActivityName`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.endedStepActivityName`,
                  rules: stepsMonitoringEndedValidationMap.endedStepActivityName,
                  textareaProps: {
                    label: 'Наименование мероприятия',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.endedStepActivityName`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.endedStepActivityName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.endedStepActivityValue`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.endedStepActivityValue`,
                  rules: stepsMonitoringEndedValidationMap.endedStepActivityValue,
                  inputProps: {
                    label: 'Значение показателя',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${formName}.endedStepActivityValue`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${formName}.endedStepActivityValue`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </FieldView.ContainerWithPublic>
    </Accordion>
  )
}

export default CertificateOfImplementationInvestprojectStagesStepsMonitoringEnded
