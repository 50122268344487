import { ComponentProps, ComponentType, HTMLAttributeAnchorTarget, MouseEvent } from 'react'

import Button, { ButtonProps } from '@components/Button'
import Modal from '@components/Modal'
import cn from 'classnames'

export interface SimpleModalComponentProps extends ComponentProps<typeof Modal> {
  onSubmit?(event: MouseEvent<HTMLButtonElement>): void
  onReject?(event: MouseEvent<HTMLButtonElement>): void
  textHeader: string
  linkWithCopyAfterDescription?: string
  description: string
  buttonSubmitText?: string
  buttonRejectText?: string
  buttonSubmitPriority?: ButtonProps['priority']
  buttonRejectPriority?: ButtonProps['priority']
  hideCancelButton?: boolean
  hideSubmitButton?: boolean
  href?: string
  target?: HTMLAttributeAnchorTarget
  isCloseModalIcon?: boolean
  closeAfterSubmit?: boolean
  contentClassname?: string
  actionsClassname?: string
  buttonSubmitProps?: ButtonProps
  buttonRejectProps?: ButtonProps
}

const SimpleModal: ComponentType<SimpleModalComponentProps> = ({
  textHeader,
  description,
  linkWithCopyAfterDescription,
  buttonSubmitText,
  buttonRejectText,
  buttonSubmitPriority,
  buttonRejectPriority,
  hideCancelButton,
  hideSubmitButton,
  href,
  target,
  isCloseModalIcon = false,
  onSubmit,
  onReject,
  onClose,
  closeAfterSubmit,
  contentClassname,
  actionsClassname,
  buttonSubmitProps,
  buttonRejectProps,
  ...restProps
}) => {
  const preparedSubmitHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (closeAfterSubmit) {
      onClose?.()
    }

    onSubmit?.(event)
  }

  const preparedCloseHandler = (event: MouseEvent<HTMLButtonElement>) => {
    onReject?.(event)

    onClose?.()
  }

  return (
    <Modal
      isCloseModalIcon={isCloseModalIcon}
      size="l"
      title={textHeader}
      linkWithCopyAfterDescription={linkWithCopyAfterDescription}
      description={description}
      onClose={preparedCloseHandler}
      {...restProps}
    >
      <div className={contentClassname}>{restProps.children}</div>
      <div className={cn('flex justify-end space-x-4 mt-12', actionsClassname)}>
        {!hideCancelButton && (
          <Button
            {...buttonRejectProps}
            priority={buttonRejectPriority || 'secondary-positive'}
            size="s"
            onClick={preparedCloseHandler}
          >
            {buttonRejectText || 'Отказаться'}
          </Button>
        )}
        {!hideSubmitButton && (
          <Button
            {...buttonSubmitProps}
            priority={buttonSubmitPriority || 'primary-positive'}
            size="s"
            href={href}
            target={target}
            onClick={preparedSubmitHandler}
          >
            {buttonSubmitText || 'Разрешить'}
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default SimpleModal
