import React, { DetailedHTMLProps, FC, FormHTMLAttributes, memo, ReactNode } from 'react'

import AutoGeneratedFormBanner from '@components/DocumentFormComponents/AutoGeneratedForm/Banner'
import styles from '@components/DocumentFormComponents/Form/Form.module.scss'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import FormBlock from '@components/DocumentFormComponents/FormBlock'
import { MapOfMenu } from '@components/DocumentFormComponents/types'
import Loader from '@components/Loader'
import cn from 'classnames'

export interface DocumentFormProps
  extends DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  mapOfMenu: MapOfMenu[]
  mapOfForms: FormItemProps
  formIsLoading: boolean
  onBindFormsRef?: (item: MapOfMenu) => (ref: HTMLDivElement | null) => void
  onSubmitForm?: (e: unknown) => Promise<void>
  additionalContent?: ReactNode
  isAutoGeneratedForm?: boolean
}

const DocumentForm: FC<DocumentFormProps> = ({
  formIsLoading,
  mapOfMenu,
  mapOfForms,
  onBindFormsRef,
  onSubmitForm,
  additionalContent,
  isAutoGeneratedForm,
  ...rest
}) => {
  return (
    <div className={cn(styles.form, rest.className)}>
      {isAutoGeneratedForm && <AutoGeneratedFormBanner />}
      <form
        {...rest}
        className={cn({
          [styles['form--autogenerated']]: isAutoGeneratedForm,
        })}
        onSubmit={onSubmitForm}
      >
        {mapOfMenu.map((item) => {
          return !formIsLoading ? (
            <FormBlock
              {...mapOfForms[item.id].overrideBlockProps}
              key={item.id}
              id={item.id}
              className={cn(styles.form__block, mapOfForms[item.id].overrideBlockProps?.className)}
              titleOfBLock={item.title}
              ref={onBindFormsRef?.(item)}
            >
              {mapOfForms[item.id].node}
            </FormBlock>
          ) : (
            <FormBlock
              key={item.id}
              id={item.id}
              className={cn(styles.form__block, styles['form__block--loading'])}
              titleOfBLock={item.title}
              contentClassname={styles['form__block-content--loading']}
            >
              <Loader loading dataTestId="form-form-loader" className={styles.form__loader} />
            </FormBlock>
          )
        })}
        {additionalContent}
      </form>
    </div>
  )
}

export default memo(DocumentForm)
