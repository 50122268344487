import { FC, memo } from 'react'

import { isNumber, isString } from '@helpers/checkTypes'
import cn from 'classnames'

import { defaultIndicatorOptions } from './const'
import styles from './Indicator.module.scss'
import { IndicatorOptions, IndicatorValue } from './types'

interface IndicatorProps {
  options?: IndicatorOptions
  activeValue?: IndicatorValue | null
  wrapperClassName?: string
  direction?: 'horizontal' | 'vertical'
  reverse?: boolean
}

const Indicator: FC<IndicatorProps> = ({
  options = defaultIndicatorOptions,
  activeValue,
  wrapperClassName,
  direction = 'horizontal',
  reverse = false,
}) => {
  const listClassName = cn(styles['indicator__list'], {
    [styles['indicator__list--vertical']]: direction === 'vertical' && !reverse,
    [styles['indicator__list--vertical-reverse']]: direction === 'vertical' && reverse,
    [styles['indicator__list--horizontal']]: direction === 'horizontal' && !reverse,
    [styles['indicator__list--horizontal-reverse']]: direction === 'horizontal' && reverse,
  })

  return (
    <div className={cn(styles.indicator, wrapperClassName)}>
      <ul className={listClassName}>
        {options.map(({ value: optionValue, activeColor, mainColor }, index) => {
          const isActive = activeValue && optionValue === activeValue
          const ariaLabel =
            isString(optionValue) || isNumber(optionValue)
              ? `Значение: ${optionValue} ${isActive ? '(активно)' : ''}`
              : ''

          const itemValueInlineStyles = {
            backgroundColor: (isActive && activeColor) || mainColor || activeColor,
            opacity: !isActive && !mainColor ? 0.4 : '',
          }
          const itemValueClassName = cn(styles['indicator__item-value'], {
            [styles['indicator__item-value--active']]: isActive,
          })

          return (
            <li key={index} className={styles.indicator__item} aria-label={ariaLabel}>
              <span style={itemValueInlineStyles} className={itemValueClassName}></span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export type { IndicatorProps }

export default memo(Indicator)
