import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useIndicatorsOfUnfulfilledAgreementReportManager } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/Manager'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'

import { mapOfIndicatorsOfUnfulfilledAgreementReportMenu } from '../const'
import IndicatorsOfUnfulfilledAgreementReportForms from '../Forms'

const mapOfIndicatorsOfUnfulfilledAgreementReportForms: FormItemProps = {
  '1': {
    node: <IndicatorsOfUnfulfilledAgreementReportForms.First />,
    overrideBlockProps: {
      withBlockTitleIndicator: true,
    },
  },
  '2': {
    node: <IndicatorsOfUnfulfilledAgreementReportForms.Second />,
  },
  '3': {
    node: <IndicatorsOfUnfulfilledAgreementReportForms.Third />,
  },
  '4': {
    node: <IndicatorsOfUnfulfilledAgreementReportForms.Fourth />,
  },
  '5': {
    node: <IndicatorsOfUnfulfilledAgreementReportForms.Fifth />,
  },
} as const

const IndicatorsOfUnfulfilledAgreementReportForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<IndicatorsOfUnfulfilledAgreementReportFormValues>()

  const {
    state: { formIsLoading },
  } = useIndicatorsOfUnfulfilledAgreementReportManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfIndicatorsOfUnfulfilledAgreementReportMenu}
      mapOfForms={mapOfIndicatorsOfUnfulfilledAgreementReportForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(IndicatorsOfUnfulfilledAgreementReportForm)
