import React, { FC, memo, useCallback } from 'react'
import { useParams } from 'react-router'

import { useDfosManager } from '@components/Projects/[id]/DfosManager'
import Actions from '@components/Projects/[id]/DocumentLayout/Navigation/Actions'
import { useNavigationLoading } from '@components/Projects/[id]/DocumentLayout/Navigation/hook'
import NavigationLoader from '@components/Projects/[id]/DocumentLayout/Navigation/Loader'
import { useAvailableConfigsByRole } from '@hooks/new/configs/useAvailableDfoConfigsByRole'
import { useDebounceLoading } from '@hooks/new/loading/useDebounceLoading'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'

import DocumentContentGroup from './Group'

interface DocumentNavigationProps {
  currentDfoInfo: IDfoListItem | null
}

const DocumentsNavigation: FC<DocumentNavigationProps> = ({ currentDfoInfo }) => {
  const { projectId } = useParams()

  const {
    state: { projectDfos, projectDfosWithGroup, dfoAvailableForCreate, createdDfoCategories },
    handlers: { handleChangeDfo },
  } = useDfosManager()

  const isNavigationLoading = useNavigationLoading(projectId || '')
  const { isLoadingRoleConfig } = useAvailableConfigsByRole()

  const { isLoading: isLoadingAllNavigationData } = useDebounceLoading(
    isNavigationLoading || isLoadingRoleConfig,
    [isNavigationLoading, isLoadingRoleConfig],
    300,
  )

  const cardAddDfo = projectId && dfoAvailableForCreate && (
    <Actions.AddChangesToProject
      projectId={projectId}
      projectDfos={projectDfos}
      dfoAvailableForCreate={dfoAvailableForCreate}
      createdDfoCategory={createdDfoCategories}
    />
  )

  const handleChangeDfoFromNavigation = useCallback(
    (dfo: IDfoListItem) => {
      handleChangeDfo?.(dfo)
    },
    [handleChangeDfo],
  )

  if (isLoadingAllNavigationData) return <NavigationLoader />

  //TODO: Продумать лучше
  if (!currentDfoInfo) return null

  if ((projectDfosWithGroup?.length ?? 0) > 1) {
    return (
      <>
        {projectDfosWithGroup?.map((group, index) => (
          <DocumentContentGroup
            key={index}
            group={group}
            currentDfoId={currentDfoInfo?.id}
            dfoAvailableForCreate={dfoAvailableForCreate}
            createdDfoCategories={createdDfoCategories}
            dataTestId={`DocumentContentGroup-groupButton-${group.groupType}`}
            iconButtonDataTestId={`DocumentContentGroup-groupIconButton-${group.groupType}`}
            onDfoChange={handleChangeDfo}
          />
        ))}

        {cardAddDfo}
      </>
    )
  }

  return (
    <>
      {projectDfosWithGroup?.map((group, index) => (
        <DocumentContentGroup
          onlyDocumentsWithoutGroup
          key={index}
          group={group}
          currentDfoId={currentDfoInfo?.id}
          dfoAvailableForCreate={dfoAvailableForCreate}
          createdDfoCategories={createdDfoCategories}
          dataTestId={`DocumentContentGroup-groupButton-${group.groupType}`}
          onDfoChange={handleChangeDfoFromNavigation}
        />
      ))}

      {cardAddDfo}
    </>
  )
}

export default memo(DocumentsNavigation)
