import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { isString } from '@helpers/checkTypes'
import cloneDeep from 'clone-deep'

interface QueryGeneralOptions {
  initialSearchParams?: URLSearchParams
  applyInstantly?: boolean
}

interface IUseQueryManagerAdd extends QueryGeneralOptions {
  query: Record<string, string>
}

interface IUseQueryManagerRemove extends QueryGeneralOptions {
  exceptions?: string[]
}

interface IUserQueryManagerRemove extends QueryGeneralOptions {
  query: Array<string> | string
}

const useQueryManager = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getQuery = useCallback((name: string) => searchParams.get(name), [searchParams])

  const addQuery = useCallback(
    ({ query, initialSearchParams, applyInstantly = true }: IUseQueryManagerAdd) => {
      const currentParams = initialSearchParams ?? (cloneDeep(searchParams) as URLSearchParams)

      const queryEntry = Object.entries(query)
      if (queryEntry.length < 2) {
        const [queryName, queryValue] = queryEntry[0]

        currentParams.set(queryName, queryValue)
      } else {
        queryEntry.forEach(([queryName, queryValue]) => {
          if (queryValue) {
            currentParams.set(queryName, queryValue)
          } else {
            currentParams.delete(queryName)
          }
        })
      }

      if (applyInstantly) {
        setSearchParams(currentParams)
        return
      }

      return currentParams
    },
    [searchParams, setSearchParams],
  )

  const removeQuery = useCallback(
    ({ query, initialSearchParams, applyInstantly = true }: IUserQueryManagerRemove) => {
      const currentParams = initialSearchParams ?? (cloneDeep(searchParams) as URLSearchParams)

      if (isString(query)) {
        currentParams.delete(query)
      } else {
        query.forEach((queryToDelete) => {
          currentParams.delete(queryToDelete)
        })
      }

      if (applyInstantly) {
        setSearchParams(currentParams)
        return
      }

      return currentParams
    },
    [searchParams, setSearchParams],
  )

  const clearAllQuery = useCallback(
    ({ exceptions, initialSearchParams, applyInstantly = true }: IUseQueryManagerRemove = {}) => {
      const currentParams = initialSearchParams ?? (cloneDeep(searchParams) as URLSearchParams)

      for (const params of Array.from(currentParams)) {
        const [value] = params
        if (!exceptions || !exceptions.length) {
          searchParams.delete(value)
          continue
        }

        if (exceptions.every((exception) => exception !== value)) {
          searchParams.delete(value)
        }
      }

      if (applyInstantly) {
        setSearchParams(currentParams)
        return
      }

      return currentParams
    },
    [searchParams, setSearchParams],
  )

  const queryUtils = useMemo(
    () => ({
      getQuery,
      addQuery,
      removeQuery,
      clearAllQuery,
    }),
    [addQuery, clearAllQuery, getQuery, removeQuery],
  )

  return {
    searchParams,
    setSearchParams,
    queryUtils,
  }
}

export { useQueryManager }
