import React, { FC, ReactNode } from 'react'

import Typography from '@components/Typography'
import cn from 'classnames'

import styles from './NoItemText.module.scss'

export interface NoItemTextProps extends Partial<React.ComponentProps<typeof Typography>> {
  children: ReactNode
}

const NoItemText: FC<NoItemTextProps> = ({ children, className, ...rest }) => {
  return (
    <Typography
      {...rest}
      variant="p"
      color="secondary"
      className={cn(styles.noItemText, className)}
    >
      {children}
    </Typography>
  )
}

export default NoItemText
