import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { thirteenSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/13/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Thirteen = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getInputProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ProjectSZPKFieldsControlUpdateWatcher,
    })

  const projectIsFederal = useWatch({
    name: 'additionalFields.isFederal',
    control: formInstance?.control,
  })

  const isMunicipalityParticipant = useWatch({
    name: 'additionalFields.isMunicipalityParticipant',
    control: formInstance?.control,
  })

  const extraMunicipalAccessionLinkStabilization = useWatch({
    name: projectSZPKBlockValues['13'].extraMunicipalAccessionLinkStabilization,
    control: formInstance.control,
  })

  const extraMunicipalAccessionLinkCompensation = useWatch({
    name: projectSZPKBlockValues['13'].extraMunicipalAccessionLinkCompensation,
    control: formInstance.control,
  })

  const extraMunicipalAccessionLinkStabilizationRenderCondition = projectIsFederal
    ? !!extraMunicipalAccessionLinkStabilization
    : isMunicipalityParticipant

  const extraMunicipalAccessionLinkCompensationRenderCondition = projectIsFederal
    ? !!extraMunicipalAccessionLinkCompensation
    : isMunicipalityParticipant

  const isExtraMunicipalAccessionLinksDisabled = !!projectIsFederal

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        {extraMunicipalAccessionLinkStabilizationRenderCondition && (
          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableBottomDefaultStyles
                title="Пункты дополнительного соглашения о присоединении муниципального образования к СЗПК, определяющие срок стабилизационной оговорки"
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['13'].extraMunicipalAccessionLinkStabilization,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: projectSZPKBlockValues['13'].extraMunicipalAccessionLinkStabilization,
                      rules: thirteenSectionValidationMap.extraMunicipalAccessionLinkStabilization,
                      inputProps: {
                        disabled: isExtraMunicipalAccessionLinksDisabled,
                        dataTestId: 'extraMunicipalAccessionLinkStabilization',
                        caption: 'заполняется, если МО участвует в соглашении',
                        leftAddons: (
                          <FormIconWithTooltip tooltipContent="рекомендация: 2.2 - 2.4" />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            projectSZPKBlockValues['13'].extraMunicipalAccessionLinkStabilization,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            projectSZPKBlockValues['13'].extraMunicipalAccessionLinkStabilization,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title="Количество лет, в течение которых применяется стабилизационная оговорка"
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: projectSZPKBlockValues['13'].stabilizationPeriod,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: projectSZPKBlockValues['13'].stabilizationPeriod,
                    rules: thirteenSectionValidationMap.stabilizationPeriod,
                    inputProps: {
                      suffix: '',
                      dataTestId: 'stabilizationPeriod',
                      integersOnly: true,
                      integerLength: 2,
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(projectSZPKBlockValues['13'].stabilizationPeriod)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          projectSZPKBlockValues['13'].stabilizationPeriod,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        {extraMunicipalAccessionLinkCompensationRenderCondition && (
          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableBottomDefaultStyles
                title="Пункт дополнительного соглашения о присоединении муниципального образования к СЗПК, определяющий срок неприменения актов, изменяющих решение о предоставлении меры гос. поддержки"
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['13'].extraMunicipalAccessionLinkCompensation,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: projectSZPKBlockValues['13'].extraMunicipalAccessionLinkCompensation,
                      rules: thirteenSectionValidationMap.extraMunicipalAccessionLinkCompensation,
                      inputProps: {
                        disabled: isExtraMunicipalAccessionLinksDisabled,
                        dataTestId: 'extraMunicipalAccessionLinkCompensation',
                        caption: 'заполняется, если МО участвует в соглашении',
                        leftAddons: <FormIconWithTooltip tooltipContent="рекомендация: 2.1" />,
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            projectSZPKBlockValues['13'].extraMunicipalAccessionLinkCompensation,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            projectSZPKBlockValues['13'].extraMunicipalAccessionLinkCompensation,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
        )}
      </Stack>
    </Container>
  )
}
export default Thirteen
