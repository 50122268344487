import React, { FC, memo } from 'react'

import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import WebFormDocumentItem from '@components/Attachments/WebForm/WithDocumentController/DocumentItem'
import WebFormDocumentStatus from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DocumentStatus'
import WebFormDocumentDownloadButton from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/DownloadButton'
import WebFormDocumentFormButton from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/FormButton'
import type { WebFormStateWithDocumentControllerStateProps } from '@components/Attachments/WebForm/WithDocumentController/WebFormDocumentController/types'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerStatusLoader from '@components/ControlContainer/Status/Loader'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import Button from '@components/NewDesign/Button'
import env from '@config'

const WebFormNewStateWithDocumentController: FC<WebFormStateWithDocumentControllerStateProps> = ({
  digitizingInProcess,
  documentInfoFromDict,
  downloadProps,
  document,
  statusType,
  permissions,
  onControlClick,
}) => {
  const { description: title, isVisible } = documentInfoFromDict

  const { isWebFormReadDigitizing, isWebFormReadDownloadingForbidden } =
    useWebFormPermissions(permissions)

  if (!!digitizingInProcess && isWebFormReadDigitizing && isVisible) {
    return (
      <WebFormDocumentItem
        title={title}
        document={document}
        leadingNodeContent={statusType && <WebFormDocumentStatus type={statusType} />}
        trailingNodeContent={
          <>
            <WebFormDocumentFormButton
              disabled
              tooltipProps={{
                content: 'Идет создание формы, пожалуйста, подождите',
              }}
              loaderProps={{
                variant: 'lite',
                placement: 'leading',
                loading: true,
              }}
            >
              Открыть форму
            </WebFormDocumentFormButton>
            <WebFormDocumentDownloadButton {...downloadProps} />
          </>
        }
        onClick={onControlClick}
      />
    )
  }

  if (!!digitizingInProcess && isWebFormReadDigitizing && !isVisible) {
    return (
      <ControlContainer
        title={title}
        leadingNodeContent={<ControlContainerStatusLoader />}
        additionalMainContent={
          <ControlContainerSubtitle>
            Идет создание формы, пожалуйста, подождите
          </ControlContainerSubtitle>
        }
      />
    )
  }

  if (!digitizingInProcess && isWebFormReadDownloadingForbidden) {
    return (
      <WebFormDocumentItem
        title={title}
        document={document}
        leadingNodeContent={statusType && <WebFormDocumentStatus type={statusType} />}
        onClick={onControlClick}
      />
    )
  }

  return (
    <ControlContainer
      color="negative"
      title={title}
      leadingNodeContent={<ControlContainerStatusIcon iconType="error" />}
      additionalMainContent={
        <ControlContainerSubtitle
          additionalContent={
            <Button
              href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}
              size="xs"
              view="text"
              variant="buttonSMedium"
              color="negative"
            >
              Сообщить в поддержку
            </Button>
          }
        >
          Произошла ошибка получения формы документа.
        </ControlContainerSubtitle>
      }
    />
  )
}

export default memo(WebFormNewStateWithDocumentController)
