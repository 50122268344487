import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  SupplementalCostSharingAContractDutyMembers,
  SupplementalCostSharingAContractDutyObject,
  SupplementalCostSharingAFormValues,
  SupplementalCostSharingAObjectArea,
} from '@components/Forms/SupplementalCostSharingAForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFForSupplementalCostSharingAForm = (
  objectFromPropertyState: Record<string, any>,
): SupplementalCostSharingAFormValues => {
  const preparedContractDutyMembers: SupplementalCostSharingAContractDutyMembers[] = (() => {
    if (
      !isArray(objectFromPropertyState?.dfoContractDuty?.value?.contractDutyMembers?.value) ||
      !objectFromPropertyState?.dfoContractDuty?.value?.contractDutyMembers?.value?.length
    )
      return []

    return objectFromPropertyState.dfoContractDuty.value.contractDutyMembers.value.map(
      (contractDutyMember): SupplementalCostSharingAContractDutyMembers => ({
        id: contractDutyMember.propertyId,
        contractDutyMemberName: contractDutyMember?.value?.contractDutyMemberName?.value || '',
        contractDutyMemberOgrn: contractDutyMember?.value?.contractDutyMemberOgrn?.value || '',
        contractDutyMemberInn: contractDutyMember?.value?.contractDutyMemberInn?.value || '',
        contractDutyMemberAddress:
          contractDutyMember?.value?.contractDutyMemberAddress?.value || '',
      }),
    )
  })()

  const preparedContractDutyObjects: SupplementalCostSharingAContractDutyObject[] = (() => {
    if (
      !isArray(objectFromPropertyState?.dfoContractDuty?.value?.contractDutyObjects?.value) ||
      !objectFromPropertyState?.dfoContractDuty?.value?.contractDutyObjects?.value?.length
    )
      return []

    return objectFromPropertyState.dfoContractDuty.value.contractDutyObjects.value.map(
      (contractDutyObject): SupplementalCostSharingAContractDutyObject => {
        const preparedObjectAreas =
          isArray(contractDutyObject?.value?.contractDutyObjectName.value?.objectAreas) &&
          contractDutyObject?.value?.contractDutyObjectName.value?.objectAreas?.length
            ? contractDutyObject.value?.contractDutyObjectName.value?.objectAreas.map(
                (objectArea): SupplementalCostSharingAObjectArea => {
                  return {
                    id: objectArea?.propertyId,
                    objectAreaNumber: objectArea?.objectAreaNumber || '',
                    objectAreaSquare: objectArea?.objectAreaSquare || '',
                  }
                },
              )
            : []

        return {
          id: contractDutyObject.propertyId,
          objectName: !!contractDutyObject?.value?.contractDutyObjectName?.value?.id
            ? {
                value: contractDutyObject?.value?.contractDutyObjectName?.value?.id || '',
                displayValue:
                  contractDutyObject?.value?.contractDutyObjectName?.value?.objectName || '',
              }
            : null,
          infrastructureType:
            contractDutyObject?.value?.contractDutyObjectName?.value?.infrastructureType?.name ||
            '',
          objectConstructionKind:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectConstructionKind || '',
          objectPurpose:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectPurpose || '',
          objectConstructionSquare:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectConstructionSquare ||
            '',
          objectSquare:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectSquare || '',
          objectNonLivingAreaSquare:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectNonLivingAreaSquare ||
            '',
          objectLivingAreaSquare:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectLivingAreaSquare || '',
          objectFloor: contractDutyObject?.value?.contractDutyObjectName?.value?.objectFloor || '',
          objectUndergroundFloor:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectUndergroundFloor || '',
          objectHeight:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectHeight || '',
          objectDetails:
            contractDutyObject?.value?.contractDutyObjectName?.value?.objectDetails || '',
          contractDutyObjectActivityType: !!contractDutyObject?.value
            ?.contractDutyObjectActivityType?.value?.id
            ? {
                value: contractDutyObject?.value?.contractDutyObjectActivityType?.value?.id || '',
                displayValue:
                  contractDutyObject?.value?.contractDutyObjectActivityType?.value?.name || '',
              }
            : null,
          contractDutyObjectActivityEndDate:
            contractDutyObject?.value?.contractDutyObjectActivityEndDate?.value || '',
          contractDutyObjectCost: contractDutyObject?.value?.contractDutyObjectCost?.value || '',
          contractDutyObjectInvestorCost:
            contractDutyObject?.value?.contractDutyObjectInvestorCost?.value || '',
          objectAreas: preparedObjectAreas,
        }
      },
    )
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '5': {
      contractDutyMembers: preparedContractDutyMembers,
    },
    '6': {
      contractDutyTerms:
        objectFromPropertyState?.dfoContractDuty?.value?.contractDutyTerms?.value || '',
      contractDutyNumber:
        objectFromPropertyState?.dfoContractDuty?.value?.contractDutyNumber?.value || '',
      contractDutyDate:
        objectFromPropertyState?.dfoContractDuty?.value?.contractDutyDate?.value || '',
      objectsInfrastructureInvestorCost:
        objectFromPropertyState?.objectsInfrastructureInvestorCost?.value || '',
    },
    '7': {
      contractDutyObjects: preparedContractDutyObjects,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  }
}

export { generateRHFForSupplementalCostSharingAForm }
