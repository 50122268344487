type EnumOfStageReports = 'ACCEPTED' | 'CHECKING' | 'EMPTY' | 'RETURNED'
type EnumOfTypeReports = 'investor' | 'region' | 'federal' | 'consolidated'

const mapOfTypeReports: Record<EnumOfTypeReports, EnumOfTypeReports> = {
  investor: 'investor',
  region: 'region',
  federal: 'federal',
  consolidated: 'consolidated',
}

interface IGetReportsParams {
  year?: string
  isFederal?: boolean
  stage?: EnumOfStageReports

  indicatorValue?: number

  type?: EnumOfTypeReports

  searchText?: string

  regions?: number[]
}

export interface IDocumentReportEntity {
  documentId: string
  documentName: string
  documentOrder: number
  documentExt: string
  documentSize: number
  versionId: number
}

interface IReportEntity {
  projectId: string
  projectName: string
  isTerminate: boolean
  isTerminateNextYear: boolean
  orgName: string
  indicatorValue?: number

  isFederal: boolean

  dfo: {
    dfoId: string
    dfoType: string

    stage: EnumOfStageReports
    dfoIsActual: boolean
    dfoVersion: number

    digitizingInProcess?:boolean

    documentSets: {
      documentSetId: string
      documentSetType: string

      documents: IDocumentReportEntity[] | null

      ownerOrganizationName?: string
    }[]
  }
}

type TGetReportsResponse = IReportEntity[]

export type {
  EnumOfStageReports,
  EnumOfTypeReports,
  IGetReportsParams,
  IReportEntity,
  TGetReportsResponse,
}

export { mapOfTypeReports }
