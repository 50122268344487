import React, { FC, MouseEvent, useState } from 'react'

import Button from '@components/Button'
import Section from '@components/Layout/Section'
import TechnicalWork from '@components/TechnicalWork'
import Typography from '@components/Typography'
import env from '@config'
import withBuildTypeProtect from '@hoc/withBuildTypeProtect'
import BussinessCase from '@images/BusinessCase.png'
import Gerb from '@images/Gerb.png'
import LogoImg from '@images/LogoImg.png'
import { useTechnicalWork } from '@services/TechnicalWork/TechnicalWork.entity'
import cn from 'classnames'

import styles from './MainSection.module.scss'

export interface IMainSection {
  handleGoToInvestorLK: () => void
  handleGoToCommissionerLK: (text: string) => (e: MouseEvent<HTMLButtonElement>) => void
  href?: string
}

const MainSection: FC<IMainSection> = ({
  handleGoToCommissionerLK,
  handleGoToInvestorLK,
  href,
}) => {
  const { startDate, endDate, isRunning, fallbackStatus, isSetup } = useTechnicalWork()

  const [showCase, setShowCase] = useState(true)
  const [showArms, setShowArms] = useState(false)

  const handleShowCase = () => {
    setShowCase(true)
    setShowArms(false)
  }

  const handleShowArms = () => {
    setShowArms(true)
    setShowCase(false)
  }

  return (
    <Section containerClassName={styles.sectionContainer}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.mainInfo}>
              <Typography variant="h5" color="secondary" className={styles.subtitle}>
                Государственная информационная система
              </Typography>
              <Typography variant="h1" className={styles.title}>
                Капиталовложения
              </Typography>
              <Typography variant="p" className={styles.description}>
                Цифровая экосистема сопровождения и поддержки реализации инвестиционных проектов
              </Typography>
              <div className={styles.buttons}>
                <div
                  className={cn({ 'cursor-not-allowed': isRunning })}
                  onMouseEnter={() => handleShowCase()}
                >
                  <Button
                    disabled={isRunning}
                    priority="primary-positive-gradient-green"
                    onClick={handleGoToInvestorLK}
                  >
                    Войти как инвестор
                  </Button>
                </div>
                <div
                  className={cn({ 'cursor-not-allowed': isRunning })}
                  onMouseEnter={() => handleShowArms()}
                >
                  <Button
                    disabled={isRunning}
                    priority="primary-positive-gradient-blue"
                    href={href}
                    target={
                      env.REACT_APP_GOVERNMENT_DOMAIN === window.location.origin ? '' : '_blank'
                    }
                    onClick={handleGoToCommissionerLK('')}
                  >
                    Войти как орган власти
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.additionalInfo}>
              <TechnicalWork.HomeBanner
                fallbackStatus={fallbackStatus}
                startDate={startDate}
                endDate={endDate}
                isRunning={isRunning}
                isSetup={isSetup}
              />
              {!fallbackStatus && !startDate && !endDate && (
                <Typography variant="p" color="secondary" className={styles.additional}>
                  Регистрация произойдет автоматически, потребуется только УКЭП
                </Typography>
              )}
            </div>
          </div>
          <div className={styles.right}>
            <div
              className={cn(styles.case, {
                [styles.showCase]: showCase,
              })}
            >
              <img src={BussinessCase} alt="bussiness case" />
            </div>
            <div
              className={cn(styles.arms, {
                [styles.showArms]: showArms,
              })}
            >
              <img src={Gerb} alt="arms" />
            </div>
            <div className={styles.mobileImg}>
              <img src={LogoImg} alt="spiral" />
            </div>
            <div className={styles.lkInfoBlock}>
              <Typography variant="p" className={styles.lkInfo}>
                Чтобы войти, используйте компьютер с&nbsp;подключённым носителем УКЭП
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default withBuildTypeProtect(MainSection)
