import React, { useCallback } from 'react'

import Portal from '@components/NewDesign/Portal'

const HeaderPortal = ({ children }) => {
  const node = document.querySelector('#headerPortal') || document.body

  const getPortalContainer = useCallback(() => {
    return node
  }, [node])

  return <Portal getPortalContainer={getPortalContainer}>{children}</Portal>
}

export default HeaderPortal
