import { FC, memo, useCallback } from 'react'

import WebFormCompletedStateController from '@components/Attachments/WebForm/WIthoutDocumentController/CompletedStateController'
import WebFormConvertedStateController from '@components/Attachments/WebForm/WIthoutDocumentController/ConvertedStateController'
import WebFormConvertingStateController from '@components/Attachments/WebForm/WIthoutDocumentController/ConvertingStateController'
import WebFormEditableStateController from '@components/Attachments/WebForm/WIthoutDocumentController/EditableStateController'
import WebFormMovingStateController from '@components/Attachments/WebForm/WIthoutDocumentController/MovingStateController'
import WebFormNewStateController from '@components/Attachments/WebForm/WIthoutDocumentController/NewStateController'
import WebFormNonDigitizableStateController from '@components/Attachments/WebForm/WIthoutDocumentController/NonDigitizableStateController'
import WebFormNotFoundStateController from '@components/Attachments/WebForm/WIthoutDocumentController/NotFoundStateController'
import { WebFormStateControllerProps } from '@components/Attachments/WebForm/WIthoutDocumentController/types'
import { documentsWebFormStatesMap } from '@services/Documents/documents.const'
import { IDocumentDictInfo, WebFormInfo } from '@services/Documents/documents.entity'
import { useSWRConfig } from 'swr'

interface WebFormWithoutDocumentControllerProps {
  disabled: boolean
  permissions: number
  projectId: string
  currentDfoId: string
  documentSetId: string
  documentInfoFromDict: IDocumentDictInfo
  onOpenForm?: (editMode?: boolean) => () => void
  formInfo?: WebFormInfo
}

const renderStateControllersMap = (props: WebFormStateControllerProps) => ({
  [documentsWebFormStatesMap.COMPLETED]: <WebFormCompletedStateController {...props} />,
  [documentsWebFormStatesMap.EDITABLE]: <WebFormEditableStateController {...props} />,
  [documentsWebFormStatesMap.CONVERTING]: <WebFormConvertingStateController {...props} />,
  [documentsWebFormStatesMap.CONVERTED]: <WebFormConvertedStateController {...props} />,
  [documentsWebFormStatesMap.MOVING]: <WebFormMovingStateController {...props} />,
  [documentsWebFormStatesMap.NEW]: <WebFormNewStateController {...props} />,
  [documentsWebFormStatesMap.NON_DIGITIZABLE]: <WebFormNonDigitizableStateController {...props} />,
  // Если formInfo или formInfo.state пустой, то попадает в этот компонент
  [documentsWebFormStatesMap.NOT_FOUND]: <WebFormNotFoundStateController {...props} />,
})

const WebFormWithoutDocumentController: FC<WebFormWithoutDocumentControllerProps> = ({
  disabled,
  projectId,
  documentSetId,
  currentDfoId,
  permissions,
  documentInfoFromDict,
  onOpenForm,
  formInfo,
}) => {
  const { state = documentsWebFormStatesMap.NOT_FOUND, id: formId } = formInfo || {}

  const { mutate } = useSWRConfig()

  const handleUpdateDocumentSets = useCallback(async () => {
    await mutate({
      _key: 'document-sets',
      projectId,
      dfoId: currentDfoId,
    })
  }, [currentDfoId, mutate, projectId])

  return (
    renderStateControllersMap({
      disabled,
      permissions,
      formId,
      documentSetId,
      documentInfoFromDict,
      onSuccess: handleUpdateDocumentSets,
      onOpenForm,
    })[state] || null
  )
}

export default memo(WebFormWithoutDocumentController)
