import React, { FC, KeyboardEvent, MouseEvent, SyntheticEvent, useCallback } from 'react'

import Icon from '@components/Icon/Icon'
import MultipleInput from '@components/NewDesign/MultipleInput'
import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import { PopoverProps } from '@components/NewDesign/Popover'
import { MultipleSelectProps, MultipleSelectState } from '@components/NewDesign/Select/model'
import { Options } from '@components/NewDesign/Select/Options'
import { useSelectState } from '@components/NewDesign/Select/useSelectState'
import arrowBackIcon from '@icons/hardware/keyboard_arrow_down.svg'
import arrowUpIcon from '@icons/hardware/keyboard_arrow_up.svg'
import cn from 'classnames'

import styles from '../SingleSelect.module.scss'


export const MultipleSelect: FC<MultipleSelectProps> = (props) => {
  const {
    onBlurForm,
    onChangeForm,
    error,
    popoverProps,
    inputProps,
    options: receivedOptions,
    defaultOptionsSelected,
    multipleClassName,
    withSearch,
    withContextSearch,
    withInput,
    staticWidth,
    defaultSelected = [],
    closeOptionsAfterOnChange,
    disabled,
    optionsProps,
    optionsContainer,
    onChangeSearchValue,
    chipProps,
    onAddValue,
    onRemoveChip,
    overrideDisplayValue,
  } = props
  const { labelClassName, ...restInputProps } = inputProps || {}

  const inputActive = !!(withInput || withSearch || withContextSearch)

  const {
    selected,
    setSelected,
    readyInputValues,
    allOptionsInArray,
    inputContainerRef,
    popoverRef,
    isPopoverOpen,
    reverseIsPopoverOpen,
    searchValue,
    setSearchValue,
    clue,
    availableOptions,
    inputRef,
    openPopover,
    valueIsLongerThenInput,
  } = useSelectState<MultipleSelectState>({
    defaultSelected,
    receivedOptions,
    withSearch,
    withContextSearch,
    defaultOptionsSelected,
    onBlur: onBlurForm,
    overrideDisplayValue,
    disableOptionsMutate: !!onChangeSearchValue,
  })

  const inputArrow = (
    <Icon src={isPopoverOpen ? arrowUpIcon : arrowBackIcon} className={styles.Arrow} />
  )

  const addValueHandler = (value: string) => {
    const prepearedCurrentAndNewValues = [...selected, value]
    setSelected(prepearedCurrentAndNewValues)
    onChangeForm?.(prepearedCurrentAndNewValues, value, selected)

    onAddValue?.()

    setSearchValue('')

    onChangeSearchValue?.('')
  }

  const removeValueHandler = useCallback(
    (value: string) => {
      setSelected((prev) => prev.filter((v) => v !== value))

      onChangeForm?.(
        selected.filter((v) => v !== value),
        value,
        selected,
      )
    },
    [onChangeForm, selected, setSelected],
  )

  const keyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const isTargetButtonPressed = ['Enter'].includes(e.key)

    if (isTargetButtonPressed && clue) {
      e.preventDefault()

      const valueToAdd = allOptionsInArray.find(
        (item) => item.displayValue.toLowerCase() === clue.toLowerCase(),
      )

      if (valueToAdd) addValueHandler(valueToAdd?.value)
    }
  }

  const onChangeInput = (e: SyntheticEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value

    if (currentValue) openPopover()

    setSearchValue(currentValue)

    onChangeSearchValue?.(currentValue)
  }

  const onClearSearch = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      inputProps?.onClear?.(e)

      setSearchValue('')

      onChangeSearchValue?.('')
    },
    [onChangeSearchValue, setSearchValue, inputProps],
  )

  const onClickOption = (value: string) => {
    if (selected.includes(value)) return removeValueHandler(value)
    addValueHandler(value)

    if (closeOptionsAfterOnChange) {
      reverseIsPopoverOpen()
    }
  }

  const staticPopoverProps = {
    ref: popoverRef,
    position: 'bottom-start' as PopoverProps['position'],
    open: isPopoverOpen,
    anchorElement: inputContainerRef.current,
    ...popoverProps,
  }

  const onMergedRemoveChip = useCallback(
    (displayValues: MultipleInputValue[], removedValue: MultipleInputValue) => {
      const currentList = displayValues.map((displayValue) => displayValue.value)

      onRemoveChip?.(currentList, removedValue.value)

      setSelected(currentList)
      onChangeForm?.(currentList, removedValue.value, selected)
    },
    [onChangeForm, onRemoveChip, selected, setSelected],
  )

  return (
    <>
      <MultipleInput
        manualValuesControl
        values={readyInputValues}
        staticWidth={staticWidth}
        error={error}
        ref={inputRef}
        labelClassName={labelClassName}
        className={cn(
          { [styles.Input__disabled]: disabled, [styles.Input__staticWidth]: staticWidth },
          multipleClassName,
        )}
        chipProps={{
          size: 'xs',
          variant: 'accent',
          priority: 'secondary',
          stopPropagation: true,
          ...chipProps,
        }}
        inputProps={{
          rootClassName: styles.Input,
          inputCursor: inputActive ? undefined : 'pointer',
          inputActive,
          disabled,
          onChange: onChangeInput,
          onClear: onClearSearch,
          rightAddons: !disabled && inputArrow,
          value: searchValue,
          inputContainerRef: inputContainerRef,
          view: 'secondary',
          inputClue: valueIsLongerThenInput ? '' : clue,
          onKeyDown: keyDownHandler,
          ...restInputProps,
          enableConditionToShowTooltip: false,
        }}
        onRemoveChip={onMergedRemoveChip}
      />
      <Options
        popoverProps={staticPopoverProps}
        selected={selected}
        options={availableOptions}
        onClick={onClickOption}
        {...optionsProps}
        optionsContainer={optionsContainer}
      />
    </>
  )
}
