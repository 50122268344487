import { FC, memo, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import AAgreementOnCreationOtherEstateObjectsForm from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm'
import { aAgreementOnCreationOtherEstateObjectsBlockValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import { getAAgreementOnCreationOtherEstateObjectsMenuByStatus } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Menu/const'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import AAgreementOnCreationOtherEstateObjectsLayoutWrapper from './Wrapper'

const AAgreementOnCreationOtherEstateObjectsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormContext<AAgreementOnCreationOtherEstateObjectsFormValues>()

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const isFederalSZPK = useWatch({
    name: aAgreementOnCreationOtherEstateObjectsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })
  const isMunicipalitiesRender = !!useWatch({
    name: aAgreementOnCreationOtherEstateObjectsBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  }).length

  const handleOnClose = useCallback(() => {
    AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  const initialMenuState = useMemo(() => {
    return getAAgreementOnCreationOtherEstateObjectsMenuByStatus(
      isFederalSZPK,
      isMunicipalitiesRender,
    )
  }, [isFederalSZPK, isMunicipalitiesRender])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <AAgreementOnCreationOtherEstateObjectsForm.MenuManager
        key={String(isFederalSZPK)}
        initialMenuHash={initialMenuState.initialAAgreementOnCreationOtherEstateObjectsMenuState}
        initialSectionId={
          initialMenuState.initialAAgreementOnCreationOtherEstateObjectsMenuState[0].id
        }
      >
        <AAgreementOnCreationOtherEstateObjectsForm.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
          onClose={handleOnClose}
        >
          <AAgreementOnCreationOtherEstateObjectsLayoutWrapper
            lastUpdateDraftTime={lastUpdateDraftTime}
          >
            <div className={styles.layout__title}>
              <Typography.Headline variant={'headlineH1'} align={'center'} as={'h1'}>
                Дополнительное соглашение о создании иных объектов недвижимости
              </Typography.Headline>
              <Typography.Body
                className={styles.layout__subtitle}
                variant={'bodyMRegular'}
                align={'center'}
                as={'h2'}
              >
                Внесите изменения в поля соглашения. На основании внесенных данных будет сформирован
                pdf файл дополнительного соглашения
              </Typography.Body>
              <AAgreementOnCreationOtherEstateObjectsForm.StatementForm
                className={styles.layout__form}
                initialMenu={initialMenuState.mapOfMenu}
              />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--l'])}>
              <AAgreementOnCreationOtherEstateObjectsForm.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </AAgreementOnCreationOtherEstateObjectsLayoutWrapper>
        </AAgreementOnCreationOtherEstateObjectsForm.Manager>
      </AAgreementOnCreationOtherEstateObjectsForm.MenuManager>
    </DocumentFormLayout>
  )
}

export default memo(AAgreementOnCreationOtherEstateObjectsLayout)
