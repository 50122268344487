import React, { SyntheticEvent } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import styles from '@components/DocumentFormComponents/FormStyles/Stages.module.scss'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useInitialCollapseStateList } from '@components/DocumentFormComponents/hooks/useInitialCollapseStateList'
import {
  useNestedMenuHandlersManager,
} from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import EventItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/EventItem'
import { SeventhEventsOfStagesPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import { isUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

const Seventh = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { handleChangeCurrentMenuItemId } = useNestedMenuHandlersManager()

  const { fields: formFields } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhEventsOfStagesPathName
  >({
    control: formInstance.control,
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
    name: projectSZPKBlockValues['7'].eventsOfStages,
  })

  const preparedStages = useWatch({
    name: projectSZPKBlockValues['6'].stages,
  })

  const { initialCollapseState } = useInitialCollapseStateList(formFields)

  const conditionToFormFieldsRender =
    !!preparedStages?.length && !!formFields.length && preparedStages?.length === formFields.length

  if (!formInstance) return null

  const handleMoveToSixSection = (e: SyntheticEvent) => {
    e.preventDefault()

    const sectionToMove = '3.1'

    handleChangeCurrentMenuItemId?.(sectionToMove)
  }

  return (
    <Container className={'p-0'}>
      <Row>
        <Col xs={12}>
          {!conditionToFormFieldsRender && (
            <Col xs={12} className={styles.stages__placeholder}>
              <Typography.Body variant={'bodyMRegular'}>
                Укажите планируемые этапы в разделе
                <button
                  type="button"
                  className={cn(
                    styles['stages__placeholder-text'],
                    styles['stages__placeholder-text--brand'],
                  )}
                  onClick={handleMoveToSixSection}
                >
                  &nbsp;«Этапы реализации проекта»&nbsp;
                </button>
                для того, чтобы добавить мероприятия
              </Typography.Body>
            </Col>
          )}
          <SubscribableControl
            {...getSubscribableControlProps({
              path: projectSZPKBlockValues['7'].eventsOfStages,
            })}
          >
            <FlipperList list={formFields} gap={1}>
              {conditionToFormFieldsRender &&
                formFields.map((field, index) => {
                  const currentInitState = !isUndefined(initialCollapseState[field.id])
                    ? initialCollapseState[field.id]
                    : true

                  return (
                    <SubscribableControl
                      key={field.id}
                      {...getSubscribableControlProps({
                        path: `${projectSZPKBlockValues['7'].eventsOfStages}.${index}`,
                      })}
                    >
                      <EventItem
                        id={field.id}
                        indexOfMainBlock={index}
                        initialAccordionState={currentInitState}
                        formInstance={formInstance}
                        formName={`${projectSZPKBlockValues['7'].eventsOfStages}.${index}` as const}
                      />
                    </SubscribableControl>
                  )
                })}
            </FlipperList>
          </SubscribableControl>
        </Col>
      </Row>
    </Container>
  )
}

export default Seventh
