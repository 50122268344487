import { FC, memo, useMemo } from 'react'
import type { Control, UseFormSetValue } from 'react-hook-form'

import Icon from '@components/Icon'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import TagItem from '@components/TagNew/Item'
import searchIcon from '@icons/action/search.svg'
import DropDownIcon from '@icons/navigation/arrow_drop_down.svg'
import PersonAddIcon from '@icons/PersonAddIcon.svg'
import cn from 'classnames'

import { filterUsersByActions, usersTabs } from '../constants'
import type { FilterActions, TFormUsers } from '../Users'

import styles from './Filters.module.scss'

interface FiltersProps {
  control: Control<TFormUsers, any>
  groupTagValue: 'participantsTable' | 'operatorsTable'
  filterActions: FilterActions
  handleAddParticipant: VoidFunction
  handleAddOperator: VoidFunction
  setValue: UseFormSetValue<TFormUsers>
}

const Filters: FC<FiltersProps> = ({
  control,
  groupTagValue,
  filterActions,
  handleAddParticipant,
  handleAddOperator,
  setValue,
}) => {
  const {
    state: { tooltipIsOpen: dropdownTooltipIsOpen },
    handlers: {
      handleOpenTooltip: handleDropdownOpenTooltip,
      handleCloseTooltip: handleDropDownCloseTooltip,
    },
  } = useManualTooltipControl()

  const {
    state: { tooltipIsOpen: personTooltipIsOpen },
    handlers: {
      handleOpenTooltip: handlePersonOpenTooltip,
      handleCloseTooltip: handlePersonCloseTooltip,
    },
  } = useManualTooltipControl()

  const handleSelectFilterAction = (key: string) => () => {
    if (key in filterUsersByActions) {
      setValue('filterActions', key as FilterActions)
    }

    handleDropDownCloseTooltip()
  }

  const dropdownTooltipContent = (
    <div className={styles.tooltip}>
      {(Object.keys(filterUsersByActions) ?? []).map((key) => (
        <button
          key={key}
          type="button"
          className={styles.tooltip__content}
          onClick={handleSelectFilterAction(key)}
        >
          <Typography
            variant="bodyMMedium"
            color="text-accent-brand-normal"
            className={cn({ [styles.active]: filterActions === key })}
          >
            {filterUsersByActions[key]}
          </Typography>
        </button>
      ))}
    </div>
  )

  const personTooltipContent = useMemo(
    () => (
      <div className={styles.tooltip}>
        <button type="button" className={styles.tooltip__content} onClick={handleAddParticipant}>
          <Typography variant="bodyMMedium" color="text-base-secondary">
            Участника
          </Typography>
        </button>
        <button type="button" className={styles.tooltip__content} onClick={handleAddOperator}>
          <Typography variant="bodyMMedium" color="text-base-secondary">
            Оператора
          </Typography>
        </button>
      </div>
    ),
    [handleAddOperator, handleAddParticipant],
  )

  return (
    <div className={styles.filters}>
      <ControlledRadioGroup
        radioGroupProps={{ type: 'tag', direction: 'horizontal' }}
        name="tagRadioGroup"
        control={control}
      >
        <TagItem value={usersTabs.participants} className={styles.tag}>
          Участники
        </TagItem>
        <TagItem value={usersTabs.operators} className={styles.tag}>
          Операторы
        </TagItem>
      </ControlledRadioGroup>
      <div className={styles.filters__rightGroup}>
        <Tooltip
          useAnchorWidth
          trigger="click"
          open={dropdownTooltipIsOpen}
          contentClassName={styles.filters__rightGroup__tooltipContent}
          popoverClassName={styles.filters__rightGroup__tooltipPopover}
          arrowClassName={styles.filters__rightGroup__tooltipArrow}
          position="bottom-end"
          content={dropdownTooltipContent}
          offset={[0, 10]}
          onClose={handleDropDownCloseTooltip}
        >
          <Button
            className={styles.dropdown}
            view="plain"
            size="l"
            color="default"
            geometry="square"
            trailingIcon={{
              src: DropDownIcon,
              className: cn(styles.button__icon, {
                [styles['button__icon--active']]: dropdownTooltipIsOpen,
              }),
            }}
            onClick={handleDropdownOpenTooltip}
          >
            Показывать: {filterUsersByActions[filterActions]?.toLowerCase()}
          </Button>
        </Tooltip>
        {groupTagValue === 'participantsTable' && (
          <ControlledInput
            name="searchString"
            control={control}
            inputProps={{
              controlClassName: styles.input__control,
              size: 'm',
              placeholder: 'Поиск',
              clear: true,
              view: 'secondary',
              leftAddons: <Icon src={searchIcon} size="s" className={styles.searchIcon} />,
            }}
          />
        )}
        <Tooltip
          useAnchorWidth
          trigger="click"
          open={personTooltipIsOpen}
          contentClassName={styles.filters__rightGroup__tooltipContent}
          popoverClassName={styles.filters__rightGroup__tooltipPopover}
          arrowClassName={styles.filters__rightGroup__tooltipArrow}
          position="bottom-end"
          content={personTooltipContent}
          offset={[0, 4]}
          onClose={handlePersonCloseTooltip}
        >
          <Button
            view="filled"
            size="l"
            color="default"
            className={styles.person}
            leadingIcon={{
              src: PersonAddIcon,
              className: styles.button__icon,
            }}
            onClick={handlePersonOpenTooltip}
          >
            Добавить
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default memo(Filters)
