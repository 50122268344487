import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'

interface NotificationFillingAppConcludingFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
}

const NotificationFillingAppConcludingForms: NotificationFillingAppConcludingFormsProps = () => null

NotificationFillingAppConcludingForms.First = First
NotificationFillingAppConcludingForms.Second = Second
NotificationFillingAppConcludingForms.Third = Third
NotificationFillingAppConcludingForms.Fourth = Fourth

export default NotificationFillingAppConcludingForms
