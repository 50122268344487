import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { ICurrentOrganizationUser } from '@services/Organization/organization.entity'
import useSWRImmutable from 'swr/immutable'

const useOrganizationUser = ({ key, config }: SWRHookProps<ICurrentOrganizationUser>) => {
  const {
    organizationApi: { getUserById },
  } = useAPIContext()

  const {
    data: organizationUser,
    error,
    mutate,
    isValidating: isLoadingOrganizationUser,
  } = useSWRImmutable(key, ({ id }) => getUserById(id), config)

  return {
    organizationUser,
    error,
    mutate,
    isLoadingOrganizationUser,
  }
}

export default useOrganizationUser
