import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { organizationUserDetailsFormNames } from '@components/Sidebars/OrganizationUserDetails/constants'
import { OrganizationUserDetailsFormValues } from '@components/Sidebars/OrganizationUserDetails/types'
import { defaultRHFValidation, lengthValidate, trimmedValueValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

interface OrganizationUserDetailsWithoutSignFormProps {
  formInstance: UseFormReturn<OrganizationUserDetailsFormValues>
}

const OrganizationUserDetailsWithoutSignForm: FC<OrganizationUserDetailsWithoutSignFormProps> = ({
  formInstance,
}) => {
  return (
    <Stack direction="vertical" gap={3}>
      <Row>
        <Col xs={12} className={'px-2.5'}>
          <Stack direction="vertical" gap={3}>
            <Row>
              <Col xs={12} className="p-0">
                <ControlledTextarea
                  name={organizationUserDetailsFormNames.position}
                  control={formInstance.control}
                  rules={{
                    required: defaultRHFValidation.required,
                    validate: {
                      positiveTrimmedValue: trimmedValueValidate,
                      positiveLength: (value) => isString(value) && lengthValidate(value, 500),
                    },
                  }}
                  textareaProps={{
                    fixWidth: true,
                    label: 'Должность',
                    size: 'xl',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Stack direction="vertical" gap={2}>
                <Col xs={12}>
                  <Typography.Body variant="bodyMMedium" color="on-surface-secondary">
                    Без права подписи
                  </Typography.Body>
                </Col>
                <Col xs={12}>
                  <Typography.Caption variant="captionSRegular" color="on-surface-secondary">
                    Чтобы совершать юридически значимые действия, пользователь должен быть
                    зарегистрирован с УКЭП лица, имеющего право действовать без доверенности, или по
                    машиночитаемой доверенности с правом подписи
                  </Typography.Caption>
                </Col>
              </Stack>
            </Row>
          </Stack>
        </Col>
      </Row>
    </Stack>
  )
}

export default OrganizationUserDetailsWithoutSignForm
