import React from 'react'
import { useFormContext } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import { tenthSectionRegistrationCertificateValidationMap } from '@components/Forms/RegistrationCertificateForm/Forms/10/validation'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Tenth = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const formModifierInstance = useFormModifierContext<RegistrationCertificateFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue, handleChangePublicValue },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { getFormFieldControlProps, getTextareaProps } = useFormComponentPresets({
    isNew: true,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['10'].infoBudgetRF,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['10'].infoBudgetRF,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['10'].infoBudgetRF,
                  rules: tenthSectionRegistrationCertificateValidationMap.infoBudgetRF,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label:
                      'Сведения об обязательствах бюджетов бюджетной системы РФ, принятых в соответствии с бюджетным законодательством РФ, в отношении организаций',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(registrationCertificateBlockValues['10'].infoBudgetRF)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['10'].infoBudgetRF,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['10'].infoDamageFormatCompensation,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['10'].infoDamageFormatCompensation,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['10'].infoDamageFormatCompensation,
                  rules:
                    tenthSectionRegistrationCertificateValidationMap.infoDamageFormatCompensation,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label:
                      'Сведения о размере реального ущерба, а также убытков (в предусмотренных Федеральным законом "О защите и поощрении капиталовложений в Российской Федерации" случаях), подлежащих возмещению по соглашению',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        registrationCertificateBlockValues['10'].infoDamageFormatCompensation,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['10'].infoDamageFormatCompensation,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['10'].infoViolationStabilizationPeriod,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['10'].infoViolationStabilizationPeriod,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['10'].infoViolationStabilizationPeriod,
                  rules:
                    tenthSectionRegistrationCertificateValidationMap.infoViolationStabilizationPeriod,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label:
                      'Сведения о полученной от организации информации о нарушении стабилизационной оговорки',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        registrationCertificateBlockValues['10'].infoViolationStabilizationPeriod,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['10'].infoViolationStabilizationPeriod,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['10'].infoPartner,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['10'].infoPartner,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['10'].infoPartner,
                  rules: tenthSectionRegistrationCertificateValidationMap.infoPartner,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label:
                      'Сведения о правопреемнике организации, в том числе наименование юридического лица, адрес, ИНН, ОГРН',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(registrationCertificateBlockValues['10'].infoPartner)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['10'].infoPartner,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['10'].infoDeposit,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['10'].infoDeposit,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['10'].infoDeposit,
                  rules: tenthSectionRegistrationCertificateValidationMap.infoDeposit,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label: 'Сведения о передаче в залог денежных требований по соглашению',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(registrationCertificateBlockValues['10'].infoDeposit)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['10'].infoDeposit,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['10'].infoCession,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['10'].infoCession,
                      value,
                    ),
                },
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: registrationCertificateBlockValues['10'].infoCession,
                  rules: tenthSectionRegistrationCertificateValidationMap.infoCession,
                  textareaProps: {
                    autosize: true,
                    minRows: 1,
                    maxRows: Infinity,
                    label: 'Сведения об уступке прав требований по соглашению',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(registrationCertificateBlockValues['10'].infoCession)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        registrationCertificateBlockValues['10'].infoCession,
                      )
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Tenth
