import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { Paths } from '@constants/paths'

//Компонент для обратной совместимости со старым урлом 'Statements', который был у ОГВ
const StatementRedirectRouter = () => {
  const { '*': statementId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`${Paths.Projects}${statementId ? `/${statementId}` : ''}`, { replace: true })
  }, [navigate, statementId])

  return null
}

export default StatementRedirectRouter
