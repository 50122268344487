import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFindFormValueDifferences } from '@components/DocumentFormComponents/hooks/useFindFormValueDifferences'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import {
  FormModalContextProps,
  FormModalManagerProps,
} from '@components/DocumentFormComponents/types'
import { useParametersOfCostRecoveryApplicationAdapters } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/adapters'
import { getRHFValuesWithPreparedExpenseSubTypesNotExist } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/helpers/getRHFValuesWithPreparedExpenseSubTypesNotExist'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import { useAPIContext } from '@context/APIContext'
import { isNull } from '@helpers/checkTypes'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'

const { getDefaultFormModalContextValue } = DocumentFormHelpers

export const ParametersOfCostRecoveryApplicationModalContext = createContext<
  FormModalContextProps<ParametersOfCostRecoveryApplicationFormValues>
>(getDefaultFormModalContextValue<ParametersOfCostRecoveryApplicationFormValues>())

const useParametersOfCostRecoveryApplicationManager = () => {
  return useContext(ParametersOfCostRecoveryApplicationModalContext)
}

const ParametersOfCostRecoveryApplicationModalManager: FC<FormModalManagerProps> = ({
  projectId,
  formId,
  editMode,
  rolesOfUser,
  initialErrorsFromViolations,
  onSetLastUpdateFormToNow,
  onClose,
  children,
}) => {
  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  const { updateLastRFHBeforeValue, getLastRHFBeforeValue } =
    useFormPreviousValue<ParametersOfCostRecoveryApplicationFormValues>()

  const formInstance = useFormContext<ParametersOfCostRecoveryApplicationFormValues>()
  const { setValue, reset: resetForm, clearErrors } = formInstance

  const { processOverrideProps, getOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const { formIsLoading, handleMountForm } = useFormMount(formId)

  const { generatePropertiesObject, generatePrefetchedPropsArray, generateRHFObject } =
    useParametersOfCostRecoveryApplicationAdapters()

  const { getBasePropsFromObjectAdapter, getAdvancedPropsFromObjectAdapter } =
    useGenerateValuesFromObjectAdapter({
      generateRHFObject,
      generatePropertiesObject,
      generatePrefetchedPropsArray,
    })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
    rolesOfUser,
  })

  const { handleNotifyAllSubscribersWithAdvancedProps } = useNotifyAllSubscribers({
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const { applyDifferences } = useFindFormValueDifferences({
    setValue,
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const {
    referenceBooksApi: { getReferenceBooks },
  } = useAPIContext()

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()

      if (isNull(prevRHFValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const readyOverrideProps = await processOverrideProps(props.overridePropsFromChanges)

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      const RHFAwaitedValueForReset = await getRHFValuesWithPreparedExpenseSubTypesNotExist(
        props.objectForAdapter,
        RHFValueForReset,
        getReferenceBooks,
      )

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      unstable_batchedUpdates(() => {
        // временное решение, чтобы работало удаление в
        // compensationStatementExpenseSubTypes[] -> compensationObjects[] -> compensationObjectExpenses[]
        setTimeout(() => {
          applyDifferences(prevRHFValue, RHFAwaitedValueForReset)
        }, 0)

        updateLastRFHBeforeValue(RHFAwaitedValueForReset)

        setTimeout(() => {
          ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher.notifyAllSubscribers(
            readyOverrideProps,
          )
        }, 0)

        onSetLastUpdateFormToNow?.()
      })
    },
    [
      propertiesPropsRef,
      lastFieldUpdateTime,
      applyDifferences,
      getBasePropsFromObjectAdapter,
      getLastRHFBeforeValue,
      getReferenceBooks,
      onSetLastUpdateFormToNow,
      updateLastRFHBeforeValue,
      processOverrideProps,
    ],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const wrappedHandleApplyFormChanges = useMemo(
    () => applyFormChanges(handleApplyChanges),
    [applyFormChanges, handleApplyChanges],
  )

  const {
    handleAddItemToListWithValue,
    handleAddMultipleItemsToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleChangeValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    debouncedHandleChangeValue,
  } = useFormUpdate({
    formId,
    getPropertiesProps,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: wrappedHandleApplyFormChanges,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const { handleCheckForm, handlePreviewForm } = useFormActions({
    formId,
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    handleApplyChanges: checkFormChanges,
    onClearError: clearErrors,
  })

  const handleSuccessInitializeForm = useCallback(
    async ({ lastChangesDt, propertyInstance }: SuccessInitialCallbackReturn) => {
      lastFieldUpdateTime.current = lastChangesDt

      propertyInstanceRef.current = propertyInstance

      const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(objectForAdapters)

      const RHFAwaitedValueForReset = await getRHFValuesWithPreparedExpenseSubTypesNotExist(
        objectForAdapters,
        RHFValueForReset,
        getReferenceBooks,
      )
      //Установка propertiesProps
      propertiesPropsRef.current = cloneDeep(propertiesProps)

      resetForm(RHFAwaitedValueForReset)
      updateLastRFHBeforeValue(RHFAwaitedValueForReset)
    },
    [
      propertyInstanceRef,
      propertiesPropsRef,
      lastFieldUpdateTime,
      resetForm,
      getBasePropsFromObjectAdapter,
      updateLastRFHBeforeValue,
      getReferenceBooks,
    ],
  )

  const handleAfterInitializeForm = useMemo(
    () => handleNotifyAllSubscribersWithAdvancedProps(getAdvancedPropsFromObjectAdapter),
    [getAdvancedPropsFromObjectAdapter, handleNotifyAllSubscribersWithAdvancedProps],
  )

  //Инициализация формы
  useEffect(() => {
    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleAfterInitializeForm,
    })
  }, [])

  const preparedValue: FormModalContextProps<ParametersOfCostRecoveryApplicationFormValues> =
    useMemo(
      () => ({
        state: {
          formIsLoading,
          blockViewIsValidating,
          initialErrorsFromViolations,
          editMode,
        },
        handlers: {
          checkFormChanges,
          getPropertiesProps,
          getOverrideProps,
          handleChangeValue,
          handleAddCustomValue,
          handleRemoveCustomValue,
          debouncedHandleChangeValue,
          handleAddItemToListWithOutValue,
          handleAddMultipleItemsToListWithValue,
          handleAddItemToListWithValue,
          handleRemoveItemFromList,
          applyFormChanges: wrappedHandleApplyFormChanges,
          handleChangeBlockValidation,
        },
        actions: {
          handleFormClose: onClose,
          handleCheckForm,
          handlePreviewForm,
        },
        preparedProps: {
          subscribableControl,
        },
      }),
      [
        formIsLoading,
        blockViewIsValidating,
        initialErrorsFromViolations,
        editMode,
        checkFormChanges,
        getPropertiesProps,
        getOverrideProps,
        handleChangeValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        handleAddMultipleItemsToListWithValue,
        debouncedHandleChangeValue,
        handleAddItemToListWithOutValue,
        handleAddItemToListWithValue,
        handleRemoveItemFromList,
        wrappedHandleApplyFormChanges,
        handleChangeBlockValidation,
        onClose,
        handleCheckForm,
        handlePreviewForm,
        subscribableControl,
      ],
    )

  return (
    <ParametersOfCostRecoveryApplicationModalContext.Provider value={preparedValue}>
      {children}
    </ParametersOfCostRecoveryApplicationModalContext.Provider>
  )
}

export { useParametersOfCostRecoveryApplicationManager }

export default ParametersOfCostRecoveryApplicationModalManager
