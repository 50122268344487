import React, { FC, useMemo } from 'react'

import Button from '@components/NewDesign/Button'
import { isSelectOption } from '@components/NewDesign/Select/helpers'
import { OptionProps } from '@components/NewDesign/Select/model'
import Typography from '@components/NewDesign/Typography'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'
import { isBoolean, isEmptyString, isNull, isUndefined } from '@helpers/checkTypes'
import { ValueOf } from '@helpers/ValueOf'

import { formActionTypes } from './const'
import styles from './TooltipContentWithValue.module.scss'

const currentTitleMap = {
  [formActionTypes.RETURN]: 'Исходное значение:',
}

const currentButtonTextMap = {
  [formActionTypes.RETURN]: 'Вернуть',
}

interface TooltipContentWithValueProps {
  value: string | boolean | OptionProps | null
  type?: ValueOf<typeof formActionTypes>
  onActionClick?: (
    value: string | boolean | OptionProps | null,
    type: ValueOf<typeof formActionTypes>,
  ) => void
}

const TooltipContentWithValue: FC<TooltipContentWithValueProps> = ({
  type = formActionTypes.RETURN,
  value,
  onActionClick,
}) => {
  const editMode = !isUndefined(onActionClick)

  const handleActionClick = () => {
    onActionClick?.(value, type)
  }

  const displayValue: string = useMemo(() => {
    if (isNull(value) || isEmptyString(value)) {
      return 'Не заполнено'
    }

    if (isBoolean(value)) {
      return value ? 'Включен' : 'Выключен'
    }

    if (isSelectOption(value)) {
      return value?.displayValue || ''
    }

    return value
  }, [value])

  return (
    <Container className="p-0">
      <Stack gap={1} direction="vertical">
        <div>
          <Typography.Body
            variant="bodyMRegular"
            color="text-base-tertiary"
            className={styles['tooltip__content-text']}
          >
            {currentTitleMap[type]}
          </Typography.Body>
          <Typography.Body
            variant="bodyMRegular"
            color="on-surface-primary"
            className={styles['tooltip__content-text']}
          >
            {displayValue}
          </Typography.Body>
        </div>
        {editMode && (
          <Button
            className={styles['tooltip__content-button']}
            textClassName={styles['tooltip__content-buttonText']}
            size="xs"
            view="text"
            variant="buttonSMedium"
            onClick={handleActionClick}
          >
            {currentButtonTextMap[type]}
          </Button>
        )}
      </Stack>
    </Container>
  )
}

export default TooltipContentWithValue
