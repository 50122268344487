import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import cn from 'classnames'

import styles from './Actions.module.scss'

export type DefaultActionProps = ButtonProps & {
  bindOnClose?: boolean
}

export interface BaseActionsModal {
  actions: DefaultActionProps[]
  onClose: VoidFunction
  actionsClassName?: string
}

export type BaseModalActionsProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  BaseActionsModal

const BaseModalActions: FC<BaseModalActionsProps> = ({ actions, onClose, ...divProps }) => {
  return (
    <div {...divProps} className={cn(styles.actions, divProps.className)}>
      {actions.map((actionProps, index) => {
        if (!actionProps) return

        const handleActionClick = (e) => {
          actionProps.onClick?.(e)
          if (actionProps.bindOnClose && onClose) onClose()
        }

        return (
          <Button
            key={index}
            {...actionProps}
            className={cn(styles['actions-item'], actionProps.className)}
            onClick={handleActionClick}
          />
        )
      })}
    </div>
  )
}

export default BaseModalActions
