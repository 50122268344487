import { FC } from 'react'
import { Path, useFormContext, useWatch } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { financialModelBlockValues } from '@components/Forms/FinancialModelForm/const'
import { stageTaxYearsFinancialModelValidationMap } from '@components/Forms/FinancialModelForm/Forms/4/Forms/StageTaxYears/validation'
import { useFinancialModelManager } from '@components/Forms/FinancialModelForm/Manager'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { FinancialModelFieldsControlUpdateWatcher } from '@components/Forms/FinancialModelForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

interface StageTaxYearProps {
  indexOfStage: number
}

const StageTaxYear: FC<StageTaxYearProps> = ({ indexOfStage }) => {
  const formInstance = useFormContext<FinancialModelFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { debouncedHandleChangeValue, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useFinancialModelManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: FinancialModelFieldsControlUpdateWatcher,
  })

  const formName = `${financialModelBlockValues['4'].stageTaxYears}.${indexOfStage}`

  const stageTaxYear = useWatch({
    name: `${formName}.stageTaxYear` as Path<FinancialModelFormValues>,
    control: formInstance.control,
  })

  const preparedStageTaxYear = isDateValidForDayjs(stageTaxYear)
    ? DayjsService.dayjs(stageTaxYear, objOfDateFormats.defaultFormat).format(
        objOfDateFormats.yearFormat.yearOnly,
      )
    : stageTaxYear

  return (
    <RowWithBorder
      disableTitleLeftPadding
      disableBottomDefaultStyles
      title={preparedStageTaxYear}
    >
      <SubscribableControl
        {...getSubscribableControlProps({
          path: `${formName}.stageTaxYearValue` as Path<FinancialModelFormValues>,
        })}
      >
        <ControlledAmountInput
          {...getAmountInputProps({
            name: `${formName}.stageTaxYearValue` as Path<FinancialModelFormValues>,
            rules: stageTaxYearsFinancialModelValidationMap.stageTaxYearValue,
            inputProps: {
              hasNegativeValue: true,
              hideCurrency: true,
              label: 'Объем за год',
            },
            onBlur: () =>
              setTimeout(() => {
                handleChangeValue?.(
                  `${formName}.stageTaxYearValue` as Path<FinancialModelFormValues>,
                  'stageTaxYear',
                )
              }, 0),
            onChange: () =>
              setTimeout(() => {
                debouncedHandleChangeValue?.(
                  `${formName}.stageTaxYearValue` as Path<FinancialModelFormValues>,
                  'stageTaxYear',
                )
              }, 0),
          })}
        />
      </SubscribableControl>
    </RowWithBorder>
  )
}

export default StageTaxYear
