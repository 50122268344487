import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { INpaItemView } from '@services/NPA/NPA.entity'
import useSWRImmutable from 'swr/immutable'

const useNpaById = ({ key, config }: SWRHookProps<INpaItemView | undefined>) => {
  const {
    npaApi: { getNpaById },
  } = useAPIContext()

  const {
    data: npa,
    error,
    mutate,
    isValidating: isNpaLoading,
  } = useSWRImmutable(key, ({ npaId }) => getNpaById(npaId), config)

  return {
    npa,
    error,
    mutate,
    isNpaLoading,
  }
}

export { useNpaById }
