import { FC, memo, useCallback, useMemo } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { useLoadingModal } from '@components/NewDesignedModals/LoadingModal/manager'
import { useAPIContext } from '@context/APIContext'
import type { DefaultModalProps } from '@globalTypes/base'
import { isAxiosError } from '@helpers/checkTypes'
import { useDfoAvailableForCreate } from '@hooks/new/swr/useDfoAvailableForCreate'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useProjectActions } from '@hooks/new/swr/useUiActions'
import { useQueryManager } from '@hooks/useQueryManager'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { AxiosError } from 'axios'

import styles from './DeleteDfoModal.module.scss'

interface DeleteProjectDfoProps {
  projectId: string
  dfoInfo: {
    name: string
    id: string
    redirectId: string
  }
  onSuccessDeleteDfo: () => void
  onErrorDeleteDfo: (e: AxiosError<unknown>) => void
}

export type DeleteProjectModalProps = Required<PopupProps> & DeleteProjectDfoProps

const useDeleteDfoModal = () => {
  const manager = usePopupManager()

  const handleOpenDeleteDfoModal = ({
    dfoInfo,
    projectId,
    onSuccessDeleteDfo,
    onErrorDeleteDfo,
    onClose,
  }: DeleteProjectDfoProps & DefaultModalProps) => {
    if (!projectId || !dfoInfo) return

    manager.open<DeleteProjectModalProps>(DeleteDfoModal, {
      dfoInfo,
      projectId,
      onSuccessDeleteDfo,
      onErrorDeleteDfo,
      onClose: onClose ?? (() => null),
    })
  }

  return {
    handleOpenDeleteDfoModal,
  }
}

const DeleteDfoModal: FC<DeleteProjectModalProps> = ({
  isOpen,
  projectId,
  dfoInfo,
  onSuccessDeleteDfo,
  onErrorDeleteDfo,
  onClose,
}) => {
  const {
    dfosApi: { deleteProjectDfoById },
  } = useAPIContext()

  const popupManager = usePopupManager()

  const { queryUtils } = useQueryManager()
  const { handleOpenLoadingModal } = useLoadingModal()

  const { mutate: revalidateDfos } = useProjectDfos({
    key: {
      projectId,
      _key: 'projectDfos',
    },
  })

  const { mutate: revalidateProjectActions } = useProjectActions({
    key: {
      projectId,
      _key: 'projectActions',
    },
  })

  const { mutate: revalidateAvailableForCreate } = useDfoAvailableForCreate({
    key: {
      projectId,
      _key: 'availableDfoForCreate',
    },
    config: {
      isPaused: () => !projectId,
    },
  })

  const handleDeleteDfo = useCallback(async () => {
    popupManager.closeAll()

    handleOpenLoadingModal()

    try {
      await deleteProjectDfoById({ projectId, dfoId: dfoInfo.id })

      queryUtils.addQuery({
        query: {
          dfoId: dfoInfo.redirectId,
        },
      })

      onSuccessDeleteDfo()

      try {
        await revalidateDfos()
        await revalidateAvailableForCreate()
        await revalidateProjectActions()
      } catch {}
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          dfoInfo,
        },
        componentInfo: {
          componentName: 'DeleteDfoModal',
          moduleName: 'useDocumentActions',
          componentType: 'deleteDfo',
        },
      })(error)

      if (!isAxiosError(error)) throw error

      onErrorDeleteDfo(error)
    } finally {
      popupManager.closeAll()
    }
  }, [
    deleteProjectDfoById,
    dfoInfo.id,
    dfoInfo.redirectId,
    handleOpenLoadingModal,
    onErrorDeleteDfo,
    onSuccessDeleteDfo,
    popupManager,
    projectId,
    queryUtils,
    revalidateAvailableForCreate,
    revalidateDfos,
    revalidateProjectActions,
  ])

  const actions = useMemo(() => {
    return [
      {
        view: 'gray',
        dataTestId: 'DeleteDfoModal-cancel-button',
        children: 'Отмена',
        onClick: onClose,
      },
      {
        dataTestId: 'DeleteDfoModal-deleteDfo-button',
        children: 'Удалить',
        color: 'negative',
        onClick: handleDeleteDfo,
      },
    ] as ButtonProps[]
  }, [handleDeleteDfo, onClose])

  return (
    <Modal.Action
      isOpen={isOpen}
      title={`Удалить ${dfoInfo.name}\u00A0?`}
      actions={actions}
      dataTestId="DeleteDfoModal-modal"
      closeButtonDataTestId="DeleteDfoModal-modal-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      actionModalContentClassName={styles.modalAction}
      onClose={onClose}
    >
      <Typography.Body variant="bodyMRegular" className={styles.body}>
        Все добавленные документы также будут удалены. Вы действительно хотите удалить{' '}
        {dfoInfo.name}?
      </Typography.Body>
    </Modal.Action>
  )
}

export { useDeleteDfoModal }
export default memo(DeleteDfoModal)
