import { FC, memo, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { MapOfMenu } from '@components/DocumentFormComponents/types'
import SupplementalCostSharingA from '@components/Forms/SupplementalCostSharingAForm'
import {
  mapOfSupplementalCostSharingAMenu,
  supplementalCostSharingABlockValues,
} from '@components/Forms/SupplementalCostSharingAForm/const'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import { SupplementalCostSharingAFieldsControlUpdateWatcher } from '@components/Forms/SupplementalCostSharingAForm/watcher'
import Typography from '@components/NewDesign/Typography'

import SupplementalCostSharingALayoutWrapper from './Wrapper'

const SupplementalCostSharingALayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormContext<SupplementalCostSharingAFormValues>()

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const isFederalSZPK = useWatch({
    name: supplementalCostSharingABlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })

  const isMunicipalitiesRender = !!useWatch({
    name: supplementalCostSharingABlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const currentMenu: MapOfMenu[] = useMemo(() => {
    return [...mapOfSupplementalCostSharingAMenu].reduce<MapOfMenu[]>(
      (previousValue, currentValue) => {
        if (!isFederalSZPK && currentValue.id === '1') return previousValue

        if (!isMunicipalitiesRender && currentValue.id === '3') return previousValue

        return [...previousValue, currentValue]
      },
      [],
    )
  }, [isFederalSZPK, isMunicipalitiesRender])

  const handleOnClose = useCallback(() => {
    SupplementalCostSharingAFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <SupplementalCostSharingA.Manager
        formId={formId}
        projectId={projectId}
        initialErrorsFromViolations={initialErrorsFromViolations}
        editMode={editMode}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
        onClose={handleOnClose}
      >
        <FlatMenuManager key={String(isFederalSZPK)} initialCurrentAnchorId={currentMenu[0].id}>
          <SupplementalCostSharingALayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Дополнительное соглашение о распределении затрат
              </Typography.Headline>
              <SupplementalCostSharingA.StatementForm
                initialMenu={currentMenu}
                className={styles.layout__form}
              />
            </div>
            <div className={styles['layout__menu-wrapper']}>
              <SupplementalCostSharingA.Menu
                initialMenu={currentMenu}
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </SupplementalCostSharingALayoutWrapper>
        </FlatMenuManager>
      </SupplementalCostSharingA.Manager>
    </DocumentFormLayout>
  )
}

export default memo(SupplementalCostSharingALayout)
