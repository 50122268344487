import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectArea,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { bigIntIsValidate, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const objectAreaValidationMap: FormValuesValidationSection<
  keyof AAgreementOnChangingCharacteristicsOfObjectArea,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues
> = {
  objectAreaNumber: {
    validate: (value) => (isString(value) ? lengthValidate(value, 100) : undefined),
  },
  objectAreaSquare: {
    validate: bigIntIsValidate,
  },
}

export { objectAreaValidationMap }
