import { Urls } from '@constants/urls'
import {
  GetProcurationItemProps,
  GetProcurationItemReturnType,
  ProcurationItem,
} from '@context/APIContext/hooks/useProcurationsApi/types'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'

const useProcurationsApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const getProcurations = async ({
    userId,
    isValid,
    authorities,
  }: GetProcurationItemProps): Promise<GetProcurationItemReturnType> => {
    try {
      const { data } = await FetcherWithInterceptors.get<ProcurationItem[]>({
        url: Urls.Procurations,
        config: {
          params: {
            userId,
            isValid,
            authorities,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    getProcurations,
  }
}

export { useProcurationsApi }
