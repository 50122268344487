import { FC, useEffect } from 'react'
import { Outlet } from 'react-router'

import { useFolderStateManager } from '@services/FolderState/FolderState.manager'
import { FolderStateService } from '@services/FolderState/FolderState.service'

const InitializeLC: FC = () => {
  const LCFolderState = FolderStateService.getFolderStateFromLC()
  const folderStateManager = useFolderStateManager()

  //Инициализация folderStateStore из LocalStorage
  useEffect(() => {
    if (LCFolderState) {
      folderStateManager.replaceFoldersState(LCFolderState)
    }
  }, [])

  return <Outlet />
}

export default InitializeLC
