import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import universalStyles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import NoDataFieldFallback from '@components/DocumentFormComponents/NoDataFieldFallback'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { CompensationArrayFactObjectPathName } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Forms/3/types'
import { useParametersApplicationReimbursementExpensesManager } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Manager'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import { ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/watcher'
import Button from '@components/NewDesign/Button'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString, isNullOrUndefined, isObject, isString } from '@helpers/checkTypes'
import DeleteIcon from '@icons/DeleteIcon.svg'

interface CompensationFactObjectProps {
  id: string
  formInstance: UseFormReturn<ParametersApplicationReimbursementExpensesFormValues>
  name: CompensationArrayFactObjectPathName
  compensationFactObjectIndex: number
  onRemoveCompensationFactObject: (event?: unknown) => Promise<unknown>
}

const CompensationFactObject: FC<CompensationFactObjectProps> = ({
  id,
  formInstance,
  name,
  compensationFactObjectIndex,
  onRemoveCompensationFactObject,
}) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useParametersApplicationReimbursementExpensesManager()

  const { getSubscribableControlProps, getTextareaProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher,
    })

  const compensationFactObject = useWatch({
    control: formInstance.control,
    name: `${name}.compensationFactObject`,
  })
  const compensationFactObjectOther = useWatch({
    control: formInstance.control,
    name: `${name}.compensationFactObjectOther`,
  })

  const compensationFactObjectOtherIsNotEmpty =
    isString(compensationFactObjectOther) && !!compensationFactObjectOther.trim().length

  const compensationFactObjectIsNotEmpty =
    !isNullOrUndefined(compensationFactObject) && isObject(compensationFactObject)

  return (
    <FlippedWithCollapse flipId={id}>
      <div className={universalStyles.objectItem}>
        <div className={universalStyles.objectItem__header}>
          <Row>
            <Col xs={4}>
              <ObjectStepWrapperHeaderNode
                buttonProps={{
                  trailingIcon: null,
                }}
              >
                Объект №{compensationFactObjectIndex + 1}
              </ObjectStepWrapperHeaderNode>
            </Col>
          </Row>

          {editMode && (
            <AsyncWrapper promise={onRemoveCompensationFactObject}>
              {({ isLoading, wrappedPromise }) => (
                <Button
                  disabled={isLoading}
                  variant={'buttonSMedium'}
                  size={'2xs'}
                  color={'negative'}
                  dataTestId={'deleteButton'}
                  view={'plain'}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  leadingIcon={{
                    noCurrentColorSvgFill: true,
                    src: DeleteIcon,
                  }}
                  onClick={wrappedPromise}
                >
                  Удалить
                </Button>
              )}
            </AsyncWrapper>
          )}
        </div>
        <div className={universalStyles.objectItem__content}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationFactObject`,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormSingleSelect
                        {...getSingleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            value: item.id,
                            displayValue: item.objectName || item.ridName || item.otherActivityName,
                          }),
                          controllerProps: {
                            name: `${name}.compensationFactObject`,
                          },
                          selectProps: {
                            disabled: !editMode || compensationFactObjectOtherIsNotEmpty,
                            inputProps: {
                              label: 'Объект',
                              leftAddons: compensationFactObjectOtherIsNotEmpty && (
                                <FormIconWithTooltip
                                  tooltipContent={
                                    'Вносится информация об объекте из СЗПК или о тех объектах, которые не были указаны в СЗПК'
                                  }
                                />
                              ),
                            },
                            onChangeFormValue: () =>
                              setTimeout(() => {
                                handleChangeValue?.(`${name}.compensationFactObject`)
                              }, 0),
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
            </Row>
            <Row className={'px-2'}>
              <Col xs={12}>
                <NoDataFieldFallback className={'px-1'} text={'или'} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationFactObjectOther`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${name}.compensationFactObjectOther`,
                      rules: {
                        validate: (value) =>
                          isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined,
                      },
                      textareaProps: {
                        disabled: !editMode || compensationFactObjectIsNotEmpty,
                        label: 'Иные объекты, не указанные в тексте соглашения',
                        leftAddons: compensationFactObjectIsNotEmpty && (
                          <FormIconWithTooltip
                            tooltipContent={
                              'Вносится информация об объекте из СЗПК или о тех объектах, которые не были указаны в СЗПК'
                            }
                          />
                        ),
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.compensationFactObjectOther`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.compensationFactObjectOther`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </div>
      </div>
    </FlippedWithCollapse>
  )
}

export default CompensationFactObject
