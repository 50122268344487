import { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { parametersApplicationReimbursementExpensesBlockValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/const'
import CompensationFact from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Forms/3/AdditionalForms/CompensationFact'
import { useParametersApplicationReimbursementExpensesManager } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Manager'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import {
  ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersApplicationReimbursementExpensesForm/watcher'

interface SubscribableCompensationFactProps {
  id: string
  anchorId: string
  indexOfFact: number
  initialAccordionState?: boolean
  initialAccordionMountState?: boolean
  onRemove?: VoidFunction
}

const SubscribableCompensationFact: FC<SubscribableCompensationFactProps> = ({
  id,
  anchorId,
  indexOfFact,
  initialAccordionState,
  initialAccordionMountState,
  onRemove,
}) => {
  const formInstance = useFormContext<ParametersApplicationReimbursementExpensesFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useParametersApplicationReimbursementExpensesManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher,
  })

  const formName =
    `${parametersApplicationReimbursementExpensesBlockValues['3'].compensationsFacts}.${indexOfFact}` as const

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    name: formName,
    initialExpanded: initialAccordionMountState,
  })

  const tabName = useWatch({
    name: `${formName}.tabName`,
    control: formInstance.control,
  })

  return (
    <SubscribableControl
      {...getSubscribableControlProps({
        path: formName,
      })}
    >
      <Accordion
        id={id}
        anchorId={anchorId}
        title={tabName}
        isExpanded={isExpanded}
        initialAccordionState={initialAccordionState}
        initialAccordionMountState={initialAccordionMountState}
        onToggle={onToggleCollapse}
        onRemove={onRemove}
      >
        <CompensationFact id={id} name={formName} />
      </Accordion>
    </SubscribableControl>
  )
}

export default SubscribableCompensationFact
