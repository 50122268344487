import React, { FC, memo, useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'

import { chooseNpaBlockValues } from '@components/RegistryNpa/Choose/const'
import NpaModalDefaultThreeNpaList from '@components/RegistryNpa/Choose/Grid/DefaultThree/NpaList'
import { BaseNpaModalThreeProps } from '@components/RegistryNpa/Choose/Grid/types'
import DirectionItem from '@components/RegistryNpa/Choose/Items/DirectionItem'

const NpaModalDefaultThree: FC<BaseNpaModalThreeProps> = ({
  formInstance,
  mainDirection,
  mapOfNpaDirections,
  classificationHeader,
}) => {
  const { fields: threeToRender, update } = useFieldArray({
    control: formInstance.control,
    name: chooseNpaBlockValues.threeToRender,
    keyName: 'keyNameId',
  })

  const handleToggleDirectionItem = useCallback(
    (index: number) => (isCollapsed: boolean) => {
      const currentItem = formInstance.getValues(`${chooseNpaBlockValues.threeToRender}.${index}`)

      update(index, {
        ...currentItem,
        isCollapsed,
      })
    },
    [update],
  )

  return (
    <>
      {threeToRender.map(({ id, isCollapsed, direction, minInvestmentAmount }, index) => {
        return (
          <DirectionItem
            key={id}
            disabled={!!mainDirection}
            direction={direction}
            isCollapsed={isCollapsed}
            type={mapOfNpaDirections?.[direction] || ''}
            investmentSum={minInvestmentAmount}
            onToggle={handleToggleDirectionItem(index)}
          >
            <NpaModalDefaultThreeNpaList
              formInstance={formInstance}
              direction={direction}
              mainDirection={mainDirection}
              name={`${chooseNpaBlockValues.threeToRender}.${index}` as const}
              classificationHeader={classificationHeader}
            />
          </DirectionItem>
        )
      })}
    </>
  )
}

export default memo(NpaModalDefaultThree)
