import { isDateObject, isNullOrUndefined, isNumber, isString } from '@helpers/checkTypes'
import { ConfigType, isDayjs } from 'dayjs'

const mapOfInvalidDateTypes = {
  DATE_NON_EXISTENT: 'DATE_NON_EXISTENT',
  DATE_FORMAT_INCORRECT: 'DATE_FORMAT_INCORRECT',
} as const

const isDateValidForDayjs = (date: unknown): date is ConfigType => {
  return (
    isDayjs(date) ||
    isString(date) ||
    isNullOrUndefined(date) ||
    isDateObject(date) ||
    isNumber(date)
  )
}

export { isDateValidForDayjs, mapOfInvalidDateTypes }
