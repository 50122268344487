import React, { FC, ReactNode, useRef } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition'
import { TransitionProps } from 'react-transition-group/Transition'

import Container from '@components/ReactBootstrap/Container'
import { isFunction } from '@helpers/checkTypes'

import styles from './ContainerWithSwitchTransition.module.scss'

interface BaseContainerWithSwitchTransitionProps {
  condition?: boolean
  wrapperClassName?: string

  children: ((condition: boolean) => ReactNode) | ReactNode
}

interface PreparedTransitionProps
  extends Omit<TransitionProps<HTMLDivElement>, 'in' | 'nodeRef' | 'children' | 'addEndListener'> {
  classNames?: CSSTransitionClassNames
}

type ContainerWithSwitchTransitionProps = BaseContainerWithSwitchTransitionProps &
  PreparedTransitionProps

const defaultFadeTransitionClassNames = {
  enter: styles['transition__fade-enter'],
  exit: styles['transition__fade-exit'],
  enterActive: styles['transition__fade-enter--active'],
  exitActive: styles['transition__fade-exit--active'],
}

const ContainerWithSwitchTransition: FC<ContainerWithSwitchTransitionProps> = ({
  condition = false,
  mountOnEnter = true,
  unmountOnExit = true,
  timeout = 300,
  classNames,
  wrapperClassName,
  children,
  ...restProps
}) => {
  const firstNodeRef = useRef<HTMLDivElement | null>(null)
  const secondNodeRef = useRef<HTMLDivElement | null>(null)

  const currentNodeRef = !condition ? firstNodeRef : secondNodeRef

  return (
    <Container className={'p-0'}>
      <SwitchTransition>
        <CSSTransition
          key={String(condition)}
          mountOnEnter={mountOnEnter}
          unmountOnExit={unmountOnExit}
          nodeRef={currentNodeRef}
          timeout={timeout}
          classNames={classNames || defaultFadeTransitionClassNames}
          addEndListener={(done) =>
            currentNodeRef.current?.addEventListener('transitionend', done, false)
          }
          {...restProps}
        >
          <div ref={currentNodeRef} className={wrapperClassName}>
            {isFunction(children) ? children(condition) : children}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </Container>
  )
}

export default ContainerWithSwitchTransition
