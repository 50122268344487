import React, { useState } from 'react'
import { useWatch } from 'react-hook-form'

import Collapse from '@components/Collapse'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { blockValues } from '@components/Forms/CreateStatementOld/const'
import formStyles from '@components/Forms/CreateStatementOld/Forms/Forms.module.scss'
import { investorAddressValidation } from '@components/Forms/CreateStatementOld/Forms/InvestorAddress/validation'
import StatementHelpers from '@components/Forms/CreateStatementOld/helpers'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSingleSelect } from '@components/NewDesign/Select/SingleSelect/ControlledSingleSelect'
import Typography from '@components/NewDesign/Typography'
import CustomTransition from '@components/Transition'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import cn from 'classnames'

import styles from './AddressPart.module.scss'

const { generateAddressInOneString } = StatementHelpers
const { validationAdapter } = DocumentFormHelpers

const AddressPart = () => {
  const {
    state: { formInstance, blockViewIsValidating, initialErrorsFromViolations, statementRegions },
  } = useCreateStatementManager()

  const [fullMode, setFullMode] = useState<boolean>(!!initialErrorsFromViolations)

  useUpdateEffect(() => {
    setFullMode(true)
  }, [blockViewIsValidating])

  const handleActivateFullMode = (e) => {
    //Тригерится onSubmit
    e.preventDefault()

    setFullMode(true)
  }

  const heightOfFullMode = fullMode ? 'auto' : 0
  const heightOfMinMode = fullMode ? 0 : 'auto'

  const investorAddress = useWatch({
    name: 'investorAddress',
    control: formInstance?.control,
  })

  if (!formInstance) return null

  return (
    <div className={styles.addressPart}>
      <div className={styles.addressPart__minMode}>
        <div className={styles['addressPart__header']}>
          <Typography.Body color={'text-base-tertiary'} variant={'bodyMMedium'}>
            Юридический адрес
          </Typography.Body>
          <CustomTransition.Fade show={!fullMode}>
            <Button
              dataTestId="AddressPart-editAddress-button"
              size={'s'}
              view={'plain'}
              variant={'buttonSMedium'}
              onClick={handleActivateFullMode}
            >
              Изменить
            </Button>
          </CustomTransition.Fade>
        </div>
        {investorAddress && !initialErrorsFromViolations && (
          <Collapse height={heightOfMinMode} className={styles['addressPart__minMode-view']}>
            <Typography variant={'bodyMMedium'}>
              {generateAddressInOneString(investorAddress)}
            </Typography>
          </Collapse>
        )}

        <Collapse
          height={heightOfFullMode}
          className={cn({ [styles.addressPart__fullMode]: fullMode })}
          contentClassName={formStyles.form__container}
          dataTestId="AddressPart-investorGeneralInfo-collapse"
          delay={250}
        >
          <div className={formStyles.form__item}>
            <ControlledSingleSelect
              withContextSearch
              options={statementRegions}
              controllerProps={{
                name: blockValues.investorGeneralInfo.subject,
                control: formInstance.control,
              }}
              inputProps={{
                fixWidth: true,
                view: 'secondary',
                label: 'Субъект',
                dataTestId: `AddressPart-${blockValues.investorGeneralInfo.subject}-singleSelect`,
              }}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              name={blockValues.investorGeneralInfo.city}
              control={formInstance.control}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Город',
                maxLength: 500,
                dataTestId: `AddressPart-${blockValues.investorGeneralInfo.city}-input`,
              }}
              rules={validationAdapter(investorAddressValidation.city, {
                form: formInstance,
                name: blockValues.investorGeneralInfo.city,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              name={blockValues.investorGeneralInfo.district}
              control={formInstance.control}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Муниципальный район или округ',
                maxLength: 500,
                dataTestId: `AddressPart-${blockValues.investorGeneralInfo.district}-input`,
              }}
              rules={validationAdapter(investorAddressValidation.district, {
                form: formInstance,
                name: blockValues.investorGeneralInfo.district,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorGeneralInfo.area}
              inputProps={{
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Поселение, межселенная территория, внутригородской район',
                maxLength: 200,
                dataTestId: `AddressPart-${blockValues.investorGeneralInfo.area}-input`,
              }}
              rules={validationAdapter(investorAddressValidation.area, {
                form: formInstance,
                name: blockValues.investorGeneralInfo.area,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorGeneralInfo.planningStructureElement}
              inputProps={{
                maxLength: 200,
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Элемент планировочной структуры',
                caption:
                  'квартал; микрорайон; сквер; территория садоводческого, огороднического или дачного некоммерческого объединения граждан',
                dataTestId: `AddressPart-${blockValues.investorGeneralInfo.planningStructureElement}-input`,
              }}
              rules={validationAdapter(investorAddressValidation.planningStructureElement, {
                form: formInstance,
                name: blockValues.investorGeneralInfo.planningStructureElement,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__item}>
            <ControlledInput
              control={formInstance.control}
              name={blockValues.investorGeneralInfo.roadNetworkElement}
              inputProps={{
                maxLength: 500,
                view: 'secondary',
                size: 'xl',
                fixWidth: true,
                label: 'Улица',
                dataTestId: `AddressPart-${blockValues.investorGeneralInfo.roadNetworkElement}-input`,
              }}
              rules={validationAdapter(investorAddressValidation.roadNetworkElement, {
                form: formInstance,
                name: blockValues.investorGeneralInfo.roadNetworkElement,
                needTrigger: blockViewIsValidating,
              })}
            />
          </div>
          <div className={formStyles.form__row}>
            <div className={formStyles.form__item}>
              <ControlledInput
                control={formInstance.control}
                name={blockValues.investorGeneralInfo.house}
                inputProps={{
                  view: 'secondary',
                  size: 'xl',
                  label: 'Дом',
                  maxLength: 50,
                  dataTestId: `AddressPart-${blockValues.investorGeneralInfo.house}-input`,
                }}
                rules={validationAdapter(investorAddressValidation.house, {
                  form: formInstance,
                  name: blockValues.investorGeneralInfo.house,
                  needTrigger: blockViewIsValidating,
                })}
              />
            </div>
            <div className={formStyles.form__item}>
              <ControlledInput
                control={formInstance.control}
                name={blockValues.investorGeneralInfo.room}
                inputProps={{
                  view: 'secondary',
                  size: 'xl',
                  label: 'Квартира или офис',
                  maxLength: 50,
                  dataTestId: `AddressPart-${blockValues.investorGeneralInfo.room}-input`,
                }}
                rules={validationAdapter(investorAddressValidation.room, {
                  form: formInstance,
                  name: blockValues.investorGeneralInfo.room,
                  needTrigger: blockViewIsValidating,
                })}
              />
            </div>
            <div className={formStyles.form__item}>
              <ControlledInput
                control={formInstance.control}
                name={blockValues.investorGeneralInfo.roomPart}
                inputProps={{
                  view: 'secondary',
                  size: 'xl',
                  label: 'Комната',
                  caption: 'если применимо',
                  maxLength: 50,
                  dataTestId: `AddressPart-${blockValues.investorGeneralInfo.roomPart}-input`,
                }}
                rules={validationAdapter(investorAddressValidation.planningStructureElement, {
                  form: formInstance,
                  name: blockValues.investorGeneralInfo.roomPart,
                  needTrigger: blockViewIsValidating,
                })}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default AddressPart
