import {
  StatementOldFormValues,
  TStatementRules,
} from '@components/Forms/CreateStatementOld/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const investorAddressValidation: Record<
  keyof StatementOldFormValues['investorAddress'],
  TStatementRules
> = {
  area: {
    validate: (value) => isString(value) && lengthValidate(value, 200),
  },
  city: {
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  planningStructureElement: {
    validate: (value) => isString(value) && lengthValidate(value, 50),
  },
  roadNetworkElement: {
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  house: {
    validate: (value) => isString(value) && lengthValidate(value, 50),
  },
  room: {
    validate: (value) => isString(value) && lengthValidate(value, 50),
  },
  roomPart: {
    validate: (value) => isString(value) && lengthValidate(value, 50),
  },
  district: {
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  subject: {
    required: defaultRHFValidation.required,
  },
}

export { investorAddressValidation }
