import { useCallback, useRef } from 'react'

import { QueryRequestProps } from '@components/DocumentFormComponents/hooks/useFormRequestManager'
import { isFunction, isNumber } from '@helpers/checkTypes'
import { nanoid } from 'nanoid'

interface UseBatchFactoryProps {
  onBatchCreate: (batchPromise: QueryRequestProps) => Promise<void>
  onApplyFormChanges?: () => Promise<void>
}

export type BatchTimeOrTimerHandler = number | ((cb: () => unknown) => NodeJS.Timeout)

const DEFAULT_TIME_OF_CREATE_BATCH = 1750

const useBatchFormActions = ({ onBatchCreate, onApplyFormChanges }: UseBatchFactoryProps) => {
  const batchActivatedId = useRef<string | null>(null)
  const currentBatchActions = useRef<QueryRequestProps[]>([])

  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const resetBatchState = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    timeoutId.current = null
    batchActivatedId.current = null
    currentBatchActions.current = []
  }, [])

  const removeDuplicatesFromBatch = useCallback((actions: QueryRequestProps[]) => {
    const seenIds = new Set<string>()
    const result: QueryRequestProps[] = []

    for (let i = actions.length - 1; i >= 0; i--) {
      if (!seenIds.has(actions[i].id)) {
        seenIds.add(actions[i].id)
        result.unshift(actions[i])
      }
    }

    return result
  }, [])

  const preparationBatchPromise = useCallback(
    async (promise: () => Promise<unknown>) => {
      try {
        await promise()
      } catch (e) {
        console.error(e)
      } finally {
        onApplyFormChanges?.()
      }
    },
    [onApplyFormChanges],
  )

  const handleBatchCreate = useCallback(
    (actions: QueryRequestProps[]) => {
      if (!actions.length) return

      onBatchCreate({
        id: nanoid(),
        promise: () =>
          preparationBatchPromise(() =>
            Promise.allSettled(actions.map((action) => action.promise())),
          ),
      })
    },
    [onBatchCreate, preparationBatchPromise],
  )

  const getAndCloseBatch = useCallback(() => {
    const readyBatch = removeDuplicatesFromBatch(currentBatchActions.current)

    handleBatchCreate(readyBatch)

    resetBatchState()
  }, [removeDuplicatesFromBatch, handleBatchCreate, resetBatchState])

  const createBatchTimeout = useCallback(
    (batchTimeOrTimerHandler?: BatchTimeOrTimerHandler) => {
      if (isFunction(batchTimeOrTimerHandler)) {
        const batchTimerHandler = batchTimeOrTimerHandler

        timeoutId.current = batchTimerHandler(() => {
          getAndCloseBatch()
        })

        return
      }

      const batchTime = isNumber(batchTimeOrTimerHandler)
        ? batchTimeOrTimerHandler
        : DEFAULT_TIME_OF_CREATE_BATCH

      timeoutId.current = setTimeout(() => {
        getAndCloseBatch()
      }, batchTime)
    },
    [getAndCloseBatch],
  )

  const activateBatchQuery = useCallback(
    (
      idOfActivate: string,
      initialAction: QueryRequestProps,
      batchTimeOrTimerHandler?: BatchTimeOrTimerHandler,
    ) => {
      batchActivatedId.current = idOfActivate
      currentBatchActions.current.push(initialAction)

      createBatchTimeout(batchTimeOrTimerHandler)
    },
    [createBatchTimeout],
  )

  const continueBatchQuery = useCallback(
    (action: QueryRequestProps, batchTimeOrTimerHandler?: BatchTimeOrTimerHandler) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
      currentBatchActions.current.push(action)

      createBatchTimeout(batchTimeOrTimerHandler)
    },
    [createBatchTimeout],
  )

  const isActionAccessToBatch = useCallback(
    (batchId: string) => batchActivatedId.current === batchId,
    [],
  )

  const checkActionToBatch = useCallback(
    (
      action: QueryRequestProps,
      batchId?: string | null,
      batchTimeOrTimerHandler?: BatchTimeOrTimerHandler,
    ) => {
      if (!batchId && !batchActivatedId.current) {
        resetBatchState()

        return action
      }

      //Разрыв батча. Закрываем текущий батч и отдаём в обработку + возвращаем action разрыва
      if (!batchId && batchActivatedId.current) {
        getAndCloseBatch()
        return action
      }

      if (batchId && !batchActivatedId.current) {
        activateBatchQuery(batchId, action, batchTimeOrTimerHandler)
        return
      }

      if (batchId && batchActivatedId.current && isActionAccessToBatch(batchId)) {
        continueBatchQuery(action, batchTimeOrTimerHandler)
      }

      //Остался необработанный случай, когда batchId есть, но нет текущего сбора.
    },
    [
      resetBatchState,
      activateBatchQuery,
      continueBatchQuery,
      getAndCloseBatch,
      isActionAccessToBatch,
    ],
  )

  return { checkActionToBatch }
}

export { useBatchFormActions }
