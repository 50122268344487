import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultInformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/const'
import InformationOfExecutionConditionsSZPKLayout from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Layout'

const InformationOfExecutionConditionsSZPKLayoutWithFormProvider = (
  props: FormModalLayoutProps,
) => {
  return (
    <FormProviderWrapper defaultValues={defaultInformationOfExecutionConditionsSZPKFormValues}>
      <InformationOfExecutionConditionsSZPKLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(InformationOfExecutionConditionsSZPKLayoutWithFormProvider)
