import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NotificationDrawalAppConclusionSZPKFormValues } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfNotificationDrawalAppConclusionSZPKFormMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Основание отзыва заявления' },
]

const defaultNotificationDrawalAppConclusionSZPKFormValues: NotificationDrawalAppConclusionSZPKFormValues =
  {
    '1': {
      ...getDefaultInvestorFieldState(),
      investorInn: '',
      investorOgrn: '',
      investorEgrulAddress: '',
    },
    '2': {
      ruleLinkStatementRevoke: '',
    },
  }

const notificationDrawalAppConclusionSZPKBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorFullNameGenitive: '1.investorFullNameGenitive',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorEgrulAddress: '1.investorEgrulAddress',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
  },
  '2': {
    ruleLinkStatementRevoke: '2.ruleLinkStatementRevoke',
  },
} as const

export {
  defaultNotificationDrawalAppConclusionSZPKFormValues,
  mapOfNotificationDrawalAppConclusionSZPKFormMenu,
  notificationDrawalAppConclusionSZPKBlockValues,
}
