export const limitWithTooltip = (value: string, limit: number, dataFor = 'actionTypeValue') => {
  const valueLength = value?.length
  if (valueLength >= 0 && valueLength < limit) {
    return false
  }
  return {
    'data-tip': value,
    'data-for': dataFor,
  }
}
