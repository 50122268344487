import { getNotificationId } from '@components/NewNotifications/getNotificationId'
import { NotificationsLinkedList } from '@components/NewNotifications/NotificationsLinkedList'
import { INotification } from '@context/APIContext/hooks/useNotificationsApi'
import { findLast } from '@helpers/array/findLast'

export let currentNotificationsList = new NotificationsLinkedList()
let savedNotifications = new NotificationsLinkedList()

export const freshNotificationsArrivedHandler = (notifications: INotification[]) => {
  const availableNotification = findLast(notifications, (n) => !!currentNotificationsList.findNode(getNotificationId(n)))

  const newNotificationsList = new NotificationsLinkedList(notifications)

  /* Если пришла страница с одним или двумя новыми уведомлениями, но есть уже имеющееся, то добавляем в начало новые  */
  if (availableNotification) return currentNotificationsList.concat(newNotificationsList)

  /* Если вся страница - новые уведомления, то сохраняем имеющуюся последовательность уведомлений, а актуальными делаем новую страницу */
  savedNotifications = currentNotificationsList
  currentNotificationsList = newNotificationsList
}

export const newNotificationsArrivedHandler = (notifications: INotification[]) => {
  /**
   * Если у нас есть сохраненные уведомления и при новом запросе мы получили уже имеющееся, то соединяем оба листа
   */
  const availableNotificationInSaved = savedNotifications.head?.value && notifications.find((notification) => savedNotifications.findNode(getNotificationId(notification)))

  if (availableNotificationInSaved) {
    savedNotifications.concat(currentNotificationsList)
    currentNotificationsList = savedNotifications

    return
  }
  /**
   * Если нам пришла новая страница с уведомлениями, где одно из ведущих уведомлений уже есть в актуальном листе
   * Такое может быть из-за смещения списка при получении одного или двух более свежих уведомлений
   */

  const availableNotification = currentNotificationsList.head?.value && findLast(notifications, (n) => !!currentNotificationsList.findNode(getNotificationId(n)))

  /* Если все уведомления новые */
  currentNotificationsList.addValues(availableNotification ? notifications.slice(notifications.indexOf(availableNotification) + 1) : notifications)
}
