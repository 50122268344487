import { NewDfosType } from '@constants/types'
import { compact } from '@helpers/array/compact'
import { IDfoListItem, IDfoNewList } from '@services/Dfo/Dfo.entity'
import { TGetProjectActions } from '@services/Projects/Project.entity'

class DfoService {
  isDfoRejectWillBeCreate(dfoTypesFromCreate: string[]) {
    return dfoTypesFromCreate.some((item) =>
      (
        [
          NewDfosType.SZPK_TERMINATE,
          NewDfosType.SZPK_TERMINATE_JUDGMENT,
          NewDfosType.SZPK_TERMINATE_ONE_SIDE,
        ] as string[]
      ).includes(item),
    )
  }

  compareSubsidy(prevValue: IDfoListItem, nextValue: IDfoListItem) {
    if (prevValue.type === NewDfosType.CLAIM_SUBSIDY_PERMIT_DEBT) {
      if (nextValue.type === NewDfosType.CLAIM_SUBSIDY_PERMIT_DEBT) {
        return 0
      }
      return -1
    }

    if (
      prevValue.type === NewDfosType.CLAIM_SUBSIDY_PERMIT_DESTRUCTION &&
      nextValue.type === NewDfosType.CLAIM_SUBSIDY_PERMIT_BUILDING_APARTMENTS
    ) {
      return -1
    }

    if (
      nextValue.type === NewDfosType.CLAIM_SUBSIDY_PERMIT_DESTRUCTION &&
      prevValue.type === NewDfosType.CLAIM_SUBSIDY_PERMIT_BUILDING_APARTMENTS
    ) {
      return 1
    }

    return 0
  }

  getAllDfosFromGroupAndSubgrops(dfos: IDfoNewList[]) {
    return dfos
      .reduce<IDfoListItem[][]>((acc, curr) => {
        if (curr.dfos) {
          acc.push(curr.dfos)
        }
        if (curr.subgroups) {
          acc.push(this.getAllDfosFromGroupAndSubgrops(curr.subgroups).flat())
        }
        return acc
      }, [])
      .flat()
  }

  getCurrentDfoActions(dfoId: string, projectActions: TGetProjectActions) {
    return compact(
      projectActions.map((projectAction) => {
        if (projectAction.dfos.findIndex((item) => item.dfoId === dfoId) === -1) return null

        return {
          actionId: projectAction.actionId,
          action: projectAction.action,
          enable: projectAction.enable,
          uiActions: projectAction.uiActions,
          displayName: projectAction.displayName,
          position: projectAction.position,
          dfos: projectAction.dfos,
        }
      }),
    ) as TGetProjectActions
  }
}

export default new DfoService()
