import React from 'react'
import { useParams } from 'react-router'

import {
  dictionariesNames,
  DictionaryNameFromParams,
  referenceBookTitlesMap,
} from '@components/ReferenceBooks/constants'
import NotFound from '@routes/NotFound'
import ReferenceBooksFlatPage from '@routes/ReferenceBooks/[dictionaryName]/ReferenceBooksFlatPage'
import ReferenceBooksHierarchyPage from '@routes/ReferenceBooks/[dictionaryName]/ReferenceBooksHierarchyPage'

const mapOfDictionaryNamePages: Record<keyof typeof dictionariesNames, JSX.Element> = {
  estate_permissions: (
    <ReferenceBooksFlatPage titleText={referenceBookTitlesMap.estate_permissions} />
  ),
  eco_activities: <ReferenceBooksFlatPage titleText={referenceBookTitlesMap.eco_activities} />,
  infrastructure_objects: (
    <ReferenceBooksHierarchyPage titleText={referenceBookTitlesMap.infrastructure_objects} />
  ),
  tnved: <ReferenceBooksHierarchyPage titleText={referenceBookTitlesMap.tnved} />,
  okved: <ReferenceBooksHierarchyPage titleText={referenceBookTitlesMap.okved} />,
}

const DictionaryNamePage = () => {
  const { dictionaryName } = useParams<DictionaryNameFromParams>()

  if (!dictionaryName) return <NotFound />

  return mapOfDictionaryNamePages[dictionaryName] || <NotFound />
}

export default DictionaryNamePage
