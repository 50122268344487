import { IOrganizationInfo } from '@services/Organization/organization.entity'

const fromOrganizationInfoToProjectMember = (organization: IOrganizationInfo) => ({
  type: organization.type,
  name: organization.name,
  fullName: organization.fullName,
  organizationId: organization.id,
})

export { fromOrganizationInfoToProjectMember }
