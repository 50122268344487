import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'
import Sixth from './6'

interface SummaryReportOfMonitoringResultsFormProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
  Sixth: typeof Sixth
}

const SummaryReportOfMonitoringResultsForms: SummaryReportOfMonitoringResultsFormProps =
  () => null

SummaryReportOfMonitoringResultsForms.First = First
SummaryReportOfMonitoringResultsForms.Second  = Second
SummaryReportOfMonitoringResultsForms.Third = Third
SummaryReportOfMonitoringResultsForms.Fourth = Fourth
SummaryReportOfMonitoringResultsForms.Fifth = Fifth
SummaryReportOfMonitoringResultsForms.Sixth = Sixth

export default SummaryReportOfMonitoringResultsForms

