import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const eRegistrationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionStage['registration'],
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  typeObjectStateRegistration: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  objectStateRegistrationEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { eRegistrationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap }
