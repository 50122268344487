import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultSupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/const'
import SupplementalCostSharingALayout from '@components/Forms/SupplementalCostSharingAForm/Layout'

const SupplementalCostSharingALayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultSupplementalCostSharingAFormValues}>
      <SupplementalCostSharingALayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(SupplementalCostSharingALayoutWithFormProvider)
