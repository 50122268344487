import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { ApplicationInclusionOfAARegisterSZPKFormValues } from '@components/Forms/ApplicationInclusionOfAARegisterSZPK/types'

const { getDefaultInitiatorFieldState } = FieldStateHelpers

const mapOfApplicationInclusionOfAARegisterSZPKMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Дополнительное соглашение' },
]

const defaultApplicationInclusionOfAARegisterSZPKFormValues: ApplicationInclusionOfAARegisterSZPKFormValues =
  {
    '1': getDefaultInitiatorFieldState(),
    '2': {
      extraStatementName: '',
    },
  }

const applicationInclusionOfAARegisterSZPKBlockValues = {
  '1': {
    initiatorFullName: '1.initiatorFullName',
    initiatorInn: '1.initiatorInn',
    initiatorOgrn: '1.initiatorOgrn',
    initiatorAddress: '1.initiatorAddress',
    initiatorHeaderName: '1.initiatorHeaderName',
    initiatorHeaderNameGenitive: '1.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '1.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '1.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '1.initiatorHeaderReasonGenitive',
  },
  '2': {
    extraStatementName: '2.extraStatementName',
  },
} as const

export {
  applicationInclusionOfAARegisterSZPKBlockValues,
  defaultApplicationInclusionOfAARegisterSZPKFormValues,
  mapOfApplicationInclusionOfAARegisterSZPKMenu,
}
