import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import ApplicationOfConclusionAgreementSZPKRegisterLayout from './Layout'
import ApplicationOfConclusionAgreementSZPKRegisterModalManager from './Manager'
import ApplicationOfConclusionAgreementSZPKRegisterMenu from './Menu'
import ApplicationOfConclusionAgreementSZPKRegisterStatementForm from './Statement'

interface IApplicationOfConclusionAgreementSZPKRegister extends FC {
  Layout: typeof ApplicationOfConclusionAgreementSZPKRegisterLayout
  Menu: typeof ApplicationOfConclusionAgreementSZPKRegisterMenu
  StatementForm: typeof ApplicationOfConclusionAgreementSZPKRegisterStatementForm
  Manager: typeof ApplicationOfConclusionAgreementSZPKRegisterModalManager
}

const useApplicationOfConclusionAgreementSZPKRegisterControl = () => {
  const popupManager = usePopupManager()

  const openApplicationOfConclusionAgreementSZPKRegisterModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(ApplicationOfConclusionAgreementSZPKRegisterLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openApplicationOfConclusionAgreementSZPKRegisterModal }
}

const ApplicationOfConclusionAgreementSZPKRegister: IApplicationOfConclusionAgreementSZPKRegister =
  () => null

ApplicationOfConclusionAgreementSZPKRegister.Menu = ApplicationOfConclusionAgreementSZPKRegisterMenu
ApplicationOfConclusionAgreementSZPKRegister.Layout =
  ApplicationOfConclusionAgreementSZPKRegisterLayout
ApplicationOfConclusionAgreementSZPKRegister.StatementForm =
  ApplicationOfConclusionAgreementSZPKRegisterStatementForm
ApplicationOfConclusionAgreementSZPKRegister.Manager =
  ApplicationOfConclusionAgreementSZPKRegisterModalManager

export { useApplicationOfConclusionAgreementSZPKRegisterControl }
export default ApplicationOfConclusionAgreementSZPKRegister
