import React, { Children, DetailedHTMLProps, FC, HTMLAttributes } from 'react'

interface TagsWrapperProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  spaceX?: number
  spaceY?: number
}

const TagsWrapper: FC<TagsWrapperProps> = ({ spaceX = 8, spaceY = 24, children }) => {
  const childrens = Children.toArray(children)

  return (
    <div
      style={{
        marginLeft: `-${spaceX}px`,
        marginBottom: `-${spaceY}px`,
      }}
    >
      {childrens.map((component) => {
        if (!React.isValidElement(component)) return null

        return React.cloneElement(component, {
          ...component.props,
          style: {
            ...component.props.style,
            marginLeft: spaceX,
            marginBottom: spaceY,
          },
        })
      })}
    </div>
  )
}

export default TagsWrapper
