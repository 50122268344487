import { isStringNullOrUndefined } from '@helpers/checkTypes'

const fromStringNullOfUndefinedToNative = (value: unknown) => {
  if (isStringNullOrUndefined(value)) {
    return value === 'null' ? null : undefined
  }

  return value
}

export { fromStringNullOfUndefinedToNative }
