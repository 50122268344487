import React, { DetailedHTMLProps, FC, FormHTMLAttributes, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { useNotificationFillingAppConcludingManager } from '@components/Forms/NotificationFillingAppConcludingForm/Manager'
import { NotificationFillingAppConcludingFormValues } from '@components/Forms/NotificationFillingAppConcludingForm/types'

import { mapOfNotificationFillingAppConcludingMenu } from '../const'
import NotificationFillingAppConcludingForms from '../Forms'

type NotificationFillingAppConcludingFormProps = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>

const mapOfNotificationFillingAppConcludingForms: FormItemProps = {
  '1': {
    node: <NotificationFillingAppConcludingForms.First />,
  },
  '2': {
    node: <NotificationFillingAppConcludingForms.Second />,
  },
  '3': {
    node: <NotificationFillingAppConcludingForms.Third />,
  },
  '4': {
    node: <NotificationFillingAppConcludingForms.Fourth />,
  },
} as const

const NotificationFillingAppConcludingForm: FC<NotificationFillingAppConcludingFormProps> = ({
  ...rest
}) => {
  const formInstance = useFormContext<NotificationFillingAppConcludingFormValues>()

  const {
    state: { formIsLoading },
  } = useNotificationFillingAppConcludingManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfNotificationFillingAppConcludingMenu}
      mapOfForms={mapOfNotificationFillingAppConcludingForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(NotificationFillingAppConcludingForm)
