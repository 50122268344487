import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'

import { ApplicationOfConclusionAgreementSZPKFormValues } from './types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfApplicationOfConclusionAgreementSZPKRegisterMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Информация о проекте' },
]

const defaultApplicationOfConclusionAgreementSZPKRegisterFormValues: ApplicationOfConclusionAgreementSZPKFormValues =
  {
    '1': {
      ...getDefaultInvestorFieldState(),
      investorInn: '',
      investorOgrn: '',
      investorAddress: '',
    },
    '2': {
      projectName: '',
    },
  }

const applicationOfConclusionAgreementSZPKRegisterBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorAddress: '1.investorAddress',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
  },
  '2': {
    projectName: '2.projectName',
  },
} as const

export {
  applicationOfConclusionAgreementSZPKRegisterBlockValues,
  defaultApplicationOfConclusionAgreementSZPKRegisterFormValues,
  mapOfApplicationOfConclusionAgreementSZPKRegisterMenu,
}
