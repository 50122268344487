import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo } from 'react'

import { MapOfMenu } from '@components/DocumentFormComponents/types'
import Typography from '@components/NewDesign/Typography'
import { ITypographyBody } from '@components/NewDesign/Typography/types'
import cn from 'classnames'

import styles from './MenuItem.module.scss'

interface MenuItemProps
  extends Omit<
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'onClick'
  > {
  item: MapOfMenu
  dataTestId?: string
  activeMenuId?: string
  typographyProps?: Partial<Omit<ITypographyBody, 'children'>>
  onClick?: (menuItem: MapOfMenu) => void
}

const MenuItem: FC<MenuItemProps> = ({
  item,
  dataTestId,
  activeMenuId,
  className,
  typographyProps,
  onClick,
  ...props
}) => {
  const { variant = 'bodyMMedium', className: typographyClassName } = typographyProps || {}

  const isActive = activeMenuId === item.id
  const handleChangeActiveMenuOnClick = (menuItem: MapOfMenu) => () => {
    onClick?.(menuItem)
  }

  return (
    <button
      {...props}
      type="button"
      data-testid={`MenuItem-item-${dataTestId}`}
      className={cn(
        styles.menuItem,
        {
          [styles['menuItem--active']]: isActive,
        },
        className,
      )}
      onClick={handleChangeActiveMenuOnClick?.(item)}
    >
      <Typography.Body
        variant={variant}
        className={cn(
          styles.menuItem__text,
          {
            [styles['menuItem__text--active']]: isActive,
          },
          typographyClassName,
        )}
      >
        {item.title}
      </Typography.Body>
    </button>
  )
}

export default memo(MenuItem)
