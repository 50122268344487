import React, { FC, memo, useCallback, useMemo } from 'react'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMultipleSelect } from '@components/NewDesign/Select'
import Typography from '@components/NewDesign/Typography'
import ControlledOMSUSelect from '@components/PreparedSelects/OMSUSelect'
import FilterTooltip from '@components/PreparedTooltips/Filter'
import { useReusableFiltersTooltipLogicOld } from '@components/RegistryNpa/hooks/useReusableFiltersTooltipLogicOld'
import { useNpaManager } from '@components/RegistryNpa/Manager'
import { getCountOfActiveFiltersOld } from '@components/RegistryNpa/utils'
import { IAuthorizedOrganizationItem } from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { IFiltersOfGetNpa } from '@context/APIContext/hooks/useNpaApi'
import { isArray, isString } from '@helpers/checkTypes'
import filterIcon from '@icons/FilterIcon.svg'
import cn from 'classnames'

import styles from './Filters.module.scss'

export type TFiltersModalFormValues = Omit<
  IFiltersOfGetNpa,
  'level' | 'searchString' | 'page' | 'size'
> & {
  isFederal?: boolean
  isRegional?: boolean
  isMunicipal?: boolean
}

const HeaderNPAFilters: FC = () => {
  const {
    state: {
      filtersInstance,
      forSelect: { directionOptions, typeOptions, regionWithCodeOptions },
    },
    handlers: { handleApplyFilters },
  } = useNpaManager()

  const {
    control,
    tooltipIsOpen,
    handleOpenTooltip,
    handleCloseTooltip,
    handleCloseFilters,
    handleGetFiltersInModal,
    handleSetFilter,
    handleResetButton,
    handleApplyButton,
    isRegional,
    isFederal,
    isMunicipal,
    region,
  } = useReusableFiltersTooltipLogicOld({
    initialFilters: {
      isFederal: filtersInstance?.watch('filters.isFederal'),
      isRegional: filtersInstance?.watch('filters.isRegional'),
      isMunicipal: filtersInstance?.watch('filters.isMunicipal'),
      direction: filtersInstance?.watch('filters.direction'),
      part: filtersInstance?.watch('filters.part'),
      type: filtersInstance?.watch('filters.type'),
      region: filtersInstance?.watch('filters.region'),
    },
    handleApplyFilters,
  })

  const regionCodesForOMSUFilter = useMemo(() => {
    if (region && region.length) return region
    return []
  }, [region])

  const allFilters = filtersInstance?.watch('filters')
  const countOfActiveFilters = useMemo(() => {
    if (!allFilters) return

    return getCountOfActiveFiltersOld(allFilters)
  }, [allFilters])

  const handleRecalculateOMSUByChangeRegion = useCallback(() => {
    const modalFormValues = handleGetFiltersInModal()

    if (!modalFormValues.region) return

    const currentRegion = modalFormValues.region

    const OMSUValues = handleGetFiltersInModal().oktmo
    if (!isArray(OMSUValues)) return

    const preparedOMSUValues = OMSUValues.filter((stringifiedOMSU) => {
      const OMSUItem = JSON.parse(stringifiedOMSU) as IAuthorizedOrganizationItem

      if (!OMSUItem.region) return true
      if (isString(currentRegion)) return currentRegion === OMSUItem.region

      return currentRegion.includes(String(OMSUItem.region))
    })

    handleSetFilter('oktmo', preparedOMSUValues)
  }, [handleGetFiltersInModal, handleSetFilter])

  const handleRecalculateRegionByChooseOMSU = useCallback(() => {
    const modalFormValues = handleGetFiltersInModal()

    const OMSUValues = modalFormValues.oktmo

    if (isArray(modalFormValues.region) ? modalFormValues.region.length : modalFormValues.region)
      return
    if (!isArray(OMSUValues)) return

    const recalculatedRegions = OMSUValues.reduce((prevRegions, stringifiedOMSU) => {
      const OMSUItem = JSON.parse(stringifiedOMSU) as IAuthorizedOrganizationItem
      if (!OMSUItem.region) return prevRegions

      prevRegions.push(String(OMSUItem.region))
      return prevRegions
    }, [] as string[])

    handleSetFilter('region', recalculatedRegions)
  }, [handleGetFiltersInModal, handleSetFilter])

  const filtersTooltipContent = useMemo(
    () =>
      filtersInstance && (
        <>
          <Typography.Body
            className={styles['filters__checkboxes-container']}
            variant={'bodyMMedium'}
            color={'text-base-tertiary'}
          >
            Уровень акта
          </Typography.Body>
          <div
            className={cn(styles.filters__checkboxes, [styles['filters__checkboxes-container']])}
          >
            <ControlledCheckbox
              name={'isFederal'}
              control={control}
              checkBoxProps={{
                disabled: !isRegional && !isMunicipal,
                label: 'Федеральный',
              }}
            />
            <ControlledCheckbox
              name={'isRegional'}
              control={control}
              checkBoxProps={{
                disabled: !isFederal && !isMunicipal,
                label: 'Региональный',
              }}
            />
            <ControlledCheckbox
              name={'isMunicipal'}
              control={control}
              checkBoxProps={{
                disabled: !isFederal && !isRegional,
                label: 'Муниципальный',
              }}
            />
          </div>
          <div className={styles.filters__control}>
            <ControlledMultipleSelect
              closeOptionsAfterOnChange
              options={typeOptions}
              controllerProps={{
                name: 'type',
                control,
              }}
              inputProps={{
                label: 'Вид акта',
                fixWidth: true,
              }}
            />
          </div>
          <div className={styles.filters__control}>
            <ControlledMultipleSelect
              closeOptionsAfterOnChange
              options={directionOptions}
              controllerProps={{
                name: 'direction',
                control,
              }}
              inputProps={{
                label: 'Классификация',
                fixWidth: true,
              }}
            />
          </div>
          <div className={styles.filters__control}>
            <ControlledInput
              name={'part'}
              control={control}
              inputProps={{
                view: 'secondary',
                label: 'Положение',
                fixWidth: true,
              }}
            />
          </div>
          <div className={styles.filters__control}>
            <ControlledMultipleSelect
              closeOptionsAfterOnChange
              withContextSearch
              options={regionWithCodeOptions}
              //Значение = [код региона] - [значение региона]
              overrideDisplayValue={(value: string) => value.split('-').slice(1).join('-').trim()}
              controllerProps={{
                name: 'region',
                control,
              }}
              inputProps={{
                label: 'Регион применения акта',
                fixWidth: true,
              }}
              onRemoveChip={handleRecalculateOMSUByChangeRegion}
            />
          </div>
          <div className={styles.filters__control}>
            <ControlledOMSUSelect
              type={'multi'}
              regionCodes={regionCodesForOMSUFilter}
              controllerProps={{
                name: 'oktmo',
                control,
              }}
              onAddValue={handleRecalculateRegionByChooseOMSU}
            />
          </div>
        </>
      ),
    [
      filtersInstance,
      control,
      isRegional,
      isMunicipal,
      isFederal,
      typeOptions,
      directionOptions,
      regionWithCodeOptions,
      handleRecalculateOMSUByChangeRegion,
      regionCodesForOMSUFilter,
      handleRecalculateRegionByChooseOMSU,
    ],
  )

  return (
    <FilterTooltip
      filterContent={filtersTooltipContent}
      manualControl={{
        handleCloseTooltip,
        tooltipIsOpen,
      }}
      onApplyFilters={handleApplyButton}
      onResetFilters={handleResetButton}
      onClose={handleCloseFilters}
    >
      <Button
        view={'plain'}
        leadingIcon={
          !countOfActiveFilters
            ? {
                className: styles.filterIcon,
                src: filterIcon,
              }
            : undefined
        }
        onClick={handleOpenTooltip}
      >
        {!!countOfActiveFilters && (
          <Typography.Body
            as={'span'}
            className={styles.filterCount}
            variant={'bodyMMedium'}
            color={'on-accent-on-surface-primary'}
          >
            {countOfActiveFilters}
          </Typography.Body>
        )}
        <span
          className={cn({
            [styles.filterCount__text]: !!countOfActiveFilters,
          })}
        >
          Фильтры
        </span>
      </Button>
    </FilterTooltip>
  )
}

export default memo(HeaderNPAFilters)
