import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/const'
import { thirdSectionValidationMap } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/Forms/3/validation'
import { useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAManager } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/Manager'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance =
    useFormContext<NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAManager()

  const { getSubscribableControlProps, getControllerProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher:
        NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                  '3'
                ].investorFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Полное наименование'}
                controllerProps={getControllerProps({
                  name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '3'
                  ].investorFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                  '3'
                ].investorFullNameInstrumental,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '3'
                  ].investorFullNameInstrumental,
                  rules: thirdSectionValidationMap.investorFullNameInstrumental,
                  textareaProps: {
                    label: 'Полное наименование',
                    caption: 'в творительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '3'
                          ].investorFullNameInstrumental,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                          '3'
                        ].investorFullNameInstrumental,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                  '3'
                ].investorInn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ИНН'}
                controllerProps={getControllerProps({
                  name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '3'
                  ].investorInn,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                  '3'
                ].investorOgrn,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'ОГРН'}
                controllerProps={getControllerProps({
                  name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '3'
                  ].investorOgrn,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                  '3'
                ].investorAddress,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Юридический адрес'}
                controllerProps={getControllerProps({
                  name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '3'
                  ].investorAddress,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
