import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useParametersApplicationReimbursementExpensesManager } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Manager'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'

import { mapOfParametersApplicationReimbursementExpensesMenu } from '../const'
import ParametersApplicationReimbursementExpensesForms from '../Forms'

const mapOfParametersApplicationReimbursementExpensesForms: FormItemProps = {
  '1': {
    node: <ParametersApplicationReimbursementExpensesForms.First />,
  },
  '2': {
    node: <ParametersApplicationReimbursementExpensesForms.Second />,
  },
  '3': {
    node: <ParametersApplicationReimbursementExpensesForms.Third />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const ParametersApplicationReimbursementExpensesForm: FC<FormModalStatementProps> = ({
  ...rest
}) => {
  const formInstance = useFormContext<ParametersApplicationReimbursementExpensesFormValues>()

  const {
    state: { formIsLoading },
  } = useParametersApplicationReimbursementExpensesManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfParametersApplicationReimbursementExpensesMenu}
      mapOfForms={mapOfParametersApplicationReimbursementExpensesForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(ParametersApplicationReimbursementExpensesForm)
