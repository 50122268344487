import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useStatementAlreadyInvestmentsManager } from '@components/Forms/StatementAlreadyInvestments/Manager'
import { StatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/types'

import { mapOfStatementAlreadyInvestmentsMenu } from '../const'
import StatementAlreadyInvestmentsForms from '../Forms'

const mapOfStatementAlreadyInvestmentsForms: FormItemProps = {
  '1': {
    node: <StatementAlreadyInvestmentsForms.One />,
  },
  '2': {
    node: <StatementAlreadyInvestmentsForms.Two />,
  },
} as const

const StatementAlreadyInvestmentsForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<StatementAlreadyInvestmentsFormValues>()

  const {
    state: { formIsLoading },
  } = useStatementAlreadyInvestmentsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfStatementAlreadyInvestmentsMenu}
      mapOfForms={mapOfStatementAlreadyInvestmentsForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(StatementAlreadyInvestmentsForm)
