import { RolesTypes } from '@constants/types'
import type { ESIASignInProps } from '@context/APIContext/hooks/useESIAApi'
import { ValueOf } from '@helpers/ValueOf'
import type { AuthData, AuthRequestCert } from '@interfaces/auth'
import type { HeaderTypes } from '@packages/api/fetcher/config'

export interface AuthState {
  token?: string
  refreshToken?: string
}

export interface UserInfoRoles {
  roles: string[]
}

export interface UserInfoState {
  name: string
  resource_access: {
    account: UserInfoRoles
  }
  realm_access: UserInfoRoles

  //USER ID
  sub: string

  email?: string
}

export interface AuthRequest {
  email?: string
  password?: string
  scope?: string
  refresh_token?: string
}

export interface Procuration {
  number: string
  createdDate: string
  startDate: string
  organizationINN: string
  authority: string[]
  endDate?: string
  organizationName?: string
}

export type ProcurationData = Procuration[]

export interface IUserNameInfo {
  IO: string
  surname: string
}

export const FetchMethods = {
  POST: 'post',
  GET: 'get',
  DELETE: 'delete',
  PUT: 'put',
} as const

export interface RequestUrlAsObject {
  url: string
  queries?: Record<string, string>
  params?: Record<string, string>
}

export interface RequestProps<C = any> {
  url: string | RequestUrlAsObject
  config?: C & { headers?: any }
  header?: HeaderTypes
}

export interface RequestPropsWithData<D = any, C = any> extends RequestProps<C> {
  data?: D
}

/**
 * @type D - Data type
 */
export interface fetchMethodsResponse<D = any> {
  data: D
  status: number
  statusText: string
  headers: any
  config: any
  request?: any
}

/**
 * @type R - Response type
 * @type C - Config type
 * @type D - Data type
 */
export type fetchMethod = <R = any, C = any, D = any>(
  props: RequestProps<C> | RequestPropsWithData<D, C>,
) => Promise<fetchMethodsResponse<R>>

export type FetcherInterface = Record<ValueOf<typeof FetchMethods>, fetchMethod>

export interface AuthContextProps {
  refreshToken?: (body: AuthRequest) => Promise<AuthData>
  Fetcher: FetcherInterface
  FetcherWithInterceptors: FetcherInterface
  authState?: AuthState | null
  resetAuthState?: () => void
  setAuthState?: (state: AuthState) => void
  signIn?: (body: AuthRequest) => Promise<AuthData>
  // Расписать any когда будет известно
  getDataToSign?: () => Promise<any>
  saveProcuration?: (body: AuthRequestCert) => Promise<any>
  signInByCertificate?: (body: SignInByCertificateProps, isFL?: boolean) => Promise<AuthData>
  signInByESIA?: (body: ESIASignInProps) => Promise<AuthData>
  getClientId?: () => string
  getUserThumbprint?: () => string
  resetKapClient?: () => void
  saveCertificateData?: (certificate: any) => void
  userInfoState?: UserInfoState | null
  getUserRole?: () => any
  getUserIOandSurname?: () => IUserNameInfo

  getEmailStatus?: () => boolean
  getMainRole?: () => keyof typeof RolesTypes
  getInnByToken?: () => string | number
  getRolesByToken?: () => string[]
  findRoleBySelectedRoles?: (selectedRoles: (keyof typeof RolesTypes)[]) => string | undefined
  checkingRole?: (role: string) => boolean
  isHasRoleForRegister?: () => boolean

  logLogoutOfUser?: () => Promise<fetchMethodsResponse<void> | undefined>
}

export type SignInByCertificateProps = {
  type: 'default'
  body: AuthRequestCert
  isFL?: boolean
}
