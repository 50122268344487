import React, { cloneElement, ComponentType, isValidElement, ReactElement, ReactNode } from 'react'
import { FieldValues, Path, useWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types'

import AsyncWrapper from '@components/AsyncWrapper'
import Button from '@components/NewDesign/Button'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { isBoolean, isFunction } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './FormFieldPublicControl.module.scss'

const uiComponentNames = [
  'ControllerTextarea',
  'ControlledInput',
  'ControlledMultipleInput',
  'ControlledCalendarInput',
  'ControlledMaskInput',
  'ControlledAmountInput',
  'ControlledFormSingleSelect',
  'ControlledFormMultipleSelect',
  'ControlledHierarchyReferenceBookSelect',
  'ControlledCheckbox',
  'ControlledDocumentDataView',
]

interface FormFieldPublicControlProps<T extends FieldValues> {
  children: ReactNode
  disabled?: boolean
  name: Path<T>
  tooltipContent?: ReactNode
  control?: Control<T>
  onPublic?: (isPublic: boolean) => void
}

const FormFieldPublicControl = <T extends FieldValues>({
  disabled = false,
  tooltipContent,
  name,
  control,
  children,
  onPublic,
}: FormFieldPublicControlProps<T>) => {
  const isPublic = useWatch({
    control,
    name: `${name}.isPublic` as Path<T>,
  })

  const publicTooltipContent = isPublic
    ? 'Данные не будут отображены в публичной части реестра СЗПК'
    : 'Данные будут отображены в публичной части реестра СЗПК'

  if (!isValidElement(children)) return null

  if (!isBoolean(isPublic)) return children

  const handleChangePublic = async () => {
    await onPublic?.(!isPublic)
  }

  const publicButtonElement = (
    <Tooltip
      content={tooltipContent || publicTooltipContent}
      targetClassName={styles.tooltip__target}
      position={'top'}
      fallbackPlacements={['top', 'left', 'right', 'bottom']}
    >
      <AsyncWrapper promise={handleChangePublic}>
        {({ isLoading, wrappedPromise }) => (
          <Button
            disabled={isLoading || disabled}
            size={'xs'}
            view={'plain'}
            geometry={'round'}
            color={isPublic ? 'negative' : 'default'}
            variant={'buttonSMedium'}
            className={styles.formFieldPublicControl__button}
            textClassName={styles['formFieldPublicControl__button-text']}
            loaderProps={{
              loading: isLoading,
              placement: 'trailing',
              variant: 'lite',
              className: styles['formFieldPublicControl__button-loader'],
              wrapperClassName: styles['formFieldPublicControl__button-loaderWrapper'],
            }}
            onClick={wrappedPromise}
          >
            {isPublic ? 'Не публиковать' : 'Публиковать'}
          </Button>
        )}
      </AsyncWrapper>
    </Tooltip>
  )

  const cloneSelectComponent = (children: ReactElement) => {
    return cloneElement(children, {
      ...children.props,
      selectProps: {
        ...children.props.selectProps,
        inputProps: {
          ...children.props.selectProps.inputProps,
          labelTextClassName: cn(children.props.selectProps.inputProps?.labelTextClassName, {
            [styles['formFieldPublicControl__label--isPublic']]: isPublic,
          }),
          ...(!children.props.selectProps.disabled && {
            labelRightAddons: publicButtonElement,
          }),
        },
      },
    })
  }

  const cloneDataViewComponent = (children: ReactElement) => {
    return cloneElement(children, {
      ...children.props,
      suptitleTypographyProps: {
        ...children.props.suptitleTypographyProps,
        className: cn(children.props?.suptitleTypographyProps?.className, {
          [styles['formFieldPublicControl__label--isPublic']]: isPublic,
        }),
        rightAddons: publicButtonElement,
      },
    })
  }

  const cloneComponentByPropsName = (children: ReactElement, propsName: string) => {
    return cloneElement(children, {
      ...children.props,
      [propsName]: {
        ...children.props[propsName],
        labelTextClassName: cn(children.props?.[propsName]?.labelTextClassName, {
          [styles['formFieldPublicControl__label--isPublic']]: isPublic,
        }),
        ...(!children.props[propsName]?.disabled && {
          labelRightAddons: publicButtonElement,
        }),
      },
    })
  }

  const cloneCheckboxComponentByPropsName = (children: ReactElement) => {
    return cloneElement(children, {
      ...children.props,
      checkBoxProps: {
        ...children.props.checkBoxProps,
        rightAddons: publicButtonElement,
        labelTypographyProps: {
          ...children.props.checkBoxProps?.labelTypographyProps,
          className: cn(children.props.checkBoxProps?.labelTypographyProps?.className, {
            [styles['formFieldPublicControl__label--isPublic']]: isPublic,
          }),
        },
      },
    })
  }

  const cloneChild = (child: ReactElement) => {
    if ((child.type as ComponentType)?.displayName === 'ControlledDocumentDataView') {
      return cloneDataViewComponent(child)
    }

    if ('selectProps' in child.props) {
      return cloneSelectComponent(child)
    }

    if ('textareaProps' in child.props) {
      return cloneComponentByPropsName(child, 'textareaProps')
    }

    if ('calendarInputProps' in child.props) {
      return cloneComponentByPropsName(child, 'calendarInputProps')
    }

    if ('checkBoxProps' in child.props) {
      return cloneCheckboxComponentByPropsName(child)
    }

    if ('inputProps' in child.props) {
      return cloneComponentByPropsName(child, 'inputProps')
    }

    return child
  }

  const renderRecursiveMap = (children: ReactNode) => {
    return React.Children.map(children, (child) => {
      if (!isValidElement(child)) return child

      if (
        isFunction(child.type) &&
        'displayName' in child.type &&
        uiComponentNames.includes(String(child.type?.['displayName']))
      ) {
        return cloneChild(child)
      }

      if (!!child?.props?.children) {
        return cloneElement(child, {
          ...child?.props,
          children: renderRecursiveMap(child.props.children),
        })
      }

      return cloneElement(child, child?.props)
    })
  }

  return <>{renderRecursiveMap(children)}</>
}

export type { FormFieldPublicControlProps }
export default FormFieldPublicControl
