import React, { FC, memo } from 'react'

import IconStatus from '@components/IconStatus'
import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import { TIconDocumentSet } from '@services/Dictionaries/dictionaries.entity'
import cn from 'classnames'

import styles from './CustomSetHeader.module.scss'

interface CustomHeaderProps {
  title: string
  subtitle: string
  organizationOwnerName: string
  organizationFullOwnerName: string | null

  documentSetIcon: TIconDocumentSet
}

const CustomSetHeader: FC<CustomHeaderProps> = ({
  title,
  subtitle,
  documentSetIcon,
  organizationOwnerName,
  organizationFullOwnerName,
}) => {
  const isCustomConditionForIcon = documentSetIcon !== 'neutral'

  return (
    <div className={styles.customSetHeader}>
      {isCustomConditionForIcon && (
        <IconStatus status={documentSetIcon} className={styles.customSetHeader__icon} />
      )}
      <div
        className={cn(styles.customSetHeader__info, {
          [styles['customSetHeader__info--withIcon']]: isCustomConditionForIcon,
        })}
      >
        {organizationFullOwnerName ? (
          <Tooltip position={'top'} offset={[0, 2]} content={organizationFullOwnerName}>
            <Typography.Body color={'text-base-secondary'} variant={'bodyLMedium'}>
              {organizationOwnerName}
            </Typography.Body>
          </Tooltip>
        ) : (
          <Typography.Body color={'text-base-secondary'} variant={'bodyLMedium'}>
            {organizationOwnerName}
          </Typography.Body>
        )}
        <Typography.Headline as={'h2'} variant={'headlineH2'}>
          {title}
        </Typography.Headline>
        {subtitle && <Typography.Body variant={'bodyXLMedium'}>{subtitle}</Typography.Body>}
      </div>
    </div>
  )
}

export default memo(CustomSetHeader)
