import React, { Children, cloneElement, FC, isValidElement, ReactNode } from 'react'

import styles from './Wrapper.module.scss'

interface DocumentsSetWrapperProps {
  children: ReactNode
}

const DocumentsSetWrapper: FC<DocumentsSetWrapperProps> = ({ children }) => {
  return (
    <>
      {Children.toArray(children).map((child, index) => {
        if (!isValidElement(child)) return null

        return (
          <div key={index} className={styles.wrapper__item}>
            {cloneElement(child)}
          </div>
        )
      })}
    </>
  )
}

export default DocumentsSetWrapper
