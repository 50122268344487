import React, { FC } from 'react'

import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import UiActionButton from '@components/Projects/[id]/ActionBanner/Button'
import PlusIcon from '@icons/AddCircleIcon.svg'
import { IDocumentAction } from '@services/DocumentActions/DocumentActions.entity'

import styles from './Headline.module.scss'

export interface IHeadline {
  isNotificationCanBeAdded: boolean
  onInitNotificationAdding: () => Promise<void>
  onCancelNotificationAdding: () => Promise<void>
  isLoading?: boolean
  uiActionProps?: {
    uiAction: IDocumentAction
    onUiActionClick: () => void
  }
}

const Headline: FC<IHeadline> = ({
  isNotificationCanBeAdded,
  onInitNotificationAdding,
  onCancelNotificationAdding,
  isLoading,
  uiActionProps,
}) => {
  return (
    <div className={styles.headline}>
      <Typography.Headline variant={'headlineH3'} className={styles.headline__title}>
        Уведомление о смене реквизитов
      </Typography.Headline>
      <div>
        <Loader loading={isLoading} wrapperClassName={styles.headline__loader} variant={'lite'}>
          {isNotificationCanBeAdded ? (
            <Button
              type="button"
              view="plain"
              className={styles.headline__addingButton}
              leadingIcon={{
                src: PlusIcon,
                noCurrentColorSvgFill: true,
                className: styles.headline__plusIcon,
              }}
              onClick={onInitNotificationAdding}
            >
              Добавить уведомление
            </Button>
          ) : (
            <Button
              variant={'buttonSMedium'}
              view={'text'}
              size="s"
              type={'button'}
              color={'negative'}
              className={styles.headline__addingButton}
              onClick={onCancelNotificationAdding}
            >
              Отменить
            </Button>
          )}
          {uiActionProps && (
            <UiActionButton
              uiAction={uiActionProps.uiAction}
              className={styles.headline__uiActionButton}
              buttonProps={{
                onClick: uiActionProps.onUiActionClick,
              }}
            />
          )}
        </Loader>
      </div>
    </div>
  )
}

export default Headline
