import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  DataOnFulfillmentOfTermsSZPKFormValues,
  SzpkChangeTerminateCard,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import {
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

export const szpkChangeTerminateStepValidationMap: FormValuesValidationSection<
  keyof SzpkChangeTerminateCard,
  DataOnFulfillmentOfTermsSZPKFormValues
> = {
  totalCapitalCorrect: {
    validate: (value) => isString(value) && standardAmountValidate(value),
  },
  investorCapitalCorrect: {
    validate: (value) => isString(value) && standardAmountValidate(value),
  },
  changeTerminateReason: {
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}
