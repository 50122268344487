import { FC, memo } from 'react'

import { loginFormIcon } from '@components/Forms/LoginForm/constants'
import { isInvestorDomain } from '@constants/system'

import styles from './LoginLogo.module.scss'

interface LoginLogoProps {
  isShowErrorLogo?: boolean
}

const LoginLogo: FC<LoginLogoProps> = ({ isShowErrorLogo }) => {
  const logo = isInvestorDomain ? loginFormIcon.investor : loginFormIcon.goverment

  const logoIcon = isShowErrorLogo ? logo.error : logo.default

  return <img className={styles.logo} src={logoIcon} alt="Logo" />
}

export default memo(LoginLogo)
