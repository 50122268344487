import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useBeneficiaryInformationManager } from '@components/Forms/BeneficiaryInformationForm/Manager'
import { BeneficiaryInformationFormValues } from '@components/Forms/BeneficiaryInformationForm/types'

import { mapOfBeneficiaryInformationMenu } from '../const'
import BeneficiaryInformationForms from '../Forms'

const mapOfBeneficiaryInformationForms: FormItemProps = {
  '1': {
    node: <BeneficiaryInformationForms.One />,
  },
  '2': {
    node: <BeneficiaryInformationForms.Two />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
} as const

const BeneficiaryInformationForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<BeneficiaryInformationFormValues>()

  const {
    state: { formIsLoading },
  } = useBeneficiaryInformationManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfBeneficiaryInformationMenu}
      mapOfForms={mapOfBeneficiaryInformationForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(BeneficiaryInformationForm)
