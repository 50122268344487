import { useCallback, useMemo, useState } from 'react'

import { OptionProps } from '@components/NewDesign/Select/model'
import { RolesTypes } from '@constants/types'
import { useOMSUList } from '@hooks/new/swr/useOMSUList'
import useDebounce from '@hooks/useDebounce'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'

interface OMSUSelectConfigProps {
  regionCodes?: string | string[]
  pageSize?: number
  paginationBottomTrigger?: number
}

const PAGE_SIZE_FOR_MUNICIPALITY = 30
const PAGINATION_BOTTOM_TRIGGER = 150

const useOMSUSelectConfig = (props?: OMSUSelectConfigProps) => {
  const {
    pageSize = PAGE_SIZE_FOR_MUNICIPALITY,
    paginationBottomTrigger = PAGINATION_BOTTOM_TRIGGER,
    regionCodes,
  }: OMSUSelectConfigProps = props ?? {}

  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchString = useDebounce(searchValue, 500)

  const getKey = useCallback(
    (page) => ({
      page,
      size: pageSize,
      type: RolesTypes.OMSU,
      searchString: debouncedSearchString || undefined,
      regionNumbers: regionCodes,
      _key: 'OMSU_List',
    }),
    [debouncedSearchString, regionCodes, pageSize],
  )

  const { OMSUOrganizationsList, isLoadingOMSUOrganizationsList, hasMoreData, updatePage, error } =
    useOMSUList({
      key: getKey,
    })

  const incrementPage = useDebouncedCallback(updatePage, 250)

  const preparedOMSUOptions: OptionProps[] = useMemo(() => {
    if (!OMSUOrganizationsList) return []

    return OMSUOrganizationsList.reduce((prevOMSUList, currentOMSUItem) => {
      if (!currentOMSUItem.oktmo) return prevOMSUList

      prevOMSUList.push({
        displayValue: `${currentOMSUItem.region} - ${currentOMSUItem.municipalityName}`,
        value: JSON.stringify(currentOMSUItem),
      })

      return prevOMSUList
    }, [] as OptionProps[])
  }, [OMSUOrganizationsList])

  const onContentScroll = useCallback(
    async (e) => {
      const requestCondition = !error && !isLoadingOMSUOrganizationsList && hasMoreData

      if (
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
          paginationBottomTrigger &&
        requestCondition
      ) {
        try {
          await incrementPage((size) => size + 1)
        } catch {}
      }
    },
    [incrementPage, paginationBottomTrigger, error, hasMoreData, isLoadingOMSUOrganizationsList],
  )

  return {
    onChangeSearchValue: setSearchValue,
    onContentScroll,
    preparedOMSUOptions,
    OMSUData: {
      OMSUOrganizationsList,
      isLoadingOMSUOrganizationsList,
      hasMoreData,
      updatePage,
      error,
    },
  }
}

export { useOMSUSelectConfig }
