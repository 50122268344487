import { RolesTypes } from '@constants/types'
import { DfoGroupTypes } from '@context/APIContext/types'
import { compact } from '@helpers/array/compact'
import { isNullOrUndefined } from '@helpers/checkTypes'
import {
  mapOfActionAllowed,
  mapOfBaskets,
  mapOfStageStatementFilters,
  mapOfStatementFilterActions,
  mapOfStatementLvl,
  mapOfStatementSortActions,
  statementBasketsDefaultOptions,
  statementFiltersDefaultValues,
  statementSortDefaultValues,
} from '@routes/NewStatements/const'
import { FilterStatementController } from '@routes/NewStatements/filters'
import {
  BasketFilterValue,
  FilterController,
  StatementFilterFormValues,
  TFormStatement,
  TStatementParams,
} from '@routes/NewStatements/types'
import { ProjectTypes } from '@services/Projects/Project.entity'
import store from 'store2'
import { unstable_serialize } from 'swr'

export type BasketsRolesMap = keyof Pick<typeof RolesTypes, 'MER' | 'UO' | 'OIV' | 'ROIV' | 'OMSU'>

const { getFilterInstanceByTagGroup } = FilterStatementController

class StatementFiltersService {
  static prepareQueryForApply(filters: TStatementParams) {
    return Object.entries(filters).reduce((prevValue, [key, value]) => {
      if (value === undefined) return prevValue

      return {
        ...prevValue,
        [key]: Array.isArray(value) ? JSON.stringify(value) : String(value),
      }
    }, {})
  }

  static getFiltersToSendAPI(formValues: TFormStatement) {
    const filtersKey = StatementFiltersService.convertStatementFiltersToKey(formValues.filtersState)

    const otherFilters = {
      type: ProjectTypes.SZPK,
      dfoGroupType: formValues.tagRadioGroup === 'all' ? undefined : formValues.tagRadioGroup,
      ...filtersKey,
      orderBy: (() => {
        if (formValues.sortState.filterActions === statementSortDefaultValues.filterActions) {
          if (
            (formValues.tagRadioGroup === DfoGroupTypes.SZPK ||
              formValues.tagRadioGroup === DfoGroupTypes.EXTRA) &&
            formValues.basketTagGroup === mapOfBaskets.consideration
          ) {
            return `${mapOfStatementFilterActions.actionAllowed}:${mapOfStatementSortActions.desc}`
          }

          return `${mapOfStatementFilterActions.dfoUpdate}:${mapOfStatementSortActions.desc}`
        }

        if (formValues.sortState.filterActions === mapOfStatementFilterActions.initial) return

        return `${formValues.sortState.filterActions}:${formValues.sortState.sortActions}`
      })(),
      isRegistered:
        formValues.tagRadioGroup !== 'all' && formValues.tagRadioGroup !== DfoGroupTypes.SZPK
          ? undefined
          : filtersKey.isRegistered,
    }

    return {
      ...filtersKey,
      ...otherFilters,
    }
  }

  static getCountOfActiveFilters(newFiltersValue: Partial<StatementFilterFormValues>) {
    const activeFilters: Record<keyof StatementFilterFormValues, boolean | undefined> = {
      actionAllowed: newFiltersValue.actionAllowed !== mapOfActionAllowed.all,
      statementLvl: newFiltersValue.statementLvl !== mapOfStatementLvl.all,
      stage: newFiltersValue.stage !== mapOfStageStatementFilters.all,
      isRegistered: newFiltersValue.isRegistered,
      dfoStageExclude:
        unstable_serialize(newFiltersValue.dfoStageExclude) !==
        unstable_serialize(statementFiltersDefaultValues.dfoStageExclude),
      dfoStageInclude: !isNullOrUndefined(newFiltersValue.dfoStageInclude),
    }

    return compact(Object.values(activeFilters)).length
  }

  static convertStatementFiltersToKey(newFiltersValues: StatementFilterFormValues) {
    return {
      actionAllowed:
        newFiltersValues.actionAllowed === mapOfActionAllowed.needAttention || undefined,
      isFederal: (() => {
        if (isNullOrUndefined(newFiltersValues.statementLvl)) return

        return newFiltersValues.statementLvl === mapOfStatementLvl.all
          ? undefined
          : newFiltersValues.statementLvl === mapOfStatementLvl.federal
      })(),
      stage:
        newFiltersValues.stage === mapOfStageStatementFilters.all
          ? undefined
          : mapOfStageStatementFilters[newFiltersValues.stage?.toLowerCase()],
      isRegistered: newFiltersValues.isRegistered || undefined,
      dfoStageExclude: !newFiltersValues.dfoStageExclude
        ? undefined
        : newFiltersValues.dfoStageExclude,
      dfoStageInclude: !newFiltersValues.dfoStageInclude
        ? undefined
        : newFiltersValues.dfoStageInclude,
    }
  }

  static convertFiltersToPersist(filterValues: TFormStatement): TStatementParams {
    return {
      tagRadioGroup: filterValues.tagRadioGroup,
      basketTagGroup: filterValues.basketTagGroup,
      actionAllowed: filterValues.filtersState.actionAllowed,
      filterActions: filterValues.sortState.filterActions,
      stage: filterValues.filtersState.stage,
      isRegistered: filterValues.filtersState.isRegistered,
      statementLvl: filterValues.filtersState.statementLvl,
      sortActions: filterValues.sortState.sortActions,
      dfoStageExclude: filterValues.filtersState.dfoStageExclude,
      dfoStageInclude: filterValues.filtersState.dfoStageInclude,
      searchString: filterValues.searchString.length ? filterValues.searchString : undefined,
    }
  }

  static getConditionToSessionPersistFilters(
    tagGroup: TFormStatement['tagRadioGroup'],
    basketGroup: TFormStatement['basketTagGroup'],
  ) {
    return (
      tagGroup === 'all' ||
      tagGroup === DfoGroupTypes.COST_RECOVERY ||
      tagGroup === DfoGroupTypes.MONITORING
    )
  }
}

class BasketFiltersService {
  private readonly _role: BasketsRolesMap | null = null

  constructor(roleName: BasketsRolesMap) {
    this._role = roleName
  }

  private getFiltersController(tagGroup: keyof typeof DfoGroupTypes, role: BasketsRolesMap) {
    if (!role || !tagGroup) return

    const filterInstance = getFilterInstanceByTagGroup(tagGroup)

    if (!filterInstance) return

    const mapOfFiltersByRole: Record<BasketsRolesMap, FilterController> = {
      OIV: filterInstance.OIVFilterController,
      MER: filterInstance.MERFilterController,
      ROIV: filterInstance.ROIVFilterController,
      UO: filterInstance.UOFilterController,
      OMSU: filterInstance.OMSUFilterController,
    }

    return mapOfFiltersByRole[role]
  }

  getFiltersByBasketType(
    tagGroup: keyof typeof DfoGroupTypes,
    basketType: BasketFilterValue,
  ): StatementFilterFormValues | Record<string, unknown> {
    if (!this._role) return {}

    const controller = this.getFiltersController(tagGroup, this._role)

    if (!controller) return {}

    return controller.getFilters(basketType)
  }

  getBasketOptionsByTagGroup(tagGroup: keyof typeof DfoGroupTypes) {
    let preparedOptions = statementBasketsDefaultOptions

    if (tagGroup === DfoGroupTypes.EXTRA) {
      preparedOptions = [
        {
          title: 'Все изменения',
          id: mapOfBaskets.all,
        },
        {
          title: 'Приняты',
          id: mapOfBaskets.concluded,
        },
        {
          title: 'На подписании',
          id: mapOfBaskets.signing,
        },
        {
          title: 'На рассмотрении',
          id: mapOfBaskets.consideration,
        },
        {
          title: 'Черновики',
          id: mapOfBaskets.drafts,
        },
        {
          title: 'Архив',
          id: mapOfBaskets.archive,
        },
      ]
    }

    //Связь наличия рендера корзин, в зависимости от наличия группы фильтров
    if (!this._role) return preparedOptions

    const controller = this.getFiltersController(tagGroup, this._role)
    const allMapOfFilters = controller?.getAllMapOfFilters()

    if (!allMapOfFilters) return preparedOptions
    preparedOptions = preparedOptions.filter((option) => {
      return !!allMapOfFilters[option.id]
    })

    return preparedOptions
  }
}

class StatementPersistService {
  private readonly storeName = 'persistedStatementFilters'

  getFiltersFromLC(organizationId: string): TStatementParams | undefined {
    const storeByName = store.get(this.storeName)
    const filtersOfOrganization = storeByName?.[organizationId]

    return filtersOfOrganization
  }

  writeFiltersToLC(organizationId: string, filters: TStatementParams) {
    const currentStateOfLC = store.get(this.storeName)

    const preparedLC = {
      ...(currentStateOfLC ? currentStateOfLC : {}),
      [organizationId]: filters,
    }

    return store.set(this.storeName, preparedLC, true)
  }

  resetFiltersFromLC(organizationId: string) {
    const currentStateOfLC = store.get(this.storeName)
    if (!currentStateOfLC) return

    delete currentStateOfLC[organizationId]

    if (!Object.keys(currentStateOfLC).length) {
      store.remove(this.storeName)
      return
    }

    store.set(this.storeName, currentStateOfLC)
  }
}

export { BasketFiltersService, StatementFiltersService, StatementPersistService }
