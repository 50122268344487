import React, { FC, memo } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import Icon from '@components/Icon/Icon'
import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Typography from '@components/NewDesign/Typography'
import { DictionaryNameFromParams } from '@components/ReferenceBooks/constants'
import { useGetPersistedFilterValues } from '@components/ReferenceBooks/hooks/useGetPersistedFilterValues'
import { ReferenceBooksFiltersFormValues } from '@components/ReferenceBooks/types'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import useDebounce from '@hooks/useDebounce'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import { useQueryManager } from '@hooks/useQueryManager'
import searchIcon from '@icons/action/search.svg'
import cn from 'classnames'

import styles from './ReferenceBooksHeader.module.scss'

interface ReferenceBooksHeaderProps {
  titleText: string
  buttonProps?: ButtonProps
  onSearchClear?: () => Promise<void>
}

const ReferenceBooksHeader: FC<ReferenceBooksHeaderProps> = ({
  buttonProps,
  titleText,
  onSearchClear,
}) => {
  const { dictionaryName } = useParams<DictionaryNameFromParams>()
  const { queryUtils } = useQueryManager()

  const { getInitialFormStateFilters } = useGetPersistedFilterValues()

  const { control, watch, setValue } = useForm<ReferenceBooksFiltersFormValues>({
    defaultValues: getInitialFormStateFilters(),
  })

  const currentSearchString = watch('searchString')?.trim()
  const debouncedSearchValue = useDebounce(currentSearchString, 300)

  // добавление searchValue в адресную строку
  useUpdateEffect(() => {
    if (debouncedSearchValue.length >= 3) {
      queryUtils.addQuery({
        query: {
          searchString: debouncedSearchValue,
        },
      })
      return
    }

    queryUtils.removeQuery({ query: 'searchString' })
  }, [debouncedSearchValue])

  // очищается значение поиска, если сменили роут
  useEffectAfterMount(() => {
    setValue('searchString', '')
  }, [dictionaryName])

  return (
    <div className={styles.header}>
      <Typography.Headline className={styles.headline} variant={'headlineH2'}>
        {titleText}
      </Typography.Headline>
      <div className={styles.header__actions}>
        <ControlledInput
          name="searchString"
          control={control}
          inputProps={{
            rootClassName: styles.header__search,
            size: 'm',
            placeholder: 'Поиск',
            clear: true,
            view: 'secondary',
            leftAddons: (
              <Icon noCurrentColorSvgFill src={searchIcon} size="s" className={styles.searchIcon} />
            ),
          }}
          onClear={onSearchClear}
        />
        <Button className={cn(styles.header__addButton, buttonProps?.className)} {...buttonProps}>
          {buttonProps?.children || 'Добавить'}
        </Button>
      </div>
    </div>
  )
}

export default memo(ReferenceBooksHeader)
