import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { financialModelBlockValues } from '@components/Forms/FinancialModelForm/const'
import { oneSectionFinancialModelValidationMap } from '@components/Forms/FinancialModelForm/Forms/1/validation'
import { useFinancialModelManager } from '@components/Forms/FinancialModelForm/Manager'
import styles from '@components/Forms/FinancialModelForm/Statement/FinancialModelForm.module.scss'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { FinancialModelFieldsControlUpdateWatcher } from '../../watcher'

const One = () => {
  const formInstance = useFormContext<FinancialModelFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { debouncedHandleChangeValue, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useFinancialModelManager()

  const { getSubscribableControlProps, getCalendarInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: FinancialModelFieldsControlUpdateWatcher,
  })

  const investStagePeriod = useWatch({
    name: financialModelBlockValues['1'].investStagePeriod,
    control: formInstance?.control,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Typography.Body className={styles.form__item} variant={'bodyMMedium'}>
          Внесите отдельные показатели инвестиционного проекта в соответствии с финансовой моделью.
          Финансовая модель в формате Excel загружается отдельно
        </Typography.Body>
        <Group disableBottomBorder title={'Инвестиционная стадия'} groupClassName={'mt-4'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: financialModelBlockValues['1'].startInvestStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: financialModelBlockValues['1'].startInvestStage,
                      rules: oneSectionFinancialModelValidationMap.startInvestStage,
                      calendarProps: {
                        typeOfPicker: 'years',
                        dateFormat: 'YYYY',
                      },
                      calendarInputProps: {
                        label: 'Плановый год начала',
                        inputMask: YEAR_MASK,
                        dataTestId: 'startInvestStage',
                      },

                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(financialModelBlockValues['1'].startInvestStage),
                          0,
                        ),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            financialModelBlockValues['1'].startInvestStage,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(financialModelBlockValues['1'].startInvestStage),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: financialModelBlockValues['1'].endInvestStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: financialModelBlockValues['1'].endInvestStage,
                      rules: oneSectionFinancialModelValidationMap.endInvestStage,
                      calendarProps: {
                        typeOfPicker: 'years',
                        dateFormat: 'YYYY',
                      },
                      calendarInputProps: {
                        label: 'Плановый год окончания',
                        inputMask: YEAR_MASK,
                        dataTestId: 'endInvestStage',
                      },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(financialModelBlockValues['1'].endInvestStage),
                          0,
                        ),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            financialModelBlockValues['1'].endInvestStage,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(financialModelBlockValues['1'].endInvestStage),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <RowWithBorder
              disableTitleLeftPadding
              disableBottomPadding
              title={'Срок реализации (количество лет)'}
            >
              <Typography.Body variant="bodyMMedium" className={'py-3 pl-3'}>
                {investStagePeriod || '-'}
              </Typography.Body>
            </RowWithBorder>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Эксплуатационная стадия'} groupClassName={'mt-0'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: financialModelBlockValues['1'].startExploitationStage,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: financialModelBlockValues['1'].startExploitationStage,
                      rules: oneSectionFinancialModelValidationMap.startExploitationStage,
                      calendarProps: {
                        typeOfPicker: 'years',
                        dateFormat: 'YYYY',
                      },
                      calendarInputProps: {
                        label: 'Плановый год начала',
                        inputMask: YEAR_MASK,
                        dataTestId: 'startExploitationStage',
                      },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(
                              financialModelBlockValues['1'].startExploitationStage,
                            ),
                          0,
                        ),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            financialModelBlockValues['1'].startExploitationStage,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(
                              financialModelBlockValues['1'].startExploitationStage,
                            ),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </Col>
              <Col xs={6}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: financialModelBlockValues['1'].projectCapacityYear,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: financialModelBlockValues['1'].projectCapacityYear,
                      rules: oneSectionFinancialModelValidationMap.projectCapacityYear,

                      calendarProps: {
                        typeOfPicker: 'years',
                        dateFormat: 'YYYY',
                      },
                      calendarInputProps: {
                        label: 'Выход на проектную мощность',
                        inputMask: YEAR_MASK,
                        dataTestId: 'projectCapacityYear',
                      },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(financialModelBlockValues['1'].projectCapacityYear),
                          0,
                        ),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            financialModelBlockValues['1'].projectCapacityYear,
                          )
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(financialModelBlockValues['1'].projectCapacityYear),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default One
