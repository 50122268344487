import React from 'react'
import { useFormContext } from 'react-hook-form'

import MunicipalitiesSection from '@components/DocumentFormComponents/FormSections/MunicipalitiesSection'
import { aAChangingVolumeReimbursementBlockValues } from '@components/Forms/AAChangingVolumeReimbursementForm/const'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import {
  AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher,
  AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
} from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'

const Third = () => {
  const formInstance = useFormContext<AAChangingVolumeReimbursementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAChangingVolumeReimbursementManager()

  if (!formInstance) return null

  return (
    <MunicipalitiesSection
      formName={aAChangingVolumeReimbursementBlockValues['3']['municipalities']}
      formInstance={formInstance}
      blockViewIsValidating={blockViewIsValidating}
      editMode={editMode}
      watcher={AAChangingVolumeReimbursementFieldsControlUpdateWatcher}
      fieldArrayWatcher={AAChangingVolumeReimbursementFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Third
