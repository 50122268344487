import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import AAgreementOnChangingCharacteristicsOfObjectsMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'
import AAgreementOnChangingCharacteristicsOfObjectsLayoutWithFormProvider from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Layout/LayoutWithFormProvider'
import AAgreementOnChangingCharacteristicsOfObjectsModalManager from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import AAgreementOnChangingCharacteristicsOfObjectsMenu from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Menu'
import AAgreementOnChangingCharacteristicsOfObjectsForm from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Statement'

interface AAgreementOnChangingCharacteristicsOfObjectsProps extends FC {
  LayoutWithFormProvider: typeof AAgreementOnChangingCharacteristicsOfObjectsLayoutWithFormProvider
  Menu: typeof AAgreementOnChangingCharacteristicsOfObjectsMenu
  StatementForm: typeof AAgreementOnChangingCharacteristicsOfObjectsForm
  Manager: typeof AAgreementOnChangingCharacteristicsOfObjectsModalManager
  MenuManager: typeof AAgreementOnChangingCharacteristicsOfObjectsMenuManager
}

const useAAgreementOnChangingCharacteristicsOfObjectsControl = () => {
  const popupManager = usePopupManager()

  const openAAgreementOnChangingCharacteristicsOfObjectsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        AAgreementOnChangingCharacteristicsOfObjectsLayoutWithFormProvider,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openAAgreementOnChangingCharacteristicsOfObjectsModal }
}

const AAgreementOnChangingCharacteristicsOfObjects: AAgreementOnChangingCharacteristicsOfObjectsProps =
  () => null

AAgreementOnChangingCharacteristicsOfObjects.Menu = AAgreementOnChangingCharacteristicsOfObjectsMenu
AAgreementOnChangingCharacteristicsOfObjects.LayoutWithFormProvider =
  AAgreementOnChangingCharacteristicsOfObjectsLayoutWithFormProvider
AAgreementOnChangingCharacteristicsOfObjects.StatementForm =
  AAgreementOnChangingCharacteristicsOfObjectsForm
AAgreementOnChangingCharacteristicsOfObjects.Manager =
  AAgreementOnChangingCharacteristicsOfObjectsModalManager
AAgreementOnChangingCharacteristicsOfObjects.MenuManager =
  AAgreementOnChangingCharacteristicsOfObjectsMenuManager

export { useAAgreementOnChangingCharacteristicsOfObjectsControl }
export default AAgreementOnChangingCharacteristicsOfObjects
