import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'

class ProjectSZPKMenuHelpers {
  static sortThreeFunction(prevItem: NestedMapOfMenu, nextItem: NestedMapOfMenu) {
    return prevItem.id.localeCompare(nextItem.id)
  }

  static findParentNodeByMenuItem(
    initialThree: NestedMapOfMenu[],
    menuItemId: string,
    parent: NestedMapOfMenu | null = null,
  ): NestedMapOfMenu | null {
    for (const menuItem of initialThree) {
      if (menuItem.id === menuItemId) {
        return parent // Возвращаем родителя найденного узла
      } else {
        const foundParent = ProjectSZPKMenuHelpers.findParentNodeByMenuItem(
          menuItem.children,
          menuItemId,
          menuItem,
        )

        if (foundParent) {
          return foundParent
        }
      }
    }
    return null
  }

  static treeToHashMap(
    initialThree: NestedMapOfMenu[],
    initialHashMap: Record<string, NestedMapOfMenu> = {},
  ): Record<string, NestedMapOfMenu> {
    initialThree.forEach((threeItem) => {
      if (threeItem && threeItem.id) {
        initialHashMap[threeItem.id] = threeItem

        if (threeItem.children.length) {
          ProjectSZPKMenuHelpers.treeToHashMap(threeItem.children, initialHashMap)
        }
      }
    })
    return initialHashMap
  }

  static addItemInThree(
    initialThree: NestedMapOfMenu[],
    parentId: string | null,
    newMenuItem: NestedMapOfMenu,
  ) {
    if (parentId === null) {
      return [...initialThree, newMenuItem].sort(ProjectSZPKMenuHelpers.sortThreeFunction)
    } else {
      const ready = initialThree.map((menuItem) => {
        if (menuItem.id === parentId) {
          // Нашли родителя, добавляем новый узел в его детей
          const updatedChildren = [...menuItem.children, newMenuItem].sort(
            ProjectSZPKMenuHelpers.sortThreeFunction,
          )
          return { ...menuItem, children: updatedChildren }
        } else if (menuItem.children.length > 0) {
          // Если это не родительский узел, но у узла есть дети, продолжаем поиск
          const updatedChildren = ProjectSZPKMenuHelpers.addItemInThree(
            menuItem.children,
            parentId,
            newMenuItem,
          ).sort(ProjectSZPKMenuHelpers.sortThreeFunction)
          return { ...menuItem, children: updatedChildren }
        }
        // Возвращаем узел без изменений, если он не связан с операцией вставки
        return menuItem
      })

      return ready
    }
  }

  static updateItemInThree = (
    initialThree: NestedMapOfMenu[],
    menuItemId: string,
    updateMenuItemFn: (menuItem: NestedMapOfMenu) => NestedMapOfMenu,
  ) => {
    return initialThree.map((menuItem) => {
      if (menuItem.id === menuItemId) {
        // Применяем функцию обновления к узлу
        return updateMenuItemFn({ ...menuItem })
      } else if (menuItem.children.length > 0) {
        // Если это не узел для обновления, но у узла есть дети, обновляем дочерние узлы
        const updatedChildren = ProjectSZPKMenuHelpers.updateItemInThree(
          menuItem.children,
          menuItemId,
          updateMenuItemFn,
        ).sort(ProjectSZPKMenuHelpers.sortThreeFunction)

        return { ...menuItem, children: updatedChildren }
      }
      // Возвращаем узел без изменений, если он не подлежит обновлению
      return menuItem
    })
  }

  static removeItemFromThree(
    initialThree: NestedMapOfMenu[],
    menuItemId: string,
  ): NestedMapOfMenu[] {
    return initialThree.reduce((prevMapOfMenu: NestedMapOfMenu[], menuItem) => {
      if (menuItem.id === menuItemId) {
        // Если текущий узел - тот, что нужно удалить, не добавляем его в аккумулятор
        return prevMapOfMenu
      } else {
        // Если это не узел для удаления, проверяем его детей
        const newChildren = ProjectSZPKMenuHelpers.removeItemFromThree(
          menuItem.children,
          menuItemId,
        ).sort(ProjectSZPKMenuHelpers.sortThreeFunction)

        // Создаем новый узел с обновленным списком детей
        const newNode = { ...menuItem, children: newChildren }
        prevMapOfMenu.push(newNode)

        return prevMapOfMenu
      }
    }, [])
  }
}

export { ProjectSZPKMenuHelpers }
