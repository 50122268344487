import { documentsLinks } from '@constants/documents'

export interface IAct {
  id: number
  title: string
  description: string
  localDocument: string | null
  hrefToResource: string | null
}

export const listActs: IAct[] = [
  {
    id: 0,
    title: 'Федеральный закон от 01 апреля 2020 г. № 69-ФЗ',
    description: `О защите и поощрении капиталовложений в Российской Федерации`,
    localDocument: documentsLinks.FZ_N69,
    hrefToResource: 'https://base.garant.ru/73826576/',
  },
  {
    id: 1,
    title: 'Указ Президента Российской Федерации от 07.05.2024 № 309',
    description: `О национальных целях развития Российской Федерации на период до 2030 года и на перспективу до 2036 года`,
    localDocument: null,
    hrefToResource: 'http://publication.pravo.gov.ru/document/0001202405070015',
  },
  {
    id: 2,
    title: 'Постановление от 13 сентября 2022 г. № 1602',
    description: `О соглашениях о защите и поощрении капиталовложений`,
    localDocument: documentsLinks.DECREE_N1602,
    hrefToResource: 'https://base.garant.ru/405299567/',
  },
  {
    id: 3,
    title: 'Постановление от 13 сентября 2022 г. № 1601',
    description: `О рабочей группе по содействию реализации новых инвестиционных проектов на основании соглашений о защите и поощрении капиталовложений в Российской Федерации`,
    localDocument: null,
    hrefToResource: 'https://base.garant.ru/405293697/',
  },
  {
    id: 4,
    title: 'Постановление от 3 октября 2020 г. № 1599',
    description: `О порядке предоставления из федерального бюджета субсидий юридическим лицам (за исключением государственных (муниципальных) учреждений, государственных (муниципальных) предприятий) на возмещение затрат на создание (строительство), модернизацию и (или) реконструкцию обеспечивающей и (или) сопутствующей инфраструктур, необходимых для реализации инвестиционного проекта, в отношении которого заключено соглашение о защите и поощрении капиталовложений, а также затрат на уплату процентов по кредитам и займам, купонных платежей по облигационным займам, привлеченным на указанные цели, и определения объема возмещения указанных затрат`,
    localDocument: documentsLinks.DECREE_N1599,
    hrefToResource: ' https://base.garant.ru/74732084/',
  },
  {
    id: 5,
    title: 'Постановление от 28 мая 2022 г. № 967',
    description: `О первой и второй очередях государственной информационной системы "Капиталовложения"`,
    localDocument: null,
    hrefToResource: 'https://base.garant.ru/404780091/',
  },
  {
    id: 6,
    title: 'Распоряжение Правительства Российской Федерации от 21 сентября 2022 г. № 2724-р',
    description:
      'Утвержденный перечень федеральных законов и иных нормативных правовых актов Российской Федерации, которые применяются с учетом особенностей, установленных статьей 9 Федерального закона "О защите и поощрении капиталовложений в Российской Федерации".',
    localDocument: documentsLinks.GOVERNMENT_ORDER_N2724,
    hrefToResource: null,
  },
  {
    id: 7,
    title: 'Приказ Минэкономразвития России от 16 ноября 2022 г. № 626',
    description: 'Об утверждении общих требований к финансовой модели инвестиционного проекта',
    localDocument: documentsLinks.ORDER_N626,
    hrefToResource: null,
  },
]
