import React, { FC, memo, ReactNode } from 'react'

import Typography from '@components/NewDesign/Typography'

import styles from './MetaItem.module.scss'

export interface MetaItemProps {
  metaName: string
  metaInfo: ReactNode
}

const MetaItem: FC<MetaItemProps> = ({ metaName, metaInfo }) => {
  return (
    <div className={styles.metaItem}>
      <Typography.Body
        as={'span'}
        className={styles.metaItem__name}
        variant={'bodyMRegular'}
        color={'text-base-secondary'}
      >
        {metaName}
      </Typography.Body>
      <div className={styles.metaItem__content}>{metaInfo}</div>
    </div>
  )
}

export default memo(MetaItem)
