import React, { createContext, memo } from 'react'

import Headline from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/Headline/index'
import { useNotificationsAddingActions } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/hooks/useNotificationsAddingActions'
import { useNotificationsUiAction } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/hooks/useNotificationsUiAction'
import { NotificationsList } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/NotificationsList'
import { NotNotifications } from '@components/OrganizationInfo/NotificationsOfRequisitesChanges/NotNotifications'
import { DfosStage } from '@constants/types'
import { useProjects } from '@hooks/new/swr/useProjects'
import { ProjectTypes } from '@services/Projects/Project.entity'
import { useSWRConfig } from 'swr'

import styles from './NotificationsOfRequisitesChanges.module.scss'

interface INotificationsOfRequisitesChangesContext {
  revalidateProjects: () => Promise<void>
  isDeletingInProcess: boolean
}

export const NotificationsOfRequisitesChangesContext = createContext<
  INotificationsOfRequisitesChangesContext | Record<string, never>
>({})

const NotificationsOfRequisitesChangesProvider = NotificationsOfRequisitesChangesContext.Provider

const mapOfQueryKeys = {
  projectsKey: 'notificationsProjects',
  uiActionsKey: 'notificationsUiActions',
}
const PAGE_SIZE = 10

const NotificationsOfRequisitesChanges = () => {
  const { mutate: revalidate } = useSWRConfig()

  const {
    projects,
    isLoadingProjects,
    goToNextPage,
    mutate: revalidateProjects,
  } = useProjects({
    key: (page) => ({
      _key: mapOfQueryKeys.projectsKey,
      page,
      size: PAGE_SIZE,
      type: ProjectTypes.ORGANIZATION_INFO_CHANGES,
    }),
    config: {
      revalidateFirstPage: false,
    },
    pageSize: PAGE_SIZE,
  })

  const handleRevalidateProjects = async () => {
    await revalidate(mapOfQueryKeys.uiActionsKey)
    await revalidateProjects()
  }

  const activeProjectForAddingActions =
    projects?.length && projects[0]?.dfos[0].stage === DfosStage.DRAFT ? projects?.[0] : undefined

  const {
    isNotificationCanBeAdded,
    isProjectAddingInProgress,
    isProjectDeletingInProgress,
    onCreateProject,
    onDeleteActiveProject,
  } = useNotificationsAddingActions(activeProjectForAddingActions, handleRevalidateProjects)

  const { uiAction, onUiActionClick } = useNotificationsUiAction(
    mapOfQueryKeys.uiActionsKey,
    handleRevalidateProjects,
  )
  const uiActionProps = uiAction ? { uiAction, onUiActionClick } : undefined

  return (
    <NotificationsOfRequisitesChangesProvider
      value={{
        revalidateProjects: handleRevalidateProjects,
        isDeletingInProcess: isProjectDeletingInProgress,
      }}
    >
      <div className={styles.wrapper}>
        {projects?.length ? (
          <>
            <Headline
              isNotificationCanBeAdded={isNotificationCanBeAdded}
              uiActionProps={uiActionProps}
              isLoading={
                isLoadingProjects || isProjectAddingInProgress || isProjectDeletingInProgress
              }
              onInitNotificationAdding={onCreateProject}
              onCancelNotificationAdding={onDeleteActiveProject}
            />
            <NotificationsList projects={projects} onLoadMore={goToNextPage} />
          </>
        ) : (
          !isLoadingProjects &&
          projects && (
            <NotNotifications
              isLoading={isProjectAddingInProgress}
              onInitNotificationAdding={onCreateProject}
            />
          )
        )}
      </div>
    </NotificationsOfRequisitesChangesProvider>
  )
}

export default memo(NotificationsOfRequisitesChanges)
