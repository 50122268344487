import React, { FC, memo } from 'react'

import Typography from '@components/NewDesign/Typography'
import { EventToDisplay } from '@components/Sidebars/Events/store'

import styles from './SideBarEventItem.module.scss'

type SidebarEventItemProps = EventToDisplay

const SidebarEventItem: FC<SidebarEventItemProps> = ({
  eventDescription,
  eventTitle,
  actionDescription,
  eventDateTime,
}) => {
  return (
    <div className={styles.eventItem}>
      <header className={styles.eventItem__header}>
        <Typography.Caption variant={'captionSRegular'}>{eventTitle}</Typography.Caption>
        <Typography.Caption className={styles.eventItem__date} variant={'captionSRegular'}>
          {eventDateTime}
        </Typography.Caption>
      </header>
      <Typography.Body variant={'bodyMMedium'} className={styles.eventItem__eventDescription}>
        {eventDescription}
      </Typography.Body>
      <Typography.Body variant={'bodyMRegular'} className={styles.eventItem__actionDescription}>
        {actionDescription}
      </Typography.Body>
    </div>
  )
}

export default memo(SidebarEventItem)
