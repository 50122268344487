import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import {
  defaultRHFValidation,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionValidationMap: FormValuesValidationSection<
  keyof SummaryReportOfMonitoringResultsFormValues['3'],
  SummaryReportOfMonitoringResultsFormValues
> = {
  federalSzpkCountFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  federalSzpkCountFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  regionalSzpkCountFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  regionalSzpkCountFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  federalSzpkPeriodCountFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  federalSzpkPeriodCountFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  regionalSzpkPeriodCountFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  regionalSzpkPeriodCountFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  federalTotalCapitalFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  federalTotalCapitalFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  regionalTotalCapitalFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  regionalTotalCapitalFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  federalInvestorCapitalFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  federalInvestorCapitalFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  regionalInvestorCapitalFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  regionalInvestorCapitalFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  federalNewWorkPlacesFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  federalNewWorkPlacesFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  regionalNewWorkPlacesFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  regionalNewWorkPlacesFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },
  federalCompensationValueFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  federalCompensationValueFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  regionalCompensationValueFederalReportPlanCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  regionalCompensationValueFederalReportFactCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { thirdSectionValidationMap }
