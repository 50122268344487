import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ReactSVG } from 'react-svg'

import Icon from '@components/Icon/Icon'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import { ControlledSingleSelect } from '@components/NewDesign/Select'
import { OptionProps } from '@components/NewDesign/Select/model'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import Filter from '@components/PreparedTooltips/Filter'
import Table from '@components/Table'
import { defaultRHFValidation, Patterns } from '@constants/validations'
import { AuditListProps } from '@context/APIContext/hooks/useOperatorApi'
import { useAudits } from '@hooks/new/swr/useAudits'
import useAuditsDictionaries from '@hooks/new/swr/useAuditsDictionaries'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import FilterIcon from '@icons/action/filter_alt.svg'
import searchIcon from '@icons/action/search.svg'
import {
  formNames,
  initialFilterState,
  initialTableState,
  ROLE_OPTIONS,
  TableFormField,
} from '@routes/Audits/constants'
import cn from 'classnames'

import { getColumnsAuditsTable } from './columns'
import {
  calculateCountOfActiveFilters,
  dataFormToDataParameters,
  getDefaultDateValues,
} from './helpers'
import styles from './Table.module.scss'

interface TableProps {
  formInstance: UseFormReturn<TableFormField>
}

const _Table: FC<TableProps> = ({ formInstance }) => {
  const { control, getValues, reset, trigger, watch, setValue } = formInstance

  const [filterState, setFilterState] = useState<AuditListProps>(initialFilterState)

  const getKey = useCallback(
    (pageIndex: number) => ({
      ...initialTableState,
      ...filterState,
      pageIndex,
      _key: 'auditsTable',
    }),
    [filterState],
  )

  const { auditsDictionaries } = useAuditsDictionaries({
    key: {
      _key: 'auditsDictionaries',
    },
  })

  const { audits, goToNextPage, isLoadingAudits } = useAudits({
    key: getKey,
    pageSize: initialTableState.pageSize,
    config: {
      revalidateFirstPage: false,
    },
  })

  const calculatedCountOfActiveFilters = useMemo(
    () => calculateCountOfActiveFilters(getValues()),
    [filterState, getValues],
  )

  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
  } = useManualTooltipControl()

  const actionTypeOptions = useMemo(
    () =>
      auditsDictionaries
        ? ([
            ...Object.keys(auditsDictionaries).map((value) => ({
              value,
              displayValue: auditsDictionaries[value],
            })),
          ] as OptionProps[])
        : [],
    [auditsDictionaries],
  )

  const columns = useMemo(() => getColumnsAuditsTable(auditsDictionaries), [auditsDictionaries])

  const updateFilterState = useCallback(() => {
    const fields = dataFormToDataParameters(getValues())
    setFilterState(fields)
  }, [getValues])

  const handleResetFilters = useCallback(() => {
    reset()
    setFilterState(initialFilterState)
  }, [reset])

  const handleSearch = useDebouncedCallback(updateFilterState, 500)

  const triggerDate = useCallback(
    (key: typeof formNames.dateFrom.index | typeof formNames.dateTo.index) => {
      const { date } = getValues()[key]

      if (!date) trigger(formNames[key].date)
    },
    [getValues, trigger],
  )

  const formStartDate = watch(formNames.dateFrom.date)
  const formEndDate = watch(formNames.dateTo.date)

  useEffect(() => {
    if (!formStartDate && !formEndDate) return

    const newFormValues = getDefaultDateValues(formStartDate || formEndDate, getValues())

    if (!newFormValues) return

    setValue(formNames.dateFrom.index, newFormValues.dateFrom)
    setValue(formNames.dateTo.index, newFormValues.dateTo)
  }, [formStartDate, formEndDate])

  const filterTooltipContent = useMemo(
    () => (
      <>
        <div className={styles['tooltip__item']}>
          <div className={styles['tooltip__item-date']}>
            <ControlledCalendarInput
              control={control}
              name={formNames.dateFrom.date}
              calendarInputProps={{
                label: 'Начало',
              }}
            />
            <ControlledMaskInput
              control={control}
              name={formNames.dateFrom.time}
              inputProps={{
                view: 'secondary',
                fixWidth: true,
                label: 'Время',
                className: 'max-w-[70px]',
                placeholder: '00:00',
                mask: '99:99',
              }}
              rules={{
                pattern: RegExp(Patterns.time),
                onChange: () => triggerDate(formNames.dateFrom.index),
              }}
            />
            <Typography.Body variant="bodySMedium" className={styles['tooltip__item-date-line']}>
              –
            </Typography.Body>
            <ControlledCalendarInput
              control={control}
              name={formNames.dateTo.date}
              calendarInputProps={{
                label: 'Окончание',
              }}
            />
            <ControlledMaskInput
              control={control}
              name={formNames.dateTo.time}
              inputProps={{
                view: 'secondary',
                fixWidth: true,
                label: 'Время',
                className: 'max-w-[70px]',
                placeholder: '00:00',
                mask: '99:99',
              }}
              rules={{
                pattern: RegExp(Patterns.time),
                onChange: () => triggerDate(formNames.dateTo.index),
              }}
            />
          </div>
          <Typography.Body variant="bodySMedium" className={styles['tooltip__item-date-text']}>
            выберите дату, время начала и окончания события
          </Typography.Body>
        </div>
        <div className={styles['tooltip__item']}>
          <ControlledInput
            name={formNames.userId}
            control={control}
            inputProps={{
              view: 'secondary',
              fixWidth: true,
              label: 'ID пользователя',
              type: 'text',
              size: 'xl',
            }}
          />
        </div>
        <div className={styles['tooltip__item']}>
          <ControlledSingleSelect
            options={actionTypeOptions}
            inputProps={{
              label: 'Тип события',
              fixWidth: true,
            }}
            controllerProps={{
              name: formNames.actionType,
              control: control,
              rules: {
                required: defaultRHFValidation.required,
              },
            }}
          />
        </div>
        <div className={styles['tooltip__item']}>
          <ControlledSingleSelect
            options={ROLE_OPTIONS}
            inputProps={{
              label: 'Тип организации',
              fixWidth: true,
            }}
            controllerProps={{
              name: formNames.organizationRole,
              control: control,
              rules: {
                required: defaultRHFValidation.required,
              },
            }}
          />
        </div>
      </>
    ),
    [actionTypeOptions, control, triggerDate],
  )

  return (
    <>
      <div className={styles.heading}>
        <Typography.Headline variant="headlineH2">События</Typography.Headline>
        <div className={styles.heading__right}>
          <Filter
            filterContent={filterTooltipContent}
            manualControl={{
              tooltipIsOpen,
              handleCloseTooltip,
            }}
            onApplyFilters={updateFilterState}
            onResetFilters={handleResetFilters}
          >
            <Button
              variant="buttonSMedium"
              view="plain"
              className={cn(styles.heading__filterButton, {
                [styles.buttonTransparent_active]: tooltipIsOpen,
              })}
              onClick={handleOpenTooltip}
            >
              <div className={styles.heading__filterBlock}>
                {calculatedCountOfActiveFilters ? (
                  <Typography.Body
                    className={cn(styles.heading__filterNumber, styles.heading__filterBefore)}
                    variant="bodySMedium"
                  >
                    {calculatedCountOfActiveFilters}
                  </Typography.Body>
                ) : (
                  <Icon src={FilterIcon} size="s" className={styles.heading__filterBefore} />
                )}
                Фильтры
              </div>
            </Button>
          </Filter>
          <ControlledInput
            name={formNames.searchString}
            control={control}
            inputProps={{
              view: 'secondary',
              fixWidth: true,
              placeholder: 'Поиск',
              type: 'text',

              rootClassName: styles.heading__Search,
              onChangeCapture: handleSearch,
              leftAddons: <ReactSVG className={styles.heading__SearchIcon} src={searchIcon} />,
            }}
          />
        </div>
      </div>
      <Table
        infinitePagination
        manualSortBy
        manualPagination
        hidePagination
        loading={isLoadingAudits}
        autoResetPage={false}
        showTotalElements={false}
        columns={columns}
        className={styles.table}
        data={audits || []}
        initialState={initialTableState}
        onLoadMore={goToNextPage}
      />
    </>
  )
}

export default _Table
