import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayout from './Layout'
import NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAModalManager from './Manager'
import NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu from './Menu'
import NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAStatementForm from './Statement'

interface NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFC extends FC {
  Layout: typeof NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayout
  Menu: typeof NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu
  StatementForm: typeof NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAStatementForm
  Manager: typeof NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAModalManager
}

const useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAControl = () => {
  const popupManager = usePopupManager()

  const openNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayout,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAModal }
}

const NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA: NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFC =
  () => null

NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA.Menu =
  NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu
NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA.Layout =
  NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayout
NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA.StatementForm =
  NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAStatementForm
NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA.Manager =
  NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAModalManager

export { useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAControl }
export default NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA
