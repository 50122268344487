import type { ReportsFormValues } from '@routes/Reports/types'
import type { IGetReportsParams } from '@services/Monitoring/monitoring.entity'

class FiltersReportsService {
  prepareQueryForApply(filters: IGetReportsParams) {
    return Object.entries(filters)
    .reduce((prevValue, [key, value]) => {
      if (value === undefined) return prevValue

      return {
        ...prevValue,
        [key]: Array.isArray(value) ? JSON.stringify(value) : String(value),
      }
    }, {})
  }

  transformFormFiltersToParams(formFilters: ReportsFormValues) {
    const { filters, typeOfReport } = formFilters

    const preparedIsFederal =
      filters.isFederal && filters.isRegional ? undefined : filters.isFederal

    const preparedStage = filters.stageOfReport !== '' ? filters.stageOfReport : undefined

    const preparedRegions = filters.regions.length ? filters.regions : undefined

    const preparedIndicatorValue = Number(filters?.indicatorValue) || undefined

    const preparedSearch = filters.searchText ? filters.searchText : undefined

    const preparedYear = filters.dateOfReport ? String(filters.dateOfReport) : undefined

    return {
      type: typeOfReport,
      isFederal: preparedIsFederal,
      regions: preparedRegions,
      indicatorValue: preparedIndicatorValue,
      searchText: preparedSearch,
      stage: preparedStage,
      year: preparedYear,
    }
  }
}

export default new FiltersReportsService()
