import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import RegistrationCertificateSubjectItem from '@components/Forms/RegistrationCertificateForm/Forms/4/SubjectsList/SubjectItem'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import {
  RegistrationCertificateFieldArrayControlUpdateWatcher,
  RegistrationCertificateFieldsControlUpdateWatcher,
} from '@components/Forms/RegistrationCertificateForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface RegistrationCertificateSubjectsListProps {
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  subscribableControl: SubscribableFormControlProps<RegistrationCertificateFormValues>
}
const RegistrationCertificateSubjectsList: FC<RegistrationCertificateSubjectsListProps> = ({
  formInstance,
  formModifierInstance,
  subscribableControl,
}) => {
  const { fields: subjects } = useFieldArraySubscribableControl<
    RegistrationCertificateFormValues,
    typeof registrationCertificateBlockValues['4']['subjects']
  >({
    control: formInstance.control,
    name: registrationCertificateBlockValues['4'].subjects,
    watcher: RegistrationCertificateFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    isNew: true,
    formInstance,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
    subscribableControl,
  })

  if (!subjects.length) return null

  return (
    <Row>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: registrationCertificateBlockValues['4'].subjects,
            })}
          >
            {subjects.map((subject, index) => (
              <RegistrationCertificateSubjectItem
                key={subject.id}
                indexOfItem={index}
                formInstance={formInstance}
                formModifierInstance={formModifierInstance}
              />
            ))}
          </SubscribableControl>
        </Stack>
      </Col>
    </Row>
  )
}

export default RegistrationCertificateSubjectsList
