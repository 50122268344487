import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { TGetListAuthorizedOrganizationResponse } from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { SWRIniniteHookProps } from '@interfaces'
import useSWRInfinite from 'swr/infinite'

const useOMSUList = ({
  key,
  config,
}: SWRIniniteHookProps<TGetListAuthorizedOrganizationResponse>) => {
  const {
    authorizedOrganizationsApi: { getListOfAuthorizedOrganizations },
  } = useAPIContext()

  const {
    data: OMSUOrganizationsList,
    isValidating: isLoadingOMSUOrganizationsList,
    error,
    mutate,
    setSize,
  } = useSWRInfinite(key, getListOfAuthorizedOrganizations, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateFirstPage: false,
    ...config,
  })

  const hasMoreData = useMemo(() => {
    return (
      OMSUOrganizationsList && OMSUOrganizationsList[OMSUOrganizationsList.length - 1]?.length >= 29
    )
  }, [OMSUOrganizationsList])

  const preparedMunicipalityList = useMemo(() => {
    return OMSUOrganizationsList?.flat()
  }, [OMSUOrganizationsList])

  return {
    OMSUOrganizationsList: preparedMunicipalityList,
    isLoadingOMSUOrganizationsList,
    updatePage: setSize,
    hasMoreData,
    mutate,
    error,
  }
}

export { useOMSUList }
