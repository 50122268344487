import { FC, useCallback, useRef } from 'react'

import Icon, { IconProps } from '@components/Icon'
import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import { Notification as INotification } from '@components/NewNotifications/model'
import { notificationLevelType } from '@constants/types'
import { NotificationLevelTypes } from '@context/APIContext/hooks/useNotificationsApi'
import { getNotificationDateView } from '@helpers/date/getNotificationDateView'
import { useTimer } from '@hooks/useTimer'
import infoOutlineIcon from '@icons/action/info_outline.svg'
import warningIcon from '@icons/alert/warning.svg'
import logoIcon from '@icons/layout/logoBlue.svg'
import cn from 'classnames'

import styles from './Notifications.module.scss'

type IconPropsMap = Record<NotificationLevelTypes, IconProps>

const ICON_COLOR = '#ffffff' // surface-primary
const LIMIT_NOTIFICATION_TITLE = 29

const iconPropsMap: IconPropsMap = {
  WARNING: {
    src: warningIcon,
    className: cn(styles.Notification__icon, styles['Notification__icon--WARNING']),
    color: ICON_COLOR,
  },
  CRITICAL: {
    src: infoOutlineIcon,
    className: cn(styles.Notification__icon, styles['Notification__icon--CRITICAL']),
    color: ICON_COLOR,
  },
  INFO: {
    noCurrentColorSvgFill: true,
    src: logoIcon,
    className: cn(styles.Notification__icon, styles['Notification__icon--INFO']),
  },
} as const

export const Notification: FC<INotification> = (props) => {
  const {
    className,
    date,
    title,
    description,
    subtitle,
    level,
    iconProps,
    titleDataTestId,
    dateViewDataTestId,
    subtitleDataTestId,
    descriptionDataTestId,
  } = props

  const headerNotificationRef = useRef<HTMLDivElement | null>(null)

  const intervalHandler = useCallback(() => {
    return date ? getNotificationDateView(date) : ''
  }, [date])

  const dateView = useTimer(intervalHandler, 30000)

  const textColor =
    !level || level === notificationLevelType.INFO
      ? 'text-base-primary'
      : 'on-accent-on-surface-primary'

  return (
    <div
      className={cn(
        styles.Notification,
        styles[`Notification--${level || notificationLevelType.INFO}`],
        className,
      )}
    >
      <div ref={headerNotificationRef} className={styles.Header}>
        <Icon
          size={'s'}
          {...(iconProps
            ? { ...iconProps, className: cn(styles.Notification__icon, iconProps.className) }
            : { ...iconPropsMap[level || notificationLevelType.INFO] })}
        />
        {/* «Иконка зависит от типа уведомления, пока делаем уведомления только с иконками» */}
        {title.length > LIMIT_NOTIFICATION_TITLE ? (
          <Tooltip
            useAnchorWidth
            anchor={headerNotificationRef.current}
            offset={[0, 5]}
            preventOverflow={false}
            contentClassName={styles.Notification__tooltip}
            targetClassName={styles['Notification__tooltip-target']}
            content={title}
            position={'top'}
          >
            <Typography color={textColor} variant={'bodyMSemibold'} className={styles.Title} dataTestId={titleDataTestId}>
              {title}
            </Typography>
          </Tooltip>
        ) : (
          <div className={styles['Notification__tooltip-target']}>
            <Typography color={textColor} variant={'bodyMSemibold'} className={styles.Title}>
              {title}
            </Typography>
          </div>
        )}
        <Typography color={textColor} variant={'captionSRegular'} className={styles.Date} dataTestId={dateViewDataTestId}>
          {dateView}
        </Typography>
      </div>
      {subtitle && (
        <Typography
          color={textColor}
          variant={'captionMMedium'}
          className={styles.Subtitle}
          dataTestId={subtitleDataTestId}
        >
          {subtitle}
        </Typography>
      )}
      <Typography
        color={textColor}
        variant={'bodyMMedium'}
        className={styles.Description}
        dataTestId={descriptionDataTestId}
      >
        {description}
      </Typography>
    </div>
  )
}
