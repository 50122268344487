import { createContext, FC, useCallback, useContext, useMemo } from 'react'

import { useChangesMadeRequests } from '@components/Projects/[id]/DocumentLayout/ChangesMade/hooks/useChangesMadeRequests'
import { useShowAllDocumentsSwitch } from '@components/Projects/[id]/DocumentLayout/ChangesMade/hooks/useShowAllDocumentsSwitch'
import { DEFAULT_SYSTEM_VERSION } from '@services/Dfo/Dfo.const'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'
import { IModifiedDocumentSetInfoForRenderToArray } from '@services/Documents/documents.entity'

import { useTabs } from './hooks/useTabs'
import { IChangesMadeContext } from './model'

interface ChangesMadeContextProviderProps {
  currentDfoInfo: IDfoListItem | null
}

const ChangesMadeContext = createContext<IChangesMadeContext>({} as IChangesMadeContext)

export const ChangesMadeContextProvider: FC<ChangesMadeContextProviderProps> = ({
  currentDfoInfo,
  children,
}) => {
  const { documentsSetsDict, mapOfSetsToRender, updateDocumentSets } =
    useChangesMadeRequests(currentDfoInfo)
  const switchState = useShowAllDocumentsSwitch()

  const isDocumentForRevision = useCallback(
    (props?: IModifiedDocumentSetInfoForRenderToArray) => {
      if (!props || !currentDfoInfo || !currentDfoInfo.type) return false

      const currentDocumentSetsDict =
        documentsSetsDict?.[currentDfoInfo.systemVersion ?? DEFAULT_SYSTEM_VERSION]

      return !!currentDocumentSetsDict?.[currentDfoInfo.type]?.[props.documentSetsType]
        ?.documentSetForRevision
    },
    [currentDfoInfo, documentsSetsDict],
  )

  const tabsInfo = useTabs(documentsSetsDict, mapOfSetsToRender, isDocumentForRevision)

  const valueToReturn = useMemo(
    () => ({ ...tabsInfo, isDocumentForRevision, ...switchState, updateDocumentSets }),
    [isDocumentForRevision, switchState, tabsInfo, updateDocumentSets],
  )

  return <ChangesMadeContext.Provider value={valueToReturn}>{children}</ChangesMadeContext.Provider>
}

export const useChangesMadeContext = () => useContext(ChangesMadeContext)
