import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForProjectSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedProjectResults = {
    propertyId: objectFromPropertyState.projectResults?.propertyId,
    type: objectFromPropertyState.projectResults?.type,
    lastUpdateDt: objectFromPropertyState.projectResults?.lastUpdateDt,
    value: isArray(objectFromPropertyState.projectResults?.value)
      ? objectFromPropertyState.projectResults?.value.map((item) => {
          return {
            propertyId: item.propertyId,
            type: item.type,
            lastUpdateDt: item.lastUpdateDt,
            value: {
              projectResultName: {
                propertyId: item.value.projectResultName?.propertyId,
                type: item.value.projectResultName?.type,
                lastUpdateDt: item.value.projectResultName?.lastUpdateDt,
              },
              isStabilizationResult: {
                propertyId: item.value.isStabilizationResult?.propertyId,
                type: item.value.isStabilizationResult?.type,
                lastUpdateDt: item.value.isStabilizationResult?.lastUpdateDt,
              },
              projectResultCodeTnVed: {
                propertyId: item.value.projectResultCodeTnVed?.propertyId,
                type: item.value.projectResultCodeTnVed?.type,
                lastUpdateDt: item.value.projectResultCodeTnVed?.lastUpdateDt,
              },
              volumeOfProduction: {
                propertyId: item.value.projectResultValues?.propertyId,
                type: item.value.projectResultValues?.type,
                lastUpdateDt: item.value.projectResultValues?.lastUpdateDt,
                value: isArray(item.value.projectResultValues?.value)
                  ? item.value.projectResultValues?.value
                      .sort(
                        (projectResultA, projectResultB) =>
                          projectResultA.order - projectResultB.order,
                      )
                      .map((resultValue) => ({
                        propertyId: resultValue.propertyId,
                        type: resultValue.type,
                        lastUpdateDt: resultValue.lastUpdateDt,
                        value: {
                          projectResultYear: {
                            propertyId: resultValue.value.projectResultYear?.propertyId,
                            type: resultValue.value.projectResultYear?.type,
                            lastUpdateDt: resultValue.value.projectResultYear?.lastUpdateDt,
                          },
                          projectResultValue: {
                            propertyId: resultValue.value.projectResultYearValue?.propertyId,
                            type: resultValue.value.projectResultYearValue?.type,
                            lastUpdateDt: resultValue.value.projectResultYearValue?.lastUpdateDt,
                          },
                          projectResultUnit: {
                            propertyId: resultValue.value.projectResultValueUnit?.propertyId,
                            type: resultValue.value.projectResultValueUnit?.type,
                            lastUpdateDt: resultValue.value.projectResultValueUnit?.lastUpdateDt,
                          },
                        },
                      }))
                  : [],
              },
            },
          }
        })
      : [],
  }

  const getPreparedObjectRegions = (objectRegions: any[]) => {
    if (!isArray(objectRegions) || !objectRegions.length) return []

    return objectRegions.map((objectRegion) => ({
      propertyId: objectRegion?.propertyId,
      lastUpdateDt: objectRegion?.lastUpdateDt,
      type: objectRegion?.type,
      value: {
        objectRegion: {
          propertyId: objectRegion?.value?.objectRegionCode?.propertyId,
          lastUpdateDt: objectRegion?.value?.objectRegionCode?.lastUpdateDt,
          type: objectRegion?.value?.objectRegionCode?.type,
        },
      },
    }))
  }

  const preparedStepActivityOthers = isArray(objectFromPropertyState.steps?.value)
    ? objectFromPropertyState.steps?.value.map((step) => {
        const preparedValueForStepActivityOther = {
          propertyId: step.value.stepActivityOthers?.propertyId,
          lastUpdateDt: step.value.stepActivityOthers?.lastUpdateDt,
          type: step.value.stepActivityOthers?.type,
          value: step.value.stepActivityOthers?.value.map((stepActivity) => {
            const preparedMeasureObjects = step.value.objects?.value
              .filter((object) => {
                return object.value.objectOtherActivityName?.value?.id === stepActivity.permanentId
              })
              .map((object) => {
                const preparedObjectAreas =
                  isArray(object.value?.objectAreas?.value) &&
                  object.value?.objectAreas?.value?.length
                    ? object.value?.objectAreas?.value.map((objectArea) => ({
                        propertyId: objectArea?.propertyId,
                        type: objectArea?.type,
                        lastUpdateDt: objectArea?.lastUpdateDt,
                        value: {
                          objectAreaNumber: {
                            propertyId: objectArea.value?.objectAreaNumber?.propertyId,
                            type: objectArea.value?.objectAreaNumber?.type,
                            lastUpdateDt: objectArea.value?.objectAreaNumber?.lastUpdateDt,
                          },
                          objectAreaSquare: {
                            propertyId: objectArea.value?.objectAreaSquare?.propertyId,
                            type: objectArea.value?.objectAreaSquare?.type,
                            lastUpdateDt: objectArea.value?.objectAreaSquare?.lastUpdateDt,
                          },
                        },
                      }))
                    : []

                return {
                  propertyId: object.propertyId,
                  type: object.type,
                  lastUpdateDt: object.lastUpdateDt,
                  value: {
                    objectName: {
                      propertyId: object.value.objectName?.propertyId,
                      type: object.value.objectName?.type,
                      lastUpdateDt: object.value.objectName?.lastUpdateDt,
                    },
                    objectRegions: {
                      propertyId: object.value.objectRegions?.propertyId,
                      type: object.value.objectRegions?.type,
                      lastUpdateDt: object.value.objectRegions?.lastUpdateDt,
                      value: getPreparedObjectRegions(object.value?.objectRegions?.value),
                    },
                    objectAreas: {
                      propertyId: object.value.objectAreas?.propertyId,
                      type: object.value.objectAreas?.type,
                      lastUpdateDt: object.value.objectAreas?.lastUpdateDt,
                      value: preparedObjectAreas,
                    },
                    objectConstructionKind: {
                      propertyId: object.value?.objectConstructionKind?.propertyId,
                      type: object.value?.objectConstructionKind?.type,
                      lastUpdateDt: object.value?.objectConstructionKind?.lastUpdateDt,
                    },
                    objectPurpose: {
                      propertyId: object.value?.objectPurpose?.propertyId,
                      type: object.value?.objectPurpose?.type,
                      lastUpdateDt: object.value?.objectPurpose?.lastUpdateDt,
                    },
                    objectConstructionSquare: {
                      propertyId: object.value?.objectConstructionSquare?.propertyId,
                      type: object.value?.objectConstructionSquare?.type,
                      lastUpdateDt: object.value?.objectConstructionSquare?.lastUpdateDt,
                    },
                    objectSquare: {
                      propertyId: object.value.objectSquare?.propertyId,
                      type: object.value.objectSquare?.type,
                      lastUpdateDt: object.value.objectSquare?.lastUpdateDt,
                    },
                    objectNonLivingAreaSquare: {
                      propertyId: object.value?.objectNonLivingAreaSquare?.propertyId,
                      type: object.value?.objectNonLivingAreaSquare?.type,
                      lastUpdateDt: object.value?.objectNonLivingAreaSquare?.lastUpdateDt,
                    },
                    objectLivingAreaSquare: {
                      propertyId: object.value?.objectLivingAreaSquare?.propertyId,
                      type: object.value?.objectLivingAreaSquare?.type,
                      lastUpdateDt: object.value?.objectLivingAreaSquare?.lastUpdateDt,
                    },
                    objectFloor: {
                      propertyId: object.value.objectFloor?.propertyId,
                      type: object.value.objectFloor?.type,
                      lastUpdateDt: object.value.objectFloor?.lastUpdateDt,
                    },
                    objectUndergroundFloor: {
                      propertyId: object.value?.objectUndergroundFloor?.propertyId,
                      type: object.value?.objectUndergroundFloor?.type,
                      lastUpdateDt: object.value?.objectUndergroundFloor?.lastUpdateDt,
                    },
                    objectHeight: {
                      propertyId: object.value?.objectHeight?.propertyId,
                      type: object.value?.objectHeight?.type,
                      lastUpdateDt: object.value?.objectHeight?.lastUpdateDt,
                    },
                    objectDetails: {
                      propertyId: object.value.objectDetails?.propertyId,
                      type: object.value.objectDetails?.type,
                      lastUpdateDt: object.value.objectDetails?.lastUpdateDt,
                    },
                    objectIsInfrastructure: {
                      propertyId: object.value.isInfrastructure?.propertyId,
                      type: object.value.isInfrastructure?.type,
                      lastUpdateDt: object.value.isInfrastructure?.lastUpdateDt,
                    },
                    objectInfrastructureType: {
                      propertyId: object.value.infrastructureType?.propertyId,
                      type: object.value.infrastructureType?.type,
                      lastUpdateDt: object.value.infrastructureType?.lastUpdateDt,
                    },
                  },
                }
              })

            return {
              propertyId: stepActivity.propertyId,
              permanentId: stepActivity.permanentId,
              type: stepActivity.type,
              lastUpdateDt: stepActivity.lastUpdateDt,
              value: {
                otherActivityName: {
                  propertyId: stepActivity.value.otherActivityName?.propertyId,
                  type: stepActivity.value.otherActivityName?.type,
                  lastUpdateDt: stepActivity.value.otherActivityName?.lastUpdateDt,
                },
                otherActivityEndDate: {
                  propertyId: stepActivity.value.otherActivityEndDate?.propertyId,
                  type: stepActivity.value.otherActivityEndDate?.type,
                  lastUpdateDt: stepActivity.value.otherActivityEndDate?.lastUpdateDt,
                },
                isOtherActivityInfrastructure: {
                  propertyId: stepActivity.value.isOtherActivityInfrastructure?.propertyId,
                  type: stepActivity.value.isOtherActivityInfrastructure?.type,
                  lastUpdateDt: stepActivity.value.isOtherActivityInfrastructure?.lastUpdateDt,
                },
                otherActivityInfrastructureTypeName: {
                  propertyId: stepActivity.value.otherActivityInfrastructureType?.propertyId,
                  type: stepActivity.value.otherActivityInfrastructureType?.type,
                  lastUpdateDt: stepActivity.value.otherActivityInfrastructureType?.lastUpdateDt,
                },
                stepNumber: {
                  propertyId: stepActivity.propertyId,
                  type: stepActivity.type,
                  lastUpdateDt: stepActivity.lastUpdateDt,
                },
                measureObjects: {
                  propertyId: step.value.objects?.propertyId,
                  type: step.value.objects?.type,
                  lastUpdateDt: step.value.objects?.lastUpdateDt,
                  value: preparedMeasureObjects,
                },
              },
            }
          }),
        }

        return {
          propertyId: step.value.stepActivityOthers?.propertyId,
          type: step.value.stepActivityOthers?.type,
          lastUpdateDt: step.value.stepActivityOthers?.lastUpdateDt,
          value: {
            activityOthers: preparedValueForStepActivityOther,
          },
        }
      })
    : []

  const preparedEventOfStages = (() => {
    return {
      value: objectFromPropertyState.steps?.value.map((step) => {
        const checkboxes = (() => {
          const updateValueForCheckbox = {
            propertyId: step.value.stepActivityNames?.propertyId,
            type: step.value.stepActivityNames?.type,
            lastUpdateDt: step.value.stepActivityNames?.lastUpdateDt,
          }

          const chosenCheckboxes = {
            isConstruction: updateValueForCheckbox,
            isReconstruction: updateValueForCheckbox,
            isModernization: updateValueForCheckbox,
            isCreateRID: updateValueForCheckbox,
          }

          return chosenCheckboxes
        })()

        const preparedConstructionObjects = (() => {
          const getPreparedObjectRegions = (objectRegions: any[]) => {
            if (!isArray(objectRegions) || !objectRegions.length) return []

            return objectRegions.map((objectRegion) => ({
              propertyId: objectRegion?.propertyId,
              lastUpdateDt: objectRegion?.lastUpdateDt,
              type: objectRegion?.type,
              value: {
                objectRegion: {
                  propertyId: objectRegion?.value.objectRegionCode?.propertyId,
                  lastUpdateDt: objectRegion?.value.objectRegionCode?.lastUpdateDt,
                  type: objectRegion?.value.objectRegionCode?.type,
                },
              },
            }))
          }

          const objects = step.value.objects?.value.filter((object) => {
            return (
              object.value.isMainObject?.value &&
              !object.value.isSubObject?.value &&
              !object.value.objectOtherActivityName?.value?.id
            )
          })

          const subObjects = step.value.objects?.value.filter((object) => {
            return (
              object.value.isSubObject?.value &&
              !object.value.objectOtherActivityName?.value?.id &&
              object.value.parentSubObject?.value
            )
          })

          const preparedConstructionObjects = objects?.map((object) => {
            const preparedSubObjects = subObjects
              .filter((subObject) => {
                return object.permanentId === subObject.value.parentSubObject?.value?.id
              })
              .map((subObject) => {
                return {
                  propertyId: subObject.propertyId,
                  type: subObject.type,
                  lastUpdateDt: subObject.lastUpdateDt,
                  value: {
                    objectName: {
                      propertyId: subObject.value.objectName?.propertyId,
                      type: subObject.value.objectName?.type,
                      lastUpdateDt: subObject.value.objectName?.lastUpdateDt,
                    },
                    objectRegions: {
                      propertyId: subObject.value.objectRegions?.propertyId,
                      type: subObject.value.objectRegions?.type,
                      lastUpdateDt: subObject.value.objectRegions?.lastUpdateDt,
                      value: getPreparedObjectRegions(subObject.value.objectRegions?.value),
                    },
                    objectConstructionKind: {
                      propertyId: subObject.value.objectConstructionKind?.propertyId,
                      type: subObject.value.objectConstructionKind?.type,
                      lastUpdateDt: subObject.value.objectConstructionKind?.lastUpdateDt,
                    },
                    objectPurpose: {
                      propertyId: subObject.value.objectPurpose?.propertyId,
                      type: subObject.value.objectPurpose?.type,
                      lastUpdateDt: subObject.value.objectPurpose?.lastUpdateDt,
                    },
                    objectConstructionSquare: {
                      propertyId: subObject.value.objectConstructionSquare?.propertyId,
                      type: subObject.value.objectConstructionSquare?.type,
                      lastUpdateDt: subObject.value.objectConstructionSquare?.lastUpdateDt,
                    },
                    objectSquare: {
                      propertyId: subObject.value.objectSquare?.propertyId,
                      type: subObject.value.objectSquare?.type,
                      lastUpdateDt: subObject.value.objectSquare?.lastUpdateDt,
                    },
                    objectNonLivingAreaSquare: {
                      propertyId: subObject.value.objectNonLivingAreaSquare?.propertyId,
                      type: subObject.value.objectNonLivingAreaSquare?.type,
                      lastUpdateDt: subObject.value.objectNonLivingAreaSquare?.lastUpdateDt,
                    },
                    objectLivingAreaSquare: {
                      propertyId: subObject.value.objectLivingAreaSquare?.propertyId,
                      type: subObject.value.objectLivingAreaSquare?.type,
                      lastUpdateDt: subObject.value.objectLivingAreaSquare?.lastUpdateDt,
                    },
                    objectFloor: {
                      propertyId: subObject.value.objectFloor?.propertyId,
                      type: subObject.value.objectFloor?.type,
                      lastUpdateDt: subObject.value.objectFloor?.lastUpdateDt,
                    },
                    objectUndergroundFloor: {
                      propertyId: subObject.value.objectUndergroundFloor?.propertyId,
                      type: subObject.value.objectUndergroundFloor?.type,
                      lastUpdateDt: subObject.value.objectUndergroundFloor?.lastUpdateDt,
                    },
                    objectHeight: {
                      propertyId: subObject.value.objectHeight?.propertyId,
                      type: subObject.value.objectHeight?.type,
                      lastUpdateDt: subObject.value.objectHeight?.lastUpdateDt,
                    },
                    objectDetails: {
                      propertyId: subObject.value.objectDetails?.propertyId,
                      type: subObject.value.objectDetails?.type,
                      lastUpdateDt: subObject.value.objectDetails?.lastUpdateDt,
                    },
                    objectIsInfrastructure: {
                      propertyId: subObject.value.isInfrastructure?.propertyId,
                      type: subObject.value.isInfrastructure?.type,
                      lastUpdateDt: subObject.value.isInfrastructure?.lastUpdateDt,
                    },
                    objectInfrastructureType: {
                      propertyId: subObject.value.infrastructureType?.propertyId,
                      type: subObject.value.infrastructureType?.type,
                      lastUpdateDt: subObject.value.infrastructureType?.lastUpdateDt,
                    },
                  },
                }
              })

            const preparedObjectAreas =
              isArray(object.value?.objectAreas?.value) && object.value?.objectAreas?.value?.length
                ? object.value?.objectAreas?.value.map((objectArea) => ({
                    propertyId: objectArea?.propertyId,
                    type: objectArea?.type,
                    lastUpdateDt: objectArea?.lastUpdateDt,
                    value: {
                      objectAreaNumber: {
                        propertyId: objectArea.value.objectAreaNumber?.propertyId,
                        type: objectArea.value.objectAreaNumber?.type,
                        lastUpdateDt: objectArea.value.objectAreaNumber?.lastUpdateDt,
                      },
                      objectAreaSquare: {
                        propertyId: objectArea.value.objectAreaSquare?.propertyId,
                        type: objectArea.value.objectAreaSquare?.type,
                        lastUpdateDt: objectArea.value.objectAreaSquare?.lastUpdateDt,
                      },
                    },
                  }))
                : []

            return {
              propertyId: object.propertyId,
              type: object.type,
              lastUpdateDt: object.lastUpdateDt,
              value: {
                objectName: {
                  propertyId: object.value.objectName?.propertyId,
                  type: object.value.objectName?.type,
                  lastUpdateDt: object.value.objectName?.lastUpdateDt,
                },
                objectRegions: {
                  propertyId: object.value.objectRegions?.propertyId,
                  type: object.value.objectRegions?.type,
                  lastUpdateDt: object.value.objectRegions?.lastUpdateDt,
                  value: getPreparedObjectRegions(object.value?.objectRegions?.value),
                },
                objectAreas: {
                  propertyId: object.value.objectAreas?.propertyId,
                  type: object.value.objectAreas?.type,
                  lastUpdateDt: object.value.objectAreas?.lastUpdateDt,
                  value: preparedObjectAreas,
                },
                objectConstructionKind: {
                  propertyId: object.value.objectConstructionKind?.propertyId,
                  type: object.value.objectConstructionKind?.type,
                  lastUpdateDt: object.value.objectConstructionKind?.lastUpdateDt,
                },
                objectPurpose: {
                  propertyId: object.value.objectPurpose?.propertyId,
                  type: object.value.objectPurpose?.type,
                  lastUpdateDt: object.value.objectPurpose?.lastUpdateDt,
                },
                objectConstructionSquare: {
                  propertyId: object.value.objectConstructionSquare?.propertyId,
                  type: object.value.objectConstructionSquare?.type,
                  lastUpdateDt: object.value.objectConstructionSquare?.lastUpdateDt,
                },
                objectSquare: {
                  propertyId: object.value.objectSquare?.propertyId,
                  type: object.value.objectSquare?.type,
                  lastUpdateDt: object.value.objectSquare?.lastUpdateDt,
                },
                objectNonLivingAreaSquare: {
                  propertyId: object.value.objectNonLivingAreaSquare?.propertyId,
                  type: object.value.objectNonLivingAreaSquare?.type,
                  lastUpdateDt: object.value.objectNonLivingAreaSquare?.lastUpdateDt,
                },
                objectLivingAreaSquare: {
                  propertyId: object.value.objectLivingAreaSquare?.propertyId,
                  type: object.value.objectLivingAreaSquare?.type,
                  lastUpdateDt: object.value.objectLivingAreaSquare?.lastUpdateDt,
                },
                objectFloor: {
                  propertyId: object.value.objectFloor?.propertyId,
                  type: object.value.objectFloor?.type,
                  lastUpdateDt: object.value.objectFloor?.lastUpdateDt,
                },
                objectUndergroundFloor: {
                  propertyId: object.value.objectUndergroundFloor?.propertyId,
                  type: object.value.objectUndergroundFloor?.type,
                  lastUpdateDt: object.value.objectUndergroundFloor?.lastUpdateDt,
                },
                objectHeight: {
                  propertyId: object.value.objectHeight?.propertyId,
                  type: object.value.objectHeight?.type,
                  lastUpdateDt: object.value.objectHeight?.lastUpdateDt,
                },
                objectDetails: {
                  propertyId: object.value.objectDetails?.propertyId,
                  type: object.value.objectDetails?.type,
                  lastUpdateDt: object.value.objectDetails?.lastUpdateDt,
                },
                objectIsInfrastructure: {
                  propertyId: object.value.isInfrastructure?.propertyId,
                  type: object.value.isInfrastructure?.type,
                  lastUpdateDt: object.value.isInfrastructure?.lastUpdateDt,
                },
                objectInfrastructureType: {
                  propertyId: object.value.infrastructureType?.propertyId,
                  type: object.value.infrastructureType?.type,
                  lastUpdateDt: object.value.infrastructureType?.lastUpdateDt,
                },
                subObjects: {
                  propertyId: step.value.objects?.propertyId,
                  type: step.value.objects?.type,
                  lastUpdateDt: step.value.objects?.lastUpdateDt,
                  value: preparedSubObjects,
                },
              },
            }
          })

          return {
            propertyId: step.value.objects?.propertyId,
            lastUpdateDt: step.value.objects?.lastUpdateDt,
            type: step.value.objects?.type,
            value: preparedConstructionObjects,
          }
        })()

        const preparedPermissions = (() => {
          const preparedValueForPermissions =
            !step.value.permissions?.value || !step.value.permissions?.value.length
              ? []
              : step.value.permissions?.value.map((permission) => {
                  const preparedObjectNamePermissions = (() => {
                    if (
                      !permission.value.permissionObjects?.value ||
                      !permission.value.permissionObjects?.value.length
                    )
                      return []

                    return permission.value.permissionObjects?.value?.map((permissionObject) => ({
                      propertyId: permissionObject.propertyId,
                      lastUpdateDt: permissionObject.lastUpdateDt,
                      type: permissionObject.type,
                      value: {
                        objectOption: {
                          propertyId: permissionObject.propertyId,
                          lastUpdateDt: permissionObject.lastUpdateDt,
                          type: permissionObject.type,
                        },
                      },
                    }))
                  })()

                  return {
                    propertyId: permission.propertyId,
                    type: permission.type,
                    lastUpdateDt: permission.lastUpdateDt,
                    value: {
                      permissionKind: {
                        propertyId: permission.value.permissionKind?.propertyId,
                        type: permission.value.permissionKind?.type,
                        lastUpdateDt: permission.value.permissionKind?.lastUpdateDt,
                      },
                      permissionOrganization: {
                        propertyId: permission.value.permissionOrganization?.propertyId,
                        type: permission.value.permissionOrganization?.type,
                        lastUpdateDt: permission.value.permissionOrganization?.lastUpdateDt,
                      },
                      objectNamePermissions: {
                        propertyId: permission.value.permissionObjects?.propertyId,
                        type: permission.value.permissionObjects?.type,
                        lastUpdateDt: permission.value.permissionObjects?.lastUpdateDt,
                        value: preparedObjectNamePermissions,
                      },
                      isReceivedPermission: {
                        propertyId: permission.value.isReceivedPermission?.propertyId,
                        type: permission.value.isReceivedPermission?.type,
                        lastUpdateDt: permission.value.isReceivedPermission?.lastUpdateDt,
                      },
                      permissionDate: {
                        propertyId: permission.value.permissionDate?.propertyId,
                        type: permission.value.permissionDate?.type,
                        lastUpdateDt: permission.value.permissionDate?.lastUpdateDt,
                      },
                      permissionNumber: {
                        propertyId: permission.value.permissionNumber?.propertyId,
                        type: permission.value.permissionNumber?.type,
                        lastUpdateDt: permission.value.permissionNumber?.lastUpdateDt,
                      },
                      receivedPermissionDate: {
                        propertyId: permission.value.receivedPermissionDate?.propertyId,
                        type: permission.value.receivedPermissionDate?.type,
                        lastUpdateDt: permission.value.receivedPermissionDate?.lastUpdateDt,
                      },
                    },
                  }
                })

          return {
            propertyId: step.value.permissions?.propertyId,
            type: step.value.permissions?.type,
            lastUpdateDt: step.value.permissions?.lastUpdateDt,
            value: preparedValueForPermissions,
          }
        })()

        const preparedExploitationObjects = (() => {
          const exploitationObjects = (() => {
            if (
              !step.value.commissionObjects?.value ||
              !step.value?.commissionObjects?.value.length
            )
              return []

            return step.value.commissionObjects.value.map((exploitation) => ({
              propertyId: exploitation.propertyId,
              lastUpdateDt: exploitation.lastUpdateDt,
              type: exploitation.type,
              value: {
                objectName: {
                  propertyId: exploitation.value.commissionObjectId?.propertyId,
                  lastUpdateDt: exploitation.value.commissionObjectId?.lastUpdateDt,
                  type: exploitation.value.commissionObjectId?.type,
                },
                objectCommissioningDate: {
                  propertyId: exploitation.value.commissionObjectDate?.propertyId,
                  lastUpdateDt: exploitation.value.commissionObjectDate?.lastUpdateDt,
                  type: exploitation.value.commissionObjectDate?.type,
                },
                objectPermissionCommissionNumber: {
                  propertyId: exploitation.value.commissionPermissionNumber?.propertyId,
                  lastUpdateDt: exploitation.value.commissionPermissionNumber?.lastUpdateDt,
                  type: exploitation.value.commissionPermissionNumber?.type,
                },
              },
            }))
          })()

          return {
            propertyId: step.value.commissionObjects?.propertyId,
            lastUpdateDt: step.value.commissionObjects?.lastUpdateDt,
            type: step.value.commissionObjects?.type,
            value: exploitationObjects,
          }
        })()

        const preparedRegistrationObjects = (() => {
          const registrationObjects = (() => {
            if (
              !step.value.registrationObjects?.value ||
              !step.value.registrationObjects.value.length
            )
              return []

            return step.value.registrationObjects.value.map((registration) => ({
              propertyId: registration.propertyId,
              lastUpdateDt: registration.lastUpdateDt,
              type: registration.type,
              value: {
                objectName: {
                  propertyId: registration.value.registrationObjectId?.propertyId,
                  lastUpdateDt: registration.value.registrationObjectId?.lastUpdateDt,
                  type: registration.value.registrationObjectId?.type,
                },
                objectType: {
                  propertyId: registration.value.stateRegistrationType?.propertyId,
                  lastUpdateDt: registration.value.stateRegistrationType?.lastUpdateDt,
                  type: registration.value.stateRegistrationType?.type,
                },
                estateObjectStateRegistrationDate: {
                  propertyId: registration.value.objectStateRegistrationDate?.propertyId,
                  lastUpdateDt: registration.value.objectStateRegistrationDate?.lastUpdateDt,
                  type: registration.value.objectStateRegistrationDate?.type,
                },
                estateObjectStateRegistrationNumber: {
                  propertyId: registration.value.objectStateRegistrationNumber?.propertyId,
                  lastUpdateDt: registration.value.objectStateRegistrationNumber?.lastUpdateDt,
                  type: registration.value.objectStateRegistrationNumber?.type,
                },
                estateObjectStateRegistrationOrganization: {
                  propertyId: registration.value.objectStateRegistrationOrganization?.propertyId,
                  lastUpdateDt:
                    registration.value.objectStateRegistrationOrganization?.lastUpdateDt,
                  type: registration.value.objectStateRegistrationOrganization?.type,
                },
              },
            }))
          })()

          return {
            propertyId: step.value.registrationObjects?.propertyId,
            lastUpdateDt: step.value.registrationObjects?.lastUpdateDt,
            type: step.value.registrationObjects?.type,
            value: registrationObjects,
          }
        })()

        const preparedRidExploitationObjects = (() => {
          const exploitationRids = (() => {
            if (!step.value.commissionRids?.value || !step.value.commissionRids.value.length)
              return []

            return step.value.commissionRids.value.map((exploitation) => ({
              propertyId: exploitation.propertyId,
              lastUpdateDt: exploitation.lastUpdateDt,
              type: exploitation.type,
              value: {
                ridName: {
                  propertyId: exploitation.value.commissionRidId?.propertyId,
                  lastUpdateDt: exploitation.value.commissionRidId?.lastUpdateDt,
                  type: exploitation.value.commissionRidId?.type,
                },
                ridCommissionNumber: {
                  propertyId: exploitation.value.ridCommissionNumber?.propertyId,
                  lastUpdateDt: exploitation.value.ridCommissionNumber?.lastUpdateDt,
                  type: exploitation.value.ridCommissionNumber?.type,
                },
                ridCommissioningDate: {
                  propertyId: exploitation.value.ridCommissioningDate?.propertyId,
                  lastUpdateDt: exploitation.value.ridCommissioningDate?.lastUpdateDt,
                  type: exploitation.value.ridCommissioningDate?.type,
                },
              },
            }))
          })()

          return {
            propertyId: step.value.commissionRids?.propertyId,
            lastUpdateDt: step.value.commissionRids?.lastUpdateDt,
            type: step.value.commissionRids?.type,
            value: exploitationRids,
          }
        })()

        const preparedRidRegistrationObjects = (() => {
          const registrationRids = (() => {
            if (!step.value.registrationRids?.value || !step.value.registrationRids?.value.length)
              return []

            return step.value.registrationRids?.value.map((registration) => ({
              propertyId: registration.propertyId,
              lastUpdateDt: registration.lastUpdateDt,
              type: registration.type,
              value: {
                ridName: {
                  propertyId: registration.value.registrationRidId?.propertyId,
                  lastUpdateDt: registration.value.registrationRidId?.lastUpdateDt,
                  type: registration.value.registrationRidId?.type,
                },
                ridStateRegistrationNumber: {
                  propertyId: registration.value.ridStateRegistrationNumber?.propertyId,
                  lastUpdateDt: registration.value.ridStateRegistrationNumber?.lastUpdateDt,
                  type: registration.value.ridStateRegistrationNumber?.type,
                },
                ridStateRegistrationOrganization: {
                  propertyId: registration.value.ridStateRegistrationOrganization?.propertyId,
                  lastUpdateDt: registration.value.ridStateRegistrationOrganization?.lastUpdateDt,
                  type: registration.value.ridStateRegistrationOrganization?.type,
                },
              },
            }))
          })()

          return {
            propertyId: step.value.registrationRids?.propertyId,
            lastUpdateDt: step.value.registrationRids?.lastUpdateDt,
            type: step.value.registrationRids?.type,
            value: registrationRids,
          }
        })()

        const preparedRidNameRegistrations = (() => {
          if (
            !step.value.ridStateRegistrationIds?.value ||
            !step.value.ridStateRegistrationIds.value.length
          )
            return []

          return step.value.ridStateRegistrationIds?.value.map((registrationRid) => ({
            propertyId: registrationRid.propertyId,
            lastUpdateDt: registrationRid.lastUpdateDt,
            type: registrationRid.type,
            value: {
              ridOption: {
                propertyId: registrationRid.propertyId,
                lastUpdateDt: registrationRid.lastUpdateDt,
                type: registrationRid.type,
              },
            },
          }))
        })()

        return {
          value: {
            tabName: {
              propertyId: step.value.stepDescription?.propertyId,
              type: step.value.stepDescription?.type,
              lastUpdateDt: step.value.stepDescription?.lastUpdateDt,
            },

            ...checkboxes,
            //Фейковое свойство
            stepActivityNames: {
              propertyId: step.value.stepActivityNames?.propertyId,
              type: step.value.stepActivityNames?.type,
              lastUpdateDt: step.value.stepActivityNames?.lastUpdateDt,
              value: step.value.stepActivityNames?.value.map((name) => ({
                propertyId: name.propertyId,
                type: name?.type,
                lastUpdateDt: name?.lastUpdateDt,
              })),
            },
            constructionStage: {
              propertyId: step.propertyId,
              lastUpdateDt: step.lastUpdateDt,
              type: step.type,
              value: {
                constructionObjects: preparedConstructionObjects,
                permissionsObjects: preparedPermissions,

                exploitation: {
                  propertyId: step.propertyId,
                  lastUpdateDt: step.lastUpdateDt,
                  type: step.type,
                  value: {
                    objectCommissioningEndDate: {
                      propertyId: step.value.objectCommissioningEndDate?.propertyId,
                      type: step.value.objectCommissioningEndDate?.type,
                      lastUpdateDt: step.value.objectCommissioningEndDate?.lastUpdateDt,
                    },

                    exploitationObjects: preparedExploitationObjects,
                  },
                },

                registration: {
                  propertyId: step.propertyId,
                  lastUpdateDt: step.lastUpdateDt,
                  type: step.type,
                  value: {
                    typeObjectStateRegistration: {
                      propertyId: step.value.typeObjectStateRegistration?.propertyId,
                      type: step.value.typeObjectStateRegistration?.type,
                      lastUpdateDt: step.value.typeObjectStateRegistration?.lastUpdateDt,
                    },
                    objectStateRegistrationEndDate: {
                      propertyId: step.value.objectStateRegistrationEndDate?.propertyId,
                      type: step.value.objectStateRegistrationEndDate?.type,
                      lastUpdateDt: step.value.objectStateRegistrationEndDate?.lastUpdateDt,
                    },

                    registrationObjects: preparedRegistrationObjects,
                  },
                },
              },
            },
            ridStage: {
              propertyId: step.propertyId,
              lastUpdateDt: step.lastUpdateDt,
              type: step.type,
              value: {
                ridObjects: {
                  propertyId: step.value.rids?.propertyId,
                  type: step.value.rids?.type,
                  lastUpdateDt: step.value.rids?.lastUpdateDt,
                  value: step.value.rids?.value.map((rid) => ({
                    propertyId: rid.propertyId,
                    type: rid.type,
                    lastUpdateDt: rid.lastUpdateDt,
                    value: {
                      ridKind: {
                        propertyId: rid.value.ridKind?.propertyId,
                        type: rid.value.ridKind?.type,
                        lastUpdateDt: rid.value.ridKind?.lastUpdateDt,
                      },
                      ridName: {
                        propertyId: rid.value.ridName?.propertyId,
                        type: rid.value.ridName?.type,
                        lastUpdateDt: rid.value.ridName?.lastUpdateDt,
                      },
                      ridAddress: {
                        propertyId: rid.value.ridAddress?.propertyId,
                        type: rid.value.ridAddress?.type,
                        lastUpdateDt: rid.value.ridAddress?.lastUpdateDt,
                      },
                      ridOwner: {
                        propertyId: rid.value.ridOwner?.propertyId,
                        type: rid.value.ridOwner?.type,
                        lastUpdateDt: rid.value.ridOwner?.lastUpdateDt,
                      },
                      ridDetails: {
                        propertyId: rid.value.ridDetails?.propertyId,
                        type: rid.value.ridDetails?.type,
                        lastUpdateDt: rid.value.ridDetails?.lastUpdateDt,
                      },
                    },
                  })),
                },
                exploitation: {
                  propertyId: step.propertyId,
                  lastUpdateDt: step.lastUpdateDt,
                  type: step.type,
                  value: {
                    ridCommissioningEndDate: {
                      propertyId: step.value.ridCommissioningEndDate?.propertyId,
                      type: step.value.ridCommissioningEndDate?.type,
                      lastUpdateDt: step.value.ridCommissioningEndDate?.lastUpdateDt,
                    },

                    ridExploitationObjects: preparedRidExploitationObjects,
                  },
                },
                registration: {
                  propertyId: step.propertyId,
                  lastUpdateDt: step.lastUpdateDt,
                  type: step.type,
                  value: {
                    ridNames: {
                      propertyId: step.value.ridStateRegistrationIds?.propertyId,
                      type: step.value.ridStateRegistrationIds?.type,
                      lastUpdateDt: step.value.ridStateRegistrationIds?.lastUpdateDt,
                      value: preparedRidNameRegistrations,
                    },
                    ridStateRegistrationEndDate: {
                      propertyId: step.value.ridStateRegistrationEndDate?.propertyId,
                      type: step.value.ridStateRegistrationEndDate?.type,
                      lastUpdateDt: step.value.ridStateRegistrationEndDate?.lastUpdateDt,
                    },

                    ridRegistrationsObjects: preparedRidRegistrationObjects,
                  },
                },
              },
            },
          },
        }
      }),
    }
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getRFAdapter(objectFromPropertyState),
    },
    '2': {
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '4': {
      ruleSubjectSzpkInstrumental: {
        propertyId: objectFromPropertyState.ruleSubjectSzpkInstrumental?.propertyId,
        type: objectFromPropertyState.ruleSubjectSzpkInstrumental?.type,
        lastUpdateDt: objectFromPropertyState.ruleSubjectSzpkInstrumental?.lastUpdateDt,
      },
      ruleLinkStabilizationNpa: {
        propertyId: objectFromPropertyState.ruleLinkStabilizationNpa?.propertyId,
        type: objectFromPropertyState.ruleLinkStabilizationNpa?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStabilizationNpa?.lastUpdateDt,
      },
    },
    '5': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
      projectRegion: {
        propertyId: objectFromPropertyState.projectRegionCode?.propertyId,
        type: objectFromPropertyState.projectRegionCode?.type,
        lastUpdateDt: objectFromPropertyState.projectRegionCode?.lastUpdateDt,
      },
      economicSphere: {
        propertyId: objectFromPropertyState.economicSphere?.propertyId,
        type: objectFromPropertyState.economicSphere?.type,
        lastUpdateDt: objectFromPropertyState.economicSphere?.lastUpdateDt,
      },
      otherEconomicSphere: {
        propertyId: objectFromPropertyState?.otherEconomicSphere?.propertyId,
        type: objectFromPropertyState?.otherEconomicSphere?.type,
        lastUpdateDt: objectFromPropertyState?.otherEconomicSphere?.lastUpdateDt,
      },
      projectPurpose: {
        propertyId: objectFromPropertyState.projectPurpose?.propertyId,
        type: objectFromPropertyState.projectPurpose?.type,
        lastUpdateDt: objectFromPropertyState.projectPurpose?.lastUpdateDt,
      },
      projectStartDate: {
        propertyId: objectFromPropertyState.projectStartDate?.propertyId,
        type: objectFromPropertyState.projectStartDate?.type,
        lastUpdateDt: objectFromPropertyState.projectStartDate?.lastUpdateDt,
      },
      projectDecisionDepartmentGenitive: {
        propertyId: objectFromPropertyState?.projectDecisionDepartamentGenitive?.propertyId,
        type: objectFromPropertyState?.projectDecisionDepartamentGenitive?.type,
        lastUpdateDt: objectFromPropertyState?.projectDecisionDepartamentGenitive?.lastUpdateDt,
      },
      totalCapital: {
        propertyId: objectFromPropertyState.totalCapital?.propertyId,
        type: objectFromPropertyState.totalCapital?.type,
        lastUpdateDt: objectFromPropertyState.totalCapital?.lastUpdateDt,
      },
      investorCapital: {
        propertyId: objectFromPropertyState.investorCapital?.propertyId,
        type: objectFromPropertyState.investorCapital?.type,
        lastUpdateDt: objectFromPropertyState.investorCapital?.lastUpdateDt,
      },
      madeInvestorCapital: {
        propertyId: objectFromPropertyState.madeInvestorCapital?.propertyId,
        type: objectFromPropertyState.madeInvestorCapital?.type,
        lastUpdateDt: objectFromPropertyState.madeInvestorCapital?.lastUpdateDt,
      },
      remainsInvestorCapital: {
        propertyId: objectFromPropertyState.remainsInvestorCapital?.propertyId,
        type: objectFromPropertyState.remainsInvestorCapital?.type,
        lastUpdateDt: objectFromPropertyState.remainsInvestorCapital?.lastUpdateDt,
      },
      madeInvestorCapitalDate: {
        propertyId: objectFromPropertyState.madeInvestorCapitalDate?.propertyId,
        type: objectFromPropertyState.madeInvestorCapitalDate?.type,
        lastUpdateDt: objectFromPropertyState.madeInvestorCapitalDate?.lastUpdateDt,
      },
      projectSupportCompensation: (() => {
        const element = isArray(objectFromPropertyState.projectCompensations?.value)
          ? objectFromPropertyState.projectCompensations?.value.find(
              (item) =>
                item.value.projectCompensationType.value?.name === 'Обеспечивающая инфраструктура',
            )?.value.projectCompensationValue
          : ''

        return {
          propertyId: element?.propertyId,
          type: element?.type,
          lastUpdateDt: element?.lastUpdateDt,
        }
      })(),
      projectRelatedCompensation: (() => {
        const element = isArray(objectFromPropertyState.projectCompensations?.value)
          ? objectFromPropertyState.projectCompensations?.value.find(
              (item) =>
                item.value.projectCompensationType.value?.name === 'Сопутствующая инфраструктура',
            )?.value.projectCompensationValue
          : ''

        return {
          propertyId: element?.propertyId,
          type: element?.type,
          lastUpdateDt: element?.lastUpdateDt,
        }
      })(),
    },
    '6': {
      stages: {
        propertyId: objectFromPropertyState.steps?.propertyId,
        lastUpdateDt: objectFromPropertyState.steps?.lastUpdateDt,
        type: objectFromPropertyState.steps?.type,
        value:
          isArray(objectFromPropertyState.steps?.value) &&
          objectFromPropertyState.steps.value.length
            ? objectFromPropertyState.steps?.value.map((step) => ({
                propertyId: step.propertyId,
                lastUpdateDt: step.lastUpdateDt,
                value: {
                  stepNumber: {
                    propertyId: step.value.stepNumber?.propertyId,
                    type: step.value.stepNumber?.type,
                    lastUpdateDt: step.value.stepNumber?.lastUpdateDt,
                  },
                  stepDescription: {
                    propertyId: step.value.stepDescription?.propertyId,
                    type: step.value.stepDescription?.type,
                    lastUpdateDt: step.value.stepDescription?.lastUpdateDt,
                  },
                  stepStartDate: {
                    propertyId: step.value.stepStartDate?.propertyId,
                    type: step.value.stepStartDate?.type,
                    lastUpdateDt: step.value.stepStartDate?.lastUpdateDt,
                  },
                  stepEndDate: {
                    propertyId: step.value.stepEndDate?.propertyId,
                    type: step.value.stepEndDate?.type,
                    lastUpdateDt: step.value.stepEndDate?.lastUpdateDt,
                  },
                  stepInvestorCapitalValue: {
                    propertyId: step.value.stepInvestorCapitalValue?.propertyId,
                    type: step.value.stepInvestorCapitalValue?.type,
                    lastUpdateDt: step.value.stepInvestorCapitalValue?.lastUpdateDt,
                  },
                  stepCompensationValue: {
                    propertyId: step.value.stepCompensationValue?.propertyId,
                    type: step.value.stepCompensationValue?.type,
                    lastUpdateDt: step.value.stepCompensationValue?.lastUpdateDt,
                  },
                  stepCompensationForm: {
                    propertyId: step.value.stepCompensationForm?.propertyId,
                    type: step.value.stepCompensationForm?.type,
                    lastUpdateDt: step.value.stepCompensationForm?.lastUpdateDt,
                  },
                  stepCompensations: {
                    propertyId: step.value.stepCompensations?.propertyId,
                    type: step.value.stepCompensations?.type,
                    lastUpdateDt: step.value.stepCompensations?.lastUpdateDt,
                    value:
                      isArray(step.value.stepCompensations?.value) &&
                      step.value.stepCompensations?.value.length
                        ? step.value.stepCompensations?.value.map((stepCompensation) => ({
                            propertyId: stepCompensation?.propertyId,
                            type: stepCompensation?.type,
                            lastUpdateDt: stepCompensation?.lastUpdateDt,
                            value: {
                              stepCompensationType: {
                                propertyId:
                                  stepCompensation?.value.stepCompensationType?.propertyId,
                                type: stepCompensation?.value.stepCompensationType?.type,
                                lastUpdateDt:
                                  stepCompensation?.value.stepCompensationType?.lastUpdateDt,
                              },
                              stepCompensationPeriod: {
                                propertyId:
                                  stepCompensation?.value.stepCompensationPeriod?.propertyId,
                                type: stepCompensation?.value.stepCompensationPeriod?.type,
                                lastUpdateDt:
                                  stepCompensation?.value.stepCompensationPeriod?.lastUpdateDt,
                              },
                            },
                          }))
                        : [],
                  },
                },
              }))
            : [],
      },
    },
    '7': {
      eventsOfStages: preparedEventOfStages,
    },
    '8': {
      stepActivityOthers: {
        value: preparedStepActivityOthers,
      },
    },
    '9': {
      projectResultEcoActivity: {
        propertyId: objectFromPropertyState?.projectResultEcoActivity?.propertyId,
        lastUpdateDt: objectFromPropertyState.projectResultEcoActivity?.lastUpdateDt,
        type: objectFromPropertyState.projectResultEcoActivity?.type,
        value: {
          ecoActivityName: {
            propertyId:
              objectFromPropertyState?.projectResultEcoActivity?.value.ecoActivityName?.propertyId,
            type: objectFromPropertyState?.projectResultEcoActivity?.value.ecoActivityName?.type,
            lastUpdateDt:
              objectFromPropertyState?.projectResultEcoActivity?.value.ecoActivityName
                ?.lastUpdateDt,
          },
          ecoActivityProgramNameInstrumental: {
            propertyId:
              objectFromPropertyState?.projectResultEcoActivity?.value
                ?.ecoActyvityProgrammNameInstrumental?.propertyId,
            type: objectFromPropertyState?.projectResultEcoActivity?.value
              ?.ecoActyvityProgrammNameInstrumental?.type,
            lastUpdateDt:
              objectFromPropertyState?.projectResultEcoActivity?.value
                ?.ecoActyvityProgrammNameInstrumental?.lastUpdateDt,
          },
          ecoActivityEndDate: {
            propertyId:
              objectFromPropertyState?.projectResultEcoActivity?.value.ecoActivityEndDate
                ?.propertyId,
            type: objectFromPropertyState?.projectResultEcoActivity?.value.ecoActivityEndDate?.type,
            lastUpdateDt:
              objectFromPropertyState?.projectResultEcoActivity?.value.ecoActivityEndDate
                ?.lastUpdateDt,
          },
        },
      },
      otherProjectResultActivity: {
        propertyId: objectFromPropertyState?.otherProjectResultActivity?.propertyId,
        type: objectFromPropertyState?.otherProjectResultActivity?.type,
        lastUpdateDt: objectFromPropertyState?.otherProjectResultActivity?.lastUpdateDt,
      },
      projectResultActivity: {
        propertyId: objectFromPropertyState.projectResultActivities?.propertyId,
        type: objectFromPropertyState.projectResultActivities?.type,
        lastUpdateDt: objectFromPropertyState.projectResultActivities?.lastUpdateDt,
        value: objectFromPropertyState.projectResultActivities?.value.map((projectResult) => ({
          propertyId: projectResult.propertyId,
          lastUpdateDt: projectResult.lastUpdateDt,
          type: projectResult.type,
        })),
      },
      projectResults: preparedProjectResults,
    },
    '10': {
      technologicalRequirements: {
        propertyId: objectFromPropertyState.projectNpas?.propertyId,
        type: objectFromPropertyState.projectNpas?.type,
        lastUpdateDt: objectFromPropertyState.projectNpas?.lastUpdateDt,
        value: isArray(objectFromPropertyState.projectNpas?.value)
          ? objectFromPropertyState.projectNpas?.value.map((npa) => ({
              propertyId: npa.propertyId,
              type: npa.type,
              lastUpdateDt: npa.lastUpdateDt,
              value: {
                projectNpaName: {
                  propertyId: npa.propertyId,
                  type: npa.type,
                  lastUpdateDt: npa.lastUpdateDt,
                },
              },
            }))
          : [],
      },
    },
    '11': {
      rightDutySubjectForestResource: {
        propertyId: objectFromPropertyState.rightDutySubjectForestResource?.propertyId,
        type: objectFromPropertyState.rightDutySubjectForestResource?.type,
        lastUpdateDt: objectFromPropertyState.rightDutySubjectForestResource?.lastUpdateDt,
      },
      rightDutySubjectWaterResource: {
        propertyId: objectFromPropertyState.rightDutySubjectWaterResource?.propertyId,
        type: objectFromPropertyState.rightDutySubjectWaterResource?.type,
        lastUpdateDt: objectFromPropertyState.rightDutySubjectWaterResource?.lastUpdateDt,
      },
      rightDutyRfForestResource: {
        propertyId: objectFromPropertyState.rightDutyRfForestResource?.propertyId,
        type: objectFromPropertyState.rightDutyRfForestResource?.type,
        lastUpdateDt: objectFromPropertyState.rightDutyRfForestResource?.lastUpdateDt,
      },
      rightDutyRfWaterResource: {
        propertyId: objectFromPropertyState.rightDutyRfWaterResource?.propertyId,
        type: objectFromPropertyState.rightDutyRfWaterResource?.type,
        lastUpdateDt: objectFromPropertyState.rightDutyRfWaterResource?.lastUpdateDt,
      },
    },
    '13': {
      extraMunicipalAccessionLinkCompensation: {
        propertyId: objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.propertyId,
        type: objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.type,
        lastUpdateDt: objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.lastUpdateDt,
      },
      extraMunicipalAccessionLinkStabilization: {
        propertyId: objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.propertyId,
        type: objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.type,
        lastUpdateDt:
          objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.lastUpdateDt,
      },
      stabilizationPeriod: {
        propertyId: objectFromPropertyState.stabilizationPeriod?.propertyId,
        type: objectFromPropertyState.stabilizationPeriod?.type,
        lastUpdateDt: objectFromPropertyState.stabilizationPeriod?.lastUpdateDt,
      },
    },
    '14': {
      ruleLinkRelatedContract: {
        propertyId: objectFromPropertyState.ruleLinkRelatedContract?.propertyId,
        type: objectFromPropertyState.ruleLinkRelatedContract?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkRelatedContract?.lastUpdateDt,
      },
      subjectRuleLinkRelatedContract: {
        propertyId: objectFromPropertyState.subjectRuleLinkRelatedContract?.propertyId,
        type: objectFromPropertyState.subjectRuleLinkRelatedContract?.type,
        lastUpdateDt: objectFromPropertyState.subjectRuleLinkRelatedContract?.lastUpdateDt,
      },
    },
    '15': {
      subjectNpaReasonMonitoring: {
        propertyId: objectFromPropertyState.subjectNpaReasonMonitoring?.propertyId,
        type: objectFromPropertyState.subjectNpaReasonMonitoring?.type,
        lastUpdateDt: objectFromPropertyState.subjectNpaReasonMonitoring?.lastUpdateDt,
      },
      ruleLinkMonitoring: {
        propertyId: objectFromPropertyState.ruleLinkMonitoring?.propertyId,
        type: objectFromPropertyState.subjectNpaReasonMonitoring?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkMonitoring?.lastUpdateDt,
      },
    },
    '16': {
      courtChoice: {
        propertyId: objectFromPropertyState.courtChoice?.propertyId,
        type: objectFromPropertyState.courtChoice?.type,
        lastUpdateDt: objectFromPropertyState.courtChoice?.lastUpdateDt,
      },
      courtName: {
        propertyId: objectFromPropertyState.courtName?.propertyId,
        type: objectFromPropertyState.courtName?.type,
        lastUpdateDt: objectFromPropertyState.courtName?.lastUpdateDt,
      },
    },
    anotherFields: {
      objectsFromSteps: objectFromPropertyState.steps?.value.map((step) => {
        return {
          propertyId: step.value.objects?.propertyId,
          permanentId: step.value.objects?.permanentId,
          lastUpdateDt: step.value.objects?.lastUpdateDt,
        }
      }),
    },
  }
}

export { generatePropertiesObjectForProjectSZPKForm }
