import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { financialModelBlockValues } from '@components/Forms/FinancialModelForm/const'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { parseDateString } from '@components/NewDesign/DateInput/DateInput'
import { objOfDateFormats } from '@constants/dateFormats'
import { isString } from '@helpers/checkTypes'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

const oneSectionFinancialModelValidationMap: FormValuesValidationSection<
  keyof FinancialModelFormValues['1'],
  FinancialModelFormValues
> = {
  startInvestStage: ({ form }) => ({
    onBlur: () => {
      form.trigger(financialModelBlockValues['1'].endInvestStage)
    },
  }),
  endInvestStage: ({ form }) => ({
    validate: {
      positiveCurrentDate: (value) => {
        if (!isString(value)) return

        const currentYear = DayjsService.dayjsWithFormatToMSK().format(
          objOfDateFormats.yearFormat.yearOnly,
        )

        return (
          (isDateValidForDayjs(value) &&
            dayjs(
              parseDateString(value, objOfDateFormats.yearFormat.yearOnly),
              objOfDateFormats.defaultFormat,
            ) >=
              dayjs(
                parseDateString(currentYear, objOfDateFormats.yearFormat.yearOnly),
                objOfDateFormats.defaultFormat,
              )) ||
          'плановый год окончания стадии должен быть больше или равен текущему году'
        )
      },
    },
    onBlur: () => {
      form.trigger(financialModelBlockValues['1'].startExploitationStage)
    },
  }),

  startExploitationStage: ({ form }) => ({
    validate: {
      positiveEndInvestStage: (value) => {
        const endInvestStage = form.getValues(financialModelBlockValues['1'].endInvestStage)

        if (
          form &&
          isDateValidForDayjs(value) &&
          dayjs(
            parseDateString(value, objOfDateFormats.yearFormat.yearOnly),
            objOfDateFormats.defaultFormat,
          ) <
            dayjs(
              parseDateString(endInvestStage, objOfDateFormats.yearFormat.yearOnly),
              objOfDateFormats.defaultFormat,
            )
        )
          return 'эксплуатационная стадия должна начинаться после завершения инвестиционной стадии'
      },
    },
  }),

  projectCapacityYear: ({ form }) => ({
    validate: {
      positiveEndInvestStage: (value) => {
        const startExploitationStage = form.getValues(
          financialModelBlockValues['1'].startExploitationStage,
        )

        if (
          form &&
          isDateValidForDayjs(value) &&
          dayjs(
            parseDateString(value, objOfDateFormats.yearFormat.yearOnly),
            objOfDateFormats.defaultFormat,
          ) <
            dayjs(
              parseDateString(startExploitationStage, objOfDateFormats.yearFormat.yearOnly),
              objOfDateFormats.defaultFormat,
            )
        )
          return 'год выхода на проектную мощность не может быть раньше, чем год начала эксплуатационной стадии'
      },
    },
  }),
}

export { oneSectionFinancialModelValidationMap }
