import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOnMonitoringResultsCompensationExpenses,
  CertificateOnMonitoringResultsFormValues,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { lengthValidate,standardAmountValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const compensationExpensesCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsCompensationExpenses,
  CertificateOnMonitoringResultsFormValues
> = {
  expenseName: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 200) : undefined),
  },
  expenseValueChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { compensationExpensesCertificateOnMonitoringResultsValidationMap }
