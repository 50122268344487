import { FC, useCallback } from 'react'

import { IStepsProps, TStepOnChange } from '@routes/Projects/shared/GetRightToSZPK/steps/model'
import { NotFound } from '@routes/Projects/shared/GetRightToSZPK/steps/NotFound/Components'

import { ChooseSZPKStep } from './ChooseSZPK'
import { InfoStep } from './Info'
import { SpecifyingSZPKDataStep } from './SpecifyingSZPKData'

const GetRightToSZPKFormSteps = {
  0: InfoStep,
  1: SpecifyingSZPKDataStep,
  2: ChooseSZPKStep,
  notFound: NotFound,
}

export const Steps: FC<IStepsProps> = ({
  step,
  savedStepsValues,
  changeSavedStepsValuesHandler,
}) => {
  const CurrentStepUI = GetRightToSZPKFormSteps[step]

  const changeValuesHandler = useCallback<TStepOnChange>(
    (key, value) => changeSavedStepsValuesHandler((prev) => ({ ...prev, [key]: value })),
    [changeSavedStepsValuesHandler],
  )

  return <CurrentStepUI values={savedStepsValues} onChange={changeValuesHandler} />
}

export type { IStepsProps, IStepsValues, TStep, TStepsKeys } from './model'
