import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

import { NotificationOfChangesInDetailsOfPartiesFormValues } from '../../types'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof NotificationOfChangesInDetailsOfPartiesFormValues['2'],
  NotificationOfChangesInDetailsOfPartiesFormValues
> = {
  ruleLinkInfoChanges: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { secondSectionValidationMap }
