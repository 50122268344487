import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import { IconProps } from '@components/Icon'
import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { isUndefined } from '@helpers/checkTypes'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from './ObjectStepWrapperHeaderNode.module.scss'

interface ObjectStepWrapperHeaderNodeButtonProps
  extends Omit<ButtonProps, 'children' | 'trailingIcon'> {
  trailingIcon?: Partial<IconProps> | null
}

interface ObjectStepWrapperHeaderNodeProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  leftAddons?: ReactNode
  buttonProps?: ObjectStepWrapperHeaderNodeButtonProps
}

const ObjectStepWrapperHeaderNode: FC<ObjectStepWrapperHeaderNodeProps> = ({
  leftAddons,
  buttonProps,
  children,
  ...restProps
}) => {
  const {
    fixWidth = true,
    view = 'plain',
    size = 's',
    variant = 'buttonSMedium',
    color = 'default',
    className,
    trailingIcon,
    ...restButtonProps
  } = buttonProps || {}

  const {
    src = chevronRightIcon,
    className: trailingIconClassName,
    ...restTrailingIcon
  } = trailingIcon || {}

  const leftAddonsRenderCondition = !isUndefined(leftAddons)

  return (
    <div
      {...restProps}
      className={cn(styles['objectStepWrapperHeaderNode__header'], {
        [styles['objectStepWrapperHeaderNode__header--withAddons']]: leftAddonsRenderCondition,
      })}
    >
      {leftAddons}
      <Button
        {...(restButtonProps as ButtonProps)}
        fixWidth={fixWidth}
        view={view}
        size={size}
        variant={variant}
        color={color}
        className={cn(
          styles['objectStepWrapperHeaderNode__header-button'],
          {
            [styles['objectStepWrapperHeaderNode__header-button--withAddons']]:
              leftAddonsRenderCondition,
          },
          className,
        )}
        trailingIcon={
          trailingIcon
            ? {
                ...restTrailingIcon,
                src,
                className: cn(
                  styles['objectStepWrapperHeaderNode__header-button__icon'],
                  trailingIconClassName,
                ),
              }
            : undefined
        }
      >
        {children}
      </Button>
    </div>
  )
}

export default ObjectStepWrapperHeaderNode
