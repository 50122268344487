import { type FC, memo, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import AttachmentItem from '@components/Attachments/Attachment'
import type { AttachmentsFormValue } from '@components/Attachments/types'
import { createFolderItem, parseDocument } from '@helpers/fileHelpers'
import { IAttachmentFileItem } from '@interfaces/documents'
import type { IDocumentTypeInfoWithDict } from '@services/Documents/documents.entity'

interface AttachmentItemControllerProps {
  parentSetType: string
  permissions: number
  documentProps: IDocumentTypeInfoWithDict
  projectId: string
  dfoId: string
  documentSetId: string
  isChangesMadeTab?: boolean
}

const AttachmentItemController: FC<AttachmentItemControllerProps> = ({
  documentSetId,
  parentSetType,
  documentProps,
  permissions,
  projectId,
  dfoId,
  isChangesMadeTab,
}) => {
  const { documents, folders, documentInfoFromDict } = documentProps
  const { isMultiple, isFolderAvailable, ...restDocumentInfoFromDict } = documentInfoFromDict

  const initialFormValue: AttachmentsFormValue = useMemo(() => {
    const preparedDocuments =
      documents && !!documents.length ? documents.map((document) => parseDocument(document)) : []

    const preparedMultipleItemDocuments: IAttachmentFileItem[] = preparedDocuments.map(
      (document) => ({
        ...document,
        checked: false,
      }),
    )

    const preparedFolders = (folders || [])?.map(({ id, name, documents, updateDt }) => {
      const preparedDocuments =
        documents && !!documents.length ? documents.map((document) => parseDocument(document)) : []

      return createFolderItem({
        name,
        documents: preparedDocuments,
        folderId: id,
        updateDt,
      })
    })

    return {
      fileItems: !isMultiple && !isFolderAvailable ? preparedDocuments : [],
      folderItems: isMultiple && !isFolderAvailable ? preparedDocuments : [],
      multipleItems:
        isMultiple && isFolderAvailable
          ? [...preparedMultipleItemDocuments, ...preparedFolders]
          : [],
    }
  }, [])

  const formInstance = useForm<AttachmentsFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialFormValue,
  })

  if (isMultiple && isFolderAvailable) {
    return (
      <AttachmentItem.MultipleItem
        documentInfoFromDict={restDocumentInfoFromDict}
        formInstance={formInstance}
        projectId={projectId}
        dfoId={dfoId}
        documentSetId={documentSetId}
        permissions={permissions}
        parentSetType={parentSetType}
        isChangesMadeTab={isChangesMadeTab}
      />
    )
  }

  if (isMultiple && !isFolderAvailable) {
    return (
      <AttachmentItem.FolderItem
        documentInfoFromDict={restDocumentInfoFromDict}
        formInstance={formInstance}
        projectId={projectId}
        dfoId={dfoId}
        documentSetId={documentSetId}
        permissions={permissions}
        parentSetType={parentSetType}
        isChangesMadeTab={isChangesMadeTab}
      />
    )
  }

  if (!isMultiple && !isFolderAvailable) {
    return (
      <AttachmentItem.FileItem
        documentInfoFromDict={restDocumentInfoFromDict}
        documentSetId={documentSetId}
        permissions={permissions}
        parentSetType={parentSetType}
        formInstance={formInstance}
        projectId={projectId}
        dfoId={dfoId}
        isChangesMadeTab={isChangesMadeTab}
      />
    )
  }

  return null
}

export default memo(AttachmentItemController)
