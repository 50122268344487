import React, { FC, memo } from 'react'
import { useForm } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Typography from '@components/NewDesign/Typography'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'
import { PartCustomProps } from '@services/NPA/NPA.entity'

import styles from './EditPart.module.scss'

interface EditPartCardProps {
  initialPartInfo: PartCustomProps
  onUpdate: ({ partCorrection, justification }: PartCustomProps) => Promise<void>
  onCancel: () => void
}

const EditPartCard: FC<EditPartCardProps> = ({ initialPartInfo, onUpdate, onCancel }) => {
  const {
    getValues,
    control,
    formState: { isValid, isDirty },
  } = useForm<PartCustomProps>({
    reValidateMode: 'onChange',
    mode: 'onBlur',
    defaultValues: {
      justification: initialPartInfo.justification,
      partCorrection: initialPartInfo.partCorrection,
    },
  })

  const handleUpdate = async () => {
    const formValues = getValues()

    await onUpdate({
      partCorrection: formValues.partCorrection,
      justification: formValues.justification,
    })
  }

  return (
    <div className={styles.editPart}>
      <div className={styles.editPart__inputContainer}>
        <div className={styles.editPart__inputRow}>
          <Typography.Body className={styles.editPart__part} variant={'bodyMRegular'}>
            Обоснование
          </Typography.Body>
          <ControlledInput
            name={'justification'}
            control={control}
            rules={{
              required: defaultRHFValidation.required,
              validate: (value) => isString(value) && lengthValidate(value, 1000),
            }}
            inputProps={{
              controlClassName: styles.editPart__control,
              rootClassName: styles.editPart__input,
              fixWidth: true,
              clear: true,
              size: 's',
              placeholder: 'Укажите обоснование',
            }}
          />
        </div>
        <div className={styles.editPart__inputRow}>
          <Typography.Body className={styles.editPart__part} variant={'bodyMRegular'}>
            Статьи и пункты
          </Typography.Body>
          <ControlledInput
            name={'partCorrection'}
            control={control}
            rules={{
              validate: (value) =>
                isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined,
            }}
            inputProps={{
              controlClassName: styles.editPart__control,
              rootClassName: styles.editPart__input,
              fixWidth: true,
              clear: true,
              size: 's',
              placeholder: 'Укажите статьи и пункты, которые хотите стабилизировать',
            }}
          />
        </div>
      </div>
      <AsyncWrapper promise={handleUpdate}>
        {({ wrappedPromise, isLoading }) => (
          <div className={styles.editPart__actions}>
            <Button disabled={isLoading} size={'m'} view={'gray'} onClick={onCancel}>
              Отмена
            </Button>
            <Button
              size={'m'}
              disabled={!isValid || !isDirty || isLoading}
              loaderProps={{
                loading: isLoading,
                placement: 'trailing',
                variant: 'lite',
              }}
              onClick={wrappedPromise}
            >
              Сохранить
            </Button>
          </div>
        )}
      </AsyncWrapper>
    </div>
  )
}

export default memo(EditPartCard)
