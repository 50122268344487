import { FC } from 'react'

import One from './1'
import Two from './2'
import Third from './3'
import Four from './4'
import Five from './5'

interface AgreementStabilizationNegotiationsFormsProps extends FC {
  One: typeof One
  Two: typeof Two
  Third: typeof Third
  Four: typeof Four
  Five: typeof Five
}

const AgreementStabilizationNegotiationsForms: AgreementStabilizationNegotiationsFormsProps = () =>
  null

AgreementStabilizationNegotiationsForms.One = One
AgreementStabilizationNegotiationsForms.Two = Two
AgreementStabilizationNegotiationsForms.Third = Third
AgreementStabilizationNegotiationsForms.Four = Four
AgreementStabilizationNegotiationsForms.Five = Five

export default AgreementStabilizationNegotiationsForms
