import { generatePropertiesObjectForPetitionTransferRightForm } from '@components/Forms/PetitionTransferRightForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForPetitionTransferRightForm } from '@components/Forms/PetitionTransferRightForm/adapters/RHF.adapter'
import { PetitionTransferRightFormValues } from '@components/Forms/PetitionTransferRightForm/types'

const usePetitionTransferRightAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): PetitionTransferRightFormValues =>
    generateRHFObjectForPetitionTransferRightForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForPetitionTransferRightForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { usePetitionTransferRightAdapters }
