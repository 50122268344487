import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import type {
  IGetOrganizationsListParams,
  IOrganization,
} from '@context/APIContext/hooks/useOrganizationsApi'
import type { SWRIniniteHookProps } from '@interfaces'
import useSWRInfinite from 'swr/infinite'

interface HandleGetListParams extends IGetOrganizationsListParams {
  _key: string
}

const useMunicipality = ({ key, config }: SWRIniniteHookProps<IOrganization[]>) => {
  const {
    organizationsApi: { getOrganizationsList },
  } = useAPIContext()

  const handleGetList = async ({ _key, ...params }: HandleGetListParams) => {
    const data = await getOrganizationsList(params)
    return data?.organizations || []
  }

  const {
    data: municipalityList,
    error,
    mutate,
    isValidating: isMunicipalityLoading,
    setSize,
  } = useSWRInfinite<IOrganization[]>(key, handleGetList, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateFirstPage: false,
    ...config,
  })

  const hasMoreData = useMemo(() => {
    return municipalityList && municipalityList?.[municipalityList?.length - 1]?.length >= 29
  }, [municipalityList])

  const preparedMunicipalityList = useMemo(() => {
    return municipalityList?.flat()
  }, [municipalityList])

  return {
    municipalityList: preparedMunicipalityList,
    error,
    mutate,
    isMunicipalityLoading,
    hasMoreData,
    incPage: setSize,
  }
}

export { useMunicipality }
