import React, { ReactNode } from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import RadioGroup, { RadioGroupProps } from '@components/Radio/Group'
import WithCaption from '@components/WithCaption'

type ControlledRadioGroupProps<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> & {
  children: ReactNode
  radioGroupProps?: Omit<RadioGroupProps, 'value' | 'onChange' | 'ref' | 'children'>
  onChange?: (value) => void
}
const ControlledRadioGroup = <T extends FieldValues>(props: ControlledRadioGroupProps<T>) => {
  const { radioGroupProps, children, onChange, ...controllerProps } = props

  const {
    field: { onChange: onFormChange, value, ref },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const onRadioGroupChange = (_, payload) => {
    onFormChange(payload.value)

    onChange?.(payload.value)
  }

  return (
    <WithCaption caption={error?.message}>
      <RadioGroup {...radioGroupProps} value={value} ref={ref} onChange={onRadioGroupChange}>
        {children}
      </RadioGroup>
    </WithCaption>
  )
}

export type { ControlledRadioGroupProps }
export default ControlledRadioGroup
