import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesList from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/5/CompensationStatementPaidTax/CompensationStatementPaidTaxValuesList'
import { ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxesArrayPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/5/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import { ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxProps {
  id: string
  name: ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxesArrayPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
}

const ParametersOfCostRecoveryApplicationCompensationStatementPaidTax: FC<
  ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxProps
> = ({ id, name, formInstance }) => {
  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
  })

  return (
    <FieldView.StepTemplate id={id}>
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.compensationStatementPaidTaxTaxKindName`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${name}.compensationStatementPaidTaxTaxKindName`,
                    inputProps: {
                      disabled: true,
                      label: 'Вид налога',
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <ParametersOfCostRecoveryApplicationCompensationStatementPaidTaxValuesList
            editMode={editMode}
            blockViewIsValidating={blockViewIsValidating}
            name={`${name}.compensationStatementPaidTaxValues` as const}
            formInstance={formInstance}
          />
        </Stack>
      </Container>
    </FieldView.StepTemplate>
  )
}

export default ParametersOfCostRecoveryApplicationCompensationStatementPaidTax
