//@ts-nocheck
import { onFrame, performanceNow } from './utils'

/**
 * Plays each frame of the SpringSystem on animation
 * timing loop. This is the default type of looper for a new spring system
 * as it is the most common when developing UI.
 * @public
 */
export class AnimationLooper {
  run() {
    onFrame(() => {
      this.springSystem.loop(performanceNow())
    })
  }
}
