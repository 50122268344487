import { useMemo } from 'react'

import { DFOSStage } from '@context/APIContext/hooks/useDFOSApi'
import { getRolesByToken } from '@context/AuthContext/workers/rolesWorkers'
import { useDfosStages } from '@hooks/swr/useDfosStages'
import { isActionAllowed } from '@routes/Projects/helpers'

interface IGetConfigForDfoProps {
  dfo: {
    stage: string
    type: string
    version: number | null
    actionAllowed: boolean
  }
  isFederal: boolean
}

export const useAvailableConfigsByRole = () => {
  const { dfosStages, isLoadingDfosStages } = useDfosStages({ key: { _key: 'dfosStages' } })

  const availableConfigs = useMemo(() => {
    const roles = getRolesByToken?.() || []

    return dfosStages?.filter((stage) => roles.includes(stage.organizationRole))
  }, [dfosStages])

  const getConfigForDfo = ({ dfo, isFederal }: IGetConfigForDfoProps): DFOSStage | undefined => {
    if (!dfo.version) return

    const versionStageByDfoVersion = (() => {
      if (dfo.version === 1) return 'FIRST'

      if (dfo.version > 1) return 'SECOND_OR_MORE'

      return 'ANY'
    })()

    const paramsFromConfig = availableConfigs?.filter(
      (stage) =>
        stage.dfoStage === dfo.stage &&
        dfo.type === stage.dfoType &&
        stage.isFederal === isFederal &&
        (stage.version === versionStageByDfoVersion || stage.version === 'ANY'),
    )

    if (!paramsFromConfig?.length) return

    return paramsFromConfig.length > 1
      ? paramsFromConfig.find((param) => isActionAllowed(param.actionAllowed, dfo.actionAllowed))
      : paramsFromConfig[0]
  }

  return { isLoadingRoleConfig: isLoadingDfosStages, availableConfigs, getConfigForDfo }
}
