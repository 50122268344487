import { generatePropertiesObjectForNoticeOfRefuseConcludeAAForm } from '@components/Forms/NoticeOfRefuseConcludeAAForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNoticeOfRefuseConcludeAAForm } from '@components/Forms/NoticeOfRefuseConcludeAAForm/adapters/RHF.adapter'
import { NoticeOfRefuseConcludeAAFormValues } from '@components/Forms/NoticeOfRefuseConcludeAAForm/types'

const useNoticeOfRefuseConcludeAAAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NoticeOfRefuseConcludeAAFormValues =>
    generateRHFObjectForNoticeOfRefuseConcludeAAForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNoticeOfRefuseConcludeAAForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNoticeOfRefuseConcludeAAAdapters }
