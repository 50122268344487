import React, { ReactNode, useMemo } from 'react'

import { statusTextsMap, statusTypesMap } from '@components/Attachments/constants'
import { type TooltipProps, Tooltip } from '@components/NewDesign/Tooltip'
import { useHover } from '@hooks/new/events/useHover'
import cn from 'classnames'

import styles from './FileItemLeadingNode.module.scss'

interface LeadingNodeStatusProps extends Omit<TooltipProps, 'open' | 'content' | 'children'> {
  statusType: keyof typeof statusTypesMap
}

interface LeadingNodeProps {
  statusProps?: LeadingNodeStatusProps
  additionalLeadingNodeContent?: ReactNode
}

const FileItemLeadingNode = ({ statusProps, additionalLeadingNodeContent }: LeadingNodeProps) => {
  const {
    position = 'top',
    offset = [0, 0],
    statusType = '',
    targetClassName,
    ...restStatusTooltipProps
  } = statusProps || {}

  const [hoverRef, isHovered] = useHover<HTMLDivElement>()

  const statusTooltipContent = useMemo(
    () => <span>{statusTextsMap[statusType]}</span>,
    [statusType],
  )

  return (
    <div
      className={cn(styles.leadingNode__wrapper, {
        [styles['leadingNode__wrapper--with-content']]:
          (statusProps && statusType) || !!additionalLeadingNodeContent,
      })}
    >
      {statusProps && statusType && (
        <Tooltip
          open={isHovered}
          offset={offset}
          position={position}
          content={statusTooltipContent}
          targetClassName={cn(
            styles.tooltip__target,
            {
              [styles['tooltip__target--with-additional-content']]: !!additionalLeadingNodeContent,
            },
            targetClassName,
          )}
          {...restStatusTooltipProps}
        >
          <div ref={hoverRef} className={styles['leadingNode__status-wrapper']}>
            <span
              className={cn(styles.leadingNode__status, {
                [styles[`leadingNode__status--${statusType}`]]: statusType,
              })}
            />
          </div>
        </Tooltip>
      )}
      {additionalLeadingNodeContent}
    </div>
  )
}

export type { LeadingNodeProps, LeadingNodeStatusProps }
export default FileItemLeadingNode
