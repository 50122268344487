import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResult,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const manufactoringOfGoodsValidationMap: FormValuesValidationSection<
  keyof AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResult,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
> = {
  projectResultName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 2000),
  },
  projectResultCodeTnVed: {
    required: defaultRHFValidation.required,
  },
}

export { manufactoringOfGoodsValidationMap }
