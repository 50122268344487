import React, { FC } from 'react'

import type { OptionProps, OptionsVariant, OptionsView } from '@components/NewDesign/Select/model'
import Options from '@components/NewDesign/Select/OptionsNew'
import { TypographyTooltipProps } from '@components/NewDesign/Typography/types'
import { TypographyWithTooltip } from '@components/NewDesign/Typography/TypographyWithTooltip'

import styles from './OptionDefaultItem.module.scss'

interface OptionDefaultItemProps {
  option: OptionProps
  onClick: (value: OptionProps) => void
  variant?: OptionsVariant
  view?: OptionsView
  selected: OptionProps | OptionProps[] | null
  optionClassName?: string
  DisplayValueFC?: FC<OptionProps>
}

const baseTooltipProps: TypographyTooltipProps = {
  targetClassName: styles.tooltip__target,
  fallbackPlacements: ['left', 'right', 'top', 'bottom'],
}

const OptionDefaultItem: FC<OptionDefaultItemProps> = ({
  option,
  onClick,
  selected,
  optionClassName,
  variant,
  view,
  DisplayValueFC,
}) => {
  return (
    <Options.Item
      {...option}
      selected={selected}
      optionClassName={optionClassName}
      variant={variant}
      onClick={onClick}
    >
      {option.label ? (
        <>
          {view === 'normal' && (
            <>
              <TypographyWithTooltip
                color={'text-base-secondary'}
                variant={'captionSRegular'}
                tooltipProps={{
                  ...baseTooltipProps,
                }}
              >
                {option.label}
              </TypographyWithTooltip>
              {DisplayValueFC ? (
                <DisplayValueFC {...option}>{option.displayValue}</DisplayValueFC>
              ) : (
                <TypographyWithTooltip
                  variant={'bodyMRegular'}
                  tooltipProps={{
                    ...baseTooltipProps,
                  }}
                >
                  {option.displayValue}
                </TypographyWithTooltip>
              )}
            </>
          )}
          {view === 'reversed' && (
            <>
              {DisplayValueFC ? (
                <DisplayValueFC {...option}>{option.displayValue}</DisplayValueFC>
              ) : (
                <TypographyWithTooltip
                  variant={'bodyMRegular'}
                  tooltipProps={{
                    ...baseTooltipProps,
                  }}
                >
                  {option.displayValue}
                </TypographyWithTooltip>
              )}
              <TypographyWithTooltip
                color={'text-base-secondary'}
                variant={'captionSRegular'}
                tooltipProps={{
                  ...baseTooltipProps,
                }}
              >
                {option.label}
              </TypographyWithTooltip>
            </>
          )}
        </>
      ) : (
        option.displayValue
      )}
    </Options.Item>
  )
}

export default OptionDefaultItem
