import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import FormSingleSelect, {
  FormSingleSelectProps,
} from '@components/DocumentFormComponents/FormSelect/Single'

interface ControlledFormSingleSelectProps<T extends FieldValues> extends FormSingleSelectProps {
  controllerProps: Omit<ControllerProps<T>, 'render'>
  id?: string
}

const ControlledFormSingleSelect = <T extends FieldValues>(
  props: ControlledFormSingleSelectProps<T>,
) => {
  const { controllerProps, selectProps, id, ...restProps } = props

  const {
    field: { onChange: onChangeForm, onBlur: onBlurForm, value: formValue },
    fieldState: { error },
  } = useController(controllerProps)

  return (
    <FormSingleSelect
      id={id}
      {...restProps}
      selectProps={{
        ...selectProps,
        error,
        defaultSelected: selectProps.defaultSelected || formValue,
        onChangeForm,
        onBlurForm,
      }}
    />
  )
}

ControlledFormSingleSelect.displayName = 'ControlledFormSingleSelect'

export type { ControlledFormSingleSelectProps }
export default ControlledFormSingleSelect
