import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext, useFormState } from 'react-hook-form'

import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFindFormValueDifferences } from '@components/DocumentFormComponents/hooks/useFindFormValueDifferences'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNestedMenuFormErrors } from '@components/DocumentFormComponents/hooks/useNestedMenuFormErrors'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import {
  useNestedMenuHandlersManager,
  useNestedMenuManager as useInformationOfExecutionAgreementEventsMenuManager,
} from '@components/DocumentFormComponents/NestedMenu/Manager'
import {
  FormModalContextProps,
  FormModalManagerProps,
} from '@components/DocumentFormComponents/types'
import { useInformationOfExecutionAgreementEventsAdapters } from '@components/Forms/InformationOfExecutionAgreementEventsForm/adapters'
import { getInformationOfExecutionAgreementEventsMenuHashMapMenuByStatus } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Menu/const'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import {
  InformationOfExecutionAgreementEventsFieldArrayControlUpdateWatcher,
  InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher,
} from '@components/Forms/InformationOfExecutionAgreementEventsForm/watcher'
import { isNull } from '@helpers/checkTypes'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'

const { getDefaultFormModalContextValue } = DocumentFormHelpers

export const InformationOfExecutionAgreementEventsModalContext = createContext<
  FormModalContextProps<InformationOfExecutionAgreementEventsFormValues>
>(getDefaultFormModalContextValue<InformationOfExecutionAgreementEventsFormValues>())

const useInformationOfExecutionAgreementEventsManager = () => {
  return useContext(InformationOfExecutionAgreementEventsModalContext)
}

const InformationOfExecutionAgreementEventsModalManager: FC<FormModalManagerProps> = ({
  projectId,
  formId,
  editMode,
  rolesOfUser,
  initialErrorsFromViolations,
  onClose,
  children,
}) => {
  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  const { updateLastRFHBeforeValue, getLastRHFBeforeValue } =
    useFormPreviousValue<InformationOfExecutionAgreementEventsFormValues>()

  const formInstance = useFormContext<InformationOfExecutionAgreementEventsFormValues>()
  const { setValue, getValues, reset: resetForm, clearErrors } = formInstance

  const { processOverrideProps, getOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const { errors } = useFormState({
    control: formInstance?.control,
  })

  const { formIsLoading, handleMountForm } = useFormMount(formId)

  const { formErrorsRef } = useNestedMenuFormErrors(errors)

  const {
    handlers: { handleRecalculateFormMenu },
  } = useInformationOfExecutionAgreementEventsMenuManager()

  const { handleChangeCurrentMenuItemId } = useNestedMenuHandlersManager()

  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const { generatePropertiesObject, generateRHFObject, generateMenuListByFormValues } =
    useInformationOfExecutionAgreementEventsAdapters()

  const { getBasePropsFromObjectAdapter } = useGenerateValuesFromObjectAdapter({
    generateRHFObject,
    generatePropertiesObject,
  })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
    rolesOfUser,
  })

  const { handleNotifyAllSubscribers } = useNotifyAllSubscribers({
    watcher: InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const { applyDifferences } = useFindFormValueDifferences({
    setValue,
    watcher: InformationOfExecutionAgreementEventsFieldArrayControlUpdateWatcher,
  })

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()

      if (isNull(prevRHFValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const readyOverrideProps = await processOverrideProps(props.overridePropsFromChanges)

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      unstable_batchedUpdates(() => {
        applyDifferences(prevRHFValue, RHFValueForReset)
        updateLastRFHBeforeValue(RHFValueForReset)

        setTimeout(() => {
          InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher.notifyAllSubscribers(
            readyOverrideProps,
          )
        }, 0)

        handleSetLastUpdateFormToNow()
      })
    },
    [
      applyDifferences,
      getBasePropsFromObjectAdapter,
      getLastRHFBeforeValue,
      handleSetLastUpdateFormToNow,
      lastFieldUpdateTime,
      processOverrideProps,
      propertiesPropsRef,
      updateLastRFHBeforeValue,
    ],
  )

  const handleRecalculateMenu = useCallback(() => {
    const formValues = getValues()

    const preparedMenuList = generateMenuListByFormValues(
      formValues,
      cloneDeep(
        getInformationOfExecutionAgreementEventsMenuHashMapMenuByStatus()
          .initialInformationOfExecutionAgreementEventsMenuHashMap,
      ),
      formErrorsRef.current,
    )

    handleRecalculateFormMenu?.(preparedMenuList)
  }, [getValues, generateMenuListByFormValues, formErrorsRef, handleRecalculateFormMenu])

  const handleApplyChangesWithMenu = useCallback(
    async (props: ApplyChangesReturn) => {
      await handleApplyChanges(props)

      handleRecalculateMenu()
    },
    [handleApplyChanges, handleRecalculateMenu],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const wrappedHandleApplyFormChangesWithMenu = useMemo(
    () => applyFormChanges(handleApplyChangesWithMenu),
    [applyFormChanges, handleApplyChangesWithMenu],
  )

  const {
    handleAddItemToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleChangeValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    debouncedHandleChangeValue,
  } = useFormUpdate({
    formId,
    getPropertiesProps,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: wrappedHandleApplyFormChangesWithMenu,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const wrappedHandleApplyFormChanges = useMemo(
    () => applyFormChanges(handleApplyChanges),
    [applyFormChanges, handleApplyChanges],
  )

  const { handleCheckForm, handlePreviewForm } = useFormActions({
    formId,
    watcher: InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    handleApplyChanges: wrappedHandleApplyFormChanges,
    onErrorCheckForm: handleRecalculateMenu,
    onClearError: clearErrors,
  })

  const handleSuccessInitializeForm = useCallback(
    async ({ lastChangesDt, propertyInstance }: SuccessInitialCallbackReturn) => {
      lastFieldUpdateTime.current = lastChangesDt

      propertyInstanceRef.current = propertyInstance

      const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(objectForAdapters)

      const preparedMenuList = generateMenuListByFormValues(
        RHFValueForReset,
        cloneDeep(
          getInformationOfExecutionAgreementEventsMenuHashMapMenuByStatus()
            .initialInformationOfExecutionAgreementEventsMenuHashMap,
        ),
        errors,
      )

      //Установка propertiesProps
      propertiesPropsRef.current = cloneDeep(propertiesProps)

      resetForm(RHFValueForReset)
      updateLastRFHBeforeValue(RHFValueForReset)

      handleRecalculateFormMenu?.(preparedMenuList)
      handleChangeCurrentMenuItemId?.(preparedMenuList[0].children[0].id)
    },
    [
      errors,
      propertiesPropsRef,
      propertyInstanceRef,
      lastFieldUpdateTime,
      resetForm,
      generateMenuListByFormValues,
      getBasePropsFromObjectAdapter,
      handleRecalculateFormMenu,
      handleChangeCurrentMenuItemId,
      updateLastRFHBeforeValue,
    ],
  )

  //Инициализация формы
  useEffect(() => {
    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleNotifyAllSubscribers,
    })
  }, [])

  const preparedValue: FormModalContextProps<InformationOfExecutionAgreementEventsFormValues> =
    useMemo(
      () => ({
        state: {
          formIsLoading,
          blockViewIsValidating,
          lastUpdateDraftTime,
          initialErrorsFromViolations,
          editMode,
        },
        handlers: {
          checkFormChanges,
          getPropertiesProps,
          getOverrideProps,
          handleChangeValue,
          handleAddCustomValue,
          handleRemoveCustomValue,
          debouncedHandleChangeValue,
          handleAddItemToListWithOutValue,
          handleAddItemToListWithValue,
          handleRemoveItemFromList,
          applyFormChanges: wrappedHandleApplyFormChanges,
          handleChangeBlockValidation,
        },
        actions: {
          handleFormClose: onClose,
          handleCheckForm,
          handlePreviewForm,
        },
        preparedProps: {
          subscribableControl,
        },
      }),
      [
        formIsLoading,
        blockViewIsValidating,
        lastUpdateDraftTime,
        initialErrorsFromViolations,
        editMode,
        checkFormChanges,
        getPropertiesProps,
        getOverrideProps,
        handleChangeValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        debouncedHandleChangeValue,
        handleAddItemToListWithOutValue,
        handleAddItemToListWithValue,
        handleRemoveItemFromList,
        wrappedHandleApplyFormChanges,
        handleChangeBlockValidation,
        onClose,
        handleCheckForm,
        handlePreviewForm,
        subscribableControl,
      ],
    )

  return (
    <InformationOfExecutionAgreementEventsModalContext.Provider value={preparedValue}>
      {children}
    </InformationOfExecutionAgreementEventsModalContext.Provider>
  )
}

export { useInformationOfExecutionAgreementEventsManager }

export default InformationOfExecutionAgreementEventsModalManager
