import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultAAgreementForceCircumstancesFormValue } from '@components/Forms/AAgreementForceCircumstancesForm/const'
import AAgreementForceCircumstancesLayout from '@components/Forms/AAgreementForceCircumstancesForm/Layout'

const AAgreementForceCircumstancesLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultAAgreementForceCircumstancesFormValue}>
      <AAgreementForceCircumstancesLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(AAgreementForceCircumstancesLayoutWithFormProvider)
