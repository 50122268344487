import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useAssignmentMonetaryOrPledgeAManager } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/Manager'
import { AssignmentMonetaryOrPledgeAFormValues } from '@components/Forms/AssignmentMonetaryOrPledgeAForm/types'

import { mapOfAssignmentMonetaryOrPledgeAMenu } from '../const'
import AssignmentMonetaryOrPledgeAForms from '../Forms'

const mapOfAssignmentMonetaryOrPledgeAForms: FormItemProps = {
  '1': {
    node: <AssignmentMonetaryOrPledgeAForms.First />,
  },
  '2': {
    node: <AssignmentMonetaryOrPledgeAForms.Second />,
  },
} as const

const AssignmentMonetaryOrPledgeAForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<AssignmentMonetaryOrPledgeAFormValues>()

  const {
    state: { formIsLoading },
  } = useAssignmentMonetaryOrPledgeAManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfAssignmentMonetaryOrPledgeAMenu}
      mapOfForms={mapOfAssignmentMonetaryOrPledgeAForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(AssignmentMonetaryOrPledgeAForm)
