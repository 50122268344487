import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useInitialCollapseStateList } from '@components/DocumentFormComponents/hooks/useInitialCollapseStateList'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { parametersApplicationReimbursementExpensesBlockValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/const'
import SubscribableCompensationFact from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Forms/3/AdditionalForms/SubscribableCompensationFact'
import { useParametersApplicationReimbursementExpensesManager } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/Manager'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import {
  ParametersApplicationReimbursementExpensesFieldArrayControlUpdateWatcher,
  ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersApplicationReimbursementExpensesForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isUndefined } from '@helpers/checkTypes'

const { isFormFieldError } = DocumentFormHelpers
const Third = () => {
  const formInstance = useFormContext<ParametersApplicationReimbursementExpensesFormValues>()

  const {
    state: { editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useParametersApplicationReimbursementExpensesManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher,
  })

  const { fields: compensationsFacts } = useFieldArraySubscribableControl<
    ParametersApplicationReimbursementExpensesFormValues,
    '3.compensationsFacts',
    'keyNameId'
  >({
    control: formInstance?.control,
    name: parametersApplicationReimbursementExpensesBlockValues['3'].compensationsFacts,
    watcher: ParametersApplicationReimbursementExpensesFieldArrayControlUpdateWatcher,
  })

  const compensationsFactsError = formInstance.getFieldState(
    parametersApplicationReimbursementExpensesBlockValues['3'].compensationsFacts,
  )?.error

  const { initialCollapseState } = useInitialCollapseStateList(compensationsFacts)

  const handleAddCompensationFact = async () => {
    await handleAddItemToListWithOutValue?.(
      parametersApplicationReimbursementExpensesBlockValues['3'].compensationsFacts,
    )

    if (!compensationsFacts.length) {
      formInstance.clearErrors(
        parametersApplicationReimbursementExpensesBlockValues['3'].compensationsFacts,
      )
    }
  }

  const handleRemoveCompensationFact = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${parametersApplicationReimbursementExpensesBlockValues['3'].compensationsFacts}.${index}`,
      parametersApplicationReimbursementExpensesBlockValues['3'].compensationsFacts,
    )
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            {!editMode && !compensationsFacts.length && <EmptyDataText />}
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersApplicationReimbursementExpensesBlockValues['3'].compensationsFacts,
              })}
            >
              <FlipperList list={compensationsFacts} gap={1}>
                {compensationsFacts.map((compensationFact, index) => {
                  const currentInitState = !isUndefined(initialCollapseState[compensationFact.id])
                    ? initialCollapseState[compensationFact.id]
                    : true

                  return (
                    <SubscribableCompensationFact
                      key={compensationFact.id}
                      id={compensationFact.id}
                      anchorId={compensationFact.anchorId}
                      indexOfFact={index}
                      initialAccordionState={currentInitState}
                      initialAccordionMountState={currentInitState}
                      onRemove={editMode ? handleRemoveCompensationFact(index) : undefined}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Col>
          {isFormFieldError(compensationsFactsError) && !compensationsFacts.length && (
            <Row className={'px-1'}>
              <FormError rowClassName={'px-4'} colClassName={'px-3'}>
                {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
              </FormError>
            </Row>
          )}
        </Row>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddCompensationFact}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить информацию о предоставлении меры поддержки
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Third
