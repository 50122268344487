import { FormModifierAdapters } from '@components/DocumentFormComponents/adapters/Modifier.adapter'
import {
  CertificateOfImplementationInvestprojectStagesFormModifierValues,
  IStepMonitoringEndedModifier,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'
import { isArray } from '@helpers/checkTypes'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

const { getModifierStatesFormPropertyState } = FormModifierAdapters

const generateFormModifierValuesForCertificateOfImplementationInvestprojectStagesForm = (
  objectFromPropertyState: Record<string, any>,
): CertificateOfImplementationInvestprojectStagesFormModifierValues => {
  const preparedStepsMonitoringEnded: IStepMonitoringEndedModifier[] = (() => {
    if (
      !isArray(objectFromPropertyState.stepsMonitoringEnded?.value) ||
      !objectFromPropertyState.stepsMonitoringEnded?.value?.length
    )
      return []

    return objectFromPropertyState.stepsMonitoringEnded?.value.map(
      (stepMonitoringEnded): IStepMonitoringEndedModifier => ({
        id: stepMonitoringEnded.propertyId,
        endedStepTotalCapitalValue: getModifierStatesFormPropertyState<
          typeof PropertyTypeEnum.STRING
        >(stepMonitoringEnded.value?.endedStepTotalCapitalValue),
        endedStepInvestorCapitalValue: getModifierStatesFormPropertyState<
          typeof PropertyTypeEnum.STRING
        >(stepMonitoringEnded.value?.endedStepInvestorCapitalValue),
        isEndedStepActivityChange:
          stepMonitoringEnded.value?.endedStepActivityName?.isPublic &&
          stepMonitoringEnded.value?.endedStepActivityValue?.isPublic,
      }),
    )
  })()

  return {
    '3': {
      stepsMonitoringEnded: preparedStepsMonitoringEnded,
    },
  }
}

export { generateFormModifierValuesForCertificateOfImplementationInvestprojectStagesForm }
