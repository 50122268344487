import { useCallback, useMemo } from 'react'
import { UseFormRegister, UseFormReturn } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

//Возвращает модифицированный useForm инстанс, дополненный правилом на валидацию значения с trim(). Также для работы необходимо rule={required}

export const validateNotWhiteSpaceOnly = <T>(value: T) => {
  if (value && typeof value === 'string') {
    return value.trim().length > 0 ? true : 'Поле не может быть пустым'
  }
  return true
}

export const useFormWrapperWithTrim = <T>(
  formInstance: UseFormReturn<T>,
): UseFormReturn<T> & { defaultControl: Control<T, any> } => {
  const { register } = formInstance

  const wrappedRegister: UseFormRegister<T> = useCallback(
    (name, options) => {
      if (!options || !options.required) return register(name, options)

      const originalValidationRules =
        typeof options.validate === 'function' ? { default: options.validate } : options.validate

      const wrappedOptions = {
        ...options,
        validate: {
          ...originalValidationRules,
          whitespaces: validateNotWhiteSpaceOnly,
        },
      }
      return register(name, wrappedOptions)
    },
    [register],
  )

  const control = useMemo(
    () => ({
      ...formInstance.control,
      register: wrappedRegister,
    }),
    [formInstance.control, wrappedRegister],
  )

  return {
    ...formInstance,
    register: wrappedRegister,
    control,
    defaultControl: formInstance.control,
  }
}
