import { FC } from 'react'

import AddChangesToProject from '@components/Projects/[id]/DocumentLayout/Navigation/Actions/AddChangesToProject'

interface ActionsProps extends FC {
  AddChangesToProject: typeof AddChangesToProject
}

const Actions: ActionsProps = () => null

Actions.AddChangesToProject = AddChangesToProject

export default Actions
