import { useCallback, useRef } from 'react'

const useCurrentTableUpdater = () => {
  const currentTableUpdater = useRef<VoidFunction | null>(null)

  const setCurrentTableUpdater = useCallback(
    (updater: VoidFunction) => (currentTableUpdater.current = updater),
    [],
  )

  return {
    updater: currentTableUpdater,
    setUpdater: setCurrentTableUpdater,
  }
}

export default useCurrentTableUpdater
