//Подготавливает категории для объекта
import { NewDfosType } from '@constants/types'
import { TAllDfoTypes } from '@services/Dfo/Dfo.entity'
import { TGetDfoTypes } from '@services/Dictionaries/dictionaries.entity'
import { GroupType } from '@services/Projects/Project.entity'

const CategoriesNames = {
  EXTRA: 'EXTRA',
  PETITION: 'PETITION',
  OTHER: 'OTHER',
} as const

type IAgreementIntoCategories = {
  [key in 'EXTRA' | 'PETITION' | 'OTHER']?: TGetDfoTypes
}

const DfoCategoryTypes = {
  EXTRA: 'EXTRA',
  NPA_LIST_UPDATE: 'NPA_LIST_UPDATE',
  RELATED_CONTRACT_LIST: 'RELATED_CONTRACT_LIST',

  TERMINATION: 'TERMINATION',
  SZPK: 'SZPK',
  INVESTPROJECT: 'INVESTPROJECT',
  NPA_LIST: 'NPA_LIST',
  MONITORING: 'MONITORING',
  ORGANIZATION: 'ORGANIZATION',
  OTHER_DONATIONS: 'OTHER_DONATIONS',
  CLAIM_SUBSIDY_PERMIT: 'CLAIM_SUBSIDY_PERMIT',
  CLAIM_TAX_REFUND: 'CLAIM_TAX_REFUND',
} as const

const extraCategoryTypes: TAllDfoTypes[] = [
  NewDfosType.SZPK_RIGHTS_TRANSFER,
  NewDfosType.ORG_REORGANIZATION,
  NewDfosType.MUNICIPAL_ACCESSION_UPDATE,
]

const hasAnyCategoryValue = (categories: IAgreementIntoCategories): boolean => {
  return Object.keys(categories).some((key) => {
    const value = categories[key as keyof IAgreementIntoCategories]
    return value && Object.keys(value).length > 0
  })
}

const divideAgreementTypesIntoCategories = (
  dfoAvailableForCreate: TAllDfoTypes[],
  dfoTypes: TGetDfoTypes,
): IAgreementIntoCategories => {
  return dfoAvailableForCreate.reduce((prevValue, currentValue) => {
    const dfoByType = dfoTypes[currentValue] || ''

    if (
      currentValue.startsWith(DfoCategoryTypes.EXTRA) ||
      extraCategoryTypes.includes(currentValue)
    ) {
      return {
        ...prevValue,
        EXTRA: {
          ...prevValue.EXTRA,
          [currentValue]: dfoByType,
        },
      } as IAgreementIntoCategories
    } else if (
      currentValue === DfoCategoryTypes.NPA_LIST_UPDATE ||
      currentValue === DfoCategoryTypes.RELATED_CONTRACT_LIST
    ) {
      return {
        ...prevValue,
        PETITION: {
          ...prevValue.PETITION,
          [currentValue]: dfoByType,
        },
      } as IAgreementIntoCategories
    }

    if (
      !currentValue.startsWith(GroupType.CLAIM_SUBSIDY_PERMIT) &&
      !currentValue.startsWith(GroupType.CLAIM_TAX_REFUND) &&
      !currentValue.startsWith(NewDfosType.SZPK_TERMINATE) &&
      currentValue !== NewDfosType.ORG_REORGANIZATION_SPLIT_OFF &&
      currentValue !== NewDfosType.OTHER_DONATIONS
    ) {
      return {
        ...prevValue,
        OTHER: {
          ...prevValue.OTHER,
          [currentValue]: dfoByType,
        },
      } as IAgreementIntoCategories
    }

    return prevValue
  }, {} as IAgreementIntoCategories)
}

const compareCategoryFunction = (
  prevValue: [string, Record<string, unknown>],
  nextValue: [string, Record<string, unknown>],
) => {
  const [categoryName] = prevValue
  const [categoryNextName] = nextValue

  if (categoryNextName === CategoriesNames.EXTRA) {
    if (categoryName === CategoriesNames.EXTRA) {
      return 0
    }
    return 1
  }

  if (categoryNextName === CategoriesNames.PETITION && categoryName !== CategoriesNames.EXTRA) {
    return 1
  }

  if (categoryNextName === CategoriesNames.OTHER && categoryName === CategoriesNames.OTHER) {
    return 0
  }

  return -1
}
export type { IAgreementIntoCategories }

export {
  CategoriesNames,
  compareCategoryFunction,
  DfoCategoryTypes,
  divideAgreementTypesIntoCategories,
  hasAnyCategoryValue,
}
