import { isFunction, isNullOrUndefined } from '@helpers/checkTypes'
import { defaultActionConfig, stackErrorMap } from '@services/DocumentActions/DocumentActions.const'
import {
  IStackFullProps,
  TDocumentStackAction,
} from '@services/DocumentActions/DocumentActions.entity'

type UpdateActionProps = {
  initialProps: TDocumentStackAction['initialProps']
  type?: TDocumentStackAction['type']
  config?: TDocumentStackAction['config']
}

type UpdateStackByTypeProps =
  | UpdateActionProps
  | ((prevActionProps: TDocumentStackAction) => UpdateActionProps)

type RequiredActionConfig = Required<TDocumentStackAction['config']>

type TChangeReturnedProps =
  | TDocumentStackAction['returnedProps']
  | ((prevConfig: TDocumentStackAction['returnedProps']) => TDocumentStackAction['returnedProps'])

type TChangeConfigProps =
  | RequiredActionConfig
  | ((prevConfig: RequiredActionConfig) => RequiredActionConfig)

type TChangeInitialProps =
  | TDocumentStackAction['initialProps']
  | ((prevConfig: TDocumentStackAction['initialProps']) => TDocumentStackAction['initialProps'])

class DocumentActionState {
  private readonly _documentActionState: TDocumentStackAction | null = null

  constructor(documentActionState: TDocumentStackAction) {
    this._documentActionState = {
      ...documentActionState,
      config: documentActionState.config ?? defaultActionConfig,
    }
  }

  get getFullProps(): TDocumentStackAction | null {
    return this._documentActionState
  }

  get id() {
    return this._documentActionState?.id
  }

  get type() {
    return this._documentActionState?.type
  }

  get initialProps() {
    return this._documentActionState?.initialProps
  }

  get returnedProps() {
    return this._documentActionState?.returnedProps
  }

  get fullConfig() {
    return this._documentActionState?.config
  }

  changeType(newType: TDocumentStackAction['type']) {
    if (!this._documentActionState) return

    this._documentActionState.type = newType
  }

  changeInitialProps(newInitialProps: TChangeInitialProps) {
    if (!this._documentActionState) return

    if (isFunction(newInitialProps)) {
      this._documentActionState.initialProps = newInitialProps(
        this._documentActionState.initialProps,
      )
      return
    }

    this._documentActionState.initialProps = newInitialProps as TDocumentStackAction['initialProps']
  }

  changeReturnedProps(newReturnedProps: TChangeReturnedProps) {
    if (!this._documentActionState) return

    if (isFunction(newReturnedProps)) {
      this._documentActionState.returnedProps = newReturnedProps(
        this._documentActionState.returnedProps,
      )
      return
    }

    this._documentActionState.returnedProps =
      newReturnedProps as TDocumentStackAction['returnedProps']
  }

  changeConfig(newConfig: TChangeConfigProps) {
    if (!this._documentActionState) return

    if (isFunction(newConfig)) {
      this._documentActionState.config = newConfig(this._documentActionState.config)
      return
    }

    this._documentActionState.config = newConfig as TDocumentStackAction['config']
  }
}

class DocumentActionStack {
  private readonly _historyStack: DocumentActionState[] = []
  private readonly _memoryStack: DocumentActionState[] = []
  private _callStack: TDocumentStackAction['id'][] = []
  private _processingAction: DocumentActionState | null = null
  private _prevAction: DocumentActionState | null = null
  private _nextAction: DocumentActionState | null = null

  constructor(newStack: DocumentActionState[]) {
    this._callStack = newStack.map((stack) => stack.id || '')
    this._memoryStack = newStack

    this._nextAction = newStack[0]
  }

  get fullStackProps(): IStackFullProps {
    return {
      historyStack: this._historyStack,
      memoryStack: this._memoryStack,
      callStack: this._callStack,
      processingAction: this._processingAction,
      prevAction: this._prevAction,
      nextAction: this._nextAction,
    }
  }

  get getCallStack() {
    return this._callStack
  }

  get getHistoryStack() {
    return this._historyStack
  }

  get getMemoryStack() {
    return this._memoryStack
  }

  get prevAction() {
    return this._prevAction
  }

  get nextAction() {
    return this._nextAction
  }

  get processingAction() {
    return this._processingAction
  }

  private processEndOfStack() {}

  private shiftAction() {
    const actionId = this._callStack.shift()

    if (!actionId) return

    this._historyStack.push(this._memoryStack[this.getActionIndexByIdInMemoryStack(actionId)])

    return actionId
  }

  updateStackById(id: TDocumentStackAction['id'], newProps: UpdateStackByTypeProps) {
    const indexOfUpdatedType = this.getActionIndexByIdInMemoryStack(id)

    //TODO: Обработать ошибку
    if (indexOfUpdatedType == -1 || !this._processingAction) return

    const actionByIndex = this._memoryStack[indexOfUpdatedType]
    const actionProps = actionByIndex.getFullProps

    const preparedProps = isFunction(newProps) ? newProps(actionProps) : newProps

    actionByIndex.changeInitialProps(preparedProps.initialProps)

    if (preparedProps.type) {
      actionByIndex.changeType(preparedProps.type)
    }

    if (preparedProps.config) {
      actionByIndex.changeConfig(preparedProps.config)
    }

    this.updateSystemFields(this._processingAction)
  }

  updateNextAction(newProps: UpdateStackByTypeProps) {
    const idOfNextAction = this._nextAction?.id

    //TODO: Обработать ошибку
    if (!idOfNextAction) return

    this.updateStackById(idOfNextAction, newProps)
  }

  resetCallStack() {
    this._callStack = []
  }

  async runNextAction() {
    try {
      return new Promise((resolve, reject) => {
        //Если нет следующего для processingAction, то stack опустошен
        if (!this._callStack.length) {
          this.processEndOfStack()
          return reject(stackErrorMap.stackIsOver)
        }

        const actionId = this.shiftAction()
        if (!actionId) return reject(stackErrorMap.stackShiftError)

        if (this.prevAction?.returnedProps) {
          this.updateNextAction((prevProps) => ({
            ...prevProps,
            initialProps: this.prevAction
              ?.returnedProps as unknown as TDocumentStackAction['initialProps'],
          }))
        }

        const currentAction = this._memoryStack[this.getActionIndexByIdInMemoryStack(actionId)]

        this.updateSystemFields(currentAction)
        resolve(true)
      })
    } catch (e) {
      throw e
    }
  }

  //Если пришёл undefined, значит последний action для обработки
  private updateSystemFields(currentProcessingAction: DocumentActionState | null) {
    const currentActionIndexInMemory = this._memoryStack.findIndex(
      (action) => action.id === currentProcessingAction?.id,
    )

    const nextAction =
      isNullOrUndefined(currentProcessingAction) || currentActionIndexInMemory === -1
        ? null
        : this._memoryStack[currentActionIndexInMemory + 1]
    const prevAction =
      currentActionIndexInMemory === -1 || currentActionIndexInMemory === 0
        ? null
        : this._memoryStack[currentActionIndexInMemory - 1]

    this.setPrevAction(prevAction)
    this.setProcessingAction(currentProcessingAction)

    this.setNextAction(nextAction)
  }

  setPrevAction(newAction: DocumentActionState | null) {
    this._prevAction = newAction
  }

  setNextAction(newAction: DocumentActionState | null) {
    this._nextAction = newAction
  }

  setProcessingAction(newAction: DocumentActionState | null) {
    this._processingAction = newAction
  }

  getActionIndexByType(type: TDocumentStackAction['type']) {
    return this._memoryStack.findIndex((action) => action.type === type)
  }

  getActionIndexByIdInMemoryStack(id: string) {
    return this._memoryStack.findIndex((action) => action.id === id)
  }
}

export { DocumentActionStack, DocumentActionState }
