import { FC, ReactNode, useRef, useState } from 'react'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import { isEmptyString, isUndefined } from '@helpers/checkTypes'
import { appendPx, removePx } from '@helpers/manipulateWithPx'
import { useResizeObserver } from '@hooks/new/observers/useResizeObserver'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import cn from 'classnames'

import styles from './RowWithBorder.module.scss'

const mapOfTitleHeights = {
  // заголовок с 3 строками
  '60px': 'pt-0',
  // заголовок с 2 строками
  '40px': 'pt-2',
  // заголовок с 1 строкой
  '20px': 'pt-3',
}

interface RowWithBorder {
  children?: ReactNode
  title?: ReactNode
  titleHeightFallback?: keyof typeof mapOfTitleHeights
  contentClassName?: string
  titleWrapperClassName?: string
  titleClassName?: string
  rowClassName?: string
  alignByTop?: boolean
  disableBottomDefaultStyles?: boolean
  disableTitleLeftPadding?: boolean
  disableTitleTopPadding?: boolean
  disableTopPadding?: boolean
  disableRightPadding?: boolean
  disableBottomPadding?: boolean
  disableFlexNoWrap?: boolean
  disableAllPadding?: boolean
  firstColumnSize?: number
  secondColumnSize?: number
}

const RowWithBorder: FC<RowWithBorder> = ({
  firstColumnSize = 8,
  secondColumnSize = 4,
  alignByTop = false,
  disableTitleLeftPadding = false,
  disableTitleTopPadding = false,
  disableRightPadding = false,
  titleHeightFallback = '20px',
  title,
  children,
  contentClassName,
  titleWrapperClassName,
  titleClassName,
  rowClassName,
  disableBottomDefaultStyles,
  disableTopPadding,
  disableBottomPadding,
  disableFlexNoWrap,
  disableAllPadding,
}) => {
  const typographyRef = useRef<HTMLParagraphElement | null>(null)
  const [heightOfTypography, setHeightOfTypography] = useState<string>('')

  const isShowTitle = !isUndefined(title) || !isEmptyString(title)

  const calculateTitleHeight: ResizeObserverCallback = ([entry]) => {
    if (!entry.target || !title) return

    const { height, paddingTop, paddingBottom } = window.getComputedStyle(entry.target)

    const preparedHeight = removePx(height)
    const preparedPaddingY = removePx(paddingTop) + removePx(paddingBottom)

    const titleHeight = preparedHeight - preparedPaddingY

    if (!titleHeight) return setHeightOfTypography(titleHeightFallback)

    setHeightOfTypography(appendPx(titleHeight))
  }

  const debouncedCalculateTitleHeight = useDebouncedCallback(calculateTitleHeight, 100)

  useResizeObserver({
    elementsToObserve: typographyRef,
    resizeListener: debouncedCalculateTitleHeight,
  })

  return (
    <Container className={'p-0'}>
      <Row
        className={cn(
          styles.rowWithBorder,
          {
            [styles['rowWithBorder--disableBottom']]:
              disableBottomDefaultStyles || disableBottomPadding,
            [styles['rowWithBorder--disableRightPadding']]: disableRightPadding,
            [styles['rowWithBorder--disableTop']]: disableTopPadding,
            [styles['rowWithBorder--disableFlexNoWrap']]: disableFlexNoWrap,
            [styles['rowWithBorder--disableAllPadding']]: disableAllPadding,
            [styles['rowWithBorder--alignByTop']]: alignByTop,

          },
          rowClassName,
        )}
      >
        {isShowTitle && (
          <Col
            xs={firstColumnSize}
            className={cn(
              styles['rowWithBorder__title-wrapper'],
              !disableTitleTopPadding && mapOfTitleHeights[heightOfTypography],
              {
                [styles['rowWithBorder__title-wrapper--disableTopPadding']]: disableTitleTopPadding,
                [styles['rowWithBorder__title--disableLeftPadding']]: disableTitleLeftPadding,
              },
              titleWrapperClassName,
            )}
          >
            <Typography.Body
              className={cn(styles.rowWithBorder__title, titleClassName)}
              variant={'bodyMRegular'}
              elRef={typographyRef}
            >
              {title}
            </Typography.Body>
          </Col>
        )}
        <Col xs={secondColumnSize} className={cn(styles.rowWithBorder__content, contentClassName)}>
          {children}
        </Col>
      </Row>
      {!disableBottomDefaultStyles && <Border />}
    </Container>
  )
}

export default RowWithBorder
