import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NpaFormValues } from '@components/Forms/NpaForm/types'
import { isArray } from '@helpers/checkTypes'
import { INpaBody, NpaPartForBody } from '@services/NPA/NPA.entity'

const generateRHFObjectForNpaForm = (
  objectFromPropertyState: Record<string, any>,
): NpaFormValues => {
  const preparedChosenParts = ((): INpaBody | null => {
    if (
      !isArray(objectFromPropertyState.stabilizationNpas?.value) ||
      !objectFromPropertyState.stabilizationNpas?.value.length
    )
      return null

    const npaParts = objectFromPropertyState.stabilizationNpas.value.map((npa): NpaPartForBody => {
      return {
        id: npa.propertyId,
        justification: npa?.value?.stabilizationNpaJustification?.value,
        partCorrection: npa?.value?.stabilizationNpaCorrection?.value,
        npaId: npa?.value?.stabilizationNpaPartId?.value?.npaId,
        partId: npa?.value?.stabilizationNpaPartId?.value?.id,
      }
    })

    return {
      npaParts,
    }
  })()

  return {
    headerInfo: {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    npa: {
      npaStructure: [],
      chosenParts: preparedChosenParts,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
    },
  }
}

export { generateRHFObjectForNpaForm }
