import { FocusEvent, MouseEvent, MutableRefObject } from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import { mergeRefs } from '@helpers/ref/mergeRefs'

import AmountInput from './AmountInput'
import { AmountInputProps } from './types'

export type ControlledAmountInputProps = Omit<
  AmountInputProps,
  | 'onBlur'
  | 'onChange'
  | 'onFocus'
  | 'onPaste'
  | 'onClear'
  | 'onKeyDown'
  | 'value'
  | 'ref'
  | 'error'
> & {
  ref?: MutableRefObject<HTMLInputElement | null>
}

export type TControlledAmountProps<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> &
  Pick<
    AmountInputProps,
    'onBlur' | 'onChange' | 'onFocus' | 'onPaste' | 'onClear' | 'onKeyDown' | 'id'
  > & {
    inputProps: ControlledAmountInputProps
  }

export const ControlledAmountInput = <T extends FieldValues>(props: TControlledAmountProps<T>) => {
  const {
    onBlur,
    onFocus,
    onChange,
    onPaste,
    onClear,
    onKeyDown,
    inputProps,
    id,
    ...controllerProps
  } = props
  const {
    field: { onChange: onFormChange, onBlur: onFormBlur, value, ref },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const mergedOnChange = (e, payload) => {
    onChange?.(e, payload)
    onFormChange(payload.valueString)
  }

  const mergedOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e)

    onFormBlur()
  }

  const mergedOnClear = (e: MouseEvent<HTMLButtonElement>) => {
    onClear?.(e)

    onFormChange('')
  }

  const refToInput = inputProps.ref ? mergeRefs([ref, inputProps.ref]) : ref

  return (
    <AmountInput
      value={value}
      id={id}
      error={error}
      onBlur={mergedOnBlur}
      onChange={mergedOnChange}
      onFocus={onFocus}
      onPaste={onPaste}
      onClear={mergedOnClear}
      onKeyDown={onKeyDown}
      {...inputProps}
      ref={refToInput}
    />
  )
}

ControlledAmountInput.displayName = 'ControlledAmountInput'
