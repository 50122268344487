import React from 'react'

import cn from 'classnames'

import { TypographyComponent } from './types'
import styles from './Typography.module.scss'

const Typography: TypographyComponent = ({
  align = 'left',
  as = 'p',
  color = 'on-surface-primary',
  children,
  className,
  fontWeight,
  variant,
  elRef,
  dataTestId,
  ...props
}) => {
  const modifiedClassName = cn(className, styles.typographyFontWeight, {
    [styles[variant]]: variant,
    [styles[`color-${color}`]]: !!color,
    [styles[align]]: align,
    ...(fontWeight && { [styles[fontWeight]]: fontWeight }),
  })

  return React.createElement(
    as,
    {
      className: modifiedClassName,
      'data-testid': dataTestId,
      ...props,
      ref: elRef,
    },
    children,
  )
}

Typography.Headline = ({ variant, children, as = 'h1', ...props }) => {
  return (
    <Typography variant={variant} as={as} {...props}>
      {children}
    </Typography>
  )
}

Typography.Body = ({ variant, children, ...props }) => {
  return (
    <Typography variant={variant} {...props}>
      {children}
    </Typography>
  )
}

Typography.Button = ({ variant, children, ...props }) => {
  return (
    <Typography variant={variant} {...props}>
      {children}
    </Typography>
  )
}

Typography.Caption = ({ variant, children, ...props }) => {
  return (
    <Typography variant={variant} {...props}>
      {children}
    </Typography>
  )
}

export default Typography
