import React, { memo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import StepCompensation from '@components/Forms/CreateStatement/Forms/Compensations/Compensation'
import { ProjectCompensationsPathName } from '@components/Forms/CreateStatement/Forms/Compensations/types'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import {
  CreateStatementControlUpdateWatcher,
  CreateStatementFieldArrayControlUpdateWatcher,
} from '@components/Forms/CreateStatement/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const ExpensesCompensationArrayForm = () => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CreateStatementControlUpdateWatcher,
  })

  const { fields: compensations } = useFieldArraySubscribableControl<
    StatementFormValues,
    ProjectCompensationsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: statementBlockValues.compensation.projectCompensations,
    keyName: 'keyNameId',
    watcher: CreateStatementFieldArrayControlUpdateWatcher,
  })

  const handleAppendCompensation = useCallback(
    async (e) => {
      e.preventDefault()

      await handleAddItemToListWithOutValue?.(
        statementBlockValues.compensation.projectCompensations,
      )
    },
    [handleAddItemToListWithOutValue],
  )

  const handleRemoveCompensation = useCallback(
    (index: number) => async () => {
      await handleRemoveItemFromList?.(
        `${statementBlockValues.compensation.projectCompensations}.${index}`,
        statementBlockValues.compensation.projectCompensations,
      )
    },
    [handleRemoveItemFromList],
  )

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: statementBlockValues.compensation.projectCompensations,
          })}
        >
          <FlipperList list={compensations}>
            {compensations.map((stage, index) => {
              const formName =
                `${statementBlockValues.compensation.projectCompensations}.${index}` as const

              return (
                <StepCompensation
                  key={stage.id}
                  id={stage.id}
                  name={formName}
                  index={index}
                  onRemoveCompensation={handleRemoveCompensation(index)}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAppendCompensation}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    dataTestId="ExpensesCompensationArrayForm-add-button"
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default memo(ExpensesCompensationArrayForm)
