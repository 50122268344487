import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

import KnowledgeBase from '@components/KnowledgeBase'
import { mockKnowledgeHashMap } from '@components/KnowledgeBase/__mock__'
import KnowledgeBaseMobile from '@components/KnowledgeBase/Mobile'
import type { IKnowledgeBaseItem } from '@components/KnowledgeBase/types'
import Footer from '@components/Layout/Footer'
import { useQueryManager } from '@hooks/useQueryManager'

import styles from './MobileKnowledgeBase.module.scss'

const MobileKnowledgeBase = () => {
  const { queryUtils } = useQueryManager()

  const [rootBaseItem, setRootBaseItem] = useState<IKnowledgeBaseItem | null>(null)

  const pageViewId = useMemo(() => {
    return queryUtils.getQuery('pageViewId')
  }, [queryUtils])

  const handleSelectBaseItem = useCallback((baseItem: IKnowledgeBaseItem) => {
    setRootBaseItem(baseItem)
  }, [])

  const handleClearBaseItem = useCallback(() => {
    setRootBaseItem(null)
  }, [])

  const findRootBaseItemRecursively = useCallback((baseItem: IKnowledgeBaseItem) => {
    if (!baseItem.parentBaseId) {
      return setRootBaseItem(baseItem)
    }

    findRootBaseItemRecursively(mockKnowledgeHashMap[baseItem.parentBaseId])
  }, [])

  useLayoutEffect(() => {
    if (!pageViewId) return

    findRootBaseItemRecursively(mockKnowledgeHashMap[pageViewId])
  }, [])

  return (
    <KnowledgeBase.Manager initializationPageViewId={pageViewId}>
      <KnowledgeBaseMobile.Header onBack={handleClearBaseItem} />
      <KnowledgeBaseMobile.Info name={rootBaseItem?.name} />
      <div className={styles.main}>
        {rootBaseItem?.children && <KnowledgeBaseMobile.Menu menuList={rootBaseItem.children} />}
        <KnowledgeBaseMobile.Preview />
        <KnowledgeBaseMobile.RootList
          selectedRootItem={rootBaseItem}
          onSelectBaseItem={handleSelectBaseItem}
        />
      </div>
      <Footer />
    </KnowledgeBase.Manager>
  )
}

export default MobileKnowledgeBase
