import React, { FC, memo, useCallback } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import InformationOfExecutionConditionsSZPK from '@components/Forms/InformationOfExecutionConditionsSZPKForm'
import { getInformationOfExecutionConditionsSZPKMenuByStatus } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/Menu/const'
import { InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import InformationOfExecutionConditionsSZPKLayoutWrapper from './Wrapper'

const InformationOfExecutionConditionsSZPKLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const handleOnClose = useCallback(() => {
    InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  const initialMenuState = getInformationOfExecutionConditionsSZPKMenuByStatus()

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <InformationOfExecutionConditionsSZPK.MenuManager
        initialMenuHash={initialMenuState.initialAAgreementOnCreationOtherEstateObjectsMenuState}
        initialSectionId={initialMenuState.mapOfMenu[0].id}
      >
        <InformationOfExecutionConditionsSZPK.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
          onClose={handleOnClose}
        >
          <InformationOfExecutionConditionsSZPKLayoutWrapper
            lastUpdateDraftTime={lastUpdateDraftTime}
          >
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Данные об исполнении условий СЗПК
              </Typography.Headline>
              <InformationOfExecutionConditionsSZPK.StatementForm className={styles.layout__form} />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}>
              <InformationOfExecutionConditionsSZPK.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </InformationOfExecutionConditionsSZPKLayoutWrapper>
        </InformationOfExecutionConditionsSZPK.Manager>
      </InformationOfExecutionConditionsSZPK.MenuManager>
    </DocumentFormLayout>
  )
}

export default memo(InformationOfExecutionConditionsSZPKLayout)
