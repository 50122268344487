import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import { defaultFiltersValue } from '@components/RegistryNpa/const'
import { TFiltersModalFormValues } from '@components/RegistryNpa/Header/Filters'
import { IFiltersFormValues } from '@components/RegistryNpa/Manager'

interface ReusableFiltersTooltipLogicProps {
  handleApplyFilters?: (formValues: TFiltersModalFormValues) => void
  initialFilters?: Partial<IFiltersFormValues['filters']>
}

const useReusableFiltersTooltipLogicOld = ({
  initialFilters,
  handleApplyFilters,
}: ReusableFiltersTooltipLogicProps) => {
  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
  } = useManualTooltipControl()

  const { control, getValues, watch, setValue, reset } = useForm<TFiltersModalFormValues>({
    defaultValues: {
      isFederal: initialFilters?.isFederal ?? true,
      isRegional: initialFilters?.isRegional ?? true,
      isMunicipal: !!initialFilters?.isMunicipal,
      oktmo: '',
      part: '',
      region: initialFilters?.region ?? '',
      direction: '',
      type: '',
    },
  })

  const filtersInModal = watch()

  const isRegional = filtersInModal?.isRegional
  const isFederal = filtersInModal?.isFederal
  const isMunicipal = filtersInModal?.isMunicipal
  const region = filtersInModal?.region

  const handleApplyButton = useCallback(() => {
    const formValues = getValues()

    handleApplyFilters?.(formValues)

    handleCloseTooltip()
  }, [getValues, handleApplyFilters, handleCloseTooltip])

  const handleResetButton = useCallback(() => {
    reset({
      isFederal: initialFilters?.isFederal ?? true,
      isRegional: initialFilters?.isRegional ?? true,
      isMunicipal: initialFilters?.isMunicipal ?? true,
      part: '',
      region: '',
      oktmo: '',
      direction: '',
      type: '',
    })

    handleApplyFilters?.(defaultFiltersValue.filters)

    handleCloseTooltip()
  }, [handleApplyFilters, handleCloseTooltip, reset])

  const handleCloseFilters = () => {
    setTimeout(() => {
      reset(initialFilters)
    }, 55)
  }

  return {
    isRegional,
    isFederal,
    isMunicipal,
    region,
    tooltipIsOpen,
    handleOpenTooltip,
    handleCloseTooltip,
    control,
    watch,
    handleApplyButton,
    handleResetButton,
    handleCloseFilters,
    handleGetFiltersInModal: getValues,
    handleSetFilter: setValue,
  }
}

export { useReusableFiltersTooltipLogicOld }
