import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'

const generateRHFObjectForNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValuesForm =
  (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues => {
    return {
      '1': {
        ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
      },
      '2': {
        ...RHFBasicAdapters.getUOAdapter(objectFromPropertyState),
      },
      '3': {
        ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
        investorFullNameInstrumental:
          objectFromPropertyState.investorFullNameInstrumental?.value || '',
      },
      '4': {
        ruleLinkSolicitationRelatedContractChecking:
          objectFromPropertyState.ruleLinkSolicitationRelatedContractChecking?.value || '',
        ruleLinkSolicitationRelatedContractReturn:
          objectFromPropertyState.ruleLinkSolicitationRelatedContractReturn?.value || '',
        solicitationRelatedContractTechError:
          objectFromPropertyState.solicitationRelatedContractTechError?.value || '',
        solicitationRelatedContractDocumentError:
          objectFromPropertyState.solicitationRelatedContractDocumentError?.value || '',
        solicitationRelatedContractRequirementError:
          objectFromPropertyState.solicitationRelatedContractRequirementError?.value || '',
        solicitationRelatedContractRequirementLinkError:
          objectFromPropertyState.solicitationRelatedContractRequirementLinkError?.value || '',
        solicitationRelatedContractContractKindError:
          objectFromPropertyState.solicitationRelatedContractContractKindError?.value || '',
        solicitationRelatedContractRuleError:
          objectFromPropertyState.solicitationRelatedContractRuleError?.value || '',
        solicitationRelatedContractRuleLinkError:
          objectFromPropertyState.solicitationRelatedContractRuleLinkError?.value || '',
        solicitationRelatedContractRelatedContractError:
          objectFromPropertyState.solicitationRelatedContractRelatedContractError?.value || '',
        solicitationRelatedContractCreditContractError:
          objectFromPropertyState.solicitationRelatedContractCreditContractError?.value || '',
        solicitationRelatedContractOtherError:
          objectFromPropertyState.solicitationRelatedContractOtherError?.value || '',
        solicitationRelatedContractOtherDetails:
          objectFromPropertyState.solicitationRelatedContractOtherDetails?.value || '',
        solicitationDaysRevision: objectFromPropertyState.solicitationDaysRevision?.value || '',
      },
    }
  }

export { generateRHFObjectForNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValuesForm }
