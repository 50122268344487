import React, { ComponentType, createContext, useContext } from 'react'
import { Navigate, useParams } from 'react-router'

import Loader from '@components/Loader'
import { Paths } from '@constants/paths'
import { HttpStatusCode } from '@constants/statusCodes'
import { useProject } from '@hooks/new/swr/useProject'
import { useBooleanState } from '@hooks/useBooleanState'
import { IProject } from '@services/Projects/Project.entity'

interface ProjectPermissionsContextProps {
  haveProjectPermission: boolean
  projectLayoutDisabled: boolean
  isLoadingRequest: boolean
  requestProjectPermission: (disableLayout?: boolean) => Promise<IProject | undefined> | null
}

const ProjectPermissionsContext = createContext<ProjectPermissionsContextProps>({
  haveProjectPermission: true,
  projectLayoutDisabled: false,
  isLoadingRequest: false,
  requestProjectPermission: () => null,
})

function withPermissionsProtect<T = unknown>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const { haveProjectPermission, projectLayoutDisabled, isLoadingRequest } =
      usePermissionsProjectManager()

    if (isLoadingRequest && projectLayoutDisabled) return <Loader loading />

    if (!haveProjectPermission) return <Navigate replace to={Paths.Projects} />

    return <WrappedComponent {...props} />
  }
}

const usePermissionsProjectManager = () => {
  return useContext(ProjectPermissionsContext)
}

const ProjectPermissionsManager = ({ children }) => {
  const { projectId } = useParams()

  const { booleanState: haveProjectPermission, setBooleanState: changePermissionAccess } =
    useBooleanState(true)
  const { booleanState: projectLayoutDisabled, setBooleanState: changeDisabledLayout } =
    useBooleanState(true)

  const onErrorHandler = (error) => {
    if (error.response?.status === HttpStatusCode.FORBIDDEN) changePermissionAccess(false)
  }

  const { isLoadingProject, mutate } = useProject({
    key: {
      projectId,
      _key: 'project',
    },
    config: {
      revalidateOnMount: true,
      onError: onErrorHandler,
    },
  })

  const handleRequestProjectPermission = async (disableProjectLayout = true) => {
    changeDisabledLayout(disableProjectLayout)

    try {
      return await mutate()
    } catch (error) {
      throw error
    } finally {
      changeDisabledLayout(true)
    }
  }

  return (
    <ProjectPermissionsContext.Provider
      value={{
        haveProjectPermission,
        projectLayoutDisabled,
        isLoadingRequest: isLoadingProject,
        requestProjectPermission: handleRequestProjectPermission,
      }}
    >
      {children}
    </ProjectPermissionsContext.Provider>
  )
}

export { usePermissionsProjectManager, withPermissionsProtect }
export default ProjectPermissionsManager
