import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  isEmptyString,
  isFunction,
  isNullOrUndefined,
  isNumber,
  isObject,
  isString,
} from '@helpers/checkTypes'
import { removeExtraQuotes } from '@helpers/string/removeExtraQuotes'
import { BasePropertyState } from '@services/Properties/OOP/BaseClass'
import {
  PropertyTypeEnum,
  TPropertyValueCustom,
  TPropertyValueFieldName,
} from '@services/Properties/Properties.entity'
import { generateDefaultValueByType } from '@services/Properties/Properties.helpers'
import {
  PropertiesWatcher,
  SubscriberPropertiesWatcherUpdateValueProps,
} from '@services/Properties/Properties.watcher'

const { formatBackendMoneyToString, getDictionaryNameByCatalogType } = DocumentFormHelpers

interface PropertyStateCustomModifiers {
  '@new': boolean
}

const generateCustomValueByType = (
  property: TPropertyValueCustom,
  propertyValueName: TPropertyValueFieldName = '@value',
) => {
  if (property['@type'] === PropertyTypeEnum.MONEY) {
    if (
      isNullOrUndefined(property[propertyValueName]) ||
      isEmptyString(property[propertyValueName])
    )
      return ''

    return formatBackendMoneyToString(property[propertyValueName])
  }

  if (
    property['@type'] &&
    property['@type'] === PropertyTypeEnum.NPA &&
    isObject(property[propertyValueName] && isString(property[propertyValueName].id))
  ) {
    return {
      ...property[propertyValueName],
      id: removeExtraQuotes(property[propertyValueName].id as string),
    }
  }

  if (
    property['@type'] &&
    property['@type'] === PropertyTypeEnum.NPA_PART &&
    isObject(property[propertyValueName] && isString(property[propertyValueName].id))
  ) {
    return {
      ...property[propertyValueName],
      id: removeExtraQuotes(property[propertyValueName].id as string),
    }
  }

  if (
    property['@type'] &&
    property['@type'].startsWith(PropertyTypeEnum.LOOKUP) &&
    isObject(property[propertyValueName] && isString(property[propertyValueName].id))
  ) {
    return {
      ...property[propertyValueName],
      id: removeExtraQuotes(property[propertyValueName].id as string),
    }
  }

  if (
    property['@type'] &&
    property['@type'].startsWith(PropertyTypeEnum.FIXED_LOOKUP) &&
    isObject(property[propertyValueName] && isString(property[propertyValueName].id))
  ) {
    return {
      ...property[propertyValueName],
      id: removeExtraQuotes(property[propertyValueName].id as string),
    }
  }

  if (property['@type'] === PropertyTypeEnum.ORGANIZATION) {
    return isObject(property[propertyValueName])
      ? Object.entries(property[propertyValueName]).reduce((prevValue, [key, value]) => {
          if (isNumber(value)) {
            prevValue[key] = formatBackendMoneyToString(value)

            return prevValue
          }

          prevValue[key] = value

          return prevValue
        }, {})
      : property[propertyValueName]
  }

  return property[propertyValueName]
}

class PropertyStateCustom extends BasePropertyState {
  private _propertyValueInfo: TPropertyValueCustom | null = null
  private _propertyModifiersInfo: PropertyStateCustomModifiers | null = null

  constructor(
    propertyKey: string,
    propertyValue: TPropertyValueCustom,
    watcher: PropertiesWatcher,
  ) {
    super(propertyKey, {
      '@id': propertyValue['@id'],
      '@isPublic': propertyValue['@isPublic'],
      '@lastUpdateDt': propertyValue['@lastUpdateDt'],
      '@order': propertyValue['@order'],
      '@permanentId': propertyValue['@permanentId'],
    })

    this._propertyModifiersInfo = {
      '@new': propertyValue['@new'] ?? false,
    }

    this._propertyValueInfo = {
      ...this._propertyModifiersInfo,
      '@type': propertyValue['@type'],
      '@isPublic': propertyValue['@isPublic'],
      '@value': generateDefaultValueByType(propertyValue),
      '@commitedValue': propertyValue['@commitedValue'],
    } as TPropertyValueCustom

    if (this.getPropertyId) {
      watcher.subscribe(this.getPropertyId, {
        updateValueCallback: this.updateValueCallback,
      })
    }
  }

  get getType() {
    return this._propertyValueInfo?.['@type']
  }

  get getNew() {
    return this._propertyModifiersInfo?.['@new']
  }

  get getIsPublic() {
    return this._propertyValueInfo?.['@isPublic']
  }

  private readonly updateValueCallback = (
    newValue: SubscriberPropertiesWatcherUpdateValueProps['updateValue'],
  ) => {
    const objectToUpdate = isFunction(newValue) ? newValue(this._propertyValueInfo) : newValue

    this.setLastUpdateDt(objectToUpdate['@lastUpdateDt'])
    this.setOrder(objectToUpdate['@order'] ?? this.getOrder)

    if (
      !isNullOrUndefined(objectToUpdate['@value']) &&
      objectToUpdate['@type'] &&
      isString(objectToUpdate['@value'])
    )
      return

    this._propertyValueInfo = {
      '@type': objectToUpdate['@type'],
      '@value': objectToUpdate['@value'],
      '@isPublic': objectToUpdate['@isPublic'],
      '@commitedValue': objectToUpdate['@commitedValue'],
    }

    this._propertyModifiersInfo = {
      '@new': objectToUpdate['@new'] ?? false,
    }
  }

  generatePropertiesProps() {
    if (!this._propertyValueInfo) return null

    return {
      propertyId: this.getPropertyId,
      type: this.getType,
      lastUpdateDt: this.getLastUpdateDate,
      new: this.getNew,
    }
  }

  generateObjectForAdapterFromPropertiesState() {
    if (!this._propertyValueInfo) return null

    return {
      ...this.generatePropertiesProps(),
      permanentId: this.getPermanentId,
      isPublic: this.getIsPublic,
      value: generateCustomValueByType(this._propertyValueInfo),
      commitedValue: generateCustomValueByType(this._propertyValueInfo, '@commitedValue'),
    }
  }

  getOverrideProps() {
    if (!this._propertyValueInfo) return null

    if (this._propertyValueInfo?.['@type'].startsWith(PropertyTypeEnum.CATALOG)) {
      return {
        type: PropertyTypeEnum.CATALOG,
        initialType: this.getType,
        propertyId: this.getPropertyId,
        dictionaryName: getDictionaryNameByCatalogType(this._propertyValueInfo['@type']),
      }
    }

    if (this._propertyValueInfo?.['@type'].startsWith(PropertyTypeEnum.LOOKUP)) {
      return {
        type: PropertyTypeEnum.LOOKUP,
        initialType: this.getType,
        propertyId: this.getPropertyId,
      }
    }

    if (this._propertyValueInfo?.['@type'].startsWith(PropertyTypeEnum.FIXED_LOOKUP)) {
      return {
        type: PropertyTypeEnum.FIXED_LOOKUP,
        initialType: this.getType,
        propertyId: this.getPropertyId,
      }
    }

    if (this._propertyValueInfo?.['@type'] === PropertyTypeEnum.NPA) {
      return {
        type: PropertyTypeEnum.NPA,
        initialType: this.getType,
        propertyId: this.getPropertyId,
      }
    }

    if (this._propertyValueInfo?.['@type'] === PropertyTypeEnum.NPA_PART) {
      return {
        type: PropertyTypeEnum.NPA_PART,
        initialType: this.getType,
        propertyId: this.getPropertyId,
      }
    }
  }
}

export { PropertyStateCustom }
