import { useCallback } from 'react'

import { generatePrefetchedPropsForStatement } from '@components/Forms/CreateStatement/adapters/PrefetchedProps.adapter'
import { generatePropertiesObjectForStatement } from '@components/Forms/CreateStatement/adapters/PropertiesObject.adapter'
import { generateRHFObjectForCreateStatement } from '@components/Forms/CreateStatement/adapters/RHF.adapter'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'

const useCreateStatementAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): StatementFormValues =>
      generateRHFObjectForCreateStatement(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForStatement(objectFromPropertyState),
    [],
  )

  const generatePrefetchedPropsArray = useCallback(
    (objectFromPropertyState: Record<string, any>): string[] =>
      generatePrefetchedPropsForStatement(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generatePropertiesObject, generatePrefetchedPropsArray }
}

export { useCreateStatementAdapters }
