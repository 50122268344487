import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const twelfthSectionCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsFormValues['12'],
  CertificateOnMonitoringResultsFormValues
> = {
  otherReasonNameChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  otherReasonDescriptionChecker: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  changeRecommendations: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { twelfthSectionCertificateOnMonitoringResultsValidationMap }
