import React, { FC } from 'react'

import ReferenceBooksHierarchyRoot from '@components/ReferenceBooks/HierarchyType/Root'
import styles from '@components/ReferenceBooks/ReferenceBooks.module.scss'
import Layouts from '@layouts'
import { BaseReferenceBooksPageProps } from '@routes/ReferenceBooks/[dictionaryName]/types'

const ReferenceBooksHierarchyPage: FC<BaseReferenceBooksPageProps> = ({ titleText }) => {
  return (
    <Layouts.Main
      headerContentContainer={{
        className: styles.referenceBooks__headerContentContainer,
      }}
    >
      <ReferenceBooksHierarchyRoot titleText={titleText} />
    </Layouts.Main>
  )
}

export default ReferenceBooksHierarchyPage
