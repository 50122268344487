const mapOfDeadlineViewType = {
  DEFAULT: 'DEFAULT',
  RECOMMENDED: 'RECOMMENDED',
}

const deadlineMayBeExtendedWithDfoType = {
  CLAIM_: 'CLAIM_',
} as const

export { deadlineMayBeExtendedWithDfoType, mapOfDeadlineViewType }
