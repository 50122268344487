import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import StatementByHeadOfMunicipality from '@components/Forms/StatementByHeadOfMunicipality'
import {
  defaultStatementByHeadOfMunicipalityFormValues,
  mapOfStatementByHeadOfMunicipalityMenu,
} from '@components/Forms/StatementByHeadOfMunicipality/const'
import { StatementByHeadOfMunicipalityFormValues } from '@components/Forms/StatementByHeadOfMunicipality/types'
import { StatementByHeadOfMunicipalityFieldsControlUpdateWatcher } from '@components/Forms/StatementByHeadOfMunicipality/watcher'
import Typography from '@components/NewDesign/Typography'

import StatementByHeadOfMunicipalityLayoutWrapper from './Wrapper'

const StatementByHeadOfMunicipalityLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<StatementByHeadOfMunicipalityFormValues>({
    defaultValues: defaultStatementByHeadOfMunicipalityFormValues,
  })

  const handleOnClose = () => {
    StatementByHeadOfMunicipalityFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <StatementByHeadOfMunicipality.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfStatementByHeadOfMunicipalityMenu[0].id}>
            <StatementByHeadOfMunicipalityLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Заявление главы муниципального образования
                </Typography.Headline>
                <StatementByHeadOfMunicipality.StatementForm className={styles.layout__form} />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <StatementByHeadOfMunicipality.Menu className={styles.layout__menu} />
              </div>
            </StatementByHeadOfMunicipalityLayoutWrapper>
          </FlatMenuManager>
        </StatementByHeadOfMunicipality.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(StatementByHeadOfMunicipalityLayout)
