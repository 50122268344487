import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { useWatchFieldsForValidation } from '@components/Forms/CreateStatement/Forms/Stage/hooks'
import StepProjectArrayForm from '@components/Forms/CreateStatement/Forms/Stage/StageBlock'
import { stageFieldValidation } from '@components/Forms/CreateStatement/Forms/Stage/validation'
import { useCreateStatementManager } from '@components/Forms/CreateStatement/Manager'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { CreateStatementControlUpdateWatcher } from '@components/Forms/CreateStatement/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const StageBlockForm = () => {
  const formInstance = useFormContext<StatementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCreateStatementManager()

  const { getSubscribableControlProps, getCalendarInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CreateStatementControlUpdateWatcher,
  })

  useWatchFieldsForValidation(formInstance)

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: statementBlockValues.stage.projectStartDate,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: statementBlockValues.stage.projectStartDate,
                        rules: stageFieldValidation.projectStartDate,

                        calendarInputProps: {
                          label: 'Дата начала проекта',
                          dataTestId: 'projectStartDate',
                        },
                        onBlur: () =>
                          setTimeout(
                            () => handleChangeValue?.(statementBlockValues.stage.projectStartDate),
                            0,
                          ),
                        onInputChange: () =>
                          setTimeout(
                            () =>
                              debouncedHandleChangeValue?.(
                                statementBlockValues.stage.projectStartDate,
                              ),
                            0,
                          ),
                        onCalendarChange: () =>
                          setTimeout(
                            () => handleChangeValue?.(statementBlockValues.stage.projectStartDate),
                            0,
                          ),
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: statementBlockValues.stage.projectEndDate,
                    })}
                  >
                    <ControlledCalendarInput
                      {...getCalendarInputProps({
                        name: statementBlockValues.stage.projectEndDate,
                        rules: stageFieldValidation.projectEndDate,

                        calendarInputProps: {
                          label: 'Дата окончания проекта',
                          dataTestId: 'projectEndDate',
                        },
                        onBlur: () =>
                          setTimeout(
                            () => handleChangeValue?.(statementBlockValues.stage.projectEndDate),
                            0,
                          ),
                        onInputChange: () =>
                          setTimeout(
                            () =>
                              debouncedHandleChangeValue?.(
                                statementBlockValues.stage.projectEndDate,
                              ),
                            0,
                          ),
                        onCalendarChange: () =>
                          setTimeout(
                            () => handleChangeValue?.(statementBlockValues.stage.projectEndDate),
                            0,
                          ),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
              <FieldView.RowWithBottomBorder
                title={'Планируемая дата окончания соглашения'}
                firstColumnSize={6}
                secondColumnSize={6}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: statementBlockValues.stage.agreementEndDate,
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: statementBlockValues.stage.agreementEndDate,
                      rules: stageFieldValidation.agreementEndDate,

                      calendarInputProps: {
                        label: 'ДД.ММ.ГГГГ',
                        dataTestId: 'agreementEndDate',
                      },
                      onBlur: () =>
                        setTimeout(
                          () => handleChangeValue?.(statementBlockValues.stage.agreementEndDate),
                          0,
                        ),
                      onInputChange: () =>
                        setTimeout(
                          () =>
                            debouncedHandleChangeValue?.(
                              statementBlockValues.stage.agreementEndDate,
                            ),
                          0,
                        ),
                      onCalendarChange: () =>
                        setTimeout(
                          () => handleChangeValue?.(statementBlockValues.stage.agreementEndDate),
                          0,
                        ),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Col>
        </Row>
        <Row>
          <StepProjectArrayForm />
        </Row>
      </Stack>
    </Container>
  )
}

export default memo(StageBlockForm)
