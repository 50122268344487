import React, { FC, memo } from 'react'

import NpaDocumentForm from '@components/DocumentFormComponents/Form/NpaForm'
import { NpaFormItemProps } from '@components/DocumentFormComponents/Form/NpaForm/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import NpaForms from '@components/Forms/NpaForm/Forms'
import { useNpaFormManager } from '@components/Forms/NpaForm/Manager'

const mapOfNpaForms: NpaFormItemProps[] = [
  {
    id: 'headerInfo',
    node: <NpaForms.HeaderInfo />,
  },
  {
    id: 'npa',
    node: <NpaForms.NPA />,
  },
]

const NpaFormStatement: FC<FormModalStatementProps> = (props) => {
  const {
    state: { formIsLoading },
  } = useNpaFormManager()

  return <NpaDocumentForm {...props} formIsLoading={formIsLoading} mapOfForms={mapOfNpaForms} />
}

export default memo(NpaFormStatement)
