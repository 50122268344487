import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectsFormValues,
  IConstructionStage,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const fifthSectionValidationMap: FormValuesValidationSection<
  keyof IConstructionStage,
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  stepCompensationForm: {
    required: defaultRHFValidation.required,
  },
}

export { fifthSectionValidationMap }
