import { usePopupManager } from 'react-popup-manager'

import WarnSignSZPSignSZPKModal from '.'

const useWarnSignSZPKModal = () => {
  const popupManager = usePopupManager()

  const handleOpenUseWarnSignSZPKModal = () => {
    popupManager.open(WarnSignSZPSignSZPKModal, {
      onClose: () => null,
    })
  }

  return {
    handleOpenUseWarnSignSZPKModal,
  }
}

export { useWarnSignSZPKModal }
