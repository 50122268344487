import { forwardRef, ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { getDefaultPortalContainer, setRef } from './helpers'

export type PortalProps = {
  /** Контент */
  children?: ReactNode

  /**
   * Функция, возвращающая контейнер, в который будут рендериться дочерние элементы
   */
  getPortalContainer?: () => Element
}
const Portal = forwardRef<Element, PortalProps>(
  ({ getPortalContainer = getDefaultPortalContainer, children }, ref) => {
    const [mountNode, setMountNode] = useState<Element | null>(null)

    useEffect(() => {
      setMountNode(getPortalContainer())
    }, [getPortalContainer])

    useEffect(() => {
      if (mountNode) {
        setRef(ref, mountNode)
        return () => {
          setRef(ref, null)
        }
      }
      return () => null
    }, [ref, mountNode])

    return mountNode ? createPortal(children, mountNode) : mountNode
  },
)

export default Portal
