import React, { Children, cloneElement, FunctionComponent, ReactElement } from 'react'

import * as constants from '@components/ReactFlipToolkit/constants'
import { FlipContext, PortalContext } from '@components/ReactFlipToolkit/Flipper/context'
import { FlippedProps, SerializableFlippedProps } from '@components/ReactFlipToolkit/types'
import * as utilities from '@components/ReactFlipToolkit/utils'

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(child: any): child is Function {
  return typeof child === 'function'
}

// This wrapper creates child components for the main Flipper component
export const Flipped: FunctionComponent<SerializableFlippedProps> = ({
  children,
  flipId,
  inverseFlipId,
  portalKey,
  ...rest
}) => {
  let child = children
  const isFunctionAsChildren = isFunction(child)

  if (!isFunctionAsChildren) {
    try {
      child = Children.only(children)
    } catch (e) {
      throw new Error('Each Flipped component must wrap a single child')
    }
  }

  // if nothing is being animated, assume everything is being animated
  if (!rest.scale && !rest.translate && !rest.opacity) {
    utilities.assign(rest, {
      translate: true,
      scale: true,
      opacity: true,
    })
  }

  const dataAttributes: Record<string, string | undefined> = {
    [constants.DATA_FLIP_CONFIG]: JSON.stringify(rest),
  }

  if (flipId !== undefined) dataAttributes[constants.DATA_FLIP_ID] = String(flipId)
  else if (inverseFlipId) dataAttributes[constants.DATA_INVERSE_FLIP_ID] = String(inverseFlipId)
  if (portalKey !== undefined) {
    dataAttributes[constants.DATA_PORTAL_KEY] = portalKey
  }
  if (isFunctionAsChildren) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (child as Function)(dataAttributes)
  }
  return cloneElement(child as ReactElement<any>, dataAttributes)
}
// @ts-ignore
export const FlippedWithContext: FunctionComponent<FlippedProps> = ({
  children,
  flipId,
  shouldFlip,
  shouldInvert,
  onAppear,
  onStart,
  onStartImmediate,
  onComplete,
  onExit,
  onSpringUpdate,
  ...rest
}) => {
  if (!children) {
    return null
  }
  if (rest.inverseFlipId) {
    return <Flipped {...rest}>{children}</Flipped>
  }

  return (
    <PortalContext.Consumer>
      {(portalKey) => (
        <FlipContext.Consumer>
          {(data) => {
            // if there is no surrounding Flipper component,
            // we don't want to throw an error, so check
            // that data exists and is not the default string
            if (utilities.isObject(data) && flipId) {
              data[flipId] = {
                shouldFlip,
                shouldInvert,
                onAppear,
                onStart,
                onStartImmediate,
                onComplete,
                onExit,
                onSpringUpdate,
              }
            }
            return (
              <Flipped flipId={flipId} {...rest} portalKey={portalKey}>
                {children}
              </Flipped>
            )
          }}
        </FlipContext.Consumer>
      )}
    </PortalContext.Consumer>
  )
}

export default FlippedWithContext
