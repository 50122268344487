import { StatusViewProps } from '@components/Forms/RegistrationForm/StatusView'
import { defaultMessageSupportWithLink, errorModalHeaderTexts } from '@constants/texts'
import { ComponentInfo } from '@services/LoggerService/LoggerService.entity'

const mapOfRegistrationStatus = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
} as const

const mapOfTypeRedirect = {
  ESIA: 'ESIA',
  UKEP: 'UKEP',
  MCHD: 'MCHD',
} as const

const mapOfRegistrationReducerActions = {
  CHECK_STATUS: 'CHECK_STATUS',
  SETUP_STATUS: 'SETUP_STATUS',
  SETUP_LOADING: 'SETUP_LOADING',
  SETUP_ERROR: 'SETUP_ERROR',
  SETUP_CONTENT: 'SETUP_CONTENT',
} as const

const defaultStatusViewProps: StatusViewProps = {
  isLoading: true,
  title: 'Идёт загрузка',
  actions: [],
}

const defaultErrorStatusViewProps: StatusViewProps = {
  isLoading: false,
  title: errorModalHeaderTexts.defaultMessage,
  description: defaultMessageSupportWithLink,
  actions: [],
}

const registrationFormComponentInfo: ComponentInfo = {
  componentName: 'handleProcessCheckStatusErrors',
  moduleName: 'RegistrationForm',
  componentType: 'FC',
}

export {
  defaultErrorStatusViewProps,
  defaultStatusViewProps,
  mapOfRegistrationReducerActions,
  mapOfRegistrationStatus,
  mapOfTypeRedirect,
  registrationFormComponentInfo,
}
