import useDFOSApi, { DfoDigitizing } from '@context/APIContext/hooks/useDFOSApi'
import { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useDfosDigitizing = ({ key, config }: SWRHookProps<DfoDigitizing[] | undefined>) => {
  const { getDfosDigitizing } = useDFOSApi()

  const {
    data: dfosDigitizing,
    error,
    mutate: revalidateDfosDigitizing,
    isValidating: isLoadingDfosDigitizing,
  } = useSWRImmutable(key, getDfosDigitizing, config)

  return {
    dfosDigitizing,
    error,
    revalidateDfosDigitizing,
    isLoadingDfosDigitizing,
  }
}

export { useDfosDigitizing }
