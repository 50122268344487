import { useEffect } from 'react'
import { ArrayPath, FieldValues, useFieldArray, UseFieldArrayReturn } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import {
  FieldArrayControlUpdateWatcher,
  FieldArrayControlWatcherNotifyValue,
} from '@components/DocumentFormComponents/fieldArrayWatcher'
import { isUndefined } from '@helpers/checkTypes'

interface SubscribableControlProps<
  T extends FieldValues,
  P extends ArrayPath<T>,
  KeyNameId extends string = string,
> {
  name: P
  control: Control<T>
  watcher?: FieldArrayControlUpdateWatcher<T, P>
  keyName?: KeyNameId
}

type SubscribableControlReturnType<
  T extends FieldValues,
  P extends ArrayPath<T>,
  KeyNameId extends string = string,
> = UseFieldArrayReturn<T, P, KeyNameId>

const useFieldArraySubscribableControl = <
  T extends FieldValues,
  P extends ArrayPath<T> = ArrayPath<T>,
  KeyNameId extends string = string,
>({
  keyName = 'keyNameId' as KeyNameId,
  name,
  control,
  watcher,
}: SubscribableControlProps<T, P, KeyNameId>): SubscribableControlReturnType<T, P, KeyNameId> => {
  const fields = useFieldArray<T, P, KeyNameId>({
    name,
    control,
    keyName,
  })

  useEffect(() => {
    if (isUndefined(watcher)) return

    const appendProps = (object: FieldArrayControlWatcherNotifyValue<T, P>['appendProps']) => {
      fields.append(object, { shouldFocus: false })
    }

    const removeProps = (index: FieldArrayControlWatcherNotifyValue<T, P>['removeProps']) => {
      fields.remove(index)
    }

    const swapProps = (
      newIndex: FieldArrayControlWatcherNotifyValue<T, P>['swapProps']['newIndex'],
      oldIndex: FieldArrayControlWatcherNotifyValue<T, P>['swapProps']['oldIndex'],
    ) => {
      fields.swap(newIndex, oldIndex)
    }

    watcher.subscribe(name, {
      appendPropsCallback: appendProps,
      removePropsCallback: removeProps,
      swapPropsCallback: swapProps,
    })

    return () => {
      watcher.unsubscribe(name)
    }
  }, [name])

  return fields
}

export { useFieldArraySubscribableControl }
