import { FC } from 'react'

import One from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'

interface AAgreementOnChangingCharacteristicsOfObjectsFormsProps extends FC {
  One: typeof One
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
}

const AAgreementOnChangingCharacteristicsOfObjectsForms: AAgreementOnChangingCharacteristicsOfObjectsFormsProps =
  () => null

AAgreementOnChangingCharacteristicsOfObjectsForms.One = One
AAgreementOnChangingCharacteristicsOfObjectsForms.Second = Second
AAgreementOnChangingCharacteristicsOfObjectsForms.Third = Third
AAgreementOnChangingCharacteristicsOfObjectsForms.Fourth = Fourth
AAgreementOnChangingCharacteristicsOfObjectsForms.Fifth = Fifth

export default AAgreementOnChangingCharacteristicsOfObjectsForms
