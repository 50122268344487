import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { infrFacilitiesCostsExpectedReimbursedBlockValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import { mainProjectValidationMap } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Forms/2/MainProjectsList/validation'
import { useInfrFacilitiesCostsExpectedReimbursedManager } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Manager'
import { InfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import {
  InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher,
  InfrFacilitiesCostsExpectedReimbursedFieldArrayControlUpdateWatcher,
} from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const { isFormFieldError } = DocumentFormHelpers

interface MainProjectsListProps {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<InfrFacilitiesCostsExpectedReimbursedFormValues>
  formName: typeof infrFacilitiesCostsExpectedReimbursedBlockValues['2']['mainProjectObjects']
}

const MainProjectsList: FC<MainProjectsListProps> = ({
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useInfrFacilitiesCostsExpectedReimbursedManager()

  const { getSubscribableControlProps, getSingleSelectProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: InfrFacilitiesCostsExpectedReimbursedControlUpdateWatcher,
  })

  const { fields: mainObjects } = useFieldArraySubscribableControl<
    InfrFacilitiesCostsExpectedReimbursedFormValues,
    typeof infrFacilitiesCostsExpectedReimbursedBlockValues['2']['mainProjectObjects'],
    'keyNameId'
  >({
    control: formInstance.control,
    name: formName,
    keyName: 'keyNameId',
    watcher: InfrFacilitiesCostsExpectedReimbursedFieldArrayControlUpdateWatcher,
  })

  const mainObjectsError = formInstance.getFieldState(formName)?.error

  const handleAddNewMainProject = async () => {
    await handleAddItemToListWithOutValue?.(formName)

    if (!mainObjects.length) {
      formInstance.clearErrors(formName)
    }
  }

  const handleRemoveMainProject = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${formName}.${index}`, formName)
  }

  return (
    <Group disableBottomBorder title="Основные объекты проекта">
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: formName,
          })}
        >
          <FlipperList list={mainObjects}>
            {mainObjects.map((country, index) => {
              const mainProjectName = `${formName}.${index}` as const

              return (
                <FieldView.RowWithRemoveButton
                  key={country.id}
                  id={country.id}
                  data-testid='mainObjectListItem'
                  onRemove={editMode ? handleRemoveMainProject(index) : undefined}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${mainProjectName}.mainObjectOption`,
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.objectName,
                              value: item.id,
                            }),
                            controllerProps: {
                              name: `${mainProjectName}.mainObjectOption`,
                              rules: mainProjectValidationMap.mainObjectOption,
                            },
                            selectProps: {
                              inputProps: { label: 'Объект',
                               dataTestId: 'mainObjectOption', },
                              onChangeFormValue: () =>
                                setTimeout(
                                  () => handleChangeValue?.(`${mainProjectName}.mainObjectOption`),
                                  0,
                                ),
                            },
                          })}
                        />
                      )
                    }}
                  </SubscribableControl>
                </FieldView.RowWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(mainObjectsError) && !mainObjects.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddNewMainProject}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Указать основной объект
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default MainProjectsList
