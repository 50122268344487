import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationDrawalAppConclusionSZPKBlockValues } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/const'
import { secondSectionValidationMap } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/Forms/2/validation'
import { useNotificationDrawalAppConclusionSZPKManager } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/Manager'
import { NotificationDrawalAppConclusionSZPKFormValues } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/types'
import { NotificationDrawalAppConclusionSZPKFieldsControlUpdateWatcher } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Second = () => {
  const formInstance = useFormContext<NotificationDrawalAppConclusionSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationDrawalAppConclusionSZPKManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: NotificationDrawalAppConclusionSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Пункт Правил, на основании которого осуществляется отзыв заявления на СЗПК'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationDrawalAppConclusionSZPKBlockValues['2'].ruleLinkStatementRevoke,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationDrawalAppConclusionSZPKBlockValues['2']
                      .ruleLinkStatementRevoke,
                    rules: secondSectionValidationMap.ruleLinkStatementRevoke,
                    inputProps: {
                      caption: 'рекомендация: 33',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          notificationDrawalAppConclusionSZPKBlockValues['2']
                            .ruleLinkStatementRevoke,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationDrawalAppConclusionSZPKBlockValues['2']
                            .ruleLinkStatementRevoke,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Second
