import React, { ChangeEvent, FC, FocusEvent, memo, useRef } from 'react'
import { UseFormReturn } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { systemVariablesTextMap } from '@components/SystemVariables/const'
import { SystemVariablesFormValues } from '@components/SystemVariables/types'
import { TransformedSystemVariableItem } from '@context/APIContext/hooks/useOperatorApi'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'

import styles from './SystemVariablesItem.module.scss'

interface SystemVariablesItemProps {
  name: `variables.${number}`
  item: TransformedSystemVariableItem
  formInstance: UseFormReturn<SystemVariablesFormValues>
  onError: (variableItem: TransformedSystemVariableItem) => void
  onUpdate?: (variableItem: TransformedSystemVariableItem) => Promise<void>
}

const SystemVariablesItem: FC<SystemVariablesItemProps> = ({
  name,
  item,
  formInstance,
  onUpdate,
  onError,
}) => {
  const previousValueRef = useRef<string>(item.value)

  const handleUpdateValue = async (
    event: FocusEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target

    if (value === previousValueRef.current) return

    try {
      await onUpdate?.({
        type: item.type,
        value,
      })

      previousValueRef.current = value
    } catch (error) {
      onError({
        value: previousValueRef.current,
        type: item.type,
      })

      throw error
    }
  }

  const debounceUpdateValue = useDebouncedCallback(handleUpdateValue, 5000)

  return (
    <div className={styles.systemVariablesItem}>
      <RowWithBorder
        disableBottomDefaultStyles
        firstColumnSize={6}
        secondColumnSize={6}
        title={systemVariablesTextMap[item.type]}
      >
        <ControlledInput
          name={`${name}.value`}
          control={formInstance.control}
          inputProps={{
            view: 'secondary',
            size: 'xl',
            fixWidth: true,
          }}
          onChange={debounceUpdateValue}
          onBlur={handleUpdateValue}
        />
      </RowWithBorder>
    </div>
  )
}

export default memo(SystemVariablesItem)
