import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import type { StatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfStatementAlreadyInvestmentsMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'Капиталовложения' },
]

const statementAlreadyInvestmentsBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorAddress: '1.investorAddress',
    investorHeaderName: '1.investorHeaderName',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
  },
  '2': {
    madeInvestorCapital: '2.madeInvestorCapital',
    remainsInvestorCapital: '2.remainsInvestorCapital',
  },
} as const

const defaultStatementAlreadyInvestmentsFormValues: StatementAlreadyInvestmentsFormValues = {
  '1': {
    ...getDefaultInvestorFieldState(),
    investorInn: '',
    investorOgrn: '',
    investorAddress: '',
  },
  '2': {
    madeInvestorCapital: '',
    remainsInvestorCapital: '',
  },
}

export {
  defaultStatementAlreadyInvestmentsFormValues,
  mapOfStatementAlreadyInvestmentsMenu,
  statementAlreadyInvestmentsBlockValues,
}
