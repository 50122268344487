import { FC, memo } from 'react'

import { useKnowledgeBase } from '@components/KnowledgeBase/Manager'
import Typography from '@components/NewDesign/Typography'

import styles from './Info.module.scss'

interface KnowledgeBaseMobileInfoProps {
  name?: string
}

const KnowledgeBaseMobileInfo: FC<KnowledgeBaseMobileInfoProps> = ({ name }) => {
  const {
    state: { currentBaseItem },
  } = useKnowledgeBase()

  return (
    <div className={styles.info}>
      <Typography.Headline variant="headlineH2" className={styles.info__title}>
        {name || 'Справочная информация'}
      </Typography.Headline>
      {!currentBaseItem && (
        <Typography.Body variant="bodyLMedium" fontWeight="normal" className={styles.info__text}>
          Краткое руководство о том, как работает ГИС «КАПИТАЛОВЛОЖЕНИЯ»
        </Typography.Body>
      )}
    </div>
  )
}

export default memo(KnowledgeBaseMobileInfo)
