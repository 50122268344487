import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import IconButton from '@components/NewDesign/IconButton'
import Sidebar from '@components/NewDesign/Sidebar'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import { useRemoveModal } from '@components/NewDesignedModals/RemoveModal'
import { useNpaEditSidebar } from '@components/Sidebars/NPA/Edit'
import MainAct from '@components/Sidebars/NPA/View/Item'
import { RolesTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { useAuthContext } from '@context/AuthContext'
import { useNpaById } from '@hooks/new/swr/useNpaById'
import { useOrganizationInfo } from '@hooks/new/swr/useOrganizationInfo'
import ExpandLessIcon from '@icons/navigation/expand_less.svg'
import ExpandMoreIcon from '@icons/navigation/expand_more.svg'
import OriginalLinkIcon from '@icons/OriginalLinkIcon.svg'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import styles from './NPAView.module.scss'

export interface NpaViewComponentProps {
  npaId: string
  onReloadGrid?: () => void
}

type NpaViewSidebarProps = PopupProps & NpaViewComponentProps

const useNpaViewSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenNpaViewSidebar = (props: NpaViewComponentProps) => {
    popupManager.open(NpaViewSidebar, props)
  }

  return {
    handleOpenNpaViewSidebar,
  }
}

const NpaViewSidebar: FC<NpaViewSidebarProps> = ({ npaId = '', onReloadGrid, isOpen, onClose }) => {
  const {
    state: { tooltipIsOpen, targetTooltipRef },
    handlers: { handleOpenTooltip, handleCloseTooltip },
    bindHandlersRef: { bindRefOfClickableOutsideZone },
  } = useManualTooltipControl()

  const { checkingRole } = useAuthContext()

  const {
    npaApi: { excludeNpa },
  } = useAPIContext()

  const popupManager = usePopupManager()

  const { handleOpenRemoveModal } = useRemoveModal()
  const { handleOpenNpaEditSidebar } = useNpaEditSidebar()

  const [currentChooseRedaction, setCurrentChooseRedaction] = useState<string>('')

  const {
    npa,
    isNpaLoading,
    mutate: handleReloadNpa,
  } = useNpaById({
    key: {
      npaId,
      _key: 'npa',
    },
    config: {
      revalidateOnMount: true,
    },
  })

  const isMER = !!checkingRole?.(RolesTypes.MER)
  const isOIV = !!checkingRole?.(RolesTypes.OIV)
  const isOMSU = !!checkingRole?.(RolesTypes.OMSU)

  const { organizationInfo } = useOrganizationInfo({
    key: { _key: 'organizationInfo' },
    config: {
      isPaused: () => isMER,
    },
  })

  useEffect(() => {
    const actualRedactionVersion = npa?.redactions?.filter((redaction) => redaction.isActual)[0].id

    setCurrentChooseRedaction(actualRedactionVersion || '')
  }, [npa])

  const handleChooseRedaction = (id: string) => () => {
    setCurrentChooseRedaction(id)
    handleCloseTooltip()
  }

  const actualNpaInfo = useMemo(() => {
    return npa?.redactions?.find((redaction) => redaction.isActual)
  }, [npa?.redactions])

  const currentRedactionToRender = useMemo(() => {
    if (!currentChooseRedaction) return

    return npa?.redactions.find((redaction) => redaction.id === currentChooseRedaction)
  }, [currentChooseRedaction, npa?.redactions])

  const tooltipContent = useMemo(
    () => (
      <div className={styles.NPAView__redactions}>
        {npa?.redactions.map((redaction, index) => (
          <Button
            key={index}
            size={'s'}
            className={styles.NPAView__chooseButton}
            view={'plain'}
            variant={'buttonSMedium'}
            onClick={handleChooseRedaction(redaction.id)}
          >
            <Typography.Button variant={'buttonSMedium'} color={'text-base-tertiary'}>
              {redaction.redactionNumber} редакция
            </Typography.Button>
          </Button>
        ))}
      </div>
    ),
    [npa?.redactions],
  )

  const handleExcludeNpa = useCallback(async () => {
    if (!actualNpaInfo) return

    try {
      await excludeNpa(actualNpaInfo.id)

      popupManager.closeAll()
      onReloadGrid?.()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          actualNpaInfo,
        },
        componentInfo: {
          componentName: 'NpaViewSidebar',
          moduleName: 'excludeNPA',
        },
      })(error)

      throw error
    }
  }, [actualNpaInfo, excludeNpa, onReloadGrid, popupManager])

  const handleEditNPA = useCallback(() => {
    if (!actualNpaInfo) return

    popupManager.closeAll()

    handleOpenNpaEditSidebar({
      npa: actualNpaInfo,
      onReloadGrid,
      onReloadNpa: handleReloadNpa,
    })
  }, [actualNpaInfo, handleOpenNpaEditSidebar, handleReloadNpa, onReloadGrid, popupManager])

  const OIVIsHaveAccessToEditOrExclude = useMemo(() => {
    if (!actualNpaInfo || !isOIV) return

    return (
      actualNpaInfo.region === organizationInfo?.regionCode && actualNpaInfo.level === 'REGIONAL'
    )
  }, [actualNpaInfo, isOIV, organizationInfo?.regionCode])

  const OMSUIsHaveAccessToEditOrExclude = useMemo(() => {
    if (!actualNpaInfo || !isOMSU) return

    return (
      actualNpaInfo.oktmo === organizationInfo?.municipalityOktmo &&
      actualNpaInfo.level === 'MUNICIPAL'
    )
  }, [actualNpaInfo, isOMSU, organizationInfo])

  const actions = useMemo(() => {
    const defaultActions =
      npa?.showActions && currentRedactionToRender?.isActual
        ? ([
            {
              children: 'Исключить',
              disabled: !npa.enableActions,
              onClick: () =>
                handleOpenRemoveModal({
                  title: 'Исключить НПА из реестра?',
                  description:
                    'Стабилизируемые положения, которые относятся к НПА, будут так же удалены из реестра \nи        недоступны для выбора инвестором',
                  confirmButtonText: 'Исключить',
                  onConfirm: handleExcludeNpa,
                }),
              view: 'gray',
              color: 'negative',
            },
            {
              children: 'Редактировать',
              disabled: !npa.enableActions,
              onClick: handleEditNPA,
              view: 'gray',
            },
          ] as ButtonProps[])
        : undefined

    return isMER || OIVIsHaveAccessToEditOrExclude || OMSUIsHaveAccessToEditOrExclude
      ? defaultActions
      : undefined
  }, [
    OIVIsHaveAccessToEditOrExclude,
    OMSUIsHaveAccessToEditOrExclude,
    currentRedactionToRender?.isActual,
    handleEditNPA,
    handleExcludeNpa,
    handleOpenRemoveModal,
    isMER,
    npa?.enableActions,
    npa?.showActions,
  ])

  const preparedOnCloseHandler = () => {
    onClose?.()
  }

  return (
    <Sidebar
      headerClassName={styles['NPAView__sidebar-header']}
      contentClassName={styles['NPAView__sidebar-content']}
      title={currentRedactionToRender?.name || ''}
      isOpen={isOpen}
      actions={actions}
      ref={bindRefOfClickableOutsideZone}
      headerAddons={
        currentRedactionToRender?.link && (
          <IconButton
            className={styles.NPAView__link}
            href={currentRedactionToRender.link}
            target={'_blank'}
            geometry={'square'}
            view={'basic'}
            size={'m'}
            icon={{
              src: OriginalLinkIcon,
              noCurrentColorSvgFill: true,
              size: 'initial',
            }}
          />
        )
      }
      onClose={preparedOnCloseHandler}
    >
      <Loader wrapperClassName={styles.NPAView__loader} variant={'lite'} loading={isNpaLoading}>
        <div
          className={cn(styles.NPAView, {
            [styles['NPAView--disableVersions']]: !npa?.showRedactions,
          })}
        >
          {npa?.showRedactions && (
            <Tooltip
              useAnchorWidth
              contentClassName={styles.tooltip__content}
              arrowClassName={styles.tooltip__arrow}
              position={'bottom'}
              targetRef={targetTooltipRef}
              open={tooltipIsOpen}
              trigger={'click'}
              content={tooltipContent}
              zIndex={51}
              onClose={handleCloseTooltip}
            >
              <Button
                size="s"
                view="gray"
                geometry="round"
                className={styles.NPAView__version}
                variant={'buttonSMedium'}
                trailingIcon={{
                  src: tooltipIsOpen ? ExpandLessIcon : ExpandMoreIcon,
                  noCurrentColorSvgFill: true,
                }}
                onClick={handleOpenTooltip}
              >
                <Typography.Button variant={'buttonSMedium'} color={'text-base-tertiary'}>
                  {currentRedactionToRender?.redactionNumber} редакция
                </Typography.Button>
              </Button>
            </Tooltip>
          )}
          {currentRedactionToRender && (
            <div className={styles.NPAView__mainAct}>
              <MainAct redaction={currentRedactionToRender} />
            </div>
          )}
        </div>
      </Loader>
    </Sidebar>
  )
}

export { useNpaViewSidebar }
export default memo(NpaViewSidebar)
