import { reportReducerActionMap } from '@routes/Reports/constants'

import type { ReportModuleState, ReportReducerAction, ReportReducerActionCreators } from './types'

const initialReportState: ReportModuleState = {
  reports: [],
  reportsIsLoading: false,
  reportsError: null,

  searchPlaceholder: false,
  noDataPlaceholder: false,

  countOfFilters: 1,
}

const reportReducer = (
  state: ReportModuleState,
  action: ReportReducerAction,
): ReportModuleState => {
  const { type } = action

  switch (action.type) {
    case reportReducerActionMap.SETUP_LOADING:
      return {
        ...state,
        reportsIsLoading: action.payload,
      }

    case reportReducerActionMap.SETUP_REPORTS: {
      return {
        ...state,
        reports: action.payload,
        reportsIsLoading: false,
      }
    }

    case reportReducerActionMap.SETUP_ERROR: {
      return {
        ...state,
        reportsIsLoading: false,
        reportsError: action.payload,
      }
    }

    case reportReducerActionMap.SETUP_SEARCH_PLACEHOLDER: {
      return {
        ...state,
        searchPlaceholder: action.payload,
      }
    }

    case reportReducerActionMap.SETUP_NO_DATA_PLACEHOLDER: {
      return {
        ...state,
        noDataPlaceholder: action.payload,
      }
    }

    case reportReducerActionMap.REMOVE_PLACEHOLDERS: {
      return {
        ...state,
        noDataPlaceholder: false,
        searchPlaceholder: false,
      }
    }

    case reportReducerActionMap.SETUP_FILTERS_COUNT: {
      return { ...state, countOfFilters: action.payload }
    }

    case reportReducerActionMap.RESET_ALL_DATA: {
      return {
        reports: [],
        reportsIsLoading: true,
        searchPlaceholder: false,
        noDataPlaceholder: false,
        reportsError: null,
        countOfFilters: 1,
      }
    }

    default:
      return state
  }
}

const reportActionCreators: ReportReducerActionCreators = {
  removePlaceholders: () => ({ type: reportReducerActionMap.REMOVE_PLACEHOLDERS }),
  resetAllData: () => ({ type: reportReducerActionMap.RESET_ALL_DATA }),
  setLoading: (payload) => ({ type: reportReducerActionMap.SETUP_LOADING, payload }),
  setReports: (payload) => ({ type: reportReducerActionMap.SETUP_REPORTS, payload }),
  setError: (payload) => ({ type: reportReducerActionMap.SETUP_ERROR, payload }),
  setFiltersCount: (payload) => ({ type: reportReducerActionMap.SETUP_FILTERS_COUNT, payload }),
  setSearchPlaceholder: (payload) => ({
    type: reportReducerActionMap.SETUP_SEARCH_PLACEHOLDER,
    payload,
  }),
  setNoDataPlaceholder: (payload) => ({
    type: reportReducerActionMap.SETUP_NO_DATA_PLACEHOLDER,
    payload,
  }),
}

export { initialReportState, reportActionCreators, reportReducer }
