import { useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'

import Alert from '@components/Alert'
import { Paths } from '@constants/paths'
import { HttpStatusCode } from '@constants/statusCodes'
import { Urls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { isAxiosError } from '@helpers/checkTypes'
import { useQueryManager } from '@hooks/useQueryManager'
import { emailVerifiedControl } from '@layouts/Header/Message/constants'
import { FetcherWithInterceptors } from '@packages/api/fetcher'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import storage from 'store2'

const approveEmail = async (approveId, email) =>
  await FetcherWithInterceptors.post({
    url: Urls.ApproveEmail,
    data: { approveId, email },
  })

const Approve = () => {
  const { queryUtils } = useQueryManager()

  const navigate = useNavigate()

  const { refreshToken } = useAuthContext()

  const approveId = queryUtils.getQuery('approveId')
  const email = queryUtils.getQuery('email')

  const approveEmailHandler = useCallback(async () => {
    try {
      await approveEmail(approveId, email)

      await refreshToken?.({
        refresh_token: storage.get('auth_state')?.refreshToken,
      })
      // Указываем, что почта подтверждена и единоразово нужно показать об этом уведомление
      emailVerifiedControl.set()

      toast(
        <Alert transparent variant="success">
          Ваша почта успешно подтверждена
        </Alert>,
      )
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'Approve',
          moduleName: 'App',
          componentType: 'approveEmailHandler',
        },
      })(error)

      if (!isAxiosError(error)) throw error

      if (error.response?.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
        toast(
          <Alert transparent variant="error">
            Не удалось подтвердить почту, попробуйте позже
          </Alert>,
        )
      } else {
        toast(
          <Alert transparent variant="warning">
            {error.response?.data.detail || error.response?.data.title}
          </Alert>,
        )
      }
    } finally {
      //Сработает HOC  и перенаправит на правильную страницу от роли
      navigate(Paths.Projects)
    }
  }, [approveId, email, navigate, refreshToken])

  useEffect(() => {
    (async () => {
      await approveEmailHandler()
    })()
  }, [approveEmailHandler])

  return null
}

export default Approve
