import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generatePropertiesObjectForNotificationOfIdentifiedNPAListErrorsForm } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNotificationOfIdentifiedNPAListErrorsForm } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/adapters/RHF.adapter'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/types'

import { generateMenuListForNotificationOfIdentifiedNPAListErrorsMenuState } from './Menu.adapter'

const useNotificationOfIdentifiedNPAListErrorsAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfIdentifiedNPAListErrorsFormValues =>
    generateRHFObjectForNotificationOfIdentifiedNPAListErrorsForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNotificationOfIdentifiedNPAListErrorsForm(objectFromPropertyState)

  const generateMenuListByFormValues = (
    formValues:
      | NotificationOfIdentifiedNPAListErrorsFormValues
      | UnpackNestedValue<NotificationOfIdentifiedNPAListErrorsFormValues>,
    initialHashMap: NestedMenuHashMap,
    formErrors: FieldErrors<NotificationOfIdentifiedNPAListErrorsFormValues>,
  ): NestedMapOfMenu[] =>
    generateMenuListForNotificationOfIdentifiedNPAListErrorsMenuState(
      formValues,
      initialHashMap,
      formErrors,
    )

  return { generateRHFObject, generatePropertiesObject, generateMenuListByFormValues }
}

export { useNotificationOfIdentifiedNPAListErrorsAdapters }
