import React, { FC, useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { PopupProps } from 'react-popup-manager'

import FieldInfo from '@components/NewDesign/FieldInfo'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Sidebar from '@components/NewDesign/Sidebar'
import Typography from '@components/NewDesign/Typography/Typography'
import { defaultRHFValidation, NewPatterns, Patterns } from '@constants/validations'
import ActionSidebarButtons from '@containers/ActionSidebarButtons'
import FieldCondition from '@containers/FieldCondition'
import { useAPIContext } from '@context/APIContext'
import type { Operator } from '@context/APIContext/hooks/useOperatorApi'
import { encodingsConverter } from '@helpers/encodings/converter'
import { useBooleanState } from '@hooks/useBooleanState'
import { useFormWrapperWithTrim, validateNotWhiteSpaceOnly } from '@hooks/useFormWrapperWithTrim'
import cn from 'classnames'

import styles from './ManageOperatorSidebar.module.scss'

export interface ManageOperatorSidebarProps {
  user?: Operator
  onSuccess?: () => Promise<void>
}

type TManageOperatorSidebar = ManageOperatorSidebarProps & Required<PopupProps>

interface OperatorFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  position: string
  isEnabled: boolean
}

const operatorsTitleMap = {
  add: 'Добавить оператора',
  edit: 'Редактирование оператора',
} as const

const ManageOperatorSidebar: FC<TManageOperatorSidebar> = ({
  isOpen,
  onSuccess,
  onClose,
  user,
}) => {
  const { id: userId, firstName, lastName, email, position, isEnabled } = user ?? {}

  const {
    operatorApi: { updateOperatorById, saveOperator },
  } = useAPIContext()

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { isValid },
  } = useFormWrapperWithTrim(
    useForm<OperatorFormValues>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        email: email ?? '',
        password: '',
        position: position ?? '',
        isEnabled: isEnabled ?? true,
      },
    }),
  )

  const {
    booleanState: isLoadingSaveOperator,
    setBooleanStateToFalse: setLoadingSaveOperatorToFalse,
    setBooleanStateToTrue: setLoadingSaveOperatorToTrue,
  } = useBooleanState(false)

  const {
    booleanState: isLoadingBlockOperator,
    setBooleanStateToFalse: setLoadingBlockOperatorToFalse,
    setBooleanStateToTrue: setLoadingBlockOperatorToTrue,
  } = useBooleanState(false)

  const submitForm: SubmitHandler<OperatorFormValues> = useCallback(
    async ({ isEnabled, email, password, ...formData }) => {
      if (isEnabled) {
        setLoadingSaveOperatorToTrue()
      } else {
        setLoadingBlockOperatorToTrue()
      }

      const preparedEmail = encodingsConverter.ToUnicode(email)

      try {
        if (!userId) {
          await saveOperator({
            ...formData,
            email: preparedEmail,
            password,
          })
        } else {
          await updateOperatorById(userId, {
            ...formData,
            email: preparedEmail,
            isEnabled,
          })
        }

        await onSuccess?.()

        onClose()
      } finally {
        if (isEnabled) {
          setLoadingSaveOperatorToFalse()
        } else {
          setLoadingBlockOperatorToFalse()
        }
      }
    },
    [
      userId,
      setLoadingSaveOperatorToTrue,
      setLoadingBlockOperatorToTrue,
      onSuccess,
      onClose,
      saveOperator,
      updateOperatorById,
      setLoadingSaveOperatorToFalse,
      setLoadingBlockOperatorToFalse,
    ],
  )

  const onSubmit = useCallback(
    async (params?: Record<string, unknown>) => {
      await submitForm({ ...getValues(), ...params })
    },
    [getValues, submitForm],
  )

  const handleSaveOperator = useCallback(async () => {
    await handleSubmit(onSubmit)()
  }, [handleSubmit, onSubmit])

  const handleBlockOperator = useCallback(() => onSubmit({ isEnabled: false }), [onSubmit])
  const handleUnBlockOperator = useCallback(() => onSubmit({ isEnabled: true }), [onSubmit])
  const handleBackFormState = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  return (
    <Sidebar
      isOpen={isOpen}
      title={user ? operatorsTitleMap.edit : operatorsTitleMap.add}
      drawerClassName={styles.documentView__drawer}
      contentClassName={styles['documentView__drawer-content']}
      actions={
        <ActionSidebarButtons
          data={user}
          isLoadingSaveUser={isLoadingSaveOperator}
          isLoadingBlockUser={isLoadingBlockOperator}
          handleSaveUser={handleSaveOperator}
          handleBlockUser={handleBlockOperator}
          handleUnBlockUser={handleUnBlockOperator}
          handleBackFormState={handleBackFormState}
          isDisabledSaveButton={!isValid}
        />
      }
      onClose={onClose}
    >
      {user && !user.isEnabled ? (
        <div className={styles.content}>
          <div className={styles.content__head}>
            <Typography.Body
              variant="bodyMMedium"
              className={cn(styles.content__headBlocked, styles.content__headItem)}
            >
              ЗАБЛОКИРОВАН
            </Typography.Body>
            <Typography.Headline variant="headlineH2" className={styles.content__headName}>
              {`${user.firstName} ${user.lastName}`}
            </Typography.Headline>
            {user.email && (
              <Typography.Caption variant="captionMAllcaps" className={styles.content__headCaption}>
                {user.email}
              </Typography.Caption>
            )}
          </div>
          {user.position && (
            <div className={styles.section}>
              <FieldInfo label="Должность" className={styles.section__item}>
                {user.position}
              </FieldInfo>
            </div>
          )}
        </div>
      ) : (
        <form className={styles.section__form}>
          <div className={styles.content}>
            <div className={styles.section}>
              <ControlledInput
                control={control}
                name="lastName"
                rules={{
                  required: defaultRHFValidation.required,
                  pattern: Patterns.TruthlyString,
                }}
                inputProps={{
                  fixWidth: true,
                  type: 'text',
                  label: 'Фамилия',
                  view: 'secondary',
                  rootClassName: styles.section__item,
                }}
              />
              <ControlledInput
                control={control}
                name="firstName"
                rules={{
                  required: defaultRHFValidation.required,
                  pattern: Patterns.TruthlyString,
                }}
                inputProps={{
                  fixWidth: true,
                  type: 'text',
                  label: 'Имя',
                  view: 'secondary',
                  rootClassName: styles.section__item,
                }}
              />
              <ControlledInput
                control={control}
                name="email"
                rules={{
                  pattern: defaultRHFValidation.email,
                  required: defaultRHFValidation.required,
                }}
                inputProps={{
                  fixWidth: true,
                  type: 'text',
                  label: 'Email',
                  view: 'secondary',
                  autoComplete: 'off',
                  rootClassName: styles.section__item,
                }}
              />
              <ControlledInput
                control={control}
                name="position"
                rules={{ validate: validateNotWhiteSpaceOnly }}
                inputProps={{
                  fixWidth: true,
                  type: 'text',
                  label: 'Должность',
                  view: 'secondary',
                  caption: 'необязательно',
                  rootClassName: styles.section__item,
                }}
              />
              {!user && (
                <>
                  <ControlledInput
                    control={control}
                    name="password"
                    rules={{
                      pattern: NewPatterns.Password,
                      required: defaultRHFValidation.required,
                    }}
                    inputProps={{
                      fixWidth: true,
                      type: 'text',
                      label: 'Пароль',
                      view: 'secondary',
                      rootClassName: styles.section__item,
                    }}
                  />
                  <FieldCondition value={watch('password')} />
                </>
              )}
            </div>
          </div>
        </form>
      )}
    </Sidebar>
  )
}

export default ManageOperatorSidebar
