import React, { FC } from 'react'
import { FieldErrors, useFormContext, useFormState } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormMenu.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormNavigationMenu } from '@components/DocumentFormComponents/hooks/useFormNavigation'
import DocumentFormMenu from '@components/DocumentFormComponents/Menu'
import { useFlatMenuManager } from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalMenuProps } from '@components/DocumentFormComponents/types'
import {
  indicatorsOfUnfulfilledAgreementReportBlockValues,
  mapOfIndicatorsOfUnfulfilledAgreementReportMenu,
} from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/const'
import { useIndicatorsOfUnfulfilledAgreementReportManager } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/Manager'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'

const { isBlockHaveError } = DocumentFormHelpers

const FormTemplateMenu: FC<FormModalMenuProps> = ({ ...rest }) => {
  const formInstance = useFormContext<IndicatorsOfUnfulfilledAgreementReportFormValues>()

  const { errors } = useFormState({
    control: formInstance?.control,
  })

  const {
    state: { blockViewIsValidating, lastUpdateDraftTime },
  } = useIndicatorsOfUnfulfilledAgreementReportManager()

  const { currentAnchorId, handleChangeCurrentAnchorId } = useFlatMenuManager()
  const { handleMenuItemClick } = useFormNavigationMenu()

  const handleIsBlockValues = (
    id: string,
    blockErrors: FieldErrors<IndicatorsOfUnfulfilledAgreementReportFormValues>,
  ) => {
    return isBlockHaveError(id, indicatorsOfUnfulfilledAgreementReportBlockValues, blockErrors)
  }

  if (!formInstance) return null

  return (
    <div className={styles.menu}>
      <DocumentFormMenu
        {...rest}
        mapOfMenu={mapOfIndicatorsOfUnfulfilledAgreementReportMenu}
        activeMenuId={currentAnchorId}
        blockErrors={errors}
        blockViewIsValidating={blockViewIsValidating}
        lastUpdateDraftTime={lastUpdateDraftTime}
        onBlockCheckError={handleIsBlockValues}
        onMenuClick={handleMenuItemClick(handleChangeCurrentAnchorId)}
      />
    </div>
  )
}

export default FormTemplateMenu
