import { fromHexToText } from '@helpers/encodings/fromHexToText'

const fromHexInnToTextInn = (inn: string): string => {
  return fromHexToText(inn)
    .replace(/[\x00-\x1F\x7F]/g, '')
    .trim()
}

const isValidUlINN = (inn: string) =>
  inn.length === 10 || (inn.length === 12 && inn.substring(0, 2) === '00')
const isValidFlINN = (inn: string) => inn.length === 12 && inn.substring(0, 2) !== '00'

export { fromHexInnToTextInn, isValidFlINN, isValidUlINN }
