import React, { memo, useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'

import { blockValues, defaultStepOfStage } from '@components/Forms/CreateStatementOld/const'
import StepStage from '@components/Forms/CreateStatementOld/Forms/Stages/Stage'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'
import { StatementOldFormValues } from '@components/Forms/CreateStatementOld/types'
import Button from '@components/NewDesign/Button'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import cn from 'classnames'

import styles from './StageBlock.module.scss'

const StepProjectArrayForm = () => {
  const {
    state: { formInstance },
  } = useCreateStatementManager()

  const {
    fields: stages,
    append: appendStage,
    remove: removeStage,
  } = useFieldArray<StatementOldFormValues, 'stages', 'id'>({
    control: formInstance?.control,
    name: blockValues.stages.stages,
  })

  const handleAppendStage = useCallback(
    (e) => {
      e.preventDefault()

      appendStage(defaultStepOfStage)
    },
    [appendStage],
  )

  const handleRemoveStage = useCallback(
    (index: number) => {
      removeStage(index)
    },
    [handleAppendStage],
  )

  if (!formInstance) return null

  return (
    <div className={cn(styles.stages, { [styles['stages--empty']]: !stages.length })}>
      {stages.map((stage, index) => {
        return (
          <div key={stage.id} className={styles.stages__item}>
            <StepStage
              name={blockValues.stages.stages}
              positionOfStage={index}
              onRemoveStage={handleRemoveStage}
            />
          </div>
        )
      })}
      <div className={cn(styles.stages__add, { [styles['stages__add--empty']]: !stages.length })}>
        <Button
          leadingIcon={{ src: CircleAddIcon }}
          size={'m'}
          view={'outline'}
          dataTestId="StepProjectArrayForm-add-button"
          onClick={handleAppendStage}
        >
          Добавить
        </Button>
      </div>
    </div>
  )
}

export default memo(StepProjectArrayForm)
