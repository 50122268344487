import React from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import { Switch, SwitchParams } from '@components/NewDesign/Switch/Switch'
import WithCaption from '@components/WithCaption'

export type ControlledSwitchProps<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> & {
  switchProps: SwitchParams
  captionClassName?: string
  id?: string
  onChange?: (value: boolean) => void
}

export const ControlledSwitch = <T extends FieldValues>(props: ControlledSwitchProps<T>) => {
  const { onChange, switchProps, captionClassName, id, ...controllerProps } = props

  const {
    field: { onChange: onFormChange, value },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const onSwitchChange = (value: boolean) => {
    onFormChange(value)

    onChange?.(value)
  }

  return (
    <WithCaption caption={error?.message} captionClassName={captionClassName}>
      <Switch {...switchProps} id={id} value={value} onChange={onSwitchChange} />
    </WithCaption>
  )
}

ControlledSwitch.displayName = 'ControlledSwitch'
