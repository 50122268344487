const removeExtraQuotes = (id: string): string => {
  // Удаляем внешние кавычки, если они есть
  const trimmed = id.trim()

  // Проверяем, начинается ли и заканчивается ли строка на двойные кавычки
  if (trimmed.startsWith('"') && trimmed.endsWith('"')) {
    // Убираем внешние двойные кавычки
    const withoutOuterQuotes = trimmed.substring(1, trimmed.length - 1)

    // Проверяем, начинается ли и заканчивается ли новая строка также на двойные кавычки
    if (withoutOuterQuotes.startsWith('"') && withoutOuterQuotes.endsWith('"')) {
      // Убираем ещё один уровень кавычек
      return withoutOuterQuotes.substring(1, withoutOuterQuotes.length - 1)
    }
    return withoutOuterQuotes
  }

  return id // Возвращаем исходный id, если нет избыточных кавычек
}

export { removeExtraQuotes }
