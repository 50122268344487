import toast, { Renderable, Toast, ToastOptions, ValueOrFunction } from 'react-hot-toast'

import DownloadToast from '@components/Toast/Download'
import { nanoid } from 'nanoid'

interface WithToastRenderOptions<T> extends ToastOptions {
  loading: ValueOrFunction<Renderable, Toast>
  success?: ValueOrFunction<Renderable, T>
  error?: ValueOrFunction<Renderable, any>
}

const withToastPromise = async <T,>(
  promise: Promise<T>,
  { loading, success, error, ...toastOptions }: WithToastRenderOptions<T>,
) => {
  const toastId = toast((t) => (typeof loading === 'function' ? loading(t) : loading), {
    ...toastOptions,
    duration: Infinity,
  })

  try {
    const resolvedData = await promise

    toast.dismiss(toastId)

    if (success) {
      toast(typeof success === 'function' ? success(resolvedData) : success, toastOptions)
    }
  } catch (e) {
    toast.dismiss(toastId)

    if (error) {
      toast(typeof error === 'function' ? error(e) : error, toastOptions)
    }
  }
}

const withDownloadToastPromise = (downloadCallback: Promise<unknown>) => {
  return withToastPromise(downloadCallback, {
    loading: <DownloadToast />,
    id: nanoid(),
  })
}

export { withDownloadToastPromise, withToastPromise }
