import type { FC, ReactNode } from 'react'

import FormTooltip, { type FormTooltipProps } from '@components/DocumentFormComponents/FormTooltip'
import Icon, { type IconProps } from '@components/Icon'
import infoOutlineIcon from '@icons/action/info_outline.svg'
import cn from 'classnames'

import styles from './FormIconWithTooltip.module.scss'

interface FormIconWithTooltipProps {
  tooltipProps?: Omit<FormTooltipProps, 'content' | 'children'>
  iconProps?: Partial<IconProps>
  tooltipContent: ReactNode
}

const FormIconWithTooltip: FC<FormIconWithTooltipProps> = ({
  tooltipContent,
  tooltipProps,
  iconProps,
}) => {
  const {
    src = infoOutlineIcon,
    size = 's',
    className: iconClassName,
    ...restIconProps
  } = iconProps || {}

  return (
    <FormTooltip {...tooltipProps} content={tooltipContent}>
      <Icon {...restIconProps} src={src} size={size} className={cn(styles.icon, iconClassName)} />
    </FormTooltip>
  )
}

export type { FormIconWithTooltipProps }
export default FormIconWithTooltip
