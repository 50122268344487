import React, { FC, memo, useEffect, useState } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Icon from '@components/Icon'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import CalendarInput from '@components/NewDesign/CalendarInput'
import Sidebar from '@components/NewDesign/Sidebar'
import Typography from '@components/NewDesign/Typography'
import MetaInputItem from '@components/Sidebars/DocumentView/MetaInputItem'
import MetaItem from '@components/Sidebars/DocumentView/MetaItem'
import SignItem from '@components/Sidebars/DocumentView/SignItem'
import { objOfDateFormats } from '@constants/dateFormats'
import { DocumentsType } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { getAllPartsOfName } from '@helpers/name/getAllPartsOfName'
import { useDocumentMetaInfo } from '@hooks/new/swr/useMetaDocument'
import ApprovedDocumentIcon from '@icons/ApprovedDocumentIcon.svg'
import RejectFileIcon from '@icons/RejectFileIcon.svg'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import dayjs from 'dayjs'
import { unstable_serialize, useSWRConfig } from 'swr'

import styles from './DocumentView.module.scss'

interface ChangeDocumentAttributeProps {
  id: string
  attributeName: string
  value: string | number | boolean
}

export interface DocumentViewSidebarProps extends PopupProps {
  typeOfDocument: string

  documentId: string

  documentSetId: string

  projectId: string

  dfoId: string
  isStatic: boolean

  isApproved?: boolean | null

  edit?: {
    onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => any
    value: string
  }

  onChangeDocumentAttribute?({ id, attributeName, value }: ChangeDocumentAttributeProps): void

  accessToApprove?: boolean
}

const useDocumentViewSidebarControl = () => {
  const popupManager = usePopupManager()

  const openDocumentViewSidebar = (openProps: DocumentViewSidebarProps) => {
    if (
      !openProps.documentId ||
      !openProps.dfoId ||
      !openProps.projectId ||
      !openProps.documentSetId
    )
      return

    popupManager.open<DocumentViewSidebarProps>(DocumentViewSidebar, openProps)
  }

  return {
    openDocumentViewSidebar,
  }
}

const DocumentViewSidebar: FC<DocumentViewSidebarProps> = ({
  isOpen,
  onClose,
  documentId,
  isStatic,
  dfoId,
  projectId,
  documentSetId,
  typeOfDocument,
  isApproved,
  accessToApprove,
  edit,
  onChangeDocumentAttribute,
}) => {
  const {
    documentsApi: { changeDocumentAttribute },
  } = useAPIContext()

  const [dateOfDocument, setDateOfDocument] = useState<string>('')

  const [changeStateView, setChangeStateView] = useState<boolean>(isApproved === null)
  const [documentIsApproved, setDocumentIsApproved] = useState<boolean | null | undefined>(
    isApproved,
  )
  const [attributeIsChanging, setAttributeIsChanging] = useState<boolean>(false)

  const {
    documentsApi: { changeDocumentMetaInfoById },
  } = useAPIContext()

  const { cache } = useSWRConfig()

  const {
    documentMetaInfo,
    isLoadingDocumentMetaInfo,
    error: errorDocumentMetaInfo,
  } = useDocumentMetaInfo({
    key: {
      documentId,
      dfoId,
      projectId,
      documentSetId,
      _key: 'documentInfo',
    },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DocumentViewSidebar',
          componentType: 'sidebar',
        },
        additionInfo: {
          documentSetId,
          documentId,
        },
      }),
    },
  })

  const isAdditionalDocument = documentMetaInfo?.type === DocumentsType.ADDITIONAL_DOCUMENT

  const partNameObject = getAllPartsOfName(documentMetaInfo?.updateUserName)

  const onChangeCalendarInput = async (e, payload) => {
    setDateOfDocument(payload?.value)

    if (!payload.value) return

    try {
      await changeDocumentMetaInfoById({
        documentId,
        dfoId,
        projectId,
        documentSetId,
        body: {
          docDate: dayjs(payload?.value, objOfDateFormats.defaultFormat).format(
            objOfDateFormats.defaultNativeDateFormat,
          ),
        },
      })
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DocumentViewSidebar',
          componentType: 'onChangeCalendarInput',
        },
        additionInfo: {
          documentSetId,
          documentId,
        },
      })(error)

      setDateOfDocument(documentMetaInfo?.fields?.docDate || '')
    }
  }

  useEffect(() => {
    if (errorDocumentMetaInfo) preparedOnCloseHandler?.()

    if (!isAdditionalDocument || !documentMetaInfo) return

    const dateToApply = documentMetaInfo?.fields?.docDate
      ? dayjs(documentMetaInfo.fields?.docDate).format(objOfDateFormats.defaultFormat)
      : ''

    setDateOfDocument(dateToApply)
  }, [documentMetaInfo, errorDocumentMetaInfo, isAdditionalDocument])

  const preparedOnCloseHandler = () => {
    cache.delete(
      unstable_serialize({
        documentId,
        dfoId,
        projectId,
        documentSetId,
        _key: 'documentInfo',
      }),
    )

    onClose?.()
  }

  const firstNameInitials = `${partNameObject?.name?.[0] || ''}${
    partNameObject?.surname?.[0] || ''
  }`

  const handleRejectChoose = () => {
    setChangeStateView(true)
  }

  const handleChangeApprove = (newApprove: boolean) => async () => {
    setDocumentIsApproved(newApprove)
    setChangeStateView(false)
    setAttributeIsChanging(true)

    try {
      await changeDocumentAttribute({
        dfoId,
        documentId,
        documentSetId,
        projectId,
        isApproved: newApprove,
      })

      onChangeDocumentAttribute?.({
        id: documentId,
        attributeName: 'isApproved',
        value: newApprove,
      })
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DocumentViewSidebar',
          componentType: 'handleChangeApprove',
        },
        additionInfo: {
          documentSetId,
          documentId,
          isApproved: newApprove,
        },
      })(error)

      setDocumentIsApproved(documentIsApproved)
      setChangeStateView(changeStateView)
    } finally {
      setAttributeIsChanging(false)
    }
  }

  return (
    <Sidebar
      isOpen={isOpen}
      title={typeOfDocument ?? 'Информация о документе'}
      dataTestId="DocumentViewSidebar-sidebar"
      closeButtonDataTestId="DocumentViewSidebar-modal-closeButton"
      drawerClassName={styles.documentView__drawer}
      contentClassName={styles['documentView__drawer-content']}
      onClose={preparedOnCloseHandler}
    >
      <Loader
        variant={'lite'}
        loading={isLoadingDocumentMetaInfo}
        dataTestId="DocumentViewSidebar-loader"
      >
        <div className={styles.documentView__content}>
          <div className={styles.documentView__metaInfo}>
            {edit &&
              (isStatic ? (
                <MetaItem
                  metaName={'Имя'}
                  metaInfo={
                    <Typography.Body
                      className={styles['documentView__metaInfo-item']}
                      as={'span'}
                      variant={'bodyMMedium'}
                    >
                      {edit.value}
                    </Typography.Body>
                  }
                />
              ) : (
                <MetaInputItem
                  metaItemProps={{ metaName: 'Имя' }}
                  inputProps={{
                    ...edit,
                    dataTestId: 'DocumentViewSidebar-fileName-metaInput',
                  }}
                />
              ))}
            {isAdditionalDocument && (
              <MetaItem
                metaName={'Дата документа'}
                metaInfo={
                  isStatic ? (
                    <Typography.Body as={'span'} variant={'bodyMMedium'}>
                      {documentMetaInfo?.fields?.docDate
                        ? dayjs(documentMetaInfo?.fields?.docDate).format(
                            objOfDateFormats.defaultFormat,
                          )
                        : ''}
                    </Typography.Body>
                  ) : (
                    <CalendarInput
                      size={'s'}
                      value={dateOfDocument}
                      dataTestId="DocumentViewSidebar-fileDate-calendarInput"
                      tooltipProps={{
                        position: 'bottom-start',
                        offset: [-45, 5],
                      }}
                      onChange={onChangeCalendarInput}
                    />
                  )
                }
              />
            )}
            <MetaItem
              metaName={'Дата загрузки'}
              metaInfo={
                <Typography.Body as={'span'} variant={'bodyMMedium'}>
                  {dayjs(documentMetaInfo?.updateDate).format(objOfDateFormats.defaultFormat)}
                </Typography.Body>
              }
            />
            <MetaItem
              metaName={'Автор'}
              metaInfo={
                <div className={styles.documentView__author}>
                  <div className={styles.documentView__avatar}>
                    <Typography.Body
                      variant={'bodySMedium'}
                      fontWeight={'semibold'}
                      color={'text-accent-brand'}
                    >
                      {firstNameInitials}
                    </Typography.Body>
                  </div>
                  <Typography.Body as={'span'} variant={'bodyMMedium'}>
                    {documentMetaInfo?.updateUserName}
                  </Typography.Body>
                </div>
              }
            />
          </div>
          {!!documentMetaInfo?.signaturesInfo.length && (
            <div className={styles.documentView__signs}>
              <Typography.Body
                className={styles['documentView__signs-title']}
                variant={'bodyMRegular'}
                color={'text-accent-brand'}
              >
                Документ подписан квалифицированными электронными подписями
              </Typography.Body>

              {documentMetaInfo.signaturesInfo.map((signature, index) => {
                return (
                  <SignItem
                    key={index}
                    name={signature.userName}
                    organization={signature.organizationName}
                    date={dayjs(signature.signDate).format(objOfDateFormats.defaultFormat)}
                  />
                )
              })}
            </div>
          )}
        </div>
        {accessToApprove && (
          <div className={styles.documentView__actions}>
            <Loader
              variant={'lite'}
              loading={attributeIsChanging}
              dataTestId="DocumentViewSidebar-documentLoader"
            >
              {changeStateView ? (
                <>
                  <Button
                    view={'gray'}
                    color={'negative'}
                    dataTestId="DocumentViewSidebar-reject-button"
                    onClick={handleChangeApprove(false)}
                  >
                    Вернуть на доработку
                  </Button>
                  <Button
                    dataTestId="DocumentViewSidebar-accept-button"
                    onClick={handleChangeApprove(true)}
                  >
                    Принять
                  </Button>
                </>
              ) : (
                <div className={styles['documentView__actions-changeState']}>
                  <div className={styles.documentView__status}>
                    <Icon
                      noCurrentColorSvgFill
                      className={styles['documentView__status-icon']}
                      src={documentIsApproved ? ApprovedDocumentIcon : RejectFileIcon}
                      size={'initial'}
                    />
                    <Typography.Body
                      className={styles['documentView__status-text']}
                      as={'span'}
                      variant={'bodyMMedium'}
                    >
                      {documentIsApproved ? 'Документ принят' : 'Возвращён на доработку'}
                    </Typography.Body>
                  </div>
                  <Button
                    fixWidth
                    className={styles.documentView__changeButton}
                    size={'m'}
                    variant={'buttonSMedium'}
                    view={'plain'}
                    dataTestId="DocumentViewSidebar-edit-button"
                    onClick={handleRejectChoose}
                  >
                    Изменить решение
                  </Button>
                </div>
              )}
            </Loader>
          </div>
        )}
      </Loader>
    </Sidebar>
  )
}

export type { ChangeDocumentAttributeProps }
export { useDocumentViewSidebarControl }
export default memo(DocumentViewSidebar)
