import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { additionalAgreementOnTerminationFfSZPKValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/const'
import { fifthSectionValidationMap } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/Forms/5/validation'
import { useAdditionalAgreementOnTerminationFfSZPKManager } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/Manager'
import { AdditionalAgreementOnTerminationFfSZPKFormValues } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/types'
import { AdditionalAgreementOnTerminationFfSZPKFieldsControlUpdateWatcher } from '@components/Forms/AdditionalAgreementOnTerminationFfSZPK/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fifth = () => {
  const formInstance = useFormContext<AdditionalAgreementOnTerminationFfSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAdditionalAgreementOnTerminationFfSZPKManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AdditionalAgreementOnTerminationFfSZPKFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <RowWithBorder
          disableBottomDefaultStyles
          title={
            'Пункт Правил, на основании которого СЗПК считается расторгнутым с момента регистрации доп. соглашения'
          }
        >
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: additionalAgreementOnTerminationFfSZPKValues['5']
                    .ruleLinkAgreementTerminationExtra,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: additionalAgreementOnTerminationFfSZPKValues['5']
                      .ruleLinkAgreementTerminationExtra,
                    rules: fifthSectionValidationMap.ruleLinkAgreementTerminationExtra,
                    inputProps: {
                      caption: 'рекомендация: 77',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            additionalAgreementOnTerminationFfSZPKValues['5']
                              .ruleLinkAgreementTerminationExtra,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          additionalAgreementOnTerminationFfSZPKValues['5']
                            .ruleLinkAgreementTerminationExtra,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        </RowWithBorder>
      </Stack>
    </Container>
  )
}

export default Fifth
