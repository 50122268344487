import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { RegistrationObjectsArrayPathName } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/types'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

import { eRegistrationFormItemAAgreementOnChangingCharacteristicsOfObjectsValidationMap } from './validation'

interface RegistrationFormItemProps {
  formName: RegistrationObjectsArrayPathName
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnChangingCharacteristicsOfObjectsFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>
  positiveStepEndDate: (text: string) => (value: unknown) => string | undefined
}

const RegistrationFormItem: FC<RegistrationFormItemProps> = ({
  blockViewIsValidating,
  formInstance,
  formModifierInstance,
  formName,
  positiveStepEndDate,
}) => {
  const {
    state: { editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formModifierInstance.control,
  })

  const {
    getInputProps,
    getTextareaProps,
    getSingleSelectProps,
    getCalendarInputProps,
    getSubscribableControlProps,
    getFormFieldControlProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
  })

  return (
    <div>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <Row>
            <Col xs={6}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.objectType`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.objectType`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.objectType`,
                    rules:
                      eRegistrationFormItemAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectType,
                    inputProps: {
                      label: 'Вид зарегистрированного права',
                      dataTestId: 'regObjectType',
                      caption: 'в именительном падеже',
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent="Например: право собственности, договор аренды" />
                      ),
                    },
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${formName}.objectType`), 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.objectType`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Col>
            <Col xs={6}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.objectName`,
                })}
              >
                {({ overrideProps }) => {
                  const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                    ? overrideProps.fetcherOptions
                    : undefined

                  return (
                    <ControlledFormSingleSelect
                      {...getSingleSelectProps({
                        fetcherProps,
                        optionsAdapter: (item) => ({
                          displayValue: item?.objectName || '',
                          value: item.id,
                        }),
                        controllerProps: {
                          name: `${formName}.objectName`,
                          rules:
                            eRegistrationFormItemAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectName,
                        },
                        selectProps: {
                          inputProps: {
                            label: 'Объект недвижимости',
                            dataTestId: 'regObjectName',
                          },
                          onChangeFormValue: () =>
                            setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                        },
                      })}
                    />
                  )
                }}
              </SubscribableControl>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.estateObjectStateRegistrationNumber`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(`${formName}.estateObjectStateRegistrationNumber`),
                        0,
                      ),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.estateObjectStateRegistrationNumber`,
                    rules:
                      eRegistrationFormItemAAgreementOnChangingCharacteristicsOfObjectsValidationMap.estateObjectStateRegistrationNumber,
                    inputProps: {
                      label: 'Регистрационный номер',
                      dataTestId: 'regObjectRegistrationNumber',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(`${formName}.estateObjectStateRegistrationNumber`),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          `${formName}.estateObjectStateRegistrationNumber`,
                        )
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Col>
            <Col xs={4}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.estateObjectStateRegistrationDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.estateObjectStateRegistrationDate`),
                        0,
                      ),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${formName}.estateObjectStateRegistrationDate`,
                    rules: {
                      ...eRegistrationFormItemAAgreementOnChangingCharacteristicsOfObjectsValidationMap.estateObjectStateRegistrationDate,
                      validate: {
                        positiveStepEndDate: positiveStepEndDate(
                          'дата регистрации объекта должна быть меньше или равна дате окончания этапа',
                        ),
                        positiveCurrentDate: (value) => {
                          const currentDate = DayjsService.dayjsWithFormatToMSK()

                          if (
                            isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) > currentDate
                          )
                            return 'дата регистрации объекта должна быть меньше или равна текущей дате'
                        },
                      },
                    },
                    calendarInputProps: {
                      label: 'Дата регистрации',
                      dataTestId: 'regObjectRegistrationDate',
                    },
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.estateObjectStateRegistrationDate`),
                        0,
                      ),
                    onInputChange: () =>
                      debouncedHandleChangeValue?.(`${formName}.estateObjectStateRegistrationDate`),
                    onCalendarChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${formName}.estateObjectStateRegistrationDate`),
                        0,
                      ),
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${formName}.estateObjectStateRegistrationOrganization`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            `${formName}.estateObjectStateRegistrationOrganization`,
                          ),
                        0,
                      ),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${formName}.estateObjectStateRegistrationOrganization`,
                    rules:
                      eRegistrationFormItemAAgreementOnChangingCharacteristicsOfObjectsValidationMap.estateObjectStateRegistrationOrganization,

                    textareaProps: {
                      label: 'Регистрирующий орган',
                      dataTestId: 'regObjectRegistrationOrganization',
                      caption: 'в творительном падеже',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            `${formName}.estateObjectStateRegistrationOrganization`,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          `${formName}.estateObjectStateRegistrationOrganization`,
                        )
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
        </Stack>
      </Col>
    </div>
  )
}

export default RegistrationFormItem
