import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'
import Fourth from './4'
import Fifth from './5'
import Sixth from './6'
import Seventh from './7'
import Eighth from './8'
import Ninth from './9'
import Tenth from './10'

interface InformationOfExecutionConditionsSZPKFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
  Fourth: typeof Fourth
  Fifth: typeof Fifth
  Sixth: typeof Sixth
  Seventh: typeof Seventh
  Eighth: typeof Eighth
  Ninth: typeof Ninth
  Tenth: typeof Tenth
}

const InformationOfExecutionConditionsSZPKForms: InformationOfExecutionConditionsSZPKFormsProps =
  () => null

InformationOfExecutionConditionsSZPKForms.First = First
InformationOfExecutionConditionsSZPKForms.Second = Second
InformationOfExecutionConditionsSZPKForms.Third = Third
InformationOfExecutionConditionsSZPKForms.Fourth = Fourth
InformationOfExecutionConditionsSZPKForms.Fifth = Fifth
InformationOfExecutionConditionsSZPKForms.Sixth = Sixth
InformationOfExecutionConditionsSZPKForms.Seventh = Seventh
InformationOfExecutionConditionsSZPKForms.Eighth = Eighth
InformationOfExecutionConditionsSZPKForms.Ninth = Ninth
InformationOfExecutionConditionsSZPKForms.Tenth = Tenth

export default InformationOfExecutionConditionsSZPKForms
