import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import {
  IFactStepActivityOther,
  IFactStepPermissions,
  IStepMonitoring,
} from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import { isArray } from '@helpers/checkTypes'
import { convertStringToDateFormat } from '@helpers/date/convertStringToDateFormat'

const generateRHFObjectForInformationOfExecutionAgreementEventsForm = (
  objectFromPropertyState: Record<string, any>,
): InformationOfExecutionAgreementEventsFormValues => {
  const preparedStepsMonitoring: IStepMonitoring[] = (() => {
    if (
      !isArray(objectFromPropertyState.stepsMonitoring?.value) ||
      !objectFromPropertyState.stepsMonitoring?.value?.length
    )
      return []

    return objectFromPropertyState.stepsMonitoring?.value.map(
      (stepMonitoring, index): IStepMonitoring => {
        const preparedFactStepPermissions: IFactStepPermissions[] = (() => {
          if (
            !isArray(stepMonitoring.value?.factStepPermissions?.value) ||
            !stepMonitoring.value?.factStepPermissions?.value?.length
          )
            return []

          return stepMonitoring.value?.factStepPermissions?.value.map(
            (factStepPermission): IFactStepPermissions => ({
              id: factStepPermission.propertyId,
              permissionKindName:
                factStepPermission.value?.planPermissionId?.value?.permissionKind.name,
              permissionOrganization:
                factStepPermission.value?.planPermissionId?.value?.permissionOrganization,
              permissionDate: convertStringToDateFormat(
                factStepPermission.value?.planPermissionId?.value?.permissionDate,
              ),
              factPermissionDate: factStepPermission.value?.factPermissionDate?.value,
            }),
          )
        })()

        const preparedFactStepActivityOthers: IFactStepActivityOther[] = (() => {
          if (
            !isArray(stepMonitoring.value?.factStepActivityOthers?.value) ||
            !stepMonitoring.value?.factStepActivityOthers?.value?.length
          )
            return []

          return stepMonitoring.value?.factStepActivityOthers?.value.map(
            (factStepActivityOther): IFactStepActivityOther => ({
              id: factStepActivityOther.propertyId,
              otherActivityName:
                factStepActivityOther.value?.planStepActivityOtherId?.value?.otherActivityName,
              otherActivityEndDate: convertStringToDateFormat(
                factStepActivityOther.value?.planStepActivityOtherId?.value?.otherActivityEndDate,
              ),
              factOtherActivityEndDate:
                factStepActivityOther.value?.factOtherActivityEndDate?.value,
            }),
          )
        })()

        return {
          id: stepMonitoring.propertyId,
          anchorId: `1.${index + 1}`,
          stepTitle:
            (stepMonitoring.value?.planStepId?.value?.stepNumber
              ? `${stepMonitoring.value?.planStepId?.value?.stepNumber}. `
              : '') + stepMonitoring.value?.planStepId?.value?.stepDescription || '',
          notContainsCompletedEvents: stepMonitoring.value?.notContainsCompletedEvents?.value,
          notContainsCompletedEventsComment:
            stepMonitoring.value?.notContainsCompletedEventsComment?.value,
          objectCommissioningEndDate: convertStringToDateFormat(
            stepMonitoring.value?.planStepId?.value?.objectCommissioningEndDate,
          ),
          factObjectCommissioningEndDate:
            stepMonitoring.value?.factObjectCommissioningEndDate?.value,
          ridCommissioningEndDate: convertStringToDateFormat(
            stepMonitoring.value?.planStepId?.value?.ridCommissioningEndDate,
          ),
          factRidCommissioningEndDate: stepMonitoring.value?.factRidCommissioningEndDate?.value,
          typeObjectStateRegistration:
            stepMonitoring.value?.planStepId?.value?.typeObjectStateRegistration,
          objectStateRegistrationEndDate: convertStringToDateFormat(
            stepMonitoring.value?.planStepId?.value?.objectStateRegistrationEndDate,
          ),
          factObjectStateRegistrationDate:
            stepMonitoring.value?.factObjectStateRegistrationDate?.value,
          ridStateRegistrationEndDate: convertStringToDateFormat(
            stepMonitoring.value?.planStepId?.value?.ridStateRegistrationEndDate,
          ),
          factRidStateRegistrationDate: stepMonitoring.value?.factRidStateRegistrationDate?.value,
          factStepPermissions: preparedFactStepPermissions,
          factStepActivityOthers: preparedFactStepActivityOthers,
        }
      },
    )
  })()
  return {
    '1': {
      monitoringReportDate: objectFromPropertyState.monitoringReportDate?.value,
      stepsMonitoring: preparedStepsMonitoring,
    },
  }
}

export { generateRHFObjectForInformationOfExecutionAgreementEventsForm }
