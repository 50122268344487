import { FC, JSXElementConstructor, ReactElement, ReactNode, useEffect } from 'react'

import Highlighted from '@components/Highlighted/Highlighted'
import IconButton from '@components/NewDesign/IconButton'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import { toggle } from '@helpers/commonHelpers'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from '../InputSearchTree.module.scss'
import { ISearchElementTree } from '../types'

interface TreeItemProps {
  displayValue: string
  value: string
  searchValue: string

  chapter?: string
  level?: string
  initialPadding?: number
  stepOfPadding?: number
  children?: ISearchElementTree[]

  onTreeChange?: (obj: ISearchElementTree) => void
}

const MAX_LENGTH_FIRST_LEVEL_TITLE = 47
const MAX_LENGTH_SECOND_LEVEL_TITLE = 52
const MAX_LENGTH_THIRD_LEVEL_TITLE = 48

const TreeItem: FC<TreeItemProps> = ({
  displayValue,
  value,
  searchValue,
  level,
  initialPadding = 8,
  stepOfPadding = 24,
  children,
  chapter,

  onTreeChange,
}) => {
  const {
    state: { tooltipIsOpen },
    handlers: { handleSetupTooltip },
  } = useManualTooltipControl()

  const { getCollapseProps } = useCollapse({
    isExpanded: tooltipIsOpen,
    duration: 500,
  })

  const handleFoldUnfold = () => {
    handleSetupTooltip(toggle(tooltipIsOpen))
  }

  const handleOnClick = (obj: ISearchElementTree) => {
    onTreeChange?.(obj)
  }

  const nextInitialPaddingLeft = initialPadding + stepOfPadding

  const isExpanded = searchValue.length

  useEffect(() => {
    if (isExpanded) {
      handleSetupTooltip(true)
    }
  }, [isExpanded])

  const wrapperTooltip = (
    text: string,
    element: ReactElement<any, string | JSXElementConstructor<any>> & ReactNode,
  ) => (
    <Tooltip content={text} popoverClassName={styles.popoverTooltip__text}>
      {element}
    </Tooltip>
  )

  const getFormattedValue = () => {
    const highlight = searchValue === value ? displayValue : searchValue
    const highlightedValue = (
      <Highlighted highlight={highlight}>
        {level === '1' ? displayValue.toUpperCase() : displayValue}
      </Highlighted>
    )

    switch (level) {
      case '1': {
        const typography = (
          <Typography.Caption
            className={styles.groupName}
            color={'text-base-tertiary'}
            variant={'captionMAllcaps'}
          >
            {highlightedValue}
          </Typography.Caption>
        )
        return displayValue.length > MAX_LENGTH_FIRST_LEVEL_TITLE
          ? wrapperTooltip(displayValue, typography)
          : typography
      }

      case '2': {
        const typography = (
          <Typography.Body className={styles.subgroupName} variant={'bodyMMedium'}>
            {highlightedValue}
          </Typography.Body>
        )
        return displayValue.length > MAX_LENGTH_SECOND_LEVEL_TITLE
          ? wrapperTooltip(displayValue, typography)
          : typography
      }

      default: {
        const typography = (
          <Typography.Body className={styles.name} variant={'bodyMRegular'}>
            {highlightedValue}
          </Typography.Body>
        )
        return displayValue.length > MAX_LENGTH_THIRD_LEVEL_TITLE
          ? wrapperTooltip(displayValue, typography)
          : typography
      }
    }
  }

  if (children?.length) {
    return (
      <div className={styles.childWrapper}>
        <button
          type="button"
          className={styles.subgroup}
          style={{ paddingLeft: nextInitialPaddingLeft }}
          onClick={handleFoldUnfold}
        >
          <IconButton
            size={'s'}
            view={'basic'}
            icon={{
              className: cn({ [styles.toggler__toggled]: tooltipIsOpen }),
              src: chevronRightIcon,
            }}
          />
          {getFormattedValue()}
        </button>
        <div {...getCollapseProps()}>
          <div className={styles.collapse__wrapper}>
            {children.map((props) => (
              <TreeItem
                {...props}
                initialPadding={nextInitialPaddingLeft}
                searchValue={searchValue}
                key={props.value}
                onTreeChange={onTreeChange}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <button
      type="button"
      className={styles.line}
      style={{ paddingLeft: nextInitialPaddingLeft }}
      onClick={() => handleOnClick({ displayValue, value, chapter })}
    >
      {getFormattedValue()}
    </button>
  )
}
export default TreeItem
