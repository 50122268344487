import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForStatementInvestments = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      madeInvestorCapital: {
        propertyId: objectFromPropertyState.madeInvestorCapital?.propertyId,
        type: objectFromPropertyState.madeInvestorCapital?.type,
        lastUpdateDt: objectFromPropertyState.madeInvestorCapital?.lastUpdateDt,
      },
      remainsInvestorCapital: {
        propertyId: objectFromPropertyState.remainsInvestorCapital?.propertyId,
        type: objectFromPropertyState.remainsInvestorCapital?.type,
        lastUpdateDt: objectFromPropertyState.remainsInvestorCapital?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForStatementInvestments }
