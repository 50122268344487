import React, { FC, memo, ReactNode, useMemo } from 'react'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import rootStyles from '@components/RegistryNpa/MasterItem/MasterItem.module.scss'
import { objOfDateFormats } from '@constants/dateFormats'
import { useNpaTypes } from '@hooks/new/swr/useNpaTypes'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import { IMasterNpaItemNew } from '@services/NPA/NPA.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import styles from './MasterNpaItem.module.scss'

interface MasterItemNPAProps {
  npa: IMasterNpaItemNew
  children: ReactNode
  onToggle: (isCollapsed: boolean) => void
}

const MasterItem: FC<MasterItemNPAProps> = ({ npa, children, onToggle }) => {
  const { mapOfNpaTypes } = useNpaTypes({
    key: {
      _key: 'npaTypes',
    },
  })

  const formattedNpaDate = useMemo(
    () => dayjs(npa.date).format(objOfDateFormats.defaultFormat),
    [npa.date],
  )

  const handleToggleMasterItem = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    onToggle(!npa.isCollapsed)
  }

  return (
    <>
      <button
        type="button"
        data-testid={`MasterItem-npa-${npa.id}-itemButton`}
        className={cn(rootStyles.masterItem, styles.masterNpaItem)}
        onClick={handleToggleMasterItem}
      >
        <div className={rootStyles.masterItem__leftPart}>
          <IconButton
            className={rootStyles.masterItem__control}
            size={'s'}
            view={'basic'}
            dataTestId={`MasterItem-npa-${npa.id}-toggleCollapse-iconButton`}
            icon={{
              className: cn(rootStyles['masterItem__control-item'], {
                [rootStyles['masterItem__control--toggled']]: npa.isCollapsed,
              }),
              src: chevronRightIcon,
            }}
            onClick={handleToggleMasterItem}
          />

          <div className={rootStyles.masterItem__mainInfo}>
            <Typography.Caption variant={'captionMMedium'} color={'text-base-secondary'}>
              {mapOfNpaTypes?.[npa.type]} ・{' '}
              <Typography.Caption as={'span'} variant={'captionMMedium'}>
                {npa.number}
              </Typography.Caption>{' '}
              от {formattedNpaDate}
            </Typography.Caption>
            <Typography.Body variant={'bodyMMedium'}>{npa.name}</Typography.Body>
          </div>
        </div>
        <div className={rootStyles.masterItem__additionalInfo}>
          <Typography.Body as={'span'} variant={'bodyMRegular'}>
            Ред. № {npa.redactionNumber} от {formattedNpaDate}
          </Typography.Body>
        </div>
      </button>

      {npa.parts && (
        <CollapseWrapper
          unmountOnCollapse
          isExpanded={npa.isCollapsed}
          defaultExpanded={npa.isCollapsed}
        >
          <div
            className={cn({
              [rootStyles.masterItem__parts]: npa.isCollapsed,
            })}
          >
            {children}
          </div>
        </CollapseWrapper>
      )}
    </>
  )
}

export default memo(MasterItem)
