import React, { FC, memo } from 'react'

import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import { useCreateStatementOldControl } from '@components/Forms/CreateStatementOld'
import Button from '@components/NewDesign/Button'
import { IDefaultStatementOldUrlParams } from '@context/APIContext/types'

type AttachmentDraftContainerProps = IDefaultStatementOldUrlParams

const AttachmentDraftContainer: FC<AttachmentDraftContainerProps> = (props) => {
  const { openStatementOldModal } = useCreateStatementOldControl()

  return (
    <ControlContainer
      leadingNodeContent={<ControlContainerStatusIcon />}
      title={'Заявление на заключение СЗПК'}
      additionalMainContent={'Заполните заявление'}
      trailingNodeContent={
        <Button fixWidth view="tined" onClick={() => openStatementOldModal(props)}>
          Заполнить заявление
        </Button>
      }
    />
  )
}

export default memo(AttachmentDraftContainer)
