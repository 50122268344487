import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { ObjectArea, ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { bigIntIsValidate, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const objectAreaValidationMap: FormValuesValidationSection<
  keyof ObjectArea,
  ProjectSZPKFormValues
> = {
  objectAreaNumber: {
    validate: (value) => (isString(value) ? lengthValidate(value, 100) : undefined),
  },
  objectAreaSquare: {
    validate: bigIntIsValidate,
  },
}

export { objectAreaValidationMap }
