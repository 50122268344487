import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  CertificateOnMonitoringResultsCompensationMonitoring,
  CertificateOnMonitoringResultsFormValues,
} from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import { standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const compensationMonitoringCertificateOnMonitoringResultsValidationMap: FormValuesValidationSection<
  keyof CertificateOnMonitoringResultsCompensationMonitoring,
  CertificateOnMonitoringResultsFormValues
> = {
  incomeTaxFederalChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  incomeTaxRegionalChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  propertyTaxChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  landTaxChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  vatTaxChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  customDutyChecker: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
}

export { compensationMonitoringCertificateOnMonitoringResultsValidationMap }
