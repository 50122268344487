import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap: FormValuesValidationSection<
  keyof NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues['3'],
  NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues
> = {
  ruleLinkDenySolicitationProject: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  reasonDenySolicitationProject: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
}

export { thirdSectionNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyValidationMap }
