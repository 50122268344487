import React, { FC, KeyboardEvent, MouseEvent, SyntheticEvent, useCallback, useMemo } from 'react'

import { ChipBaseProps } from '@components/Chip/Base'
import Icon from '@components/Icon/Icon'
import MultipleInput from '@components/NewDesign/MultipleInput'
import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import { PopoverProps } from '@components/NewDesign/Popover'
import { isSelectOption } from '@components/NewDesign/Select/helpers'
import {
  GroupOfOptions,
  isOptionsGroup,
  OptionProps,
  SelectNewProps,
  SelectStateNew,
} from '@components/NewDesign/Select/model'
import Options from '@components/NewDesign/Select/OptionsNew'
import { useSelectStateNew } from '@components/NewDesign/Select/useSelectStateNew'
import { isEmptyString, isString } from '@helpers/checkTypes'
import arrowBackIcon from '@icons/hardware/keyboard_arrow_down.svg'
import arrowUpIcon from '@icons/hardware/keyboard_arrow_up.svg'
import cn from 'classnames'

import styles from '../SingleSelect.module.scss'

type MultipleSelectNewProps = SelectNewProps<(OptionProps | string)[]> & {
  closeOptionsAfterOnChange?: boolean
  staticWidth?: boolean
  chipNameLengthToShowTooltip?: number
  multipleClassName?: string
  chipProps?: Omit<ChipBaseProps, 'label' | 'key' | 'className' | 'onClose'>
  onRemoveChip?: (payload: OptionProps[], removedValue: OptionProps) => void
}
type MultipleSelectNewState = SelectStateNew<OptionProps[]>

const MultipleSelectNew: FC<MultipleSelectNewProps> = ({
  defaultSelected = [],
  onBlurForm,
  onFocusForm,
  onChangeForm,
  error,
  popoverProps,
  inputProps,
  options: receivedOptions,
  defaultOptionsSelected,
  multipleClassName,
  withSearch,
  withContextSearch,
  withInput,
  staticWidth,
  closeOptionsAfterOnChange,
  disabled,
  optionsProps,
  optionsContainer,
  chipNameLengthToShowTooltip,
  onChangeSearchValue,
  chipProps,
  onRemoveChip,
}) => {
  const inputActive = !!(withInput || withSearch || withContextSearch)

  const findOptionBySelectedValue = (
    options: (GroupOfOptions | OptionProps)[],
    selectedValue: string,
  ): OptionProps | null => {
    return options.reduce<OptionProps | null>((previousValue, currentValue) => {
      if (isOptionsGroup(currentValue)) {
        return findOptionBySelectedValue(currentValue.options, selectedValue)
      }

      if (currentValue.value === selectedValue) {
        return currentValue
      }

      return previousValue
    }, null)
  }

  const handlePrepareDefaultSelected = (defaultSelectedValue: (OptionProps | string)[]) => {
    if (!defaultSelectedValue.length) return []

    return defaultSelectedValue.reduce<OptionProps[]>((previousValue, currentValue) => {
      if (isString(currentValue)) {
        const defaultOption: OptionProps = {
          value: currentValue,
          displayValue: '',
        }

        // если не найдена опция в функции, то возвращается дефолтная опция
        const foundOption =
          findOptionBySelectedValue(receivedOptions, currentValue) || defaultOption

        return [...previousValue, foundOption]
      }

      if (isSelectOption(currentValue) && isEmptyString(currentValue.displayValue)) {
        // если не найдена опция в функции, то возвращается дефолтная опция
        const foundOption =
          findOptionBySelectedValue(receivedOptions, currentValue.value) || currentValue

        return [...previousValue, foundOption]
      }

      return [...previousValue, currentValue]
    }, [])
  }

  const {
    selected,
    setSelected,
    allOptionsInArray,
    inputContainerRef,
    popoverRef,
    isPopoverOpen,
    reverseIsPopoverOpen,
    searchValue,
    setSearchValue,
    clue,
    availableOptions,
    inputRef,
    openPopover,
    valueIsLongerThenInput,
  } = useSelectStateNew<MultipleSelectNewState>({
    defaultSelected: handlePrepareDefaultSelected(defaultSelected),
    receivedOptions,
    withSearch,
    withContextSearch,
    defaultOptionsSelected,
    onBlur: onBlurForm,
    onFocus: onFocusForm,
    disableOptionsMutate: !!onChangeSearchValue,
  })

  const inputArrow = (
    <Icon src={isPopoverOpen ? arrowUpIcon : arrowBackIcon} className={styles.Arrow} />
  )

  const addValueHandler = (value: OptionProps) => {
    const prepearedCurrentAndNewValues =
      selected && selected.length ? [...selected, value] : [value]
    setSelected(prepearedCurrentAndNewValues)
    onChangeForm?.(prepearedCurrentAndNewValues, value, selected)

    setSearchValue('')

    onChangeSearchValue?.('')
  }

  const removeValueHandler = useCallback(
    (option: OptionProps) => {
      setSelected((prev) => (!prev ? null : prev.filter(({ value }) => value !== option.value)))

      onChangeForm?.(
        !selected ? [] : selected.filter(({ value }) => value !== option.value),
        option,
        selected,
      )
    },
    [onChangeForm, selected, setSelected],
  )

  const keyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const isTargetButtonPressed = ['Enter'].includes(e.key)

    if (isTargetButtonPressed && clue) {
      e.preventDefault()

      const valueToAdd = allOptionsInArray.find(
        (item) => item.displayValue.toLowerCase() === clue.toLowerCase(),
      )

      if (valueToAdd) addValueHandler(valueToAdd)
    }
  }

  const onChangeInput = (e: SyntheticEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value

    if (currentValue) openPopover()

    setSearchValue(currentValue)

    onChangeSearchValue?.(currentValue)
  }

  const onClearSearch = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      inputProps?.onClear?.(e)

      setSearchValue('')

      onChangeSearchValue?.('')
    },
    [onChangeSearchValue, setSearchValue, inputProps],
  )

  const onClickOption = (option: OptionProps) => {
    const foundSelected = selected?.find((item) => item.value === option.value)

    if (!!foundSelected) return removeValueHandler(option)
    addValueHandler(option)

    if (closeOptionsAfterOnChange) {
      reverseIsPopoverOpen()
    }
  }

  const staticPopoverProps = {
    ref: popoverRef,
    position: 'bottom-start' as PopoverProps['position'],
    open: isPopoverOpen,
    anchorElement: inputContainerRef.current,
    ...popoverProps,
  }

  const onMergedRemoveChip = useCallback(
    (displayValues: MultipleInputValue[], removedValue: MultipleInputValue) => {
      onRemoveChip?.(displayValues, removedValue)

      setSelected(displayValues)
      onChangeForm?.(displayValues, removedValue, selected)
    },
    [onChangeForm, onRemoveChip, selected, setSelected],
  )

  const preparedSelectedValues: MultipleInputValue[] = useMemo(() => {
    if (!selected || !selected.length) return []

    return selected.map((item) => ({
      value: item.value,
      displayValue: item.displayValue,
    }))
  }, [selected])

  return (
    <>
      <MultipleInput
        manualValuesControl
        values={preparedSelectedValues}
        staticWidth={staticWidth}
        chipNameLengthToShowTooltip={chipNameLengthToShowTooltip}
        error={error}
        ref={inputRef}
        className={cn(
          { [styles.Input__disabled]: disabled, [styles.Input__staticWidth]: staticWidth },
          multipleClassName,
        )}
        chipProps={{
          size: 'xs',
          variant: 'accent',
          priority: 'secondary',
          stopPropagation: true,
          ...chipProps,
        }}
        inputProps={{
          rootClassName: styles.Input,
          inputCursor: inputActive ? undefined : 'pointer',
          inputActive,
          disabled,
          onChange: onChangeInput,
          onClear: onClearSearch,
          rightAddons: !disabled && inputArrow,
          value: searchValue,
          inputContainerRef: inputContainerRef,
          view: 'secondary',
          inputClue: valueIsLongerThenInput ? '' : clue,
          onKeyDown: keyDownHandler,
          ...inputProps,
          enableConditionToShowTooltip: false,
        }}
        onRemoveChip={onMergedRemoveChip}
      />
      <Options
        popoverProps={staticPopoverProps}
        selected={selected}
        options={availableOptions}
        onClick={onClickOption}
        {...optionsProps}
        optionsContainer={optionsContainer}
      />
    </>
  )
}

export type { MultipleSelectNewProps, MultipleSelectNewState }
export default MultipleSelectNew
