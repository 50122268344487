import { FC } from 'react'

import OrganizationUserDetailsWithoutSignForm from '@components/Sidebars/OrganizationUserDetails/Form/WithoutSign'
import OrganizationUserDetailsWithSignForm from '@components/Sidebars/OrganizationUserDetails/Form/WithSign'

interface OrganizationUserDetailsFormFC extends FC {
  WithoutSign: typeof OrganizationUserDetailsWithoutSignForm
  WithSign: typeof OrganizationUserDetailsWithSignForm
}

const OrganizationUserDetailsForm: OrganizationUserDetailsFormFC = () => null

OrganizationUserDetailsForm.WithoutSign = OrganizationUserDetailsWithoutSignForm
OrganizationUserDetailsForm.WithSign = OrganizationUserDetailsWithSignForm

export default OrganizationUserDetailsForm
