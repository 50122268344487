import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import AsyncWrapper from '@components/AsyncWrapper'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import RExploitationItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RExploitationForm/RExploitationItem'
import { ridExploitationProjectSZPKValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RExploitationForm/validation'
import {
  SeventhRidExploitationObjectsPathName,
  SeventhRidExploitationPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

interface RExploitationFormProps {
  name: SeventhRidExploitationPathName
  control: Control<ProjectSZPKFormValues>
  indexOfBlock: number
}

const RExploitationForm: FC<RExploitationFormProps> = ({ indexOfBlock, name, control }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
    },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getCalendarInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: exploitationRidForms } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhRidExploitationObjectsPathName,
    'keyNameId'
  >({
    name: `${name}.ridExploitationObjects`,
    control,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const handleAddRidExploitation = async () => {
    await handleAddItemToListWithOutValue?.(`${name}.ridExploitationObjects`)
  }

  const handleRemoveRidExplotation = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.ridExploitationObjects.${index}`,
      `${name}.ridExploitationObjects`,
    )
  }

  if (!formInstance) return null

  return (
    <Container className="pb-0 px-0">
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Ввод в эксплуатацию РИД планируется осуществить до'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.ridCommissioningEndDate`,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.ridCommissioningEndDate`,
                    rules: {
                      ...ridExploitationProjectSZPKValidationMap.ridCommissioningEndDate,
                      validate: {
                        positiveRidStateRegistrationEndDate: (value) => {
                          const ridStateRegistrationEndDate = formInstance.getValues(
                            `${projectSZPKBlockValues['7'].eventsOfStages}.${indexOfBlock}.ridStage.registration.ridStateRegistrationEndDate`,
                          ) as string

                          if (
                            isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) >
                              dayjs(ridStateRegistrationEndDate, objOfDateFormats.defaultFormat)
                          )
                            return 'дата ввода всех РИД в эксплуатацию должна быть меньше или равна дате регистрации всех РИД'
                        },
                      },
                    },
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.ridCommissioningEndDate`), 0),
                    onInputChange: () =>
                      setTimeout(
                        () => debouncedHandleChangeValue?.(`${name}.ridCommissioningEndDate`),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.ridCommissioningEndDate`), 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Stack direction="vertical" gap={3} className={'mt-3'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.ridExploitationObjects`,
            })}
          >
            <FlipperList list={exploitationRidForms}>
              {exploitationRidForms.map((exploitation, index) => {
                return (
                  <SubscribableControl
                    key={exploitation.id}
                    {...getSubscribableControlProps({
                      path: `${name}.ridExploitationObjects.${index}`,
                    })}
                  >
                    <RExploitationItem
                      id={exploitation.id}
                      anchorId={exploitation.anchorId}
                      formName={`${name}.ridExploitationObjects.${index}` as const}
                      blockViewIsValidating={blockViewIsValidating}
                      editMode={editMode}
                      formInstance={formInstance}
                      indexOfBlock={indexOfBlock}
                      indexOfExploitation={index}
                      onDeleteExploitation={handleRemoveRidExplotation(index)}
                    />
                  </SubscribableControl>
                )
              })}
            </FlipperList>
          </SubscribableControl>
          {editMode && (
            <Row className={'px-1'}>
              <Col xs={9}>
                <AsyncWrapper promise={handleAddRidExploitation}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <Button
                        disabled={isLoading}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        view={'plain'}
                        leadingIcon={{
                          src: CircleAddIcon,
                        }}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Указать РИД, введенный в эксплуатацию
                      </Button>
                    )
                  }}
                </AsyncWrapper>
              </Col>
            </Row>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

export default RExploitationForm
