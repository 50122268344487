import React, { useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import { CheckboxDefaultPayload } from '@components/Checkbox/types'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { getFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  parametersOfCostRecoveryApplicationBlockValues,
  parametersOfCostRecoveryApplicationConstValues,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import { CompensationStatementBudgetLevelsPathName } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/3/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'

const { transformRHFPathInProperties } = DocumentFormHelpers

const Third = () => {
  const formInstance = useFormContext<ParametersOfCostRecoveryApplicationFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleAddMultipleItemsToListWithValue,
      handleRemoveCustomValue,
      getPropertiesProps,
      getOverrideProps,
    },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getControllerProps, getSubscribableControlProps, getCheckboxProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
    })

  useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    CompensationStatementBudgetLevelsPathName
  >({
    name: parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementBudgetLevels,
    control: formInstance.control,
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const statementFormName = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementForm,
    control: formInstance.control,
  })

  const compensationStatementBudgetLevelsFromWatcher = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementBudgetLevels,
    control: formInstance.control,
  })

  const isCheckboxDisabled =
    statementFormName === parametersOfCostRecoveryApplicationConstValues.SUBSIDY

  const compensationStatementBudgetLevelsForAppend = useRef<(Record<string, unknown> | unknown)[]>(
    [],
  )

  const resetCompensationStatementBudgetLevelsForAppend = useDebouncedCallback(() => {
    compensationStatementBudgetLevelsForAppend.current = []
  }, 1800)

  const handleCheckSzpkType = async (
    szpkTypePath: string,
    szpkType: string,
    payload?: CheckboxDefaultPayload,
  ) => {
    const compensationStatementBudgetLevels = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(szpkTypePath),
    )

    const allControlProps = getOverrideProps?.()[compensationStatementBudgetLevels?.propertyId]

    const overrideProps = getFetcherProps(allControlProps)
    if (!overrideProps || !compensationStatementBudgetLevels || !overrideProps.length) return null

    const idOfAddProperty = overrideProps.find((item) => item.systemName === szpkType)?.id

    const bugetLevelInfo = {
      id: compensationStatementBudgetLevels.propertyId,
      lastUpdateDt: compensationStatementBudgetLevels.lastUpdateDt,
    }

    if (!payload?.checked) {
      const idToRemove = compensationStatementBudgetLevelsFromWatcher?.find(
        (item) => item.value.systemName === szpkType,
      ).propertyId

      if (!idToRemove) return
      return await handleRemoveCustomValue?.(bugetLevelInfo, idToRemove)
    }

    compensationStatementBudgetLevelsForAppend.current.push({
      id: idOfAddProperty,
    })

    await handleAddMultipleItemsToListWithValue?.(
      parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementBudgetLevels,
      compensationStatementBudgetLevelsForAppend.current,
      'compensationStatementBudgetLevels',
    )
    resetCompensationStatementBudgetLevelsForAppend()
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementYear,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Год заявления'}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['3']
                    .compensationStatementYear,
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementForm,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Форма возмещения'}
                controllerProps={getControllerProps({
                  name: parametersOfCostRecoveryApplicationBlockValues['3']
                    .compensationStatementForm,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Уровень бюджета, из которого запрашивается возмещение'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: parametersOfCostRecoveryApplicationBlockValues['3']
                .compensationStatementBudgetLevels,
            })}
          >
            <AsyncWrapper promise={handleCheckSzpkType}>
              {({ isLoading: isSzpkTypeChecking, wrappedPromise: wrappedHandleCheckSzpkType }) => (
                <Row className={styles.form__checkboxRow}>
                  <Col xs={4}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: parametersOfCostRecoveryApplicationBlockValues['3'].isFederal,
                      })}
                    >
                      <ControlledCheckbox
                        {...getCheckboxProps({
                          name: parametersOfCostRecoveryApplicationBlockValues['3'].isFederal,
                          checkBoxProps: {
                            disabled: isCheckboxDisabled || isSzpkTypeChecking || !editMode,
                            label: 'Федеральный',
                          },
                          onChange: async (e, payload) =>
                            wrappedHandleCheckSzpkType(
                              parametersOfCostRecoveryApplicationBlockValues['3'].isFederal,
                              parametersOfCostRecoveryApplicationConstValues.FEDERAL,
                              payload,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                  <Col xs={4}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: parametersOfCostRecoveryApplicationBlockValues['3'].isRegional,
                      })}
                    >
                      <ControlledCheckbox
                        {...getCheckboxProps({
                          name: parametersOfCostRecoveryApplicationBlockValues['3'].isRegional,
                          checkBoxProps: {
                            disabled: isCheckboxDisabled || isSzpkTypeChecking || !editMode,
                            label: 'Региональный',
                          },
                          onChange: async (e, payload) =>
                            wrappedHandleCheckSzpkType(
                              parametersOfCostRecoveryApplicationBlockValues['3'].isRegional,
                              parametersOfCostRecoveryApplicationConstValues.REGIONAL,
                              payload,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                  <Col xs={4}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: parametersOfCostRecoveryApplicationBlockValues['3'].isLocal,
                      })}
                    >
                      <ControlledCheckbox
                        {...getCheckboxProps({
                          name: parametersOfCostRecoveryApplicationBlockValues['3'].isLocal,
                          checkBoxProps: {
                            disabled: isCheckboxDisabled || isSzpkTypeChecking || !editMode,
                            label: 'Местный',
                          },
                          onChange: async (e, payload) =>
                            wrappedHandleCheckSzpkType(
                              parametersOfCostRecoveryApplicationBlockValues['3'].isLocal,
                              parametersOfCostRecoveryApplicationConstValues.LOCAL,
                              payload,
                            ),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
            </AsyncWrapper>
          </SubscribableControl>
        </Group>
      </Stack>
    </Container>
  )
}

export default Third
