import React, { FC } from 'react'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import objectItemStyles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import { useBooleanState } from '@hooks/useBooleanState'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

interface CollapsedCardProps {
  children: React.ReactNode | string
  cardNumber: number
  investorFullName: string
}

const CollapsedCard: FC<CollapsedCardProps> = ({ children, cardNumber, investorFullName }) => {
  const { booleanState: isExpanded, reverseBooleanState: toggleExpanded } = useBooleanState(false)

  return (
    <>
      <Row>
        <Col>
          <Button
            fixWidth
            view="plain"
            size="s"
            variant="buttonSMedium"
            color="default"
            className={objectItemStyles['objectItem__header-subObjectButton']}
            textClassName={cn(
              objectItemStyles['objectItem__header-subObjectButton__text'],
              objectItemStyles['objectItem__header-subObjectButton__text--wrapped'],
            )}
            trailingIcon={{
              src: chevronRightIcon,
              className: cn(objectItemStyles['objectItem__header-subObjectButton__icon'], {
                [objectItemStyles['objectItem__header-subObjectButton__icon--active']]: isExpanded,
              }),
            }}
            onClick={toggleExpanded}
          >
            №{cardNumber} · {investorFullName}
          </Button>
        </Col>
      </Row>

      {/* unmountOnCollapse нужен для производительности,
          ибо в этой форме может быть много нередактируемых карточек СЗПК */}
      <CollapseWrapper unmountOnCollapse isExpanded={isExpanded}>
        {children}
      </CollapseWrapper>
    </>
  )
}

export default CollapsedCard
