import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import BeneficiaryInformationLayout from './Layout'
import BeneficiaryInformationModalManager from './Manager'
import BeneficiaryInformationMenu from './Menu'
import BeneficiaryInformationStatementForm from './Statement'

interface IBeneficiaryInformation extends FC {
  Layout: typeof BeneficiaryInformationLayout
  Menu: typeof BeneficiaryInformationMenu
  StatementForm: typeof BeneficiaryInformationStatementForm
  Manager: typeof BeneficiaryInformationModalManager
}

const useBeneficiaryInformationControl = () => {
  const popupManager = usePopupManager()

  const openBeneficiaryInformationModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(BeneficiaryInformationLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openBeneficiaryInformationModal }
}

const BeneficiaryInformation: IBeneficiaryInformation = () => null

BeneficiaryInformation.Menu = BeneficiaryInformationMenu
BeneficiaryInformation.Layout = BeneficiaryInformationLayout
BeneficiaryInformation.StatementForm = BeneficiaryInformationStatementForm
BeneficiaryInformation.Manager = BeneficiaryInformationModalManager

export { useBeneficiaryInformationControl }
export default BeneficiaryInformation
