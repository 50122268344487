import { FC, memo, useCallback, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'
import { useNavigate } from 'react-router-dom'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import { Paths } from '@constants/paths'
import { useAPIContext } from '@context/APIContext'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { TGetProjectActions } from '@services/Projects/Project.entity'

import styles from './DeleteProject.module.scss'

export interface IDeleteProjectModal {
  projectId: string
  projectActions?: TGetProjectActions
  onSuccessProjectDelete?: () => void
}

export type DeleteProjectModalProps = Required<PopupProps> & IDeleteProjectModal

const DeleteProjectModal: FC<DeleteProjectModalProps> = ({
  isOpen,
  projectId,
  onClose,
  onSuccessProjectDelete,
}) => {
  const {
    projectsApi: { deleteProjectById },
  } = useAPIContext()

  const navigate = useNavigate()

  const handleDeleteProject = useCallback(async () => {
    try {
      await deleteProjectById(projectId)

      navigate(Paths.Projects)

      onSuccessProjectDelete?.()
      onClose()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DeleteProjectModal',
          moduleName: 'UiActionsManager',
          componentType: 'handleDeleteProject',
        },
      })(error)

      throw error
    }
  }, [deleteProjectById, navigate, onClose, onSuccessProjectDelete, projectId])

  const actions = useMemo(() => {
    return [
      {
        dataTestId: 'DeleteProjectModal-cancel-button',
        view: 'gray',
        children: 'Отмена',
        onClick: onClose,
      },
      {
        dataTestId: 'DeleteProjectModal-deleteProject-button',
        children: 'Удалить',
        color: 'negative',
        onClick: handleDeleteProject,
      },
    ] as ButtonProps[]
  }, [handleDeleteProject, onClose])

  return (
    <Modal.Action
      isOpen={isOpen}
      title={'Подтверждение удаления'}
      actions={actions}
      dataTestId="DeleteProjectModal-modal"
      closeButtonDataTestId="DeleteProjectModal-modal-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      actionModalContentClassName={styles.modalAction}
      onClose={onClose}
    >
      <Typography.Body variant="bodyMRegular" className={styles.body}>
        Вы действительно хотите удалить проект и всё его содержимое?
      </Typography.Body>
    </Modal.Action>
  )
}

export default memo(DeleteProjectModal)
