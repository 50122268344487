import React, { FC, memo } from 'react'
import { Outlet } from 'react-router'

import UserSupport from '@components/UserSupport'

import styles from './WithUserSupport.module.scss'

const WithUserSupport: FC = () => {
  return (
    <>
      <Outlet />
      <div className={styles.position}>
        <UserSupport />
      </div>
    </>
  )
}

export default memo(WithUserSupport)
