const mapOfCitizenshipCodes = {
  russianCitizenship: '1',
  foreignInfo: '2',
  withoutCitizenship: '3',
}

const mapOfDocumentTypeCodes = {
  russianPassport: '21',
}

export { mapOfCitizenshipCodes, mapOfDocumentTypeCodes }
