import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'

const readNotificationsKey = 'readNotifications'

export const useNotificationsCount = (props: SWRHookProps) => {
  const {
    notificationsApi: { getNotificationsCount },
  } = useAPIContext()

  const { cache } = useSWRConfig()

  const readNotifications = cache.get(readNotificationsKey)

  const readNotification = () => cache.set(readNotificationsKey, readNotifications + 1)

  const {
    data: notificationsCount,
    error,
    mutate,
    isValidating: isNotificationsLoading,
  } = useSWRImmutable<{ count: number }>(props.key, getNotificationsCount, {
    ...props.config,
    refreshInterval: 1000 * 30, // 30s
    onSuccess: () => cache.set(readNotificationsKey, 0),
  })

  return {
    readNotifications,
    readNotification,
    notificationsCount,
    error,
    mutate,
    isNotificationsLoading,
  }
}
