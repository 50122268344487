import {
  FetcherInterface,
  FetchMethods,
  fetchMethodsResponse,
  RequestProps,
  RequestPropsWithData,
} from '@context/AuthContext/types'
import { Headers } from '@packages/api/fetcher/config'
import { buildUrl } from '@packages/api/fetcher/helpers/buildUrl'
import { AxiosRequestConfig } from 'axios'

import { axiosInstance, axiosInstanceWithInterceptors } from './instances'

const neededMethods = Object.values(FetchMethods)
type methodsWithPropsWithData = typeof FetchMethods.POST | typeof FetchMethods.PUT

type request = typeof axiosInstance.get
type requestWithData = typeof axiosInstance.post

interface Request<C> {
  props: RequestProps<C>
  method: request
}

interface RequestWithData<C, D> {
  props: RequestPropsWithData<C, D>
  method: requestWithData
}

type RequestDecoratorProps<C, D> = Request<C> | RequestWithData<C, D>

const requestDecorator = <R = any, C = any, D = any>({
  props: { config: inputConfig, header, url, ...optional },
  method,
}: RequestDecoratorProps<C, D>) => {
  const gotSimpleUrl = typeof url === 'string'

  const config = {
    ...inputConfig,
    headers: { ...Headers[header || 'JSON'], ...inputConfig?.headers },
  } as C & AxiosRequestConfig<C>

  const finalUrl = gotSimpleUrl ? url : buildUrl(url)

  return 'data' in optional
    ? method<R, fetchMethodsResponse<R>>(finalUrl, optional.data, config)
    : method<R, fetchMethodsResponse<R>, C>(finalUrl, config)
}

const buildFetcher = (instance): FetcherInterface => {
  return neededMethods.reduce<FetcherInterface>((methods, methodName) => {
    methods[methodName] = <R, C, D>(
      props: typeof methodName extends methodsWithPropsWithData
        ? RequestPropsWithData<C, D>
        : RequestProps<C>,
    ) => requestDecorator<R, C, D>({ props, method: instance[methodName] })

    return methods
  }, {} as FetcherInterface)
}

export const Fetcher = buildFetcher(axiosInstance)
export const FetcherWithInterceptors = buildFetcher(axiosInstanceWithInterceptors)
