import { isEmptyString, isNullOrUndefined } from '@helpers/checkTypes'
import { MigrateArrayProps } from '@services/ReleaseMigration/ReleaseMigration.types'

import migrationFrom2_0_11_hotfix_4 from './[2.0.11-hotfix.4]'
import migrationFrom2_0_11_hotfix_5 from './[2.0.11-hotfix.5]'
import migrationFrom2_0_12 from './[2.0.12]'
import migrationFrom2_0_12_hotfix_1 from './[2.0.12-hotfix.1]'
import migrationFrom2_0_12_hotfix_2 from './[2.0.12-hotfix.2]'
import migrationFrom2_0_12_hotfix_5 from './[2.0.12-hotfix.5]'
import migrationFrom2_0_12_hotfix_6 from './[2.0.12-hotfix.6]'
import migrationFrom2_0_12_hotfix_7 from './[2.0.12-hotfix.7]'
import migrationFrom2_0_12_hotfix_10 from './[2.0.12-hotfix.10]'
import migrationFrom2_0_12_hotfix_12 from './[2.0.12-hotfix.12]'
import migrationFrom2_0_12_hotfix_14 from './[2.0.12-hotfix.14]'
import migrationFrom2_0_12_hotfix_15 from './[2.0.12-hotfix.15]'
import defaultMigration from './defaultMigration'

const migrationArray: MigrateArrayProps[] = [
  {
    version: '2.0.11-hotfix.4',
    migration: migrationFrom2_0_11_hotfix_4,
  },
  {
    version: '2.0.11-hotfix.5',
    migration: migrationFrom2_0_11_hotfix_5,
  },
  {
    version: '2.0.12',
    migration: migrationFrom2_0_12,
  },
  {
    version: '2.0.12-hotfix.1',
    migration: migrationFrom2_0_12_hotfix_1,
  },
  {
    version: '2.0.12-hotfix.2',
    migration: migrationFrom2_0_12_hotfix_2,
  },
  {
    version: '2.0.12-hotfix.5',
    migration: migrationFrom2_0_12_hotfix_5,
  },
  {
    version: '2.0.12-hotfix.6',
    migration: migrationFrom2_0_12_hotfix_6,
  },
  { version: '2.0.12-hotfix.7', migration: migrationFrom2_0_12_hotfix_7 },
  { version: '2.0.12-hotfix.10', migration: migrationFrom2_0_12_hotfix_10 },
  { version: '2.0.12-hotfix.12', migration: migrationFrom2_0_12_hotfix_12 },
  { version: '2.0.12-hotfix.14', migration: migrationFrom2_0_12_hotfix_14 },
  { version: '2.0.12-hotfix.15', migration: migrationFrom2_0_12_hotfix_15 },
]

const getMigrationsByPrevAndCurrentVersions = (
  lastUserAppVersion: string | undefined,
  currentAppVersion: string | undefined,
): MigrateArrayProps[] => {
  const defaultMigrationArray = [
    {
      version: 'default',
      migration: defaultMigration,
    },
  ]

  if (
    isNullOrUndefined(lastUserAppVersion) ||
    isEmptyString(lastUserAppVersion) ||
    isNullOrUndefined(currentAppVersion) ||
    isEmptyString(currentAppVersion)
  ) {
    return defaultMigrationArray
  }

  let indexOfStartMigration = 0
  let indexOfEndMigration = 0

  migrationArray.forEach((migrationItem, index) => {
    if (migrationItem.version === lastUserAppVersion) {
      indexOfStartMigration = index
    }
    if (migrationItem.version === currentAppVersion) {
      indexOfEndMigration = index
    }
  })

  const preparedMigrations = migrationArray.slice(indexOfStartMigration, indexOfEndMigration + 1)

  if (preparedMigrations.length) return preparedMigrations

  return defaultMigrationArray
}

export { getMigrationsByPrevAndCurrentVersions }
