import React from 'react'
import { useFormContext } from 'react-hook-form'

import InfoAboutDocumentSection from '@components/DocumentFormComponents/FormSections/InfoAboutDocumentSection'
import { useStatementByHeadOfMunicipalityManager } from '@components/Forms/StatementByHeadOfMunicipality/Manager'
import { StatementByHeadOfMunicipalityFormValues } from '@components/Forms/StatementByHeadOfMunicipality/types'
import { StatementByHeadOfMunicipalityFieldsControlUpdateWatcher } from '@components/Forms/StatementByHeadOfMunicipality/watcher'

const First = () => {
  const formInstance = useFormContext<StatementByHeadOfMunicipalityFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useStatementByHeadOfMunicipalityManager()

  if (!formInstance) return null

  return (
    <InfoAboutDocumentSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={StatementByHeadOfMunicipalityFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default First
