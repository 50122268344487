import { DependentUrls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { ProcessesTypes } from '@services/Processes/processes.const'

import { CreateProcessProps } from './types'

const useProcessesApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const createProcess = async ({
    projectId,
    actionId,
    processType = ProcessesTypes.SEAMLESS_CORRECTING,
  }: CreateProcessProps) => {
    try {
      return await FetcherWithInterceptors.post({
        url: {
          url: DependentUrls.CreateProcess,
          params: {
            projectId,
            actionId,
          },
        },
        data: {
          processType,
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    createProcess,
  }
}

export default useProcessesApi
