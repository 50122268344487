import React, { memo } from 'react'

import Typography from '@components/NewDesign/Typography'

import styles from './Header.module.scss'

const SystemVariablesHeader = () => {
  return (
    <div className={styles.header}>
      <Typography.Headline variant={'headlineH2'}>Системные переменные</Typography.Headline>
    </div>
  )
}

export default memo(SystemVariablesHeader)
