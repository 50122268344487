import { usePopupManager } from 'react-popup-manager'

import RegistrationSignModal from '@components/NewDesignedModals/RegistrationSignModal'
import {
  loaderModalBodyTexts,
  loaderModalHeaderTexts,
  successModalBodyTexts,
  successModalHeaderTexts,
} from '@constants/texts'
import { NewDfosType } from '@constants/types'

import { mapOfRejectInfo } from './constants'
import type {
  HandleOpenRegistrationSignModalProps,
  HandleOpenTerminateRegistrationSignModalProps,
} from './types'

const useRegistrationSignModal = () => {
  const popupManager = usePopupManager()

  const handleOpenRegistrationModal = ({
    actionType,
    actionId,
    dfoId,
    projectId,
    onSuccessSignFiles,
    onErrorSignFiles,
    onClose,
  }: HandleOpenRegistrationSignModalProps) => {
    popupManager.open(RegistrationSignModal, {
      actionType,
      dfoId,
      actionId,
      projectId,

      loaderHeaderTitle: loaderModalHeaderTexts.agreementIntoRegister,
      loaderBodyText: loaderModalBodyTexts.progressMessage,

      successHeaderTitle: successModalHeaderTexts.agreementIntoRegister,
      successBodyText: successModalBodyTexts.agreementInfo,

      onClose: onClose ?? (() => null),
      onSuccessSignFiles,
      onErrorSignFiles,
    })
  }

  const handleOpenRegistrationRejectModal = ({
    actionType,
    dfoId,
    actionId,
    projectId,
    onSuccessSignFiles,
    onErrorSignFiles,
    dfoType,
    onClose,
  }: HandleOpenTerminateRegistrationSignModalProps) => {
    popupManager.open(RegistrationSignModal, {
      actionType,
      dfoId,
      actionId,
      projectId,

      loaderHeaderTitle: loaderModalHeaderTexts.terminationIntoRegister,
      loaderBodyText: loaderModalBodyTexts.progressMessage,

      headerTitle: mapOfRejectInfo[dfoType]?.title || '',
      bodyText: mapOfRejectInfo[dfoType]?.description || '',

      disableNumberOfRegistration: mapOfRejectInfo[dfoType]?.disabledNumberInput,

      dateRegistrationPlaceholder:
        dfoType === NewDfosType.SZPK_TERMINATE_JUDGMENT ? 'Дата решения суда' : undefined,

      successHeaderTitle: successModalHeaderTexts.terminationInRegister,
      successBodyText: successModalBodyTexts.terminationInfo,

      onClose: onClose ?? (() => null),
      onSuccessSignFiles,
      onErrorSignFiles,
    })
  }

  return {
    handleOpenRegistrationModal,
    handleOpenRegistrationRejectModal,
  }
}

export default useRegistrationSignModal
