import { isArray, isNullOrUndefined, isObject } from '@helpers/checkTypes'

const removeNullOrUndefinedValuesFromObject = (object: Record<string, unknown>) => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (isNullOrUndefined(value)) {
      return acc
    }

    if (isObject(value)) {
      return {
        ...acc,
        [key]: removeNullOrUndefinedValuesFromObject(value as Record<string, unknown>),
      }
    }

    if (isArray(value)) {
      return {
        ...acc,
        [key]: value.filter((item) => !isNullOrUndefined(item)),
      }
    }

    return { ...acc, [key]: value }
  }, {})
}

export { removeNullOrUndefinedValuesFromObject }
