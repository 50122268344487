import { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useExtendedFormModeByUserRoles } from '@components/DocumentFormComponents/hooks/useExtendedFormModeByUserRoles'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import {
  defaultFinancialModelFormValue,
  mapOfFinancialModelMenu,
} from '@components/Forms/FinancialModelForm/const'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { FinancialModelFieldsControlUpdateWatcher } from '@components/Forms/FinancialModelForm/watcher'
import { Switch } from '@components/NewDesign/Switch'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import FinancialModel from '../index'

import FinancialModelLayoutWrapper from './Wrapper'

const FinancialModelLayout: FC<FormModalLayoutProps> = ({
  isOpen,
  onClose,
  formId,
  projectId,
  editMode,
  initialErrorsFromViolations,
  rolesOfUser,
}) => {
  const formInstance = useFormWithDefaultProps<FinancialModelFormValues>({
    defaultValues: defaultFinancialModelFormValue,
  })

  const {
    currentDebugMode,
    handleChangeDebugMode,
    conditionToActivateDebugMode,
    disabledDebugMode,
  } = useExtendedFormModeByUserRoles(rolesOfUser)

  const handleOnClose = () => {
    disabledDebugMode()
    FinancialModelFieldsControlUpdateWatcher.resetAllState()

    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <FinancialModel.Manager
          projectId={projectId}
          formId={formId}
          editMode={editMode}
          rolesOfUser={rolesOfUser}
          initialErrorsFromViolations={initialErrorsFromViolations}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfFinancialModelMenu[0].id}>
            <FinancialModelLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Ключевые показатели финансовой модели
                </Typography.Headline>
                <FinancialModel.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}
              >
                <FinancialModel.Menu
                  className={cn(styles.layout__menu, styles['layout__menu--nested'])}
                >
                  {conditionToActivateDebugMode && (
                    <Switch
                      defaultState={currentDebugMode}
                      wrapperClassName={styles.layout__debug}
                      label={'Просмотр мета информации'}
                      onChange={handleChangeDebugMode}
                    />
                  )}
                </FinancialModel.Menu>
              </div>
            </FinancialModelLayoutWrapper>
          </FlatMenuManager>
        </FinancialModel.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(FinancialModelLayout)
