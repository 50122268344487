type DraftEnumError = '01.01' | '01.02'

type RegistrationErrorCodes = '_00_02' | '_00_03'

const mapOfCustomErrorCodes = {
  ORGANIZATION_NOT_REGISTERED: '00.00',

  ORGANIZATION_NOT_WILL_REGISTERED_MER: '_00_02',
  ORGANIZATION_NOT_WILL_REGISTERED: '_00_03',

  ORGANIZATION_DETERMINING_AUTHORIZED_PERSONS: '_00_04',

  USER_DETERMINING_AUTHORIZED_PERSONS: '00.04',

  STATEMENT_DRAFT: '01.01',
  NPA_DRAFT: '01.02',

  FORM_PREVIEW_TEMPLATE_ERROR: '01.20',

  DOMAINS_NOT_FOUND: '02.01',
  DOMAINS_FOUND: '02.02',

  OGV_ACCESS_DENIED: '03.01',
  AUTH_NOT_ENOUGH_RIGHTS: '03.02',

  ACTIONS_NOT_FOUND: '05.01',
}

export type { DraftEnumError, RegistrationErrorCodes }
export { mapOfCustomErrorCodes }
