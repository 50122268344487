import React, { FC, memo } from 'react'

import styles from '@components/Attachments/Attachment/FileItem/TrailingNode/FileItemTrailingNode.module.scss'
import Typography from '@components/NewDesign/Typography'

interface TrailingAdditionInTrailingNodeProps {
  version?: number
}

const TrailingAdditionInTrailingNode: FC<TrailingAdditionInTrailingNodeProps> = ({
  version = 0,
}) => {
  const hasCurrentVersionsLength = version > 1

  if (!hasCurrentVersionsLength) return null

  return (
    <Typography.Body
      color="text-base-tertiary"
      variant="bodySMedium"
      className={styles['trailingNode__item-version']}
    >
      {version} версия
    </Typography.Body>
  )
}

export default memo(TrailingAdditionInTrailingNode)
