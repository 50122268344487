import { FC } from 'react'

import One from './1'
import Two from './2'

interface StatementAlreadyInvestmentsFormsProps extends FC {
  One: typeof One
  Two: typeof Two
}

const StatementAlreadyInvestmentsForms: StatementAlreadyInvestmentsFormsProps = () => null

StatementAlreadyInvestmentsForms.One = One
StatementAlreadyInvestmentsForms.Two = Two

export default StatementAlreadyInvestmentsForms
