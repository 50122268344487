import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AAConnectionReorganizationImplementingFormValues } from '@components/Forms/AAConnectionReorganizationImplementingForm/types'

const {
  getDefaultRfFieldState,
  getDefaultSubjectsFieldState,
  getDefaultMunicipalitiesFieldState,
  getDefaultInvestorPartnerFieldStates,
} = FieldStateHelpers

const mapOfAAConnectionReorganizationImplementingMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация правопреемник' },
]

const defaultAAConnectionReorganizationImplementingFormValues: AAConnectionReorganizationImplementingFormValues =
  {
    '1': getDefaultRfFieldState(),
    '2': {
      subjects: getDefaultSubjectsFieldState(),
    },
    '3': {
      municipalities: getDefaultMunicipalitiesFieldState(),
    },
    '4': {
      ...getDefaultInvestorPartnerFieldStates(),
      investorPartnerOgrn: '',
      investorPartnerInn: '',
      investorPartnerEgrulAddress: '',
    },
    additionalFields: {
      isFederal: true,
      initialMunicipalities: [],
    },
  }

const aAConnectionReorganizationImplementingBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorPartnerFullName: '4.investorPartnerFullName',
    investorPartnerHeaderName: '4.investorPartnerHeaderName',
    investorPartnerInn: '4.investorPartnerInn',
    investorPartnerOgrn: '4.investorPartnerOgrn',
    investorPartnerEgrulAddress: '4.investorPartnerEgrulAddress',
    investorPartnerHeaderNameGenitive: '4.investorPartnerHeaderNameGenitive',
    investorPartnerHeaderPosition: '4.investorPartnerHeaderPosition',
    investorPartnerHeaderPositionGenitive: '4.investorPartnerHeaderPositionGenitive',
    investorPartnerHeaderReasonGenitive: '4.investorPartnerHeaderReasonGenitive',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
  },
} as const

export {
  aAConnectionReorganizationImplementingBlockValues,
  defaultAAConnectionReorganizationImplementingFormValues,
  mapOfAAConnectionReorganizationImplementingMenu,
}
