import React, { type MouseEvent, FC, ReactNode } from 'react'

import styles from '@components/Attachments/WebForm/WithDocumentController/DocumentItem/WebFormDocumentItem.module.scss'
import IconButton from '@components/NewDesign/IconButton'
import { IconButtonProps } from '@components/NewDesign/IconButton/types'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import downloadAttachmentIcon from '@icons/attachment/actions/DownloadAttachmentIcon.svg'
import cn from 'classnames'

interface DownloadProps {
  signCondition?: boolean
  downloadTooltipContent?: ReactNode
  onDownload: VoidFunction
}

interface WebFormIconButtonProps extends Omit<IconButtonProps, 'onClick' | 'icon'> {
  icon?: IconButtonProps['icon']
}

interface WebFormDocumentDownloadButtonProps extends DownloadProps {
  iconProps?: WebFormIconButtonProps
}

const WebFormDocumentDownloadButton: FC<WebFormDocumentDownloadButtonProps> = ({
  signCondition,
  downloadTooltipContent,
  onDownload,
  iconProps,
}) => {
  const { view = 'tined', size = 's', icon, ...restIconButtonProps } = iconProps || {}

  const {
    state: { tooltipIsOpen: downloadTooltipIsOpen, targetTooltipRef: targetDownloadTooltipRef },
    handlers: {
      handleOpenTooltip: handleOpenDownloadTooltip,
      handleCloseTooltip: handleCloseDownloadTooltip,
    },
  } = useManualTooltipControl()

  const handleDownloadTooltipClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    handleOpenDownloadTooltip()
  }

  const handleDownloadClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    onDownload()
  }

  return (
    <Tooltip
      open={downloadTooltipIsOpen}
      targetRef={targetDownloadTooltipRef}
      position={'bottom-start'}
      content={downloadTooltipContent}
      arrowClassName={styles.tooltip__arrow}
      popoverClassName={cn(styles.tooltip__popover, styles['tooltip__popover--download'])}
      contentClassName={cn(styles.tooltip__content, styles['tooltip__content--download'])}
    >
      <IconButton
        {...restIconButtonProps}
        view={view}
        size={size}
        dataTestId="WebFormDocumentItem-ControlContainer-downloadButton"
        icon={{
          ...icon,
          src: icon?.src || downloadAttachmentIcon,
          className: cn(styles['webFormDocumentItem__action-buttonIcon'], icon?.className),
        }}
        onBlur={handleCloseDownloadTooltip}
        onClick={signCondition ? handleDownloadTooltipClick : handleDownloadClick}
      />
    </Tooltip>
  )
}

export type { DownloadProps }
export default WebFormDocumentDownloadButton
