import { useCallback } from 'react'
import { FieldValues } from 'react-hook-form'

import { IOverridePropsFromClass } from '@services/Properties/Properties.entity'

interface UseGenerateValuesFromObjectAdapterProps<T extends FieldValues> {
  generateRHFObject: (objectFromPropertyState: Record<string, any>) => T
  generatePropertiesObject: (
    objectFromPropertyState: Record<string, any>,
  ) => Record<string, unknown>
  generateOverrideProps?: (
    objectFromPropertyState: Record<string, any>,
  ) => IOverridePropsFromClass[]
  generatePrefetchedPropsArray?: (objectFromPropertyState: Record<string, any>) => string[]
}

interface AdvancedProps {
  prefetchedIds: string[]
  customProps: IOverridePropsFromClass[]
}

type GetAdvancedPropsFromObjectAdapterPropsHandler = (
  objectFromPropertyState: Record<string, any>,
) => Promise<AdvancedProps>

const useGenerateValuesFromObjectAdapter = <T extends FieldValues>({
  generateOverrideProps,
  generatePrefetchedPropsArray,
  generatePropertiesObject,
  generateRHFObject,
}: UseGenerateValuesFromObjectAdapterProps<T>) => {
  const getBasePropsFromObjectAdapter = useCallback(
    (objectForAdapter: Record<string, any>) => {
      const propertiesProps = generatePropertiesObject(objectForAdapter)

      const RHFValueForReset = generateRHFObject(objectForAdapter)

      return {
        propertiesProps,
        RHFValueForReset,
      }
    },
    [generatePropertiesObject, generateRHFObject],
  )

  const getAdvancedPropsFromObjectAdapter: GetAdvancedPropsFromObjectAdapterPropsHandler =
    useCallback(
      async (objectForAdapter: Record<string, any>) => {
        const prefetchedIds = (await generatePrefetchedPropsArray?.(objectForAdapter)) || []

        const customProps = (await generateOverrideProps?.(objectForAdapter)) || []

        return {
          prefetchedIds,
          customProps,
        }
      },
      [generateOverrideProps, generatePrefetchedPropsArray],
    )

  return { getBasePropsFromObjectAdapter, getAdvancedPropsFromObjectAdapter }
}

export type { GetAdvancedPropsFromObjectAdapterPropsHandler }
export { useGenerateValuesFromObjectAdapter }
