import React, { FC, memo } from 'react'

import Icon from '@components/Icon/Icon'
import Typography from '@components/NewDesign/Typography'
import { UserProfiles } from '@constants/types'
import lockOutlineIcon from '@icons/action/lock_outline.svg'
import warningAmberIcon from '@icons/alert/warning_amber.svg'
import { LOCK_ICON_COLOR } from '@routes/Users/constants'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { IOrganizationUser } from '@services/Organization/organization.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import styles from './UserItemDetails.module.scss'

interface UserItemDetailsProps {
  user: IOrganizationUser
}

const UserItemDetails: FC<UserItemDetailsProps> = ({ user }) => {
  const {
    isEnabled,
    profile,
    position,
    fioGenitive,
    reasonProcurationEndDate,
    reasonProcurationId,
    reasonDocument,
    positionGenitive,
  } = user

  const isEmptyUserDetails =
    profile === UserProfiles.HEADER &&
    (!fioGenitive || !position || !positionGenitive || (!reasonDocument && !reasonProcurationId))

  const isExpiredProcuration =
    !!reasonProcurationEndDate &&
    dayjs(reasonProcurationEndDate) < DayjsService.dayjsWithFormatToMSK()

  if (!isEnabled) {
    return (
      <div className={styles.userItemDetails}>
        <Icon
          src={lockOutlineIcon}
          className={styles.userItemDetails__icon}
          color={LOCK_ICON_COLOR}
          size="s"
        />
        <Typography.Body variant={'bodyMMedium'} color="text-base-tertiary">
          Заблокирован оператором
        </Typography.Body>
      </div>
    )
  }

  if (isEmptyUserDetails) {
    return (
      <div className={styles.userItemDetails}>
        <Icon
          src={warningAmberIcon}
          size="s"
          className={cn(styles.userItemDetails__icon, styles['userItemDetails__icon--warning'])}
        />
        <Typography.Body variant={'bodyMMedium'} color="text-accent-orange-secondary">
          Укажите данные пользователя
        </Typography.Body>
      </div>
    )
  }

  if (isExpiredProcuration) {
    return (
      <div className={styles.userItemDetails}>
        <Icon
          src={warningAmberIcon}
          size="s"
          className={cn(styles.userItemDetails__icon, styles['userItemDetails__icon--warning'])}
        />
        <Typography.Body variant={'bodyMMedium'} color="text-accent-orange-secondary">
          Истек срок действия доверенности
        </Typography.Body>
      </div>
    )
  }

  return null
}

export default memo(UserItemDetails)
