import React, { FC } from 'react'
import { PopupProps } from 'react-popup-manager'

import Modal from '@components/NewDesign/Modal'
import BaseModalActions, { BaseActionsModal } from '@components/NewDesign/Modal/Base/Actions'
import { SimpleModalComponentProps } from '@components/NewDesign/Modal/Simple'
import cn from 'classnames'

import styles from './ActionModal.module.scss'

export type ModalActionWithoutActionProps = SimpleModalComponentProps & {
  actionModalContentClassName?: string
}
export type ModalActionComponentProps = ModalActionWithoutActionProps & BaseActionsModal

type ModalActionProps = ModalActionComponentProps & PopupProps

const ModalAction: FC<ModalActionProps> = ({
  actions,
  children,
  actionModalContentClassName,
  actionsClassName,
  ...simpleModalRest
}) => {
  const handleClose = () => {
    simpleModalRest.onClose?.()
  }

  return (
    <Modal.Simple {...simpleModalRest}>
      <div className={cn(styles.actionModal__body, actionModalContentClassName)}>{children}</div>
      <BaseModalActions actions={actions} className={actionsClassName} onClose={handleClose} />
    </Modal.Simple>
  )
}

export default ModalAction
