import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import CustomTransition from '@components/Transition'
import { useHover } from '@hooks/useHover'
import backArrowIcon from '@icons/BackArrow.svg'
import cn from 'classnames'

import styles from '../Layout/Layout.module.scss'

interface LayoutContentProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  draftIsSaved: boolean
  onClose: () => void
}

const DocumentLayoutContent: FC<LayoutContentProps> = ({
  draftIsSaved,
  onClose,
  children,
  ...rest
}) => {
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>()

  return (
    <div {...rest} id="form-layout" className={cn(styles.layout__wrapper, rest.className)}>
      <div className={styles.layout__close}>
        <IconButton
          accent
          ref={hoverRef}
          view={'basic'}
          geometry={'round'}
          size={'xl'}
          type={'button'}
          dataTestId="LayoutWrapper-close-icon-button"
          icon={{
            src: backArrowIcon,
            className: styles['layout__close-icon'],
          }}
          onClick={onClose}
        />
        <CustomTransition.Fade show={isHovered && draftIsSaved}>
          <Typography.Caption
            className={styles['layout__close-text']}
            color={'on-surface-secondary'}
            variant={'captionSRegular'}
          >
            Изменения сохранены
          </Typography.Caption>
        </CustomTransition.Fade>
      </div>
      <div className={styles.layout__container}>
        <div className={styles.layout__content}>{children}</div>
      </div>
    </div>
  )
}

export default DocumentLayoutContent
