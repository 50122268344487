import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import {
  CertificateOfImplementationInvestprojectStagesFormModifierValues,
  CertificateOfImplementationInvestprojectStagesFormValues,
} from '@components/Forms/CertificateOfImplementationInvestprojectStagesForm/types'

const { getDefaultInitiatorFieldState, getDefaultInfoAboutDocument } = FieldStateHelpers
const mapOfCertificateOfImplementationInvestprojectStagesMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченный орган' },
  { id: '3', title: 'Завершенные этапы' },
]

const defaultCertificateOfImplementationInvestprojectStagesFormValues: CertificateOfImplementationInvestprojectStagesFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': getDefaultInitiatorFieldState(),
    '3': {
      stepsMonitoringEnded: [],
    },
  }

const defaultCertificateOfImplementationInvestprojectStagesFormModifierValues: CertificateOfImplementationInvestprojectStagesFormModifierValues =
  {
    '3': {
      stepsMonitoringEnded: [],
    },
  }

const certificateOfImplementationInvestprojectStagesBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderNameGenitive: '2.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '2.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '2.initiatorHeaderReasonGenitive',
  },
  '3': {
    stepsMonitoringEnded: '3.stepsMonitoringEnded',
  },
} as const

export {
  certificateOfImplementationInvestprojectStagesBlockValues,
  defaultCertificateOfImplementationInvestprojectStagesFormModifierValues,
  defaultCertificateOfImplementationInvestprojectStagesFormValues,
  mapOfCertificateOfImplementationInvestprojectStagesMenu,
}
