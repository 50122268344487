import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'

import Alert from '@components/Alert'
import { RolesByActions } from '@constants/types'
import { getUserRole } from '@context/AuthContext/workers/rolesWorkers'
import { equals, findOne } from '@helpers/commonHelpers'
import { useDfoAvailableForCreate } from '@hooks/new/swr/useDfoAvailableForCreate'
import { useDocumentsSetDictTypes } from '@hooks/new/swr/useDocumentsSetDictTypes'
import { useProjectDfos } from '@hooks/new/swr/useProjectDfos'
import { useVersions } from '@hooks/new/swr/useVersions'
import { useDebouncedCallback } from '@hooks/useDebounceCallback'
import { useQueryManager } from '@hooks/useQueryManager'
import { IDfoListItem, IDfoNewList } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { unstable_serialize, useSWRConfig } from 'swr'

interface DocumentsContextProps {
  state: {
    currentDfoInfo: IDfoListItem | null
    projectDfosWithGroup?: IDfoNewList[]
    projectDfos: IDfoListItem[]
    dfoAvailableForCreate?: string[]
    createdDfoCategories?: string[]
  }
  handlers: {
    handleChangeDfo?: (dfo: IDfoListItem) => void
  }
}

const DfosContext = createContext<DocumentsContextProps>({
  state: {
    currentDfoInfo: null,
    projectDfos: [],
  },
  handlers: {},
})

const DfoManager = ({ children }) => {
  const { projectId } = useParams()
  const { queryUtils } = useQueryManager()

  const { cache } = useSWRConfig()

  const { roles } = getUserRole?.()
  const isFKOrUO = findOne(roles, RolesByActions.CantCreateNewODO)

  //Вызывается для инициализации в кеш
  useDocumentsSetDictTypes({ key: { _key: 'documentsSetsDictTypes' } })

  const { projectDfos, projectDfosWithGroup } = useProjectDfos({
    key: projectId ? { projectId, _key: 'projectDfos' } : null,
    config: {
      revalidateOnMount: true,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DfoManager',
          moduleName: 'ProjectLayout',
          componentType: 'manager',
        },
      }),
    },
  })

  const { dfoAvailableForCreate } = useDfoAvailableForCreate({
    key: { projectId, _key: 'availableDfoForCreate' },
    config: {
      isPaused: () => !projectId || isFKOrUO,
    },
  })

  const dfoId = queryUtils.getQuery('dfoId')
  const [currentDfoInfo, setCurrentDfoInfo] = useState<IDfoListItem | null>(null)

  const { versions, actualVersionId } = useVersions({
    key: { dfoId, projectId, _key: 'versions' },
    config: {
      isPaused: () => !dfoId || !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DfoManager',
          moduleName: 'ProjectLayout',
          componentType: 'manager',
        },
      }),
    },
  })

  const debouncedCallVersionToast = useDebouncedCallback(() => {
    toast(
      <Alert transparent variant="warning">
        Недостаточно прав для просмотра данной версии
      </Alert>,
    )
  }, 1000)

  //Удаляем аттрибуты проекта при  его анмаунте для корректного перезапрашивания аттрибутов
  useEffect(() => {
    return () => {
      cache.delete(unstable_serialize({ projectId, _key: 'projectAttributes' }))
    }
  }, [cache, projectId])

  const handlePreparedProjectsDfosWithGroup = useCallback((groupsWithDfo: IDfoNewList[]) => {
    return groupsWithDfo.reduce((acc: IDfoNewList[], curr) => {
      if (curr.dfos) {
        acc.push({ ...curr, dfos: curr.dfos })
      }

      if (curr.subgroups) {
        acc.push({
          ...curr,
          subgroups: handlePreparedProjectsDfosWithGroup(curr.subgroups),
        })
      }

      return acc
    }, [])
  }, [])

  const preparedProjectsDfosWithGroup = useMemo(() => {
    if (!projectDfosWithGroup) return

    return handlePreparedProjectsDfosWithGroup(projectDfosWithGroup)
  }, [handlePreparedProjectsDfosWithGroup, projectDfosWithGroup])

  const createdDfoCategories = useMemo(() => {
    return projectDfos?.map((projectDfo) => {
      if (projectDfo.type.includes('CLAIM_SUBSIDY_PERMIT')) return 'CLAIM_SUBSIDY_PERMIT'

      return projectDfo.type
    })
  }, [projectDfos])

  /**
   * oldVersionDfo - находим неактуальную версию Dfo
   * isVersionDfoWithoutDfoReadPermission - проверяем права на доступность найденного Dfo
   *  выводим алерт, если нет соответствующих прав
   * dfoWithActualVersionId - если это неактуальная версия, то берем тело Dfo актуальной версии
   *  либо если нет актуальных версий (isActual = false у всех версий)
   *  то берем ту, с которой есть совпадение
   */
  const getDfoObjectWithActualVersionId = useCallback(() => {
    if (!projectDfos?.length || !versions || !dfoId) return

    const oldVersionDfo =
      versions && versions?.find((version) => !version.isActual && version.id === dfoId)

    const isVersionDfoWithoutDfoReadPermission = oldVersionDfo && !oldVersionDfo.dfoReadPermission

    if (isVersionDfoWithoutDfoReadPermission) {
      debouncedCallVersionToast()
    }

    const dfoWithActualVersionId =
      oldVersionDfo &&
      projectDfos?.find(
        (dfo) => dfo.id === actualVersionId || versions.some((version) => version.id === dfo.id),
      )

    if (dfoWithActualVersionId) {
      return { ...dfoWithActualVersionId, id: dfoId }
    }

    return projectDfos?.find((dfo) => dfo.id === actualVersionId) || projectDfos[0]
  }, [actualVersionId, dfoId, projectDfos, versions])

  const handleDefaultChooseProcess = useCallback(() => {
    if (!projectDfos) return

    if ((!dfoId || dfoId === 'undefined') && projectDfos[0]?.id) {
      queryUtils.addQuery({
        query: {
          dfoId: projectDfos[0].id,
        },
      })

      setCurrentDfoInfo(projectDfos[0])
      return
    }
  }, [dfoId, projectDfos, queryUtils])

  const handleChangeDfo = useCallback(
    (dfo: IDfoListItem) => {
      if (dfoId !== dfo.id) {
        queryUtils.addQuery({
          query: {
            dfoId: dfo.id,
          },
        })
      }

      setCurrentDfoInfo((prevDfo) => {
        if (!prevDfo) return dfo
        const objectIsEquals = equals(prevDfo, dfo)
        return objectIsEquals ? prevDfo : dfo
      })
    },
    [queryUtils],
  )

  const handleProcessDfosBeforeChoose = useCallback(() => {
    const dfoObjectWithActualVersionId = getDfoObjectWithActualVersionId()

    if (!dfoObjectWithActualVersionId) return

    handleChangeDfo(dfoObjectWithActualVersionId)
  }, [getDfoObjectWithActualVersionId, handleChangeDfo])

  /**
   * dfoObjectWithActualVersionId - подменяем телу Dfo актуальной версии ID неактуальной версии
   */
  useEffect(() => {
    //Подхватит изменение верхнего и пересчитает снова уже относительно версинности, прав и т.д
    handleProcessDfosBeforeChoose()
  }, [handleProcessDfosBeforeChoose])

  useEffect(() => {
    //Отвечает за первоначальный выбор дфо системой, если был произведен вход только по projectId
    handleDefaultChooseProcess()
  }, [handleDefaultChooseProcess])

  const providerValue = useMemo(
    () => ({
      state: {
        currentDfoInfo,
        dfoAvailableForCreate,
        createdDfoCategories,
        projectDfosWithGroup: preparedProjectsDfosWithGroup,
        projectDfos,
      },
      handlers: {
        handleChangeDfo,
      },
    }),
    [
      preparedProjectsDfosWithGroup,
      projectDfos,
      currentDfoInfo,
      dfoAvailableForCreate,
      createdDfoCategories,
      handleChangeDfo,
    ],
  )

  return <DfosContext.Provider value={providerValue}>{children}</DfosContext.Provider>
}

const useDfosManager = () => {
  return useContext(DfosContext)
}

export { useDfosManager }
export default DfoManager
