import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNotificationOfIdentifiedViolationsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getUOAdapter(objectFromPropertyState),
    },
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: {
        propertyId: objectFromPropertyState?.investorFullNameGenitive?.propertyId,
        type: objectFromPropertyState?.investorFullNameGenitive?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameGenitive?.lastUpdateDt,
      },
    },
    '4': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
      ruleLinkStatementReturn: {
        propertyId: objectFromPropertyState.ruleLinkStatementReturn?.propertyId,
        type: objectFromPropertyState.ruleLinkStatementReturn?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkStatementReturn?.lastUpdateDt,
      },
      statementTechError: {
        propertyId: objectFromPropertyState.statementTechError?.propertyId,
        type: objectFromPropertyState.statementTechError?.type,
        lastUpdateDt: objectFromPropertyState.statementTechError?.lastUpdateDt,
      },
      statementSetDocumentError: {
        propertyId: objectFromPropertyState.statementSetDocumentError?.propertyId,
        type: objectFromPropertyState.statementSetDocumentError?.type,
        lastUpdateDt: objectFromPropertyState.statementSetDocumentError?.lastUpdateDt,
      },
      statementDocumentError: {
        propertyId: objectFromPropertyState.statementDocumentError?.propertyId,
        type: objectFromPropertyState.statementDocumentError?.type,
        lastUpdateDt: objectFromPropertyState.statementDocumentError?.lastUpdateDt,
      },
      ruleLinkDocumentError: {
        propertyId: objectFromPropertyState.ruleLinkDocumentError?.propertyId,
        type: objectFromPropertyState.ruleLinkDocumentError?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkDocumentError?.lastUpdateDt,
      },
      statementHeadError: {
        propertyId: objectFromPropertyState.statementHeadError?.propertyId,
        type: objectFromPropertyState.statementHeadError?.type,
        lastUpdateDt: objectFromPropertyState.statementHeadError?.lastUpdateDt,
      },
      statementInvestorError: {
        propertyId: objectFromPropertyState.statementInvestorError?.propertyId,
        type: objectFromPropertyState.statementInvestorError?.type,
        lastUpdateDt: objectFromPropertyState.statementInvestorError?.lastUpdateDt,
      },
      statementProjectCompanyError: {
        propertyId: objectFromPropertyState.statementprojectCompanyError?.propertyId,
        type: objectFromPropertyState.statementprojectCompanyError?.type,
        lastUpdateDt: objectFromPropertyState.statementprojectCompanyError?.lastUpdateDt,
      },
      statementProjectError: {
        propertyId: objectFromPropertyState.statementProjectError?.propertyId,
        type: objectFromPropertyState.statementProjectError?.type,
        lastUpdateDt: objectFromPropertyState.statementProjectError?.lastUpdateDt,
      },
      statementNpaError: {
        propertyId: objectFromPropertyState.statementNpaError?.propertyId,
        type: objectFromPropertyState.statementNpaError?.type,
        lastUpdateDt: objectFromPropertyState.statementNpaError?.lastUpdateDt,
      },
      statementOtherError: {
        propertyId: objectFromPropertyState.statementOtherError?.propertyId,
        type: objectFromPropertyState.statementOtherError?.type,
        lastUpdateDt: objectFromPropertyState.statementOtherError?.lastUpdateDt,
      },
      statementOtherDetails: {
        propertyId: objectFromPropertyState.statementOtherDetails?.propertyId,
        type: objectFromPropertyState.statementOtherDetails?.type,
        lastUpdateDt: objectFromPropertyState.statementOtherDetails?.lastUpdateDt,
      },
      statementDaysRevision: {
        propertyId: objectFromPropertyState.statementDaysRevision?.propertyId,
        type: objectFromPropertyState.statementDaysRevision?.type,
        lastUpdateDt: objectFromPropertyState.statementDaysRevision?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNotificationOfIdentifiedViolationsForm }
