import { HttpStatusCode } from '@constants/statusCodes'
import { isAxiosError, isNumber } from '@helpers/checkTypes'
import { AxiosError } from 'axios'

export const getErrorMessage = (error: AxiosError): string => {
  return error.response?.data?.error_description || error.message
}

export const isErrorsWithBackendMessage = (errorStatus: number) => {
  const clientErrorStatuses: number[] = [
    HttpStatusCode.BAD_REQUEST,
    HttpStatusCode.FORBIDDEN,
    HttpStatusCode.NOT_FOUND,
  ]

  return clientErrorStatuses.includes(errorStatus)
}

export const isIntervalServerError = (statusCode?: number) =>
  isNumber(statusCode) && statusCode >= HttpStatusCode.INTERNAL_SERVER_ERROR

export const handleDetailFromError = (error: unknown) =>
  isAxiosError(error) && error.response?.data?.detail
