import { FC } from 'react'

import ExpensesCompensationArrayForm from '@components/Forms/CreateStatement/Forms/Compensations'
import GeneralInfoForm from '@components/Forms/CreateStatement/Forms/GeneralInfo'
import InvestmentsForm from '@components/Forms/CreateStatement/Forms/Investments'
import InvestorForm from '@components/Forms/CreateStatement/Forms/Investor'
import PlannedTax from '@components/Forms/CreateStatement/Forms/PlannedTax'
import StageBlockForm from '@components/Forms/CreateStatement/Forms/Stage'

interface CreateStatementFormsProps extends FC {
  Investor: typeof InvestorForm
  GeneralInfo: typeof GeneralInfoForm
  Investments: typeof InvestmentsForm
  ExpensesCompensationArray: typeof ExpensesCompensationArrayForm
  StageForm: typeof StageBlockForm
  PlannedTaxForm: typeof PlannedTax
}

const CreateStatementForms: CreateStatementFormsProps = () => null

CreateStatementForms.Investor = InvestorForm
CreateStatementForms.GeneralInfo = GeneralInfoForm
CreateStatementForms.Investments = InvestmentsForm
CreateStatementForms.ExpensesCompensationArray = ExpensesCompensationArrayForm
CreateStatementForms.StageForm = StageBlockForm
CreateStatementForms.PlannedTaxForm = PlannedTax

export default CreateStatementForms
