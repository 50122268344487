import { useCallback } from 'react'

import { NpaSolicitationInclusionFormValues } from '@components/Forms/NpaSolicitationInclusionForm/types'

import { generatePropertiesObjectForNpaSolicitationInclusionForm } from './PropertiesObject.adapter'
import { generateRHFObjectForNpaSolicitationInclusionForm } from './RHF.adapter'

const useNpaSolicitationInclusionFormAdapters = () => {
  const generateRHFObject = useCallback(
    (objectFromPropertyState: Record<string, any>): NpaSolicitationInclusionFormValues =>
      generateRHFObjectForNpaSolicitationInclusionForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForNpaSolicitationInclusionForm(objectFromPropertyState),
    [],
  )

  return { generateRHFObject, generatePropertiesObject }
}

export { useNpaSolicitationInclusionFormAdapters }
