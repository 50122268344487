import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NotificationOfIdentifiedViolationsFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsForm/types'

const { getDefaultUOFieldState, getDefaultInfoAboutDocument } = FieldStateHelpers

const mapOfNotificationOfIdentifiedViolationsMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченная организация' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Выявленные нарушения' },
]

const defaultNotificationOfIdentifiedViolationsFormValues: NotificationOfIdentifiedViolationsFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': getDefaultUOFieldState(),
    '3': {
      investorFullName: '',
      investorFullNameGenitive: '',
    },
    '4': {
      projectName: '',
      ruleLinkStatementReturn: '',
      statementTechError: '',
      statementSetDocumentError: '',
      statementDocumentError: '',
      ruleLinkDocumentError: '',
      statementHeadError: '',
      statementInvestorError: '',
      statementProjectCompanyError: '',
      statementProjectError: '',
      statementNpaError: '',
      statementOtherError: '',
      statementOtherDetails: '',
      statementDaysRevision: '',
    },
  }

const notificationOfIdentifiedViolationsBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    uoFullName: '2.uoFullName',
    uoHeaderName: '2.uoHeaderName',
    uoHeaderNameGenitive: '2.uoHeaderNameGenitive',
    uoHeaderPosition: '2.uoHeaderPosition',
    uoHeaderPositionGenitive: '2.uoHeaderPositionGenitive',
    uoHeaderReasonGenitive: '2.uoHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameGenitive: '3.investorFullNameGenitive',
  },
  '4': {
    projectName: '4.projectName',
    ruleLinkStatementReturn: '4.ruleLinkStatementReturn',
    statementTechError: '4.statementTechError',
    statementSetDocumentError: '4.statementSetDocumentError',
    statementDocumentError: '4.statementDocumentError',
    ruleLinkDocumentError: '4.ruleLinkDocumentError',
    statementHeadError: '4.statementHeadError',
    statementInvestorError: '4.statementInvestorError',
    statementProjectCompanyError: '4.statementProjectCompanyError',
    statementProjectError: '4.statementProjectError',
    statementNpaError: '4.statementNpaError',
    statementOtherError: '4.statementOtherError',
    statementOtherDetails: '4.statementOtherDetails',
    statementDaysRevision: '4.statementDaysRevision',
  },
} as const

export {
  defaultNotificationOfIdentifiedViolationsFormValues,
  mapOfNotificationOfIdentifiedViolationsMenu,
  notificationOfIdentifiedViolationsBlockValues,
}
