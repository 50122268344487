import React, { FC, memo, useCallback } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import ParametersOfCostRecoveryApplication from '@components/Forms/ParametersOfCostRecoveryApplicationForm'
import { mapOfParametersOfCostRecoveryApplicationMenu } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import { ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import Typography from '@components/NewDesign/Typography'

import ParametersOfCostRecoveryApplicationLayoutWrapper from './Wrapper'

const ParametersOfCostRecoveryApplicationLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  rolesOfUser,
  initialErrorsFromViolations,
  editMode,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const handleOnClose = useCallback(() => {
    ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <ParametersOfCostRecoveryApplication.Manager
        formId={formId}
        projectId={projectId}
        rolesOfUser={rolesOfUser}
        initialErrorsFromViolations={initialErrorsFromViolations}
        editMode={editMode}
        onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
        onClose={handleOnClose}
      >
        <FlatMenuManager
          initialCurrentAnchorId={mapOfParametersOfCostRecoveryApplicationMenu[0].id}
        >
          <ParametersOfCostRecoveryApplicationLayoutWrapper
            lastUpdateDraftTime={lastUpdateDraftTime}
          >
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Параметры заявления на возмещение затрат
              </Typography.Headline>
              <ParametersOfCostRecoveryApplication.StatementForm className={styles.layout__form} />
            </div>
            <div className={styles['layout__menu-wrapper']}>
              <ParametersOfCostRecoveryApplication.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </ParametersOfCostRecoveryApplicationLayoutWrapper>
        </FlatMenuManager>
      </ParametersOfCostRecoveryApplication.Manager>
    </DocumentFormLayout>
  )
}

export default memo(ParametersOfCostRecoveryApplicationLayout)
