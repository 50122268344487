import React, { FC } from 'react'
import { ReactSVG } from 'react-svg'

import AttachmentIcon from '@icons/AttachmentIcon.svg'
import doneIcon from '@icons/webForm/doneIcon.svg'
import errorIcon from '@icons/webForm/errorIcon.svg'
import formIcon from '@icons/webForm/formIcon.svg'
import noFormIcon from '@icons/webForm/noFormIcon.svg'

import { statusIconTypesMap } from './constants'
import styles from './ControlContainerStatusIcon.module.scss'

interface ControlContainerStatusIconProps {
  controlIcon?: string
  iconType?: keyof typeof statusIconTypesMap | ''
}

const statusIconMap = {
  [statusIconTypesMap.done]: doneIcon,
  [statusIconTypesMap.form]: formIcon,
  [statusIconTypesMap.noForm]: noFormIcon,
  [statusIconTypesMap.error]: errorIcon,
}

const ControlContainerStatusIcon: FC<ControlContainerStatusIconProps> = ({
  controlIcon,
  iconType = '',
}) => {
  const iconSrc = statusIconMap[iconType] ?? AttachmentIcon

  return (
    <div className={styles['controlContainerStatusIcon__icon-wrapper']}>
      <ReactSVG src={controlIcon ? controlIcon : iconSrc} />
    </div>
  )
}

export default ControlContainerStatusIcon
