import { FC, memo } from 'react'

import { RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { isUndefined } from '@helpers/checkTypes'

import WebFormBaseGovernmentController from './BaseGovernmentController'
import WebFormAddonInvestorController from './InvestorController'
import { WebFormAddonBaseProps } from './types'

const SELECTED_ROLES = [
  RolesTypes.INVESTOR,
  RolesTypes.OMSU,
  RolesTypes.MER,
  RolesTypes.FK,
  RolesTypes.OIV,
  RolesTypes.OIV_EXTRA,
  RolesTypes.ROIV,
  RolesTypes.UO,
]

const renderAddonsMap = ({ children, ...props }: WebFormAddonBaseProps) => ({
  [RolesTypes.INVESTOR]: (
    <WebFormAddonInvestorController {...props}>{children}</WebFormAddonInvestorController>
  ),
  [RolesTypes.MER]: (
    <WebFormBaseGovernmentController {...props}>{children}</WebFormBaseGovernmentController>
  ),
  [RolesTypes.OMSU]: (
    <WebFormBaseGovernmentController {...props}>{children}</WebFormBaseGovernmentController>
  ),
  [RolesTypes.FK]: (
    <WebFormBaseGovernmentController {...props}>{children}</WebFormBaseGovernmentController>
  ),
  [RolesTypes.OIV]: (
    <WebFormBaseGovernmentController {...props}>{children}</WebFormBaseGovernmentController>
  ),
  [RolesTypes.OIV_EXTRA]: (
    <WebFormBaseGovernmentController {...props}>{children}</WebFormBaseGovernmentController>
  ),
  [RolesTypes.ROIV]: (
    <WebFormBaseGovernmentController {...props}>{children}</WebFormBaseGovernmentController>
  ),
  [RolesTypes.UO]: (
    <WebFormBaseGovernmentController {...props}>{children}</WebFormBaseGovernmentController>
  ),
})

const WebFormAddonController: FC<WebFormAddonBaseProps> = ({
  children,
  projectId,
  currentDfoId,
  documentType,
  documentProps,
  permissions,
}) => {
  const { findRoleBySelectedRoles } = useAuthContext()

  const role = findRoleBySelectedRoles?.(SELECTED_ROLES)

  if (isUndefined(role)) return <>{children}</>

  return renderAddonsMap({
    permissions,
    projectId,
    currentDfoId,
    documentType,
    documentProps,
    children,
  })[role]
}

export default memo(WebFormAddonController)
