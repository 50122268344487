import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/PermissionsItem.module.scss'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import PermissionsFormItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm/PermissionsFormItem'
import { SeventhPermissionsObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldCollapseControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import cn from 'classnames'

interface PermissionsItemProps {
  id: string
  anchorId: string
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: SeventhPermissionsObjectsArrayPathName
  indexOfBlock: number
  indexOfPermission: number

  onDeleteObject: VoidFunction
}

const PermissionsItem: FC<PermissionsItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formInstance,
  indexOfBlock,
  indexOfPermission,
  formName,
  onDeleteObject,
}) => {
  const {
    isExpanded: isShowPermissionItemContent,
    onToggleCollapse: toggleShowPermissionItemContent,
  } = useFormCollapseControl({
    initialExpanded: true,
    name: formName,
    watcher: ProjectSZPKFieldCollapseControlUpdateWatcher,
  })

  return (
    <ObjectStepWrapper
      disableBorder
      disableHeaderPaddingLeft
      stepId={id}
      id={anchorId}
      headerNode={
        <ObjectStepWrapperHeaderNode
          buttonProps={{
            onClick: toggleShowPermissionItemContent,
            trailingIcon: {
              className: cn({
                [styles['permissionsItem__header-button__icon--active']]:
                  isShowPermissionItemContent,
              }),
            },
          }}
        >
          РАЗРЕШЕНИЕ №{indexOfPermission + 1}
        </ObjectStepWrapperHeaderNode>
      }
      onRemoveObjectStep={editMode ? onDeleteObject : undefined}
    >
      <Row>
        <Col xs={12}>
          <Stack direction="vertical" gap={3}>
            <Row>
              <PermissionsFormItem
                isShowPermissionItemContent={isShowPermissionItemContent}
                blockViewIsValidating={blockViewIsValidating}
                editMode={editMode}
                formInstance={formInstance}
                formName={formName}
                indexOfBlock={indexOfBlock}
              />
            </Row>
            <Row>
              <Col xs={12}>
                <Border />
              </Col>
            </Row>
          </Stack>
        </Col>
      </Row>
    </ObjectStepWrapper>
  )
}

export default PermissionsItem
