import { folderItemReducerActionMap } from '@components/Attachments/Attachment/FolderItem/constants'
import { FolderItemService } from '@components/Attachments/Attachment/FolderItem/helpers'
import {
  FolderFileItem,
  FolderItemAction,
  FolderItemReducerActionCreators,
  FolderItemReducerState,
} from '@components/Attachments/Attachment/FolderItem/types'
import { defaultMessage } from '@constants/texts'
import { isAxiosError } from '@helpers/checkTypes'

const { getFileIndexById } = FolderItemService

const initialFolderItemState: FolderItemReducerState = {
  files: null,
  folderName: 'Новая папка',
  isFold: false,
}

const folderItemReducer = (
  state: FolderItemReducerState,
  action: FolderItemAction,
): FolderItemReducerState => {
  const { type, payload } = action

  switch (type) {
    case folderItemReducerActionMap.SETUP_FILE_LOADING:
      if (!state.files) return state

      const indexOfFileToSetupLoading = getFileIndexById(payload.id, state.files)
      const fileToReplace = state.files[indexOfFileToSetupLoading]

      const fileWithLoading: FolderFileItem = {
        id: fileToReplace.id,
        fileState: {
          ...fileToReplace.fileState,
          isLoading: payload.isLoading,
        },
      }

      return {
        ...state,
        files: [
          ...state.files.slice(0, indexOfFileToSetupLoading),
          fileWithLoading,
          ...state.files.slice(indexOfFileToSetupLoading + 1, state.files.length),
        ],
      }

    case folderItemReducerActionMap.SETUP_FOLD_STATE:
      return {
        ...state,
        isFold: payload,
      }

    case folderItemReducerActionMap.SETUP_FOLDER_NAME:
      return {
        ...state,
        folderName: payload,
      }

    case folderItemReducerActionMap.SETUP_FILE_ERROR:
      if (!state.files) return state

      const indexOfFileToSetupError = getFileIndexById(payload.id, state.files)
      const replacedFile = state.files[indexOfFileToSetupError]

      const errorMessage: string = !isAxiosError(payload.error)
        ? defaultMessage
        : payload.error.response?.data?.detail || defaultMessage

      const fileWithError: FolderFileItem = {
        id: replacedFile.id,
        fileState: {
          ...replacedFile.fileState,
          isLoading: false,
          errorMessage,
        },
      }

      return {
        ...state,
        files: [
          ...state.files.slice(0, indexOfFileToSetupError),
          fileWithError,
          ...state.files.slice(indexOfFileToSetupError + 1, state.files.length),
        ],
      }
    case folderItemReducerActionMap.ADD_FILE:
      return {
        ...state,
        files: [...(state.files || []), payload],
      }

    case folderItemReducerActionMap.INSERT_FILE:
      if (!state.files) return state

      return {
        ...state,
        files: [
          ...state.files?.slice(0, payload.indexOfInsert),
          payload.file,
          ...state.files?.slice(payload.indexOfInsert),
        ],
      }

    case folderItemReducerActionMap.REPLACE_FILE:
      if (!state.files) return state

      const indexOfFileToReplace = getFileIndexById(payload.id, state.files)
      const fileReplaced = state.files[indexOfFileToReplace]

      const fileWithNewFileState: FolderFileItem = {
        id: fileReplaced.id,
        fileState: payload.fileState,
      }

      return {
        ...state,
        files: [
          ...state.files.slice(0, indexOfFileToReplace),
          fileWithNewFileState,
          ...state.files.slice(indexOfFileToReplace + 1, state.files.length),
        ],
      }

    case folderItemReducerActionMap.REMOVE_FILE:
      if (!state.files) return state
      return {
        ...state,
        files: [...state.files.filter((folderFileItem) => folderFileItem.id !== payload)],
      }

    default:
      return state
  }
}

const folderItemCreators: FolderItemReducerActionCreators = {
  setFileLoading: (payload) => ({ type: folderItemReducerActionMap.SETUP_FILE_LOADING, payload }),
  setFileError: (payload) => ({ type: folderItemReducerActionMap.SETUP_FILE_ERROR, payload }),
  setFolded: (payload) => ({ type: folderItemReducerActionMap.SETUP_FOLD_STATE, payload }),
  setFolderName: (payload) => ({
    type: folderItemReducerActionMap.SETUP_FOLDER_NAME,
    payload,
  }),
  addFile: (payload) => ({
    type: folderItemReducerActionMap.ADD_FILE,
    payload,
  }),
  insertFile: (payload) => ({
    type: folderItemReducerActionMap.INSERT_FILE,
    payload,
  }),
  replaceFile: (payload) => ({
    type: folderItemReducerActionMap.REPLACE_FILE,
    payload,
  }),
  removeFile: (payload) => ({
    type: folderItemReducerActionMap.REMOVE_FILE,
    payload,
  }),
}

export { folderItemCreators, folderItemReducer, initialFolderItemState }
