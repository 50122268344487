import { UseFormReturn } from 'react-hook-form'

import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { IEventOfStage, ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  getAmountValueFromStr,
  getAmountValueFromStrWithoutRound,
} from '@components/NewDesign/AmountInput/utils'
import {
  defaultRHFValidation,
  lengthValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const clearStepCompensationValueErrors = (form: UseFormReturn<ProjectSZPKFormValues>) => {
  const stages = form.getValues(projectSZPKBlockValues['6'].stages)

  if (!stages || !stages.length) return

  stages.forEach((_stage, index) => {
    const stepCompensationValue = form.getValues(
      `${projectSZPKBlockValues['6'].stages}.${index}.stepCompensationValue`,
    )
    const numberStepCompensationValue = getAmountValueFromStrWithoutRound(stepCompensationValue)

    if (!!numberStepCompensationValue) {
      form.clearErrors(`${projectSZPKBlockValues['6'].stages}.${index}.stepCompensationValue`)
    }
  })
}

const fifthSectionValidationMap: FormValuesValidationSection<
  keyof ProjectSZPKFormValues['5'],
  ProjectSZPKFormValues
> = {
  economicSphere: {
    required: defaultRHFValidation.required,
  },
  otherEconomicSphere: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  projectPurpose: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  projectStartDate: {
    required: defaultRHFValidation.required,
  },
  projectDecisionDepartmentGenitive: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },

  totalCapital: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },

  madeInvestorCapital: ({ form }) => ({
    validate: {
      positiveAmountValidate: (value) => {
        if (!isString(value)) return

        return standardAmountValidate(value)
      },

      positiveInvestorCapital: (value) => {
        if (!isString(value)) return true

        const investorCapital = form.getValues(projectSZPKBlockValues['5'].investorCapital)

        const madeInvestorCapital = getAmountValueFromStr(value, 100)
        const preparedInvestorCapital =
          investorCapital && getAmountValueFromStr(investorCapital, 100)

        if (!madeInvestorCapital || !preparedInvestorCapital) return true

        return (
          madeInvestorCapital <= preparedInvestorCapital ||
          'объем осуществленных капиталовложений должен быть меньше объема капиталовложений по проекту'
        )
      },
    },
  }),
  madeInvestorCapitalDate: ({ form }) => ({
    required: defaultRHFValidation.required,
    onBlur: async () => {
      const eventsOfStagesName = projectSZPKBlockValues['7'].eventsOfStages

      const eventsOfStages = form.getValues(eventsOfStagesName) as IEventOfStage[] | undefined

      if (!eventsOfStages || !eventsOfStages.length) return

      eventsOfStages.forEach((stage, index) => {
        form.trigger(
          `${eventsOfStagesName}.${index}.constructionStage.registration.objectStateRegistrationEndDate`,
        )
      })
    },
  }),

  projectSupportCompensation: ({ form }) => ({
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
    onBlur: () => {
      const projectRelatedCompensation = form.getValues(
        projectSZPKBlockValues['5'].projectRelatedCompensation,
      )
      const numberProjectRelatedCompensation = getAmountValueFromStrWithoutRound(
        projectRelatedCompensation,
      )

      if (numberProjectRelatedCompensation) {
        form.clearErrors(projectSZPKBlockValues['5'].projectRelatedCompensation)
      }

      clearStepCompensationValueErrors(form)
    },
  }),
  projectRelatedCompensation: ({ form }) => ({
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
    onBlur: () => {
      const projectSupportCompensation = form.getValues(
        projectSZPKBlockValues['5'].projectSupportCompensation,
      )
      const numberProjectSupportCompensation = getAmountValueFromStrWithoutRound(
        projectSupportCompensation,
      )

      if (numberProjectSupportCompensation) {
        form.clearErrors(projectSZPKBlockValues['5'].projectSupportCompensation)
      }

      clearStepCompensationValueErrors(form)
    },
  }),
}

export { fifthSectionValidationMap }
