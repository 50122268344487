import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import cn from 'classnames'

import styles from '../Container.module.scss'

export type ProjectsContainerProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const ProjectsContainer: FC<ProjectsContainerProps> = ({ children, ...rest }) => {
  return (
    <div {...rest} className={cn(styles.container, rest.className)}>
      {children}
    </div>
  )
}

export default ProjectsContainer
