import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'

import { mapOfSummaryReportOfMonitoringResultsMenu } from '../const'
import SummaryReportOfMonitoringResultsForms from '../Forms'

const mapOfSummaryReportOfMonitoringResultsForms: FormItemProps = {
  '1': {
    node: <SummaryReportOfMonitoringResultsForms.First />,
  },
  '2': {
    node: <SummaryReportOfMonitoringResultsForms.Second />,
  },
  '3': {
    node: <SummaryReportOfMonitoringResultsForms.Third />,
  },
  '4': {
    node: <SummaryReportOfMonitoringResultsForms.Fourth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '5': {
    node: <SummaryReportOfMonitoringResultsForms.Fifth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '6': {
    node: <SummaryReportOfMonitoringResultsForms.Sixth />,
  },
} as const

const SummaryReportOfMonitoringResultsForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<SummaryReportOfMonitoringResultsFormValues>()

  const {
    state: { formIsLoading },
  } = useSummaryReportOfMonitoringResultsManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfSummaryReportOfMonitoringResultsMenu}
      mapOfForms={mapOfSummaryReportOfMonitoringResultsForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(SummaryReportOfMonitoringResultsForm)
