import React, {
  createContext,
  Dispatch,
  FC,
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { UseFormReturn } from 'react-hook-form'

import { IViolation } from '@components/DocumentFormComponents/types'
import { OptionProps } from '@components/NewDesign/Select/model'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import { TFiltersModalFormValues } from '@components/RegistryNpa/Header/Filters'
import { useNpaFiltersOld } from '@components/RegistryNpa/hooks/useNpaFiltersOld'
import { IFiltersFormValues } from '@components/RegistryNpa/Manager'
import { useNpaSelectValues } from '@components/Sidebars/NPA/hooks/useNpaSelectValues'
import { errorModalBodyTexts, errorModalButtonTexts, errorModalHeaderTexts } from '@constants/texts'
import { DocumentsType } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { TGetModalListNpaResponse } from '@context/APIContext/hooks/useNpaApi'
import { isAxiosError, isFunction, isNullOrUndefined } from '@helpers/checkTypes'
import { useNpaModalList } from '@hooks/new/swr/useNpaModalList'
import type { ComponentInfo } from '@services/LoggerService/LoggerService.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { TNpaDraftPartForBody } from '@services/NPA/NPA.entity'
import { AxiosError } from 'axios'
import { RegistryNpaChooseComponentProps } from 'components/RegistryNpa/ChooseOld'
import { KeyedMutator } from 'swr'

import {
  initialNpaModalOldState,
  npaNormalizedActionOldCreators,
  npaNormalizedOldReducer,
} from './reducer'
import type {
  ChosenPart,
  HandleChangeMasterNpaItemCollapseStates,
  NPAModalModuleOldState,
  NPAModalReducerOldAction,
} from './types'

export type ViewModalType = 'chosen' | 'default'

export interface NpaModalOldContextProps {
  state: {
    filtersInstance: UseFormReturn<IFiltersFormValues> | null

    gridSwrInstance: {
      isNpaListLoading: boolean
      mutate: KeyedMutator<TGetModalListNpaResponse>
      npaList?: TGetModalListNpaResponse
      error?: AxiosError<unknown, unknown>
    } | null

    forSelect: {
      typeOptions: OptionProps[]
      directionOptions: OptionProps[]
      regionOptions: OptionProps[]
    }

    npaReducer: [NPAModalModuleOldState, Dispatch<NPAModalReducerOldAction>]

    debouncedSearchValue: string

    viewMode: ViewModalType

    masterNpaCollapseStatesMap: RefObject<Record<string, boolean> | null> | null
  }
  handlers: {
    handleApplyFilters?: (filters: TFiltersModalFormValues) => void
    handleChangeViewMode?: (newMode: ViewModalType) => void
    handleChangeMasterNpaCollapseStates?: HandleChangeMasterNpaItemCollapseStates
    resetMasterNpaCollapseStates?: VoidFunction
  }
  actions: {
    handlePutNpaDraft?: VoidFunction
  }
}

const ChooseNpaModalOldContext = createContext<NpaModalOldContextProps>({
  state: {
    gridSwrInstance: null,
    filtersInstance: null,

    forSelect: {
      directionOptions: [],
      typeOptions: [],
      regionOptions: [],
    },

    viewMode: 'default',

    npaReducer: [initialNpaModalOldState, () => null],

    debouncedSearchValue: '',

    masterNpaCollapseStatesMap: null,
  },
  handlers: {},
  actions: {},
})

interface ChooseNpaModalManagerOldProps extends RegistryNpaChooseComponentProps {
  children: ReactNode
  onClose?: VoidFunction
}

const useChooseNpaManagerOld = () => {
  return useContext(ChooseNpaModalOldContext)
}

const REGEXP_TO_EJECT_VALUE = /\[([^\]]+)\]/

const ChooseModalManagerOld: FC<ChooseNpaModalManagerOldProps> = ({
  chosenParts,
  initialFilters,
  excludedPartIds,
  dfoId,
  projectId,
  documentSetId,
  onUpdateNpaLayout,
  onClose,
  children,
}) => {
  const [state, dispatch] = useReducer(npaNormalizedOldReducer, initialNpaModalOldState)
  const [viewMode, setViewMode] = useState<ViewModalType>('default')
  const masterNpaCollapseStatesMap = useRef<Record<string, boolean> | null>(null)

  const {
    draftApi: { putDraft },
  } = useAPIContext()

  const { preparedTypesForSelect, preparedRegionsForSelect, preparedDirectionsForSelect } =
    useNpaSelectValues()

  const {
    filtersInstance,
    currentFilters,
    currentSearchString,
    preparedKeyToSwr,
    handleApplyFilters,
    debouncedSearchValue,
  } = useNpaFiltersOld({
    keyMutator: {
      excludedPartIds: excludedPartIds?.length ? excludedPartIds : undefined,
      _key: 'npasModalList',
    },
    initialFilters,
  })

  const gridSwrInstance = useNpaModalList({
    key: preparedKeyToSwr,
    config: {
      isPaused: () => viewMode === 'chosen',
      onError: LoggerHelpersService.handleMultipleLogError({
        additionInfo: {
          searchString: currentSearchString,
          filters: currentFilters,
        },
        componentInfo: {
          componentName: 'ChooseModalManager',
          moduleName: 'RegistryNPAChoose',
          componentType: 'manager',
        },
      }),
    },
  })

  const { handleOpenErrorModal } = useErrorModal()

  const handleChangeMasterNpaCollapseStates = useCallback(
    (masterNpaItemId: string, isOpenMasterNpaItem: ((prev: boolean) => boolean) | boolean) => {
      const prevState = masterNpaCollapseStatesMap.current?.[masterNpaItemId]

      const currentMasterNpaItemState = isFunction(isOpenMasterNpaItem)
        ? isOpenMasterNpaItem(prevState)
        : isOpenMasterNpaItem

      masterNpaCollapseStatesMap.current = {
        ...masterNpaCollapseStatesMap.current,
        [masterNpaItemId]: currentMasterNpaItemState,
      }
    },
    [],
  )

  const resetMasterNpaCollapseStates = useCallback(() => {
    masterNpaCollapseStatesMap.current = null
  }, [])

  useEffect(() => {
    if (!gridSwrInstance?.npaList) return

    //Нормализация должна сработать только при первом открытии
    if (filtersInstance.getValues().filters.direction === '' && !state.normalizedData.parts.length)
      dispatch(npaNormalizedActionOldCreators.setNormalizeData(gridSwrInstance.npaList))

    dispatch(npaNormalizedActionOldCreators.setThreeToRender(gridSwrInstance.npaList))
  }, [gridSwrInstance?.npaList])

  const handleChangeViewMode = (viewMode: ViewModalType) => {
    setViewMode(viewMode)

    resetMasterNpaCollapseStates()
  }

  const handleRejectedPutNpaWithViolations = (violations?: IViolation[]) => () => {
    if (!violations) return handleChangeViewMode('chosen')

    const preparedViolations = violations.reduce((previousValue, currentValue) => {
      const [nameOfError, fieldError] = currentValue.field.split('.')

      const indexOfChosenPart = nameOfError.match(REGEXP_TO_EJECT_VALUE)?.[1]

      if (isNullOrUndefined(indexOfChosenPart) || !fieldError) return previousValue

      const preparedIndexOfChosenPart = parseInt(indexOfChosenPart) - (excludedPartIds?.length || 0)

      // Неявно преобразуется в any
      const partOfGroup = state.chosenParts[preparedIndexOfChosenPart] as ChosenPart | undefined

      if (!partOfGroup) return previousValue

      const [directionLvlMapKey, npaLvlMapKey, partLvlMapKey] = [
        partOfGroup.parentDirection,
        partOfGroup.npaId,
        partOfGroup.partId,
      ]

      const foundPartsOfDirectionKey = previousValue.get(directionLvlMapKey)

      // Если нет direction, значит все вложенные структуры пустые
      if (!foundPartsOfDirectionKey) {
        previousValue.set(directionLvlMapKey, {
          [npaLvlMapKey]: {
            [partLvlMapKey]: { [fieldError]: currentValue.message },
          },
        })

        return previousValue
      }

      const foundPartsOfNpaKey = foundPartsOfDirectionKey?.[npaLvlMapKey]

      if (!foundPartsOfNpaKey) {
        previousValue.set(directionLvlMapKey, {
          ...previousValue.get(directionLvlMapKey),
          [npaLvlMapKey]: {
            [partLvlMapKey]: { [fieldError]: currentValue.message },
          },
        })
        return previousValue
      }

      previousValue.set(directionLvlMapKey, {
        ...previousValue.get(directionLvlMapKey),
        [npaLvlMapKey]: {
          ...previousValue.get(directionLvlMapKey)[npaLvlMapKey],
          [partLvlMapKey]: { [fieldError]: currentValue.message },
        },
      })

      return previousValue
    }, new Map())

    // Заполняем state c приготовленными ошибками с violations
    if (preparedViolations.size) {
      dispatch(npaNormalizedActionOldCreators.setChosenPartsError(preparedViolations))
    }

    handleChangeViewMode('chosen')
  }

  const handlePutNpaDraft = async () => {
    const preparedChosen = [...(chosenParts ? chosenParts : []), ...state.chosenParts].map(
      (chosenPart) => ({
        npaId: chosenPart.npaId,
        partId: chosenPart.partId,
        justification: chosenPart.justification,
        partCorrection: chosenPart.partCorrection,
      }),
    ) as TNpaDraftPartForBody[]

    dispatch(npaNormalizedActionOldCreators.setRequestLoading())

    try {
      await putDraft({
        documentType: DocumentsType.NPA_LIST,
        dfoId,
        projectId,
        documentSetId,
        body: {
          npaParts: preparedChosen,
        },
      })

      await onUpdateNpaLayout()

      onClose?.()
    } catch (error) {
      const additionInfo = {
        documentSetId,
        documentType: DocumentsType.NPA_LIST,
        body: {
          npaParts: preparedChosen,
        },
      }
      const componentInfo: ComponentInfo = {
        componentName: 'ChooseModalManager',
        moduleName: 'RegistryNPAChoose',
        componentType: 'updateNPADraft',
      }

      if (!isAxiosError(error)) {
        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo,
        })(error)

        throw error
      }

      switch (error.response?.status) {
        case 400:
          return handleOpenErrorModal({
            headerText: errorModalHeaderTexts.fillError,
            bodyText: errorModalBodyTexts.requiredFieldsFilled,
            customActions: {
              actions: [
                {
                  dataTestId: 'ErrorModal-returnToFilling-button',
                  fixWidth: true,
                  children: errorModalButtonTexts.returnToFilling,
                  onClick: handleRejectedPutNpaWithViolations(error.response?.data.violations),
                  bindOnClose: true,
                },
              ],
              mergeDefault: false,
            },
          })

        default:
          LoggerHelpersService.handleMultipleLogError({
            additionInfo,
            componentInfo,
          })(error)

          return handleOpenErrorModal({
            headerText: errorModalHeaderTexts.defaultMessage,
            bodyText: error.response?.data.detail || errorModalBodyTexts.defaultMessage,
          })
      }
    } finally {
      dispatch(npaNormalizedActionOldCreators.setRequestLoading())
    }
  }

  const providerValue: NpaModalOldContextProps = useMemo(
    () => ({
      state: {
        viewMode,
        gridSwrInstance,
        filtersInstance,
        forSelect: {
          directionOptions: preparedDirectionsForSelect,
          typeOptions: preparedTypesForSelect,
          regionOptions: preparedRegionsForSelect,
        },
        debouncedSearchValue,
        npaReducer: [state, dispatch],
        masterNpaCollapseStatesMap,
      },
      handlers: {
        handleChangeViewMode,
        handleApplyFilters,
        handleChangeMasterNpaCollapseStates,
        resetMasterNpaCollapseStates,
      },
      actions: {
        handlePutNpaDraft,
      },
    }),
    [
      masterNpaCollapseStatesMap,
      debouncedSearchValue,
      filtersInstance,
      gridSwrInstance,
      preparedDirectionsForSelect,
      preparedRegionsForSelect,
      preparedTypesForSelect,
      state,
      viewMode,
    ],
  )

  return (
    <ChooseNpaModalOldContext.Provider value={providerValue}>
      {children}
    </ChooseNpaModalOldContext.Provider>
  )
}

export { useChooseNpaManagerOld }
export default memo(ChooseModalManagerOld)
