import React, { DetailedHTMLProps, FC, MutableRefObject, useCallback, useMemo, useRef } from 'react'

import Icon from '@components/Icon'
import Avatar from '@components/NewDesign/Avatar'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import TextWithTooltip from '@components/NewDesign/TextWithTooltip'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import { useChangeUserEmailModal } from '@components/NewDesignedModals/ChangeUserEmailModal'
import { useMainMenuSidebar } from '@components/Sidebars/MainHeaderMenu'
import { Roles, UserProfiles } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import { findOne } from '@helpers/commonHelpers'
import { getAllPartsOfName } from '@helpers/name/getAllPartsOfName'
import { useHover } from '@hooks/new/events/useHover'
import useAuthUser from '@hooks/useAuthUser'
import BurgerIcon from '@icons/BurgerIcon.svg'
import ChevronSmallIcon from '@icons/ChevronSmallIcon.svg'
import exitIcon from '@icons/Sidebar/exit.svg'
import pencilIcon from '@icons/Sidebar/pencilIcon.svg'
import HeaderNotifications from '@layouts/Header/HeaderNotifications'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { useProjectsPageStateManager } from '@services/ProjectsState/ProjectsState.manager'
import cn from 'classnames'
import { useSWRConfig } from 'swr'

import styles from './Header.module.scss'

export interface HeaderLayoutProps {
  headerContentContainer?: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  headerContentRef?: MutableRefObject<HTMLDivElement | null>
}

const MAX_LENGTH_EMAIL = 16

const HeaderLayout: FC<HeaderLayoutProps> = ({
  headerContentContainer,
  headerContentRef,
  children,
}) => {
  const { cache } = useSWRConfig()

  const { userInfoState, getUserRole, resetAuthState, logLogoutOfUser, getUserIOandSurname } =
    useAuthContext()

  const { currentUserFullInfo, email, isEmailVerified } = useAuthUser()

  const { handleOpenMainMenuSidebar } = useMainMenuSidebar()
  const { handleOpenChangeUserEmail } = useChangeUserEmailModal()

  const { clearProjectsPageState } = useProjectsPageStateManager()

  const {
    state: { tooltipIsOpen },
    handlers: { handleCloseTooltip, handleOpenTooltip, withCloseTooltip },
  } = useManualTooltipControl()

  const [buttonHoverRef, isButtonHovered] = useHover<HTMLButtonElement>()

  const headerRef = useRef<HTMLElement>(null)

  const partNameObject = getAllPartsOfName(userInfoState?.name)

  const { roles } = getUserRole?.() || {}
  const nameInfo = getUserIOandSurname?.()

  const onLogout = useCallback(async () => {
    try {
      cache.clear()
      clearProjectsPageState()

      await logLogoutOfUser?.()

      resetAuthState?.()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'HeaderLayout',
          componentType: 'onLogout',
        },
      })(error)
    }
  }, [cache, logLogoutOfUser, resetAuthState])

  const handleChangeUserEmail = useCallback(() => {
    const currentRole = roles.find(
      (role) => role === UserProfiles.HEADER || role === UserProfiles.EMPLOYEE,
    )

    handleOpenChangeUserEmail({
      userInfo: {
        firstName: nameInfo?.IO || '',
        lastName: nameInfo?.surname || '',
        profile: currentRole,
        id: currentUserFullInfo?.id || '',
      },
      initialEmail: email || '',
    })
  }, [
    currentUserFullInfo,
    email,
    handleOpenChangeUserEmail,
    nameInfo?.IO,
    nameInfo?.surname,
    roles,
  ])

  const userTooltipActions = useMemo(
    () => (
      <div className={styles.contextMenu__actions}>
        {email && isEmailVerified && (
          <Button
            size={'s'}
            view={'plain'}
            geometry={'square'}
            color={'default'}
            dataTestId="HeaderLayout-changeUserEmail-button"
            trailingIcon={{ src: pencilIcon, className: styles.icon_pencil }}
            className={styles.contextMenu__actions_button}
            onClick={withCloseTooltip(handleChangeUserEmail)}
          >
            <TextWithTooltip
              variant={'bodyMMedium'}
              value={email ?? ''}
              limit={MAX_LENGTH_EMAIL}
              className={styles['contextMenu__content-email']}
              tooltipProps={{
                position: 'left',
              }}
              tooltipTypographyProps={{
                variant: 'bodyMMedium',
              }}
            />
          </Button>
        )}
        <Button
          className={styles.contextMenu__actions_button}
          size={'s'}
          view={'plain'}
          dataTestId="HeaderLayout-logoutUser-button"
          geometry={'square'}
          color={'negative'}
          trailingIcon={{ src: exitIcon, className: styles.icon_logout }}
          onClick={withCloseTooltip(onLogout)}
        >
          <Typography.Body variant={'bodyMMedium'} color={'accent-error-normal'}>
            Выйти из аккаунта
          </Typography.Body>
        </Button>
      </div>
    ),
    [handleChangeUserEmail, isEmailVerified, onLogout, userInfoState?.email],
  )

  return (
    <>
      <header className={styles.header} ref={headerRef}>
        <IconButton
          view={'basic'}
          geometry={'round'}
          size={'xl'}
          dataTestId="HeaderLayout-openMainMenuSidebar-button"
          icon={{
            src: BurgerIcon,
            noCurrentColorSvgFill: true,
          }}
          onClick={handleOpenMainMenuSidebar}
        />
        <div id={'headerPortal'} className={styles.header__portal} />
        <Tooltip
          open={tooltipIsOpen}
          content={userTooltipActions}
          popoverClassName={styles.contextMenu__popover}
          contentClassName={styles.contextMenu__content}
          targetClassName={styles.contextMenu__target}
          trigger={'click'}
          position={'bottom'}
          arrowClassName={styles.contextMenu__arrow}
          onClose={handleCloseTooltip}
        >
          <button
            type="button"
            ref={buttonHoverRef}
            data-testid="HeaderLayout-userTooltip-button"
            className={cn(styles['header__user-panel'], {
              [styles['header__user-panel--opened']]: isButtonHovered || tooltipIsOpen,
            })}
            onClick={handleOpenTooltip}
          >
            <div className={styles.header__user}>
              <Avatar.Medium variant={'headlineH4'} size={'m'}>
                {`${partNameObject?.name?.[0]}${
                  partNameObject?.surname?.[0] || partNameObject?.patronymic?.[0]
                }`}
              </Avatar.Medium>
              <div className={styles['header__user-info']}>
                <span className={styles['header__user-name']}>{`${partNameObject?.name} ${
                  partNameObject?.surname || partNameObject?.patronymic
                }`}</span>
                {roles && (
                  <span className={styles['header__user-subtitle']}>
                    {findOne(roles, Roles.Header) ? 'c правом подписи' : 'без права подписи'}
                  </span>
                )}
              </div>
              <Icon
                noCurrentColorSvgFill
                src={ChevronSmallIcon}
                size={'initial'}
                className={cn(styles.contextMenu__chevron, {
                  [styles['contextMenu__chevron--toggled']]: tooltipIsOpen,
                })}
              />
            </div>
          </button>
        </Tooltip>
        <HeaderNotifications headerRef={headerRef} />
      </header>
      <div
        {...headerContentContainer}
        id={'headerLayout'}
        ref={headerContentRef}
        className={cn(styles.content, headerContentContainer?.className)}
      >
        {children}
      </div>
    </>
  )
}

export default HeaderLayout
