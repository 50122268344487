import React, { FC, memo, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementWithInitialMenuProps } from '@components/DocumentFormComponents/types'
import { useAATransferRightsAndObligationsManager } from '@components/Forms/AATransferRightsAndObligations/Manager'
import { AATransferRightsAndObligationsFormValues } from '@components/Forms/AATransferRightsAndObligations/types'

import {
  aATransferRightsAndObligationsBlockValues,
  mapOfAATransferRightsAndObligationsMenu,
} from '../const'
import AATransferRightsAndObligationsForms from '../Forms'

const mapOfAATransferRightsAndObligationsForms: FormItemProps = {
  '1': {
    node: <AATransferRightsAndObligationsForms.First />,
  },
  '2': {
    node: <AATransferRightsAndObligationsForms.Second />,
  },
  '3': {
    node: <AATransferRightsAndObligationsForms.Third />,
  },
  '4': {
    node: <AATransferRightsAndObligationsForms.Fourth />,
  },
  '5': {
    node: <AATransferRightsAndObligationsForms.Fifth />,
  },
} as const

const AATransferRightsAndObligationsForm: FC<FormModalStatementWithInitialMenuProps> = ({
  initialMenu,
  ...rest
}) => {
  const formInstance = useFormContext<AATransferRightsAndObligationsFormValues>()

  const {
    state: { formIsLoading },
  } = useAATransferRightsAndObligationsManager()

  const isFederalSZPK = useWatch({
    name: aATransferRightsAndObligationsBlockValues.additionalFields.isFederal,
    control: formInstance.control,
  })
  const isMunicipalitiesRender = !!useWatch({
    name: aATransferRightsAndObligationsBlockValues.additionalFields.initialMunicipalities,
    control: formInstance.control,
  })?.length

  const currentForms = useMemo(() => {
    const readyForms = { ...mapOfAATransferRightsAndObligationsForms }

    if (!isFederalSZPK) {
      delete readyForms['1']
    }

    if (!isMunicipalitiesRender) {
      delete readyForms['3']
    }

    return readyForms
  }, [isFederalSZPK, isMunicipalitiesRender])

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      isAutoGeneratedForm
      mapOfMenu={initialMenu ?? mapOfAATransferRightsAndObligationsMenu}
      mapOfForms={currentForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(AATransferRightsAndObligationsForm)
