import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'

import Collapse from '@components/Collapse'
import DocumentsSet from '@components/DocumentsSet'
import {
  isOtherDonationsWillBeCreate,
  isSubsidyStatementWillBeCreate,
} from '@components/DocumentsSet/helpers'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import OtherDonationsAddon from '@components/Projects/[id]/DocumentLayout/Navigation/Group/Addons/OtherDonationsAddon'
import SubsidyAddon from '@components/Projects/[id]/DocumentLayout/Navigation/Group/Addons/SubsidyAddon'
import { NewDfosType } from '@constants/types'
import { isArray } from '@helpers/checkTypes'
import { ValueOf } from '@helpers/ValueOf'
import { useAvailableConfigsByRole } from '@hooks/new/configs/useAvailableDfoConfigsByRole'
import { useProject } from '@hooks/new/swr/useProject'
import { useVersions } from '@hooks/new/swr/useVersions'
import { useBooleanState } from '@hooks/useBooleanState'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import DeadlineService from '@services/Deadline/Deadline.service'
import { IDfoListItem, IDfoNewList } from '@services/Dfo/Dfo.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { GroupType } from '@services/Projects/Project.entity'
import cn from 'classnames'

import styles from './Group.module.scss'

type TDocumentContentGroup = {
  currentDfoId: string

  group: IDfoNewList
  onDfoChange?: (dfo: IDfoListItem) => void
  isSubgroup?: boolean
  dfoAvailableForCreate?: string[]
  createdDfoCategories?: string[]
  onlyDocumentsWithoutGroup?: boolean
  dataTestId?: string
  iconButtonDataTestId?: string
}

const { getDefaultFormattedDate } = DeadlineService

const DocumentContentGroup: FC<TDocumentContentGroup> = ({
  currentDfoId,
  dfoAvailableForCreate,
  createdDfoCategories,
  group,
  isSubgroup,
  onlyDocumentsWithoutGroup,
  dataTestId,
  iconButtonDataTestId,
  onDfoChange,
}) => {
  const { projectId } = useParams()

  const { project } = useProject({
    key: { projectId, _key: 'project' },
    config: {
      isPaused: () => !projectId,
    },
  })

  const { versions } = useVersions({
    key: { projectId, dfoId: currentDfoId ?? '', _key: 'versions' },
    config: {
      isPaused: () => !projectId || !currentDfoId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'DocumentContentGroup',
        },
      }),
    },
  })

  const otherDonationsRenderCondition = useMemo(
    () =>
      dfoAvailableForCreate?.length &&
      isOtherDonationsWillBeCreate(dfoAvailableForCreate) &&
      !createdDfoCategories?.includes(NewDfosType.OTHER_DONATIONS),
    [createdDfoCategories, dfoAvailableForCreate],
  )

  const { booleanState: groupIsUnfold, setBooleanState: setGroupFold } = useBooleanState()

  const { getConfigForDfo } = useAvailableConfigsByRole()

  const handleChooseDfo = useCallback(
    (dfo: IDfoListItem) => () => {
      onDfoChange?.(dfo)
    },
    [onDfoChange],
  )

  const hasVersionDfo = versions?.some((version) => version.id === currentDfoId)

  const handleFindDfoById = useCallback(
    (dfo: IDfoListItem) =>
      hasVersionDfo ? versions?.some((version) => version.id === dfo.id) : dfo.id === currentDfoId,
    [currentDfoId, hasVersionDfo, versions],
  )

  const dfoByIdFromUrl = useMemo(
    () =>
      group.dfos?.find((item) => handleFindDfoById(item)) ||
      group.subgroups
        ?.map((item) => item.dfos?.find((dfo) => handleFindDfoById(dfo)))
        .filter(Boolean),
    [group, handleFindDfoById],
  )

  const isExist = isArray(dfoByIdFromUrl) ? !!dfoByIdFromUrl.length : !!dfoByIdFromUrl

  const hasDfoFromURLAndShouldBeToggled = !groupIsUnfold && !!currentDfoId && isExist

  const handleFoldUnfold = useCallback(() => {
    setGroupFold((prev) => !prev)
  }, [setGroupFold])

  //TODO: Неправильные зависимости
  useEffect(() => {
    if (hasDfoFromURLAndShouldBeToggled) {
      handleFoldUnfold()
    }
  }, [currentDfoId, dfoByIdFromUrl, handleFoldUnfold])

  const documentWrapper = (dfos: IDfoListItem[], groupType?: ValueOf<typeof GroupType>) => (
    <DocumentsSet.Wrapper>
      {dfos?.map((dfo) => {
        const params = getConfigForDfo({
          dfo: {
            stage: dfo.stage,
            actionAllowed: dfo.actionAllowed,
            type: dfo.type,
            version: dfo.version,
          },
          isFederal: !!project?.isFederal,
        })

        const isDocumentSetVisible = !!dfo.type

        if (!isDocumentSetVisible) return null

        const foundDfoFromArray =
          dfoByIdFromUrl &&
          (isArray(dfoByIdFromUrl)
            ? dfoByIdFromUrl.find((item) => item?.id === dfo.id)
            : dfoByIdFromUrl?.id === dfo.id)

        const deadlineCaption =
          dfo.deadline?.overdueDate && getDefaultFormattedDate(dfo.deadline?.overdueDate)
        const allCaptionForItemExist = `${params?.stageDescription || dfo.stage} ${
          deadlineCaption || ''
        }`

        const preparedConfig = params
          ? {
              color: params.stageStyle,
              icon: params.stageIcon,
            }
          : undefined

        return (
          <DocumentsSet.ItemExist
            key={dfo.id}
            isActive={!!foundDfoFromArray}
            title={dfo.typeTitle}
            subtitle={dfo.typeSubtitle}
            caption={allCaptionForItemExist}
            iconStatusProps={{ status: 'add' }}
            dataTestId={`DocumentSetItem-button-${dfo.type}`}
            config={preparedConfig}
            onClick={handleChooseDfo(dfo)}
          />
        )
      })}
      {(() => {
        switch (groupType) {
          case GroupType.CLAIM_SUBSIDY_PERMIT:
            const isExistedSubsidy = dfos.find(
              (dfo) =>
                dfo.type.includes(GroupType.CLAIM_SUBSIDY_PERMIT) ||
                dfo.type.includes(GroupType.CLAIM_TAX_REFUND),
            )

            const subsidyAddonRenderCondition =
              dfoAvailableForCreate?.length &&
              isSubsidyStatementWillBeCreate(dfoAvailableForCreate) &&
              isExistedSubsidy

            if (!subsidyAddonRenderCondition) return

            return <SubsidyAddon projectId={projectId} />

          default:
            return null
        }
      })()}
    </DocumentsSet.Wrapper>
  )

  if (onlyDocumentsWithoutGroup && group.dfos) {
    return <div className={styles.aloneGroup__wrapper}>{documentWrapper(group.dfos)}</div>
  }

  if (isSubgroup) {
    return (
      <div className={styles.subgroup__wrapper}>
        <Typography.Body
          variant={'bodyMMedium'}
          color={'text-base-tertiary'}
          className={styles.subgroup__name}
        >
          {group.groupName}
        </Typography.Body>
        {group.dfos && documentWrapper(group.dfos, group.groupType)}
      </div>
    )
  }

  return (
    <>
      <div className={styles.group__wrapper}>
        <button
          type="button"
          className={styles.group__wrapper__collapse}
          data-testid={dataTestId}
          onClick={handleFoldUnfold}
        >
          <Typography.Caption
            variant={'captionAllcaps'}
            color="text-base-secondary"
            className={cn(styles.group__name, {
              [styles.group__name__active]: !!dfoByIdFromUrl,
            })}
          >
            {group.groupName}
          </Typography.Caption>
          <IconButton
            size={'s'}
            view={'basic'}
            dataTestId={iconButtonDataTestId}
            icon={{
              className: cn(styles.toggler, {
                [styles.toggler__toggled]: groupIsUnfold,
              }),
              src: chevronRightIcon,
            }}
          />
        </button>
        <Collapse height={groupIsUnfold ? 'auto' : 0} className={styles.collapse__wrapper}>
          {group.subgroups
            ? group.subgroups.map((subgroup, index) => (
                <React.Fragment key={index}>
                  <DocumentContentGroup
                    isSubgroup
                    currentDfoId={currentDfoId}
                    createdDfoCategories={createdDfoCategories}
                    dfoAvailableForCreate={dfoAvailableForCreate}
                    dataTestId={`DocumentContentGroup-subgroupButton-${index}`}
                    iconButtonDataTestId={`DocumentContentGroup-subgroupIconButton-${index}`}
                    group={subgroup}
                    onDfoChange={onDfoChange}
                  />
                  {(() => {
                    switch (group.groupType) {
                      case GroupType.COST_RECOVERY:
                        if (!otherDonationsRenderCondition) return

                        return <OtherDonationsAddon projectId={projectId} />

                      default:
                        return null
                    }
                  })()}
                </React.Fragment>
              ))
            : group.dfos && documentWrapper(group.dfos, group.groupType)}
        </Collapse>
      </div>
    </>
  )
}

export default memo(DocumentContentGroup)
