import { FC, memo, useCallback, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography/Typography'
import { useAPIContext } from '@context/APIContext'
import { isAxiosError } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import { AxiosError } from 'axios'

import styles from './CreateProcessModal.module.scss'

export interface CreateProcessModalProps {
  projectId: string
  actionId: string
  onCreateProcess: (value: void | PromiseLike<void>) => void
  onErrorProcess: (e: AxiosError<unknown>) => void
}

type TCreateProcessModalProps = Required<PopupProps> & CreateProcessModalProps

const CreateProcessModal: FC<TCreateProcessModalProps> = ({
  isOpen,
  actionId,
  projectId,
  onCreateProcess,
  onErrorProcess,
  onClose,
}) => {
  const {
    processesApi: { createProcess },
  } = useAPIContext()

  const { booleanState: processIsSend, setBooleanState: setProcessIsSend } = useBooleanState()

  const handleModalClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleCreateProcess = useCallback(async () => {
    setProcessIsSend(true)

    try {
      await createProcess({ actionId, projectId })

      onCreateProcess()
    } catch (error) {
      if (!isAxiosError(error)) throw error

      onErrorProcess(error)
    } finally {
      setProcessIsSend(false)
      onClose()
    }
  }, [
    actionId,
    createProcess,
    onClose,
    onCreateProcess,
    onErrorProcess,
    projectId,
    setProcessIsSend,
  ])

  const actions = useMemo(() => {
    return [
      {
        view: 'gray',
        dataTestId: 'CreateProcessModal-cancel-button',
        children: 'Отмена',
        onClick: handleModalClose,
      },
      {
        dataTestId: 'CreateProcessModal-createProcess-button',
        children: 'Продолжить',
        className: styles.confirmButton,
        disabled: processIsSend,
        loaderProps: {
          variant: 'lite',
          placement: 'trailing',
          loading: processIsSend,
        },
        onClick: handleCreateProcess,
        fixWidth: true,
      },
    ] as ButtonProps[]
  }, [handleCreateProcess, handleModalClose, processIsSend])

  return (
    <Modal.Action
      hideCloseIcon
      isOpen={isOpen}
      title=""
      actions={actions}
      dataTestId="CreateProcessModal-modal"
      closeButtonDataTestId="CreateProcessModal-modal-closeButton"
      simpleModalContainerClassName={styles.modalWrapper}
      simpleModalBodyClassName={styles.modalBody}
      actionModalContentClassName={styles.modalAction}
      onClose={onClose}
    >
      <Typography.Headline variant="headlineH3" align="center">
        Внести изменения?
      </Typography.Headline>
      <Typography.Body variant="bodyMRegular" align="center">
        Изменение документов по инвестиционному проекту может изменить решение субъекта об оценке
        бюджетной эффективности и решение о подписании СЗПК
      </Typography.Body>
    </Modal.Action>
  )
}

export default memo(CreateProcessModal)
