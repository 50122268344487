import { FC, useEffect } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import CollapseCardWithPreview from '@components/DocumentFormComponents/CollapseCardWithPreview'
import FieldView from '@components/DocumentFormComponents/FieldView'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { summaryReportOfMonitoringResultsSzpkChangeTerminateStepValidationMap } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/6/SzpkChangeTerminateStep/validation'
import { SummaryReportOfMonitoringResultsSzpkChangeTerminateArrayPathName } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/6/types'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import { SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { useBooleanState } from '@hooks/useBooleanState'

interface SummaryReportOfMonitoringResultsSzpkChangeTerminateStepProps {
  id: string
  formName: SummaryReportOfMonitoringResultsSzpkChangeTerminateArrayPathName
  formInstance: UseFormReturn<SummaryReportOfMonitoringResultsFormValues>
}

const SummaryReportOfMonitoringResultsSzpkChangeTerminateStep: FC<
  SummaryReportOfMonitoringResultsSzpkChangeTerminateStepProps
> = ({ id, formName, formInstance }) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  const {
    getCheckboxProps,
    getSubscribableControlProps,
    getInputProps,
    getCalendarInputProps,
    getAmountInputProps,
    getTextareaProps,
  } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
  })

  const isChangeTerminate = useWatch({
    name: `${formName}.isChange`,
    control: formInstance.control,
  })

  const isTerminate = useWatch({
    name: `${formName}.isTerminate`,
    control: formInstance.control,
  })

  const {
    booleanState: isExpanded,
    setBooleanStateToTrue: expandForm,
    setBooleanStateToFalse: collapseForm,
  } = useBooleanState(isChangeTerminate || isTerminate)

  useEffect(() => {
    if (isChangeTerminate || isTerminate) {
      expandForm()
    } else {
      collapseForm()
    }
  }, [isChangeTerminate, isTerminate])

  return (
    <CollapseCardWithPreview
      key={id}
      id={id}
      isExpanded={isExpanded}
      previewNode={
        <>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.projectName`,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: `${formName}.projectName`,
                    inputProps: {
                      disabled: true,
                      label: 'Наименование проекта',
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <AsyncWrapper promise={handleChangeValue}>
            {({ isLoading: isReasonsChecking, wrappedPromise: handleCheckReasons }) => (
              <Row>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.isChange`,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${formName}.isChange`,
                        checkBoxProps: {
                          disabled: isReasonsChecking || !editMode,
                          label: 'Основания для изменения',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckReasons?.(`${formName}.isChange`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
                <Col xs={6}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.isTerminate`,
                    })}
                  >
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${formName}.isTerminate`,
                        checkBoxProps: {
                          disabled: isReasonsChecking || !editMode,
                          label: 'Основания для расторжения',
                        },
                        onChange: () =>
                          setTimeout(() => {
                            handleCheckReasons?.(`${formName}.isTerminate`)
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </Col>
              </Row>
            )}
          </AsyncWrapper>
        </>
      }
      onCollapseNode={collapseForm}
      onExpandForm={expandForm}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.registeredDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${formName}.registeredDate`,
                  calendarInputProps: {
                    disabled: true,
                    label: 'Дата регистрации',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.registeredNumber`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.registeredNumber`,
                  inputProps: {
                    disabled: true,
                    label: 'Регистрационный номер',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group disableBottomBorder title="Объем капитальных вложений">
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем по справкам проверяющей организации'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.totalCapitalFactChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.totalCapitalFactChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем по региональному отчету'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.totalCapitalFact`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.totalCapitalFact`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем для переноса в отчет'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.totalCapitalCorrect`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.totalCapitalCorrect`,
                    rules:
                      summaryReportOfMonitoringResultsSzpkChangeTerminateStepValidationMap.totalCapitalCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.totalCapitalCorrect`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.totalCapitalCorrect`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
        <Group disableBottomBorder title="Объем капиталовложений">
          <Stack direction={'vertical'} gap={3}>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем по справкам проверяющей организации'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorCapitalFactChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.investorCapitalFactChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем по региональному отчету'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorCapitalFact`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.investorCapitalFact`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
            <FieldView.RowWithBottomBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем для переноса в отчет'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.investorCapitalCorrect`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.investorCapitalCorrect`,
                    rules:
                      summaryReportOfMonitoringResultsSzpkChangeTerminateStepValidationMap.investorCapitalCorrect,
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${formName}.investorCapitalCorrect`)
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${formName}.investorCapitalCorrect`)
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </FieldView.RowWithBottomBorder>
          </Stack>
        </Group>
        <Group disableBottomBorder title="Основания для изменения или расторжения СЗПК">
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.reasonChecker`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.reasonChecker`,
                      inputProps: {
                        disabled: true,
                        label: 'Основания по справкам проверяющей организации',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.reasonRegion`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.reasonRegion`,
                      inputProps: {
                        disabled: true,
                        label: 'Основания по региональному отчету',
                      },
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.changeTerminateReason`,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: `${formName}.changeTerminateReason`,
                      rules:
                        summaryReportOfMonitoringResultsSzpkChangeTerminateStepValidationMap.changeTerminateReason,
                      textareaProps: {
                        label: 'Основания для переноса в отчет',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.changeTerminateReason`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.changeTerminateReason`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </Group>
      </Stack>
    </CollapseCardWithPreview>
  )
}

export default SummaryReportOfMonitoringResultsSzpkChangeTerminateStep
