import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

type TechnologicalRequirementsKey =
  keyof ProjectSZPKFormValues['10']['technologicalRequirements'][number]

const technologicalRequirementsValidationMap: FormValuesValidationSection<
  TechnologicalRequirementsKey,
  ProjectSZPKFormValues
> = {
  projectNpaName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 600),
  },
}

export { technologicalRequirementsValidationMap }
