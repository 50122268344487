import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { IOrganizationInfo } from '@services/Organization/organization.entity'
import useSWRImmutable from 'swr/immutable'

const useOrganizationInfoById = ({ key, config }: SWRHookProps<IOrganizationInfo | undefined>) => {
  const {
    organizationsApi: { getOrganizationById },
  } = useAPIContext()

  const {
    data: organization,
    error,
    mutate,
    isValidating: isLoadingOrganization,
  } = useSWRImmutable(key, ({ organizationId }) => getOrganizationById(organizationId), config)

  return {
    organization,
    error,
    mutate,
    isLoadingOrganization,
  }
}

export { useOrganizationInfoById }
