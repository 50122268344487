export const PAGE_SIZE_FOR_NPA = 50

const defaultFiltersValue = Object.freeze({
  filters: {
    size: PAGE_SIZE_FOR_NPA,
    isFederal: true,
    isRegional: true,
    isMunicipal: true,
    part: '',
    region: '',
    oktmo: '',
    direction: '' as const,
    type: '' as const,
  },
  searchString: '',
})

export { defaultFiltersValue }
