import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import CertificateOnMonitoringResultsMenuManager from '@components/DocumentFormComponents/NestedMenu/Manager'
import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import CertificateOnMonitoringResultsLayoutWithFormProvider from './Layout/LayoutWithFormProvider'
import CertificateOnMonitoringResultsModalManager from './Manager'
import CertificateOnMonitoringResultsMenu from './Menu'
import CertificateOnMonitoringResultsStatementForm from './Statement'

interface ICreateCertificateOnMonitoringResultsControl extends FC {
  LayoutWithFormProvider: typeof CertificateOnMonitoringResultsLayoutWithFormProvider
  Menu: typeof CertificateOnMonitoringResultsMenu
  StatementForm: typeof CertificateOnMonitoringResultsStatementForm
  Manager: typeof CertificateOnMonitoringResultsModalManager
  MenuManager: typeof CertificateOnMonitoringResultsMenuManager
}

const useCertificateOnMonitoringResultsControl = () => {
  const popupManager = usePopupManager()

  const openCertificateOnMonitoringResultsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(
        CertificateOnMonitoringResultsLayoutWithFormProvider,
        {
          ...rest,
          formId,
          onClose: onClose || (() => null),
        },
      )
    },
    [popupManager],
  )

  return { openCertificateOnMonitoringResultsModal }
}

const CertificateOnMonitoringResults: ICreateCertificateOnMonitoringResultsControl = () => null

CertificateOnMonitoringResults.Menu = CertificateOnMonitoringResultsMenu
CertificateOnMonitoringResults.LayoutWithFormProvider =
  CertificateOnMonitoringResultsLayoutWithFormProvider
CertificateOnMonitoringResults.StatementForm = CertificateOnMonitoringResultsStatementForm
CertificateOnMonitoringResults.Manager = CertificateOnMonitoringResultsModalManager
CertificateOnMonitoringResults.MenuManager = CertificateOnMonitoringResultsMenuManager

export { useCertificateOnMonitoringResultsControl }
export default CertificateOnMonitoringResults
