import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import BackButton from '@components/BackButton'
import Container from '@components/Container'
import { Paths } from '@constants/paths'
import cn from 'classnames'

import styles from './NavigationPanel.module.scss'

interface NavigationPanelProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  sticky?: boolean
}

const NavigationPanel: FC<NavigationPanelProps> = ({ sticky, ...rest }) => {
  return (
    <div
      {...rest}
      className={cn(
        styles.panel,
        {
          [styles.sticky]: sticky,
        },
        rest.className,
      )}
    >
      <Container>
        <div className={styles.wrapper}>
          <BackButton customColor="surface" size="s" to={Paths.Home}>
            На главную
          </BackButton>
        </div>
      </Container>
    </div>
  )
}

export default NavigationPanel
