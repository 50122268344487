import React, { type FC, type FocusEvent, useCallback, useMemo, useRef } from 'react'
import type { FileRejection } from 'react-dropzone'
import type { UseFormReturn } from 'react-hook-form'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import { useAttachmentAPIContext } from '@components/Attachments/Attachment/APIProvider'
import { AttachmentFileItemService } from '@components/Attachments/Attachment/FileItem/AttachmentFileItem/helpers'
import { FileItemService } from '@components/Attachments/Attachment/FileItem/helpers'
import LeadingAdditionInTrailingNode from '@components/Attachments/Attachment/FileItem/TrailingNode/Addition/LeadingNodeTrailing'
import TrailingAdditionInTrailingNode from '@components/Attachments/Attachment/FileItem/TrailingNode/Addition/TrailingNodeAddition'
import trailingNodeStyles from '@components/Attachments/Attachment/FileItem/TrailingNode/FileItemTrailingNode.module.scss'
import {
  FileItemLeadingAdditionProps,
  FileItemTrailingAdditionProps,
  RejectedFile,
} from '@components/Attachments/Attachment/FileItem/types'
import FolderItem from '@components/Attachments/Attachment/FolderItem'
import { useAttachmentFolderItemFieldArray } from '@components/Attachments/Attachment/FolderItem/AttachmentFolderItem/hooks/useAttachmentFolderItemFieldArray'
import { HandleDownloadFolderFileProps } from '@components/Attachments/Attachment/FolderItem/AttachmentFolderItem/types'
import type {
  FolderFileItem,
  FolderItemDropzoneProps,
} from '@components/Attachments/Attachment/FolderItem/types'
import { useAttachmentPermissions } from '@components/Attachments/Attachment/hooks/useAttachmentPermissions'
import { useDropzoneValidation } from '@components/Attachments/Attachment/hooks/useDropzoneValidation'
import { attachmentsFormNames, statusTypesMap } from '@components/Attachments/constants'
import { useFileContext } from '@components/Attachments/FileProvider'
import type { AttachmentsFormValue } from '@components/Attachments/types'
import Typography from '@components/NewDesign/Typography'
import { useDeleteFolderModal } from '@components/NewDesignedModals/DeleteFolderModal/manager'
import { useErrorModal } from '@components/NewDesignedModals/ErrorModal/manager'
import { useVersionController } from '@components/Projects/[id]/VersionConroller'
import {
  ChangeDocumentAttributeProps,
  useDocumentViewSidebarControl,
} from '@components/Sidebars/DocumentView'
import { documentActionsMap } from '@constants/documents'
import { DocumentsSetsType, MimeTypes, RolesTypes } from '@constants/types'
import DownloadDocumentTooltipContent from '@containers/DownloadDocumentTooltipContent/DownloadDocumentTooltipContent'
import { checkRoleOnExistence } from '@context/AuthContext/workers/rolesWorkers'
import { isBoolean, isNull, isNullOrUndefined } from '@helpers/checkTypes'
import { createFileItem, splitFileName } from '@helpers/fileHelpers'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import useDownloadFile from '@hooks/new/documents/useDownloadFile'
import { useVersions } from '@hooks/new/swr/useVersions'
import { useBooleanState } from '@hooks/useBooleanState'
import type { AttachmentErrorProps, IAttachmentFileItem } from '@interfaces/documents'
import type { IDocumentDictInfo } from '@services/Dictionaries/dictionaries.entity'
import { DocumentsHelpersService } from '@services/Documents/documents.helpers'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import cn from 'classnames'

import styles from './AttachmentFolderItem.module.scss'
import { AttachmentFolderItemService } from './helpers'

const MAX_FILE_SIZE_IN_BYTES = 200 * 1024 * 1024

const { convertRejectedFile, convertMultipleRejectedFile } = FileItemService
const { findFileInFolderById } = AttachmentFolderItemService
const { isSignedDocument, parseFileItemError, parseFileItemSignError } = AttachmentFileItemService

const { isDocumentReadSignMainPermission } = DocumentsHelpersService

interface AttachmentFolderItemProps {
  documentInfoFromDict: Omit<IDocumentDictInfo, 'isFolderAvailable' | 'isMultiple'>
  formInstance: UseFormReturn<AttachmentsFormValue>
  projectId: string
  dfoId: string
  documentSetId: string
  permissions: number
  parentSetType: string
  isChangesMadeTab?: boolean
}

const AttachmentFolderItem: FC<AttachmentFolderItemProps> = ({
  documentInfoFromDict,
  formInstance,
  projectId,
  dfoId,
  documentSetId,
  permissions,
  parentSetType,
  isChangesMadeTab,
}) => {
  const { isFederal, isMain, templateExists, description, documentType } = documentInfoFromDict
  const { handleAddFileToSet, handleRemoveFileFromSet, handleUpdateFileFromSet } = useFileContext()

  const {
    attachmentFileApi: {
      handleAddFile,
      handleRemoveFile,
      handleChangeFile,
      handleChangeFileName,
      handleForeignSign,
    },
  } = useAttachmentAPIContext()

  const {
    documents,
    handleAddFileItemInFolder,
    handleUpdateFileItemInFolder,
    handleInsertFileItemInFolder,
    handleRemoveFileItemInFolder,
  } = useAttachmentFolderItemFieldArray(formInstance)

  const { versions } = useVersions({
    key: { dfoId, projectId, _key: 'versions' },
    config: {
      isPaused: () => !dfoId || !projectId,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'AttachmentFolderItem',
          moduleName: 'AttachmentsController',
        },
      }),
    },
  })

  const versionState = useVersionController()

  const { download } = useDownloadFile()

  const { openDocumentViewSidebar } = useDocumentViewSidebarControl()
  const { handleOpenDeleteFolderModal } = useDeleteFolderModal()
  const { handleOpenErrorModal } = useErrorModal()

  const { dropzoneValidator } = useDropzoneValidation({
    maxFileSize: MAX_FILE_SIZE_IN_BYTES,
    acceptExtensions: MimeTypes['text/csv'],
  })

  const { booleanState: isFold, reverseBooleanState: handleToggleFold } = useBooleanState()

  const replacedFileInFolderRef = useRef<IAttachmentFileItem | null>(null)

  const {
    isReadOnlyAttachmentPermission,
    isReadAttachmentPermission,
    isReadApproveAttachmentPermission,
    isWriteAttachmentPermission,
    isDownloadForbiddenAttachmentPermission,
    isReadDigitizingPermission,
  } = useAttachmentPermissions({
    permissions,
  })

  const isReadSignMainPermission = isDocumentReadSignMainPermission(permissions)

  const isSignAttachmentPermission = isReadSignMainPermission && isMain

  const baseDropzoneProps: FolderItemDropzoneProps = {
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    accept: MimeTypes,
    validator: dropzoneValidator(),
    multiple: false,
  }

  const conditionToSignedMarkRender =
    isReadOnlyAttachmentPermission && parentSetType === DocumentsSetsType.DEPARTMENTS_RESPONSES

  const isOIV = checkRoleOnExistence(RolesTypes.OIV)

  const isShowSignButton = isOIV && !isFederal && isSignAttachmentPermission

  const hasDocumentTemplateType = isWriteAttachmentPermission && templateExists

  const isStatic =
    isReadAttachmentPermission || isReadApproveAttachmentPermission || isReadDigitizingPermission

  const preparedFiles: FolderFileItem[] = useMemo(() => {
    if (!documents.length) return []

    return documents.map(({ file, rejectedFile, id, error, isLoading, documentInfo }) => ({
      id,
      fileState: {
        file,
        isDeletedFile: documentInfo?.versionChangeState === 'DELETED',
        rejectedFile,
        fileSize: documentInfo?.size ?? 0,
        errorMessage: error?.message || null,
        isLoading,
      },
    }))
  }, [documents])

  const signConditionOfDocuments: boolean[] = useMemo(() => {
    if (!documents.length) return []

    return documents.map((document) => isSignedDocument(document.documentInfo))
  }, [documents])

  const readOnlyDocuments: boolean[] = useMemo(() => {
    if (!documents.length) return []

    return documents.map(({ documentInfo }) => isStatic || !!documentInfo?.isApproved)
  }, [documents, isStatic])

  const handleToastAlert = useCallback((text = 'Не удалось получить информацию о документе') => {
    toast(
      <Alert transparent variant="error">
        {text}
      </Alert>,
    )
  }, [])

  const getAttachmentFileItemIndexInFolder = useCallback(
    (attachmentFileItem: IAttachmentFileItem) => {
      const currentAttachmentsInFolder = formInstance.getValues(attachmentsFormNames.folderItems)

      return currentAttachmentsInFolder.findIndex(
        (attachment) => attachment.id === attachmentFileItem.id,
      )
    },
    [formInstance],
  )

  const handleAddNewFileInFolder = useCallback(
    async (file: File) => {
      const fileWithCurrentDate = new File([file], file.name)

      const createdAttachmentFileItem = createFileItem({
        file: fileWithCurrentDate,
        isLoading: true,
      })

      let updatedAttachmentItem = createdAttachmentFileItem

      handleAddFileItemInFolder({
        attachmentFileItem: createdAttachmentFileItem,
      })

      const attachmentFileIndex = getAttachmentFileItemIndexInFolder(updatedAttachmentItem)

      try {
        const document = await handleAddFile?.({
          file,
          type: documentType,
          documentSetId,
        })

        if (!document) return

        const { type, ...documentInfo } = document

        updatedAttachmentItem = {
          ...createdAttachmentFileItem,
          documentInfo,
        }

        handleUpdateFileItemInFolder({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachmentItem,
        })

        handleAddFileToSet?.({
          type,
          document: documentInfo,
        })
      } catch (error) {
        const preparedError: AttachmentErrorProps = {
          actionType: documentActionsMap.ADD,
          message: parseFileItemError(error),
        }

        updatedAttachmentItem = {
          ...createdAttachmentFileItem,
          error: preparedError,
        }

        handleUpdateFileItemInFolder({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachmentItem,
        })

        throw error
      } finally {
        handleUpdateFileItemInFolder({
          attachmentFileIndex,
          attachmentFileItem: {
            ...updatedAttachmentItem,
            isLoading: false,
          },
        })
      }
    },
    [
      documentSetId,
      documentType,
      getAttachmentFileItemIndexInFolder,
      handleAddFile,
      handleAddFileItemInFolder,
      handleAddFileToSet,
      handleUpdateFileItemInFolder,
    ],
  )

  const handleUpdateFileInFolder = useCallback(
    async (attachmentFile: IAttachmentFileItem, updatedFile: File) => {
      if (!attachmentFile.documentInfo) return handleToastAlert()

      const { documentInfo, id: fileId } = attachmentFile

      const updatedFileWithCurrentDate = new File([updatedFile], updatedFile.name)

      let updatedAttachmentInFolder: IAttachmentFileItem = {
        ...attachmentFile,
        file: updatedFileWithCurrentDate,
        isLoading: true,
        error: null,
      }

      const attachmentFileIndex = getAttachmentFileItemIndexInFolder(updatedAttachmentInFolder)

      const [fileName, fileExtension] = splitFileName(updatedFileWithCurrentDate.name)

      updatedAttachmentInFolder = {
        ...updatedAttachmentInFolder,
        file: updatedFileWithCurrentDate,
        id: fileId,
        documentInfo: {
          ...documentInfo,
          size: updatedFile.size,
          name: fileName,
          extension: fileExtension,
        },
      }

      handleUpdateFileItemInFolder({
        attachmentFileIndex,
        attachmentFileItem: updatedAttachmentInFolder,
      })

      try {
        const updatedDocumentInfo = await handleChangeFile?.({
          file: updatedFileWithCurrentDate,
          fileId: documentInfo.id,
          documentSetId,
        })

        if (!updatedDocumentInfo) return

        updatedAttachmentInFolder = {
          ...updatedAttachmentInFolder,
          documentInfo: updatedDocumentInfo,
        }

        handleUpdateFileItemInFolder({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachmentInFolder,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
        })

        replacedFileInFolderRef.current = null
      } catch (error) {
        const preparedError: AttachmentErrorProps = {
          actionType: documentActionsMap.UPDATE,
          message: parseFileItemError(error),
        }

        updatedAttachmentInFolder = {
          ...attachmentFile,
          error: preparedError,
        }

        replacedFileInFolderRef.current = {
          ...updatedAttachmentInFolder,
          file: updatedFileWithCurrentDate,
        }

        handleUpdateFileItemInFolder({
          attachmentFileIndex,
          attachmentFileItem: updatedAttachmentInFolder,
        })

        throw error
      } finally {
        handleUpdateFileItemInFolder({
          attachmentFileIndex,
          attachmentFileItem: {
            ...updatedAttachmentInFolder,
            isLoading: false,
          },
        })
      }
    },
    [
      documentSetId,
      documentType,
      getAttachmentFileItemIndexInFolder,
      handleChangeFile,
      handleToastAlert,
      handleUpdateFileFromSet,
      handleUpdateFileItemInFolder,
    ],
  )

  const handleRejectedFileInFolder = useCallback(
    (rejectedFiles: FileRejection[], files: File[], attachmentFileItem?: IAttachmentFileItem) => {
      let preparedRejectedFile: RejectedFile | null = null

      if (
        rejectedFiles.length > 1 ||
        !!files?.length ||
        (rejectedFiles.length && !!files?.length)
      ) {
        preparedRejectedFile = convertMultipleRejectedFile(files?.[0] || rejectedFiles[0].file)
      } else {
        preparedRejectedFile = convertRejectedFile(rejectedFiles[0])
      }

      if (!replacedFileInFolderRef.current && attachmentFileItem) {
        const { file, documentInfo, ...restAttachmentItem } = attachmentFileItem

        const attachmentFileIndex = getAttachmentFileItemIndexInFolder(attachmentFileItem)

        const rejectedAttachmentItem: IAttachmentFileItem = {
          ...restAttachmentItem,
          file: null,
          rejectedFile: preparedRejectedFile,
        }

        return handleUpdateFileItemInFolder({
          attachmentFileItem: rejectedAttachmentItem,
          attachmentFileIndex,
        })
      }

      if (!replacedFileInFolderRef.current || !attachmentFileItem) {
        const rejectedAttachmentItem = createFileItem({
          rejectedFile: preparedRejectedFile,
        })

        return handleAddFileItemInFolder({ attachmentFileItem: rejectedAttachmentItem })
      }

      const { file, documentInfo, ...restAttachmentItem } = replacedFileInFolderRef.current

      if (!documentInfo) return

      const attachmentFileIndex = getAttachmentFileItemIndexInFolder(
        replacedFileInFolderRef.current,
      )

      const rejectedAttachmentItem: IAttachmentFileItem = {
        ...restAttachmentItem,
        file: null,
        rejectedFile: preparedRejectedFile,
      }

      handleUpdateFileItemInFolder({
        attachmentFileItem: rejectedAttachmentItem,
        attachmentFileIndex,
      })
    },
    [getAttachmentFileItemIndexInFolder, handleAddFileItemInFolder, handleUpdateFileItemInFolder],
  )

  const handleRejectDropFileInFolder = useCallback(
    (fileInFolder: FolderFileItem) =>
      async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        const foundAttachmentFileItem = findFileInFolderById(documents, fileInFolder.id)

        if (fileRejections.length || acceptedFiles.length > 1) {
          return handleRejectedFileInFolder(fileRejections, acceptedFiles, foundAttachmentFileItem)
        }

        const fileToUploadWithCurrentDate = new File([acceptedFiles[0]], acceptedFiles[0].name)

        if (!foundAttachmentFileItem) return

        const attachmentFileIndex = getAttachmentFileItemIndexInFolder(foundAttachmentFileItem)

        handleRemoveFileItemInFolder({ attachmentFileIndex })

        await handleAddNewFileInFolder(fileToUploadWithCurrentDate)
      },
    [
      documents,
      getAttachmentFileItemIndexInFolder,
      handleAddNewFileInFolder,
      handleRejectedFileInFolder,
      handleRemoveFileItemInFolder,
    ],
  )

  const handleDropFileInFolder = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length || acceptedFiles.length > 1)
        return handleRejectedFileInFolder(fileRejections, acceptedFiles)

      const fileToUploadWithCurrentDate = new File([acceptedFiles[0]], acceptedFiles[0].name)

      if (!replacedFileInFolderRef.current) {
        return handleAddNewFileInFolder(fileToUploadWithCurrentDate)
      }

      return handleUpdateFileInFolder(replacedFileInFolderRef.current, fileToUploadWithCurrentDate)
    },
    [handleAddNewFileInFolder, handleRejectedFileInFolder, handleUpdateFileInFolder],
  )

  const handleDeleteFileFromFolder = useCallback(
    (attachmentFileItem: IAttachmentFileItem) => async () => {
      const { id } = attachmentFileItem.documentInfo || {}

      const currentAttachmentsInFolder = formInstance.getValues(attachmentsFormNames.folderItems)

      const attachmentFileIndex = currentAttachmentsInFolder.findIndex(
        (attachment) => attachment.id === attachmentFileItem.id,
      )

      handleRemoveFileItemInFolder({
        attachmentFileIndex,
      })

      try {
        await handleRemoveFile?.({
          documentSetId,
          fileId: id || '',
        })

        handleRemoveFileFromSet?.({
          documentId: id || '',
          type: documentType,
        })
      } catch (error) {
        const preparedError: AttachmentErrorProps = {
          actionType: documentActionsMap.DELETE,
          message: parseFileItemError(error),
        }

        handleInsertFileItemInFolder({
          attachmentFileIndex,
          attachmentFileItem: {
            ...attachmentFileItem,
            error: preparedError,
          },
        })

        throw error
      }
    },
    [
      documentSetId,
      documentType,
      formInstance,
      handleInsertFileItemInFolder,
      handleRemoveFile,
      handleRemoveFileFromSet,
      handleRemoveFileItemInFolder,
    ],
  )

  const handleDeleteFileFromFolderWithModal = useCallback(
    async (fileInFolder: FolderFileItem) => {
      const foundAttachmentFileItem = findFileInFolderById(documents, fileInFolder.id)

      if (!foundAttachmentFileItem) return handleToastAlert()

      const preparedFileNameText = foundAttachmentFileItem.file?.name
        ? ` "${foundAttachmentFileItem.file.name}"`
        : ''

      handleOpenDeleteFolderModal({
        header: 'Подтверждение удаления документа',
        description: `Вы действительно хотите удалить документ${preparedFileNameText}?`,
        onDelete: handleDeleteFileFromFolder(foundAttachmentFileItem),
      })
    },
    [documents, handleDeleteFileFromFolder, handleOpenDeleteFolderModal, handleToastAlert],
  )

  const handleDownloadFile = useCallback(
    (props?: HandleDownloadFolderFileProps) => async (fileInFolder: FolderFileItem) => {
      const { withSigned = false, event } = props || {}

      event?.stopPropagation()

      const foundAttachmentFileItem = findFileInFolderById(documents, fileInFolder.id)

      const { documentInfo } = foundAttachmentFileItem || {}

      if (!documentInfo) return handleToastAlert()

      const { name, extension, id, versionId } = documentInfo

      const fileNameWithExtension = `${name}.${extension}`

      return withDownloadToastPromise(
        download({
          documentSetId,
          projectId,
          fileId: id,
          fileNameWithExtension,
          versionId,
          withSigned,
          type: 'save',
        }),
      )
    },
    [documentSetId, documents, download, handleToastAlert, projectId],
  )

  const handleReplaceFileInFolder = useCallback(
    (folderFileItem: FolderFileItem) => {
      replacedFileInFolderRef.current = findFileInFolderById(documents, folderFileItem.id) ?? null
    },
    [documents],
  )

  const handleChangeFileNameOnBlur = useCallback(
    (attachment: IAttachmentFileItem) => async (event: FocusEvent<HTMLInputElement>) => {
      const { documentInfo, file, ...restAttachment } = attachment

      if (!documentInfo || !file) return handleToastAlert()

      const attachmentFileIndex = getAttachmentFileItemIndexInFolder(attachment)

      const { value } = event.target

      const [_fileName, fileExtension] = splitFileName(file.name)

      const updatedFile = new File([file], `${value}.${fileExtension}`)
      const updatedDocumentInfo: IAttachmentFileItem['documentInfo'] = {
        ...documentInfo,
        name: value,
      }

      const updatedAttachmentFileItem: IAttachmentFileItem = {
        ...restAttachment,
        file: updatedFile,
        documentInfo: updatedDocumentInfo,
      }

      handleUpdateFileItemInFolder({
        attachmentFileIndex,
        attachmentFileItem: updatedAttachmentFileItem,
      })

      try {
        await handleChangeFileName?.({
          fileId: documentInfo.id,
          documentSetId,
          name: value,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
        })
      } catch (error) {
        handleUpdateFileItemInFolder({
          attachmentFileIndex,
          attachmentFileItem: attachment,
        })

        throw error
      }
    },
    [
      documentSetId,
      documentType,
      getAttachmentFileItemIndexInFolder,
      handleChangeFileName,
      handleToastAlert,
      handleUpdateFileFromSet,
      handleUpdateFileItemInFolder,
    ],
  )

  const handleChangeFileFromFolderAttribute = useCallback(
    (attachment: IAttachmentFileItem) =>
      ({ attributeName, value }: ChangeDocumentAttributeProps) => {
        if (!attachment.documentInfo) return handleToastAlert()

        const attachmentFileIndex = getAttachmentFileItemIndexInFolder(attachment)

        const updatedDocumentInfo: IAttachmentFileItem['documentInfo'] = {
          ...attachment.documentInfo,
          [attributeName]: value,
        }

        handleUpdateFileItemInFolder({
          attachmentFileItem: {
            ...attachment,
            documentInfo: updatedDocumentInfo,
          },
          attachmentFileIndex,
        })

        handleUpdateFileFromSet?.({
          document: updatedDocumentInfo,
          type: documentType,
        })
      },
    [
      documentType,
      getAttachmentFileItemIndexInFolder,
      handleToastAlert,
      handleUpdateFileFromSet,
      handleUpdateFileItemInFolder,
    ],
  )

  const handleOpenDocumentViewSidebar = useCallback(
    ({ id }: FolderFileItem) =>
      () => {
        const foundAttachmentFileItem = findFileInFolderById(documents, id)

        if (!foundAttachmentFileItem || !foundAttachmentFileItem.documentInfo)
          return handleToastAlert()

        const { documentInfo, error } = foundAttachmentFileItem

        if (!!error || documentInfo?.versionChangeState === 'DELETED') return

        openDocumentViewSidebar({
          isApproved: documentInfo.isApproved,
          accessToApprove: isReadApproveAttachmentPermission,
          isStatic: isStatic || !!documentInfo?.isApproved,
          documentId: documentInfo.id,
          projectId,
          dfoId,
          documentSetId,
          typeOfDocument: documentInfo.name,
          edit: {
            onBlur: handleChangeFileNameOnBlur(foundAttachmentFileItem),
            value: documentInfo.name,
          },
          onChangeDocumentAttribute: handleChangeFileFromFolderAttribute(foundAttachmentFileItem),
        })
      },
    [
      dfoId,
      documentSetId,
      documents,
      handleChangeFileFromFolderAttribute,
      handleChangeFileNameOnBlur,
      handleToastAlert,
      isReadApproveAttachmentPermission,
      isStatic,
      openDocumentViewSidebar,
      projectId,
    ],
  )

  const handleDropSignFile = useCallback(
    (fileInFolder: FolderFileItem) =>
      async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (!acceptedFiles.length || fileRejections.length) return

        const foundAttachmentFileItem = findFileInFolderById(documents, fileInFolder.id)

        const { documentInfo } = foundAttachmentFileItem || {}

        if (!documentInfo) return handleToastAlert()

        const sigFile = acceptedFiles[0]

        try {
          await handleForeignSign({
            sigFile,
            documentSetId,
            documentId: documentInfo.id,
          })
        } catch (error) {
          const { headerText, bodyText } = parseFileItemSignError(error)

          handleOpenErrorModal({
            headerText,
            bodyText,
          })

          throw error
        }
      },
    [documentSetId, documents, handleForeignSign, handleOpenErrorModal, handleToastAlert],
  )

  const handleReloadFileInFolder = useCallback(
    async (fileInFolder: FolderFileItem) => {
      const foundAttachmentFileItem = findFileInFolderById(documents, fileInFolder.id)

      if (!foundAttachmentFileItem || !fileInFolder.fileState.file) return handleToastAlert()

      const attachmentFileIndex = getAttachmentFileItemIndexInFolder(foundAttachmentFileItem)

      const { actionType } = foundAttachmentFileItem.error || {}

      if (actionType === documentActionsMap.ADD) {
        handleRemoveFileItemInFolder({
          attachmentFileIndex,
        })

        return handleAddNewFileInFolder(fileInFolder.fileState.file)
      }

      if (actionType === documentActionsMap.DELETE) {
        return handleDeleteFileFromFolder(foundAttachmentFileItem)()
      }

      if (actionType === documentActionsMap.UPDATE) {
        if (!replacedFileInFolderRef.current || !replacedFileInFolderRef.current?.file) return

        return handleUpdateFileInFolder(
          foundAttachmentFileItem,
          replacedFileInFolderRef.current.file,
        )
      }
    },
    [
      documents,
      getAttachmentFileItemIndexInFolder,
      handleAddNewFileInFolder,
      handleDeleteFileFromFolder,
      handleRemoveFileItemInFolder,
      handleToastAlert,
      handleUpdateFileInFolder,
    ],
  )

  const handleRemoveNotLoadedFileInFolder = useCallback(
    (fileInFolder: FolderFileItem) => {
      const foundAttachmentFileItem = findFileInFolderById(documents, fileInFolder.id)

      if (!foundAttachmentFileItem || !fileInFolder.fileState.file) return handleToastAlert()

      const attachmentFileIndex = getAttachmentFileItemIndexInFolder(foundAttachmentFileItem)

      const { actionType } = foundAttachmentFileItem.error || {}

      if (actionType === documentActionsMap.ADD) {
        return handleRemoveFileItemInFolder({
          attachmentFileIndex,
        })
      }

      if (actionType === documentActionsMap.UPDATE) {
        replacedFileInFolderRef.current = null
      }

      handleUpdateFileItemInFolder({
        attachmentFileIndex,
        attachmentFileItem: {
          ...foundAttachmentFileItem,
          error: null,
        },
      })
    },
    [
      documents,
      getAttachmentFileItemIndexInFolder,
      handleRemoveFileItemInFolder,
      handleToastAlert,
      handleUpdateFileItemInFolder,
    ],
  )

  const renderLeadingAdditionProps = useCallback(
    ({ id }: FolderFileItem): FileItemLeadingAdditionProps | undefined => {
      const foundAttachmentFileItem = findFileInFolderById(documents, id)

      const { documentInfo } = foundAttachmentFileItem || {}

      if (!documentInfo) return

      return {
        statusProps: !isNullOrUndefined(documentInfo.isApproved)
          ? {
              statusType: documentInfo.isApproved
                ? statusTypesMap.APPROVED
                : statusTypesMap.REJECTED,
            }
          : undefined,
      }
    },
    [documents],
  )

  const handleCheckDeletedVersionDocument = useCallback(
    ({ id }: FolderFileItem): boolean => {
      const foundAttachmentFileItem = findFileInFolderById(documents, id)

      if (!foundAttachmentFileItem) return false

      const { versionChangeState } = foundAttachmentFileItem.documentInfo || {}

      const hasCorrectVersionsLength = (versions?.length ?? 0) > 1

      const filteredVersionChangeState =
        isChangesMadeTab || hasCorrectVersionsLength ? versionChangeState : undefined

      return filteredVersionChangeState === 'DELETED' || isDownloadForbiddenAttachmentPermission
    },
    [documents, isChangesMadeTab, isDownloadForbiddenAttachmentPermission, versions?.length],
  )

  const renderTrailingAdditionProps = useCallback(
    ({ id }: FolderFileItem): FileItemTrailingAdditionProps | undefined => {
      const foundAttachmentFileItem = findFileInFolderById(documents, id)

      if (!foundAttachmentFileItem) return

      const { isEditable, versionChangeState, version } = foundAttachmentFileItem.documentInfo || {}

      const isDepartmentResponseSigned =
        conditionToSignedMarkRender && isBoolean(isEditable) && !isEditable

      const hasCorrectVersionsLength = (versions?.length ?? 0) > 1

      const filteredVersionChangeState =
        isChangesMadeTab || hasCorrectVersionsLength ? versionChangeState : undefined

      const isDeletedVersionDocument = filteredVersionChangeState === 'DELETED'
      const isFixedVersionDocument = filteredVersionChangeState === 'FIXED'

      const showVersionChangeState =
        (isChangesMadeTab || versionState.versionNumber > 1) &&
        !!filteredVersionChangeState &&
        !isFixedVersionDocument &&
        !isDeletedVersionDocument

      return {
        sizeClassName: cn({
          [trailingNodeStyles['trailingNode__item-size--small']]:
            isDepartmentResponseSigned || showVersionChangeState,
        }),
        trailingNode: <TrailingAdditionInTrailingNode version={version} />,
        additionalTrailingNode: isDeletedVersionDocument && (
          <Typography.Body variant="bodySMedium" color={'text-base-tertiary'}>
            Документ был удален инвестором
          </Typography.Body>
        ),
        leadingNode: !isDeletedVersionDocument && (
          <LeadingAdditionInTrailingNode
            readOnly={isStatic && isNull(foundAttachmentFileItem.documentInfo?.isApproved)}
            isDepartmentResponseSigned={isDepartmentResponseSigned}
            versionChangeState={versionChangeState}
            versionNumber={versionState.versionNumber}
          />
        ),
      }
    },
    [
      conditionToSignedMarkRender,
      documents,
      isChangesMadeTab,
      isStatic,
      versionState.versionNumber,
      versions?.length,
    ],
  )

  return (
    <FolderItem
      readOnly={isStatic}
      hasDocumentTemplateType={hasDocumentTemplateType}
      readOnlyDocuments={readOnlyDocuments}
      checkFileDownloadForbidden={handleCheckDeletedVersionDocument}
      trailingAdditionProps={renderTrailingAdditionProps}
      leadingAdditionProps={renderLeadingAdditionProps}
      folderClassName={cn(styles['folder-item'], styles['folder-item--multiple'])}
      dataTestId={`${documentType}.AttachmentFolderItem-folder`}
      folderState={{
        folderName: description,
        files: preparedFiles,
        isFold,
      }}
      downloadProps={{
        signConditionOfDocuments,
        onFileDownload: handleDownloadFile(),
        downloadTooltipContent: (file) => (
          <DownloadDocumentTooltipContent
            handleDownloadFile={(event) => handleDownloadFile({ event })(file)}
            handleDownloadFileWithStamps={(event) =>
              handleDownloadFile({ event, withSigned: true })(file)
            }
          />
        ),
      }}
      folderNameProps={{
        readOnlyFolderName: true,
      }}
      entityProps={{
        dataTestId: 'AttachmentFolderItem-attachment',
        className: styles['folder-item__entity-item'],
        onClick: handleOpenDocumentViewSidebar,
      }}
      signFileProps={{
        signFileTooltipContent: 'Добавить открепленную подпись',
        onFileDropSign: isShowSignButton ? handleDropSignFile : undefined,
      }}
      dropzoneProps={{
        ...baseDropzoneProps,
        onDrop: handleDropFileInFolder,
        onRejectDrop: handleRejectDropFileInFolder,
      }}
      onFoldUnfold={handleToggleFold}
      onFileRemove={handleDeleteFileFromFolderWithModal}
      onFileReplace={handleReplaceFileInFolder}
      onFileReload={handleReloadFileInFolder}
      onFileRemoveNotLoaded={handleRemoveNotLoadedFileInFolder}
    />
  )
}

export default AttachmentFolderItem
