import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof SummaryReportOfMonitoringResultsFormValues['2'],
  SummaryReportOfMonitoringResultsFormValues
> = {
  initiatorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { secondSectionValidationMap }
