import { getAmountValueFromStrWithoutRound } from '@components/NewDesign/AmountInput/utils'
import { parseDateString } from '@components/NewDesign/DateInput/DateInput'
import { isNotEmptyString, isNullOrUndefined, isObject, isString } from '@helpers/checkTypes'
import DayjsService from '@services/Dayjs/Dayjs.service'
import plural from 'plural-ru'

export const Patterns = Object.freeze({
  Numbers: /^\d+$/,
  PositiveNumbers: /[^0-9.]/,
  Capital: /^[1-9]([ ]\d*)*/,
  Currency: /^(-?)(\s|[0-9])*(\.\d\d?)?$/,
  Phone: /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g,
  Password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{30}$/,
  // inputmask принимает только строку в качестве регулярки
  time: '([01][0-9]|2[0-3]):([0-5][0-9])',
  Email:
    /^(?:[a-zA-Zа-яA-Я0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Zа-яA-Я0-9!#$%&'*+\/=?^_`{|}~-]+)*)@(?:(?:[a-zA-Zа-яA-Я0-9](?:[a-zA-Zа-яA-Я0-9-]*[a-zA-Zа-яA-Я0-9])?\.)+[a-zA-Zа-яA-Я0-9](?:[a-zA-Zа-яA-Я0-9-]*[a-zA-Zа-яA-Я0-9])?)$/,
  TruthlyString: /^[^«»<>|?*/\\:]*$/,
  /**
   * пример валид: 12.12.1900
   * пример невалид: 100.13.2100
   */
  DayMonthYear: /(0?[1-9]|[12][0-9]|3[01])[\/\-\.](0?[1-9]|1[012])[ \/\.\-]((19|20)\d\d)/,
})

export const NewPatterns = Object.freeze({
  Password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{16,30}$/,
})

export const defaultRHFValidation = Object.freeze({
  required: {
    value: true,
    message: 'обязательное поле',
  },
  phone: {
    value: Patterns.Phone,
    message: 'некорректный номер',
  },
  email: {
    value: Patterns.Email,
    message: 'электронная почта не соответствует формату',
  },
  capital: {
    value: Patterns.Capital,
    message: 'сумма должна быть больше нуля',
  },
})

export const currencyIsValidate = async (value: string | number) => {
  const valueToString = String(value)

  const numValue = +valueToString.replace(/\s/g, '').replace(/[^-0-9|.}]/g, '')

  if (numValue < 0)
    return -50000000000000.0 < numValue || 'значение должно быть больше -50 000 000 000 000.00'

  return 50000000000000.0 > numValue || 'значение должно быть меньше 50 000 000 000 000.00'
}

export const bigIntIsValidate = async (value: unknown) => {
  if (isNullOrUndefined(value) || isObject(value)) return

  const valueToString = String(value)

  return (
    9223372036854775807n >= BigInt(valueToString.replace(/\s/g, '').replace(/[^0-9]/g, '')) ||
    'значение не должно превышать максимально допустимое (9223372036854775807)'
  )
}

export const amountValidate = (value: string) => {
  const maxValue = 49999999999999.99

  const numberValue = getAmountValueFromStrWithoutRound(value)

  if (!numberValue) return

  return (
    maxValue >= numberValue ||
    'значение не должно превышать  максимально допустимое (49 999 999 999 999.99)'
  )
}

export const specificIntValidate = (value: string) => {
  const maxValue = 2147483647

  const numberValue = getAmountValueFromStrWithoutRound(value)

  if (!numberValue) return

  return (
    numberValue <= maxValue || 'значение не должно превышать  максимально допустимое (2147483647)'
  )
}

export const standardAmountValidate = (value: string) => {
  const maxValue = 15000000000000.0

  const numberValue = getAmountValueFromStrWithoutRound(value)

  if (!numberValue) return

  return (
    maxValue >= numberValue ||
    'значение не должно превышать  максимально допустимое (15 000 000 000 000.00)'
  )
}

export const billionIntValidate = (value: string, minority = 2) => {
  const maxValue = 15000.0

  const zeroRenderAfterComma: string = (new Array(minority) as string[])
    .fill('0', 0, minority)
    .join('')

  const numberValue = getAmountValueFromStrWithoutRound(value)

  if (!numberValue) return

  return (
    maxValue >= numberValue ||
    `значение не должно превышать максимально допустимое (15 000.${zeroRenderAfterComma})`
  )
}

export const millionIntValidate = (value: string, minority = 2) => {
  const maxValue = 15000000.0

  const zeroRenderAfterComma: string = (new Array(minority) as string[])
    .fill('0', 0, minority)
    .join('')

  const numberValue = getAmountValueFromStrWithoutRound(value)

  if (!numberValue) return

  return (
    maxValue >= numberValue ||
    `значение не должно превышать  максимально допустимое (15 000 000.${zeroRenderAfterComma})`
  )
}

export const bigIntValidateWithNegative = async (value: string | number) => {
  const valueToString = String(value)

  const bigIntValue = BigInt(valueToString.replace(/\s/g, '').replace(/[^-0-9]/g, ''))

  if (bigIntValue < 0)
    return (
      -999999999999999999n <= bigIntValue ||
      'значение не должно быть меньше, чем минимально допустимое (-9223372036854775807)'
    )

  return (
    99999999999999999n >= bigIntValue ||
    'значение не должно превышать максимально допустимое (9 223 372 036 854 775 807)'
  )
}

export const lengthValidate = (
  value: string,
  length: number,
  type: 'string' | 'mailString' | 'folder' = 'string',
) => {
  if (!value) return

  const isValidateValue = value.length <= length

  switch (type) {
    case 'string':
      return (
        isValidateValue ||
        `значение не должно превышать ${length + plural(length, ' знак', ' знака', ' знаков')}`
      )
    case 'mailString':
      return (
        isValidateValue ||
        `электронная почта не соответствует формату или длина значения превышает 500 ${plural(
          length,
          ' символа',
          ' символов',
          ' символов',
        )}`
      )
    case 'folder':
      return isValidateValue || `название папки может включать в себя не более ${length} символов.`
  }
}

export const trimmedValueValidate = (value: unknown) => {
  if (!isString(value)) return

  return isNotEmptyString(value.trim()) || defaultRHFValidation.required.message
}

export const currentDateValidate = (value: string, message: string) => {
  if (!parseDateString(value).isAfter(DayjsService.dayjsWithFormatToMSK())) {
    return
  }
  return message
}
