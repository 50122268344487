import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NoticeIntentionTerminateSZPKFormValues } from '@components/Forms/NoticeIntentionTerminateSZPKForm/types'

const generateRHFObjectForNoticeIntentionTerminateSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): NoticeIntentionTerminateSZPKFormValues => {
  return {
    '1': RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    '2': RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    '3': {
      ruleLinkAgreementTermination:
        objectFromPropertyState.ruleLinkAgreementTermination?.value || '',
    },
  } as NoticeIntentionTerminateSZPKFormValues
}

export { generateRHFObjectForNoticeIntentionTerminateSZPKForm }
