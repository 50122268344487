import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultSummaryReportOfMonitoringResultsValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/const'
import SummaryReportOfMonitoringResultsLayout from '@components/Forms/SummaryReportOfMonitoringResultsForm/Layout'

const SummaryReportOfMonitoringResultsLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultSummaryReportOfMonitoringResultsValues}>
      <SummaryReportOfMonitoringResultsLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(SummaryReportOfMonitoringResultsLayoutWithFormProvider)
