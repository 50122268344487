import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useFormContext, useFormState } from 'react-hook-form'

import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useApplyErrorsFromViolations } from '@components/DocumentFormComponents/hooks/useApplyErrorsFromViolations'
import { useFindFormValueDifferences } from '@components/DocumentFormComponents/hooks/useFindFormValueDifferences'
import { useFormActions } from '@components/DocumentFormComponents/hooks/useFormActions'
import { useFormChanges } from '@components/DocumentFormComponents/hooks/useFormChanges'
import { useFormMount } from '@components/DocumentFormComponents/hooks/useFormMount'
import { useFormPreviousValue } from '@components/DocumentFormComponents/hooks/useFormPreviousValue'
import { useFormPropertyRefs } from '@components/DocumentFormComponents/hooks/useFormPropertyRefs'
import { useFormRegions } from '@components/DocumentFormComponents/hooks/useFormRegions'
import { useFormSubscribableControl } from '@components/DocumentFormComponents/hooks/useFormSubscribableControl'
import { useFormUpdate } from '@components/DocumentFormComponents/hooks/useFormUpdate'
import { useGenerateValuesFromObjectAdapter } from '@components/DocumentFormComponents/hooks/useGenerateValuesFromObjectAdapter'
import { SuccessInitialCallbackReturn } from '@components/DocumentFormComponents/hooks/useInitializeForm'
import { useNestedMenuFormErrors } from '@components/DocumentFormComponents/hooks/useNestedMenuFormErrors'
import { useNotifyAllSubscribers } from '@components/DocumentFormComponents/hooks/useNotifyAllSubscribers'
import { useOverrideFormProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { useNestedMenuManager as useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import {
  FormModalContextProps,
  FormModalManagerProps,
} from '@components/DocumentFormComponents/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAAdapters } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/adapters'
import {
  aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues,
  rewriteAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsAfterApplyDiffs,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { getAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatus } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Menu/const'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { isSelectOption } from '@components/NewDesign/Select/helpers'
import { isArray, isNull, isNumber, isString } from '@helpers/checkTypes'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { ApplyChangesReturn } from '@services/Properties/OOP/Property'
import cloneDeep from 'clone-deep'
import { nanoid } from 'nanoid'

const { getDefaultFormModalContextValue } = DocumentFormHelpers

export const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalContext = createContext<
  FormModalContextProps<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
>(
  getDefaultFormModalContextValue<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>(),
)

const useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager = () => {
  return useContext(AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalContext)
}

const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalManager: FC<
  FormModalManagerProps
> = ({
  projectId,
  formId,
  editMode,
  initialErrorsFromViolations,
  onSetLastUpdateFormToNow,
  onClose,
  children,
}) => {
  const {
    propertiesPropsRef,
    propertyInstanceRef,
    lastFieldUpdateTime,
    getPropertiesProps,
    getLastFieldUpdateTime,
    getPropertyInstance,
  } = useFormPropertyRefs()

  //Инициализация регионов для селектов
  useFormRegions(projectId)

  const {
    updateLastRFHBeforeValue,
    getLastRHFBeforeValue,
    getLastFormModifierBeforeValue,
    updateLastFormModifierBeforeValue,
  } = useFormPreviousValue<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues
  >()

  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()
  const { setValue, getValues, reset: resetForm, clearErrors } = formInstance
  const { errors } = useFormState({
    control: formInstance?.control,
  })

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  const {
    reset: resetModifierForm,
    getValues: getValuesModifier,
    setValue: setValueModifier,
  } = formModifierInstance

  const { processOverrideProps, getOverrideProps } = useOverrideFormProps({
    formId,
    projectId,
  })

  const { formIsLoading, handleMountForm } = useFormMount(formId)

  const { formErrorsRef } = useNestedMenuFormErrors(errors)

  const {
    handlers: { handleRecalculateFormMenu },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuManager()

  const {
    generatePropertiesObject,
    generateRHFObject,
    generateMenuListByFormValues,
    generateOverrideProps,
    generatePrefetchedPropsArray,
    generateFormModifierValues,
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAAdapters()

  const { getBasePropsFromObjectAdapter, getAdvancedPropsFromObjectAdapter } =
    useGenerateValuesFromObjectAdapter({
      generateRHFObject,
      generatePropertiesObject,
      generateOverrideProps,
      generatePrefetchedPropsArray,
    })

  const { subscribableControl } = useFormSubscribableControl({
    formInstance,
    getPropertiesProps,
  })

  const { handleNotifyAllSubscribersWithAdvancedProps } = useNotifyAllSubscribers({
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
    getPropertyInstance,
    processOverrideProps,
  })

  const { applyDifferences: applyModifierDifferences } =
    useFindFormValueDifferences<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>(
      {
        watcher:
          AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
        setValue: setValueModifier,
      },
    )

  const { applyDifferences } = useFindFormValueDifferences({
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
    setValue,
  })

  const handleApplyChanges = useCallback(
    async (props: ApplyChangesReturn) => {
      const prevRHFValue = getLastRHFBeforeValue()
      const prevModifierValue = getLastFormModifierBeforeValue()

      if (isNull(prevRHFValue) || isNull(prevModifierValue)) return

      lastFieldUpdateTime.current = props.lastUpdateDt

      const stepActivityOthers = getValues(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['8'].stepActivityOthers,
      )

      const { customProps, prefetchedIds } = await getAdvancedPropsFromObjectAdapter(
        props.objectForAdapter,
      )

      const readyOverrideProps = await processOverrideProps(
        [...props.overridePropsFromChanges, ...customProps],
        prefetchedIds,
      )

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(
        props.objectForAdapter,
      )

      const formModifierValues = generateFormModifierValues(props.objectForAdapter)

      propertiesPropsRef.current = cloneDeep(propertiesProps)

      const preparedRHFValue: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues = {
        ...RHFValueForReset,
        ['8']: {
          ...RHFValueForReset['8'],
          stepActivityOthers: RHFValueForReset['8'].stepActivityOthers.map((step, indexOfStep) => ({
            ...step,
            activityOthers:
              step.activityOthers?.map((activity, indexOfActivity) => ({
                ...activity,
                frontId:
                  stepActivityOthers?.[indexOfStep]?.activityOthers?.[indexOfActivity]?.frontId ||
                  nanoid(),
              })) || [],
          })),
        },
      }

      unstable_batchedUpdates(() => {
        applyDifferences(prevRHFValue, RHFValueForReset)
        applyModifierDifferences(prevModifierValue, formModifierValues)

        rewriteAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsAfterApplyDiffs.forEach(
          (rewriteField) => {
            const valueToApply = getObjectValue(preparedRHFValue, rewriteField)

            if (
              isNumber(valueToApply) ||
              isString(valueToApply) ||
              isArray(valueToApply) ||
              isSelectOption(valueToApply)
            ) {
              setValue(rewriteField, valueToApply)
            }
          },
        )

        updateLastRFHBeforeValue(preparedRHFValue)
        updateLastFormModifierBeforeValue(formModifierValues)

        setTimeout(() => {
          AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher.notifyAllSubscribers(
            readyOverrideProps,
          )
        }, 0)

        onSetLastUpdateFormToNow?.()
      })
    },
    [
      getLastRHFBeforeValue,
      getLastFormModifierBeforeValue,
      lastFieldUpdateTime,
      getValues,
      getAdvancedPropsFromObjectAdapter,
      processOverrideProps,
      getBasePropsFromObjectAdapter,
      generateFormModifierValues,
      propertiesPropsRef,
      applyDifferences,
      applyModifierDifferences,
      updateLastRFHBeforeValue,
      updateLastFormModifierBeforeValue,
      onSetLastUpdateFormToNow,
      setValue,
    ],
  )

  const handleRecalculateMenu = useCallback(() => {
    const formValues = getValues()
    const formModifierValues = getValuesModifier()

    const preparedMenuList = generateMenuListByFormValues(
      formValues,
      cloneDeep(
        getAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatus(
          formValues.additionalFields.isFederal,
          !!formValues.additionalFields.isMunicipalityParticipant,
        ).initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap,
      ),
      formErrorsRef.current,
      formModifierValues,
    )

    handleRecalculateFormMenu?.(preparedMenuList)
  }, [
    getValues,
    getValuesModifier,
    generateMenuListByFormValues,
    formErrorsRef,
    handleRecalculateFormMenu,
  ])

  const handleApplyChangesWithMenu = useCallback(
    async (props: ApplyChangesReturn) => {
      await handleApplyChanges(props)

      handleRecalculateMenu()
    },
    [handleApplyChanges, handleRecalculateMenu],
  )

  const { checkFormChanges, applyFormChanges } = useFormChanges({
    formId,
    getLastFieldUpdateTime,
    propertyInstance: propertyInstanceRef.current,
  })

  const wrappedHandleApplyFormChangesWithMenu = useMemo(
    () => applyFormChanges(handleApplyChangesWithMenu),
    [applyFormChanges, handleApplyChangesWithMenu],
  )

  const {
    handleAddItemToListWithValue,
    handleRemoveItemFromList,
    handleAddItemToListWithOutValue,
    handleChangeValue,
    handleAddCustomValue,
    handleRemoveCustomValue,
    debouncedHandleChangeValue,
    handleUpElementInList,
    handleDownElementInList,
  } = useFormUpdate({
    formId,
    getPropertiesProps,
    getRHFValueBeforeUpdate: getLastRHFBeforeValue,
    applyFormChanges: wrappedHandleApplyFormChangesWithMenu,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { blockViewIsValidating, handleChangeBlockValidation } = useApplyErrorsFromViolations({
    formIsLoading,
    applyErrorsFromViolations,
    formInstance,
    initialErrorsFromViolations,
  })

  const wrappedHandleApplyFormChanges = useMemo(
    () => applyFormChanges(handleApplyChanges),
    [applyFormChanges, handleApplyChanges],
  )

  const { handleCheckForm, handlePreviewForm } = useFormActions({
    formId,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
    onChangeBlockValidation: handleChangeBlockValidation,
    handleApplyChanges: wrappedHandleApplyFormChanges,
    onErrorCheckForm: handleRecalculateMenu,
    onClearError: clearErrors,
  })

  const handleSuccessInitializeForm = useCallback(
    async ({ lastChangesDt, propertyInstance }: SuccessInitialCallbackReturn) => {
      lastFieldUpdateTime.current = lastChangesDt

      propertyInstanceRef.current = propertyInstance

      const objectForAdapters = propertyInstance.generateObjectForAdapterFromPropertiesState()

      const { propertiesProps, RHFValueForReset } = getBasePropsFromObjectAdapter(objectForAdapters)

      const formModifierValues = generateFormModifierValues(objectForAdapters)

      const preparedMenuList = generateMenuListByFormValues(
        RHFValueForReset,
        cloneDeep(
          getAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuByStatus(
            RHFValueForReset.additionalFields.isFederal,
            RHFValueForReset.additionalFields.isMunicipalityParticipant,
          ).initialAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenuHashMap,
        ),
        errors,
        formModifierValues,
      )

      const preparedRHFValue: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues = {
        ...RHFValueForReset,
        ['8']: {
          ...RHFValueForReset['8'],
          stepActivityOthers: RHFValueForReset['8'].stepActivityOthers.map((step) => ({
            ...step,
            activityOthers:
              step.activityOthers?.map((activity) => ({
                ...activity,
                frontId: nanoid(),
              })) || [],
          })),
        },
      }

      //Установка propertiesProps
      propertiesPropsRef.current = cloneDeep(propertiesProps)

      resetForm(preparedRHFValue)
      resetModifierForm(formModifierValues)
      updateLastRFHBeforeValue(preparedRHFValue)
      updateLastFormModifierBeforeValue(formModifierValues)

      handleRecalculateFormMenu?.(preparedMenuList)
    },
    [
      resetModifierForm,
      errors,
      lastFieldUpdateTime,
      propertiesPropsRef,
      propertyInstanceRef,
      resetForm,
      generateMenuListByFormValues,
      getBasePropsFromObjectAdapter,
      generateFormModifierValues,
      handleRecalculateFormMenu,
      updateLastRFHBeforeValue,
      updateLastFormModifierBeforeValue,
    ],
  )

  //Инициализация формы
  useEffect(() => {
    handleMountForm({
      onInitializeForm: handleSuccessInitializeForm,
      onAfterInitializeForm: handleNotifyAllSubscribersWithAdvancedProps(
        getAdvancedPropsFromObjectAdapter,
      ),
    })
  }, [])

  const preparedValue: FormModalContextProps<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues> =
    useMemo(
      () => ({
        state: {
          formIsLoading,
          blockViewIsValidating,
          initialErrorsFromViolations,
          editMode,
        },
        handlers: {
          checkFormChanges,
          getPropertiesProps,
          getOverrideProps,
          handleChangeValue,
          handleAddCustomValue,
          handleRemoveCustomValue,
          debouncedHandleChangeValue,
          handleAddItemToListWithOutValue,
          handleAddItemToListWithValue,
          handleUpElementInList,
          handleDownElementInList,
          handleRemoveItemFromList,
          applyFormChanges: wrappedHandleApplyFormChanges,
          handleChangeBlockValidation,
        },
        actions: {
          handleFormClose: onClose,
          handleCheckForm,
          handlePreviewForm,
        },
        preparedProps: {
          subscribableControl,
        },
      }),
      [
        formIsLoading,
        blockViewIsValidating,
        initialErrorsFromViolations,
        editMode,
        checkFormChanges,
        getPropertiesProps,
        getOverrideProps,
        handleChangeValue,
        handleAddCustomValue,
        handleRemoveCustomValue,
        debouncedHandleChangeValue,
        handleAddItemToListWithOutValue,
        handleAddItemToListWithValue,
        handleRemoveItemFromList,
        handleUpElementInList,
        handleDownElementInList,
        wrappedHandleApplyFormChanges,
        handleChangeBlockValidation,
        onClose,
        handleCheckForm,
        handlePreviewForm,
        subscribableControl,
      ],
    )

  return (
    <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalContext.Provider
      value={preparedValue}
    >
      {children}
    </AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalContext.Provider>
  )
}

export { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager }

export default AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModalManager
