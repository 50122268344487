import React, { FC, memo, useMemo } from 'react'
import { ReactSVG } from 'react-svg'

import AddStatusIcon from '@icons/documentStatus/AddStatus.svg'
import CheckingStatusIcon from '@icons/documentStatus/CheckingStatus.svg'
import ConcertStatusIcon from '@icons/documentStatus/ConcertStatus.svg'
import ConsiderStatusIcon from '@icons/documentStatus/ConsiderStatus.svg'
import DeniedStatusIcon from '@icons/documentStatus/DeniedStatus.svg'
import FixStatusIcon from '@icons/documentStatus/FixStatus.svg'
import ReadyServeStatusIcon from '@icons/documentStatus/ReadyServeStatus.svg'
import SentedStatusIcon from '@icons/documentStatus/SentedStatus.svg'
import cn from 'classnames'

import styles from './IconStatus.module.scss'

export interface IconStatusProps {
  status:
    | 'add'
    | 'concert'
    | 'checking'
    | 'denied'
    | 'consider'
    | 'readyServe'
    | 'sented'
    | 'fix'
    | 'warning'
    | 'error'
    | 'ok'
  className?: string
}

const IconStatus: FC<IconStatusProps> = ({ status, className }) => {
  const iconForStatus = useMemo(() => {
    switch (status) {
      case 'add':
        return {
          icon: AddStatusIcon,
          activeColor: 'accent-faint-brand-quaternary',
        }

      case 'ok':
      case 'concert':
        return {
          icon: ConcertStatusIcon,
          activeColor: 'accent-success-faint-quaternary',
        }

      case 'checking':
        return {
          icon: CheckingStatusIcon,
          activeColor: 'complimentary-orange-faint-quaternary',
        }

      case 'error':
      case 'denied':
        return {
          icon: DeniedStatusIcon,
          activeColor: 'accent-error-faint-quaternary',
        }

      case 'consider':
        return {
          icon: ConsiderStatusIcon,
          activeColor: 'accent-faint-brand-quaternary',
        }
      case 'readyServe':
        return {
          icon: ReadyServeStatusIcon,
          activeColor: 'accent-success-faint-quaternary',
        }
      case 'sented':
        return {
          icon: SentedStatusIcon,
          activeColor: 'complimentary-purple-faint-quaternary',
        }

      case 'warning':
      case 'fix':
        return {
          icon: FixStatusIcon,
          activeColor: 'accent-error-faint-quaternary',
        }

      default:
        return {
          icon: null,
          activeColor: 'on-surface-faint-tertiary',
        }
    }
  }, [status])

  return (
    <div
      className={cn(styles.iconStatus, styles[`color-${iconForStatus?.activeColor}`], className)}
    >
      <ReactSVG src={iconForStatus?.icon || ''} />
    </div>
  )
}

export default memo(IconStatus)
