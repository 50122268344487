import { hierarchyReferenceBookSelectManagerReducerActionMap } from './constants'
import type {
  HierarchyReferenceBookActionCreators,
  HierarchyReferenceBookSelectActions,
  HierarchyReferenceBookSelectManagerReducerState,
} from './types'

const initialHierarchyReferenceBookSelectManagerReducerState: HierarchyReferenceBookSelectManagerReducerState =
  {
    threeIsLoading: true,
    selectModalIsOpen: false,
    selectedItemId: null,
    three: new Map(),
    searchThree: null,
  }

const hierarchyReferenceBookSelectManagerReducer = (
  state: HierarchyReferenceBookSelectManagerReducerState,
  action: HierarchyReferenceBookSelectActions,
): HierarchyReferenceBookSelectManagerReducerState => {
  const { type } = action

  switch (type) {
    case hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_THREE_LOADING:
      return {
        ...state,
        threeIsLoading: action.payload,
      }

    case hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_MODAL_OPEN:
      return {
        ...state,
        selectModalIsOpen: action.payload,
      }

    case hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_SELECTED_ITEM_ID:
      return {
        ...state,
        selectedItemId: action.payload,
      }

    case hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_THREE:
      return {
        ...state,
        three: action.payload,
      }

    case hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_SEARCH_THREE:
      return {
        ...state,
        searchThree: action.payload,
      }

    case hierarchyReferenceBookSelectManagerReducerActionMap.CLEAR_ALL_STATES:
      return initialHierarchyReferenceBookSelectManagerReducerState

    case hierarchyReferenceBookSelectManagerReducerActionMap.CLEAR_SELECTED_ITEM:
      return {
        ...state,
        selectedItemId: null,
        three: new Map(),
      }

    default:
      return state
  }
}

const hierarchyReferenceBookSelectCreators: HierarchyReferenceBookActionCreators = {
  clearAllStates: () => ({
    type: hierarchyReferenceBookSelectManagerReducerActionMap.CLEAR_ALL_STATES,
  }),
  clearSelectedItem: () => ({
    type: hierarchyReferenceBookSelectManagerReducerActionMap.CLEAR_SELECTED_ITEM,
  }),
  setThreeLoading: (payload) => ({
    type: hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_THREE_LOADING,
    payload,
  }),
  setSelectModalOpen: (payload) => ({
    type: hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_MODAL_OPEN,
    payload,
  }),
  setSelectedItemId: (payload) => ({
    type: hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_SELECTED_ITEM_ID,
    payload,
  }),
  setThree: (payload) => ({
    type: hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_THREE,
    payload,
  }),
  setSearchThree: (payload) => ({
    type: hierarchyReferenceBookSelectManagerReducerActionMap.SETUP_SEARCH_THREE,
    payload,
  }),
}

export {
  hierarchyReferenceBookSelectCreators,
  hierarchyReferenceBookSelectManagerReducer,
  initialHierarchyReferenceBookSelectManagerReducerState,
}
