import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
  IConstructionRegistration,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const eRegistrationFormItemAAgreementOnChangingCharacteristicsOfObjectsValidationMap: FormValuesValidationSection<
  keyof IConstructionRegistration,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues
> = {
  objectType: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 500),
  },
  objectName: {
    required: defaultRHFValidation.required,
  },
  estateObjectStateRegistrationDate: {
    required: defaultRHFValidation.required,
  },
  estateObjectStateRegistrationNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  estateObjectStateRegistrationOrganization: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
}

export { eRegistrationFormItemAAgreementOnChangingCharacteristicsOfObjectsValidationMap }
