import React, { memo, useMemo, useReducer, useRef } from 'react'
import { type FileRejection, DropEvent, DropzoneRef } from 'react-dropzone'
import { FieldValues } from 'react-hook-form'

import FileItem from '@components/Attachments/Attachment/FileItem'
import { FileItemService } from '@components/Attachments/Attachment/FileItem/helpers'
import type {
  FileItemLeadingAdditionProps,
  FileItemTrailingAdditionProps,
  RejectedFile,
} from '@components/Attachments/Attachment/FileItem/types'
import FolderItem from '@components/Attachments/Attachment/FolderItem'
import { FolderItemService } from '@components/Attachments/Attachment/FolderItem/helpers'
import type {
  FolderFileItem,
  FolderNameProps,
} from '@components/Attachments/Attachment/FolderItem/types'
import { useDropRejectedFile } from '@components/Attachments/Attachment/hooks/useDropRejectedFile'
import items from '@components/Attachments/Items'
import FileDropzone from '@components/FileDropzone'
import Button from '@components/NewDesign/Button'
import Typography from '@components/NewDesign/Typography'
import { rejectedFileErrorCodesMap } from '@constants/documents'
import { MimeTypes } from '@constants/types'
import { filterArrayIntoTwoChunks } from '@helpers/array/filterArrayIntoChunks'
import CircleAddIcon from '@icons/AddCircleIcon.svg'
import FolderIcon from '@icons/FolderIcon.svg'

import { MultipleItemService } from './helpers'
import styles from './MultipleItem.module.scss'
import { initialMultipleItemState, multipleItemCreators, multipleItemReducer } from './reducer'
import {
  CombinedMultipleItem,
  EntityMultipleItemProps,
  MultipleFileItem,
  MultipleFolderItem,
  MultipleItemDefaultPayloadProps,
  MultipleItemDownloadProps,
  MultipleItemDropzoneProps,
  MultipleItemReducerState,
  MultipleItemSignFileProps,
} from './types'

interface MultipleItemProps<FormValues extends FieldValues> {
  multipleItemsState?: MultipleItemReducerState
  title?: string
  isDownloadForbidden?: boolean
  readOnly?: boolean
  hasDocumentTemplateType?: boolean
  readOnlyItems?: (boolean | boolean[])[]
  dataTestId?: string
  folderDataTestId?: string
  folderNameProps?: (item: MultipleFolderItem) => FolderNameProps<FormValues> | undefined
  dropzoneProps?: MultipleItemDropzoneProps
  downloadProps?: MultipleItemDownloadProps
  signFileProps?: MultipleItemSignFileProps
  entityProps?: EntityMultipleItemProps
  checkMultipleItemDownloadForbidden?: (
    item: CombinedMultipleItem,
    folderFileItem?: FolderFileItem,
  ) => boolean
  leadingAdditionProps?: (
    item: CombinedMultipleItem,
    folderFileItem?: FolderFileItem,
  ) => FileItemLeadingAdditionProps | undefined
  trailingAdditionProps?: (
    item: CombinedMultipleItem,
    folderFileItem?: FolderFileItem,
  ) => FileItemTrailingAdditionProps | undefined
  onLoadFiles?: () => Promise<void>
  onFileRemove?: (item: CombinedMultipleItem) => (folderFileItem?: FolderFileItem) => Promise<void>
  onFoldUnfold?: (item: MultipleFolderItem) => VoidFunction
  onFileReplace?: (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) => void
  onFileReload?: (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) => Promise<void>
  onFileRemoveNotLoaded?: (item: CombinedMultipleItem, folderFileItem?: FolderFileItem) => void
  onFolderAdd?: () => Promise<void>
  onFolderRename?: (
    item: MultipleFolderItem,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  onFolderRemove?: (item: MultipleFolderItem) => () => Promise<void>
}

const { convertRejectedFile, convertMultipleRejectedFile } = FileItemService
const { isMultipleFileItem, getMultipleItemIndexById, createFolder } = MultipleItemService
const { createLoadingFolderFileItem, getFileIndexById } = FolderItemService

const MultipleItem = <FormValues extends FieldValues>({
  multipleItemsState,
  title,
  isDownloadForbidden,
  readOnly,
  hasDocumentTemplateType,
  readOnlyItems,
  dataTestId,
  folderDataTestId,
  leadingAdditionProps,
  folderNameProps,
  entityProps,
  dropzoneProps,
  downloadProps,
  signFileProps,
  trailingAdditionProps,
  checkMultipleItemDownloadForbidden,
  onLoadFiles,
  onFileReload,
  onFileRemove,
  onFileRemoveNotLoaded,
  onFoldUnfold,
  onFileReplace,
  onFolderAdd,
  onFolderRename,
  onFolderRemove,
}: MultipleItemProps<FormValues>) => {
  const {
    multiple = false,
    accept = MimeTypes,
    onFileDrop,
    onRejectFileDrop,
    ...restDropzoneProps
  } = dropzoneProps || {}
  const {
    onClick: entityOnClick,
    dataTestId: entityDataTestId,
    ...restEntityProps
  } = entityProps || {}
  const { onFileDownload, downloadTooltipContent, signConditionOfDocuments } = downloadProps || {}
  const { signFileTooltipContent, onFileDropSign } = signFileProps || {}

  const [state, dispatch] = useReducer(multipleItemReducer, initialMultipleItemState)
  const dropzoneRef = useRef<DropzoneRef | null>(null)
  const replacedStateRef = useRef<MultipleItemDefaultPayloadProps | null>(null)

  const { rejectedFile, handleClose, handleRejectedFiles } = useDropRejectedFile<RejectedFile>()

  const uncontrolledItems = !items
  const currentState = multipleItemsState ?? state

  const handleAddFileFromAction = () => {
    dropzoneRef.current?.open()
  }

  const handleDropRejected = (fileRejections: FileRejection[], files?: File[]) => {
    let rejectedFile: RejectedFile | null = null

    if (
      fileRejections.length > 1 ||
      !!files?.length ||
      (fileRejections.length && !!files?.length)
    ) {
      rejectedFile = convertMultipleRejectedFile(files?.[0] || fileRejections[0].file)
    } else {
      rejectedFile = convertRejectedFile(fileRejections[0])
    }

    handleRejectedFiles(rejectedFile)
  }

  const handleLoadFile = async (file: File, item?: MultipleFolderItem) => {
    let folderId = ''
    let fileId = ''

    if (!replacedStateRef.current) {
      const fileToUpload = createLoadingFolderFileItem({
        file,
        rejectedFile: null,
      })
      fileId = fileToUpload.id
      folderId = item?.id || ''

      dispatch(
        multipleItemCreators.addFile({
          folderId,
          fileState: {
            id: fileId,
            ...fileToUpload.fileState,
          },
        }),
      )
    } else {
      fileId = replacedStateRef.current.fileId
      folderId = replacedStateRef.current.folderId || ''

      dispatch(
        multipleItemCreators.replaceItem({
          type: 'file',
          fileId: replacedStateRef.current.fileId,
          folderId: replacedStateRef.current.folderId,
          newFileState: {
            file,
            isLoading: true,
            errorMessage: null,
          },
        }),
      )
    }

    try {
      await onLoadFiles?.()
    } catch (error) {
      dispatch(
        multipleItemCreators.setFileError({
          fileId,
          folderId,
          error,
        }),
      )
      throw error
    } finally {
      dispatch(
        multipleItemCreators.setFileLoading({
          fileId,
          folderId,
          isLoading: false,
        }),
      )
    }
  }

  const handleDropFile = (item?: MultipleFolderItem) => async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    await handleLoadFile(acceptedFiles[0], item)
  }

  // TODO: написать функционал добавления файла
  const handleRejectDropFile =
    (item?: CombinedMultipleItem) =>
    (folderFileItem?: FolderFileItem) =>
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {}

  const handleReloadFile =
    (item: CombinedMultipleItem) => async (folderFileItem?: FolderFileItem) => {
      const folderId = isMultipleFileItem(item) ? '' : item.id
      const fileId = isMultipleFileItem(item) ? item.id : folderFileItem?.id || ''

      dispatch(
        multipleItemCreators.setFileError({
          folderId,
          fileId,
          error: null,
        }),
      )

      dispatch(
        multipleItemCreators.setFileLoading({
          folderId,
          fileId,
          isLoading: true,
        }),
      )

      try {
        await onFileReload?.(item, folderFileItem)
      } catch (error) {
        dispatch(
          multipleItemCreators.setFileError({
            folderId,
            fileId,
            error,
          }),
        )
        throw error
      } finally {
        dispatch(
          multipleItemCreators.setFileLoading({
            folderId,
            fileId,
            isLoading: false,
          }),
        )
      }
    }

  const handleRemoveFile =
    (item: CombinedMultipleItem) => async (folderFileItem?: FolderFileItem) => {
      const folderId = isMultipleFileItem(item) ? '' : item.id
      const fileId = isMultipleFileItem(item) ? item.id : folderFileItem?.id || ''

      const currentFileState = isMultipleFileItem(item) ? item : folderFileItem?.fileState
      const currentIndexOfInsert = isMultipleFileItem(item)
        ? getMultipleItemIndexById(item.id, state.items)
        : (() => {
            if (!folderFileItem) return -1

            const indexOfFolder = getMultipleItemIndexById(item.id, state.items)
            const filesInFolder = (state.items[indexOfFolder] as MultipleFolderItem)?.files

            if (!filesInFolder) return -1

            return getFileIndexById(folderFileItem?.id, filesInFolder)
          })()

      dispatch(
        multipleItemCreators.removeFile({
          folderId,
          fileId,
        }),
      )

      try {
        await onFileRemove?.(item)(folderFileItem)
      } catch (error) {
        if (!currentFileState || currentIndexOfInsert === -1) throw error

        dispatch(
          multipleItemCreators.insertItem({
            type: 'file',
            folderId,
            file: {
              id: fileId,
              ...currentFileState,
            },
            indexOfInsert: currentIndexOfInsert,
          }),
        )

        throw error
      }
    }

  const handleFileReplace = (item: CombinedMultipleItem) => (folderFileItem?: FolderFileItem) => {
    const folderId = isMultipleFileItem(item) ? '' : item.id
    const fileId = isMultipleFileItem(item) ? item.id : folderFileItem?.id || ''

    try {
      onFileReplace?.(item, folderFileItem)

      replacedStateRef.current = {
        fileId,
        folderId,
      }
    } catch (error) {
      throw error
    }
  }

  const handleFileDownload =
    (item: CombinedMultipleItem) => async (folderFileItem?: FolderFileItem) => {
      await onFileDownload?.(item, folderFileItem)
    }

  const handleFolderAdd = async () => {
    const createdFolder = createFolder()

    dispatch(
      multipleItemCreators.addFolder({
        folder: createdFolder,
      }),
    )

    try {
      await onFolderAdd?.()
    } catch (e) {
      dispatch(
        multipleItemCreators.removeFolder({
          folderId: createdFolder.id,
        }),
      )
      throw e
    }
  }

  const handleFolderRemove = (item: MultipleFolderItem) => async () => {
    const removedFolderIndex = getMultipleItemIndexById(item.id, state.items)
    const removedFolder = state.items[removedFolderIndex] as MultipleFolderItem

    dispatch(
      multipleItemCreators.removeFolder({
        folderId: item.id,
      }),
    )

    try {
      await onFolderRemove?.(item)()
    } catch (e) {
      if (removedFolderIndex === -1) throw e

      dispatch(
        multipleItemCreators.insertItem({
          type: 'folder',
          indexOfInsert: removedFolderIndex,
          folder: removedFolder,
        }),
      )
      throw e
    }
  }
  const handleFoldUnfoldFolder = (item: MultipleFolderItem) => () => {
    dispatch(
      multipleItemCreators.setFoldFolderState({
        folderId: item.id,
        isFold: (prevState) => !prevState,
      }),
    )
  }

  const handleFolderRename =
    (item: MultipleFolderItem) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value

      if (!newName.trim().length) return

      dispatch(
        multipleItemCreators.setFolderName({
          newName,
          folderId: item.id,
        }),
      )
    }

  const currentFolderAddHandler = uncontrolledItems ? handleFolderAdd : onFolderAdd

  const currentFolderRemoveHandler = uncontrolledItems
    ? handleFolderRemove
    : (item: MultipleFolderItem) => onFolderRemove?.(item)

  const currentFolderRenameHandler = uncontrolledItems
    ? handleFolderRename
    : (item: MultipleFolderItem) => onFolderRename?.(item)

  const currentFoldUnfoldFolderHandler = uncontrolledItems
    ? handleFoldUnfoldFolder
    : (item: MultipleFolderItem) => onFoldUnfold?.(item)

  const currentDropFileHandler =
    (item?: MultipleFolderItem) =>
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      if (uncontrolledItems) {
        return handleDropFile(item)(acceptedFiles)
      }

      return onFileDrop?.(item)?.(acceptedFiles, fileRejections, event)
    }

  const handleDropFileSecondary = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    if (fileRejections.length || acceptedFiles.length > 1) {
      return handleDropRejected(fileRejections, acceptedFiles)
    }

    handleClose()

    currentDropFileHandler()(acceptedFiles, fileRejections, event)
  }

  const currentRejectDropFileHandler = uncontrolledItems
    ? handleRejectDropFile
    : (item: CombinedMultipleItem) => (folderFileItem?: FolderFileItem) =>
        onRejectFileDrop?.(item, folderFileItem)

  const currentRemoveFileHandler = uncontrolledItems
    ? handleRemoveFile
    : (item: CombinedMultipleItem) => onFileRemove?.(item)

  const currentReloadFileHandler = uncontrolledItems
    ? handleReloadFile
    : (item: CombinedMultipleItem) => async (folderFileItem?: FolderFileItem) =>
        onFileReload?.(item, folderFileItem)

  const currentNotLoadedFileHandler =
    (item: CombinedMultipleItem) => (folderFileItem?: FolderFileItem) =>
      onFileRemoveNotLoaded?.(item, folderFileItem)

  const conditionToMultipleItemRender = useMemo(() => {
    const [folderItems, fileItems] = filterArrayIntoTwoChunks(
      currentState.items,
      (item) => !isMultipleFileItem(item),
    ) as [MultipleFolderItem[], MultipleFileItem[]]

    const hasFilesInFolder = folderItems.some((folderItem) => folderItem.files?.length)

    return fileItems.length || hasFilesInFolder
  }, [currentState.items])

  if (readOnly && !conditionToMultipleItemRender) return null

  return (
    <div className={styles.multipleItem} data-testid={dataTestId}>
      <div className={styles.multipleItem__header}>
        <Typography.Body
          variant={'bodyLMedium'}
          dataTestId={dataTestId && `${dataTestId}-title`}
          color={'text-base-secondary'}
        >
          {title}
        </Typography.Body>
        {!readOnly && !isDownloadForbidden && (
          <div className={styles.multipleItem__actions}>
            <Button
              size={'s'}
              view={'plain'}
              geometry={'round'}
              dataTestId={dataTestId && `${dataTestId}-action-addFile`}
              leadingIcon={{ src: CircleAddIcon }}
              onClick={handleAddFileFromAction}
            >
              Добавить файл
            </Button>
            <Button
              size={'s'}
              view={'plain'}
              geometry={'round'}
              dataTestId={dataTestId && `${dataTestId}-action-addFolder`}
              leadingIcon={{ src: FolderIcon }}
              onClick={currentFolderAddHandler}
            >
              Создать папку
            </Button>
          </div>
        )}
      </div>
      <div className={styles.multipleItem__items}>
        {!currentState.items.length && readOnly && (
          <Typography.Body
            className={styles['multipleItem--empty']}
            color={'text-base-secondary'}
            variant={'bodySMedium'}
            dataTestId={dataTestId && `${dataTestId}-items-empty`}
          >
            Список документов пуст
          </Typography.Body>
        )}
        {!!currentState.items.length && (
          <ul className={styles['multipleItem__items-wrapper']}>
            {currentState.items.map((item, index) => {
              if (isMultipleFileItem(item)) {
                const readOnlyDocument = !!readOnlyItems?.[index]
                const signCondition = !!signConditionOfDocuments?.[index]

                return (
                  <li aria-hidden key={item.id}>
                    <FileItem
                      readOnly={readOnlyDocument}
                      hasDocumentTemplateType={hasDocumentTemplateType}
                      fileState={item}
                      trailingAdditionProps={trailingAdditionProps?.(item)}
                      leadingAdditionProps={leadingAdditionProps?.(item)}
                      isDownloadForbidden={checkMultipleItemDownloadForbidden?.(item)}
                      entityProps={{
                        ...restEntityProps,
                        dataTestId: entityDataTestId && `${item.id}.${entityDataTestId}`,
                        onClick: !item.errorMessage ? entityOnClick?.(item)() : undefined,
                      }}
                      downloadProps={{
                        signCondition,
                        downloadTooltipContent: downloadTooltipContent?.(item),
                        onFileDownload: onFileDownload && handleFileDownload(item),
                      }}
                      signFileProps={{
                        signFileTooltipContent,
                        onFileDropSign: onFileDropSign && onFileDropSign?.(item),
                      }}
                      dropzoneProps={{
                        ...dropzoneProps,
                        accept,
                        multiple,
                        onDrop: currentDropFileHandler(),
                        onRejectDrop: currentRejectDropFileHandler(item)(),
                      }}
                      onFileReplace={onFileReplace && handleFileReplace(item)}
                      onFileRemove={onFileRemove && currentRemoveFileHandler(item)}
                      onFileReload={currentReloadFileHandler(item)}
                      onFileNotLoadedRemove={currentNotLoadedFileHandler(item)}
                    />
                  </li>
                )
              }

              const readOnlyDocuments = (readOnlyItems?.[index] || []) as boolean[]

              const signConditionDocumentsInFolder = (signConditionOfDocuments?.[index] ||
                []) as boolean[]

              return (
                <li aria-hidden key={item.id}>
                  <FolderItem
                    readOnly={readOnly}
                    hasDocumentTemplateType={hasDocumentTemplateType}
                    readOnlyDocuments={readOnlyDocuments}
                    dataTestId={folderDataTestId && `${item.id}.${folderDataTestId}`}
                    folderState={item}
                    folderNameProps={folderNameProps?.(item)}
                    isDownloadForbidden={isDownloadForbidden}
                    leadingAdditionProps={(folderFileItem) =>
                      leadingAdditionProps?.(item, folderFileItem)
                    }
                    trailingAdditionProps={(folderFileItem) =>
                      trailingAdditionProps?.(item, folderFileItem)
                    }
                    checkFileDownloadForbidden={(folderFileItem) =>
                      !!checkMultipleItemDownloadForbidden?.(item, folderFileItem)
                    }
                    entityProps={{
                      ...restEntityProps,
                      dataTestId: entityDataTestId,
                      onClick: entityOnClick?.(item),
                    }}
                    downloadProps={{
                      signConditionOfDocuments: signConditionDocumentsInFolder,
                      downloadTooltipContent: (folderFileItem) =>
                        downloadTooltipContent?.(item, folderFileItem),
                      onFileDownload: onFileDownload && handleFileDownload(item),
                    }}
                    signFileProps={{
                      signFileTooltipContent,
                      onFileDropSign:
                        onFileDropSign &&
                        ((folderFileItem) => onFileDropSign?.(item, folderFileItem)),
                    }}
                    dropzoneProps={{
                      ...restDropzoneProps,
                      accept,
                      multiple,
                      onDrop: currentDropFileHandler(item),
                      onRejectDrop: currentRejectDropFileHandler(item),
                    }}
                    onFileReplace={onFileReplace && handleFileReplace(item)}
                    onFileRemove={onFileRemove && currentRemoveFileHandler(item)}
                    onFolderRemove={onFolderRemove && currentFolderRemoveHandler(item)}
                    onFolderRename={onFolderRename && currentFolderRenameHandler(item)}
                    onFoldUnfold={currentFoldUnfoldFolderHandler(item)}
                    onFileReload={currentReloadFileHandler(item)}
                    onFileRemoveNotLoaded={currentNotLoadedFileHandler(item)}
                  />
                </li>
              )
            })}
          </ul>
        )}
        {!readOnly && !isDownloadForbidden && !rejectedFile && (
          <FileDropzone.Secondary
            dropZoneControlRef={dropzoneRef}
            dropZoneProps={{
              ...restDropzoneProps,
              onDrop: handleDropFileSecondary,
              multiple,
              accept,
            }}
          />
        )}
        {!readOnly && !isDownloadForbidden && rejectedFile && (
          <FileDropzone.ErrorSecondary
            dropzoneControlRef={dropzoneRef}
            rejectedFile={rejectedFile.file}
            titleReject={rejectedFile.message}
            buttonReject={rejectedFile.buttonMessage}
            disableTooltipButton={rejectedFileErrorCodesMap.FILE_INVALID_TYPE !== rejectedFile.code}
            dropZoneProps={{
              ...restDropzoneProps,
              onDrop: handleDropFileSecondary,
              multiple,
              accept,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default memo(MultipleItem) as typeof MultipleItem
