import { PROJECT_COMPENSATION_PERIOD } from '@context/APIContext/types'

const mapOfCompensationsType = {
  PROVIDING_INFRASTRUCTURE: 'PROVIDING_INFRASTRUCTURE',
  ASSOCIATED_INFRASTRUCTURE: 'ASSOCIATED_INFRASTRUCTURE',
  LOAN_AND_BOND_PROVIDING_INFRASTRUCTURE: 'LOAN_AND_BOND_PROVIDING_INFRASTRUCTURE',
  LOAN_AND_BOND_ASSOCIATED_INFRASTRUCTURE: 'LOAN_AND_BOND_ASSOCIATED_INFRASTRUCTURE',
  LOAN_AND_BOND_INVESTPROJECT_AND_INTELLECTUAL_ACTIVITY:
    'LOAN_AND_BOND_INVESTPROJECT_AND_INTELLECTUAL_ACTIVITY',
  DISMANTLING_MILITARY_CAMP: 'DISMANTLING_MILITARY_CAMP',
} as const

const mapOfCompensationsTypeByTitle = {
  [mapOfCompensationsType.PROVIDING_INFRASTRUCTURE]: 'Обеспечивающая инфраструктура',
  [mapOfCompensationsType.ASSOCIATED_INFRASTRUCTURE]: 'Сопутствующая инфраструктура',
  [mapOfCompensationsType.LOAN_AND_BOND_PROVIDING_INFRASTRUCTURE]:
    'Проценты по кредитам и займам, купонный доход по облигационным займам (в отношении объектов обеспечивающей инфраструктуры)',
  [mapOfCompensationsType.LOAN_AND_BOND_ASSOCIATED_INFRASTRUCTURE]:
    'Проценты по кредитам и займам, купонный доход по облигационным займам (в отношении объектов сопутствующей инфраструктуры)',
  [mapOfCompensationsType.LOAN_AND_BOND_INVESTPROJECT_AND_INTELLECTUAL_ACTIVITY]:
    'Проценты по кредитам и займам, купонный доход по облигационным займам (в отношении объектов по инвестиционному проекту и РИД)',
  [mapOfCompensationsType.DISMANTLING_MILITARY_CAMP]: 'Демонтаж объектов в военных городках',
} as const

const mapOfCompensationsTypeByReimbursementPeriod = {
  [mapOfCompensationsType.PROVIDING_INFRASTRUCTURE]: PROJECT_COMPENSATION_PERIOD._5_YEARS,
  [mapOfCompensationsType.ASSOCIATED_INFRASTRUCTURE]: PROJECT_COMPENSATION_PERIOD._10_YEARS,
  [mapOfCompensationsType.LOAN_AND_BOND_PROVIDING_INFRASTRUCTURE]:
    PROJECT_COMPENSATION_PERIOD._5_YEARS,
  [mapOfCompensationsType.LOAN_AND_BOND_ASSOCIATED_INFRASTRUCTURE]:
    PROJECT_COMPENSATION_PERIOD._10_YEARS,
  [mapOfCompensationsType.LOAN_AND_BOND_INVESTPROJECT_AND_INTELLECTUAL_ACTIVITY]:
    PROJECT_COMPENSATION_PERIOD._5_YEARS,
  [mapOfCompensationsType.DISMANTLING_MILITARY_CAMP]: PROJECT_COMPENSATION_PERIOD._10_YEARS,
} as const

const mapOfCompensationsFormat = {
  TAX_DEDUCTION: 'Налоговый вычет',
  SUBSIDY: 'Субсидия',
} as const

const DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST = 'Добавьте хотя бы один элемент'

export {
  DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST,
  mapOfCompensationsFormat,
  mapOfCompensationsType,
  mapOfCompensationsTypeByReimbursementPeriod,
  mapOfCompensationsTypeByTitle,
}
