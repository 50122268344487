import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'

export const InformationOfExecutionConditionsSZPKFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const InformationOfExecutionConditionsSZPKFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<InformationOfExecutionConditionsSZPKFormValues>()
export const InformationOfExecutionConditionsSZPKFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
