import React from 'react'
import { useFormContext } from 'react-hook-form'

import AuthorizedOfficialGroup from '@components/DocumentFormComponents/AuthorizedOfficialGroup'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementByHeadOfMunicipalityBlockValues } from '@components/Forms/StatementByHeadOfMunicipality/const'
import { useStatementByHeadOfMunicipalityManager } from '@components/Forms/StatementByHeadOfMunicipality/Manager'
import { StatementByHeadOfMunicipalityFormValues } from '@components/Forms/StatementByHeadOfMunicipality/types'
import { StatementByHeadOfMunicipalityFieldsControlUpdateWatcher } from '@components/Forms/StatementByHeadOfMunicipality/watcher'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const Second = () => {
  const formInstance = useFormContext<StatementByHeadOfMunicipalityFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useStatementByHeadOfMunicipalityManager()

  const {
    getSubscribableControlProps,
    getControllerProps,
    getTextareaProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: StatementByHeadOfMunicipalityFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementByHeadOfMunicipalityBlockValues['2'].municipalityFullName,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование'}
                controllerProps={getControllerProps({
                  name: statementByHeadOfMunicipalityBlockValues['2'].municipalityFullName,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: statementByHeadOfMunicipalityBlockValues['2'].municipalityFullNameGenitive,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: statementByHeadOfMunicipalityBlockValues['2'].municipalityFullNameGenitive,
                  rules: {
                    required: defaultRHFValidation.required,
                    validate: (value) => isString(value) && lengthValidate(value, 1000),
                  },
                  textareaProps: {
                    label: 'Наименование в родительном падеже',
                  },
                  onBlur: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          statementByHeadOfMunicipalityBlockValues['2']
                            .municipalityFullNameGenitive,
                        ),
                      0,
                    ),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        statementByHeadOfMunicipalityBlockValues['2'].municipalityFullNameGenitive,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <AuthorizedOfficialGroup
          groupTitle={'Уполномоченное должностное лицо'}
          formInstance={formInstance}
          formSection={'2'}
          prefixFormFieldName={'municipality'}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: statementByHeadOfMunicipalityBlockValues['2'].municipalityHeaderName,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.headerName || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: statementByHeadOfMunicipalityBlockValues['2'].municipalityHeaderName,
                      rules: {
                        required: defaultRHFValidation.required,
                      },
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Фамилия, Имя, Отчество',
                      },
                      onChangeFormValue: () =>
                        handleChangeValue?.(
                          statementByHeadOfMunicipalityBlockValues['2'].municipalityHeaderName,
                        ),
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>
        </AuthorizedOfficialGroup>
      </Stack>
    </Container>
  )
}

export default Second
