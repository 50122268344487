import React, { ReactElement, VFC } from 'react'

import { Roles, RolesTypes } from '@constants/types'
import { useAuthContext } from '@context/AuthContext'
import StatementsPage from '@routes/NewStatements'
import ProjectsPage from '@routes/Projects'

interface RouterRoleManager {
  OIV?: ReactElement
  OMSU?: ReactElement
  INVESTOR?: ReactElement
  UO?: ReactElement
  MER?: ReactElement
  FK?: ReactElement
}

const OrganizationRoles = [Roles.Investor, Roles.Commissioner].flat()

const ProjectsRoleManager: VFC<RouterRoleManager> = (props) => {
  const { getUserRole, checkingRole } = useAuthContext()
  const { roles } = getUserRole?.()

  const currentRole = roles.find((role) => OrganizationRoles.includes(role))

  // переопределение
  if (props[currentRole]) return props[currentRole]

  const isInvestor = checkingRole?.(RolesTypes.INVESTOR)

  // default Investor
  if (isInvestor) {
    return <ProjectsPage />
  }

  // default Commissioner
  return <StatementsPage />
}

export default ProjectsRoleManager
