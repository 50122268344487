import React from 'react'
import { useFormContext } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAChangingVolumeReimbursementBlockValues } from '@components/Forms/AAChangingVolumeReimbursementForm/const'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import { AAChangingVolumeReimbursementFieldsControlUpdateWatcher } from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fifth = () => {
  const formInstance = useFormContext<AAChangingVolumeReimbursementFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAChangingVolumeReimbursementManager()

  const { getSubscribableControlProps, getSwitchProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack gap={3} direction={'vertical'}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAChangingVolumeReimbursementBlockValues['5'].isSMBusinessesInvolved,
              })}
            >
              <ControlledSwitch
                {...getSwitchProps({
                  name: aAChangingVolumeReimbursementBlockValues['5'].isSMBusinessesInvolved,
                  switchProps: {
                    wrapperClassName: styles.form__switch,
                    labelClassName: styles['form__switch-label'],
                    label:
                      'В рамках реализации инвестиционного проекта ОРП заключила договоры с субъектами малого или среднего предпринимательства на сумму, указанную в ч. 11 ст. 10 69-ФЗ',
                  },
                  onChange: () =>
                    setTimeout(
                      () =>
                        handleChangeValue?.(
                          aAChangingVolumeReimbursementBlockValues['5'].isSMBusinessesInvolved,
                        ),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Fifth
