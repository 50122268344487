import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import NoDataFieldFallback from '@components/DocumentFormComponents/NoDataFieldFallback'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import stepGroupStyles from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/Forms/StepGroup.module.scss'
import { useIndicatorsOfUnfulfilledAgreementReportManager } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/Manager'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'
import { StepCompensation } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'
import { IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/watcher'
import Stack from '@components/ReactBootstrap/Stack'

import { StepCompensationPathName } from '../../types'

import styles from './StepCompensationItem.module.scss'

interface StepCompensationItemProps {
  stepCompensationDescription: StepCompensation['stepCompensationDescription']
  stepCompensationNumber: StepCompensation['stepCompensationNumber']
  stepCompensationDatesInfo: StepCompensation['stepCompensationDatesInfo']
  formName: StepCompensationPathName
  isLastItem: boolean
}

const StepCompensationItem: FC<StepCompensationItemProps> = ({
  stepCompensationDescription,
  stepCompensationNumber,
  stepCompensationDatesInfo,
  formName,
  isLastItem,
}) => {
  const formInstance = useFormContext<IndicatorsOfUnfulfilledAgreementReportFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useIndicatorsOfUnfulfilledAgreementReportManager()

  const { getControllerProps, getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher,
  })

  const stepCompensationValue = useWatch({
    name: `${formName}.stepCompensationValue`,
    control: formInstance.control,
  })

  if (!formInstance) return null

  if (!stepCompensationValue)
    return (
      <Stack direction={'vertical'} gap={3}>
        <Group disableBottomBorder={isLastItem} title={`ЭТАП №${stepCompensationNumber}`}>
          <Group disableBottomBorder title={stepCompensationDescription}>
            <Stack direction={'vertical'} gap={1}>
              <NoDataFieldFallback
                className={styles.stepCompensation__groupSubtitle}
                text={'Возмещение не запланировано'}
              />
            </Stack>
          </Group>
        </Group>
      </Stack>
    )

  return (
    <Stack direction={'vertical'} gap={3}>
      <Group disableBottomBorder title={`ЭТАП №${stepCompensationNumber}`}>
        <Group
          disableBottomBorder
          groupClassName={stepGroupStyles.stepGroup}
          titleClassName={stepGroupStyles['stepGroup-title']}
          title={stepCompensationDescription}
        >
          <Stack direction={'vertical'} gap={2}>
            <RowWithBorder
              alignByTop
              disableTitleTopPadding
              disableBottomDefaultStyles
              title={'Объем возмещаемых затрат (план)'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.stepCompensationValue`,
                })}
              >
                <ControlledDocumentDataView
                  hideSuptitle
                  disableContainerBorder
                  disableContainerPaddingTop
                  disableContainerPaddingBottom
                  contentPosition="right"
                  controllerProps={getControllerProps({
                    name: `${formName}.stepCompensationValue`,
                  })}
                  formIconTooltipProps={{
                    tooltipContent: 'по данным СЗПК',
                    iconPosition: 'right',
                  }}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              alignByTop
              disableTitleTopPadding
              disableBottomDefaultStyles
              title={'Объем возмещаемых затрат (факт)'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.factStepCompensationValue`,
                })}
              >
                <ControlledDocumentDataView
                  hideSuptitle
                  disableContainerBorder
                  disableContainerPaddingTop
                  disableContainerPaddingBottom
                  contentPosition="right"
                  fallbackValue={<NoDataFieldFallback />}
                  controllerProps={getControllerProps({
                    name: `${formName}.factStepCompensationValue`,
                  })}
                  formIconTooltipProps={{
                    tooltipContent: 'по данным отчета об исполнении',
                    iconPosition: 'right',
                  }}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
        {!!stepCompensationDatesInfo.length && (
          <Group
            title={`Предельные сроки возмещения`}
            groupClassName={stepGroupStyles.stepGroup}
            titleClassName={stepGroupStyles['stepGroup-title']}
            disableBottomBorder={isLastItem}
          >
            <Stack direction={'vertical'} gap={2}>
              {stepCompensationDatesInfo.map((dateInfo, dateInfoIndex) => (
                <RowWithBorder
                  alignByTop
                  disableTitleTopPadding
                  disableBottomDefaultStyles
                  key={dateInfoIndex}
                  title={dateInfo.stepCompensationName}
                  firstColumnSize={6}
                  secondColumnSize={6}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.stepCompensationDatesInfo.${dateInfoIndex}.stepCompensationEndDate`,
                    })}
                  >
                    <ControlledDocumentDataView
                      hideSuptitle
                      disableContainerBorder
                      disableContainerPaddingTop
                      disableContainerPaddingBottom
                      contentPosition="right"
                      fallbackValue={
                        <NoDataFieldFallback
                          className={styles.stepCompensation__endDateFallback}
                          text={'срок получения меры поддержки истек'}
                        />
                      }
                      controllerProps={getControllerProps({
                        name: `${formName}.stepCompensationDatesInfo.${dateInfoIndex}.stepCompensationEndDate`,
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              ))}
            </Stack>
          </Group>
        )}
      </Group>
    </Stack>
  )
}

export default StepCompensationItem
