import { DependentUrls } from '@constants/urls'
import { useAuthContext } from '@context/AuthContext'
import download from '@helpers/download'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { downloadFile, splitFileName } from '@helpers/fileHelpers'
import { CreatedDocumentItem } from '@interfaces/documents'
import { IDocumentMetaInfo } from '@services/Documents/documents.entity'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import { ResponseType } from 'axios'

import type {
  ChangeDocumentMetaInfoByIdProps,
  ChangeDocumentProps,
  CreateFolderProps,
  CreateSystemDocumentProps,
  DeleteDocumentProps,
  DeleteFolderProps,
  DocsForSignProps,
  DocumentMetaInfoByIdProps,
  DownloadDfoArchiveProps,
  DownloadDocumentProps,
  DownloadProjectArchiveProps,
  Folder,
  GetHashData,
  GetHashProps,
  ReplaceDocumentProps,
  SaveDetachedSignatureData,
  SaveDetachedSignatureProps,
  SaveDocumentProps,
  SaveForeignSignatureData,
  SaveForeignSignatureProps,
  SaveSignedDocumentProps,
  UnsignedDocuments,
  UpdatedDocumentItem,
  UpdateFolderProps,
} from './types'

const responseBlobType = {
  responseType: 'blob' as ResponseType,
}

const useDocumentsApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  const downloadDocumentById = async (
    { name, projectId, versionId, documentId, documentSetId, withSigned }: DownloadDocumentProps,
    type: 'save' | 'open',
  ) => {
    try {
      const [filename, extension] = splitFileName(name)

      const { data: blob } = await FetcherWithInterceptors.get<File>({
        url: {
          url: withSigned
            ? `${DependentUrls.DownloadFile}/with-stamps`
            : DependentUrls.DownloadFile,
          params: {
            projectId,
            documentSetId,
            documentId,
            versionId,
          },
        },
        config: {
          responseType: 'blob',
          params: {
            name: filename,
            extension,
          },
        },
      })

      const fileUrl = URL.createObjectURL(blob)
      //Со штампом скачивается всегда pdf
      const preparedName = withSigned ? `${filename}.pdf` : name

      if (type === 'save') {
        return download(fileUrl, preparedName)
      }

      return window.open(fileUrl)
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useDocumentsApi',
          componentType: 'hook',
        },
        additionInfo: {
          documentSetId,
          versionId,
          type,
          documentId,
          documentName: name,
          withSigned,
        },
      })(error)

      handleErrorWithPopup(error, true)

      throw error
    }
  }

  /**
   * Метод используется для создания и сохранения системного документа
   * @param projectId Идентификатор проекта
   * @param actionId Идентификатор action

   */
  const createSystemDocument = async ({ projectId, actionId }: CreateSystemDocumentProps) => {
    try {
      return await FetcherWithInterceptors.post({
        url: {
          url: DependentUrls.CreateSystemDocument,
          params: {
            projectId,
            actionId,
          },
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  // Метод используется для получения списка подписываемых документов
  const getUnsignedDocuments = async ({
    projectId,
    actionId,
  }: DocsForSignProps): Promise<UnsignedDocuments | undefined> => {
    try {
      const { data } = await FetcherWithInterceptors.get<UnsignedDocuments>({
        url: {
          url: DependentUrls.GetUnsignedDocuments,
          params: {
            projectId,
            actionId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  // ЦР. загрузка подписи с выполнением ФЛК и преобразованием типа
  const saveDetachedSignature = async ({
    documentSetId,
    file,
    projectId,
  }: SaveDetachedSignatureProps): Promise<SaveDetachedSignatureData> => {
    try {
      const formData = new FormData()
      formData.append('document', file)

      const { data } = await FetcherWithInterceptors.post<SaveDetachedSignatureData>({
        url: {
          url: DependentUrls.SaveFLCSignature,
          params: {
            projectId,
            documentSetId,
          },
        },
        data: formData,
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const saveSignedDocument = async ({
    projectId,
    dfoId,
    documentSetId,
    documentId,
    signatureId,
  }: SaveSignedDocumentProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post<SaveDetachedSignatureData>({
        url: {
          url: DependentUrls.SaveDocumentSignature,
          params: {
            projectId,
            dfoId,
            documentSetId,
            documentId,
          },
        },
        data: { signatureId },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const saveForeignSignature = async ({
    projectId,
    dfoId,
    documentSetId,
    signatureId,
    documentId,
  }: SaveForeignSignatureProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post<SaveForeignSignatureData>({
        url: {
          url: DependentUrls.SaveForeignSignature,
          params: {
            projectId,
            dfoId,
            documentSetId,
            documentId,
          },
        },
        data: {
          signatureId,
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  /**
   * Метод используется для получение хеша файла из бакета набора документов для последующего подписания
   * @param documentId Идентификатор файла
   * @param projectId Идентификатор объекта документооборота
   * @param documentSetId Идентификатор набора документов
   * @param versionId Версия файла
   * @returns
   */
  const getDocumentHashToSign = async ({
    documentId,
    projectId,
    documentSetId,
    versionId,
  }: GetHashProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetHashData>({
        url: {
          url: DependentUrls.GetHashDocumentFromS3,
          params: {
            projectId,
            documentSetId,
            documentId,
            versionId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const saveDocument = async (
    {
      folderId = '', //Если файл положили в папку
      projectId,
      dfoId,
      type,
      documentSetId,
    }: SaveDocumentProps,
    files: File[],
  ) => {
    try {
      const formData = new FormData()
      formData.append('document', files?.[0], files?.[0]?.name)

      const {
        data: { documentId },
      } = await FetcherWithInterceptors.post<SaveDetachedSignatureData>({
        url: {
          url: DependentUrls.SaveFLCDocument,
          params: {
            projectId: projectId || '',
            documentSetId,
          },
        },
        data: formData,
        header: 'Form',
      })

      const { data: uploadedResponse } = await FetcherWithInterceptors.post<CreatedDocumentItem>({
        url: {
          url: DependentUrls.SaveDocument,
          params: {
            projectId: projectId || '',
            dfoId,
            documentSetId,
          },
        },
        data: {
          id: documentId,
          type: type,
          ...(folderId && { folderId }),
        },
      })

      return uploadedResponse
    } catch (error) {
      handleErrorWithPopup(error, false)

      throw error
    }
  }

  const replaceDocument = async (
    { replacedDocumentId = '', dfoId, documentSetId, projectId }: ReplaceDocumentProps,
    files: File[],
  ) => {
    try {
      const formData = new FormData()
      formData.append('document', files?.[0], files?.[0]?.name)

      const {
        data: { documentId },
      } = await FetcherWithInterceptors.post<SaveDetachedSignatureData>({
        url: {
          url: DependentUrls.SaveFLCDocument,
          params: {
            projectId,
            documentSetId,
          },
        },
        data: formData,
        header: 'Form',
      })

      const { data: replaceResponse } = await FetcherWithInterceptors.put<UpdatedDocumentItem>({
        url: {
          url: DependentUrls.ActionsWithDocument,
          params: {
            projectId: projectId || '',
            dfoId,
            documentSetId,
            documentId: replacedDocumentId,
          },
        },
        data: {
          tempDocumentId: documentId,
        },
      })

      return replaceResponse
    } catch (error) {
      handleErrorWithPopup(error, false)

      throw error
    }
  }

  const changeDocumentAttribute = async ({
    name,
    isApproved,
    dfoId,
    documentSetId,
    projectId,
    documentId,
  }: ChangeDocumentProps) => {
    try {
      return await FetcherWithInterceptors.put({
        url: {
          url: `${DependentUrls.ActionsWithDocument}/attributes`,
          params: {
            projectId: projectId || '',
            dfoId,
            documentSetId,
            documentId,
          },
        },
        data: {
          name,
          isApproved,
        },
      })
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const deleteDocument = async ({
    dfoId,
    documentId,
    projectId,
    documentSetId,
  }: DeleteDocumentProps) => {
    try {
      const { data } = await FetcherWithInterceptors.delete<void>({
        url: {
          url: DependentUrls.ActionsWithDocument,
          params: {
            projectId: projectId || '',
            dfoId,
            documentSetId,
            documentId,
          },
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const createFolder = async ({
    dfoId,
    documentSetId,
    projectId,
    documentType,
    name,
    documentIds,
  }: CreateFolderProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post<Folder>({
        url: {
          url: `${DependentUrls.SaveFolder}`,
          params: {
            projectId: projectId || '',
            dfoId,
            documentSetId,
          },
        },
        data: {
          documentType,
          documentIds,
          name,
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const updateFolder = async ({
    dfoId,
    documentSetId,
    projectId,
    folderId,
    name,
  }: UpdateFolderProps) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: `${DependentUrls.ActionsWithFolders}`,
          params: {
            projectId: projectId || '',
            dfoId,
            documentSetId,
            folderId,
          },
        },
        data: {
          name,
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const deleteFolder = async ({ dfoId, documentSetId, projectId, folderId }: DeleteFolderProps) => {
    try {
      const { data } = await FetcherWithInterceptors.delete({
        url: {
          url: `${DependentUrls.ActionsWithFolders}`,
          params: {
            projectId: projectId || '',
            dfoId,
            documentSetId,
            folderId,
          },
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const downloadProjectArchive = async ({ projectId }: DownloadProjectArchiveProps) => {
    try {
      const { data: blob, headers } = await FetcherWithInterceptors.get<File>({
        url: {
          url: DependentUrls.DownloadProjectArchive,
          params: {
            projectId: projectId || '',
          },
        },
        config: responseBlobType,
      })

      downloadFile(blob, headers)

      return blob
    } catch (error) {
      handleErrorWithPopup(error, true)

      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'useDocumentsApi',
          componentType: 'downloadProjectArchive',
        },
      })(error)

      throw error
    }
  }

  const downloadDfoArchive = async ({ projectId, dfoId }: DownloadDfoArchiveProps) => {
    try {
      const { data: blob, headers } = await FetcherWithInterceptors.get<File>({
        url: {
          url: DependentUrls.DownloadDfoArchive,
          params: {
            projectId: projectId || '',
            dfoId: dfoId || '',
          },
        },
        config: responseBlobType,
      })

      downloadFile(blob, headers)

      return blob
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getDocumentMetaInfoById = async ({
    projectId,
    dfoId,
    documentId,
    documentSetId,
  }: DocumentMetaInfoByIdProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<IDocumentMetaInfo>({
        url: {
          url: DependentUrls.ActionsWithDocument,
          params: {
            dfoId,
            projectId: projectId || '',
            documentId,
            documentSetId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const changeDocumentMetaInfoById = async ({
    projectId,
    dfoId,
    documentId,
    documentSetId,
    body,
  }: ChangeDocumentMetaInfoByIdProps) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: `${DependentUrls.ActionsWithDocument}/fields`,
          params: {
            dfoId,
            projectId: projectId || '',
            documentId,
            documentSetId,
          },
        },
        data: body,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    saveSignedDocument,
    saveDetachedSignature,
    saveForeignSignature,
    getDocumentHashToSign,
    downloadProjectArchive,
    getUnsignedDocuments,
    replaceDocument,
    saveDocument,
    changeDocumentAttribute,
    downloadDocumentById,
    deleteDocument,
    createFolder,
    updateFolder,
    deleteFolder,
    downloadDfoArchive,
    createSystemDocument,

    getDocumentMetaInfoById,
    changeDocumentMetaInfoById,
  }
}

export default useDocumentsApi
