import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import {
  defaultRHFValidation,
  lengthValidate,
  Patterns,
  specificIntValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const generalInfoValidation: FormValuesValidationSection<
  keyof StatementFormValues['generalInfo'],
  StatementFormValues
> = {
  projectDescription: ({ form }) => ({
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 5000),
    onChange: (e) => {
      form?.setValue(
        statementBlockValues.generalInfo.projectDescription,
        e.target.value.trimStart(),
      )
    },
  }),

  economicSphere: {
    required: defaultRHFValidation.required,
  },
  otherEconomicSphere: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000)
  },

  projectOkved: {
    required: defaultRHFValidation.required,
  },

  newWorkPlaces: {
    required: defaultRHFValidation.required,
    pattern: {
      value: Patterns.Capital,
      message: 'значение должно быть больше нуля',
    },
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },

  buildWorkPlaces: {
    required: defaultRHFValidation.required,
    pattern: {
      value: Patterns.Capital,
      message: 'значение должно быть больше нуля',
    },
    validate: (value) => {
      if (!isString(value)) return

      return specificIntValidate(value)
    },
  },

  lawLinkReasonSzpk: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },

  ruleLinkReasonSzpk: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { generalInfoValidation }
