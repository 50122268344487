import { ChangeEvent, FocusEvent } from 'react'
import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import { InputProps } from '../Input/types'

import MultipleInput from './MultipleInput'
import { MultipleInputProps, MultipleInputValue } from './types'

export type ControlledMultipleInputProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> &
  Pick<InputProps, 'onChange' | 'onBlur'> &
  Pick<MultipleInputProps, 'inputProps' | 'onAddChip' | 'onRemoveChip'> & {
    multipleProps?: Omit<
      MultipleInputProps,
      | 'inputProps'
      | 'ref'
      | 'error'
      | 'defaultValue'
      | 'onBlur'
      | 'onChange'
      | 'onKeyDown'
      | 'onAddChip'
      | 'onRemoveChip'
    >
  }
const ControlledMultipleInput = <T extends FieldValues>({
  onChange,
  onBlur,
  onAddChip,
  onRemoveChip,
  inputProps,
  multipleProps = {},
  ...controllerProps
}: ControlledMultipleInputProps<T>) => {
  const {
    field: { onChange: onFormChange, onBlur: onFormBlur, value, ref },
    fieldState: { error },
  } = useController(controllerProps)

  const mergedOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e)

    onFormBlur()
  }

  const mergedOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    payload: MultipleInputValue | MultipleInputValue[],
  ) => {
    onChange?.(e)

    onFormChange(payload)
  }

  const mergedOnRemoveChip = (payload: MultipleInputValue[], removedValue: MultipleInputValue) => {
    onRemoveChip?.(payload, removedValue)

    if (!payload.length) {
      onFormBlur()
      onFormChange(payload)

      return
    }

    onFormChange(payload)
  }

  const mergedOnAddChip = (payload: MultipleInputValue[], newValue: MultipleInputValue) => {
    onAddChip?.(payload, newValue)

    onFormChange(payload)
  }

  return (
    <MultipleInput
      defaultValue={value}
      ref={ref}
      error={error}
      inputProps={{
        ...inputProps,
        view: 'secondary',
      }}
      onChange={mergedOnChange}
      onBlur={mergedOnBlur}
      onRemoveChip={mergedOnRemoveChip}
      onAddChip={mergedOnAddChip}
      {...multipleProps}
    />
  )
}

ControlledMultipleInput.displayName = 'ControlledMultipleInput'

export default ControlledMultipleInput
