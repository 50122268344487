import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultInfrFacilitiesCostsExpectedReimbursedFormValues } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/const'
import InfrFacilitiesCostsExpectedReimbursedLayout from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/Layout'

const InfrFacilitiesCostsExpectedReimbursedLayoutWithFormProvider = (
  props: FormModalLayoutProps,
) => {
  return (
    <FormProviderWrapper defaultValues={defaultInfrFacilitiesCostsExpectedReimbursedFormValues}>
      <InfrFacilitiesCostsExpectedReimbursedLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(InfrFacilitiesCostsExpectedReimbursedLayoutWithFormProvider)
