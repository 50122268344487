import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '@components/Forms/NotificationOfIdentifiedNPAListErrorsForm/types'

const generateMenuListForNotificationOfIdentifiedNPAListErrorsMenuState = (
  formValues:
    | NotificationOfIdentifiedNPAListErrorsFormValues
    | UnpackNestedValue<NotificationOfIdentifiedNPAListErrorsFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<NotificationOfIdentifiedNPAListErrorsFormValues>,
): NestedMapOfMenu[] => {
  const getPreparedNpaErrors = (item: NestedMapOfMenu): NestedMapOfMenu => {
    const npaErrorItemsError = formErrors?.['4']?.npaErrors

    if (!formValues['4'].npaErrors || !formValues['4'].npaErrors.length)
      return {
        ...item,
        hasError: !!npaErrorItemsError,
      }

    return {
      ...item,
      hasError: !!npaErrorItemsError,
      children: formValues?.['4']?.npaErrors.map((npaError, indexOfNpaError): NestedMapOfMenu => {
        const npaErrorItemError = formErrors?.['4']?.npaErrors?.[indexOfNpaError]

        return {
          id: `4.${indexOfNpaError + 1}`,
          path: `4.npaErrors.${indexOfNpaError}`,
          title: npaError.npaName,
          isCollapsed: false,
          hasError: !!npaErrorItemError,
          parentBaseId: '4',
          children: [],
        }
      }),
    }
  }

  const preparedHashMap = {
    '1': {
      ...initialHashMap['1'],
      hasError: !!formErrors?.[initialHashMap['1'].id],
    },
    '2': {
      ...initialHashMap['2'],
      hasError: !!formErrors?.[initialHashMap['2'].id],
    },
    '3': {
      ...initialHashMap['3'],
      hasError: !!formErrors?.[initialHashMap['3'].id],
    },
    '4': getPreparedNpaErrors(initialHashMap['4']),
  }

  return Object.values(preparedHashMap)
}

export { generateMenuListForNotificationOfIdentifiedNPAListErrorsMenuState }
