import { InformationOfExecutionConditionsSZPKFormValues } from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'

const mapOfInformationOfExecutionConditionsSZPKMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'О проекте' },
  { id: '3', title: 'Информация о реализации этапов' },
  {
    id: '4',
    title:
      'Кредиты и займы, купонный доход, облигационные займы, затраты по которым понесены при реализации инвестиционного проекта',
  },
  {
    id: '5',
    title: 'Объекты жилищного строительства, расположенные на территориях военных городков',
  },
  {
    id: '6',
    title:
      'Документально подтвержденные и фактические понесенные расходы, возникшие вследствие нарушения условий стабилизационной оговорки',
  },
  { id: '7', title: 'Информация о возмещении затрат' },
  { id: '8', title: 'Объем уплаченных налогов и таможенных платежей' },
  { id: '9', title: 'Информация об осуществленных мерах государственной поддержки' },
  { id: '10', title: 'Информация о наличии оснований для изменения или расторжения СЗПК' },
]

const defaultInformationOfExecutionConditionsSZPKFormValues: InformationOfExecutionConditionsSZPKFormValues =
  {
    '1': {
      investorFullName: '',
      investorInn: '',
      investorOgrn: '',
      investorAddress: '',
      investorHeaderName: null,
      investorHeaderNameGenitive: '',
      investorHeaderPosition: '',
      investorHeaderPositionGenitive: '',
      investorHeaderReasonGenitive: '',
    },
    '2': {
      projectName: '',
      projectDecisionDepartament: '',
      economicSphere: '',
      projectOkved: null,
      szpkRegistrationDate: '',
      startPreinvestStage: '',
      endPreinvestStage: '',
      startInvestStage: '',
      endInvestStage: '',
      startExploitationStage: '',
      totalCapital: '',
      totalCapitalVAT: '',
      investorCapital: '',
      investorCapitalVAT: '',
      endExploitationStage: '',
      newWorkPlaces: '',
      projectResultEffect: '',
      isVatMoney: false,
    },
    '3': {
      stepsMonitoring: [],
    },
    '4': {
      percentCredits: [],
    },
    '5': {
      dismantlingObjects: [],
    },
    '6': {
      expenses: [],
    },
    '7': {
      compensationsMonitoring: [],
    },
    '8': {
      compensationsMonitoringChecker: [],
    },
    '9': {
      stateSupports: [],
      generalStateSupportValue: '',
    },
    '10': {
      relatedContractReason: false,
      relatedContractReasonDescription: '',
      extendStabilizationReason: false,
      extendStabilizationReasonDescription: '',
      municipalAccessionReason: false,
      municipalAccessionReasonDescription: '',
      rightTransferReason: false,
      rightTransferReasonDescription: '',
      infoChangesReason: false,
      infoChangesReasonDescription: '',
      forceMajeureReason: false,
      forceMajeureReasonDescription: '',
      agreementDutyReason: false,
      agreementDutyReasonDescription: '',
      concessionFailReason: false,
      concessionFailReasonDescription: '',
      constructionChangeReason: false,
      constructionChangeReasonDescription: '',
      supportingInfrastructureReason: false,
      supportingInfrastructureReasonDescription: '',
      investmentChangeReason: false,
      investmentChangeReasonDescription: '',
      compensationChangeReason: false,
      compensationChangeReasonDescription: '',
      changeCompensationFormatReason: false,
      changeCompensationFormatReasonDescription: '',
      lawChangeReason: false,
      lawChangeReasonDescription: '',
      noMadeInvestorCapitalReason: false,
      noMadeInvestorCapitalReasonDescription: '',
      legalFactsReason: false,
      legalFactsReasonDescription: '',
      lawViolationReason: false,
      lawViolationReasonDescription: '',
      suspensionOrganizationActivityReason: false,
      suspensionOrganizationActivityReasonDescription: '',
      liquidationOrganisationReason: false,
      liquidationOrganisationReasonDescription: '',
      otherReason: false,
      otherReasonName: '',
      otherReasonDescription: '',
      changeSuggestions: '',
    },
    additionalFields: {
      isPaper: false,
    },
  }

const informationOfExecutionConditionsSZPKBlockValues = {
  '1': {
    investorHeaderName: '1.investorHeaderName',
    investorHeaderNameGenitive: '1.investorHeaderNameGenitive',
    investorHeaderPosition: '1.investorHeaderPosition',
    investorHeaderPositionGenitive: '1.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '1.investorHeaderReasonGenitive',
    investorFullName: '1.investorFullName',
    investorInn: '1.investorInn',
    investorOgrn: '1.investorOgrn',
    investorAddress: '1.investorAddress',
  },
  '2': {
    projectName: '2.projectName',
    projectDecisionDepartament: '2.projectDecisionDepartament',
    economicSphere: '2.economicSphere',
    projectOkved: '2.projectOkved',
    szpkRegistrationDate: '2.szpkRegistrationDate',
    startPreinvestStage: '2.startPreinvestStage',
    endPreinvestStage: '2.endPreinvestStage',
    startInvestStage: '2.startInvestStage',
    endInvestStage: '2.endInvestStage',
    startExploitationStage: '2.startExploitationStage',
    totalCapital: '2.totalCapital',
    totalCapitalVAT: '2.totalCapitalVAT',
    investorCapitalVAT: '2.investorCapitalVAT',
    investorCapital: '2.investorCapital',
    endExploitationStage: '2.endExploitationStage',
    newWorkPlaces: '2.newWorkPlaces',
    projectResultEffect: '2.projectResultEffect',
    isVatMoney: '2.isVatMoney',
  },
  '3': {
    stepsMonitoring: '3.stepsMonitoring',
  },
  '4': {
    percentCredits: '4.percentCredits',
  },
  '5': {
    dismantlingObjects: '5.dismantlingObjects',
  },
  '6': {
    expenses: '6.expenses',
  },
  '7': {
    compensationsMonitoring: '7.compensationsMonitoring',
    compensationSubsidyFormat: '7.compensationSubsidyFormat',
    compensationTaxDeductionFormat: '7.compensationTaxDeductionFormat',
    compensationDamageFormat: '7.compensationDamageFormat',
    generalCompensation: '7.generalCompensation',
  },
  '8': {
    compensationsMonitoringChecker: '8.compensationsMonitoringChecker',
  },
  '9': {
    stateSupports: '9.stateSupports',
    generalStateSupportValue: '9.generalStateSupportValue',
  },
  '10': {
    relatedContractReason: '10.relatedContractReason',
    relatedContractReasonDescription: '10.relatedContractReasonDescription',
    extendStabilizationReason: '10.extendStabilizationReason',
    extendStabilizationReasonDescription: '10.extendStabilizationReasonDescription',
    municipalAccessionReason: '10.municipalAccessionReason',
    municipalAccessionReasonDescription: '10.municipalAccessionReasonDescription',
    rightTransferReason: '10.rightTransferReason',
    rightTransferReasonDescription: '10.rightTransferReasonDescription',
    infoChangesReason: '10.infoChangesReason',
    infoChangesReasonDescription: '10.infoChangesReasonDescription',
    forceMajeureReason: '10.forceMajeureReason',
    forceMajeureReasonDescription: '10.forceMajeureReasonDescription',
    agreementDutyReason: '10.agreementDutyReason',
    agreementDutyReasonDescription: '10.agreementDutyReasonDescription',
    concessionFailReason: '10.concessionFailReason',
    concessionFailReasonDescription: '10.concessionFailReasonDescription',
    constructionChangeReason: '10.constructionChangeReason',
    constructionChangeReasonDescription: '10.constructionChangeReasonDescription',
    supportingInfrastructureReason: '10.supportingInfrastructureReason',
    supportingInfrastructureReasonDescription: '10.supportingInfrastructureReasonDescription',
    investmentChangeReason: '10.investmentChangeReason',
    investmentChangeReasonDescription: '10.investmentChangeReasonDescription',
    compensationChangeReason: '10.compensationChangeReason',
    compensationChangeReasonDescription: '10.compensationChangeReasonDescription',
    changeCompensationFormatReason: '10.changeCompensationFormatReason',
    changeCompensationFormatReasonDescription: '10.changeCompensationFormatReasonDescription',
    lawChangeReason: '10.lawChangeReason',
    lawChangeReasonDescription: '10.lawChangeReasonDescription',
    noMadeInvestorCapitalReason: '10.noMadeInvestorCapitalReason',
    noMadeInvestorCapitalReasonDescription: '10.noMadeInvestorCapitalReasonDescription',
    legalFactsReason: '10.legalFactsReason',
    legalFactsReasonDescription: '10.legalFactsReasonDescription',
    lawViolationReason: '10.lawViolationReason',
    lawViolationReasonDescription: '10.lawViolationReasonDescription',
    suspensionOrganizationActivityReason: '10.suspensionOrganizationActivityReason',
    suspensionOrganizationActivityReasonDescription:
      '10.suspensionOrganizationActivityReasonDescription',
    liquidationOrganisationReason: '10.liquidationOrganisationReason',
    liquidationOrganisationReasonDescription: '10.liquidationOrganisationReasonDescription',
    otherReason: '10.otherReason',
    otherReasonName: '10.otherReasonName',
    otherReasonDescription: '10.otherReasonDescription',
    changeSuggestions: '10.changeSuggestions',
  },
  additionalFields: {
    isPaper: 'additionalFields.isPaper',
  },
} as const

export {
  defaultInformationOfExecutionConditionsSZPKFormValues,
  informationOfExecutionConditionsSZPKBlockValues,
  mapOfInformationOfExecutionConditionsSZPKMenu,
}
