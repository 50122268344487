import { usePopupManager } from 'react-popup-manager'

import SignModal from '@components/NewDesignedModals/SignModal'

import type { HandleOpenSignModal } from './types'

const useSignModal = () => {
  const popupManager = usePopupManager()

  const handleOpenSignModal = ({ onClose, ...rest }: HandleOpenSignModal) => {
    popupManager.open(SignModal, {
      onClose: onClose ?? (() => null),
      ...rest,
    })
  }

  return {
    handleOpenSignModal,
  }
}

export { useSignModal }
