import React, { FC, memo, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import InformationOfExecutionAgreementEvents from '@components/Forms/InformationOfExecutionAgreementEventsForm'
import { defaultInformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/const'
import { getInformationOfExecutionAgreementEventsMenuHashMapMenuByStatus } from '@components/Forms/InformationOfExecutionAgreementEventsForm/Menu/const'
import { InformationOfExecutionAgreementEventsFormValues } from '@components/Forms/InformationOfExecutionAgreementEventsForm/types'
import { InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher } from '@components/Forms/InformationOfExecutionAgreementEventsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import { useMonitoringReportDateSubtitle } from './hooks'
import InformationOfExecutionAgreementEventsLayoutWrapper from './Wrapper'

const InformationOfExecutionAgreementEventsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  rolesOfUser,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps<InformationOfExecutionAgreementEventsFormValues>({
    defaultValues: defaultInformationOfExecutionAgreementEventsFormValues,
  })

  const handleOnClose = () => {
    InformationOfExecutionAgreementEventsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  const initialMenuState = useMemo(() => {
    return getInformationOfExecutionAgreementEventsMenuHashMapMenuByStatus()
  }, [])

  const monitoringReportDateSubtitle = useMonitoringReportDateSubtitle(formInstance)

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <InformationOfExecutionAgreementEvents.MenuManager
          initialMenuHash={initialMenuState.initialInformationOfExecutionAgreementEventsMenuState}
          initialSectionId={
            initialMenuState.initialInformationOfExecutionAgreementEventsMenuState[0].id
          }
        >
          <InformationOfExecutionAgreementEvents.Manager
            formId={formId}
            projectId={projectId}
            rolesOfUser={rolesOfUser}
            initialErrorsFromViolations={initialErrorsFromViolations}
            editMode={editMode}
            onClose={handleOnClose}
          >
            <InformationOfExecutionAgreementEventsLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Данные об исполнении мероприятий соглашения
                </Typography.Headline>
                <Typography.Body
                  className={styles.layout__subtitle}
                  variant={'bodyMRegular'}
                  align={'center'}
                  as={'h2'}
                >
                  {monitoringReportDateSubtitle}
                </Typography.Body>
                <InformationOfExecutionAgreementEvents.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <InformationOfExecutionAgreementEvents.Menu className={styles.layout__menu} />
              </div>
            </InformationOfExecutionAgreementEventsLayoutWrapper>
          </InformationOfExecutionAgreementEvents.Manager>
        </InformationOfExecutionAgreementEvents.MenuManager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(InformationOfExecutionAgreementEventsLayout)
