import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import type { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import { defaultRHFValidation, standardAmountValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const sixthSectionValidationMap: FormValuesValidationSection<
  keyof AAVolumeOfCapitalInvestmentsFormValues['6'],
  AAVolumeOfCapitalInvestmentsFormValues
> = {
  totalCapital: {
    validate: (value) => {
      if (!isString(value)) return

      return standardAmountValidate(value)
    },
  },
  madeInvestorCapitalDate: {
    required: defaultRHFValidation.required
  }
}

export { sixthSectionValidationMap }