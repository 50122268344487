import { Dispatch, ReactNode, SetStateAction } from 'react'
import { UseFormReturn } from 'react-hook-form'

import {
  IAuthorizedOrganizationItem,
  TGetListAuthorizedOrganizationResponse,
} from '@context/APIContext/hooks/useAuthorizedOrganizationsApi'
import { AxiosError } from 'axios'
import { KeyedMutator } from 'swr'

import { IFiltersFormValues } from '../hooks/useAuthorizedOrganizationsFilters'

export interface AuthorizedOrganizationsContextProps {
  state: {
    additionalLoader: boolean
    gridSwrInstance: {
      isAuthorizedOrganizationsListLoading: boolean
      mutate: KeyedMutator<TGetListAuthorizedOrganizationResponse[]>
      goToNextPage: VoidFunction
      hasMoreData?: boolean
      authorizedOrganizationsList?: IAuthorizedOrganizationItem[]
      error?: AxiosError<unknown, unknown>
    } | null

    filtersInstance: UseFormReturn<IFiltersFormValues> | null

    debouncedSearchValue: string
  }
  handlers: {
    setAdditionalLoader: Dispatch<SetStateAction<boolean>> | null
  }
}

export type AuthorizedOrganizationsManagerProps = {
  children: ReactNode
}

export const AuthorizedOrganizations = {
  OMSU: 'OMSU',
  OIV: 'OIV',
} as const
