import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { FinancialModelFormValues } from '@components/Forms/FinancialModelForm/types'
import { getAmountValueFromStrWithoutRound } from '@components/NewDesign/AmountInput/utils'
import { billionIntValidate } from '@constants/validations'
import { isNullOrUndefined, isString } from '@helpers/checkTypes'

const INTERNAL_RATE_RETURN_LIMIT_VALUE = 50000

const thirdSectionFinancialModelValidationMap: FormValuesValidationSection<
  keyof FinancialModelFormValues['3'],
  FinancialModelFormValues
> = {
  netPresentValue: {
    validate: (value) => {
      if (!isString(value)) return

      return billionIntValidate(value, 6)
    },
  },

  internalRateReturn: {
    validate: (value) => {
      if (!isString(value)) return

      const valueToEqual = getAmountValueFromStrWithoutRound(value, true)

      if (isNullOrUndefined(valueToEqual)) return

      if (valueToEqual < -1 * INTERNAL_RATE_RETURN_LIMIT_VALUE)
        return `значение не должно быть меньше, чем минимально допустимое (-${INTERNAL_RATE_RETURN_LIMIT_VALUE})`

      return (
        valueToEqual <= INTERNAL_RATE_RETURN_LIMIT_VALUE ||
        `значение не должно превышать  максимально допустимое (${INTERNAL_RATE_RETURN_LIMIT_VALUE})`
      )
    },
  },
}

export { thirdSectionFinancialModelValidationMap }
