import { SetStateAction, useState } from 'react'

import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import {
  useIntersectionObserverEntry,
  UseIntersectionProps,
} from '@hooks/new/observers/useIntersectionObserverEntry'

const useHeaderCollapse = (ref: HTMLElement | null, intersectionProps: UseIntersectionProps) => {
  const [collapsedPageMode, setCollapsedPageMode] = useState<boolean>(false)

  const intersectionEntry = useIntersectionObserverEntry(ref, intersectionProps)

  useUpdateEffect(() => {
    if (intersectionEntry?.isIntersecting) {
      return handleCollapsedMode(false)
    }

    handleCollapsedMode(true)
  }, [intersectionEntry?.isIntersecting])

  const handleCollapsedMode = (newValue: SetStateAction<boolean>) => {
    setCollapsedPageMode(newValue)
  }

  return {
    collapsedPageMode,
    handleCollapsedMode,
    intersectionEntry,
  }
}

export { useHeaderCollapse }
