import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import AsyncWrapper from '@components/AsyncWrapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import Button from '@components/NewDesign/Button'
import Stack from '@components/ReactBootstrap/Stack'
import { isUndefined } from '@helpers/checkTypes'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

import styles from './ObjectStepWrapper.module.scss'

interface ObjectStepWrapperProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  stepId: string
  headerNode: ReactNode
  children: ReactNode
  additionalHeaderNode?: ReactNode
  onRemoveObjectStep?: VoidFunction
  disableBorder?: boolean
  disableHeaderPaddingLeft?: boolean
  borderClassName?: string
  leftAddons?: ReactNode
}

const ObjectStepWrapper: FC<ObjectStepWrapperProps> = ({
  disableHeaderPaddingLeft = false,
  stepId,
  disableBorder,
  headerNode,
  children,
  additionalHeaderNode,
  borderClassName,
  onRemoveObjectStep,
  id,
  leftAddons,
  ...restProps
}) => {
  const isShowRemoveButton = !isUndefined(onRemoveObjectStep)

  const leftAddonsRenderCondition = !isUndefined(leftAddons)

  const handleDelete = async (e) => {
    e.preventDefault()

    await onRemoveObjectStep?.()
  }

  return (
    <FlippedWithCollapse flipId={stepId}>
      <div {...restProps} id={id}>
        {!disableBorder && (
          <Border className={cn(styles.objectStepWrapper__border, borderClassName)} />
        )}
        <div
          className={cn(styles.objectStepWrapper, {
            [styles['objectStepWrapper--borderedTop']]: !disableBorder,
          })}
        >
          <div
            className={cn(styles.objectStepWrapper__header, {
              [styles['objectStepWrapper__header--disablePaddingLeft']]: disableHeaderPaddingLeft,
            })}
          >
            {leftAddonsRenderCondition && (
              <div className={styles['objectStepWrapper__header-leftAddons']}>{leftAddons}</div>
            )}
            <div className={styles['objectStepWrapper__header-title']}>{headerNode}</div>
            <Stack direction={'horizontal'} gap={isShowRemoveButton ? 2 : 0}>
              {additionalHeaderNode}
              {isShowRemoveButton && (
                <AsyncWrapper promise={handleDelete}>
                  {({ isLoading, wrappedPromise }) => (
                    <Button
                      disabled={isLoading}
                      variant={'buttonSMedium'} 
                      dataTestId={'deleteButton'}
                      size={'2xs'}
                      color={'negative'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite', 
                      }}
                      leadingIcon={{
                        noCurrentColorSvgFill: true,
                        src: DeleteIcon,
                      }}
                      onClick={wrappedPromise}
                    >
                      Удалить
                    </Button>
                  )}
                </AsyncWrapper>
              )}
            </Stack>
          </div>
          <div className={styles.objectStepWrapper__content}>{children}</div>
        </div>
      </div>
    </FlippedWithCollapse>
  )
}

export default ObjectStepWrapper
