import React, { FC, memo } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import FieldView from '@components/DocumentFormComponents/FieldView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/MainTemplate.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import {
  getFetcherProps,
  isFetcherProps,
} from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import FormController from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/FormController'
import { useWatchFieldsForValidation } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/hooks'
import AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpenses from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/OldStepExpenses'
import AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageStepCompensations from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/StepCompensations'
import {
  SeventhStagesArrayPathName,
  SeventhStagesOldStepInvestorSourcesPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { seventhSectionValidationMap } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/validation'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { getObjectValue } from '@helpers/object/getObjectValue'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

interface MainTemplateProps {
  stageId: string
  name: SeventhStagesArrayPathName
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  isConstructionRender: boolean
  isRidRender: boolean
  indexOfMainBlock: number
}

const { transformRHFPathInProperties } = DocumentFormHelpers

const STEP_COMPENSATION_FORM_TO_IGNORE = ['Налоговый вычет', 'Субсидия и (или) налоговый вычет']

const MainTemplate: FC<MainTemplateProps> = ({
  stageId,
  formInstance,
  formModifierInstance,
  name,
  isConstructionRender,
  isRidRender,
  indexOfMainBlock,
}) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleAddCustomValue,
      handleRemoveCustomValue,
      getPropertiesProps,
      getOverrideProps,
      handleChangeValue,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${name}.isNew`,
    control: formModifierInstance.control,
  })

  const isFederal = useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues.additionalFields
      .isFederal,
    control: formInstance?.control,
  })

  const {
    getSubscribableControlProps,
    getFormFieldControlProps,
    getCheckboxProps,
    getAmountInputProps,
    getTextareaProps,
    getCalendarInputProps,
    getInputProps,
    getSingleSelectProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { fields: oldStepInvestorCapitalSources } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhStagesOldStepInvestorSourcesPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${name}.oldStepInvestorCapitalSources`,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  const isPaper = useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues.additionalFields
      .isPaper,
    control: formInstance.control,
  })

  const projectStartDate = useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues.additionalFields
      .projectStartDate,
    control: formInstance.control,
  })

  const projectEndDate = useWatch({
    name: `additionalFields.projectEndDate`,
    control: formInstance.control,
  })

  const stepStartDate = useWatch({
    name: `${name}.stepStartDate`,
    control: formInstance.control,
  })

  const stepActivityNames = useWatch({
    name: `${name}.stepActivityNames`,
    control: formInstance.control,
  })

  const activityOthers = useWatch({
    name: `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['8'].stepActivityOthers}.${indexOfMainBlock}.activityOthers`,
    control: formInstance.control,
  })

  useWatchFieldsForValidation(formInstance)

  const handleAddOldInvestorCapitalSource = async () => {
    await handleAddItemToListWithOutValue?.(`${name}.oldStepInvestorCapitalSources`)
  }

  const handleRemoveOldInvestorCapitalSource = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.oldStepInvestorCapitalSources.${index}`,
      `${name}.oldStepInvestorCapitalSources`,
    )
  }

  return (
    <Container className={styles.mainTemplate}>
      <Col xs={12} className={styles.mainTemplate__container}>
        <Stack direction={'vertical'} gap={3} className={styles.mainTemplate__stack}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Номер этапа'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.stepNumber`,
                formFieldTooltipProps: {
                  onChange: () => setTimeout(() => handleChangeValue?.(`${name}.stepNumber`), 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.stepNumber`,
                  rules: seventhSectionValidationMap.stepNumber,
                  inputProps: {
                    integersOnly: true,
                    hasNegativeValue: false,
                    hideSuffix: true,
                  },
                  onBlur: () => setTimeout(() => handleChangeValue?.(`${name}.stepNumber`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepNumber`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </FieldView.RowWithBottomBorder>
          <Row>
            <Col xs={12}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.stepDescription`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.stepDescription`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${name}.stepDescription`,
                    rules: seventhSectionValidationMap.stepDescription,
                    textareaProps: {
                      label: 'Описание этапа',
                    },
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.stepDescription`), 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.stepDescription`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.stepStartDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.stepStartDate`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.stepStartDate`,
                    rules: {
                      ...seventhSectionValidationMap.stepStartDate,
                      validate: {
                        positiveProjectStartDate: (value) => {
                          return (
                            (isDateValidForDayjs(value) &&
                              DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >=
                                DayjsService.dayjs(
                                  projectStartDate,
                                  objOfDateFormats.defaultFormat,
                                )) ||
                            'дата начала этапа должна быть больше или равна дате начала проекта'
                          )
                        },
                      },
                      onChange: () => {
                        formInstance.trigger(`${name}.stepEndDate`)
                      },
                    },
                    calendarInputProps: {
                      label: 'Дата начала этапа',
                    },
                    onBlur: () => setTimeout(() => handleChangeValue?.(`${name}.stepStartDate`), 0),
                    onInputChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.stepStartDate`)
                      }, 0),
                    onCalendarChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.stepStartDate`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Col>
            <Col xs={6}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.stepEndDate`,
                  formFieldTooltipProps: {
                    onChange: () => setTimeout(() => handleChangeValue?.(`${name}.stepEndDate`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.stepEndDate`,
                    rules: {
                      ...seventhSectionValidationMap.stepEndDate,
                      validate: {
                        positiveProjectStartDate: (value) => {
                          if (
                            isDateValidForDayjs(value) &&
                            DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >
                              DayjsService.dayjs(projectEndDate, objOfDateFormats.defaultFormat)
                          )
                            return 'дата окончания этапа должна быть меньше или равна дате окончания проекта'
                        },
                        positiveStepStartDate: (value) => {
                          return (
                            (isDateValidForDayjs(value) &&
                              DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >
                                DayjsService.dayjs(
                                  stepStartDate,
                                  objOfDateFormats.defaultFormat,
                                )) ||
                            'дата окончания этапа должна быть больше даты начала этапа'
                          )
                        },
                      },
                      onChange: () => {
                        activityOthers.forEach((activity, index) => {
                          formInstance.trigger(
                            `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['8'].stepActivityOthers}.${indexOfMainBlock}.activityOthers.${index}.otherActivityEndDate`,
                          )
                        })
                      },
                    },
                    calendarInputProps: {
                      label: 'Дата окончания этапа',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.stepEndDate`)
                      }, 0),
                    onInputChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.stepEndDate`)
                      }, 0),
                    onCalendarChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.stepEndDate`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            title={'Объем капиталовложений по этапу'}
          >
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.stepInvestorCapitalValue`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.stepInvestorCapitalValue`), 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.stepInvestorCapitalValue`,
                  rules: seventhSectionValidationMap.stepInvestorCapitalValue,
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.stepInvestorCapitalValue`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.stepInvestorCapitalValue`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </FieldView.RowWithBottomBorder>
          <Group disableBottomBorder title={'Планируемые к возмещению затраты'}>
            <Stack direction={'vertical'} gap={3}>
              {!isPaper && (
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  title={'Объем планируемых к возмещению затрат (без НДС)'}
                >
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: `${name}.stepCompensationValue`,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(() => handleChangeValue?.(`${name}.stepCompensationValue`), 0),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: `${name}.stepCompensationValue`,
                        rules: seventhSectionValidationMap.stepCompensationValue,
                        onBlur: () =>
                          setTimeout(() => handleChangeValue?.(`${name}.stepCompensationValue`), 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(`${name}.stepCompensationValue`)
                          }, 0),
                      })}
                    />
                  </FormFieldControl>
                </FieldView.RowWithBottomBorder>
              )}
              <Row>
                <Col xs={12}>
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: `${name}.stepCompensationForm`,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(() => handleChangeValue?.(`${name}.stepCompensationForm`), 0),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => {
                              if (
                                !isFederal &&
                                STEP_COMPENSATION_FORM_TO_IGNORE.some((value) => value == item.name)
                              )
                                return null

                              return {
                                displayValue: item.name || '',
                                value: item.id,
                              }
                            },
                            selectProps: {
                              withContextSearch: true,
                              inputProps: {
                                label: 'В форме',
                              },
                              onChangeFormValue: () =>
                                handleChangeValue?.(`${name}.stepCompensationForm`),
                            },
                            controllerProps: {
                              name: `${name}.stepCompensationForm`,
                              rules: seventhSectionValidationMap.stepCompensationForm,
                            },
                          })}
                        />
                      )
                    }}
                  </FormFieldControl>
                </Col>
              </Row>
            </Stack>
          </Group>
          {!isPaper && (
            <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageStepCompensations
              formInstance={formInstance}
              formModifierInstance={formModifierInstance}
              blockViewIsValidating={blockViewIsValidating}
              editMode={editMode}
              formName={`${name}.stepCompensations` as const}
            />
          )}
          {isPaper && (
            <RowWithBorder disableBottomDefaultStyles title={'Срок осуществления капиталовложений'}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.stepInvestorCapitalDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.stepInvestorCapitalDate`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.stepInvestorCapitalDate`,
                    rules: seventhSectionValidationMap.stepInvestorCapitalDate,
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.stepInvestorCapitalDate`), 0),
                    onInputChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.stepInvestorCapitalDate`)
                      }, 0),
                    onCalendarChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(`${name}.stepInvestorCapitalDate`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </RowWithBorder>
          )}
          {isPaper && (
            <Group disableBottomBorder title={'Источники капиталовложений'}>
              <FlipperList list={oldStepInvestorCapitalSources}>
                {oldStepInvestorCapitalSources.map((source, index) => {
                  const sourceFormName = `${name}.oldStepInvestorCapitalSources.${index}` as const

                  return (
                    <FieldView.RowWithRemoveButton
                      key={source.id}
                      id={source.id}
                      onRemove={editMode ? handleRemoveOldInvestorCapitalSource(index) : undefined}
                    >
                      <Row>
                        <Col xs={12}>
                          <FormFieldControl
                            {...getFormFieldControlProps({
                              path: `${sourceFormName}.oldStepCapitalSourceName`,
                            })}
                          >
                            <ControlledInput
                              {...getInputProps({
                                name: `${sourceFormName}.oldStepCapitalSourceName`,
                                rules: seventhSectionValidationMap.oldStepInvestorCapitalSources,
                                inputProps: {
                                  label: 'Источник капиталовложений',
                                },
                                onBlur: () =>
                                  setTimeout(() => {
                                    handleChangeValue?.(
                                      `${sourceFormName}.oldStepCapitalSourceName`,
                                    )
                                  }, 0),
                                onChange: () =>
                                  setTimeout(() => {
                                    debouncedHandleChangeValue?.(
                                      `${sourceFormName}.oldStepCapitalSourceName`,
                                    )
                                  }, 0),
                              })}
                            />
                          </FormFieldControl>
                        </Col>
                      </Row>
                    </FieldView.RowWithRemoveButton>
                  )
                })}
              </FlipperList>
              {editMode && (
                <Row className={'px-1 py-1'}>
                  <Col xs={12}>
                    <AsyncWrapper promise={handleAddOldInvestorCapitalSource}>
                      {({ isLoading, wrappedPromise }) => {
                        return (
                          <Button
                            disabled={isLoading}
                            leadingIcon={{ src: CircleAddIcon }}
                            variant={'buttonSMedium'}
                            size={'2xs'}
                            view={'plain'}
                            loaderProps={{
                              loading: isLoading,
                              placement: 'trailing',
                              variant: 'lite',
                            }}
                            onClick={wrappedPromise}
                          >
                            Добавить
                          </Button>
                        )
                      }}
                    </AsyncWrapper>
                  </Col>
                </Row>
              )}
            </Group>
          )}
          {isPaper && (
            <AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpenses
              blockViewIsValidating={blockViewIsValidating}
              editMode={editMode}
              formInstance={formInstance}
              formModifierInstance={formModifierInstance}
              stageId={stageId}
              formName={`${name}.oldStepExpenses` as const}
            />
          )}
          <Row>
            <Col xs={12}>
              <FormListTooltipControl
                name={`${name}.stepActivityNames`}
                control={formModifierInstance.control}
                onDifference={handleUpdateChanges}
              >
                <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
                  Тип мероприятия
                </Typography.Body>
              </FormListTooltipControl>
            </Col>
          </Row>
          <Row className={styles['mainTemplate__checkbox-row']}>
            <Col
              id={`${name}.isConstruction`}
              xs={4}
              className={styles['mainTemplate__checkbox-col']}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.isConstruction`,
                })}
              >
                <FormObjectTooltipControl
                  name={`${name}.isConstruction`}
                  size={'s'}
                  control={formModifierInstance.control}
                  onDifference={handleUpdateChanges}
                >
                  {({ leftAddons }) => (
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${name}.isConstruction`,
                        checkBoxProps: {
                          label: 'Строительство',
                          leftAddons,
                        },
                        onChange: async (e, payload) => {
                          const propertyStepActivityNames = getObjectValue(
                            getPropertiesProps?.(),
                            transformRHFPathInProperties(`${name}.isConstruction`),
                          )

                          const allControlProps =
                            getOverrideProps?.()[propertyStepActivityNames?.propertyId]

                          const overrideProps = getFetcherProps(allControlProps)
                          if (!overrideProps) return null

                          if (!propertyStepActivityNames || !overrideProps.length) return

                          const idOfAddProperty = overrideProps.find(
                            (item) => item.name === 'Строительство (создание)',
                          )?.id

                          const propertyInfo = {
                            id: propertyStepActivityNames.propertyId,
                            lastUpdateDt: propertyStepActivityNames.lastUpdateDt,
                          }

                          if (payload?.checked) {
                            handleAddCustomValue?.(propertyInfo, {
                              id: idOfAddProperty,
                            })
                          } else {
                            const idToRemove = stepActivityNames?.find(
                              (item) => item.value.name === 'Строительство (создание)',
                            ).propertyId

                            if (!idToRemove) return
                            handleRemoveCustomValue?.(propertyInfo, idToRemove)
                          }
                        },
                      })}
                    />
                  )}
                </FormObjectTooltipControl>
              </SubscribableControl>
            </Col>
            <Col
              id={`${name}.isReconstruction`}
              xs={4}
              className={styles['mainTemplate__checkbox-col']}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.isReconstruction`,
                })}
              >
                <FormObjectTooltipControl
                  name={`${name}.isReconstruction`}
                  size={'s'}
                  control={formModifierInstance.control}
                  onDifference={handleUpdateChanges}
                >
                  {({ leftAddons }) => (
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${name}.isReconstruction`,
                        checkBoxProps: {
                          label: 'Реконструкция',
                          leftAddons,
                        },
                        onChange: async (e, payload) => {
                          const propertyStepActivityNames = getObjectValue(
                            getPropertiesProps?.(),
                            transformRHFPathInProperties(`${name}.isReconstruction`),
                          )

                          const allControlProps =
                            getOverrideProps?.()[propertyStepActivityNames?.propertyId]

                          const overrideProps = getFetcherProps(allControlProps)
                          if (!overrideProps) return null

                          if (!propertyStepActivityNames || !overrideProps.length) return

                          const idOfAddProperty = overrideProps.find(
                            (item) => item.name === 'Реконструкция',
                          )?.id

                          const propertyInfo = {
                            id: propertyStepActivityNames.propertyId,
                            lastUpdateDt: propertyStepActivityNames.lastUpdateDt,
                          }

                          if (payload?.checked) {
                            handleAddCustomValue?.(propertyInfo, {
                              id: idOfAddProperty,
                            })
                          } else {
                            const idToRemove = stepActivityNames?.find(
                              (item) => item.value.name === 'Реконструкция',
                            ).propertyId

                            if (!idToRemove) return
                            handleRemoveCustomValue?.(propertyInfo, idToRemove)
                          }
                        },
                      })}
                    />
                  )}
                </FormObjectTooltipControl>
              </SubscribableControl>
            </Col>
            <Col
              id={`${name}.isModernization`}
              xs={4}
              className={styles['mainTemplate__checkbox-col']}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.isModernization`,
                })}
              >
                <FormObjectTooltipControl
                  name={`${name}.isModernization`}
                  size={'s'}
                  control={formModifierInstance.control}
                  onDifference={handleUpdateChanges}
                >
                  {({ leftAddons }) => (
                    <ControlledCheckbox
                      {...getCheckboxProps({
                        name: `${name}.isModernization`,
                        checkBoxProps: {
                          label: 'Модернизация',
                          leftAddons,
                        },
                        onChange: async (e, payload) => {
                          const propertyStepActivityNames = getObjectValue(
                            getPropertiesProps?.(),
                            transformRHFPathInProperties(`${name}.isModernization`),
                          )

                          const allControlProps =
                            getOverrideProps?.()[propertyStepActivityNames?.propertyId]

                          const overrideProps = getFetcherProps(allControlProps)
                          if (!overrideProps) return null

                          if (!propertyStepActivityNames || !overrideProps.length) return

                          const idOfAddProperty = overrideProps.find(
                            (item) => item.name === 'Модернизация',
                          )?.id

                          const propertyInfo = {
                            id: propertyStepActivityNames.propertyId,
                            lastUpdateDt: propertyStepActivityNames.lastUpdateDt,
                          }

                          if (payload?.checked) {
                            handleAddCustomValue?.(propertyInfo, {
                              id: idOfAddProperty,
                            })
                          } else {
                            const idToRemove = stepActivityNames?.find(
                              (item) => item.value.name === 'Модернизация',
                            ).propertyId

                            if (!idToRemove) return
                            handleRemoveCustomValue?.(propertyInfo, idToRemove)
                          }
                        },
                      })}
                    />
                  )}
                </FormObjectTooltipControl>
              </SubscribableControl>
            </Col>
          </Row>
          <Row className={styles['mainTemplate__checkbox-row']}>
            <Col id={`${name}.isCreateRID`} xs={4} className={styles['mainTemplate__checkbox-col']}>
              <SubscribableControl
                path={`${name}.isCreateRID`}
                watcher={
                  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher
                }
                {...subscribableControl}
              >
                <FormObjectTooltipControl
                  name={`${name}.isCreateRID`}
                  size={'s'}
                  control={formModifierInstance.control}
                  onDifference={handleUpdateChanges}
                >
                  {({ leftAddons }) => (
                    <ControlledCheckbox
                      name={`${name}.isCreateRID`}
                      control={formInstance.control}
                      checkBoxProps={{
                        disabled: !editMode,
                        label: 'Создание РИД',
                        leftAddons,
                      }}
                      onChange={async (e, payload) => {
                        const propertyStepActivityNames = getObjectValue(
                          getPropertiesProps?.(),
                          transformRHFPathInProperties(`${name}.isCreateRID`),
                        )

                        const allControlProps =
                          getOverrideProps?.()[propertyStepActivityNames?.propertyId]

                        const overrideProps = getFetcherProps(allControlProps)
                        if (!overrideProps) return null

                        if (!propertyStepActivityNames || !overrideProps.length) return

                        const idOfAddProperty = overrideProps.find(
                          (item) => item.name === 'Создание РИД',
                        )?.id

                        const propertyInfo = {
                          id: propertyStepActivityNames.propertyId,
                          lastUpdateDt: propertyStepActivityNames.lastUpdateDt,
                        }

                        if (payload?.checked) {
                          handleAddCustomValue?.(propertyInfo, {
                            id: idOfAddProperty,
                          })
                        } else {
                          const idToRemove = stepActivityNames?.find(
                            (item) => item.value.name === 'Создание РИД',
                          ).propertyId

                          if (!idToRemove) return
                          handleRemoveCustomValue?.(propertyInfo, idToRemove)
                        }
                      }}
                    />
                  )}
                </FormObjectTooltipControl>
              </SubscribableControl>
            </Col>
          </Row>
        </Stack>
      </Col>
      <Row>
        <Col xs={12}>
          <FormController
            isNew={isNew}
            indexOfMainBlock={indexOfMainBlock}
            isConstructionRender={isConstructionRender}
            isRidRender={isRidRender}
            name={name}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default memo(MainTemplate)
