import { FC } from 'react'

import First from './1'
import Second from './2'

interface ApplicationOfConclusionAgreementSZPKRegisterFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const ApplicationOfConclusionAgreementSZPKRegisterForms: ApplicationOfConclusionAgreementSZPKRegisterFormsProps =
  () => null

ApplicationOfConclusionAgreementSZPKRegisterForms.First = First
ApplicationOfConclusionAgreementSZPKRegisterForms.Second = Second

export default ApplicationOfConclusionAgreementSZPKRegisterForms
