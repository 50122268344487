import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/AdditionalFormItem.module.scss'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import RExploitationFormItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RExploitationForm/RExploitationFormItem'
import { SeventhRidExploitationObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface RExploitationItemProps {
  id: string
  anchorId: string
  formName: SeventhRidExploitationObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  indexOfBlock: number
  indexOfExploitation: number
  onDeleteExploitation: VoidFunction
}

const RExploitationItem: FC<RExploitationItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formInstance,
  indexOfBlock,
  formName,
  indexOfExploitation,
  onDeleteExploitation,
}) => {
  return (
    <ObjectStepWrapper
      disableBorder
      stepId={id}
      id={anchorId}
      headerNode={
        <Typography.Body variant={'bodyMSemibold'}>
          Ввод в эксплуатацию №{indexOfExploitation + 1}
        </Typography.Body>
      }
      onRemoveObjectStep={editMode ? onDeleteExploitation : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <RExploitationFormItem
            formName={formName}
            blockViewIsValidating={blockViewIsValidating}
            formInstance={formInstance}
            indexOfBlock={indexOfBlock}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Border className={styles.additionalFormItem__border} />
          </Col>
        </Row>
      </Stack>
    </ObjectStepWrapper>
  )
}

export default RExploitationItem
