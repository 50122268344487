import React, { FC, memo, useMemo } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps, MapOfMenu } from '@components/DocumentFormComponents/types'
import AAgreementIncludeRelatedTerms from '@components/Forms/AAgreementIncludeRelatedTermsForm'
import {
  aAgreementIncludeRelatedTermsBlockValues,
  defaultAAgreementIncludeRelatedTermsFormValues,
  mapOfAAgreementIncludeRelatedTermsMenu,
} from '@components/Forms/AAgreementIncludeRelatedTermsForm/const'
import { AAgreementIncludeRelatedTermsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementIncludeRelatedTermsForm/watcher'
import Typography from '@components/NewDesign/Typography'

import AAgreementIncludeRelatedTermsLayoutWrapper from './Wrapper'

const AAgreementIncludeRelatedTermsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  documentsTypeOfSet,
  editMode,
}) => {
  const formInstance = useFormWithDefaultProps({
    defaultValues: defaultAAgreementIncludeRelatedTermsFormValues,
  })

  const handleOnClose = () => {
    AAgreementIncludeRelatedTermsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  const isFederalSZPK = useWatch({
    name: aAgreementIncludeRelatedTermsBlockValues.additionalFields.isFederal,
    control: formInstance.control
  })

  const currentMenu: MapOfMenu[] = useMemo(() => {
    return [...mapOfAAgreementIncludeRelatedTermsMenu].reduce<MapOfMenu[]>(
      (previousValue, currentValue) => {
        if (!isFederalSZPK && currentValue.id === '1') return previousValue

        return [...previousValue, currentValue]
      },
      [],
    )
  }, [isFederalSZPK])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <AAgreementIncludeRelatedTerms.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          documentsTypeOfSet={documentsTypeOfSet}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager key={String(isFederalSZPK)} initialCurrentAnchorId={currentMenu[0].id}>
            <AAgreementIncludeRelatedTermsLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Дополнительное соглашение о включении условий связанного договора
                </Typography.Headline>
                <AAgreementIncludeRelatedTerms.StatementForm
                  initialMenu={currentMenu}
                  className={styles.layout__form}
                />
              </div>
              <div className={styles['layout__menu-wrapper']}>
                <AAgreementIncludeRelatedTerms.Menu
                  initialMenu={currentMenu}
                  className={styles.layout__menu}
                />
              </div>
            </AAgreementIncludeRelatedTermsLayoutWrapper>
          </FlatMenuManager>
        </AAgreementIncludeRelatedTerms.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(AAgreementIncludeRelatedTermsLayout)
