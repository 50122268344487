import { FC } from 'react'

import ChipBase from '@components/Chip/Base'
import ChipCheckbox from '@components/Chip/Checkbox'
import ChipTag from '@components/Chip/Tag'

interface ChipProps extends FC {
  Base: typeof ChipBase
  Tag: typeof ChipTag
  Checkbox: typeof ChipCheckbox
}

const Chip: ChipProps = () => null

Chip.Base = ChipBase
Chip.Tag = ChipTag
Chip.Checkbox = ChipCheckbox

export default Chip
