import { FC } from 'react'

import DocumentsLayout from '@components/Projects/[id]/DocumentLayout'

import ParticipantsOfStatement from './ParticipantsOfStatement/index'
import ActionBanner from './ActionBanner'

interface ProjectProps extends FC {
  ParticipantsOfStatement: typeof ParticipantsOfStatement
  ActionBanner: typeof ActionBanner
  DocumentsLayout: typeof DocumentsLayout
}

const Project: ProjectProps = () => null

Project.ParticipantsOfStatement = ParticipantsOfStatement
Project.ActionBanner = ActionBanner
Project.DocumentsLayout = DocumentsLayout

export default Project
