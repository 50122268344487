import React, { FC, memo } from 'react'
import { FormProvider } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useFormWithDefaultProps } from '@components/DocumentFormComponents/hooks/useFormWithDefaultProps'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm'
import {
  defaultNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues,
  mapOfNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu,
} from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/const'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayoutWrapper from './Wrapper'

const NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayout: FC<
  FormModalLayoutProps
> = ({ projectId, formId, isOpen, onClose, initialErrorsFromViolations, editMode }) => {
  const formInstance =
    useFormWithDefaultProps<NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues>(
      {
        defaultValues:
          defaultNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues,
      },
    )

  const handleOnClose = () => {
    NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager
            initialCurrentAnchorId={
              mapOfNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu[0].id
            }
          >
            <NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление о выявленных нарушениях при рассмотрении ходатайства о признании ранее
                  заключенного договора связанным договором
                </Typography.Headline>
                <NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA.StatementForm
                  className={styles.layout__form}
                />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--xl'])}
              >
                <NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA.Menu
                  className={styles.layout__menu}
                />
              </div>
            </NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayoutWrapper>
          </FlatMenuManager>
        </NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedA.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedALayout)
