import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import RidNamesList from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RRegistrationForm/RidNamesList'
import RRegistrationItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RRegistrationForm/RRegistrationItem'
import {
  SeventhRidRegistrationObjectsPathName,
  SeventhRidRegistrationPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

import { ridRegistrationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from './validation'

interface RRegistrationFormProps {
  isNew: boolean
  name: SeventhRidRegistrationPathName
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  indexOfBlock: number
}

const RRegistrationForm: FC<RRegistrationFormProps> = ({
  isNew,
  name,
  formInstance,
  formModifierInstance,
  indexOfBlock,
}) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { getSubscribableControlProps, getFormFieldControlProps, getCalendarInputProps } =
    useFormComponentPresets({
      isNew,
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
    })

  const { fields: registrationForms } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhRidRegistrationObjectsPathName,
    'keyNameId'
  >({
    name: `${name}.ridRegistrationsObjects`,
    control: formInstance.control,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
    SeventhRidRegistrationObjectsPathName
  >({
    name: `${name}.ridRegistrationsObjects`,
    control: formModifierInstance.control,
    watcher:
      AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher,
  })

  const stepEndDate = useWatch({
    name: `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${indexOfBlock}.stepEndDate`,
    control: formInstance.control,
  })

  const handleAddRidRegistration = async () => {
    await handleAddItemToListWithOutValue?.(`${name}.ridRegistrationsObjects`)
  }

  const handleRemoveRidRegistration = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.ridRegistrationsObjects.${index}`,
      `${name}.ridRegistrationsObjects`,
    )
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(`${name}.ridRegistrationsObjects.${index}`)
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(`${name}.ridRegistrationsObjects.${index}`)
  }

  if (!formInstance) return null

  return (
    <Container className="p-0">
      <Stack direction={'vertical'} gap={3}>
        <RidNamesList
          blockViewIsValidating={blockViewIsValidating}
          editMode={editMode}
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
          formName={name}
        />
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Планируется осуществить регистрацию в срок до'}
            >
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.ridStateRegistrationEndDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${name}.ridStateRegistrationEndDate`),
                        0,
                      ),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.ridStateRegistrationEndDate`,
                    rules: {
                      ...ridRegistrationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.ridStateRegistrationEndDate,
                      validate: {
                        positiveStepEndDate: (value) => {
                          if (
                            isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) >
                              dayjs(stepEndDate, objOfDateFormats.defaultFormat)
                          )
                            return 'дата регистрации всех РИД должна быть меньше или равна дате окончания этапа'
                        },
                      },
                    },
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${name}.ridStateRegistrationEndDate`),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${name}.ridStateRegistrationEndDate`),
                        0,
                      ),
                    onInputChange: () =>
                      debouncedHandleChangeValue?.(`${name}.ridStateRegistrationEndDate`),
                  })}
                />
              </FormFieldControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Stack direction="vertical" gap={!!registrationForms?.length ? 3 : 0} className={'mt-3'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.ridRegistrationsObjects`,
            })}
          >
            <FlipperList list={registrationForms}>
              {registrationForms.map((registrationForm, index) => {
                const formName = `${name}.ridRegistrationsObjects.${index}` as const

                return (
                  <SubscribableControl
                    key={registrationForm.id}
                    {...getSubscribableControlProps({
                      path: formName,
                    })}
                  >
                    <FormObjectTooltipControl
                      name={formName}
                      control={formModifierInstance.control}
                      onDifference={handleUpdateChanges}
                    >
                      <RRegistrationItem
                        id={registrationForm.id}
                        formName={formName}
                        blockViewIsValidating={blockViewIsValidating}
                        editMode={editMode}
                        formInstance={formInstance}
                        formModifierInstance={formModifierInstance}
                        indexOfRegistration={index}
                        lastIndexOfRegistration={registrationForms.length - 1}
                        onDeleteRegistration={handleRemoveRidRegistration(index)}
                        onMoveTop={handleChangeElementOrderToUp(index)}
                        onMoveBottom={handleChangeElementOrderToDown(index)}
                      />
                    </FormObjectTooltipControl>
                  </SubscribableControl>
                )
              })}
            </FlipperList>
          </SubscribableControl>
          {editMode && (
            <Row className={'px-1'}>
              <Col xs={9}>
                <AsyncWrapper promise={handleAddRidRegistration}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <Button
                        disabled={isLoading}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        view={'plain'}
                        leadingIcon={{
                          src: CircleAddIcon,
                        }}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Указать зарегистрированный РИД
                      </Button>
                    )
                  }}
                </AsyncWrapper>
              </Col>
            </Row>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

export default RRegistrationForm
