import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FieldValues, Path, UseFormGetFieldState, UseFormReturn } from 'react-hook-form'

import Alert from '@components/Alert'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import IconButton from '@components/NewDesign/IconButton'
import { RolesTypes } from '@constants/types'
import { isFunction } from '@helpers/checkTypes'
import { findOne } from '@helpers/commonHelpers'
import { copyToClipboard } from '@helpers/copy'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { MainToastManager } from '@helpers/ToastManager'
import { useQueryManager } from '@hooks/useQueryManager'
import CopyIcon from '@icons/content/copy.svg'
import { IPropertyProps, PropertyTypeEnum } from '@services/Properties/Properties.entity'
import cloneDeep from 'clone-deep'

import styles from './SubscribableControl.module.scss'

const { transformRHFPathInProperties } = DocumentFormHelpers

interface SubscribableControlChildrenProps {
  overrideProps: Record<string, unknown>
}

interface SubscribableFormControlProps<T extends FieldValues> {
  setError: UseFormReturn<T>['setError']
  getFieldState: UseFormGetFieldState<T>
  getValues: UseFormReturn<T>['getValues']
  propertiesProps: () => Record<string, any> | null
  userRoles?: string[]
}

interface SubscribableControlProps<T extends FieldValues> extends SubscribableFormControlProps<T> {
  path: Path<T>
  watcher: FieldsControlUpdateWatcher
  children: ((props: SubscribableControlChildrenProps) => ReactNode) | ReactNode
}

const SubscribableControl = <T extends FieldValues>({
  path,
  watcher,
  setError,
  getFieldState,
  propertiesProps,
  children,
  userRoles,
}: SubscribableControlProps<T>) => {
  const { queryUtils } = useQueryManager()

  const [dynamicProps, setDynamicProps] = useState<Record<string, unknown>>({})

  const propertyPropsPath = useMemo(() => {
    return transformRHFPathInProperties(path)
  }, [path])

  const propertyProps = getObjectValue(propertiesProps?.(), propertyPropsPath) as
    | IPropertyProps<string>
    | undefined

  const debugMode =
    userRoles &&
    !!queryUtils.getQuery('debugMode') &&
    findOne(userRoles, [RolesTypes.MER]) &&
    propertyProps?.type &&
    propertyProps.type !== PropertyTypeEnum.MAP &&
    propertyProps.type !== PropertyTypeEnum.LIST

  useEffect(() => {
    if (!propertyProps?.propertyId) return

    const applyCachedProps = (props: Record<string, unknown>) => {
      setDynamicProps(props)
    }

    const updateError = (newError: string) => {
      setError(path, {
        message: newError,
      })
    }

    const updateProps = (newProps: Record<string, any>) => {
      setDynamicProps((prevState) => ({
        ...(isFunction(newProps) ? newProps(prevState) : newProps),
      }))
    }

    watcher.subscribe(propertyProps.propertyId, {
      updatePropsCallback: updateProps,
      updateErrorCallback: updateError,
      applyCachedPropsCallback: applyCachedProps,
    })
  }, [])

  useEffect(() => {
    if (!propertyProps?.propertyId) return

    return () => {
      const propsToUnmount = cloneDeep(dynamicProps)

      watcher.unsubscribe(propertyProps.propertyId, {
        ...propsToUnmount,
        lastError: getFieldState(path).error?.message,
      })
    }
  }, [])

  //TODO: Быстрое решение, в будущем убрать в отдельную абстракцию FormControl
  const handleCopyClick = (value: string) => async () => {
    try {
      await copyToClipboard(value)

      MainToastManager.toast(
        <Alert transparent variant="success">
          Успешно скопировано
        </Alert>,
      )
    } catch {
      MainToastManager.toast(
        <Alert transparent variant="error">
          Произошла ошибка при копировании
        </Alert>,
      )
    }
  }

  const getChildrenWrapper = useCallback(
    (children: ReactNode) => {
      return (
        <div className={styles.subscribableControl}>
          {children}
          <IconButton
            size={'xxs'}
            className={styles.subscribableControl__icon}
            icon={{
              src: CopyIcon,
              noCurrentColorSvgFill: true,
            }}
            onClick={handleCopyClick(
              getObjectValue(propertiesProps?.(), propertyPropsPath)?.propertyId,
            )}
          />
        </div>
      )
    },
    [propertiesProps, propertyPropsPath],
  )

  if (debugMode) {
    return (
      <React.Fragment>
        {isFunction(children)
          ? getChildrenWrapper(children({ overrideProps: dynamicProps }))
          : getChildrenWrapper(children)}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {isFunction(children) ? children({ overrideProps: dynamicProps }) : children}
    </React.Fragment>
  )
}

export type { SubscribableControlProps, SubscribableFormControlProps }
export default SubscribableControl
