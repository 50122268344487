import React, { forwardRef, memo, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import PartsOfMasterItem from '@components/RegistryNpa/MasterItem/Parts'
import { useNpaViewSidebar } from '@components/Sidebars/NPA/View'
import { objOfDateFormats } from '@constants/dateFormats'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useNpaTypes } from '@hooks/new/swr/useNpaTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import { IMasterNpaItem } from '@services/NPA/NPA.entity'
import cn from 'classnames'
import dayjs from 'dayjs'

import styles from './MasterItem.module.scss'

interface MasterItemProps {
  npa: IMasterNpaItem
  onReloadGrid?: VoidFunction
}

export interface MasterItemFormValues {
  npa: IMasterNpaItem
}

const MasterItem = forwardRef<HTMLButtonElement | null, MasterItemProps>(
  ({ npa, onReloadGrid }, ref) => {
    const { control, setValue, reset, watch } = useForm<MasterItemFormValues>({
      defaultValues: {
        npa,
      },
    })

    useEffect(() => {
      reset({ npa })
    }, [npa])

    const partsToRender = watch('npa.parts')

    const { booleanState: masterItemIsOpen, reverseBooleanState: toggleMasterItem } =
      useBooleanState()

    const {
      booleanState: isMountChildren,
      setBooleanStateToTrue: mountChildren,
      setBooleanStateToFalse: unmountChildren,
    } = useBooleanState(masterItemIsOpen)

    const { getCollapseProps } = useCollapse({
      isExpanded: masterItemIsOpen,
      onExpandStart: () => {
        mountChildren()
      },
      onCollapseEnd: () => {
        unmountChildren()
      },
    })

    const { mapOfNpaTypes } = useNpaTypes({
      key: {
        _key: 'npaTypes',
      },
    })

    const { handleOpenNpaViewSidebar } = useNpaViewSidebar()

    const formattedNpaDate = useMemo(
      () => dayjs(npa.date).format(objOfDateFormats.defaultFormat),
      [npa.date],
    )

    const formattedLastRedactionDate = useMemo(
      () => dayjs(npa.redactionDate).format(objOfDateFormats.defaultFormat),
      [npa.redactionDate],
    )

    const handleToggleMasterItem = (e) => {
      e.preventDefault()
      e.stopPropagation()

      toggleMasterItem()
    }

    const handleOpenMasterItem = () => {
      handleOpenNpaViewSidebar({ npaId: npa.id, onReloadGrid })
    }

    return (
      <>
        <button ref={ref} className={styles.masterItem} onClick={handleOpenMasterItem}>
          <div className={styles.masterItem__leftPart}>
            <IconButton
              className={styles.masterItem__control}
              size={'s'}
              view={'basic'}
              icon={{
                className: cn(styles['masterItem__control-item'], {
                  [styles['masterItem__control--toggled']]: masterItemIsOpen,
                }),
                src: chevronRightIcon,
              }}
              onClick={handleToggleMasterItem}
            />

            <div className={styles.masterItem__mainInfo}>
              <Typography.Caption variant={'captionMMedium'} color={'text-base-secondary'}>
                {mapOfNpaTypes?.[npa.type]} ・{' '}
                <Typography.Caption as={'span'} variant={'captionMMedium'}>
                  {npa.number}
                </Typography.Caption>{' '}
                от {formattedNpaDate}
              </Typography.Caption>
              <Typography.Body variant={'bodyMMedium'}>{npa.name}</Typography.Body>
            </div>
          </div>
          <div className={styles.masterItem__additionalInfo}>
            <Typography.Body as={'span'} variant={'bodyMRegular'}>
              Ред. № {npa.redactionNumber} от {formattedLastRedactionDate}
            </Typography.Body>
          </div>
        </button>

        {partsToRender && (
          <div {...getCollapseProps()}>
            <div
              className={cn({
                [styles.masterItem__parts]: masterItemIsOpen,
              })}
            >
              <PartsOfMasterItem control={control} mainNpa={npa} />
            </div>
          </div>
        )}
      </>
    )
  },
)

export default memo(MasterItem)
