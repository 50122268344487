import React from 'react'
import { useFormContext } from 'react-hook-form'

import BeneficialOwnersInfoSection from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection'
import { beneficiaryInformationBlockValues } from '@components/Forms/BeneficiaryInformationForm/const'
import { useBeneficiaryInformationManager } from '@components/Forms/BeneficiaryInformationForm/Manager'
import { BeneficiaryInformationFormValues } from '@components/Forms/BeneficiaryInformationForm/types'
import {
  BeneficiaryInformationFieldArrayControlUpdateWatcher,
  BeneficiaryInformationFieldsControlUpdateWatcher,
} from '@components/Forms/BeneficiaryInformationForm/watcher'

const Second = () => {
  const formInstance = useFormContext<BeneficiaryInformationFormValues>()

  const beneficiaryInformationManager = useBeneficiaryInformationManager()

  if (!formInstance) return null

  return (
    <BeneficialOwnersInfoSection
      formName={beneficiaryInformationBlockValues['2'].beneficiars}
      formInstance={formInstance}
      manager={beneficiaryInformationManager}
      watcher={BeneficiaryInformationFieldsControlUpdateWatcher}
      fieldArrayWatcher={BeneficiaryInformationFieldArrayControlUpdateWatcher}
    />
  )
}

export default Second
