import React from 'react'
import { useFormContext } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import CertificateOnMonitoringResultsStepMonitoringChecker from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/5/StepMonitoringChecker'
import { CertificateOnMonitoringResultsStepsMonitoringCheckerPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/5/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

const Fifth = () => {
  const formInstance = useFormContext<CertificateOnMonitoringResultsFormValues>()

  const {
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { fields: stepsMonitoringChecker } = useFieldArraySubscribableControl<
    CertificateOnMonitoringResultsFormValues,
    CertificateOnMonitoringResultsStepsMonitoringCheckerPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: certificateOnMonitoringResultsBlockValues['5'].stepsMonitoringChecker,
    keyName: 'keyNameId',
    watcher: CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: certificateOnMonitoringResultsBlockValues['5'].stepsMonitoringChecker,
          })}
        >
          <FlipperList list={stepsMonitoringChecker}>
            {stepsMonitoringChecker.map((stepMonitoring, index) => {
              const formName =
                `${certificateOnMonitoringResultsBlockValues['5'].stepsMonitoringChecker}.${index}` as const

              return (
                <CertificateOnMonitoringResultsStepMonitoringChecker
                  key={stepMonitoring.id}
                  id={stepMonitoring.id}
                  anchorId={stepMonitoring.anchorId}
                  formInstance={formInstance}
                  name={formName}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
      </Stack>
    </Container>
  )
}

export default Fifth
