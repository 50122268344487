import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import { IOrganizationUser } from '@services/Organization/organization.entity'
import useSWRImmutable from 'swr/immutable'

const useOrganizationUsers = ({ key, config }: SWRHookProps<IOrganizationUser[] | undefined>) => {
  const {
    organizationApi: { getUsers },
  } = useAPIContext()

  const {
    data: organizationUsers,
    error,
    mutate,
    isValidating: isLoadingOrganizationUsers,
  } = useSWRImmutable(key, getUsers, config)

  return {
    organizationUsers,
    error,
    mutate,
    isLoadingOrganizationUsers,
  }
}

export { useOrganizationUsers }
