import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

export interface IRegions {
  [key: string]: string
}

const useRegions = ({ key, config }: SWRHookProps<IRegions | undefined>) => {
  const {
    dictionariesApi: { getRegions },
  } = useAPIContext()

  const {
    data: regions,
    error,
    isValidating: isRegionsLoading,
    mutate,
  } = useSWRImmutable(key, getRegions, config)

  //По умолчанию: код - город, данный объект --- город - код
  const reversedRegionsObject = useMemo(() => {
    if (!regions) return

    return Object.entries(regions).reduce((prevValue, [regionCode, regionValue]) => {
      return { ...prevValue, [regionValue]: regionCode }
    }, {})
  }, [regions])

  return {
    regions,
    reversedRegionsObject,
    error,
    isRegionsLoading,
    mutate,
  }
}

export { useRegions }
