import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationDrawalAppConclusionSZPKFormValues } from '@components/Forms/NotificationDrawalAppConclusionSZPKForm/types'
import { defaultRHFValidation } from '@constants/validations'

const oneSectionValidationMap: FormValuesValidationSection<
  keyof NotificationDrawalAppConclusionSZPKFormValues['1'],
  NotificationDrawalAppConclusionSZPKFormValues
> = {
  investorHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { oneSectionValidationMap }
