import { FC, memo } from 'react'

import Typography from '@components/NewDesign/Typography'
import { defaultPasswordConditionConfig, PasswordRowProps } from '@containers/FieldCondition/const'
import { isNullOrUndefined } from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './FieldCondition.module.scss'

interface PasswordConditionProps {
  displayValue?: string
  config?: PasswordRowProps[]
  value?: string
}

const FieldCondition: FC<PasswordConditionProps> = ({
  displayValue = 'Пароль должен содержать',
  config = defaultPasswordConditionConfig,
  value,
}) => {
  const defaultCondition = value?.length

  return (
    <div className={styles.wrapper}>
      <Typography variant="bodyMRegular" color="text-base-secondary">
        {displayValue}
      </Typography>
      <ul className={styles.list}>
        {config.map((row, index) => {
          const patternCondition = row.pattern && value && row.pattern.test(value)

          return (
            <li
              key={index}
              className={cn({
                [styles.complete]: defaultCondition && patternCondition,
                [styles.error]:
                  defaultCondition && !isNullOrUndefined(row.pattern) && !patternCondition,
              })}
            >
              {row.text}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default memo(FieldCondition)
