import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'

const { getDefaultInfoAboutDocument } = FieldStateHelpers

const mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu =
  [
    { id: '1', title: 'Сведения о документе' },
    { id: '2', title: 'Организация, реализующая проект' },
    { id: '3', title: 'Основания невозможности удовлетворения ходатайства' },
  ]

const defaultNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues: NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': {
      investorFullName: '',
      investorFullNameInstrumental: '',
      investorInn: '',
      investorOgrn: '',
      investorAddress: '',
    },
    '3': {
      ruleLinkDenySolicitationProject: '',
      reasonDenySolicitationProject: '',
    },
  }

const notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues =
  {
    '1': {
      documentDateSign: '1.documentDateSign',
      documentNumber: '1.documentNumber',
      documentReceivers: '1.documentReceivers',
    },
    '2': {
      investorFullName: '2.investorFullName',
      investorFullNameInstrumental: '2.investorFullNameInstrumental',
      investorInn: '2.investorInn',
      investorOgrn: '2.investorOgrn',
      investorAddress: '2.investorAddress',
    },
    '3': {
      ruleLinkDenySolicitationProject: '3.ruleLinkDenySolicitationProject',
      reasonDenySolicitationProject: '3.reasonDenySolicitationProject',
    },
  } as const

export {
  defaultNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues,
  mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyMenu,
  notificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyBlockValues,
}
