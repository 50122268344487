import React, { FC, memo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import FlatMenuManager from '@components/DocumentFormComponents/Menu/Manager'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import NotificationFillingAppConcluding from '@components/Forms/NotificationFillingAppConcludingForm'
import {
  defaultNotificationFillingAppConcludingFormValues,
  mapOfNotificationFillingAppConcludingMenu,
} from '@components/Forms/NotificationFillingAppConcludingForm/const'
import { NotificationFillingAppConcludingFieldsControlUpdateWatcher } from '@components/Forms/NotificationFillingAppConcludingForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import NotificationFillingAppConcludingLayoutWrapper from './Wrapper'

const NotificationFillingAppConcludingLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const formInstance = useForm({
    defaultValues: defaultNotificationFillingAppConcludingFormValues,
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })

  const handleOnClose = () => {
    NotificationFillingAppConcludingFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <FormProvider {...formInstance}>
        <NotificationFillingAppConcluding.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onClose={handleOnClose}
        >
          <FlatMenuManager initialCurrentAnchorId={mapOfNotificationFillingAppConcludingMenu[0].id}>
            <NotificationFillingAppConcludingLayoutWrapper>
              <div>
                <Typography.Headline
                  className={styles.layout__title}
                  variant={'headlineH1'}
                  align={'center'}
                  as={'h1'}
                >
                  Уведомление о выявленных нарушениях в связи с подачей заявления о заключении
                  дополнительного соглашения
                </Typography.Headline>
                <NotificationFillingAppConcluding.StatementForm className={styles.layout__form} />
              </div>
              <div
                className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--m'])}
              >
                <NotificationFillingAppConcluding.Menu className={styles.layout__menu} />
              </div>
            </NotificationFillingAppConcludingLayoutWrapper>
          </FlatMenuManager>
        </NotificationFillingAppConcluding.Manager>
      </FormProvider>
    </DocumentFormLayout>
  )
}

export default memo(NotificationFillingAppConcludingLayout)
