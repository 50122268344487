import React, { FC, memo } from 'react'

import VersionProvider from '@components/Attachments/Version/manager'
import { useDfosManager } from '@components/Projects/[id]/DfosManager'
import DocumentsNavigation from '@components/Projects/[id]/DocumentLayout/Navigation'
import DocumentsView from '@components/Projects/[id]/DocumentLayout/View'

import styles from './DocumentLayout.module.scss'

const DocumentsLayout: FC = () => {
  const {
    state: { currentDfoInfo },
  } = useDfosManager()

  return (
    <>
      <div className={styles['documentsLayout-navigation']}>
        <div className={styles['documentsLayout-navigation-container']}>
          <DocumentsNavigation currentDfoInfo={currentDfoInfo} />
        </div>
      </div>
      <div className={styles.documentsLayout_view}>
        <VersionProvider key={currentDfoInfo?.id || ''} dfoId={currentDfoInfo?.id || ''}>
          <DocumentsView currentDfoInfo={currentDfoInfo} />
        </VersionProvider>
      </div>
    </>
  )
}

export default memo(DocumentsLayout)
