import React, { FC, memo } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import Button from '@components/NewDesign/Button'
import { NpaFormValues } from '@components/Sidebars/NPA/Edit'
import StabilizedPosition from '@components/Sidebars/NPA/Edit/StabilizedPositions/Item'
import AddButtonIcon from '@icons/AddButtonIcon.svg'
import cn from 'classnames'

import styles from './StabilizedPositions.module.scss'

interface StabilizedPositionsProps {
  formInstance: UseFormReturn<NpaFormValues>
}

const StabilizedPositions: FC<StabilizedPositionsProps> = ({ formInstance }) => {
  const { append, remove, fields } = useFieldArray({
    control: formInstance.control,
    name: 'parts',
  })

  const handleRemoveValue = (index) => () => {
    remove(index)
  }

  const handleAppendValue = () => {
    append({
      part: '',
      classificationId: '',
    })
  }

  return (
    <div className={styles.stabilizedPositions}>
      {fields.map((field, index) => (
        <StabilizedPosition
          key={field.id}
          indexOfField={index}
          name={`parts.${index}.`}
          control={formInstance.control}
          onRemove={handleRemoveValue(index)}
        />
      ))}
      <Button
        variant={'buttonSMedium'}
        size={'s'}
        view={'plain'}
        className={cn(styles.stabilizedPositions__add, {
          [styles['stabilizedPositions__add--empty']]: !fields.length,
        })}
        leadingIcon={{
          src: AddButtonIcon,
        }}
        onClick={handleAppendValue}
      >
        Добавить стабилизируемое положение
      </Button>
    </div>
  )
}

export default memo(StabilizedPositions)
