import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import cn from 'classnames'

import styles from './PageContainer.module.scss'

type StatementsContainerProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const PageContainer: FC<StatementsContainerProps> = ({ children, ...rest }) => {
  return (
    <div {...rest} className={cn(styles.container, rest.className)}>
      {children}
    </div>
  )
}

export default PageContainer
