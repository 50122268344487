import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'

export const ParametersApplicationReimbursementExpensesFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()

export const ParametersApplicationReimbursementExpensesFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<ParametersApplicationReimbursementExpensesFormValues>()

export const ParametersApplicationReimbursementExpensesFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
