import React, { FC } from 'react'
import { PopupProps } from 'react-popup-manager'
import { Props as ReactSVGProps, ReactSVG } from 'react-svg'

import Modal from '@components/NewDesign/Modal'
import { ModalBaseComponentProps } from '@components/NewDesign/Modal/Base'
import BaseModalActions, { BaseActionsModal } from '@components/NewDesign/Modal/Base/Actions'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import styles from './StatisModal.module.scss'

export type StatusModalComponentProps = ModalBaseComponentProps &
  BaseActionsModal & {
    title: string
    icon?: Omit<ReactSVGProps, 'ref'>
    actionsClassName?: string
  }

type StatusModalProps = StatusModalComponentProps & PopupProps

const StatusModal: FC<StatusModalProps> = ({
  title,
  children,
  icon,
  actions,
  actionsClassName,
  contentClassName,
  ...modalBaseComponentProps
}) => {
  //В undefined не выпадает, т.к ниже условный рендер
  const src = icon?.src as string

  const handleClose = () => {
    modalBaseComponentProps.onClose?.()
  }

  return (
    <Modal.Base {...modalBaseComponentProps}>
      <div className={styles.statusModal}>
        {icon && <ReactSVG src={src || ''} {...icon} />}
        <Typography.Headline className={styles.statusModal__title} as={'h3'} variant={'headlineH3'}>
          {title}
        </Typography.Headline>
        <div className={cn(styles.statusModal__content, contentClassName)}>{children}</div>
        <BaseModalActions
          actions={actions}
          className={cn(styles.statusModal__actions, actionsClassName)}
          onClose={handleClose}
        />
      </div>
    </Modal.Base>
  )
}

export default StatusModal
