import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import Loader from '@components/Loader'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import Stack from '@components/ReactBootstrap/Stack'
import { isUndefined } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import DeleteIcon from '@icons/DeleteIcon.svg'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from './Accordion.module.scss'

type DivProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'id'>

interface AccordionProps extends DivProps {
  id: string
  anchorId?: string
  initialAccordionState?: boolean
  initialAccordionMountState?: boolean
  isExpanded?: boolean
  title: string
  additionalNode?: ReactNode
  children: ReactNode
  unmountOnCollapse?: boolean
  contentClassName?: string
  onToggle?: (value: boolean) => void
  onRemove?: VoidFunction
}

const Accordion: FC<AccordionProps> = ({
  initialAccordionState = false,
  initialAccordionMountState = false,
  unmountOnCollapse = false,
  isExpanded,
  id,
  anchorId,
  title,
  additionalNode,
  children,
  contentClassName,
  onToggle,
  onRemove,
  ...props
}) => {
  const { booleanState: isAccordionExpanded, reverseBooleanState: toggleAccordionExpanded } =
    useBooleanState(initialAccordionMountState)

  const currentExpanded = !isUndefined(isExpanded) ? isExpanded : isAccordionExpanded

  const isShowRemoveButton = !isUndefined(onRemove)

  const handleToggleAccordionOnClick = () => {
    toggleAccordionExpanded()

    onToggle?.(!isAccordionExpanded)
  }

  const handleDelete = async (e) => {
    e.preventDefault()

    await onRemove?.()
  }

  return (
    <FlippedWithCollapse flipId={id}>
      <div {...props} id={anchorId} className={styles.accordion__wrapper}>
        <button
          type="button"
          className={cn(styles.accordion, {
            [styles['accordion--opened']]: currentExpanded,
          })}
          onClick={handleToggleAccordionOnClick}
        >
          <div className={styles.accordion__header}>
            <Typography.Headline
              className={styles['accordion__header-title']}
              variant={'headlineH4'}
              as={'h4'}
            >
              {title}
            </Typography.Headline>
            <IconButton
              size={'s'}
              view={'basic'}
              icon={{
                className: cn(styles.toggler, {
                  [styles.toggler__toggled]: currentExpanded,
                }),
                src: chevronRightIcon,
              }}
            />
          </div>
        </button>
        <CollapseWrapper
          unmountOnCollapse={unmountOnCollapse}
          isExpanded={currentExpanded}
          defaultExpanded={!isUndefined(isExpanded) ? isExpanded : initialAccordionState}
        >
          <div className={cn(styles.accordion__content, contentClassName)}>{children}</div>
        </CollapseWrapper>
        <Stack
          direction={'horizontal'}
          gap={isShowRemoveButton ? 2 : 0}
          className={cn(styles['accordion__header-wrapper'], {
            [styles['accordion__header-wrapper--withRemove']]: isShowRemoveButton,
          })}
        >
          {additionalNode}
          {isShowRemoveButton && (
            <AsyncWrapper promise={handleDelete}>
              {({ isLoading, wrappedPromise }) => {
                if (isLoading) {
                  return (
                    <div className={styles['accordion__loader-wrapper']}>
                      <Loader loading variant={'lite'} className={styles['accordion__loader']} />
                    </div>
                  )
                }

                return (
                  <IconButton
                    disabled={isLoading}
                    geometry={'round'}
                    view={'tined'}
                    color={'negative'}
                    dataTestId="FieldView.StepTemplate-delete-iconButton"
                    size={'s'}
                    icon={{ src: DeleteIcon }}
                    onClick={wrappedPromise}
                  />
                )
              }}
            </AsyncWrapper>
          )}
        </Stack>
      </div>
    </FlippedWithCollapse>
  )
}

export default Accordion
