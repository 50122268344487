import { useAPIContext } from '@context/APIContext'
import type { SWRHookProps } from '@interfaces'
import type { TGetProjectMembers } from '@services/Members/Members.entity'
import useSWRImmutable from 'swr/immutable'

const usePossibleSolicitationMembers = ({
  key,
  config,
}: SWRHookProps<TGetProjectMembers | undefined>) => {
  const {
    dfosApi: { getPossibleSolicitationMembers },
  } = useAPIContext()

  const {
    data: possibleSolicitationMembers,
    error,
    mutate,
    isValidating: isLoadingPossibleSolicitationMembers,
  } = useSWRImmutable(key, getPossibleSolicitationMembers, config)

  return {
    possibleSolicitationMembers,
    mutate,
    error,
    isLoadingPossibleSolicitationMembers,
  }
}

export { usePossibleSolicitationMembers }
