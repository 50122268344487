import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import StepAnotherObjectAreasList from '@components/Forms/ProjectSZPKForm/Forms/8/AdditionalForms/AnotherObjectForm/StepAnotherObjectForm/StepAnotherObjectAreasList'
import StepAnotherObjectRegionsList from '@components/Forms/ProjectSZPKForm/Forms/8/AdditionalForms/AnotherObjectForm/StepAnotherObjectForm/StepAnotherObjectRegionsList'
import { anotherObjectValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/8/AdditionalForms/AnotherObjectForm/StepAnotherObjectForm/validation'
import { EighthMeasureObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/8/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface StepAnotherObjectFormProps {
  formName: EighthMeasureObjectsArrayPathName
}

const StepAnotherObjectForm: FC<StepAnotherObjectFormProps> = ({ formName }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const {
    getSubscribableControlProps,
    getInputProps,
    getTextareaProps,
    getSwitchProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const objectIsInfrastructure = useWatch({
    name: `${formName}.objectIsInfrastructure`,
  })

  const isCompensationRenderCondition = !!objectIsInfrastructure

  if (!formInstance) return null

  return (
    <Stack direction={'vertical'} gap={3}>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectName`,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: `${formName}.objectName`,
                rules: anotherObjectValidationMap.objectName,
                textareaProps: {
                  label: 'Наименование',
                },
                onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.objectName`), 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectName`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <StepAnotherObjectRegionsList
        blockViewIsValidating={blockViewIsValidating}
        editMode={editMode}
        formInstance={formInstance}
        formName={formName}
      />
      <StepAnotherObjectAreasList
        editMode={editMode}
        blockViewIsValidating={blockViewIsValidating}
        formInstance={formInstance}
        formName={formName}
      />
      <Row>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.objectConstructionKind`,
          })}
        >
          <ControlledTextarea
            {...getTextareaProps({
              name: `${formName}.objectConstructionKind`,
              rules: anotherObjectValidationMap.objectConstructionKind,
              textareaProps: {
                dataTestId: 'objectConstructionKind',
                label: 'Вид объекта капитального строительства',
                caption: 'например: здание, сооружение, строение',
              },
              onBlur: () =>
                setTimeout(() => {
                  handleChangeValue?.(`${formName}.objectConstructionKind`)
                }, 0),
              onChange: () =>
                setTimeout(() => {
                  debouncedHandleChangeValue?.(`${formName}.objectConstructionKind`)
                }, 0),
            })}
          />
        </SubscribableControl>
      </Row>
      <Row>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.objectPurpose`,
          })}
        >
          <ControlledTextarea
            {...getTextareaProps({
              name: `${formName}.objectPurpose`,
              rules: anotherObjectValidationMap.objectPurpose,
              textareaProps: {
                dataTestId: 'objectPurpose',
                label: 'Назначение объекта',
              },
              onBlur: () =>
                setTimeout(() => {
                  handleChangeValue?.(`${formName}.objectPurpose`)
                }, 0),
              onChange: () =>
                setTimeout(() => {
                  debouncedHandleChangeValue?.(`${formName}.objectPurpose`)
                }, 0),
            })}
          />
        </SubscribableControl>
      </Row>
      <Row>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectConstructionSquare`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectConstructionSquare`,
                rules: anotherObjectValidationMap.objectConstructionSquare,
                inputProps: {
                  dataTestId: 'objectConstructionSquare',
                  label: 'Площадь застройки, кв. м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectConstructionSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectConstructionSquare`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectSquare`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectSquare`,
                rules: anotherObjectValidationMap.objectSquare,
                inputProps: {
                  dataTestId: 'objectSquare',
                  label: 'Площадь, кв.м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectSquare`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectNonLivingAreaSquare`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectNonLivingAreaSquare`,
                rules: anotherObjectValidationMap.objectNonLivingAreaSquare,
                inputProps: {
                  dataTestId: 'objectNonLivingAreaSquare',
                  label: 'Площадь нежилых помещений, кв. м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectNonLivingAreaSquare`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectLivingAreaSquare`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectLivingAreaSquare`,
                rules: anotherObjectValidationMap.objectLivingAreaSquare,
                inputProps: {
                  dataTestId: 'objectLivingAreaSquare',
                  label: 'Площадь жилых помещений, кв. м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectLivingAreaSquare`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectFloor`,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: `${formName}.objectFloor`,
                rules: anotherObjectValidationMap.objectFloor,
                inputProps: {
                  dataTestId: 'objectFloor',
                  label: 'Количество этажей',
                  caption: 'если применимо',
                  suffix: '',
                  maxLength: 10,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectFloor`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectFloor`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectUndergroundFloor`,
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: `${formName}.objectUndergroundFloor`,
                rules: anotherObjectValidationMap.objectUndergroundFloor,
                inputProps: {
                  dataTestId: 'objectUndergroundFloor',
                  label: 'в том числе количество подземных',
                  caption: 'если применимо',
                  suffix: '',
                  maxLength: 10,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectUndergroundFloor`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectUndergroundFloor`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectHeight`,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${formName}.objectHeight`,
                rules: anotherObjectValidationMap.objectHeight,
                inputProps: {
                  dataTestId: 'objectHeight',
                  label: 'Высота, м',
                  caption: 'если применимо',
                  suffix: '',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectHeight`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectHeight`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.objectDetails`,
            })}
          >
            <ControlledTextarea
              {...getTextareaProps({
                name: `${formName}.objectDetails`,
                rules: anotherObjectValidationMap.objectDetails,
                textareaProps: {
                  dataTestId: 'objectDetails',
                  label: 'Иные характеристики объекта',
                  caption: 'если применимо',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${formName}.objectDetails`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${formName}.objectDetails`)
                  }, 0),
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Stack direction={'vertical'} gap={2}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectIsInfrastructure`,
              })}
            >
              <ControlledSwitch
                {...getSwitchProps({
                  name: `${formName}.objectIsInfrastructure`,
                  switchProps: {
                    label: 'Является объектом инфраструктуры',
                    wrapperClassName: styles.form__switch,
                  },
                  onChange: () => handleChangeValue?.(`${formName}.objectIsInfrastructure`),
                })}
              />
            </SubscribableControl>
            <CollapseWrapper
              defaultExpanded={isCompensationRenderCondition}
              isExpanded={isCompensationRenderCondition}
            >
              <Stack direction={'vertical'} gap={3}>
                <Col xs={12}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${formName}.objectInfrastructureType`,
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.name || '',
                              value: item.id,
                            }),

                            selectProps: {
                              inputProps: {
                                dataTestId: 'objectInfrastructureType',
                                label: 'Тип инфраструктуры',
                              },
                              onChangeFormValue: () =>
                                setTimeout(() => {
                                  handleChangeValue?.(`${formName}.objectInfrastructureType`)
                                }),
                            },
                            controllerProps: {
                              name: `${formName}.objectInfrastructureType`,
                              rules: anotherObjectValidationMap.objectInfrastructureType,
                            },
                          })}
                        />
                      )
                    }}
                  </SubscribableControl>
                </Col>
              </Stack>
            </CollapseWrapper>
          </Stack>
        </Col>
      </Row>
    </Stack>
  )
}

export default StepAnotherObjectForm
