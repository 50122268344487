import { memo, useMemo, useRef } from 'react'
import { Path, useWatch } from 'react-hook-form'

import { defaultFiltersValue } from '@components/AuthorizedOrganizations/consts'
import { IFiltersFormValues } from '@components/AuthorizedOrganizations/hooks/useAuthorizedOrganizationsFilters'
import Error500Fallback from '@components/DataFallback/500Error'
import Icon, { IconProps } from '@components/Icon'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMultipleSelect } from '@components/NewDesign/Select'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import { useItemOIVAuthorizedOrganizationsSidebar } from '@components/Sidebars/AuthorizedOrganizations/OIV'
import { useItemAuthorizedOrganizationsOMSUSidebar } from '@components/Sidebars/AuthorizedOrganizations/OMSU'
import TagItem from '@components/TagNew/Item'
import { isIntervalServerError } from '@helpers/errorHelpers'
import { useRegions } from '@hooks/new/swr/useRegions'
import SearchIcon from '@icons/action/search.svg'
import PersonAddIcon from '@icons/social/person_add.svg'

import { useAuthorizedOrganizationsManager } from '../Manager'
import { AuthorizedOrganizations } from '../Manager/types'

import styles from './Header.module.scss'

const personIconProps: IconProps = { src: PersonAddIcon, className: styles.personAddIcon }
const defaultFiltersRefValue = {
  searchString: defaultFiltersValue.searchString,
  regionNumbers: defaultFiltersValue.region,
}

const AuthorizedOrganizationsHeader = () => {
  const {
    state: { gridSwrInstance, filtersInstance },
    handlers: { setAdditionalLoader },
  } = useAuthorizedOrganizationsManager()

  const regionValue = useWatch({
    control: filtersInstance?.control,
    name: 'regionNumbers',
  })

  const cachedTabFormValue = useRef<
    Record<string, Partial<Pick<IFiltersFormValues, 'regionNumbers' | 'searchString'>>>
  >({
    [AuthorizedOrganizations.OMSU]: defaultFiltersRefValue,
    [AuthorizedOrganizations.OIV]: defaultFiltersRefValue,
  })

  const { regions } = useRegions({
    key: { _key: 'projectRegions' },
  })

  const { handleOpenOMSUItemAuthorizedOrganizationsSidebar } =
    useItemAuthorizedOrganizationsOMSUSidebar()
  const { handleOpenItemOIVAuthorizedOrganizationsSidebar } =
    useItemOIVAuthorizedOrganizationsSidebar()

  const regionsToSelect = useMemo(() => {
    if (!regions) return []

    return Object.entries(regions).map(([id, value]) => ({ displayValue: value, value: id }))
  }, [regions])

  const isOIVTagActive = filtersInstance?.watch('type') === AuthorizedOrganizations.OIV

  const handleAddAuthorizedOrganizations = () => {
    (isOIVTagActive
      ? handleOpenItemOIVAuthorizedOrganizationsSidebar
      : handleOpenOMSUItemAuthorizedOrganizationsSidebar)({ onReloadGrid: gridSwrInstance?.mutate })
  }

  if (!filtersInstance) return null

  const handleParticipantsGroupChange = (value: string) => {
    setAdditionalLoader?.(true)

    const valueToApply = { ...cachedTabFormValue.current[value] }
    const filtersValues = filtersInstance.getValues()

    cachedTabFormValue.current[
      value === AuthorizedOrganizations.OIV
        ? AuthorizedOrganizations.OMSU
        : AuthorizedOrganizations.OIV
    ] = {
      searchString: filtersValues.searchString,
      regionNumbers: filtersValues.regionNumbers,
    }

    Object.entries(valueToApply).map(([key, value]) => {
      filtersInstance.setValue(key as Path<IFiltersFormValues>, value)
    })

    setTimeout(() => {
      setAdditionalLoader?.(false)
    }, 400)
  }

  return (
    <Error500Fallback
      className={styles.fallback}
      title={'Не удалось загрузить организации'}
      error={isIntervalServerError(200)}
    >
      <div className={styles.filters}>
        <ControlledRadioGroup
          radioGroupProps={{ type: 'tag', direction: 'horizontal' }}
          name="type"
          control={filtersInstance.control}
          onChange={handleParticipantsGroupChange}
        >
          <TagItem value={AuthorizedOrganizations.OMSU} className={styles.tag}>
            ОМСУ
          </TagItem>
          <TagItem value={AuthorizedOrganizations.OIV} className={styles.tag}>
            РОИВ
          </TagItem>
        </ControlledRadioGroup>
        <div className={styles.filters__rightGroup}>
          {!isOIVTagActive && (
            <ControlledMultipleSelect
              withContextSearch
              staticWidth
              multipleClassName={styles.filters__subject}
              options={regionsToSelect}
              popoverProps={{
                zIndex: 51,
              }}
              inputProps={{
                rootClassName: styles.filters__subject__root,
                fixWidth: true,
                placeholder: regionValue?.length ? '' : 'Субъект РФ',
                view: 'secondary',
                size: 's',
              }}
              controllerProps={{
                name: 'regionNumbers',
                control: filtersInstance.control,
              }}
            />
          )}
          <ControlledInput
            name="searchString"
            control={filtersInstance.control}
            inputProps={{
              rootClassName: styles.filters__search,
              size: 'm',
              placeholder: 'Поиск',
              clear: true,
              view: 'secondary',
              leftAddons: <Icon src={SearchIcon} size="s" className={styles.searchIcon} />,
            }}
          />
          <Button
            leadingIcon={personIconProps}
            className={styles.filters__addButton}
            onClick={handleAddAuthorizedOrganizations}
          >
            Добавить
          </Button>
        </div>
      </div>
    </Error500Fallback>
  )
}

export default memo(AuthorizedOrganizationsHeader)
