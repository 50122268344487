import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
  IConstructionStage,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { defaultRHFValidation } from '@constants/validations'

const eExploitationAAgreementOnChangingCharacteristicsOfObjectsValidationMap: FormValuesValidationSection<
  keyof IConstructionStage['exploitation'],
  AAgreementOnChangingCharacteristicsOfObjectsFormValues
> = {
  objectCommissioningEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { eExploitationAAgreementOnChangingCharacteristicsOfObjectsValidationMap }
