import { ErrorDescriptionTexts } from '@components/Forms/LoginForm/constants'
import type {
  AuthActionCreators,
  AuthReducerAction,
  AuthReducerState,
} from '@components/Forms/LoginForm/LoginByAuth/types'
import { HttpStatusCode } from '@constants/statusCodes'
import { defaultMessageSupportWithLink } from '@constants/texts'
import { isNull } from '@helpers/checkTypes'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

import { authReducerActionMap, reducerAuthComponentInfo } from './constants'

const initialAuthState: AuthReducerState = {
  error: null,
}

const clientErrorRegex = /4[0-9][0-9]/

const authReducer = (state: AuthReducerState, action: AuthReducerAction): AuthReducerState => {
  const { type } = action

  switch (type) {
    case authReducerActionMap.SETUP_NATIVE_ERROR:
      const nativeError = action.payload

      LoggerHelpersService.handleInternalLogError({
        componentInfo: reducerAuthComponentInfo,
      })(nativeError)

      return {
        ...state,
        error: {
          message: nativeError?.message || defaultMessageSupportWithLink,
        },
      }

    case authReducerActionMap.SETUP_ERROR:
      if (isNull(action.payload)) {
        return {
          ...state,
          error: action.payload,
        }
      }

      const error = action.payload
      const errorStatus = error.response?.status
      const errorData = error?.response?.data
      const { detail: errorDetail } = errorData ?? {}

      LoggerHelpersService.handleInternalLogError({
        componentInfo: reducerAuthComponentInfo,
      })(error)

      const isClientError =
        !!errorStatus &&
        errorStatus >= HttpStatusCode.BAD_REQUEST &&
        errorStatus < HttpStatusCode.INTERNAL_SERVER_ERROR

      // TODO: поменять условие, как будет пофикшен баг на бэке со статусами
      if (isClientError || errorDetail?.match(clientErrorRegex)) {
        return {
          ...state,
          error: {
            message: ErrorDescriptionTexts.operatorUnauthorizedMessage,
          },
        }
      }

      return {
        ...state,
        error: {
          message: defaultMessageSupportWithLink,
        },
      }

    default:
      return state
  }
}

const authActionCreators: AuthActionCreators = {
  setError: (payload) => ({
    type: authReducerActionMap.SETUP_ERROR,
    payload,
  }),
  setNativeError: (payload) => ({ type: authReducerActionMap.SETUP_NATIVE_ERROR, payload }),
}

export { authActionCreators, authReducer, initialAuthState }
