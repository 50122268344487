import { FC } from 'react'
import { Path, useFormContext } from 'react-hook-form'

import Group, { GroupProps } from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

interface GroupWithPlanAndFactReportsProps {
  isIntegersOnly?: boolean

  groupTitle: string
  groupProps?: Omit<GroupProps, 'children'>

  planFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>

  factFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
}

const GroupWithPlanAndFactReports: FC<GroupWithPlanAndFactReportsProps> = ({
  isIntegersOnly,
  groupTitle,
  groupProps,
  planFormName,
  factFormName,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  return (
    <Group title={groupTitle} {...groupProps}>
      <Row>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: planFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: planFormName,
                inputProps: {
                  disabled: true,
                  label: 'Плановое значение',
                  ...(isIntegersOnly
                    ? {
                        integersOnly: true,
                        suffix: '',
                      }
                    : {}),
                },
              })}
            />
          </SubscribableControl>
        </Col>
        <Col xs={6}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: factFormName,
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: factFormName,
                inputProps: {
                  disabled: true,
                  label: 'Фактическое значение',
                  ...(isIntegersOnly
                    ? {
                        integersOnly: true,
                        suffix: '',
                      }
                    : {}),
                },
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
    </Group>
  )
}

export default GroupWithPlanAndFactReports
