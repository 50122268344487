import { FC, memo } from 'react'

import Icon from '@components/Icon'
import { Tooltip, TooltipProps } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import PlaceIcon from '@icons/maps/place.svg'

import styles from './Regional.module.scss'

type TRegionalProps = Pick<
  TooltipProps,
  | 'arrowClassName'
  | 'targetClassName'
  | 'popoverClassName'
  | 'contentClassName'
  | 'offset'
  | 'position'
> & {
  text?: string
}

const Regional: FC<TRegionalProps> = ({
  text = 'Региональное соглашение',
  position = 'bottom',
  ...props
}) => {
  const tooltipContent = (
    <Typography.Body variant="bodyMRegular" color="color-text-base-primary">
      {text}
    </Typography.Body>
  )

  return (
    <Tooltip trigger={'hover'} position={position} content={tooltipContent} {...props}>
      <Icon src={PlaceIcon} size="xs" className={styles.regionalIcon} />
    </Tooltip>
  )
}

export default memo(Regional)
