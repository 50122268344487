import React, { FC, useRef } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import SubscribableStepRegisteredDateValueItem from '@components/Forms/RegistrationCertificateForm/Forms/5/StepRegisteredDateValuesList/SubscribableStepRegisteredDateValueItem'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import {
  RegistrationCertificateFieldArrayControlUpdateWatcher,
  RegistrationCertificateFieldsControlUpdateWatcher,
} from '@components/Forms/RegistrationCertificateForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface RegistrationCertificateStepRegisteredDateValuesListProps {
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  subscribableControl: SubscribableFormControlProps<RegistrationCertificateFormValues>
}

const RegistrationCertificateStepRegisteredDateValuesList: FC<
  RegistrationCertificateStepRegisteredDateValuesListProps
> = ({ formInstance, formModifierInstance, subscribableControl }) => {
  const { fields: stepRegisteredDateValues } = useFieldArraySubscribableControl<
    RegistrationCertificateFormValues,
    typeof registrationCertificateBlockValues['5']['stepRegisteredDateValues']
  >({
    control: formInstance.control,
    name: registrationCertificateBlockValues['5'].stepRegisteredDateValues,
    watcher: RegistrationCertificateFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
    subscribableControl,
  })

  const accordionStateRefs = useRef<boolean[]>([])

  const getUpdateAccordionStateHandler = (index: number) => (value: boolean) => {
    accordionStateRefs.current[index] = value
  }

  return (
    <Row>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: registrationCertificateBlockValues['5'].stepRegisteredDateValues,
            })}
          >
            <FlipperList list={stepRegisteredDateValues}>
              {stepRegisteredDateValues.map((step, index) => (
                <SubscribableStepRegisteredDateValueItem
                  id={step.id}
                  key={step.id}
                  indexOfStep={index}
                  initialAccordionState={accordionStateRefs.current[index]}
                  formInstance={formInstance}
                  formModifierInstance={formModifierInstance}
                  onAccordionToggle={getUpdateAccordionStateHandler(index)}
                />
              ))}
            </FlipperList>
          </SubscribableControl>
        </Stack>
      </Col>
    </Row>
  )
}

export default RegistrationCertificateStepRegisteredDateValuesList
