import { StatementOldFormValues } from '@components/Forms/CreateStatementOld/types'
import { IStatementOldCompensation, IStatementStage } from '@context/APIContext/types'

const mapOFMenu = [
  { id: 'investorGeneralInfo', title: 'Информация о компании' },
  { id: 'investorAddress', title: 'Уполномоченный представитель' },
  { id: 'generalInfo', title: 'О проекте' },
  { id: 'stages', title: 'Этапы реализации проекта' },
  { id: 'participantsAndInvestments', title: 'Инвестиции' },
  { id: 'plannedTaxes', title: 'Прогнозируемый объем налогов' },
  { id: 'compensations', title: 'Объем затрат, планируемых к возмещению' },
]

export type ExistingAnchorIds = typeof mapOFMenu[number]['id']

const mapOfDescriptionCheckModal = {
  default:
    'Во время вашей работы над заявлением другой пользователь внес в него изменения. Сохраняя свои изменения, вы удалите изменения другого пользователя. Сохранить ваши изменения?',
  verify:
    'Во время вашей работы над заявлением другой пользователь внес в него изменения. Сохраняя свои изменения, вы удалите изменения другого пользователя. Сохранить изменения перед проверкой?',
} as const

//! При добавлении значений, указать его ниже в blockValues
const defaultFormValues: StatementOldFormValues = {
  investorGeneralInfo: {
    fullName: '',
    name: '',
    inn: '',
    ogrn: '',
    kpp: '',
    head: '',
    position: '',
    reason: '',

    okved: null,

    reasonGenitive: '',
    positionGenitive: '',
    headGenitive: '',

    okpo: '',
    capital: null,
    isProjectCompany: false,
    isVed: false,
  },

  investorAddress: {
    subject: '',
    district: '',
    area: '',
    city: '',
    planningStructureElement: '',
    roadNetworkElement: '',
    house: '',
    room: '',
    roomPart: '',
  },

  generalInfo: {
    ruleParagraph: '',
    headPhone: '',
    headAdditionalPhone: '',
    headEmail: null,
    characteristic: '',
    activityKind: '',
    activityKindOkved: null,
    startDate: '',
    projectEndDate: '',
    szpkEndDate: '',
    newWorkplaces: '',
    newBuildingWorkplaces: '',
  },

  participantsAndInvestments: {
    decisionDate: '',
    endDate: '',
    investorCapital: '',
    investorCapitalVAT: '',
    totalCapitalVAT: '',
    totalCapital: '',
    taxes: [],
  },

  stages: [],
  compensations: [],
}

//Расположение полей по блокам, необходимо для вычисления ошибочных блоков
const blockValues = {
  compensations: 'compensations',
  generalInfo: {
    activityKindOkved: 'generalInfo.activityKindOkved',
    activityKind: 'generalInfo.activityKind',
    ruleParagraph: 'generalInfo.ruleParagraph',
    newWorkplaces: 'generalInfo.newWorkplaces',
    newBuildingWorkplaces: 'generalInfo.newBuildingWorkplaces',
    characteristic: 'generalInfo.characteristic',
  },
  investorAddress: {
    head: 'investorGeneralInfo.head',
    position: 'investorGeneralInfo.position',
    reason: 'investorGeneralInfo.reason',
    headGenitive: 'investorGeneralInfo.headGenitive',
    positionGenitive: 'investorGeneralInfo.positionGenitive',
    reasonGenitive: 'investorGeneralInfo.reasonGenitive',

    headEmail: 'generalInfo.headEmail',
    headPhone: 'generalInfo.headPhone',
    headAdditionalPhone: 'generalInfo.headAdditionalPhone',
  },
  plannedTaxes: 'plannedTaxes',
  investorGeneralInfo: {
    fullName: 'investorGeneralInfo.fullName',
    name: 'investorGeneralInfo.name',
    inn: 'investorGeneralInfo.inn',
    ogrn: 'investorGeneralInfo.ogrn',
    kpp: 'investorGeneralInfo.kpp',
    okpo: 'investorGeneralInfo.okpo',
    okved: 'investorGeneralInfo.okved',
    capital: 'investorGeneralInfo.capital',
    isProjectCompany: 'investorGeneralInfo.isProjectCompany',
    isVed: 'investorGeneralInfo.isVed',

    subject: 'investorAddress.subject',
    city: 'investorAddress.city',
    district: 'investorAddress.district',
    area: 'investorAddress.area',
    planningStructureElement: 'investorAddress.planningStructureElement',
    roadNetworkElement: 'investorAddress.roadNetworkElement',
    house: 'investorAddress.house',
    room: 'investorAddress.room',
    roomPart: 'investorAddress.roomPart',
  },
  stages: {
    stages: 'stages',
    startDate: 'generalInfo.startDate',
    projectEndDate: 'generalInfo.projectEndDate',
    szpkEndDate: 'generalInfo.szpkEndDate',
  },
  participantsAndInvestments: {
    decisionDate: 'participantsAndInvestments.decisionDate',
    endDate: 'participantsAndInvestments.endDate',
    investorCapital: 'participantsAndInvestments.investorCapital',
    totalCapital: 'participantsAndInvestments.totalCapital',
    investorCapitalVAT: 'participantsAndInvestments.investorCapitalVAT',
    totalCapitalVAT: 'participantsAndInvestments.totalCapitalVAT',
  },
} as const

const mapOfActivityKind = {
  HEALTH: 'Здравоохранение',
  EDUCATION: 'Образование',
  CULTURE: 'Культура',
  SPORT: 'Физическая культура и спорт',
  DIGITAL: 'Цифровая экономика',
  ECOLOGY: 'Охрана окружающей среды',
  AGRICULTURE: 'Сельское хозяйство, пищевая и перерабатывающая промышленность',
  MANUFACTUR: 'Обрабатывающее производство',
  AIRPORT: 'Аэровокзалы (терминалы)',
  PUBLIC_TRANSPORT: 'Общественный транспорт городского и пригородного сообщения',
  LOGISTIC: 'Транспортно-логистические центры',
  TOURISM: 'Туризм',
  ANOTHER: 'Иная сфера',
} as const

const mapOfCompensationsType = {
  PROVIDING_INFRASTRUCTURE: 'PROVIDING_INFRASTRUCTURE',
  ASSOCIATED_INFRASTRUCTURE: 'ASSOCIATED_INFRASTRUCTURE',
  LOAN_AND_BOND_PROVIDING_INFRASTRUCTURE: 'LOAN_AND_BOND_PROVIDING_INFRASTRUCTURE',
  LOAN_AND_BOND_ASSOCIATED_INFRASTRUCTURE: 'LOAN_AND_BOND_ASSOCIATED_INFRASTRUCTURE',
  LOAN_AND_BOND_INVESTPROJECT_AND_INTELLECTUAL_ACTIVITY:
    'LOAN_AND_BOND_INVESTPROJECT_AND_INTELLECTUAL_ACTIVITY',
  DISMANTLING_MILITARY_CAMP: 'DISMANTLING_MILITARY_CAMP',
} as const

const mapOfCompensationsTypeByTitle = {
  [mapOfCompensationsType.PROVIDING_INFRASTRUCTURE]: 'Обеспечивающая инфраструктура',
  [mapOfCompensationsType.ASSOCIATED_INFRASTRUCTURE]: 'Сопутствующая инфраструктура',
  [mapOfCompensationsType.LOAN_AND_BOND_PROVIDING_INFRASTRUCTURE]:
    'Проценты по кредитам и займам, купонный доход по облигационным займам (в отношении объектов обеспечивающей инфраструктуры)',
  [mapOfCompensationsType.LOAN_AND_BOND_ASSOCIATED_INFRASTRUCTURE]:
    'Проценты по кредитам и займам, купонный доход по облигационным займам (в отношении объектов сопутствующей инфраструктуры)',
  [mapOfCompensationsType.LOAN_AND_BOND_INVESTPROJECT_AND_INTELLECTUAL_ACTIVITY]:
    'Проценты по кредитам и займам, купонный доход по облигационным займам (в отношении объектов по инвестиционному проекту и РИД)',
  [mapOfCompensationsType.DISMANTLING_MILITARY_CAMP]: 'Демонтаж объектов в военных городках',
} as const

const mapOfCompensationsTypeBeforeMigrate = {
  LOAN: 'LOAN',
  BOND: 'BOND',
} as const

const mapOfCompensationsFormat = {
  TAX_DEDUCTION: 'Налоговый вычет',
  SUBSIDY: 'Субсидия',
} as const

const defaultStepOfStage: IStatementStage = {
  name: '',
  endDate: '',
  startDate: '',
} as const

const defaultStepOfCompensation: IStatementOldCompensation = {
  format: '',
  type: '',
  value: '',
} as const

export {
  blockValues,
  defaultFormValues,
  defaultStepOfCompensation,
  defaultStepOfStage,
  mapOfActivityKind,
  mapOfCompensationsFormat,
  mapOfCompensationsType,
  mapOfCompensationsTypeBeforeMigrate,
  mapOfCompensationsTypeByTitle,
  mapOfDescriptionCheckModal,
  mapOFMenu,
}
