import React, { FC, memo, useCallback } from 'react'

import Icon from '@components/Icon'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import { getFullClassification } from '@components/NewDesign/InputSearchTree/InputSearchTree'
import Typography from '@components/NewDesign/Typography'
import FileView from '@components/Sidebars/NPA/View/FileView'
import { objOfDateFormats } from '@constants/dateFormats'
import { RolesTypes } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { useCollapse } from '@hooks/new/collapse/useCollapse'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import { useNpaTypes } from '@hooks/new/swr/useNpaTypes'
import { useOMSUList } from '@hooks/new/swr/useOMSUList'
import { useRegions } from '@hooks/new/swr/useRegions'
import ChevronSmallIcon from '@icons/ChevronSmallIcon.svg'
import LocationIcon from '@icons/LocationIcon.svg'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { INpaRedaction } from '@services/NPA/NPA.entity'
import cn from 'classnames'

import styles from './MainAct.module.scss'

interface MainActProps {
  redaction: INpaRedaction
}

const MainAct: FC<MainActProps> = ({ redaction }) => {
  const {
    npaApi: { downloadNpaFile },
  } = useAPIContext()

  const {
    isExpanded: complexNameIsCollapsed,
    getToggleProps,
    getCollapseProps,
  } = useCollapse({
    defaultExpanded: false,
    duration: 300,
  })

  const { mapOfNpaTypes } = useNpaTypes({
    key: {
      _key: 'npaTypes',
    },
  })

  const { regions, isRegionsLoading } = useRegions({
    key: {
      _key: 'regions',
    },
  })

  const { partClassifications } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  const OMSUOrganizationsKey = useCallback(
    (page) => ({
      _key: 'OMSU_List',
      page,
      size: 1,
      type: RolesTypes.OMSU,
      searchString: redaction.oktmo,
    }),
    [redaction.oktmo],
  )

  const { OMSUOrganizationsList, isLoadingOMSUOrganizationsList } = useOMSUList({
    key: OMSUOrganizationsKey,
    config: {
      revalidateOnMount: true,
      isPaused: () => !redaction.oktmo,
    },
  })

  const { partClassificationInObjectMap } = useNpaPartClassification({
    key: 'partClassification',
  })

  const renderMunicipalityByOKTMO =
    redaction.oktmo && OMSUOrganizationsList?.length
      ? `${OMSUOrganizationsList[0].municipalityName}`
      : ''

  const handleDownloadDocument = async () => {
    if (!redaction.documentId || !redaction.documentName || !redaction.documentExtension) return

    try {
      await downloadNpaFile({
        documentId: redaction.documentId,
        name: redaction.documentName,
        extension: redaction.documentExtension,
      })
    } catch (e) {}
  }

  return (
    <div className={styles.mainAct}>
      {redaction.region && regions && (
        <Loader loading={isRegionsLoading || isLoadingOMSUOrganizationsList} variant={'lite'}>
          <div className={styles.mainAct__location}>
            <Icon
              noCurrentColorSvgFill
              className={styles['mainAct__location-icon']}
              src={LocationIcon}
              size={'initial'}
            />
            <Typography
              className={styles['mainAct__location-text']}
              color={'text-base-secondary'}
              variant={'bodyMMedium'}
            >
              {`${regions[redaction.region]}${renderMunicipalityByOKTMO.length ? ',' : ' '}${
                renderMunicipalityByOKTMO.length > 24 ? `\n` : ' '
              }${renderMunicipalityByOKTMO}`}
            </Typography>
          </div>
        </Loader>
      )}
      <Typography.Body className={styles.mainAct__title} variant={'bodyLMedium'}>
        {mapOfNpaTypes?.[redaction.type]} №{redaction.number} от{' '}
        {DayjsService.dayjs(redaction.date).format(objOfDateFormats.defaultFormat)}
      </Typography.Body>
      <Typography.Body
        className={styles.mainAct__description}
        variant={'bodyMRegular'}
        color={'text-base-secondary'}
      >
        Ред. {redaction.redactionNumber} от{' '}
        {DayjsService.dayjs(redaction.redactionDate).format(objOfDateFormats.defaultFormat)}
      </Typography.Body>

      {redaction.complexName && (
        <div className={styles.mainAct__complexName}>
          <Button
            className={styles.mainAct__button}
            size={'xs'}
            view={'text'}
            trailingIcon={{
              className: cn(styles.mainAct__chevron, {
                [styles['mainAct__chevron--toggled']]: complexNameIsCollapsed,
              }),
              size: 'initial',
              noCurrentColorSvgFill: true,
              src: ChevronSmallIcon,
            }}
            {...getToggleProps()}
          >
            {!complexNameIsCollapsed
              ? 'Показать полное наименование'
              : 'Скрыть полное наименование'}
          </Button>

          <div {...getCollapseProps()}>
            <div>
              <Typography.Body
                className={styles['mainAct__complexName-content']}
                variant={'bodyMRegular'}
              >
                {redaction.complexName}
              </Typography.Body>
            </div>
          </div>
        </div>
      )}

      {redaction.documentId && redaction.documentName && redaction.documentExtension && (
        <FileView
          className={styles.mainAct__document}
          file={{
            documentExtension: redaction.documentExtension,
            documentName: redaction.documentName,
            documentSize: redaction.documentSize,
          }}
          onDownload={handleDownloadDocument}
        />
      )}

      {!!redaction.parts.length && (
        <div className={styles.mainAct__items}>
          {redaction.parts.map((part, index) => {
            const classificationHeader = getFullClassification({
              partClassifications: partClassifications ?? [],
              value: part.classificationId,
            })

            return (
              <div key={index} className={styles.mainAct__item}>
                <Typography.Body variant={'bodyLMedium'}>{part.part}</Typography.Body>
                <Typography.Body variant={'bodyMRegular'}>{classificationHeader}</Typography.Body>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default memo(MainAct)
