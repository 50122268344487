import { FC } from 'react'

import InitializeAuthenticatedGlobalState from '@routes/InitializeEntities/GlobalState/Authenticated'
import InitializeNotAuthenticatedGlobalState from '@routes/InitializeEntities/GlobalState/NotAuthenticated'

interface InitializeGlobalStateProps extends FC {
  Authenticated: typeof InitializeAuthenticatedGlobalState
  NotAuthenticated: typeof InitializeNotAuthenticatedGlobalState
}

const InitializeGlobalState: InitializeGlobalStateProps = () => null

InitializeGlobalState.Authenticated = InitializeAuthenticatedGlobalState
InitializeGlobalState.NotAuthenticated = InitializeNotAuthenticatedGlobalState

export default InitializeGlobalState
