import { memo, useMemo, useState } from 'react'

import OIVItem from '@components/AuthorizedOrganizations/OIVItem'
import OMSUItem from '@components/AuthorizedOrganizations/OMSUItem'
import NoDataPlaceholder from '@components/DataFallback/NoDataPlaceholder'
import SearchPlaceholder from '@components/DataFallback/SearchPlaceholder'
import Loader from '@components/Loader'
import PageContainer from '@containers/PageContainer'
import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'
import useLoadMore from '@hooks/useLoadMore'

import { useAuthorizedOrganizationsManager } from '../Manager'
import { AuthorizedOrganizations } from '../Manager/types'

import styles from './Grid.module.scss'

const AuthorizedOrganizationsGrid = () => {
  const {
    state: { additionalLoader, gridSwrInstance, debouncedSearchValue, filtersInstance },
  } = useAuthorizedOrganizationsManager()
  const { authorizedOrganizationsList, isAuthorizedOrganizationsListLoading, error, goToNextPage } =
    gridSwrInstance ?? {}

  const [searchPlaceholderCondition, setSearchPlaceholderCondition] = useState<boolean>(false)

  const gridIsLoading = additionalLoader || isAuthorizedOrganizationsListLoading
  const gridIsRender = !gridIsLoading && !error && !!authorizedOrganizationsList?.length

  const noDataCondition = useMemo(() => {
    return (
      !error &&
      !gridIsLoading &&
      !authorizedOrganizationsList?.length &&
      !debouncedSearchValue.length
    )
  }, [error, gridIsLoading, authorizedOrganizationsList?.length, debouncedSearchValue.length])

  const isOIVTagActive = filtersInstance?.watch('type') === AuthorizedOrganizations.OIV

  useUpdateEffect(() => {
    setSearchPlaceholderCondition(false)

    if (!gridIsLoading && debouncedSearchValue.length && !authorizedOrganizationsList?.length)
      setSearchPlaceholderCondition(true)
  }, [debouncedSearchValue, gridIsLoading, authorizedOrganizationsList?.length])

  const { getObserveRef } = useLoadMore({ onLoadMore: goToNextPage })

  const CurrentGridItem = isOIVTagActive ? OIVItem : OMSUItem

  return (
    <PageContainer key={filtersInstance?.watch('type')} className={styles.container}>
      <div>
        {noDataCondition && <NoDataPlaceholder className={styles.placeholder} />}
        {searchPlaceholderCondition && !gridIsLoading && (
          <SearchPlaceholder className={styles.placeholder}>
            {debouncedSearchValue}
          </SearchPlaceholder>
        )}
        {gridIsRender &&
          authorizedOrganizationsList?.map((item, index, list) => (
            <div ref={getObserveRef(index, list.length - 1)}>
              <CurrentGridItem
                key={item.inn}
                className={styles.item}
                authorizedOrganization={item}
                onReloadGrid={gridSwrInstance?.mutate}
              />
            </div>
          ))}
        <Loader loading={gridIsLoading} wrapperClassName={styles.Loader} />
      </div>
    </PageContainer>
  )
}

export default memo(AuthorizedOrganizationsGrid)
