import { useEffect, useState } from 'react'

import { useStageLineProcess } from '@components/Projects/[id]/ActionBanner/hooks/useStageLineProcess'
import { DFOSStage } from '@context/APIContext/hooks/useDFOSApi'
import { useBooleanState } from '@hooks/useBooleanState'

interface useActionBannerStatusProps {
  params?: DFOSStage | null
  projectId?: string
}

const useActionBannerStatus = ({ params, projectId }: useActionBannerStatusProps) => {
  const { booleanState: stageLineInfoProcessing, setBooleanState: setStageLineProcessing } =
    useBooleanState()

  const [processedStageLineHeader, setProcessedStageLineHeader] = useState<string>('')
  const [processedStageLineDescription, setProcessedStageLineDescription] = useState<string>('')

  const { parseStageLineInfo } = useStageLineProcess(params, projectId)

  //Логика формирования информации для ActionBanner Title And Description
  useEffect(() => {
    if (!projectId || !params?.stageLineDescription || !params?.stageLineHeader) return
    ;(async () => {
      setStageLineProcessing(true)

      try {
        try {
          const readyStageLineHeader = parseStageLineInfo(params.stageLineHeader)
          setProcessedStageLineHeader(await readyStageLineHeader)
        } catch {
          setProcessedStageLineHeader('')
        }

        const readyStageLineDescription = parseStageLineInfo()

        setProcessedStageLineDescription(await readyStageLineDescription)
      } catch {
        setProcessedStageLineDescription('')
      } finally {
        setStageLineProcessing(false)
      }
    })()
  }, [params, parseStageLineInfo, projectId, setStageLineProcessing])

  return {
    processedStageLineHeader,
    processedStageLineDescription,
    stageLineInfoProcessing,
  }
}

export { useActionBannerStatus }
