import React, { FC, memo, useCallback, useEffect, useMemo } from 'react'
import toast from 'react-hot-toast'
import { usePopupManager } from 'react-popup-manager'

import Alert from '@components/Alert'
import Button from '@components/NewDesign/Button'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { Tooltip } from '@components/NewDesign/Tooltip'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import { useDfosManager } from '@components/Projects/[id]/DfosManager'
import { useVersionController } from '@components/Projects/[id]/VersionConroller'
import { objOfDateFormats } from '@constants/dateFormats'
import { Roles } from '@constants/types'
import { GetVersionData } from '@context/APIContext/types'
import { useAuthContext } from '@context/AuthContext'
import { findOne } from '@helpers/commonHelpers'
import ExpandLessIcon from '@icons/navigation/expand_less.svg'
import ExpandMoreIcon from '@icons/navigation/expand_more.svg'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { IDfoListItem } from '@services/Dfo/Dfo.entity'

import VersionToaster from './Toaster'
import styles from './Version.module.scss'

type VersionProps = Pick<GetVersionData, 'version' | 'versionDateTime' | 'id' | 'versionNumber'>

type VersionButtonProps = {
  dfo: IDfoListItem | null
  shouldRenderLessTwoVersions?: boolean
  hasPermissionsWithAction?: boolean
  versionName?: string
  versions?: GetVersionData[]
  openButtonProps?: ButtonProps
  onResetOnlyChangedDocumentsSwitch?: () => void
  updateDocumentSets?: () => void
}

export const formattedVersionDate = (versionDate: string) =>
  DayjsService.dayjs(versionDate).format(objOfDateFormats.currentYear.fullFormat)

const VersionButton: FC<VersionButtonProps> = ({
  dfo,
  shouldRenderLessTwoVersions,
  hasPermissionsWithAction,
  versionName,
  versions,
  openButtonProps,
  onResetOnlyChangedDocumentsSwitch,
  updateDocumentSets,
}) => {
  const {
    handlers: { handleChangeDfo },
  } = useDfosManager()

  const popupManager = usePopupManager()

  const dfoId = dfo?.id

  const {
    state: { tooltipIsOpen },
    handlers: { handleOpenTooltip, handleCloseTooltip },
  } = useManualTooltipControl()

  const { getUserRole } = useAuthContext()

  const { roles } = getUserRole?.()

  const isInvestor = findOne(roles, Roles.Investor)

  const actualVersionId = useMemo(
    () => (versions?.length ? versions?.find(({ isActual }) => isActual) : undefined),
    [versions],
  )?.id
  //Если на версию нет прав, то она не возвращается.
  const hasAccessToActualVersion = useMemo(
    () => versions?.some((version) => version.isActual),
    [versions],
  )
  const versionState = useVersionController()

  const handleCloseToast = useCallback(() => {
    toast.remove(dfoId || '')

    versionState.reset()

    onResetOnlyChangedDocumentsSwitch?.()

    if (dfo && actualVersionId) {
      const preparedDfoInfoObj = Object.assign(dfo, { id: actualVersionId })

      handleChangeDfo?.(preparedDfoInfoObj)
    }

    popupManager.closeAll()
  }, [
    dfoId,
    versionState,
    onResetOnlyChangedDocumentsSwitch,
    dfo,
    actualVersionId,
    handleChangeDfo,
    popupManager,
  ])

  const handleSelectVersion = ({
    id,
    dfoReadPermission,
    version,
    versionDateTime,
    versionNumber,
  }: VersionProps & Pick<GetVersionData, 'dfoReadPermission'>) => {
    toast.remove()

    handleCloseTooltip()

    const isOldVersion = versions?.some((version) => version.id === dfoId && !version.isActual)

    const isNotActualId = actualVersionId !== id

    if (!isOldVersion && isNotActualId) {
      updateDocumentSets?.()
    }

    if (!dfoReadPermission || !dfo) {
      toast(
        <Alert transparent variant="warning">
          Недостаточно прав для просмотра данной версии
        </Alert>,
      )

      return
    }

    versionState.set({ version, versionDateTime, id, versionNumber })

    onResetOnlyChangedDocumentsSwitch?.()

    const preparedDfoInfoObj = Object.assign(dfo, { id })

    handleChangeDfo?.(preparedDfoInfoObj)

    if (isNotActualId) {
      toast(
        <VersionToaster
          isInvestor={isInvestor}
          hasAccessToActualVersion={hasAccessToActualVersion}
          hasPermissionsWithAction={hasPermissionsWithAction}
          versionNumber={versionNumber}
          versionDateTime={formattedVersionDate(versionDateTime ?? '')}
          handleCloseToast={handleCloseToast}
        />,
        {
          duration: Infinity,
          id,
        },
      )
    }
  }

  const tooltipContent = (
    <div className={styles.version__list}>
      {(versions ?? []).map(
        ({ version, versionNumber, versionDateTime, isActual, dfoReadPermission, id }) => (
          <button
            key={id}
            type="button"
            data-testid={`VersionButton-version-${versionNumber}-button`}
            className={styles.version__list__content}
            onClick={() =>
              handleSelectVersion({
                id,
                dfoReadPermission,
                version,
                versionDateTime,
                versionNumber,
              })
            }
          >
            <Typography
              variant="bodyMMedium"
              color="text-accent-brand-normal"
              className={((dfoId ? dfoId === id : isActual) && styles.active) || ''}
            >
              {version}
            </Typography>
            <Typography variant="captionSRegular" color="text-base-secondary">
              {formattedVersionDate(versionDateTime)}
            </Typography>
          </button>
        ),
      )}
    </div>
  )

  useEffect(() => {
    if (versions && versions?.length < 2) return

    if (versions?.some((version) => version.id === dfoId && !version.isActual) && dfoId) {
      const currentVersionObject = versions?.find((item) => item.id === dfoId)

      toast(
        <VersionToaster
          isInvestor={isInvestor}
          versionNumber={currentVersionObject?.versionNumber}
          versionDateTime={formattedVersionDate(currentVersionObject?.versionDateTime ?? '')}
          hasAccessToActualVersion={hasAccessToActualVersion}
          hasPermissionsWithAction={hasPermissionsWithAction}
          handleCloseToast={handleCloseToast}
        />,

        {
          duration: Infinity,
          id: dfoId,
        },
      )
    }

    if (versions?.some((version) => version.id === dfoId && version.isActual)) {
      toast.remove(dfoId ?? '')
    }

    return () => {
      toast.remove(dfoId ?? '')
    }
  }, [versions])

  useEffect(() => {
    const currentVersion = versions?.find((item) => item.id === dfoId)

    versionState.set({
      id: dfoId ?? '',
      version: currentVersion?.version ?? '',
      versionDateTime: formattedVersionDate(currentVersion?.versionDateTime ?? ''),
      versionNumber: currentVersion?.versionNumber || 0,
    })
  }, [versions, dfoId])

  if (!versions?.length || (versions?.length < 2 && !shouldRenderLessTwoVersions)) return null

  const displayVersionValue = versionState.versionNumber
    ? `Текущая версия: ${versionState.versionNumber}`
    : versionName

  const trailingIcon =
    versions.length > 1
      ? {
          noCurrentColorSvgFill: true,
          src: tooltipIsOpen ? ExpandLessIcon : ExpandMoreIcon,
          className: styles.button__icon,
        }
      : undefined

  return (
    <Tooltip
      open={tooltipIsOpen}
      content={tooltipContent}
      trigger={'click'}
      position={'bottom-start'}
      contentClassName={styles.version__content}
      targetClassName={styles.version__target}
      arrowClassName={styles.version__arrow}
      onClose={handleCloseTooltip}
    >
      <Button
        size="s"
        color="default"
        view="gray"
        geometry="round"
        trailingIcon={trailingIcon}
        variant={'buttonSMedium'}
        dataTestId="VersionButton-version-button"
        onClick={() => versions.length > 1 && handleOpenTooltip()}
        {...openButtonProps}
      >
        {displayVersionValue}
      </Button>
    </Tooltip>
  )
}

export default memo(VersionButton)
