import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { ApplicationConclusionAdditionalAgreementFormFormValues } from '@components/Forms/ApplicationConclusionAdditionalAgreementForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const secondSectionValidationMap: FormValuesValidationSection<
  keyof ApplicationConclusionAdditionalAgreementFormFormValues['2'],
  ApplicationConclusionAdditionalAgreementFormFormValues
> = {
  ruleLinkExtraReason: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
}

export { secondSectionValidationMap }
