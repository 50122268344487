import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { SupplementalCostSharingAContractDutyObjectAreasPathName } from '@components/Forms/SupplementalCostSharingAForm/Forms/7/types'
import { useSupplementalCostSharingAManager } from '@components/Forms/SupplementalCostSharingAForm/Manager'
import { SupplementalCostSharingAFormValues } from '@components/Forms/SupplementalCostSharingAForm/types'
import {
  SupplementalCostSharingAFieldArrayControlUpdateWatcher,
  SupplementalCostSharingAFieldsControlUpdateWatcher,
} from '@components/Forms/SupplementalCostSharingAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface SupplementalCostSharingAObjectAreasListProps {
  formInstance: UseFormReturn<SupplementalCostSharingAFormValues>
  formName: SupplementalCostSharingAContractDutyObjectAreasPathName
}

const SupplementalCostSharingAObjectAreasList: FC<SupplementalCostSharingAObjectAreasListProps> = ({
  formInstance,
  formName,
}) => {
  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useSupplementalCostSharingAManager()

  const { getSubscribableControlProps, getInputProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: SupplementalCostSharingAFieldsControlUpdateWatcher,
    })

  const { fields: objectAreas } = useFieldArraySubscribableControl<
    SupplementalCostSharingAFormValues,
    SupplementalCostSharingAContractDutyObjectAreasPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: formName,
    keyName: 'keyNameId',
    watcher: SupplementalCostSharingAFieldArrayControlUpdateWatcher,
  })

  if (!objectAreas.length) return null

  return (
    <Group disableBottomBorder title="Земельные участки">
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: formName,
          })}
        >
          {objectAreas.map((objectArea, index) => {
            const objectAreaFormName = `${formName}.${index}` as const

            return (
              <Group
                disableBottomBorder
                key={objectArea.id}
                title={`Земельный участок №${index + 1}`}
              >
                <Row>
                  <Col xs={6}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${objectAreaFormName}.objectAreaNumber`,
                      })}
                    >
                      <ControlledInput
                        {...getInputProps({
                          name: `${objectAreaFormName}.objectAreaNumber`,
                          inputProps: {
                            disabled: true,
                            label: 'Кадастровый номер з/у',
                            caption: 'если применимо',
                          },
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                  <Col xs={6}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${objectAreaFormName}.objectAreaSquare`,
                      })}
                    >
                      <ControlledAmountInput
                        {...getAmountInputProps({
                          name: `${objectAreaFormName}.objectAreaSquare`,
                          inputProps: {
                            disabled: true,
                            label: 'Площадь з/у, кв. м',
                            suffix: '',
                          },
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              </Group>
            )
          })}
        </SubscribableControl>
      </Stack>
    </Group>
  )
}

export default SupplementalCostSharingAObjectAreasList
