import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { getStrValueFromNumber } from '@components/NewDesign/AmountInput/utils'
import { objOfDateFormats } from '@constants/dateFormats'
import { isEmptyString, isFunction, isNull, isNullOrUndefined } from '@helpers/checkTypes'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { BasePropertyState } from '@services/Properties/OOP/BaseClass'
import {
  PropertyTypeEnum,
  TPropertyValueFieldName,
  TPropertyValueSimple,
} from '@services/Properties/Properties.entity'
import { generateDefaultValueByType } from '@services/Properties/Properties.helpers'
import {
  PropertiesWatcher,
  SubscriberPropertiesWatcherUpdateValueProps,
} from '@services/Properties/Properties.watcher'

const { formatSeparatedValue } = DocumentFormHelpers

interface PropertyStateSimpleModifiers {
  '@new': boolean
}

const generateSimpleValueByType = (
  property: TPropertyValueSimple,
  propertyValueName: TPropertyValueFieldName = '@value',
) => {
  if (property['@type'] === PropertyTypeEnum.STRING) {
    return isNullOrUndefined(property[propertyValueName]) ? '' : property[propertyValueName]
  }

  if (property['@type'] === PropertyTypeEnum.BOOLEAN) {
    return isNullOrUndefined(property[propertyValueName]) ? false : property[propertyValueName]
  }

  if (property['@type'] === PropertyTypeEnum.DOUBLE) {
    if (
      isNullOrUndefined(property[propertyValueName]) ||
      isEmptyString(property[propertyValueName])
    )
      return ''

    return formatSeparatedValue(getStrValueFromNumber(property[propertyValueName]))
  }

  if (property['@type'] === PropertyTypeEnum.DATE) {
    return isEmptyString(property[propertyValueName]) || isNull(property[propertyValueName])
      ? ''
      : DayjsService.dayjs(
          property[propertyValueName],
          objOfDateFormats.defaultNativeDateFormat,
        ).format(objOfDateFormats.defaultFormat)
  }

  if (isNullOrUndefined(property[propertyValueName]) || isEmptyString(property[propertyValueName]))
    return ''

  return String(property[propertyValueName])
}

class PropertyStateSimple extends BasePropertyState {
  private _propertyValueInfo: TPropertyValueSimple | null = null
  private _propertyModifiersInfo: PropertyStateSimpleModifiers | null = null

  constructor(
    propertyKey: string,
    propertyValue: TPropertyValueSimple,
    watcher: PropertiesWatcher,
  ) {
    super(propertyKey, {
      '@id': propertyValue['@id'],
      '@isPublic': propertyValue['@isPublic'],
      '@lastUpdateDt': propertyValue['@lastUpdateDt'],
      '@order': propertyValue['@order'],
      '@permanentId': propertyValue['@permanentId'],
    })

    this._propertyValueInfo = {
      '@type': propertyValue['@type'],
      '@isPublic': propertyValue['@isPublic'],
      '@value': generateDefaultValueByType(propertyValue),
      '@commitedValue': propertyValue['@commitedValue'],
    } as TPropertyValueSimple

    this._propertyModifiersInfo = {
      '@new': propertyValue['@new'] ?? false,
    }

    if (this.getPropertyId) {
      watcher.subscribe(this.getPropertyId, {
        updateValueCallback: this.updateValueCallback,
      })
    }
  }

  get getType() {
    return this._propertyValueInfo?.['@type']
  }

  get getNew() {
    return this._propertyModifiersInfo?.['@new']
  }

  get getIsPublic() {
    return this._propertyValueInfo?.['@isPublic']
  }

  private readonly updateValueCallback = (
    newValue: SubscriberPropertiesWatcherUpdateValueProps['updateValue'],
  ) => {
    const objectToUpdate = isFunction(newValue) ? newValue(this._propertyValueInfo) : newValue

    this.setLastUpdateDt(objectToUpdate['@lastUpdateDt'])
    this.setOrder(objectToUpdate['@order'] ?? this.getOrder)

    this._propertyValueInfo = {
      '@type': objectToUpdate['@type'],
      '@value': objectToUpdate['@value'],
      '@isPublic': objectToUpdate['@isPublic'],
      '@commitedValue': objectToUpdate['@commitedValue'],
    }

    this._propertyModifiersInfo = {
      '@new': objectToUpdate['@new'] ?? false,
    }
  }

  generatePropertiesProps() {
    if (!this._propertyValueInfo) return null
    return {
      propertyId: this.getPropertyId,
      type: this.getType,
      lastUpdateDt: this.getLastUpdateDate,
      new: this.getNew,
    }
  }

  generateObjectForAdapterFromPropertiesState() {
    if (!this._propertyValueInfo) return null

    return {
      ...this.generatePropertiesProps(),
      permanentId: this.getPermanentId,
      isPublic: this.getIsPublic,
      value: generateSimpleValueByType(this._propertyValueInfo),
      commitedValue: generateSimpleValueByType(this._propertyValueInfo, '@commitedValue'),
    }
  }

  getOverrideProps() {
    if (!this._propertyValueInfo) return null

    return null
  }
}

export { generateSimpleValueByType }
export { PropertyStateSimple }
