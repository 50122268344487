import { ComponentType } from 'react'
import { Navigate, useLocation } from 'react-router'

import { Paths } from '@constants/paths'
import { useAuthContext } from '@context/AuthContext'
import useIsFirstRender from '@hooks/new/effects/useIsFirstRender'

function withAuthProtect<T = any>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const { authState } = useAuthContext()
    const location = useLocation()

    const isFirstRender = useIsFirstRender()

    const stateToApply = isFirstRender
      ? {
          redirectFrom: location,
        }
      : undefined

    if (!authState?.token) {
      return <Navigate replace to={Paths.Login} state={stateToApply} />
    }

    return <WrappedComponent {...props} />
  }
}

export default withAuthProtect
