import React, { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'

import { BaseMenuHeaderIconProps } from '@components/Sidebars/MainHeaderMenu/PreparedIcons/types'
import ProjectsIcon from '@icons/project.svg'
import cn from 'classnames'

import styles from './AgreementIcon.module.scss'

const AgreementIcon: FC<BaseMenuHeaderIconProps> = ({ isActive }) => {
  return (
    <ReactSVG
      className={cn(styles.agreementIcon, { [styles.agreementIcon__active]: isActive })}
      src={ProjectsIcon}
    />
  )
}

export default memo(AgreementIcon)
