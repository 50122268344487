import { FC } from 'react'

import { NpaFormLayoutProps } from '@components/DocumentFormComponents/types'
import NpaSolicitationInclusionFormLayoutWithFormProvider from '@components/Forms/NpaSolicitationInclusionForm/Layout/LayoutWithFormProvider'

import NpaSolicitationInclusionFormActions from './Actions'
import NpaSolicitationInclusionFormManager from './Manager'
import NpaSolicitationInclusionFormStatement from './Statement'

interface NpaSolicitationInclusionFormFC extends FC<NpaFormLayoutProps> {
  Manager: typeof NpaSolicitationInclusionFormManager
  Actions: typeof NpaSolicitationInclusionFormActions
  Statement: typeof NpaSolicitationInclusionFormStatement
}

const NpaSolicitationInclusionForm: NpaSolicitationInclusionFormFC = (props) => (
  <NpaSolicitationInclusionFormLayoutWithFormProvider {...props} />
)

NpaSolicitationInclusionForm.Manager = NpaSolicitationInclusionFormManager
NpaSolicitationInclusionForm.Actions = NpaSolicitationInclusionFormActions
NpaSolicitationInclusionForm.Statement = NpaSolicitationInclusionFormStatement

export default NpaSolicitationInclusionForm
