import React, { FC, memo } from 'react'

import { useWebFormAPIContext } from '@components/Attachments/WebForm/APIProvider'
import { useWebFormPermissions } from '@components/Attachments/WebForm/hooks/useWebFormPermissions'
import { WebFormStateControllerProps } from '@components/Attachments/WebForm/WIthoutDocumentController/types'
import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import ControlContainerSubtitle from '@components/ControlContainer/Subtitle'
import Button from '@components/NewDesign/Button'
import env from '@config'

const WebFormNotFoundStateController: FC<WebFormStateControllerProps> = ({
  disabled,
  permissions,
  documentSetId,
  documentInfoFromDict,
  onSuccess,
  onOpenForm,
}) => {
  const { isRequired, description: title, documentType } = documentInfoFromDict

  const {
    isWebFormReadPermission,
    isWebFormReadEditPermission,
    isWebFormReadApprovePermission,
    isWebFormReadDigitizing,
    isWebFormReadAddSignPermission,
  } = useWebFormPermissions(permissions)

  const {
    state: { webFormIsLoading },
    handlers: { handleCreateWebFormDocument },
  } = useWebFormAPIContext()

  const isStaticForm =
    isWebFormReadPermission || isWebFormReadApprovePermission || isWebFormReadDigitizing

  const handleSuccessCreateWebForm = async () => {
    await onSuccess()

    onOpenForm?.(true)()
  }

  const handleCreateWebFormOnClick = async () => {
    await handleCreateWebFormDocument?.({
      documentSetId,
      documentType,
      onSuccess: handleSuccessCreateWebForm,
    })
  }

  if (isRequired) {
    return (
      <ControlContainer
        color="negative"
        title={title}
        leadingNodeContent={<ControlContainerStatusIcon iconType="error" />}
        additionalMainContent={
          <ControlContainerSubtitle
            additionalContent={
              <Button
                href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}
                size="xs"
                view="text"
                variant="buttonSMedium"
                color="negative"
              >
                Сообщить в поддержку
              </Button>
            }
          >
            Произошла ошибка получения формы документа.
          </ControlContainerSubtitle>
        }
      />
    )
  }

  if (isStaticForm) return null

  if (!isRequired && (isWebFormReadEditPermission || isWebFormReadAddSignPermission)) {
    return (
      <ControlContainer
        color="tertiary"
        title={title}
        leadingNodeContent={<ControlContainerStatusIcon iconType="noForm" />}
        additionalMainContent={
          <ControlContainerSubtitle>
            Документ не является обязательным. Чтобы подать его, заполните форму
          </ControlContainerSubtitle>
        }
        trailingNodeContent={
          <Button
            fixWidth
            disabled={webFormIsLoading || disabled}
            view="tined"
            dataTestId="fillFormButton"
            loaderProps={{
              variant: 'lite',
              placement: 'leading',
              loading: webFormIsLoading,
            }}
            onClick={handleCreateWebFormOnClick}
          >
            Заполнить форму
          </Button>
        }
      />
    )
  }

  if (!isRequired && (!isWebFormReadEditPermission || !isWebFormReadAddSignPermission)) {
    return (
      <ControlContainer
        color="tertiary"
        title={title}
        leadingNodeContent={<ControlContainerStatusIcon iconType="noForm" />}
        additionalMainContent={
          <ControlContainerSubtitle>
            Документ необязательный и его не начали заполнять
          </ControlContainerSubtitle>
        }
      />
    )
  }

  return (
    <ControlContainer
      color="negative"
      title={title}
      leadingNodeContent={<ControlContainerStatusIcon iconType="error" />}
      additionalMainContent={
        <ControlContainerSubtitle
          additionalContent={
            <Button
              href={`mailto:${env.REACT_APP_SUPPORT_EMAIL || ''}`}
              size="xs"
              view="text"
              variant="buttonSMedium"
              color="negative"
            >
              Сообщить в поддержку
            </Button>
          }
        >
          Произошла ошибка получения формы документа.
        </ControlContainerSubtitle>
      }
    />
  )
}

export default memo(WebFormNotFoundStateController)
