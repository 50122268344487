import { useAPIContext } from '@context/APIContext'
import { IOrganizations } from '@context/APIContext/hooks/useOrganizationsApi'
import { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useAllOrganizationsList = ({ key, config }: SWRHookProps<IOrganizations | undefined>) => {
  const {
    organizationsApi: { getAllOrganizationsList },
  } = useAPIContext()

  const {
    data: allOrganizations,
    isValidating: isLoadingAllOrganizations,
    error,
    mutate,
  } = useSWRImmutable(key, getAllOrganizationsList, config)

  return {
    allOrganizations,
    isLoadingAllOrganizations,
    mutate,
    error,
  }
}

export { useAllOrganizationsList }
