import { generatePropertiesObjectForCertificateOfComplianceContractForRecognitionConcludedForm } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/adapters/PropertiesProps.adapter'
import { generateRHFObjectForCertificateOfComplianceContractForRecognitionConcludedForm } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/adapters/RHF.adapter'
import { CertificateOfComplianceContractForRecognitionConcludedAFormValues } from '@components/Forms/CertificateOfComplianceContractForRecognitionConcludedAForms/types'

const useCertificateOfComplianceContractForRecognitionConcludedAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): CertificateOfComplianceContractForRecognitionConcludedAFormValues =>
    generateRHFObjectForCertificateOfComplianceContractForRecognitionConcludedForm(
      objectFromPropertyState,
    )

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForCertificateOfComplianceContractForRecognitionConcludedForm(
      objectFromPropertyState,
    )

  return { generateRHFObject, generatePropertiesObject }
}

export { useCertificateOfComplianceContractForRecognitionConcludedAdapters }
