import { createContext, FC, useCallback, useContext, useMemo } from 'react'
import { useParams } from 'react-router'

import useDocumentsApi from '@context/APIContext/hooks/useDocumentsApi'
import { GetVersionData } from '@context/APIContext/types'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import { useVersions } from '@hooks/new/swr/useVersions'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'

interface IVersionContextProps {
  state: {
    isNotActualVersion: boolean
    versions: GetVersionData[]
    versionName: string
    isLoadingVersions?: boolean
  }
  handlers: {
    onArchiveDownload: (() => Promise<void>) | null
  }
}

interface VersionProviderProps {
  dfoId: string
}

const VersionContext = createContext<IVersionContextProps>({
  state: {
    isNotActualVersion: false,
    versions: [],
    versionName: '',
  },
  handlers: {
    onArchiveDownload: null,
  },
})

const useVersionManager = () => {
  return useContext(VersionContext)
}

const VersionProvider: FC<VersionProviderProps> = ({ dfoId, children }) => {
  const { projectId } = useParams()

  const { downloadDfoArchive } = useDocumentsApi()

  const { versions, versionName, isLoadingVersions } = useVersions({
    key: { projectId, dfoId, _key: 'versions' },
    config: {
      isPaused: () => !projectId || !dfoId,
      revalidateOnMount: true,
      revalidateIfStale: true,
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'VersionProvider',
          moduleName: 'DocumentsLayout',
          componentType: 'manager',
        },
      }),
    },
  })

  const hasFoundOldVersion = versions?.some((version) => version.id === dfoId && !version.isActual)

  const onArchiveDownload = useCallback(async () => {
    if (!projectId || !dfoId) return

    try {
      await downloadDfoArchive({
        projectId,
        dfoId,
      })
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'VersionProvider',
          componentType: 'downloadDfoArchive',
        },
      })(error)
    }
  }, [dfoId, downloadDfoArchive, projectId])

  const providerValue = useMemo(
    () => ({
      state: {
        isNotActualVersion: hasFoundOldVersion ?? false,
        versions: versions ?? [],
        versionName: versionName ?? '',
        isLoadingVersions,
      },
      handlers: { onArchiveDownload: () => withDownloadToastPromise(onArchiveDownload()) },
    }),
    [onArchiveDownload, isLoadingVersions, hasFoundOldVersion, versions, versionName],
  )

  return <VersionContext.Provider value={providerValue}>{children}</VersionContext.Provider>
}

export { useVersionManager }

export default VersionProvider
