import { ProjectTypes } from '@services/Projects/Project.entity'

const isActionAllowed = (actionAllowed: number, dfoActionAllowed: boolean) => {
  if (actionAllowed === 3) return true
  if (actionAllowed === 0 && !dfoActionAllowed) return true
  return actionAllowed === 1 && dfoActionAllowed
}

const keyForUseProjects = (page: number, previousPageData: unknown[]) => {
  if (previousPageData && !previousPageData.length) return null
  return { page, size: 15, type: ProjectTypes.SZPK, _key: 'projects' }
}

export { isActionAllowed, keyForUseProjects }
