import { FC, memo, ReactElement, useEffect, useState } from 'react'

interface WithFileWrapperProps {
  src: string
  children: (markdown: string) => ReactElement
}

const WithFileWrapper: FC<WithFileWrapperProps> = ({ src, children }) => {
  const [currentText, setCurrentText] = useState<string>('')

  useEffect(() => {
    (async () => {
      const markdown = await fetch(src)

      const textFromMarkdown = await markdown.text()

      setCurrentText(textFromMarkdown)
    })()
  }, [src])

  return children(currentText)
}

export default memo(WithFileWrapper)
