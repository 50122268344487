import React, { DetailedHTMLProps, FC, FormHTMLAttributes } from 'react'

import { NpaFormItemProps } from '@components/DocumentFormComponents/Form/NpaForm/types'
import Loader from '@components/Loader'
import Stack from '@components/ReactBootstrap/Stack'

interface NpaDocumentFormProps
  extends Omit<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    'onSubmit'
  > {
  formIsLoading: boolean
  mapOfForms: NpaFormItemProps[]
  gap?: 1 | 2 | 3 | 4 | 5
  onSubmitForm?: (e: unknown) => Promise<void>
}

const NpaDocumentForm: FC<NpaDocumentFormProps> = ({
  gap = 2,
  mapOfForms,
  formIsLoading,
  onSubmitForm,
  ...rest
}) => {
  return (
    <form {...rest} onSubmit={onSubmitForm}>
      <Loader loading={formIsLoading} dataTestId={'NpaDocumentForm'} variant={'lite'}>
        <Stack direction={'vertical'} gap={gap}>
          {mapOfForms.map(({ id, node }) => (
            <div key={id}>{node}</div>
          ))}
        </Stack>
      </Loader>
    </form>
  )
}

export default NpaDocumentForm
