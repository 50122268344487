import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { PetitionRecognizePreviouslyConcludedContractAsBoundFormValues } from '@components/Forms/PetitionRecognizePreviouslyConcludedContractAsBound/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const twoSectionValidationMap: FormValuesValidationSection<
  keyof PetitionRecognizePreviouslyConcludedContractAsBoundFormValues['2'],
  PetitionRecognizePreviouslyConcludedContractAsBoundFormValues
> = {
  relatedContractNumber: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 50),
  },
  relatedContractDate: {
    required: defaultRHFValidation.required,
  },
  relatedContractKind: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1500),
  },
  projectName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 2000),
  },
}

export { twoSectionValidationMap }
