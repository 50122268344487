import { OptionProps } from '@components/NewDesign/Select/model'
import { isArray, isObject } from '@helpers/checkTypes'

const isSelectOption = (value: unknown): value is OptionProps => {
  return isObject(value) && 'value' in value && 'displayValue' in value
}

const isEveryItemOption = (array: unknown[]): boolean => {
  return array.every((item) => isSelectOption(item))
}

const extractValueFromOption = (value: unknown) => {
  if (isArray(value) && isEveryItemOption(value)) {
    return value.map((item) => item.value)
  }
  if (isSelectOption(value)) {
    return value?.value
  }
  return value
}

export { extractValueFromOption, isSelectOption }
