import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import Group from '@components/DocumentFormComponents/Group'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { oldStepInvestorCapitalSourcesSectionValidationMap } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/AdditionalForms/MainTemplate/FormController/OldStepInvestorCapitalSources/validation'
import { FifthOldStepInvestorCapitalSourcesPathName } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Forms/5/types'
import { useAAVolumeOfCapitalInvestmentsManager } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/Manager'
import { AAVolumeOfCapitalInvestmentsFormValues } from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/types'
import {
  AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher,
  AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
} from '@components/Forms/AAVolumeOfCapitalInvestmentsForm/watcher'
import Button from '@components/NewDesign/Button'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

interface OldStepInvestorCapitalSourcesProps {
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAVolumeOfCapitalInvestmentsFormValues>
  formName: FifthOldStepInvestorCapitalSourcesPathName
}

const OldStepInvestorCapitalSources: FC<OldStepInvestorCapitalSourcesProps> = ({
  editMode,
  blockViewIsValidating,
  formName,
  formInstance,
}) => {
  const {
    handlers: {
      handleAddItemToListWithOutValue,
      handleChangeValue,
      handleRemoveItemFromList,
      debouncedHandleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useAAVolumeOfCapitalInvestmentsManager()

  const { getSubscribableControlProps, getTextareaProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAVolumeOfCapitalInvestmentsFieldsControlUpdateWatcher,
  })

  const { fields: oldStepInvestorCapitalSources } = useFieldArraySubscribableControl<
    AAVolumeOfCapitalInvestmentsFormValues,
    FifthOldStepInvestorCapitalSourcesPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: formName,
    keyName: 'keyNameId',
    watcher: AAVolumeOfCapitalInvestmentsFieldArrayControlUpdateWatcher,
  })

  const handleAddOldStepExpense = async () => {
    await handleAddItemToListWithOutValue?.(formName)
  }

  const handleRemoveOldStepExpense = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${formName}.${index}`, formName)
  }

  return (
    <Group disableBottomBorder title={'Источники капиталовложений'}>
      <Stack direction={'vertical'} gap={!!oldStepInvestorCapitalSources?.length ? 3 : 0}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: formName,
          })}
        >
          <FlipperList list={oldStepInvestorCapitalSources}>
            {oldStepInvestorCapitalSources.map((oldStepExpense, index) => {
              const oldStepInvestorCapitalSourceName = `${formName}.${index}` as const

              return (
                <GroupWithRemoveButton
                  disableBottomBorder
                  key={oldStepExpense.id}
                  id={oldStepExpense.id}
                  title={`Источник капиталовложений №${index + 1}`}
                  onRemove={editMode ? handleRemoveOldStepExpense(index) : undefined}
                >
                  <Stack direction={'vertical'} gap={3}>
                    <Row>
                      <Col xs={12}>
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${oldStepInvestorCapitalSourceName}.oldStepInvestorCapitalSourceCommitted`,
                          })}
                        >
                          <ControlledTextarea
                            {...getTextareaProps({
                              name: `${oldStepInvestorCapitalSourceName}.oldStepInvestorCapitalSourceCommitted`,
                              textareaProps: {
                                disabled: true,
                                label: 'Текущее значение источника капиталовложений',
                              },
                            })}
                          />
                        </SubscribableControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: `${oldStepInvestorCapitalSourceName}.oldStepInvestorCapitalSource`,
                          })}
                        >
                          <ControlledTextarea
                            {...getTextareaProps({
                              name: `${oldStepInvestorCapitalSourceName}.oldStepInvestorCapitalSource`,
                              rules:
                                oldStepInvestorCapitalSourcesSectionValidationMap.oldStepInvestorCapitalSource,
                              textareaProps: {
                                label: 'Измененное значение источника капиталовложений',
                              },
                              onBlur: () =>
                                setTimeout(
                                  () =>
                                    handleChangeValue?.(
                                      `${oldStepInvestorCapitalSourceName}.oldStepInvestorCapitalSource`,
                                    ),
                                  0,
                                ),
                              onChange: () =>
                                setTimeout(() => {
                                  debouncedHandleChangeValue?.(
                                    `${oldStepInvestorCapitalSourceName}.oldStepInvestorCapitalSource`,
                                  )
                                }, 0),
                            })}
                          />
                        </SubscribableControl>
                      </Col>
                    </Row>
                  </Stack>
                </GroupWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddOldStepExpense}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      disabled={isLoading}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default OldStepInvestorCapitalSources
