import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import Typography from '@components/NewDesign/Typography'
import {
  ModalFormValues,
  StatementFormFieldProps,
} from '@components/NewDesignedModals/CreateSubsidyModal'
import styles from '@components/NewDesignedModals/CreateSubsidyModal/CreateSubsidy.module.scss'
import Radio from '@components/Radio'

interface ISubsidyDfoToCreateProps {
  name: string
  value: string
}

interface SubsidyStepProps {
  formInstance: UseFormReturn<ModalFormValues>
  typeOfStatement: StatementFormFieldProps
  localBudgetRenderCondition: boolean
  dfoToCreate?: ISubsidyDfoToCreateProps[]
  isFederal?: boolean
  onChangeTypeOfStatement?: (typeOfStatement: StatementFormFieldProps) => void
}

const SubsidyStep: FC<SubsidyStepProps> = ({
  formInstance,
  isFederal,
  typeOfStatement,
  dfoToCreate,
  localBudgetRenderCondition,
  onChangeTypeOfStatement,
}) => {
  const isStatementSubsidy = typeOfStatement === 'subsidy'

  return (
    <>
      {isFederal && (
        <>
          <Typography.Body variant={'bodyLMedium'}>Выберите форму возмещения</Typography.Body>
          <Radio.ControlledGroup
            control={formInstance.control}
            name={'typeOfStatement'}
            radioGroupProps={{
              className: styles.createSubsidyModal__checkboxStep,
            }}
            onChange={onChangeTypeOfStatement}
          >
            <Radio.Button
              value={'subsidy'}
              label={
                <Typography.Body as={'span'} variant={'bodyMMedium'}>
                  Субсидия
                </Typography.Body>
              }
            />
            <Radio.Button
              value={'taxRefund'}
              label={
                <Typography.Body as={'span'} variant={'bodyMMedium'}>
                  Налоговый вычет
                </Typography.Body>
              }
            />
          </Radio.ControlledGroup>
        </>
      )}

      <div className={styles.createSubsidyModal__statementStep}>
        <Typography.Body variant={'bodyLMedium'}>Выберите тип заявления</Typography.Body>
        {dfoToCreate && (
          <Radio.ControlledGroup
            name={'typeOfDfo'}
            control={formInstance.control}
            radioGroupProps={{
              className: styles.createSubsidyModal__checkboxStep,
              direction: 'vertical',
            }}
          >
            {dfoToCreate.map((dfoToCreate, index) => {
              return (
                <Radio.Button
                  key={index}
                  value={dfoToCreate.value}
                  label={
                    <Typography.Body as={'span'} variant={'bodyMMedium'}>
                      {dfoToCreate.name}
                    </Typography.Body>
                  }
                />
              )
            })}
          </Radio.ControlledGroup>
        )}
      </div>

      <CollapseWrapper isExpanded={isStatementSubsidy}>
        <div className={styles.createSubsidyModal__budgetStep}>
          <Typography.Body variant={'bodyLMedium'}>
            Выберите бюджет, из которого планируется получить возмещение
          </Typography.Body>
          <Radio.ControlledGroup
            control={formInstance.control}
            name={'typeOfBudget'}
            radioGroupProps={{
              className: styles.createSubsidyModal__checkboxStep,
            }}
          >
            {isFederal && (
              <Radio.Button
                value={'FEDERAL'}
                label={
                  <Typography.Body as={'span'} variant={'bodyMMedium'}>
                    Федеральный
                  </Typography.Body>
                }
              />
            )}
            <Radio.Button
              value={'REGIONAL'}
              label={
                <Typography.Body as={'span'} variant={'bodyMMedium'}>
                  Региональный
                </Typography.Body>
              }
            />
            {localBudgetRenderCondition && (
              <Radio.Button
                value={'LOCAL'}
                label={
                  <Typography.Body as={'span'} variant={'bodyMMedium'}>
                    Местный
                  </Typography.Body>
                }
              />
            )}
          </Radio.ControlledGroup>
        </div>
      </CollapseWrapper>
    </>
  )
}

export default SubsidyStep
