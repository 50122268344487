import { defaultMessageSupport } from '@constants/texts'
import { isIntervalServerError } from '@helpers/errorHelpers'

import { signDocumentsReducerActionMap } from './constants'
import type {
  SignDocumentsActionCreators,
  SignDocumentsReducerAction,
  SignDocumentsReducerState,
} from './types'

const initialSignDocumentsState: SignDocumentsReducerState = {
  isLoading: false,
  isDone: false,
  signDocumentsInProgress: false,
  signedDocumentsCounter: 0,
  errorMessage: '',
}

const signDocumentsReducer = (
  state: SignDocumentsReducerState,
  action: SignDocumentsReducerAction,
): SignDocumentsReducerState => {
  const { type } = action

  switch (type) {
    case signDocumentsReducerActionMap.START_SIGN_DOCS:
      return {
        ...initialSignDocumentsState,
        isLoading: true,
      }

    case signDocumentsReducerActionMap.SETUP_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case signDocumentsReducerActionMap.SETUP_DONE:
      return {
        ...state,
        isDone: action.payload,
      }

    case signDocumentsReducerActionMap.SETUP_SIGN_DOCS_PROGRESS:
      return {
        ...state,
        signDocumentsInProgress: action.payload,
      }

    case signDocumentsReducerActionMap.INCREMENT_SIGNED_DOCUMENT:
      return {
        ...state,
        signedDocumentsCounter: state.signedDocumentsCounter + 1,
      }

    case signDocumentsReducerActionMap.SETUP_DEFAULT_ERROR:
      const error = action.payload

      return {
        ...state,
        errorMessage: error.message || defaultMessageSupport,
      }

    case signDocumentsReducerActionMap.SETUP_AXIOS_ERROR:
      const axiosError = action.payload
      const axiosErrorStatus = axiosError.response?.status
      const axiosErrorMessage = axiosError?.message
      const { detail } = axiosError.response?.data || {}

      if (isIntervalServerError(axiosErrorStatus || 0)) {
        return {
          ...state,
          errorMessage: defaultMessageSupport,
        }
      }

      return {
        ...state,
        errorMessage: detail || axiosErrorMessage || defaultMessageSupport,
      }

    default:
      return state
  }
}

const signDocumentsActionCreators: SignDocumentsActionCreators = {
  startSignDocuments: () => ({ type: signDocumentsReducerActionMap.START_SIGN_DOCS }),
  incrementSignedDocumentsCounter: () => ({
    type: signDocumentsReducerActionMap.INCREMENT_SIGNED_DOCUMENT,
  }),
  setLoading: (payload) => ({ type: signDocumentsReducerActionMap.SETUP_LOADING, payload }),
  setDone: (payload) => ({ type: signDocumentsReducerActionMap.SETUP_DONE, payload }),
  setSignDocumentsProgress: (payload) => ({
    type: signDocumentsReducerActionMap.SETUP_SIGN_DOCS_PROGRESS,
    payload,
  }),
  setDefaultError: (payload) => ({
    type: signDocumentsReducerActionMap.SETUP_DEFAULT_ERROR,
    payload,
  }),
  setError: (payload) => ({ type: signDocumentsReducerActionMap.SETUP_AXIOS_ERROR, payload }),
}

export { initialSignDocumentsState, signDocumentsActionCreators, signDocumentsReducer }
