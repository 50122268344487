import React, { FC, memo } from 'react'

import { IAct } from '@routes/Acts/mapping'

import styles from './Act.module.scss'

type ActProps = IAct
const Act: FC<ActProps> = ({ title, description, hrefToResource, localDocument }) => {
  return (
    <a
      aria-hidden
      className={styles.wrapper}
      href={hrefToResource || localDocument || ''}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className={styles.whiteBox} />
      <h3 className={styles.title}>{title}</h3>
      {description && <span className={styles.description}>{description}</span>}
    </a>
  )
}

export default memo(Act)
