import { IInfrastructureObject } from '@components/Forms/InfrFacilitiesCostsExpectedReimbursedForm/types'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForInfrFacilitiesCosts = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedInvestorHeaderInfo = (() => {
    const defaultInvestorHeaderValue = {
      headerName: {
        propertyId: '',
        lastUpdateDt: '',
      },
      headerPosition: {
        propertyId: '',
        lastUpdateDt: '',
      },
    }

    const docSigners = objectFromPropertyState.docSigners?.value
    if (!docSigners || !docSigners.length) return defaultInvestorHeaderValue

    const headerInfo = docSigners.find((docSigner) => {
      return (
        docSigner.value?.signerOrganization?.value.id ===
        objectFromPropertyState.investor?.permanentId
      )
    })

    if (!headerInfo) return defaultInvestorHeaderValue

    return {
      headerName: {
        propertyId: headerInfo.value.signer?.propertyId,
        type: headerInfo.value.signer?.type,
        lastUpdateDt: headerInfo.value.signer?.lastUpdateDt,
      },
      headerPosition: headerInfo.value.signer?.value.headerPosition,
    }
  })()

  const preparedHeaderEmail = (() => {
    if (
      !isArray(objectFromPropertyState.docSignerEmails?.value) ||
      !objectFromPropertyState.docSignerEmails.value.length
    )
      return []

    return objectFromPropertyState.docSignerEmails?.value.map((email) => ({
      propertyId: email.propertyId,
      lastUpdateDt: email.lastUpdateDt,
      type: email.type,
    }))
  })()

  const preparedInfrObjects = ((): IInfrastructureObject[] => {
    if (
      !isArray(objectFromPropertyState.infrastructureObjects?.value) ||
      !objectFromPropertyState.infrastructureObjects?.value.length
    )
      return []

    return objectFromPropertyState.infrastructureObjects.value.map((infrastructureObject) => {
      return {
        propertyId: infrastructureObject.propertyId,
        lastUpdateDt: infrastructureObject.lastUpdateDt,
        value: {
          objectName: infrastructureObject.value?.infrastructureObjectName?.value?.objectName
            ? {
                propertyId:
                  infrastructureObject.value?.infrastructureObjectName?.value?.objectName
                    ?.propertyId,
                lastUpdateDt:
                  infrastructureObject.value?.infrastructureObjectName?.value?.objectName
                    ?.lastUpdateDt,
                type: infrastructureObject.value?.infrastructureObjectName?.value?.objectName?.type,
              }
            : {
                propertyId:
                  infrastructureObject.value?.infrastructureOtherActivityName?.value
                    ?.otherActivityName?.propertyId,
                lastUpdateDt:
                  infrastructureObject.value?.infrastructureOtherActivityName?.value
                    ?.otherActivityName?.lastUpdateDt,
                type: infrastructureObject.value?.infrastructureOtherActivityName?.value
                  ?.otherActivityName?.type,
              },
          infrastructureOtherName: {
            propertyId: infrastructureObject.value?.infrastructureOtherName?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureOtherName?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureOtherName?.type,
          },
          infrastructureKind: {
            propertyId: infrastructureObject.value?.infrastructureKind?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureKind?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureKind?.type,
          },
          infrastructureCommissioningYear: {
            propertyId: infrastructureObject.value?.infrastructureCommissioningYear?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureCommissioningYear?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureCommissioningYear?.type,
          },
          infrastructureType: {
            propertyId:
              infrastructureObject.value.infrastructureObjectName?.value?.infrastructureType?.value
                ?.name?.propertyId || '',
            lastUpdateDt:
              infrastructureObject.value.infrastructureObjectName?.value?.infrastructureType?.value
                ?.name?.lastUpdateDt || '',
            type:
              infrastructureObject.value.infrastructureObjectName?.value?.infrastructureType?.value
                ?.name?.type || '',
          },
          infrastructureCharacteristicsDescription: {
            propertyId:
              infrastructureObject.value?.infrastructureCharacteristicsDescription?.propertyId,
            lastUpdateDt:
              infrastructureObject.value?.infrastructureCharacteristicsDescription?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureCharacteristicsDescription?.type,
          },
          infrastructureFunctional: {
            propertyId: infrastructureObject.value?.infrastructureFunctional?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureFunctional?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureFunctional?.type,
          },
          infrastructureCost: {
            propertyId: infrastructureObject.value?.infrastructureCost?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureCost?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureCost?.type,
          },
          infrastructureCompensationValue: {
            propertyId: infrastructureObject.value?.infrastructureCompensationValue?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureCompensationValue?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureCompensationValue?.type,
          },
          infrastructureReason: {
            propertyId: infrastructureObject.value?.infrastructureReason?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureReason?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureReason?.type,
          },
          infrastructureBalanceHolder: {
            propertyId: infrastructureObject.value?.infrastructureBalanceHolder?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureBalanceHolder?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureBalanceHolder?.type,
          },
          infrastructureCompensationStartDate: {
            propertyId: infrastructureObject.value?.infrastructureCompensationStartDate?.propertyId,
            lastUpdateDt:
              infrastructureObject.value?.infrastructureCompensationStartDate?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureCompensationStartDate?.type,
          },
          infrastructureCompensationForm: {
            propertyId: infrastructureObject?.value?.infrastructureCompensationForm?.propertyId,
            lastUpdateDt: infrastructureObject?.value?.infrastructureCompensationForm?.lastUpdateDt,
            type: infrastructureObject?.value?.infrastructureCompensationForm?.type,
          },
          documentInfrastructure: {
            propertyId:
              infrastructureObject.value?.infrastructureDocumentInfrastructure?.propertyId,
            lastUpdateDt:
              infrastructureObject.value?.infrastructureDocumentInfrastructure?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureDocumentInfrastructure?.type,
          },
          infrastructureNote: {
            propertyId: infrastructureObject.value?.infrastructureNote?.propertyId,
            lastUpdateDt: infrastructureObject.value?.infrastructureNote?.lastUpdateDt,
            type: infrastructureObject.value?.infrastructureNote?.type,
          },
        },
      }
    })
  })()

  const preparedMainProjectObjects =
    isArray(objectFromPropertyState.mainProjectObjects?.value) &&
    objectFromPropertyState.mainProjectObjects?.value?.length
      ? objectFromPropertyState.mainProjectObjects?.value.map((object) => ({
          propertyId: object.propertyId,
          lastUpdateDt: object.lastUpdateDt,
          type: object.type,
          value: {
            mainObjectOption: {
              propertyId: object.propertyId,
              lastUpdateDt: object.lastUpdateDt,
              type: object.type,
            },
          },
        }))
      : []

  const preparedInvestorAddress = (() => {
    const defaultPropertyProps = objectFromPropertyState.investor?.value.index

    const address = [defaultPropertyProps, objectFromPropertyState.investor?.value.region]

    const addressPropertyPropsWithoutValue = address.find((item) => !item?.value)

    return addressPropertyPropsWithoutValue || defaultPropertyProps
  })()

  return {
    '2': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
      mainProjectObjects: {
        propertyId: objectFromPropertyState.mainProjectObjects?.propertyId,
        type: objectFromPropertyState.mainProjectObjects?.type,
        lastUpdateDt: objectFromPropertyState.mainProjectObjects?.lastUpdateDt,
        value: preparedMainProjectObjects,
      },
      investorCapital: {
        propertyId: objectFromPropertyState.investorCapital?.propertyId,
        type: objectFromPropertyState.investorCapital?.type,
        lastUpdateDt: objectFromPropertyState.investorCapital?.lastUpdateDt,
      },
      investorCapitalVAT: {
        propertyId: objectFromPropertyState.investorCapitalVAT?.propertyId,
        type: objectFromPropertyState.investorCapitalVAT?.type,
        lastUpdateDt: objectFromPropertyState.investorCapitalVAT?.lastUpdateDt,
      },
      totalCapital: {
        propertyId: objectFromPropertyState.totalCapital?.propertyId,
        type: objectFromPropertyState.totalCapital?.type,
        lastUpdateDt: objectFromPropertyState.totalCapital?.lastUpdateDt,
      },
      totalCapitalVAT: {
        propertyId: objectFromPropertyState.totalCapitalVAT?.propertyId,
        type: objectFromPropertyState.totalCapitalVAT?.type,
        lastUpdateDt: objectFromPropertyState.totalCapitalVAT?.lastUpdateDt,
      },
      endInvestStage: {
        propertyId: objectFromPropertyState.endInvestStage?.propertyId,
        type: objectFromPropertyState.endInvestStage?.type,
        lastUpdateDt: objectFromPropertyState.endInvestStage?.lastUpdateDt,
      },
    },
    '3': {
      infrastructureObjects: {
        propertyId: objectFromPropertyState.infrastructureObjects?.propertyId,
        lastUpdateDt: objectFromPropertyState.infrastructureObjects?.lastUpdateDt,
        type: objectFromPropertyState.infrastructureObjects?.type,
        value: preparedInfrObjects,
      },
      objectGeneralCost: {
        propertyId: objectFromPropertyState.objectGeneralCost?.propertyId,
        type: objectFromPropertyState.objectGeneralCost?.type,
        lastUpdateDt: objectFromPropertyState.objectGeneralCost?.lastUpdateDt,
      },
      objectGeneralCompensations: {
        propertyId: objectFromPropertyState.objectGeneralCompensations?.propertyId,
        type: objectFromPropertyState.objectGeneralCompensations?.type,
        lastUpdateDt: objectFromPropertyState.objectGeneralCompensations?.lastUpdateDt,
      },
    },
    '4': {
      headerPhone: {
        propertyId: objectFromPropertyState.docSignerPhone?.propertyId,
        lastUpdateDt: objectFromPropertyState.docSignerPhone?.lastUpdateDt,
        type: objectFromPropertyState.docSignerPhone?.type,
      },
      headerEmail: {
        propertyId: objectFromPropertyState.docSignerEmails?.propertyId,
        lastUpdateDt: objectFromPropertyState.docSignerEmails?.lastUpdateDt,
        value: preparedHeaderEmail,
      },
      ...preparedInvestorHeaderInfo,
    },
  }
}

export { generatePropertiesObjectForInfrFacilitiesCosts }
