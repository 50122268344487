import React, { FC } from 'react'
import { Control } from 'react-hook-form/dist/types/form'

import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/ConstructionObject.module.scss'
import CExploitationForm from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CExploitationForm'
import CObjectsForm from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm'
import CPermissionsForm from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CPermissionsForm'
import CRegistrationForm from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CRegistrationForm'
import { SeventhConstructionStagePathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import cn from 'classnames'

interface ConstructionStageProps {
  parentId: string
  indexOfMainBlock: number
  name: SeventhConstructionStagePathName
  control: Control<ProjectSZPKFormValues>
}

const ConstructionObject: FC<ConstructionStageProps> = ({
  parentId,
  indexOfMainBlock,
  name,
  control,
}) => {
  return (
    <div className={styles.constructionObject}>
      <div
        id={`${parentId}.1`}
        className={cn(
          styles.constructionObject__header,
          styles['constructionObject__object-header'],
        )}
      >
        <Typography.Headline variant={'headlineH3'}>Объекты недвижимости</Typography.Headline>
        <Typography.Body
          color={'on-surface-secondary'}
          className={styles.constructionObject__caption}
          variant={'bodyMRegular'}
        >
          Объект(ы) недвижимости и (или) комплекс объектов движимого и недвижимого имущества,
          связанных между собой
        </Typography.Body>
      </div>
      <Row className={styles.constructionObject__container}>
        <Col xs={12}>
          <CObjectsForm name={`${name}.constructionObjects`} control={control} />
        </Col>
      </Row>
      <Border className={styles.constructionObject__border} />
      <Row className={styles.constructionObject__container}>
        <Col id={`${parentId}.2`} xs={12}>
          <div className={styles.constructionObject__header}>
            <Typography.Headline variant={'headlineH4'}>Разрешения на объекты</Typography.Headline>
          </div>
          <CPermissionsForm
            indexOfBlock={indexOfMainBlock}
            name={`${name}.permissionsObjects`}
            control={control}
          />
        </Col>
      </Row>
      <Border className={styles.constructionObject__border} />
      <Row className={styles.constructionObject__container}>
        <Col id={`${parentId}.3`} xs={12}>
          <div className={styles.constructionObject__header}>
            <Typography.Headline variant={'headlineH4'}>Эксплуатация объектов</Typography.Headline>
          </div>
          <CExploitationForm
            indexOfBlock={indexOfMainBlock}
            name={`${name}.exploitation`}
            control={control}
          />
        </Col>
      </Row>
      <Border className={styles.constructionObject__border} />
      <Row className={styles.constructionObject__container}>
        <Col id={`${parentId}.4`} xs={12}>
          <div className={styles.constructionObject__header}>
            <Typography.Headline variant={'headlineH4'}>Регистрация объектов</Typography.Headline>
          </div>
          <CRegistrationForm
            name={`${name}.registration`}
            control={control}
            indexOfBlock={indexOfMainBlock}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ConstructionObject
