import React, { useEffect, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  parametersOfCostRecoveryApplicationBlockValues,
  parametersOfCostRecoveryApplicationConstValues,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/const'
import ParametersOfCostRecoveryApplicationExpenseSubTypes from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes'
import ParametersOfCostRecoveryApplicationExpenseSubTypesNotExist from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/NotExist'
import {
  ParametersOfCostRecoveryApplicationExpenseSubTypesNotExistPathName,
  ParametersOfCostRecoveryApplicationExpenseSubTypesPathName,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { fourthSectionValidationMap } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/validation'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import Loader from '@components/Loader'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isArray, isNullOrUndefined } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'

const Fourth = () => {
  const formInstance = useFormContext<ParametersOfCostRecoveryApplicationFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, handleAddItemToListWithValue },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getSubscribableControlProps, getAmountInputProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
    })

  const { fields: expenseSubTypes } = useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    ParametersOfCostRecoveryApplicationExpenseSubTypesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseSubTypes,
    keyName: 'keyNameId',
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const { fields: expenseSubTypesNotExist } = useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    ParametersOfCostRecoveryApplicationExpenseSubTypesNotExistPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: parametersOfCostRecoveryApplicationBlockValues['4']
      .compensationStatementExpenseSubTypesNotExist,
    keyName: 'keyNameId',
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const expenseGroup = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseGroupName,
    control: formInstance.control,
  })

  const statementFormName = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['3'].compensationStatementForm,
    control: formInstance.control,
  })

  const expenseGroupSystemName = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['4']
      .compensationStatementExpenseGroupSystemName,
    control: formInstance.control,
  })

  const compensationTypeSystemName = useWatch({
    name: parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementTypeSystemName,
    control: formInstance.control,
  })

  const isTaxDeductionRender =
    statementFormName === parametersOfCostRecoveryApplicationConstValues.TAX_DEDUCTION &&
    (expenseGroupSystemName === parametersOfCostRecoveryApplicationConstValues.DESTRUCTION ||
      expenseGroupSystemName ===
        parametersOfCostRecoveryApplicationConstValues.BUILD_INFRASTRUCTURE)

  const { booleanState: isExpenseGroupLoading, setBooleanState: setExpenseGroupLoading } =
    useBooleanState()

  const conditionSubTypesFieldsRender = !isNullOrUndefined(expenseGroup)

  const canDeleteExpenseSubTypes = expenseSubTypes.length + expenseSubTypesNotExist.length > 1

  useEffect(() => {
    setExpenseGroupLoading(false)
  }, [expenseGroupSystemName])

  const accordionStateRefs = useRef<boolean[]>([])

  const handleUpdateAccordionStateOnToggle = (index: number) => (value: boolean) => {
    accordionStateRefs.current[index] = value
  }

  const handleChangeExpenseGroup = async () => {
    setExpenseGroupLoading(true)

    try {
      await handleChangeValue?.(
        parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseGroupName,
      )
    } finally {
      setExpenseGroupLoading(false)
    }
  }

  const handleAddExpense = (id: string) => async () => {
    accordionStateRefs.current[expenseSubTypes.length] = true

    await handleAddItemToListWithValue?.(
      parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseSubTypes,
      {
        compensationStatementExpenseSubTypeValue: { id },
      },
    )

    if (!expenseSubTypes.length) {
      formInstance.clearErrors(
        parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseSubTypes,
      )
    }
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: parametersOfCostRecoveryApplicationBlockValues['4']
                  .compensationStatementExpenseGroupName,
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.name || '',
                        value: item.id,
                      }),
                      transformOptionsData: (data) =>
                        compensationTypeSystemName ===
                          parametersOfCostRecoveryApplicationConstValues.DEBT && isArray(data)
                          ? data.filter(
                              (item) =>
                                item.systemName ===
                                  parametersOfCostRecoveryApplicationConstValues.DEBT_MAIN_OBJECT ||
                                item.systemName ===
                                  parametersOfCostRecoveryApplicationConstValues.DEBT_INFRASTRUCTURE,
                            )
                          : data,
                      controllerProps: {
                        name: parametersOfCostRecoveryApplicationBlockValues['4']
                          .compensationStatementExpenseGroupName,
                        rules: fourthSectionValidationMap.compensationStatementExpenseGroupName,
                      },
                      selectProps: {
                        disabled:
                          !editMode ||
                          compensationTypeSystemName ===
                            parametersOfCostRecoveryApplicationConstValues.DESTRUCTION ||
                          compensationTypeSystemName ===
                            parametersOfCostRecoveryApplicationConstValues.BUILD_INFRASTRUCTURE,
                        inputProps: {
                          label: 'Тип затрат',
                        },
                        onChangeFormValue: () => setTimeout(() => handleChangeExpenseGroup(), 0),
                      },
                    })}
                  />
                )
              }}
            </SubscribableControl>
          </Col>
        </Row>
        <Loader loading={isExpenseGroupLoading} variant={'l'}>
          <CollapseWrapper
            isExpanded={conditionSubTypesFieldsRender}
            defaultExpanded={conditionSubTypesFieldsRender}
          >
            <Stack direction={'vertical'} gap={3}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: parametersOfCostRecoveryApplicationBlockValues['4']
                    .compensationStatementExpenseSubTypes,
                })}
              >
                <FlipperList list={expenseSubTypes}>
                  {expenseSubTypes.map((subType, index) => {
                    const formName =
                      `${parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseSubTypes}.${index}` as const

                    return (
                      <ParametersOfCostRecoveryApplicationExpenseSubTypes
                        key={subType.id}
                        canDelete={canDeleteExpenseSubTypes}
                        id={subType.id}
                        title={subType.compensationStatementExpenseSubTypeValueFullName}
                        indexOfType={index}
                        name={formName}
                        formInstance={formInstance}
                        initialAccordionState={accordionStateRefs.current[index]}
                        initialAccordionMountState={accordionStateRefs.current[index]}
                        onAccordionToggle={handleUpdateAccordionStateOnToggle(index)}
                      />
                    )
                  })}
                </FlipperList>
              </SubscribableControl>
              {editMode && (
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: parametersOfCostRecoveryApplicationBlockValues['4']
                      .compensationStatementExpenseSubTypesNotExist,
                  })}
                >
                  <FlipperList list={expenseSubTypesNotExist}>
                    {expenseSubTypesNotExist.map((subType, index) => {
                      const formName =
                        `${parametersOfCostRecoveryApplicationBlockValues['4'].compensationStatementExpenseSubTypesNotExist}.${index}` as const

                      return (
                        <SubscribableControl
                          {...getSubscribableControlProps({
                            path: formName,
                          })}
                        >
                          <FlippedWithCollapse
                            disableExitAnimation
                            key={subType.id}
                            flipId={subType.id}
                          >
                            <ParametersOfCostRecoveryApplicationExpenseSubTypesNotExist
                              name={formName}
                              formInstance={formInstance}
                              onAddExpense={editMode ? handleAddExpense(subType.id) : undefined}
                            />
                          </FlippedWithCollapse>
                        </SubscribableControl>
                      )
                    })}
                  </FlipperList>
                </SubscribableControl>
              )}
            </Stack>
          </CollapseWrapper>
          <CollapseWrapper isExpanded={isTaxDeductionRender} defaultExpanded={isTaxDeductionRender}>
            <Row>
              <Col xs={12}>
                <Border />
              </Col>
            </Row>
            <Group disableBottomBorder title={'Общая сумма налогового вычета для СЗПК'}>
              <Stack direction={'vertical'} gap={3}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title="По налогу на имущество организаций, который будет предоставлен по всем объектам недвижимого имущества"
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: parametersOfCostRecoveryApplicationBlockValues['4']
                        .compensationStatementTaxLegalPropertyRefundTotalValue,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: parametersOfCostRecoveryApplicationBlockValues['4']
                          .compensationStatementTaxLegalPropertyRefundTotalValue,
                        inputProps: {
                          disabled: true,
                          leftAddons: (
                            <FormIconWithTooltip
                              tooltipContent={
                                'Общая сумма будет посчитана автоматически на основе данных об уплаченном налоге по годам'
                              }
                            />
                          ),
                        },
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title="По земельному налогу, который будет предоставлен по всем земельным участкам"
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: parametersOfCostRecoveryApplicationBlockValues['4']
                        .compensationStatementTaxLandPropertyRefundTotalValue,
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: parametersOfCostRecoveryApplicationBlockValues['4']
                          .compensationStatementTaxLandPropertyRefundTotalValue,
                        inputProps: {
                          disabled: true,
                          leftAddons: (
                            <FormIconWithTooltip
                              tooltipContent={
                                'Общая сумма будет посчитана автоматически на основе данных об уплаченном налоге по годам'
                              }
                            />
                          ),
                        },
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Stack>
            </Group>
          </CollapseWrapper>
        </Loader>
      </Stack>
    </Container>
  )
}

export default Fourth
