import React, { DetailedHTMLProps, FC, HTMLAttributes, memo } from 'react'

import Icon from '@components/Icon'
import Loader from '@components/Loader'
import IconButton from '@components/NewDesign/IconButton'
import Typography from '@components/NewDesign/Typography'
import WithUpload from '@components/WithUpload'
import { Transition } from '@headlessui/react'
import { formatBytes } from '@helpers/fileHelpers'
import { useHover } from '@hooks/new/events/useHover'
import DownloadAttachmentIcon from '@icons/attachment/actions/DownloadAttachmentIcon.svg'
import RemoveAttachmentIcon from '@icons/attachment/actions/RemoveAttachmentIcon.svg'
import ReplaceAttachmentIcon from '@icons/attachment/actions/ReplaceAttachmentIcon.svg'
import FileNpaView from '@icons/FileNpaView.svg'
import cn from 'classnames'

import styles from './FileView.module.scss'

export interface FileItemViewProps {
  documentName: string
  documentExtension: string
  documentSize?: number
}

interface FileViewProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  file: FileItemViewProps
  isLoading?: boolean
  onDownload?: () => void
  onReplace?: (files: File[]) => void
  onRemove?: () => void
}

const FileView: FC<FileViewProps> = ({
  file,
  isLoading,
  onRemove,
  onReplace,
  onDownload,
  ...divRest
}) => {
  const [ref, isHovered] = useHover()

  const { documentName, documentSize, documentExtension } = file

  const extensionAndSizeOfFile = `${(documentExtension || '').toUpperCase()} · ${
    documentSize && formatBytes(documentSize)
  }`

  return (
    <div
      {...divRest}
      ref={ref}
      className={cn(
        styles.fileView,
        cn({
          [styles['fileView--loading']]: isLoading,
        }),
        divRest.className,
      )}
    >
      <Icon noCurrentColorSvgFill src={FileNpaView} />
      {!isLoading ? (
        <div className={styles.fileView__metaInfo}>
          <Typography.Body variant={'bodyMMedium'}>{documentName}</Typography.Body>
          <Typography.Body
            className={styles.fileView__extensionWithSize}
            variant={'bodySMedium'}
            color={'text-base-tertiary'}
          >
            {extensionAndSizeOfFile}
          </Typography.Body>
        </div>
      ) : (
        <div className={styles.fileView__loading}>
          <Loader loading className={styles.fileView__loader} variant={'lite'} />
          <Typography.Body
            className={styles.fileView__addFileText}
            color={'text-base-tertiary'}
            variant={'bodySMedium'}
          >
            Добавление файла
          </Typography.Body>
        </div>
      )}
      {!isLoading && (
        <div className={styles['fileView__actions-wrapper']}>
          <Transition
            show={isHovered}
            enter={styles.transition__enter}
            enterFrom={styles.transition__enterFrom}
            enterTo={styles.transition__enterTo}
            leave={styles.transition__leave}
            leaveFrom={styles.transition__leaveFrom}
            leaveTo={styles.transition__leaveTo}
          >
            <div className={styles.fileView__actions}>
              {onReplace && (
                <WithUpload onDropAccepted={onReplace}>
                  <IconButton
                    geometry={'round'}
                    size={'s'}
                    view={'plain'}
                    icon={{
                      src: ReplaceAttachmentIcon,
                    }}
                  />
                </WithUpload>
              )}
              {onDownload && (
                <IconButton
                  geometry={'round'}
                  size={'s'}
                  view={'plain'}
                  icon={{
                    src: DownloadAttachmentIcon,
                  }}
                  onClick={onDownload}
                />
              )}
              {onRemove && (
                <IconButton
                  geometry={'round'}
                  size={'s'}
                  view={'plain'}
                  icon={{
                    noCurrentColorSvgFill: true,
                    src: RemoveAttachmentIcon,
                  }}
                  onClick={onRemove}
                />
              )}
            </div>
          </Transition>
        </div>
      )}
    </div>
  )
}

export default memo(FileView)
