import React from 'react'
import { useFormContext } from 'react-hook-form'

import InfoAboutDocumentSection from '@components/DocumentFormComponents/FormSections/InfoAboutDocumentSection'
import { useSummaryReportOfMonitoringResultsManager } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Manager'
import { SummaryReportOfMonitoringResultsFormValues } from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import { SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'

const First = () => {
  const formInstance = useFormContext<SummaryReportOfMonitoringResultsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useSummaryReportOfMonitoringResultsManager()

  return (
    <InfoAboutDocumentSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'1'}
      formInstance={formInstance}
      watcher={SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default First
