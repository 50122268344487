import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  memo,
  MutableRefObject,
  useCallback,
} from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import { INpaDraftBody, INpaThreeLayout } from '@services/NPA/NPA.entity'
import cn from 'classnames'

import ClassificationsViewOld from '../Views/ClassificationsOld'
import DirectionViewOld from '../Views/DirectionOld'
import { FormLayoutValues, ICardViewRefs, IDirectionStateViewRef } from '../'

import styles from './NpaThreeOld.module.scss'

interface NpaThreeProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  stateOfDirections: MutableRefObject<IDirectionStateViewRef | null>
  layoutThree: UseFormReturn<FormLayoutValues>
  onUpdateDraftModel: (updatedChosenParts: INpaDraftBody) => Promise<void>
  updateAllLayoutThree: (partToScrollAfterUpdate: string) => Promise<void>
  handleBindCardRef: (callback: (prevState: ICardViewRefs | null) => ICardViewRefs) => void
}

const NpaThree: FC<NpaThreeProps> = ({
  layoutThree,
  onUpdateDraftModel,
  updateAllLayoutThree,
  handleBindCardRef,
  stateOfDirections,
  ...divProps
}) => {
  const { fields: directions, remove } = useFieldArray({
    name: 'npaStructure',
    control: layoutThree.control,
    keyName: 'structureId',
  })

  const { mapOfNpaDirections } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const getCurrentClassifications = (directionIndex: number) =>
    layoutThree.watch(`npaStructure.${directionIndex}.classifications`)

  const removePartDirection = useCallback(
    (index: number, direction: INpaThreeLayout['direction']) => () => {
      remove(index)

      delete stateOfDirections.current?.[direction]
    },
    [remove, stateOfDirections],
  )

  const handleDirectionStateChange = useCallback(
    (direction: string) => (value: boolean) => {
      stateOfDirections.current = {
        ...stateOfDirections.current,
        [direction]: value,
      }
    },
    [stateOfDirections],
  )

  return (
    <div {...divProps} className={cn(styles.npaThree, divProps.className)}>
      {!!directions?.length &&
        directions.map((direction, directionIndex) => {
          const currentClassifications = getCurrentClassifications(directionIndex)

          const countOfDirectionClassifications = currentClassifications.reduce(
            (prevValue, nextValue) => {
              return prevValue + nextValue.parts.length
            },
            0,
          )

          return (
            <DirectionViewOld
              key={direction.structureId}
              isOpen={!!stateOfDirections.current?.[direction.direction]}
              needEdit={direction.isChangesNpaRegistry}
              direction={mapOfNpaDirections?.[direction.direction] || ''}
              count={countOfDirectionClassifications}
              onChange={handleDirectionStateChange(direction.direction)}
            >
              <ClassificationsViewOld
                layoutThree={layoutThree}
                directionIndex={directionIndex}
                updateAllLayoutThree={updateAllLayoutThree}
                handleBindCardRef={handleBindCardRef}
                removePartDirection={removePartDirection(directionIndex, direction.direction)}
                onUpdateDraftModel={onUpdateDraftModel}
              />
            </DirectionViewOld>
          )
        })}
    </div>
  )
}

export default memo(NpaThree)
