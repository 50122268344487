import React, { FC, memo, useCallback, useMemo } from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/FormLayout.module.scss'
import { useTimeUpdateForm } from '@components/DocumentFormComponents/hooks/useTimeUpdateForm'
import DocumentFormLayout from '@components/DocumentFormComponents/Layout'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import CertificateOnMonitoringResults from '@components/Forms/CertificateOnMonitoringResultsForm'
import { getCertificateOnMonitoringResultsFormMenuHashMapMenuByStatus } from '@components/Forms/CertificateOnMonitoringResultsForm/Menu/const'
import { CertificateOnMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import Typography from '@components/NewDesign/Typography'
import cn from 'classnames'

import CertificateOnMonitoringResultsLayoutWrapper from './Wrapper'

const CertificateOnMonitoringResultsLayout: FC<FormModalLayoutProps> = ({
  projectId,
  formId,
  isOpen,
  onClose,
  initialErrorsFromViolations,
  editMode,
}) => {
  const { lastUpdateDraftTime, handleSetLastUpdateFormToNow } = useTimeUpdateForm()

  const handleOnClose = useCallback(() => {
    CertificateOnMonitoringResultsFieldsControlUpdateWatcher.resetAllState()
    onClose?.()
  }, [onClose])

  const initialMenuState = useMemo(() => {
    return getCertificateOnMonitoringResultsFormMenuHashMapMenuByStatus()
  }, [])

  return (
    <DocumentFormLayout isOpen={isOpen} onClose={handleOnClose}>
      <CertificateOnMonitoringResults.MenuManager
        initialMenuHash={initialMenuState.initialCertificateOnMonitoringResultsMenuState}
        initialSectionId={initialMenuState.initialCertificateOnMonitoringResultsMenuState[0].id}
      >
        <CertificateOnMonitoringResults.Manager
          formId={formId}
          projectId={projectId}
          initialErrorsFromViolations={initialErrorsFromViolations}
          editMode={editMode}
          onSetLastUpdateFormToNow={handleSetLastUpdateFormToNow}
          onClose={handleOnClose}
        >
          <CertificateOnMonitoringResultsLayoutWrapper lastUpdateDraftTime={lastUpdateDraftTime}>
            <div>
              <Typography.Headline
                className={styles.layout__title}
                variant={'headlineH1'}
                align={'center'}
                as={'h1'}
              >
                Справка по результатам мониторинга
              </Typography.Headline>
              <CertificateOnMonitoringResults.StatementForm className={styles.layout__form} />
            </div>
            <div className={cn(styles['layout__menu-wrapper'], styles['layout__menu-wrapper--s'])}>
              <CertificateOnMonitoringResults.Menu
                className={styles.layout__menu}
                lastUpdateDraftTime={lastUpdateDraftTime}
              />
            </div>
          </CertificateOnMonitoringResultsLayoutWrapper>
        </CertificateOnMonitoringResults.Manager>
      </CertificateOnMonitoringResults.MenuManager>
    </DocumentFormLayout>
  )
}

export default memo(CertificateOnMonitoringResultsLayout)
