import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectsFormValues,
  IConstructionSubObject,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { bigIntIsValidate, defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'

const eObjectAAgreementOnCreationOtherEstateObjectsValidationMap: FormValuesValidationSection<
  keyof IConstructionSubObject,
  AAgreementOnCreationOtherEstateObjectsFormValues
> = {
  objectName: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 1000),
  },
  objectConstructionKind: {
    validate: (value) => (isString(value) ? lengthValidate(value, 100) : undefined),
  },
  objectPurpose: {
    validate: (value) => (isString(value) ? lengthValidate(value, 100) : undefined),
  },
  objectConstructionSquare: {
    validate: bigIntIsValidate,
  },
  objectSquare: {
    validate: bigIntIsValidate,
  },
  objectNonLivingAreaSquare: {
    validate: bigIntIsValidate,
  },
  objectLivingAreaSquare: {
    validate: bigIntIsValidate,
  },
  objectFloor: {
    validate: (value) => (isString(value) ? lengthValidate(value, 10) : undefined),
  },
  objectUndergroundFloor: {
    validate: (value) => (isString(value) ? lengthValidate(value, 10) : undefined),
  },
  objectHeight: {
    validate: bigIntIsValidate,
  },
  objectDetails: {
    validate: (value) => isString(value) && lengthValidate(value, 100000),
  },
  objectInfrastructureType: {
    required: defaultRHFValidation.required,
  },
  oldCreatedDocument: {
    validate: (value) => (isString(value) ? lengthValidate(value, 1000) : undefined),
  },
  oldCreatedOrganization: {
    validate: (value) => (isString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { eObjectAAgreementOnCreationOtherEstateObjectsValidationMap }
