import TextWithTooltip from '@components/NewDesign/TextWithTooltip'
import Typography from '@components/NewDesign/Typography/Typography'
import Tag from '@components/Tag'
import TextWithCopy from '@components/TextWithCopy/TextWithCopy'
import { objOfDateFormats } from '@constants/dateFormats'
import { AuditDictionaries } from '@context/APIContext/hooks/useOperatorApi'
import { getTagPropsByActionType, getTagPropsByRole } from '@helpers/tagHelpers'
import { TActionTypes } from '@routes/Audits/types'
import DayjsService from '@services/Dayjs/Dayjs.service'
import cn from 'classnames'

import styles from './Table.module.scss'

export const getColumnsAuditsTable = (auditsDictionaries?: AuditDictionaries) => [
  {
    Header: 'Дата и Время',
    accessor: 'dateTime',
    className: cn(styles.dateTime, 'w-[11.2%]'),
    Cell: ({ value: dateTime }) => {
      return (
        <Typography.Body variant="bodyMRegular">
          {DayjsService.dayjs(dateTime).format(objOfDateFormats.sameElse.normalFormat)}
        </Typography.Body>
      )
    },
  },
  {
    Header: 'Тип события',
    className: 'w-[13%]',
    accessor: 'actionType',
    Cell: ({ value: actionType }) => {
      const actionTypeValue =
        auditsDictionaries?.[actionType as TActionTypes] ??
        getTagPropsByActionType(actionType)?.[0] ??
        ''
      return <TextWithTooltip variant="bodyMRegular" value={actionTypeValue} limit={25} />
    },
  },
  {
    Header: 'Описание',
    accessor: 'description',
    className: ' w-[22.8%] break-all',
    Cell: ({ value }) => <TextWithTooltip variant="bodyMRegular" value={value} limit={67} />,
  },
  {
    Header: 'ID',
    accessor: 'user.id',
    className: 'w-[14.4%]',
    Cell: ({ value }) => (
      <TextWithCopy value={value}>
        <TextWithTooltip variant="bodyMRegular" value={value} limit={43} />
      </TextWithCopy>
    ),
  },
  {
    Header: 'Пользователь · Должность',
    accessor: 'user',
    className: 'w-[16.1]',
    Cell: ({ value: user }) => {
      const fullName = `${user?.firstName} ${user?.lastName}`

      return (
        <div>
          <Typography.Body variant="bodyMRegular">{fullName}</Typography.Body>
          <Typography.Body className={styles.textGray} variant="bodyMRegular" color="gray">
            {user?.position || ''}
          </Typography.Body>
        </div>
      )
    },
  },
  {
    Header: 'ИНН Организации',
    accessor: 'organization.inn',
    className: 'w-[11.06%]',
    Cell: ({ value }) => <Typography.Body variant="bodyMRegular">{value}</Typography.Body>,
  },
  {
    Header: 'Тип',
    accessor: 'organization.role',
    className: cn(styles.column, 'w-[8.9%]'),
    Cell: ({ value: role }) => {
      const [text, type] = getTagPropsByRole(role)
      return (
        <Tag size="m" key={role} type={type} className={styles.pointer}>
          {text}
        </Tag>
      )
    },
  },
]
