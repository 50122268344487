import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import { DependentUrls, Urls } from '@constants/urls'
import type { GetVersionData } from '@context/APIContext/types'
import { useAuthContext } from '@context/AuthContext'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import { downloadFile } from '@helpers/fileHelpers'
import type { StageInfo } from '@interfaces'
import type { IDocumentsList } from '@interfaces/documents'
import type { IDfoAttribute } from '@services/Attribute/Attribute.entity'
import type { GetDfoPropertiesBody, TGetAllDocumentsOfDfo } from '@services/Dfo/Dfo.entity'
import type {
  IDocumentSetsDictTypes,
  TGetDfoTypes,
} from '@services/Dictionaries/dictionaries.entity'
import type {
  ISolicitationMembersEntity,
  TGetProjectMembers,
} from '@services/Members/Members.entity'

import type {
  AddSolicitationMembersData,
  AddSolicitationMembersProps,
  CheckFLCProps,
  CreateInventoryProps,
  DefaultDFOSProps,
  DfoDocumentsProps,
  DfosDigitizingProps,
  DfosDigitizingReturnType,
  DFOSStage,
  GetDfoProperties,
  GetDfoPropertiesProps,
  PostDfoAttributeProps,
  PutDfoAttributeProps,
  PutDfoPropertiesProps,
  RemoveSolicitationMembersProps,
} from './types'

const useDFOSApi = () => {
  const { FetcherWithInterceptors } = useAuthContext()

  // TODO: Типизировать documentType. Доступные типы возвращает бек при ошибке
  const downloadDocumentTemplateByType = async (documentType: string) => {
    try {
      const { data: blob, headers } = await FetcherWithInterceptors.get<Blob>({
        url: {
          url: DependentUrls.downloadTemplateByType,
          params: {
            documentType,
          },
        },
        config: {
          responseType: 'blob',
        },
      })

      downloadFile(blob, headers)

      return blob
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getDfoDocuments = async ({ dfoId, process: { id: processId } }: DfoDocumentsProps) => {
    if (!processId) return

    try {
      const { data } = await FetcherWithInterceptors.get<IDocumentsList>({
        url: {
          url: DependentUrls.DfoDocuments,
          params: { dfoId, processId },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, false)

      throw error
    }
  }

  /**
   * Метод используется для получения результата ФЛК процессов перед выполнением действия над ними
   * @param projectId Идентификатор проекта
   * @param action Действие над процессами, для которых проверяется ФЛК
   * @param processIds Идентификаторы процессов, для которых проверяется ФЛК
   * @returns
   */
  const checkNewFLC = async ({ projectId, actionId }: CheckFLCProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post<StageInfo>({
        url: {
          url: DependentUrls.CheckFlc,
          params: {
            projectId,
            actionId,
          },
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  /**
   * Метод используется для формирования описи документов ОДО перед подписанием всего пакета документов ОДО
   * @param projectId Идентификатор проекта
   * @param dfoIds Идентификаторы ОДО, который вытаскивается из каждого элемента getProjectActionsById
   */
  const createInventoryFile = async ({ projectId, actionId }: CreateInventoryProps) => {
    try {
      return await FetcherWithInterceptors.post<void>({
        url: {
          url: DependentUrls.CreateInventory,
          params: { projectId, actionId },
        },
      })
    } catch (error) {
      throw error
    }
  }

  const getDfoDocumentsById = async ({ dfoId, projectId }: DefaultDFOSProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetAllDocumentsOfDfo>({
        url: {
          url: DependentUrls.DfoSet,
          params: {
            projectId,
            dfoId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getDocumentsSetsTypes = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<IDocumentSetsDictTypes>({
        url: `${Urls.Dfo}/dictionaries/document-sets/document-types`,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getDfoTypes = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetDfoTypes>({
        url: `${Urls.Dfo}/dictionaries/dfo-types`,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getDfosStages = async () => {
    try {
      const { data } = await FetcherWithInterceptors.get<DFOSStage[]>({
        url: `${Urls.Dfo}/dictionaries/stages`,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const deleteProjectDfoById = async ({ projectId, dfoId }: DefaultDFOSProps) => {
    try {
      const { data } = await FetcherWithInterceptors.delete({
        url: {
          url: DependentUrls.ActionsWithDfo,
          params: {
            projectId,
            dfoId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getSolicitationMembersByDfoId = async ({ projectId, dfoId }: DefaultDFOSProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<ISolicitationMembersEntity[]>({
        url: {
          url: DependentUrls.SolicitationMembers,
          params: {
            projectId,
            dfoId,
          },
        },
      })

      return data
    } catch (error) {
      toast(
        <Alert transparent variant="warning">
          {'Не удалось получить орган согласующий ходатайство о связанности'}
        </Alert>,
      )

      throw error
    }
  }

  const getPossibleSolicitationMembers = async ({ projectId, dfoId }: DefaultDFOSProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<TGetProjectMembers>({
        url: {
          url: `${DependentUrls.SolicitationMembers}/possible`,
          params: {
            dfoId,
            projectId,
          },
        },
      })

      return data
    } catch (error) {
      toast(
        <Alert transparent variant="warning">
          {'Не удалось получить орган согласующий ходатайство о связанности'}
        </Alert>,
      )

      throw error
    }
  }

  const addSolicitationMembersByDfoId = async ({
    projectId,
    dfoId,
    organizationId,
  }: AddSolicitationMembersProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post<AddSolicitationMembersData>({
        url: {
          url: DependentUrls.SolicitationMembers,
          params: {
            projectId,
            dfoId,
          },
        },
        data: {
          organizationId,
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  const removeSolicitationMembersByDfoId = async ({
    projectId,
    dfoId,
    memberId,
  }: RemoveSolicitationMembersProps) => {
    try {
      const { data } = await FetcherWithInterceptors.delete({
        url: {
          url: `${DependentUrls.SolicitationMembers}/:memberId`,
          params: {
            projectId,
            dfoId,
            memberId,
          },
        },
      })

      return data
    } catch (error) {
      throw error
    }
  }

  /**
   * ЦР. Метод, который используется для получения списка версий ОДО
   * @param projectId Идентификатор проекта
   * @param dfoId Идентификатор объекта документооборота
   */
  const getVersions = async ({ projectId, dfoId }: DefaultDFOSProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetVersionData[]>({
        url: {
          url: DependentUrls.GetVersions,
          params: {
            projectId,
            dfoId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, false)

      throw error
    }
  }

  const getDfoAttributes = async ({ projectId, dfoId }: DefaultDFOSProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<IDfoAttribute[]>({
        url: {
          url: DependentUrls.DfoAttributes,
          params: {
            projectId,
            dfoId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const putDfoAttribute = async ({ projectId, dfoId, body }: PutDfoAttributeProps) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.DfoAttributes,
          params: {
            projectId,
            dfoId,
          },
        },
        data: body,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const postDfoAttribute = async ({ projectId, dfoId, body }: PostDfoAttributeProps) => {
    try {
      const { data } = await FetcherWithInterceptors.post({
        url: {
          url: DependentUrls.DfoAttributes,
          params: {
            projectId,
            dfoId,
          },
        },
        data: body,
      })
      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getDfoProperties = async <BodyType extends GetDfoPropertiesBody>({
    dfoId,
    body,
  }: GetDfoPropertiesProps) => {
    try {
      const { data } = await FetcherWithInterceptors.get<GetDfoProperties<BodyType>>({
        url: {
          url: DependentUrls.DfoProperties,
          params: {
            dfoId,
          },
        },
        config: {
          params: body,
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const putDfoProperties = async ({ dfoId, body }: PutDfoPropertiesProps) => {
    try {
      const { data } = await FetcherWithInterceptors.put({
        url: {
          url: DependentUrls.DfoProperties,
          params: {
            dfoId,
          },
        },
        data: body,
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  const getDfosDigitizing = async ({
    projectId,
  }: DfosDigitizingProps): Promise<DfosDigitizingReturnType> => {
    try {
      const { data } = await FetcherWithInterceptors.get<DfosDigitizingReturnType>({
        url: {
          url: Urls.DfosDigitizing,
        },
        config: {
          params: {
            projectId,
          },
        },
      })

      return data
    } catch (error) {
      handleErrorWithPopup(error, true)

      throw error
    }
  }

  return {
    checkNewFLC,
    createInventoryFile,
    getDfosStages,
    getDfoTypes,
    getDfoDocuments,
    downloadDocumentTemplateByType,
    getDfoDocumentsById,
    getDocumentsSetsTypes,
    deleteProjectDfoById,

    getSolicitationMembersByDfoId,
    getPossibleSolicitationMembers,
    addSolicitationMembersByDfoId,
    removeSolicitationMembersByDfoId,

    getVersions,

    getDfoAttributes,
    postDfoAttribute,
    putDfoAttribute,

    getDfoProperties,
    putDfoProperties,

    getDfosDigitizing,
  }
}

export default useDFOSApi
