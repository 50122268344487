import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import styles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ObjectFormItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem'
import SubObjectItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/SubObjectItem'
import {
  SeventhConstructionObjectsArrayPathName,
  SeventhConstructionSubObjectsPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldCollapseControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import useIsFirstRender from '@hooks/new/effects/useIsFirstRender'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

interface ObjectItemProps {
  parentId: string
  objectId: string
  blockViewIsValidating: boolean
  editMode: boolean
  //Если много объектов, необходимо все сворачивать и анмаунтить
  expandedRenderMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  name: SeventhConstructionObjectsArrayPathName
  indexOfObject: number

  onDeleteObject: () => Promise<void>
}

const ObjectItem: FC<ObjectItemProps> = ({
  expandedRenderMode,
  editMode,
  parentId,
  objectId,
  blockViewIsValidating,
  formInstance,
  indexOfObject,
  name,
  onDeleteObject,
}) => {
  const {
    handlers: { handleAddItemToListWithValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { fields: subObjectItems } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhConstructionSubObjectsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${name}.subObjects`,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const { isExpanded: isShowObjectItemContent, onToggleCollapse: toggleShowObjectItemContent } =
    useFormCollapseControl({
      initialExpanded: expandedRenderMode,
      name,
      watcher: ProjectSZPKFieldCollapseControlUpdateWatcher,
    })

  const subObjectExpandedRenderMode = !useIsFirstRender()

  const handleDeleteObject = async () => {
    await onDeleteObject()
  }

  const handleAddSubObject = async () => {
    await handleAddItemToListWithValue?.(`${name}.subObjects`, {
      isSubObject: true,
      parentSubObject: objectId,
    })
  }

  const handleDeleteSubObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.subObjects.${index}`, `${name}.subObjects`)
  }

  return (
    <FlippedWithCollapse flipId={objectId}>
      <Row id={parentId}>
        <Col xs={12}>
          <div className={styles.objectItem}>
            <div className={styles.objectItem__header}>
              <ObjectStepWrapperHeaderNode
                buttonProps={{
                  onClick: toggleShowObjectItemContent,
                  trailingIcon: {
                    className: cn({
                      [styles['objectItem__header-button__icon--active']]: isShowObjectItemContent,
                    }),
                  },
                }}
              >
                ОСНОВНОЙ ОБЪЕКТ №{indexOfObject + 1}
              </ObjectStepWrapperHeaderNode>

              {editMode && (
                <AsyncWrapper promise={handleDeleteObject}>
                  {({ isLoading, wrappedPromise }) => (
                    <Button
                      disabled={isLoading}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      dataTestId={'deleteButton'}
                      color={'negative'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      leadingIcon={{
                        noCurrentColorSvgFill: true,
                        src: DeleteIcon,
                      }}
                      onClick={wrappedPromise}
                    >
                      Удалить
                    </Button>
                  )}
                </AsyncWrapper>
              )}
            </div>
            <div className={styles.objectItem__content}>
              <ObjectFormItem
                isParentObject
                isShowObjectItemContent={isShowObjectItemContent}
                blockViewIsValidating={blockViewIsValidating}
                editMode={editMode}
                formInstance={formInstance}
                formName={name}
              />
            </div>

            <FlipperList list={subObjectItems}>
              <SubscribableControl
                path={`${name}.subObjects` as const}
                watcher={ProjectSZPKFieldsControlUpdateWatcher}
                {...subscribableControl}
              >
                {subObjectItems.map((subObject, index) => (
                  <SubscribableControl
                    key={subObject.id}
                    path={`${name}.subObjects.${index}` as const}
                    watcher={ProjectSZPKFieldsControlUpdateWatcher}
                    {...subscribableControl}
                  >
                    <SubObjectItem
                      expandedRenderMode={subObjectExpandedRenderMode}
                      editMode={editMode}
                      subObjectId={subObject.id}
                      blockViewIsValidating={blockViewIsValidating}
                      name={`${name}.subObjects.${index}` as const}
                      formInstance={formInstance}
                      indexOfObject={index}
                      onDeleteSubObject={handleDeleteSubObject(index)}
                    />
                  </SubscribableControl>
                ))}
              </SubscribableControl>
            </FlipperList>

            {editMode && (
              <Row className={cn(styles.objectItem__action, 'px-1')}>
                <Col xs={12}>
                  <AsyncWrapper promise={handleAddSubObject}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <Button
                          disabled={isLoading}
                          leadingIcon={{ src: CircleAddIcon }}
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          view={'plain'}
                          dataTestId={'addSubObject'}
                          className={styles['objectItem__action-button']}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Добавить объект, входящий в состав основного объекта №{indexOfObject + 1}
                        </Button>
                      )
                    }}
                  </AsyncWrapper>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </FlippedWithCollapse>
  )
}

export default ObjectItem
