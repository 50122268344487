import { useMemo } from 'react'

import { useAPIContext } from '@context/APIContext'
import { SWRHookProps } from '@interfaces'
import {
  TMasterDirectionTypeNpa,
  TNpaDirectionsFromDict,
  TNpaDirectionsFromDictWithOrder,
} from '@services/NPA/NPA.entity'
import useSWRImmutable from 'swr/immutable'

type MapOfNpaDirections = Record<TMasterDirectionTypeNpa, string>

const useNpaDirections = ({ key, config }: SWRHookProps<TNpaDirectionsFromDict | undefined>) => {
  const {
    npaApi: { getNpaDirections },
  } = useAPIContext()

  const {
    data: npaDirections,
    error,
    mutate,
    isValidating: isLoadingNpaDirections,
  } = useSWRImmutable(key, getNpaDirections, config)

  const mapOfNpaDirections: MapOfNpaDirections | undefined = useMemo(() => {
    if (!npaDirections) return

    return npaDirections.reduce(
      (prevValue, currentValue) => ({
        ...prevValue,
        [currentValue.type]: currentValue.description,
      }),
      {} as Record<TMasterDirectionTypeNpa, string>,
    )
  }, [npaDirections])

  const npaDirectionsWithOrder: TNpaDirectionsFromDictWithOrder | undefined = useMemo(() => {
    if (!npaDirections) return

    return npaDirections.map((direction, index) => ({
      ...direction,
      order: index,
    }))
  }, [npaDirections])

  return {
    npaDirections,
    mapOfNpaDirections,
    npaDirectionsWithOrder,
    error,
    mutate,
    isLoadingNpaDirections,
  }
}

export type { MapOfNpaDirections }
export { useNpaDirections }
