import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import { fourthSectionValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/4/validation'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getInputProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
    subscribableControl,
  })

  const isFederalSZPK = useWatch({
    name: 'additionalFields.isFederal',
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        {!isFederalSZPK && (
          <Row>
            <Col xs={12}>
              <RowWithBorder
                disableBottomDefaultStyles
                title={
                  'Указываются акты органов государственной власти субъектов РФ, принятые в соответствии с пунктом 1 части 7 статьи 4 ФЗ'
                }
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: projectSZPKBlockValues['4'].ruleSubjectSzpkInstrumental,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: projectSZPKBlockValues['4'].ruleSubjectSzpkInstrumental,
                      rules: fourthSectionValidationMap.ruleSubjectSzpkInstrumental,
                      inputProps: {
                        caption: 'заполняется в творительном падеже, только для региональных СЗПК',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(
                            projectSZPKBlockValues['4'].ruleSubjectSzpkInstrumental,
                          )
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${projectSZPKBlockValues['4'].ruleSubjectSzpkInstrumental}`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </RowWithBorder>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункты Правил, согласно которым публично-правовое образование обязуется обеспечить неприменение актов, которые будут приняты после заключения СЗПК'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: projectSZPKBlockValues['4'].ruleLinkStabilizationNpa,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: projectSZPKBlockValues['4'].ruleLinkStabilizationNpa,
                    rules: fourthSectionValidationMap.ruleLinkStabilizationNpa,
                    inputProps: {
                      dataTestId: 'ruleLinkStabilizationNpa',
                      caption: 'рекомендация: 37 и 75',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          `${projectSZPKBlockValues['4'].ruleLinkStabilizationNpa}`,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          `${projectSZPKBlockValues['4'].ruleLinkStabilizationNpa}`,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Fourth
