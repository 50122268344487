import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import { eleventhSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/11/validation'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Eleventh = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { getFormFieldControlProps, getInputProps, getAmountInputProps } = useFormComponentPresets({
    isNew: false,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const isPaper = useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues.additionalFields
      .isPaper,
    control: formInstance.control,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title="Пункты дополнительного соглашения о присоединении муниципального образования к СЗПК, определяющие срок стаб. оговорки"
            >
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                    .extraMunicipalAccessionLinkStabilization,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .extraMunicipalAccessionLinkStabilization,
                        )
                      }, 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                      .extraMunicipalAccessionLinkStabilization,
                    rules:
                      eleventhSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.extraMunicipalAccessionLinkStabilization,
                    inputProps: {
                      caption: 'заполняется, если МО участвует в соглашении',
                      leftAddons: <FormIconWithTooltip tooltipContent="рекомендация: 2.2 - 2.4" />,
                    },
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .extraMunicipalAccessionLinkStabilization,
                        )
                      }, 0),
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .extraMunicipalAccessionLinkStabilization,
                        )
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title="Количество лет, в течение которых применяется стабилизационная оговорка"
            >
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                    .stabilizationPeriod,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .stabilizationPeriod,
                        )
                      }, 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                      .stabilizationPeriod,
                    rules:
                      eleventhSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.stabilizationPeriod,
                    inputProps: {
                      suffix: '',
                      integersOnly: true,
                      integerLength: 2,
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .stabilizationPeriod,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .stabilizationPeriod,
                        )
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title="Пункт дополнительного соглашения о присоединении муниципального образования к СЗПК, определяющий срок неприменения актов, изменяющих решение о предоставлении меры гос. поддержки"
            >
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                    .extraMunicipalAccessionLinkCompensation,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .extraMunicipalAccessionLinkCompensation,
                        )
                      }, 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                      .extraMunicipalAccessionLinkCompensation,
                    rules:
                      eleventhSectionAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.extraMunicipalAccessionLinkCompensation,
                    inputProps: {
                      caption: 'заполняется, если МО участвует в соглашении',
                      leftAddons: <FormIconWithTooltip tooltipContent="рекомендация: 2.1" />,
                    },
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .extraMunicipalAccessionLinkCompensation,
                        )
                      }, 0),
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                            .extraMunicipalAccessionLinkCompensation,
                        )
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </RowWithBorder>
          </Col>
        </Row>
        {isPaper && (
          <>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title="Срок стаб. оговорки применительно к ставкам ввозных таможенных пошлин"
                >
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                        .oldStabilizationPeriodTaxDuty,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodTaxDuty,
                            )
                          }, 0),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                          '11'
                        ].oldStabilizationPeriodTaxDuty,
                        inputProps: {
                          suffix: '',
                          integersOnly: true,
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodTaxDuty,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodTaxDuty,
                            )
                          }, 0),
                      })}
                    />
                  </FormFieldControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title="Срок стаб. оговорки применительно к федеральным актам, изменяющим сроки и (или) объемы меры гос. поддержки"
                >
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                        .oldStabilizationPeriodFederalStateSupport,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodFederalStateSupport,
                            )
                          }, 0),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                          '11'
                        ].oldStabilizationPeriodFederalStateSupport,
                        inputProps: {
                          suffix: '',
                          integersOnly: true,
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodFederalStateSupport,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodFederalStateSupport,
                            )
                          }, 0),
                      })}
                    />
                  </FormFieldControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  title="Срок стаб. оговорки применительно к региональным актам, изменяющим сроки и (или) объемы меры гос. поддержки"
                >
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['11']
                        .oldStabilizationPeriodRegionalStateSupport,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodRegionalStateSupport,
                            )
                          }, 0),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledAmountInput
                      {...getAmountInputProps({
                        name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                          '11'
                        ].oldStabilizationPeriodRegionalStateSupport,
                        inputProps: {
                          suffix: '',
                          integersOnly: true,
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodRegionalStateSupport,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                                '11'
                              ].oldStabilizationPeriodRegionalStateSupport,
                            )
                          }, 0),
                      })}
                    />
                  </FormFieldControl>
                </RowWithBorder>
              </Col>
            </Row>
          </>
        )}
      </Stack>
    </Container>
  )
}

export default Eleventh
