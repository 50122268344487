import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/const'
import NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/Forms'
import { useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyManager } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/Manager'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForm/types'

const mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAForms: FormItemProps =
  {
    '1': {
      node: (
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms.First />
      ),
    },
    '2': {
      node: (
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms.Second />
      ),
    },
    '3': {
      node: (
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms.Third />
      ),
    },
    '4': {
      node: (
        <NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyForms.Fourth />
      ),
    },
  } as const

const NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAStatementForm: FC<
  FormModalStatementProps
> = ({ ...rest }) => {
  const formInstance =
    useFormContext<NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyFormValues>()

  const {
    state: { formIsLoading },
  } = useNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfForms={mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAForms}
      formIsLoading={formIsLoading}
      mapOfMenu={
        mapOfNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAResultDenyMenu
      }
    />
  )
}

export default memo(NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAStatementForm)
