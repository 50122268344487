import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForApplicationOfConclusionAgreementSZPKRegisterForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForApplicationOfConclusionAgreementSZPKRegisterForm }
