import { generatePropertiesObjectForNoticeOfRejectConcludeSZPKForm } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForNoticeOfRejectConcludeSZPKForm } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/adapters/RHF.adapter'
import { NoticeOfRejectConcludeSZPKFormValues } from '@components/Forms/NoticeOfRejectConcludeSZPKForm/types'

const useNoticeOfRejectConcludeSZPKAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): NoticeOfRejectConcludeSZPKFormValues =>
    generateRHFObjectForNoticeOfRejectConcludeSZPKForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForNoticeOfRejectConcludeSZPKForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useNoticeOfRejectConcludeSZPKAdapters }
