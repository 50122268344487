import React, { DetailedHTMLProps, FC, HTMLAttributes, memo } from 'react'
import { useFormState } from 'react-hook-form'

import styles from '@components/DocumentFormComponents/FormStyles/FormMenu.module.scss'
import { useFormNavigationMenu } from '@components/DocumentFormComponents/hooks/useFormNavigation'
import DocumentFormMenu from '@components/DocumentFormComponents/Menu'
import { useFlatMenuManager as useCreateStatementOldMenuManager } from '@components/DocumentFormComponents/Menu/Manager'
import { mapOFMenu } from '@components/Forms/CreateStatementOld/const'
import StatementHelpers from '@components/Forms/CreateStatementOld/helpers'
import { useCreateStatementManager } from '@components/Forms/CreateStatementOld/Manager'

type CreateStatementMenuProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const { isBlockHaveError } = StatementHelpers

const CreateStatementMenu: FC<CreateStatementMenuProps> = ({ ...rest }) => {
  const {
    state: { formInstance, blockViewIsValidating, lastUpdateDraftTime },
  } = useCreateStatementManager()

  const { errors } = useFormState({
    control: formInstance?.control,
  })

  const { currentAnchorId, handleChangeCurrentAnchorId } = useCreateStatementOldMenuManager()

  const { handleMenuItemClick } = useFormNavigationMenu()

  if (!formInstance) return null

  return (
    <div className={styles.menu}>
      <DocumentFormMenu
        {...rest}
        mapOfMenu={mapOFMenu}
        blockErrors={errors}
        blockViewIsValidating={blockViewIsValidating}
        lastUpdateDraftTime={lastUpdateDraftTime}
        activeMenuId={currentAnchorId}
        onBlockCheckError={isBlockHaveError}
        onMenuClick={handleMenuItemClick(handleChangeCurrentAnchorId)}
      />
    </div>
  )
}

export default memo(CreateStatementMenu)
