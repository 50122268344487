import React, { FC, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import styles from '@components/DocumentFormComponents/FormStyles/ManufactoringOfGoods.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import {
  manufactoringOfGoodsValidationMap,
  volumeOfProductionValidationMap,
} from '@components/Forms/ProjectSZPKForm/Forms/9/Forms/ManufactoringOfGoods/validation'
import {
  NinthManufactoringOfGoodsArrayPathName,
  NinthVolumeOfProductionPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/9/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { getAmountValueFromStr } from '@components/NewDesign/AmountInput/utils'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledHierarchyReferenceBookSelect from '@components/NewDesign/Select/HierarchyReferenceBookSelect/Controlled'
import { ControlledSwitch } from '@components/NewDesign/Switch'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import DayjsService from '@services/Dayjs/Dayjs.service'

//Добавляем два нуля minority
const LIMIT_INVESTOR_CAPITAL_VALUE = '15 000 000 000,00'

interface ManufactoringOfGoodsFormProps {
  id: string
  name: NinthManufactoringOfGoodsArrayPathName
  control: Control<ProjectSZPKFormValues>
  onRemoveStep: VoidFunction
}

const ManufactoringOfGoodsForm: FC<ManufactoringOfGoodsFormProps> = ({
  id,
  control,
  name,
  onRemoveStep,
}) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const {
    getSubscribableControlProps,
    getHierarchyReferenceBookSelectProps,
    getSwitchProps,
    getInputProps,
    getTextareaProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: ProjectSZPKFieldsControlUpdateWatcher,
  })

  const { fields: projectResults } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    NinthVolumeOfProductionPathName,
    'keyNameId'
  >({
    control,
    name: `${name}.volumeOfProduction`,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const investorCapital = useWatch({
    name: projectSZPKBlockValues['5'].investorCapital,
  })

  const conditionToTnVedRender = useWatch({
    name: `${name}.isStabilizationResult`,
  })

  const conditionToIsStabilizationResultRender = useMemo(() => {
    if (!investorCapital) return false

    const preparedInvestorCapital = getAmountValueFromStr(investorCapital, 100)
    const preparedLimitInvestorCapital =
      investorCapital && getAmountValueFromStr(LIMIT_INVESTOR_CAPITAL_VALUE, 100)

    if (!preparedInvestorCapital || !preparedLimitInvestorCapital) return false

    return preparedInvestorCapital >= preparedLimitInvestorCapital
  }, [investorCapital])

  const conditionToRenderTaxes = !!projectResults.length

  if (!formInstance) return null

  return (
    <FieldView.StepTemplate
      id={id}
      dataTestId={'projectResultNameListItem'}
      onRemoveStep={editMode ? onRemoveStep : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.projectResultName`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${name}.projectResultName`,
                  rules: manufactoringOfGoodsValidationMap.projectResultName,
                  textareaProps: {
                    label: 'Наименование и характеристика',
                    dataTestId: 'projectResultName',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.projectResultName`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.projectResultName`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        {!conditionToRenderTaxes && (
          <Row className={'px-1'}>
            <Col xs={12} className={'px-3'}>
              <Typography.Body variant={'bodyMRegular'}>
                укажите дату начала проекта и планируемую дату окончания соглашения в форме
                Заявления о заключении СЗПК для того, чтобы добавить объём производства товаров на
                каждый год
              </Typography.Body>
            </Col>
          </Row>
        )}
        {conditionToRenderTaxes && (
          <Group
            disableBottomBorder
            title={'Объем производства товаров, выполнения работ, оказания услуг за указанный год'}
          >
            <Stack direction={'vertical'} gap={3}>
              {projectResults.map((item, index) => {
                const formName = `${name}.volumeOfProduction.${index}` as const

                return (
                  <Row key={item.id}>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.projectResultValue`,
                        })}
                      >
                        <ControlledAmountInput
                          {...getAmountInputProps({
                            name: `${formName}.projectResultValue`,
                            rules: volumeOfProductionValidationMap.projectResultValue,
                            inputProps: {
                              dataTestId: 'projectResultValue',
                              suffix: '',
                              label: DayjsService.dayjs(item.projectResultYear).year().toString(),
                              hasNegativeValue: false,
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(
                                  `${formName}.projectResultValue`,
                                  'projectResults',
                                )
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${formName}.projectResultValue`,
                                  'projectResults',
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={6}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${formName}.projectResultUnit`,
                        })}
                      >
                        <ControlledInput
                          {...getInputProps({
                            name: `${formName}.projectResultUnit`,
                            rules: volumeOfProductionValidationMap.projectResultUnit,
                            inputProps: {
                              label: 'Единица измерения',
                              dataTestId: 'projectResultUnit',
                            },
                            onBlur: () =>
                              setTimeout(() => {
                                handleChangeValue?.(
                                  `${formName}.projectResultUnit`,
                                  'projectResults',
                                )
                              }, 0),
                            onChange: () =>
                              setTimeout(() => {
                                debouncedHandleChangeValue?.(
                                  `${formName}.projectResultUnit`,
                                  'projectResults',
                                )
                              }, 0),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                )
              })}
              {conditionToIsStabilizationResultRender && (
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.isStabilizationResult`,
                      })}
                    >
                      <ControlledSwitch
                        {...getSwitchProps({
                          name: `${name}.isStabilizationResult`,
                          switchProps: {
                            wrapperClassName: styles.form__switch,
                            label:
                              'Применимость стабилизации актов (решений), предусматривающих увеличение ставок вывозных таможенных пошлин в отношении товара',
                          },
                          onChange: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${name}.isStabilizationResult`)
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
              {conditionToTnVedRender && (
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.projectResultCodeTnVed`,
                      })}
                    >
                      <ControlledHierarchyReferenceBookSelect
                        {...getHierarchyReferenceBookSelectProps({
                          dictionaryName: 'tnved',
                          customDisplayValueFC: ({ code }) => code || '',
                          controllerProps: {
                            name: `${name}.projectResultCodeTnVed`,
                            rules: manufactoringOfGoodsValidationMap.projectResultCodeTnVed,
                          },
                          inputProps: {
                            label: 'Код товара по ТН ВЭД',
                            dataTestId: 'projectResultCodeTnVed',
                          },
                          onChange: () =>
                            setTimeout(() => {
                              handleChangeValue?.(`${name}.projectResultCodeTnVed`)
                            }, 0),
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
            </Stack>
          </Group>
        )}
      </Stack>
    </FieldView.StepTemplate>
  )
}

export default ManufactoringOfGoodsForm
