import React, { FC, memo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnCreationOtherEstateObjectsBlockValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/const'
import ConstructionObject from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject'
import { fifthSectionValidationMap } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/validation'
import { ConstructionStagesArrayPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface MainTemplateProps {
  name: ConstructionStagesArrayPathName
  indexOfMainBlock: number
}

const STEP_COMPENSATION_FORM_TO_IGNORE = ['Налоговый вычет', 'Субсидия и (или) налоговый вычет']

const MainTemplate: FC<MainTemplateProps> = ({ name, indexOfMainBlock }) => {
  const formInstance = useFormContext<AAgreementOnCreationOtherEstateObjectsFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isFederal = useWatch({
    name: aAgreementOnCreationOtherEstateObjectsBlockValues.additionalFields.isFederal,
    control: formInstance?.control,
  })

  const {
    getSubscribableControlProps,
    getTextareaProps,
    getSingleSelectProps,
    getAmountInputProps,
    getFormFieldControlProps,
    getCalendarInputProps,
  } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  })

  const isShowConstructionObjects = useWatch({
    name: `${name}.isShowConstructionObjects`,
    control: formInstance.control,
  })

  const isPaper = useWatch({
    name: aAgreementOnCreationOtherEstateObjectsBlockValues.additionalFields.isPaper,
    control: formInstance.control,
  })

  const stepCompensationValueCommited = useWatch({
    name: `${name}.stepCompensationValueCommited`,
  })

  return (
    <Container>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Номер этапа'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepNumber`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.stepNumber`,
                  inputProps: {
                    disabled: true,
                    integersOnly: true,
                    hideSuffix: true,
                    hasNegativeValue: false,
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.stepDescription`,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${name}.stepDescription`,
                    textareaProps: {
                      disabled: true,
                      maxRows: Infinity,
                      label: 'Описание этапа',
                    },
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Дата окончания этапа'}
          >
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.stepEndDate`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${name}.stepEndDate`)
                    }, 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.stepEndDate`,
                  calendarInputProps: {
                    disabled: true,
                    label: 'Дата окончания этапа',
                  },
                })}
              />
            </FormFieldControl>
          </FieldView.RowWithBottomBorder>
          <Group disableBottomBorder title={'Планируемые к возмещению затраты'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Объем планируемых к возмещению затрат (без НДС)'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.stepCompensationValue`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.stepCompensationValue`,
                      inputProps: {
                        disabled: true,
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              {!isPaper && !!stepCompensationValueCommited && (
                <Row>
                  <Col xs={12}>
                    <FormFieldControl
                      {...getFormFieldControlProps({
                        path: `${name}.stepCompensationForm`,
                        formFieldTooltipProps: {
                          isNew: false,
                          onChange: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${name}.stepCompensationForm`),
                              0,
                            ),
                          onDifference: handleUpdateChanges,
                        },
                      })}
                    >
                      {({ overrideProps }) => {
                        const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                          ? overrideProps.fetcherOptions
                          : undefined

                        return (
                          <ControlledFormSingleSelect
                            {...getSingleSelectProps({
                              fetcherProps,
                              optionsAdapter: (item) => {
                                if (
                                  !isFederal &&
                                  STEP_COMPENSATION_FORM_TO_IGNORE.some(
                                    (value) => value == item.name,
                                  )
                                )
                                  return null

                                return {
                                  displayValue: item.name || '',
                                  value: item.id,
                                }
                              },
                              controllerProps: {
                                name: `${name}.stepCompensationForm`,
                                rules: fifthSectionValidationMap.stepCompensationForm,
                              },
                              selectProps: {
                                withContextSearch: true,
                                inputProps: {
                                  label: 'В форме',
                                },
                                onChangeFormValue: () =>
                                  handleChangeValue?.(`${name}.stepCompensationForm`),
                              },
                            })}
                          />
                        )
                      }}
                    </FormFieldControl>
                  </Col>
                </Row>
              )}
            </Stack>
          </Group>
          {isShowConstructionObjects && (
            <Row>
              <ConstructionObject indexOfMainBlock={indexOfMainBlock} name={name} />
            </Row>
          )}
        </Stack>
      </Col>
    </Container>
  )
}

export default memo(MainTemplate)
