import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import OrganizationUserDetailsForm from '@components/Sidebars/OrganizationUserDetails/Form'
import { OrganizationUserDetailsFormValues } from '@components/Sidebars/OrganizationUserDetails/types'
import { UserProfiles } from '@constants/types'
import type { Profile } from '@interfaces'

interface OrganizationUserDetailsControllerProps {
  editMode: boolean
  userId: string
  profile: Profile
  formInstance: UseFormReturn<OrganizationUserDetailsFormValues>
}

const OrganizationUserDetailsController: FC<OrganizationUserDetailsControllerProps> = ({
  editMode,
  userId,
  profile,
  formInstance,
}) => {
  const isSignPermission = profile === UserProfiles.HEADER

  if (!isSignPermission) {
    return <OrganizationUserDetailsForm.WithoutSign formInstance={formInstance} />
  }

  return (
    <OrganizationUserDetailsForm.WithSign
      editMode={editMode}
      userId={userId}
      formInstance={formInstance}
    />
  )
}

export default OrganizationUserDetailsController
