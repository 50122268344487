import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'

import { mapOfApplicationOfConclusionAgreementSZPKRegisterMenu } from '../const'
import ApplicationOfConclusionAgreementSZPKRegisterForms from '../Forms'
import { useApplicationOfConclusionAgreementSZPKRegisterManager } from '../Manager'
import { ApplicationOfConclusionAgreementSZPKFormValues } from '../types'

const mapOfApplicationOfConclusionAgreementSZPKRegisterForms: FormItemProps = {
  '1': {
    node: <ApplicationOfConclusionAgreementSZPKRegisterForms.First />,
  },
  '2': {
    node: <ApplicationOfConclusionAgreementSZPKRegisterForms.Second />,
  },
} as const

const ApplicationOfConclusionAgreementSZPKRegisterStatementForm: FC<FormModalStatementProps> = ({
  ...rest
}) => {
  const formInstance = useFormContext<ApplicationOfConclusionAgreementSZPKFormValues>()

  const {
    state: { formIsLoading },
  } = useApplicationOfConclusionAgreementSZPKRegisterManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      isAutoGeneratedForm
      mapOfMenu={mapOfApplicationOfConclusionAgreementSZPKRegisterMenu}
      mapOfForms={mapOfApplicationOfConclusionAgreementSZPKRegisterForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(ApplicationOfConclusionAgreementSZPKRegisterStatementForm)
