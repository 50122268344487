import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { RegistrationCertificateFormValues } from '@components/Forms/RegistrationCertificateForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const ninthSectionRegistrationCertificateValidationMap: FormValuesValidationSection<
  keyof RegistrationCertificateFormValues['9'],
  RegistrationCertificateFormValues
> = {
  infoMonitoringReport: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  infoMonitoringResult: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { ninthSectionRegistrationCertificateValidationMap }
