import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultAAChangingFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/const'
import AAChangingVolumeReimbursementLayout from '@components/Forms/AAChangingVolumeReimbursementForm/Layout'

const AAChangingVolumeReimbursementLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultAAChangingFormValues}>
      <AAChangingVolumeReimbursementLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(AAChangingVolumeReimbursementLayoutWithFormProvider)
