import { useAPIContext } from '@context/APIContext'
import type { DFOSStage } from '@context/APIContext/hooks/useDFOSApi'
import type { SWRHookProps } from '@interfaces'
import useSWRImmutable from 'swr/immutable'

const useDfosStages = ({ key, config }: SWRHookProps<DFOSStage[] | undefined>) => {
  const {
    dfosApi: { getDfosStages },
  } = useAPIContext()

  const {
    data: dfosStages,
    error,
    mutate,
    isValidating: isLoadingDfosStages,
  } = useSWRImmutable(key, getDfosStages, config)

  return {
    dfosStages,
    error,
    mutate,
    isLoadingDfosStages,
  }
}

export { useDfosStages }
