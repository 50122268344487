import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'
import { objOfDateFormats } from '@constants/dateFormats'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isString } from '@helpers/checkTypes'
import dayjs from 'dayjs'

const stepOfStageFieldsValidation: FormValuesValidationSection<
  keyof StatementFormValues['stage']['stepOfStages'][number],
  StatementFormValues
> = {
  stepDescription: {
    required: defaultRHFValidation.required,
    validate: (v) => isString(v) && lengthValidate(v, 500),
  },
  stepStartDate: ({ form }) => ({
    required: defaultRHFValidation.required,
    validate: {
      positiveProjectStartDate: (value) =>
        (isString(value) &&
          dayjs(value, objOfDateFormats.defaultFormat) >=
            dayjs(
              form.getValues(statementBlockValues.stage.projectStartDate),
              objOfDateFormats.defaultFormat,
            )) ||
        'дата начала этапа должна быть больше или равна дате начала проекта',
    },
  }),
  stepEndDate: {
    required: defaultRHFValidation.required,
  },
}

export { stepOfStageFieldsValidation }
