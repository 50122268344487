import { useCallback, useState } from 'react'

const useLastUpdateFormDraft = () => {
  const [lastUpdateDraftTime, setLastUpdateDraftTime] = useState<string>('')

  const handleChangeLastUpdateDraft = useCallback((newDate: string) => {
    setLastUpdateDraftTime(newDate)
  }, [])

  return {
    lastUpdateDraftTime,
    handleChangeLastUpdateDraft,
  }
}

export { useLastUpdateFormDraft }
