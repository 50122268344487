import { FC } from 'react'

import TransitionFade from '@components/Transition/Fade'
import TransitionSlide from '@components/Transition/Slide'
import Slide from '@components/Transition/Slide'

interface CustomTransitionProps extends FC {
  Fade: typeof TransitionFade
  Slide: typeof TransitionSlide
}

const CustomTransition: CustomTransitionProps = () => null

CustomTransition.Fade = TransitionFade
CustomTransition.Slide = Slide

export default CustomTransition
