class PunnyCode {
  private initial_n = 0x80
  private initial_bias = 72
  private delimiter = '\x2D'
  private base = 36
  private damp = 700
  private tmin = 1
  private tmax = 26
  private skew = 38
  private maxint = 0x7fffffff

  private decode_digit(cp) {
    return cp - 48 < 10 ? cp - 22 : cp - 65 < 26 ? cp - 65 : cp - 97 < 26 ? cp - 97 : this.base
  }

  private encode_digit(d: number, flag) {
    return d + 22 + 75 * Number(d < 26) - (Number(flag != 0) << 5)
  }

  private adapt(delta, numpoints, firsttime) {
    let k = 0

    delta = firsttime ? Math.floor(delta / this.damp) : delta >> 1
    delta += Math.floor(delta / numpoints)

    for (k; delta > ((this.base - this.tmin) * this.tmax) >> 1; k += this.base) {
      delta = Math.floor(delta / (this.base - this.tmin))
    }
    return Math.floor(k + ((this.base - this.tmin + 1) * delta) / (delta + this.skew))
  }

  private encode_basic(bcp, flag) {
    bcp -= (bcp - Number(97 < 26)) << 5
    return bcp + (Number(!flag && bcp - 65 < 26) << 5)
  }

  private decode(input, preserveCase = false) {
    const output: Array<number> = [],
      case_flags: Array<boolean> = [],
      input_length = input.length

    let n, out, i, bias, basic, j, ic, oldi, w, k, digit, t, len

    n = this.initial_n
    i = 0
    bias = this.initial_bias

    basic = input.lastIndexOf(this.delimiter)
    if (basic < 0) basic = 0

    for (j = 0; j < basic; ++j) {
      if (preserveCase) case_flags[output.length] = input.charCodeAt(j) - 65 < 26

      if (input.charCodeAt(j) >= 0x80) {
        throw new RangeError('Illegal input >= 0x80')
      }
      output.push(input.charCodeAt(j))
    }

    for (ic = basic > 0 ? basic + 1 : 0; ic < input_length; ) {
      for (oldi = i, w = 1, k = this.base; ; k += this.base) {
        if (ic >= input_length) {
          throw RangeError('punycode_bad_input(1)')
        }
        digit = this.decode_digit(input.charCodeAt(ic++))

        if (digit >= this.base) {
          throw RangeError('punycode_bad_input(2)')
        }
        if (digit > Math.floor((this.maxint - i) / w)) {
          throw RangeError('punycode_overflow(1)')
        }
        i += digit * w
        t = k <= bias ? this.tmin : k >= bias + this.tmax ? this.tmax : k - bias
        if (digit < t) {
          break
        }
        if (w > Math.floor(this.maxint / (this.base - t))) {
          throw RangeError('punycode_overflow(2)')
        }
        w *= this.base - t
      }

      out = output.length + 1
      bias = this.adapt(i - oldi, out, oldi === 0)

      if (Math.floor(i / out) > this.maxint - n) {
        throw RangeError('punycode_overflow(3)')
      }
      n += Math.floor(i / out)
      i %= out

      if (preserveCase) {
        case_flags.splice(i, 0, input.charCodeAt(ic - 1) - 65 < 26)
      }

      output.splice(i, 0, n)
      i++
    }
    if (preserveCase) {
      for (i = 0, len = output.length; i < len; i++) {
        if (case_flags[i]) {
          output[i] = String.fromCharCode(output[i]).toUpperCase().charCodeAt(0)
        }
      }
    }
    return this.utf16.encode(output)
  }

  private encode(input, preserveCase = false) {
    let n, delta, h, b, bias, j, m, q, k, t, ijv, case_flags

    if (preserveCase) {
      case_flags = this.utf16.decode(input)
    }

    input = this.utf16.decode(input.toLowerCase())

    const input_length = input.length,
      output: Array<string> = []

    if (preserveCase) {
      for (j = 0; j < input_length; j++) {
        case_flags[j] = input[j] != case_flags[j]
      }
    }

    n = this.initial_n
    delta = 0
    bias = this.initial_bias

    for (j = 0; j < input_length; ++j) {
      if (input[j] < 0x80) {
        output.push(
          String.fromCharCode(case_flags ? this.encode_basic(input[j], case_flags[j]) : input[j]),
        )
      }
    }

    h = b = output.length

    if (b > 0) output.push(this.delimiter)

    while (h < input_length) {
      for (m = this.maxint, j = 0; j < input_length; ++j) {
        ijv = input[j]
        if (ijv >= n && ijv < m) m = ijv
      }

      if (m - n > Math.floor((this.maxint - delta) / (h + 1))) {
        throw RangeError('punycode_overflow (1)')
      }
      delta += (m - n) * (h + 1)
      n = m

      for (j = 0; j < input_length; ++j) {
        ijv = input[j]

        if (ijv < n) {
          if (++delta > this.maxint) return Error('punycode_overflow(2)')
        }

        if (ijv == n) {
          for (q = delta, k = this.base; ; k += this.base) {
            t = k <= bias ? this.tmin : k >= bias + this.tmax ? this.tmax : k - bias
            if (q < t) break
            output.push(String.fromCharCode(this.encode_digit(t + ((q - t) % (this.base - t)), 0)))
            q = Math.floor((q - t) / (this.base - t))
          }
          output.push(
            String.fromCharCode(this.encode_digit(q, preserveCase && case_flags[j] ? 1 : 0)),
          )
          bias = this.adapt(delta, h + 1, h == b)
          delta = 0
          ++h
        }
      }

      ++delta, ++n
    }
    return output.join('')
  }

  utf16 = {
    decode: function (input) {
      const output: Array<number> = [],
        len = input.length

      let i = 0,
        value = 0,
        extra = 0

      while (i < len) {
        value = input.charCodeAt(i++)
        if ((value & 0xf800) === 0xd800) {
          extra = input.charCodeAt(i++)
          if ((value & 0xfc00) !== 0xd800 || (extra & 0xfc00) !== 0xdc00) {
            throw new RangeError('UTF-16(decode): Illegal UTF-16 sequence')
          }
          value = ((value & 0x3ff) << 10) + (extra & 0x3ff) + 0x10000
        }
        output.push(value)
      }
      return output
    },
    encode: function (input) {
      const output: Array<string> = [],
        len = input.length

      let i = 0,
        value = 0

      while (i < len) {
        value = input[i++]
        if ((value & 0xf800) === 0xd800) {
          throw new RangeError('UTF-16(encode): Illegal UTF-16 value')
        }
        if (value > 0xffff) {
          value -= 0x10000
          output.push(String.fromCharCode(((value >>> 10) & 0x3ff) | 0xd800))
          value = 0xdc00 | (value & 0x3ff)
        }
        output.push(String.fromCharCode(value))
      }
      return output.join('')
    },
  }

  private formatArrayFromConverter(arr) {
    if (arr.length === 1) {
      return arr[0]
    }

    if (arr.length === 2) {
      return arr.join('.')
    }

    return arr.slice(0, -1).join('@') + '.' + arr.slice(-1)
  }

  ToASCII(str: string) {
    try {
      const domain_array = str.includes('@') ? str.split('@') : str.split('.')
      const domainToConvert = domain_array.length === 2 ? domain_array[1].split('.') : domain_array

      const out: Array<string> = []

      for (let i = 0; i < domainToConvert.length; ++i) {
        const s = domainToConvert[i]
        out.push(s.match(/[^A-Za-z0-9-]/) ? 'xn--' + this.encode(s) : s)
      }

      if (domain_array.length === 2) {
        const preparedValue = out.unshift(domain_array[0])

        return this.formatArrayFromConverter(preparedValue)
      }
    } catch {
      return str
    }
  }

  ToUnicode(str: string) {
    if (!str) return str

    try {
      const domain_array = str.includes('@') ? str.split('@') : str.split('.')
      const domainToConvert = domain_array.length === 2 ? domain_array[1].split('.') : domain_array

      const out: Array<string> = []

      for (let i = 0; i < domainToConvert.length; ++i) {
        const s = domainToConvert[i]
        out.push(s.match(/^xn--/) ? this.decode(s.slice(4)) : s)
      }

      if (domain_array.length === 2) {
        const preparedValue = out.unshift(domain_array[0])

        return this.formatArrayFromConverter(preparedValue)
      }

      return this.formatArrayFromConverter(out)
    } catch {
      return str
    }
  }
}

const encodingsConverter = new PunnyCode()

export { encodingsConverter }
