import { FocusEvent, useRef, useState } from 'react'

import useUpdateEffect from '@hooks/new/effects/useUpdateEffect'

interface UseTitleModeProps {
  onBlur: (e: FocusEvent<HTMLInputElement, Element>) => void
}

const useTitleMode = ({ onBlur }: UseTitleModeProps) => {
  const [editMode, setEditMode] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleBlurInput = (e: FocusEvent<HTMLInputElement, Element>) => {
    handleChangeEditMode()
    onBlur?.(e)
  }

  const handleEditClick = () => {
    if (editMode) return

    handleChangeEditMode()
  }

  useUpdateEffect(() => {
    if (editMode) {
      inputRef.current?.focus()
    }
  }, [editMode])

  const handleChangeEditMode = () => {
    setEditMode((prev) => !prev)
  }

  return {
    inputRef,
    state: {
      editMode,
    },
    handlers: {
      handleBlurInput,
      handleChangeEditMode,
      handleEditClick,
    },
  }
}

export { useTitleMode }
