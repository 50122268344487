import { UseFormReturn, useWatch } from 'react-hook-form'

import { indicatorsOfUnfulfilledAgreementReportBlockValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/const'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'

export const useFactInvestorCapitalIndicator = (
  formInstance: UseFormReturn<IndicatorsOfUnfulfilledAgreementReportFormValues, any>,
) => {
  const factInvestorCapitalVatIndicator = useWatch({
    name: indicatorsOfUnfulfilledAgreementReportBlockValues['1'].factInvestorCapitalVatIndicator,
    control: formInstance.control,
  })

  const factInvestorCapitalIndicatorTitle = factInvestorCapitalVatIndicator
    ? 'Сумма капиталовложений (с НДС) (факт)'
    : 'Сумма капиталовложений (без НДС) (факт)'

  const factInvestorCapitalIndicatorFormName = factInvestorCapitalVatIndicator
    ? indicatorsOfUnfulfilledAgreementReportBlockValues['1'].factInvestorCapitalVatIndicator
    : indicatorsOfUnfulfilledAgreementReportBlockValues['1'].factInvestorCapitalIndicator

  return { factInvestorCapitalIndicatorTitle, factInvestorCapitalIndicatorFormName }
}

export const useInvestorCapitalMadeOnTimeIndicatorValue = (
  formInstance: UseFormReturn<IndicatorsOfUnfulfilledAgreementReportFormValues, any>,
) => {
  const investorCapitalMadeOnTimeIndicatorField = useWatch({
    name: indicatorsOfUnfulfilledAgreementReportBlockValues['1'].investorCapitalMadeOnTimeIndicator,
    control: formInstance.control,
  })

  const investorCapitalMadeOnTimeIndicatorValue =
    investorCapitalMadeOnTimeIndicatorField !== null &&
    !isNaN(Number(investorCapitalMadeOnTimeIndicatorField))
      ? Number(investorCapitalMadeOnTimeIndicatorField)
      : null

  return investorCapitalMadeOnTimeIndicatorValue
}
