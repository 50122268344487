import React from 'react'
import { useFormContext } from 'react-hook-form'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Third = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const formModifierInstance = useFormModifierContext<RegistrationCertificateFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, handleChangePublicValue, handleChangePublicValueType },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { getSingleSelectProps, getCheckboxProps, getControllerProps, getFormFieldControlProps } =
    useFormComponentPresets({
      isNew: true,
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: RegistrationCertificateFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].investorFullName,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValueType?.(
                      registrationCertificateBlockValues['3'].investorFullName,
                      value,
                      'memberFullName',
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Полное наименование"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['3'].investorFullName,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].investorName,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValueType?.(
                      registrationCertificateBlockValues['3'].investorName,
                      value,
                      'memberName',
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Сокращенное наименование"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['3'].investorName,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row className={styles.form__checkboxRow}>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].isProjectCompany,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['3'].isProjectCompany,
                      value,
                    ),
                },
              })}
            >
              <ControlledCheckbox
                {...getCheckboxProps({
                  name: registrationCertificateBlockValues['3'].isProjectCompany,
                  checkBoxProps: {
                    disabled: true,
                    label: 'Проектная компания',
                  },
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].investorAddressFullEgrulFormat,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValueType?.(
                      registrationCertificateBlockValues['3'].investorAddressFullEgrulFormat,
                      value,
                      'addressFullEgrulFormat',
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Адрес"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['3'].investorAddressFullEgrulFormat,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].investorInn,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValueType?.(
                      registrationCertificateBlockValues['3'].investorInn,
                      value,
                      'memberInn',
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="ИНН"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['3'].investorInn,
                })}
              />
            </FormFieldControl>
          </Col>
          <Col xs={6}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].investorOgrn,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValueType?.(
                      registrationCertificateBlockValues['3'].investorOgrn,
                      value,
                      'memberOgrn',
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="ОГРН"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['3'].investorOgrn,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].investorEmail,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValueType?.(
                      registrationCertificateBlockValues['3'].investorEmail,
                      value,
                      'memberEmail',
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Электронная почта"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['3'].investorEmail,
                })}
              />
            </FormFieldControl>
          </Col>
          <Col xs={6}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].investorPhone,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValueType?.(
                      registrationCertificateBlockValues['3'].investorPhone,
                      value,
                      'memberPhone',
                    ),
                },
              })}
            >
              <ControlledDocumentDataView
                suptitle="Телефон"
                controllerProps={getControllerProps({
                  name: registrationCertificateBlockValues['3'].investorPhone,
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: registrationCertificateBlockValues['3'].investorHeaderName,
                formFieldPublicProps: {
                  control: formModifierInstance.control,
                  onPublic: (value) =>
                    handleChangePublicValue?.(
                      registrationCertificateBlockValues['3'].investorHeaderName,
                      value,
                    ),
                },
              })}
            >
              {({ overrideProps }) => {
                const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                  ? overrideProps.fetcherOptions
                  : undefined

                return (
                  <ControlledFormSingleSelect
                    {...getSingleSelectProps({
                      fetcherProps,
                      optionsAdapter: (item) => ({
                        displayValue: item.headerName || '',
                        value: item.id,
                      }),
                      selectProps: {
                        inputProps: {
                          label:
                            'Лицо, уполномоченное действовать от имени организации без доверенности',
                        },
                        onChangeFormValue: () =>
                          handleChangeValue?.(
                            registrationCertificateBlockValues['3'].investorHeaderName,
                          ),
                      },
                      controllerProps: {
                        name: registrationCertificateBlockValues['3'].investorHeaderName,
                      },
                    })}
                  />
                )
              }}
            </FormFieldControl>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Third
