import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { projectSZPKBlockValues } from '@components/Forms/ProjectSZPKForm/const'
import RegistrationItem from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CRegistrationForm/RegistrationItem'
import { eRegistrationProjectSZPKValidationMap } from '@components/Forms/ProjectSZPKForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CRegistrationForm/validation'
import {
  SeventhRegistrationObjectsPathName,
  SeventhRegistrationPathName,
} from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import {
  ProjectSZPKFieldArrayControlUpdateWatcher,
  ProjectSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/ProjectSZPKForm/watcher'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'
import dayjs from 'dayjs'

interface CRegistrationFormProps {
  name: SeventhRegistrationPathName
  control: Control<ProjectSZPKFormValues>
  indexOfBlock: number
}

const CRegistrationForm: FC<CRegistrationFormProps> = ({ name, control, indexOfBlock }) => {
  const formInstance = useFormContext<ProjectSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleRemoveItemFromList,
      handleAddItemToListWithOutValue,
      debouncedHandleChangeValue,
      handleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getInputProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ProjectSZPKFieldsControlUpdateWatcher,
    })

  const { fields: registrationForms } = useFieldArraySubscribableControl<
    ProjectSZPKFormValues,
    SeventhRegistrationObjectsPathName,
    'keyNameId'
  >({
    name: `${name}.registrationObjects`,
    control,
    keyName: 'keyNameId',
    watcher: ProjectSZPKFieldArrayControlUpdateWatcher,
  })

  const handleAddRegistration = async () => {
    await handleAddItemToListWithOutValue?.(`${name}.registrationObjects`)
  }

  const handleRemoveRegistration = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.registrationObjects.${index}`,
      `${name}.registrationObjects`,
    )
  }

  if (!formInstance) return null

  const positiveStepEndDate = (index: number) => (text: string) => (value: unknown) => {
    const stepEndDate = formInstance.getValues(
      `${projectSZPKBlockValues['6'].stages}.${index}.stepEndDate`,
    ) as string

    if (
      isDateValidForDayjs(value) &&
      dayjs(value, objOfDateFormats.defaultFormat) >
        dayjs(stepEndDate, objOfDateFormats.defaultFormat)
    )
      return text
  }

  return (
    <Container className="p-0 pt-3 px-0">
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={8}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.typeObjectStateRegistration`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${name}.typeObjectStateRegistration`,
                  rules: eRegistrationProjectSZPKValidationMap.typeObjectStateRegistration,
                  inputProps: {
                    label: 'Вид права, подлежащего гос.регистрации',
                    dataTestId: 'typeObjectStateRegistration',
                    caption: 'в родительном падеже',
                    leftAddons: (
                      <FormIconWithTooltip tooltipContent='Например: собственности. Слова "право" указывать не требуется' />
                    ),
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.typeObjectStateRegistration`), 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={4}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.objectStateRegistrationEndDate`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.objectStateRegistrationEndDate`,
                  rules: {
                    ...eRegistrationProjectSZPKValidationMap.objectStateRegistrationEndDate,
                    onBlur: async () => {
                      formInstance.trigger(projectSZPKBlockValues['5'].madeInvestorCapitalDate)
                    },
                    validate: {
                      positiveStepEndDate: (value) => {
                        const stepEndDate = formInstance.getValues(
                          `${projectSZPKBlockValues['6'].stages}.${indexOfBlock}.stepEndDate`,
                        ) as string

                        return (
                          (isDateValidForDayjs(value) &&
                            DayjsService.dayjs(value, objOfDateFormats.defaultFormat).isSame(
                              DayjsService.dayjs(stepEndDate, objOfDateFormats.defaultFormat),
                              'd',
                            )) ||
                          'дата должна быть равна дате окончания этапа'
                        )
                      },
                    },
                  },
                  calendarInputProps: {
                    label: 'Дата регистрации',
                  },
                  onBlur: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${name}.objectStateRegistrationEndDate`),
                      0,
                    ),
                  onInputChange: () =>
                    debouncedHandleChangeValue?.(`${name}.objectStateRegistrationEndDate`),
                  onCalendarChange: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${name}.objectStateRegistrationEndDate`),
                      0,
                    ),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Stack direction="vertical" gap={3} className={'mt-3'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.registrationObjects`,
            })}
          >
            <FlipperList list={registrationForms} gap={3}>
              {registrationForms.map((registrationForm, index) => {
                return (
                  <SubscribableControl
                    key={registrationForm.id}
                    {...getSubscribableControlProps({
                      path: `${name}.registrationObjects.${index}`,
                    })}
                  >
                    <RegistrationItem
                      id={registrationForm.id}
                      anchorId={registrationForm.anchorId}
                      formName={`${name}.registrationObjects.${index}` as const}
                      blockViewIsValidating={blockViewIsValidating}
                      editMode={editMode}
                      formInstance={formInstance}
                      indexOfRegistration={index}
                      positiveStepEndDate={positiveStepEndDate(indexOfBlock)}
                      onDeleteRegistration={handleRemoveRegistration(index)}
                    />
                  </SubscribableControl>
                )
              })}
            </FlipperList>
          </SubscribableControl>
          {editMode && (
            <Row className={'px-1'}>
              <Col xs={9}>
                <AsyncWrapper promise={handleAddRegistration}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <Button
                        disabled={isLoading}
                        leadingIcon={{ src: CircleAddIcon }}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        view={'plain'}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Указать зарегистрированный объект
                      </Button>
                    )
                  }}
                </AsyncWrapper>
              </Col>
            </Row>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

export default CRegistrationForm
