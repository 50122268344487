import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  DataOnFulfillmentOfTermsSZPKFormValues,
  EconomicSphereOkvedRegionalReportCard,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import {
  defaultRHFValidation,
  specificIntValidate,
  standardAmountValidate,
} from '@constants/validations'
import { isString } from '@helpers/checkTypes'

export const economicSphereOkvedsValidationMap: FormValuesValidationSection<
  keyof EconomicSphereOkvedRegionalReportCard,
  DataOnFulfillmentOfTermsSZPKFormValues
> = {
  economicSphereOkvedCodeRegionalReport: defaultRHFValidation.required,

  countEconomicSphereOkvedRegionalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return
      return specificIntValidate(value)
    },
  },
  newWorkPlacesEconomicSphereOkvedRegionalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return
      return specificIntValidate(value)
    },
  },

  totalCapitalEconomicSphereOkvedRegionalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return
      return standardAmountValidate(value)
    },
  },
  investorCapitalEconomicSphereOkvedRegionalReportCorrect: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return
      return standardAmountValidate(value)
    },
  },
}
