import { memo } from 'react'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { FormModalLayoutProps } from '@components/DocumentFormComponents/types'
import { defaultCreateStatementFormValues } from '@components/Forms/CreateStatement/const'
import CreateStatementLayout from '@components/Forms/CreateStatement/Layout'

const CreateStatementLayoutWithFormProvider = (props: FormModalLayoutProps) => {
  return (
    <FormProviderWrapper defaultValues={defaultCreateStatementFormValues}>
      <CreateStatementLayout {...props} />
    </FormProviderWrapper>
  )
}

export default memo(CreateStatementLayoutWithFormProvider)
