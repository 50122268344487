import { DocumentsHelpersService } from '@services/Documents/documents.helpers'

const {
  isDocumentReadOnlyPermission,
  isDocumentReadSignPermission,
  isDocumentReadEditPermission,
  isDocumentReadSignMainPermission,
  isDocumentReadAddSignPermission,
  isDocumentReadApprovePermission,
  isDocumentReadDigitizing,
  isDocumentReadDownloadingForbidden,
} = DocumentsHelpersService

const useWebFormPermissions = (permissions: number) => {
  const isWebFormReadOnlyPermission = isDocumentReadOnlyPermission(permissions)
  const isWebFormReadSignPermission = isDocumentReadSignPermission(permissions)
  const isWebFormReadSignMainPermission = isDocumentReadSignMainPermission(permissions)
  const isWebFormReadEditPermission = isDocumentReadEditPermission(permissions)
  const isWebFormReadAddSignPermission = isDocumentReadAddSignPermission(permissions)
  const isWebFormReadApprovePermission = isDocumentReadApprovePermission(permissions)
  const isWebFormReadDigitizing = isDocumentReadDigitizing(permissions)
  const isWebFormReadDownloadingForbidden = isDocumentReadDownloadingForbidden(permissions)

  const isWebFormReadPermission =
    isWebFormReadOnlyPermission ||
    isWebFormReadSignPermission ||
    isWebFormReadSignMainPermission ||
    isWebFormReadDownloadingForbidden

  return {
    isWebFormReadOnlyPermission,
    isWebFormReadPermission,
    isWebFormReadEditPermission,
    isWebFormReadAddSignPermission,
    isWebFormReadDigitizing,
    isWebFormReadDownloadingForbidden,
    isWebFormReadApprovePermission,
  }
}

export { useWebFormPermissions }
