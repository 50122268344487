import { useCallback } from 'react'

import { isCheckFormChangedError } from '@components/DocumentFormComponents/hooks/useFormChanges/types'
import usePropertiesApi from '@context/APIContext/hooks/usePropertiesApi'
import { ApplyChangesReturn, Property } from '@services/Properties/OOP/Property'
import { DefaultPropertiesProps } from '@services/Properties/Properties.entity'

interface UseFormChangesProps extends DefaultPropertiesProps {
  getLastFieldUpdateTime: () => string | undefined
  propertyInstance: Property | null
}

const useFormChanges = ({
  formId,
  getLastFieldUpdateTime,
  propertyInstance,
}: UseFormChangesProps) => {
  const { getFormPropertiesChanges } = usePropertiesApi()

  //Проверяет, изменилась ли форма кем-то другим, пока пользователь его редактировал.
  const checkFormChanges = useCallback(async () => {
    const lastChangesDt = getLastFieldUpdateTime()

    if (!lastChangesDt) return

    try {
      const propertiesChanges = await getFormPropertiesChanges({
        formId,
        lastChangesDt,
      })

      if (
        propertiesChanges.length &&
        (propertiesChanges[0].changed || propertiesChanges[0].added || propertiesChanges[0].deleted)
      )
        return Promise.reject({
          formIsChanged: true,
          changedProperties: propertiesChanges,
          lastChangesDt,
        })
    } catch (e) {
      throw e
    }
  }, [formId, getLastFieldUpdateTime, getFormPropertiesChanges])

  const applyFormChanges = useCallback(
    (onApplyChanges?: (props: ApplyChangesReturn) => void) => async () => {
      try {
        await checkFormChanges()
      } catch (e) {
        if (!isCheckFormChangedError(e) || !propertyInstance) throw e

        const propsForApplyChanges = propertyInstance.applyAllChanges(e.changedProperties)
        onApplyChanges?.(propsForApplyChanges)
      }
    },
    [checkFormChanges, propertyInstance],
  )

  return {
    checkFormChanges,
    applyFormChanges,
  }
}

export { useFormChanges }
