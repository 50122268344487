import React, { FC, useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import Alert from '@components/Alert'
import Form from '@components/Form'
import Button from '@components/NewDesign/Button'
import Input from '@components/NewDesign/Input'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import SuccessModal from '@components/NewDesignedModals/SuccessModal'
import { NotificationWithButton } from '@components/NewNotifications/NotificationWithButton'
import { defaultMessage, successModalBodyTexts, successModalHeaderTexts } from '@constants/texts'
import { Patterns } from '@constants/validations'
import useOrganizationApi from '@context/APIContext/hooks/useOrganizationApi'
import { useAuthContext } from '@context/AuthContext'
import { isAxiosError } from '@helpers/checkTypes'
import { handleErrorWithPopup } from '@helpers/errorWithPopup'
import useAuthUser from '@hooks/useAuthUser'
import { useBooleanState } from '@hooks/useBooleanState'
import mailIcon from '@icons/content/mail.svg'
import { MessageWithUserDataAndPopup } from '@layouts/Header/Message/model'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import storage from 'store2'

import styles from './Message.module.scss'

const ICON_COLOR = '#ffffff' // surface-primary

export const EmailIndication: FC<MessageWithUserDataAndPopup> = ({
  isOpen,
  onOpen,
  onClose,
  userInfo,
}) => {
  const { booleanState: isEmailChanged, setBooleanStateToTrue: setIsEmailChangedToTrue } =
    useBooleanState()
  const { booleanState: isEmailProcessing, setBooleanState: setEmailProcessing } = useBooleanState()

  const { indicationEmail, askEmailApproving } = useOrganizationApi()

  const { refreshToken } = useAuthContext()

  const { control, handleSubmit } = useForm({})

  const { mutate: revalidateUser } = useAuthUser()

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name: 'EmailModal',
    defaultValue: '',
    rules: {
      required: {
        value: true,
        message: 'поле является обязательным',
      },
      pattern: {
        value: Patterns.Email,
        message: 'неправильный формат введенной почты',
      },
    },
  })

  const indicationEmailHandler = useCallback(async () => {
    if (!userInfo) return

    setEmailProcessing(true)

    try {
      await indicationEmail({
        id: String(userInfo.id),
        email: value,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        profile: userInfo.profile,
      })

      try {
        await askEmailApproving()
      } catch (error) {
        throw error
      }

      await refreshToken?.({
        refresh_token: storage.get('auth_state')?.refreshToken,
      })

      await revalidateUser()

      setIsEmailChangedToTrue()
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'EmailIndication',
          moduleName: 'useEmailMessage',
          componentType: 'indicationEmailHandler',
        },
      })(error)

      handleErrorWithPopup(error)

      if (!isAxiosError(error) || !error.response) {
        return toast(
          <Alert transparent variant="error">
            {defaultMessage}
          </Alert>,
        )
      }
    } finally {
      setEmailProcessing(false)
    }
  }, [
    userInfo,
    setEmailProcessing,
    indicationEmail,
    value,
    refreshToken,
    revalidateUser,
    setIsEmailChangedToTrue,
    askEmailApproving,
  ])

  return (
    <>
      {isEmailChanged ? (
        <SuccessModal
          headerText={successModalHeaderTexts.checkEmail}
          text={`${successModalBodyTexts.checkEmail} ${value}`}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : (
        <Modal.Simple
          disableOnClose={isEmailProcessing}
          isOpen={isOpen}
          title={'Укажите свою почту'}
          dataTestId="EmailIndication-emailModal"
          closeButtonDataTestId="EmailIndication-emailModal-closeButton"
          simpleModalContainerClassName={styles.Modal}
          onClose={onClose}
        >
          <Form
            dataTestId="EmailIndication-emailModal-form"
            onSubmit={handleSubmit(indicationEmailHandler)}
          >
            <Typography className={styles.Modal_description} variant={'bodyMRegular'}>
              На неё отправим ссылку для подтверждения, чтобы вы могли получать уведомления о ходе
              рассмотрения соглашения
            </Typography>
            <Input
              fixWidth
              error={error}
              value={value}
              name={'EmailModal'}
              dataTestId="EmailIndication-emailModal-input"
              label="Электронная почта для связи"
              view="secondary"
              onChange={onChange}
            />
            <div className={styles.Buttons}>
              <Button
                disabled={isEmailProcessing}
                view={'gray'}
                dataTestId="EmailIndication-cancel-button"
                type={'button'}
                onClick={onClose}
              >
                Отмена
              </Button>
              <Button
                disabled={isEmailProcessing || !!error || !value || !userInfo}
                view={'filled'}
                className={styles.Buttons_change}
                dataTestId="EmailIndication-changeEmail-button"
                type={'submit'}
                loaderProps={{
                  variant: 'lite',
                  placement: 'trailing',
                  loading: isEmailProcessing,
                }}
              >
                Изменить
              </Button>
            </div>
          </Form>
        </Modal.Simple>
      )}
      <NotificationWithButton
        title={'Укажите свою почту'}
        description={'На нее будут приходить уведомления о ходе подписания соглашения'}
        dataTestId="EmailIndication-container"
        titleDataTestId={'EmailIndication-title'}
        descriptionDataTestId={'EmailIndication-description'}
        iconProps={{
          src: mailIcon,
          color: ICON_COLOR,
          className: styles.SingleMessage__icon,
        }}
        onClick={onOpen}
      />
    </>
  )
}
