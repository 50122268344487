import React, { FC } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { FormModalLayoutWrapperProps } from '@components/DocumentFormComponents/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'

const ProjectSZPKLayoutWrapper: FC<FormModalLayoutWrapperProps> = ({ children, lastUpdateDraftTime, ...rest }) => {
  const {
    actions: { handleFormClose },
  } = useProjectSZPKManager()

  const preparedHandleFormClose = () => {
    handleFormClose?.()
  }

  return (
    <>
      <DocumentLayoutContent
        {...rest}
        id="layout"
        draftIsSaved={!!lastUpdateDraftTime}
        onClose={preparedHandleFormClose}
      >
        {children}
      </DocumentLayoutContent>
    </>
  )
}

export default ProjectSZPKLayoutWrapper
