import { FC } from 'react'

import First from './1'
import Second from './2'
import Third from './3'

interface CertificateOfComplianceContractForRecognitionConcludedAFormsProps extends FC {
  First: typeof First
  Second: typeof Second
  Third: typeof Third
}

const CertificateOfComplianceContractForRecognitionConcludedAForms: CertificateOfComplianceContractForRecognitionConcludedAFormsProps =
  () => null

CertificateOfComplianceContractForRecognitionConcludedAForms.First = First
CertificateOfComplianceContractForRecognitionConcludedAForms.Second = Second
CertificateOfComplianceContractForRecognitionConcludedAForms.Third = Third

export default CertificateOfComplianceContractForRecognitionConcludedAForms
