import { createContext, FC, useContext, useMemo } from 'react'

import { useAuthorizedOrganizationsList } from '@hooks/new/swr/useAuthorizedOrganizationsList'
import { useBooleanState } from '@hooks/useBooleanState'

import { useAuthorizedOrganizationsFilters } from '../hooks/useAuthorizedOrganizationsFilters'

import { AuthorizedOrganizationsContextProps, AuthorizedOrganizationsManagerProps } from './types'

const AuthorizedOrganizationsContext = createContext<AuthorizedOrganizationsContextProps>({
  state: {
    additionalLoader: false,
    gridSwrInstance: null,
    filtersInstance: null,

    debouncedSearchValue: '',
  },
  handlers: {
    setAdditionalLoader: null,
  },
})

const keyMutatorAuthorizedOrganizationsList = {
  keyMutator: { _key: 'authorizedOrganizationsList' },
}

const useAuthorizedOrganizationsManager = () => {
  return useContext(AuthorizedOrganizationsContext)
}

const AuthorizedOrganizationsManager: FC<AuthorizedOrganizationsManagerProps> = ({ children }) => {
  const { booleanState: additionalLoader, setBooleanState: setAdditionalLoader } = useBooleanState()

  const { filtersInstance, preparedKeyToSwr, debouncedSearchValue } =
    useAuthorizedOrganizationsFilters(keyMutatorAuthorizedOrganizationsList)

  const gridSwrInstance = useAuthorizedOrganizationsList({
    key: preparedKeyToSwr,
    config: {
      revalidateFirstPage: true,
    },
  })

  const providerValue: AuthorizedOrganizationsContextProps = useMemo(
    () => ({
      state: {
        additionalLoader,
        filtersInstance,
        debouncedSearchValue,
        gridSwrInstance,
      },
      handlers: {
        setAdditionalLoader,
      },
    }),
    [additionalLoader, debouncedSearchValue, filtersInstance, gridSwrInstance, setAdditionalLoader],
  )

  return (
    <AuthorizedOrganizationsContext.Provider value={providerValue}>
      {children}
    </AuthorizedOrganizationsContext.Provider>
  )
}

export default AuthorizedOrganizationsManager
export { useAuthorizedOrganizationsManager }
