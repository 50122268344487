import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import RegistrationCertificateMunicipalitiesList from '@components/Forms/RegistrationCertificateForm/Forms/4/MunicipalitiesList'
import RegistrationCertificateSubjectsList from '@components/Forms/RegistrationCertificateForm/Forms/4/SubjectsList'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const formModifierInstance = useFormModifierContext<RegistrationCertificateFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
    handlers: { handleChangeValue, handleChangePublicValue, handleChangePublicValueType },
  } = useRegistrationCertificateManager()

  const projectIsFederal = useWatch({
    name: registrationCertificateBlockValues.additionalFields.isFederal,
    control: formInstance?.control,
  })

  const { getControllerProps, getFormFieldControlProps } = useFormComponentPresets({
    isNew: true,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        {projectIsFederal && (
          <Row>
            <Col xs={12}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: registrationCertificateBlockValues['4'].rfFullName,
                  formFieldPublicProps: {
                    control: formModifierInstance.control,
                    onPublic: (value) =>
                      handleChangePublicValueType?.(
                        registrationCertificateBlockValues['4'].rfFullName,
                        value,
                        'memberFullName',
                      ),
                  },
                })}
              >
                <ControlledDocumentDataView
                  suptitle="Уполномоченный федеральный орган исполнительной власти"
                  controllerProps={getControllerProps({
                    name: registrationCertificateBlockValues['4'].rfFullName,
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
        )}
        <RegistrationCertificateSubjectsList
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
          subscribableControl={subscribableControl}
        />
        <RegistrationCertificateMunicipalitiesList
          editMode={editMode}
          blockViewIsValidating={blockViewIsValidating}
          formInstance={formInstance}
          formModifierInstance={formModifierInstance}
          subscribableControl={subscribableControl}
          handleChangePublicValue={handleChangePublicValue}
          onChangeValue={handleChangeValue}
        />
      </Stack>
    </Container>
  )
}

export default Fourth
