import React, { memo } from 'react'

import SystemVariablesHeader from '@components/SystemVariables/Header'
import SystemVariablesList from '@components/SystemVariables/List'
import SystemVariablesManager from '@components/SystemVariables/Manager'
import styles from '@components/SystemVariables/SystemVariables.module.scss'

const SystemVariablesLayout = () => {
  return (
    <SystemVariablesManager>
      <div className={styles.systemVariables}>
        <header className={styles.systemVariables__header}>
          <SystemVariablesHeader />
        </header>
        <div className={styles.systemVariables__body}>
          <SystemVariablesList />
        </div>
      </div>
    </SystemVariablesManager>
  )
}

export default memo(SystemVariablesLayout)
