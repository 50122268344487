import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues } from '@components/Forms/NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm/types'

const generateRHFObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm =
  (
    objectFromPropertyState: Record<string, any>,
  ): NotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyFormValues => {
    return {
      '1': {
        ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
        documentReceivers: objectFromPropertyState?.documentReceiversDraft?.value,
      },
      '2': {
        ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
        investorFullNameInstrumental:
          objectFromPropertyState.investorFullNameInstrumentalDraft?.value || '',
      },
      '3': {
        reasonDenySolicitationProject:
          objectFromPropertyState?.reasonDenySolicitationDraft?.value || '',
        ruleLinkDenySolicitationProject:
          objectFromPropertyState?.ruleLinkDenySolicitationDraft?.value || '',
      },
    }
  }

export { generateRHFObjectForNotificationOfImpossibilitySatisfyingContractToRecognizeConcludedAProjectResultDenyForm }
