import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import { MultipleSelectProps } from '@components/NewDesign/Select/model'
import { MultipleSelect } from '@components/NewDesign/Select/MultipleSelect/MultipleSelect'

export interface ControlledMultipleSelectProps<T extends FieldValues> extends MultipleSelectProps {
  controllerProps: Omit<ControllerProps<T>, 'render'>
}

export const ControlledMultipleSelect = <T extends FieldValues>(
  props: ControlledMultipleSelectProps<T>,
) => {
  const { controllerProps, onRemoveChip, ...restProps } = props

  const {
    field: { onChange: onChangeForm, onBlur: onBlurForm, value: formValue },
    fieldState: { error },
  } = useController<T>(controllerProps)

  const onMergedBlur = () => {
    onBlurForm()

    restProps?.onBlurForm?.()
  }

  const onMergedChange = (currentList: string[], currentValue: string, prevList: string[]) => {
    onChangeForm(currentList)

    restProps?.onChangeForm?.(currentList, currentValue, prevList)
  }

  const onMergedRemoveChip = (payload: string[], removedValue: string) => {
    onRemoveChip?.(payload, removedValue)

    if (!payload.length) {
      onBlurForm()
      onChangeForm(payload)

      return
    }

    onChangeForm(payload)
  }

  return (
    <MultipleSelect
      {...restProps}
      error={error}
      defaultSelected={restProps.defaultSelected || formValue}
      onChangeForm={onMergedChange}
      onBlurForm={onMergedBlur}
      onRemoveChip={onMergedRemoveChip}
    />
  )
}
