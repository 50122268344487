import React from 'react'
import { useFormContext } from 'react-hook-form'

import MunicipalitiesSection from '@components/DocumentFormComponents/FormSections/MunicipalitiesSection'
import { useAAgreementAccessionMunicipalManager } from '@components/Forms/AAgreementAccessionMunicipalForm/Manager'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'
import {
  AAgreementAccessionMunicipalFieldArrayControlUpdateWatcher,
  AAgreementAccessionMunicipalFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementAccessionMunicipalForm/watcher'

const Three = () => {
  const formInstance = useFormContext<AAgreementAccessionMunicipalFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementAccessionMunicipalManager()

  if (!formInstance) return null

  return (
    <MunicipalitiesSection
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formName={'3.municipalities'}
      formInstance={formInstance}
      watcher={AAgreementAccessionMunicipalFieldsControlUpdateWatcher}
      fieldArrayWatcher={AAgreementAccessionMunicipalFieldArrayControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Three
