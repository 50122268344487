import React, { FC, memo, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import Loader from '@components/Loader'
import { ButtonProps } from '@components/NewDesign/Button/types'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Sidebar from '@components/NewDesign/Sidebar'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import Redaction from '@components/Sidebars/NPA/PartChanges/Redaction'
import { defaultRHFValidation } from '@constants/validations'
import { useNpaRedactions } from '@hooks/new/swr/useNpaRedactions'
import { ChosenPart, PartCustomProps } from '@services/NPA/NPA.entity'

import styles from './PartChanges.module.scss'

interface NpaPartChangesOldProps {
  npaId: string
  partId: string
  onReplaceChosenPart: (newChosen: Omit<ChosenPart, 'classificationId'>) => void
  onRemovePart: () => void
  initialJustification?: string
  initialPartCorrection?: string
}

type NpaPartChangesSidebarOldProps = PopupProps & NpaPartChangesOldProps

const useNpaPartChangesOldSidebar = () => {
  const popupManager = usePopupManager()

  const handleOpenNpaPartChangesSidebar = (props: NpaPartChangesSidebarOldProps) => {
    popupManager.open(PartChangesSidebarOld, props)
  }

  return {
    handleOpenNpaPartChangesSidebar,
  }
}

const PartChangesSidebarOld: FC<NpaPartChangesSidebarOldProps> = ({
  isOpen,
  onClose,
  initialJustification,
  initialPartCorrection,
  npaId,
  partId,
  onReplaceChosenPart,
  onRemovePart,
}) => {
  const { npaRedactions, isLoadingNpaRedactions } = useNpaRedactions({
    key: {
      npaId,
      partId,
      _key: 'npaRedactions',
    },
  })

  const prevVersion = useMemo(() => {
    return npaRedactions?.find((redaction) => redaction.id === partId)
  }, [npaRedactions, partId])

  const lastVersion = useMemo(() => {
    return npaRedactions?.find((redaction) => redaction.isActual)
  }, [npaRedactions])

  const preparedOnCloseHandler = () => {
    onClose?.()
  }

  const { control, handleSubmit, getValues } = useForm<PartCustomProps>({
    defaultValues: {
      justification: initialJustification ?? '',
      partCorrection: initialPartCorrection ?? '',
    },
  })

  const handleReplaceChosenPart = useCallback(() => {
    if (!lastVersion) return

    const { justification, partCorrection } = getValues()

    onReplaceChosenPart({
      partCorrection,
      justification,
      partId: lastVersion.id,
      npaId: lastVersion.npaInfo.id,
    })

    onClose?.()
  }, [getValues, lastVersion, onClose, onReplaceChosenPart])

  const handleRemovePart = useCallback(() => {
    onRemovePart()

    onClose?.()
  }, [onClose, onRemovePart])

  const actions = useMemo(() => {
    return prevVersion && lastVersion
      ? ([
          {
            children: 'Удалить',
            view: 'gray',
            color: 'negative',
            onClick: handleRemovePart,
          },
          {
            children: 'Принять изменения',
            onClick: handleSubmit(handleReplaceChosenPart),
          },
        ] as ButtonProps[])
      : undefined
  }, [handleRemovePart, handleSubmit, handleReplaceChosenPart, lastVersion, prevVersion])

  return (
    <Sidebar
      disableOnClose
      headerClassName={styles['partChanges__sidebar-header']}
      contentClassName={styles['partChanges__sidebar-content']}
      actions={actions}
      isOpen={isOpen}
      title={'Просмотр изменений'}
      onClose={preparedOnCloseHandler}
    >
      <Loader variant={'lite'} loading={isLoadingNpaRedactions}>
        <div className={styles.partChanges}>
          {prevVersion && (
            <div className={styles.partChanges__prevVersion}>
              <Redaction applyGreyMode redaction={prevVersion} />
            </div>
          )}
          <div className={styles.partChanges__delimiter}>
            <span className={styles['partChanges__delimiter-line']} />
            <Typography.Body
              className={styles['partChanges__delimiter-text']}
              as={'span'}
              color={'text-accent-brand'}
              variant={'bodyMRegular'}
            >
              Изменено
            </Typography.Body>
          </div>
          {lastVersion && (
            <div className={styles.partChanges__lastVersion}>
              <Redaction redaction={lastVersion} />
            </div>
          )}
        </div>
        <div className={styles.partChanges__controls}>
          <Typography.Body
            className={styles['partChanges__controls-title']}
            variant={'bodyLMedium'}
          >
            Обоснование выбора положения
          </Typography.Body>

          <ControlledTextarea
            name={'justification'}
            control={control}
            rules={{
              required: defaultRHFValidation.required,
            }}
            textareaProps={{
              rootClassName: styles['partChanges__controls-item'],
              label: 'Обоснование',
              fixWidth: true,
              minRows: 2,
            }}
          />
          <ControlledInput
            name={'partCorrection'}
            control={control}
            inputProps={{
              rootClassName: styles['partChanges__controls-item'],
              label: 'Статьи и пункты',
              fixWidth: true,
              view: 'secondary',
            }}
          />
        </div>
      </Loader>
    </Sidebar>
  )
}

export { useNpaPartChangesOldSidebar }
export default memo(PartChangesSidebarOld)
