import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import styles from '@components/DocumentFormComponents/FormStyles/FormFields.module.scss'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementAccessionMunicipalBlockValues } from '@components/Forms/AAgreementAccessionMunicipalForm/const'
import { sixthSectionAAgreementAccessionMunicipalValidationMap } from '@components/Forms/AAgreementAccessionMunicipalForm/Forms/6/validation'
import { useAAgreementAccessionMunicipalManager } from '@components/Forms/AAgreementAccessionMunicipalForm/Manager'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'
import { AAgreementAccessionMunicipalFieldsControlUpdateWatcher } from '@components/Forms/AAgreementAccessionMunicipalForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Sixth = () => {
  const formInstance = useFormContext<AAgreementAccessionMunicipalFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementAccessionMunicipalManager()

  const { getSubscribableControlProps, getCheckboxProps, getInputProps, getCalendarInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAgreementAccessionMunicipalFieldsControlUpdateWatcher,
    })

  const szpkNumber = useWatch({
    name: aAgreementAccessionMunicipalBlockValues['additionalFields'].szpkNumber,
    control: formInstance.control,
  })

  const extraMunicipalAccessionLinksRenderCondition = !szpkNumber

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              disableTopPadding
              title={'Пункты Правил, на основании которых определяются муниципальные стаб. акты'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aAgreementAccessionMunicipalBlockValues['6'].ruleLinkMunicipalNpa,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: aAgreementAccessionMunicipalBlockValues['6'].ruleLinkMunicipalNpa,
                    rules:
                      sixthSectionAAgreementAccessionMunicipalValidationMap.ruleLinkMunicipalNpa,
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            aAgreementAccessionMunicipalBlockValues['6'].ruleLinkMunicipalNpa,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          aAgreementAccessionMunicipalBlockValues['6'].ruleLinkMunicipalNpa,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        {extraMunicipalAccessionLinksRenderCondition && (
          <>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  disableTopPadding
                  title={
                    'Пункты дополнительного соглашения о присоединении муниципального образования к СЗПК, определяющие срок стабилизационной оговорки'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: aAgreementAccessionMunicipalBlockValues['6']
                        .extraMunicipalAccessionLinkStabilization,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: aAgreementAccessionMunicipalBlockValues['6']
                          .extraMunicipalAccessionLinkStabilization,
                        rules:
                          sixthSectionAAgreementAccessionMunicipalValidationMap.extraMunicipalAccessionLinkStabilization,
                        inputProps: {
                          leftAddons: (
                            <FormIconWithTooltip tooltipContent="рекомендация: 2.2 - 2.4" />
                          ),
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                aAgreementAccessionMunicipalBlockValues['6']
                                  .extraMunicipalAccessionLinkStabilization,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              aAgreementAccessionMunicipalBlockValues['6']
                                .extraMunicipalAccessionLinkStabilization,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  disableTopPadding
                  title={
                    'Пункт дополнительного соглашения о присоединении муниципального образования к СЗПК, определяющий срок неприменения актов, изменяющих решение о предоставлении меры гос. поддержки'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: aAgreementAccessionMunicipalBlockValues['6']
                        .extraMunicipalAccessionLinkCompensation,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: aAgreementAccessionMunicipalBlockValues['6']
                          .extraMunicipalAccessionLinkCompensation,
                        rules:
                          sixthSectionAAgreementAccessionMunicipalValidationMap.extraMunicipalAccessionLinkCompensation,
                        inputProps: {
                          leftAddons: <FormIconWithTooltip tooltipContent="рекомендация: 2.1" />,
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                aAgreementAccessionMunicipalBlockValues['6']
                                  .extraMunicipalAccessionLinkCompensation,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              aAgreementAccessionMunicipalBlockValues['6']
                                .extraMunicipalAccessionLinkCompensation,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </>
        )}
        <Row className={styles.form__checkboxRow}>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementAccessionMunicipalBlockValues['6'].isMunicipalityReadyToCompensate,
              })}
            >
              <ControlledCheckbox
                {...getCheckboxProps({
                  name: aAgreementAccessionMunicipalBlockValues['6']
                    .isMunicipalityReadyToCompensate,
                  checkBoxProps: {
                    label:
                      'Муниципальное образование принимает на себя обязательства по возмещению затрат, указанных в ч. 1 ст. 15 69-ФЗ',
                  },
                  onChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        aAgreementAccessionMunicipalBlockValues['6']
                          .isMunicipalityReadyToCompensate,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              disableTopPadding
              title={'Дата согласования главой МО муниципальных актов'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: aAgreementAccessionMunicipalBlockValues['6'].dateApproveMO,
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: aAgreementAccessionMunicipalBlockValues['6'].dateApproveMO,
                    rules:
                      sixthSectionAAgreementAccessionMunicipalValidationMap.dateApproveMO,
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            aAgreementAccessionMunicipalBlockValues['6'].dateApproveMO,
                          ),
                        0,
                      ),
                    onInputChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          aAgreementAccessionMunicipalBlockValues['6'].dateApproveMO,
                        )
                      }, 0),
                    onCalendarChange: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            aAgreementAccessionMunicipalBlockValues['6'].dateApproveMO,
                          ),
                        0,
                      ),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Sixth
