import { ControllerProps, FieldValues, useController } from 'react-hook-form'

import { SingleSelectProps } from '../model'

import { SingleSelect } from './SingleSelect'

export interface ControlledSingleSelectProps<T extends FieldValues> extends SingleSelectProps {
  controllerProps: Omit<ControllerProps<T>, 'render'>
}

export const ControlledSingleSelect = <T extends FieldValues>(
  props: ControlledSingleSelectProps<T>,
) => {
  const { controllerProps, ...restProps } = props

  const {
    field: { onChange: onChangeForm, onBlur: onBlurForm, value: formValue },
    fieldState: { error },
  } = useController(controllerProps)

  return (
    <SingleSelect
      {...restProps}
      error={error}
      defaultSelected={restProps.defaultSelected || formValue}
      onChangeForm={onChangeForm}
      onBlurForm={onBlurForm}
    />
  )
}
