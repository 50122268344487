import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  memo,
  SyntheticEvent,
  useEffect,
} from 'react'

import Highlighted from '@components/Highlighted/Highlighted'
import Icon from '@components/Icon/Icon'
import Button from '@components/NewDesign/Button'
import IconButton from '@components/NewDesign/IconButton'
import ProportionalBox from '@components/ProportionalBox'
import { updateSearchThreeById } from '@components/ReferenceBooks/HierarchyType/helpers'
import { useReferenceBooksHierarchyManager } from '@components/ReferenceBooks/HierarchyType/Manager'
import { SkeletonText } from '@components/Skeleton/Text/SkeletonText'
import { ReferenceBookItem } from '@context/APIContext/hooks/useReferenceBooksApi/types'
import { useHover } from '@hooks/new/events/useHover'
import { useReferenceBookChildsByDictionaryNameAndId } from '@hooks/new/swr/useReferenceBookChildsByDictionaryNameAndId'
import { useBooleanState } from '@hooks/useBooleanState'
import editIcon from '@icons/EditIcon.svg'
import groupIcon from '@icons/list/groupList.svg'
import cn from 'classnames'
import { unstable_serialize, useSWRConfig } from 'swr'

import styles from './HierarchyTypeItem.module.scss'

interface HierarchyListItemProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  codeObj: ReferenceBookItem
  onCodeOpen: (codeObj: ReferenceBookItem, parentCodeObj?: ReferenceBookItem) => void
  onCodeAdd: (codeObj: ReferenceBookItem, parentCodeObj?: ReferenceBookItem) => void
  initialPadding?: number
  stepOfPadding?: number
  parentCodeObj?: ReferenceBookItem
}

const INITIAL_PADDING = 26
const STEP_OF_PADDING = 32

const HierarchyListItem: FC<HierarchyListItemProps> = ({
  parentCodeObj,
  codeObj,
  onCodeOpen,
  onCodeAdd,
  initialPadding = INITIAL_PADDING,
  stepOfPadding = STEP_OF_PADDING,
  ...rest
}) => {
  const { cache } = useSWRConfig()

  const { state, handlers } = useReferenceBooksHierarchyManager()
  const [hoverRef, isHovered] = useHover()

  const { booleanState: isExpanded, setBooleanState: setIsExpanded } = useBooleanState()

  const isRenderFromSearchThree = !!state.searchThree

  const currentReferenceBook = useReferenceBookChildsByDictionaryNameAndId({
    key:
      isExpanded && codeObj.hasChild
        ? {
            id: codeObj.id,
            dictionaryName: state.dictionaryName,
            _key: 'referenceBookChildsById',
          }
        : null,
  })

  const isListFromFetcher = codeObj.hasChild && !codeObj.children
  const isListFromHybrid = codeObj.hasChild

  const currentIsList = isRenderFromSearchThree ? isListFromHybrid : isListFromFetcher
  const recursionCondition = currentIsList && isExpanded

  useEffect(() => {
    if (isRenderFromSearchThree && codeObj.hasChild && codeObj.children?.length) {
      setIsExpanded(true)
      return
    }

    setIsExpanded(false)
  }, [
    codeObj.children?.length,
    codeObj.hasChild,
    isRenderFromSearchThree,
    setIsExpanded,
    state.searchThree,
  ])

  useEffect(() => {
    const needUpdateThreeInsideParent = isExpanded && codeObj.hasChild

    if (
      needUpdateThreeInsideParent &&
      cache.get(
        unstable_serialize({
          id: codeObj.id,
          dictionaryName: state.dictionaryName,
          _key: 'referenceBookChildsById',
        }),
      )
    ) {
      cache.delete(
        unstable_serialize({
          id: codeObj.id,
          dictionaryName: state.dictionaryName,
          _key: 'referenceBookChildsById',
        }),
      )

      currentReferenceBook.mutate()
    }
  }, [isExpanded, codeObj.hasChild])

  const handleExpandedClick = async () => {
    setIsExpanded((prev) => !prev)

    if (isExpanded) return

    try {
      const newReferenceBook = await currentReferenceBook.mutate()

      if (isRenderFromSearchThree && newReferenceBook && codeObj.fromSearchThree) {
        handlers.handleSetupSearchThree?.((prevThree) =>
          prevThree
            ? updateSearchThreeById(prevThree, codeObj.id, (prevStateObj) => ({
                ...prevStateObj,
                children: prevStateObj.children
                  ? ([...prevStateObj.children, ...newReferenceBook].filter(
                      Boolean,
                    ) as ReferenceBookItem[])
                  : [...newReferenceBook],
              }))
            : prevThree,
        )
      }
    } catch (e) {
      throw e
    }
  }

  const preparedHandleExpandedClick = async (e: SyntheticEvent) => {
    e.stopPropagation()

    if (!codeObj || !currentIsList) return

    await handleExpandedClick()
  }

  const handleOpenCode =
    (codeObj: ReferenceBookItem, parentCodeObj?: ReferenceBookItem) => (e: SyntheticEvent) => {
      e.stopPropagation()

      onCodeOpen(codeObj, parentCodeObj)
    }

  const handleAddCode =
    (codeObj: ReferenceBookItem, parentCodeObj?: ReferenceBookItem) => (e: SyntheticEvent) => {
      e.stopPropagation()

      onCodeAdd(codeObj, parentCodeObj)
    }

  const currentMapItems = codeObj.children || currentReferenceBook.referenceBooks

  const nextInitialPaddingLeft = initialPadding + stepOfPadding

  const trimmedSearchPattern = state.searchString?.trim() || ''

  return (
    <>
      <div
        role={'presentation'}
        ref={hoverRef}
        className={cn(
          styles.hierarchyTypeItem,
          {
            [styles['hierarchyTypeItem--hovered']]: isHovered,
          },
          rest.className,
        )}
        onClick={preparedHandleExpandedClick}
        {...rest}
      >
        <div className={styles.hierarchyTypeItem__wrapper}>
          <ProportionalBox width={5} height={6} className={styles.hierarchyTypeItem_proportional}>
            {currentIsList && (
              <Icon
                noCurrentColorSvgFill
                src={groupIcon}
                size="initial"
                wrapper="span"
                className={cn(styles.hierarchyTypeItem_icon, {
                  [styles['hierarchyTypeItem_icon--expanded']]: isExpanded,
                })}
              />
            )}
          </ProportionalBox>
          <div className={styles.hierarchyTypeItem__leftSide}>
            <span className={styles.hierarchyTypeItem_code}>
              <Highlighted highlight={trimmedSearchPattern}>{codeObj.code || ''}</Highlighted>
            </span>
            <span className={styles.hierarchyTypeItem_codeName}>
              <Highlighted highlight={trimmedSearchPattern}>{codeObj.name}</Highlighted>
            </span>
          </div>

          <span className={styles.hierarchyTypeItem__measureType}>
            <Highlighted highlight={trimmedSearchPattern}>{codeObj.measureType || ''}</Highlighted>
          </span>
        </div>

        <IconButton
          geometry={'square'}
          size={'m'}
          view={'gray'}
          className={cn(styles.editIcon, {
            [styles['editIcon--entered']]: isHovered,
          })}
          icon={{
            size: 's',
            src: editIcon,
          }}
          onClick={handleOpenCode(codeObj, parentCodeObj)}
        />

        {isHovered && (
          <Button
            variant={'buttonMMedium'}
            className={styles.hierarchyTypeItem__addButton}
            geometry={'round'}
            style={{
              left: `${initialPadding}px`,
            }}
            onClick={handleAddCode(codeObj, parentCodeObj)}
          >
            +
          </Button>
        )}
      </div>

      {recursionCondition &&
        currentMapItems?.map((currentCodeObj) => {
          return (
            <HierarchyListItem
              key={currentCodeObj.id}
              parentCodeObj={codeObj}
              codeObj={currentCodeObj}
              style={{ paddingLeft: nextInitialPaddingLeft }}
              initialPadding={nextInitialPaddingLeft}
              onCodeOpen={onCodeOpen}
              onCodeAdd={onCodeAdd}
            />
          )
        })}

      {recursionCondition && !currentMapItems && (
        <div className={styles.hierarchyTypeItem} style={{ paddingLeft: nextInitialPaddingLeft }}>
          <SkeletonText
            animate
            visible
            className={cn(styles.skeleton_code, styles.hierarchyTypeItem_code)}
          />
          <SkeletonText
            animate
            visible
            className={cn(styles.skeleton_name, styles.hierarchyTypeItem_codeName)}
          />
        </div>
      )}
    </>
  )
}

export default memo(HierarchyListItem)
