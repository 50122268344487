import { FC } from 'react'

import DocumentSetItemCreate from '@components/DocumentsSet/ItemCreate'
import DocumentSetItem from '@components/DocumentsSet/ItemExist'
import DocumentsSetWrapper from '@components/DocumentsSet/Wrapper'

interface DocumentsSetProps extends FC {
  ItemExist: typeof DocumentSetItem
  ItemCreate: typeof DocumentSetItemCreate
  Wrapper: typeof DocumentsSetWrapper
}

const DocumentsSet: DocumentsSetProps = () => null

DocumentsSet.ItemExist = DocumentSetItem
DocumentsSet.Wrapper = DocumentsSetWrapper
DocumentsSet.ItemCreate = DocumentSetItemCreate

export default DocumentsSet
