import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForSummaryReportOfMonitoringResultsForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedRegionReportInfo = (() => {
    if (
      !isArray(objectFromPropertyState.regionsReportInfo?.value) ||
      !objectFromPropertyState.regionsReportInfo?.value?.length
    )
      return []

    return objectFromPropertyState.regionsReportInfo?.value.map((regionReportInfo) => ({
      propertyId: regionReportInfo.propertyId,
      lastUpdateDt: regionReportInfo.lastUpdateDt,
      type: regionReportInfo.type,
      value: {
        regionName: {
          propertyId: regionReportInfo.value.regionName?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionName?.lastUpdateDt,
          type: regionReportInfo.value.regionName?.type,
        },
        federalSzpkCountPlan: {
          propertyId: regionReportInfo.value.federalSzpkCountPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalSzpkCountPlan?.lastUpdateDt,
          type: regionReportInfo.value.federalSzpkCountPlan?.type,
        },
        federalSzpkCountFact: {
          propertyId: regionReportInfo.value.federalSzpkCountFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalSzpkCountFact?.lastUpdateDt,
          type: regionReportInfo.value.federalSzpkCountFact?.type,
        },
        regionalSzpkCountPlan: {
          propertyId: regionReportInfo.value.regionalSzpkCountPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalSzpkCountPlan?.lastUpdateDt,
          type: regionReportInfo.value.regionalSzpkCountPlan?.type,
        },
        regionalSzpkCountFact: {
          propertyId: regionReportInfo.value.regionalSzpkCountFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalSzpkCountFact?.lastUpdateDt,
          type: regionReportInfo.value.regionalSzpkCountFact?.type,
        },
        federalSzpkPeriodCountPlan: {
          propertyId: regionReportInfo.value.federalSzpkPeriodCountPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalSzpkPeriodCountPlan?.lastUpdateDt,
          type: regionReportInfo.value.federalSzpkPeriodCountPlan?.type,
        },
        federalSzpkPeriodCountFact: {
          propertyId: regionReportInfo.value.federalSzpkPeriodCountFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalSzpkPeriodCountFact?.lastUpdateDt,
          type: regionReportInfo.value.federalSzpkPeriodCountFact?.type,
        },
        federalTotalCapitalFactChecker: {
          propertyId: regionReportInfo.value.federalTotalCapitalFactChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalTotalCapitalFactChecker?.lastUpdateDt,
          type: regionReportInfo.value.federalTotalCapitalFactChecker?.type,
        },
        federalTotalCapitalFact: {
          propertyId: regionReportInfo.value.federalTotalCapitalFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalTotalCapitalFact?.lastUpdateDt,
          type: regionReportInfo.value.federalTotalCapitalFact?.type,
        },
        federalTotalCapitalPlanChecker: {
          propertyId: regionReportInfo.value.federalTotalCapitalPlanChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalTotalCapitalPlanChecker?.lastUpdateDt,
          type: regionReportInfo.value.federalTotalCapitalPlanChecker?.type,
        },
        federalTotalCapitalPlan: {
          propertyId: regionReportInfo.value.federalTotalCapitalPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalTotalCapitalPlan?.lastUpdateDt,
          type: regionReportInfo.value.federalTotalCapitalPlan?.type,
        },
        regionalTotalCapitalFactChecker: {
          propertyId: regionReportInfo.value.regionalTotalCapitalFactChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalTotalCapitalFactChecker?.lastUpdateDt,
          type: regionReportInfo.value.regionalTotalCapitalFactChecker?.type,
        },
        regionalTotalCapitalFact: {
          propertyId: regionReportInfo.value.regionalTotalCapitalFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalTotalCapitalFact?.lastUpdateDt,
          type: regionReportInfo.value.regionalTotalCapitalFact?.type,
        },
        regionalTotalCapitalPlanChecker: {
          propertyId: regionReportInfo.value.regionalTotalCapitalPlanChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalTotalCapitalPlanChecker?.lastUpdateDt,
          type: regionReportInfo.value.regionalTotalCapitalPlanChecker?.type,
        },
        regionalTotalCapitalPlan: {
          propertyId: regionReportInfo.value.regionalTotalCapitalPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalTotalCapitalPlan?.lastUpdateDt,
          type: regionReportInfo.value.regionalTotalCapitalPlan?.type,
        },
        federalInvestorCapitalFactChecker: {
          propertyId: regionReportInfo.value.federalInvestorCapitalFactChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalInvestorCapitalFactChecker?.lastUpdateDt,
          type: regionReportInfo.value.federalInvestorCapitalFactChecker?.type,
        },
        federalInvestorCapitalFact: {
          propertyId: regionReportInfo.value.federalInvestorCapitalFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalInvestorCapitalFact?.lastUpdateDt,
          type: regionReportInfo.value.federalInvestorCapitalFact?.type,
        },
        federalInvestorCapitalPlanChecker: {
          propertyId: regionReportInfo.value.federalInvestorCapitalPlanChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalInvestorCapitalPlanChecker?.lastUpdateDt,
          type: regionReportInfo.value.federalInvestorCapitalPlanChecker?.type,
        },
        federalInvestorCapitalPlan: {
          propertyId: regionReportInfo.value.federalInvestorCapitalPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalInvestorCapitalPlan?.lastUpdateDt,
          type: regionReportInfo.value.federalInvestorCapitalPlan?.type,
        },
        regionalInvestorCapitalFactChecker: {
          propertyId: regionReportInfo.value.regionalInvestorCapitalFactChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalInvestorCapitalFactChecker?.lastUpdateDt,
          type: regionReportInfo.value.regionalInvestorCapitalFactChecker?.type,
        },
        regionalInvestorCapitalFact: {
          propertyId: regionReportInfo.value.regionalInvestorCapitalFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalInvestorCapitalFact?.lastUpdateDt,
          type: regionReportInfo.value.regionalInvestorCapitalFact?.type,
        },
        regionalInvestorCapitalPlanChecker: {
          propertyId: regionReportInfo.value.regionalInvestorCapitalPlanChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalInvestorCapitalPlanChecker?.lastUpdateDt,
          type: regionReportInfo.value.regionalInvestorCapitalPlanChecker?.type,
        },
        regionalInvestorCapitalPlan: {
          propertyId: regionReportInfo.value.regionalInvestorCapitalPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalInvestorCapitalPlan?.lastUpdateDt,
          type: regionReportInfo.value.regionalInvestorCapitalPlan?.type,
        },
        federalNewWorkPlacesFactChecker: {
          propertyId: regionReportInfo.value.federalNewWorkPlacesFactChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalNewWorkPlacesFactChecker?.lastUpdateDt,
          type: regionReportInfo.value.federalNewWorkPlacesFactChecker?.type,
        },
        federalNewWorkPlacesFact: {
          propertyId: regionReportInfo.value.federalNewWorkPlacesFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalNewWorkPlacesFact?.lastUpdateDt,
          type: regionReportInfo.value.federalNewWorkPlacesFact?.type,
        },
        federalNewWorkPlacesPlanChecker: {
          propertyId: regionReportInfo.value.federalNewWorkPlacesPlanChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalNewWorkPlacesPlanChecker?.lastUpdateDt,
          type: regionReportInfo.value.federalNewWorkPlacesPlanChecker?.type,
        },
        federalNewWorkPlacesPlan: {
          propertyId: regionReportInfo.value.federalNewWorkPlacesPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalNewWorkPlacesPlan?.lastUpdateDt,
          type: regionReportInfo.value.federalNewWorkPlacesPlan?.type,
        },
        regionalNewWorkPlacesFactChecker: {
          propertyId: regionReportInfo.value.regionalNewWorkPlacesFactChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalNewWorkPlacesFactChecker?.lastUpdateDt,
          type: regionReportInfo.value.regionalNewWorkPlacesFactChecker?.type,
        },
        regionalNewWorkPlacesFact: {
          propertyId: regionReportInfo.value.regionalNewWorkPlacesFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalNewWorkPlacesFact?.lastUpdateDt,
          type: regionReportInfo.value.regionalNewWorkPlacesFact?.type,
        },
        regionalNewWorkPlacesPlanChecker: {
          propertyId: regionReportInfo.value.regionalNewWorkPlacesPlanChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalNewWorkPlacesPlanChecker?.lastUpdateDt,
          type: regionReportInfo.value.regionalNewWorkPlacesPlanChecker?.type,
        },
        regionalNewWorkPlacesPlan: {
          propertyId: regionReportInfo.value.regionalNewWorkPlacesPlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalNewWorkPlacesPlan?.lastUpdateDt,
          type: regionReportInfo.value.regionalNewWorkPlacesPlan?.type,
        },
        federalCompensationValueFactChecker: {
          propertyId: regionReportInfo.value.federalCompensationValueFactChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalCompensationValueFactChecker?.lastUpdateDt,
          type: regionReportInfo.value.federalCompensationValueFactChecker?.type,
        },
        federalCompensationValueFact: {
          propertyId: regionReportInfo.value.federalCompensationValueFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalCompensationValueFact?.lastUpdateDt,
          type: regionReportInfo.value.federalCompensationValueFact?.type,
        },
        federalCompensationValuePlanChecker: {
          propertyId: regionReportInfo.value.federalCompensationValuePlanChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalCompensationValuePlanChecker?.lastUpdateDt,
          type: regionReportInfo.value.federalCompensationValuePlanChecker?.type,
        },
        federalCompensationValuePlan: {
          propertyId: regionReportInfo.value.federalCompensationValuePlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.federalCompensationValuePlan?.lastUpdateDt,
          type: regionReportInfo.value.federalCompensationValuePlan?.type,
        },
        regionalCompensationValueFactChecker: {
          propertyId: regionReportInfo.value.regionalCompensationValueFactChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalCompensationValueFactChecker?.lastUpdateDt,
          type: regionReportInfo.value.regionalCompensationValueFactChecker?.type,
        },
        regionalCompensationValueFact: {
          propertyId: regionReportInfo.value.regionalCompensationValueFact?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalCompensationValueFact?.lastUpdateDt,
          type: regionReportInfo.value.regionalCompensationValueFact?.type,
        },
        regionalCompensationValuePlanChecker: {
          propertyId: regionReportInfo.value.regionalCompensationValuePlanChecker?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalCompensationValuePlanChecker?.lastUpdateDt,
          type: regionReportInfo.value.regionalCompensationValuePlanChecker?.type,
        },
        regionalCompensationValuePlan: {
          propertyId: regionReportInfo.value.regionalCompensationValuePlan?.propertyId,
          lastUpdateDt: regionReportInfo.value.regionalCompensationValuePlan?.lastUpdateDt,
          type: regionReportInfo.value.regionalCompensationValuePlan?.type,
        },
      },
    }))
  })()

  const preparedRegionsFederalReport = (() => {
    if (
      !isArray(objectFromPropertyState.regionsFederalReport?.value) ||
      !objectFromPropertyState.regionsFederalReport?.value?.length
    )
      return []

    return objectFromPropertyState.regionsFederalReport.value.map((regionFederalReport) => ({
      propertyId: regionFederalReport.propertyId,
      lastUpdateDt: regionFederalReport.lastUpdateDt,
      type: regionFederalReport.type,
      value: {
        regionName: {
          propertyId: regionFederalReport.value.regionName?.propertyId,
          type: regionFederalReport.value.regionName?.type,
          lastUpdateDt: regionFederalReport.value.regionName?.lastUpdateDt,
        },
        countRegionFederalReport: {
          propertyId: regionFederalReport.value.countRegionFederalReport?.propertyId,
          type: regionFederalReport.value.countRegionFederalReport?.type,
          lastUpdateDt: regionFederalReport.value.countRegionFederalReport?.lastUpdateDt,
        },
        countRegionFederalReportCorrect: {
          propertyId: regionFederalReport.value.countRegionFederalReportCorrect?.propertyId,
          type: regionFederalReport.value.countRegionFederalReportCorrect?.type,
          lastUpdateDt: regionFederalReport.value.countRegionFederalReportCorrect?.lastUpdateDt,
        },
        totalCapitalRegionFederalReportChecker: {
          propertyId: regionFederalReport.value.totalCapitalRegionFederalReportChecker?.propertyId,
          type: regionFederalReport.value.totalCapitalRegionFederalReportChecker?.type,
          lastUpdateDt:
            regionFederalReport.value.totalCapitalRegionFederalReportChecker?.lastUpdateDt,
        },
        totalCapitalRegionFederalReportRegion: {
          propertyId: regionFederalReport.value.totalCapitalRegionFederalReportRegion?.propertyId,
          type: regionFederalReport.value.totalCapitalRegionFederalReportRegion?.type,
          lastUpdateDt:
            regionFederalReport.value.totalCapitalRegionFederalReportRegion?.lastUpdateDt,
        },
        totalCapitalRegionFederalReportCorrect: {
          propertyId: regionFederalReport.value.totalCapitalRegionFederalReportCorrect?.propertyId,
          type: regionFederalReport.value.totalCapitalRegionFederalReportCorrect?.type,
          lastUpdateDt:
            regionFederalReport.value.totalCapitalRegionFederalReportCorrect?.lastUpdateDt,
        },
        investorCapitalRegionFederalReportChecker: {
          propertyId:
            regionFederalReport.value.investorCapitalRegionFederalReportChecker?.propertyId,
          type: regionFederalReport.value.investorCapitalRegionFederalReportChecker?.type,
          lastUpdateDt:
            regionFederalReport.value.investorCapitalRegionFederalReportChecker?.lastUpdateDt,
        },
        investorCapitalRegionFederalReportRegion: {
          propertyId:
            regionFederalReport.value.investorCapitalRegionFederalReportRegion?.propertyId,
          type: regionFederalReport.value.investorCapitalRegionFederalReportRegion?.type,
          lastUpdateDt:
            regionFederalReport.value.investorCapitalRegionFederalReportRegion?.lastUpdateDt,
        },
        investorCapitalRegionFederalReportCorrect: {
          propertyId:
            regionFederalReport.value.investorCapitalRegionFederalReportCorrect?.propertyId,
          type: regionFederalReport.value.investorCapitalRegionFederalReportCorrect?.type,
          lastUpdateDt:
            regionFederalReport.value.investorCapitalRegionFederalReportCorrect?.lastUpdateDt,
        },
        newWorkPlacesRegionFederalReportChecker: {
          propertyId: regionFederalReport.value.newWorkPlacesRegionFederalReportChecker?.propertyId,
          type: regionFederalReport.value.newWorkPlacesRegionFederalReportChecker?.type,
          lastUpdateDt:
            regionFederalReport.value.newWorkPlacesRegionFederalReportChecker?.lastUpdateDt,
        },
        newWorkPlacesRegionFederalReportRegion: {
          propertyId: regionFederalReport.value.newWorkPlacesRegionFederalReportRegion?.propertyId,
          type: regionFederalReport.value.newWorkPlacesRegionFederalReportRegion?.type,
          lastUpdateDt:
            regionFederalReport.value.newWorkPlacesRegionFederalReportRegion?.lastUpdateDt,
        },
        newWorkPlacesRegionFederalReportCorrect: {
          propertyId: regionFederalReport.value.newWorkPlacesRegionFederalReportCorrect?.propertyId,
          type: regionFederalReport.value.newWorkPlacesRegionFederalReportCorrect?.type,
          lastUpdateDt:
            regionFederalReport.value.newWorkPlacesRegionFederalReportCorrect?.lastUpdateDt,
        },
      },
    }))
  })()

  const preparedSzpksChangeTerminateFederalReport = (() => {
    if (
      !isArray(objectFromPropertyState.szpksChangeTerminateFederalReport?.value) ||
      !objectFromPropertyState.szpksChangeTerminateFederalReport?.value?.length
    )
      return []

    return objectFromPropertyState.szpksChangeTerminateFederalReport.value.map(
      (szpkChangeTerminateFederalReport) => ({
        propertyId: szpkChangeTerminateFederalReport.propertyId,
        lastUpdateDt: szpkChangeTerminateFederalReport.lastUpdateDt,
        type: szpkChangeTerminateFederalReport.type,
        value: {
          projectName: {
            propertyId: szpkChangeTerminateFederalReport.value.projectName?.propertyId,
            type: szpkChangeTerminateFederalReport.value.projectName?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.projectName?.lastUpdateDt,
          },
          isChange: {
            propertyId: szpkChangeTerminateFederalReport.value.isChanges?.propertyId,
            type: szpkChangeTerminateFederalReport.value.isChanges?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.isChanges?.lastUpdateDt,
          },
          isTerminate: {
            propertyId: szpkChangeTerminateFederalReport.value.isTerminate?.propertyId,
            type: szpkChangeTerminateFederalReport.value.isTerminate?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.isTerminate?.lastUpdateDt,
          },
          registeredDate: {
            propertyId: szpkChangeTerminateFederalReport.value.szpkReportId?.propertyId,
            type: szpkChangeTerminateFederalReport.value.registeredDate?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.registeredDate?.lastUpdateDt,
          },
          registeredNumber: {
            propertyId: szpkChangeTerminateFederalReport.value.szpkReportId?.propertyId,
            type: szpkChangeTerminateFederalReport.value.szpkReportId?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.szpkReportId?.lastUpdateDt,
          },
          totalCapitalFactChecker: {
            propertyId: szpkChangeTerminateFederalReport.value.szpkReportId?.propertyId,
            type: szpkChangeTerminateFederalReport.value.szpkReportId?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.szpkReportId?.lastUpdateDt,
          },
          totalCapitalFact: {
            propertyId: szpkChangeTerminateFederalReport.value.szpkReportId?.propertyId,
            type: szpkChangeTerminateFederalReport.value.szpkReportId?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.szpkReportId?.lastUpdateDt,
          },
          totalCapitalCorrect: {
            propertyId: szpkChangeTerminateFederalReport.value.totalCapitalCorrect?.propertyId,
            type: szpkChangeTerminateFederalReport.value.totalCapitalCorrect?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.totalCapitalCorrect?.lastUpdateDt,
          },
          investorCapitalFactChecker: {
            propertyId: szpkChangeTerminateFederalReport.value.szpkReportId?.propertyId,
            type: szpkChangeTerminateFederalReport.value.szpkReportId?.type,
            lastUpdateDt:
              szpkChangeTerminateFederalReport.value.szpkReportId?.lastUpdateDt,
          },
          investorCapitalFact: {
            propertyId: szpkChangeTerminateFederalReport.value.szpkReportId?.propertyId,
            type: szpkChangeTerminateFederalReport.value.szpkReportId?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.szpkReportId?.lastUpdateDt,
          },
          investorCapitalCorrect: {
            propertyId: szpkChangeTerminateFederalReport.value.investorCapitalCorrect?.propertyId,
            type: szpkChangeTerminateFederalReport.value.investorCapitalCorrect?.type,
            lastUpdateDt:
              szpkChangeTerminateFederalReport.value.investorCapitalCorrect?.lastUpdateDt,
          },
          reasonChecker: {
            propertyId: szpkChangeTerminateFederalReport.value.szpkReportId?.propertyId,
            type: szpkChangeTerminateFederalReport.value.szpkReportId?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.szpkReportId?.lastUpdateDt,
          },
          reasonRegion: {
            propertyId: szpkChangeTerminateFederalReport.value.szpkReportId?.propertyId,
            type: szpkChangeTerminateFederalReport.value.szpkReportId?.type,
            lastUpdateDt: szpkChangeTerminateFederalReport.value.szpkReportId?.lastUpdateDt,
          },
          changeTerminateReason: {
            propertyId: szpkChangeTerminateFederalReport.value.changeTerminateReason?.propertyId,
            type: szpkChangeTerminateFederalReport.value.changeTerminateReason?.type,
            lastUpdateDt:
              szpkChangeTerminateFederalReport.value.changeTerminateReason?.lastUpdateDt,
          },
        },
      }),
    )
  })()

  const preparedEconomicSpheresFederalReport = (() => {
    if (
      !isArray(objectFromPropertyState.economicSpheresFederalReport?.value) ||
      !objectFromPropertyState.economicSpheresFederalReport?.value?.length
    )
      return []

    return objectFromPropertyState.economicSpheresFederalReport?.value.map(
      (economicSphereFederalReport) => {
        const preparedEconomicSphereOkvedsFederalReport = (() => {
          if (
            !isArray(economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.value) ||
            !economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.value?.length
          )
            return []

          return economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.value.map(
            (economicSphereOkvedFederalReport) => ({
              propertyId: economicSphereOkvedFederalReport.propertyId,
              type: economicSphereOkvedFederalReport.type,
              lastUpdateDt: economicSphereOkvedFederalReport.lastUpdateDt,
              value: {
                economicSphereNameFederalReport: {
                  propertyId:
                    economicSphereOkvedFederalReport.value?.economicSphereNameFederalReport
                      ?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value?.economicSphereNameFederalReport
                      ?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value?.economicSphereNameFederalReport
                    ?.type,
                },
                economicSphereOkvedCodeFederalReport: {
                  propertyId:
                    economicSphereOkvedFederalReport.value?.economicSphereOkvedCodeFederalReport
                      ?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value?.economicSphereOkvedCodeFederalReport
                      ?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value?.economicSphereOkvedCodeFederalReport
                    ?.type,
                },
                countEconomicSphereOkvedFederalReport: {
                  propertyId:
                    economicSphereOkvedFederalReport.value?.countEconomicSphereOkvedFederalReport
                      ?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value?.countEconomicSphereOkvedFederalReport
                      ?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.countEconomicSphereOkvedFederalReport?.type,
                },
                countEconomicSphereOkvedFederalReportCorrect: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.countEconomicSphereOkvedFederalReportCorrect?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.countEconomicSphereOkvedFederalReportCorrect?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.countEconomicSphereOkvedFederalReportCorrect?.type,
                },
                totalCapitalEconomicSphereOkvedFederalReportChecker: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportChecker?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportChecker?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.totalCapitalEconomicSphereOkvedFederalReportChecker?.type,
                },
                totalCapitalEconomicSphereOkvedFederalReportRegion: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportRegion?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportRegion?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.totalCapitalEconomicSphereOkvedFederalReportRegion?.type,
                },
                totalCapitalEconomicSphereOkvedFederalReportCorrect: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportCorrect?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportCorrect?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.totalCapitalEconomicSphereOkvedFederalReportCorrect?.type,
                },
                investorCapitalEconomicSphereOkvedFederalReportChecker: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportChecker?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportChecker?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.investorCapitalEconomicSphereOkvedFederalReportChecker?.type,
                },
                investorCapitalEconomicSphereOkvedFederalReportRegion: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportRegion?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportRegion?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.investorCapitalEconomicSphereOkvedFederalReportRegion?.type,
                },
                investorCapitalEconomicSphereOkvedFederalReportCorrect: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportCorrect?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportCorrect?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.investorCapitalEconomicSphereOkvedFederalReportCorrect?.type,
                },
                newWorkPlacesEconomicSphereOkvedFederalReportChecker: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportChecker?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportChecker?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.newWorkPlacesEconomicSphereOkvedFederalReportChecker?.type,
                },
                newWorkPlacesEconomicSphereOkvedFederalReportRegion: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportRegion?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportRegion?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.newWorkPlacesEconomicSphereOkvedFederalReportRegion?.type,
                },
                newWorkPlacesEconomicSphereOkvedFederalReportCorrect: {
                  propertyId:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportCorrect?.propertyId,
                  lastUpdateDt:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportCorrect?.lastUpdateDt,
                  type: economicSphereOkvedFederalReport.value
                    ?.newWorkPlacesEconomicSphereOkvedFederalReportCorrect?.type,
                },
                regionsOkvedReportInfo: {
                  propertyId: objectFromPropertyState.regionsReportInfo?.propertyId,
                  lastUpdateDt: objectFromPropertyState.regionsReportInfo?.lastUpdateDt,
                  type: objectFromPropertyState.regionsReportInfo?.type,
                },
              },
            }),
          )
        })()

        return {
          propertyId: economicSphereFederalReport.propertyId,
          lastUpdateDt: economicSphereFederalReport.lastUpdateDt,
          type: economicSphereFederalReport.type,
          value: {
            economicSphereNameFederalReport: {
              propertyId:
                economicSphereFederalReport.value?.economicSphereNameFederalReport?.propertyId,
              lastUpdateDt:
                economicSphereFederalReport.value?.economicSphereNameFederalReport?.lastUpdateDt,
              type: economicSphereFederalReport.value?.economicSphereNameFederalReport?.type,
            },
            economicSphereOkvedsFederalReport: {
              propertyId:
                economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.propertyId,
              lastUpdateDt:
                economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.lastUpdateDt,
              type: economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.type,
              value: preparedEconomicSphereOkvedsFederalReport,
            },
          },
        }
      },
    )
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
      documentReceivers: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.documentReceivers,
      ),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      regionsReportInfo: {
        propertyId: objectFromPropertyState.regionsReportInfo?.propertyId,
        lastUpdateDt: objectFromPropertyState.regionsReportInfo?.lastUpdateDt,
        type: objectFromPropertyState.regionsReportInfo?.type,
        value: preparedRegionReportInfo,
      },
      szpkCountFederalReportPlan: {
        propertyId: objectFromPropertyState?.szpkCountFederalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkCountFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.szpkCountFederalReportPlan?.type,
      },
      szpkCountFederalReportFact: {
        propertyId: objectFromPropertyState?.szpkCountFederalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkCountFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.szpkCountFederalReportFact?.type,
      },
      federalSzpkCountFederalReportPlan: {
        propertyId: objectFromPropertyState?.federalSzpkCountFederalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.federalSzpkCountFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkCountFederalReportPlan?.type,
      },
      federalSzpkCountFederalReportPlanCorrect: {
        propertyId: objectFromPropertyState?.federalSzpkCountFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkCountFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkCountFederalReportPlanCorrect?.type,
      },
      federalSzpkCountFederalReportFact: {
        propertyId: objectFromPropertyState?.federalSzpkCountFederalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.federalSzpkCountFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkCountFederalReportFact?.type,
      },
      federalSzpkCountFederalReportFactCorrect: {
        propertyId: objectFromPropertyState?.federalSzpkCountFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkCountFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkCountFederalReportFactCorrect?.type,
      },
      regionalSzpkCountFederalReportPlan: {
        propertyId: objectFromPropertyState?.regionalSzpkCountFederalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.regionalSzpkCountFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkCountFederalReportPlan?.type,
      },
      regionalSzpkCountFederalReportPlanCorrect: {
        propertyId: objectFromPropertyState?.regionalSzpkCountFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkCountFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkCountFederalReportPlanCorrect?.type,
      },
      regionalSzpkCountFederalReportFact: {
        propertyId: objectFromPropertyState?.regionalSzpkCountFederalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.regionalSzpkCountFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkCountFederalReportFact?.type,
      },
      regionalSzpkCountFederalReportFactCorrect: {
        propertyId: objectFromPropertyState?.regionalSzpkCountFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkCountFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkCountFederalReportFactCorrect?.type,
      },
      szpkPeriodCountFederalReportPlan: {
        propertyId: objectFromPropertyState?.szpkPeriodCountFederalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkPeriodCountFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.szpkPeriodCountFederalReportPlan?.type,
      },
      szpkPeriodCountFederalReportFact: {
        propertyId: objectFromPropertyState?.szpkPeriodCountFederalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.szpkPeriodCountFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.szpkPeriodCountFederalReportFact?.type,
      },
      federalSzpkPeriodCountFederalReportPlan: {
        propertyId: objectFromPropertyState?.federalSzpkPeriodCountFederalReportPlan?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkPeriodCountFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkPeriodCountFederalReportPlan?.type,
      },
      federalSzpkPeriodCountFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalSzpkPeriodCountFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkPeriodCountFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkPeriodCountFederalReportPlanCorrect?.type,
      },
      federalSzpkPeriodCountFederalReportFact: {
        propertyId: objectFromPropertyState?.federalSzpkPeriodCountFederalReportFact?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkPeriodCountFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkPeriodCountFederalReportFact?.type,
      },
      federalSzpkPeriodCountFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalSzpkPeriodCountFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalSzpkPeriodCountFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalSzpkPeriodCountFederalReportFactCorrect?.type,
      },
      regionalSzpkPeriodCountFederalReportPlan: {
        propertyId: objectFromPropertyState?.regionalSzpkPeriodCountFederalReportPlan?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkPeriodCountFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkPeriodCountFederalReportPlan?.type,
      },
      regionalSzpkPeriodCountFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalSzpkPeriodCountFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkPeriodCountFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkPeriodCountFederalReportPlanCorrect?.type,
      },
      regionalSzpkPeriodCountFederalReportFact: {
        propertyId: objectFromPropertyState?.regionalSzpkPeriodCountFederalReportFact?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkPeriodCountFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkPeriodCountFederalReportFact?.type,
      },
      regionalSzpkPeriodCountFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalSzpkPeriodCountFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalSzpkPeriodCountFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalSzpkPeriodCountFederalReportFactCorrect?.type,
      },
      totalCapitalFederalReportPlan: {
        propertyId: objectFromPropertyState?.totalCapitalFederalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.totalCapitalFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.totalCapitalFederalReportPlan?.type,
      },
      totalCapitalFederalReportFact: {
        propertyId: objectFromPropertyState?.totalCapitalFederalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.totalCapitalFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.totalCapitalFederalReportFact?.type,
      },
      federalTotalCapitalFederalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalFederalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalFederalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalFederalReportPlanChecker?.type,
      },
      federalTotalCapitalFederalReportPlanRegion: {
        propertyId: objectFromPropertyState?.federalTotalCapitalFederalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalFederalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalFederalReportPlanRegion?.type,
      },
      federalTotalCapitalFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalFederalReportPlanCorrect?.type,
      },
      federalTotalCapitalFederalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalFederalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalFederalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalFederalReportFactChecker?.type,
      },
      federalTotalCapitalFederalReportFactRegion: {
        propertyId: objectFromPropertyState?.federalTotalCapitalFederalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalFederalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalFederalReportFactRegion?.type,
      },
      federalTotalCapitalFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalTotalCapitalFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalTotalCapitalFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalTotalCapitalFederalReportFactCorrect?.type,
      },
      regionalTotalCapitalFederalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalFederalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalFederalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalFederalReportPlanChecker?.type,
      },
      regionalTotalCapitalFederalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalFederalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalFederalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalFederalReportPlanRegion?.type,
      },
      regionalTotalCapitalFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalFederalReportPlanCorrect?.type,
      },
      regionalTotalCapitalFederalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalFederalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalFederalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalFederalReportFactChecker?.type,
      },
      regionalTotalCapitalFederalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalFederalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalFederalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalFederalReportFactRegion?.type,
      },
      regionalTotalCapitalFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalTotalCapitalFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalTotalCapitalFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalTotalCapitalFederalReportFactCorrect?.type,
      },
      investorCapitalFederalReportPlan: {
        propertyId: objectFromPropertyState?.investorCapitalFederalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.investorCapitalFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.investorCapitalFederalReportPlan?.type,
      },
      investorCapitalFederalReportFact: {
        propertyId: objectFromPropertyState?.investorCapitalFederalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.investorCapitalFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.investorCapitalFederalReportFact?.type,
      },
      federalInvestorCapitalFederalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalFederalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalFederalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalFederalReportPlanChecker?.type,
      },
      federalInvestorCapitalFederalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalFederalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalFederalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalFederalReportPlanRegion?.type,
      },
      federalInvestorCapitalFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalFederalReportPlanCorrect?.type,
      },
      federalInvestorCapitalFederalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalFederalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalFederalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalFederalReportFactChecker?.type,
      },
      federalInvestorCapitalFederalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalFederalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalFederalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalFederalReportFactRegion?.type,
      },
      federalInvestorCapitalFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalInvestorCapitalFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalInvestorCapitalFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalInvestorCapitalFederalReportFactCorrect?.type,
      },
      regionalInvestorCapitalFederalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanChecker?.type,
      },
      regionalInvestorCapitalFederalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanRegion?.type,
      },
      regionalInvestorCapitalFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanCorrect?.type,
      },
      regionalInvestorCapitalFederalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalFederalReportFactChecker?.type,
      },
      regionalInvestorCapitalFederalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalFederalReportFactRegion?.type,
      },
      regionalInvestorCapitalFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalInvestorCapitalFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalInvestorCapitalFederalReportFactCorrect?.type,
      },
      newWorkPlacesFederalReportPlan: {
        propertyId: objectFromPropertyState?.newWorkPlacesFederalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.newWorkPlacesFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.newWorkPlacesFederalReportPlan?.type,
      },
      newWorkPlacesFederalReportFact: {
        propertyId: objectFromPropertyState?.newWorkPlacesFederalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.newWorkPlacesFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.newWorkPlacesFederalReportFact?.type,
      },
      federalNewWorkPlacesFederalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanChecker?.type,
      },
      federalNewWorkPlacesFederalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanRegion?.type,
      },
      federalNewWorkPlacesFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanCorrect?.type,
      },
      federalNewWorkPlacesFederalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesFederalReportFactChecker?.type,
      },
      federalNewWorkPlacesFederalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesFederalReportFactRegion?.type,
      },
      federalNewWorkPlacesFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalNewWorkPlacesFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalNewWorkPlacesFederalReportFactCorrect?.type,
      },

      regionalNewWorkPlacesFederalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanRegion?.type,
      },
      regionalNewWorkPlacesFederalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanChecker?.type,
      },
      regionalNewWorkPlacesFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanCorrect?.type,
      },
      regionalNewWorkPlacesFederalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactChecker?.type,
      },
      regionalNewWorkPlacesFederalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactRegion?.type,
      },
      regionalNewWorkPlacesFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactCorrect?.type,
      },
      compensationValueFederalReportPlan: {
        propertyId: objectFromPropertyState?.compensationValueFederalReportPlan?.propertyId,
        lastUpdateDt: objectFromPropertyState?.compensationValueFederalReportPlan?.lastUpdateDt,
        type: objectFromPropertyState?.compensationValueFederalReportPlan?.type,
      },
      compensationValueFederalReportFact: {
        propertyId: objectFromPropertyState?.compensationValueFederalReportFact?.propertyId,
        lastUpdateDt: objectFromPropertyState?.compensationValueFederalReportFact?.lastUpdateDt,
        type: objectFromPropertyState?.compensationValueFederalReportFact?.type,
      },
      federalCompensationValueFederalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueFederalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueFederalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueFederalReportPlanChecker?.type,
      },
      federalCompensationValueFederalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueFederalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueFederalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueFederalReportPlanRegion?.type,
      },
      federalCompensationValueFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueFederalReportPlanCorrect?.type,
      },
      federalCompensationValueFederalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueFederalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueFederalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueFederalReportFactChecker?.type,
      },
      federalCompensationValueFederalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueFederalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueFederalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueFederalReportFactRegion?.type,
      },
      federalCompensationValueFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.federalCompensationValueFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.federalCompensationValueFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.federalCompensationValueFederalReportFactCorrect?.type,
      },
      regionalCompensationValueFederalReportPlanChecker: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueFederalReportPlanChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueFederalReportPlanChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueFederalReportPlanChecker?.type,
      },
      regionalCompensationValueFederalReportPlanRegion: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueFederalReportPlanRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueFederalReportPlanRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueFederalReportPlanRegion?.type,
      },
      regionalCompensationValueFederalReportPlanCorrect: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueFederalReportPlanCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueFederalReportPlanCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueFederalReportPlanCorrect?.type,
      },
      regionalCompensationValueFederalReportFactChecker: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueFederalReportFactChecker?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueFederalReportFactChecker?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueFederalReportFactChecker?.type,
      },
      regionalCompensationValueFederalReportFactRegion: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueFederalReportFactRegion?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueFederalReportFactRegion?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueFederalReportFactRegion?.type,
      },
      regionalCompensationValueFederalReportFactCorrect: {
        propertyId:
          objectFromPropertyState?.regionalCompensationValueFederalReportFactCorrect?.propertyId,
        lastUpdateDt:
          objectFromPropertyState?.regionalCompensationValueFederalReportFactCorrect?.lastUpdateDt,
        type: objectFromPropertyState?.regionalCompensationValueFederalReportFactCorrect?.type,
      },
    },
    '4': {
      economicSpheresFederalReport: {
        propertyId: objectFromPropertyState.economicSpheresFederalReport?.propertyId,
        type: objectFromPropertyState.economicSpheresFederalReport?.type,
        lastUpdateDt: objectFromPropertyState.economicSpheresFederalReport?.lastUpdateDt,
        value: preparedEconomicSpheresFederalReport,
      },
    },
    '5': {
      regionsFederalReport: {
        propertyId: objectFromPropertyState.regionsFederalReport?.propertyId,
        type: objectFromPropertyState.regionsFederalReport?.type,
        lastUpdateDt: objectFromPropertyState.regionsFederalReport?.lastUpdateDt,
        value: preparedRegionsFederalReport,
      },
    },
    '6': {
      szpksChangeTerminateFederalReport: {
        propertyId: objectFromPropertyState.szpksChangeTerminateFederalReport?.propertyId,
        type: objectFromPropertyState.szpksChangeTerminateFederalReport?.type,
        lastUpdateDt: objectFromPropertyState.szpksChangeTerminateFederalReport?.lastUpdateDt,
        value: preparedSzpksChangeTerminateFederalReport,
      },
      federalChangeTerminationSzpkCountFederalReport: {
        propertyId:
          objectFromPropertyState.federalChangeTerminationSzpkCountFederalReport?.propertyId,
        type: objectFromPropertyState.federalChangeTerminationSzpkCountFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeTerminationSzpkCountFederalReport?.lastUpdateDt,
      },
      federalChangeTerminationSzpkTotalCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.federalChangeTerminationSzpkTotalCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.federalChangeTerminationSzpkTotalCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeTerminationSzpkTotalCapitalFederalReport
            ?.lastUpdateDt,
      },
      federalChangeTerminationSzpkInvestorCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.federalChangeTerminationSzpkInvestorCapitalFederalReport
            ?.propertyId,
        type: objectFromPropertyState.federalChangeTerminationSzpkInvestorCapitalFederalReport
          ?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeTerminationSzpkInvestorCapitalFederalReport
            ?.lastUpdateDt,
      },
      federalChangeSzpkCountFederalReport: {
        propertyId: objectFromPropertyState.federalChangeSzpkCountFederalReport?.propertyId,
        type: objectFromPropertyState.federalChangeSzpkCountFederalReport?.type,
        lastUpdateDt: objectFromPropertyState.federalChangeSzpkCountFederalReport?.lastUpdateDt,
      },
      federalChangeSzpkTotalCapitalFederalReport: {
        propertyId: objectFromPropertyState.federalChangeSzpkTotalCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.federalChangeSzpkTotalCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeSzpkTotalCapitalFederalReport?.lastUpdateDt,
      },
      federalChangeSzpkInvestorCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.federalChangeSzpkInvestorCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.federalChangeSzpkInvestorCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalChangeSzpkInvestorCapitalFederalReport?.lastUpdateDt,
      },
      federalTerminationSzpkCountFederalReport: {
        propertyId: objectFromPropertyState.federalTerminationSzpkCountFederalReport?.propertyId,
        type: objectFromPropertyState.federalTerminationSzpkCountFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalTerminationSzpkCountFederalReport?.lastUpdateDt,
      },
      federalTerminationSzpkTotalCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.federalTerminationSzpkTotalCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.federalTerminationSzpkTotalCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalTerminationSzpkTotalCapitalFederalReport?.lastUpdateDt,
      },
      federalTerminationSzpkInvestorCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.federalTerminationSzpkInvestorCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.federalTerminationSzpkInvestorCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.federalTerminationSzpkInvestorCapitalFederalReport?.lastUpdateDt,
      },
      regionalChangeTerminationSzpkCountFederalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeTerminationSzpkCountFederalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeTerminationSzpkCountFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeTerminationSzpkCountFederalReport?.lastUpdateDt,
      },
      regionalChangeTerminationSzpkTotalCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeTerminationSzpkTotalCapitalFederalReport
            ?.propertyId,
        type: objectFromPropertyState.regionalChangeTerminationSzpkTotalCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeTerminationSzpkTotalCapitalFederalReport
            ?.lastUpdateDt,
      },
      regionalChangeTerminationSzpkInvestorCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeTerminationSzpkInvestorCapitalFederalReport
            ?.propertyId,
        type: objectFromPropertyState.regionalChangeTerminationSzpkInvestorCapitalFederalReport
          ?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeTerminationSzpkInvestorCapitalFederalReport
            ?.lastUpdateDt,
      },
      regionalChangeSzpkCountFederalReport: {
        propertyId: objectFromPropertyState.regionalChangeSzpkCountFederalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeSzpkCountFederalReport?.type,
        lastUpdateDt: objectFromPropertyState.regionalChangeSzpkCountFederalReport?.lastUpdateDt,
      },
      regionalChangeSzpkTotalCapitalFederalReport: {
        propertyId: objectFromPropertyState.regionalChangeSzpkTotalCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeSzpkTotalCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeSzpkTotalCapitalFederalReport?.lastUpdateDt,
      },
      regionalChangeSzpkInvestorCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.regionalChangeSzpkInvestorCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.regionalChangeSzpkInvestorCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalChangeSzpkInvestorCapitalFederalReport?.lastUpdateDt,
      },
      regionalTerminationSzpkCountFederalReport: {
        propertyId: objectFromPropertyState.regionalTerminationSzpkCountFederalReport?.propertyId,
        type: objectFromPropertyState.regionalTerminationSzpkCountFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalTerminationSzpkCountFederalReport?.lastUpdateDt,
      },
      regionalTerminationSzpkTotalCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.regionalTerminationSzpkTotalCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.regionalTerminationSzpkTotalCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalTerminationSzpkTotalCapitalFederalReport?.lastUpdateDt,
      },
      regionalTerminationSzpkInvestorCapitalFederalReport: {
        propertyId:
          objectFromPropertyState.regionalTerminationSzpkInvestorCapitalFederalReport?.propertyId,
        type: objectFromPropertyState.regionalTerminationSzpkInvestorCapitalFederalReport?.type,
        lastUpdateDt:
          objectFromPropertyState.regionalTerminationSzpkInvestorCapitalFederalReport?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForSummaryReportOfMonitoringResultsForm }
