import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import rowWithRemoveButtonStyles from '@components/DocumentFormComponents/FieldView/RowWithRemoveButton/RowWithRemoveButton.module.scss'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import styles from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/Forms/Forms.module.scss'
import { compensationObjectExpenseValidationMap } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/ExpenseSubTypes/CompensationObjects/validation'
import {
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName,
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsExpensesPathName,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Forms/4/types'
import { useParametersOfCostRecoveryApplicationManager } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/Manager'
import { ParametersOfCostRecoveryApplicationFormValues } from '@components/Forms/ParametersOfCostRecoveryApplicationForm/types'
import {
  ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
} from '@components/Forms/ParametersOfCostRecoveryApplicationForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { parseDateString } from '@components/NewDesign/DateInput/DateInput'
import { YEAR_MASK } from '@components/NewDesign/DateInput/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isString } from '@helpers/checkTypes'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import DeleteIcon from '@icons/DeleteIcon.svg'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

const { getYearOfDateFromPropertyDateValue, isFormFieldError } = DocumentFormHelpers

interface ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFieldsProps {
  name: ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsArrayPathName
  formInstance: UseFormReturn<ParametersOfCostRecoveryApplicationFormValues>
}

const ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFields: FC<
  ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFieldsProps
> = ({ name, formInstance }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleRemoveItemFromList,
      handleAddItemToListWithOutValue,
    },
    preparedProps: { subscribableControl },
  } = useParametersOfCostRecoveryApplicationManager()

  const { getSubscribableControlProps, getCalendarInputProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ParametersOfCostRecoveryApplicationFieldsControlUpdateWatcher,
    })

  useFieldArraySubscribableControl<
    ParametersOfCostRecoveryApplicationFormValues,
    ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectsExpensesPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${name}.compensationObjectExpenses`,
    keyName: 'keyNameId',
    watcher: ParametersOfCostRecoveryApplicationFieldArrayControlUpdateWatcher,
  })

  const expenseValues = useWatch({
    name: `${name}.compensationObjectExpenses`,
    control: formInstance.control,
  })

  const projectDecisionDate = useWatch({
    name: `${name}.projectDecisionDate`,
    control: formInstance.control,
  })

  const expenseValuesError = formInstance.getFieldState(`${name}.compensationObjectExpenses`)?.error

  const handleAddExpenseValue = async () => {
    await handleAddItemToListWithOutValue?.(`${name}.compensationObjectExpenses`)
  }

  const handleRemoveExpenseValue = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.compensationObjectExpenses.${index}`,
      `${name}.compensationObjectExpenses`,
    )
  }

  const expenseValuesRenderCondition = !!expenseValues.length

  return (
    <Stack direction={'vertical'} gap={3}>
      <FieldView.RowWithBottomBorder
        disableBottomDefaultStyles
        firstColumnSize={8}
        secondColumnSize={4}
        title={'Общая сумма затрат'}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${name}.compensationObjectExpensesTotalValue`,
          })}
        >
          <ControlledAmountInput
            {...getAmountInputProps({
              name: `${name}.compensationObjectExpensesTotalValue`,
              inputProps: {
                disabled: true,
                leftAddons: (
                  <FormIconWithTooltip
                    tooltipContent={
                      'Общая сумма будет посчитана автоматически на основе внесенных по годам затрат'
                    }
                  />
                ),
              },
            })}
          />
        </SubscribableControl>
      </FieldView.RowWithBottomBorder>
      <Group disableBottomBorder titleClassName={styles.expenses__title} title={'Затраты по годам'}>
        <Stack direction={'vertical'} gap={3} className={styles.expenses__stack}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.compensationObjectExpenses`,
            })}
          >
            <FlipperList list={expenseValues}>
              {!expenseValuesRenderCondition &&
                (isFormFieldError(expenseValuesError) ? (
                  <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
                ) : (
                  <Row className={'px-3'}>
                    <Col xs={12} className={'px-1'}>
                      <Typography.Caption variant={'captionSRegular'}>
                        {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
                      </Typography.Caption>
                    </Col>
                  </Row>
                ))}
              {expenseValues.map((expenseValue, index) => {
                const expenseName = `${name}.compensationObjectExpenses.${index}` as const

                return (
                  <SubscribableControl
                    key={expenseValue.id}
                    {...getSubscribableControlProps({
                      path: expenseName,
                    })}
                  >
                    <FlippedWithCollapse flipId={expenseValue.id}>
                      <Row>
                        <Col xs={3}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${expenseName}.compensationObjectExpenseYear`,
                            })}
                          >
                            <ControlledCalendarInput
                              {...getCalendarInputProps({
                                name: `${expenseName}.compensationObjectExpenseYear`,
                                rules: {
                                  ...compensationObjectExpenseValidationMap.compensationObjectExpenseYear,
                                  validate: {
                                    positiveCurrentDate: (value) => {
                                      if (!isString(value)) return

                                      const currentYear =
                                        DayjsService.dayjsWithFormatToMSK().format(
                                          objOfDateFormats.yearFormat.yearOnly,
                                        )

                                      return (
                                        (isDateValidForDayjs(value) &&
                                          DayjsService.dayjs(
                                            parseDateString(
                                              value,
                                              objOfDateFormats.yearFormat.yearOnly,
                                            ),
                                            objOfDateFormats.defaultFormat,
                                          ) <=
                                            DayjsService.dayjs(
                                              parseDateString(
                                                currentYear,
                                                objOfDateFormats.yearFormat.yearOnly,
                                              ),
                                              objOfDateFormats.defaultFormat,
                                            )) ||
                                        'значение должно быть меньше или равно текущему году'
                                      )
                                    },

                                    positiveStepEndDate: (value) => {
                                      if (!isString(value) || !isString(projectDecisionDate)) return

                                      return (
                                        (isDateValidForDayjs(value) &&
                                          DayjsService.dayjs(
                                            parseDateString(
                                              value,
                                              objOfDateFormats.yearFormat.yearOnly,
                                            ),
                                            objOfDateFormats.defaultFormat,
                                          ) >=
                                            DayjsService.dayjs(
                                              parseDateString(
                                                getYearOfDateFromPropertyDateValue(
                                                  projectDecisionDate,
                                                ),
                                                objOfDateFormats.yearFormat.yearOnly,
                                              ),
                                              objOfDateFormats.defaultFormat,
                                            )) ||
                                        'значение должно быть больше или равно году, когда было принято решение об утверждении бюджета на капитальные вложения'
                                      )
                                    },
                                  },
                                },
                                calendarProps: {
                                  typeOfPicker: 'years',
                                  dateFormat: 'YYYY',
                                },
                                calendarInputProps: {
                                  label: 'Год',
                                  inputMask: YEAR_MASK,
                                },
                                onBlur: () =>
                                  setTimeout(() => {
                                    handleChangeValue?.(
                                      `${expenseName}.compensationObjectExpenseYear`,
                                    )
                                  }, 0),
                                onCalendarChange: () =>
                                  setTimeout(() => {
                                    handleChangeValue?.(
                                      `${expenseName}.compensationObjectExpenseYear`,
                                    )
                                  }, 0),
                                onInputChange: () =>
                                  setTimeout(() => {
                                    debouncedHandleChangeValue?.(
                                      `${expenseName}.compensationObjectExpenseYear`,
                                    )
                                  }, 0),
                              })}
                            />
                          </SubscribableControl>
                        </Col>
                        <Col xs={6}>
                          <SubscribableControl
                            {...getSubscribableControlProps({
                              path: `${expenseName}.compensationObjectExpenseValue`,
                            })}
                          >
                            <ControlledAmountInput
                              {...getAmountInputProps({
                                name: `${expenseName}.compensationObjectExpenseValue`,
                                rules:
                                  compensationObjectExpenseValidationMap.compensationObjectExpenseValue,
                                inputProps: {
                                  label: 'Затраты за год',
                                },
                                onBlur: () =>
                                  setTimeout(() => {
                                    handleChangeValue?.(
                                      `${expenseName}.compensationObjectExpenseValue`,
                                    )
                                  }, 0),
                                onChange: () =>
                                  setTimeout(() => {
                                    debouncedHandleChangeValue?.(
                                      `${expenseName}.compensationObjectExpenseValue`,
                                    )
                                  }, 0),
                              })}
                            />
                          </SubscribableControl>
                        </Col>
                        {editMode && (
                          <AsyncWrapper promise={handleRemoveExpenseValue(index)}>
                            {({ isLoading, wrappedPromise }) => (
                              <Col
                                xs={3}
                                className={
                                  rowWithRemoveButtonStyles[
                                    'rowWithRemoveButton__removeButton-wrapper'
                                  ]
                                }
                              >
                                <Button
                                  fixWidth
                                  disabled={isLoading}
                                  variant={'buttonSMedium'}
                                  size={'2xs'}
                                  color={'negative'}
                                  dataTestId={'deleteButton'}
                                  view={'plain'}
                                  loaderProps={{
                                    loading: isLoading,
                                    placement: 'trailing',
                                    variant: 'lite',
                                  }}
                                  leadingIcon={{
                                    noCurrentColorSvgFill: true,
                                    src: DeleteIcon,
                                  }}
                                  onClick={wrappedPromise}
                                >
                                  Удалить
                                </Button>
                              </Col>
                            )}
                          </AsyncWrapper>
                        )}
                      </Row>
                    </FlippedWithCollapse>
                  </SubscribableControl>
                )
              })}
            </FlipperList>
          </SubscribableControl>
          {editMode && (
            <Row className={'px-1'}>
              <Col xs={12}>
                <AsyncWrapper promise={handleAddExpenseValue}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <Button
                        disabled={isLoading}
                        leadingIcon={{ src: CircleAddIcon }}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        view={'plain'}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Добавить затраты за год
                      </Button>
                    )
                  }}
                </AsyncWrapper>
              </Col>
            </Row>
          )}
        </Stack>
      </Group>
    </Stack>
  )
}

export default ParametersOfCostRecoveryApplicationExpenseSubTypesCompensationObjectGeneralFields
