import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import ContainerWithBorder from '@components/DocumentFormComponents/FieldView/ContainerWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { certificateOnMonitoringResultsBlockValues } from '@components/Forms/CertificateOnMonitoringResultsForm/const'
import CertificateOnMonitoringResultsStateSupportChecker from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/11/StateSupportChecker'
import { CertificateOnMonitoringResultsStateSupportsCheckerPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/11/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import styles from './Eleventh.module.scss'

const Eleventh = () => {
  const formInstance = useFormContext<CertificateOnMonitoringResultsFormValues>()

  const {
    state: { editMode },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { fields: stateSupportsChecker } = useFieldArraySubscribableControl<
    CertificateOnMonitoringResultsFormValues,
    CertificateOnMonitoringResultsStateSupportsCheckerPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: certificateOnMonitoringResultsBlockValues['11'].stateSupportsChecker,
    keyName: 'keyNameId',
    watcher: CertificateOnMonitoringResultsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  const handleAddStateSupportChecker = async (e) => {
    e.preventDefault()

    await handleAddItemToListWithOutValue?.(
      certificateOnMonitoringResultsBlockValues['11'].stateSupportsChecker,
    )
  }

  const handleRemoveStateSupportChecker = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${certificateOnMonitoringResultsBlockValues['11'].stateSupportsChecker}.${index}`,
      certificateOnMonitoringResultsBlockValues['11'].stateSupportsChecker,
    )
  }

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <ContainerWithBorder disablePaddingTop>
          <Stack direction={'vertical'} gap={3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['11'].stateSupportsChecker,
              })}
            >
              <FlipperList list={stateSupportsChecker}>
                {stateSupportsChecker.map((stateSupport, index) => {
                  const formName =
                    `${certificateOnMonitoringResultsBlockValues['11'].stateSupportsChecker}.${index}` as const

                  return (
                    <CertificateOnMonitoringResultsStateSupportChecker
                      key={stateSupport.id}
                      id={stateSupport.id}
                      formInstance={formInstance}
                      title={`Мера поддержки №${index + 1}`}
                      name={formName}
                      onRemove={handleRemoveStateSupportChecker(index)}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
            {editMode && (
              <Row>
                <AsyncWrapper promise={handleAddStateSupportChecker}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <FieldView.ActionButton
                        disabled={isLoading}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Добавить
                      </FieldView.ActionButton>
                    )
                  }}
                </AsyncWrapper>
              </Row>
            )}
          </Stack>
        </ContainerWithBorder>
        <Container className={'px-3'}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            titleWrapperClassName={styles.form__row}
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Совокупный объем предоставленных мер гос. поддержки'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['11'].generalStateSupportValue,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: certificateOnMonitoringResultsBlockValues['11'].generalStateSupportValue,
                  inputProps: {
                    disabled: true,
                    caption: 'рассчитывается на основе данных, введенных инвестором',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            titleWrapperClassName={styles.form__row}
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Совокупный объем предоставленных мер гос. поддержки'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: certificateOnMonitoringResultsBlockValues['11']
                  .generalStateSupportValueChecker,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: certificateOnMonitoringResultsBlockValues['11']
                    .generalStateSupportValueChecker,
                  inputProps: {
                    disabled: true,
                    caption:
                      'рассчитывается на основе данных, введенных уполномоченной организацией',
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
        </Container>
      </Stack>
    </Container>
  )
}

export default Eleventh
