import { FC } from 'react'

import ModalAction from '@components/NewDesign/Modal/Action'
import ModalBase from '@components/NewDesign/Modal/Base'
import SimpleModal from '@components/NewDesign/Modal/Simple'
import StatusModal from '@components/NewDesign/Modal/Status'

interface ModalProps extends FC {
  Base: typeof ModalBase
  Simple: typeof SimpleModal
  Action: typeof ModalAction
  Status: typeof StatusModal
}

const Modal: ModalProps = () => null

Modal.Base = ModalBase
Modal.Action = ModalAction
Modal.Simple = SimpleModal
Modal.Status = StatusModal

export default Modal
