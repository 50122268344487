import React from 'react'
import { useFormContext } from 'react-hook-form'

import InvestorSection from '@components/DocumentFormComponents/FormSections/InvestorSection'

import { useNotificationOfIdentifiedNPAListErrorsManager } from '../../Manager'
import { NotificationOfIdentifiedNPAListErrorsFormValues } from '../../types'
import { NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher } from '../../watcher'

const Third = () => {
  const formInstance = useFormContext<NotificationOfIdentifiedNPAListErrorsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedNPAListErrorsManager()

  return (
    <InvestorSection
      fullNameInstrumentalRenderCondition
      nameOrganizationChangeTooltipRenderCondition={false}
      authorizedPersonGroupRenderCondition={false}
      formName={'3'}
      investorFullNameSuptitle={'Полное наименование'}
      editMode={editMode}
      blockViewIsValidating={blockViewIsValidating}
      formInstance={formInstance}
      watcher={NotificationOfIdentifiedNPAListErrorsFieldsControlUpdateWatcher}
      subscribableControl={subscribableControl}
      debouncedChangeValue={debouncedHandleChangeValue}
      onChangeValue={handleChangeValue}
    />
  )
}

export default Third
