const documentsWebFormStatesMap = {
  NEW: 'NEW',
  MOVING: 'MOVING',
  EDITABLE: 'EDITABLE',
  COMPLETED: 'COMPLETED',
  CONVERTING: 'CONVERTING',
  CONVERTED: 'CONVERTED',
  NON_DIGITIZABLE: 'NON_DIGITIZABLE',
  // Нет этого типа на бэке, подмена, если тип === undefined
  NOT_FOUND: 'NOT_FOUND',
} as const

export { documentsWebFormStatesMap }
