import { useMemo } from 'react'

import { LetterHeadPartsUrls } from '@constants/urls'
import { useAPIContext } from '@context/APIContext'
import useSWRImmutable from 'swr/immutable'

export const useOrganizationBlankFiles = (organizationId: string) => {
  const {
    headerLetterPartsApi: { getOrganizationLetterHeadParts },
  } = useAPIContext()

  const {
    data: files,
    isValidating: isLoading,
    mutate,
  } = useSWRImmutable([LetterHeadPartsUrls.OrganizationParts, organizationId], () =>
    getOrganizationLetterHeadParts(organizationId),
  )

  return useMemo(
    () => ({
      files,
      isLoading,
      refetchFiles: mutate,
    }),
    [files, isLoading, mutate],
  )
}
