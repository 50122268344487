import type { ReferenceBooksFiltersFormValues } from '@components/ReferenceBooks/types'

type DictionaryNameFromParams = Record<'dictionaryName', keyof typeof dictionariesNames>

const defaultReferenceBooksFiltersValue: ReferenceBooksFiltersFormValues = {
  searchString: '',
}

const dictionariesNames = {
  estate_permissions: 'estate_permissions',
  eco_activities: 'eco_activities',
  infrastructure_objects: 'infrastructure_objects',
  okved: 'okved',
  tnved: 'tnved',
} as const

const referenceBookTitlesMap = {
  estate_permissions: 'Разрешения на объекты недвижимости',
  eco_activities: 'Мероприятия по снижению негативного воздействия на окружающую среду',
  infrastructure_objects: 'Объекты инфраструктуры',
  tnved: 'ТН ВЭД',
  okved: 'ОКВЭД',
}

export type { DictionaryNameFromParams }
export { defaultReferenceBooksFiltersValue, dictionariesNames,referenceBookTitlesMap }
