import React from 'react'

import styles from '@components/DocumentFormComponents/FormStyles/NpaFormAction.module.scss'
import { useNpaSolicitationInclusionFormManager } from '@components/Forms/NpaSolicitationInclusionForm/Manager'
import Button from '@components/NewDesign/Button'
import Stack from '@components/ReactBootstrap/Stack'

const NpaSolicitationInclusionFormActions = () => {
  const {
    state: { formIsLoading },
    actions: { handlePreviewForm, handleCheckForm },
  } = useNpaSolicitationInclusionFormManager()

  if (formIsLoading) return null

  return (
    <div className={styles.npaAction}>
      <Stack direction={'horizontal'} gap={1}>
        <Button
          geometry={'square'}
          size={'s'}
          view={'plain'}
          className={styles.npaAction__button}
          textClassName={styles['npaAction__button-text']}
          onClick={handleCheckForm}
        >
          Проверить заполнение
        </Button>
        <Button
          geometry={'square'}
          size={'s'}
          view={'plain'}
          className={styles.npaAction__button}
          textClassName={styles['npaAction__button-text']}
          onClick={handlePreviewForm}
        >
          Предварительный просмотр
        </Button>
      </Stack>
    </div>
  )
}

export default NpaSolicitationInclusionFormActions
