import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { StepCompensationsArrayPathName } from '@components/Forms/AAChangingVolumeReimbursementForm/Forms/6/types'
import { useAAChangingVolumeReimbursementManager } from '@components/Forms/AAChangingVolumeReimbursementForm/Manager'
import { AAChangingVolumeReimbursementFormValues } from '@components/Forms/AAChangingVolumeReimbursementForm/types'
import { AAChangingVolumeReimbursementFieldsControlUpdateWatcher } from '@components/Forms/AAChangingVolumeReimbursementForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import { documentsLinks } from '@constants/documents'

import styles from './StepCompensation.module.scss'

interface StepCompensationProps {
  editMode: boolean
  id: string
  formName: StepCompensationsArrayPathName
  formInstance: UseFormReturn<AAChangingVolumeReimbursementFormValues>
  subscribableControl: SubscribableFormControlProps<AAChangingVolumeReimbursementFormValues>
  onRemoveStep: VoidFunction
  dataTestId?: string
}

const StepCompensation: FC<StepCompensationProps> = ({
  editMode,
  id,
  formInstance,
  formName,
  subscribableControl,
  onRemoveStep,
  dataTestId,
}) => {
  const {
    handlers: { handleChangeValue },
  } = useAAChangingVolumeReimbursementManager()

  const isNew = useWatch({
    control: formInstance.control,
    name: `${formName}.isNew`,
  })

  const currentValueOfType = useWatch({
    control: formInstance.control,
    name: `${formName}.stepCompensationType`,
  })

  const stepCompensationPeriod = useWatch({
    control: formInstance.control,
    name: `${formName}.stepCompensationPeriod`,
  })

  const { getSubscribableControlProps, getInputProps, getSingleSelectProps } =
    useFormComponentPresets({
      editMode,
      formInstance,
      subscribableControl,
      watcher: AAChangingVolumeReimbursementFieldsControlUpdateWatcher,
    })

  return (
    <FieldView.StepTemplate
      id={id}
      dataTestId={dataTestId}
      onRemoveStep={editMode ? onRemoveStep : undefined}
    >
      <Row>
        {!isNew && (
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.stepCompensationTypeCommited`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.stepCompensationTypeCommited`,
                  inputProps: {
                    disabled: true,
                    label: 'Текущее значение',
                  },
                })}
              />
            </SubscribableControl>
          </Col>
        )}
        <Col xs={!isNew ? 6 : 12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.stepCompensationType`,
            })}
          >
            {({ overrideProps }) => {
              const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                ? overrideProps.fetcherOptions
                : undefined

              return (
                <ControlledFormSingleSelect
                  {...getSingleSelectProps({
                    fetcherProps,
                    optionsAdapter: (item) => ({
                      displayValue: item.name || '',
                      value: item.id,
                    }),
                    controllerProps: {
                      name: `${formName}.stepCompensationType`,
                    },
                    selectProps: {
                      inputProps: {
                        label: 'Измененное значение',
                      },
                      onChangeFormValue: () => {
                        setTimeout(() => {
                          handleChangeValue?.(`${formName}.stepCompensationType`)
                        }, 0)
                      },
                    },
                  })}
                />
              )
            }}
          </SubscribableControl>

          {currentValueOfType && stepCompensationPeriod && (
            <Typography.Caption
              className={styles.stepCompensation__caption}
              color={'text-base-tertiary'}
              variant={'captionSRegular'}
            >
              {`Срок возмещения затрат составит до ${stepCompensationPeriod}`}
              <Typography.Caption
                as={'span'}
                variant={'captionSRegular'}
                color={'text-accent-brand'}
              >
                <a href={documentsLinks.FZ_N69} target="_blank" rel="noopener noreferrer">
                  &nbsp;(часть 6 статьи 15 69-ФЗ)
                </a>
              </Typography.Caption>
            </Typography.Caption>
          )}
        </Col>
      </Row>
    </FieldView.StepTemplate>
  )
}

export default StepCompensation
