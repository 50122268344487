import { useState } from 'react'
import { FileRejection } from 'react-dropzone'

import { handleDropRejected } from '@helpers/fileReject/fileReject'

const useDragAndDropHook = (initialRejectedFile: File | null = null) => {
  const [isActiveError, setIsActiveError] = useState(!!initialRejectedFile)
  const [rejectedFile, setRejectedFile] = useState<File | null>(initialRejectedFile)

  const handleClose = () => {
    setIsActiveError(false)
    setRejectedFile(null)
  }

  const handleRejectedFiles = (rejectedFile: FileRejection[]) =>
    handleDropRejected(rejectedFile, (file: File) => {
      setIsActiveError(true)
      setRejectedFile(file)
    })

  return {
    isActiveError,
    rejectedFile,
    handleClose,
    handleRejectedFiles,
  }
}

export { useDragAndDropHook }
