import { FC } from 'react'

import One from './1'
import Two from './2'

interface StatementConsiderationInvestmentAuthorizedFormsProps extends FC {
  One: typeof One
  Two: typeof Two
}

const StatementConsiderationInvestmentAuthorizedForms: StatementConsiderationInvestmentAuthorizedFormsProps =
  () => null

StatementConsiderationInvestmentAuthorizedForms.One = One
StatementConsiderationInvestmentAuthorizedForms.Two = Two

export default StatementConsiderationInvestmentAuthorizedForms
