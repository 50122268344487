import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { defaultBeneficiarItemValue } from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/constants'
import {
  BeneficiarSecondNationalityCountry,
  BeneficiarStep,
} from '@components/DocumentFormComponents/FormSections/BeneficialOwnersInfoSection/types'
import { BeneficiaryInformationFormValues } from '@components/Forms/BeneficiaryInformationForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForBeneficiaryForm = (
  objectFromPropertyState: Record<string, any>,
): BeneficiaryInformationFormValues => {
  const preparedBeneficiars = (() => {
    if (
      !isArray(objectFromPropertyState.beneficiars.value) ||
      !objectFromPropertyState.beneficiars.value.length
    )
      return []

    return objectFromPropertyState.beneficiars.value.map((beneficiar): BeneficiarStep => {
      const preparedBeneficiarSecondNationalityCountries =
        isArray(beneficiar.value?.beneficiarSecondNationalityCountries?.value) &&
        beneficiar.value?.beneficiarSecondNationalityCountries?.value.length
          ? beneficiar.value?.beneficiarSecondNationalityCountries?.value.map(
              (country): BeneficiarSecondNationalityCountry => ({
                id: country?.propertyId,
                beneficiarSecondNationalityCountry: !!country?.value?.id
                  ? {
                      value: country?.value?.id || '',
                      displayValue: country?.value?.name || '',
                    }
                  : null,
              }),
            )
          : []

      return {
        id: beneficiar.propertyId,
        beneficiarName:
          beneficiar.value.beneficiarName?.value || defaultBeneficiarItemValue.beneficiarName,
        beneficiarBirthPlace:
          beneficiar.value.beneficiarBirthPlace?.value ||
          defaultBeneficiarItemValue.beneficiarBirthPlace,
        beneficiarBirthDate:
          beneficiar.value.beneficiarBirthDate?.value ||
          defaultBeneficiarItemValue.beneficiarBirthDate,
        beneficiarNationality: beneficiar.value.beneficiarNationality.value?.id
          ? {
              value: beneficiar.value.beneficiarNationality.value?.id || '',
              displayValue: beneficiar.value.beneficiarNationality.value?.name || '',
            }
          : defaultBeneficiarItemValue.beneficiarNationality,
        otherNationality:
          beneficiar.value.otherNationality?.value || defaultBeneficiarItemValue.otherNationality,
        beneficiarNationalityCountry: !!beneficiar.value.beneficiarNationalityCountry.value?.id
          ? {
              value: beneficiar.value.beneficiarNationalityCountry.value?.id || '',
              displayValue: beneficiar.value.beneficiarNationalityCountry.value?.name || '',
            }
          : defaultBeneficiarItemValue.beneficiarNationalityCountry,
        beneficiarSecondNationalityCountries: preparedBeneficiarSecondNationalityCountries,
        beneficiarInn:
          beneficiar.value.beneficiarInn?.value || defaultBeneficiarItemValue.beneficiarInn,
        beneficiarDocument: !!beneficiar.value.beneficiarDocument.value?.id
          ? {
              value: beneficiar.value.beneficiarDocument.value?.id,
              displayValue: beneficiar.value.beneficiarDocument.value?.name,
            }
          : defaultBeneficiarItemValue.beneficiarDocument,
        beneficiarDocumentSeries:
          beneficiar.value.beneficiarDocumentSeries?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentSeries,
        beneficiarDocumentNumber:
          beneficiar.value.beneficiarDocumentNumber?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentNumber,
        beneficiarDocumentDate:
          beneficiar.value.beneficiarDocumentDate?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentDate,
        beneficiarDocumentOrganization:
          beneficiar.value.beneficiarDocumentOrganization?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentOrganization,
        beneficiarDocumentCodeOrganization:
          beneficiar.value.beneficiarDocumentCodeOrganization?.value ||
          defaultBeneficiarItemValue.beneficiarDocumentCodeOrganization,
        beneficiarAddress:
          beneficiar.value.beneficiarAddress?.value || defaultBeneficiarItemValue.beneficiarAddress,
        beneficiarMigrationNumber:
          beneficiar.value.beneficiarMigrationNumber?.value ||
          defaultBeneficiarItemValue.beneficiarMigrationNumber,
        beneficiarMigrationStartDate:
          beneficiar.value.beneficiarMigrationStartDate?.value ||
          defaultBeneficiarItemValue.beneficiarMigrationStartDate,
        beneficiarMigrationEndDate:
          beneficiar.value.beneficiarMigrationEndDate?.value ||
          defaultBeneficiarItemValue.beneficiarMigrationEndDate,
        beneficiarResidenceStartDate:
          beneficiar.value.beneficiarResidenceStartDate?.value ||
          defaultBeneficiarItemValue.beneficiarResidenceStartDate,
        beneficiarResidenceEndDate:
          beneficiar.value.beneficiarResidenceEndDate?.value ||
          defaultBeneficiarItemValue.beneficiarResidenceEndDate,
        beneficiarResidenceNumber:
          beneficiar.value.beneficiarResidenceNumber?.value ||
          defaultBeneficiarItemValue.beneficiarResidenceNumber,
        beneficiarPart:
          beneficiar.value.beneficiarPart?.value || defaultBeneficiarItemValue.beneficiarPart,
        beneficiarReason:
          beneficiar.value.beneficiarReason?.value || defaultBeneficiarItemValue.beneficiarReason,
      }
    })
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      beneficiars: preparedBeneficiars,
    },
  }
}

export { generateRHFObjectForBeneficiaryForm }
