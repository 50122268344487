import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { statementAlreadyInvestmentsBlockValues } from '@components/Forms/StatementAlreadyInvestments/const'
import { secondSectionStatementAlreadyInvestmentsValidationMap } from '@components/Forms/StatementAlreadyInvestments/Forms/2/validation'
import { useStatementAlreadyInvestmentsManager } from '@components/Forms/StatementAlreadyInvestments/Manager'
import { StatementAlreadyInvestmentsFormValues } from '@components/Forms/StatementAlreadyInvestments/types'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { StatementAlreadyFieldsControlUpdateWatcher } from '../../watcher'

const Two = () => {
  const formInstance = useFormContext<StatementAlreadyInvestmentsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useStatementAlreadyInvestmentsManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: StatementAlreadyFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем осуществленных капиталовложений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementAlreadyInvestmentsBlockValues['2'].madeInvestorCapital,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: statementAlreadyInvestmentsBlockValues['2'].madeInvestorCapital,
                    rules:
                      secondSectionStatementAlreadyInvestmentsValidationMap.madeInvestorCapital,
                    inputProps: {
                      integerLength: 19,
                      dataTestId: 'madeInvestorCapital',
                    },
                    onBlur: () =>
                      setTimeout(() => {
                        handleChangeValue?.(
                          statementAlreadyInvestmentsBlockValues['2'].madeInvestorCapital,
                        )
                      }, 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          statementAlreadyInvestmentsBlockValues['2'].madeInvestorCapital,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder disableBottomDefaultStyles title={'Оставшийся объем капиталовложений'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: statementAlreadyInvestmentsBlockValues['2'].remainsInvestorCapital,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: statementAlreadyInvestmentsBlockValues['2'].remainsInvestorCapital,
                    inputProps: {
                      disabled: true,
                      integerLength: 19,
                      dataTestId: 'remainsInvestorCapital',
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent='Оставшийся объем капиталовложений будет посчитан автоматически на основе сведений об объеме капиталовложений, указанных в форме "Проект СЗПК"' />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default Two
