import React from 'react'

import cn from 'classnames'

import { TabProps } from '../../types'

import styles from './tab.module.scss'

export const Tab = ({ children, hidden, className, disabled, dataTestId }: TabProps) => {
  return children ? (
    <div
      hidden={hidden}
      role="tabpanel"
      tabIndex={disabled ? -1 : 0}
      data-test-id={dataTestId}
      className={cn(
        styles.component,
        {
          [styles.hidden]: hidden,
        },
        className,
      )}
    >
      {children}
    </div>
  ) : null
}
