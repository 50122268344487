import { generatePropertiesObjectForIndicatorsOfUnfulfilledAgreementReportForm } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/adapters/PropertiesObject.adapter'
import { generateRHFObjectForIndicatorsOfUnfulfilledAgreementReportForm } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/adapters/RHF.adapter'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/types'

const useIndicatorsOfUnfulfilledAgreementReportAdapters = () => {
  const generateRHFObject = (
    objectFromPropertyState: Record<string, any>,
  ): IndicatorsOfUnfulfilledAgreementReportFormValues =>
    generateRHFObjectForIndicatorsOfUnfulfilledAgreementReportForm(objectFromPropertyState)

  const generatePropertiesObject = (
    objectFromPropertyState: Record<string, any>,
  ): Record<string, unknown> =>
    generatePropertiesObjectForIndicatorsOfUnfulfilledAgreementReportForm(objectFromPropertyState)

  return { generateRHFObject, generatePropertiesObject }
}

export { useIndicatorsOfUnfulfilledAgreementReportAdapters }
