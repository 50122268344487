import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'

export const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()
export const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAModifierFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()
export const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
