import React, { FC, memo } from 'react'
import { useNavigate } from 'react-router'

import { Paths } from '@constants/paths'
import { usePaths } from '@hooks/usePaths'
import { ReactComponent as LogoSVG } from '@icons/layout/logo.svg'
import cn from 'classnames'

import styles from './Logo.module.scss'

interface LogoProps {
  position?: 'center'
  sticky?: boolean
  redirectOnClickTo?: string
  onLogoClick?: () => void
  withRedirectOnClick?: boolean
}

const Logo: FC<LogoProps> = ({
  position,
  sticky,
  redirectOnClickTo = Paths.Home,
  onLogoClick,
  withRedirectOnClick = true,
}) => {
  const navigate = useNavigate()
  const { isHome } = usePaths()

  const handleHome = () => {
    onLogoClick?.()

    if (isHome || !withRedirectOnClick) return

    navigate(redirectOnClickTo)
  }

  return (
    <div
      aria-hidden
      role="button"
      className={cn(styles.wrapper, {
        [styles[`wrapper--isHome`]]: isHome,
        [styles[`wrapper--${position}`]]: position,
        [styles[`wrapper--sticky`]]: sticky,
      })}
      onClick={handleHome}
    >
      <LogoSVG role="img" aria-label="Logo" aria-labelledby="Logo" />
    </div>
  )
}

export default memo(Logo)
