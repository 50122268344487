import React, { cloneElement } from 'react'

import { TabsProps } from '../../types'

export const Tabs = ({
  TabList,
  className,
  containerClassName,
  size,
  defaultMatchMediaValue,
  children,
  selectedId,
  scrollable,
  collapsedTabsIds,
  fullWidthScroll = false,
  keepMounted = false,
  dataTestId,
  onChange,
  breakpoint = 1024,
  textStyle,
  style,
}: Omit<TabsProps, 'view'>) => {
  const tabsArray = React.Children.toArray(children) as TabsProps['children']
  const titles = tabsArray.map(
    ({
      props: {
        title,
        id,
        rightAddons,
        rightAddonClassname,
        disabled,
        hidden,
        toggleClassName,
        dataTestId: toggleTestId,
        toggleRef,
      },
    }) => ({
      title,
      id,
      disabled,
      rightAddons,
      rightAddonClassname,
      hidden,
      toggleClassName,
      dataTestId: toggleTestId,
      toggleRef,
    }),
  )

  const tabs = tabsArray.filter(
    (tab) => tab.props.id === selectedId || tab.props.keepMounted || keepMounted,
  )

  return (
    <div className={className} style={style}>
      <TabList
        containerClassName={containerClassName}
        size={size}
        titles={titles}
        selectedId={selectedId}
        scrollable={scrollable}
        collapsedTabsIds={collapsedTabsIds}
        dataTestId={dataTestId}
        defaultMatchMediaValue={defaultMatchMediaValue}
        fullWidthScroll={fullWidthScroll}
        breakpoint={breakpoint}
        textStyle={textStyle}
        onChange={onChange}
      />

      {tabs.map((tab) => cloneElement(tab, { hidden: tab.props.id !== selectedId }))}
    </div>
  )
}
