import React from 'react'
import { useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/const'
import { fourthSectionValidationMap } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/Forms/4/validation'
import { useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAManager } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/Manager'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedFieldsControlUpdateWatcher } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const Fourth = () => {
  const formInstance =
    useFormContext<NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAManager()

  const { getSubscribableControlProps, getInputProps, getTextareaProps, getAmountInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher:
        NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedFieldsControlUpdateWatcher,
    })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункт Правил, на основании которого выполняется рассмотрение ходатайства о связанности'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].ruleLinkSolicitationRelatedContractChecking,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].ruleLinkSolicitationRelatedContractChecking,
                    rules: fourthSectionValidationMap.ruleLinkSolicitationRelatedContractChecking,
                    inputProps: {
                      caption: 'рекомендация: 42',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].ruleLinkSolicitationRelatedContractChecking,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].ruleLinkSolicitationRelatedContractChecking,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              title={
                'Пункт Правил, на основании которого направляется уведомление о выявленных нарушениях при рассмотрении ходатайства о связанности'
              }
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].ruleLinkSolicitationRelatedContractReturn,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].ruleLinkSolicitationRelatedContractReturn,
                    rules: fourthSectionValidationMap.ruleLinkSolicitationRelatedContractReturn,

                    inputProps: {
                      caption: 'рекомендация: 40',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].ruleLinkSolicitationRelatedContractReturn,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].ruleLinkSolicitationRelatedContractReturn,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Технические ошибки'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].solicitationRelatedContractTechError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].solicitationRelatedContractTechError,
                    rules: fourthSectionValidationMap.solicitationRelatedContractTechError,
                    textareaProps: {
                      maxRows: Infinity,
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].solicitationRelatedContractTechError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].solicitationRelatedContractTechError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Не приложены один или несколько необходимых документов'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].solicitationRelatedContractDocumentError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].solicitationRelatedContractDocumentError,
                    rules: fourthSectionValidationMap.solicitationRelatedContractDocumentError,
                    textareaProps: {
                      maxRows: Infinity,
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].solicitationRelatedContractDocumentError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].solicitationRelatedContractDocumentError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Group disableBottomBorder title={'Несоответствие ходатайства требованиям'}>
          <Stack direction={'vertical'} gap={4}>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={
                    'Ходатайство и (или) документы, прилагаемые к нему, не соответствуют требованиям пункта Правил'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                        '4'
                      ].solicitationRelatedContractRequirementError,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                          '4'
                        ].solicitationRelatedContractRequirementError,
                        rules:
                          fourthSectionValidationMap.solicitationRelatedContractRequirementError,

                        textareaProps: {
                          maxRows: Infinity,
                          caption: 'не обязательно',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                  '4'
                                ].solicitationRelatedContractRequirementError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                '4'
                              ].solicitationRelatedContractRequirementError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder firstColumnSize={6} secondColumnSize={6} title={'Пункт Правил'}>
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                        '4'
                      ].solicitationRelatedContractRequirementLinkError,
                    })}
                  >
                    <ControlledInput
                      {...getInputProps({
                        name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                          '4'
                        ].solicitationRelatedContractRequirementLinkError,
                        rules:
                          fourthSectionValidationMap.solicitationRelatedContractRequirementLinkError,
                        inputProps: {},
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                  '4'
                                ].solicitationRelatedContractRequirementLinkError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                '4'
                              ].solicitationRelatedContractRequirementLinkError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={
                    'Требования, установленные частью 1 статьи 14 ФЗ, в части вида, предмета \n' +
                    'и сторон договора, который может быть признан связанным, не соблюдены'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                        '4'
                      ].solicitationRelatedContractContractKindError,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                          '4'
                        ].solicitationRelatedContractContractKindError,
                        rules:
                          fourthSectionValidationMap.solicitationRelatedContractContractKindError,

                        textareaProps: {
                          maxRows: Infinity,
                          caption: 'не обязательно',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                  '4'
                                ].solicitationRelatedContractContractKindError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                '4'
                              ].solicitationRelatedContractContractKindError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
          </Stack>
        </Group>
        <Group disableBottomBorder title={'Несоответствие договора критериям'}>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <RowWithBorder
                  disableBottomDefaultStyles
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={
                    'Договор, в отношении которого подано ходатайство о связанности, не соответствует критериям, предусмотренным пунктом Правил'
                  }
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                        '4'
                      ].solicitationRelatedContractRuleError,
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                          '4'
                        ].solicitationRelatedContractRuleError,
                        rules: fourthSectionValidationMap.solicitationRelatedContractRuleError,

                        textareaProps: {
                          maxRows: Infinity,
                          caption: 'не обязательно',
                        },
                        onBlur: () =>
                          setTimeout(
                            () =>
                              handleChangeValue?.(
                                notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                  '4'
                                ].solicitationRelatedContractRuleError,
                              ),
                            0,
                          ),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                '4'
                              ].solicitationRelatedContractRuleError,
                            )
                          }, 0),
                      })}
                    />
                  </SubscribableControl>
                </RowWithBorder>
              </Col>
            </Row>
            <RowWithBorder firstColumnSize={6} secondColumnSize={6} title={'Пункт Правил'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].solicitationRelatedContractRuleLinkError,
                })}
              >
                <ControlledInput
                  {...getInputProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].solicitationRelatedContractRuleLinkError,
                    rules: fourthSectionValidationMap.solicitationRelatedContractRuleLinkError,
                    inputProps: {},
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].solicitationRelatedContractRuleLinkError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].solicitationRelatedContractRuleLinkError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Заявителем не соблюдены требования части 16 статьи 7 ФЗ'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].solicitationRelatedContractRelatedContractError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].solicitationRelatedContractRelatedContractError,
                    rules:
                      fourthSectionValidationMap.solicitationRelatedContractRelatedContractError,
                    textareaProps: {
                      maxRows: Infinity,
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].solicitationRelatedContractRelatedContractError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].solicitationRelatedContractRelatedContractError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Ходатайство не соответствует требованиям'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].solicitationRelatedContractCreditContractError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].solicitationRelatedContractCreditContractError,
                    rules:
                      fourthSectionValidationMap.solicitationRelatedContractCreditContractError,
                    textareaProps: {
                      maxRows: Infinity,
                      caption: 'не обязательно',
                      leftAddons: (
                        <FormIconWithTooltip
                          tooltipContent={
                            'Ходатайство не соответствует требованиям к кредитору по кредитному договору, предусматривающему предоставление заявителю кредитных средств по льготной ставке, установленному пунктом 2 части 1 статьи 14 ФЗ (если применимо)'
                          }
                        />
                      ),
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].solicitationRelatedContractCreditContractError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].solicitationRelatedContractCreditContractError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder firstColumnSize={6} secondColumnSize={6} title={'Иные нарушения'}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].solicitationRelatedContractOtherError,
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].solicitationRelatedContractOtherError,
                    rules: fourthSectionValidationMap.solicitationRelatedContractOtherError,

                    textareaProps: {
                      maxRows: Infinity,
                      caption: 'не обязательно',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].solicitationRelatedContractOtherError,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].solicitationRelatedContractOtherError,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].solicitationRelatedContractOtherDetails,
                  })}
                >
                  <ControlledTextarea
                    {...getTextareaProps({
                      name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                        '4'
                      ].solicitationRelatedContractOtherDetails,
                      rules: fourthSectionValidationMap.solicitationRelatedContractOtherDetails,

                      textareaProps: {
                        maxRows: Infinity,
                        label: 'Дополнительная информация для ОРП',
                        caption: 'не обязательно',
                      },
                      onBlur: () =>
                        setTimeout(
                          () =>
                            handleChangeValue?.(
                              notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                                '4'
                              ].solicitationRelatedContractOtherDetails,
                            ),
                          0,
                        ),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].solicitationRelatedContractOtherDetails,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Дней на устранение нарушений'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                    '4'
                  ].solicitationDaysRevision,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                      '4'
                    ].solicitationDaysRevision,
                    rules: fourthSectionValidationMap.solicitationDaysRevision,
                    inputProps: {
                      integersOnly: true,
                      offAmountSplitting: true,
                      hasNegativeValue: false,
                      caption: 'рекомендация: 10',
                      suffix: '',
                    },
                    onBlur: () =>
                      setTimeout(
                        () =>
                          handleChangeValue?.(
                            notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                              '4'
                            ].solicitationDaysRevision,
                          ),
                        0,
                      ),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(
                          notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues[
                            '4'
                          ].solicitationDaysRevision,
                        )
                      }, 0),
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
      </Stack>
    </Container>
  )
}

export default Fourth
