import { FC, useCallback } from 'react'
import { usePopupManager } from 'react-popup-manager'

import { FormModalControlProps } from '@components/DocumentFormComponents/types'

import AAgreementIncludeRelatedTermsLayout from './Layout'
import AAgreementIncludeRelatedTermsModalManager from './Manager'
import AAgreementIncludeRelatedTermsMenu from './Menu'
import AAgreementIncludeRelatedTermsStatementForm from './Statement'

interface IAAgreementIncludeRelatedTerms extends FC {
  Layout: typeof AAgreementIncludeRelatedTermsLayout
  Menu: typeof AAgreementIncludeRelatedTermsMenu
  StatementForm: typeof AAgreementIncludeRelatedTermsStatementForm
  Manager: typeof AAgreementIncludeRelatedTermsModalManager
}

const useAAgreementIncludeRelatedTermsControl = () => {
  const popupManager = usePopupManager()

  const openAAgreementIncludeRelatedTermsModal = useCallback(
    (props: FormModalControlProps) => {
      const { formId, onClose, ...rest } = props

      if (!formId) {
        onClose?.()

        return
      }

      popupManager.open<FormModalControlProps>(AAgreementIncludeRelatedTermsLayout, {
        ...rest,
        formId,
        onClose: onClose || (() => null),
      })
    },
    [popupManager],
  )

  return { openAAgreementIncludeRelatedTermsModal }
}

const AAgreementIncludeRelatedTerms: IAAgreementIncludeRelatedTerms = () => null

AAgreementIncludeRelatedTerms.Menu = AAgreementIncludeRelatedTermsMenu
AAgreementIncludeRelatedTerms.Layout = AAgreementIncludeRelatedTermsLayout
AAgreementIncludeRelatedTerms.StatementForm = AAgreementIncludeRelatedTermsStatementForm
AAgreementIncludeRelatedTerms.Manager = AAgreementIncludeRelatedTermsModalManager

export { useAAgreementIncludeRelatedTermsControl }
export default AAgreementIncludeRelatedTerms
