import { FC } from 'react'

import First from './1'
import Second from './2'

interface NotificationOfChangesInDetailsOfPartiesFormsProps extends FC {
  First: typeof First
  Second: typeof Second
}

const NotificationOfChangesInDetailsOfPartiesForms: NotificationOfChangesInDetailsOfPartiesFormsProps =
  () => null

NotificationOfChangesInDetailsOfPartiesForms.First = First
NotificationOfChangesInDetailsOfPartiesForms.Second = Second

export default NotificationOfChangesInDetailsOfPartiesForms
