import { FieldValues } from 'react-hook-form'

import { OptionProps } from '@components/NewDesign/Select/model'
import { RolesTypes } from '@constants/types'

interface OptionOld extends FieldValues {
  id: number | string
  name: string
}

export const ORGANIZATION_ROLE_OPTIONS: OptionOld[] = [
  {
    id: 1,
    name: 'Уполномоченная организация',
    type: RolesTypes.UO,
  },
  {
    id: 2,
    name: 'Федеральное казначейство РФ',
    type: RolesTypes.FK,
  },
  {
    id: 3,
    name: 'МЭР РФ',
    type: RolesTypes.MER,
  },
  {
    id: 4,
    name: 'Орган исполнительной власти',
    type: RolesTypes.OIV,
  },
  {
    id: 5,
    name: 'Инвестор',
    type: RolesTypes.INVESTOR,
  },
]

export const ORGANIZATION_ROLE_OPTIONS_NEW_DESIGN: OptionProps[] = [
  {
    displayValue: 'Уполномоченная организация',
    value: RolesTypes.UO,
  },
  {
    displayValue: 'Федеральное казначейство РФ',
    value: RolesTypes.FK,
  },
  {
    displayValue: 'МЭР РФ',
    value: RolesTypes.MER,
  },
  {
    displayValue: 'Орган исполнительной власти',
    value: RolesTypes.OIV,
  },
  {
    displayValue: 'Инвестор',
    value: RolesTypes.INVESTOR,
  },
]
