import React, { FC, memo } from 'react'
import { PopupProps, usePopupManager } from 'react-popup-manager'

import FormProviderWrapper from '@components/DocumentFormComponents/FormProviderWrapper'
import { defaultChooseNpaFormValues } from '@components/RegistryNpa/Choose/const'
import ChooseModalManager from '@components/RegistryNpa/Choose/Manager'
import RegistryNpaChooseModal from '@components/RegistryNpa/Choose/Modal'
import { IFiltersFormValues } from '@components/RegistryNpa/Manager'
import { ChosenPart, NpaFormTypes } from '@services/NPA/NPA.entity'

interface RegistryNpaChooseComponentProps {
  documentType: NpaFormTypes
  onAddItemToList: (chosenParts: unknown[]) => Promise<void>
  initialFilters?: Partial<IFiltersFormValues['filters']>
  chosenParts?: ChosenPart[]
  excludedPartIds?: string[]
}

type RegistryNPAChoose = PopupProps & RegistryNpaChooseComponentProps

const useRegistryNpaChooseModal = () => {
  const popupManager = usePopupManager()

  const handleOpenRegistryNpaChooseModal = (props: RegistryNpaChooseComponentProps) => {
    popupManager.open(RegistryNPAChoose, props)
  }

  return {
    handleOpenRegistryNpaChooseModal,
  }
}

const RegistryNPAChoose: FC<RegistryNPAChoose> = ({ isOpen, onClose, ...managerProps }) => {
  return (
    <FormProviderWrapper defaultValues={defaultChooseNpaFormValues}>
      <ChooseModalManager onClose={onClose} {...managerProps}>
        <RegistryNpaChooseModal isOpen={isOpen} onClose={onClose} />
      </ChooseModalManager>
    </FormProviderWrapper>
  )
}

export type { RegistryNpaChooseComponentProps }
export { useRegistryNpaChooseModal }
export default memo(RegistryNPAChoose)
