import { FC, memo, useMemo } from 'react'
import { PopupProps } from 'react-popup-manager'

import { ButtonProps } from '@components/NewDesign/Button/types'
import Modal from '@components/NewDesign/Modal'
import Typography from '@components/NewDesign/Typography'
import EditIcon from '@icons/EditIcon_Big.svg'

import styles from './SendChangeProjectModal.module.scss'

export interface ContinueProjectModalType {
  onSuccess: () => void
}

export type TSendChangeProjectModalProps = Required<PopupProps> & ContinueProjectModalType

const IconProp = {
  src: EditIcon,
  className: styles.icon,
}

const SendChangeProjectModal: FC<TSendChangeProjectModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const doneActions = useMemo(
    () =>
      [
        {
          dataTestId: 'SendChangeProjectModal-cancel-button',
          children: 'Отмена',
          view: 'gray',
          onClick: onClose,
          className: styles.btn,
        },
        {
          dataTestId: 'SendChangeProjectModal-sendChangeProject-button',
          children: 'Продолжить',
          fixWidth: true,
          onClick: () => {
            onSuccess()
          },
          className: styles.btn,
        },
      ] as ButtonProps[],
    [onSuccess, onClose],
  )

  return (
    <Modal.Status
      icon={IconProp}
      actions={doneActions}
      isOpen={isOpen}
      title={'Отправить изменения?'}
      dataTestId="SendChangeProjectModal-modal"
      containerClassName={styles.modalWrapper}
      actionsClassName={styles.modalActions}
      contentClassName={styles.body}
      onClose={onClose}
    >
      <div className={styles.body__text}>
        <Typography.Body variant="bodyMRegular" className={styles.body}>
          Срок рассмотрения может быть увеличен до 30 рабочих дней
        </Typography.Body>
      </div>
    </Modal.Status>
  )
}

export default memo(SendChangeProjectModal)
