let onFrame

if (typeof window !== 'undefined') {
  onFrame = window.requestAnimationFrame
}

onFrame =
  onFrame ||
  function (callback) {
    window.setTimeout(callback, 1000 / 60)
  }

const start = Date.now()
const performanceNow =
  typeof performance === 'object' && typeof performance.now === 'function'
    ? () => performance.now()
    : () => Date.now() - start

// Lop off the first occurence of the reference in the Array.
function removeFirst(array, item) {
  const idx = array.indexOf(item)
  idx !== -1 && array.splice(idx, 1)
}

export { onFrame, performanceNow, removeFirst }
