import React, {
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  memo,
  ReactNode,
  useRef,
} from 'react'
import { CSSTransition } from 'react-transition-group'

import { CheckboxDefaultPayload } from '@components/Checkbox/types'
import Typography from '@components/NewDesign/Typography'
import { ITypographyBody } from '@components/NewDesign/Typography/types'
import { mergeRefs } from '@helpers/ref/mergeRefs'
import { useFocus } from '@hooks/useFocus'
import { useHover } from '@hooks/useHover'
import cn from 'classnames'

import styles from './Checkbox.module.scss'

const TRANSITION_TIMEOUT = 100

const transitionLabelAddonsClassNames = {
  enter: styles['transition__fade-enter'],
  exit: styles['transition__fade-exit'],
  enterActive: styles['transition__fade-enter--active'],
  exitActive: styles['transition__fade-exit--active'],
}

type NativeProps = InputHTMLAttributes<HTMLInputElement>
type Align = 'start' | 'center'

export type CheckboxProps = Omit<NativeProps, 'size' | 'onChange'> & {
  /**
   * Управление состоянием вкл/выкл чекбокса (native prop)
   */
  checked?: boolean

  /**
   * Обработчик переключения чекбокса
   */
  onChange?: (event?: ChangeEvent<HTMLInputElement>, payload?: CheckboxDefaultPayload) => void

  /**
   * Текст подписи к чекбоксу
   */
  label?: ReactNode

  labelTypographyProps?: Omit<ITypographyBody, 'variant'>

  /**
   * Доп. класс чекбокса
   */
  boxClassName?: string

  /**
   * Доп. класс контента
   */
  contentClassName?: string

  /**
   * Выравнивание
   */
  align?: Align

  /**
   * Растягивать ли компонент на всю ширину
   */
  block?: boolean

  /**
   * Управление состоянием включен / выключен
   */
  disabled?: boolean

  /**
   * Управление неопределенным состоянием чекбокса
   */
  indeterminate?: boolean

  /**
   * Отображение аддона слева от чекбокса
   */
  leftAddons?: ReactNode

  /**
   * Отображение аддона слева от чекбокса
   */
  rightAddons?: ReactNode

  labelWrapperProps?: DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >

  dataTestId?: string
}

const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps>(
  (
    {
      checked,
      label,
      boxClassName,
      contentClassName,
      align = 'center',
      block,
      onChange,
      className,
      name,
      disabled,
      indeterminate = false,
      labelTypographyProps,
      labelWrapperProps,
      dataTestId,
      leftAddons,
      rightAddons,
      ...restProps
    },
    ref,
  ) => {
    const labelRef = useRef<HTMLLabelElement>(null)
    const rightAddonsNodeRef = useRef<HTMLDivElement>(null)

    const [hoverRef, isHovered] = useHover<HTMLLabelElement>()

    const [focused] = useFocus(labelRef, 'keyboard')

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event, { checked: event.target.checked, name })
      }
    }

    return (
      <label
        {...labelWrapperProps}
        ref={mergeRefs([labelRef, ref, hoverRef])}
        className={cn(styles.component, styles[align], className, {
          [styles.disabled]: disabled,
          [styles.checked]: checked,
          [styles.indeterminate]: indeterminate,
          [styles.focused]: focused,
          [styles.block]: block,
          [styles['component--withAddon']]: !!rightAddons,
        })}
      >
        {leftAddons && <div className={cn(styles.addon, styles['addon--left'])}>{leftAddons}</div>}
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          data-testid={dataTestId}
          onChange={handleChange}
          {...restProps}
        />
        <span className={cn(styles.box, boxClassName)}>
          {checked && (
            <svg
              role="img"
              focusable="false"
              fill="currentColor"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className={styles.checkedIcon}
            >
              <path
                d="M17 9.5L10.5 16 7 12.5 8.5 11l2 2 5-5L17 9.5z"
                fillRule="evenodd"
                clipRule="evenodd"
              />
            </svg>
          )}

          {indeterminate && !checked && <span className={styles.indeterminateLine} />}
        </span>

        {label && (
          <span className={cn(styles.content, contentClassName)}>
            <Typography.Body {...labelTypographyProps} variant={'bodyMMedium'}>
              {label}
            </Typography.Body>
          </span>
        )}
        <CSSTransition
          mountOnEnter
          unmountOnExit
          in={isHovered && !!rightAddons}
          timeout={TRANSITION_TIMEOUT}
          classNames={transitionLabelAddonsClassNames}
          nodeRef={rightAddonsNodeRef}
        >
          <div ref={rightAddonsNodeRef} className={cn(styles.addon, styles['addon--right'])}>
            {rightAddons}
          </div>
        </CSSTransition>
      </label>
    )
  },
)

export default memo(Checkbox)
