import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import Accordion from '@components/DocumentFormComponents/Accordion'
import FieldView from '@components/DocumentFormComponents/FieldView'
import styles from '@components/DocumentFormComponents/FormStyles/MainTemplate.module.scss'
import Group from '@components/DocumentFormComponents/Group'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import CertificateOnMonitoringResultsCompensationsDismantlingCheckerGroup from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationsDismantlingCheckerGroup'
import CertificateOnMonitoringResultsCompensationsExpensesCheckerGroup from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationsExpensesCheckerGroup'
import CertificateOnMonitoringResultsCompensationsPercentCreditCheckerGroup from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationsPercentCreditCheckerGroup'
import CertificateOnMonitoringResultsCompensationMonitoringCheckerGroup from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationsRelatedInfrastructureCheckerGroup'
import CertificateOnMonitoringResultsCompensationsSupportingInfrastructureCheckerGroup from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/CompensationsSupportingInfrastructureCheckerGroup'
import { compensationMonitoringCheckerCertificateOnMonitoringResultsValidationMap } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/CompensationMonitoringChecker/validation'
import { CertificateOnMonitoringResultsCompensationsMonitoringCheckerArrayPathName } from '@components/Forms/CertificateOnMonitoringResultsForm/Forms/9/types'
import { useCertificateOnMonitoringResultsManager } from '@components/Forms/CertificateOnMonitoringResultsForm/Manager'
import { CertificateOnMonitoringResultsFormValues } from '@components/Forms/CertificateOnMonitoringResultsForm/types'
import {
  CertificateOnMonitoringResultsFieldCollapseControlUpdateWatcher,
  CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
} from '@components/Forms/CertificateOnMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

interface CertificateOnMonitoringResultsCompensationMonitoringCheckerProps {
  id: string
  initialAccordionState?: boolean
  name: CertificateOnMonitoringResultsCompensationsMonitoringCheckerArrayPathName
  formInstance: UseFormReturn<CertificateOnMonitoringResultsFormValues>
}

const CertificateOnMonitoringResultsCompensationMonitoringChecker: FC<
  CertificateOnMonitoringResultsCompensationMonitoringCheckerProps
> = ({ formInstance, id, initialAccordionState, name }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useCertificateOnMonitoringResultsManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: CertificateOnMonitoringResultsFieldsControlUpdateWatcher,
  })

  const { compensationYearChecker, anchorId } = useWatch({
    control: formInstance.control,
    name,
  })

  const { isExpanded, onToggleCollapse } = useFormCollapseControl({
    initialExpanded: initialAccordionState,
    watcher: CertificateOnMonitoringResultsFieldCollapseControlUpdateWatcher,
    name,
  })

  return (
    <Accordion
      id={id}
      anchorId={anchorId}
      title={`Год возмещения затрат - ${compensationYearChecker}`}
      initialAccordionState={initialAccordionState}
      isExpanded={isExpanded}
      onToggle={onToggleCollapse}
    >
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={3} className={styles.mainTemplate__stack}>
          <CertificateOnMonitoringResultsCompensationMonitoringCheckerGroup
            name={name}
            formInstance={formInstance}
          />
          <CertificateOnMonitoringResultsCompensationsSupportingInfrastructureCheckerGroup
            name={name}
            formInstance={formInstance}
          />
          <CertificateOnMonitoringResultsCompensationsPercentCreditCheckerGroup
            name={name}
            formInstance={formInstance}
          />
          <CertificateOnMonitoringResultsCompensationsDismantlingCheckerGroup
            name={name}
            formInstance={formInstance}
          />
          <CertificateOnMonitoringResultsCompensationsExpensesCheckerGroup
            name={name}
            formInstance={formInstance}
          />
          <Group disableBottomBorder title={'Объем возмещенных затрат (расходов)'}>
            <Stack direction={'vertical'} gap={3}>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'В форме субсидии'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationSubsidyFormat`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.compensationSubsidyFormat`,
                      inputProps: {
                        disabled: true,
                        caption: 'значение, введенное инвестором',
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'В форме субсидии'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationSubsidyFormatChecker`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.compensationSubsidyFormatChecker`,
                      rules:
                        compensationMonitoringCheckerCertificateOnMonitoringResultsValidationMap.compensationSubsidyFormatChecker,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.compensationSubsidyFormatChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.compensationSubsidyFormatChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'В форме налогового вычета'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationTaxDeductionFormat`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.compensationTaxDeductionFormat`,
                      inputProps: {
                        disabled: true,
                        caption: 'значение, введенное инвестором',
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'В форме налогового вычета'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationTaxDeductionFormatChecker`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.compensationTaxDeductionFormatChecker`,
                      rules:
                        compensationMonitoringCheckerCertificateOnMonitoringResultsValidationMap.compensationTaxDeductionFormatChecker,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.compensationTaxDeductionFormatChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(
                            `${name}.compensationTaxDeductionFormatChecker`,
                          )
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'В форме возмещения реального ущерба'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationDamageFormat`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.compensationDamageFormat`,
                      inputProps: {
                        disabled: true,
                        caption: 'значение, введенное инвестором',
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                firstColumnSize={6}
                secondColumnSize={6}
                title={'В форме возмещения реального ущерба'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.compensationDamageFormatChecker`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.compensationDamageFormatChecker`,
                      rules:
                        compensationMonitoringCheckerCertificateOnMonitoringResultsValidationMap.compensationDamageFormatChecker,
                      onBlur: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.compensationDamageFormatChecker`)
                        }, 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.compensationDamageFormatChecker`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Общий объем возмещенных затрат'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.generalCompensation`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.generalCompensation`,
                      inputProps: {
                        disabled: true,
                        caption: 'рассчитывается на основе данных, введенных инвестором',
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Общий объем возмещенных затрат'}
              >
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${name}.generalCompensationChecker`,
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.generalCompensationChecker`,
                      inputProps: {
                        disabled: true,
                        caption:
                          'рассчитывается на основе данных, введенных уполномоченной организацией',
                      },
                    })}
                  />
                </SubscribableControl>
              </FieldView.RowWithBottomBorder>
            </Stack>
          </Group>
        </Stack>
      </Container>
    </Accordion>
  )
}

export default CertificateOnMonitoringResultsCompensationMonitoringChecker
