import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import DocumentLayoutContent from '@components/DocumentFormComponents/LayoutContent'
import { useCertificateApplicationConclusionOfAARequirementsManager } from '@components/Forms/CertificateApplicationConclusionOfAARequirementsForm/Manager'

type CertificateApplicationConclusionOfAARequirementsLayoutWrapperProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const CertificateApplicationConclusionOfAARequirementsLayoutWrapper: FC<
  CertificateApplicationConclusionOfAARequirementsLayoutWrapperProps
> = ({ children, ...rest }) => {
  const {
    state: { lastUpdateDraftTime },
    actions: { handleFormClose },
  } = useCertificateApplicationConclusionOfAARequirementsManager()

  const handleClose = () => {
    handleFormClose?.()
  }

  return (
    <>
      <DocumentLayoutContent {...rest} draftIsSaved={!!lastUpdateDraftTime} onClose={handleClose}>
        {children}
      </DocumentLayoutContent>
    </>
  )
}

export default CertificateApplicationConclusionOfAARequirementsLayoutWrapper
