import React, { FC } from 'react'

import Toast from '@components/Toast'

import styles from './DownloadToast.module.scss'

interface DownloadToast {
  onClose?: VoidFunction
}

const DownloadToast: FC<DownloadToast> = ({ children = 'Подготовка документа...', onClose }) => {
  return (
    <Toast
      withLoader
      wrapperClassname={styles['toast__loading']}
      contentClassname={styles['toast__content']}
      onClose={onClose}
    >
      {children}
    </Toast>
  )
}

export default DownloadToast
