import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import ControlContainer from '@components/ControlContainer'
import ControlContainerStatusIcon from '@components/ControlContainer/Status/Icon'
import Loader from '@components/Loader'
import Button from '@components/NewDesign/Button'
import NpaThreeOld from '@components/Projects/[id]/DocumentLayout/Addons/NpaAddonOld/NpaThreeOld'
import { getProjectAttributeByName } from '@components/Projects/[id]/helpers'
import { useRegistryNpaChooseOldModal } from '@components/RegistryNpa/ChooseOld'
import { DocumentsType } from '@constants/types'
import { useAPIContext } from '@context/APIContext'
import { compact } from '@helpers/array/compact'
import { isString } from '@helpers/checkTypes'
import { withDownloadToastPromise } from '@helpers/toast/withToastPromise'
import { NpaAddonThreeManager } from '@helpers/watcher'
import { useNpaDirections } from '@hooks/new/swr/useNpaDirections'
import { useNpaPartClassification } from '@hooks/new/swr/useNpaPartClassification'
import { useProject } from '@hooks/new/swr/useProject'
import { useProjectAttributes } from '@hooks/new/swr/useProjectAttribute'
import { useRegions } from '@hooks/new/swr/useRegions'
import { useBooleanState } from '@hooks/useBooleanState'
import EyeIcon from '@icons/EyeIcon.svg'
import LoggerHelpersService from '@services/LoggerService/LoggerService.helpers'
import type { INpaDraftBody, INpaThreeLayoutWithChangesNpaRegistry } from '@services/NPA/NPA.entity'
import NPAService from '@services/NPA/NPA.service'

import styles from './NpaLayoutOld.module.scss'

export interface FormLayoutValues {
  npaStructure: INpaThreeLayoutWithChangesNpaRegistry[] | null
  chosenParts: INpaDraftBody | null
}

interface NpaLayoutProps {
  dfoId: string
  documentSetId: string
}

export interface ICardViewRefs {
  [key: string]: number
}

export interface IDirectionStateViewRef {
  [key: string]: boolean
}

const { buildThreeForNpaLayout, fromLayoutInterfaceToModalChosen } = NPAService

const NpaAddonOld: FC<NpaLayoutProps> = ({ documentSetId, dfoId }) => {
  const { projectId } = useParams()

  const { booleanState: layoutIsLoading, setBooleanState: setLayoutIsLoading } = useBooleanState()

  const { handleOpenRegistryNpaChooseOldModal } = useRegistryNpaChooseOldModal()
  const {
    draftApi: { getDraft, putDraft, getDraftPreview },
    npaApi: { getNpasList, getNpaPartsById },
  } = useAPIContext()

  const { project } = useProject({ key: { projectId, _key: 'project' } })

  const { npaDirectionsWithOrder } = useNpaDirections({
    key: {
      _key: 'npaDirections',
    },
  })

  const { partClassificationInObjectMap } = useNpaPartClassification({
    key: {
      _key: 'partClassifications',
    },
  })

  const { projectAttributes } = useProjectAttributes({
    key: { projectId, _key: 'projectAttributes' },
    config: {
      onError: LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'NpaAddon',
          moduleName: 'DynamicDocumentNew',
        },
      }),
    },
  })

  const { reversedRegionsObject } = useRegions({
    key: {
      _key: 'regions',
    },
  })

  const layoutThree = useForm<FormLayoutValues>({
    defaultValues: {
      npaStructure: [],
      chosenParts: null,
    },
  })

  const cardViewRefs = useRef<ICardViewRefs | null>(null)
  const stateOfDirections = useRef<IDirectionStateViewRef | null>(null)

  const regionsToNpaModal = useMemo(() => {
    if (!projectAttributes) return []

    const subjectsToReturn = getProjectAttributeByName('subjects', projectAttributes)[0]?.value

    const returnCondition = Array.isArray(subjectsToReturn)

    return returnCondition && reversedRegionsObject
      ? compact(subjectsToReturn.map((value) => isString(value) && reversedRegionsObject[value]))
      : []
  }, [projectAttributes, reversedRegionsObject])

  const chosenParts = layoutThree.watch('chosenParts')

  const handleBindCardRef = useCallback(
    (callback: (prevState: ICardViewRefs | null) => ICardViewRefs) => {
      cardViewRefs.current = callback(cardViewRefs.current)
    },
    [],
  )

  const initializeLayoutThree = useCallback(
    async (partToScrollAfterUpdate?: string) => {
      if (!documentSetId || !projectId || !partClassificationInObjectMap || !npaDirectionsWithOrder)
        return

      setLayoutIsLoading(true)

      try {
        const npaDraft = await getDraft({
          dfoId,
          documentSetId: documentSetId,
          projectId,
          documentType: DocumentsType.NPA_LIST,
        })

        if (!npaDraft.npaParts) return

        const data = await buildThreeForNpaLayout({
          initialParts: npaDraft.npaParts,
          directions: npaDirectionsWithOrder,
          helpers: {
            getPartsByNpaID: getNpaPartsById,
            getNpaList: getNpasList,
          },
          mapOfClassificationPart: partClassificationInObjectMap,
        })

        layoutThree.setValue('npaStructure', data)
        layoutThree.setValue('chosenParts', npaDraft)

        if (partToScrollAfterUpdate) {
          setTimeout(() => {
            document.querySelector('#headerLayout')?.scrollTo({
              top: cardViewRefs.current?.[partToScrollAfterUpdate] || 0,
              behavior: 'smooth',
            })
          }, 0)
        }
      } catch (error) {
        const additionInfo = {
          documentSetId: documentSetId,
          documentType: DocumentsType.NPA_LIST,
        }

        LoggerHelpersService.handleMultipleLogError({
          additionInfo,
          componentInfo: {
            componentName: 'NpaAddon',
            moduleName: 'DynamicDocumentNew',
            componentType: 'initializeNPAThree',
          },
        })(error)

        throw error
      } finally {
        setLayoutIsLoading(false)
        NpaAddonThreeManager.setStaleStatus(false)
      }
    },
    [
      documentSetId,
      projectId,
      partClassificationInObjectMap,
      npaDirectionsWithOrder,
      setLayoutIsLoading,
      getDraft,
      dfoId,
      getNpaPartsById,
      getNpasList,
      layoutThree,
    ],
  )

  //Следит за ошибками из ActionBanner. Если пришла оттуда ошибка, необходимо пересобрать дерево
  useEffect(() => {
    const listener = (isStale) => isStale && initializeLayoutThree()

    NpaAddonThreeManager.subscribe(listener)

    return () => NpaAddonThreeManager.unsubscribe(listener)
  }, [initializeLayoutThree])

  useEffect(() => {
    (async () => {
      await initializeLayoutThree()
    })()
  }, [initializeLayoutThree])

  const handleOpenNpaModal = () => {
    if (!projectId || !dfoId) return

    const { npaStructure } = layoutThree.getValues()

    const chosenPartsForModal = fromLayoutInterfaceToModalChosen(npaStructure)

    const excludedPartIds = chosenPartsForModal?.map((part) => part.partId) || []

    const isFederalProject = !!project?.isFederal

    handleOpenRegistryNpaChooseOldModal({
      documentSetId,
      projectId,
      dfoId,
      excludedPartIds,
      onUpdateNpaLayout: initializeLayoutThree,
      chosenParts: chosenPartsForModal,
      initialFilters: project
        ? {
            isRegional: isFederalProject ? true : !project.isFederal,
            isFederal: isFederalProject,
            isMunicipal: undefined,
            region: regionsToNpaModal.length ? regionsToNpaModal : undefined,
          }
        : undefined,
    })
  }

  const handleUpdateDraftModel = async (updatedChosenParts: INpaDraftBody) => {
    if (!projectId) return

    try {
      await putDraft({
        dfoId,
        documentSetId: documentSetId,
        projectId,
        documentType: DocumentsType.NPA_LIST,
        body: updatedChosenParts,
      })

      layoutThree.setValue('chosenParts', updatedChosenParts)
    } catch (error) {
      const additionInfo = {
        documentSetId: documentSetId,
        documentType: DocumentsType.NPA_LIST,
        body: updatedChosenParts,
      }

      LoggerHelpersService.handleMultipleLogError({
        additionInfo,
        componentInfo: {
          componentName: 'NpaAddon',
          moduleName: 'DynamicDocumentNew',
          componentType: 'updateDraft',
        },
      })(error)

      throw error
    }
  }

  const handleOpenNPAPreview = async (e) => {
    e.preventDefault()

    if (!projectId) return

    try {
      return await getDraftPreview({
        dfoId,
        documentSetId: documentSetId,
        projectId,
        documentType: DocumentsType.NPA_LIST,
      })
    } catch (error) {
      LoggerHelpersService.handleMultipleLogError({
        componentInfo: {
          componentName: 'NpaAddon',
          componentType: 'handleOpenNPAPreview',
          moduleName: 'DynamicDocumentNew',
        },
        additionInfo: {
          documentSetId: documentSetId,
          documentType: DocumentsType.NPA_LIST,
        },
      })(error)
    }
  }

  const openPreviewWithToast = (openPreviewProps: Parameters<typeof handleOpenNPAPreview>[0]) => {
    return withDownloadToastPromise(handleOpenNPAPreview(openPreviewProps))
  }

  return (
    <div className={styles.npaLayout}>
      {!layoutIsLoading && !!chosenParts?.npaParts.length && (
        <Button
          className={styles.npaLayout__download}
          geometry={'square'}
          size={'s'}
          view={'plain'}
          dataTestId="NpaAddon-preview-button"
          leadingIcon={{
            src: EyeIcon,
            size: 'initial',
          }}
          onClick={openPreviewWithToast}
        >
          Предварительный просмотр
        </Button>
      )}

      <Loader
        wrapperClassName={styles.npaLayout__loading}
        loading={layoutIsLoading}
        variant={'lite'}
        dataTestId="NpaAddon-loader"
      >
        <NpaThreeOld
          className={styles.npaLayout__three}
          stateOfDirections={stateOfDirections}
          handleBindCardRef={handleBindCardRef}
          layoutThree={layoutThree}
          updateAllLayoutThree={initializeLayoutThree}
          onUpdateDraftModel={handleUpdateDraftModel}
        />

        <ControlContainer
          className={styles.npaLayout__control}
          titleClassName={styles['npaLayout__control-title']}
          leadingNodeContent={<ControlContainerStatusIcon />}
          title={
            'Выберите нормативные правовые акты, которые хотите стабилизировать в рамках соглашения'
          }
          trailingNodeContent={
            <Button
              fixWidth
              view="tined"
              dataTestId="NpaAddon-openNpaModal-button"
              onClick={handleOpenNpaModal}
            >
              Выбрать НПА
            </Button>
          }
        />
      </Loader>
    </div>
  )
}

export default memo(NpaAddonOld)
