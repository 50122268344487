import { UseFormReturn, useWatch } from 'react-hook-form'

import { statementBlockValues } from '@components/Forms/CreateStatement/const'
import { StatementFormValues } from '@components/Forms/CreateStatement/types'

// В валидации используется getValues на эти поля, useWatch нужен, чтобы сделать их реактивными
export const useWatchFieldsForValidation = (
  formInstance: UseFormReturn<StatementFormValues, any>,
) => {
  useWatch({
    name: statementBlockValues.stage.stepOfStages,
    control: formInstance.control,
  })

  useWatch({
    name: statementBlockValues.stage.projectEndDate,
    control: formInstance.control,
  })

  useWatch({
    name: statementBlockValues.stage.projectStartDate,
    control: formInstance.control,
  })

  useWatch({
    name: statementBlockValues.stage.stepOfStages,
    control: formInstance.control,
  })

  useWatch({
    name: statementBlockValues.stage.projectStartDate,
    control: formInstance.control,
  })
}
